import React from 'react'
import PropTypes from 'prop-types'

import { Save } from '@styled-icons/heroicons-outline/Save'

import { memo } from 'utils/react'
import { CommonTextLabel, CommonTextfield } from 'components/common'

import T from 'T'
import Constants from 'Constants'
import BasicModal from 'components/modal/BasicModal'
import FooterButton from 'components/buttons/FooterButton'

const { MEDIA_SERVER } = Constants

const SaveEditInvoiceViewModal = ({ isOpen, input, isAddMode, onChange, onDelete, onSubmit, onClose }) => (
  <BasicModal isOpen={isOpen} onClose={onClose}>
    <div className={`common-modal-style add-edit-invoice-view ${isAddMode ? 'add-mode' : 'edit-mode'}`}>
      <div className="area">
        <div className="modal-header flex items-center justify-between">
          <div className="flex items-center left">
            <Save className="icon-w-16" />
            <span className="text ml1">{isAddMode ? T.SAVE_VIEW : T.SAVED_VIEW}</span>
          </div>
          <span className="close-icon cursor-pointer" onClick={onClose}>
            <img src={`${MEDIA_SERVER}Close.svg`} alt="" />
          </span>
        </div>
        <div className="modal-body view-body">
          <div>
            <CommonTextLabel title={T.VIEW_NAME} isRequired />
            <CommonTextfield
              placeholder={T.VIEW_NAME}
              value={input}
              onChange={event => onChange('saveEditViewInput', event.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <FooterButton
              leftButtonTitle={isAddMode ? T.CANCEL : T.REMOVE}
              hasBackButton={!isAddMode}
              backButtonTitle={T.CANCEL}
              rightButtonTitle={T.SAVE}
              onClose={isAddMode ? onClose : onDelete}
              onBack={onClose}
              onProceed={onSubmit}
              disabledProceed={!input.trim()}
            />
          </div>
        </div>
      </div>
    </div>
  </BasicModal>
)

SaveEditInvoiceViewModal.defaultProps = {
  isOpen: false,
  input: '',
  isAddMode: true,
}

SaveEditInvoiceViewModal.propTypes = {
  isOpen: PropTypes.bool,
  input: PropTypes.string,
  isAddMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(SaveEditInvoiceViewModal)
