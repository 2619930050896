import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'

const PreviewListItem = ({ color, primaryLeft, primaryRight, secondaryLeft, secondaryRight, withVerticalDivider = false }) => {
  return (
    <Box>
      <Stack direction="row" flexWrap="nowrap" alignItems="flex-start">
        <ListItem
          disablePadding
          disableGutters
          sx={{ px: 1, flex: '5 5 auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {color && (
            <ListItemAvatar sx={{ minWidth: 'unset', mr: 0.5 }}>
              <Box bgcolor={color} height="12px" width="12px" />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={primaryLeft}
            primaryTypographyProps={{ fontWeight: 700, noWrap: true }}
            secondary={secondaryLeft}
            secondaryTypographyProps={{ noWrap: true, variant: 'body1' }}
          />
        </ListItem>
        {withVerticalDivider && <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 1 }} />}
        <ListItem
          disablePadding
          disableGutters
          sx={{ px: 1, flex: '7 7 auto', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          <ListItemText
            primary={primaryRight}
            primaryTypographyProps={{ fontWeight: 700, textAlign: 'right', noWrap: true, whiteSpace: 'nowrap' }}
            secondary={secondaryRight}
            secondaryTypographyProps={{ textAlign: 'right', variant: 'body1' }}
          />
        </ListItem>
      </Stack>
      <Divider />
    </Box>
  )
}

PreviewListItem.propTypes = {
  color: PropTypes.string,
  primaryLeft: PropTypes.node,
  primaryRight: PropTypes.node,
  secondaryLeft: PropTypes.node,
  secondaryRight: PropTypes.node,
  withVerticalDivider: PropTypes.bool,
}

export default PreviewListItem
