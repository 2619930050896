import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ReactComponent as WorkOrderNoteIcon } from 'assets/note/WorkOrderNote.svg'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import { CheckCircle } from '@styled-icons/heroicons-outline/CheckCircle'

import { get } from 'utils/lodash'
import {
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
  usePatchWorkOrderNoteMutation,
  useLazyGetAllWorkOrderNotesQuery,
} from 'api/notes/workOrderNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'
import { CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { handleError } from 'utils/error'
import { canChangeWOInvoiceStatusSelector } from 'data/work-order/workOrderSelectors'
import { APP_PREFIX } from 'router/routes'

import T from 'T'
import api from 'api'
import Loader from 'components/common/loader'
import ViewArchiveNotesDialog from 'components/notes/common/ViewArchiveNotesDialog'
import ViewArchivedNotesMenuOption from 'components/notes/common/actions/ViewArchivedNotesMenuOption'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'

import CommonSwitch from 'components/common/CommonSwitch'
import ExternalLinkBehavior from 'components/common/ExternalLinkBehavior'
import AddEditPurchaseOrderMenuOption from 'components/work-order/purchase-order/AddEditPurchaseOrderMenuOption'
import RemovePurchaseOrderMenuOption from 'components/work-order/purchase-order/RemovePurchaseOrderMenuOption'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE } = INVOICE_STATUS

const WorkOrderActionMenu = ({ anchorEl, workOrder = {}, onClose }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder, { isLoading: isUpdateWorkOrderLoading }] = useUpdateWorkOrderMutation()

  const [getAllWorkOrderNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllWorkOrderNotesQuery()
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)
  const [isOpenAddNoteDialog, setIsOpenAddNoteDialog] = useState(false)
  const { workOrderId, routeId, invoiceStatus, pricedServiceType, serviceDate, purchaseOrderNumber, archivedNotesCount } = workOrder

  const canChangeWOInvoiceStatus = canChangeWOInvoiceStatusSelector({ serviceType: pricedServiceType, invoiceStatus })

  const handleInvoiceStatusChange = () => {
    updateWorkOrder({
      id: workOrderId,
      invoiceStatus: invoiceStatus === READY_FOR_INVOICE ? NOT_READY_FOR_INVOICE : READY_FOR_INVOICE,
    })
      .unwrap()
      .then(() => {
        onClose()
        toast.success('Invoice status updated successfully')
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_LIST]))
      })
      .catch(handleError)
  }

  const handleOpenAddNoteClick = () => {
    onClose()
    setIsOpenAddNoteDialog(true)
  }

  const handleCloseAddNote = () => {
    setIsOpenAddNoteDialog(false)
  }

  const handleViewArchiveClick = () => {
    onClose()
    setIsOpenViewArchiveNoteDialog(true)
    getAllWorkOrderNotes({ id: workOrderId })
      .unwrap()
      .catch(error => {
        handleError(error)
        setIsOpenViewArchiveNoteDialog(false)
      })
  }

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  return (
    <>
      {isUpdateWorkOrderLoading && <Loader />}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...(canChangeWOInvoiceStatus && { slotProps: { paper: { sx: { minWidth: 275 } } } })}
      >
        {routeId && workOrderId && (
          <MenuItem>
            <Button
              component={ExternalLinkBehavior}
              to={`${APP_PREFIX}/routes/${routeId}/work_orders/${workOrderId}/export?date=${serviceDate}`}
              variant="text"
              sx={{ color: 'inherit', fontSize: '13px', fontWeight: '400', margin: '0', padding: '0' }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <Printer width={24} height={24} />
              </ListItemIcon>
              <ListItemText>{T.PRINTABLE_VIEW}</ListItemText>
            </Button>
          </MenuItem>
        )}
        {canChangeWOInvoiceStatus && (
          <MenuItem onClick={handleInvoiceStatusChange}>
            <ListItemIcon sx={{ mr: 1 }}>
              <CheckCircle width={24} height={24} />
            </ListItemIcon>
            <ListItemText>{INVOICE_STATUS_DISPLAY.READY_FOR_INVOICE.text}</ListItemText>
            <CommonSwitch isChecked={invoiceStatus === READY_FOR_INVOICE} />
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenAddNoteClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <WorkOrderNoteIcon />
          </ListItemIcon>
          <ListItemText>{T.ADD_NOTE}</ListItemText>
        </MenuItem>
        <ViewArchivedNotesMenuOption isDisabled={archivedNotesCount === 0} count={archivedNotesCount} onClick={handleViewArchiveClick} />
        <AddEditPurchaseOrderMenuOption workOrderId={workOrderId} existingPurchaseOrder={purchaseOrderNumber} onClose={onClose} />
        <RemovePurchaseOrderMenuOption workOrderId={workOrderId} existingPurchaseOrder={purchaseOrderNumber} onClose={onClose} />
      </Menu>
      <AddUpdateNoteDialog
        id={workOrderId}
        isOpen={isOpenAddNoteDialog}
        title={T.NEW_WORK_ORDER_NOTE}
        useCreateNoteMutation={useCreateWorkOrderNoteMutation}
        useUpdateNoteMutation={useUpdateWorkOrderNoteNewMutation}
        onClose={handleCloseAddNote}
      />
      <ViewArchiveNotesDialog
        id={workOrderId}
        isOpen={isOpenViewArchiveNoteDialog}
        isLoading={isGetAllNotesLoading}
        title={T.ARCHIVED_WORK_ORDER_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchWorkOrderNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

WorkOrderActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  workOrder: PropTypes.object,
  onClose: PropTypes.func,
}

export default WorkOrderActionMenu
