import React from 'react'
import { get } from 'lodash'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as DashedTag } from 'assets/tags/DashedTag.svg'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import Avatar from '@mui/material/Avatar'
import { Tag } from '@styled-icons/heroicons-outline/Tag'
import { NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'

const RenderTagFilterOption = (props, option, { selected }) => {
  const theme = useTheme()
  const id = get(option, 'id')
  const isActive = get(option, 'active', false)
  const tagName = get(option, 'tagName')
  const secondaryText = get(option, 'secondaryText')
  const tagColor = get(option, 'tagColor')
  const contrastText = theme.palette.getContrastText(tagColor)
  const isNoTagsOption = id === NO_TAGS_OPTION_ID
  return (
    <ListItemButton dense disableGutters {...props}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 1,
        }}
      >
        <Stack alignItems="center" direction="row">
          <Checkbox checked={selected} />
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: tagColor,
            }}
          >
            <SvgIcon
              sx={{
                color: contrastText,
              }}
            >
              {isNoTagsOption ? <DashedTag /> : <Tag />}
            </SvgIcon>
          </Avatar>
        </Stack>
      </ListItemIcon>
      <ListItemText
        primary={tagName}
        primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }}
        secondary={secondaryText}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
      {!isActive && (
        <ListItemIcon sx={{ minWidth: 0, ml: 1 }}>
          <VisibilityOffOutlinedIcon color="action" />
        </ListItemIcon>
      )}
    </ListItemButton>
  )
}

export default RenderTagFilterOption
