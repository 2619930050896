import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import PricingDrawer from 'components/pricing/common/drawer/PricingDrawer'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import { FormProvider, useForm } from 'react-hook-form'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { shallowEqual, useSelector } from 'react-redux'
import LateFeeDetailsHeader from 'components/pricing/LateFees/details/LateFeeDetailsHeader'
import LateFeeDetailsList from 'components/pricing/LateFees/details/LateFeeDetailsList'
import { get } from 'lodash'
import { useLazyFetchAccountsLinkedToLateFeeQuery } from 'api/pricing/lateFeesCrud'
import uniq from 'lodash/uniq'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'
import { FORM_MODEL } from 'containers/customers/groups/account/details/settings'
import { useParams } from 'react-router-dom-v5-compat'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'

const LateFeeDetails = () => {
  const { id } = useParams()
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const [fetchAccountsLinkedToLateFee, { data }] = useLazyFetchAccountsLinkedToLateFeeQuery()

  const [getAccountMeta, { isSuccess: isAccountMetaSuccess, data: accountMetaData }] = useLazyGetAccountMetaQuery()
  const lateFeeDetailsForm = useForm({ defaultValues: { ...FORM_MODEL } })
  const { reset } = lateFeeDetailsForm
  const customerMeta = useSelector(getAccountMetaData, shallowEqual)

  useEffect(() => {
    if (id && data && isAccountMetaSuccess) {
      const accounts = get(data, 'accounts', [])
      const dataBillingProfileIds = uniq(accounts.map(({ billingProfileId }) => billingProfileId))
      const dataTagIds = uniq(accounts.map(({ tagIds }) => tagIds).flat())
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      const selectedTags = allTags.filter(({ id }) => dataTagIds.includes(id))
      const selectedBillingProfiles = billingProfiles.filter(({ id }) => dataBillingProfileIds.includes(id))
      reset({
        ...FORM_MODEL,
        tagList: selectedTags,
        selectedTags,
        billingProfileList: selectedBillingProfiles,
        selectedBillingProfiles,
      })
    }
  }, [accountMetaData, id, data, isAccountMetaSuccess])

  useEffect(() => {
    getAccountMeta()
  }, [])

  useEffect(() => {
    if (id) {
      fetchAccountsLinkedToLateFee({ id, includeTags: true })
    }
  }, [id])

  return (
    <Box height="100%" overflowY="hidden" minWidth="750px">
      <PricingDrawer />
      <CustomerLayout overflow="hidden" open={isOpenPricingDrawer}>
        <FormProvider {...lateFeeDetailsForm}>
          <LateFeeDetailsHeader />
          <LateFeeDetailsList />
        </FormProvider>
      </CustomerLayout>
    </Box>
  )
}

export default LateFeeDetails
