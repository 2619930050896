import React from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Grid, IconButton, styled, SvgIcon, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { XCircle as UnstyledXCircle } from '@styled-icons/heroicons-solid/XCircle'
import T from 'T'
import DisposalMaterialPicker from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalMaterialPicker'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { ReactComponent as MultiplicationSign } from 'assets/MultiplicationSign.svg'
import { ReactComponent as EqualSign } from 'assets/EqualSign.svg'
import MaterialTaxFooter from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/MaterialTaxFooter/MaterialTaxFooter'
import { HHFormNumberField, HHFormPriceField } from '../../../../form-fields/v5'

const XCircle = styled(UnstyledXCircle)(({ theme }) => ({ color: theme.palette.text.secondary }))
const DisposalMaterialFormRow = ({
  allSites,
  materialFields,
  validMaterials,
  index,
  isFormEditable,
  onMaterialDelete,
  groupByFeeTaxes,
  isLast,
  showSubtotal = true,
}) => {
  const { control, watch, setValue } = useFormContext()
  const isTaxable = watch(`isTaxable`)
  const quantity = watch(`materials.${index}.quantity`)
  const unitPriceDollars = watch(`materials.${index}.unitPriceDollars`)
  const disposalSiteId = watch('disposalSiteId')
  const subtotal = quantity * unitPriceDollars

  const validateMaterialId = materialId => {
    const isValid = validMaterials.find(({ id }) => id === materialId)
    return Boolean(isValid)
  }

  const handleMaterialDelete = () => onMaterialDelete(index)

  const handleDisposalSiteMaterialIdChange = newMaterialId => {
    const selectedDisposal = allSites.find(site => site.id === disposalSiteId)
    const selectedMaterial = get(selectedDisposal, 'disposalSiteMaterial', []).find(material => material.id === newMaterialId)
    setValue(`materials.${index}.measureUnit`, selectedMaterial.measureUnit)
    setValue(`materials.${index}.tippingFee`, selectedMaterial.tippingFee)
  }

  return (
    <Box width="100%">
      <Grid item container xs={12} columnGap={1} rowGap={1.5} justifyContent="space-between" alingItems="center">
        <Grid container item xs={12} justifyContent="space-between" alingItems="center">
          <Grid item xs="auto">
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography variant="h5" fontWeight={500}>{`Material #${index + 1}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs="auto">
            <IconButton
              sx={{ visibility: materialFields.length !== 1 && isFormEditable ? 'visible' : 'hidden' }}
              onClick={handleMaterialDelete}
            >
              <XCircle width={20} height={20} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <DisposalMaterialPicker
            rules={{
              validate: validateMaterialId,
            }}
            onChange={handleDisposalSiteMaterialIdChange}
            readOnly={!disposalSiteId || !isFormEditable}
            materials={validMaterials}
            name={`materials.${index}.disposalSiteMaterialId`}
            control={control}
          />
        </Grid>
        {/* Row */}
        <Grid container item xs={12} sm={12} md={12} lg xl columnSpacing={1}>
          <Grid item xs sm md lg xl>
            <HHFormNumberField
              deprecatedLabel={false}
              fullWidth
              readOnly={!isFormEditable}
              decimalScale={8}
              control={control}
              name={`materials.${index}.quantity`}
              label={T.QUANTITY}
            />
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center" height={40}>
              <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
                <MultiplicationSign />
              </SvgIcon>
            </Box>
          </Grid>
          <Grid item xs sm md lg xl>
            <HHFormPriceField
              deprecatedLabel={false}
              fullWidth
              readOnly={!isFormEditable}
              fixedDecimalScale
              control={control}
              name={`materials.${index}.unitPriceDollars`}
              label={T.PRICE_PER_UNIT}
            />
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg="auto" xl="auto" alignItems="center" justifyContent={{ xs: 'flex-end' }}>
            <Grid item>
              <Box display="flex" justifyContent="center" alignItems="center" height={40}>
                <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
                  <EqualSign />
                </SvgIcon>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h5" fontWeight={500}>
                <HHDisplayMoney value={subtotal} formatToDollars={false} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MaterialTaxFooter showSubtotal={showSubtotal} subtotal={subtotal} groupByFeeTaxes={groupByFeeTaxes} index={index} />
      {!isLast && <Divider sx={{ mt: isTaxable ? 1 : 2, mb: isTaxable ? 1 : 2, mx: 0 }} variant="middle" />}
    </Box>
  )
}

DisposalMaterialFormRow.propTypes = {
  isFormEditable: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  allSites: PropTypes.array.isRequired,
  materialFields: PropTypes.array.isRequired,
  validMaterials: PropTypes.array.isRequired,
  onMaterialDelete: PropTypes.func.isRequired,
  groupByFeeTaxes: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  showSubtotal: PropTypes.bool,
}

export default DisposalMaterialFormRow
