import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableHead as THead, TableCell, Typography } from '@mui/material'

import { memo } from 'utils/react'

const OfficeTableHeader = ({ values = [] }) => {
  return (
    <THead>
      <TableRow>
        {Children.toArray(
          values &&
            values.map((val, index) => {
              return (
                <TableCell sx={{ p: '10px 20px', textAlign: index > 0 ? 'center' : '', width: index === 0 ? '65%' : 'auto' }}>
                  <Typography variant="subtitle2">{val}</Typography>
                </TableCell>
              )
            })
        )}
      </TableRow>
    </THead>
  )
}

OfficeTableHeader.propTypes = {
  values: PropTypes.array,
}

export default memo(OfficeTableHeader)
