import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'
import WeekSummary from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/WeekSummary'

const StopListItemText = ({ accountName, addressStr, serviceName, parsedDaysOfWeek, recurrence, showWeekSummary }) => {
  return (
    <ListItemText
      sx={{
        my: 0.5,
        [`& .${listItemTextClasses.primary}`]: {
          mr: 2.5,
        },
      }}
      primary={accountName}
      secondary={
        <Stack direction="column">
          <Typography noWrap variant="body2" color="textSecondary">
            {addressStr}
          </Typography>
          <Typography variant="body2" color="textSecondary" fontWeight={600}>
            {recurrence}
          </Typography>
          <Typography noWrap variant="body1" color="textPrimary" fontWeight={500}>
            {serviceName}
          </Typography>
          {showWeekSummary && <WeekSummary daysOfWeek={parsedDaysOfWeek} />}
        </Stack>
      }
      primaryTypographyProps={{ noWrap: true, color: 'textPrimary', fontWeight: 600 }}
      secondaryTypographyProps={{ noWrap: true }}
    />
  )
}

StopListItemText.propTypes = {
  addressStr: PropTypes.string,
  serviceName: PropTypes.string,
  parsedDaysOfWeek: PropTypes.array,
  accountName: PropTypes.string,
  recurrence: PropTypes.string,
  showWeekSummary: PropTypes.bool,
}

export default memo(StopListItemText)
