import React, { useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import useTheme from '@mui/material/styles/useTheme'
import PropTypes from 'prop-types'
import Constants from 'Constants'
import { parse, format } from 'date-fns'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { getBusinessInfo } from 'data/business-info/businessInfoSelector'
import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import { ReactComponent as ServiceNoteIcon } from 'assets/note/ServiceNote.svg'
import { ReactComponent as LocationNoteIcon } from 'assets/note/LocationNote.svg'
import { ReactComponent as WorkOrderNoteIcon } from 'assets/note/WorkOrderNote.svg'
import T from '../../../T'
import { get } from '../../../utils/lodash'
import { replaceAllSpacesAndAmpersand } from '../../../utils/string'
import { getContainerCount } from '../../../utils/container'
import WOInfo from '../common/WOInfo'
import Notes from './Notes'

const { MEDIA_SERVER } = Constants

const WorkOrderCard = ({
  accountName,
  accountNumber,
  city,
  contactName,
  contactPhone,
  containers,
  isCustomerCopy = true,
  line1,
  line2,
  locationName,
  measure,
  serviceAction,
  serviceName,
  state,
  locationNotesList = [],
  configuredServiceNotesList = [],
  workOrderNotesList = [],
  workOrderSequence,
  zipCode,
  purchaseOrderNumber = '',
}) => {
  const theme = useTheme()
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  const businessInfo = useSelector(getBusinessInfo)
  const businessAddress = get(userInfo, 'businessInfo.address', '')
  const businessWebsite = get(userInfo, 'businessInfo.website', '')
  const businessPhoneNumber = get(userInfo, 'businessInfo.phoneNumber', '')
  const businessEmail = get(businessInfo, 'businessEmail', '')
  const action = replaceAllSpacesAndAmpersand(serviceAction)
  const [searchParams] = useSearchParams()
  const serviceDate = searchParams.get('date')
  const parsedServiceDate = serviceDate ? parse(serviceDate, 'yyyy-M-dd', new Date()) : ''
  const formattedServiceDate = parsedServiceDate ? format(parsedServiceDate, 'MMMM d, yyyy') : ''
  const containerCount = getContainerCount(containers)
  const totalNotesCount = locationNotesList.length + configuredServiceNotesList.length + workOrderNotesList.length

  const getHeight = useCallback(() => {
    if (totalNotesCount > 3) {
      return isCustomerCopy ? '3.5in' : '6.5in'
    }

    return '5in'
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pt={6}
      sx={{
        breakInside: 'avoid',
        '-moz-column-break-inside': 'avoid',
        '@media print': {
          minHeight: getHeight(),
        },
      }}
    >
      <Grid container justifyContent="space-between" alignItems="flex-start" direction="row">
        <Grid item container xs={12} justifyContent="flexStart">
          <WOInfo
            accountName={accountName}
            accountNumber={accountNumber}
            containerCount={containerCount}
            serviceDate={formattedServiceDate}
            workOrderSequence={workOrderSequence}
            purchaseOrderNumber={purchaseOrderNumber}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flexStart">
          <Typography variant="h4" fontWeight="bold" sx={{ mt: 2 }}>
            {isCustomerCopy ? 'CUSTOMER COPY' : 'DRIVER COPY'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="hr"
            sx={{
              display: 'block',
              height: 4,
              background: 'transparent',
              border: 'none',
              borderTop: `solid 4px ${theme.palette.text.primary}`,
              width: '100%',
              mb: 2,
            }}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary" gutterBottom>
              Service Location
            </Typography>
          </Grid>
          {locationName && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textPrimary">
                {locationName}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1" color="textPrimary">
              {line1}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textPrimary">
              {line2}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textPrimary">
              {[city, state].filter(Boolean).join(', ')}
              {zipCode && ` ${zipCode}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item container xs={12} alignItems="center" sx={{ mb: 1 }}>
            <Grid item sx={{ width: 52 }}>
              <Typography variant="body2" textTransform="uppercase">
                {T.SERVICE}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {serviceName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center" sx={{ mb: 1 }}>
            <Grid item sx={{ width: 52 }}>
              <Typography variant="body2" textTransform="uppercase">
                {T.MEASURE}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {measure}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center" sx={{ mb: 1 }}>
            <Grid item sx={{ width: 52 }}>
              <Typography variant="body2" textTransform="uppercase">
                {T.ACTION}
              </Typography>
            </Grid>
            <Grid item container xs>
              <Typography variant="body1" sx={{ ml: 2, mr: 0.5 }}>
                {serviceAction}
              </Typography>
              {action && <img src={`${MEDIA_SERVER}Action/${action}.svg`} alt="" />}
            </Grid>
          </Grid>
        </Grid>
        {!isCustomerCopy && totalNotesCount > 0 && (
          <>
            {locationNotesList.length > 0 && <Notes label="Location notes" Icon={LocationNoteIcon} notes={locationNotesList} />}
            {configuredServiceNotesList.length > 0 && (
              <Notes label="Service notes" Icon={ServiceNoteIcon} notes={configuredServiceNotesList} />
            )}
            {workOrderNotesList.length > 0 && <Notes label="Work order notes" Icon={WorkOrderNoteIcon} notes={workOrderNotesList} />}
          </>
        )}
        {!isCustomerCopy && contactName && contactPhone && (
          <>
            <Grid item container xs={12} justifyContent="flex-end">
              <Grid item>
                <Typography variant="subtitle1" color="textPrimary" gutterBottom sx={{ mt: 2 }}>
                  Contact
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end">
              <Grid item>
                <Typography variant="body1" color="textPrimary">
                  {contactName}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {contactPhone}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {isCustomerCopy && (
        <Grid mt={3} mb={6} container>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            {businessPhoneNumber && (
              <>
                <Typography color="textSecondary">Tel.&nbsp;</Typography>
                <Typography>
                  <HHPhoneField displayType="text" value={businessPhoneNumber} defaultValue="" />
                </Typography>
              </>
            )}
            {businessEmail && (
              <>
                {businessPhoneNumber && <Typography color="textSecondary">&nbsp;|&nbsp;</Typography>}
                <Typography color="textSecondary">{businessEmail}</Typography>
              </>
            )}
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Typography>{businessAddress}</Typography>
            <Typography color="textSecondary">&nbsp;|&nbsp;</Typography>
            <Typography color="textSecondary">{businessWebsite}</Typography>
          </Grid>
        </Grid>
      )}
      {!isCustomerCopy && (
        <Grid mt={6} mb={3} container>
          <Grid item variant="subtitle1" color="textPrimary">
            Customer Signature:
          </Grid>
          <Grid item xs={3}>
            <Box
              component="hr"
              sx={{
                display: 'block',
                height: 10,
                background: 'transparent',
                border: 'none',
                borderBottom: `solid 2px ${theme.palette.text.primary}`,
                width: '100%',
                mb: 2,
                ml: 1,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

WorkOrderCard.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  containers: PropTypes.string.isRequired,
  isCustomerCopy: PropTypes.bool,
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  measure: PropTypes.string.isRequired,
  serviceAction: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  locationNotesList: PropTypes.array,
  configuredServiceNotesList: PropTypes.array,
  workOrderNotesList: PropTypes.array,
  workOrderSequence: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  purchaseOrderNumber: PropTypes.string,
}

export default WorkOrderCard
