import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid, Typography } from '@mui/material'

const SummaryCard = ({ label, value }) => (
  <Card variant="outlined">
    <CardContent>
      <Grid container justifyContent="center" alignItems="center" rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary" textAlign="center" fontWeight={500}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h3">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)

SummaryCard.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
}

export default SummaryCard
