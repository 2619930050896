import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@mui/material'
import T from '../../../../T'
import { get } from '../../../../utils/lodash'
import MeasureChip from '../../../../containers/new-route-manager/RouteCard/MeasureChip'
import { HHFormAutocompleteField } from '../../../form-fields/v5'

const MeasuresAutocompleteFormField = ({ sx, options, control, name }) => {
  const renderTags = (tags, getTagProps) =>
    tags.map((item, index) => {
      const volume = get(item, 'volume', '')
      const unit = get(item, 'unit', '')
      const measureLabel = [volume, unit].join(' ').trim()
      return <MeasureChip measureUnit={unit} label={measureLabel} size="small" {...getTagProps({ index })} />
    })

  const renderOptions = (props, option) => {
    const volume = get(option, 'volume', '')
    const unit = get(option, 'unit', '')
    const measureLabel = [volume, unit].join(' ').trim()
    return (
      <MenuItem {...props}>
        <MeasureChip measureUnit={unit} label={measureLabel} size="small" />
      </MenuItem>
    )
  }

  const getOptionLabel = x => {
    const volume = get(x, 'volume', '')
    const unit = get(x, 'unit', '')
    return [volume, unit].join(' ').trim()
  }

  return (
    <HHFormAutocompleteField
      getOptionLabel={getOptionLabel}
      fullWidth
      sx={sx}
      options={options}
      name={name}
      control={control}
      label={T.MEASURE}
      deprecatedLabel={false}
      renderTags={renderTags}
      renderOption={renderOptions}
    />
  )
}

MeasuresAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  options: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default MeasuresAutocompleteFormField
