import React from 'react'
import PropTypes from 'prop-types'

import Switch from 'react-switch'

import { memo } from 'utils/react'

const PricingSwitch = ({ handleSwitchVal, checked }) => {
  return (
    <Switch
      offColor="#7A869A"
      onColor="#00875A"
      width={32}
      height={16}
      handleDiameter={10}
      className="inner-switch"
      checked={checked}
      onChange={event => {
        if (handleSwitchVal) {
          handleSwitchVal(event)
        }
      }}
    />
  )
}

PricingSwitch.defaultProps = {
  handleSwitchVal: null,
  checked: false,
}

PricingSwitch.propTypes = {
  handleSwitchVal: PropTypes.func,
  checked: PropTypes.bool,
}

export default memo(PricingSwitch)
