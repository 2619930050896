import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'

import WorkOrderTableBody from './WorkOrderTableBody'
import WorkOrderTableHeader from './WorkOrderTableHeader'

import './style.scss'

const WorkOrderTable = ({ workOrderList, invoiceNumber, onImageIconClick }) => (
  <>
    <TableContainer sx={{ mt: 1 }} className="invoice-edit-container work-order-table">
      <TableView stickyHeader aria-label="sticky table">
        <WorkOrderTableHeader />
        <WorkOrderTableBody workOrderList={workOrderList} invoiceNumber={invoiceNumber} onImageIconClick={onImageIconClick} />
      </TableView>
    </TableContainer>
  </>
)

WorkOrderTable.defaultProps = {
  workOrderList: [],
}

WorkOrderTable.propTypes = {
  workOrderList: PropTypes.array,
}

export default WorkOrderTable
