import api from 'api'
import { CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    deletePaymentBatchesFromGroup: build.mutation({
      query: ({ id, batchIds }) => ({
        url: `/api/v1/billing/payment/groups/${id}/payments`,
        body: batchIds,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS, id: arg.id }],
    }),
  }),
})

export const { useDeletePaymentBatchesFromGroupMutation } = extendedApi
