import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost, doGet } from 'providers/api'

import {
  actions,
  putRouteStopsList,
  putRouteMetaDetails,
  putRouteAdd,
  putRouteSequenceUpdate,
  putRouteMoveStop,
  putUnassignedWorkOrder,
  putSubscribeToken,
} from '../actions/routeManager'

import endpoint from './endpoint'

export const getRouteStopsAPI = ({ body }) => doPost({ url: endpoint.routeManager.stopsList, body, showLoader: true })
export const getRouteDetailAPI = ({ body }) => doPost({ url: endpoint.routeManager.routeDetail, body, showLoader: true })
export const getRouteMetaDetailsAPI = ({ body }) => doPost({ url: endpoint.meta.route, body, showLoader: true })
export const getAddRouteAPI = ({ body }) => doPost({ url: endpoint.routeManager.addRoute, body, showLoader: true })
export const getUpdateRouteSequenceAPI = ({ body }) => doPost({ url: endpoint.routeManager.updateSequence, body, showLoader: true })
export const getMoveRouteStop = ({ body }) => doPost({ url: endpoint.routeManager.moveStopInterRoute, body, showLoader: true })
export const getUnassignedWorkOrdersAPI = ({ body }) => doPost({ url: endpoint.routeManager.unassignedWorkOrder, body, showLoader: true })
export const getWorkOrderAssignAPI = ({ body }) => doPost({ url: endpoint.routeManager.assignWorkOrder, body, showLoader: true })
export const getUpdatedRouteDate = ({ body }) => doPost({ url: endpoint.routeManager.moveRoute, body, showLoader: true })
export const getRouteSubscribeTokenAPI = ({ body }) => doGet({ url: endpoint.routeManager.getSyncToken, body, showLoader: false })
export const getRouteDeleteAPI = ({ body }) => doPost({ url: endpoint.routeManager.getRouteDeleated, body, showLoader: true })
export const getUnassignWorkOrderInBulk = ({ body }) => doPost({ url: endpoint.routeManager.unassignWorkOrders, body, showLoader: true })

export function* getRouteStopsSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putRouteStopsList([]))
    const { data: res } = yield call(getRouteStopsAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }

    yield put(putRouteStopsList(res))
  } catch (err) {
    yield put(putRouteStopsList([]))
  }
}

export function* getRouteDetail({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getRouteDetailAPI, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* getRouteMetaDetailsSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putRouteMetaDetails([]))
    const { data: res } = yield call(getRouteMetaDetailsAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }

    yield put(putRouteMetaDetails(res))
  } catch (err) {
    yield put(putRouteMetaDetails([]))
  }
}

export function* getAddRouteSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putRouteAdd(null))

    const { data: res } = yield call(getAddRouteAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }

    yield put(putRouteAdd(res))
  } catch (err) {
    yield put(putRouteAdd(null))
  }
}

export function* getRouteUpdateSequenceSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    yield put(putRouteSequenceUpdate(null))

    const { data: res } = yield call(getUpdateRouteSequenceAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess()
    }

    yield put(putRouteSequenceUpdate(res))
  } catch (err) {
    yield put(putRouteSequenceUpdate(null))
  }
}

export function* getRouteMoveSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putRouteMoveStop(null))

    const { data: res } = yield call(getMoveRouteStop, { body })

    if (typeof onSuccess === 'function') {
      onSuccess()
    }

    yield put(putRouteMoveStop(res))
  } catch (err) {
    yield put(putRouteMoveStop(null))
  }
}

export function* getUnassignedWorkOrdersSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putUnassignedWorkOrder(null))
    const { data: res } = yield call(getUnassignedWorkOrdersAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
    yield put(putUnassignedWorkOrder(res))
  } catch (err) {
    yield put(putUnassignedWorkOrder(null))
  }
}

export function* getRouteDeleteSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getRouteDeleteAPI, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
  }
}

export function* getWorkOrdreAssignSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getWorkOrderAssignAPI, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
  }
}

export function* getUpdatedRouteDateSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getUpdatedRouteDate, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
  }
}

export function* getRouteSubscribeTokenSaga({ payload }) {
  const { body } = payload
  try {
    const { data: res } = yield call(getRouteSubscribeTokenAPI, { body })
    yield put(putSubscribeToken(res))
  } catch (err) {
    yield put(putSubscribeToken(null))
  }
}

export function* getUnassignWorkOrdersInBulkSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getUnassignWorkOrderInBulk, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
  }
}

export default function* RouteManager() {
  yield all([
    takeEvery(actions.GET.ROUTE_STOPS_LIST, getRouteStopsSaga),
    takeEvery(actions.GET.META_DETAILS, getRouteMetaDetailsSaga),
    takeEvery(actions.GET.ADD_ROUTE, getAddRouteSaga),
    takeEvery(actions.GET.ROUTE_DETAIL, getRouteDetail),
    takeEvery(actions.GET.UPDATE_ROUTE_SEQUENCE, getRouteUpdateSequenceSaga),
    takeEvery(actions.GET.MOVE_ROUTE_STOP, getRouteMoveSaga),
    takeEvery(actions.GET.UNASSIGNED_WORK_ORDERS, getUnassignedWorkOrdersSaga),
    takeEvery(actions.ASSIGN_WORK_ORDER, getWorkOrdreAssignSaga),
    takeEvery(actions.MOVE_ROUTE_DATE, getUpdatedRouteDateSaga),
    takeEvery(actions.GET.SUBSCRIBE_TOKEN, getRouteSubscribeTokenSaga),
    takeEvery(actions.GET.DELETE_ROUTE, getRouteDeleteSaga),
    takeEvery(actions.UNASSIGN_WORK_ORDERS, getUnassignWorkOrdersInBulkSaga),
  ])
}
