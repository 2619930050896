import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get, groupBy } from 'lodash'
import { List, ListItem, ListItemAvatar, ListItemText, Skeleton, Box, Pagination } from '@mui/material'
import { useSelector } from 'react-redux'
import useTheme from '@mui/material/styles/useTheme'
import { useLazyGetAccountRouteStopsQuery } from 'api/accounts/accountRouting'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { CUSTOMER_DETAILS_DEFAULT_PAGINATION } from 'settings/constants/pagination'
import HHTablePagination from 'components/common/HHTablePagination'
import LocationItem from './LocationItem'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CUSTOMER_DETAILS_DEFAULT_PAGINATION
const MAX_SHIMMER_ROWS = 10

const ServicesGroupedByLocationList = ({ activeLocations = [], accountId, showLastDivider = false }) => {
  const theme = useTheme()
  const [pagination, setPagination] = useState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
  const activeLocationsCount = activeLocations?.length
  const maxShimmerRows = activeLocationsCount > MAX_SHIMMER_ROWS ? MAX_SHIMMER_ROWS : activeLocationsCount
  const shimmersArray = new Array(maxShimmerRows).fill(0)
  const [getAccountRouteStops, { data, isSuccess, isLoading, isFetching }] = useLazyGetAccountRouteStopsQuery()
  const stops = get(data, 'stops', [])
  const stopsByLocationId = groupBy(stops, 'locationId')
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const { page, rowsPerPage } = pagination
  const currentPageLocations = activeLocations.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  const currentPageLocationsLength = currentPageLocations.length

  const handlePageChange = (newPage = INITIAL_PAGE) => setPagination(p => ({ ...p, page: newPage }))

  useEffect(() => {
    getAccountRouteStops({
      accountId,
      serviceDate: formatDateToBEFormatDateFns(serviceDate),
    })
  }, [])

  useEffect(() => {
    getAccountRouteStops({
      accountId,
      serviceDate: formatDateToBEFormatDateFns(serviceDate),
    })
  }, [serializedServiceDate])

  useEffect(() => {
    if (currentPageLocationsLength === 0 && activeLocationsCount > 0) {
      handlePageChange()
    }
  }, [currentPageLocationsLength, activeLocationsCount])

  const showList = isSuccess && !isFetching && !isLoading

  return (
    <>
      <List sx={{ pb: 0 }}>
        {showList &&
          currentPageLocations.map(location => {
            const locationId = get(location, 'id')
            const serviceList = get(stopsByLocationId, locationId, [])
            return <LocationItem location={location} serviceList={serviceList} showLastDivider={showLastDivider} />
          })}
        {(isFetching || isLoading) &&
          shimmersArray.map(() => (
            <ListItem
              sx={{
                height: '64px',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:last-of-type': {
                  ...(showLastDivider === false && { borderBottom: 0 }),
                },
              }}
            >
              <ListItemAvatar>
                <Skeleton variant="circular" width={24} height={24} />
              </ListItemAvatar>
              <ListItemText primary={<Skeleton width={200} />} secondary={<Skeleton width={100} />} />
            </ListItem>
          ))}
      </List>
      {showList && activeLocationsCount > 0 && (
        <Box backgroundColor="background.header" pl={3} pr={2} display="flex" alignItems="center" justifyContent="space-between">
          <HHTablePagination
            page={page}
            component="div"
            count={activeLocationsCount}
            rowsPerPage={rowsPerPage}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onRowsPerPageChange={event => setPagination({ page: INITIAL_PAGE, rowsPerPage: event.target.value })}
            ActionsComponent={params => <Box display="none" {...params} />}
          />
          <Pagination
            color="primary"
            count={Math.ceil(activeLocationsCount / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => handlePageChange(newPage - 1)}
          />
        </Box>
      )}
    </>
  )
}

ServicesGroupedByLocationList.propTypes = {
  activeLocations: PropTypes.array.isRequired,
  accountId: PropTypes.string,
  showLastDivider: PropTypes.bool,
}

export default ServicesGroupedByLocationList
