import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addSkill: build.mutation({
      query: body => ({
        url: 'api/settings/skill/add',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAddSkillMutation } = extendedApi
