import React, { useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, CardHeader, Grid, Tab, Tabs } from '@mui/material'
import { useGridApiContext, useGridSelector, gridRowCountSelector } from '@mui/x-data-grid-pro'

import { RENTAL_FEE_ACCOUNTS_TAB_ID, RENTAL_FEE_SERVICES_TAB_ID } from 'components/pricing/RentalFees/details/settings'
import { selectRentalFeeTab, setRentalFeeTab } from 'slices/pricing/rentalFeesSlice'
import { useLazyGetRentalFeeDetailsQuery } from 'api/pricing/rentalFeesCrud'

import T from 'T'
import FiltersMenuButton from 'components/locations/filters/FiltersMenuButton'
import Summary from './Summary'

const TopSection = () => {
  const dispatch = useDispatch()
  const apiRef = useGridApiContext()
  const { id } = useParams()

  const [getRentalFeeDetails, { isFetching: isLoading, data }] = useLazyGetRentalFeeDetailsQuery()

  const { accountCount, serviceCount } = data || {}
  const rowCount = useGridSelector(apiRef, gridRowCountSelector) - 1
  const currentRentalFeeTab = useSelector(selectRentalFeeTab)

  const handleChangeSelectedRentalFeeTab = (event, newRentalFeeTab) => {
    dispatch(setRentalFeeTab(newRentalFeeTab))
  }

  useEffect(() => {
    if (id) {
      getRentalFeeDetails({ id })
    }
  }, [id])

  return (
    <Box px={3} pt={3} width="100%" pb={0}>
      <Grid container>
        <Grid item xs={12}>
          <Summary loading={isLoading} accountCount={accountCount} serviceCount={serviceCount} />
        </Grid>
        <Grid item>
          <Box sx={{ mt: 4, mb: 2 }}>
            <Tabs
              value={currentRentalFeeTab}
              onChange={handleChangeSelectedRentalFeeTab}
              sx={{
                button: { textTransform: 'none' },
                '.MuiTab-root': {
                  minWidth: 160,
                },
              }}
            >
              <Tab value={RENTAL_FEE_ACCOUNTS_TAB_ID} label={T.ACCOUNTS} />
              <Tab value={RENTAL_FEE_SERVICES_TAB_ID} label={T.SERVICES} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <CardHeader
              title={`${currentRentalFeeTab === RENTAL_FEE_ACCOUNTS_TAB_ID ? T.ACCOUNTS : T.SERVICES} (${rowCount})`}
              sx={{ bgcolor: 'background.header' }}
              // action={<FiltersMenuButton showSuspensionTypeFilter={false} />}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
export default TopSection
