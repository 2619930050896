import React, { Children } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { get } from 'utils/lodash'
import { setPaymentReceiptDownloadFailureDialogState } from 'slices/download/DownloadSlice'

const PaymentReceiptDownloadFailureDialog = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'Download.paymentReceiptDownloadFailureDialog.isOpen', false), shallowEqual)
  const receipts = useSelector(state => get(state, 'Download.paymentReceiptDownloadFailureDialog.receipts', []), shallowEqual)

  const handleClose = () => dispatch(setPaymentReceiptDownloadFailureDialogState({ isOpen: false, receipts: [] }))

  return (
    <HHBaseDialog open={isOpen} onClose={handleClose}>
      <HHDialogTitle title="Failed download" onClose={handleClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400} mb={1}>
            Failed to download the following payment receipts:
          </Typography>
          {Children.toArray(
            receipts.map(receipt => (
              <Typography variant="h5" color="textPrimary">
                {receipt}
              </Typography>
            ))
          )}
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" color="primary" onClick={handleClose}>
          OK
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

export default PaymentReceiptDownloadFailureDialog
