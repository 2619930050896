import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, Tooltip, Typography, useTheme } from '@mui/material'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { sortAccountsByFirstName } from 'data/groups/accountGroupsSelectors'
import { ReactComponent as AccountNoteIcon } from 'assets/note/AccountNote.svg'
import { ReactComponent as BillingNoteIcon } from 'assets/note/BillingNote.svg'
import { ReactComponent as UserMinusOutlineIcon } from 'assets/UserMinusOutline.svg'
import { ReactComponent as MoneyCheckDollarIcon } from 'assets/MoneyCheckDollar.svg'
import { ReactComponent as SuspensionProrationOnIcon } from 'assets/SuspensionProrationOnIcon.svg'
import { ReactComponent as SuspensionProrationOffIcon } from 'assets/SuspensionProrationOffIcon.svg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { Tag as TagIcon } from '@styled-icons/heroicons-outline/Tag'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { ReactComponent as SuspensionIcon } from 'assets/services/hold/SuspensionIcon.svg'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as InvoiceIcon } from 'assets/Invoice.svg'
import { ReactComponent as PaperPlaneIcon } from 'assets/PaperPlane.svg'
import { ReactComponent as RentalFeeGracePeriodIcon } from 'assets/RentalFeeGracePeriod.svg'

import T from 'T'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import AddAccountsToSuspensionGroupDialog from 'components/customers/groups/suspension/add-remove/AddAccountsToSuspensionGroupDialog'
import RemoveAccountsFromSuspensionGroupDialog from 'components/customers/groups/suspension/add-remove/RemoveAccountsFromSuspensionGroupDialog'
import { useGetAccountBulkActionFromGroupId } from 'components/customers/groups/account/useGetAccountBulkActionFromGroupId'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'
import { useGetBulkUpdateAccountSettings } from 'components/customers/groups/account/useGetBulkUpdateAccountSettings'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import AccountsResetGracePeriodDialog from 'components/pricing/RentalFees/ResetGracePeriod/AccountWise/AccountsResetGracePeriodDialog'
import AddEditAccountGroupDialog from './AddEditAccountGroupDialog'
import BulkChangeBillingProfileDialog from './common/bulk-change-billing-profile/BulkChangeBillingProfileDialog'
import BulkAddTagsToAccounts from './common/bulk-add-tags/BulkAddTagsToAccounts'
import BulkSendPortalInviteDialog from './common/bulk-send-portal-invite'
import RemoveAccountsFromGroupDialog from './add-remove/RemoveAccountsFromGroupDialog'
import AddAgingAccountsToAccountGroup from './add-remove/AddAgingAccountsToAccountGroup'
import BulkResendUnpaidInvoices from './common/bulk-resend-unpaid-invoices'

const SHOW_RESET_GRACE_PERIOD = false

const AccountGroupActionMenu = ({
  anchorEl,
  selectedGroup = {},
  showEditGroupOption = false,
  showAddToAccountGroupOption = false,
  showRemoveAccountsOption = false,
  showBulkSuspendOption = false,
  showBulkUnsuspendOption = false,
  showInviteAllButton = true,
  loading = false,
  onClose,
}) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const accountGroupId = get(selectedGroup, 'groupId')
  const useBulkUpdateAccountSettings = useGetBulkUpdateAccountSettings(accountGroupId)
  const [bulkUpdateAccountSettings, { isLoading: isBulkUpdateAccountSettingsLoading }] = useBulkUpdateAccountSettings()
  const theme = useTheme()
  const [isOpenEditAccountGroupDialog, setIsOpenEditAccountGroupDialog] = useState(false)
  const [isOpenAddToAccountGroupDialog, setIsOpenAddToAccountGroupDialog] = useState(false)
  const [isOpenBulkResendUnpaidInvoicesDialog, setIsOpenBulkResendUnpaidInvoicesDialog] = useState(false)
  const [isOpenAddBillingNoteConfirmDialog, setIsOpenAddBillingNoteConfirmDialog] = useState(false)
  const [isOpenAddBillingNoteDialog, setIsOpenAddBillingNoteDialog] = useState(false)
  const [isOpenAddAccountNoteConfirmDialog, setIsOpenAddAccountNoteConfirmDialog] = useState(false)
  const [isOpenAddAccountNoteDialog, setIsOpenAddAccountNoteDialog] = useState(false)
  const [isOpenAddAccountTagsConfirmDialog, setIsOpenAddAccountTagsConfirmDialog] = useState(false)
  const [isOpenAddAccountTagsDialog, setIsOpenAddAccountTagsDialog] = useState(false)
  const [isOpenTakePaymentDialog, setIsOpenTakePaymentDialog] = useState(false)
  const [isOpenChangeBillingProfileConfirmDialog, setIsOpenChangeBillingProfileConfirmDialog] = useState(false)
  const [isOpenChangeBillingProfileDialog, setIsOpenChangeBillingProfileDialog] = useState(false)
  const [isOpenResetGracePeriodDialog, setIsOpenResetGracePeriodDialog] = useState(false)
  const [isOpenSendPortalInviteDialog, setIsOpenSendPortalInviteDialog] = useState(false)
  const [isOpenRemoveAccountsFromGroupDialog, setIsOpenRemoveAccountsFromGroupDialog] = useState(false)
  const [isOpenAddAccountsToSuspensionGroupDialog, setIsOpenAddAccountsToSuspensionGroupDialog] = useState(false)
  const [isOpenRemoveAccountsFromSuspensionGroupDialog, setIsOpenRemoveAccountsFromSuspensionGroupDialog] = useState(false)
  const [isOpenTurnOffSuspensionProrationSuspensionDialog, setIsOpenTurnOffSuspensionProrationSuspensionDialog] = useState(false)
  const [isOpenTurnOnSuspensionProrationSuspensionDialog, setIsOpenTurnOnSuspensionProrationSuspensionDialog] = useState(false)
  const isSuspendedGroup = getIsSuspendedBucket(accountGroupId)
  const showBulkProrationOptions = !isSuspendedGroup
  const { useBulkAddBillingNote, useBulkAddAccountNote } = useGetAccountBulkActionFromGroupId(accountGroupId)
  const accounts = get(selectedGroup, 'accounts', [])
  const sortedAccounts = useMemo(() => cloneDeep(accounts).sort(sortAccountsByFirstName), [accounts])

  const handleOpenEditAccountGroupDialog = () => setIsOpenEditAccountGroupDialog(true)

  const handleCloseEditAccountGroupDialog = () => {
    setIsOpenEditAccountGroupDialog(false)
    onClose()
  }

  const handleOpenAddToAccountGroupDialog = () => setIsOpenAddToAccountGroupDialog(true)

  const handleCloseAddToAccountGroupDialog = () => {
    setIsOpenAddToAccountGroupDialog(false)
    onClose()
  }

  const handleOpenBulkResendUnpaidInvoicesDialog = () => setIsOpenBulkResendUnpaidInvoicesDialog(true)

  const handleCloseBulkResendUnpaidInvoicesDialog = () => {
    setIsOpenBulkResendUnpaidInvoicesDialog(false)
    onClose()
  }

  const handleOpenAddBillingNoteConfirmDialog = () => setIsOpenAddBillingNoteConfirmDialog(true)

  const handleCloseAddBillingNoteConfirmDialog = () => {
    setIsOpenAddBillingNoteConfirmDialog(false)
    onClose()
  }

  const handleOpenAddBillingNoteDialog = () => {
    setIsOpenAddBillingNoteConfirmDialog(false)
    setIsOpenAddBillingNoteDialog(true)
  }

  const handleCloseAddBillingNoteDialog = () => {
    setIsOpenAddBillingNoteDialog(false)
    onClose()
  }

  const handleOpenAddAccountNoteConfirmDialog = () => setIsOpenAddAccountNoteConfirmDialog(true)

  const handleCloseAddAccountNoteConfirmDialog = () => {
    setIsOpenAddAccountNoteConfirmDialog(false)
    onClose()
  }

  const handleOpenAddAccountNoteDialog = () => {
    setIsOpenAddAccountNoteConfirmDialog(false)
    setIsOpenAddAccountNoteDialog(true)
  }

  const handleCloseAddAccountNoteDialog = () => {
    setIsOpenAddAccountNoteDialog(false)
    onClose()
  }

  const handleOpenAddAccountTagsConfirmDialog = () => setIsOpenAddAccountTagsConfirmDialog(true)

  const handleCloseAddAccountTagsConfirmDialog = () => {
    setIsOpenAddAccountTagsConfirmDialog(false)
    onClose()
  }

  const handleOpenAddAccountTagsDialog = () => {
    setIsOpenAddAccountTagsConfirmDialog(false)
    setIsOpenAddAccountTagsDialog(true)
  }

  const handleCloseAddAccountTagsDialog = () => {
    setIsOpenAddAccountTagsDialog(false)
    onClose()
  }

  const handleOpenTakePaymentDialog = () => setIsOpenTakePaymentDialog(true)

  const handleCloseTakePaymentDialog = () => {
    setIsOpenTakePaymentDialog(false)
    onClose()
  }

  const handleOpenChangeBillingProfileConfirmDialog = () => setIsOpenChangeBillingProfileConfirmDialog(true)

  const handleCloseChangeBillingProfileConfirmDialog = () => {
    setIsOpenChangeBillingProfileConfirmDialog(false)
    onClose()
  }

  const handleOpenChangeBillingProfileDialog = () => {
    setIsOpenChangeBillingProfileConfirmDialog(false)
    setIsOpenChangeBillingProfileDialog(true)
  }

  const handleCloseChangeBillingProfileDialog = () => {
    setIsOpenChangeBillingProfileDialog(false)
    onClose()
  }

  const handleOpenResetGracePeriodDialog = () => setIsOpenResetGracePeriodDialog(true)

  const handleCloseResetGracePeriodDialog = () => {
    onClose()
    setIsOpenResetGracePeriodDialog(false)
  }

  const handleOpenSendPortalInviteDialog = () => setIsOpenSendPortalInviteDialog(true)

  const handleCloseSendPortalInviteDialog = () => {
    setIsOpenSendPortalInviteDialog(false)
    onClose()
  }

  const handleOpenRemoveAccountsFromGroupDialog = () => setIsOpenRemoveAccountsFromGroupDialog(true)

  const handleCloseRemoveAccountsFromGroupDialog = () => {
    setIsOpenRemoveAccountsFromGroupDialog(false)
    onClose()
  }

  const handleOpenAddAccountsToSuspensionGroupDialog = () => setIsOpenAddAccountsToSuspensionGroupDialog(true)

  const handleCloseAddAccountsToSuspensionGroupDialog = () => {
    setIsOpenAddAccountsToSuspensionGroupDialog(false)
    onClose()
  }

  const handleOpenRemoveAccountsFromSuspensionGroupDialog = () => setIsOpenRemoveAccountsFromSuspensionGroupDialog(true)

  const handleCloseRemoveAccountsFromSuspensionGroupDialog = () => {
    setIsOpenRemoveAccountsFromSuspensionGroupDialog(false)
    onClose()
  }

  const openConfirmTurnOnSuspensionProrationDialog = () => {
    setIsOpenTurnOnSuspensionProrationSuspensionDialog(true)
  }

  const closeConfirmTurnOnSuspensionProrationDialog = () => {
    setIsOpenTurnOnSuspensionProrationSuspensionDialog(false)
  }

  const openConfirmTurnOffSuspensionProrationDialog = () => {
    setIsOpenTurnOffSuspensionProrationSuspensionDialog(true)
  }

  const closeConfirmTurnOffSuspensionProrationDialog = () => {
    setIsOpenTurnOffSuspensionProrationSuspensionDialog(false)
  }

  const handleConfirmTurnOnSuspensionProrationDialog = () => {
    bulkUpdateAccountSettings({
      id: accountGroupId,
      data: {
        prorateSuspensions: true,
      },
    })
      .unwrap()
      .then(() => {
        toast.success(T.GROUP_PRORATION_UPDATED)
        closeConfirmTurnOnSuspensionProrationDialog()
        onClose()
      })
      .catch(handleError)
  }

  const handleConfirmTurnOffSuspensionProrationDialog = () => {
    bulkUpdateAccountSettings({
      id: accountGroupId,
      data: {
        prorateSuspensions: false,
      },
    })
      .unwrap()
      .then(() => {
        toast.success(T.GROUP_PRORATION_UPDATED)
        closeConfirmTurnOffSuspensionProrationDialog()
        onClose()
      })
      .catch(handleError)
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            variant: 'outlined',
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        {showEditGroupOption && (
          <MenuItem onClick={handleOpenEditAccountGroupDialog}>
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <PencilAlt />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.EDIT_GROUP_NAME}</ListItemText>
          </MenuItem>
        )}
        {showAddToAccountGroupOption && (
          <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
            <span>
              <MenuItem disabled={loading} onClick={handleOpenAddToAccountGroupDialog}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: theme.palette.action.active }} />
                  ) : (
                    <AddIcon fontSize="small" />
                  )}
                </ListItemIcon>

                <ListItemText>{T.ADD_TO_ACCOUNT_GROUP}</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
          <span>
            <MenuItem disabled={loading} onClick={handleOpenBulkResendUnpaidInvoicesDialog}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress size={20} thickness={4} sx={{ color: theme.palette.action.active }} />
                ) : (
                  <SvgIcon fontSize="small">
                    <InvoiceIcon />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText>{T.RESEND_UNPAID_INVOICES}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={handleOpenAddBillingNoteConfirmDialog}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <BillingNoteIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{T.ADD_BILLING_NOTES}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenAddAccountNoteConfirmDialog}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <AccountNoteIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{T.ADD_ACCOUNT_NOTES}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenAddAccountTagsConfirmDialog}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <TagIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{T.ADD_ACCOUNT_TAGS}</ListItemText>
        </MenuItem>
        <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
          <span>
            <MenuItem disabled={loading} onClick={handleOpenTakePaymentDialog}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress
                    size={20}
                    thickness={4}
                    sx={{
                      color: theme.palette.action.active,
                    }}
                  />
                ) : (
                  <AttachMoneyIcon fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>{T.TAKE_PAYMENT}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
          <span>
            <MenuItem disabled={loading} onClick={handleOpenChangeBillingProfileConfirmDialog}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress size={20} thickness={4} sx={{ color: theme.palette.action.active }} />
                ) : (
                  <MoneyCheckDollarIcon />
                )}
              </ListItemIcon>
              <ListItemText>{T.CHANGE_BILLING_PROFILE}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>

        {SHOW_RESET_GRACE_PERIOD && isRentalFeeEnabled && (
          <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
            <span>
              <MenuItem disabled={loading} onClick={handleOpenResetGracePeriodDialog}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress size={20} thickness={4} sx={{ color: theme.palette.action.active }} />
                  ) : (
                    <RentalFeeGracePeriodIcon />
                  )}
                </ListItemIcon>
                <ListItemText>{T.RESET_GRACE_PERIOD}</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}

        <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
          <span>
            <MenuItem disabled={loading} onClick={handleOpenSendPortalInviteDialog}>
              <ListItemIcon>
                {loading ? (
                  <CircularProgress size={20} thickness={4} sx={{ color: theme.palette.action.active }} />
                ) : (
                  <SvgIcon fontSize="small">
                    <PaperPlaneIcon />
                  </SvgIcon>
                )}
              </ListItemIcon>
              <ListItemText>{T.SEND_PORTAL_INVITE}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        {showRemoveAccountsOption && (
          <MenuItem onClick={handleOpenRemoveAccountsFromGroupDialog}>
            <ListItemIcon>
              <SvgIcon>
                <UserMinusOutlineIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.REMOVE_ACCOUNTS}</ListItemText>
          </MenuItem>
        )}
        {showBulkProrationOptions && (
          <MenuItem onClick={openConfirmTurnOnSuspensionProrationDialog}>
            <ListItemIcon>
              <SvgIcon>
                <SuspensionProrationOnIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.TURN_ON_SUSPENSION_PRORATION}</ListItemText>
          </MenuItem>
        )}
        {showBulkProrationOptions && (
          <MenuItem onClick={openConfirmTurnOffSuspensionProrationDialog}>
            <ListItemIcon>
              <SvgIcon>
                <SuspensionProrationOffIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.TURN_OFF_SUSPENSION_PRORATION}</ListItemText>
          </MenuItem>
        )}
        {showBulkSuspendOption && (
          <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
            <span>
              <MenuItem disabled={loading} sx={{ color: 'error.main' }} onClick={handleOpenAddAccountsToSuspensionGroupDialog}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{
                        color: theme.palette.action.active,
                      }}
                    />
                  ) : (
                    <SvgIcon sx={{ color: 'error.main' }} fontSize="small">
                      <SuspensionIcon />
                    </SvgIcon>
                  )}
                </ListItemIcon>
                <ListItemText>{T.SUSPEND_ACCOUNTS}</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {showBulkUnsuspendOption && (
          <Tooltip arrow title={loading && T.LOADING_GROUP_DATA} disableHoverListener={!loading} disableInteractive>
            <span>
              <MenuItem disabled={loading} sx={{ color: 'success.main' }} onClick={handleOpenRemoveAccountsFromSuspensionGroupDialog}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{
                        color: theme.palette.action.active,
                      }}
                    />
                  ) : (
                    <SvgIcon sx={{ color: 'success.main' }} fontSize="small">
                      <SuspensionIcon />
                    </SvgIcon>
                  )}
                </ListItemIcon>
                <ListItemText>{T.UNSUSPEND_ACCOUNTS}</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>
      <AddEditAccountGroupDialog
        isOpen={isOpenEditAccountGroupDialog}
        existingGroup={selectedGroup}
        onClose={handleCloseEditAccountGroupDialog}
      />
      {isOpenBulkResendUnpaidInvoicesDialog && (
        <BulkResendUnpaidInvoices
          isOpen={isOpenBulkResendUnpaidInvoicesDialog}
          groupId={accountGroupId}
          existingAccounts={accounts}
          onClose={handleCloseBulkResendUnpaidInvoicesDialog}
        />
      )}
      <HHConfirmDialog
        isOpen={isOpenAddBillingNoteConfirmDialog}
        DialogProps={{ maxWidth: 'xs' }}
        ConfirmButtonProps={{ color: 'primary' }}
        confirmButtonTitle={T.CONFIRM}
        confirmTitle="Confirm bulk add billing note"
        confirmDescription="You're about to add a billing note to every account in this group. Currently, there is no way to bulk remove notes. Are you sure you want to proceed?"
        onConfirm={handleOpenAddBillingNoteDialog}
        onClose={handleCloseAddBillingNoteConfirmDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenAddAccountNoteConfirmDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary' }}
        confirmTitle="Confirm bulk add account note"
        confirmDescription="You're about to add an account note to every account in this group. Currently, there is no way to bulk remove notes. Are you sure you want to proceed?"
        onConfirm={handleOpenAddAccountNoteDialog}
        onClose={handleCloseAddAccountNoteConfirmDialog}
      />
      <AddUpdateNoteDialog
        id={accountGroupId}
        isOpen={isOpenAddBillingNoteDialog}
        title={T.BILLING_NOTE}
        useCreateNoteMutation={useBulkAddBillingNote}
        useUpdateNoteMutation={() => [{}, {}]}
        onClose={handleCloseAddBillingNoteDialog}
      />
      <AddUpdateNoteDialog
        id={accountGroupId}
        isOpen={isOpenAddAccountNoteDialog}
        title={T.ACCOUNT_NOTE}
        useCreateNoteMutation={useBulkAddAccountNote}
        useUpdateNoteMutation={() => [{}, {}]}
        onClose={handleCloseAddAccountNoteDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenAddAccountTagsConfirmDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary' }}
        confirmTitle="Confirm add account tags"
        confirmDescription="You're about to add account tags to all accounts in this group. This will not affect existing tags on accounts. Are you sure you want to proceed?"
        onConfirm={handleOpenAddAccountTagsDialog}
        onClose={handleCloseAddAccountTagsConfirmDialog}
      />
      {isOpenAddAccountTagsDialog && (
        <BulkAddTagsToAccounts isOpen={isOpenAddAccountTagsDialog} groupId={accountGroupId} onClose={handleCloseAddAccountTagsDialog} />
      )}
      <HHConfirmDialog
        isOpen={isOpenChangeBillingProfileConfirmDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary' }}
        confirmTitle="Confirm change billing profile"
        confirmDescription="You're about to change the billing profile for every account in this group, are you sure you want to proceed?"
        onConfirm={handleOpenChangeBillingProfileDialog}
        onClose={handleCloseChangeBillingProfileConfirmDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenTurnOffSuspensionProrationSuspensionDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'error', loading: isBulkUpdateAccountSettingsLoading, disabled: isBulkUpdateAccountSettingsLoading }}
        confirmTitle={T.TURN_OFF_SUSPENSION_PRORATION}
        confirmDescription={
          <>
            You’re about to turn
            <Typography component="span" fontWeight={700}>
              {` OFF `}
            </Typography>
            suspension proration for every account in this group, are you sure you want to proceed?
          </>
        }
        onConfirm={handleConfirmTurnOffSuspensionProrationDialog}
        onClose={closeConfirmTurnOffSuspensionProrationDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenTurnOnSuspensionProrationSuspensionDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'success', loading: isBulkUpdateAccountSettingsLoading, disabled: isBulkUpdateAccountSettingsLoading }}
        confirmTitle={T.TURN_ON_SUSPENSION_PRORATION}
        confirmDescription={
          <>
            You’re about to turn
            <Typography component="span" fontWeight={700}>
              {` ON `}
            </Typography>
            suspension proration for every account in this group, are you sure you want to proceed?
          </>
        }
        onConfirm={handleConfirmTurnOnSuspensionProrationDialog}
        onClose={closeConfirmTurnOnSuspensionProrationDialog}
      />
      <BulkChangeBillingProfileDialog
        isOpen={isOpenChangeBillingProfileDialog}
        groupId={accountGroupId}
        allAccounts={accounts}
        onClose={handleCloseChangeBillingProfileDialog}
      />
      {isOpenSendPortalInviteDialog && (
        <BulkSendPortalInviteDialog
          isOpen={isOpenSendPortalInviteDialog}
          existingAccounts={sortedAccounts}
          showInviteAllButton={showInviteAllButton}
          onClose={handleCloseSendPortalInviteDialog}
        />
      )}
      <RemoveAccountsFromGroupDialog
        isOpen={isOpenRemoveAccountsFromGroupDialog}
        groupId={accountGroupId}
        existingAccounts={sortedAccounts}
        onClose={handleCloseRemoveAccountsFromGroupDialog}
      />
      {isOpenTakePaymentDialog && (
        <TakePaymentsDialog isOpen={isOpenTakePaymentDialog} existingAccounts={sortedAccounts} onClose={handleCloseTakePaymentDialog} />
      )}
      <AddAccountsToSuspensionGroupDialog
        isOpen={isOpenAddAccountsToSuspensionGroupDialog}
        onClose={handleCloseAddAccountsToSuspensionGroupDialog}
        existingAccounts={accounts}
      />
      <RemoveAccountsFromSuspensionGroupDialog
        isOpen={isOpenRemoveAccountsFromSuspensionGroupDialog}
        onClose={handleCloseRemoveAccountsFromSuspensionGroupDialog}
        existingAccounts={accounts}
      />
      <AddAgingAccountsToAccountGroup
        isOpen={isOpenAddToAccountGroupDialog}
        existingAccounts={accounts}
        onClose={handleCloseAddToAccountGroupDialog}
      />
      <AccountsResetGracePeriodDialog
        isOpen={isOpenResetGracePeriodDialog}
        existingAccounts={sortedAccounts}
        onClose={handleCloseResetGracePeriodDialog}
      />
    </>
  )
}

AccountGroupActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  selectedGroup: PropTypes.object,
  showAddToAccountGroupOption: PropTypes.bool,
  showEditGroupOption: PropTypes.bool,
  showRemoveAccountsOption: PropTypes.bool,
  showBulkSuspendOption: PropTypes.bool,
  showBulkUnsuspendOption: PropTypes.bool,
  showInviteAllButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default memo(AccountGroupActionMenu)
