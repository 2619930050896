import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@mui/material'
import { noop } from 'lodash'
import CancelButton from '../../buttons/CancelButton'
import SaveButton from '../../buttons/SaveButton'
import HHDialogActions from '../../common/HHDialogActions'

const ScheduleStopDialogActions = ({ onReset = noop, onCancel = noop, onSave = noop, loading = false }) => {
  return (
    <HHDialogActions sx={{ borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: 'divider' }}>
      <Grid sx={{ height: '100%' }} container justifyContent="space-between" wrap="nowrap" alignItems="center">
        <Grid item xs>
          <Button onClick={onReset} variant="outlined" color="warning" size="small">
            Reset
          </Button>
        </Grid>
        <Grid item container xs="auto" columnSpacing={2}>
          <Grid item>
            <CancelButton onClick={onCancel} />
          </Grid>
          <Grid item>
            <SaveButton loading={loading} onClick={onSave} />
          </Grid>
        </Grid>
      </Grid>
    </HHDialogActions>
  )
}

ScheduleStopDialogActions.propTypes = {
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool.isRequired,
}

export default ScheduleStopDialogActions
