import React from 'react'
import PropTypes from 'prop-types'
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const PaymentStatsTooltip = ({ color = '', label = '', value = 0, total = 0 }) => {
  return (
    <Box bgcolor="white" width="auto" height="auto" boxShadow="0px 1px 4px 0px #00000040">
      <List dense disablePadding>
        <ListItem dense disablePadding sx={{ px: 1, py: 0.5 }}>
          <ListItemText
            primary={
              <Box display="flex" alignItems="center">
                <Box width={16} height={16} bgcolor={color} />
                <Typography variant="caption" fontWeight={700} color="textPrimary" ml={0.5}>
                  {label}
                </Typography>
              </Box>
            }
          />
          <ListItemText
            sx={{ ml: 2 }}
            primaryTypographyProps={{ variant: 'caption', fontWeight: 700, color: 'textPrimary', textAlign: 'right' }}
            primary={
              <>
                <HHDisplayMoney value={total} />
                {`(${value})`}
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  )
}

PaymentStatsTooltip.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  total: PropTypes.number,
}

export default PaymentStatsTooltip
