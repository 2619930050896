import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useGetRouteImportSequenceMutation } from 'api/route/getRouteImportSequence'
import { putIsLoading } from 'middleware/actions/response'

import { isXlsxFormat, isValidFileSize } from 'utils/file'
import T from 'T'
import { Button, Dialog, DialogContent, FormControlLabel, Checkbox } from '@mui/material'
import { isImportSequenceFileValid } from 'utils/importSequenceFile'
import { handleError } from 'utils/error'
import ImportSequenceDialogContent from 'containers/new-route-manager/ImportSequenceDialogContent'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'

const ImportSequenceDialog = ({
  openImportSequenceDialog = false,
  routeId = '',
  serviceDate = new Date(),
  handleSequenceDialog,
  handleContextMenu,
  handleRouteUpdate,
}) => {
  const maxFileUploadSize = '5MB'
  const dispatch = useDispatch()
  const [resequenceRoutePlan, setResequenceRoutePlan] = useState(false)
  const [getRouteImportSequence] = useGetRouteImportSequenceMutation()

  const handleClose = () => {
    setResequenceRoutePlan(false)
    handleSequenceDialog()
  }

  const handleSequenceFile = async file => {
    try {
      await isImportSequenceFileValid(file, T.WORK_ORDER_ID_COLUMN)
      dispatch(putIsLoading(true))
      const data = { routeId, serviceDate, resequenceRoutePlan, exportXlsFile: file }
      getRouteImportSequence(data)
        .then(() => {
          dispatch(putIsLoading(false))
          handleClose()
          handleContextMenu()
          handleRouteUpdate(true)
          toast.success(T.SUCCESS_IMPORT_SEQUENCE)
        })
        .catch(handleError)
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <Dialog
      open={openImportSequenceDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <HHDialogTitle title={T.IMPORT_SEQUENCE} id="alert-dialog-title" onClose={handleClose} />
      <DialogContent>
        <ImportSequenceDialogContent resequenceColumnName={T.WORK_ORDER_ID_COLUMN} />
        <FormControlLabel
          control={
            <Checkbox
              checked={resequenceRoutePlan}
              onChange={event => {
                setResequenceRoutePlan(event.target.checked)
              }}
            />
          }
          label="Make Permanent"
        />
      </DialogContent>
      <HHDialogActions>
        <Button onClick={handleClose} autoFocus>
          {T.CANCEL}
        </Button>
        <Button sx={{ ml: 1 }} color="primary" component="label" variant="contained">
          <input
            accept=".xlsx"
            id="icon-button-file"
            type="file"
            hidden
            onChange={event => {
              const fileInput = event.target.files[0]
              // Return if not an image
              if (!isXlsxFormat(fileInput.name)) {
                toast.error(T.ERROR_READING_SEQUENCE_FILE)
                return
              }
              // convert to mb and check file size
              if (isValidFileSize(fileInput.size, maxFileUploadSize)) {
                toast.error(`Error! Please select image size less than ${maxFileUploadSize}`)
                return
              }
              handleSequenceFile(fileInput)
              event.target.value = ''
            }}
          />
          {T.IMPORT_SEQUENCE}
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

ImportSequenceDialog.propTypes = {
  openImportSequenceDialog: PropTypes.bool,
  routeId: PropTypes.string,
  serviceDate: PropTypes.string,
  handleSequenceDialog: PropTypes.func.isRequired,
  handleRouteUpdate: PropTypes.func.isRequired,
}

export default ImportSequenceDialog
