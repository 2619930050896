import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized'
import { get } from 'lodash'
import { Box } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import ChargesAccordionListItem from './ChargesAccordionListItem'
import Loader from '../../../common/loader'

const ChargeList = ({ loadMoreRows, isLoadingFirstBatch }) => {
  const listRef = useRef(null)
  const docIdList = useSelector(state => state.createInvoicesSlice.previewChargeDrawer.docIdList, shallowEqual)
  const docIdToChargeMap = useSelector(state => state.createInvoicesSlice.previewChargeDrawer.docIdToChargeMap, shallowEqual)
  const remoteRowCount = useSelector(state => state.createInvoicesSlice.preview.remoteRowCount)

  const rowRenderer = ({ index, style, isScrolling }) => {
    const docId = docIdList[index]
    const chargeMap = get(docIdToChargeMap, docId, {})
    const accountNumber = get(chargeMap, 'accountNumber')
    const total = get(chargeMap, 'total')
    const accountName = get(chargeMap, 'accountName')
    const locationListOfChargeList = get(chargeMap, 'locationListOfChargeList', [])
    const isLoading = get(chargeMap, 'isLoading', true)
    const expanded = get(chargeMap, 'expanded', false)
    const chargesCount = get(chargeMap, 'chargesCount', 0)
    const locationsCount = get(chargeMap, 'locationsCount', 0)
    const poCount = get(chargeMap, 'poCount', 0)

    return (
      <ChargesAccordionListItem
        key={`AccordionChargeList-${index}`}
        sx={style}
        docId={docId}
        expanded={expanded}
        index={index}
        isLoading={isLoading}
        title={accountName}
        accountNumber={accountNumber}
        total={total}
        locationListOfChargeList={locationListOfChargeList}
        listRef={listRef}
        chargesCount={chargesCount}
        locationsCount={locationsCount}
        poCount={poCount}
        isScrolling={isScrolling}
      />
    )
  }

  const getRowHeight = ({ index }) => {
    const docId = docIdList[index]
    const chargeMap = get(docIdToChargeMap, docId, {})
    return get(chargeMap, 'height', 98)
  }
  const isRowLoaded = ({ index }) => !!docIdList[index]

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {isLoadingFirstBatch && (
        <Box overflow="hidden">
          <Loader />
        </Box>
      )}
      <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={remoteRowCount}>
        {({ onRowsRendered, registerChild }) => (
          <Box
            flex="1 1 auto"
            width="100%"
            sx={{
              '& .ReactVirtualized__List': {
                py: '60px',
              },
            }}
          >
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={el => {
                    listRef.current = el
                    registerChild(el)
                  }}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={rowRenderer}
                  rowCount={remoteRowCount}
                  rowHeight={getRowHeight}
                  height={height}
                  width={width}
                />
              )}
            </AutoSizer>
          </Box>
        )}
      </InfiniteLoader>
    </Box>
  )
}

ChargeList.propTypes = {
  loadMoreRows: PropTypes.func,
  isLoadingFirstBatch: PropTypes.bool,
}

export default ChargeList
