import { styled, ToggleButton as MuiToggleButton } from '@mui/material'

const MapToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  '&, &:hover': {
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    opacity: 0.5,
    backgroundColor: theme.palette.background.paper,
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    opacity: 1,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}))

export default MapToggleButton
