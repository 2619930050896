import React from 'react'
import { Box } from '@mui/material'
import RouteManagerDatePicker from 'components/route-manager/RouteManagerAppBar/RouteManagerDatePicker'
import { setRoutingServiceDate } from 'slices/customer/customerDetailsSlice'
import { addDays } from 'date-fns'
import subDays from 'date-fns/subDays'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { deserializeDate } from 'utils/date'
import { FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS } from 'settings/constants/date'

const RoutingDatePickerSection = () => {
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()

  const handleServiceDateChange = newServiceDate => {
    dispatch(setRoutingServiceDate(newServiceDate))
  }

  const handleNextDateClick = () => {
    dispatch(setRoutingServiceDate(addDays(serviceDate, 1)))
  }

  const handlePreviousDateClick = () => {
    dispatch(setRoutingServiceDate(subDays(serviceDate, 1)))
  }
  return (
    <Box display="flex" position="absolute" top={27} alignItems="center" justifyContent="center" width="100%">
      <Box width="fit-content">
        <RouteManagerDatePicker
          inputFormat={FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS}
          width="14rem"
          value={serviceDate}
          onChange={handleServiceDateChange}
          onNextDate={handleNextDateClick}
          onPrevDate={handlePreviousDateClick}
        />
      </Box>
    </Box>
  )
}

RoutingDatePickerSection.propTypes = {}

export default RoutingDatePickerSection
