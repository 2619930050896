import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { get } from '../../../../utils/lodash'
import { WORK_ORDER_STATUS_DISPLAY } from '../../../../settings/constants/service'

const RouteSequence = ({ workOrderStatus, children }) => {
  const woStatusColor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}`, grey[400])
  const woStatusBgColor = get(woStatusColor, 'bgColor')
  return (
    <Box
      sx={{
        textAlign: 'center',
        border: '1.5px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: woStatusBgColor,
        color: 'text.lightBrown',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 0.5,
        px: 1,
      }}
    >
      <Typography variant="body2" color="textPrimary">
        {children}
      </Typography>
    </Box>
  )
}

RouteSequence.propTypes = {
  workOrderStatus: PropTypes.string,
  children: PropTypes.node,
}

export default RouteSequence
