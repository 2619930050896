import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useSelector, shallowEqual } from 'react-redux'
import {
  Table as TableView,
  TableContainer,
  TableRow,
  TableCell,
  TableBody as TBody,
  TableHead as THead,
  Radio,
  Typography,
  Box,
} from '@mui/material'

import { CAPTURE_MAPPINGS, FUNCTIONS_MAPPINGS, EXCEPTIONS, EXPAND, COMPLETE, SKIP, NONE } from 'settings/constants/roles'

import { memo } from 'utils/react'

import { canUpdateSettings, canUpdateUsers, canUpdatePermissions } from 'data/permissions/permissionsSelectors'

import T from 'T'
import ExceptionIcon from '../../../../../exceptions/ExceptionIcon'

const headerValues = [`${T.DATA_CAPTURE} Type`, `Functions for ${T.DATA_CAPTURE}`, T.EXPAND, T.COMPLETE, T.SKIP, T.NONE]

const DataCaptureScreen = ({ roleState = {}, setRoleState = noop, readonly = '', calledBy = '' }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)

  const handleChange = (event, type) => {
    if (updateSettings && updatePermissions && updateUsers) {
      setRoleState({ [`${CAPTURE_MAPPINGS[type]}`]: Number(event.target.value), footerFlag: true })
    }
  }

  const setMaxHeight = { xs: 'calc(100vh - 470px)', md: 'calc(100vh - 450px)' }

  return (
    <TableContainer
      sx={{
        maxHeight: calledBy ? setMaxHeight : 'calc(100vh - 350px)',
        overflowY: 'scroll',
        '& .MuiTableCell-body': {
          padding: '10px 20px',
          borderBottom: 'unset',
          '& .MuiRadio-root': {
            padding: 0,
          },
        },
      }}
    >
      <TableView stickyHeader aria-label="sticky table" sx={{ pointerEvents: readonly }}>
        <THead>
          <TableRow>
            {Children.toArray(
              headerValues &&
                headerValues.map((val, index) => {
                  return (
                    <TableCell sx={{ p: '10px 20px', textAlign: index > 1 ? 'center' : '' }}>
                      <Typography variant="subtitle2">{val}</Typography>
                    </TableCell>
                  )
                })
            )}
          </TableRow>
        </THead>

        <TBody>
          {Children.toArray(
            EXCEPTIONS &&
              EXCEPTIONS.map(val => {
                return (
                  <>
                    {val === T.CAMERA && (
                      <TableRow>
                        <TableCell colSpan="4">
                          <Typography variant="subtitle2" mt={1}>{`${T.DATA_CAPTURE} Only`}</Typography>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow hover>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <ExceptionIcon exception={val} />
                          <Typography variant="body1" ml={1}>
                            {val}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ display: 'flex', mt: 0.5 }}>
                        {Children.toArray(
                          FUNCTIONS_MAPPINGS &&
                            FUNCTIONS_MAPPINGS[val].map((title, index) => {
                              return (
                                <Typography
                                  variant="subtitle2"
                                  color={['DropDown', 'Selection'].includes(title) ? 'primary.main' : ''}
                                  display="flex"
                                >
                                  {title}
                                  {index !== FUNCTIONS_MAPPINGS[val].length - 1 && <Box mr={0.4}>{`, `}</Box>}
                                </Typography>
                              )
                            })
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <Radio
                          checked={roleState[CAPTURE_MAPPINGS[val]] === EXPAND}
                          onChange={event => handleChange(event, val)}
                          value={EXPAND}
                          name="radio-buttons"
                          size="small"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <Radio
                          checked={roleState[CAPTURE_MAPPINGS[val]] === COMPLETE}
                          onChange={event => handleChange(event, val)}
                          value={COMPLETE}
                          name="radio-buttons"
                          size="small"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <Radio
                          checked={roleState[CAPTURE_MAPPINGS[val]] === SKIP}
                          onChange={event => handleChange(event, val)}
                          value={SKIP}
                          name="radio-buttons"
                          size="small"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <Radio
                          checked={[NONE, 0].includes(roleState[CAPTURE_MAPPINGS[val]])}
                          onChange={event => handleChange(event, val)}
                          value={NONE}
                          name="radio-buttons"
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                )
              })
          )}
        </TBody>
      </TableView>
    </TableContainer>
  )
}

DataCaptureScreen.propTypes = {
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
  readonly: PropTypes.string,
  calledBy: PropTypes.string,
}

export default memo(DataCaptureScreen)
