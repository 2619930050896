import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBulkMoveFromRouteStopMarkers,
  selectBulkMoveFromRows,
  selectBulkMoveToRouteSequenceLine,
  selectBulkMoveToRouteStopMarkers,
  selectBulkMoveToRows,
  selectIsSequenceDialogOpen,
  setBulkMoveStopsState,
} from 'slices/route/bulkMoveBetweenRoutesSlice'
import PropTypes from 'prop-types'
import CommonSequenceDialog from 'components/route-manager/BulkActions/common/CommonSequenceDialog'
import useBulkResequenceFunctions from 'components/route-manager/BulkActions/common/useBulkResequenceFunctions'

const SequenceDialog = ({ moveFromGridApiRef, moveToGridApiRef }) => {
  const dispatch = useDispatch()
  const methods = useForm({
    defaultValues: {
      startingSequence: '',
    },
  })
  const { reset } = methods
  const isSequenceDialogOpen = useSelector(selectIsSequenceDialogOpen)
  const moveToRows = useSelector(selectBulkMoveToRows)
  const moveFromRows = useSelector(selectBulkMoveFromRows)
  const moveFromRouteStopMarkers = useSelector(selectBulkMoveFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkMoveToRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkMoveToRouteSequenceLine)
  const onClose = () => {
    dispatch(
      setBulkMoveStopsState({
        isSequenceDialogOpen: false,
      })
    )
  }

  const { onResetSequence, onPreserveSequence } = useBulkResequenceFunctions({
    moveToRows,
    moveFromRows,
    moveFromRouteStopMarkers,
    moveToRouteSequenceLine,
    moveFromGridApiRef,
    moveToGridApiRef,
    moveToRouteStopMarkers,
    reset,
    bulkReduxAction: setBulkMoveStopsState,
  })

  return (
    <FormProvider {...methods}>
      <CommonSequenceDialog
        onResetSequence={onResetSequence}
        onClose={onClose}
        isOpen={isSequenceDialogOpen}
        onPreserveSequence={onPreserveSequence}
      />
    </FormProvider>
  )
}

SequenceDialog.propTypes = {
  moveFromGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  moveToGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default SequenceDialog
