import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import DetailPanelContent from 'components/customers/accounts/common/expansion-panel'

const AccountGroupExpansionContent = ({ row = {} }) => {
  return (
    <Box mx={3} py={1.5} border="1px solid" borderColor="divider">
      <DetailPanelContent row={row} />
    </Box>
  )
}

AccountGroupExpansionContent.propTypes = {
  row: PropTypes.object,
}

export default AccountGroupExpansionContent
