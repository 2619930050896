import { useEffect, useState } from 'react'
import { collection, onSnapshot, orderBy, query, limit, startAfter } from 'firebase/firestore'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { db } from '../../../../providers/WithFirebase'
import { calculateSum, formatCentsToDollars } from '../../../../utils/price'
import { createDateFromString, formatDateToFEFormatDateFns, isValidDate } from '../../../../utils/date'
import {
  resetCreateInvoicesPreviewChargeDrawerState,
  resetCreateInvoicesPreviewData,
  updateCreateInvoicesDocIdToChargeMapItem,
} from '../../../../slices/billing/createInvoicesSlice'
import { handleError } from '../../../../utils/error'
import { filterValidPO } from '../../groups/invoice/printable-view/helpers/address'

function getFormattedAddress(address) {
  const addressName = get(address, 'addressName')
  const line1 = get(address, 'line1')
  const city = get(address, 'city')
  const state = get(address, 'state')
  const zipCode = get(address, 'zipCode')
  const addressLeftStr = [line1, city].filter(Boolean).join(', ')
  const addressRightStr = [state, zipCode].filter(Boolean).join(' ')
  const addressStr = [addressLeftStr, addressRightStr].filter(Boolean).join(', ')
  return [addressName, addressStr].filter(Boolean).join(' | ')
}
const getFormattedDate = dateStr => {
  if (!dateStr) return ''
  try {
    const date = createDateFromString(dateStr)
    return formatDateToFEFormatDateFns(date)
  } catch (e) {
    return ''
  }
}

const getSubChargeItemFromInvoiceFeeTaxCharges = (invoiceFeeTaxCharges, pricedServiceType) => {
  const service = get(invoiceFeeTaxCharges, 'name', '')
  const subChargeType = get(invoiceFeeTaxCharges, '')
  const startDate = get(invoiceFeeTaxCharges, 'createdAt')
  const formattedStartDate = startDate && isValidDate(startDate) && getFormattedDate(startDate)
  const quantity = get(invoiceFeeTaxCharges, 'quantity', 0)
  const fixedQuantity = quantity < 0 ? '' : quantity
  const charge = get(invoiceFeeTaxCharges, 'feeCharge', 0)
  const fixedCharge = charge.toFixed(2)
  return {
    service,
    startDate: pricedServiceType === 'On Request' ? '' : formattedStartDate,
    quantity: subChargeType === 'Disposal' ? '' : fixedQuantity,
    charge: fixedCharge,
  }
}
const getChargeItemFromInvoiceLine = invoiceLine => {
  const service = get(invoiceLine, 'summary', '')
  const startDate = get(invoiceLine, 'serviceStartDate', null)
  const formattedStartDate = getFormattedDate(startDate)
  const endDate = get(invoiceLine, 'serviceEndDate')
  const formattedEndDate = getFormattedDate(endDate)
  const charge = get(invoiceLine, 'serviceCharge', 0)
  const fixedCharge = charge.toFixed(2)
  const quantity = get(invoiceLine, 'quantity')
  const totalWithSubcharges = get(invoiceLine, 'total')
  const configuredServiceId = get(invoiceLine, 'configuredServiceId')
  const invoiceFeeTaxCharges = get(invoiceLine, 'invoiceFeeTaxCharges', [])
  const pricedServiceType = get(invoiceLine, 'pricedServiceType', '')
  const subChargeList = invoiceFeeTaxCharges.map(item => getSubChargeItemFromInvoiceFeeTaxCharges(item, pricedServiceType))
  const addressObj = get(invoiceLine, 'location.address', {})
  const locationId = get(invoiceLine, 'location.id')
  const address = getFormattedAddress(addressObj)
  const workOrders = get(invoiceLine, 'workOrders', [])
  return {
    service,
    date: [formattedStartDate, formattedEndDate].filter(Boolean).join(' - '),
    charge: fixedCharge,
    quantity,
    total: fixedCharge,
    totalWithSubcharges,
    subChargeList,
    configuredServiceId,
    address,
    pricedServiceType,
    startDate,
    locationId,
    workOrders,
  }
}

const compareInvoiceLine = (a, b) => {
  if (a.pricedServiceType < b.pricedServiceType) {
    return -1
  }
  if (a.pricedServiceType > b.pricedServiceType) {
    return 1
  }
  if (Date(a.startDate) < Date(b.startDate)) {
    return -1
  }
  if (Date(a.startDate) > Date(b.startDate)) {
    return 1
  }
  if (a.service < b.service) {
    return -1
  }
  if (a.service > b.service) {
    return 1
  }
  return 0
}

const updateLocationIdToChargeObjList = (locationIdToChargeObjList, locationId, chargeItemToBeInserted) => {
  const invoiceLines = get(locationIdToChargeObjList, locationId, [])
  const isOnRequestService = chargeItemToBeInserted.pricedServiceType === 'On Request'
  const isNotDuplicated = !invoiceLines.find(line => line.configuredServiceId === chargeItemToBeInserted.configuredServiceId)
  if (!(locationId in locationIdToChargeObjList)) {
    // eslint-disable-next-line no-param-reassign
    locationIdToChargeObjList[locationId] = [chargeItemToBeInserted]
  } else if (isOnRequestService || isNotDuplicated) {
    const index = invoiceLines.findIndex(invoiceLine => compareInvoiceLine(invoiceLine, chargeItemToBeInserted) === 1)
    if (index === -1) {
      locationIdToChargeObjList[locationId].push(chargeItemToBeInserted)
    } else {
      locationIdToChargeObjList[locationId].splice(index, 0, chargeItemToBeInserted)
    }
  }
}

const getLocationIdToChargeList = data => {
  const locationIdToChargeObj = {}
  const invoiceLines = get(data, 'invoiceLines', [])
  for (const invoiceLine of invoiceLines) {
    const locationId = get(invoiceLine, 'locationId')
    const chargeItem = getChargeItemFromInvoiceLine(invoiceLine)
    updateLocationIdToChargeObjList(locationIdToChargeObj, locationId, chargeItem)
  }
  return locationIdToChargeObj
}

const getSubtotalByLocation = locationIdToChargeList => {
  const subtotalByLocation = {}
  for (const [locationId, chargeList] of Object.entries(locationIdToChargeList)) {
    let subtotal = 0
    for (const charge of chargeList) {
      const totalWithSubcharges = get(charge, 'totalWithSubcharges', '0')
      subtotal += Number.parseFloat(totalWithSubcharges)
    }
    subtotalByLocation[locationId] = subtotal
  }
  return subtotalByLocation
}

export const useFetchChargeList = (loadingTimeout = 3000) => {
  const dispatch = useDispatch()
  const previewPath = useSelector(state => state.createInvoicesSlice.preview.previewPath)
  const previewDocumentId = useSelector(state => state.createInvoicesSlice.preview.previewDocumentId)
  const docIdList = useSelector(state => state.createInvoicesSlice.previewChargeDrawer.docIdList, shallowEqual)
  const prefilledDocIdList = useSelector(state => state.createInvoicesSlice.preview.prefilledDocIdList, shallowEqual)
  const [unSubList, setUnSubList] = useState([])
  const [fetchedItemsCount, setFetchedItemsCount] = useState(0)

  const updateDocIdToChargeMapItem = ({ docId, data }) => {
    const isLoading = get(data, 'totalCents', null) === null
    const totalCents = get(data, 'totalCents', null)
    const accountNumber = get(data, 'account.accountNumber')
    const accountName = get(data, 'account.accountName', '')
    const total = formatCentsToDollars(totalCents)
    const locationIdToChargeList = getLocationIdToChargeList(data)
    const subtotalByLocation = getSubtotalByLocation(locationIdToChargeList)
    const locationListOfChargeList = Object.values(locationIdToChargeList)
    const chargeList = locationListOfChargeList.flat()
    const subchargeLenghtList = chargeList.map(chargeItem => get(chargeItem, 'subChargeList', []).length)
    const chargesCount = subchargeLenghtList.reduce((accumulator, subchargeLength) => accumulator + subchargeLength + 1, 0)
    const poLenghtList = chargeList.map(chargeItem => filterValidPO(get(chargeItem, 'workOrders', [])).length)
    const poCount = calculateSum(poLenghtList)
    dispatch(
      updateCreateInvoicesDocIdToChargeMapItem({
        docId,
        value: {
          id: docId,
          total,
          accountNumber,
          subtotalByLocation,
          locationListOfChargeList,
          accountName,
          isLoading,
          expanded: false,
          height: 98,
          chargesCount,
          locationsCount: locationListOfChargeList.length,
          poCount,
        },
      })
    )
    if (isLoading) {
      setTimeout(() => {
        dispatch(
          updateCreateInvoicesDocIdToChargeMapItem({
            docId,
            value: {
              isLoading: false,
            },
          })
        )
      }, loadingTimeout)
    }
  }

  const getNextQuery = (startAfterVal, limitVal) => {
    if (startAfterVal) {
      return query(
        collection(db, `${previewPath}/${previewDocumentId}`, 'accounts'),
        orderBy('accountId'),
        limit(limitVal),
        startAfter(startAfterVal)
      )
    }
    return query(collection(db, `${previewPath}/${previewDocumentId}`, 'accounts'), orderBy('accountId'), limit(limitVal))
  }

  const resetState = () => {
    dispatch(resetCreateInvoicesPreviewChargeDrawerState())
    dispatch(resetCreateInvoicesPreviewData())
    unSubList.forEach(unSub => {
      unSub()
    })
    setFetchedItemsCount(0)
    setUnSubList([])
  }
  const loadMoreRows = async ({ startIndex, stopIndex }) => {
    // ignore duplicated request
    if (startIndex in docIdList && stopIndex in docIdList) return Promise.resolve()
    let startAfterVal
    let limitVal
    // initial range subscription
    if (startIndex === 0 && fetchedItemsCount === 0) {
      limitVal = stopIndex - startIndex + 1
      setFetchedItemsCount(limitVal)
    }
    // not initial range subscription
    else if (!(stopIndex in docIdList) && stopIndex >= fetchedItemsCount) {
      startAfterVal = prefilledDocIdList[fetchedItemsCount - 1]
      limitVal = stopIndex - fetchedItemsCount + 1
      setFetchedItemsCount(limitVal)
    }
    // ignore request because is duplicated
    else {
      return Promise.resolve()
    }
    const nextQuery = getNextQuery(startAfterVal, limitVal)
    return new Promise(resolve => {
      const unSub = onSnapshot(
        nextQuery,
        queryAccountsSnapshot => {
          queryAccountsSnapshot.docChanges().forEach(change => {
            if (change.type === 'added' || change.type === 'modified') {
              updateDocIdToChargeMapItem({ docId: change.doc.id, data: change.doc.data() })
            }
          })
          setUnSubList([...unSubList, unSub])
          resolve()
        },
        error => {
          resetState()
          handleError(error)
        }
      )
    })
  }

  useEffect(() => {
    resetState()
    return () => {
      resetState()
    }
  }, [])

  return { loadMoreRows, onClose: resetState, isLoadingFirstBatch: fetchedItemsCount === 0 }
}
