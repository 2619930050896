import { differenceInMonths, differenceInWeeks, differenceInYears, formatDistance } from 'date-fns'
import { get } from './lodash'

const NOTIFICATIONS = new Map([
  ['ENABLE', { color: '#128f4d', icon: 'Bell' }],
  ['PARTIAL', { color: '#ffb732', icon: 'Bell' }],
  ['DISABLE', { color: '#aa2b2b', icon: 'Bell-Off' }],
  ['DEFAULT', { color: '', icon: '' }],
])

export const getNotificationIcon = (type, method) => {
  const lowerCaseType = type ? type.toLowerCase() : ''
  const serviceNotifications = get(method, 'serviceNotifications', false)
  const billingNotifications = get(method, 'billingNotifications', false)

  if (lowerCaseType !== 'email') {
    return serviceNotifications ? NOTIFICATIONS.get('ENABLE') : NOTIFICATIONS.get('DISABLE')
  }

  if (serviceNotifications && billingNotifications) {
    return NOTIFICATIONS.get('ENABLE')
  }

  if (serviceNotifications || billingNotifications) {
    return NOTIFICATIONS.get('PARTIAL')
  }

  if (!serviceNotifications || !billingNotifications) {
    return NOTIFICATIONS.get('DISABLE')
  }

  return NOTIFICATIONS.get('DEFAULT')
}

export const groupNotificationsByDate = (notificationList, groupBy = 'day') => {
  const notificationDateGroups = new Map()
  const currentDate = new Date()
  for (const notification of notificationList) {
    const { date } = notification
    let key
    if (groupBy === 'day') {
      key = `${formatDistance(date, currentDate)} ago`
    } else if (groupBy === 'week') {
      const differenceInWeeksResult = Math.floor(differenceInWeeks(date, currentDate))
      key = differenceInWeeksResult === 0 ? 'This week' : `${differenceInWeeksResult} week${differenceInWeeksResult > 1 ? 's' : ''} ago`
    } else if (groupBy === 'month') {
      const differenceInMonthsResult = Math.floor(differenceInMonths(date, currentDate))
      key =
        differenceInMonthsResult === 0 ? 'This month' : `${differenceInMonthsResult} month${differenceInMonthsResult > 1 ? 's' : ''} ago`
    } else if (groupBy === 'year') {
      const differenceInYearsResult = Math.floor(differenceInYears(date, currentDate))
      key = differenceInYearsResult === 0 ? 'This year' : `${differenceInYearsResult} year${differenceInYearsResult > 1 ? 's' : ''} ago`
    } else {
      throw Error('Invalid groupBy param provided, value should be day, week, month or year')
    }
    if (!notificationDateGroups.has(key)) {
      notificationDateGroups.set(key, [notification])
    } else {
      notificationDateGroups.get(key).push(notification)
    }
  }
  return notificationDateGroups
}
