import React from 'react'
import PropTypes from 'prop-types'

import HHTablePagination from 'components/common/HHTablePaginationV5'
import HHPagination from 'components/common/HHPaginationV5'
import { Box, Grid } from '@mui/material'

import { memo } from 'utils/react'
import { PAGINATION } from 'settings/constants/pagination'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION

const BillingCommonPagination = ({
  page = INITIAL_PAGE,
  rowsPerPage = ROWS_PER_PAGE,
  totalTableRowsCount = 0,
  totalPageCount = 0,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
}) => {
  return (
    <>
      {totalTableRowsCount > 0 && (
        <Grid
          container
          sx={{
            height: 48,
            backgroundColor: 'background.default',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: 'border.light',
            overflow: 'hidden',
            mt: 0.5,
          }}
        >
          <Grid item container xs={6} justifyContent="flex-start" pl={2}>
            <HHTablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalTableRowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => onPageChange(newPage)}
              onRowsPerPageChange={onRowsPerPageChange}
              ActionsComponent={params => <Box display="none" {...params} />}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <HHPagination count={totalPageCount} page={page + 1} onChange={(event, newPage) => onPageChange(newPage - 1)} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

BillingCommonPagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
}

export default memo(BillingCommonPagination)
