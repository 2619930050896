import T from 'T'
import { get, sortBy } from 'lodash'
import { formatCentsToDollars, formatDollarsToCents } from 'utils/price'

export const NONE_BUSINESS_LINE_OPTION = {
  label: T.NONE,
  value: '',
}

export const CUSTOM_LATE_FEE_VALUE = 'CUSTOM'

export const CUSTOM_LATE_FEE_OPTION = {
  label: T.CUSTOM,
  value: CUSTOM_LATE_FEE_VALUE,
}

export const IMMEDIATE_LATE_FEE_OPTION = {
  value: 0,
  label: T.IMMEDIATE,
}

export const INDEFINITE_LATE_FEE_OPTION = { value: 0, label: T.INDEFINITE }

export const LATE_FEE_FREQUENCY = {
  ONCE: 'ONCE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  ANNUALLY: 'ANNUALLY',
}

export const LATE_FEE_FREQUENCY_OPTIONS = [
  { value: 'ONCE', label: T.ONCE },
  { value: 'DAILY', label: T.DAILY },
  { value: 'WEEKLY', label: T.WEEKLY },
  { value: 'MONTHLY', label: T.MONTHLY },
  { value: 'QUARTERLY', label: T.QUARTERLY },
  { value: 'ANNUALLY', label: T.ANNUALLY },
]

export const getMeasureUnitLabel = (value, unit = '') => {
  const unitLowerCase = unit.toLowerCase()
  return value === 1 ? unitLowerCase.slice(0, -1) : unitLowerCase
}

const START_DATE_OPTIONS_BY_FREQUENCY = {
  [LATE_FEE_FREQUENCY.DAILY]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [LATE_FEE_FREQUENCY.WEEKLY]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [LATE_FEE_FREQUENCY.MONTHLY]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [LATE_FEE_FREQUENCY.QUARTERLY]: [1, 2, 3, 4],
  [LATE_FEE_FREQUENCY.ANNUALLY]: [1, 2, 3, 4],
}

export const getDurationMeasureUnit = frequency => {
  switch (frequency) {
    case LATE_FEE_FREQUENCY.DAILY:
      return T.DAYS
    case LATE_FEE_FREQUENCY.WEEKLY:
      return T.WEEKS
    case LATE_FEE_FREQUENCY.MONTHLY:
      return T.MONTHS
    case LATE_FEE_FREQUENCY.QUARTERLY:
      return T.QUARTERS
    case LATE_FEE_FREQUENCY.ANNUALLY:
      return T.YEARS
    default:
      return T.DAYS
  }
}

export const getStartOffsetOptions = frequency => {
  const options = START_DATE_OPTIONS_BY_FREQUENCY[frequency] || []
  const measureUnit = getDurationMeasureUnit(frequency)

  return options.map(value => ({
    value,
    label: `${value} ${getMeasureUnitLabel(value, measureUnit)}`,
  }))
}

export const LATE_FEES_BUSINESS_LINES_TABS = {
  ALL_BUSINESS_LINES: 'ALL',
  RESIDENTIAL: 'RESIDENTIAL',
  COMMERCIAL: 'COMMERCIAL',
  ROLL_OFF: 'ROLLOFF',
}

const DURATION_OPTIONS_BY_FREQUENCY = {
  [LATE_FEE_FREQUENCY.DAILY]: [30, 45, 60, 90],
  [LATE_FEE_FREQUENCY.WEEKLY]: [1, 2, 3, 4, 5, 6],
  [LATE_FEE_FREQUENCY.MONTHLY]: [1, 2, 3, 4],
  [LATE_FEE_FREQUENCY.QUARTERLY]: [1, 2, 3, 4],
  [LATE_FEE_FREQUENCY.ANNUALLY]: [1, 2, 3, 4],
}

export const getDurationOptions = frequency => {
  const options = DURATION_OPTIONS_BY_FREQUENCY[frequency] || []
  const measureUnit = getDurationMeasureUnit(frequency)

  return options.map(value => ({
    value,
    label: `${value} ${getMeasureUnitLabel(value, measureUnit)}`,
  }))
}

export const formatDataToPayload = data => {
  const { amount, customDuration, businessLine, customStartOffset, duration, feeName, pricingPeriod, startOffset } = data
  const isPricingPeriodOnce = pricingPeriod === LATE_FEE_FREQUENCY.ONCE
  const isCustomStartOffset = startOffset === 'CUSTOM'
  const isCustomDuration = duration === 'CUSTOM'
  const businessLineId = get(businessLine, 'value')
  return {
    feeName,
    amountCents: formatDollarsToCents(amount),
    pricingPeriod,
    businessLineId,
    ...(isPricingPeriodOnce
      ? { startOffset: 0, duration: 0 }
      : {
          startOffset: isCustomStartOffset ? customStartOffset : startOffset,
          duration: isCustomDuration ? customDuration : duration,
        }),
  }
}

export const transformServerDataToForm = (serverData, businessLineOptions) => {
  const { feeName, amountCents, startOffset, duration, pricingPeriod, businessLineId } = serverData

  const amount = formatCentsToDollars(amountCents, false)
  const pricingPeriodMapped = LATE_FEE_FREQUENCY[pricingPeriod] || LATE_FEE_FREQUENCY.ONCE
  const predefinedStartOffsets = getStartOffsetOptions(pricingPeriodMapped).map(option => option.value)
  const predefinedDurations = getDurationOptions(pricingPeriodMapped).map(option => option.value)
  const isCustomStartOffset = !predefinedStartOffsets.includes(startOffset)
  const isCustomDuration = ![0, ...predefinedDurations].includes(duration)
  const isIndefiniteDuration = duration === 0
  const isImmediateStartOffset = startOffset === 0
  const businessLine = businessLineOptions.find(option => option.value === businessLineId) || NONE_BUSINESS_LINE_OPTION
  return {
    feeName: feeName || '',
    amount: amount || '',
    businessLine,
    pricingPeriod: pricingPeriodMapped,
    startOffset: isCustomStartOffset && !isImmediateStartOffset ? 'CUSTOM' : startOffset,
    customStartOffset: isCustomStartOffset ? startOffset : 1,
    duration: isCustomDuration ? 'CUSTOM' : duration,
    customDuration: isCustomDuration && !isIndefiniteDuration ? duration : 1,
  }
}

export const getLateFeeRowClassName = params => {
  const { row } = params
  if (row?.archived) return 'archived'
}

export const mapActiveBusinessLinesToCategories = businessLines => {
  const hashMap = {}

  businessLines.forEach(line => {
    if (line.active) {
      const category = get(line, 'businessLineIcon', 'RESIDENTIAL').replace('.svg', '').toUpperCase()
      if (['RESIDENTIAL', 'COMMERCIAL', 'ROLLOFF'].includes(category)) {
        hashMap[line.id] = category
      }
    }
  })
  return hashMap
}

export const groupRowsByBusinessLine = (rows, businessLineHashMap) => {
  const groupedRows = {
    RESIDENTIAL: [],
    COMMERCIAL: [],
    ROLLOFF: [],
    ALL: [],
  }
  const sortedRows = sortBy(rows, ['archived', 'feeName'])

  sortedRows.forEach(row => {
    const category = get(businessLineHashMap, get(row, 'businessLineId'))
    if (category) {
      groupedRows[category].push(row)
    }
    groupedRows.ALL.push(row)
  })

  return groupedRows
}
