import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { get } from 'utils/lodash'

const initialState = {
  accountSelectionModel: [],
  paymentSelectionModel: [],
  expansionState: [],
}

const removePaymentDialogSlice = createSlice({
  name: 'RemovePaymentDialog',
  initialState,
  reducers: {
    setAccountSelectionModel(state, action) {
      state.accountSelectionModel = action.payload
    },
    setPaymentSelectionModel(state, action) {
      state.paymentSelectionModel = action.payload
    },
    setExpansionState(state, action) {
      const id = get(action, 'payload.id', '')
      if (action.payload.type === 'add') {
        state.expansionState = uniq([...state.expansionState, id])
      } else {
        const filtered = state.expansionState.filter(expansionId => expansionId !== id)
        state.expansionState = [...filtered]
      }
    },
    resetSelectionModels(state) {
      state.accountSelectionModel = []
      state.paymentSelectionModel = []
      state.expansionState = []
    },
  },
})

export const { setAccountSelectionModel, setPaymentSelectionModel, setExpansionState, resetSelectionModels } =
  removePaymentDialogSlice.actions
export default removePaymentDialogSlice.reducer
