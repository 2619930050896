import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme, Button, Typography, DialogContent, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import T from 'T'
import api from 'api'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { get } from 'utils/lodash'
import { calculateSum } from 'utils/price'
import { handleError } from 'utils/error'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import { useUnpayInvoiceMutation } from 'api/billing/unpayInvoice'
import {
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
  CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { HHAlert } from 'components/common/HHAlert'
import Loader from 'components/common/loader'
import { isCustomerDetailsPage } from 'router/routes'

const TIMEOUT = 4000

const MoveToAccountCreditConfirmation = ({ isOpen = false, accountId = '', paymentNumbers = [], invoiceNumbers = {}, onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [getAccountDetails, { data: accountData, isFetching: isGetAccountDetailsLoading }] = useLazyGetAccountDetailsQuery()
  const [getAccountInvoices, { data: invoiceList, isFetching: isAccountInvoicesLoading }] = useLazyGetAccountInvoicesQuery()
  const [unpayInvoice, { isLoading: isUnpayInvoiceLoading }] = useUnpayInvoiceMutation()

  const invoices = get(invoiceList, 'invoices', []).filter(({ invoiceNumber }) => invoiceNumbers.includes(invoiceNumber))
  const customerCreditBalanceCents = get(accountData, 'customerCreditBalanceCents', 0)
  const amountPaidCents = calculateSum(invoices, 'amountPaidCents')
  const newBalance = customerCreditBalanceCents + amountPaidCents

  const handleRefetch = (timeout = TIMEOUT) => {
    setTimeout(() => {
      if (isCustomerDetailsPage()) {
        dispatch(getCustomerDetails({ accountId }))
      }
      dispatch(
        api.util.invalidateTags([
          CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
          CACHE_TAG_ACCOUNT_INVOICE_LIST,
          CACHE_TAG_ACCOUNT_PAYMENT_LIST,
          CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
          CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
          CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS,
          CACHE_TAG_ACCOUNT_LIST,
          { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
          CACHE_TAG_ACCOUNT_GROUP_DETAILS,
          CACHE_TAG_AGING_GROUP_DETAILS,
          CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        ])
      )
    }, timeout)
  }

  const handleMoveToAccountCredit = async () => {
    const promise = invoices.map(({ id, invoiceNumber }) =>
      unpayInvoice({ invoiceId: id })
        .unwrap()
        .then(() => Promise.resolve({ invoiceNumber, success: true }))
        .catch(() => Promise.resolve({ invoiceNumber, success: false }))
    )

    const response = await Promise.all(promise)
    const invoicesWithError = response.filter(({ success }) => !success)

    if (invoicesWithError.length > 0) {
      toast.error('There was some error. Please try again.')
    } else {
      toast.success(T.MOVE_TO_ACCOUNT_CREDIT_SUCCESS)
    }

    handleRefetch(2000)
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      getAccountDetails({ accountId }).unwrap().catch(handleError)
      getAccountInvoices({ accountId }).unwrap().catch(handleError)
    }
  }, [isOpen])

  return (
    <>
      {(isGetAccountDetailsLoading || isAccountInvoicesLoading) && <Loader />}
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <HHDialogTitle title="Moving payments" onClose={onClose} />
        <DialogContent>
          <DialogContentText>
            {amountPaidCents === 0 && (
              <HHAlert borderColor={theme.palette.info.light} severity="info">
                Payment already moved to account credit
              </HHAlert>
            )}
            {amountPaidCents > 0 && (
              <Box>
                {paymentNumbers.length > 0 && (
                  <>
                    <Typography variant="h5" color="textPrimary" fontWeight={400} mb={1}>
                      {`The following payment${paymentNumbers.length > 1 ? 's' : ''} will be moved to account credit:`}
                    </Typography>
                    {Children.toArray(
                      paymentNumbers.map(paymentNumber => (
                        <Typography variant="h5" color="textPrimary">
                          {paymentNumber}
                        </Typography>
                      ))
                    )}
                  </>
                )}

                <Typography variant="h5" color="textPrimary" fontWeight={400} mt={3}>
                  New account balance: <HHDisplayMoney value={newBalance} />
                </Typography>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <HHDialogActions>
          {amountPaidCents > 0 ? (
            <>
              <CancelButton onClick={onClose} />
              <LoadingButton
                loading={isUnpayInvoiceLoading}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleMoveToAccountCredit}
              >
                Move
              </LoadingButton>
            </>
          ) : (
            <Button size="small" variant="contained" color="primary" onClick={onClose}>
              OK
            </Button>
          )}
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

MoveToAccountCreditConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string,
  paymentNumbers: PropTypes.array,
  invoiceNumbers: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default MoveToAccountCreditConfirmation
