import React, { useEffect, useMemo, useState } from 'react'
import { ResponsiveTimeRange } from '@nivo/calendar'
import { Box, IconButton as MuiIconButton, Stack, styled, SvgIcon } from '@mui/material'
import { ChevronUp as UnstyledChevronUp, ChevronDown as UnstyledChevronDown } from '@styled-icons/heroicons-solid'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { addDays, addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns'
import useTheme from '@mui/material/styles/useTheme'
import subDays from 'date-fns/subDays'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { useLazyGetStopsHeatMapQuery } from 'api/accounts/accountRouting'
import { setRoutingServiceDate } from 'slices/customer/customerDetailsSlice'
import { colorScaleFn, formatHeatmapData } from '../utils'
import RenderTooltip from '../RenderTooltip/RenderTooltip'

const ChevronUp = styled(UnstyledChevronUp)({
  height: 20,
  width: 20,
})
const ChevronDown = styled(UnstyledChevronDown)({
  height: 20,
  width: 20,
})

const MARGIN = { top: 40, right: 40, bottom: 20, left: 40 }

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 24,
  height: 24,
}))

const ThreeMonthsHeapMap = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const [startDate, setStartDate] = useState(startOfMonth(serviceDate))
  const [endDate, setEndDate] = useState(endOfMonth(addMonths(startDate, 2)))
  const formattedStartDate = useMemo(() => formatDateToBEFormatDateFns(startDate), [startDate])
  const formattedEndDate = useMemo(() => formatDateToBEFormatDateFns(endDate), [endDate])
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const accountId = get(customerDetails, 'id')
  const [getStopsHeatMap, { data }] = useLazyGetStopsHeatMapQuery()
  const heatmap = get(data, 'heatmap', {})
  const [graphData, setGraphData] = useState([])

  const handleNextDateClick = () => {
    const newStartDate = addDays(endDate, 1)
    setStartDate(startOfMonth(newStartDate))
    setEndDate(endOfMonth(addMonths(newStartDate, 2)))
  }

  const handlePreviousDateClick = () => {
    const newEndDate = endOfMonth(subDays(startDate, 1))
    setEndDate(newEndDate)
    setStartDate(startOfMonth(subMonths(newEndDate, 2)))
  }

  const onClick = day => {
    const newServiceDate = get(day, 'date')
    dispatch(setRoutingServiceDate(newServiceDate))
  }

  useEffect(() => {
    getStopsHeatMap({
      accountId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
  }, [formattedStartDate, formattedEndDate])

  useEffect(() => {
    const formattedData = formatHeatmapData(heatmap, formatDateToBEFormatDateFns(serviceDate))
    setGraphData(formattedData)
  }, [data, serializedServiceDate])

  return (
    <Box minHeight="420px" position="relative" width="250px" height="100%" display="flex" alignItems="start">
      <Box
        height="420px"
        width="100%"
        position="sticky"
        top="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          '& svg text': {
            ...theme.typography.h6,
            fontWeight: 600,
          },
        }}
      >
        <Stack alignItems="center" sx={{ width: '100%', top: 8, position: 'absolute', zIndex: 2 }}>
          <IconButton onClick={handlePreviousDateClick}>
            <SvgIcon>
              <ChevronUp />
            </SvgIcon>
          </IconButton>
        </Stack>
        <ResponsiveTimeRange
          data={graphData}
          from={startDate}
          to={endDate}
          emptyColor="#eeeeee"
          colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
          minValue={-300}
          margin={MARGIN}
          direction="vertical"
          weekdayLegendOffset={0}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[]}
          weekdayTicks={[]}
          colorScale={colorScaleFn}
          onClick={onClick}
          tooltip={RenderTooltip}
        />
        <Stack alignItems="center" sx={{ width: '100%', bottom: 8, position: 'absolute' }}>
          <IconButton onClick={handleNextDateClick}>
            <SvgIcon>
              <ChevronDown />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Box>
    </Box>
  )
}

ThreeMonthsHeapMap.propTypes = {}

export default ThreeMonthsHeapMap
