export const USER_STATUS = {
  ACTIVATED: 'ACTIVATED',
  PENDING: 'PENDING',
  DEACTIVATED: 'DEACTIVATED',
}

const { ACTIVATED, PENDING, DEACTIVATED } = USER_STATUS

export const getIsActivatedOrPending = status => [ACTIVATED, PENDING].includes(status)

export const getIsPendingOrDeactivated = status => [PENDING, DEACTIVATED].includes(status)

export const getIsPending = status => status === PENDING
