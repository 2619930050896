import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import { TableRow, Typography } from '@mui/material'
import HHDisplayMoney from '../../../common/HHDisplayMoney'

const ChargesTableRow = ({ service, date, charge, quantity, total, isSubCharge = false, isCalledFromBilling = true }) => {
  const isNotSubcharge = !isSubCharge
  const sx = isCalledFromBilling ? { maxWidth: '13rem' } : {}
  return (
    <TableRow>
      <TableCell sx={sx}>
        <Typography fontWeight={isNotSubcharge && '600'} color="textPrimary" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {service}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight={isNotSubcharge && '700'} color="textSecondary">
          {date}
        </Typography>
      </TableCell>
      <TableCell>{isNotSubcharge && <HHDisplayMoney value={charge} formatToDollars={false} />}</TableCell>
      <TableCell>{quantity > 0 && quantity}</TableCell>
      <TableCell align="right">
        <HHDisplayMoney value={total} formatToDollars={false} />
      </TableCell>
    </TableRow>
  )
}

ChargesTableRow.propTypes = {
  service: PropTypes.string,
  date: PropTypes.string,
  charge: PropTypes.string,
  quantity: PropTypes.number,
  total: PropTypes.string,
  isSubCharge: PropTypes.bool,
  isCalledFromBilling: PropTypes.bool,
}

export default ChargesTableRow
