import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'

import { toast } from 'react-toastify'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import { Box, Button, DialogContent, Grid, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import SearchIcon from '@mui/icons-material/Search'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { dataGridHeightFixes } from 'utils/datagrid'
import { putIsLoading } from 'middleware/actions/response'
import { getAccountsInvoicesForDataGrid } from 'data/groups/invoiceGroupsSelectors'
import { useDeleteInvoicesFromGroupMutation } from 'api/groups/invoice/deleteInvoicesFromGroup'
import { setAccountSelectionModel, setInvoiceSelectionModel, resetSelectionModels } from 'slices/groups/AddRemoveInvoiceDialogSlice'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHTextField from 'components/form-fields/v5/HHTextField'
import AccountsDataGrid from './common/AccountsDataGrid'
import InvoicesDataGrid from './common/InvoicesDataGrid'

const RemoveInvoicesFromGroupDialog = ({ isOpen = false, invoices = [], groupId, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const accountApiRef = useGridApiRef()
  const invoiceApiRef = useGridApiRef()
  const [search, setSearch] = useState('')
  const accountSelectionModel = useSelector(state => get(state, 'AddRemoveInvoiceDialog.accountSelectionModel', []), shallowEqual)
  const invoiceSelectionModel = useSelector(state => get(state, 'AddRemoveInvoiceDialog.invoiceSelectionModel', []), shallowEqual)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [deleteInvoicesFromGroup] = useDeleteInvoicesFromGroupMutation()
  const { accountRows, invoiceRows, totalAmountCents, hierarchyIds } = getAccountsInvoicesForDataGrid({ invoices, search })

  const handleRemoveInvoicesFromGroup = () => {
    dispatch(putIsLoading(true))
    deleteInvoicesFromGroup({ invoiceGroupId: groupId, invoiceIds: invoiceSelectionModel })
      .unwrap()
      .then(() =>
        toast.success(invoiceSelectionModel.length > 1 ? T.REMOVE_INVOICES_FROM_GROUP_SUCCESS_MSG : T.REMOVE_INVOICE_FROM_GROUP_SUCCESS_MSG)
      )
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleSelectAll = () => {
    dispatch(setAccountSelectionModel(accountApiRef.current.getAllRowIds()))
    dispatch(setInvoiceSelectionModel(invoiceApiRef.current.getAllRowIds()))
  }

  const handleDeselectAll = () => dispatch(resetSelectionModels())

  const handleSearchChange = event => {
    const { value } = event.target
    setSearch(value)
    handleDeselectAll()
  }

  const handleAccountSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, accountSelectionModel)
    const unselectedIds = difference(accountSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setAccountSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let invoiceIds = []
    currentIds.forEach(id => {
      const rowData = accountApiRef.current.getRow(id)
      invoiceIds = [...invoiceIds, ...rowData.invoiceIds]
    })

    const newInvoices = isAdded ? [...new Set([...invoiceSelectionModel, ...invoiceIds])] : difference(invoiceSelectionModel, invoiceIds)
    dispatch(setInvoiceSelectionModel(newInvoices))
  }

  const handleInvoiceSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, invoiceSelectionModel)
    const unselectedIds = difference(invoiceSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setInvoiceSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let accountIds = []
    currentIds.forEach(id => {
      const rowData = invoiceApiRef.current.getRow(id)
      if (rowData?.accountId) {
        accountIds = [...accountIds, rowData.accountId]
      }
    })

    const newInvoices = isAdded ? [...new Set([...accountSelectionModel, ...accountIds])] : difference(accountSelectionModel, accountIds)
    dispatch(setAccountSelectionModel(newInvoices))
  }

  useEffect(() => {
    if (isOpen) {
      setSearch('')
      handleDeselectAll()
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={T.REMOVE_INVOICE} onClose={onClose} />
      <DialogContent>
        <Grid mt={1} container spacing={1}>
          <Grid item xs={12} md={4} borderRight={isTabletOrMobile ? 0 : 1} borderColor="divider">
            <HHTextField
              sx={{ pr: 1, '& .MuiInputBase-root': { pr: 0 } }}
              fullWidth
              placeholder="Search"
              label="Search"
              deprecatedLabel={false}
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />

            <Box height={isTabletOrMobile ? 250 : 'calc(100vh - 325px)'}>
              <AccountsDataGrid
                ref={accountApiRef}
                rows={accountRows}
                onSelectionModelChange={handleAccountSelectionChange}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box height={isTabletOrMobile ? 500 : '100%'} {...dataGridHeightFixes}>
              <InvoicesDataGrid
                ref={invoiceApiRef}
                rows={invoiceRows}
                hierarchyIds={hierarchyIds}
                totalAmountCents={totalAmountCents}
                onSelectionModelChange={handleInvoiceSelectionChange}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <Button
          disabled={!invoiceSelectionModel.length}
          color="error"
          autoFocus
          size="small"
          variant="contained"
          onClick={handleRemoveInvoicesFromGroup}
        >
          {T.REMOVE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RemoveInvoicesFromGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  invoices: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RemoveInvoicesFromGroupDialog
