import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Menu, MenuItem } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { fixContactLocationAssociation } from 'utils/form-body'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useAddUpdateContactMutation } from 'api/contact/addUpdateContact'
import { useDeactivateContactsMutation } from 'api/contact/deactivateContacts'
import { useDelecteContactMutation } from 'api/contact/delecteContact'
import { ERROR_EXCEPTIONS } from 'settings/error'

import T from 'T'
import Loader from 'components/common/loader'
import HHSwitchIconField from 'components/form-fields/v5/HHSwitchIconField'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const { DATA_INTEGRITY_EXCEPTION } = ERROR_EXCEPTIONS

const ContactsActionMenu = ({ anchorEl, accountId, selectedContact = {}, onEdit, onClose }) => {
  const dispatch = useDispatch()
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  const [failureDialog, setFailureDialog] = useState({ isOpen: false, message: '' })
  const [addUpdateContact, { isLoading: isAddUpdateContactLoading }] = useAddUpdateContactMutation()
  const [deactivateContacts, { isLoading: isDeactivateContactsLoading }] = useDeactivateContactsMutation()
  const [delecteContact, { isLoading: isDeleteContactLoading }] = useDelecteContactMutation()
  const isDeactivated = get(selectedContact, 'deactivated')
  const contactId = get(selectedContact, 'id')

  const handleContactActiveStatus = isChecked => {
    if (isChecked) {
      const fixContactsAssociation = fixContactLocationAssociation([selectedContact])
      const payload = { accountId, contact: { ...get(fixContactsAssociation, '[0]', {}), deactivated: false } }

      addUpdateContact(payload)
        .unwrap()
        .then(() => {
          dispatch(getCustomerDetails({ accountId }))
          toast.success(T.CONTACT_ACTIVATE_SUCCESS)
        })
        .catch(handleError)
        .finally(() => onClose())
      return
    }

    deactivateContacts({ contacts: [{ id: contactId }] })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        toast.success(T.CONTACT_DEACTIVATE_SUCCESS)
      })
      .catch(handleError)
      .finally(() => onClose())
  }

  const handleDeleteConfirm = () => {
    delecteContact({ accountId, contactId })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        toast.success(T.DELETE_CONTACT_SUCCESS_MSG)
      })
      .catch(error => {
        const isDataIntegrityIssue = get(error, 'data.exceptionType') === DATA_INTEGRITY_EXCEPTION
        const errorMessage = get(error, 'data.message')

        if (!isDataIntegrityIssue) {
          handleError(error)
          return
        }

        if (errorMessage.includes('hh_call')) {
          setFailureDialog({ isOpen: true, message: T.DELETE_CONTACT_CALL_FOLLOWUP_FAILURE })
          return
        }

        if (errorMessage.includes('hh_contact')) {
          setFailureDialog({ isOpen: true, message: T.DELETE_CONTACT_ASSOCIATION_FAILURE })
        }
      })
      .finally(() => {
        onClose()
        setIsOpenConfirmDeleteDialog(false)
      })
  }

  return (
    <>
      {(isAddUpdateContactLoading || isDeactivateContactsLoading || isDeleteContactLoading) && <Loader />}
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={onEdit}>{T.EDIT}</MenuItem>
        <MenuItem sx={{ '.MuiFormLabel-root': { color: 'text.primary' } }}>
          <HHSwitchIconField
            type="AccountStatus"
            label={T.ACTIVE_CONTACT}
            isChecked={!isDeactivated}
            onChange={handleContactActiveStatus}
          />
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={() => setIsOpenConfirmDeleteDialog(true)}>
          {T.DELETE_CONTACT}
        </MenuItem>
      </Menu>
      <HHConfirmDialog
        isOpen={isOpenConfirmDeleteDialog}
        confirmTitle={T.DELETE_CONTACT}
        confirmDescription="Are you sure you want to delete this contact?"
        onConfirm={handleDeleteConfirm}
        onClose={() => {
          onClose()
          setIsOpenConfirmDeleteDialog(false)
        }}
      />

      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        isOpen={failureDialog.isOpen}
        showCancelButton={false}
        ConfirmButtonProps={{ color: 'primary' }}
        confirmButtonTitle={T.CLOSE}
        confirmTitle={T.DELETE_CONTACT_FAILURE}
        confirmDescription={failureDialog.message}
        onConfirm={() => setFailureDialog({ isOpen: false, message: '' })}
        onClose={() => setFailureDialog({ isOpen: false, message: '' })}
      />
    </>
  )
}

ContactsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string,
  selectedContact: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ContactsActionMenu
