import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { noop } from 'lodash'
import RouteAvatar from 'components/route-manager/RouteDetailsModal/RouteAvatar/RouteAvatar'
import WorkOrderStatusAvatar from 'containers/new-route-manager/RouteCard/WorkOrderStatusAvatar'
import WeekDayIcon from 'components/customer-details/content/routing/WeekDayIcon'
import RouteSequence from 'components/customer-details/content/routing/RouteSequence'

const ServiceItemSummary = ({
  workOrderStatus,
  sequence,
  stopCount,
  serviceName,
  addressStr,
  routeName,
  day,
  isUnassigned,
  currentStop,
  onClick = noop,
}) => {
  const routeSequence = [sequence, stopCount].filter(Boolean).join('/')

  return (
    <Grid container flexWrap="nowrap" alignItems="center" overflow="hidden">
      <Grid item xs={6}>
        <ListItem disableGutters sx={{ ml: 1 }}>
          <ListItemAvatar sx={{ minWidth: 'unset', mr: 1, height: '24px' }}>
            <Stack justifyContent="center" alignItems="center" height="100%" spacing={1} direction="row">
              {routeSequence && <RouteSequence workOrderStatus={workOrderStatus}>{sequence}</RouteSequence>}
              <WeekDayIcon day={day} variant="active" />
            </Stack>
          </ListItemAvatar>
          <ListItemText
            primary={serviceName}
            primaryTypographyProps={{ noWrap: true }}
            secondary={addressStr}
            secondaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 3 }} />
      <Grid item xs={6}>
        <ListItem
          sx={{ pr: 10 }}
          secondaryAction={
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              <WorkOrderStatusAvatar status={workOrderStatus} />
              <IconButton size="small">
                <MoreVertIcon onClick={onClick} />
              </IconButton>
            </Stack>
          }
        >
          <ListItemAvatar>
            <RouteAvatar name={routeName} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ variant: 'h5', fontWeight: 500, noWrap: true }}
            primary={routeName}
            secondary={!isUnassigned && `Driver's at stop ${currentStop} of ${stopCount}`}
            secondaryTypographyProps={{ variant: 'body1' }}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}

ServiceItemSummary.propTypes = {
  workOrderStatus: PropTypes.string.isRequired,
  currentStop: PropTypes.bool.isRequired,
  sequence: PropTypes.string.isRequired,
  stopCount: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  addressStr: PropTypes.string.isRequired,
  routeName: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  isUnassigned: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

export default ServiceItemSummary
