import React from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { get } from 'lodash'
import SettingsTable from '../../../SettingsTable'

const columns = [
  { name: `${T.YARD} ${T.NAME}`, key: 'name' },
  {
    name: T.ADDRESS,
    key: 'address',
  },
  {
    name: T.STATUS,
    key: 'status',
    align: 'right',
  },
]

const ListYards = ({ yards, isLoading = false, handleEdit }) => {
  const data = yards.map(({ id, name, address, active }) => {
    const line1 = get(address, 'line1', '')
    const state = get(address, 'state', '')
    const city = get(address, 'city', '')
    const country = get(address, 'country', '')
    const addressStr = `${line1} ${state} ${city}, ${country}`
    return {
      id,
      name,
      address: addressStr,
      status: active ? 'Active' : 'Deactivated',
    }
  })
  return <SettingsTable columns={columns} isLoading={isLoading} data={data} onEditRecord={({ id }) => handleEdit(id)} />
}

ListYards.propTypes = {
  yards: PropTypes.array,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ListYards
