import { get } from 'utils/lodash'
import { formatCentsToDollars } from 'utils/price'

export const getTransformDisposalData = tickets => {
  return {
    isTaxable: get(tickets, '[0].isTaxable', false),
    disposalTicketId: get(tickets, '[0].id'),
    ticketNumber: get(tickets, '[0].ticketNumber', ''),
    disposalSiteId: get(tickets, '[0].disposalSiteId', ''),

    materials: get(tickets, '[0].materials', []).map(material => ({
      id: get(material, 'id'),
      disposalSiteMaterialId: get(material, 'disposalSiteMaterial.id'),
      quantity: get(material, 'quantity'),
      measureUnit: get(material, 'disposalSiteMaterial.measureUnit'),
      // If tippingFee modification is allowed then use Line 24 otherwise 25
      // tippingFee: get(material, 'tippingFee'),
      tippingFee: get(material, 'disposalSiteMaterial.tippingFee'),
      unitPriceDollars: formatCentsToDollars(get(material, 'unitPriceCents', 0)),
      total: get(material, 'disposalSiteMaterial.tippingFee', 0) * formatCentsToDollars(get(material, 'unitPriceCents', 0)),
    })),
  }
}

export const isFormInValid = state => {
  const hasEmptyMaterial = get(state, 'materials', []).find(material => !material.disposalSiteMaterialId)
  return !get(state, 'ticketNumber') || !get(state, 'disposalSiteId') || hasEmptyMaterial
}
