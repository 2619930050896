import format from 'date-fns/format'

import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { createDateFromString } from 'utils/date'
import { BILLING_PERIOD_SUPPORTED_VIEW } from 'settings/constants/billing'

const YEAR_FORMAT = 'yyyy'
const MONTH_FORMAT = 'MMMM'
const MONTH_SHORT_FORMAT = 'MMM'
const DAY_FORMAT = 'd'
const MONTH_DAY_FORMAT = `${MONTH_SHORT_FORMAT} ${DAY_FORMAT}`

const { MONTH, QUARTER, BI_ANNUALLY, DEFAULT } = BILLING_PERIOD_SUPPORTED_VIEW

export const getSupportedViewType = (billingProfile = {}) => {
  const { interval, intervalType } = get(billingProfile, 'billingCycle', {})
  const { timing, timingDay } = billingProfile
  const isMonthlyCycle = intervalType === 'Month'
  const isQuarterlyCycle = intervalType === 'Quarter'

  if (timing !== 'Day' || parseInt(timingDay) !== 1) {
    return DEFAULT
  }

  if (interval === 1 && isMonthlyCycle) {
    return MONTH
  }

  if ((interval === 1 && isQuarterlyCycle) || (interval === 3 && isMonthlyCycle)) {
    return QUARTER
  }

  if ((interval === 2 && isQuarterlyCycle) || (interval === 6 && isMonthlyCycle)) {
    return BI_ANNUALLY
  }

  return DEFAULT
}

const getSupportedViewData = (viewType, period) => {
  const startDate = createDateFromString(get(period, 'startDate'))
  const endDate = createDateFromString(get(period, 'endDate'))
  const invoiceDate = get(period, 'invoiceDate')

  switch (viewType) {
    case MONTH:
      return {
        invoiceDate,
        labelLeft: format(startDate, `${MONTH_FORMAT} ${YEAR_FORMAT}`),
        labelRight: `${format(startDate, MONTH_DAY_FORMAT)} - ${format(endDate, DAY_FORMAT)}`,
      }

    case QUARTER:
      return {
        invoiceDate,
        labelLeft: format(startDate, `QQQ ${YEAR_FORMAT}`),
        labelRight: `${format(startDate, MONTH_DAY_FORMAT)} - ${format(endDate, MONTH_DAY_FORMAT)}`,
      }

    case BI_ANNUALLY:
      return {
        invoiceDate,
        labelLeft: format(startDate, YEAR_FORMAT),
        labelRight: `${format(startDate, MONTH_DAY_FORMAT)} - ${format(endDate, MONTH_DAY_FORMAT)}`,
      }

    default:
      return {
        invoiceDate,
        labelLeft: `${format(startDate, MONTH_SHORT_FORMAT)} - ${format(endDate, `${MONTH_SHORT_FORMAT} ${YEAR_FORMAT}`)}`,
        labelRight: `${format(createDateFromString(invoiceDate), 'do')}`,
      }
  }
}

export const selectBillingProfileById = createSelector([state => state?.billingProfiles, state => state?.id], (billingProfiles = [], id) =>
  billingProfiles.find(profile => profile.id === id)
)

export const getBillingPeriods = createSelector(
  [state => state?.billingProfile, state => state?.billingPeriods],
  (billingProfile, billingPeriods = []) => {
    // If no matched profile and no periods then return []
    if (!billingProfile || !Array.isArray(billingPeriods) || !billingPeriods.length) return []

    const viewType = getSupportedViewType(billingProfile)
    return billingPeriods.map(period => getSupportedViewData(viewType, period))
  }
)
