import React from 'react'
import T from 'T'
import { Box, Card, CardHeader, Divider, Grid } from '@mui/material'
import Summary from './Summary'
import PaymentBatches from './PaymentBatches'
import PaymentGroupStatistics from './PaymentGroupStatistics'

const TopSection = () => {
  return (
    <Box px={3} pt={3} width="100%" pb={0}>
      <Grid container>
        <Grid item xs={12}>
          <Summary />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 3 }} />
        </Grid>
        <PaymentBatches />
        <PaymentGroupStatistics />
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <CardHeader title={T.PAYMENTS} sx={{ bgcolor: 'background.header' }} />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
export default TopSection
