export const DEFAULT_STATE = {
  accountNamePlaceholder: 'Hauler Hero',
  attentionPlaceholder: 'Full Name',
  addressNamePlaceholder: 'Hauler Hero',
  line1Placeholder: '1450 Frazee Rd',
  line2Placeholder: 'apt, suite, PO Box',
  cityPlaceholder: 'San Diego',
  statePlaceholder: 'CA',
  zipCodePlaceholder: '92108',
  latitudePlaceholder: '32.77321',
  longitudePlaceholder: '-117.15920',
}

export const DEFAULT_ADDRESS_FIELD_KEY = 'address'
