import React from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import Box from '@mui/material/Box'
import { memo } from 'utils/react'
import GeneralSettings from './pages/GeneralSettings'
import BusinessLineSettings from './pages/BusinessLineSettings'
import TagSettings from './pages/TagSettings'
import UserSettings from './pages/UserSettings'
import AssetSettings from './pages/AssetSettings'
import ZoneSettings from './pages/ZoneSettings'
import BillingSettings from './pages/BillingSettings'
import CustomerPortalSettings from './pages/CustomerPortalSettings'
import PermitsSettings from './pages/permit/PermitsSettings'

const USERS = ['people', 'roles', 'teams', 'skills']
const ZONES = ['pricing-zones', 'tax-zones', 'customer-zones']
const BILLING = ['general', 'customer-billing-profiles', 'general-ledger']
const BUSINESS_LINES = ['attributes', 'create-configure']
const GENERAL_SETTINGS = ['business-information', 'yards', 'disposal-sites']

const ContentPage = ({ selectedTab = '' }) => {
  return (
    <Box width="100%" pt={2.5}>
      {GENERAL_SETTINGS.includes(selectedTab) && (
        <GeneralSettings selectedTab={selectedTab} generalSettingsOption={startCase(selectedTab)} />
      )}
      {BUSINESS_LINES.includes(selectedTab) && (
        <BusinessLineSettings selectedTab={selectedTab} businessLineOption={startCase(selectedTab)} />
      )}
      {selectedTab === `tags` && <TagSettings />}
      {USERS.includes(selectedTab) && <UserSettings userOption={startCase(selectedTab)} />}
      {selectedTab === `trucks` && <AssetSettings />}
      {ZONES.includes(selectedTab) && <ZoneSettings zoneOption={startCase(selectedTab)} />}
      {BILLING.includes(selectedTab) && <BillingSettings billingOption={startCase(selectedTab)} />}
      {selectedTab === `customer-portal` && <CustomerPortalSettings selectedTab={selectedTab} />}
      {selectedTab === 'permits' && <PermitsSettings />}
    </Box>
  )
}

ContentPage.propTypes = {
  selectedTab: PropTypes.string,
}

export default memo(ContentPage)
