import React, { useCallback, useEffect, useRef } from 'react'
import {
  setPermanentRecurrenceChangeState,
  selectBulkMoveFocusRowIndex,
  selectBulkMoveFocusName,
  selectBulkMoveStopsDate,
  selectIsOpenBulkMoveConfirmCloseDialog,
  selectBulkMoveToRows,
  selectIsSelectDateDialogOpen,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveToSerializedDate,
  selectBulkMoveRoutes,
  selectIsOpenBulkMoveBetweenDaysStopsDialog,
  selectBulkMoveRouteId,
  selectBulkMoveFetchId,
  selectToBeInsertedRowsIds,
  selectBulkMoveStopsToBeAssignedCount,
  setConfirmPermanentMoveDialogState,
  selectIsConfirmPermanentMoveDialogOpen,
  resetBulkState,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DialogContent, Box } from '@mui/material'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import { FormProvider, useForm } from 'react-hook-form'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { deserializeDate, formatDateToBEFormatDateFns, formatDateToFEFormatDateFns, serializeDate } from 'utils/date'
import SelectStopsToMoveMap from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/SelectStopsToMoveMap'
import MoveFromRouteColumn from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/MoveFromRouteColumn'
import MoveToRouteColumn from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/MoveToRouteColumn'
import { get, keyBy } from 'lodash'
import { MapProvider } from 'providers/MapProvider'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useLazyGetRouteStopsGeojsonQuery } from 'api/route/getRouteStops'
import useTheme from '@mui/material/styles/useTheme'
import { isValidHex } from 'utils/validations'
import { common } from '@mui/material/colors'
import SelectDateDialogBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/SelectDateDialogBetweenDaysVariant'
import SequenceDialog from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/SequenceDialog'
import debounce from 'lodash/debounce'
import BulkMoveFailure from 'containers/new-route-manager/BulkMoveFailure'
import { selectBulkMoveFailureStops, selectIsBulkMoveFailureOpen, setBulkMoveFailure } from 'slices/route/routeSlice'
import {
  formatGeojsonDataBetweenDays,
  getBulkMoveStopColumnSx,
  removeLayersAndSources,
  ROW_HEIGHT,
} from 'components/route-manager/BulkActions/settings'
import UnsavedChangesConfirmDialog from 'components/route-manager/BulkActions/common/UnsavedChangesConfirmDialog'
import BulkMoveStopsBetweenDaysDialogActions from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/BulkMoveStopsBetweenDaysDialogActions'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'
import BulkDialogAppbar from 'components/route-manager/BulkActions/common/BulkDialogAppbar'
import ConfirmPermanentMoveDialog from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/ConfirmPermanentMoveDialog/ConfirmPermanentMoveDialog'
import { useBulkChangeDateMutation } from 'api/route/moveStops'
import api from 'api'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import { handleError } from 'utils/error'
import T from 'T'
import useGetBEBatchesBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/useGetBEBatchesBetweenDaysVariant'
import useSplitValidInvalidKeysFromFormValues from 'components/route-manager/BulkActions/common/useSplitValidInvalidKeysFromFormValues'

const defaultValues = {}

const BulkPermanentRecurrenceChangeDialog = () => {
  const confirmPermanentGridApiRef = useGridApiRef()
  const dispatch = useDispatch()
  const isConfirmCloseDialogOpen = useSelector(selectIsOpenBulkMoveConfirmCloseDialog)
  const map = useRef()
  const moveFromGridApiRef = useGridApiRef()
  const moveToGridApiRef = useGridApiRef()
  const open = useSelector(selectIsOpenBulkMoveBetweenDaysStopsDialog)
  const routeId = useSelector(selectBulkMoveRouteId)
  const methods = useForm({
    defaultValues,
  })
  const [bulkChangeDate, { isLoading }] = useBulkChangeDateMutation()
  const [getRouteStopsGeojson] = useLazyGetRouteStopsGeojsonQuery()
  const focusName = useSelector(selectBulkMoveFocusName)
  const focusRowIndex = useSelector(selectBulkMoveFocusRowIndex)
  const moveToRows = useSelector(selectBulkMoveToRows)
  const serializedServiceDate = useSelector(selectBulkMoveStopsDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const theme = useTheme()
  const { reset, watch, getValues, setFocus } = methods
  const [getRoutesMetadata] = useLazyGetRoutesMetadataByServiceDateQuery()
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const routes = useSelector(selectBulkMoveRoutes)
  const bulkMoveFailureStops = useSelector(selectBulkMoveFailureStops)
  const columnSx = getBulkMoveStopColumnSx(theme)
  const fetchId = useSelector(selectBulkMoveFetchId)
  const isConfirmPermanentMoveDialogOpen = useSelector(selectIsConfirmPermanentMoveDialogOpen)
  const isOpenBulkMoveFailure = useSelector(selectIsBulkMoveFailureOpen)
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const toBeInsertedRowsIds = useSelector(selectToBeInsertedRowsIds)
  const splitValidInvalidKeysFromFormValues = useSplitValidInvalidKeysFromFormValues(toBeInsertedRowsIds)

  const handleReset = (isSelectDateDialogOpen = true) => {
    const mapCurrentRef = map.current
    if (!mapCurrentRef) return
    if (fromFEDate) removeLayersAndSources(mapCurrentRef, fromFEDate)
    if (toFEDate) removeLayersAndSources(mapCurrentRef, toFEDate)
    dispatch(
      resetBulkState({
        isSelectDateDialogOpen,
        isOpen: true,
      })
    )
    reset()
  }

  const handleClose = useCallback(() => {
    if (stopsToBeAssignedCount === 0) {
      handleReset(false)
      dispatch(
        setPermanentRecurrenceChangeState({
          isOpen: false,
          serializedServiceDate: serializeDate(new Date()),
        })
      )
    } else {
      dispatch(
        setPermanentRecurrenceChangeState({
          isConfirmCloseDialogOpen: true,
        })
      )
    }
  }, [stopsToBeAssignedCount])

  const getBEBatches = useGetBEBatchesBetweenDaysVariant({
    moveToRows,
    routeId,
    toSerializedDate,
  })

  const onCancelCloseDialog = () => {
    dispatch(
      setPermanentRecurrenceChangeState({
        isConfirmCloseDialogOpen: false,
      })
    )
  }

  const onConfirmCloseDialog = () => {
    handleReset(false)
    dispatch(
      setPermanentRecurrenceChangeState({
        isConfirmCloseDialogOpen: false,
        isOpen: false,
      })
    )
  }

  const onCloseSelectDateDialog = () => {
    dispatch(
      setPermanentRecurrenceChangeState({
        isSelectDateDialogOpen: false,
        serializedServiceDate: serializeDate(new Date()),
      })
    )
  }

  const fetchAndFormatGeojsonData = useCallback(
    ({ routeId, date, markersStateKey, sequenceLineStateKey }) => {
      getRouteStopsGeojson(
        {
          serviceDate: formatDateToBEFormatDateFns(date),
          routes: [routeId],
          id: fetchId,
        },
        false
      )
        .unwrap()
        .then(data => {
          const { stopMarkers, sequenceLine } = formatGeojsonDataBetweenDays(data, formatDateToFEFormatDateFns(date), routeId, theme)
          dispatch(
            setPermanentRecurrenceChangeState({
              [markersStateKey]: stopMarkers,
              [sequenceLineStateKey]: sequenceLine,
            })
          )
        })
    },
    [fetchId]
  )

  const onConfirmSelectDateDialog = ({ fromDate: newFromDate, toDate: newToDate, routeId: newRouteId }) => {
    dispatch(
      setPermanentRecurrenceChangeState({
        isSelectDateDialogOpen: false,
        isOpen: true,
        fromSerializedDate: serializeDate(newFromDate),
        toSerializedDate: serializeDate(newToDate),
        routeId: newRouteId,
        fetchId: crypto.randomUUID(),
        toBeInsertedRowsIds: [],
      })
    )
    getRoutesMetadata({
      serviceDate: formatDateToBEFormatDateFns(newFromDate),
    })
      .unwrap()
      .then(routesMetaData => {
        const routes = get(routesMetaData, 'routes', [])
        const formattedRoutes = routes.map(route => {
          const color = get(route, 'color', common.black)
          const formattedColor = isValidHex(color) ? color : common.black
          const contrastColor = theme.palette.getContrastText(formattedColor)
          return {
            ...route,
            color: formattedColor,
            contrastColor,
          }
        })
        dispatch(
          setPermanentRecurrenceChangeState({
            routesById: keyBy(formattedRoutes, 'id'),
            routes,
          })
        )
        if (newFromDate) {
          fetchAndFormatGeojsonData({
            routeId: newRouteId,
            date: newFromDate,
            markersStateKey: 'moveFromRouteStopMarkers',
            sequenceLineStateKey: 'moveFromRouteSequenceLine',
          })
        }
        if (newToDate) {
          fetchAndFormatGeojsonData({
            routeId: newRouteId,
            date: newToDate,
            markersStateKey: 'moveToRouteStopMarkers',
            sequenceLineStateKey: 'moveToRouteSequenceLine',
          })
        }
      })
  }
  const onCloseBulkMoveFailure = () => {
    dispatch(setBulkMoveFailure({ isOpen: false, stops: [] }))
  }

  const onCloseConfirmPermanentMoveDialog = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: false,
      })
    )
  }

  const onConfirmPermanentMove = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: false,
      })
    )
    const beBatches = getBEBatches(true).map(beBatch => {
      const { serviceDate: targetDate, destinationRouteId, ...restBeBatch } = beBatch
      return { ...restBeBatch, routeId: destinationRouteId, targetDate }
    })
    bulkChangeDate({ moves: beBatches })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        handleReset(false)
        dispatch(
          setPermanentRecurrenceChangeState({
            isOpen: false,
            isUnsavedChangesModalOpen: false,
          })
        )
      })
      .catch(handleError)
  }

  useEffect(() => {
    const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(getValues())
    dispatch(
      setPermanentRecurrenceChangeState({
        invalidStopsToBeAssignedCount: invalidKeys.length,
        stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
        validStopIdsToBeAssigned: validKeys,
      })
    )
  }, [toBeInsertedRowsIds])

  useEffect(() => {
    let timeoutId
    if (focusName) {
      const fixedFocusIndex = focusRowIndex || 0
      setTimeout(() => {
        scrollToSelectedRowInDataGrid(moveToGridApiRef, fixedFocusIndex, ROW_HEIGHT)
        setTimeout(() => {
          if (focusName) {
            setFocus(focusName)
          }
          dispatch(
            setPermanentRecurrenceChangeState({
              focus: {
                name: '',
                rowIndex: 0,
              },
            })
          )
        }, 100)
      }, 100)
    }

    return () => clearTimeout(timeoutId)
  }, [focusName])

  useEffect(() => {
    const debouncedUpdate = debounce(values => {
      const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(values)
      dispatch(
        setPermanentRecurrenceChangeState({
          invalidStopsToBeAssignedCount: invalidKeys.length,
          stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
          validStopIdsToBeAssigned: validKeys,
        })
      )
    }, 1)

    const subscription = watch(values => {
      debouncedUpdate(values)
    })

    return () => {
      debouncedUpdate.cancel()
      subscription.unsubscribe()
    }
  }, [watch, toBeInsertedRowsIds])

  useEffect(() => {
    return () => {
      handleReset(false)
    }
  }, [])

  useEffect(() => {
    dispatch(
      setPermanentRecurrenceChangeState({
        toBeInsertedRowsIds: [],
      })
    )
  }, [routeId, fromSerializedDate, toSerializedDate])

  return (
    <>
      <FormProvider {...methods}>
        <MapProvider map={map}>
          <HHFullScreenBaseDialog open={open} onClose={handleClose}>
            <BulkDialogAppbar title={T.PERMANENT_CHANGE_RECURRENCE_DAY} onClose={handleClose} showDate={false} />
            <DialogContent dividers sx={{ p: 0 }}>
              <Box display="flex" direction="row" flexWrap="nowrap" height="100%" overflow="hidden">
                <Box mt={5} display="flex" flexDirection="column" sx={columnSx}>
                  <MoveFromRouteColumn apiRef={moveFromGridApiRef} fetchAndFormatGeojsonData={fetchAndFormatGeojsonData} />
                </Box>
                <Box mt={5} display="flex" flexDirection="column" sx={{ order: 3, ...columnSx }}>
                  <MoveToRouteColumn apiRef={moveToGridApiRef} routes={routes} fetchAndFormatGeojsonData={fetchAndFormatGeojsonData} />
                </Box>
                <Box flex={1}>{open && <SelectStopsToMoveMap />}</Box>
              </Box>
            </DialogContent>
            <BulkMoveStopsBetweenDaysDialogActions isLoading={isLoading} onClose={handleClose} onReset={handleReset} />
          </HHFullScreenBaseDialog>
          <SequenceDialog moveFromGridApiRef={moveFromGridApiRef} moveToGridApiRef={moveToGridApiRef} />
        </MapProvider>
        <ConfirmPermanentMoveDialog
          isPermanentLoading={isLoading}
          confirmPermanentGridApiRef={confirmPermanentGridApiRef}
          onClose={onCloseConfirmPermanentMoveDialog}
          onConfirm={onConfirmPermanentMove}
          isOpen={isConfirmPermanentMoveDialogOpen}
          DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
        />
      </FormProvider>
      <UnsavedChangesConfirmDialog isOpen={isConfirmCloseDialogOpen} onClose={onCancelCloseDialog} onConfirm={onConfirmCloseDialog} />
      <SelectDateDialogBetweenDaysVariant
        title={T.PERMANENT_CHANGE_RECURRENCE_DAY}
        isPermanent
        isOpen={isSelectDateDialogOpen}
        onConfirm={onConfirmSelectDateDialog}
        onClose={onCloseSelectDateDialog}
        value={serviceDate}
        DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
      />
      <BulkMoveFailure isOpen={isOpenBulkMoveFailure} stops={bulkMoveFailureStops} onClose={onCloseBulkMoveFailure} />
    </>
  )
}

BulkPermanentRecurrenceChangeDialog.propTypes = {}

export default BulkPermanentRecurrenceChangeDialog
