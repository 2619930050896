import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { setWOReviewDisposalTicketsDialog } from 'slices/billing/workOrdersReviewSlice'
import DisposalTicketModal from '../DisposalTicketModal'
import { useWOReviewContext } from './WOReviewDataGrid/WOReviewProvider'

const WithDisposalTicketsDialogForm = ({ children }) => {
  const dispatch = useDispatch()
  const disposalTicketsDialog = useSelector(state => state.workOrdersReview.disposalTicketsDialog, shallowEqual)
  const { woId, isOpen, routeId, configuredServiceId } = disposalTicketsDialog
  const { refreshInnerRows } = useWOReviewContext()
  const handleCloseDisposalModal = () => {
    if (routeId) {
      refreshInnerRows({ routeId })
    }
    dispatch(
      setWOReviewDisposalTicketsDialog({
        isOpen: false,
        woId: '',
        routeId: '',
        configuredServiceId: '',
      })
    )
  }
  return (
    <>
      {children}
      <DisposalTicketModal workOrderId={woId} configuredServiceId={configuredServiceId} open={isOpen} onClose={handleCloseDisposalModal} />
    </>
  )
}

WithDisposalTicketsDialogForm.propTypes = {
  children: PropTypes.node,
}

export default WithDisposalTicketsDialogForm
