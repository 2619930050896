import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'

import { LoadingButton } from '@mui/lab'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { DialogContent, Button, MobileStepper } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getInvoicesByStatus } from 'data/invoice/invoiceSelectors'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import { usePostTransferPaymentMutation } from 'api/billing/postTransferPayment'
import { getCustomerDetails } from 'middleware/actions/customers'
import {
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { isCustomerDetailsPage } from 'router/routes'

import T from 'T'
import api from 'api'
import CancelButton from 'components/buttons/CancelButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import Loader from 'components/common/loader'

import TransferStep from './TransferStep'
import SummaryStep from './SummaryStep'
import { getPaidInvoicesWIthAppliedAmount } from './helper'

const { UNPAID } = INVOICE_STATUS

const FIRST_STEP_INDEX = 0
const TransferPaymentModal = ({ isOpen = false, customerDetails = null, payment = {}, isIntraAccountTransfer = false, onClose = noop }) => {
  const dispatch = useDispatch()
  const customerMeta = useSelector(getAccountMeta, shallowEqual)

  const [postTransferPayment, { isLoading: isLoadingTransferPayments }] = usePostTransferPaymentMutation()
  const [getSourceAccountInvoices, { data: sourceInvoices, isFetching: isLoadingSourceInvoices }] = useLazyGetAccountInvoicesQuery()
  const [getDestinationAccountInvoices, { data: destinationInvoices, isFetching: isLoadingDestinationInvoices }] =
    useLazyGetAccountInvoicesQuery()

  const [activeStep, setActiveStep] = useState(FIRST_STEP_INDEX)
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const isFirstStep = activeStep === FIRST_STEP_INDEX

  const allBillingProfiles = get(customerMeta, 'billingProfiles', [])
  const sourceAccountDetails = {
    accountId: get(customerDetails, 'id'),
    accountName: get(customerDetails, 'accountName'),
    accountNumber: get(customerDetails, 'accountNumber'),
    customerCreditBalanceCents: get(customerDetails, 'customerCreditBalanceCents', 0),
    billingProfileDetails: get(customerDetails, 'accountBilling.billingProfile', {}),
  }
  const destinationAccountDetails = isIntraAccountTransfer
    ? {
        accountId: get(selectedAccount, 'id'),
        accountName: get(selectedAccount, 'accountName'),
        accountNumber: get(selectedAccount, 'accountNumber'),
        billingProfileDetails: allBillingProfiles.find(profile => profile.id === get(selectedAccount, 'billingProfileId')),
      }
    : { ...sourceAccountDetails }

  const { transactionAmountCents, invoices = [] } = payment || {}
  const paidInvoicesInvoiceNumbers = invoices.map(invoice => invoice.invoiceNumber)
  const paidInvoices = getPaidInvoicesWIthAppliedAmount(get(sourceInvoices, 'invoices', []), invoices)

  const invoicesByStatus = getInvoicesByStatus({
    invoices: get(isIntraAccountTransfer ? destinationInvoices : sourceInvoices, 'invoices', []),
  })
  const unPaidInvoices = get(invoicesByStatus, UNPAID, []).filter(invoice => !paidInvoicesInvoiceNumbers.includes(invoice.invoiceNumber))

  const handleTransferPaymentSuccess = () => {
    const { accountId } = sourceAccountDetails
    if (isCustomerDetailsPage()) {
      dispatch(getCustomerDetails({ accountId }))
    }
    dispatch(
      api.util.invalidateTags([
        CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
        CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
        { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST, id: accountId },
        CACHE_TAG_ACCOUNT_LIST,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ])
    )
  }

  const handleClose = () => {
    onClose()
    setActiveStep(FIRST_STEP_INDEX)
    setSelectedInvoice(null)
    setSelectedAccount(null)
  }

  const handleNext = () => {
    if (isFirstStep) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      return
    }

    postTransferPayment({
      paymentNumber: payment.paymentNumber,
      targetAccount: destinationAccountDetails.accountId,
      targetInvoice: selectedInvoice.invoiceNumber,
    })
      .unwrap()
      .then(() => {
        toast.info(T.PAYMENT_TRANSFER_MSG, {
          hideProgressBar: false,
          autoClose: 4000,
          onClose: () => handleTransferPaymentSuccess(),
        })

        handleClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      getSourceAccountInvoices({ accountId: sourceAccountDetails.accountId }).unwrap().catch(handleError)
    }
  }, [isOpen])

  useEffect(() => {
    const selectedAccountId = get(selectedAccount, 'id')
    if (selectedAccountId) {
      getDestinationAccountInvoices({ accountId: selectedAccountId }).unwrap().catch(handleError)
    }
  }, [selectedAccount])

  return (
    <>
      {(isLoadingSourceInvoices || isLoadingDestinationInvoices) && <Loader />}
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose} disableScrollLock>
        <HHDialogTitle title={isIntraAccountTransfer ? T.TRANSFER_TO_ACCOUNT : T.TRANSFER_TO_INVOICE} onClose={handleClose} />
        <DialogContent>
          {isFirstStep && (
            <TransferStep
              isIntraAccountTransfer={isIntraAccountTransfer}
              payment={payment}
              selectedInvoice={selectedInvoice}
              selectedAccount={selectedAccount}
              unPaidInvoices={unPaidInvoices}
              paidInvoices={paidInvoices}
              handleInvoiceChange={invoice => setSelectedInvoice(invoice)}
              handleAccountChange={account => setSelectedAccount(account)}
              sourceAccountDetails={sourceAccountDetails}
              destinationAccountDetails={destinationAccountDetails}
            />
          )}

          {!isFirstStep && (
            <SummaryStep
              isIntraAccountTransfer={isIntraAccountTransfer}
              paidInvoices={paidInvoices.filter(invoice => !invoice.isAccountCredit)}
              selectedInvoice={selectedInvoice}
              transactionAmountCents={transactionAmountCents}
              sourceAccountDetails={sourceAccountDetails}
              destinationAccountDetails={destinationAccountDetails}
            />
          )}
        </DialogContent>
        <HHDialogActions sx={{ px: 2, py: 1, display: 'block', border: '1px solid', borderColor: 'border.light' }}>
          <MobileStepper
            sx={{ background: 'none' }}
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            backButton={
              isFirstStep ? (
                <CancelButton size="medium" onClick={handleClose} />
              ) : (
                <Button
                  startIcon={<ArrowBackIcon />}
                  size="small"
                  variant="outlined"
                  onClick={() => setActiveStep(FIRST_STEP_INDEX)}
                  sx={{ mr: 1 }}
                >
                  Go back
                </Button>
              )
            }
            nextButton={
              <LoadingButton
                loading={isLoadingTransferPayments}
                size="small"
                disabled={!selectedInvoice}
                variant="contained"
                onClick={handleNext}
                endIcon={isFirstStep && <ArrowForwardIcon />}
              >
                {isFirstStep ? 'Next' : 'Transfer'}
              </LoadingButton>
            }
          />
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

TransferPaymentModal.propTypes = {
  isOpen: PropTypes.bool,
  customerDetails: PropTypes.object,
  payment: PropTypes.object,
  isIntraAccountTransfer: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default TransferPaymentModal
