import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    addGeneralLedger: builder.mutation({
      query: body => ({ url: '/api/v1/core/general-ledger-accounts', method: 'POST', body }),
      transformResponse: data => data,
    }),
  }),
})

export const { useAddGeneralLedgerMutation } = extendedApi
