import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

const PreviewField = ({
  fieldName = '',
  fieldValue = '',
  showPlaceholder = false,
  placeholderValue = '',
  defaultValue = '--',
  ...rest
}) => {
  return (
    <>
      <Typography variant="body" noWrap color="textSecondary">
        {fieldName}
      </Typography>
      <Typography
        variant="h6"
        fontWeight={400}
        sx={{ opacity: fieldValue ? 1 : 0.38, wordBreak: 'break-word' }}
        color={fieldValue ? 'textPrimary' : 'textSecondary'}
        {...rest}
      >
        {showPlaceholder ? fieldValue || placeholderValue : fieldValue || defaultValue}
      </Typography>
    </>
  )
}

PreviewField.propTypes = {
  fieldName: PropTypes.string,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  showPlaceholder: PropTypes.bool,
  placeholderValue: PropTypes.string,
  defaultValue: PropTypes.string,
}

export default PreviewField
