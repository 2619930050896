const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  classes: { list: 'ma0 pa0' },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 140,
      backgroundColor: '#ffffff',
      border: '1px solid #eeeeee',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08)',
      borderRaduis: '4px',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
}

const options = ['Service', 'General Fee']

export { MenuProps, options }
