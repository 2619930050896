import { createSlice } from '@reduxjs/toolkit'

const contactsSlice = createSlice({
  name: 'ContactsSlice',
  initialState: {
    addContact: {
      isOpen: false,
      accountId: '',
      initialFirstName: '',
      initialLastName: '',
      initialMethodValue: '',
      parent: '',
    },
  },
  reducers: {
    setAddContactState: (state, action) => {
      state.addContact = action.payload
    },
  },
})
export const { setAddContactState } = contactsSlice.actions

export default contactsSlice.reducer
