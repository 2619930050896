import React, { Children } from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { PlusCircle } from '@styled-icons/heroicons-solid/PlusCircle'
import { XCircle } from '@styled-icons/heroicons-solid/XCircle'

import { NumericFormat } from 'react-number-format'

import { CommonSelect } from 'components/common'
import { MEASURE_OPTIONS } from 'components/pricing/settings'

const TiersSection = ({ index, fee, tierCount, onHandleTiersChange, handleTiers, onHandleMeasureChange, type }) => {
  return (
    <>
      {Children.toArray(
        fee.tiers.map((tier, indx) => {
          return (
            <div className={`tiers ${indx > 0 ? 'mt2' : ''}`}>
              {tierCount[index] > 0 && (
                <div className="add-tiers">
                  <TextField
                    id="outlined-size-small"
                    className="disabled-tier text-center"
                    value={tier.minVal}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="minVal"
                    disabled
                    InputProps={{
                      onChange: e => onHandleTiersChange(e, fee, indx, type),
                    }}
                  />
                  <div className="dash">-</div>
                  <TextField
                    id="outlined-size-small"
                    value={tier.maxVal === 0 ? '' : tier.maxVal}
                    className={indx === fee.tiers.length - 1 && indx > 0 ? 'disabled-tier text-center' : 'text-center'}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="maxVal"
                    InputProps={{
                      onChange: e => onHandleTiersChange(e, fee, indx, type),
                    }}
                    disabled={indx === fee.tiers.length - 1 && indx > 0}
                  />
                </div>
              )}

              <NumericFormat
                className="ml10 price"
                id="outlined-size-small"
                placeholder="0.00"
                value={tier.price}
                variant="outlined"
                size="small"
                autoComplete="off"
                name="price"
                onChange={e => onHandleTiersChange(e, fee, indx, type)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                customInput={TextField}
                thousandSeparator
                decimalSeparator="."
                decimalScale={2}
              />

              <CommonSelect
                className={`${indx > 0 ? 'opacity0' : ''} common-calls-select measure-select-tier`}
                placeholder="Measure"
                placeholderHidden
                value={fee.measureType}
                options={MEASURE_OPTIONS}
                onChange={e => onHandleMeasureChange(e, fee, type)}
              />

              {indx === 0 && (
                <PlusCircle onClick={() => handleTiers('add', fee, indx, index, type)} className="tiers-img ml10 add-tier-img" />
              )}

              {indx > 0 && (
                <XCircle
                  src="/RemoveTier.svg"
                  onClick={() => handleTiers('remove', fee, indx, index, type)}
                  className="tiers-img ml10 remove-tier-img"
                />
              )}
            </div>
          )
        })
      )}
    </>
  )
}

TiersSection.defaultProps = {
  feeTab: 'general',
  tierCount: {},
  validatedFees: [],
  confServices: [],
  type: 'unique',
  saveConfSettings: false,
  handleClick: null,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getFeeServiceName: null,
  onHandleTiersChange: null,
  handleTiers: null,
  onTableFeeScopeChange: null,
  onHandleMeasureChange: null,
}

TiersSection.propTypes = {
  feeTab: PropTypes.string,
  tierCount: PropTypes.object,
  validatedFees: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  saveConfSettings: PropTypes.bool,
  handleClick: PropTypes.func,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  onHandleTiersChange: PropTypes.func,
  handleTiers: PropTypes.func,
  onTableFeeScopeChange: PropTypes.func,
  onHandleMeasureChange: PropTypes.func,
}

export default TiersSection
