const migrations = {
  0: state => {
    return {
      ...state,
      CustomerDetails: {
        ...state.CustomerDetails,
        workOrders: {
          pagination: {
            size: 10,
          },
        },
      },
    }
  },
  1: state => {
    return {
      ...state,
      Route: {
        ...state.Route,
        bulkAssign: {
          isOpen: false,
          selectedRows: [],
        },
      },
    }
  },
  2: state => {
    return {
      ...state,
      persistentWorkOrdersReview: {
        routeIdList: [],
      },
    }
  },
}

export default migrations
