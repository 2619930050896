import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    removeAccountsFromGroup: build.mutation({
      query: ({ accountGroupId, accounts }) => ({
        url: `/api/v1/core/accounts/groups/${accountGroupId}:remove-accounts`,
        body: { accounts },
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => {
        const { accountGroupId } = arg
        return [
          { type: CACHE_TAG_ACCOUNT_GROUP_DETAILS, id: accountGroupId },
          { type: CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS, id: accountGroupId },
        ]
      },
    }),
  }),
})

export const { useRemoveAccountsFromGroupMutation } = extendedApi
