import api from 'api'
import {
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_SUSPENSION_HISTORY,
  CACHE_TAG_VACATION_HOLD_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
} from 'api/cacheTagTypes'

const TAGS_TO_INVALIDATE_BY_SUSPENSION_MUTATION = [
  CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_HISTORY,
  CACHE_TAG_VACATION_HOLD_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
]

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    unsuspendAccount: builder.mutation({
      query: ({ accountId, rescheduleWorkOrders = false }) => ({
        url: `/api/v1/core/accounts/${accountId}/suspension-holds`,
        method: 'DELETE',
        body: {
          rescheduleWorkOrders,
        },
      }),
      invalidatesTags: (result, error, { accountId }) => [
        ...TAGS_TO_INVALIDATE_BY_SUSPENSION_MUTATION,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        { type: CACHE_TAG_SUSPENSION_HISTORY, id: accountId },
      ],
    }),
    suspendAccount: builder.mutation({
      query: ({ accountId, suspensionType, description, startDate }) => ({
        url: `/api/v1/core/accounts/${accountId}/suspension-holds`,
        method: 'POST',
        body: {
          suspensionType,
          description,
          startDate,
        },
      }),
      invalidatesTags: (result, error, { accountId }) => [
        ...TAGS_TO_INVALIDATE_BY_SUSPENSION_MUTATION,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        { type: CACHE_TAG_SUSPENSION_HISTORY, id: accountId },
      ],
    }),
    unsuspendAccountList: builder.mutation({
      query: body => ({
        url: `/api/v1/core/suspension-holds`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, { accountIds }) => {
        const invalidateTags = []
        for (const accountId of accountIds) {
          invalidateTags.push({ type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId })
          invalidateTags.push({ type: CACHE_TAG_SUSPENSION_HISTORY, id: accountId })
        }
        return [...TAGS_TO_INVALIDATE_BY_SUSPENSION_MUTATION, ...invalidateTags]
      },
    }),
    suspendAccountList: builder.mutation({
      query: body => ({
        url: `/api/v1/core/suspension-holds`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { accountIds }) => {
        const invalidateTags = []
        for (const accountId of accountIds) {
          invalidateTags.push({ type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId })
          invalidateTags.push({ type: CACHE_TAG_SUSPENSION_HISTORY, id: accountId })
        }
        return [...TAGS_TO_INVALIDATE_BY_SUSPENSION_MUTATION, ...invalidateTags]
      },
    }),
    getSuspensionHistory: builder.query({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/suspension-holds`,
      }),
      providesTags: (result, error, { accountId }) => [{ type: CACHE_TAG_SUSPENSION_HISTORY, id: accountId }],
      transformResponse: data => (Array.isArray(data) ? data.toReversed() : []),
    }),
  }),
})

export const {
  useUnsuspendAccountMutation,
  useSuspendAccountMutation,
  useLazyGetSuspensionHistoryQuery,
  useSuspendAccountListMutation,
  useUnsuspendAccountListMutation,
} = extendedApi
