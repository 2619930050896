import { green, red, orange, purple } from '@mui/material/colors'
import { PAYMENT_STATUS } from 'settings/constants/payment'

const { APPROVED, REVERSED, DECLINED, TRANSFERRED, FAILED } = PAYMENT_STATUS

export const PAYMENT_STATUS_COLORS = {
  [APPROVED]: green,
  [REVERSED]: orange,
  [TRANSFERRED]: purple,
  [DECLINED]: red,
  [FAILED]: red,
}
