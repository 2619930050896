import api from 'api'
import Constants from 'Constants'

const { SERVER_PUBLIC_URL } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    sendResetPasswordEmailOkta: build.mutation({
      query: body => ({
        method: 'POST',
        url: `${SERVER_PUBLIC_URL}/api/portal/reset-password`,
        body,
      }),
    }),
    sendResetPasswordEmailGcp: build.mutation({
      query: body => ({
        method: 'POST',
        url: `${SERVER_PUBLIC_URL}/api/v1/portal-user/reset-password`,
        body,
      }),
    }),
  }),
})

export const { useSendResetPasswordEmailOktaMutation, useSendResetPasswordEmailGcpMutation } = extendedApi
