import orderBy from 'lodash/orderBy'
import lowerCase from 'lodash/lowerCase'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { calculateSum } from 'utils/price'
import { isUnPaidInvoice } from 'utils/billing'
import { getAgingBucket } from 'utils/agingBucket'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import { INVOICE_STATUS } from 'settings/constants/billing'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const getInvoicesRevenue = createSelector([state => state?.invoices], (invoices = []) => {
  const totalAmountCents = calculateSum(invoices, 'totalCents')
  const collectedRevenueCents = calculateSum(invoices, 'amountPaidCents')
  const uncollectedRevenueCents = totalAmountCents - collectedRevenueCents
  return { totalAmountCents, collectedRevenueCents, uncollectedRevenueCents }
})

export const getAccountsInvoicesForDataGrid = createSelector(
  [state => state?.invoices, state => state?.search],
  (invoices = [], search = '') => {
    const lowerCaseSearch = lowerCase(search)
    const filteredInvoices = search ? invoices.filter(({ accountName }) => lowerCase(accountName).includes(lowerCaseSearch)) : invoices
    const sortedInvoices = orderBy(
      filteredInvoices,
      [({ accountName }) => lowerCase(accountName), ({ invoiceDate }) => new Date(invoiceDate)],
      ['asc', 'desc']
    )

    const accountRows = {}
    const invoiceByStatus = {
      // Draft
      [PARTIAL]: [],
      [CURRENT]: [],
      [DUE_ONE_THIRTY]: [],
      [DUE_THIRTY_ONE_SIXTY]: [],
      [DUE_SIXTY_ONE_NINETY]: [],
      [DUE_OVER_NINETY]: [],
      [PAID]: [],
    }
    let hierarchyIds = []

    sortedInvoices.forEach(invoice => {
      const { accountId, accountName, accountNumber, accountAddress, id: invoiceId, status, dueDate } = invoice
      const existingAccountObj = accountRows[accountId]

      if (existingAccountObj) {
        accountRows[accountId].invoiceIds.push(invoiceId)
      } else {
        accountRows[accountId] = { accountId, accountName, accountNumber, accountAddress, invoiceIds: [invoiceId] }
      }

      if (isUnPaidInvoice(status)) {
        const agingBucketType = getAgingBucket(dueDate) || CURRENT
        invoiceByStatus[agingBucketType].push({ ...invoice, hierarchy: [agingBucketType, invoiceId] })
      } else {
        invoiceByStatus[status].push({ ...invoice, hierarchy: [status, invoiceId] })
      }
    })

    let invoiceRows = []
    let totalAmountCents = 0

    Object.keys(invoiceByStatus).forEach(status => {
      const currentStatusInvoices = invoiceByStatus[status]
      const currentGroupTotalCents = calculateSum(currentStatusInvoices, 'totalCents')
      if (currentStatusInvoices.length) {
        invoiceRows = [
          ...invoiceRows,
          {
            id: status,
            isParent: true,
            groupName: status,
            hierarchy: [status],
            status: [PARTIAL, PAID].includes(status) ? status : UNPAID,
            totalCents: currentGroupTotalCents,
          },
          ...currentStatusInvoices,
        ]
        totalAmountCents += currentGroupTotalCents
        hierarchyIds = [...hierarchyIds, status]
      }
    })

    return { accountRows: Object.values(accountRows), invoiceRows, totalAmountCents, hierarchyIds }
  }
)

const getDraftInvoices = state => get(state, 'draftList.invoices', [])
const getCurrentInvoices = state => get(state, 'currentList.invoices', [])
const getOneThirtyInvoices = state => get(state, 'oneThirtyList.invoices', [])
const getThirtySixtyInvoices = state => get(state, 'thirtySixtyList.invoices', [])
const getSixtyNinetyInvoices = state => get(state, 'sixtyNinetyList.invoices', [])
const getOverNinetyInvoices = state => get(state, 'overNinetyList.invoices', [])
const getPaidInvoices = state => get(state, 'paidList.invoices', [])

export const getCombinedInvoices = createSelector(
  [
    getDraftInvoices,
    getCurrentInvoices,
    getOneThirtyInvoices,
    getThirtySixtyInvoices,
    getSixtyNinetyInvoices,
    getOverNinetyInvoices,
    getPaidInvoices,
  ],
  (draft, current, oneThirty, thirtySixty, sixtyNinety, overNinety, paid) => [
    ...draft,
    ...current,
    ...oneThirty,
    ...thirtySixty,
    ...sixtyNinety,
    ...overNinety,
    ...paid,
  ]
)
