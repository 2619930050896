import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateGeneralLedger: builder.mutation({
      query: body => ({ url: `/api/v1/core/general-ledger-accounts/${body.id}`, method: 'PUT', body }),
      transformResponse: data => data,
    }),
  }),
})

export const { useUpdateGeneralLedgerMutation } = extendedApi
