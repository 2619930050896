/* eslint-disable no-param-reassign */
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { get } from 'lodash'
import { createDataGridSlice, mapSortRulesToSortModel } from '../generic/createDataGridSlice'
import { INVOICE_PAGINATION } from '../../settings/constants/pagination'
import { handleCommonTableformatFilters, handleCommonTableformatSortBy, handlePrefillResponseModification } from '../../utils/table'
import { filterMapToQueryParams } from '../../utils/LHSbracket'
// eslint-disable-next-line import/no-cycle
import { ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID } from '../../containers/billing/settings'
import { fixDateFilters } from '../generic/fixDateFilters'

const { INITIAL_PAGE, ROWS_PER_PAGE } = INVOICE_PAGINATION

const getInitialState = () => ({
  pagination: {
    page: INITIAL_PAGE,
    pageSize: ROWS_PER_PAGE,
  },
  sorting: {
    sortModel: [],
  },
  columns: {
    columnVisibilityModel: {
      accountNumber: true,
      customerName: true,
      invoiceCount: true,
      balanceCents: true,
      unBilledCents: true,
      customerCreditBalanceCents: true,
      currentCents: true,
      dueOneThirtyCents: true,
      dueThirtyOneSixtyCents: true,
      dueSixtyOneNinetyCents: true,
      dueOverNinetyCents: true,
      line1: false,
      city: false,
      state: false,
      zipCode: false,
      transactionDate: false,
      transactionAmountCents: true,
      paymentType: false,
      billingCycleText: false,
      timing: false,
      businessLinesCount: false,
      pricingZones: false,
      paymentMethod: false,
      paymentTermsText: false,
      lateFeeAmount: false,
      customerProfileName: false,
      tags: false,
      contactName: false,
      contactNumber: false,
      email: false,
      autoPay: false,
      autoPaymentType: false,
      autoPaymentMethod: false,
      autoPaymentExpiry: false,
      accountStatus: true,
    },
  },
  selectionModel: [],
  selectedRows: [],
  savedView: {
    existingFilterList: [],
    activeFilter: '',
    input: '',
    selectedFilterId: '',
    selectedFilter: {},
    isModalOpen: false,
  },
  resetOnUnmount: true,
  filters: {
    allFilters: [],
    isOpenTableFilters: false,
    searchFilters: {},
    sortRules: [],
    refetchFlag: false,
    queryParams: {},
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
  statementDrawer: {
    isOpen: false,
    accountId: '',
  },
})

export const accountsReceivable = createDataGridSlice({
  name: 'accountsReceivable',
  initialState: getInitialState(),
  reducers: {
    setARSelectionModel: (state, action) => {
      const selectionModel = get(action, 'payload.selectionModel', [])
      const rows = get(action, 'payload.rows', [])
      state.selectionModel = selectionModel
      if (selectionModel.length > 0) {
        const selectedARIdsSet = new Set(selectionModel)
        state.selectedRows = rows.filter(({ accountId }) => selectedARIdsSet.has(accountId))
      } else {
        state.selectedRows = []
      }
    },
    setARSavedViewSelectedFilterId: (state, action) => {
      state.savedView.selectedFilterId = action.payload
    },
    setARExistingFilterList: (state, action) => {
      state.savedView.existingFilterList = action.payload
    },
    setARSavedViewInput: (state, action) => {
      state.savedView.input = action.payload
    },
    setARRefetchFlag: (state, action) => {
      state.filters.refetchFlag = action.payload
    },
    setARFiltersAndSortRulesFromSavedView: (state, action) => {
      const newAllFilters = {}
      const newSortBy = {}
      const searchFilters = get(action, 'payload.searchFilters', [])
      const sortRules = get(action, 'payload.sortRules', [])
      if (Array.isArray(searchFilters) && searchFilters.length > 0) {
        searchFilters.forEach(filter => {
          const initialFilter = ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID.find(({ columnName }) => columnName === filter.columnName)
          newAllFilters[filter.columnName] = handlePrefillResponseModification(initialFilter, filter)
        })
        state.filters.allFilters = newAllFilters
        state.filters.queryParams = filterMapToQueryParams(newAllFilters)
        const parsedFilters = handleCommonTableformatFilters(newAllFilters)
        state.filters.searchFilters = fixDateFilters(parsedFilters)
      } else {
        state.filters.queryParams = {}
        state.filters.allFilters = {}
        state.filters.searchFilters = {}
      }
      if (Array.isArray(sortRules) && sortRules.length > 0) {
        sortRules.forEach(sort => {
          newSortBy[sort.columnName] = { columnName: sort.columnName, operation: sort.operation }
        })
        const newSortRules = handleCommonTableformatSortBy(newSortBy)
        state.filters.sortRules = newSortRules
        state.sorting.sortModel = mapSortRulesToSortModel(newSortRules)
      } else {
        state.filters.sortRules = {}
        state.sorting.sortModel = []
      }
      state.filters.refetchFlag = true
    },
    setARSavedViewState: (state, action) => {
      state.savedView = {
        ...state.savedView,
        ...action.payload,
      }
    },
    setResetARStateOnUnmount: (state, action) => {
      state.resetOnUnmount = action.payload
    },
    resetARState: state => {
      if (state.resetOnUnmount) return getInitialState()
      return state
    },
    setStatementDrawerState: (state, action) => {
      state.statementDrawer = action.payload
    },
  },
})

export const {
  setSortModel: setARSortModel,
  setPage: setARPage,
  setPageSize: setARPageSize,
  setColumnVisibilityModel: setARColumnVisibilityModel,
  setFilters: setARFilters,
  setIsOpenTableFilters: setARIsOpenTableFilters,
  setPinnedColumns: setARPinnedColumns,
  setARSelectionModel,
  setARSavedViewSelectedFilterId,
  setARExistingFilterList,
  setARSavedViewInput,
  setResetARStateOnUnmount,
  resetARState,
  setARRefetchFlag,
  setARSavedViewState,
  setARFiltersAndSortRulesFromSavedView,
  setStatementDrawerState,
} = accountsReceivable.actions

export default accountsReceivable.reducer
