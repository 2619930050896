import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    deactivateAccount: build.mutation({
      query: ({ accountId, locations = [] }) => {
        return {
          url: `/api/v1/core/accounts/${accountId}/deactivate`,
          method: 'POST',
          body: {
            locations: Array.isArray(locations) ? locations : [],
          },
        }
      },
    }),
  }),
})

export const { useDeactivateAccountMutation } = extendedApi
