import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, ListItem, ListItemText } from '@mui/material'

import { memo } from 'utils/react'

const ListItemRow = ({ icon, label, activeCount = 0, deactivatedCount = 0 }) => {
  return (
    <Box display="flex" alignItems="center">
      <ListItem sx={{ width: 85 }} disablePadding disableGutters>
        {icon}
        <ListItemText sx={{ ml: 1 }} primaryTypographyProps={{ variant: 'caption' }} primary={label} />
      </ListItem>
      <Typography variant="caption">{` | ${activeCount} active | ${deactivatedCount} deactivated`}</Typography>
    </Box>
  )
}

ListItemRow.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  activeCount: PropTypes.number,
  deactivatedCount: PropTypes.number,
}

export default memo(ListItemRow)
