/* eslint-disable */
import Constants from './Constants'
import EventBus from './EventBus'
import LocalStorage from './LocalStorage'

export default class Util {
  static getUrlVars() {
    var vars = [],
      hash
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=')
      vars.push(hash[0])
      vars[hash[0]] = hash[1]
    }
    return vars
  }

  static getUrlVar(name) {
    return this.getUrlVars()[name]
  }

  static getFramentVar(name) {
    let hash = window.location.hash.substr(1)
    let hashArray = hash.split('&')
    let ret = null
    for (var i = 0; i < hashArray.length; i++) {
      hash = hashArray[i].split('=')
      if (hash[0] === name) {
        ret = hash[1]
        break
      }
    }
    return ret
  }

  static loginInitiated = false
  static initiateAuthentication() {
    if (this.loginInitiated) return

    console.log('logging in...')
    this.loginInitiated = true

    window.location.href = Constants.AUTH_SERVER + '?callback=' + window.location.origin
  }

  static logoutInitiated = false
  static initiateLogout() {
    if (this.logoutInitiated) return

    console.log('logging out...')
    this.logoutInitiated = true
    LocalStorage.removeAccessToken()
    window.location.href = Constants.AUTH_SERVER + 'logout?postLogoutRedirectUrl=' + window.location.origin
  }

  static showMessage(message) {
    EventBus.notifyListeners(EventBus.SHOW_MESSAGE, message)
  }
}
