import React, { useContext } from 'react'
import MoreVert from '@mui/icons-material/MoreVert'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import { LocationsActionsContext } from 'components/locations/LocationsContext'
import FilterListIcon from '@mui/icons-material/FilterList'
import FiltersMenu from 'components/locations/filters/FiltersMenu'
import SortByFilterMenuField from 'components/locations/filters/SortByFilter/SortByFilterMenuField'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { SORT_BY_SEARCH_PARAM_KEY } from 'components/locations/settings'
import CommonSwitch from 'components/common/CommonSwitch'
import { ReactComponent as ArrowUp1To9Icon } from 'assets/ArrowUp1To9.svg'
import { EyeOff } from '@styled-icons/heroicons-outline/EyeOff'
import { Eye } from '@styled-icons/heroicons-outline/Eye'

const LocationsActionsMenu = props => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const { control, watch, setValue } = useFormContext()
  const {
    actionsAnchorEl,
    openActions,
    handleActionsClick,
    handleActionsClose,
    filtersAnchorEl,
    openFilters,
    handleFiltersClick,
    handleFiltersClose,
  } = useContext(LocationsActionsContext)
  const [searchParams, setSearchParams] = useSearchParams()

  const onSortByChange = value => {
    searchParams.set(SORT_BY_SEARCH_PARAM_KEY, value)
    setSearchParams(searchParams)
  }
  const withDeactivated = watch('withDeactivated')
  const withDigits = watch('withDigits')

  const handleDeactivatedClick = () => {
    setValue('withDeactivated', !withDeactivated)
    handleActionsClose()
  }

  const handleShowNumericClick = () => {
    setValue('withDigits', !withDigits)
    handleActionsClose()
  }

  return (
    <>
      <IconButton onClick={handleActionsClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="locations-filters-menu"
        slotProps={{
          paper: {
            variant: 'outlined',
            sx: {
              overflow: 'hidden',
              minWidth: 250,
            },
          },
        }}
        anchorEl={actionsAnchorEl}
        open={openActions}
        onClose={handleActionsClose}
        keepMounted
      >
        <MenuItem onClick={handleDeactivatedClick}>
          <ListItemIcon sx={{ mr: 1 }}>{withDeactivated ? <EyeOff width={24} height={24} /> : <Eye width={24} height={24} />}</ListItemIcon>
          <ListItemText>{withDeactivated ? 'Hide deactivated' : 'Show deactivated'}</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleShowNumericClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <ArrowUp1To9Icon width={24} height={24} />
          </ListItemIcon>
          <ListItemText>Show numeric (0-9)</ListItemText>
          <CommonSwitch isChecked={withDigits} />
        </MenuItem>
        <MenuItem onClick={handleFiltersClick} sx={{ ...(!isTabletOrMobile && { display: 'none' }) }}>
          <ListItemIcon>
            <FilterListIcon />
          </ListItemIcon>
          <ListItemText>Filters</ListItemText>
        </MenuItem>
        <SortByFilterMenuField
          sx={{ ...(!isTabletOrMobile && { display: 'none' }) }}
          name="sortBy"
          control={control}
          onChange={onSortByChange}
          isMenuItemVariant
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      </Menu>
      <FiltersMenu
        open={openFilters}
        anchorEl={filtersAnchorEl}
        onClose={handleFiltersClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  )
}

LocationsActionsMenu.propTypes = {}

export default LocationsActionsMenu
