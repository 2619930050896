import T from 'T'

export const OFFICE_PERMISSION = 100
export const ROAD_PERMISSION = 101

export const EXPAND = 100
export const COMPLETE = 101
export const SKIP = 102
export const NONE = 103

export const NO_ACCESS_PERMISSION = 100
export const READ_PERMISSION = 101
export const UPDATE_PERMISSION = 102
export const FULL_PERMISSION = 103

export const CAPTURE_MAPPINGS = {
  [T.SAFETY]: 'dcSafety',
  [T.DELAY]: 'dcDelay',
  [T.NO_ACCESS]: 'dcNoAccess',
  [T.DAMAGE]: 'dcDamage',
  [T.OVERLOADED]: 'dcOverloaded',
  [T.EXTRA_ITEM]: 'dcExtraItem',
  [T.SPECIAL]: 'dcSpecialItem',
  [T.CONTAMINATION]: 'dcContamination',
  [T.SERVICE_REVIEW]: 'dcServiceReview',
  [T.WEIGHT]: 'dcWeight',
  [T.NO_PAYMENT]: 'dcNoPayment',
  [T.NOT_OUT]: 'dcNotOut',
  [T.CAMERA]: 'dcCamera',
  [T.SIGNATURE]: 'dcSignature',
  [T.NOTE]: 'dcNote',
  [T.SERIAL_NUMBER]: 'dcSerialNumber',
}

export const FUNCTIONS_MAPPINGS = {
  [T.SAFETY]: [T.PHOTO, T.NOTE],
  [T.DELAY]: [T.PHOTO, T.NOTE, T.TIMER],
  [T.NO_ACCESS]: [T.PHOTO, T.NOTE],
  [T.DAMAGE]: [T.PHOTO, T.NOTE, T.SIGNATURE],
  [T.OVERLOADED]: [T.PHOTO, T.NOTE],
  [T.EXTRA_ITEM]: [T.PHOTO, T.NOTE, T.NUMBER],
  [T.SPECIAL]: [T.PHOTO, T.NOTE, T.NUMBER],
  [T.CONTAMINATION]: [T.PHOTO, T.NOTE],
  [T.SERVICE_REVIEW]: [T.PHOTO, T.NOTE],
  [T.WEIGHT]: [T.PHOTO, T.NOTE, T.NUMBER],
  [T.NO_PAYMENT]: [T.PHOTO, T.NOTE, T.NUMBER],
  [T.NOT_OUT]: [T.PHOTO, T.NOTE],
  [T.CAMERA]: [T.PHOTO],
  [T.SIGNATURE]: [T.SIGNATURE],
  [T.NOTE]: [T.NOTE],
  [T.SERIAL_NUMBER]: [T.NUMBER, T.PHOTO],
}

export const EXCEPTIONS = [
  T.SAFETY,
  T.DELAY,
  T.NO_ACCESS,
  T.DAMAGE,
  T.OVERLOADED,
  T.EXTRA_ITEM,
  T.SPECIAL,
  T.CONTAMINATION,
  T.SERVICE_REVIEW,
  T.WEIGHT,
  T.NO_PAYMENT,
  T.NOT_OUT,
  T.CAMERA,
  T.SIGNATURE,
  T.NOTE,
  T.SERIAL_NUMBER,
]

export const DISPLAY_MAPPINGS = {
  [T.SHOW_MILES_ON_ROUTE_LIST]: 'mrcShowMiles',
  [T.PURCHASE_ORDER]: 'mrcPurchaseOrder',
  [T.MATERIAL]: 'mrcMaterial',
  [T.MEASURE]: 'mrcMeasure',
  [T.SERVICE]: 'mrcService',
  [T.SCHEDULE]: 'mrcSchedule',
  [T.SEQUENCE]: 'mrcSequence',
  [T.DATE]: 'mrcDate',
  [T.QTY]: 'mrcQuantity',
}

export const DISPLAY_OPTIONS = [
  T.SHOW_MILES_ON_ROUTE_LIST,
  T.PURCHASE_ORDER,
  T.MATERIAL,
  T.MEASURE,
  T.SERVICE,
  T.SCHEDULE,
  T.SEQUENCE,
  T.DATE,
  T.QTY,
]

export const ACCESS_VALUES = ['', T.NONE, T.VIEW, T.EDIT, T.FULL]

export const PERMISSION_MAPPINGS = {
  [T.CUSTOMER_INFORMATION]: 'customerInformationCrud',
  [T.TRUCK]: 'truckCrud',
  [T.ROUTE]: 'routeCrud',
  [T.AUTO_COMPLETE]: 'autocompleteCrud',
  [T.TIMER]: 'timerCrud',
}

export const ALLOWED_PERMISSIONS = {
  [T.CUSTOMER_INFORMATION]: [NO_ACCESS_PERMISSION, READ_PERMISSION],
  [T.TRUCK]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION],
  [T.ROUTE]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION],
  [T.AUTO_COMPLETE]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION],
  [T.TIMER]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION],
}

export const PERMISSION_TOOLTIP_VALUES = [
  T.VIEW_CONTACT_INFO,
  T.ABILITY_TO_CHANGE_TRUCK,
  T.ABILITY_TO_CHANGE_ROUTE,
  T.ALLOW_TO_AUTOCOMPLETE,
  T.ALLOW_TIMER,
]

export const PERMISSION_RECORDS = [T.CUSTOMER_INFORMATION, T.TRUCK, T.ROUTE, T.TIMER]

export const OFFICE_MAPPING = {
  [T.SETTINGS]: 'settingsCrud',
  [`${T.ROLE}s`]: 'permissionsCrud',
  [T.USER]: 'userCrud',
  [T.RESET_PASSWORD]: 'resetPasswordCrud',
  [T.PRICING_MANAGER]: 'pricingManagerCrud',
  [T.BILLING]: 'billingCrud',
  [T.CUSTOMER_BILLING_PROFILE]: 'customerBillingProfileCrud',
  [`${T.INVOICE}s`]: 'invoicesCrud',
  [T.POST_INVOICES_PAYMENT]: 'postInvoicesPaymentCrud',
  [T.PAYMENTS]: 'paymentsCrud',
  [T.APPLY_PAYMENTS]: 'applyPaymentsCrud',
  [`${T.LOCK} ${T.DATE}`]: 'lockDateCrud',
  [T.SERVICE]: 'serviceCrud',
  [T.CUSTOMER_MANAGER]: 'customerManagerCrud',
  [T.ROUTE_MANAGER]: 'routeManagerCrud',
  [`${T.FOLLOW_UP}s`]: 'followUpCrud',
  [`Any ${T.FOLLOW_UP}`]: 'anyFollowUpCrud',
  [T.DASHBOARD]: 'dashboardCrud',
  [T.REPORTS]: 'reportsCrud',
  [T.GLOBAL_SEARCH]: 'globalSearchCrud',
}

export const OFFICE_ALLOWED_PERMISSIONS = {
  [T.SETTINGS]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [`${T.ROLE}s`]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.USER]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.RESET_PASSWORD]: [NO_ACCESS_PERMISSION, UPDATE_PERMISSION],
  [T.PRICING_MANAGER]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.BILLING]: [NO_ACCESS_PERMISSION, READ_PERMISSION],
  [T.CUSTOMER_BILLING_PROFILE]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [`${T.INVOICE}s`]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.POST_INVOICES_PAYMENT]: [NO_ACCESS_PERMISSION, UPDATE_PERMISSION],
  [T.PAYMENTS]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.APPLY_PAYMENTS]: [NO_ACCESS_PERMISSION, UPDATE_PERMISSION],
  [`${T.LOCK} ${T.DATE}`]: [NO_ACCESS_PERMISSION, UPDATE_PERMISSION],
  [T.SERVICE]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.CUSTOMER_MANAGER]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.ROUTE_MANAGER]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [`${T.FOLLOW_UP}s`]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [`Any ${T.FOLLOW_UP}`]: [READ_PERMISSION, UPDATE_PERMISSION],
  [T.DASHBOARD]: [NO_ACCESS_PERMISSION, READ_PERMISSION],
  [T.REPORTS]: [NO_ACCESS_PERMISSION, READ_PERMISSION, UPDATE_PERMISSION, FULL_PERMISSION],
  [T.GLOBAL_SEARCH]: [NO_ACCESS_PERMISSION, READ_PERMISSION],
}

export const OFFICE_TOOLTIP_TEXT = {
  [T.SETTINGS]: T.ACCESS_MODIFY_SETTINGS,
  [`${T.ROLE}s`]: T.MODIFY_PERMISSIONS_IN_ROLES,
  [T.USER]: T.CREATE_USER_INFO,
  [T.RESET_PASSWORD]: T.RESET_USER_PWD,
  [T.PRICING_MANAGER]: T.CREATE_USER_INFO,
  [T.BILLING]: T.BILLING_INFO,
  [T.CUSTOMER_BILLING_PROFILE]: T.VIEW_CUSTOMER_BILLING_PROFILE,
  [`${T.INVOICE}s`]: T.BILLING_INVOICES,
  [T.POST_INVOICES_PAYMENT]: T.POST_INVOICES_PAYMENTS,
  [T.PAYMENTS]: T.PAYMENT_PERMISSION_NOTE,
  [T.APPLY_PAYMENTS]: T.APPLY_PAYMENTS,
  [`${T.LOCK} ${T.DATE}`]: T.LOCK_DATE_INFO,
  [T.SERVICE]: T.SERVICE_INFO,
  [T.CUSTOMER_MANAGER]: T.CUSTOMER_MANAGER_INFO,
  [T.ROUTE_MANAGER]: T.ROUTE_INFO,
  [`${T.FOLLOW_UP}s`]: T.FOLLOWUPS_INFO,
  [`Any ${T.FOLLOW_UP}`]: T.ANY_FOLLOWUP_INFO,
  [T.DASHBOARD]: T.DASHBOARD_INFO,
  [T.REPORTS]: T.REPORT_INFO,
  [T.GLOBAL_SEARCH]: T.GLOBAL_SEARCH_INFO,
}

export const OFFICE_RECORDS = {
  [T.SETTINGS]: [T.SETTINGS, `${T.ROLE}s`, T.USER, T.RESET_PASSWORD],
  [T.PRICING_MANAGER]: [T.PRICING_MANAGER],
  [T.BILLING]: [T.BILLING, T.CUSTOMER_BILLING_PROFILE, `${T.INVOICE}s`, T.POST_INVOICES_PAYMENT, T.PAYMENTS, T.APPLY_PAYMENTS],
  [T.CUSTOMER_MANAGER]: [T.SERVICE, T.CUSTOMER_MANAGER],
  [T.ROUTE_MANAGER]: [T.ROUTE_MANAGER],
  [T.FOLLOW_UP]: [`${T.FOLLOW_UP}s`],
  [T.REPORTING]: [T.REPORTS, T.GLOBAL_SEARCH],
}

export const LIST_ROLES_HEADER_VALUES = [
  { filter: false, name: T.ROLE_NAME, sort: false },
  { filter: false, name: T.EMPLOYEES, sort: false },
  { filter: false, name: T.LAST_UPDATED, sort: false },
]

export const INITIAL_ROLE_VALUES = {
  active: true,
  allowOfficeLogin: true,
  allowRoadLogin: true,
  profileName: '',
  userType: ROAD_PERMISSION,
  roadTab: T.DISPLAY,

  settingsCrud: NO_ACCESS_PERMISSION,
  permissionsCrud: NO_ACCESS_PERMISSION,
  userCrud: NO_ACCESS_PERMISSION,
  resetPasswordCrud: NO_ACCESS_PERMISSION,
  pricingManagerCrud: NO_ACCESS_PERMISSION,
  billingCrud: NO_ACCESS_PERMISSION,
  customerBillingProfileCrud: NO_ACCESS_PERMISSION,
  invoicesCrud: NO_ACCESS_PERMISSION,
  postInvoicesPaymentCrud: NO_ACCESS_PERMISSION,
  paymentsCrud: NO_ACCESS_PERMISSION,
  applyPaymentsCrud: NO_ACCESS_PERMISSION,
  lockDateCrud: NO_ACCESS_PERMISSION,
  serviceCrud: NO_ACCESS_PERMISSION,
  customerManagerCrud: NO_ACCESS_PERMISSION,
  routeManagerCrud: NO_ACCESS_PERMISSION,
  followUpCrud: NO_ACCESS_PERMISSION,
  anyFollowUpCrud: NO_ACCESS_PERMISSION,
  dashboardCrud: NO_ACCESS_PERMISSION,
  reportsCrud: NO_ACCESS_PERMISSION,
  globalSearchCrud: NO_ACCESS_PERMISSION,

  mrcShowMiles: true,
  mrcPurchaseOrder: true,
  mrcMaterial: true,
  mrcMeasure: true,
  mrcService: true,
  mrcSchedule: true,
  mrcSequence: true,
  mrcDate: true,
  mrcQuantity: true,

  dcSafety: NONE,
  dcDelay: NONE,
  dcNoAccess: NONE,
  dcDamage: NONE,
  dcOverloaded: NONE,
  dcExtraItem: NONE,
  dcSpecialItem: NONE,
  dcContamination: NONE,
  dcServiceReview: NONE,
  dcWeight: NONE,
  dcNoPayment: NONE,
  dcNotOut: NONE,
  dcCamera: NONE,
  dcSignature: NONE,
  dcNote: NONE,
  dcSerialNumber: NONE,

  customerInformationCrud: NO_ACCESS_PERMISSION,
  truckCrud: NO_ACCESS_PERMISSION,
  routeCrud: NO_ACCESS_PERMISSION,
  autocompleteCrud: NO_ACCESS_PERMISSION,
  timerCrud: NO_ACCESS_PERMISSION,
}

export const OFFICE_ROAD_OPTIONS = [
  {
    key: T.OFFICE,
    value: OFFICE_PERMISSION,
  },
  {
    key: T.ROAD,
    value: ROAD_PERMISSION,
  },
]
