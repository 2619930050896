import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import upperCase from 'lodash/upperCase'

import { signInWithEmailAndPassword } from 'firebase/auth'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, Typography, useTheme, InputAdornment, IconButton } from '@mui/material'
import { indigo } from '@mui/material/colors'
import LoadingButton from '@mui/lab/LoadingButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { PASSWORD_MIN_LENGTH } from 'settings/constants/auth'
import { useUpdatePasswordMutation } from 'api/auth/updatePassword'

import T from 'T'
import { HHAlert } from 'components/common/HHAlert'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import { PRIMARY_BUTTON_SX } from './settings'

const CreatePassword = ({ isOpen = false, auth, businessId, userId, hasAccessError = false, onAccountError, onRegisterUser, onClose }) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [updatePassword, { isLoading: isUpdatePasswordLoading }] = useUpdatePasswordMutation()

  const createPasswordForm = useForm({ defaultValues: { password: '' } })
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = createPasswordForm

  const passwordHelperText = get(errors, 'password.message')

  const onSubmitHandler = data => {
    const { password } = data
    const payload = { businessId, userId, password }
    updatePassword(payload)
      .unwrap()
      .then(response => {
        const email = get(response, 'email')
        setIsLoading(true)
        signInWithEmailAndPassword(auth, email, password)
          .then(onRegisterUser)
          .catch(handleError)
          .finally(() => setTimeout(() => setIsLoading(false), 1000))
      })
      .catch(error => {
        const errorMsg = get(error, 'data.message', '').toLowerCase()
        if (errorMsg.includes(`User not found`.toLowerCase())) {
          onAccountError()
          return
        }
        handleError(error)
      })
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      handleSubmit(onSubmitHandler)()
    }
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleMouseUpPassword = event => {
    event.preventDefault()
  }

  const handleChangePasswordVisibility = () => {
    setShowPassword(prev => !prev)
  }

  useEffect(() => {
    if (isOpen) {
      reset({ password: '' })
    }
  }, [isOpen])

  return (
    <Box position="relative">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={hasAccessError ? 'center' : 'space-between'}
        bgcolor="background.paper"
        position="absolute"
        height={200}
        zIndex={theme.zIndex.modal}
        py={2}
        px={3}
        width="100%"
        sx={{ bottom: 0 }}
      >
        {hasAccessError && (
          <>
            <Typography variant="h4">Account activated</Typography>
            <HHAlert borderColor={theme.palette.success.light} severity="success" sx={{ mt: 2 }}>
              Your account was successfully activated. Log in to the tablet using the password you just created.
            </HHAlert>
          </>
        )}

        {!hasAccessError && (
          <FormProvider {...createPasswordForm}>
            <Typography variant="h4">Create password</Typography>
            <HHFormTextField
              sx={{
                my: 3,
                '& fieldset': {
                  borderTop: 'none',
                  borderRight: 'none',
                  borderLeft: 'none',
                },
              }}
              autoFocus
              error={passwordHelperText}
              helperText={T.PASSWORD_LENGTH_MSG}
              control={control}
              rules={{ required: T.CANNOT_BE_EMPTY, minLength: { value: PASSWORD_MIN_LENGTH, message: T.PASSWORD_LENGTH_MSG } }}
              type={showPassword ? 'text' : 'password'}
              name="password"
              label={T.PASSWORD}
              placeholder={T.PASSWORD}
              fullWidth
              deprecatedLabel={false}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleChangePasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box my={2} display="flex" justifyContent="flex-end">
              <Button sx={{ color: indigo[500], fontWeight: 500 }} variant="text" onClick={onClose}>
                {upperCase(T.CANCEL)}
              </Button>
              <LoadingButton
                sx={{ ml: 1, ...PRIMARY_BUTTON_SX }}
                loading={isLoading || isUpdatePasswordLoading}
                size="small"
                variant="contained"
                onClick={handleSubmit(onSubmitHandler)}
              >
                {upperCase(T.CREATE)}
              </LoadingButton>
            </Box>
          </FormProvider>
        )}
      </Box>
    </Box>
  )
}

CreatePassword.propTypes = {
  isOpen: PropTypes.bool,
  auth: PropTypes.object,
  email: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  hasAccessError: PropTypes.bool,
  onAccountError: PropTypes.func.isRequired,
  onRegisterUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CreatePassword
