import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Stack, Typography } from '@mui/material'
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined'
import T from 'T'
import { useFormContext } from 'react-hook-form'
import { noop } from 'lodash'
import { grey } from '@mui/material/colors'

const SearchOptionListHeader = ({ onClick = noop }) => {
  const { watch } = useFormContext()
  const searchInputValue = watch('searchInputValue')
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      bgcolor={grey[100]}
      color={grey[700]}
      my={1}
      mx={1.5}
      px={1}
      py={0.5}
      borderRadius={1}
      onClick={onClick}
    >
      <Grid container wrap="nowrap" alignItems="center">
        <Grid container item xs columnSpacing={1}>
          <Grid item xs="auto">
            <Typography noWrap textAlign="left">
              {`${T.SHOW_ALL_RESULTS}`}
            </Typography>
          </Grid>
          <Grid item xs wrap="nowrap">
            <Typography variant="body1" color="textPrimary" noWrap textAlign="left" fontWeight={600}>
              {searchInputValue}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs="auto" alignItems="center" columnSpacing={0.5}>
          <Grid item>
            <Typography align="right">{T.PRESS_ENTER}</Typography>
          </Grid>
          <Grid item>
            <Stack justifyContent="center" alignItems="center">
              <KeyboardReturnOutlinedIcon fontSize="small" />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

SearchOptionListHeader.propTypes = {
  onClick: PropTypes.func,
}

export default SearchOptionListHeader
