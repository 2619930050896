import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    postBillingAccountApplyCredit: build.mutation({
      query: body => ({ url: '/api/v1/billing/account/receivables/apply-credit', method: 'POST', body }),
    }),
  }),
})

export const { usePostBillingAccountApplyCreditMutation } = extendedApi
