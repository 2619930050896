import React from 'react'
import PropTypes from 'prop-types'
import { Chip, ListItem, ListItemText } from '@mui/material'
import { get } from 'lodash'
import T from 'T'
import MultiSelectItem from '../../form-fields/v5/MultiSelectItem'
import PopupPaper from '../../form-fields/v5/PopupPaper'
import { HHFormAutocompleteField } from '../../form-fields/v5'
import ServiceActionTypeChip from '../../services/ServiceActionTypeChip'

const options = [
  { id: 'Swap', label: 'Swap', value: 'Swap' },
  { id: 'Delivery', label: 'Delivery', value: 'Delivery' },
  { id: 'Dump & Return', label: 'Dump & Return', value: 'Dump & Return' },
  { id: 'Drop', label: 'Drop', value: 'Drop' },
  { id: 'Live load', label: 'Live load', value: 'Live load' },
  { id: 'Reposition', label: 'Reposition', value: 'Reposition' },
  { id: 'Final', label: 'Final', value: 'Final' },
  { id: 'Recurring', label: 'Recurring', value: 'Recurring' },
  { id: 'Collection', label: 'Collection', value: 'Collection' },
  { id: 'Rental', label: 'Rental', value: 'Rental' },
  { id: 'Concierge', label: 'Concierge', value: 'Concierge' },
]

const ActionsAutocompleteFormField = ({
  sx,
  allActionsSelected,
  control,
  error = false,
  helperText,
  label,
  name,
  readOnly = false,
  required = false,
  rules,
  setValue,
  ...rest
}) => {
  const handleToggleSelectAll = () => {
    setValue('allActionsSelected', !allActionsSelected)
    if (!allActionsSelected) {
      setValue(name, [...options])
    } else {
      setValue(name, [])
    }
  }

  const getLocationOptionLabel = option => get(option, 'label')

  const renderLocationOption = ({ className, ...rest }, option, { selected }) => {
    const label = get(option, 'label')
    const isRecurringCollection = label === 'Recurring'
    const action = isRecurringCollection ? 'Collection' : label

    return (
      <MultiSelectItem key={`actions-option-${getLocationOptionLabel(option)}-${option?.id}`} {...rest} selected={selected}>
        <ListItem disabbleGutters disablePadding>
          <ListItemText
            primary={<ServiceActionTypeChip action={action} label={isRecurringCollection ? T.RECURRING_COLLECTION : undefined} />}
          />
        </ListItem>
      </MultiSelectItem>
    )
  }

  const renderLocationTags = (value, getTagProps) => {
    return (
      <>
        {value.map((option, index) => {
          const label = get(option, 'label')
          const isRecurringCollection = label === 'Recurring'
          const action = isRecurringCollection ? 'Collection' : label
          if (index === 0)
            return (
              <>
                {allActionsSelected && <Chip size="small" label={T.ALL_ACTIONS} onDelete={handleToggleSelectAll} />}
                <ServiceActionTypeChip
                  size="small"
                  action={action}
                  label={isRecurringCollection ? T.RECURRING_COLLECTION : undefined}
                  {...getTagProps({ index })}
                />
              </>
            )
          return (
            <ServiceActionTypeChip
              action={action}
              size="small"
              label={isRecurringCollection ? T.RECURRING_COLLECTION : undefined}
              {...getTagProps({ index })}
            />
          )
        })}
      </>
    )
  }

  const onChange = (_, newValue, reason) => {
    const newValueCount = get(newValue, 'length', 0)
    if (reason === 'clear' || reason === 'removeOption') {
      setValue('allActionsSelected', false)
      setValue(name, newValue)
    } else if (reason === 'selectOption' && newValueCount === options.length) {
      setValue('allActionsSelected', true)
      setValue(name, options)
    } else {
      setValue(name, newValue)
    }
  }

  const SelectAllPaperComponent = paperProps => {
    const { children, ...restPaperProps } = paperProps
    return (
      <PopupPaper {...restPaperProps}>
        <MultiSelectItem
          divider
          selected={allActionsSelected}
          onMouseDown={e => {
            e.preventDefault() // prevent blur
            handleToggleSelectAll()
          }}
        >
          Select all
        </MultiSelectItem>
        {children}
      </PopupPaper>
    )
  }

  const isOptionEqualToValue = (option, value) => value.id === option.id

  const handleChange = () => {}

  return (
    <HHFormAutocompleteField
      clearIcon={false}
      sx={sx}
      isOptionEqualToValue={isOptionEqualToValue}
      PaperComponent={SelectAllPaperComponent}
      renderOption={renderLocationOption}
      renderTags={renderLocationTags}
      readOnly={readOnly}
      helperText={helperText}
      rules={rules}
      error={error}
      control={control}
      name={name}
      onChangeMiddleware={handleChange}
      required={required}
      deprecatedLabel={false}
      label={required && label ? `${label} *` : label}
      fullWidth
      options={options}
      multiple
      getOptionLabel={getLocationOptionLabel}
      onChange={onChange}
      ListboxProps={{
        style: {
          maxHeight: '30vh',
          overflow: 'auto',
        },
      }}
      {...rest}
    />
  )
}

ActionsAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  allActionsSelected: PropTypes.bool.isRequired,
}

export default ActionsAutocompleteFormField
