import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, IconButton, Box, Tooltip, Snackbar, InputLabel, FormHelperText, useTheme } from '@mui/material'
import { common, orange } from '@mui/material/colors'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const UniqueUrlDialogContent = ({ url, label = '', description = '' }) => {
  const [isCopied, setisCopied] = useState(false)
  const theme = useTheme()

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(url)
    setisCopied(true)
  }

  return (
    <Box>
      <InputLabel
        position="relative"
        sx={{
          mx: 2,
          px: 0.5,
          top: 14,
          display: 'inline-block',
          background: `linear-gradient(to bottom, ${common.white} 50%, ${orange[50]} 50%)`,
          color: theme.palette.warning.main,
        }}
      >
        {label}
      </InputLabel>
      <Box
        border="1px solid"
        borderColor={theme.palette.warning.main}
        borderRadius={1}
        bgcolor={orange[50]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Tooltip arrow title={url} placement="bottom">
          <Typography sx={{ color: theme.palette.warning.main }} pl={2} py={1} noWrap>
            {url}
          </Typography>
        </Tooltip>
        <IconButton onClick={handleCopyToClipboard}>
          <ContentCopyIcon sx={{ color: theme.palette.warning.main }} />
        </IconButton>
      </Box>
      <FormHelperText sx={{ color: theme.palette.warning.main, ...theme.typography.h6, fontWeight: 500 }}>{description}</FormHelperText>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isCopied}
        onClose={() => setisCopied(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </Box>
  )
}

UniqueUrlDialogContent.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
}

export default UniqueUrlDialogContent
