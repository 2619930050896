import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom-v5-compat'
import { useGetInvoiceGroupDetailsQuery } from 'api/groups/invoice/getInvoiceGroupDetails'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import { Add, MoreVert } from '@mui/icons-material'
import ReorderIcon from 'components/common/ReorderIcon'
import { get } from 'utils/lodash'
import { setShowBillingSideNav } from 'slices/billing/BillingSideNavSlice'
import Loader from 'components/common/loader'
import InvoiceGroupActionMenu from 'components/billing/groups/invoice/InvoiceGroupActionMenu'
import AddInvoicesToGroupDialog from 'components/billing/groups/invoice/add-remove/AddInvoicesToGroupDialog'
import InvoiceGroupPrintableView from 'components/billing/groups/invoice/printable-view'

const InvoiceGroupDetails = ({ children }) => {
  const dispatch = useDispatch()
  const [invoiceGroupAnchorEl, setInvoiceGroupAnchorEl] = useState(null)
  const [isOpenAddInvoicesToGroupDialog, setIsOpenAddInvoicesToGroupDialog] = useState(false)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'))
  const { id } = useParams()
  const { data, isLoading, isFetching } = useGetInvoiceGroupDetailsQuery({ id })
  const groupName = get(data, 'groupName')

  const handleDrawerToggle = () => dispatch(setShowBillingSideNav(!showBillingSideNav))
  const handleActionButtonClick = event => setInvoiceGroupAnchorEl(event.currentTarget)
  const handleCloseActionMenu = () => setInvoiceGroupAnchorEl(false)
  const handleToggleAddInvoiceDialog = () => setIsOpenAddInvoicesToGroupDialog(!isOpenAddInvoicesToGroupDialog)

  return (
    <Box bgcolor="background.paper" height="100%" overflowY="hidden" minWidth="750px">
      {(isLoading || isFetching) && <Loader />}
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <Box pl={2} pr={3} py={1} bgcolor="background.default" borderBottom="1px solid" borderColor="divider">
          <Grid container>
            <Grid container item xs>
              <Grid item container xs={12} alignItems="center">
                <Grid item>{!showBillingSideNav && <ReorderIcon onClick={handleDrawerToggle} />}</Grid>
                <Grid item>
                  <Typography variant="h4" fontWeight="bold">
                    {groupName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Add invoices to this group">
                <IconButton onClick={handleToggleAddInvoiceDialog}>
                  <Add />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleActionButtonClick}>
                <MoreVert />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {children}
      </BillingLayout>
      <InvoiceGroupActionMenu anchorEl={invoiceGroupAnchorEl} selectedGroup={data} onClose={handleCloseActionMenu} />
      {isOpenAddInvoicesToGroupDialog && (
        <AddInvoicesToGroupDialog isOpen={isOpenAddInvoicesToGroupDialog} groupId={id} onClose={handleToggleAddInvoiceDialog} />
      )}
      <InvoiceGroupPrintableView />
    </Box>
  )
}

InvoiceGroupDetails.propTypes = {
  children: PropTypes.node,
}

export default InvoiceGroupDetails
