import React, { useState, Children, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import { Card, Grid, Box, Typography } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import FooterButton from 'components/buttons/FooterButton'
import PlaceIcon from '@mui/icons-material/Place'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import CommonSwitch from 'components/common/CommonSwitch'
import { useLazyGetAddressSuggestListQuery } from 'api/map/getAddressSuggestList'

import T from 'T'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'

const MAX_YARDS_ADDRESS_SHOW = 5
const DEBOUNCE_TIME = 300
const { LNG_LAT_FIX } = MAP_DEFAULT_OPTIONS

const AddYards = ({ yardState = {}, setYardState = noop, handleSave = noop, handleCancel = noop, onHandleNameChange = noop }) => {
  const [showYardsAddressList, setShowYardsAddressList] = useState(false)
  const [yardsAddressList, setYardsAddressList] = useState([])

  const [getAddressSuggestList] = useLazyGetAddressSuggestListQuery()

  const handleAddressList = useCallback(
    debounce(value => {
      getAddressSuggestList({ search: value })
        .unwrap()
        .then(res => {
          setYardsAddressList(get(res, 'suggestions', []))
          setShowYardsAddressList(true)
        })
        .catch(handleError)
    }, DEBOUNCE_TIME),
    []
  )

  const getAddressList = (event, isOptionSelected) => {
    if (isOptionSelected) {
      const streetNumber = get(event, 'streetNumber', '')
      const streetName = get(event, 'streetName', '')
      const line1 = `${streetNumber} ${streetName}`.trim()
      const state = get(event, 'state', '')
      const city = get(event, 'city', '')
      const zipCode = get(event, 'zipCode', '')
      const country = get(event, 'country', '')
      const longitude = get(event, 'center.longitude', '') ? get(event, 'center.longitude', '').toFixed(LNG_LAT_FIX) : ''
      const latitude = get(event, 'center.latitude', '') ? get(event, 'center.latitude', '').toFixed(LNG_LAT_FIX) : ''

      setYardState({
        streetAddress: line1,
        state,
        country,
        city,
        zipCode,
        longitude,
        latitude,
      })
      setShowYardsAddressList(false)
      return
    }
    const { value } = event.target
    onHandleNameChange(event)

    if (!value) {
      setYardsAddressList([])
      setShowYardsAddressList(false)
      return
    }
    handleAddressList(value)
  }

  const handleCloseYardsAddressSuggestions = event => {
    if (!showYardsAddressList || !event.target) {
      return
    }
    // Only check if yardsAddressList is open
    // Disable for UL tag dropdown & self input click
    try {
      if (event.target.className.indexOf('billing autocomplete') > -1 || event.target.getAttribute('placeholder') === T.STREET_ADDRESS) {
        return
      }

      setShowYardsAddressList(false)
    } catch (err) {}
  }
  useEffect(() => {
    document.addEventListener('click', handleCloseYardsAddressSuggestions, true)
    return () => {
      document.removeEventListener('click', handleCloseYardsAddressSuggestions, true)
    }
  })

  return (
    <Card>
      <Box sx={{ p: 3, minHeight: 'calc(100vh - 180px)' }}>
        <Grid container justifyContent="space-between">
          <Grid item xs="7">
            <HHTextField
              label={`${T.YARD} ${T.NAME}`}
              placeholder={`${T.YARD} ${T.NAME}`}
              fullWidth
              name="yardName"
              value={yardState.yardName}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item>
            <CommonSwitch
              titlePosition="right"
              title={T.ACTIVE}
              isChecked={get(yardState, 'active', false)}
              onChange={checked => setYardState({ active: checked, footerFlag: true })}
            />
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={`${T.STREET} ${T.ADDRESS}`}
              placeholder={`${T.STREET} ${T.ADDRESS}`}
              fullWidth
              name="streetAddress"
              value={yardState.streetAddress}
              onChange={event => getAddressList(event, false)}
              onFocus={() => {
                if (yardsAddressList.length) {
                  setShowYardsAddressList(true)
                  return
                }
                setYardsAddressList([])
              }}
            />

            <Box position="relative">
              {showYardsAddressList && yardsAddressList && yardsAddressList.length > 0 && (
                <Card sx={{ maxHeight: 200, position: 'absolute', width: '100%', zIndex: 9 }}>
                  {Children.toArray(
                    yardsAddressList.map((address, index) => {
                      const streetData = `${get(address, 'streetNumber', '')} ${get(address, 'streetName', '')}`.trim()
                      const cityData = get(address, 'city', '')
                      const stateData = get(address, 'state', '')
                      const areaData = cityData && stateData ? `${cityData}, ${stateData}` : cityData || stateData

                      if (index < MAX_YARDS_ADDRESS_SHOW) {
                        return (
                          <Box
                            onMouseDown={() => getAddressList(address, true)}
                            m={1.8}
                            display="flex"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}
                          >
                            <PlaceIcon fontSize="small" sx={{ color: 'text.secondary' }} />
                            <Typography variant="body1" ml={1}>
                              {streetData}
                            </Typography>
                            <Typography variant="subtitle2" ml={1}>
                              {areaData}
                            </Typography>
                          </Box>
                        )
                      }
                    })
                  )}
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={T.CITY}
              placeholder={T.CITY}
              name="city"
              fullWidth
              value={yardState.city}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item xs="4" ml={2}>
            <HHTextField label={T.STATE} placeholder={T.STATE} fullWidth value={yardState.state} onChange={e => onHandleNameChange(e)} />
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={T.ZIP_CODE}
              placeholder={T.ZIP_CODE}
              fullWidth
              name="zipCode"
              value={yardState.zipCode}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item xs="4" ml={2}>
            <HHTextField
              label={T.COUNTRY}
              placeholder={T.COUNTRY}
              fullWidth
              name="country"
              value={yardState.country}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
        </Grid>
      </Box>

      {yardState.footerFlag && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={handleCancel}
            rightButtonTitle={T.SAVE}
            onProceed={handleSave}
            disabledProceed={
              yardState.yardName === '' ||
              yardState.streetAddress === '' ||
              yardState.city === '' ||
              yardState.state === '' ||
              yardState.country === '' ||
              yardState.zipCode === ''
            }
          />
        </Box>
      )}
    </Card>
  )
}

AddYards.propTypes = {
  yardState: PropTypes.object,
  setYardState: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  onHandleNameChange: PropTypes.func,
}

export default AddYards
