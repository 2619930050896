import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { Box, IconButton, Tooltip, useTheme, useMediaQuery, SvgIcon } from '@mui/material'
import { ReactComponent as AccountNoteIcon } from 'assets/note/AccountNote.svg'
import { ReactComponent as InvoiceIcon } from 'assets/Invoice.svg'
import { User } from '@styled-icons/heroicons-outline/User'
import { UserAdd } from '@styled-icons/heroicons-outline/UserAdd'
import { MoreVert } from '@mui/icons-material'

import { getCustomerDetailsPageUrl } from 'router/routes'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'
import {
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { useLazyGetAllAccountNotesQuery } from 'api/notes/accountNotesCrud'

import api from 'api'
import StyledBadge from 'components/notes/common/StyledBadge'
import EventBlocker from 'components/common/EventBlocker'
import Link from 'components/common/Link'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import AccountNotesDialogVariant from 'components/notes/account/AccountNotesDialogVariant'
import AddEditContactDialog from 'components/customer-details/content/contacts/AddEditContactDialog'
import ViewNotesPopover from 'components/notes/common/ViewNotesPopover'
import ViewContactsDialog from './view-contacts/ViewContactsDialog'
import ViewContactsPopover from './view-contacts/ViewContactsPopover'
import AccountCellActionMenu from './AccountCellActionMenu'
import InvoicesPopover from './popover/InvoicesPopover'

const { BILLING_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const RenderAccountRightContentCell = ({ row = {}, isViewOnly = false, showAddToGroup = false, showRemoveFromGroup = false }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const [isOpenAccountNotesDialog, setIsOpenAccountNotesDialog] = useState(false)
  const [isOpenViewContactsDialog, setIsOpenViewContactsDialog] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null)
  const [viewAccountNotesPopoverAnchorEl, setViewAccountNotesPopoverAnchorEl] = useState(null)
  const [viewContactsPopoverAnchorEl, setViewContactsPopoverAnchorEl] = useState(null)
  const [viewInvoicesPopoverAnchorEl, setViewInvoicesPopoverAnchorEl] = useState(null)
  const [isOpenAddContactDialog, setIsOpenAddContactDialog] = useState(false)

  const { accountId, accountName, activeAccountNoteCount = 0, accountContactCount = 0, unpaidInvoiceCount = 0 } = row
  const hasAccountContacts = accountContactCount > 0
  const hasUnpaidInvoices = unpaidInvoiceCount > 0

  const handleClickAccountNotes = event => {
    event.preventDefault()
    setIsOpenAccountNotesDialog(true)
  }

  const handleOpenCreateContactDialog = () => setIsOpenAddContactDialog(true)

  const handleCloseCreateContactDialog = () => setIsOpenAddContactDialog(false)

  const handleClickViewContacts = event => {
    event.preventDefault()
    if (hasAccountContacts) {
      setIsOpenViewContactsDialog(true)
      return
    }
    handleOpenCreateContactDialog()
  }

  const handleClickActionMenu = event => {
    event.preventDefault()
    setActionMenuAnchorEl(event.currentTarget)
  }

  const handleCloseAccountNotesDialog = () => setIsOpenAccountNotesDialog(false)

  const handleCloseViewContactsDialog = () => setIsOpenViewContactsDialog(false)

  const handleCloseActionMenu = () => setActionMenuAnchorEl(false)

  const handleRefetch = () =>
    dispatch(
      api.util.invalidateTags([
        CACHE_TAG_ACCOUNT_LIST,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
      ])
    )

  const onSaveTags = (data, closeTagsDialog) => {
    handleRefetch()
    closeTagsDialog()
  }

  const handleViewAccountNotesPopoverOpen = event => setViewAccountNotesPopoverAnchorEl(event.currentTarget)

  const handleViewAccountNotesPopoverClose = () => setViewAccountNotesPopoverAnchorEl(null)

  const handleViewContactsPopoverOpen = event => setViewContactsPopoverAnchorEl(event.currentTarget)

  const handleViewContactsPopoverClose = () => setViewContactsPopoverAnchorEl(null)

  const handleViewInvoicesPopoverOpen = event => setViewInvoicesPopoverAnchorEl(event.currentTarget)

  const handleViewInvoicesPopoverClose = () => setViewInvoicesPopoverAnchorEl(null)

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="end" mr={isViewOnly && 2}>
        {!isTabletOrMobile && (
          <>
            <EventBlocker>
              <InteractiveTagsList
                GridContainerProps={{ direction: 'row', justifyContent: 'flex-end', alignItems: 'center', spacing: 0.5 }}
                limit={3}
                data={{ ...row, name: accountName, accountId, tagDetails: { forAccount: row.tags } }}
                disableLocation
                disableService
                onSave={onSaveTags}
                isViewOnly={isViewOnly}
              />
            </EventBlocker>
            {!isViewOnly && (
              <IconButton
                onClick={handleClickAccountNotes}
                {...(activeAccountNoteCount > 0 && {
                  onMouseEnter: handleViewAccountNotesPopoverOpen,
                  onMouseLeave: handleViewAccountNotesPopoverClose,
                })}
              >
                <StyledBadge badgeContent={activeAccountNoteCount}>
                  <AccountNoteIcon />
                </StyledBadge>
              </IconButton>
            )}
            {!isViewOnly && (
              <IconButton
                onClick={handleClickViewContacts}
                {...(hasAccountContacts && {
                  onMouseEnter: handleViewContactsPopoverOpen,
                  onMouseLeave: handleViewContactsPopoverClose,
                })}
              >
                <StyledBadge badgeContent={accountContactCount}>
                  <SvgIcon>{hasAccountContacts ? <User width={24} height={24} /> : <UserAdd width={24} height={24} />}</SvgIcon>
                </StyledBadge>
              </IconButton>
            )}
            <Tooltip placement="top" arrow title={!hasUnpaidInvoices && 'This account has no unpaid invoices'}>
              <span>
                <IconButton
                  disabled={!hasUnpaidInvoices}
                  {...(!isViewOnly && { to: `${getCustomerDetailsPageUrl(accountId)}?tab=${BILLING_TAB_ID}`, component: Link })}
                  onMouseEnter={handleViewInvoicesPopoverOpen}
                  onMouseLeave={handleViewInvoicesPopoverClose}
                >
                  <StyledBadge badgeContent={unpaidInvoiceCount}>
                    <InvoiceIcon />
                  </StyledBadge>
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {!isViewOnly && (
          <IconButton onClick={handleClickActionMenu}>
            <MoreVert />
          </IconButton>
        )}
      </Box>
      <EventBlocker preventDefault={false}>
        <AccountNotesDialogVariant
          isOpen={isOpenAccountNotesDialog}
          accountId={accountId}
          getCachedData
          onClose={handleCloseAccountNotesDialog}
        />
        <ViewContactsPopover
          anchorEl={viewContactsPopoverAnchorEl}
          accountId={accountId}
          accountName={accountName}
          onClose={handleViewContactsPopoverClose}
        />
        <ViewContactsDialog
          isOpen={isOpenViewContactsDialog}
          accountId={accountId}
          accountName={accountName}
          getCachedData
          onCreateContact={handleOpenCreateContactDialog}
          onClose={handleCloseViewContactsDialog}
        />
        <AccountCellActionMenu
          anchorEl={actionMenuAnchorEl}
          row={row}
          onSaveTags={onSaveTags}
          onClickAccountNotes={handleClickAccountNotes}
          onClickViewContacts={handleClickViewContacts}
          onClose={handleCloseActionMenu}
          onRefetch={handleRefetch}
          showAddToGroup={showAddToGroup}
          showRemoveFromGroup={showRemoveFromGroup}
        />
        {isOpenAddContactDialog && (
          <AddEditContactDialog isOpen={isOpenAddContactDialog} accountId={accountId} onClose={handleCloseCreateContactDialog} />
        )}
      </EventBlocker>
      <InvoicesPopover anchorEl={viewInvoicesPopoverAnchorEl} accountId={accountId} onClose={handleViewInvoicesPopoverClose} />
      <ViewNotesPopover
        anchorEl={viewAccountNotesPopoverAnchorEl}
        id={accountId}
        useLazyGetAllNotesQuery={useLazyGetAllAccountNotesQuery}
        onClose={handleViewAccountNotesPopoverClose}
      />
    </>
  )
}

RenderAccountRightContentCell.propTypes = {
  row: PropTypes.object,
  isViewOnly: PropTypes.bool,
  showAddToGroup: PropTypes.bool,
  showRemoveFromGroup: PropTypes.bool,
}

export default RenderAccountRightContentCell
