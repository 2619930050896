import React from 'react'
import PropTypes from 'prop-types'

import AutoComplete from 'components/common/AutoComplete'

import T from 'T'

import { get } from 'utils/lodash'

const ActionAutoComplete = ({ globalFilterOptions, checkedServiceAction, handleCheckboxValue, backFlag }) => {
  return (
    <div className="actions left-elements-width">
      <div className="service-label">{`${T.ACTION}s`}</div>
      <AutoComplete
        fullWidth
        backFlag={backFlag}
        isHandleCheckbox
        metaData={get(globalFilterOptions, 'serviceAction', [])}
        label={`${T.ACTION}s`}
        getByLabelText={option => option.actionName}
        handleCheckboxValue={handleCheckboxValue}
        checkedRecords={checkedServiceAction}
        getPlaceholder={checkedServiceAction.length ? '' : `Select ${T.ACTION}s`}
      />
    </div>
  )
}

ActionAutoComplete.defaultProps = {
  globalFilterOptions: {},
  handleCheckboxValue: null,
  checkedServiceAction: [],
  backFlag: false,
}

ActionAutoComplete.propTypes = {
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedServiceAction: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default ActionAutoComplete
