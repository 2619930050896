import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import { ReactComponent as GrappleNew } from 'assets/methods/GrappleNew.svg'
import { get } from 'utils/lodash'
import { METHOD_TO_ICON_MAP } from 'components/common/chip/MethodChip'
import { METHOD_TYPES } from 'settings/constants/methods'

const { GRAPPLE } = METHOD_TYPES

const RenderMethodFilterOption = ({ props, option, selected, onOpenFilterMenu }) => {
  const theme = useTheme()
  const methodName = get(option, 'methodName', '')
  const methodType = get(option, 'methodType', '')
  const methodTypeUpperCase = methodType.toUpperCase()
  const Icon = get(METHOD_TO_ICON_MAP, methodTypeUpperCase, null)
  const routes = get(option, 'allRoutes', [])
  const routesCount = routes.length
  const stopsCount = get(option, 'stopCount', 0)
  const isGrapple = methodTypeUpperCase === GRAPPLE

  return (
    <ListItemButton dense disableGutters {...props} onMouseOver={event => onOpenFilterMenu(event, routes)}>
      <ListItemIcon sx={{ minWidth: 0 }}>
        <Checkbox checked={selected} />
      </ListItemIcon>
      {Icon && (
        // Fix Grapple icon
        <ListItemIcon sx={{ minWidth: 0, mr: 2, '& svg path': { fill: isGrapple ? 'none' : 'inherit' } }}>
          {isGrapple ? <GrappleNew width={24} height={24} /> : <Icon width={24} height={24} fill={theme.palette.text.secondary} />}
        </ListItemIcon>
      )}
      <ListItemText
        primary={methodName}
        secondary={`${routesCount} route${routesCount > 1 ? 's' : ''} | ${stopsCount} stop${stopsCount > 1 ? 's' : ''}`}
        primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }}
        secondaryTypographyProps={{ variant: 'body1', fontWeight: 500 }}
      />
    </ListItemButton>
  )
}

RenderMethodFilterOption.propTypes = {
  option: PropTypes.object,
  props: PropTypes.object,
  selected: PropTypes.bool,
  onOpenFilterMenu: PropTypes.func,
}

export default RenderMethodFilterOption
