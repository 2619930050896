import React from 'react'
import PropTypes from 'prop-types'

import { Grid, CardActionArea, Box, cardActionAreaClasses } from '@mui/material'

import Link from 'components/common/Link'
import { getCustomerDetailsPageUrl } from 'router/routes'
import RenderAccountLeftContentCell from './RenderAccountLeftContentCell'
import RenderAccountRightContentCell from './RenderAccountRightContentCell'

const RenderAccountCell = ({ row = {}, isViewOnly = false, showAddToGroup = false, showRemoveFromGroup = false }) => {
  const { accountId } = row

  return (
    <CardActionArea
      {...(!isViewOnly && { to: getCustomerDetailsPageUrl(accountId), component: Link })}
      disableRipple
      disableTouchRipple
      sx={{
        py: 1.5,
        px: 0,
        height: '100%',
        [`& .${cardActionAreaClasses.focusVisible}, & .${cardActionAreaClasses.focusHighlight}`]: {
          display: 'none',
        },
      }}
    >
      <Box height="100%" width="100%">
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs zeroMinWidth>
            <RenderAccountLeftContentCell row={row} />
          </Grid>
          <Grid item xs="auto" md sx={{ minWidth: '40px' }}>
            <RenderAccountRightContentCell
              row={row}
              isViewOnly={isViewOnly}
              showAddToGroup={showAddToGroup}
              showRemoveFromGroup={showRemoveFromGroup}
            />
          </Grid>
        </Grid>
      </Box>
    </CardActionArea>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
  isViewOnly: PropTypes.bool,
  showAddToGroup: PropTypes.bool,
  showRemoveFromGroup: PropTypes.bool,
}

export default RenderAccountCell
