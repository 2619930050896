import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, ListItem, ListItemText, Popover, useTheme } from '@mui/material'
import capitalize from 'lodash/capitalize'
import { PAYMENT_SUSPENSION_REASON } from 'components/customer-details/content/suspensions/settings'

const WithSuspensionPopover = ({ children, suspensionType, suspensionDescription }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isPaymentSuspensionType = useMemo(() => suspensionType === PAYMENT_SUSPENSION_REASON, [suspensionType])
  const theme = useTheme()
  const handlePopoverOpen = event => {
    if (isPaymentSuspensionType) return
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    if (isPaymentSuspensionType) return
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'suspension-details-popover' : undefined
  return (
    <>
      <Box
        sx={{
          cursor: isPaymentSuspensionType ? 'initial' : 'pointer',
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
          pointerEvents: 'none',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid',
              borderColor: theme.palette.divider,
              mt: 0.5,
            },
          },
        }}
      >
        <ListItem>
          <ListItemText primary={capitalize(suspensionDescription)} secondary={capitalize(suspensionType)} />
        </ListItem>
      </Popover>
    </>
  )
}

WithSuspensionPopover.propTypes = {
  children: PropTypes.node,
  suspensionType: PropTypes.string,
  suspensionDescription: PropTypes.string,
}

export default WithSuspensionPopover
