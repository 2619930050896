import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const suspensionGroupsSlice = createSlice({
  name: 'suspensionGroups',
  initialState: {
    isAddEditSuspensionGroupDialogOpen: false,
    isCustomGroupViewOpen: true,
    isDefaultGroupViewOpen: true,
    rows: [],
  },
  reducers: {
    setIsAddEditSuspensionGroupDialogOpen: (state, action) => {
      state.isAddEditSuspensionGroupDialogOpen = action.payload
    },
    setIsCustomGroupViewOpen: (state, action) => {
      state.isCustomGroupViewOpen = action.payload
    },
    setIsDefaultGroupViewOpen: (state, action) => {
      state.isDefaultGroupViewOpen = action.payload
    },
    setSuspensionGroupsRows: (state, action) => {
      state.rows = action.payload
    },
  },
})

export const { setSuspensionGroupsRows, setIsAddEditSuspensionGroupDialogOpen, setIsCustomGroupViewOpen, setIsDefaultGroupViewOpen } =
  suspensionGroupsSlice.actions

export const selectIsAddEditSuspensionGroupDialogOpen = s => get(s, 'suspensionGroups.isAddEditSuspensionGroupDialogOpen', false)
export const selectIsCustomGroupViewOpen = s => get(s, 'suspensionGroups.isCustomGroupViewOpen', false)
export const selectIsDefaultGroupViewOpen = s => get(s, 'suspensionGroups.isDefaultGroupViewOpen', false)
export const selectSuspensionGroupsRows = s => get(s, 'suspensionGroups.rows', [])

export default suspensionGroupsSlice.reducer
