import api from 'api'
import { CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS } from 'api/cacheTagTypes'
import { get } from 'utils/lodash'
import { getTagIds } from 'utils/tags'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRouteStopsUnassigned: build.query({
      query: ({ serviceDate }) => ({
        url: '/api/v1/core/route/stops/unassigned',
        method: 'GET',
        params: { serviceDate },
      }),
      transformResponse: data => {
        const newStops = get(data, 'stops', []).map(stop => {
          return {
            ...stop,
            tagIds: getTagIds(get(stop, 'tags', {})),
          }
        })
        return { stops: newStops }
      },
      providesTags: [CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS],
    }),
  }),
})

export const { useLazyGetRouteStopsUnassignedQuery } = extendedApi
