import T from 'T'

const {
  BUSINESS_LINE,
  CONFIGURED_SERVICE,
  RECURRENCE,
  CUSTOMER,
  ADDRESS,
  CITY,
  STATE,
  ZIP,
  STATUS,
  CONTACT_NAME,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  PRICING_ZONE,
  MEASURE,
  SERVICE_START_DATE,
  LAST_WORK_ORDER_DATE,
  LAST_WORK_ORDER,
  NEXT_WORK_ORDER_DATE,
  NEXT_WORK_ORDER,
  TAGS,
  ROUTE_NOTE,
  ROUTE_NAME,
  PO,
  CONTAINERS,
  CONTAINER,
  CUSTOMER_BILLING_PROFILE,
  SERVICE_END_DATE,
  LAST_ACTIVITY_DATE,
  AUTOPAY,
  AUTOPAY_PAYMENT_TYPE,
} = T

export const SERVICE_CONFIGURATOR = [
  {
    position: 0,
    tableLabel: STATUS,
    label: STATUS,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'status',
    subType: 'serviceStatus',
  },
  {
    position: 1,
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    align: 'left',
    filterType: 'string',
    columnName: 'businessLine',
  },
  {
    position: 2,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'customerName',
  },
  {
    position: 3,
    tableLabel: CONFIGURED_SERVICE,
    label: CONFIGURED_SERVICE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'serviceName',
  },
  {
    position: 4,
    tableLabel: RECURRENCE,
    label: RECURRENCE,
    checked: true,
    locked: false,
    filterType: 'meta',
    columnName: 'recurrenceText',
  },
  {
    position: 5,
    tableLabel: `Location ${ADDRESS}`,
    label: `Location ${ADDRESS}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'locationAddress',
  },
  {
    position: 6,
    tableLabel: `Location ${CITY}`,
    label: `Location ${CITY}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'locationCity',
  },
  {
    position: 7,
    tableLabel: `Location ${STATE}`,
    label: `Location ${STATE}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'locationState',
  },
  {
    position: 8,
    tableLabel: `Location ${ZIP}`,
    label: `Location ${ZIP}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'locationZip',
  },
  {
    position: 9,
    tableLabel: CONTACT_NAME,
    label: CONTACT_NAME,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'contactName',
  },
  {
    position: 10,
    tableLabel: CONTACT_PHONE,
    label: CONTACT_PHONE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'contactNumber',
  },
  {
    position: 11,
    tableLabel: CONTACT_EMAIL,
    label: CONTACT_EMAIL,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'email',
  },
  {
    position: 12,
    tableLabel: PRICING_ZONE,
    label: PRICING_ZONE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'zoneName',
  },
  {
    position: 13,
    tableLabel: MEASURE,
    label: MEASURE,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'measureText',
  },
  {
    position: 14,
    tableLabel: SERVICE_START_DATE,
    label: SERVICE_START_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'startDate',
    subType: 'date',
  },
  {
    position: 15,
    tableLabel: SERVICE_END_DATE,
    label: SERVICE_END_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'endDate',
    subType: 'date',
  },
  {
    position: 16,
    tableLabel: LAST_WORK_ORDER_DATE,
    label: LAST_WORK_ORDER_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'lastWorkOrderDate',
    subType: 'date',
  },
  {
    position: 17,
    tableLabel: LAST_WORK_ORDER,
    label: LAST_WORK_ORDER,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'lastWorkOrderSequence',
  },
  {
    position: 18,
    tableLabel: `${LAST_WORK_ORDER} Status`,
    label: `${LAST_WORK_ORDER} Status`,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'lastWorkOrderStatus',
    subType: 'scheduledStatus',
  },
  {
    position: 19,
    tableLabel: `${LAST_WORK_ORDER} Exceptions`,
    label: `${LAST_WORK_ORDER} Exceptions`,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'lastWorkOrderException',
  },
  {
    position: 20,
    tableLabel: `${LAST_WORK_ORDER} Route`,
    label: `${LAST_WORK_ORDER} Route`,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'lastWorkOrderRoute',
  },
  {
    position: 21,
    tableLabel: NEXT_WORK_ORDER,
    label: NEXT_WORK_ORDER,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'nextWorkOrderSequence',
  },
  {
    position: 22,
    tableLabel: NEXT_WORK_ORDER_DATE,
    label: NEXT_WORK_ORDER_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'nextWorkOrderDate',
    subType: 'date',
  },
  {
    position: 23,
    tableLabel: `${NEXT_WORK_ORDER} Route`,
    label: `${NEXT_WORK_ORDER} Route`,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'nextWorkOrderRoute',
  },
  {
    position: 24,
    tableLabel: TAGS,
    label: TAGS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'tags',
    className: 'tags',
    subType: 'tags',
    hideSort: true,
  },
  {
    position: 25,
    tableLabel: ROUTE_NOTE,
    label: ROUTE_NOTE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'routeNote',
    subType: 'note',
  },
  {
    position: 26,
    tableLabel: ROUTE_NAME,
    label: ROUTE_NAME,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'routeName',
  },
  {
    position: 27,
    tableLabel: `${PO} #`,
    label: `${PO} #`,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'poNumber',
  },
  {
    position: 28,
    tableLabel: CONTAINERS,
    label: CONTAINERS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'containers',
  },
  {
    position: 29,
    tableLabel: `${CONTAINER} #`,
    label: `${CONTAINER} #`,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'containerName',
  },
  {
    position: 30,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'profile',
  },
  {
    position: 31,
    tableLabel: LAST_ACTIVITY_DATE,
    label: LAST_ACTIVITY_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'lastActivityDate',
    subType: 'date',
  },
  {
    position: 32,
    tableLabel: AUTOPAY,
    label: AUTOPAY,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'autoPay',
    subType: 'autoPay',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 33,
    tableLabel: AUTOPAY_PAYMENT_TYPE,
    label: AUTOPAY_PAYMENT_TYPE,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'autoPaymentType',
  },
]
