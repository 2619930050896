import React from 'react'

import { Box, ListItem, ListItemText, IconButton, Skeleton } from '@mui/material'

const PaymentMethodViewSkelton = () => {
  return (
    <Box px={2} py={1}>
      <ListItem disablePadding>
        <ListItemText primary={<Skeleton width="8rem" />} />
        <IconButton size="small">
          <Skeleton width="1rem" height="2rem" />
        </IconButton>
      </ListItem>

      <ListItem disablePadding>
        <Skeleton width="1rem" height="2rem" />
        <ListItemText sx={{ ml: 1 }} primary={<Skeleton width="6rem" />} secondary={<Skeleton width="6rem" />} />
      </ListItem>

      <ListItem disablePadding sx={{ ' .MuiListItemText-root': { display: 'flex', flexDirection: 'column-reverse' } }}>
        <ListItemText primary={<Skeleton width="4rem" />} secondary={<Skeleton width="4rem" />} />
      </ListItem>
    </Box>
  )
}

export default PaymentMethodViewSkelton
