import React, { useEffect, useState } from 'react'

import { Grid, IconButton, Collapse, Typography, Box } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import { useLazyGetAccountGroupSummaryByAgingBucketQuery } from 'api/groups/account/accountsByAgingBucketCrud'
import AccountGroupDefaultCard from './AccountGroupDefaultCard'

const { DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const AccountGroupDefaultView = () => {
  const [isExpanded, setIsExpanded] = useState(true)

  const handleClick = () => setIsExpanded(!isExpanded)
  const [getAccountGroupSummaryByAgingBucket, { data, isLoading, isFetching }] = useLazyGetAccountGroupSummaryByAgingBucketQuery()
  const loading = isLoading || isFetching

  useEffect(() => {
    getAccountGroupSummaryByAgingBucket()
  }, [])

  return (
    <Box px={3} mt={2} width="100%">
      <Box pl={1} display="flex" alignItems="center" gap={1} borderBottom="1px solid" borderColor="divider">
        <IconButton size="small" onClick={handleClick}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Typography variant="h5" fontWeight={400} color="textPrimary">
          Default
        </Typography>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6} xxl={3}>
            <AccountGroupDefaultCard isLoading={loading} data={data} id={DUE_ONE_THIRTY} />
          </Grid>
          <Grid item xs={12} md={6} xxl={3}>
            <AccountGroupDefaultCard isLoading={loading} data={data} id={DUE_THIRTY_ONE_SIXTY} />
          </Grid>
          <Grid item xs={12} md={6} xxl={3}>
            <AccountGroupDefaultCard isLoading={loading} data={data} id={DUE_SIXTY_ONE_NINETY} />
          </Grid>
          <Grid item xs={12} md={6} xxl={3}>
            <AccountGroupDefaultCard isLoading={loading} data={data} id={DUE_OVER_NINETY} />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default AccountGroupDefaultView
