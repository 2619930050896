import React from 'react'

import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { green } from '@mui/material/colors'

import T from 'T'

const AutopayChip = () => (
  <Chip
    size="small"
    variant="outlined"
    avatar={
      <Avatar sx={{ background: 'none' }}>
        <CheckCircleIcon fontSize="small" sx={{ color: green.A700 }} />
      </Avatar>
    }
    label={T.AUTOPAY}
    sx={{
      fontWeight: 500,
      border: '1px solid',
      borderColor: green.A700,
      backgroundColor: green[50],
      color: green.A700,
    }}
  />
)

export default AutopayChip
