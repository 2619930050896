import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountBillingPeriods: builder.query({
      query: ({ accountId }) => ({ url: `/api/v1/core/accounts/${accountId}/billing-periods` }),
    }),
  }),
})

export const { useLazyGetAccountBillingPeriodsQuery } = extendedApi
