import { useCallback, useRef } from 'react'
import { get } from 'lodash'
import { HIGHLIGHTED_MARKER_PROPS, UNASSIGNED_MARKER_PROPS } from 'components/route-manager/BulkActions/settings'
import debounce from 'lodash/debounce'

const useHandleSequenceChange = ({ getTargetIndex, getValues, routeSequenceLine, routeStopMarkers, rows, setValue, stopId, onSuccess }) => {
  const repositionArrayItem = (array, fromIndex, targetIndex) => {
    const arrayWithoutItem = [...array.slice(0, fromIndex), ...array.slice(fromIndex + 1)]
    return [...arrayWithoutItem.slice(0, targetIndex), array[fromIndex], ...arrayWithoutItem.slice(targetIndex)]
  }

  const handleSequenceChange = useCallback(
    value => {
      const originalIndex = rows.findIndex(item => item.id === stopId)
      if (originalIndex !== -1) {
        const formValues = getValues()
        const targetIndex = getTargetIndex(originalIndex, value)
        const reorderedRows = repositionArrayItem(rows, originalIndex, targetIndex)
        const reorderedStopMarkers = repositionArrayItem(routeStopMarkers, originalIndex, targetIndex)
        const reorderedCoordinates = repositionArrayItem(routeSequenceLine.geometry.coordinates, originalIndex, targetIndex)
        let currentPosition = 0
        const updatedRows = []
        const updatedMarkers = []
        for (let i = 0; i < reorderedRows.length; i++) {
          const rowItem = { ...reorderedRows[i] }
          const markerItem = { ...reorderedStopMarkers[i], properties: { ...reorderedStopMarkers[i].properties } }
          const rowId = get(rowItem, 'id')
          const rowTypedPosition = get(formValues, rowId, '')

          if (stopId !== rowId && rowTypedPosition && rowTypedPosition !== currentPosition + 1) {
            setValue(rowId, currentPosition + 1)
          }

          rowItem.position = rowTypedPosition ? currentPosition : ''
          markerItem.properties.position = rowTypedPosition ? currentPosition + 1 : ''

          if (stopId === rowId) {
            rowItem.isChanged = true
            markerItem.properties = {
              isChanged: true,
              ...markerItem.properties,
              ...(rowTypedPosition !== '' ? { ...HIGHLIGHTED_MARKER_PROPS } : { ...UNASSIGNED_MARKER_PROPS }),
            }
          }

          if (rowTypedPosition !== '') {
            currentPosition += 1
          }

          updatedRows.push(rowItem)
          updatedMarkers.push(markerItem)
        }

        const updatedrouteSequenceLine = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: reorderedCoordinates,
          },
        }
        onSuccess({
          rows: updatedRows,
          routeStopMarkers: updatedMarkers,
          routeSequenceLine: updatedrouteSequenceLine,
          focus: {
            name: stopId,
            rowIndex: targetIndex,
          },
        })
      }
    },
    [rows, stopId, getValues, getTargetIndex, routeStopMarkers, routeSequenceLine, setValue]
  )

  const debouncedHandleSequenceChange = useRef(debounce(handleSequenceChange, 750))

  return useCallback(
    value => {
      debouncedHandleSequenceChange.current.cancel()
      debouncedHandleSequenceChange.current = debounce(handleSequenceChange, 750)
      debouncedHandleSequenceChange.current(value)
    },
    [handleSequenceChange]
  )
}

export default useHandleSequenceChange
