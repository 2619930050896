import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Popover,
  Popper,
  TextField,
  ListItemButton,
  MenuItem,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop, orderBy, lowerCase } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { shallowEqual, useSelector } from 'react-redux'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { getMetadataMethods } from 'data/route/routeMetadataSelector'
import { getRoutesByMethod } from 'data/route/routesByGroup'
import { calculateSum } from 'utils/price'
import zIndex from '@mui/material/styles/zIndex'
import RenderMethodFilterOption from './RenderMethodFilterOption'
import RouteFilter from '../route-filter/RouteFilter'
import { generateFilterLabel } from '../settings'

const WIDTH = 250

const CustomPopper = props => <Popper {...props} placement="bottom-start" sx={{ '&.base-Popper-root': { zIndex: zIndex.modal + 2 } }} />

const MethodFilterMenu = ({ value, onChange = noop, RenderOption = RenderMethodFilterOption, ...rest }) => {
  const inputRef = useRef(null)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const open = Boolean(popoverAnchorEl)
  const [filterMenu, setFiltermenu] = useState({ anchorEl: null, position: null, filteredRoutes: [] })
  const { anchorEl, position, filteredRoutes } = filterMenu
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const allRoutes = useSelector(state => get(state, 'Route.allRoutes', []), shallowEqual)
  const routesByMethod = getRoutesByMethod({ routes: allRoutes })
  const metaMethods = useSelector(getMetadataMethods, shallowEqual)
  const metaMethodsWithRoutes = useMemo(
    () =>
      metaMethods.map(method => {
        const linkedRoutes = get(routesByMethod, method.methodType, [])
        return { ...method, allRoutes: linkedRoutes, stopCount: calculateSum(linkedRoutes, 'stopCount') }
      }),
    [metaMethods, routesByMethod]
  )
  const methods = useMemo(
    () => orderBy(metaMethodsWithRoutes, [row => row?.stopCount > 0, row => lowerCase(row?.methodName)], ['desc', 'asc']),
    [metaMethodsWithRoutes]
  )
  const methodIdList = useMemo(() => methods.filter(({ id }) => id).map(({ id }) => id), [methods])
  const isControlled = value !== undefined

  const [internalSelectedMethods, setInternalSelectedMethods] = useState([])
  const selectedMethods = isControlled ? value : internalSelectedMethods

  const allMethodsSelected = useMemo(() => selectedMethods.length === methodIdList.length, [selectedMethods, methodIdList])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleOpen = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(null)
  }

  const getOptionLabel = option => get(option, 'methodName')

  const toggleAllMethods = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelectedMethods = allMethodsSelected ? [] : [...methods]
      if (isControlled) {
        onChange(newSelectedMethods)
      } else {
        setInternalSelectedMethods(newSelectedMethods)
        onChange(newSelectedMethods)
      }
    },
    [isControlled, allMethodsSelected, methods, selectedRouteIds]
  )

  const handleChange = (event, newSelectedMethods) => {
    event.stopPropagation()
    if (isControlled) {
      onChange(newSelectedMethods)
    } else {
      setInternalSelectedMethods(newSelectedMethods)
      onChange(newSelectedMethods)
    }
  }

  const handleClosFilterMenu = useCallback(() => {
    setFiltermenu({ anchorEl: null, position: null, filteredRoutes: [] })
  }, [])

  const handleMouseLeave = event => {
    event.stopPropagation()
    const relatedTarget = event?.relatedTarget
    if (relatedTarget?.classList?.[0] === 'MuiBackdrop-root') {
      handleClosFilterMenu()
    }
  }

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton
            disableGutters
            divider
            selected={allMethodsSelected}
            onMouseDown={toggleAllMethods}
            onMouseOver={handleClosFilterMenu}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allMethodsSelected} />
            </ListItemIcon>
            <ListItemText primary={allMethodsSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allMethodsSelected, toggleAllMethods, handleClosFilterMenu]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  const handleOpenFilterMenu = (event, routeList) => {
    const target = event.currentTarget
    if (anchorEl !== target) {
      const popoverTop = target?.getBoundingClientRect().top || 0
      const popoverLeft = target?.getBoundingClientRect().left || 0
      setFiltermenu({ anchorEl: target, position: { top: popoverTop, left: popoverLeft }, filteredRoutes: routeList })
    }
  }

  const dropdownHeight = get(rest, 'dropdownHeight', 'calc(100vh - 272px)')
  const maxHeight = get(rest, 'autoHeight') ? null : { maxHeight: dropdownHeight }
  const buttonLabel = useMemo(() => generateFilterLabel(methodIdList, selectedMethods, 'method'), [methodIdList, selectedMethods])

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedMethods.length} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 100 }}>{buttonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="method-filter-menu-popover"
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        transitionDuration={{ appear: 0, exit: 0 }}
        TransitionProps={{ onEntered: focusAutocomplete }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onMouseLeave={handleMouseLeave}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClosFilterMenu}>
          <Autocomplete
            multiple
            options={methods}
            sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
            fullWidth
            componentsProps={{
              paper: { allMethodsSelected, toggleAllMethods },
              popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
            }}
            ListboxProps={{
              sx: { ...maxHeight, py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
            }}
            open={open}
            disableClearable
            onChange={handleChange}
            PopperComponent={CustomPopper}
            PaperComponent={SelectAllPaperComponent}
            renderTags={() => <></>}
            value={selectedMethods}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, { selected }) => (
              <RenderOption props={props} option={option} selected={selected} onOpenFilterMenu={handleOpenFilterMenu} />
            )}
            renderInput={params => <TextField {...params} placeholder="Type to filter methods" inputRef={inputRef} variant="standard" />}
            {...rest}
          />
        </ClickAwayListener>
      </Popover>
      {position && filteredRoutes.length > 0 && (
        <RouteFilter type="method" anchorEl={position} filteredRoutes={filteredRoutes} onMouseLeave={handleMouseLeave} />
      )}
    </>
  )
}

MethodFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  RenderOption: PropTypes.func,
}

export default MethodFilterMenu
