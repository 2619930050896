import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import TooltipListItem from './TooltipListItem'

const TooltipList = ({ stops = [], color, title }) => {
  const stopsList = stops.slice(0, 5)
  const stopsCount = stops.length - stopsList.length
  return (
    <>
      {stops.length !== 0 && (
        <>
          <TooltipListItem color={color} primaryLeft={title} primaryRight={`${stops.length} stops`} />
          {stopsList.map(stop => {
            const addressStr = get(stop, 'addressStr')
            const serviceName = get(stop, 'serviceName')
            const routeName = get(stop, 'routeName', 'Unassigned')
            const isUnassigned = routeName === 'Unassigned'
            const sequence = get(stop, 'sequence')
            const stopCount = get(stop, 'stopCount')
            const position = !isUnassigned && `${sequence}/${stopCount}`

            return (
              <TooltipListItem
                primaryLeft={serviceName}
                primaryRight={routeName}
                secondaryLeft={addressStr}
                secondaryRight={position}
                withVerticalDivider
              />
            )
          })}
          {stopsCount > 0 && <TooltipListItem secondaryRight={`+${stopsCount} more`} />}
        </>
      )}
    </>
  )
}

TooltipList.propTypes = {
  title: PropTypes.string,
  stops: PropTypes.array,
  color: PropTypes.string,
}

export default TooltipList
