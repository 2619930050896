import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { DialogContent, Stack, Typography } from '@mui/material'
import { get, noop } from 'lodash'
import { useForm } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import { HOLD_TYPES } from 'settings/constants/service'
import {
  useCreateVacationHoldMutation,
  useDeleteVacationHoldMutation,
  useLazyGetVacationHoldDetailsQuery,
  useUpdateVacationHoldMutation,
} from 'api/accounts/vacationHoldsCrud'
import { createDateFromString, isNotInvalidDate } from 'utils/date'
import { getActiveDeactivatedLocations } from 'utils/location'
import CancelButton from 'components/buttons/CancelButton'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import SaveButton from 'components/buttons/SaveButton'
import HHConfirmDeleteButton from 'components/common/HHConfirmDeleteButton'
import { HHDateRangePicker, HHFormTextField } from 'components/form-fields/v5'
import LocationsAutocompleteFormField from 'components/customer-details/content/holds/LocationsAutocompleteFormField'

const { VACATION } = HOLD_TYPES

const defaultValues = {
  title: '',
  type: VACATION,
  locations: [],
  dateRange: [null, null],
  allLocationsSelected: false,
  active: false,
}

const getParsedData = data => {
  const { type, title, dateRange, locations, ...rest } = data
  const [rawStartDate, rawEndDate] = dateRange
  const startDate = isNotInvalidDate(rawStartDate) ? rawStartDate : undefined
  const endDate = isNotInvalidDate(rawEndDate) ? rawEndDate : undefined
  const parsedLocations = locations.map(({ id }) => id)
  return { type, title, startDate, endDate, locations: parsedLocations, ...rest }
}

const HoldsFormDialog = ({ accountId, customerDetails, open = false, selectedHold, mode = 'create', onClose = noop, onSave = noop }) => {
  const dispatch = useDispatch()
  const { activeLocations } = getActiveDeactivatedLocations(customerDetails)
  const isCreateMode = mode === 'create'
  const isUpdateMode = mode === 'update'
  const [create, { isLoading: isCreateLoading }] = useCreateVacationHoldMutation()
  const [update, { isLoading: isUpdateLoading }] = useUpdateVacationHoldMutation()
  const [deleteHold, { isLoading: isDeleteLoading }] = useDeleteVacationHoldMutation()
  const isMutationLoading = isCreateLoading || isUpdateLoading
  const [getHoldDetails, { isLoading, isFetching }] = useLazyGetVacationHoldDetailsQuery()
  const selectedLocationId = useSelector(state => get(state, 'CustomerDetails.selectedLocationId', ''), shallowEqual)

  const selectedHoldId = useMemo(() => get(selectedHold, 'id'), [selectedHold])
  const isFormLoading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const {
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues })
  const allLocationsSelected = watch('allLocationsSelected')
  const titleHelperText = get(errors, 'title.message')
  const dateRangeHelperText = get(errors, 'dateRange.message')
  const locationsHelperText = get(errors, 'locations.message')
  const handleClose = () => {
    onClose()
    reset(defaultValues)
  }

  const validateDateRange = dateRange => {
    const dateRangeCount = dateRange.filter(Boolean).length
    return dateRangeCount === 2 || 'This field is required'
  }

  const sendMutation = async data => {
    const parsedData = getParsedData(data)
    if (isCreateMode) {
      return create({ data: parsedData, accountId }).unwrap()
    }
    return update({ data: parsedData, accountId, vacationHoldId: selectedHoldId }).unwrap()
  }

  const onSubmit = async data =>
    sendMutation(data).then(() => {
      dispatch(getCustomerDetails({ accountId }))
      reset(defaultValues)
      onSave()
    })

  const handleDeleteConfirmed = () => {
    deleteHold({ vacationHoldId: selectedHoldId, accountId })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
      })
    handleClose()
  }

  useEffect(() => {
    if (!open || !selectedHoldId || isCreateMode) return
    getHoldDetails({
      vacationHoldId: selectedHoldId,
      accountId,
    })
      .unwrap()
      .then(data => {
        const { startDate: startDateStr, endDate: endDateStr, type, title, locations: locationIdList, active } = data
        const startDate = createDateFromString(startDateStr)
        const endDate = createDateFromString(endDateStr)
        const selectedLocations = activeLocations.filter(location => {
          const locationId = get(location, 'id')
          return locationIdList.includes(locationId)
        })
        reset({
          active,
          type,
          title,
          dateRange: [startDate, endDate],
          locations: selectedLocations,
          allLocationsSelected: activeLocations.length === selectedLocations.length && selectedLocations.length !== 0,
        })
      })
  }, [open])

  useEffect(() => {
    if (!open || isUpdateMode) return
    if (selectedLocationId) {
      const selectedLocation = activeLocations.find(location => {
        const locationId = get(location, 'id')
        return locationId === selectedLocationId
      })
      if (!selectedLocation) return
      reset({
        ...defaultValues,
        locations: [selectedLocation],
        allLocationsSelected: activeLocations.length === 1,
      })
    } else {
      reset({ ...defaultValues, locations: activeLocations, allLocationsSelected: false })
    }
  }, [open, selectedLocationId, mode])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <HHDialogTitle
        title={
          <>
            {isCreateMode && 'Add a hold'}
            {isUpdateMode && 'Edit hold'}
          </>
        }
        sx={{ color: 'text.secondary' }}
        onClose={handleClose}
      />
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 4, mb: 2 }} justifyContent="center">
          <HHDateRangePicker
            readOnly={isFormLoading}
            name="dateRange"
            control={control}
            localeText={{ start: 'Start *', end: 'End *' }}
            required
            error={dateRangeHelperText}
            rules={{ required: 'This field is required', validate: validateDateRange }}
          />
          <HHFormTextField
            readOnly={isFormLoading}
            helperText={titleHelperText}
            rules={{ required: 'This field is required' }}
            error={titleHelperText}
            control={control}
            name="title"
            required
            deprecatedLabel={false}
            label="Title"
            fullWidth
          />
          <LocationsAutocompleteFormField
            sx={{
              '& .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
                height: 'unset',
                paddingY: '4.5px',
              },
              '& .MuiAutocomplete-inputRoot': {
                flexWrap: 'wrap',
              },
            }}
            allLocationsSelected={allLocationsSelected}
            readOnly={isFormLoading}
            helperText={locationsHelperText}
            rules={{ required: 'This field is required' }}
            error={locationsHelperText}
            control={control}
            name="locations"
            required
            deprecatedLabel={false}
            label="Locations"
            fullWidth
            setValue={setValue}
          />
        </Stack>
      </DialogContent>
      <HHDialogActions sx={{ justifyContent: 'space-between' }}>
        {isUpdateMode && (
          <HHConfirmDeleteButton
            size="small"
            variant="outlined"
            DeleteButtonProps={{ loading: isDeleteLoading }}
            onDeleteConfirmed={handleDeleteConfirmed}
            confirmTitle="Confirm hold deletion"
            confirmDescription={<Typography component="p">Are you sure you want to continue?</Typography>}
            DialogProps={{ maxWidth: 'xs', fullWidth: true }}
          />
        )}
        <Stack
          sx={{ ...(isCreateMode && { width: '100%' }) }}
          justifyContent={isCreateMode ? 'flex-end' : 'space-between'}
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <CancelButton onClick={handleClose} />
          <SaveButton loading={isMutationLoading} onClick={handleSubmit(onSubmit)} />
        </Stack>
      </HHDialogActions>
    </Dialog>
  )
}

HoldsFormDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  selectedHold: PropTypes.object.isRequired,
  open: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'update']),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  customerDetails: PropTypes.object.isRequired,
}

export default HoldsFormDialog
