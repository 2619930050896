import React, { useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'

import { toast } from 'react-toastify'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Menu, MenuItem, Typography, Tooltip } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { isUnPaidInvoice } from 'utils/billing'
import { getRevertPaymentConfirmationMsg } from 'utils/payment'
import { PAYMENT_STATUS, PAYMENT_METHODS } from 'settings/constants/payment'
import { putIsLoading } from 'middleware/actions/response'
import { downloadPaymentReceiptBlobListInChunks } from 'utils/file'
import { useDeletePaymentBatchesFromGroupMutation } from 'api/groups/payment/deletePaymentBatchesFromGroup'
import { useRegeneratePaymentReceiptsMutation } from 'api/billing/regeneratePaymentReceipts'
import { getCustomerDetails } from 'middleware/actions/customers'
import {
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { isCustomerDetailsPage } from 'router/routes'

import T from 'T'
import api from 'api'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import TransferPaymentModal from 'components/customer-details/content/payments/transfer-payments'
import ACHFailedDialog from 'components/customer-details/content/payments/ach-failed'
import AddToGroupDialog from 'components/billing/groups/payment/AddToGroupDialog'
import { useReversePaymentMutation } from 'api/billing/reversePayment'
import PaymentReceiptEmailConfirmation from './payment-receipt-email-confirmation'
import MoveToAccountCreditConfirmation from './MoveToAccountCreditConfirmation'
import AddUpdatePaymentNoteDialog from './AddUpdatePaymentNoteDialog'

const { APPROVED } = PAYMENT_STATUS
const { CHECK, OTHER, CASH, ACH, CREDIT_CARD } = PAYMENT_METHODS

const PaymentsActionMenu = ({
  anchorEl,
  customerDetails = null,
  selectedPayment = {},
  isOriginalPaymentReversedOrTransferred = false,
  onClose,
  showRevertAction = true,
  showTransferAction = true,
  showACHFailedAction = true,
  showAddToGroupAction = true,
  showMoveToAccountCreditAction = true,
}) => {
  const dispatch = useDispatch()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isConfirmRemovePaymentDialogOpen, setIsConfirmRemovePaymentDialogOpen] = useState(false)
  const [isOpenAddToGroupDialog, setIsOpenAddToGroupDialog] = useState(false)
  const [transferInvoiceDialog, setTransferInvoiceDialog] = useState({ isOpen: false, isIntraAccountTransfer: false })
  const [isACHDialogDialogOpen, setIsACHDialogDialogOpen] = useState(false)
  const [isMarkAsRefundedConfirmDialogOpen, setIsMarkAsRefundedConfirmDialogOpen] = useState(false)
  const [isOpenReceiptConfirmationDialog, setisOpenReceiptConfirmationDialog] = useState(false)
  const [isOpenMoveToAccountCreditConfirmation, setIsOpenMoveToAccountCreditConfirmation] = useState(false)
  const [isOpenAddUpdatePaymentNoteDialog, setIsOpenAddUpdatePaymentNoteDialog] = useState(false)
  const [markPaymentAsReverted] = useReversePaymentMutation()
  const [deletePaymentBatchesFromGroup] = useDeletePaymentBatchesFromGroupMutation()
  const [regeneratePaymentReceipts] = useRegeneratePaymentReceiptsMutation()
  const paymentNumber = get(selectedPayment, 'paymentNumber')
  const transactionStatus = capitalize(get(selectedPayment, 'transactionStatus', ''))
  const paymentMethod = get(selectedPayment, 'paymentMethod')

  const hasLinkedAccountCredit = get(selectedPayment, 'accountCredit.amountCents', 0) > 0
  const invoices = get(selectedPayment, 'invoices', [])
  const linkedInvoices = invoices.map(invoice => invoice?.invoiceNumber)
  const hasLinkedInvoices = linkedInvoices.length > 0
  const hasPartialInvoice = invoices.find(
    invoice => isUnPaidInvoice(invoice.status) && invoice.amountPaidCents > 0 && invoice.amountPaidCents < invoice.totalCents
  )
  const isApproved = transactionStatus === APPROVED
  const isRevertTransferBtnEnabled = isApproved && [CHECK, OTHER, CASH].includes(paymentMethod)
  const isMarkAsRevertedBtnEnabled = isApproved && [CREDIT_CARD, ACH].includes(paymentMethod)
  const revertMessage = getRevertPaymentConfirmationMsg(hasLinkedAccountCredit, linkedInvoices, hasPartialInvoice)
  const isMarkACHFailedBtnEnabled = isApproved && paymentMethod === ACH
  const groupId = get(selectedPayment, 'groupId')
  const batchId = get(selectedPayment, 'batchId')
  const accountTransactionHistoryId = get(selectedPayment, 'id')
  const accountId = get(selectedPayment, 'accountId')
  const paymentNote = get(selectedPayment, 'paymentNote', '')

  const invalidateAccountTransaction = () => {
    if (isCustomerDetailsPage()) {
      dispatch(getCustomerDetails({ accountId }))
    }
    dispatch(
      api.util.invalidateTags([
        CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
        CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
        { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST, id: accountId },
        CACHE_TAG_ACCOUNT_LIST,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ])
    )
  }

  const handleRevertConfirmation = () => {
    dispatch(putIsLoading(true))
    markPaymentAsReverted({ paymentNumber, force: false })
      .unwrap()
      .then(() => {
        setIsConfirmDialogOpen(false)
        toast.info(T.PAYMENT_REVERT_MSG, {
          hideProgressBar: false,
          autoClose: 4000,
          onClose: () => invalidateAccountTransaction(),
        })
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleDownloadPaymentReceipt = () => {
    dispatch(putIsLoading(true))
    setTimeout(() => {
      downloadPaymentReceiptBlobListInChunks(businessId, [{ paymentNumber, accountTransactionHistoryId, accountId }])
      onClose()
      dispatch(putIsLoading(false))
    }, 5000)
  }

  const handleRegeneratePaymentReceipt = () => {
    dispatch(putIsLoading(true))
    regeneratePaymentReceipts({ accountTransactionHistoryIds: [accountTransactionHistoryId] })
      .unwrap()
      .then(() => toast.success(T.RECEIPT_IS_REGENERATING, { hideProgressBar: false, autoClose: 4000 }))
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleSendPaymentReceipt = () => setisOpenReceiptConfirmationDialog(true)

  const handleRemovePaymentFromGroup = () => {
    dispatch(putIsLoading(true))
    deletePaymentBatchesFromGroup({ id: groupId, batchIds: [batchId] })
      .unwrap()
      .then(() => {
        setIsConfirmRemovePaymentDialogOpen(false)
        toast.success(T.REMOVE_INVOICE_FROM_GROUP_SUCCESS_MSG)
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleMarkAsRevertedConfirmation = () => {
    dispatch(putIsLoading(true))
    markPaymentAsReverted({ paymentNumber, force: true })
      .unwrap()
      .then(() => {
        setIsMarkAsRefundedConfirmDialogOpen(false)
        toast.success('Payment marked as reversed successfully')
        invalidateAccountTransaction()
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const openMarkAsRefundedConfirmationDialog = () => setIsMarkAsRefundedConfirmDialogOpen(true)
  const closeMarkAsRefundedConfirmationDialog = () => setIsMarkAsRefundedConfirmDialogOpen(false)

  const handleOpenAddToGroupDialog = () => setIsOpenAddToGroupDialog(true)

  const handleCloseAddToGroupDialog = () => {
    setIsOpenAddToGroupDialog(false)
    onClose()
  }

  const handleMoveToAccountCredit = () => setIsOpenMoveToAccountCreditConfirmation(true)

  const handleOpenAddUpdatePaymentNote = () => setIsOpenAddUpdatePaymentNoteDialog(true)

  const handleCloseAddUpdatePaymentNote = () => {
    setIsOpenAddUpdatePaymentNoteDialog(false)
    onClose()
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        {paymentNumber && (
          <MenuItem onClick={handleOpenAddUpdatePaymentNote}>{`${paymentNote ? 'Edit' : 'Add'} ${lowerCase(T.PAYMENT_NOTE)}`}</MenuItem>
        )}
        {paymentNumber && <MenuItem onClick={handleDownloadPaymentReceipt}>{T.DOWNLOAD}</MenuItem>}
        {paymentNumber && <MenuItem onClick={handleRegeneratePaymentReceipt}>{T.REGENERATE_RECEIPT}</MenuItem>}
        {paymentNumber && <MenuItem onClick={handleSendPaymentReceipt}>{T.EMAIL}</MenuItem>}
        {showAddToGroupAction && <MenuItem onClick={handleOpenAddToGroupDialog}>{T.ADD_TO_PAYMENT_GROUP}</MenuItem>}
        {groupId && <MenuItem onClick={() => setIsConfirmRemovePaymentDialogOpen(true)}>{T.REMOVE_FROM_GROUP}</MenuItem>}
        {/* {showMoveToAccountCreditAction && isApproved && hasLinkedInvoices && ( */}
        {/*  <MenuItem sx={{ color: 'warning.main' }} disabled={!hasLinkedInvoices} onClick={handleMoveToAccountCredit}> */}
        {/*    {T.MOVE_TO_ACCOUNT_CREDIT} */}
        {/*  </MenuItem> */}
        {/* )} */}
        {showTransferAction && paymentNumber && isRevertTransferBtnEnabled && linkedInvoices.length > 0 && (
          <>
            <Tooltip title={T.PAYMENT_ALREADY_REVERT_TRANSFER_MSG} disableHoverListener={!isOriginalPaymentReversedOrTransferred}>
              <span>
                <MenuItem
                  sx={{ color: 'warning.main' }}
                  disabled={isOriginalPaymentReversedOrTransferred}
                  onClick={() => setTransferInvoiceDialog({ isOpen: true, isIntraAccountTransfer: false })}
                >
                  {T.TRANSFER_TO_INVOICE}
                </MenuItem>
              </span>
            </Tooltip>
            <Tooltip title={T.PAYMENT_ALREADY_REVERT_TRANSFER_MSG} disableHoverListener={!isOriginalPaymentReversedOrTransferred}>
              <span>
                <MenuItem
                  sx={{ color: 'warning.main' }}
                  disabled={isOriginalPaymentReversedOrTransferred}
                  onClick={() => setTransferInvoiceDialog({ isOpen: true, isIntraAccountTransfer: true })}
                >
                  {T.TRANSFER_TO_ACCOUNT}
                </MenuItem>
              </span>
            </Tooltip>
          </>
        )}

        {showACHFailedAction && isMarkACHFailedBtnEnabled && (
          <Tooltip title={T.PAYMENT_ALREADY_ACH_FAILED_MSG} disableHoverListener={!isOriginalPaymentReversedOrTransferred}>
            <span>
              <MenuItem
                sx={{ color: 'error.main' }}
                disabled={isOriginalPaymentReversedOrTransferred}
                onClick={() => setIsACHDialogDialogOpen(true)}
              >
                {T.ACH_FAILED}
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {showRevertAction && paymentNumber && isRevertTransferBtnEnabled && (
          <Tooltip title={T.PAYMENT_ALREADY_REVERT_TRANSFER_MSG} disableHoverListener={!isOriginalPaymentReversedOrTransferred}>
            <span>
              <MenuItem
                sx={{ color: 'error.main' }}
                disabled={isOriginalPaymentReversedOrTransferred}
                onClick={() => setIsConfirmDialogOpen(true)}
              >
                {T.REVERT}
              </MenuItem>
            </span>
          </Tooltip>
        )}
        {paymentNumber && isMarkAsRevertedBtnEnabled && (
          <Tooltip title={T.PAYMENT_ALREADY_REVERT_TRANSFER_MSG} disableHoverListener={!isOriginalPaymentReversedOrTransferred}>
            <span>
              <MenuItem
                sx={{ color: 'error.main' }}
                disabled={isOriginalPaymentReversedOrTransferred}
                onClick={openMarkAsRefundedConfirmationDialog}
              >
                {T.MARK_AS_REVERTED}
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>

      <HHConfirmDialog
        isOpen={isMarkAsRefundedConfirmDialogOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmButtonTitle={T.MARK_AS_REVERTED}
        confirmTitle={T.MARK_AS_REVERTED}
        confirmDescription={
          <>
            <Typography variant="h6" fontWeight={400}>
              {`Payment ${paymentNumber} will be marked as reversed. Do you want to proceed?`}
            </Typography>
          </>
        }
        onConfirm={handleMarkAsRevertedConfirmation}
        onClose={() => {
          closeMarkAsRefundedConfirmationDialog()
          onClose()
        }}
      />

      <HHConfirmDialog
        isOpen={isConfirmDialogOpen}
        DialogProps={{ maxWidth: 'md', fullWidth: true }}
        confirmButtonTitle={T.REVERT}
        confirmTitle="Revert payment confirmation"
        confirmDescription={
          <>
            <Typography variant="h6" fontWeight={400}>
              {revertMessage}
            </Typography>
            <Typography variant="h6" fontWeight={400} mt={2}>
              Are you sure you want to proceed?
            </Typography>
          </>
        }
        onConfirm={handleRevertConfirmation}
        onClose={() => {
          setIsConfirmDialogOpen(false)
          onClose()
        }}
      />

      <TransferPaymentModal
        isOpen={transferInvoiceDialog.isOpen}
        customerDetails={customerDetails}
        isIntraAccountTransfer={transferInvoiceDialog.isIntraAccountTransfer}
        payment={selectedPayment}
        onClose={() => {
          setTransferInvoiceDialog({ isOpen: false, isIntraAccountTransfer: false })
          onClose()
        }}
      />

      <ACHFailedDialog
        isOpen={isACHDialogDialogOpen}
        customerDetails={customerDetails}
        payment={selectedPayment}
        onClose={() => {
          setIsACHDialogDialogOpen(false)
          onClose()
        }}
      />
      <HHConfirmDialog
        isOpen={isConfirmRemovePaymentDialogOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        ConfirmButtonProps={{ color: 'warning' }}
        confirmButtonTitle={T.REMOVE}
        confirmTitle="Remove payment"
        confirmDescription={`By removing this payment from this group, the other payments within the batch ${batchId} will also be removed. Do you want to proceed?`}
        onConfirm={handleRemovePaymentFromGroup}
        onClose={() => {
          setIsConfirmRemovePaymentDialogOpen(false)
          onClose()
        }}
      />
      <AddToGroupDialog isOpen={isOpenAddToGroupDialog} batchIds={[batchId]} onClose={handleCloseAddToGroupDialog} />
      {isOpenReceiptConfirmationDialog && (
        <PaymentReceiptEmailConfirmation
          isOpen={isOpenReceiptConfirmationDialog}
          isSingleAccount
          payments={[selectedPayment]}
          onClose={() => {
            setisOpenReceiptConfirmationDialog(false)
            onClose()
          }}
        />
      )}
      <MoveToAccountCreditConfirmation
        isOpen={isOpenMoveToAccountCreditConfirmation}
        accountId={accountId}
        invoiceNumbers={get(selectedPayment, 'invoices', []).map(({ invoiceNumber }) => invoiceNumber)}
        paymentNumbers={paymentNumber ? [paymentNumber] : []}
        onClose={() => {
          setIsOpenMoveToAccountCreditConfirmation(false)
          onClose()
        }}
      />
      <AddUpdatePaymentNoteDialog
        isOpen={isOpenAddUpdatePaymentNoteDialog}
        accountId={accountId}
        paymentNumber={paymentNumber}
        existingNote={paymentNote}
        onClose={handleCloseAddUpdatePaymentNote}
      />
    </>
  )
}

PaymentsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  customerDetails: PropTypes.object,
  selectedPayment: PropTypes.object,
  isOriginalPaymentReversedOrTransferred: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showRevertAction: PropTypes.bool,
  showTransferAction: PropTypes.bool,
  showACHFailedAction: PropTypes.bool,
  showAddToGroupAction: PropTypes.bool,
  showMoveToAccountCreditAction: PropTypes.bool,
}

export default PaymentsActionMenu
