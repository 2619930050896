import T from 'T'
import { createDateFromString, formatDateToFEFormatDateFns } from '../../utils/date'
import { formatCentsToDollars } from '../../utils/price'
import { getPaymentMethod } from '../../utils/payment'
import { PAYMENT_STATUS } from '../../settings/constants/payment'

// eslint-disable-next-line import/no-cycle
import { convertDateTimeToPreferedTimezone } from '../../data/dateTime/convertDateTimeToPreferedTimezone'

const { TRANSFERRED } = PAYMENT_STATUS

const dateValueFormatter = params => {
  if (!params.value) return ''
  return formatDateToFEFormatDateFns(createDateFromString(params.value))
}

const dateTimeValueFormatter = params => {
  if (!params.value) return ''
  const { fullDateTime } = convertDateTimeToPreferedTimezone({ dateTime: params.value, defaultZone: 'America/Los_Angeles' })
  return fullDateTime
}

const moneyValueFormatter = params => {
  if (!params.value) return ''
  return formatCentsToDollars(params.value)
}

const yesNoFormatter = params => {
  if (!params.value) return 'No'
  return 'Yes'
}

const paymentMethodFormatter = params => {
  if (!params.value) return ''
  return getPaymentMethod(params.value)
}

const transactionStatusFormatter = params => {
  const status = params.value
  if (!status) return ''
  if (status === TRANSFERRED) return T.TRANSFER
  return status
}

export {
  dateTimeValueFormatter,
  dateValueFormatter,
  moneyValueFormatter,
  yesNoFormatter,
  paymentMethodFormatter,
  transactionStatusFormatter,
}
