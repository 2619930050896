import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const RenderExceptionContent = ({ height = '194px', src, isPdf, sx, PageProps, ...rest }) => {
  const pageNumber = 1
  const onDocumentLoadSuccess = () => {}
  if (isPdf) {
    return (
      <Box
        src={src}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          width: '100%',
          objectFit: 'cover',
          ...sx,
        }}
        {...rest}
      >
        <Document
          style={{
            ...sx,
          }}
          border={0}
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} {...PageProps} />
        </Document>
      </Box>
    )
  }
  return (
    <Box
      component="img"
      src={src}
      sx={{
        ...height,
        width: '100%',
        objectFit: 'cover',
        ...sx,
      }}
      {...rest}
    />
  )
}

RenderExceptionContent.propTypes = {
  src: PropTypes.string,
  isPdf: PropTypes.bool,
  sx: PropTypes.object,
  PageProps: PropTypes.object,
}

export default RenderExceptionContent
