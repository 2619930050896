import React from 'react'

import WorkOrdersReview from 'components/work-order/WorkOrderReviewDialog'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'

const WorkOrdersReviewPage = () => (
  <ProtectedRouteHoC>
    <NavbarWrapper innerSx={{ overflow: 'auto' }}>
      <WorkOrdersReview />
    </NavbarWrapper>
  </ProtectedRouteHoC>
)

export default WorkOrdersReviewPage
