import { get } from 'utils/lodash'
import { createSelector } from 'reselect'
import { getMetadataRoutes } from 'data/route/routeMetadataSelector'

const emptyRoutes = []
const getReducerSlice = (state = {}) => get(state, 'Route', {})

const selectedRouteIdsSlice = createSelector(getReducerSlice, (reducer = {}) => get(reducer, 'filters.selectedRouteIds', emptyRoutes))

export const getSelectedRouteIds = createSelector(selectedRouteIdsSlice, (selectedIds = emptyRoutes) => selectedIds)

export const getSelectedMetadataRoutes = createSelector(
  getMetadataRoutes,
  getSelectedRouteIds,
  (routes = emptyRoutes, selectedRoutes = emptyRoutes) => routes.filter(({ id }) => selectedRoutes.includes(id))
)
