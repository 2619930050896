import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import flattenDeep from 'lodash/flattenDeep'

import { Checkbox, Chip, InputAdornment, Autocomplete } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Search } from '@styled-icons/heroicons-outline/Search'

import Constants from 'Constants'
import T from 'T'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { HHTextField } from 'components/form-fields/v5'
import CommonNoOptionsMsg from './CommonNoOptionsMsg'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const { MEDIA_SERVER } = Constants

const AutoComplete = ({
  isTagsType,
  isHavingImage,
  isTypedText,
  isHandleCheckbox,
  maxHeight,
  metaData,
  label,
  getByLabelText,
  checkedRecords,
  handleCheckboxValue,
  multiple,
  getPlaceholder,
  handleTypedCall,
  disabled = false,
  defaultSize = true,
  ...rest
}) => {
  const [checkPlaceHolder, setCheckPlaceHolder] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [selectedValues, setSelectedValues] = useState([])

  const [autoOpen, setAutoOpen] = useState(false)

  useEffect(() => {
    const recordsChecked = checkedRecords || []

    const selectedRecords = flattenDeep(recordsChecked.map(data => metaData.filter(rec => rec.id === data.id)))

    if (!multiple && selectedRecords && selectedRecords.length > 0) {
      setSelectedValues(selectedRecords[0])
      return
    }
    setSelectedValues(selectedRecords)
  }, [checkedRecords])

  const handleClick = () => {
    setCheckPlaceHolder(checkedRecords.length ? '' : T.SEARCH_PLACEHOLDER)
    setAutoOpen(!autoOpen)
  }

  const handleBlur = () => {
    setAutoOpen(false)
    setCheckPlaceHolder(checkedRecords.length ? '' : label)
  }

  const handleDelete = (value, index) => {
    setCheckPlaceHolder(T.SEARCH_PLACEHOLDER)
    setAutoOpen(!autoOpen)

    if (typeof checkedRecords === 'string') {
      checkedRecords = [checkedRecords] // convert the string to array so that we can splice the deleted record
      checkedRecords.splice(index, 1)
    } else {
      value.splice(index, 1)
    }

    handleCheckboxValue(label, value)
  }

  return (
    <Autocomplete
      sx={{
        bgcolor: 'common.white',
      }}
      // Change this to className
      ListboxProps={{
        style: {
          maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
        },
      }}
      disabled={disabled}
      noOptionsText={<CommonNoOptionsMsg text={inputValue} />}
      open={!disabled && autoOpen}
      multiple={multiple}
      options={metaData}
      value={isEmpty(selectedValues) && !multiple ? '' : selectedValues}
      disableCloseOnSelect
      onChange={(event, newValue) => {
        if (isHandleCheckbox) {
          handleCheckboxValue(label, newValue, event)
        }
        setCheckPlaceHolder(newValue === null || newValue.length ? '' : T.SEARCH_PLACEHOLDER)
      }}
      onBlur={() => {
        handleBlur()
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      inputValue={inputValue}
      getOptionLabel={option => getByLabelText(option, label)}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            {multiple && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}

            {isHavingImage && (
              <img src={MEDIA_SERVER + option.image} alt="" style={{ marginRight: 9, height: 20, width: 20 }} className="mr12" />
            )}
            {isTagsType ? (
              <span
                className="tag_name"
                style={{
                  fontSize: '13px',
                  fontFamily: 'Inter',
                  border: option.border ? '1px solid #042040' : 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '2px 8px',
                  height: '20px',
                  background: option.tagColor,
                  borderRadius: '4px',
                  color: option.tagTextColor,
                }}
              >
                {getByLabelText(option, label)}
              </span>
            ) : (
              getByLabelText(option, label)
            )}
          </li>
        )
      }}
      renderTags={value => (
        <>
          {checkedRecords.length > 0 &&
            Children.toArray(
              value.map(
                (option, index) =>
                  index === 0 && (
                    <Chip
                      label={getByLabelText(option, label)}
                      deleteIcon={<CloseIcon />}
                      onDelete={() => {
                        if (disabled) return
                        handleDelete(value, index)
                      }}
                      style={{ height: '100%', borderRadius: '2px', color: '#0f0f13' }}
                    />
                  )
              )
            )}

          {value.length > 1 && <div className="plus-tags">+{value.length - 1}</div>}
        </>
      )}
      renderInput={params => {
        const placeHolderVal = checkPlaceHolder || getPlaceholder

        // eslint-disable-next-line no-unused-expressions
        placeHolderVal === T.SEARCH_PLACEHOLDER &&
          (params.InputProps.startAdornment = (
            <>
              <InputAdornment position="start">
                <Search className="search-icon" />
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ))
        return (
          <HHTextField
            defaultSize={defaultSize}
            {...params}
            name={label}
            placeholder={placeHolderVal}
            onClick={() => handleClick()}
            onChange={e => {
              if (e.target.value && isTypedText) {
                handleTypedCall(e)
              }
            }}
          />
        )
      }}
      {...rest}
    />
  )
}

AutoComplete.defaultProps = {
  isTagsType: false,
  isHavingImage: false,
  isTypedText: false,
  isHandleCheckbox: false,
  checkedRecords: [],
  metaData: null, // Data object whose Autocomplete feature is desired
  label: '', // category name for autocomplete field
  getByLabelText: null, // Label for dropdown items
  getCategoryRecords: null, // fetching category wise records
  handleCheckboxValue: null, // checkbox click handle
  getPlaceholder: '', // placeholder content
  multiple: true, // single select/multiple
  maxHeight: 'auto',
  handleTypedCall: null,
}

AutoComplete.propTypes = {
  isTagsType: PropTypes.bool,
  isHavingImage: PropTypes.bool,
  isTypedText: PropTypes.bool,
  isHandleCheckbox: PropTypes.bool,
  checkedRecords: PropTypes.array,
  metaData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getByLabelText: PropTypes.func,
  getCategoryRecords: PropTypes.func,
  handleCheckboxValue: PropTypes.func,
  handleTypedCall: PropTypes.func,
  getPlaceholder: PropTypes.string,
  multiple: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  defaultSize: PropTypes.bool,
}

export default AutoComplete
