import difference from 'lodash/difference'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const getsortedGroups = groupList => {
  if (!Array.isArray(groupList)) {
    return []
  }

  return [...groupList].sort((a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt))
}

export const removeParentFromSelection = (invoices = []) =>
  difference(invoices, [PARTIAL, UNPAID, PAID, CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY])
