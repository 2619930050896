export const actions = {
  GET: {
    PRICING_TABLE_CONTENT: 'PRICING_TABLE_GET_CONTENT',
    PRICING_RESULT: 'PRICING_GET_RESULT',
    TAX_RESULT: 'TAX_GET_RESULT',
    GENERAL_FEE_RESULT: 'GENERAL_FEE_GET_RESULT',
    DISPOSAL_FEE_RESULT: 'DISPOSAL_FEE_GET_RESULT',
    CONFIGURATOR_SERVICES: 'CONFIGURATOR_GET_SERVICES',
    VALIDATE_PRICING_SERVICES: 'VALIDATE_PRICING_GET_SERVICES',
    RE_VALIDATE_PRICING_SERVICES: 'RE_VALIDATE_PRICING_GET_SERVICES',
    CREATE_PRICING_SERVICES: 'CREATE_PRICING_GET_SERVICES',
    VALIDATE_TAX_SERVICES: 'VALIDATE_TAX_GET_SERVICES',
    RE_VALIDATE_TAX_SERVICES: 'RE_VALIDATE_TAX_GET_SERVICES',
    VALIDATE_GENERAL_FEE_SERVICES: 'VALIDATE_GENERAL_FEE_GET_SERVICES',
    RE_VALIDATE_GENERAL_FEE_SERVICES: 'RE_VALIDATE_GENERAL_FEE_GET_SERVICES',
    VALIDATE_DISPOSAL_FEE_SERVICES: 'VALIDATE_DISPOSAL_FEE_GET_SERVICES',
    RE_VALIDATE_DISPOSAL_FEE_SERVICES: 'RE_VALIDATE_DISPOSAL_FEE_GET_SERVICES',
    CREATE_TAX_SERVICES: 'CREATE_TAX_GET_SERVICES',
    CREATE_GENERAL_FEE_SERVICES: 'CREATE_GENERAL_FEE_GET_SERVICES',
    GENERAL_FEE_MATCHED_SERVICES: 'GENERAL_FEE_MATCHED_GET_SERVICES',
    TAXES_MATCHED_SERVICES: 'TAXES_MATCHED_SERVICES',
    CREATE_DISPOSAL_FEE_SERVICES: 'CREATE_DISPOSAL_FEE_GET_SERVICES',
    FETCH_SERVICE_REVENUE_BY_ID: 'FETCH_SERVICE_REVENUE_BY_GET_ID',
    FETCH_GENERAL_FEE_REVENUE_BY_ID: 'FETCH_GENERAL_FEE_REVENUE_BY_GET_ID',
    FETCH_DISPOSAL_FEE_REVENUE_BY_ID: 'FETCH_DISPOSAL_FEE_REVENUE_BY_GET_ID',
    UPDATE_SERVICE_BY_ID: 'UPDATE_SERVICE_BY_GET_ID',
    UPDATE_TAX_BY_ID: 'UPDATE_TAX_BY_GET_ID',
    UPDATE_GENERAL_FEE_BY_ID: 'UPDATE_GENERAL_FEE_BY_GET_ID',
    UPDATE_DISPOSAL_FEE_BY_ID: 'UPDATE_DISPOSAL_FEE_BY_GET_ID',
    SERVICE_LINKED_LIST: 'SERVICE_LINKED_GET_LIST',
    LINKED_SERVICE_UPDATE: 'LINKED_SERVICE_GET_UPDATE',
    TAX_LINKED_LIST: 'TAX_LINKED_GET_LIST',
    LINKED_TAX_UPDATE: 'LINKED_TAX_GET_UPDATE',
    GENERAL_FEE_LINKED_LIST: 'GENERAL_FEE_LINKED_GET_LIST',
    LINKED_GENERAL_FEE_UPDATE: 'LINKED_GENERAL_FEE_GET_UPDATE',
    DISPOSAL_FEE_LINKED_LIST: 'DISPOSAL_FEE_LINKED_GET_LIST',
    LINKED_DISPOSAL_FEE_UPDATE: 'LINKED_DISPOSAL_FEE_GET_UPDATE',
    PRICING_STATUS_CHANGE: 'PRICING_STATUS_GET_CHANGE',
    PRICING_SUMMARY: 'PRICING_GET_SUMMARY',
    PRICING_REVENUE: 'PRICING_GET_REVENUE',
    PRICING_FILTERS_META: 'PRICING_FILTERS_GET_META',
  },
  PUT: {
    PRICING_RESULT: 'PRICING_PUT_RESULT',
    TAX_RESULT: 'TAX_PUT_RESULT',
    CONFIGURATOR_SERVICES: 'CONFIGURATOR_PUT_SERVICES',
    CONFIGURATOR_TAXES: 'CONFIGURATOR_PUT_TAXES',
    SAVED_CONFIGURATOR_SERVICES: 'SAVED_CONFIGURATOR_PUT_SERVICES',
    SERVICE_CONFIG_RESULTS: 'SERVICE_CONFIG_PUT_RESULTS',
    TAX_CONFIG_RESULTS: 'TAX_CONFIG_PUT_RESULTS',
    ALL_CONFIG_RESULTS: 'ALL_CONFIG_PUT_RESULTS',
    VALIDATE_PRICING_SERVICES: 'VALIDATE_PRICING_PUT_SERVICES',
    CREATE_PRICING_SERVICES: 'CREATE_PRICING_PUT_SERVICES',
    VALIDATE_TAX_SERVICES: 'VALIDATE_TAX_PUT_SERVICES',
    CREATE_TAX_SERVICES: 'CREATE_TAX_PUT_SERVICES',
    PRICING_FILTERS_META: 'PRICING_FILTERS_PUT_META',
  },
}

export const getPricingServices = (body, onSuccess) => ({
  type: actions.GET.PRICING_RESULT,
  payload: { body, onSuccess },
})

export const getPricingTableContent = (body, onSuccess) => ({
  type: actions.GET.PRICING_TABLE_CONTENT,
  payload: { body, onSuccess },
})

export const putPricingServices = res => ({
  type: actions.PUT.PRICING_RESULT,
  payload: res,
})

export const getTaxServices = (body, onSuccess) => ({
  type: actions.GET.TAX_RESULT,
  payload: { body, onSuccess },
})

export const putTaxServices = res => ({
  type: actions.PUT.TAX_RESULT,
  payload: res,
})

export const getGeneralFeesServices = (body, onSuccess) => ({
  type: actions.GET.GENERAL_FEE_RESULT,
  payload: { body, onSuccess },
})

export const getDisposalFeesServices = (body, onSuccess) => ({
  type: actions.GET.DISPOSAL_FEE_RESULT,
  payload: { body, onSuccess },
})

export const getconfiguratorServicesList = data => ({
  type: actions.PUT.CONFIGURATOR_SERVICES,
  payload: data,
})

export const putconfiguratorServicesList = res => ({
  type: actions.PUT.CONFIGURATOR_SERVICES,
  payload: res,
})

export const getServiceConfigResults = body => ({
  type: actions.PUT.SERVICE_CONFIG_RESULTS,
  payload: body,
})

export const getAllConfigResults = body => ({
  type: actions.PUT.ALL_CONFIG_RESULTS,
  payload: body,
})

export const getSavedServicesConfList = data => ({
  type: actions.PUT.SAVED_CONFIGURATOR_SERVICES,
  payload: data,
})

export const getValidatePricingServices = (body, onSuccess) => ({
  type: actions.GET.VALIDATE_PRICING_SERVICES,
  payload: { body, onSuccess },
})

export const getReValidatePricingServices = (body, onSuccess) => ({
  type: actions.GET.RE_VALIDATE_PRICING_SERVICES,
  payload: { body, onSuccess },
})

export const putValidatePricingServices = res => ({
  type: actions.PUT.VALIDATE_PRICING_SERVICES,
  payload: res,
})

export const getCreatePricingServices = (body, onSuccess) => ({
  type: actions.GET.CREATE_PRICING_SERVICES,
  payload: { body, onSuccess },
})

export const putCreatePricingServices = res => ({
  type: actions.PUT.CREATE_PRICING_SERVICES,
  payload: res,
})

export const getValidateTaxServices = (body, onSuccess) => ({
  type: actions.GET.VALIDATE_TAX_SERVICES,
  payload: { body, onSuccess },
})

export const getReValidateTaxServices = (body, onSuccess) => ({
  type: actions.GET.RE_VALIDATE_TAX_SERVICES,
  payload: { body, onSuccess },
})

export const putValidateTaxServices = res => ({
  type: actions.PUT.VALIDATE_TAX_SERVICES,
  payload: res,
})

export const getCreateTaxServices = (body, onSuccess) => ({
  type: actions.GET.CREATE_TAX_SERVICES,
  payload: { body, onSuccess },
})

export const putCreateTaxServices = res => ({
  type: actions.PUT.CREATE_TAX_SERVICES,
  payload: res,
})

export const getGeneralValidateFeesServices = (body, onSuccess) => ({
  type: actions.GET.VALIDATE_GENERAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getReGeneralValidateFeesServices = (body, onSuccess) => ({
  type: actions.GET.RE_VALIDATE_GENERAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getDisposalValidateFeesServices = (body, onSuccess) => ({
  type: actions.GET.VALIDATE_DISPOSAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getReDisposalValidateFeesServices = (body, onSuccess) => ({
  type: actions.GET.RE_VALIDATE_DISPOSAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getPricingSummary = (body, onSuccess) => ({
  type: actions.GET.PRICING_SUMMARY,
  payload: { body, onSuccess },
})

export const getPricingRevenue = (body, onSuccess) => ({
  type: actions.GET.PRICING_REVENUE,
  payload: { body, onSuccess },
})

export const getGeneralFeesCreateServices = (body, onSuccess) => ({
  type: actions.GET.CREATE_GENERAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getGeneralMatchedFeesServices = (body, onSuccess) => ({
  type: actions.GET.GENERAL_FEE_MATCHED_SERVICES,
  payload: { body, onSuccess },
})

export const getTaxesMatchedServices = (body, onSuccess) => ({
  type: actions.GET.TAXES_MATCHED_SERVICES,
  payload: { body, onSuccess },
})

export const getDisposalFeesCreateServices = (body, onSuccess) => ({
  type: actions.GET.CREATE_DISPOSAL_FEE_SERVICES,
  payload: { body, onSuccess },
})

export const getServiceRevenueById = (body, onSuccess) => ({
  type: actions.GET.FETCH_SERVICE_REVENUE_BY_ID,
  payload: { body, onSuccess },
})

export const getGeneralFeeRevenueById = (body, onSuccess) => ({
  type: actions.GET.FETCH_GENERAL_FEE_REVENUE_BY_ID,
  payload: { body, onSuccess },
})

export const getDisposalFeeRevenueById = (body, onSuccess) => ({
  type: actions.GET.FETCH_DISPOSAL_FEE_REVENUE_BY_ID,
  payload: { body, onSuccess },
})

export const updateServiceById = (body, onSuccess) => ({
  type: actions.GET.UPDATE_SERVICE_BY_ID,
  payload: { body, onSuccess },
})

export const updateTaxById = (body, onSuccess) => ({
  type: actions.GET.UPDATE_TAX_BY_ID,
  payload: { body, onSuccess },
})

export const updateGeneralFeeById = (body, onSuccess) => ({
  type: actions.GET.UPDATE_GENERAL_FEE_BY_ID,
  payload: { body, onSuccess },
})

export const updateDisposalFeeById = (body, onSuccess) => ({
  type: actions.GET.UPDATE_DISPOSAL_FEE_BY_ID,
  payload: { body, onSuccess },
})

export const getServiceLinkList = (body, onSuccess) => ({
  type: actions.GET.SERVICE_LINKED_LIST,
  payload: { body, onSuccess },
})

export const getLinkServiceUpdate = (body, onSuccess) => ({
  type: actions.GET.LINKED_SERVICE_UPDATE,
  payload: { body, onSuccess },
})

export const getTaxLinkList = (body, onSuccess) => ({
  type: actions.GET.TAX_LINKED_LIST,
  payload: { body, onSuccess },
})

export const getLinkTaxUpdate = (body, onSuccess) => ({
  type: actions.GET.LINKED_TAX_UPDATE,
  payload: { body, onSuccess },
})

export const getGeneralFeeLinkList = (body, onSuccess) => ({
  type: actions.GET.GENERAL_FEE_LINKED_LIST,
  payload: { body, onSuccess },
})

export const getLinkGeneralFeeUpdate = (body, onSuccess) => ({
  type: actions.GET.LINKED_GENERAL_FEE_UPDATE,
  payload: { body, onSuccess },
})

export const getDisposalFeeLinkList = (body, onSuccess) => ({
  type: actions.GET.DISPOSAL_FEE_LINKED_LIST,
  payload: { body, onSuccess },
})

export const getLinkDisposalFeeUpdate = (body, onSuccess) => ({
  type: actions.GET.LINKED_DISPOSAL_FEE_UPDATE,
  payload: { body, onSuccess },
})

export const getPricingStatusChange = (body, onSuccess) => ({
  type: actions.GET.PRICING_STATUS_CHANGE,
  payload: { body, onSuccess },
})

export const getPricingFilteresMeta = body => ({
  type: actions.GET.PRICING_FILTERS_META,
  payload: { body },
})

export const putPricingFiltersMeta = res => ({
  type: actions.PUT.PRICING_FILTERS_META,
  payload: res,
})
