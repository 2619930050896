import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/lodash'
import T from 'T'
import { Photo } from '@mui/icons-material'
import ExceptionImageFromPath from 'components/work-order/details/exceptions/ExceptionImageFromPath'
import { Dialog, useMediaQuery, styled, Box, IconButton as MuiIconButton, Grid, Typography, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import HHDialogTitle from 'components/common/HHDialogTitle'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SwiperContainer from 'components/work-order/details/exceptions/SwiperContainer'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import ExceptionChip from 'components/exceptions/ExceptionChip'

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    overflow: 'hidden',
    margin: 0,
    minHeight: '100%',
  },
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
  },
})

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  opacity: '0.7',
  background: theme.palette.background.paper,
  '&.Mui-disabled': {
    background: theme.palette.background.paper,
  },
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 20,
  height: 20,
}))

const WorkOrdersPhotos = ({ isOpenDrawer = false, exceptions = [], onClose }) => {
  const swiperRef = useRef(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const createdAt = get(exceptions[currentIndex], 'createdAt')
  const { fullDateTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const exceptionType = get(exceptions[currentIndex], 'exceptionType')
  const noteText = get(exceptions[currentIndex], 'noteText', '-')

  const handleSwiperPrev = useCallback(
    e => {
      const swiper = swiperRef?.current?.swiper
      swiper?.slidePrev(300)
      swiper?.update()
    },
    [isOpenDrawer]
  )

  const handleSwiperNext = useCallback(
    e => {
      const swiper = swiperRef?.current?.swiper
      e.stopPropagation()
      swiper?.slideNext(300)
      swiper?.update()
    },
    [isOpenDrawer]
  )

  const updateSliceState = swiper => {
    const { isEnd, isBeginning, realIndex } = swiper
    setIsEnd(isEnd)
    setIsBeginning(isBeginning)
    setCurrentIndex(realIndex)
  }

  useEffect(() => {
    return () => {
      setIsEnd(false)
      setIsBeginning(true)
      setCurrentIndex(0)
    }
  }, [isOpenDrawer, swiperRef])

  return (
    <StyledDialog
      sx={{
        zIndex: theme.zIndex.snackbar,
      }}
      fullScreen={isTabletOrMobile}
      fullWidth={!isTabletOrMobile}
      maxWidth="md"
      open={isOpenDrawer}
      onClose={onClose}
    >
      <HHDialogTitle title={T.WO_PHOTOS} showCloseIcon onClose={onClose} />
      <Box height={680} overflow="hidden" position="relative">
        {isOpenDrawer && (
          <SwiperContainer
            swiperRef={swiperRef}
            sx={{
              '.swiper-pagination-bullet': {
                opacity: 0.5,
                background: theme.palette.common.white,
              },
              '.swiper-pagination-bullet-active': {
                opacity: 1,
                background: theme.palette.common.white,
              },
            }}
            onReachEnd={updateSliceState}
            onSlideChange={updateSliceState}
          >
            {Array.isArray(exceptions) && exceptions.length === 0 && (
              <Box bgcolor={grey[200]} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Photo sx={{ fontSize: 40, color: grey[600] }} />
              </Box>
            )}
            {Array.isArray(exceptions) &&
              exceptions.length > 0 &&
              exceptions.map(exception => {
                const path = get(exception, 'fileLocation')
                return (
                  <ExceptionImageFromPath
                    height="100%"
                    minHeight="675px"
                    withSwiperSlide
                    size="medium"
                    path={path}
                    exceptionType={exceptionType}
                  />
                )
              })}
          </SwiperContainer>
        )}

        {Array.isArray(exceptions) && exceptions.length > 1 && (
          <>
            <Box
              bottom="calc(50% - 12px)"
              left="4px"
              margin="0 auto"
              marginRight="-28px"
              position="absolute"
              transform="translate(-50%, -50%)"
              zIndex={1}
            >
              <IconButton disabled={isBeginning} onClick={handleSwiperPrev}>
                <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Box>
            <Box
              bottom="calc(50% - 12px)"
              margin="0 auto"
              marginLeft="-28px"
              position="absolute"
              right="4px"
              transform="translate(-50%, -50%)"
              zIndex={1}
            >
              <IconButton disabled={isEnd} sx={{ ml: 1 }} onClick={handleSwiperNext}>
                <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
      <Box mt={2} mx={2}>
        <Grid container rowGap={1}>
          <Grid container item columnGap={2} xs={12}>
            <Grid item xs={12}>
              <Typography color="textSecondary" fontWeight={500}>
                {T.DATE_AND_TIME}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{`${fullDateTime || '-'}`}</Typography>
            </Grid>
          </Grid>
          <Grid container item columnGap={2} xs={12}>
            <Grid item xs={12}>
              <Typography color="textSecondary" fontWeight={500}>
                {T.EXCEPTION}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <ExceptionChip size="small" exception={exceptionType} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item columnGap={2} xs={12}>
            <Grid item xs={12}>
              <Typography color="textSecondary" fontWeight={500}>
                {T.NOTE}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{`${noteText || '-'}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledDialog>
  )
}

WorkOrdersPhotos.propTypes = {
  isOpenDrawer: PropTypes.bool,
  exceptions: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default WorkOrdersPhotos
