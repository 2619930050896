import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useForm, useFormState } from 'react-hook-form'
import { HHFormTextField, HHFormSelectField, HHFormSwitchField } from 'components/form-fields/v5'
import { IS_ALPHABET_NUMERIC_AND_SPACE } from 'utils/validations'
import { useEditPermitMutation } from 'api/settings/editPermit'

const PERMIT_TYPES = ['CLASS A', 'CLASS B', 'TYPE A', 'TYPE B', 'TYPE C', 'TYPE D', 'TYPE R']

const EditPermitTableRow = ({ onCancel, onEditSuccess, id, permitName, permitType, active }) => {
  const { control, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      id,
      permitName,
      permitType,
      active,
    },
  })
  const { isValid } = useFormState({ control })
  const [editPermit, { isSuccess }] = useEditPermitMutation()

  React.useEffect(() => {
    if (isSuccess) onEditSuccess()
  }, [isSuccess])

  return (
    <TableRow>
      <TableCell p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <CancelIcon fontSize="small" onClick={onCancel} sx={{ mr: 1, cursor: 'pointer' }} />
        <HHFormTextField
          size="small"
          control={control}
          name="permitName"
          onKeyPress={e => {
            if (e.key === 'Enter' && isValid) {
              e.preventDefault()
              const { permitName, permitType, active } = getValues()
              editPermit({
                id,
                body: {
                  permitName: permitName.trim(),
                  permitType,
                  active,
                },
              })
            }
          }}
          required
          regexValidators={[IS_ALPHABET_NUMERIC_AND_SPACE]}
        />
      </TableCell>
      <TableCell p={1}>
        <HHFormSelectField
          size="small"
          control={control}
          options={PERMIT_TYPES}
          getOptionLabel={option => option}
          getOptionValue={option => option}
          name="permitType"
          required
        />
      </TableCell>
      <TableCell>
        <HHFormSwitchField control={control} name="active" />
      </TableCell>
    </TableRow>
  )
}

EditPermitTableRow.propTypes = {
  onEditSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  permitName: PropTypes.string,
  permitType: PropTypes.string,
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

export default EditPermitTableRow
