import React, { Children, useEffect, useState } from 'react'
import { List, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get, includes, filter } from 'lodash'
import { getCustomerDetails } from 'middleware/actions/customers'
import HHAccordion from 'components/common/HHAccordion'
import HoldListItem from 'components/customer-details/content/holds/HoldListItem'
import HoldsFormDialog from 'components/customer-details/content/holds/HoldsFormDialog'
import { useDeleteVacationHoldMutation, useLazyGetVacationHoldListQuery } from 'api/accounts/vacationHoldsCrud'
import { handleError } from 'utils/error'
import HoldActionMenu from 'components/customer-details/content/holds/HoldActionMenu'
import { formatDateToFEFormat } from 'utils/date'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import api from 'api'
import { CACHE_TAG_VACATION_HOLD_LIST, CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import SkeletonHoldListItem from 'components/customer-details/content/holds/SkeletonHoldListItem'

const HoldsAccordion = ({ accountId }) => {
  const dispatch = useDispatch()
  const [formMode, setFormMode] = useState('create')
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedHold, setSelectedHold] = useState(null)
  const [getVacationHoldList, { data, isLoading, isFetching, isSuccess }] = useLazyGetVacationHoldListQuery()
  const [deleteHold, { isLoading: isDeleteLoading }] = useDeleteVacationHoldMutation()
  const loading = isLoading || isFetching
  const vacationHoldList = get(data, 'vacationHolds', [])
  const selectedHoldId = get(selectedHold, 'id')
  const selectedLocationId = useSelector(state => get(state, 'CustomerDetails.selectedLocationId', ''))
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)

  const filterVacationHoldListByLocationId = () => {
    if (selectedLocationId === '') return vacationHoldList
    return filter(vacationHoldList, vacationHold => includes(get(vacationHold, 'locations', []), selectedLocationId))
  }

  const filteredVacationHoldList = filterVacationHoldListByLocationId()

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleConfirmDelete = () => {
    deleteHold({ vacationHoldId: selectedHoldId, accountId })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        dispatch(api.util.invalidateTags([CACHE_TAG_VACATION_HOLD_LIST, CACHE_TAG_WORK_ORDER_LIST]))
      })
    setOpenDeleteDialog(false)
  }
  const handleAddClick = () => {
    setFormMode('create')
    setOpenFormDialog(true)
  }

  const handleFormDialogClose = () => {
    setOpenFormDialog(false)
    setFormMode('create')
  }

  const handleOpenActionMenu = (e, hold) => {
    setSelectedHold(hold)
    setAnchorEl(e.target)
  }

  const handleCloseActionMenu = () => {
    setAnchorEl(null)
  }

  const handleEditActionClick = () => {
    setFormMode('update')
    setOpenFormDialog(true)
  }

  const handleDeleteActionClick = () => {
    setOpenDeleteDialog(true)
  }

  useEffect(() => {
    if (!accountId) return
    getVacationHoldList({ accountId, deleted: false }).unwrap().catch(handleError)
  }, [accountId])

  return (
    <>
      <HHAccordion withNewColors summary="Vacation hold" actionType="add" showActionIcon isExpanded onActionClick={handleAddClick}>
        {loading && (
          <List>
            <SkeletonHoldListItem divider />
            <SkeletonHoldListItem divider />
            <SkeletonHoldListItem />
          </List>
        )}
        {!loading && isSuccess && filteredVacationHoldList.length === 0 && (
          <HHSectionPlaceholder title="Click the + button to add an account hold" />
        )}
        {!loading && isSuccess && filteredVacationHoldList.length !== 0 && (
          <List>
            {Children.toArray(
              filteredVacationHoldList.map((vacationHold, index) => {
                const type = get(vacationHold, 'type')
                const id = get(vacationHold, 'id')
                const title = get(vacationHold, 'title')
                const startDate = get(vacationHold, 'startDate')
                const endDate = get(vacationHold, 'endDate')
                const startDateFEFormat = formatDateToFEFormat(startDate)
                const endDateFEFormat = formatDateToFEFormat(endDate)
                const description = startDateFEFormat === endDateFEFormat ? startDateFEFormat : `${startDateFEFormat} - ${endDateFEFormat}`
                const divider = filteredVacationHoldList.length - 1 !== index && filteredVacationHoldList.length > 1
                return (
                  <HoldListItem
                    hold={vacationHold}
                    key={`${id}-hold-list-item`}
                    type={type}
                    primary={title}
                    secondary={description}
                    divider={divider}
                    onOpenActionMenu={handleOpenActionMenu}
                  />
                )
              })
            )}
          </List>
        )}
        <HoldActionMenu
          accountId={accountId}
          selectedHold={selectedHold}
          anchorEl={anchorEl}
          onClose={handleCloseActionMenu}
          onEdit={handleEditActionClick}
          onDelete={handleDeleteActionClick}
        />
        <HHConfirmDialog
          ConfirmButtonProps={{ loading: isDeleteLoading }}
          DialogProps={{ maxWidth: 'xs', fullWidth: true }}
          isOpen={openDeleteDialog}
          confirmTitle="Confirm hold deletion"
          confirmDescription={<Typography component="p">Are you sure you want to continue?</Typography>}
          onConfirm={handleConfirmDelete}
          onClose={handleCloseDeleteDialog}
        />
        <HoldsFormDialog
          customerDetails={customerDetails}
          accountId={accountId}
          open={openFormDialog}
          mode={formMode}
          selectedHold={selectedHold}
          onClose={handleFormDialogClose}
          onSave={handleFormDialogClose}
        />
      </HHAccordion>
    </>
  )
}

HoldsAccordion.propTypes = {
  accountId: PropTypes.string.isRequired,
}

export default HoldsAccordion
