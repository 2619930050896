import React, { useState } from 'react'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogActions from 'components/common/HHDialogActions'
import PaymentTermsConditions from 'components/customers/PaymentTermsConditions'

const AUTOPAY_FORMER_TEXT = 'AutoPay setup may require customers to review and accept'
const AUTOPAY_LATER_TEXT = '. Based on your local regulations, you may need to get explicit, documented approval before enabling AutoPay.'

const AutopayDisclaimer = () => {
  const [isOpen, setIsopen] = useState(false)

  const handleClose = () => setIsopen(false)

  return (
    <>
      <Alert severity="info" sx={{ mt: 2, color: 'primary.main', '& strong': { textDecoration: 'underline', cursor: 'pointer' } }}>
        {AUTOPAY_FORMER_TEXT} <strong onClick={() => setIsopen(true)}>{T.TERMS_CONDITIONS}</strong>
        {AUTOPAY_LATER_TEXT}
      </Alert>

      <HHBaseDialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogContent>
          <DialogContentText>
            <PaymentTermsConditions />
          </DialogContentText>
        </DialogContent>
        <HHDialogActions>
          <Button size="small" variant="contained" onClick={handleClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

export default AutopayDisclaimer
