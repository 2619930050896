import React from 'react'
import { Grid, Card, CardContent, Typography, Skeleton, Stack } from '@mui/material'

const SkeletonConfiguredService = () => {
  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <CardContent
          sx={{
            px: 2,
            py: 1,
            '&:last-child': {
              pb: 1,
            },
          }}
        >
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Grid container item xs alignItems="center" rowSpacing={0.5}>
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600}>
                  <Skeleton width="15rem" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={1}>
                  <Skeleton width="5rem" height="2rem" variant="rectangular" />
                  <Skeleton width="8rem" height="2rem" variant="rectangular" />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  <Skeleton width="10rem" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs="auto" alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600} textAlign="right">
                  <Skeleton width="6rem" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                  <Skeleton width="2rem" height="2rem" variant="circular" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SkeletonConfiguredService
