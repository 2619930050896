import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@mui/icons-material/Close'
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { ReactComponent as HoldIcon } from 'assets/work-orders/HoldIcon.svg'

import { get } from 'utils/lodash'
import { WORK_ORDER_STATUS, WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { Check } from '@mui/icons-material'
import { SvgIcon, Avatar, Chip, useTheme } from '@mui/material'
import { ALPHA_38_PERCENT } from 'theme/colors'

const { SERVICED, SKIPPED, IN_PROCESS, CANCELED, HOLD } = WORK_ORDER_STATUS
const CHIP_SX = { color: 'background.paper', width: 12, height: 12 }

const WorkOrderStatusChip = ({ status }) => {
  const theme = useTheme()
  const getWOStatusChipIcon = () => {
    if (status === CANCELED) return <CloseIcon sx={CHIP_SX} />
    if (status === SERVICED) return <Check sx={CHIP_SX} />
    if (status === SKIPPED) return <FastForwardOutlinedIcon sx={CHIP_SX} />
    if (status === IN_PROCESS) return <PlayCircleOutlineOutlinedIcon sx={CHIP_SX} />
    if (status === HOLD)
      return (
        <SvgIcon inheritViewBox viewBox="0 0 12 10" sx={{ color: 'background.paper', width: 14, height: 14 }}>
          <HoldIcon />
        </SvgIcon>
      )
    // SCHEDULED
    return <CalendarTodayOutlinedIcon sx={CHIP_SX} />
  }

  return (
    <Chip
      size="small"
      avatar={<Avatar sx={{ backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}` }}>{getWOStatusChipIcon()}</Avatar>}
      label={status}
      sx={{
        backgroundColor: get(WORK_ORDER_STATUS_DISPLAY, `${status}.bgColor`),
        ...(status === HOLD && { '& .MuiChip-avatar': { color: 'background.paper' } }),
      }}
    />
  )
}

WorkOrderStatusChip.propTypes = {
  status: PropTypes.string,
}

export default WorkOrderStatusChip
