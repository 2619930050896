import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS } from 'api/cacheTagTypes'
import {
  getAccountStatusGraphDataFromStatistics,
  getBillingProfileGraphDataFromStatistics,
} from 'components/customers/groups/account/common/settings'
import isEmpty from 'lodash/isEmpty'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getAccountGroupStatistics: build.query({
      query: ({ id, includeTags = true, includeContacts = false, includeDeactivated = true }) => ({
        url: `/api/v1/core/accounts/groups/${id}/statistics`,
        method: 'GET',
        params: { includeTags, includeContacts, includeDeactivated },
      }),
      providesTags: (result, error, { id }) => [
        CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
        { type: CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS, id },
      ],
      transformResponse: data => {
        const { accountStatus, billingProfiles } = data || {}
        const { topProfiles, cutOffCount } = isEmpty(accountStatus)
          ? { topProfiles: undefined, cutOffCount: 0 }
          : getBillingProfileGraphDataFromStatistics(billingProfiles)
        return {
          accountStatus,
          billingProfiles,
          accountStatusGraphData: isEmpty(accountStatus) ? undefined : getAccountStatusGraphDataFromStatistics(accountStatus),
          billingProfilesGraphData: topProfiles,
          cutOffBillingProfiles: cutOffCount,
        }
      },
    }),
  }),
})

export const { useGetAccountGroupStatisticsQuery, useLazyGetAccountGroupStatisticsQuery } = extendedApi
