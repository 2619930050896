import api from 'api'
import { CACHE_TAG_ACCOUNT_LOCATION_DETAILS, CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    activateDeactivateLocations: build.mutation({
      query: body => ({ url: '/api/location/activate', method: 'POST', body }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_LOCATION_LIST, CACHE_TAG_ACCOUNT_LOCATION_DETAILS],
    }),
  }),
})

export const { useActivateDeactivateLocationsMutation } = extendedApi
