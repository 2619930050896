import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import compact from 'lodash/compact'
import { NumericFormat } from 'react-number-format'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { Table as TableView, TableContainer, TableRow, TableHead as THead, TableCell, TableBody as TBody } from '@mui/material'

import { getServiceHoverTitle } from 'components/pricing/pricingMethods'

import Constants from 'Constants'
import ToolTip from 'components/common/Tooltip'
import TiersSection from 'components/pricing/Fees/TiersSection'
import HHSwitchField from 'components/form-fields/v5/HHSwitchField'

import T from 'T'

const { MEDIA_SERVER } = Constants

const ChangeDisposalPrice = ({
  selectedValues = [],
  tierCount = {},
  setTierCount = noop,
  handleChangePriceDelete = noop,
  onHandleEditFieldsChange = noop,
  onHandleTiersChange = noop,
  handleTiers = noop,
  onHandleMeasureChange = noop,
}) => {
  return (
    <TableContainer className="change-pricing-table">
      <TableView stickyHeader aria-label="sticky table">
        <THead className="table-header">
          <TableRow>
            <TableCell>{T.DISPOSAL_FEE}</TableCell>
            <TableCell>{T.COST_PLUS}</TableCell>
            <TableCell>{T.PRICE}</TableCell>

            <TableCell>{T.MEASURE}</TableCell>
            <TableCell>{T.MINIMUM_FEE}</TableCell>
            <TableCell>{T.OVERAGE}</TableCell>
            <TableCell>{T.FEE}</TableCell>
            <TableCell />
          </TableRow>
        </THead>

        <TBody className="table-body">
          {selectedValues &&
            Children.toArray(
              compact(selectedValues).map((service, index) => {
                // Need to validate below logic on why we need to trigger setstate here?
                if (tierCount[index] === undefined) {
                  tierCount[index] = 0
                }

                tierCount[index] = tierCount[index] + 1
                setTierCount(tierCount)

                const hoverTitle = getServiceHoverTitle(service)

                return (
                  <TableRow>
                    <TableCell>
                      <div className="services-field">
                        <ToolTip title={hoverTitle}>
                          <TextField
                            id="outlined-size-small"
                            className="change-price"
                            value={service.feeName}
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            name="feeName"
                            InputProps={{
                              onChange: e => onHandleEditFieldsChange(e, service),
                            }}
                          />
                        </ToolTip>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* Ideally we should also pass checked but old logic is already getting existing subItem through loop */}
                      {/* It's an common handler, so not changing it. */}
                      <HHSwitchField checked={service.costPlus} onChange={() => onHandleEditFieldsChange('costPlus', service)} />
                    </TableCell>

                    <TableCell colSpan="2">
                      <TiersSection
                        index={index}
                        fee={service}
                        tierCount={tierCount}
                        onHandleTiersChange={onHandleTiersChange}
                        handleTiers={handleTiers}
                        onHandleMeasureChange={onHandleMeasureChange}
                        type="edit"
                      />
                    </TableCell>

                    <TableCell>
                      <div className="prices-field">
                        <NumericFormat
                          id="outlined-size-small"
                          placeholder="0.00"
                          value={service.minFee}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          name="minFee"
                          onChange={e => onHandleEditFieldsChange(e, service, 'price')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          customInput={TextField}
                          thousandSeparator
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="prices-field">
                        <NumericFormat
                          id="outlined-size-small"
                          placeholder="0.00"
                          value={service.overageThreshold}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          name="overageThreshold"
                          onChange={e => onHandleEditFieldsChange(e, service, 'integer')}
                          customInput={TextField}
                          thousandSeparator
                          decimalSeparator={false}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="prices-field">
                        <NumericFormat
                          id="outlined-size-small"
                          placeholder="0.00"
                          value={service.overageFee}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          name="overageFee"
                          onChange={e => onHandleEditFieldsChange(e, service, 'price')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          customInput={TextField}
                          thousandSeparator
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleChangePriceDelete(service, selectedValues)} />
                    </TableCell>
                  </TableRow>
                )
              })
            )}
        </TBody>
      </TableView>
    </TableContainer>
  )
}

ChangeDisposalPrice.propTypes = {
  selectedValues: PropTypes.array,
  tierCount: PropTypes.object,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
  onHandleTiersChange: PropTypes.func,
  handleTiers: PropTypes.func,
  onHandleMeasureChange: PropTypes.func,
  setTierCount: PropTypes.func,
}

export default ChangeDisposalPrice
