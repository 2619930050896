import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import { lightBlue } from '@mui/material/colors'

import T from 'T'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { LoadingButton } from '@mui/lab'

const DialogActions = ({
  accountId,
  isFirstStep = true,
  isSingleStep = false,
  paymentMethod,
  requestedPayments = [],
  onClose,
  onNextBtnClick,
  onPostBtnClick,
  onSummaryBtnClick,
  onNewPaymentClick,
  isPostLoading = false,
  editPaymentRefId,
}) => {
  const totalSavedPayments = requestedPayments.length
  const isAddPaymentButtonDisabled = useMemo(() => !accountId || !paymentMethod, [accountId, paymentMethod])
  const isSummaryButtonDisabled = useMemo(
    () => isPostLoading || (requestedPayments.length === 0 && (!accountId || !paymentMethod)),
    [isPostLoading, requestedPayments.length, accountId, paymentMethod]
  )
  const summaryButtonHelperText = useMemo(() => {
    if (isPostLoading) return T.LOADING
    if (!accountId) return T.SELECT_A_CUSTOMER
    if (!paymentMethod) return T.SELECT_A_PAYMENT_METHOD
  }, [isPostLoading, accountId, paymentMethod])
  return (
    <HHDialogActions>
      <CancelButton size="medium" onClick={onClose} />
      <Box sx={{ flex: '1 1 auto' }} />

      {totalSavedPayments > 0 && <Typography color={lightBlue[400]}>{`${totalSavedPayments} saved`}</Typography>}

      {isFirstStep && (
        <>
          {isSingleStep ? (
            <LoadingButton disabled={!paymentMethod} size="small" variant="contained" onClick={onNextBtnClick}>
              {T.POST}
            </LoadingButton>
          ) : (
            <Stack flexDirection="row" alignItems="center" columnGap={1}>
              <Tooltip arrow placement="top" title={isSummaryButtonDisabled && summaryButtonHelperText}>
                <span>
                  <Button disabled={isSummaryButtonDisabled} size="small" variant="outlined" onClick={onSummaryBtnClick}>
                    {T.SUMMARY}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip arrow placement="top" title={isAddPaymentButtonDisabled && T.SELECT_A_CUSTOMER}>
                <span>
                  <Button disabled={isAddPaymentButtonDisabled} size="small" autoFocus variant="contained" onClick={onNextBtnClick}>
                    {editPaymentRefId ? 'Update payment' : 'Add payment'}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          )}
        </>
      )}

      {!isFirstStep && (
        <>
          <Button size="small" variant="outlined" onClick={onNewPaymentClick}>
            {T.ADD_PAYMENT}
          </Button>
          <LoadingButton
            disabled={isPostLoading || requestedPayments.length === 0}
            size="small"
            variant="contained"
            onClick={onPostBtnClick}
          >
            {T.POST}
          </LoadingButton>
        </>
      )}
    </HHDialogActions>
  )
}

DialogActions.propTypes = {
  isFirstStep: PropTypes.bool,
  isSingleStep: PropTypes.bool,
  paymentMethod: PropTypes.string.isRequired,
  requestedPayments: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onNextBtnClick: PropTypes.func.isRequired,
  onPostBtnClick: PropTypes.func.isRequired,
  onSummaryBtnClick: PropTypes.func.isRequired,
  onNewPaymentClick: PropTypes.func.isRequired,
  isPostLoading: PropTypes.bool,
  accountId: PropTypes.string,
  editPaymentRefId: PropTypes.string,
}

export default DialogActions
