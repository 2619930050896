import { useLazyGetAccountGroupDetailsQuery } from 'api/groups/account/getAccountGroupDetails'
import { useLazyGetAccountGroupDetailsByAgingBucketQuery } from 'api/groups/account/accountsByAgingBucketCrud'
import { useLazyGetSuspendedAccountsQuery } from 'api/groups/account/suspendedAccountsCrud'
import { useEffect, useMemo } from 'react'
import { getIsDefaultBucket, getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { get } from 'lodash'

export const useGetAccountGroupDetailsData = (id, preferCacheValue = false) => {
  const [getAccountGroupDetails, accountGroupDetails] = useLazyGetAccountGroupDetailsQuery()
  const [getAccountGroupDetailsByBucket, accountGroupDetailsByBucket] = useLazyGetAccountGroupDetailsByAgingBucketQuery()
  const [getSuspendedAccounts, suspendedAccounts] = useLazyGetSuspendedAccountsQuery()

  const fetchData = () => {
    if (!id) return

    if (getIsDefaultBucket(id)) {
      getAccountGroupDetailsByBucket({ aging: get(BUCKET_NAME_TO_GROUP_ID_PARAM, id) }, preferCacheValue)
    } else if (getIsSuspendedBucket(id)) {
      getSuspendedAccounts({ id: 'suspended' }, preferCacheValue)
    } else if (id) {
      getAccountGroupDetails({ id }, preferCacheValue)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  return useMemo(() => {
    if (!id) return {}

    if (getIsSuspendedBucket(id)) {
      return suspendedAccounts
    }
    if (getIsDefaultBucket(id)) {
      return accountGroupDetailsByBucket
    }
    return accountGroupDetails
  }, [id, suspendedAccounts, accountGroupDetailsByBucket, accountGroupDetails])
}
