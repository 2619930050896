import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerFollowUpById: build.query({
      query: body => ({ url: '/api/account/follow-up/view', method: 'POST', body }),
    }),
  }),
})

export const { useLazyGetCustomerFollowUpByIdQuery } = extendedApi
