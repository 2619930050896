import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import CommonDrawer from 'components/common/CommonDrawer'
import ToolTip from 'components/common/Tooltip'

import T from 'T'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'

const ReportDrawer = ({ isOpenDrawer = false, title = '', defaultReports = [], getTitle = noop, onClose, onClick = noop }) => {
  return (
    <CommonDrawer isOpen={isOpenDrawer} onChange={(event, isOpen) => onClose(isOpen)}>
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottomColor="background.tags"
        sx={{
          p: '12px 24px',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Typography variant="h4" fontSize="14px" noWrap>
          {title}
        </Typography>

        <CloseIcon fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => onClose(false)} />
      </Box>

      <Box
        pl={3}
        pr={3}
        sx={{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2" fontWeight="normal" sx={{ margin: '16px 12px 5px 12px' }}>
            {T.DATASET}
          </Typography>
          <Typography variant="subtitle2" fontWeight="normal" sx={{ margin: '16px 12px 5px 12px' }}>
            {T.PARAMETERS}
          </Typography>
        </Box>

        <Divider sx={{ mb: 1 }} />

        {Children.toArray(
          defaultReports.map(report => {
            const reportParentType = get(report, 'reportType', '')
            const reportName = getTitle(reportParentType)
            const count = get(report, 'columnCount', '')

            return (
              <Card variant="outlined" sx={{ width: 300, mb: 0.6, cursor: 'pointer' }}>
                <CardContent
                  sx={{
                    p: 1.3,
                    '&:last-child': {
                      pb: 1.3,
                    },
                  }}
                >
                  <Grid container onClick={() => onClick(reportName, reportParentType)}>
                    <Grid item xs={6}>
                      <ToolTip title={reportName}>
                        <Typography variant="h6" fontSize="13px" noWrap>
                          {reportName}
                        </Typography>
                      </ToolTip>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" align="right" fontSize="12px" sx={{ color: 'text.secondary' }}>
                        {count}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })
        )}
      </Box>
    </CommonDrawer>
  )
}

ReportDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
  title: PropTypes.string,
  defaultReports: PropTypes.array,
  getTitle: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

export default memo(ReportDrawer)
