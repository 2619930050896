import React from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { DialogContent, DialogContentText, Stack } from '@mui/material'
import { HHFormSwitchField } from 'components/form-fields/v5'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import { noop } from 'lodash'
import { useFormContext } from 'react-hook-form'

const GenericUnsuspendAccountDialog = ({
  open = false,
  onClose = noop,
  title = 'Unsuspend account',
  description = 'Are you sure you want to unsuspend this account',
  onSave,
  isSaveLoading = false,
}) => {
  const { control } = useFormContext()
  return (
    <HHBaseDialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title={title} onClose={onClose} showCloseIcon />
      <DialogContent>
        <DialogContentText color="textPrimary">{description}</DialogContentText>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          <HHFormSwitchField control={control} name="rescheduleWorkOrders" label="Unhold work orders" />
        </Stack>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton onClick={onSave} size="small" loading={isSaveLoading} variant="contained" color="success">
          Unsuspend
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

GenericUnsuspendAccountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  onSave: PropTypes.func,
}

export default GenericUnsuspendAccountDialog
