import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Box, Button, Divider as MuiDivider, Grid, styled, Typography, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import { orange } from '@mui/material/colors'
import { useSearchParams } from 'react-router-dom-v5-compat'
import lowerCase from 'lodash/lowerCase'
import BulkActionMenu from 'components/route-manager/BulkActions/BulkActionMenu'
import BulkMoveFailure from 'containers/new-route-manager/BulkMoveFailure'
import { FULL_PERMISSION, NO_ACCESS_PERMISSION } from 'settings/constants/roles'
import {
  changeViewMode,
  setIsOpenNewRouteDrawer,
  setResetSearch,
  setSelectedSearchResult,
  setSerializedServiceDate,
  setViewOnMapRouteId,
  toggleUnassignedStops,
  VIEW_MODE_MAP,
  VIEW_MODE_SEQUENCE,
  setStopDetailsDialogState,
  setStopDetailsPopoverState,
  setBulkMoveFailure,
  selectIsBulkMoveFailureOpen,
  selectBulkMoveFailureStops,
  selectRouteSerializedServiceDate,
} from 'slices/route/routeSlice'
import { putMarkerLocation } from 'middleware/actions/routeManager'
import { getViewMode } from 'data/route/viewModeSelector'
import LookupToggle, { LOOKUP_MODE_ADDRESS, LOOKUP_MODE_SEARCH } from 'containers/new-route-manager/Search/LookupToggle'
import Search from 'containers/new-route-manager/Search/Search'
import SearchAddress from 'containers/new-route-manager/Search/SearchAddress'
import { deserializeDate, formatDateToBEFormatDateFns, formatDateToURLFormatDateFns } from 'utils/date'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { isValidLatitude, isValidLatitudeRange, isValidLongitude, isValidLongitudeRange } from 'utils/validations'
import { useLazyGetRouteStopsUnassignedQuery } from 'api/route/getRouteStopsUnassigned'
import T from 'T'
import { getIsUnassignedStopsOpen } from 'data/route/unassignedStopsSelector'
import { combineAddressInfo } from 'utils/helper'
import StopDetailsDialog from 'containers/new-route-manager/stop-details/StopDetailsDialog'
import { MapContext } from 'providers/MapProvider'
import { flyToMap } from 'utils/map'
import RouteDetailsModal from '../RouteDetailsModal/RouteDetailsModal'
import RouteManagerActionsMenu from './RouteManagerActionsMenu'
import RouteManagerDatePicker from './RouteManagerDatePicker'
import RouteManagerTabs from './RouteManagerTabs'
import ScheduleStopDialog from '../ScheduleStop/ScheduleStopDialog'
import StopDetailsPopover from '../StopDetailsPopover'
import FiltersMenuButton from '../filters/FiltersMenuButton'

const Divider = styled(MuiDivider)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(2.4),
  '&::before': {
    width: 'calc(100% - 140px)',
  },
  '&::after': {
    width: 'calc(100% + 140px)',
  },
}))

const RouteManagerAppBar = () => {
  const map = useContext(MapContext)
  const theme = useTheme()
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  const isOpenNewRouteDrawer = useSelector(s => get(s, 'Route.newRouteDrawer.isOpen', false), shallowEqual)
  const [getRouteStops, { data }] = useLazyGetRouteStopsQuery()
  const [getRouteStopsUnassigned, { data: unassignedData }] = useLazyGetRouteStopsUnassignedQuery()
  const viewMode = useSelector(getViewMode, shallowEqual)
  const isUnassignedStopsOpen = useSelector(getIsUnassignedStopsOpen)
  const selectedRouteIds = useSelector(s => get(s, 'Route.filters.selectedRouteIds', []), shallowEqual)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const isTemporaryModeActive = useSelector(s => s.Route.isRouteChangesOnTemporaryMode)
  const routePermission = get(userInfo, 'profile.routeManagerCrud', NO_ACCESS_PERMISSION)
  const [startDate, setStartDate] = useState(new Date())
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const stopDetailsId = useSelector(s => get(s, 'Route.stopDetailsDialog.id'), shallowEqual)
  const refetchStopDetailsDialogData = useSelector(s => get(s, 'Route.stopDetailsDialog.refetchStop'), shallowEqual)
  const stopDetailsPopoverId = useSelector(s => get(s, 'Route.stopDetailsPopover.id'))
  const isOpenBulkMoveFailure = useSelector(selectIsBulkMoveFailureOpen)
  const bulkMoveFailureStops = useSelector(selectBulkMoveFailureStops)
  const initialState = {
    selectedRouteDefaultList: [],
    selectedRouteList: [],
    isNewRouteFormDirty: false,
    toggleSearchInput: LOOKUP_MODE_SEARCH,
  }

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState)

  const onResetSearch = (resetSearch = true) => {
    dispatch(setViewOnMapRouteId(''))
    dispatch(setResetSearch(resetSearch))
    dispatch(setSelectedSearchResult(null))
    dispatch(putMarkerLocation(null))
  }

  const onAddressSelected = value => {
    const longitude = get(value, 'center.longitude')
    const latitude = get(value, 'center.latitude')
    dispatch(putMarkerLocation(value))
    if (isValidLongitude(longitude) && isValidLatitude(latitude)) {
      flyToMap(map, longitude, latitude, true, 12)
    }
  }

  const onSearchRowClick = workOrder => {
    const workOrderId = get(workOrder, 'workOrderId')
    const latitude = get(workOrder, 'latitude', '')
    const longitude = get(workOrder, 'longitude', '')
    if (isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)) {
      dispatch(setSelectedSearchResult({ cord: [longitude, latitude] }))
    }
    dispatch(setStopDetailsDialogState({ id: workOrderId }))
  }

  const updateRoute = () => {
    dispatch(setSerializedServiceDate(new Date()))
  }

  const openNewRouteDrawer = () => {
    dispatch(setIsOpenNewRouteDrawer(true))
  }

  const closeNewRouteDrawer = () => {
    dispatch(setIsOpenNewRouteDrawer(false))
  }

  const onSaveNewRoute = () => {
    updateRoute()
    closeNewRouteDrawer()
  }

  const updateDateSearchParam = date => {
    searchParams.set('service_date', formatDateToURLFormatDateFns(date))
    setSearchParams(searchParams)
  }

  const handlePrevDate = () => {
    const prevDate = moment(serviceDate).subtract(1, 'days')
    const prevDateObj = prevDate.toDate()
    setStartDate({ startDate: prevDateObj })
    dispatch(setSerializedServiceDate(prevDateObj))
    updateDateSearchParam(prevDateObj)
  }

  const handleNextDate = () => {
    const nextDate = moment(startDate).add(1, 'days')
    const nextDateObj = nextDate.toDate()
    setStartDate({ startDate: nextDateObj })
    dispatch(setSerializedServiceDate(nextDateObj))
    updateDateSearchParam(nextDateObj)
  }

  const handleDateChange = inDate => {
    setStartDate(inDate)
    dispatch(setSerializedServiceDate(inDate))
    updateDateSearchParam(inDate)
  }

  const getStopById = (allStops, id) => allStops.find(stop => stop.workOrderId === id) || {}

  useEffect(() => {
    const routes = get(data, 'routes', [])
    const assignedSelectBoxRouteList = routes.flatMap(route => {
      if (Array.isArray(route?.stops)) {
        const totalStops = route.stops.length
        return route.stops.map((stop, stopIndex) => ({
          ...stop,
          routeName: get(route, 'name', ''),
          stopIndex: stopIndex + 1,
          totalStops,
          address: combineAddressInfo(stop),
        }))
      }
      return []
    })

    const unassignedStops = get(unassignedData, 'stops', [])
    const totalUnassignedStops = unassignedStops.length
    const unassignedSelectBoxRouteList = unassignedStops.map((stop, stopIndex) => ({
      ...stop,
      stopIndex: stopIndex + 1,
      totalStops: totalUnassignedStops,
      routeName: T.UNASSIGNED,
      address: combineAddressInfo(stop),
    }))
    const newSelectBoxRouteList = [...assignedSelectBoxRouteList, ...unassignedSelectBoxRouteList]
    setState({
      selectedRouteList: newSelectBoxRouteList,
      selectedRouteDefaultList: newSelectBoxRouteList,
    })

    if (refetchStopDetailsDialogData) {
      const currentStopDetails = getStopById(newSelectBoxRouteList, stopDetailsId)
      dispatch(setStopDetailsDialogState({ stop: { ...currentStopDetails }, refetchStop: false }))
    }
  }, [data, unassignedData])

  useEffect(() => {
    if (stopDetailsId) {
      const currentStopDetails = getStopById(state.selectedRouteDefaultList, stopDetailsId)
      dispatch(setStopDetailsDialogState({ isOpen: true, stop: { ...currentStopDetails } }))
    }
  }, [stopDetailsId])

  useEffect(() => {
    if (stopDetailsPopoverId) {
      const currentStopDetails = getStopById(state.selectedRouteDefaultList, stopDetailsPopoverId)
      dispatch(setStopDetailsPopoverState({ isOpen: true, stop: { ...currentStopDetails } }))
    }
  }, [stopDetailsPopoverId])

  useEffect(() => {
    setStartDate(serviceDate)
  }, [serializedServiceDate, viewMode])

  useEffect(() => {
    dispatch(setSelectedSearchResult(null))
    if (serviceDate && selectedRouteIds && selectedRouteIds.length !== 0) {
      getRouteStops({ routes: selectedRouteIds, serviceDate: formatDateToBEFormatDateFns(serviceDate) })
    }
  }, [serializedServiceDate, selectedRouteIds])

  useEffect(() => {
    if (isUnassignedStopsOpen && serviceDate) {
      getRouteStopsUnassigned({ serviceDate: formatDateToBEFormatDateFns(serviceDate) })
    }
  }, [serializedServiceDate, isUnassignedStopsOpen])

  const handleOnSearchValue = (value, customerListing) => {
    const filterData = []
    customerListing.filter(data => {
      const valueFormated = value.toLowerCase()
      if (data.accountName.toLowerCase().includes(valueFormated) || data.address.toLowerCase().includes(valueFormated)) {
        filterData.push(data)
      }
    })
    setState({ selectedRouteList: filterData })
  }

  const handleToggleUnassignedStops = () => {
    dispatch(toggleUnassignedStops())
  }

  const handleSearchToggle = event => {
    setState({ toggleSearchInput: event.target.value })
  }

  const handleTabChange = (event, newValue) => {
    searchParams.set('view', lowerCase(newValue))
    setSearchParams(searchParams)
    dispatch(changeViewMode(newValue))
  }

  const onCloseBulkMoveFailure = () => {
    dispatch(setBulkMoveFailure({ isOpen: false, stops: [] }))
  }

  return (
    <>
      <Box
        width="100%"
        bgcolor={isTemporaryModeActive && orange[50]}
        sx={{
          position: viewMode === VIEW_MODE_SEQUENCE ? 'fixed' : 'inherit',
          zIndex: theme.zIndex.appBar,
        }}
      >
        <Grid container>
          <Grid container item xs={12} columnSpacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h3" fontWeight={600} sx={{ mt: theme.spacing(1), ml: theme.spacing(2) }}>
                Route Manager
              </Typography>
            </Grid>
            <Grid item container xs columnSpacing={2} alignItems="center">
              <Grid item container xs="auto" sm md lg xl alignItems="center">
                <Box width="100%" sx={{ mt: theme.spacing(2) }} display="flex" justifyContent="flex-end" alignItems="center">
                  {(viewMode === VIEW_MODE_SEQUENCE || state.toggleSearchInput === LOOKUP_MODE_SEARCH) && (
                    <Search
                      onReset={onResetSearch}
                      sx={
                        viewMode === VIEW_MODE_SEQUENCE && {
                          '& .MuiOutlinedInput-root': {
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                          },
                        }
                      }
                      defaultOptions={state.selectedRouteDefaultList}
                      filteredOptions={state.selectedRouteList}
                      onInputChange={handleOnSearchValue}
                      onChange={onSearchRowClick}
                    />
                  )}
                  {viewMode === VIEW_MODE_MAP && state.toggleSearchInput === LOOKUP_MODE_ADDRESS && (
                    <SearchAddress onChange={onAddressSelected} onReset={onResetSearch} />
                  )}
                  {viewMode === VIEW_MODE_MAP && <LookupToggle value={state.toggleSearchInput} onChange={handleSearchToggle} />}
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2}>
                  <FiltersMenuButton />
                </Box>
              </Grid>
              {routePermission && (
                <Grid item>
                  <Button
                    onClick={openNewRouteDrawer}
                    startIcon={<Add />}
                    color={isTemporaryModeActive ? 'warning' : 'primary'}
                    variant="outlined"
                    sx={{ mt: theme.spacing(2) }}
                  >
                    New route
                  </Button>
                </Grid>
              )}
              <Grid item>
                <BulkActionMenu
                  sx={{
                    mt: theme.spacing(2),
                  }}
                />
              </Grid>
              <Grid item>
                <RouteManagerActionsMenu
                  updateRoute={updateRoute}
                  onUnscheduledRouteChange={handleToggleUnassignedStops}
                  sx={{ mt: theme.spacing(2) }}
                />
              </Grid>
              <Grid item>
                <Box mr={2} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="flex-end">
            <Grid item>
              <RouteManagerTabs onChange={handleTabChange} />
            </Grid>
            <Grid item xs>
              <Box position="relative" top={-19.5} zIndex={theme.zIndex.drawer}>
                <Divider />
                <Box position="absolute" left="50%" sx={{ transform: 'translate(-50%, 0)', marginLeft: '-66px' }}>
                  <RouteManagerDatePicker
                    onPrevDate={handlePrevDate}
                    onNextDate={handleNextDate}
                    onChange={handleDateChange}
                    value={startDate?.startDate || startDate}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {routePermission === FULL_PERMISSION && (
          <RouteDetailsModal defaultMode="create" open={isOpenNewRouteDrawer} onClose={closeNewRouteDrawer} onSave={onSaveNewRoute} />
        )}
      </Box>
      <StopDetailsDialog />
      <StopDetailsPopover />
      <ScheduleStopDialog isTemporary={isTemporaryModeActive} />
      <BulkMoveFailure isOpen={isOpenBulkMoveFailure} stops={bulkMoveFailureStops} onClose={onCloseBulkMoveFailure} />
    </>
  )
}

export default RouteManagerAppBar
