import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, Typography } from '@mui/material'
import { CommonSwitch } from 'components/common'
import { useTogglePermitMutation } from 'api/settings/togglePermit'

const PermitTableRow = ({ data, onClick, onToggleSuccess }) => {
  const [togglePermit, { isSuccess }] = useTogglePermitMutation()

  React.useEffect(() => {
    if (isSuccess) onToggleSuccess()
  }, [isSuccess])

  return (
    <TableRow>
      {Object.keys(data)
        .filter(key => ['permitName', 'permitType', 'active'].includes(key))
        .map(key => (
          <TableCell onClick={key === 'permitName' ? onClick : undefined} sx={{ cursor: key === 'permitName' ? 'pointer' : '' }}>
            {key === 'permitName' && <Typography variant="body1">{data[key]}</Typography>}
            {key === 'permitType' && <Typography variant="body1">{data[key]}</Typography>}
            {key === 'active' && (
              <CommonSwitch
                isChecked={data[key]}
                onChange={active => {
                  togglePermit({
                    id: data.id,
                    active,
                  })
                }}
              />
            )}
          </TableCell>
        ))}
    </TableRow>
  )
}

PermitTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onToggleSuccess: PropTypes.func.isRequired,
}

export default PermitTableRow
