import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'

const AccountGroupDefaultCardListItem = ({ label = '', value = '', Icon, isLoading = false }) => (
  <ListItem disablePadding>
    <ListItemIcon>
      <Icon width={24} height={24} />
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{ variant: 'h5', fontWeight: 500 }}
      primary={isLoading ? <Skeleton width="4rem" /> : value}
      secondaryTypographyProps={{ variant: 'caption' }}
      secondary={label}
    />
  </ListItem>
)

AccountGroupDefaultCardListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  Icon: PropTypes.element,
  isLoading: PropTypes.bool,
}

export default AccountGroupDefaultCardListItem
