import { CONTACT_METHODS, CONTACT_METHOD_TYPES } from 'settings/constants/contacts'

const { MOBILE, EMAIL } = CONTACT_METHODS
const { PERSONAL } = CONTACT_METHOD_TYPES

export const NEW_ACCOUNT_INITIAL_STATE = {
  accountName: '',
  contact: {
    firstName: '',
    lastName: '',
  },
  address: {
    addressName: '',
    line1: '',
    unitNumber: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  },
  useAsBillingAddress: true,
  billingProfileId: '',
}

export const SEARCH_CUSTOMER_BY_TYPE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
}

export const INITIAL_PHONE_CONTACT_METHOD = {
  methodType: MOBILE,
  methodValue: '',
  contactMethodType: PERSONAL,
  serviceNotifications: true,
  billingNotifications: false,
}

export const INITIAL_EMAIL_CONTACT_METHOD = {
  methodType: EMAIL,
  methodValue: '',
  contactMethodType: PERSONAL,
  serviceNotifications: false,
  billingNotifications: true,
}
