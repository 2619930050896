export const ADDRESS_SEARCH_TYPE = 'address'
export const PHONE_SEARCH_TYPE = 'phone'
export const CONTACT_NAME_SEARCH_TYPE = 'contact name'
export const CONTAINER_ID_SEARCH_TYPE = 'container id'
export const GENERAL_SEARCH_TYPE = 'suggest'
export const RECENT_ACCOUNT_VIEWED_SEARCH_TYPE = 'recently viewed'
export const RECENT_ACCOUNT_ADDED_SEARCH_TYPE = 'recently added'
export const ACCOUNT_NUMBER_SEARCH_TYPE = 'account number'
export const ACCOUNT_NAME_SEARCH_TYPE = 'account name'

export const SEARCH_TYPE_VALUES = [
  ADDRESS_SEARCH_TYPE,
  PHONE_SEARCH_TYPE,
  CONTACT_NAME_SEARCH_TYPE,
  CONTAINER_ID_SEARCH_TYPE,
  GENERAL_SEARCH_TYPE,
  RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
  ACCOUNT_NUMBER_SEARCH_TYPE,
  ACCOUNT_NAME_SEARCH_TYPE,
]

export const SEARCH_TYPE_VALUE_TO_SEARCH_KEY = {
  [ADDRESS_SEARCH_TYPE]: 'Address',
  [PHONE_SEARCH_TYPE]: 'Phone',
  [CONTACT_NAME_SEARCH_TYPE]: 'ContactName',
  [CONTAINER_ID_SEARCH_TYPE]: 'ContainerId',
  [GENERAL_SEARCH_TYPE]: 'Suggest',
  [RECENT_ACCOUNT_VIEWED_SEARCH_TYPE]: 'RecentlyViewed',
  [RECENT_ACCOUNT_ADDED_SEARCH_TYPE]: 'RecentlyAdded',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'AccountNumber',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'AccountName',
}

export const SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE = {
  [ADDRESS_SEARCH_TYPE]: 'ADDRESS',
  [PHONE_SEARCH_TYPE]: 'PHONE',
  [CONTACT_NAME_SEARCH_TYPE]: 'CONTACT_NAME',
  [CONTAINER_ID_SEARCH_TYPE]: 'CONTAINER_ID',
  [GENERAL_SEARCH_TYPE]: 'GENERAL',
  [RECENT_ACCOUNT_VIEWED_SEARCH_TYPE]: 'ACCOUNT_VIEWED',
  [RECENT_ACCOUNT_ADDED_SEARCH_TYPE]: 'ACCOUNT_ADDED',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'ACCOUNT_NUMBER',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'ACCOUNT_NAME',
}

export const SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL = {
  [ADDRESS_SEARCH_TYPE]: 'Choose a location address',
  [PHONE_SEARCH_TYPE]: 'Choose a contact mobile or office phone number',
  [CONTACT_NAME_SEARCH_TYPE]: 'Choose an individual contact name',
  [CONTAINER_ID_SEARCH_TYPE]: 'Choose a container identification number',
  [GENERAL_SEARCH_TYPE]: 'Choose a location',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'Choose an account number',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'Choose an account name',
}

export const SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER = {
  [ADDRESS_SEARCH_TYPE]: 'Search by address',
  [PHONE_SEARCH_TYPE]: 'Search by',
  [CONTACT_NAME_SEARCH_TYPE]: 'Search by contact',
  [CONTAINER_ID_SEARCH_TYPE]: 'Search by the container ID',
  [GENERAL_SEARCH_TYPE]: 'Search...',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'Search by account number',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'Search by account name',
}
