import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { FEATURE_FLAGS } from 'settings/constants/launchDarkly'

const {
  NOTIFICATION_CENTER,
  NEW_ROUTE_MANAGER,
  BILLING_WORK_ORDER_INVOICE_STATUS,
  INVOICE_BATCHING,
  PAYMENT_GROUPS,
  GCP_IP,
  NOTES_V_2,
  ACCOUNT_GROUPS,
  LATE_FEE,
  RENTAL_FEE,
  PAYMENTS_V15,
} = FEATURE_FLAGS

const selectFlags = state => get(state, 'flags', {})

export const isFeatureAvailable = (flags, key) => get(flags, key, false)

export const isNotificationFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, NOTIFICATION_CENTER))

export const isNewRouteManagerFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, NEW_ROUTE_MANAGER))

export const isBillingWOInvoiceStatusFeatureAvailable = createSelector(selectFlags, flags =>
  isFeatureAvailable(flags, BILLING_WORK_ORDER_INVOICE_STATUS)
)

export const isInvoiceBatchingFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, INVOICE_BATCHING))

export const isPaymentGroupsFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, PAYMENT_GROUPS))

export const isGcpIpFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, GCP_IP))

export const isNotesV2FeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, NOTES_V_2))

export const isAccountGroupsFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, ACCOUNT_GROUPS))

export const isLateFeeFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, LATE_FEE))

export const isRentalFeeFeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, RENTAL_FEE))

export const isPaymentsV15FeatureAvailable = createSelector(selectFlags, flags => isFeatureAvailable(flags, PAYMENTS_V15))
