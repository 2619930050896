import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, styled } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import uniq from 'lodash/uniq'
import T from 'T'
import { get } from 'lodash'
import { HHFormAutocompleteField } from '../../../form-fields/v5'
import PopupPaper from '../../../form-fields/v5/PopupPaper'
import { ALL_DAYS } from '../../../../settings/constants/day'
import { containsAllWeekendDays, containsAllWorkingDays } from '../../../../utils/day'
import MultiSelectItem from '../../../form-fields/v5/MultiSelectItem'

const StyledChip = styled(props => <Chip {...props} size="small" variant="outlined" />)(({ theme }) => ({
  '& .MuiChip-avatar': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}))

const DaysAutocompleteFormField = ({ sx, name }) => {
  const { control, setValue, watch } = useFormContext()
  const workingDaysSelected = watch('workingDaysSelected')
  const weekendSelected = watch('weekendSelected')
  const days = watch(name)

  const isOptionEqualToValue = (option, value) => value === option

  const toggleWeekendSelected = () => {
    setValue('weekendSelected', !weekendSelected)
    if (!weekendSelected) {
      setValue(name, uniq([...days, T.SATURDAY, T.SUNDAY]))
    } else {
      setValue(
        name,
        days.filter(day => [T.SATURDAY, T.SUNDAY].indexOf(day) === -1)
      )
    }
  }

  const toggleWorkingDaysSelected = () => {
    setValue('workingDaysSelected', !workingDaysSelected)
    if (!workingDaysSelected) {
      setValue(name, uniq([...days, T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY]))
    } else {
      setValue(
        name,
        days.filter(day => [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY].indexOf(day) === -1)
      )
    }
  }
  const handleWorkingDaysOptionMouseDown = e => {
    e.preventDefault()
    toggleWorkingDaysSelected()
  }

  const handleWeekendOptionMouseDown = e => {
    e.preventDefault()
    toggleWeekendSelected()
  }

  const renderTags = (tags, getTagProps) =>
    tags.map((item, index) => {
      if (index === 0)
        return (
          <>
            {workingDaysSelected && <StyledChip avatar={<Avatar>MF</Avatar>} label="Working days" onDelete={toggleWorkingDaysSelected} />}
            {weekendSelected && <StyledChip avatar={<Avatar>SS</Avatar>} label="Weekend" onDelete={toggleWeekendSelected} />}
            <StyledChip avatar={<Avatar>{item.charAt(0).toUpperCase()}</Avatar>} {...getTagProps(index)} label={item} />
          </>
        )
      return <StyledChip avatar={<Avatar>{item.charAt(0).toUpperCase()}</Avatar>} {...getTagProps(index)} label={item} />
    })

  const renderOption = (props, option) => {
    const selected = get(props, 'aria-selected', false)
    return (
      <MultiSelectItem selected={selected} {...props}>
        <StyledChip avatar={<Avatar>{option.charAt(0).toUpperCase()}</Avatar>} label={option} />
      </MultiSelectItem>
    )
  }

  const PaperComponent = paperProps => {
    const { children, ...restPaperProps } = paperProps

    return (
      <PopupPaper
        sx={{
          '& .MuiAutocomplete-listbox': {
            '& .MuiAutocomplete-option': {
              paddingRight: 0.5,
              paddingLeft: 0.5,
            },
          },
        }}
        {...restPaperProps}
      >
        <MultiSelectItem className="MuiAutocomplete-option" selected={workingDaysSelected} onMouseDown={handleWorkingDaysOptionMouseDown}>
          <StyledChip avatar={<Avatar>MF</Avatar>} label="Working days" />
        </MultiSelectItem>
        <MultiSelectItem className="MuiAutocomplete-option" selected={weekendSelected} onMouseDown={handleWeekendOptionMouseDown}>
          <StyledChip avatar={<Avatar>SS</Avatar>} label="Weekend" />
        </MultiSelectItem>
        {children}
      </PopupPaper>
    )
  }

  const onChange = (_, newValue) => {
    const newWeekendSelected = containsAllWeekendDays(newValue)
    const newWorkingDaysSelected = containsAllWorkingDays(newValue)
    setValue('weekendSelected', newWeekendSelected)
    setValue('workingDaysSelected', newWorkingDaysSelected)
    setValue(name, newValue)
  }

  return (
    <HHFormAutocompleteField
      isOptionEqualToValue={isOptionEqualToValue}
      PaperComponent={PaperComponent}
      sx={sx}
      fullWidth
      options={ALL_DAYS}
      name={name}
      control={control}
      label={T.DAYS}
      placeholder={T.DAYS}
      multiple
      onChange={onChange}
      deprecatedLabel={false}
      renderOption={renderOption}
      renderTags={renderTags}
    />
  )
}

DaysAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string.isRequired,
}

export default DaysAutocompleteFormField
