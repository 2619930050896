import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isRecurringService, getDeactivationStatusMessage } from 'utils/service'
import { CommonSwitch } from 'components/common'
import { HHTextField } from 'components/form-fields/v5'

import T from 'T'
import ConfirmationModal from 'components/modal/ConfirmationModal'

import './style.scss'
import { Stack } from '@mui/material'

const ServiceTabEditContent = ({
  existingEvents = [],
  schedule = {},
  serviceType = '',
  isServiceActivated = false,
  pricedServiceDetails = {},
  selectedLocation = {},
  onChange,
}) => {
  const [isOpenDeactivationConfirmModal, setIsOpenDeactivationConfirmModal] = useState(false)
  const [confirmationMsg, setConfirmationMsg] = useState('')

  const isRecurring = isRecurringService(serviceType)
  const endDate = get(schedule, 'endDate', '')

  return (
    <div className="service-tab-content common-padding-lr-3-5">
      <div className="edit-mode">
        <Stack sx={{ mt: 2 }} justifyContent="flex-start">
          <CommonSwitch
            title={T.ACTIVE}
            isChecked={!isServiceActivated}
            onChange={checked => {
              // Now flow is disabled, actual condition will be isRecurring ? existingEvents : serviceEvents
              const { showConfirmation, message } = getDeactivationStatusMessage(isRecurring, endDate, existingEvents)
              if (!checked && showConfirmation) {
                setConfirmationMsg(message)
                setIsOpenDeactivationConfirmModal(true)
                return
              }

              onChange('deactivate', !checked, 'advanced')
            }}
          />
        </Stack>
        <Box mt={2}>
          <HHTextField label={T.SERVICE_ADDRESS} fullWidth readOnly value={get(selectedLocation, 'locationName', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField label={T.PRICING_ZONE} fullWidth readOnly value={get(pricedServiceDetails, 'pricingZone.zoneName', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField label={T.BUSINESS_LINE} fullWidth readOnly value={get(pricedServiceDetails, 'businessLine', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField label={T.SERVICE} fullWidth readOnly value={get(pricedServiceDetails, 'serviceName', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField
            label={T.MEASURE}
            fullWidth
            readOnly
            value={`${get(pricedServiceDetails, 'measure.volume', '')} ${get(pricedServiceDetails, 'measure.unit', '')}`}
          />
        </Box>
        <Box mt={2}>
          <HHTextField label={`${T.MATERIAL}s`} fullWidth readOnly value={get(pricedServiceDetails, 'material.materialType', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField label={T.METHOD} fullWidth readOnly value={get(pricedServiceDetails, 'method.methodName', '')} />
        </Box>
        <Box mt={2}>
          <HHTextField label={T.ACTION} fullWidth readOnly value={get(pricedServiceDetails, 'action.actionName', '')} />
        </Box>
      </div>

      <ConfirmationModal
        className="global-drawer-confirm service-drawer-confirm"
        isOpen={isOpenDeactivationConfirmModal}
        title={confirmationMsg}
        hasCancelBtn={false}
        proceedButtonTitle={T.CLOSE}
        onCancel={() => setIsOpenDeactivationConfirmModal(false)}
        onProceed={() => setIsOpenDeactivationConfirmModal(false)}
      />
    </div>
  )
}

ServiceTabEditContent.propTypes = {
  existingEvents: PropTypes.array,
  schedule: PropTypes.object,
  serviceType: PropTypes.string,
  isServiceActivated: PropTypes.bool,
  pricedServiceDetails: PropTypes.object,
  selectedLocation: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(ServiceTabEditContent)
