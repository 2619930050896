import React, { Children } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import { TableRow, TableCell, TableBody as TBody, Typography, Box } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

import { memo } from 'utils/react'

import Constants from 'Constants'

const { MEDIA_SERVER } = Constants

const ActionMethodTableBody = ({ values = [], handleRemoval = () => {}, type = '' }) => {
  return (
    <TBody>
      {Children.toArray(
        values &&
          values.map((val, index) => {
            const categoryType = type === 'action' ? 'actionName' : 'methodName'
            const iconCategoryType = type === 'action' ? 'actionType' : 'methodType'

            return (
              <TableRow>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    {val[iconCategoryType] && (
                      <img src={`${MEDIA_SERVER}${startCase(type)}/${val[iconCategoryType].replace(/ |& /g, '')}.svg`} alt="icons" />
                    )}
                    <Typography variant="body1" ml={1.5}>
                      {val[categoryType]}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>
                  {val.id === '' && (
                    <CancelOutlinedIcon
                      fontSize="small"
                      onClick={() => handleRemoval(index)}
                      sx={{ color: 'text.secondary', m: 'auto 14px', cursor: 'pointer' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })
      )}
    </TBody>
  )
}

ActionMethodTableBody.propTypes = {
  values: PropTypes.array,
  type: PropTypes.string,
  handleRemoval: PropTypes.func,
}

export default memo(ActionMethodTableBody)
