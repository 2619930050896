import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    deleteException: builder.mutation({
      query: ({ id }) => ({
        url: `api/v1/core/work-order-exceptions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useDeleteExceptionMutation } = extendedApi
