import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import {
  Table as TableView,
  TableRow,
  TableCell,
  TableBody as TBody,
  TableHead as THead,
  Card,
  Typography,
  Radio,
  FormControlLabel,
} from '@mui/material'
import Cancel from '@mui/icons-material/Cancel'
import HHTextField from 'components/form-fields/v5/HHTextField'
import TableSkelton from 'components/common/TableSkelton'
import PricingSwitch from 'components/pricing/PricingSwitch'
import { SETTINGS_SKELTON_ROWS } from 'settings/constants/pagination'
import T from 'T'
import TableLayout from '../../layout/TableLayout'

const ZonesTable = ({
  type = 'pricingZone',
  zones = [],
  onPlaceEdit = {},
  isLoading = false,
  onHandleChange = noop,
  handleSave = noop,
  handleZoneSwitchVal = noop,
  handleZoneRemoval = noop,
  handleOnPlaceEdit = noop,
  handleDefaultChange = noop,
}) => {
  const ZONE_COLUMNS = [
    { title: `${T.ZONE} ${T.NAME}` },
    { title: `${T.CUSTOMER}s ${T.ASSIGNED}`, align: 'right' },
    { title: T.ACTIVE, align: 'right' },
  ]

  if (type === 'tax') ZONE_COLUMNS.splice(1, 0, { title: T.DEFAULT, align: 'center' })

  return (
    <Card sx={{ mt: 2 }}>
      <TableLayout height="calc(100vh - 135px)">
        <TableView stickyHeader aria-label="sticky table">
          <THead>
            <TableRow>
              {ZONE_COLUMNS.map(record => {
                return (
                  <TableCell sx={{ p: '25px 16px 10px 16px', backgroundColor: 'background.paper' }} align={record.align}>
                    <Typography variant="subtitle2">{record.title}</Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </THead>

          <TBody>
            {isLoading && <TableSkelton rows={SETTINGS_SKELTON_ROWS} columns={type === 'tax' ? 4 : 3} />}
            {Children.toArray(
              zones &&
                zones.map((val, index) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {!onPlaceEdit[index] && (
                          <Typography variant="body1" onClick={() => handleOnPlaceEdit(index)}>
                            {val.zoneName}
                          </Typography>
                        )}

                        {onPlaceEdit[index] && Object.keys(onPlaceEdit).length > 0 && (
                          <HHTextField
                            placeholder={T.ZONE_NAME}
                            value={val.zoneName}
                            variant="outlined"
                            autoComplete="off"
                            name="zoneName"
                            InputProps={{
                              onChange: e => onHandleChange(e, index),
                              onKeyDown: event => (event.code === 'Enter' || event.code === 'NumpadEnter') && handleSave(index),
                            }}
                            sx={{
                              width: 278,
                            }}
                          />
                        )}
                      </TableCell>

                      {type === 'tax' && (
                        <TableCell align="center">
                          <FormControlLabel
                            sx={{ m: 0 }}
                            control={
                              <Radio
                                checked={val.default}
                                onChange={event => handleDefaultChange(event, index)}
                                name="radio-buttons"
                                sx={{
                                  p: 0,
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 20,
                                  },
                                }}
                              />
                            }
                          />
                        </TableCell>
                      )}

                      <TableCell align="right">
                        <Typography variant="body1">{val.customerCount || '-'}</Typography>
                      </TableCell>

                      <TableCell align="right">
                        <PricingSwitch checked={val.active} handleSwitchVal={() => handleZoneSwitchVal(!val.active, index)} />

                        {val.zoneId === '' && (
                          <Cancel
                            onClick={() => handleZoneRemoval(index)}
                            fontSize="small"
                            sx={{ ml: 2, pt: 0.2, alignItems: 'center', color: 'text.secondary', cursor: 'pointer' }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
            )}
          </TBody>
        </TableView>
      </TableLayout>
    </Card>
  )
}

ZonesTable.propTypes = {
  type: PropTypes.string,
  zones: PropTypes.array,
  onPlaceEdit: PropTypes.object,
  isLoading: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleSave: PropTypes.func,
  handleZoneSwitchVal: PropTypes.func,
  handleZoneRemoval: PropTypes.func,
  handleOnPlaceEdit: PropTypes.func,
  handleDefaultChange: PropTypes.func,
}

export default ZonesTable
