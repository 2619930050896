import { createSlice } from '@reduxjs/toolkit'

import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'
import { WORK_ORDERS_PAGINATION } from 'settings/constants/pagination'
import { get } from 'lodash'
import { serializeDate } from '../../utils/date'

const { ACCOUNT_TAB_ID } = CUSTOMER_DETAILS_TABS_ID
const { ROWS_PER_PAGE } = WORK_ORDERS_PAGINATION

const customerDetailsSlice = createSlice({
  name: 'CustomerDetails',
  initialState: {
    currentTab: ACCOUNT_TAB_ID,
    selectedLocationId: '',
    workOrders: {
      pagination: {
        size: ROWS_PER_PAGE,
      },
    },
    routing: {
      serializedServiceDate: serializeDate(new Date()),
      tooltipStopCacheByAccountId: {},
    },
    headerActionMenu: {
      isReactivateLocationsDialogOpen: false,
      isSuspendDialogOpen: false,
      isUnsuspendDialogOpen: false,
      isChangeAccountRepresentativeDialogOpen: false,
      isActiveDeactivateAccountDialogOpen: false,
    },
  },
  reducers: {
    onChangeTab: (state, action) => {
      state.currentTab = action.payload
    },
    onChangeLocationId: (state, action) => {
      state.selectedLocationId = action.payload
    },
    changeWOPageSize: (state, action) => {
      state.workOrders.pagination.size = action.payload
    },
    setRoutingServiceDate: (state, action) => {
      state.routing = {
        ...state.routing,
        serializedServiceDate: serializeDate(action.payload),
      }
    },
    setRoutingTooltipCacheByAccountId: (state, action) => {
      state.routing = {
        ...state.routing,
        tooltipStopCacheByAccountId: {
          ...state.tooltipStopCacheByAccountId,
          ...action.payload,
        },
      }
    },
    resetRoutingTooltipCacheByAccountId: (state, action) => {
      state.routing = {
        ...state.routing,
        tooltipStopCacheByAccountId: {},
      }
    },
    setIsReactivateLocationsDialogOpen: (state, action) => {
      state.headerActionMenu = {
        ...state.headerActionMenu,
        isReactivateLocationsDialogOpen: action.payload,
      }
    },
    setIsChangeAccountRepresentativeDialogOpen: (state, action) => {
      state.headerActionMenu = {
        ...state.headerActionMenu,
        isChangeAccountRepresentativeDialogOpen: action.payload,
      }
    },
    setIsActiveDeactivateAccountDialogOpen: (state, action) => {
      state.headerActionMenu = {
        ...state.headerActionMenu,
        isActiveDeactivateAccountDialogOpen: action.payload,
      }
    },
  },
})
export const {
  onChangeTab,
  onChangeLocationId,
  changeWOPageSize,
  setRoutingServiceDate,
  setRoutingTooltipCacheByAccountId,
  resetRoutingTooltipCacheByAccountId,
  setIsReactivateLocationsDialogOpen,
  setIsChangeAccountRepresentativeDialogOpen,
  setIsActiveDeactivateAccountDialogOpen,
} = customerDetailsSlice.actions

export const selectIsReactivateLocationsDialogOpen = s => get(s, 'CustomerDetails.headerActionMenu.isReactivateLocationsDialogOpen', false)
export const selectIsChangeAccountRepresentativeDialogOpen = s =>
  get(s, 'CustomerDetails.headerActionMenu.isChangeAccountRepresentativeDialogOpen', false)
export const selectIsActiveDeactivateAccountDialogOpen = s =>
  get(s, 'CustomerDetails.headerActionMenu.isActiveDeactivateAccountDialogOpen', false)

export default customerDetailsSlice.reducer
