import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    togglePermit: build.mutation({
      query: ({ id, active }) => ({
        url: `/api/v1/core/settings/permit/${active ? 'activate' : 'deactivate'}/${id}`,
        method: 'PUT',
      }),
    }),
  }),
})

export const { useTogglePermitMutation } = extendedApi
