import React from 'react'
import { Box } from '@mui/material'

import { get } from 'utils/lodash'
import AccountGroupDefaultView from './AccountGroupDefaultView'
import AccountGroupCustomViewHeader from './AccountGroupCustomViewHeader'
import AccountGroupDataGridRow from './AccountGroupDataGridRow'

const RenderAccountGroupWrapper = params => {
  const rowId = get(params, 'row.groupId')

  if (rowId === 'header') {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <AccountGroupDefaultView />
        <AccountGroupCustomViewHeader {...params} />
      </Box>
    )
  }

  return <AccountGroupDataGridRow {...params} />
}

export default RenderAccountGroupWrapper
