import { takeEvery, all, call } from 'redux-saga/effects'

import { get } from 'utils/lodash'

import { doGet, doPost } from 'providers/api'

import { actions } from '../actions/report'

import endpoint from './endpoint'

export const getReportTableContent = ({ body, url }) => doPost({ url, body, showLoader: false })
export const getReportSearchFilterViewList = ({ body, url }) => doPost({ url, body, showLoader: false })
export const reportAddEditFilterView = ({ body, url }) => doPost({ url, body, showLoader: true })
export const reportDeleteFilterView = ({ body, url }) => doPost({ url, body, showLoader: true })
export const reportSave = ({ body }) => doPost({ url: endpoint.report.save, body, showLoader: true })
export const newReportList = ({ body }) => doGet({ url: endpoint.report.newReportList, body, showLoader: true })

export function* getReportTableContentSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const url = endpoint.report.list
    if (!url) {
      return
    }

    const { data: res } = yield call(getReportTableContent, { body, url })

    const { totalItems, totalPages } = res
    const tableRows = get(res, 'dataList') || []
    if (typeof onSuccess === 'function') {
      onSuccess(tableRows, totalItems, totalPages)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getReportSearchFilterViewListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = endpoint.report.filterList
    if (!url) {
      return
    }

    const { data: res } = yield call(getReportSearchFilterViewList, { body, url })
    const { searchFilters } = res
    if (typeof onSuccess === 'function') {
      onSuccess(searchFilters)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* reportAddEditFilterViewSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = endpoint.report.filterSave
    if (!url) {
      return
    }

    const { data: res } = yield call(reportAddEditFilterView, { body, url })
    const { id } = res
    if (typeof onSuccess === 'function') {
      onSuccess(true, id)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* reportDeleteFilterViewSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = endpoint.report.filterDelete
    if (!url) {
      return
    }

    yield call(reportDeleteFilterView, { body, url })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* saveReportSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(reportSave, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getNewReportListSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(newReportList, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export default function* Report() {
  yield all([
    takeEvery(actions.GET.REPORT_TABLE_CONTENT, getReportTableContentSaga),
    takeEvery(actions.GET.REPORT_SEARCH_FILTER_VIEW_LIST, getReportSearchFilterViewListSaga),
    takeEvery(actions.REPORT_ADD_EDIT_FILTER_VIEW, reportAddEditFilterViewSaga),
    takeEvery(actions.REPORT_DELETE_FILTER_VIEW, reportDeleteFilterViewSaga),
    takeEvery(actions.SAVE_REPORT, saveReportSaga),
    takeEvery(actions.GET.NEW_REPORT_LIST, getNewReportListSaga),
  ])
}
