import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Box } from '@mui/material'

import HHTablePaginationV5 from 'components/common/HHTablePaginationV5'
import HHPaginationV5 from 'components/common/HHPaginationV5'

import { PAGINATION } from 'settings/constants/pagination'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION

const CustomerDetailsPaginations = ({
  page = { INITIAL_PAGE },
  totalCount = 0,
  rowsPerPage = { ROWS_PER_PAGE },
  rowsPerPageOptions = { ROWS_PER_PAGE_OPTIONS },
  totalPageCount = 0,
  handlePageChange,
  handleRowsPerPageChange,
  withNewColors = false,
}) => {
  return (
    <Card variant="outlined">
      <Grid container justifyContent="flex-start" sx={{ bgcolor: withNewColors ? 'background.header' : 'background.default' }}>
        <Grid item xs={6} pl={2}>
          <HHTablePaginationV5
            sx={{ borderBottom: 'none' }}
            ActionsComponent={params => <Box display="none" {...params} />}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            onPageChange={(_, newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <HHPaginationV5
            count={totalPageCount}
            page={page + 1}
            onChange={(_, newPage) => handlePageChange(newPage - 1)}
            siblingCount={0}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

CustomerDetailsPaginations.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  rowsPerPageOptions: PropTypes.number,
  handlePageChange: PropTypes.func.isRequired,
  handleRowsPerPageChange: PropTypes.func.isRequired,
  withNewColors: PropTypes.bool.isRequired,
}

export default CustomerDetailsPaginations
