import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, Grid } from '@mui/material'

const RouteDetailsItem = ({ icon, label, value }) => {
  return (
    <Grid item container alignItems="center">
      <Grid item sx={{ minWidth: '140px' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography color="textSecondary" fontWeight={500}>
            {label}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  )
}

RouteDetailsItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.node,
  value: PropTypes.node,
}

export default RouteDetailsItem
