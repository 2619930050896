import React from 'react'
import PropTypes from 'prop-types'
import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material'
import { noop } from 'lodash'
import { EyeOff } from '@styled-icons/heroicons-outline/EyeOff'
import { Eye } from '@styled-icons/heroicons-outline/Eye'
import { selectHideArchivedRentalFees, setHideArchivedRentalFees } from 'slices/pricing/rentalFeesSlice'
import { useDispatch, useSelector } from 'react-redux'

const RentalFeesHeaderActionMenu = ({ anchorEl = null, onClose = noop }) => {
  const dispatch = useDispatch()
  const hideArchived = useSelector(selectHideArchivedRentalFees)
  const open = Boolean(anchorEl)

  const toggleArchived = () => {
    dispatch(setHideArchivedRentalFees(!hideArchived))
  }

  const handleHideArchivedClick = () => {
    toggleArchived()
    onClose()
  }

  return (
    <Menu
      id="rental-fees-header-action-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          sx: { border: '1px solid', borderColor: 'divider' },
        },
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={handleHideArchivedClick}>
        <ListItemIcon sx={{ mr: 1 }}>
          <SvgIcon>{hideArchived ? <Eye /> : <EyeOff />}</SvgIcon>
        </ListItemIcon>
        <ListItemText>{hideArchived ? 'Show archived' : 'Hide archived'}</ListItemText>
      </MenuItem>
    </Menu>
  )
}

RentalFeesHeaderActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Element)]),
  onClose: PropTypes.func,
}

export default RentalFeesHeaderActionMenu
