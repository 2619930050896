import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'

import { Typography, Box, Alert, ListItem, ListItemText, Popper, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { get } from 'utils/lodash'
import { useGetSuggestedAccountsMutation } from 'api/accounts/getSuggestedAccounts'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import StyledTextField from 'components/form-fields/v5/StyledTextField'
import HHAutocompleteField from 'components/form-fields/v5/HHAutocompleteField'
import AccountDetails from '../common/AccountDetails'
import PaymentInfoBlock from '../common/PaymentInfoBlock'
import SelectInvoiceDropdown from '../common/SelectInvoiceDropdown'

const StyledPopper = styled(Popper)({
  '& .MuiPaper-root': {
    maxHeight: '33vh',
  },
  '& ul': {
    overflow: 'hidden',
  },
})

const TransferStep = ({
  isIntraAccountTransfer = false,
  payment = {},
  selectedInvoice,
  selectedAccount,
  unPaidInvoices = [],
  paidInvoices = [],
  sourceAccountDetails = {},
  destinationAccountDetails = {},
  handleInvoiceChange = noop,
  handleAccountChange = noop,
}) => {
  const [getSuggestedAccounts, { data: dataSuggestedAccounts }] = useGetSuggestedAccountsMutation()

  const { transactionAmountCents } = payment || {}
  const pendingInvoiceAmount = selectedInvoice?.totalCents - selectedInvoice?.amountPaidCents

  const handleAccountList = useCallback(
    debounce(val => {
      getSuggestedAccounts({ search: val })
    }, 300),
    []
  )

  return (
    <>
      <Box my={2} display="flex" alignItems="center">
        <RemoveCircleIcon fontSize="small" color="textSecondary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.TRANSFERRING_FROM}:`}
        </Typography>
      </Box>
      {isIntraAccountTransfer && (
        <AccountDetails accountName={sourceAccountDetails.accountName} accountNumber={sourceAccountDetails.accountNumber} />
      )}
      <PaymentInfoBlock
        payment={{ ...payment, paymentAppliedAmountCents: payment?.transactionAmountCents }}
        paidInvoices={paidInvoices}
        sourceAccountDetails={sourceAccountDetails}
      />

      <Box my={2} display="flex" alignItems="center">
        <AddCircleIcon fontSize="small" color="primary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.TRANSFERRING_TO}:`}
        </Typography>
      </Box>

      {isIntraAccountTransfer && (
        <HHAutocompleteField
          sx={{ my: 1 }}
          blurOnSelect
          openOnFocus
          label={`${T.ACCOUNT} *`}
          fullWidth
          deprecatedLabel={false}
          filterOptions={x => x}
          options={get(dataSuggestedAccounts, 'accounts', [])}
          getOptionLabel={option => option?.accountName || ''}
          renderOption={(props, option) => (
            <ListItem dense {...props}>
              <ListItemText
                primary={<Typography>{option?.accountName}</Typography>}
                secondary={option?.accountNumber ? option.accountNumber : null}
              />
            </ListItem>
          )}
          renderInput={params => (
            <StyledTextField
              {...params}
              placeholder="Search accounts.."
              label={`${T.ACCOUNT} *`}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchIcon sx={{ color: 'text.secondary' }} />,
              }}
            />
          )}
          PopperComponent={StyledPopper}
          onChange={(event, value) => {
            handleAccountChange(value)
            handleInvoiceChange(null)
          }}
          onInputChange={(event, value) => {
            if (event?.type !== 'change') {
              return
            }

            if (value) {
              handleAccountList(value)
            }
          }}
          value={selectedAccount}
          noOptionsText="Start typing to search"
        />
      )}

      <SelectInvoiceDropdown
        disabled={isIntraAccountTransfer ? !selectedAccount : false}
        selectedInvoice={selectedInvoice}
        unPaidInvoices={unPaidInvoices}
        destinationAccountDetails={destinationAccountDetails}
        onInvoiceChange={handleInvoiceChange}
      />

      {selectedInvoice && transactionAmountCents > pendingInvoiceAmount && (
        <Alert severity="warning" sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">
            This payment amount is{' '}
            <strong>
              <HHDisplayMoney value={transactionAmountCents - pendingInvoiceAmount} />
            </strong>{' '}
            more than the amount due on the selected invoice. Any overage will be added to account credit
          </Typography>
        </Alert>
      )}
    </>
  )
}

TransferStep.propTypes = {
  isIntraAccountTransfer: PropTypes.bool,
  payment: PropTypes.object,
  selectedInvoice: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedAccount: PropTypes.object,
  unPaidInvoices: PropTypes.array,
  paidInvoices: PropTypes.array,
  handleInvoiceChange: PropTypes.func,
  handleAccountChange: PropTypes.func,
  customerCreditBalanceCents: PropTypes.number,
  sourceAccountDetails: PropTypes.object,
  destinationAccountDetails: PropTypes.object,
}

export default TransferStep
