import api from '../index'
import { CACHE_TAG_ACCOUNT_PAYMENT_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountPayments: builder.query({
      providesTags: (result, error, { accountId }) => [
        CACHE_TAG_ACCOUNT_PAYMENT_LIST,
        { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
      ],
      query: ({ accountId }) => ({ url: `/api/v1/core/accounts/${accountId}/payments` }),
    }),
  }),
})

export const { useLazyGetAccountPaymentsQuery } = extendedApi
