import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Grid, ListItem, Stack, ListItemAvatar, ListItemText, Typography } from '@mui/material'

import { get } from 'utils/lodash'
import { formatDateToFEFormat } from 'utils/date'
import { INVOICE_TYPE } from 'settings/constants/billing'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import AccountSummary from 'components/billing/groups/common/data-grid/AccountSummary'

const { MANUAL } = INVOICE_TYPE
const getBillingCycle = ({ startDate, endDate, invoiceDate, billingProfileDetails }) => {
  try {
    return getBillingPeriods({
      billingProfile: billingProfileDetails,
      billingPeriods: [{ startDate, endDate, invoiceDate }],
    })
  } catch (error) {
    return []
  }
}

const RenderInvoiceCell = props => {
  const { row, rowNode } = props
  const {
    isParent,
    groupName,
    totalCents,
    amountPaidCents,
    status,
    dueDate: rawDueDate,
    invoiceNumber,
    type,
    startDate,
    endDate,
    invoiceDate,
    billingProfileName,
    billingCycleInterval,
    billingCycleIntervalType,
    billingProfileTiming,
    billingProfileTimingDay,
    accountName,
    accountNumber,
    accountId,
  } = row

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const dueDate = transformDate(rawDueDate)
  const pendingAmount = totalCents - amountPaidCents
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, rawDueDate, amountPaidCents, totalCents)
  const billingProfileDetails = {
    billingCycle: {
      interval: billingCycleInterval,
      intervalType: capitalize(billingCycleIntervalType),
    },
    timing: capitalize(billingProfileTiming),
    timingDay: billingProfileTimingDay,
  }
  const parsedInvoiceDate = transformDate(invoiceDate)
  const billingCycle = getBillingCycle({ startDate, endDate, invoiceDate, billingProfileDetails })
  const isManual = type === MANUAL
  const title = isManual ? parsedInvoiceDate : get(billingCycle, '[0].labelLeft', '-')
  const labelRight = get(billingCycle, '[0].labelRight')

  return (
    <Grid container>
      {!isParent && (
        <AccountSummary
          accountId={accountId}
          accountNumber={accountNumber}
          accountName={accountName}
          billingProfileName={billingProfileName}
          billingCycle={labelRight}
        />
      )}

      <Grid container alignItems="center" px={isParent ? 0 : 2}>
        <Grid item xs>
          <ListItem sx={{ '&.MuiListItem-root': { p: 0 } }}>
            <ListItemAvatar sx={{ mr: 2 }}>
              <Stack spacing={1}>
                <InvoiceStatusChip
                  status={status}
                  isPartial={isPartial}
                  agingBucketType={groupName || agingBucketType}
                  count={get(rowNode, 'children', []).length}
                />
                {isPartial && agingBucketType && (
                  <Typography color={AGING_BUCKET_COLORS[agingBucketType][700]} variant="caption" fontWeight={700}>
                    <HHDisplayMoney value={pendingAmount} /> left
                  </Typography>
                )}
              </Stack>
            </ListItemAvatar>
            {invoiceNumber && (
              <ListItemText
                primary={title}
                primaryTypographyProps={{ variant: 'subtitle2', fontWeight: 500, color: 'textPrimary' }}
                secondary={
                  <>
                    <Typography display="block" variant="caption">{`Invoice #: ${invoiceNumber}`}</Typography>
                    <Typography display="block" variant="caption">{`Invoice date: ${parsedInvoiceDate}`}</Typography>
                  </>
                }
              />
            )}
          </ListItem>
        </Grid>
        <Grid item>
          <ListItem>
            <ListItemText
              primary={<HHDisplayMoney value={totalCents} formatToDollars />}
              primaryTypographyProps={{ variant: 'h6', fontWeight: 500, color: 'textPrimary', textAlign: 'right' }}
              secondary={rawDueDate ? `Due: ${dueDate}` : null}
              secondaryTypographyProps={{ color: 'info.light', fontWeight: 400 }}
            />
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

RenderInvoiceCell.propTypes = {
  row: PropTypes.object,
  rowNode: PropTypes.object,
}

export default RenderInvoiceCell
