import React, { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  styled,
  IconButton,
  Button,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import T from 'T'
import { useFieldArray, useForm, useFormState, useWatch } from 'react-hook-form'
import { XCircle } from '@styled-icons/heroicons-solid/XCircle'
import isEmpty from 'lodash/isEmpty'
import { useFetchTagListMutation } from 'api/tags/fetchTagList'
import { useAddTagsMutation } from 'api/tags/addTags'
import { HHFormSwitchField, HHFormCheckboxField, HHFormTextField } from '../../form-fields/v5'
import Tag from '../../tags/Tag'
import { FooterButton } from '../../buttons'
import FormColorPickerField from './tags/FormColorPickerField'
import ShimmerTagTableRow from './tags/ShimmerTagTableRow'

const headerValues = [
  { name: T.COLOR },
  { name: T.LABEL },
  { name: T.ACCOUNT },
  { name: T.LOCATION },
  { name: T.SERVICE },
  { name: T.MOBILE },
  { name: T.PREVIEW },
  { name: T.ACTIVE },
]

const StyledTableCell = styled(TableCell)(() => ({
  padding: 12,
}))

const IsolatedTag = ({ control, index }) => {
  const tag = useWatch({
    control,
    name: `tags.${index}`,
  })
  return <Tag tag={{ ...tag, hasBorder: tag.border }} />
}

const headerTableCellSX = {
  borderRadius: '8px 8px 0 0',
  padding: '25px 8px 10px',
  backgroundColor: 'background.paper',
  color: 'text.secondary',
}

const TagSettings = () => {
  const [showFooter, toggleShowFooter] = useState(false)
  const [fetch, { data, isLoading }] = useFetchTagListMutation()
  const [addTags, { isLoading: isAddTagsLoading }] = useAddTagsMutation()
  const { control, reset, watch, getValues, setValue, handleSubmit } = useForm()
  const { replace, append, remove, fields } = useFieldArray({
    control,
    name: 'tags',
  })
  const { touchedFields } = useFormState({
    control,
  })

  const shimmersArray = new Array(30).fill(0)
  const isDirty = Array.isArray(touchedFields?.tags) ? touchedFields.tags.filter(f => !isEmpty(f)).length > 0 : false

  const onSubmit = async ({ tags }) => {
    if (Array.isArray(tags) && !isAddTagsLoading) {
      await addTags({
        tag: tags.filter(({ tagName }) => tagName !== '').map(tag => ({ ...tag, tagName: tag.tagName.trim() })),
      }).unwrap()
      await fetch()
        .unwrap()
        .then(d => {
          replace(d.tag)
        })
      toggleShowFooter(false)
    }
  }

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name.includes('.')) {
        const [parent, index] = name.split('.')
        const tagId = getValues(`${parent}.${index}`)
        setValue(`${parent}.${index}.editId`, tagId)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    fetch({})
      .unwrap()
      .then(d => {
        replace(d.tag)
      })
  }, [])

  return (
    <form>
      <Grid container sx={{ paddingTop: '7px' }}>
        <Grid px={4} item sm={12}>
          <Typography variant="h3" fontWeight={600}>{`${T.TAG}s`}</Typography>
        </Grid>
        <Grid item sm={12} sx={{ paddingTop: 3 }}>
          <Card>
            <TableContainer sx={{ height: 'calc(100vh - 185px)', overflowY: 'scroll' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headerValues.map(val => (
                      <TableCell sx={headerTableCellSX}>
                        <Typography variant="subtitle2">{val.name}</Typography>
                      </TableCell>
                    ))}
                    <TableCell sx={headerTableCellSX} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && shimmersArray.map(e => <ShimmerTagTableRow key={e} />)}
                  {Array.isArray(fields) &&
                    !isLoading &&
                    fields.map(({ id, tagId, tagName }, index) => (
                      <TableRow key={id}>
                        <StyledTableCell>
                          <FormColorPickerField control={control} name={`tags.${index}.tagColor`} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2" color="text.primary">
                            {tagId && tagName}
                            {!tagId && (
                              <HHFormTextField size="small" control={control} placeholder={T.LABEL} name={`tags.${index}.tagName`} />
                            )}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <HHFormCheckboxField control={control} name={`tags.${index}.forAccount`} sx={{ padding: 0 }} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <HHFormCheckboxField control={control} name={`tags.${index}.forLocation`} sx={{ padding: 0 }} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <HHFormCheckboxField control={control} name={`tags.${index}.forService`} sx={{ padding: 0 }} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <HHFormCheckboxField control={control} name={`tags.${index}.forMobile`} sx={{ padding: 0 }} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IsolatedTag control={control} index={index} />
                        </StyledTableCell>
                        <StyledTableCell>
                          <HHFormSwitchField control={control} name={`tags.${index}.active`} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {!tagId && (
                            <IconButton
                              onClick={() => {
                                remove(index)
                              }}
                            >
                              <XCircle width={20} />
                            </IconButton>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <StyledTableCell colSpan={2} sx={{ paddingX: 1, borderBottom: 'none' }}>
                      <Button
                        onClick={() => {
                          append({
                            tagId: '',
                            tagColor: '#fff',
                            tagTextColor: '#042040',
                            tagName: '',
                            border: '',
                            forAccount: false,
                            forLocation: false,
                            forService: false,
                            forMobile: false,
                            active: true,
                          })
                          toggleShowFooter(true)
                        }}
                        variant="text"
                        color="primary"
                        startIcon={<Add />}
                      >
                        Add Tag
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {(isDirty || showFooter) && (
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'border.light',
                  boxShadow: ' 0 1px 3px rgba(35,38,59,.15)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingX: 3,
                  paddingY: 1,
                }}
              >
                <FooterButton
                  leftButtonTitle={T.CANCEL}
                  onClose={() => {
                    reset()
                    replace(Array.isArray(data?.tag) ? data.tag : [])
                    toggleShowFooter(false)
                  }}
                  rightButtonTitle={T.SAVE}
                  proceedButtonType="submit"
                  onProceed={handleSubmit(onSubmit)}
                />
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </form>
  )
}

TagSettings.propTypes = {}

export default TagSettings
