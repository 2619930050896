import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { DialogContent, Divider, List, ListItem, ListItemText } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import T from 'T'
import { get, noop } from 'lodash'
import SaveButton from 'components/buttons/SaveButton'
import CancelButton from 'components/buttons/CancelButton'
import Dialog from '@mui/material/Dialog'
import AddLateFeeItem from 'components/customer-details/content/fees/late_fees/AddLateFeesDialog/AddLateFeeItem'
import { useLazyFetchLateFeesQuery } from 'api/pricing/lateFeesCrud'
import AddLateFeeItemSkeleton from 'components/customer-details/content/fees/late_fees/AddLateFeesDialog/AddLateFeeItemSkeleton'

const AddLateFeesDialog = ({ open = false, onClose = noop }) => {
  const listSx = {
    maxHeight: {
      lg: 64 * 5,
      md: 64 * 3,
      sm: 64 * 3,
      xs: 64 * 3,
    },
    overflow: 'auto',
  }
  const [fetchLateFees, { data, isLoading, isFetching, isSuccess }] = useLazyFetchLateFeesQuery()
  const lateFees = useMemo(() => get(data, 'lateFees', []), [data])
  const [selectedLateFeesIds, setSelectedLateFeesIds] = useState([])
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const shimmersArray = new Array(10).fill()

  useEffect(() => {
    if (open) {
      fetchLateFees()
    }
  }, [open])

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title={T.ADD_LATE_FEE} onClose={onClose} />
      <DialogContent>
        <ListItem
          sx={{
            px: 1,
          }}
        >
          <ListItemText
            primary="Already added"
            primaryTypographyProps={{
              variant: 'h6',
              color: 'textPrimary',
            }}
          />
        </ListItem>
        <List disablePadding sx={listSx}>
          {isSuccess &&
            lateFees?.map(lateFee => {
              const { id } = lateFee
              return <AddLateFeeItem disabled indeterminate lateFee={lateFee} key={`selected-latefee${id}`} />
            })}
          {/* eslint-disable-next-line react/no-array-index-key */}
          {loading && shimmersArray.map((_, id) => <AddLateFeeItemSkeleton key={`skeleton-latefee${id}`} />)}
        </List>
        <Divider
          sx={{
            my: 2,
          }}
        />
        <List disablePadding sx={listSx}>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {loading && shimmersArray.map((_, id) => <AddLateFeeItemSkeleton key={`skeleton-latefee${id}`} />)}{' '}
          {isSuccess &&
            lateFees?.map(lateFee => {
              const { id } = lateFee
              const checked = selectedLateFeesIds.includes(id)
              const toggleLateFeeSelection = checked => {
                console.log(checked)
                if (checked) {
                  setSelectedLateFeesIds([...selectedLateFeesIds, id])
                } else {
                  setSelectedLateFeesIds(selectedLateFeesIds.filter(lateFeeId => lateFeeId !== id))
                }
              }
              return <AddLateFeeItem key={`to-select-latefee${id}`} checked={checked} lateFee={lateFee} onChange={toggleLateFeeSelection} />
            })}
        </List>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton label={T.ADD} onClick={onClose} />
      </HHDialogActions>
    </Dialog>
  )
}

AddLateFeesDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default AddLateFeesDialog
