import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { css } from '@emotion/react'
import { register } from 'swiper/element/bundle'
import { noop } from 'lodash'

const SwiperContainer = ({
  breakpoints = undefined,
  bulletActiveClass = 'swiper-pagination-bullet-active',
  centeredSlides = false,
  children,
  className,
  clickable = true,
  freeMode = true,
  slidesPerView = 1,
  spaceBetween = 0,
  swiperRef,
  sx,
  thumbsSwiper,
  watchSlidesProgress = false,
  onReachEnd = noop,
  onSlideChange = noop,
}) => {
  useEffect(() => {
    register()

    const styles = sx ? css(sx).styles : ''
    const params = {
      breakpoints,
      centeredSlides,
      freeMode,
      injectStyles: sx ? [styles] : [],
      loop: false,
      pagination: {
        clickable,
        bulletActiveClass,
      },
      slidesPerView,
      spaceBetween,
      thumbs: {
        swiper: thumbsSwiper,
      },
      watchSlidesProgress,
      on: {
        reachEnd: onReachEnd,
        slideChange: onSlideChange,
      },
    }

    Object.assign(swiperRef.current, params)
    swiperRef.current.initialize()
  }, [])

  return (
    <Box sx={sx}>
      <swiper-container className={className} ref={swiperRef} init="false">
        {children}
      </swiper-container>
    </Box>
  )
}

SwiperContainer.propTypes = {
  breakpoints: PropTypes.object,
  bulletActiveClass: PropTypes.string,
  centeredSlides: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  freeMode: PropTypes.bool,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  swiperRef: PropTypes.oneOf([PropTypes.func, PropTypes.object]).isRequired,
  sx: PropTypes.object,
  thumbsSwiper: PropTypes.any,
  watchSlidesProgress: PropTypes.bool,
  onReachEnd: PropTypes.func,
  onSlideChange: PropTypes.func,
}

export default SwiperContainer
