import React from 'react'
import PropTypes from 'prop-types'

import { DialogContentText, Typography, List, ListItem } from '@mui/material'

const LIST_STYLES = { listStyleType: 'disc', pl: 3 }
const LIST_ITEM_STYLES = { display: 'list-item' }

const ImportSequenceDialogContent = ({ resequenceColumnName }) => {
  return (
    <DialogContentText color="text.primary">
      <Typography>
        You are about to replace the sequence for this route on this day <strong>only</strong>.
      </Typography>

      <Typography mt={2}>
        <strong>Please note:</strong>
        <List sx={LIST_STYLES}>
          <ListItem disablePadding sx={LIST_ITEM_STYLES}>
            That this does <strong>not</strong> effect work orders.
          </ListItem>
          <ListItem disablePadding sx={LIST_ITEM_STYLES}>
            It’s best to export the route immediately before sequencing to get the format right and avoid losing sequence for unmatched work
            orders.
          </ListItem>
        </List>
      </Typography>

      <Typography mt={2}>
        <strong>Before you begin, make sure:</strong>
        <List sx={LIST_STYLES}>
          <ListItem disablePadding sx={LIST_ITEM_STYLES}>
            File contains the column <strong>{resequenceColumnName}</strong>.
            <List disablePadding sx={LIST_STYLES}>
              <ListItem disablePadding sx={LIST_ITEM_STYLES}>
                Order doesn’t matter
              </ListItem>
            </List>
          </ListItem>
          <ListItem disablePadding sx={LIST_ITEM_STYLES}>
            File format is <strong>.XLSX</strong>
          </ListItem>
        </List>
      </Typography>
    </DialogContentText>
  )
}

ImportSequenceDialogContent.propTypes = {
  resequenceColumnName: PropTypes.string.isRequired,
}

export default ImportSequenceDialogContent
