import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Grid, Box, Typography } from '@mui/material'

import T from 'T'

import ColorPickerToolBar from './ColorPickerToolBar'

const ColorScheme = ({
  mainColor = '',
  secondaryColor = '',
  navigationFontColor = '',
  actionButtonColor = '',
  actionButtonFontColor = '',
  onHandleChange = noop,
  handleColorPickerChangeComplete = noop,
}) => {
  const tools = [
    { title: T.MAIN_NAVIGATION_BAR, color: mainColor, type: 'mainColor' },
    { title: T.NAVIGATION_FONT_COLOR, color: navigationFontColor, type: 'navigationFontColor' },
    { title: T.SECONDARY_TEXT_HEADINGS, color: secondaryColor, type: 'secondaryColor' },
    { title: T.ACTION_BUTTONS, color: actionButtonColor, type: 'actionButtonColor' },
    { title: T.ACTION_BUTTONS_FONT_COLOR, color: actionButtonFontColor, type: 'actionButtonFontColor' },
  ]

  return (
    <Box>
      <Typography variant="h3">{T.CUSTOMER_PORTAL}</Typography>
      <Typography variant="subtitle1" mt={3}>
        {T.COLOR_SCHEME}
      </Typography>
      <Grid container>
        {Children.toArray(
          tools.map(tool => (
            <Grid item xs={12} md={6}>
              <ColorPickerToolBar
                title={tool.title}
                color={tool.color}
                type={tool.type}
                onHandleChange={onHandleChange}
                handleColorPickerChangeComplete={handleColorPickerChangeComplete}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  )
}
ColorScheme.propTypes = {
  mainColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  navigationFontColor: PropTypes.string,
  actionButtonColor: PropTypes.string,
  actionButtonFontColor: PropTypes.string,
  onHandleChange: PropTypes.func,
  handleColorPickerChangeComplete: PropTypes.func,
}

export default ColorScheme
