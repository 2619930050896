import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getProfileList: build.query({
      query: body => ({
        url: 'api/settings/profile/list',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useLazyGetProfileListQuery } = extendedApi
