import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@mui/material'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { HHAlert } from 'components/common/HHAlert'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useDispatch, useSelector } from 'react-redux'
import { selectBulkResequenceRows, setBulkResequenceStopsState, selectIsSelectDateDialogOpen } from 'slices/route/bulkResequenceStopsSlice'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { mapStopToListItemFormat } from 'utils/route'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import RenderStopListItem from './RenderStopListItem'

const StopsDataGrid = ({ apiRef, routes }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { control, watch, setValue, setFocus } = useFormContext()
  const routeId = watch('routeId')
  const serviceDate = watch('serviceDate')
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const rows = useSelector(selectBulkResequenceRows)
  const changedStops = rows.find(row => row.isChanged)

  const columns = [
    {
      field: 'id',
      renderCell: RenderStopListItem,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  useEffect(() => {
    if (!isSelectDateDialogOpen && serviceDate && routeId) {
      getRouteStops({
        routes: [routeId],
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
        .unwrap()
        .then(data => {
          const stops = get(data, `routes.0.stops`, [])
          const formattedStops = []
          stops.forEach((stop, index) => {
            const id = get(stop, 'workOrderId')
            formattedStops.push({
              ...mapStopToListItemFormat(stop),
              position: index,
              originalPosition: index,
              routeId: get(stop, 'routeId'),
            })
            setValue(id, index + 1)
          })
          dispatch(setBulkResequenceStopsState({ rows: formattedStops }))
          const firstStopId = get(formattedStops, '0.id')
          if (firstStopId) {
            setFocus(firstStopId)
          }
        })
    }
  }, [isSelectDateDialogOpen, serviceDate, routeId])

  const getRowClassName = useCallback(params => {
    if (params.row?.isChanged) {
      return params.row.position === '' ? 'from-stop-to-be-assigned' : 'from-stop-assigned'
    }
    return ''
  }, [])

  const isRowSelectable = useCallback(() => false, [])

  return (
    <>
      <Box mx={2} mb={2}>
        <DesktopRoutePicker readOnly={changedStops} name="routeId" control={control} routes={routes} />
        {!routeId && (
          <HHAlert severity="info" borderColor={theme.palette.info.dark} sx={{ mt: 1 }}>
            Select a route
          </HHAlert>
        )}
      </Box>
      <Box flex={1} height="100%" width="100%" overflow="hidden">
        {!isSelectDateDialogOpen && routeId && (
          <BulkMoveStopsDataGridPro
            apiRef={apiRef}
            isRowSelectable={isRowSelectable}
            getRowClassName={getRowClassName}
            loading={isLoading || isFetching}
            columns={columns}
            rows={rows}
            checkboxSelection={false}
            headerHeight={0}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                display: 'none',
              },
            }}
          />
        )}
      </Box>
    </>
  )
}

StopsDataGrid.propTypes = {
  routes: PropTypes.array.isRequired,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default StopsDataGrid
