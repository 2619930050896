import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, ListItem, ListItemText, IconButton, Avatar, Chip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import green from '@mui/material/colors/green'

import { get } from 'utils/lodash'
import { getPaymentMethodImage, getPaymentMethodName } from 'utils/payment'
import { PAYMENT_METHODS, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'

import T from 'T'

const { CREDIT_CARD } = PAYMENT_METHODS

const PaymentMethodView = ({ paymentMethod = {}, onActionClick }) => {
  const expiryDate = get(paymentMethod, 'expiry')
  const accountType = get(paymentMethod, 'accountType', '')
  const creditCardType = get(paymentMethod, 'creditCardType')
  const methodName = getPaymentMethodName(creditCardType, accountType)
  const cardImage = getPaymentMethodImage(creditCardType || methodName)
  const accountNumber = get(paymentMethod, 'accountNumber')
  const secondaryText = methodName === CREDIT_CARD && expiryDate ? `Expires on ${expiryDate.substr(0, 2)}/${expiryDate.substr(2)}` : null
  const cardNickName = get(paymentMethod, 'cardNickName', '--')
  const autoPay = get(paymentMethod, 'autoPay')

  return (
    <Box px={2} py={1}>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="h6" fontWeight={500}>
              {get(PAYMENT_METHODS_LABELS, methodName, '--')}
            </Typography>
          }
        />
        <IconButton size="small" onClick={onActionClick}>
          <MoreVertIcon />
        </IconButton>
      </ListItem>
      <ListItem disablePadding>
        <img src={cardImage} alt="" />
        <ListItemText sx={{ ml: 1 }} primary={`${creditCardType || accountType} •••• ${accountNumber}`} secondary={secondaryText} />
      </ListItem>
      <ListItem disablePadding sx={{ ' .MuiListItemText-root': { display: 'flex', flexDirection: 'column-reverse' } }}>
        <ListItemText primary={cardNickName} secondary="Nickname" />
        {autoPay && (
          <Chip
            size="small"
            avatar={
              <Avatar sx={{ backgroundColor: green['A700'] }}>
                <CheckIcon sx={{ color: 'background.paper', width: 12, height: 12 }} />
              </Avatar>
            }
            label={T.AUTOPAY}
            sx={{ backgroundColor: green[50], border: '1px solid', borderColor: green['A700'], color: green[800] }}
          />
        )}
      </ListItem>
    </Box>
  )
}

PaymentMethodView.propTypes = {
  paymentMethod: PropTypes.object,
  onActionClick: PropTypes.func.isRequired,
}

export default PaymentMethodView
