import React, { useCallback } from 'react'
import StarBorderIcon from '@mui/icons-material/StarOutlineRounded'
import StarIcon from '@mui/icons-material/StarRateRounded'
import { IconButton } from '@mui/material'
import { selectHomePage, setHomePage } from 'slices/home/homeSlice'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const CustomerFavButton = ({ url }) => {
  const homePage = useSelector(selectHomePage, shallowEqual)
  const isFavorite = url === homePage
  const dispatch = useDispatch()

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(setHomePage(url))
    },
    [url, homePage]
  )

  return <IconButton onClick={handleClick}>{isFavorite ? <StarIcon /> : <StarBorderIcon />}</IconButton>
}

CustomerFavButton.propTypes = {
  url: PropTypes.string,
}

export default CustomerFavButton
