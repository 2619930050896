import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Tooltip, useTheme, SvgIcon } from '@mui/material'

import { Archive } from '@styled-icons/heroicons-outline/Archive'

import T from 'T'
import StyledBadge from '../StyledBadge'

const ViewArchivedNotesAction = ({ onViewArchiveClick, archivedNotes = [] }) => {
  const theme = useTheme()
  const hasArchivedNotes = archivedNotes.length > 0

  const handleViewArchiveClick = event => {
    event.stopPropagation()
    if (hasArchivedNotes) {
      onViewArchiveClick()
    }
  }

  return hasArchivedNotes ? (
    <IconButton onClick={handleViewArchiveClick}>
      <StyledBadge badgeContent={archivedNotes.length}>
        <Archive width={24} height={24} />
      </StyledBadge>
    </IconButton>
  ) : (
    <Tooltip title={T.NO_ARCHIVED_NOTE_AVAILABLE} disableHoverListener={hasArchivedNotes}>
      <SvgIcon sx={{ cursor: 'default', m: 1 }} onClick={handleViewArchiveClick}>
        <Archive width={24} height={24} color={theme.palette.action.disabled} />
      </SvgIcon>
    </Tooltip>
  )
}

ViewArchivedNotesAction.propTypes = {
  archivedNotes: PropTypes.array,
  onViewArchiveClick: PropTypes.func.isRequired,
}

export default ViewArchivedNotesAction
