import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import difference from 'lodash/difference'
import lowerCase from 'lodash/lowerCase'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { replaceAllSpacesAndAmpersand } from 'utils/string'
import { Box, Button, MenuItem, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'
import Table from 'components/settings/pages/businesslines/Table'
import Constants from 'Constants'
import T from 'T'
import { canAddSettings } from 'data/permissions/permissionsSelectors'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants
const headerValues = [
  { filter: false, name: T.METHOD_NAME, sort: false },
  { filter: false, name: '', sort: false },
]

const Methods = ({ method = [], addMore = false, methodCombinations = [], setServiceState = noop, setAddMore = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)

  const METHODS = [T.FRONT_LOAD, T.REAR_LOAD, T.HOOK, T.HOIST, T.GRAPPLE, T.SIDE_LOAD]

  const uniqueRecords = difference(
    METHODS,
    methodCombinations.map(data => data.methodName)
  )

  const [methods, setMethods] = useState([])
  const [currentMethod, setCurrentMethod] = useState([])

  useEffect(() => {
    const methodCloned = cloneDeep(method)
    setServiceState({
      methodCombinations: methodCloned,
    })
  }, [method])

  useEffect(() => {
    setMethods(uniqueRecords)
  }, [methodCombinations])

  const onHandleChange = (e, index) => {
    methodCombinations[index]['methodName'] = e.target.value
    setServiceState({ methodCombinations })
  }

  const updateRecords = () => {
    const newRecords = difference(
      METHODS,
      methodCombinations.map(data => data.methodName)
    )

    setMethods(newRecords)

    setAddMore(false)
  }

  const handleRemoval = index => {
    methodCombinations.splice(index, 1)
    setServiceState({ methodCombinations })

    if (!methodCombinations.map(data => data.id).includes('')) {
      setServiceState({ showFooter: false })
    }
    updateRecords()
  }

  const getSearchList = value => {
    const values = uniqueRecords.filter(data => lowerCase(data).includes(lowerCase(value)))
    setCurrentMethod([])
    setMethods(values)
  }

  const handleAddMore = () => setAddMore(!addMore)

  return (
    <Box>
      <Table
        headerValues={headerValues}
        records={methodCombinations}
        onHandleChange={onHandleChange}
        handleRemoval={handleRemoval}
        type="method"
      />

      {addMore && (
        <HHTextField
          select
          sx={{
            minWidth: 300,
            '& .MuiSelect-select': {
              display: 'flex',
            },
            mt: 2,
          }}
          value={[currentMethod]}
          onChange={e => {
            const { value } = e.target

            const methodVal = get(value, '[0]', '')

            if (!methodVal) return
            getSearchList(methodVal)

            let newRecords = methodCombinations.find(data => data.methodName === methodVal)

            if (newRecords === undefined) {
              newRecords = {
                methodName: methodVal,
                methodType: methodVal,
                alreadySelected: false,
                id: '',
              }
            }
            methodCombinations.push(newRecords)
            setServiceState({
              methodCombinations,
              showFooter: true,
            })

            updateRecords()
          }}
        >
          <MenuItem value="">{`Select ${T.METHOD}s`}</MenuItem>
          {methods.map(data => (
            <MenuItem value={[data]} sx={{ display: 'flex' }}>
              {data && <img src={`${MEDIA_SERVER}Method/${replaceAllSpacesAndAmpersand(data)}.svg`} alt="icons" />}
              <Typography variant="body1" ml={1}>
                {data}
              </Typography>
            </MenuItem>
          ))}
        </HHTextField>
      )}

      {addSettings && (
        <Button onClick={handleAddMore} variant="text" color="primary" startIcon={<Add />} sx={{ mt: 1, display: 'flex' }}>
          {T.ADD_METHOD}
        </Button>
      )}
    </Box>
  )
}

Methods.propTypes = {
  method: PropTypes.array,
  addMore: PropTypes.bool,
  methodCombinations: PropTypes.array,
  setServiceState: PropTypes.func,
  setAddMore: PropTypes.func,
}

export default Methods
