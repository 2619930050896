import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Grid, ListItem, ListItemText, IconButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import InvoiceGroupCard from './InvoiceGroupCard'

const InvoiceGroupDataGridRow = ({ originalRows = [], row = {}, collapsedGroups = [], noOfColumns = 1, onGroupCollapse }) => {
  const { isParent, originalIndex, groupRef } = row
  const isOpen = !collapsedGroups.includes(groupRef)

  const handleClose = () => onGroupCollapse(!isOpen, groupRef)

  return (
    <>
      {isParent && (
        <ListItem sx={{ px: 3.5, py: 0 }}>
          <IconButton onClick={handleClose}>{isOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
          <ListItemText primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }} primary={row.groupName} />
        </ListItem>
      )}

      {!isParent && (
        <Grid container spacing={2} px={3}>
          {Children.toArray(
            new Array(noOfColumns).fill().map((_, index) => {
              const nextRow = get(originalRows, `[${originalIndex + index}]`)

              return (
                nextRow &&
                nextRow.groupRef === groupRef && (
                  <Grid item xs={12} md={6} xl={4}>
                    <InvoiceGroupCard group={nextRow} />
                  </Grid>
                )
              )
            })
          )}
        </Grid>
      )}
    </>
  )
}

InvoiceGroupDataGridRow.propTypes = {
  originalRows: PropTypes.array,
  row: PropTypes.object,
  collapsedGroups: PropTypes.array,
  noOfColumns: PropTypes.number,
  onGroupCollapse: PropTypes.func.isRequired,
}

export default InvoiceGroupDataGridRow
