import React from 'react'
import PropTypes from 'prop-types'
import upperCase from 'lodash/upperCase'

import { Box, Button, Typography } from '@mui/material'

import T from 'T'
import { PRIMARY_BUTTON_SX } from './settings'

const RecoverPasswordSuccess = ({ email = '', onClose }) => (
  <>
    <Typography variant="h4">Check your email</Typography>
    <Typography my={2} variant="h5" fontWeight={400}>
      {`Follow the instructions sent to ${email} to recover your password`}
    </Typography>
    <Box my={2} display="flex" justifyContent="flex-end">
      <Button sx={{ ...PRIMARY_BUTTON_SX }} size="small" variant="contained" onClick={onClose}>
        {upperCase(T.DONE)}
      </Button>
    </Box>
  </>
)

RecoverPasswordSuccess.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default RecoverPasswordSuccess
