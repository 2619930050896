import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography, Box, Tab, styled, Card, useTheme, IconButton, Tooltip, CircularProgress, ListItem, ListItemText } from '@mui/material'
import { TabContext, TabPanel, TabList, LoadingButton } from '@mui/lab'
import { ReactComponent as UserLockSolidIcon } from 'assets/UserLockSolid.svg'
import { ReactComponent as UserUnlockSolidIcon } from 'assets/UserUnlockSolid.svg'
import { MoreVert } from '@mui/icons-material'

import FooterButton from 'components/buttons/FooterButton'
import Loader from 'components/common/loader'
import T from 'T'
import Constants from 'Constants'

import { get } from 'utils/lodash'
import { canSeePermissionSettings } from 'data/permissions/permissionsSelectors'
import { useUpdateUserMutation } from 'api/auth/updateUser'
import { isEmail } from 'utils/validations'
import { useLazyGetEmailStatusQuery } from 'api/settings/getEmailStatus'
import { getIsPendingOrDeactivated, getIsPending, getIsActivatedOrPending } from 'settings/constants/user'
import { HHAlert } from 'components/common/HHAlert'
import { handleError } from 'utils/error'
import { useLazyGetUserByIdQuery } from 'api/settings/getUserById'
import { useResendActivationEmailMutation } from 'api/auth/resendActivationEmail'

import PersonalInfoScreen from './PersonalInfoScreen'
import PayScreen from './PayScreen'
import ProfileScreen from './ProfileScreen'
import EditUserActionMenu from './action-menu/EditUserActionMenu'

const { CAN_UPDATE_USER } = Constants

const AddUsers = ({
  userState = {},
  setUserState = noop,
  roleState = {},
  setRoleState = noop,
  isLoading = false,
  isSubmitting = false,
  handleSwitchVal = noop,
  onHandleNameChange = noop,
  handleSave = noop,
  handleCancel = noop,
  originalEmail = '',
}) => {
  const seePermissionSettings = useSelector(canSeePermissionSettings, shallowEqual)
  const theme = useTheme()
  const [resendActivationEmail, { isLoading: isLoadingResendActivationEmail }] = useResendActivationEmailMutation()
  const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation()
  const [getUserById, { isFetching: isLoadingGetUserById }] = useLazyGetUserByIdQuery()
  const [getEmailStatus, { isFetching }] = useLazyGetEmailStatusQuery()
  const [isEmailAlreadyExist, setIsEmailAlreadyExist] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null)

  const { userTab, firstName, lastName, role, active, status, userID } = userState
  const isEditMode = Boolean(userID)
  const isPending = getIsPending(status)
  const isPendingOrDeactivated = getIsPendingOrDeactivated(status)

  const handleChange = (event, newValue) => {
    if (newValue !== T.PERSONAL_INFORMATION && userState.profileId === '') return

    setUserState({ userTab: newValue })
  }

  const handleEmailChange = event => {
    setIsEmailAlreadyExist(false)
    const email = event.target.value.toLowerCase()

    if (!isEmail(email)) {
      setIsValidEmail(false)
      return
    }

    setIsValidEmail(true)

    if (email === originalEmail.toLowerCase()) {
      // For edit case, Ignore current email
      setIsEmailAlreadyExist(false)
      return
    }

    getEmailStatus({ email })
      .unwrap()
      .then(response => {
        setIsEmailAlreadyExist(response.exists)
      })
      .catch(() => setIsValidEmail(false))
  }

  const StyledTab = styled(Tab)(() => ({
    textTransform: 'none',
    padding: 0,
    minWidth: 'auto',
    marginRight: 16,
  }))

  const isInvalidEmail = isFetching || isEmailAlreadyExist || !isValidEmail

  useEffect(() => {
    if (originalEmail) {
      setIsValidEmail(true)
    }
  }, [originalEmail])

  const resetStatus = () => {
    getUserById({ id: userID })
      .unwrap()
      .then(res => {
        const newStatus = get(res, 'user.status')
        setUserState({ active: getIsActivatedOrPending(newStatus), status: newStatus })
      })
      .catch(handleError)
  }

  const handleStatusChange = () => {
    updateUser({ email: userState.email, payload: { status: !active } })
      .unwrap()
      .then(resetStatus)
      .catch(handleError)
  }

  const handleOpenActionMenu = e => setActionMenuAnchorEl(e.currentTarget)
  const handleCloseActionMenu = () => setActionMenuAnchorEl(null)

  const handleResendActivationEmail = () => {
    resendActivationEmail({ email: userState.email })
      .unwrap()
      .then(() => toast.success(T.INVITE_SENT_SUCCESS_MESSAGE))
      .catch(handleError)
  }

  return (
    <Card>
      <Box p="20px 24px" maxHeight="calc(100vh - 175px)">
        {isEditMode && isPendingOrDeactivated && (
          <>
            <HHAlert
              borderColor={isPending ? theme.palette.info.light : theme.palette.warning.light}
              severity={isPending ? 'info' : 'warning'}
              sx={{ mb: 2, alignItems: 'center', '& .MuiAlert-message': { width: '100%', p: 0 } }}
            >
              {isPending ? (
                <ListItem
                  disablePadding
                  disableGutters
                  secondaryAction={
                    <Box display="flex" alignItems="center" gap={1}>
                      <LoadingButton loading={isLoadingResendActivationEmail} variant="text" onClick={handleResendActivationEmail}>
                        {T.RESEND_INVITE}
                      </LoadingButton>
                      <IconButton size="small" onClick={handleOpenActionMenu}>
                        <MoreVert />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{ variant: 'h5', fontWeight: 500 }}
                    primary="Invite pending"
                    secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
                    secondary="An activation email has been sent to this account"
                  />
                </ListItem>
              ) : (
                <Typography variant="h5" fontWeight={500}>
                  User deactivated
                </Typography>
              )}
            </HHAlert>
            <EditUserActionMenu userId={userID} anchorEl={actionMenuAnchorEl} onClose={handleCloseActionMenu} />
          </>
        )}
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h3">{`${firstName === '' ? 'Name' : firstName} ${lastName === '' ? 'Lastname' : lastName}`}</Typography>
            <Typography variant="h5" ml={2}>
              {role}
            </Typography>
          </Box>

          {isEditMode &&
            (isLoadingUpdateUser || isLoadingGetUserById ? (
              <CircularProgress />
            ) : (
              <Tooltip title={active ? 'Deactivate user' : 'Activate user'} placement="left">
                <IconButton
                  disabled={!CAN_UPDATE_USER}
                  onClick={handleStatusChange}
                  disableElevation
                  disableRipple
                  size="small"
                  sx={{ background: active ? theme.palette.warning.main : theme.palette.success.main }}
                >
                  {active ? <UserLockSolidIcon /> : <UserUnlockSolidIcon />}
                </IconButton>
              </Tooltip>
            ))}
        </Box>

        {isLoading && <Loader />}

        <TabContext value={userTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
            <TabList onChange={handleChange}>
              <StyledTab label={T.PERSONAL_INFORMATION} value={T.PERSONAL_INFORMATION} />
              <StyledTab label={T.PAY} value={T.PAY} />
              {seePermissionSettings && <StyledTab label={T.PROFILE} value={T.PROFILE} />}
            </TabList>
          </Box>

          <Box
            sx={{
              '.MuiTabPanel-root': {
                p: '15px 0',
              },
              height: userTab !== T.PROFILE ? 'calc(100vh - 305px)' : '',
              overflowY: 'auto',
            }}
          >
            <TabPanel value={T.PERSONAL_INFORMATION}>
              <PersonalInfoScreen
                userState={userState}
                setUserState={setUserState}
                roleState={roleState}
                setRoleState={setRoleState}
                handleSwitchVal={handleSwitchVal}
                onHandleNameChange={onHandleNameChange}
                isValidEmail={isValidEmail}
                isEmailAlreadyExist={isEmailAlreadyExist}
                onEmailChange={handleEmailChange}
              />
            </TabPanel>

            <TabPanel value={T.PAY}>
              <PayScreen userState={userState} roleState={roleState} setUserState={setUserState} onHandleNameChange={onHandleNameChange} />
            </TabPanel>

            {seePermissionSettings && (
              <TabPanel value={T.PROFILE}>
                <ProfileScreen
                  userState={userState}
                  setUserState={setUserState}
                  roleState={roleState}
                  setRoleState={setRoleState}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
              </TabPanel>
            )}
          </Box>
        </TabContext>
      </Box>

      <Box
        px={3}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderTop: 1, borderColor: 'border.light' }}
      >
        <FooterButton
          isProceedButtonLoading={isSubmitting}
          isProceedButtonMUIType
          leftButtonTitle={T.CANCEL}
          onClose={handleCancel}
          rightButtonTitle={T.SAVE}
          onProceed={handleSave}
          disabledProceed={
            !CAN_UPDATE_USER ||
            userState.firstName === '' ||
            userState.lastName === '' ||
            isInvalidEmail ||
            userState.profileId === '' ||
            !(userState.footerFlag || roleState.footerFlag)
          }
        />
      </Box>
    </Card>
  )
}

AddUsers.propTypes = {
  userState: PropTypes.object,
  setUserState: PropTypes.func,
  roleState: PropTypes.object,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setRoleState: PropTypes.func,
  onHandleNameChange: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSwitchVal: PropTypes.func,
  originalEmail: PropTypes.string,
}

export default AddUsers
