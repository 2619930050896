import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'

import { Archive } from '@styled-icons/heroicons-outline/Archive'

import StyledBadge from '../StyledBadge'

const ViewArchivedNotesMenuOption = ({ isDisabled = false, count = 0, onClick }) => {
  return (
    <MenuItem disabled={isDisabled} onClick={onClick}>
      <ListItemIcon sx={{ mr: 1 }}>
        <StyledBadge badgeContent={count}>
          <Archive width={24} height={24} />
        </StyledBadge>
      </ListItemIcon>
      <ListItemText>View archived notes</ListItemText>
    </MenuItem>
  )
}

ViewArchivedNotesMenuOption.propTypes = {
  isDisabled: PropTypes.bool,
  count: PropTypes.number,
  onClick: PropTypes.func.isRequired,
}

export default ViewArchivedNotesMenuOption
