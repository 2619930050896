import { useCallback, useEffect, useState } from 'react'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { addPositionLayers } from 'utils/map'
import useLoadMapAssets from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useLoadMapAssets'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import mapboxgl from '!mapbox-gl'

const { MAP_VIEW_URL, LIGHT_VIEW, PROJECTION } = MAP_DEFAULT_OPTIONS

const useMapInitDispose = ({ map, mapContainer, open }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const loadMapAssets = useLoadMapAssets(map)
  const userInfo = useSelector(s => s.AuthReducer.userInfo)
  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  const onLoad = useCallback(() => {
    loadMapAssets()
    addPositionLayers(map.current)
    setIsMapLoaded(true)
  }, [map])

  const onStyleLoad = useCallback(() => {
    setIsMapLoaded(false)
    onLoad()
  }, [onLoad])

  const initializeMap = useCallback(() => {
    if (mapContainer.current && !map.current && open) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `${MAP_VIEW_URL}${LIGHT_VIEW}`,
        center: [-98.5795, 39.82835],
        zoom: 3.6,
        projection: PROJECTION,
      })

      map.current.on('load', onLoad)

      map.current.on('style.load', onStyleLoad)
    }
  }, [map, mapContainer, onLoad, onStyleLoad, open])

  const disposeMap = useCallback(() => {
    if (map.current) {
      map.current.remove()
      map.current = null
    }
  }, [map])

  useEffect(() => {
    initializeMap()
    return () => {
      disposeMap()
    }
  }, [])

  useEffect(() => {
    initializeMap()
    return () => {
      disposeMap()
    }
  }, [map, onLoad, onStyleLoad, open])

  useEffect(() => {
    if (!open) {
      disposeMap()
    }
  }, [open])

  return {
    isMapLoaded,
    setIsMapLoaded,
  }
}

export default useMapInitDispose
