import React from 'react'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'
import PaymentGroupDetails from 'containers/billing/groups/payment/details'

const PaymentGroupsDetailsPage = () => {
  return (
    <ProtectedRouteHoC>
      <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
        <PaymentGroupDetails />
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

PaymentGroupsDetailsPage.propTypes = {}

export default PaymentGroupsDetailsPage
