import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useUnsuspendAccountMutation } from 'api/accounts/suspensionsCrud'
import { handleError } from 'utils/error'
import { noop } from 'lodash'
import { useDispatch } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import { toast } from 'react-toastify'
import T from 'T'
import GenericUnsuspendAccountDialog from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/GenericUnsuspendAccountDialog'
import { UNSUSPEND_FORM_MODEL } from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/settings'

const UnsuspendAccountDialog = ({ open = false, accountId, onClose = noop }) => {
  const dispatch = useDispatch()
  const [unsuspendAccount, { isLoading }] = useUnsuspendAccountMutation()
  const formMethods = useForm({
    defaultValues: UNSUSPEND_FORM_MODEL,
  })
  const { watch, reset } = formMethods
  const rescheduleWorkOrders = watch('rescheduleWorkOrders')

  const onUnsuspendAccount = () => {
    unsuspendAccount({
      accountId,
      rescheduleWorkOrders,
    })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        toast.success(T.ACCOUNT_UNSUSPENDED_SUCCESSFULLY)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (open) {
      reset(UNSUSPEND_FORM_MODEL)
    }
  }, [open])

  return (
    <FormProvider {...formMethods}>
      <GenericUnsuspendAccountDialog open={open} onClose={onClose} onSave={onUnsuspendAccount} isSaveLoading={isLoading} />
    </FormProvider>
  )
}

UnsuspendAccountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  accountId: PropTypes.string,
}

export default UnsuspendAccountDialog
