import React from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, Stack, SvgIcon, styled } from '@mui/material'
import { Star } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { ReactComponent as FlagCheckered } from 'assets/route_manager/yards_preview/FlagCheckered.svg'
import { useFormContext } from 'react-hook-form'
import { get, keyBy } from 'lodash'
import { HHFormSelectField } from '../../form-fields/v5'
import { getMetadataBusinessLines, getMetadataMethods, getMetadataYards } from '../../../data/route/routeMetadataSelector'
import Constants from '../../../Constants'
import { replaceAllSpacesAndAmpersand } from '../../../utils/string'

const { MEDIA_SERVER } = Constants

const Icon = styled('img')`
  height: 24px;
  width: 24px;
`

const RouteDetailsFormContent = ({ onStartingYardChange, onEndingYardChange }) => {
  const allYards = useSelector(getMetadataYards)
  const allBusinessLines = useSelector(getMetadataBusinessLines)
  const idToBusinessLineMap = keyBy(allBusinessLines, 'id')
  const allMethods = useSelector(getMetadataMethods)
  const idToMethodsMap = keyBy(allMethods, 'id')
  const methods = useFormContext()
  const {
    control,
    watch,
    formState: { errors },
  } = methods

  const businessLineId = watch('businessLineId')
  const selectedBusinessLineId = businessLineId in idToBusinessLineMap && idToBusinessLineMap[businessLineId]
  const businessLineIcon = get(selectedBusinessLineId, 'businessLineIcon', undefined)

  const methodId = watch('methodId')
  const selectedMethod = methodId in idToMethodsMap && idToMethodsMap[methodId]
  const methodType = get(selectedMethod, 'methodType', undefined)
  const methodIcon = methodType && `${replaceAllSpacesAndAmpersand(methodType)}.svg`
  const endingYardIdError = get(errors, 'endingYardId', false)
  const startingYardIdError = get(errors, 'startingYardId', false)
  const businessLineIdError = get(errors, 'businessLineId', false)
  const methodIdError = get(errors, 'methodId', false)
  const getYardOptionLabel = ({ name }) => name
  const getYardOptionValue = ({ id }) => id

  const getBusinessLineLabel = ({ businessLineLabel }) => businessLineLabel
  const getBusinessLineValue = ({ id }) => id

  const getMethodLabel = ({ methodName }) => methodName
  const getMethodValue = ({ id }) => id

  return (
    <Stack direction="column">
      <HHFormSelectField
        error={businessLineIdError}
        deprecatedLabel={false}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{businessLineIcon && <Icon src={`${MEDIA_SERVER}${businessLineIcon}`} />}</InputAdornment>
          ),
        }}
        required
        label={businessLineIdError ? 'Select a business line' : 'Business line *'}
        control={control}
        name="businessLineId"
        fullWidth
        options={allBusinessLines}
        getOptionLabel={getBusinessLineLabel}
        getOptionValue={getBusinessLineValue}
      />
      <HHFormSelectField
        required
        error={methodIdError}
        sx={{ mt: 2 }}
        deprecatedLabel={false}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{methodIcon && <Icon src={`${MEDIA_SERVER}Method/${methodIcon}`} />}</InputAdornment>
          ),
        }}
        label={methodIdError ? 'Select a method' : 'Method *'}
        control={control}
        name="methodId"
        fullWidth
        options={allMethods}
        getOptionLabel={getMethodLabel}
        getOptionValue={getMethodValue}
      />
      <HHFormSelectField
        required
        error={startingYardIdError}
        sx={{ mt: 2 }}
        deprecatedLabel={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Star fontSize="small" />
            </InputAdornment>
          ),
        }}
        label={startingYardIdError ? 'Select a yard' : 'Starting yard *'}
        control={control}
        fullWidth
        name="startingYardId"
        onChange={onStartingYardChange}
        options={allYards}
        SelectProps={{ MenuProps: { disableScrollLock: true, marginThreshold: 0 } }}
        getOptionLabel={getYardOptionLabel}
        getOptionValue={getYardOptionValue}
      />
      <HHFormSelectField
        required
        error={endingYardIdError}
        sx={{ mt: 2, mb: 2 }}
        deprecatedLabel={false}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small">
                <FlagCheckered />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        label={endingYardIdError ? 'Select a yard' : 'Ending yard *'}
        control={control}
        name="endingYardId"
        onChange={onEndingYardChange}
        fullWidth
        options={allYards}
        getOptionLabel={getYardOptionLabel}
        getOptionValue={getYardOptionValue}
      />
    </Stack>
  )
}

RouteDetailsFormContent.propTypes = {
  onStartingYardChange: PropTypes.func.isRequired,
  onEndingYardChange: PropTypes.func.isRequired,
}

export default RouteDetailsFormContent
