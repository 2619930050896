import React, { useState, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { setViewOnMapRouteId, selectRouteIds, setSelectedSearchResult } from 'slices/route/routeSlice'

import RouteFilterMenuOptions from '../filters/route-filter/RouteFilterMenuOptions'
import { generateFilterLabel } from '../filters/settings'

const RoutePickerFilterButton = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const viewOnMapRouteId = useSelector(s => get(s, 'Route.viewOnMap.routeId', null), shallowEqual)
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const selectedRouteIdList = viewOnMapRouteId ? [viewOnMapRouteId] : selectedRouteIds
  const allRoutes = useSelector(s => get(s, 'Route.allRoutes', []), shallowEqual)
  const selectedRouteIdListCount = selectedRouteIdList.length
  const [anchorEl, setAnchorEl] = useState(null)
  const allRoutesSelected = selectedRouteIdListCount === allRoutes.length && allRoutes.length > 0

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (routeIds = []) => {
    if (Array.isArray(routeIds)) {
      dispatch(selectRouteIds(routeIds))
      dispatch(setSelectedSearchResult(null))
    }
  }

  const handleSelectRouteChange = (event, routeId) => {
    dispatch(setViewOnMapRouteId(null))
    if (selectedRouteIdList.includes(routeId)) {
      handleChange(selectedRouteIdList.filter(f => f !== routeId))
    } else {
      handleChange([...selectedRouteIdList, routeId])
    }
  }

  const handleSelectAllFilters = () => {
    dispatch(setViewOnMapRouteId(null))
    if (allRoutesSelected) {
      handleChange([])
    } else {
      handleChange(allRoutes.map(({ id }) => id))
    }
  }

  const buttonLabel = useMemo(() => generateFilterLabel(allRoutes, selectedRouteIdList, 'route'), [allRoutes, selectedRouteIdList])

  return (
    <>
      <MenuItem variant="text" onClick={handleClick}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedRouteIdListCount} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 110 }}>{buttonLabel}</ListItemText>
      </MenuItem>

      <Menu
        disableScrollLock
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: theme.spacing(1) }}
      >
        <RouteFilterMenuOptions
          allRoutes={allRoutes}
          selectedRouteIds={selectedRouteIds}
          onSelectRouteChange={handleSelectRouteChange}
          onSelectAllFilters={handleSelectAllFilters}
        />
      </Menu>
    </>
  )
}

RoutePickerFilterButton.propTypes = {}

export default RoutePickerFilterButton
