import api from 'api'
import { CACHE_TAG_FOLLOW_UP_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerFollowUpList: build.query({
      providesTags: [CACHE_TAG_FOLLOW_UP_LIST],
      query: body => ({ url: '/api/account/follow-up', method: 'POST', body }),
    }),
  }),
})

export const { useLazyGetCustomerFollowUpListQuery } = extendedApi
