import React, { useCallback, useMemo } from 'react'
import { useTheme, Box } from '@mui/material'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, useGridApiRef } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetInvoiceGroupDetailsQuery } from 'api/groups/invoice/getInvoiceGroupDetails'
import InvoiceActivityFeedWrapper from './InvoiceActivityFeedWrapper'
import RenderInvoiceGroupWrapper from './RenderInvoiceGroupWrapper'

const InvoiceGroupList = () => {
  const theme = useTheme()
  const { id: groupId } = useParams()
  const { data, isLoading } = useGetInvoiceGroupDetailsQuery({ id: groupId })
  const invoices = get(data, 'invoices', [])
  const apiRef = useGridApiRef()

  const getDetaiilPanelContent = useCallback(({ row }) => {
    const { payments, accountCredits, accountId, invoiceNumber } = row

    return (
      <InvoiceActivityFeedWrapper payments={payments} accountCredits={accountCredits} accountId={accountId} invoiceNumber={invoiceNumber} />
    )
  }, [])

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ id }) => id, [])

  const getRowHeight = useCallback(({ id }) => {
    if (id === 'header') return 'auto'
    return null
  }, [])

  const getRowSpacing = useCallback(
    params => ({
      bottom: params.isLastVisible ? 128 : 0,
    }),
    []
  )

  const rows = useMemo(() => (isLoading ? [] : [{ id: 'header' }, ...invoices]), [isLoading, invoices])

  return (
    <Box display="flex" height="100%" overflow="auto">
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            slots={{ noRowsOverlay: () => null }}
            apiRef={apiRef}
            hideFooter
            rows={rows}
            rowHeight={119}
            getRowId={getRowId}
            getDetailPanelContent={getDetaiilPanelContent}
            getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
            getRowHeight={getRowHeight}
            getRowSpacing={getRowSpacing}
            columnVisibilityModel={{
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            }}
            columns={[
              {
                field: 'id',
                headerName: 'First name',
                flex: 1,
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: RenderInvoiceGroupWrapper,
              },
              {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                renderCell: () => <div />,
              },
            ]}
            columnHeaderHeight={0}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
                border: 0,
                padding: 0,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },

              '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
                backgroundColor: 'background.paper',
              },
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
              '& .WOReview-DataGridHeader': {
                width: 'calc(100% - 128px)',
                borderBottom: `1px solid ${theme.palette.border.light}`,
              },
              '& .MuiDataGrid-menuIcon': {
                visibility: 'visible',
                width: 'unset',
              },
            }}
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    </Box>
  )
}

export default InvoiceGroupList
