import React, { useReducer, useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import lowerCase from 'lodash/lowerCase'

import { useDispatch } from 'react-redux'

import { red, orange } from '@mui/material/colors'

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, Grid, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { handleTooltipOnLargeText } from 'utils/helper'
import { calculateDaysDiff } from 'utils/date'
import { getAddCallFollowUpPayload, addEditAdditionalNote, getAPIIdParam } from 'utils/form-body'
import { FOLLOW_UP_PAGINATION } from 'settings/constants/pagination'
import { addFollowUpAdditionalNotes } from 'middleware/actions/followUp'
import { useLazyGetCustomerFollowUpListQuery } from 'api/follow-up/getCustomerFollowUpList'
import { useAddUpdateCallFollowUpMutation } from 'api/calls/addUpdateCallFollowUp'
import { useLazyGetCustomerFollowUpByIdQuery } from 'api/follow-up/getCustomerFollowUpById'
import { CACHE_TAG_FOLLOW_UP_LIST } from 'api/cacheTagTypes'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'

import T from 'T'
import api from 'api'
import Loader from 'components/common/loader'
import HHAccordion from 'components/common/HHAccordion'
import AvatarSquare from 'components/user/AvatarSquare'
import TableSkelton from 'components/common/TableSkelton'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'

import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import CallsFollowUpDrawer from '../drawer/calls-followup'
import CustomerDetailsPaginations from './CustomerDetailsPaginations'
import AddCallsFollowupDialog from '../content/AddCallsFollowupDialog'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = FOLLOW_UP_PAGINATION

const FOLLOW_UP_TABLE_HEADER = [T.DATE, T.ASSIGNEE, T.CONTACT, T.SUBJECT, T.STATUS, T.DAYS]

const CONFIRM_TITLE = T.FOLLOWUP_CONFIRM_TITLE

const FollowUp = ({ accountId, locationId = '' }) => {
  const dispatch = useDispatch()
  const [getCustomerFollowUpList, { isFetching: isFollowUpLoading, data }] = useLazyGetCustomerFollowUpListQuery()
  const [addUpdateCallFollowUp, { isLoading: isUpdateFollowUpLoading }] = useAddUpdateCallFollowUpMutation()
  const [getCustomerFollowUpById, { isFetching: isCustomerFollowUpByIdLoading }] = useLazyGetCustomerFollowUpByIdQuery()

  const [followUpState, setFollowUpState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    // Drawer state
    isDirty: false,
    isOpenDrawer: false,
    selectedFollowUpId: '',
    singleFollow: null,
    isOpenConfirmModal: false,
  })

  const { page, rowsPerPage, isDirty, isOpenDrawer, selectedFollowUpId, singleFollow, isOpenConfirmModal } = followUpState
  const [isOpenAddFollowUpDialog, setIsOpenAddFollowUpDialog] = useState(false)

  const { calls: unsortedFollowUp = [], totalItems: totalFollowUps = 0, totalPages = 0 } = data || {}
  const followUp = sortByDateSelector({ data: unsortedFollowUp, key: 'createdAt' })

  const disabledFollowUpDrawerProceed = !get(singleFollow, 'subject', '').trim()

  const getFollowUp = (requestedPage, requestedPageSize) => {
    const payload = { ...getAPIIdParam(locationId, accountId), requestedPage, requestedPageSize }
    getCustomerFollowUpList(payload).unwrap().catch(handleError)
  }

  // Page change handler
  const handleFollowUpPageChange = newPage => {
    setFollowUpState({ page: newPage })
    getFollowUp(newPage, rowsPerPage)
  }

  // Rows per page change handler
  const handleFollowUpRowsPerPageChange = event => {
    setFollowUpState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
    getFollowUp(INITIAL_PAGE, event.target.value)
  }

  const resetFollowUpDrawer = () =>
    setFollowUpState({ singleFollow: null, selectedFollowUpId: '', isOpenConfirmModal: false, isOpenDrawer: false, isDirty: false })

  const handleNotesCalling = allChangedNotes => {
    const notePromises = allChangedNotes.map(note => {
      const payload = addEditAdditionalNote(selectedFollowUpId, note)
      return new Promise((resolve, reject) => {
        dispatch(
          addFollowUpAdditionalNotes(payload, res => {
            if (!res) {
              return reject()
            }
            return resolve()
          })
        )
      })
    })

    return Promise.all(notePromises)
  }

  const getAllChangedAdditionalNotes = () => get(singleFollow, 'followUpNotes', []).filter(note => get(note, 'isDirty'))

  const handleUpdateFollowUp = () => {
    if (!isDirty) {
      // No need to call API if no changes
      resetFollowUpDrawer()
      return
    }
    singleFollow.followUp = true
    const payload = getAddCallFollowUpPayload(singleFollow, accountId, locationId, selectedFollowUpId)

    addUpdateCallFollowUp(payload)
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_FOLLOW_UP_LIST]))
        resetFollowUpDrawer()
      })
      .catch(handleError)
  }

  const updateAdditionalNotes = () => {
    if (disabledFollowUpDrawerProceed) {
      return
    }

    const allChangedNotes = getAllChangedAdditionalNotes()

    if (!allChangedNotes.length) {
      handleUpdateFollowUp()
      return
    }

    handleNotesCalling(allChangedNotes).then(() => {
      handleUpdateFollowUp()
    })
  }

  const addAdditionalFollowUps = () => {
    const allNotes = get(singleFollow, 'followUpNotes', [])
    const isEmptyNotes = allNotes.find(followUpNote => get(followUpNote, 'note', '').trim() === '')

    if (!isEmptyNotes) {
      // add additional note if all notes are filled
      setFollowUpState({ singleFollow: { ...singleFollow, followUpNotes: [...allNotes, {}] } })
    }
  }

  const getFollowUpById = followUpId => {
    if (!followUpId) {
      return
    }

    getCustomerFollowUpById({ id: followUpId })
      .unwrap()
      .then(res => {
        const followUpDetails = get(res, 'followUp', {})
        const followUpData = {
          ...followUpDetails,
          assignee: {
            email: get(followUpDetails, 'assigneeEmail', ''),
            firstName: get(followUpDetails, 'assigneeFirstName', ''),
            lastName: get(followUpDetails, 'assigneeLastName', ''),
          },
        }

        if (followUpData) {
          const includeFollowUpKey = merge(followUpData, { followUp: true })
          let allFollowUpNotes = get(followUpData, 'followUpNotes', [])
          if (allFollowUpNotes.length === 0) {
            // attach notes and additional notes
            allFollowUpNotes = [{}, {}]
          }

          if (
            allFollowUpNotes.length === 1 ||
            allFollowUpNotes.filter(followUpNote => get(followUpNote, 'note', '').trim() === '').length === 0
          ) {
            // add additional note
            allFollowUpNotes = [...allFollowUpNotes, {}]
          }

          set(
            includeFollowUpKey,
            'followUpNotes',
            allFollowUpNotes.map(note => ({ ...note, image: get(note, 'noteImage.name', '') }))
          )
          setFollowUpState({
            isOpenDrawer: true,
            selectedFollowUpId: followUpId,
            singleFollow: includeFollowUpKey,
          })
        }
      })
      .catch(handleError)
  }

  const getStatusColor = status => {
    switch (lowerCase(status)) {
      case 'open':
        return red[900]
      case 'closed':
        return 'text.secondary'
      default:
        return orange[500]
    }
  }

  useEffect(() => {
    if (accountId || locationId) {
      getFollowUp(INITIAL_PAGE, rowsPerPage)
    }
  }, [accountId, locationId])

  useEffect(() => handleTooltipOnLargeText())

  return (
    <>
      {(isUpdateFollowUpLoading || isCustomerFollowUpByIdLoading) && <Loader />}

      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={T.FOLLOW_UP}
        actionType="add"
        onActionClick={() => setIsOpenAddFollowUpDialog(true)}
      >
        <Grid container>
          <Grid item xs={12}>
            {(!Array.isArray(followUp) || followUp.length === 0) && !isFollowUpLoading && <HHSectionPlaceholder title={T.NO_FOLLOW_UP} />}
            <Box display={!isFollowUpLoading && (!Array.isArray(followUp) || followUp.length === 0) && 'none'}>
              <TableContainer sx={{ paddingX: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {Children.toArray(
                        FOLLOW_UP_TABLE_HEADER.map((header, index) => (
                          <TableCell align={index === header.length + 1 ? 'right' : 'left'}>
                            <Typography variant="subtitle2">{header}</Typography>
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFollowUpLoading && <TableSkelton rows={3} columns={6} />}
                    {!isFollowUpLoading &&
                      Array.isArray(followUp) &&
                      followUp.length > 0 &&
                      Children.toArray(
                        followUp.map(singleFollowUp => {
                          const profilePicture = get(singleFollowUp, 'assignee.profilePicUri', '')
                          const firstName = get(singleFollowUp, 'assigneeFirstName', '')
                          const lastName = `${get(singleFollowUp, 'assigneeLastName', '')}`

                          const contactFirstName = get(singleFollowUp, 'contact.firstName', '')
                          const contactLastName = `${get(singleFollowUp, 'contact.lastName', '')}`
                          const contactFullName = `${contactFirstName} ${contactLastName}`.trim()

                          const followUpStatus = get(singleFollowUp, 'status', 'Open')
                          const followUpCreatedAt = get(singleFollowUp, 'createdAt', '')
                          const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: followUpCreatedAt })
                          const followSubject = get(singleFollowUp, 'subject', '')

                          const statusColor = getStatusColor(followUpStatus)
                          return (
                            <TableRow
                              hover
                              sx={{
                                cursor: 'pointer',
                                '& .MuiTableCell-root': {
                                  padding: '8px 16px',
                                },
                              }}
                              onClick={() => getFollowUpById(get(singleFollowUp, 'id'))}
                            >
                              <TableCell>
                                <Typography color="text.secondary" variant="body1" display="grid">
                                  {onlyDate}
                                  <small>{onlyTime}</small>
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <AvatarSquare firstName={firstName} lastName={lastName} src={profilePicture} />
                              </TableCell>
                              <TableCell>
                                <Typography noWrap fontWeight={500}>
                                  {contactFullName}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{followSubject}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={500} noWrap sx={{ color: statusColor }}>
                                  {followUpStatus}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="body1">{calculateDaysDiff(followUpCreatedAt)}</Typography>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          {totalFollowUps > ROWS_PER_PAGE && (
            <Grid item xs={12}>
              <CustomerDetailsPaginations
                withNewColors
                page={page}
                totalCount={totalFollowUps}
                rowsPerPage={rowsPerPage}
                handlePageChange={handleFollowUpPageChange}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                handleRowsPerPageChange={handleFollowUpRowsPerPageChange}
                totalPageCount={totalPages}
              />
            </Grid>
          )}
        </Grid>
      </HHAccordion>

      <CallsFollowUpDrawer
        isOpenDrawer={isOpenDrawer}
        details={singleFollow}
        onClose={isOpen => {
          if (isDirty || getAllChangedAdditionalNotes().length) {
            setFollowUpState({ isOpenConfirmModal: true })
            return
          }
          setFollowUpState({ isOpenDrawer: isOpen, selectedFollowUpId: '', singleFollow: null })
        }}
        onChange={(key, value, index = -1) => {
          if (index >= 0) {
            // Invalid case if array is not present
            // const updatedNotes = get(singleFollow, 'followUpNotes', []).map((note, noteIndex) =>
            //   noteIndex === index ? { ...note, isDirty: true, note: value } : note
            // );

            const cloneNotes = cloneDeep(get(singleFollow, 'followUpNotes', []))
            set(cloneNotes, `[${index}]`, { ...get(cloneNotes, `[${index}]`, {}), isDirty: true, [key]: value })

            setFollowUpState({ singleFollow: { ...singleFollow, followUpNotes: cloneNotes } })
            return
          }
          setFollowUpState({ singleFollow: { ...singleFollow, [key]: value }, isDirty: true })
        }}
        disabledProceed={disabledFollowUpDrawerProceed}
        onProceed={updateAdditionalNotes}
        isFollowUp
        addAdditionalFollowUps={addAdditionalFollowUps}
      />

      <ConfirmationModal
        className="customer-details-slideout-confirm"
        isOpen={isOpenConfirmModal}
        title={CONFIRM_TITLE}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={T.CLOSE}
        onCancel={() => {
          setFollowUpState({ isOpenConfirmModal: false })
        }}
        onProceed={() => {
          resetFollowUpDrawer()
        }}
      />

      {isOpenAddFollowUpDialog && (
        <AddCallsFollowupDialog
          isOpen={isOpenAddFollowUpDialog}
          isFollowUp
          locationId={locationId}
          accountId={accountId}
          onClose={() => setIsOpenAddFollowUpDialog(false)}
        />
      )}
    </>
  )
}

FollowUp.propTypes = {
  locationId: PropTypes.string,
  accountId: PropTypes.string.isRequired,
}

export default memo(FollowUp)
