import React, { useContext, createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from 'providers/firebaseAuth'

const AuthContext = createContext()

const AuthProvider = ({ children, extraContext = {} }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, user => {
      setCurrentUser(user)
      setIsInitialized(true)
    })

    return () => unregisterAuthObserver()
  }, [])

  return <AuthContext.Provider value={{ currentUser, isInitialized, ...extraContext }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  extraContext: PropTypes.object,
}

export default AuthProvider

export const useAuthValue = () => useContext(AuthContext)
