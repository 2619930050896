import React from 'react'
import { Tab, Icon, styled, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Constants from 'Constants'
import MenuToolTip from '../common/Tooltip'

const { MEDIA_SERVER } = Constants

const NavIcon = ({ icon, label }) => (
  <Icon
    sx={{
      display: 'flex',
      height: 'inherit',
      width: 'inherit',
      mr: 1,
    }}
  >
    <img edge="start" src={MEDIA_SERVER + icon} alt={label} />
  </Icon>
)

const StyledTab = styled(Tab)(({ theme, isActive }) => ({
  textTransform: 'capitalize',
  minHeight: '54px',
  color: theme.palette.background.paper,
  opacity: isActive ? 1 : 0.6,
  '&.Mui-selected': {
    color: theme.palette.background.paper,
    borderBottom: `2px solid ${theme.palette.background.paper}`,
    opacity: 1,
  },
  '& img': {
    filter: 'sepia(100%) saturate(0%) hue-rotate(184deg) brightness(215%) contrast(101%)',
  },
}))

const NavItem = ({ label, icon, to, isActive = false, index }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <MenuToolTip disableHoverListener={isDesktop} title={label}>
      <StyledTab
        isActive={isActive}
        iconPosition={!isTablet ? 'start' : 'top'}
        icon={<NavIcon icon={icon} isActive={isActive} />}
        component={Link}
        label={!isTablet && label}
        to={to}
        value={index}
      />
    </MenuToolTip>
  )
}

NavItem.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  index: PropTypes.number,
}

export default NavItem
