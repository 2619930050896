import api from 'api'
import { CACHE_TAG_ACCOUNT_LIST, CACHE_TAG_LATE_FEES } from 'api/cacheTagTypes'
import { transformSearchResponse } from 'utils/accountSearch'

const DEFAULT_CACHE_TIME = 3600

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    createLateFee: build.mutation({
      query: body => ({
        url: '/api/v1/core/fee/late',
        method: 'POST',
        body,
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    archiveLateFee: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/late/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    restoreLateFee: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/late/${id}/restore`,
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    fetchLateFees: build.query({
      query: body => ({
        url: `api/v1/core/fee/late`,
        method: 'GET',
        body,
      }),
      providesTags: [CACHE_TAG_LATE_FEES],
    }),
    fetchLateFeeDetails: build.query({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/late/${id}`,
      }),
      providesTags: [CACHE_TAG_LATE_FEES],
    }),
    updateLateFee: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/fee/late/${id}`,
        method: 'PUT',
        body: {
          ...rest,
        },
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    linkAccountsToLateFee: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/fee/late/${id}/link`,
        method: 'POST',
        body: {
          ...rest,
        },
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    unlinkAccountsToLateFee: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/fee/late/${id}/unlink`,
        method: 'POST',
        body: {
          ...rest,
        },
      }),
      invalidatesTags: [CACHE_TAG_LATE_FEES],
    }),
    fetchAccountsLinkedToLateFee: build.query({
      query: ({ id, includeTags = false, includeContacts = true, includeDeactivated = true }) => ({
        url: `/api/v1/core/fee/late/${id}/accounts`,
        params: {
          includeTags,
          includeContacts,
          includeDeactivated,
        },
      }),
      transformResponse: ({ accounts }) => transformSearchResponse({ results: accounts }),
      providesTags: [CACHE_TAG_ACCOUNT_LIST],
      keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
  }),
})

export const {
  useCreateLateFeeMutation,
  useLazyFetchLateFeesQuery,
  useArchiveLateFeeMutation,
  useRestoreLateFeeMutation,
  useLazyFetchLateFeeDetailsQuery,
  useUpdateLateFeeMutation,
  useLinkAccountsToLateFeeMutation,
  useUnlinkAccountsToLateFeeMutation,
  useLazyFetchAccountsLinkedToLateFeeQuery,
} = extendedApi
