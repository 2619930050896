import { deserializeDate, formatDateToBEFormatDateFns, formatDateToFEFormatDateFns } from 'utils/date'
import { useCallback } from 'react'

const useGetBeBatchesBetweenDaysVariant = ({ moveToRows, routeId, toSerializedDate }) => {
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  return useCallback(() => {
    let currentBatch = null
    let lastStop = null
    const batches = moveToRows.reduce((accum, currentRow) => {
      if (currentRow.date !== toFEDate) {
        if (!currentBatch) {
          currentBatch = {
            destinationRouteId: routeId,
            stops: [currentRow.id],
            serviceDate: formatDateToBEFormatDateFns(toDate),
            targetStopId: lastStop,
            updateMasterSequence: false,
          }
        } else {
          currentBatch.stops.push(currentRow.id)
        }
      } else {
        if (currentBatch) {
          accum.push(currentBatch)
          currentBatch = null
        }
        lastStop = currentRow.id
      }
      return accum
    }, [])
    if (currentBatch) {
      batches.push(currentBatch)
    }
    return batches
  }, [moveToRows])
}

useGetBeBatchesBetweenDaysVariant.propTypes = {}

export default useGetBeBatchesBetweenDaysVariant
