import React from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { Box, Typography, Link } from '@mui/material'
import { get } from 'utils/lodash'
import { getCustomerDetailsPageUrl } from 'router/routes'
import { combineAddressInfo } from 'utils/helper'

const RenderAccountCell = ({ row = {} }) => {
  const { accountId, accountName, accountNumber } = row

  const onLinkClick = e => e.stopPropagation()

  return (
    <Box py={0.5} overflow="hidden">
      <Typography variant="subtitle2" color="textPrimary">
        {accountName}
      </Typography>
      <Typography>
        <Link variant="caption" onClick={onLinkClick} component={RouterLink} to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`}>
          {accountNumber}
        </Link>
      </Typography>
      <Typography display="block" variant="caption" color="textPrimary" noWrap>
        {combineAddressInfo(get(row, 'accountAddress', ''))}
      </Typography>
    </Box>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountCell
