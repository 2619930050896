import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import isEqual from 'date-fns/isEqual'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Step,
  Stepper,
  StepLabel,
} from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { formatDateToBEFormatDateFns, createDateFromString } from 'utils/date'
import { usePostInvoiceBulkPreviewMutation } from 'api/billing/postBulkPreview'
import { useLazyGetAccountBillingPeriodsQuery } from 'api/accounts/getAccountBillingPeriods'
import { usePostBillingCreateInvoicesMutation } from 'api/billing/postBillingCreateInvoices'
import { setCreateInvoicesPreviewData } from 'slices/billing/createInvoicesSlice'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import CancelButton from 'components/buttons/CancelButton'
import Loader from 'components/common/loader'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

import capitalize from 'lodash/capitalize'
import PreviewCharges from './PreviewCharges'
import { useFetchChargeList } from '../../billing/create-invoices/PreviewChargeListDrawer/useFetchChargeList'
import { INVOICE_TYPE } from '../../../settings/constants/billing'

const FIRST_STEP_INDEX = 0

const STEPS = [
  { stepName: 'Select an invoice type', optionalText: 'Manual or recurring' },
  { stepName: 'Review', optionalText: 'Make sure your services\nand charges look good' },
]

const { RECURRING, MANUAL } = INVOICE_TYPE

const CreateInvoice = ({ isOpen = false, accountId, billingProfileDetails = {}, callback = noop, onClose }) => {
  const dispatch = useDispatch()
  const { onClose: onCloseFetchChargeList, loadMoreRows, isLoadingFirstBatch } = useFetchChargeList()
  const [getAccountBillingPeriods, { data: billingPeriods, isFetching: isLoading, isError }] = useLazyGetAccountBillingPeriodsQuery()
  const [postBillingCreateInvoices, { isLoading: isCreateInvoiceLoading }] = usePostBillingCreateInvoicesMutation()
  const [postInvoiceBulkPreviewMutation, { isLoading: isPreviewInvoiceLoading }] = usePostInvoiceBulkPreviewMutation()

  const allBillingPeriods = getBillingPeriods({
    billingProfile: billingProfileDetails,
    billingPeriods: isError ? [] : get(billingPeriods, 'accountInvoiceBillingPeriods', []).filter(invoice => !invoice.invoiceId),
  })

  const [activeStep, setActiveStep] = useState(FIRST_STEP_INDEX)
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [selectedPeriod, setSelectedPeriod] = useState({ invoiceDate: '', periodName: '', startDate: '' })
  const [type, setType] = useState(RECURRING)
  const isRecurringInvoice = type === RECURRING
  const isFirstStep = activeStep === FIRST_STEP_INDEX
  const nextButtonLabel = !isFirstStep ? T.CREATE : T.NEXT
  const selectedPeriodDate = selectedPeriod.invoiceDate
  const finalDate = isEqual(invoiceDate, createDateFromString(selectedPeriodDate))
    ? selectedPeriodDate
    : formatDateToBEFormatDateFns(invoiceDate)
  const isSubmitBtnDisabled = (!selectedPeriodDate && type === RECURRING) || !invoiceDate

  const handleInvoiceTypeChange = e => setType(e.target.value)
  const handleOpenChargesDrawer = () => {
    postInvoiceBulkPreviewMutation({
      accountIds: [accountId],
      billingPeriodStartDate: selectedPeriod.startDate,
      invoiceType: type,
      invoiceDate: finalDate,
    })
      .unwrap()
      .then(data => {
        dispatch(setCreateInvoicesPreviewData({ ...data, remoteRowCount: 1, prefilledDocIdList: [accountId] }))
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      })
      .catch(handleError)
  }

  const handleCreateInvoice = () => {
    const payload = {
      ...(type === RECURRING && { billingProfileId: get(billingProfileDetails, 'id'), invoicePeriodStartDate: selectedPeriodDate }),
      accountIds: [accountId],
      invoiceDate: finalDate,
      invoiceType: type,
    }

    postBillingCreateInvoices(payload)
      .unwrap()
      .then(() => {
        onClose()
        toast.success(T.INVOICE_IS_GENERATING, {
          hideProgressBar: false,
          autoClose: 4000,
        })
        callback()
      })
      .catch(handleError)
  }

  const handleNext = () => {
    if (isFirstStep) {
      handleOpenChargesDrawer()
      return
    }
    handleCreateInvoice()
  }

  const handleBack = () => {
    if (!isFirstStep) {
      onCloseFetchChargeList()
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(FIRST_STEP_INDEX)
    onCloseFetchChargeList()
    onClose()
  }

  const handleBillingPeriodChange = event => {
    const { value } = event.target
    const periodDetails = get(billingPeriods, 'accountInvoiceBillingPeriods', []).find(bp => bp.invoiceDate === value) || {}
    const periodName = allBillingPeriods.find(bp => bp.invoiceDate === value) || {}
    setSelectedPeriod({ invoiceDate: value, periodName: get(periodName, 'labelLeft'), startDate: get(periodDetails, 'startDate') })

    if (value) {
      setInvoiceDate(createDateFromString(value))
    }
  }

  useEffect(() => {
    if (accountId) {
      getAccountBillingPeriods({ accountId })
        .unwrap()
        .catch(error => {
          handleError(error)
          onClose()
        })
    }
  }, [accountId])

  return (
    <>
      {(isLoading || isCreateInvoiceLoading || isPreviewInvoiceLoading) && <Loader />}
      <Dialog open={isOpen} maxWidth="lg" fullWidth={!isFirstStep}>
        <DialogTitle>
          <Typography variant="h4">Create invoice</Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 0, maxHeight: 'calc(100vh - 270px)' }}>
          <Box px={2} pb={2} sx={{ borderBottom: '1px dashed', borderColor: 'text.secondary' }}>
            <Stepper
              sx={{
                width: isFirstStep ? 'auto' : '500px',
                margin: isFirstStep ? 'auto' : '0 auto',
                '.MuiStepConnector-line': {
                  borderTopStyle: 'dashed',
                  borderColor: isFirstStep ? 'text.secondary' : 'primary.main',
                },
              }}
              activeStep={activeStep}
            >
              {STEPS.map((label, index) => {
                const labelProps = {}
                const sx = index === 1 ? { '.MuiStepLabel-labelContainer': { position: 'relative', top: 10 } } : {}
                labelProps.optional = (
                  <Typography whiteSpace="pre-line" variant="caption">
                    {label.optionalText}
                  </Typography>
                )

                return (
                  <Step key={label.stepName}>
                    <StepLabel sx={sx} {...labelProps}>
                      {label.stepName}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>

          <DialogContentText>
            <Box mt={2} px={3}>
              {isFirstStep && (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <FormControl>
                      <Typography variant="caption">Invoice type</Typography>

                      <RadioGroup row value={type} onChange={handleInvoiceTypeChange}>
                        <FormControlLabel
                          value={MANUAL}
                          control={<Radio size="small" />}
                          label={<Typography color="textPrimary">{T.MANUAL}</Typography>}
                        />
                        <FormControlLabel
                          value={RECURRING}
                          control={<Radio size="small" />}
                          label={<Typography color="textPrimary">{T.RECURRING}</Typography>}
                        />
                      </RadioGroup>
                    </FormControl>

                    <HHDesktopDatePicker
                      label="Invoice date"
                      value={invoiceDate}
                      onChange={date => setInvoiceDate(date)}
                      TextFieldProps={{ deprecatedLabel: false }}
                    />
                  </Box>

                  {isRecurringInvoice && (
                    <HHTextField
                      sx={{
                        mt: 2,
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        },
                      }}
                      deprecatedLabel={false}
                      label={T.BILLING_PERIOD}
                      fullWidth
                      select
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      value={[selectedPeriodDate]}
                      onChange={handleBillingPeriodChange}
                    >
                      <SelectItem value="">Select a period</SelectItem>
                      {allBillingPeriods.map(val => {
                        return (
                          <SelectItem value={val.invoiceDate}>
                            <Typography fontWeight={600} noWrap>
                              {val.labelLeft}
                            </Typography>
                            <Typography noWrap>{val.labelRight}</Typography>
                          </SelectItem>
                        )
                      })}
                    </HHTextField>
                  )}
                </>
              )}

              {!isFirstStep && (
                <PreviewCharges
                  type={type}
                  selectedPeriod={selectedPeriod}
                  invoiceDate={finalDate}
                  isLoadingFirstBatch={isLoadingFirstBatch}
                  loadMoreRows={loadMoreRows}
                />
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          {!isFirstStep && (
            <Button size="small" variant="outlined" onClick={handleBack} sx={{ mr: 1 }}>
              Go back
            </Button>
          )}

          <Box sx={{ flex: '1 1 auto' }} />

          <CancelButton onClick={handleReset} sx={{ mr: 1 }} />
          <Button sx={{ ml: 1 }} size="small" variant="contained" disabled={isSubmitBtnDisabled} onClick={handleNext}>
            {capitalize(nextButtonLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CreateInvoice.propTypes = {
  isOpen: PropTypes.bool,
  billingProfileDetails: PropTypes.object,
  callback: PropTypes.func,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CreateInvoice
