/* eslint-disable no-param-reassign */
import { get } from 'lodash'
import { createBulkActionSlice } from 'slices/generic/createBulkActionSlice'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const bulkMoveBetweenDaysSlice = createBulkActionSlice({
  name: 'bulkMoveBetweenDays',
})
export const {
  setBulkState: setBulkMoveBetweenDaysState,
  addSelectedMoveToRows,
  addSelectedMoveFromRows,
  setSelectedMoveFromRows,
  setSelectedMoveToRows,
} = bulkMoveBetweenDaysSlice.actions

export const selectBulkMoveFocusName = state => get(state, 'bulkMoveBetweenDays.focus.name')
export const selectBulkMoveFocusRowIndex = state => get(state, 'bulkMoveBetweenDays.focus.rowIndex', 0)
export const selectBulkMoveFromRouteSequenceLine = state => get(state, 'bulkMoveBetweenDays.moveFromRouteSequenceLine', {})
export const selectBulkMoveFromRouteStopMarkers = state => get(state, 'bulkMoveBetweenDays.moveFromRouteStopMarkers', {})
export const selectBulkMoveFromRows = state => get(state, 'bulkMoveBetweenDays.moveFromRows', [])
export const selectBulkMoveInvalidStopsToBeAssignedCount = state => get(state, 'bulkMoveBetweenDays.invalidStopsToBeAssignedCount', 0)
export const selectBulkMoveValidStopIdsToBeAssigned = state => get(state, 'bulkMoveBetweenDays.validStopIdsToBeAssigned', [])
export const selectBulkMoveMapLayer = state => get(state, 'bulkMoveBetweenDays.mapLayer', FROM_ROUTE_LAYER)
export const selectBulkMoveStopsDate = state => get(state, 'bulkMoveBetweenDays.serializedServiceDate')
export const selectBulkMoveStopsToBeAssignedCount = state => get(state, 'bulkMoveBetweenDays.stopsToBeAssignedCount', 0)
export const selectBulkMoveToRouteSequenceLine = state => get(state, 'bulkMoveBetweenDays.moveToRouteSequenceLine', {})
export const selectBulkMoveToRouteStopMarkers = state => get(state, 'bulkMoveBetweenDays.moveToRouteStopMarkers', {})
export const selectBulkMoveToRows = state => get(state, 'bulkMoveBetweenDays.moveToRows', [])
export const selectBulkSelectedMoveFromRows = state => get(state, 'bulkMoveBetweenDays.selectedMoveFromRows', [])
export const selectBulkSelectedMoveToRows = state => get(state, 'bulkMoveBetweenDays.selectedMoveToRows', [])
export const selectIsOpenBulkMoveConfirmCloseDialog = state => get(state, 'bulkMoveBetweenDays.isConfirmCloseDialogOpen', false)
export const selectIsOpenBulkMoveBetweenDaysStopsDialog = state => get(state, 'bulkMoveBetweenDays.isOpen', false)
export const selectBulkMoveRoutesById = state => get(state, 'bulkMoveBetweenDays.routesById', {})

export const selectIsSelectDateDialogOpen = state => get(state, 'bulkMoveBetweenDays.isSelectDateDialogOpen', false)
export const selectIsSequenceDialogOpen = state => get(state, 'bulkMoveBetweenDays.isSequenceDialogOpen', false)
export const selectBulkMoveFitBounds = state => get(state, 'bulkMoveBetweenDays.fitBounds', false)
export const selectBulkMoveToSerializedDate = state => get(state, 'bulkMoveBetweenDays.toSerializedDate', false)
export const selectBulkMoveFromSerializedDate = state => get(state, 'bulkMoveBetweenDays.fromSerializedDate', false)
export const selectBulkMoveRouteId = state => get(state, 'bulkMoveBetweenDays.routeId')
export const selectBulkMoveRoutes = state => get(state, 'bulkMoveBetweenDays.routes', [])
export const selectBulkMoveFetchId = state => get(state, 'bulkMoveBetweenDays.fetchId')
export const selectToBeInsertedRowsIds = state => get(state, 'bulkMoveBetweenDays.toBeInsertedRowsIds', [])

export default bulkMoveBetweenDaysSlice.reducer
