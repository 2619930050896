export const actions = {
  GET: {
    GLOBAL_SEARCH_RESULT: 'GLOBAL_SEARCH_GET_RESULT',
    GLOBAL_SEARCH_FILTER: 'GLOBAL_SEARCH_GET_FILTER',
    GLOBAL_FILTER_OPTIONS: 'GLOBAL_FILTER_GET_OPTIONS',
    GLOBAL_FILTERED_RESULT_OPTIONS: 'GLOBAL_FILTERED_RESULT_GET_OPTIONS',
    SEARCH_SUGGEST_RESULT: 'SEARCH_SUGGEST_GET_RESULT',
    SUGGEST_CONTACT_LIST: 'SUGGEST_CONTACT_GET_LIST',
    SUGGEST_PRICED_SERVICE_LIST: 'SUGGEST_PRICED_SERVICE_GET_LIST',
    SUGGEST_CONFIGURED_SERVICE_LIST: 'SUGGEST_CONFIGURED_SERVICE_GET_LIST',
    SUGGEST_NOTE_LIST: 'SUGGEST_NOTE_GET_LIST',
    SUGGEST_WORK_ORDER_LIST: 'SUGGEST_WORK_ORDER_GET_LIST',
    SUGGEST_FOLLOW_UP_LIST: 'SUGGEST_FOLLOW_UP_GET_LIST',
  },
  PUT: {
    GLOBAL_SEARCH_RESULT: 'GLOBAL_SEARCH_PUT_RESULT',
    GLOBAL_SEARCH_FILTER: 'GLOBAL_SEARCH_PUT_FILTER',
    GLOBAL_FILTER_OPTIONS: 'GLOBAL_FILTER_PUT_OPTIONS',
    GLOBAL_FILTERED_RESULT_OPTIONS: 'GLOBAL_FILTERED_RESULT_PUT_OPTIONS',
    SEARCH_SUGGEST_RESULT: 'SEARCH_SUGGEST_PUT_RESULT',
    SUGGEST_CONTACT_LIST: 'SUGGEST_CONTACT_PUT_LIST',
    SUGGEST_PRICED_SERVICE_LIST: 'SUGGEST_PRICED_SERVICE_PUT_LIST',
    SUGGEST_CONFIGURED_SERVICE_LIST: 'SUGGEST_CONFIGURED_SERVICE_PUT_LIST',
    SUGGEST_NOTE_LIST: 'SUGGEST_NOTE_PUT_LIST',
    SUGGEST_WORK_ORDER_LIST: 'SUGGEST_WORK_ORDER_PUT_LIST',
    SUGGEST_FOLLOW_UP_LIST: 'SUGGEST_FOLLOW_UP_PUT_LIST',
  },
}

export const getGlobalSearchResult = (body, onSuccess) => ({
  type: actions.GET.GLOBAL_SEARCH_RESULT,
  payload: { body, onSuccess },
})

export const putGlobalSearchResult = res => ({
  type: actions.PUT.GLOBAL_SEARCH_RESULT,
  payload: res,
})

export const getGlobalSearchFilter = (body, onSuccess) => ({
  type: actions.GET.GLOBAL_SEARCH_FILTER,
  payload: { body, onSuccess },
})

export const putGlobalSearchFilter = res => ({
  type: actions.PUT.GLOBAL_SEARCH_FILTER,
  payload: res,
})

export const getGlobalFilterOptions = (body, onSuccess = null) => ({
  type: actions.GET.GLOBAL_FILTER_OPTIONS,
  payload: { body, onSuccess },
})

export const putGlobalFilterOptions = res => ({
  type: actions.PUT.GLOBAL_FILTER_OPTIONS,
  payload: res,
})

export const getSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SEARCH_SUGGEST_RESULT,
  payload: { body, onSuccess },
})

export const putSearchSuggestionList = res => ({
  type: actions.PUT.SEARCH_SUGGEST_RESULT,
  payload: res,
})

export const getContactSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_CONTACT_LIST,
  payload: { body, onSuccess },
})

export const getPricedServiceSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_PRICED_SERVICE_LIST,
  payload: { body, onSuccess },
})

export const getConfiguredServiceSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_CONFIGURED_SERVICE_LIST,
  payload: { body, onSuccess },
})

export const getNoteSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_NOTE_LIST,
  payload: { body, onSuccess },
})

export const getFollowUpSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_FOLLOW_UP_LIST,
  payload: { body, onSuccess },
})

export const getWorkOrderSearchSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_WORK_ORDER_LIST,
  payload: { body, onSuccess },
})

export const putContactSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_CONTACT_LIST,
  payload: res,
})

export const putPricedServiceSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_PRICED_SERVICE_LIST,
  payload: res,
})

export const putConfiguredServiceSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_CONFIGURED_SERVICE_LIST,
  payload: res,
})

export const putNoteSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_NOTE_LIST,
  payload: res,
})

export const putFollowUpSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_FOLLOW_UP_LIST,
  payload: res,
})

export const putWorkOrderSearchSuggestionList = res => ({
  type: actions.PUT.SUGGEST_WORK_ORDER_LIST,
  payload: res,
})
