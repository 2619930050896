export const actions = {
  UPDATE_WORK_ORDER_NOTE: 'WORK_ORDER_UPDATE_NOTE',
  UPDATE_EXCEPTION_NOTE: 'WORK_ORDER_UPDATE_EXCEPTION_NOTE',
  UPDATE_TAG: 'WORK_ORDER_UPDATE_TAG',
  GET: {
    WORK_ORDERS: 'CUSTOMERS_GET_WORK_ORDERS',
    WORK_ORDER_DETAILS: 'CUSTOMERS_GET_WORK_ORDER_DETAILS',
    WORK_ORDER_ID_DETAILS: 'GET_WORK_ORDER_DETAILS',
  },
  PUT: {
    WORK_ORDER_DETAILS: 'CUSTOMERS_PUT_WORK_ORDER_DETAILS',
  },
}

export const putCustomerWorkOrderDetails = res => ({
  type: actions.PUT.WORK_ORDER_DETAILS,
  payload: res,
})

export const getCustomerWorkOrders = (body, onSuccess) => ({
  type: actions.GET.WORK_ORDERS,
  payload: { body, onSuccess },
})

export const getCustomerWorkOrderDetails = (body, onSuccess) => ({
  type: actions.GET.WORK_ORDER_DETAILS,
  payload: { body, onSuccess },
})

export const getWorkOrderDetails = (body, onSuccess) => ({
  type: actions.GET.WORK_ORDER_ID_DETAILS,
  payload: { body, onSuccess },
})

export const updateWorkOrderExceptionNote = (body, onSuccess) => ({
  type: actions.UPDATE_EXCEPTION_NOTE,
  payload: { body, onSuccess },
})

export const updateWorkOrderTag = (body, onSuccess) => ({
  type: actions.UPDATE_TAG,
  payload: { body, onSuccess },
})
