import React from 'react'
import PropTypes from 'prop-types'

import { NumericFormat } from 'react-number-format'

import AutoComplete from 'components/common/AutoComplete'
import RecurrenceNumberTool from 'components/pricing/RecurrenceNumberTool'
import MinimumBillingNumberTool from 'components/pricing/MinimumBillingNumberTool'

import PricingSwitch from 'components/pricing/PricingSwitch'

import MeasureMaterialAutoComplete from 'components/pricing/MeasureMaterialAutoComplete'
import MethodDaysAutoComplete from 'components/pricing/MethodDaysAutoComplete'
import ActionExceptionAutoComplete from 'components/pricing/ActionExceptionAutoComplete'
import PrizingZoneAutoComplete from 'components/pricing/PrizingZoneAutoComplete'
import PricingPeriodAutoComplete from 'components/pricing/PricingPeriodAutoComplete'
import GeneralLedgerAutoComplete from 'components/pricing/GeneralLedgerAutoComplete'

import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'
import { getRecurrenceLabel } from 'components/pricing/pricingMethods'

import { get } from 'utils/lodash'

import T from 'T'

import Constants from 'Constants'
import { Button, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { HHTextField } from 'components/form-fields/v5'

const { MEDIA_SERVER } = Constants

const GeneralFeesFormScreen = ({
  tabValue,
  matchedServices,
  globalFilterOptions,
  handleCheckboxValue,
  checkedValues,
  recurCounter,
  onValueTypeChange,
  counter,
  setCount,
  setRecCount,
  feeInput,
  handlePriceLocked,
  handleSwitchVal,
  onHandleChange,
  inputFeeText,
  backFlag,
  handleLinkedServices,
  handleAutoLinkedServices,
}) => {
  const getFeeName = () => {
    return inputFeeText.feeName
  }

  const getPriceValue = () => {
    return get(inputFeeText, 'value', '')
  }

  const {
    checkedFeeMethods,
    checkedFeeMaterials,
    checkedFeeMeasure,
    checkedFeePricingPeriod,
    checkedFeePricingZones,
    checkedFeeRecurrence,
    checkedFeeServiceAction,
    checkedFeeDays,
    checkedGeneralLedger,
  } = (() => {
    return {
      checkedFeeMethods: get(checkedValues, 'methods', []),
      checkedFeeMaterials: get(checkedValues, 'materials', []),
      checkedFeeMeasure: get(checkedValues, 'measures', []),
      checkedFeePricingPeriod: get(checkedValues, 'pricingPeriod', []),
      checkedFeePricingZones: get(checkedValues, 'pricingZones', []),
      checkedFeeRecurrence: get(checkedValues, 'recurrence', []),
      checkedFeeServiceAction: get(checkedValues, 'actions', []),
      checkedFeeDays: get(checkedValues, 'specificDays', []),
      checkedGeneralLedger: get(checkedValues, 'generalLedgerAccount', []),
    }
  })()

  return (
    <Stack flexDirection="row" className="grid-container new-fee-form">
      <div className="left-section">
        <ActionExceptionAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedServiceAction={checkedFeeServiceAction}
          backFlag={backFlag}
        />

        <MethodDaysAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMethods={checkedFeeMethods}
          checkedDays={checkedFeeDays}
          backFlag={backFlag}
        />

        {tabValue === 'recurring' && (
          <div className="min-billing-period">
            <div className="switch-section">
              <div className="switch">
                <PricingSwitch
                  checked={inputFeeText.minBillingSwitchVal}
                  handleSwitchVal={() => handleSwitchVal('billingPeriod', !inputFeeText.minBillingSwitchVal)}
                />
              </div>
              <div className="switch-label">Min. Billing Periods</div>
            </div>

            {inputFeeText.minBillingSwitchVal && (
              <MinimumBillingNumberTool counter={counter} onHandleChange={onHandleChange} setCount={setCount} />
            )}
          </div>
        )}

        <div className="value left-elements-width mt15">
          <div className="service-label">Value</div>

          <div className="price-fields">
            <Button
              variant="outlined"
              className={`dollar-btn ${inputFeeText.valueType === '$' ? 'value-type-active' : ''}`}
              onClick={() => onValueTypeChange('$')}
            >
              $
            </Button>

            <Button
              className={`percent-btn ml10 ${inputFeeText.valueType === '%' ? 'value-type-active' : ''}`}
              onClick={() => onValueTypeChange('%')}
            >
              %
            </Button>

            <NumericFormat
              id="outlined-size-small"
              className="normal-textfield service-text-field price-field ml10"
              placeholder="0.00"
              value={getPriceValue()}
              variant="outlined"
              autoComplete="off"
              name="value"
              defaultSize
              onChange={e => onHandleChange(e)}
              customInput={HHTextField}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
            />
          </div>
        </div>

        <PricingPeriodAutoComplete
          globalFilterOptions={globalFilterOptions}
          handleCheckboxValue={handleCheckboxValue}
          checkedPricingPeriod={checkedFeePricingPeriod}
          backFlag={backFlag}
        />

        <div className="unlocked-price left-elements-width" onClick={() => handlePriceLocked()}>
          <div className="lock-img">
            <img src={!inputFeeText.priceLocked ? `${MEDIA_SERVER}Unlock.svg` : `${MEDIA_SERVER}Locked.svg`} alt="lock" height="20" />
          </div>

          <div className="unlock-label">{T.UNLOCKED_PRICE}</div>
        </div>
      </div>

      <div className="vertical-line" />

      <Box ml={2} className="right-section">
        <div className="service">
          <div className="service-label">
            {T.FEE}
            <span className="red ml1">*</span>
          </div>
          <HHTextField
            defaultSize
            placeholder={T.FEE}
            value={getFeeName()}
            autoFocus
            name="feeName"
            variant="outlined"
            autoComplete="off"
            className="normal-textfield service-text-field"
            InputProps={{
              onChange: e => onHandleChange(e, true),
            }}
          />
        </div>

        <div className="sub-item">
          <div className="switch-section">
            <div className="switch">
              {/* we will update this later */}
              <PricingSwitch checked={inputFeeText.subItem} handleSwitchVal={() => handleSwitchVal('subItem', !inputFeeText.subItem)} />
            </div>
            <div className="switch-label">Sub-Item</div>
          </div>
        </div>

        <PrizingZoneAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedPricingZones={checkedFeePricingZones}
          backFlag={backFlag}
        />

        {tabValue === 'recurring' && (
          <div className="recurrence left-elements-width mt15">
            <div className="service-label">{T.RECURRENCE}</div>

            <div className="recur-block general-fee">
              <RecurrenceNumberTool recurCounter={recurCounter} onHandleChange={onHandleChange} setRecCount={setRecCount} />

              <div className="auto-complete ml10">
                <AutoComplete
                  backFlag={backFlag}
                  maxHeight={180}
                  isHandleCheckbox
                  metaData={get(globalFilterOptions, 'recurrence', [])}
                  label={T.RECURRENCE}
                  getByLabelText={option => getRecurrenceLabel(option)}
                  handleCheckboxValue={handleCheckboxValue}
                  checkedRecords={checkedFeeRecurrence}
                  getPlaceholder={checkedFeeRecurrence.length ? '' : T.RECURRENCE}
                />
              </div>
            </div>
          </div>
        )}

        <MeasureMaterialAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMeasure={checkedFeeMeasure}
          checkedMaterials={checkedFeeMaterials}
          backFlag={backFlag}
        />

        <GeneralLedgerAutoComplete checkedValues={checkedGeneralLedger} handleCheckboxValue={handleCheckboxValue} backFlag={backFlag} />

        <div className="auto-link">
          <div className="switch-section">
            <div className="switch">
              <PricingSwitch
                className={`${inputFeeText.subItem ? '' : 'disable-switch'}`}
                checked={inputFeeText.autoLink}
                handleSwitchVal={() => handleSwitchVal('autoLink', !inputFeeText.autoLink)}
              />
            </div>
            <div className="switch-label">Auto-Link to Matched Services </div>
          </div>
        </div>

        <div className="linked-service-block" onClick={() => (inputFeeText.autoLink ? handleAutoLinkedServices() : '')}>
          <div className="title">{inputFeeText.autoLink ? matchedServices.length : 0} Services Linked Automatically</div>
          <div className="sub-title">Click here to View</div>
        </div>
      </Box>
    </Stack>
  )
}

GeneralFeesFormScreen.defaultProps = {
  tabValue: 'recurring',
  globalFilterOptions: {},
  feeInput: {},
  matchedServices: {},
  inputFeeText: {},
  checkedValues: {},
  counter: 1,
  recurCounter: 1,
  backFlag: false,
  handleCheckboxValue: null,
  onValueTypeChange: null,
  handleSwitchVal: null,
  setCount: null,
  setRecCount: null,
  handlePriceLocked: null,
  handleLinkedServices: null,
  onHandleChange: null,
  handleAutoLinkedServices: null,
}

GeneralFeesFormScreen.propTypes = {
  tabValue: PropTypes.string,
  globalFilterOptions: PropTypes.object,
  feeInput: PropTypes.object,
  matchedServices: PropTypes.object,
  inputFeeText: PropTypes.object,
  checkedValues: PropTypes.object,
  counter: PropTypes.number,
  recurCounter: PropTypes.number,
  backFlag: PropTypes.bool,
  handleCheckboxValue: PropTypes.func,
  onValueTypeChange: PropTypes.func,
  handleSwitchVal: PropTypes.func,
  setCount: PropTypes.func,
  setRecCount: PropTypes.func,
  handlePriceLocked: PropTypes.func,
  handleLinkedServices: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleAutoLinkedServices: PropTypes.func,
}

export default GeneralFeesFormScreen
