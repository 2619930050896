import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getDisposalSiteById: build.query({
      query: ({ id }) => ({
        url: '/api/settings/disposal/site/info',
        method: 'POST',
        body: { id },
      }),
    }),
  }),
})

export const { useLazyGetDisposalSiteByIdQuery } = extendedApi
