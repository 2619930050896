import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Divider } from '@mui/material'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { getNoteUserInfo } from 'data/notes/notesSelectors'
import { get } from 'utils/lodash'

const Notes = ({ notes = [], Icon, label = '' }) => {
  return (
    <Grid container mt={1}>
      <Grid item xs={12} display="flex" alignItems="center" gap={0.5}>
        <Icon />
        <Typography variant="caption">{label}</Typography>
      </Grid>
      {Children.toArray(
        notes.map(note => {
          const { firstName, lastName, username, dateTime } = getNoteUserInfo({ note })
          const fullName = `${firstName} ${lastName}`.trim()
          const displayName = fullName || username || '--'
          const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime })

          return (
            <>
              <Grid item container>
                <Grid item xs>
                  <Typography variant="caption" color="textPrimary">
                    {get(note, 'note', '')}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography variant="caption">{`Updated by ${displayName} | ${onlyDate} @ ${onlyTime}`}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ width: '100%' }} />
            </>
          )
        })
      )}
    </Grid>
  )
}

Notes.propTypes = {
  notes: PropTypes.array,
  Icon: PropTypes.node,
  label: PropTypes.string,
}

export default Notes
