import React from 'react'
import PropTypes from 'prop-types'
import { Chip, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'

const SkeletonHoldListItem = ({ divider = false }) => {
  return (
    <ListItem divider={divider}>
      <ListItemIcon sx={{ mr: 1 }}>
        <Skeleton width="3rem">
          <Chip size="small" />
        </Skeleton>
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton variant="text" width="5rem" />}
        secondary={<Skeleton variant="text" width="8rem" />}
        primaryTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        secondaryTypographyProps={{ variant: 'body1' }}
      />
    </ListItem>
  )
}

SkeletonHoldListItem.propTypes = {
  divider: PropTypes.bool,
}

export default SkeletonHoldListItem
