import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material'
import { capitalize, get, uniqBy } from 'lodash'
import { Mail } from '@styled-icons/heroicons-outline'
import { shallowEqual, useSelector } from 'react-redux'
import T from '../../../../T'

const filterContactsWithEmail = contacts => {
  return contacts
    .filter(contact => {
      const contactMethods = get(contact, 'contactMethods', [])
      return contactMethods && contactMethods.length > 0
    })
    .map(contact => {
      const { firstName, lastName, id } = contact
      const emailMethod = contact.contactMethods.find(method => {
        const methodType = get(method, 'methodType')
        return methodType === 'Email'
      })
      if (emailMethod) {
        const { methodValue } = emailMethod
        return { id, firstName, lastName, email: methodValue }
      }
      return null
    })
    .filter(Boolean)
}

const ContactEmailList = () => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)

  const accountContacts = get(customerDetails, 'contacts', [])
  const locationContacts = get(customerDetails, 'locations', []).map(location => get(location, 'contacts', []))
  const mergeContacts = [...accountContacts, ...locationContacts.flat()]
  const uniqueContacts = uniqBy(mergeContacts, contact => contact.id)
  const contactsWithEmail = filterContactsWithEmail(uniqueContacts)
  return (
    <>
      {contactsWithEmail.length > 0 && (
        <Box sx={{ px: 2, pt: 2 }}>
          <Typography variant="h5" color="textSecondary" fontWeight={500}>
            {T.CONTACT_EMAILS}
          </Typography>
          <List>
            {contactsWithEmail.map(contact => {
              const firstName = get(contact, 'firstName')
              const lastName = get(contact, 'lastName')
              const email = get(contact, 'email')
              const fullName = [firstName, lastName].join(' ')
              return (
                <ListItem disableGutters>
                  <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                    <SvgIcon>
                      <Mail />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={capitalize(email)}
                    primaryTypographyProps={{ variant: 'h5', fontWeight: 400 }}
                    secondary={fullName}
                    secondaryTypographyProps={{ color: 'textSecondary' }}
                  />
                </ListItem>
              )
            })}
          </List>
        </Box>
      )}
    </>
  )
}

ContactEmailList.propTypes = {}

export default ContactEmailList
