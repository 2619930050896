import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'
import { ListItem, ListItemText, Collapse, List, IconButton, Divider, Box, useTheme } from '@mui/material'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import CheckIcon from '@mui/icons-material/Check'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'

import InvoiceSummary from './InvoiceSummary'

const ExpandView = ({ index = 0, batch = [], isExpanded = false, onExpandChange, onPrint }) => {
  const theme = useTheme()
  const batchPrintCompleteIds = useSelector(state => get(state, 'InvoicePrintableView.batchDialog.batchPrintCompleteIds', []), shallowEqual)
  const selectedRowsById = useSelector(state => get(state, 'InvoicePrintableView.selectedRowsById', {}), shallowEqual)

  const invoicesCount = batch.length
  const isAlreadyPrinted = batchPrintCompleteIds.includes(index)

  const handleExpandCollapseClick = () => onExpandChange(!isExpanded)

  return (
    <>
      <ListItem
        sx={{ pl: 0 }}
        secondaryAction={
          <Box display="flex" alignItems="center">
            <IconButton sx={{ borderRadius: 0, border: '1px solid', borderColor: 'divider' }} onClick={onPrint}>
              <Printer width={24} height={24} />
            </IconButton>
            <CheckIcon sx={{ ml: 2, color: isAlreadyPrinted ? 'success.light' : theme.palette.action.active }} />
          </Box>
        }
      >
        <IconButton size="small" onClick={handleExpandCollapseClick}>
          {isExpanded ? <ExpandMore /> : <ChevronRight />}
        </IconButton>

        <ListItemText
          primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }}
          primary={`Batch ${index + 1}`}
          secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
          secondary={`${invoicesCount} invoice${invoicesCount > 1 ? 's' : ''}`}
        />
      </ListItem>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List disablePadding sx={{ ml: 3 }}>
          {Children.toArray(
            batch.map((invoiceId, subIndex) => {
              const invoice = get(selectedRowsById, invoiceId, {})
              return (
                <>
                  <InvoiceSummary row={invoice} />
                  {batch.length - 1 !== subIndex && <Divider />}
                </>
              )
            })
          )}
        </List>
      </Collapse>
    </>
  )
}

ExpandView.propTypes = {
  index: PropTypes.number,
  batch: PropTypes.array,
  isExpanded: PropTypes.bool,
  onExpandChange: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
}

export default ExpandView
