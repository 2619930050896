import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { INVOICE_STATUS } from 'settings/constants/billing'

const { UNPAID, PARTIAL, PAID } = INVOICE_STATUS

// Total cents
const getAmountCents = state => get(state, 'amountCents', 0)
const getpostedAmountCents = state => get(state, 'postedAmountCents', 0)
const getStatus = state => get(state, 'status', '')
const getAmountPaidCents = state => get(state, 'amountPaidCents', 0)

export const isPostInvoiceBtnEnabled = createSelector(
  [getpostedAmountCents, getAmountCents],
  (postedAmountCents, amountCents) => postedAmountCents < amountCents
)
export const isRegenerateBtnEnabled = createSelector([getStatus], status => [PARTIAL, UNPAID].includes(status))
export const isMarkAsPaidBtnEnabled = createSelector(
  [getStatus, getAmountCents],
  (status, amountCents) => status === UNPAID && amountCents === 0
)
export const isMarkAsSentBtnEnabled = createSelector([getStatus], status => status === PARTIAL)
export const isEditBtnEnabled = createSelector([getStatus], status => status === PARTIAL)
export const isDeleteBtnEnabled = createSelector([getStatus], status => status === PARTIAL)

export const isDeleteUnpaidEnabled = createSelector(
  [getStatus, getAmountPaidCents],
  (status, amountPaidCents) => status === UNPAID && amountPaidCents === 0
)
export const isMakePaymentEnabled = createSelector(
  [getStatus, getAmountCents],
  (status, amountCents) => status === UNPAID && amountCents > 0
)
export const isRevertToDraftEnabled = createSelector(
  [getStatus, getAmountPaidCents],
  (status, amountPaidCents) => status === UNPAID && amountPaidCents === 0
)

export const isMoveToAccountCreditEnabled = createSelector(
  [getStatus, getAmountPaidCents],
  (status, amountPaidCents) => [UNPAID, PAID].includes(status) && amountPaidCents > 0
)

export const isRevertToUnpaidEnabled = createSelector(
  [getStatus, getAmountCents],
  (status, amountCents) => status === PAID && amountCents === 0
)
