import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MapSelectionSnackbar from 'components/route-manager/BulkActions/common/MapSelectionSnackbar'
import { Box, styled } from '@mui/material'
import { useFormContext } from 'react-hook-form'

const MapContainer = styled('div')(({ zIndex }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  zIndex,
}))

const CommonSelectStopsToMoveMap = ({ mapContainer, ToggleComponent }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true)
  const { watch } = useFormContext()

  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')

  const handleCloseSnackbar = () => setIsSnackbarOpen(false)

  return (
    <Box position="relative" width="100%" height="100%">
      <MapContainer ref={mapContainer} />
      {ToggleComponent}
      <MapSelectionSnackbar isOpen={moveToRouteId && moveFromRouteId && isSnackbarOpen} onClose={handleCloseSnackbar} />
    </Box>
  )
}

CommonSelectStopsToMoveMap.propTypes = {
  mapContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  ToggleComponent: PropTypes.node,
}

export default CommonSelectStopsToMoveMap
