import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    postTransferPayment: build.mutation({
      query: ({ paymentNumber, ...body }) => ({ url: `/api/v1/billing/payment/${paymentNumber}/transfer`, method: 'POST', body }),
    }),
  }),
})

export const { usePostTransferPaymentMutation } = extendedApi
