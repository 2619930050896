import React, { memo, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { blobToBase64, getExceptionImageBlobFromPath } from 'utils/file'
import { CircularProgress, Stack } from '@mui/material'
import { EXCEPTION_TYPES } from 'settings/constants/exceptions'
import RenderException from 'components/work-order/details/exceptions/RenderException'

const ExceptionImageFromPath = ({
  path,
  height = '194px',
  minHeight,
  ImageSx,
  size = 'default',
  withSwiperSlide = false,
  exceptionType,
  PageProps,
}) => {
  const [base64Img, setBase64Img] = useState('')
  const isSignExceptionType = useMemo(() => exceptionType === EXCEPTION_TYPES.Sign, [exceptionType])
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)
  const showImage = base64Img && !isPhotoLoading
  const showSkeleton = !base64Img || isPhotoLoading
  const getCircularProgressSize = size => {
    if (size === 'medium') return 40
    if (size === 'small') return 40
    return 64
  }

  const circularProgressSize = getCircularProgressSize(size)

  const getExceptionPhoto = async () => {
    setIsPhotoLoading(true)
    getExceptionImageBlobFromPath(path, isSignExceptionType ? 'default' : size)
      .then(blobToBase64)
      .then(base64 => {
        if (base64) {
          setBase64Img(base64)
          setIsPhotoLoading(false)
        }
      })
      .catch(() => {
        setIsPhotoLoading(false)
      })
      .finally(() => setIsPhotoLoading(false))
  }

  useEffect(() => {
    if (!path) return noop
    getExceptionPhoto()
  }, [path, exceptionType, isSignExceptionType])

  const lazy = useMemo(() => (isSignExceptionType ? 'false' : 'true'), [isSignExceptionType])
  if (withSwiperSlide) {
    return (
      <swiper-slide lazy={lazy}>
        {showImage && (
          <RenderException
            PageProps={PageProps}
            isSignExceptionType={isSignExceptionType}
            base64Data={base64Img}
            ImageSx={ImageSx}
            height={height}
          />
        )}
        {showSkeleton && (
          <Stack sx={{ height, minHeight, ...ImageSx }} alignItems="center" justifyContent="center">
            <CircularProgress size={circularProgressSize} />
          </Stack>
        )}
      </swiper-slide>
    )
  }
  return (
    <>
      {showImage && <RenderException isSignExceptionType={isSignExceptionType} base64Data={base64Img} ImageSx={ImageSx} height={height} />}
      {showSkeleton && (
        <Stack sx={{ height, minHeight, ...ImageSx }} justifyContent="center" alignItems="center">
          {isPhotoLoading && <CircularProgress size={circularProgressSize} />}
        </Stack>
      )}
    </>
  )
}

ExceptionImageFromPath.propTypes = {
  height: PropTypes.string,
  minHeight: PropTypes.string,
  path: PropTypes.string.isRequired,
  ImageSx: PropTypes.object,
  size: PropTypes.string,
  withSwiperSlide: PropTypes.bool,
  exceptionType: PropTypes.string,
  PageProps: PropTypes.object,
}

export default memo(ExceptionImageFromPath)
