import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton as MuiIconButton, styled, useMediaQuery, useTheme } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { get } from 'lodash'
import SwiperContainer from 'components/work-order/details/exceptions/SwiperContainer'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  '&.Mui-disabled': {
    background: theme.palette.background.paper,
  },
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 28,
  height: 28,
}))

const ExceptionSwiperContainer = ({ exceptions, children }) => {
  const swiperRef = useRef(null)
  const theme = useTheme()
  const swiper = swiperRef?.current?.swiper
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const isCustomXL = useMediaQuery('(min-width: 1536px)')
  const isCustomLarge = useMediaQuery('(min-width: 1200px)')
  const isCustomMedium = useMediaQuery('(min-width: 900px)')
  const isCustomSmall = useMediaQuery('(min-width: 300px)')
  const exceptionsCount = get(exceptions, 'length', 0)

  const getShowSwiperNavigationArrows = () => {
    if (isCustomXL) return exceptionsCount > 4
    if (isCustomLarge || isCustomMedium) return exceptionsCount > 3
    if (isCustomSmall) return exceptionsCount > 2
    return exceptionsCount > 2
  }

  const showSwiperNavigationArrows = getShowSwiperNavigationArrows()

  const handleSwiperPrev = () => {
    swiper?.slidePrev(300)
    swiper.update()
  }

  const handleSwiperNext = () => {
    swiper?.slideNext(300)
    swiper.update()
  }

  useEffect(() => {
    if (Array.isArray(exceptions)) {
      setIsEnd(exceptions.length === 1)
    }
  }, [exceptions])

  useEffect(() => {
    if (swiper) {
      swiper.on('reachEnd', swiper => {
        setIsEnd(swiper.isEnd)
        setIsBeginning(swiper.isBeginning)
      })
      swiper.on('slideChange', swiper => {
        setIsEnd(swiper.isEnd)
        setIsBeginning(swiper.isBeginning)
      })
    }
  }, [swiper])

  useEffect(() => {
    if (Array.isArray(exceptions)) {
      setIsEnd(exceptions.length === 1)
    }
  }, [exceptions])

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', swiper => {
        setIsEnd(swiper.isEnd)
        setIsBeginning(swiper.isBeginning)
      })
    }
  }, [swiper])

  return (
    <>
      <SwiperContainer
        sx={{
          '&': { px: 3 },
          '.exceptions-swiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction':
            {
              bottom: '24px',
            },
          '.swiper-pagination-bullet': {
            opacity: 0.3,
            background: theme.palette.common.black,
          },
          '.swiper-pagination-bullet-active': {
            opacity: 0.54,
            background: theme.palette.common.black,
          },
          'swiper-container.exceptions-swiper': {
            padding: exceptionsCount === 0 ? 0 : ' 16px 0 40px 0px',
          },
        }}
        className="exceptions-swiper"
        swiperRef={swiperRef}
        slidesPerView={2}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          300: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 2,
          },
          1800: {
            slidesPerView: 4,
          },
        }}
      >
        {children}
      </SwiperContainer>
      {showSwiperNavigationArrows && (
        <Box zIndex={1} position="absolute" left="50%" bottom="-14px" transform="translate(-50%, -50%)" margin="0 auto" marginLeft="-32px">
          <IconButton disabled={isBeginning} onClick={handleSwiperPrev}>
            <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <IconButton disabled={isEnd} sx={{ ml: 1 }} onClick={handleSwiperNext}>
            <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      )}
    </>
  )
}

ExceptionSwiperContainer.propTypes = {
  exceptions: PropTypes.array.isRequired,
  children: PropTypes.node,
}

export default ExceptionSwiperContainer
