import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Droppable, Draggable } from 'react-beautiful-dnd'
import { FixedSizeList, areEqual } from 'react-window'
import { AutoSizer } from 'react-virtualized'
import { Box } from '@mui/material'
import { PRIMARY } from 'theme/colors'

import { get } from 'utils/lodash'
import { DROPPABLE_COLUMN_TYPE_WORK_ORDER } from 'data/route/routeDataMappingSelector'

import RouteItem from '../RouteList/RouteItem'
import { getStyle, ROUTE_COLUMN_WIDTH } from './settings'
import { canMoveStopToAnotherRoute } from '../helper'

// Item component
const Item = ({ provided, stop, index, style, isDragging, ...rest }) => {
  return (
    <Box
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`stop ${isDragging ? 'is-dragging' : ''}`}
      sx={{
        '&.is-dragging .MuiCard-root': { border: `2pt solid ${PRIMARY.main}` },
      }}
    >
      <RouteItem {...rest} routeIndex={index} scheduledStops={stop} />
    </Box>
  )
}

Item.propTypes = {
  provided: PropTypes.object,
  index: PropTypes.number,
  stop: PropTypes.object,
  style: PropTypes.object,
  isDragging: PropTypes.bool,
}

// Row component
const Row = React.memo(props => {
  const { data, index, style } = props
  const { stops, isColumnDragDisabled, dragPermissionsDisabled, ...rest } = data

  const stop = get(stops, `[${index}]`)

  if (!stop) {
    return null
  }

  const workOrderStatus = get(stop, 'workOrderStatus', '')
  const isDisabled = !canMoveStopToAnotherRoute(workOrderStatus)
  const workOrderId = get(stop, 'workOrderId')

  return (
    <Draggable
      draggableId={workOrderId}
      index={index}
      key={workOrderId}
      isDragDisabled={isColumnDragDisabled ? true : dragPermissionsDisabled || isDisabled}
    >
      {provided => <Item provided={provided} stop={stop} {...rest} index={index} style={style} />}
    </Draggable>
  )
}, areEqual)

Row.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number,
  style: PropTypes.object,
  dragPermissionsDisabled: PropTypes.bool,
  isColumnDragDisabled: PropTypes.bool,
}

// Route Column component
const RouteColumn = props => {
  const { route, index, ...rest } = props
  const listRef = useRef()

  return (
    <Droppable
      droppableId={route.id}
      mode="virtual"
      type={DROPPABLE_COLUMN_TYPE_WORK_ORDER}
      renderClone={(provided, snapshot, rubric) => (
        <Item provided={provided} isDragging={snapshot.isDragging} stop={route.stops[rubric.source.index]} index={rubric.source.index} />
      )}
    >
      {(provided, snapshot) => {
        const { stops = [] } = route
        const itemCount = snapshot.isUsingPlaceholder ? stops.length + 1 : stops.length

        return (
          <Box height="100%">
            <AutoSizer defaultHeight={500} disableWidth>
              {({ height }) => (
                <FixedSizeList
                  height={height}
                  itemCount={itemCount}
                  itemSize={170}
                  width={ROUTE_COLUMN_WIDTH}
                  outerRef={provided.innerRef}
                  itemData={{ ...rest, stops }}
                  ref={listRef}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        )
      }}
    </Droppable>
  )
}

RouteColumn.propTypes = {
  route: PropTypes.object,
  index: PropTypes.number,
}

export default RouteColumn
