import { get } from 'utils/lodash'
import { createSelector } from 'reselect'
import { getRouteMetadataSlice } from 'api/route/getRouteMetadata'

const emptyCollection = []
const allRouteMetadataSlice = getRouteMetadataSlice

export const getMetadataRoutes = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.routes', emptyCollection)
)

export const getAllMetadataRouteIds = createSelector(getMetadataRoutes, (routes = emptyCollection) => routes.map(({ id }) => id))

export const getMetadataBusinessLines = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.businessLines', emptyCollection)
)

export const getMetadataMethods = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.methods', emptyCollection)
)

export const getMetadataPricingZones = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.pricingZones', emptyCollection)
)

export const getMetadataYards = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.yards', emptyCollection)
)

export const getMetadataDrivers = createSelector(allRouteMetadataSlice, (routeMetadata = {}) =>
  get(routeMetadata, 'data.drivers', emptyCollection)
)
