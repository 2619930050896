import React from 'react'
import PropTypes from 'prop-types'

import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle'

import ValidatedTaxTable from 'components/pricing/Taxes/ValidatedTaxTable'

import T from 'T'

import 'components/pricing/services.scss'

const TaxTableScreen = ({
  getTaxServiceName,
  validatedTaxes,
  duplicateTaxRecords,
  configData,
  onHandleValidatedFieldsChange,
  handleDelete,
  setState,
  onTableScopeChange,
}) => {
  const handleClick = (key, value) => {
    setState({ [key]: value })
  }

  return (
    <div className="tax-tabpanel-content">
      <div className="all-taxes-screen">
        {validatedTaxes.length > 0 && (
          <ValidatedTaxTable
            validatedTaxes={validatedTaxes}
            confServices={configData}
            handleSingleChange={handleClick}
            handleDelete={handleDelete}
            onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
            type="unique"
            getTaxServiceName={getTaxServiceName}
            onTableScopeChange={onTableScopeChange}
          />
        )}

        {duplicateTaxRecords.length > 0 && (
          <div className="duplicate-records">
            <div className={`error-block-record ${validatedTaxes.length === 0 ? 'no-validated-record' : ''}`}>
              <div className="error-block">
                <ExclamationCircle className="error-msg" />
                <div className="label">{T.TAX_DUPLICATED_MSG}</div>
              </div>

              <ValidatedTaxTable
                validatedTaxes={duplicateTaxRecords}
                confServices={configData}
                handleSingleChange={handleClick}
                handleDelete={handleDelete}
                onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
                type="duplicate"
                getTaxServiceName={getTaxServiceName}
                onTableScopeChange={onTableScopeChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

TaxTableScreen.defaultProps = {
  validatedTaxes: [],
  duplicateTaxRecords: [],
  configData: [],
  setState: null,
  getTaxServiceName: null,
  onHandleValidatedFieldsChange: null,
  handleDelete: null,
  onTableScopeChange: null,
}

TaxTableScreen.propTypes = {
  validatedTaxes: PropTypes.array,
  duplicateTaxRecords: PropTypes.array,
  configData: PropTypes.array,
  setState: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onTableScopeChange: PropTypes.func,
}

export default TaxTableScreen
