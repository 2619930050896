import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerPortalSettings: build.query({
      query: () => ({
        url: '/api/settings/customer-portal',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useLazyGetCustomerPortalSettingsQuery } = extendedApi
