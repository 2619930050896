import { get } from 'lodash'
import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    fetchFocusedServices: builder.query({
      query: ({ lookups, page: requestedPage, size: requestedPageSize, includeDeactivatedLocations }) => ({
        url: '/api/configured-service/focusedSearch',
        method: 'POST',
        body: {
          lookups,
          requestedPage,
          requestedPageSize,
          includeDeactivatedLocations,
        },
      }),
      transformResponse: response => {
        const { services, totalPages, totalItems } = response
        const currentPage = get(response, 'currentPage', 0)
        const pageSize = get(response, 'pageSize', 0)
        return {
          page: currentPage,
          size: pageSize,
          locations: services,
          totalPages,
          totalItems,
        }
      },
    }),
  }),
})

export const { useLazyFetchFocusedServicesQuery } = extendedApi
