import React, { Children, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import debounce from 'lodash/debounce'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { CircularProgress, Box, Pagination } from '@mui/material'

import { get } from 'utils/lodash'
import { HHNumberField } from 'components/form-fields/v5'
import { setGoToPage, resetGoToPage, setSelectedInvoiceIdForPreview } from 'slices/groups/InvoicePrintableViewSlice'

import PageBreak from 'components/work-order/export/PageBreak'
import { common } from '@mui/material/colors'

import { BATCH_SIZE, ROW_HEIGHT } from './settings'
import Header from './pdf/Header'
import InvoiceLine from './pdf/InvoiceLine'
import TotalInfo from './pdf/TotalInfo'
import AgingInfo from './pdf/AgingInfo'
import './pdf/style.scss'

const InvoicePreview = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const { isLoading = false, invoiceRows = [], rawInvoicesData = {}, hierarchyIds = [] } = props
  const selectionModel = useSelector(state => get(state, 'InvoicePrintableView.invoiceSelectionModel', []), shallowEqual)
  const selectedInvoiceIdForPreview = useSelector(state => get(state, 'InvoicePrintableView.selectedInvoiceIdForPreview'), shallowEqual)
  const goToPage = useSelector(state => get(state, 'InvoicePrintableView.goToPage', ''), shallowEqual)
  const currentBatchId = useSelector(state => get(state, 'InvoicePrintableView.batchDialog.currentBatchId'), shallowEqual)

  const invoices = invoiceRows.filter(row => !row.isParent)
  const selectedInvoiceIndex = invoices.findIndex(invoice => invoice.id === selectedInvoiceIdForPreview)
  const selectedPageNumber = selectedInvoiceIndex >= 0 ? selectedInvoiceIndex : 0

  const getInvoices = () => {
    if (currentBatchId >= 0) {
      const batches = chunk(selectionModel, BATCH_SIZE)
      return get(batches, currentBatchId, [])
    }

    const invoice = get(invoices, selectedPageNumber)
    return invoice?.id ? [invoice.id] : []
  }

  const previewInvoices = getInvoices()
  const previewInvoicesLength = previewInvoices.length

  const handleScroll = (invoiceId, page) => {
    try {
      if (!invoiceId) {
        ref.current?.scrollToIndexes({ rowIndex: 0 })
        return
      }

      const rowDetails = ref.current.getRowNode(invoiceId)
      const parentName = rowDetails.parent
      const prevParentRows = hierarchyIds.indexOf(parentName)
      ref.current.setRowChildrenExpansion(rowDetails.parent, true)
      let prevCollapsedRows = 0
      hierarchyIds.slice(0, prevParentRows).forEach(hierarchy => {
        const currentEl = ref.current.getRowNode(hierarchy)
        prevCollapsedRows += currentEl.childrenExpanded ? 0 : currentEl.children.length
      })

      setTimeout(() => ref.current?.scroll({ top: (prevParentRows + page - prevCollapsedRows) * ROW_HEIGHT - ROW_HEIGHT }), 500)
    } catch (err) {}
  }

  const handlePageChange = (newPage, reset = false) => {
    const newSelectedInvoiceId = get(invoices, `[${newPage}].id`)
    dispatch(setSelectedInvoiceIdForPreview(newSelectedInvoiceId))
    handleScroll(newSelectedInvoiceId, newPage)
    if (reset) {
      dispatch(resetGoToPage())
    }
  }

  const handleGoToPage = debounce(values => {
    const { value: originalValue } = values
    dispatch(setGoToPage(originalValue))
    if (originalValue > 0 && originalValue <= invoices.length) {
      handlePageChange(originalValue - 1)
    }
  }, 150)

  useEffect(() => {
    return () => {
      handleGoToPage.cancel()
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {!isLoading && previewInvoicesLength > 0 && (
        <>
          {Children.toArray(
            previewInvoices.map((id, index) => {
              const invoice = get(rawInvoicesData, id)
              return (
                <>
                  <Box sx={{ '@media print': { p: 0 }, color: common.black }} p={3} pb={12} width="100%" className="invoice-pdf-view">
                    <Header invoice={invoice} />
                    <InvoiceLine invoice={invoice} />
                    <TotalInfo invoice={invoice} />
                    <AgingInfo invoice={invoice} />
                  </Box>
                  {previewInvoicesLength - 1 !== index && <PageBreak />}
                </>
              )
            })
          )}

          <Box
            className="no-print"
            position="fixed"
            bottom={0}
            width="69%"
            bgcolor="background.paper"
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            <HHNumberField
              decimalScale={0}
              deprecatedLabel={false}
              size="small"
              allowNegative={false}
              allo
              label="Page"
              value={goToPage}
              onValueChange={handleGoToPage}
              sx={{ width: 65, mr: 0.5 }}
            />
            <Pagination
              variant="outlined"
              shape="rounded"
              color="primary"
              count={invoices.length}
              page={selectedPageNumber + 1}
              onChange={(event, newPage) => handlePageChange(newPage - 1, true)}
            />
          </Box>
        </>
      )}
    </>
  )
})

InvoicePreview.propTypes = {
  isLoading: PropTypes.bool,
  invoiceRows: PropTypes.array,
  rawInvoicesData: PropTypes.object,
  hierarchyIds: PropTypes.array,
}

export default InvoicePreview
