import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'
import capitalize from 'lodash/capitalize'
import { toast } from 'react-toastify'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Box, Button, DialogContent, Grid, InputAdornment, IconButton, useTheme, useMediaQuery } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import SearchIcon from '@mui/icons-material/Search'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { dataGridHeightFixes } from 'utils/datagrid'
import { putIsLoading } from 'middleware/actions/response'
import { getAccountsInvoicesForDataGrid, getCombinedInvoices } from 'data/groups/invoiceGroupsSelectors'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import { useAddInvoicesToGroupMutation } from 'api/groups/invoice/addInvoicesToGroup'
import { useGetBillingInvoiceMetaQuery } from 'api/billing/getBillingInvoiceMeta'
import { useLazyGetBillingPeriodsByIdQuery } from 'api/billing/getBillingPeriodsById'
import { useLazyGetCustomerBillingProfileByIdQuery } from 'api/settings/customerBillingProfileCrud'
import { setAccountSelectionModel, setInvoiceSelectionModel, resetSelectionModels } from 'slices/groups/AddRemoveInvoiceDialogSlice'
import {
  useLazyGetDraftInvoiceListQuery,
  useLazyGetCurrentInvoiceListQuery,
  useLazyGetOneThirtyInvoiceListQuery,
  useLazyGetThirtySixtyInvoiceListQuery,
  useLazyGetSixtyNinetyInvoiceListQuery,
  useLazyGetOverNinetyInvoiceListQuery,
  useLazyGetPaidInvoiceListQuery,
} from 'api/billing/getBillingInvoiceListByAging'
import { HHAlert } from 'components/common/HHAlert'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHTextField from 'components/form-fields/v5/HHTextField'
import BillingProfileDropdown from '../../../common/BillingProfileDropdown'
import BillingPeriodDropdown from '../../../common/BillingPeriodDropdown'
import AccountsDataGrid from './common/AccountsDataGrid'
import InvoicesDataGrid from './common/InvoicesDataGrid'

const AddInvoicesToGroupDialog = ({ isOpen = false, groupId, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const accountApiRef = useGridApiRef()
  const invoiceApiRef = useGridApiRef()
  const accountSelectionModel = useSelector(state => get(state, 'AddRemoveInvoiceDialog.accountSelectionModel', []), shallowEqual)
  const invoiceSelectionModel = useSelector(state => get(state, 'AddRemoveInvoiceDialog.invoiceSelectionModel', []), shallowEqual)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { data: billingInvoiceMetaData = {} } = useGetBillingInvoiceMetaQuery()
  const [getBillingPeriodsById, { data: billingPeriodList, isError: isBillingPeriodListError }] = useLazyGetBillingPeriodsByIdQuery()
  const [getCustomerBillingProfileById, { data: billingProfileDetails, isError: isBillingProfileByIdError }] =
    useLazyGetCustomerBillingProfileByIdQuery()
  const [addInvoicesToGroup] = useAddInvoicesToGroupMutation()
  const [getDraftInvoiceList, { data: draftList, isFetching: isDraftListLoading }] = useLazyGetDraftInvoiceListQuery()
  const [getCurrentInvoiceList, { data: currentList, isFetching: isCurrentListLoading }] = useLazyGetCurrentInvoiceListQuery()
  const [getOneThirtyInvoiceList, { data: oneThirtyList, isFetching: isOneThirtyListLoading }] = useLazyGetOneThirtyInvoiceListQuery()
  const [getThirtySixtyInvoiceList, { data: thirtySixtyList, isFetching: isThirtySixtyListLoading }] =
    useLazyGetThirtySixtyInvoiceListQuery()
  const [getSixtyNinetyInvoiceList, { data: sixtyNinetyList, isFetching: isSixtyNinetyListLoading }] =
    useLazyGetSixtyNinetyInvoiceListQuery()
  const [getOverNinetyInvoiceList, { data: overNinetyList, isFetching: isOverNinetyListLoading }] = useLazyGetOverNinetyInvoiceListQuery()
  const [getPaidInvoiceList, { data: paidList, isFetching: isPaidListLoading }] = useLazyGetPaidInvoiceListQuery()

  const [search, setSearch] = useState('')
  const [isSearchDirty, setIsSearchDirty] = useState(false)

  const [selectedBillingProfile, setSelectedBillingProfile] = useState('')
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState({ id: '', details: {} })
  const billingProfileMeta = get(billingInvoiceMetaData, 'billingProfiles', [])
  const allBillingPeriods = getBillingPeriods({
    billingProfile: isBillingProfileByIdError ? {} : billingProfileDetails,
    billingPeriods: isBillingPeriodListError ? [] : get(billingPeriodList, 'billingPeriods', []),
  })
  const isDataGridLoading =
    isDraftListLoading ||
    isCurrentListLoading ||
    isOneThirtyListLoading ||
    isThirtySixtyListLoading ||
    isSixtyNinetyListLoading ||
    isOverNinetyListLoading ||
    isPaidListLoading
  const invoices = isDataGridLoading
    ? []
    : getCombinedInvoices({ draftList, currentList, oneThirtyList, thirtySixtyList, sixtyNinetyList, overNinetyList, paidList })
  const { accountRows, invoiceRows, totalAmountCents, hierarchyIds } = getAccountsInvoicesForDataGrid({
    invoices: isDataGridLoading ? [] : invoices,
  })

  const handleSelectAll = () => {
    dispatch(setAccountSelectionModel(accountApiRef.current.getAllRowIds()))
    dispatch(setInvoiceSelectionModel(invoiceApiRef.current.getAllRowIds()))
  }

  const handleDeselectAll = () => dispatch(resetSelectionModels())

  const getInvoiceList = params => {
    const payload = {
      pageNumber: 0,
      pageSize: 1000,
      billingProfileId: selectedBillingProfile,
      accountName: search,
      startDate: get(selectedBillingPeriod, 'details.startDate', ''),
      endDate: get(selectedBillingPeriod, 'details.endDate', ''),
      ...params,
    }

    if (!payload.billingProfileId && !payload.accountName) {
      return
    }

    handleDeselectAll()

    getDraftInvoiceList(payload)
    getCurrentInvoiceList(payload)
    getOneThirtyInvoiceList(payload)
    getThirtySixtyInvoiceList(payload)
    getSixtyNinetyInvoiceList(payload)
    getOverNinetyInvoiceList(payload)
    getPaidInvoiceList(payload)
  }

  const handleAddInvoicesToGroup = () => {
    dispatch(putIsLoading(true))
    addInvoicesToGroup({ invoiceGroupId: groupId, invoiceIds: invoiceSelectionModel })
      .unwrap()
      .then(() =>
        toast.success(invoiceSelectionModel.length > 1 ? T.ADD_INVOICES_TO_GROUP_SUCCESS_MSG : T.ADD_INVOICE_TO_GROUP_SUCCESS_MSG)
      )
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleBillingProfileChange = value => {
    setSelectedBillingProfile(value)
    setSelectedBillingPeriod({ id: '', details: {} })
    if (value) {
      getBillingPeriodsById(value).unwrap().catch(handleError)
      getCustomerBillingProfileById({ id: value }).unwrap().catch(handleError)
    }
  }

  const handleBillingPeriodChange = value => {
    const currentPeriod = get(billingPeriodList, 'billingPeriods', []).find(bp => bp.invoiceDate === value) || {}
    setSelectedBillingPeriod({ id: value, details: currentPeriod })
  }

  const handleSearchChange = event => {
    const { value } = event.target
    setSearch(value)
    if (!value) {
      setIsSearchDirty(false)
    }
  }

  const handleSearchEnterPress = () => {
    if (search) {
      setIsSearchDirty(true)
    }
    getInvoiceList()
  }

  const handleAccountSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, accountSelectionModel)
    const unselectedIds = difference(accountSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setAccountSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let invoiceIds = []
    currentIds.forEach(id => {
      const rowData = accountApiRef.current.getRow(id)
      invoiceIds = [...invoiceIds, ...rowData.invoiceIds]
    })

    const newInvoices = isAdded ? [...new Set([...invoiceSelectionModel, ...invoiceIds])] : difference(invoiceSelectionModel, invoiceIds)
    dispatch(setInvoiceSelectionModel(newInvoices))
  }

  const handleInvoiceSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, invoiceSelectionModel)
    const unselectedIds = difference(invoiceSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setInvoiceSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let accountIds = []
    currentIds.forEach(id => {
      const rowData = invoiceApiRef.current.getRow(id)
      if (rowData?.accountId) {
        accountIds = [...accountIds, rowData.accountId]
      }
    })

    const newInvoices = isAdded ? [...new Set([...accountSelectionModel, ...accountIds])] : difference(accountSelectionModel, accountIds)
    dispatch(setAccountSelectionModel(newInvoices))
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedBillingProfile('')
      setSelectedBillingPeriod({ id: '', details: {} })
      setSearch('')
      setIsSearchDirty(false)
      handleDeselectAll()
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedBillingProfile || search) {
      getInvoiceList()
    }
  }, [selectedBillingProfile, selectedBillingPeriod])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={T.ADD_INVOICE} onClose={onClose} />
      <DialogContent>
        <Grid mt={1} container spacing={2}>
          <Grid item xs={12} sm>
            <BillingProfileDropdown
              label={capitalize(T.BILLING_PROFILE)}
              deprecatedLabel={false}
              selectedBillingProfile={selectedBillingProfile}
              billingProfileMeta={billingProfileMeta}
              onBillingProfileChange={handleBillingProfileChange}
            />
          </Grid>
          <Grid item xs={12} sm>
            <BillingPeriodDropdown
              deprecatedLabel={false}
              disabled={!selectedBillingProfile}
              selectedBillingPeriod={selectedBillingPeriod.id}
              allBillingPeriods={allBillingPeriods}
              onBillingPeriodChange={handleBillingPeriodChange}
            />
          </Grid>
        </Grid>

        <Grid mt={2} container spacing={1}>
          <Grid item xs={12} md={4} borderRight={isTabletOrMobile ? 0 : 1} borderColor="divider">
            <HHTextField
              sx={{ pr: 1, '& .MuiInputBase-root': { pr: 0 } }}
              fullWidth
              placeholder="Search"
              label="Search"
              deprecatedLabel={false}
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearchEnterPress}>
                      <SearchIcon sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              onKeyDown={event => {
                if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                  handleSearchEnterPress()
                }
              }}
            />
            <Box height={isTabletOrMobile ? 250 : 'calc(100vh - 410px)'}>
              {(selectedBillingProfile || isSearchDirty) && (
                <AccountsDataGrid
                  ref={accountApiRef}
                  loading={isDataGridLoading}
                  rows={accountRows}
                  onSelectionModelChange={handleAccountSelectionChange}
                  onSelectAll={handleSelectAll}
                  onDeselectAll={handleDeselectAll}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box height={isTabletOrMobile ? 500 : '100%'} {...dataGridHeightFixes}>
              {!selectedBillingProfile && !isSearchDirty && (
                <HHAlert borderColor={theme.palette.info.light} severity="info">
                  Select a billing profile or search by customer name to start
                </HHAlert>
              )}

              {(selectedBillingProfile || isSearchDirty) && (
                <InvoicesDataGrid
                  ref={invoiceApiRef}
                  loading={isDataGridLoading}
                  rows={invoiceRows}
                  hierarchyIds={hierarchyIds}
                  totalAmountCents={totalAmountCents}
                  onSelectionModelChange={handleInvoiceSelectionChange}
                  onSelectAll={handleSelectAll}
                  onDeselectAll={handleDeselectAll}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <Button disabled={!invoiceSelectionModel.length} autoFocus size="small" variant="contained" onClick={handleAddInvoicesToGroup}>
          {T.ADD}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddInvoicesToGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddInvoicesToGroupDialog
