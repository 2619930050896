import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import { useDispatch, useSelector } from 'react-redux'
import { Box, DialogContent, useTheme, useMediaQuery, Grid } from '@mui/material'
import { get } from 'lodash'
import HHDialogTitle from '../../common/HHDialogTitle'
import {
  setWOReviewApplyFilters,
  setWOReviewFilters,
  setWOReviewFiltersDialogIsOpen,
  setWOReviewFormData,
  setWOReviewServiceDateRange,
  setWOReviewServiceTypeList,
} from '../../../slices/billing/workOrdersReviewSlice'
import HHDialogActions from '../../common/HHDialogActions'
import CancelButton from '../../buttons/CancelButton'
import SaveButton from '../../buttons/SaveButton'
import { HHDateRangePicker } from '../../form-fields/v5'
import RoutesAutocompleteFormField from './RoutesAutocompleteFormField'
import ActionsAutocompleteFormField from './ActionsAutocompleteFormField'
import { serializeDate } from '../../../utils/date'
import { setWOReviewRouteIdList } from '../../../slices/billing/persistentWorkOrdersReviewSlice'
import { RECURRENCE } from '../../../settings/constants/service'

const { RECURRING, ON_REQUEST } = RECURRENCE

const WoReviewFiltersDialog = () => {
  const isOpen = useSelector(s => s.workOrdersReview.filtersDialog.isOpen)
  const applyFilters = useSelector(s => s.workOrdersReview.filtersDialog.applyFilters)
  const dispatch = useDispatch()
  const theme = useTheme()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useFormContext()
  const allRoutesSelected = watch('allRoutesSelected')
  const allActionsSelected = watch('allActionsSelected')
  const routesError = get(errors, 'selectedRouteIds.message')
  const actionsError = get(errors, 'actions.message')
  const dateRangeError = get(errors, 'dateRange.message')
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const closeDialog = () => {
    dispatch(setWOReviewFiltersDialogIsOpen(false))
    reset()
  }

  const submit = data => {
    const { dateRange, selectedRouteIds, actions } = data
    const filteredActions = actions.filter(({ id }) => id !== RECURRING).map(({ id }) => id.toLowerCase())
    const hasRecurring = actions.some(({ id }) => id === RECURRING)
    const serviceType = []
    if (hasRecurring) {
      serviceType.push(RECURRING)
    }
    if (filteredActions.length > 0) {
      serviceType.push(ON_REQUEST)
    }
    dispatch(setWOReviewServiceDateRange(dateRange.map(serializeDate)))
    dispatch(setWOReviewFilters(filteredActions))
    dispatch(setWOReviewRouteIdList(selectedRouteIds.map(({ id }) => id)))
    dispatch(setWOReviewServiceTypeList(serviceType))
    dispatch(setWOReviewApplyFilters(!applyFilters))
    dispatch(setWOReviewFormData(data))
    dispatch(setWOReviewFiltersDialogIsOpen(false))
  }

  const validateDateRange = dateRange => {
    const dateRangeCount = dateRange.filter(Boolean).length
    return dateRangeCount === 2 || 'This field is required'
  }

  useEffect(() => {
    if (isOpen) {
      const values = getValues()
      reset(values)
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} maxWidth="xl">
      <HHDialogTitle title={T.FILTERS} onClose={closeDialog} />
      <DialogContent sx={{ overflow: 'hidden', mt: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={1}>
              <HHDateRangePicker
                className="WO-filter-date-range"
                localeText={{ start: 'Start date *', end: 'End date *' }}
                name="dateRange"
                error={dateRangeError}
                helperText={dateRangeError}
                control={control}
                rules={{ required: 'This field is required', validate: validateDateRange }}
                restSlotProps={{
                  popper: {
                    // Position not adjusting automatically for large textbox width
                    ...(isDesktop && {
                      placement: 'bottom',
                      anchorEl: () => document.getElementsByClassName('WO-filter-date-range')[0],
                    }),
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <RoutesAutocompleteFormField
                required
                setValue={setValue}
                rules={{ required: 'Select at least one route' }}
                name="selectedRouteIds"
                allRoutesSelected={allRoutesSelected}
                error={Boolean(routesError)}
                helperText={routesError}
                control={control}
                label={`${T.ROUTE}s`}
                sx={{
                  width: '100%',
                  '& .MuiTextField-root .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
                    height: 'unset',
                    py: 0.5,
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    overflow: 'hidden',
                    flexWrap: 'wrap',
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <ActionsAutocompleteFormField
                required
                rules={{ required: 'Select at least one action' }}
                setValue={setValue}
                name="actions"
                error={Boolean(actionsError)}
                helperText={actionsError}
                allActionsSelected={allActionsSelected}
                control={control}
                label={`${T.ACTION}s`}
                sx={{
                  width: '100%',
                  '& .MuiTextField-root .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
                    height: 'unset',
                    py: 0.5,
                  },
                  '& .MuiAutocomplete-inputRoot': {
                    overflow: 'hidden',
                    flexWrap: 'wrap',
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={closeDialog} />
        <SaveButton onClick={handleSubmit(submit)} label={T.APPLY_FILTERS} />
      </HHDialogActions>
    </Dialog>
  )
}

export default WoReviewFiltersDialog
