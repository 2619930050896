export const actions = {
  SAVE_UPDATE_EVENT_CS_SERVICE: 'CS_ADD_EVENT_SERVICE',
  DELETE_EVENT_CS_SERVICE: 'CS_DELETE_EVENT_SERVICE',
  GET: {
    SEARCH_CONFIGURED_SERVICES: 'GET_CONFIGURED_SERVICES_SEARCH_RESULTS',
    SEARCH_PRICING_SERVICE: 'GET_PRICING_SERVICE_SEARCH_RESULTS',
    PRICING_SERVICE_DETAIL: 'GET_PRICING_SERVICE_DETAIL',
    CONFIGURED_SERVICE_DETAIL: 'GET_CONFIGURED_SERVICE_DETAIL',
    CONFIGURED_SERVICES_EVENTS: 'GET_CONFIGURED_SERVICES_EVENTS',
  },
  PUT: {
    SEARCH_PRICING_SERVICE: 'PUT_PRICING_SERVICE_SEARCH_RESULTS',
    UPDATE_CS_NOTE: 'PUT_UPDATE_CS_NOTE',
  },
}

export const putPricingServiceSearch = res => ({
  type: actions.PUT.SEARCH_PRICING_SERVICE,
  payload: res,
})

export const getCSSearchResults = (body, onSuccess) => ({
  type: actions.GET.SEARCH_CONFIGURED_SERVICES,
  payload: { body, onSuccess },
})

export const getPricingServiceSearchResults = (body, onSuccess) => ({
  type: actions.GET.SEARCH_PRICING_SERVICE,
  payload: { body, onSuccess },
})

export const getPricingServiceDetail = (body, onSuccess) => ({
  type: actions.GET.PRICING_SERVICE_DETAIL,
  payload: { body, onSuccess },
})

export const getConfigureServiceeDetail = (body, onSuccess) => ({
  type: actions.GET.CONFIGURED_SERVICE_DETAIL,
  payload: { body, onSuccess },
})

export const saveUpdateEventCSService = (body, onSuccess) => ({
  type: actions.SAVE_UPDATE_EVENT_CS_SERVICE,
  payload: { body, onSuccess },
})

export const deleteEventCSService = (body, onSuccess) => ({
  type: actions.DELETE_EVENT_CS_SERVICE,
  payload: { body, onSuccess },
})

export const updateConfiguredServicesNote = body => ({
  type: actions.PUT.UPDATE_CS_NOTE,
  payload: { body },
})

export const getConfiguredServiceEvents = (body, onSuccess) => ({
  type: actions.GET.CONFIGURED_SERVICES_EVENTS,
  payload: { body, onSuccess },
})
