import React from 'react'

import Checkbox from '@mui/material/Checkbox'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

const DataGridBaseCheckbox = params => (
  <Checkbox
    icon={<RadioButtonUncheckedSharpIcon />}
    checkedIcon={<CheckCircleSharpIcon />}
    indeterminateIcon={<RemoveCircleIcon />}
    {...params}
  />
)

export default DataGridBaseCheckbox
