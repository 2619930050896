import { useEffect } from 'react'
import { INVOICE_STATUS } from '../../../../settings/constants/invoice'

const { NOT_READY_FOR_INVOICE } = INVOICE_STATUS

export const useFetchWORowsOnExpandGroup = ({ fetchWorkOrders, getPayload, apiRef }) => {
  useEffect(() => {
    const handleRowExpansionChange = async node => {
      const parentRow = apiRef.current.getRow(node.id)
      if (!node || !node.childrenExpanded || !parentRow || parentRow.childrenFetched) {
        return
      }

      apiRef.current.updateRows([
        {
          id: `placeholder-children-${node.id}`,
          hierarchy: [...parentRow.hierarchy, ''],
        },
      ])
      const payload = getPayload(parentRow)
      await fetchWorkOrders(payload)
        .unwrap()
        .then(({ workOrders }) => {
          if (Array.isArray(workOrders)) {
            const newRows = []
            const childrenNotReadyIds = []

            workOrders.forEach(({ id, routeId, invoiceStatus, ...rest }) => {
              const newRow = {
                ...rest,
                id,
                routeId,
                invoiceStatus,
                hierarchy: [routeId, id],
                descendantCount: 0,
                hideDescendantCount: true,
                isWO: true,
                isRoute: false,
              }

              newRows.push(newRow)

              if (invoiceStatus === NOT_READY_FOR_INVOICE) {
                childrenNotReadyIds.push(id)
              }
            })
            apiRef.current.updateRows([
              ...newRows,
              { id: node.id, childrenFetched: true, childrenNotReadyIds },
              { id: `placeholder-children-${node.id}`, _action: 'delete' },
            ])

            if (newRows.length) {
              apiRef.current.setRowChildrenExpansion(node.id, true)
            }
          }
        })
        .catch(() => {
          apiRef.current.setRowChildrenExpansion(node.id, false)
        })
    }
    // https://v5.mui.com/x/react-data-grid/events/#with-apiref-current-subscribeevent
    // return so that it can unsubscribe
    return apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange)
  }, [apiRef, getPayload])

  useEffect(() => {
    const handleCellKeyDown = (params, event) => {
      const cellParams = apiRef.current.getCellParams(params.id, params.field)
      if (cellParams.colDef.type === 'treeDataGroup' && event.key === ' ') {
        event.stopPropagation()
        event.preventDefault()
        event.defaultMuiPrevented = true

        apiRef.current.setRowChildrenExpansion(params.id, !params.rowNode.childrenExpanded)
      }
    }
    // https://v5.mui.com/x/react-data-grid/events/#with-apiref-current-subscribeevent
    // return so that it can unsubscribe
    return apiRef.current.subscribeEvent('cellKeyDown', handleCellKeyDown, {
      isFirst: true,
    })
  }, [apiRef])
}
