import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import { Check } from '@styled-icons/heroicons-outline/Check'

import { CommonTextLabel } from 'components/common'

import T from 'T'

import { get } from 'utils/lodash'

import './taxes.scss'

const TaxInitialScreen = ({ businessLines, onBusinessLineChange }) => {
  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  return (
    <div className="tax-tabpanel-content">
      <div className="business-line-select">
        <CommonTextLabel title={T.BUSINESS_LINE} className="ttc" />

        <div className="business-line-container">
          {Children.toArray(
            globalFilterOptions &&
              globalFilterOptions.businessLines.map((business, index) => (
                <div
                  className={`business-lines ${businessLines.includes(business.businessLineLabel) ? 'active-business-Line' : ''}`}
                  onClick={() => onBusinessLineChange(business.businessLineLabel)}
                >
                  <Check />
                  {business.businessLineLabel}
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  )
}

TaxInitialScreen.defaultProps = {
  businessLines: [],
  onBusinessLineChange: null,
}

TaxInitialScreen.propTypes = {
  businessLine: PropTypes.array,
  onBusinessLineChange: PropTypes.func,
}

export default TaxInitialScreen
