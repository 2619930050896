import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import T from 'T'
import { ChevronLeft, ChevronRight, ArrowDropDown, ArrowRight, Add } from '@mui/icons-material'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import noop from 'lodash/noop'
import { useFormContext } from 'react-hook-form'
import { addDays, subDays } from 'date-fns'
import { canAddService } from 'data/permissions/permissionsSelectors'
import { HHFormSwitchField, HHTextField, HHFormSelectField } from '../../../form-fields/v5'

const DATE_FORMAT = 'M/d'
const STATUS_OPTIONS = ['Active', 'Deactivated']

const Header = ({ label, onLabelClick = noop, onNewServiceClick = noop, isExpanded = false }) => {
  const addService = useSelector(canAddService, shallowEqual)
  const theme = useTheme()
  const { control, watch, setValue, getValues } = useFormContext()
  const upcoming = watch('upcoming')
  const handleAddDays = () => {
    const startDate = getValues('startDate')
    setValue('startDate', addDays(startDate, 7))
  }
  const handleSubDays = () => {
    const startDate = getValues('startDate')
    setValue('startDate', subDays(startDate, 7))
  }
  return (
    <Grid container alignItems="flex-start">
      <Grid item container justifyContent="start" alignItems="center" flexDirection="row" xs="auto">
        {isExpanded ? (
          <ArrowDropDown cursor="pointer" onClick={onLabelClick} color="disabled" />
        ) : (
          <ArrowRight cursor="pointer" onClick={onLabelClick} color="disabled" />
        )}

        <Typography ml={1} variant="h6" fontWeight={500} onClick={onLabelClick} noWrap sx={{ cursor: 'pointer' }}>
          {label}
        </Typography>
        <HHFormSelectField
          sx={{ ml: 2, width: '8rem' }}
          size="small"
          variant="outlined"
          getOptionLabel={x => x}
          getOptionValue={x => x}
          options={STATUS_OPTIONS}
          control={control}
          name="status"
          multiple
        />
      </Grid>
      <Grid item container xs justifyContent="end" alignItems="center" flexDirection="row">
        <Typography sx={{ mr: 1 }}>Week</Typography>
        <HHFormSwitchField
          onColor={theme.palette.textPrimary.main}
          offColor={theme.palette.textPrimary.main}
          uncheckedIcon={false}
          checkedIcon={false}
          label="Upcoming"
          control={control}
          name="upcoming"
        />
        {!upcoming && (
          <Box display="flex" alignItems="center" justifyContent="between" ml={1} mt={0.2}>
            <Button
              sx={{ ml: 0.5, minWidth: 'unset', px: 0 }}
              size="small"
              variant="outlined"
              color="textSecondary"
              onClick={handleSubDays}
            >
              <ChevronLeft color="inherit" />
            </Button>
            <HHTextField
              size="small"
              readOnly
              sx={{ ml: 0.5, width: '7rem' }}
              value={`${format(startOfWeek(watch('startDate')), DATE_FORMAT)} - ${format(endOfWeek(watch('startDate')), DATE_FORMAT)} `}
            />
            <Button
              sx={{ ml: 0.5, minWidth: 'unset', px: 0 }}
              size="small"
              variant="outlined"
              color="textSecondary"
              onClick={handleAddDays}
            >
              <ChevronRight color="inherit" />
            </Button>
          </Box>
        )}
        {addService && (
          <Button
            sx={{ ml: 1, whiteSpace: 'nowrap', minWidth: 'max-content' }}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={onNewServiceClick}
          >
            {T.NEW_SERVICE}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  label: PropTypes.string,
  onLabelClick: PropTypes.func,
  onNewServiceClick: PropTypes.func,
  isExpanded: PropTypes.bool,
}

export default Header
