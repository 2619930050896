import { React, useReducer, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { Stack, Typography, Alert, Box } from '@mui/material'
import { X as Close } from '@styled-icons/heroicons-solid/X'

import { app } from 'providers/firebaseAuth'
import { firebaseErrorHandler } from 'utils/error'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword as updatePasswordFirebase } from 'firebase/auth'
import { putIsLoading } from 'middleware/actions/response'
import { PASSWORD_MIN_LENGTH } from 'settings/constants/auth'

import T from 'T'
import Constants from 'Constants'
import CommonTextfield from 'components/common/CommonTextfield'
import CommonTextLabel from 'components/common/CommonTextLabel'
import BasicModal from 'components/modal/BasicModal'
import FooterButton from 'components/buttons/FooterButton'

import './style.scss'

const { MEDIA_SERVER } = Constants

const ChangePassword = ({ passwordModal, onClose }) => {
  const auth = getAuth(app)
  const currentUser = auth?.currentUser
  const dispatch = useDispatch()

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [response, setResponse] = useState({ type: '', message: '' })

  const { oldPassword, newPassword, confirmPassword } = localState

  const minPasswordLength = PASSWORD_MIN_LENGTH

  const isDisabledProceed =
    !oldPassword || !newPassword || !confirmPassword || newPassword.length < minPasswordLength || newPassword !== confirmPassword

  const onHandleChange = event => {
    const { name, value } = event.target

    setLocalState({ [name]: value })
  }

  const handleReset = () => {
    setLocalState({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
  }

  const handleResetMessage = () => {
    setResponse({ type: '', message: '' })
  }

  const handleSaveFirebase = async () => {
    dispatch(putIsLoading(true))
    setResponse({ type: '', message: '' })
    auth.tenantId = currentUser.tenantId
    const credentials = EmailAuthProvider.credential(currentUser.email, oldPassword)
    reauthenticateWithCredential(currentUser, credentials)
      .then(() => {
        updatePasswordFirebase(currentUser, newPassword)
          .then(() => {
            setResponse({ type: 'success', message: T.PASSWORD_CHANGED_SUCCESSFULLY })
            handleReset()
          })
          .catch(error => setResponse({ type: 'error', message: firebaseErrorHandler(error) }))
          .finally(() => dispatch(putIsLoading(false)))
      })
      .catch(error => {
        setResponse({ type: 'error', message: firebaseErrorHandler(error) })
        dispatch(putIsLoading(false))
      })
  }

  useEffect(() => {
    handleReset()
    handleResetMessage()
  }, [passwordModal])

  return (
    <BasicModal isOpen={passwordModal} onClose={() => onClose()} className="">
      <Box className="change-password" width={375}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" className="header common-padding-lr-3-5 bg-white">
          <Stack flexDirection="row" columnGap={1} alignItems="center">
            <img src={`${MEDIA_SERVER}ChangePassword.svg`} alt="" className="icon-w-16" />
            <Typography component="span" className="text">
              {T.CHANGE_PASSWORD}
            </Typography>
          </Stack>
          <div className="close-icon cursor-pointer" onClick={() => onClose()}>
            <Close />
          </div>
        </Stack>

        <div className="status-info">
          {response.message && (
            <Alert sx={{ m: 2, display: 'flex', alignItems: 'center' }} severity={response.type}>
              {response.message}
            </Alert>
          )}
        </div>

        <form className="change-password-form" onClick={handleResetMessage}>
          <CommonTextLabel title="Current Password" isRequired />

          <CommonTextfield
            name="oldPassword"
            value={oldPassword}
            type="password"
            placeholder="Enter Password"
            onChange={event => {
              onHandleChange(event)
            }}
          />
          <CommonTextLabel className="change-password-label" title="New Password" isRequired />
          <CommonTextfield
            name="newPassword"
            value={newPassword}
            type="password"
            placeholder="Enter Password"
            onChange={event => onHandleChange(event)}
          />

          <div className="min-characters">
            <div className={`circle ${newPassword.length >= minPasswordLength ? 'green-circle' : ''}`} />
            {T.PASSWORD_LENGTH_MSG}
          </div>

          <CommonTextLabel className="change-password-label mt40" title="Confirm Password" isRequired />
          <CommonTextfield
            name="confirmPassword"
            value={confirmPassword}
            type="password"
            placeholder="Enter Password"
            onChange={event => onHandleChange(event)}
          />

          <div className="min-characters">
            <div
              className={`circle ${confirmPassword.length >= minPasswordLength && newPassword === confirmPassword ? 'green-circle' : ''}`}
            />
            {T.BOTH_PASSWORDS_MATCH}
          </div>
        </form>

        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" className="mt3-5 footer-section">
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={onClose}
            rightButtonTitle={T.SAVE_CHANGES}
            disabledProceed={isDisabledProceed}
            onProceed={handleSaveFirebase}
          />
        </Stack>
      </Box>
    </BasicModal>
  )
}

ChangePassword.defaultProps = {
  passwordModal: false,
  onClose: null,
}

ChangePassword.propTypes = {
  passwordModal: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ChangePassword
