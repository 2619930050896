import React, { Children } from 'react'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Button, DialogContent, Divider, List } from '@mui/material'
import { get } from 'utils/lodash'
import { setChartSummaryDialogState } from 'slices/groups/PaymentGroupsSlice'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import SummaryRow from './SummaryRow'
import SummaryHeader from './SummaryHeader'

const ChartSummaryDialog = () => {
  const dispatch = useDispatch()
  const chartSummaryDialog = useSelector(state => get(state, 'PaymentGroups.chartSummaryDialog', {}), shallowEqual)
  const { isOpen = false, color = '', label = '', value = 0, total = 0, rows = [], isBatch = false } = chartSummaryDialog

  const handleClose = () =>
    dispatch(setChartSummaryDialogState({ isOpen: false, color: '', label: '', value: 0, total: 0, rows: [], isBatch: false }))

  return (
    <HHBaseDialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={T.SUMMARY} onClose={handleClose} />
      <DialogContent>
        <List dense disablePadding>
          <SummaryHeader color={color} label={label} value={value} total={total} isBatch={isBatch} rows={rows} />
          {Children.toArray(
            rows.map(({ customerName, accountNumber, accountId, transactionAmountCents, checkNum, isIndividualPayment, batchId }) => {
              const checkValue = checkNum ? `Check# ${checkNum} ` : null
              return (
                <>
                  <Divider />
                  <SummaryRow
                    customerName={customerName}
                    accountNumber={accountNumber}
                    accountId={accountId}
                    transactionAmountCents={transactionAmountCents}
                    isIndividualPayment={isIndividualPayment}
                    batchId={batchId}
                    checkValue={checkValue}
                  />
                </>
              )
            })
          )}
        </List>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" onClick={handleClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

export default ChartSummaryDialog
