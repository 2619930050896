import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tooltip, Chip, useTheme } from '@mui/material'

import Tag from 'components/tags/Tag'
import EditTagsPopup from 'components/tags/EditTagsPopup'
import noop from 'lodash/noop'
import T from 'T'
import toPairs from 'lodash/toPairs'
import pick from 'lodash/pick'
import { get } from 'utils/lodash'
import { TAG_TYPE_ACCOUNT, TAG_TYPE_LOCATION, TAG_TYPE_SERVICE } from 'slices/tags/tagsSlice'
import { getEnabledTypes, getTagsGroupInfo, addParentIdToTagGroupTypes } from 'data/tags/tagsDataMappingSelector'

const SIZE_CHIP_SMALL = 'small'
const SIZE_CHIP_MEDIUM = 'medium'
const TAG_TYPES_LABELS = {
  [TAG_TYPE_ACCOUNT]: T.ACCOUNT,
  [TAG_TYPE_LOCATION]: T.LOCATION,
  [TAG_TYPE_SERVICE]: T.SERVICE,
}

const getTooltipText = ({ enabledGroups = [] }) => enabledGroups.map(accountType => get(TAG_TYPES_LABELS, [accountType])).join(', ')

const InteractiveTagsList = ({
  data,
  disableAccount = false,
  disableLocation = false,
  disableService = false,
  disableTooltip = false,
  limit = 0,
  onOpen = noop,
  onSave = noop,
  size = SIZE_CHIP_SMALL,
  sx,
  tagsFlexWrap = 'wrap',
  TagGridItemProps = { xs: 'auto' },
  RemainingTagsGridItemProps = { xs: 'auto' },
  GridContainerProps = { direction: 'row', justifyContent: 'flex-start', alignItems: 'center', spacing: 0.5 },
  tagMaxWidth = 200,
  isViewOnly = false,
}) => {
  const theme = useTheme()
  const container = useRef(null)
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const enabledTagTypes = getEnabledTypes({ disableAccount, disableLocation, disableService })

  // display only active tags
  const selectedTagsByType = get(data, 'tagDetails', [])
  const enabledSavedTags = pick(selectedTagsByType, enabledTagTypes)
  const filteredSavedTags = addParentIdToTagGroupTypes(data, enabledSavedTags)

  // add enabledGroups prop to each tags object indicating the assigned tag groups ['forAccount' and or 'forLocation' and or 'forService']
  const allSelectedTags = toPairs(filteredSavedTags)
    .map(([type, { tags }]) =>
      tags.map(tag => ({
        ...tag,
        enabledGroups: type,
      }))
    )
    .flat()
  const allSelectedTagsWithTagsGroupInfo = getTagsGroupInfo(allSelectedTags)

  // determine if we need to limit teh amount of tags displayed
  const shouldLimitTagsCount = limit > 0 && limit < allSelectedTagsWithTagsGroupInfo.length
  const normalizedTags = shouldLimitTagsCount ? allSelectedTagsWithTagsGroupInfo.slice(0, limit) : allSelectedTagsWithTagsGroupInfo
  const remainingTagsCount = shouldLimitTagsCount ? Math.floor(allSelectedTagsWithTagsGroupInfo.length - normalizedTags.length) : 0
  const showAddMoreTagsChip = (!shouldLimitTagsCount || remainingTagsCount === 0) && normalizedTags.length > 0
  const onSaveTagsCallback = () => setIsEditPopupOpen(false)

  const onEditTagsOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    if (isViewOnly) return
    // the parent entity id [RouteId, Service, Location]
    const parentId = get(data, 'id')

    setIsEditPopupOpen(true)
    onOpen(parentId)
  }

  const onEditTagsClose = e => {
    setIsEditPopupOpen(false)
  }

  const onSaveTags = () => {
    onSave(data, onSaveTagsCallback)
  }

  return (
    <>
      <Grid container ref={container} wrap={tagsFlexWrap} {...GridContainerProps}>
        {!isViewOnly && !allSelectedTags.length && (
          <Grid item>
            <Chip
              size="small"
              label="+ add a tag"
              variant="outlined"
              sx={{
                borderStyle: 'dashed',
                borderWidth: 1.5,
                '& .MuiChip-label': { color: theme.palette.text.secondary, textWeight: 'bold', overflow: 'inherit' },
              }}
              onClick={onEditTagsOpen}
            />
          </Grid>
        )}
        {normalizedTags.map((tag, index) => {
          if (!tag || !tag.tagName) {
            return false
          }
          const keyBuilder = ({ tag, index }) => {
            const tagName = get(tag, 'tagName')
            const accountId = get(data, 'accountId')
            const locationId = get(data, 'locationId')
            const configuredServiceId = get(data, 'configuredServiceId')
            const id = get(data, 'id')
            return [id, accountId, locationId, configuredServiceId, tagName, index, 'TagChip'].filter(Boolean).join('-')
          }
          return (
            <Grid item {...TagGridItemProps} key={keyBuilder({ index, tag })}>
              <Tooltip title={disableTooltip ? '' : getTooltipText(tag)}>
                <div>
                  <Tag maxWidth={tagMaxWidth} tag={tag} size={size} onClick={onEditTagsOpen} />
                </div>
              </Tooltip>
            </Grid>
          )
        })}
        {!isViewOnly && shouldLimitTagsCount && (
          <Grid item {...RemainingTagsGridItemProps}>
            {remainingTagsCount && (
              <Chip
                size="small"
                label={`+${remainingTagsCount}`}
                sx={{
                  borderRadius: 1,
                  '& .MuiChip-label': { overflow: 'inherit' },
                }}
                onClick={onEditTagsOpen}
              />
            )}
          </Grid>
        )}
        {!isViewOnly && showAddMoreTagsChip && (
          <Grid item>
            <Chip
              size="small"
              label="+"
              variant="outlined"
              sx={{
                borderStyle: 'dashed',
                borderWidth: 1.5,
                '& .MuiChip-label': { color: theme.palette.text.secondary, textWeight: 'bold', overflow: 'inherit' },
              }}
              onClick={onEditTagsOpen}
            />
          </Grid>
        )}
      </Grid>
      {isEditPopupOpen && (
        <EditTagsPopup
          open={isEditPopupOpen}
          onClose={onEditTagsClose}
          onSave={onSaveTags}
          onOpen={onEditTagsOpen}
          name={get(data, 'name') || get(data, 'accountName', '')}
          defaultSelectedTags={filteredSavedTags}
          disableAccount={disableAccount}
          disableLocation={disableLocation}
          disableService={disableService}
        />
      )}
    </>
  )
}

InteractiveTagsList.propTypes = {
  data: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    accountName: PropTypes.string,
    configuredServiceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locationId: PropTypes.string.isRequired,
    name: PropTypes.string,
    tagDetails: PropTypes.shape({
      [TAG_TYPE_ACCOUNT]: PropTypes.array,
      [TAG_TYPE_LOCATION]: PropTypes.array,
      [TAG_TYPE_SERVICE]: PropTypes.array,
    }).isRequired,
  }).isRequired,
  disableAccount: PropTypes.bool,
  disableLocation: PropTypes.bool,
  disableService: PropTypes.bool,
  disableTooltip: PropTypes.bool,
  limit: PropTypes.number,
  onOpen: PropTypes.func,
  onSave: PropTypes.func,
  showTagPlaceholder: PropTypes.bool,
  size: PropTypes.oneOf([SIZE_CHIP_SMALL, SIZE_CHIP_MEDIUM]),
  sx: PropTypes.object,
  tagsFlexWrap: PropTypes.oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  TagGridItemProps: PropTypes.object,
  RemainingTagsGridItemProps: PropTypes.object,
  tagMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  GridContainerProps: PropTypes.object,
  isViewOnly: PropTypes.bool,
}

export default InteractiveTagsList
