import React from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'

import { Table, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { PAGINATION } from 'settings/constants/pagination'
import { BillingTableContainer } from 'components/billing/BillingCommonMethods'
import ReportCommonTableHeader from 'components/reports/ReportCommonTableHeader'
import ReportCommonPagination from 'components/billing/BillingCommonPagination'
import ReportTableBody from './ReportTableBody'

import './style.scss'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

const ReportTable = ({
  allFilters = {},
  allSortBy = {},
  columns = [],
  lockedColumns = [],
  allTableRows = [],
  page = INITIAL_PAGE,
  rowsPerPage = ROWS_PER_PAGE,
  totalTableRowsCount = 0,
  totalPageCount = 0,
  onTableFilterChange,
  onSortByChange,
  onPageChange,
  onRowsPerPageChange,
  getTabData,
}) => {
  const { isLoading } = useSelector(
    state => ({
      isLoading: get(state, 'ResponseReducer.isLoading', false),
    }),
    shallowEqual
  )

  return (
    <Box p="23px 20px 0" bgcolor="complete.light">
      <BillingTableContainer className="custom-scroll-x tabpanel-body">
        <Table stickyHeader aria-label="sticky table">
          {/* Pass old state until we press done */}
          <ReportCommonTableHeader
            checkboxKey="accountId"
            columns={columns}
            allFilters={allFilters}
            allSortBy={allSortBy}
            onTableFilterChange={onTableFilterChange}
            onSortByChange={onSortByChange}
            lockedColumns={lockedColumns}
          />

          <ReportTableBody
            isLoading={isLoading}
            columns={columns}
            allTableRows={allTableRows}
            lockedColumns={lockedColumns}
            refreshTable={getTabData}
          />
        </Table>
      </BillingTableContainer>

      <ReportCommonPagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  )
}

ReportTable.propTypes = {
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onTableFilterChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  getTabData: PropTypes.func.isRequired,
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  allTableRows: PropTypes.array,
}

export default ReportTable
