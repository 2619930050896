import { TablePagination, styled } from '@mui/material'

const HHTablePaginationV5 = styled(TablePagination)(({ theme }) => ({
  '& .MuiInputBase-root': {
    order: 3,
    background: theme.palette.background.paper,
    border: '1px solid #dedfe2',
    borderRadius: 4,
  },
  '& .MuiTablePagination-toolbar': {
    minHeight: 48,
  },
  '& .MuiToolbar-gutters': {
    paddingLeft: 0,
  },
  '& .MuiTablePagination-displayedRows': {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontWeight: '500',
    order: 1,
    marginRight: 16,
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  '& .MuiTablePagination-selectLabel': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    order: 2,
    marginRight: 8,
  },
}))

export default HHTablePaginationV5
