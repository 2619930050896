import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import StyledAutocomplete from 'components/form-fields/v5/StyledAutocomplete'
import SearchTypeField from 'components/locations/filters/Search/SearchTypeField'
import { HHTextField } from 'components/form-fields/v5'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import {
  PHONE_SEARCH_TYPE,
  RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
} from 'components/locations/filters/Search/settings'
import { get, noop, flatMap, find } from 'lodash'
import SearchListbox from 'components/locations/filters/Search/SearchListbox'
import SearchOptionListHeader from 'components/locations/filters/Search/SearchOptionListHeader'
import NoOptionsFound from 'components/locations/filters/Search/NoOptionsFound'
import { combineAddressInfo, formatContactName } from 'utils/helper'
import SearchCustomerOption from 'components/customers/search-customer-bar/SearchCustomerOption'
import { INPUT_MASK_REGEX } from 'settings/constants/customer'
import MaskedInput from 'react-text-mask/dist/reactTextMask'
import CloseIcon from '@mui/icons-material/Close'

const getOutlineInputSx = color => ({
  '& fieldset': {
    borderColor: color,
  },
  '&:hover fieldset': {
    borderColor: color,
  },
  '&:focus fieldset': {
    borderColor: color,
  },
  '&:focus-within fieldset': {
    borderColor: color,
  },
})

const PhoneInput = React.forwardRef((props, ref) => {
  return (
    <MaskedInput
      {...props}
      mask={INPUT_MASK_REGEX}
      render={(textMaskRef, innerProps) => (
        <input
          {...props}
          {...innerProps}
          ref={node => {
            textMaskRef(node)
            ref(node)
          }}
        />
      )}
    />
  )
})

const SearchAutocomplete = ({
  loading = false,
  onChange = noop,
  onChangeSearchType = noop,
  onEnterPress = noop,
  onInputChange = noop,
  onSelectFreeEntry = noop,
  options = [],
  label = '',
  placeholder = '',
  popperSx,
  paperSx,
  showRecentlyMenuOptions = false,
  ...rest
}) => {
  const inputRef = useRef()
  const [open, setOpen] = useState(false)
  const { control, setValue, watch } = useFormContext()
  const searchInputValue = watch('searchInputValue')
  const searchType = watch('searchType')
  const searchValue = watch('searchValue')

  const hasValue = useMemo(() => searchInputValue !== '', [searchInputValue])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const outlineInputSx = getOutlineInputSx(theme.palette.primary.main)

  const filterOptions = option => option
  const getOptionLabel = option => get(option, 'accountName', '')

  const openOptionList = () => {
    setOpen(true)
  }
  const closeOptionList = () => {
    setOpen(false)
  }

  const debouncedFocus = () =>
    setTimeout(() => {
      inputRef.current.focus()
    }, 300)

  const handleInputChange = useCallback(
    (e, value, reason) => {
      onInputChange(e, value, reason)
      if (reason !== 'reset') {
        setValue('searchInputValue', value)
      }
      if (value && !open) {
        openOptionList()
      }
    },
    [onInputChange, open, setValue]
  )

  const handleChange = (event, item, reason) => {
    onChange(event, item, reason)
    if (reason === 'clear') {
      inputRef.current.blur()
    }
    event.stopPropagation()
  }

  const handleChangeSearchType = value => {
    onChangeSearchType(value)
    debouncedFocus()
  }

  const handleSelectFreeEntry = useCallback(() => {
    onSelectFreeEntry(searchInputValue, closeOptionList)
  }, [searchInputValue, onSelectFreeEntry])

  const handleClearButtonClick = () => {
    setValue('searchValue', '')
    setValue('search', '')
    setValue('searchInputValue', '')
  }

  const isRecentlyViewedOptionSelected = useMemo(() => searchType === RECENT_ACCOUNT_VIEWED_SEARCH_TYPE, [searchType])
  const isRecentlyAddedOptionSelected = useMemo(() => searchType === RECENT_ACCOUNT_ADDED_SEARCH_TYPE, [searchType])

  const Icon = useMemo(() => {
    if (isRecentlyViewedOptionSelected) {
      return VisibilityOutlinedIcon
    }
    if (isRecentlyAddedOptionSelected) {
      return PersonAddAltOutlinedIcon
    }
    return SearchIcon
  }, [isRecentlyViewedOptionSelected, isRecentlyAddedOptionSelected])

  const isDisabled = useMemo(
    () => isRecentlyViewedOptionSelected || isRecentlyAddedOptionSelected,
    [isRecentlyViewedOptionSelected, isRecentlyAddedOptionSelected]
  )

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value) {
        inputRef.current.select()
      } else {
        inputRef.current.focus()
      }
    }
  }, [])

  return (
    <Grid container alignItems="center" flexWrap="nowrap">
      <Grid item xs>
        <StyledAutocomplete
          disabled={isDisabled}
          disableClearable
          selectOnFocus
          handleHomeEndKeys
          forcePopupIcon={false}
          fullWidth
          open={open && hasValue}
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          clearOnBlur={false}
          options={options}
          value={searchValue}
          inputValue={searchInputValue}
          loading={loading}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 0],
                  },
                },
              ],
              sx: {
                [`& .${autocompleteClasses.noOptions}`]: {
                  pt: 0,
                  px: 0,
                },
                ...popperSx,
              },
            },
            paper: {
              sx: {
                '&': {
                  ...(!hasValue && { boxShadow: 'none', border: 0 }),
                  ...paperSx,
                },
              },
            },
          }}
          onChange={handleChange}
          onInputChange={handleInputChange}
          ListboxComponent={p => <SearchListbox {...p} onClick={handleSelectFreeEntry} />}
          renderInput={p => {
            const handleBlur = () => {
              closeOptionList()
            }

            const handleFocus = () => {
              openOptionList()
            }
            const getInputRef = ref => {
              inputRef.current = ref
            }

            const handleKeyDown = event => {
              const { code } = event
              if (['Enter', 'NumpadEnter'].includes(code)) {
                event.preventDefault()
                event.stopPropagation()
                onEnterPress(searchInputValue, closeOptionList)
              }
            }
            return (
              <HHTextField
                autoFocus
                onBlur={handleBlur}
                onFocus={handleFocus}
                fullWidth
                {...p}
                onKeyDown={handleKeyDown}
                inputRef={getInputRef}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    ...outlineInputSx,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    '&.Mui-disabled': {
                      background: theme.palette.action.hover,
                      input: {
                        '&.Mui-disabled': {
                          '-webkit-text-fill-color': theme.palette.text.primary,
                        },
                      },
                    },
                  },
                }}
                label={hasValue ? label : undefined}
                placeholder={!hasValue && placeholder}
                deprecatedLabel={false}
                InputProps={{
                  ...p.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment sx={{ ...(!searchValue && { display: 'none' }) }} position="end">
                      <IconButton onClick={handleClearButtonClick}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  ...(searchType === PHONE_SEARCH_TYPE && { inputComponent: PhoneInput }),
                }}
              />
            )
          }}
          noOptionsText={
            <>
              <SearchOptionListHeader onClick={handleSelectFreeEntry} />
              <NoOptionsFound />
            </>
          }
          renderOption={(params, option) => {
            const contacts = option?.contacts || option?.contactList || []
            const allContactMethods = flatMap(contacts, 'contactMethods')
            const method = find(allContactMethods, { methodType: 'Phone' })
            const phone = get(method, 'methodValue')
            const accountName = get(option, 'accountName', get(option, 'customerName', ''))
            const activeLocation = get(option, 'locations', []).find(location => location?.locActive)
            const address = combineAddressInfo(
              get(activeLocation, 'address') || get(option, 'locations[0].address') || get(option, 'address')
            )
            const billingAddress = get(option, 'billingAddress')
            const billingProfile = get(option, 'billingProfile', get(option, 'customerBillingProfile', ''))
            const contactName = formatContactName(get(contacts, '[0]'))
            const accountNumber = get(option, 'accountNumber', '').toString()

            return (
              <SearchCustomerOption
                key={option.id}
                {...params}
                phone={phone}
                accountName={accountName}
                address={address || billingAddress}
                billingProfile={billingProfile}
                contactName={contactName}
                accountNumber={accountNumber}
              />
            )
          }}
          {...rest}
        />
      </Grid>
      <Grid item>
        <SearchTypeField
          showRecentlyMenuOptions={showRecentlyMenuOptions}
          sx={{
            minWidth: isMobile ? '155.58px' : '9.5rem',
            width: '9.5rem',
            maxWidth: '9.5rem',
            '& .MuiOutlinedInput-root': { ...outlineInputSx, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
          }}
          onChange={handleChangeSearchType}
          control={control}
        />
      </Grid>
    </Grid>
  )
}

SearchAutocomplete.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeSearchType: PropTypes.bool,
  onEnterPress: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectFreeEntry: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  popperSx: PropTypes.object,
  paperSx: PropTypes.object,
  showRecentlyMenuOptions: PropTypes.bool,
}

export default SearchAutocomplete
