import React from 'react'
import { Card, CardContent, Grid, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const CustomCardContent = styled(CardContent)(() => ({
  padding: '8px 12px',
  '&:last-child': {
    paddingBottom: 8,
  },
}))

const CustomSkeleton = ({ xs }) => (
  <Grid item xs={xs}>
    <Skeleton variant="text" />
  </Grid>
)

CustomSkeleton.propTypes = {
  xs: PropTypes.number,
}

const LoadingServiceCard = () => {
  return (
    <Card variant="outlined">
      <CustomCardContent>
        <Grid container item justifyContent="space-between" wrap="nowrap" xs={12}>
          <CustomSkeleton xs={4} />
          <CustomSkeleton xs={2} />
        </Grid>
        <Grid container item justifyContent="space-between" xs={12}>
          <CustomSkeleton xs={8} />
          <CustomSkeleton xs={2} />
        </Grid>
        <Grid container item alignItems="center" xs={12}>
          <CustomSkeleton xs={8} />
        </Grid>
      </CustomCardContent>
    </Card>
  )
}

export default LoadingServiceCard
