/* eslint-disable import/no-webpack-loader-syntax,import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import { Box, styled } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import EndingYardMarker from 'assets/route_manager/yards_preview/EndingYardMarkerIcon.png'
import BlueCircleOutlined from 'assets/route_manager/yards_preview/BlueCircleOutlined.png'
import StartingYardMarker from 'assets/route_manager/yards_preview/StartingYardMarkerIcon.png'
import { grey } from '@mui/material/colors'
import { get } from 'utils/lodash'
import {
  addAssetsImageToMap,
  calculateBounds,
  getFeatureLineStringFromCoordinates,
  getFeaturePointCollectionFromStops,
  getFeaturePointFromCoordsObj,
  mapStopsToCoordinatesArray,
} from 'utils/map'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import mapboxgl from '!mapbox-gl'

const { MAP_VIEW_URL, LIGHT_VIEW, PROJECTION } = MAP_DEFAULT_OPTIONS

const MapContainer = styled('div')({
  height: '100%',
  width: '100%',
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: grey[300],
})

const pointsLayer = {
  id: 'points',
  type: 'symbol',
  source: 'markers',
  layout: {
    'icon-image': ['get', 'icon'],
  },
}

const routeLayer = {
  id: 'routes',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#95C9FF',
    'line-width': 1,
  },
}

const isValidCoordArray = coords => Array.isArray(coords) && coords.length === 2

const getFeaturePointFromCoordArray = (coords, properties) => {
  if (isValidCoordArray(coords))
    return getFeaturePointFromCoordsObj({
      longitude: coords[0],
      latitude: coords[1],
      properties: { ...properties, id: 'ending-yard-marker' },
    })
  return null
}
const StopsMapPreview = ({ stops, startingYardCoords, endingYardCoords }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const featureStopsPointCollection = getFeaturePointCollectionFromStops({
    stops,
    properties: {
      icon: 'stop-marker',
    },
  })
  const coordinates = mapStopsToCoordinatesArray(stops)
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  const mapContainer = useRef(null)
  const map = useRef(null)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `${MAP_VIEW_URL}${LIGHT_VIEW}`,
      center: [-98.5795, 39.82835],
      zoom: 1,
      showPadding: true,
      projection: PROJECTION,
    })
    map.current.addControl(new mapboxgl.FullscreenControl())
  })

  useEffect(() => {
    if (map.current && featureStopsPointCollection && coordinates) {
      const mapCurrentRef = map.current
      mapCurrentRef.on('load', () => {
        addAssetsImageToMap(mapCurrentRef, 'stop-marker', BlueCircleOutlined)
        addAssetsImageToMap(mapCurrentRef, 'starting-yard-marker', StartingYardMarker)
        addAssetsImageToMap(mapCurrentRef, 'ending-yard-marker', EndingYardMarker)
        // set marker source
        const startingYardFeaturePoint = getFeaturePointFromCoordArray(startingYardCoords, {
          icon: 'starting-yard-marker',
          id: 'starting-yard-marker',
        })
        const endingYardFeaturePoint = getFeaturePointFromCoordArray(endingYardCoords, {
          icon: 'ending-yard-marker',
          id: 'ending-yard-marker',
        })
        const stopFeatures = get(featureStopsPointCollection, 'features', [])
        const features = [startingYardFeaturePoint, ...stopFeatures, endingYardFeaturePoint].filter(Boolean)
        featureStopsPointCollection.features = features
        mapCurrentRef.addSource('markers', { type: 'geojson', data: featureStopsPointCollection })
        mapCurrentRef.addLayer(pointsLayer)
        // set route source
        const updatedCoordsArray = [startingYardCoords, ...coordinates, endingYardCoords].filter(Boolean)
        const newFeatureLineString = getFeatureLineStringFromCoordinates(updatedCoordsArray)
        mapCurrentRef.addSource('route', { type: 'geojson', data: newFeatureLineString })
        mapCurrentRef.addLayer(routeLayer)
        if (Array.isArray(coordinates) && coordinates.length > 0) {
          const bounds = calculateBounds(coordinates)
          mapCurrentRef.fitBounds(bounds, {
            padding: { top: 25, bottom: 25, left: 25, right: 25 },
            linear: true,
          })
        }
        setIsMapLoaded(true)
      })
    }
  }, [map, featureStopsPointCollection, coordinates])

  useEffect(() => {
    const mapCurrentRef = map.current
    const markersSource = mapCurrentRef.getSource('markers')
    const routeSource = mapCurrentRef.getSource('route')
    if (!isMapLoaded) return
    if (startingYardCoords || endingYardCoords) {
      // update marker source
      const startingYardFeaturePoint = getFeaturePointFromCoordArray(startingYardCoords, {
        icon: 'starting-yard-marker',
        id: 'starting-yard-marker',
      })
      const endingYardFeaturePoint = getFeaturePointFromCoordArray(endingYardCoords, {
        icon: 'ending-yard-marker',
        id: 'ending-yard-marker',
      })

      const stopFeatures = get(featureStopsPointCollection, 'features', [])
      const features = [startingYardFeaturePoint, ...stopFeatures, endingYardFeaturePoint].filter(Boolean)
      markersSource.setData({
        type: 'FeatureCollection',
        features,
      })
      // update route source
      const updatedCoordsArray = [startingYardCoords, ...coordinates, endingYardCoords].filter(Boolean)
      const newFeatureLineString = getFeatureLineStringFromCoordinates(updatedCoordsArray)
      routeSource.setData(newFeatureLineString)
      if (Array.isArray(updatedCoordsArray) && updatedCoordsArray.length > 0) {
        const bounds = calculateBounds(updatedCoordsArray)
        mapCurrentRef.fitBounds(bounds, {
          padding: { top: 25, bottom: 25, left: 25, right: 25 },
          linear: true,
        })
      }
    }
  }, [isMapLoaded, map, startingYardCoords, endingYardCoords, featureStopsPointCollection])

  return (
    <Box height="210px" width="260px" ml={2} mr={6}>
      <MapContainer ref={mapContainer} />
    </Box>
  )
}

StopsMapPreview.propTypes = {
  stops: PropTypes.array,
  startingYardCoords: PropTypes.array,
  endingYardCoords: PropTypes.array,
}

export default StopsMapPreview
