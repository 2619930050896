import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getPaymentBatchDetail: build.query({
      query: batchId => ({
        url: `/api/v1/billing/payment/batch/${batchId}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetPaymentBatchDetailQuery } = extendedApi
