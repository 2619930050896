import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingCreateInvoiceList: build.query({
      query: body => ({
        url: '/api/v1/billing/invoice/billing-periods/accounts/list',
        method: 'POST',
        body,
      }),
      transformResponse: data => {
        const { customers, totalItems, totalPages } = data
        return {
          tableRows: customers,
          totalItems,
          totalPages,
        }
      },
    }),
  }),
})

export const { useGetBillingCreateInvoiceListQuery, useLazyGetBillingCreateInvoiceListQuery } = extendedApi
