import React, { useEffect, useState, useReducer } from 'react'
import moment from 'moment'
import { isEmpty, isObject, noop } from 'lodash'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { get } from 'utils/lodash'
import { DragDropContext } from 'react-beautiful-dnd'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Box, Snackbar } from '@mui/material'
import T from 'T'
import RouteFiltersMenu from 'components/route-manager/filters/RouteFiltersMenu'

import { selectRoutes, selectRouteByRouteId, getFilteredStopsByTags } from 'data/route-manager/routeSelectors'
import { selectStops, selectWorkOrderId, selectAllWorkOrderStatus } from 'data/route-manager/stopSelector'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useLazyGetRouteStopsUnassignedQuery } from 'api/route/getRouteStopsUnassigned'
import { useMoveStopsMutation } from 'api/route/moveStops'
import { putRouteDateMove } from 'middleware/actions/routeManager'
import { initiateStatusDownload } from 'middleware/actions/download'

import { extractHash } from 'utils/url'
import { handleError } from 'utils/error'

import Loader from 'components/common/loader'
import UnassignedStopsColumn from 'containers/new-route-manager/UnassignedStopsColumn'
import { getIsUnassignedStopsOpen } from 'data/route/unassignedStopsSelector'
import {
  changeViewMode,
  selectRouteSerializedServiceDate,
  setBulkMoveFailure,
  setRecentDraggedStop,
  setRecentStopsMoved,
  setSerializedServiceDate,
  setViewOnMapRouteId,
  toggleUnassignedStops,
  VIEW_MODE_MAP,
} from 'slices/route/routeSlice'
import {
  DROPPABLE_ID_UNASSIGNED,
  isDroppingOnSameColumn,
  isValidUnassignOperation,
  isAssignOperation,
} from 'data/route/routeDataMappingSelector'

import { WORK_ORDER_STATUS } from 'settings/constants/service'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS } from 'api/cacheTagTypes'
import api from 'api'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'components/common/calendar.scss'
import { useGetBulkCompleteStopsMutation } from 'api/route/getBulkCompleteStops'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { deserializeDate, formatDateToBEFormatDateFns, getDateObjectFromDateString, serializeDate } from 'utils/date'
import { FULL_PERMISSION, NO_ACCESS_PERMISSION, UPDATE_PERMISSION } from 'settings/constants/roles'
import NoRoutesEmptyState from 'components/route-manager/NoRoutesEmptyState'
import RouteDetailsHeader from 'components/route-manager/RouteDetailsModal/RouteDetailsHeader'
import RouteContextMenu from './contextMenu/RouteContextMenu'
import EditRouteSelection from './EditRouteSelection'
import RouteColumn from './board-view/RouteColumn'
import { ROUTE_COLUMN_WIDTH, ROUTE_COLUMN_HEIGHT } from './board-view/settings'
import { canMoveStopToAnotherRoute } from './helper'
import { UNASSIGNED_ROUTE } from './settings'

const { SERVICED, SKIPPED, IN_PROGRESS, SCHEDULED } = WORK_ORDER_STATUS
const IN__PROGRESS = 'In Progress'

const SequenceContainer = () => {
  const recentDraggedStopId = useSelector(s => get(s, 'Route.recentDraggedStop.id'), shallowEqual)
  const location = useLocation()
  const hashParams = extractHash(get(location, 'hash'))
  const showRS = get(hashParams, 'rs', false)
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)

  const { watch } = useFormContext()
  const selectedRoutesByTagId = watch('selectedRoutesByTagId')
  const dispatch = useDispatch()
  const [getRouteStops, { data: routesData, isFetching: isGetRouteStopsLoading }] = useLazyGetRouteStopsQuery()
  const [getRouteStopsUnassigned, { data: unassignedData, isFetching: isGetRouteStopsUnassignedLoading }] =
    useLazyGetRouteStopsUnassignedQuery()
  const [moveStops, { isLoading: isMoveStopsLoading }] = useMoveStopsMutation()

  const [getBulkCompleteStops, { isLoading: isBulkCompleteStopsLoading }] = useGetBulkCompleteStopsMutation()

  const selectedRouteIds = useSelector(s => s.Route.filters.selectedRouteIds, shallowEqual)
  const hasRoutes = Array.isArray(selectedRouteIds) && selectedRouteIds.length !== 0
  const [routeFilterMenu, setRouteFilterMenu] = useState({ anchorEl: null, routeId: '' })
  const allRouteList = hasRoutes ? selectRoutes(routesData) : []
  const routesList = isObject(selectedRoutesByTagId)
    ? getFilteredStopsByTags({ routes: allRouteList, selectedRoutesByTagId })
    : allRouteList

  const unassignedWorkOrders = selectStops(unassignedData)
  const unassignedWorkOrdersList = isObject(selectedRoutesByTagId)
    ? getFilteredStopsByTags({ routes: [{ ...UNASSIGNED_ROUTE, ...unassignedData }], selectedRoutesByTagId, isUnassignedOnly: true })
    : unassignedWorkOrders

  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const [currentDate, setNewRouteDate] = useState(new Date(serviceDate))
  const [currentRouteId, setCurrentRouteId] = useState()
  const [contextMenu, setContextMenu] = useState(null)
  const [isStopMoved, setIsStopMoved] = useState(false)
  const [currentRouteName, setCurrentRouteName] = useState('')
  const [currentRoute, setCurrentRoute] = useState(undefined)
  const [editRouteStops, setEditRouteStops] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState([])
  const [allWorkOrdersCount, setAllWorkOrdersCount] = useState('')
  const [showBulkAssignPanel, setShowBulkAssignPanel] = useState(false)
  const [showBulkCompletePanel, setShowBulkCompletePanel] = useState(false)
  const [selectedRouteStatus, setSelectedRouteStatus] = useState({})
  const [localState, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    currentDay: 0,
    isPermanentOccurrenceModal: false,
    isSameRouteChange: false,
    snackbarMessage: '',
    stopRouteRequestedPage: 0,
    stopRouteRequestedPageSize: 100000,
    updateRoutePayload: null,
    moveUnassignedStop: false,
    movedStopName: '',
    updateRoutes: false,
    sequenceDialog: false,
    isRouteUpdated: false,
  })
  const stopServiceDate = serviceDate ? moment(serviceDate).format('YYYY-MM-DD') : null
  const isOpenBulkAssign = useSelector(s => s.Route.bulkAssign.isOpen)
  const isUnassignedStopsOpen = useSelector(getIsUnassignedStopsOpen)
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  const routePemission = get(userInfo, 'profile.routeManagerCrud', NO_ACCESS_PERMISSION)
  const isDragDisabled = !(routePemission == UPDATE_PERMISSION || routePemission == FULL_PERMISSION)

  const onEditRoute = () => {
    dispatch(setSerializedServiceDate(new Date()))
  }

  const handleRecentDraggedStop = id => dispatch(setRecentDraggedStop({ id }))

  const handleRefetchUnassignedStops = () => {
    if (isUnassignedStopsOpen) {
      dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS]))
    }
  }

  const handleRefetchStops = (updateMetaApi = true) => {
    if (updateMetaApi) {
      dispatch(api.util.invalidateTags([{ type: CACHE_TAG_ROUTES_METADATA_BY_DATE, id: stopServiceDate }]))
    }
    dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES]))
  }

  const getAllStops = () => {
    if (!stopServiceDate || (Array.isArray(selectedRouteIds) && selectedRouteIds.length === 0)) {
      return
    }
    getRouteStops({ routes: selectedRouteIds, serviceDate: stopServiceDate }).unwrap().catch(handleError)
  }

  const fetchUnassignedWorkOrder = () => {
    // Don't call API if unAssignedstops drawer is closed
    if (!stopServiceDate || !isUnassignedStopsOpen) {
      return
    }

    getRouteStopsUnassigned({ serviceDate: stopServiceDate }).unwrap().catch(handleError)
  }

  const changeServiceDate = date => {
    const newServiceDate = getDateObjectFromDateString(date)
    const newSerializedServiceDate = serializeDate(newServiceDate)
    dispatch(setSerializedServiceDate(newSerializedServiceDate))
  }

  useEffect(() => {
    if (serviceDate) {
      setNewRouteDate(new Date(serviceDate))
    }
  }, [serializedServiceDate])

  useEffect(() => {
    if (isUnassignedStopsOpen && stopServiceDate) {
      fetchUnassignedWorkOrder()
    }
  }, [isUnassignedStopsOpen, stopServiceDate])

  useEffect(() => {
    if (stopServiceDate) {
      getAllStops()
    }
  }, [stopServiceDate, selectedRouteIds])

  const getWorkOrdersFromRoute = routeId => {
    const filteredRoute = selectRouteByRouteId({ routes: routesList, id: routeId })
    return { workOrdersList: selectStops(filteredRoute) }
  }

  // udpdate the workorder tags with the new selection

  const onTagsEditSave = (workOrder, onTagsSaveCallback = () => {}) => {
    const isUnassignedWorkOrder = isEmpty(workOrder?.routeId)
    onTagsSaveCallback()

    // get unassigned items after tags on a work order have been updated
    // Ideally we should listen for the update success status, and update the item directly on the cached collection item by id instead of refetching the entire list again
    // since only 1 item can be updated at a time, we should not request the full list of items from BE each time
    if (isUnassignedWorkOrder) {
      handleRefetchUnassignedStops()
    } else {
      handleRefetchStops(false)
    }
  }

  // used in order to disable reordering of unassigned items within the same unassigned column
  const [isDropDisabled, setIsDropDisabled] = useState(false)

  const callMoveStopsAPI = (payload, fromUnassigned = false, callback = noop) => {
    return moveStops({ ...payload, updateMasterSequence: !isTemporaryModeActive })
      .unwrap()
      .then(response => {
        if (fromUnassigned && !isUnassignedStopsOpen) {
          dispatch(toggleUnassignedStops(true))
        }
        handleRefetchStops()
        handleRefetchUnassignedStops()
        callback(response)
      })
      .catch(handleError)
  }

  // assigns a workorder to a destination route
  const handleAssignWorkOrder = ({ routeId, workOrderId, toIndex }) => {
    if (isEmpty(routeId) || isEmpty(workOrderId) || isNaN(Number(toIndex))) {
      return
    }

    const { workOrdersList } = getWorkOrdersFromRoute(routeId)
    const previousStop = get(workOrdersList, `[${toIndex - 1}]`, {})
    callMoveStopsAPI({
      destinationRouteId: routeId,
      serviceDate: stopServiceDate,
      stops: [workOrderId],
      targetStopId: selectWorkOrderId(previousStop),
    })
  }

  // unassigns a workorder from a route into the unassigned status
  const handleUnassignWorkOrder = ({ stops }) => {
    if (isEmpty(stops)) {
      return
    }

    callMoveStopsAPI({ stops, serviceDate: stopServiceDate }, true)
  }

  const getDragDropProps = (source, destination) => ({
    from: source?.droppableId,
    to: destination?.droppableId,
    fromIndex: source?.index,
    toIndex: destination?.index,
  })

  const handleInterIntraDrop = ({ source, destination, draggableId }) => {
    const { from, to, fromIndex, toIndex } = getDragDropProps(source, destination)
    const isInterRoute = from === to

    const { workOrdersList } = getWorkOrdersFromRoute(to)
    const previousStopIndex = isInterRoute && fromIndex < toIndex ? toIndex : toIndex - 1
    const previousStop = get(workOrdersList, `[${previousStopIndex}]`, {})

    callMoveStopsAPI({
      destinationRouteId: to,
      serviceDate: stopServiceDate,
      stops: [draggableId],
      targetStopId: selectWorkOrderId(previousStop),
    })
  }

  const onDragStart = ({ source, type: draggableType }) => {
    const from = source.droppableId

    // reordering within unassigned is disabled, disable drop on unassign column
    if (!isValidUnassignOperation(from)) {
      // unassigned items can only be dropped into other columns
      setIsDropDisabled([from, draggableType].every(id => id === DROPPABLE_ID_UNASSIGNED))
    }
  }

  const onDragEnd = ({ source, destination, draggableId }) => {
    // item was not dropped into a droppable column, exit
    if (!destination) {
      return
    }

    const { from, to, fromIndex, toIndex } = getDragDropProps(source, destination)

    // the item was dropped to the same position, exit
    if (isDroppingOnSameColumn(from, to) && fromIndex === toIndex) {
      return
    }

    handleRecentDraggedStop(draggableId)

    // assign | unassign drop
    if ([from, to].includes(DROPPABLE_ID_UNASSIGNED)) {
      // assigning item from source into route destination at given index
      if (isAssignOperation(from, to)) {
        handleAssignWorkOrder({ routeId: to, workOrderId: draggableId, toIndex })
      } else {
        // item is already unassigned exit
        if (!isValidUnassignOperation(from, to)) {
          return
        }

        // perform unassign operation
        handleUnassignWorkOrder({ stops: [draggableId] })
      }
    } else {
      // Inter | Intra drop
      handleInterIntraDrop({ source, destination, draggableId })
    }
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  const handleRouteDate = date => {
    setNewRouteDate(date)
  }

  const handleExportRoute = () => {
    const payload = {
      type: 'excel',
      fileName: 'workOrder',
      reportName: 'route',
      serviceDate: stopServiceDate,
      routeId: currentRouteId,
      isDownload: true,
      buffer: true,
      urlToMapWith: 'routeExport',
    }

    dispatch(initiateStatusDownload(payload))
    setContextMenu(null)
  }

  const getMovedRouteStatus = res => {
    try {
      if (res) {
        setState({
          isPermanentOccurrenceModal: true,
          snackbarMessage: `"${currentRouteName}" route has been moved to ${formatDateToBEFormatDateFns(currentDate)}`,
        })
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const changeRouteDate = () => {
    const res = {
      existingDate: stopServiceDate,
      routeId: currentRouteId,
      updatedDate: moment(currentDate).format('YYYY-MM-DD'),
      requestedPage: localState.stopRouteRequestedPage,
      requestedPageSize: localState.stopRouteRequestedPageSize,
    }
    if (currentRouteId) {
      dispatch(putRouteDateMove(res, getMovedRouteStatus))
      setContextMenu(null)
      handleRefetchStops()
    }
  }

  const handleViewRoute = () => {
    changeServiceDate(currentDate)
    setState({ isPermanentOccurrenceModal: false })
  }

  const getTodaysRouteDetails = route => {
    const workOrderStatus = selectAllWorkOrderStatus(route)
    if (workOrderStatus.length === 0) {
      return ''
    }

    const isInProgress = workOrderStatus.includes(SERVICED) || workOrderStatus.includes(SKIPPED) || workOrderStatus.includes(IN_PROGRESS)
    const hasScheduledOrInProgressWO = workOrderStatus.includes(SCHEDULED) || workOrderStatus.includes(IN_PROGRESS)

    if (isInProgress) {
      return hasScheduledOrInProgressWO ? IN__PROGRESS : 'Complete'
    }

    return 'Not Started'
  }

  const getPastRouteDetails = route => {
    const workOrderStatus = selectAllWorkOrderStatus(route)
    const inComplete = workOrderStatus.includes(IN_PROGRESS) || workOrderStatus.includes(SCHEDULED)
    return inComplete ? 'Incomplete' : 'Complete'
  }

  const getRouteStatus = route => {
    if (moment(serviceDate).startOf('day').unix() === moment().startOf('day').unix()) {
      return getTodaysRouteDetails(route)
    }

    if (moment(serviceDate).startOf('day').unix() < moment().startOf('day').unix()) {
      return getPastRouteDetails(route)
    }

    return 'Not Started'
  }

  const handleClick = (event, route) => {
    setCurrentRoute(route)
    const totalWorkOrders = selectStops(route).length
    // Don't use separate variables to store id, name, status etc just add complete route object and use
    setCurrentRouteId(get(route, 'id'))
    setCurrentRouteName(get(route, 'name'))
    setAllWorkOrdersCount(totalWorkOrders)
    setContextMenu(event.currentTarget)

    const routeStatus = getRouteStatus(route)
    setSelectedRouteStatus(routeStatus)
  }

  const getDriverInfo = driverInfo => {
    let driverProfilePic = null
    let driverName = null
    if (driverInfo) {
      driverProfilePic = get(driverInfo, 'profilePicUri', null)
      driverName = `${get(driverInfo, 'firstName', '').charAt(0)}${get(driverInfo, 'lastName', '').charAt(0)}`
    }
    return { driverProfilePic, driverName }
  }

  const handleViewOnMap = () => {
    dispatch(setViewOnMapRouteId(currentRouteId))
    dispatch(changeViewMode(VIEW_MODE_MAP))
  }

  const handleEditRouteStops = () => {
    setEditRouteStops(contextMenu)
    setContextMenu(null)
    setShowBulkAssignPanel(false)
    setShowBulkCompletePanel(false)
  }

  const handleBulkAssign = () => {
    setEditRouteStops(contextMenu)
    setContextMenu(null)
    setShowBulkAssignPanel(true)
  }

  const handleBulkComplete = () => {
    setEditRouteStops(contextMenu)
    setContextMenu(null)
    setShowBulkCompletePanel(true)
  }

  const handleCancelEditRouteStops = () => {
    setEditRouteStops(false)
    setIsCheckAll(false)
    setIsCheck([])
    setAllWorkOrdersCount('')
    setShowBulkAssignPanel(false)
    setShowBulkCompletePanel(false)
  }

  const handleSelectClick = e => {
    if (e?.target === undefined) return
    const { id, checked } = e.target

    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id))
    } else {
      const stopIds = [...isCheck]
      stopIds.push(id)
      setIsCheck(stopIds)
    }
    if (isCheck.length === allWorkOrdersCount) {
      setIsCheckAll(!isCheckAll)
    }
  }

  const handleSelectAll = event => {
    const { checked, id } = event.target
    if (!checked) {
      setIsCheck([])
    } else {
      const { workOrdersList } = getWorkOrdersFromRoute(id)
      const filteredWO = workOrdersList.filter(stop => canMoveStopToAnotherRoute(stop.workOrderStatus)).map(stop => stop.workOrderId)
      setIsCheck(filteredWO)
    }
    setIsCheckAll(checked)
  }

  const successBulkOperation = () => {
    setContextMenu(null)
    setEditRouteStops(false)
    setShowBulkAssignPanel(false)
    setShowBulkCompletePanel(false)
    setAllWorkOrdersCount('')
  }

  const handleUnassignRouteStops = () => {
    if (isCheck) {
      handleUnassignWorkOrder({ stops: isCheck })
      setIsCheck([])
      successBulkOperation()
    }
  }

  const resetBulkAssignStates = () => {
    window.removeEventListener('click', resetBulkAssignStates)
    setState({ isRouteUpdated: false })
    setIsCheck([])
  }

  const handleRouteUpdateStatus = status => {
    setState({ isRouteUpdated: status })
  }

  const handleBulkCompleteApi = () => {
    const payload = {
      status: SERVICED,
      workOrderIds: isCheck,
    }

    getBulkCompleteStops(payload)
      .unwrap()
      .then(() => {
        handleCancelEditRouteStops()
        getAllStops()
      })
      .catch(handleError)
  }

  const changeMostRecentMovedStops = ({ stops, routeId, failedStops = [] }) => {
    const nonFailedStops = stops.filter(stop => {
      return !failedStops.includes(stop.id)
    })
    const idMap = {}
    nonFailedStops.forEach(id => {
      idMap[id] = id
    })
    dispatch(setRecentStopsMoved({ idMap, routeId }))
  }

  const handleBulkMoveStops = (routeId, routeDate, checkedStops, targetStopId) => {
    callMoveStopsAPI({ destinationRouteId: routeId, serviceDate: routeDate, stops: checkedStops, targetStopId }, false, response => {
      const failedStops = get(response, 'stops', []).map(failedStop => get(failedStop, 'stop.accountName'))
      if (failedStops.length) {
        dispatch(setBulkMoveFailure({ isOpen: true, stops: failedStops }))
      }
      changeMostRecentMovedStops({ stops: checkedStops, routeId, failedStops })
      setIsCheck([])
      setIsCheckAll(false)

      successBulkOperation()
    })
  }

  const handleBulkMoveStopsFromUnassigned = ({ destinationRouteId, serviceDate, stops, targetStopId }) => {
    callMoveStopsAPI({ destinationRouteId, serviceDate, stops, targetStopId }, true, response => {
      const failedStops = get(response, 'stops', []).map(failedStop => get(failedStop, 'stop.accountName'))
      if (failedStops.length) {
        dispatch(setBulkMoveFailure({ isOpen: true, stops: failedStops }))
      }
      changeMostRecentMovedStops({ stops, routeId: destinationRouteId, failedStops })

      setIsCheck([])
      setIsCheckAll(false)
      successBulkOperation()
    })
  }

  const onCloseSuccessChangeDateDialog = () => {
    setState({ isPermanentOccurrenceModal: false })
  }

  useEffect(() => {
    if (localState.isRouteUpdated) {
      handleRefetchStops()
      if (isCheck.length !== 0) {
        window.addEventListener('click', resetBulkAssignStates)
      }
    }
  }, [localState.isRouteUpdated])

  const handleCloseRouteFilterMenu = () => {
    setRouteFilterMenu({ anchorEl: null, routeId: '' })
  }

  const handleOpenRouteFilterMenu = (event, route) => {
    setRouteFilterMenu({ anchorEl: event.currentTarget, routeId: route.id })
  }

  return (
    <Box overflow={!hasRoutes && 'hidden'} className="flex">
      {(isGetRouteStopsLoading ||
        (isUnassignedStopsOpen && isGetRouteStopsUnassignedLoading) ||
        isMoveStopsLoading ||
        isBulkCompleteStopsLoading) && <Loader />}

      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Box display="flex" flexDirection="column" alignItems="center" mt={20}>
          <Box display="flex" mx={2} gap={2}>
            {isUnassignedStopsOpen && (
              <UnassignedStopsColumn
                onOpenRouteFilterMenu={handleOpenRouteFilterMenu}
                isDragDisabled={Boolean(editRouteStops) || isOpenBulkAssign || showBulkAssignPanel || showBulkCompletePanel}
                routeList={routesList}
                handleBulkMoveStopsFromUnassigned={handleBulkMoveStopsFromUnassigned}
                serviceDate={stopServiceDate}
                unassignedWorkOrders={unassignedWorkOrdersList}
                isDropDisabled={isDropDisabled}
                onTagsSave={onTagsEditSave}
                isActionsButtonDisabled={isOpenBulkAssign || showBulkAssignPanel || showBulkCompletePanel}
              />
            )}

            {!hasRoutes && <NoRoutesEmptyState />}

            {routesList.map((route, index) => {
              const routeId = get(route, 'id')
              const routeName = get(route, 'name', '')
              const allStops = selectStops(route)
              const routeStatus = getRouteStatus(route)
              const driverInfo = get(route, 'driverInfo', null)
              const stopsCount = allStops.length
              const driverDetail = getDriverInfo(driverInfo)
              const driverProfilePic = get(driverDetail, 'driverProfilePic', null)
              const driverName = get(driverDetail, 'driverName', null)
              const businessLineIcon = get(route, 'businessLine.businessLineIcon')
              const methodType = get(route, 'method.methodType')

              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  bgcolor="background.paper"
                  boxShadow="0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%)"
                  borderRadius="6px"
                  borderTop={`4px solid ${route?.color}`}
                  width={ROUTE_COLUMN_WIDTH}
                  height={ROUTE_COLUMN_HEIGHT}
                  sx={{
                    ...(editRouteStops && currentRouteId !== routeId && { opacity: '.4', pointerEvents: 'none' }),
                  }}
                >
                  <RouteDetailsHeader
                    onOpenRouteFilterMenu={handleOpenRouteFilterMenu}
                    onEditRoute={onEditRoute}
                    businessLineIcon={businessLineIcon}
                    driverName={driverName}
                    driverProfilePic={driverProfilePic}
                    methodType={methodType}
                    routeName={routeName}
                    stopsCount={stopsCount}
                    routeStatus={routeStatus}
                    isActionsMenuDisabled={isDragDisabled || isOpenBulkAssign || showBulkAssignPanel || showBulkCompletePanel}
                    onActionsMenuClick={handleClick}
                    route={route}
                    serviceDate={stopServiceDate}
                  />
                  <EditRouteSelection
                    routeList={routesList}
                    serviceDate={stopServiceDate}
                    editRouteStops={editRouteStops}
                    currentRouteId={currentRouteId}
                    workOrders={routeId}
                    handleSelectAll={handleSelectAll}
                    isCheckAll={isCheckAll}
                    allWorkOrdersCount={allWorkOrdersCount}
                    checkedStops={isCheck}
                    handleCancelEditRouteStops={handleCancelEditRouteStops}
                    handleUnassignRouteStops={handleUnassignRouteStops}
                    showBulkAssignPanel={showBulkAssignPanel}
                    showBulkCompletePanel={showBulkCompletePanel}
                    handleBulkMoveStops={handleBulkMoveStops}
                    isRouteUpdated={localState.isRouteUpdated}
                    handleBulkCompleteApi={handleBulkCompleteApi}
                  />
                  <RouteColumn
                    route={route}
                    index={index}
                    dragPermissionsDisabled={isDragDisabled}
                    isColumnDragDisabled={isOpenBulkAssign || editRouteStops}
                    editRouteStops={editRouteStops}
                    selectedWorkOrderID={recentDraggedStopId}
                    currentRouteId={currentRouteId}
                    handleSelectClick={handleSelectClick}
                    handleWorkOrderDetails={handleSelectClick}
                    onTagsEditSave={onTagsEditSave}
                    isCheck={isCheck}
                    showRS={showRS}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      </DragDropContext>

      <RouteFiltersMenu
        open={Boolean(routeFilterMenu.anchorEl)}
        anchorEl={routeFilterMenu.anchorEl}
        routeId={routeFilterMenu.routeId}
        onClose={handleCloseRouteFilterMenu}
      />

      <RouteContextMenu
        contextMenu={contextMenu}
        currentDate={currentDate}
        currentRoute={currentRoute}
        currentRouteId={currentRouteId}
        currentRouteName={currentRouteName}
        handleBulkAssign={handleBulkAssign}
        handleBulkComplete={handleBulkComplete}
        handleClose={handleClose}
        handleCurrentRouteDate={changedDate => handleRouteDate(changedDate)}
        handleDateChange={changeRouteDate}
        handleEditRouteStops={handleEditRouteStops}
        handleExportRoute={handleExportRoute}
        handleRouteUpdateStatus={handleRouteUpdateStatus}
        handleViewOnMap={handleViewOnMap}
        isDragDisabled={isDragDisabled}
        onEditRoute={onEditRoute}
        selectedRouteStatus={selectedRouteStatus}
        startDate={stopServiceDate}
        workOrderCount={allWorkOrdersCount}
      />

      <HHConfirmDialog
        isOpen={localState.isPermanentOccurrenceModal}
        onClose={onCloseSuccessChangeDateDialog}
        confirmTitle={localState.snackbarMessage}
        showCloseIconButton={false}
        ConfirmButtonProps={{
          color: 'primary',
          size: 'small',
        }}
        CancelButtonProps={{
          size: 'small',
        }}
        confirmButtonTitle={T.VIEW_ROUTE}
        onConfirm={handleViewRoute}
      />
      {isStopMoved && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={8000}
          open={isStopMoved}
          onClose={() => setIsStopMoved(false)}
          message={`${localState.movedStopName} is moved`}
          key={'bottom' + 'right'}
        />
      )}
    </Box>
  )
}

export default SequenceContainer
