import { takeEvery, all, call, put } from 'redux-saga/effects'

import { toast } from 'react-toastify'

import { get } from 'utils/lodash'
import { doPost } from 'providers/api'
import { TOAST_DISMISS_TIMEOUT } from 'settings/constants/toast'

import T from 'T'

import { handleFirebaseLogout } from 'providers/firebaseLogout'
import { putToken, putUserDetails, putIsUserAuthenticated } from '../actions/auth'
import { actions } from '../actions/login'

import endpoint from './endpoint'
import Util from '../../Util'

export const getUserContext = () => doPost({ url: endpoint.user.info, showLoader: true })
export const getPasswordInitiate = ({ body }) => doPost({ url: endpoint.user.passwordInitiate, body, showLoader: true })
export const getPasswordUpdate = ({ body }) => doPost({ url: endpoint.user.passwordChange, body, showLoader: true })
export const getForgotPassword = ({ body }) => doPost({ url: endpoint.public.forgotPassword, body, showLoader: true, isPublic: true })
export const getPasswordReset = ({ body }) => doPost({ url: endpoint.public.passwordReset, body, showLoader: true, isPublic: true })
export const getPasswordResetInitiate = ({ body }) =>
  doPost({ url: endpoint.public.initiateCompleteResetPwd, body, showLoader: true, isPublic: true })
export const getPwdResetResendEmail = ({ body }) =>
  doPost({ url: endpoint.public.passwordResetSendEmail, body, showLoader: true, isPublic: true })

export function* loginSaga({ payload }) {
  const { success, token, error } = payload

  if (success) {
    yield put(putIsUserAuthenticated(true))
    yield put(putToken(token))
    return
  }

  toast(error)
}

function* signOutSaga({ payload }) {
  const { initiateLogoutFromUtil } = payload

  yield put(putIsUserAuthenticated(false))
  yield put(putToken(''))

  if (initiateLogoutFromUtil) {
    setTimeout(() => {
      Util.initiateLogout()
    }, TOAST_DISMISS_TIMEOUT)
  }
}

export function* getUserContextSaga({ payload }) {
  const { onSuccess } = payload
  try {
    const { data: res } = yield call(getUserContext)
    const { userInfo } = res
    const allowOfficeLogin = get(userInfo, 'profile.allowOfficeLogin', false)
    if (allowOfficeLogin) {
      yield put(putUserDetails(userInfo))
      onSuccess(res)
      return
    }

    onSuccess(false)
    toast.error(T.NOT_ALLOWED_OFFICE_LOGIN_MSG)
    handleFirebaseLogout()
  } catch (err) {
    yield put(putUserDetails(null))
    onSuccess(false)
  }
}

export function* getPasswordInitiateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPasswordInitiate, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getPasswordUpdateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPasswordUpdate, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getForgotPasswordSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getForgotPassword, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getPasswordResetSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPasswordReset, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getPasswordResetInitiateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPasswordResetInitiate, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export function* getPwdResetResendEmailSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPwdResetResendEmail, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess({})
  }
}

export default function* Login() {
  yield all([
    takeEvery(actions.LOGIN, loginSaga),
    takeEvery(actions.LOGOUT, signOutSaga),
    takeEvery(actions.USER_INFO, getUserContextSaga),
    takeEvery(actions.PASSWORD_INITIATE, getPasswordInitiateSaga),
    takeEvery(actions.PASSWORD_UPDATE, getPasswordUpdateSaga),
    takeEvery(actions.FORGOT_PASSWORD, getForgotPasswordSaga),
    takeEvery(actions.PASSWORD_RESET, getPasswordResetSaga),
    takeEvery(actions.PASSWORD_RESET_INITIATE, getPasswordResetInitiateSaga),
    takeEvery(actions.PASSWORD_RESET_RESEND_EMAIL, getPwdResetResendEmailSaga),
  ])
}
