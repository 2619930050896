import endpoint from 'middleware/sagas/endpoint'

import { BILLING_TABS_ID } from 'settings/constants/billing'
import { PRICING_TABS_ID } from 'settings/constants/pricing'
import { REPORTS_DATA_VALUES } from 'settings/constants/reports'
import { WORK_ORDER_DOWNLOAD_PDF_KEY } from 'settings/constants/workOrder'

const { ACCOUNTS_RECEIVABLE_TAB_ID, INVOICES_TAB_ID, SENT_INVOICES_TAB_ID, PAYMENTS_TAB_ID } = BILLING_TABS_ID
const { SERVICES_TAB_ID, GENERAL_TAB_ID, DISPOSAL_TAB_ID, TAXES_TAB_ID } = PRICING_TABS_ID
const { AR_REPORT, INVOICE_REPORT, SI_REPORT, PAYMENT_REPORT, REVENUE_REPORT, SERVICE_REPORT, WO_REPORT } = REPORTS_DATA_VALUES

const GET_DOWNLOAD_URLS = new Map([
  [
    `billingExport${ACCOUNTS_RECEIVABLE_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.accountReceivable.initiateExport,
      statusUrl: endpoint.billing.accountReceivable.exportStatus,
      downLoadUrl: endpoint.billing.accountReceivable.exportDownload,
    },
  ],
  [
    `billingExport${INVOICES_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.invoice.initiateExport,
      statusUrl: endpoint.billing.invoice.exportStatus,
      downLoadUrl: endpoint.billing.invoice.exportDownload,
    },
  ],
  [
    `billingExport${SENT_INVOICES_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.sentInvoice.initiateExport,
      statusUrl: endpoint.billing.sentInvoice.exportStatus,
      downLoadUrl: endpoint.billing.sentInvoice.exportDownload,
    },
  ],
  [
    `billingBatchExport${SENT_INVOICES_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.sentInvoice.initiateBatchExport,
      statusUrl: endpoint.billing.sentInvoice.batchExportStatus,
      downLoadUrl: endpoint.billing.sentInvoice.batchExportDownload,
    },
  ],
  [
    `billingPaymentReceipt${PAYMENTS_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.payment.initiateReceiptDownload,
      statusUrl: endpoint.billing.payment.receiptDownloadStatus,
      downLoadUrl: endpoint.billing.payment.receiptDownload,
    },
  ],
  [
    `billingExport${PAYMENTS_TAB_ID}`,
    {
      initiateUrl: endpoint.billing.payment.initiateExport,
      statusUrl: endpoint.billing.payment.exportStatus,
      downLoadUrl: endpoint.billing.payment.exportDownload,
    },
  ],
  [
    AR_REPORT,
    {
      initiateUrl: endpoint.report.accountReceivables.initiateExport,
      statusUrl: endpoint.report.accountReceivables.exportStatus,
      downLoadUrl: endpoint.report.accountReceivables.exportDownload,
    },
  ],
  [
    INVOICE_REPORT,
    {
      initiateUrl: endpoint.report.invoices.initiateExport,
      statusUrl: endpoint.report.invoices.exportStatus,
      downLoadUrl: endpoint.report.invoices.exportDownload,
    },
  ],
  [
    SI_REPORT,
    {
      initiateUrl: endpoint.report.sentInvoices.initiateExport,
      statusUrl: endpoint.report.sentInvoices.exportStatus,
      downLoadUrl: endpoint.report.sentInvoices.exportDownload,
    },
  ],
  [
    PAYMENT_REPORT,
    {
      initiateUrl: endpoint.report.payments.initiateExport,
      statusUrl: endpoint.report.payments.exportStatus,
      downLoadUrl: endpoint.report.payments.exportDownload,
    },
  ],
  [
    REVENUE_REPORT,
    {
      initiateUrl: endpoint.report.revenue.initiateExport,
      statusUrl: endpoint.report.revenue.exportStatus,
      downLoadUrl: endpoint.report.revenue.exportDownload,
    },
  ],
  [
    SERVICE_REPORT,
    {
      initiateUrl: endpoint.report.service.initiateExport,
      statusUrl: endpoint.report.service.exportStatus,
      downLoadUrl: endpoint.report.service.exportDownload,
    },
  ],
  [
    WO_REPORT,
    {
      initiateUrl: endpoint.report.workOrder.initiateExport,
      statusUrl: endpoint.report.workOrder.exportStatus,
      downLoadUrl: endpoint.report.workOrder.exportDownload,
    },
  ],
  [
    WORK_ORDER_DOWNLOAD_PDF_KEY,
    {
      initiateUrl: endpoint.workOrder.initiateDownload,
      statusUrl: endpoint.workOrder.downloadStatus,
      downLoadUrl: endpoint.workOrder.downloadWorkOrder,
    },
  ],
  [
    'billingInvoicePDF',
    {
      initiateUrl: endpoint.billing.invoice.initiateDownload,
      statusUrl: endpoint.billing.invoice.downloadStatus,
      downLoadUrl: endpoint.billing.invoice.downloadInvoice,
    },
  ],
  [
    'accountReceivableDownloadPDF',
    {
      initiateUrl: endpoint.billing.accountReceivable.initiateDownload,
      statusUrl: endpoint.billing.accountReceivable.exportStatus,
      downLoadUrl: endpoint.billing.accountReceivable.exportDownload,
    },
  ],
  [
    'accountReceivableTransactionPDF',
    {
      initiateUrl: endpoint.billing.accountReceivable.initiateDownload,
      statusUrl: endpoint.billing.accountReceivable.exportStatus,
      downLoadUrl: endpoint.billing.accountReceivable.downloadAccountReceivable,
    },
  ],
  [
    `pricingExport${SERVICES_TAB_ID}`,
    {
      initiateUrl: endpoint.pricing.service.initiateExport,
      statusUrl: endpoint.pricing.service.exportStatus,
      downLoadUrl: endpoint.pricing.service.exportDownload,
    },
  ],
  [
    `pricingExport${GENERAL_TAB_ID}`,
    {
      initiateUrl: endpoint.pricing.fee.general.initiateExport,
      statusUrl: endpoint.pricing.fee.general.exportStatus,
      downLoadUrl: endpoint.pricing.fee.general.exportDownload,
    },
  ],
  [
    `pricingExport${DISPOSAL_TAB_ID}`,
    {
      initiateUrl: endpoint.pricing.fee.disposal.initiateExport,
      statusUrl: endpoint.pricing.fee.disposal.exportStatus,
      downLoadUrl: endpoint.pricing.fee.disposal.exportDownload,
    },
  ],
  [
    `pricingExport${TAXES_TAB_ID}`,
    {
      initiateUrl: endpoint.pricing.tax.initiateExport,
      statusUrl: endpoint.pricing.tax.exportStatus,
      downLoadUrl: endpoint.pricing.tax.exportDownload,
    },
  ],
  [
    'routeExport',
    {
      initiateUrl: endpoint.export.initiate.download,
      statusUrl: endpoint.export.check.status,
      downLoadUrl: endpoint.export.download,
    },
  ],
  ['', { initiateUrl: '', statusUrl: '', downLoadUrl: '' }],
])

export const getDownloadURL = type => GET_DOWNLOAD_URLS.get(type)
