import React, { Children } from 'react'
import { styled, Tab } from '@mui/material'
import TabList from '@mui/lab/TabList'
import PropTypes from 'prop-types'

const CustomTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    background: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.background.tags}`,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 5%)',
    borderRadius: 6,
    width: 'max-content',
    height: 40,
  },
  '& .MuiTab-root': {
    fontWeight: 400,
    fontSize: 13,
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    minHeight: 'unset',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .Mui-selected': {
    background: 'linear-gradient(180deg, #0073E6 0%, #0068CF 100%)',
    boxShadow: ' 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    borderRadius: 4,
    height: 32,
    margin: 3,
    color: `${theme.palette.primary.contrastText} !important`, // to override  default color
  },
}))

const HHTabList = ({ tabOptions = [], handleChange }) => {
  return (
    <CustomTabList onChange={handleChange}>
      {Children.toArray(
        tabOptions.map(option => {
          return <Tab label={option.key} value={option.value} />
        })
      )}
    </CustomTabList>
  )
}
HHTabList.propTypes = {
  tabOptions: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
}
export default HHTabList
