import React, { useReducer, useEffect } from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import { Button, Typography, Box } from '@mui/material'
import { useLazyGetPricingZoneListQuery } from 'api/settings/getPricingZoneList'
import { useAddPricingZoneMutation } from 'api/settings/addPricingZone'

import T from 'T'

import 'components/settings/pages/users/style.scss'

import { get } from 'utils/lodash'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'

import { isAlphaNumeric } from 'utils/validations'
import { handleError } from 'utils/error'
import ZonesTable from './ZonesTable'

const PricingZoneSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [pricingZoneState, setPricingZoneState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    pricingZones: [],
    onPlacePricingZoneEdit: {},
  })

  const { pricingZones, onPlacePricingZoneEdit } = pricingZoneState

  const [getPricingZoneList, { isLoading, isFetching }] = useLazyGetPricingZoneListQuery()
  const [addPricingZone, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddPricingZoneMutation()

  const fetchPricingZones = () => {
    getPricingZoneList({})
      .unwrap()
      .then(res =>
        setPricingZoneState({
          pricingZones: get(res, 'zone', []).map(data => ({
            active: data.active,
            customerCount: data.customerCount,
            zoneId: data.id,
            zoneName: data.zoneName,
          })),
        })
      )
      .catch(handleError)
  }

  useEffect(() => {
    fetchPricingZones()
  }, [])

  const handleOnPlaceEdit = index => {
    if (updateSettings) {
      if (Object.keys(onPlacePricingZoneEdit).length === 0) {
        onPlacePricingZoneEdit[index] = false
      }
      onPlacePricingZoneEdit[index] = !onPlacePricingZoneEdit[index]
      setPricingZoneState({ onPlacePricingZoneEdit })
    }
  }

  const handleSave = index => {
    const payload = {
      zones: pricingZones.filter(data => data.zoneName !== ''),
    }

    setPricingZoneState({ onPlacePricingZoneEdit: {} })

    addPricingZone(payload)
      .unwrap()
      .then(() => {
        fetchPricingZones()
      })
      .catch(handleError)
  }

  const handleZoneSwitchVal = (value, index) => {
    if (updateSettings) {
      pricingZones[index].active = value

      setPricingZoneState({ pricingZones })
      handleSave(index)
    }
  }

  const onHandleChange = (e, index) => {
    let { value } = e.target

    if (/^\s/.test(value)) {
      value = ''
    }

    if (!isAlphaNumeric(value)) return

    pricingZones[index].zoneName = value

    setPricingZoneState({ pricingZones })
  }

  const handleZoneRemoval = index => {
    pricingZones.splice(index, 1)

    setPricingZoneState({ pricingZones })
  }

  const addRows = () => {
    pricingZones.push({
      active: true,
      zoneId: '',
      zoneName: '',
    })
    onPlacePricingZoneEdit[pricingZones.length - 1] = true
    setPricingZoneState({ pricingZones, onPlacePricingZoneEdit })
  }

  return (
    <Box>
      <Box px={4} display="flex" justifyContent="space-between">
        <Typography variant="h3">{`${T.PRICING_ZONE}s`}</Typography>

        {addSettings && (
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addRows()}>
            {T.ADD}
          </Button>
        )}
      </Box>

      <ZonesTable
        type="pricingZone"
        zones={pricingZones}
        onPlaceEdit={onPlacePricingZoneEdit}
        isLoading={isLoading || isFetching || isAddLoading || isAddFetching}
        onHandleChange={onHandleChange}
        handleSave={handleSave}
        handleZoneSwitchVal={handleZoneSwitchVal}
        handleZoneRemoval={handleZoneRemoval}
        handleOnPlaceEdit={handleOnPlaceEdit}
      />
    </Box>
  )
}
export default PricingZoneSettings
