/* eslint-disable react/react-in-jsx-scope */
import uniq from 'lodash/uniq'
import { NumericFormat } from 'react-number-format'

import { getApp } from 'firebase/app'
import { getStorage, ref, getMetadata } from 'firebase/storage'

import ToolTip from 'components/common/Tooltip'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'

import { get } from 'utils/lodash'
import { store } from 'providers/store'
import { getPaymentMethod } from 'utils/payment'
import { insertSpaceBeforeCapitalLetter } from 'utils/string'
import { formatDateToFEFormat, getLocalDate } from 'utils/date'
import { BILLING_TABS_ID, INVOICE_STATUS } from 'settings/constants/billing'
import { FILTER_SUB_TYPES } from 'settings/constants/filters'

import Constants from 'Constants'
import endpoint from 'middleware/sagas/endpoint'
import { handleError } from './error'

const { ASSETS_BUCKET_NAME } = Constants
const { PARTIAL, UNPAID } = INVOICE_STATUS

const { ACCOUNTS_RECEIVABLE_TAB_ID, INVOICES_TAB_ID, SENT_INVOICES_TAB_ID, PAYMENTS_TAB_ID, CREATE_INVOICES_TAB_ID } = BILLING_TABS_ID
const { MONEY, PRICE } = FILTER_SUB_TYPES

const GET_BILLING_LIST_URLS = new Map([
  [ACCOUNTS_RECEIVABLE_TAB_ID, endpoint.billing.accountReceivable.list],
  [INVOICES_TAB_ID, endpoint.billing.invoice.list],
  [SENT_INVOICES_TAB_ID, endpoint.billing.sentInvoice.list],
  [PAYMENTS_TAB_ID, endpoint.billing.payment.list],
  ['', ''],
])

const GET_BILLING_FILTER_VIEW_URLS = new Map([
  [ACCOUNTS_RECEIVABLE_TAB_ID, endpoint.billing.accountReceivable.filterList],
  [INVOICES_TAB_ID, endpoint.billing.invoice.filterList],
  [SENT_INVOICES_TAB_ID, endpoint.billing.sentInvoice.filterList],
  [PAYMENTS_TAB_ID, endpoint.billing.payment.filterList],
  [CREATE_INVOICES_TAB_ID, endpoint.billing.createInvoice.filterList],
  ['', ''],
])

const GET_BILLING_FILTER_ADD_EDIT = new Map([
  [ACCOUNTS_RECEIVABLE_TAB_ID, endpoint.billing.accountReceivable.filterSave],
  [INVOICES_TAB_ID, endpoint.billing.invoice.filterSave],
  [SENT_INVOICES_TAB_ID, endpoint.billing.sentInvoice.filterSave],
  [PAYMENTS_TAB_ID, endpoint.billing.payment.filterSave],
  [CREATE_INVOICES_TAB_ID, endpoint.billing.createInvoice.filterSave],
  ['', ''],
])

const GET_BILLING_FILTER_DELETE = new Map([
  [ACCOUNTS_RECEIVABLE_TAB_ID, endpoint.billing.accountReceivable.filterDelete],
  [INVOICES_TAB_ID, endpoint.billing.invoice.filterDelete],
  [SENT_INVOICES_TAB_ID, endpoint.billing.sentInvoice.filterDelete],
  [PAYMENTS_TAB_ID, endpoint.billing.payment.filterDelete],
  [CREATE_INVOICES_TAB_ID, endpoint.billing.createInvoice.filterDelete],
  ['', ''],
])

const GET_BILLING_REVENUE_URLS = new Map([
  [ACCOUNTS_RECEIVABLE_TAB_ID, endpoint.billing.accountReceivable.revenue],
  [INVOICES_TAB_ID, endpoint.billing.invoice.revenue],
  [SENT_INVOICES_TAB_ID, ''],
  [PAYMENTS_TAB_ID, endpoint.billing.payment.revenue],
  ['', ''],
])

export const getBillingURL = (type, activeTab) => {
  if (type === 'list') {
    return GET_BILLING_LIST_URLS.get(activeTab)
  }

  if (type === 'filterView') {
    return GET_BILLING_FILTER_VIEW_URLS.get(activeTab)
  }

  if (type === 'filterAddEdit') {
    return GET_BILLING_FILTER_ADD_EDIT.get(activeTab)
  }

  if (type === 'filterDelete') {
    return GET_BILLING_FILTER_DELETE.get(activeTab)
  }

  if (type === 'revenue') {
    return GET_BILLING_REVENUE_URLS.get(activeTab)
  }

  return ''
}

export const getBillingColumnData = (currentRow, columnName, subType, columnValueCallback = () => {}) => {
  switch (subType) {
    case MONEY:
      return {
        columnValue: <HHDisplayMoney value={get(currentRow, columnName, 0)} />,
      }
    case PRICE:
      return {
        columnValue: <HHDisplayMoney value={get(currentRow, columnName, 0)} formatToDollars={false} />,
      }
    case 'taxFeeValue': {
      const taxValues = get(currentRow, columnName, '')

      return {
        columnValue: (
          <div className="flex items-center justify-center">
            <NumericFormat
              value={get(taxValues, '[0].value', '')}
              displayType="text"
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
              prefix={get(taxValues, '[0].valueType') === '$' ? '$' : ''}
              suffix={get(taxValues, '[0].valueType') === '%' ? '%' : ''}
            />
            {taxValues.length > 1 && <div className="tags-count flex items-center justify-center ml1">{`+${taxValues.length - 1}`}</div>}
          </div>
        ),
      }
    }
    case 'date':
      return { columnValue: formatDateToFEFormat(get(currentRow, columnName, '')) }
    case 'dateTime':
      return {
        columnValue: get(currentRow, columnName, '') ? getLocalDate(get(currentRow, columnName)) : '',
      }
    case 'tags': {
      return {
        columnValue: (
          // avoid openning the invoice slideout upon clickng tags and its edit dialog, parent table has an onClick listener attached to all cells in order to display the invoice slideout
          // should add specificity to the parent handler as to which items open the dialog, row not cells and children of cells
          <div onClick={event => event?.stopPropagation()}>
            <InteractiveTagsList
              data={{
                ...currentRow,
                name: get(currentRow, 'customerName', currentRow?.invoiceNumber),
              }}
              onSave={columnValueCallback}
              limit={1}
              tagsFlexWrap="nowrap"
              disableService
              disableLocation
            />
          </div>
        ),
      }
    }
    case 'note': {
      const note = get(currentRow, columnName, '')
      return {
        columnValue: (
          <ToolTip title={note} tooltipClassName="payments-notes-tooltip">
            <div className="dib flex">
              <span>{note.substring(0, 10)}</span>
              {note.length > 15 && <div className="shadow">{note.substring(11, 14)}</div>}
            </div>
          </ToolTip>
        ),
      }
    }
    case 'paymentMethod': {
      return {
        columnValue: getPaymentMethod(get(currentRow, 'paymentMethod', [])),
      }
    }
    case 'paymentTarget': {
      const allTargets = get(currentRow, 'paymentTarget', [])
      const firstTarget = insertSpaceBeforeCapitalLetter(get(allTargets, '[0]', ''))

      return {
        columnValue: (
          <ToolTip title={insertSpaceBeforeCapitalLetter(allTargets.join('\n'))} tooltipClassName="payments-invoices-tooltip">
            <div className="dib flex">{firstTarget}</div>
          </ToolTip>
        ),
      }
    }
    case 'paymentModes': {
      return { columnValue: get(currentRow, 'paymentModes', []).join(', ') }
    }
    case 'pricingZones': {
      const zoneValues = uniq(get(currentRow, columnName, []))

      return {
        columnValue: (
          <div className="flex items-center">
            {get(zoneValues, '[0]', '')}

            {zoneValues.length > 1 && <div className="tags-count flex items-center justify-center ml1">{`+${zoneValues.length - 1}`}</div>}
          </div>
        ),
      }
    }
    case 'autoPay':
      return { columnValue: get(currentRow, columnName, 'No') ? 'Yes' : 'No' }
    case 'woPhone': {
      const numbers = get(currentRow, columnName, [])

      return {
        columnValue: (
          <ToolTip title={numbers.join('\n')} tooltipClassName="payments-invoices-tooltip">
            <div className="dib flex cursor-pointer">{get(numbers, '[0]', '')}</div>
          </ToolTip>
        ),
      }
    }

    default:
      return { columnValue: get(currentRow, columnName, '') }
  }
}

export const handleTableClass = (index, isRowLocked, lockedColumns) => {
  if (lockedColumns.length === index + 1) {
    return 'sticky-col add-shadow'
  }

  if (isRowLocked) {
    return 'sticky-col'
  }

  return ''
}

export const getBillingRevenueKeyName = activeBillingTab => {
  if (activeBillingTab === ACCOUNTS_RECEIVABLE_TAB_ID) {
    return 'accountIds'
  }

  if (activeBillingTab === INVOICES_TAB_ID) {
    return 'invoiceNumbers'
  }

  if (activeBillingTab === PAYMENTS_TAB_ID) {
    return 'paymentIds'
  }

  return ''
}

export const getBillingFileName = activeBillingTab => {
  if (activeBillingTab === ACCOUNTS_RECEIVABLE_TAB_ID) {
    return 'AccountReceivable'
  }

  if (activeBillingTab === INVOICES_TAB_ID) {
    return 'Invoice'
  }

  if (activeBillingTab === PAYMENTS_TAB_ID) {
    return 'Payment'
  }

  return ''
}

export const isDraftInvoice = status => status === PARTIAL
export const isUnPaidInvoice = status => status === UNPAID

export const checkPDFExists = allInvoices => {
  const state = store.getState()
  const businessId = get(state, 'AuthReducer.userInfo.businessInfo.businessId')
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)

  const allPromises = allInvoices.map(invoiceNumber => {
    const invoiceRef = ref(storage, `${businessId}/INVOICE/${invoiceNumber.toString()}.pdf`)
    return getMetadata(invoiceRef)
      .then(() => Promise.resolve({ invoiceNumber, isExist: true }))
      .catch(() =>
        // we can also use error.code === 'storage/object-not-found'
        Promise.resolve({ invoiceNumber, isExist: false })
      )
  })

  return Promise.all(allPromises)
}

export const getInvoicesPDFStatus = async invoices => {
  try {
    const result = await checkPDFExists(invoices)
    const withPDF = []
    const withoutPDF = []
    result.forEach(({ invoiceNumber, isExist }) => {
      if (isExist) withPDF.push(invoiceNumber)
      else withoutPDF.push(invoiceNumber)
    })

    return { withPDF, withoutPDF, result }
  } catch (err) {
    handleError(err)
    return { withPDF: [], withoutPDF: [] }
  }
}

export const getDraftAndNonDraftInvoicesBySelectedRows = (invoices = [], key = 'id') => {
  const draftInvoices = []
  const nonDraftInvoices = []
  invoices.forEach(invoice => {
    if (isDraftInvoice(invoice.status)) draftInvoices.push(get(invoice, key))
    else nonDraftInvoices.push(get(invoice, key))
  })

  return { draftInvoices, nonDraftInvoices }
}
