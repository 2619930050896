import { useCallback, useMemo } from 'react'
import { filter, get, isString } from 'lodash'
import {
  ALPHABET_LIST,
  ALPHABET_WITH_DIGITS_LIST,
  getSortKeyWithDigits,
  getSortKeyWithoutDigits,
  hasIntersection,
} from 'components/locations/settings'
import {
  ACCOUNTS_DESKTOP_ROW_HEIGHT,
  ACCOUNTS_MOBILE_ROW_HEIGHT,
  SORT_BY_FIRST_NAME_CRITERIA,
  SORT_BY_LAST_NAME_CRITERIA,
  SORT_BY_OPTIONS,
  showDeactivatedRow,
  PAGE_TYPE,
} from 'components/customers/accounts/settings'
import { useMediaQuery, useTheme } from '@mui/material'
import { checkIsManualSearch, getManualSearchParamName } from 'components/customers/accounts/search/settings'

const useAccountListUtilities = ({ data, characterToIndexMap, apiRef, sortedRows, watch, setValue, isRecentlySelected }) => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const sortByValue = watch('sortBy')
  const selectedTags = watch('selectedTags')
  const withDeactivated = watch('withDeactivated')
  const withDigits = watch('withDigits')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const searchType = watch('searchType')
  const searchValue = watch('searchValue')
  const isManualSearch = useMemo(() => checkIsManualSearch(searchType), [searchType])

  const sortBySelectedOption = useMemo(() => SORT_BY_OPTIONS.find(option => option.value === sortByValue), [sortByValue])
  const isListAscending = useMemo(() => get(sortBySelectedOption, 'ascending', true), [sortBySelectedOption])
  const sortByCriteria = useMemo(() => get(sortBySelectedOption, 'sortCriteria'), [sortBySelectedOption])
  const isFilterByFirstName = useMemo(() => sortByCriteria === SORT_BY_FIRST_NAME_CRITERIA, [sortByCriteria])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const rows = useMemo(() => (Array.isArray(data) ? data : []), [data])

  const handleCharacterButtonClick = useCallback(
    character => {
      const targetIndex = get(characterToIndexMap, character, false)
      if (targetIndex === false) return

      setValue('expandedRowIds', [])
      const rowHeight = isTabletOrMobile ? ACCOUNTS_MOBILE_ROW_HEIGHT : ACCOUNTS_DESKTOP_ROW_HEIGHT
      apiRef?.current?.scroll({ top: targetIndex > -1 ? targetIndex * rowHeight : 0 })
    },
    [characterToIndexMap, isTabletOrMobile]
  )

  const shouldDisableCharacterButton = useCallback(
    character => {
      const targetIndex = get(characterToIndexMap, character)
      return typeof targetIndex !== 'number'
    },
    [characterToIndexMap]
  )

  const createAlphabetToRowIndexMap = useCallback(() => {
    const alphabetList = withDigits ? ALPHABET_WITH_DIGITS_LIST : ALPHABET_LIST
    const dictionary = {}
    const indexMap = new Map()
    sortedRows.forEach((row, index) => {
      const customerName = isFilterByFirstName ? get(row, SORT_BY_FIRST_NAME_CRITERIA, '') : get(row, SORT_BY_LAST_NAME_CRITERIA, '')
      const firstChar = customerName.charAt(0).toLowerCase()
      if (alphabetList.includes(firstChar) && !indexMap.has(firstChar)) {
        indexMap.set(firstChar, index)
      }
    })
    indexMap.forEach((value, key) => {
      dictionary[key] = value
    })
    alphabetList.forEach(letter => {
      if (!indexMap.has(letter)) {
        dictionary[letter] = false
      }
    })

    return dictionary
  }, [sortedRows, withDigits, isFilterByFirstName])

  const accountsSortComparator = useCallback(
    (a, b) => {
      const customerNameA = isFilterByFirstName ? get(a, SORT_BY_FIRST_NAME_CRITERIA, '') : get(a, SORT_BY_LAST_NAME_CRITERIA, '')
      const customerNameB = isFilterByFirstName ? get(b, SORT_BY_FIRST_NAME_CRITERIA, '') : get(b, SORT_BY_LAST_NAME_CRITERIA, '')
      const firstCharA = customerNameA.charAt(0).toLowerCase()
      const firstCharB = customerNameB.charAt(0).toLowerCase()

      let sortKeyA = ''
      let sortKeyB = ''

      if (isListAscending) {
        sortKeyA = withDigits ? getSortKeyWithDigits(firstCharA) : getSortKeyWithoutDigits(firstCharA)
        sortKeyB = withDigits ? getSortKeyWithDigits(firstCharB) : getSortKeyWithoutDigits(firstCharB)
      } else {
        sortKeyA = getSortKeyWithDigits(firstCharA)
        sortKeyB = getSortKeyWithDigits(firstCharB)
      }

      if (sortKeyA < sortKeyB) return isListAscending ? -1 : 1
      if (sortKeyA > sortKeyB) return isListAscending ? 1 : -1
      return 0
    },
    [withDigits, isListAscending, isFilterByFirstName]
  )

  const sortRows = useCallback(() => {
    const selectedTagIdsSet = new Set(selectedTagsIds)
    const selectedBillingProfilesIdsSet = new Set(selectedBillingProfilesIds)
    const filteredRows = filter(rows, row => {
      if (!showDeactivatedRow(withDeactivated, row, PAGE_TYPE)) return false
      const paramName = getManualSearchParamName(searchType)
      const value = get(row, paramName, '').toLowerCase()
      if (!isRecentlySelected && isManualSearch && isString(searchValue) && !value.includes(searchValue.toLowerCase())) return false

      const billingProfileId = get(row, 'billingProfileId')
      const tagIds = get(row, 'tagIds', [])
      return hasIntersection(selectedTagIdsSet, tagIds) && (selectedBillingProfilesIdsSet.has(billingProfileId) || !billingProfileId)
    })
    return isRecentlySelected ? filteredRows : filteredRows.sort(accountsSortComparator)
  }, [
    accountsSortComparator,
    rows,
    selectedTagsIds,
    withDeactivated,
    selectedBillingProfilesIds,
    isRecentlySelected,
    isManualSearch,
    searchType,
    searchValue,
  ])

  const handleFilterCharacter = useCallback(
    character => {
      if (/\d/.test(character)) {
        return characterToIndexMap[character] !== false
      }

      return true
    },
    [characterToIndexMap]
  )

  return { handleCharacterButtonClick, shouldDisableCharacterButton, createAlphabetToRowIndexMap, sortRows, handleFilterCharacter }
}

export default useAccountListUtilities
