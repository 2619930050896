/* eslint-disable */
import ALL_STATES from './lib/states.json'
import ALL_COUNTRIES from './lib/countries.json'

let states
const isState = str => {
  if (!states) {
    const map = ALL_STATES
    states = {}
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        states[key.toLowerCase()] = true
        states[map[key].toLowerCase()] = true
      }
    }
  }
  str = str.trim().toLowerCase()
  return !!states[str]
}

let countries
const isCountry = str => {
  if (!countries) {
    const map = ALL_COUNTRIES
    countries = {}
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        countries[key.toLowerCase()] = true
        countries[map[key].toLowerCase()] = true
      }
    }
  }
  str = str.trim().toLowerCase()
  if (str == 'usa') {
    return true
  }
  return !!countries[str]
}

export const splitAddress = addressString => {
  const addressObj = {
    street_address1: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
  }
  if (typeof addressString !== 'string') {
    return addressObj
  }
  addressString = addressString.trim()

  let postalCode = addressString.match(/([0-9]{5})|([a-z][0-9][a-z] ?[0-9][a-z][0-9])/gi)
  let indexOfPostalCode = -1
  if (postalCode) {
    postalCode = postalCode.pop()
    indexOfPostalCode = addressString.lastIndexOf(postalCode)
    if (indexOfPostalCode == 0 && addressString.length > 10) {
      postalCode = null
      indexOfPostalCode = -1
    }
    if (postalCode) {
      addressObj.postal_code = postalCode
      const everythingAfterPostalCode = addressString.substr(indexOfPostalCode + postalCode.length)
      addressString = addressString.substr(0, indexOfPostalCode) + everythingAfterPostalCode
      const possibleCountry = everythingAfterPostalCode.replace(/\s*,/, '').split(',').shift().trim()
      if (possibleCountry && isCountry(possibleCountry)) {
        addressObj.country = possibleCountry
        addressString = addressString.substr(0, indexOfPostalCode)
      }
    }
  }

  const addSplit = addressString.split(',')

  if (addSplit.length == 3 && isState(addSplit[2])) {
    addressObj.street_address1 = addSplit[0].trim()
    addressObj.city = addSplit[1].trim()
    addressObj.state = addSplit[2].trim()
    return addressObj
  }

  addSplit.forEach(function (addPart) {
    if (!(addPart = addPart.trim())) return
    if (/[0-9]/.test(addPart)) {
      return !addressObj.street_address1 && (addressObj.street_address1 = addPart)
    }
    if (isState(addPart) && !addressObj.state) {
      return (addressObj.state = addPart)
    }
    if (isCountry(addPart)) {
      return !addressObj.country && (addressObj.country = addPart)
    }
    !addressObj.city && (addressObj.city = addPart)
  })

  return addressObj
}
