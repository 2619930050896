import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { Card, Grid, IconButton, Box, useTheme } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import { ReactComponent as ServiceNoteIcon } from 'assets/note/ServiceNote.svg'
import AddIcon from '@mui/icons-material/Add'
import MoreVert from '@mui/icons-material/MoreVert'
import { get } from 'utils/lodash'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import StyledBadge from 'components/notes/common/StyledBadge'
import RentalFeeBadgeButton from 'components/pricing/RentalFees/common/badge/RentalFeeBadgeButton'
import TopRow from './TopRow'
import BottomRow from './BottomRow'

const ServiceDetailsCard = ({
  service,
  selectedServiceId = '',
  onServiceNameClick,
  onHandleResetGracePeriod,
  onAddEventClick,
  onActionClick,
  onViewNotesClick,
  onSaveTags = noop,
}) => {
  const theme = useTheme()
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const serviceId = get(service, 'id', '')
  const isRowSelected = serviceId === selectedServiceId
  const notesCount = get(service, 'notesCount', 0)
  const rentalFeeCount = get(service, 'rentalFeeCount', 0)

  return (
    <Card
      variant="outlined"
      sx={{ pt: 0.5, px: 2, backgroundColor: isRowSelected ? `${lightBlue[700]}14` : theme.palette.background.paper }}
    >
      <Grid container direction="row" columnSpacing={2} flexWrap="nowrap" zeroMinWidth>
        <Grid py={1} item container overflow="auto" direction="column" rowSpacing={0.5} justifyContent="space-evenly" xs zeroMinWidth>
          <TopRow onServiceNameClick={onServiceNameClick} service={service} onSaveTags={onSaveTags} />
          <BottomRow service={service} />
        </Grid>
        <Grid pt={0.5} item xs="auto" display="flex" flexDirection="column" alignItems="end">
          <Box>
            {isRentalFeeEnabled && (
              <RentalFeeBadgeButton rentalFeeCount={rentalFeeCount} configuredServiceId={serviceId} onClick={onHandleResetGracePeriod} />
            )}
            <IconButton size="small" onClick={onAddEventClick}>
              <AddIcon />
            </IconButton>
            <IconButton size="small" onClick={onActionClick}>
              <MoreVert />
            </IconButton>
          </Box>
          <IconButton size="small" onClick={onViewNotesClick}>
            <StyledBadge badgeContent={notesCount}>
              <ServiceNoteIcon />
            </StyledBadge>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}

ServiceDetailsCard.propTypes = {
  service: PropTypes.object,
  selectedServiceId: PropTypes.string,
  onServiceNameClick: PropTypes.func.isRequired,
  onHandleResetGracePeriod: PropTypes.func.isRequired,
  onAddEventClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onViewNotesClick: PropTypes.func.isRequired,
  onSaveTags: PropTypes.func,
}

export default ServiceDetailsCard
