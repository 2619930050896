import React from 'react'
import TopSection from 'components/pricing/LateFees/details/TopSection'
import RenderLateFeeAccountCell from 'components/pricing/LateFees/details/RenderLateFeeAccountCell'

const RenderLateFeeDetailsWrapper = params => {
  const { id: rowId } = params

  if (rowId === 'header') {
    return <TopSection />
  }

  return <RenderLateFeeAccountCell {...params} />
}

export default RenderLateFeeDetailsWrapper
