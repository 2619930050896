import React from 'react'
import PropTypes from 'prop-types'

import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'

import { get } from 'utils/lodash'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE } = INVOICE_STATUS
const CHIP_SX = { color: 'background.paper', width: 12, height: 12 }

const WorkOrderInvoiceStatusChip = ({ status, label }) => {
  const getInvoiceStatusChipIcon = () => {
    if (status === NOT_READY_FOR_INVOICE) return <CloseIcon sx={CHIP_SX} />
    if (status === READY_FOR_INVOICE) return <CheckIcon sx={CHIP_SX} />
    // INVOICED
    return <TaskOutlinedIcon sx={CHIP_SX} />
  }

  return (
    <Chip
      size="small"
      avatar={<Avatar sx={{ backgroundColor: 'background.black', opacity: 0.38 }}>{getInvoiceStatusChipIcon()}</Avatar>}
      label={label || get(INVOICE_STATUS_DISPLAY, `${status}.title`)}
      sx={{ backgroundColor: get(INVOICE_STATUS_DISPLAY, `${status}.bgColor`) }}
    />
  )
}

WorkOrderInvoiceStatusChip.propTypes = {
  status: PropTypes.string,
  label: PropTypes.string,
}

export default WorkOrderInvoiceStatusChip
