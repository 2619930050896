import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Tab, styled, Box } from '@mui/material'

import { TabContext, TabPanel, TabList } from '@mui/lab'

import T from 'T'

import DisplayScreen from './RoadPermissions/DisplayScreen'
import DataCaptureScreen from './RoadPermissions/DataCaptureScreen'
import PermissionScreen from './RoadPermissions/PermissionScreen'

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
  padding: 0,
  minWidth: 'auto',
  marginRight: 16,
}))

const Road = ({ readonly = '', roleState = {}, setRoleState = noop, calledBy = '' }) => {
  const { roadTab } = roleState

  const handleChange = (event, newValue) => {
    setRoleState({ roadTab: newValue })
  }

  return (
    <Box minHeight="calc(100vh - 300px)">
      {roleState.allowRoadLogin && (
        <TabContext value={roadTab}>
          <Box mt={1}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <StyledTab label={T.DISPLAY} value={T.DISPLAY} />
              <StyledTab label={T.DATA_CAPTURE} value={T.DATA_CAPTURE} />
              <StyledTab label={T.PERMISSION} value={T.PERMISSION} />
            </TabList>
          </Box>

          <TabPanel value={T.DISPLAY}>
            <DisplayScreen roleState={roleState} setRoleState={setRoleState} readonly={readonly} calledBy={calledBy} />
          </TabPanel>

          <TabPanel value={T.DATA_CAPTURE}>
            <DataCaptureScreen roleState={roleState} setRoleState={setRoleState} readonly={readonly} calledBy={calledBy} />
          </TabPanel>

          <TabPanel value={T.PERMISSION}>
            <PermissionScreen roleState={roleState} setRoleState={setRoleState} readonly={readonly} calledBy={calledBy} />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  )
}

Road.propTypes = {
  calledBy: PropTypes.string,
  readonly: PropTypes.string,
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
}

export default Road
