import React, { useCallback } from 'react'
import { Box, Button, Grid, Menu, MenuItem, Tooltip } from '@mui/material'
import { toast } from 'react-toastify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { canAddInvoices, canUpdatePostInvoicesPayment } from 'data/permissions/permissionsSelectors'
import T from 'T'
import {
  filterInvoicesSelectionModel,
  setInvoicesIsOpenInvoiceActionMenu,
  setInvoicesSelectionModel,
  setInvoicesSendPostData,
  setInvoicesDeleteConfirmation,
  setInvoicesRegenerateConfirmation,
  setBulkSentInvoicesConfirmation,
  setAddToGroupDialogState,
  setConfirmInvoiceBulkActionDialog,
} from 'slices/billing/invoicesSlice'
import api from 'api'
import { CACHE_TAG_INVOICE_LIST } from 'api/cacheTagTypes'
import { handleError } from 'utils/error'
import { downloadInvoiceBlobListInChunks } from 'utils/file'
import { getInvoicesPDFStatus, isDraftInvoice } from 'utils/billing'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { usePostInvoiceListMutation } from 'api/billing/postInvoiceList'
import { useInvoiceBulkMarkPaidMutation } from 'api/billing/invoiceBulkMarkPaid'
import { useInvoiceBulkMarkSentMutation } from 'api/billing/invoiceBulkMarkSent'
import { isPostInvoiceBtnEnabled } from 'data/billing/InvoiceActionSelectors'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import ConfirmInvoiceBulkActionDialog from 'containers/billing/ConfirmInvoiceBulkActionDialog'
import { MARK_AS_SENT_BULK_ACTION, REGENERATION_BULK_ACTION } from 'containers/billing/settings/invoices'
import SaveViews from './SecondaryNavbar/SaveViews'
import AddToGroupDialog from '../groups/invoice/AddToGroupDialog'
import BulkSentInvoicesConfirmationDialog from './BulkSentInvoicesConfirmationDialog'

const TIMEOUT = 4000

const InvoicesTopSection = () => {
  const dispatch = useDispatch()
  const [sendInvoiceList, { isLoading: isSendInvoiceLoading }] = useSendInvoiceListMutation()
  const [postInvoiceList, { isLoading: isPostInvoiceLoading }] = usePostInvoiceListMutation()
  const [invoiceBulkMarkPaid, { isLoading: isBulkMarkPaidLoading }] = useInvoiceBulkMarkPaidMutation()
  const [invoiceBulkMarkSent, { isLoading: isBulkMarkSentLoading }] = useInvoiceBulkMarkSentMutation()
  const [regenerateInvoiceList, { isLoading: isInvoiceRegenerationLoading }] = useRegenerateInvoiceListMutation()
  const isListLoading = useSelector(state => state.invoices.isListLoading)
  const isLoading =
    isListLoading ||
    isSendInvoiceLoading ||
    isPostInvoiceLoading ||
    isBulkMarkSentLoading ||
    isBulkMarkPaidLoading ||
    isInvoiceRegenerationLoading
  const isOpenInvoiceActionMenu = useSelector(state => state.invoices.isOpenInvoiceActionMenu)
  const markAsSentAction = useSelector(state => state.invoices.markAsSentAction, shallowEqual)
  const markAsPaidAction = useSelector(state => state.invoices.markAsPaidAction, shallowEqual)
  const regenerateAction = useSelector(state => state.invoices.regenerateAction, shallowEqual)
  const deleteAction = useSelector(state => state.invoices.deleteAction, shallowEqual)
  const selectionModel = useSelector(state => state.invoices.selectionModel, shallowEqual)
  const selectedInvoiceIdList = useSelector(state => state.invoices.selectedInvoiceIdList, shallowEqual)
  const selectedRows = useSelector(state => state.invoices.selectedRows, shallowEqual)
  const selectedRowsStatusCount = useSelector(state => state.invoices.selectedRowsStatusCount, shallowEqual)
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const isOpenAddToGroupDialog = useSelector(state => state.invoices.addToGroup.isOpen)
  const addToGroupInvoiceIds = useSelector(state => state.invoices.addToGroup.invoiceIds)
  const addInvoices = useSelector(canAddInvoices, shallowEqual)
  const updatePostInvoicesPayment = useSelector(canUpdatePostInvoicesPayment, shallowEqual)

  const getDraftAndNonDraftInvoicesBySelectedRows = (key = 'invoiceId') => {
    const draftInvoices = []
    const nonDraftInvoices = []
    selectedRows.forEach(invoice => {
      if (isDraftInvoice(invoice.status)) draftInvoices.push(get(invoice, key))
      else nonDraftInvoices.push(key ? get(invoice, key) : invoice)
    })

    return { draftInvoices, nonDraftInvoices }
  }

  const handleSendInvoicesWithoutPDF = notExists => {
    dispatch(filterInvoicesSelectionModel({ selectionModel: notExists }))
    dispatch(setInvoicesSendPostData({ draftInvoicesWithoutPDF: notExists, isOpenInvoiceSendFailureConfirmation: true }))
  }

  const handleRefetch = (timeout = 0) => setTimeout(() => dispatch(api.util.invalidateTags([CACHE_TAG_INVOICE_LIST])), timeout)

  const handleAPISuccess = ({ message, refetch = false, timer = false, clearSelection = false }) => {
    if (clearSelection) dispatch(setInvoicesSelectionModel({ selectionModel: [], rows: [] }))

    if (timer) {
      toast.success(message, {
        hideProgressBar: false,
        autoClose: TIMEOUT,
        onClose: () => {
          if (refetch) handleRefetch()
        },
      })
      return
    }

    toast.success(message)
    if (refetch) handleRefetch()
  }

  const handleAPISuccessDialogVariant = useCallback(
    action => {
      dispatch(
        setConfirmInvoiceBulkActionDialog({
          open: true,
          action,
          invoiceCount: selectionModel.length,
        })
      )
    },
    [selectionModel.length]
  )

  const handleCloseConfirmInvoiceBulkActionDialog = () => {
    dispatch(
      setConfirmInvoiceBulkActionDialog({
        open: false,
        action: '',
        invoiceCount: '',
      })
    )
    dispatch(setInvoicesSelectionModel({ selectionModel: [], rows: [] }))
  }

  const onSendInvoice = async () => {
    const { draftInvoices, nonDraftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows('invoiceNumber')
    const { withPDF, withoutPDF } = await getInvoicesPDFStatus(draftInvoices)
    const invoicesWithPDF = nonDraftInvoices.concat(withPDF)
    const hasInvoicesWithoutPDF = withoutPDF.length > 0

    if (invoicesWithPDF.length) {
      sendInvoiceList({ invoiceNumbers: invoicesWithPDF })
        .unwrap()
        .then(() => {
          if (hasInvoicesWithoutPDF) {
            handleSendInvoicesWithoutPDF(withoutPDF)
            handleRefetch(TIMEOUT)
            return
          }
          handleRefetch(TIMEOUT)
          dispatch(setBulkSentInvoicesConfirmation({ isOpen: true }))
        })
        .catch(handleError)
    } else if (hasInvoicesWithoutPDF) {
      handleSendInvoicesWithoutPDF(withoutPDF)
    }
  }
  const handleCloseInvoiceActionMenu = () => {
    dispatch(setInvoicesIsOpenInvoiceActionMenu(false))
  }
  const handleOpenInvoiceActionMenu = event => {
    dispatch(setInvoicesIsOpenInvoiceActionMenu(event.currentTarget.id))
  }

  const handleRegenerateInvoiceList = () => {
    regenerateInvoiceList({ invoiceIds: selectedInvoiceIdList })
      .unwrap()
      .then(() => handleAPISuccessDialogVariant(REGENERATION_BULK_ACTION))
      .catch(handleError)
  }

  const handleValidateRegenerateInvoiceList = () => {
    const unPaidInvoices = get(selectedRowsStatusCount, 'unpaid')
    if (unPaidInvoices > 0) {
      const { nonDraftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows('')
      const nonDraftInvoiceNumbers = nonDraftInvoices.map(invoice => get(invoice, 'invoiceNumber'))
      dispatch(
        setInvoicesRegenerateConfirmation({
          isOpen: true,
          invoiceIds: selectedInvoiceIdList,
          invoiceNumbers: nonDraftInvoiceNumbers,
          invoices: nonDraftInvoices,
          isMultiSelect: true,
        })
      )
      return
    }

    handleRegenerateInvoiceList()
  }

  const handleInvoiceBulkMarkPaid = () => {
    invoiceBulkMarkPaid({ invoiceIds: selectedInvoiceIdList })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccess({ message: T.MARK_AS_PAID_SUCCESS, refetch: true, timer: true })
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
    handleCloseInvoiceActionMenu()
  }

  const handleInvoiceBulkMarkSent = () => {
    const { draftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows()
    invoiceBulkMarkSent({ invoiceIds: draftInvoices })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccessDialogVariant(MARK_AS_SENT_BULK_ACTION)
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
    handleCloseInvoiceActionMenu()
  }

  const handlePostInvoice = () => {
    postInvoiceList({ invoiceNumbers: selectionModel })
      .unwrap()
      .then(() => handleAPISuccess({ message: T.POST_INVOICE_SUCCESS, clearSelection: true }))
      .catch(handleError)
    handleCloseInvoiceActionMenu()
  }

  const handleDownloadInvoiceListPDF = () => {
    if (selectionModel.length === 0) return
    downloadInvoiceBlobListInChunks(selectionModel, businessId)
    handleCloseInvoiceActionMenu()
  }

  const handleAddToGroupOptionClick = () => {
    dispatch(setAddToGroupDialogState({ isOpen: true, invoiceIds: selectedInvoiceIdList }))
    handleCloseInvoiceActionMenu()
  }

  const isPostBtnDisabled = () => {
    if (selectedRows.length === 0) return true
    if (selectedRows.length === 1) return !isPostInvoiceBtnEnabled(selectedRows[0])
    return false
  }

  const handleDeleteInvoice = () => {
    const { draftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows('invoiceNumber')
    dispatch(setInvoicesDeleteConfirmation({ isOpen: true, invoiceIds: selectedInvoiceIdList, invoiceNumbers: draftInvoices }))
    handleCloseInvoiceActionMenu()
  }

  const showPostAction = updatePostInvoicesPayment && !isPostBtnDisabled()
  const showInvoiceActionMenu = markAsSentAction.show || markAsPaidAction.show || selectionModel.length > 0

  return (
    <Box p={3} width="100%">
      <Grid container sx alignItems="flex-end">
        <Grid item xs>
          <SaveViews isLoading={isLoading} />
        </Grid>
        <Grid item container alignItems="flex-end" xs="auto">
          {addInvoices && regenerateAction.show && (
            <Tooltip
              title={regenerateAction.disabled && T.REGENERATE_INVOICE_DISABLED_MSG}
              disableHoverListener={!regenerateAction.disabled}
            >
              <span>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={regenerateAction.disabled || isLoading}
                  onClick={handleValidateRegenerateInvoiceList}
                >
                  {T.REGENERATE}
                </LoadingButton>
              </span>
            </Tooltip>
          )}
          {addInvoices && (
            <Button
              sx={{ ml: 1 }}
              size="small"
              // Only disable if single row
              disabled={selectionModel.length === 0 || isLoading}
              variant="outlined"
              onClick={onSendInvoice}
            >
              {T.SEND}
            </Button>
          )}
          {addInvoices && showInvoiceActionMenu && (
            <>
              <Button
                id="invoiceActionMenuButton"
                variant="outlined"
                color="textSecondary"
                size="small"
                sx={{ ml: 1, minWidth: 'auto', p: 0.6, svg: { color: 'text.primary' } }}
                onClick={handleOpenInvoiceActionMenu}
                disabled={isLoading}
              >
                <MoreVertIcon sx={{ width: 20, height: 20 }} />
              </Button>
              <Menu
                className="configurator-container"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  variant: 'outlined',
                }}
                open={Boolean(isOpenInvoiceActionMenu)}
                anchorEl={document.getElementById(isOpenInvoiceActionMenu)}
                onClose={handleCloseInvoiceActionMenu}
              >
                <MenuItem onClick={handleAddToGroupOptionClick}>{T.ADD_TO_INVOICE_GROUP}</MenuItem>
                <MenuItem onClick={handleDownloadInvoiceListPDF}>{`${T.DOWNLOAD} ${T.PDF}`}</MenuItem>
                {markAsSentAction.show && <MenuItem onClick={handleInvoiceBulkMarkSent}>{T.MARK_AS_SENT}</MenuItem>}
                {markAsPaidAction.show && (
                  <Tooltip
                    title={markAsPaidAction.disabled && T.MARK_AS_PAID_DISABLED_MSG}
                    disableHoverListener={!markAsPaidAction.disabled}
                  >
                    <span>
                      <MenuItem disabled={markAsPaidAction.disabled} onClick={handleInvoiceBulkMarkPaid}>
                        {T.MARK_AS_PAID}
                      </MenuItem>
                    </span>
                  </Tooltip>
                )}
                {showPostAction && <MenuItem onClick={handlePostInvoice}>{T.POST}</MenuItem>}
                {deleteAction.show && (
                  <Tooltip title={deleteAction.disabled && T.DELETE_INVOICE_DISABLED_MSG} disableHoverListener={!deleteAction.disabled}>
                    <span>
                      <MenuItem sx={{ color: 'error.main' }} disabled={deleteAction.disabled} onClick={handleDeleteInvoice}>
                        {T.DELETE}
                      </MenuItem>
                    </span>
                  </Tooltip>
                )}
              </Menu>
            </>
          )}
        </Grid>
      </Grid>
      <ConfirmInvoiceBulkActionDialog
        onConfirm={handleCloseConfirmInvoiceBulkActionDialog}
        onClose={handleCloseConfirmInvoiceBulkActionDialog}
      />
      <BulkSentInvoicesConfirmationDialog />
      <AddToGroupDialog
        isOpen={isOpenAddToGroupDialog}
        invoiceIds={addToGroupInvoiceIds}
        onClose={() => dispatch(setAddToGroupDialogState({ isOpen: false, invoiceIds: [] }))}
      />
    </Box>
  )
}
export default InvoicesTopSection
