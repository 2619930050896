import React from 'react'
import PropTypes from 'prop-types'
import { DialogActions } from '@mui/material'

const HHDialogActions = ({ sx, children }) => {
  return <DialogActions sx={{ px: 3, paddingBottom: 2, paddingTop: 1, ...sx }}>{children}</DialogActions>
}

HHDialogActions.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
}

export default HHDialogActions
