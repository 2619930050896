import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import sumBy from 'lodash/sumBy'
import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'

import Button from '@mui/material/Button'

import { Document } from '@styled-icons/heroicons-outline/Document'

import { addFormDirtyClass } from 'utils/dom'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { formatCentsToDollars, formatDollarsToCents } from 'utils/price'
import { WORK_ORDER_STATUS } from 'settings/constants/service'
import { putIsLoading } from 'middleware/actions/response'
import { getPricingTableContent } from 'middleware/actions/pricing'
import { getBillingInvoiceWorkOrder } from 'middleware/actions/billing'
import { useUpdateInvoiceFeeMutation } from 'api/billing/updateInvoiceFee'
import { useLazyGetInvoiceFeeListQuery } from 'api/billing/getInvoiceFeeList'

import T from 'T'
import Loader from 'components/common/loader'
import WorkOrdersPhotos from 'components/drawer/work-orders-photos'
import FooterButton from 'components/buttons/FooterButton'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Divider, Stack } from '@mui/material'
import Box from '@mui/material/Box'

import { generalFeeDefaultFilter } from './filter'
import WorkOrderTable from './workorder/WorkOrderTable'
import FeesAdjustmentTable from './fees-adjustment/FeesAdjustmentTable'
import './style.scss'

const { SCHEDULED, IN_PROGRESS, SKIPPED, CANCELED, SERVICED } = WORK_ORDER_STATUS
const STATE_DELAY = 100

const InvoiceEdit = ({
  isOpen = false,
  className,
  invoiceNumber,
  accountId,
  accountName,
  invoiceDate,
  invoiceId,
  unPostedAmountCents,
  invoiceStatus,
  onInvoiceSideView,
  onClose,
  onRefreshTable,
}) => {
  const dispatch = useDispatch()
  const [getInvoiceFeeList, { isFetching: isInvoiceFeeListLoading }] = useLazyGetInvoiceFeeListQuery()
  const [updateInvoiceFee, { isLoading: isUpdateInvoiceLoading }] = useUpdateInvoiceFeeMutation()

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isDirty: false,
    workOrderList: [],
    currentWorkOrder: {},
    showImageModel: false,
    feesList: [],
    instanceFeeList: [],
    filteredinstanceFeeList: [],
    removeFeeIds: [],
    invoiceTotal: 0,
    initialFeeTotal: 0,
  })

  const {
    isDirty,
    workOrderList,
    currentWorkOrder,
    showImageModel,
    feesList,
    instanceFeeList,
    filteredinstanceFeeList,
    removeFeeIds,
    invoiceTotal,
    initialFeeTotal,
  } = localState

  const isSubmitBtnDisabled = (() => {
    if (isDirty && removeFeeIds.length) {
      return false
    }

    if (isDirty && feesList.find(fee => fee.feeRefId)) {
      return false
    }

    return true
  })()
  const exceptions = get(currentWorkOrder, 'exceptions', [])
  const exceptionsWithPhotos = exceptions.filter(e => get(e, 'fileLocation', false))

  const handleStateChange = (key, value) => setLocalState({ [key]: value, isDirty: true })

  const handleImageIconClick = (showImageModelStatus, workOrder) => {
    setLocalState({ showImageModel: showImageModelStatus, currentWorkOrder: workOrder })
  }

  const adjustedTotal = (() => {
    let validFeeTotal = 0
    feesList.forEach(fee => {
      if (!Number.isNaN(parseFloat(fee.value))) {
        validFeeTotal += parseFloat(fee.value)
      }
    })
    return invoiceTotal - initialFeeTotal + validFeeTotal
  })()

  const handleAddRemoveFee = (index = '', removeId = '') => {
    if (index === '') {
      // add fee
      const feeListClone = feesList.map(fee => ({ ...fee, inlineEdit: false }))
      handleStateChange('feesList', [...feeListClone, { feeRefId: '', feeName: '', note: '', value: '', inlineEdit: true }])
      return
    }
    // remove fee

    const newState = {
      feesList: feesList.filter((fee, feeIndex) => feeIndex !== index),
      isDirty: true,
    }

    if (removeId) {
      // push deleteId
      set(newState, 'removeFeeIds', [...removeFeeIds, { feeId: removeId }])
    }

    setLocalState(newState)
  }

  const handleRowInlineClick = index =>
    handleStateChange(
      'feesList',
      feesList.map((fee, feeIndex) => (feeIndex === index ? { ...fee, inlineEdit: true } : { ...fee, inlineEdit: false }))
    )

  const handleRowDataChange = (index, key, value, feeName, showNote, price) => {
    const feeClone = cloneDeep(feesList)
    // We can also use map to update
    set(feeClone, `[${index}].${key}`, value)

    if (showNote) {
      set(feeClone, `[${index}].showNote`, showNote)
    }

    if (feeName) {
      set(feeClone, `[${index}].feeName`, feeName)
    }

    if (price >= 0) {
      set(feeClone, `[${index}].value`, price)
    }

    handleStateChange('feesList', feeClone)
  }

  const handleFeesResponse = res => {
    const fees = get(res, 'fees', [])
    setLocalState({
      isDirty: false,
      removeFeeIds: [],
      feesList: fees,
      // Later manage all calculations in cents once BE updates all invoice money fields
      invoiceTotal: formatCentsToDollars(get(res, 'totalCents', 0)),
      initialFeeTotal: sumBy(fees, 'value'),
    })
  }

  const handleFeeChanges = () => {
    const payload = {
      invoiceId,
    }

    // Discard empty rows
    const validFeeRows = feesList.filter(fee => fee.feeRefId)
    if (validFeeRows.length) {
      const fees = validFeeRows.map(row => ({ ...row, valueCents: formatDollarsToCents(row.value) }))
      set(payload, 'fees', fees)
    }

    if (removeFeeIds.length) {
      set(payload, 'removeFees', removeFeeIds)
    }

    updateInvoiceFee(payload)
      .unwrap()
      .then(() => {
        toast.success(T.FEES_ADJUSTED, {
          hideProgressBar: false,
          autoClose: 4000,
          onClose: () => onRefreshTable(),
        })
        onClose(false)
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (!isOpen || !invoiceNumber || !invoiceId) {
      return
    }

    dispatch(putIsLoading(true))

    const WOPromise = new Promise(resolve => {
      const workOderBody = {
        invoiceNumber,
        requestedPage: 0,
        requestedPageSize: 100,
        statuses: [SCHEDULED, IN_PROGRESS, SKIPPED, CANCELED, SERVICED],
      }
      dispatch(
        getBillingInvoiceWorkOrder(workOderBody, workOrderResponce => {
          setLocalState({ workOrderList: workOrderResponce })
          resolve()

          setTimeout(() => {
            const woHeightElRef = document.querySelector('.invoice-edit-container.work-order-table')
            if (woHeightElRef) {
              const woHeight = document.querySelector('.invoice-edit-container.work-order-table').clientHeight
              // Subtract header, footer & work order height
              document.querySelector('.invoice-edit-container.fee-adjustment-table').style.height = `calc(100vh - 335px - ${woHeight}px)`
            }
          }, STATE_DELAY)
        })
      )
    })

    const nonSubFeePromise = new Promise(resolve => {
      dispatch(
        getPricingTableContent(generalFeeDefaultFilter, tableRows => {
          const rows = tableRows.map(row => ({ feeRefId: row.id, value: row.value, feeName: row.feeName, valueType: row.valueType }))
          setLocalState({ instanceFeeList: rows, filteredinstanceFeeList: rows })
          resolve()
        })
      )
    })

    const invoiceFeePromise = new Promise(resolve => {
      getInvoiceFeeList({ invoiceId })
        .unwrap()
        .then(response => handleFeesResponse(response))
        .catch(handleError)
        .finally(() => resolve())
    })

    Promise.all([WOPromise, nonSubFeePromise, invoiceFeePromise])
      .catch(() => {})
      .finally(() => dispatch(putIsLoading(false)))
  }, [isOpen, invoiceNumber])

  return (
    <>
      {(isInvoiceFeeListLoading || isUpdateInvoiceLoading) && <Loader />}
      <section className={`${className} ${addFormDirtyClass(isDirty)}`}>
        <Box>
          <HHDialogTitle
            title={[T.EDIT_INVOICE, accountName, invoiceNumber].join(' - ')}
            icon={<Document className="icon-w-16 cursor-pointer header-icon" />}
            onClose={() => onClose(false)}
            TitleTypographyProps={{
              variant: 'h6',
            }}
          />
          <Divider />
        </Box>

        <div className="content common-padding-lr-3-5 transparent-scroll">
          <Box mt={2} className="wo-block">
            <span className="heading">{T.WORK_ORDER}</span>
            <WorkOrderTable workOrderList={workOrderList} invoiceNumber={invoiceNumber} onImageIconClick={handleImageIconClick} />
          </Box>

          <Box mt={4} className="fee-adjust-block">
            <Stack alignItems="center" justifyContent="space-between" flexDirection="row">
              <span className="heading">{T.INVOICE_FEES_ADJUSTMENTS}</span>
              <Button size="small" sx={{ ml: 2 }} variant="outlined" onClick={() => handleAddRemoveFee()}>
                {T.ADD}
              </Button>
            </Stack>

            <FeesAdjustmentTable
              feesList={feesList}
              adjustedTotal={adjustedTotal}
              instanceFeeList={instanceFeeList}
              filteredinstanceFeeList={filteredinstanceFeeList}
              onStateChange={handleStateChange}
              onAddRemoveFee={handleAddRemoveFee}
              onRowInlineClick={handleRowInlineClick}
              onRowDataChange={handleRowDataChange}
            />
          </Box>
        </div>

        <Stack
          sx={{ position: 'fixed' }}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          className="total-block common-padding-lr-3-5"
        >
          <span className="total">{T.TOTAL}</span>
          <Box sx={{ ml: 2, mr: 4 }} className="price">
            <HHDisplayMoney value={adjustedTotal} formatToDollars={false} />
          </Box>
        </Stack>

        <Box position="fixed" className="footer common-padding-lr-3-5 bg-white">
          <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
            <FooterButton
              leftButtonTitle={T.CANCEL}
              hasBackButton
              backButtonTitle={T.BACK}
              rightButtonTitle={T.SAVE}
              isProceedButtonMUIType
              onBack={() => {
                onClose(false)
                onInvoiceSideView(accountId, invoiceNumber, invoiceId, unPostedAmountCents, accountName, invoiceDate, invoiceStatus)
              }}
              onClose={() => onClose(false)}
              disabledProceed={adjustedTotal < 0 || isSubmitBtnDisabled}
              onProceed={handleFeeChanges}
            />
          </Stack>
        </Box>

        <WorkOrdersPhotos
          exceptions={exceptionsWithPhotos}
          isOpenDrawer={showImageModel}
          onClose={() => setLocalState({ showImageModel: false, currentWorkOrder: null })}
        />
      </section>
    </>
  )
}

InvoiceEdit.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  invoiceNumber: PropTypes.number,
  accountId: PropTypes.string.isRequired,
  accountName: PropTypes.string,
  invoiceDate: PropTypes.string,
  invoiceId: PropTypes.string,
  unPostedAmountCents: PropTypes.number,
  invoiceStatus: PropTypes.string,
  onInvoiceSideView: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefreshTable: PropTypes.func.isRequired,
}

export default InvoiceEdit
