import React from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { Box, Typography, Link, ListItem, ListItemText, ListItemIcon } from '@mui/material'

import { getCustomerDetailsPageUrl } from 'router/routes'

const RenderSecondaryText = ({ row = {}, rightSecondaryText = '' }) => {
  const { accountId, accountNumber, billingProfile } = row

  return (
    <ListItem disablePadding sx={{ mt: 1 }}>
      <ListItemText
        sx={{ p: 0, m: 0 }}
        primary={
          <Box display="flex" gap={0.5}>
            <Typography variant="subtitle1">
              <Link
                component={RouterLink}
                to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`}
                rel="noopener noreferrer"
                target="_blank"
                underline="hover"
              >
                {accountNumber}
              </Link>
            </Typography>
            <Typography variant="caption" noWrap>{`| ${billingProfile}`}</Typography>
          </Box>
        }
        primaryTypographyProps={{ noWrap: true }}
      />
      <ListItemIcon>
        <Typography whiteSpace="noWrap" variant="subtitle2">
          {rightSecondaryText}
        </Typography>
      </ListItemIcon>
    </ListItem>
  )
}

RenderSecondaryText.propTypes = {
  row: PropTypes.object,
  rightSecondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default RenderSecondaryText
