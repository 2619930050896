import React from 'react'

import { shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { useGetRouteMetadataQuery } from 'api/route/getRouteMetadata'

import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import Loader from 'components/common/loader'
import WorkOrdersReviewDialog from './WorkOrdersReviewDialog'

const WorkOrdersReview = () => {
  const { isLoading } = useGetRouteMetadataQuery()
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  return (
    <>
      {isLoading && <Loader />}
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <WorkOrdersReviewDialog />
      </BillingLayout>
    </>
  )
}

export default WorkOrdersReview
