import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@mui/material'

import { get } from 'utils/lodash'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const RenderhHerarchyRow = ({ row = {}, rowNode = {} }) => {
  const { groupName, totalCents } = row
  const childCount = get(rowNode, 'children', []).length

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Typography variant="h6" fontWeight={400} color="textPrimary">
        {groupName}
      </Typography>
      <Typography variant="h6" fontWeight={500} color="textPrimary">
        {childCount > 0 ? `${childCount} / ` : ''}
        <HHDisplayMoney value={totalCents} />
      </Typography>
    </Box>
  )
}

RenderhHerarchyRow.propTypes = {
  row: PropTypes.object,
  rowNode: PropTypes.object,
}

export default RenderhHerarchyRow
