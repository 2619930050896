import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, CardHeader, CardContent, Box, Tooltip, Grid, Divider, useTheme, IconButton } from '@mui/material'
import { ReactComponent as InvoiceIcon } from 'assets/Invoice.svg'
import { ReactComponent as SackDollarIcon } from 'assets/SackDollar.svg'
import { UserGroup } from '@styled-icons/heroicons-solid/UserGroup'

import { get } from 'utils/lodash'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { AGING_BUCKET_LABEL, AGING_BUCKET_NAME, BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { UserGroup as UserGroupOutlined } from '@styled-icons/heroicons-outline/UserGroup'

import routes from 'router/routes'
import { formatCentsToDollars, shortenMoneyValue } from 'utils/price'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Link from 'components/common/Link'
import T from 'T'
import { ReactComponent as UserGroupRemoveOutlinedIcon } from 'assets/UserGroupRemoveOutlined.svg'
import { useMatch } from 'react-router-dom-v5-compat'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import AccountGroupDefaultCardListItem from './AccountGroupDefaultCardListItem'
import AccountGroupActionMenu from './AccountGroupActionMenu'

const { DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const AccountGroupDefaultCard = ({ id, data, isLoading }) => {
  const theme = useTheme()
  const [accountGroupAnchorEl, setAccountGroupAnchorEl] = useState(null)
  const { data: accountGroupDetailsData, isLoading: isLoadingDetails, isFetching } = useGetAccountGroupDetailsData(id, true)
  const loading = useMemo(() => isLoading || isLoadingDetails || isFetching)
  const label = get(AGING_BUCKET_LABEL, id, '')
  const color = get(AGING_BUCKET_COLORS, id, '')
  const accountGroupDetailsPage = `${routes.app.accountGroups}/${id}`
  const suspensionGroupDetailsPage = `${routes.app.suspensionGroups}/${id}`
  const shouldShortenValue = value => {
    return Math.abs(value) >= 1e6
  }
  const accountGroupsPathMatch = useMatch(`${routes.app.accountGroups}/*`)
  const isAccountGroupsPath = useMemo(() => Boolean(accountGroupsPathMatch), [accountGroupsPathMatch])
  const handleOpenAccountGroupActionMenu = event => setAccountGroupAnchorEl(event.currentTarget)
  const handleCloseAccountGroupActionMenu = () => setAccountGroupAnchorEl(false)

  const getDataByAgingBucket = id => {
    if (isLoading) return {}
    const oneThirty = get(data, 'oneThirty[0]', {})
    const thirtySixty = get(data, 'thirtySixty[0]', {})
    const sixtyNinety = get(data, 'sixtyNinety[0]', {})
    const overNinety = get(data, 'overNinety[0]', {})
    if (id === DUE_ONE_THIRTY) {
      return oneThirty
    }
    if (id === DUE_THIRTY_ONE_SIXTY) {
      return thirtySixty
    }
    if (id === DUE_SIXTY_ONE_NINETY) {
      return sixtyNinety
    }
    if (id === DUE_OVER_NINETY) {
      return overNinety
    }
  }

  const {
    accountCount = 0,
    invoiceCount = 0,
    suspendedCount = 0,
    totalCents = 0,
  } = useMemo(() => getDataByAgingBucket(id), [isLoading, id, data])
  const fixedTotal = useMemo(() => {
    const total = formatCentsToDollars(totalCents)
    return shouldShortenValue(total)
      ? `${shortenMoneyValue(total, 2, 2, '$')}`
      : `$${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  }, [totalCents])

  return (
    <>
      <Card variant="outlined" sx={{ boxShadow: `0px 1px 18px 0px ${theme.palette.action.disabledBackground}`, border: 'none' }}>
        <CardHeader
          sx={{
            py: 1,
            bgcolor: color[50],
            borderBottom: '2px solid',
            borderColor: color[500],
          }}
          avatar={<UserGroup width={24} height={24} color={color[500]} />}
          title={
            <Typography noWrap variant="h5" fontWeight={700} ml={2}>
              {label}
            </Typography>
          }
          action={
            <>
              <Tooltip title={T.VIEW_ACCOUNT_GROUP_DETAIL}>
                <IconButton component={Link} to={isAccountGroupsPath ? accountGroupDetailsPage : suspensionGroupDetailsPage}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleOpenAccountGroupActionMenu}>
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Box>
            <Grid container gap={1}>
              <Grid container columnGap={2} xs={12}>
                <Grid item xs>
                  <AccountGroupDefaultCardListItem
                    label="Total due"
                    value={
                      <Tooltip title={<HHDisplayMoney value={totalCents} formatToDollars />} arrow>
                        {fixedTotal}
                      </Tooltip>
                    }
                    isLoading={isLoading}
                    Icon={SackDollarIcon}
                  />
                </Grid>
                <Grid sx="auto">
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs>
                  <AccountGroupDefaultCardListItem
                    label="Total invoices"
                    value={<HHDisplayMoney value={invoiceCount} formatToDollars={false} prefix="" decimalScale={0} />}
                    isLoading={isLoading}
                    Icon={InvoiceIcon}
                  />
                </Grid>
              </Grid>
              <Grid container columnGap={2} xs={12}>
                <Grid item xs>
                  <AccountGroupDefaultCardListItem
                    label="Total accounts"
                    value={<HHDisplayMoney value={accountCount} formatToDollars={false} prefix="" decimalScale={0} />}
                    isLoading={isLoading}
                    Icon={UserGroupOutlined}
                  />
                </Grid>
                <Grid sx="auto">
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item xs>
                  <AccountGroupDefaultCardListItem
                    label="Suspended accounts"
                    value={suspendedCount}
                    isLoading={isLoading}
                    Icon={UserGroupRemoveOutlinedIcon}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      {accountGroupAnchorEl && (
        <AccountGroupActionMenu
          anchorEl={accountGroupAnchorEl}
          selectedGroup={{ ...data, ...accountGroupDetailsData, groupId: get(BUCKET_NAME_TO_GROUP_ID_PARAM, id) }}
          showAddToAccountGroupOption
          showBulkSuspendOption
          showBulkUnsuspendOption
          loading={loading}
          onClose={handleCloseAccountGroupActionMenu}
        />
      )}
    </>
  )
}

AccountGroupDefaultCard.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default AccountGroupDefaultCard
