import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ReactComponent as LocationNoteIcon } from 'assets/note/LocationNote.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import {
  useLazyGetAllLocationNotesQuery,
  useCreateLocationNoteMutation,
  useUpdateLocationNoteMutation,
  usePatchLocationNoteMutation,
} from 'api/notes/locationNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import ViewArchivedNotesAction from '../common/actions/ViewArchivedNotesAction'
import ViewArchiveNotesDialog from '../common/ViewArchiveNotesDialog'
import LocationNotes from './LocationNotes'
import NoteSkeleton from '../common/NoteSkeleton'

const LocationNotesAccordionVariant = ({ locationId = '' }) => {
  const [getAllLocationNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllLocationNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes, archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddLocationNote = event => {
    event.stopPropagation()
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleViewArchiveClick = () => setIsOpenViewArchiveNoteDialog(true)

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  const handleCloseAddLocationNote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (locationId) {
      getAllLocationNotes({ id: locationId }).unwrap().catch(handleError)
    }
  }, [locationId])

  return (
    <>
      <Box px={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <LocationNoteIcon />
          <Typography ml={1} variant="h5" fontWeight={500} color="textSecondary">
            Location notes
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <IconButton onClick={handleOpenAddLocationNote}>
            <AddIcon />
          </IconButton>
          <ViewArchivedNotesAction onViewArchiveClick={handleViewArchiveClick} archivedNotes={archivedNotes} />
        </Box>
      </Box>
      {isGetAllNotesLoading && <NoteSkeleton rows={3} />}
      {!isGetAllNotesLoading && <LocationNotes notes={notes} locationId={locationId} showFullVersion={false} />}
      <AddUpdateNoteDialog
        id={locationId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.LOCATION_NOTE : T.NEW_LOCATION_NOTE}
        existingNote={selectedNote}
        useCreateNoteMutation={useCreateLocationNoteMutation}
        useUpdateNoteMutation={useUpdateLocationNoteMutation}
        onClose={handleCloseAddLocationNote}
      />
      <ViewArchiveNotesDialog
        id={locationId}
        isOpen={isOpenViewArchiveNoteDialog}
        title={T.ARCHIVED_LOCATION_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchLocationNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

LocationNotesAccordionVariant.propTypes = {
  locationId: PropTypes.string,
}

export default LocationNotesAccordionVariant
