import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { noop, orderBy } from 'lodash'

import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import { Menu, MenuItem, ListItemIcon, useTheme, useMediaQuery, ListItemText, Badge, SvgIcon } from '@mui/material'
import { ReactComponent as AccountNoteIcon } from 'assets/note/AccountNote.svg'
import { ReactComponent as BillingNoteIcon } from 'assets/note/BillingNote.svg'
import { User } from '@styled-icons/heroicons-outline/User'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined'
import { CheckCircle } from '@styled-icons/heroicons-outline/CheckCircle'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { ReactComponent as PaperPlane } from 'assets/PaperPlane.svg'
import { ReactComponent as SuspensionIcon } from 'assets/services/hold/SuspensionIcon.svg'
import { ReactComponent as SuspensionProrationOnIcon } from 'assets/SuspensionProrationOnIcon.svg'
import { ReactComponent as SuspensionProrationOffIcon } from 'assets/SuspensionProrationOffIcon.svg'
import { ReactComponent as RentalFeeGracePeriodIcon } from 'assets/RentalFeeGracePeriod.svg'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { getEditAccountModel } from 'utils/customerPayload'
import { setAddContactState } from 'slices/customer/contactsSlice'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import { useActivateAccountMutation } from 'api/accounts/activateAccount'
import { useDeactivateAccountMutation } from 'api/accounts/deactivateAccount'
import { useLazyGetCustomerLocationListQuery } from 'api/accounts/getCustomerLocationList'
import { useLazyGetPortalUsersQuery } from 'api/customer-portal/getPortalUsers'
import { useRemoveAccountsFromGroupMutation } from 'api/groups/account/removeAccountsFromGroup'

import T from 'T'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import InteractiveTagItemMenu from 'components/tags/InteractiveTagItemMenu'
import BillingNotesDialogVariant from 'components/notes/billing/BillingNotesDialogVariant'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'
import EditAccountDialog from 'components/customer-details/content/account-details/edit'
import AddCustomerPortalUserDialog from 'components/customer-details/content/customer-portal/add-portal-user/AddCustomerPortalUserDialog'
import AddEditContactDialog from 'components/customer-details/content/contacts/AddEditContactDialog'
import { getAddModel, ADD_EDIT_CONTACT_PARENT_TYPES } from 'components/customer-details/content/contacts/settings'
import AddToGroupDialog from 'components/customers/groups/account/AddToGroupDialog'
import SuspendAccountDialog from 'components/customer-details/content/suspensions/SuspendAccountDialog/SuspendAccountDialog'
import UnsuspendAccountDialog from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/UnsuspendAccountDialog'
import { useLazyGetAccountSettingsQuery, useUpdateAccountSettingsMutation } from 'api/accounts/accountSettingsCrud'
import LocationsResetGracePeriodDialog from 'components/pricing/RentalFees/ResetGracePeriod/LocationWise/LocationsResetGracePeriodDialog'
import { openConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'

const { ACCOUNTS } = ADD_EDIT_CONTACT_PARENT_TYPES

const AccountCellActionMenu = ({
  anchorEl,
  row = {},
  onSaveTags,
  onClickAccountNotes,
  onClickViewContacts,
  onClose,
  onRefetch,
  showAddToGroup = false,
  showRemoveFromGroup = false,
}) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const suspensionHold = get(row, 'suspensionHold')
  const { id: accountGroupId } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [getAccountSettings, { data: accountSettingsData }] = useLazyGetAccountSettingsQuery()
  const [updateAccountSettings] = useUpdateAccountSettingsMutation()
  const prorateSuspensions = get(accountSettingsData, 'prorateSuspensions')
  const [getAccountDetails, { data: customerDetails }] = useLazyGetAccountDetailsQuery()
  const [getCustomerLocationList, { isFetching: isGetCustomerLocationListLoading }] = useLazyGetCustomerLocationListQuery()
  const [activateAccount] = useActivateAccountMutation()
  const [deactivateAccount, { isLoading: isDeactivateAccountLoading }] = useDeactivateAccountMutation()
  const [getPortalUsers, { data: portalData }] = useLazyGetPortalUsersQuery()
  const [removeAccountsFromGroup] = useRemoveAccountsFromGroupMutation()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [isOpenBillingNotesDialog, setIsOpenBillingNotesDialog] = useState(false)
  const [isOpenDeactivateConfirm, setIsOpenDeactivateConfirm] = useState(false)
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)
  const [isOpenEditAccountDialog, setIsOpenEditAccountDialog] = useState(false)
  const [isOpenAddPortalUserDialog, setIsOpenAddPortalUserDialog] = useState(false)
  const [isOpenAddToGroupDialog, setIsOpenAddToGroupDialog] = useState(false)
  const [isOpenSuspendDialog, setIsOpenSuspendDialog] = useState(false)
  const [isOpenUnsuspendDialog, setIsOpenUnsuspendDialog] = useState(false)
  const [isOpenResetGracePeriodDialog, setIsOpenResetGracePeriodDialog] = useState(false)

  const isOpenAddContactDialog = useSelector(state => get(state, 'Contacts.addContact.isOpen', false), shallowEqual)
  const contactAccountId = useSelector(state => get(state, 'Contacts.addContact.accountId'), shallowEqual)
  const initialMethodValue = useSelector(state => get(state, 'Contacts.addContact.initialMethodValue', ''), shallowEqual)
  const initialFirstName = useSelector(state => get(state, 'Contacts.addContact.initialFirstName', ''), shallowEqual)
  const initialLastName = useSelector(state => get(state, 'Contacts.addContact.initialLastName', ''), shallowEqual)
  const parentType = useSelector(state => get(state, 'Contacts.addContact.parent', ''), shallowEqual)

  const { accountId, accountName, active, activeAccountNoteCount = 0, accountContactCount = 0 } = row
  const allContacts = get(customerDetails, 'contacts', [])
  const portalUserList = orderBy(get(portalData, 'portalUsers', []), ['confirmed'], ['desc'])

  const fetchAccountDetails = async () => {
    dispatch(putIsLoading(true))
    await getAccountDetails({ accountId }).unwrap().catch(handleError)
    dispatch(putIsLoading(false))
  }

  const handleAccountDeactivation = async () => {
    const data = await getCustomerLocationList({ accountId }).unwrap().catch(handleError)
    const activeLocations = Array.isArray(data) ? data.filter(location => location.locActive) : []

    deactivateAccount({ accountId, locations: activeLocations.map(location => location.locationId) })
      .unwrap()
      .then(() => {
        setIsOpenDeactivateConfirm(false)
        onRefetch()
        toast.success(T.ACCOUNT_DEACTIVATED_SUCCESSFULLY)
      })
      .catch(handleError)
  }

  const onOpenConfirmAccountReactivatedDialog = () => {
    dispatch(openConfirmAccountReactivatedDialog())
  }

  const handleAccountActivateDeactivate = () => {
    onClose()
    if (active) {
      // Deactivate account
      setIsOpenDeactivateConfirm(true)
      return
    }
    // Activate account
    activateAccount({ accountId })
      .unwrap()
      .then(() => {
        onRefetch()
        onOpenConfirmAccountReactivatedDialog()
      })
      .catch(handleError)
  }

  const handleClickAccountNotes = event => {
    onClose()
    onClickAccountNotes(event)
  }

  const handleClickContacts = event => {
    onClose()
    onClickViewContacts(event)
  }

  const handleSaveTags = (data, closeTagsDialog) => {
    onClose()
    onSaveTags(data, closeTagsDialog)
  }

  const handleClickViewBillingNotes = () => {
    onClose()
    setIsOpenBillingNotesDialog(true)
  }

  const handleCloseBillingNotesDialog = () => setIsOpenBillingNotesDialog(false)

  const handleOpenTakePaymentDialog = () => {
    onClose()
    setIsOpenTakePaymentsDialog(true)
  }

  const handleCloseTakePaymentDialog = () => setIsOpenTakePaymentsDialog(false)

  const handleOpenEditAccountDialog = async () => {
    onClose()
    await fetchAccountDetails()
    setIsOpenEditAccountDialog(true)
  }

  const handleCloseEditAccountDialog = () => setIsOpenEditAccountDialog(false)

  const handleOpenAddPortalUserDialog = async () => {
    onClose()
    await fetchAccountDetails()
    dispatch(putIsLoading(true))
    await getPortalUsers({ accountId }).unwrap().catch(handleError)
    dispatch(putIsLoading(false))
    setIsOpenAddPortalUserDialog(true)
  }

  const handleCloseAddPortalUserDialog = () => setIsOpenAddPortalUserDialog(false)

  const handleCloseCreateContactDialog = () =>
    dispatch(
      setAddContactState({ isOpen: false, accountId: '', initialFirstName: '', initialLastName: '', initialMethodValue: '', parent: '' })
    )

  const contactModel = initialMethodValue ? getAddModel(initialFirstName, initialLastName, initialMethodValue) : null

  const handleOpenAddToGroupDialog = () => {
    onClose()
    setIsOpenAddToGroupDialog(true)
  }

  const handleCloseAddToGroupDialog = () => setIsOpenAddToGroupDialog(false)

  const handleRemoveAccountFromGroup = () => {
    onClose()
    const payload = { accountGroupId, accounts: [accountId] }
    removeAccountsFromGroup(payload)
      .unwrap()
      .then(() => toast.success(T.REMOVE_ACCOUNT_FROM_GROUP_SUCCESS_MSG))
      .catch(handleError)
  }

  const handleSuspendAction = () => {
    if (suspensionHold) {
      onClose()
      setIsOpenUnsuspendDialog(true)
    } else {
      onClose()
      setIsOpenSuspendDialog(true)
    }
  }

  const handleCloseSuspendDialog = () => {
    setIsOpenSuspendDialog(false)
  }

  const handleCloseUnsuspendDialog = () => {
    setIsOpenUnsuspendDialog(false)
  }

  const handleTurnOnSuspensionProrationClick = () => {
    updateAccountSettings({ accountId, prorateSuspensions: true })
      .unwrap()
      .then(() => {
        toast.success(T.INDIVIDUAL_PRORATION_UPDATED_SUCCESS)
        onClose()
      })
  }

  const handleTurnOffSuspensionProrationClick = () => {
    updateAccountSettings({
      accountId,
      prorateSuspensions: false,
    })
      .unwrap()
      .then(() => {
        toast.success(T.INDIVIDUAL_PRORATION_UPDATED_SUCCESS)
        onClose()
      })
  }

  const handleOpenResetGracePeriodDialog = () => {
    onClose()
    setIsOpenResetGracePeriodDialog(true)
  }

  const handleCloseResetGracePeriodDialog = () => setIsOpenResetGracePeriodDialog(false)

  useEffect(() => {
    if (anchorEl && accountId) {
      getAccountSettings({
        accountId,
      })
    }
  }, [anchorEl, accountId])

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleOpenEditAccountDialog}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <PencilAlt />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>Edit account</ListItemText>
        </MenuItem>
        {isTabletOrMobile && (
          <InteractiveTagItemMenu
            data={{ ...row, name: accountName, accountId, tagDetails: { forAccount: row.tags } }}
            onSave={handleSaveTags}
            disableLocation
            disableService
            onClick={noop}
          />
        )}
        {isTabletOrMobile && (
          <MenuItem onClick={handleClickAccountNotes}>
            <ListItemIcon>
              <Badge badgeContent={activeAccountNoteCount} color="primary">
                <SvgIcon fontSize="small">
                  <AccountNoteIcon />
                </SvgIcon>
              </Badge>
            </ListItemIcon>
            <ListItemText>{`${T.ACCOUNT_NOTE}s`}</ListItemText>
          </MenuItem>
        )}
        {isTabletOrMobile && (
          <MenuItem onClick={handleClickContacts}>
            <ListItemIcon>
              <Badge badgeContent={accountContactCount} color="primary">
                <SvgIcon fontSize="small">
                  <User />
                </SvgIcon>
              </Badge>
            </ListItemIcon>
            <ListItemText>Account contacts</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenAddPortalUserDialog}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <PaperPlane />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>Send portal invite</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleClickViewBillingNotes}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <BillingNoteIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{`${T.BILLING_NOTE}s`}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenTakePaymentDialog}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{T.TAKE_PAYMENT}</ListItemText>
        </MenuItem>
        {isRentalFeeEnabled && (
          <MenuItem onClick={handleOpenResetGracePeriodDialog}>
            <ListItemIcon>
              <RentalFeeGracePeriodIcon />
            </ListItemIcon>
            <ListItemText>{T.RESET_GRACE_PERIOD}</ListItemText>
          </MenuItem>
        )}
        {prorateSuspensions === false && (
          <MenuItem onClick={handleTurnOnSuspensionProrationClick}>
            <ListItemIcon>
              <SvgIcon>
                <SuspensionProrationOnIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.TURN_ON_SUSPENSION_PRORATION}</ListItemText>
          </MenuItem>
        )}
        {prorateSuspensions === true && (
          <MenuItem onClick={handleTurnOffSuspensionProrationClick}>
            <ListItemIcon>
              <SvgIcon>
                <SuspensionProrationOffIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>{T.TURN_OFF_SUSPENSION_PRORATION}</ListItemText>
          </MenuItem>
        )}
        {showAddToGroup && (
          <MenuItem onClick={handleOpenAddToGroupDialog}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{T.ADD_TO_ACCOUNT_GROUP}</ListItemText>
          </MenuItem>
        )}
        {showRemoveFromGroup && (
          <MenuItem onClick={handleRemoveAccountFromGroup}>
            <ListItemIcon>
              <DeleteForeverOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{T.REMOVE_FROM_ACCOUNT_GROUP}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleAccountActivateDeactivate} sx={{ color: active ? 'warning.main' : 'success.main' }}>
          <ListItemIcon sx={{ color: active ? 'warning.main' : 'success.main' }}>
            <SvgIcon fontSize="small">{active ? <PersonOffOutlinedIcon /> : <CheckCircle />}</SvgIcon>
          </ListItemIcon>
          <ListItemText>{`${active ? T.DEACTIVATE : T.REACTIVATE} account`}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSuspendAction} sx={{ color: suspensionHold ? 'success.main' : 'error.main' }}>
          <ListItemIcon sx={{ color: suspensionHold ? 'success.main' : 'error.main' }}>
            <SvgIcon fontSize="small">
              <SuspensionIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{suspensionHold ? T.UNSUSPEND_ACCOUNT : T.SUSPEND_ACCOUNT}</ListItemText>
        </MenuItem>
      </Menu>

      <HHConfirmDialog
        isOpen={isOpenDeactivateConfirm}
        DialogProps={{ maxWidth: 'xs' }}
        ConfirmButtonProps={{
          loading: isGetCustomerLocationListLoading || isDeactivateAccountLoading,
          disabled: isGetCustomerLocationListLoading || isDeactivateAccountLoading,
        }}
        confirmButtonTitle={T.DEACTIVATE}
        confirmTitle="Deactivate account"
        confirmDescription="You're about to deactivate this account. All services and locations in this account will be deactivated. Do you want to continue?"
        onConfirm={handleAccountDeactivation}
        onClose={() => setIsOpenDeactivateConfirm(false)}
      />

      <BillingNotesDialogVariant isOpen={isOpenBillingNotesDialog} accountId={accountId} onClose={handleCloseBillingNotesDialog} />
      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog isOpen={isOpenTakePaymentsDialog} initialAccount={{ id: accountId }} onClose={handleCloseTakePaymentDialog} />
      )}
      {isOpenEditAccountDialog && (
        <EditAccountDialog
          isOpen={isOpenEditAccountDialog}
          account={getEditAccountModel(customerDetails)}
          customerDetails={customerDetails}
          onClose={handleCloseEditAccountDialog}
        />
      )}
      <AddCustomerPortalUserDialog
        isOpen={isOpenAddPortalUserDialog}
        accountId={accountId}
        contacts={allContacts}
        portalUserList={portalUserList}
        parent={ACCOUNTS}
        onClose={handleCloseAddPortalUserDialog}
      />
      {parentType === ACCOUNTS && contactAccountId === accountId && isOpenAddContactDialog && (
        <AddEditContactDialog
          isOpen={isOpenAddContactDialog}
          accountId={accountId}
          contact={contactModel}
          onClose={handleCloseCreateContactDialog}
        />
      )}
      <AddToGroupDialog isOpen={isOpenAddToGroupDialog} accountIds={[accountId]} onClose={handleCloseAddToGroupDialog} />
      <SuspendAccountDialog accountId={accountId} open={isOpenSuspendDialog} onClose={handleCloseSuspendDialog} />
      <UnsuspendAccountDialog accountId={accountId} open={isOpenUnsuspendDialog} onClose={handleCloseUnsuspendDialog} />
      <LocationsResetGracePeriodDialog
        isOpen={isOpenResetGracePeriodDialog}
        accountId={accountId}
        onClose={handleCloseResetGracePeriodDialog}
      />
    </>
  )
}

AccountCellActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  row: PropTypes.object,
  onSaveTags: PropTypes.func.isRequired,
  onClickAccountNotes: PropTypes.func.isRequired,
  onClickViewContacts: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
  showAddToGroup: PropTypes.bool,
  showRemoveFromGroup: PropTypes.bool,
}

export default AccountCellActionMenu
