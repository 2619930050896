import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    emailPaymentReceipts: build.mutation({
      query: body => ({ url: '/api/v1/billing/payment/email_receipt', method: 'POST', body }),
    }),
  }),
})

export const { useEmailPaymentReceiptsMutation } = extendedApi
