/* eslint-disable import/no-cycle */
import T from 'T'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { dateValueFormatter, moneyValueFormatter, yesNoFormatter } from 'components/data_grid/formatters'
import { RenderAccountLinkCell, RenderCheckCell, RenderTagsCell, RenderPriceCell } from 'components/data_grid/render_cell'
import RenderHeaderCell from 'components/data_grid/render_cell/RenderHeaderCell'
import RenderGetAccountsReceivableActionsDataCell from 'components/data_grid/render_cell/RenderGetAccountsReceivableActionsDataCell'
import RenderAccountActiveCell from 'components/data_grid/render_cell/RenderAccountActiveCell'

const {
  CUSTOMER,
  BALANCE,
  LAST_PAYMENT_DATE,
  PRICING_ZONE,
  LATE_FEES,
  PAYMENT_TYPE,
  PAYMENT_TERMS,
  CUSTOMER_ADDRESS,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP,
  LAST_PAYMENT_AMOUNT,
  BILLING_TYPE,
  BILLING_CYCLE,
  BILLING_TIMING,
  BUSINESS_LINE,
  INVOICES_NUMBER,
  ACCOUNT,
  CUSTOMER_BILLING_PROFILE,
  TAGS,
  CONTACT_NAME,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  AUTOPAY,
  AUTOPAY_PAYMENT_CARD,
  AUTOPAY_PAYMENT_METHOD,
  AUTOPAY_EXPIRY,
  ACCOUNT_STATUS,
} = T

export const ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID = [
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 50, resizable: false },
  {
    flex: 1,
    minWidth: 120,
    headerName: `${ACCOUNT} #`,
    tableLabel: `${ACCOUNT} #`,
    label: `${ACCOUNT} #`,
    filterType: 'string',
    field: 'accountNumber',
    columnName: 'accountNumber',
    renderCell: RenderAccountLinkCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: CUSTOMER,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    filterType: 'string',
    field: 'customerName',
    columnName: 'customerName',
    renderCell: RenderAccountLinkCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 130,
    headerName: INVOICES_NUMBER,
    tableLabel: INVOICES_NUMBER,
    label: INVOICES_NUMBER,
    type: 'number',
    filterType: 'number',
    field: 'invoiceCount',
    columnName: 'invoiceCount',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: BALANCE,
    tableLabel: BALANCE,
    label: BALANCE,
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'balanceCents',
    columnName: 'balanceCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: 'Unbilled',
    tableLabel: 'Unbilled',
    label: 'Unbilled',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'unBilledCents',
    columnName: 'unBilledCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: 'Account credit',
    tableLabel: 'Account credit',
    label: 'Account credit',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'customerCreditBalanceCents',
    columnName: 'customerCreditBalanceCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: 'Current',
    tableLabel: 'Current',
    label: 'Current',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'currentCents',
    columnName: 'currentCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: '1-30',
    tableLabel: '1-30',
    label: '1-30',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'dueOneThirtyCents',
    columnName: 'dueOneThirtyCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: '31-60',
    tableLabel: '31-60',
    label: '31-60',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'dueThirtyOneSixtyCents',
    columnName: 'dueThirtyOneSixtyCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: '61-90',
    tableLabel: '61-90',
    label: '61-90',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'dueSixtyOneNinetyCents',
    columnName: 'dueSixtyOneNinetyCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: 'Over 90',
    tableLabel: 'Over 90',
    label: 'Over 90',
    type: 'number',
    filterType: 'number',
    subType: 'money',
    field: 'dueOverNinetyCents',
    columnName: 'dueOverNinetyCents',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  { field: 'actions', type: 'actions', align: 'left', width: 90, resizable: false, getActions: RenderGetAccountsReceivableActionsDataCell },
  {
    flex: 3,
    minWidth: 250,
    headerName: CUSTOMER_ADDRESS,
    tableLabel: CUSTOMER_ADDRESS,
    label: CUSTOMER_ADDRESS,
    filterType: 'string',
    field: 'line1',
    columnName: 'line1',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 144,
    headerName: CUSTOMER_CITY,
    tableLabel: CUSTOMER_CITY,
    label: CUSTOMER_CITY,
    filterType: 'string',
    field: 'city',
    columnName: 'city',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 160,
    headerName: CUSTOMER_STATE,
    tableLabel: CUSTOMER_STATE,
    label: CUSTOMER_STATE,
    filterType: 'string',
    field: 'state',
    columnName: 'state',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 140,
    headerName: CUSTOMER_ZIP,
    tableLabel: CUSTOMER_ZIP,
    label: CUSTOMER_ZIP,
    filterType: 'string',
    field: 'zipCode',
    columnName: 'zipCode',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 180,
    headerName: LAST_PAYMENT_DATE,
    label: LAST_PAYMENT_DATE,
    tableLabel: LAST_PAYMENT_DATE,
    type: 'date',
    filterType: 'date',
    subType: 'date',
    field: 'transactionDate',
    columnName: 'transactionDate',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateValueFormatter,
  },
  {
    flex: 1,
    minWidth: 180,
    headerName: LAST_PAYMENT_AMOUNT,
    label: LAST_PAYMENT_AMOUNT,
    tableLabel: LAST_PAYMENT_AMOUNT,
    type: 'number',
    filterType: 'number',
    subType: 'price',
    field: 'transactionAmountCents',
    columnName: 'transactionAmountCents',
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: BILLING_TYPE,
    tableLabel: BILLING_TYPE,
    label: BILLING_TYPE,
    filterType: 'string',
    field: 'paymentType',
    columnName: 'paymentType',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: BILLING_CYCLE,
    tableLabel: BILLING_CYCLE,
    label: BILLING_CYCLE,
    filterType: 'meta',
    field: 'billingCycleText',
    columnName: 'billingCycleText',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: BILLING_TIMING,
    tableLabel: BILLING_TIMING,
    label: BILLING_TIMING,
    filterType: 'string',
    columnName: 'timing',
    field: 'timing',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: BUSINESS_LINE,
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    type: 'number',
    filterType: 'number',
    columnName: 'businessLinesCount',
    field: 'businessLinesCount',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: PRICING_ZONE,
    tableLabel: PRICING_ZONE,
    label: PRICING_ZONE,
    filterType: 'string',
    subType: 'pricingZones',
    columnName: 'pricingZones',
    field: 'pricingZones',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: PAYMENT_TYPE,
    tableLabel: PAYMENT_TYPE,
    label: PAYMENT_TYPE,
    filterType: 'string',
    subType: 'paymentMethod',
    columnName: 'paymentMethod',
    field: 'paymentMethod',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: PAYMENT_TERMS,
    tableLabel: PAYMENT_TERMS,
    label: PAYMENT_TERMS,
    filterType: 'meta',
    field: 'paymentTermsText',
    columnName: 'paymentTermsText',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: LATE_FEES,
    tableLabel: LATE_FEES,
    label: LATE_FEES,
    type: 'number',
    filterType: 'number',
    subType: 'price',
    columnName: 'lateFeeAmount',
    field: 'lateFeeAmount',
    renderCell: RenderPriceCell,
  },
  {
    flex: 2,
    minWidth: 200,
    headerName: CUSTOMER_BILLING_PROFILE,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    filterType: 'meta',
    field: 'customerProfileName',
    columnName: 'customerProfileName',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 250,
    sortable: false,
    headerName: TAGS,
    tableLabel: TAGS,
    label: TAGS,
    filterType: 'string',
    field: 'tags',
    columnName: 'tags',
    subType: 'tags',
    renderCell: RenderTagsCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 200,
    headerName: CONTACT_NAME,
    tableLabel: CONTACT_NAME,
    label: CONTACT_NAME,
    filterType: 'string',
    columnName: 'contactName',
    field: 'contactName',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: CONTACT_PHONE,
    tableLabel: CONTACT_PHONE,
    label: CONTACT_PHONE,
    filterType: 'string',
    columnName: 'contactNumber',
    field: 'contactNumber',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 200,
    headerName: CONTACT_EMAIL,
    tableLabel: CONTACT_EMAIL,
    filterType: 'string',
    columnName: 'email',
    field: 'email',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: AUTOPAY,
    tableLabel: AUTOPAY,
    label: AUTOPAY,
    filterType: 'checkbox',
    subType: 'autoPay',
    isSingleSelect: true,
    isBoolean: true,
    columnName: 'autoPay',
    field: 'autoPay',
    renderHeader: RenderHeaderCell,
    RenderCheckCell,
    valueFormatter: yesNoFormatter,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: AUTOPAY_PAYMENT_METHOD,
    tableLabel: AUTOPAY_PAYMENT_METHOD,
    label: AUTOPAY_PAYMENT_METHOD,
    filterType: 'checkbox',
    subType: 'autoPaymentType',
    columnName: 'autoPaymentType',
    field: 'autoPaymentType',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: AUTOPAY_PAYMENT_CARD,
    tableLabel: AUTOPAY_PAYMENT_CARD,
    label: AUTOPAY_PAYMENT_CARD,
    filterType: 'string',
    columnName: 'autoPaymentMethod',
    field: 'autoPaymentMethod',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 150,
    headerName: AUTOPAY_EXPIRY,
    tableLabel: AUTOPAY_EXPIRY,
    label: AUTOPAY_EXPIRY,
    filterType: 'string',
    columnName: 'autoPaymentExpiry',
    field: 'autoPaymentExpiry',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 110,
    headerName: ACCOUNT_STATUS,
    tableLabel: ACCOUNT_STATUS,
    label: ACCOUNT_STATUS,
    filterType: 'checkbox',
    isSingleSelect: true,
    isBoolean: true,
    columnName: 'accountActive',
    subType: 'accountActive',
    field: 'accountActive',
    renderHeader: RenderHeaderCell,
    renderCell: RenderAccountActiveCell,
  },
]
