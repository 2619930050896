import { PAGINATION } from 'settings/constants/pagination'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

export const REPORT_MODEL = {
  isOpenSaveEditViewModal: false,
  isOpenConfigurator: false,
  isOpenTableFilters: false,
  isOpenNewReportDrawer: false,
  allFilters: {},
  activeFilter: '',
  allSortBy: {},
  configuratorColumns: [],
  pastConfiguratorColumns: [],
  allTableRows: [],
  selectedRows: [],
  paginationCache: '',
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  totalTableRowsCount: 0,
  totalPageCount: 0,
  existingFilterList: [],
  // Dropdown variable
  selectedFilterId: '',
  // When we click edit store current in this
  selectedFilter: {},
  saveEditViewInput: '',
  reportName: '',
  reportDesc: '',
}
