import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingInvoiceMeta: build.query({
      query: () => ({
        url: '/api/billing/invoice/meta?includeAll=false',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetBillingInvoiceMetaQuery, useLazyGetBillingInvoiceMetaQuery } = extendedApi

export const getInvoiceMetaSlice = extendedApi.endpoints.getBillingInvoiceMeta.select()
