import { createSelector } from 'reselect'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'

export const getExpandedRows = createSelector(
  [state => state?.rows, state => state?.collapsedGroups, state => state?.noOfColumns],
  (rows = [], collapsedGroups = [], noOfColumns = 1) =>
    rows.filter(row => row.isParent || (!collapsedGroups.includes(row.groupRef) && row?.groupIndex % noOfColumns === 0))
)

export const getGroupsForDataGrid = createSelector([state => state?.groupList], (groupList = []) => {
  const sortedGroups = sortByDateSelector({ data: groupList, key: 'createdAt' })
  let originalRows = []
  let modifiedRows = []
  let groupNames = []
  let monthYearName = ''
  let groupIndex = 0
  sortedGroups.forEach((group, index) => {
    const { onlyDate } = convertDateTimeToPreferedTimezone({ dateTime: group?.createdAt, defaultDateFormat: 'MMMM-yyyy' })
    if (monthYearName !== onlyDate) {
      modifiedRows = [...modifiedRows, { isParent: true, id: onlyDate, groupRef: onlyDate, groupName: onlyDate.replace('-', ' ') }]
      groupNames = [...groupNames, onlyDate]
      monthYearName = onlyDate
      groupIndex = 0
    }

    const newGroup = { isParent: false, groupRef: onlyDate, originalIndex: index, groupIndex }
    originalRows = [...originalRows, { ...group, ...newGroup }]
    modifiedRows = [...modifiedRows, { ...group, ...newGroup }]
    groupIndex += 1
  })

  return { originalRows, modifiedRows, groupNames }
})
