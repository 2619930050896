import React from 'react'
import PropTypes from 'prop-types'

import LastNextPriceUpdateColumn from 'components/pricing/LastNextPriceUpdateColumn'
import ToolTip from 'components/common/Tooltip'

import { TableCell } from '@mui/material'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import Constants from 'Constants'

import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const TaxBody = ({ service, column, getTaxServiceName, ind, lockedColumns }) => {
  const taxServiceName = getTaxServiceName(service, column.label, 'listing')
  const isLocked = get(column, 'locked', false)

  return (
    <>
      <LastNextPriceUpdateColumn service={service} column={column} ind={ind} lockedColumns={lockedColumns} />

      {!['Last Value Update', 'Next Value Update'].includes(column.label) && (
        <TableCell className={`${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
          <div className="price-cell">
            {column.label === 'Scope' && taxServiceName === 'Cumulative' && (
              <ToolTip title={taxServiceName} className="notes-user-tooltip">
                <img src={`${MEDIA_SERVER}TaxCircuit.svg`} alt="" />
              </ToolTip>
            )}

            {column.label === 'Scope' && taxServiceName === 'Global' && (
              <ToolTip title={taxServiceName} className="notes-user-tooltip">
                <img src={`${MEDIA_SERVER}TaxSphere.svg`} alt="" />
              </ToolTip>
            )}

            {column.label !== 'Scope' && taxServiceName}
          </div>
        </TableCell>
      )}
    </>
  )
}

TaxBody.defaultProps = {
  ind: null,
  service: {},
  column: {},
  lockedColumns: [],
  getTaxServiceName: null,
}

TaxBody.propTypes = {
  ind: PropTypes.number,
  service: PropTypes.object,
  column: PropTypes.object,
  lockedColumns: PropTypes.array,
  getTaxServiceName: PropTypes.func,
}

export default TaxBody
