import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro'
import { unstable_composeClasses as composeClasses, Box, CircularProgress, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'

const LoadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const useUtilityClasses = ownerState => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

export const isNavigationKey = key =>
  key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' '

const ConfirmPermanentGroupingCellWithLazyLoading = (props = {}) => {
  const { id, field, rowNode, row, fetchConfirmPermanentMoveChildren, isInitialized } = props

  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useUtilityClasses({ classes: rootProps?.classes })
  const { treeDataCollapseIcon, treeDataExpandIcon } = rootProps?.slots
  const isLoading = useMemo(() => row?.isLoading, [row])

  const getIcon = useCallback(() => {
    if (rowNode?.childrenExpanded && treeDataCollapseIcon) {
      return treeDataCollapseIcon
    }
    if (treeDataExpandIcon) {
      return treeDataExpandIcon
    }
    return Fragment
  }, [rowNode, rootProps])

  const Icon = getIcon()
  const handleKeyDown = event => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current?.publishEvent('cellNavigationKeyDown', props, event)
    }
  }

  const handleClick = event => {
    apiRef.current?.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current?.setCellFocus(id, field)
    event.stopPropagation()
  }

  useEffect(() => {
    if (apiRef && apiRef?.current && !row?.isLoading && row?.isParent && !row?.childrenFetched && isInitialized) {
      setTimeout(() => fetchConfirmPermanentMoveChildren(apiRef, row), 100)
    }
  }, [apiRef, fetchConfirmPermanentMoveChildren, row, isInitialized])

  return (
    <Box className={classes.root} sx={{ pl: 2 }}>
      <Box className={classes.toggle} sx={{ minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {row?.isParent && (
          <>
            {isLoading && (
              <LoadingContainer>
                <CircularProgress size="1.4rem" color="inherit" />
              </LoadingContainer>
            )}
            {!isLoading && row?.descendantCount !== 0 && (
              <IconButton
                size="small"
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                tabIndex={-1}
                aria-label={
                  rowNode.childrenExpanded
                    ? apiRef.current.getLocaleText('treeDataCollapse')
                    : apiRef.current.getLocaleText('treeDataExpand')
                }
              >
                <Icon fontSize="inherit" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

ConfirmPermanentGroupingCellWithLazyLoading.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  rowNode: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  fetchConfirmPermanentMoveChildren: PropTypes.func.isRequired,
  isInitialized: PropTypes.bool,
}

export default ConfirmPermanentGroupingCellWithLazyLoading
