import T from 'T'
import routes from 'router/routes'

export const GROUPS_MENU = [
  { label: T.INVOICE_GROUPS, url: routes.app.invoiceGroups },
  { label: T.PAYMENT_GROUPS, url: routes.app.paymentGroups },
]

export const WORK_ORDERS_MENU = [{ label: T.WORK_ORDERS_REVIEW, url: routes.app.workOrdersReview }]

export const INVOICES_MENU = [
  { label: T.CREATE_DRAFT_INVOICES, url: routes.app.createInvoices },
  { label: T.INVOICES, url: routes.app.invoices },
]

export const PAYMENTS_MENU = [
  { label: T.PAYMENTS, url: routes.app.payments },
  { label: T.ACCOUNTS_RECEIVABLE, url: routes.app.accountsReceivable },
]
