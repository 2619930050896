import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stack } from '@mui/material'
import LocationsHeader from 'components/locations/LocationsHeader'
import FilterByLetterButtonGroup from 'components/locations/filters/FilterByLetterButtonGroup'
import LocationsDataGrid from 'components/locations/LocationsDataGrid/LocationsDataGrid'
import { FormProvider, useForm } from 'react-hook-form'
import { SORT_BY_DEFAULT_VALUE, SORT_BY_OPTIONS } from 'components/locations/settings'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { get } from 'lodash'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { ADDRESS_SEARCH_TYPE, SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE, GENERAL_SEARCH_TYPE } from 'components/locations/filters/Search/settings'
import { useLazyGetLocationsQuery } from 'api/location/getLocations'
import useLocationListUtilities from 'components/locations/useLocationListUtilities'
import {
  selectLocationFilters,
  selectScrollTopPosition,
  setCsNoteDialog,
  setLocationsFilters,
  setScrollTopPosition,
} from 'slices/locations/locationsSlice'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'
import { checkIsManualSearch } from 'components/customers/accounts/search/settings'

const Locations = () => {
  const dispatch = useDispatch()
  const filters = useSelector(selectLocationFilters, shallowEqual)
  const scrollTopPosition = useSelector(selectScrollTopPosition)
  const [searchParams, setSearchParams] = useSearchParams()
  const [initialized, setInitialized] = useState(false)
  const [isManualSearchDataLoaded, setIsManualSearchDataLoaded] = useState(false)
  const [characterToIndexMap, setCharacterToIndexMap] = useState({})
  const methods = useForm({
    defaultValues: {
      selectedBillingProfiles: [],
      selectedTags: [],
      sortBy: SORT_BY_DEFAULT_VALUE,
      searchType: ADDRESS_SEARCH_TYPE,
      searchInputValue: '',
      searchValue: '',
      expandedRowIds: [],
      searchTagsInput: '',
      searchBillingInput: '',
      withDeactivated: true,
      withDigits: true,
    },
  })
  const { watch, reset, setValue } = methods
  const sortByValue = watch('sortBy')
  const selectedTags = watch('selectedTags')
  const withDeactivated = watch('withDeactivated')
  const withDigits = watch('withDigits')
  const searchType = watch('searchType')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const searchValue = watch('searchValue')
  const sortBySelectedOption = useMemo(() => SORT_BY_OPTIONS.find(option => option.value === sortByValue), [sortByValue])
  const isListAscending = useMemo(() => get(sortBySelectedOption, 'ascending', true), [sortBySelectedOption])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const debouncedHandleScrollEventRef = useRef(null)
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const billingProfiles = get(customerMeta, 'billingProfiles', [])
  const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
  const { isSuccess: isAccountMetaSuccess } = useGetAccountMetaQuery()
  const [getLocations, { isLoading, isFetching, data, isSuccess }] = useLazyGetLocationsQuery()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const isSameTenant = useMemo(() => {
    const filtersBusinessId = get(filters, 'selectedBillingProfiles[0].businessId')
    return !filtersBusinessId || businessId === filtersBusinessId
  }, [businessId, filters])
  const apiRef = useGridApiRef()
  const [sortedRows, setSortedRows] = useState([])
  const locationsCount = get(sortedRows, 'length', 0)
  const isManualSearchSelected = useMemo(() => checkIsManualSearch(searchType), [searchType])

  const { handleCharacterButtonClick, shouldDisableCharacterButton, createAlphabetToRowIndexMap, sortRows, filterCharacter } =
    useLocationListUtilities({ data, characterToIndexMap, apiRef, sortedRows, watch, setValue })

  const allTags = useMemo(() => [NO_TAGS_OPTION, ...tagsByVisibility], [tagsByVisibility])

  const initializeFilters = useCallback(() => {
    const filtersSelectedTags = get(filters, 'selectedTags', [])
    const filtersSelectedBillingProfiles = get(filters, 'selectedBillingProfiles', [])
    const selectedTags = isSameTenant && filtersSelectedTags.length > 0 ? filtersSelectedTags : allTags
    const selectedBillingProfiles =
      isSameTenant && filtersSelectedBillingProfiles.length > 0 ? filtersSelectedBillingProfiles : billingProfiles
    const otherValues = { ...(!isSameTenant && { searchTagsInput: '', searchBillingInput: '', searchInputValue: '', searchValue: '' }) }
    reset({ ...filters, expandedRowIds: [], selectedTags, selectedBillingProfiles, ...otherValues })
    setSearchParams(searchParams, { replace: true })
  }, [searchParams, filters, allTags, billingProfiles, reset, setSearchParams])

  useEffect(() => {
    if (isAccountMetaSuccess) {
      initializeFilters()
    }
  }, [isAccountMetaSuccess])

  const getLocationsData = () => {
    if (isManualSearchSelected) {
      getLocations({ searchText: '', searchType: SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE[GENERAL_SEARCH_TYPE], includeDeactivated: true })
      setIsManualSearchDataLoaded(true)
      return
    }

    getLocations({
      searchText: searchValue,
      searchType: SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE[searchType],
      includeDeactivated: true,
    })
  }

  useEffect(() => {
    if (isManualSearchSelected && isManualSearchDataLoaded) {
      setSortedRows(sortRows())
      return
    }

    setIsManualSearchDataLoaded(false)
    getLocationsData()
  }, [searchValue, searchType])

  useEffect(() => {
    setSortedRows(sortRows())
  }, [data, selectedTags, isListAscending, withDigits, withDeactivated, selectedBillingProfilesIds, sortRows])

  useEffect(() => {
    if (Array.isArray(sortedRows)) {
      const alphabetToRowIndexMap = createAlphabetToRowIndexMap()
      setCharacterToIndexMap(alphabetToRowIndexMap)
    }
  }, [sortedRows])

  useEffect(() => {
    const handleScrollEvent = (params, event, details) => {
      const top = get(params, 'top', 0)
      if (!initialized) return
      dispatch(setScrollTopPosition(top))
    }

    debouncedHandleScrollEventRef.current = debounce(handleScrollEvent, 300)

    return apiRef.current.subscribeEvent('scrollPositionChange', (params, event, details) => {
      if (debouncedHandleScrollEventRef.current) {
        debouncedHandleScrollEventRef.current(params, event, details)
      }
    })
  }, [apiRef, initialized])

  useEffect(() => {
    if (!initialized && isSuccess) {
      setTimeout(() => {
        apiRef?.current?.scroll({ top: scrollTopPosition })
      }, 300)
      setInitialized(true)
    }
  }, [apiRef, initialized, sortedRows, isSuccess])

  useEffect(() => {
    return () => {
      dispatch(
        setCsNoteDialog({
          open: false,
          selectedService: null,
        })
      )
    }
  }, [])

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(setLocationsFilters(value))
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <FormProvider {...methods}>
      <Stack
        direction="column"
        sx={{
          height: '100%',
          width: '100%',
          flex: '1 1 0',
        }}
      >
        <LocationsHeader locationsCount={locationsCount} loadingRows={isLoading || isFetching} allLocations={data} />
        <Stack sx={{ flex: '1 1 0', overflowY: 'auto' }} direction="row">
          <FilterByLetterButtonGroup
            withDigits={withDigits}
            ascending={isListAscending}
            onCharacterButtonClick={handleCharacterButtonClick}
            shouldDisableCharacterButton={shouldDisableCharacterButton}
            filterCharacter={filterCharacter}
          />
          <LocationsDataGrid rows={sortedRows} loading={isLoading || isFetching} apiRef={apiRef} />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

Locations.propTypes = {}

export default Locations
