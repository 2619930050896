import api from 'api'

import { formatCentsToDollars } from 'utils/price'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingPaymentRevenue: build.query({
      query: body => ({ url: '/api/v1/billing/payment/revenue', method: 'POST', body }),
      transformResponse: data => ({ ...data, totalRevenue: formatCentsToDollars(data?.totalRevenueCents) }),
    }),
  }),
})

export const { useLazyGetBillingPaymentRevenueQuery } = extendedApi
