import React, { useReducer, useEffect, useState } from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Button, Box, Typography } from '@mui/material'

import { useGetDisposalSitesMutation } from 'api/settings/getDisposalSites'
import { useAddDisposalSiteMutation } from 'api/settings/addDisposalSite'
import { useLazyGetDisposalSiteByIdQuery } from 'api/settings/getDisposalSiteById'

import T from 'T'
import { get } from 'utils/lodash'
import { get12HourTimeFormat, get24HourTimeFormat } from 'utils/time'
import { handleError } from 'utils/error'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'

import ListDisposalSites from './disposal-sites/ListDisposalSites'
import AddDisposalSites from './disposal-sites/AddDisposalSites'

const DisposalSitesSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const DISPOSAL_SITES_INITIAL_VALUES = {
    name: '',
    streetAddress: '',
    city: '',
    allStates: [],
    state: '',
    zipCode: '',
    country: '',
    active: true,
    ticketRequired: true,
    latitude: '',
    longitude: '',
    allCountries: [],
    footerFlag: false,
    disposalSiteHours: [],
    disposalSiteMaterials: [],
    checkedDays: [],
    addressId: '',
    disposalSiteId: '',
  }

  const [addNewDisposalSite, setAddNewDisposalSite] = useState(false)

  const [disposalState, setDisposalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    disposalSites: [],
    ...DISPOSAL_SITES_INITIAL_VALUES,
  })

  const {
    disposalSites,
    disposalSiteId,
    name,
    active,
    ticketRequired,
    streetAddress,
    city,
    state,
    country,
    zipCode,
    addressId,
    longitude,
    latitude,
  } = disposalState

  const [getDisposalSites, { isLoading, isFetching }] = useGetDisposalSitesMutation()
  const [addDisposalSite, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddDisposalSiteMutation()
  const [getDisposalSiteById, { isLoading: isEditLoading, isFetching: isEditFetching }] = useLazyGetDisposalSiteByIdQuery()

  const fetchDisposalSites = () => {
    getDisposalSites()
      .then(data => {
        const res = get(data, 'data', [])
        setDisposalState({
          disposalSites: get(res, 'sites', []),
          ...DISPOSAL_SITES_INITIAL_VALUES,
        })
      })
      .catch(() => {
        setDisposalState({
          disposalSites: [],
        })
      })
  }

  useEffect(() => {
    fetchDisposalSites()
  }, [])

  const handleAdd = () => {
    setAddNewDisposalSite(true)
  }

  const handleSave = () => {
    setAddNewDisposalSite(false)

    const payload = {
      name,
      active,
      ticketRequired,
      address: {
        line1: streetAddress,
        city,
        state,
        country,
        zipCode,
        longitude,
        latitude,
      },
      disposalSiteHour: disposalState.disposalSiteHours
        .filter(data => data.openTime !== '' && data.closeTime !== '' && data.days.length > 0)
        .filter(rec => (rec.id && !rec.deleted) || (!rec.id && !rec.deleted) || (rec.id && rec.deleted))
        .map(data => ({
          id: data.id,
          days: data.days
            .filter(rec => rec.id !== 'All')
            .map(val => val.id)
            .join(', '),
          openTime: get24HourTimeFormat(data.openTime),
          closeTime: get24HourTimeFormat(data.closeTime),
          deleted: data.deleted,
        })),
      disposalSiteMaterial: disposalState.disposalSiteMaterials
        .filter(data => data.tippingFee !== '' && data.unitOfMeasure !== '' && data.materials !== '')
        .filter(rec => (rec.id && !rec.deleted) || (!rec.id && !rec.deleted) || (rec.id && rec.deleted))
        .map(data => ({
          id: data.id,
          tippingFee: data.tippingFee,
          measureUnit: data.unitOfMeasure,
          material: data.materials,
          deleted: data.deleted,
        })),
    }

    if (disposalSiteId !== '') {
      payload.address.id = addressId
      payload.id = disposalSiteId
    }

    setDisposalState({ footerFlag: false })

    addDisposalSite(payload)
      .unwrap()
      .then(() => {
        fetchDisposalSites()
      })
      .catch(handleError)
  }

  const handleEdit = id => {
    if (updateSettings) {
      setAddNewDisposalSite(true)
      setDisposalState({
        disposalSiteId: id,
      })

      getDisposalSiteById({ id })
        .unwrap()
        .then(res =>
          setDisposalState({
            name: get(res, 'name', ''),
            streetAddress: get(res, 'address.line1', ''),
            city: get(res, 'address.city', ''),
            state: get(res, 'address.state', ''),
            zipCode: get(res, 'address.zipCode', ''),
            country: get(res, 'address.country', ''),
            footerFlag: false,
            active: get(res, 'active', true),
            ticketRequired: get(res, 'ticketRequired', true),
            disposalSiteId: get(res, 'id', ''),
            addressId: get(res, 'address.id', ''),
            disposalSiteHours: get(res, 'disposalSiteHour', []).map(data => ({
              id: data.id,
              days:
                data.days.split(', ').length === 7
                  ? `All, ${data.days}`.split(', ').map(rec => ({ id: rec, value: rec }))
                  : data.days.split(', ').map(rec => ({ id: rec, value: rec })),
              openTime: get12HourTimeFormat(data.openTime),
              closeTime: get12HourTimeFormat(data.closeTime),
            })),
            disposalSiteMaterials: get(res, 'disposalSiteMaterial', []).map(data => ({
              id: data.id,
              unitOfMeasure: data.measureUnit,
              tippingFee: data.tippingFee,
              materials: { id: get(data, 'material.id', '') },
            })),
          })
        )
        .catch(handleError)
    }
  }

  const handleCancel = () => {
    setAddNewDisposalSite(false)
    fetchDisposalSites()
  }

  const onHandleNameChange = event => {
    const nameVal = event.target.name

    let { value } = event.target

    if (/^\s/.test(value)) {
      value = ''
    }

    disposalState[nameVal] = value
    setDisposalState({ [nameVal]: value, footerFlag: true })
  }

  return (
    <Box minWidth={770}>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewDisposalSite ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCancel()}
            >
              {`${T.DISPOSAL_SITE}s`}
            </Typography>

            {addNewDisposalSite && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${disposalSiteId === '' ? T.NEW : T.EDIT} ${T.DISPOSAL_SITE}`}</Typography>
              </Box>
            )}
          </Box>
          {!addNewDisposalSite && addSettings && (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>
        {addNewDisposalSite && updateSettings && (
          <AddDisposalSites
            isLoading={isEditLoading || isEditFetching}
            disposalState={disposalState}
            handleSave={handleSave}
            handleCancel={handleCancel}
            setDisposalState={setDisposalState}
            onHandleNameChange={onHandleNameChange}
          />
        )}
      </Box>

      {!addNewDisposalSite && (
        <ListDisposalSites
          disposalSites={disposalSites}
          isLoading={isLoading || isFetching || isAddLoading || isAddFetching}
          handleEdit={handleEdit}
        />
      )}
    </Box>
  )
}

export default DisposalSitesSettings
