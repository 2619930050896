import React from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { setAddContactState } from 'slices/customer/contactsSlice'
import { canUpdateCustomerManager } from 'data/permissions/permissionsSelectors'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'

import ContactsListing from './ContactsListing'
import AddEditContactDialog from './AddEditContactDialog'
import { getAddModel } from './settings'

const Contacts = ({ customerDetails }) => {
  const dispatch = useDispatch()
  const updateCustomerManager = useSelector(canUpdateCustomerManager, shallowEqual)
  const isOpenAddContactDialog = useSelector(state => get(state, 'Contacts.addContact.isOpen', false), shallowEqual)
  const initialMethodValue = useSelector(state => get(state, 'Contacts.addContact.initialMethodValue', ''), shallowEqual)
  const initialFirstName = useSelector(state => get(state, 'Contacts.addContact.initialFirstName', ''), shallowEqual)
  const initialLastName = useSelector(state => get(state, 'Contacts.addContact.initialLastName', ''), shallowEqual)

  const accountContacts = get(customerDetails, 'contacts', [])
  const locationContacts = get(customerDetails, 'locations', []).map(location => get(location, 'contacts', []))
  const mergeContacts = [...accountContacts, ...locationContacts.flat()]
  // Remove duplicates
  const uniqueContacts = uniqBy(mergeContacts, contact => contact.id)

  const addModel = getAddModel(initialFirstName, initialLastName, initialMethodValue)

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={T.CONTACTS}
        showActionIcon={updateCustomerManager}
        actionType="add"
        onActionClick={() =>
          dispatch(setAddContactState({ isOpen: true, initialFirstName: '', initialLastName: '', initialMethodValue: '' }))
        }
        sx={{ position: 'inherit' }}
      >
        <ContactsListing contacts={uniqueContacts} customerDetails={customerDetails} showActionMenu />
      </HHAccordion>

      {isOpenAddContactDialog && (
        <AddEditContactDialog
          isOpen={isOpenAddContactDialog}
          accountId={get(customerDetails, 'id')}
          contact={initialMethodValue ? addModel : null}
          onClose={() =>
            dispatch(
              setAddContactState({
                isOpen: false,
                accountId: '',
                initialFirstName: '',
                initialLastName: '',
                initialMethodValue: '',
                parent: '',
              })
            )
          }
        />
      )}
    </>
  )
}

Contacts.propTypes = {
  customerDetails: PropTypes.object.isRequired,
}

export default Contacts
