import React, { useState } from 'react'

import { useFormContext } from 'react-hook-form'

import TagFilterMenuField from 'components/locations/filters/TagFilter/TagFilterMenuField'
import BillingProfileFilterMenuField from 'components/locations/filters/BillingProfileFilter/BillingProfileFilterMenuField'
import { IconButton, useMediaQuery, useTheme, Grid } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FiltersMenu from 'components/locations/filters/FiltersMenu'

const Filters = () => {
  const { control, watch, setValue } = useFormContext()
  const searchTagsInput = watch('searchTagsInput')
  const searchBillingInput = watch('searchBillingInput')
  const tagList = watch('tagList')
  const billingProfileList = watch('billingProfileList')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const onBillingSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchBillingInput', value)
    }
  }

  return (
    <>
      {isDesktop ? (
        <Grid container flexWrap="nowrap" justifyContent="flex-end">
          <Grid item xs="auto">
            <BillingProfileFilterMenuField
              name="selectedBillingProfiles"
              control={control}
              inputValue={searchBillingInput}
              onInputChange={onBillingSearchInputChange}
              dropdownHeightOffset={48}
              autoHeight
              billingProfileList={billingProfileList}
            />
          </Grid>
          <Grid item xs="auto">
            <TagFilterMenuField
              name="selectedTags"
              control={control}
              inputValue={searchTagsInput}
              onInputChange={onTagsSearchInputChange}
              dropdownHeightOffset={80}
              tagList={tagList}
              autoHeight
            />
          </Grid>
        </Grid>
      ) : (
        <IconButton onClick={handleClick} endIcon={open ? <ExpandLess /> : <ExpandMore />}>
          <FilterListIcon color="action" />
        </IconButton>
      )}
      <FiltersMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      />
    </>
  )
}

export default Filters
