export const APP_PREFIX = '/app'

const routes = {
  app: {
    error: `${APP_PREFIX}/error`,
    login: `${APP_PREFIX}/login`,
    logout: `${APP_PREFIX}/logout`,
    selectAccount: `${APP_PREFIX}/select-account`,
    home: `${APP_PREFIX}/home`,
    route: `${APP_PREFIX}/route`,
    exportRouteWorkOrders: `${APP_PREFIX}/routes/:routeId/work_orders/export`,
    exportRouteWorkOrder: `${APP_PREFIX}/routes/:routeId/work_orders/:workOrderId/export`,
    routes: `${APP_PREFIX}/routes`,
    payments: `${APP_PREFIX}/billing/payments`,
    createInvoices: `${APP_PREFIX}/billing/create_invoices`,
    accountsReceivable: `${APP_PREFIX}/billing/accounts_receivable`,
    invoices: `${APP_PREFIX}/billing/invoices`,
    invoiceGroups: `${APP_PREFIX}/billing/invoice_groups`,
    paymentGroups: `${APP_PREFIX}/billing/payment_groups`,
    billing: `${APP_PREFIX}/billing`,
    reports: `${APP_PREFIX}/reports`,
    reportDetails: `${APP_PREFIX}/reports/:slug`,
    pricing: `${APP_PREFIX}/pricing`,
    pricingServices: `${APP_PREFIX}/pricing/services`,
    pricingFees: `${APP_PREFIX}/pricing/general_fees`,
    pricingLateFees: `${APP_PREFIX}/pricing/late_fees`,
    pricingLateFeeDetails: `${APP_PREFIX}/pricing/late_fees/:id`,
    pricingRentalFees: `${APP_PREFIX}/pricing/rental_fees`,
    pricingRentalFeeDetails: `${APP_PREFIX}/pricing/rental_fees/:id`,
    pricingTaxes: `${APP_PREFIX}/pricing/taxes`,
    pricingServiceGroups: `${APP_PREFIX}/pricing/service_groups`,
    followUp: `${APP_PREFIX}/follow-up`,
    dashboard: `${APP_PREFIX}/dashboard`,
    customers: `${APP_PREFIX}/customers`,
    accounts: `${APP_PREFIX}/customers/accounts`,
    locations: `${APP_PREFIX}/customers/locations`,
    recentlyViewed: `${APP_PREFIX}/customers/recently_viewed`,
    recentlyAdded: `${APP_PREFIX}/customers/recently_added`,
    customerDetails: `${APP_PREFIX}/customers/:accountId/details`,
    accountGroups: `${APP_PREFIX}/customers/account_groups`,
    suspensionGroups: `${APP_PREFIX}/customers/suspension_groups`,
    workOrderDetails: `${APP_PREFIX}/work-orders/:workOrderId/details`,
    workOrdersReview: `${APP_PREFIX}/billing/work_orders_review`,
    search: `${APP_PREFIX}/search`,
    searchDetails: `${APP_PREFIX}/search/:slug`,
    settings: `${APP_PREFIX}/settings`,
    settingDetails: `${APP_PREFIX}/settings/:slug`,
    help: `${APP_PREFIX}/help`,
    notifications: `${APP_PREFIX}/notifications`,
  },
}

export default routes

export const getWorkOrderDetailsPageUrl = workOrderId => `${APP_PREFIX}/work-orders/${workOrderId}/details`
export const getCustomerDetailsPageUrl = accountId => `${APP_PREFIX}/customers/${accountId}/details`
export const getLateFeeDetailsPageUrl = id => `${APP_PREFIX}/pricing/late_fees/${id}`
export const getRentalFeeDetailsPageUrl = id => `${APP_PREFIX}/pricing/rental_fees/${id}`

// Temporary fix, until we remove dispatch(getCustomerDetails({ accountId })) and use RTK invalidateTags
export const isCustomerDetailsPage = () => {
  const { pathname } = window.location

  return pathname.includes(`${APP_PREFIX}/customers`) && pathname.includes('/details')
}

const { app } = routes
const { login, selectAccount } = app

export const isPublicURL = route => [login, selectAccount].includes(route)
