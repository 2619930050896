import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'

import { debounce } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { Stack } from '@mui/material'

import { get } from 'utils/lodash'
import { GENERAL_SEARCH_TYPE, SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE, PHONE_SEARCH_TYPE } from 'components/locations/filters/Search/settings'
import { checkIsManualSearch } from 'components/customers/accounts/search/settings'
import {
  useLazyGetAccountsBySearchQuery,
  useLazyGetAccountsByFocusedSearchQuery,
  useLazyGetAccountsByRecentSearchQuery,
} from 'api/accounts/accountSearch'
import {
  RECENTLY_ADDED_VIEW_MODE,
  RECENTLY_VIEWED_VIEW_MODE,
  SORT_BY_OPTIONS,
  VIEW_MODE_TO_SEARCH_TYPE,
} from 'components/customers/accounts/settings'
import { initialState, setScrollTopPosition, setAccountsFilters } from 'slices/customer/accountsSlice'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'

import useAccountListUtilities from 'components/customers/accounts/common/useAccountListUtilities'
import AccountsHeader from 'components/customers/accounts/AccountsHeader'
import FilterByLetterButtonGroup from 'components/locations/filters/FilterByLetterButtonGroup'
import AccountsDataGrid from 'components/customers/accounts/AccountsDataGrid'
import PropTypes from 'prop-types'
import { getOnlyDigits } from 'utils/string'

const AccountsV2 = ({ viewMode = GENERAL_SEARCH_TYPE }) => {
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()
  const { isSuccess: isAccountMetaSuccess } = useGetAccountMetaQuery()
  const [
    getAccountsBySearch,
    { isFetching: isLoadingGetAccountsBySearch, data: dataSearchAccounts, isSuccess: isSuccessGetAccountsBySearch },
  ] = useLazyGetAccountsBySearchQuery()
  const [
    getAccountsByFocusedSearch,
    { isFetching: isLoadingGetAccountsByFocusedSearch, data: dataFocusedSearchAccounts, isFetching: isSuccessGetAccountsByFocusedSearch },
  ] = useLazyGetAccountsByFocusedSearchQuery()
  const [
    getAccountsByRecentSearch,
    { isFetching: isLoadingGetAccountsByRecentSearch, data: dataRecentSearchAccounts, isFetching: isSuccessGetAccountsByRecentSearch },
  ] = useLazyGetAccountsByRecentSearchQuery()

  const filters = useSelector(state => get(state, 'accounts.filters'), shallowEqual)
  const scrollTopPosition = useSelector(state => get(state, 'accounts.scrollTopPosition'), shallowEqual)

  const [initialized, setInitialized] = useState(false)
  const [isManualSearchDataLoaded, setIsManualSearchDataLoaded] = useState(false)
  const [characterToIndexMap, setCharacterToIndexMap] = useState({})
  const [sortedRows, setSortedRows] = useState([])

  const accountListForm = useForm({ defaultValues: { ...(filters || initialState.filters) } })

  const { setValue, watch, reset } = accountListForm
  const sortByValue = watch('sortBy')
  const withDigits = watch('withDigits')
  const searchType = watch('searchType')
  const searchValue = watch('searchValue')

  const sortBySelectedOption = useMemo(() => SORT_BY_OPTIONS.find(option => option.value === sortByValue), [sortByValue])
  const isListAscending = useMemo(() => get(sortBySelectedOption, 'ascending', true), [sortBySelectedOption])
  const debouncedHandleScrollEventRef = useRef(null)

  const isRecentlySelected = useMemo(() => [RECENTLY_VIEWED_VIEW_MODE, RECENTLY_ADDED_VIEW_MODE].includes(viewMode), [viewMode])
  const isSuggestSelected = useMemo(() => searchType === GENERAL_SEARCH_TYPE || !searchValue, [searchValue, searchType])
  const isManualSearchSelected = useMemo(() => checkIsManualSearch(searchType), [searchType])

  const { isLoading, isSuccess, data } = useMemo(() => {
    if (isRecentlySelected) {
      return {
        isLoading: isLoadingGetAccountsByRecentSearch,
        isSuccess: isSuccessGetAccountsByRecentSearch,
        data: dataRecentSearchAccounts,
      }
    }

    if (isSuggestSelected || isManualSearchSelected) {
      return { isLoading: isLoadingGetAccountsBySearch, isSuccess: isSuccessGetAccountsBySearch, data: dataSearchAccounts }
    }

    return {
      isLoading: isLoadingGetAccountsByFocusedSearch,
      isSuccess: isSuccessGetAccountsByFocusedSearch,
      data: dataFocusedSearchAccounts,
    }
  }, [
    isRecentlySelected,
    isSuggestSelected,
    isManualSearchSelected,
    dataSearchAccounts,
    dataFocusedSearchAccounts,
    dataRecentSearchAccounts,
    isLoadingGetAccountsByRecentSearch,
    isLoadingGetAccountsBySearch,
    isLoadingGetAccountsByFocusedSearch,
    isSuccessGetAccountsByRecentSearch,
    isSuccessGetAccountsBySearch,
    isSuccessGetAccountsByFocusedSearch,
  ])

  const accountsCount = get(sortedRows, 'length', 0)
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const billingProfiles = get(customerMeta, 'billingProfiles', [])
  const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)

  const isSameTenant = useMemo(() => {
    const filtersBusinessId = get(filters, 'selectedBillingProfiles[0].businessId')
    return !filtersBusinessId || businessId === filtersBusinessId
  }, [businessId, filters])

  const { handleCharacterButtonClick, shouldDisableCharacterButton, createAlphabetToRowIndexMap, sortRows, handleFilterCharacter } =
    useAccountListUtilities({
      data: get(data, 'accounts', []),
      characterToIndexMap,
      apiRef,
      sortedRows,
      watch,
      setValue,
      isRecentlySelected,
    })

  const allTags = useMemo(() => [NO_TAGS_OPTION, ...tagsByVisibility], [tagsByVisibility])

  const initializeFilters = () => {
    const filtersSelectedTags = get(filters, 'selectedTags', [])
    const filtersSelectedBillingProfiles = get(filters, 'selectedBillingProfiles', [])
    const selectedTags = isSameTenant && filtersSelectedTags.length > 0 ? filtersSelectedTags : allTags
    const selectedBillingProfiles =
      isSameTenant && filtersSelectedBillingProfiles.length > 0 ? filtersSelectedBillingProfiles : billingProfiles
    const otherValues = { ...(!isSameTenant && { searchTagsInput: '', searchBillingInput: '', searchInputValue: '', searchValue: '' }) }
    reset({
      ...filters,
      expandedRowIds: [],
      selectedTags,
      selectedBillingProfiles,
      ...otherValues,
      ...(isRecentlySelected && { search: '', searchTagsInput: '', searchBillingInput: '', searchInputValue: '', searchValue: '' }),
    })
  }

  useEffect(() => {
    if (isAccountMetaSuccess) {
      initializeFilters()
    }
  }, [isAccountMetaSuccess])

  const getAccountsData = useCallback(() => {
    if (isRecentlySelected) {
      getAccountsByRecentSearch({ type: SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE[VIEW_MODE_TO_SEARCH_TYPE[viewMode]] })
      // For recently, we are not changing the listing data, so call API for manual search options
      if (isManualSearchSelected) {
        getAccountsBySearch({ search: '' })
        setIsManualSearchDataLoaded(true)
      }
      return
    }

    if (isManualSearchSelected) {
      getAccountsBySearch({ search: '' })
      setIsManualSearchDataLoaded(true)
      return
    }

    const formattedValue = searchType === PHONE_SEARCH_TYPE ? getOnlyDigits(searchValue) : searchValue
    if (isSuggestSelected) {
      getAccountsBySearch({ search: formattedValue })
      return
    }
    getAccountsByFocusedSearch({ search: formattedValue, type: SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE[searchType] })
  }, [isRecentlySelected, isSuggestSelected, searchValue, searchType, viewMode, isManualSearchSelected])

  useEffect(() => {
    if (isManualSearchSelected && isManualSearchDataLoaded) {
      setSortedRows(sortRows())
      return
    }

    setIsManualSearchDataLoaded(false)
    getAccountsData()
  }, [searchValue, searchType, viewMode])

  useEffect(() => {
    setSortedRows(sortRows())
  }, [data, sortRows])

  useEffect(() => {
    if (Array.isArray(sortedRows)) {
      const alphabetToRowIndexMap = createAlphabetToRowIndexMap()
      setCharacterToIndexMap(alphabetToRowIndexMap)
    }
  }, [sortedRows])

  useEffect(() => {
    const handleScrollEvent = (params, event, details) => {
      const top = get(params, 'top', 0)
      if (!initialized) return
      dispatch(setScrollTopPosition(top))
    }

    debouncedHandleScrollEventRef.current = debounce(handleScrollEvent, 300)

    return apiRef.current.subscribeEvent('scrollPositionChange', (params, event, details) => {
      if (debouncedHandleScrollEventRef.current) {
        debouncedHandleScrollEventRef.current(params, event, details)
      }
    })
  }, [apiRef, initialized])

  useEffect(() => {
    if (!initialized && isSuccess) {
      setTimeout(() => apiRef?.current?.scroll({ top: scrollTopPosition }), 300)
      setInitialized(true)
    }
  }, [apiRef, initialized, sortedRows, isSuccess])

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(setAccountsFilters(value))
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <FormProvider {...accountListForm}>
      <Stack
        direction="column"
        sx={{
          height: '100%',
          width: '100%',
          flex: '1 1 0',
        }}
      >
        <AccountsHeader
          showSortByControl={!isRecentlySelected}
          showToggleNumericControl={!isRecentlySelected}
          loadingRows={isLoading}
          accountsCount={accountsCount}
          viewMode={viewMode}
          isRecentlySelected={isRecentlySelected}
          // Behavior will be different than locations page b/c of recently options
          allAccounts={get(dataSearchAccounts, 'accounts', [])}
          isManualSearchLoading={isManualSearchSelected && isLoadingGetAccountsBySearch}
        />
        <Stack sx={{ flex: '1 1 0', overflowY: 'auto' }} direction="row">
          {!isRecentlySelected && (
            <FilterByLetterButtonGroup
              withDigits={withDigits}
              ascending={isListAscending}
              onCharacterButtonClick={handleCharacterButtonClick}
              filterCharacter={handleFilterCharacter}
              shouldDisableCharacterButton={shouldDisableCharacterButton}
            />
          )}

          <AccountsDataGrid apiRef={apiRef} isLoading={isLoading} rows={sortedRows} />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

AccountsV2.propTypes = {
  viewMode: PropTypes.string,
}

export default AccountsV2
