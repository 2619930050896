import React, { createContext, useCallback, useContext, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { INVOICE_STATUS } from 'settings/constants/invoice'
import { useLazyGetWorkOrdersReviewDataQuery } from 'api/work-order/getWorkOrdersReviewData'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { setWOReviewNotReadyIds, setWOReviewStatusCountByRouteId } from 'slices/billing/workOrdersReviewSlice'
import { useGetPayload } from './useGetPayload'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE, INVOICED } = INVOICE_STATUS

const WOReviewContext = createContext()

export const useWOReviewContext = () => {
  return useContext(WOReviewContext)
}

export const WOReviewProvider = ({ children, apiRef }) => {
  const dispatch = useDispatch()
  const routeIdList = useSelector(state => state.persistentWorkOrdersReview.routeIdList, shallowEqual)
  const isServiceDateRangeValid = useSelector(state => state.workOrdersReview.isServiceDateRangeValid)
  const parentRows = useSelector(state => state.workOrdersReview.parentRows)
  const [isLoading, setIsLoading] = useState(false)

  const routeIdsListCount = Array.isArray(routeIdList) ? routeIdList.length : 0
  const getPayload = useGetPayload()
  const [getWorkOrdersReview] = useLazyGetWorkOrdersReviewDataQuery()

  const refreshInnerRows = useCallback(
    async ({ routeId }) => {
      if (!apiRef.current || !routeId) {
        return
      }
      if (routeIdsListCount !== 0 && isServiceDateRangeValid) {
        const parentRow = apiRef.current?.getRow(routeId)
        apiRef.current?.updateRows([
          { id: routeId, childrenFetched: false },
          {
            id: `placeholder-children-${routeId}`,
            hierarchy: [...parentRow?.hierarchy, ''],
          },
        ])
        const payload = getPayload(parentRow)
        return getWorkOrdersReview(payload)
          .unwrap()
          .then(({ workOrders }) => {
            if (Array.isArray(workOrders)) {
              const groupedWorkOrders = groupBy(workOrders, 'invoiceStatus')
              const sortedWorkOrders = [
                ...get(groupedWorkOrders, 'NOT_READY_FOR_INVOICE', []),
                ...get(groupedWorkOrders, 'READY_FOR_INVOICE', []),
                ...get(groupedWorkOrders, 'INVOICED', []),
              ]
              const newRows = []
              const childrenNotReadyIds = []
              const woStatusCount = {
                [NOT_READY_FOR_INVOICE]: 0,
                [READY_FOR_INVOICE]: 0,
                [INVOICED]: 0,
              }

              sortedWorkOrders.forEach(({ workOrderId: id, routeId, invoiceStatus, ...rest }) => {
                const disposalTicketAmountCents = get(rest, 'disposalTicketAmountCents', null)
                const workOrderFees = get(rest, 'workOrderFees', [])
                if (woStatusCount[invoiceStatus] === undefined) {
                  woStatusCount[invoiceStatus] = 1
                } else {
                  woStatusCount[invoiceStatus]++
                }
                const newRow = {
                  ...rest,
                  id,
                  routeId,
                  invoiceStatus,
                  hierarchy: [routeId, id],
                  descendantCount: 0,
                  hideDescendantCount: true,
                  isWO: true,
                  isRoute: false,
                  disposalTicketAmountCents,
                  workOrderFees,
                }

                newRows.push(newRow)

                if (invoiceStatus === NOT_READY_FOR_INVOICE) {
                  childrenNotReadyIds.push(id)
                }
              })
              apiRef.current?.updateRows([
                ...newRows,
                {
                  id: routeId,
                  childrenFetched: true,
                  childrenNotReadyIds,
                  descendantCount: workOrders.length,
                  count: workOrders.length,
                  countByStatus: woStatusCount,
                },
              ])
              if (newRows?.length) {
                apiRef.current?.setRowChildrenExpansion(routeId, true)
              }
              return { workOrders: newRows, count: workOrders.length, countByStatus: woStatusCount, childrenNotReadyIds }
            }
          })
          .catch(() => {
            apiRef.current?.updateRows([
              {
                id: routeId,
                childrenFetched: true,
                childrenNotReadyIds: [],
                descendantCount: 0,
                count: 0,
                countByStatus: {
                  [NOT_READY_FOR_INVOICE]: 0,
                  [READY_FOR_INVOICE]: 0,
                  [INVOICED]: 0,
                },
              },
            ])

            return {
              workOrders: [],
              count: 0,
              countByStatus: {
                [NOT_READY_FOR_INVOICE]: 0,
                [READY_FOR_INVOICE]: 0,
                [INVOICED]: 0,
              },
              childrenNotReadyIds: [],
            }
          })
      }
      return []
    },
    [apiRef, getPayload, routeIdsListCount]
  )

  const updateDataGridForIncomingChanges = useCallback(() => {
    if (!apiRef.current) {
      return
    }
    const rows = apiRef.current.getSortedRows()
    const updatedRows = rows.map(row => {
      const { isRoute, isWO } = row
      if (isRoute) {
        const routeId = get(row, 'id')
        apiRef.current?.updateRows([{ id: routeId, childrenFetched: false }])
        apiRef.current.setRowChildrenExpansion(row.id, false)
        return { id: row.id, childrenFetched: false, childrenExpanded: false, descendantCount: 0 }
      }
      if (isWO) {
        return { id: row.id, _action: 'delete' }
      }
      return row
    })
    apiRef.current.updateRows(updatedRows)
  }, [apiRef, parentRows])

  const fetchAllChildrenRows = useCallback(async () => {
    const woStatusCountByRouteId = {}
    const refreshPromises = []
    let allNotReadyIds = []

    for (const { id } of parentRows) {
      const refreshPromise = refreshInnerRows({ routeId: id }).then(({ count, countByStatus, childrenNotReadyIds }) => {
        woStatusCountByRouteId[id] = { count, countByStatus }
        allNotReadyIds = [...childrenNotReadyIds, ...allNotReadyIds]
      })

      refreshPromises.push(refreshPromise)
    }
    return Promise.all(refreshPromises).then(() => {
      dispatch(setWOReviewStatusCountByRouteId(woStatusCountByRouteId))
      dispatch(setWOReviewNotReadyIds(allNotReadyIds))
    })
  }, [parentRows])

  const contextValue = {
    refreshInnerRows,
    updateDataGridForIncomingChanges,
    fetchAllChildrenRows,
    setIsLoading,
    isLoading,
  }

  return <WOReviewContext.Provider value={contextValue}>{children}</WOReviewContext.Provider>
}

WOReviewProvider.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  children: PropTypes.node,
}
