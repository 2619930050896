import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogContentText, Button } from '@mui/material'
import { noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import LoadingButton from '@mui/lab/LoadingButton'
import T from 'T'
import HHDialogTitle from './HHDialogTitle'
import HHDialogActions from './HHDialogActions'

const HHConfirmDialog = ({
  isOpen = false,
  DialogProps = {},
  showCloseIconButton = true,
  showCancelButton = true,
  CancelButtonProps = {},
  cancelButtonTitle = T.CANCEL,
  ConfirmButtonProps = {},
  confirmTitle = 'Are you sure?',
  confirmDescription,
  confirmButtonTitle = 'Delete',
  onConfirm = noop,
  onClose = noop,
}) => {
  const theme = useTheme()

  return (
    <Dialog {...DialogProps} open={isOpen} onClose={onClose}>
      <HHDialogTitle title={confirmTitle} showCloseIcon={showCloseIconButton} onClose={onClose} />
      {confirmDescription && (
        <DialogContent>
          <DialogContentText sx={{ ...theme.typography.body1, color: theme.palette.text.primary }}>{confirmDescription}</DialogContentText>
        </DialogContent>
      )}
      <HHDialogActions>
        {showCancelButton && (
          <Button onClick={onClose} variant="text" color="textSecondary" {...CancelButtonProps}>
            {cancelButtonTitle}
          </Button>
        )}
        <LoadingButton onClick={onConfirm} color="error" variant="contained" {...ConfirmButtonProps}>
          {confirmButtonTitle}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

HHConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  showCloseIconButton: PropTypes.bool,
  confirmTitle: PropTypes.string,
  confirmDescription: PropTypes.node,
  cancelButtonTitle: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  DialogProps: PropTypes.object,
  CancelButtonProps: PropTypes.object,
  ConfirmButtonProps: PropTypes.object,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default HHConfirmDialog
