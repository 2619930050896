import React from 'react'

import RenderAccountGroupCell from './RenderAccountGroupCell'
import TopSection from './TopSection'

const RenderAccountGroupWrapper = params => {
  const { id: rowId } = params

  if (rowId === 'header') {
    return <TopSection />
  }

  return <RenderAccountGroupCell {...params} />
}

export default RenderAccountGroupWrapper
