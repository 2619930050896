import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from 'components/customer-details/tables/StyledAccordian'
import { Add, ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Typography, Grid, Tabs, Tab, Box, IconButton, Divider, useTheme } from '@mui/material'
import T from 'T'
import { LATE_FEES_TABS_ID } from 'components/customer-details/content/fees/late_fees/settings'
import LateFeeAccordionItem from 'components/customer-details/content/fees/late_fees/LateFeeAccordionItem'
import AddLateFeesDialog from 'components/customer-details/content/fees/late_fees/AddLateFeesDialog/AddLateFeesDialog'
import { HHAlert } from 'components/common/HHAlert'

const { BALANCE_TAB_ID, FEES_TAB_ID } = LATE_FEES_TABS_ID

const LateFeesAccordion = ({ initialTab = LATE_FEES_TABS_ID.FEES_TAB_ID }) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(true)
  const [selectedTab, setSelectedTab] = useState(initialTab)
  const [isAddLateFeesDialogOpen, setIsAddLateFeesDialogOpen] = useState(false)
  const mockedLateFees = new Array(2).fill()
  const hasLateFees = mockedLateFees.length > 0
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const openAddLateFeeDialog = () => {
    setIsAddLateFeesDialogOpen(true)
  }

  const closeAddLateFeeDialog = () => {
    setIsAddLateFeesDialogOpen(false)
  }

  return (
    <>
      <Accordion elevation={0} expanded={isExpanded}>
        <AccordionSummary
          withNewColors
          sx={{
            '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
              mb: 0,
            },
          }}
          disablePointer
          expandIcon={undefined}
          iconGap={false}
        >
          <Grid container alignItems="center">
            <Grid item container alignItems="center" flexWrap="nowrap">
              <Grid item display="flex">
                {isExpanded ? (
                  <ArrowDropDown cursor="pointer" onClick={toggleIsExpanded} color="disabled" />
                ) : (
                  <ArrowRight cursor="pointer" onClick={toggleIsExpanded} color="disabled" />
                )}
              </Grid>

              <Grid item xs>
                <Typography sx={{ cursor: 'pointer' }} variant="h6" fontWeight={500} margin="auto 20px auto 8px" onClick={toggleIsExpanded}>
                  {T.LATE}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <IconButton onClick={openAddLateFeeDialog}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Tabs value={selectedTab} onChange={handleTabChange} sx={{ button: { textTransform: 'capitalize' } }}>
                <Tab value={FEES_TAB_ID} label={T.FEES} />
                <Tab value={BALANCE_TAB_ID} label={T.BALANCE} />
              </Tabs>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            width="100%"
            sx={{
              ...(!hasLateFees && {
                p: 2,
              }),
            }}
          >
            {!hasLateFees && (
              <HHAlert
                severity="info"
                borderColor={theme.palette.info.main}
                sx={{
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                No late fees on this account yet
              </HHAlert>
            )}
            {mockedLateFees.map(value => (
              <>
                <LateFeeAccordionItem />
                <Divider />
              </>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <AddLateFeesDialog open={isAddLateFeesDialogOpen} onClose={closeAddLateFeeDialog} />
    </>
  )
}

LateFeesAccordion.propTypes = {
  initialTab: PropTypes.string,
}

export default LateFeesAccordion
