import { useContext, useEffect } from 'react'
import { addCustomSelectBoxMapbox } from 'components/route-manager/BulkActions/common/addCustomSelectBoxMapbox'
import { addRouteIdSuffix } from 'components/route-manager/RouteManagerMap/settings'
import {
  DYNAMIC_COLOR_DOT_LAYER,
  DYNAMIC_COLOR_STOPS_LAYER,
  STATIC_COLOR_DOT_LAYER,
  STATIC_COLOR_STOPS_LAYER,
} from 'components/route-manager/BulkActions/settings'
import { MapContext } from 'providers/MapProvider'
import { noop } from 'lodash'

const useCustomSelectBoxMapbox = ({ isMapLoaded, onSelectFeatures, moveFromRouteId, moveToRouteId }) => {
  const map = useContext(MapContext)

  useEffect(() => {
    if (!map.current || !isMapLoaded || !moveFromRouteId || !moveToRouteId) {
      return noop
    }
    const { unsubscribe } = addCustomSelectBoxMapbox({
      map: map.current,
      onSelectFeatures,
      layers: [
        addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, moveToRouteId),
        addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, moveToRouteId),
        addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, moveToRouteId),
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, moveToRouteId),
        addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, moveFromRouteId),
        addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, moveFromRouteId),
        addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, moveFromRouteId),
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, moveFromRouteId),
      ],
    })

    return () => {
      unsubscribe()
    }
  }, [moveToRouteId, moveFromRouteId, isMapLoaded])
}
export default useCustomSelectBoxMapbox
