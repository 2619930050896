import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText } from '@mui/material'
import { formatDateToFEwithWeekday } from 'utils/date'

const WorkOrderPreviewCell = ({ workOrder, serviceDate }) => {
  const serviceDateFEformat = formatDateToFEwithWeekday(serviceDate)
  return (
    <ListItem>
      <ListItemText
        primary={workOrder}
        secondary={serviceDateFEformat}
        primaryTypographyProps={{ variant: 'h6', color: 'textPrimary' }}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
}

WorkOrderPreviewCell.propTypes = {
  workOrder: PropTypes.string,
  serviceDate: PropTypes.oneOfType([PropTypes.instanceOf(Date)]),
}

export default memo(WorkOrderPreviewCell)
