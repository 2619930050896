import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, Link, SvgIcon } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as GallonIcon } from 'assets/measures/GallonIcon.svg'
import { ReactComponent as YardIcon } from 'assets/measures/YardIcon.svg'
import { ReactComponent as TonIcon } from 'assets/measures/TonIcon.svg'
import { ReactComponent as PoundIcon } from 'assets/measures/PoundIcon.svg'
import { ReactComponent as ItemIcon } from 'assets/measures/ItemIcon.svg'
import { getWorkOrderDetailsPageUrl } from 'router/routes'
import { MEASURE_TYPES } from 'settings/constants/measure'
import { ALPHA_38_PERCENT } from 'theme/colors'

const { GALLON, TON, YARD, POUND, ITEM } = MEASURE_TYPES

const MEASURE_TO_ICON_MAP = {
  [GALLON]: GallonIcon,
  [YARD]: YardIcon,
  [TON]: TonIcon,
  [POUND]: PoundIcon,
  [ITEM]: ItemIcon,
}
const MeasureChip = ({ workOrderId, measureUnit, label, ...rest }) => {
  const theme = useTheme()
  const measureUnitUpperCase = measureUnit?.toUpperCase()
  const Icon = measureUnitUpperCase in MEASURE_TO_ICON_MAP ? MEASURE_TO_ICON_MAP[measureUnitUpperCase] : ItemIcon
  const linkSx = { cursor: 'pointer', '&:hover .MuiChip-label': { textDecoration: 'underline' } }
  return (
    <Chip
      sx={workOrderId && linkSx}
      component={workOrderId && Link}
      href={workOrderId && getWorkOrderDetailsPageUrl(workOrderId)}
      size="small"
      target="_blank"
      variant="outlined"
      avatar={
        <Avatar
          sx={{
            backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}`,
          }}
        >
          <SvgIcon inheritViewBox sx={{ fontSize: 12, color: theme.palette.background.paper }}>
            <Icon />
          </SvgIcon>
        </Avatar>
      }
      label={label}
      {...rest}
    />
  )
}

MeasureChip.propTypes = {
  workOrderId: PropTypes.string,
  label: PropTypes.string,
  measureUnit: PropTypes.oneOf([GALLON, TON, YARD, POUND, ITEM]).isRequired,
}

export default MeasureChip
