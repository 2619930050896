import React from 'react'
import PropTypes from 'prop-types'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import WithInputLabel from './WithInputLabel'
import StyledTextField from './StyledTextField'
import { get, omit } from 'lodash'

const HHTextField = ({
  label,
  disabled,
  required,
  endIcon,
  variant = 'outlined',
  error = '',
  readOnly = false,
  InputProps = {},
  SelectProps = {},
  deprecatedLabel = true,
  ...rest
}) => {
  const helperText = rest?.helperText
  const MenuProps = get(SelectProps, 'MenuProps', {})
  const MenuSx = get(MenuProps, 'sx', {})
  const PaperProps = get(MenuProps, 'PaperProps', {})
  const PaperSx = get(PaperProps, 'sx', {})
  return (
    <WithInputLabel label={deprecatedLabel && label} required={required} endIcon={endIcon} error={error} disabled={disabled}>
      <StyledTextField
        disabled={disabled}
        InputProps={{ ...InputProps, readOnly }}
        variant={variant}
        label={deprecatedLabel ? null : label}
        {...rest}
        error={Boolean(error)}
        helperText={helperText}
        SelectProps={{
          ...SelectProps,
          IconComponent: props => <KeyboardArrowDownRounded {...props} />,
          MenuProps: {
            ...MenuProps,
            sx: { ...MenuSx },
            elevation: 0,
            PaperProps: {
              sx: {
                boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
                maxHeight: 400,
                ...PaperSx,
              },
              variant: 'outlined',
              ...omit(PaperProps, ['sx']),
            },
          },
        }}
      />
    </WithInputLabel>
  )
}

HHTextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  endIcon: PropTypes.node,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  SelectProps: PropTypes.object,
  InputProps: PropTypes.object,
  deprecatedLabel: PropTypes.bool,
}

export default HHTextField
