import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { DialogContent, Stack } from '@mui/material'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'
import { HHFormSelectField, HHFormTextField } from 'components/form-fields/v5'
import T from 'T'
import { PAYMENT_SUSPENSION_REASON, SUSPENSION_REASON_TYPES_OPTIONS } from 'components/customer-details/content/suspensions/settings'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import Dialog from '@mui/material/Dialog'
import { get, noop } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { isAfter, startOfDay } from 'date-fns'

const GenericSuspendAccountDialog = ({ open, title = 'Suspend account', onClose = noop, onSave = noop, isSaveLoading = false }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const inputRef = useRef()
  const suspensionType = watch('suspensionType')
  const isPaymentSuspensionType = useMemo(() => suspensionType === PAYMENT_SUSPENSION_REASON, [suspensionType])
  const descriptionHelperText = get(errors, 'description.message')

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onSave()
    }
  }

  const disableFutureDates = date => {
    const today = startOfDay(new Date())
    return isAfter(startOfDay(date), today)
  }

  useEffect(() => {
    if (!isPaymentSuspensionType) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 100)
    }
  }, [isPaymentSuspensionType])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 4, mb: 2 }} justifyContent="center">
          <HHFormDesktopDatePicker name="startDate" control={control} required shouldDisableDate={disableFutureDates} />
          <HHFormSelectField
            label={T.TYPE}
            control={control}
            name="suspensionType"
            deprecatedLabel={false}
            options={SUSPENSION_REASON_TYPES_OPTIONS}
          />
          {!isPaymentSuspensionType && (
            <HHFormTextField
              inputRef={inputRef}
              helperText={descriptionHelperText}
              rules={{ required: 'This field is required' }}
              error={descriptionHelperText}
              control={control}
              onKeyDown={handleKeyDown}
              name="description"
              required
              deprecatedLabel={false}
              label="Reason"
              fullWidth
            />
          )}
        </Stack>
      </DialogContent>
      <HHDialogActions sx={{ justifyContent: 'space-between' }}>
        <Stack sx={{ width: '100%' }} justifyContent="flex-end" direction="row" alignItems="center" spacing={1}>
          <CancelButton onClick={onClose} />
          <SaveButton loadingPosition="center" loading={isSaveLoading} onClick={onSave} />
        </Stack>
      </HHDialogActions>
    </Dialog>
  )
}

GenericSuspendAccountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isSaveLoading: PropTypes.bool,
  title: PropTypes.string,
}

export default GenericSuspendAccountDialog
