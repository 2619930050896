import { format, utcToZonedTime } from 'date-fns-tz'

const DATE_DEFAULT_TIMEZONE = 'America/Los_Angeles'

// NOTE find a better way to determine user's local IANA time zone name (e.g. America/New_York, America/Loas_Angeles, etc) required input for date-fns-tz convertions
export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const convertTimeToTimezone = (timestamp, dateFormat, localTimeZone = DATE_DEFAULT_TIMEZONE) => {
  // NOTE adding ZULU time (UTC) identifier if no UTC identifier exists on the timestamp
  const parsedTimestamp = !timestamp.includes('Z') ? `${timestamp}Z` : timestamp
  const date = new Date(parsedTimestamp)

  // convert utc date into user's device timezone
  const zonedTime = utcToZonedTime(date, localTimeZone)
  // format date as 14:20:36 EST with user's device timezone
  return format(zonedTime, dateFormat, {
    timeZone: localTimeZone,
  })
}

export const convertToLocalTimezone = (timestamp, dateFormat) => {
  // NOTE adding ZULU time (UTC) identifier if no UTC identifier exists on the timestamp
  const parsedTimestamp = !timestamp.includes('Z') ? `${timestamp}Z` : timestamp

  const date = new Date(parsedTimestamp)
  const localTimeZone = getLocalTimezone()

  // convert utc date into 14:20:36 EST as user's prefered timezone
  const zonedTime = utcToZonedTime(date, localTimeZone)

  // format date as per the time zone timezone
  return format(zonedTime, dateFormat, {
    timeZone: localTimeZone,
  })
}

export const getTimezoneLocation = (time, dateFormat, timeZone = DATE_DEFAULT_TIMEZONE) => {
  return format(utcToZonedTime(time, timeZone), dateFormat, { timeZone: timeZone })
}
