import { orange, blue, green } from '@mui/material/colors'

export const INVOICE_STATUS = {
  NOT_READY_FOR_INVOICE: 'NOT_READY_FOR_INVOICE',
  READY_FOR_INVOICE: 'READY_FOR_INVOICE',
  INVOICED: 'INVOICED',
}

export const INVOICE_STATUS_DISPLAY = {
  NOT_READY_FOR_INVOICE: { title: 'Not ready', text: 'Not ready for invoice', type: 'warning', bgColor: orange[100] },
  READY_FOR_INVOICE: { title: 'Ready', text: 'Ready for invoice', type: 'success', bgColor: green[100] },
  INVOICED: { title: 'Invoiced', text: 'Invoiced', type: 'info', bgColor: blue[100] },
}
