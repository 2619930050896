import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceGroupEmailSummary: build.query({
      providesTags: (result, error, { id }) => [
        CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
        { type: CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA, id },
      ],
      query: ({ id }) => ({
        url: `/api/v1/billing/invoice/groups/${id}/email-summary`,
      }),
    }),
  }),
})

export const { useGetInvoiceGroupEmailSummaryQuery } = extendedApi
