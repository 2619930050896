import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addYard: build.mutation({
      query: body => ({
        url: 'api/settings/yard/add',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAddYardMutation } = extendedApi
