import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getUserById: build.query({
      query: ({ id }) => ({
        url: `/api/v1/core/settings/users/${id}:details`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetUserByIdQuery } = extendedApi
