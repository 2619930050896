import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import { noop } from 'lodash'
import {
  Box,
  Card,
  DialogContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Skeleton,
} from '@mui/material'
import { ReactComponent as InvoiceIcon } from 'assets/Invoice.svg'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import { useForm } from 'react-hook-form'
import { useLazyGetAccountSettingsQuery, useUpdateAccountSettingsMutation } from 'api/accounts/accountSettingsCrud'
import { toast } from 'react-toastify'

const SettingsDialog = ({ accountId, open = false, onClose = noop, onSave = noop }) => {
  const { watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      prorateSuspensions: 'true',
    },
  })

  const [getAccountSettings, { isLoading, isFetching, isSuccess }] = useLazyGetAccountSettingsQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const [updateAccountSettings, { isLoading: isUpdateAccountSettingsLoading }] = useUpdateAccountSettingsMutation()

  const prorate = watch('prorateSuspensions')

  const handleChange = event => {
    setValue('prorateSuspensions', event.target.value)
  }

  const handleSave = ({ prorateSuspensions }) => {
    updateAccountSettings({
      accountId,
      prorateSuspensions: prorateSuspensions === 'true',
    })
      .unwrap()
      .then(() => {
        toast.success(T.INDIVIDUAL_PRORATION_UPDATED_SUCCESS)
        onSave()
        onClose()
      })
  }

  useEffect(() => {
    if (open && accountId) {
      getAccountSettings({
        accountId,
      })
        .unwrap()
        .then(({ prorateSuspensions }) => {
          reset({
            prorateSuspensions: prorateSuspensions ? 'true' : 'false',
          })
        })
    }
  }, [open, accountId])

  return (
    <HHBaseDialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <HHDialogTitle title={T.SETTINGS} onClose={onClose} />
      <DialogContent sx={{ p: 0 }} dividers>
        <Grid container>
          <Grid item xs={3}>
            <List disablePadding sx={{ height: 371 }}>
              <ListItemButton selected divider>
                <ListItemIcon>
                  <InvoiceIcon />
                </ListItemIcon>
                <ListItemText
                  primary={T.BILLING_SUSPENSION}
                  primaryTypographyProps={{
                    variant: 'h6',
                    fontWeight: 400,
                  }}
                />
              </ListItemButton>
            </List>
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                borderLeftStyle: 'solid',
                borderLeftColor: 'divider',
                borderLeftWidth: 1,
                height: '100%',
              }}
              p={3}
            >
              <Card variant="outlined">
                <ListItem>
                  <ListItemText
                    primary={T.SUSPENSION}
                    primaryTypographyProps={{
                      variant: 'h5',
                      fontWeight: 700,
                    }}
                    secondary={T.BILLING_SUSPENSION_DESCRIPTION}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: 'textSecondary',
                    }}
                  />
                </ListItem>
                {isSuccess && !loading && (
                  <>
                    <ListItem>
                      <Radio sx={{ pl: 0 }} checked={prorate === 'true'} onChange={handleChange} value="true" name="prorate" />
                      <ListItemText
                        primary={T.ON}
                        primaryTypographyProps={{
                          variant: 'h6',
                          textTransform: 'uppercase',
                          fontWeight: 700,
                        }}
                        secondary={T.PRORATE}
                        secondaryTypographyProps={{
                          variant: 'body1',
                          color: 'textPrimary',
                        }}
                      />
                    </ListItem>
                    <Divider variant="inset" />
                    <ListItem>
                      <Radio sx={{ pl: 0 }} checked={prorate === 'false'} onChange={handleChange} value="false" name="prorate" />
                      <ListItemText
                        primary={T.OFF}
                        primaryTypographyProps={{
                          variant: 'h6',
                          fontWeight: 700,
                          textTransform: 'uppercase',
                        }}
                        secondary={T.BILL_THEM}
                        secondaryTypographyProps={{
                          variant: 'body1',
                          color: 'textPrimary',
                        }}
                      />
                    </ListItem>
                  </>
                )}
                {loading && (
                  <>
                    <ListItem>
                      <ListItemText
                        primary={<Skeleton variant="text" sx={{ width: '3rem' }} />}
                        secondary={<Skeleton variant="text" sx={{ width: '5rem' }} />}
                        primaryTypographyProps={{
                          variant: 'h6',
                          textTransform: 'uppercase',
                          fontWeight: 700,
                        }}
                        secondaryTypographyProps={{
                          variant: 'body1',
                          color: 'textPrimary',
                        }}
                      />
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <ListItemText
                        primary={<Skeleton variant="text" sx={{ width: '3rem' }} />}
                        secondary={<Skeleton variant="text" sx={{ width: '5rem' }} />}
                        primaryTypographyProps={{
                          variant: 'h6',
                          textTransform: 'uppercase',
                          fontWeight: 700,
                        }}
                        secondaryTypographyProps={{
                          variant: 'body1',
                          color: 'textPrimary',
                        }}
                      />
                    </ListItem>
                  </>
                )}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loading={isUpdateAccountSettingsLoading}
          disabled={isUpdateAccountSettingsLoading}
          size="small"
          onClick={handleSubmit(handleSave)}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

SettingsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  accountId: PropTypes.string,
}

export default SettingsDialog
