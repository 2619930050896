import React from 'react'
import { styled } from '@mui/material'

const BreakAfter = styled('div')`
  page-break-after: always;
  break-after: page;
  @media print {
    page-break-after: always;
    break-after: page;
  }
`

const PageBreak = () => {
  return (
    <>
      <BreakAfter />
    </>
  )
}

export default PageBreak
