import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    createUpdateDraftFee: builder.mutation({
      query: body => {
        return { url: '/api/v1/core/work-orders/fee', method: 'POST', body }
      },
    }),
  }),
})

export const { useCreateUpdateDraftFeeMutation } = extendedApi
