import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { get, noop } from 'lodash'
import { useDispatch } from 'react-redux'
import { ROUTE_STOPS_LINE_STRING } from 'slices/route/routeSlice'
import { addSourceAsync, BOTTOM_LAYER, MIDDLE_LAYER, TOP_LAYER } from 'utils/map'
import { addRouteIdSuffix } from 'components/route-manager/RouteManagerMap/settings'
import { isValidHex } from 'utils/validations'
import { addCustomSelectBoxMapbox } from 'components/route-manager/BulkActions/common/addCustomSelectBoxMapbox'
import { MapContext } from 'providers/MapProvider'
import { common } from '@mui/material/colors'
import isEmpty from 'lodash/isEmpty'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import {
  addMarkerLayers,
  fitBoundsMoveBetweenRoutesLayers,
  removeLayersAndSources,
  DYNAMIC_COLOR_DOT_LAYER,
  DYNAMIC_COLOR_STOPS_LAYER,
  DYNAMIC_COLOR_STOPS_SOURCE,
  STATIC_COLOR_DOT_LAYER,
  STATIC_COLOR_STOPS_LAYER,
  STATIC_COLOR_STOPS_SOURCE,
  toggleMoveBetweenRoutesLayers,
  groupFeaturesByIcon,
} from 'components/route-manager/BulkActions/settings'
import CommonSelectStopsToMoveMap from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/CommonSelectStopsToMoveMap'
import useMapRefInitDispose from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useMapInitDispose'
import PropTypes from 'prop-types'

const CommonSelectStopsToMoveBetweenDaysMap = ({
  fetchId,
  fitBounds,
  fromSerializedDate,
  mapLayer,
  moveFromRouteSequenceLine,
  moveFromRouteStopMarkers,
  moveToRouteSequenceLine,
  moveToRouteStopMarkers,
  open,
  routeId,
  routes,
  routesById,
  toSerializedDate,
  addSelectedMoveFromRows,
  addSelectedMoveToRows,
  ToggleRouteLayers,
}) => {
  const map = useContext(MapContext)
  const [layersCreated, setLayersCreated] = useState(false)
  const mapContainer = useRef(null)
  const dispatch = useDispatch()
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const { isMapLoaded } = useMapRefInitDispose({ map, mapContainer, open })

  const recreateRouteSourcesAndLayers = useCallback(
    (id, sequenceData, features, stopsBeforeIdLayer) => {
      const mapCurrentRef = map.current
      removeLayersAndSources(mapCurrentRef, id)
      addSourceAsync(mapCurrentRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, id), sequenceData, {
        type: 'geojson',
      }).then(() => {
        const color = get(routesById, [routeId, 'color'], common.black)
        const formattedColor = isValidHex(color) ? color : common.black
        mapCurrentRef.addLayer(
          {
            id: addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, id),
            type: 'line',
            source: addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, id),
            paint: {
              'line-color': formattedColor,
              'line-width': 2,
            },
          },
          BOTTOM_LAYER
        )
      })
      const [monochromeFeatures, otherFeatures] = groupFeaturesByIcon(features)

      addSourceAsync(
        mapCurrentRef,
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_SOURCE, id),
        { type: 'FeatureCollection', features: monochromeFeatures },
        {
          type: 'geojson',
        }
      ).then(() => {
        addMarkerLayers(mapCurrentRef, {
          routeId: id,
          beforeIdLayer: stopsBeforeIdLayer,
          dotLayerPrefix: DYNAMIC_COLOR_DOT_LAYER,
          markerLayerPrefix: DYNAMIC_COLOR_STOPS_LAYER,
          sourcePrefix: DYNAMIC_COLOR_STOPS_SOURCE,
        })
      })
      addSourceAsync(
        mapCurrentRef,
        addRouteIdSuffix(STATIC_COLOR_STOPS_SOURCE, id),
        { type: 'FeatureCollection', features: otherFeatures },
        {
          type: 'geojson',
        }
      ).then(() => {
        addMarkerLayers(mapCurrentRef, {
          routeId: id,
          beforeIdLayer: stopsBeforeIdLayer,
          dotLayerPrefix: STATIC_COLOR_DOT_LAYER,
          markerLayerPrefix: STATIC_COLOR_STOPS_LAYER,
          sourcePrefix: STATIC_COLOR_STOPS_SOURCE,
        })
      })
    },
    [routeId, map, routesById, moveFromRouteStopMarkers, moveToRouteStopMarkers, moveFromRouteSequenceLine, moveToRouteSequenceLine]
  )

  const updateRouteLayer = useCallback(
    (id, sequenceData, features) => {
      const mapCurrentRef = map.current
      const fixedSequenceData = isEmpty(sequenceData) ? { type: 'FeatureCollection', features: [] } : sequenceData
      mapCurrentRef.getSource(addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, id))?.setData(fixedSequenceData)
      const [monochromeFeatures, otherFeatures] = groupFeaturesByIcon(features)
      mapCurrentRef
        .getSource(addRouteIdSuffix(DYNAMIC_COLOR_STOPS_SOURCE, id))
        ?.setData({ type: 'FeatureCollection', features: monochromeFeatures })
      mapCurrentRef
        .getSource(addRouteIdSuffix(STATIC_COLOR_STOPS_SOURCE, id))
        ?.setData({ type: 'FeatureCollection', features: otherFeatures })
    },
    [map, routes]
  )

  const getRouteIdFromSource = useCallback(
    source => {
      if (source.includes(toFEDate)) {
        return toFEDate
      }
      if (source.includes(fromFEDate)) {
        return fromFEDate
      }
      return null
    },
    [fromFEDate, toFEDate]
  )

  const onSelectFeatures = useCallback(
    features => {
      const selectedFromMarkerIds = []
      const selectedToMarkersIds = []
      features.forEach(feature => {
        const date = get(feature, 'properties.date')
        const stopId = get(feature, 'properties.id')
        const source = get(feature, 'source')
        const sourceDateId = getRouteIdFromSource(source)
        if (date === fromFEDate && sourceDateId === fromFEDate) selectedFromMarkerIds.push(stopId)
        if (date === fromFEDate && sourceDateId === toFEDate) selectedToMarkersIds.push(stopId)
      })
      dispatch(addSelectedMoveFromRows(selectedFromMarkerIds))
      dispatch(addSelectedMoveToRows(selectedToMarkersIds))
    },
    [fromFEDate, toFEDate]
  )

  const recreateSourcesAndLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !routes.length || isEmpty(routesById)) {
      return null
    }
    setLayersCreated(false)
    routes.forEach(route => {
      removeLayersAndSources(map.current, route.id)
    })
    if (fromFEDate) recreateRouteSourcesAndLayers(fromFEDate, moveFromRouteSequenceLine, moveFromRouteStopMarkers, TOP_LAYER)
    if (toFEDate) recreateRouteSourcesAndLayers(toFEDate, moveToRouteSequenceLine, moveToRouteStopMarkers, MIDDLE_LAYER)
    setLayersCreated(true)
  }, [
    fetchId,
    map,
    isMapLoaded,
    fromSerializedDate,
    toSerializedDate,
    routesById,
    routes,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
  ])

  const updateLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    if (fromFEDate) updateRouteLayer(fromFEDate, moveFromRouteSequenceLine, moveFromRouteStopMarkers)
    if (toFEDate) updateRouteLayer(toFEDate, moveToRouteSequenceLine, moveToRouteStopMarkers)
    toggleMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, fromFEDate, toFEDate)
  }, [
    map,
    isMapLoaded,
    layersCreated,
    fromSerializedDate,
    toSerializedDate,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
    updateRouteLayer,
  ])

  const fitBoundsToLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    fitBoundsMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, moveFromRouteSequenceLine, moveToRouteSequenceLine)
  }, [map, isMapLoaded, layersCreated, moveFromRouteSequenceLine, moveToRouteSequenceLine])

  useEffect(() => {
    recreateSourcesAndLayers()
  }, [isMapLoaded, fromFEDate, toFEDate, routes, fetchId])

  useEffect(() => {
    updateLayers()
  }, [
    fetchId,
    toSerializedDate,
    fromSerializedDate,
    layersCreated,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
  ])

  useEffect(() => {
    if (fitBounds) {
      fitBoundsToLayers()
    }
  }, [fitBounds, moveFromRouteSequenceLine, moveToRouteSequenceLine])

  useEffect(() => {
    if (!map.current || !isMapLoaded || !fromFEDate || !toFEDate) {
      return noop
    }
    const { unsubscribe } = addCustomSelectBoxMapbox({
      map: map.current,
      onSelectFeatures,
      layers: [
        addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, toFEDate),
        addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, toFEDate),
        addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, toFEDate),
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, toFEDate),
        addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, fromFEDate),
        addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, fromFEDate),
        addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, fromFEDate),
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, fromFEDate),
      ],
    })

    return () => {
      unsubscribe()
    }
  }, [fromSerializedDate, toSerializedDate, isMapLoaded])

  return <CommonSelectStopsToMoveMap mapContainer={mapContainer} ToggleComponent={ToggleRouteLayers} />
}

CommonSelectStopsToMoveBetweenDaysMap.propTypes = {
  fetchId: PropTypes.func.isRequired,
  fitBounds: PropTypes.bool.isRequired,
  fromSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]).isRequired,
  mapLayer: PropTypes.string.isRequired,
  moveFromRouteSequenceLine: PropTypes.object.isRequired,
  moveFromRouteStopMarkers: PropTypes.array.isRequired,
  moveToRouteSequenceLine: PropTypes.object.isRequired,
  moveToRouteStopMarkers: PropTypes.array.isRequired,
  routes: PropTypes.array.isRequired,
  routesById: PropTypes.object.isRequired,
  toSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]).isRequired,
  open: PropTypes.bool.isRequired,
  routeId: PropTypes.string.isRequired,
  addSelectedMoveFromRows: PropTypes.func.isRequired,
  addSelectedMoveToRows: PropTypes.func.isRequired,
  ToggleRouteLayers: PropTypes.node.isRequired,
}

export default CommonSelectStopsToMoveBetweenDaysMap
