import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom-v5-compat'
import { toast } from 'react-toastify'
import { useLocation, useHistory } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import Box from '@mui/material/Box'

import { get } from 'utils/lodash'
import { putInvoiceTabState } from 'middleware/actions/billing'
import { getWorkOrderData } from 'data/work-order/workOrderSelectors'
import { useGetMetaFiltersMutation } from 'api/meta/getMetaFilters'
import { useLazyGetWorkOrderDetailsQuery } from 'api/work-order/getWorkOrderDetails'
import { useCreateUpdateDraftFeeMutation } from 'api/work-order/createUpdateDraftFee'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'

import Constants from 'Constants'
import routes from 'router/routes'
import Loader from 'components/common/loader'
import { Grid, Stack, useTheme } from '@mui/material'

import WONotesAccordionVariant from 'components/notes/work-order/WONotesAccordionVariant'
import { Exceptions } from './tables'
import WorkOrderDetails from './invoice-details'

import ServiceDetails from './ServiceDetails'
import InvoiceDetails from './InvoiceDetails'
import TopSection from './TopSection'

import 'styles/accordion.scss'
import './style.scss'
import DisposalTicketAccordion from './disposal-ticket/DisposalTicketAccordion/DisposalTicketAccordionWrapper'
import LocationAccordion from '../../customer-details/content/location/LocationAccordion'

const { MEDIA_SERVER } = Constants

const WODetails = () => {
  const [getMetaFilters, { data: metaFilters }] = useGetMetaFiltersMutation()
  const [getWorkOrderDetails, { data: workOrder, isFetching: isWOLoading, isError }] = useLazyGetWorkOrderDetailsQuery()
  const [createUpdateDraftFee, { isLoading: isCreateUpdateFeeLoading }] = useCreateUpdateDraftFeeMutation()
  const [updateWorkOrder, { isLoading: isUpdateServiceLoading }] = useUpdateWorkOrderMutation()

  const { workOrderId } = useParams()
  const theme = useTheme()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { invoiceTabState } = useSelector(
    state => ({
      invoiceTabState: get(state, 'BillingReducer.invoiceTabState', null),
    }),
    shallowEqual
  )
  const configuredServiceId = get(workOrder, 'configuredServiceId')
  const accountId = get(workOrder, 'account.id')
  const workOrderLocation = get(workOrder, 'location')
  const accountName = get(location, 'state.accountName', '') || get(workOrder, 'account.accountName', '')
  const prevPath = get(location, 'state.prevPath', '')
  const { draftFees, invoiceLine, service, workOrderStatus, allNotes, allExceptions, invoiceStatus } = getWorkOrderData({
    workOrder,
  })

  // This will only work if opened from a anchor tag or direct url, for history and navigation use state flag prevPath
  const isNewPage = !prevPath && (!document.referrer || history?.length === 1)

  const goBack = () => history.goBack()

  const handleRouteClick = () => {
    history.push({
      pathname: routes.app.route,
      state: { workOrderId },
    })
  }

  const handleError = error => {
    const errorMsg = get(error, 'data.message') || get(error, 'data.error')
    if (errorMsg) {
      toast.error(errorMsg)
    }
  }

  const getWODetails = () => {
    getWorkOrderDetails({ workOrderId }).unwrap().catch(handleError)
  }

  useEffect(() => {
    getWODetails()
  }, [])

  useEffect(() => {
    getMetaFilters({})
  }, [])

  return (
    <Box mt={0.5} className="wo-details-container">
      {(isWOLoading || isCreateUpdateFeeLoading || isUpdateServiceLoading) && <Loader sx={{ maxHeight: '100%', position: 'fixed' }} />}
      <TopSection workOrder={workOrder} />
      <Grid container className="content" sx={{ backgroundColor: theme.palette.background.paper }}>
        <Grid
          item
          sx={{
            [theme.breakpoints.down('lg')]: {
              flexBasis: '100%',
              flexGrow: 1,
              flexShrink: 0,
            },
            flexBasis: '384px',
            flexGrow: 0,
            flexShrink: 0,
            overflowY: 'auto',
          }}
          className="left-section"
        >
          {!isNewPage && (
            <Stack
              flexDirection="row"
              sx={{
                cursor: 'pointer',
                mb: 1,
              }}
              alignItems="center"
              backgroundColor="background.header"
              className="back-btn"
              onClick={() => {
                if (prevPath === routes.app.billing) {
                  dispatch(putInvoiceTabState(invoiceTabState.tableState, true))
                }
                goBack()
              }}
            >
              <img className="icon" src={`${MEDIA_SERVER}Return.svg`} alt="" />
              <span className="text">{`Return to: ${accountName}`}</span>
            </Stack>
          )}

          <div className="wo-service-details common-accordion">
            <ServiceDetails
              service={service}
              workOrderId={workOrderId}
              workOrderStatus={workOrderStatus}
              onRouteClick={handleRouteClick}
              onUpdateServiceDetails={updateWorkOrder}
              onUpdateWO={getWODetails}
              onError={handleError}
            />
          </div>

          <Box mt={1}>
            <InvoiceDetails workOrderId={workOrderId} invoiceStatus={invoiceStatus} service={service} invoiceLine={invoiceLine} />
          </Box>
          <Box mt={1}>
            <LocationAccordion
              selectedLocation={workOrderLocation}
              accountId={accountId}
              showActionsMenu={false}
              onSaveTags={getWODetails}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs
          className="right-section"
          sx={{
            [theme.breakpoints.down('lg')]: {
              flexBasis: 'auto',
              flexGrow: 1,
              flexShrink: 0,
              pl: 0,
              pt: 2,
            },
            pl: 2,
            pb: 4,

            flexBasis: {
              md: 'calc(100% - 384px)',
            },
            width: {
              md: 'calc(100% - 384px)',
            },
            flexShrink: {
              xs: 1,
              md: 0,
            },
            flexGrow: {
              xs: 1,
              md: 0,
            },
          }}
        >
          {!isError && (
            <>
              <WorkOrderDetails
                workOrderId={workOrderId}
                workOrderStatus={workOrderStatus}
                service={service}
                draftFees={draftFees}
                invoiceLine={invoiceLine}
                onUpdateInvoice={getWODetails}
                onAddUpdateDraftFee={createUpdateDraftFee}
                onUpdateServiceDetails={updateWorkOrder}
                onError={handleError}
              />
              <Box mt={3}>
                <DisposalTicketAccordion workOrderId={workOrderId} configuredServiceId={configuredServiceId} />
              </Box>
              <Exceptions accountId={accountId} workOrderId={workOrderId} exceptions={allExceptions} />
              <Box mt={3}>
                <WONotesAccordionVariant workOrderId={workOrderId} notes={allNotes} />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default WODetails
