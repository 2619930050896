import React from 'react'
import { Box, Card, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { get } from 'lodash'
import { grey } from '@mui/material/colors'
import { useFormContext } from 'react-hook-form'
import useTheme from '@mui/material/styles/useTheme'
import T from 'T'
import PropTypes from 'prop-types'
import { WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { isValidHex } from 'utils/validations'
import { INPUT_WIDTH } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/settings'
import { HHFormNumberField } from '../../form-fields/v5'

const RenderStopListItem = props => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const { row } = props
  const sequenceError = get(errors, 'sequence.message')
  const theme = useTheme()
  const accountName = get(row, 'accountName')
  const position = get(row, 'position', 0) + 1
  const addressStr = get(row, 'addressStr')
  const toBeInserted = get(row, 'toBeInserted', false)
  const workOrderStatus = get(row, 'workOrderStatus')
  const bgcolor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`, grey[100])
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const color = theme.palette.getContrastText(formattedBgcolor)
  const selectedRoute = watch('selectedRoute')
  const isRouteSelected = Boolean(selectedRoute)

  return (
    <Card variant="outlined" sx={{ width: 'calc(100% - 2px)', height: '63px' }}>
      <ListItem disableGutters sx={{ width: '100%', px: 1 }}>
        <ListItemAvatar sx={{ minWidth: 'unset' }}>
          {toBeInserted === false && (
            <Box
              sx={{
                width: INPUT_WIDTH,
                mr: 1,
                textAlign: 'center',
                height: 32,
                border: '1.5px solid',
                borderColor: 'divider',
                borderRadius: 1,
                backgroundColor: formattedBgcolor,
                color,
                padding: 0.25,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: 'inherit' }}>{position}</Typography>
            </Box>
          )}

          {toBeInserted && (
            <HHFormNumberField
              readOnly={!isRouteSelected}
              required
              error={Boolean(sequenceError)}
              rules={{ required: T.REQUIRED_HELPER_TEXT }}
              control={control}
              name="sequence"
              sx={{ width: INPUT_WIDTH, mr: 1 }}
              size="small"
              allowNegative={false}
              thousandSeparator={false}
              decimalScale={0}
            />
          )}
        </ListItemAvatar>
        <ListItemText primary={accountName} secondary={addressStr} secondaryTypographyProps={{ noWrap: true }} />
      </ListItem>
    </Card>
  )
}

RenderStopListItem.propTypes = {
  row: PropTypes.object,
}

export default RenderStopListItem
