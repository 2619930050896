import React from 'react'
import './index.css'
import { Box } from '@mui/material'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'
import omit from 'lodash/omit'
import { useRevertInvoiceToDraftMutation } from 'api/billing/revertInvoiceToDraft'
import RegenerateInvoiceConfirmation from 'components/billing/invoices/RegenerateInvoiceConfirmation/RegenerateInvoiceConfirmation'
import {
  setInvoicesFilters,
  setInvoicesPage,
  setInvoicesRefetchFlag,
  setInvoicesSendPostData,
  setInvoicesSideView,
  setInvoicesSelectedInvoice,
  setInvoicesDeleteConfirmation,
  setInvoicesRegenerateConfirmation,
  setInvoicesSelectionModel,
  setInvoicesPaymentsState,
  setInvoicesRevertToDraftConfirmation,
  setInvoicesDeleteUnpaidConfirmation,
  selectIsOpenInvoiceSendFailureConfirmation,
  selectDraftInvoicesWithoutPDF,
} from 'slices/billing/invoicesSlice'
import { handleFilterInputChange, handlePrefillResponseModification } from 'utils/table'
import { filterMapToQueryParams } from 'utils/LHSbracket'
import { handleError } from 'utils/error'
import { useDeleteInvoicesMutation, useDeleteUnpaidInvoiceMutation } from 'api/billing/deleteInvoices'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import { resetInvoicesCreationBridgeState } from 'slices/billing/invoicesCreationBridgeSlice'
import Header from 'components/header'
import SecondaryNavbar from 'components/billing/invoices/SecondaryNavbar/SecondaryNavbar'
import { CommonTableFilters } from 'components/common'
import InvoiceDrawer from 'components/billing/invoices/InvoiceDrawer'
import CommonDrawer from 'components/common/CommonDrawer'
import InvoiceEdit from 'components/billing/invoices/invoice-edit'
import InvoicesDataGrid from 'components/billing/invoices/InvoicesDataGrid'
import T from 'T'
import InvoicesTopSection from 'components/billing/invoices/InvoicesTopSection'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'
import RevertInvoiceToDraftConfirmation from '../RevertInvoiceToDraftConfirmation'
import DeleteUnpaidInvoiceConfirmation from '../DeleteUnpaidInvoiceConfirmation'
import DeleteInvoiceConfirmation from '../DeleteInvoiceConfirmation'
import InvoiceSendFailureConfirmation from '../InvoiceSendFailureConfirmation'
import { INVOICE_CONFIGURATOR_DATA_GRID } from '../settings'

const Invoices = () => {
  const dispatch = useDispatch()
  const [deleteInvoices] = useDeleteInvoicesMutation()
  const [deleteUnpaidInvoice] = useDeleteUnpaidInvoiceMutation()
  const [regenerateInvoiceList] = useRegenerateInvoiceListMutation()
  const [revertToDraft] = useRevertInvoiceToDraftMutation()

  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams()
  const allFilters = useSelector(state => state.invoices.filters.allFilters, shallowEqual)
  const activeFilter = useSelector(state => state.invoices.filters.activeFilter)
  const isOpenTableFilters = useSelector(state => state.invoices.filters.isOpenTableFilters)
  const selectedFilterId = useSelector(state => state.invoices.filters.selectedFilterId)
  const existingFilterList = useSelector(state => state.invoices.savedView.existingFilterList)
  const isSideViewOpen = useSelector(state => state.invoices.sideView.isOpen)
  const isEditInvoiceWOListingOpen = useSelector(state => state.invoices.sideView.isEditInvoiceWOListingOpen)
  const currentInvoice = useSelector(state => state.invoices.sideView.currentInvoice)

  const isOpenInvoiceSendFailureConfirmation = useSelector(selectIsOpenInvoiceSendFailureConfirmation)
  const draftInvoicesWithoutPDF = useSelector(selectDraftInvoicesWithoutPDF)
  const deleteInvoiceConfirmation = useSelector(state => state.invoices.deleteInvoiceConfirmation)
  const regenerateConfirmation = useSelector(state => state.invoices.regenerateConfirmation)
  const revertToDraftConfirmation = useSelector(state => state.invoices.revertToDraftConfirmation)
  const deleteUnpaidInvoiceConfirmation = useSelector(state => state.invoices.deleteUnpaidInvoiceConfirmation)

  const currentAccountId = get(currentInvoice, 'accountId', '')
  const currentInvoiceNumber = get(currentInvoice, 'invoiceNumber', '')
  const currentAccountName = get(currentInvoice, 'accountName', '')
  const currentInvoiceId = get(currentInvoice, 'invoiceId', '')
  const currentInvoiceDate = get(currentInvoice, 'invoiceDate', '')
  const currentInvoiceStatus = get(currentInvoice, 'invoiceStatus', '')
  const isCurrentUnPosted = get(currentInvoice, 'isCurrentUnPosted', false)

  const selectedInvoicesCount = useSelector(state => state.invoicesCreationBridge.createdInvoicesData.selectedInvoicesCount)
  const selectedBillingProfileName = useSelector(state => state.invoicesCreationBridge.createdInvoicesData.selectedBillingProfileName)
  const skipZeroAmountInvoices = useSelector(state => state.invoicesCreationBridge.createdInvoicesData.skipZeroAmountInvoices)
  const isInvoiceCreationSuccessModalOpen = useSelector(
    state => state.invoicesCreationBridge.createdInvoicesData.isInvoiceCreationSuccessModalOpen
  )
  const isOpenTakePaymentsDialog = useSelector(state => state.invoices.payments.isOpenTakePaymentsDialog)
  const selectedRow = useSelector(state => state.invoices.payments.selectedRow, shallowEqual)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  const selectedInvoiceNumber = get(selectedRow, 'invoiceNumber')

  const handlePaymentsDrawerClose = () => dispatch(setInvoicesPaymentsState({ isOpenTakePaymentsDialog: false, selectedRow: undefined }))

  const handleFilterApply = () => {
    const queryParams = filterMapToQueryParams(allFilters)
    setSearchParams({ ...queryParams })
    dispatch(setInvoicesPage(0))
    dispatch(setInvoicesRefetchFlag(true))
  }

  const handleFilterChange = (key, value, columnName) => {
    const newFiltersState = handleFilterInputChange(key, value, columnName, allFilters)
    dispatch(
      setInvoicesFilters({
        ...newFiltersState,
        refetchFlag: false,
      })
    )
  }

  const handleFilterClose = () => {
    let newAllFilters = allFilters
    if (selectedFilterId === '') {
      newAllFilters = omit(allFilters, activeFilter)
    } else {
      const currentFilter = existingFilterList.find(filterListEl => selectedFilterId === filterListEl.searchFilterId)
      const filteredRecord = get(currentFilter, 'searchFilters', []).find(
        filterListEl => filterListEl.columnName === allFilters[activeFilter].columnName
      )

      if (!filteredRecord) {
        newAllFilters = omit(allFilters, activeFilter)
      } else {
        const initialFilter = INVOICE_CONFIGURATOR_DATA_GRID.find(conf => conf.columnName === allFilters[activeFilter].columnName)
        newAllFilters[activeFilter] = handlePrefillResponseModification(initialFilter, filteredRecord)
      }
    }
    const queryParams = filterMapToQueryParams(newAllFilters)
    setSearchParams({ ...queryParams })
    dispatch(setInvoicesPage(0))
    dispatch(setInvoicesFilters({ allFilters: newAllFilters, isOpenTableFilters: false, refetchFlag: true }))
  }

  const handleEditInvoice = () => {
    dispatch(
      setInvoicesSideView({
        isEditInvoiceWOListingOpen: !isEditInvoiceWOListingOpen,
        isOpen: false,
      })
    )
  }

  const handleInvoiceSideView = (accountId, invoiceNumber, invoiceId, unPostedAmountCents, accountName, invoiceDate, status) => {
    const newCurrentInvoice = {
      ...(accountId && { accountId }),
      ...(invoiceNumber && { invoiceNumber }),
      ...(invoiceId && { invoiceId }),
      ...(unPostedAmountCents && { unPostedAmountCents }),
      ...(accountName && { accountName }),
      ...(invoiceDate && { invoiceDate }),
      ...(status && { status }),
      ...(unPostedAmountCents && { isCurrentUnPosted: unPostedAmountCents > 0 }),
    }
    dispatch(
      setInvoicesSideView({
        isOpen: !isSideViewOpen,
      })
    )
    dispatch(setInvoicesSelectedInvoice(newCurrentInvoice))
  }

  const onRefreshTable = () => {
    dispatch(setInvoicesRefetchFlag(true))
  }

  const handleCloseInvoiceSendFailureConfirmation = () => {
    dispatch(setInvoicesSendPostData({ isOpenInvoiceSendFailureConfirmation: false, draftInvoicesWithoutPDF: [] }))
  }

  const handleCloseDeleteInvoiceConfirmation = () =>
    dispatch(setInvoicesDeleteConfirmation({ isOpen: false, invoiceIds: [], invoiceNumbers: [] }))

  const handleCloseInvoiceCreationSuccessModal = () => {
    dispatch(resetInvoicesCreationBridgeState())
  }

  const handleConfirmInvoiceCreationSuccessModal = () => {
    dispatch(resetInvoicesCreationBridgeState())
    dispatch(setInvoicesRefetchFlag(true))
  }

  const handleCloseInvoiceToDraftConfirmation = () => {
    dispatch(setInvoicesRevertToDraftConfirmation({ isOpen: false, invoiceId: '' }))
  }

  const handleCloseDeleteUnpaidInvoiceConfirmation = () => {
    dispatch(setInvoicesDeleteUnpaidConfirmation({ isOpen: false, invoiceId: '' }))
  }

  const handleDeleteInvoiceList = () => {
    deleteInvoices({ invoiceIds: deleteInvoiceConfirmation.invoiceIds })
      .unwrap()
      .then(() => {
        toast.success(T.DELETE_INVOICES_SUCCESS_MSG)
        dispatch(setInvoicesSelectionModel({ selectionModel: [], rows: [] }))
        setTimeout(onRefreshTable, 2000)
      })
      .catch(handleError)
      .finally(() => {
        handleCloseDeleteInvoiceConfirmation()
      })
  }
  const handleCloseInvoiceRegenerateConfirmation = () =>
    dispatch(setInvoicesRegenerateConfirmation({ isOpen: false, invoiceIds: [], invoiceNumbers: [], invoices: [], isMultiSelect: false }))

  const handleRegenerateInvoiceList = () => {
    regenerateInvoiceList({ invoiceIds: regenerateConfirmation.invoiceIds })
      .unwrap()
      .then(() => {
        toast.success(T.REGENERATE_INVOICES_SUCCESS_MSG)
        if (regenerateConfirmation.isMultiSelect) {
          dispatch(setInvoicesSelectionModel({ selectionModel: [], rows: [] }))
        }
      })
      .catch(handleError)
      .finally(() => {
        handleCloseInvoiceRegenerateConfirmation()
      })
  }

  const handleRevertInvoiceToDraft = () => {
    revertToDraft({ id: revertToDraftConfirmation.invoiceId })
      .unwrap()
      .then(() => {
        toast.success(T.REVERT_INVOICE_TO_DRAFT_SUCCESS)
        onRefreshTable()
      })
      .catch(handleError)
      .finally(() => {
        handleCloseInvoiceToDraftConfirmation()
      })
  }

  const handleDeleteUnpaidInvoice = () => {
    deleteUnpaidInvoice({ id: deleteUnpaidInvoiceConfirmation.invoiceId })
      .unwrap()
      .then(() => {
        toast.success(T.DELETE_INVOICE_SUCCESS_MSG)
        onRefreshTable()
      })
      .catch(handleError)
      .finally(() => {
        handleCloseDeleteUnpaidInvoiceConfirmation()
      })
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100vw">
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <Header />
        <SecondaryNavbar />
        <Box overflow="auto" flex={1} display="flex" flexDirection="column" backgroundColor="background.paper">
          <InvoicesTopSection />
          <InvoicesDataGrid />
        </Box>
      </BillingLayout>
      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        label={get(allFilters, `${activeFilter}.label`)}
        isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
        isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
        filterComponent="billing"
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={handleFilterClose}
      />
      <InvoiceDrawer
        open={isSideViewOpen}
        invoiceNumber={currentInvoiceNumber}
        invoiceId={currentInvoiceId}
        invoiceStatus={currentInvoiceStatus}
        accountName={currentAccountName}
        invoiceDate={currentInvoiceDate}
        onEditInvoice={handleEditInvoice}
        onClose={handleInvoiceSideView}
      />
      <CommonDrawer isOpen={isEditInvoiceWOListingOpen} className="common-drawer-container invoice-work-order" onChange={handleEditInvoice}>
        <InvoiceEdit
          isOpen={isEditInvoiceWOListingOpen}
          invoiceNumber={currentInvoiceNumber}
          accountId={currentAccountId}
          accountName={currentAccountName}
          invoiceDate={currentInvoiceDate}
          invoiceId={currentInvoiceId}
          invoiceStatus={currentInvoiceStatus}
          isEditInvoice={isCurrentUnPosted}
          onInvoiceSideView={handleInvoiceSideView}
          onClose={handleEditInvoice}
          onRefreshTable={onRefreshTable}
        />
      </CommonDrawer>
      <InvoiceSendFailureConfirmation
        isOpen={isOpenInvoiceSendFailureConfirmation}
        invoices={draftInvoicesWithoutPDF}
        onClose={handleCloseInvoiceSendFailureConfirmation}
      />
      <DeleteInvoiceConfirmation
        isOpen={deleteInvoiceConfirmation.isOpen}
        invoices={deleteInvoiceConfirmation.invoiceNumbers}
        onClose={handleCloseDeleteInvoiceConfirmation}
        onDelete={handleDeleteInvoiceList}
      />

      <RegenerateInvoiceConfirmation
        isOpen={regenerateConfirmation.isOpen}
        invoices={regenerateConfirmation.invoices}
        onClose={handleCloseInvoiceRegenerateConfirmation}
        onRegenerate={handleRegenerateInvoiceList}
      />
      <RevertInvoiceToDraftConfirmation
        isOpen={revertToDraftConfirmation.isOpen}
        onClose={handleCloseInvoiceToDraftConfirmation}
        onConfirm={handleRevertInvoiceToDraft}
      />

      <DeleteUnpaidInvoiceConfirmation
        isOpen={deleteUnpaidInvoiceConfirmation.isOpen}
        onClose={handleCloseDeleteUnpaidInvoiceConfirmation}
        onConfirm={handleDeleteUnpaidInvoice}
      />
      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog
          isOpen={isOpenTakePaymentsDialog}
          initialAccount={{ id: get(selectedRow, 'accountId'), invoiceNumber: selectedInvoiceNumber }}
          onClose={handlePaymentsDrawerClose}
        />
      )}
      <HHConfirmDialog
        DialogProps={{
          maxWidth: 'sm',
          fullWidth: true,
        }}
        isOpen={isInvoiceCreationSuccessModalOpen}
        confirmTitle={T.INVOICE_CREATION}
        confirmDescription={
          <>
            {`${selectedInvoicesCount} ${
              selectedInvoicesCount === 1 ? 'invoice is' : 'invoices are'
            } being generated for ${selectedBillingProfileName}. This will take a few`}
            <br />
            minutes. Come back and click refresh later.
            <br />
            {skipZeroAmountInvoices ? '* Please note that $0 invoices will not be generated.' : ''}
          </>
        }
        showCancelButton={false}
        ConfirmButtonProps={{
          color: 'primary',
          variant: 'contained',
        }}
        onClose={handleCloseInvoiceCreationSuccessModal}
        onConfirm={handleConfirmInvoiceCreationSuccessModal}
        confirmButtonTitle={T.OK}
      />
    </Box>
  )
}

Invoices.propTypes = {}

export default Invoices
