import React, { useCallback } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import RenderLocationCell from 'components/locations/LocationsDataGrid/RenderLocationCell'
import RenderLocationDetailPanelContent from 'components/locations/LocationsDataGrid/RenderLocationDetailPanelContent/RenderLocationDetailPanelContent'
import { Box, darken, lighten, LinearProgress, Stack, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { getLocationRowClassName, LOCATIONS_DESKTOP_ROW_HEIGHT } from 'components/locations/settings'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useFormContext } from 'react-hook-form'
import SearchSharpIcon from '@mui/icons-material/SearchSharp'
import T from 'T'
import CSNotesDialogVariant from 'components/notes/configured-service/CSNotesDialogVariant'
import { useDispatch, useSelector } from 'react-redux'
import { selectCsDialogOpen, selectCsSelectedService, setCsNoteDialog } from 'slices/locations/locationsSlice'
import CustomLocationRow from 'components/locations/LocationsDataGrid/CustomLocationRow'

const LocationsDataGrid = ({ rows, loading, apiRef }) => {
  const dispatch = useDispatch()
  const getRowId = useCallback(row => get(row, 'locationId'), [])
  const getDetailPanelHeight = useCallback(() => 'auto', [])
  const theme = useTheme()
  const isOpenCSNotesDialog = useSelector(selectCsDialogOpen)
  const selectedService = useSelector(selectCsSelectedService)
  const { watch, setValue } = useFormContext()
  const expandedRowIds = watch('expandedRowIds')
  const columns = [
    {
      field: 'locationId',
      renderCell: RenderLocationCell,
      flex: 1,
      cellClassName: 'location-row-cell',
    },
  ]

  const getRowSpacing = useCallback(
    params => ({
      bottom: params.isLastVisible ? 128 : 0,
    }),
    []
  )

  const getRowClassName = useCallback(getLocationRowClassName, [])

  const getRowHeight = useCallback(() => {
    return LOCATIONS_DESKTOP_ROW_HEIGHT
  }, [])

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(newIds => {
    setValue('expandedRowIds', newIds)
  }, [])

  const closeCSNotesDialog = () => {
    dispatch(
      setCsNoteDialog({
        open: false,
        selectedService: null,
      })
    )
  }

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  return (
    <Box flex="1" position="relative">
      <Box sx={{ position: 'absolute', inset: 0, overflowX: 'hidden' }}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-row, & .MuiDataGrid-cell,': {
              border: 0,
            },
            '& .MuiDataGrid-cell.location-row-cell': {
              pr: 0.8,
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
              background: 'transparent',
            },
            '& .MuiDataGrid-row, & .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover, & .MuiDataGrid-row:focus-within ': {
              '&.held': {
                background: theme.palette.background.hold,
                '&:hover': {
                  backgroundColor: getHoverBackgroundColor(theme.palette.background.hold, theme.palette.mode),
                },
              },
              '&.suspended': {
                background: theme.palette.background.suspension,
                '&:hover': {
                  backgroundColor: getHoverBackgroundColor(theme.palette.background.suspension, theme.palette.mode),
                },
              },
              '&.deactivated': {
                background: theme.palette.background.deactivated,
                '&:hover': {
                  backgroundColor: getHoverBackgroundColor(theme.palette.background.deactivated, theme.palette.mode),
                },
              },
            },

            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'hidden',
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack
                sx={{
                  pt: 4,
                }}
                justifyContent="center"
                alignItems="center"
              >
                <SearchSharpIcon />
                <Typography variant="h6">{T.NO_LOCATIONS_FOUND}</Typography>
                <Typography>{T.SEARCH_TRY_AGAIN}</Typography>
              </Stack>
            ),
            row: CustomLocationRow,
            detailPanelExpandIcon: ChevronRightIcon,
            detailPanelCollapseIcon: ExpandMoreIcon,
            loadingOverlay: LinearProgress,
          }}
          apiRef={apiRef}
          columnHeaderHeight={0}
          columns={columns}
          initialState={{
            detailPanel: {
              expandedRowIds,
            },
          }}
          detailPanelExpandedRowIds={expandedRowIds}
          disableColumnSelector
          getDetailPanelContent={params => <RenderLocationDetailPanelContent {...params} />}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowClassName={getRowClassName}
          getRowHeight={getRowHeight}
          getRowId={getRowId}
          getRowSpacing={getRowSpacing}
          hideFooter
          loading={loading}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          rows={rows}
        />
        <CSNotesDialogVariant isOpen={isOpenCSNotesDialog} configuredServiceId={selectedService?.id} onClose={closeCSNotesDialog} />
      </Box>
    </Box>
  )
}

LocationsDataGrid.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  rows: PropTypes.array,
  loading: PropTypes.bool,
}

export default LocationsDataGrid
