import React from 'react'
import PropTypes from 'prop-types'
import { noop, isObject, isEmpty } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { getOnlyDigits } from 'utils/string'
import SearchOptionListHeader from 'components/locations/filters/Search/SearchOptionListHeader'
import { Box } from '@mui/material'
import { PHONE_SEARCH_TYPE } from 'components/locations/filters/Search/settings'

const SearchListbox = ({ children, onHeaderClick = noop, sx, ...rest }) => {
  const { watch } = useFormContext()
  const searchInputValue = watch('searchInputValue')
  const searchValue = watch('searchValue')
  const searchType = watch('searchType')
  const text = searchType === PHONE_SEARCH_TYPE ? getOnlyDigits(searchInputValue) : searchInputValue
  const showList = text.trim() !== '' || (isObject(searchValue) && !isEmpty(searchValue))

  return (
    <Box component="ul" role="listbox" sx={{ ...sx, ...(!showList && { display: 'none' }) }} {...rest}>
      <SearchOptionListHeader onClick={onHeaderClick} />
      {children}
    </Box>
  )
}

SearchListbox.propTypes = {
  children: PropTypes.node,
  onHeaderClick: PropTypes.func,
  sx: PropTypes.object,
}

export default SearchListbox
