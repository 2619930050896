import T from 'T'

export const DEFAULT_REPORTS = [
  {
    reportName: 'Accounts Receivable',
    updatedAt: new Date(),
    reportDesc: 'Customer’s outstanding balance based on services provided.',
    reportParentType: T.AR_REPORT,
    reportTypeFilter: T.AR_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Revenue',
    updatedAt: new Date(),
    reportDesc: 'Displays a customer’s revenue for the last 7 days and key accounting information.',
    reportParentType: T.REVENUE_REPORT,
    reportTypeFilter: T.REVENUE_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Services',
    updatedAt: new Date(),
    reportDesc: 'Displays a customer’s logistical and recent route information.',
    reportParentType: T.SERVICE_REPORT,
    reportTypeFilter: T.SERVICE_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Payments',
    updatedAt: new Date(),
    reportDesc: 'Displays payment details of customers.',
    reportParentType: T.PAYMENT_REPORT,
    reportTypeFilter: T.PAYMENT_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Invoices',
    reportDesc: 'Displays invoices details.',
    reportParentType: T.INVOICE_REPORT,
    reportTypeFilter: T.INVOICE_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Sent Invoices',
    updatedAt: new Date(),
    reportDesc: 'Displays details of invoices that a user has sent to their customers.',
    reportParentType: T.SI_REPORT,
    reportTypeFilter: T.SI_REPORT_TYPE_FILTER,
  },
  {
    reportName: 'Work Order',
    updatedAt: new Date(),
    reportDesc: '-',
    reportParentType: T.WO_REPORT,
    reportTypeFilter: T.WO_REPORT,
  },
  {
    reportName: 'Work Order',
    updatedAt: new Date(),
    reportDesc: '-',
    reportParentType: T.WO_REPORT,
  },
]

export const REPORTS_DATA_VALUES = {
  AR_REPORT: 'AR_REPORT', // Initial Selected tab Id
  INVOICE_REPORT: 'INVOICE_REPORT',
  SI_REPORT: 'SI_REPORT',
  PAYMENT_REPORT: 'PAYMENT_REPORT',
  REVENUE_REPORT: 'REVENUE_REPORT',
  SERVICE_REPORT: 'SERVICE_REPORT',
  WO_REPORT: 'WO_REPORT',
}

export const PDF_LIMIT_MSG = 'There are too many columns selected. Please choose up to 10 columns only for PDF exports.'
