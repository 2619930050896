import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { capitalize, cloneDeep, uniq } from 'lodash'

import { Trash } from '@styled-icons/heroicons-outline/Trash'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { Box, useTheme, Typography, IconButton, Grid } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { getSummaryTableData } from 'utils/payment'

import T from 'T'
import HHDatePicker from 'components/common/HHDatePicker'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import AddToGroupField from './AddToGroupField'
import PaymentMethod from './common/PaymentMethod'

const Summary = ({
  requestedPayments = [],
  batchDate,
  groupId = '',
  showAddToGroupField = false,
  onChange,
  onEditClick,
  onRemoveClick,
}) => {
  const theme = useTheme()
  const { summaryRows, total } = getSummaryTableData(cloneDeep(requestedPayments))
  const customerCount = uniq(requestedPayments.map(({ accountId }) => accountId)).length

  const getRowHeight = useCallback(() => 50, [])

  const renderActions = ({ row }) => (
    <>
      <IconButton onClick={() => onEditClick(row.refId, row.accountId)}>
        <PencilAlt width={16} height={16} />
      </IconButton>

      <IconButton onClick={() => onRemoveClick(row.refId, row.invoiceNumber, row.totalInvoices)}>
        <Trash width={16} height={16} />
      </IconButton>
    </>
  )

  const renderFooter = () => (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height={52}
      px={2}
      bgcolor="#F7F9FC"
      borderTop="1px solid"
      borderColor="divider"
      borderRadius={1}
    >
      {customerCount > 0 && (
        <>
          <Typography variant="h5" fontWeight={400}>
            {`${customerCount} Customer${customerCount > 1 ? 's' : ''}`}
          </Typography>
          <Typography mr={12} variant="h5" fontWeight={400}>
            <HHDisplayMoney value={total} />
          </Typography>
        </>
      )}
    </Box>
  )

  return (
    <Box px={3} py={2}>
      <Grid container columnSpacing={2} justifyContent="space-between">
        <Grid item xs sm={6}>
          <HHDatePicker
            label={capitalize(T.BATCH_DATE)}
            handleChange={date => onChange('batchDate', date)}
            value={batchDate}
            TextFieldProps={{ deprecatedLabel: false }}
            disableFuture
          />
        </Grid>
        {showAddToGroupField && (
          <Grid item xs sm={6}>
            <AddToGroupField groupId={groupId} onChange={event => onChange('groupId', event.target.value)} />
          </Grid>
        )}
      </Grid>
      <Box mt={2} height="calc(100vh - 355px)">
        <DataGridPro
          rows={summaryRows}
          disableRowSelectionOnClick
          columns={[
            {
              flex: 2,
              headerName: 'Customer',
              field: 'customerName',
              sortable: false,
            },
            {
              flex: 2,
              headerName: 'Invoice #',
              field: 'invoiceNumber',
              sortable: false,
            },
            {
              flex: 2,
              headerName: 'Payment type',
              field: 'paymentMethod',
              sortable: false,
              renderCell: PaymentMethod,
            },
            {
              flex: 2,
              headerName: 'Amount',
              field: 'amount',
              sortable: false,
              renderCell: ({ formattedValue }) => <HHDisplayMoney value={formattedValue} />,
              headerAlign: 'right',
              align: 'right',
            },
            {
              field: 'actions',
              headerName: '',
              sortable: false,
              width: 100,
              minWidth: 100,
              maxWidth: 100,
              renderCell: renderActions,
            },
          ]}
          getRowId={({ refId, invoiceNumber, paymentMethod }) => `${refId}-${invoiceNumber}-${paymentMethod}`}
          getRowHeight={getRowHeight}
          disableColumnMenu
          slots={{ footer: renderFooter }}
          sx={{
            ...theme.typography.h6,
            fontWeight: 400,
            color: theme.palette.text.primary,
            '& .MuiDataGrid-columnHeaders': {
              ...theme.typography.subtitle2,
              background: '#F7F9FC',
              color: theme.palette.text.primary,
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
            },
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
              padding: '0 16px',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Box>
    </Box>
  )
}

Summary.propTypes = {
  batchDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  groupId: PropTypes.string,
  showAddToGroupField: PropTypes.bool,
  requestedPayments: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
}

export default Summary
