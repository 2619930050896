import React, { Fragment, useCallback } from 'react'
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro'
import { unstable_composeClasses as composeClasses, Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { get } from 'lodash'

const useUtilityClasses = ownerState => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

const CustomGridTreeDataGroupingCell = props => {
  const { id, field, rowNode, row } = props
  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useUtilityClasses({ classes: rootProps.classes })
  const isInvoiceRow = get(row, 'isInvoiceRow', false)
  const { treeDataCollapseIcon, treeDataExpandIcon } = rootProps.slots

  const getIcon = useCallback(() => {
    if (rowNode?.childrenExpanded && !isInvoiceRow && treeDataCollapseIcon) {
      return treeDataCollapseIcon
    }
    if (treeDataExpandIcon && !isInvoiceRow) {
      return treeDataExpandIcon
    }
    return Fragment
  }, [rootProps, rowNode])

  const handleKeyDown = event => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event)
    }
  }

  const handleClick = event => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  const Icon = getIcon()

  return (
    <Box className={classes.root} sx={{ pl: 2 }}>
      <Box className={classes.toggle} sx={{ minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <IconButton
          size="small"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
          aria-label={
            rowNode.childrenExpanded ? apiRef.current.getLocaleText('treeDataCollapse') : apiRef.current.getLocaleText('treeDataExpand')
          }
        >
          <Icon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}

CustomGridTreeDataGroupingCell.propTypes = {}

export default CustomGridTreeDataGroupingCell
