import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useLazyGetBillingPaymentRevenueQuery } from 'api/billing/getBillingPaymentRevenue'
import { get } from 'utils/lodash'
import T from 'T'
import BillingSecondaryNavBar from '../common/BillingSecondaryNavBar'

const SecondaryNavbar = () => {
  const [getBillingPaymentRevenue, { data }] = useLazyGetBillingPaymentRevenueQuery()
  const rawSearchFilters = useSelector(state => state.payments.filters.searchFilters, shallowEqual)
  const selectionModel = useSelector(state => state.payments.selectionModel, shallowEqual)
  const selectedRows = useSelector(state => state.payments.selectedRows, shallowEqual)
  const customers = Array.isArray(selectionModel) && selectionModel.length > 0 ? get(data, 'accountCount', 0) : '-'
  const revenue = Array.isArray(selectionModel) && selectionModel.length > 0 ? get(data, 'totalRevenueCents', 0) : '-'
  const searchFilters = isEmpty(rawSearchFilters) ? [] : rawSearchFilters

  useEffect(() => {
    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      getBillingPaymentRevenue({
        searchFilters,
        historyIds: selectedRows.map(({ accountTransactionHistoryId }) => accountTransactionHistoryId),
      })
    }
  }, [selectedRows])

  return <BillingSecondaryNavBar heading={T.PAYMENTS} showRevenueCard customers={customers} revenue={revenue} />
}

export default SecondaryNavbar
