import React from 'react'
import T from 'T'
import PropTypes from 'prop-types'

import SettingsTable from '../../SettingsTable'

const columns = [
  { name: T.NAME, key: 'name' },
  { name: T.STATUS, key: 'status', align: 'right' },
]

const ListAssets = ({ assetState, isLoading, assets, handlePageChange, handleRowsPerPageChange, handleEdit }) => {
  const data = assets.map(({ id, assetName: name, active }) => ({ id, name, status: active ? 'Active' : 'Deactivated' }))
  return (
    <SettingsTable
      columns={columns}
      data={data}
      onEditRecord={({ id }) => handleEdit(id)}
      paginated
      page={assetState.page}
      count={assetState.totalAssetsCount}
      pageCount={assetState.totalPageCount}
      size={assetState.rowsPerPage}
      isLoading={isLoading}
      onPageChange={(_, newPage) => handlePageChange(newPage)}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  )
}

ListAssets.propTypes = {
  isLoading: PropTypes.bool,
  assetState: PropTypes.object,
  assets: PropTypes.array,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleEdit: PropTypes.func,
}

export default ListAssets
