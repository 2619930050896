import React from 'react'
import { getCustomerBillingDetails } from 'data/customer/billingDetailsSelector'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

const RenderBillingProfileFilterOption = (props, option, { selected }) => {
  const data = getCustomerBillingDetails({ accountBilling: { billingProfile: option } })
  const { billingCycle, paymentType, timing, paymentTerms, profileName } = data
  const secondary = `${[paymentType, timing, billingCycle, paymentTerms].filter(Boolean).join(' | ')}`
  return (
    <ListItemButton dense disableGutters {...props}>
      <ListItemIcon
        sx={{
          minWidth: 0,
        }}
      >
        <Checkbox checked={selected} />
      </ListItemIcon>
      <ListItemText
        primary={profileName}
        primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }}
        secondary={secondary}
        secondaryTypographyProps={{
          variant: 'body1',
          fontWeight: 500,
        }}
      />
    </ListItemButton>
  )
}

RenderBillingProfileFilterOption.propTypes = {}

export default RenderBillingProfileFilterOption
