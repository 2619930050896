import isEmpty from 'lodash/isEmpty'

import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { get } from './lodash'

const { LNG_LAT_FIX } = MAP_DEFAULT_OPTIONS

export const combineAddressInfo = addressObj => {
  if (!addressObj || isEmpty(addressObj)) {
    // Check for empty conditions
    return ''
  }

  const line1 = get(addressObj, 'line1', '')
  const city = get(addressObj, 'city', '')
  const state = get(addressObj, 'state', '')
  const zipCode = get(addressObj, 'zipCode', '')
  const stateAndZipCode = `${state} ${zipCode}`.trim()
  const unitNumber = get(addressObj, 'unitNumber', '')

  // Join address
  return [line1, unitNumber, city, stateAndZipCode].filter(Boolean).join(', ')
}

export const isAddressObjectEmpty = addressObject => {
  if (typeof addressObject !== 'object' || addressObject === null) {
    return true
  }
  const keysToCheck = ['line1', 'unitNumber', 'city', 'state', 'country', 'zipCode']
  return keysToCheck.every(key => !addressObject[key])
}

export const formatPhone = phone => {
  try {
    const normalizePhone = `${phone}`.replace(/\D/g, '')
    const result = normalizePhone.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (result) {
      return `(${result[1]}) ${result[2]}-${result[3]}`
    }
    return phone
  } catch (err) {
    return phone
  }
}

export const formatContactName = contactObj => {
  if (!contactObj || isEmpty(contactObj)) {
    // Check for empty conditions
    return ''
  }
  const firstName = get(contactObj, 'firstName', '')
  const lastName = get(contactObj, 'lastName', '')
  return `${firstName} ${lastName}`.trim()
}

export const handleTooltipOnLargeText = () => {
  // Todo: optimize this function when called from useEffect
  try {
    document.querySelectorAll('td.trim-large-notes span, td.trim-large-notes .wrapper').forEach(el => {
      if (el.offsetWidth < el.scrollWidth) {
        el.parentElement.classList.add('hasTooltip')
      } else {
        el.parentElement.classList.remove('hasTooltip')
      }
    })
  } catch (err) {
    console.log('tooltip error', err)
  }
}

export const getAddressParams = address => {
  const streetNumber = get(address, 'streetNumber', '')
  const streetName = get(address, 'streetName', '')
  const line1 = `${streetNumber} ${streetName}`.trim()
  const city = get(address, 'city', '')
  const stateShortCode = get(address, 'stateShortCode', '').split('-')
  const state = get(stateShortCode, '[1]', '')
  const zipCode = get(address, 'zipCode', '')
  const country = get(address, 'country', '')
  const longitude = get(address, 'center.longitude', '')
  const latitude = get(address, 'center.latitude', '')
  const longitudeFixed = longitude ? longitude.toFixed(LNG_LAT_FIX) : ''
  const latitudeFixed = latitude ? latitude.toFixed(LNG_LAT_FIX) : ''
  return { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed }
}

export const splitAddressLine1 = line1 => {
  const result = {
    number: null,
    streetName: null,
  }

  const parts = line1.split(' ')

  for (let i = 0; i < parts.length; i++) {
    const currentPart = parts[i]

    if (!Number.isNaN(currentPart)) {
      result.number = currentPart
      result.streetName = parts.slice(i + 1).join(' ')
      break
    }
  }

  return result
}
