import api from 'api'
import { CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_NOTES_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllWorkOrderNotes: builder.query({
      query: ({ workOrderId }) => ({
        url: `/api/v1/core/work-orders/${workOrderId}/notes`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_WORK_ORDER_NOTES_LIST],
    }),
    getWorkOrderNoteDetails: builder.query({
      query: ({ workOrderId, noteId }) => ({
        url: `/api/v1/core/work-orders/${workOrderId}/notes/${noteId}`,
        method: 'GET',
      }),
    }),
    getWorkOrderNoteHistory: builder.query({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/work-orders/${id}/notes/${noteId}/history`,
        method: 'GET',
      }),
    }),
    createWorkOrderNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/work-orders/${id}/notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_NOTES_LIST],
    }),
    updateWorkOrderNoteNew: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/work-orders/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_NOTES_LIST],
    }),
    deleteWorkOrderNote: builder.mutation({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/work-orders/${id}/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_NOTES_LIST],
    }),
    patchWorkOrderNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/work-orders/${id}/notes/${noteId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_NOTES_LIST],
    }),
  }),
})

export const {
  useLazyGetAllWorkOrderNotesQuery,
  useLazyGetWorkOrderNoteDetailsQuery,
  useLazyGetWorkOrderNoteHistoryQuery,
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
  useDeleteWorkOrderNoteMutation,
  usePatchWorkOrderNoteMutation,
} = extendedApi
