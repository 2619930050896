import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useSelector, shallowEqual } from 'react-redux'
import { Card, Box, Grid } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import HHTabList from 'components/common/HHTabList'
import HHTextField from 'components/form-fields/v5/HHTextField'
import CommonSwitch from 'components/common/CommonSwitch'
import Office from 'components/settings/pages/users/roles/Office'
import Road from 'components/settings/pages/users/roles/Road'
import FooterButton from 'components/buttons/FooterButton'

import { OFFICE_ROAD_OPTIONS, OFFICE_PERMISSION, ROAD_PERMISSION } from 'settings/constants/roles'

import T from 'T'

import { canUpdateSettings, canUpdateUsers, canUpdatePermissions, canSeePermissionSettings } from 'data/permissions/permissionsSelectors'

const AddRoles = ({
  roleState = {},
  setRoleState = noop,
  handleSave = noop,
  handleCancel = noop,
  onHandleNameChange = noop,
  calledBy = '',
  readonly = '',
}) => {
  const seePermissionSettings = useSelector(canSeePermissionSettings, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const handleTabChange = (event, newValue) => {
    setRoleState({ userType: newValue })
  }

  const handleSwitchVal = (title, value) => {
    if (updateSettings) {
      let switchTitle = ''
      if (title === T.ACTIVATE_ROAD_LOGIN) {
        switchTitle = 'allowRoadLogin'
      } else if (title === T.ACTIVATE_OFFICE_LOGIN) {
        switchTitle = 'allowOfficeLogin'
      } else {
        switchTitle = 'active'
      }

      if (updatePermissions && updateUsers) {
        setRoleState({ [switchTitle]: value, footerFlag: true })
      }
    }
  }

  return (
    <Card>
      <TabContext value={roleState.userType}>
        <Grid
          container
          spacing="20"
          alignItems="center"
          justifyContent={calledBy === '' ? 'space-between' : ''}
          sx={{ p: calledBy === '' ? '20px 24px 10px' : '' }}
        >
          {calledBy === '' && (
            <Grid item xs="4">
              <HHTextField
                fullWidth
                label={`${T.ROLE} ${T.NAME}`}
                placeholder={`${T.ROLE} ${T.NAME}`}
                value={roleState.profileName}
                autoComplete="off"
                name="profileName"
                onChange={e => onHandleNameChange(e)}
              />
            </Grid>
          )}

          {seePermissionSettings && (
            <Grid item mt={4}>
              <HHTabList tabOptions={OFFICE_ROAD_OPTIONS} handleChange={handleTabChange} />
            </Grid>
          )}

          {seePermissionSettings && (
            <Grid item xs="4" mt={3}>
              <CommonSwitch
                titlePosition="right"
                title={roleState.userType === OFFICE_PERMISSION ? T.ACTIVATE_OFFICE_LOGIN : T.ACTIVATE_ROAD_LOGIN}
                isChecked={roleState.userType === OFFICE_PERMISSION ? roleState.allowOfficeLogin : roleState.allowRoadLogin}
                onChange={checked =>
                  handleSwitchVal(roleState.userType === OFFICE_PERMISSION ? T.ACTIVATE_OFFICE_LOGIN : T.ACTIVATE_ROAD_LOGIN, checked)
                }
              />
            </Grid>
          )}
          {calledBy === '' && (
            <Grid item>
              <CommonSwitch
                titlePosition="right"
                title={T.ACTIVE}
                isChecked={roleState.active}
                onChange={checked => handleSwitchVal(T.ACTIVE, checked)}
              />
            </Grid>
          )}
        </Grid>

        {seePermissionSettings && (
          <Box
            sx={{
              p: calledBy === '' ? '0px 24px' : '',
              '& .MuiTabPanel-root': {
                p: 0,
              },
              '& .MuiTabs-flexContainer': {
                borderBottom: theme => `1px solid ${theme.palette.border.light}`,
              },
            }}
          >
            {roleState.userType === OFFICE_PERMISSION && (
              <TabPanel value={OFFICE_PERMISSION}>
                <Office roleState={roleState} setRoleState={setRoleState} readonly={readonly} calledBy={calledBy} />
              </TabPanel>
            )}

            {roleState.userType === ROAD_PERMISSION && (
              <TabPanel value={ROAD_PERMISSION}>
                <Road roleState={roleState} setRoleState={setRoleState} readonly={readonly} calledBy={calledBy} />
              </TabPanel>
            )}
          </Box>
        )}
      </TabContext>

      {roleState.footerFlag && calledBy === '' && (
        <Box
          px={3}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={handleCancel}
            rightButtonTitle={T.SAVE}
            onProceed={handleSave}
            disabledProceed={roleState.profileName === ''}
          />
        </Box>
      )}
    </Card>
  )
}

AddRoles.propTypes = {
  roleState: PropTypes.object,
  calledBy: PropTypes.string,
  readonly: PropTypes.string,
  setRoleState: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  onHandleNameChange: PropTypes.func,
}

export default AddRoles
