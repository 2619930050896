import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'utils/lodash'
import { canSeeInvoices, canSeePayments } from 'data/permissions/permissionsSelectors'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'

import TabMainLayout from '../layout/TabMainLayout'
import TabLeftLayout from '../layout/TabLeftLayout'
import TabRightLayout from '../layout/TabRightLayout'
import PaymentDetails from '../content/payment-details'
import BillingDetails from '../content/billing/BillingDetails'
import Invoices from '../tables/Invoices'
import Payments from '../tables/Payments'
import BillingLocationDetails from '../content/billing-location-details/BillingLocationDetails'
import BillingNotesAccordionVariant from '../../notes/billing/BillingNotesAccordionVariant'

const { BILLING_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const BillingTab = ({ selectedTab = '', customerDetails = {} }) => {
  const accountId = get(customerDetails, 'id')
  const [isBillingTabReady, setIsBillingTabReady] = useState(false)
  const seePayments = useSelector(canSeePayments, shallowEqual)
  const seeInvoices = useSelector(canSeeInvoices, shallowEqual)

  useEffect(() => {
    if (selectedTab === BILLING_TAB_ID) {
      setIsBillingTabReady(true)
    }
  }, [selectedTab])
  return (
    isBillingTabReady && (
      <TabMainLayout>
        <TabLeftLayout>
          <PaymentDetails accountId={accountId} />
          <Box mt={2}>
            <BillingDetails customerDetails={customerDetails} />
          </Box>
          <Box mt={2}>
            <BillingLocationDetails accountId={accountId} />
          </Box>
        </TabLeftLayout>
        <TabRightLayout>
          <Box>
            <BillingNotesAccordionVariant accountId={accountId} />
          </Box>
          {seePayments && (
            <Box mt={2}>
              <Payments accountId={accountId} customerDetails={customerDetails} showTakePaymentButton />
            </Box>
          )}
          {seeInvoices && (
            <Box mt={2}>
              <Invoices accountId={accountId} customerDetails={customerDetails} showCreateInvoiceButton showARData />
            </Box>
          )}
        </TabRightLayout>
      </TabMainLayout>
    )
  )
}

BillingTab.propTypes = {
  selectedTab: PropTypes.string,
  customerDetails: PropTypes.object,
}

export default BillingTab
