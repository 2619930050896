import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { Button, Stack, useTheme, DialogActions, Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { handleError } from 'utils/error'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import {
  setBulkResequenceStopsState,
  selectBulkResequenceRows,
  setConfirmPermanentMoveDialogState,
} from 'slices/route/bulkResequenceStopsSlice'

import T from 'T'
import api from 'api'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import Loader from 'components/common/loader'

const BulkUnassignStopsDialogActions = ({ onReset, getBEBatches, isPermanentMove, onClose, isPermanentLoading }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const { handleSubmit } = useFormContext()
  const rows = useSelector(selectBulkResequenceRows)
  const changedStops = rows.filter(row => row.isChanged)
  const changedStopsCount = changedStops.length
  const invalidStopsCount = changedStops.filter(row => row.position === '').length

  const isSaveButtonDisabled = invalidStopsCount > 0 || changedStopsCount === 0
  const submitValidTitle =
    changedStopsCount > 0 ? 'This does not affect future stops of these services' : 'This does not affect future stops of this service'
  const submitTitle = invalidStopsCount > 0 ? `${invalidStopsCount} stop left to assign` : submitValidTitle

  const onValidSubmit = () => {
    const batches = getBEBatches(false)
    bulkMoveStops({ moves: batches })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        onReset(false)
        dispatch(setBulkResequenceStopsState({ isOpen: false }))
      })
      .catch(handleError)
  }

  const handleSavePermanentClick = () => dispatch(setConfirmPermanentMoveDialogState({ isOpen: true }))

  return (
    <DialogActions sx={{ flex: '0 0 48px' }}>
      {(isPermanentLoading || isLoading) && <Loader />}
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1}>
        <Button size="small" variant="outlined" color="warning" onClick={onReset}>
          {T.RESET}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
        <CancelButton size="small" onClick={onClose} />
        <LoadingButton
          loading={isPermanentMove && (isLoading || isPermanentLoading)}
          disabled={isSaveButtonDisabled}
          variant="outlined"
          size="small"
          onClick={handleSavePermanentClick}
        >
          {T.SAVE_PERMANENT}
        </LoadingButton>
        <Tooltip PopperProps={{ sx: { zIndex: theme.zIndex.intercom + 1 } }} arrow title={submitTitle}>
          <span>
            <SaveButton
              size="small"
              loading={!isPermanentMove && isLoading}
              disabled={isSaveButtonDisabled}
              label={T.SAVE_CURRENT_DAY}
              onClick={handleSubmit(onValidSubmit)}
            />
          </span>
        </Tooltip>
      </Stack>
    </DialogActions>
  )
}

BulkUnassignStopsDialogActions.propTypes = {
  onReset: PropTypes.func.isRequired,
  getBEBatches: PropTypes.func.isRequired,
  isPermanentMove: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isPermanentLoading: PropTypes.bool,
}

export default memo(BulkUnassignStopsDialogActions)
