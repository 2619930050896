import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

const getStops = state => get(state, 'stops', [])
const getWorkOrderId = state => get(state, 'workOrderId', null)
const getRouteIdFromStop = state => get(state, 'routeId', null)

export const selectStops = createSelector([getStops], stops => stops)
export const selectWorkOrderId = createSelector([getWorkOrderId], workOrderId => workOrderId)
export const selectAllWorkOrderId = createSelector([selectStops], stops => stops.map(stop => stop.workOrderId))
export const selectAllWorkOrderStatus = createSelector([selectStops], stops => stops.map(stop => stop.workOrderStatus))
export const selectRouteIdFromStop = createSelector([getRouteIdFromStop], routeId => routeId)

export const selectStopsByStatusCounts = createSelector([getStops], stops => {
  return stops.reduce((acc, stop) => {
    const status = get(stop, 'workOrderStatus')
    acc[status] = (acc[status] || 0) + 1
    return acc
  }, {})
})
