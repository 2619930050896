// Default Pagination values

export const INVOICE_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10000,
  ROWS_PER_PAGE_OPTIONS: [1000, 2000, 5000, 10000],
}

export const SETTINGS_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 100,
  ROWS_PER_PAGE_OPTIONS: [50, 100, 500],
}

export const PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 25,
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50, 100],
}

export const PRICING_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 200,
  ROWS_PER_PAGE_OPTIONS: [50, 100, 200, 500, 1000],
}

// Default AR Pagination values
export const AR_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [5, 10, 20, 50],
}

// Default Notes Pagination values
export const NOTES_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 1000,
  ROWS_PER_PAGE_OPTIONS: [1000],
}

// Default User Search Pagination values
export const USER_SEARCH_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 6,
}

// Default Configured Services Pagination values
export const CONFIGURED_SERVICES_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [5, 10, 20, 50],
}

export const CUSTOMER_DETAILS_DEFAULT_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [10, 25, 50, 100],
}

// Default Work Orders Pagination values
export const WORK_ORDERS_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 10,
  ROWS_PER_PAGE_OPTIONS: [5, 10, 20, 50],
}

// Default Follow Up Pagination values
export const FOLLOW_UP_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 1000,
  ROWS_PER_PAGE_OPTIONS: [1000],
}

// Default Calls Pagination values
export const CALLS_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 500,
  ROWS_PER_PAGE_OPTIONS: [1000],
}

// Default Pricing Service Pagination values
export const PRICING_SERVICE_SEARCH_PAGINATION = {
  INITIAL_PAGE: 0,
  ROWS_PER_PAGE: 25,
  MAX_ROWS: 50,
}

export const SETTINGS_SKELTON_ROWS = 25
