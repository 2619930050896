export const actions = {
  GET: {
    ADDRESS_SUGGEST_LIST: 'ADDRESS_SUGGEST_GET_LIST',
    MAP_IMAGE: 'MAP_GET_IMAGE',
  },
}

export const getAddressSuggestList = (body, onSuccess) => ({
  type: actions.GET.ADDRESS_SUGGEST_LIST,
  payload: { body, onSuccess },
})

export const getMapImage = (body, onSuccess) => ({
  type: actions.GET.MAP_IMAGE,
  payload: { body, onSuccess },
})
