import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery, useTheme } from '@mui/material'

import { LEFT_LAYOUT_WIDTH, LAYOUT_HEIGHT } from './settings'

const TabLeftLayout = ({ children }) => {
  const theme = useTheme()
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box
      py={3}
      pr={isTabletorMobile ? 4 : 2}
      pl={4}
      borderRight={isTabletorMobile ? 0 : 1}
      borderColor="divider"
      minWidth={isTabletorMobile ? '100%' : LEFT_LAYOUT_WIDTH}
      maxWidth={isTabletorMobile ? '100%' : LEFT_LAYOUT_WIDTH}
      height={isTabletorMobile ? '100%' : LAYOUT_HEIGHT}
      overflow="auto"
    >
      {children}
    </Box>
  )
}

TabLeftLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabLeftLayout
