import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import PricingZoneSettings from 'components/settings/pages/zones/PricingZoneSettings'
import TaxZoneSettings from 'components/settings/pages/zones/TaxZoneSettings'
import CustomerZoneSettings from 'components/settings/pages/zones/CustomerZoneSettings'

import T from 'T'

const ZoneSettings = ({ zoneOption = '' }) => {
  return (
    <Box>
      {zoneOption === `${T.PRICING_ZONE}s` && <PricingZoneSettings />}
      {zoneOption === `${T.TAX_ZONE}s` && <TaxZoneSettings />}
      {zoneOption === `${T.CUSTOMER_ZONE}s` && <CustomerZoneSettings />}
    </Box>
  )
}

ZoneSettings.propTypes = {
  zoneOption: PropTypes.string,
}

export default ZoneSettings
