/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { addDays, isValid } from 'date-fns'
import { RECURRENCE, SERVICE_ACTION_TYPE_TO_BE_STR } from 'settings/constants/service'
import { deserializeDate, serializeDate } from 'utils/date'

const { ON_REQUEST } = RECURRENCE

const getInitialState = () => ({
  allRoutesFetched: false,
  serializedServiceDateRange: [serializeDate(new Date()), serializeDate(addDays(new Date(), 6))],
  isServiceDateRangeValid: true,
  selectionModel: [],
  filters: Object.values(SERVICE_ACTION_TYPE_TO_BE_STR),
  serviceTypeList: [ON_REQUEST],
  statusCountByRouteId: {},
  totalStatusCount: {},
  notReadyIds: [],
  parentRows: [],
  generalFeesDialog: {
    isOpen: false,
    woId: '',
    fees: [],
    routeId: '',
  },
  generalFeeList: [],
  generalFeesById: {},
  notReadyRowsSelectedCount: 0,
  disposalTicketsDialog: {
    isOpen: false,
    woId: '',
    routeId: '',
    configuredServiceId: '',
  },
  filtersDialog: {
    isOpen: false,
    applyFilters: false,
    formData: {},
  },
})

const getTotalStatusCount = statusCountByRouteId =>
  Object.values(statusCountByRouteId).reduce((result, row) => {
    const statusCounts = row.countByStatus
    Object.keys(statusCounts).forEach(status => {
      // eslint-disable-next-line no-param-reassign
      result[status] = (result[status] || 0) + statusCounts[status]
    })
    return result
  }, {})

const workOrdersReviewSlice = createSlice({
  name: 'workOrdersReview',
  initialState: getInitialState(),
  reducers: {
    setWOReviewServiceDateRange: (state, action) => {
      state.serializedServiceDateRange = action.payload
      const isValidDate = serializedDate => {
        const date = deserializeDate(serializedDate)
        return isValid(date)
      }
      state.isServiceDateRangeValid = Array.isArray(action.payload) && action.payload.every(isValidDate)
    },
    resetWOReviewState: state => {
      return { ...getInitialState(), routeIdList: state.routeIdList }
    },
    setWOReviewRowSelectionModel: (state, action) => {
      state.selectionModel = action.payload
    },
    setWOReviewFilters: (state, action) => {
      state.filters = action.payload
    },
    setWOReviewServiceTypeList: (state, action) => {
      state.serviceTypeList = action.payload
    },
    setWOReviewGeneralFeesDialog: (state, action) => {
      state.generalFeesDialog = action.payload
    },
    setWOReviewDisposalTicketsDialog: (state, action) => {
      state.disposalTicketsDialog = action.payload
    },
    setWOReviewStatusCountByRouteId: (state, action) => {
      state.statusCountByRouteId = action.payload
      state.totalStatusCount = getTotalStatusCount(action.payload)
    },
    setWOReviewParentRows: (state, action) => {
      state.parentRows = action.payload
    },
    setWOReviewNotReadyRowsSelectedCount: (state, action) => {
      state.notReadyRowsSelectedCount = action.payload
    },
    setWOReviewGeneralFeeList: (state, action) => {
      state.generalFeeList = action.payload
      const newFeesMapById = {}
      action.payload.forEach(item => {
        newFeesMapById[item.id] = item
      })
      state.generalFeesById = newFeesMapById
    },
    setWOReviewNotReadyIds: (state, action) => {
      state.notReadyIds = action.payload
    },
    setWOReviewAllRoutesFetched: (state, action) => {
      state.allRoutesFetched = action.payload
    },
    setWOReviewFiltersDialogIsOpen: (state, action) => {
      state.filtersDialog.isOpen = action.payload
    },
    setWOReviewApplyFilters: (state, action) => {
      state.filtersDialog.applyFilters = action.payload
    },
    setWOReviewFormData: (state, action) => {
      state.filtersDialog.formData = action.payload
    },
  },
})

export const {
  setWOReviewServiceDateRange,
  resetWOReviewState,
  setWOReviewRowSelectionModel,
  setWOReviewFilters,
  setWOReviewServiceTypeList,
  setWOReviewGeneralFeesDialog,
  setWOReviewDisposalTicketsDialog,
  setWOReviewStatusCountByRouteId,
  setWOReviewParentRows,
  setWOReviewNotReadyRowsSelectedCount,
  setWOReviewGeneralFeeList,
  setWOReviewNotReadyIds,
  setWOReviewAllRoutesFetched,
  setWOReviewFiltersDialogIsOpen,
  setWOReviewApplyFilters,
  setWOReviewFormData,
} = workOrdersReviewSlice.actions

export default workOrdersReviewSlice.reducer
