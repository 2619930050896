import { useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const useGetExceptionProps = props => {
  const theme = useTheme()

  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.between(300, 899))
  const isMd = useMediaQuery(theme.breakpoints.between(900, 1199))
  const isBetweenMdLg = useMediaQuery(theme.breakpoints.between(1200, 1499))
  const isLg = useMediaQuery(theme.breakpoints.between(1500, 1799))
  const isXl = useMediaQuery(theme.breakpoints.between(1800, 2399))
  const is2Xl = useMediaQuery(theme.breakpoints.up(2399))

  const getPageProps = useMemo(() => {
    if (isXs) return { height: 300, width: 300, scale: 1 }
    if (isSm) return { height: 250, width: 300, scale: 1 }
    if (isMd) return { height: 150, width: 200, scale: 1.6 }
    if (isBetweenMdLg) return { height: 300, width: 300, scale: 1 }
    if (isLg) return { height: 300, width: 300, scale: 1.2 }
    if (isXl) return { height: 300, width: 400, scale: 0.8 }
    if (is2Xl) return { height: 300, width: 500, scale: 0.7 }

    return { height: 300, width: 300, scale: 1 }
  }, [isXs, isSm, isMd, isLg, isXl])

  const getImageSx = useMemo(() => {
    return { maxHeight: 250, overflow: 'hidden' }
  }, [])

  return {
    ...props,
    PageProps: getPageProps,
    ImageSx: getImageSx,
  }
}

useGetExceptionProps.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default useGetExceptionProps
