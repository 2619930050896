import api from '../index'
import { CACHE_TAG_WORK_ORDER_DETAILS } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getWorkOrderDetails: builder.query({
      query: ({ workOrderId }) => {
        return { url: `/api/v1/core/work-orders/${workOrderId}` }
      },
      providesTags: [CACHE_TAG_WORK_ORDER_DETAILS],
    }),
  }),
})

export const { useGetWorkOrderDetailsQuery, useLazyGetWorkOrderDetailsQuery } = extendedApi
