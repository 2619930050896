import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    bulkAddTagsToAccounts: build.mutation({
      query: ({ accountGroupId, tags }) => ({
        url: `/api/v1/core/accounts/groups/${accountGroupId}:add-tags`,
        body: { tags },
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_GROUP_DETAILS],
    }),
  }),
})

export const { useBulkAddTagsToAccountsMutation } = extendedApi
