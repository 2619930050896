import React, { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import TagFilterMenuField from 'components/locations/filters/TagFilter/TagFilterMenuField'
import BillingProfileFilterMenuField from 'components/locations/filters/BillingProfileFilter/BillingProfileFilterMenuField'
import { IconButton, useMediaQuery, useTheme, Grid } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FiltersMenu from 'components/locations/filters/FiltersMenu'

const Filters = () => {
  const { control, watch, setValue } = useFormContext()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const searchTagsInput = watch('searchTagsInput')
  const searchBillingInput = watch('searchBillingInput')

  const onTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const onBillingSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchBillingInput', value)
    }
  }

  useEffect(() => {
    if (open && isDesktop) {
      handleClose()
    }
  }, [open, isDesktop])

  return (
    <>
      {isDesktop ? (
        <Grid container flexWrap="nowrap" justifyContent="flex-end">
          <Grid item xs="auto">
            <BillingProfileFilterMenuField
              name="selectedBillingProfiles"
              control={control}
              inputValue={searchBillingInput}
              onInputChange={onBillingSearchInputChange}
              dropdownHeight="calc(100vh - 300px)"
            />
          </Grid>
          <Grid item xs="auto">
            <TagFilterMenuField
              name="selectedTags"
              control={control}
              inputValue={searchTagsInput}
              onInputChange={onTagsSearchInputChange}
              dropdownHeight="calc(100vh - 288px)"
            />
          </Grid>
        </Grid>
      ) : (
        <IconButton endIcon={open ? <ExpandLess /> : <ExpandMore />} onClick={handleClick}>
          <FilterListIcon color="action" />
        </IconButton>
      )}
      <FiltersMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  )
}

export default Filters
