import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { IconButton, useTheme } from '@mui/material'
import MuiReorderIcon from '@mui/icons-material/Reorder'

const ReorderIcon = ({ onClick = noop, sx = {} }) => {
  const theme = useTheme()

  return (
    <IconButton onClick={onClick} sx={{ zIndex: theme.zIndex.drawer + 1, ...sx }}>
      <MuiReorderIcon />
    </IconButton>
  )
}

ReorderIcon.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.object,
}

export default ReorderIcon
