import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, SvgIcon, Typography, Divider, IconButton, styled, Stack } from '@mui/material'
import { ReactComponent as MultiplicationSign } from 'assets/MultiplicationSign.svg'
import { ReactComponent as EqualSign } from 'assets/EqualSign.svg'
import { XCircle as UnstyledXCircle } from '@styled-icons/heroicons-solid/XCircle'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import { HHFormNumberField, HHFormPriceField, HHFormSelectField } from '../../../form-fields/v5'
import HHDisplayMoney from '../../../common/HHDisplayMoney'

const XCircle = styled(UnstyledXCircle)({
  height: '20px',
  width: '20px',
})

const GeneralFeeFormRow = ({ index, generalFeeList, watch, onFeeChange, onRemove }) => {
  const { control } = useFormContext()
  const getFeeOptionLabel = ({ feeName }) => feeName
  const getFeeOptionValue = ({ id }) => id

  const charge = watch(`fees.${index}.feeCharge`)
  const qty = watch(`fees.${index}.quantity`)
  const total = (charge * qty).toFixed(2)
  const handleFeeChange = id => {
    onFeeChange({ feeId: id, index })
  }

  const handleClick = () => {
    onRemove(index)
  }

  return (
    <>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="h4" fontWeight={600}>{`${T.FEE} #${index + 1}`}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={handleClick}>
            <SvgIcon>
              <XCircle />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HHFormSelectField
          size="small"
          options={generalFeeList}
          control={control}
          name={`fees.${index}.feeTaxId`}
          fullWidth
          onChange={handleFeeChange}
          getOptionValue={getFeeOptionValue}
          getOptionLabel={getFeeOptionLabel}
        />
      </Grid>
      {/* Row */}
      <Grid container item xs={12} wrap="nowrap" columnSpacing={1}>
        <Grid item>
          <HHFormNumberField
            sx={{ width: '6rem' }}
            deprecatedLabel={false}
            label={T.QTY}
            size="small"
            control={control}
            name={`fees.${index}.quantity`}
          />
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
              <MultiplicationSign />
            </SvgIcon>
          </Box>
        </Grid>
        <Grid item xs>
          <HHFormPriceField
            fullWidth
            deprecatedLabel={false}
            label={T.COST}
            size="small"
            control={control}
            name={`fees.${index}.feeCharge`}
          />
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
              <EqualSign />
            </SvgIcon>
          </Box>
        </Grid>
        <Grid item>
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h4" align="right" fontWeight={600}>
              <HHDisplayMoney value={total} formatToDollars={false} />
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Divider />
      </Grid>
    </>
  )
}

GeneralFeeFormRow.propTypes = {
  index: PropTypes.number,
  generalFeeList: PropTypes.array,
  onFeeChange: PropTypes.func,
  onRemove: PropTypes.func,
}

export default GeneralFeeFormRow
