import React from 'react'
import { Box } from '@mui/material'
import Header from 'components/header'
import SecondaryNavbar from 'components/billing/payments/SecondaryNavbar'
import PaymentsTopSection from 'components/billing/payments/PaymentsTopSection'
import PaymentsDataGrid from 'components/billing/payments/PaymentsDataGrid'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { CommonTableFilters } from '../../../components/common'
import { filterMapToQueryParams } from '../../../utils/LHSbracket'
import { handleFilterInputChange, handlePrefillResponseModification } from '../../../utils/table'
import { PAYMENT_CONFIGURATOR } from '../settings'
import {
  setPaymentsFilters,
  setPaymentsPage,
  setPaymentsRefetchFlag,
  setPaymentReceiptDialogState,
  setPaymentReceiptEmailConfirmationState,
  setAddUpdatePaymentNoteState,
} from '../../../slices/billing/paymentsSlice'
import BillingSideNav from '../../../components/billing/common/side-nav/BillingSideNav'
import BillingLayout from '../../../components/billing/common/BillingLayout'
import PaymentReceiptDialog from '../../../components/billing/payments/PaymentReceiptDialog'
import PaymentReceiptEmailConfirmation from '../../../components/billing/common/payment-receipt-email-confirmation'
import AddUpdatePaymentNoteDialog from '../../../components/billing/common/AddUpdatePaymentNoteDialog'

const Payments = () => {
  const [_, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const isOpenTableFilters = useSelector(state => state.payments.filters.isOpenTableFilters)
  const allFilters = useSelector(state => state.payments.filters.allFilters, shallowEqual)
  const activeFilter = useSelector(state => state.payments.filters.activeFilter)
  const selectedFilterId = useSelector(state => state.payments.filters.selectedFilterId)
  const existingFilterList = useSelector(state => state.payments.savedView.existingFilterList)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)
  const paymentReceiptDialog = useSelector(state => state.payments.paymentReceiptDialog, shallowEqual)
  const paymentReceiptEmailConfirmation = useSelector(state => state.payments.paymentReceiptEmailConfirmation, shallowEqual)
  const addUpdatePaymentNote = useSelector(state => get(state, 'payments.addUpdatePaymentNote', {}), shallowEqual)
  const { accountId, accountName, paymentNumber, accountTransactionHistoryId } = get(paymentReceiptDialog, 'payment', {})

  const handleFilterApply = () => {
    const queryParams = filterMapToQueryParams(allFilters)
    setSearchParams({ ...queryParams })
    dispatch(setPaymentsPage(0))
    dispatch(setPaymentsRefetchFlag(true))
  }

  const handleFilterChange = (key, value, columnName) => {
    const newFiltersState = handleFilterInputChange(key, value, columnName, allFilters)
    dispatch(
      setPaymentsFilters({
        ...newFiltersState,
        refetchFlag: false,
      })
    )
  }

  const handleFilterClose = () => {
    let newAllFilters = allFilters
    if (selectedFilterId === '') {
      newAllFilters = omit(allFilters, activeFilter)
    } else {
      const currentFilter = existingFilterList.find(filterListEl => selectedFilterId === filterListEl.searchFilterId)
      const filteredRecord = get(currentFilter, 'searchFilters', []).find(
        filterListEl => filterListEl.columnName === allFilters[activeFilter].columnName
      )

      if (!filteredRecord) {
        newAllFilters = omit(allFilters, activeFilter)
      } else {
        const initialFilter = PAYMENT_CONFIGURATOR.find(conf => conf.columnName === allFilters[activeFilter].columnName)
        newAllFilters[activeFilter] = handlePrefillResponseModification(initialFilter, filteredRecord)
      }
    }
    const queryParams = filterMapToQueryParams(newAllFilters)
    setSearchParams({ ...queryParams })
    dispatch(setPaymentsPage(0))
    dispatch(setPaymentsFilters({ allFilters: newAllFilters, isOpenTableFilters: false, refetchFlag: true }))
  }

  const handleClosePaymentReceiptDrawer = () =>
    dispatch(
      setPaymentReceiptDialogState({
        isOpen: false,
        payment: { accountId: '', accountName: '', paymentNumber: '', accountTransactionHistoryId: '' },
      })
    )

  const handleClosePaymentReceiptEmailConfirmation = () =>
    dispatch(setPaymentReceiptEmailConfirmationState({ isOpen: false, payments: [] }))

  const handleCloseAddUpdatePaymentNote = () =>
    dispatch(setAddUpdatePaymentNoteState({ isOpen: false, accountId: '', paymentNumber: '', paymentNote: '' }))

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100vw">
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <Header />
        <SecondaryNavbar />
        <Box overflow="auto" flex={1} display="flex" flexDirection="column" backgroundColor="background.paper">
          <PaymentsTopSection />
          <PaymentsDataGrid />
        </Box>
      </BillingLayout>
      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        label={get(allFilters, `${activeFilter}.label`)}
        isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
        isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
        filterComponent="billing"
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={handleFilterClose}
      />
      <PaymentReceiptDialog
        open={paymentReceiptDialog?.isOpen}
        accountId={accountId}
        accountName={accountName}
        paymentNumber={paymentNumber}
        accountTransactionHistoryId={accountTransactionHistoryId}
        onClose={handleClosePaymentReceiptDrawer}
      />
      {paymentReceiptEmailConfirmation?.isOpen && (
        <PaymentReceiptEmailConfirmation
          isOpen={paymentReceiptEmailConfirmation.isOpen}
          payments={get(paymentReceiptEmailConfirmation, 'payments', [])}
          onClose={handleClosePaymentReceiptEmailConfirmation}
        />
      )}
      <AddUpdatePaymentNoteDialog
        isOpen={addUpdatePaymentNote.isOpen}
        accountId={addUpdatePaymentNote.accountId}
        paymentNumber={addUpdatePaymentNote.paymentNumber}
        existingNote={addUpdatePaymentNote.paymentNote}
        onClose={handleCloseAddUpdatePaymentNote}
      />
    </Box>
  )
}

export default Payments
