import { takeEvery, all, call } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions } from '../actions/settings'

import endpoint from './endpoint'

export const getTagsListResults = ({ body }) => doPost({ url: endpoint.settings.tag.list, body, showLoader: true })
export const getTagsAddResults = ({ body }) => doPost({ url: endpoint.settings.tag.add, body, showLoader: true })

export function* getTagsListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getTagsListResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getTagsAddSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getTagsAddResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess([])
  }
}

export default function* FollowUp() {
  yield all([takeEvery(actions.GET.LIST_TAGS, getTagsListSaga), takeEvery(actions.ADD_TAGS, getTagsAddSaga)])
}
