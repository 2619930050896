/* eslint-disable import/no-cycle */
import T from 'T'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import {
  dateTimeValueFormatter,
  dateValueFormatter,
  moneyValueFormatter,
  paymentMethodFormatter,
  transactionStatusFormatter,
} from 'components/data_grid/formatters'
import RenderGetPaymentsActionsDataCell from 'components/data_grid/render_cell/RenderGetPaymentsActionsDataCell'
import RenderHeaderCell from 'components/data_grid/render_cell/RenderHeaderCell'
import { RenderAccountLinkCell, RenderPriceCell, RenderTagsCell } from 'components/data_grid/render_cell'

const {
  CUSTOMER,
  PAYMENT_ID,
  BATCH_NUMBER,
  METHOD,
  TRANSACTION_DATE,
  AMOUNT,
  CHECK,
  STATUS,
  POSTED_DATE,
  NOTE,
  CUSTOMER_ADDRESS,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP,
  ACCOUNT,
  CUSTOMER_BILLING_PROFILE,
  TAGS,
} = T

export const PAYMENT_CONFIGURATOR = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 50,
    resizable: false,
  },
  {
    minWidth: 120,
    flex: 1,
    headerName: `${ACCOUNT} #`,
    tableLabel: `${ACCOUNT} #`,
    label: `${ACCOUNT} #`,
    filterType: 'string',
    field: 'accountNumber',
    columnName: 'accountNumber',
    renderHeader: RenderHeaderCell,
    renderCell: RenderAccountLinkCell,
  },
  {
    flex: 1,
    minWidth: 235,
    headerName: CUSTOMER,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    filterType: 'string',
    field: 'customerName',
    columnName: 'customerName',
    renderHeader: RenderHeaderCell,
    renderCell: RenderAccountLinkCell,
  },
  {
    flex: 1,
    minWidth: 130,
    headerName: PAYMENT_ID,
    tableLabel: PAYMENT_ID,
    label: PAYMENT_ID,
    filterType: 'number',
    type: 'string',
    field: 'paymentNumber',
    columnName: 'paymentNumber',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 200,
    headerName: BATCH_NUMBER,
    tableLabel: BATCH_NUMBER,
    label: BATCH_NUMBER,
    filterType: 'string',
    field: 'batchId',
    columnName: 'batchId',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: METHOD,
    tableLabel: METHOD,
    label: METHOD,
    filterType: 'checkbox',
    field: 'paymentMethod',
    columnName: 'paymentMethod',
    subType: 'paymentMethod',
    renderHeader: RenderHeaderCell,
    valueFormatter: paymentMethodFormatter,
  },
  {
    flex: 1,
    minWidth: 200,
    headerName: TRANSACTION_DATE,
    tableLabel: TRANSACTION_DATE,
    label: TRANSACTION_DATE,
    filterType: 'date',
    field: 'transactionPostedDate',
    columnName: 'transactionPostedDate',
    subType: 'dateTime',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateTimeValueFormatter,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: AMOUNT,
    tableLabel: AMOUNT,
    label: AMOUNT,
    filterType: 'number',
    type: 'number',
    field: 'transactionAmountCents',
    columnName: 'transactionAmountCents',
    subType: 'money',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: `${CHECK} #`,
    tableLabel: `${CHECK} #`,
    label: `${CHECK} #`,
    filterType: 'string',
    type: 'string',
    field: 'checkNum',
    columnName: 'checkNum',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 130,
    headerName: 'Invoice #',
    tableLabel: 'Invoice #',
    label: 'Invoice #',
    filterType: 'number',
    field: 'paymentTarget',
    columnName: 'paymentTarget',
    subType: 'paymentTarget',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 120,
    headerName: STATUS,
    tableLabel: STATUS,
    label: STATUS,
    filterType: 'checkbox',
    field: 'transactionStatus',
    columnName: 'transactionStatus',
    subType: 'transactionStatus',
    renderHeader: RenderHeaderCell,
    valueFormatter: transactionStatusFormatter,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: POSTED_DATE,
    tableLabel: POSTED_DATE,
    label: POSTED_DATE,
    filterType: 'date',
    type: 'date',
    field: 'paymentDate',
    columnName: 'paymentDate',
    subType: 'date',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateValueFormatter,
  },
  {
    flex: 1,
    headerName: NOTE,
    tableLabel: NOTE,
    label: NOTE,
    filterType: 'string',
    field: 'paymentNote',
    columnName: 'paymentNote',
    subType: 'note',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: CUSTOMER_ADDRESS,
    tableLabel: CUSTOMER_ADDRESS,
    label: CUSTOMER_ADDRESS,
    filterType: 'string',
    columnName: 'customerAddress',
    field: 'customerAddress',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: CUSTOMER_CITY,
    tableLabel: CUSTOMER_CITY,
    label: CUSTOMER_CITY,
    filterType: 'string',
    field: 'customerCity',
    columnName: 'customerCity',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: CUSTOMER_STATE,
    tableLabel: CUSTOMER_STATE,
    label: CUSTOMER_STATE,
    filterType: 'string',
    field: 'customerState',
    columnName: 'customerState',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: CUSTOMER_ZIP,
    tableLabel: CUSTOMER_ZIP,
    label: CUSTOMER_ZIP,
    filterType: 'number',
    field: 'customerZipCode',
    columnName: 'customerZipCode',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: CUSTOMER_BILLING_PROFILE,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    filterType: 'meta',
    field: 'customerProfileName',
    columnName: 'customerProfileName',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 250,
    sortable: false,
    headerName: TAGS,
    tableLabel: TAGS,
    label: TAGS,
    filterType: 'string',
    field: 'tags',
    columnName: 'tags',
    subType: 'tags',
    renderCell: RenderTagsCell,
    renderHeader: RenderHeaderCell,
  },
  { field: 'actions', type: 'actions', align: 'left', width: 90, resizable: false, getActions: RenderGetPaymentsActionsDataCell },
]
