import axios from 'axios'

import Constants from 'Constants'

const { SERVER_URL, SERVER_PUBLIC_URL } = Constants

const APIInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    // Todo: add token here
  },
})

export const APIInstancePublic = axios.create({
  baseURL: SERVER_PUBLIC_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default APIInstance
