import { PROVIDERS } from 'settings/constants/auth'
import { get } from './lodash'

const { GOOGLE, FACEBOOK, MICROSOFT } = PROVIDERS

export const getFirstNameAndLastName = (displayName = '', providerId, profile) => {
  if (providerId === GOOGLE) {
    return { firstName: get(profile, 'given_name', ''), lastName: get(profile, 'family_name', '') }
  }

  if (providerId === FACEBOOK) {
    return { firstName: get(profile, 'firstName', ''), lastName: get(profile, 'lastName', '') }
  }

  if (providerId === MICROSOFT) {
    return { firstName: get(profile, 'givenName', ''), lastName: get(profile, 'surname', '') }
  }

  const [first, ...last] = displayName.split(' ')
  return { firstName: first, lastName: last.join(' ') }
}
