import { useCallback, useMemo } from 'react'
import { filter, get, isString } from 'lodash'
import {
  ALPHABET_LIST,
  ALPHABET_WITH_DIGITS_LIST,
  getSortKeyWithDigits,
  getSortKeyWithoutDigits,
  hasIntersection,
  LOCATIONS_DESKTOP_ROW_HEIGHT,
  LOCATIONS_MOBILE_ROW_HEIGHT,
  SORT_BY_OPTIONS,
  SORT_BY_STREET_NAME_CRITERIA,
  PAGE_TYPE,
} from 'components/locations/settings'
import { useMediaQuery, useTheme } from '@mui/material'
import { checkIsManualSearch, getManualSearchParamName } from 'components/customers/accounts/search/settings'
import { showDeactivatedRow } from 'components/customers/accounts/settings'

const useLocationListUtilities = ({ data, characterToIndexMap, apiRef, sortedRows, watch, setValue }) => {
  const sortByValue = watch('sortBy')
  const selectedTags = watch('selectedTags')
  const expandedRowIds = watch('expandedRowIds')
  const withDeactivated = watch('withDeactivated')
  const withDigits = watch('withDigits')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const sortBySelectedOption = useMemo(() => SORT_BY_OPTIONS.find(option => option.value === sortByValue), [sortByValue])
  const isListAscending = useMemo(() => get(sortBySelectedOption, 'ascending', true), [sortBySelectedOption])
  const sortByCriteria = useMemo(() => get(sortBySelectedOption, 'criteria'), [sortBySelectedOption])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const searchType = watch('searchType')
  const searchValue = watch('searchValue')
  const isManualSearch = useMemo(() => checkIsManualSearch(searchType), [searchType])
  const rows = useMemo(() => (Array.isArray(data) ? data : []), [data])

  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const handleCharacterButtonClick = useCallback(
    character => {
      const targetIndex = get(characterToIndexMap, character, false)
      if (targetIndex === false) return
      apiRef?.current?.scrollToIndexes({ rowIndex: targetIndex })
      const rowHeight = isTabletOrMobile ? LOCATIONS_MOBILE_ROW_HEIGHT : LOCATIONS_DESKTOP_ROW_HEIGHT
      setValue('expandedRowIds', [])
      apiRef?.current?.scroll({ top: targetIndex * rowHeight })
    },
    [characterToIndexMap, isTabletOrMobile, expandedRowIds]
  )

  const shouldDisableCharacterButton = useCallback(
    character => {
      const targetIndex = get(characterToIndexMap, character)
      return typeof targetIndex !== 'number'
    },
    [characterToIndexMap]
  )

  const createAlphabetToRowIndexMap = useCallback(() => {
    const alphabetList = withDigits ? ALPHABET_WITH_DIGITS_LIST : ALPHABET_LIST
    const dictionary = {}
    const indexMap = new Map()
    sortedRows.forEach((row, index) => {
      const addressStr = get(row, 'addressStr', '')
      const firstChar = addressStr.charAt(0).toLowerCase()
      if (alphabetList.includes(firstChar) && !indexMap.has(firstChar)) {
        indexMap.set(firstChar, index)
      }
    })
    indexMap.forEach((value, key) => {
      dictionary[key] = value
    })
    alphabetList.forEach(letter => {
      if (!indexMap.has(letter)) {
        dictionary[letter] = false
      }
    })

    return dictionary
  }, [sortedRows, withDigits, sortByValue])

  const locationsSortComparator = useCallback(
    (a, b) => {
      const addressStrA = get(a, sortByCriteria === SORT_BY_STREET_NAME_CRITERIA ? 'streetName' : 'addressStr', '')
      const addressStrB = get(b, sortByCriteria === SORT_BY_STREET_NAME_CRITERIA ? 'streetName' : 'addressStr', '')
      const firstCharA = addressStrA.charAt(0).toLowerCase()
      const firstCharB = addressStrB.charAt(0).toLowerCase()

      const sortKeyA = withDigits ? getSortKeyWithDigits(firstCharA) : getSortKeyWithoutDigits(firstCharA)
      const sortKeyB = withDigits ? getSortKeyWithDigits(firstCharB) : getSortKeyWithoutDigits(firstCharB)

      if (sortKeyA < sortKeyB) return isListAscending ? -1 : 1
      if (sortKeyA > sortKeyB) return isListAscending ? 1 : -1
      return 0
    },
    [withDigits, isListAscending, sortByCriteria]
  )

  const sortRows = useCallback(() => {
    const selectedTagIdsSet = new Set(selectedTagsIds)
    const selectedBillingProfilesIdsSet = new Set(selectedBillingProfilesIds)
    const filteredRows = filter(rows, row => {
      if (!showDeactivatedRow(withDeactivated, row, PAGE_TYPE)) return false
      const paramName = getManualSearchParamName(searchType)
      const value = get(row, paramName, '').toLowerCase()
      if (isManualSearch && isString(searchValue) && !value.includes(searchValue.toLowerCase())) return false

      const billingProfileId = get(row, 'billingProfileId')
      const tagIds = get(row, 'tagIds', [])
      return hasIntersection(selectedTagIdsSet, tagIds) && (selectedBillingProfilesIdsSet.has(billingProfileId) || !billingProfileId)
    })
    return filteredRows.sort(locationsSortComparator)
  }, [locationsSortComparator, rows, selectedTagsIds, withDeactivated, selectedBillingProfilesIds, isManualSearch, searchType, searchValue])

  const filterCharacter = useCallback(
    character => {
      if (/\d/.test(character)) {
        return characterToIndexMap[character] !== false
      }

      return true
    },
    [characterToIndexMap]
  )

  return { handleCharacterButtonClick, shouldDisableCharacterButton, createAlphabetToRowIndexMap, sortRows, filterCharacter }
}

export default useLocationListUtilities
