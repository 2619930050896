import { useCallback, useContext } from 'react'
import { get } from 'lodash'
import { setBulkMoveBetweenDaysState } from 'slices/route/bulkMoveBetweenDaysSlice'
import {
  BULK_MOVE_MAP_LAYERS,
  insertAndResequenceRows,
  preProcessMoveDataOnSequenceChange,
  reorderMoveToData,
  ROW_HEIGHT,
  toggleMoveBetweenRoutesLayers,
  UNASSIGNED_MARKER_PROPS,
} from 'components/route-manager/BulkActions/settings'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { MapContext } from 'providers/MapProvider'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'

const { TO_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const useBulkResequenceFunctionsBetweenDaysVariant = ({
  fromSerializedDate,
  toSerializedDate,
  moveToRows,
  moveFromRows,
  moveFromRouteStopMarkers,
  moveToRouteSequenceLine,
  moveFromGridApiRef,
  moveToGridApiRef,
  moveToRouteStopMarkers,
  reset,
  bulkReduxAction,
}) => {
  const map = useContext(MapContext)
  const dispatch = useDispatch()
  const { getValues, setValue } = useFormContext()
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const insertArrayAtPosition = (array, insertArray, targetIndex) => {
    return [...array.slice(0, targetIndex), ...insertArray, ...array.slice(targetIndex)]
  }

  const getTargetIndex = useCallback(
    toPosition => {
      if (!toPosition) return 0
      const values = getValues()
      const toInsertCount = moveToRows.filter(item => item.date === fromFEDate).filter(({ id }) => !get(values, id, '')).length
      let targetPosition = Math.max(1, toPosition)
      targetPosition = Math.min(targetPosition, moveToRows.length + 1)
      const targetIndex = targetPosition - 1
      return targetIndex + toInsertCount
    },
    [moveToRows, fromSerializedDate]
  )

  const changeLayer = useCallback(
    newCurrentLayer => {
      const mapCurrentRef = map?.current
      if (mapCurrentRef && newCurrentLayer !== null) {
        dispatch(
          setBulkMoveBetweenDaysState({
            mapLayer: newCurrentLayer,
          })
        )
        toggleMoveBetweenRoutesLayers(mapCurrentRef, newCurrentLayer, fromFEDate, toFEDate)
      }
    },
    [fromSerializedDate, toSerializedDate, map]
  )

  const parseToBeInsertedDaysOfWeek = useCallback(
    daysOfWeek => {
      const dayAbbreviations = ['S', 'M', 'T', 'W', 'R', 'F', 'U']
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

      return dayAbbreviations.map((abbr, index) => {
        let variant = 'default'
        if (daysOfWeek.includes(abbr)) {
          variant = 'active'
        }
        if (toDate.getDay() === index) {
          variant = 'warning'
        }
        if (fromDate.getDay() === index) {
          variant = 'primary'
        }
        return {
          day: dayNames[index],
          variant,
        }
      })
    },
    [fromSerializedDate, toSerializedDate]
  )

  const onPreserveSequence = useCallback(
    data => {
      const startingSequence = get(data, 'startingSequence', '')
      const selectedRowIdsMap = moveFromGridApiRef.current.getSelectedRows()
      const {
        moveToCoords,
        selectedMoveFromRows: rawSelectedMoveFromRows,
        selectedMoveFromMarkers,
        selectedMoveFromCoords,
        updatedMoveFromRows,
        updatedMoveFromRouteStopMarkers,
        updatedMoveFromRouteSequenceLine,
      } = preProcessMoveDataOnSequenceChange({
        moveFromRouteStopMarkers,
        moveFromRows,
        moveToRouteSequenceLine,
        selectedRowIdsMap,
      })
      const selectedMoveFromRows = rawSelectedMoveFromRows.map(r => {
        const daysOfWeek = get(r, 'daysOfWeek')
        const parsedDaysOfWeek = parseToBeInsertedDaysOfWeek(daysOfWeek)
        return { ...r, parsedDaysOfWeek }
      })
      const values = getValues()
      const targetIndex = getTargetIndex(startingSequence)

      const { reorderedMoveToRows, reorderedMoveToRouteStopMarkers, reorderedMoveToRouteSequenceLine } = reorderMoveToData(
        moveToRows,
        moveToRouteStopMarkers,
        moveToCoords,
        selectedMoveFromRows,
        selectedMoveFromMarkers,
        targetIndex,
        insertArrayAtPosition,
        selectedMoveFromCoords
      )
      const getIsMoveToRow = row => row.date === toFEDate
      const { updatedMoveToRows, updatedMoveToMarkers, toBeInsertedRowsIds } = insertAndResequenceRows(
        reorderedMoveToRows,
        reorderedMoveToRouteStopMarkers,
        values,
        selectedRowIdsMap,
        setValue,
        getIsMoveToRow
      )
      dispatch(
        bulkReduxAction({
          moveFromRows: updatedMoveFromRows,
          moveFromRouteStopMarkers: updatedMoveFromRouteStopMarkers,
          moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
          moveToRows: updatedMoveToRows,
          moveToRouteStopMarkers: updatedMoveToMarkers,
          moveToRouteSequenceLine: reorderedMoveToRouteSequenceLine,
          isSequenceDialogOpen: false,
          toBeInsertedRowsIds,
        })
      )
      scrollToSelectedRowInDataGrid(moveToGridApiRef, targetIndex, ROW_HEIGHT)
      changeLayer(TO_ROUTE_LAYER)
      reset({ startingSequence: '' })
      moveFromGridApiRef.current.selectRows([])
    },
    [map, toSerializedDate, moveFromRows, moveFromRouteStopMarkers, moveToRouteSequenceLine, moveToRows, moveToRouteStopMarkers]
  )

  const onResetSequence = useCallback(() => {
    const selectedRowIdsMap = moveFromGridApiRef.current.getSelectedRows()
    const {
      moveToCoords,
      selectedMoveFromRows: rawSelectedMoveFromRows,
      selectedMoveFromMarkers,
      selectedMoveFromCoords,
      updatedMoveFromRows,
      updatedMoveFromRouteStopMarkers,
      updatedMoveFromRouteSequenceLine,
    } = preProcessMoveDataOnSequenceChange({
      moveFromRouteStopMarkers,
      moveFromRows,
      moveToRouteSequenceLine,
      selectedRowIdsMap,
    })
    const selectedMoveFromRows = rawSelectedMoveFromRows.map(r => {
      const daysOfWeek = get(r, 'daysOfWeek')
      const parsedDaysOfWeek = parseToBeInsertedDaysOfWeek(daysOfWeek)
      return { ...r, parsedDaysOfWeek }
    })
    const updatedMoveToRows = [...selectedMoveFromRows, ...moveToRows]
    const toBeInsertedRowsIds = updatedMoveToRows.filter(({ date }) => fromFEDate === date).map(({ id }) => id)
    toBeInsertedRowsIds.forEach(id => {
      if (selectedRowIdsMap.has(id)) {
        setValue(id, '')
      }
    })
    const updatedMoveToRouteStopMarkers = [
      ...selectedMoveFromMarkers.map(m => {
        const props = get(m, 'properties', {})
        return {
          ...m,
          properties: {
            ...props,
            ...UNASSIGNED_MARKER_PROPS,
            position: '',
          },
        }
      }),
      ...moveToRouteStopMarkers,
    ]
    const updatedMoveToRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: [...selectedMoveFromCoords, ...moveToCoords] },
    }
    dispatch(
      bulkReduxAction({
        moveFromRows: updatedMoveFromRows,
        moveFromRouteStopMarkers: updatedMoveFromRouteStopMarkers,
        moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
        moveToRows: updatedMoveToRows,
        moveToRouteStopMarkers: updatedMoveToRouteStopMarkers,
        moveToRouteSequenceLine: updatedMoveToRouteSequenceLine,
        isSequenceDialogOpen: false,
        toBeInsertedRowsIds,
      })
    )
    changeLayer(TO_ROUTE_LAYER)
    scrollToSelectedRowInDataGrid(moveToGridApiRef, 0, ROW_HEIGHT)
    reset({ startingSequence: '' })
    moveFromGridApiRef.current.selectRows([])
  }, [map, moveFromRows, moveFromRouteStopMarkers, moveToRouteSequenceLine, moveToRows, moveToRouteStopMarkers])

  return {
    onPreserveSequence,
    onResetSequence,
  }
}

export default useBulkResequenceFunctionsBetweenDaysVariant
