import api from 'api'
import {
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_VACATION_HOLD_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
} from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getVacationHoldList: builder.query({
      query: ({ accountId, deleted = false }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds?deleted=${deleted}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [CACHE_TAG_VACATION_HOLD_LIST, { type: CACHE_TAG_VACATION_HOLD_LIST, id }],
    }),
    getVacationHoldDetails: builder.query({
      query: ({ accountId, vacationHoldId }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds/${vacationHoldId}`,
        method: 'GET',
      }),
    }),
    deleteVacationHold: builder.mutation({
      query: ({ accountId, vacationHoldId }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds/${vacationHoldId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { vacationHoldId: id }) => [
        CACHE_TAG_VACATION_HOLD_LIST,
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        { type: CACHE_TAG_VACATION_HOLD_LIST, id },
      ],
    }),
    createVacationHold: builder.mutation({
      query: ({ accountId, data }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { vacationHoldId: id }) => [
        CACHE_TAG_VACATION_HOLD_LIST,
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        { type: CACHE_TAG_VACATION_HOLD_LIST, id },
      ],
    }),
    updateVacationHold: builder.mutation({
      query: ({ accountId, data, vacationHoldId }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds/${vacationHoldId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { vacationHoldId: id }) => [
        CACHE_TAG_VACATION_HOLD_LIST,
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_WORK_ORDER_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        { type: CACHE_TAG_VACATION_HOLD_LIST, id },
      ],
    }),
    restoreVacationHold: builder.mutation({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/vacation-holds/restore`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { vacationHoldId: id }) => [
        CACHE_TAG_VACATION_HOLD_LIST,
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        { type: CACHE_TAG_VACATION_HOLD_LIST, id },
      ],
    }),
  }),
})

export const {
  useLazyGetVacationHoldListQuery,
  useLazyGetVacationHoldDetailsQuery,
  useDeleteVacationHoldMutation,
  useCreateVacationHoldMutation,
  useUpdateVacationHoldMutation,
  useRestoreVacationHoldMutation,
} = extendedApi
