import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography, Divider, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { selectAddressName, selectLatitude, selectLongitude } from 'data/address/addressSelectors'

import T from 'T'

import LocationNotesAccordionVariant from 'components/notes/location/LocationNotesAccordionVariant'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import ContactsListing from '../contacts/ContactsListing'
import LocationContent from './LocationContent'
import LocationMapImage from './LocationMapImage'

const LEFT_SECTION_WIDTH = 340
const IMAGE_WIDTH_HORIZONTAL = 280
const IMAGE_WIDTH_VERTICAL = 399
const IMAGE_HEIGHT = 250
const HORIZONTAL = 'horizontal'
const VERTICAL = 'vertical'

const LocationExpandView = ({
  caller,
  selectedLocation,
  orientation,
  onSaveTags,
  accountId,
  dynamicWidth = false,
  showNotes = false,
  showContacts = true,
}) => {
  const [contactHeight, setContactHeight] = useState(IMAGE_HEIGHT)
  const selectedLocationId = get(selectedLocation, 'id')
  const locationAddress = get(selectedLocation, 'address', {})
  const contacts = get(selectedLocation, 'contacts', [])
  const tags = get(selectedLocation, 'tags', [])
  const addressName = selectAddressName(locationAddress)
  const longitude = selectLongitude(locationAddress)
  const latitude = selectLatitude(locationAddress)
  const locationContentHeight = document.querySelector('.location-content')?.clientHeight
  const isVertical = orientation === VERTICAL
  const isHorizontal = orientation === HORIZONTAL

  useEffect(() => {
    if (locationContentHeight) {
      setContactHeight(locationContentHeight)
    }
  }, [locationContentHeight])

  return (
    <Grid container direction={isVertical ? 'column' : 'row'} flexWrap="nowrap">
      <Grid
        container
        item
        columnSpacing={isVertical ? 0 : 2}
        rowSpacing={isVertical ? 2 : 0}
        direction={isVertical ? 'column' : 'row'}
        flexWrap="nowrap"
      >
        <Grid item xs="auto">
          <LocationMapImage
            height={IMAGE_HEIGHT}
            width={isVertical ? IMAGE_WIDTH_VERTICAL : IMAGE_WIDTH_HORIZONTAL}
            latitude={latitude}
            longitude={longitude}
            dynamicWidth={dynamicWidth}
            containerId={`location-${selectedLocationId}-map-preview-${caller}`}
          />
        </Grid>

        <Grid item xs>
          <Box px={2}>
            <LocationContent
              showAddressName
              address={locationAddress}
              tags={tags}
              interactiveTags
              locationId={selectedLocation?.id}
              accountId={accountId}
            />
          </Box>
          <Box mt={1.5} px={2}>
            <InteractiveTagsList
              data={{
                locationId: selectedLocation?.id,
                name: addressName,
                tagDetails: { forLocation: tags },
              }}
              disableAccount
              disableService
              onSave={(data, closeCallback) => {
                closeCallback()
                onSaveTags()
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {showContacts && (
        <>
          {isVertical && <Divider orientation="horizontal" flexItem sx={{ my: 1 }} />}
          {isHorizontal && <Divider flexItem orientation="vertical" />}
          <Grid item xs="auto">
            <Box px={2} display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" fontWeight={500} color="textSecondary">
                Location contacts
              </Typography>
            </Box>
            <Box
              sx={
                isHorizontal && {
                  overflow: 'auto',
                  width: `${LEFT_SECTION_WIDTH}px`,
                  maxHeight: `${contactHeight > IMAGE_HEIGHT ? contactHeight : IMAGE_HEIGHT}px`,
                }
              }
            >
              <ContactsListing contacts={contacts} noContactMessage={T.NO_LOCATION_CONTACTS} />
            </Box>
          </Grid>
        </>
      )}

      {showNotes && (
        <>
          <Divider orientation="horizontal" flexItem sx={{ mt: 1 }} />
          <LocationNotesAccordionVariant locationId={selectedLocationId} />
        </>
      )}
    </Grid>
  )
}

LocationExpandView.propTypes = {
  caller: PropTypes.string,
  selectedLocation: PropTypes.object,
  orientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
  onSaveTags: PropTypes.func.isRequired,
  dynamicWidth: PropTypes.bool,
  showNotes: PropTypes.bool,
  showContacts: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
}

export default LocationExpandView
