import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, capitalize } from 'lodash'
import { Box, Divider, Grid } from '@mui/material'
import HHAccordion from 'components/common/HHAccordion'
import T from 'T'
import { getCustomerBillingDetails } from 'data/customer/billingDetailsSelector'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import BillingDetailsListItem from 'components/customer-details/content/billing/BillingDetailsListItem'
import BillingField from './BillingField'
import BillingDetailsActionMenu from './BillingDetailsActionMenu'

const DEFAULT_PRICE_TYPE = '$'

const BillingDetails = ({ customerDetails = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const creditLimitCents = get(customerDetails, 'creditLimitCents', 0)
  const billingProfile = get(customerDetails, 'accountBilling.billingProfile', {})
  const isBillingProfileEmpty = isEmpty(billingProfile)

  const {
    profileName,
    billingCycle,
    paymentType,
    timing,
    firstMonths,
    balance,
    isLateFeeTypeDollar,
    lateFeeAmount,
    lateFeeThreshold,
    lateFeeGracePeriod,
    paymentTerms,
    taxExempt,
  } = getCustomerBillingDetails(customerDetails)

  const handleActionClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        isExpanded
        actionType="ellipsis"
        withNewColors
        summary={T.BILLING_DETAILS}
        onActionClick={handleActionClick}
      >
        {isBillingProfileEmpty && <HHSectionPlaceholder title={T.NO_BILLING_DETAILS} />}
        {!isBillingProfileEmpty && (
          <Box width="100%" p={2}>
            <Grid container sx={{ mt: 1 }}>
              <>
                <Grid item xs>
                  <BillingDetailsListItem
                    primaryTypographyProps={{
                      fontWeight: 600,
                      variant: 'h4',
                    }}
                    primary={<HHDisplayMoney value={balance} />}
                    secondary={T.BALANCE}
                  />
                </Grid>
              </>
              {creditLimitCents > 0 && (
                <>
                  <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                  <Grid item xs>
                    <BillingDetailsListItem
                      primary={<HHDisplayMoney value={creditLimitCents} formatToDollars />}
                      secondary={T.CREDIT_LIMIT}
                      primaryTypographyProps={{
                        textAlign: 'right',
                        variant: 'h5',
                      }}
                      secondaryTypographyProps={{
                        textAlign: 'right',
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Divider sx={{ my: 1 }} />

            <BillingDetailsListItem primary={profileName} secondary={T.BILLING_PROFILE} />
            <BillingDetailsListItem primary={paymentType} secondary={T.TYPE} />
            <Grid container flexWrap="nowrap">
              <Grid item xs>
                <BillingDetailsListItem primary={timing} secondary={T.TIMING} />
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              <Grid item xs>
                <BillingDetailsListItem
                  primary={billingCycle}
                  primaryTypographyProps={{
                    textAlign: 'right',
                  }}
                  secondaryTypographyProps={{
                    textAlign: 'right',
                  }}
                  secondary={T.CYCLE}
                />
              </Grid>
            </Grid>
            {firstMonths && <BillingDetailsListItem primary={firstMonths} secondary={T.FIRST_MONTHS} />}
            <BillingDetailsListItem secondary={T.PAYMENT_TERMS} primary={paymentTerms} />
            <Divider sx={{ my: 1 }} />
            <BillingField
              variant="h5"
              ValueTypographyProps={{ fontWeight: 400 }}
              label={T.LATE_FEES_FINANCING}
              value={lateFeeAmount}
              isNumberFormat
              prefix={isLateFeeTypeDollar ? DEFAULT_PRICE_TYPE : ''}
              suffix={!isLateFeeTypeDollar ? '%' : ''}
            />
            <BillingField
              variant="h5"
              ValueTypographyProps={{ fontWeight: 400 }}
              label={T.THRESHOLD}
              value={lateFeeThreshold}
              isNumberFormat
              prefix={DEFAULT_PRICE_TYPE}
            />
            <BillingField variant="h5" ValueTypographyProps={{ fontWeight: 400 }} label={T.GRACE_PERIOD} value={lateFeeGracePeriod} />
            <BillingField variant="h5" ValueTypographyProps={{ fontWeight: 400 }} label={T.TAX_STATUS} value={capitalize(taxExempt)} />
          </Box>
        )}
      </HHAccordion>
      <BillingDetailsActionMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} customerDetails={customerDetails} />
    </>
  )
}

BillingDetails.propTypes = {
  customerDetails: PropTypes.object,
}

export default memo(BillingDetails)
