import React, { useState } from 'react'
import { TableHead, Typography, Popover, IconButton, styled, TableCell as MuiTableCell } from '@mui/material'
import PropTypes from 'prop-types'
import Configurator from 'components/common/Configurator'
import Constants from 'Constants'
import T from 'T'
import { ALL_DISPOSAL_FEES } from 'components/pricing/settings'
import { handleConfigurationChange, handleConfiguratorDragEnd } from 'data/utils/configuratorSelector'

const { MEDIA_SERVER } = Constants

const TableCell = styled(MuiTableCell)({
  p: 1,
})

const ConfirmationTableHeader = ({ className, columns, setColumns, sx }) => {
  const [previousColumns, setPreviousColumns] = useState(ALL_DISPOSAL_FEES)
  const [open, setOpen] = useState(false)
  const buttonRef = React.useRef()
  const [anchorEl, setAnchorEl] = useState(undefined)
  return (
    <TableHead sx={sx} className={className}>
      {columns
        .filter(({ checked }) => checked)
        .map(({ label }) => {
          return (
            <>
              {label === T.OVERAGE_FEE && (
                <>
                  <TableCell>
                    <Typography variant="body1" textTransform="capitalize" sx={{ color: '#6E788A' }}>
                      Overage
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" textTransform="capitalize" sx={{ color: '#6E788A' }}>
                      Fee
                    </Typography>
                  </TableCell>
                </>
              )}
              {label === T.PRICE && (
                <>
                  <TableCell>
                    <Typography variant="body1" textTransform="capitalize" sx={{ color: '#6E788A', marginLeft: 98 }}>
                      Price
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" textTransform="capitalize" sx={{ color: '#6E788A' }}>
                      Measure
                    </Typography>
                  </TableCell>
                </>
              )}
              {label !== T.PRICE && label !== T.OVERAGE_FEE && label !== T.MEASURE && (
                <TableCell>
                  <Typography variant="body1" textTransform="capitalize" sx={{ color: '#6E788A' }}>
                    {label}
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <IconButton
                  sx={{
                    width: 24,
                    height: 24,
                    padding: 3,
                    border: '1px solid #BDC3CD',
                    boxShadow: '0px 0.75px 1.5px rgba(0, 0, 0, 0.1)',
                    borderRadius: 4,
                  }}
                  ref={buttonRef}
                  onClick={() => {
                    setOpen(true)
                    setAnchorEl(buttonRef.current)
                  }}
                >
                  <img src={`${MEDIA_SERVER}Services.svg`} alt="" />
                </IconButton>
              </TableCell>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                className="configurator-container"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Configurator
                  configData={columns}
                  handleOnDragEnd={result => setColumns(handleConfiguratorDragEnd({ result, columns }))}
                  handleCancel={() => {
                    setOpen(false)
                    setColumns(previousColumns)
                  }}
                  handleConfChange={(index, type, confData) => {
                    setColumns(handleConfigurationChange({ index, type, confData }))
                  }}
                  saveConfigSettings={() => {
                    setOpen(false)
                    setPreviousColumns(columns)
                  }}
                />
              </Popover>
            </>
          )
        })}
    </TableHead>
  )
}

ConfirmationTableHeader.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
  sx: PropTypes.object,
}

export default ConfirmationTableHeader
