import set from 'lodash/set'

import { get } from 'utils/lodash'
import { PRICING_SERVICE_SEARCH_PAGINATION } from 'settings/constants/pagination'

const { INITIAL_PAGE } = PRICING_SERVICE_SEARCH_PAGINATION
const MAX_PAGE_SIZE = 1000

export const getPricingServiceSearchPayload = newChanges => ({
  serviceType: 'On Request',
  businessLines: get(newChanges, 'businessLine', '') ? [newChanges.businessLine] : [],
  measureIds: get(newChanges, 'measureIds', []).map(filter => filter.id),
  materialIds: get(newChanges, 'materialIds', []).map(filter => filter.id),
  serviceMethodIds: get(newChanges, 'serviceMethodIds', []).map(filter => filter.id),
  serviceActionIds: get(newChanges, 'serviceActionIds', []).map(filter => filter.id),
  pricingZoneId: get(newChanges, 'pricingZoneId', ''),
  search: get(newChanges, 'search', ''),
  requestedPage: INITIAL_PAGE,
  requestedPageSize: MAX_PAGE_SIZE,
})

export const getCreateServiceGroupPayload = (name = '', selectedBusinessLineId = '', currentSelectedServicesIds = [], filterModel = {}) => {
  const payload = {
    name: name.trim(),
    businessLine: {
      // later change to get(filterModel, 'businessLine')
      id: selectedBusinessLineId,
    },
    pricedServices: currentSelectedServicesIds.map(service => ({ id: service })),
  }

  const pricingZone = get(filterModel, 'pricingZoneId', '')

  if (pricingZone) {
    set(payload, 'pricingZone.id', pricingZone)
  }

  return payload
}

export const getUpdateServiceGroupPayload = (currentSelectedGroup, currentSelectedServicesIds = []) => ({
  id: get(currentSelectedGroup, 'id'),
  addedServices: currentSelectedServicesIds.map(service => ({ id: service })),
})
