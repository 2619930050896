import React, { useCallback } from 'react'
import StarBorderIcon from '@mui/icons-material/StarOutlineRounded'
import StarIcon from '@mui/icons-material/StarRateRounded'
import { IconButton } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { selectPricingFavoriteUrl, setFavoritePricingUrl } from 'slices/pricing/pricingDrawerSlice'

const PricingFavButton = ({ url }) => {
  const pricingFavoriteUrl = useSelector(selectPricingFavoriteUrl, shallowEqual)
  const isFavorite = url === pricingFavoriteUrl
  const dispatch = useDispatch()

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(setFavoritePricingUrl(url))
    },
    [url, pricingFavoriteUrl]
  )

  return <IconButton onClick={handleClick}>{isFavorite ? <StarIcon /> : <StarBorderIcon />}</IconButton>
}

PricingFavButton.propTypes = {
  url: PropTypes.string,
}

export default PricingFavButton
