import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box } from '@mui/material'
import { green, grey } from '@mui/material/colors'

import PersonIcon from '@mui/icons-material/Person'
import { NoAccounts } from '@mui/icons-material'

const BOX_ACTIVE_SX = { borderColor: green[500], backgroundColor: green[50] }
const AVATAR_ACTIVE_SX = { backgroundColor: green[400] }

const BOX_DEACTIVATED_SX = { borderColor: 'rgba(0, 0, 0, 0.54)', backgroundColor: grey[50] }
const AVATAR_DEACTIVATED_SX = { backgroundColor: 'rgba(0, 0, 0, 0.54)' }

const ContactStatusAvatar = ({ isActive = false, styles = {} }) => {
  const boxStyles = isActive ? BOX_ACTIVE_SX : BOX_DEACTIVATED_SX
  const sx = isActive ? AVATAR_ACTIVE_SX : AVATAR_DEACTIVATED_SX
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={24}
      height={24}
      borderRadius="50%"
      sx={{ border: '1px solid', ...boxStyles, ...styles }}
    >
      <Avatar sx={{ ...sx, width: 18, height: 18 }}>{isActive ? <PersonIcon /> : <NoAccounts />}</Avatar>
    </Box>
  )
}

ContactStatusAvatar.propTypes = {
  isActive: PropTypes.bool,
  styles: PropTypes.object,
}

export default ContactStatusAvatar
