import React, { useReducer, useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell as CallsTableCell } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { handleTooltipOnLargeText } from 'utils/helper'
import { getAddCallFollowUpPayload, getAPIIdParam } from 'utils/form-body'
import { CALLS_PAGINATION } from 'settings/constants/pagination'
import { useLazyGetCustomerCallsQuery } from 'api/calls/getCustomerCalls'
import { useAddUpdateCallFollowUpMutation } from 'api/calls/addUpdateCallFollowUp'
import { useLazyGetCustomerCallByIdQuery } from 'api/calls/getCustomerCallById'
import { CACHE_TAG_CALLS_LIST } from 'api/cacheTagTypes'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'

import T from 'T'
import api from 'api'
import Loader from 'components/common/loader'
import HHAccordion from 'components/common/HHAccordion'
import AvatarSquare from 'components/user/AvatarSquare'
import TableSkelton from 'components/common/TableSkelton'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'

import CallsFollowUpDrawer from '../drawer/calls-followup'
import CustomerDetailsPaginations from './CustomerDetailsPaginations'
import './common.scss'
import AddCallsFollowupDialog from '../content/AddCallsFollowupDialog'
import { convertDateTimeToPreferedTimezone } from '../../../data/dateTime/convertDateTimeToPreferedTimezone'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CALLS_PAGINATION

const CALLS_TABLE_HEADER = [T.DATE, T.ADDED_BY, T.CONTACT, T.SUBJECT, T.DIRECTION]

const CONFIRM_TITLE = T.FOLLOWUP_CONFIRM_TITLE

const Calls = ({ accountId, locationId = '' }) => {
  const dispatch = useDispatch()
  const [getCustomerCalls, { isFetching: isCallsLoading, data: callsData }] = useLazyGetCustomerCallsQuery()
  const [addUpdateCallFollowUp, { isLoading: isUpdateCallLoading }] = useAddUpdateCallFollowUpMutation()
  const [getCustomerCallById, { isFetching: isCallByIdLoading }] = useLazyGetCustomerCallByIdQuery()

  const [callsState, setCallsState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    // Drawer state
    isDirty: false,
    isOpenDrawer: false,
    selectedCallId: '',
    singleCall: null,
    isOpenConfirmModal: false,
  })

  const { page, rowsPerPage, isDirty, isOpenDrawer, selectedCallId, singleCall, isOpenConfirmModal } = callsState
  const [isOpenAddCallsDialog, setIsOpenAddCallsDialog] = useState(false)

  const { calls: unsortedCalls = [], totalItems: totalCalls = 0, totalPages = 0 } = callsData || {}
  const calls = sortByDateSelector({ data: unsortedCalls, key: 'createdAt' })

  const disabledCallDrawerProceed = !get(singleCall, 'subject', '').trim()

  const getCalls = (requestedPage, requestedPageSize) => {
    const payload = { ...getAPIIdParam(locationId, accountId), requestedPage, requestedPageSize }
    getCustomerCalls(payload).unwrap().catch(handleError)
  }

  // Page change handler
  const handleCallsPageChange = newPage => {
    setCallsState({ page: newPage })
    getCalls(newPage, rowsPerPage)
  }

  // Rows per page change handler
  const handleCallsRowsPerPageChange = event => {
    setCallsState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
    getCalls(INITIAL_PAGE, event.target.value)
  }

  const resetCallsDrawer = () =>
    setCallsState({ singleCall: null, selectedCallId: '', isOpenConfirmModal: false, isOpenDrawer: false, isDirty: false })

  const handleUpdateCall = () => {
    if (disabledCallDrawerProceed) {
      return
    }

    if (!isDirty) {
      // No need to call API if no changes
      resetCallsDrawer()
      return
    }

    const payload = getAddCallFollowUpPayload(singleCall, accountId, locationId, selectedCallId)
    addUpdateCallFollowUp(payload)
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_CALLS_LIST]))
        resetCallsDrawer()
      })
      .catch(handleError)
  }

  const getCallById = callId => {
    if (!callId) {
      return
    }

    getCustomerCallById({ id: callId })
      .unwrap()
      .then(res => {
        const callData = get(res, 'call', {})
        if (callData) {
          setCallsState({ isOpenDrawer: true, selectedCallId: callId, singleCall: callData })
        }
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (accountId || locationId) {
      getCalls(INITIAL_PAGE, rowsPerPage)
    }
  }, [accountId, locationId])

  useEffect(() => handleTooltipOnLargeText())

  return (
    <>
      {(isUpdateCallLoading || isCallByIdLoading) && <Loader />}

      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={T.CALLS}
        actionType="add"
        onActionClick={() => setIsOpenAddCallsDialog(true)}
      >
        <Box className="calls-section common-section">
          <Box className="calls-box common-box common-acc-table">
            <Box className="acc-details common-details">
              {(!Array.isArray(calls) || calls.length === 0) && !isCallsLoading && <HHSectionPlaceholder title={T.NO_CALLS} />}

              <Box display={!isCallsLoading && (!Array.isArray(calls) || calls.length === 0) && 'none'} className="common-table-wrapper">
                <TableContainer className="calls-table common-table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {Children.toArray(
                          CALLS_TABLE_HEADER.map(header => (
                            <CallsTableCell>{header && <div className="inline-flex items-center">{header}</div>}</CallsTableCell>
                          ))
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isCallsLoading && <TableSkelton rows={3} columns={5} />}

                      {!isCallsLoading &&
                        Array.isArray(calls) &&
                        Children.toArray(
                          calls.map(call => {
                            const profilePicture = get(call, 'userInfo.profilePicUri', '')
                            const firstName = get(call, 'createdByFirstName', '')
                            const lastName = `${get(call, 'createdByLastName', '')}`

                            const callsContactFirstName = get(call, 'contact.firstName', '')
                            const callsContactLastName = `${get(call, 'contact.lastName', '')}`
                            const callsContactFullName = `${callsContactFirstName} ${callsContactLastName}`.trim()

                            const callCreatedAt = get(call, 'createdAt', '')
                            const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: callCreatedAt })

                            return (
                              <TableRow onClick={() => getCallById(get(call, 'id'))} className="cursor-pointer">
                                <CallsTableCell className="date">
                                  {onlyDate}
                                  <br />
                                  <span>{onlyTime}</span>
                                </CallsTableCell>
                                <CallsTableCell>
                                  <AvatarSquare firstName={firstName} lastName={lastName} src={profilePicture} />
                                </CallsTableCell>
                                <CallsTableCell className="contact whitespace-nowrap">{callsContactFullName}</CallsTableCell>
                                <CallsTableCell className="subject">{get(call, 'subject', '')}</CallsTableCell>
                                <CallsTableCell>{get(call, 'direction', '')}</CallsTableCell>
                              </TableRow>
                            )
                          })
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {totalCalls > ROWS_PER_PAGE && (
                <CustomerDetailsPaginations
                  withNewColors
                  page={page}
                  totalCount={totalCalls}
                  rowsPerPage={rowsPerPage}
                  handlePageChange={handleCallsPageChange}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  handleRowsPerPageChange={handleCallsRowsPerPageChange}
                  totalPageCount={totalPages}
                />
              )}
            </Box>
          </Box>
        </Box>
      </HHAccordion>

      <CallsFollowUpDrawer
        isOpenDrawer={isOpenDrawer}
        className="calls-drawer"
        details={singleCall}
        onClose={isOpen => {
          if (isDirty) {
            setCallsState({ isOpenConfirmModal: true })
            return
          }
          setCallsState({ isOpenDrawer: isOpen, selectedCallId: '', singleCall: null })
        }}
        onChange={(key, value) => setCallsState({ singleCall: { ...singleCall, [key]: value }, isDirty: true })}
        disabledProceed={disabledCallDrawerProceed}
        onProceed={handleUpdateCall}
      />

      <ConfirmationModal
        className="customer-details-slideout-confirm"
        isOpen={isOpenConfirmModal}
        title={CONFIRM_TITLE}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={T.CLOSE}
        onCancel={() => {
          setCallsState({ isOpenConfirmModal: false })
        }}
        onProceed={() => {
          resetCallsDrawer()
        }}
      />

      {isOpenAddCallsDialog && (
        <AddCallsFollowupDialog
          isOpen={isOpenAddCallsDialog}
          locationId={locationId}
          accountId={accountId}
          onClose={() => setIsOpenAddCallsDialog(false)}
        />
      )}
    </>
  )
}

Calls.propTypes = {
  locationId: PropTypes.string,
  accountId: PropTypes.string.isRequired,
}

export default memo(Calls)
