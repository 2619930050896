import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'
import { ListItemText, Menu, MenuItem } from '@mui/material'

import T from 'T'
import UniqueUrlDialog from 'components/customer-details/content/customer-portal/UniqueUrlDialog'

const CustomerPortalActionMenu = ({ accountId, anchorEl, onClose }) => {
  const [isOpenUniqueUrlDialog, setIsOpenUniqueUrlDialog] = useState(false)

  const businessName = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessName, shallowEqual)
  const portalInviteURL = `https://${businessName}.haulerhero.com/v1/app/login?account_id=${accountId}`

  const handleOpenUniqueUrlDialog = () => {
    onClose()
    setIsOpenUniqueUrlDialog(true)
  }

  const handleCloseUniqueUrlDialog = () => {
    setIsOpenUniqueUrlDialog(false)
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined' } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleOpenUniqueUrlDialog}>
          <ListItemText sx={{ color: 'warning.main' }}>{T.COPY_UNIQUE_PORTAL_URL}</ListItemText>
        </MenuItem>
      </Menu>
      <UniqueUrlDialog
        open={isOpenUniqueUrlDialog}
        url={portalInviteURL}
        title={T.COPY_UNIQUE_PORTAL_URL}
        label="Portal URL"
        description="DO NOT SHARE WITH OTHER CUSTOMERS. This URL is unique to this account ONLY."
        onClose={handleCloseUniqueUrlDialog}
      />
    </>
  )
}

CustomerPortalActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string,
  onClose: PropTypes.func,
}

export default CustomerPortalActionMenu
