import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useDeleteVacationHoldMutation } from 'api/accounts/vacationHoldsCrud'
import { get } from 'lodash'
import HoldActionMenu from 'components/customer-details/content/holds/HoldActionMenu'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import HoldsFormDialog from 'components/customer-details/content/holds/HoldsFormDialog'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import DialogContent from '@mui/material/DialogContent'
import HHDialogActions from 'components/common/HHDialogActions'
import VacationHoldList from 'components/customers/accounts/vacation-holds/VacationHoldList'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'

const VacationHoldDialog = ({ open, row, onClose }) => {
  const { accountId } = row
  const [getAccountDetails, { data: accountData }] = useLazyGetAccountDetailsQuery()

  const [formMode, setFormMode] = useState('create')
  const [openFormDialog, setOpenFormDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [actionAnchorEl, setActionAnchorEl] = useState(null)
  const [selectedHold, setSelectedHold] = useState(null)
  const [deleteHold, { isLoading: isDeleteLoading }] = useDeleteVacationHoldMutation()

  const selectedHoldId = get(selectedHold, 'id')

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleConfirmDelete = () => {
    deleteHold({ vacationHoldId: selectedHoldId, accountId })
    setOpenDeleteDialog(false)
  }
  const handleAddClick = () => {
    setFormMode('create')
    setOpenFormDialog(true)
  }

  const handleFormDialogClose = () => {
    setOpenFormDialog(false)
    setFormMode('create')
  }

  const handleOpenActionMenu = (e, hold) => {
    setSelectedHold(hold)
    setActionAnchorEl(e.target)
  }

  const handleCloseActionMenu = () => {
    setActionAnchorEl(null)
  }

  const handleEditActionClick = () => {
    setFormMode('update')
    setOpenFormDialog(true)
  }

  const handleDeleteActionClick = () => {
    setOpenDeleteDialog(true)
  }

  useEffect(() => {
    if (open && accountId) {
      getAccountDetails({ accountId })
    }
  }, [open, accountId])

  return (
    <HHBaseDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title="Vacation holds" showCloseIcon onClose={onClose} />
      <DialogContent sx={{ pr: 1.5 }}>
        <VacationHoldList itemSx={{ pr: 0 }} row={row} open={open} showActionMenu onClickVacationHoldActionMenu={handleOpenActionMenu} />
        <HoldActionMenu
          accountId={accountId}
          selectedHold={selectedHold}
          anchorEl={actionAnchorEl}
          onClose={handleCloseActionMenu}
          onEdit={handleEditActionClick}
          onDelete={handleDeleteActionClick}
        />
        <HHConfirmDialog
          ConfirmButtonProps={{ loading: isDeleteLoading }}
          DialogProps={{ maxWidth: 'xs', fullWidth: true }}
          isOpen={openDeleteDialog}
          confirmTitle="Confirm hold deletion"
          confirmDescription="Are you sure you want to continue?"
          onConfirm={handleConfirmDelete}
          onClose={handleCloseDeleteDialog}
        />
        <HoldsFormDialog
          customerDetails={accountData}
          accountId={accountId}
          open={openFormDialog}
          mode={formMode}
          selectedHold={selectedHold}
          onClose={handleFormDialogClose}
          onSave={handleFormDialogClose}
        />
      </DialogContent>
      <HHDialogActions>
        <Button size="small" onClick={handleAddClick}>
          Add hold
        </Button>
        <Button size="small" variant="contained" onClick={onClose}>
          Close
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

VacationHoldDialog.propTypes = {
  open: PropTypes.bool,
  row: PropTypes.object,
  onClose: PropTypes.func,
}

export default VacationHoldDialog
