import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_LIST } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    createInvoiceGroup: build.mutation({
      query: body => ({ url: '/api/v1/billing/invoice/groups', method: 'POST', body }),
      invalidatesTags: [CACHE_TAG_BILLING_INVOICE_GROUP_LIST],
    }),
  }),
})

export const { useCreateInvoiceGroupMutation } = extendedApi
