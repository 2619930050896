export const AGING_BUCKET_NAME = {
  CURRENT: 'current',
  DUE_ONE_THIRTY: 'dueOneThirty',
  DUE_THIRTY_ONE_SIXTY: 'dueThirtyOneSixty',
  DUE_SIXTY_ONE_NINETY: 'dueSixtyOneNinety',
  DUE_OVER_NINETY: 'dueOverNinety',
}

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const AGING_BUCKET_LABEL = {
  [CURRENT]: 'Current',
  [DUE_ONE_THIRTY]: '1-30 days',
  [DUE_THIRTY_ONE_SIXTY]: '31-60 days',
  [DUE_SIXTY_ONE_NINETY]: '61-90 days',
  [DUE_OVER_NINETY]: '90+ days',
}

export const BUCKET_NAME_TO_GROUP_ID_PARAM = {
  [DUE_ONE_THIRTY]: 'ONE_THIRTY',
  [DUE_THIRTY_ONE_SIXTY]: 'THIRTY_SIXTY',
  [DUE_SIXTY_ONE_NINETY]: 'SIXTY_NINETY',
  [DUE_OVER_NINETY]: 'OVER_NINETY',
}
