import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const RenderInvoiceHeader = ({ rows = [], selectedRows = [], hierarchyIds = [], totalAmountCents }) => {
  const totalRows = rows.length - hierarchyIds.length
  const totalSelectedRows = selectedRows.length

  return (
    <Box pr={2} width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        {`${totalRows} invoice${totalRows > 1 ? 's' : ''}`}
        {totalSelectedRows > 0 ? ` / ${totalSelectedRows} selected` : ''}
      </Typography>
      <Typography variant="h5" fontWeight={500} color="textPrimary">
        <HHDisplayMoney value={totalAmountCents} />
      </Typography>
    </Box>
  )
}

RenderInvoiceHeader.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  hierarchyIds: PropTypes.array,
  totalAmountCents: PropTypes.number,
}

export default RenderInvoiceHeader
