import React from 'react'
import PropTypes from 'prop-types'

import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const AccountInfoListItem = ({ label = '', value = 0, ...rest }) => (
  <ListItem {...rest}>
    <ListItemText
      primary={label}
      secondary={value}
      primaryTypographyProps={{ variant: 'body1', color: 'textSecondary' }}
      secondaryTypographyProps={{ variant: 'h5', fontWeight: 500, color: 'textPrimary' }}
    />
  </ListItem>
)

AccountInfoListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
}

export default AccountInfoListItem
