import React from 'react'
import PropTypes from 'prop-types'
import RenderAgingBucketInvoiceCell from 'components/common/aging-bucket/InteractiveAgingBucket/RenderAgingBucketInvoiceCell'
import { get } from 'lodash'
import RenderAgingBucketAccountRow from 'components/common/aging-bucket/InteractiveAgingBucket/RenderAgingBucketAccountRow'

const RenderAgingBucketCell = ({ row, accountData }) => {
  const isAccountRow = get(row, 'isAccountRow')
  if (isAccountRow) return <RenderAgingBucketAccountRow accountData={accountData} />
  return <RenderAgingBucketInvoiceCell row={row} accountData={accountData} />
}

RenderAgingBucketCell.propTypes = {
  row: PropTypes.object,
  accountData: PropTypes.object,
}

export default RenderAgingBucketCell
