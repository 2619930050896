import React from 'react'
import { GridRow } from '@mui/x-data-grid-pro'
import WithDeactivatedIndicator from 'components/customers/WithDeactivatedIndicator'
import { get } from 'lodash'
import PropTypes from 'prop-types'

const CustomLocationRow = props => {
  const { row } = props
  const accountActive = get(row, 'accountActive', false)
  return (
    <WithDeactivatedIndicator showIndicator={!accountActive} locationsVariant {...props}>
      <GridRow {...props} />
    </WithDeactivatedIndicator>
  )
}

CustomLocationRow.propTypes = {
  row: PropTypes.object,
}

export default CustomLocationRow
