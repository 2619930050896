import T from 'T'

export const WORK_ORDERS = [
  {
    label: T.WO,
  },
  {
    label: T.DATE,
    sortKey: 'SERVICE_DATE',
  },
  {
    label: T.SERVICE,
    sortKey: 'SERVICE_NAME',
  },
  {
    label: T.ROUTE,
    sortKey: 'ROUTE_NAME',
  },
  {
    label: `${T.PO} #`,
    headerSx: { whiteSpace: 'nowrap' },
  },
  {
    label: T.EXCEPTION,
  },
  {
    label: T.NOTE,
  },
  {
    label: T.PHOTOS,
  },
  {
    label: T.FEE_TOTAL,
  },
  {
    label: T.STATUS,
  },
  {
    label: T.INVOICE_STATUS,
  },
]
