import T from 'T'

export const BILLING_TABS_ID = {
  CREATE_INVOICES_TAB_ID: 'create_invoices',
  INVOICES_TAB_ID: 'invoices',
  SENT_INVOICES_TAB_ID: 'sent_invoices',
  PAYMENTS_TAB_ID: 'payments',
  ACCOUNTS_RECEIVABLE_TAB_ID: 'accounts_receivable',
}

export const INVOICE_STATUS = {
  PAID: 'Paid',
  PARTIAL: 'Partial', // Draft
  UNPAID: 'Unpaid',
  VOID: 'Void',
}

export const INVOICE_TYPE = {
  RECURRING: 'RECURRING',
  MANUAL: 'MANUAL',
}

export const PAYMENT_TERMS = ['Net [X] Days', 'Due on Receipt']

export const INTERVALS = ['1', '2', '3', '4', '5', '6']

export const RECURRENCE_INTERVALS = ['1', '2', '3', '4', '5', '6']

export const BILLING_CYCLE_LABELS = ['Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']

export const BILLING_CYCLES = {
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
}
export const CYCLES = ['Manual', 'Day', 'Week', 'Month', 'Quarter', 'Year']

export const CYCLE_PERIOD = [
  { key: 'One-Time', value: 'One-Time' },
  { key: 'Month', value: 'Monthly' },
  { key: 'Quarter', value: 'Quarterly' },
]

export const DAY_BASED_PRORATE = 1
export const EVENT_BASED_PRORATE = 2
export const PRORATION_OPTIONS = [
  { key: DAY_BASED_PRORATE, value: T.DAY_BASED_PRORATION },
  { key: EVENT_BASED_PRORATE, value: T.EVENT_BASED_PRORATION },
]

export const BILLING_TABLE_BACKEND_VALUES = {
  ACCOUNT_CREDIT: 'AccountCredit',
  CREDIT_CARD: 'CreditCredit',
}

export const DELIVERY_METHODS = ['Pdf', 'Portal', 'Paper', 'Postcard']

export const DELIVERY_MAPPING_VALUES = {
  Pdf: T.EMAIL,
  Portal: T.PORTAL,
  Paper: T.PDF,
}

export const BILLING_PERIOD_SUPPORTED_VIEW = {
  MONTH: 'Month',
  QUARTER: 'Quarter',
  BI_ANNUALLY: 'BiAnnually',
  DEFAULT: 'Default',
}
