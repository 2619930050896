import T from 'T'

const { ITEM, NOTE, VALUE, SHOW_NOTE } = T

export const FEES_ADJUSTMENT_COLUMNS = [
  { label: ITEM },
  { label: NOTE },
  { label: SHOW_NOTE, align: 'center' },
  { label: VALUE, align: 'right' },
  { label: '', align: 'right' },
]
