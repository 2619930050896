import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { getBucketStyles } from './settings'

const AgingBucket = ({ type = '', label, value = 0 }) => {
  const bucketStyles = useMemo(() => getBucketStyles(type), [type])

  return (
    <>
      <Box
        minWidth="fit-content"
        flex={1}
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        p={1}
        borderRadius={1}
        sx={{ border: '1px solid', ...bucketStyles }}
      >
        <Typography variant="caption" fontWeight={700} noWrap>
          {label}
        </Typography>
        <Typography variant="h6" fontWeight={700} mt={0.5} noWrap color="textPrimary">
          <HHDisplayMoney value={value} />
        </Typography>
      </Box>
    </>
  )
}

AgingBucket.propTypes = {
  value: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default AgingBucket
