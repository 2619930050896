import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    delecteContact: build.mutation({
      query: ({ accountId, contactId }) => ({ url: `/api/v1/core/accounts/${accountId}/contacts/${contactId}`, method: 'DELETE' }),
    }),
  }),
})

export const { useDelecteContactMutation } = extendedApi
