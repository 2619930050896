import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { CACHE_TAG_WORK_ORDER_DETAILS, CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'

const AddEditPurchaseOrderDialog = ({ isOpen = false, workOrderId, existingPurchaseOrder = '', onClose }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder, { isLoading }] = useUpdateWorkOrderMutation()
  const purchaseOrderForm = useForm({ defaultValues: { purchaseOrderNumber: '' } })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = purchaseOrderForm

  const purchaseOrderNumberHelperText = get(errors, 'purchaseOrderNumber.message')

  const onSubmitHandler = data => {
    updateWorkOrder({ id: workOrderId, purchaseOrderNumber: data.purchaseOrderNumber })
      .unwrap()
      .then(() => {
        onClose()
        toast.success(existingPurchaseOrder ? T.PURCHASE_ORDER_UPDATED_SUCCESS_MSG : T.PURCHASE_ORDER_ADDED_SUCCESS_MSG)
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS, CACHE_TAG_WORK_ORDER_LIST]))
      })
      .catch(handleError)
  }

  const handleKeyDown = event => {
    event.stopPropagation()
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      handleSubmit(onSubmitHandler)()
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({ purchaseOrderNumber: existingPurchaseOrder })
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={existingPurchaseOrder ? T.EDIT_PURCHASE_ORDER : T.ADD_PURCHASE_ORDER} onClose={onClose} />
      <DialogContent>
        <FormProvider {...purchaseOrderForm}>
          <Box mt={2}>
            <HHFormTextField
              autoFocus
              error={purchaseOrderNumberHelperText}
              helperText={purchaseOrderNumberHelperText}
              control={control}
              rules={{ required: T.CANNOT_BE_EMPTY }}
              name="purchaseOrderNumber"
              label={`${T.PO} #`}
              placeholder={`${T.PO} #`}
              fullWidth
              deprecatedLabel={false}
              onKeyDown={handleKeyDown}
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton loading={isLoading} size="small" variant="contained" onClick={handleSubmit(onSubmitHandler)}>
          {existingPurchaseOrder ? T.UPDATE : T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddEditPurchaseOrderDialog.propTypes = {
  isOpen: PropTypes.bool,
  workOrderId: PropTypes.string.isRequired,
  existingPurchaseOrder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default AddEditPurchaseOrderDialog
