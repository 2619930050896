import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { SketchPicker } from 'react-color'
import { Typography, Box, Popover, Paper } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'

const ColorPickerToolBar = ({ title = '', color = '', type = '', onHandleChange = noop, handleColorPickerChangeComplete = noop }) => {
  const [picker, setPicker] = useState(false)

  return (
    <Box mt={2}>
      <Typography variant="subtitle2" mb={0.5}>
        {title}
      </Typography>

      <Box display="flex">
        <Paper variant="outlined" sx={{ backgroundColor: color, width: 32 }} onClick={event => setPicker(event.currentTarget)} />

        <HHTextField
          sx={{
            width: 180,
            ml: 1.5,
            mr: 1.5,
          }}
          name={type}
          autoComplete="off"
          size="small"
          variant="outlined"
          value={color}
          InputProps={{
            onChange: e => onHandleChange(e, 'color'),
          }}
        />
      </Box>

      {picker && (
        <Popover
          open={Boolean(picker)}
          anchorEl={picker}
          onClose={() => setPicker(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SketchPicker color={color} disableAlpha onChangeComplete={e => handleColorPickerChangeComplete(e, type)} />
        </Popover>
      )}
    </Box>
  )
}

ColorPickerToolBar.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onHandleChange: PropTypes.func,
  handleColorPickerChangeComplete: PropTypes.func,
}

export default ColorPickerToolBar
