import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import { Typography, Button, Grid, FormControlLabel, Switch, Stack } from '@mui/material'

import CancelButton from 'components/buttons/CancelButton'
import { usePostBillingCreateInvoicesMutation } from 'api/billing/postBillingCreateInvoices'
import { formatDateToFEFormat, formatDateToBEFormatDateFns } from 'utils/date'
import T from 'T'
import { handleError } from 'utils/error'
import { memo } from 'utils/react'
import { useDispatch } from 'react-redux'
import { setCreatedInvoicesState } from 'slices/billing/invoicesCreationBridgeSlice'
import { changeCreatedInvoicesData } from 'slices/billing/createInvoicesSlice'
import { INVOICE_TYPE } from 'settings/constants/billing'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { capitalize } from 'lodash'
import HHDialogActions from 'components/common/HHDialogActions'
import DialogContent from '@mui/material/DialogContent'
import CommonTextLabel from 'components/common/CommonTextLabel'
import HHDatePicker from 'components/common/HHDatePicker'
import Dialog from '@mui/material/Dialog'

const { RECURRING } = INVOICE_TYPE
const ConfirmInvoiceDate = ({
  currentBillingProfile = '',
  nextBillDate = formatDateToBEFormatDateFns(new Date()),
  accountIds = [],
  isOpenDrawer = false,
  onClose,
  onConfirm,
}) => {
  const dispatch = useDispatch()
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    changeInvoiceDate: false,
    newInvoiceDate: new Date(),
  })
  const [skipZeroAmountInvoices, setSkipZeroAmountInvoices] = useState(false)
  const { changeInvoiceDate, newInvoiceDate } = localState
  const [postBillingCreateInvoices, { isLoading }] = usePostBillingCreateInvoicesMutation()
  const finalDate = changeInvoiceDate ? formatDateToBEFormatDateFns(newInvoiceDate) : nextBillDate

  const handleSkipZeroAmountInvoicesChange = () => {
    setSkipZeroAmountInvoices(!skipZeroAmountInvoices)
  }

  useEffect(() => {
    dispatch(changeCreatedInvoicesData({ invoiceDate: changeInvoiceDate ? formatDateToBEFormatDateFns(newInvoiceDate) : nextBillDate }))
  }, [nextBillDate, newInvoiceDate, changeInvoiceDate])

  useEffect(() => {
    dispatch(setCreatedInvoicesState({ skipZeroAmountInvoices }))
  }, [skipZeroAmountInvoices])

  return (
    <Dialog maxWidth="xs" open={isOpenDrawer} onClose={onClose}>
      <HHDialogTitle title={capitalize(`${T.CONFIRM} ${T.INVOICE_DATE}`)} onClose={onClose} />

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" pt={2} pb={0.5}>
              {`${T.CURRENT} ${T.INVOICE_DATE}: ${nextBillDate ? formatDateToFEFormat(nextBillDate) : ''}`}
            </Typography>
          </Grid>
          <Grid item sx={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={changeInvoiceDate}
                  color="success"
                  onChange={() => {
                    setLocalState({ changeInvoiceDate: !changeInvoiceDate })
                  }}
                  name="changeInvoiceDate"
                />
              }
              label={`${T.CHANGE} ${T.INVOICE_DATE}`}
            />
          </Grid>
          <Grid item xs={12}>
            {changeInvoiceDate && (
              <Stack mt={1}>
                <CommonTextLabel title={`${T.NEW} ${T.INVOICE_DATE}`} />
                <HHDatePicker
                  handleChange={date => {
                    setLocalState({
                      newInvoiceDate: date,
                    })
                  }}
                  value={newInvoiceDate}
                />
              </Stack>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch
                  checked={skipZeroAmountInvoices}
                  color="success"
                  onChange={handleSkipZeroAmountInvoicesChange}
                  name="skipZeroAmountInvoices"
                />
              }
              label="Do not generate $0 invoices"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <HHDialogActions>
        <CancelButton
          onClick={() => {
            setLocalState({
              changeInvoiceDate: false,
            })
            onClose(false)
          }}
          size="medium"
        />

        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={() => {
            const payload = {
              billingProfileId: currentBillingProfile,
              accountIds,
              invoicePeriodStartDate: nextBillDate,
              invoiceDate: finalDate,
              invoiceType: RECURRING,
              skipZeroAmountInvoices,
            }

            postBillingCreateInvoices(payload)
              .unwrap()
              .then(() => {
                setLocalState({
                  changeInvoiceDate: false,
                })
                onConfirm(false)
              })
              .catch(handleError)
          }}
          sx={{ ml: 1.5 }}
        >
          {T.CONFIRM}
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

ConfirmInvoiceDate.propTypes = {
  currentBillingProfile: PropTypes.array,
  accountIds: PropTypes.array,
  nextBillDate: PropTypes.object,
  isOpenDrawer: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default memo(ConfirmInvoiceDate)
