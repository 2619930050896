import { styled } from '@mui/material'
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup'

const CommonToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '&': {
    padding: 4,
    background: '#f9fafb',
    boxShadow: 'inset 0px 1px 4px rgb(26 31 54 / 20%)',
    borderRadius: '6px',
  },
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

export default CommonToggleButtonGroup
