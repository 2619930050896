import { createSelector } from 'reselect'

import T from 'T'
import { get } from 'utils/lodash'
import { getFirstMonthsScheduleOptions } from 'utils/billingSchedule'
import { BILLING_CYCLES } from 'settings/constants/billing'
import { ANNUAL_SCHEDULE_OPTIONS } from 'settings/constants/day'

const { MONTH, QUARTER, YEAR } = BILLING_CYCLES

const selectBillingCycles = state => get(state, 'billingCycles', [])
const selectBillingCycleId = state => get(state, 'cycleId')
const selectPaymentType = state => get(state, 'paymentType', '')
const selectTiming = state => get(state, 'timing', '')
const selectTimingDay = state => get(state, 'timingDay', '')
const selectTimingWeek = state => get(state, 'timingWeek', '')
const selectTimingQuarter = state => get(state, 'timingQuarter', '')
const selectBillingMonths = state => get(state, 'billingMonths', '')

export const selectedCycleDetails = createSelector([selectBillingCycles, selectBillingCycleId], (billingCycles, cycleId) => {
  if (!cycleId) {
    return ''
  }

  return billingCycles.find(bc => bc.id === cycleId)
})

export const selectedCycleIntervalType = createSelector([selectBillingCycles, selectBillingCycleId], (billingCycles, cycleId) => {
  if (!cycleId) {
    return ''
  }

  return get(
    billingCycles.find(bc => bc.id === cycleId),
    'intervalType',
    ''
  )
})

export const getBillingCycleChanges = createSelector(
  [selectedCycleDetails, selectPaymentType, selectTiming, selectTimingDay, selectTimingWeek, selectTimingQuarter, selectBillingMonths],
  (cycleDetails, paymentType, timing, timingDay, timingWeek, timingQuarter, billingMonths) => {
    const newPaymentType = [T.ADVANCE, T.ARREARS].includes(paymentType) ? paymentType : T.ADVANCE
    const interval = get(cycleDetails, 'interval')
    const intervalType = get(cycleDetails, 'intervalType')

    if (!intervalType) {
      return { paymentType: T.ADVANCE, timing: T.ANNIVERSARY_VALUE, timingDay: '', timingWeek: '', timingQuarter: '', billingMonths: '' }
    }

    // Manual
    if (intervalType === T.MANUAL) {
      return { paymentType: T.PAYMENT_TYPE_MANUAL, timing: '', timingDay: '', timingWeek: '', timingQuarter: '', billingMonths: '' }
    }

    // Year
    if (intervalType === YEAR) {
      return {
        paymentType: newPaymentType,
        timing: T.ANNIVERSARY_VALUE,
        timingDay: '',
        timingWeek: '',
        timingQuarter: '',
        billingMonths: billingMonths || ANNUAL_SCHEDULE_OPTIONS[0].key,
      }
    }

    // Week
    if (intervalType.includes(T.WEEK)) {
      return {
        paymentType: newPaymentType,
        timing: [T.ANNIVERSARY_VALUE, T.DAY_OF_WEEK].includes(timing) ? timing : T.ANNIVERSARY_VALUE,
        timingDay: '',
        timingWeek: timingWeek || '',
        timingQuarter: '',
        billingMonths: '',
      }
    }

    // Month
    if (intervalType === MONTH) {
      const firstMonthsOptions = getFirstMonthsScheduleOptions({ intervalType, interval })
      return {
        paymentType: newPaymentType,
        timing: [T.ANNIVERSARY_VALUE, T.DAY].includes(timing) ? timing : T.ANNIVERSARY_VALUE,
        timingDay: timingDay || '',
        timingWeek: '',
        timingQuarter: '',
        billingMonths: firstMonthsOptions.length > 0 ? billingMonths || firstMonthsOptions[0].key : '',
      }
    }

    // Quarter
    if (intervalType === QUARTER) {
      return {
        paymentType: newPaymentType,
        timing: [T.ANNIVERSARY_VALUE, T.DAY].includes(timing) ? timing : T.ANNIVERSARY_VALUE,
        timingDay: timingDay || '',
        timingWeek: '',
        timingQuarter: timingQuarter || '1',
        billingMonths: '',
      }
    }

    return { paymentType: T.ADVANCE, timing: T.ANNIVERSARY_VALUE, timingDay: '', timingWeek: '', timingQuarter: '', billingMonths: '' }
  }
)
