import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { ListItem, ListItemText, Collapse, List, IconButton, Link, Divider, Typography } from '@mui/material'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getCustomerDetailsPageUrl } from 'router/routes'

const ExpandView = ({ payment = {}, defaultOpen = false }) => {
  const [open, setOpen] = useState(false)
  const accountId = get(payment, 'accountId')
  const accountNumber = get(payment, 'accountNumber')
  const customerName = get(payment, 'customerName', '')
  const emails = get(payment, 'emails', [])
  const paymentNumbers = get(payment, 'paymentNumbers', []).join(', ')

  const handleClick = () => setOpen(!open)

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true)
    }
  }, [defaultOpen])

  return (
    <>
      <ListItem
        sx={{ pl: 0 }}
        secondaryAction={
          emails.length === 0 ? (
            <Typography variant="h5" color="error" fontWeight={400}>
              Missing email
            </Typography>
          ) : null
        }
      >
        <IconButton size="small" onClick={handleClick}>
          {open ? <ExpandMore /> : <ChevronRight />}
        </IconButton>

        <ListItemText
          primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }}
          primary={customerName}
          secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
          secondary={
            <Link
              component={RouterLink}
              to={`${getCustomerDetailsPageUrl(accountId)}?tab=account`}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              {accountNumber}
            </Link>
          }
        />
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding sx={{ ml: 3 }}>
          {emails.length > 0 ? (
            Children.toArray(
              emails.map(email => (
                <ListItem sx={{ pt: 0, pl: 3 }}>
                  <ListItemText primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }} primary={email} />
                </ListItem>
              ))
            )
          ) : (
            <ListItem sx={{ pt: 0, pl: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }}
                primary="No billing email on file"
              />
            </ListItem>
          )}
          <Divider />
          <ListItem sx={{ pl: 3 }}>
            <ListItemText
              primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }}
              primary={`Payment # ${paymentNumbers}`}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}

ExpandView.propTypes = {
  payment: PropTypes.object,
  defaultOpen: PropTypes.bool,
}

export default ExpandView
