import React, { Children } from 'react'
import PropTypes from 'prop-types'

import Constants from 'Constants'
import HHSortKeys from 'components/common/HHSortKeys'
import HHTableFilter from 'components/common/HHTableFilter'

import { Typography, TableRow, TableHead, TableCell, Box } from '@mui/material'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isValidFilter } from 'utils/table'

const { MEDIA_SERVER } = Constants

const ReportCommonTableHeader = ({
  columns = [],
  allFilters = {},
  allSortBy = {},
  lockedColumns = [],
  showFilters = true,
  onTableFilterChange = () => {},
  onSortByChange,
}) => {
  const handleHeaderClass = (ind, isLocked) => {
    if (lockedColumns.length === ind + 1) {
      return 'sticky-col add-shadow'
    }

    if (isLocked) {
      return 'sticky-col'
    }

    return ''
  }

  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          columns.map((acColumn, ind) => {
            const isLocked = get(acColumn, 'locked', false)
            const columnName = get(acColumn, 'columnName', '')
            return (
              <TableCell
                align={acColumn.align}
                size={acColumn.size}
                className={handleHeaderClass(ind, isLocked)}
                sx={{
                  padding: '0 8px 8px',
                  lineHeight: '16px',
                  backgroundColor: 'complete.light',
                }}
              >
                {acColumn.tableLabel && (
                  <Box sx={{ width: 'max-content', display: 'inline-flex', alignItems: 'center' }}>
                    {showFilters && !get(acColumn, 'hideFilter', false) && (
                      <HHTableFilter
                        isActive={isValidFilter(allFilters, columnName)}
                        onClick={event =>
                          onTableFilterChange(
                            event.currentTarget,
                            acColumn.filterType,
                            columnName,
                            get(acColumn, 'subType', ''),
                            acColumn.tableLabel,
                            get(acColumn, 'isSingleSelect', false),
                            get(acColumn, 'isBoolean', false)
                          )
                        }
                      />
                    )}

                    <Typography variant="subtitle2">{acColumn.tableLabel}</Typography>

                    {!get(acColumn, 'hideSort', false) && (
                      <HHSortKeys
                        isAscendingOrderActive={get(allSortBy, `${columnName}.operation`) === 'ASC'}
                        isDescendingOrderActive={get(allSortBy, `${columnName}.operation`) === 'DESC'}
                        columnName={columnName}
                        handleChange={onSortByChange}
                      />
                    )}
                  </Box>
                )}

                {!acColumn.tableLabel && (
                  <img style={{ cursor: 'pointer' }} src={`${MEDIA_SERVER}DownloadOutline.svg`} alt="download.svg" />
                )}
              </TableCell>
            )
          })
        )}
      </TableRow>
    </TableHead>
  )
}

ReportCommonTableHeader.propTypes = {
  columns: PropTypes.array,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  lockedColumns: PropTypes.array,
  showFilters: PropTypes.bool,
  onTableFilterChange: PropTypes.func,
  onSortByChange: PropTypes.func.isRequired,
}

export default memo(ReportCommonTableHeader)
