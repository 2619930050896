import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLazyGetAccountsBySuggestQuery, useLazyGetAccountsByFocusedSuggestQuery } from 'api/accounts/accountSearch'
import {
  ADDRESS_SEARCH_TYPE,
  CONTACT_NAME_SEARCH_TYPE,
  CONTAINER_ID_SEARCH_TYPE,
  PHONE_SEARCH_TYPE,
  SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE,
} from 'components/locations/filters/Search/settings'

export const useLazyDebouncedSearchAccountOptionsQuery = () => {
  const { watch } = useFormContext()
  const searchType = watch('searchType')

  const lazyGetAccountsBySuggestQuery = useLazyGetAccountsBySuggestQuery()
  const lazyGetAccountsByFocusedSuggestQuery = useLazyGetAccountsByFocusedSuggestQuery()
  const isFocusedSearch = useMemo(
    () => [ADDRESS_SEARCH_TYPE, PHONE_SEARCH_TYPE, CONTACT_NAME_SEARCH_TYPE, CONTAINER_ID_SEARCH_TYPE].includes(searchType),
    [searchType]
  )
  const [fetchAccountOptions, fetchAccountOptionsQueryState] = useMemo(
    () => (isFocusedSearch ? lazyGetAccountsByFocusedSuggestQuery : lazyGetAccountsBySuggestQuery),
    [isFocusedSearch, lazyGetAccountsByFocusedSuggestQuery, lazyGetAccountsBySuggestQuery]
  )

  const searchAccounts = useCallback(
    ({ searchInputValue, includeDeactivated }) => {
      if (!searchInputValue) return Promise.resolve()
      const payload = {
        ...(isFocusedSearch
          ? { search: searchInputValue, type: SEARCH_TYPE_VALUE_TO_SEARCH_TYPE_BE[searchType] }
          : { search: searchInputValue }),
        includeDeactivated,
      }
      return fetchAccountOptions(payload)
    },
    [searchType, isFocusedSearch, fetchAccountOptions]
  )

  return [searchAccounts, fetchAccountOptionsQueryState]
}
