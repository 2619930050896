import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'

import Box from '@mui/material/Box'

import { get } from 'utils/lodash'
import { combineAddressInfo } from 'utils/helper'
import { getActiveDeactivatedLocations } from 'utils/location'
import { canSeeService } from 'data/permissions/permissionsSelectors'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import TabMainLayout from '../layout/TabMainLayout'
import TabLeftLayout from '../layout/TabLeftLayout'
import TabRightLayout from '../layout/TabRightLayout'
import Location from '../content/location'
import { ConfiguredServices, WorkOrders } from '../tables'
import HoldsAccordion from '../content/holds/HoldsAccordion'
import Routing from '../content/routing/Routing'

const { SERVICES_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const ServicesTab = ({ selectedTab = '', customerDetails = {}, onLocationChange }) => {
  const accountId = get(customerDetails, 'id')
  const [isServicesTabReady, setIsServicesTabReady] = useState(false)

  const seeService = useSelector(canSeeService, shallowEqual)
  const selectedLocationId = useSelector(state => get(state, 'CustomerDetails.selectedLocationId', ''))
  // Filter all valid locations with address
  const { activeLocations } = getActiveDeactivatedLocations(customerDetails)
  const activeLocationsCount = activeLocations.length

  const getLocationOptionLabel = location => {
    const addressName = get(location, 'address.addressName')
    const completeAddress = combineAddressInfo(get(location, 'address', {}))
    if (!addressName) return completeAddress
    return `${addressName}: ${completeAddress}`
  }

  useEffect(() => {
    if (selectedTab === SERVICES_TAB_ID) {
      setIsServicesTabReady(true)
    }
  }, [selectedTab])

  return (
    isServicesTabReady && (
      <TabMainLayout>
        <TabLeftLayout>
          <HHTextField
            sx={{ mt: '2px', '& .MuiInputBase-root.MuiOutlinedInput-root ': { height: '48px' } }}
            SelectProps={{
              MenuProps: {
                disableScrollLock: true,
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              },
            }}
            deprecatedLabel={false}
            label={T.SERVICE_LOCATION}
            fullWidth
            select
            value={[selectedLocationId]}
            onChange={event => onLocationChange(event.target.value)}
          >
            {activeLocationsCount === 0 && <SelectItem value="">No active location</SelectItem>}
            {activeLocationsCount > 1 && <SelectItem value="">All locations</SelectItem>}
            {activeLocations.map(location => (
              <SelectItem value={location.id}>{getLocationOptionLabel(location)}</SelectItem>
            ))}
          </HHTextField>
          <Box mt={2}>
            <HoldsAccordion accountId={accountId} />
          </Box>
          {selectedLocationId && (
            <Box mt={2}>
              <Location customerDetails={customerDetails} onLocationChange={onLocationChange} locationId={selectedLocationId} />
            </Box>
          )}
        </TabLeftLayout>
        <TabRightLayout sx={{ flex: '1 1 auto' }}>
          {seeService && (
            <>
              <ConfiguredServices accountId={accountId} locationId={selectedLocationId} />
              <Box mt={2}>
                <Routing accountId={accountId} activeLocations={activeLocations} />
              </Box>
              <Box mt={2} minWidth="560px">
                <WorkOrders accountId={accountId} locationId={selectedLocationId} />
              </Box>
            </>
          )}
        </TabRightLayout>
      </TabMainLayout>
    )
  )
}

ServicesTab.propTypes = {
  selectedTab: PropTypes.string,
  customerDetails: PropTypes.object,
  onLocationChange: PropTypes.func.isRequired,
}

export default ServicesTab
