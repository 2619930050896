import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { get } from 'utils/lodash'
import {
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import LocationMapImage from 'components/customer-details/content/location/LocationMapImage'
import AccountDetailsListItem from './AccountDetailsListItem'

const AccountDetails = ({ isViewOnly = false, accountData = {} }) => {
  const dispatch = useDispatch()
  const billingAddress = get(accountData, 'billingAddress', {})
  const accountId = get(accountData, 'id')
  const { id, latitude, longitude, line1, unitNumber, city, state, zipCode } = billingAddress

  return (
    <Grid container flexWrap={{ xs: 'wrap', md: 'nowrap' }} columnGap={2} rowGap={2}>
      <Grid item xs={12} md>
        <LocationMapImage
          width="100%"
          height={200}
          latitude={latitude}
          longitude={longitude}
          containerId={`${id}-customer-search-map-container`}
          dynamicWidth
          showInvalidAlert={false}
          showPlaceholder
        />
      </Grid>
      <Grid item xs={12} md>
        <Grid container rowGap={1.5}>
          <Grid item xs={12}>
            <AccountDetailsListItem
              primaryText={capitalize(T.STREET_ADDRESS)}
              secondaryText={`${[line1, unitNumber].filter(Boolean).join(', ')}`}
            />
          </Grid>
          <Grid container item spacing={3}>
            <Grid item>
              <AccountDetailsListItem primaryText={T.CITY} secondaryText={city} />
            </Grid>
            <Grid item>
              <AccountDetailsListItem primaryText={T.STATE} secondaryText={state} />
            </Grid>
            <Grid item>
              <AccountDetailsListItem primaryText={capitalize(T.ZIP_CODE)} secondaryText={zipCode} />
            </Grid>
          </Grid>
          <Grid mt={1.5} container item xs={12} spacing={0.5} alignItems="center">
            <InteractiveTagsList
              isViewOnly={isViewOnly}
              data={{
                accountId,
                name: get(accountData, 'accountName'),
                tagDetails: { forAccount: get(accountData, 'tags', []) },
              }}
              disableLocation
              disableService
              onSave={(data, closeCallback) => {
                closeCallback()
                dispatch(
                  api.util.invalidateTags([
                    CACHE_TAG_ACCOUNT_LIST,
                    { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
                    CACHE_TAG_ACCOUNT_GROUP_DETAILS,
                    CACHE_TAG_AGING_GROUP_DETAILS,
                    CACHE_TAG_SUSPENSION_GROUP_DETAILS,
                  ])
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

AccountDetails.propTypes = {
  isViewOnly: PropTypes.bool,
  accountData: PropTypes.object,
}

export default AccountDetails
