import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { get, noop } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTE_STOPS_UNASSIGNED, ROUTE_STOPS_UNASSIGNED_DOT_LAYER, ROUTE_STOPS_UNASSIGNED_LAYER } from 'slices/route/routeSlice'
import {
  addSelectedMoveFromRows,
  addSelectedMoveToRows,
  selectBulkAssignFetchId,
  selectBulkAssignFitBounds,
  selectBulkAssignFromRouteStopMarkers,
  selectBulkAssignMapLayer,
  selectBulkAssignRoutesById,
  selectBulkAssignToRouteSequenceLine,
  selectBulkAssignToRouteStopMarkers,
  selectIsOpenBulkAssignStopsDialog,
} from 'slices/route/bulkAssignStopsSlice'
import { useFormContext } from 'react-hook-form'
import { MIDDLE_LAYER, removeLayerIfExist, removeSourceIfExist, TOP_LAYER } from 'utils/map'
import Proptypes from 'prop-types'
import ToggleRouteLayers from 'components/route-manager/BulkActions/BulkAssignStopsDialog/ToggleRouteLayers'
import { MapContext } from 'providers/MapProvider'
import {
  fitBoundsMoveBetweenRoutesLayers,
  removeLayersAndSources,
  toggleMoveBetweenRoutesLayers,
} from 'components/route-manager/BulkActions/settings'
import CommonSelectStopsToMoveMap from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/CommonSelectStopsToMoveMap'
import useRecreateRouteSourcesAndLayers from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useRecreateRouteSourcesAndLayers'
import useMapRefInitDispose from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useMapInitDispose'
import useUpdateRouteLayer from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useUpdateRouteLayer'
import useCustomSelectBoxMapbox from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useCustomSelectBoxMapbox'

const SelectStopsToMoveMap = ({ routes }) => {
  const map = useContext(MapContext)
  const routesById = useSelector(selectBulkAssignRoutesById)
  const [layersCreated, setLayersCreated] = useState(false)
  const mapContainer = useRef(null)
  const { watch } = useFormContext()
  const dispatch = useDispatch()
  const open = useSelector(selectIsOpenBulkAssignStopsDialog)
  const moveFromRouteStopMarkers = useSelector(selectBulkAssignFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkAssignToRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkAssignToRouteSequenceLine)
  const mapLayer = useSelector(selectBulkAssignMapLayer)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const fitBounds = useSelector(selectBulkAssignFitBounds)
  const recreateRouteSourcesAndLayers = useRecreateRouteSourcesAndLayers(map, routesById)
  const { isMapLoaded } = useMapRefInitDispose({ map, mapContainer, open })
  const fetchId = useSelector(selectBulkAssignFetchId)
  const updateRouteLayer = useUpdateRouteLayer(map)

  const getRouteIdFromSource = useCallback(
    source => {
      if (source.includes(moveToRouteId)) {
        return moveToRouteId
      }
      if (source.includes(moveFromRouteId)) {
        return moveFromRouteId
      }
      return null
    },
    [moveFromRouteId, moveToRouteId]
  )

  const onSelectFeatures = useCallback(
    features => {
      const selectedFromMarkerIds = []
      const selectedToMarkersIds = []
      features.forEach(feature => {
        const routeId = get(feature, 'properties.routeId')
        const stopId = get(feature, 'properties.id')
        const source = get(feature, 'source', '')
        const sourceRouteId = getRouteIdFromSource(source)
        if (routeId === moveFromRouteId && sourceRouteId === moveFromRouteId) selectedFromMarkerIds.push(stopId)
        if (routeId === moveFromRouteId && sourceRouteId === moveToRouteId) selectedToMarkersIds.push(stopId)
      })
      dispatch(addSelectedMoveFromRows(selectedFromMarkerIds))
      dispatch(addSelectedMoveToRows(selectedToMarkersIds))
    },
    [moveFromRouteId, moveToRouteId]
  )

  useCustomSelectBoxMapbox({ isMapLoaded, onSelectFeatures, moveFromRouteId, moveToRouteId })

  const recreateSourcesAndLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !routes.length) {
      return null
    }
    setLayersCreated(false)
    const mapCurrentRef = map.current
    removeLayerIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED_DOT_LAYER)
    removeLayerIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED_LAYER)
    removeSourceIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED)
    routes.forEach(route => {
      removeLayersAndSources(map.current, route.id)
    })
    if (moveFromRouteId)
      recreateRouteSourcesAndLayers(moveFromRouteId, { features: moveFromRouteStopMarkers, hasLineString: false }, TOP_LAYER)
    if (moveToRouteId)
      recreateRouteSourcesAndLayers(
        moveToRouteId,
        { sequenceData: moveToRouteSequenceLine, features: moveToRouteStopMarkers },
        MIDDLE_LAYER
      )
    setLayersCreated(true)
  }, [map, isMapLoaded, moveFromRouteId, moveToRouteId, routes, moveFromRouteStopMarkers, moveToRouteStopMarkers, moveToRouteSequenceLine])

  const updateLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    if (moveFromRouteId) updateRouteLayer(moveFromRouteId, { features: moveFromRouteStopMarkers, hasLineString: false })
    if (moveToRouteId) updateRouteLayer(moveToRouteId, { sequenceData: moveToRouteSequenceLine, features: moveToRouteStopMarkers })
    toggleMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, moveFromRouteId, moveToRouteId)
  }, [
    fetchId,
    map,
    isMapLoaded,
    layersCreated,
    moveFromRouteId,
    moveToRouteId,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveToRouteSequenceLine,
    updateRouteLayer,
  ])

  const fitBoundsToLayers = useCallback(() => {
    if (!map || !map.current) return noop
    if (!isMapLoaded || !layersCreated) {
      return noop
    }
    const mapCurrentRef = map.current
    const moveFromCoords = Array.isArray(moveFromRouteStopMarkers)
      ? moveFromRouteStopMarkers.map(marker => get(marker, 'geometry.coordinates', []))
      : []
    const fromSequenceLine = { geometry: { coordinates: moveFromCoords } }
    fitBoundsMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, fromSequenceLine, moveToRouteSequenceLine)
  }, [map, isMapLoaded, layersCreated, moveToRouteSequenceLine, moveFromRouteStopMarkers])

  useEffect(() => {
    recreateSourcesAndLayers()
  }, [fetchId, isMapLoaded, moveFromRouteId, moveToRouteId, routes])

  useEffect(() => {
    updateLayers()
  }, [fetchId, moveToRouteId, moveFromRouteId, layersCreated, moveFromRouteStopMarkers, moveToRouteStopMarkers, moveToRouteSequenceLine])

  useEffect(() => {
    if (fitBounds) {
      fitBoundsToLayers()
    }
  }, [fitBounds, moveToRouteSequenceLine, moveFromRouteStopMarkers, layersCreated])

  return <CommonSelectStopsToMoveMap mapContainer={mapContainer} ToggleComponent={<ToggleRouteLayers map={map} />} />
}

SelectStopsToMoveMap.propTypes = {
  routes: Proptypes.array.isRequired,
}

export default memo(SelectStopsToMoveMap)
