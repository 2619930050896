import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import AutoComplete from 'components/common/AutoComplete'

import T from 'T'
import { getPricingLabel } from 'components/pricing/pricingMethods'
import { PRICING_NEW_VIEW_TYPE } from 'components/pricing/settings'

import { get } from 'utils/lodash'

const PricingPeriodAutoComplete = ({
  viewType = '',
  isRecurringService = false,
  globalFilterOptions = {},
  checkedPricingPeriod = [],
  handleCheckboxValue = noop,
  backFlag = false,
}) => {
  const pricingPeriod = get(globalFilterOptions, 'pricingPeriod', [])
  return (
    <div className="pricing-period left-elements-width mt15">
      <div className="service-label">
        {T.PRICING_PERIOD}
        <span className="red ml1">*</span>
      </div>

      <AutoComplete
        multiple={false}
        backFlag={backFlag}
        isHandleCheckbox
        maxHeight={150}
        metaData={
          viewType === PRICING_NEW_VIEW_TYPE.NEW_SERVICE && isRecurringService
            ? pricingPeriod.filter(period => period?.periodInterval !== 'Instance')
            : pricingPeriod
        }
        label={T.PRICING_PERIOD}
        getByLabelText={option => getPricingLabel(option)}
        handleCheckboxValue={handleCheckboxValue}
        checkedRecords={checkedPricingPeriod}
        getPlaceholder={checkedPricingPeriod.length ? '' : `Select ${T.PRICING_PERIOD}`}
      />
    </div>
  )
}

PricingPeriodAutoComplete.propTypes = {
  viewType: PropTypes.string,
  isRecurringService: PropTypes.bool,
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedPricingPeriod: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default PricingPeriodAutoComplete
