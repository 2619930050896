import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useSelector, shallowEqual } from 'react-redux'
import { TableRow, TableCell, TableBody as TBody, Radio, Tooltip, Typography } from '@mui/material'
import ExclamationCircle from '@mui/icons-material/ErrorOutline'

import {
  PERMISSION_MAPPINGS,
  ALLOWED_PERMISSIONS,
  PERMISSION_TOOLTIP_VALUES,
  PERMISSION_RECORDS,
  NO_ACCESS_PERMISSION,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  FULL_PERMISSION,
} from 'settings/constants/roles'

import { canUpdateSettings, canUpdateUsers, canUpdatePermissions } from 'data/permissions/permissionsSelectors'

import T from 'T'

const PermissionScreenTableBody = ({ roleState = {}, setRoleState = noop }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)

  const handleChange = (event, type) => {
    if (updateSettings && updatePermissions && updateUsers) {
      setRoleState({ [`${PERMISSION_MAPPINGS[type]}`]: Number(event.target.value), footerFlag: true })
    }
  }

  return (
    <TBody
      sx={{
        '& .MuiTableCell-body': {
          borderBottom: 'unset',
          padding: '4px 0',
          '& .MuiRadio-root': {
            padding: 0,
          },
        },
      }}
    >
      <TableRow>
        <Typography variant="h5" mt={2} mb={0.5}>
          {T.ROAD}
        </Typography>
      </TableRow>

      {Children.toArray(
        PERMISSION_RECORDS.map((value, index) => {
          return (
            <TableRow hover>
              <TableCell>
                <TableCell sx={{ display: 'flex', p: '6px 0' }}>
                  <Typography variant="body1">{value}</Typography>

                  <Tooltip title={PERMISSION_TOOLTIP_VALUES[index]}>
                    <ExclamationCircle fontSize="small" sx={{ ml: 1, pl: 0.5, cursor: 'pointer' }} />
                  </Tooltip>
                </TableCell>
              </TableCell>

              <TableCell align="center">
                {ALLOWED_PERMISSIONS[value].includes(NO_ACCESS_PERMISSION) && (
                  <Radio
                    checked={[NO_ACCESS_PERMISSION, 0].includes(roleState[PERMISSION_MAPPINGS[value]])}
                    onChange={event => handleChange(event, value)}
                    value={NO_ACCESS_PERMISSION}
                    name="radio-buttons"
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {ALLOWED_PERMISSIONS[value].includes(READ_PERMISSION) && (
                  <Radio
                    checked={roleState[PERMISSION_MAPPINGS[value]] === READ_PERMISSION}
                    onChange={event => handleChange(event, value)}
                    value={READ_PERMISSION}
                    name="radio-buttons"
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {ALLOWED_PERMISSIONS[value].includes(UPDATE_PERMISSION) && (
                  <Radio
                    checked={roleState[PERMISSION_MAPPINGS[value]] === UPDATE_PERMISSION}
                    onChange={event => handleChange(event, value)}
                    value={UPDATE_PERMISSION}
                    name="radio-buttons"
                    size="small"
                  />
                )}
              </TableCell>
              <TableCell align="center">
                {ALLOWED_PERMISSIONS[value].includes(FULL_PERMISSION) && (
                  <Radio
                    checked={roleState[PERMISSION_MAPPINGS[value]] === FULL_PERMISSION}
                    onChange={event => handleChange(event, value)}
                    value={FULL_PERMISSION}
                    name="radio-buttons"
                    size="small"
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })
      )}
    </TBody>
  )
}

PermissionScreenTableBody.propTypes = {
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
}

export default PermissionScreenTableBody
