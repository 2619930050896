import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { RIGHT_LAYOUT_WIDTH, LAYOUT_HEIGHT } from './settings'

const TabRightLayout = ({ children, sx }) => {
  const theme = useTheme()
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box
      py={3}
      pr={isTabletorMobile ? 4 : 6.5}
      pl={isTabletorMobile ? 4 : 2}
      minWidth={isTabletorMobile ? '100%' : RIGHT_LAYOUT_WIDTH}
      maxWidth={isTabletorMobile ? '100%' : RIGHT_LAYOUT_WIDTH}
      height={isTabletorMobile ? '100%' : LAYOUT_HEIGHT}
      overflow="auto"
      sx={sx}
    >
      {children}
    </Box>
  )
}

TabRightLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
}

export default TabRightLayout
