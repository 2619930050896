import React from 'react'
import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { HHFormAutocompleteField } from '../../../form-fields/v5'
import T from '../../../../T'
import ServiceActionTypeChip from '../../../services/ServiceActionTypeChip'

const ActionsAutocompleteFormField = ({ sx, control, options, name }) => {
  const renderTags = (tags, getTagProps) =>
    tags.map((item, index) => <ServiceActionTypeChip action={item.actionType} size="small" {...getTagProps({ index })} />)

  const renderOption = (props, option) => (
    <MenuItem {...props}>
      <ServiceActionTypeChip action={option.actionType} size="small" />
    </MenuItem>
  )

  const getOptionLabel = x => get(x, 'actionType', '')

  return (
    <HHFormAutocompleteField
      name={name}
      getOptionLabel={getOptionLabel}
      sx={sx}
      fullWidth
      options={options}
      control={control}
      label={T.ACTION}
      deprecatedLabel={false}
      renderTags={renderTags}
      renderOption={renderOption}
    />
  )
}

ActionsAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
}

export default ActionsAutocompleteFormField
