import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Menu, MenuItem, ListItemIcon, ListItemText, useTheme, useMediaQuery } from '@mui/material'
import { EyeOff } from '@styled-icons/heroicons-outline/EyeOff'
import { Eye } from '@styled-icons/heroicons-outline/Eye'
import { ReactComponent as ArrowUp1To9Icon } from 'assets/ArrowUp1To9.svg'
import FilterListIcon from '@mui/icons-material/FilterList'

import CommonSwitch from 'components/common/CommonSwitch'
import FiltersMenu from 'components/locations/filters/FiltersMenu'
import SortByFilterMenuField from 'components/customers/accounts/filters/SortByFilter/SortByFilterMenuField'

const AccountsActionMenu = ({ anchorEl, onSortByChange, onClose, showSortByControl = true, showToggleNumericControl = true }) => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null)
  const { watch, setValue, control } = useFormContext()
  const withDeactivated = watch('withDeactivated')
  const withDigits = watch('withDigits')

  const handleDeactivatedClick = () => {
    setValue('withDeactivated', !withDeactivated)
    onClose()
  }

  const handleShowNumericClick = () => {
    setValue('withDigits', !withDigits)
    onClose()
  }

  const handleFiltersClick = event => {
    event.stopPropagation()
    setFiltersAnchorEl(event.currentTarget)
  }

  const handleFiltersClose = () => {
    setFiltersAnchorEl(null)
  }

  return (
    <>
      <Menu
        slotProps={{ paper: { variant: 'outlined', sx: { minWidth: 250 } } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
        keepMounted
      >
        <MenuItem onClick={handleDeactivatedClick}>
          <ListItemIcon sx={{ mr: 1 }}>{withDeactivated ? <EyeOff width={24} height={24} /> : <Eye width={24} height={24} />}</ListItemIcon>
          <ListItemText>{withDeactivated ? 'Hide deactivated' : 'Show deactivated'}</ListItemText>
        </MenuItem>

        {showToggleNumericControl && (
          <MenuItem onClick={handleShowNumericClick}>
            <ListItemIcon sx={{ mr: 1 }}>
              <ArrowUp1To9Icon width={24} height={24} />
            </ListItemIcon>
            <ListItemText>Show numeric (0-9)</ListItemText>
            <CommonSwitch isChecked={withDigits} />
          </MenuItem>
        )}

        <MenuItem onClick={handleFiltersClick} sx={{ ...(!isTabletOrMobile && { display: 'none' }) }}>
          <ListItemIcon>
            <FilterListIcon />
          </ListItemIcon>
          <ListItemText>Filters</ListItemText>
        </MenuItem>

        <SortByFilterMenuField
          sx={{ ...((!isTabletOrMobile || !showSortByControl) && { display: 'none' }) }}
          name="sortBy"
          control={control}
          onChange={onSortByChange}
          isMenuItemVariant
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      </Menu>
      <FiltersMenu
        open={Boolean(filtersAnchorEl)}
        anchorEl={filtersAnchorEl}
        onClose={handleFiltersClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  )
}

AccountsActionMenu.propTypes = {
  showToggleNumericControl: PropTypes.bool,
  showSortByControl: PropTypes.bool,
  anchorEl: PropTypes.object,
  onSortByChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AccountsActionMenu
