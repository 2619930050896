import React, { useEffect, Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Popover, List } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import NoteListItem from 'components/notes/common/NoteListItem'
import NoteSkeleton from 'components/notes/common/NoteSkeleton'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'

const ViewNotesPopover = ({ anchorEl, id, getCachedData = false, useLazyGetAllNotesQuery, onClose }) => {
  const isOpen = Boolean(anchorEl)
  const [getAllNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllNotesQuery()
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const hasNotes = notes.length > 0

  useEffect(() => {
    if (isOpen && id) {
      getAllNotes({ id }, getCachedData).unwrap().catch(handleError)
    }
  }, [isOpen, id])

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
      slotProps={{ paper: { sx: { width: 'max-content' } } }}
    >
      {isGetAllNotesLoading && (
        <Box minWidth={300}>
          <NoteSkeleton rows={1} showActions={false} />
        </Box>
      )}
      {!isGetAllNotesLoading && (
        <>
          {!hasNotes && <HHSectionPlaceholder title="No notes" />}
          {hasNotes && (
            <List sx={{ py: 0 }}>
              {Children.toArray(
                notes.map((note, index) => (
                  <NoteListItem
                    note={note}
                    showNoteVisibilityOnMobileIcon={false}
                    showDivider={notes.length - 1 !== index}
                    showSecondaryActions={false}
                  />
                ))
              )}
            </List>
          )}
        </>
      )}
    </Popover>
  )
}

ViewNotesPopover.propTypes = {
  anchorEl: PropTypes.object,
  id: PropTypes.string.isRequired,
  getCachedData: PropTypes.bool,
  useLazyGetAllNotesQuery: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export default memo(ViewNotesPopover)
