import { takeEvery, all, call } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions } from '../actions/calls'

import endpoint from './endpoint'

export const getCallById = ({ body }) => doPost({ url: endpoint.account.callById, body, showLoader: true })

export function* getCallByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCallById, { body })
    const { call: callData } = res
    if (typeof onSuccess === 'function') {
      onSuccess(callData)
    }
  } catch (err) {
    onSuccess(null)
  }
}

export default function* Calls() {
  yield all([takeEvery(actions.GET.CALL_BY_ID, getCallByIdSaga)])
}
