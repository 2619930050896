import React, { useReducer, useRef, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactCalendar from 'react-calendar'
import cloneDeep from 'lodash/cloneDeep'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import { putIsLoading } from 'middleware/actions/response'

import { useSaveUpdateEventCSServiceMutation } from 'api/configured-service/saveUpdateEventCSService'
import { useDeleteEventCSServiceMutation } from 'api/configured-service/deleteEventCSService'

import { handleError } from 'utils/error'
import { memo } from 'utils/react'
import { WORK_ORDER_STATUS } from 'settings/constants/service'
import { INVOICE_STATUS } from 'settings/constants/invoice'

import T from 'T'
import Constants from 'Constants'
import CommonDrawer from 'components/common/CommonDrawer'
import FooterButton from 'components/buttons/FooterButton'
import CommonSelect from 'components/common/CommonSelect'
import CommonTextLabel from 'components/common/CommonTextLabel'
import CommonIconSelectExtended from 'components/common/CommonIconSelectExtended'

import { get } from 'utils/lodash'

import 'react-calendar/dist/Calendar.css'
import './style.scss'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import { Divider, Stack } from '@mui/material'
import HHDialogTitle from 'components/common/HHDialogTitle'
import api from '../../../../api'
import { CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_ROUTES } from '../../../../api/cacheTagTypes'

const { MEDIA_SERVER } = Constants
const { SERVICED } = WORK_ORDER_STATUS
const { INVOICED } = INVOICE_STATUS

const MONTHS = [
  { key: 0, value: 'January' },
  { key: 1, value: 'February' },
  { key: 2, value: 'March' },
  { key: 3, value: 'April' },
  { key: 4, value: 'May' },
  { key: 5, value: 'June' },
  { key: 6, value: 'July' },
  { key: 7, value: 'August' },
  { key: 8, value: 'September' },
  { key: 9, value: 'October' },
  { key: 10, value: 'November' },
  { key: 11, value: 'December' },
]

const YEARS = [2020, 2021, 2022, 2023, 2024, 2025, 2026]
const EVENT_TYPES = {
  ADD_EVENT: 'add',
  REMOVE_EVENT: 'remove',
}
const { ADD_EVENT, REMOVE_EVENT } = EVENT_TYPES

const EventsDrawer = ({ isOpenDrawer = false, configuredServiceId = '', eventInstances = [], onClose, onRefetch }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    route: '',
    serviceWindows: false,
    isRouteNote: false,
    routeNote: '',
    events: null,
    eventType: ADD_EVENT,
    actionList: [],
    selectedAction: '',
    currentSelection: '',
    selectedInstance: '',
  })

  const { events, eventType, month, year, isRouteNote, routeNote, actionList, selectedAction, currentSelection, selectedInstance } = state

  const existingEvents = eventInstances.map(event => moment(event.date).format('YYYY-MM-DD'))

  const getEventClass = value => (existingEvents.includes(moment(value).format('YYYY-MM-DD')) ? REMOVE_EVENT : ADD_EVENT)

  const [saveUpdateEventCSService] = useSaveUpdateEventCSServiceMutation()
  const [deleteEventCSService] = useDeleteEventCSServiceMutation()

  const onClickDay = value => {
    setState({
      eventType: getEventClass(value),
      currentSelection: value,
      selectedInstance: eventInstances.find(data => data?.date === moment(value).format('YYYY-MM-DD')),
    })
  }

  const serviceActionAllMeta = get(globalFilterOptions, 'serviceAction', []).map(meta => ({
    type: meta.actionType,
    name: meta.actionName,
    id: meta.id,
  }))

  const handleChange = (key, value) => {
    if (['month', 'year'].includes(key)) {
      const selectedMonth = key === 'month' ? value : month
      const selectedYear = key === 'year' ? value : year
      const newDate = new Date(selectedYear, selectedMonth, 1)
      setState({ [key]: value, events: newDate })
      onClickDay(newDate)
      return
    }
    setState({ [key]: value })
  }

  const markEvents = ({ date, view }) => {
    if (view !== 'month') {
      return ''
    }

    return (
      <div className={`${existingEvents.includes(moment(date).format('YYYY-MM-DD')) ? '' : 'visibility-hidden'}`}>
        {Children.toArray(
          existingEvents.map((event, index) => {
            if (event === moment(date).format('YYYY-MM-DD')) {
              return <img className={`event-exists ${index === 0 ? '' : 'ml1'}`} src={`${MEDIA_SERVER}Dot.svg`} alt="" />
            }
            // need to check this
            return <img alt="" />
          })
        )}
      </div>
    )
  }

  const handleDateChange = value => {
    setState({
      month: new Date(value).getMonth(),
      year: new Date(value).getFullYear(),
      events: value,
    })
  }

  const onSaveEvent = () => {
    toast.success(T.CALENDAR_SAVE_MESSAGE, {
      hideProgressBar: false,
      autoClose: 2000,
    })
    setTimeout(() => {
      dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_ROUTES]))
    }, 2000)
  }

  const addEvent = () => {
    const payload = {
      configuredServiceId,
      note: routeNote,
      schedule: {
        serviceEvent: {
          actionDate: moment(currentSelection).format('YYYY-MM-DD'),
          actionType: selectedAction,
        },
      },
    }
    dispatch(putIsLoading(true))
    saveUpdateEventCSService(payload)
      .unwrap()
      .then(() => {
        onSaveEvent()
        onClose()
        onRefetch()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const removeEvent = () => {
    const payload = {
      workOrderId: get(selectedInstance, 'workOrderId', ''),
    }
    dispatch(putIsLoading(true))
    deleteEventCSService(payload)
      .unwrap()
      .then(() => {
        onSaveEvent()
        onClose()
        onRefetch()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const onProceed = () => {
    if (eventType === ADD_EVENT) {
      addEvent()
      return
    }
    removeEvent()
  }

  const isProceedBtnDisabled = () => {
    // If no day selected
    if (!currentSelection) {
      return { disabled: true, message: '' }
    }

    // Add case
    if (eventType === ADD_EVENT) {
      return { disabled: !selectedAction, message: selectedAction ? '' : T.REQUIRED_FIELD_MSG }
    }

    // remove action
    if (eventType === REMOVE_EVENT) {
      const workOrderStatus = get(selectedInstance, 'workOrderStatus')
      const isServicedOrInvoiced = workOrderStatus === SERVICED || get(selectedInstance, 'invoiceStatus') === INVOICED
      return {
        disabled: !workOrderStatus || isServicedOrInvoiced,
        message: isServicedOrInvoiced ? T.CANT_REMOVE_PAST_SERVICED_INVOICED_EVENTS : '',
      }
    }

    return { disabled: false, message: '' }
  }

  const { disabled, message } = isProceedBtnDisabled()

  useEffect(() => {
    setState({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      route: '',
      serviceWindows: false,
      isRouteNote: false,
      routeNote: '',
      events: null,
      eventType: ADD_EVENT,
      actionList: cloneDeep(serviceActionAllMeta),
      selectedAction: '',
      currentSelection: '',
    })
  }, [isOpenDrawer])

  useEffect(() => {
    dispatch(getGlobalFilterOptions())
  }, [])

  return (
    <CommonDrawer isOpen={isOpenDrawer} className="events-details-drawer events-drawer" onChange={onClose}>
      <section>
        <HHDialogTitle
          title={T.ADD_REMOVE_EVENT}
          icon={<img className="header-icon" src={`${MEDIA_SERVER}CalendarCheck.svg`} alt="" />}
          onClose={onClose}
          TitleTypographyProps={{
            variant: 'h6',
          }}
        />
        <Divider />
        <div className="content">
          <div className="events-content transparent-scroll">
            <div className="calendar calendar-margin">
              <Box mb={2} className="cal-actions">
                <CommonSelect
                  className="month-dropdown month-event"
                  value={month}
                  options={MONTHS}
                  onChange={e => handleChange('month', e.target.value)}
                />
                <CommonSelect
                  className="ml2 year-dropdown month-event"
                  value={year}
                  options={YEARS}
                  onChange={e => handleChange('year', e.target.value)}
                />
              </Box>
              <div key={events ? events.toISOString() : ''}>
                <ReactCalendar
                  inputRef={inputRef}
                  showNavigation={false}
                  onChange={handleDateChange}
                  value={events}
                  calendarType="US"
                  tileContent={markEvents}
                  onClickDay={onClickDay}
                />
              </div>
            </div>
            <div className="form common-margin">
              <div className="separator" />

              <Box mt={1}>
                <CommonTextLabel title={T.ACTION} isRequired={eventType === ADD_EVENT} />
                <CommonIconSelectExtended
                  records={actionList}
                  selectedRecord={{ name: selectedAction }}
                  placeholder={`Select ${T.ACTION}s`}
                  type="action"
                  onChange={(isChanged, value) => {
                    console.log(isChanged, value)
                    if (isChanged) {
                      setState({
                        actionList: serviceActionAllMeta.filter(action => action.name.toLowerCase().indexOf(value) > -1),
                        selectedAction: value.name,
                      })
                    }
                  }}
                  onBlur={() => setState({ actionList: cloneDeep(serviceActionAllMeta) })}
                />
              </Box>
            </div>
          </div>
        </div>

        <Box position="fixed" className="footer common-margin bg-white">
          <Stack flexDirection="row" justifyContent="space-between" className={`${eventType}`}>
            <FooterButton
              leftButtonTitle={T.CANCEL}
              rightButtonTitle={eventType === ADD_EVENT ? 'Add service' : 'Remove service'}
              isProceedButtonMUIType={disabled}
              tooltipTitle={message}
              onClose={onClose}
              onProceed={onProceed}
              disabledProceed={disabled}
            />
          </Stack>
        </Box>
      </section>
    </CommonDrawer>
  )
}

EventsDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
  configuredServiceId: PropTypes.string,
  eventInstances: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
}

export default memo(EventsDrawer)
