/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Skeleton,
  Chip,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

import { get } from 'lodash'
import { formatDateToFEFormat } from 'utils/date'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

import { orange } from '@mui/material/colors'
import WorkOrderPOList from 'components/billing/create-invoices/PreviewChargeListDrawer/WorkOrderPOList'
import ChargesTableRow from '../../billing/create-invoices/PreviewChargeListDrawer/ChargesTableRow'
import { INVOICE_TYPE } from '../../../settings/constants/billing'

const { RECURRING, MANUAL } = INVOICE_TYPE

const PreviewCharges = ({ selectedPeriod = {}, invoiceDate, loadMoreRows, isLoadingFirstBatch, type = RECURRING }) => {
  const theme = useTheme()

  const docIdList = useSelector(state => state.createInvoicesSlice.previewChargeDrawer.docIdList, shallowEqual)
  const docIdToChargeMap = useSelector(state => state.createInvoicesSlice.previewChargeDrawer.docIdToChargeMap, shallowEqual)

  const docId = docIdList[0]
  const chargeMap = get(docIdToChargeMap, docId, {})
  const total = get(chargeMap, 'total')
  const locationListOfChargeList = get(chargeMap, 'locationListOfChargeList', [])
  const subtotalByLocation = get(chargeMap, 'subtotalByLocation')
  const isLoading = get(chargeMap, 'isLoading', true) || isLoadingFirstBatch
  const periodName = get(selectedPeriod, 'periodName')
  const isManualInvoice = type === MANUAL
  const parsedInvoiceDate = invoiceDate ? formatDateToFEFormat(invoiceDate) : '-'
  const title = isManualInvoice ? parsedInvoiceDate : periodName

  useEffect(() => {
    loadMoreRows({ startIndex: 0, stopIndex: 0 })
  }, [])

  return (
    <Accordion sx={{ boxShadow: 'none', border: `1px solid ${theme.palette.border.light}` }} expanded={!isLoading}>
      <AccordionSummary sx={{ '.MuiAccordionSummary-content': { my: 2 } }}>
        <Box width="100%">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h4" fontWeight={600}>
                {title}
              </Typography>
              {isManualInvoice && <Chip label={T.MANUAL} size="small" sx={{ backgroundColor: orange[100], ml: 1, borderRadius: 1 }} />}
            </Box>
            <Typography display="flex" alignItems="center" variant="h3" fontWeight={700}>
              {isLoading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={total} formatToDollars={false} />}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="end" mt={1}>
            <Typography variant="subtitle1" color="textSecondary">
              Invoice date:
            </Typography>
            <Typography ml={0.5} variant="subtitle1" fontWeight={400}>
              {parsedInvoiceDate}
            </Typography>
          </Box>
          {isLoading && <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        <Divider />
        <Box width="100%" overflow="auto">
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                px: 2,
                py: 0.5,
                ...theme.typography.body1,
              },
              [`& .${tableCellClasses.head}`]: {
                py: 1,
                overflow: 'hidden',
                whiteSpace: 'noWrap',
                textOverflow: 'ellipsis',
              },
              [`& .${tableCellClasses.body}`]: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'noWrap',
              },
            }}
          >
            <TableHead
              sx={{
                textTransform: 'uppercase',
                borderBottom: `1px solid ${theme.palette.border.grey}`,
                borderTop: `1px solid ${theme.palette.border.grey}`,
              }}
            >
              <TableCell>{T.SERVICE}</TableCell>
              <TableCell>{T.DATE}</TableCell>
              <TableCell>{T.PRICE}</TableCell>
              <TableCell>{T.QTY}</TableCell>
              <TableCell align="right">{T.AMOUNT}</TableCell>
            </TableHead>
            <TableBody>
              {locationListOfChargeList.map(chargeList => {
                const address = get(chargeList, `0.address`, '')
                const locationId = get(chargeList, `0.locationId`, '')
                const subtotal = subtotalByLocation[locationId]
                return (
                  <>
                    <TableRow
                      sx={{
                        height: 37,
                        backgroundColor: `${theme.palette.info.dark}0A`,
                        borderBottom: `1px solid ${theme.palette.border.grey}`,
                        borderTop: `1px solid ${theme.palette.border.grey}`,
                      }}
                    >
                      <TableCell colSpan={4}>{address}</TableCell>
                      <TableCell colSpan={1}>
                        <Typography align="right" fontWeight="bold">
                          <HHDisplayMoney value={subtotal} formatToDollars={false} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {chargeList.map((chargeItem, rowIndex) => {
                      const service = get(chargeItem, 'service')
                      const date = get(chargeItem, 'date')
                      const charge = get(chargeItem, 'charge')
                      const quantity = get(chargeItem, 'quantity')
                      const chargeTotal = get(chargeItem, 'total', 0)
                      const subChargeList = get(chargeItem, 'subChargeList', [])
                      const workOrders = get(chargeItem, 'workOrders', [])
                      return (
                        <>
                          <ChargesTableRow
                            key={`ChargeTableRow-${rowIndex}`}
                            service={service}
                            date={date}
                            charge={charge}
                            quantity={quantity}
                            total={chargeTotal}
                            isCalledFromBilling={false}
                          />
                          {subChargeList.map((subChargeItem, subRowIndex) => {
                            const subService = get(subChargeItem, 'service')
                            const subDate = get(subChargeItem, 'startDate')
                            const subCharge = get(subChargeItem, 'charge')
                            const subQuantity = get(subChargeItem, 'quantity')
                            return (
                              <ChargesTableRow
                                key={`ChargeTableRow-${rowIndex}-${subRowIndex}`}
                                service={subService}
                                date={subDate}
                                charge={subCharge}
                                quantity={subQuantity}
                                total={subCharge}
                                isSubCharge
                                isCalledFromBilling={false}
                              />
                            )
                          })}
                          <WorkOrderPOList workOrders={workOrders} />
                        </>
                      )
                    })}
                  </>
                )
              })}
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" fontWeight="700">
                    Total
                  </Typography>
                </TableCell>
                <TableCell colSpan={3} />
                <TableCell>
                  <Typography align="right" color="textPrimary" fontWeight="700">
                    <HHDisplayMoney value={total} formatToDollars={false} />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

PreviewCharges.propTypes = {
  selectedPeriod: PropTypes.string,
  invoiceDate: PropTypes.string,
  isLoadingFirstBatch: PropTypes.bool,
  loadMoreRows: PropTypes.func.isRequired,
  type: PropTypes.oneOf([RECURRING, MANUAL]),
}

export default PreviewCharges
