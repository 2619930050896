import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerCallById: build.query({
      query: body => ({ url: '/api/account/call/view', method: 'POST', body }),
    }),
  }),
})

export const { useLazyGetCustomerCallByIdQuery } = extendedApi
