import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import { DialogContent, Box, Grid, useMediaQuery, useTheme, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { CACHE_TAG_ACCOUNT_INVOICE_LIST } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { FORM_MODEL } from './settings'
import AccountsDataGrid from './AccountsDataGrid'
import InvoicesDataGrid from './InvoicesDataGrid'
import { useBulkResendUnpaidInvoices } from './useBulkResendUnpaidInvoices'

const BulkResendUnpaidInvoices = ({ isOpen = false, groupId, existingAccounts = [], onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [sendInvoiceList, { isLoading }] = useSendInvoiceListMutation()
  const useBulkResendUnpaidInvoicesMutation = useBulkResendUnpaidInvoices(groupId)
  const [bulkResendUnpaidInvoices, { isLoading: isBulkResendLoading }] = useBulkResendUnpaidInvoicesMutation()

  const form = useForm({ defaultValues: { ...FORM_MODEL } })
  const { setValue, watch, reset, handleSubmit } = form

  const invoicesSelectionModel = watch('invoicesSelectionModel')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const filteredAccounts = useMemo(
    () => existingAccounts.filter(({ unpaidInvoiceCount = 0 }) => unpaidInvoiceCount > 0),
    [existingAccounts]
  )

  const totalAccounts = useMemo(() => filteredAccounts.length, [filteredAccounts])

  const handleOpenResendToAllConfirmDialog = () => setIsConfirmDialogOpen(true)

  const handleCloseResendToAllConfirmDialog = () => {
    setIsConfirmDialogOpen(false)
    onClose()
  }

  const handleSuccess = closeDialog => {
    toast.success(T.SEND_INVOICE_SUCCESS)
    if (closeDialog) {
      onClose()
    }
  }

  const handleResend = invoiceNumbers => {
    sendInvoiceList({ invoiceNumbers })
      .unwrap()
      .then(() => handleSuccess())
      .catch(handleError)
      .finally(() => setValue('clickedAccountId', ''))
  }

  const handleResendSelectedInvoices = async data => {
    handleResend(get(data, 'invoicesSelectionModel', []))
    setValue('invoicesSelectionModel', [])
  }

  const handleResendToAll = () => {
    bulkResendUnpaidInvoices({ accountGroupId: groupId })
      .unwrap()
      .then(() => handleSuccess(true))
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen && totalAccounts > 0) {
      dispatch(api.util.invalidateTags([CACHE_TAG_ACCOUNT_INVOICE_LIST]))
      reset({ ...FORM_MODEL, accountsSelectionModel: [get(filteredAccounts, '[0].accountId')] })
    }
  }, [isOpen, totalAccounts])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
        <HHDialogTitle title="Bulk resend unpaid invoices" onClose={onClose} />
        <DialogContent>
          <FormProvider {...form}>
            <Grid mt={1} container columnGap={2}>
              <Grid item xs={12} md={4} borderRight={isTabletOrMobile ? 0 : 1} borderColor="divider">
                <Box height={isTabletOrMobile ? 250 : 'calc(100vh - 325px)'}>
                  <AccountsDataGrid rows={filteredAccounts} onResend={handleResend} />
                </Box>
              </Grid>
              <Grid item xs={12} md overflow="auto">
                <Box height={isTabletOrMobile ? 250 : 'calc(100vh - 325px)'}>
                  <InvoicesDataGrid />
                </Box>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            loading={isLoading}
            disabled={invoicesSelectionModel.length === 0}
            size="small"
            variant="outlined"
            onClick={handleSubmit(handleResendSelectedInvoices)}
          >
            Resend selected invoices
          </LoadingButton>
          <Button disabled={totalAccounts === 0} size="small" variant="contained" onClick={handleOpenResendToAllConfirmDialog}>
            Resend to all accounts
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <HHConfirmDialog
        isOpen={isConfirmDialogOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmTitle="Confirm resend unpaid invoices"
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary', loading: isBulkResendLoading }}
        confirmDescription={`We're resending all the unpaid invoices for ${totalAccounts} account${
          totalAccounts > 1 ? 's' : ''
        }. Do you want to proceed?`}
        onConfirm={handleResendToAll}
        onClose={handleCloseResendToAllConfirmDialog}
      />
    </>
  )
}

BulkResendUnpaidInvoices.propTypes = {
  isOpen: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default BulkResendUnpaidInvoices
