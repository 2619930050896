import React, { useState } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'

import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useActivateDeactivateLocationsMutation } from 'api/location/activateDeactivateLocations'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'
import { useLazyGetAllLocationNotesQuery, usePatchLocationNoteMutation } from 'api/notes/locationNotesCrud'
import T from 'T'
import Loader from 'components/common/loader'
import { toast } from 'react-toastify'
import ViewArchiveNotesDialog from 'components/notes/common/ViewArchiveNotesDialog'
import ViewArchivedNotesMenuOption from 'components/notes/common/actions/ViewArchivedNotesMenuOption'
import ToggleLocationStatusMenuItem from 'components/common/locations/ToggleLocationStatusMenuItem'
import AddEditLocationDialog from './add-edit-dialog'

const LocationActionMenu = ({
  anchorEl,
  selectedLocation = {},
  isAccountActive = true,
  showArchivedNotesOption = false,
  accountId,
  onClose,
  onLocationStatusChange,
}) => {
  const [isOpenAddEditLocationDialog, setIsOpenAddEditLocationDialog] = useState(false)
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)
  const [activateDeactivateLocations, { isLoading: isActivateDeactivateLocationsLoading }] = useActivateDeactivateLocationsMutation()
  const [getAllLocationNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllLocationNotesQuery()

  const locationId = get(selectedLocation, 'id')
  const locationAddress = get(selectedLocation, 'address', {})
  const contacts = get(selectedLocation, 'contacts', [])
  const tags = get(selectedLocation, 'tags', [])
  const isDeactivated = get(selectedLocation, 'deactivated', false)
  const hasActiveServices = get(selectedLocation, 'serviceCount', 0) > 0
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const archivedNotesCount = get(selectedLocation, 'archivedNotesCount', 0)

  const getLocationModel = () => {
    const locationModel = {
      id: locationId,
      address: locationAddress,
      contacts,
      tags,
      signatureRequired: get(selectedLocation, 'signatureRequired', false),
      deactivated: isDeactivated,
    }

    const po = get(selectedLocation, 'po')
    const maxPoAmount = get(selectedLocation, 'maxPoAmount')

    if (po || maxPoAmount) {
      set(locationModel, 'locationPurchaseOrder', true)
      set(locationModel, 'po', po)
      set(locationModel, 'maxPoAmount', maxPoAmount)
    }

    return locationModel
  }

  const handleLocationActivateDeactivate = () => {
    const payload = {
      deactivate: !isDeactivated,
      locations: [{ id: locationId }],
    }

    activateDeactivateLocations(payload)
      .unwrap()
      .then(() => {
        onClose()
        onLocationStatusChange()
        toast.success(payload.deactivate ? T.LOCATION_DEACTIVATED_MSG : T.LOCATION_ACTIVATED_MSG)
      })
      .catch(handleError)
  }

  const handleViewArchiveClick = () => {
    onClose()
    getAllLocationNotes({ locationId })
      .unwrap()
      .then(() => setIsOpenViewArchiveNoteDialog(true))
      .catch(handleError)
  }

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  return (
    <>
      {(isActivateDeactivateLocationsLoading || isGetAllNotesLoading) && <Loader />}
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined', sx: { minWidth: showArchivedNotesOption ? 275 : 200 } } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem
          onClick={() => {
            setIsOpenAddEditLocationDialog(true)
            onClose()
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <PencilAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.EDIT}</ListItemText>
        </MenuItem>
        {showArchivedNotesOption && (
          <ViewArchivedNotesMenuOption isDisabled={archivedNotesCount === 0} count={archivedNotesCount} onClick={handleViewArchiveClick} />
        )}
        <ToggleLocationStatusMenuItem
          onClick={handleLocationActivateDeactivate}
          hasActiveServices={hasActiveServices}
          isAccountActive={isAccountActive}
          isLocationDeactivated={isDeactivated}
        />
      </Menu>

      <AddEditLocationDialog
        isOpen={isOpenAddEditLocationDialog}
        accountId={accountId}
        location={getLocationModel()}
        onClose={() => setIsOpenAddEditLocationDialog(false)}
      />
      <ViewArchiveNotesDialog
        id={locationId}
        isOpen={isOpenViewArchiveNoteDialog}
        title={T.ARCHIVED_LOCATION_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchLocationNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

LocationActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  selectedLocation: PropTypes.object,
  isAccountActive: PropTypes.bool,
  showArchivedNotesOption: PropTypes.bool,
  accountId: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onLocationStatusChange: PropTypes.func.isRequired,
}

export default LocationActionMenu
