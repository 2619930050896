import React, { useEffect } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { SwipeableDrawer, IconButton, ListItem, ListItemText, useTheme, useMediaQuery } from '@mui/material'
import { get } from 'utils/lodash'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'

import T from 'T'
import CustomerFavButton from 'components/customers/common/side-nav/CustomerFavButton'
import MenuGroup from './MenuGroup'
import { GROUPS_MENU, LIST_MENU } from './MenuItems'
import { CUSTOMER_SIDE_NAV_DRAWER_WIDTH, MIN_SCREEN_SIZE } from './settings'

const CustomerSideNav = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const handleDrawerOpen = () => dispatch(setShowCustomerSideNav(true))
  const handleDrawerClose = () => dispatch(setShowCustomerSideNav(false))

  useEffect(() => {
    if (window?.innerWidth < MIN_SCREEN_SIZE) {
      handleDrawerClose()
    }
  }, [])

  return (
    <SwipeableDrawer
      sx={{
        width: CUSTOMER_SIDE_NAV_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: CUSTOMER_SIDE_NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
          top: 56,
        },
      }}
      variant={isTabletOrMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={showCustomerSideNav}
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
    >
      <ListItem
        sx={{ height: 56, borderBottom: 1, borderColor: 'divider' }}
        secondaryAction={
          <IconButton edge="end" onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        }
      >
        <ListItemText primary={T.CUSTOMER} primaryTypographyProps={{ variant: 'h5', fontWeight: 500, color: 'textSecondary' }} />
      </ListItem>

      <MenuGroup groupName={T.GROUPS} menuItems={GROUPS_MENU} RenderSecondaryAction={CustomerFavButton} />
      <MenuGroup groupName={T.LIST_VIEWS} menuItems={LIST_MENU} RenderSecondaryAction={CustomerFavButton} />
    </SwipeableDrawer>
  )
}

export default CustomerSideNav
