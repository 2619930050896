import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TagsSelect from 'components/tags/TagsSelect'
import CancelButton from 'components/buttons/CancelButton'
import noop from 'lodash/noop'
import pick from 'lodash/pick'
import T from 'T'
import { useDispatch, useSelector } from 'react-redux'
import { useGetTagsMetadataQuery } from 'api/tags/getTagsMetadata'
import { usePutUpdateTagsMutation } from 'api/tags/putUpdateTags'
import { getAccountTags, getLocationTags, getServiceTags } from 'data/tags/tagsMetadataSelector'
import { TAG_TYPE_ACCOUNT, TAG_TYPE_LOCATION, TAG_TYPE_SERVICE, selectTagIds, resetSelectedTagIds } from 'slices/tags/tagsSlice'
import { getSelectedTagIds } from 'data/tags/selectedTagsSelector'
import { getEnabledTypes } from 'data/tags/tagsDataMappingSelector'
import { styled } from '@mui/material/styles'
import { get } from 'utils/lodash'
import { Stack } from '@mui/material'

const HHDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2, 6, 2, 3),
    overflow: 'hidden',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1, 3),
  },
}))

const EditTagsPopup = ({
  name,
  open = false,
  disableAccount = false,
  disableService = false,
  disableLocation = false,
  onClose = noop,
  onSave = noop,
  defaultSelectedTags = {},
}) => {
  const dispatch = useDispatch()
  const { isLoading, isFetching } = useGetTagsMetadataQuery()
  const [putUpdateTags, { isLoading: isUpdateTagsLoading }] = usePutUpdateTagsMutation()

  const allAccountTags = useSelector(getAccountTags)
  const allLocationTags = useSelector(getLocationTags)
  const allServiceTags = useSelector(getServiceTags)

  const selectedTagsUnsaved = useSelector(getSelectedTagIds)

  const getSelectedTags = (allTags, { parentId, tags: selectedTags = [] }) => {
    const allSelectedIds = selectedTags.map(({ id }) => id)
    const allTagsSafeValue = Array.isArray(allTags) ? allTags : []

    return {
      parentId,
      tags: allTagsSafeValue.filter(({ id }) => allSelectedIds.includes(id)),
    }
  }

  // get the selected tag objects
  const defaultAccountSelectedTags = getSelectedTags(allAccountTags, get(defaultSelectedTags, [TAG_TYPE_ACCOUNT], {}))
  const defaultLocationSelectedTags = getSelectedTags(allLocationTags, get(defaultSelectedTags, [TAG_TYPE_LOCATION], {}))
  const defaultServiceSelectedTags = getSelectedTags(allServiceTags, get(defaultSelectedTags, [TAG_TYPE_SERVICE], {}))

  // if we have default options, store those on the selected options slice per tag type on initial load
  // AutoComplete does not perform onChange updates on initial default options set, so we need to do it here, once on default options load
  useEffect(() => {
    if (open && !isFetching && !isLoading) {
      ;[
        [TAG_TYPE_ACCOUNT, defaultAccountSelectedTags?.tags, defaultAccountSelectedTags?.parentId],
        [TAG_TYPE_LOCATION, defaultLocationSelectedTags?.tags, defaultLocationSelectedTags?.parentId],
        [TAG_TYPE_SERVICE, defaultServiceSelectedTags?.tags, defaultServiceSelectedTags?.parentId],
      ].forEach(([tagType, selectedTags, parentId]) => {
        onTagsSelectionChange(selectedTags, tagType, parentId)
      })
    }
  }, [open, defaultSelectedTags, isFetching, isLoading])

  useEffect(() => {
    if (open) return
    // cleanup function on unmount, since the parent conditionaly renders based on the open status
    return () => {
      onTagsSelectionCancel()
    }
  }, [open])

  const onTagsSelectionChange = (selectedOptions, tagType, parentId) => {
    // get selected tag ids array
    const selectedTagIds = selectedOptions.map(({ id }) => id)

    // dispatch selection
    dispatch(
      selectTagIds({
        tagType,
        selectedTagIds,
        parentId,
      })
    )
  }

  const onTagsSelectionCancel = () => {
    dispatch(resetSelectedTagIds())
    onClose()
  }

  const onTagsSelectionSave = async () => {
    const enabledTagTypes = getEnabledTypes({ disableAccount, disableLocation, disableService })
    const filteredSelectedtagsByEnabledType = pick(selectedTagsUnsaved, enabledTagTypes)
    await putUpdateTags(filteredSelectedtagsByEnabledType)
    onSave()
  }

  const onDialogClose = (e, reason) => {
    // we disable close on backdrop click, must specifically click on action buttons in order to cancel editing
    if (reason === 'backdropClick') {
      return false
    }

    onTagsSelectionCancel()
  }

  return (
    // we only render after the options have been loaded, otherwise the options wont render due to an Autocomplete error for uncontrolled component
    isLoading ? null : (
      <HHDialog open={open} onClose={onDialogClose} maxWidth="lg" fullWidth hideBackdrop>
        <DialogTitle>
          <Typography variant="h4" noWrap>
            {T.EDIT} tags -{name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onTagsSelectionCancel}
            size="small"
            sx={{
              position: 'absolute',
              right: theme => theme.spacing(2),
              top: theme => theme.spacing(1.5),
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 1 }}>
            {!disableAccount && (
              <TagsSelect
                tags={allAccountTags}
                selectedTags={defaultAccountSelectedTags?.tags}
                label={`${T.ACCOUNT} ${T.TAGS}`}
                onChange={(e, selectedOptions) =>
                  onTagsSelectionChange(selectedOptions, TAG_TYPE_ACCOUNT, defaultAccountSelectedTags?.parentId)
                }
              />
            )}
            {!disableLocation && (
              <TagsSelect
                tags={allLocationTags}
                selectedTags={defaultLocationSelectedTags?.tags}
                label={`${T.LOCATION} ${T.TAGS}`}
                onChange={(e, selectedOptions) =>
                  onTagsSelectionChange(selectedOptions, TAG_TYPE_LOCATION, defaultLocationSelectedTags?.parentId)
                }
              />
            )}
            {!disableService && (
              <TagsSelect
                tags={allServiceTags}
                selectedTags={defaultServiceSelectedTags?.tags}
                label={`${T.SERVICE} ${T.TAGS}`}
                onChange={(e, selectedOptions) =>
                  onTagsSelectionChange(selectedOptions, TAG_TYPE_SERVICE, defaultServiceSelectedTags?.parentId)
                }
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <CancelButton onClick={onTagsSelectionCancel} />
          <LoadingButton size="small" color="primary" variant="contained" loading={isUpdateTagsLoading} onClick={onTagsSelectionSave}>
            {T.SAVE}
          </LoadingButton>
        </DialogActions>
      </HHDialog>
    )
  )
}

EditTagsPopup.propTypes = {
  name: PropTypes.string.isRequired,
  defaultSelectedTags: PropTypes.shape({
    [TAG_TYPE_ACCOUNT]: PropTypes.shape({
      parentId: PropTypes.string.isRequired,
      tags: PropTypes.array,
    }),
    [TAG_TYPE_LOCATION]: PropTypes.shape({
      parentId: PropTypes.string.isRequired,
      tags: PropTypes.array,
    }),
    [TAG_TYPE_SERVICE]: PropTypes.shape({
      parentId: PropTypes.string.isRequired,
      tags: PropTypes.array,
    }),
  }).isRequired,
  open: PropTypes.bool,
  disableAccount: PropTypes.bool,
  disableService: PropTypes.bool,
  disableLocation: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default EditTagsPopup
