import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getWorkOrdersDisposalTicket: builder.mutation({
      query: workOrderId => ({ url: `/api/v1/core/work-orders/${workOrderId}/disposal-tickets`, method: 'GET' }),
    }),
  }),
})

export const { useGetWorkOrdersDisposalTicketMutation } = extendedApi
