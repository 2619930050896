import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import { ResponsiveBar } from '@nivo/bar'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'
import T from 'T'
import GroupChartName from 'components/billing/groups/common/GroupChartName'
import RenderTooltip from 'components/customers/groups/account/details/RenderTooltip'
import { Chip, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, useTheme } from '@mui/material'

const BillingProfileStatistic = ({ showFooter = true, data = [], moreCount = 0, graphMargin = undefined, sx }) => {
  const keys = useMemo(() => data.map(({ id }) => id), [data])
  const colors = useMemo(() => data.map(dataItem => dataItem[`${dataItem.id}Color`]), [data])
  const theme = useTheme()
  return (
    <>
      <Box height={250} position="relative" display="flex" overflow="hidden" sx={sx}>
        <Box flex={3}>
          <ResponsiveBar
            data={data}
            colors={colors}
            keys={keys}
            indexBy="id"
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            margin={graphMargin}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40,
              truncateTickAt: 0,
              format: e => (Math.floor(e) === e ? e : ''),
            }}
            tooltip={RenderTooltip}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
          />
        </Box>
        <Stack sx={{ flex: 2, pl: 1 }} justifyContent="flex-end" overflow="hidden">
          {data.map(dataItem => {
            const { id, label } = dataItem
            const color = dataItem[`${id}Color`]
            const value = dataItem[`${id}`]
            return (
              <Tooltip disableInteractive arrow title={`${label}: ${value}`}>
                <ListItem dense disablePadding disableGutters>
                  <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                    <Box width={15} height={15} backgroundColor={color} />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </Tooltip>
            )
          })}
          {moreCount > 0 && (
            <Tooltip disableInteractive arrow title={`+${moreCount} billing profiles`}>
              <ListItem dense disablePadding disableGutters>
                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                  <Chip label={`+${moreCount}`} size="small" sx={{ borderRadius: 0.5, ...theme.typography.body2, height: 15 }} />
                </ListItemIcon>
                <ListItemText
                  primary="billing profiles"
                  primaryTypographyProps={{
                    noWrap: true,
                    variant: 'body2',
                  }}
                />
              </ListItem>
            </Tooltip>
          )}
        </Stack>
      </Box>
      {showFooter && <GroupChartName Icon={CurrencyDollar} label={T.BILLING_PROFILE} />}
    </>
  )
}

BillingProfileStatistic.propTypes = {
  showFooter: PropTypes.bool,
  data: PropTypes.array,
  moreCount: PropTypes.number,
  graphMargin: PropTypes.object,
  sx: PropTypes.object,
}

export default BillingProfileStatistic
