import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DialogActions from '@mui/material/DialogActions'
import { Button, Stack, useTheme } from '@mui/material'
import T from 'T'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import Tooltip from '@mui/material/Tooltip'
import SaveButton from 'components/buttons/SaveButton'
import {
  selectBulkMoveInvalidStopsToBeAssignedCount,
  selectBulkMoveValidStopIdsToBeAssigned,
  setBulkMoveStopsState,
  setConfirmPermanentMoveDialogState,
} from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { handleError } from 'utils/error'
import api from 'api'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import Loader from 'components/common/loader'

const BulkMoveStopsDialogActions = ({ onReset, getBEBatches, isPermanentMove, onClose, isPermanentLoading }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const { handleSubmit } = useFormContext()
  const invalidStopsToBeAssignedCount = useSelector(selectBulkMoveInvalidStopsToBeAssignedCount)
  const validStopsToBeAssigned = useSelector(selectBulkMoveValidStopIdsToBeAssigned)
  const validStopsToBeAssignedCount = get(validStopsToBeAssigned, 'length', 0)
  const submitValidTitle =
    validStopsToBeAssignedCount > 0
      ? 'This does not affect future stops of these services'
      : 'This does not affect future stops of this service'
  const submitTitle = invalidStopsToBeAssignedCount > 0 ? `${invalidStopsToBeAssignedCount} stop left to assign` : submitValidTitle
  const isSaveButtonDisabled = invalidStopsToBeAssignedCount > 0 || validStopsToBeAssignedCount === 0

  const onValidSubmit = async () => {
    const beBatches = getBEBatches(false)
    bulkMoveStops({ moves: beBatches })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        onReset(false)
        dispatch(
          setBulkMoveStopsState({
            isOpen: false,
            isUnsavedChangesModalOpen: false,
          })
        )
      })
      .catch(handleError)
  }

  const handleSavePermanentClick = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: true,
      })
    )
  }

  return (
    <DialogActions sx={{ flex: '0 0 48px' }}>
      {(isPermanentLoading || isLoading) && <Loader />}
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1}>
        <Button size="small" variant="outlined" color="warning" onClick={onReset}>
          {T.RESET}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
        <CancelButton size="small" onClick={onClose} />
        <LoadingButton
          loading={isPermanentMove && (isLoading || isPermanentLoading)}
          disabled={isSaveButtonDisabled}
          variant="outlined"
          size="small"
          onClick={handleSavePermanentClick}
        >
          {T.SAVE_PERMANENT}
        </LoadingButton>
        <Tooltip PopperProps={{ sx: { zIndex: theme.zIndex.intercom + 1 } }} arrow title={submitTitle}>
          <span>
            <SaveButton
              size="small"
              loading={!isPermanentMove && isLoading}
              disabled={isSaveButtonDisabled}
              label={T.SAVE_CURRENT_DAY}
              onClick={handleSubmit(onValidSubmit)}
            />
          </span>
        </Tooltip>
      </Stack>
    </DialogActions>
  )
}

BulkMoveStopsDialogActions.propTypes = {
  onReset: PropTypes.func.isRequired,
  getBEBatches: PropTypes.func.isRequired,
  isPermanentMove: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isPermanentLoading: PropTypes.bool,
}

export default memo(BulkMoveStopsDialogActions)
