import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText'

import { memo } from 'utils/react'

import Constants from 'Constants'

import { MenuProps } from './utils'

import './style.scss'

const { MEDIA_SERVER } = Constants

const MAX_NAME_LIMIT = 12

const TaxAssignedTo = ({ mainTaxId = '', taxList = [], onRenderName, onTaxChange }) => {
  const [selectedId, setSelectedId] = useState([])
  const [selectedName, setSelectedName] = useState([])

  const isAllSelected = taxList.length > 0 && selectedId.length === taxList.length

  const handleChange = event => {
    const { value } = event.target
    let linkedId = value
    if (value[value.length - 1] === 'all') {
      linkedId = selectedId.length === taxList.length ? [] : taxList.map(tax => tax.appliedOnId)
    }

    setSelectedId(linkedId)
    onTaxChange(mainTaxId, linkedId)
  }

  useEffect(() => {
    const getAllLinked = taxList.filter(tax => tax.linked)
    setSelectedId(getAllLinked.map(tax => tax.appliedOnId))
    setSelectedName(getAllLinked.map(tax => tax.appliedOnName))
  }, [taxList])

  return (
    <Select
      disableUnderline
      placeholder="Please Select"
      displayEmpty
      multiple
      value={selectedId}
      onChange={handleChange}
      renderValue={selected => (selected.length === 0 ? 'Please Select' : selectedName.join(', '))}
      MenuProps={MenuProps}
      className="taxes-on-fees"
    >
      <MenuItem
        value="all"
        sx={{
          margin: '4px 8px',
          padding: 0,
          background: 'none !important',
        }}
      >
        <Checkbox
          color="primary"
          indeterminateIcon={<img src={`${MEDIA_SERVER}CheckboxBackground.svg`} />}
          icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} />}
          checkedIcon={<img src={`${MEDIA_SERVER}CheckboxChecked.svg`} />}
          className="pl0"
          checked={isAllSelected}
          indeterminate={selectedId.length > 0 && selectedId.length < taxList.length}
        />
        <ListItemText
          sx={{
            [`.${listItemTextClasses.primary}`]: {
              fontWeight: 500,
              fontSize: 13,
              lineHeight: '24px',
              color: '#7A869A',
            },
          }}
          primary={isAllSelected ? 'Deselect All' : 'Select All'}
        />
      </MenuItem>

      {Children.toArray(
        taxList.map(option => (
          <MenuItem
            value={option.appliedOnId}
            sx={{
              margin: '4px 8px',
              padding: 0,
              background: 'none !important',
            }}
          >
            <Checkbox
              color="primary"
              className="pl0"
              indeterminateIcon={<img src={`${MEDIA_SERVER}CheckboxBackground.svg`} />}
              icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} />}
              checkedIcon={<img src={`${MEDIA_SERVER}CheckboxChecked.svg`} />}
              checked={selectedId.indexOf(option.appliedOnId) > -1}
            />
            <ListItemText
              sx={{
                [`.${listItemTextClasses.primary}`]: {
                  fontSize: 13,
                  lineHeight: '24px',
                  color: '#042040',
                },
              }}
            >
              {onRenderName(option.appliedOnName, MAX_NAME_LIMIT)}
            </ListItemText>
          </MenuItem>
        ))
      )}
    </Select>
  )
}

TaxAssignedTo.propTypes = {
  mainTaxId: PropTypes.string,
  taxList: PropTypes.array,
  onRenderName: PropTypes.func.isRequired,
  onTaxChange: PropTypes.func.isRequired,
}

export default memo(TaxAssignedTo)
