import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useSelector } from 'react-redux'
import BasePermanentMoveDialog from 'components/route-manager/BulkActions/common/BasePermanentMoveDialog'
import PermanentMoveAlert from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/ConfirmPermanentMoveDialog/PermanentMoveAlert'
import { useFormContext } from 'react-hook-form'
import { selectBulkMoveStopsDate, selectBulkMoveToRows } from 'slices/route/bulkMoveBetweenRoutesSlice'

const ConfirmPermanentMoveDialog = ({
  confirmPermanentGridApiRef,
  isOpen,
  onClose = noop,
  onConfirm = noop,
  DialogProps = {},
  isPermanentLoading,
}) => {
  const { watch } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRows = useSelector(selectBulkMoveToRows)
  const serializedServiceDate = useSelector(selectBulkMoveStopsDate)
  const getIsFromRow = useCallback(row => row.routeId === moveFromRouteId, [moveFromRouteId])
  return (
    <BasePermanentMoveDialog
      AlertComponent={PermanentMoveAlert}
      DialogProps={DialogProps}
      confirmPermanentGridApiRef={confirmPermanentGridApiRef}
      isPermanentLoading={isPermanentLoading}
      moveToRows={moveToRows}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      serializedServiceDate={serializedServiceDate}
      getIsFromRow={getIsFromRow}
    />
  )
}

ConfirmPermanentMoveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  DialogProps: PropTypes.object,
  confirmPermanentGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isPermanentLoading: PropTypes.bool,
}

export default ConfirmPermanentMoveDialog
