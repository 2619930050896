import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { getPriceType } from 'utils/service'
import { Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const MaterialTaxListItem = ({ groupByFeeTaxes, taxKey, materialIndex, taxIndex, subtotal }) => {
  const { watch, setValue } = useFormContext()
  const allTaxes = get(groupByFeeTaxes, taxKey, [])
  const tax = get(allTaxes, `[0]`, {})
  const taxName = get(tax, 'feeName', '')
  const priceType = getPriceType(get(tax, 'taxValueType'))
  const taxPrice = get(tax, 'price', 0)
  let formattedTaxPrice = get(tax, 'price', 0)
  const isFixedTax = priceType === '$'
  const isTaxable = watch('isTaxable')
  const taxAmount = watch(`materials.${materialIndex}.taxes.${taxIndex}`)
  if (isFixedTax) {
    formattedTaxPrice = priceType + taxPrice
  } else {
    formattedTaxPrice += priceType
  }

  useEffect(() => {
    if (subtotal === 0) {
      setValue(`materials.${materialIndex}.taxes.${taxIndex}`, 0)
    } else if (isFixedTax) {
      setValue(`materials.${materialIndex}.taxes.${taxIndex}`, taxPrice)
    } else {
      const calculatedTaxAmount = subtotal * (taxPrice / 100)
      const roundedCalculatedTaxAmount = Math.ceil(calculatedTaxAmount * 100) * 0.01
      setValue(`materials.${materialIndex}.taxes.${taxIndex}`, roundedCalculatedTaxAmount)
    }
  }, [subtotal, taxPrice, priceType, isTaxable])

  return (
    <Grid container item xs={12} justifyContent="flex-end">
      <Grid item xs />
      <Grid item container xs="auto">
        <Grid item>
          <Typography textAlign="right">{`${taxName} - ${formattedTaxPrice}`}</Typography>
        </Grid>
        <Grid item sx={{ minWidth: 100 }}>
          <Typography textAlign="right" fontWeight={500}>
            <HHDisplayMoney value={taxAmount} formatToDollars={false} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

MaterialTaxListItem.propTypes = {
  groupByFeeTaxes: PropTypes.object.isRequired,
  taxKey: PropTypes.string.isRequired,
  materialIndex: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
}

export default MaterialTaxListItem
