import React from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, useTheme } from '@mui/material'
import T from 'T'
import CancelButton from 'components/buttons/CancelButton'
import Tooltip from '@mui/material/Tooltip'
import SaveButton from 'components/buttons/SaveButton'
import DialogActions from '@mui/material/DialogActions'
import { get } from 'lodash'
import {
  selectBulkMoveInvalidStopsToBeAssignedCount,
  selectBulkMoveValidStopIdsToBeAssigned,
  setConfirmPermanentMoveDialogState,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import { useDispatch, useSelector } from 'react-redux'

const BulkMoveStopsBetweenDaysDialogActions = ({ isLoading, onReset, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const invalidStopsToBeAssignedCount = useSelector(selectBulkMoveInvalidStopsToBeAssignedCount)
  const validStopsToBeAssigned = useSelector(selectBulkMoveValidStopIdsToBeAssigned)
  const validStopsToBeAssignedCount = get(validStopsToBeAssigned, 'length', 0)
  const isSaveButtonDisabled = invalidStopsToBeAssignedCount > 0 || validStopsToBeAssignedCount === 0
  const submitTitle = invalidStopsToBeAssignedCount > 0 ? `${invalidStopsToBeAssignedCount} stop left to assign` : false

  const handleSaveClick = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: true,
      })
    )
  }

  return (
    <DialogActions sx={{ flex: '0 0 48px' }}>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1}>
        <Button size="small" variant="outlined" color="warning" onClick={onReset}>
          {T.RESET}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
        <CancelButton size="small" onClick={onClose} />
        <Tooltip PopperProps={{ sx: { zIndex: theme.zIndex.intercom + 1 } }} arrow title={submitTitle}>
          <span>
            <SaveButton
              loadingPosition="center"
              loading={isLoading}
              size="small"
              disabled={isSaveButtonDisabled}
              label={T.SAVE}
              onClick={handleSaveClick}
            />
          </span>
        </Tooltip>
      </Stack>
    </DialogActions>
  )
}

BulkMoveStopsBetweenDaysDialogActions.propTypes = {
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

export default BulkMoveStopsBetweenDaysDialogActions
