import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getInvoiceBySearch: builder.query({
      query: ({ pageSize, pageNumber, invoiceNumber }) => ({
        url: '/api/v1/billing/invoice',
        params: { pageSize, pageNumber, invoiceNumber },
      }),
    }),
  }),
})

export const { useLazyGetInvoiceBySearchQuery } = extendedApi
