import T from 'T'
// eslint-disable-next-line import/no-cycle
import RenderHeaderCell from '../../../components/data_grid/render_cell/RenderHeaderCell'

const { CUSTOMER, ACCOUNT_NUMBER, BILLING_ADDRESS, SERVICE } = T

export const CREATE_INVOICE_CONFIGURATOR = [
  {
    flex: 2,
    headerName: CUSTOMER,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    filterType: 'string',
    columnName: 'customerName',
    field: 'customerName',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: ACCOUNT_NUMBER,
    tableLabel: ACCOUNT_NUMBER,
    label: ACCOUNT_NUMBER,
    filterType: 'string',
    columnName: 'accountNumber',
    field: 'accountNumber',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 3,
    headerName: BILLING_ADDRESS,
    tableLabel: BILLING_ADDRESS,
    label: BILLING_ADDRESS,
    filterType: 'string',
    columnName: 'billingAddress',
    field: 'billingAddress',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    headerName: SERVICE,
    tableLabel: SERVICE,
    label: SERVICE,
    filterType: 'number',
    type: 'number',
    columnName: 'serviceCount',
    field: 'serviceCount',
    renderHeader: RenderHeaderCell,
    headerAlign: 'center',
    align: 'center',
  },
]
