import T from 'T'

const { ACCOUNT_NUMBER, REVENUE, ACCOUNT_REPRESENTATIVE, CUSTOMER_NAME, TAG, TAX_ZONE, TAX_AMOUNT, PAYMENT_TYPE, CUSTOMER_ADDRESS } = T

export const REVENUE_CONFIGURATOR = [
  {
    position: 0,
    tableLabel: CUSTOMER_NAME,
    label: CUSTOMER_NAME,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'customerName',
  },
  {
    position: 1,
    tableLabel: ACCOUNT_NUMBER,
    label: ACCOUNT_NUMBER,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'accountNumber',
    className: 'font-500',
  },
  {
    position: 2,
    tableLabel: REVENUE,
    label: REVENUE,
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'revenue',
    subType: 'price',
  },
  {
    position: 3,
    tableLabel: TAG,
    label: TAG,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'tags',
    className: 'tags',
    subType: 'tags',
    hideSort: true,
  },
  {
    position: 4,
    tableLabel: TAX_ZONE,
    label: TAX_ZONE,
    checked: true,
    locked: false,
    columnName: 'zoneNameList',
    className: 'font-500',
    filterType: 'string',
    subType: 'pricingZones',
  },
  {
    position: 5,
    tableLabel: TAX_AMOUNT,
    label: TAX_AMOUNT,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'number',
    columnName: 'tax',
    subType: 'taxFeeValue',
  },
  {
    position: 6,
    label: CUSTOMER_ADDRESS,
    tableLabel: CUSTOMER_ADDRESS,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'customerAddress',
  },
  {
    position: 7,
    label: ACCOUNT_REPRESENTATIVE,
    tableLabel: ACCOUNT_REPRESENTATIVE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'csr',
  },
  {
    position: 8,
    tableLabel: PAYMENT_TYPE,
    label: PAYMENT_TYPE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'paymentType',
    subType: 'paymentType',
  },
]
