import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@mui/material'

import { PAYMENT_METHODS, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'
import { AGING_BUCKET_NAME, AGING_BUCKET_LABEL } from 'settings/constants/accountReceivables'

import AgingBucket from 'components/common/aging-bucket/AgingBucket'
import InteractiveAgingBucket from 'components/common/aging-bucket/InteractiveAgingBucket/InteractiveAgingBucket'

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME
const { ACCOUNT_CREDIT } = PAYMENT_METHODS

const AgingBucketContent = ({
  accountId,
  currentCents = 0,
  dueOneThirtyCents = 0,
  dueThirtyOneSixtyCents = 0,
  dueSixtyOneNinetyCents = 0,
  dueOverNinetyCents = 0,
  customerCreditBalanceCents = 0,
  isInteractive = true,
  showCurrent = true,
  showDueOneThirty = true,
  showDueThirtyOneSixty = true,
  showDueSixtyOneNinety = true,
  showDueOverNinety = true,
  showCustomerCreditBalance = true,
  ...rest
}) => {
  const AgingBucketComponent = useMemo(() => (isInteractive ? InteractiveAgingBucket : AgingBucket), [isInteractive])

  return (
    <Grid container spacing={1} {...rest}>
      {showCurrent && (
        <Grid item xs={6} md>
          <AgingBucketComponent accountId={accountId} type={CURRENT} label={AGING_BUCKET_LABEL[CURRENT]} value={currentCents} />
        </Grid>
      )}
      {showDueOneThirty && (
        <Grid item xs={6} md>
          <AgingBucketComponent
            accountId={accountId}
            type={DUE_ONE_THIRTY}
            label={AGING_BUCKET_LABEL[DUE_ONE_THIRTY]}
            value={dueOneThirtyCents}
          />
        </Grid>
      )}
      {showDueThirtyOneSixty && (
        <Grid item xs={6} md>
          <AgingBucketComponent
            accountId={accountId}
            type={DUE_THIRTY_ONE_SIXTY}
            label={AGING_BUCKET_LABEL[DUE_THIRTY_ONE_SIXTY]}
            value={dueThirtyOneSixtyCents}
          />
        </Grid>
      )}
      {showDueSixtyOneNinety && (
        <Grid item xs={6} md>
          <AgingBucketComponent
            accountId={accountId}
            type={DUE_SIXTY_ONE_NINETY}
            label={AGING_BUCKET_LABEL[DUE_SIXTY_ONE_NINETY]}
            value={dueSixtyOneNinetyCents}
          />
        </Grid>
      )}
      {showDueOverNinety && (
        <Grid item xs={6} md>
          <AgingBucketComponent
            accountId={accountId}
            type={DUE_OVER_NINETY}
            label={AGING_BUCKET_LABEL[DUE_OVER_NINETY]}
            value={dueOverNinetyCents}
          />
        </Grid>
      )}
      {showCustomerCreditBalance && (
        <Grid item xs={6} md>
          <AgingBucket type={ACCOUNT_CREDIT} label={PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT]} value={customerCreditBalanceCents} />
        </Grid>
      )}
    </Grid>
  )
}

AgingBucketContent.propTypes = {
  accountId: PropTypes.string.isRequired,
  currentCents: PropTypes.number,
  dueOneThirtyCents: PropTypes.number,
  dueThirtyOneSixtyCents: PropTypes.number,
  dueSixtyOneNinetyCents: PropTypes.number,
  dueOverNinetyCents: PropTypes.number,
  customerCreditBalanceCents: PropTypes.number,
  isInteractive: PropTypes.bool,
  showCurrent: PropTypes.bool,
  showDueOneThirty: PropTypes.bool,
  showDueThirtyOneSixty: PropTypes.bool,
  showDueSixtyOneNinety: PropTypes.bool,
  showDueOverNinety: PropTypes.bool,
  showCustomerCreditBalance: PropTypes.bool,
}

export default AgingBucketContent
