import api from '../index'
import { CACHE_TAG_ACCOUNT_SETTINGS } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountSettings: builder.query({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/settings`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_ACCOUNT_SETTINGS],
    }),
    updateAccountSettings: builder.mutation({
      query: ({ accountId, ...rest }) => ({
        url: `/api/v1/core/accounts/${accountId}/settings`,
        method: 'PATCH',
        body: {
          ...rest,
        },
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_SETTINGS],
    }),
  }),
})

export const { useLazyGetAccountSettingsQuery, useUpdateAccountSettingsMutation } = extendedApi
