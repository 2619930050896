import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useDispatch } from 'react-redux'

import { Menu, MenuItem } from '@mui/material'
import { toast } from 'react-toastify'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { getInvoicesPDFStatus, isDraftInvoice, isUnPaidInvoice } from 'utils/billing'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { usePostInvoiceListMutation } from 'api/billing/postInvoiceList'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import { useInvoiceBulkMarkPaidMutation } from 'api/billing/invoiceBulkMarkPaid'
import { useInvoiceBulkMarkSentMutation } from 'api/billing/invoiceBulkMarkSent'
import { useDeleteInvoicesMutation, useDeleteUnpaidInvoiceMutation } from 'api/billing/deleteInvoices'
import { useRevertInvoiceToDraftMutation } from 'api/billing/revertInvoiceToDraft'
import { useDeleteInvoicesFromGroupMutation } from 'api/groups/invoice/deleteInvoicesFromGroup'
import { useUnpayInvoiceMutation } from 'api/billing/unpayInvoice'
import {
  isPostInvoiceBtnEnabled,
  isRegenerateBtnEnabled,
  isMarkAsPaidBtnEnabled,
  isMarkAsSentBtnEnabled,
  isEditBtnEnabled,
  isDeleteBtnEnabled,
  isRevertToDraftEnabled,
  isDeleteUnpaidEnabled,
  isMoveToAccountCreditEnabled,
  isRevertToUnpaidEnabled,
} from 'data/billing/InvoiceActionSelectors'
import { getCustomerDetails } from 'middleware/actions/customers'
import { isCustomerDetailsPage } from 'router/routes'

import T from 'T'
import InvoiceSendFailureConfirmation from 'containers/billing/InvoiceSendFailureConfirmation'
import DeleteInvoiceConfirmation from 'containers/billing/DeleteInvoiceConfirmation'
import RegenerateInvoiceConfirmation from 'components/billing/invoices/RegenerateInvoiceConfirmation/RegenerateInvoiceConfirmation'
import RevertInvoiceToDraftConfirmation from 'containers/billing/RevertInvoiceToDraftConfirmation'
import DeleteUnpaidInvoiceConfirmation from 'containers/billing/DeleteUnpaidInvoiceConfirmation'
import AddToGroupDialog from 'components/billing/groups/invoice/AddToGroupDialog'
import InvoiceEdit from '../invoices/invoice-edit'
import CommonDrawer from '../../common/CommonDrawer'
import api from '../../../api'
import {
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
  CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from '../../../api/cacheTagTypes'
import { changeCanTakePayment } from '../../../slices/response/responseSlice'
import MoveToAccountCreditConfirmation from './MoveToAccountCreditConfirmation'

const TIMEOUT = 4000

const InvoiceActionMenu = ({ anchorEl, selectedInvoice = {}, showAddToGroupAction = true, onClose }) => {
  const dispatch = useDispatch()
  const [sendInvoiceList] = useSendInvoiceListMutation()
  const [postInvoiceList] = usePostInvoiceListMutation()
  const [regenerateInvoiceList] = useRegenerateInvoiceListMutation()
  const [invoiceBulkMarkPaid] = useInvoiceBulkMarkPaidMutation()
  const [invoiceBulkMarkSent] = useInvoiceBulkMarkSentMutation()
  const [deleteInvoices] = useDeleteInvoicesMutation()
  const [revertToDraft] = useRevertInvoiceToDraftMutation()
  const [deleteUnpaidInvoice] = useDeleteUnpaidInvoiceMutation()
  const [deleteInvoicesFromGroup] = useDeleteInvoicesFromGroupMutation()
  const [unpayInvoice] = useUnpayInvoiceMutation()
  const [isOpenEditInvoiceWOListing, setIsOpenEditInvoiceWOListing] = useState(false)
  const [sendInvoiceConfirmation, setSendInvoiceConfirmation] = useState({ invoiceNumbers: [], isOpen: false })
  const [isOpenDeleteInvoiceConfirmation, setIsOpenDeleteInvoiceConfirmation] = useState(false)
  const [isOpenRegenerateInvoiceConfirmation, setIsOpenRegenerateInvoiceConfirmation] = useState(false)
  const [isOpenRevertToDraftConfirmation, setIsOpenRevertToDraftConfirmation] = useState(false)
  const [isOpenDeleteUnpaidInvoiceConfirmation, setIsOpenDeleteUnpaidInvoiceConfirmation] = useState(false)
  const [isOpenMoveToAccountCreditConfirmation, setIsOpenMoveToAccountCreditConfirmation] = useState(false)
  const [isOpenAddToInvoiceGroupDialog, setIsOpenAddToInvoiceGroupDialog] = useState(false)
  const showPostAction = isPostInvoiceBtnEnabled(selectedInvoice)
  const showRegenerateAction = isRegenerateBtnEnabled(selectedInvoice)
  const showMarkAsPaidAction = isMarkAsPaidBtnEnabled(selectedInvoice)
  const showMarkAsSentAction = isMarkAsSentBtnEnabled(selectedInvoice)
  const showEditAction = isEditBtnEnabled(selectedInvoice)
  const showDeleteAction = isDeleteBtnEnabled(selectedInvoice)
  const showDeleteUnpaidAction = isDeleteUnpaidEnabled(selectedInvoice)
  const showRevertToDraftAction = isRevertToDraftEnabled(selectedInvoice)
  const showMoveToAccountCreditAction = isMoveToAccountCreditEnabled(selectedInvoice)
  const showRevertToUnpaidAction = isRevertToUnpaidEnabled(selectedInvoice)
  const groupId = get(selectedInvoice, 'groupId')
  const invoiceId = get(selectedInvoice, 'id')
  const accountId = get(selectedInvoice, 'accountId')

  const handleChangeCanTakePayment = ({ timer }) => {
    if (timer) {
      dispatch(changeCanTakePayment(false))
      setTimeout(() => dispatch(changeCanTakePayment(true)), TIMEOUT + 1000)
    }
  }

  const handleDeleteInvoiceConfirmation = () => {
    setIsOpenDeleteInvoiceConfirmation(!isOpenDeleteInvoiceConfirmation)
    onClose()
  }

  const handleCloseRegenerateConfirmation = () => setIsOpenRegenerateInvoiceConfirmation(false)

  const closeRevertToDraftConfirmationModal = () => {
    setIsOpenRevertToDraftConfirmation(false)
  }

  const openRevertToDraftConfirmationModal = () => {
    setIsOpenRevertToDraftConfirmation(true)
  }

  const openDeleteUnpaidConfirmationModal = () => {
    setIsOpenDeleteUnpaidInvoiceConfirmation(true)
  }

  const closeDeleteUnpaidConfirmationModal = () => {
    setIsOpenDeleteUnpaidInvoiceConfirmation(false)
  }

  const handleRefetch = (timeout = 0) =>
    setTimeout(() => {
      if (isCustomerDetailsPage()) {
        dispatch(getCustomerDetails({ accountId }))
      }
      dispatch(
        api.util.invalidateTags([
          CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
          CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
          CACHE_TAG_INVOICE_LIST,
          { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
          { type: CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST, id: accountId },
          CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
          CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
          CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
          CACHE_TAG_ACCOUNT_LIST,
          { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
          CACHE_TAG_ACCOUNT_GROUP_DETAILS,
          CACHE_TAG_AGING_GROUP_DETAILS,
          CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        ])
      )
    }, timeout)

  const handleAPISuccess = (message, refetch = false, timer = false) => {
    handleChangeCanTakePayment({ timer })
    if (timer) {
      toast.success(message, {
        hideProgressBar: false,
        autoClose: TIMEOUT,
        onClose: () => {
          if (refetch) handleRefetch()
        },
      })
      return
    }

    toast.success(message)
    if (refetch) handleRefetch()
  }

  const handleSendInvoice = async () => {
    try {
      dispatch(putIsLoading(true))
      const { invoiceNumber, status } = selectedInvoice
      let withPDF = [invoiceNumber]
      let withoutPDF = []

      if (isDraftInvoice(status)) {
        const result = await getInvoicesPDFStatus(withPDF)
        withPDF = result.withPDF
        withoutPDF = result.withoutPDF
      }

      const hasInvoicesWithoutPDF = withoutPDF.length > 0

      if (withPDF.length) {
        await sendInvoiceList({ invoiceNumbers: withPDF })
          .unwrap()
          .then(() => {
            if (hasInvoicesWithoutPDF) {
              setSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
              handleRefetch(TIMEOUT)
              handleChangeCanTakePayment({ timer: true })
              return
            }
            handleAPISuccess(T.SEND_INVOICE_SUCCESS, true, true)
          })
          .catch(handleError)
      } else if (hasInvoicesWithoutPDF) {
        setSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
      }
    } finally {
      onClose()
      dispatch(putIsLoading(false))
    }
  }

  const handlePostInvoice = () => {
    dispatch(putIsLoading(true))
    postInvoiceList({ invoiceNumbers: [selectedInvoice.invoiceNumber] })
      .unwrap()
      .then(() => handleAPISuccess(T.POST_INVOICE_SUCCESS))
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleRevertToDraftInvoice = () => {
    dispatch(putIsLoading(true))
    revertToDraft({ id: invoiceId })
      .unwrap()
      .then(() => handleAPISuccess(T.REVERT_INVOICE_TO_DRAFT_SUCCESS, true, true))
      .catch(handleError)
      .finally(() => {
        onClose()
        setIsOpenRevertToDraftConfirmation(false)
        dispatch(putIsLoading(false))
      })
  }

  const handleRegenerateInvoice = () => {
    dispatch(putIsLoading(true))
    regenerateInvoiceList({ invoiceIds: [invoiceId] })
      .unwrap()
      .then(() => handleAPISuccess(T.REGENERATE_INVOICES_SUCCESS_MSG, true))
      .catch(handleError)
      .finally(() => {
        handleCloseRegenerateConfirmation()
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleValidateRegenerateInvoice = () => {
    if (isUnPaidInvoice(selectedInvoice?.status)) {
      setIsOpenRegenerateInvoiceConfirmation(true)
      onClose()
      return
    }

    handleRegenerateInvoice()
  }

  const handleInvoiceMarkPaid = () => {
    dispatch(putIsLoading(true))
    invoiceBulkMarkPaid({ invoiceIds: [invoiceId] })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccess(T.MARK_AS_PAID_SUCCESS, true, true)
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleInvoiceMarkSent = () => {
    dispatch(putIsLoading(true))
    invoiceBulkMarkSent({ invoiceIds: [invoiceId] })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccess(T.MARK_AS_SENT_SUCCESS, true, true)
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleDeleteInvoice = () => {
    dispatch(putIsLoading(true))
    deleteInvoices({ invoiceIds: [invoiceId] })
      .unwrap()
      .then(() => handleAPISuccess(T.DELETE_INVOICES_SUCCESS_MSG, true, true))
      .catch(handleError)
      .finally(() => {
        handleDeleteInvoiceConfirmation()
        dispatch(putIsLoading(false))
      })
  }

  const handleDeleteUnpaidInvoice = () => {
    dispatch(putIsLoading(true))
    deleteUnpaidInvoice({ id: invoiceId })
      .unwrap()
      .then(() => handleAPISuccess(T.DELETE_INVOICE_SUCCESS_MSG, true, true))
      .catch(handleError)
      .finally(() => {
        onClose()
        closeDeleteUnpaidConfirmationModal()
        dispatch(putIsLoading(false))
      })
  }

  const handleEditInvoice = () => {
    setIsOpenEditInvoiceWOListing(!isOpenEditInvoiceWOListing)
    onClose()
  }

  const handleRemoveInvoiceFromGroup = () => {
    dispatch(putIsLoading(true))
    deleteInvoicesFromGroup({ invoiceGroupId: groupId, invoiceIds: [invoiceId] })
      .unwrap()
      .then(() => handleAPISuccess(T.REMOVE_INVOICE_FROM_GROUP_SUCCESS_MSG))
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleMoveToAccountCredit = () => setIsOpenMoveToAccountCreditConfirmation(true)

  const handleRevertToUnpaid = () => {
    dispatch(putIsLoading(true))
    unpayInvoice({ invoiceId })
      .unwrap()
      .then(() => handleAPISuccess(T.REVERT_INVOICE_TO_UNPAID_SUCCESS, true, true))
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleOpenAddToInvoiceGroupDialog = () => setIsOpenAddToInvoiceGroupDialog(true)

  const handleCloseAddToInvoiceGroupDialog = () => {
    setIsOpenAddToInvoiceGroupDialog(false)
    onClose()
  }

  return (
    <>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          variant: 'outlined',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {showEditAction && <MenuItem onClick={handleEditInvoice}>{T.EDIT}</MenuItem>}
        {showMarkAsSentAction && <MenuItem onClick={handleInvoiceMarkSent}>{T.MARK_AS_SENT}</MenuItem>}
        {showMarkAsPaidAction && <MenuItem onClick={handleInvoiceMarkPaid}>{T.MARK_AS_PAID}</MenuItem>}
        {showRegenerateAction && <MenuItem onClick={handleValidateRegenerateInvoice}>{T.REGENERATE}</MenuItem>}
        <MenuItem onClick={handleSendInvoice}>{T.SEND}</MenuItem>
        {showPostAction && <MenuItem onClick={handlePostInvoice}>{T.POST}</MenuItem>}
        {groupId && <MenuItem onClick={handleRemoveInvoiceFromGroup}>{T.REMOVE_FROM_GROUP}</MenuItem>}
        {showRevertToUnpaidAction && <MenuItem onClick={handleRevertToUnpaid}>{T.REVERT_TO_UNPAID}</MenuItem>}
        {showMoveToAccountCreditAction && <MenuItem onClick={handleMoveToAccountCredit}>{T.MOVE_PAYMENT_TO_ACCOUNT_CREDIT}</MenuItem>}
        {showAddToGroupAction && <MenuItem onClick={handleOpenAddToInvoiceGroupDialog}>{T.ADD_TO_INVOICE_GROUP}</MenuItem>}
        {showRevertToDraftAction && (
          <MenuItem sx={{ color: 'warning.main' }} onClick={openRevertToDraftConfirmationModal}>
            {T.CHANGE_BACK_TO_DRAFT}
          </MenuItem>
        )}
        {showDeleteAction && (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteInvoiceConfirmation}>
            {T.DELETE}
          </MenuItem>
        )}
        {showDeleteUnpaidAction && (
          <MenuItem sx={{ color: 'error.main' }} onClick={openDeleteUnpaidConfirmationModal}>
            {T.DELETE}
          </MenuItem>
        )}
      </Menu>
      <InvoiceSendFailureConfirmation
        isOpen={sendInvoiceConfirmation.isOpen}
        invoices={sendInvoiceConfirmation.invoiceNumbers}
        onClose={() => setSendInvoiceConfirmation({ isOpen: false, invoiceNumbers: [] })}
      />
      <DeleteInvoiceConfirmation
        isOpen={isOpenDeleteInvoiceConfirmation}
        invoices={selectedInvoice?.invoiceNumber ? [selectedInvoice.invoiceNumber] : []}
        onClose={handleDeleteInvoiceConfirmation}
        onDelete={handleDeleteInvoice}
      />
      <DeleteUnpaidInvoiceConfirmation
        isOpen={isOpenDeleteUnpaidInvoiceConfirmation}
        onClose={closeDeleteUnpaidConfirmationModal}
        onConfirm={handleDeleteUnpaidInvoice}
      />
      <RegenerateInvoiceConfirmation
        isOpen={isOpenRegenerateInvoiceConfirmation}
        invoices={selectedInvoice ? [selectedInvoice] : []}
        onClose={handleCloseRegenerateConfirmation}
        onRegenerate={handleRegenerateInvoice}
      />
      <RevertInvoiceToDraftConfirmation
        isOpen={isOpenRevertToDraftConfirmation}
        onClose={closeRevertToDraftConfirmationModal}
        onConfirm={handleRevertToDraftInvoice}
      />
      <MoveToAccountCreditConfirmation
        isOpen={isOpenMoveToAccountCreditConfirmation}
        accountId={accountId}
        paymentNumbers={get(selectedInvoice, 'payments', []).map(({ paymentNumber }) => paymentNumber)}
        invoiceNumbers={[selectedInvoice?.invoiceNumber]}
        onClose={() => {
          setIsOpenMoveToAccountCreditConfirmation(false)
          onClose()
        }}
      />
      <AddToGroupDialog isOpen={isOpenAddToInvoiceGroupDialog} invoiceIds={[invoiceId]} onClose={handleCloseAddToInvoiceGroupDialog} />
      <CommonDrawer isOpen={isOpenEditInvoiceWOListing} className="common-drawer-container invoice-work-order" onChange={handleEditInvoice}>
        <InvoiceEdit
          isOpen={isOpenEditInvoiceWOListing}
          invoiceNumber={selectedInvoice?.invoiceNumber}
          accountId={accountId}
          accountName={selectedInvoice?.accountName}
          invoiceDate={selectedInvoice?.invoiceDate}
          invoiceId={invoiceId}
          unPostedAmountCents={selectedInvoice?.totalCents}
          invoiceStatus={selectedInvoice?.status}
          onInvoiceSideView={noop}
          onClose={handleEditInvoice}
          onRefreshTable={handleRefetch}
        />
      </CommonDrawer>
    </>
  )
}

InvoiceActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  selectedInvoice: PropTypes.object,
  showAddToGroupAction: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default InvoiceActionMenu
