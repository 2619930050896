import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from '@mui/material'
import { ALPHABET_LIST, ALPHABET_WITH_DIGITS_LIST } from 'components/locations/settings'
import noop from 'lodash/noop'

const FilterByLetterButtonGroup = ({
  withDigits = false,
  ascending = true,
  onCharacterButtonClick = noop,
  shouldDisableCharacterButton = noop,
  filterCharacter = () => true,
}) => {
  const options = withDigits ? ALPHABET_WITH_DIGITS_LIST : ALPHABET_LIST
  const sortedOptions = useMemo(() => (ascending ? options : options.toReversed()), [options, ascending])
  const sortedAndFilteredOptions = useMemo(() => sortedOptions.filter(char => filterCharacter(char)), [sortedOptions, filterCharacter])
  return (
    <ButtonGroup
      variant="text"
      color="primary"
      sx={{ minHeight: 0, overflow: 'auto', borderTopColor: 'divider', borderTopWidth: 1, borderTopStyle: 'solid', borderRadius: 0 }}
      orientation="vertical"
    >
      {sortedAndFilteredOptions.map(item => {
        const disableCharacterButton = shouldDisableCharacterButton(item)

        const handleClick = () => {
          onCharacterButtonClick(item)
        }

        return (
          <Button sx={{ textTransform: 'capitalize' }} key={item} value={item} onClick={handleClick} disabled={disableCharacterButton}>
            {item}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

FilterByLetterButtonGroup.propTypes = {
  onCharacterButtonClick: PropTypes.func,
  shouldDisableCharacterButton: PropTypes.func,
  filterCharacter: PropTypes.func,
  ascending: PropTypes.bool,
  withDigits: PropTypes.bool,
}

export default FilterByLetterButtonGroup
