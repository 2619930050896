import React, { useReducer, useEffect, useState } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Add from '@mui/icons-material/Add'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { Button, Box, Typography } from '@mui/material'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import { useLazyGetTruckListQuery } from 'api/settings/getTrucksList'
import { useAddTrucksMutation } from 'api/settings/addTrucks'
import { useLazyGetTruckByIdQuery } from 'api/settings/getTruckById'
import { SETTINGS_PAGINATION } from 'settings/constants/pagination'
import { handleError } from 'utils/error'
import T from 'T'
import { get } from 'utils/lodash'

import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { isAlphaNumeric } from 'utils/validations'

import ListAssets from './assets/ListAssets'
import AddAssets from './assets/AddAssets'

const { INITIAL_PAGE, ROWS_PER_PAGE } = SETTINGS_PAGINATION

const AssetSettings = () => {
  const dispatch = useDispatch()
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const [addNewAsset, setAddNewAsset] = useState(false)

  const [assetState, setAssetState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    assets: [],
    methods: [],
    businessLines: [],
    assetName: '',
    active: true,
    commercialDriverLicense: false,
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    selectedBusinessLines: [],
    selectedMethods: [],
    totalAssetsCount: 0,
    totalPageCount: 0,
    footerFlag: false,
    assetId: '',
  })

  const { assets, assetName, page, rowsPerPage, active, commercialDriverLicense, assetId, selectedMethods, selectedBusinessLines } =
    assetState

  const [getTruckList, { isLoading: isTruckLoading, isFetching: isTruckFetching }] = useLazyGetTruckListQuery()
  const [addTrucks, { isLoading: isTruckAddLoading, isFetching: isTruckAddFetching }] = useAddTrucksMutation()
  const [getTruckById, { isLoading: isTruckEditLoading, isFetching: isTruckEditFetching }] = useLazyGetTruckByIdQuery()

  const handleClear = () => {
    setAssetState({
      assetName: '',
      active: true,
      businessLines: get(globalFilterOptions, 'businessLines', []),
      methods: get(globalFilterOptions, 'methods', []),
      commercialDriverLicense: false,
      selectedBusinessLines: [],
      selectedMethods: [],
      footerFlag: false,
      assetId: '',
    })
  }

  const fetchAssets = (requestedPage, requestedPageSize) => {
    const payload = {
      requestedPage,
      requestedPageSize,
    }

    getTruckList(payload)
      .unwrap()
      .then(response => {
        const { totalItems, totalPages } = response
        setAssetState({
          assets: get(response, 'assets', []),
          totalAssetsCount: totalItems,
          totalPageCount: totalPages,
        })
      })
      .catch(handleError)
    handleClear()
  }

  useEffect(() => {
    dispatch(getGlobalFilterOptions())
    fetchAssets(page, rowsPerPage)
  }, [])

  useEffect(() => {
    setAssetState({ businessLines: get(globalFilterOptions, 'businessLines', []), methods: get(globalFilterOptions, 'methods', []) })
  }, [globalFilterOptions])

  const handleAdd = () => {
    setAddNewAsset(true)
  }

  const handleSave = () => {
    setAddNewAsset(false)

    const payload = {
      assetName,
      assetType: 'Truck',
      active,
      commercialDriverLicense,
      businessLines: selectedBusinessLines,
      methods: selectedMethods,
    }

    if (assetState.assetId !== '') {
      payload.assetId = assetId
    }

    addTrucks(payload)
      .unwrap()
      .then(() => {
        handleClear()
        fetchAssets(page, rowsPerPage)
      })
      .catch(handleError)
  }

  const handleEdit = id => {
    if (updateSettings) {
      setAddNewAsset(true)
      setAssetState({
        assetId: id,
      })

      const payload = {
        assetId: id,
      }

      getTruckById(payload)
        .unwrap()
        .then(res => {
          setAssetState({
            assetName: get(res, 'assetName', ''),
            active: get(res, 'active', true),
            selectedBusinessLines: get(res, 'businessLine', []),
            selectedMethods: get(res, 'method', []),
            commercialDriverLicense: get(res, 'commercialDriverLicense', false),
          })
        })
        .catch(handleError)
    }
  }

  const handleCancel = () => {
    setAddNewAsset(false)
    fetchAssets(page, rowsPerPage)
  }

  const onHandleNameChange = event => {
    const { name } = event.target

    let { value } = event.target

    if (/^\s/.test(value)) {
      value = ''
    }

    if (name === 'assetName' && !isAlphaNumeric(value)) return

    assetState[name] = value
    setAssetState({ [name]: value, footerFlag: true })
  }

  // Page change handler
  const handlePageChange = newPage => {
    setAssetState({ page: newPage })
    fetchAssets(newPage, rowsPerPage)
  }

  // Rows per page change handler
  const handleRowsPerPageChange = event => {
    setAssetState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
    fetchAssets(INITIAL_PAGE, event.target.value)
  }

  return (
    <Box minWidth={770}>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewAsset ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCancel()}
            >
              {`${T.TRUCK}s`}
            </Typography>

            {addNewAsset && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${assetId === '' ? T.NEW : T.EDIT} ${T.TRUCK}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNewAsset && addSettings && (
            <Button variant="outlined" startIcon={<Add />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>
        {addNewAsset && updateSettings && (
          <AddAssets
            assetState={assetState}
            setAssetState={setAssetState}
            isLoading={isTruckEditLoading || isTruckEditFetching}
            handleSave={handleSave}
            handleCancel={handleCancel}
            onHandleNameChange={onHandleNameChange}
          />
        )}
      </Box>

      {!addNewAsset && (
        <ListAssets
          assetState={assetState}
          isLoading={isTruckLoading || isTruckFetching || isTruckAddLoading || isTruckAddFetching}
          assets={assets}
          handlePageChange={handlePageChange}
          handleEdit={handleEdit}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Box>
  )
}

export default AssetSettings
