export const isString = str => typeof str === 'string'

export const replaceAllSpaces = str => (isString(str) ? str.replaceAll(' ', '') : str)
export const replaceAllSpacesAndAmpersand = str => (isString(str) ? str.replace(/ |& /g, '') : str)
export const getOnlyDigits = str => (isString(str) ? str.replace(/\D/g, '') : str)

// Will automatically handle the cases like ACH b/c of + pattern
export const insertSpaceBeforeCapitalLetter = str => (isString(str) ? str.replace(/([A-Z]+)/g, ' $1').trim() : str)

export const parseLHSbracketStr = str => {
  if (!str.includes('[') && !str.includes(']')) return { key: str, operation: null }
  const key = str.substring(0, str.indexOf('['))
  const operation = str.substring(str.indexOf('[') + 1, str.lastIndexOf(']'))
  return { key, operation }
}

export const extractNameInitials = name => {
  const regex = /[^\w\s]|_/g
  const initials = name.replace(regex, '').match(/\b\w/g)
  if (initials === null || initials.length < 1) {
    return ''
  }
  return initials[0].toUpperCase() + (initials.length > 1 ? initials[1].toUpperCase() : '')
}

export const generateRandomId = length => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const values = new Uint32Array(length)
  window.crypto.getRandomValues(values)
  let result = ''
  for (let i = 0; i < length; i++) {
    result += charset[values[i] % charset.length]
  }
  return result
}

export const removePrefix = (str, prefix) => {
  if (str.startsWith(prefix)) {
    return str.substring(prefix.length)
  }
  return str
}
