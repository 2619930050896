import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { Box, Typography, Link, IconButton, Tooltip, CircularProgress } from '@mui/material'
import { ReactComponent as InboxesIcon } from 'assets/Inboxes.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getCustomerDetailsPageUrl } from 'router/routes'
import { useLazyGetAccountContactsQuery } from 'api/accounts/getAccountContacts'
import { getAccountEmailContacts } from 'components/customer-details/content/customer-portal/add-portal-user/utils'

const RenderAccountCell = ({ row = {}, onInviteAll }) => {
  const { watch, setValue } = useFormContext()
  const [getAccountContacts, { isFetching: isGetAccountContactsLoading }] = useLazyGetAccountContactsQuery()
  const { accountId, accountName, accountNumber, billingAddress, accountContactCount } = row
  const clickedAccountId = watch('clickedAccountId')

  const handleInvite = async event => {
    event.stopPropagation()
    setValue('clickedAccountId', accountId)
    const result = await getAccountContacts({ accountIds: [accountId] }, true)
      .unwrap()
      .catch(handleError)
    const contacts = get(result, `contactsByAccount.${accountId}`, [])
    const emailContacts = getAccountEmailContacts(contacts, [], true).map(({ methodValue, firstName = '', lastName = '' }) => ({
      email: methodValue,
      firstName,
      lastName,
    }))
    onInviteAll(accountId, emailContacts)
  }

  return (
    <Box py={1} width="100%" display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column" gap={0.5} overflow="hidden">
        <Box display="flex" gap={0.5}>
          <Typography variant="subtitle1" whiteSpace="nowrap">
            <Link
              component={RouterLink}
              to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              {accountNumber}
            </Link>
          </Typography>
          <Typography variant="h6" fontWeight={500} color="textPrimary" noWrap>
            {accountName}
          </Typography>
        </Box>
        <Typography variant="subtitle2" noWrap>
          {`${accountContactCount} contact${accountContactCount > 1 ? 's' : ''} | ${billingAddress}`}
        </Typography>
      </Box>
      {isGetAccountContactsLoading || clickedAccountId === accountId ? (
        <CircularProgress size={30} />
      ) : (
        <Tooltip placement="right" title={`Invite all email contacts for ${accountName} - ${accountNumber}`}>
          <IconButton disabled={accountContactCount === 0} onClick={handleInvite}>
            <InboxesIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
  onInviteAll: PropTypes.func.isRequired,
}

export default RenderAccountCell
