import { styled } from '@mui/material'

const PrintSettingsWrapper = styled('div')`
  /* Hides url and title when printing */
  @page {
    size: auto;
    margin: 20px 30px;
  }
  html,
  body {
    height: 100%;
  }
  height: 100%;
  width: 100%;
`
export default PrintSettingsWrapper
