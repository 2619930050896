import api from 'api'
import Constants from 'Constants'

const { SERVER_PUBLIC_URL } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllBusinessesByEmail: builder.query({
      query: email => ({
        method: 'GET',
        url: `${SERVER_PUBLIC_URL}/api/v1/crm-user/${email}`,
      }),
    }),
  }),
})

export const { useLazyGetAllBusinessesByEmailQuery } = extendedApi
