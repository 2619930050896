import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import invert from 'lodash/invert'

import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { yellow, green, orange, red, blue, purple } from '@mui/material/colors'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'

import { get } from 'utils/lodash'
import { PAYMENT_METHODS, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'
import { getPaymentsFromBatch, getPaymentTypeStats } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import { setChartSummaryDialogState } from 'slices/groups/PaymentGroupsSlice'
import T from 'T'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'
import GraphCardEmptyState from '../common/GraphCardEmptyState'
import ChartLegend from '../common/ChartLegend'
import PaymentStatsTooltip from './common/PaymentStatsTooltip'
import PaymentStatsGroupTooltip from './common/PaymentStatsGroupTooltip'

const { CHECK, CASH, OTHER, CREDIT_CARD, ACH, ACCOUNT_CREDIT } = PAYMENT_METHODS

const COLOR_MAP = {
  [CHECK]: yellow[100],
  [CASH]: green[100],
  [CREDIT_CARD]: orange[100],
  [ACH]: red[100],
  [OTHER]: blue[100],
  [ACCOUNT_CREDIT]: purple[100],
}

const PaymentTypeStatistic = ({ id, showFooter = true, groupedTooltip = true }) => {
  const dispatch = useDispatch()
  const { data: groupDetails } = useGetPaymentGroupDetailsQuery({ id })
  const payments = getPaymentsFromBatch({ paymentBatches: get(groupDetails, 'paymentBatches', []) })
  const paymentTypeData = getPaymentTypeStats({ payments })
  const check = get(paymentTypeData, `${CHECK}.count`, 0)
  const cash = get(paymentTypeData, `${CASH}.count`, 0)
  const creditCard = get(paymentTypeData, `${CREDIT_CARD}.count`, 0)
  const ach = get(paymentTypeData, `${ACH}.count`, 0)
  const other = get(paymentTypeData, `${OTHER}.count`, 0)
  const accountCredit = get(paymentTypeData, `${ACCOUNT_CREDIT}.count`, 0)
  const totalCount = check + cash + creditCard + ach + other + accountCredit
  const isChartEmpty = totalCount === 0
  const invertedLabels = invert(PAYMENT_METHODS_LABELS)

  const data = [
    {
      id: PAYMENT_METHODS_LABELS[CHECK],
      label: PAYMENT_METHODS_LABELS[CHECK],
      value: check,
      color: COLOR_MAP[CHECK],
    },
    {
      id: PAYMENT_METHODS_LABELS[CASH],
      label: PAYMENT_METHODS_LABELS[CASH],
      value: cash,
      color: COLOR_MAP[CASH],
    },
    {
      id: PAYMENT_METHODS_LABELS[CREDIT_CARD],
      label: PAYMENT_METHODS_LABELS[CREDIT_CARD],
      value: creditCard,
      color: COLOR_MAP[CREDIT_CARD],
    },
    {
      id: PAYMENT_METHODS_LABELS[ACH],
      label: PAYMENT_METHODS_LABELS[ACH],
      value: ach,
      color: COLOR_MAP[ACH],
    },
    {
      id: PAYMENT_METHODS_LABELS[OTHER],
      label: PAYMENT_METHODS_LABELS[OTHER],
      value: other,
      color: COLOR_MAP[OTHER],
    },
    {
      id: PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT],
      label: PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT],
      value: accountCredit,
      color: COLOR_MAP[ACCOUNT_CREDIT],
    },
  ]

  const renderPaymentTypeTooltip = params => {
    const { label, value, color } = params?.datum || {}
    const { rows, total } = get(paymentTypeData, invertedLabels[label])
    if (groupedTooltip) {
      return <PaymentStatsGroupTooltip color={color} label={label} value={value} total={total} />
    }
    return <PaymentStatsTooltip color={color} label={label} value={value} total={total} rows={rows} />
  }

  const handleClick = ({ label, value, color }) => {
    if (value > 0) {
      const { rows, total } = get(paymentTypeData, invertedLabels[label])
      dispatch(setChartSummaryDialogState({ isOpen: true, color, label, value, total, rows, isBatch: false }))
    }
  }

  return (
    <>
      {isChartEmpty && <GraphCardEmptyState title={T.NO_PAYMENT_DATA} />}
      {!isChartEmpty && (
        <>
          <Box height={250} position="relative">
            <GroupPieChart
              data={data}
              colors={Object.values(COLOR_MAP)}
              legends={[]}
              tooltip={renderPaymentTypeTooltip}
              onClick={handleClick}
            />
            <GroupChartOverlay overlayValue={totalCount} />
          </Box>
          <ChartLegend data={data} mt={-3.5} />
        </>
      )}
      {showFooter && <GroupChartName Icon={CurrencyDollar} label={capitalize(T.PAYMENT_TYPE)} />}
    </>
  )
}

PaymentTypeStatistic.propTypes = {
  id: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
  groupedTooltip: PropTypes.bool,
}

export default PaymentTypeStatistic
