import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@mui/material'

const urlRegex = /\b((https?:\/\/|www\.|localhost)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?::\d+)?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?)\b/g

const createElements = text => {
  const elements = []
  let lastIndex = 0
  for (const match of text.matchAll(urlRegex)) {
    const { 0: url, index } = match
    if (lastIndex < index) {
      elements.push({ type: 'text', content: text.slice(lastIndex, index) })
    }
    elements.push({ type: 'link', content: url })
    lastIndex = index + url.length
  }
  if (lastIndex < text.length) {
    elements.push({ type: 'text', content: text.slice(lastIndex) })
  }

  return elements
}

const LinkifyText = ({ children = '' }) => {
  const elements = useMemo(() => createElements(children), [children])

  return (
    <>
      {elements.map((element, index) => {
        if (element.type === 'link') {
          const url = element.content.startsWith('http') ? element.content : `http://${element.content}`
          return (
            <Link key={`link-${index}`} href={url} target="_blank" rel="noopener noreferrer">
              {element.content}
            </Link>
          )
        }
        return <span key={`text-${index}`}>{element.content}</span>
      })}
    </>
  )
}

LinkifyText.propTypes = {
  children: PropTypes.string,
}

export default LinkifyText
