import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import MapView from '../location/MapView'

const { LIGHT_VIEW } = MAP_DEFAULT_OPTIONS

const MapPreview = ({ currentLongitude, currentLatitude, defaultLatitude, defaultLongitude, setCurrentCoords }) => {
  const setCoords = (long, lat) => {
    setCurrentCoords({ currentLatitude: lat, currentLongitude: long })
  }

  const onChangeCoords = ({ mapLng, mapLat }) => setCoords(mapLng, mapLat)

  const onResetCoords = () => setCoords(defaultLongitude, defaultLatitude)

  return (
    <Box height={250}>
      <MapView
        defaultMapView={LIGHT_VIEW}
        longitude={currentLongitude}
        latitude={currentLatitude}
        longitudeForMapHistory={defaultLatitude}
        latitudeForMapHistory={defaultLongitude}
        onChange={onChangeCoords}
        onReset={onResetCoords}
      />
    </Box>
  )
}

MapPreview.propTypes = {
  defaultLatitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultLongitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentLatitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentLongitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setCurrentCoords: PropTypes.func.isRequired,
}

export default MapPreview
