import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, SvgIcon } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as VacationIcon } from 'assets/services/hold/VacationIcon.svg'
import { purple } from '@mui/material/colors'

const VacationHoldChip = ({ workOrderId, label, size = 'small', ...rest }) => {
  const theme = useTheme()
  const linkSx = { cursor: 'pointer', '&:hover .MuiChip-label': { textDecoration: 'underline' } }
  return (
    <Chip
      sx={{
        ...(workOrderId && linkSx),
        fontWeight: 600,
        border: '1px solid',
        borderColor: purple[300],
        backgroundColor: purple[50],
        color: purple[500],
      }}
      size={size}
      variant="outlined"
      avatar={
        <Avatar
          sx={{
            backgroundColor: purple[300],
            '& svg': {
              width: '14px',
            },
          }}
        >
          <SvgIcon
            sx={{
              color: theme.palette.common.white,
            }}
          >
            <VacationIcon />
          </SvgIcon>
        </Avatar>
      }
      label={label || 'Vacation hold'}
      {...rest}
    />
  )
}

VacationHoldChip.propTypes = {
  workOrderId: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
}

export default VacationHoldChip
