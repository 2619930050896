import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Menu, MenuItem, Popper, Tooltip, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { Trash } from '@styled-icons/heroicons-outline/Trash'
import { Archive } from '@styled-icons/heroicons-outline/Archive'
import { ReactComponent as TruckHiddenOutlinedIcon } from 'assets/TruckHiddenOutlined.svg'
import { ReactComponent as ThumbtackOutlinedIcon } from 'assets/ThumbtackOutlined.svg'
import { ReactComponent as ClipboardListIcon } from 'assets/ClipboardList.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { CommonSwitch } from 'components/common'
import { putIsLoading } from 'middleware/actions/response'
import { usePatchCSNoteMutation, useDeleteCSNoteMutation } from 'api/notes/configuredServiceNotesCrud'

import T from 'T'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { getArchiveDescriptionAndTitle, getDeleteDescriptionAndTitle, getMobileVisibilityDescriptionAndTitle } from './utils'

const POPPER_COMMON_STYLES = { zIndex: zIndex.modal, backgroundColor: 'common.white' }

const NoteActionMenu = ({ id, anchorEl, showDeleteOption = false, selectedNote = {}, onEditClick, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [patchCSNote, { isLoading: isPatchCSNoteLoading }] = usePatchCSNoteMutation()
  const [deleteCSNote, { isLoading: isDeleteCSNoteLoading }] = useDeleteCSNoteMutation()

  const [isOpenMobileVisibilityMenu, setIsOpenMobileVisibilityMenu] = useState(null)
  const [isOpenArchiveMenu, setIsOpenArchiveMenu] = useState(null)
  const [isOpenDeleteMenu, setIsOpenDeleteMenu] = useState(null)
  const [mobileVisibiltyDialog, setMobileVisibiltyDialog] = useState({ isOpen: false, props: {} })
  const [archiveDialog, setArchiveDialog] = useState({ isOpen: false, props: {} })
  const [deleteDialog, setDeleteDialog] = useState({ isOpen: false, props: {} })
  const noteId = get(selectedNote, 'id')
  const isNotePinned = get(selectedNote, 'pinned', false)
  const isVisibleOnMobile = get(selectedNote, 'visibleOnMobile', false)
  const isInherit = get(selectedNote, 'inherit', false)

  const { description: visibilityDescription = '', title: visibilityTitle = '' } = getMobileVisibilityDescriptionAndTitle(
    mobileVisibiltyDialog.props.visibleOnMobile,
    mobileVisibiltyDialog.props.updateInherited
  )
  const { description: archiveDescription = '', title: archiveTitle = '' } = getArchiveDescriptionAndTitle(
    archiveDialog.props.updateServiceNote,
    archiveDialog.props.updateInherited
  )
  const { description: deleteDescription = '', title: deleteTitle = '' } = getDeleteDescriptionAndTitle(
    deleteDialog.props.deleteServiceNote,
    deleteDialog.props.deleteInherited
  )

  const handleEditClick = () => {
    onClose()
    onEditClick()
  }

  const handlePinUnpin = () => {
    dispatch(putIsLoading(true))
    onClose()
    patchCSNote({ id, noteId, data: { pinned: !isNotePinned } })
      .unwrap()
      .then(() => toast.success(`Note ${isNotePinned ? 'unpinned' : 'pinned'} successfully`))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleOpenMobileVisibiltyMenu = event => {
    if (isOpenMobileVisibilityMenu !== event.currentTarget) {
      setIsOpenMobileVisibilityMenu(event.currentTarget)
    }
  }

  const handleCloseMobileVisibiltyMenu = () => setIsOpenMobileVisibilityMenu(null)

  const handleOpenMobileVisibiltyDialog = props => {
    handleCloseMobileVisibiltyMenu()
    onClose()
    setMobileVisibiltyDialog({ isOpen: true, props })
  }

  const handleCloseMobileVisibiltyDialog = () => setMobileVisibiltyDialog({ isOpen: false, props: {} })

  const handleMobileVisibilty = () => {
    patchCSNote({ id, noteId, data: { ...mobileVisibiltyDialog.props } })
      .unwrap()
      .then(() => {
        toast.success('Note visibility changed successfully')
        handleCloseMobileVisibiltyDialog()
      })
      .catch(handleError)
  }

  const handleOpenArchiveMenu = event => {
    if (isOpenArchiveMenu !== event.currentTarget) {
      setIsOpenArchiveMenu(event.currentTarget)
    }
  }

  const handleCloseArchiveMenu = () => setIsOpenArchiveMenu(null)

  const handleOpenArchiveDialog = props => {
    handleCloseArchiveMenu()
    onClose()
    setArchiveDialog({ isOpen: true, props })
  }

  const handleCloseArchiveDialog = () => setArchiveDialog({ isOpen: false, props: {} })

  const handleArchive = () => {
    patchCSNote({ id, noteId, data: { archived: true, ...archiveDialog.props } })
      .unwrap()
      .then(() => {
        toast.success('Note archived successfully')
        handleCloseArchiveDialog()
      })
      .catch(handleError)
  }

  const handleOpenDeleteMenu = event => {
    if (isOpenDeleteMenu !== event.currentTarget) {
      setIsOpenDeleteMenu(event.currentTarget)
    }
  }

  const handleCloseDeleteMenu = () => setIsOpenDeleteMenu(null)

  const handleOpenDeleteDialog = props => {
    handleCloseDeleteMenu()
    onClose()
    setDeleteDialog({ isOpen: true, props })
  }

  const handleCloseDeleteDialog = () => setDeleteDialog({ isOpen: false, props: {} })

  const handleDelete = () => {
    deleteCSNote({ id, noteId, data: { ...deleteDialog.props } })
      .unwrap()
      .then(() => {
        toast.success('Note deleted successfully')
        handleCloseDeleteDialog()
      })
      .catch(handleError)
  }

  const handleToggleAddToFutureWorkOrders = () => {
    dispatch(putIsLoading(true))
    onClose()
    patchCSNote({ id, noteId, data: { inherit: !isInherit } })
      .unwrap()
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined', sx: { minWidth: 300 } } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <PencilAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.EDIT}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePinUnpin}>
          <ListItemIcon sx={{ mr: 1 }}>
            <ThumbtackOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{isNotePinned ? T.UNPIN : T.PIN}</ListItemText>
        </MenuItem>
        <Tooltip title={T.NEW_WO_GENERATE_EVERY_SATURDAY} placement="left">
          <MenuItem onClick={handleToggleAddToFutureWorkOrders}>
            <ListItemIcon sx={{ mr: 1 }}>
              <ClipboardListIcon />
            </ListItemIcon>
            <ListItemText>{T.ADD_TO_NEW_WORK_ORDERS}</ListItemText>
            <CommonSwitch isChecked={isInherit} />
          </MenuItem>
        </Tooltip>
        <MenuItem onMouseOver={handleOpenMobileVisibiltyMenu} onMouseLeave={handleCloseMobileVisibiltyMenu}>
          <ListItemIcon sx={{ mr: 1 }}>
            <TruckHiddenOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{T.MOBILE_VISIBILITY}</ListItemText>
          <KeyboardArrowRightIcon fontSize="small" sx={{ color: theme.palette.action.active }} />
          <Popper
            sx={{ ...POPPER_COMMON_STYLES }}
            anchorEl={isOpenMobileVisibilityMenu}
            open={Boolean(isOpenMobileVisibilityMenu)}
            placement="right-start"
          >
            <MenuItem
              onClick={() =>
                handleOpenMobileVisibiltyDialog({ visibleOnMobile: !isVisibleOnMobile, updateServiceNote: true, updateInherited: false })
              }
            >
              {`${isVisibleOnMobile ? 'Hide' : 'Show'} service note`}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenMobileVisibiltyDialog({ visibleOnMobile: true, updateServiceNote: false, updateInherited: true })}
            >
              {T.SHOW_FOR_ALL_WORK_ORDERS}
            </MenuItem>
            <MenuItem
              onClick={() => handleOpenMobileVisibiltyDialog({ visibleOnMobile: false, updateServiceNote: false, updateInherited: true })}
            >
              {T.HIDE_FOR_ALL_WORK_ORDERS}
            </MenuItem>
          </Popper>
        </MenuItem>
        <MenuItem sx={{ color: 'warning.main' }} onMouseOver={handleOpenArchiveMenu} onMouseLeave={handleCloseArchiveMenu}>
          <ListItemIcon sx={{ mr: 1, color: 'warning.main' }}>
            <Archive width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.ARCHIVE}</ListItemText>
          <KeyboardArrowRightIcon fontSize="small" sx={{ color: theme.palette.action.active }} />
          <Popper sx={{ ...POPPER_COMMON_STYLES }} anchorEl={isOpenArchiveMenu} open={Boolean(isOpenArchiveMenu)} placement="right-start">
            <MenuItem onClick={() => handleOpenArchiveDialog({ updateServiceNote: true, updateInherited: false })}>
              {T.ARCHIVE_SERVICE_ONLY}
            </MenuItem>
            <MenuItem onClick={() => handleOpenArchiveDialog({ updateServiceNote: false, updateInherited: true })}>
              {T.ARCHIVE_WORK_ORDERS_ONLY}
            </MenuItem>
            <MenuItem onClick={() => handleOpenArchiveDialog({ updateServiceNote: true, updateInherited: true })}>Archive both</MenuItem>
          </Popper>
        </MenuItem>
        {showDeleteOption && (
          <MenuItem sx={{ color: 'error.main' }} onMouseOver={handleOpenDeleteMenu} onMouseLeave={handleCloseDeleteMenu}>
            <ListItemIcon sx={{ mr: 1, color: 'error.main' }}>
              <Trash width={24} height={24} />
            </ListItemIcon>
            <ListItemText>{T.DELETE}</ListItemText>
            <KeyboardArrowRightIcon fontSize="small" sx={{ color: theme.palette.action.active }} />
            <Popper sx={{ ...POPPER_COMMON_STYLES }} anchorEl={isOpenDeleteMenu} open={Boolean(isOpenDeleteMenu)} placement="right-start">
              <MenuItem onClick={() => handleOpenDeleteDialog({ deleteServiceNote: true, deleteInherited: false })}>
                {T.DELETE_SERVICE_ONLY}
              </MenuItem>
              <MenuItem onClick={() => handleOpenDeleteDialog({ deleteServiceNote: false, deleteInherited: true })}>
                {T.DELETE_WORK_ORDERS_ONLY}
              </MenuItem>
              <MenuItem onClick={() => handleOpenDeleteDialog({ deleteServiceNote: true, deleteInherited: true })}>Delete both</MenuItem>
            </Popper>
          </MenuItem>
        )}
      </Menu>
      <HHConfirmDialog
        isOpen={mobileVisibiltyDialog.isOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmTitle={visibilityTitle}
        confirmDescription={visibilityDescription}
        cancelButtonTitle={T.CANCEL}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ loading: isPatchCSNoteLoading, color: 'primary' }}
        onConfirm={handleMobileVisibilty}
        onClose={handleCloseMobileVisibiltyDialog}
      />
      <HHConfirmDialog
        isOpen={archiveDialog.isOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmTitle={archiveTitle}
        confirmDescription={archiveDescription}
        cancelButtonTitle={T.CANCEL}
        confirmButtonTitle={T.ARCHIVE}
        ConfirmButtonProps={{ loading: isPatchCSNoteLoading, color: 'warning' }}
        onConfirm={handleArchive}
        onClose={handleCloseArchiveDialog}
      />
      <HHConfirmDialog
        isOpen={deleteDialog.isOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmTitle={deleteTitle}
        confirmDescription={deleteDescription}
        cancelButtonTitle={T.CANCEL}
        confirmButtonTitle={T.DELETE}
        ConfirmButtonProps={{ loading: isDeleteCSNoteLoading }}
        onConfirm={handleDelete}
        onClose={handleCloseDeleteDialog}
      />
    </>
  )
}

NoteActionMenu.propTypes = {
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  showDeleteOption: PropTypes.bool,
  selectedNote: PropTypes.object,
  onEditClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default NoteActionMenu
