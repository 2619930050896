import React, { useEffect } from 'react'
import { Box, useTheme } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import WOReviewDialogAppbar from './WOReviewDialogAppbar'
import { resetWOReviewState, setWOReviewApplyFilters, setWOReviewGeneralFeeList } from '../../../slices/billing/workOrdersReviewSlice'
import { setWOReviewRouteIdList } from '../../../slices/billing/persistentWorkOrdersReviewSlice'
import WOReviewDataGrid from './WOReviewDataGrid/WOReviewDataGrid'
import { useGetRouteMetadataQuery } from '../../../api/route/getRouteMetadata'
import WithGeneralFeesDialogForm from './WithGeneralFeesDialogForm'
import WithDisposalTicketsDialogForm from './WithDisposalTicketsDialogForm'
import { WOReviewProvider } from './WOReviewDataGrid/WOReviewProvider'
import { deserializeDate } from '../../../utils/date'
import { useGetGeneralFeeListMutation } from '../../../api/pricing/getGeneralFeeList'
import { isPriceTypeDollar } from '../../../utils/service'
import { generalFeeDefaultFilter } from '../details/invoice-details/filters'
import WithFiltersDialog from './WithFiltersDialog'

const options = [
  { id: 'Swap', label: 'Swap', value: 'Swap' },
  { id: 'Delivery', label: 'Delivery', value: 'Delivery' },
  { id: 'Dump & Return', label: 'Dump & Return', value: 'Dump & Return' },
  { id: 'Drop', label: 'Drop', value: 'Drop' },
  { id: 'Live load', label: 'Live load', value: 'Live load' },
  { id: 'Reposition', label: 'Reposition', value: 'Reposition' },
  { id: 'Final', label: 'Final', value: 'Final' },
  { id: 'Collection', label: 'Collection', value: 'Collection' },
  { id: 'Rental', label: 'Rental', value: 'Rental' },
  { id: 'Concierge', label: 'Concierge', value: 'Concierge' },
]

const WorkOrdersReviewDialog = () => {
  const theme = useTheme()
  const { isLoading, data: metaData } = useGetRouteMetadataQuery()
  const [getGeneralFeeList] = useGetGeneralFeeListMutation()

  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const serializedServiceDateRange = useSelector(state => state.workOrdersReview.serializedServiceDateRange, shallowEqual)
  const routeIdList = useSelector(state => state.persistentWorkOrdersReview.routeIdList, shallowEqual)
  const applyFilters = useSelector(state => state.workOrdersReview.filtersDialog.applyFilters, shallowEqual)
  const serviceDateRange = serializedServiceDateRange.map(deserializeDate)
  const methods = useForm({
    defaultValues: {
      dateRange: serviceDateRange,
      selectedRouteIds: routeIdList,
      allRoutesSelected: false,
      allActionsSelected: false,
      actions: options,
    },
  })
  const { reset } = methods

  const updateGeneralFees = payload => {
    const feeAndCharges = get(payload, 'feeAndCharges', []).filter(fee => fee?.feeName && isPriceTypeDollar(fee?.valueType))
    dispatch(setWOReviewGeneralFeeList(feeAndCharges))
  }

  useEffect(() => {
    let selectedRouteIds = []
    let allRoutesSelected = false
    const allRoutes = get(metaData, 'routes', [])
    if (routeIdList.length === 0) {
      const routeIds = allRoutes.map(({ id }) => id)
      dispatch(setWOReviewRouteIdList(routeIds))
      selectedRouteIds = allRoutes
      if (routeIds.length !== 0) {
        dispatch(setWOReviewApplyFilters(!applyFilters))
      }
    } else {
      selectedRouteIds = allRoutes.filter(({ id }) => routeIdList.includes(id))
      const selectedRouteIdsSet = new Set(routeIdList)
      const containsAllIds = allRoutes.every(({ id }) => selectedRouteIdsSet.has(id))
      if (containsAllIds) {
        allRoutesSelected = true
      }
    }
    reset({
      dateRange: serviceDateRange,
      selectedRouteIds,
      allRoutesSelected,
      allActionsSelected: false,
      actions: options,
    })
  }, [metaData])

  useEffect(() => {
    getGeneralFeeList(generalFeeDefaultFilter)
      .unwrap()
      .then(payload => {
        updateGeneralFees(payload)
      })
      .catch(() => {
        dispatch(setWOReviewGeneralFeeList([]))
      })
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetWOReviewState())
    }
  }, [])

  return (
    <WOReviewProvider apiRef={apiRef}>
      <WithGeneralFeesDialogForm>
        <WithDisposalTicketsDialogForm>
          <FormProvider {...methods}>
            <WithFiltersDialog>
              <WOReviewDialogAppbar />
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  minWidth: '900px',
                  overflowX: 'hidden',
                  padding: 0,
                  bgcolor: theme.palette.background.paper,
                }}
              >
                <Box flex="1" position="relative">
                  <Box sx={{ position: 'absolute', inset: 0, overflowX: 'hidden' }}>
                    <WOReviewDataGrid routesLoading={isLoading} apiRef={apiRef} />
                  </Box>
                </Box>
              </Box>
            </WithFiltersDialog>
          </FormProvider>
        </WithDisposalTicketsDialogForm>
      </WithGeneralFeesDialogForm>
    </WOReviewProvider>
  )
}

export default WorkOrdersReviewDialog
