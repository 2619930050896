import { BEAMER_SCRIPT_ID } from 'components/header/settings'

export const initializeBeamer = (id, args = {}) => {
  if (!window) {
    return
  }

  try {
    window.beamer_config = { product_id: id, ...args }

    const beamerScript = document.getElementById(BEAMER_SCRIPT_ID)
    if (beamerScript) {
      return
    }

    const beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js'
    const script = document.createElement('script')

    script.defer = 'defer'
    script.src = beamerURL
    script.id = BEAMER_SCRIPT_ID
    document.body.appendChild(script)
    window?.Beamer.init()
  } catch (error) {}
}
