import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, useTheme } from '@mui/material'

const GroupChartName = ({ Icon, label = '' }) => {
  const theme = useTheme()

  return (
    <Box mt={1.5} display="flex" alignItems="center" justifyContent="center">
      <Icon width={24} height={24} style={{ color: theme.palette.action.active }} />
      <Typography ml={0.5}>{label}</Typography>
    </Box>
  )
}

GroupChartName.propTypes = {
  Icon: PropTypes.node,
  label: PropTypes.string,
}

export default GroupChartName
