import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { noop } from 'lodash'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, DialogTitle, Dialog, Grid, useTheme } from '@mui/material'
import { HHFormSwitchField } from 'components/form-fields/v5'
import T from 'T'
import DisposalTicketTaxInfoIcon from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalTicketTaxInfoIcon'
import DisposalTicketForm from './details/disposal-ticket/DisposalTicketAccordion/DisposalTicketForm'

const DisposalTicketModal = ({ workOrderId, configuredServiceId, open = false, onClose = noop }) => {
  const theme = useTheme()
  const { control } = useForm()
  const isTaxable = Boolean(localStorage.getItem('isDisposalFeeTaxable'))
  const methods = useForm({
    defaultValues: {
      disposalTicketId: '',
      ticketNumber: '',
      disposalSiteId: '',
      materials: [
        {
          id: undefined,
          disposalSiteMaterialId: '',
          quantity: '',
          measureUnit: '',
          tippingFee: '',
          unitPriceDollars: '',
          isTaxable,
        },
      ],
    },
  })
  const onSubmitSuccess = () => {
    onClose()
  }

  const onDeleteConfirmationModalProceed = () => {
    onClose()
  }

  return (
    <FormProvider {...methods}>
      <Dialog maxWidth="lg" sx={{ zIndex: theme.zIndex.tooltip + 10 }} open={open} onClose={onClose}>
        <DialogTitle
          sx={{
            ...theme.typography.h4,
            fontWeight: 600,
            py: 2,
            pl: 3,
            pr: 2,
            m: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item xs>
              Disposal ticket
            </Grid>
            <Grid item>
              <Grid item container columnGap={1} alignItems="center" wrap="nowrap">
                <Grid item>
                  <HHFormSwitchField control={control} name="isTaxable" label={T.TAXABLE} />
                </Grid>
                <Grid item>
                  <DisposalTicketTaxInfoIcon />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: 'active',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {open && (
          <DisposalTicketForm
            onSubmitSuccess={onSubmitSuccess}
            onClose={onClose}
            variant="modal"
            disablePadding
            isFormAlwaysVisible
            isFormEditable
            workOrderId={workOrderId}
            onDeleteConfirmationModalProceed={onDeleteConfirmationModalProceed}
            configuredServiceId={configuredServiceId}
          />
        )}
      </Dialog>
    </FormProvider>
  )
}

DisposalTicketModal.propTypes = {
  workOrderId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  configuredServiceId: PropTypes.string.isRequired,
}

export default DisposalTicketModal
