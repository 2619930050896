import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { green } from '@mui/material/colors'
import { get } from 'utils/lodash'
import { formatPhone } from 'utils/helper'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { parseAddressSelector } from 'data/groups/invoicePrintSelectors'
import { addHashIfNeeded } from '../helpers/address'
import { getTimeZoneDetails, formatDateTimeToPreferedTimezone } from '../helpers/date'

const Header = ({ invoice = {} }) => {
  const business = get(invoice, 'business', {})
  const businessAddress = parseAddressSelector({ addressStr: get(business, 'address') })
  const { line1, city, state, zipCode } = businessAddress
  const logoUrl = get(business, 'logoURL')

  const account = get(invoice, 'account', {})
  const billingAddress = get(account, 'billingAddress', {})
  const unitNumber = addHashIfNeeded(get(billingAddress, 'unitNumber', ''))

  const { timeZone } = getTimeZoneDetails(invoice.business?.timeZone)
  const { onlyDate: paidAt } = formatDateTimeToPreferedTimezone(invoice?.paidAt, timeZone)

  return (
    <Box className="text-sm" width="100%" display="flex" flexDirection="column">
      <Box display="flex" width="100%" alignItems="flex-start" justifyContent="space-between">
        <Box position="relative" component="div">
          <Box position="absolute" top={24} component="div">
            <Box component="p" textTransform="uppercase" whiteSpace="pre" className="leading-tight" pl={5}>
              {business.businessTitle && (
                <>
                  {business.businessTitle}
                  <br />
                </>
              )}
              {line1}
              <br />
              {city && city}
              {(state || zipCode) && `${city ? ', ' : ''}${[state, zipCode].filter(Boolean).join(' ')}`}
              {business.phoneNumber && (
                <>
                  <br />
                  {formatPhone(business.phoneNumber)}
                </>
              )}
              {business.website && (
                <>
                  <br />
                  <Box component="span" textTransform="none">
                    {business.website}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {logoUrl && (
          <Box sx={{ '& img': { height: 112, float: 'right' } }}>
            <img src={logoUrl} alt="Business Logo" />
          </Box>
        )}
      </Box>

      <Box display="flex" width="100%" alignItems="flex-start" justifyContent="space-between" py={2} pl={5}>
        <Box position="relative" component="div" width="100%">
          <Box position="absolute" top={16} component="div">
            <Box mb={1} className="font-bold">
              Invoice for
            </Box>

            {billingAddress.attention && (
              <Box component="p" textTransform="uppercase" fontWeight={900} className="leading-tight">
                ATTN:
                <Box component="span" fontWeight={400}>
                  {` ${billingAddress.attention}`}
                </Box>
              </Box>
            )}
            <Box component="p" textTransform="uppercase" className="leading-tight">
              {get(account, 'accountName', '')}
              <br />
              {[billingAddress.line1, unitNumber].filter(Boolean).join(', ')}
              <br />
              {billingAddress.city && billingAddress.city}
              {(billingAddress.state || billingAddress.zipCode) &&
                `${billingAddress.city ? ', ' : ''}${[billingAddress.state, billingAddress.zipCode].filter(Boolean).join(' ')}`}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end" justifyContent="center">
          {invoice.status === 'Paid' && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mr={2}
              borderRadius="9999px"
              width={88}
              height={88}
              border="2px solid"
              borderColor={green[500]}
            >
              <Box color={green[500]} textTransform="uppercase" className="font-bold">
                {invoice.status}
              </Box>
              {paidAt && (
                <Box component="p" fontWeight={600} className="text-sm">
                  {paidAt}
                </Box>
              )}
            </Box>
          )}

          <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
            <Box width={288} pt={2} display="flex" justifyContent="space-between">
              <Box className="font-bold">Account #:</Box>
              <Box>{account.accountNumber}</Box>
            </Box>
            {account.po && (
              <Box width={288} display="flex" justifyContent="space-between">
                <Box className="font-bold">Acct PO #:</Box>
                <Box>{account.po}</Box>
              </Box>
            )}
            <Box width={288} display="flex" justifyContent="space-between">
              <Box className="font-bold">Invoice #:</Box>
              <Box>{invoice.invoiceNumber}</Box>
            </Box>
            <Box width={288} display="flex" justifyContent="space-between">
              <Box className="font-bold">Invoice date:</Box>
              <Box>{invoice.createdAt}</Box>
            </Box>
            <Box pb={0.5} borderBottom="2px solid" borderColor="divider" width={288} display="flex" justifyContent="space-between">
              <Box className="font-bold">Due date:</Box>
              <Box>{account.paymentTerms === 'Due on Receipt' ? 'Due upon receipt' : invoice.dueDate}</Box>
            </Box>
            <Box width={288} display="flex" justifyContent="space-between">
              <Box className="font-bold">Invoice total:</Box>
              <Box>
                <HHDisplayMoney value={invoice.total} formatToDollars={false} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Header.propTypes = {
  invoice: PropTypes.object,
}

export default Header
