import React from 'react'
import PropTypes from 'prop-types'
import { CommonTextLabel } from 'components/common'

import AutoComplete from 'components/common/AutoComplete'
import { FORM_MEASURE_OPTIONS } from 'components/pricing/settings'

import { getMeasureLabel } from 'components/pricing/pricingMethods'

import T from 'T'
import { get } from 'utils/lodash'

const MeasureMaterialAutoComplete = ({ globalFilterOptions, checkedMeasure, checkedMaterials, handleCheckboxValue, backFlag, type }) => {
  return (
    <>
      <div className="measures left-elements-width mt15">
        <CommonTextLabel isRequired={type === 'disposal'}>{`${T.MEASURE}s`}</CommonTextLabel>
        <AutoComplete
          backFlag={backFlag}
          isHandleCheckbox
          metaData={type === 'disposal' ? FORM_MEASURE_OPTIONS : get(globalFilterOptions, 'measure', [])}
          label={`${T.MEASURE}s`}
          getByLabelText={option => getMeasureLabel(option)}
          handleCheckboxValue={handleCheckboxValue}
          checkedRecords={checkedMeasure}
          getPlaceholder={checkedMeasure.length ? '' : `Select ${T.MEASURE}s`}
        />
      </div>

      <div className="materials left-elements-width mt15">
        <div className="service-label">{`${T.MATERIAL}s`}</div>

        <AutoComplete
          backFlag={backFlag}
          isHandleCheckbox
          metaData={get(globalFilterOptions, 'materials', [])}
          label={`${T.MATERIAL}s`}
          getByLabelText={option => option.materialType}
          handleCheckboxValue={handleCheckboxValue}
          checkedRecords={checkedMaterials}
          getPlaceholder={checkedMaterials.length ? '' : `Select ${T.MATERIAL}s`}
        />
      </div>
    </>
  )
}

MeasureMaterialAutoComplete.defaultProps = {
  type: '',
  globalFilterOptions: {},
  handleCheckboxValue: null,
  checkedMaterials: [],
  checkedMeasure: [],
  backFlag: false,
}

MeasureMaterialAutoComplete.propTypes = {
  type: PropTypes.string,
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedMaterials: PropTypes.array,
  checkedMeasure: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default MeasureMaterialAutoComplete
