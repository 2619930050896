import { styled } from '@mui/system'
import { DataGridPro } from '@mui/x-data-grid-pro'

const BorderlessDataGridPro = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  backgroundColor: theme.palette.background.paper,
  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
    border: 0,
    padding: 0,
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row.Mui-selected.Mui-hovered, & .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected':
    {
      backgroundColor: 'inherit',
    },
  '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
    backgroundColor: 'inherit',
  },
  '&.MuiDataGrid-root .Mui-hovered': {
    backgroundColor: 'inherit',
  },
  '& .MuiDataGrid-cellCheckbox': {
    paddingTop: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
}))

export default BorderlessDataGridPro
