import React from 'react'
import PropTypes from 'prop-types'

import { DateRangePicker } from 'react-date-range'

import { Box, Button, Menu } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { Table as ConfiguratorIcon } from '@styled-icons/heroicons-outline/Table'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'

import T from 'T'
import { formatDateToFEFormat } from 'utils/date'
import { get } from 'utils/lodash'

const ReportTopSection = ({
  showDateRange = true,
  rangeSelection = {},
  showConfigurator = true,
  newReport = false,
  onChange = () => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  return (
    <Box p="12px 20px" display="flex" alignItems="end" justifyContent="space-between" bgcolor="complete.light">
      <Box display="flex" alignItems="center">
        <Box>
          {showDateRange && (
            <HHTextField
              label="From - To"
              size="small"
              readOnly
              onClick={event => {
                setAnchorEl(event.currentTarget)
              }}
              value={`${formatDateToFEFormat(get(rangeSelection, 'startDate') || new Date())} - ${formatDateToFEFormat(
                get(rangeSelection, 'endDate') || new Date()
              )}`}
              InputProps={{
                startAdornment: <DateRangeOutlinedIcon sx={{ width: 16, mr: 0.5 }} />,
              }}
            />
          )}

          <Menu
            open={open}
            onClose={() => {
              setAnchorEl(null)
            }}
            anchorEl={anchorEl}
          >
            <DateRangePicker
              onChange={item => {
                onChange('calendar', item.selection)
              }}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              showDateDisplay={false}
              showMonthAndYearPickers
              ranges={[rangeSelection]}
              direction="horizontal"
            />
          </Menu>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={2.5}>
        {newReport ? (
          <Button variant="outlined" color="primary" onClick={() => onChange('isOpenNewReportDrawer', true)}>
            {T.SAVE_REPORT}
          </Button>
        ) : (
          <Button variant="outlined" color="primary" onClick={() => onChange('isOpenNewReportDrawer', true)}>
            {T.UPDATE_REPORT}
          </Button>
        )}

        {showConfigurator && (
          <Button
            variant="outlined"
            color="textSecondary"
            size="small"
            sx={{
              ml: 1,
              minWidth: 'auto',
              p: 0.6,
              svg: {
                color: 'text.primary',
              },
            }}
            onClick={event => onChange('isOpenConfigurator', event.currentTarget)}
          >
            <ConfiguratorIcon width={20} height={20} />
          </Button>
        )}
      </Box>
    </Box>
  )
}

ReportTopSection.propTypes = {
  showDateRange: PropTypes.bool,
  rangeSelection: PropTypes.object,
  showConfigurator: PropTypes.bool,
  newReport: PropTypes.bool,
  onChange: PropTypes.func,
}

export default ReportTopSection
