import React from 'react'
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridRowCountSelector,
  gridPaginationModelSelector,
} from '@mui/x-data-grid-pro'
import { Grid, Pagination, useTheme, useMediaQuery, Box } from '@mui/material'

import { INVOICE_PAGINATION } from 'settings/constants/pagination'
import HHTablePagination from 'components/common/HHTablePagination'

const { ROWS_PER_PAGE_OPTIONS } = INVOICE_PAGINATION

export const DataGridPagination = () => {
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const { page, pageSize } = paginationModel
  const rowCount = useGridSelector(apiRef, gridRowCountSelector)
  // gridPageCountSelector not working with v6, so calculate pageCount manually
  const pageCount = Math.ceil(rowCount / pageSize)
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs container justifyContent="flex-start" pl={2}>
        {/* We can also use default GridPagination */}
        <HHTablePagination
          page={page}
          component="div"
          count={rowCount}
          rowsPerPage={pageSize}
          onPageChange={(event, newPage) => apiRef.current.setPage(newPage)}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onRowsPerPageChange={event => apiRef.current.setPageSize(event.target.value)}
          ActionsComponent={isLg ? params => <Box display="none" {...params} /> : undefined}
        />
      </Grid>
      {isLg && (
        <Grid item xs container justifyContent="flex-end">
          <Pagination color="primary" count={pageCount} page={page + 1} onChange={(event, value) => apiRef.current.setPage(value - 1)} />
        </Grid>
      )}
    </Grid>
  )
}
