import React, { useState, Children } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box, IconButton, Menu, MenuItem, Typography, Tabs, styled, useTheme } from '@mui/material'
import { Z_INDEX } from 'theme/zIndex'
import Constants from 'Constants'
import { getAllMenuItems } from './menuItems'
import NavItem from './NavItem'

const { MEDIA_SERVER } = Constants
const { MENU } = Z_INDEX

const MenuIcon = styled(`img`)`
  margin-right: 8px;
  size: 10px;
`

const NavigationMenu = () => {
  const flags = useFlags()
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()

  const { pathname } = location

  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleNavigate = route => {
    history.push(route)
    handleCloseNavMenu()
  }

  const menuItems = getAllMenuItems(flags).filter(({ permission, isFeatureAvailable }) => permission && isFeatureAvailable)

  const activeIndex = menuItems.findIndex(({ childList }) => childList.includes(pathname))
  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}>
        <Tabs
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.background.paper,
            },
          }}
          textColor="primary"
          value={activeIndex}
        >
          {Children.toArray(
            menuItems.map((menuItem, index) => {
              const isActive = menuItem.childList.includes(pathname)
              const { title, route, icon } = menuItem
              return <NavItem isActive={isActive} icon={icon} label={title} to={route} index={index} />
            })
          )}
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
        <IconButton
          color="inherit"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
        >
          <img src={`${MEDIA_SERVER}HambugerMenu.svg`} alt="menu" />
        </IconButton>
      </Box>
      <Menu
        sx={{ zIndex: MENU }}
        id="menu-appbar"
        anchorEl={anchorElNav}
        open={Boolean(anchorElNav)}
        keepMounted
        onClose={handleCloseNavMenu}
      >
        {menuItems.map(({ icon, title, route }, i) => {
          return (
            <MenuItem divider={i < menuItems.length - 1} key={title} onClick={() => handleNavigate(route)}>
              <MenuIcon src={MEDIA_SERVER + icon} alt={icon} />
              <Typography variant="body1">{title}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default NavigationMenu
