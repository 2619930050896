import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Box, List, Typography, Tooltip, IconButton, Skeleton } from '@mui/material'
import { blue, grey, lightBlue } from '@mui/material/colors'
import { ReactComponent as BillingNoteIcon } from 'assets/note/BillingNote.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAllBillingNotesQuery, useCreateBillingNoteMutation, useUpdateBillingNoteMutation } from 'api/notes/billingNotesCrud'
import { getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import StyledBadge from 'components/notes/common/StyledBadge'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import BillingNotesDialogVariant from 'components/notes/billing/BillingNotesDialogVariant'
import ViewNotesPopover from 'components/notes/common/ViewNotesPopover'
import AccountInfoListItem from './AccountInfoListItem'

const AccountInfo = ({
  isLoading = false,
  selectedAccount = null,
  initialCreditBalanceCents = 0,
  updatedCreditBalanceCents = 0,
  showUpdatedCreditBalanceCents = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpenAddNoteDialog, setIsOpenAddNoteDialog] = useState(null)
  const [isOpenBillingNotesDialog, setIsOpenBillingNotesDialog] = useState(false)

  const [getAllBillingNotes, { isFetching: isGetAllNotesFetching, isLoading: isGetAllNotesLoading, data: notesData }] =
    useLazyGetAllBillingNotesQuery()
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: get(notesData, 'notes', []) })

  const accountId = useMemo(() => get(selectedAccount, 'id'), [selectedAccount])
  const notesCount = useMemo(() => (accountId ? notes.length : 0), [accountId, notes])
  const hasNotes = useMemo(() => notesCount > 0, [notesCount])
  const loading = isLoading || isGetAllNotesFetching || isGetAllNotesLoading

  const handleBillingNotesPopoverOpen = event => {
    if (hasNotes) {
      setAnchorEl(event.currentTarget)
      return
    }
    setAnchorEl(null)
  }

  const handleBillingNotesPopoverClose = () => setAnchorEl(null)

  const handleOpenAddBillingNote = () => setIsOpenAddNoteDialog(true)

  const handleCloseAddBillingNote = () => setIsOpenAddNoteDialog(false)

  const handleOpenBillingNotesDialog = () => setIsOpenBillingNotesDialog(true)

  const handleCloseBillingNotesDialog = () => setIsOpenBillingNotesDialog(false)

  const handleClick = () => {
    if (hasNotes) {
      handleOpenBillingNotesDialog()
      return
    }
    handleOpenAddBillingNote()
  }

  useEffect(() => {
    if (accountId) {
      getAllBillingNotes({ id: accountId }).unwrap().catch(handleError)
    }
  }, [accountId])

  return (
    <>
      <Box display="flex" mt={1} gap={2}>
        <Box height="100%">
          {loading && <Skeleton variant="rectangular" width={34} height={34} />}
          {!loading && (
            <Tooltip arrow placement="bottom" title={T.ADD_BILLING_NOTES} disableHoverListener={hasNotes || !accountId} disableInteractive>
              <IconButton
                disabled={!accountId}
                size="small"
                onClick={handleClick}
                onMouseEnter={handleBillingNotesPopoverOpen}
                onMouseLeave={handleBillingNotesPopoverClose}
              >
                <StyledBadge badgeContent={notesCount}>
                  <BillingNoteIcon />
                </StyledBadge>
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box flex={1} sx={{ border: 1, borderColor: 'divider', bgcolor: selectedAccount ? blue[50] : grey[100] }}>
          <List dense disablePadding sx={{ display: 'flex' }}>
            {selectedAccount && <AccountInfoListItem label={selectedAccount?.accountNumber} value={selectedAccount?.accountName} />}
            <AccountInfoListItem
              label={capitalize(T.ACCOUNT_CREDIT)}
              value={<HHDisplayMoney value={initialCreditBalanceCents} />}
              sx={{ textAlign: 'right' }}
            />
          </List>

          {showUpdatedCreditBalanceCents && initialCreditBalanceCents !== updatedCreditBalanceCents && (
            <Typography variant="subtitle1" fontWeight={700} color={lightBlue[400]} textAlign="right" px={2} mt={-1} mb={1}>
              Your new account credit will be <HHDisplayMoney value={updatedCreditBalanceCents} />
            </Typography>
          )}
        </Box>
      </Box>
      <ViewNotesPopover
        anchorEl={anchorEl}
        id={accountId}
        getCachedData
        useLazyGetAllNotesQuery={useLazyGetAllBillingNotesQuery}
        onClose={handleBillingNotesPopoverClose}
      />
      <BillingNotesDialogVariant
        isOpen={isOpenBillingNotesDialog}
        accountId={accountId}
        getCachedData
        onClose={handleCloseBillingNotesDialog}
      />
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddNoteDialog}
        title={T.NEW_BILLING_NOTE}
        useCreateNoteMutation={useCreateBillingNoteMutation}
        useUpdateNoteMutation={useUpdateBillingNoteMutation}
        onClose={handleCloseAddBillingNote}
      />
    </>
  )
}

AccountInfo.propTypes = {
  isLoading: PropTypes.bool,
  selectedAccount: PropTypes.object,
  initialCreditBalanceCents: PropTypes.number,
  updatedCreditBalanceCents: PropTypes.number,
  showUpdatedCreditBalanceCents: PropTypes.bool,
}

export default AccountInfo
