import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getRecurrenceText } from 'utils/service'
import { getInvoiceLineData, canChangeWOInvoiceStatusSelector } from 'data/work-order/workOrderSelectors'
import { CommonTextLabel } from 'components/common'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import WorkOrderInvoiceStatusAlert from 'components/work-order/WorkOrderInvoiceStatusAlert'

import EditInvoiceDialog from './EditInvoiceDialog'

const InvoiceDetails = ({ workOrderId = '', invoiceStatus = '', service = {}, invoiceLine = {} }) => {
  const [isOpenEditInvoiceDialog, setIsOpenEditInvoiceDialog] = useState(false)
  const { invoiceNumber } = getInvoiceLineData({ invoiceLine })
  const { recurrence, recurrenceFrequency } = service

  const serviceType = getRecurrenceText(
    get(recurrence, 'recurrencePer', ''),
    get(recurrence, 'recurrenceInterval', ''),
    recurrenceFrequency
  )
  const canChangeWOInvoiceStatus = canChangeWOInvoiceStatusSelector({ serviceType, invoiceStatus })

  return (
    <HHAccordion
      withNewColors
      isExpanded
      isExpandCollapseHeightSame
      summary={T.INVOICE_DETAILS}
      showActionIcon={canChangeWOInvoiceStatus}
      onActionClick={() => setIsOpenEditInvoiceDialog(true)}
    >
      <Box px={2} py={1.5}>
        {!invoiceStatus && <Box className="no-results">{T.NO_WO_INVOICE}</Box>}
        {invoiceStatus && (
          <Box>
            <CommonTextLabel title={T.INVOICE_STATUS} className="ttc" />
            <WorkOrderInvoiceStatusAlert status={invoiceStatus} invoiceNumber={invoiceNumber} />
          </Box>
        )}
      </Box>
      <EditInvoiceDialog
        isOpen={isOpenEditInvoiceDialog}
        currentInvoiceStatus={invoiceStatus}
        workOrderId={workOrderId}
        onClose={() => setIsOpenEditInvoiceDialog(false)}
      />
    </HHAccordion>
  )
}

InvoiceDetails.propTypes = {
  workOrderId: PropTypes.string,
  invoiceStatus: PropTypes.string,
  service: PropTypes.object,
  invoiceLine: PropTypes.object,
}

export default memo(InvoiceDetails)
