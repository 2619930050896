import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'

import { get } from 'utils/lodash'
import { getAddressParams } from 'utils/helper'
import { HHFormTextField } from 'components/form-fields/v5'
import { DEFAULT_STATE } from 'components/common/address/model'

import T from 'T'
import TagsSelect from 'components/tags/TagsSelect'
import TagsWrapper from 'components/customers/add/tab-content/TagsWrapper'
import AddressRowOne from 'components/common/address/AddressRowOne'
import AddressRowTwo from 'components/common/address/AddressRowTwo'
import AddressLatLong from 'components/common/address/AddressLatLong'

const { accountNamePlaceholder, attentionPlaceholder } = DEFAULT_STATE

const AccountForm = ({ metaTags = [], setLatLngForMap, setLatLngForMapHistory }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { billingAddress: addressErrors, accountName: accountNameError } = errors
  const accountNameHelperText = get(accountNameError, 'message')
  const watchLine1 = watch('billingAddress.line1')
  const watchLong = watch('billingAddress.longitude')
  const watchLat = watch('billingAddress.latitude')

  const handleBillingAddressChange = address => {
    const { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed } = getAddressParams(address)
    setValue('billingAddress.line1', line1)
    setValue('billingAddress.city', city)
    setValue('billingAddress.state', state)
    setValue('billingAddress.country', country)
    setValue('billingAddress.zipCode', zipCode)
    setValue('billingAddress.latitude', latitudeFixed)
    setValue('billingAddress.longitude', longitudeFixed)
    setLatLngForMap({ longitudeForMap: longitudeFixed, latitudeForMap: latitudeFixed })
    setLatLngForMapHistory({ longitudeForMapHistory: longitudeFixed, latitudeForMapHistory: latitudeFixed })
  }

  return (
    <Grid container item xs={12} md={5} rowGap={2} height="fit-content">
      <Grid item xs={12}>
        <HHFormTextField
          error={accountNameHelperText}
          helperText={accountNameHelperText}
          control={control}
          required
          rules={{ required: T.CANNOT_BE_EMPTY }}
          name="accountName"
          label={capitalize(T.CUSTOMER_NAME)}
          placeholder={accountNamePlaceholder}
          fullWidth
          deprecatedLabel={false}
        />
      </Grid>

      <Typography variant="h5" fontWeight={500}>
        {capitalize(T.BILLING_ADDRESS)}
      </Typography>

      <Grid item xs={12}>
        <HHFormTextField
          control={control}
          name="billingAddress.attention"
          label={T.ATTENTION}
          placeholder={attentionPlaceholder}
          fullWidth
          deprecatedLabel={false}
        />
      </Grid>

      <AddressRowOne
        fieldPrefix="billingAddress"
        control={control}
        line1={watchLine1}
        onLine1Change={newValue => setValue('billingAddress.line1', newValue)}
        onBillingAddressChange={handleBillingAddressChange}
      />

      <AddressRowTwo fieldPrefix="billingAddress" control={control} />

      <AddressLatLong
        showInvalidAlert={false}
        fieldPrefix="billingAddress"
        control={control}
        lat={watchLat}
        long={watchLong}
        hasLatError={addressErrors?.latitude}
        hasLongError={addressErrors?.longitude}
        onBlurLat={value => {
          setLatLngForMap(prev => ({ ...prev, latitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, latitudeForMapHistory: value }))
        }}
        onBlurLong={value => {
          setLatLngForMap(prev => ({ ...prev, longitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, longitudeForMapHistory: value }))
        }}
      />

      <Grid item xs={12}>
        <TagsWrapper>
          <TagsSelect
            deprecatedLabel={false}
            label={capitalize(T.ACCOUNT_TAGS)}
            tags={metaTags.filter(data => data.active && data.forAccount)}
            selectedTags={watch('tags')}
            onChange={(e, selectedOptions) => {
              setValue('tags', selectedOptions)
              // Trigger resize to fix map height
              setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
            }}
            limit={2}
          />
        </TagsWrapper>
      </Grid>
    </Grid>
  )
}

AccountForm.propTypes = {
  metaTags: PropTypes.array,
  setLatLngForMap: PropTypes.func.isRequired,
  setLatLngForMapHistory: PropTypes.func.isRequired,
}

export default AccountForm
