import { toast } from 'react-toastify'
import { get } from 'utils/lodash'
import { insertSpaceBeforeCapitalLetter } from 'utils/string'
import { CARD_IMAGES, ACH_IMAGE, PAYMENT_METHODS, ACH_ACCOUNT_TYPE, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'

const { CREDIT_CARD, ACH, ACCOUNT_CREDIT } = PAYMENT_METHODS
const accountCreditLabel = PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT]

export const isValidAccountType = accountType => ACH_ACCOUNT_TYPE.includes(accountType)

export const getPaymentMethodName = (creditCardType, accountType) => {
  if (creditCardType) {
    return CREDIT_CARD
  }

  if (isValidAccountType(accountType)) {
    return ACH
  }

  return ''
}

export const getPaymentMethodImage = type => {
  if (type === ACH) {
    return ACH_IMAGE
  }

  return CARD_IMAGES[type] || CARD_IMAGES.Unknown
}

// All values in Cents
export const handleInvoiceSelectDeselect = (allInvoices, initialCreditBalanceCents, addedAmount, checkConditions = false) => {
  let selectedInvoices = []

  if (!Array.isArray(allInvoices)) {
    return { isChanged: false, selectedInvoices }
  }

  let creditBalanceClone = initialCreditBalanceCents || 0
  let addedAmountClone = addedAmount || 0
  if (creditBalanceClone + addedAmountClone <= 0 && checkConditions) {
    toast.info('Available credit is less than the invoice selected')
    return { isChanged: false }
  }

  // Check for partial
  const hasPartialInvoice = allInvoices.find(invoice => invoice.isPartial)
  if (hasPartialInvoice && checkConditions) {
    toast.info('Can’t use one payment to partially pay 2 invoices.')
    return { isChanged: false }
  }

  let isPartial = false

  // we can also save this separately to avoid recalculation similiar to isAmountAvailable
  allInvoices.forEach(invoice => {
    const { invoiceAmount, invoiceNumber } = invoice
    const totalAddedAmount = addedAmountClone + creditBalanceClone

    // Only one partial invoice allowed
    // Ignore for $0
    if (invoiceAmount && (totalAddedAmount <= 0 || isPartial)) {
      return
    }

    const usedAmount = addedAmountClone >= invoiceAmount ? invoiceAmount : addedAmountClone
    // any amount left
    const leftAmount = addedAmountClone >= invoiceAmount ? 0 : invoiceAmount - addedAmountClone

    // Use account credit for the pending amount
    let usedCreditBalance = 0
    if (leftAmount) {
      usedCreditBalance = creditBalanceClone >= leftAmount ? leftAmount : creditBalanceClone
    }

    // Check for partial case
    isPartial = invoiceAmount > totalAddedAmount
    const usedPartialAmount = isPartial ? totalAddedAmount : 0

    // Reduce amount for next invoices
    addedAmountClone -= usedAmount
    creditBalanceClone -= usedCreditBalance

    selectedInvoices = [
      ...selectedInvoices,
      {
        invoiceNumber,
        invoiceAmount,
        usedAmount,
        usedCreditBalance,
        // Can be extra balance which will be added to the account credit
        extraBalance: Math.max(addedAmountClone, 0),
        isPartial,
        usedPartialAmount,
      },
    ]
  })

  return { isChanged: true, selectedInvoices }
}

export const isAmountAvailable = (totalUnpaid, initialAvailableCredit, newAmount) => {
  if (totalUnpaid > initialAvailableCredit + (newAmount ? parseFloat(newAmount) : 0)) {
    toast.info('Available credit is less than the invoice selected')
    return false
  }

  return true
}

// remove invoice amount
export const removeInvoiceAmountFromPayload = payload => ({
  ...payload,
  requestedPayments: payload.requestedPayments.map(payment => ({
    ...payment,
    invoicesToPay: payment.invoicesToPay.map(invoice => invoice.invoiceNumber),
  })),
})

const getSingleSummaryRow = (refId, accountId, customerName, invoiceNumber, checkNum, paymentMethod, amount, totalInvoices) => ({
  refId,
  accountId,
  customerName,
  invoiceNumber,
  checkNum,
  paymentMethod,
  amount,
  totalInvoices,
})

export const getSummaryTableData = requestedPayments => {
  let summaryRows = []
  let total = 0
  requestedPayments.forEach(account => {
    const { refId, accountId, customerName, invoicesToPay } = account
    const checkNum = get(account, 'checkNum', '')
    const paymentMethod = get(account, 'paymentMethod', '')

    const { amountCents } = account

    if (invoicesToPay.length === 0) {
      // if no invoice and amount
      summaryRows = [
        ...summaryRows,
        getSingleSummaryRow(refId, accountId, customerName, accountCreditLabel, checkNum, paymentMethod, amountCents, 0),
      ]
      total += amountCents
    }

    invoicesToPay.forEach((invoice, index) => {
      const { invoiceNumber, usedAmount, usedCreditBalance, extraBalance } = invoice

      if (usedAmount) {
        summaryRows = [
          ...summaryRows,
          getSingleSummaryRow(refId, accountId, customerName, invoiceNumber, checkNum, paymentMethod, usedAmount, invoicesToPay.length),
        ]
        total += usedAmount
      }

      // Use account credit
      if (usedCreditBalance || !usedAmount) {
        summaryRows = [
          ...summaryRows,
          getSingleSummaryRow(
            refId,
            accountId,
            customerName,
            invoiceNumber,
            accountCreditLabel,
            ACCOUNT_CREDIT,
            usedCreditBalance,
            invoicesToPay.length
          ),
        ]
        total += usedCreditBalance
      }

      if (invoicesToPay.length - 1 === index && extraBalance) {
        // add left over balance
        summaryRows = [
          ...summaryRows,
          getSingleSummaryRow(refId, accountId, customerName, accountCreditLabel, checkNum, paymentMethod, extraBalance, 0),
        ]
        total += extraBalance
      }
    })
  })

  return { summaryRows, total }
}

export const getPaymentMethod = paymentMethod =>
  insertSpaceBeforeCapitalLetter(Array.isArray(paymentMethod) ? paymentMethod.join(',') : paymentMethod)

export const getRevertPaymentConfirmationMsg = (hasLinkedAccountCredit = false, invoices = [], hasPartialInvoice = false) => {
  let message = ''

  if (invoices.length) {
    const joinInvoices = invoices.join(', ')
    const isSingleInvoice = invoices.length === 1
    if (hasPartialInvoice) {
      return `Reverting this payment will affect invoice${isSingleInvoice ? '' : 's'} ${joinInvoices}.`
    }

    message = `Reverting this payment will change invoice${isSingleInvoice ? '' : 's'} ${joinInvoices} to Unpaid.`

    if (hasLinkedAccountCredit) {
      message += ` Any excess payment that went to account credit will also be reverted.`
    }

    return message
  }

  return 'Please move the payment to account credit on all affected invoices before proceeding with the revert process. The system does not have information about which specific invoice was paid using the account credit that you would like to have the payment pulled out from.'
}
