import React, { memo } from 'react'
import { Stack, useTheme } from '@mui/material'
import MapToggleButton from 'components/route-manager/RouteManagerMap/MapToggleButton'
import { selectBulkAssignMapLayer, setBulkAssignStopsState, selectBulkAssignRoutesById } from 'slices/route/bulkAssignStopsSlice'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import MapToggleButtonGroup from 'components/route-manager/RouteManagerMap/MapToggleButtonGroup'
import { get } from 'lodash'
import { BULK_MOVE_MAP_LAYERS, toggleMoveBetweenRoutesLayers } from 'components/route-manager/BulkActions/settings'

const { FROM_ROUTE_LAYER, TO_ROUTE_LAYER, BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const ToggleRouteLayers = ({ map }) => {
  const routesById = useSelector(selectBulkAssignRoutesById)
  const theme = useTheme()
  const { watch } = useFormContext()
  const dispatch = useDispatch()
  const mapLayer = useSelector(selectBulkAssignMapLayer)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const showToggle = moveFromRouteId && moveToRouteId

  const changeLayer = (event, newCurrentLayer) => {
    const mapCurrentRef = map.current
    if (mapCurrentRef && newCurrentLayer !== null) {
      dispatch(
        setBulkAssignStopsState({
          mapLayer: newCurrentLayer,
        })
      )
      toggleMoveBetweenRoutesLayers(mapCurrentRef, newCurrentLayer, moveFromRouteId, moveToRouteId)
    }
  }

  const toRouteName = get(routesById, [moveToRouteId, 'name'])

  return (
    <>
      {showToggle && (
        <Stack sx={{ position: 'absolute', top: 16, right: 16, zIndex: theme.zIndex.appbar }}>
          <MapToggleButtonGroup color="primary" value={mapLayer} exclusive onChange={changeLayer} aria-label="Platform">
            <MapToggleButton value={FROM_ROUTE_LAYER}>{T.UNASSIGNED}</MapToggleButton>
            <MapToggleButton value={BOTH_ROUTES_LAYER}>{T.ALL}</MapToggleButton>
            <MapToggleButton value={TO_ROUTE_LAYER}>{toRouteName}</MapToggleButton>
          </MapToggleButtonGroup>
        </Stack>
      )}
    </>
  )
}

ToggleRouteLayers.propTypes = {
  map: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default memo(ToggleRouteLayers)
