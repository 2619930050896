import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { ListItem, ListItemText, Typography, ListItemIcon } from '@mui/material'
import { ReactComponent as RentalFeeIcon } from 'assets/RentalFee.svg'

import { getFormattedGracePeriod } from 'components/pricing/RentalFees/common/utils'

import HHDisplayMoney from 'components/common/HHDisplayMoney'

const RentalFeeListItem = ({ rentalFee }) => {
  const { feeName, gracePeriodDays, pricingPeriod, amountCents = 0 } = rentalFee
  const formattedGracePeriod = useMemo(() => getFormattedGracePeriod(gracePeriodDays), [gracePeriodDays])

  return (
    <ListItem sx={{ px: 0 }}>
      <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
        <RentalFeeIcon />
      </ListItemIcon>
      <ListItemText
        primary={feeName}
        primaryTypographyProps={{ variant: 'h5', fontWeight: 500, noWrap: true, color: 'textPrimary' }}
        secondary={`${formattedGracePeriod} | ${capitalize(pricingPeriod)}`}
        secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
      />
      <ListItemIcon sx={{ ml: 2, minWidth: 'auto' }}>
        <Typography whiteSpace="noWrap" variant="h5" fontWeight={500} color="textPrimary">
          <HHDisplayMoney value={amountCents} />
        </Typography>
      </ListItemIcon>
    </ListItem>
  )
}

RentalFeeListItem.propTypes = {
  rentalFee: PropTypes.object,
}

export default RentalFeeListItem
