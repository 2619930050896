import React, { memo, useMemo } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { get } from 'lodash'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import { HHAlert } from 'components/common/HHAlert'
import { orange } from '@mui/material/colors'
import PropTypes from 'prop-types'

const CommonPermanentMoveAlert = ({
  toBeMovedRowsCount = 0,
  routesById,
  moveFromRouteId,
  moveToRouteId,
  fromSerializedDate,
  toSerializedDate,
}) => {
  const theme = useTheme()
  const fromRouteName = get(routesById, [moveFromRouteId, 'name'])
  const toRouteName = get(routesById, [moveToRouteId, 'name'])
  const fromDate = useMemo(() => {
    if (fromSerializedDate) {
      return deserializeDate(fromSerializedDate)
    }
    return new Date()
  }, [fromSerializedDate])
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toDate = useMemo(() => {
    if (toSerializedDate) {
      return deserializeDate(toSerializedDate)
    }
    return new Date()
  }, [toSerializedDate])
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  return (
    <HHAlert
      severity="warning"
      sx={{
        ...theme.typography.body1,
        borderColor: orange[500],
        borderWidth: 1,
        borderStyle: 'solid',
        pr: theme.spacing(3),
      }}
    >
      <Typography>
        You’re about to move
        <Box component="span" fontWeight={600}>
          {` ${toBeMovedRowsCount} `}
        </Box>
        {toBeMovedRowsCount > 1 ? 'services' : 'service'} from
        <Box component="span" fontWeight={600}>{` ${fromRouteName} `}</Box>
        on
        <Box component="span" fontWeight={600}>{` ${fromFEDate} `}</Box>
        to
        <Box component="span" fontWeight={600}>
          {` ${toRouteName} `}
        </Box>
        on
        <Box component="span" fontWeight={600}>{` ${toFEDate}`}</Box>. Do you want to continue?
      </Typography>
    </HHAlert>
  )
}

CommonPermanentMoveAlert.propTypes = {
  toBeMovedRowsCount: PropTypes.number,
  routesById: PropTypes.object,
  fromSerializedDate: PropTypes.string,
  toSerializedDate: PropTypes.string,
  moveFromRouteId: PropTypes.string,
  moveToRouteId: PropTypes.string,
}

export default memo(CommonPermanentMoveAlert)
