import { Typography, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const FULL_HEADING_LENGTH_LIMIT = 100
const HEADING_WITH_ACTIONS_LENGTH_LIMIT = 88

const ModalSplittedTitle = ({ title = '', actionsGap = false }) => {
  const limit = actionsGap ? HEADING_WITH_ACTIONS_LENGTH_LIMIT : FULL_HEADING_LENGTH_LIMIT
  const theme = useTheme()
  return (
    <>
      {title.length > limit ? (
        <Tooltip title={title}>
          <Typography
            sx={{
              minHeight: 40,
              maxHeight: '3.6em',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 24,
              lineHeight: '40px',
              color: theme.palette.text.primary,
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              boxSizing: 'border-box',
              width: actionsGap ? 576 : 696,
            }}
            component="p"
          >
            {`${title.substring(0, limit)}...`}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          sx={{
            minHeight: 40,
            maxHeight: '3.6em',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '40px',
            color: theme.palette.text.primary,
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box',
            width: actionsGap ? 576 : 696,
          }}
          component="p"
        >
          {title}
        </Typography>
      )}
    </>
  )
}

ModalSplittedTitle.propTypes = {
  title: PropTypes.string,
  actionsGap: PropTypes.bool,
}

export default ModalSplittedTitle
