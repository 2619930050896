import React from 'react'
import { useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { Box } from '@mui/material'
import InteractiveTagsList from '../../tags/InteractiveTagsList'
import api from '../../../api'
import { CACHE_TAG_BILLING_PAYMENT_LIST, CACHE_TAG_INVOICE_LIST, CACHE_TAG_BILLING_AR_LIST } from '../../../api/cacheTagTypes'

const RenderTagsCell = params => {
  const dispatch = useDispatch()
  const { row } = params
  const customerName = get(row, 'customerName')
  const tagsData = { ...row, ...(customerName && { name: customerName }) }
  const handleClick = e => e.stopPropagation()
  return (
    <Box display="inline-flex" flex="noWrap" onClick={handleClick}>
      <InteractiveTagsList
        data={!isEmpty(tagsData) ? tagsData : undefined}
        disableLocation
        tagsFlexWrap="nowrap"
        disableService
        limit={1}
        onSave={(data, closeCallback) => {
          dispatch(api.util.invalidateTags([CACHE_TAG_INVOICE_LIST, CACHE_TAG_BILLING_PAYMENT_LIST, CACHE_TAG_BILLING_AR_LIST]))
          closeCallback()
        }}
      />
    </Box>
  )
}

export default RenderTagsCell
