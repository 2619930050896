import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import useTheme from '@mui/material/styles/useTheme'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { AGING_BUCKET_TYPE_TO_QUERY_PARAM, getBucketStyles } from 'components/common/aging-bucket/settings'
import { Card, CardContent, ListItem, ListItemText } from '@mui/material'
import CardActionArea, { cardActionAreaClasses } from '@mui/material/CardActionArea'
import AgingBucketPreviewPopover from 'components/common/aging-bucket/InteractiveAgingBucket/AgingBucketPreviewPopover'
import { useDispatch } from 'react-redux'
import { setAgingBucketState } from 'slices/billing/agingBucketSlice'
import { useLazyGetAgingInvoicePreviewListByAccountQuery } from 'api/billing/getBillingInvoiceListByAccount'
import { get } from 'lodash'

const InteractiveAgingBucket = ({ accountId, type = '', label, value = 0 }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const bucketStyles = useMemo(() => getBucketStyles(type), [type])
  const [getAgingInvoicePreviewList, { isLoading, isFetching, data, isSuccess }] = useLazyGetAgingInvoicePreviewListByAccountQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const invoices = useMemo(() => get(data, 'accountInvoices', []), [data])
  const invoiceCount = useMemo(() => get(data, 'invoiceCount', 0), [data])
  const hasInvoices = value > 0 && isSuccess && Array.isArray(invoices) && invoices.length !== 0
  const disableClick = useMemo(() => get(invoices, 'length', 0) === 0, [data, invoices])
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClick = useCallback(() => {
    if (disableClick) return
    dispatch(
      setAgingBucketState({
        open: true,
        accountId,
        agingBucketType: type,
      })
    )
  }, [accountId, disableClick])

  useEffect(() => {
    const aging = get(AGING_BUCKET_TYPE_TO_QUERY_PARAM, type)
    if (anchorEl && aging && value > 0) {
      getAgingInvoicePreviewList({
        accountId,
        aging,
      })
    }
  }, [anchorEl, type, value])

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          flex: 1,
          minWidth: 'fit-content',
          height: '100%',
          border: '1px solid',
          ...bucketStyles,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleClick}
      >
        <CardActionArea
          disabled={disableClick}
          sx={{
            [`& .${cardActionAreaClasses.focusHighlight}`]: {
              opacity: 0,
              background: 'transparent',
            },
          }}
        >
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <ListItem disableGutters disablePadding>
              <ListItemText
                sx={{ m: 0 }}
                primary={label}
                primaryTypographyProps={{
                  variant: 'caption',
                  fontWeight: 700,
                  noWrap: true,
                  textAlign: 'right',
                  gutterBottom: true,
                }}
                secondary={<HHDisplayMoney value={value} />}
                secondaryTypographyProps={{
                  variant: 'h6',
                  fontWeight: 700,
                  color: 'primaryText',
                  noWrap: true,
                  textAlign: 'right',
                }}
              />
            </ListItem>
          </CardContent>
        </CardActionArea>
      </Card>
      <AgingBucketPreviewPopover
        hasInvoices={hasInvoices}
        invoiceCount={invoiceCount}
        invoices={invoices}
        loading={loading}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        agingBucketType={type}
      />
    </>
  )
}

InteractiveAgingBucket.propTypes = {
  accountId: PropTypes.string,
  value: PropTypes.number,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
}

export default InteractiveAgingBucket
