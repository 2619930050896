import React from 'react'
import PropTypes from 'prop-types'
import WOReviewFiltersDialog from './WOReviewFiltersDialog'

const WithFiltersDialog = ({ children }) => {
  return (
    <>
      {children}
      <WOReviewFiltersDialog />
    </>
  )
}

WithFiltersDialog.propTypes = {
  children: PropTypes.node,
}

export default WithFiltersDialog
