import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Divider, Menu, MenuItem } from '@mui/material'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { selectLatitude, selectLongitude } from 'data/address/addressSelectors'
import { getCustomerDetails } from 'middleware/actions/customers'
import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import LocationMapImage from '../location/LocationMapImage'
import AddressDetails from '../common/AddressDetails'
import ContactEmailList from '../contacts/ContactEmailList'
import EditBillingLocationModal from './EditBillingLocationModal'

const BillingLocationDetails = ({ accountId }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const billingAddress = get(customerDetails, 'billingAddress', {})
  const attention = get(billingAddress, 'attention')
  const latitude = selectLatitude(billingAddress)
  const longitude = selectLongitude(billingAddress)

  const handleActionClick = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleEditClick = () => {
    handleCloseMenu()
    setIsEditDialogOpen(true)
  }

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false)
  }

  const handleSaveEditDialog = () => {
    setIsEditDialogOpen(false)
    dispatch(getCustomerDetails({ accountId }, () => {}))
  }

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        isExpanded
        withNewColors
        actionType="ellipsis"
        onActionClick={handleActionClick}
        summary={T.BILLING_LOCATION_DETAILS}
      >
        <LocationMapImage
          height={200}
          width={399}
          dynamicWidth
          containerId={`billing-${accountId}-location-details-map-preview`}
          latitude={latitude}
          longitude={longitude}
          showInvalidAlert={false}
        />
        <AddressDetails
          sx={{ px: 2, pt: 4, pb: 2 }}
          address={{ ...billingAddress, addressName: attention }}
          showAddressName
          addressNameLabel={T.ATTENTION}
          line1Label={T.BILLING_ADDRESS}
        />
        <Divider />
        <ContactEmailList />
      </HHAccordion>
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ width: '5rem' }} onClick={handleEditClick}>
          {T.EDIT}
        </MenuItem>
      </Menu>
      <EditBillingLocationModal
        open={isEditDialogOpen}
        customerDetails={customerDetails}
        onClose={handleCloseEditDialog}
        onSave={handleSaveEditDialog}
      />
    </>
  )
}

BillingLocationDetails.propTypes = {
  accountId: PropTypes.string.isRequired,
}

export default BillingLocationDetails
