import React from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import CustomerSideNav from 'components/customers/common/side-nav/CustomerSideNav'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import { selectShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'
import SuspensionGroupsHeader from 'components/customers/groups/suspension/SuspensionGroupsHeader'
import SuspensionGroupsCustomView from 'components/customers/groups/suspension/SuspensionGroupsCustomView'
import AccountGroupDefaultView from 'components/customers/groups/account/AccountGroupDefaultView'

const SuspensionGroups = () => {
  const showSideNav = useSelector(selectShowCustomerSideNav)

  return (
    <>
      <CustomerSideNav />
      <CustomerLayout open={showSideNav}>
        <SuspensionGroupsHeader />
        <Box display="flex" flexDirection="column" width="100%">
          <AccountGroupDefaultView />
          <SuspensionGroupsCustomView />
        </Box>
      </CustomerLayout>
    </>
  )
}

SuspensionGroups.propTypes = {}

export default SuspensionGroups
