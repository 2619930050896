import api from '../index'
import { CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getARCustomerSummary: builder.query({
      providesTags: [CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY],
      query: ({ accountId }) => ({ url: `/api/v1/billing/account/${accountId}/receivables/summary` }),
    }),
  }),
})

export const { useLazyGetARCustomerSummaryQuery } = extendedApi
