import React from 'react'

import { useLocation, Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { AppBar, Toolbar, Box, IconButton, useTheme, SvgIcon } from '@mui/material'
import { grey } from '@mui/material/colors'
import { BACKGROUND } from 'theme/colors'

import User from 'components/user'
import MenuToolTip from 'components/common/Tooltip'

import { canSeeSettingsTab, canSeeCustomersTab } from 'data/permissions/permissionsSelectors'
import { ReactComponent as BeamerIcon } from 'assets/BeamerIcon.svg'

import { get } from 'utils/lodash'

import routes from 'router/routes'
import { Cog } from '@styled-icons/heroicons-outline'
import { Z_INDEX } from 'theme/zIndex'
import BusinessLogo from './BusinessLogo'
import NavigationMenu from './NavigationMenu'
import GlobalAddMenu from './GlobalAddMenu'
import NotificationsMenu from '../notifications/NotificationsMenu/NotificationsMenu'
import { isNotificationFeatureAvailable } from '../../data/launch-darkly/featureSelectors'
import { BEAMER_SELECTOR_ID } from './settings'

const { NAVIGATION } = Z_INDEX

const Header = () => {
  const flags = useFlags()
  const theme = useTheme()
  const location = useLocation()
  const { pathname } = location

  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const firstName = get(userInfo, 'firstName', '')
  const lastName = get(userInfo, 'lastName', '')
  const profilePicture = get(userInfo, 'profilePicUri', '')

  const settingRoute = `${routes.app.settings}/business-information`

  const appBarSx = { height: '56px', backgroundColor: BACKGROUND.appbar, zIndex: NAVIGATION }
  const isSettingsActive = pathname.split('/').includes('settings')
  return (
    <Box display="block" sx={appBarSx}>
      <AppBar elevation="0" position="fixed" sx={appBarSx}>
        <Toolbar variant="dense">
          <BusinessLogo />
          <NavigationMenu />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {canSeeCustomersTab && <GlobalAddMenu />}
            <MenuToolTip title="What's new on Hauler Hero">
              <IconButton id={BEAMER_SELECTOR_ID} sx={{ opacity: 0.9 }}>
                <BeamerIcon />
              </IconButton>
            </MenuToolTip>
            {isNotificationFeatureAvailable({ flags }) && <NotificationsMenu />}
            {canSeeSettingsTab() && (
              <MenuToolTip title="Settings">
                <IconButton size="large" component={Link} to={settingRoute}>
                  <SvgIcon sx={{ color: isSettingsActive ? theme.palette.background.paper : grey[500] }}>
                    <Cog />
                  </SvgIcon>
                </IconButton>
              </MenuToolTip>
            )}
            <User firstName={firstName} lastName={lastName} profilePicture={profilePicture} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
