import { useCallback } from 'react'
import {
  combineAndRecolorMarkers,
  combineAndResetPositionRows,
  filterAndRepositionUnselectedRows,
} from 'components/route-manager/BulkActions/settings'
import { get, partition } from 'lodash'
import { useFormContext } from 'react-hook-form'
import useTheme from '@mui/material/styles/useTheme'
import { useDispatch } from 'react-redux'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const useBulkMoveSelectedRows = ({
  moveToRows,
  moveFromRows,
  moveFromRouteStopMarkers,
  moveToRouteStopMarkers,
  routesById,
  moveToRouteSequenceLine,
  bulkReduxAction,
}) => {
  const dispatch = useDispatch()
  const { watch, getValues, setValue } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const theme = useTheme()
  return useCallback(() => {
    const values = getValues()
    const selectedRows = Array.from(selectedRowIdsMap.values())

    const unselectedMoveToRows = filterAndRepositionUnselectedRows(moveToRows, selectedRowIdsMap)
    const updatedMoveFromRows = combineAndResetPositionRows(moveFromRows, selectedRows)

    const [selectedMoveToMarkers, unselectedMoveToMarkers] = partition(moveToRouteStopMarkers, feature =>
      selectedRowIdsMap.has(feature.properties.id)
    )
    const updatedMoveFromMarkers = combineAndRecolorMarkers(
      moveFromRouteStopMarkers,
      selectedMoveToMarkers,
      routesById,
      moveFromRouteId,
      theme
    )
    const unselectedMoveToSequences = get(moveToRouteSequenceLine, 'geometry.coordinates', []).filter(
      (coord, index) => !selectedRowIdsMap.has(moveToRows[index].id)
    )

    const unselectedMoveToRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: unselectedMoveToSequences },
    }

    const updatedMoveFromSequences = updatedMoveFromRows.map(({ latitude, longitude }) => {
      return [longitude, latitude]
    })

    const updatedMoveFromRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: updatedMoveFromSequences },
    }

    const updatedMoveToRows = []
    const updatedMoveToMarkers = []
    const toBeInsertedRowsIds = []
    let currentPosition = 0
    for (let i = 0; i < unselectedMoveToRows.length; i++) {
      const rowItem = { ...unselectedMoveToRows[i] }
      const markerItem = { ...unselectedMoveToMarkers[i], properties: { ...unselectedMoveToMarkers[i].properties } }

      const rowId = get(rowItem, 'id')
      const routeId = get(rowItem, 'routeId')

      if (routeId === moveToRouteId) {
        rowItem.position = currentPosition
        markerItem.properties.position = currentPosition + 1
        currentPosition += 1
      } else {
        toBeInsertedRowsIds.push(rowId)
        const rowTypedPosition = get(values, rowId, '')

        if (rowTypedPosition && rowTypedPosition !== currentPosition + 1) {
          setValue(rowId, currentPosition + 1)
        }

        rowItem.position = rowTypedPosition ? currentPosition : ''
        markerItem.properties.position = rowTypedPosition ? currentPosition + 1 : ''

        if (rowTypedPosition !== '') {
          currentPosition += 1
        }
      }
      updatedMoveToRows.push(rowItem)
      updatedMoveToMarkers.push(markerItem)
    }
    dispatch(
      bulkReduxAction({
        moveToRows: updatedMoveToRows,
        moveFromRows: updatedMoveFromRows,
        moveFromRouteStopMarkers: updatedMoveFromMarkers,
        moveToRouteStopMarkers: updatedMoveToMarkers,
        moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
        moveToRouteSequenceLine: unselectedMoveToRouteSequenceLine,
        toBeInsertedRowsIds,
      })
    )
    apiRef.current.selectRows([])
  }, [moveToRows, moveFromRows, moveFromRouteStopMarkers, moveToRouteStopMarkers, routesById, moveToRouteSequenceLine])
}

export default useBulkMoveSelectedRows
