import { lowerCase, filter } from 'lodash'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { hasIntersection, getSortKeyWithDigits } from 'components/locations/settings'
import { SORT_BY_FIRST_NAME_CRITERIA } from 'components/customers/accounts/settings'
import { getAccountStatusIdsValues } from 'components/customers/groups/account/details/filters/account-status-filter/settings'
import { OTHER_SUSPENSION_REASON } from 'components/customer-details/content/suspensions/settings'

export const getExpandedRows = createSelector(
  [state => state?.rows, state => state?.isExpanded, state => state?.noOfColumns],
  (rows = [], isExpanded = false, noOfColumns = 1) => {
    return [{ groupId: 'header' }, ...rows.filter((row, index) => isExpanded && index % noOfColumns === 0)]
  }
)

export const sortAccountsByFirstName = (a, b) => {
  const customerNameA = get(a, SORT_BY_FIRST_NAME_CRITERIA, '')
  const customerNameB = get(b, SORT_BY_FIRST_NAME_CRITERIA, '')
  const firstCharA = customerNameA.charAt(0).toLowerCase()
  const firstCharB = customerNameB.charAt(0).toLowerCase()

  const sortKeyA = getSortKeyWithDigits(firstCharA)
  const sortKeyB = getSortKeyWithDigits(firstCharB)

  if (sortKeyA < sortKeyB) return -1
  if (sortKeyA > sortKeyB) return 1
  return 0
}

export const getFilteredRowsForAddRemoveDialog = createSelector(
  [state => state?.rows, state => state?.search, state => state?.selectedTagsIds, state => state?.selectedBillingProfilesIds],
  (rows = [], search = '', selectedTagsIds = [], selectedBillingProfilesIds = []) => {
    const lowerCaseSearch = lowerCase(search)
    const selectedTagIdsSet = new Set(selectedTagsIds)
    const selectedBillingProfilesIdsSet = new Set(selectedBillingProfilesIds)
    const filteredRows = filter(rows, row => {
      const accountName = get(row, 'accountName', '').toLowerCase()
      if (search && !accountName.includes(lowerCaseSearch)) return false

      const billingProfileId = get(row, 'billingProfileId')
      const tagIds = get(row, 'tagIds', [])
      return hasIntersection(selectedTagIdsSet, tagIds) && (selectedBillingProfilesIdsSet.has(billingProfileId) || !billingProfileId)
    })

    return filteredRows.sort(sortAccountsByFirstName)
  }
)

export const getFilteredRowsForAccountGroupDetails = createSelector(
  [
    state => state?.rows,
    state => state?.selectedTagsIds,
    state => state?.selectedBillingProfilesIds,
    state => state?.selectedAccountStatusIds,
    state => state?.selectedSuspensionTypesValues,
    state => state?.isSuspendedBucket,
  ],
  (
    rows = [],
    selectedTagsIds = [],
    selectedBillingProfilesIds = [],
    selectedAccountStatusIds = [],
    selectedSuspensionTypesValues = [],
    isSuspendedBucket = false
  ) => {
    const selectedTagIdsSet = new Set(selectedTagsIds)
    const selectedBillingProfilesIdsSet = new Set(selectedBillingProfilesIds)
    const selectedAccountStatusIdsValues = getAccountStatusIdsValues(selectedAccountStatusIds)
    return filter(rows, row => {
      const active = get(row, 'active')
      const billingProfileId = get(row, 'billingProfileId')
      const tagIds = get(row, 'tagIds', [])
      const suspensionType = get(row, 'suspensionType', OTHER_SUSPENSION_REASON)
      return (
        selectedAccountStatusIdsValues.includes(active) &&
        hasIntersection(selectedTagIdsSet, tagIds) &&
        (selectedBillingProfilesIdsSet.has(billingProfileId) || !billingProfileId) &&
        (isSuspendedBucket ? selectedSuspensionTypesValues.includes(suspensionType) : true)
      )
    })
  }
)
