import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { ReactComponent as LateFeeIcon } from 'assets/pricing/LateFee.svg'
import Divider from '@mui/material/Divider'
import { List, ListItem, SvgIcon } from '@mui/material'
import Badge from '@mui/material/Badge'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const LateFeesPopover = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'late-fees-popover' : undefined

  return (
    <>
      <IconButton aria-describedby={id} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <Badge badgeContent={2} color="error">
          <SvgIcon
            sx={{
              fontSize: 26,
            }}
          >
            <LateFeeIcon />
          </SvgIcon>
        </Badge>
      </IconButton>
      <Popover
        sx={{ pointerEvents: 'none' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableRestoreFocus
      >
        <List
          sx={{
            py: 0,
            minWidth: 320,
          }}
        >
          <ListItem>
            <ListItemText
              primary="Late fee name 1"
              primaryTypographyProps={{
                variant: 'body1',
                fontWeight: '500',
                color: 'textPrimary',
              }}
              secondary="Daily | Indefinite"
              secondaryTypographyProps={{
                variant: 'body2',
                color: 'textSecondary',
              }}
            />
            <Typography variant="h5">
              <HHDisplayMoney value={5} formatToDollars={false} />
            </Typography>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default LateFeesPopover
