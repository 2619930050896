import { createSlice } from '@reduxjs/toolkit'
import { ADDRESS_SEARCH_TYPE } from 'components/locations/filters/Search/settings'
import { SORT_BY_DEFAULT_VALUE } from 'components/locations/settings'
import { get } from 'lodash'

const initialState = {
  filters: {
    selectedBillingProfiles: [],
    selectedTags: [],
    sortBy: SORT_BY_DEFAULT_VALUE,
    searchType: ADDRESS_SEARCH_TYPE,
    searchInputValue: '',
    searchValue: '',
    expandedRowIds: [],
    searchTagsInput: '',
    searchBillingInput: '',
    withDeactivated: true,
    withDigits: true,
  },
  csNoteDialog: {
    open: false,
    selectedService: null,
  },
  scrollTopPosition: 0,
}

const locationsSlice = createSlice({
  name: 'locationsSlice',
  initialState,
  reducers: {
    setLocationsFilters: (state, action) => {
      state.filters = {
        ...state?.filters,
        ...action.payload,
      }
    },
    setScrollTopPosition: (state, action) => {
      state.scrollTopPosition = action.payload
    },
    setCsNoteDialog: (state, action) => {
      state.csNoteDialog = {
        ...state.csNoteDialog,
        ...action.payload,
      }
    },
  },
})

export const selectLocationFilters = s => get(s, 'locations.filters')
export const selectScrollTopPosition = s => get(s, 'locations.scrollTopPosition')
export const selectCsDialog = s => get(s, 'locations.csNoteDialog')
export const selectCsDialogOpen = s => get(s, 'locations.csNoteDialog.open', false)
export const selectCsSelectedService = s => get(s, 'locations.csNoteDialog.selectedService')

export const { setLocationsFilters, setScrollTopPosition, setCsNoteDialog } = locationsSlice.actions
export default locationsSlice.reducer
