import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ReactComponent as MoneyCheckDollarPenIcon } from 'assets/MoneyCheckDollarPen.svg'
import { ReactComponent as MoneyCheckDollarPlusIcon } from 'assets/MoneyCheckDollarPlus.svg'

import T from 'T'
import AddEditPurchaseOrderDialog from 'components/work-order/purchase-order/AddEditPurchaseOrderDialog'

const AddEditPurchaseOrderMenuOption = ({ workOrderId, existingPurchaseOrder = '', onClose }) => {
  const [isOpenAddEditPurchaseOrderDialog, setIsOpenAddEditPurchaseOrderDialog] = useState(false)

  const handleAddEditPOClick = () => setIsOpenAddEditPurchaseOrderDialog(true)

  const handleAddEditPOClose = () => {
    setIsOpenAddEditPurchaseOrderDialog(false)
    onClose()
  }

  return (
    <>
      <MenuItem onClick={handleAddEditPOClick}>
        <ListItemIcon sx={{ mr: 1 }}>
          {existingPurchaseOrder ? <MoneyCheckDollarPenIcon width={24} height={24} /> : <MoneyCheckDollarPlusIcon width={24} height={24} />}
        </ListItemIcon>
        <ListItemText>{existingPurchaseOrder ? T.EDIT_PURCHASE_ORDER : T.ADD_PURCHASE_ORDER}</ListItemText>
      </MenuItem>
      <AddEditPurchaseOrderDialog
        isOpen={isOpenAddEditPurchaseOrderDialog}
        workOrderId={workOrderId}
        existingPurchaseOrder={existingPurchaseOrder}
        onClose={handleAddEditPOClose}
      />
    </>
  )
}

AddEditPurchaseOrderMenuOption.propTypes = {
  workOrderId: PropTypes.string.isRequired,
  existingPurchaseOrder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default AddEditPurchaseOrderMenuOption
