import { actions } from '../actions/globalsearch'

const {
  GLOBAL_SEARCH_RESULT,
  GLOBAL_SEARCH_FILTER,
  GLOBAL_FILTER_OPTIONS,
  SEARCH_SUGGEST_RESULT,
  SUGGEST_CONTACT_LIST,
  SUGGEST_PRICED_SERVICE_LIST,
  SUGGEST_CONFIGURED_SERVICE_LIST,
  SUGGEST_NOTE_LIST,
  SUGGEST_WORK_ORDER_LIST,
  SUGGEST_FOLLOW_UP_LIST,
} = actions.PUT

const initialState = {
  globalSearchResult: null,
  globalFilterResult: null,
  globalFilterOptions: null,
  searchSuggestList: null,
  contactSuggestList: null,
  pricedServiceSuggestList: null,
  configuredServiceSuggestList: null,
  noteSuggestList: null,
  workOrderSuggestList: null,
  followUpSuggestList: null,
}

const GlobalSearchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GLOBAL_SEARCH_RESULT:
      return { ...state, globalSearchResult: action.payload }
    case GLOBAL_SEARCH_FILTER:
      return { ...state, globalFilterResult: action.payload }
    case GLOBAL_FILTER_OPTIONS:
      return { ...state, globalFilterOptions: action.payload }
    case SEARCH_SUGGEST_RESULT:
      return { ...state, searchSuggestList: action.payload }
    case SUGGEST_CONTACT_LIST:
      return { ...state, contactSuggestList: action.payload }
    case SUGGEST_PRICED_SERVICE_LIST:
      return { ...state, pricedServiceSuggestList: action.payload }
    case SUGGEST_CONFIGURED_SERVICE_LIST:
      return { ...state, configuredServiceSuggestList: action.payload }
    case SUGGEST_NOTE_LIST:
      return { ...state, noteSuggestList: action.payload }
    case SUGGEST_WORK_ORDER_LIST:
      return { ...state, workOrderSuggestList: action.payload }
    case SUGGEST_FOLLOW_UP_LIST:
      return { ...state, followUpSuggestList: action.payload }
    default:
      return state
  }
}
export default GlobalSearchReducer
