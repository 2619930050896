import React from 'react'
import { styled, TableCell, TableRow, Skeleton, Box } from '@mui/material'

const StyledTableCell = styled(TableCell)(() => ({
  padding: 12,
}))

const ShimmerTagTableRow = () => {
  return (
    <TableRow>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={26} height={26} />
      </StyledTableCell>
      <StyledTableCell>
        <Box display="flex">
          <Skeleton variant="rectangular" width={26} height={26} />
          <Skeleton variant="rectangular" width={26} height={26} sx={{ marginLeft: 1 }} />
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="text" />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={32} height={16} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={26} height={26} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={26} height={26} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={26} height={26} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={26} height={26} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={100} height={24} />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton variant="rectangular" width={32} height={16} />
      </StyledTableCell>
      <StyledTableCell />
    </TableRow>
  )
}

export default ShimmerTagTableRow
