import React from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import noop from 'lodash/noop'
import SettingsTable from '../../../SettingsTable'

export const columns = [
  { key: 'name', name: T.ROLE_NAME, colSpan: 2 },
  { key: 'employees', name: T.EMPLOYEES, align: 'right' },
  { key: 'lastUpdated', name: T.LAST_UPDATED, align: 'right' },
]

const ListRoles = ({
  roleState = {},
  roles = [],
  handlePageChange = noop,
  handleRowsPerPageChange = noop,
  handleEdit = noop,
  isLoading = false,
}) => {
  const data = roles.map(({ id, profileName, employees, updatedAt }) => {
    return { id, name: profileName, employees, lastUpdated: updatedAt }
  })
  return (
    <SettingsTable
      columns={columns}
      data={data}
      paginated
      isLoading={isLoading}
      onEditRecord={({ id }) => handleEdit(id)}
      page={roleState.page}
      onPageChange={handlePageChange}
      size={roleState.rowsPerPage}
      onRowsPerPageChange={handleRowsPerPageChange}
      count={roleState.totalRolesCount}
      pageCount={roleState.totalPageCount}
    />
  )
}

ListRoles.propTypes = {
  roleState: PropTypes.object,
  roles: PropTypes.array,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleEdit: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ListRoles
