import React from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import { Chip, Avatar, useTheme } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { green, red, orange, grey, blue } from '@mui/material/colors'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { AGING_BUCKET_COLORS } from '../aging-bucket/settings'
import HHDisplayMoney from '../HHDisplayMoney'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS

const InvoiceStatusChip = ({ status, isPartial = false, agingBucketType = '', count = 0, showAmount = false, amountCents = 0 }) => {
  const theme = useTheme()

  const getParams = () => {
    if (status === UNPAID && agingBucketType) {
      return { color: AGING_BUCKET_COLORS[agingBucketType], label: isPartial ? PARTIAL : UNPAID }
    }

    if (status === PAID) {
      return { color: green, label: PAID }
    }

    if (status === PARTIAL) {
      return { color: grey, label: 'Draft' }
    }

    if (status === UNPAID) {
      return isPartial ? { color: orange, label: PARTIAL } : { color: blue, label: UNPAID }
    }

    return { color: red, label: status }
  }

  const { color, label } = getParams()

  return (
    <Chip
      size="small"
      avatar={
        <Avatar sx={{ backgroundColor: color[500] }}>
          <AttachMoneyIcon sx={{ fontSize: 16, color: theme.palette.common.white }} />
        </Avatar>
      }
      variant="outlined"
      label={
        <>
          {count > 0 && <NumericFormat value={count} displayType="text" thousandSeparator />}
          {count > 0 && <>&nbsp;</>}
          {showAmount ? <HHDisplayMoney value={amountCents} /> : label}
        </>
      }
      sx={{
        fontWeight: 600,
        border: '1px solid',
        borderColor: color[500],
        backgroundColor: color[50],
        color: color[700],
      }}
    />
  )
}

InvoiceStatusChip.propTypes = {
  status: PropTypes.string,
  isPartial: PropTypes.bool,
  agingBucketType: PropTypes.string,
  count: PropTypes.number,
  showAmount: PropTypes.bool,
  amountCents: PropTypes.number,
}

export default InvoiceStatusChip
