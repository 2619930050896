import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Droppable, Draggable } from 'react-beautiful-dnd'
import { FixedSizeList, areEqual } from 'react-window'
import { AutoSizer } from 'react-virtualized'
import { Box, Typography } from '@mui/material'
import { PRIMARY } from 'theme/colors'

import { get } from 'utils/lodash'
import { DROPPABLE_COLUMN_TYPE_WORK_ORDER, DROPPABLE_ID_UNASSIGNED } from 'data/route/routeDataMappingSelector'

import T from 'T'
import UnassignedListItem from '../UnassignedListItem'
import { getStyle, UNASSIGNED_COLUMN_WIDTH } from './settings'

// Item component
const Item = ({ provided, stop, index, style, isDragging, ...rest }) => {
  return (
    <Box
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`unassigned ${isDragging ? 'is-dragging' : ''}`}
      sx={{
        '&.is-dragging .MuiCard-root': { border: `2pt solid ${PRIMARY.main}` },
      }}
    >
      <UnassignedListItem workOrder={stop} {...rest} />
    </Box>
  )
}

Item.propTypes = {
  provided: PropTypes.object,
  index: PropTypes.number,
  stop: PropTypes.object,
  style: PropTypes.object,
  isDragging: PropTypes.bool,
}

// Row component
const Row = React.memo(props => {
  const { data, index, style } = props
  const { stops, isDragDisabled, ...rest } = data

  const stop = get(stops, `[${index}]`)

  if (!stop) {
    return null
  }

  const workOrderId = get(stop, 'workOrderId')

  return (
    <Draggable draggableId={workOrderId} index={index} key={workOrderId} isDragDisabled={isDragDisabled}>
      {provided => <Item provided={provided} stop={stop} {...rest} index={index} style={style} />}
    </Draggable>
  )
}, areEqual)

Row.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number,
  style: PropTypes.object,
  dragPermissionsDisabled: PropTypes.bool,
  isColumnDragDisabled: PropTypes.bool,
}

// Unassigned Column component
const UnassignedColumn = props => {
  const { unassignedWorkOrders, isDropDisabled, ...rest } = props
  const listRef = useRef()

  return (
    <Droppable
      droppableId={DROPPABLE_ID_UNASSIGNED}
      mode="virtual"
      type={DROPPABLE_COLUMN_TYPE_WORK_ORDER}
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          stop={unassignedWorkOrders[rubric.source.index]}
          index={rubric.source.index}
        />
      )}
      isDropDisabled={isDropDisabled}
    >
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder ? unassignedWorkOrders.length + 1 : unassignedWorkOrders.length

        return (
          <>
            {unassignedWorkOrders.length === 0 && (
              <Typography variant="body1" color="textSecondary" textAlign="center" p={2}>
                {T.UNASSIGNED_STOPS_NO_ITEMS}
              </Typography>
            )}

            <Box height="100%">
              <AutoSizer defaultHeight={500} disableWidth>
                {({ height }) => (
                  <FixedSizeList
                    height={height}
                    itemCount={itemCount}
                    itemSize={170}
                    width={UNASSIGNED_COLUMN_WIDTH}
                    outerRef={provided.innerRef}
                    itemData={{ ...rest, stops: unassignedWorkOrders }}
                    ref={listRef}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Box>
          </>
        )
      }}
    </Droppable>
  )
}

UnassignedColumn.propTypes = {
  isDragDisabled: PropTypes.bool,
  unassignedWorkOrders: PropTypes.array,
  isDropDisabled: PropTypes.bool,
  onTagsSave: PropTypes.func,
}

export default UnassignedColumn
