import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

const ContactListItem = ({ title = '', Icon, primaryProps = {} }) => (
  <ListItem>
    <ListItemIcon sx={{ minWidth: 24 }}>
      <Icon />
    </ListItemIcon>
    <ListItemText sx={{ ml: 1 }} primaryTypographyProps={{ ...primaryProps }} primary={title} />
  </ListItem>
)

ContactListItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
  Icon: PropTypes.node,
  primaryProps: PropTypes.object,
}

export default ContactListItem
