import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'utils/lodash'
import Locations from 'containers/customers/Locations'
import CustomerSideNav from 'components/customers/common/side-nav/CustomerSideNav'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import NavbarWrapper from '../components/common/NavbarWrapper'

const LocationsPage = () => {
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)

  return (
    <ProtectedRouteHoC>
      <NavbarWrapper
        sx={{ backgroundColor: 'background.paper', overflow: 'hidden' }}
        innerSx={{
          flex: '1 1 0',
          overflow: 'hidden',
        }}
      >
        <CustomerSideNav />
        <CustomerLayout sx={{ overflow: 'hidden' }} open={showCustomerSideNav}>
          <Locations />
        </CustomerLayout>
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

export default LocationsPage
