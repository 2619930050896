import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@mui/material'

const ServiceMaterialTypeChip = ({ materialColor, label = '', ...rest }) => {
  const theme = useTheme()
  const textColor = materialColor ? theme.palette.getContrastText(materialColor) : ''

  return (
    <Box
      px={1}
      py={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={1}
      {...(materialColor && { bgcolor: materialColor, color: textColor })}
      {...rest}
    >
      {label}
    </Box>
  )
}

ServiceMaterialTypeChip.propTypes = {
  materialColor: PropTypes.string,
  label: PropTypes.string,
}

export default ServiceMaterialTypeChip
