import { createSlice } from '@reduxjs/toolkit'
import { GENERAL_SEARCH_TYPE } from 'components/locations/filters/Search/settings'
import { SORT_BY_DEFAULT_VALUE } from 'components/customers/accounts/settings'
import { get } from 'utils/lodash'

export const initialState = {
  filters: {
    selectedBillingProfiles: [],
    selectedTags: [],
    sortBy: SORT_BY_DEFAULT_VALUE,
    searchType: GENERAL_SEARCH_TYPE,
    searchInputValue: '',
    searchValue: '',
    expandedRowIds: [],
    searchTagsInput: '',
    searchBillingInput: '',
    withDeactivated: true,
    withDigits: true,
  },
  scrollTopPosition: 0,
}

const accountsSlice = createSlice({
  name: 'accountsSlice',
  initialState,
  reducers: {
    setAccountsFilters: (state, action) => {
      state.filters = {
        ...state?.filters,
        ...action.payload,
      }
    },
    setScrollTopPosition: (state, action) => {
      state.scrollTopPosition = action.payload
    },
  },
})

export const selectAccountsFilters = state => get(state, 'accounts.filters')
export const selectScrollTopPosition = state => get(state, 'accounts.scrollTopPosition')

export const { setAccountsFilters, setScrollTopPosition } = accountsSlice.actions
export default accountsSlice.reducer
