import React from 'react'
import NavbarWrapper from 'components/common/NavbarWrapper'
import RentalFeeDetails from 'containers/pricing/RentalFeeDetails'

const RentalFeeDetailsPage = () => {
  return (
    <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
      <RentalFeeDetails />
    </NavbarWrapper>
  )
}

export default RentalFeeDetailsPage
