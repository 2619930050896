import React from 'react'
import PropTypes from 'prop-types'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { TableCell } from '@mui/material'

import { get } from 'utils/lodash'
import { handlePricingTableClass } from 'components/pricing/pricingMethods'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import RentalFeeBadgeButton from '../RentalFees/common/badge/RentalFeeBadgeButton'

const ServiceBody = ({ service, column, getPricingServiceName, ind, lockedColumns }) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const priceServiceName = getPricingServiceName(service, column.label, 'listing', true)
  const isLocked = get(column, 'locked', false)

  return (
    <>
      {!['rentalFeeCount'].includes(column.columnName) && (
        <TableCell className={`${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
          <div className="price-cell">{priceServiceName}</div>
        </TableCell>
      )}

      {isRentalFeeEnabled && column.columnName === 'rentalFeeCount' && (
        <TableCell sx={{ textAlign: 'center' }} className={`${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
          <RentalFeeBadgeButton rentalFeeCount={get(service, column.columnName, 0)} pricedServiceId={service.id} disableRipple />
        </TableCell>
      )}
    </>
  )
}

ServiceBody.defaultProps = {
  ind: null,
  service: {},
  column: {},
  lockedColumns: [],
  getPricingServiceName: null,
}

ServiceBody.propTypes = {
  ind: PropTypes.number,
  service: PropTypes.object,
  column: PropTypes.object,
  lockedColumns: PropTypes.array,
  getPricingServiceName: PropTypes.func,
}

export default ServiceBody
