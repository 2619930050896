import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Button } from '@mui/material'
import Table from 'components/settings/pages/businesslines/Table'
import Add from '@mui/icons-material/Add'
import T from 'T'

import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'

const headerValues = [
  { filter: false, name: T.COLOR, sort: false },
  { filter: false, name: T.MATERIAL, sort: false },
  { filter: false, name: T.ACTIVE, sort: false },
  { filter: false, name: T.REQUIRE_PHOTO, sort: false },
  { filter: false, name: '', sort: false },
]

const Materials = ({ material = [], materialCombinations = [], setServiceState = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  useEffect(() => {
    let materialCloned = cloneDeep(material)
    setServiceState({
      materialCombinations: materialCloned,
    })
  }, [material])

  const handleSwitchVal = (scope, value, index) => {
    if (updateSettings) {
      materialCombinations[index][scope] = value
      setServiceState({ materialCombinations, showFooter: true })
    }
  }

  const onHandleChange = (e, index, type) => {
    if (type === 'color') {
      materialCombinations[index]['color'] = e.hex
    } else {
      materialCombinations[index]['materialType'] = e.target.value
    }

    const footerVal = materialCombinations[index]['materialType'] !== ''

    setServiceState({ materialCombinations, showFooter: footerVal })
  }

  const handleRemoval = index => {
    materialCombinations.splice(index, 1)

    if (!materialCombinations.map(data => data.id).includes('')) {
      setServiceState({ showFooter: false })
    }

    setServiceState({ materialCombinations })
  }

  const addRows = () => {
    materialCombinations.push({
      active: true,
      photoRequired: true,
      alreadySelected: false,
      color: null,
      id: '',
      materialType: '',
    })
    setServiceState({ materialCombinations })
  }

  return (
    <Box sx={{ pointerEvents: updateSettings ? '' : 'none' }}>
      <Table
        headerValues={headerValues}
        records={materialCombinations}
        handleSwitchVal={handleSwitchVal}
        onHandleChange={onHandleChange}
        handleRemoval={handleRemoval}
        type="material"
      />

      {addSettings && (
        <Button onClick={addRows} variant="text" color="primary" startIcon={<Add />} sx={{ mt: 1 }}>
          {T.ADD_MATERIAL}
        </Button>
      )}
    </Box>
  )
}

Materials.propTypes = {
  material: PropTypes.array,
  materialCombinations: PropTypes.array,
  setServiceState: PropTypes.func,
}

export default Materials
