import React, { useMemo, useState } from 'react'

import { useParams } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, IconButton, Typography, useTheme, Tooltip } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import ReorderIcon from 'components/common/ReorderIcon'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { AGING_BUCKET_LABEL } from 'settings/constants/accountReceivables'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import { red } from '@mui/material/colors'
import AccountGroupActionMenu from '../AccountGroupActionMenu'
import { getIsDefaultBucket, getIsSuspendedBucket } from '../common/settings'
import AddAccountsToGroupDialog from '../add-remove/AddAccountsToGroupDialog'

const AccountGroupDetailsHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { data, isLoading, isFetching } = useGetAccountGroupDetailsData(id, true)
  const [accountGroupAnchorEl, setAccountGroupAnchorEl] = useState(null)
  const [isOpenAddAccountsToGroupDialog, setIsOpenAddAccountsToGroupDialog] = useState(false)
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const isDefaultBucket = getIsDefaultBucket(id)
  const isSuspendedBucket = getIsSuspendedBucket(id)
  const isSuspendedOrDefaultBucket = isDefaultBucket || isSuspendedBucket
  const color = isSuspendedBucket ? red : get(AGING_BUCKET_COLORS, id)
  const bucketName = useMemo(() => {
    if (isSuspendedBucket) return 'Suspended'
    if (isDefaultBucket) return get(AGING_BUCKET_LABEL, id)
    return get(data, 'groupName', '')
  }, [data, id, isDefaultBucket, isSuspendedBucket])

  const handleDrawerToggle = () => dispatch(setShowCustomerSideNav(!showCustomerSideNav))
  const handleOpenAccountGroupActionMenu = event => setAccountGroupAnchorEl(event.currentTarget)
  const handleCloseActionMenu = () => setAccountGroupAnchorEl(false)
  const handleOpenAddAccountsToGroupDialog = () => setIsOpenAddAccountsToGroupDialog(true)
  const handleCloseAddAccountsToGroupDialog = () => setIsOpenAddAccountsToGroupDialog(false)

  return (
    <>
      <Grid
        container
        pr={3}
        pl={showCustomerSideNav ? 3 : 1.5}
        minHeight={56}
        alignItems="center"
        borderBottom={isSuspendedOrDefaultBucket ? 2 : 1}
        borderColor={color ? color[500] : 'divider'}
        bgcolor={color ? color[50] : theme.background.default}
      >
        <Grid item xs display="flex" alignItems="center">
          {!showCustomerSideNav && <ReorderIcon onClick={handleDrawerToggle} />}
          <Typography variant="h4" fontWeight={600}>
            {bucketName}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          {!isSuspendedOrDefaultBucket && (
            <Tooltip title="Add accounts to this group">
              <IconButton onClick={handleOpenAddAccountsToGroupDialog}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={handleOpenAccountGroupActionMenu}>
            <MoreVert />
          </IconButton>
        </Grid>
      </Grid>
      <AccountGroupActionMenu
        anchorEl={accountGroupAnchorEl}
        selectedGroup={data}
        showAddToAccountGroupOption={isDefaultBucket}
        showEditGroupOption={!isSuspendedOrDefaultBucket}
        showRemoveAccountsOption={!isSuspendedOrDefaultBucket}
        showBulkSuspendOption={!isSuspendedBucket}
        showBulkUnsuspendOption
        loading={loading}
        onClose={handleCloseActionMenu}
      />
      {isOpenAddAccountsToGroupDialog && (
        <AddAccountsToGroupDialog isOpen={isOpenAddAccountsToGroupDialog} groupId={id} onClose={handleCloseAddAccountsToGroupDialog} />
      )}
    </>
  )
}

export default AccountGroupDetailsHeader
