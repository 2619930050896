import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'

import { Box, Button, DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useUpdateAccountMutation } from 'api/accounts/updateAccount'
import { addBillingPOAndTax } from 'utils/form-body'
import { getPOAndTaxInfo, getExistingCustomerInfo } from 'utils/customerPayload'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import AccountRepDropdown from './AccountRepDropdown'

const EditAccountRepDialog = ({ isOpen = false, customerDetails = {}, onClose }) => {
  const dispatch = useDispatch()
  const [updateAccount] = useUpdateAccountMutation()
  const [csrId, setCsrId] = useState(null)

  const accountId = get(customerDetails, 'id')
  const currentCsrId = get(customerDetails, 'csr.id', '')
  const accountBilling = get(customerDetails, 'accountBilling', {})
  const billingProfileId = get(accountBilling, 'billingProfile.id')

  const handleUpdateCsr = () => {
    dispatch(putIsLoading(true))
    const payload = {
      id: accountId,
      ...getExistingCustomerInfo(customerDetails),
      csr: csrId || null,
      accountBilling: {
        billingProfileId,
        id: accountBilling.id,
        ...addBillingPOAndTax({ ...getPOAndTaxInfo(customerDetails) }),
      },
    }

    updateAccount(payload)
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        toast.success(T.ACCOUNT_REP_UPDATED_SUCCESSFULLY)
        onClose()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useEffect(() => {
    if (isOpen) {
      setCsrId(currentCsrId)
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={T.ACCOUNT_REP} onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box mt={2}>
          <AccountRepDropdown csrId={csrId} onChange={id => setCsrId(id)} />
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <Button disabled={csrId === currentCsrId} size="small" variant="contained" onClick={handleUpdateCsr}>
          {T.SAVE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

EditAccountRepDialog.propTypes = {
  isOpen: PropTypes.bool,
  customerDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default EditAccountRepDialog
