import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'

import { IconButton, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { REGEX } from 'utils/validations'

import T from 'T'
import HHFormPhoneField from 'components/form-fields/v5/HHFormPhoneField'

const PhoneSearchField = ({ onEnter, onSearch, onChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const hasError = Boolean(errors?.phone)
  const helperText = hasError && 'Incomplete phone number'
  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onEnter()
    }
  }
  return (
    <HHFormPhoneField
      onKeyDown={onKeyDown}
      autoFocus
      onChange={onChange}
      sx={{ mt: 1, '& .MuiInputBase-root': { pr: 0 } }}
      deprecatedLabel={false}
      control={control}
      error={hasError}
      helperText={helperText}
      name="phone"
      rules={{ pattern: REGEX.IS_VALID_MOBILE }}
      label={T.CONTACT_PHONE_NUMBER}
      placeholder="Search phone numbers"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

PhoneSearchField.propTypes = {
  onEnter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PhoneSearchField
