import Constants from 'Constants'

const { INTERCOM_APP_ID } = Constants

export const initializeIntercom = (params = {}) => {
  window.Intercom('boot', { app_id: INTERCOM_APP_ID, vertical_padding: 65, ...params })
}

export const updateIntercom = (params = {}) => {
  window.Intercom('update', { app_id: INTERCOM_APP_ID, vertical_padding: 65, ...params })
}

export const shutdownIntercom = () => {
  window.Intercom('shutdown')
  initializeIntercom()
}
