import api from 'api/index'
import {
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
  CACHE_TAG_ACCOUNT_SETTINGS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { transformSearchResponse } from 'utils/accountSearch'
import { get } from 'utils/lodash'
import { useBulkUpdateAccountSettingsMutation } from 'api/groups/account/bulkUpdateAccountSettings'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountGroupSummaryByAgingBucket: builder.query({
      query: () => ({
        url: `/api/v1/core/accounts/groups/by-aging/summary`,
        method: 'GET',
      }),
      providesTags: (result, error) => [CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY],
    }),
    getAccountGroupDetailsByAgingBucket: builder.query({
      query: params => ({
        url: `/api/v1/core/accounts/groups/by-aging`,
        method: 'GET',
        params,
      }),
      transformResponse: (data, meta, params) => {
        const { accounts } = transformSearchResponse({ results: get(data, 'accounts', []) })
        return { ...data, accounts, groupId: params.aging }
      },
      providesTags: (result, error, { aging, suspendedOnly }) => [{ type: CACHE_TAG_AGING_GROUP_DETAILS, id: `${aging}-${suspendedOnly}` }],
    }),
    bulkAddAccountNoteToAgingGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/groups/aging-group:add-account-note`,
        body: { ...data, aging: id },
        method: 'POST',
      }),
      invalidatesTags: [
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
      ],
    }),
    bulkAddBillingNoteToAgingGroup: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/groups/aging-group:add-billing-note`,
        body: { ...data, aging: id },
        method: 'POST',
      }),
      invalidatesTags: [
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
      ],
    }),
    bulkAddTagsToAgingGroup: builder.mutation({
      query: ({ accountGroupId, tags }) => ({
        url: `/api/v1/core/accounts/groups/aging-group:add-tags`,
        body: { tags, aging: accountGroupId },
        method: 'PUT',
      }),
      invalidatesTags: [
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
        CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
      ],
    }),
    bulkUpdateAccountSettingsByAgingBucket: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/aging/group/${id}/settings`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_SETTINGS],
    }),
  }),
})

export const {
  useLazyGetAccountGroupSummaryByAgingBucketQuery,
  useLazyGetAccountGroupDetailsByAgingBucketQuery,
  useBulkAddBillingNoteToAgingGroupMutation,
  useBulkAddAccountNoteToAgingGroupMutation,
  useBulkAddTagsToAgingGroupMutation,
  useBulkUpdateAccountSettingsByAgingBucketMutation,
} = extendedApi
