import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import Header from '../header'
import { setShowAppBar } from '../../slices/appbar/appbarSlice'

const NavbarWrapper = ({ children, sx, className = '', innerSx }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setShowAppBar(false))
    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" sx={sx} className={className}>
      <Box height="56px">
        <Header />
      </Box>
      <Box flex={1} sx={innerSx}>
        {children}
      </Box>
    </Box>
  )
}

NavbarWrapper.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  className: PropTypes.string,
  innerSx: PropTypes.object,
}

export default NavbarWrapper
