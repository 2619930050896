import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link, Stack, Typography, Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { getWorkOrderDetailsPageUrl, getCustomerDetailsPageUrl } from 'router/routes'
import useTheme from '@mui/material/styles/useTheme'

const WOReviewDetailsCardLeftSection = ({
  accountId,
  accountNumber,
  id,
  line1,
  name,
  secondLine,
  serviceDate,
  unitNumber,
  workOrderSequence,
}) => {
  const theme = useTheme()
  const columnGap = theme.spacing(1)
  return (
    <Stack>
      <Box display="flex" alignItems="center" columnGap={columnGap}>
        <Typography variant="body2" noWrap gutterBottom>
          DATE:
        </Typography>
        <Typography color="primaryText" variant="h6" sx={{ ml: 0.25, fontWeight: 400 }} noWrap gutterBottom>
          {serviceDate}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" columnGap={columnGap}>
        <Typography variant="body2" noWrap gutterBottom>
          WORK ORDER #
        </Typography>
        <Link
          href={getWorkOrderDetailsPageUrl(id)}
          underline="hover"
          color="info.main"
          variant="h6"
          sx={{ ml: 0.25, fontWeight: 400 }}
          noWrap
          gutterBottom
        >
          {workOrderSequence}
        </Link>
      </Box>
      <Box display="flex" alignItems="center" columnGap={columnGap}>
        <Typography variant="body2" noWrap gutterBottom>
          ACCOUNT #
        </Typography>
        <Link
          underline="hover"
          color="info.main"
          component={RouterLink}
          to={getCustomerDetailsPageUrl(accountId)}
          rel="noopener noreferrer"
          target="_blank"
          variant="h6"
          sx={{ ml: 0.25, fontWeight: 400 }}
          noWrap
          gutterBottom
        >
          {accountNumber}
        </Link>
      </Box>

      <Link
        underline="hover"
        color="info.main"
        component={RouterLink}
        to={getCustomerDetailsPageUrl(accountId)}
        rel="noopener noreferrer"
        target="_blank"
        variant="h6"
        sx={{ mt: 1, mb: 0.25, fontWeight: 600 }}
        noWrap
      >
        {name}
      </Link>
      <Typography color="textPrimary" sx={{ fontWeight: 600, display: 'inline' }} noWrap>
        {unitNumber !== line1 && unitNumber}
        <Typography variant="body1" color="textPrimary" sx={{ display: 'inline' }} noWrap>
          {unitNumber !== line1 ? ` ${line1}` : line1}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textPrimary" noWrap>
        {secondLine}
      </Typography>
    </Stack>
  )
}

WOReviewDetailsCardLeftSection.propTypes = {
  accountId: PropTypes.string,
  accountNumber: PropTypes.string.isRequired,
  id: PropTypes.string,
  line1: PropTypes.string,
  name: PropTypes.string,
  secondLine: PropTypes.string,
  serviceDate: PropTypes.string.isRequired,
  unitNumber: PropTypes.string,
  workOrderSequence: PropTypes.string,
}

export default memo(WOReviewDetailsCardLeftSection)
