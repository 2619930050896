import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import {
  useLazyGetAllAccountNotesQuery,
  useCreateAccountNoteMutation,
  useUpdateAccountNoteMutation,
  usePatchAccountNoteMutation,
} from 'api/notes/accountNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import ViewArchivedNotesAction from '../common/actions/ViewArchivedNotesAction'
import ViewArchiveNotesDialog from '../common/ViewArchiveNotesDialog'
import AccountNotes from './AccountNotes'
import NoteSkeleton from '../common/NoteSkeleton'

const AccountNotesAccordionVariant = ({ accountId = '' }) => {
  const [getAllAccountNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllAccountNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes, archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddAccountNote = event => {
    event.stopPropagation()
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleViewArchiveClick = () => setIsOpenViewArchiveNoteDialog(true)

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  const handleCloseAddAccountNote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (accountId) {
      getAllAccountNotes({ accountId }).unwrap().catch(handleError)
    }
  }, [accountId])

  return (
    <>
      <HHAccordion
        withNewColors
        isExpanded
        isExpandCollapseHeightSame
        summary={`${T.ACCOUNT_NOTE}s`}
        showActionIcon={false}
        showCustomActions
        customActionContent={
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleOpenAddAccountNote}>
              <AddIcon />
            </IconButton>
            <ViewArchivedNotesAction onViewArchiveClick={handleViewArchiveClick} archivedNotes={archivedNotes} />
          </Box>
        }
      >
        {isGetAllNotesLoading && <NoteSkeleton rows={3} />}
        {!isGetAllNotesLoading && <AccountNotes notes={notes} accountId={accountId} />}
      </HHAccordion>
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_ACCOUNT_NOTE}
        useCreateNoteMutation={useCreateAccountNoteMutation}
        useUpdateNoteMutation={useUpdateAccountNoteMutation}
        onClose={handleCloseAddAccountNote}
      />
      <ViewArchiveNotesDialog
        id={accountId}
        isOpen={isOpenViewArchiveNoteDialog}
        title={T.ARCHIVED_ACCOUNT_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchAccountNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

AccountNotesAccordionVariant.propTypes = {
  accountId: PropTypes.string,
}

export default AccountNotesAccordionVariant
