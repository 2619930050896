import { useLazyGetAccountGroupDetailsByAgingBucketQuery } from 'api/groups/account/accountsByAgingBucketCrud'
import { useParams } from 'react-router-dom'
import {
  getIsDefaultBucket,
  getIsSuspendedBucket,
  getAccountStatusGraphDataFromStatistics,
  getBillingProfileGraphDataFromStatistics,
} from 'components/customers/groups/account/common/settings'
import { useEffect, useMemo } from 'react'
import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { get } from 'lodash'
import { useLazyGetAccountGroupStatisticsQuery } from 'api/groups/account/getAccountGroupStatistics'
import isEmpty from 'lodash/isEmpty'
import { useLazyGetSuspendedAccountsQuery } from 'api/groups/account/suspendedAccountsCrud'

const getAccountStatusAndBillingProfiles = accounts => {
  const accountStatus = {
    activeCount: 0,
    deactivatedCount: 0,
  }

  const billingProfiles = {}

  accounts.forEach(account => {
    if (account.active) {
      accountStatus.activeCount++
    } else {
      accountStatus.deactivatedCount++
    }

    const { billingProfile } = account
    billingProfiles[billingProfile] = (billingProfiles[billingProfile] || 0) + 1
  })

  return { accountStatus, billingProfiles }
}

export const useGetAccountGroupStatisticsData = (preferCacheValue = false) => {
  const { id } = useParams()
  const [getAccountGroupDetails, accountGroupStatistics] = useLazyGetAccountGroupStatisticsQuery()
  const [getAccountGroupDetailsByBucket, accountGroupDetailsByBucket] = useLazyGetAccountGroupDetailsByAgingBucketQuery()
  const [getSuspendedAccounts, suspendedAccounts] = useLazyGetSuspendedAccountsQuery()

  const fetchData = () => {
    if (!id) return

    if (getIsDefaultBucket(id)) {
      getAccountGroupDetailsByBucket({ aging: get(BUCKET_NAME_TO_GROUP_ID_PARAM, id) }, preferCacheValue)
    } else if (getIsSuspendedBucket(id)) {
      getSuspendedAccounts({ id: 'suspended' }, preferCacheValue)
    } else {
      getAccountGroupDetails({ id }, preferCacheValue)
    }
  }

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])

  const { data, isFetching, isLoading, isSuccess } = useMemo(() => {
    if (!id) return { data: {}, isFetching: false, isLoading: false, isSuccess: false }

    if (getIsSuspendedBucket(id)) {
      const accounts = get(suspendedAccounts.data, 'accounts', [])
      const { accountStatus, billingProfiles } = getAccountStatusAndBillingProfiles(accounts)
      const { topProfiles, cutOffCount } = isEmpty(accountStatus)
        ? { topProfiles: undefined, cutOffCount: 0 }
        : getBillingProfileGraphDataFromStatistics(billingProfiles)

      return {
        ...suspendedAccounts,
        data: {
          accountStatus,
          billingProfiles,
          accountStatusGraphData: isEmpty(accountStatus) ? undefined : getAccountStatusGraphDataFromStatistics(accountStatus),
          billingProfilesGraphData: topProfiles,
          cutOffBillingProfiles: cutOffCount,
        },
      }
    }

    if (getIsDefaultBucket(id)) {
      const accounts = get(accountGroupDetailsByBucket.data, 'accounts', [])
      const { accountStatus, billingProfiles } = getAccountStatusAndBillingProfiles(accounts)
      const { topProfiles, cutOffCount } = isEmpty(accountStatus)
        ? { topProfiles: undefined, cutOffCount: 0 }
        : getBillingProfileGraphDataFromStatistics(billingProfiles)

      return {
        ...accountGroupDetailsByBucket,
        data: {
          accountStatus,
          billingProfiles,
          accountStatusGraphData: isEmpty(accountStatus) ? undefined : getAccountStatusGraphDataFromStatistics(accountStatus),
          billingProfilesGraphData: topProfiles,
          cutOffBillingProfiles: cutOffCount,
        },
      }
    }

    return {
      ...accountGroupStatistics,
      data: accountGroupStatistics.data,
    }
  }, [id, suspendedAccounts, accountGroupDetailsByBucket, accountGroupStatistics])

  return useMemo(
    () => ({
      data,
      isFetching,
      isLoading,
      isSuccess,
    }),
    [data, isFetching, isLoading, isSuccess]
  )
}
