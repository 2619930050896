import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ReactComponent as MoneyCheckDollarPenIcon } from 'assets/MoneyCheckDollarPen.svg'

import { handleError } from 'utils/error'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { CACHE_TAG_WORK_ORDER_DETAILS, CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const RemovePurchaseOrderMenuOption = ({ workOrderId, existingPurchaseOrder = '', onClose }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder, { isLoading }] = useUpdateWorkOrderMutation()
  const [isOpenRemovePurchaseOrderDialog, setIsOpenRemovePurchaseOrderDialog] = useState(false)

  const handleRemovePOClick = () => setIsOpenRemovePurchaseOrderDialog(true)

  const handleRemovePOClose = () => {
    setIsOpenRemovePurchaseOrderDialog(false)
    onClose()
  }

  const handleRemovePO = () => {
    updateWorkOrder({ id: workOrderId, purchaseOrderNumber: '' })
      .unwrap()
      .then(() => {
        onClose()
        setIsOpenRemovePurchaseOrderDialog(false)
        toast.success(T.REMOVE_PURCHASE_ORDER_SUCCESS_MSG)
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS, CACHE_TAG_WORK_ORDER_LIST]))
      })
      .catch(handleError)
  }

  return (
    <>
      {existingPurchaseOrder ? (
        <MenuItem sx={{ color: 'warning.main' }} onClick={handleRemovePOClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <MoneyCheckDollarPenIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.REMOVE_PURCHASE_ORDER}</ListItemText>
        </MenuItem>
      ) : null}
      <HHConfirmDialog
        isOpen={isOpenRemovePurchaseOrderDialog}
        onClose={handleRemovePOClose}
        onConfirm={handleRemovePO}
        confirmTitle={T.REMOVE_PURCHASE_ORDER}
        confirmDescription={T.REMOVE_PURCHASE_ORDER_CONFIRM_MSG}
        confirmButtonTitle={T.REMOVE}
        ConfirmButtonProps={{ loading: isLoading, color: 'warning' }}
      />
    </>
  )
}

RemovePurchaseOrderMenuOption.propTypes = {
  workOrderId: PropTypes.string.isRequired,
  existingPurchaseOrder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default RemovePurchaseOrderMenuOption
