import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getTaxLinkList: build.query({
      query: body => ({ url: '/api/pricing/tax/linked/list', method: 'POST', body }),
    }),
  }),
})

export const { useLazyGetTaxLinkListQuery } = extendedApi