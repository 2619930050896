import uniqBy from 'lodash/uniqBy'
import { CONTACT_METHODS } from 'settings/constants/contacts'
import { get } from 'utils/lodash'

const { EMAIL } = CONTACT_METHODS

export const combinePayments = (payments, contactsByAccount) => {
  const groupPayments = {}
  let paymentsWithoutEmail = 0

  payments.forEach(payment => {
    const accountId = payment?.accountId
    const paymentNumber = payment?.paymentNumber
    if (get(groupPayments, accountId)) {
      groupPayments[accountId].paymentNumbers.push(paymentNumber)
      if (groupPayments[accountId].emails.length === 0) paymentsWithoutEmail += 1
      return
    }

    const contacts = get(contactsByAccount, accountId, [])
    const activeContacts = contacts.filter(({ deactivated }) => !deactivated)
    const allContactMethods = activeContacts.map(({ contactMethods = [] }) => contactMethods).flat()

    const validContacts = allContactMethods.filter(
      ({ billingNotifications, methodValue, methodType }) => methodType === EMAIL && methodValue && billingNotifications
    )

    // API is returning duplicate records
    const uniqueContacts = uniqBy(validContacts, ({ id }) => id)
    const emails = uniqueContacts.map(({ methodValue }) => methodValue)
    groupPayments[accountId] = { ...payment, paymentNumbers: [paymentNumber], emails }

    if (emails.length === 0) paymentsWithoutEmail += 1
  })

  return { groupPayments: Object.values(groupPayments), paymentsWithoutEmail }
}
