export const generalFeeDefaultFilter = {
  requestedPage: 0,
  requestedPageSize: 100,
  searchFilters: [
    {
      columnName: 'periodText',
      columnValue: '1 Instance',
      operation: 'EQUAL_TO',
    },
    {
      columnName: 'isSubItem',
      columnValue: false,
      operation: 'EQUAL_TO',
    },
    {
      columnName: 'active',
      columnValue: true,
      operation: 'EQUAL_TO',
    },
  ],
  sortRules: [],
  tab: 'general',
}
