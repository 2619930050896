import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { INVOICE_STATUS } from 'settings/constants/invoice'
import { WORK_ORDER_STATUS, RECURRENCE } from 'settings/constants/service'
import { INVOICE_STATUS as INVOICE_BILLING_STATUS } from 'settings/constants/billing'

const { ON_REQUEST } = RECURRENCE
const { INVOICED } = INVOICE_STATUS
const { SERVICED } = WORK_ORDER_STATUS
const { PARTIAL } = INVOICE_BILLING_STATUS

export const getServiceDetails = createSelector([state => state.workOrder], workOrder => ({
  name: get(workOrder, 'serviceName', ''),
  recurrence: get(workOrder, 'recurrence', 'Instance'),
  recurrenceFrequency: get(workOrder, 'recurrenceFrequency', '1'),
  pricingPeriod: get(workOrder, 'pricingPeriod', ''),
  price: get(workOrder, 'servicePrice', 0),
  materialType: get(workOrder, 'materialType', ''),
  measure: get(workOrder, 'measure', ''),
  action: get(workOrder, 'serviceAction', ''),
  method: get(workOrder, 'serviceMethod', ''),
  routeName: get(workOrder, 'routeName', ''),
  driverName: `${get(workOrder, 'driver.firstName', '')} ${get(workOrder, 'driver.lastName', '')}`.trim(),
  serviceDate: get(workOrder, 'serviceDate', ''),
}))

export const getWorkOrderData = createSelector([state => state.workOrder], workOrder => ({
  allContainers: get(workOrder, 'containers', []),
  draftFees: get(workOrder, 'draftFees', []),
  invoiceLine: get(workOrder, 'invoiceLine', {}),
  service: { ...getServiceDetails({ workOrder }) },
  workOrderSequence: get(workOrder, 'workOrderSequence', ''),
  workOrderStatus: get(workOrder, 'workOrderStatus', ''),
  invoiceStatus: get(workOrder, 'invoiceStatus', ''),
  allNotes: get(workOrder, 'workOrderNotes', []),
  allExceptions: get(workOrder, 'workOrderExceptions', []),
}))

export const getInvoiceLineData = createSelector([state => state.invoiceLine], invoiceLine => ({
  invoiceLineId: get(invoiceLine, 'id', ''),
  invoiceNumber: get(invoiceLine, 'invoiceNumber', ''),
  invoiceLineItemId: get(invoiceLine, 'invoiceLineItem[0].id', ''),
  invoiceStatus: get(invoiceLine, 'invoiceStatus', ''),
}))

export const canChangeWOInvoiceStatusSelector = createSelector(
  [state => state?.serviceType, state => state?.invoiceStatus],
  (serviceType, invoiceStatus) => {
    if (!serviceType || !invoiceStatus) {
      return false
    }
    return serviceType === ON_REQUEST && invoiceStatus !== INVOICED
  }
)

export const canChangeWorkOrderStatusSelector = createSelector(
  [state => state?.workOrderStatus, state => state?.invoiceStatus],
  (workOrderStatus, invoiceStatus) => {
    if (workOrderStatus === SERVICED) {
      return !invoiceStatus || invoiceStatus === PARTIAL
    }

    return true
  }
)
