import api from '../index'
import { CACHE_TAG_PORTAL_USER_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getPortalUsers: builder.query({
      providesTags: [CACHE_TAG_PORTAL_USER_LIST],
      query: ({ accountId }) => ({ url: `/api/v1/core/accounts/${accountId}/portal-users` }),
    }),
  }),
})

export const { useLazyGetPortalUsersQuery } = extendedApi
