import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardActions, CardContent, Typography, Divider, styled, useTheme, Box } from '@mui/material'

const StyledCard = styled(Card)({
  boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  // initial/inherit - for charts tooltip
  overflow: 'initial',
})

const StyledCardHeader = styled(CardHeader)({
  pl: 1.5,
  pr: 1,
  py: 1,
  '& .MuiCardHeader-avatar': { mr: 1 },
  '& .MuiCardHeader-content': { width: '75%' },
})

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  px: 2,
  py: 1.5,
  justifyContent: 'space-between',
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
}))

const GraphCard = ({ Icon, title = '', createdBy = '', createdAt = '', time = '', date = '', children, ...rest }) => {
  const theme = useTheme()
  return (
    <StyledCard variant="outlined" {...rest}>
      <StyledCardHeader
        titleTypographyProps={{ variant: 'h5', fontWeight: 500 }}
        {...(Icon && { avatar: <Icon width={24} height={24} style={{ color: theme.palette.action.active }} /> })}
        title={title}
      />
      <Divider />
      <CardContent>{children}</CardContent>
      <StyledCardActions>
        <Typography variant="caption">{`Created by ${createdBy}`}</Typography>
        {createdAt && <Typography variant="caption">{`${time} | ${date}`}</Typography>}
      </StyledCardActions>
    </StyledCard>
  )
}

GraphCard.propTypes = {
  Icon: PropTypes.element,
  title: PropTypes.string,
  children: PropTypes.node,
  createdBy: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  createdAt: PropTypes.string,
}

export default GraphCard
