import api from 'api'
import { CACHE_TAG_ACCOUNT_META } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountMeta: builder.query({
      query: () => ({ url: '/api/meta/account', method: 'POST' }),
      providesTags: [CACHE_TAG_ACCOUNT_META],
    }),
  }),
})

export const { useGetAccountMetaQuery, useLazyGetAccountMetaQuery } = extendedApi

export const getAccountMetaSlice = extendedApi.endpoints.getAccountMeta.select()
