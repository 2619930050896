import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAllAccountNotesQuery, useCreateAccountNoteMutation, useUpdateAccountNoteMutation } from 'api/notes/accountNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import AccountNotes from 'components/notes/account/AccountNotes'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import NoteSkeleton from 'components/notes/common/NoteSkeleton'

const AccountNotesDialogVariant = ({ isOpen = false, accountId = '', getCachedData = false, onClose }) => {
  const [getAllAccountNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllAccountNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddAccountNote = () => {
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleCloseAddAccountNote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (isOpen && accountId) {
      getAllAccountNotes({ id: accountId }, getCachedData).unwrap().catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={`${T.ACCOUNT_NOTE}s`} onClose={onClose} />
        <DialogContent sx={{ p: 0, pl: 1 }}>
          {isGetAllNotesLoading && <NoteSkeleton rows={1} />}
          {!isGetAllNotesLoading && (
            <AccountNotes
              itemSx={{
                [`.MuiListItemSecondaryAction-root`]: {
                  right: 12,
                },
              }}
              notes={notes}
              accountId={accountId}
              noNotesText={T.NO_ACCOUNT_NOTES}
            />
          )}
        </DialogContent>
        <HHDialogActions>
          <Button variant="outlined" onClick={handleOpenAddAccountNote}>
            {T.CREATE_NOTE}
          </Button>
          <Button variant="contained" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_ACCOUNT_NOTE}
        useCreateNoteMutation={useCreateAccountNoteMutation}
        useUpdateNoteMutation={useUpdateAccountNoteMutation}
        onClose={handleCloseAddAccountNote}
      />
    </>
  )
}

AccountNotesDialogVariant.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string,
  getCachedData: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default AccountNotesDialogVariant
