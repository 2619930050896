import React from 'react'

import Home from 'containers/home'

const HomePage = () => (
  <section className="common-padding">
    <Home />
  </section>
)

export default HomePage
