import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { BUSINESS_LINE_TYPE_UPPERCASE } from 'settings/constants/businessLine'
import { NONE_BUSINESS_LINE_OPTION } from 'components/pricing/RentalFees/common/settings'

const { RESIDENTIAL, COMMERCIAL, ROLL_OFF } = BUSINESS_LINE_TYPE_UPPERCASE
const ALL_BUSINESS_LINES = [RESIDENTIAL, COMMERCIAL, ROLL_OFF]

export const getActiveBusinessLines = createSelector(
  state => state?.businessLines,
  (businessLines = []) => businessLines.filter(({ active }) => active)
)

export const getBusinessLineDropdownOptions = createSelector(
  [state => state?.businessLines, state => state?.includeNoneOption],
  (businessLines = [], includeNoneOption = true) => {
    const formattedBusinessLineOptions = businessLines
      .filter(({ active }) => active)
      .map(({ id: businessLineId, businessLineLabel }) => ({ label: businessLineLabel, value: businessLineId }))

    return includeNoneOption ? [{ ...NONE_BUSINESS_LINE_OPTION }, ...formattedBusinessLineOptions] : formattedBusinessLineOptions
  }
)

export const getActiveBusinessLinesToCategories = createSelector([state => state?.businessLines], (businessLines = []) => {
  const hashMap = {}
  businessLines.forEach(line => {
    if (line.active) {
      const category = get(line, 'businessLineIcon', '').replace('.svg', '').toUpperCase()
      if (ALL_BUSINESS_LINES.includes(category)) {
        hashMap[line.id] = category
      }
    }
  })
  return hashMap
})
