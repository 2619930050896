import React from 'react'
import PropTypes from 'prop-types'
import { grey, red, amber, blue, deepPurple, green } from '@mui/material/colors'
import { Check, Close, FastForwardOutlined, PlayCircleOutlineOutlined, CalendarTodayOutlined } from '@mui/icons-material'
import { ReactComponent as HoldIcon } from 'assets/work-orders/HoldIcon.svg'
import { Avatar, SvgIcon, Tooltip, Box } from '@mui/material'
import { WORK_ORDER_STATUS } from '../../../settings/constants/service'

const { SCHEDULED, IN_PROCESS, IN_PROGRESS, CANCELED, COMPLETE, SKIPPED, HOLD, SERVICED } = WORK_ORDER_STATUS
const WorkOrderStatusAvatar = ({ status }) => {
  const isScheduled = status === SCHEDULED
  const isCompleted = status === COMPLETE
  const isCanceled = status === CANCELED
  const isSkipped = status === SKIPPED
  const isServiced = status === SERVICED
  const isInProcess = status === IN_PROCESS
  const isInProgress = status === IN_PROGRESS
  const isOnHold = status === HOLD
  const iconSx = { fontSize: '.9rem', color: 'common.white' }
  return (
    <Tooltip title={status}>
      <Box>
        <Avatar
          sx={{
            width: 20,
            height: 20,
            fontSize: '1rem',
            ...(isScheduled && { bgcolor: grey[500] }),
            ...(isCanceled && { bgcolor: red[500] }),
            ...(isSkipped && { bgcolor: amber[500] }),
            ...((isInProcess || isInProgress) && { bgcolor: blue[500] }),
            ...(isOnHold && { bgcolor: deepPurple[500] }),
            ...(isCompleted || (isServiced && { bgcolor: green[500] })),
          }}
        >
          {isCanceled && <Close sx={iconSx} />}
          {(isServiced || isCompleted) && <Check sx={iconSx} />}
          {isSkipped && <FastForwardOutlined sx={iconSx} />}
          {(isInProcess || isInProgress) && <PlayCircleOutlineOutlined sx={iconSx} />}
          {isOnHold && (
            <SvgIcon inheritViewBox viewBox="0 0 12 10" sx={iconSx}>
              <HoldIcon />
            </SvgIcon>
          )}
          {isScheduled && <CalendarTodayOutlined sx={iconSx} />}
        </Avatar>
      </Box>
    </Tooltip>
  )
}

WorkOrderStatusAvatar.propTypes = {
  status: PropTypes.string.isRequired,
}

export default WorkOrderStatusAvatar
