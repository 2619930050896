import React from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { Menu } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { get } from 'utils/lodash'

import RouteFilterMenuOptions from './RouteFilterMenuOptions'

const RouteFilterByTags = ({ allTags = [], anchorEl, tagId, filteredRoutes = [], onMouseLeave }) => {
  const { watch, setValue } = useFormContext()
  const selectedTags = watch('selectedTags')
  const selectedRoutesByTagId = watch('selectedRoutesByTagId')
  const filteredRouteIds = filteredRoutes.map(({ id }) => id)
  const selectedRouteIds = get(selectedRoutesByTagId, tagId, [])
  const allRoutesSelected = selectedRouteIds.length === filteredRoutes.length && filteredRoutes.length > 0

  const handleChange = (routeIds = []) => {
    setValue(`selectedRoutesByTagId.${tagId}`, routeIds)
    setValue(
      'selectedTags',
      routeIds.length === 0
        ? selectedTags.filter(tag => tag.id !== tagId)
        : uniqBy([...selectedTags, allTags.find(tag => tag.id === tagId)], 'id')
    )
  }

  const handleSelectRouteChange = (event, routeId) => {
    event.stopPropagation()
    if (selectedRouteIds.includes(routeId)) {
      handleChange(selectedRouteIds.filter(f => f !== routeId))
    } else {
      handleChange([...selectedRouteIds, routeId])
    }
  }

  const handleSelectAllFilters = event => {
    event.stopPropagation()
    if (allRoutesSelected) {
      handleChange([])
    } else {
      handleChange([...filteredRouteIds])
    }
  }

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={{ top: anchorEl?.top, left: anchorEl?.left + 0.25 }}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      MenuListProps={{ onMouseLeave }}
    >
      <RouteFilterMenuOptions
        allRoutes={filteredRoutes}
        selectedRouteIds={selectedRouteIds}
        onSelectRouteChange={handleSelectRouteChange}
        onSelectAllFilters={handleSelectAllFilters}
      />
    </Menu>
  )
}

RouteFilterByTags.propTypes = {
  allTags: PropTypes.array,
  anchorEl: PropTypes.object,
  tagId: PropTypes.string,
  filteredRoutes: PropTypes.array,
  onMouseLeave: PropTypes.func,
}

export default RouteFilterByTags
