import React, { useMemo } from 'react'

import Pricing from 'containers/pricing'
import NavbarWrapper from 'components/common/NavbarWrapper'
import PricingDrawer from 'components/pricing/common/drawer/PricingDrawer'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import { useSelector } from 'react-redux'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { useMatch } from 'react-router-dom-v5-compat'
import routes from 'router/routes'
import { get } from 'lodash'
import { PRICING_PARAM_TO_TAB_ID } from 'settings/constants/pricing'

const PricingPage = () => {
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const match = useMatch(`${routes.app.pricing}/:pricingParam`)
  const pricingParam = useMemo(() => get(match, 'params.pricingParam'), [match])
  const pricingTabId = useMemo(() => PRICING_PARAM_TO_TAB_ID[pricingParam], [pricingParam])

  return (
    <NavbarWrapper
      sx={{ backgroundColor: 'background.paper' }}
      innerSx={{
        overflow: 'hidden',
      }}
    >
      <PricingDrawer />
      <CustomerLayout overflow="hidden" open={isOpenPricingDrawer}>
        <Pricing defaultTabValue={pricingTabId} />
      </CustomerLayout>
    </NavbarWrapper>
  )
}

export default PricingPage
