import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'
import { getPricingPeriod } from 'utils/service'
import { getMeasureUnit } from 'utils/get-measure-unit'
import { setCsNoteDialog } from 'slices/locations/locationsSlice'
import { Card, CardContent, Chip, Grid, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'
import MeasureChip from 'containers/new-route-manager/RouteCard/MeasureChip'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import Badge from '@mui/material/Badge'
import { ReactComponent as ServiceNoteIcon } from 'assets/note/ServiceNote.svg'
import { useDispatch } from 'react-redux'
import { RECURRENCE } from 'settings/constants/service'

export const getServiceRecurrenceText = service => {
  const serviceType = get(service, 'serviceType', '').toUpperCase()
  const isRecurring = serviceType === RECURRENCE.RECURRING.toUpperCase()
  const recurrenceFrequency = get(service, 'recurrenceFrequency', 1)
  const recurrencePer = get(service, 'recurrencePer', '')
  const recurrenceInterval = get(service, 'recurrenceInterval', '')
  const pricingPeriod = getPricingPeriod(recurrencePer, recurrenceInterval)
  return isRecurring ? `${pricingPeriod} | ${recurrenceFrequency}x ` : 'On Request'
}

const ConfiguredServiceCard = ({ service }) => {
  const dispatch = useDispatch()
  const name = get(service, 'name')
  const price = get(service, 'price')
  const startDateStr = get(service, 'startDate')
  const startDate = createDateFromString(startDateStr)
  const formattedStartDate = formatDateToFEFormatDateFns(startDate)
  const measureUnit = get(service, 'measureUnit')
  const measureVolume = get(service, 'measureVolume', 0)
  const measureLabel = `${measureVolume} ${getMeasureUnit(measureUnit)}`
  const recurrenceLabel = useMemo(() => getServiceRecurrenceText(service), [service])

  const notesCount = get(service, 'notesCount', 0)
  const openCSNoteDialog = () => {
    dispatch(
      setCsNoteDialog({
        open: true,
        selectedService: service,
      })
    )
  }
  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <CardContent
          sx={{
            px: 2,
            py: 1,
            '&:last-child': {
              pb: 1,
            },
          }}
        >
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Grid container item xs alignItems="center" rowSpacing={0.5}>
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600}>
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={1}>
                  <Chip
                    avatar={
                      <SvgIcon>
                        <Calendar />
                      </SvgIcon>
                    }
                    size="small"
                    label={formattedStartDate}
                  />
                  <MeasureChip label={measureLabel.trim() ? measureLabel : 'No measurement'} measureUnit={measureUnit} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">{recurrenceLabel}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs="auto" alignItems="flex-end">
              <Grid item xs={12}>
                <Typography variant="h4" fontWeight={600} textAlign="right">
                  <HHDisplayMoney value={price} formatToDollars={false} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                  <IconButton size="small" onClick={openCSNoteDialog}>
                    <Badge badgeContent={notesCount} color="primary">
                      <ServiceNoteIcon />
                    </Badge>
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

ConfiguredServiceCard.propTypes = {
  service: PropTypes.object,
}

export default ConfiguredServiceCard
