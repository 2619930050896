import api from 'api'

export const extendApiSlice = api.injectEndpoints({
  endpoints: build => ({
    updateAccount: build.mutation({
      query: body => ({ url: `/api/v1/core/accounts/${body.id}`, method: 'PUT', body }),
    }),
  }),
})

export const { useUpdateAccountMutation } = extendApiSlice
