import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import {
  Table as TableView,
  TableRow,
  TableCell,
  TableBody as TBody,
  TableHead as THead,
  Typography,
  Card,
  Box,
  Button,
} from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { Add, Cancel } from '@mui/icons-material'
import { GENERAL_LEDGER_HEADER_VALUES } from 'settings/constants/generalLedgerSettings'
import FooterButton from 'components/buttons/FooterButton'
import { canAddSettings } from 'data/permissions/permissionsSelectors'
import { getLedgerData } from 'data/settings/generalLedgerSelectors'
import T from 'T'
import Loader from 'components/common/loader'
import TableLayout from '../../../layout/TableLayout'

const GeneralLedgerTable = ({
  isLoading = false,
  records = [],
  onPlaceLedgerEdit = [],
  footerFlag = false,
  onHandleChange = () => {},
  setNewFields = () => {},
  handleSaveOrUpdate = () => {},
  handleLedgerCancel = () => {},
  handleOnPlaceEdit = () => {},
  handleCancel = () => {},
}) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)

  const addRows = () => {
    if (records.filter(rec => rec.id === '').length > 0) return
    records = getLedgerData(records)
    records.push({
      id: '',
      accountName: '',
      accountNumber: '',
    })

    setNewFields(records)
  }

  return (
    <Card sx={{ mt: 2 }}>
      {isLoading && <Loader />}
      <TableLayout height="calc(100vh - 176px)">
        <TableView stickyHeader aria-label="sticky table">
          <THead>
            <TableRow>
              {Children.toArray(
                GENERAL_LEDGER_HEADER_VALUES.map(val => {
                  return (
                    <TableCell sx={{ p: '25px 16px 10px 16px', backgroundColor: 'background.paper' }} align={val.align}>
                      <Typography variant="subtitle2">{val.name}</Typography>
                    </TableCell>
                  )
                })
              )}
            </TableRow>
          </THead>

          <TBody>
            {Children.toArray(
              records &&
                records.map((val, index) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {(!onPlaceLedgerEdit[index] || (onPlaceLedgerEdit[index] && !onPlaceLedgerEdit[index].canEditAccountName)) && (
                          <Typography variant="body1" onClick={() => handleOnPlaceEdit(index, 'accountName')}>
                            {val.accountName}
                          </Typography>
                        )}

                        {onPlaceLedgerEdit[index] &&
                          onPlaceLedgerEdit[index].canEditAccountName &&
                          Object.keys(onPlaceLedgerEdit).length > 0 && (
                            <Box display="flex" alignItems="center">
                              {val.id === '' && (
                                <Cancel
                                  onClick={() => handleLedgerCancel(index)}
                                  fontSize="small"
                                  sx={{ mr: 1, pt: 0.2, alignItems: 'center', color: 'text.secondary', cursor: 'pointer' }}
                                />
                              )}

                              <HHTextField
                                value={val.accountName}
                                variant="outlined"
                                autoComplete="off"
                                name="accountName"
                                InputProps={{
                                  onChange: e => onHandleChange(e, index),
                                }}
                                sx={{
                                  width: 278,
                                }}
                              />
                            </Box>
                          )}
                      </TableCell>

                      <TableCell align="right">
                        {(!onPlaceLedgerEdit[index] || (onPlaceLedgerEdit[index] && !onPlaceLedgerEdit[index].canEditAccountNumber)) && (
                          <Typography variant="body1" onClick={() => handleOnPlaceEdit(index, 'accountNumber')}>
                            {val.accountNumber}
                          </Typography>
                        )}

                        {onPlaceLedgerEdit[index] &&
                          onPlaceLedgerEdit[index].canEditAccountNumber &&
                          Object.keys(onPlaceLedgerEdit).length > 0 && (
                            <HHTextField
                              value={val.accountNumber}
                              variant="outlined"
                              autoComplete="off"
                              name="accountNumber"
                              InputProps={{
                                onChange: e => onHandleChange(e, index),
                              }}
                              sx={{
                                width: 278,
                              }}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  )
                })
            )}

            {addSettings && (
              <TableCell colSpan="2">
                <Button onClick={() => addRows()} variant="text" color="primary" startIcon={<Add />}>
                  {T.ADD_ACCOUNT}
                </Button>
              </TableCell>
            )}
          </TBody>
        </TableView>
      </TableLayout>

      {footerFlag && (
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'border.light',
            boxShadow: ' 0 1px 3px rgba(35,38,59,.15)',
            display: 'flex',
            justifyContent: 'space-between',
            paddingX: 3,
            paddingY: 1,
          }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={() => {
              handleCancel()
            }}
            rightButtonTitle={T.SAVE}
            onProceed={handleSaveOrUpdate}
          />
        </Box>
      )}
    </Card>
  )
}

GeneralLedgerTable.propTypes = {
  isLoading: PropTypes.bool,
  records: PropTypes.array,
  onPlaceLedgerEdit: PropTypes.array,
  footerFlag: PropTypes.bool,
  onHandleChange: PropTypes.func,
  setNewFields: PropTypes.func,
  handleSaveOrUpdate: PropTypes.func,
  handleLedgerCancel: PropTypes.func,
  handleOnPlaceEdit: PropTypes.func,
  handleCancel: PropTypes.func,
}

export default GeneralLedgerTable
