import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import FormLabel from '@mui/material/FormLabel'
import { TEXT_SECONDARY, SUCCESS } from 'theme/colors'
import { Typography } from '@mui/material'
import noop from 'lodash/noop'

const CommonSwitch = ({
  title = '',
  titlePosition = 'right',
  isChecked = false,
  offColor = TEXT_SECONDARY.main,
  onColor = SUCCESS.main,
  onChange = noop,
  ...rest
}) => (
  <FormLabel sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
    {title && titlePosition === 'left' && (
      <Typography variant="body1" mr={1}>
        {title}
      </Typography>
    )}
    <Switch
      offColor={offColor}
      onColor={onColor}
      width={32}
      height={16}
      handleDiameter={10}
      checked={isChecked}
      onChange={onChange}
      {...rest}
    />
    {title && titlePosition === 'right' && (
      <Typography variant="body1" ml={1}>
        {title}
      </Typography>
    )}
  </FormLabel>
)

CommonSwitch.propTypes = {
  title: PropTypes.string,
  titlePosition: PropTypes.string,
  isChecked: PropTypes.bool,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CommonSwitch
