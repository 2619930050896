import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'

import HHSortKeys from 'components/common/HHSortKeys'
import HHTableFilter from 'components/common/HHTableFilter'
import { TableRow, TableHead as THead, TableCell, Checkbox, Popover, Stack } from '@mui/material'
import { Cog } from '@styled-icons/heroicons-solid/Cog'

import { memo } from 'utils/react'
import { get, capitalize } from 'lodash'
import { isValidFilter } from 'utils/table'
import { handleCheckBoxClass } from 'components/pricing/pricingMethods'

import Constants from 'Constants'

const { MEDIA_SERVER } = Constants

const TableHeader = ({
  handleChange,
  confServices,
  allCheckedVal,
  serviceList,
  saveConfSettings,
  type,
  checkedVal,
  columns,
  lockedColumns,
  allFilters,
  allSortBy,
  onTableFilterChange,
  onSortByChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [lastPriceSettings, setLastPriceSettings] = useState(false)
  const [nextPriceSettings, setNextPriceSettings] = useState(false)
  const [settingsSelection, setsettingsSelection] = useState('')

  const handleSettingsClick = (event, columnTitle) => {
    setAnchorEl(event.currentTarget)
    if (columnTitle === 'Last Value Update') {
      setLastPriceSettings(!lastPriceSettings)
    } else {
      setNextPriceSettings(!nextPriceSettings)
    }
  }

  const handleClose = columnTitle => {
    setAnchorEl(null)

    if (columnTitle === 'Last Value Update') {
      setLastPriceSettings(false)
    } else {
      setNextPriceSettings(false)
    }
  }

  const handleSettingsSelect = selection => {
    setsettingsSelection(selection)
  }

  // Later move this to a common component
  const tableCellContent = (column, labelName, columnName, filterType, subType) => {
    return (
      <Stack flexDirection="row" alignItems="center" className="wrapper wrapper-filter-sort">
        <HHTableFilter
          isActive={isValidFilter(allFilters, columnName)}
          onClick={event =>
            onTableFilterChange(
              event.currentTarget,
              filterType,
              columnName,
              subType,
              labelName,
              get(column, 'isSingleSelect', false),
              get(column, 'isBoolean', false)
            )
          }
        />
        <span>{capitalize(labelName)}</span>

        <HHSortKeys
          isAscendingOrderActive={get(allSortBy, `${columnName}.operation`) === 'ASC'}
          isDescendingOrderActive={get(allSortBy, `${columnName}.operation`) === 'DESC'}
          columnName={columnName}
          handleChange={onSortByChange}
        />
      </Stack>
    )
  }

  const handleHeaderClass = (ind, isLocked) => {
    if (lockedColumns.length === ind + 1) {
      return 'sticky-col add-shadow'
    }

    if (isLocked) {
      return 'sticky-col'
    }

    return ''
  }

  return (
    <THead className="table-header">
      <TableRow>
        <TableCell padding="checkbox" className={handleCheckBoxClass(saveConfSettings)}>
          <Checkbox checked={allCheckedVal[type]} onChange={event => handleChange(serviceList, event, type)} name="checkedHead" />
        </TableCell>

        {Children.toArray(
          columns.map((column, index) => {
            const isLocked = get(column, 'locked', false)
            const columnName = get(column, 'columnName', '')
            const filterType = get(column, 'filterType', '')
            const subType = get(column, 'subType', '')
            return (
              <>
                {['Last Value Update', 'Next Value Update'].includes(column.label) && (
                  <>
                    <TableCell
                      className={`next-last-update ${handleHeaderClass(index, isLocked)} ${column.tableLabel ? 'filled-th' : 'empty-th'}`}
                    >
                      <div className="cog" onClick={event => handleSettingsClick(event, column.label)}>
                        <Cog className="table-settings-icon" />
                      </div>

                      <div>{column.label === 'Last Value Update' ? 'Last Update' : 'Next Update'}</div>
                    </TableCell>

                    <TableCell
                      className={`next-last-value-update ${handleHeaderClass(index, isLocked)} ${
                        column.tableLabel ? 'filled-th' : 'empty-th'
                      }`}
                    >
                      <div>Value</div>
                    </TableCell>

                    <Popover
                      open={column.label === 'Last Value Update' ? lastPriceSettings : nextPriceSettings}
                      anchorEl={anchorEl}
                      onClose={() => handleClose(column.label)}
                      className="next-last-price-settings"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div className="settings">
                        <div
                          className={`percent-change ${settingsSelection === 'percent-change' && 'active-settings'}`}
                          onClick={() => handleSettingsSelect('percent-change')}
                        >
                          % Change
                        </div>
                        <div
                          className={`dollar-change ${settingsSelection === 'dollar-change' && 'active-settings'}`}
                          onClick={() => handleSettingsSelect('dollar-change')}
                        >
                          $ Change
                        </div>
                        <div
                          className={`dollar-specific ${settingsSelection === 'dollar-specific' && 'active-settings'}`}
                          onClick={() => handleSettingsSelect('dollar-specific')}
                        >
                          $ Specific
                        </div>
                      </div>
                    </Popover>
                  </>
                )}

                {column.label === 'Overage Fee' && (
                  <>
                    <TableCell
                      className={`next-last-update ${handleHeaderClass(index, isLocked)} ${column.tableLabel ? 'filled-th' : 'empty-th'}`}
                    >
                      {tableCellContent(column, 'Overage', 'overageThreshold', filterType, '')}
                    </TableCell>

                    <TableCell
                      className={`next-last-value-update ${handleHeaderClass(index, isLocked)} ${
                        column.tableLabel ? 'filled-th' : 'empty-th'
                      }`}
                    >
                      {tableCellContent(column, 'Overage Fee', 'overageFee', filterType, subType)}
                    </TableCell>
                  </>
                )}

                {!['Last Value Update', 'Next Value Update', 'Overage Fee'].includes(column.label) && (
                  <TableCell
                    className={`${column.label === 'Tiers' ? 'tiers-column' : ''} ${handleHeaderClass(index, isLocked)} ${
                      column.tableLabel ? 'filled-th' : 'empty-th'
                    }`}
                  >
                    {tableCellContent(column, column.label, columnName, filterType, subType)}
                  </TableCell>
                )}
              </>
            )
          })
        )}

        <TableCell padding="checkbox" className="three-dots" />
      </TableRow>
    </THead>
  )
}

TableHeader.defaultProps = {
  type: '',
  confServices: [],
  serviceList: [],
  columns: [],
  lockedColumns: [],
  checkedVal: {},
  allCheckedVal: {},
  saveConfSettings: false,
  allFilters: {},
  allSortBy: {},
  handleChange: null,
}

TableHeader.propTypes = {
  type: PropTypes.string,
  confServices: PropTypes.array,
  serviceList: PropTypes.array,
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  checkedVal: PropTypes.object,
  allCheckedVal: PropTypes.object,
  saveConfSettings: PropTypes.bool,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  handleChange: PropTypes.func,
  onTableFilterChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
}

export default memo(TableHeader)
