import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  canSeeDashboardTab,
  canSeeCustomersTab,
  canSeeRouteTab,
  canSeeBillingTab,
  canSeeReportTab,
  canSeePricingTab,
  canSeeSettingsTab,
} from 'data/permissions/permissionsSelectors'
import {
  isNotificationFeatureAvailable,
  isBillingWOInvoiceStatusFeatureAvailable,
  isLateFeeFeatureAvailable,
  isRentalFeeFeatureAvailable,
} from 'data/launch-darkly/featureSelectors'

import {
  LoginPage,
  SelectAccountPage,
  HomePage,
  ErrorPage,
  NewRoutesPage,
  PricingPage,
  ReportsPage,
  ReportsDetailPage,
  FollowUpPage,
  DashboardPage,
  AccountsPage,
  LocationsPage,
  SettingsPage,
  HelpPage,
  NotificationsPage,
  ExportWorkOrderListPage,
  ExportWorkOrderPage,
  InvoicesPage,
  CreateInvoicesPage,
  PaymentsPage,
  AccountsReceivablePage,
  WODetailsPage,
  InvoiceGroupsPage,
  InvoiceGroupsDetailsPage,
  WorkOrdersReviewPage,
  PaymentGroupsPage,
  PaymentGroupsDetailsPage,
  AccountGroupsPage,
  AccountGroupsDetailsPage,
  SuspensionGroupsPage,
  ServiceGroupsPage,
  LateFeesPage,
  LateFeeDetailsPage,
  RentalFeesPage,
  RentalFeeDetailsPage,
} from 'pages'

import CustomerDetails from 'containers/customer-details'

import routes from 'router/routes'
import { useAuthValue } from 'auth/AuthContext'
import { handleFirebaseLogout } from 'providers/firebaseLogout'
import { CompatRoute } from 'react-router-dom-v5-compat'
import RedirectToValidCustomerDetailsUrlHelper, { singularCustomerRoutes } from 'router/RedirectToValidCustomerDetailsUrlHelper'
import { GENERAL_VIEW_MODE, RECENTLY_ADDED_VIEW_MODE, RECENTLY_VIEWED_VIEW_MODE } from 'components/customers/accounts/settings'
import { selectHomePage } from 'slices/home/homeSlice'
import { selectPricingFavoriteUrl, setFavoritePricingUrl } from 'slices/pricing/pricingDrawerSlice'
import ProtectedRoute from './ProtectedRoute'

const MainRoute = () => {
  const location = useLocation()
  const homePage = useSelector(selectHomePage, shallowEqual)
  const pricingFavoriteUrl = useSelector(selectPricingFavoriteUrl, shallowEqual)
  const { currentUser } = useAuthValue() || {}
  const flags = useFlags()
  const isLateFeeEnabled = isLateFeeFeatureAvailable({ flags })
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const dispatch = useDispatch()
  useEffect(() => {
    if (
      (!isLateFeeEnabled || !isRentalFeeEnabled) &&
      (pricingFavoriteUrl === routes.app.pricingLateFees || pricingFavoriteUrl === routes.app.pricingRentalFees)
    ) {
      dispatch(setFavoritePricingUrl(routes.app.pricingServices))
    }
  }, [pricingFavoriteUrl, isLateFeeEnabled, isRentalFeeEnabled])

  return (
    <Switch>
      <CompatRoute path={routes.app.login} component={LoginPage} />
      <ProtectedRoute exact component={() => handleFirebaseLogout()} path={routes.app.logout} />
      <ProtectedRoute exact component={SelectAccountPage} path={routes.app.selectAccount} />
      <ProtectedRoute exact component={HomePage} path={routes.app.home} />

      {canSeeDashboardTab() && <ProtectedRoute exact component={DashboardPage} path={routes.app.dashboard} />}

      {/* Redirect old url to new url */}
      {canSeeCustomersTab() && <Redirect exact from={routes.app.customers} to={homePage} />}
      {canSeeCustomersTab() && (
        <ProtectedRoute path={routes.app.accounts} component={() => <AccountsPage viewMode={GENERAL_VIEW_MODE} />} />
      )}
      {canSeeCustomersTab() && (
        <ProtectedRoute path={routes.app.recentlyAdded} component={() => <AccountsPage viewMode={RECENTLY_ADDED_VIEW_MODE} />} />
      )}
      {canSeeCustomersTab() && (
        <ProtectedRoute path={routes.app.recentlyViewed} component={() => <AccountsPage viewMode={RECENTLY_VIEWED_VIEW_MODE} />} />
      )}
      {/* Redirect singular url to standard url */}
      {canSeeCustomersTab() && (
        <ProtectedRoute exact path={singularCustomerRoutes.customers} component={RedirectToValidCustomerDetailsUrlHelper} />
      )}
      {canSeeCustomersTab() && (
        <ProtectedRoute exact path={singularCustomerRoutes.customerDetails} component={RedirectToValidCustomerDetailsUrlHelper} />
      )}
      {canSeeCustomersTab() && (
        <ProtectedRoute exact path={singularCustomerRoutes.accounts} component={RedirectToValidCustomerDetailsUrlHelper} />
      )}
      {canSeeCustomersTab() && (
        <ProtectedRoute path={singularCustomerRoutes.customerDetailsVariant} component={RedirectToValidCustomerDetailsUrlHelper} />
      )}
      {canSeeCustomersTab() && <ProtectedRoute exact path={routes.app.locations} component={LocationsPage} />}
      {canSeeCustomersTab() && <ProtectedRoute exact path={routes.app.suspensionGroups} component={SuspensionGroupsPage} />}
      {canSeeCustomersTab() && <ProtectedRoute exact path={`${routes.app.suspensionGroups}/:id`} component={AccountGroupsDetailsPage} />}
      {canSeeCustomersTab() && <ProtectedRoute exact path={routes.app.accountGroups} component={AccountGroupsPage} />}
      {canSeeCustomersTab() && <ProtectedRoute exact path={`${routes.app.accountGroups}/:id`} component={AccountGroupsDetailsPage} />}

      {canSeeCustomersTab() && <ProtectedRoute exact component={CustomerDetails} path={routes.app.customerDetails} />}

      {canSeeCustomersTab() && <ProtectedRoute exact component={WODetailsPage} path={routes.app.workOrderDetails} />}

      <ProtectedRoute component={ExportWorkOrderListPage} path={routes.app.exportRouteWorkOrders} />
      <ProtectedRoute component={ExportWorkOrderPage} path={routes.app.exportRouteWorkOrder} />

      {canSeeRouteTab() && <ProtectedRoute exact component={NewRoutesPage} path={routes.app.routes} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={CreateInvoicesPage} path={routes.app.createInvoices} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={PaymentsPage} path={routes.app.payments} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={InvoicesPage} path={routes.app.invoices} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={AccountsReceivablePage} path={routes.app.accountsReceivable} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={InvoiceGroupsPage} path={routes.app.invoiceGroups} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={InvoiceGroupsDetailsPage} path={`${routes.app.invoiceGroups}/:id`} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={PaymentGroupsPage} path={routes.app.paymentGroups} />}
      {canSeeBillingTab() && <ProtectedRoute exact component={PaymentGroupsDetailsPage} path={`${routes.app.paymentGroups}/:id`} />}
      {canSeeBillingTab() && isBillingWOInvoiceStatusFeatureAvailable({ flags }) && (
        <ProtectedRoute exact component={WorkOrdersReviewPage} path={routes.app.workOrdersReview} />
      )}

      <ProtectedRoute exact component={FollowUpPage} path={routes.app.followUp} />

      {canSeeReportTab() && <ProtectedRoute exact component={ReportsPage} path={routes.app.reports} />}
      {canSeeReportTab() && <ProtectedRoute exact component={ReportsDetailPage} path={routes.app.reportDetails} />}
      {canSeePricingTab() && <Redirect exact from={routes.app.pricing} to={pricingFavoriteUrl} />}

      {canSeePricingTab() && <ProtectedRoute exact component={PricingPage} path={routes.app.pricingServices} />}
      {canSeePricingTab() && <ProtectedRoute exact component={PricingPage} path={routes.app.pricingFees} />}
      {canSeePricingTab() && <ProtectedRoute exact component={PricingPage} path={routes.app.pricingTaxes} />}
      {canSeePricingTab() && <ProtectedRoute exact component={ServiceGroupsPage} path={routes.app.pricingServiceGroups} />}
      {canSeePricingTab() && isLateFeeEnabled && <ProtectedRoute exact component={LateFeesPage} path={routes.app.pricingLateFees} />}
      {canSeePricingTab() && <ProtectedRoute exact component={LateFeeDetailsPage} path={routes.app.pricingLateFeeDetails} />}
      {canSeePricingTab() && isRentalFeeEnabled && <ProtectedRoute exact component={RentalFeesPage} path={routes.app.pricingRentalFees} />}
      {canSeePricingTab() && <ProtectedRoute exact component={RentalFeeDetailsPage} path={routes.app.pricingRentalFeeDetails} />}
      {canSeeSettingsTab() && <ProtectedRoute exact component={SettingsPage} path={routes.app.settingDetails} />}

      <ProtectedRoute exact component={HelpPage} path={routes.app.help} />

      {isNotificationFeatureAvailable({ flags }) && <ProtectedRoute exact component={NotificationsPage} path={routes.app.notifications} />}

      <ProtectedRoute exact component={ErrorPage} path={routes.app.error} />
      <Redirect to={{ pathname: currentUser ? homePage : routes.app.login, search: location?.search }} />
    </Switch>
  )
}

export default MainRoute
