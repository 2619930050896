import React from 'react'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import SuspensionChip from 'components/common/chip/SuspensionChip'
import { get, capitalize } from 'lodash'
import WithSuspensionPopover from 'components/customer-details/content/suspensions/WithSuspensionPopover'
import VacationHoldChipAccountHeader from 'components/customer-details/content/holds/VacationHoldChipAccountHeader'

const CustomerInfo = () => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const accountName = get(customerDetails, 'accountName', '')
  const accountNumber = get(customerDetails, 'accountNumber', '')
  const billingProfile = get(customerDetails, 'accountBilling.billingProfile.profile', '')
  const isAccountSuspended = get(customerDetails, 'isSuspended', false)
  const suspensionDescription = get(customerDetails, 'suspensionDescription')
  const suspensionType = get(customerDetails, 'suspensionType')
  return (
    <Grid container direction="column" item sx={{ pt: 1 }}>
      <Grid item>
        <Grid container alignItems="end" columnSpacing={2}>
          <Grid item xs="auto">
            <Typography variant="h4" fontWeight={600} pl={4}>
              {accountName}
            </Typography>
          </Grid>
          <Grid item xs>
            <Stack columnGap={1} direction="row" flexWrap="nowrap" alignItems="center">
              <VacationHoldChipAccountHeader />
              {isAccountSuspended && (
                <WithSuspensionPopover suspensionType={suspensionType} suspensionDescription={suspensionDescription}>
                  <SuspensionChip label={capitalize(suspensionType)} />
                </WithSuspensionPopover>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Typography color="textSecondary" fontWeight={500} sx={{ pl: 4 }}>
              #{accountNumber}
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ mx: 1, borderRightWidth: '1.5px' }} />
          </Grid>
          <Grid item>
            <Typography color="textSecondary" fontWeight={500}>
              {billingProfile}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CustomerInfo.propTypes = {}

export default CustomerInfo
