import React, { useCallback, useEffect, useState } from 'react'
import uniq from 'lodash/uniq'
import capitalize from 'lodash/capitalize'
import format from 'date-fns/format'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Box, useTheme, Typography, Grid, DialogContent, Button, InputAdornment } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import EventIcon from '@mui/icons-material/Event'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { calculateSum } from 'utils/price'
import { generateRandomId, isString } from 'utils/string'
import { setBatchConfirmationDialogState } from 'slices/groups/PaymentGroupsSlice'
import { useLazyGetPaymentBatchDetailQuery } from 'api/billing/getPaymentBatchDetail'
import { useGetPaymentGroupListQuery } from 'api/groups/payment/getPaymentGroupList'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import HHTextField from 'components/form-fields/v5/HHTextField'
import PaymentMethod from './common/PaymentMethod'
import { PAYMENT_BATCH_CONFIRMATION_TIMEOUT } from './settings'

const PaymentBatchConfirmationDialog = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [getPaymentBatchDetail, { data }] = useLazyGetPaymentBatchDetailQuery()
  const { data: paymentGroupList = [] } = useGetPaymentGroupListQuery()

  const batchConfirmationDialog = useSelector(state => get(state, 'PaymentGroups.batchConfirmationDialog', {}), shallowEqual)
  const { isOpen = false, batchId = '', groupId = '', batchDate = '' } = batchConfirmationDialog

  const batchItems = isLoading ? [] : get(data, 'batchItems', []).map(item => ({ ...item, rowId: generateRandomId(13) }))
  const groupDetails = paymentGroupList.find(group => group.id === groupId)
  const customerCount = uniq(batchItems.map(({ customerName }) => customerName)).length
  const total = calculateSum(batchItems, 'amount')

  const handleClose = () => dispatch(setBatchConfirmationDialogState({ isOpen: false, batchId: '', groupId: '', batchDate: '' }))

  const getRowHeight = useCallback(() => 50, [])

  const renderFooter = () => (
    <Box
      width="100%"
      height={52}
      px={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#F7F9FC"
      borderTop="1px solid"
      borderColor="divider"
      borderRadius={1}
    >
      {customerCount > 0 && (
        <>
          <Typography variant="h5" fontWeight={400}>
            {`${customerCount} Customer${customerCount > 1 ? 's' : ''}`}
          </Typography>
          <Typography mr={18} variant="h5" fontWeight={400}>
            <HHDisplayMoney value={total} />
          </Typography>
        </>
      )}
    </Box>
  )

  useEffect(() => {
    if (isOpen && batchId) {
      setIsLoading(true)
      setTimeout(
        () =>
          getPaymentBatchDetail(batchId)
            .unwrap()
            .catch(handleError)
            .finally(() => setIsLoading(false)),
        PAYMENT_BATCH_CONFIRMATION_TIMEOUT
      )
    }
  }, [isOpen, batchId])

  return (
    <HHBaseDialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={capitalize(T.PAYMENT_BATCH_CONFIRMATION)} onClose={handleClose} />
      <DialogContent>
        <Grid mt={2} container columnSpacing={2} justifyContent="space-between">
          <Grid item xs sm={4}>
            <HHTextField
              fullWidth
              label={T.BATCH_DATE}
              value={batchDate ? format(batchDate, 'EEEE, MMMM dd, yyyy') : ''}
              readOnly
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon />
                  </InputAdornment>
                ),
              }}
              deprecatedLabel={false}
            />
          </Grid>
          <Grid item xs sm={4}>
            <HHTextField fullWidth label="Payment group" value={get(groupDetails, 'groupName', ' ')} readOnly deprecatedLabel={false} />
          </Grid>
          <Grid item xs sm={4}>
            <HHTextField fullWidth label={capitalize(T.BATCH_NUMBER)} value={batchId} readOnly deprecatedLabel={false} />
          </Grid>
        </Grid>
        <Box mt={2} height="calc(100vh - 355px)">
          <DataGridPro
            loading={isLoading}
            rows={batchItems}
            disableRowSelectionOnClick
            columns={[
              {
                flex: 2,
                sortable: false,
                headerName: 'Customer',
                field: 'customerName',
              },
              {
                flex: 2,
                sortable: false,
                headerName: 'Invoice #',
                field: 'paymentTarget',
                renderCell: ({ value }) => (isString(value) ? capitalize(value.replace(/[[\]]/g, '')) : value),
              },
              {
                flex: 2,
                headerName: 'Payment type',
                field: 'paymentMethod',
                sortable: false,
                renderCell: ({ row }) => <PaymentMethod row={{ ...row, paymentMethod: row?.paymentMethod || row?.paymentNumber }} />,
              },
              {
                flex: 2,
                headerName: 'Amount',
                field: 'amount',
                sortable: false,
                renderCell: ({ formattedValue }) => <HHDisplayMoney value={formattedValue} />,
                headerAlign: 'right',
                align: 'right',
              },
              {
                headerName: 'Status',
                field: 'transactionStatus',
                sortable: false,
                width: 150,
                minWidth: 150,
                maxWidth: 150,
                headerAlign: 'right',
                align: 'right',
              },
            ]}
            getRowHeight={getRowHeight}
            getRowId={({ rowId }) => rowId}
            disableColumnMenu
            slots={{ footer: renderFooter }}
            sx={{
              ...theme.typography.h6,
              fontWeight: 400,
              color: theme.palette.text.primary,
              '& .MuiDataGrid-columnHeaders': {
                ...theme.typography.subtitle2,
                background: '#F7F9FC',
                color: theme.palette.text.primary,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              },
              '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                padding: '0 16px',
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" onClick={handleClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

export default PaymentBatchConfirmationDialog
