import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chartSummaryDialog: {
    isOpen: false,
    color: '',
    label: '',
    value: 0,
    total: 0,
    rows: [],
    isBatch: false,
  },
  batchConfirmationDialog: {
    isOpen: false,
    batchId: '',
    groupId: '',
    batchDate: '',
  },
}

const PaymentGroupsSlice = createSlice({
  name: 'PaymentGroups',
  initialState,
  reducers: {
    setChartSummaryDialogState: (state, action) => {
      state.chartSummaryDialog = action.payload
    },
    setBatchConfirmationDialogState: (state, action) => {
      state.batchConfirmationDialog = action.payload
    },
  },
})

export const { setChartSummaryDialogState, setBatchConfirmationDialogState } = PaymentGroupsSlice.actions
export default PaymentGroupsSlice.reducer
