export const actions = {
  ADD_CUSTOMER_FOLLOW_UP_NOTE: 'CUSTOMERS_ADD_FOLLOW_UP_NOTE',
  GET: {
    FOLLOW_UP_BY_ID: 'CUSTOMERS_GET_FOLLOW_UP_BY_ID',
  },
}

export const getCustomerFollowUpById = (body, onSuccess) => ({
  type: actions.GET.FOLLOW_UP_BY_ID,
  payload: { body, onSuccess },
})

export const addFollowUpAdditionalNotes = (body, onSuccess) => ({
  type: actions.ADD_CUSTOMER_FOLLOW_UP_NOTE,
  payload: { body, onSuccess },
})
