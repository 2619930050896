import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateWorkOrder: builder.mutation({
      query: body => ({ url: '/api/v1/core/work-orders', method: 'PUT', body }),
    }),
  }),
})

export const { useUpdateWorkOrderMutation } = extendedApi
