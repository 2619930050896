import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@mui/icons-material/Add'

import Constants from 'Constants'

import { memo } from 'utils/react'
import { Table } from '@styled-icons/heroicons-outline'
import { canAddPricingManager, canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import T from 'T'
import { Box, Stack, SvgIcon, Tooltip, Typography, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { capitalize, isEmpty } from 'lodash'
import ReorderIcon from '@mui/icons-material/Reorder'
import SummaryCard from 'components/pricing/SummaryCard'
import { openPricingDrawer, selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { get } from 'utils/lodash'
import useTheme from '@mui/material/styles/useTheme'
import noop from 'lodash/noop'
import { PRICING_TABS_ID } from 'settings/constants/pricing'
import { ReactComponent as BulkEdit } from 'assets/BulkEdit.svg'

const { MEDIA_SERVER } = Constants
const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID

const SecondaryNavBar = ({
  changePrice = {},
  feeTab = 'general',
  handleChangePrice = () => {},
  handleSingleChange = () => {},
  handleTabChange = noop,
  newButtonName = '',
  onClickNewDisposal = () => {},
  summary = {},
  tab = 'service',
  tabValue = 'summary',
  toggleDrawer = () => {},
  handleBackToGroupClick,
  currentSelectedGroupName,
}) => {
  const addPricingManager = useSelector(canAddPricingManager, shallowEqual)
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)

  const hasNoRowsSelected = Boolean(isEmpty(changePrice) || changePrice[tab] !== true)
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const min = useMemo(() => {
    return !hasNoRowsSelected ? get(summary[tab], 'min', '') : 0
  }, [hasNoRowsSelected, summary, tab])
  const max = useMemo(() => {
    return !hasNoRowsSelected ? get(summary[tab], 'max', '') : 0
  }, [hasNoRowsSelected, summary, tab])
  const avg = useMemo(() => {
    return !hasNoRowsSelected ? get(summary[tab], 'avg', '') : 0
  }, [hasNoRowsSelected, summary, tab])
  const locations = useMemo(() => {
    return !hasNoRowsSelected ? get(summary[tab], 'locations', '') : 0
  }, [hasNoRowsSelected, summary, tab])
  const revenue = useMemo(() => {
    return !hasNoRowsSelected ? get(summary[tab], 'revenue', '') : 0
  }, [hasNoRowsSelected, summary, tab])
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const handleOpenDrawer = () => {
    dispatch(openPricingDrawer())
  }
  const isServiceGroup = useMemo(() => tabValue === SERVICE_GROUPS_TAB_ID, [tabValue])
  const isServiceGroupDetails = useMemo(() => isServiceGroup && currentSelectedGroupName, [currentSelectedGroupName, isServiceGroup])

  const bulkEditTooltip = useMemo(() => {
    if (hasNoRowsSelected) {
      return 'Select rows to bulk edit'
    }
    if (tab === 'services') {
      return 'Bulk edit services'
    }
    if (tab === 'taxes') {
      return 'Bulk edit taxes'
    }
    return 'Bulk edit fees'
  }, [tab, hasNoRowsSelected])

  const formattedTabValue = useMemo(() => {
    if (tabValue === SERVICE_GROUPS_TAB_ID) return capitalize(`${T.SERVICE_GROUP}s`)
    return capitalize(tabValue)
  }, [tabValue])

  return (
    <>
      <Box
        width="100%"
        overflow="hidden"
        px={isTabletOrMobile ? 2 : 4}
        height={56}
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent={isTabletOrMobile ? 'normal' : 'space-between'}
        bgcolor="background.default"
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.divider,
        }}
      >
        <Stack flexDirection="row" alignItems="center" columnGap={1} sx={{ flex: 1, flexGrow: 1 }}>
          {!isOpenPricingDrawer && <ReorderIcon sx={{ mr: 1 }} onClick={handleOpenDrawer} />}
          {!isServiceGroupDetails && (
            <Typography variant="h4" fontWeight="600">
              {formattedTabValue}
            </Typography>
          )}
          {isServiceGroupDetails && (
            <Typography key="3" sx={{ color: 'text.primary' }} variant="h4" fontWeight="600">
              {currentSelectedGroupName}
            </Typography>
          )}
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end" columnGap={1}>
          {updatePricingManager && !isServiceGroup && (
            <Tooltip title={bulkEditTooltip} arrow placement="bottom">
              <span>
                <IconButton
                  disabled={hasNoRowsSelected}
                  variant="text"
                  startIcon={<img src={`${MEDIA_SERVER}SwitchVertical.svg`} alt="change-price" />}
                  onClick={() => handleChangePrice()}
                >
                  <SvgIcon>
                    <BulkEdit />
                  </SvgIcon>
                </IconButton>
              </span>
            </Tooltip>
          )}
          {addPricingManager && (
            <IconButton
              onClick={event => {
                if (tab === 'disposal' && onClickNewDisposal) {
                  onClickNewDisposal()
                } else if (toggleDrawer !== null) {
                  toggleDrawer(true)(event)
                }
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Stack flexDirection="row" alignItems="center" sx={{ flex: 1, overflow: 'hidden', px: isTabletOrMobile ? 2 : 4, py: 2 }}>
        <Stack flexDirection="row" alignItems="center" sx={{ flex: 1, flexGrow: 1 }}>
          {tabValue !== SERVICE_GROUPS_TAB_ID && (
            <SummaryCard tabValue={tabValue} min={min} max={max} average={avg} locations={locations} revenue={revenue} />
          )}
        </Stack>

        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
          <IconButton
            sx={{ ml: 2 }}
            variant="outlined"
            color="textSecondary"
            onClick={event => handleSingleChange('isOpenConfigurator', event.currentTarget)}
          >
            <SvgIcon>
              <Table />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
    </>
  )
}

SecondaryNavBar.propTypes = {
  tab: PropTypes.string,
  newButtonName: PropTypes.string,
  onClickNewDisposal: PropTypes.func,
  changePrice: PropTypes.object,
  handleSingleChange: PropTypes.func,
  handleChangePrice: PropTypes.func,
  toggleDrawer: PropTypes.func,
  handleBackToGroupClick: PropTypes.func,
  currentSelectedGroupName: PropTypes.string,
}

export default memo(SecondaryNavBar)
