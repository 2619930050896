import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_AGING_GROUP_DETAILS, CACHE_TAG_SUSPENSION_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    bulkAddAccountNoteToAccounts: build.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/groups/${id}:add-account-note`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_SUSPENSION_GROUP_DETAILS, CACHE_TAG_AGING_GROUP_DETAILS],
    }),
  }),
})

export const { useBulkAddAccountNoteToAccountsMutation } = extendedApi
