import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FiltersMenu from './FiltersMenu'

const FiltersMenuButton = ({ sx, onRouteFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode, shallowEqual)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const color = isTemporaryModeActive ? 'warning' : 'primary'

  return (
    <>
      <Button
        color={color}
        sx={sx}
        startIcon={<FilterListIcon />}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        variant="text"
        onClick={handleClick}
      >
        Filters
      </Button>
      <FiltersMenu open={open} anchorEl={anchorEl} onRouteFilterChange={onRouteFilterChange} onClose={handleClose} />
    </>
  )
}

FiltersMenuButton.propTypes = {
  sx: PropTypes.object,
  onRouteFilterChange: PropTypes.func,
}

export default FiltersMenuButton
