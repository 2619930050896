import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'

import { useGridApiRef } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import { DialogContent, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { getFilteredRowsForAddRemoveDialog } from 'data/groups/accountGroupsSelectors'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'
import { transformSearchResponse } from 'utils/accountSearch'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { ADD_REMOVE_FORM_MODEL } from 'components/customers/groups/account/add-remove/common/settings'
import SearchField from 'components/customers/groups/account/add-remove/common/SearchField'
import DataGrid from 'components/customers/groups/account/add-remove/common/DataGrid'
import GenericUnsuspendAccountDialog from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/GenericUnsuspendAccountDialog'
import { UNSUSPEND_FORM_MODEL } from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/settings'
import { useUnsuspendAccountListMutation } from 'api/accounts/suspensionsCrud'
import { handleError } from 'utils/error'
import Filters from 'components/customers/groups/suspension/add-remove/Filters'
import uniq from 'lodash/uniq'

const defaultValues = {
  ...ADD_REMOVE_FORM_MODEL,
  ...UNSUSPEND_FORM_MODEL,
}

const RemoveAccountsFromSuspensionGroupDialog = ({ isOpen = false, existingAccounts = [], onClose }) => {
  const apiRef = useGridApiRef()
  const [getAccountMeta, { isSuccess: isAccountMetaSuccess }] = useLazyGetAccountMetaQuery()
  const removeFromGroupForm = useForm({ defaultValues: { ...defaultValues } })
  const { watch, reset, handleSubmit } = removeFromGroupForm
  const [isConfirmUnsuspendDialogOpen, setIsConfirmUnsuspendDialogOpen] = useState(false)
  const customerMeta = useSelector(getAccountMetaData, shallowEqual)
  const selectionModel = watch('selectionModel')
  const search = watch('search')
  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedCount = useMemo(() => (Array.isArray(selectionModel) ? selectionModel.length : 0), [selectionModel])
  const confirmDialogTitle = useMemo(
    () => (selectedCount === 1 ? `Unsuspend ${selectedCount} account` : `Unsuspend ${selectedCount} accounts`),
    [selectedCount]
  )
  const confirmDialogDescription = useMemo(
    () => (selectedCount > 1 ? `Are you sure you want to unsuspend these accounts` : `Are you sure you want to unsuspend this account`),
    [selectedCount]
  )
  const allAccounts = useMemo(
    () => get(transformSearchResponse({ results: existingAccounts }), 'accounts', []).filter(row => row?.suspensionHold),
    [existingAccounts]
  )
  const filteredRows = useMemo(
    () => getFilteredRowsForAddRemoveDialog({ rows: allAccounts, search, selectedTagsIds, selectedBillingProfilesIds }),
    [allAccounts, search, selectedTagsIds, selectedBillingProfilesIds]
  )
  const [unsuspendAccountList, { isLoading }] = useUnsuspendAccountListMutation()

  const handleOpenConfirmUnsuspendDialog = () => {
    setIsConfirmUnsuspendDialogOpen(true)
  }

  const handleCloseConfirmUnsuspendDialog = () => {
    setIsConfirmUnsuspendDialogOpen(false)
  }

  const handleRemoveAccountsFromGroup = data => {
    const { selectionModel: accountIds, rescheduleWorkOrders } = data
    unsuspendAccountList({ accountIds, rescheduleWorkOrders })
      .unwrap()
      .then(() => {
        toast.success(selectedCount > 1 ? T.ACCOUNTS_UNSUSPENDED_SUCCESSFULLY : T.ACCOUNT_UNSUSPENDED_SUCCESSFULLY)
        handleCloseConfirmUnsuspendDialog()
        onClose()
      })
      .catch(handleError)
  }

  const isRowSelectable = params => params.row?.suspensionHold

  useEffect(() => {
    if (isOpen) {
      getAccountMeta()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && isAccountMetaSuccess) {
      const dataBillingProfileIds = uniq(allAccounts.map(({ billingProfileId }) => billingProfileId))
      const dataTagIds = uniq(allAccounts.map(({ tagIds }) => tagIds).flat())
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      const selectedTags = allTags.filter(({ id }) => dataTagIds.includes(id))
      const selectedBillingProfiles = billingProfiles.filter(({ id }) => dataBillingProfileIds.includes(id))
      reset({ ...defaultValues, tagList: selectedTags, selectedTags, billingProfileList: selectedBillingProfiles, selectedBillingProfiles })
    }
  }, [isOpen, allAccounts, existingAccounts, isAccountMetaSuccess, customerMeta])

  return (
    <FormProvider {...removeFromGroupForm}>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
        <HHDialogTitle title={T.UNSUSPEND_ACCOUNTS} onClose={onClose} />
        <DialogContent>
          <Grid mt={1} container spacing={2} alignItems="center">
            <Grid item xs>
              <SearchField />
            </Grid>
            <Grid item xs="auto">
              <Filters />
            </Grid>
          </Grid>
          <DataGrid isRowSelectable={isRowSelectable} apiRef={apiRef} rows={existingAccounts} filteredRows={filteredRows} />
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <LoadingButton
            disabled={selectionModel.length === 0}
            color="success"
            size="small"
            variant="contained"
            onClick={handleOpenConfirmUnsuspendDialog}
          >
            {T.UNSUSPEND}
          </LoadingButton>
        </HHDialogActions>
        <GenericUnsuspendAccountDialog
          title={confirmDialogTitle}
          description={confirmDialogDescription}
          open={isConfirmUnsuspendDialogOpen}
          onClose={handleCloseConfirmUnsuspendDialog}
          isSaveLoading={isLoading}
          onSave={handleSubmit(handleRemoveAccountsFromGroup)}
        />
      </HHBaseDialog>
    </FormProvider>
  )
}

RemoveAccountsFromSuspensionGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default RemoveAccountsFromSuspensionGroupDialog
