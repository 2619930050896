import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material'

const StyledAccordion = styled(MuiAccordion)({
  '&': {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  [`.${accordionSummaryClasses.expanded}`]: {},
})

const StyledAccordionSummary = styled(MuiAccordionSummary)({
  '&': {
    padding: '0px',
    backgroundColor: '#F3F4F6',
    borderRadius: 4,
    marginBottom: -1,
    minHeight: 36,
    '&$expanded': {
      minHeight: 36,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    flexDirection: 'row-reverse',
  },
  [`.${accordionSummaryClasses.content}`]: {
    '&$expanded': {
      margin: '16px 0px',
    },
  },
  [`.${accordionSummaryClasses.expanded}`]: {},
})

const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  '&': {
    padding: 0,
    marginTop: 12,
  },
}))

export { StyledAccordionSummary, StyledAccordion, StyledAccordionDetails }
