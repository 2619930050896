import React, { useState, Children, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import flattenDeep from 'lodash/flattenDeep'
import noop from 'lodash/noop'

import { shallowEqual, useSelector } from 'react-redux'
import { Card, Grid, Box, Typography, Tooltip, Button } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import FooterButton from 'components/buttons/FooterButton'
import PlaceIcon from '@mui/icons-material/Place'
import AddIcon from '@mui/icons-material/Add'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CommonSwitch from 'components/common/CommonSwitch'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'

import { canAddSettings } from 'data/permissions/permissionsSelectors'
import { useLazyGetAddressSuggestListQuery } from 'api/map/getAddressSuggestList'
import { DISPOSAL_TOOLTIP_TITLE } from 'settings/constants/generalSettings'
import Loader from 'components/common/loader'
import T from 'T'
import { handleError } from 'utils/error'
import { get } from 'utils/lodash'

import DisposalSiteHours from './DisposalSiteHours'
import DisposalSiteMaterials from './DisposalSiteMaterials'

const MAX_DISPOSAL_ADDRESS_SHOW = 5
const DEBOUNCE_TIME = 300
const { LNG_LAT_FIX } = MAP_DEFAULT_OPTIONS

const AddDisposalSites = ({
  disposalState = {},
  isLoading = false,
  handleSave = noop,
  handleCancel = noop,
  setDisposalState = noop,
  onHandleNameChange = noop,
}) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const [showDisposalAddressList, setShowDisposalAddressList] = useState(false)
  const [disposalAddressList, setDisposalAddressList] = useState([])
  const [getAddressSuggestList] = useLazyGetAddressSuggestListQuery()

  const selectedDays = flattenDeep(disposalState.disposalSiteHours.filter(rec => !rec.deleted).map(data => data.days))

  const handleDisposalAddressList = useCallback(
    debounce(value => {
      getAddressSuggestList({ search: value })
        .unwrap()
        .then(res => {
          setDisposalAddressList(get(res, 'suggestions', []))
          setShowDisposalAddressList(true)
        })
        .catch(handleError)
    }, DEBOUNCE_TIME),
    []
  )

  const addMore = type => {
    const undeletedRecords = disposalState.disposalSiteHours.filter(rec => !rec.deleted)
    if (type === 'disposal-site-hours') {
      if (
        selectedDays.length >= 7 ||
        (undeletedRecords.length > 0 &&
          (disposalState.disposalSiteHours[undeletedRecords.length - 1].openTime === '' ||
            disposalState.disposalSiteHours[undeletedRecords.length - 1].closeTime === ''))
      )
        return
      if (undeletedRecords.filter(data => data.days === '' && data.openTime === '' && data.closeTime === '').length > 0) return
      disposalState.disposalSiteHours.push({
        days: [],
        openTime: '',
        closeTime: '',
      })

      setDisposalState({ disposalSiteHours: disposalState.disposalSiteHours, footerFlag: true })
    } else {
      const undeletedMaterial = disposalState.disposalSiteMaterials.filter(rec => !rec.deleted)
      if (undeletedMaterial.filter(data => data.materials === '' && data.tippingFee === '' && data.unitOfMeasure === '').length > 0) return
      disposalState.disposalSiteMaterials.push({
        materials: '',
        tippingFee: '',
        unitOfMeasure: '',
      })

      setDisposalState({ disposalSiteMaterials: disposalState.disposalSiteMaterials, footerFlag: true })
    }
  }

  const handleRemoval = (index, type) => {
    if (type === 'disposal-site-hours') {
      if (disposalState.disposalSiteHours.length > 0) {
        disposalState.disposalSiteHours[index].deleted = true
      }

      setDisposalState({ disposalSiteHours: disposalState.disposalSiteHours, footerFlag: disposalState.name !== '' })
    } else {
      if (disposalState.disposalSiteMaterials.length > 0) {
        disposalState.disposalSiteMaterials[index].deleted = true
      }
      setDisposalState({ disposalSiteMaterials: disposalState.disposalSiteMaterials, footerFlag: disposalState.name !== '' })
    }
  }

  const getDisposalAddressList = (event, isOptionSelected) => {
    if (isOptionSelected) {
      const streetNumber = get(event, 'streetNumber', '')
      const streetName = get(event, 'streetName', '')
      const zipCode = get(event, 'zipCode', '')
      const country = get(event, 'country', '')
      const state = get(event, 'state', '')
      const city = get(event, 'city', '')
      const line1 = `${streetNumber} ${streetName}`.trim()
      const longitude = get(event, 'center.longitude', '') ? get(event, 'center.longitude', '').toFixed(LNG_LAT_FIX) : ''
      const latitude = get(event, 'center.latitude', '') ? get(event, 'center.latitude', '').toFixed(LNG_LAT_FIX) : ''

      setDisposalState({
        streetAddress: line1,
        state,
        country,
        city,
        zipCode,
        longitude,
        latitude,
      })
      setShowDisposalAddressList(false)
      return
    }
    const { value } = event.target
    onHandleNameChange(event)

    if (!value) {
      setDisposalAddressList([])
      setShowDisposalAddressList(false)
      return
    }
    handleDisposalAddressList(value)
  }

  const handleCloseDisposalAddressSuggestions = event => {
    if (!showDisposalAddressList || !event.target) {
      return
    }
    // Only check if addressList is open
    // Disable for UL tag dropdown & self input click
    try {
      if (event.target.className.indexOf('billing autocomplete') > -1 || event.target.getAttribute('placeholder') === T.STREET_ADDRESS) {
        return
      }

      setShowDisposalAddressList(false)
    } catch (err) {}
  }

  useEffect(() => {
    document.addEventListener('click', handleCloseDisposalAddressSuggestions, true)
    return () => {
      document.removeEventListener('click', handleCloseDisposalAddressSuggestions, true)
    }
  })

  return (
    <Card>
      {isLoading && <Loader />}
      <Box sx={{ p: 3, maxHeight: 'calc(100vh - 180px)', overflowY: 'scroll' }}>
        <Grid container justifyContent="space-between">
          <Grid item xs="7">
            <HHTextField
              label={T.NAME}
              placeholder={T.NAME}
              fullWidth
              name="name"
              value={get(disposalState, 'name', '')}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item>
            <CommonSwitch
              titlePosition="right"
              title={T.ACTIVE}
              isChecked={get(disposalState, 'active', false)}
              onChange={checked =>
                setDisposalState({
                  active: checked,
                  footerFlag: true,
                })
              }
            />
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={`${T.STREET} ${T.ADDRESS}`}
              placeholder={`${T.STREET} ${T.ADDRESS}`}
              fullWidth
              name="streetAddress"
              value={get(disposalState, 'streetAddress', '')}
              onChange={event => getDisposalAddressList(event, false)}
              onFocus={() => {
                if (disposalAddressList.length) {
                  setShowDisposalAddressList(true)
                  return
                }
                setDisposalAddressList([])
              }}
            />

            <Box position="relative">
              {showDisposalAddressList && disposalAddressList && disposalAddressList.length > 0 && (
                <Card
                  sx={{
                    maxHeight: 200,
                    position: 'absolute',
                    width: '100%',
                    zIndex: 9,
                  }}
                >
                  {Children.toArray(
                    disposalAddressList.map((address, index) => {
                      const streetValue = `${get(address, 'streetNumber', '')} ${get(address, 'streetName', '')}`.trim()
                      const cityValue = get(address, 'city', '')
                      const stateValue = get(address, 'state', '')
                      const areaValue = cityValue && stateValue ? `${cityValue}, ${stateValue}` : cityValue || stateValue

                      if (index < MAX_DISPOSAL_ADDRESS_SHOW) {
                        return (
                          <Box
                            onMouseDown={() => getDisposalAddressList(address, true)}
                            m={1.8}
                            display="flex"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}
                          >
                            <PlaceIcon fontSize="small" sx={{ color: 'text.secondary' }} />
                            <Typography variant="body1" ml={1}>
                              {streetValue}
                            </Typography>
                            <Typography variant="subtitle2" ml={1}>
                              {areaValue}
                            </Typography>
                          </Box>
                        )
                      }
                    })
                  )}
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={T.CITY}
              placeholder={T.CITY}
              name="city"
              fullWidth
              value={get(disposalState, 'city', '')}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item xs="4" ml={2}>
            <HHTextField
              label={T.STATE}
              placeholder={T.STATE}
              fullWidth
              value={get(disposalState, 'state', '')}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="7">
            <HHTextField
              label={T.ZIP_CODE}
              placeholder={T.ZIP_CODE}
              fullWidth
              name="zipCode"
              value={get(disposalState, 'zipCode', '')}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
          <Grid item xs="4" ml={2}>
            <HHTextField
              label={T.COUNTRY}
              placeholder={T.COUNTRY}
              fullWidth
              name="country"
              value={get(disposalState, 'country', '')}
              onChange={e => onHandleNameChange(e)}
            />
          </Grid>
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="12">
            <Typography variant="subtitle1">{T.DISPOSAL_SITE_HOURS}</Typography>
          </Grid>

          <Grid item xs="12">
            {[...Array(disposalState.disposalSiteHours.length || 1)].map((num, index) => (
              <DisposalSiteHours
                index={index}
                handleRemoval={handleRemoval}
                disposalState={disposalState}
                setDisposalState={setDisposalState}
              />
            ))}
          </Grid>

          {selectedDays.filter(day => day && day.id !== 'All').length !== 7 && addSettings && (
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                addMore('disposal-site-hours')
              }}
              startIcon={<AddIcon />}
            >
              {`${T.ADD}`}
            </Button>
          )}
        </Grid>

        <Grid container mt={1.8}>
          <Grid item xs="12">
            <Typography variant="subtitle1">{T.DISPOSAL_SITE_MATERIALS}</Typography>
          </Grid>

          <Grid item xs="12">
            {[...Array(disposalState.disposalSiteMaterials.length || 1)].map((num, index) => (
              <DisposalSiteMaterials
                disposalState={disposalState}
                setDisposalState={setDisposalState}
                index={index}
                handleRemoval={handleRemoval}
              />
            ))}
          </Grid>

          {addSettings && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                addMore('disposal-site-materials')
              }}
            >
              {`${T.ADD}`}
            </Button>
          )}
        </Grid>

        <Box display="flex" mt={1.5} alignItems="center">
          <CommonSwitch
            titlePosition="right"
            title={T.TICKET_REQUIRED}
            isChecked={get(disposalState, 'ticketRequired', false)}
            onChange={checked => setDisposalState({ ticketRequired: checked, footerFlag: true })}
          />

          <Tooltip title={DISPOSAL_TOOLTIP_TITLE} sx={{ ml: 0.5 }}>
            <ErrorOutlineIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>

      {get(disposalState, 'footerFlag', false) && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={() => handleCancel()}
            rightButtonTitle={T.SAVE}
            onProceed={handleSave}
            disabledProceed={
              disposalState.name === '' ||
              disposalState.streetAddress === '' ||
              disposalState.city === '' ||
              disposalState.state === '' ||
              disposalState.country === '' ||
              disposalState.zipCode === ''
            }
          />
        </Box>
      )}
    </Card>
  )
}

AddDisposalSites.propTypes = {
  disposalState: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  setDisposalState: PropTypes.func,
  onHandleNameChange: PropTypes.func,
}

export default AddDisposalSites
