import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    updateLinkedDisposalFee: build.mutation({
      query: body => ({
        url: `/api/pricing/service/link/disposal/update`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useUpdateLinkedDisposalFeeMutation } = extendedApi