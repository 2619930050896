import { isString } from './string'

export const getContainerCount = containers => {
  if (Array.isArray(containers)) {
    return containers.length
  }

  if (isString(containers) && containers) {
    return containers.split(',').length
  }

  return 0
}
