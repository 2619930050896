import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceGroupAgingBucketData: build.query({
      providesTags: (result, error, { id }) => [
        CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
        { type: CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA, id },
      ],
      query: ({ id }) => ({
        url: `/api/v1/billing/invoice/groups/${id}/aging`,
      }),
    }),
  }),
})

export const { useGetInvoiceGroupAgingBucketDataQuery } = extendedApi
