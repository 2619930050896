import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import { noop } from 'lodash'
import HHConfirmDialog from './HHConfirmDialog'

const HHConfirmDeleteButton = ({
  DialogProps,
  CancelButtonProps,
  DeleteButtonProps,
  confirmTitle,
  confirmDescription,
  onDeleteConfirmed = noop,
  onDeleteCanceled = noop,
  children = 'Delete',
  size = 'medium',
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    onDeleteCanceled()
  }

  const handleConfirm = () => {
    setOpen(false)
    onDeleteConfirmed()
  }
  return (
    <>
      <LoadingButton loadingPosition="end" color="error" size={size} {...rest} onClick={handleClickOpen}>
        {children}
      </LoadingButton>
      <HHConfirmDialog
        isOpen={open}
        DialogProps={DialogProps}
        ConfirmButtonProps={DeleteButtonProps}
        confirmTitle={confirmTitle}
        confirmDescription={confirmDescription}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  )
}

HHConfirmDeleteButton.propTypes = {
  size: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmDescription: PropTypes.node,
  DialogProps: PropTypes.object,
  CancelButtonProps: PropTypes.object,
  DeleteButtonProps: PropTypes.object,
  children: PropTypes.node,
  onDeleteConfirmed: PropTypes.func,
  onDeleteCanceled: PropTypes.func,
}

export default HHConfirmDeleteButton
