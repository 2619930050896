import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CalendarPlus } from 'assets/route_manager/actions_menu/CalendarPlus.svg'
import { Box, ListItemText, SvgIcon } from '@mui/material'
import { HHAlert } from 'components/common/HHAlert'
import CenteredListItem from 'components/common/CenteredListItem'
import useTheme from '@mui/material/styles/useTheme'
import RenderFromStopListItem from 'components/route-manager/BulkActions/common/StopListItem/RenderFromStopListItem'
import { useDispatch, useSelector } from 'react-redux'
import { selectBulkUnassignToRows, selectBulkSelectedMoveToRows, setSelectedMoveToRows } from 'slices/route/bulkUnassignStopsSlice'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import RenderMoveToHeader from 'components/route-manager/BulkActions/BulkUnassignStopsDialog/RenderMoveToHeader'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'

const MoveToRouteColumn = ({ routes, apiRef }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const moveToRows = useSelector(selectBulkUnassignToRows)
  const selectedMoveToRows = useSelector(selectBulkSelectedMoveToRows)
  const hasMoveToRows = moveToRows.length > 0

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
    },
    {
      field: 'id',
      renderCell: p => <RenderFromStopListItem {...p} showAvatarContent={false} />,
      renderHeader: p => <RenderMoveToHeader routes={routes} {...p} />,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const handleSelectionModelChange = selectionModel => dispatch(setSelectedMoveToRows(selectionModel))

  return (
    <>
      <Box mx={2} mb={2}>
        <CenteredListItem
          sx={{ '& .MuiListItemSecondaryAction-root': { display: 'flex', alignItems: 'center' } }}
          secondaryAction={
            <SvgIcon fontSize="small">
              <CalendarPlus />
            </SvgIcon>
          }
        >
          <ListItemText primaryTypographyProps={{ variant: 'h4', color: 'textSecondary' }} primary="Unassigned" />
        </CenteredListItem>
        {!hasMoveToRows && (
          <HHAlert severity="info" borderColor={theme.palette.info.dark} sx={{ mt: 1 }}>
            Nothing to unassign yet
          </HHAlert>
        )}
      </Box>
      <Box flex={1} height="100%" width="100%" overflow="hidden">
        {hasMoveToRows && (
          <BulkMoveStopsDataGridPro
            apiRef={apiRef}
            rowSelectionModel={selectedMoveToRows}
            onRowSelectionModelChange={handleSelectionModelChange}
            columns={columns}
            rows={moveToRows}
          />
        )}
      </Box>
    </>
  )
}

MoveToRouteColumn.propTypes = {
  routes: PropTypes.array.isRequired,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default MoveToRouteColumn
