import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { Button, Stack, useTheme, DialogActions, Tooltip } from '@mui/material'

import { handleError } from 'utils/error'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS } from 'api/cacheTagTypes'
import { setBulkUnassignStopsState, selectBulkUnassignToRows } from 'slices/route/bulkUnassignStopsSlice'

import T from 'T'
import api from 'api'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'

const BulkUnassignStopsDialogActions = ({ onReset, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const { handleSubmit, watch } = useFormContext()
  const serviceDate = watch('serviceDate')

  const moveToRows = useSelector(selectBulkUnassignToRows)
  const validStopsCount = moveToRows.length
  const submitTitle =
    validStopsCount > 0 ? 'This does not affect future stops of these services' : 'This does not affect future stops of this service'
  const isSaveButtonDisabled = validStopsCount === 0

  const onValidSubmit = () => {
    bulkMoveStops({
      moves: [
        {
          stops: moveToRows.map(row => row.id),
          serviceDate: formatDateToBEFormatDateFns(serviceDate),
          updateMasterSequence: false,
        },
      ],
    })
      .unwrap()
      .then(() => {
        onReset(false)
        dispatch(setBulkUnassignStopsState({ isOpen: false }))
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS]))
      })
      .catch(handleError)
  }

  return (
    <DialogActions sx={{ flex: '0 0 48px' }}>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1}>
        <Button size="small" variant="outlined" color="warning" onClick={onReset}>
          {T.RESET}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
        <CancelButton size="small" onClick={onClose} />
        <Tooltip PopperProps={{ sx: { zIndex: theme.zIndex.intercom + 1 } }} arrow title={submitTitle}>
          <span>
            <SaveButton
              size="small"
              loading={isLoading}
              disabled={isSaveButtonDisabled}
              label={T.SAVE_CURRENT_DAY}
              onClick={handleSubmit(onValidSubmit)}
            />
          </span>
        </Tooltip>
      </Stack>
    </DialogActions>
  )
}

BulkUnassignStopsDialogActions.propTypes = {
  onReset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(BulkUnassignStopsDialogActions)
