import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, LinearProgress, Tabs, Tab } from '@mui/material'

import { get } from 'utils/lodash'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'

import TabPanel from 'components/common/TabPanel'
import AgingBucketContent from '../AgingBucketContent'
import DetailsTab from './DetailsTab'
import PaymentsTab from './PaymentsTab'
import InvoicesTab from './InvoicesTab'
import { TABS_ID } from './settings'

const { ACCOUNT_DETAILS_TAB_ID, PAYMENTS_TAB_ID, INVOICES_TAB_ID } = TABS_ID
const GET_CACHED_DATA = false

const DetailPanelContent = ({ row = {}, isViewOnly = false }) => {
  const [selectedTab, setSelectedTab] = useState(ACCOUNT_DETAILS_TAB_ID)
  const { accountId } = row
  const [getAccountDetails, { data: accountData, isFetching: isGetAccountDetailsLoading }] = useLazyGetAccountDetailsQuery()

  const summaryData = get(accountData, 'balanceDetails', {})

  const handleTabChange = (event, newValue) => setSelectedTab(newValue)

  useEffect(() => {
    getAccountDetails({ accountId }, GET_CACHED_DATA)
  }, [])

  return (
    <Box ml={7.5} mr={2} mb={2}>
      <AgingBucketContent my={1} accountId={accountId} {...summaryData} isInteractive={!isViewOnly} />
      <Box>
        <Tabs value={selectedTab} onChange={handleTabChange} sx={{ button: { textTransform: 'capitalize', minWidth: '33%' } }}>
          <Tab value={ACCOUNT_DETAILS_TAB_ID} label="Details" />
          <Tab value={PAYMENTS_TAB_ID} label="Payments" />
          <Tab value={INVOICES_TAB_ID} label="Invoices" />
        </Tabs>
      </Box>
      {isGetAccountDetailsLoading && <LinearProgress sx={{ mt: -0.5 }} />}
      <Box mt={2}>
        <TabPanel value={selectedTab} index={ACCOUNT_DETAILS_TAB_ID}>
          <DetailsTab isViewOnly={isViewOnly} selectedTab={selectedTab} accountId={accountId} accountData={accountData} />
        </TabPanel>
        <TabPanel value={selectedTab} index={PAYMENTS_TAB_ID}>
          <PaymentsTab isViewOnly={isViewOnly} selectedTab={selectedTab} accountId={accountId} accountData={accountData} />
        </TabPanel>
        <TabPanel value={selectedTab} index={INVOICES_TAB_ID}>
          <InvoicesTab isViewOnly={isViewOnly} selectedTab={selectedTab} accountId={accountId} accountData={accountData} />
        </TabPanel>
      </Box>
    </Box>
  )
}

DetailPanelContent.propTypes = {
  row: PropTypes.object,
  isViewOnly: PropTypes.bool,
}

export default DetailPanelContent
