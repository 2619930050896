import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Alert } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { PAYMENT_STATUS } from 'settings/constants/payment'

import T from 'T'
import PaymentInfoBlock from '../common/PaymentInfoBlock'
import { getTransferFromUpdatedInvoices } from '../transfer-payments/helper'

const { FAILED } = PAYMENT_STATUS

const ACHFailedDialogContent = ({ transactionAmountCents = 0, payment = {}, paidInvoices = [], sourceAccountDetails = {} }) => {
  const updatedPaidInvoices = getTransferFromUpdatedInvoices(paidInvoices, transactionAmountCents)

  return (
    <>
      <Box my={2} display="flex" alignItems="center">
        <RemoveCircleIcon fontSize="small" color="textSecondary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.PREVIOUS_STATE}:`}
        </Typography>
      </Box>
      <PaymentInfoBlock
        payment={{ ...payment, paymentAppliedAmountCents: payment?.transactionAmountCents }}
        paidInvoices={paidInvoices}
        sourceAccountDetails={sourceAccountDetails}
      />

      <Alert sx={{ my: 2, display: 'flex', alignItems: 'center' }} severity="warning">
        Marking this payment as failed will change the payment status of the following invoices
      </Alert>

      <Box my={2} display="flex" alignItems="center">
        <AddCircleIcon fontSize="small" color="primary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.UPDATED_STATE}:`}
        </Typography>
      </Box>

      <PaymentInfoBlock
        payment={{ ...payment, transactionStatus: FAILED, transactionPostedDate: null, paymentAppliedAmountCents: 0 }}
        paidInvoices={updatedPaidInvoices.map(invoice => ({ ...invoice, paymentAppliedAmountCents: 0 }))}
        sourceAccountDetails={sourceAccountDetails}
      />
    </>
  )
}

ACHFailedDialogContent.propTypes = {
  transactionAmountCents: PropTypes.number,
  payment: PropTypes.object,
  paidInvoices: PropTypes.array,
  sourceAccountDetails: PropTypes.object,
}

export default ACHFailedDialogContent
