import React, { useCallback } from 'react'
import { Box, Collapse, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsCustomGroupViewOpen, setIsCustomGroupViewOpen } from 'slices/customer/suspensionGroupsSlice'
import SuspendedAccountsStatsCard from 'components/customers/groups/suspension/SuspendedAccountsStatsCard'

const SuspensionGroupsCustomView = () => {
  const isExpanded = useSelector(selectIsCustomGroupViewOpen)
  const dispatch = useDispatch()
  const toggleIsCustomGroupOpen = useCallback(() => {
    dispatch(setIsCustomGroupViewOpen(!isExpanded))
  }, [isExpanded])

  return (
    <Box px={3} pt={2} pb={1} width="100%">
      <ListItem
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <ListItemIcon>
          <IconButton size="small" onClick={toggleIsCustomGroupOpen}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemIcon>
        <ListItemText primary="Suspended" primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }} />
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box mt={4}>
          <SuspendedAccountsStatsCard id="suspended" />
        </Box>
      </Collapse>
    </Box>
  )
}

SuspensionGroupsCustomView.propTypes = {}

export default SuspensionGroupsCustomView
