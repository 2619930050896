import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    reversePayment: build.mutation({
      query: ({ paymentNumber, force }) => ({ url: `/api/v1/billing/payment/${paymentNumber}/reverse`, method: 'POST', body: { force } }),
    }),
  }),
})

export const { useReversePaymentMutation } = extendedApi
