import React, { memo, useEffect, useState } from 'react'
import { CircularProgress, List, Paper, Popper, Stack } from '@mui/material'
import { get, groupBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { grey } from '@mui/material/colors'
import { format } from 'date-fns-tz'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { useLazyGetAccountRouteStopsQuery } from 'api/accounts/accountRouting'
import { setRoutingTooltipCacheByAccountId } from 'slices/customer/customerDetailsSlice'
import { combineAddressInfo } from 'utils/helper'
import { isValidHex } from 'utils/validations'
import useTheme from '@mui/material/styles/useTheme'
import debounce from 'lodash/debounce'
import { FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS } from 'settings/constants/date'
import TooltipListItem from './TooltipListItem'
import { RECURRENCE_TO_HEATMAP_VALUE, HEATMAP_VALUE_TO_RECURRENCE } from '../utils'
import TooltipList from './TooltipList'

const formatRawStops = (rawStops = []) => {
  const stops = rawStops.map(rawStop => {
    const workOrderId = get(rawStop, 'workOrderId')
    const serviceName = get(rawStop, 'serviceName')
    const addressStr = combineAddressInfo(rawStop)
    const routeName = get(rawStop, 'routeName')
    const serviceType = get(rawStop, 'serviceType')
    const sequence = get(rawStop, 'sequence')
    const stopCount = get(rawStop, 'stopCount')
    return {
      workOrderId,
      id: workOrderId,
      addressStr,
      serviceName,
      routeName,
      serviceType,
      sequence,
      stopCount,
    }
  })
  const groupedStops = groupBy(stops, 'serviceType')
  return {
    stopsCount: get(stops, 'length', 0),
    groupedStops,
  }
}

const RenderTooltip = params => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const date = get(params, 'date')
  const formattedDate = formatDateToBEFormatDateFns(date)
  const dateFEFormat = format(date, FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS)
  const color = get(params, 'color')
  const validColor = isValidHex(color) ? color : grey[100]
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const accountId = get(customerDetails, 'id')
  const tooltipStopCacheByAccountId = useSelector(state => get(state, 'CustomerDetails.routing.tooltipStopCacheByAccountId', {}))
  const accountTooltipStopCacheByDate = get(tooltipStopCacheByAccountId, accountId, {})
  const [getAccountRouteStops] = useLazyGetAccountRouteStopsQuery()
  const [loading, setLoading] = useState(true)
  const tooltipData = get(accountTooltipStopCacheByDate, formattedDate, {})
  const groupedStops = get(tooltipData, 'groupedStops', {})
  const stopsCount = get(tooltipData, 'stopsCount', 0)
  const recurringStops = get(groupedStops, 'Recurring', [])
  const onRequestStops = get(groupedStops, 'On Request', [])
  const recurrenceColor = get(HEATMAP_VALUE_TO_RECURRENCE[RECURRENCE_TO_HEATMAP_VALUE.RECURRING], 'color')
  const onRequestColor = get(HEATMAP_VALUE_TO_RECURRENCE[RECURRENCE_TO_HEATMAP_VALUE.ON_REQUEST], 'color')
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  useEffect(() => {
    setLoading(true)
    const delayedGetAccountRouteStops = debounce(() => {
      if (isEmpty(tooltipData)) {
        getAccountRouteStops({
          accountId,
          serviceDate: formattedDate,
        })
          .unwrap()
          .then(d => {
            const stops = get(d, 'stops', [])
            const formattedData = formatRawStops(stops)
            dispatch(
              setRoutingTooltipCacheByAccountId({
                [accountId]: {
                  ...accountTooltipStopCacheByDate,
                  [formattedDate]: formattedData,
                },
              })
            )
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    }, 300) // Adjust the delay as needed

    delayedGetAccountRouteStops()

    return () => {
      delayedGetAccountRouteStops.cancel() // Cancel any pending API calls on unmount
    }
  }, [date])

  useEffect(() => {
    const x = get(params, 'x', get(params, 'coordinates.x'))
    const y = get(params, 'y', get(params, 'coordinates.y'))
    // get nivo rect to position popper properly
    const selector = `rect[x='${x}'][y='${y}']`
    const square = document.querySelector(selector)
    setAnchorEl(square)
    return () => {
      setAnchorEl(null)
    }
  }, [params])

  return (
    <Popper
      sx={{ zIndex: theme.zIndex.tooltip }}
      placement="bottom"
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
      id={id}
      open={open}
      anchorEl={anchorEl}
    >
      <Paper sx={{ minWidth: '300px', maxWidth: '450px' }}>
        {!loading && (
          <List disablePadding>
            <TooltipListItem color={validColor} primaryLeft={dateFEFormat} primaryRight={`${stopsCount} stops`} />
            <TooltipList stops={recurringStops} title="Recurring" color={recurrenceColor} />
            <TooltipList stops={onRequestStops} title="On-request" color={onRequestColor} />
          </List>
        )}
        {loading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
      </Paper>
    </Popper>
  )
}

RenderTooltip.propTypes = {}

export default memo(RenderTooltip)
