import React, { useCallback, useEffect, useState } from 'react'
import lowerCase from 'lodash/lowerCase'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams, useLocation } from 'react-router-dom-v5-compat'

import { Box } from '@mui/material'

import { get } from 'utils/lodash'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { onChangeLocationId } from 'slices/customer/customerDetailsSlice'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import TabPanel from 'components/common/TabPanel'
import NavbarWrapper from 'components/common/NavbarWrapper'
import AccountTab from 'components/customer-details/account'
import ServicesTab from 'components/customer-details/services'
import BillingTab from 'components/customer-details/billing'

import './style.scss'
import Header from 'components/customer-details/layout/Header'
import { CUSTOMER_DETAILS_TABS_ID, CUSTOMER_DETAILS_TABS_ID_LIST } from 'containers/customer-details/settings'
import FeesTab from 'components/customer-details/fees'

const { ACCOUNT_TAB_ID, SERVICES_TAB_ID, BILLING_TAB_ID, FEES_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const CustomerDetails = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [getAccountMeta] = useLazyGetAccountMetaQuery()

  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const currentTab = useSelector(s => get(s, 'CustomerDetails.currentTab', ACCOUNT_TAB_ID))
  // This param is important to avoid duplicate API calls
  const [isPageReady, setIsPageReady] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const { accountId } = useParams()
  const urlTabId = lowerCase(searchParams.get('tab') || '')
  const urlLocationId = searchParams.get('location') || ''

  const locations = get(customerDetails, 'locations', [])
  const newAccountId = get(customerDetails, 'id')

  const handleLocationChange = useCallback(
    (id = '', isInitialLoad = false, allLocations = locations) => {
      const isAccountType = !id || id === accountId || !allLocations.find(loc => loc.id === id && !loc?.deactivated)

      // Reset location dropdown
      dispatch(onChangeLocationId(isAccountType ? '' : id))
      setIsPageReady(true)

      if (!CUSTOMER_DETAILS_TABS_ID_LIST.includes(urlTabId) && isInitialLoad) {
        searchParams.set('tab', currentTab)
        setSearchParams(searchParams, { replace: true, state: get(location, 'state') })
      }
      if (isAccountType) {
        searchParams.delete('location')
      } else {
        searchParams.set('location', id)
      }
      setSearchParams(searchParams, { replace: true, state: get(location, 'state') })
    },
    [searchParams, currentTab, newAccountId]
  )

  const handleCustomerDetailsSuccess = useCallback(
    account => {
      let locationId = urlLocationId
      const allLocations = get(account, 'locations', [])
      const activeLocations = allLocations.filter(loc => !loc?.deactivated)

      // If location id is not present in the url and account has single location then pre select
      if (!locationId && activeLocations.length === 1) {
        locationId = get(activeLocations, '[0].id')
        if (locationId) {
          searchParams.set('location', locationId)
        }
        setSearchParams(searchParams, { replace: true, state: get(location, 'state') })
      }

      handleLocationChange(locationId || accountId, true, allLocations)
    },
    [accountId, handleLocationChange, searchParams, urlLocationId, urlTabId]
  )

  useEffect(() => {
    setIsPageReady(false)
    // Call customer details API
    dispatch(getCustomerDetails({ accountId, isInitialCall: true, addRecent: true }, handleCustomerDetailsSuccess))
    // Call customer meta, used by inner components
    getAccountMeta()
  }, [accountId])

  useEffect(() => {
    if (urlTabId && isPageReady) {
      handleLocationChange(urlLocationId || accountId)
    }
  }, [urlTabId, isPageReady])

  return (
    <ProtectedRouteHoC>
      <NavbarWrapper innerSx={{ overflow: { lg: 'hidden' } }}>
        <Box className="customer-details-container">
          <Header isPageReady={isPageReady} accountId={accountId} />
          {isPageReady && (
            <>
              <TabPanel value={urlTabId} index={ACCOUNT_TAB_ID} sx={{ p: 0 }}>
                <AccountTab selectedTab={urlTabId} customerDetails={customerDetails} onLocationChange={handleLocationChange} />
              </TabPanel>
              <TabPanel value={urlTabId} index={SERVICES_TAB_ID} sx={{ p: 0 }}>
                <ServicesTab selectedTab={urlTabId} customerDetails={customerDetails} onLocationChange={handleLocationChange} />
              </TabPanel>
              <TabPanel value={urlTabId} index={BILLING_TAB_ID} sx={{ p: 0 }}>
                <BillingTab selectedTab={urlTabId} customerDetails={customerDetails} />
              </TabPanel>
              <TabPanel value={urlTabId} index={FEES_TAB_ID} sx={{ p: 0 }}>
                <FeesTab selectedTab={urlTabId} customerDetails={customerDetails} />
              </TabPanel>
            </>
          )}
        </Box>
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

export default CustomerDetails
