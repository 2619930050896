import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateServiceGroup: builder.mutation({
      query: body => ({ url: `/api/v1/core/priced-service-groups/${body.id}`, method: 'PATCH', body }),
    }),
  }),
})

export const { useUpdateServiceGroupMutation } = extendedApi
