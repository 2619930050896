import { useEffect } from 'react'

import BillingActive from 'assets/switch/active/Billing.svg'
import BillingDeactivated from 'assets/switch/deactivated/Billing.svg'

import ServiceActive from 'assets/switch/active/Service.svg'
import ServiceDeactivated from 'assets/switch/deactivated/Service.svg'

import AccountStatusActive from 'assets/switch/active/AccountStatus.svg'
import AccountStatusDeactivated from 'assets/switch/deactivated/AccountStatus.svg'

import UnscheduledActive from 'assets/switch/active/Unscheduled.svg'
import UnscheduledDeactivated from 'assets/switch/deactivated/Unscheduled.svg'

import TemporaryModeActive from 'assets/switch/active/TemporaryMode.svg'
import TemporaryModeDeactivated from 'assets/switch/deactivated/TemporaryMode.svg'

import DriverPathActive from 'assets/switch/active/DriverPath.svg'
import DriverPathDeactivated from 'assets/switch/deactivated/DriverPath.svg'

import SequenceLineActive from 'assets/switch/active/SequenceLine.svg'
import SequenceLineDeactivated from 'assets/switch/deactivated/SequenceLine.svg'

const PreLoadImages = () => {
  useEffect(() => {
    ;[
      BillingActive,
      BillingDeactivated,
      ServiceActive,
      ServiceDeactivated,
      AccountStatusActive,
      AccountStatusDeactivated,
      UnscheduledActive,
      UnscheduledDeactivated,
      TemporaryModeActive,
      TemporaryModeDeactivated,
      DriverPathActive,
      DriverPathDeactivated,
      SequenceLineActive,
      SequenceLineDeactivated,
    ].forEach(image => {
      new Image().src = image
    })
  }, [])

  return null
}

export default PreLoadImages
