import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'

import { Box, Popover, Divider, Typography } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { useLazyGetAccountContactsQuery } from 'api/accounts/getAccountContacts'

import ViewContacts from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContacts'

const MAX_CONTACTS = 5

const ViewContactsPopover = ({ anchorEl, accountId, onClose }) => {
  const isOpen = Boolean(anchorEl)
  const [getAccountContacts, { isFetching, data }] = useLazyGetAccountContactsQuery()
  const contacts = useMemo(() => {
    const contactsByAccount = get(data, 'contactsByAccount', {})
    return uniqBy(get(contactsByAccount, accountId, []), 'id').filter(({ deactivated }) => !deactivated)
  }, [data, accountId])

  const slicedContacts = useMemo(() => {
    return contacts.slice(0, MAX_CONTACTS)
  }, [contacts])

  const extraContacts = useMemo(() => {
    return contacts.length - MAX_CONTACTS
  }, [contacts])

  useEffect(() => {
    if (isOpen && accountId) {
      getAccountContacts({ accountIds: [accountId] })
        .unwrap()
        .catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <Popover
      sx={{ pointerEvents: 'none', minWidth: 'fit-content', maxWidth: '100%', mr: 2 }}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box>
        <ViewContacts loading={isFetching} contacts={slicedContacts} />
        {!isFetching && extraContacts > 0 && (
          <>
            <Divider />
            <Typography m={2} variant="caption" display="flex" justifyContent="end">
              {`+${extraContacts} more`}
            </Typography>
          </>
        )}
      </Box>
    </Popover>
  )
}

ViewContactsPopover.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default memo(ViewContactsPopover)
