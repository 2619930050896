import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAllCSNotesQuery } from 'api/notes/configuredServiceNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CSNotes from './CSNotes'
import AddUpdateCSNoteDialog from './AddUpdateCSNoteDialog'
import NoteSkeleton from '../common/NoteSkeleton'

const CSNotesDialogVariant = ({ isOpen = false, configuredServiceId = '', getCachedData = false, onClose }) => {
  const [getAllCSNotes, { isFetching, isLoading, data: notesData }] = useLazyGetAllCSNotesQuery()
  const isGetAllNotesLoading = isFetching || isLoading
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddWONote = () => {
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleCloseAddWONote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (isOpen && configuredServiceId) {
      getAllCSNotes({ id: configuredServiceId }, getCachedData).unwrap().catch(handleError)
    }
  }, [isOpen, configuredServiceId])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={`${T.CONFIGURED_SERVICE_NOTE}s`} onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          {isGetAllNotesLoading && <NoteSkeleton rows={1} />}
          {!isGetAllNotesLoading && (
            <CSNotes
              itemSx={{
                [`.MuiListItemSecondaryAction-root`]: {
                  right: 12,
                },
              }}
              notes={notes}
              configuredServiceId={configuredServiceId}
            />
          )}
        </DialogContent>
        <HHDialogActions>
          <Button variant="outlined" onClick={handleOpenAddWONote}>
            {T.CREATE_NOTE}
          </Button>
          <Button variant="contained" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <AddUpdateCSNoteDialog
        id={configuredServiceId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_CONFIGURED_SERVICE_NOTE}
        onClose={handleCloseAddWONote}
      />
    </>
  )
}

CSNotesDialogVariant.propTypes = {
  isOpen: PropTypes.bool,
  configuredServiceId: PropTypes.string,
  getCachedData: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default CSNotesDialogVariant
