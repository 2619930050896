import { CACHE_TAG_ACCOUNT_INVOICE_LIST } from 'api/cacheTagTypes'
import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getBillingInvoiceListByAccount: builder.query({
      query: ({ accountId, status, page, size }) => ({
        url: `/api/v1/billing/invoice/account/${accountId}`,
        params: { status, page, size },
      }),
    }),
    getAgingInvoiceListByAccount: builder.query({
      query: ({ accountId, aging }) => ({
        url: `/api/v1/core/accounts/${accountId}/invoices-by-aging`,
        params: { aging },
      }),
      providesTags: [CACHE_TAG_ACCOUNT_INVOICE_LIST],
    }),
    getAgingInvoicePreviewListByAccount: builder.query({
      query: ({ accountId, aging }) => ({
        url: `/api/v1/core/accounts/${accountId}/invoices-by-aging/preview`,
        params: { aging },
      }),
      providesTags: [CACHE_TAG_ACCOUNT_INVOICE_LIST],
    }),
  }),
})

export const {
  useLazyGetBillingInvoiceListByAccountQuery,
  useLazyGetAgingInvoiceListByAccountQuery,
  useLazyGetAgingInvoicePreviewListByAccountQuery,
} = extendedApi
