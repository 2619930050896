import { capitalize } from 'lodash'

export const generateFilterLabel = (allData, selectedData, label) => {
  const allDataCount = allData.length
  const selectedDataCount = selectedData.length
  const allSelected = selectedDataCount === allDataCount && allDataCount > 0

  if (allSelected) {
    return `All ${label}s`
  }

  if (selectedDataCount === 0) {
    return `No ${label}`
  }

  if (selectedDataCount > 1) {
    return `${capitalize(label)}s`
  }

  return capitalize(label)
}
