import T from 'T'
import round from 'lodash/round'

import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { getOrdinal } from 'utils/day'
import { formatCentsToDollars } from 'utils/price'
import { getPricingPeriod, isPriceTypeDollar } from 'utils/service'
import { getFirstMonthsScheduleOptions, getQuarterlyScheduleOptions } from 'utils/billingSchedule'
import { BILLING_CYCLES } from 'settings/constants/billing'
import { isString } from 'utils/string'

const { QUARTER } = BILLING_CYCLES

const DEFAULT_ROUND = 2
const DEFAULT_STRING_VALUE = '-'
const DEFAULT_NUMBER_VALUE = 0
const billingProfileKey = 'accountBilling.billingProfile'
const LAST_DAY_TEXT = 'Last Day of Month'

// Empty string is a valid case
export const isTaxExempt = taxExempt => isString(taxExempt) || taxExempt

const selectAccountName = customer => get(customer, 'accountName', '')
const selectProfileName = customer => get(customer, `${billingProfileKey}.profile`, 'Custom')
const selectParentBillingProfileId = customer => get(customer, `${billingProfileKey}.parentBillingProfileId`, '')
const selectCycleInterval = customer => get(customer, `${billingProfileKey}.billingCycle.interval`)
const selectCycleIntervalType = customer => get(customer, `${billingProfileKey}.billingCycle.intervalType`)
const selectNextBillingDate = customer => get(customer, 'accountBilling.nextBillingDate', DEFAULT_STRING_VALUE)
const selectPaymentType = customer => get(customer, `${billingProfileKey}.paymentType`, DEFAULT_STRING_VALUE)
const selectTiming = customer => get(customer, `${billingProfileKey}.timing`, DEFAULT_STRING_VALUE)
const selectTimingDay = customer => get(customer, `${billingProfileKey}.timingDay`, '')
const selectTimingWeek = customer => get(customer, `${billingProfileKey}.timingWeek`, '')
const selectTimingQuarter = customer => get(customer, `${billingProfileKey}.timingQuarter`, '')
const selectBillingMonths = customer => get(customer, `${billingProfileKey}.billingMonths`, '')
const selectBalance = customer => formatCentsToDollars(get(customer, 'balanceDetails.balanceCents', DEFAULT_NUMBER_VALUE))
const selectCreditLimit = customer => get(customer, `${billingProfileKey}.creditLimit`, DEFAULT_NUMBER_VALUE)
const selectAvailableCredit = customer => get(customer, 'availableCredit', DEFAULT_NUMBER_VALUE)
const selectLateFeeType = customer => get(customer, `${billingProfileKey}.lateFeeType`, DEFAULT_NUMBER_VALUE)
const selectLateFeeAmount = customer => get(customer, `${billingProfileKey}.lateFeeAmount`, DEFAULT_NUMBER_VALUE)
const selectLateFeeThreshold = customer => get(customer, `${billingProfileKey}.lateFeeThreshold`, DEFAULT_NUMBER_VALUE)
const selectLateFeeGracePeriod = customer => get(customer, `${billingProfileKey}.lateFeeGracePeriod`, DEFAULT_NUMBER_VALUE)
const selectTerm = customer => get(customer, `${billingProfileKey}.paymentTerm.term`, DEFAULT_STRING_VALUE)
const selectTermInterval = customer => get(customer, `${billingProfileKey}.paymentTerm.interval`, DEFAULT_NUMBER_VALUE)
const selectDeliveryMethod = customer => get(customer, `${billingProfileKey}.deliveryMethod`, '')
const selectTaxExempt = customer => customer?.accountBilling?.taxExempt

const selectAccountBalance = account => get(account, 'balance', DEFAULT_NUMBER_VALUE)
const selectAccountCreditLimit = account => get(account, 'creditLimit', DEFAULT_NUMBER_VALUE)

export const getAvailableCredit = createSelector(
  [selectAccountBalance, selectAccountCreditLimit, state => state?.isRoundingOn],
  (balance, creditLimit, isRoundingOn) => {
    const availableCredit = Math.max(0, creditLimit - balance)
    // We are using <NumericFormat /> which has inbuilt rounding prop, In most of the cases this is not required
    return isRoundingOn ? round(availableCredit, DEFAULT_ROUND) : availableCredit
  }
)

export const getFirstMonths = createSelector(
  [state => state?.cycleInterval, state => state?.cycleIntervalType, state => state?.timingQuarter, state => state?.billingMonths],
  (cycleInterval, cycleIntervalType, timingQuarter, billingMonths) => {
    if (cycleIntervalType === QUARTER) {
      const quarterlyScheduleOptions = getQuarterlyScheduleOptions({ intervalType: cycleIntervalType, interval: cycleInterval })
      return get(
        quarterlyScheduleOptions.find(option => option.key === timingQuarter),
        'value',
        ''
      )
    }

    // Month, Year options
    const firstMonthsOptions = getFirstMonthsScheduleOptions({ intervalType: cycleIntervalType, interval: cycleInterval })
    if (firstMonthsOptions.length > 0) {
      return get(
        firstMonthsOptions.find(option => option.key === billingMonths),
        'value',
        ''
      )
    }

    return ''
  }
)

export const getTiming = createSelector(
  [state => state?.timing, state => state?.timingDay, state => state?.timingWeek, state => state?.cycleIntervalType],
  (timing, day, week, cycleIntervalType) => {
    // Check for cycle
    if (cycleIntervalType === T.MANUAL) {
      return DEFAULT_STRING_VALUE
    }
    if (typeof cycleIntervalType === 'string' && cycleIntervalType.includes(T.YEAR)) {
      return timing === T.ANNIVERSARY_LABEL ? T.ANNIVERSARY_VALUE : DEFAULT_STRING_VALUE
    }

    // Check for timing
    if (timing === T.ANNIVERSARY_LABEL) {
      return T.ANNIVERSARY_VALUE
    }
    if (timing === T.DAY && day) {
      return day === T.LAST ? LAST_DAY_TEXT : day + getOrdinal(day)
    }
    if (timing === T.DAY_OF_WEEK && week) {
      return week
    }
    return DEFAULT_STRING_VALUE
  }
)

export const getCustomerBillingDetails = createSelector(
  [
    selectAccountName,
    selectProfileName,
    selectParentBillingProfileId,
    selectCycleInterval,
    selectCycleIntervalType,
    selectNextBillingDate,
    selectPaymentType,
    selectTiming,
    selectTimingDay,
    selectTimingWeek,
    selectTimingQuarter,
    selectBillingMonths,
    selectBalance,
    selectCreditLimit,
    selectAvailableCredit,
    selectLateFeeType,
    selectLateFeeAmount,
    selectLateFeeThreshold,
    selectLateFeeGracePeriod,
    selectTerm,
    selectTermInterval,
    selectDeliveryMethod,
    selectTaxExempt,
  ],
  (
    accountName,
    profileName,
    parentBillingProfileId,
    cycleInterval,
    cycleIntervalType,
    nextBillingDate,
    paymentType,
    timing,
    timingDay,
    timingWeek,
    timingQuarter,
    billingMonths,
    balance,
    creditLimit,
    availableCredit,
    lateFeeType,
    lateFeeAmount,
    lateFeeThreshold,
    lateFeeGracePeriod,
    term,
    termInterval,
    deliveryMethod,
    taxExempt
  ) => ({
    profileName: parentBillingProfileId ? `${profileName} (${accountName})` : profileName,
    parentBillingProfileId,
    billingCycle: getPricingPeriod(cycleInterval, cycleIntervalType),
    nextBillingDate,
    paymentType,
    timing: getTiming({ timing, timingDay, timingWeek, cycleIntervalType }),
    firstMonths: getFirstMonths({ cycleInterval, cycleIntervalType, timingQuarter, billingMonths }),
    balance,
    creditLimit,
    availableCredit,
    creditLeft: getAvailableCredit({ balance, creditLimit }),
    isLateFeeTypeDollar: isPriceTypeDollar(lateFeeType),
    lateFeeAmount,
    lateFeeThreshold,
    lateFeeGracePeriod: `${lateFeeGracePeriod} day${lateFeeGracePeriod > 1 ? 's' : ''}`,
    term,
    termInterval,
    paymentTerms: term.concat(termInterval > 0 ? ` ${termInterval}` : ''),
    deliveryMethod: isString(deliveryMethod) ? deliveryMethod.replaceAll(',', ', ') : '',
    taxExempt: isTaxExempt(taxExempt) ? 'Exempt' : 'Not Exempt',
  })
)
