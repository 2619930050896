/* eslint-disable */
console.log(`Mode => ${process.env.REACT_APP_MODE}`)
const Constants = {
  CAN_UPDATE_USER: process.env.REACT_APP_CAN_UPDATE_USER?.toLowerCase() === 'true',
  CAN_SEE_REPORTS: process.env.REACT_APP_CAN_SEE_REPORTS?.toLowerCase() === 'true',
  BUSINESS_IDENTIFIER: 'HH-Business-Identifier',
  AUTH_TENANT_ID: process.env.REACT_APP_AUTH_TENANT_ID,
  SERVER_URL: process.env.REACT_APP_BACKEND_API_PRIVATE_BASE_URL,
  SERVER_PUBLIC_URL: process.env.REACT_APP_BACKEND_API_PUBLIC_BASE_URL,
  PORTAL_URL: process.env.REACT_APP_PORTAL_BASE_URL,
  MEDIA_SERVER: 'https://media.haulerhero.com/',
  AUTH_SERVER: process.env.REACT_APP_AUTH_SERVER,
  DEV_MODE: process.env.REACT_APP_MODE === 'dev',
  REDIRECT_KEY: 'redirectPath',
  STORAGE_PATH: 'https://storage.googleapis.com/',
  PUBLIC_ASSETS_BUCKET_NAME: process.env.REACT_APP_PUBLIC_ASSETS_BUCKET_NAME,
  ASSETS_BUCKET_NAME: process.env.REACT_APP_ASSETS_BUCKET_NAME,
  IMAGE_UPLOADS_PRIVATE_BUCKET_NAME: process.env.REACT_APP_IMAGE_UPLOADS_PRIVATE_BUCKET_NAME,
  LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  MUI_X_LICENSE_KEY: process.env.REACT_APP_MUI_X_LICENSE_KEY,
  INTERCOM_APP_ID: 'mue7bvdy',
  BEAMER_APP_ID: 'fHKosAwX55221',
  LOGROCKET_ID: process.env.REACT_APP_LOGROCKET_ID,
  SUPPORT_EMAIL: 'support@haulerhero.com',
  SUPPORT_WEBSITE: 'https://www.haulerhero.com',
  PRIVACY_POLICY_PAGE: 'https://www.haulerhero.com/terms',
}

export default Constants
