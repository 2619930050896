import React from 'react'
import { Typography, Grid, styled, Box, Tooltip } from '@mui/material'
import { DAY_SHORT_FORM } from 'settings/constants/day'
import { get, capitalize } from 'lodash'
import { XCircle as UnstyledXCircle } from '@styled-icons/heroicons-outline/XCircle'
import { CheckCircle as UnstyledCheckCircle } from '@styled-icons/heroicons-solid/CheckCircle'
import moment from 'moment'
import PropTypes from 'prop-types'
import Constants from 'Constants'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import useTheme from '@mui/material/styles/useTheme'
import { replaceAllSpacesAndAmpersand } from '../../../../utils/string'

const { MEDIA_SERVER } = Constants

const XCircle = styled(UnstyledXCircle)(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.palette.text.secondary,
}))

const CheckCircle = styled(UnstyledCheckCircle)(({ theme }) => ({
  width: 16,
  height: 16,
  color: theme.palette.text.secondary,
}))

const Icon = styled('img')`
  width: 16px;
  height: 16px;
`

const ScheduleItem = ({ event, align, justifyContent = 'flex-start', alignItems = 'center' }) => {
  const date = get(event, 'date', undefined)
  const actionName = get(event, 'action', '').replace(' ', '')
  const routeName = get(event, 'route.name') ? `#${get(event, 'route.name')}` : ''
  const workOrderStatus = get(event, 'workOrderStatus', '')
  const dayOfTheWeek = get(event, 'dayOfTheWeek', '')
  const { watch } = useFormContext()
  const upcoming = watch('upcoming')
  const theme = useTheme()

  if (!event || (!date && upcoming)) {
    return <Grid item xs />
  }
  return (
    <Grid item xs container columnGap={0.5} justifyContent={justifyContent} alignItems={alignItems} wrap="nowrap">
      <Grid item xs="auto" zeroMinWidth>
        <Typography variant="subtitle1" align={align} noWrap>
          {upcoming && date && moment(date).format('M/D')}
          {!upcoming && DAY_SHORT_FORM[capitalize(dayOfTheWeek)]}
        </Typography>
      </Grid>
      {routeName && (
        <Grid item xs zeroMinWidth>
          <Tooltip title={routeName}>
            <Box
              sx={{
                ...theme.typography.subtitle1,
                fontWeight: 'normal',
                height: '15px',
                overflow: 'hidden',
              }}
            >
              {routeName}
            </Box>
          </Tooltip>
        </Grid>
      )}
      {workOrderStatus === T.SERVICED ? <CheckCircle /> : <XCircle />}
      {actionName && (
        <Grid item zeroMinWidth container justifyContent="center" alignItems="center" xs="auto">
          <Icon src={`${MEDIA_SERVER}Action/${replaceAllSpacesAndAmpersand(actionName)}.svg`} alt="" />
        </Grid>
      )}
    </Grid>
  )
}

ScheduleItem.propTypes = {
  event: PropTypes.object,
  align: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
}

export default ScheduleItem
