import React, { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { shallowEqual, useSelector } from 'react-redux'
import { useLazyGetBillingInvoiceRevenueQuery } from '../../../../api/billing/getBillingInvoiceRevenue'
import { get } from '../../../../utils/lodash'
import BillingSecondaryNavBar from '../../common/BillingSecondaryNavBar'
import T from '../../../../T'

const SecondaryNavbar = () => {
  const [getBillingInvoiceRevenue, { data, isError }] = useLazyGetBillingInvoiceRevenueQuery()
  const rawSearchFilters = useSelector(state => state.invoices.filters.searchFilters, shallowEqual)
  const selectionModel = useSelector(state => state.invoices.selectionModel, shallowEqual)
  const customers = !isError && Array.isArray(selectionModel) && selectionModel.length > 0 ? get(data, 'accountCount', 0) : '-'
  const revenue = !isError && Array.isArray(selectionModel) && selectionModel.length > 0 ? get(data, 'totalRevenueCents', 0) : '-'
  const searchFilters = isEmpty(rawSearchFilters) ? [] : rawSearchFilters

  useEffect(() => {
    if (Array.isArray(selectionModel) && selectionModel.length > 0) {
      getBillingInvoiceRevenue({
        searchFilters,
        invoiceNumbers: selectionModel,
      })
    }
  }, [selectionModel])

  return <BillingSecondaryNavBar heading={T.INVOICES} showRevenueCard customers={customers} revenue={revenue} />
}

SecondaryNavbar.propTypes = {}

export default SecondaryNavbar
