import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import LocationOff from '@mui/icons-material/LocationOff'

const MapPlaceholder = ({ sx = {} }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100%"
    border="1px solid"
    borderColor="divider"
    bgcolor={grey[100]}
    sx={{ ...sx }}
  >
    <LocationOff sx={{ width: 72, height: 72, color: 'rgba(0, 0, 0, 0.54)' }} />
  </Box>
)

MapPlaceholder.propTypes = {
  sx: PropTypes.object,
}

export default MapPlaceholder
