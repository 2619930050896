import React from 'react'
import PropTypes from 'prop-types'

import { formatDateToFEFormat } from 'utils/date'
import SearchOptionRow from './SearchOptionRow'

const SearchInvoiceOptionRow = ({ row = {} }) => {
  const { accountName, accountNumber, invoiceNumber, dueDate, totalCents = 0, amountPaidCents = 0 } = row
  const formattedDueDate = dueDate ? formatDateToFEFormat(dueDate) : '-'

  return (
    <SearchOptionRow
      leftPrimaryText={invoiceNumber}
      leftSecondaryText={`${accountNumber} | ${accountName}`}
      rightPrimaryText={totalCents - amountPaidCents}
      rightSecondaryText={`Due: ${formattedDueDate}`}
    />
  )
}

SearchInvoiceOptionRow.propTypes = {
  row: PropTypes.object,
}

export default SearchInvoiceOptionRow
