import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import cloneDeep from 'lodash/cloneDeep'
import { ReactComponent as TruckIcon } from 'assets/Truck.svg'

import { useDispatch } from 'react-redux'

import { Reply } from '@styled-icons/heroicons-outline/Reply'
import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle'

import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'

import ServiceTable from 'components/pricing/Services/ServiceTable'
import NewServicesForm from 'components/pricing/Services/NewServicesForm'
import ServicesInitialScreen from 'components/pricing/Services/ServicesInitialScreen'
import CommonDrawer from 'components/common/CommonDrawer'
import ChangePrice from 'components/pricing/ChangePrice'
import Loader from 'components/common/loader'

import { useCreateServicesMutation } from 'api/pricing/createServices'
import { useLazyGetValidatePricingServicesQuery } from 'api/pricing/getValidatePricingServices'
import { useLazyGetExistingServicesQuery } from 'api/pricing/getExistingServices'
import { useGetGeneralLedgerMutation } from 'api/settings/getGeneralLedger'
import { useUpdatePricingServiceMutation } from 'api/pricing/pricingServiceCrud'

import { ALL_SERVICES, SERVICES } from 'components/pricing/settings'
import { PRICING_CONFIRMATION_MSG } from 'settings/constants/pricing'
import { handleSpecificDays } from 'components/pricing/pricingMethods'
import { PRICING_PAGINATION } from 'settings/constants/pagination'
import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'
import ConfirmationModal from 'components/modal/ConfirmationModal'

import T from 'T'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { keyBy } from 'lodash'
import { Divider, SvgIcon } from '@mui/material'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { useLinkPricedServicesToRentalFeeMutation } from 'api/pricing/rentalFeePricedServicesCrud'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const NewServiceModalContent = ({
  serviceList,
  tabValue,
  businessLine,
  monthlyRevenue,
  setTabValue,
  selectedValues,
  setBusinessLine,
  switchVal,
  setSwitchVal,
  counter,
  isDirty,
  recurCounter,
  setRecurCounter,
  checkedRecurValues,
  checkedRequestValues,
  setCheckedRecurValues,
  setCheckedRequestValues,
  serviceInput,
  setServiceInput,
  inputTextRecurrence,
  inputTextOnRequest,
  setInputTextRecurrence,
  setInputTextOnRequest,
  combinationRecurCount,
  combinationRequestCount,
  validatedServices,
  duplicateRecords,
  globalFilterOptions,
  setRecCount,
  setCount,
  onHandleChange,
  setLocalState,
  setState,
  page,
  rowsPerPage,
  getPricingServicesResults,
  openDrawer,
  setOpenDrawer,
  configData,
  getPricingServiceName,
  onFrontScreenChange,
  servicesScreen,
  setServicesScreen,
  handleChangePriceDelete,
  onHandleEditFieldsChange,
  handleClearConf,
}) => {
  const [getGeneralLedger, { data: generalLedgerData }] = useGetGeneralLedgerMutation()
  const actionList = get(globalFilterOptions, 'serviceAction', [])
  const actionsByType = keyBy(actionList, 'actionType')
  const measureList = get(globalFilterOptions, 'measure', [])
  const methodList = get(globalFilterOptions, 'methods', [])
  const methodsByType = keyBy(methodList, 'methodType')
  const materialList = get(globalFilterOptions, 'materials', [])
  const materialsByType = keyBy(materialList, 'materialType')
  const pricingPeriodList = get(globalFilterOptions, 'pricingPeriod', [])
  const pricingZoneList = get(globalFilterOptions, 'pricingZones', [])
  const pricingZonesByName = keyBy(pricingZoneList, 'zoneName')
  const recurrenceList = get(globalFilterOptions, 'recurrence', [])
  const dispatch = useDispatch()
  const servicesById = keyBy(serviceList, 'id')
  const generalLedgerList = Array.isArray(generalLedgerData) ? generalLedgerData : []
  const [allServices, setAllServices] = useState([])
  const [backFlag, setBackFlag] = useState(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false)
  const [isMultipleRecordsAltered, setIsMultipleRecordsAltered] = useState(false)

  const [getValidatePricingServices] = useLazyGetValidatePricingServicesQuery()
  const [createServices, { isLoading }] = useCreateServicesMutation()
  const [getExistingServices] = useLazyGetExistingServicesQuery()
  const [updatePricingServices, { isLoading: isUpdateLoading }] = useUpdatePricingServiceMutation()

  const [linkPricedServicesToRentalFee] = useLinkPricedServicesToRentalFeeMutation()
  const [rentalFeeId, setRentalFeeId] = useState('')

  const handleChangeRentalFee = id => {
    setRentalFeeId(id)
  }

  const handlePriceLocked = () => {
    if (tabValue === 'recurring') {
      setInputTextRecurrence({ ...inputTextRecurrence, priceLocked: !inputTextRecurrence.priceLocked })
    } else {
      setInputTextOnRequest({ ...inputTextOnRequest, priceLocked: !inputTextOnRequest.priceLocked })
    }
  }

  const handleServicesCancel = () => {
    setRentalFeeId('')
    setRecurCounter(1)
    setCheckedRecurValues({})
    setCheckedRequestValues({})
    setInputTextRecurrence({
      serviceName: '',
      value: '',
      recurrenceTimes: 1,
      billingPeriods: 1,
      priceLocked: false,
      isBusinessLine: false,
      isNameDirty: false,
    })

    setInputTextOnRequest({
      serviceName: '',
      value: '',
      priceLocked: false,
      isBusinessLine: false,
      isNameDirty: false,
    })

    setServiceInput({})
    setTabValue('')
    setBusinessLine('')

    setSwitchVal(false)
    setIsOpenConfirmationModal(false)
    setIsMultipleRecordsAltered(false)
    setOpenDrawer(false)

    setState({
      isDirty: false,
      configuratorColumns: cloneDeep(SERVICES),
      pastConfiguratorColumns: cloneDeep(SERVICES),
    })
  }

  const handleProceed = () => {
    switch (servicesScreen) {
      case 'initial':
        if (businessLine) {
          const payload = {
            businessLine: [businessLine],
          }

          dispatch(getGlobalFilterOptions(payload))
        }

        serviceInput[tabValue] = businessLine
        setServiceInput(serviceInput)

        getDefaultTitle()

        setServicesScreen('services-form')

        document.getElementsByClassName('tabpanel')[0].scrollTop = 0

        return

      case 'services-form':
        let checkedValues
        if (tabValue === 'recurring') checkedValues = checkedRecurValues
        else checkedValues = checkedRequestValues

        handleValidate(checkedValues, 'submit')
        return
      case 'services-table':
        handleCreateServices()
        return
      case 'edit-services':
        handleUpdateServices('services')

      default:
    }
  }

  const getCheckedValues = () => {
    if (tabValue === 'recurring') {
      return checkedRecurValues
    }

    return checkedRequestValues
  }

  const handleServicesBackBtn = () => {
    const checkedValues = getCheckedValues()

    if (servicesScreen === 'services-table') {
      if (isMultipleRecordsAltered) {
        setIsOpenConfirmationModal(true)
        return
      }
      setServicesScreen('services-form')

      handleValidate(checkedValues, 'onChange')
    } else if (servicesScreen === 'services-form') {
      setServicesScreen('initial')
      setInputTextOnRequest({ isNameDirty: false })
      setInputTextRecurrence({ isNameDirty: false })
    } else {
      setOpenDrawer(false)
    }
    handleClearConf()
    setBackFlag(true)
  }

  const getInputdata = () => {
    if (tabValue === 'recurring') return inputTextRecurrence

    return inputTextOnRequest
  }

  const getServiceType = () => {
    if (tabValue === 'recurring') return 'Recurring'

    return 'On Request'
  }

  const setConfiguratorColumns = type => {
    if (type === 'submit') {
      setState({
        configuratorColumns: cloneDeep(ALL_SERVICES),
        pastConfiguratorColumns: cloneDeep(ALL_SERVICES),
      })
    }
  }

  const handleValidate = (values, type) => {
    const dayData = get(values, 'specificDays', [])

    const inputData = getInputdata()

    const businessLineMeta = get(globalFilterOptions, 'businessLines', [])

    let businessLineRecord = []

    if (businessLineMeta.length > 0) businessLineRecord = businessLineMeta.filter(data => businessLine === data.businessLineLabel)

    const businessIds = []

    Children.toArray(
      businessLineRecord.length > 0 &&
        businessLineRecord.map((data, index) => {
          businessIds.push({ id: data.id })
        })
    )

    let title = inputData.serviceName

    if (title === '') title = serviceInput[tabValue]
    const days = dayData.length > 0 ? dayData.map(data => data.label) : []
    const filteredDays = days.filter(item => !['Working Days', 'Weekend'].includes(item))

    const payload = {
      serviceType: getServiceType(),
      serviceName: title.trim(),
      value: inputData.value.replaceAll(',', ''),
      minBillingPeriodBoolean: switchVal,
      action: get(values, 'actions', []),
      businessLine: businessIds,
      measure: get(values, 'measures', []),
      method: get(values, 'methods', []),
      material: get(values, 'materials', []),
      pricingPeriod: get(values, 'pricingPeriod', []),
      zone: get(values, 'pricingZones', []),
      recurrence: get(values, 'recurrence', []),
      recurrenceFrequency: inputData.recurrenceTimes,
      days: filteredDays,
      priceLocked: inputData.priceLocked,
      generalLedgerAccountId: get(values, 'generalLedgerAccount[0]', {}).id,
    }

    if (switchVal) {
      payload.minBillingPeriod = inputData.billingPeriods
    } else {
      payload.minBillingPeriod = 0
    }

    allServices.push(payload)
    setAllServices(allServices)

    setConfiguratorColumns(type)

    if (Object.keys(values).includes('businessLine')) {
      if (tabValue === 'recurring') setInputTextRecurrence({ ...inputTextRecurrence, isBusinessLine: true })
      else setInputTextOnRequest({ ...inputTextOnRequest, isBusinessLine: true })
    }

    getValidatePricingServices(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        const validData = res.services.filter(data => !data.exist)

        setLocalState({
          validatedServices: validData,
          duplicateRecords: res.services.filter(data => data.exist),
        })

        if (type === 'submit') setServicesScreen('services-table')

        if (tabValue === 'recurring') {
          setLocalState({
            combinationRecurCount: validData.filter(data => data.serviceType === 'Recurring').length,
          })
        } else {
          setLocalState({
            combinationRequestCount: validData.filter(data => data.serviceType === 'On Request').length,
          })
        }
      })
      .catch(handleError)
  }

  const handleReValidate = (name, value, service) => {
    const payload = {
      serviceType: get(service, 'serviceType', 'On Request'),
      serviceName: name === 'serviceName' ? value : get(service, 'serviceName', ''),
      material: { id: get(service, 'material', { id: null }).id },
      recurrence: { id: get(service, 'recurrence', { id: null }).id },
      serviceAction: { id: get(service, 'serviceAction', { id: null }).id },
      serviceMethod: { id: get(service, 'serviceMethod', { id: null }).id },
      pricingZone: { id: get(service, 'pricingZone', { id: null }).id },
      measure: { id: get(service, 'measure', { id: null }).id },
      days: get(service, 'days', []),
      specificDays: get(service, 'specificDays', ''),
      pricingPeriod: { id: get(service, 'pricingPeriod', { id: null }).id },
      businessLine: get(service, 'businessLine', ''),
      value: name === 'value' ? value.toString().replaceAll(',', '') : get(service, 'value', '').toString().replaceAll(',', ''),
      recurrenceFrequency: get(service, 'recurrenceFrequency', 1),
      minBillingPeriodBoolean: get(service, 'minBillingPeriodBoolean', false),
      minBillingPeriod: get(service, 'minBillingPeriod', 0),
      generalLedgerAccountId: get(service, 'generalLedgerAccountId', null),
    }

    getExistingServices(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        service.exist = res.exist
        const allRecords = validatedServices.concat(duplicateRecords)
        const validServiceData = allRecords.filter(data => !data.exist)

        setLocalState({
          validatedServices: validServiceData,
          duplicateRecords: allRecords.filter(data => data.exist),
        })

        if (tabValue === 'recurring') {
          setLocalState({
            combinationRecurCount: validServiceData.filter(data => data.serviceType === 'Recurring').length,
          })
        } else {
          setLocalState({
            combinationRequestCount: validServiceData.filter(data => data.serviceType === 'On Request').length,
          })
        }
      })
      .catch(handleError)
  }

  const onHandleValidatedFieldsChange = (e, service) => {
    const { name, value } = e.target
    if (!isMultipleRecordsAltered) {
      setIsMultipleRecordsAltered(true)
    }
    handleReValidate(name, value, service)

    if (validatedServices.length > 0) {
      validatedServices.find(data => data === service)[name] = value

      setLocalState({
        validatedServices,
      })
    }

    if (duplicateRecords.length > 0) {
      duplicateRecords.find(data => data === service)[name] = value

      setLocalState({
        duplicateRecords,
      })
    }

    if (validatedServices.concat(duplicateRecords).length === 1) {
      onHandleChange(e)
    }
  }

  const convertLegacyPricingServiceToNewModel = legacyPricingService => {
    const serviceName = get(legacyPricingService, 'serviceName')
    const serviceType = get(legacyPricingService, 'serviceType')
    const materialId = get(legacyPricingService, 'material.id')
    const recurrenceId = get(legacyPricingService, 'recurrence.id')
    const serviceActionId = get(legacyPricingService, 'serviceAction.id')
    const serviceMethodId = get(legacyPricingService, 'serviceMethod.id')
    const pricingZoneId = get(legacyPricingService, 'pricingZone.id')
    const measureId = get(legacyPricingService, 'measure.id')
    const pricingPeriodId = get(legacyPricingService, 'pricingPeriod.id')
    const generalLedgerAccountId = get(legacyPricingService, 'generalLedgerAccountId')
    const days = get(legacyPricingService, 'days')
    const value = get(legacyPricingService, 'value')
    const recurrenceFrequency = get(legacyPricingService, 'recurrenceFrequency')
    const minBillingPeriod = get(legacyPricingService, 'minBillingPeriod')
    const businessLine = get(legacyPricingService, 'businessLine')

    return {
      serviceName,
      serviceType,
      materialId,
      recurrenceId,
      serviceActionId,
      serviceMethodId,
      pricingZoneId,
      measureId,
      pricingPeriodId,
      generalLedgerAccountId,
      days,
      value,
      recurrenceFrequency,
      minBillingPeriod,
      businessLine,
    }
  }

  const handleCreateServices = () => {
    const legacyServices = validatedServices.filter(service => (service.value = service.value.toString().replaceAll(',', '')))
    const services = legacyServices.map(convertLegacyPricingServiceToNewModel)
    createServices({ services })
      .unwrap()
      .then(result => {
        const pricedServiceIds = get(result, 'services', []).map(({ id }) => id)
        if (pricedServiceIds.length > 0 && rentalFeeId) {
          linkPricedServicesToRentalFee({ id: rentalFeeId, pricedServiceIds }).unwrap().catch(handleError)
        }
        getPricingServicesResults(page, rowsPerPage)
        setServicesScreen('initial')
        handleServicesCancel()
      })
      .catch(handleError)
  }

  const handleUpdateServices = type => {
    const parsedSelectedValues = selectedValues.map(selectedValue => {
      const serviceName = get(selectedValue, 'serviceName')
      const value = get(selectedValue, 'value')
      const id = get(selectedValue, 'id')
      const serviceData = get(servicesById, id)
      const serviceType = get(serviceData, 'serviceType')
      const zoneName = get(serviceData, 'zoneName')
      const materialType = get(serviceData, 'materialType')
      const recurrencePer = get(serviceData, 'recurrencePer')
      const recurrenceInterval = get(serviceData, 'recurrenceInterval')
      const recurrence =
        recurrencePer && recurrenceInterval
          ? recurrenceList.find(item => item.recurrencePer === recurrencePer && item.recurrenceInterval === recurrenceInterval)
          : {}
      const recurrenceId = get(recurrence, 'id')
      const actionType = get(serviceData, 'actionType')
      const action = get(actionsByType, actionType)
      const serviceActionId = get(action, 'id')
      const material = get(materialsByType, materialType)
      const materialId = get(material, 'id')
      const pricingZone = get(pricingZonesByName, zoneName)
      const pricingZoneId = get(pricingZone, 'id')
      const methodType = get(serviceData, 'methodType')
      const method = get(methodsByType, methodType)
      const serviceMethodId = get(method, 'id')
      const unit = get(serviceData, 'unit')
      const volume = get(serviceData, 'volume')
      const measure = measureList.find(item => item.unit === unit && item.volume === volume)
      const measureId = get(measure, 'id')
      const periodPer = get(serviceData, 'periodPer')
      const periodInterval = get(serviceData, 'periodInterval')
      const pricingPeriod = pricingPeriodList.find(item => item.periodPer === periodPer && item.periodInterval === periodInterval)
      const pricingPeriodId = get(pricingPeriod, 'id')
      const generalLedgerAccountNumber = get(serviceData, 'generalLedgerAccountNumber')
      const generalLedger = generalLedgerList.find(item => item.accountNumber === generalLedgerAccountNumber)
      const generalLedgerAccountId = get(generalLedger, 'id')
      const days = get(serviceData, 'days', [])
      const recurrenceFrequency = get(selectedValue, 'recurrenceFrequency')
      const minBillingPeriod = get(serviceData, 'minBillingPeriod', 0)
      const businessLine = get(serviceData, 'businessLine')

      return {
        id,
        serviceName,
        serviceType,
        materialId,
        recurrenceId,
        serviceActionId,
        serviceMethodId,
        pricingZoneId,
        measureId,
        pricingPeriodId,
        generalLedgerAccountId,
        days,
        value,
        recurrenceFrequency,
        minBillingPeriod,
        businessLine,
      }
    })
    const payload = {
      services: parsedSelectedValues,
    }

    updatePricingServices(payload)
      .unwrap()
      .then(() => {
        getPricingServicesResults(page, rowsPerPage)
        setServicesScreen('initial')
        handleServicesCancel()
      })
      .catch(handleError)
  }

  const getServiceName = () => {
    return getInputdata().serviceName
  }

  const handleDisabled = (() => {
    let isDisabled = true

    if (servicesScreen === 'initial') {
      isDisabled = businessLine === '' || tabValue === ''
    } else if (servicesScreen === 'services-form') {
      if (tabValue === 'recurring')
        isDisabled = !(
          (businessLine !== '' &&
            getServiceName() !== '' &&
            Object.keys(checkedRecurValues).includes('recurrence') &&
            checkedRecurValues.recurrence.length > 0 &&
            Object.keys(checkedRecurValues).includes('pricingPeriod') &&
            checkedRecurValues.pricingPeriod.length > 0) ||
          servicesScreen === 'services-table'
        )
      else
        isDisabled = !(
          (businessLine !== '' &&
            getServiceName() !== '' &&
            Object.keys(checkedRequestValues).includes('pricingPeriod') &&
            checkedRequestValues.pricingPeriod.length > 0) ||
          servicesScreen === 'services-table'
        )
    } else if (servicesScreen === 'services-table') {
      const isAnyNameEmpty = validatedServices.find(({ serviceName }) => serviceName.replaceAll(' ', '') === '') !== undefined
      isDisabled = validatedServices.length === 0 || isAnyNameEmpty
    } else if (servicesScreen === 'edit-services') {
      isDisabled = selectedValues.filter(Boolean).find(({ serviceName }) => serviceName?.trim() === '') !== undefined
    }

    return isDisabled
  })()

  const handleDelete = (service, type) => {
    const validateRecords = validatedServices.filter(data => data !== service)

    if (type === 'duplicate') {
      setLocalState({
        validatedServices: validateRecords,
        duplicateRecords: duplicateRecords.filter(data => data !== service),
        combinationRecurCount: validateRecords.filter(data => data.serviceType === 'Recurring').length,
        combinationRequestCount: validateRecords.filter(data => data.serviceType === 'On Request').length,
      })
    } else {
      setLocalState({
        validatedServices: validateRecords,
        combinationRecurCount: validateRecords.filter(data => data.serviceType === 'Recurring').length,
        combinationRequestCount: validateRecords.filter(data => data.serviceType === 'On Request').length,
      })
    }
  }

  const handleButtonText = () => {
    let count = ''
    let editCount = ''
    let duplicate = 0
    if (tabValue === 'recurring') {
      count = combinationRecurCount
      duplicate = duplicateRecords.filter(data => data.serviceType === 'Recurring')

      if (servicesScreen === 'services-form') count = duplicate.length + combinationRecurCount
    } else if (tabValue === 'on request') {
      count = combinationRequestCount
      duplicate = duplicateRecords.filter(data => data.serviceType === 'On Request')

      if (servicesScreen === 'services-form') count = duplicate.length + combinationRequestCount
    }

    if (selectedValues.length > 0) {
      editCount = selectedValues.length
    }

    if (servicesScreen === 'services-form') {
      editCount = 0
    }

    if (servicesScreen === 'initial') {
      return `${T.CONTINUE}`
    }
    if (editCount > 0 && servicesScreen === 'edit-services') {
      return `${T.CHANGE_SERVICE}s (${editCount})`
    }
    if (servicesScreen === 'edit-services') {
      return `${T.CHANGE_SERVICE}s`
    }
    if (count > 0) {
      return `${T.CREATE_SERVICES} (${count})`
    }
    return `${T.CREATE_SERVICES}`
  }

  const getDefaultTitle = () => {
    let checkedValues
    if (tabValue === 'recurring') checkedValues = checkedRecurValues
    else checkedValues = checkedRequestValues

    const recurrence = checkedValues.recurrence && checkedValues.recurrence.length === 1 && checkedValues.recurrence
    const measure = checkedValues.measures && checkedValues.measures.length === 1 && checkedValues.measures
    const method = checkedValues.methods && checkedValues.methods.length === 1 && checkedValues.methods
    const material = checkedValues.materials && checkedValues.materials.length === 1 && checkedValues.materials

    const recurrenceLabel = get(recurrence, '[0]label', '')
    const measureLabel = get(measure, '[0]label', '')
    const methodLabel = get(method, '[0]label', '')
    const materialLabel = get(material, '[0]label', '')

    let serviceVal = businessLine

    if (recurrenceLabel != '') {
      serviceVal += ` ${recurrenceLabel}`
    }

    if (measureLabel != '') {
      serviceVal += ` ${measureLabel}`
    }

    if (methodLabel != '') {
      serviceVal += ` ${methodLabel}`
    }

    if (materialLabel != '') {
      serviceVal += ` ${materialLabel}`
    }

    serviceInput[tabValue] = serviceVal.trim()
    setServiceInput(serviceInput)
  }

  const handleCheckboxValue = (label, records, event) => {
    let checkedValues
    if (tabValue === 'recurring') {
      checkedValues = checkedRecurValues
    } else {
      checkedValues = checkedRequestValues
    }

    const category = camelCase(label.replace(' ', ''))
    checkedValues[category] = []

    if (label === 'Specific Days') {
      checkedValues[category] = handleSpecificDays(records, checkedValues[category], label, event)
    } else if ((label === 'Pricing Period' || label === `${T.GENERAL_LEDGER} ${T.ACCOUNT}`) && records !== null) {
      checkedValues[category] = [
        {
          id: records.id,
          value: records.value,
        },
      ]
    } else {
      records &&
        Children.toArray(
          records.forEach(data => {
            checkedValues[category].push({ id: data.id, label: getByLabelText(data, label) })
          })
        )
    }

    if (tabValue === 'recurring') {
      setCheckedRecurValues({ ...checkedValues })
    } else {
      setCheckedRequestValues({ ...checkedValues })
    }

    getDefaultTitle()

    handleValidate(checkedValues, 'onChange')
  }

  const handleClick = (key, value) => {
    setState({ [key]: value })
  }

  let heading = T.NEW_SERVICE

  if (servicesScreen === 'edit-services') {
    heading = T.CHANGE_SERVICE
  }

  useEffect(() => {
    getGeneralLedger({})
  }, [])

  return (
    <div>
      {(isLoading || isUpdateLoading) && <Loader />}
      <CommonDrawer
        isOpen={openDrawer}
        isDirty={isDirty}
        className={`add-pricing-services ${servicesScreen}`}
        onChange={(event, isOpen) => handleServicesCancel()}
      >
        <HHDialogTitle
          TitleTypographyProps={{
            variant: 'h6',
          }}
          icon={
            <SvgIcon>
              <TruckIcon />
            </SvgIcon>
          }
          title={
            <>
              {heading}
              {['services-form', 'services-table'].includes(servicesScreen) &&
                businessLine &&
                tabValue &&
                `: ${businessLine} ${startCase(tabValue)}`}
            </>
          }
          onClose={() => handleServicesCancel()}
        />
        <Divider />
        <section>
          <div className="content">
            <div className="tabpanel transparent-scroll">
              {servicesScreen === 'initial' && (
                <ServicesInitialScreen businessLine={businessLine} tabValue={tabValue} onFrontScreenChange={onFrontScreenChange} />
              )}

              {servicesScreen === 'services-form' && (
                <NewServicesForm
                  tabValue={tabValue}
                  globalFilterOptions={globalFilterOptions}
                  handleCheckboxValue={handleCheckboxValue}
                  checkedValues={getCheckedValues()}
                  counter={counter}
                  businessLine={businessLine}
                  recurCounter={recurCounter}
                  switchVal={switchVal}
                  setSwitchVal={setSwitchVal}
                  setCount={setCount}
                  setRecCount={setRecCount}
                  serviceInput={serviceInput}
                  handlePriceLocked={handlePriceLocked}
                  onHandleChange={onHandleChange}
                  inputText={getInputdata()}
                  backFlag={backFlag}
                  rentalFeeId={rentalFeeId}
                  onChangeRentalFee={handleChangeRentalFee}
                />
              )}

              {servicesScreen === 'services-table' && (
                <div className="service-tabpanel-content">
                  <div className="all-services-screen">
                    {validatedServices.length > 0 && (
                      <ServiceTable
                        validatedServices={validatedServices}
                        confServices={configData}
                        handleSingleChange={handleClick}
                        handleDelete={handleDelete}
                        onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
                        type="unique"
                        getPricingServiceName={getPricingServiceName}
                      />
                    )}

                    {duplicateRecords.length > 0 && (
                      <div className="duplicate-records">
                        <div className={`error-block-record ${validatedServices.length === 0 ? 'no-validated-record' : ''}`}>
                          <div className="error-block">
                            <ExclamationCircle className="error-msg" />
                            <div className="label">{T.SERVICE_DUPLICATED_MSG}</div>
                          </div>

                          <ServiceTable
                            validatedServices={duplicateRecords}
                            confServices={configData}
                            handleSingleChange={handleClick}
                            handleDelete={handleDelete}
                            onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
                            type="duplicate"
                            getPricingServiceName={getPricingServiceName}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {servicesScreen === 'edit-services' && (
                <ChangePrice
                  selectedValues={selectedValues}
                  type="services"
                  handleChangePriceDelete={handleChangePriceDelete}
                  onHandleEditFieldsChange={onHandleEditFieldsChange}
                />
              )}
            </div>
          </div>

          <div className="services-footer">
            <input type="button" className="cancel" value={T.CANCEL} onClick={() => handleServicesCancel()} />

            <div className="right-aligned">
              {servicesScreen !== 'initial' && servicesScreen !== 'edit-services' && (
                <div className="back" onClick={() => handleServicesBackBtn()}>
                  <Reply className="back-btn" />
                  <div className="back-txt">{T.BACK}</div>
                </div>
              )}

              <input disabled={handleDisabled} type="button" className="save" value={handleButtonText()} onClick={() => handleProceed()} />
            </div>
          </div>
        </section>
      </CommonDrawer>
      {isOpenConfirmationModal && (
        <ConfirmationModal
          className="global-drawer-confirm"
          isOpen={isOpenConfirmationModal}
          title={PRICING_CONFIRMATION_MSG}
          cancelButtonTitle={T.CANCEL}
          proceedButtonTitle={T.OK}
          onCancel={() => {
            setIsOpenConfirmationModal(false)
          }}
          onProceed={() => {
            setServicesScreen('services-form')
            setIsMultipleRecordsAltered(false)
            setIsOpenConfirmationModal(false)
          }}
        />
      )}
    </div>
  )
}

NewServiceModalContent.defaultProps = {
  tabValue: '',
  businessLine: '',
  monthlyRevenue: null,
  switchVal: false,
  openDrawer: false,
  isDirty: false,
  counter: 1,
  selectedValues: [],
  configData: [],
  validatedServices: [],
  duplicateRecords: [],
  serviceInput: {},
  inputTextRecurrence: {},
  inputTextOnRequest: {},
  checkedRecurValues: {},
  checkedRequestValues: {},
  combinationRecurCount: 0,
  combinationRequestCount: 0,
  servicesScreen: 'initial',
  setServicesScreen: null,
  globalFilterOptions: {},
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  setTabValue: null,
  onFrontScreenChange: null,
  setBusinessLine: null,
  setSwitchVal: null,
  setServiceInput: null,
  recurCounter: 1,
  setCheckedRecurValues: null,
  setCheckedRequestValues: null,
  setInputTextRecurrence: null,
  setInputTextOnRequest: null,
  setRecCount: null,
  setCount: null,
  onHandleChange: null,
  setState: null,
  setLocalState: null,
  getPricingServicesResults: null,
  setOpenDrawer: null,
  getPricingServiceName: null,
  handleChangePriceDelete: null,
  onHandleEditFieldsChange: null,
  handleClearConf: null,
}

NewServiceModalContent.propTypes = {
  serviceList: PropTypes.array,
  tabValue: PropTypes.string,
  businessLine: PropTypes.string,
  monthlyRevenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  switchVal: PropTypes.bool,
  openDrawer: PropTypes.bool,
  isDirty: PropTypes.bool,
  selectedValues: PropTypes.array,
  counter: PropTypes.number,
  servicesScreen: PropTypes.string,
  setServicesScreen: PropTypes.func,
  configData: PropTypes.array,
  validatedServices: PropTypes.array,
  duplicateRecords: PropTypes.array,
  serviceInput: PropTypes.object,
  inputTextRecurrence: PropTypes.object,
  inputTextOnRequest: PropTypes.object,
  checkedRecurValues: PropTypes.object,
  checkedRequestValues: PropTypes.object,
  combinationRecurCount: PropTypes.number,
  combinationRequestCount: PropTypes.number,
  globalFilterOptions: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setSwitchVal: PropTypes.func,
  setTabValue: PropTypes.func,
  onFrontScreenChange: PropTypes.func,
  setBusinessLine: PropTypes.func,
  setServiceInput: PropTypes.func,
  recurCounter: PropTypes.number,
  setRecurCounter: PropTypes.func,
  setCheckedRecurValues: PropTypes.func,
  setCheckedRequestValues: PropTypes.func,
  setInputTextRecurrence: PropTypes.func,
  setInputTextOnRequest: PropTypes.func,
  setRecCount: PropTypes.func,
  setCount: PropTypes.func,
  onHandleChange: PropTypes.func,
  setLocalState: PropTypes.func,
  setState: PropTypes.func,
  getPricingServicesResults: PropTypes.func,
  setOpenDrawer: PropTypes.func,
  getPricingServiceName: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
  handleClearConf: PropTypes.func,
}

export default NewServiceModalContent
