import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_LIST } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getAccountGroupList: build.query({
      query: () => ({ url: '/api/v1/core/accounts/groups', method: 'GET' }),
      providesTags: [CACHE_TAG_ACCOUNT_GROUP_LIST],
    }),
  }),
})

export const { useGetAccountGroupListQuery, useLazyGetAccountGroupListQuery } = extendedApi
