import { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { addRouteIdSuffix } from 'components/route-manager/RouteManagerMap/settings'
import { DYNAMIC_COLOR_STOPS_SOURCE, groupFeaturesByIcon, STATIC_COLOR_STOPS_SOURCE } from 'components/route-manager/BulkActions/settings'
import { ROUTE_STOPS_LINE_STRING } from 'slices/route/routeSlice'

const useUpdateRouteLayer = map => {
  return useCallback(
    (routeId, { sequenceData, features, hasLineString = true }) => {
      const mapCurrentRef = map.current
      if (hasLineString) {
        const fixedSequenceData = isEmpty(sequenceData) ? { type: 'FeatureCollection', features: [] } : sequenceData
        mapCurrentRef.getSource(addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId))?.setData(fixedSequenceData)
      }
      const [monochromeFeatures, otherFeatures] = groupFeaturesByIcon(features)
      mapCurrentRef
        .getSource(addRouteIdSuffix(DYNAMIC_COLOR_STOPS_SOURCE, routeId))
        ?.setData({ type: 'FeatureCollection', features: monochromeFeatures })
      mapCurrentRef
        .getSource(addRouteIdSuffix(STATIC_COLOR_STOPS_SOURCE, routeId))
        ?.setData({ type: 'FeatureCollection', features: otherFeatures })
    },
    [map]
  )
}

export default useUpdateRouteLayer
