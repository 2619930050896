import { actions } from '../actions/customers'

const { SEARCH_RESULT, CUSTOMER_LIST, SUGGEST_CUSTOMER_LIST, CUSTOMER_DETAILS, CUSTOMER_META, USER_SEARCH_RESULT, CUSTOMER_HISTORY } =
  actions.PUT

const initialState = {
  customerSearchResult: null,
  customerList: null,
  customerSuggestList: null,
  customerDetails: null,
  customerMeta: null,
  userSearchResult: null,
  customerHistory: {
    sortBy: '',
  },
}

const CustomersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_RESULT:
      return { ...state, customerSearchResult: action.payload }

    case CUSTOMER_LIST:
      return { ...state, customerList: action.payload }

    case SUGGEST_CUSTOMER_LIST:
      return { ...state, customerSuggestList: action.payload }

    case CUSTOMER_DETAILS:
      return { ...state, customerDetails: action.payload }

    case CUSTOMER_META:
      return { ...state, customerMeta: action.payload }

    case CUSTOMER_HISTORY:
      return {
        ...state,
        customerHistory: {
          ...initialState.customerHistory,
          ...action.payload,
        },
      }

    case USER_SEARCH_RESULT:
      return { ...state, userSearchResult: action.payload }

    default:
      return state
  }
}

export default CustomersReducer
