import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip,
  MenuItem,
  Typography,
  Card,
  styled,
  Box,
  Grid,
  useTheme,
  Button,
} from '@mui/material'
import { HHTextField } from 'components/form-fields/v5'
import { getFirstMonthsScheduleOptions, getQuarterlyScheduleOptions } from 'utils/billingSchedule'

import T from 'T'

import { get } from 'utils/lodash'
import { getPricingPeriod } from 'utils/service'
import {
  selectAccountBillingCycleInterval,
  selectAccountBillingCycleName,
  selectTiming,
} from 'data/billing-profile/billingProfileSelectors'

const StyledCard = styled(Card)(() => ({
  minWidth: 40,
  textAlign: 'center',
  padding: 8,
  cursor: 'pointer',
}))

const LeftSide = ({
  disableFormFields = false,
  profileState = {},
  customerMeta = {},
  onHandleTextChange = noop,
  onHandleValChange = noop,
  onValueTypeChange = noop,
  setProfileState = noop,
}) => {
  const theme = useTheme()
  const [disableQuarterMonths, setDisableQuarterMonths] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const billingCycles = get(customerMeta, 'billingCycles', [])
    .filter(({ intervalType }) => !['Week', 'Manual'].includes(intervalType))
    .map(
      cycle =>
        cycle.intervalType !== '' && {
          key: cycle.id,
          value: getPricingPeriod(cycle.interval, cycle.intervalType),
        }
    )

  const paymentTerms = get(customerMeta, 'paymentTerms', []).map(term => ({
    key: term.id,
    value: `${term.term} ${term.interval > 0 ? term.interval : ''}`,
  }))

  const getSelectedCycle = () => get(customerMeta, 'billingCycles', []).find(bc => bc.id === profileState.cycle)

  const validateFormOptions = () => {
    const cycle = getSelectedCycle()
    if (cycle && cycle.intervalType === T.QUARTER && profileState.timing === T.ANNIVERSARY_VALUE) {
      setProfileState({ timingQuarter: '' })
      setDisableQuarterMonths(true)
    } else {
      setDisableQuarterMonths(false)
    }
  }

  const selectedBillingCycleInterval = selectAccountBillingCycleInterval(customerMeta, profileState)
  const selectedBillingCycleName = selectAccountBillingCycleName(customerMeta, profileState)
  useEffect(() => {
    setProfileState({ timing: 'Day', timingDay: '1' })
    validateFormOptions()
  }, [selectedBillingCycleInterval, selectedBillingCycleName])

  const quarterOptions = getQuarterlyScheduleOptions(getSelectedCycle())
  const firstMonthsOptions = getFirstMonthsScheduleOptions(getSelectedCycle())

  const timing = selectTiming(profileState)
  useEffect(() => {
    validateFormOptions()
  }, [timing])

  const handleQuarterMonthsTooltipOpen = () => {
    const cycle = getSelectedCycle()
    if (cycle && cycle.intervalType === T.QUARTER && profileState.timing === T.ANNIVERSARY_VALUE) {
      setShowTooltip(true)
    }
  }

  const handleQuarterMonthsTooltipClose = () => {
    setShowTooltip(false)
  }

  return (
    <Box>
      <Box mb={1}>
        <Typography variant="subtitle2">{T.TYPE}</Typography>
        <FormControl component="fieldset" style={{ display: 'block' }}>
          <RadioGroup
            aria-label="timing"
            name="timing"
            row
            value={get(profileState, 'paymentType', '')}
            onChange={e => onHandleValChange(e, 'paymentType')}
          >
            <FormControlLabel value="Advance" control={<Radio disabled={disableFormFields} size="small" />} label="Advance" />
            <FormControlLabel value="Arrears" control={<Radio disabled={disableFormFields} size="small" />} label="Arrears" />
          </RadioGroup>
        </FormControl>
      </Box>

      <HHTextField
        fullWidth
        label={T.CYCLE}
        required
        select
        readOnly={disableFormFields}
        value={[get(profileState, 'cycle', '')]}
        onChange={e => onHandleValChange(e, 'cycle')}
        sx={{ mb: 1 }}
      >
        <MenuItem value="">{T.SELECT}</MenuItem>
        {billingCycles.map(data => (
          <MenuItem value={data.key}>{data.value}</MenuItem>
        ))}
      </HHTextField>

      {quarterOptions.length > 0 && (
        <Tooltip
          title="Option not applicable to Anniversary Timing"
          disableFocusListener
          disableTouchListener
          open={showTooltip}
          onOpen={handleQuarterMonthsTooltipOpen}
          onClose={handleQuarterMonthsTooltipClose}
          sx={{ mb: 1 }}
        >
          <HHTextField
            label={T.FIRST_MONTHS}
            select
            readOnly={disableFormFields}
            value={[get(profileState, 'timingQuarter', '')]}
            onChange={e => onHandleValChange(e, 'timingQuarter')}
            disabled={disableQuarterMonths}
            fullWidth
          >
            {quarterOptions.map(data => (
              <MenuItem value={data.key}>{data.value}</MenuItem>
            ))}
          </HHTextField>
        </Tooltip>
      )}
      {firstMonthsOptions.length > 0 && (
        <HHTextField
          SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 'auto' } } } }}
          label={T.FIRST_MONTHS}
          select
          readOnly={disableFormFields}
          value={[get(profileState, 'billingMonths', '')]}
          onChange={e => onHandleValChange(e, 'billingMonths')}
          fullWidth
        >
          {firstMonthsOptions.map(({ key, value }) => (
            <MenuItem value={key}>{value}</MenuItem>
          ))}
        </HHTextField>
      )}
      <Box mt={1}>
        <HHTextField
          fullWidth
          label={T.PAYMENT_TERMS}
          required
          select
          value={[get(profileState, 'paymentTerms', '')]}
          onChange={e => onHandleValChange(e, 'paymentTerms')}
        >
          <MenuItem value="">{T.SELECT}</MenuItem>
          {paymentTerms.map(data => (
            <MenuItem value={data.key}>{data.value}</MenuItem>
          ))}
        </HHTextField>
      </Box>

      <Typography variant="subtitle1" mt={2} mb={0.5}>
        {T.LATE_FEES}
      </Typography>

      <Grid container spacing={1.5} alignItems="end" mb={1}>
        <Grid item xs="auto">
          <Typography variant="subtitle2">{T.AMOUNT}</Typography>
          <Box display="flex" mt={0.5}>
            <Button
              disabled={disableFormFields}
              variant="outlined"
              size="large"
              sx={{
                height: 49,
                border: `1px solid ${profileState.feeType === 'Dollar' ? theme.palette.primary.main : theme.palette.border.lightGrey}`,
                backgroundColor: disableFormFields ? theme.palette.background.disabled : theme.palette.background.paper,
              }}
              onClick={() => (disableFormFields ? noop : onValueTypeChange('Dollar'))}
            >
              $
            </Button>

            <Button
              disabled={disableFormFields}
              variant="outlined"
              size="large"
              sx={{
                height: 49,
                ml: 1,
                border: `1px solid ${profileState.feeType === 'Percentage' ? theme.palette.primary.main : theme.palette.border.lightGrey}`,
                backgroundColor: disableFormFields ? theme.palette.background.disabled : theme.palette.background.paper,
              }}
              onClick={() => (disableFormFields ? noop : onValueTypeChange('Percentage'))}
            >
              %
            </Button>
          </Box>
        </Grid>

        <Grid item xs>
          <HHTextField
            defaultSize
            fullWidth
            readOnly={disableFormFields}
            value={get(profileState, 'feeAmount', '')}
            variant="outlined"
            autoComplete="off"
            placeholder="0.00"
            name="feeAmount"
            InputProps={{
              onChange: e => onHandleTextChange(e),
            }}
          />
        </Grid>

        <Grid item xs>
          <HHTextField
            fullWidth
            readOnly={disableFormFields}
            label={T.THRESHOLD}
            value={get(profileState, 'threshold', '')}
            variant="outlined"
            autoComplete="off"
            placeholder="0.00"
            name="threshold"
            InputProps={{
              onChange: e => onHandleTextChange(e),
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs>
          <HHTextField
            fullWidth
            readOnly={disableFormFields}
            label={T.GRACE_PERIOD}
            value={get(profileState, 'gracePeriod', '')}
            variant="outlined"
            autoComplete="off"
            placeholder="# Days"
            name="gracePeriod"
            InputProps={{
              onChange: e => onHandleTextChange(e),
            }}
          />
        </Grid>
      </Grid>

      <HHTextField
        readOnly={disableFormFields}
        label={T.CYCLE}
        select
        value={[get(profileState, 'cyclePeriod', '')]}
        onChange={e => onHandleValChange(e, 'cyclePeriod')}
        sx={{ minWidth: '100%' }}
      >
        <MenuItem value="">{`Select ${T.PERIOD}`}</MenuItem>
        {billingCycles.map(data => (
          <MenuItem value={data.key}>{data.value}</MenuItem>
        ))}
      </HHTextField>
    </Box>
  )
}

LeftSide.propTypes = {
  disableFormFields: PropTypes.bool,
  profileState: PropTypes.object,
  customerMeta: PropTypes.object,
  onValueTypeChange: PropTypes.func,
  onHandleTextChange: PropTypes.func,
  onHandleValChange: PropTypes.func,
  setProfileState: PropTypes.func,
}

export default LeftSide
