import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHTextField from 'components/form-fields/v5/HHTextField'
import ListView from './ListView'

const BillingProfileDropdown = ({ label = T.BILLING_PROFILE, billingProfileId = '', onChange }) => {
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const allBillingProfiles = get(customerMeta, 'billingProfiles', [])
    .filter(profile => profile?.id && profile?.profile)
    .map(profile => ({
      id: profile?.id,
      name: profile.profile,
      ...profile,
    }))

  return (
    <HHTextField
      sx={{
        '& .MuiSelect-select li button': { display: 'none' },
        '& .MuiOutlinedInput-root': {
          height: billingProfileId ? '50px!important' : 40,
        },
      }}
      deprecatedLabel={false}
      label={capitalize(label)}
      fullWidth
      select
      value={billingProfileId}
      onChange={onChange}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              '& .Mui-selected': {
                color: 'inherit !important',
                '&:hover': { color: 'inherit' },
                '& .billing-detail-summary': {
                  '& .MuiTypography-root, & svg': {
                    color: 'primary.main',
                  },
                },
              },
            },
          },
        },
      }}
    >
      {allBillingProfiles.map(profile => (
        <SelectItem sx={{ display: 'block', p: 0, borderBottom: 1, borderColor: 'divider' }} value={profile.id}>
          <ListView billingProfile={profile} />
        </SelectItem>
      ))}
    </HHTextField>
  )
}

BillingProfileDropdown.propTypes = {
  label: PropTypes.string,
  billingProfileId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default BillingProfileDropdown
