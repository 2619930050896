import React from 'react'
import PropTypes from 'prop-types'
import { Badge, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import useTheme from '@mui/material/styles/useTheme'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

const RouteStatusAvatarWrapper = ({ isRouteInProgress = false, children }) => {
  const Wrapper = isRouteInProgress ? StyledBadge : Badge
  const theme = useTheme()
  return (
    <Wrapper
      sx={
        !isRouteInProgress && {
          '& .MuiBadge-badge': {
            backgroundColor: grey[500],
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          },
        }
      }
      overlap="circular"
      variant="dot"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {children}
    </Wrapper>
  )
}

RouteStatusAvatarWrapper.propTypes = {
  isRouteInProgress: PropTypes.bool,
  children: PropTypes.node,
}

export default RouteStatusAvatarWrapper
