import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { selectBulkMoveRoutesById, selectBulkMoveStopsDate } from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import CommonPermanentMoveAlert from 'components/route-manager/BulkActions/common/PermanentMove/CommonPermanentMoveAlert'

const PermanentMoveAlert = ({ toBeMovedRowsCount = 0 }) => {
  const { watch } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const routesById = useSelector(selectBulkMoveRoutesById)
  const serializedServiceDate = useSelector(selectBulkMoveStopsDate)
  return (
    <CommonPermanentMoveAlert
      toSerializedDate={serializedServiceDate}
      fromSerializedDate={serializedServiceDate}
      routesById={routesById}
      moveFromRouteId={moveFromRouteId}
      moveToRouteId={moveToRouteId}
      toBeMovedRowsCount={toBeMovedRowsCount}
    />
  )
}

PermanentMoveAlert.propTypes = {
  toBeMovedRowsCount: PropTypes.number,
}

export default memo(PermanentMoveAlert)
