import T from 'T'

export const SERVICE_MODEL = {
  status: 'Active',
  pricedServiceId: '',
  serviceType: '',
  taxZoneId: '',
  tags: [],
  price: '',
  service: {
    searchType: 'Service',
    serviceType: T.RECURRING,
    recurrenceFrequency: 1,
    recurrenceIds: [],
    businessLines: [],
    measureIds: [],
    materialIds: [],
    serviceMethodIds: [],
    serviceActionIds: [],
    pricingZoneId: '',
    search: '',
  },
  schedule: {
    startDate: '',
    isStartDateInvalid: null,
    isEndDateInvalid: null,
    endDate: '',
    deactivateOnEndDate: true,
    defaultActionType: '',
    serviceEvents: [],
    deletedServiceEventIds: [],
  },
  billingSchedule: {
    isDifferentBillingSchedule: false,
    isStartDateInvalid: null,
    isEndDateInvalid: null,
    startDate: '',
    endDate: '',
  },
  feeTaxList: [],
  deletedFeeTaxIds: [],
  location: {
    locationId: '',
    locationName: '',
    latitude: '',
    longitude: '',
  },
  advanced: {
    deactivate: false,
    purchaseOrder: false,
    purchaseOrderNumber: '',
    maximumPurchaseOrderAmount: '',
    routeNote: false,
    routeNoteText: '',
    container: true,
    containerList: [{ containerName: 'Container #1', containerDescription: '' }],
    deletedContainerIds: [],
    minimumBillingPeriods: false,
    minimumBillingPeriodsNumber: 0,
    signatureRequired: false,
    signatureType: '',
    skillsRequired: false,
    skillId: '',
  },
}
