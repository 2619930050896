import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { handleError } from 'utils/error'
import { useLazyGetAccountsBySearchQuery } from 'api/accounts/accountSearch'

import T from 'T'
import { useLazyFetchAccountsLinkedToLateFeeQuery, useLinkAccountsToLateFeeMutation } from 'api/pricing/lateFeesCrud'
import HHBulkSelectAccountsDialog from 'components/customers/groups/account/add-remove/common/HHBulkSelectAccountsDialog'
import { get } from 'lodash'
import { selectSelectedLateFee } from 'slices/pricing/lateFeesSlice'

const BulkLinkLateFeesDialog = ({ isOpen = false, onClose }) => {
  const lateFee = useSelector(selectSelectedLateFee, shallowEqual)
  const [fetchAccountsLinkedToLateFee, { isLoading: fetchAccountsLinkedLoading, data: accountsLinkedData }] =
    useLazyFetchAccountsLinkedToLateFeeQuery()
  const lateFeeId = useMemo(() => get(lateFee, 'id'), [lateFee])
  const lateFeeName = useMemo(() => get(lateFee, 'feeName', 'late fee'), [lateFee])
  const [getAccountsBySearch, { isFetching: isLoadingGetAccountsBySearch, data: dataSearchAccounts }] = useLazyGetAccountsBySearchQuery()
  const [linkAccountsToLateFee, { isLoading: isLinkAccountsLoading }] = useLinkAccountsToLateFeeMutation()
  const allAccounts = useMemo(() => get(dataSearchAccounts, 'accounts', []), [dataSearchAccounts])
  const accountIdsAlreadyLinked = useMemo(() => {
    return get(accountsLinkedData, 'accounts', []).map(account => get(account, 'accountId'))
  }, [accountsLinkedData])

  const isRowSelectable = useCallback(
    params => {
      const { row } = params
      const accountId = get(row, 'accountId')
      return !accountIdsAlreadyLinked.includes(accountId)
    },
    [accountIdsAlreadyLinked]
  )

  const handleLinkAccountsToLateFee = ({ accounts }) => {
    const payload = { id: lateFeeId, accounts }
    linkAccountsToLateFee(payload)
      .unwrap()
      .then(() => {
        toast.success(accounts.length > 1 ? T.LINK_ACCOUNTS_TO_LATE_FEE_SUCCESS_MSG : T.LINK_ACCOUNT_TO_LATE_FEE_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      getAccountsBySearch({ search: '' })
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && lateFeeId) {
      fetchAccountsLinkedToLateFee({
        id: lateFeeId,
      })
    }
  }, [isOpen, lateFeeId])

  return (
    <HHBulkSelectAccountsDialog
      isOpen={isOpen}
      onClose={onClose}
      rows={allAccounts}
      title={`Add accounts to ${lateFeeName}`}
      confirmButtonTitle={T.ADD}
      onConfirm={handleLinkAccountsToLateFee}
      isConfirmLoading={isLinkAccountsLoading}
      isLoading={isLoadingGetAccountsBySearch || fetchAccountsLinkedLoading}
      DataGridProps={{
        isRowSelectable,
      }}
      useDisabledCheckedIcons
      defaultSelectionModel={accountIdsAlreadyLinked}
    />
  )
}

BulkLinkLateFeesDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default BulkLinkLateFeesDialog
