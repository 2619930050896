import React, { memo } from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { noop } from 'lodash'
import { useTheme } from '@mui/material'

const UnsavedChangesConfirmDialog = ({ isOpen = false, onClose = noop, onConfirm = noop }) => {
  const theme = useTheme()
  return (
    <HHConfirmDialog
      DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
      isOpen={isOpen}
      confirmTitle={T.UNSAVED_CHANGES}
      confirmDescription={
        <>
          You have unsaved changes in progress.
          <br />
          Are you sure you want to leave this page?
        </>
      }
      CancelButtonProps={{
        size: 'small',
      }}
      ConfirmButtonProps={{
        size: 'small',
        color: 'primary',
      }}
      confirmButtonTitle={T.CONFIRM}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}

UnsavedChangesConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default memo(UnsavedChangesConfirmDialog)
