import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import differenceBy from 'lodash/differenceBy'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'
import { Box, DialogContent, Divider } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetRentalFeesQuery } from 'api/pricing/rentalFeesCrud'
import { useLazyGetRentalFeesByPricedServiceQuery, useLinkRentalFeesToPricedServiceMutation } from 'api/pricing/pricedServiceRentalFeesCrud'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import RenderCell from './common/RenderCell'
import DataGrid from './common/DataGrid'
import RentalFeeListItemSkeleton from '../../common/field/RentalFeeListItemSkeleton'
import { OUTER_CONTENT, getAddDialogContentHeight } from './common/utils'
import { ROW_HEIGHT } from './common/settings'

const AddRentalFeesDialog = ({ isOpen = false, pricedServiceId, callback = noop, onClose }) => {
  const [selectionModel, setSelectionModel] = useState([])
  const [getRentalFees, { isFetching: isGetRentalFeesLoading, data: rentalFeesData }] = useLazyGetRentalFeesQuery()
  const [getRentalFeesByPricedService, { isFetching: isGetRentalFeesByPricedServiceLoading, data: rentalFeesByPricedServiceData }] =
    useLazyGetRentalFeesByPricedServiceQuery()
  const [linkRentalFeesToPricedService, { isLoading: isLinkLoading }] = useLinkRentalFeesToPricedServiceMutation()

  const isListLoading = useMemo(
    () => isGetRentalFeesLoading || isGetRentalFeesByPricedServiceLoading,
    [isGetRentalFeesLoading, isGetRentalFeesByPricedServiceLoading]
  )
  const rentalFeesByPricedService = useMemo(() => get(rentalFeesByPricedServiceData, 'rentalFees', []), [rentalFeesByPricedServiceData])
  const allRentalFees = useMemo(() => get(rentalFeesData, 'rentalFees', []), [rentalFeesData])
  const newRentalFees = useMemo(
    () => differenceBy(allRentalFees, rentalFeesByPricedService, 'id'),
    [allRentalFees, rentalFeesByPricedService]
  )
  const activeRentalFees = useMemo(() => newRentalFees.filter(({ archived }) => !archived), [newRentalFees])
  const sortedRentalFees = useMemo(() => sortBy(activeRentalFees, ['feeName']), [activeRentalFees])
  const sortedAlreadyExistRentalFees = useMemo(() => sortBy(rentalFeesByPricedService, ['feeName']), [rentalFeesByPricedService])
  const hasRentalFees = useMemo(() => sortedRentalFees.length > 0, [sortedRentalFees])
  const hasAlreadyExistRentalFees = useMemo(() => sortedAlreadyExistRentalFees.length > 0, [sortedAlreadyExistRentalFees])
  const maxContentHeight = window.innerHeight - OUTER_CONTENT

  const handleSelectionModelChange = newSelectionModel => {
    setSelectionModel(newSelectionModel)
  }

  const handleAddRentalFee = () => {
    linkRentalFeesToPricedService({ id: pricedServiceId, rentalFees: selectionModel })
      .unwrap()
      .then(() => {
        toast.success(`Rental fee${selectionModel.length === 1 ? '' : 's'} added successfully`)
        callback()
        onClose()
        handleSelectionModelChange([])
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleSelectionModelChange([])
      getRentalFees()
      getRentalFeesByPricedService({ id: pricedServiceId })
    }
  }, [isOpen])

  const { existListContainerHeight, newListContainerHeight } = useMemo(
    () => getAddDialogContentHeight(sortedAlreadyExistRentalFees, sortedRentalFees, maxContentHeight, ROW_HEIGHT),
    [maxContentHeight, sortedAlreadyExistRentalFees, sortedRentalFees]
  )

  const noRowText = useMemo(() => {
    if (!hasRentalFees && !hasAlreadyExistRentalFees) {
      return `${T.NO_RENTAL_FEES} available`
    }
    return 'You have already added all rental fees'
  }, [hasRentalFees, hasAlreadyExistRentalFees])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.ADD_RENTAL_FEE} onClose={onClose} />
      <DialogContent>
        {isListLoading && <RentalFeeListItemSkeleton rows={2} />}
        {!isListLoading && (
          <>
            {hasAlreadyExistRentalFees && (
              <>
                <Box height={existListContainerHeight}>
                  <DataGrid rows={sortedAlreadyExistRentalFees} RenderCell={RenderCell} />
                </Box>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <Box height={newListContainerHeight}>
              <DataGrid
                isSelectable
                rows={sortedRentalFees}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                RenderCell={RenderCell}
                noRowsText={noRowText}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isLinkLoading}
          disabled={isLinkLoading || selectionModel.length === 0}
          label={T.ADD}
          onClick={handleAddRentalFee}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddRentalFeesDialog.propTypes = {
  isOpen: PropTypes.bool,
  pricedServiceId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  onClose: PropTypes.func,
}

export default AddRentalFeesDialog
