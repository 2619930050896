import { hasReadPermissions, hasAddPermissions, hasUpdatePermissions } from 'utils/permissions'
import { getUserInfo } from 'data/userInfo/userInfoSelectors'
import { get } from 'utils/lodash'
import { ALL_PERMISSION_KEYS } from 'settings/constants/permission'
import Constants from 'Constants'

const { createSelector } = require('reselect')

const { CAN_SEE_REPORTS } = Constants
const {
  INVOICES_PERMISSION_KEY,
  PAYMENTS_PERMISSION_KEY,
  CUSTOMER_MENU_PERMISSION_KEY,
  SERVICE_PERMISSION_KEY,
  CUSTOMER_BILLING_PROFILE_PERMISSION_KEY,
  PRICING_MENU_PERMISSION_KEY,
  SETTING_MENU_PERMISSION_KEY,
  USER_SETTINGS_PERMISSION_KEY,
  PERMISSIONS_SETTINGS_PERMISSION_KEY,
  GLOBAL_SEARCH_PERMISSION_KEY,
  DASHBOARD_MENU_PERMISSION_KEY,
  ROUTE_MENU_PERMISSION_KEY,
  BILLING_MENU_PERMISSION_KEY,
  REPORT_MENU_PERMISSION_KEY,
  APPLY_PAYMENTS_PERMISSION_KEY,
  POST_INVOICES_PAYMENT_PERMISSION_KEY,
  RESET_PASSWORD_PERMISSION_KEY,
} = ALL_PERMISSION_KEYS
const DEFAULT_PERMISSION = 0

const getAuthReducer = (state = {}) => get(state, 'AuthReducer.userInfo', {})

const selectUserInfo = createSelector(getAuthReducer, (userInfo = {}) => userInfo)

export const canSeeCustomerBillingProfiles = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, CUSTOMER_BILLING_PROFILE_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeeService = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, SERVICE_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeeGlobalSearch = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, GLOBAL_SEARCH_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeeUserSettings = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, USER_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeePermissionSettings = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, PERMISSIONS_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeeInvoices = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, INVOICES_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeePayments = createSelector([selectUserInfo], userInfo => {
  return hasReadPermissions(get(userInfo, PAYMENTS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canSeeDashboardTab = () => {
  return hasReadPermissions(get(getUserInfo(), DASHBOARD_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeeCustomersTab = () => {
  return hasReadPermissions(get(getUserInfo(), CUSTOMER_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeeRouteTab = () => {
  return hasReadPermissions(get(getUserInfo(), ROUTE_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeeBillingTab = () => {
  return hasReadPermissions(get(getUserInfo(), BILLING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeeReportTab = () => {
  return CAN_SEE_REPORTS && hasReadPermissions(get(getUserInfo(), REPORT_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeePricingTab = () => {
  return hasReadPermissions(get(getUserInfo(), PRICING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

export const canSeeFollowUpTab = () => {
  // FOLLOW_UP_PERMISSION_KEY
  return false
}

export const canSeeSettingsTab = () => {
  return hasReadPermissions(get(getUserInfo(), SETTING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
}

// ADD PERMISSIONS

export const canAddInvoices = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, INVOICES_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddPayments = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, PAYMENTS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddCustomerManager = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, CUSTOMER_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddService = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, SERVICE_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddCustomerBillingProfiles = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, CUSTOMER_BILLING_PROFILE_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddPricingManager = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, PRICING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddSettings = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, SETTING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddUsers = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, USER_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddPermissions = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, PERMISSIONS_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canAddReports = createSelector([selectUserInfo], userInfo => {
  return hasAddPermissions(get(userInfo, REPORT_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

// Update PERMISSIONS

export const canUpdateInvoices = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, INVOICES_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdatePricingManager = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, PRICING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateApplyPayments = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, APPLY_PAYMENTS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdatePostInvoicesPayment = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, POST_INVOICES_PAYMENT_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateCustomerManager = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, CUSTOMER_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateService = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, SERVICE_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateSettings = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, SETTING_MENU_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateUsers = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, USER_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdateResetPassword = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, RESET_PASSWORD_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdatePermissions = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, PERMISSIONS_SETTINGS_PERMISSION_KEY, DEFAULT_PERMISSION))
})

export const canUpdatePayments = createSelector([selectUserInfo], userInfo => {
  return hasUpdatePermissions(get(userInfo, PAYMENTS_PERMISSION_KEY, DEFAULT_PERMISSION))
})
