import { grey } from '@mui/material/colors'

export const NO_TAGS_OPTION_ID = 'NO_TAGS'

export const NO_TAGS_OPTION = {
  id: NO_TAGS_OPTION_ID,
  tagName: 'No tags',
  tagColor: grey[100],
  tagTextColor: '#00000042',
  active: true,
  order: 1,
}
