import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import EmailEventChip from 'components/common/chip/EmailEventChip'

const EmailActivity = ({ chipGridWidth = 120, email }) => {
  const event = get(email, 'event', '')
  const emailTo = get(email, 'email', '')
  const timeStamp = get(email, 'timestamp')
  const utcDateTime = timeStamp ? new Date(timeStamp * 1000).toISOString() : ''
  const { fullDateTime } = convertDateTimeToPreferedTimezone({ dateTime: utcDateTime })

  return (
    <Grid container item xs={12} spacing={2.5} alignItems="center">
      <Grid item xs="auto" minWidth={chipGridWidth}>
        <EmailEventChip eventType={event} />
      </Grid>
      <Grid item xs>
        <Typography variant="h6" fontWeight={500} color="textPrimary">
          {`To: ${emailTo}`}
        </Typography>
        <Typography variant="caption">{fullDateTime}</Typography>
      </Grid>
    </Grid>
  )
}

EmailActivity.propTypes = {
  chipGridWidth: PropTypes.number,
  email: PropTypes.object.isRequired,
}

export default EmailActivity
