import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addUpdatePaymentNote: build.mutation({
      query: ({ paymentNumber, note }) => ({
        url: `/api/v1/billing/payment/${paymentNumber}`,
        method: 'PUT',
        body: { note },
      }),
    }),
  }),
})

export const { useAddUpdatePaymentNoteMutation } = extendedApi
