import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Fade, Backdrop, Box, useTheme } from '@mui/material'

import { memo } from 'utils/react'

import './style.scss'

const BasicModal = ({ isOpen, onClose, children, className, mainClassName }) => {
  const theme = useTheme()
  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className={`${mainClassName}`}
      open={isOpen}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
    >
      <Fade in={isOpen}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            outline: 'none',
            zIndex: theme.zIndex.modal,
          }}
          className={className}
        >
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}

BasicModal.defaultProps = {
  children: null,
  className: null,
}

BasicModal.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(BasicModal)
