export const actions = {
  BILLING_ADD_EDIT_FILTER_VIEW: 'BILLING_ADD_EDIT_SEARCH_FILTER',
  BILLING_DELETE_FILTER_VIEW: 'BILLING_DELETE_SEARCH_FILTER_VIEW',
  BILLING_INVOICES_SEND_INVOICE: 'BILLING_INVOICES_SEND_INVOICE',
  BILLING_INVOICES_POST_INVOICE: 'BILLING_INVOICES_POST_INVOICE',
  GET: {
    BILLING_TABLE_CONTENT: 'BILLING_GET_TAB_TABLE_CONTENT',
    BILLING_INVOICE_META: 'BILLING_GET_INVOICE_META',
    BILLING_GET_SEARCH_FILTER_VIEW_LIST: 'BILLING_GET_SEARCH_FILTER_VIEW_LIST',
    BILLING_SENT_INVOICE_DOWNLOAD: 'BILLING_GET_SENT_INVOICE_DOWNLOAD',
    BILLING_INVOICE_WORK_ORDER: 'BILLING_INVOICE_GET_WORK_ORDER',
  },
  PUT: {
    BILLING_INVOICE_META: 'BILLING_PUT_INVOICE_META',
    INVOICE_TAB_STATE: 'INVOICE_TAB_STATE',
  },
}

export const putInvoiceTabState = (tableState, hasReturnedFromWorkOrderRoute = false) => ({
  type: actions.PUT.INVOICE_TAB_STATE,
  payload: { tableState, hasReturnedFromWorkOrderRoute },
})

export const putBillingInvoiceMeta = res => ({
  type: actions.PUT.BILLING_INVOICE_META,
  payload: res,
})

export const getBillingTableContent = (body, onSuccess) => ({
  type: actions.GET.BILLING_TABLE_CONTENT,
  payload: { body, onSuccess },
})

export const getBillingInvoiceMeta = body => ({
  type: actions.GET.BILLING_INVOICE_META,
  payload: { body },
})

export const getBillingSearchFilterViewList = (body, onSuccess) => ({
  type: actions.GET.BILLING_GET_SEARCH_FILTER_VIEW_LIST,
  payload: { body, onSuccess },
})

export const billingAddEditFilterView = (body, onSuccess) => ({
  type: actions.BILLING_ADD_EDIT_FILTER_VIEW,
  payload: { body, onSuccess },
})

export const billingDeleteFilterView = (body, onSuccess) => ({
  type: actions.BILLING_DELETE_FILTER_VIEW,
  payload: { body, onSuccess },
})

export const billingInvoicesSendInvoice = (body, onSuccess) => ({
  type: actions.BILLING_INVOICES_SEND_INVOICE,
  payload: { body, onSuccess },
})

export const billingInvoicesPostInvoice = (body, onSuccess) => ({
  type: actions.BILLING_INVOICES_POST_INVOICE,
  payload: { body, onSuccess },
})

export const getBillingSentInvoiceDownload = (body, onSuccess) => ({
  type: actions.GET.BILLING_SENT_INVOICE_DOWNLOAD,
  payload: { body, onSuccess },
})

export const getBillingInvoiceWorkOrder = (body, onSuccess) => ({
  type: actions.GET.BILLING_INVOICE_WORK_ORDER,
  payload: { body, onSuccess },
})
