import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import {
  useLazyGetAllWorkOrderNotesQuery,
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
  usePatchWorkOrderNoteMutation,
} from 'api/notes/workOrderNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import ViewArchivedNotesAction from '../common/actions/ViewArchivedNotesAction'
import ViewArchiveNotesDialog from '../common/ViewArchiveNotesDialog'
import WONotes from './WONotes'
import NoteSkeleton from '../common/NoteSkeleton'

const WONotesAccordionVariant = ({ workOrderId = '' }) => {
  const [getAllWorkOrderNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllWorkOrderNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes, archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddWONote = event => {
    event.stopPropagation()
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleViewArchiveClick = () => setIsOpenViewArchiveNoteDialog(true)

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  const handleCloseAddWONote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (workOrderId) {
      getAllWorkOrderNotes({ id: workOrderId }).unwrap().catch(handleError)
    }
  }, [workOrderId])

  return (
    <>
      <HHAccordion
        withNewColors
        isExpanded
        isExpandCollapseHeightSame
        summary={`${T.WORK_ORDER_NOTE}s`}
        showActionIcon={false}
        showCustomActions
        customActionContent={
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleOpenAddWONote}>
              <AddIcon />
            </IconButton>
            <ViewArchivedNotesAction onViewArchiveClick={handleViewArchiveClick} archivedNotes={archivedNotes} />
          </Box>
        }
      >
        {isGetAllNotesLoading && <NoteSkeleton rows={3} />}
        {!isGetAllNotesLoading && <WONotes notes={notes} workOrderId={workOrderId} />}
      </HHAccordion>
      <AddUpdateNoteDialog
        id={workOrderId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_WORK_ORDER_NOTE}
        useCreateNoteMutation={useCreateWorkOrderNoteMutation}
        useUpdateNoteMutation={useUpdateWorkOrderNoteNewMutation}
        onClose={handleCloseAddWONote}
      />
      <ViewArchiveNotesDialog
        id={workOrderId}
        isOpen={isOpenViewArchiveNoteDialog}
        title={T.ARCHIVED_WORK_ORDER_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchWorkOrderNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

WONotesAccordionVariant.propTypes = {
  workOrderId: PropTypes.string,
}

export default WONotesAccordionVariant
