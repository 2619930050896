import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Chip, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ResidentialIcon } from 'assets/business-line/Residential.svg'
import { ReactComponent as CommercialIcon } from 'assets/business-line/Commercial.svg'
import { ReactComponent as RolloffIcon } from 'assets/business-line/Rolloff.svg'

import { get } from 'utils/lodash'
import { BUSINESS_LINE_TYPE, BUSINESS_LINE_TYPE_TO_COLOR_MAP } from 'settings/constants/businessLine'
import { ALPHA_38_PERCENT } from 'theme/colors'

const { RESIDENTIAL, COMMERCIAL, ROLL_OFF } = BUSINESS_LINE_TYPE

export const BUSINESS_LINE_TYPE_TO_SVG = {
  [RESIDENTIAL]: ResidentialIcon,
  [COMMERCIAL]: CommercialIcon,
  [ROLL_OFF]: RolloffIcon,
}

const BusinessLineChip = ({ businessLineType = '', businessLineName }) => {
  const theme = useTheme()
  const Icon = get(BUSINESS_LINE_TYPE_TO_SVG, businessLineType)
  const backgroundColor = get(BUSINESS_LINE_TYPE_TO_COLOR_MAP, businessLineType)

  return businessLineName ? (
    <Chip
      size="small"
      avatar={
        Icon ? (
          <Avatar
            sx={{
              backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}`,
            }}
          >
            <SvgIcon inheritViewBox sx={{ fontSize: 14, color: theme.palette.background.paper }}>
              <Icon />
            </SvgIcon>
          </Avatar>
        ) : null
      }
      label={businessLineName}
      sx={{ ...(backgroundColor && { backgroundColor }) }}
    />
  ) : (
    ''
  )
}

BusinessLineChip.propTypes = {
  businessLineType: PropTypes.string,
  businessLineName: PropTypes.string,
}

export default BusinessLineChip
