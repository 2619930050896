import React from 'react'
import PropTypes from 'prop-types'

import AutoComplete from 'components/common/AutoComplete'
import { DAYS } from 'components/pricing/settings'

import T from 'T'

import { get } from 'utils/lodash'

const MethodDaysAutoComplete = ({ globalFilterOptions, getByLabelText, checkedMethods, checkedDays, handleCheckboxValue, backFlag }) => {
  return (
    <>
      <div className="methods left-elements-width mt15">
        <div className="service-label">{`${T.METHOD}s`}</div>

        <AutoComplete
          backFlag={backFlag}
          isHandleCheckbox
          metaData={get(globalFilterOptions, 'methods', [])}
          label={`${T.METHOD}s`}
          getByLabelText={option => option.methodName}
          handleCheckboxValue={handleCheckboxValue}
          checkedRecords={checkedMethods}
          getPlaceholder={checkedMethods.length ? '' : `Select ${T.METHOD}s`}
        />
      </div>

      <div className="specific-days left-elements-width mt15">
        <div className="service-label">{T.SPECIFIC_DAYS}</div>

        <AutoComplete
          backFlag
          maxHeight={230}
          isHandleCheckbox
          metaData={DAYS}
          label={`${T.SPECIFIC_DAYS}`}
          getByLabelText={option => option.value}
          handleCheckboxValue={handleCheckboxValue}
          checkedRecords={checkedDays}
          getPlaceholder={checkedDays.length ? '' : `Select ${T.SPECIFIC_DAYS}`}
        />
      </div>
    </>
  )
}

MethodDaysAutoComplete.defaultProps = {
  getByLabelText: null,
  globalFilterOptions: {},
  handleCheckboxValue: null,
  checkedMethods: [],
  checkedDays: [],
  backFlag: false,
}

MethodDaysAutoComplete.propTypes = {
  getByLabelText: PropTypes.func,
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedMethods: PropTypes.array,
  checkedDays: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default MethodDaysAutoComplete
