import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addEditFilterView: build.mutation({
      query: body => ({
        url: `/api/report/data/filter/save`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAddEditFilterViewMutation } = extendedApi
