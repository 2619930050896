import T from 'T'

const { DATE_AND_TIME, CUSTOMERS, TOTAL_INVOICES, TOTAL_REVENUE, STATUS } = T

export const SENT_INVOICES_CONFIGURATOR = [
  { tableLabel: CUSTOMERS, align: 'right', checked: true, columnName: 'totalAccounts', filterType: 'number', width: '10%' },
  {
    tableLabel: DATE_AND_TIME,
    align: 'center',
    checked: true,
    columnName: 'sentTime',
    filterType: 'date',
    subType: 'dateTime',
    className: 'font-500',
    width: '25%',
  },
  { tableLabel: TOTAL_INVOICES, align: 'right', checked: true, columnName: 'totalInvoices', filterType: 'number', width: '16%' },
  {
    tableLabel: TOTAL_REVENUE,
    align: 'right',
    checked: true,
    columnName: 'totalRevenue',
    filterType: 'number',
    subType: 'price',
    width: '15%',
  },
  {
    tableLabel: STATUS,
    align: 'center',
    checked: true,
    columnName: 'status',
    filterType: 'checkbox',
    subType: 'sentInvoicesStatus',
    isSingleSelect: true,
  },
]
