import { styled } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import React from 'react'
import PropTypes from 'prop-types'
import GeneralFeesDetails from './GeneralFeesDetails'

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid ${theme.palette.common.white}`,
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
  },
}))

const WithGeneralFeesDetailsTooltip = ({ children, workOrderFees }) => {
  const disable = workOrderFees.length === 0

  return (
    <NoMaxWidthTooltip disableTouchListener title={disable ? '' : <GeneralFeesDetails workOrderFees={workOrderFees} />}>
      {children}
    </NoMaxWidthTooltip>
  )
}

WithGeneralFeesDetailsTooltip.propTypes = {
  children: PropTypes.node,
  workOrderFees: PropTypes.array,
}

export default WithGeneralFeesDetailsTooltip
