import api from 'api'
import { CACHE_TAG_BILLING_NOTES_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllBillingNotes: builder.query({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/billing-notes`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_BILLING_NOTES_LIST],
    }),
    getBillingNoteDetails: builder.query({
      query: ({ accountId, noteId }) => ({
        url: `/api/v1/core/accounts/${accountId}/billing-notes/${noteId}`,
        method: 'GET',
      }),
    }),
    getBillingNoteHistory: builder.query({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/accounts/${id}/billing-notes/${noteId}/history`,
        method: 'GET',
      }),
    }),
    createBillingNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/${id}/billing-notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_BILLING_NOTES_LIST],
    }),
    updateBillingNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/accounts/${id}/billing-notes/${noteId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_BILLING_NOTES_LIST],
    }),
    deleteBillingNote: builder.mutation({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/accounts/${id}/billing-notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CACHE_TAG_BILLING_NOTES_LIST],
    }),
    patchBillingNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/accounts/${id}/billing-notes/${noteId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [CACHE_TAG_BILLING_NOTES_LIST],
    }),
  }),
})

export const {
  useLazyGetAllBillingNotesQuery,
  useLazyGetBillingNoteDetailsQuery,
  useLazyGetBillingNoteHistoryQuery,
  useCreateBillingNoteMutation,
  useUpdateBillingNoteMutation,
  useDeleteBillingNoteMutation,
  usePatchBillingNoteMutation,
} = extendedApi
