import React from 'react'
import { Grid, Typography, Tooltip, Box } from '@mui/material'
import { capitalize, get } from 'lodash'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Constants from '../../../../../Constants'
import { getPricingPeriod } from '../../../../../utils/service'
import ScheduleItem from '../ScheduleItem'

const { MEDIA_SERVER } = Constants

const getEventsHashMap = events => {
  const eventsMap = new Map()
  for (const event of events) {
    const day = get(event, 'dayOfTheWeek', '')
    if (!eventsMap.has(day)) {
      eventsMap.set(capitalize(day), event)
    }
  }
  return eventsMap
}
const BottomRow = ({ service }) => {
  const businessLineLabel = get(service, 'businessLine.businessLineLabel', '')
  const businessLinIcon = get(service, 'businessLine.businessLineIcon', '')
  const methodType = get(service, 'method.methodType', '')
  const methodIcon = methodType.replace(' ', '')
  const recurrence = get(service, 'recurrence', '')
  const isDraftRow = get(service, 'status', '').toLowerCase() === 'draft'
  const eventInstances = get(service, 'eventInstances', [])
  const recurrenceFrequency = get(recurrence, 'recurrenceFrequency', 1)
  const recurrencePer = get(recurrence, 'recurrencePer', '')
  const recurrenceInterval = get(recurrence, 'recurrenceInterval', '')
  const pricingPeriod = getPricingPeriod(recurrencePer, recurrenceInterval)
  const eventsMap = getEventsHashMap(eventInstances)
  const { watch } = useFormContext()
  const upcoming = watch('upcoming')
  const getEvent = (index, day) => {
    return upcoming ? get(eventInstances, index, undefined) : eventsMap.get(day)
  }

  return (
    <Grid item container direction="row" columnGap={2} alignItems="center" flexWrap="nowrap" xs zeroMinWidth>
      <Box width="100px">
        <Grid container item xs="auto" columnGap={0.5} flexWrap="nowrap" alignItems="center">
          {businessLineLabel && (
            <Grid container justifyContent="center" alignItems="center" item xs>
              <Tooltip title={businessLineLabel}>
                <img src={`${MEDIA_SERVER}${businessLinIcon}`} alt="" />
              </Tooltip>
            </Grid>
          )}
          {methodType && (
            <Grid container justifyContent="center" alignItems="center" item xs>
              <Tooltip title={methodType}>
                <img src={`${MEDIA_SERVER}Method/${methodIcon}.svg`} alt="" />
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Typography
              variant="subtitle1"
              fontWeight="regular"
              color={service.deactivate || isDraftRow ? 'text.disabled' : 'textPrimary'}
              noWrap
            >
              {recurrence ? `${recurrenceFrequency}x ${pricingPeriod}` : 'On Request'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid item container directiom="row" xs justifyContent="flex-end" alignItems="center" columnGap={1} flexWrap="nowrap" wrap="nowrap">
        <ScheduleItem event={getEvent(0, 'Sunday')} />
        <ScheduleItem event={getEvent(1, 'Monday')} />
        <ScheduleItem event={getEvent(2, 'Tuesday')} />
        <ScheduleItem event={getEvent(3, 'Wednesday')} />
        <ScheduleItem event={getEvent(4, 'Thursday')} />
        <ScheduleItem event={getEvent(5, 'Friday')} />
        <ScheduleItem event={getEvent(6, 'Saturday')} />
      </Grid>
    </Grid>
  )
}

BottomRow.propTypes = {
  service: PropTypes.object.isRequired,
}

export default BottomRow
