import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import { get } from 'utils/lodash'
import { canSeeCustomersTab } from 'data/permissions/permissionsSelectors'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'

import TabMainLayout from '../layout/TabMainLayout'
import TabLeftLayout from '../layout/TabLeftLayout'
import TabRightLayout from '../layout/TabRightLayout'
import AccountDetails from '../content/account-details'
import Contacts from '../content/contacts'
import CustomerPortal from '../content/customer-portal'
import AllLocations from '../content/location/list'
import { FollowUp, Calls } from '../tables'
import AccountNotesAccordionVariant from '../../notes/account/AccountNotesAccordionVariant'

const { ACCOUNT_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const AccountTab = ({ selectedTab = '', customerDetails = {}, onLocationChange }) => {
  const accountId = get(customerDetails, 'id')
  // Only call API when we change the tabs
  const [isAccountTabReady, setIsAccountTabReady] = useState(false)

  useEffect(() => {
    if (selectedTab === ACCOUNT_TAB_ID) {
      setIsAccountTabReady(true)
    }
  }, [selectedTab])

  return (
    isAccountTabReady && (
      <TabMainLayout>
        <TabLeftLayout>
          {canSeeCustomersTab && <Contacts customerDetails={customerDetails} />}
          <Box mt={2}>
            <CustomerPortal accountId={accountId} />
          </Box>
        </TabLeftLayout>
        <TabRightLayout>
          <AccountDetails customerDetails={customerDetails} accountId={accountId} />
          <Box mt={2}>
            <AllLocations accountId={accountId} customerDetails={customerDetails} onLocationChange={onLocationChange} />
          </Box>
          <Box mt={2}>
            <AccountNotesAccordionVariant accountId={accountId} />
          </Box>
          <Box mt={2}>
            <FollowUp accountId={accountId} />
          </Box>
          <Box mt={2}>
            <Calls accountId={accountId} />
          </Box>
        </TabRightLayout>
      </TabMainLayout>
    )
  )
}

AccountTab.propTypes = {
  selectedTab: PropTypes.string,
  customerDetails: PropTypes.object,
  onLocationChange: PropTypes.func.isRequired,
}

export default AccountTab
