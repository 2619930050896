import T from 'T'

const {
  BUSINESS_LINE,
  CUSTOMER_NAME,
  ACCOUNT_NUMBER,
  BILLING_PROFILE,
  STATUS,
  BILLING_ADDRESS,
  LOCATION,
  ADDRESS,
  WORK_ORDER,
  NOTE,
  PHONE,
  SERVICE,
  PRICE,
  TOTAL,
  AR_BALANCE,
  ACCOUNT_CREDIT,
  CUSTOMER,
} = T

export const WORK_ORDER_CONFIGURATOR = [
  {
    label: `${WORK_ORDER} #`,
    tableLabel: `${WORK_ORDER} #`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'workOrderSeq',
  },
  {
    label: `${LOCATION} ${ADDRESS}`,
    tableLabel: `${LOCATION} ${ADDRESS}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'locLine1',
  },
  {
    tableLabel: STATUS,
    label: STATUS,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'checkbox',
    columnName: 'status',
    subType: 'workOrderStatus',
  },
  {
    tableLabel: BILLING_PROFILE,
    label: BILLING_PROFILE,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'profile',
  },
  {
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'businessLine',
  },

  {
    tableLabel: CUSTOMER_NAME,
    label: CUSTOMER_NAME,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'accountName',
  },
  {
    label: `${PHONE} #`,
    tableLabel: `${PHONE} #`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'contactNumber',
    subType: 'woPhone',
  },
  {
    tableLabel: ACCOUNT_NUMBER,
    label: ACCOUNT_NUMBER,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'accountNumber',
    className: 'font-500',
  },
  {
    tableLabel: BILLING_ADDRESS,
    label: BILLING_ADDRESS,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'line1',
  },
  {
    label: `${WORK_ORDER} ${NOTE}`,
    tableLabel: `${WORK_ORDER} ${NOTE}`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'notes',
    subType: 'woNotes',
  },
  {
    label: `${SERVICE} ${PRICE}`,
    tableLabel: `${SERVICE} ${PRICE}`,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    subType: 'price',
    columnName: 'servicePrice',
  },
  {
    label: TOTAL,
    tableLabel: TOTAL,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    subType: 'price',
    columnName: 'total',
  },
  {
    label: `${CUSTOMER} ${AR_BALANCE}`,
    tableLabel: `${CUSTOMER} ${AR_BALANCE}`,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    subType: 'price',
    columnName: 'balance',
  },
  {
    label: `${CUSTOMER} ${ACCOUNT_CREDIT}`,
    tableLabel: `${CUSTOMER} ${ACCOUNT_CREDIT}`,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    subType: 'price',
    columnName: 'creditLimit',
  },
]
