import React from 'react'
import PropTypes from 'prop-types'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'

const PricingWrapper = ({ children, className = '', sx }) => {
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Stack
      flexDirection="column"
      flexWrap="nowrap"
      sx={{
        maxWidth: isOpenPricingDrawer && !isTabletOrMobile ? 'calc(100vw - 270px)' : '100vw',
        overflow: 'hidden',
        ...sx,
      }}
      className={className}
    >
      {children}
    </Stack>
  )
}

PricingWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sx: PropTypes.object,
}

export default PricingWrapper
