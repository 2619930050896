import { Box, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React from 'react'
import { noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import StickyTableCell from './StickyTableCell'

const ActionsColumn = ({ sx, disabled = false, size = 'large', onClick = noop }) => {
  const theme = useTheme()
  return (
    <StickyTableCell
      padding="none"
      sx={{
        ...sx,
        right: '-16px',
        width: '64px',
        background: theme.palette.background.paper,
      }}
      zIndex={2}
      onClick={event => event.stopPropagation()}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{ width: 48, borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: theme.palette.divider }}
      >
        <IconButton disabled={disabled} size={size} onClick={onClick}>
          <MoreVertIcon />
        </IconButton>
      </Box>
    </StickyTableCell>
  )
}

ActionsColumn.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
}

export default ActionsColumn
