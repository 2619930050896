import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@mui/material'
import { get, capitalize } from 'lodash'
import T from '../../../../T'
import MethodChip from '../../../common/chip/MethodChip'
import { HHFormAutocompleteField } from '../../../form-fields/v5'

const MethodsAutocompleteFormField = ({ sx, options, control, name }) => {
  const renderTags = (tags, getTagProps) =>
    tags.map((item, index) => {
      const methodType = get(item, 'methodType', '')
      return <MethodChip methodType={methodType} size="small" {...getTagProps({ index })} />
    })

  const renderOptions = (props, option) => {
    const methodType = get(option, 'methodType', '')
    return (
      <MenuItem {...props}>
        <MethodChip methodType={methodType} size="small" />
      </MenuItem>
    )
  }

  const getOptionLabel = option => capitalize(get(option, 'methodType', ''))

  return (
    <HHFormAutocompleteField
      getOptionLabel={getOptionLabel}
      sx={sx}
      fullWidth
      options={options}
      name={name}
      control={control}
      label={T.METHOD}
      deprecatedLabel={false}
      renderTags={renderTags}
      renderOption={renderOptions}
    />
  )
}

MethodsAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  options: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default MethodsAutocompleteFormField
