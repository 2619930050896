import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Button, DialogContent, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material'
import { ReactComponent as ArrowRotateLeftIcon } from 'assets/ArrowRotateLeftIcon.svg'

import { handleError } from 'utils/error'
import { getNoteUserInfo } from 'data/notes/notesSelectors'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const ViewArchiveNotesDialog = ({ id, isOpen = false, title = '', notes = [], useUnarchiveNoteMutation, onClose }) => {
  const [unarchiveNote, { isLoading: isUnarchiveNoteLoading }] = useUnarchiveNoteMutation()
  const [isOpenConfirmUnarchiveDialog, setIsOpenConfirmUnarchiveDialog] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  const handlUnarchiveClick = note => {
    setSelectedNote(note)
    onClose()
    setIsOpenConfirmUnarchiveDialog(true)
  }

  const handleCloseUnarchiveDialog = () => setIsOpenConfirmUnarchiveDialog(false)

  const handleConfirmUnarchived = () => {
    unarchiveNote({ id, noteId: selectedNote.id, data: { archived: false } })
      .unwrap()
      .then(() => {
        setSelectedNote(null)
        handleCloseUnarchiveDialog()
        toast.success('Note unarchived successfully')
      })
      .catch(handleError)
  }

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={title} onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ py: 0 }}>
            {Children.toArray(
              notes.map((note, index) => {
                const { note: noteText } = note
                const { firstName, lastName, dateTime, username } = getNoteUserInfo({ note })
                const fullName = `${firstName} ${lastName}`.trim()
                const displayName = fullName || username || '--'
                const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime })

                return (
                  <>
                    <ListItem
                      sx={{ pl: 3 }}
                      secondaryAction={
                        <IconButton onClick={() => handlUnarchiveClick(note)}>
                          <ArrowRotateLeftIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primaryTypographyProps={{ variant: 'h6', fontWeight: 400, noWrap: true }}
                        primary={noteText}
                        secondaryTypographyProps={{ variant: 'subtitle2', fontWeight: 400 }}
                        secondary={`Created by ${displayName} | ${onlyDate} @ ${onlyTime}`}
                      />
                    </ListItem>
                    {notes.length - 1 !== index && <Divider />}
                  </>
                )
              })
            )}
          </List>
        </DialogContent>
        <HHDialogActions>
          <Button size="small" variant="contained" color="primary" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <HHConfirmDialog
        isOpen={isOpenConfirmUnarchiveDialog}
        DialogProps={{ maxWidth: 'xs', fullWidth: true, PaperProps: { sx: { maxWidth: '375px' } } }}
        confirmTitle={T.UNARCHIVE_NOTE}
        confirmButtonTitle={T.UNARCHIVE}
        ConfirmButtonProps={{ loading: isUnarchiveNoteLoading, color: 'primary' }}
        confirmDescription={T.UNARCHIVE_NOTE_CONFIRMATION_DESCRIPTION}
        onConfirm={handleConfirmUnarchived}
        onClose={handleCloseUnarchiveDialog}
      />
    </>
  )
}

ViewArchiveNotesDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  notes: PropTypes.array,
  useUnarchiveNoteMutation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ViewArchiveNotesDialog
