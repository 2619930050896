import React from 'react'
import Box from '@mui/material/Box'
import HHTextField from 'components/form-fields/v5/HHTextField'
import T from 'T'
import CardInformationInvalidAlert from 'components/customer-details/content/payments/common/CardInformationInvalidAlert'
import { isCVV } from 'utils/validations'
import PropTypes from 'prop-types'
import { PAYMENT_METHODS } from 'settings/constants/payment'
import RenderPaymentIframe from './common/RenderPaymentIframe'

const { CREDIT_CARD } = PAYMENT_METHODS

const RenderCreditCardFields = ({
  cardHolderName,
  onChange,
  onNewCardTokenChange,
  cardHolderNameError,
  showInvalidFormAlert,
  cvv,
  cardHolderNameInputRef,
}) => {
  return (
    <Box>
      <HHTextField
        inputRef={cardHolderNameInputRef}
        label={T.CARDHOLDER_NAME}
        required
        helperText={cardHolderNameError}
        error={Boolean(cardHolderNameError)}
        placeholder="John Doe"
        fullWidth
        value={cardHolderName}
        onChange={event => onChange('cardHolderName', event.target.value)}
      />
      {showInvalidFormAlert && <CardInformationInvalidAlert isCreditCardForm />}

      <RenderPaymentIframe type={CREDIT_CARD} callback={onNewCardTokenChange} />

      <Box mt={2}>
        <HHTextField
          label="CVV"
          placeholder="123"
          fullWidth
          value={cvv}
          onChange={event => {
            const eventValue = event.target.value
            // CVV is optional, so can be less than 3-4 digits
            if (isCVV(eventValue) || eventValue.length < 4) {
              onChange('cvv', eventValue)
            }
          }}
        />
      </Box>
    </Box>
  )
}

RenderCreditCardFields.propTypes = {
  cardHolderName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewCardTokenChange: PropTypes.func.isRequired,
  cardHolderNameError: PropTypes.string,
  showInvalidFormAlert: PropTypes.bool.isRequired,
  cvv: PropTypes.string.isRequired,
  cardHolderNameInputRef: PropTypes.object.isRequired,
}

export default RenderCreditCardFields
