import { createSlice } from '@reduxjs/toolkit'

const initialState = { pricingFiltersMeta: {} }

const pricingFiltersMetaSlice = createSlice({
  name: 'PricingFiltersMetaSlice',
  initialState,
  reducers: {
    pricingMeta: (state, action) => {
      state.records = action.payload.data
    },
  },
})
export const { pricingMeta } = pricingFiltersMetaSlice.actions

export default pricingFiltersMetaSlice.reducer
