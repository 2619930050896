import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Table as TableView, TableContainer } from '@mui/material'

import OfficeTableHeader from 'components/settings/pages/users/roles/OfficeTableHeader'

import { memo } from 'utils/react'

import { ACCESS_VALUES } from 'settings/constants/roles'
import PermissionScreenTableBody from './PermissionScreenTableBody'

const PermissionScreen = ({ roleState = {}, setRoleState = noop, readonly = '', calledBy = '' }) => {
  const setMaxHeight = { xs: 'calc(100vh - 495px)', md: 'calc(100vh - 450px)' }
  return (
    <TableContainer sx={{ maxHeight: calledBy ? setMaxHeight : 'calc(100vh - 350px)', overflowY: 'auto' }}>
      <TableView stickyHeader aria-label="sticky table" sx={{ pointerEvents: readonly }}>
        <OfficeTableHeader values={ACCESS_VALUES} />
        <PermissionScreenTableBody roleState={roleState} setRoleState={setRoleState} />
      </TableView>
    </TableContainer>
  )
}

PermissionScreen.propTypes = {
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
  readonly: PropTypes.string,
  calledBy: PropTypes.string,
}

export default memo(PermissionScreen)
