import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import Box from '@mui/material/Box'
import AttributesSettings from 'components/settings/pages/businesslines/AttributesSettings'
import CreateConfigureSettings from 'components/settings/pages/businesslines/CreateConfigureSettings'
import Loader from 'components/common/loader'
import { useLazyGetBusinessLineSettingsQuery } from 'api/settings/getBusinessLineSettings'
import { useLazyGetBusinessLineConfigurationQuery } from 'api/settings/getBusinessLineConfiguration'

import { handleError } from 'utils/error'

import T from 'T'
import { get } from 'utils/lodash'

const BusinessLineSettings = ({ selectedTab = '', businessLineOption = '' }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    service: {},
    measure: {},
    material: [],
    action: [],
    method: [],
    configurations: [],
    businessLines: {},
  })

  const { service, measure, material, action, method, configurations, businessLines } = localState

  const [getBusinessLineSettings, { isLoading, isFetching }] = useLazyGetBusinessLineSettingsQuery()
  const [getBusinessLineConfiguration, { isLoading: isConfLoading, isFetching: isConfFetching }] =
    useLazyGetBusinessLineConfigurationQuery()

  const getBusinessLines = () => {
    getBusinessLineSettings()
      .unwrap()
      .then(res => {
        setLocalState({
          service: res.service,
          measure: res.measure,
          material: res.material,
          action: res.action,
          method: res.method,
          businessLines: cloneDeep(res),
        })
      })
      .catch(handleError)
  }

  useEffect(() => {
    getBusinessLines()
  }, [selectedTab === 'attributes'])

  const getBusinessLineConfigurations = () => {
    getBusinessLineConfiguration()
      .unwrap()
      .then(res => {
        if (get(res, 'configurations', []).length > 0) {
          setLocalState({
            configurations: res.configurations,
          })
        }
      })
      .catch(handleError)
  }

  useEffect(() => {
    getBusinessLineConfigurations()
  }, [selectedTab === 'create-configure'])

  return (
    <Box maxHeight="calc(100vh - 100px)" sx={{ overflowY: 'auto' }}>
      {(isLoading || isConfFetching || isConfLoading || isFetching) && <Loader />}
      {businessLineOption === T.ATTRIBUTES && (
        <AttributesSettings
          service={service}
          measure={measure}
          material={material}
          action={action}
          method={method}
          getBusinessLines={getBusinessLines}
        />
      )}
      {businessLineOption === T.CREATE_CONFIGURE && (
        <CreateConfigureSettings
          service={service}
          measure={measure}
          material={material}
          action={action}
          method={method}
          configurations={configurations}
          businessLines={businessLines}
          getBusinessLineConfigurations={getBusinessLineConfigurations}
          getBusinessLines={getBusinessLines}
        />
      )}
    </Box>
  )
}

BusinessLineSettings.propTypes = {
  selectedTab: PropTypes.string,
  businessLineOption: PropTypes.string,
}

export default BusinessLineSettings
