import React from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { CircularProgress, Stack, Tooltip, SvgIcon } from '@mui/material'
import { useActivateAccountMutation } from 'api/accounts/activateAccount'
import { get } from 'utils/lodash'
import { capitalize } from 'lodash'
import IconButton from '@mui/material/IconButton'
import { getCustomerDetails } from 'middleware/actions/customers'
import api from 'api'
import { CACHE_TAG_CONFIGURED_SERVICES_LIST, CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import { useDispatch } from 'react-redux'
import { ReactComponent as ReactiveAccountIcon } from 'assets/customers/ReactiveAccount.svg'

const ReactivateAccountIconButton = ({ accountId, customerDetails }) => {
  const dispatch = useDispatch()
  const [activateAccount, { isLoading }] = useActivateAccountMutation()
  const isActiveAccount = get(customerDetails, 'active')

  const handleActivateDeactivateSuccess = () => {
    dispatch(getCustomerDetails({ accountId }))
    // Trigger configured service & work order table updates
    dispatch(api.util.invalidateTags([CACHE_TAG_CONFIGURED_SERVICES_LIST, CACHE_TAG_WORK_ORDER_LIST]))
  }

  const handleAccountActivateDeactivate = () => {
    if (isActiveAccount) {
      return
    }
    // Activate account
    activateAccount({ accountId })
      .unwrap()
      .then(() => {
        handleActivateDeactivateSuccess()
        toast.success(T.ACCOUNT_REACTIVATED_SUCCESSFULLY)
      })
      .catch(handleError)
  }

  return (
    <>
      <Tooltip title={capitalize(`${T.REACTIVATE} ${T.ACCOUNT}`)} arrow>
        <span>
          {isLoading ? (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress thickness={8} size={16} />
            </Stack>
          ) : (
            <IconButton onClick={handleAccountActivateDeactivate}>
              <SvgIcon inheritViewBox>
                <ReactiveAccountIcon />
              </SvgIcon>
            </IconButton>
          )}
        </span>
      </Tooltip>
    </>
  )
}

ReactivateAccountIconButton.propTypes = {
  accountId: PropTypes.string.isRequired,
  customerDetails: PropTypes.object.isRequired,
}

export default ReactivateAccountIconButton
