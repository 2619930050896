import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, Stack, Grid, InputAdornment, Typography, SvgIcon, useTheme } from '@mui/material'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import { HHFormAutocompleteField, HHFormNumberField, HHFormPriceField, HHFormSelectField, HHFormTextField } from 'components/form-fields/v5'
import { useForm } from 'react-hook-form'
import { get, noop, capitalize } from 'lodash'
import {
  CUSTOM_LATE_FEE_OPTION,
  CUSTOM_LATE_FEE_VALUE,
  formatDataToPayload,
  getDurationMeasureUnit,
  getDurationOptions,
  getStartOffsetOptions,
  INDEFINITE_LATE_FEE_OPTION,
  IMMEDIATE_LATE_FEE_OPTION,
  LATE_FEE_FREQUENCY,
  LATE_FEE_FREQUENCY_OPTIONS,
  NONE_BUSINESS_LINE_OPTION,
  transformServerDataToForm,
} from 'components/pricing/LateFees/CreateEditLateFeesDialog/settings'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import HHDialogActions from 'components/common/HHDialogActions'
import { ReactComponent as ArrowSpin } from 'assets/ArrowSpin.svg'
import { ReactComponent as CalendarDay } from 'assets/CalendarDay.svg'
import { ReactComponent as CalendarWeek } from 'assets/CalendarWeek.svg'
import { useCreateLateFeeMutation, useLazyFetchLateFeeDetailsQuery, useUpdateLateFeeMutation } from 'api/pricing/lateFeesCrud'
import { toast } from 'react-toastify'
import { useLazyFetchMetaFiltersQuery } from 'api/meta/getMetaFilters'
import { handleError } from 'utils/error'

const defaultValues = {
  feeName: '',
  amount: '',
  businessLine: NONE_BUSINESS_LINE_OPTION,
  pricingPeriod: LATE_FEE_FREQUENCY.ONCE,
  startOffset: '',
  duration: '',
  customDuration: 0,
  customStartOffset: 0,
}

const CreateEditLateFeesDialog = ({ archived = false, id, open = false, onClose = noop }) => {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  })
  const theme = useTheme()
  const feeNameHelperText = get(errors, 'feeName.message')
  const amountHelperText = get(errors, 'amount.message')
  const [createLateFee, { isLoading: isCreateLoading }] = useCreateLateFeeMutation()
  const [updateLateFee, { isLoading: isUpdateLoading }] = useUpdateLateFeeMutation()
  const [
    fetchMetaFilters,
    { isLoading: isMetaFiltersLoading, isFetching: isMetaFiltersFetching, data: metaFiltersData, isSuccess: isSuccessMetaFilters },
  ] = useLazyFetchMetaFiltersQuery()
  const loadingBusinessOptions = useMemo(() => isMetaFiltersLoading || isMetaFiltersFetching, [isMetaFiltersLoading, isMetaFiltersFetching])
  const loading = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading])
  const [fetchLateFeeDetails] = useLazyFetchLateFeeDetailsQuery()
  const pricingPeriod = watch('pricingPeriod')
  const startOffset = watch('startOffset')
  const duration = watch('duration')
  const durationOptions = useMemo(() => getDurationOptions(pricingPeriod), [pricingPeriod])
  const startOffsetOptions = useMemo(() => getStartOffsetOptions(pricingPeriod), [pricingPeriod])
  const durationMeasureUnit = useMemo(() => getDurationMeasureUnit(pricingPeriod), [pricingPeriod])
  const isCustomStartOffset = useMemo(() => startOffset === CUSTOM_LATE_FEE_VALUE, [startOffset])
  const isCustomDuration = useMemo(() => duration === CUSTOM_LATE_FEE_VALUE, [duration])
  const isIndefiniteDuration = useMemo(() => duration === 0, [duration])
  const isPricingPeriodOnce = useMemo(() => pricingPeriod === LATE_FEE_FREQUENCY.ONCE, [pricingPeriod])
  const gridSpan = useMemo(() => (isPricingPeriodOnce ? 12 : 4), [isPricingPeriodOnce])

  const getOptionLabel = ({ label }) => label

  const businessLineOptions = useMemo(() => {
    const { businessLines = [] } = metaFiltersData || {}
    const formattedBusinessLineOptions = businessLines
      .filter(({ active }) => active)
      .map(({ id, businessLineLabel }) => ({ label: businessLineLabel, value: id }))
    formattedBusinessLineOptions.unshift(NONE_BUSINESS_LINE_OPTION)
    return formattedBusinessLineOptions
  }, [metaFiltersData])

  const onSave = async data => {
    const payload = formatDataToPayload(data)
    if (id) {
      await updateLateFee({ id, ...payload })
        .unwrap()
        .then(() => {
          toast.success(T.LATE_FEE_UPDATED_SUCCESSFULLY)
          onClose()
        })
        .catch(handleError)
    } else {
      await createLateFee(payload)
        .unwrap()
        .then(() => {
          toast.success(T.LATE_FEE_CREATED_SUCCESSFULLY)
          onClose()
        })
        .catch(handleError)
    }
    reset(defaultValues)
  }

  const handlePricingPeriodChange = updatedPricingPeriod => {
    const isUpdatedPricingPeriodOnce = updatedPricingPeriod === LATE_FEE_FREQUENCY.ONCE
    if (isUpdatedPricingPeriodOnce) {
      setValue('startOffset', '')
      setValue('duration', '')
    } else {
      setValue('startOffset', IMMEDIATE_LATE_FEE_OPTION.value)

      setValue('duration', INDEFINITE_LATE_FEE_OPTION.value)
    }
  }

  useEffect(() => {
    if (open) {
      fetchMetaFilters({
        body: {},
      })
    }
  }, [open, id])

  useEffect(() => {
    if (id && isSuccessMetaFilters) {
      fetchLateFeeDetails({ id })
        .unwrap()
        .then(data => {
          const formValues = transformServerDataToForm(data, businessLineOptions)
          reset(formValues)
        })
    }
  }, [id, isSuccessMetaFilters, businessLineOptions])

  useEffect(() => {
    if (!open) {
      reset(defaultValues)
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <HHDialogTitle
        sx={{
          ...(archived &&
            id && {
              backgroundColor: theme.palette.background.deactivated,
            }),
        }}
        title={id ? T.EDIT_LATE_FEE_DIALOG : T.CREATE_LATE_FEE_DIALOG}
        onClose={onClose}
      />
      <DialogContent>
        <Stack direction="column" rowGap={3} sx={{ mt: 0.6 }}>
          <HHFormTextField
            required
            rules={{
              required: 'This field is required',
            }}
            deprecatedLabel={false}
            control={control}
            name="feeName"
            label={T.NAME}
            fullWidth
            helperText={feeNameHelperText}
            error={Boolean(feeNameHelperText)}
          />
          <HHFormAutocompleteField
            label={capitalize(T.BUSINESS_LINE)}
            helperText="Optional*"
            deprecatedLabel={false}
            name="businessLine"
            control={control}
            options={businessLineOptions}
            loading={loadingBusinessOptions}
            getOptionLabel={getOptionLabel}
          />
          <HHFormPriceField
            required
            rules={{
              required: 'This field is required',
            }}
            helperText={amountHelperText}
            error={Boolean(amountHelperText)}
            name="amount"
            control={control}
            deprecatedLabel={false}
            label={T.DOLLAR_AMOUNT}
          />
          <Grid container sx={{ width: '100%' }} rowGap={3}>
            <Grid container item xs={12} flexWrap="nowrap" columnGap={2}>
              <Grid item xs={gridSpan}>
                <HHFormSelectField
                  deprecatedLabel={false}
                  label={capitalize(T.PRICING_PERIOD)}
                  fullWidth
                  onChange={handlePricingPeriodChange}
                  control={control}
                  name="pricingPeriod"
                  options={LATE_FEE_FREQUENCY_OPTIONS}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon>
                          <ArrowSpin />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {!isPricingPeriodOnce && (
                <Grid item xs={gridSpan}>
                  <HHFormSelectField
                    deprecatedLabel={false}
                    fullWidth
                    control={control}
                    name="startOffset"
                    options={[IMMEDIATE_LATE_FEE_OPTION, ...startOffsetOptions, CUSTOM_LATE_FEE_OPTION]}
                    label={T.START_FROM}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon>
                            <CalendarDay />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}

              {pricingPeriod !== LATE_FEE_FREQUENCY.ONCE && (
                <Grid item xs={gridSpan}>
                  <HHFormSelectField
                    deprecatedLabel={false}
                    label={T.DURATION}
                    fullWidth
                    control={control}
                    name="duration"
                    options={[INDEFINITE_LATE_FEE_OPTION, ...durationOptions, CUSTOM_LATE_FEE_OPTION]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon>
                            <CalendarWeek />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {!isPricingPeriodOnce && (isCustomStartOffset || isCustomDuration) && (
              <Grid container item xs={12} flexWrap="nowrap" columnGap={2} justifyContent="flex-end">
                <Grid item xs={4} />
                <Grid item xs={4}>
                  {isCustomStartOffset && (
                    <HHFormNumberField
                      allowNegative={false}
                      name="customStartOffset"
                      label={T.CUSTOM_START_DATE}
                      deprecatedLabel={false}
                      required
                      fullWidth
                      rules={{
                        required: T.REQUIRED_FIELD_MSG,
                      }}
                      control={control}
                      placeholder={`Enter custom ${durationMeasureUnit.toLowerCase()}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography color="textSecondary">{durationMeasureUnit.toLowerCase()}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {isCustomDuration && !isIndefiniteDuration && (
                    <HHFormNumberField
                      allowNegative={false}
                      name="customDuration"
                      fullWidth
                      label={T.CUSTOM_DURATION}
                      deprecatedLabel={false}
                      required
                      rules={{
                        required: T.REQUIRED_FIELD_MSG,
                      }}
                      control={control}
                      placeholder={`Enter custom ${durationMeasureUnit.toLowerCase()}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography color="textSecondary">{durationMeasureUnit.toLowerCase()}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Stack>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={loading}
          disabled={loading}
          label={id ? T.SAVE : T.CREATE}
          size="small"
          onClick={handleSubmit(onSave)}
        />
      </HHDialogActions>
    </Dialog>
  )
}

CreateEditLateFeesDialog.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  archived: PropTypes.bool,
}

export default CreateEditLateFeesDialog
