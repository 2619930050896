import T from 'T'
import routes from 'router/routes'

export const GROUPS_MENU = [
  { label: T.ACCOUNTS, url: routes.app.accountGroups },
  { label: T.SUSPENSIONS, url: routes.app.suspensionGroups },
]

export const LIST_MENU = [
  { label: T.RECENTLY_VIEWED, url: routes.app.recentlyViewed },
  { label: T.RECENTLY_ADDED, url: routes.app.recentlyAdded },
  { label: T.ACCOUNTS, url: routes.app.accounts },
  { label: T.LOCATIONS, url: routes.app.locations },
]
