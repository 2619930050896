import TablePagination, { tablePaginationClasses } from '@mui/material/TablePagination'
import { styled } from '@mui/material'

const HHTablePagination = styled(TablePagination)(({ theme }) => ({
  [`& .${tablePaginationClasses.toolbar}`]: {
    minHeight: 48,
  },
  '& .MuiToolbar-gutters': {
    paddingLeft: 0,
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontWeight: '500',
    order: 1,
    marginRight: 16,
  },
  [`& .${tablePaginationClasses.selectLabel}`]: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    order: 2,
    marginRight: 8,
  },
  [`& .${tablePaginationClasses.input}`]: {
    order: 3,
    background: theme.palette.background.paper,
    border: '1px solid #dedfe2',
    borderRadius: 4,
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  [`& .${tablePaginationClasses.menuItem}`]: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    letterSpacing: '.25px',
    lineHeight: '20px',
  },
  [`& .${tablePaginationClasses.actions}`]: {
    order: 4,
    marginLeft: 0,
  },
}))

export default HHTablePagination
