import React from 'react'
import { ListItem, ListItemText, Skeleton, Stack, Typography } from '@mui/material'

const SuspensionHistorySkeletonItem = () => {
  return (
    <ListItem divider>
      <ListItemText
        disableTypography
        primary={
          <Stack>
            <Typography gutterBottom variant="h6" fontWeight={500}>
              <Skeleton width="5rem" />
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              <Skeleton width="3.5rem" />
            </Typography>
          </Stack>
        }
        secondary={
          <Typography color="textSecondary">
            <Skeleton width="20rem" />
          </Typography>
        }
      />
    </ListItem>
  )
}

SuspensionHistorySkeletonItem.propTypes = {}

export default SuspensionHistorySkeletonItem
