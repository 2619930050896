import React from 'react'
import PropTypes from 'prop-types'

const CommonTitle = ({ children, title, className }) => <h3 className={`common title ${className}`}>{title || children}</h3>

CommonTitle.defaultProps = {
  title: null,
  className: '',
  children: null,
}

CommonTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default CommonTitle
