import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'

import { toast } from 'react-toastify'
import { Box, Checkbox, DialogContent } from '@mui/material'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'

import { handleError } from 'utils/error'
import {
  useLazyGetPricedServicesByRentalFeeQuery,
  useUnlinkPricedServicesFromRentalFeeMutation,
} from 'api/pricing/rentalFeePricedServicesCrud'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import RenderCell from './common/RenderCell'
import DataGrid from '../AddRemoveRentalFees/common/DataGrid'
import RentalFeeListItemSkeleton from '../../common/field/RentalFeeListItemSkeleton'
import { getRemoveDialogContentHeight } from '../AddRemoveRentalFees/common/utils'
import { ROW_HEIGHT } from './common/settings'

const RemovePricedServicesDialog = ({ isOpen = false, rentalFeeId, onClose }) => {
  const [selectionModel, setSelectionModel] = useState([])
  const [getPricedServicesByRentalFee, { isFetching: isGetPricedServicesByRentalFeeLoading, data: pricedServicesByRentalFeeData }] =
    useLazyGetPricedServicesByRentalFeeQuery()
  const [unlinkPricedServicesFromRentalFee, { isLoading: isUnlinkLoading }] = useUnlinkPricedServicesFromRentalFeeMutation()

  const sortedPricedServices = useMemo(() => sortBy(pricedServicesByRentalFeeData, ['serviceName']), [pricedServicesByRentalFeeData])

  const handleSelectionModelChange = newSelectionModel => {
    setSelectionModel(newSelectionModel)
  }

  const handleRemovePricedService = () => {
    unlinkPricedServicesFromRentalFee({ id: rentalFeeId, pricedServiceIds: selectionModel })
      .unwrap()
      .then(() => {
        toast.success(`Priced service${selectionModel.length === 1 ? '' : 's'} removed successfully`)
        onClose()
        handleSelectionModelChange([])
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleSelectionModelChange([])
      getPricedServicesByRentalFee({ id: rentalFeeId })
    }
  }, [isOpen])

  const containerHeight = useMemo(() => getRemoveDialogContentHeight(sortedPricedServices.length, ROW_HEIGHT), [sortedPricedServices])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.REMOVE_SERVICES_FROM_RENTAL_FEE} onClose={onClose} />
      <DialogContent>
        {isGetPricedServicesByRentalFeeLoading && <RentalFeeListItemSkeleton rows={2} />}
        {!isGetPricedServicesByRentalFeeLoading && (
          <Box height={containerHeight}>
            <DataGrid
              isSelectable
              rows={sortedPricedServices}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              RenderCell={RenderCell}
              noRowsText="No priced services linked to this rental fee"
              slots={{
                baseCheckbox: params => <Checkbox {...params} color="warning" checkedIcon={<DisabledByDefaultRoundedIcon />} />,
              }}
            />
          </Box>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isUnlinkLoading}
          disabled={isUnlinkLoading || selectionModel.length === 0}
          label={T.REMOVE}
          color="warning"
          onClick={handleRemovePricedService}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RemovePricedServicesDialog.propTypes = {
  isOpen: PropTypes.bool,
  rentalFeeId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default RemovePricedServicesDialog
