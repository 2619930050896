import { Pagination, styled } from '@mui/material'

const HHPaginationV5 = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul li': {
    height: 34,
  },
  '& .MuiPagination-ul li button': {
    background: 'none',
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    paddingTop: 14,
    height: 34,
  },
  '& .MuiPagination-ul li button.Mui-selected': {
    borderRadius: 0,
    borderTop: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
  '& .MuiPagination-ul li button:hover': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  '& .MuiPagination-ul li div': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    paddingTop: 14,
    height: 34,
  },
}))

export default HHPaginationV5
