import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { DialogContent, Box, useTheme } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { keyBy } from 'lodash'
import { get } from 'utils/lodash'
import { formatStopFeatures } from 'utils/route'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { MapProvider } from 'providers/MapProvider'
import {
  selectIsSelectDateDialogOpen,
  selectIsOpenBulkUnassignStopsDialog,
  selectIsOpenBulkUnassignConfirmCloseDialog,
  setBulkUnassignStopsState,
  selectBulkUnassignToRows,
} from 'slices/route/bulkUnassignStopsSlice'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { useLazyGetRouteStopsGeojsonQuery } from 'api/route/getRouteStops'
import { isValidHex } from 'utils/validations'
import { common } from '@mui/material/colors'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import UnsavedChangesConfirmDialog from 'components/route-manager/BulkActions/common/UnsavedChangesConfirmDialog'
import { BULK_MOVE_MAP_LAYERS, getBulkMoveStopColumnSx, removeLayersAndSources } from 'components/route-manager/BulkActions/settings'
import BulkDialogAppbar from 'components/route-manager/BulkActions/common/BulkDialogAppbar'
import T from 'T'
import SelectRouteAndDateDialog from '../common/SelectRouteAndDateDialog'
import MoveFromRouteColumn from './MoveFromRouteColumn'
import MoveToRouteColumn from './MoveToRouteColumn'
import SelectStopsToMoveMap from './SelectStopsToMoveMap'
import BulkUnassignStopsDialogActions from './BulkUnassignStopsDialogActions'
import { defaultValues } from './settings'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const BulkUnassignStopsDialog = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const moveFromGridApiRef = useGridApiRef()
  const moveToGridApiRef = useGridApiRef()
  const map = useRef()
  const methods = useForm({ defaultValues })
  const { reset, watch } = methods
  const [routes, setRoutes] = useState([])
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const [getRouteStopsGeojson] = useLazyGetRouteStopsGeojsonQuery()
  const open = useSelector(selectIsOpenBulkUnassignStopsDialog)
  const isConfirmCloseDialogOpen = useSelector(selectIsOpenBulkUnassignConfirmCloseDialog)
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const moveToRows = useSelector(selectBulkUnassignToRows)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const serviceDate = watch('serviceDate')
  const columnSx = getBulkMoveStopColumnSx(theme)

  const handleReset = (isDateAndRouteSelectionDialogOpen = true) => {
    const mapCurrentRef = map.current
    if (!mapCurrentRef) return
    if (moveFromRouteId) removeLayersAndSources(mapCurrentRef, moveFromRouteId)
    if (moveToRouteId) removeLayersAndSources(mapCurrentRef, moveToRouteId)

    dispatch(
      setBulkUnassignStopsState({
        isSelectDateDialogOpen: isDateAndRouteSelectionDialogOpen,
        isConfirmCloseDialogOpen: false,
        moveFromRows: [],
        moveToRows: [],
        selectedMoveFromRows: [],
        selectedMoveToRows: [],
        moveFromRouteSequenceLine: {},
        moveFromRouteStopMarkers: {},
        moveToRouteStopMarkers: {},
        mapLayer: FROM_ROUTE_LAYER,
        mapChangeLayer: false,
      })
    )
    reset(defaultValues)
  }

  const handleClose = () => {
    if (moveToRows.length > 0) {
      dispatch(setBulkUnassignStopsState({ isConfirmCloseDialogOpen: true }))
      return
    }

    handleReset(false)
    dispatch(setBulkUnassignStopsState({ isOpen: false }))
  }

  const onCancelCloseDialog = () => {
    dispatch(setBulkUnassignStopsState({ isConfirmCloseDialogOpen: false }))
  }

  const onConfirmCloseDialog = () => {
    handleReset(false)
    dispatch(setBulkUnassignStopsState({ isOpen: false }))
  }

  const onConfirmSelectDateDialog = () => {
    dispatch(setBulkUnassignStopsState({ isSelectDateDialogOpen: false, isOpen: true }))
    getRoutesMetadata({ serviceDate: formatDateToBEFormatDateFns(serviceDate) }, true)
  }

  const onCloseSelectDateDialog = () => {
    handleReset(false)
    dispatch(setBulkUnassignStopsState({ isSelectDateDialogOpen: false }))
  }

  const formatGeojsonData = data => {
    const routeGeojsonData = get(data, 'routeGeojsonData', {})
    const moveFromRouteFeatures = get(routeGeojsonData, `${moveFromRouteId}.stops.features`, [])
    const newMoveFromRouteStopMarkers = formatStopFeatures(moveFromRouteFeatures, theme).map(marker => ({
      ...marker,
      properties: {
        ...marker?.properties,
        icon: 'monochrome-marker',
      },
    }))
    const newMoveFromRouteSequenceLine = get(routeGeojsonData, `${moveFromRouteId}.sequenceLine`, {})
    dispatch(
      setBulkUnassignStopsState({
        moveFromRouteStopMarkers: newMoveFromRouteStopMarkers,
        moveFromRouteSequenceLine: newMoveFromRouteSequenceLine,
        moveToRouteStopMarkers: [],
      })
    )
  }

  useEffect(() => {
    dispatch(
      setBulkUnassignStopsState({
        moveFromRouteId,
        moveToRouteId,
        fitBounds: true,
      })
    )
  }, [moveToRouteId, moveFromRouteId])

  useEffect(() => {
    if (!isSelectDateDialogOpen && serviceDate && moveFromRouteId) {
      getRouteStopsGeojson({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        routes: [moveFromRouteId],
      })
        .unwrap()
        .then(formatGeojsonData)
    }
  }, [isSelectDateDialogOpen, serviceDate, moveFromRouteId])

  useEffect(() => {
    return () => {
      map.current?.remove()
      map.current = null
    }
  }, [])

  useEffect(() => {
    if (routeMetaData) {
      const updatedRoutes = get(routeMetaData, 'routes', [])
      setRoutes(updatedRoutes)
    }
  }, [routeMetaData])

  useEffect(() => {
    const formattedRoutes = routes.map(route => {
      const color = get(route, 'color', common.black)
      const formattedColor = isValidHex(color) ? color : common.black
      const contrastColor = theme.palette.getContrastText(formattedColor)
      return {
        ...route,
        color: formattedColor,
        contrastColor,
      }
    })
    dispatch(
      setBulkUnassignStopsState({
        routesById: keyBy(formattedRoutes, 'id'),
      })
    )
  }, [routes])

  useEffect(() => {
    return () => {
      handleReset(false)
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <MapProvider map={map}>
          <HHFullScreenBaseDialog open={open} onClose={handleClose}>
            <BulkDialogAppbar title={T.BULK_UNASSIGN} onClose={handleClose} serviceDate={serviceDate} showDate />
            <DialogContent dividers sx={{ p: 0 }}>
              <Box display="flex" direction="row" flexWrap="nowrap" height="100%" overflow="hidden">
                <Box mt={5} display="flex" flexDirection="column" sx={columnSx}>
                  <MoveFromRouteColumn apiRef={moveFromGridApiRef} routes={routes} />
                </Box>
                <Box mt={5} display="flex" flexDirection="column" sx={{ order: 3, ...columnSx }}>
                  <MoveToRouteColumn apiRef={moveToGridApiRef} routes={routes} />
                </Box>
                <Box flex={1}>{open && <SelectStopsToMoveMap routes={routes} />}</Box>
              </Box>
            </DialogContent>
            <BulkUnassignStopsDialogActions onReset={handleReset} onClose={handleClose} />
          </HHFullScreenBaseDialog>
        </MapProvider>
        <UnsavedChangesConfirmDialog onConfirm={onConfirmCloseDialog} onClose={onCancelCloseDialog} isOpen={isConfirmCloseDialogOpen} />
        <SelectRouteAndDateDialog
          isOpen={isSelectDateDialogOpen}
          dialogTitle={T.BULK_UNASSIGN}
          onConfirm={onConfirmSelectDateDialog}
          onClose={onCloseSelectDateDialog}
          DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
        />
      </FormProvider>
    </>
  )
}

export default BulkUnassignStopsDialog
