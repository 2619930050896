import { SortAscending, SortDescending } from '@styled-icons/heroicons-solid'
import { get } from 'lodash'
import { BACKGROUND } from 'theme/colors'

export const PAGE_TYPE = 'locations'
export const SORT_BY_STREET_NAME_ASC = 'street_name_asc'
export const SORT_BY_STREET_NAME_DESC = 'street_name_des'
export const SORT_BY_ADDRESS_ASC = 'address_asc'
export const SORT_BY_ADDRESS_DESC = 'address_desc'

export const SORT_BY_DEFAULT_VALUE = SORT_BY_ADDRESS_ASC

export const SORT_BY_SEARCH_PARAM_KEY = 'sort_by'

export const SORT_BY_STREET_NAME_CRITERIA = 'street_name'
export const SORT_BY_ADDRESS_CRITERIA = 'address'

export const ALPHABET_LIST = 'abcdefghijklmnopqrstuvwxyz'.split('')
export const ALPHABET_WITH_DIGITS_LIST = '1234567890abcdefghijklmnopqrstuvwxyz'.split('')
export const LOCATIONS_DESKTOP_ROW_HEIGHT = 76
export const LOCATIONS_MOBILE_ROW_HEIGHT = 98
export const SORT_BY_OPTIONS = [
  {
    icon: SortAscending,
    label: 'Address ascending',
    value: SORT_BY_ADDRESS_ASC,
    ascending: true,
    sortCriteria: SORT_BY_ADDRESS_CRITERIA,
  },
  {
    icon: SortDescending,
    label: 'Address descending',
    value: SORT_BY_ADDRESS_DESC,
    ascending: false,
    sortCriteria: SORT_BY_ADDRESS_CRITERIA,
  },
  {
    icon: SortAscending,
    label: 'Street name ascending',
    value: SORT_BY_STREET_NAME_ASC,
    ascending: true,
    sortCriteria: SORT_BY_STREET_NAME_CRITERIA,
  },
  {
    icon: SortDescending,
    label: 'Street name descending',
    value: SORT_BY_STREET_NAME_DESC,
    ascending: false,
    sortCriteria: SORT_BY_STREET_NAME_CRITERIA,
  },
]

export const hasIntersection = (set, list) => {
  const setOfList = new Set(list)
  return set.some(item => setOfList.has(item))
}

export const getSortKeyWithDigits = char => {
  if (/\d/.test(char)) {
    return char === '0' ? `1${char}` : `0${char}`
  }
  if (/[a-zA-Z]/.test(char)) {
    return `2${char}`
  }
  return `3${char}`
}

export const getSortKeyWithoutDigits = char => {
  if (/[a-zA-Z]/.test(char)) {
    return `0${char}`
  }
  if (/\d/.test(char)) {
    return char === '0' ? `1${char}` : `2${char}`
  }
  return `3${char}`
}

export const getLocationRowClassName = params => {
  const { row } = params
  const isLocationDeactivated = get(row, 'deactivated', false)
  const vacationHoldCount = get(row, 'vacationHoldCount', 0)
  const hasVacationHolds = vacationHoldCount > 0
  if (row?.suspensionHold) return 'suspended'
  if (hasVacationHolds) return 'held'
  if (isLocationDeactivated) return 'deactivated'
  return 'active'
}

export const getLocationRowBgcolor = params => {
  const { row } = params
  const isLocationDeactivated = get(row, 'deactivated', false)
  const vacationHoldCount = get(row, 'vacationHoldCount', 0)
  const hasVacationHolds = vacationHoldCount > 0
  if (row?.suspensionHold) return BACKGROUND.suspension
  if (hasVacationHolds) return BACKGROUND.hold
  if (isLocationDeactivated) return BACKGROUND.deactivated
  return BACKGROUND.paper
}
