import React from 'react'

import { shallowEqual, useSelector } from 'react-redux'

import { Grid, Paper } from '@mui/material'

import { get } from 'utils/lodash'

import './Home.scss'
import './ListToolbar.scss'

const Home = () => {
  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  // Add loader globally
  if (!userInfo) {
    return ''
  }

  const firstName = get(userInfo, 'firstName', '')
  const lastName = get(userInfo, 'lastName', '')
  const userName = get(userInfo, 'username', '')
  const businessName = get(userInfo, 'businessInfo.businessName', '')

  return (
    <Paper>
      <Grid container justify="center" className="Home" spacing={2}>
        <Grid item xs={12}>
          {`First Name: ${firstName}`}
        </Grid>
        <Grid item xs={12}>
          {`Last Name: ${lastName}`}
        </Grid>
        <Grid item xs={12}>
          {`Username: ${userName}`}
        </Grid>
        <Grid item xs={12}>
          {`Business Name: ${businessName}`}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Home
