import React from 'react'
import PropTypes from 'prop-types'

import { memo } from 'utils/react'

import Constants from 'Constants'
import BasicModal from 'components/modal/BasicModal'

const { MEDIA_SERVER } = Constants

const SummaryModal = ({ isOpen, summaryContent, onClose }) => (
  <BasicModal isOpen={isOpen} onClose={onClose}>
    <div className="summary-modal">
      <span className="summary-close-icon cursor-pointer" onClick={onClose}>
        <img src={`${MEDIA_SERVER}Close.svg`} alt="" />
      </span>
      <div className="content">{summaryContent}</div>
    </div>
  </BasicModal>
)

SummaryModal.defaultProps = {
  isOpen: false,
  summaryContent: '',
}

SummaryModal.propTypes = {
  isOpen: PropTypes.bool,
  summaryContent: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default memo(SummaryModal)
