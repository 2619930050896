import React, { useCallback, useEffect, useMemo } from 'react'
import assign from 'lodash/assign'

import { useDispatch, useSelector } from 'react-redux'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { Box, LinearProgress, Stack, Tab, Tabs, useTheme, darken, lighten, useMediaQuery } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { BACKGROUND } from 'theme/colors'
import { HHAlert } from 'components/common/HHAlert'
import { RENTAL_FEES_BUSINESS_LINES_TABS } from 'components/pricing/RentalFees/common/settings'
import {
  selectSelectedRentalFeesBusinessLine,
  setSelectedRentalFeesBusinessLine,
  selectHideArchivedRentalFees,
  selectArchivedRestoredRentalFee,
  setArchivedRestoredRentalFee,
} from 'slices/pricing/rentalFeesSlice'
import { useLazyFetchMetaFiltersQuery } from 'api/meta/getMetaFilters'
import { useLazyGetRentalFeesQuery } from 'api/pricing/rentalFeesCrud'
import { getActiveBusinessLinesToCategories } from 'data/business-line/businessLineSelector'
import { groupRowsByBusinessLine } from 'data/pricing/commonPricingSelector'

import T from 'T'
import SecondaryNavbar from 'components/pricing/RentalFees/SecondaryNavbar'
import CustomFeeRow from 'components/pricing/common/CustomFeeRow'
import RenderRentalFeeCell from 'components/pricing/RentalFees/RentalFeesListing/RenderRentalFeeCell'

const { ALL_BUSINESS_LINES, RESIDENTIAL, ROLL_OFF, COMMERCIAL } = RENTAL_FEES_BUSINESS_LINES_TABS

const RentalFees = () => {
  const theme = useTheme()
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const [fetchMetaFilters, { isFetching: isMetaFiltersLoading, data: metaFiltersData }] = useLazyFetchMetaFiltersQuery()
  const [getRentalFees, { data, isFetching: isGetRentalFeesLoading }] = useLazyGetRentalFeesQuery()

  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))

  const selectedBusinessLine = useSelector(selectSelectedRentalFeesBusinessLine)
  const hideArchived = useSelector(selectHideArchivedRentalFees)
  const archivedRestoredRentalFee = useSelector(selectArchivedRestoredRentalFee)
  const archivedRestoredRentalFeeId = useMemo(() => get(archivedRestoredRentalFee, 'id'), [archivedRestoredRentalFee])

  const businessLines = useMemo(() => get(metaFiltersData, 'businessLines', []), [metaFiltersData])
  const rows = useMemo(() => get(data, 'rentalFees', []), [data])
  const isEmpty = useMemo(() => rows.length === 0, [rows])
  const activeBusinessLinesToCategories = getActiveBusinessLinesToCategories({ businessLines })
  const groupedRowsByCategories = groupRowsByBusinessLine({ rows, businessLineHashMap: activeBusinessLinesToCategories, hideArchived })
  const filteredSelectedGroupRows = useMemo(
    () => get(groupedRowsByCategories, selectedBusinessLine, []),
    [groupedRowsByCategories, selectedBusinessLine]
  )

  const idToIndexMap = useMemo(
    () => assign({}, ...filteredSelectedGroupRows.map((row, index) => ({ [row.id]: index }))),
    [filteredSelectedGroupRows]
  )

  const rowLateFeeHeight = useMemo(() => {
    if (isXs) return 312
    if (isSm) return 208
    if (isMd) return 156
    return 104
  }, [isXs, isSm, isMd])

  const getRowHeight = useCallback(() => 'auto', [])

  const handleChangeSelectedLateFeeBusinessLine = (event, newSelectedBusinessLine) => {
    dispatch(setSelectedRentalFeesBusinessLine(newSelectedBusinessLine))
  }

  const getRowSpacing = useCallback(params => ({ top: params.isFirstVisible ? 24 : 0 }), [])

  const getRowClassName = useCallback(({ row }) => (row?.archived ? 'archived' : ''), [])

  const getRowId = useCallback(({ id }) => id, [])

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  useEffect(() => {
    fetchMetaFilters({ body: {} })
    getRentalFees()
  }, [])

  useEffect(() => {
    if (archivedRestoredRentalFeeId) {
      setTimeout(() => {
        const rowIndex = get(idToIndexMap, archivedRestoredRentalFeeId)
        if (rowIndex) {
          apiRef?.current?.scrollToIndexes({ rowIndex })
        }
        dispatch(setArchivedRestoredRentalFee(undefined))
      }, 500)
    }
  }, [rowLateFeeHeight, archivedRestoredRentalFeeId, idToIndexMap])

  return (
    <Stack direction="column" sx={{ height: '100%', width: '100%', flex: '1 1 0' }}>
      <SecondaryNavbar />
      <Box px={2} borderBottom={1} borderColor="divider">
        <Tabs value={selectedBusinessLine} onChange={handleChangeSelectedLateFeeBusinessLine} sx={{ button: { textTransform: 'none' } }}>
          <Tab value={ALL_BUSINESS_LINES} label={T.ALL_BUSINESS_LINES} />
          <Tab value={RESIDENTIAL} label={T.RESIDENTIAL} />
          <Tab value={COMMERCIAL} label={T.COMMERCIAL} />
          <Tab value={ROLL_OFF} label={T.ROLLOFF} />
        </Tabs>
      </Box>
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            apiRef={apiRef}
            sx={{
              borderTop: 0,
              borderRadius: 0,
              '& .MuiDataGrid-cell , & .MuiDataGrid-row': {
                border: 0,
              },
              '& .MuiDataGrid-cell.rental-fee-row-cell': {
                pr: 0.8,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
                background: 'transparent',
                '&.archived': {
                  background: BACKGROUND.deactivated,
                  '&:hover, .deactivated-bg-indicator': {
                    backgroundColor: getHoverBackgroundColor(BACKGROUND.deactivated, theme.palette.mode),
                  },
                },
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden',
                ...(isEmpty && { overflow: 'hidden' }),
              },
            }}
            hideFooter
            slots={{
              detailPanelExpandIcon: ChevronRightIcon,
              detailPanelCollapseIcon: ExpandMoreIcon,
              loadingOverlay: LinearProgress,
              row: CustomFeeRow,
              ...(isEmpty && {
                noRowsOverlay: () => (
                  <Box p={2}>
                    <HHAlert borderColor={theme.palette.info.light} severity="info">
                      {T.CLICK_PLUS_BUTTON_ABOVE_TO_ADD_A_RENTAL_FEE}
                    </HHAlert>
                  </Box>
                ),
              }),
            }}
            columns={[
              {
                field: 'id',
                flex: 1,
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: params => <RenderRentalFeeCell hideFooter {...params} />,
                cellClassName: 'rental-fee-row-cell',
              },
            ]}
            getRowClassName={getRowClassName}
            getRowHeight={getRowHeight}
            columnHeaderHeight={0}
            disableRowSelectionOnClick
            getRowId={getRowId}
            getRowSpacing={getRowSpacing}
            loading={isMetaFiltersLoading || isGetRentalFeesLoading}
            rows={filteredSelectedGroupRows}
          />
        </Box>
      </Box>
    </Stack>
  )
}

RentalFees.propTypes = {}

export default RentalFees
