import React from 'react'
import PropTypes from 'prop-types'
import DialogTitle from '@mui/material/DialogTitle'

export const CustomDialogTitle = ({ children, action, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, px: 3, py: 2 }} {...other}>
      {children}
      {action}
    </DialogTitle>
  )
}

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  action: PropTypes.node,
}
