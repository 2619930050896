import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Highlighter from 'react-highlight-words'
import { useFormContext } from 'react-hook-form'

const HighlightText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent',
  fontWeight: 600,
}))

const SearchCustomerTextItem = ({ text = '', flex = undefined, align = 'left' }) => {
  const { watch } = useFormContext()
  const searchInputValue = watch('searchInputValue')
  const lowercaseText = text.toLowerCase()
  const lowercaseSearchInputValue = searchInputValue.toLowerCase()
  const containsMatchingText = searchInputValue ? lowercaseText.includes(lowercaseSearchInputValue) : false

  return (
    <Box display="flex" flex={flex} alignItems="flex-start" overflow="hidden">
      <Typography align={align} variant="body1" color="textPrimary" fontWeight={containsMatchingText ? 'bold' : 'normal'} noWrap>
        <Highlighter highlightTag={HighlightText} searchWords={[searchInputValue]} autoEscape textToHighlight={text || ''} />
      </Typography>
    </Box>
  )
}

SearchCustomerTextItem.propTypes = {
  text: PropTypes.string,
  flex: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
}

export default SearchCustomerTextItem
