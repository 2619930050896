import { createSelector } from 'reselect'
import { groupBy } from 'lodash'

export const getRoutesByMethod = createSelector([state => state?.routes], (routes = []) => {
  if (!Array.isArray(routes)) {
    return {}
  }

  const filteredRoutes = routes.filter(route => route.methodType)
  return groupBy(filteredRoutes, 'methodType')
})

export const getRoutesByBusinessLine = createSelector([state => state?.routes], (routes = []) => {
  if (!Array.isArray(routes)) {
    return {}
  }

  const filteredRoutes = routes.filter(route => route.businessLine)
  return groupBy(filteredRoutes, 'businessLine')
})
