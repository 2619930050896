import { ListItemText, styled } from '@mui/material'

const NotificationItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    ...theme.typography.h6,
    fontWeight: 700,
  },
  '& .MuiListItemText-secondary': {
    ...theme.typography.body1,
  },
}))

export default NotificationItemText
