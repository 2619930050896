import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { InputAdornment, TextField as MuiTextField, Popper, styled, CircularProgress } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import T from 'T'
import { useSelector } from 'react-redux'
import ExpandableAutocomplete from './ExpandableAutocomplete'

export const ExpandableCustomPopper = styled(Popper)({
  [`& .${autocompleteClasses.paper}:empty`]: {
    display: 'none',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})
const VirtualizedAutocomplete = ({
  value,
  sx,
  options,
  onChange = noop,
  onInputChange,
  getOptionLabel,
  ListboxComponent,
  loading = false,
  placeholder = T.SEARCH_PLACEHOLDER,
  ...rest
}) => {
  const inputValue = get(rest, 'inputValue')
  const [open, setOpen] = useState(false)
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)

  const onOpen = () => {
    setTimeout(() => setOpen(true), 300)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <ExpandableAutocomplete
      {...rest}
      isTemporaryModeActive={isTemporaryModeActive}
      sx={sx}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      autocomplete="off"
      fullWidth
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      openOnFocus
      disabledListWrap
      disablePortal
      options={options}
      onChange={onChange}
      PopperComponent={ExpandableCustomPopper}
      ListboxComponent={ListboxComponent}
      renderInput={params => (
        <MuiTextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          size="small"
        />
      )}
      renderOption={(props, option) => [props, option, inputValue]}
    />
  )
}

VirtualizedAutocomplete.propTypes = {
  value: PropTypes.object,
  sx: PropTypes.object,
  getOptionLabel: PropTypes.func.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func.isRequired,
  ListboxComponent: PropTypes.node,
  loading: PropTypes.bool,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
}

export default VirtualizedAutocomplete
