import React from 'react'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { formatDateToNotificationStr } from '../../utils/date'
import NotificationItemText from './NotificationItemText'

const NotificationListItem = ({ title, description, date }) => {
  const formattedDate = formatDateToNotificationStr(date)
  return (
    <Card variant="outlined">
      <CardContent sx={{ pt: 1.5, px: 4, '&:last-child': { pb: 2 } }}>
        <NotificationItemText
          primary={title}
          secondary={
            <Stack>
              <Typography gutterBottom>{description}</Typography>
              <Typography>{formattedDate}</Typography>
            </Stack>
          }
        />
      </CardContent>
    </Card>
  )
}

NotificationListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

export default NotificationListItem
