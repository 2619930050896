import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getDisposalFeeLinkList: build.query({
      query: body => ({ url: '/api/pricing/disposal/fee/linked/list', method: 'POST', body }),
    }),
  }),
})
export const { useLazyGetDisposalFeeLinkListQuery } = extendedApi
