import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, ListItemIcon, ListItemText, Popover, Popper, TextField, ListItemButton, MenuItem, Paper } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { shallowEqual, useSelector } from 'react-redux'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import RenderTagFilterOption from 'components/locations/filters/TagFilter/RenderTagFilterOption'
import { NO_TAGS_OPTION, NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'
import zIndex from '@mui/material/styles/zIndex'

const WIDTH = 350

const CustomPopper = props => <Popper {...props} placement="bottom-start" sx={{ '&.base-Popper-root': { zIndex: zIndex.modal + 2 } }} />

const TagFilterMenu = ({ value, onChange = noop, defaultSelectedTags = [], renderOption = RenderTagFilterOption, tagList, ...rest }) => {
  const inputRef = useRef(null)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const { isSuccess } = useGetAccountMetaQuery()
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const tagsByVisibility = useMemo(() => tagList || getTagsByVisibility({ tags: get(customerMeta, 'tags', []) }), [tagList, customerMeta])
  const [tags, setTags] = useState([])
  const [tagIdList, setTagIdList] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)

  const isControlled = useMemo(() => value, [value])

  const [internalSelectedTags, setInternalSelectedTags] = useState(defaultSelectedTags)
  const selectedTags = useMemo(() => (isControlled ? value : internalSelectedTags), [isControlled, internalSelectedTags, value])

  const allTagsSelected = useMemo(() => selectedTags.length === tagIdList.length, [selectedTags, tagIdList])
  const selectedTagsCount = useMemo(() => selectedTags.filter(({ id }) => id !== NO_TAGS_OPTION_ID).length, [selectedTags])
  const tagsButtonLabel = useMemo(() => {
    if (selectedTagsCount === 0) return 'No tags'
    return 'Tags'
  }, [selectedTagsCount])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [inputRef])

  const handleOpen = event => setPopoverAnchorEl(event.currentTarget)

  const getOptionLabel = option => get(option, 'tagName')

  const handleClose = () => setPopoverAnchorEl(null)

  const toggleAllBillingSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelectedTags = allTagsSelected ? [] : [...tags]
      if (isControlled) {
        onChange(newSelectedTags)
      } else {
        setInternalSelectedTags(newSelectedTags)
      }
    },
    [isControlled, allTagsSelected, tags]
  )

  useEffect(() => {
    if (isInitialized) {
      setIsInitialized(false)
    }
  }, [tagList])

  useEffect(() => {
    if (!isInitialized && isSuccess) {
      const filteredTags = tagList ? [...tagsByVisibility] : [NO_TAGS_OPTION, ...tagsByVisibility]
      const filteredTagIdList = filteredTags.map(({ id }) => id)
      setTags(filteredTags)
      setTagIdList(filteredTagIdList)
      setIsInitialized(true)
      if (!isControlled) {
        setInternalSelectedTags(defaultSelectedTags)
      }
    }
  }, [isInitialized, isSuccess, tagsByVisibility, tagList])

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton disableGutters divider selected={allTagsSelected} onMouseDown={toggleAllBillingSelection}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allTagsSelected} />
            </ListItemIcon>
            <ListItemText primary={allTagsSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allTagsSelected, toggleAllBillingSelection]
  )

  const handleChange = (event, newSelectedTags) => {
    if (isControlled) {
      onChange(newSelectedTags)
    } else {
      setInternalSelectedTags(newSelectedTags)
      onChange(newSelectedTags)
    }
  }

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  const dropdownHeight = get(rest, 'dropdownHeight', 'calc(100vh - 240px)')
  const maxHeight = get(rest, 'autoHeight') ? null : { maxHeight: dropdownHeight }

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedTagsCount} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 50 }}>{tagsButtonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="tag-filter-menu-popover"
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        TransitionProps={{ onEntered: focusAutocomplete }}
        transitionDuration={{ appear: 0, exit: 0 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
      >
        <Autocomplete
          multiple
          options={tags}
          sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
          fullWidth
          componentsProps={{
            paper: { allTagsSelected, toggleAllBillingSelection },
            popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
          }}
          ListboxProps={{
            sx: { ...maxHeight, py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
          }}
          open={Boolean(popoverAnchorEl)}
          disableClearable
          onChange={handleChange}
          PopperComponent={CustomPopper}
          PaperComponent={SelectAllPaperComponent}
          renderTags={() => <></>}
          value={selectedTags}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={params => <TextField {...params} placeholder="Type to filter tags" inputRef={inputRef} variant="standard" />}
          {...rest}
        />
      </Popover>
    </>
  )
}

TagFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedTags: PropTypes.array,
  renderOption: PropTypes.func,
  tagList: PropTypes.array,
}

export default TagFilterMenu
