import React from 'react'
import PropTypes from 'prop-types'
import { difference, intersection, uniq } from 'lodash'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Menu } from '@mui/material'

import { setViewOnMapRouteId, selectRouteIds, setSelectedSearchResult } from 'slices/route/routeSlice'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'

import RouteFilterMenuOptions from './RouteFilterMenuOptions'

const RouteFilter = ({ type, anchorEl, filteredRoutes = [], onMouseLeave }) => {
  const dispatch = useDispatch()
  const filteredRouteIds = filteredRoutes.map(({ id }) => id)
  const allSelectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const filteredSelectedRouteIds = intersection(allSelectedRouteIds, filteredRouteIds)
  const allRoutesSelected = filteredSelectedRouteIds.length === filteredRoutes.length && filteredRoutes.length > 0

  const handleChange = (routeIds = []) => {
    if (Array.isArray(routeIds)) {
      dispatch(selectRouteIds(routeIds))
      dispatch(setSelectedSearchResult(null))
    }
  }

  const handleSelectRouteChange = (event, routeId) => {
    event.stopPropagation()
    dispatch(setViewOnMapRouteId(null))
    if (allSelectedRouteIds.includes(routeId)) {
      handleChange(allSelectedRouteIds.filter(id => id !== routeId))
    } else {
      handleChange([...allSelectedRouteIds, routeId])
    }
  }

  const handleSelectAllFilters = event => {
    event.stopPropagation()
    dispatch(setViewOnMapRouteId(null))
    if (allRoutesSelected) {
      handleChange(difference(allSelectedRouteIds, filteredSelectedRouteIds))
    } else {
      handleChange(uniq([...allSelectedRouteIds, ...filteredRouteIds]))
    }
  }

  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={{ top: anchorEl?.top, left: anchorEl?.left + 0.25 }}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      MenuListProps={{ onMouseLeave }}
    >
      <RouteFilterMenuOptions
        allRoutes={filteredRoutes}
        selectedRouteIds={filteredSelectedRouteIds}
        onSelectRouteChange={handleSelectRouteChange}
        onSelectAllFilters={handleSelectAllFilters}
      />
    </Menu>
  )
}

RouteFilter.propTypes = {
  type: PropTypes.string,
  anchorEl: PropTypes.object,
  filteredRoutes: PropTypes.array,
  onMouseLeave: PropTypes.func,
}

export default RouteFilter
