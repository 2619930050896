import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer, useMediaQuery, useTheme } from '@mui/material'

import TableBody from 'components/pricing/TableBody'
import TableHeader from 'components/pricing/TableHeader'
import PricingPagination from 'components/pricing/PricingPagination'

import { PRICING_PAGINATION } from 'settings/constants/pagination'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const Table = ({
  serviceList,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  totalTaxCount,
  totalTaxPageCount,
  confServices,
  handleAllChecked,
  handleSingleChecked,
  checkedVal,
  getTaxServiceName,
  saveConfSettings,
  type,
  columns,
  lockedColumns,
  allCheckedVal,
  toggleLinkTaxDrawer,
  handleActivation,
  allFilters,
  allSortBy,
  onTableFilterChange,
  onSortByChange,
}) => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <TableContainer
        sx={{
          height: isTabletOrMobile ? 'calc(100vh - 450px)' : 'calc(100vh - 250px)',
        }}
        className="pricing-table"
      >
        <TableView stickyHeader aria-label="sticky table">
          <TableHeader
            handleChange={handleAllChecked}
            confServices={confServices}
            serviceList={serviceList}
            allCheckedVal={allCheckedVal}
            checkedVal={checkedVal}
            saveConfSettings={saveConfSettings}
            type={type}
            columns={columns}
            lockedColumns={lockedColumns}
            allFilters={allFilters}
            allSortBy={allSortBy}
            onTableFilterChange={onTableFilterChange}
            onSortByChange={onSortByChange}
          />

          <TableBody
            serviceList={serviceList}
            handleChange={handleSingleChecked}
            confServices={confServices}
            checkedVal={checkedVal}
            getTaxServiceName={getTaxServiceName}
            allCheckedVal={allCheckedVal}
            columns={columns}
            lockedColumns={lockedColumns}
            saveConfSettings={saveConfSettings}
            type={type}
            toggleLinkServiceDrawer={toggleLinkTaxDrawer}
            handleActivation={handleActivation}
          />
        </TableView>
      </TableContainer>

      <PricingPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalCount={totalTaxCount}
        totalPageCount={totalTaxPageCount}
      />
    </>
  )
}

Table.defaultProps = {
  type: '',
  allCheckedVal: {},
  serviceList: [],
  confServices: [],
  columns: [],
  lockedColumns: [],
  checkedVal: {},
  saveConfSettings: false,
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  totalTaxCount: INITIAL_PAGE,
  totalTaxPageCount: INITIAL_PAGE,
  onPageChange: null,
  onRowsPerPageChange: null,
  handleAllChecked: null,
  handleSingleChecked: null,
  getTaxServiceName: null,
  toggleLinkTaxDrawer: null,
  handleActivation: null,
  allFilters: {},
  allSortBy: {},
}

Table.propTypes = {
  type: PropTypes.string,
  allCheckedVal: PropTypes.object,
  serviceList: PropTypes.array,
  confServices: PropTypes.array,
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  checkedVal: PropTypes.object,
  saveConfSettings: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTaxCount: PropTypes.number,
  totalTaxPageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  handleAllChecked: PropTypes.func,
  handleSingleChecked: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  toggleLinkTaxDrawer: PropTypes.func,
  handleActivation: PropTypes.func,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  onTableFilterChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
}

export default Table
