import { get } from 'lodash'

export const MAX_CONTACT_METHODS = 3

export const getMaxContactMethods = contacts => {
  let maxCount = 0

  contacts.forEach(contact => {
    const contactMethods = get(contact, 'contactMethods', [])
    const count = get(contactMethods, 'length', 0)
    if (count > maxCount) {
      maxCount = count
    }
  })

  return Math.min(maxCount, MAX_CONTACT_METHODS)
}
