import React, { Children } from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { CommonTextLabel, CommonSelect, CommonTextfield, ServiceCard, LoadingServiceCard } from 'components/common'
import T from 'T'
import Constants from 'Constants'
import { getPricingPeriod, getRecurrenceText } from 'utils/service'
import './style.scss'
import { Box } from '@mui/material'

const { MEDIA_SERVER } = Constants

const ServiceTabAddContent = ({
  isLoading,
  pricedServiceId,
  service,
  selectedLocation,
  allLocations,
  pricingZonesMeta,
  pricedServices,
  onChange,
}) => {
  const search = get(service, 'search', '')

  return (
    <div className="service-tab-content common-padding-lr-3-5">
      <Box mt={2}>
        <CommonTextLabel title={T.SERVICE_ADDRESS} isRequired />
        <CommonSelect
          className="common-service-select"
          showEmpty={allLocations.length === 0}
          emptyText="No Service location Available"
          placeholder={`Select ${T.SERVICE_ADDRESS}`}
          placeholderHidden
          value={get(selectedLocation, 'locationId', '')}
          options={allLocations}
          onChange={e => onChange('locationId', e.target.value, 'location', 'map')}
        />
      </Box>

      <Box mt={2}>
        <CommonTextLabel title={T.PRICING_ZONE} />
        <CommonSelect
          className="common-service-select"
          showEmpty={pricingZonesMeta.length === 0}
          emptyText="No Pricing Zone Available"
          placeholder={`Select ${T.PRICING_ZONE}`}
          placeholderHidden
          value={get(service, 'pricingZoneId', '')}
          options={pricingZonesMeta.map(pricingZone => ({ key: pricingZone.id, value: pricingZone.zoneName }))}
          onChange={e => onChange('pricingZoneId', e.target.value, 'service')}
        />
      </Box>

      <Box mt={4}>
        <CommonTextLabel title={T.SELECT_SERVICE} isRequired />
        <CommonTextfield
          startAdornment={
            <InputAdornment position="start">
              <img src={`${MEDIA_SERVER}Customer-Search.svg`} alt="search-icon" />
            </InputAdornment>
          }
          placeholder={T.SEARCH_SERVICE_PLACEHOLDER}
          value={search}
          onChange={e => onChange('search', e.target.value, 'service')}
        />
      </Box>

      <div>
        {isLoading &&
          new Array(10).fill(0).map((_, i) => (
            <Box mt={i === 0 ? 3 : 0} mb={1}>
              <LoadingServiceCard />
            </Box>
          ))}

        {!isLoading && pricedServices.length === 0 && (
          <Box mt={3}>
            <ServiceCard title={T.NO_CS_PRICING_SERVICE} />
          </Box>
        )}

        {!isLoading &&
          pricedServices.length > 0 &&
          Children.toArray(
            pricedServices.map((pricedService, i) => {
              const frequency = get(pricedService, 'recurrenceFrequency') || 1
              const per = get(pricedService, 'recurrence.recurrencePer')
              const interval = get(pricedService, 'recurrence.recurrenceInterval')
              const recurrence = getRecurrenceText(per, interval, frequency)
              const periodPer = get(pricedService, 'periodPer')
              const periodInterval = get(pricedService, 'periodInterval')
              const price = get(pricedService, 'value')
              const currentServiceId = get(pricedService, 'id')
              const isSelected = pricedServiceId === currentServiceId
              const materialType = get(pricedService, 'material.materialType', '')
              const businessLine = get(pricedService, 'businessLine', '')
              const businessLineIcon = get(pricedService, 'businessLineIcon', '')
              const volume = get(pricedService, 'measure.volume', '')
              const actionType = get(pricedService, 'action.actionType', '')
              const title = get(pricedService, 'serviceName', '')
              const measureUnit = get(pricedService, 'measure.unit', '')
              const pricingPeriod = getPricingPeriod(periodPer, periodInterval)
              return (
                <Box mt={i === 0 ? 3 : 0} mb={1}>
                  <ServiceCard
                    onClick={() => onChange('pricedServiceId', currentServiceId)}
                    isSelected={isSelected}
                    title={title}
                    price={price}
                    businessLine={businessLine}
                    recurrence={recurrence}
                    volume={volume}
                    measureUnit={measureUnit}
                    pricingPeriod={pricingPeriod}
                    materialType={materialType}
                    businessLineIcon={businessLineIcon}
                    actionType={actionType}
                  />
                </Box>
              )
            })
          )}
      </div>
    </div>
  )
}

ServiceTabAddContent.defaultProps = {
  isLoading: false,
  pricedServiceId: '',
  service: {},
  selectedLocation: {},
  allLocations: [],
  pricingZonesMeta: [],
  pricedServices: [],
}

ServiceTabAddContent.propTypes = {
  isLoading: PropTypes.bool,
  pricedServiceId: PropTypes.string,
  service: PropTypes.object,
  selectedLocation: PropTypes.object,
  allLocations: PropTypes.array,
  pricingZonesMeta: PropTypes.array,
  pricedServices: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default memo(ServiceTabAddContent)
