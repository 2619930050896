import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Typography, Box } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

const AccountGroupCustomViewHeader = ({ isCustomGroupExpanded = false, handleCustomGroupExpandCollapse }) => (
  <Box px={3} mt={2} mb={1} width="100%">
    <Box pl={1} display="flex" alignItems="center" gap={1} borderBottom="1px solid" borderColor="divider">
      <IconButton size="small" onClick={handleCustomGroupExpandCollapse}>
        {isCustomGroupExpanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        Custom
      </Typography>
    </Box>
  </Box>
)

AccountGroupCustomViewHeader.propTypes = {
  isCustomGroupExpanded: PropTypes.bool,
  handleCustomGroupExpandCollapse: PropTypes.func.isRequired,
}

export default AccountGroupCustomViewHeader
