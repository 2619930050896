import api from 'api'
import { CACHE_TAG_CONFIGURED_SERVICES_LIST } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRentalFeesByConfiguredService: build.query({
      query: ({ id }) => ({
        url: `/api/v1/core/configured-service/${id}/fee/rental`,
        method: 'GET',
      }),
    }),
    addRentalFeesToConfiguredService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/configured-service/${id}/fee/rental`,
        method: 'POST',
        body: { ...rest },
      }),
      invalidatesTags: [CACHE_TAG_CONFIGURED_SERVICES_LIST],
    }),
    updateRentalFeesConfiguredService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/configured-service/${id}/fee/rental`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: [CACHE_TAG_CONFIGURED_SERVICES_LIST],
    }),
    deleteRentalFeesFromConfiguredService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/configured-service/${id}/fee/rental`,
        method: 'DELETE',
        body: { ...rest },
      }),
      invalidatesTags: [CACHE_TAG_CONFIGURED_SERVICES_LIST],
    }),
  }),
})

export const {
  useLazyGetRentalFeesByConfiguredServiceQuery,
  useAddRentalFeesToConfiguredServiceMutation,
  useUpdateRentalFeesConfiguredServiceMutation,
  useDeleteRentalFeesFromConfiguredServiceMutation,
} = extendedApi
