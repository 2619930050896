import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { get } from 'utils/lodash'
import useTheme from '@mui/material/styles/useTheme'
import { convertDateTimeToPreferedTimezone } from '../../../data/dateTime/convertDateTimeToPreferedTimezone'

const RouteCircle = ({ routeSequence, workOrderStatus = '', timestamp, isWorkOrderComplete = false }) => {
  const theme = useTheme()
  const { onlyTime, onlyDate } = convertDateTimeToPreferedTimezone({
    dateTime: timestamp,
  })
  const tooltipTitle = `${workOrderStatus} on: ${onlyDate} @ ${onlyTime}`
  return (
    <Tooltip arrow title={isWorkOrderComplete && tooltipTitle} sx={{ zIndex: theme.zIndex.tooltip }}>
      <Box
        sx={{
          width: '2.2rem',
          textAlign: 'center',
          height: 22,
          border: '1.5px solid',
          borderColor: 'divider',
          borderRadius: 1,
          backgroundColor: get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`, grey[50]),
          color: 'text.lightBrown',
          padding: 0.25,
          marginBottom: 1.5,
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" color="textPrimary">
          {routeSequence}
        </Typography>
      </Box>
    </Tooltip>
  )
}

RouteCircle.propTypes = {
  routeSequence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workOrderStatus: PropTypes.string,
  timestamp: PropTypes.string,
  isWorkOrderComplete: PropTypes.bool,
}

export default RouteCircle
