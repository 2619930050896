/* eslint-disable */

export default class T {
  static BUSINESS_INFO = 'Business Information'
  static PRICE_PER_UNIT = 'Price per Unit'
  static REGENERATE = 'Regenerate'
  static ROUTE = 'Route'
  static BILLING = 'Billing'
  static REPORTS = 'Reports'
  static PRICING = 'Pricing'
  static FOLLOW_UP = 'Follow Up'
  static DASHBOARD = 'Dashboard'
  static CUSTOMER = 'Customer'
  static CUSTOMERS = 'Customers'
  static MORE_MENU = 'More'
  static RECENT_SEARCHES = 'Recent Searches'
  static ADVANCED_SEARCH = 'Advanced Search'
  static SHOW_ALL = 'show all'
  static SEARCH = 'Search'
  static SHOW_ALL_RESULTS = 'Show all results for'
  static SEARCH_PLACEHOLDER = 'Search...'
  static SEARCH_SERVICE_PLACEHOLDER = 'Search Service...'
  static SELECT_SERVICE = 'Select a service'
  static FIND_ADDRESS_PLACEHOLDER = 'Find Address'
  static PRESS_ENTER = 'press enter'
  static NO_SERVICES = 'No services found'
  static SEARCH_SERVICES = 'Search Services'
  static SERVICE_END_DATE = 'Service End Date'
  static LAST_ACTIVITY_DATE = 'Last Activity Date'
  static TIME_ZONE = 'Time Zone'
  static NO_RESULTS = 'There are no results'
  static NO_REPORTS_FOUND = 'No Reports Found'

  static RECENTLY = 'Recently'
  static VIEWED = 'Viewed'
  static ADDED = 'Added'
  static UPDATED = 'Updated'
  static NO_CUSTOMERS = 'No customers found'
  static SEARCH_TRY_AGAIN = 'Please try another search'

  static ACCOUNT_NAME = 'Account Name'
  static LOCATION = 'Location'
  static LOCATIONS = 'Locations'
  static CONTACT_NAME = 'Contact Name'
  static CONTACT_EMAIL = 'Contact Email'
  static BILLING_ADDRESS = 'Billing Address'
  static BILLING_LOCATION = 'Billing Location'
  static BUSINESS_UNIT = 'Business Unit'
  static BUSINESS_LINE = 'Business Line'
  static TAG_NAME = 'Tag Name'

  static NO_LOCATIONS = 'No Locations for this customer yet'
  static NO_DEACTIVATED_LOCATIONS = 'No Deactivated Locations for this customer yet'
  static NO_NOTES = 'No note for this customer yet'
  static NO_FOLLOW_UP = 'Click + button above to add a followup'
  static NO_CALLS = 'Click + button above to add a call'
  static NO_WORK_ORDERS = 'No work order for this customer yet'
  static NO_CONFIGURED_SERVICES = 'Click + button above to add a service'
  static NO_DEACTIVATED_CONFIGURED_SERVICES = 'No deactivated configured service for this customer yet'
  static NO_BILLING_LOCATION = 'No Billing Location for this customer yet'
  static NO_BILLING_DETAILS = 'No Billing Details for this customer yet'
  static NO_CUSTOMER_PORTAL_CONTACTS = 'No customer portal contacts'
  static NO_PAYMENTS = 'No payment for this customer yet'
  static NO_INVOICES = 'No invoice for this customer yet'
  static NO_WO_SERVICE_DETAILS = 'No Service Details for this work order yet'
  static NO_WO_INVOICE = 'No Invoice Details for this work order yet'
  static NO_WO_NOTES = 'No notes for this work order yet'
  static NO_WO_EXCEPTIONS = 'No Exceptions for this work order yet'
  static NO_CS_PRICING_SERVICE = 'No Pricing Service for this search'
  static NO_CS_FEE = 'No Fee for this service yet'
  static NO_CS_TAX = 'No Tax for this service yet'
  static NO_TAX_ZONE = 'No Tax for this Tax Zone yet'
  static NO_TAX_ZONE_SELECTED = 'Please select the Tax Zone'
  static NO_START_DATE_SELECTED = 'Please select the Start Date'
  static NO_DEFAULT_ACTION_SELECTED = 'Please select the Default Action'
  static NOT_ALLOWED_OFFICE_LOGIN_MSG =
    'Uh-oh! This user does not have rights to access the web app. Please contact the administrator for further details.'
  static NO_PAYMENT_INVOICES = 'There are no records to display invoices for this customer'
  static NO_RECORDS = 'There are no records'
  static NO_BILLING_INVOICES_FOUND = 'No Invoices Found'
  static NO_BILLING_SENT_INVOICES_FOUND = 'No Sent Invoices Found'
  static NO_BILLING_PAYMENTS_FOUND = 'No Payments Found'
  static NO_BILLING_ACCOUNT_RECEIVABLES_FOUND = 'No Account Receivables Found'
  static AR_REPORT_TYPE_FILTER = 'ACCOUNT_RECEIVABLES'
  static REVENUE_REPORT_TYPE_FILTER = 'REVENUE'
  static SERVICE_REPORT_TYPE_FILTER = 'SERVICES'
  static PAYMENT_REPORT_TYPE_FILTER = 'PAYMENTS'
  static INVOICE_REPORT_TYPE_FILTER = 'INVOICES'
  static SI_REPORT_TYPE_FILTER = 'SENT_INVOICES'
  static UPDATE_REPORT = 'Update Report'

  static NO_DATA = 'No Data found'
  static PHONE = 'Phone'
  static EMAIL = 'Email'
  static PASSWORD = 'Password'
  static PASSWORD_LENGTH_MSG = 'Minimum 6 characters'
  static NAME = 'Name'
  static BILLING_NOTIFICATIONS = 'Billing Notifications'
  static SERVICE_NOTIFICATIONS = 'Service Notifications'
  static ADD_LOCATION_CONTACT = 'Add Location Contact'

  static PENDING_PRICE_CHANGE_DATE = 'Pending Price Change Date'
  static NEXT_PRICE_CHANGE_DATE = 'Next Price Change Date'
  static CHANGE_RECENCY = 'Change Recency'
  static CUSTOMER_COUNT = 'Customer Count'
  static SPECIFIC_DAYS = 'Specific Days'
  static DATE_RANGE = 'Date Range'
  static FEES = 'Fees'
  static MINIMUM_FEE_COMPLETE = 'Minimum Fee'
  static CHANGE_BUSINESS_LINES_ALERT =
    'By changing Business Line, Measure, Materials, Specific Days, Method, Recurrence, Action and Pricing Zones field will be reset. Proceed?'

  static SERVICE_DUPLICATED_MSG = 'This pricing already exists and cannot be duplicated'
  static TAX_DUPLICATED_MSG = 'This tax already exists and will not be duplicated'
  static FEE_DUPLICATED_MSG = 'This fee already exists and will not be duplicated'

  static TAG = 'Tag'
  static ADDRESS = 'Address'
  static CONTACT_MOBILE = 'Contact Mobile'
  static CONTACT_PHONE = 'Contact Phone'
  static CONTACT_LEVEL = 'Contact Level'
  static SERVICE_RECENCY = 'Service Recency'
  static DRIVER = 'Driver'
  static MOBILE = 'Mobile'

  static BACK_TO_SEARCH_LIST = 'Back to Search List'
  static RECURRING_COLLECTION = 'Recurring collection'
  static SERVICE = 'Service'
  static MATERIAL = 'Material'
  static RECURRENCE = 'Recurrence'
  static ACTION = 'Action'
  static METHOD = 'Method'
  static SELECT = 'Select'
  static MEASURE = 'Measure'
  static ALERT_GO_BACK = 'Are you sure you want to go back?'
  static ALERT_CHANGES = 'Any changes will be lost.'
  static DISPOSAL_UNIT_OF_MEASURE = 'Disposal Unit Of Measure'
  static PRICING_ZONE = 'Pricing Zone'
  static PERIOD = 'Period'
  static PRICING_PERIOD = 'Pricing Period'
  static NEXT_PERIOD = 'Next Period'
  static UNLOCKED_PRICE = 'Unlocked Price'
  static STATUS = 'Status'
  static PAYMENT_STATUS = 'Payment Status'
  static VALUE = 'Value'
  static PRICE = 'Price'
  static SHOW_NOTE = 'Show Note'
  static MINIMUM_BILLING_PERIOD = 'Minimum Billing Period'
  static NEXT_VALUE_UPDATE = 'Next Value Update'
  static LAST_VALUE_UPDATE = 'Last Value Update'
  static NEXT_PRICE_UPDATE = 'Next Price Update'
  static LAST_PRICE_UPDATE = 'Last Price Update'
  static PRIORITY = 'Priority'
  static AGREEMENT = 'Agreement'
  static AGREEMENT_REVERTS = 'Agreement Reverts'
  static AGREEMENT_END = 'Agreement End'
  static AGREEMENT_MAX = 'Agreement Max'
  static NEW_SERVICE = 'New Service'
  static EDIT_SERVICE = 'Edit service'
  static ACTIVATE_SERVICE = 'Activate Service'
  static FREQUENCY = 'Frequency'
  static MONTHLY_REVENUE = 'Monthly Revenue'
  static LINKED_FEES_CHARGES = 'Linked Fees / Charges'
  static PRICED_SERVICE = 'Priced Service'
  static CONFIGURED_SERVICE = 'Configured Service'
  static LINKED_SERVICE = 'Linked Service'
  static LINKED_TAX = 'Linked Tax'
  static LINKED_GENERAL_FEE = 'Linked General Fee'
  static LINKED_DISPOSAL_FEE = 'Linked Disposal Fee'
  static ZONE = 'Zone'
  static SERVICE_INTERVAL = 'Service Interval'

  static REQUIRED_DAYS = 'Required Days'
  static DISCOUNT = 'Discount'
  static PREMIUM_REASON = 'Premium Reason'
  static PREMIUM = 'Premium'
  static SIGNATURE_REQUIRED = 'Signature Required'
  static MULTIPLE_CANS = 'Multiple Cans'
  static SERVICE_DAYS = 'Service Days'
  static ANNUAL_INCREASE = 'Annual Increase'
  static CONTRACT_END = 'Contract End'
  static NEXT_BILL_DATE = 'Next Bill Date'
  static WORK_ORDER = 'Work Order'
  static PHOTO = 'Photo'
  static SERVICE_WINDOW = 'Service Window'
  static DISPOSAL_QUANTITY = 'Disposal Quantity'
  static AMOUNT = 'Amount'
  static INVOICE = 'Invoice'
  static SOURCE = 'Source'
  static DAYS_OPEN = 'Days Open'
  static PINNED = 'Pinned'
  static LAST_UPDATE = 'Last Update'

  static NEW_TAX = 'New Tax'
  static CONTINUE = 'Continue'
  static CREATE_SERVICES = 'Create Services'
  static CREATE_TAX = 'Create Tax'
  static BACK = 'Back'
  static RECURRING = 'Recurring'
  static ON_REQUEST = 'On Request'
  static TEMPLATE = 'Template'
  static HIERARCHY = 'Hierarchy'
  static TAX = 'Tax'
  static SCOPE = 'Scope'
  static TAX_ZONE = 'Tax Zone'
  static TAX_AMOUNT = 'Tax Amount'
  static CHANGE_PRICE = 'Change Price'
  static DISPOSAL_FEE_NAME = 'Disposal Fee Name'
  static NEW_FEE = 'New Fee'
  static CREATE_FEE = 'Create Fee'
  static NEW_DISPOSAL_FEE = 'New Disposal Fee'
  static CREATE_DISPOSAL_FEE = 'Create Disposal Fee'
  static FEE_CHARGE = 'Fee / Charge'
  static SUB_ITEM = 'Sub-Item'
  static NOT_SUB_ITEM = 'Not Sub-Item'
  static EXCEPTION = 'Exception'
  static EXCEPTIONS = 'Exceptions'
  static GENERAL = 'General'
  static DISPOSAL = 'Disposal'
  static AUTO_LINK = 'Auto-Link'
  static AUTO_LINKED_SERVICES = 'Auto-Linked Service'
  static DISPOSAL_FEE = 'Disposal Fee'
  static DISPOSAL_WEIGHT = 'Disposal Weight'
  static COST_PLUS = 'Cost Plus'
  static NOT_COST_PLUS = 'Not Cost Plus'
  static TIERS = 'Tiers'
  static MINIMUM_FEE = 'Min. Fee'
  static OVERAGE_THRESHOLD = 'Overage Threshold'
  static OVERAGE = 'Overage'
  static OVERAGE_FEE = 'Overage Fee'
  static FEE = 'Fee'
  static ACTIVE_LOCATIONS = 'Active Locations'
  static ACTIVE_ACCOUNT = 'Active Account'
  static DEACTIVATED_LOCATIONS = 'Deactivated Locations'
  static CREATE_FEES_CHARGES = 'Create Fees & Charges'
  static LINKED_SERVICES = 'Linked Services'
  static CHANGE_SERVICE = 'Change Service'
  static CHANGE_TAX = 'Change Tax'
  static CHANGE_FEE = 'Change Fee'
  static SAVE_CHANGES = 'Save Changes'
  static CHANGE_GENERAL_FEE = 'Change General Fee'
  static CHANGE_DISPOSAL_FEE = 'Change Disposal Fee'
  static ACTIVATE = 'Activate'

  static CUSTOMER_PORTAL = 'Customer Portal'
  static CUSTOMER_ZONE = 'Customer Zone'
  static PEOPLE = 'People'
  static ROLE = 'Role'
  static TEAM = 'Team'
  static SKILL = 'Skill'

  static DELAY = 'Delay'
  static SAFETY = 'Safety'
  static CONTAMINATION = 'Contamination'
  static DAMAGE = 'Damage'
  static SERVICE_REVIEW = 'Service Review'
  static NO_ACCESS = 'No Access'
  static SPECIAL = 'Special'
  static EXTRA_ITEM = 'Extra Item'
  static WEIGHT = 'Weight'
  static OVERLOADED = 'Overloaded'

  static LINK = 'Link'
  static HISTORY = 'History'
  static LOCK = 'Lock'
  static DUPLICATE = 'Duplicate'
  static DEACTIVATE = 'Deactivate'
  static REACTIVATE = 'Reactivate'

  static BALANCE = 'Balance'
  static TAGS = 'Tags'

  static CHANGE_PASSWORD = 'Change Password'
  static LOGOUT = 'Log Out'
  static PRIVACY_POLICY = 'Privacy policy'
  static SIGN_IN = 'Sign in'
  static BOTH_PASSWORDS_MATCH = 'Both passwords match'
  static PASSWORD_CHANGED_SUCCESSFULLY = 'Password changed successfully'
  static CURRENT_PWD_INCORRECT = 'The current password you entered is incorrect. Please try again.'
  static WEAK_PASSWORD_ERROR = 'The new password is weak, please create a strong password.'
  static UNEXPECTED_ERROR = 'There was an unexpected error.'
  static ACCOUNT_DOESNT_EXIST = "Account doesn't exist"
  static RECOVER = 'Recover'

  static MIN = 'Min'
  static MAX = 'Max'
  static AVG = 'Avg'
  static ACTIVE = 'Active'
  static INACTIVE = 'Inactive'
  static REVENUE = 'Revenue'

  static EXPORT_DATA = 'Export data'

  static ACCOUNT = 'Account'
  static ACCOUNTS = 'Accounts'
  static CONTACT = 'Contact'
  static CONTACTS = 'Contacts'
  static LOCATION_CONTACT = 'Location Contact'
  static NOTES = 'Notes'

  static DATE = 'Date'
  static TIME = 'Time'
  static USER = 'User'
  static VISIBILITY = 'Visibility'
  static NOTE = 'Note'

  static SAVE = 'Save'
  static UPDATE = 'Update'
  static CANCEL = 'Cancel'
  static CONFIRM = 'Confirm'
  static DONE = 'Done'
  static APPLY = 'Apply'
  static CLOSE = 'Close'
  static OPEN = 'Open'
  static CREATE = 'Create'
  static PROCEED = 'Proceed'
  static NEXT = 'NEXT'
  static REMOVE = 'Remove'
  static SEND = 'Send'
  static POST = 'Post'
  static EXPORT_BATCH = 'Export Batch'
  static SUMMARY = 'Summary'
  static SAVE_VIEW = 'Save View'
  static SAVED_VIEW = 'Saved View'
  static ARCHIVE = 'Archive'
  static PAYMENT = 'Payment'
  static PAYMENTS = 'Payments'

  static NEW_CUSTOMER = 'New Customer'
  static CUSTOMER_NAME = 'Customer Name'
  static FULL_NAME = 'Full Name'
  static ATTENTION = 'Attention (ATTN:)'
  static SERVICE_LOCATION_NAME = 'Service Location Name'
  static SERVICE_LOCATION_ADDRESS = 'Service Location Address'
  static STREET_ADDRESS = 'Street address'
  static UNIT_NUMBER = 'Unit Number'
  static CITY = 'City'
  static STATE = 'State'
  static ZIP = 'Zip'
  static ZIP_CODE = 'Zip Code'
  static LATITUTE = 'Latitude'
  static LONGITUDE = 'Longitude'
  static ACCOUNT_REP = 'Account rep'
  static NAME_OF_CONTACT = 'Name of Contact'
  static FIRST_NAME = 'First Name'
  static LAST_NAME = 'Last Name'
  static USERNAME = 'Username'
  static JOB_TITLE = 'Job Title'
  static CONTACT_METHOD = 'Contact Method'
  static CONTACT_METHODS = 'Contact Methods'
  static ADD_CONTACT_METHOD = 'Add Contact Method'
  static ASSOCIATED_WITH = 'Associated With'
  static ADD_CONTACT = 'Add Contact'
  static PO = 'PO'
  static PO_NUMBER = 'PO Number'
  static PO_AMOUNT = 'PO Amount'
  static MAX_PO_AMOUNT = 'Max PO amount'
  static PROFILE = 'Profile'
  static CYCLE = 'Cycle'
  static TIMING = 'Timing (Invoice date)'
  static TYPE = 'Type'
  static CREDIT_LIMIT = 'Credit limit'
  static APPLY_CREDIT = 'Apply Credit'
  static PAYMENT_TERMS = 'Payment terms'
  static LATE_FEES = 'Late Fees'
  static THRESHOLD = 'Threshold'
  static GRACE_PERIOD = 'Grace period'
  static DELIVERY_METHOD = 'Delivery method'
  static TAX_EXEMPT = 'Tax Exempt'
  static TAX_IDENTIFIER = 'Tax Exemption Identifier'
  static CUSTOMER_BILLING_PROFILE = 'Customer Billing Profile'
  static AVAILABLE_CREDIT = 'Available Credit'
  static LATE_FEES_FINANCING = 'Late fees / Financing'
  static TAX_STATUS = 'Tax status'
  static PRORATION = 'Proration'
  static PRORATION_PREFERENCE = 'Proration Preference'
  static DAY_BASED_PRORATION = 'Day-Based Proration'
  static EVENT_BASED_PRORATION = 'Event-Based Proration'

  static FIRST_MONTHS = 'First months'
  static MONDAY = 'Monday'
  static TUESDAY = 'Tuesday'
  static WEDNESDAY = 'Wednesday'
  static THURSDAY = 'Thursday'
  static FRIDAY = 'Friday'
  static SATURDAY = 'Saturday'
  static SUNDAY = 'Sunday'
  static WEEKEND = 'Weekend'
  static WORKING_DAYS = 'Working Days'
  static ASSIGNEE = 'Assignee'
  static DAYS = 'Days'
  static DAY = 'Day'
  static FIRST = 'First'
  static SECOND = 'Second'
  static THIRD = 'Third'
  static FOURTH = 'Fourth'
  static LAST = 'Last'

  static CALLS = 'Calls'
  static ADDED_BY = 'Added By'
  static SUBJECT = 'Subject'
  static DIRECTION = 'Direction'

  static SERVICE_LOCATION = 'Service Location'
  static CALL_NOTE = 'Call Note'
  static LOGGED_BY = 'Logged By'

  static WORK_ORDERS = 'Work Orders'
  static PHOTOS = 'Photos'
  static TOTAL = 'Total'
  static WO_PHOTOS = "Work Order's Photos"
  static DATE_AND_TIME = 'Date and Time'
  static CAPTION = 'Caption'
  static ADD_REMOVE_EVENT = 'Add / Remove Event'
  static SCHEDULE = 'Schedule'
  static MAP = 'Map'
  static ADVANCED = 'Advanced'
  static ON_CALL = 'On Call'
  static SIGNATURE = 'Signature'
  static SKILLS_REQUIRED = 'Skills Required'
  static PRICE_PERIOD = 'Price Period'
  static SAVE_DRAFT = 'Save Draft'
  static NEXT_STEP = 'Next Step'
  static LINKED = 'Linked'
  static ASSIGNED_TO = 'Assigned To'
  static RATE = 'Rate'
  static ZONES = 'Zones'
  static STREET = 'Street'
  static ROUTE_NOTE = 'Route Note'
  static CONTAINER_DETAIL = 'Container Detail'
  static ADD_CONTAINER = 'Add Container'
  static NUMBER_OF_CONTAINERS = 'Number of Containers'
  static DAY_OF_WEEK = 'Day of Week'
  static SPECIFIC_DATE = 'Specific Date'
  static WEEK = 'Week'
  static MONTH = 'Month'
  static QUARTER = 'Quarter'
  static ANNUAL = 'Annual'
  static YEAR = 'Year'
  static EVENT = 'Event'
  static EDIT_ACCOUNT_LOCATIONS = 'Edit Account & Locations'
  static EDIT_ACCOUNT = 'Edit Account'
  static SERVICE_ADDRESS = 'Service Address'
  static ACTIVE_ONLY = 'Active Only'
  static ADD_LOCATION = 'Add Location'
  static RESET_PASSWORD = 'Reset Password'
  static RESET_MY_PASSWORD = 'Reset My Password'
  static ALREADY_HAVE_AN_ACCOUNT = 'Already have an account?'
  static EDIT_CONTACT = 'Edit Contact'
  static CREATE_CONTACT = 'Create Contact'
  static ADD_NEW_PAYMENT_METHOD = 'Add New Payment Method'
  static NEW_PAYMENT_METHOD = 'New Payment Method'

  static DEACTIVATE_SINGLE_LOCATION_MSG = 'Are you sure you want to deactivate this location?'
  static DEACTIVATE_MULTIPLE_LOCATION_MSG = 'Are you sure you want to deactivate these locations?'
  static REACTIVATE_SINGLE_LOCATION_MSG = 'Are you sure you want to reactivate this location?'
  static REACTIVATE_MULTIPLE_LOCATION_MSG = 'Are you sure you want to reactivate these locations?'

  static COLOR_SCHEME = 'Color Scheme'
  static BILLING_DELIVERY_METHODS = 'Billing Delivery Methods'
  static MAIN_NAVIGATION_BAR = 'Main (Navigation Bar)'
  static NAVIGATION_FONT_COLOR = 'Navigation Font Color'
  static SECONDARY_TEXT_HEADINGS = 'Secondary (Page Headings)'
  static ACTION_BUTTONS = 'Action Buttons'
  static ACTION_BUTTONS_FONT_COLOR = 'Action Buttons Font Color'
  static PDF = 'PDF'
  static PAPER = 'Paper'
  static POSTCARD = 'Postcard'
  static PORTAL = 'Portal'

  static ATTRIBUTES = 'Attributes'
  static CREATE_N_CONFIGURE = 'Create & Configure'
  static CREATE_CONFIGURE = 'Create Configure'
  static FEES_PER_PERIOD = '$ Fees per Period'
  static SERVICES_PER_PERIOD = 'Services per Period'
  static ADD_PRICING_PERIOD = 'Add Pricing Period'

  static DETAIL = 'Detail'
  static ALL_CONTAINERS = 'All Containers'
  static CONTAINER = 'Container'
  static CONTAINERS = 'Containers'
  static SERVICE_DETAILS = 'Service details'
  static INVOICE_DETAILS = 'Invoice details'
  static WORK_ORDER_DETAILS = 'Work order details'
  static REVIEW = 'Review'
  static QUANTITY = 'Quantity'
  static COST = 'Cost'
  static FEE_AND_TAXES = 'Fees & Taxes'
  static TAXES = 'Taxes'
  static TONS = 'tons'
  static EDIT_NOTE = 'Edit note'
  static ADD_NOTE = 'Add note'
  static ADD_TO_CS = 'Add to Configured Service'
  static ADD_TO_LOCATION = 'Add to Location'

  static ADD_RECURRENCE = 'Add Recurrence'
  static GALLON = 'Gallon'
  static YARD = 'Yard'
  static POUND = 'Pound'
  static TON = 'Ton'
  static ITEM = 'Item'
  static ADD_CONTAINER_SIZE = 'Add Container Size'
  static COLOR = 'Color'
  static TEXT_COLOR = 'Text Color'
  static LABEL = 'Label'
  static BORDER = 'Border'
  static PREVIEW = 'Preview'
  static RESTRICT_MEASURES = 'Restrict Measures'
  static REQUIRE_PHOTO = 'Require Photo'
  static ALREADY_SELECTED = 'Already Selected'
  static ADD_MATERIAL = 'Add Material'
  static ADD_SKILL = 'Add Skill'
  static ACTION_NAME = 'Action Name'
  static ADD_ACTION = 'Add Action'
  static METHOD_NAME = 'Method Name'
  static ADD_METHOD = 'Add Method'
  static COLLECTION = 'Collection'
  static DELIVERY = 'Delivery'
  static DUMP_N_RETURN = 'Dump & Return'
  static SWAP = 'Swap'
  static FINAL = 'Final'
  static REPOSITION = 'Reposition'
  static LIVE_LOAD = 'Live Load'
  static FRONT_LOAD = 'Front Load'
  static REAR_LOAD = 'Rear Load'
  static HOOK = 'Hook'
  static HOIST = 'Hoist'
  static GRAPPLE = 'Grapple'
  static SIDE_LOAD = 'Side Load'
  static ADD = 'Add'
  static INVITE = 'Invite'
  static DRIVER_CHANGE_ACTION = 'Driver Change Action'
  static NEW = 'New'
  static EDIT = 'Edit'
  static ICON = 'Icon'
  static RESIDENTIAL = 'Residential'
  static COMMERCIAL = 'Commercial'
  static ROLLOFF = 'Roll-off'
  static ROLE_NAME = 'Role Name'
  static EMPLOYEES = 'Employees'
  static LAST_UPDATED = 'Last Updated'
  static OFFICE = 'Office'
  static ROAD = 'Road'
  static ACTIVATE_ROAD_LOGIN = 'Activate Road Login'
  static CHANGE_AS_GROUP = 'Change As Group'
  static NONE = 'None'
  static VIEW = 'View'
  static FULL = 'Full'
  static SETTINGS = 'Settings'
  static PERMISSION = 'Permission'
  static PRICING_MANAGER = 'Pricing Manager'
  static DISPLAY = 'Display'
  static DATA_CAPTURE = 'Data Capture'
  static EXPAND = 'Expand'
  static COMPLETE = 'Complete'
  static SKIP = 'Skip'
  static STRAIGHT_TO_PHOTO = 'Straight to Photo'
  static SHOW_MILES_ON_ROUTE_LIST = 'Show Miles on Route List'
  static PURCHASE_ORDER = 'Purchase Order #'
  static PAVEMENT = 'Pavement'
  static SEQUENCE = 'Sequence'
  static CUSTOMER_INFORMATION = 'Customer Information'
  static TRUCK = 'Truck'
  static AUTOCOMPLETE = 'AutoComplete'
  static AUTO_COMPLETE = 'Auto-Complete'
  static TIMER = 'Timer'
  static VIEW_CONTACT_INFO = 'View the contact info for the service location'
  static ABILITY_TO_CHANGE_TRUCK = 'Ability of driver to change truck'
  static ABILITY_TO_CHANGE_ROUTE = 'Ability of driver to change route'
  static ALLOW_TO_AUTOCOMPLETE = 'Allow driver to enable Auto-Complete'
  static ALLOW_TIMER = 'Enable driver to modify the interruption timer (countdown)'
  static POST_INVOICES_PAYMENT = 'Post Invoices and Payment'
  static APPLY_PAYMENT = 'Apply payment'
  static APPLY_PAYMENTS = 'Apply Payments'
  static CUSTOMER_MANAGER = 'Customer Manager'
  static ROUTE_MANAGER = 'Route Manager'
  static REPORTING = 'Reporting'
  static GLOBAL_SEARCH = 'Global Search'
  static QTY = 'QTY'
  static NO_PAYMENT = 'No Payment'
  static NOT_OUT = 'Not Out'
  static CAMERA = 'Camera'
  static SERIAL_NUMBER = 'Serial Number'
  static ACTIVATE_OFFICE_LOGIN = 'Activate Office Login'
  static ACCESS_MODIFY_SETTINGS = 'Access / Modify Settings'
  static MODIFY_PERMISSIONS_IN_ROLES = "View, modify or create a role and its permissions, and modify an individual user's permissions"
  static CREATE_USER_INFO = 'Create a user or modify user information & assign users to roles'
  static RESET_USER_PWD = "Reset a user's password"
  static CREATE_PRICING_DESC = 'Create service, fees or taxes; edit them; create them or deactivate them'
  static BILLING_INFO = 'View the Billing tab'
  static VIEW_CUSTOMER_BILLING_PROFILE = 'View all elements of customer billing profile, edit these; create / delete these'
  static BILLING_INVOICES = 'View all elements of invoices; edit and send these; create / delete these'
  static POST_INVOICES_PAYMENTS = 'Post invoices & payments'
  static PAYMENT_PERMISSION_NOTE = 'View payments; edit notes on payments; collect new payment & refund payments'
  static LOCK_DATE_INFO =
    'Set the lock date (before this date, no invoices or payments can be changed - user cannot create adjustment invoices in this period; however user can apply payments to invoices in this period)'
  static REPORT_INFO = 'View, modify or schedule, create / delete reports'
  static GLOBAL_SEARCH_INFO = 'View all entries in the system through Global Search'
  static DASHBOARD_INFO = 'View Dashboard'
  static ANY_FOLLOWUP_INFO = 'View and/or edit follow ups that are not assigned to or created by that particular user'
  static FOLLOWUPS_INFO = 'View, modify, create or delete follow ups'
  static ROUTE_INFO =
    'View, modify, create or delete routes, assign stops to routes, reorder routes, add disposal sites, edit disposal site order'
  static CUSTOMER_MANAGER_INFO = 'View phone, email, name of contacts; edit these; create / delete these'
  static SERVICE_INFO = 'View services, service events and work orders; edit these; create / delete these'

  static DROPDOWN = 'Dropdown'
  static DROP = 'Drop'
  static RENTAL = 'Rental'
  static SELECTION = 'Selection'
  static NUMBER = 'Number'
  static PERSONAL_INFORMATION = 'Personal Information'
  static PAY = 'Pay'
  static LAST_LOGIN = 'Last Login'
  static BASIC_INFO = 'Basic Information'
  static WORK_INFO = 'Work Information'
  static ACCOUNT_REPRESENTATIVE = 'Account Representative'
  static DEFAULT_TRUCK = 'Default Truck'
  static COMMERCIAL_DRIVER_LICENSE = "Commercial Driver's License"
  static PAYROLL = 'Payroll'
  static PAY_RATE = 'Pay Rate'
  static BURDEN_RATE = 'Burden Rate'
  static HOURLY = 'Hourly'
  static SALARY = 'Salary'
  static CHANGE_INDIVIDUAL_PERMISSIONS = 'Change Individual Permissions'
  static INVALID_EMAIL = 'Invalid Email'
  static INVALID_PHONE_NUMBER = 'Invalid Phone Number'
  static INVALID_DATE = 'Invalid Date'
  static ASSET = 'Asset'
  static POWER_UNIT = 'Power Unit'
  static TYPE_SKILL_HERE = 'Type Skill Here'
  static ASSIGNED = 'Assigned'
  static TEXT = 'Text'
  static REQUIRES = 'Requires'
  static ADD_TAG = 'Add Tag'
  static ZONE_NAME = 'Zone Name'

  static ACCOUNTS_RECEIVABLE = 'Accounts receivable'
  static TOTAL_INVOICES = 'Total Invoices'
  static INVOICES = 'Invoices'
  static SENT_INVOICES = 'Sent invoices'
  static TOTAL_REVENUE = 'Total Revenue'
  static VIEW_NAME = 'View Name'
  static SEND_INVOICE_SUCCESS = 'Invoices have been Sent'
  static POST_INVOICE_SUCCESS = 'Invoices have been posted'
  static REVERT_INVOICE_TO_DRAFT_SUCCESS = 'Invoice reverted to draft state'
  static SEND_PAYMENT_RECEIPT_SUCCESS = 'Payment receipt has been sent'
  static SEND_PAYMENT_RECEIPTS_SUCCESS = 'Payment receipts have been sent'
  static NEW_PAYMENT = 'New Payment'
  static TAKE_PAYMENT = 'Take payment'
  static ACCOUNT_RECEIVABLE_DOWNLOAD_SUCCESS = 'Downloaded Successfully'
  static PAYMENT_POST_SUCCESS = 'Payment is processing. Please see payment details for more information.'
  static PAYMENT_BATCH_POST_SUCCESS = 'Payment batch is processing. Please see payment details for more information.'
  static PAYMENT_BATCH_PROCESSING = 'Payment batch is processing ...'
  static PAYMENT_PROCESSING = 'Payment is processing ...'

  static CREATE_ROUTE = 'Create Route'
  static OCCURS_ON = 'Occurs on'
  static ONE_OFF = 'One off'
  static ONE_TIME = 'One Time'
  static OPTIONAL = 'Optional'
  static ENDING_YARD = 'Ending Yard'
  static STARTING_YARD = 'Starting Yard'
  static START_DATE = 'Start Date'
  static END_DATE = 'End Date'
  static SERVICE_START_DATE = 'Service Start Date'
  static LAST_WORK_ORDER_DATE = 'Last Work Order Date'
  static LAST_WORK_ORDER = 'Last Work Order'
  static NEXT_WORK_ORDER_DATE = 'Next Work Order Date'
  static NEXT_WORK_ORDER = 'Next Work Order'
  static ROUTE_NAME = 'Route Name'
  static NEW_ROUTE = 'New Route'
  static EDIT_ROUTE_DETAILS = 'Edit Route Details'
  static DELETE_ROUTE = 'Delete Route'
  static YES_DELETE_ROUTE = 'Yes, Delete Route'
  static YES_PROCEED = 'Yes, Proceed'

  static POSTCARD_SETUP = 'Postcard Setup'
  static BILLING_CYCLE = 'Billing Cycle'
  static BILLING_TIMING = 'Billing Timing'
  static PRICING_REASONS = 'Pricing Reasons'
  static CUSTOMER_PERSONAL_INFO = 'Customer Personal Info'
  static INSTANCE = 'Instance'
  static PERMIT = 'Permit'
  static ADD_PERMIT = 'Add Permit'
  static DEFAULT = 'Default'
  static WO = 'WO#'
  static EXPECTION = 'Expection'
  static MANUAL = 'Manual'

  static GENERAL_SETTING = 'General Setting'
  static DISPOSAL_SITE = 'Disposal Site'
  static COUNTRY = 'Country'
  static BUSINESS_HOURS = 'Business Hours'
  static TICKET_REQUIRED = 'Ticket Required'
  static DISPOSAL_SITE_HOURS = 'Disposal Site Hours'
  static DISPOSAL_SITE_MATERIALS = 'Disposal Site Materials'
  static ALL = 'All'
  static UNIT_OF_MEASURE = 'Unit Of Measure'
  static TIPPING_FEE = 'Tipping Fee'
  static SUSPENSION_THRESHOLD = 'Suspension Threshold'
  static COLLECTION_LETTER = 'Collection Letter'
  static LETTER = 'Letter'
  static DAYS_AFTER_LATE_FEE = 'Days after Late Fee'
  static ADVANCE = 'Advance'
  static ARREARS = 'Arrears'
  static FILTER_ROUTES_BY = 'Filter Routes By'
  static ROUTE_NUMBER = 'Route Number'
  static APPLY_FILTERS = 'Apply Filters'

  static PAYMENT_ID = 'Payment ID'
  static PAID_DATE = 'Paid Date'
  static PAID_TIME = 'Paid Time'
  static APPLIED = 'Applied'
  static POSTED_DATE = 'Posted Date'
  static POSTED_TIME = 'Posted Time'
  static POSTED = 'Posted'
  static UNPOSTED = 'Unposted'
  static CUSTOMER_ADDRESS = 'Customer Address'
  static CUSTOMER_CITY = 'Customer City'
  static CUSTOMER_STATE = 'Customer State'
  static CUSTOMER_ZIP = 'Customer Zip'
  static ADJUSTMENTS = 'Adjustments'
  static ADJUSTMENTS_TOTAL = 'Adjustments Total'

  static PASSWORD_CHANGE_MSG = 'Your password has been successfully changed!'
  static PHONE_NUMBER = 'Phone Number'
  static LAST_PAYMENT_DATE = 'Last Payment Date'
  static LAST_PAYMENT_AMOUNT = 'Last Payment Amount'
  static BILLING_TYPE = 'Billing Type'
  static PAYMENT_TYPE = 'Payment Type'
  static DOWNLOAD = 'Download'
  static PRINT = 'Print'
  static POST_DATE = 'Post Date'
  static LOCK_DATE = 'Lock Date'
  static STATEMENT = 'Statement'
  static INVOICES_NUMBER = '# Invoices'
  static INVOICE_NUMBER = 'Invoice Number'
  static CONCIERGE = 'Concierge'
  static RESET = 'Reset'
  static SCHEDULED = 'Scheduled'
  static ACCOUNT_NUMBER = 'Account Number'
  static UNAPPLIED_AMOUNT = 'Unapplied Amount'
  static CHECK = 'Check'
  static OTHER = 'Other'
  static CREDIT_CARD = 'Credit Card'
  static ACH = 'ACH'
  static CASH = 'Cash'
  static ACCOUNT_CREDIT = 'Account Credit'
  static ACCOUNT_DEBIT = 'Account Debit'
  static UNPAID_BALANCE = 'Unpaid Balance'
  static LAST_INVOICE = 'Last Invoice'
  static OLDEST_UNPAID_INVOICE = 'Oldest Unpaid Invoice'
  static NEW_CARD = 'New card'
  static NEW_ACH = 'New ACH'
  static SAVE_METHOD = 'Save method'
  static CARDHOLDER_NAME = 'Cardholder Name'
  static CARD_NAME = 'Card Name'
  static NO_OF_SERVICES = '# of Services'
  static METHOD_NICKNAME = 'Method Nickname'
  static BANK_ROUTING_NUMBER = 'Bank Routing Number'
  static BANK_ACCOUNT_NUMBER = 'Bank Account Number'
  static NAME_ON_ACCOUNT = 'Name on Account'
  static BANK_ACCOUNT_TYPE = 'Bank Account Type'
  static TRANSACTION_SURCHARGE_NOTE = 'Transactions will incur a surcharge where applicable.'
  static TRANSACTION_SURCHARGE_DESCRIPTION =
    'We impose a surcharge on the transaction amount on some credit card products, which is not greater than our cost of acceptance. We do not surcharge debit cards.'
  static ROUTING_ACCOUNT_NUMBER = 'Routing Number / Account Number'

  static SELECTED_METHOD = 'Selected Method'
  static AUTOPAY_TIMING = 'AutoPay Timing'
  static AUTOPAY = 'Autopay'
  static AUTOPAY_PAYMENT_TYPE = 'Autopay Payment Type'
  static AUTOPAY_PAYMENT_METHOD = 'Autopay Payment Method'
  static AUTOPAY_PAYMENT_CARD = 'Autopay Payment Card'
  static AUTOPAY_EXPIRY = 'Autopay Expiry'
  static IMMEDIATE = 'Immediate'
  static DUE_DATE = 'Due Date'
  static PAYMENT_METHOD_NAME = 'Payment Method Name'
  static PAYMENT_METHOD = 'Payment Method'
  static SERVICED = 'Serviced'
  static IN_PROCESS = 'In Process'
  static PAID = 'Paid'
  static SENT_BY = 'Sent by'
  static NOT_SENT_BY = 'Not Sent by'
  static APPROVED = 'Approved'
  static REVERSED = 'Reversed'
  static TRANSFER = 'Transfer'
  static DECLINED = 'Declined'
  static FAILED = 'Failed'
  static DEACTIVATED = 'Deactivated'
  static UNPAID = 'Unpaid'
  static PARTIAL = 'Partial'
  static DRAFT = 'Draft'
  static NOT_STARTED = 'Not Started'

  static NO_PRICING_SERVICES_FOUND = 'No Services Found'
  static NO_PRICING_FEES_FOUND = 'No Fees Found'
  static NO_PRICING_TAXES_FOUND = 'No Taxes Found'

  static EDIT_INVOICE = 'Edit Invoice'
  static INVOICE_FEES_ADJUSTMENTS = 'Invoice Fees & Adjustments'
  static FEES_ADJUSTED = 'Invoice fees have been adjusted'

  static CREATE_REPORT = 'Create Report'
  static SAVE_REPORT = 'Save Report'
  static NEW_REPORT = 'New Report'
  static DATASET = 'Data Set'
  static PARAMETERS = 'Parameters'
  static DOWNLOAD_TIMEOUT = 'Download Timeout'
  static COMMON_CONFIRM_TITLE = 'Are you sure you want to close? Any data entered will be lost.'
  static FOLLOWUP_CONFIRM_TITLE = 'Are you sure you want to close the Follow Up? Any data entered will be lost.'
  static CHANGE_DATE = 'Change date'
  static EXPORT_ROUTE = 'Export route'
  static VIEW_ON_MAP = 'View on map'
  static EDIT_ROUTE = 'Edit route'
  static BULK_ASSIGN = 'Bulk assign'
  static MOVE_TO = 'Move To'
  static AFTER_SEQUENCE = 'After Sequence'
  static MOVE_STOPS = 'Move Stops'
  static ASSIGN_TO = 'Assign to'
  static BULK_UNASSIGN = 'Bulk unassign'
  static BULK_COMPLETE = 'Bulk complete'
  static UNASSIGN = 'Unassign'
  static SKIPPED = 'Skipped'
  static UPDATED_AT = 'Updated at'
  static BILLING_PROFILE = 'Billing profile'

  static STANDARD = 'Standard'
  static LEGACY = 'Legacy'
  static UNBILLED = 'Unbilled'
  static CURRENT = 'Current'
  static DEPOSIT = 'Deposit'
  static SUB_TOTAL = 'Subtotal'

  static DIFFERENT_BILLING_DATES = 'Different Billing Dates'
  static BILLING_START_DATE = 'Billing Start Date'
  static BILLING_END_DATE = 'Billing End Date'

  static ON_REQUEST_DEACTIVATE = 'You must remove all future service events before you can deactivate the service.'
  static ON_REQUEST_DEACTIVATE_FUTURE_DATE_MSG = 'Service cannot be deactivated until after the final service event.'
  static ON_RECCURING_DEACTIVATE_NO_END_DATE_MSG = 'You must specify a service end date before you can deactivate the service.'
  static ON_RECCURING_DEACTIVATE_FUTURE_DATE_MSG = 'Service cannot be deactivated until the service end date.'
  static ON_RECCURING_SET_END_DATE_MSG =
    'Setting an end date for this service will stop the generation of new work orders and delete existing work orders after the service end date. Do you want to continue?'
  static DOWNLOAD_PAYMENT_RECEIPT = 'Download Payment Receipt'
  static EMAIL_PAYMENT_RECEIPT = 'Email Payment Receipt'
  static AR_REPORT = 'AR_REPORT'
  static REVENUE_REPORT = 'REVENUE_REPORT'
  static SERVICE_REPORT = 'SERVICE_REPORT'
  static PAYMENT_REPORT = 'PAYMENT_REPORT'
  static INVOICE_REPORT = 'INVOICE_REPORT'
  static SI_REPORT = 'SI_REPORT'
  static WO_REPORT = 'WO_REPORT'
  static TERMS_CONDITIONS = 'Terms & Conditions'

  static ROUTE_SEQUENCE = 'RS'
  static ROUTE_PLAN_ID = 'RP'
  static ROUTE_PERMANENT_SEQUENCE = 'RPS'

  static DESCRIPTION = 'Description'
  static UNIT_PRICE = 'Unit Price'
  static SERVICE_PRICE = 'Service Price'

  static UNASSIGNED_STOPS = 'Unassigned Stops'
  static UNASSIGNED_STOPS_NO_ITEMS = 'No Unassigned Stops Available'
  static SELECTED = 'Selected'
  static OK = 'OK'

  static AR_BALANCE = 'AR Balance'
  static SERVICE_GROUP = 'Service Group'
  static SERVICE_GROUPS = 'Service Groups'
  static SELECTED_SERVICES = 'Selected Services'
  static TOTAL_SERVICES = 'Total Services'
  static ADD_SERVICES = 'Add Services'
  static REMOVE_SERVICES = 'Remove Services'
  static NEW_SERVICE_GROUP = 'New Service Group'
  static ADD_SERVICE_GROUP = 'Add Service Group'

  static ANNIVERSARY_LABEL = 'Anniversary'
  static ANNIVERSARY_VALUE = 'Anniversary'

  static IMPORT_SEQUENCE = 'Import sequence'
  static ROUTE_PLAN_ID_WRONG_COLUMN_ERROR =
    'Your sequence import did not have a column named Route Plan ID. Please try a route export and you’ll have a properly formatted file to import with a hidden Column O where Route Plan ID column is properly formatted with work orders that can be sequenced.'
  static WORK_ORDER_ID_WRONG_COLUMN_ERROR =
    'Your sequence import did not have a column named Work Order ID. Please try a route export and you’ll have a properly formatted file to import with a hidden Column O where Work Order ID column is properly formatted with work orders that can be sequenced.'
  static SUCCESS_IMPORT_SEQUENCE = 'Route Import Successful!'
  static ERROR_READING_SEQUENCE_FILE =
    'The sequence import accepts XLSX files only. Please try a route export and you’ll have a properly formatted file to import.'
  static ROUTE_PLAN_ID_WRONG_UUID_FORMAT =
    'Imported Route Plan IDs were not the correct format. Please try a route export and then make sure not to modify format of hidden Column O, but the sequence only. Adding IDs or data to column O that are not in the export increases chance of this error, and IDs that are not matching will be ignored for purposes of sequencing.'
  static WORK_ORDER_ID_WRONG_UUID_FORMAT =
    'Imported Work Order IDs were not the correct format. Please try a route export and then make sure not to modify format of hidden Column O, but the sequence only. Adding IDs or data to column O that are not in the export increases chance of this error, and IDs that are not matching will be ignored for purposes of sequencing.'
  static ROUTE_PLAN_ID_COLUMN = 'Route Plan ID'
  static WORK_ORDER_ID_COLUMN = 'Work Order ID'
  static EMPTY_FILE = 'File is empty'

  static PAYMENT_TYPE_ADVANCE = 'Advance'
  static PAYMENT_TYPE_MANUAL = 'Manual'

  static ROUTE_FILTER_ALL = 'All Routes'
  static SELCT_ROUTE_BULK_ASSIGN = 'Please select a work order'
  static SELECT_ROUTE_INPUTS = 'Please select route and sequence'
  static GENERAL_LEDGER = 'General Ledger'
  static GENERAL_LEDGER_ACCOUNT = 'General ledger account'
  static ADD_ACCOUNT = 'Add Account'
  static SELECT_ROUTE = 'Select Route'
  static ERROR = 'Error'
  static SUGGESTED_FILTERS = 'SUGGESTED FILTERS:'
  static MOVE_ROUTE_TO = 'Move route to'
  static SERVICES = 'Services'
  static LOCATION_TAGS = 'Location Tags'

  static UNAVAILABLE = 'UnAvailable'
  static CONFIRMATION = 'Confirmation'
  static WO_DATE_CHANGE_MSG = 'Are you sure you want to change the Work Order Date?'
  static WO_DATE_CHANGE_SUCCESS = 'Work Order Date changed. Please route this Work Order from the Route Manager.'

  static FILTER_TAGS_PLACEHOLDER = 'Select or type tags'
  static ACCOUNT_VIEW = 'Accounts'
  static SERVICE_VIEW = 'Locations'
  static INCLUDE_DEACTIVATED_ACCOUNTS = 'Include Deactivated Accounts'
  static INCLUDE_DEACTIVATED_LOCATIONS = 'Include Deactivated Locations'
  static SERVICE_NAME = 'Service name'
  static SERVICE_TAGS = 'Service Tags'
  static ACCOUNT_TAGS = 'Account Tags'
  static CUSTOM = 'Custom'
  static CUSTOMER_SERVICES_MSG = 'You are seeing your top 10 most recent services.'
  static SELECT_ALL = 'Select All'
  static PREVIOUS_DATE = 'Previous Date'
  static NEW_DATE = 'New Date'
  static AFFECTED_WORK_ORDERS = 'Affected Work Orders'
  static REVIEW_CONFIRM_DAYS = 'Review and confirm the following updates that will occur based on your changes.'
  static RE_ROUTE_UPDATES = 'Please Note: All affected work orders will be unassigned and will need to be re-routed.'
  static PLEASE_SELECT = 'Please select'
  static DEACTIVATE_ON_END_DATE = 'Deactivate on End Date'
  static DAYS_CANCEL_SELECTION = 'If you choose to cancel, you will lose all your progess and changes'
  static UNASSIGNED = 'Unassigned'

  static DISPOSAL_TICKET = 'Disposal ticket'
  static ADD_DISPOSAL_TICKET = 'Add disposal ticket'
  static EDIT_DISPOSAL_TICKET = 'Edit disposal ticket'
  static DISPOSAL_TICKETS_EMPTY_STATE_MSG = 'No disposal tickets for this work order yet'
  static TICKET = 'Ticket'
  static DELETE = 'Delete'
  static ADD_MATERIAL_AND_ITEM = 'Material'
  static DELETE_DISPOSAL_TICKET_MSG = 'Are you sure you want to delete the Disposal Ticket?'
  static REQUIRED_FIELD_MSG = 'Please fill in all the required fields'
  static NOT_AVAILABLE = 'Not Available'
  static PREVIOUS_WO = 'Previous WO #'
  static BATCH_DATE = 'Batch Date'
  static PAYMENT_BATCH_CONFIRMATION = 'Payment Batch Confirmation'
  static CREATED_BY = 'Created By'
  static BATCH_NUMBER = 'Batch Number'
  static SUCCESS = 'Success'
  static PAYMENT_DATE = 'Payment Date'
  static TRANSACTION_DATE = 'Transaction Date'
  static XLS = 'XLS'
  static CREATE_INVOICES = 'Create Invoices'
  static CREATE_INVOICE = 'Create invoice'
  static NEXT_BILLING_DATE = 'Next Billing Date'
  static MY_SAVED_VIEWS = 'My Saved Views'
  static BILLING_PERIOD = 'Billing Period'
  static INVOICE_DATE = 'Invoice Date'
  static CHANGE = 'Change'
  static CREATE_INVOICE_TABLE_REQUIRED_MSG = 'Please select a Customer Billing Profile and Billing Period to see data'
  static INVOICE_IS_GENERATING = 'Invoice is generating.'
  static CALENDAR_SAVE_MESSAGE = 'Saving changes...'
  static MY_DASHBOARD = 'My Dashboard'
  static POSTCARD_LAYOUT = 'Postcard Layout'
  static POSTCARD_PREVIEW = 'Postcard Preview'
  static POSTCARDS_ON_PAGE = 'Postcards on page'
  static INTERVAL = 'Interval'
  static NET = 'Net'
  static NO_RECORD = 'No Record'
  static WORK_ORDER_AND_ROUTE_INFO = 'Work Order and Route Information'
  static ITEM_APPEAR_INFO = 'Set up which items appear on details card in the Road app.'
  static ACCOUNT_TRANSACTIONS = 'Account Transactions'
  static REGENERATE_INVOICE_DISABLED_MSG = 'Cannot regenerate selected invoices. Please deselect invoices that are paid, or voided'
  static REGENERATE_INVOICES_SUCCESS_MSG = 'Selected Invoices successfully regenerated'
  static REGENERATE_DRAFT_INVOICES_SUCCESS_MSG = 'Draft invoices successfully regenerated'
  static APPLY_CREDIT_SUCCESS_MSG = 'Credit Applied successfully'
  static NOTIFICATIONS_EMPTY_STATE_MSG = "That's all your notifications"
  static OPEN_FULL_NOTIFICATIONS_PAGE = 'Open full notifications page'
  static MARK_ALL_AS_READ = 'Mark all as read'
  static ACCOUNT_DEACTIVATION_MSG = 'This account has been deactivated'
  static PRINTABLE_VIEW = 'Printable view'
  static NOTIFICATIONS = 'Notifications'
  static INVOICE_REDIRECT_MSG = 'Invoices are generating. Redirecting…'

  static MARK_AS_PAID = 'Mark as paid'
  static MARK_AS_PAID_DISABLED_MSG = 'Only $0 unpaid invoices can be mark as paid'
  static MARK_AS_PAID_SUCCESS = 'Selected invoices successfully marked as paid'
  static MARK_AS_SENT = 'Mark as sent'
  static MARK_AS_SENT_SUCCESS = 'Selected invoices successfully marked as sent'
  static MARK_DRAFT_AS_SENT_SUCCESS = 'Draft invoices successfully marked as sent'
  static DELETE_INVOICES_SUCCESS_MSG = 'Selected Invoices successfully deleted'
  static DELETE_INVOICE_DISABLED_MSG = 'Cannot delete selected invoices. Please deselect invoices that are unpaid, paid, or voided'
  static NEW_CUSTOMER_DEFAULT_SERVICE_ADDRESS_INFO = "Location name and address defaults to the customer's billing address"
  static EXT_PRICE = 'EXT PRICE'

  static BULK_MOVE = 'Bulk move'
  static MOVE = 'Move'
  static BULK_MOVE_CONFIRMATION = 'Bulk move confirmation'
  static BULK_MOVE_EXCEPTIONS = 'Bulk move exceptions'

  static INVOICE_STATUS = 'Invoice status'
  static EDIT_INVOICE_DETAILS = 'Edit invoice details'
  static PAYMENT_NOTE = 'Payment note'
  static EDIT_SERVICE_DETAILS = 'Edit service details'
  static WORK_ORDERS_REVIEW = 'Work orders review'

  static TITLE = 'Title'
  static ACTIVE_CONTACT = 'Active contact'
  static CONTACT_DEACTIVATE_SUCCESS = 'Contact deactivated successfully'
  static CONTACT_ACTIVATE_SUCCESS = 'Contact activated successfully'
  static CONTACT_ADDED_SUCCESS = 'Contact added successfully'
  static CONTACT_UPDATED_SUCCESS = 'Contact updated successfully'
  static SEARCH_ADDRESS_PLACEHOLDER = 'Start typing an address to search'
  static TEMPORARY_MODE = 'Temporary mode'

  static NO_CONTACTS = 'Click + button above to add a contact'
  static NO_LOCATION_CONTACTS = 'No Location contacts'
  static INVALID_LAT_LONG = 'The address entered does not have valid latitude / longitude'

  static PAYMENT_DETAILS = 'Payment details'
  static ADD_NEW_METHOD = 'Click + button above to add a new payment method'
  static DELETE_PAYMENT_METHOD_ERROR = "Default payment method can't be deleted"
  static PAYMENT_METHOD_ALREADY_EXISTS = 'Payment method already exists'
  static PAYMENT_METHOD_ADD_SUCCESS_MESSAGE = 'Payment method added successfully'
  static PAYMENT_METHOD_DELETE_SUCCESS_MESSAGE = 'Payment method deleted successfully'

  static FEE_TOTAL = 'Fee total'
  static ACTIVITY = 'Activity'
  static REVERT = 'Revert'
  static PAYMENT_REVERT_MSG = 'Payment is reverting'
  static PAYMENT_ALREADY_REVERT_MSG = 'This payment has already been reverted'
  static PAYMENT_TRANSFER_MSG = 'Payment is transferring'
  static TRANSFER_TO_INVOICE = 'Transfer to invoice'
  static TRANSFER_TO_ACCOUNT = 'Transfer to account'
  static TRANSFERRING_FROM = 'Transferring from'
  static TRANSFERRING_TO = 'Transferring to'
  static PREVIOUS_INVOICE = 'Previous invoice'
  static UPDATED_INVOICE = 'Updated invoice'
  static PAYMENT_ALREADY_REVERT_TRANSFER_MSG = 'This payment has already been reverted or transferred'
  static PAYMENT_ALREADY_TRANSFER_MSG = 'This payment has already been transferred'
  static APPLY_TO_INVOICE = 'Apply to invoice'
  static ACH_PAYMENT_FAILED = 'ACH payment failed'
  static ACH_FAILED = 'ACH Failed'
  static ALREADY_APPLY_TO_INVOICE = 'This account credit payment was used for another invoice'
  static APPLYING_FROM = 'Applying from'
  static APPLYING_TO = 'Applying to'
  static PREVIOUS_STATE = 'Previous state'
  static UPDATED_STATE = 'Updated state'
  static PAYMENT_ACH_FAILED_MSG = 'Payment is failing'
  static PAYMENT_ALREADY_ACH_FAILED_MSG = 'This payment has already been marked as ACH failed'
  static DELETE_CONTACT = 'Delete contact'
  static DELETE_CONTACT_FAILURE = 'Delete failure'
  static DELETE_CONTACT_CALL_FOLLOWUP_FAILURE =
    "Contact is associated to a call follow up. Please chat with support through blue chat bubble and we'll resolve this for you."
  static DELETE_CONTACT_ASSOCIATION_FAILURE =
    'Contact is associated to a service location. Please remove the association by editing the contact and try again.'
  static NO_INVOICE_ACTIVITY_FOUND = 'No invoice activity available'
  static NO_PAYMENT_ACTIVITY_FOUND = 'No payment activity available'
  static NO_EMAIL_ACTIVITY_FOUND = 'No email activity available'
  static INVOICE_CREATION = 'Invoice creation'
  static ROUTE_RECOVERY = 'Route recovery'
  static CANT_DEACTIVATE_LOCATION = 'Cannot deactivate, this location has active services'
  static REACTIVATE_ACCOUNT = 'Please reactivate account'
  static LOCATION_ACTIVATED_MSG = 'Location activated successfully'
  static MULTIPLE_LOCATION_ACTIVATED_MSG = 'Location(s) activated successfully'
  static LOCATION_DEACTIVATED_MSG = 'Location deactivated successfully'
  static RESEND_INVITE = 'Resend invite'
  static COPY_UNIQUE_INVITE_URL = 'Copy unique invite URL'
  static INVITE_SENT_SUCCESS_MESSAGE = 'Invite sent successfully'
  static EMAIL_RESEND_MSG = 'Confirmation email resent'
  static DELETE_USER = 'Delete user'
  static DELETE_CUSTOMER_PORTAL_CONFIRMATION_DESCRIPTION = 'Are you sure you want to delete this customer portal user?'
  static BILLING_LOCATION_DETAILS = 'Billing location details'
  static ADDRESS_NAME = 'Address name'
  static BILLING_LOCATION_NAME = 'Billing location name'
  static CONTACT_EMAILS = 'Contact emails'
  static EDIT_BILLING_LOCATION = 'Edit billing location'
  static REQUIRED_HELPER_TEXT = 'Required'
  static ALL_LOCATIONS = 'All locations'
  static CHANGE_ACCOUNT_REP = 'Change account rep'
  static CUSTOMER_PURCHASE_ORDER = 'Customer purchase order'
  static ACCOUNT_REP_UPDATED_SUCCESSFULLY = 'Account rep updated successfully'
  static ACCOUNT_DEACTIVATED_SUCCESSFULLY = 'Account deactivated successfully'
  static ACCOUNT_REACTIVATED_SUCCESSFULLY = 'Account reactivated successfully'
  static ACCOUNT_SUSPENDED_SUCCESSFULLY = 'Account suspended successfully'
  static ACCOUNTS_SUSPENDED_SUCCESSFULLY = 'Accounts suspended successfully'
  static ACCOUNTS_UNSUSPENDED_SUCCESSFULLY = 'Accounts unsuspended successfully'
  static ACCOUNT_UNSUSPENDED_SUCCESSFULLY = 'Account unsuspended successfully'
  static SEARCH_CUSTOMER = 'Search customer'
  static GO_TO_ACCOUNT = 'Go to account'
  static CREATE_NEW = 'Create new'
  static SEARCH_EXISTING_ACCOUNT_BY_PHONE = 'Search for existing account by phone number'
  static CANT_FIND_CUSTOMER_BY_PHONE = 'Cannot find customer with that phone number. Would you like to create a new customer?'
  static USE_AS_BILLING_ADDRESS = 'Use as billing address'
  static CONTACT_FIRST_NAME = 'Contact first name'
  static CONTACT_LAST_NAME = 'Contact last name'
  static CONTACT_PHONE_NUMBER = 'Contact phone number'
  static DRAG_PIN_MESSAGE = 'Drag the pin to reposition as needed'
  static REQUIRED = 'Required'
  static STREET_LINE_2 = 'Street line 2'
  static CREATE_DRAFT_INVOICES = 'Create draft invoices'
  static NO_INVOICE_GROUPS = 'Click + button above to add an invoice group'
  static GROUPS = 'Groups'
  static GROUPS_DASHBOARD = 'Groups dashboard'
  static INVOICE_GROUPS = 'Invoice groups'
  static PAYMENT_GROUPS = 'Payment groups'
  static ARCHIVE_GROUPS = 'Archived groups'
  static CHANGE_BACK_TO_DRAFT = 'Revert back to draft'
  static CANT_REMOVE_PAST_SERVICED_INVOICED_EVENTS = 'Cannot remove event that was serviced or invoiced'
  static CONTAINER_ID = 'Container ID'
  static CANNOT_BE_EMPTY = 'Cannot be empty'
  static CREATE_NEW_GROUP = 'Create new group'
  static ADD_TO_EXISTING = 'Add to existing'
  static CREATE_INVOICE_GROUP = 'Create invoice group'
  static EDIT_GROUP_NAME = 'Edit group name'
  static INVOICE_GROUP_NAME = 'Invoice group name'
  static NEW_INVOICE_GROUP = 'New invoice group'
  static EDIT_INVOICE_GROUP = 'Edit invoice group'
  static INVOICE_GROUP_CREATED_SUCCESS_MSG = 'Invoice group created successfully'
  static INVOICE_GROUP_UPDATED_SUCCESS_MSG = 'Invoice group updated successfully'
  static BILLING_CONTACT_EMALIL_WARNING_MSG = 'At least one email needs to be set as billing contact'
  static ADD_TO_GROUP = 'Add to group'
  static ADD_TO_INVOICE_GROUP = 'Add to invoice group'
  static SELECT_GROUP = 'Select a group'
  static DOWNLOAD_ALL_INVOICES = 'Download all invoices'
  static EMPTY_INVOICE_GROUP = 'This invoice group is empty'
  static EMAIL_INVOICES = 'E-mail invoices'
  static EMAIL_UNPAID_INVOICES = 'E-mail unpaid invoices'
  static REGENERATE_DRAFT_INVOICES = 'Regenerate draft invoices'
  static MARK_DRAFT_AS_SENT = 'Mark draft as sent'
  static GENERATE_BULK_PRINT_DATA = 'Generate bulk print data'
  static GENERATING_BULK_PRINT_DATA = 'Generating bulk print data. This will take a few minutes.'
  static DOWNLOAD_BULK_PRINT_DATA = 'Download bulk print data'
  static NO_DATA_AVAILABLE = 'No data available'
  static NO__BULK_PRINT_DATA_AVAILABLE = 'No data available. Please click generate bulk print data to proceed.'
  static NO_DRAFT_INVOICE_IN_INVOICE_GROUP = 'This invoice group has no draft invoices'
  static NO_UNPAID_INVOICE_IN_INVOICE_GROUP = 'This invoice group has no unpaid invoices'
  static EXTERNAL_PAYMENT_NUMBER = 'External payment number'
  static DELETE_UNPAID_INVOICE = 'Delete unpaid invoice'
  static DELETE_INVOICE_SUCCESS_MSG = ' Selected invoice successfully deleted'
  static DOWNLOAD_STATEMENT = 'Download statement'
  static VIEW_STATEMENT = 'View statement'
  static ADD_INVOICES = 'Add invoice(s)'
  static COLLECTED_REVENUE = 'Collected revenue'
  static UNCOLLECTED_REVENUE = 'Uncollected revenue'
  static NUMBER_OF_ACCOUNTS = 'Number of accounts'
  static ADD_INVOICE = 'Add invoice'
  static AGING_BUCKET = 'Aging bucket'
  static NO_EMAIL_EVENTS = 'No e-mail events yet'
  static NO_INVOICE_DATA = 'No invoice data yet'
  static VIEW_INVOICE_GROUP_DETAIL = 'View invoice group detail'
  static REMOVE_FROM_GROUP = 'Remove from group'
  static REMOVE_INVOICE_FROM_GROUP_SUCCESS_MSG = 'Selected invoice successfully removed from group'
  static REMOVE_INVOICES_FROM_GROUP_SUCCESS_MSG = 'Selected invoices successfully removed from group'
  static REMOVE_INVOICE = 'Remove invoice'
  static REMOVE_INVOICES = 'Remove invoice(s)'
  static ADD_INVOICE_TO_GROUP_SUCCESS_MSG = 'Selected invoice successfully added to the group'
  static ADD_INVOICES_TO_GROUP_SUCCESS_MSG = 'Selected invoices successfully added to the group'
  static SEQUENCE_POSITION = 'Sequence position'
  static ASSIGN = ' Assign'
  static ALL_ROUTES = 'All routes'
  static NO_PAYMENT_GROUPS = 'Click + button above to add a payment group'
  static PAYMENT_GROUP_NAME = 'Payment group name'
  static NEW_PAYMENT_GROUP = 'New payment group'
  static EDIT_PAYMENT_GROUP = 'Edit payment group'
  static PAYMENT_GROUP_CREATED_SUCCESS_MSG = 'Payment group created successfully'
  static PAYMENT_GROUP_UPDATED_SUCCESS_MSG = 'Payment group updated successfully'
  static VIEW_PAYMENT_GROUP_DETAIL = 'View payment group detail'
  static EMPTY_PAYMENT_GROUP = 'This payment group is empty'
  static NO_PAYMENT_DATA = 'No payment data yet'
  static TRANSACTION_STATUS = 'Transaction status'
  static RECEIPT_EMAIL = 'Receipt e-mail'
  static EDIT_FILTERS = 'Edit filters'
  static FILTERS = 'Filters'
  static ADD_TO_PAYMENT_GROUP = 'Add to payment group'
  static ADD_PAYMENTS_TO_GROUP_SUCCESS_MSG = 'Selected payments successfully added to the group'
  static REMOVE_PAYMENTS_FROM_GROUP_SUCCESS_MSG = 'Selected payments successfully removed from group'
  static REMOVE_PAYMENT_FROM_GROUP_SUCCESS_MSG = 'Selected payment successfully removed from group'
  static REMOVE_PAYMENT = 'Remove payment'
  static FAILED_PAYMENTS = 'Failed payments'
  static ADD_PAYMENT = 'Add payment'
  static ALL_ACTIONS = 'All actions'
  static SEARCH_EMAIL = 'Search email'
  static BILLING_EMAIL = 'Billing email'
  static ROUTING = 'Routing'
  static MARK_AS_REVERTED = 'Mark as reversed'
  static DOWNLOAD_ALL_RECEIPTS = 'Download all receipts'
  static REGENERATE_RECEIPT = 'Regenerate receipt'
  static REGENERATE_ALL_RECEIPTS = 'Regenerate all receipts'
  static RECEIPT_IS_REGENERATING = 'Receipt is regenerating'
  static RECEIPTS_ARE_REGENERATING = 'Receipts are regenerating'
  static EMAIL_ALL_PAYMENT_RECEIPTS = 'Email all payment receipts'
  static PAYMENT_RECEIPT_CONFIRMATION = 'Payment receipt confirmation'
  static MOVE_TO_ACCOUNT_CREDIT = 'Move to account credit'
  static MOVE_PAYMENT_TO_ACCOUNT_CREDIT = 'Move payment to account credit'
  static MOVE_TO_ACCOUNT_CREDIT_SUCCESS = 'Payment moved to account credit successfully'
  static ASSIGN_STOP = 'Assign stop'
  static UNASSIGN_STOP = 'Unassign stop'
  static UNASSIGN_STOP_CONFIRM_MSG = 'Are you sure you want to unassign this stop?'
  static UNASSIGN_STOP_SUCCESS_MSG = 'Stop unassigned successfully'
  static CHANGE_WORK_ORDER_STATUS = 'Change work order status'
  static DISABLE_CHANGE_ROUTE_SEQUENCE_MSG = 'Unable to change route & sequence for Serviced and Skipped stops'
  static DISABLE_UNASSIGN_STOP_MSG = 'Unable to unassign Serviced and Skipped stops'
  static EMAIL_ACTIVITY = 'E-mail activity'
  static DISCONNECT_USER = 'Disconnect user'
  static ADD_CUSTOMER_PORTAL_USER = 'Add customer portal user'
  static INVITE_CUSTOMER_PORTAL_USER = 'Invite customer portal user'
  static NEW_USERNAME = 'New username'
  static NO_INFO_ON_FILE = 'No email on file'
  static MUST_BE_A_VALID_EMAIL_ADDRESS = 'Must be a valid email address'
  static EMAILS_DID_NOT_MATCH = 'Emails didn’t match'
  static EMAIL_ALREADY_REGISTERED = 'This email has already been registered'
  static ADD_TO_CONTACTS = 'Add to contacts'
  static SEND_RESET_PASSWORD_EMAIL = 'Customer will receive password reset email. Would you like to continue?'
  static GENERATE_PASSWORD = 'Generate password'
  static PASSWORD_RESET = 'Password reset'
  static GENERATE_PASSWORD_CONFIRMATION =
    'This action will disable the old password and generate a new one time use password. Do you want to continue?'
  static ONE_TIME_USE_PASSWORD = 'One time use password'
  static CHANGE_ROUTE_AND_SEQUENCE = 'Change route & sequence'
  static REVERT_TO_UNPAID = 'Revert to unpaid'
  static REVERT_INVOICE_TO_UNPAID_SUCCESS = 'Invoice reverted to unpaid state'
  static DRIVER_PATH = 'Driver path'
  static SEQUENCE_LINE = 'Sequence line'
  static VIEW_ROUTE = 'View route'
  static BULK = 'Bulk'
  static MOVE_BETWEEN_ROUTES = 'Move between routes'
  static DISMISS = 'Dismiss'
  static UNSAVED_CHANGES = 'Unsaved changes'
  static RESET_SEQUENCE = 'Reset sequence'
  static PRESERVE_SEQUENCE = 'Preserve sequence'
  static SAVE_CURRENT_DAY = 'Save current day'
  static SAVE_PERMANENT = 'Save permanent'
  static MOVE_BETWEEN_DAYS = 'Move between days'
  static FROM_DATE = 'From date'
  static TO_DATE = 'To date'
  static SAVE_TARGET_DAY = 'Save target day'
  static DESTINATION_DATE = 'Destination date'
  static PERMANENT_MOVE_CONFIRMATION = 'Permanent move confirmation'
  static EXPAND_ALL = 'Expand all'
  static COLLAPSE_ALL = 'Collapse all'
  static ASSIGN_STOPS = 'Assign stops'
  static UNASSIGN_STOPS = 'Unassign stops'
  static CHANGE_ROUTE_SEQUENCE = 'Change route sequence'
  static ADD_PURCHASE_ORDER = 'Add purchase order'
  static EDIT_PURCHASE_ORDER = 'Edit purchase order'
  static REMOVE_PURCHASE_ORDER = 'Remove purchase order'
  static PURCHASE_ORDER_ADDED_SUCCESS_MSG = 'Purchase order added successfully'
  static PURCHASE_ORDER_UPDATED_SUCCESS_MSG = 'Purchase order updated successfully'
  static REMOVE_PURCHASE_ORDER_CONFIRM_MSG = 'Are you sure you want to remove this purchase order?'
  static REMOVE_PURCHASE_ORDER_SUCCESS_MSG = 'Purchase order removed successfully'
  static ACCOUNT_STATUS = 'Account status'
  static TAXABLE = 'Taxable'
  static PERMANENT_CHANGE_RECURRENCE_DAY = 'Permanent change recurring day'
  static NO_NOTES_V2 = 'Click + button above to add a note'
  static SHOW_ON_MOBILE = 'Show on mobile'
  static HIDE_ON_MOBILE = 'Hide on mobile'
  static NEW_NOTE = 'New note'
  static CREATE_NOTE = 'Create note'
  static NO_WORK_ORDER_NOTES = 'No work order notes have been created yet'
  static ADD_TO_NEW_WORK_ORDERS = 'Add to future work orders'
  static UPDATE_ASSOCIATED_WORK_ORDERS = 'Also update associated work orders'
  static RELATED_WORK_ORDERS = 'Related work orders'
  static SHOW_ASSOCIATED_WO_ON_MOBILE = 'Show associated work orders on mobile'
  static NEW_WO_GENERATE_EVERY_SATURDAY = 'New work orders generate every Saturday'
  static WORK_ORDER_NOTE = 'Work order note'
  static NEW_WORK_ORDER_NOTE = 'New work order note'
  static ARCHIVE_NOTE = 'Archive note'
  static UNARCHIVE = 'Unarchive'
  static UNARCHIVE_NOTE = 'Unarchive note'
  static ARCHIVE_NOTE_CONFIRMATION_DESCRIPTION = 'Are you sure you want to archive this note?'
  static UNARCHIVE_NOTE_CONFIRMATION_DESCRIPTION = 'Are you sure you want to unarchive this note?'
  static DELETE_NOTE = 'Delete note'
  static DELETE_NOTE_CONFIRMATION_DESCRIPTION = 'Are you sure you want to delete this note?'
  static VIEW_ARCHIVED = 'View archived'
  static ARCHIVED_WORK_ORDER_NOTES = 'Archived work order notes'
  static LOCATION_NOTE = 'Location note'
  static NEW_LOCATION_NOTE = 'New location note'
  static NO_LOCATION_NOTES = 'No location notes have been created yet'
  static ARCHIVED_LOCATION_NOTES = 'Archived location notes'
  static BILLING_NOTE = 'Billing note'
  static NEW_BILLING_NOTE = 'New billing note'
  static NO_BILLING_NOTES = 'No billing notes have been created yet'
  static ARCHIVED_BILLING_NOTES = 'Archived billing notes'
  static ACCOUNT_NOTE = 'Account note'
  static NEW_ACCOUNT_NOTE = 'New account note'
  static NO_ACCOUNT_NOTES = 'No account notes have been created yet'
  static ARCHIVED_ACCOUNT_NOTES = 'Archived account notes'
  static YES = 'Yes'
  static NO = 'No'
  static SERVICE_NOTE = 'Service note'
  static NEW_SERVICE_NOTE = 'New service note'
  static EDIT_SERVICE_NOTE = 'Edit service note'
  static CONFIGURED_SERVICE_NOTE = 'Configured service note'
  static NEW_CONFIGURED_SERVICE_NOTE = 'New configured service note'
  static ARCHIVED_CONFIGURED_SERVICE_NOTES = 'Archived configured service notes'
  static NO_CS_NOTES = 'No configured service notes have been created yet'
  static MOBILE_VISIBILITY = 'Mobile visibility'
  static SHOW_FOR_ALL_WORK_ORDERS = 'Show for all work orders'
  static HIDE_FOR_ALL_WORK_ORDERS = 'Hide for all work orders'
  static ARCHIVE_SERVICE_ONLY = 'Archive service only'
  static ARCHIVE_WORK_ORDERS_ONLY = 'Archive work orders only'
  static DELETE_SERVICE_ONLY = 'Delete service only'
  static DELETE_WORK_ORDERS_ONLY = 'Delete work orders only'
  static UNARCHIVE_SERVICE_ONLY = 'Unarchive service only'
  static UNARCHIVE_WORK_ORDERS_ONLY = 'Unarchive Work orders only'
  static UNARCHIVE_BOTH = 'Unarchive both'
  static PIN = 'Pin'
  static UNPIN = 'Unpin'
  static NOTE_HISTORY = 'Note history'
  static NO_ARCHIVED_NOTE_AVAILABLE = 'No archived note available'
  static NO_NOTE_HISTORY_AVAILABLE = 'No history available'
  static CUSTOMER_BILLING_PROFILE_DELETED_SUCCESS = 'Customer billing profile deleted successfully'
  static ACCOUNT_GROUPS = 'Account groups'
  static NO_ACCOUNT_GROUPS = 'Click + button above to add an account group'
  static NEW_ACCOUNT_GROUP = 'New account group'
  static EDIT_ACCOUNT_GROUP = 'Edit account group'
  static ACCOUNT_GROUP_NAME = 'Account group name'
  static ACCOUNT_GROUP_CREATED_SUCCESS_MSG = 'Account group created successfully'
  static ACCOUNT_GROUP_UPDATED_SUCCESS_MSG = 'Account group updated successfully'
  static VIEW_ACCOUNT_GROUP_DETAIL = 'View account group detail'
  static SUSPENDED = 'Suspended'
  static ACCOUNT_SUSPENDED_MSG = 'This account has been suspended'
  static REACTIVATE_MULTIPLE_LOCATION = 'Reactivate location(s)'
  static REACTIVATE_LOCATIONS = 'Reactivate locations'
  static EMPTY_ACCOUNT_GROUP = 'This account group is empty'
  static SUSPENSION = 'Suspension'
  static SUSPENSIONS = 'Suspensions'
  static SUSPENSION_GROUPS = 'Suspension groups'
  static EDIT_SUSPENSION_GROUP = 'Edit suspension group'
  static NEW_SUSPENSION_GROUP = 'New suspension group'
  static SUSPENSION_GROUP_NAME = 'Suspension group name'
  static ADD_BILLING_NOTES = 'Add billing notes'
  static ADD_ACCOUNT_NOTES = 'Add account notes'
  static CHANGE_BILLING_PROFILE = 'Change billing profile'
  static ADD_ACCOUNT_TAGS = 'Add account tags'
  static SUSPEND_ACCOUNT = 'Suspend account'
  static SUSPEND_ACCOUNTS = 'Suspend accounts'
  static UNSUSPEND_ACCOUNT = 'Unsuspend account'
  static UNSUSPEND_ACCOUNTS = 'Unsuspend accounts'
  static SEND_PORTAL_INVITE = 'Send portal invite'
  static NO_LOCATIONS_FOUND = 'No locations found'
  static RECENTLY_VIEWED = 'Recently viewed'
  static RECENTLY_ADDED = 'Recently added'
  static LIST_VIEWS = 'List views'
  static ADD_TO_ACCOUNT_GROUP = 'Add to account group'
  static REMOVE_FROM_ACCOUNT_GROUP = 'Remove from account group'
  static ADD_ACCOUNT_TO_GROUP_SUCCESS_MSG = 'Selected account successfully added to the group'
  static ADD_ACCOUNTS_TO_GROUP_SUCCESS_MSG = 'Selected accounts successfully added to the group'
  static LINK_ACCOUNT_TO_LATE_FEE_SUCCESS_MSG = 'Selected account successfully linked to late fee'
  static LINK_ACCOUNTS_TO_LATE_FEE_SUCCESS_MSG = 'Selected accounts successfully linked to late fee'
  static REMOVE_ACCOUNT_FROM_GROUP_SUCCESS_MSG = 'Selected account successfully removed from group'
  static REMOVE_ACCOUNTS_FROM_GROUP_SUCCESS_MSG = 'Selected accounts successfully removed from group'
  static NO_ACTIVE_VACATION_HOLDS = 'There are no active vacation holds on this account'
  static ADD_ACCOUNTS_TO_GROUP = 'Add accounts to group'
  static REMOVE_ACCOUNTS_FROM_GROUP = 'Remove accounts from group'
  static ADD_ACCOUNTS = 'Add account(s)'
  static REMOVE_ACCOUNTS = 'Remove account(s)'
  static SUSPEND = 'Suspend'
  static UNSUSPEND = 'Unsuspend'
  static SUSPENSION_HISTORY = 'Suspension history'
  static NO_SUSPENSION_HISTORY = 'This account has no suspension history.'
  static AREA_CODE = 'Area code'
  static BULK_ADD_TAGS_TO_ACCOUNTS_SUCCESS_MSG = 'Selected tags successfully added to the accounts'
  static COPY_UNIQUE_PORTAL_URL = 'Copy unique portal URL'
  static LOADING_GROUP_DATA = 'Fetching group details...'
  static LOADING = 'Loading...'
  static SELECT_A_CUSTOMER = 'Please select a customer to proceed'
  static SELECT_A_PAYMENT_METHOD = 'Please select a payment method to proceed'
  static RESEND_UNPAID_INVOICES = 'Re-send unpaid invoices'
  static BILLING_SUSPENSION = 'Billing suspension'
  static BILLING_SUSPENSION_DESCRIPTION = 'If turned on, account will be prorated to the date the account was suspended'
  static ON = 'On'
  static OFF = 'Off'
  static PRORATE = 'Prorate'
  static BILL_THEM = 'Bill them'
  static TURN_ON_SUSPENSION_PRORATION = 'Turn on suspension proration'
  static TURN_OFF_SUSPENSION_PRORATION = 'Turn off suspension proration'
  static GROUP_PRORATION_UPDATED = 'Proration updated for selected group'
  static INDIVIDUAL_PRORATION_UPDATED_SUCCESS = 'Suspension proration updated successfully'
  static LATE = 'Late'
  static CREATE_LATE_FEE_DIALOG = 'Create late fee'
  static DOLLAR_AMOUNT = 'Dollar amount'
  static ONCE = 'Once'
  static DAILY = 'Daily'
  static WEEKLY = 'Weekly'
  static MONTHLY = 'Monthly'
  static QUARTERLY = 'Quarterly'
  static ANNUALLY = 'Annually'
  static INDEFINITE = 'Indefinite'
  static WEEKS = 'Weeks'
  static MONTHS = 'Months'
  static QUARTERS = 'Quarters'
  static YEARS = 'Years'
  static DURATION = 'Duration'
  static CUSTOM_DURATION = 'Custom duration'
  static ALL_BUSINESS_LINES = 'All business lines'
  static CUSTOM_START_DATE = 'Custom start date'
  static RENTAL_FEES = 'Rental fees'
  static CREATE_RENTAL_FEE_DIALOG = 'Create rental fee'
  static EDIT_RENTAL_FEE_DIALOG = 'Edit rental fee'
  static CUSTOM_GRACE_PERIOD = 'Custom grace period'
  static THIS_FIELD_IS_REQUIRED = 'This field is required'
  static LATE_FEE_CREATED_SUCCESSFULLY = 'Late fee created successfully'
  static EDIT_LATE_FEE_DIALOG = 'Edit late fee'
  static LATE_FEE_UPDATED_SUCCESSFULLY = 'Update late fee'
  static ARCHIVE_LATE_FEE = 'Archive late fee'
  static ARCHIVE_LATE_FEE_CONFIRM_MSG = 'Are you sure you want to archive this late fee?'
  static LATE_FEE_ARCHIVED_SUCCESSFULLY = 'Late fee archived successfully'
  static LATE_FEE_RESTORED_SUCCESSFULLY = 'Late fee restored successfully'
  static UNLINK_ACCOUNT_TO_LATE_FEE_SUCCESS_MSG = 'Selected account successfully unlinked from late fee'
  static UNLINK_ACCOUNTS_TO_LATE_FEE_SUCCESS_MSG = 'Selected accounts successfully unlinked from late fee'
  static EMPTY_LATE_FEE = 'There are no accounts linked to this late fee'
  static START_FROM = 'Start from'
  static REMOVE_CUSTOMERS_BEFORE_ARCHIVE_LATE_FEE = 'Remove all customers to archive this fee'
  static LATE_INVOICES = 'Late invoices'
  static CONFIRM_REGENERATION_BULK_ACTION_TITLE = 'Regenerating invoices'
  static CONFIRM_REGENERATION_BULK_ACTION_DESCRIPTION =
    'invoices are being regenerated. This will take a few minutes. Come back and click refresh later.'
  static CONFIRM_REGENERATION_INDIVIDUAL_ACTION_TITLE = 'Regenerating invoice'
  static CONFIRM_REGENERATION_INDIVIDUAL_ACTION_DESCRIPTION =
    'The invoice is being regenerated. This may take a minute. Come back and click refresh later.'

  static CONFIRM_MARK_AS_SENT_BULK_ACTION_TITLE = 'Marking as sent'
  static CONFIRM_MARK_AS_SENT_BULK_ACTION_DESCRIPTION = 'invoices are being marked as sent. Please check back later.'
  static CONFIRM_MARK_AS_SENT_INDIVIDUAL_ACTION_TITLE = 'Marking as sent'
  static CONFIRM_MARK_AS_SENT_INDIVIDUAL_ACTION_DESCRIPTION = 'The invoice is being marked as sent.'
  static CONFIRM_SEND_INVOICES_INDIVIDUAL_ACTION_TITLE = 'Sending invoice'
  static CONFIRM_SEND_INVOICES_INDIVIDUAL_ACTION_DESCRIPTION = 'The invoice is being sent. This may take a minute.'
  static CONFIRM_SEND_INVOICES_BULK_ACTION_TITLE = 'Sending invoices'
  static CONFIRM_SEND_INVOICES_BULK_ACTION_DESCRIPTION = 'invoices are being sent. Please check back later.'
  static CONFIRM_SEND_UNPAID_INVOICES_INDIVIDUAL_ACTION_TITLE = 'Sending unpaid invoice'
  static CONFIRM_SEND_UNPAID_INVOICES_INDIVIDUAL_ACTION_DESCRIPTION = 'The unpaid invoice is being sent. This may take a minute.'
  static CONFIRM_SEND_UNPAID_INVOICES_BULK_ACTION_TITLE = 'Sending unpaid invoices'
  static CONFIRM_SEND_UNPAID_INVOICES_BULK_ACTION_DESCRIPTION = 'unpaid invoices are being sent. Please check back later.'
  static ADD_LATE_FEE = 'Add late fee'
  static CLICK_PLUS_BUTTON_ABOVE_TO_ADD_A_RENTAL_FEE = 'Click + button above to add a rental fee'
  static RENTAL_FEE_CREATED_SUCCESSFULLY = 'Rental fee created successfully'
  static RENTAL_FEE_UPDATED_SUCCESSFULLY = 'Rental fee updated successfully'
  static RENTAL_FEE_ARCHIVE_ASSOCIATION_MSG =
    'This rental fee is associated with some accounts, services, invoices, or configured service and cannot be archived'
  static RENTAL_FEE_DELETE_ASSOCIATION_MSG =
    'This rental fee is associated with some accounts, services, invoices, or configured service and cannot be deleted'
  static ARCHIVE_RENTAL_FEE = 'Archive rental fee'
  static ARCHIVE_RENTAL_FEE_CONFIRM_MSG = 'Are you sure you want to archive this rental fee?'
  static RENTAL_FEE_ARCHIVED_SUCCESSFULLY = 'Rental fee archived successfully'
  static RENTAL_FEE_RESTORED_SUCCESSFULLY = 'Rental fee restored successfully'
  static DELETE_RENTAL_FEE = 'Delete rental fee'
  static DELETE_RENTAL_FEE_CONFIRM_MSG = 'Deletion of this rental fee cannot be reversed. Do you want to continue?'
  static RENTAL_FEE_DELETED_SUCCESSFULLY = 'Rental fee deleted successfully'
  static ADD_RENTAL_FEE = 'Add rental fee'
  static REMOVE_RENTAL_FEE = 'Remove rental fee'
  static ADD_SERVICES_TO_RENTAL_FEE = 'Add services to rental fee'
  static REMOVE_SERVICES_FROM_RENTAL_FEE = 'Remove services from rental fee'
  static ACCOUNT_ACTIVATED = 'Account activated'
  static ACCOUNT_ACTIVATED_DESCRIPTION = 'Account was successfully activated. Please reactive locations.'
  static RESET_GRACE_PERIOD = 'Reset grace period'
  static RESET_ALL = 'Reset all'
  static NO_RENTAL_FEES_LINKED_TO_PRICED_SERVICE = 'No rental fees linked to this priced service'
  static NO_RENTAL_FEES = 'No rental fees'
  static BILLING_DETAILS = 'Billing details'
  static CREDIT_LIMIT_HISTORY = 'Credit limit history'
  static RESET_GRACE_PERIOD_SUCCESS_MSG = 'Grace period reset successfully'
  static PENDING = 'Pending'
}
