import React from 'react'
import PropTypes from 'prop-types'

import T from 'T'
import { Chip, Avatar, useTheme } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { PAYMENT_STATUS } from 'settings/constants/payment'
import { PAYMENT_STATUS_COLORS } from './settings'

const { APPROVED, REVERSED, DECLINED, TRANSFERRED, FAILED } = PAYMENT_STATUS

const PaymentStatusChip = ({ status = '' }) => {
  const theme = useTheme()

  const getParams = () => {
    if (status === APPROVED) {
      return { label: 'Complete', color: PAYMENT_STATUS_COLORS[APPROVED] }
    }

    if (status === REVERSED) {
      return { label: T.REVERSED, color: PAYMENT_STATUS_COLORS[REVERSED] }
    }

    if (status === TRANSFERRED) {
      return { label: T.TRANSFER, color: PAYMENT_STATUS_COLORS[TRANSFERRED] }
    }

    if (status === DECLINED) {
      return { label: T.FAILED, color: PAYMENT_STATUS_COLORS[DECLINED] }
    }

    if (status === FAILED) {
      return { label: T.FAILED, color: PAYMENT_STATUS_COLORS[FAILED] }
    }

    return { label: status, color: PAYMENT_STATUS_COLORS[DECLINED] }
  }

  const { label, color } = getParams()

  return (
    <Chip
      size="small"
      avatar={
        <Avatar sx={{ backgroundColor: color[500] }}>
          <AttachMoneyIcon sx={{ fontSize: 16, color: theme.palette.common.white }} />
        </Avatar>
      }
      variant="outlined"
      label={label}
      sx={{ fontWeight: 600, border: '1px solid', borderColor: color[500], backgroundColor: color[50], color: color[700] }}
    />
  )
}

PaymentStatusChip.propTypes = {
  status: PropTypes.string,
}

export default PaymentStatusChip
