import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import UnassignedRouteHeader from 'containers/new-route-manager/UnassignedRouteHeader'
import { styled } from '@mui/material/styles'
import { Card } from '@mui/material'
import { useSelector } from 'react-redux'
import { grey } from '@mui/material/colors'
import BulkAssignForm from '../../components/route-manager/BulkAssignForm/BulkAssignForm'
import UnassignedColumn from './board-view/UnassignedColumn'
import { UNASSIGNED_COLUMN_WIDTH, UNASSIGNED_COLUMN_HEIGHT } from './board-view/settings'
import { UNASSIGNED_ROUTE } from './settings'

const StickyContainer = styled('div', { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
  background: theme.palette.background.default,
  marginTop: theme.spacing(-6),
  paddingTop: theme.spacing(6),
  position: 'sticky',
  left: theme.spacing(2),
  top: 0,
  zIndex: 4,
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    background: theme.palette.background.default,
    left: theme.spacing(-2),
    top: theme.spacing(4),
    width: theme.spacing(2),
    height: UNASSIGNED_COLUMN_HEIGHT,
  },
}))

const StyledCard = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: UNASSIGNED_COLUMN_WIDTH,
  maxWidth: UNASSIGNED_COLUMN_WIDTH,
  height: UNASSIGNED_COLUMN_HEIGHT,
  borderTop: `4px solid ${grey[300]}`,
  borderTopRightRadius: '6px',
  borderTopLeftRadius: '6px',
}))

const UnassignedStopsColumn = ({
  onOpenRouteFilterMenu,
  routeList,
  serviceDate,
  unassignedWorkOrders = [],
  isDropDisabled = false,
  onTagsSave = noop,
  handleBulkMoveStopsFromUnassigned,
  isActionsButtonDisabled = false,
  isDragDisabled = false,
}) => {
  const isOpenBulkAssign = useSelector(s => s.Route.bulkAssign.isOpen)
  return (
    <StickyContainer>
      <StyledCard variant="outlined">
        <UnassignedRouteHeader
          onOpenRouteFilterMenu={onOpenRouteFilterMenu}
          stopsCount={unassignedWorkOrders.length}
          routeName={UNASSIGNED_ROUTE.name}
          isActionsButtonDisabled={isActionsButtonDisabled}
        />
        {isOpenBulkAssign && (
          <BulkAssignForm
            routeList={routeList}
            handleBulkMoveStopsFromUnassigned={handleBulkMoveStopsFromUnassigned}
            serviceDate={serviceDate}
            unassignedWorkOrders={unassignedWorkOrders}
          />
        )}
        <UnassignedColumn
          isDragDisabled={isDragDisabled}
          unassignedWorkOrders={unassignedWorkOrders}
          isDropDisabled={isDropDisabled}
          onTagsSave={onTagsSave}
        />
      </StyledCard>
    </StickyContainer>
  )
}

UnassignedStopsColumn.propTypes = {
  onOpenRouteFilterMenu: PropTypes.func.isRequired,
  unassignedWorkOrders: PropTypes.array.isRequired,
  isDropDisabled: PropTypes.bool,
  onTagsSave: PropTypes.func,
  serviceDate: PropTypes.string,
  handleBulkMoveStopsFromUnassigned: PropTypes.func.isRequired,
  routeList: PropTypes.array.isRequired,
  isActionsButtonDisabled: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
}

export default UnassignedStopsColumn
