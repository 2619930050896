export function getMeasureUnit(mUnit) {
  const measureUnit = mUnit?.toUpperCase() || ''
  switch (measureUnit) {
    case 'YARD':
    case 'YARDS':
      return 'yd'

    case 'GALLON':
    case 'GALLONS':
      return 'gal'

    case 'BARREL':
    case 'BARRELS':
      return 'bbl'

    case 'POUND':
    case 'POUNDS':
      return 'lb'

    case 'TON':
    case 'TONS':
      return 'T'

    case 'ITEM':
    case 'ITEMS':
      return 'Item'

    default:
      return ''
  }
}
