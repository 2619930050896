import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import LateFeeStatItem from 'components/pricing/LateFees/LateFeesListing/LateFeeStatItem'
import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { MoreVert } from '@mui/icons-material'
import LateFeesActionMenu from 'components/pricing/LateFees/LateFeesListing/LateFeesActionMenu'
import { capitalize } from 'lodash'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LateInvoiceItem from 'components/customer-details/content/fees/late_fees/LateInvoiceItem'
import useFormatLateFee from 'components/customer-details/content/fees/late_fees/useFormatLateFee'

const LateFeeAccordionItem = ({
  feeName = 'Late fee',
  duration = 0,
  pricingPeriod = 'Daily',
  startOffset = 0,
  amountCents = 0,
  businessLine = '',
  accountCount = 0,
}) => {
  const theme = useTheme()
  const capitalizedPricingPeriod = useMemo(() => capitalize(pricingPeriod), [pricingPeriod])
  const [anchorEl, setAnchorEl] = useState(null)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const { formattedStartDate, formattedDuration } = useFormatLateFee({
    feeName,
    duration,
    pricingPeriod,
    startOffset,
    amountCents,
    businessLine,
    accountCount,
  })

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)

  return (
    <Accordion
      elevation={0}
      expanded={isExpanded}
      sx={{
        '&.Mui-expanded': {
          m: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
          mb: 0,
          mt: 0,
        },
        '&:before': {
          display: 'none',
        },
      }}
      disablePointer
      expandIcon={undefined}
      iconGap={false}
    >
      <AccordionSummary
        sx={{
          p: 0,
        }}
      >
        <ListItem>
          <ListItemIcon
            sx={{
              minWidth: 0,
            }}
          >
            <IconButton onClick={toggleIsExpanded}>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
          </ListItemIcon>
          <Box height="100%" width="100%">
            <Grid container alignItems="center" justifyContent="center" flexWrap="nowrap">
              <Grid item xs sm md lg zeroMinWidth>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                        {feeName}
                      </Typography>
                    }
                    secondary={
                      <Grid flexWrap={isTabletOrMobile ? 'wrap' : 'nowrap'} justifyContent="flex-start" alignItems="center" container>
                        {isDesktop && (
                          <>
                            <LateFeeStatItem
                              primary={formattedStartDate}
                              secondary={capitalize(T.START_DATE)}
                              divider
                              lg={2}
                              xl={1}
                              ListItemSx={{
                                pl: 0,
                              }}
                              sx={{
                                minWidth: {
                                  lg: '100px',
                                  xl: '150px',
                                },
                              }}
                            />
                            <LateFeeStatItem
                              primary={formattedDuration}
                              secondary={capitalize(T.DURATION)}
                              divider
                              lg={2}
                              xl={1}
                              ListItemSx={{
                                pl: 1,
                              }}
                              sx={{
                                minWidth: {
                                  lg: '100px',
                                  xl: '150px',
                                },
                              }}
                            />
                            <LateFeeStatItem
                              primary={capitalizedPricingPeriod}
                              secondary={capitalize(T.PRICING_PERIOD)}
                              divider={isXs || isDesktop}
                              lg={2}
                              xl={1}
                              ListItemSx={{
                                pl: 1,
                              }}
                              sx={{
                                minWidth: {
                                  lg: '100px',
                                  xl: '150px',
                                },
                              }}
                            />
                            <LateFeeStatItem
                              lg={2}
                              xl={1}
                              sx={{
                                minWidth: {
                                  lg: '100px',
                                  xl: '150px',
                                },
                              }}
                              ListItemSx={{
                                pl: 1,
                              }}
                              primary={accountCount}
                              secondary="accounts"
                              divider
                            />
                            <LateFeeStatItem
                              lg={4}
                              primary={businessLine || 'None'}
                              secondary={capitalize(T.BUSINESS_LINE)}
                              xl={8}
                              zeroMinWidth
                              ListItemSx={{
                                pl: 1,
                              }}
                              sx={{
                                maxWidth: {
                                  lg: 'unset',
                                  xl: 'unset',
                                },
                              }}
                            />
                          </>
                        )}
                        {isTablet && (
                          <>
                            <Grid container item xs={12}>
                              <LateFeeStatItem sm md primary={formattedStartDate} secondary={capitalize(T.START_DATE)} divider />
                              <LateFeeStatItem sm md primary={formattedDuration} secondary={capitalize(T.DURATION)} divider />
                              <LateFeeStatItem sm md primary={capitalizedPricingPeriod} secondary={capitalize(T.PRICING_PERIOD)} />
                            </Grid>
                            <Grid container item xs={12} flexWrap="nowrap">
                              <LateFeeStatItem sm={4} md={4} primary={accountCount} secondary={T.ACCOUNTS} divider />
                              <LateFeeStatItem
                                md
                                primary={businessLine || 'None'}
                                secondary={capitalize(T.BUSINESS_LINE)}
                                sm
                                zeroMinWidth
                                sx={{
                                  maxWidth: {
                                    md: 'unset',
                                  },
                                }}
                              />
                            </Grid>
                          </>
                        )}
                        {isMobile && (
                          <>
                            <Grid container item xs={12}>
                              <LateFeeStatItem xs={12} sm md primary={formattedStartDate} secondary={capitalize(T.START_DATE)} divider />
                              <LateFeeStatItem
                                divider={isXs}
                                md
                                primary={formattedDuration}
                                secondary={capitalize(T.DURATION)}
                                sm
                                xs={12}
                              />
                            </Grid>
                            <Grid container item xs={12}>
                              <LateFeeStatItem
                                divider
                                md
                                primary={capitalizedPricingPeriod}
                                secondary={capitalize(T.PRICING_PERIOD)}
                                sm
                                xs={12}
                              />
                              <LateFeeStatItem xs={12} sm md primary={accountCount} secondary={T.ACCOUNTS} divider={isXs} />
                            </Grid>
                            <LateFeeStatItem
                              primary={businessLine || 'None'}
                              secondary={capitalize(T.BUSINESS_LINE)}
                              xs={12}
                              sx={{
                                maxWidth: {
                                  sm: 'unset',
                                },
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid
                item
                sx={{
                  minWidth: {
                    xs: '12rem',
                    sm: '12rem',
                    md: '12rem',
                    lg: '13rem',
                    xl: '24rem',
                  },
                  pr: 4,
                }}
              >
                <Grid container flexWrap="nowrap" alignItems="center" justifyContent="flex-end" columnGap={2}>
                  <Grid item>
                    <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                      <HHDisplayMoney value={amountCents} formatToDollars />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                  </Grid>
                  <LateFeesActionMenu
                    lateFee={{ feeName, duration, pricingPeriod, startOffset, amountCents, businessLine, accountCount }}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
        }}
      >
        <Divider />
        <LateInvoiceItem />
      </AccordionDetails>
    </Accordion>
  )
}

LateFeeAccordionItem.propTypes = {}

export default LateFeeAccordionItem
