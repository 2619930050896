import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'

import { get } from 'utils/lodash'
import Constants from 'Constants'

const { PUBLIC_ASSETS_BUCKET_NAME, STORAGE_PATH, MEDIA_SERVER } = Constants

const BASE_PATH = `${STORAGE_PATH}${PUBLIC_ASSETS_BUCKET_NAME}/`
const DEFAULT_LOGO_PATH = `${MEDIA_SERVER}HeaderLogo.png`

const Logo = styled('img')`
  height: 32px;
  margin-right: 16px;
`

const BusinessLogo = () => {
  const businessId = useSelector(state => get(state, 'AuthReducer.userInfo.businessInfo.businessId'), shallowEqual)
  const whiteLabeled = useSelector(state => get(state, 'AuthReducer.userInfo.businessInfo.whiteLabeled'), shallowEqual)

  const [logoPath, setLogoPath] = useState('')

  const validateImage = (url, callback) => {
    const img = new Image()
    img.src = url

    if (img.complete) {
      callback(true)
    }

    img.onload = () => {
      callback(true)
    }

    img.onerror = () => {
      callback(false)
    }
  }

  useEffect(() => {
    if (!businessId || !whiteLabeled) {
      setLogoPath(DEFAULT_LOGO_PATH)
      return
    }

    const clientLogo = `${BASE_PATH}${businessId}/business_logo.png`

    // Permission error when using firebase getMetadata function, so using this
    validateImage(clientLogo, exists => setLogoPath(exists ? clientLogo : DEFAULT_LOGO_PATH))
  }, [businessId, whiteLabeled])

  return <Logo src={logoPath} alt="" />
}

export default BusinessLogo
