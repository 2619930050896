import React, { useCallback, useMemo } from 'react'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import ReorderIcon from 'components/common/ReorderIcon'
import { useDispatch, useSelector } from 'react-redux'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'
import { get } from 'lodash'
import T from 'T'
import SearchAutocomplete from 'components/locations/filters/Search/SearchAutocomplete'
import {
  PHONE_SEARCH_TYPE,
  SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL,
  SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER,
} from 'components/locations/filters/Search/settings'
import { getOnlyDigits } from 'utils/string'
import { useFormContext } from 'react-hook-form'
import { useLazyDebouncedSearchLocationOptionsQuery } from 'components/locations/filters/Search/useLazyDebouncedSerchLocationOptionsQuery'
import { useLazyEffect } from 'components/locations/filters/Search/useLazyEffect'
import { createSearchParams, useNavigate } from 'react-router-dom-v5-compat'
import { getCustomerDetailsPageUrl } from 'router/routes'
import SortByFilterMenuField from 'components/locations/filters/SortByFilter/SortByFilterMenuField'
import { PAGE_TYPE } from 'components/locations/settings'
import FiltersMenuButton from 'components/locations/filters/FiltersMenuButton'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import LocationsActionsMenu from 'components/locations/LocationsActionsMenu'
import { LocationsActionsProvider } from 'components/locations/LocationsContext'
import { CUSTOMER_SIDE_NAV_DRAWER_WIDTH } from 'components/customers/common/side-nav/settings'
import { checkIsManualSearch } from 'components/customers/accounts/search/settings'
import useManualSearchListUtilities from 'components/customers/accounts/search/useManualSearchListUtilities'

const LocationsHeader = ({ loadingRows, locationsCount, allLocations = [] }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { watch, setValue } = useFormContext()
  const searchType = watch('searchType')
  const searchInputValue = watch('searchInputValue')
  const search = watch('search')
  const searchValue = watch('searchValue')
  const includeDeactivatedLocations = watch('withDeactivated')

  const isManualSearch = useMemo(() => checkIsManualSearch(searchType), [searchType])
  const { getSearchedResultsManually } = useManualSearchListUtilities({ data: allLocations, watch, type: PAGE_TYPE })

  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'))
  const handleDrawerToggle = () => dispatch(setShowCustomerSideNav(!showCustomerSideNav))
  const [searchLocationOptions, { isLoading, isFetching, data }] = useLazyDebouncedSearchLocationOptionsQuery()
  const locations = useMemo(() => {
    if (isManualSearch) {
      return getSearchedResultsManually()
    }
    return get(data, 'locations', [])
  }, [data, allLocations, watch, searchInputValue, isManualSearch, searchType, includeDeactivatedLocations])

  const label = SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL[searchType]
  const placeholder = SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER[searchType]
  const { control } = useFormContext()
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const handleChange = (event, item, reason) => {
    const accountId = get(item, 'accountId')
    const locationId = get(item, 'locationId')
    if (accountId) {
      const accountDetailsBaseURL = getCustomerDetailsPageUrl(accountId)
      navigate({
        pathname: accountDetailsBaseURL,
        search: createSearchParams({
          ...(locationId && { location: locationId }),
        }).toString(),
      })
    }
  }

  const handleInputChange = useCallback(
    (event, value, reason) => {
      if (value === '' && reason !== 'reset') {
        setValue('searchValue', '')
        setValue('search', '')
      }
    },
    [search, searchValue]
  )

  const handleChangeSearchType = newSearchType => {
    if (searchType === PHONE_SEARCH_TYPE || newSearchType === PHONE_SEARCH_TYPE) {
      setValue('searchInputValue', '')
      setValue('searchValue', '')
    }
  }

  const handleSearch = (search, closeOptionList) => {
    setValue('search', search)
    setValue('searchValue', search)
    closeOptionList()
  }

  const popperSx = useMemo(
    () => ({
      minWidth: {
        lg: showCustomerSideNav ? `calc(100vw - ${CUSTOMER_SIDE_NAV_DRAWER_WIDTH + 64}px)` : `calc(100vw - ${64}px)`,
        md: `calc(100vw - 64px)`,
        xs: `calc(100vw - 32px)`,
      },
    }),
    [showCustomerSideNav]
  )

  const paperSx = useMemo(
    () => ({
      mr: {
        xs: 1,
        lg: 5,
      },
      ml: showCustomerSideNav ? 1 : 0,
    }),
    [showCustomerSideNav]
  )

  useLazyEffect(
    () => {
      if (searchInputValue && !isManualSearch) {
        const formattedValue = searchType === PHONE_SEARCH_TYPE ? getOnlyDigits(searchInputValue) : searchInputValue
        searchLocationOptions({ searchInputValue: formattedValue, includeDeactivatedLocations })
      }
    },
    [searchInputValue, searchType, includeDeactivatedLocations, isManualSearch],
    300
  )

  const loading = useMemo(
    () => (isManualSearch ? loadingRows : isLoading || isFetching),
    [isManualSearch, isLoading, isFetching, loadingRows]
  )

  return (
    <>
      <Box px={3} py={2} bgcolor="background.default" minHeight={56}>
        <Grid container alignItems="center" rowSpacing={1}>
          <Grid item container xs md="auto" alignItems="center" flexWrap="nowrap" columnSpacing={1} sx={{ mr: 5 }}>
            {!showCustomerSideNav && (
              <Grid item>
                <ReorderIcon onClick={handleDrawerToggle} />
              </Grid>
            )}
            <Grid item container xs="auto" alignItems="center" zeroMinWidth columnSpacing={0.5}>
              <Grid item zeroMinWidth>
                <Typography variant="h4" fontWeight="600">
                  {T.SERVICE_VIEW}
                </Typography>
              </Grid>
              <Grid item zeroMinWidth>
                {!loadingRows && (
                  <Typography variant="h4" fontWeight="600">
                    <NumericFormat value={locationsCount} prefix="(" suffix=")" displayType="text" thousandSeparator />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md sx={{ order: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 }, mr: isTabletOrMobile ? 0 : 1 }}>
            <SearchAutocomplete
              popperSx={popperSx}
              paperSx={paperSx}
              loading={loading}
              options={locations}
              onChange={handleChange}
              onChangeSearchType={handleChangeSearchType}
              label={label}
              onInputChange={handleInputChange}
              placeholder={placeholder}
              onSelectFreeEntry={handleSearch}
              onEnterPress={handleSearch}
              selectOnFocus
              handleHomeEndKeys
            />
          </Grid>
          <Grid item container xs="auto" columnSpacing={1} alignItems="center">
            <Grid item sx={{ ...(isTabletOrMobile && { display: 'none' }) }}>
              <FiltersMenuButton />
            </Grid>
            <Grid item sx={{ ...(isTabletOrMobile && { display: 'none' }) }}>
              <SortByFilterMenuField name="sortBy" control={control} />
            </Grid>
            <Grid item>
              <LocationsActionsProvider>
                <LocationsActionsMenu />
              </LocationsActionsProvider>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

LocationsHeader.propTypes = {
  locationsCount: PropTypes.number.isRequired,
  loadingRows: PropTypes.bool,
  allLocations: PropTypes.array,
}

export default LocationsHeader
