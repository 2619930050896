import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Menu, MenuItem, Tooltip } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { downloadPaymentReceiptBlobListInChunks } from 'utils/file'
import { getPaymentsFromBatch } from 'data/groups/paymentGroupsSelectors'
import { useRegeneratePaymentReceiptsMutation } from 'api/billing/regeneratePaymentReceipts'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'

import T from 'T'
import AddEditPaymentGroupDialog from './AddEditPaymentGroupDialog'
import RemovePaymentBatchesFromGroupDialog from './remove/RemovePaymentBatchesFromGroupDialog'
import PaymentReceiptEmailConfirmation from '../../common/payment-receipt-email-confirmation'

const PaymentGroupActionMenu = ({ anchorEl, selectedGroup = {}, onClose }) => {
  const dispatch = useDispatch()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const [isOpenAddEditPaymentGroupDialog, setIsOpenAddEditPaymentGroupDialog] = useState(false)
  const [isOpenRemoveBatchesFromGroupDialog, setIsOpenRemoveBatchesFromGroupDialog] = useState(false)
  const [isOpenReceiptConfirmationDialog, setisOpenReceiptConfirmationDialog] = useState(false)
  const [regeneratePaymentReceipts] = useRegeneratePaymentReceiptsMutation()

  const groupId = get(selectedGroup, 'id')
  const paymentBatches = get(selectedGroup, 'paymentBatches', [])
  const isEmptyGroup = paymentBatches.length === 0
  const payments = getPaymentsFromBatch({ paymentBatches })

  const handleEmailAllPaymentReceipts = () => setisOpenReceiptConfirmationDialog(true)

  const handleDownloadPaymentReceipt = async () => {
    const allReceipts = payments
      .filter(row => row?.paymentNumber)
      .map(({ paymentNumber, id, accountId }) => ({ paymentNumber, accountTransactionHistoryId: id, accountId }))
    if (allReceipts.length > 0) {
      downloadPaymentReceiptBlobListInChunks(businessId, allReceipts, allReceipts.length === 1)
    }
    onClose()
  }

  const handleRegeneratePaymentReceipt = async () => {
    const accountTransactionHistoryIds = payments.filter(row => row?.paymentNumber).map(({ id }) => id)
    if (accountTransactionHistoryIds.length === 0) {
      onClose()
      return
    }

    dispatch(putIsLoading(true))
    regeneratePaymentReceipts({ accountTransactionHistoryIds })
      .unwrap()
      .then(() =>
        toast.success(accountTransactionHistoryIds.length === 1 ? T.RECEIPT_IS_REGENERATING : T.RECEIPTS_ARE_REGENERATING, {
          hideProgressBar: false,
          autoClose: 4000,
        })
      )
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={() => setIsOpenAddEditPaymentGroupDialog(true)}>{T.EDIT_GROUP_NAME}</MenuItem>
        <Tooltip title={T.EMPTY_PAYMENT_GROUP} disableHoverListener={!isEmptyGroup}>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={() => setIsOpenRemoveBatchesFromGroupDialog(true)}>
              {`${T.REMOVE_PAYMENT}(s)`}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_PAYMENT_GROUP} disableHoverListener={!isEmptyGroup}>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleDownloadPaymentReceipt}>
              {T.DOWNLOAD_ALL_RECEIPTS}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_PAYMENT_GROUP} disableHoverListener={!isEmptyGroup}>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleRegeneratePaymentReceipt}>
              {T.REGENERATE_ALL_RECEIPTS}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_PAYMENT_GROUP} disableHoverListener={!isEmptyGroup}>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleEmailAllPaymentReceipts}>
              {T.EMAIL_ALL_PAYMENT_RECEIPTS}
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
      <AddEditPaymentGroupDialog
        isOpen={isOpenAddEditPaymentGroupDialog}
        existingGroup={selectedGroup}
        onClose={() => {
          setIsOpenAddEditPaymentGroupDialog(false)
          onClose()
        }}
      />
      <RemovePaymentBatchesFromGroupDialog
        isOpen={isOpenRemoveBatchesFromGroupDialog}
        groupId={groupId}
        paymentBatches={paymentBatches}
        onClose={() => {
          setIsOpenRemoveBatchesFromGroupDialog(false)
          onClose()
        }}
      />
      {isOpenReceiptConfirmationDialog && (
        <PaymentReceiptEmailConfirmation
          isOpen={isOpenReceiptConfirmationDialog}
          payments={payments.filter(row => row?.paymentNumber)}
          onClose={() => {
            setisOpenReceiptConfirmationDialog(false)
            onClose()
          }}
        />
      )}
    </>
  )
}

PaymentGroupActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  selectedGroup: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default memo(PaymentGroupActionMenu)
