import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { DialogContent, Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useCreateCSNoteMutation, useUpdateCSNoteMutation, usePatchCSNoteMutation } from 'api/notes/configuredServiceNotesCrud'
import { isEnterOrNumpadEnterWithCtrlOrMeta } from 'utils/keyboard'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { HHFormTextField, HHFormSwitchField } from 'components/form-fields/v5'

const AddUpdateCSNoteDialog = ({ id, isOpen = false, title = T.NEW_NOTE, existingNote = {}, onClose }) => {
  const [createCSNote, { isLoading: isCreateCSNoteLoading }] = useCreateCSNoteMutation()
  const [updateCSNote, { isLoading: isUpdateCSNoteLoading }] = useUpdateCSNoteMutation()
  const [patchCSNote, { isLoading: isPatchCSNoteLoading }] = usePatchCSNoteMutation()
  const CSNoteForm = useForm({ defaultValues: { note: '', inherit: false } })
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = CSNoteForm

  const noteHelperText = get(errors, 'note.message')
  const noteId = get(existingNote, 'id')

  const updateNote = (note, updateInherited) =>
    updateCSNote({ id, noteId, data: { note, updateInherited } })
      .unwrap()
      .then(() => {
        toast.success('Note updated successfully')
        onClose()
      })
      .catch(handleError)

  const handleSave = data => {
    if (!isDirty) {
      onClose()
      return
    }

    if (!noteId) {
      // New note
      createCSNote({ id, data })
        .unwrap()
        .then(() => {
          toast.success('Note added successfully')
          onClose()
        })
        .catch(handleError)
      return
    }

    if (data.inherit === existingNote?.inherit) {
      // Update note
      updateNote(data.note, data.inherit)
      return
    }

    // Update inherit and note
    patchCSNote({ id, noteId, data: { inherit: data.inherit, skipInvalidate: true } })
      .unwrap()
      .then(() => updateNote(data.note, data.inherit))
      .catch(handleError)
  }

  const handleCSNoteKeyDown = event => {
    if (isEnterOrNumpadEnterWithCtrlOrMeta(event)) {
      handleSubmit(handleSave)()
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({ note: get(existingNote, 'note', ''), inherit: get(existingNote, 'inherit', false) })
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <FormProvider {...CSNoteForm}>
          <HHFormTextField
            autoFocus
            control={control}
            rules={{ required: T.CANNOT_BE_EMPTY }}
            error={noteHelperText}
            helperText={noteHelperText}
            deprecatedLabel={false}
            name="note"
            label="Note"
            placeholder="Leave a note"
            sx={{ mt: 3, '& .MuiOutlinedInput-root': { height: 'inherit' } }}
            rows={4}
            fullWidth
            multiline
            onKeyDown={handleCSNoteKeyDown}
          />
          <Box mt={1} display="flex" justifyContent="end" alignItems="center">
            <Typography mr={0.5}>{isEmpty(existingNote) ? T.ADD_TO_NEW_WORK_ORDERS : T.UPDATE_ASSOCIATED_WORK_ORDERS}</Typography>
            <HHFormSwitchField control={control} name="inherit" />
          </Box>
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton
          loading={isCreateCSNoteLoading || isUpdateCSNoteLoading || isPatchCSNoteLoading}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSave)}
        >
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddUpdateCSNoteDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  existingNote: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default AddUpdateCSNoteDialog
