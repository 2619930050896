import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'

import { useGridApiRef } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import { DialogContent, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { getFilteredRowsForAddRemoveDialog } from 'data/groups/accountGroupsSelectors'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SearchField from 'components/customers/groups/account/add-remove/common/SearchField'
import DataGrid from 'components/customers/groups/account/add-remove/common/DataGrid'
import { ADD_REMOVE_FORM_MODEL } from 'components/customers/groups/account/add-remove/common/settings'
import { transformSearchResponse } from 'utils/accountSearch'
import { SUSPENSION_FORM_MODEL } from 'components/customer-details/content/suspensions/SuspendAccountDialog/settings'
import GenericSuspendAccountDialog from 'components/customer-details/content/suspensions/SuspendAccountDialog/GenericSuspendAccountDialog'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { useSuspendAccountListMutation } from 'api/accounts/suspensionsCrud'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import Filters from 'components/customers/groups/suspension/add-remove/Filters'
import uniq from 'lodash/uniq'

const defaultValues = { ...ADD_REMOVE_FORM_MODEL, ...SUSPENSION_FORM_MODEL }

const AddAccountsToSuspensionGroupDialog = ({ isOpen = false, existingAccounts = [], onClose }) => {
  const apiRef = useGridApiRef()
  const [getAccountMeta, { isSuccess: isAccountMetaSuccess }] = useLazyGetAccountMetaQuery()
  const addToGroupForm = useForm({ defaultValues: { ...defaultValues } })
  const { watch, reset, handleSubmit } = addToGroupForm
  const [isConfirmSuspensionDialogOpen, setIsConfirmSuspensionDialogOpen] = useState(false)
  const customerMeta = useSelector(getAccountMetaData, shallowEqual)
  const selectionModel = watch('selectionModel')
  const search = watch('search')
  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedCount = useMemo(() => (Array.isArray(selectionModel) ? selectionModel.length : 0), [selectionModel])
  const confirmDialogTitle = useMemo(
    () => (selectedCount > 1 ? `Suspend ${selectedCount} accounts` : `Suspend ${selectedCount} account`),
    [selectedCount]
  )
  const allAccounts = useMemo(
    () => get(transformSearchResponse({ results: existingAccounts }), 'accounts', []).filter(row => !row?.suspensionHold),
    [existingAccounts]
  )
  const filteredRows = useMemo(
    () => getFilteredRowsForAddRemoveDialog({ rows: allAccounts, search, selectedTagsIds, selectedBillingProfilesIds }),
    [allAccounts, search, selectedTagsIds, selectedBillingProfilesIds]
  )
  const [suspendAccountList, { isLoading }] = useSuspendAccountListMutation()

  const handleOpenConfirmSuspensionDialog = () => {
    setIsConfirmSuspensionDialogOpen(true)
  }

  const handleCloseConfirmSuspensionDialog = () => {
    setIsConfirmSuspensionDialogOpen(false)
  }

  const handleAddAccountsToGroup = data => {
    const { selectionModel: accountIds, suspensionType, description, startDate: rawStartDate } = data
    suspendAccountList({ suspensionType, description, accountIds, startDate: formatDateToBEFormatDateFns(rawStartDate) })
      .unwrap()
      .then(() => {
        toast.success(selectedCount === 0 ? T.ACCOUNT_SUSPENDED_SUCCESSFULLY : T.ACCOUNTS_SUSPENDED_SUCCESSFULLY)
        handleCloseConfirmSuspensionDialog()
        onClose()
      })
      .catch(handleError)
  }

  const isRowSelectable = params => !params.row?.suspensionHold

  useEffect(() => {
    if (isOpen) {
      getAccountMeta()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && isAccountMetaSuccess) {
      const dataBillingProfileIds = uniq(allAccounts.map(({ billingProfileId }) => billingProfileId))
      const dataTagIds = uniq(allAccounts.map(({ tagIds }) => tagIds).flat())
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      const selectedTags = allTags.filter(({ id }) => dataTagIds.includes(id))
      const selectedBillingProfiles = billingProfiles.filter(({ id }) => dataBillingProfileIds.includes(id))
      reset({ ...defaultValues, tagList: selectedTags, selectedTags, billingProfileList: selectedBillingProfiles, selectedBillingProfiles })
    }
  }, [isOpen, allAccounts, existingAccounts, isAccountMetaSuccess, customerMeta])

  return (
    <FormProvider {...addToGroupForm}>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
        <HHDialogTitle title={T.SUSPEND_ACCOUNTS} onClose={onClose} />
        <DialogContent>
          <Grid mt={1} container spacing={2} alignItems="center">
            <Grid item xs>
              <SearchField />
            </Grid>
            <Grid item xs="auto">
              <Filters />
            </Grid>
          </Grid>
          <DataGrid isRowSelectable={isRowSelectable} apiRef={apiRef} rows={allAccounts} filteredRows={filteredRows} />
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <LoadingButton
            loading={false}
            disabled={selectionModel.length === 0}
            size="small"
            color="error"
            variant="contained"
            onClick={handleOpenConfirmSuspensionDialog}
          >
            {T.SUSPEND}
          </LoadingButton>
        </HHDialogActions>
        <GenericSuspendAccountDialog
          title={confirmDialogTitle}
          open={isConfirmSuspensionDialogOpen}
          onClose={handleCloseConfirmSuspensionDialog}
          isSaveLoading={isLoading}
          onSave={handleSubmit(handleAddAccountsToGroup)}
        />
      </HHBaseDialog>
    </FormProvider>
  )
}

AddAccountsToSuspensionGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  existingAccounts: PropTypes.array.isRequired,
}

export default AddAccountsToSuspensionGroupDialog
