import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, ListItem, ListItemText, useMediaQuery, useTheme, Skeleton } from '@mui/material'
import { get } from 'utils/lodash'

const RentalFeeStatItem = ({
  isLoading,
  primary,
  secondary,
  divider,
  primaryTypographyProps,
  secondaryTypographyProps,
  DividerProps,
  ...rest
}) => {
  const theme = useTheme()
  const isSmOrLower = useMediaQuery(theme.breakpoints.down('sm'))
  const sx = get(rest, 'sx')

  return (
    <Grid display="flex" item sx={sx} {...rest}>
      <ListItem sx={{ py: 0 }} dense divider={divider && isSmOrLower}>
        <ListItemText
          primary={isLoading ? <Skeleton width="5rem" /> : primary}
          primaryTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
            noWrap: true,
            ...primaryTypographyProps,
          }}
          secondary={isLoading ? <Skeleton width="4rem" /> : secondary}
          secondaryTypographyProps={{
            variant: 'body1',
            color: 'textSecondary',
            noWrap: true,
            ...secondaryTypographyProps,
          }}
        />
      </ListItem>
      {divider && !isSmOrLower && <Divider variant="middle" orientation="vertical" flexItem {...DividerProps} />}
    </Grid>
  )
}

RentalFeeStatItem.propTypes = {
  isLoading: PropTypes.bool,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  divider: PropTypes.bool,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  DividerProps: PropTypes.object,
}

export default memo(RentalFeeStatItem)
