import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { MapContext } from 'providers/MapProvider'
import { getLatitude, getLongitude } from 'data/address/addressSelectors'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'
import { IconButton, SvgIcon } from '@mui/material'
import { Map } from '@styled-icons/heroicons-outline'

const ZoomToStopButton = ({ row }) => {
  const map = useContext(MapContext)
  const zoomToStopMarker = useCallback(() => {
    const mapCurrentRef = map.current
    const latitude = getLatitude(row)
    const longitude = getLongitude(row)
    if (isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)) {
      mapCurrentRef.jumpTo({
        center: [longitude, latitude],
        zoom: 18.1,
      })
    }
  }, [map, row, row?.id])
  return (
    <IconButton sx={{ p: 0 }} onClick={zoomToStopMarker}>
      <SvgIcon sx={{ fontSize: '1.2rem' }}>
        <Map />
      </SvgIcon>
    </IconButton>
  )
}

ZoomToStopButton.propTypes = {
  row: PropTypes.object.isRequired,
}

export default ZoomToStopButton
