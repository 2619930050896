import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    postInvoiceList: build.mutation({
      query: body => ({ url: '/api/billing/invoice/post', method: 'POST', body }),
    }),
  }),
})

export const { usePostInvoiceListMutation } = extendedApi
