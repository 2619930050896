import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import RenderAccountNameAndBalance from 'components/customers/groups/account/common/content/RenderAccountNameAndBalance'
import RenderSecondaryText from 'components/customers/groups/account/common/content/RenderSecondaryText'

const RenderAccountCell = ({ row = {} }) => {
  const { rentalFeesCount = 0 } = row

  return (
    <Box py={0.5} width="100%">
      <RenderAccountNameAndBalance row={row} />
      <RenderSecondaryText row={row} rightSecondaryText={`${rentalFeesCount} rental fee${rentalFeesCount > 1 ? 's' : ''}`} />
    </Box>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountCell
