import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    fetchTagList: build.mutation({
      query: () => ({
        url: '/api/settings/tag/list',
        method: 'POST',
        body: {},
      }),
    }),
    getTagList: build.query({
      query: () => ({
        url: '/api/settings/tag/list',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useFetchTagListMutation, useLazyGetTagListQuery } = extendedApi

export const getTagListSlice = extendedApi.endpoints.getTagList.select()
