/* eslint-disable no-param-reassign */
import { get } from 'utils/lodash'
import { createBulkActionSlice } from 'slices/generic/createBulkActionSlice'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const bulkUnassignStopsSlice = createBulkActionSlice({
  name: 'bulkUnassignStops',
})
export const {
  setBulkState: setBulkUnassignStopsState,
  addSelectedMoveToRows,
  addSelectedMoveFromRows,
  setSelectedMoveFromRows,
  setSelectedMoveToRows,
} = bulkUnassignStopsSlice.actions

export const selectIsOpenBulkUnassignStopsDialog = state => get(state, 'bulkUnassignStops.isOpen', false)
export const selectIsSelectDateDialogOpen = state => get(state, 'bulkUnassignStops.isSelectDateDialogOpen', false)
export const selectIsOpenBulkUnassignConfirmCloseDialog = state => get(state, 'bulkUnassignStops.isConfirmCloseDialogOpen', false)
export const selectBulkUnassignFromRouteSequenceLine = state => get(state, 'bulkUnassignStops.moveFromRouteSequenceLine', {})
export const selectBulkUnassignFromRouteStopMarkers = state => get(state, 'bulkUnassignStops.moveFromRouteStopMarkers', {})
export const selectBulkUnassignFromRows = state => get(state, 'bulkUnassignStops.moveFromRows', [])
export const selectBulkSelectedMoveFromRows = state => get(state, 'bulkUnassignStops.selectedMoveFromRows', [])
export const selectBulkUnassignToRouteStopMarkers = state => get(state, 'bulkUnassignStops.moveToRouteStopMarkers', {})
export const selectBulkUnassignToRows = state => get(state, 'bulkUnassignStops.moveToRows', [])
export const selectBulkSelectedMoveToRows = state => get(state, 'bulkUnassignStops.selectedMoveToRows', [])
export const selectBulkUnassignRoutesById = state => get(state, 'bulkUnassignStops.routesById', {})
export const selectBulkUnassignFitBounds = state => get(state, 'bulkUnassignStops.fitBounds', false)
export const selectBulkUnassignMapLayer = state => get(state, 'bulkUnassignStops.mapLayer', FROM_ROUTE_LAYER)

export default bulkUnassignStopsSlice.reducer
