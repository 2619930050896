import React, { memo } from 'react'
import { HHAlert } from 'components/common/HHAlert'
import { orange } from '@mui/material/colors'
import { Box, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectBulkAssignStopsDate, selectBulkAssignRoutesById } from 'slices/route/bulkAssignStopsSlice'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import PropTypes from 'prop-types'

const PermanentAssignAlert = ({ toBeMovedRowsCount = 0 }) => {
  const { watch } = useFormContext()
  const theme = useTheme()
  const moveToRouteId = watch('moveToRouteId')
  const routesById = useSelector(selectBulkAssignRoutesById)
  const toRouteName = get(routesById, [moveToRouteId, 'name'])
  const serializedServiceDate = useSelector(selectBulkAssignStopsDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const serviceDateFEFormat = formatDateToFEFormatDateFns(serviceDate)
  return (
    <HHAlert
      severity="warning"
      sx={{
        ...theme.typography.body1,
        borderColor: orange[500],
        borderWidth: 1,
        borderStyle: 'solid',
        pr: theme.spacing(3),
      }}
    >
      <Typography>
        You’re about to assign
        <Box component="span" fontWeight={600}>
          {` ${toBeMovedRowsCount} `}
        </Box>
        {toBeMovedRowsCount > 1 ? 'services ' : 'service '}
        on
        <Box component="span" fontWeight={600}>{` ${serviceDateFEFormat} `}</Box>
        to
        <Box component="span" fontWeight={600}>
          {` ${toRouteName} `}
        </Box>
        on
        <Box component="span" fontWeight={600}>{` ${serviceDateFEFormat}`}</Box>. Do you want to continue?
      </Typography>
    </HHAlert>
  )
}

PermanentAssignAlert.propTypes = {
  toBeMovedRowsCount: PropTypes.number,
}

export default memo(PermanentAssignAlert)
