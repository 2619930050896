import { isEmpty, orderBy } from 'lodash'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

export const getSortedNotes = createSelector([state => state.notes], (notes = []) => {
  if (!Array.isArray(notes)) {
    return []
  }

  return orderBy(notes, ['pinned', note => new Date(note?.createdAt)], ['desc', 'desc'])
})

export const getArchivedAndUnarchivedNotes = createSelector([state => state.notes], (notes = []) => {
  const unarchivedNotes = []
  const archivedNotes = []

  if (!Array.isArray(notes)) {
    return { unarchivedNotes, archivedNotes }
  }

  notes.forEach(note => {
    if (note.archived) {
      archivedNotes.push(note)
    } else {
      unarchivedNotes.push(note)
    }
  })

  return { unarchivedNotes, archivedNotes }
})

export const getNoteUserInfo = createSelector(
  [state => state.note, state => state.isUpdatedByPreference],
  (note = {}, isUpdatedByPreference = true) => {
    const updatedByUser = get(note, 'updatedByUser', {})
    if (isUpdatedByPreference && !isEmpty(updatedByUser)) {
      return {
        firstName: get(updatedByUser, 'firstName', ''),
        lastName: get(updatedByUser, 'lastName', ''),
        username: get(updatedByUser, 'username', ''),
        dateTime: get(note, 'updatedAt'),
      }
    }

    const createdByUser = get(note, 'createdByUser', {})
    return {
      firstName: get(createdByUser, 'firstName', ''),
      lastName: get(createdByUser, 'lastName', ''),
      username: get(createdByUser, 'username', ''),
      dateTime: get(note, 'createdAt'),
    }
  }
)

export const getSortedHistoryNotes = createSelector([state => state.notes], notes => {
  if (!Array.isArray(notes)) {
    return []
  }

  return orderBy(notes, ['version'], ['desc'])
})

export const getNotesForMobile = createSelector([state => state.notes], (notes = []) => {
  if (!Array.isArray(notes)) {
    return []
  }

  return getSortedNotes({ notes: notes.filter(note => note?.visibleOnMobile && !note?.archived) })
})
