import React, { useCallback, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { lightBlue } from '@mui/material/colors'
import { Checkbox, useTheme } from '@mui/material'
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { get } from 'utils/lodash'
import { HHAlert } from 'components/common/HHAlert'
import { removeParentFromSelection } from 'utils/groups'
import {
  resetSelectionModels,
  setInvoiceSelectionModel,
  setSelectedInvoiceIdForPreview,
  resetGoToPage,
  setExpansionState,
} from 'slices/groups/InvoicePrintableViewSlice'

import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import RenderInvoiceHeader from '../../common/data-grid/RenderInvoiceHeader'
import RenderInvoiceCheckboxCell from '../../common/data-grid/RenderInvoiceCheckboxCell'
import RenderInvoiceCell from './RenderInvoiceCell'
import { ROW_HEIGHT } from './settings'

const InvoicesDataGrid = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isLoading = false, rows = [], hierarchyIds = [], totalAmountCents = 0 } = props
  const selectionModel = useSelector(state => get(state, 'InvoicePrintableView.invoiceSelectionModel', []), shallowEqual)
  const selectedInvoiceIdForPreview = useSelector(state => get(state, 'InvoicePrintableView.selectedInvoiceIdForPreview'), shallowEqual)
  const expansionState = useSelector(state => get(state, 'InvoicePrintableView.expansionState', []), shallowEqual)

  const totalRows = rows.length
  const totalSelectedRows = selectionModel.length
  const totalHierarchyRows = hierarchyIds.length
  const firstChildId = get(rows, '[1].id')

  const getRowId = useCallback(({ id }) => id, [])

  const getRowHeight = useCallback(() => ROW_HEIGHT, [])

  const HeaderSelectAll = () => (
    <Checkbox
      indeterminate={totalSelectedRows && totalSelectedRows < totalRows - totalHierarchyRows}
      checked={totalSelectedRows === totalRows - totalHierarchyRows}
      onChange={event => {
        const isChecked = event.target.checked
        dispatch(resetGoToPage())
        if (isChecked) {
          const allIds = removeParentFromSelection(ref.current.getAllRowIds())
          dispatch(setInvoiceSelectionModel({ selectionModel: allIds, rows }))
          dispatch(setSelectedInvoiceIdForPreview(allIds[0]))
          ref.current.setRowChildrenExpansion(hierarchyIds[0], true)
          return
        }
        dispatch(resetSelectionModels())
        dispatch(setSelectedInvoiceIdForPreview(''))
      }}
      icon={<RadioButtonUncheckedSharpIcon />}
      checkedIcon={<CheckCircleSharpIcon />}
      indeterminateIcon={<RemoveCircleIcon />}
    />
  )

  const handleInvoiceSelectionChange = newSelectionModel => {
    dispatch(resetGoToPage())
    dispatch(setInvoiceSelectionModel({ selectionModel: newSelectionModel, rows }))
    const selectedIds = difference(newSelectionModel, selectionModel)
    if (selectedIds.length === 0) {
      return
    }

    const newSelectedInvoiceId = get(selectedIds, [0])
    dispatch(setSelectedInvoiceIdForPreview(newSelectedInvoiceId))
    const rowDetails = ref.current.getRowNode(newSelectedInvoiceId)
    ref.current.setRowChildrenExpansion(rowDetails.parent, true)
  }

  const getClassName = params => {
    const className = `${params.row.isParent ? `${params.id} parent` : 'child'}-row`

    // If no match, then select the first child row
    if (params.id === selectedInvoiceIdForPreview || (!selectedInvoiceIdForPreview && params.id === firstChildId)) {
      return `${className} selected-preview-invoice`
    }

    return className
  }

  const handleOnCellClick = params => {
    if (params.field === 'id' && !params.row.isParent) {
      dispatch(resetGoToPage())
      dispatch(setSelectedInvoiceIdForPreview(params.id))
    }
  }

  useEffect(() => {
    const handleRowExpansionChange = params => {
      const { id, childrenExpanded } = params
      dispatch(setExpansionState({ id, type: childrenExpanded ? 'add' : 'remove' }))
    }

    return ref?.current?.subscribeEvent('rowExpansionChange', handleRowExpansionChange)
  }, [ref])

  useEffect(() => {
    if (hierarchyIds.length > 0 && ref?.current) {
      ref.current.setRowChildrenExpansion(hierarchyIds[0], true)
    }
  }, [hierarchyIds])

  const getIsGroupExpandedByDefault = useCallback(({ groupingKey }) => expansionState.includes(groupingKey), [expansionState])

  return (
    <DataGridPro
      loading={isLoading}
      apiRef={ref}
      treeData
      isGroupExpandedByDefault={getIsGroupExpandedByDefault}
      rows={rows}
      columnHeaderHeight={totalRows > 0 ? 56 : 0}
      hideFooter
      checkboxSelection
      disableRowSelectionOnClick
      keepNonExistentRowsSelected
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleInvoiceSelectionChange}
      getRowClassName={getClassName}
      onCellClick={handleOnCellClick}
      columnVisibilityModel={{
        groupingField: false,
      }}
      columns={[
        {
          ...GRID_TREE_DATA_GROUPING_FIELD,
          field: 'groupingField',
        },
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          cellClassName: 'checkbox-column',
          headerClassName: 'checkbox-column-header',
          // DataGridPro doesn't select collapsed rows, so using a custom component
          renderHeader: () => <HeaderSelectAll />,
          // DataGridPro doesn't child selection by hierarchy, so using a custom component
          renderCell: RenderInvoiceCheckboxCell,
        },
        {
          flex: 1,
          field: 'id',
          renderCell: RenderInvoiceCell,
          headerClassName: 'data-column-header',
          renderHeader: () => (
            <RenderInvoiceHeader
              rows={rows}
              selectedRows={selectionModel}
              hierarchyIds={hierarchyIds}
              totalAmountCents={totalAmountCents}
            />
          ),
          sortable: false,
          cellClassName: 'data-column',
        },
      ]}
      slots={{
        baseCheckbox: params => <DataGridBaseCheckbox {...params} rows={rows} />,
        noRowsOverlay: () => (
          <HHAlert borderColor={theme.palette.info.light} severity="info">
            No invoices
          </HHAlert>
        ),
      }}
      getTreeDataPath={row => row.hierarchy}
      groupingColDef={{
        hideDescendantCount: true,
        valueFormatter: () => '',
        width: 24,
        minWidth: 24,
        maxWidth: 24,
        headerName: '',
        cellClassName: 'grouping-column',
      }}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      disableColumnMenu
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .data-column-header .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
          },
          '& .data-column-header, & .checkbox-column-header': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover': {
          backgroundColor: 'background.paper',
        },
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
          padding: '0 4px',
        },
        '& .MuiDataGrid-row': {
          '&.selected-preview-invoice.child-row': {
            '& .checkbox-column, & .data-column': {
              backgroundColor: `${lightBlue[700]}14`,
            },
          },
          '& .grouping-column': {
            border: 'none',
          },
        },
      }}
    />
  )
})

InvoicesDataGrid.propTypes = {
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  hierarchyIds: PropTypes.array,
  totalAmountCents: PropTypes.number,
}

export default InvoicesDataGrid
