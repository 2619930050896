import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Grid, Divider } from '@mui/material'
import { TOTAL_COLUMN_WIDTH, AMOUNT_APPLIED_COLUMN_WIDTH } from './settings'

const ActivityHeader = ({ statusLabel = 'Status', totalLabel = 'Total', appliedLabel = 'Amount applied' }) => {
  return (
    <Grid container spacing={2.5}>
      <Grid item xs>
        <Typography variant="caption">{statusLabel}</Typography>
      </Grid>

      <Grid item width={TOTAL_COLUMN_WIDTH}>
        <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
          {totalLabel}
        </Typography>
      </Grid>

      <Grid item xs="auto">
        <Divider orientation="vertical" />
      </Grid>

      <Grid item width={AMOUNT_APPLIED_COLUMN_WIDTH}>
        <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
          {appliedLabel}
        </Typography>
      </Grid>
    </Grid>
  )
}

ActivityHeader.propTypes = {
  statusLabel: PropTypes.string,
  totalLabel: PropTypes.string,
  appliedLabel: PropTypes.string,
}

export default ActivityHeader
