import React from 'react'
import PropTypes from 'prop-types'
import ActionAutoComplete from 'components/pricing/ActionAutoComplete'
import { noop } from 'lodash'

const ActionExceptionAutoComplete = ({
  globalFilterOptions = null,
  getByLabelText = noop,
  checkedServiceAction = [],
  handleCheckboxValue = noop,
  backFlag = false,
}) => {
  return (
    <>
      <ActionAutoComplete
        globalFilterOptions={globalFilterOptions}
        getByLabelText={getByLabelText}
        handleCheckboxValue={handleCheckboxValue}
        checkedServiceAction={checkedServiceAction}
        backFlag={backFlag}
      />
    </>
  )
}

ActionExceptionAutoComplete.propTypes = {
  getByLabelText: PropTypes.func,
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedServiceAction: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default ActionExceptionAutoComplete
