import React, { useState } from 'react'
import { Box, Button, Grid, Menu, MenuItem } from '@mui/material'
import { toast } from 'react-toastify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { uniq } from 'lodash'
import SaveViews from './SaveViews'
import { downloadPaymentReceiptBlobListInChunks } from '../../../utils/file'
import { handleError } from '../../../utils/error'
import { putIsLoading } from '../../../middleware/actions/response'
import T from '../../../T'
import { useRegeneratePaymentReceiptsMutation } from '../../../api/billing/regeneratePaymentReceipts'
import {
  setPaymentsIsOpenActionMenu,
  setAddToGroupDialogState,
  setPaymentReceiptEmailConfirmationState,
} from '../../../slices/billing/paymentsSlice'
import AddToGroupDialog from '../groups/payment/AddToGroupDialog'
import TakePaymentsDialog from '../groups/payment/take-payments'

const PaymentsTopSection = () => {
  const dispatch = useDispatch()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)
  const selectionModel = useSelector(state => state.payments.selectionModel, shallowEqual)
  const selectedRows = useSelector(state => state.payments.selectedRows, shallowEqual)
  const isOpenAddToGroupDialog = useSelector(state => state.payments.addToGroup.isOpen)
  const selectedBatchIds = useSelector(state => state.payments.addToGroup.batchIds)
  const isOpenPaymentActionMenu = useSelector(state => state.payments.isOpenPaymentActionMenu)
  const showPaymentActionMenu = selectionModel.length > 0

  const [regeneratePaymentReceipts] = useRegeneratePaymentReceiptsMutation()

  const handleDownloadPaymentReceipt = async () => {
    const allReceipts = selectedRows
      .filter(row => row?.paymentNumber)
      .map(({ paymentNumber, accountTransactionHistoryId, accountId }) => ({ paymentNumber, accountTransactionHistoryId, accountId }))
    if (allReceipts.length > 0) {
      downloadPaymentReceiptBlobListInChunks(businessId, allReceipts, allReceipts.length === 1)
    }
  }

  const handleSendPaymentReceipt = () => dispatch(setPaymentReceiptEmailConfirmationState({ isOpen: true, payments: selectedRows }))

  const handleToggleTakePaymentsDialog = () => setIsOpenTakePaymentsDialog(!isOpenTakePaymentsDialog)

  const handleClosePaymentActionMenu = () => {
    dispatch(setPaymentsIsOpenActionMenu(false))
  }
  const handleOpenPaymentActionMenu = event => {
    dispatch(setPaymentsIsOpenActionMenu(event.currentTarget.id))
  }

  const handleAddToGroupOptionClick = () => {
    const batchIds = selectedRows.filter(row => row?.batchId).map(({ batchId }) => batchId)
    dispatch(setAddToGroupDialogState({ isOpen: true, batchIds: uniq(batchIds) }))
    handleClosePaymentActionMenu()
  }

  const handleRegeneratePaymentReceipt = () => {
    dispatch(putIsLoading(true))
    regeneratePaymentReceipts({ accountTransactionHistoryIds: selectionModel })
      .unwrap()
      .then(() =>
        toast.success(selectionModel.length === 1 ? T.RECEIPT_IS_REGENERATING : T.RECEIPTS_ARE_REGENERATING, {
          hideProgressBar: false,
          autoClose: 4000,
        })
      )
      .catch(handleError)
      .finally(() => {
        handleClosePaymentActionMenu()
        dispatch(putIsLoading(false))
      })
  }

  return (
    <Box p={3} width="100%">
      <Grid container sx alignItems="flex-end">
        <Grid item xs>
          <SaveViews />
        </Grid>
        <Grid item container alignItems="flex-end" xs="auto">
          {selectionModel.length > 0 && (
            <Button size="small" variant="outlined" onClick={handleDownloadPaymentReceipt}>
              {T.DOWNLOAD}
            </Button>
          )}
          {selectionModel.length > 0 && (
            <Button sx={{ ml: 1, textTransform: 'none' }} size="small" variant="outlined" onClick={handleSendPaymentReceipt}>
              Email receipt
            </Button>
          )}
          <Button
            sx={{ ml: 1, textTransform: 'none' }}
            size="small"
            // Only disable if single row
            variant="outlined"
            onClick={handleToggleTakePaymentsDialog}
          >
            {T.NEW_PAYMENT}
          </Button>
          {showPaymentActionMenu && (
            <>
              <Button
                id="paymentActionMenuButton"
                variant="outlined"
                color="textSecondary"
                size="small"
                sx={{ ml: 1, minWidth: 'auto', p: 0.6, svg: { color: 'text.primary' } }}
                onClick={handleOpenPaymentActionMenu}
              >
                <MoreVertIcon sx={{ width: 20, height: 20 }} />
              </Button>
              <Menu
                className="configurator-container"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  variant: 'outlined',
                }}
                open={Boolean(isOpenPaymentActionMenu)}
                anchorEl={document.getElementById(isOpenPaymentActionMenu)}
                onClose={handleClosePaymentActionMenu}
              >
                <MenuItem onClick={handleAddToGroupOptionClick}>{T.ADD_TO_PAYMENT_GROUP}</MenuItem>
                <MenuItem onClick={handleRegeneratePaymentReceipt}>{`${T.REGENERATE_RECEIPT}s`}</MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </Grid>
      <AddToGroupDialog
        isOpen={isOpenAddToGroupDialog}
        batchIds={selectedBatchIds}
        onClose={() => dispatch(setAddToGroupDialogState({ isOpen: false, batchIds: [] }))}
      />
      {isOpenTakePaymentsDialog && <TakePaymentsDialog isOpen={isOpenTakePaymentsDialog} onClose={handleToggleTakePaymentsDialog} />}
    </Box>
  )
}

export default PaymentsTopSection
