import React from 'react'
import Avatar from '@mui/material/Avatar'
import PropTypes from 'prop-types'
import Endpoint from 'middleware/sagas/endpoint'
import ToolTip from 'components/common/Tooltip'

const { profilePicUri } = Endpoint.user

const AvatarSquare = ({ firstName = '', lastName = '', fromCDN = true, src = undefined, sx = undefined }) => {
  const firstNameLetter = firstName?.charAt(0)
  const lastNameLetter = lastName?.charAt(0)

  // I think no one is using this, we may need to migrate this to a separate API call
  const imgSrc = src && fromCDN ? profilePicUri + src : src

  return (
    <ToolTip title={`${firstName} ${lastName}`.trim()}>
      <Avatar
        src={imgSrc}
        variant="rounded"
        sx={{
          bgcolor: 'background.driverBg',
          color: 'background.paper',
          width: 24,
          height: 24,
          fontSize: 12,
          ...sx,
        }}
      >
        {!src && <>{`${firstNameLetter}${lastNameLetter}`.trim()}</>}
      </Avatar>
    </ToolTip>
  )
}

AvatarSquare.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fromCDN: PropTypes.bool,
  src: PropTypes.string,
  sx: PropTypes.object,
}

export default AvatarSquare
