import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

import RenderAccountNameAndBalance from '../content/RenderAccountNameAndBalance'
import RenderSecondaryText from '../content/RenderSecondaryText'

const RenderAccountCell = ({ row = {} }) => {
  const { unpaidInvoiceCount = 0 } = row

  return (
    <Box py={0.5} width="100%">
      <RenderAccountNameAndBalance row={row} />
      <RenderSecondaryText row={row} rightSecondaryText={`${unpaidInvoiceCount} invoice${unpaidInvoiceCount > 1 ? 's' : ''}`} />
    </Box>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountCell
