import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { BUSINESS_LINE_TYPE_UPPERCASE } from 'settings/constants/businessLine'

const { RESIDENTIAL, COMMERCIAL, ROLL_OFF } = BUSINESS_LINE_TYPE_UPPERCASE

export const groupRowsByBusinessLine = createSelector(
  [state => state?.rows, state => state?.businessLineHashMap, state => state?.hideArchived],
  (rows = [], businessLineHashMap = {}, hideArchived) => {
    const groupedRows = {
      ALL: [],
      [RESIDENTIAL]: [],
      [COMMERCIAL]: [],
      [ROLL_OFF]: [],
    }

    const filteredRows = hideArchived ? rows.filter(({ archived }) => !archived) : rows
    const sortedRows = sortBy(filteredRows, ['archived', 'feeName'])

    sortedRows.forEach(row => {
      const category = get(businessLineHashMap, get(row, 'businessLineId'))
      if (category) {
        groupedRows[category].push(row)
      }
      groupedRows.ALL.push(row)
    })

    return groupedRows
  }
)
