import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography, DialogContent } from '@mui/material'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import T from 'T'
import { Accordion, AccordionDetails, AccordionSummary } from '../../../../customer-details/tables/StyledAccordian'

const DisposalTicketVariantWrapper = ({ variant = 'accordion', children, actions, footer }) => {
  const isModalVariant = variant === 'modal'
  const [isExpanded, setIsExpanded] = useState(true)
  const ExpandIcon = isExpanded ? ArrowDropDown : ArrowRight
  const toggleIsExpanded = () => setIsExpanded(s => !s)
  if (isModalVariant)
    return (
      <>
        <DialogContent>{children}</DialogContent>
        {footer}
      </>
    )
  return (
    <Accordion disableGutters sx={{ mt: 1, '&.Mui-expanded': { my: 1 } }} expanded={isExpanded}>
      <AccordionSummary withNewColors disableContentMargin disable disablePointer expandIcon={undefined} iconGap={false}>
        <Grid container direction="row" columnSpacing={1} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto" justifyContent="center" alignItems="center">
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
              <ExpandIcon onClick={toggleIsExpanded} cursor="pointer" color="disabled" />
            </Box>
          </Grid>
          <Grid item xs>
            <Typography sx={{ cursor: 'pointer' }} variant="h6" fontWeight={500} onClick={toggleIsExpanded}>
              {T.DISPOSAL_TICKET}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            {actions}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          {children}
          {footer}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

DisposalTicketVariantWrapper.propTypes = {
  variant: PropTypes.oneOf(['modal', 'accordion']),
  children: PropTypes.node,
  actions: PropTypes.node,
  footer: PropTypes.node,
}

export default DisposalTicketVariantWrapper
