export const SEARCH_TYPES = {
  CUSTOMER: 'Customer',
  INVOICE: 'Invoice #',
}

export const SEARCH_OPTIONS = [SEARCH_TYPES.CUSTOMER, SEARCH_TYPES.INVOICE]

export const PAYMENT_MODEL = {
  searchType: SEARCH_TYPES.CUSTOMER,
  searchValue: '',
  selectedOption: null,
  invoicesToPay: [],
}

export const PAYMENT_FORM_MODEL = {
  paymentMethod: '',
  paymentDate: new Date(),
  checkNum: '',
  amount: '',
  selectedPaymentTokenDetails: {},
  isNewCard: '',
  cardHolderName: '',
  cvv: '',
  accountType: 'Checking',
  isSaveNewMethod: false,
  cardNickName: '',
  autoPayTime: null,
  note: '',
}
