import api from 'api'
import {
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
  CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
} from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addInvoicesToGroup: build.mutation({
      query: ({ invoiceGroupId, invoiceIds }) => ({
        url: `/api/v1/billing/invoice/groups/${invoiceGroupId}/invoices`,
        body: invoiceIds,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => {
        const { invoiceGroupId } = arg
        return [
          { type: CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS, id: invoiceGroupId },
          { type: CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA, id: invoiceGroupId },
          { type: CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA, id: invoiceGroupId },
        ]
      },
    }),
  }),
})

export const { useAddInvoicesToGroupMutation } = extendedApi
