import React, { useReducer, useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import { NumericFormat } from 'react-number-format'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Tab, Tabs, Box, Stack, SvgIcon, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ReactComponent as TruckIcon } from 'assets/Truck.svg'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { addFormDirtyClass } from 'utils/dom'
import { isAlphaNumeric, isNumberOnly } from 'utils/validations'
import { combineAddressInfo } from 'utils/helper'
import { getActiveDeactivatedLocations } from 'utils/location'
import { useGetTagsMetadataQuery } from 'api/tags/getTagsMetadata'
import { getServiceFee, getTaxOnFeeLinking, getTaxLinking, isServiceGroup } from 'data/service/serviceSelectors'
import {
  getPricingPeriod,
  isRecurringService,
  isOnRequestService,
  isDailyRecurrence,
  isNonWeeklyRecurrence,
  transformMultiplePricedServicesToSingle,
  getServiceGroupPrice,
  getTaxList,
} from 'utils/service'

import { createBulkServices } from 'utils/serviceGroup'
import { PRICING_SERVICE_SEARCH_PAGINATION } from 'settings/constants/pagination'
import { SERVICE_GROUP } from 'settings/constants/serviceGroups'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import { getPricingServiceSearchResults, getPricingServiceDetail } from 'middleware/actions/configuredServices'
import { useCreateUpdateConfiguredServiceMutation } from 'api/configured-service/createUpdateConfiguredService'
import { useCreateBulkConfiguredServiceMutation } from 'api/configured-service/createBulkConfiguredService'
import { putIsLoading } from 'middleware/actions/response'
import { useGetServiceEventsMutation } from 'api/configured-service/getServiceEvents'
import { useGetServiceAffectedWOMutation } from 'api/configured-service/getServiceAffectedWO'

import { TabPanel, CommonDrawer, CommonTextLabel, CommonTextfield, CommonSelect } from 'components/common'

import T from 'T'
import FooterButton from 'components/buttons/FooterButton'
import TagsSelect from 'components/tags/TagsSelect'
import ConfirmServiceDays from 'components/modal/ConfirmServiceDays'
import { getArraySortedAscendingOrder } from 'data/utils/ascendingSortSelector'
import { formatDateToBEFormatDateFns } from 'utils/date'
import HHDialogTitle from 'components/common/HHDialogTitle'

import {
  ServiceTabFilterContent,
  ServiceTabAddContent,
  ServiceTabEditContent,
  ScheduleTabContent,
  FeesTaxesTabContent,
  MapTabContent,
  AdvancedTabContent,
} from './tab-content'

import { isServiceFormInvalid } from './validation'
import { SERVICE_MODEL } from './serviceModel'

import './style.scss'

const { INITIAL_PAGE, ROWS_PER_PAGE, MAX_ROWS } = PRICING_SERVICE_SEARCH_PAGINATION

const SERVICE_TAB_ID = 0 // Initial Selected tab Id
const SCHEDULE_TAB_ID = 1
const FEE_TAXES_TAB_ID = 2
const MAP_TAB_ID = 3
const ADVANCED_TAB_ID = 4

const DEBOUNCE_TIME = 300
const FILTER_CHANGE_KEY = [
  'searchType',
  'serviceType',
  'recurrenceFrequency',
  'recurrenceIds',
  'businessLines',
  'measureIds',
  'materialIds',
  'serviceMethodIds',
  'serviceActionIds',
  'pricingZoneId',
  'search',
]

const CustomBox = styled(Box)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 8,
    background: theme.palette.background.paper,
  },
}))

const ServiceDrawer = ({
  isOpenDrawer = false,
  locationId = '',
  accountId,
  getFilters = false,
  isAddMode = false,
  existingDetails = null,
  onRefresh,
  onClose,
}) => {
  const [createUpdateConfiguredService] = useCreateUpdateConfiguredServiceMutation()
  const [createBulkConfiguredService] = useCreateBulkConfiguredServiceMutation()
  const [getServiceEvents, { data: existingEvents }] = useGetServiceEventsMutation()
  const [getServiceAffectedWO, { isLoading: isWOLoading, data: workOrders }] = useGetServiceAffectedWOMutation()
  const [isOpenConfirmDaysDrawer, setIsOpenConfirmDaysDrawer] = useState(false)

  const dispatch = useDispatch()

  const { globalFilterOptions, customerDetails, pricedServices } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
      customerDetails: get(state, 'CustomersReducer.customerDetails', null),
      pricedServices: get(state, 'ConfiguredServicesReducer.pricedServices', []),
    }),
    shallowEqual
  )

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isDirty: false,
    isLoading: false,
    activeTab: SERVICE_TAB_ID,
    feeTaxesActiveTab: T.FEES,
    eventInstanceType: '',
    pricedServiceDetails: {},
    ...cloneDeep(SERVICE_MODEL),
  })

  const {
    isDirty,
    activeTab,
    isLoading,
    feeTaxesActiveTab,
    eventInstanceType,
    pricedServiceDetails,
    pricedServiceId,
    status,
    serviceType,
    taxZoneId,
    tags,
    price,
    service,
    schedule,
    billingSchedule,
    feeTaxList,
    location,
    advanced,
  } = localState

  const { permitObjArray, permitLocation, permit, permitContainerCount, permitStartDate } = advanced

  const isServiceGroupSelected = isServiceGroup({ type: service.searchType })
  const selectedBusinessLineId = get(service, 'businessLines[0].id', '')
  const serviceEvents = getArraySortedAscendingOrder({
    arrayToSort: get(schedule, 'serviceEvents', []),
    keyToSort: 'actionDate',
  })
  const recurrenceFrequency = get(pricedServiceDetails, 'recurrenceFrequency') || 1
  const isWeeklyMaxDaysNotSelected = serviceEvents.length < recurrenceFrequency

  // Filter meta based on selected businessId
  const getMetaByBusinessLine = metaFilter => {
    if (!selectedBusinessLineId) {
      return []
    }

    return metaFilter.filter(meta =>
      get(meta, 'businessLine', [])
        .map(line => line.id)
        .includes(selectedBusinessLineId)
    )
  }

  const measureMeta = getMetaByBusinessLine(get(globalFilterOptions, 'measure', []))
  const materialsMeta = getMetaByBusinessLine(get(globalFilterOptions, 'materials', []))
  const methodsMeta = getMetaByBusinessLine(get(globalFilterOptions, 'methods', []))

  const serviceActionAllMeta = get(globalFilterOptions, 'serviceAction', []).map(meta => ({
    type: meta.actionType,
    name: meta.actionName,
    id: meta.id,
  }))

  const serviceActionMeta = getMetaByBusinessLine(get(globalFilterOptions, 'serviceAction', []))

  const allLocations = get(customerDetails, 'locations', [])
  const { activeLocations } = getActiveDeactivatedLocations(customerDetails)
  const locationsSelectDropdown = activeLocations.map(loc => ({ key: loc.id, value: combineAddressInfo(loc.address) || '--' }))

  // we don't have businessLine in pricingZones
  // const pricingZonesMeta = getMetaByBusinessLine(get(globalFilterOptions, 'pricingZones', []));

  const tagsMeta = get(globalFilterOptions, 'tags', []).filter(data => data.active && data.forService)

  const pricingZonesMeta = get(globalFilterOptions, 'pricingZones', [])
  const taxZonesMeta = get(globalFilterOptions, 'taxZones', []).map(zone => ({
    key: zone.id,
    value: zone.zoneName,
    default: get(zone, 'default', false),
  }))
  const skillsMeta = get(globalFilterOptions, 'skills', []).map(skill => ({ key: get(skill, 'id', ''), value: get(skill, 'skillName') }))

  const isNextStepBtnDisabled = !(pricedServiceId && location.locationId)
  const tooltipTitle = useMemo(() => {
    if (activeTab === SCHEDULE_TAB_ID && isWeeklyMaxDaysNotSelected) {
      return `Please select ${recurrenceFrequency} option(s) to save changes`
    }

    if (!pricedServiceId && !location.locationId) {
      return 'Please select a service and service address to proceed'
    }
    if (!pricedServiceId) {
      return 'Please select a service to proceed'
    }
    if (!location.locationId) {
      return 'Please select a service address to proceed'
    }
    return ''
  }, [pricedServiceId, location, activeTab, isWeeklyMaxDaysNotSelected, recurrenceFrequency])

  // Check all the required fields
  const isFormInvalid = isServiceFormInvalid(
    serviceType,
    taxZoneId,
    pricedServiceDetails,
    schedule,
    billingSchedule,
    get(advanced, 'deactivate', false)
  )
  const isPermitValid = permit
    ? Array.isArray(permitObjArray) && permitObjArray[0]?.id && permitLocation && permitContainerCount && permitStartDate
    : true

  const handlePricingServiceSearch = newChanges => {
    // use get method, otherwise this mat throw error
    const searchType = get(newChanges, 'searchType', '')
    const payload = {
      type: searchType,
      serviceType: get(newChanges, 'serviceType', T.RECURRING),
      recurrenceFrequency: isRecurringService(newChanges.serviceType) ? newChanges.recurrenceFrequency : '',
      recurrenceIds: isRecurringService(newChanges.serviceType) ? newChanges.recurrenceIds.map(filter => filter.id) : [],
      businessLines: get(newChanges, 'businessLines', []).map(filter => get(filter, 'businessLineName')),
      measureIds: get(newChanges, 'measureIds', []).map(filter => filter.id),
      materialIds: get(newChanges, 'materialIds', []).map(filter => filter.id),
      serviceMethodIds: get(newChanges, 'serviceMethodIds', []).map(filter => filter.id),
      serviceActionIds: get(newChanges, 'serviceActionIds', []).map(filter => filter.id),
      pricingZoneId: get(newChanges, 'pricingZoneId', ''),
      search: get(newChanges, 'search', ''),
      requestedPage: INITIAL_PAGE,
      requestedPageSize: isServiceGroup({ type: searchType }) ? ROWS_PER_PAGE : MAX_ROWS,
    }

    dispatch(getPricingServiceSearchResults(payload, () => setLocalState({ isLoading: false })))
  }

  const handleDelayPricingServiceSearch = useCallback(
    debounce((key, value, newChanges) => {
      handlePricingServiceSearch(newChanges)
    }, DEBOUNCE_TIME),
    []
  )

  const handleTaxZoneChange = (key, value) => {
    const onlyFees = feeTaxList.filter(feeTax => feeTax.type !== T.TAX)
    const taxList = getTaxList(
      feeTaxList,
      get(pricedServiceDetails, 'taxList', []),
      get(pricedServiceDetails, 'generalFeeList', []),
      get(pricedServiceDetails, 'disposableFeeList', [])
    )
    setLocalState({ [key]: value, feeTaxList: cloneDeep(onlyFees.concat(taxList)) })
  }

  const getNewPricingServiceState = res => {
    const transformResponse = transformMultiplePricedServicesToSingle(res, pricedServiceId)

    const generalFee = getServiceFee('General Fee', get(transformResponse, 'generalFeeList', []))
    const disposalFee = getServiceFee('Disposal Fee', get(transformResponse, 'disposableFeeList', []))
    const allFees = generalFee.concat(disposalFee)

    const taxList = getTaxList(
      feeTaxList,
      get(transformResponse, 'taxList', []),
      get(transformResponse, 'generalFeeList', []),
      get(transformResponse, 'disposableFeeList', [])
    )

    const allFeesAndTaxes = allFees.concat(taxList)
    const newSchedule = {
      ...schedule,
      startDate: formatDateToBEFormatDateFns(new Date()),
      endDate: null,
      serviceEvents: [],
    }

    const newServicetype = get(transformResponse, 'serviceType', '')

    if (isRecurringService(newServicetype) && isNonWeeklyRecurrence(get(transformResponse, 'recurrenceInterval', ''))) {
      set(newSchedule, 'serviceEvents', [{ dayOfTheWeek: T.MONDAY, eventOccurence: 1 }])
    }

    if (isRecurringService(newServicetype) && isDailyRecurrence(get(transformResponse, 'recurrenceInterval', ''))) {
      set(newSchedule, 'serviceEvents', [{}])
    }

    if (isOnRequestService(newServicetype)) {
      // Add default action here
      set(newSchedule, 'startDate', null)
      set(newSchedule, 'defaultActionType', get(transformResponse, 'defaultActionType', ''))
      set(newSchedule, 'serviceEvents', [{ actionDate: '', actionType: get(transformResponse, 'serviceActionType', '') }])
    }

    return {
      feeTaxesActiveTab: T.FEES,
      eventInstanceType: T.DAY_OF_WEEK,
      pricedServiceDetails: transformResponse,
      serviceType: newServicetype,
      price: parseFloat(get(transformResponse, 'value', 0)).toFixed(2),
      taxZoneId: get(
        taxZonesMeta.find(zone => zone.default),
        'key',
        ''
      ),
      schedule: newSchedule,
      billingSchedule: cloneDeep(get(SERVICE_MODEL, 'billingSchedule', {})),
      feeTaxList: allFeesAndTaxes,
      tags: [],
      advanced: cloneDeep(get(SERVICE_MODEL, 'advanced', {})),
    }
  }

  const handlePricingServiceIdChange = tabId => {
    if (!pricedServiceId) {
      return
    }

    if (pricedServiceId === get(pricedServiceDetails, 'id')) {
      setLocalState({ activeTab: tabId })
      return
    }

    dispatch(
      getPricingServiceDetail(
        { type: service.searchType, pricedServiceId, serviceGroupId: pricedServiceId, accountId },
        (apiStatus, res) => {
          // Handle error case
          setLocalState({ ...getNewPricingServiceState(res), activeTab: tabId })
        }
      )
    )
  }

  const handleChange = (key, value, parentKey = '', dependentField = '', dependentFieldValue = '', isInitial = false) => {
    if (!parentKey) {
      setLocalState({ [key]: value, isDirty: true, [dependentField]: dependentFieldValue })
      return
    }

    if (key === 'permitContainerCount' && !isNumberOnly(value)) return
    if (key === 'permitNote' && !isAlphaNumeric(value)) return

    let dependentFieldChange = {}

    if (dependentField === 'map') {
      // add lat long
      const address = get(
        activeLocations.find(loc => loc.id === value),
        'address',
        {}
      )

      dependentFieldChange = {
        latitude: get(address, 'latitude', 0),
        longitude: get(address, 'longitude', 0),
        locationName: combineAddressInfo(address),
      }
    }

    if (dependentField === 'filters') {
      dependentFieldChange = {
        measureIds: [],
        materialIds: [],
        serviceMethodIds: [],
        serviceActionIds: [],
      }
    }

    if (['startDate', 'endDate'].includes(key)) {
      setLocalState({
        [parentKey]: {
          ...get(localState, parentKey),
          [key]: value,
          [dependentField]: dependentFieldValue,
        },
        isDirty: true,
      })
      return
    }

    const isNewSearch = FILTER_CHANGE_KEY.includes(key)

    const newChanges = {
      [parentKey]: { ...get(localState, parentKey), [key]: value, ...dependentFieldChange },
      // avoid isDirty initially -- useEffect location change
      // eslint-disable-next-line no-unneeded-ternary
      isDirty: isInitial ? false : true,
      isLoading: isInitial ? true : isNewSearch,
      pricedServiceId: isNewSearch ? '' : pricedServiceId,
      pricedServiceDetails: isNewSearch ? [] : pricedServiceDetails,
    }
    setLocalState({ ...newChanges })

    // Call pricing search API
    if (isNewSearch) {
      handleDelayPricingServiceSearch(key, value, newChanges.service)
    }
  }

  const handleMapChange = (lng, lat) => {
    setLocalState({ location: { ...location, longitude: lng, latitude: lat }, isDirty: true })
  }

  const handleContainerChange = (newContainers, deletedContainerIds = get(advanced, 'deletedContainerIds', [])) => {
    setLocalState({ advanced: { ...advanced, containerList: newContainers, deletedContainerIds }, isDirty: true })
  }

  const handleFeeChange = (key, value, feeTaxId, serviceId) => {
    const newFeeList = feeTaxList.map(feeTax =>
      feeTax.serviceId === serviceId && feeTax.feeTaxId === feeTaxId && feeTax.parentType === 'Fee' ? { ...feeTax, [key]: value } : feeTax
    )

    setLocalState({
      feeTaxList: key === 'linked' ? getTaxOnFeeLinking({ feeTaxList: newFeeList, feeId: feeTaxId, serviceId, value }) : newFeeList,
      isDirty: true,
    })
  }

  const handleTaxChange = (taxId, appliedOnId, serviceId) =>
    setLocalState({
      feeTaxList: getTaxLinking({ taxZoneId, feeTaxList, taxId, appliedOnId, serviceId }),
      isDirty: true,
    })

  const prefillCSInfo = () => {
    const getPricingServiceData = getNewPricingServiceState(get(existingDetails, 'priceServiceDetail', {}))
    const permitId = get(existingDetails, 'advanced.permitId', '')
    const permitLocation = get(existingDetails, 'advanced.permitLocation', '')
    const permitContainerCount = get(existingDetails, 'advanced.permitContainerCount', '')
    const permitStartDate = get(existingDetails, 'advanced.permitStartDate', '')
    const permitEndDate = get(existingDetails, 'advanced.permitEndDate', '')
    const permitNote = get(existingDetails, 'advanced.permitNote', '')
    const permit = get(existingDetails, 'advanced.permit', '')
    const newState = {
      status: get(existingDetails, 'status', 'Active'),
      pricedServiceDetails: get(getPricingServiceData, 'pricedServiceDetails', {}),
      pricedServiceId: get(existingDetails, 'priceServiceDetail.id', ''),
      serviceType: get(existingDetails, 'priceServiceDetail.serviceType', ''),
      tags: get(existingDetails, 'tagDetails.forService', []),
      taxZoneId: get(existingDetails, 'taxZoneId', ''),
      price: parseFloat(get(existingDetails, 'price', 0)).toFixed(2),
      schedule: get(existingDetails, 'schedule', {}),
      billingSchedule: {
        ...get(existingDetails, 'billingSchedule', {}),
        isDifferentBillingSchedule: !!get(existingDetails, 'billingSchedule.startDate'),
      },
      feeTaxList: get(getPricingServiceData, 'feeTaxList', []),
      location: get(existingDetails, 'location', {}),
      advanced: {
        ...get(existingDetails, 'advanced', {}),
        routeNote: get(existingDetails, 'advanced.routeNoteText', '') !== '',
        container: true,
        minimumBillingPeriods: get(existingDetails, 'advanced.minimumBillingPeriodsNumber', 0) > 0,
        signatureRequired: get(existingDetails, 'advanced.signatureType', '') !== '',
        skillsRequired: get(existingDetails, 'advanced.skillId', '') !== '',
        purchaseOrder:
          get(existingDetails, 'advanced.purchaseOrderNumber', '') !== '' ||
          get(existingDetails, 'advanced.maximumPurchaseOrderAmount', '') !== '',
        permitId,
        permitLocation,
        permitContainerCount,
        permitStartDate,
        permitEndDate,
        permitNote,
        permit: !!(permitId && permitLocation && permitContainerCount && permitStartDate),
      },
    }

    const existingAddress = get(
      allLocations.find(loc => loc.id === newState.location.locationId),
      'address',
      {}
    )

    set(newState, 'location.locationName', combineAddressInfo(existingAddress))

    if (isRecurringService(newState.serviceType) && isNonWeeklyRecurrence(get(newState, 'pricedServiceDetails.recurrenceInterval'))) {
      const eventDayOftheWeek = get(newState, 'schedule.serviceEvents[0].dayOfTheWeek', '')
      set(newState, 'eventInstanceType', eventDayOftheWeek ? T.DAY_OF_WEEK : T.SPECIFIC_DATE)
    }

    if (isOnRequestService(newState.serviceType) && !get(newState, 'schedule.defaultActionType')) {
      // Edit service - add default action from priced service if empty
      set(newState, 'schedule.defaultActionType', get(newState, 'pricedServiceDetails.serviceActionType', ''))
    }

    if (newState.feeTaxList.length) {
      const existingFeeTax = get(existingDetails, 'feeTaxList', [])
      const inActiveFeeList = existingFeeTax.filter(feeTax => feeTax.type !== T.TAX && !feeTax.linked).map(feeTax => feeTax.feeTaxId)

      const linkFeeTax = newState.feeTaxList.map(feeTax => {
        const { feeTaxId } = feeTax
        const isExist = existingFeeTax.find(
          existing => existing.feeTaxId === feeTaxId && get(existing, 'feeId', null) === get(feeTax, 'feeId', null)
        )

        if (isExist) {
          return {
            ...feeTax,
            id: isExist.id,
            linked: isExist.linked,
            visible: isExist.feeId ? !inActiveFeeList.includes(isExist.feeId) : true,
            orignalPrice: feeTax.price,
            price: parseFloat(get(isExist, 'price', 0)).toFixed(2),
          }
        }
        return { ...feeTax, orignalPrice: feeTax.price }
      })

      set(newState, 'feeTaxList', linkFeeTax)
    }

    setLocalState(newState)
  }

  const handleFeeTaxesUpdation = payload => {
    // Service Id check not required because updation will be independent
    const existingFeeTax = get(existingDetails, 'feeTaxList', [])
    const deletedFeeTaxIds = []
    const linkFeeTax = payload.feeTaxList.flatMap(feeTax => {
      // Delete Inactive Fee if price not changed
      if (feeTax.type !== 'Tax' && feeTax.id && feeTax.linked && parseFloat(feeTax.price) === parseFloat(feeTax.orignalPrice)) {
        deletedFeeTaxIds.push(feeTax.id)
        return []
      }

      // Delete Inactive tax, feeTax.id may or may not exist because when we change taxzone it will take new values from pricing service
      if (feeTax.type === 'Tax') {
        const isExist = existingFeeTax.find(
          existing => existing.feeTaxId === feeTax.feeTaxId && get(existing, 'feeId', null) === get(feeTax, 'feeId', null)
        )

        // new case
        if (!isExist) {
          return { ...feeTax }
        }

        if (feeTax.linked) {
          // delete case
          deletedFeeTaxIds.push(isExist.id)
          return []
        }

        // update case
        return { ...feeTax, id: isExist.id }
      }

      return { ...feeTax }
    })

    return { linkFeeTax, deletedFeeTaxIds }
  }

  const handleCreateUpdateServiceSuccess = () => {
    onClose()
    onRefresh()
  }

  const handleSaveService = (serviceStatus = '') => {
    if (isNextStepBtnDisabled || isFormInvalid) {
      return
    }
    const { permitObjArray, permitLocation, permitContainerCount, permitStartDate, permitEndDate, permitNote, permit, ...advancedRest } =
      advanced
    const payload = {
      type: service.searchType,
      accountId,
      status: serviceStatus,
      serviceType,
      taxZoneId,
      tags: tags.map(tag => ({ id: tag.id })),
      price: price ? parseFloat(price) : 0,
      pricedServiceId,
      schedule,
      billingSchedule,
      feeTaxList: feeTaxList.map(feeTax => {
        const rowTaxZoneId = get(feeTax, 'taxZoneId', '')
        if (feeTax.type === T.TAX && rowTaxZoneId !== taxZoneId) {
          // autoLink false for all the extra taxZones
          return { ...feeTax, linked: false }
        }

        return { ...feeTax }
      }),
      location,
      advanced: advancedRest,
    }

    if (permit) {
      set(payload, 'advanced.permitId', get(permitObjArray, '[0].id', ''))
      set(payload, 'advanced.permitLocation', permitLocation)
      set(payload, 'advanced.permitContainerCount', permitContainerCount)
      set(payload, 'advanced.permitStartDate', permitStartDate)
      set(payload, 'advanced.permitEndDate', permitEndDate)
      set(payload, 'advanced.permitNote', permitNote)
    } else {
      set(payload, 'advanced.permitId', undefined)
      set(payload, 'advanced.permitLocation', undefined)
      set(payload, 'advanced.permitContainerCount', undefined)
      set(payload, 'advanced.permitStartDate', undefined)
      set(payload, 'advanced.permitEndDate', undefined)
      set(payload, 'advanced.permitNote', undefined)
    }

    const existingCSId = get(existingDetails, 'configuredServiceId')
    if (existingCSId && !isAddMode) {
      set(payload, 'configuredServiceId', existingCSId)
    }

    // Only in edit mode
    if (!isAddMode && payload.feeTaxList.length) {
      const { linkFeeTax, deletedFeeTaxIds } = handleFeeTaxesUpdation(payload)

      set(payload, 'feeTaxList', linkFeeTax)
      set(payload, 'deletedFeeTaxIds', deletedFeeTaxIds)
    }

    if (!isAddMode && !get(payload, 'advanced.deactivate', false)) {
      // For edit mode, if not deactivated then status is active
      set(payload, 'status', 'Active')
    }

    dispatch(putIsLoading(true))
    if (isServiceGroupSelected) {
      // Bulk creation
      const bulkPayload = createBulkServices(payload, pricedServiceDetails)
      createBulkConfiguredService(bulkPayload)
        .unwrap()
        .then(handleCreateUpdateServiceSuccess)
        .catch(handleError)
        .finally(() => dispatch(putIsLoading(false)))
      return
    }

    // Single service creation
    createUpdateConfiguredService(payload)
      .unwrap()
      .then(handleCreateUpdateServiceSuccess)
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useGetTagsMetadataQuery()

  useEffect(() => {
    if (!isAddMode) {
      // For edit mode prefill all the info
      prefillCSInfo()
      getServiceEvents({ configuredServiceId: get(existingDetails, 'configuredServiceId'), transformResponse: true })
      return
    }

    handlePricingServiceSearch({})

    if (getFilters) {
      dispatch(getGlobalFilterOptions())
    }

    if (locationId) {
      handleChange('locationId', locationId, 'location', 'map', '', true)
      return
    }

    // For one location directly prepopluate that
    if (accountId && activeLocations.length === 1) {
      handleChange('locationId', get(activeLocations, '[0].id'), 'location', 'map', '', true)
      return
    }

    setLocalState({ isLoading: true })
  }, [])

  const fetchAffectedWO = () => {
    const payload = {
      configuredServiceEventIds: get(schedule, 'deletedServiceEventIds', []),
    }

    getServiceAffectedWO(payload)
  }

  return (
    <div>
      <CommonDrawer
        isOpen={isOpenDrawer}
        className="common-drawer-container cs-service-drawer cs-service-common-classes"
        onChange={(event, isOpen) => onClose(isOpen)}
      >
        <section className={addFormDirtyClass(isDirty)}>
          <HHDialogTitle
            TitleTypographyProps={{
              variant: 'h6',
            }}
            icon={
              <SvgIcon>
                <TruckIcon />
              </SvgIcon>
            }
            title={isAddMode ? T.NEW_SERVICE : T.EDIT_SERVICE}
            onClose={() => onClose(false)}
          />
          <Divider />
          <Stack className="content" flexDirection="row" sx={{ height: '100%' }}>
            <Box flex={1} className={`left ${activeTab === MAP_TAB_ID ? 'map-tab-open' : ''}`}>
              <div className="left-content transparent-scroll common-padding-lr-3-5">
                <Box className="service-form-tabs" mt={3}>
                  <Tabs
                    className="all-tabs-wrapper"
                    value={activeTab}
                    onChange={(event, value) => {
                      if (isAddMode) {
                        handlePricingServiceIdChange(value)
                        return
                      }

                      setLocalState({ activeTab: value })
                    }}
                    indicatorColor="primary"
                    orientation="vertical"
                    variant="scrollable"
                  >
                    <Tab label={T.SERVICE} />
                    <Tab label={T.SCHEDULE} disabled={isNextStepBtnDisabled} />
                    <Tab label={T.FEE_AND_TAXES} disabled={isNextStepBtnDisabled} />
                    <Tab label={T.MAP} disabled={isNextStepBtnDisabled} />
                    <Tab label={T.ADVANCED} disabled={isNextStepBtnDisabled} />
                  </Tabs>
                </Box>

                <Divider sx={{ mt: 4, mb: 3 }} />

                <Box className="fixed-content" mb={2}>
                  {/* Just hiding it, sometimes autocomplete does not work with re rerendering activeTab === SERVICE_TAB_ID && ()  */}
                  <Box display={isAddMode && activeTab === SERVICE_TAB_ID ? 'box' : 'none'} className="service-filters-content">
                    <ServiceTabFilterContent
                      service={service}
                      measureMeta={measureMeta}
                      materialsMeta={materialsMeta}
                      methodsMeta={methodsMeta}
                      serviceActionMeta={serviceActionMeta}
                      globalFilterOptions={globalFilterOptions}
                      onChange={handleChange}
                    />
                  </Box>

                  <Box
                    display={[SCHEDULE_TAB_ID, FEE_TAXES_TAB_ID, ADVANCED_TAB_ID].includes(activeTab) || !isAddMode ? 'box' : 'none'}
                    className="other-tabs-content"
                  >
                    {/* Only show with taxes tab */}
                    {activeTab === FEE_TAXES_TAB_ID && feeTaxesActiveTab === T.TAXES && (
                      <Box mb={3}>
                        <CommonTextLabel title={T.TAX_ZONE} className="ttc" />

                        <CommonSelect
                          className="tax-zone common-service-select"
                          placeholder={`Select ${T.TAX_ZONE}`}
                          placeholderHidden
                          value={taxZoneId}
                          options={taxZonesMeta}
                          onChange={e => handleTaxZoneChange('taxZoneId', e.target.value)}
                        />
                      </Box>
                    )}

                    <CustomBox>
                      <TagsSelect
                        tags={tagsMeta}
                        selectedTags={tags}
                        label={`${T.SERVICE} ${T.TAGS}`}
                        onChange={(e, selectedOptions) => handleChange('tags', selectedOptions)}
                        limit={1}
                      />
                    </CustomBox>

                    <Box mt={3}>
                      <CommonTextLabel title={T.PRICE} className="ttc" />
                      <NumericFormat
                        disabled={isServiceGroupSelected}
                        className="common textfield no-outline"
                        placeholder="$0.00"
                        value={isServiceGroupSelected ? getServiceGroupPrice(serviceEvents) : price}
                        autoComplete="off"
                        allowNegative={false}
                        onValueChange={values => {
                          const { value } = values
                          handleChange('price', value)
                        }}
                        decimalSeparator="."
                        decimalScale={2}
                        prefix="$"
                        thousandSeparator
                        customInput={CommonTextfield}
                      />
                    </Box>
                    <Box mt={2}>
                      <CommonTextLabel title={T.PRICING_PERIOD} className="ttc" />
                      <div className="pricing-period">
                        {getPricingPeriod(get(pricedServiceDetails, 'periodPer', ''), get(pricedServiceDetails, 'periodInterval', ''))}
                      </div>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>

            <div className={`right ${activeTab === MAP_TAB_ID ? 'map-tab-open' : ''}`}>
              <div className="right-content">
                <div className="service-tabs-content">
                  <TabPanel value={activeTab} index={SERVICE_TAB_ID} className="tabpanel-body">
                    {isAddMode && (
                      <ServiceTabAddContent
                        isLoading={isLoading}
                        pricedServiceId={pricedServiceId}
                        service={service}
                        selectedLocation={location}
                        allLocations={locationsSelectDropdown}
                        pricingZonesMeta={pricingZonesMeta}
                        pricedServices={pricedServices}
                        onChange={handleChange}
                      />
                    )}

                    {/* For edit mode, we only show service data in disabled boxes */}
                    {!isAddMode && (
                      <ServiceTabEditContent
                        existingEvents={existingEvents}
                        schedule={schedule}
                        serviceType={serviceType}
                        isServiceActivated={get(advanced, 'deactivate', false)}
                        pricedServiceDetails={pricedServiceDetails}
                        selectedLocation={location}
                        onChange={handleChange}
                      />
                    )}
                  </TabPanel>

                  <TabPanel value={activeTab} index={SCHEDULE_TAB_ID} className="tabpanel-body">
                    <ScheduleTabContent
                      isGroupSelected={service.searchType === SERVICE_GROUP}
                      isAddMode={isAddMode}
                      serviceType={serviceType}
                      pricedServiceDetails={pricedServiceDetails}
                      eventInstanceType={eventInstanceType}
                      schedule={schedule}
                      billingSchedule={billingSchedule}
                      serviceActionAllMeta={serviceActionAllMeta}
                      onChange={handleChange}
                    />
                  </TabPanel>

                  <TabPanel value={activeTab} index={FEE_TAXES_TAB_ID} className="tabpanel-body">
                    <FeesTaxesTabContent
                      feeTaxesActiveTab={feeTaxesActiveTab}
                      taxZoneId={taxZoneId}
                      feeTaxList={feeTaxList}
                      pricedServiceDetails={pricedServiceDetails}
                      onFeeChange={handleFeeChange}
                      onTaxChange={handleTaxChange}
                      onChange={handleChange}
                    />
                  </TabPanel>

                  <TabPanel value={activeTab} index={MAP_TAB_ID} className="tabpanel-body">
                    <MapTabContent activeTab={activeTab} selectedLocation={location} onChange={handleMapChange} />
                  </TabPanel>

                  <TabPanel value={activeTab} index={ADVANCED_TAB_ID} className="tabpanel-body">
                    <AdvancedTabContent
                      serviceType={serviceType}
                      advanced={advanced}
                      skillsMeta={skillsMeta}
                      onContainerChange={handleContainerChange}
                      onChange={handleChange}
                    />
                  </TabPanel>
                </div>
              </div>

              <div className="footer fixed bg-white common-padding-lr-3-5">
                <Stack flexDirection="row" justifyContent="space-between">
                  <FooterButton
                    leftButtonTitle={T.CANCEL}
                    rightButtonTitle={(() => {
                      if (isAddMode) {
                        return activeTab === SERVICE_TAB_ID ? T.NEXT_STEP : T.CREATE
                      }
                      return T.SAVE_CHANGES
                    })()}
                    disabledProceed={activeTab === SERVICE_TAB_ID && isAddMode ? isNextStepBtnDisabled : isFormInvalid || !isPermitValid}
                    disabledBack={isNextStepBtnDisabled || isFormInvalid}
                    isProceedButtonMUIType
                    tooltipTitle={tooltipTitle}
                    onClose={() => onClose(false)}
                    onProceed={() => {
                      if (isAddMode && activeTab === SERVICE_TAB_ID) {
                        handlePricingServiceIdChange(SCHEDULE_TAB_ID)
                        return
                      }

                      if (
                        !isAddMode &&
                        activeTab === SCHEDULE_TAB_ID &&
                        compact(serviceEvents.map(i => i.id)).length < serviceEvents.length
                      ) {
                        fetchAffectedWO()
                        setIsOpenConfirmDaysDrawer(true)
                        return
                      }

                      // Call Save API with Active or Draft status
                      handleSaveService(status)
                    }}
                  />
                </Stack>
              </div>
            </div>
          </Stack>
        </section>
      </CommonDrawer>

      <ConfirmServiceDays
        isOpenDrawer={isOpenConfirmDaysDrawer}
        workOrders={workOrders}
        accountName={get(existingDetails, 'accountName', '')}
        isLoading={isWOLoading}
        serviceEvents={serviceEvents}
        startDate={get(schedule, 'startDate', '')}
        handleProceed={() => {
          handleSaveService(status)
        }}
        onClose={isOpen => {
          if (isOpen) {
            onClose()
            return
          }
          setIsOpenConfirmDaysDrawer(isOpen)
        }}
      />
    </div>
  )
}

ServiceDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
  locationId: PropTypes.string,
  getFilters: PropTypes.bool,
  isAddMode: PropTypes.bool,
  existingDetails: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ServiceDrawer
