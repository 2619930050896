import React from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import { get } from 'utils/lodash'

import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import PaymentGroupDetailsHeader from 'components/billing/groups/payment/details/PaymentGroupDetailsHeader'
import PaymentGroupList from 'components/billing/groups/payment/details/PaymentGroupList'
import ChartSummaryDialog from 'components/billing/groups/payment/common/ChartSummaryDialog'

const PaymentGroupDetails = () => {
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  return (
    <Box height="100%" overflowY="hidden" minWidth="750px">
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <PaymentGroupDetailsHeader />
        <PaymentGroupList />
      </BillingLayout>
      <ChartSummaryDialog />
    </Box>
  )
}

export default PaymentGroupDetails
