import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'

import { Button, DialogContent, Alert, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useUpdateAccountMutation } from 'api/accounts/updateAccount'
import { addBillingPOAndTax } from 'utils/form-body'
import { CACHE_TAG_ACCOUNT_INVOICE_LIST, CACHE_TAG_ACCOUNT_PAYMENT_LIST, CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY } from 'api/cacheTagTypes'
import { getPOAndTaxInfo, getExistingCustomerInfo } from 'utils/customerPayload'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import BillingProfileDropdown from '../../common/billing-dropdown/index'

const EditBillingProfileDialog = ({ isOpen = false, customerDetails = {}, onClose }) => {
  const dispatch = useDispatch()
  const [updateAccount] = useUpdateAccountMutation()
  const [billingProfileId, setBillingProfileId] = useState('')

  const accountId = get(customerDetails, 'id')
  const accountBilling = get(customerDetails, 'accountBilling', {})
  const currentBillingProfileId = get(accountBilling, 'billingProfile.id')

  const handleUpdateBillingProfile = () => {
    dispatch(putIsLoading(true))
    const payload = {
      id: accountId,
      ...getExistingCustomerInfo(customerDetails),
      accountBilling: {
        billingProfileId,
        id: accountBilling.id,
        ...addBillingPOAndTax({ ...getPOAndTaxInfo(customerDetails) }),
      },
    }

    updateAccount(payload)
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
        dispatch(
          api.util.invalidateTags([CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY, CACHE_TAG_ACCOUNT_INVOICE_LIST, CACHE_TAG_ACCOUNT_PAYMENT_LIST])
        )
        toast.success('Billing profile updated successfully')
        onClose()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useEffect(() => {
    if (isOpen) {
      setBillingProfileId(currentBillingProfileId)
    }
  }, [isOpen])

  return (
    <HHBaseDialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      sx={{
        '& .MuiDialog-container .MuiPaper-root': {
          maxWidth: '625px',
        },
      }}
    >
      <HHDialogTitle title={capitalize(T.BILLING_PROFILE)} onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box mt={2}>
          <BillingProfileDropdown
            label={`${T.BILLING_PROFILE} *`}
            billingProfileId={billingProfileId}
            onChange={event => setBillingProfileId(event.target.value)}
          />
          {billingProfileId !== currentBillingProfileId && (
            <Alert severity="error" sx={{ mt: 2, alignItems: 'center' }}>
              Changing the billing profile will affect the current invoicing cycle
            </Alert>
          )}
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <Button
          disabled={!billingProfileId || billingProfileId === currentBillingProfileId}
          size="small"
          variant="contained"
          onClick={handleUpdateBillingProfile}
        >
          {T.SAVE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

EditBillingProfileDialog.propTypes = {
  isOpen: PropTypes.bool,
  customerDetails: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default EditBillingProfileDialog
