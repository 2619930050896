import React from 'react'
import PropTypes from 'prop-types'

import { orange } from '@mui/material/colors'
import { Box, Typography, Grid, Stack, Chip, Divider } from '@mui/material'

import { get } from 'utils/lodash'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { formatDateToFEFormat } from 'utils/date'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { PAYMENT_METHODS, PAYMENT_METHOD_IMAGES } from 'settings/constants/payment'
import { INVOICE_TYPE } from 'settings/constants/billing'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import { TOTAL_COLUMN_WIDTH, AMOUNT_APPLIED_COLUMN_WIDTH } from './settings'

const { ACCOUNT_CREDIT } = PAYMENT_METHODS
const { MANUAL } = INVOICE_TYPE

const InvoiceActivity = ({
  chipGridWidth = 100,
  billingProfileDetails = {},
  invoice,
  showAppliedColumn = false,
  align = 'center',
  marginY = 0,
}) => {
  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')

  const getBillingPeriodTitle = (invoiceType, invoiceStartDate, invoiceEndDate, invoiceDate) => {
    let getBillingCycle = []

    try {
      getBillingCycle = getBillingPeriods({
        billingProfile: billingProfileDetails,
        billingPeriods: [{ startDate: invoiceStartDate, endDate: invoiceEndDate, invoiceDate }],
      })
    } catch (error) {}

    const parsedInvoiceDate = transformDate(invoiceDate)
    return invoiceType === MANUAL ? parsedInvoiceDate : get(getBillingCycle, '[0].labelLeft', '-')
  }

  const { invoiceNumber, invoiceDate, dueDate, amountPaidCents, totalCents, status, startDate, endDate, type, paymentAppliedAmountCents } =
    invoice || {}
  const pendingAmount = totalCents - amountPaidCents
  const isAccountCreditType = invoice?.isAccountCredit
  const title = isAccountCreditType ? invoice?.title : getBillingPeriodTitle(type, startDate, endDate, invoiceDate)
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, dueDate, amountPaidCents, totalCents)

  return (
    <Grid container item xs={12} spacing={2.5} wrap="nowrap" alignItems={align}>
      <Grid item xs="auto" minWidth={chipGridWidth} my={marginY}>
        <Stack alignItems="flex-start" spacing={0.5}>
          <InvoiceStatusChip status={status} isPartial={isPartial} agingBucketType={agingBucketType} />
          {isPartial && agingBucketType && (
            <Typography color={AGING_BUCKET_COLORS[agingBucketType][700]} variant="caption" fontWeight={700}>
              <HHDisplayMoney value={pendingAmount} /> left
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs my={marginY}>
        {!isAccountCreditType && (
          <>
            <Typography variant="h6" fontWeight={500} color="textPrimary">
              {title}
              {type === MANUAL && <Chip label={T.MANUAL} size="small" sx={{ backgroundColor: orange[100], ml: 1, borderRadius: 1 }} />}
            </Typography>
            <Typography variant="caption">
              Invoice #: {invoiceNumber} // Invoice date: {transformDate(invoiceDate)}
            </Typography>
          </>
        )}
        {isAccountCreditType && (
          <>
            <Box display="flex" alignItems="center">
              <img src={PAYMENT_METHOD_IMAGES[ACCOUNT_CREDIT]} alt="" />
              <Stack ml={1}>
                <Typography display="inline" variant="h6" fontWeight={500}>
                  {title}
                </Typography>
              </Stack>
            </Box>
          </>
        )}
      </Grid>
      <Grid item width={TOTAL_COLUMN_WIDTH} my={marginY}>
        <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h6" fontWeight={500} color="textPrimary">
          <HHDisplayMoney value={totalCents} />
        </Typography>
        {!isAccountCreditType && (
          <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
            Due: {transformDate(dueDate)}
          </Typography>
        )}
      </Grid>
      {showAppliedColumn && (
        <>
          <Grid item xs="auto" display="flex">
            <Divider flexItem orientation="vertical" />
          </Grid>
          <Grid item width={AMOUNT_APPLIED_COLUMN_WIDTH} my={marginY}>
            <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h6" fontWeight={500} color="textPrimary">
              <HHDisplayMoney value={paymentAppliedAmountCents} />
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

InvoiceActivity.propTypes = {
  chipGridWidth: PropTypes.number,
  billingProfileDetails: PropTypes.object,
  invoice: PropTypes.object.isRequired,
  showAppliedColumn: PropTypes.bool,
  align: PropTypes.string,
  marginY: PropTypes.string,
}

export default InvoiceActivity
