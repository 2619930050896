import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { Button, Checkbox, Box, Typography, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { formatDateToBEFormatDateFns, createDateFromString } from 'utils/date'
import { getMetadataRoutes } from 'data/route/routeMetadataSelector'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHTextField from 'components/form-fields/v5/HHTextField'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'
import { clamp, isEmpty } from 'lodash'
import BulkStopConfirmation from './BulkStopConfirmation'
import { HHNumberField } from '../../components/form-fields/v5'

const EditRouteSelection = ({
  serviceDate,
  editRouteStops,
  currentRouteId = null,
  workOrders,
  handleSelectAll,
  isCheckAll,
  allWorkOrdersCount = 0,
  checkedStops,
  handleCancelEditRouteStops,
  handleUnassignRouteStops,
  showBulkAssignPanel,
  showBulkCompletePanel,
  handleBulkMoveStops,
  handleBulkCompleteApi,
  routeList,
}) => {
  const theme = useTheme()
  const allRoutes = useSelector(getMetadataRoutes)
  const [routeId, setRouteId] = useState()
  const [routeDate, setRouteDate] = useState()
  const [isOpenBulkStopConfirmation, setIsOpenBulkStopConfirmation] = useState(false)
  const [sequence, setSequence] = useState(1)

  const isBtnDisabled = !Array.isArray(checkedStops) || !checkedStops.length
  const serviceDateCast = createDateFromString(serviceDate)

  const getRouteName = id =>
    get(
      allRoutes.find(route => route.id === id),
      'name',
      ''
    )

  const handleBulkMoveConfirmation = () => {
    setIsOpenBulkStopConfirmation(false)
    let targetStopId
    if (sequence > 1) {
      const targetRoute = routeList.find(({ id }) => id === routeId)
      const numberToClamp = currentRouteId === routeId ? sequence - 1 : sequence - 2
      const targetStops = get(targetRoute, 'stops', [])
      const index = clamp(numberToClamp, 0, targetStops.length - 1)
      if (!isEmpty(targetStops)) {
        targetStopId = get(targetStops, `${index}.workOrderId`)
      }
    }
    handleBulkMoveStops(routeId, formatDateToBEFormatDateFns(routeDate), checkedStops, targetStopId)
  }

  const AddActionBtn = () => {
    return (
      <>
        {showBulkAssignPanel ? (
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={() => setIsOpenBulkStopConfirmation(true)}
            disabled={isBtnDisabled}
          >
            {T.MOVE}
          </Button>
        ) : (
          <Button size="small" variant="outlined" color="error" onClick={handleUnassignRouteStops} disabled={isBtnDisabled}>
            {T.UNASSIGN}
          </Button>
        )}
      </>
    )
  }

  const handleSequenceChange = e => {
    setSequence(e.target.value)
  }

  useEffect(() => {
    if (showBulkAssignPanel) {
      setRouteId(currentRouteId)
      setRouteDate(serviceDateCast)
    }
  }, [showBulkAssignPanel])

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: theme.palette.background.header,
      }}
    >
      {editRouteStops && currentRouteId === workOrders && (
        <>
          {showBulkAssignPanel && (
            <Box margin={2}>
              <Typography variant="h4" fontWeight={600} color="primary">
                {T.BULK_MOVE}

                <Box mt={2}>
                  <HHDesktopDatePicker disableScrollLock value={routeDate} onChange={date => setRouteDate(date)} />
                </Box>

                <HHTextField
                  sx={{ mt: 2 }}
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      PaperProps: {
                        sx: { maxHeight: 'calc(100vh - 385px)' },
                      },
                    },
                  }}
                  fullWidth
                  select
                  variant="outlined"
                  value={[routeId]}
                  onChange={event => setRouteId(event.target.value)}
                >
                  {allRoutes.map(val => {
                    return (
                      <SelectItem value={val.id}>
                        <Typography noWrap>{val.name}</Typography>
                      </SelectItem>
                    )
                  })}
                </HHTextField>
                <HHNumberField
                  onChange={handleSequenceChange}
                  sx={{ mt: 2 }}
                  label={T.SEQUENCE_POSITION}
                  deprecatedLabel={false}
                  fullWidth
                  value={sequence}
                />
              </Typography>
            </Box>
          )}

          <Box
            component="div"
            sx={{
              margin: '0 16px 0 10px',
              color: theme.palette.primary.main,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <div>
              <Checkbox
                sx={{
                  padding: '9px 6px',
                  color: theme.palette.primary.main,
                }}
                type="checkbox"
                name="selectAll"
                id={workOrders}
                onChange={handleSelectAll}
                checked={isCheckAll}
                indeterminate={!isCheckAll && checkedStops.length !== 0 && allWorkOrdersCount}
              />
              {checkedStops && `${checkedStops.length} ${T.SELECTED}`}
            </div>
            <div className="flex">
              <Button sx={{ mr: 1 }} variant="outlined" size="small" onClick={handleCancelEditRouteStops}>
                {T.CANCEL}
              </Button>
              {showBulkCompletePanel ? (
                <Button size="small" variant="contained" color="success" onClick={handleBulkCompleteApi} disabled={isBtnDisabled}>
                  {T.COMPLETE}
                </Button>
              ) : (
                <AddActionBtn />
              )}
            </div>
          </Box>
        </>
      )}
      <BulkStopConfirmation
        isOpen={isOpenBulkStopConfirmation}
        totalStops={checkedStops.length}
        currentDate={serviceDate}
        newDate={routeDate}
        currentRoute={getRouteName(currentRouteId)}
        newRoute={getRouteName(routeId)}
        onClose={() => setIsOpenBulkStopConfirmation(false)}
        onConfirm={handleBulkMoveConfirmation}
      />
    </Box>
  )
}

EditRouteSelection.propTypes = {
  serviceDate: PropTypes.string,
  editRouteStops: PropTypes.object.isRequired,
  currentRouteId: PropTypes.string,
  workOrders: PropTypes.string.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  isCheckAll: PropTypes.bool.isRequired,
  allWorkOrdersCount: PropTypes.number,
  checkedStops: PropTypes.array,
  handleCancelEditRouteStops: PropTypes.func.isRequired,
  handleUnassignRouteStops: PropTypes.func.isRequired,
  showBulkAssignPanel: PropTypes.bool.isRequired,
  showBulkCompletePanel: PropTypes.bool.isRequired,
  handleBulkMoveStops: PropTypes.func.isRequired,
  openRoutesMenu: PropTypes.bool,
  handleBulkCompleteApi: PropTypes.func.isRequired,
  routeList: PropTypes.array.isRequired,
}

export default EditRouteSelection
