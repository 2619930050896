import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import { NOTE_TYPES } from 'settings/constants/note'
import {
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
  usePatchWorkOrderNoteMutation,
  useDeleteWorkOrderNoteMutation,
  useLazyGetWorkOrderNoteHistoryQuery,
} from 'api/notes/workOrderNotesCrud'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import NoteListItem from '../common/NoteListItem'
import NoteActionMenu from '../common/NoteActionMenu'
import ViewNoteDialog from '../common/ViewNoteDialog'

const { WORK_ORDER } = NOTE_TYPES

const WONotes = ({ notes = [], workOrderId = '', noNotesText = T.NO_NOTES_V2, sx, itemSx }) => {
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewNoteDialog, setIsOpenViewNoteDialog] = useState(null)
  const [isOpenNoteActionMenu, setIsOpenNoteActionMenu] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const hasNotes = notes.length > 0

  const handleOpenUpdateWONote = () => setIsOpenAddUpdateNoteDialog(true)

  const handleOpenViewWONoteDialog = note => {
    setSelectedNote(note)
    setIsOpenViewNoteDialog(true)
  }

  const handleCloseViewWONoteDialog = () => {
    setSelectedNote(null)
    setIsOpenViewNoteDialog(null)
  }

  const handleOpenWONoteActionMenu = (event, note) => {
    event.stopPropagation()
    setSelectedNote(note)
    setIsOpenNoteActionMenu(event.currentTarget)
  }

  const handleCloseWONoteActionMenu = () => setIsOpenNoteActionMenu(null)

  const handleCloseAddUpdateWONote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  return (
    <>
      {!hasNotes && <HHSectionPlaceholder title={noNotesText} />}
      {hasNotes && (
        <List sx={{ py: 0, ...sx }}>
          {Children.toArray(
            notes.map((note, index) => (
              <NoteListItem
                sx={itemSx}
                type={WORK_ORDER}
                note={note}
                showDivider={notes.length - 1 !== index}
                onViewClick={() => handleOpenViewWONoteDialog(note)}
                onActionMenuClick={event => handleOpenWONoteActionMenu(event, note)}
              />
            ))
          )}
        </List>
      )}
      <AddUpdateNoteDialog
        id={workOrderId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.WORK_ORDER_NOTE : T.NEW_WORK_ORDER_NOTE}
        existingNote={selectedNote}
        useCreateNoteMutation={useCreateWorkOrderNoteMutation}
        useUpdateNoteMutation={useUpdateWorkOrderNoteNewMutation}
        onClose={handleCloseAddUpdateWONote}
      />
      <ViewNoteDialog
        id={workOrderId}
        isOpen={isOpenViewNoteDialog}
        title={T.WORK_ORDER_NOTE}
        selectedNote={selectedNote}
        useLazyGetNoteHistoryQuery={useLazyGetWorkOrderNoteHistoryQuery}
        onClose={handleCloseViewWONoteDialog}
      />
      <NoteActionMenu
        id={workOrderId}
        anchorEl={isOpenNoteActionMenu}
        selectedNote={selectedNote}
        onEditClick={handleOpenUpdateWONote}
        usePatchNoteMutation={usePatchWorkOrderNoteMutation}
        useDeleteNoteMutation={useDeleteWorkOrderNoteMutation}
        onClose={handleCloseWONoteActionMenu}
      />
    </>
  )
}

WONotes.propTypes = {
  noNotesText: PropTypes.string,
  notes: PropTypes.array,
  workOrderId: PropTypes.string,
  sx: PropTypes.object,
  itemSx: PropTypes.object,
}

export default WONotes
