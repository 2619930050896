import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { DialogContent } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import { HHFormPriceField } from 'components/form-fields/v5'
import { get, noop } from 'lodash'
import { useForm } from 'react-hook-form'
import { formatCentsToDollars, formatDollarsToCents } from 'utils/price'
import SaveButton from 'components/buttons/SaveButton'
import CancelButton from 'components/buttons/CancelButton'
import T from 'T'
import { useUpdateCreditLimitMutation } from 'api/accounts/getAccountDetails'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useDispatch } from 'react-redux'
import { handleError } from 'utils/error'

const CreditLimitEditDialog = ({ open, onClose = noop, customerDetails }) => {
  const creditLimitCents = get(customerDetails, 'creditLimitCents', 0)
  const [updateCreditLimit, { isLoading }] = useUpdateCreditLimitMutation()
  const accountId = get(customerDetails, 'id')
  const dispatch = useDispatch()
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      creditLimit: formatCentsToDollars(creditLimitCents),
    },
  })

  const handleSave = data => {
    const creditLimit = get(data, 'creditLimit', 0)
    updateCreditLimit({
      creditLimitCents: formatDollarsToCents(creditLimit),
      accountId,
    })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
      })
      .catch(handleError)
    onClose()
  }

  useEffect(() => {
    if (open && accountId) {
      reset({
        creditLimit: formatCentsToDollars(creditLimitCents),
        accountId,
      })
    }
  }, [open, customerDetails, accountId])

  return (
    <HHBaseDialog onClose={onClose} open={open} maxWidth="xs" fullWidth>
      <HHDialogTitle onClose={onClose} title={T.CREDIT_LIMIT} />
      <DialogContent>
        <HHFormPriceField
          sx={{
            mt: 0.8,
          }}
          name="creditLimit"
          control={control}
          fullWidth
        />
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton disabled={isLoading} loading={isLoading} onClick={handleSubmit(handleSave)} />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

CreditLimitEditDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  customerDetails: PropTypes.object,
}

export default CreditLimitEditDialog
