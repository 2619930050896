import React from 'react'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'
import AccountGroupDetails from 'containers/customers/groups/account/details'

const AccountGroupsDetailsPage = () => {
  return (
    <ProtectedRouteHoC>
      <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
        <AccountGroupDetails />
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

export default AccountGroupsDetailsPage
