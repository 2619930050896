import React from 'react'

import RenderPaymentGroupCell from './RenderPaymentGroupCell'
import TopSection from './TopSection'

const RenderPaymentGroupWrapper = params => {
  const { id: rowId } = params

  if (rowId === 'header') {
    return <TopSection />
  }

  return <RenderPaymentGroupCell {...params} />
}

export default RenderPaymentGroupWrapper
