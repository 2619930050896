import React from 'react'
import PropTypes from 'prop-types'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton as MuiIconButton, Stack, styled } from '@mui/material'
import { FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 24,
  height: 24,
}))

const RouteManagerDatePicker = ({
  value,
  onChange,
  onPrevDate,
  onNextDate,
  inputFormat = FRONTEND_DATE_FORMAT_DATE_FNS,
  width = '9.3rem',
  readOnly = false,
  showArrows = true,
  DesktopDatePickerProps = {},
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
      {showArrows && (
        <IconButton disabled={readOnly} onClick={onPrevDate}>
          <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
        </IconButton>
      )}
      <HHDesktopDatePicker
        format={inputFormat}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        TextFieldProps={{ sx: { '.MuiInputBase-root': { width, borderRadius: '50px' } } }}
        {...DesktopDatePickerProps}
      />
      {showArrows && (
        <IconButton disabled={readOnly} onClick={onNextDate}>
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Stack>
  )
}

RouteManagerDatePicker.propTypes = {
  onPrevDate: PropTypes.func.isRequired,
  onNextDate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  inputFormat: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
  showArrows: PropTypes.bool,
  DesktopDatePickerProps: PropTypes.object,
}

export default RouteManagerDatePicker
