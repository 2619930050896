import { TAG_TYPE_ACCOUNT, TAG_TYPE_LOCATION, TAG_TYPE_SERVICE } from 'slices/tags/tagsSlice'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import get from 'lodash/get'

const TAG_TYPES_TO_ID_MAPPING = {
  [TAG_TYPE_ACCOUNT]: 'accountId',
  [TAG_TYPE_LOCATION]: 'locationId',
  [TAG_TYPE_SERVICE]: 'configuredServiceId',
}

export const getEnabledTypes = ({ disableAccount, disableLocation, disableService }) => [
  ...(!disableAccount ? [TAG_TYPE_ACCOUNT] : []),
  ...(!disableLocation ? [TAG_TYPE_LOCATION] : []),
  ...(!disableService ? [TAG_TYPE_SERVICE] : []),
]

// Group tags by tagType
export const getTagsGroupInfo = (tags = []) => {
  const tagsWithSingleEnabledGroup = tags || []
  return [...new Set(tagsWithSingleEnabledGroup.map(tag => tag.id))].map(id => ({
    id,
    ...tagsWithSingleEnabledGroup.find(currentTag => currentTag?.id === id),
    enabledGroups: tagsWithSingleEnabledGroup.filter(tag => tag.id === id).map(tag => tag.enabledGroups),
  }))
}

// adds the missing field parent Id to every tag group, takes the value from the container data object,
// if not found on the parent, it looks into the tags collection and takes the value from a tag object that has it
export const addParentIdToTagGroupTypes = (data = {}, tagGroups = {}) =>
  fromPairs(
    toPairs(tagGroups).map(([tagType, tags]) => [
      tagType,
      {
        parentId: get(data, TAG_TYPES_TO_ID_MAPPING[tagType], find(tags, ({ parentId }) => !isEmpty(parentId))?.parentId),
        tags,
      },
    ])
  )

// workaround until universal tags editing endpoint is available as well as selectedTagIds on configureService Objects
// we need to create a tagInfo property on the configureService Object containing all selected tag Ids
// this modifier will not be necessary once we get a normalized DTO
export const getNormalizedServiceObjectWithSelectedTagIds = (data = {}, allTagOptions = [], type = TAG_TYPE_ACCOUNT) => {
  const { tagDetails = [], id: parentId } = data || {}

  // FXME no need to get parentId from parent, new tag response has parentId
  const parentIdPropName = TAG_TYPES_TO_ID_MAPPING[type]

  // FXME no need to remap this, new tag response has the id
  const remappedTags = get(tagDetails, type, []).map(serviceTag => ({
    ...serviceTag,
    ...allTagOptions.find(({ tagName }) => tagName === serviceTag?.tagName),
  }))

  const selectedTagIds = remappedTags.map(({ id }) => id)

  return {
    ...data,
    [parentIdPropName]: parentId,
    tagInfo: [
      {
        parentId,
        tagId: selectedTagIds,
      },
    ],
    tags: remappedTags,
  }
}
