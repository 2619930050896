import React from 'react'
import PropTypes from 'prop-types'

import { memo } from 'utils/react'

import T from 'T'
import BasicModal from 'components/modal/BasicModal'

const ConfirmationModal = ({
  isOpen = false,
  title = '',
  className = '',
  hasCancelBtn = true,
  cancelButtonTitle = T.CANCEL,
  hasProceedBtn = true,
  proceedButtonTitle = T.PROCEED,
  onCancel,
  onProceed,
}) => (
  <BasicModal isOpen={isOpen} onClose={onCancel} className={className}>
    <div className="confirmation-modal">
      <div className="content">
        <h3>{title}</h3>
        <div className="action-buttons tr">
          {hasCancelBtn && <input type="button" className="cancel" value={cancelButtonTitle} onClick={onCancel} />}
          {hasProceedBtn && <input type="button" className="proceed" value={proceedButtonTitle} onClick={onProceed} />}
        </div>
      </div>
    </div>
  </BasicModal>
)

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  hasCancelBtn: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  hasProceedBtn: PropTypes.bool,
  proceedButtonTitle: PropTypes.string,
  onProceed: PropTypes.func.isRequired,
}

export default memo(ConfirmationModal)
