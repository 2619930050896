import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { blue } from '@mui/material/colors'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { getTimeZoneDetails, formatDateTimeToPreferedTimezone } from '../helpers/date'
import AgingInfoItem from './AgingInfoItem'

const AgingInfo = ({ invoice = {} }) => {
  const {
    currentCents = 0,
    dueOneThirtyCents = 0,
    dueThirtyOneSixtyCents = 0,
    dueSixtyOneNinetyCents = 0,
    dueOverNinetyCents = 0,
    customerCreditBalanceCents = 0,
  } = invoice.arSummary || {}
  const outstandingCents = currentCents + dueOneThirtyCents + dueThirtyOneSixtyCents + dueSixtyOneNinetyCents + dueOverNinetyCents
  const accountBalanceCents = outstandingCents - customerCreditBalanceCents

  const { zoneName, timeZone } = getTimeZoneDetails(invoice.business?.timeZone)
  const { fullDateTime: fileGeneratedAt } = formatDateTimeToPreferedTimezone(invoice?.fileGeneratedAt, timeZone)

  return (
    <Box width="100%" className="text-xs break-inside-avoid">
      <Box
        flexWrap="wrap"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        borderTop="2px solid"
        borderColor="divider"
      >
        <Box display="flex" alignItems="center" gap={1} className="divide-x divide-black/10">
          <AgingInfoItem label="Current" value={<HHDisplayMoney value={currentCents} />} />
          <AgingInfoItem label="1-30 days" value={<HHDisplayMoney value={dueOneThirtyCents} />} pl={1} />
          <AgingInfoItem label="31-60 days" value={<HHDisplayMoney value={dueThirtyOneSixtyCents} />} pl={1} />
          <AgingInfoItem label="61-90 days" value={<HHDisplayMoney value={dueSixtyOneNinetyCents} />} pl={1} />
          <AgingInfoItem label="90+ days" value={<HHDisplayMoney value={dueOverNinetyCents} />} pl={1} />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <AgingInfoItem label="Outstanding" value={<HHDisplayMoney value={outstandingCents} />} />
          <AgingInfoItem label={<>&nbsp;</>} value="-" />
          <AgingInfoItem label="Account credit" value={<HHDisplayMoney value={customerCreditBalanceCents} />} />
          <AgingInfoItem label={<>&nbsp;</>} value="=" />
          <AgingInfoItem label="Account balance" value={<HHDisplayMoney value={accountBalanceCents} />} />
        </Box>
      </Box>
      <Box textAlign="center" mt={1.5} className="text-xxs">
        Any amount paid in excess of Account balance will be applied to Account credit. Please contact us to ensure your account credit is
        applied to the appropriate invoice.
      </Box>
      {invoice.fileGeneratedAt && (
        <Box borderRadius={2} textAlign="center" mt={1.5} p={1} bgcolor={blue[50]} className=" font-bold">
          {invoice.arRefreshOnly
            ? `Account credit and aging balance was updated on ${fileGeneratedAt} ${zoneName}`
            : `Invoice was generated on ${fileGeneratedAt} ${zoneName}`}
        </Box>
      )}
    </Box>
  )
}

AgingInfo.propTypes = {
  invoice: PropTypes.object,
}

export default AgingInfo
