import React from 'react'
import { Box } from '@mui/material'
import { getStatusBadge } from '../../billing/BillingCommonMethods'
import { INVOICE_STATUS } from '../../../settings/constants/billing'

const { PAID, PARTIAL, UNPAID } = INVOICE_STATUS

const RenderStatusCell = ({ value }) => {
  return (
    <Box color={value === 'Not Started' ? 'text.secondary' : ''}>
      {value === PAID && getStatusBadge('success')}
      {value === PARTIAL && getStatusBadge('primary')}
      {value === UNPAID && getStatusBadge('warning')}
      {value === 'Sending' && getStatusBadge('primary')}
      {value === 'Not Started' && getStatusBadge('error')}
      {value === 'Complete' && getStatusBadge('success')}
      {value === PARTIAL ? 'Draft' : value}
    </Box>
  )
}

export default RenderStatusCell
