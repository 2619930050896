import { toPairs, fromPairs } from 'lodash'
import { get } from 'utils/lodash'
import { createSelector } from 'reselect'

const emptyTags = {
  parentId: null,
  selectedTagIds: [],
}
const getReducerSlice = (state = {}) => get(state, 'Tags', {})

const getAllTagTypesSelectedIds = createSelector(getReducerSlice, (reducer = {}) => get(reducer, 'selectedTags', {}))

export const getSelectedTagIdsByType = createSelector([getReducerSlice, (state, tagType) => tagType], (reducer, tagType) =>
  get(reducer, ['selectedTags', tagType], emptyTags)
)

export const getSelectedTagIds = createSelector(
  getAllTagTypesSelectedIds,

  // get all tag types selected ids grouped by type
  (selectedTagTypes = {}) => fromPairs(toPairs({ ...selectedTagTypes }).filter(([, { parentId }]) => parentId !== null))
)
