import React from 'react'
import {
  Accordion as UnstyledAccordion,
  AccordionSummary as UnstyledAccordionSummary,
  AccordionDetails as UnstyledAccordionDetails,
  styled,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

const Accordion = styled(props => <UnstyledAccordion defaultExpanded disableGutters elevation={0} {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.grey}`,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
  '&:before': {
    display: 'none',
  },
  borderRadius: '6px',
}))
const AccordionSummary = styled(props => <UnstyledAccordionSummary expandIcon={<ArrowRightIcon color="disabled" />} {...props} />)(
  ({ theme, iconGap = true, disablePointer = false, withNewColors = false }) => ({
    background: withNewColors ? theme.palette.background.header : theme.palette.background.accordion,
    '&:focus-within': {
      background: withNewColors ? theme.palette.background.header : theme.palette.background.accordion,
    },
    borderBottom: `1px solid ${theme.palette.border.grey}`,
    flexDirection: 'row-reverse',
    '&.MuiButtonBase-root:hover': {
      cursor: disablePointer ? 'default' : 'pointer',
    },
    '&.MuiButtonBase-root.Mui-expanded:hover': {
      cursor: disablePointer ? 'default' : 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: `12px 0 12px ${iconGap ? 8 : 0}px`,
    },
    '&.Mui-expanded': {
      minHeight: '48px',
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: iconGap ? theme.spacing(1) : 0,
    },
    borderRadius: '6px 6px 0px 0px',
  })
)
const AccordionDetails = styled(UnstyledAccordionDetails)(() => ({
  padding: 0,
}))

export { Accordion, AccordionSummary, AccordionDetails }
