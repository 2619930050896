export const actions = {
  ASSIGN_WORK_ORDER: 'PUT_ASSIGN_WORK_ORDER',
  MOVE_ROUTE_DATE: 'PUT_MOVE_ROUTE_DATE',
  GET_PUSH_STATE: 'GET_PUSH_STATE',
  UNASSIGN_WORK_ORDERS: 'GET_UNASSIGN_WORK_ORDERS',
  GET: {
    ROUTE_STOPS_LIST: 'GET_ROUTE_STOPS_LIST',
    META_DETAILS: 'GET_META_DETAILS',
    ADD_ROUTE: 'GET_ADD_ROUTE',
    ROUTE_DETAIL: 'GET_ROUTE_DETAIL',
    UPDATE_ROUTE_SEQUENCE: 'GET_UPDATE_ROUTE_SEQUENCE',
    MOVE_ROUTE_STOP: 'GET_MOVE_ROUTE_STOP',
    UNASSIGNED_WORK_ORDERS: 'GET_UNASSIGNED_WORK_ORDERS',
    SUBSCRIBE_TOKEN: 'GET_SUBSCRIBE_TOKEN',
    DELETE_ROUTE: 'GET_DELETE_ROUTE',
  },
  PUT: {
    ROUTE_STOPS_LIST: 'PUT_ROUTE_STOPS_LIST',
    META_DETAILS: 'PUT_META_DETAILS',
    ADD_ROUTE: 'PUT_ADD_ROUTE',
    UPDATE_ROUTE_SEQUENCE: 'PUT_UPDATE_ROUTE_SEQUENCE',
    MOVE_ROUTE_STOP: 'PUT_MOVE_ROUTE_STOP',
    UNASSIGNED_WORK_ORDERS: 'PUT_UNASSIGNED_WORK_ORDERS',
    SUBSCRIBE_TOKEN: 'PUT_SUBSCRIBE_TOKEN',
    MARKER_LOCATION: 'PUT_MARKER_LOCATION',
  },
  SELECT_ROUTE: 'SELECT_ROUTE',
}

export const getRouteStopsList = (body, onSuccess) => ({
  type: actions.GET.ROUTE_STOPS_LIST,
  payload: { body, onSuccess },
})

export const getRouteMetaDetails = (body, onSuccess) => ({
  type: actions.GET.META_DETAILS,
  payload: { body, onSuccess },
})

export const getRouteAdd = (body, onSuccess) => ({
  type: actions.GET.ADD_ROUTE,
  payload: { body, onSuccess },
})

export const getRouteDetail = (body, onSuccess) => ({
  type: actions.GET.ROUTE_DETAIL,
  payload: { body, onSuccess },
})

export const getRouteSequenceUpdate = (body, onSuccess) => ({
  type: actions.GET.UPDATE_ROUTE_SEQUENCE,
  payload: { body, onSuccess },
})

export const getRouteMoveStop = (body, onSuccess) => ({
  type: actions.GET.MOVE_ROUTE_STOP,
  payload: { body, onSuccess },
})

export const getRouteDelete = (body, onSuccess) => ({
  type: actions.GET.DELETE_ROUTE,
  payload: { body, onSuccess },
})

export const putRouteStopsList = res => ({
  type: actions.PUT.ROUTE_STOPS_LIST,
  payload: res,
})

export const putRouteMetaDetails = res => ({
  type: actions.PUT.META_DETAILS,
  payload: res,
})

export const putRouteAdd = res => ({
  type: actions.PUT.ADD_ROUTE,
  payload: res,
})

export const putRouteSequenceUpdate = res => ({
  type: actions.PUT.UPDATE_ROUTE_SEQUENCE,
  payload: res,
})

export const putRouteMoveStop = res => ({
  type: actions.PUT.MOVE_ROUTE_STOP,
  payload: res,
})

export const getUnassignedWorkOrder = (body, onSuccess) => ({
  type: actions.GET.UNASSIGNED_WORK_ORDERS,
  payload: { body, onSuccess },
})

export const putUnassignedWorkOrder = res => ({
  type: actions.PUT.UNASSIGNED_WORK_ORDERS,
  payload: res,
})

export const putAssignWorkOrder = (body, onSuccess) => ({
  type: actions.ASSIGN_WORK_ORDER,
  payload: { body, onSuccess },
})
export const putRouteDateMove = (body, onSuccess) => ({
  type: actions.MOVE_ROUTE_DATE,
  payload: { body, onSuccess },
})
export const getRouteSubscribeToken = body => ({
  type: actions.GET.SUBSCRIBE_TOKEN,
  payload: { body },
})
export const putSubscribeToken = res => ({
  type: actions.PUT.SUBSCRIBE_TOKEN,
  payload: res,
})
export const getPushState = body => ({
  type: actions.GET_PUSH_STATE,
  payload: body,
})
export const putUnassignWorkOrders = (body, onSuccess) => ({
  type: actions.UNASSIGN_WORK_ORDERS,
  payload: { body, onSuccess },
})

export const putMarkerLocation = location => ({
  type: actions.PUT.MARKER_LOCATION,
  payload: location,
})

export const selectRouteIds = payload => ({
  type: actions.SELECT_ROUTE,
  payload,
})
