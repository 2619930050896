import { get } from 'lodash'
import { PRIMARY } from 'theme/colors'
import { common, orange } from '@mui/material/colors'

export const ROW_HEIGHT = 104
export const HEADER_HEIGHT = 42
export const INPUT_WIDTH = '4rem'

export const defaultValues = {
  moveFromRouteId: 'UNASSIGNED',
  moveToRouteId: '',
}

export const updateUnassignedMarkers = (selectedFromRows, rowsById, fromMarkers) => {
  return fromMarkers.map(feature => {
    const properties = get(feature, 'properties', {})
    if (selectedFromRows.includes(feature.properties.id)) {
      return {
        ...feature,
        properties: {
          ...properties,
          color: PRIMARY.main,
          icon: 'highlighted-marker',
          'route-text-color': common.black,
          'border-color': common.white,
        },
      }
    }

    return {
      ...feature,
      properties: {
        ...properties,
        color: common.white,
        'border-color': orange[300],
        icon: 'to-be-assigned-marker',
        'route-text-color': common.black,
      },
    }
  })
}
