import sample from 'lodash/sample'

const emptyCollection = []
const isReorderingUnassignItemsAllowed = false

export const DROPPABLE_ID_UNASSIGNED = 'unassigned'
export const DROPPABLE_ID_ACTIVE = 'active'
export const DROPPABLE_COLUMN_TYPE_WORK_ORDER = 'WORK_ORDER'

export const isDroppingOnSameColumn = (from, to) => from === to

export const isUnassignReorderOperation = (from, to) => [from, to].every(id => id === DROPPABLE_ID_UNASSIGNED)

// determine if an item from a different column was dropped into the unassinged column
export const isUnassignOperation = (from, to) => from === DROPPABLE_ID_UNASSIGNED || to === DROPPABLE_ID_UNASSIGNED

export const isValidUnassignOperation = (from, to) => {
  // check if unassigned items are allowed to be reordered withing the same column
  if (from === DROPPABLE_ID_UNASSIGNED && isDroppingOnSameColumn(from, to) && !isReorderingUnassignItemsAllowed) {
    return false
  }

  // draggable is being dropped from a column other than Unassiged into Unassigned
  return from !== DROPPABLE_ID_UNASSIGNED && to === DROPPABLE_ID_UNASSIGNED
}

// determine if an unassigned item has ben dropped into an active route column
export const isAssignOperation = (from, to) => from === DROPPABLE_ID_UNASSIGNED && to !== DROPPABLE_ID_UNASSIGNED

// determine if an active route items is moved between active route columns
export const isMoveOperation = (from, to) => [from, to].every(id => id !== DROPPABLE_ID_UNASSIGNED)

// reorder the result using from and to indices after moving an item
export const reorderRoutesList = (list = emptyCollection, fromIndex = 0, toIndex = 1) => {
  const newListItems = Array.from(list)

  const [removedItem] = newListItems.splice(fromIndex, 1)
  newListItems.splice(toIndex, 0, removedItem)

  return newListItems
}

export const reorderRoutesListMap = ({ routesMap = emptyCollection, source = {}, destination = {} }) => {
  const current = [...routesMap[source.droppableId]]
  const next = [...routesMap[destination.droppableId]]
  const target = current[source.index]

  // move items on the same list column
  if (source.droppableId === destination.droppableId) {
    const reorderedList = reorderRoutesList(current, source.index, destination.index)

    return {
      routesMap: {
        ...routesMap,
        [source.droppableId]: reorderedList,
      },
    }
  }

  // move items between columns
  // remove item from source list
  current.splice(source.index, 1)

  // add item into destination list
  next.splice(destination.index, 0, target)

  return {
    routesMap: {
      ...routesMap,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    },
  }
}

export const moveBetweenRoutesLists = ({ listFrom = emptyCollection, listTo = emptyCollection, source = {}, destination = {} }) => {
  // using Array.from(list) instead or spread [...list] since spread only supports iterable collections
  const newFrom = Array.from(listFrom.values)
  const newTo = Array.from(listTo.values)

  // assign lists to the appropriate column based on source and destination items
  const moveFrom = source.droppableId === listFrom.id ? newFrom : newTo
  const moveTo = moveFrom === newFrom ? newTo : newFrom

  // add item to destination list
  const [movedItem] = moveFrom.splice(source.index, 1)
  moveTo.splice(destination.index, 0, movedItem)

  return {
    listFrom: {
      ...listFrom,
      values: newFrom,
    },
    listTo: {
      ...listTo,
      values: newTo,
    },
  }
}

// Mock data begin
// generate random work orders collection length, based on the actual data from the BE,
// in order to stress test the List's performace
export const getRandomWorkOrders = (mockData, count = 800) => {
  if (!mockData) {
    throw new Error(`sample mock data is required in order to generate random data of lenth ${count}`)
  }

  let workOrdersIdCount = mockData?.length + 1

  return Array.from({ length: count }, (value, key) => key).map(() => {
    const random = sample(mockData)

    return {
      ...random,
      id: `${random?.id}[${workOrdersIdCount++}]`,
    }
  })
}
// Mock data ends
