import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { selectConfirmInvoiceBulkActionDialog } from 'slices/billing/invoicesSlice'
import { shallowEqual, useSelector } from 'react-redux'
import { get, noop } from 'lodash'
import { getInvoiceBulkActionConfirmDialogContent, MARK_AS_SENT_BULK_ACTION } from 'containers/billing/settings/invoices'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import T from 'T'

const ConfirmInvoiceBulkActionDialog = ({ onConfirm = noop, onClose = noop, ...rest }) => {
  const confirmInvoiceBulkAction = useSelector(selectConfirmInvoiceBulkActionDialog, shallowEqual)
  const open = get(confirmInvoiceBulkAction, 'open', false)
  const action = get(confirmInvoiceBulkAction, 'action')
  const invoiceCount = get(confirmInvoiceBulkAction, 'invoiceCount', MARK_AS_SENT_BULK_ACTION)
  const confirmInvoiceBulkActionContent = useMemo(
    () =>
      getInvoiceBulkActionConfirmDialogContent({
        action,
        invoiceCount,
      }),
    [action, invoiceCount]
  )
  const title = get(confirmInvoiceBulkActionContent, 'title')
  const description = get(confirmInvoiceBulkActionContent, 'description')

  return (
    <HHConfirmDialog
      DialogProps={{ maxWidth: 'xs', fullWidth: true }}
      isOpen={open}
      confirmTitle={title}
      confirmDescription={description}
      confirmButtonTitle={T.OK}
      ConfirmButtonProps={{
        color: 'primary',
        size: 'small',
      }}
      showCancelButton={false}
      onConfirm={onConfirm}
      onClose={onClose}
      {...rest}
    />
  )
}

ConfirmInvoiceBulkActionDialog.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default ConfirmInvoiceBulkActionDialog
