import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { shallowEqual, useSelector } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material'
import CommonSwitch from 'components/common/CommonSwitch'
import FooterButton from 'components/buttons/FooterButton'

import Constants from 'Constants'

import { get } from 'lodash'
import { canUpdateSettings } from 'data/permissions/permissionsSelectors'

import T from 'T'

import CheckboxContainer from './CheckboxContainer'

const { MEDIA_SERVER } = Constants

const ListCategories = ({
  handleSave = noop,
  payload = {},
  configurations = [],
  handleCheckedValues = [],
  categoryState = {},
  setCategoryState = noop,
  handleEdit = noop,
  getBusinessLineConfigurations = noop,
}) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [expanded, setExpanded] = useState(false)

  const handleClick = (panel, id) => {
    if (categoryState['isExpanded'][panel] === undefined) {
      categoryState['isExpanded'][panel] = true
    } else {
      categoryState['isExpanded'][panel] = !categoryState['isExpanded'][panel]
    }

    payload['businessLineId'] = id

    setExpanded(categoryState['isExpanded'][panel] ? panel : false)

    setCategoryState({ payload: payload })
  }

  const handleSwitchVal = (value, id) => {
    if (updateSettings) {
      categoryState['active'][id] = value

      payload['businessLineId'] = id

      setCategoryState({ active: categoryState['active'], payload: payload, footerFlag: true })

      handleSave()
    }
  }

  const handleToggles = config => {
    if (categoryState['active'][config.id] === undefined) {
      categoryState['active'][config.id] = get(config, 'active', false)
    }
  }

  const handleClose = () => {
    setExpanded(false)
    setCategoryState({ footerFlag: false })
    getBusinessLineConfigurations()
  }

  return (
    <Box>
      {Children.toArray(
        configurations.length > 0 &&
          configurations.map((config, index) => {
            const panelName = `panel${config.id}`
            const title = get(config, 'businessLine', '')

            handleToggles(config)

            return (
              <Accordion expanded={expanded === panelName} disableGutters sx={{ mb: expanded === panelName ? 1.5 : 0 }}>
                <AccordionSummary
                  sx={{
                    flexDirection: 'row-reverse',
                    borderBottom: theme => `1px solid ${theme.palette.background.tags}`,
                  }}
                  expandIcon={<ExpandMoreIcon onClick={() => handleClick(panelName, config.id)} sx={{ color: 'text.primary' }} />}
                  aria-controls={`${panelName}bh-content`}
                  id={`${panelName}bh-header`}
                >
                  <Grid
                    container
                    onClick={() => handleClick(panelName, config.id)}
                    alignItems="center"
                    justifyContent="space-between"
                    ml={2}
                  >
                    <Grid item display="flex">
                      <img src={`${MEDIA_SERVER}${config.businessLineIcon}`} alt="" height="16px" />

                      <Typography variant="h6" ml={1} mr={1} width={205} noWrap>
                        {title}
                      </Typography>

                      {updateSettings && (
                        <img src={`${MEDIA_SERVER}Edit.svg`} onClick={() => handleEdit(config.id)} alt="" height="16px" />
                      )}
                    </Grid>

                    <Grid item pr={7}>
                      <CommonSwitch
                        titlePosition="right"
                        title={T.ACTIVE}
                        isChecked={categoryState['active'][config.id]}
                        onChange={checked => handleSwitchVal(checked, config.id)}
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>

                {expanded === panelName && (
                  <Box>
                    <AccordionDetails sx={{ m: 1.5 }}>
                      <CheckboxContainer records={config} handleCheckedValues={handleCheckedValues} />
                    </AccordionDetails>

                    {categoryState.footerFlag && (
                      <Box
                        px={3}
                        py={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ borderTop: 1, borderColor: 'border.light' }}
                      >
                        <FooterButton
                          leftButtonTitle={T.CANCEL}
                          onClose={() => handleClose()}
                          rightButtonTitle={T.SAVE}
                          onProceed={handleSave}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Accordion>
            )
          })
      )}
    </Box>
  )
}

ListCategories.propTypes = {
  configurations: PropTypes.array,
  categoryState: PropTypes.object,
  payload: PropTypes.object,
  handleSave: PropTypes.func,
  setCategoryState: PropTypes.func,
  handleCheckedValues: PropTypes.func,
  handleEdit: PropTypes.func,
  getBusinessLineConfigurations: PropTypes.func,
}

export default ListCategories
