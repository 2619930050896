import api from 'api'
import { CACHE_TAG_CONFIGURED_SERVICES_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    searchConfiguredServices: build.query({
      providesTags: [CACHE_TAG_CONFIGURED_SERVICES_LIST],
      query: ({ startDate, endDate, page, size, status, upcoming, ...rest }) => ({
        url: '/api/configured-service/search',
        method: 'POST',
        body: {
          startDate,
          endDate,
          requestedPage: page,
          requestedPageSize: size,
          status,
          upcoming,
          ...rest,
        },
      }),
    }),
  }),
})

export const { useLazySearchConfiguredServicesQuery } = extendedApi
