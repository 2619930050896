import React from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import T from 'T'

const CancelButton = ({ size = 'small', onClick = noop }) => {
  return (
    <Button onClick={onClick} variant="text" size={size} color="textSecondary">
      {T.CANCEL}
    </Button>
  )
}

CancelButton.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
}

export default CancelButton
