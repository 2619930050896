import React from 'react'

import { shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'

import InvoiceDownloadFailureDialog from 'containers/billing/InvoiceDownloadFailureDialog'
import PaymentReceiptDownloadFailureDialog from 'containers/billing/PaymentReceiptDownloadFailureDialog'
import PaymentBatchConfirmationDialog from 'components/billing/groups/payment/take-payments/PaymentBatchConfirmationDialog'

const GlobalDialogs = () => {
  const { isOpenInvoiceDownloadFailureDialog, isOpenPaymentReceiptDownloadFailureDialog, isOpenBatchConfirmationDialog } = useSelector(
    state => ({
      isOpenInvoiceDownloadFailureDialog: get(state, 'Download.invoiceDownloadFailureDialog.isOpen', false),
      isOpenPaymentReceiptDownloadFailureDialog: get(state, 'Download.paymentReceiptDownloadFailureDialog.isOpen', false),
      isOpenBatchConfirmationDialog: get(state, 'PaymentGroups.batchConfirmationDialog.isOpen', false),
    }),
    shallowEqual
  )

  return (
    <>
      {isOpenInvoiceDownloadFailureDialog && <InvoiceDownloadFailureDialog />}
      {isOpenPaymentReceiptDownloadFailureDialog && <PaymentReceiptDownloadFailureDialog />}
      {isOpenBatchConfirmationDialog && <PaymentBatchConfirmationDialog />}
    </>
  )
}

export default GlobalDialogs
