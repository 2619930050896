import React from 'react'

import { formLabelClasses } from '@mui/material'
import { GridCellCheckboxRenderer, selectedIdsLookupSelector } from '@mui/x-data-grid-pro'

const RenderInvoiceCheckboxCell = params => {
  const { row, rowNode, api } = params
  const selectionLookup = selectedIdsLookupSelector(api.state, api.instanceId)

  let indeterminate = false
  let checked = formLabelClasses
  const extraData = {}
  const isParentRow = row?.isParent

  if (isParentRow) {
    const hasChildren = rowNode?.children
    indeterminate =
      hasChildren &&
      rowNode.children.some(child => selectionLookup[child] === undefined) &&
      rowNode.children.some(child => selectionLookup[child] !== undefined)
    checked = hasChildren && rowNode.children.every(child => selectionLookup[child] !== undefined)

    if (indeterminate) {
      extraData.indeterminate = indeterminate
    }

    if (checked) {
      extraData.checked = checked
    }

    extraData.onClick = e => {
      e.preventDefault()
      if (hasChildren) {
        api.selectRows(rowNode.children, indeterminate || !checked)
      }
    }
  }

  return <GridCellCheckboxRenderer {...params} {...extraData} />
}

export default RenderInvoiceCheckboxCell
