import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TableBody as TBody, TableCell, TableRow, TextField, InputAdornment } from '@mui/material'

import { NumericFormat } from 'react-number-format'

import PricingSwitch from 'components/pricing/PricingSwitch'
import TiersSection from 'components/pricing/Fees/TiersSection'
import ToolTip from 'components/common/Tooltip'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import Constants from 'Constants'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const ValidatedDisposalFeeTableBody = ({
  validatedFees,
  tierCount,
  confServices,
  handleDelete,
  onHandleValidatedFieldsChange,
  onHandleTiersChange,
  type,
  handleTiers,
  getFeeServiceName,
  saveConfSettings,
  onTableFeeScopeChange,
  onHandleMeasureChange,
}) => {
  const [allowedServices, setAllowedServices] = useState(confServices.filter(data => data.checked))

  useEffect(() => {
    setAllowedServices(confServices.filter(data => data.checked))
  }, [confServices])

  const lockedColumns = confServices.filter(column => column.locked && column.checked)

  return (
    <TBody className="table-body">
      {validatedFees &&
        allowedServices &&
        Children.toArray(
          validatedFees.map((fee, index) => {
            return (
              <TableRow>
                {Children.toArray(
                  allowedServices.map((column, ind) => {
                    const columnName = column.label
                    const record = getFeeServiceName(fee, columnName, 'create')
                    const isLocked = get(column, 'locked', false)
                    return (
                      <>
                        {column.label !== 'Overage Fee' && column.label !== 'Price' && column.label !== 'Measure' && (
                          <TableCell className={handlePricingTableClass(ind, isLocked, lockedColumns)}>
                            {columnName === 'Disposal Fee' && (
                              <div className="services-field">
                                <ToolTip title={record}>
                                  <TextField
                                    id="outlined-size-small"
                                    value={record}
                                    variant="outlined"
                                    size="small"
                                    autoComplete="off"
                                    name="disposalFeeName"
                                    InputProps={{
                                      onChange: e => onHandleValidatedFieldsChange(e, fee),
                                    }}
                                  />
                                </ToolTip>
                              </div>
                            )}

                            {columnName === 'Min. Fee' && (
                              <div className="fee-field">
                                <NumericFormat
                                  id="outlined-size-small"
                                  placeholder="0.00"
                                  value={record}
                                  variant="outlined"
                                  size="small"
                                  autoComplete="off"
                                  name="minFee"
                                  onChange={e => onHandleValidatedFieldsChange(e, fee)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  customInput={TextField}
                                  thousandSeparator
                                  decimalSeparator="."
                                  decimalScale={2}
                                />
                              </div>
                            )}

                            {columnName === 'Cost Plus' && (
                              <div className="sub-item">
                                <div className="switch-section">
                                  <div className="switch">
                                    <PricingSwitch
                                      checked={fee.costPlus}
                                      handleSwitchVal={() => onTableFeeScopeChange(!fee.costPlus, fee, 'costPlus')}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {!['Disposal Fee', 'Min. Fee', 'Price', 'Measure'].includes(columnName) && (
                              <div className="price-cell">{record}</div>
                            )}
                          </TableCell>
                        )}

                        {column.label === 'Price' && (
                          <TableCell colSpan="2">
                            <TiersSection
                              index={index}
                              fee={fee}
                              tierCount={tierCount}
                              onHandleTiersChange={onHandleTiersChange}
                              handleTiers={handleTiers}
                              onHandleMeasureChange={onHandleMeasureChange}
                            />
                          </TableCell>
                        )}

                        {column.label === 'Overage Fee' && (
                          <>
                            <TableCell>
                              <div className="fee-field">
                                <NumericFormat
                                  id="outlined-size-small"
                                  placeholder="0"
                                  value={get(fee, 'overageThreshold', '')}
                                  variant="outlined"
                                  size="small"
                                  autoComplete="off"
                                  name="overage"
                                  onChange={e => onHandleValidatedFieldsChange(e, fee)}
                                  customInput={TextField}
                                  thousandSeparator
                                  decimalSeparator={false}
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="fee-field">
                                <NumericFormat
                                  id="outlined-size-small"
                                  placeholder="0.00"
                                  value={record}
                                  variant="outlined"
                                  size="small"
                                  autoComplete="off"
                                  name="overageFee"
                                  onChange={e => onHandleValidatedFieldsChange(e, fee)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  customInput={TextField}
                                  thousandSeparator
                                  decimalSeparator="."
                                  decimalScale={2}
                                />
                              </div>
                            </TableCell>
                          </>
                        )}
                      </>
                    )
                  })
                )}

                <TableCell>
                  <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleDelete(fee, type)} />
                </TableCell>
              </TableRow>
            )
          })
        )}
    </TBody>
  )
}

ValidatedDisposalFeeTableBody.defaultProps = {
  validatedFees: [],
  confServices: [],
  tierCount: {},
  type: 'unique',
  saveConfSettings: false,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getFeeServiceName: null,
  onTableFeeScopeChange: null,
  onHandleTiersChange: null,
  handleTiers: null,
  onHandleMeasureChange: null,
}

ValidatedDisposalFeeTableBody.propTypes = {
  validatedFees: PropTypes.array,
  confServices: PropTypes.array,
  tierCount: PropTypes.object,
  type: PropTypes.string,
  saveConfSettings: PropTypes.bool,
  onTableFeeScopeChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  handleTiers: PropTypes.func,
  onHandleTiersChange: PropTypes.func,
  onHandleMeasureChange: PropTypes.func,
}

export default memo(ValidatedDisposalFeeTableBody)
