import last from 'lodash/last'
import capitalize from 'lodash/capitalize'

import routes from 'router/routes'
import system from 'settings/system'
import {
  isInvoiceGroupDetailsUrl,
  isPaymentGroupDetailsUrl,
  isAccountGroupDetailsUrl,
  isSuspensionGroupDetailsUrl,
  isLateFeeDetailsUrl,
  isRentalFeeDetailsUrl,
} from './validations'

const { title: systemTitle } = system

const allRoutes = Object.keys(routes.app)

const PAGE_TITLE = new Map([
  // override any existing route here
  [routes.app.followUp, 'Follow Up'],
  [routes.app.createInvoices, 'Create Invoices'],
  [routes.app.accountsReceivable, 'Accounts Receivable'],
  [routes.app.invoiceGroups, 'Invoice Groups'],
  [routes.app.paymentGroups, 'Payment Groups'],
  [routes.app.workOrdersReview, 'Work Orders Review'],
  [routes.app.accountGroups, 'Account Groups'],
  [routes.app.suspensionGroups, 'Suspension groups'],
  [routes.app.recentlyViewed, 'Recently viewed'],
  [routes.app.recentlyAdded, 'Recently added'],
  [routes.app.pricingServices, 'Services'],
  [routes.app.pricingServiceGroups, 'Service groups'],
  [routes.app.pricingFees, 'General fees'],
  [routes.app.pricingTaxes, 'Taxes'],
  [routes.app.pricingRentalFees, 'Rental fees'],
  [(`${routes.app.search}/customer`, 'Customer Search')],
  [`${routes.app.search}/contact`, 'Contact Search'],
  [`${routes.app.search}/priced-service`, 'Priced Service Search'],
  [`${routes.app.search}/configured-service`, 'Configured Service Search'],
  [`${routes.app.search}/notes`, 'Notes Search'],
  [`${routes.app.search}/follow-up`, 'Follow Up Search'],
  [`${routes.app.search}/work-order`, 'Work Order Search'],
  [`${routes.app.settings}/attributes`, 'Business Line Attributes Settings'],
  [`${routes.app.settings}/create-configure`, 'Business Line Create & Configure Settings'],
  [`${routes.app.settings}/tags`, 'Tags Settings'],
  [`${routes.app.settings}/customer-portal`, 'Customer Portal Settings'],
  [`${routes.app.settings}/people`, 'People Settings'],
  [`${routes.app.settings}/roles`, 'Roles Settings'],
  [`${routes.app.settings}/trucks`, 'Trucks Settings'],
  [`${routes.app.settings}/teams`, 'Teams Settings'],
  [`${routes.app.settings}/skills`, 'Skills Settings'],
  [`${routes.app.settings}/pricing-zones`, 'Pricing Zones Settings'],
  [`${routes.app.settings}/tax-zones`, 'Tax Zones  Settings'],
  [`${routes.app.settings}/customer-zones`, 'Customer Zones Settings'],
  [`${routes.app.settings}/general`, 'General Billing Settings'],
  [`${routes.app.settings}/customer-billing-profiles`, 'Customer Billing Profiles Settings'],
  [`${routes.app.settings}/general-ledger`, 'General Ledger Settings'],
  [`${routes.app.settings}/postcard-setup`, 'Postcard Setup Settings'],
  [`${routes.app.settings}/yards`, 'Yards Settings'],
  [`${routes.app.settings}/disposal-sites`, 'Disposal Sites Settings'],
  [`${routes.app.settings}/permits`, 'Permits'],
  [`${routes.app.settings}/business-information`, 'Business Information'],
  [routes.app.resetPassword, 'Reset Password'],
  [routes.app.selectAccount, 'Select Account'],
  ['default', ''],
])

export const getPageTitle = page => {
  let title = PAGE_TITLE.get(page)

  if (!title) {
    const url = last(page.split('/'))
    title = allRoutes.includes(url) ? capitalize(url) : PAGE_TITLE.get('default')
  }
  if (page.includes('reports')) title = 'Reports'
  if (page.includes('routes') && page.includes('work_orders')) {
    title = 'Route Work Orders'
  }

  if (page.includes('work-orders') && page.includes('details')) {
    title = 'Work Order Details'
  }

  if (page.includes('customer') && page.includes('details')) {
    title = 'Customer Details'
  }

  if (isInvoiceGroupDetailsUrl(page)) {
    title = 'Invoice Groups'
  }

  if (isPaymentGroupDetailsUrl(page)) {
    title = 'Payment Groups'
  }

  if (isAccountGroupDetailsUrl(page)) {
    title = 'Account Groups'
  }

  if (isSuspensionGroupDetailsUrl(page)) {
    title = 'Suspension Groups'
  }

  if (isLateFeeDetailsUrl(page)) {
    title = 'Late fees'
  }

  if (isRentalFeeDetailsUrl(page)) {
    title = 'Rental fees'
  }

  return `${systemTitle} - ${title}`
}
