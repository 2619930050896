import React, { Children } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { TableBody as TBody, TableCell, TableRow } from '@mui/material'
import { Photograph } from '@styled-icons/heroicons-outline/Photograph'

import { useHistory, useLocation } from 'react-router-dom'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { formatMeasure } from 'utils/format'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

import { getWorkOrderDetailsPageUrl } from 'router/routes'
import ToolTip from 'components/common/Tooltip'
import { useDispatch } from 'react-redux'
import { setResetInvoicesStateOnUnmount } from '../../../../../slices/billing/invoicesSlice'
import ExceptionIcon from '../../../../exceptions/ExceptionIcon'

const MAX_EXCEPTION_ALLOWED = 4

const WorkOrderTableBody = ({ workOrderList, invoiceNumber, onImageIconClick }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const imageIconClick = image => {
    onImageIconClick(true, image)
  }

  return (
    <TBody className="table-body">
      {workOrderList &&
        Children.toArray(
          workOrderList.map(workOrder => {
            const date = get(workOrder, 'serviceDate', '')
            const feeTotalCents = get(workOrder, 'feeTotalCents', 0)
            const measure = get(workOrder, 'measure', '')
            const routeName = get(workOrder, 'routeName', '')
            const note = get(workOrder, 'notes[0].text', '')
            const workOrderExceptions = get(workOrder, 'exceptions', [])
            const hasExtraExceptions = workOrderExceptions.length > MAX_EXCEPTION_ALLOWED
            const skipFromIndex = hasExtraExceptions ? MAX_EXCEPTION_ALLOWED - 1 : MAX_EXCEPTION_ALLOWED
            const exceptionsCount = workOrderExceptions.length - skipFromIndex
            const workOrderImages = get(workOrder, 'images', [])
            const totalImages = workOrderImages.length
            const handleWorkOrderRowClick = () => {
              dispatch(setResetInvoicesStateOnUnmount(false))
              history.push({
                pathname: getWorkOrderDetailsPageUrl(get(workOrder, 'id')),
                state: {
                  accountName: invoiceNumber,
                  prevPath: location?.pathname,
                },
              })
            }

            return (
              <TableRow className="cursor-pointer" onClick={handleWorkOrderRowClick}>
                <TableCell>
                  <div className="work-order-date">{date && moment(date).format('L')}</div>
                </TableCell>
                <TableCell>
                  <div>{get(workOrder, 'workOrderSequence', '')}</div>
                </TableCell>
                <TableCell>
                  <div>{routeName}</div>
                </TableCell>
                <TableCell>
                  <div>{formatMeasure(measure)}</div>
                </TableCell>
                <TableCell>
                  {workOrderExceptions.length > 0 && (
                    <div className="exception-image">
                      {Children.toArray(
                        workOrderExceptions.map((exception, expIndex) => {
                          const type = get(exception, 'exceptionType', '')
                          if (type && expIndex < skipFromIndex) {
                            return <ExceptionIcon exception={type} />
                          }
                        })
                      )}
                      {exceptionsCount > 0 && (
                        <div className="max-exception">
                          <div className="max-exception-btn">+{exceptionsCount}</div>
                        </div>
                      )}
                    </div>
                  )}
                </TableCell>
                <TableCell className="work-order-note">
                  <div>{note}</div>
                </TableCell>
                <TableCell>
                  <div className="flex items-center justify-center">
                    {totalImages > 0 && (
                      <ToolTip title={`${totalImages} Photo${totalImages > 1 ? 's' : ''}`}>
                        <Photograph
                          className="flex items-center justify-center photo-icon"
                          onClick={event => {
                            event.stopPropagation()
                            imageIconClick(workOrder)
                          }}
                        />
                      </ToolTip>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <HHDisplayMoney value={feeTotalCents} />
                </TableCell>
              </TableRow>
            )
          })
        )}
      {(!workOrderList || workOrderList.length === 0) && (
        <TableRow>
          <TableCell colspan="100%" className="no-border">
            <div className="no-results">No Work Order Details Found</div>
          </TableCell>
        </TableRow>
      )}
    </TBody>
  )
}

WorkOrderTableBody.defaultProps = {
  workOrderList: [],
  invoiceNumber: 0,
}

WorkOrderTableBody.propTypes = {
  workOrderList: PropTypes.array,
  invoiceNumber: PropTypes.number,
  onImageIconClick: PropTypes.func.isRequired,
}

export default memo(WorkOrderTableBody)
