import React from 'react'
import PropTypes from 'prop-types'
import RenderRouteCell from './RenderRouteCell'
import RenderWOReviewDetailsCardCell from '../WOReviewDetailsCard/RenderWOReviewDetailsCardCell'

const RenderWoReviewRow = props => {
  const { row } = props
  if (row.isRoute) {
    return <RenderRouteCell {...props} />
  }
  if (row.isWO) {
    return <RenderWOReviewDetailsCardCell {...props} />
  }
  return <div />
}

RenderWoReviewRow.propTypes = {
  row: PropTypes.object.isRequired,
}

export default RenderWoReviewRow
