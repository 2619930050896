import React from 'react'
import PropTypes from 'prop-types'
import invert from 'lodash/invert'

import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { purple, yellow, red, green } from '@mui/material/colors'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'

import { get } from 'utils/lodash'
import { PAYMENT_STATUS, PAYMENT_STATUS_LABELS } from 'settings/constants/payment'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import { getPaymentsFromBatch, getPaymentTransactionStats } from 'data/groups/paymentGroupsSelectors'
import { setChartSummaryDialogState } from 'slices/groups/PaymentGroupsSlice'
import T from 'T'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'
import GraphCardEmptyState from '../common/GraphCardEmptyState'
import ChartLegend from '../common/ChartLegend'
import PaymentStatsTooltip from './common/PaymentStatsTooltip'
import PaymentStatsGroupTooltip from './common/PaymentStatsGroupTooltip'

const { APPROVED, REVERSED, TRANSFERRED, FAILED } = PAYMENT_STATUS

const COLOR_MAP = {
  [TRANSFERRED]: purple[100],
  [REVERSED]: yellow[100],
  [FAILED]: red[100],
  [APPROVED]: green[100],
}

const PaymentTransactionStatistic = ({ id, showFooter = true, groupedTooltip = true }) => {
  const dispatch = useDispatch()
  const { data: groupDetails } = useGetPaymentGroupDetailsQuery({ id })
  const payments = getPaymentsFromBatch({ paymentBatches: get(groupDetails, 'paymentBatches', []) })
  const transactionData = getPaymentTransactionStats({ payments })
  const transfer = get(transactionData, `${TRANSFERRED}.count`, 0)
  const reversed = get(transactionData, `${REVERSED}.count`, 0)
  const failed = get(transactionData, `${FAILED}.count`, 0)
  const complete = get(transactionData, `${APPROVED}.count`, 0)
  const totalCount = transfer + reversed + failed + complete
  const isChartEmpty = totalCount === 0
  const invertedLabels = invert(PAYMENT_STATUS_LABELS)

  const data = [
    {
      id: PAYMENT_STATUS_LABELS[TRANSFERRED],
      label: PAYMENT_STATUS_LABELS[TRANSFERRED],
      value: transfer,
      color: COLOR_MAP[TRANSFERRED],
    },
    {
      id: PAYMENT_STATUS_LABELS[REVERSED],
      label: PAYMENT_STATUS_LABELS[REVERSED],
      value: reversed,
      color: COLOR_MAP[REVERSED],
    },
    {
      id: PAYMENT_STATUS_LABELS[FAILED],
      label: PAYMENT_STATUS_LABELS[FAILED],
      value: failed,
      color: COLOR_MAP[FAILED],
    },
    {
      id: PAYMENT_STATUS_LABELS[APPROVED],
      label: PAYMENT_STATUS_LABELS[APPROVED],
      value: complete,
      color: COLOR_MAP[APPROVED],
    },
  ]

  const renderPaymentTransactionTooltip = params => {
    const { label, value, color } = params?.datum || {}
    const { rows, total } = get(transactionData, invertedLabels[label])
    if (groupedTooltip) {
      return <PaymentStatsGroupTooltip color={color} label={label} value={value} total={total} />
    }
    return <PaymentStatsTooltip color={color} label={label} value={value} total={total} rows={rows} />
  }

  const handleClick = ({ label, value, color }) => {
    if (value > 0) {
      const { rows, total } = get(transactionData, invertedLabels[label])
      dispatch(setChartSummaryDialogState({ isOpen: true, color, label, value, total, rows, isBatch: false }))
    }
  }

  return (
    <>
      {isChartEmpty && <GraphCardEmptyState title={T.NO_PAYMENT_DATA} />}
      {!isChartEmpty && (
        <>
          <Box height={250} position="relative">
            <GroupPieChart
              data={data}
              colors={Object.values(COLOR_MAP)}
              legends={[]}
              tooltip={renderPaymentTransactionTooltip}
              onClick={handleClick}
            />
            <GroupChartOverlay overlayValue={totalCount} />
          </Box>
          <ChartLegend data={data} mt={-3.5} />
        </>
      )}
      {showFooter && <GroupChartName Icon={CurrencyDollar} label={T.TRANSACTION_STATUS} />}
    </>
  )
}

PaymentTransactionStatistic.propTypes = {
  id: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
  groupedTooltip: PropTypes.bool,
}

export default PaymentTransactionStatistic
