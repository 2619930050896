import groupBy from 'lodash/groupBy'

import { get } from 'utils/lodash'

// Transform service groups attributes to services, used in new service -> service listing
export const transformServiceGroupsToServices = serviceGroups => {
  const validGroups = serviceGroups.filter(group => get(group, 'pricedGroupService.id'))

  return validGroups.map(group => ({
    id: get(group, 'pricedGroupService.id'),
    serviceName: get(group, 'pricedGroupService.name'),
    serviceType: 'On Request',
    businessLine: get(group, 'pricedGroupService.businessLine.businessLineName', ''),
    businessLineIcon: get(group, 'pricedGroupService.businessLine.icon', ''),
  }))
}

export const createBulkServices = (singleService, pricedServiceDetails) => {
  const allServices = get(pricedServiceDetails, 'allServices', [])
  const allServiceEvents = get(singleService, 'schedule.serviceEvents', [])
  const groupEventsByAction = groupBy(allServiceEvents, 'actionType')

  const allFeesAndTaxes = get(singleService, 'feeTaxList', [])
  const groupByFeesTaxes = groupBy(allFeesAndTaxes, 'serviceId')

  return Object.keys(groupEventsByAction).map(actionTypeKey => {
    const currentService = allServices.find(service => service.type === actionTypeKey)
    const events = get(groupEventsByAction, actionTypeKey)
    const serviceId = get(currentService, 'serviceId')
    const feeTaxList = get(groupByFeesTaxes, serviceId)

    const price = get(events, '[0].price', 0)
    return {
      ...singleService,
      schedule: {
        ...get(singleService, 'schedule', {}),
        serviceEvents: events,
        defaultActionType: actionTypeKey,
      },
      feeTaxList,
      price,
      pricedServiceId: serviceId,
      pricedServiceGroupId: get(pricedServiceDetails, 'id'),
    }
  })
}
