import api from 'api'
import { CACHE_TAG_ACCOUNT_META } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    setDefaultBillingProfile: build.mutation({
      query: body => ({
        url: '/api/settings/customer/billing/profile/default',
        method: 'POST',
        body,
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_META],
    }),
  }),
})

export const { useSetDefaultBillingProfileMutation } = extendedApi
