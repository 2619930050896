import React from 'react'
import noop from 'lodash/noop'
import { useSelector, useDispatch } from 'react-redux'
import { setInvoiceDetailsDrawerState } from 'slices/billing/invoiceDetailsDrawerSlice'
import PropTypes from 'prop-types'
import InvoiceDrawer from '../invoices/InvoiceDrawer'
import { CommonDrawer } from '../../common'
import InvoiceEdit from '../invoices/invoice-edit'
import { get } from '../../../utils/lodash'
import api from '../../../api'
import {
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
} from '../../../api/cacheTagTypes'

const WithInvoiceDetailsDrawer = ({ children }) => {
  const dispatch = useDispatch()
  const invoiceDetailsDrawer = useSelector(state => state.invoiceDetailsDrawer)
  const invoice = get(invoiceDetailsDrawer, 'invoice')
  const isOpenPDFDrawer = get(invoiceDetailsDrawer, 'isOpenPDFDrawer')
  const isOpenEditDrawer = get(invoiceDetailsDrawer, 'isOpenEditDrawer')

  const handleClosePDFDrawer = () => {
    dispatch(setInvoiceDetailsDrawerState({ isOpenPDFDrawer: false }))
  }

  const handleEditInvoice = () => {
    dispatch(setInvoiceDetailsDrawerState({ isOpenPDFDrawer: false, isOpenEditDrawer: !isOpenEditDrawer }))
  }

  const onRefresh = () => {
    setTimeout(() => {
      dispatch(
        api.util.invalidateTags([
          CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
          CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
          CACHE_TAG_ACCOUNT_INVOICE_LIST,
          CACHE_TAG_INVOICE_LIST,
          CACHE_TAG_WORK_ORDER_LIST,
          CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
          CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
        ])
      )
    }, 4000)
  }

  return (
    <>
      {children}
      <InvoiceDrawer
        open={isOpenPDFDrawer}
        accountName={invoice?.accountName}
        onClose={handleClosePDFDrawer}
        invoiceNumber={invoice?.invoiceNumber}
        invoiceId={invoice?.id}
        invoiceStatus={invoice?.status}
        onEditInvoice={handleEditInvoice}
      />
      <CommonDrawer isOpen={isOpenEditDrawer} className="common-drawer-container invoice-work-order" onChange={handleEditInvoice}>
        <InvoiceEdit
          isOpen={isOpenEditDrawer}
          invoiceNumber={invoice?.invoiceNumber}
          accountId={invoice?.accountId}
          accountName={invoice?.accountName}
          invoiceDate={invoice?.invoiceDate}
          invoiceId={invoice?.id}
          unPostedAmountCents={invoice?.totalCents}
          invoiceStatus={invoice?.status}
          onInvoiceSideView={noop}
          onClose={handleEditInvoice}
          onRefreshTable={onRefresh}
        />
      </CommonDrawer>
    </>
  )
}

WithInvoiceDetailsDrawer.propTypes = {
  children: PropTypes.node,
}

export default WithInvoiceDetailsDrawer
