import React from 'react'
import PropTypes from 'prop-types'
import { ListItemAvatar, ListItemText } from '@mui/material'
import Constants from 'Constants'

const { PUBLIC_ASSETS_BUCKET_NAME, STORAGE_PATH } = Constants

const SelectItemContent = ({ businessId, businessTitle }) => {
  return (
    <>
      <ListItemAvatar
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 60,
          width: 60,
          height: 40,
          img: { maxWidth: '100%' },
        }}
      >
        <img src={`${STORAGE_PATH}${PUBLIC_ASSETS_BUCKET_NAME}/${businessId}/business_logo.png`} alt="" />
      </ListItemAvatar>
      <ListItemText sx={{ ml: 1 }} primaryTypographyProps={{ noWrap: true, variant: 'h6', fontWeight: 400 }} primary={businessTitle} />
    </>
  )
}

SelectItemContent.propTypes = {
  businessId: PropTypes.string,
  businessTitle: PropTypes.string,
}

export default SelectItemContent
