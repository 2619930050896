import React from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { Typography, Box } from '@mui/material'
import { get } from 'utils/lodash'

import HHDisplayMoney from 'components/common/HHDisplayMoney'

const RenderPaymentHeader = ({ hierarchyIds = [], totalAmountCents }) => {
  const selectedRows = useSelector(state => get(state, 'RemovePaymentDialog.paymentSelectionModel', []), shallowEqual)
  const totalSelectedRows = selectedRows.length

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        {`${hierarchyIds.length} batch`}
        {totalSelectedRows > 0 ? ` / ${totalSelectedRows} selected` : ''}
      </Typography>
      <Typography variant="h5" fontWeight={500} color="textPrimary">
        <HHDisplayMoney value={totalAmountCents} />
      </Typography>
    </Box>
  )
}

RenderPaymentHeader.propTypes = {
  hierarchyIds: PropTypes.array,
  totalAmountCents: PropTypes.number,
}

export default RenderPaymentHeader
