import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, ListItemIcon, ListItemText, Popover, Popper, TextField, ListItemButton, MenuItem, Paper } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import RenderAccountStatusFilterOption from 'components/customers/groups/account/details/filters/account-status-filter/RenderAccountStatusFilterOption'
import { ACCOUNT_STATUS_FILTER_MENU_OPTIONS } from './settings'

const WIDTH = 200

const CustomPopper = props => <Popper {...props} placement="bottom-start" />

const AccountStatusFilterMenu = ({
  value,
  onChange = noop,
  defaultSelectedAccountStatuses = [],
  renderOption = RenderAccountStatusFilterOption,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const open = Boolean(popoverAnchorEl)
  const accountStatusIdList = useMemo(() => ACCOUNT_STATUS_FILTER_MENU_OPTIONS.filter(({ id }) => id).map(({ id }) => id), [])
  const isControlled = value !== undefined
  const [internalSelectedAccountStatus, setInternalSelectedAccountStatus] = useState(defaultSelectedAccountStatuses)
  const selectedStatus = isControlled ? value : internalSelectedAccountStatus
  const allStatusSelected = useMemo(() => selectedStatus.length === accountStatusIdList.length, [selectedStatus, accountStatusIdList])
  const accountStatusButtonLabel = useMemo(() => {
    if (selectedStatus.length === 0) return 'No account status'
    return 'Account status'
  }, [selectedStatus])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleOpen = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(null)
  }

  const getOptionLabel = option => get(option, 'value')

  const toggleAllAccountStatusSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelected = allStatusSelected ? [] : [...ACCOUNT_STATUS_FILTER_MENU_OPTIONS]
      if (isControlled) {
        onChange(newSelected)
      } else {
        setInternalSelectedAccountStatus(newSelected)
        onChange(newSelected)
      }
    },
    [isControlled, allStatusSelected]
  )

  const handleChange = (event, newSelected) => {
    if (isControlled) {
      onChange(newSelected)
    } else {
      setInternalSelectedAccountStatus(newSelected)
      onChange(newSelected)
    }
  }

  useEffect(() => {
    if (!isInitialized) {
      setInternalSelectedAccountStatus(defaultSelectedAccountStatuses)
      setIsInitialized(true)
    }
  }, [isInitialized, defaultSelectedAccountStatuses])

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton disableGutters divider selected={allStatusSelected} onMouseDown={toggleAllAccountStatusSelection}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allStatusSelected} />
            </ListItemIcon>
            <ListItemText primary={allStatusSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allStatusSelected, toggleAllAccountStatusSelection]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedStatus.length} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 120 }}>{accountStatusButtonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="account-status-filter-menu-popover"
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        transitionDuration={{ appear: 0, exit: 0 }}
        TransitionProps={{ onEntered: focusAutocomplete }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
      >
        <Autocomplete
          multiple
          options={ACCOUNT_STATUS_FILTER_MENU_OPTIONS}
          sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
          fullWidth
          componentsProps={{
            paper: { allStatusSelected, toggleAllAccountStatusSelection },
            popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
          }}
          ListboxProps={{
            sx: { py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
          }}
          open={open}
          disableClearable
          onChange={handleChange}
          PopperComponent={CustomPopper}
          PaperComponent={SelectAllPaperComponent}
          renderTags={() => <></>}
          value={selectedStatus}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption}
          renderInput={({ inputProps, ...restTextFieldProps }) => (
            <TextField {...restTextFieldProps} inputRef={inputRef} variant="standard" inputProps={{ ...inputProps }} />
          )}
          {...rest}
        />
      </Popover>
    </>
  )
}

AccountStatusFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedAccountStatuses: PropTypes.array,
  renderOption: PropTypes.func,
}

export default AccountStatusFilterMenu
