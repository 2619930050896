import React, { useEffect } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { setStopDetailsDialogState } from 'slices/route/routeSlice'
import { useLazyGetServiceDetailsQuery } from 'api/configured-service/getServiceDetails'

import Loader from 'components/common/loader'
import HHBaseDialog from 'components/common/HHBaseDialog'
import StopDetails from './StopDetails'

const StopDetailsDialog = () => {
  const dispatch = useDispatch()
  const [getServiceDetails, { data: serviceData, isFetching: isConfiguredServiceDetailsLoading }] = useLazyGetServiceDetailsQuery()
  const stopDetailsDialog = useSelector(s => get(s, 'Route.stopDetailsDialog', {}), shallowEqual)

  const { isOpen = false, stop = {} } = stopDetailsDialog
  const { workOrderId, configuredServiceId } = stop

  const handleClose = () => dispatch(setStopDetailsDialogState({ isOpen: false, id: '', stop: {}, refetchStop: false }))

  useEffect(() => {
    if (!isOpen || !workOrderId || !configuredServiceId) {
      return
    }

    getServiceDetails(configuredServiceId).unwrap().catch(handleError)
  }, [isOpen, configuredServiceId, workOrderId])

  return (
    <>
      {isConfiguredServiceDetailsLoading && <Loader />}
      <HHBaseDialog fullWidth open={isOpen} maxWidth="sm" onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <StopDetails
            stop={stop}
            service={isConfiguredServiceDetailsLoading ? {} : serviceData}
            showSchedule
            showFullInfo
            showCloseBtn
            onClose={handleClose}
          />
        </DialogContent>
      </HHBaseDialog>
    </>
  )
}

export default StopDetailsDialog
