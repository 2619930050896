import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Button, Badge, Menu, MenuItem, ListItemText, styled, ListItemAvatar, Typography } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { grey } from '@mui/material/colors'
import { useGetRouteMetadataQuery } from '../../../api/route/getRouteMetadata'
import RouteAvatar from '../../route-manager/RouteDetailsModal/RouteAvatar/RouteAvatar'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

const ViewActiveRoutes = ({ disabled = false }) => {
  const theme = useTheme()
  const filters = useSelector(state => state.workOrdersReview.filters, shallowEqual)
  const routeIdList = useSelector(state => state.persistentWorkOrdersReview.routeIdList, shallowEqual)
  const { data: metaData } = useGetRouteMetadataQuery()
  const allRoutes = get(metaData, 'routes', [])
  const [anchorEl, setAnchorEl] = useState(null)
  const allFiltersSelected = routeIdList.length === allRoutes.length + 1
  const activeRoutesCount = routeIdList.length
  const filteredRoutes = allRoutes.filter(({ id }) => routeIdList.includes(id))
  const handleClick = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{ minWidth: '32px' }}
        disabled={disabled}
        startIcon={
          <StyledBadge sx={{ mr: 1.5, mb: 0.5 }} badgeContent={activeRoutesCount} color="primary">
            <FilterListIcon />
          </StyledBadge>
        }
      >
        <>
          {allFiltersSelected && 'All routes'}
          {filters.length === 0 && 'No routes'}
          {filters.length > 0 && !allFiltersSelected && 'routes'}
        </>
      </Button>
      <Menu
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: theme.zIndex.tooltip + 10 }}
      >
        {filteredRoutes.map(route => {
          const name = get(route, 'name', '')
          const bgcolor = get(route, 'color', grey[100])
          return (
            <MenuItem>
              <ListItemAvatar sx={{ minWidth: '40px' }}>
                <RouteAvatar name={name} bgcolor={bgcolor} />
              </ListItemAvatar>
              <ListItemText primary={<Typography noWrap>{name}</Typography>} />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

ViewActiveRoutes.propTypes = {
  disabled: PropTypes.bool,
}

export default ViewActiveRoutes
