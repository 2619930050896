import React from 'react'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'
import InvoiceGroupList from 'components/billing/groups/invoice/details/InvoiceGroupList'
import InvoiceGroupDetails from 'containers/billing/groups/invoice/details/InvoiceGroupDetails'
import WithInvoiceDetailsDrawer from '../components/billing/common/WithInvoiceDetailsDrawer'

const InvoiceGroupsDetailsPage = () => {
  return (
    <ProtectedRouteHoC>
      <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
        <InvoiceGroupDetails>
          <WithInvoiceDetailsDrawer>
            <InvoiceGroupList />
          </WithInvoiceDetailsDrawer>
        </InvoiceGroupDetails>
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

InvoiceGroupsDetailsPage.propTypes = {}

export default InvoiceGroupsDetailsPage
