import { actions } from '../actions/auth'

const { RESET } = actions
const { TOKEN, USER_DETAILS, IS_USER_AUTHENTICATED } = actions.PUT

const initialState = {
  token: null,
  userInfo: null,
  isUserAuthenticated: false,
}

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOKEN:
      return { ...state, token: action.payload }

    case USER_DETAILS:
      return { ...state, userInfo: action.payload }

    case IS_USER_AUTHENTICATED:
      return { ...state, isUserAuthenticated: action.payload }

    case RESET:
      return initialState

    default:
      return state
  }
}

export default AuthReducer
