import React from 'react'
import PropTypes from 'prop-types'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import CancelIcon from '@mui/icons-material/Cancel'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { get } from 'utils/lodash'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE, INVOICED } = INVOICE_STATUS

const WorkOrderInvoiceStatusAlert = ({ status, invoiceNumber }) => {
  const getInvoiceInfoDetails = () => {
    const { type, title } = get(INVOICE_STATUS_DISPLAY, status, {})
    let msg = ''
    let icon

    if (status === NOT_READY_FOR_INVOICE) {
      msg = 'This work order is not ready to be invoiced'
      icon = <CancelIcon />
    }
    if (status === READY_FOR_INVOICE) {
      msg = 'This work order is ready to be invoiced'
      icon = <CheckCircleOutlineIcon />
    }
    if (status === INVOICED) {
      msg = `This work order is on invoice ${invoiceNumber}`
      icon = <TaskOutlinedIcon />
    }

    return { type, title, msg, icon }
  }

  const { type, title, msg, icon } = getInvoiceInfoDetails()

  return (
    <Alert severity={type} icon={icon}>
      <AlertTitle variant="h5" fontWeight={500}>
        {title}
      </AlertTitle>
      <Typography variant="h6" fontWeight={400}>
        {msg}
      </Typography>
    </Alert>
  )
}

WorkOrderInvoiceStatusAlert.propTypes = {
  status: PropTypes.string,
  invoiceNumber: PropTypes.number,
}

export default WorkOrderInvoiceStatusAlert
