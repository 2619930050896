import React from 'react'
import PropTypes from 'prop-types'

import { ChevronUp } from '@styled-icons/heroicons-outline/ChevronUp'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { memo } from 'utils/react'
import { HHTextField } from 'components/form-fields/v5'

const RecurrenceNumberTool = ({ recurCounter, onHandleChange, setRecCount }) => {
  return (
    <div className="number-tool">
      <HHTextField
        id="outlined-size-small"
        placeholder=""
        value={`${recurCounter}x`}
        variant="outlined"
        autoComplete="off"
        name="recurrenceTimes"
        defaultSize
        className="normal-textfield num"
        InputProps={{
          onChange: e => onHandleChange(e),
        }}
      />

      <div className="tool-section">
        <div className="upper">
          <ChevronUp className="chevron-up" onClick={() => setRecCount('sum')} />
        </div>
        <div className="lower">
          <ChevronDown className="chevron-down" onClick={() => setRecCount('sub')} />
        </div>
      </div>
    </div>
  )
}

RecurrenceNumberTool.defaultProps = {
  recurCounter: 1,
  setRecCount: null,
  onHandleChange: null,
}

RecurrenceNumberTool.propTypes = {
  recurCounter: PropTypes.number,
  setRecCount: PropTypes.func,
  onHandleChange: PropTypes.func,
}

export default memo(RecurrenceNumberTool)
