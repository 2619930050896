import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { get } from 'lodash'
import {
  selectBulkResequenceRouteSequenceLine,
  selectBulkResequenceRouteStopMarkers,
  selectBulkResequenceRows,
  setBulkResequenceStopsState,
} from 'slices/route/bulkResequenceStopsSlice'
import { getBulkStopData } from 'components/route-manager/BulkActions/settings'
import useHandleSequenceChange from 'components/route-manager/BulkActions/BulkResequenceStopsDialog/useHandleSequenceChange'
import StopListItem from 'components/route-manager/BulkActions/common/StopListItem/StopListItem'
import ToStopAvatarContent from 'components/route-manager/BulkActions/common/StopListItem/ToStopAvatarContent'

const RenderToStopListItem = props => {
  const { getValues, setValue } = useFormContext()
  const dispatch = useDispatch()
  const rows = useSelector(selectBulkResequenceRows)
  const routeStopMarkers = useSelector(selectBulkResequenceRouteStopMarkers)
  const routeSequenceLine = useSelector(selectBulkResequenceRouteSequenceLine)
  const { row } = props
  const { id: stopId, position, workOrderStatus } = getBulkStopData(row)

  const getTargetIndex = useCallback(
    (fromIndex, toPosition) => {
      if (!toPosition) return 0
      const values = getValues()
      const toInsertCount = rows.filter(({ id }) => !get(values, id, '') && id !== stopId).length
      let targetPosition = Math.max(1, toPosition)
      targetPosition = Math.min(targetPosition, rows.length + 1)
      const targetIndex = targetPosition - 1
      return targetIndex + toInsertCount
    },
    [rows, row?.id]
  )

  const onSuccess = data => dispatch(setBulkResequenceStopsState(data))

  const handleSequenceChange = useHandleSequenceChange({
    getTargetIndex,
    getValues,
    routeSequenceLine,
    routeStopMarkers,
    rows,
    setValue,
    stopId,
    onSuccess,
  })

  return (
    <Box pl={1} width="100%" flex={1}>
      <StopListItem
        row={row}
        AvatarContent={
          <ToStopAvatarContent
            stopId={stopId}
            position={position}
            workOrderStatus={workOrderStatus}
            toBeResequence
            onChange={handleSequenceChange}
          />
        }
      />
    </Box>
  )
}

RenderToStopListItem.propTypes = {
  row: PropTypes.object,
}

export default RenderToStopListItem
