import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import { handleError } from 'utils/error'

import T from 'T'
import { useLazyFetchAccountsLinkedToLateFeeQuery, useUnlinkAccountsToLateFeeMutation } from 'api/pricing/lateFeesCrud'
import HHBulkSelectAccountsDialog from 'components/customers/groups/account/add-remove/common/HHBulkSelectAccountsDialog'
import { get } from 'lodash'
import { selectSelectedLateFee } from 'slices/pricing/lateFeesSlice'

const BulkUnlinkLateFeesDialog = ({ isOpen = false, onClose }) => {
  const lateFee = useSelector(selectSelectedLateFee, shallowEqual)
  const [fetchAccountsLinkedToLateFee, { isLoading: fetchAccountsLinkedLoading, data: accountsLinkedData }] =
    useLazyFetchAccountsLinkedToLateFeeQuery()
  const lateFeeId = useMemo(() => get(lateFee, 'id'), [lateFee])
  const lateFeeName = useMemo(() => get(lateFee, 'feeName', 'late fee'), [lateFee])
  const [unlinkAccountsToLateFee, { isLoading: isUnlinkAccountsLoading }] = useUnlinkAccountsToLateFeeMutation()
  const allAccounts = useMemo(() => get(accountsLinkedData, 'accounts', []), [accountsLinkedData])

  const handleUnlinkAccountsToLateFee = ({ accounts }) => {
    const payload = { id: lateFeeId, accounts }
    unlinkAccountsToLateFee(payload)
      .unwrap()
      .then(() => {
        toast.success(accounts.length > 1 ? T.UNLINK_ACCOUNTS_TO_LATE_FEE_SUCCESS_MSG : T.UNLINK_ACCOUNT_TO_LATE_FEE_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen && lateFeeId) {
      fetchAccountsLinkedToLateFee({
        id: lateFeeId,
        includeTags: true,
      })
    }
  }, [isOpen, lateFeeId])

  return (
    <HHBulkSelectAccountsDialog
      isOpen={isOpen}
      onClose={onClose}
      rows={allAccounts}
      title={`Remove accounts from ${lateFeeName}`}
      confirmButtonTitle={T.REMOVE}
      ConfirmButtonProps={{
        color: 'error',
      }}
      onConfirm={handleUnlinkAccountsToLateFee}
      isConfirmLoading={isUnlinkAccountsLoading}
      isLoading={fetchAccountsLinkedLoading}
      useDisabledCheckedIcons
    />
  )
}

BulkUnlinkLateFeesDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default BulkUnlinkLateFeesDialog
