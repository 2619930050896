import React from 'react'

import RenderInvoiceGroupCell from './RenderInvoiceGroupCell'
import TopSection from './TopSection'

const RenderInvoiceGroupWrapper = params => {
  const { id: rowId } = params

  if (rowId === 'header') {
    return <TopSection />
  }

  return <RenderInvoiceGroupCell {...params} />
}

export default RenderInvoiceGroupWrapper
