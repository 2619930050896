import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material'
import { Trash } from '@styled-icons/heroicons-outline/Trash'
import Alert from '@mui/material/Alert'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { handleTooltipOnLargeText } from 'utils/helper'
import { CommonTextfield } from 'components/common'
import FeeSearch from './FeeSearch'

const FeeAdjustmentTableBody = ({
  feesList = [],
  adjustedTotal = 0,
  instanceFeeList = [],
  filteredinstanceFeeList = [],
  onStateChange,
  onAddRemoveFee,
  onRowInlineClick,
  onRowDataChange,
}) => {
  useEffect(() => handleTooltipOnLargeText())

  return (
    <TableBody className="table-body">
      {feesList &&
        Children.toArray(
          feesList.map((fee, index) => {
            const feeId = get(fee, 'feeId', '')
            const feeRefId = get(fee, 'feeRefId', '')
            const feeName = get(fee, 'feeName', '')
            const note = get(fee, 'note', '')
            const value = get(fee, 'value', '')
            const inlineEdit = get(fee, 'inlineEdit', false)
            const showNote = get(fee, 'showNote', false)
            return (
              <>
                <TableRow width="25%" onClick={() => onRowInlineClick(index)} className={inlineEdit ? 'edit-on' : 'edit-off'}>
                  <TableCell>
                    {!inlineEdit && feeName}
                    {inlineEdit && (
                      <FeeSearch
                        records={filteredinstanceFeeList}
                        selectedRecord={{ feeRefId, feeName }}
                        placeholder="Search"
                        onChange={(isChanged, newValue) => {
                          if (isChanged) {
                            onRowDataChange(index, 'feeRefId', newValue.feeRefId, newValue.feeName, newValue.showNote, newValue.value)
                            return
                          }
                          onStateChange(
                            'filteredinstanceFeeList',
                            instanceFeeList.filter(row => row.feeName.toLowerCase().indexOf(newValue) > -1)
                          )
                        }}
                        onBlur={() => onStateChange('filteredinstanceFeeList', instanceFeeList)}
                      />
                    )}
                  </TableCell>
                  <TableCell width="45%" className={`note edit-invoice ${note && !inlineEdit && 'trim-large-notes'}`}>
                    {!inlineEdit && (
                      <>
                        <span>{note || '-'}</span>
                        <div className="shadow" />
                        <Box component="p" display="none" className="tooltip">
                          {note}
                        </Box>
                      </>
                    )}

                    {inlineEdit && (
                      <CommonTextfield
                        placeholder="Note"
                        value={note}
                        onChange={event => onRowDataChange(index, 'note', event.target.value)}
                      />
                    )}
                  </TableCell>
                  <TableCell width="10%" align="center">
                    <Checkbox defaultChecked={showNote} onChange={event => onRowDataChange(index, 'showNote', event.target.checked)} />
                  </TableCell>
                  <TableCell className="price" width="10%" align="right">
                    <NumericFormat
                      className={`number-format common textfield no-outline${!inlineEdit && 'disabled'}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        textAlign: 'right',
                      }}
                      placeholder="$0.00"
                      value={value}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      name="value"
                      onValueChange={values => {
                        const { value: originalValue } = values
                        onRowDataChange(index, 'value', originalValue)
                      }}
                      decimalSeparator="."
                      decimalScale={2}
                      prefix="$"
                      disabled={!inlineEdit}
                    />
                  </TableCell>

                  <TableCell className="icon-col" width="10%" align="right">
                    <Trash
                      className="icon-w-16 cursor-pointer"
                      onClick={event => {
                        event.stopPropagation()
                        onAddRemoveFee(index, feeId)
                      }}
                    />
                  </TableCell>
                </TableRow>

                {adjustedTotal < 0 && value < 0 && (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Alert severity="error">
                        Invoice credit cannot be greater than invoice amount (total cannot be negative). Please adjust credit.
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })
        )}
      {(!feesList || feesList.length === 0) && (
        <TableRow>
          <TableCell colspan="100%" className="no-border">
            <div className="no-results">No Invoice Fees Found</div>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

FeeAdjustmentTableBody.propTypes = {
  feesList: PropTypes.array,
  adjustedTotal: PropTypes.number,
  instanceFeeList: PropTypes.array,
  filteredinstanceFeeList: PropTypes.array,
  onStateChange: PropTypes.func.isRequired,
  onAddRemoveFee: PropTypes.func.isRequired,
  onRowInlineClick: PropTypes.func.isRequired,
  onRowDataChange: PropTypes.func.isRequired,
}

export default memo(FeeAdjustmentTableBody)
