import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Dialog, DialogContent, Grid, Box } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import T from 'T'
import { capitalize, get, noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { useFormContext } from 'react-hook-form'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { formatDateToBEFormatDateFns } from 'utils/date'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'

const SelectRouteAndDateDialog = ({
  isOpen = false,
  dialogTitle = '',
  routeSelectionFieldKey = 'moveFromRouteId',
  onClose = noop,
  onConfirm = noop,
  DialogProps = {},
}) => {
  const theme = useTheme()
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const routes = get(routeMetaData, 'routes', [])

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const handleClose = () => {
    onClose()
    reset()
  }

  const serviceDate = watch('serviceDate')
  const routeIdErrorMessage = get(errors, `${routeSelectionFieldKey}.message`, false)
  const routeIdError = Boolean(routeIdErrorMessage)
  const serviceDateErrorMessage = get(errors, 'serviceDate.message', false)
  const serviceDateError = Boolean(serviceDateErrorMessage)

  const onSubmit = data => onConfirm(data)

  useEffect(() => {
    if (serviceDate) {
      getRoutesMetadata({ serviceDate: formatDateToBEFormatDateFns(serviceDate) }, true)
    }
  }, [serviceDate])

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleClose} {...DialogProps}>
      <HHDialogTitle title={dialogTitle} showCloseIcon onClose={handleClose} />
      <DialogContent>
        <Box>
          <Grid container rowGap={2}>
            <Grid item xs={12}>
              <Box mb={1}>
                <DesktopRoutePicker
                  rules={{ required: T.REQUIRED }}
                  name={routeSelectionFieldKey}
                  control={control}
                  routes={routes}
                  menuSx={{ zIndex: theme.zIndex.tooltip + 200 }}
                  helperText={routeIdErrorMessage}
                  error={routeIdError}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <HHFormDesktopDatePicker
                label={T.DATE}
                PopperProps={{ sx: { zIndex: theme.zIndex.tooltip + 200 } }}
                control={control}
                name="serviceDate"
                rules={{ required: T.REQUIRED }}
                TextFieldProps={{ deprecatedLabel: false, error: serviceDateError, helperText: serviceDateErrorMessage }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="small" onClick={handleClose} />
        <LoadingButton onClick={handleSubmit(onSubmit)} variant="contained" size="small">
          {capitalize(T.NEXT)}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

SelectRouteAndDateDialog.propTypes = {
  isOpen: PropTypes.bool,
  dialogTitle: PropTypes.string,
  routeSelectionFieldKey: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  DialogProps: PropTypes.object,
}

export default SelectRouteAndDateDialog
