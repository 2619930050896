import React, { useState } from 'react'
import PropTypes from 'prop-types'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import { noop } from 'lodash'
import LocationExpandView from './LocationExpandView'
import LocationActionMenu from './LocationActionMenu'

const VERTICAL = 'vertical'

const LocationAccordion = ({
  accountId,
  selectedLocation,
  onLocationChange = noop,
  onLocationStatusChange = noop,
  onSaveTags = noop,
  showActionsMenu = true,
}) => {
  const [locationAnchorEl, setLocationAnchorEl] = useState(null)

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={T.LOCATION}
        showActionIcon={showActionsMenu}
        actionType="ellipsis"
        onActionClick={event => setLocationAnchorEl(event.currentTarget)}
        sx={{ position: 'inherit', '& .MuiAccordionSummary-root': {} }}
      >
        {!selectedLocation && <HHSectionPlaceholder title="Add a service location above" />}

        {selectedLocation && (
          <LocationExpandView
            caller="accordion"
            onSaveTags={onSaveTags}
            accountId={accountId}
            selectedLocation={selectedLocation}
            orientation={VERTICAL}
            dynamicWidth
            showNotes
          />
        )}
      </HHAccordion>
      <LocationActionMenu
        onLocationChange={onLocationChange}
        anchorEl={locationAnchorEl}
        selectedLocation={selectedLocation}
        accountId={accountId}
        onClose={() => setLocationAnchorEl(null)}
        onLocationStatusChange={onLocationStatusChange}
      />
    </>
  )
}

LocationAccordion.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  onLocationChange: PropTypes.func,
  onLocationStatusChange: PropTypes.func,
  onSaveTags: PropTypes.func,
  showActionsMenu: PropTypes.bool.isRequired,
}

export default LocationAccordion
