import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Check } from '@mui/icons-material'
import { noop } from 'lodash'
import HHTextField from './HHTextField'
import SelectItem from './SelectItem'

const HHFormSelectField = ({
  sx = undefined,
  name,
  control,
  disabled = false,
  label = undefined,
  placeholder = '',
  size = 'medium',
  autocomplete = 'off',
  variant = 'outlined',
  rules = undefined,
  required = false,
  fullWidth = false,
  InputProps = {},
  options,
  getOptionValue = option => option?.value,
  getOptionLabel = option => option?.label,
  multiple = false,
  onChange = noop,
  SelectProps = {},
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? { ...rules, required: required || rules?.required === true } : { required: required || rules?.required === true }}
      render={({ field }) => {
        const { ref, onChange: onChangeHookForm, onBlur, ..._rest } = field
        const handleChange = !multiple
          ? onChangeHookForm
          : e => {
              e.target.value = typeof value === 'string' ? e.target.value.split(',') : e.target.value
              onChangeHookForm(e)
            }
        const handleChangeWrapper = e => {
          handleChange(e)
          onChange(e.target.value)
        }
        return (
          <HHTextField
            label={label}
            required={required || rules?.required === true}
            sx={sx}
            disabled={disabled}
            placeholder={placeholder}
            size={size}
            autoComplete={autocomplete}
            InputProps={InputProps}
            variant={variant}
            inputRef={ref}
            onChange={handleChangeWrapper}
            onBlur={onBlur}
            {...rest}
            {..._rest}
            fullWidth={fullWidth}
            select
            SelectProps={{ ...SelectProps, multiple }}
          >
            {options.map(option => {
              const value = getOptionValue(option)
              const isSelected = !multiple ? value === field?.value : Array.isArray(field?.value) && field.value.includes(value)
              return (
                <SelectItem disableGutters key={option?.key || `${name}-option-${getOptionLabel(option)}`} value={value}>
                  {getOptionLabel(option)}
                  {isSelected && <Check sx={{ display: 'none' }} />}
                </SelectItem>
              )
            })}
          </HHTextField>
        )
      }}
    />
  )
}

HHFormSelectField.propTypes = {
  sx: PropTypes.object,
  SelectProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  autocomplete: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  rules: PropTypes.object,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  InputProps: PropTypes.object,
  options: PropTypes.array.isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
}

export default HHFormSelectField
