import React, { useState, useEffect, useCallback } from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import { Box, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { get } from 'utils/lodash'
import { getExpandedRows } from 'data/groups/accountGroupsSelectors'
import { useLazyGetAccountGroupListQuery } from 'api/groups/account/getAccountGroupList'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import CustomerSideNav from 'components/customers/common/side-nav/CustomerSideNav'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import AccountGroupHeader from 'components/customers/groups/account/AccountGroupHeader'
import RenderAccountGroupWrapper from 'components/customers/groups/account/RenderAccountGroupWrapper'
import { BATCH_ACCOUNT_DATA_GRID_ROW_HEIGHT } from './settings'

const AccountGroups = () => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const [getAccountGroupList, { isFetching, isLoading, data: accountGroupList }] = useLazyGetAccountGroupListQuery()
  const isAccountGroupListLoading = isFetching || isLoading
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const [isCustomGroupExpanded, setIsCustomGroupExpanded] = useState(true)

  const handleCustomGroupExpandCollapse = () => setIsCustomGroupExpanded(!isCustomGroupExpanded)

  const getColumnSize = () => {
    if (isXl) return 3
    if (isLg) return 2
    return 1
  }

  const noOfColumns = getColumnSize()
  const rows = get(accountGroupList, 'accountGroups', []).map((row, index) => ({ ...row, index }))
  const expandedRows = getExpandedRows({ rows, isExpanded: isCustomGroupExpanded, noOfColumns })

  const getRowSpacing = useCallback(
    params => ({
      top: 8,
      bottom: params.isLastVisible ? 32 : 8,
    }),
    []
  )

  const getRowHeight = useCallback(() => 'auto', [])

  useEffect(() => {
    getAccountGroupList()
  }, [])

  return (
    <>
      <CustomerSideNav />
      <CustomerLayout open={showCustomerSideNav}>
        <AccountGroupHeader />
        <Box display="flex" height="100%" overflow="auto">
          <Box flex="1" position="relative">
            <Box sx={{ position: 'absolute', inset: 0 }}>
              <DataGridPro
                loading={isAccountGroupListLoading}
                slots={{ noRowsOverlay: () => <HHSectionPlaceholder title={T.NO_ACCOUNT_GROUPS} />, loadingOverlay: LinearProgress }}
                rows={expandedRows}
                hideFooter
                getRowId={({ groupId }) => groupId}
                checkboxSelection={false}
                columnHeaderHeight={0}
                columns={[
                  {
                    flex: 1,
                    field: 'id',
                    renderCell: ({ row }) => (
                      <RenderAccountGroupWrapper
                        originalRows={rows}
                        row={row}
                        noOfColumns={noOfColumns}
                        isCustomGroupExpanded={isCustomGroupExpanded}
                        handleCustomGroupExpandCollapse={handleCustomGroupExpandCollapse}
                      />
                    ),
                  },
                ]}
                disableColumnSelector
                getRowSpacing={getRowSpacing}
                getRowHeight={getRowHeight}
                getEstimatedRowHeight={() => BATCH_ACCOUNT_DATA_GRID_ROW_HEIGHT}
                isRowSelectable={false}
                sx={{
                  border: 'none',
                  '& .MuiDataGrid-columnHeaders': {
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    border: 'none',
                    padding: 0,
                    background: theme.palette.background.paper,
                    outline: '0!important',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </CustomerLayout>
    </>
  )
}

export default AccountGroups
