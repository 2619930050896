import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getUsersList: build.query({
      query: ({ page, size }) => ({
        url: '/api/v1/core/settings/users',
        method: 'GET',
        params: { page, size },
      }),
    }),
  }),
})

export const { useLazyGetUsersListQuery } = extendedApi
