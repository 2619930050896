import React, { memo } from 'react'
import T from 'T'
import { Grid, Typography } from '@mui/material'
import SearchSharpIcon from '@mui/icons-material/SearchSharp'

const NoOptionsFound = () => {
  return (
    <Grid container direction="column" alignItems="center">
      <SearchSharpIcon />
      <Typography variant="h6">{T.NO_CUSTOMERS}</Typography>
      <Typography>{T.SEARCH_TRY_AGAIN}</Typography>
    </Grid>
  )
}

export default memo(NoOptionsFound)
