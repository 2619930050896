import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useSelector, shallowEqual } from 'react-redux'
import Box from '@mui/material/Box'
import CommonSwitch from 'components/common/CommonSwitch'
import T from 'T'

import { canUpdateSettings, canUpdateUsers, canUpdatePermissions, canSeePermissionSettings } from 'data/permissions/permissionsSelectors'
import { get } from 'utils/lodash'

import AddRoles from '../roles/AddRoles'

const ProfileScreen = ({
  userState = {},
  setUserState = {},
  roleState = noop,
  setRoleState = noop,
  handleSave = noop,
  handleCancel = noop,
}) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const seePermissionSettings = useSelector(canSeePermissionSettings, shallowEqual)

  const handleSwitchVal = value => {
    if (updateSettings && updatePermissions && updateUsers) {
      setUserState({ changeIndividualPermissions: value, footerFlag: true })
    }
  }

  return (
    <Box
      sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none',
          '& .MuiGrid-item': {
            pt: 1,
          },
        },
      }}
    >
      {seePermissionSettings && (
        <CommonSwitch
          titlePosition="right"
          title={T.CHANGE_INDIVIDUAL_PERMISSIONS}
          isChecked={get(userState, 'changeIndividualPermissions', false)}
          onChange={handleSwitchVal}
        />
      )}

      <AddRoles
        roleState={roleState}
        setRoleState={setRoleState}
        handleSave={handleSave}
        handleCancel={handleCancel}
        calledBy={T.PEOPLE}
        readonly={get(userState, 'changeIndividualPermissions', false) ? '' : 'none'}
      />
    </Box>
  )
}

ProfileScreen.propTypes = {
  userState: PropTypes.object,
  roleState: PropTypes.object,
  setUserState: PropTypes.func,
  setRoleState: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
}

export default ProfileScreen
