import React from 'react'

import PaymentGroups from 'containers/billing/groups/payment'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'

const PaymentGroupsPage = () => (
  <ProtectedRouteHoC>
    <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
      <PaymentGroups />
    </NavbarWrapper>
  </ProtectedRouteHoC>
)

export default PaymentGroupsPage
