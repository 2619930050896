import T from 'T'
import routes from 'router/routes'
import capitalize from 'lodash/capitalize'

export const SERVICES_MENU = [
  { label: T.SERVICES, url: routes.app.pricingServices },
  {
    label: capitalize(`${T.SERVICE_GROUP}s`),
    url: routes.app.pricingServiceGroups,
  },
]

export const FEES_MENU = [{ label: T.GENERAL, url: routes.app.pricingFees }]

export const TAXES_MENU = [{ label: T.TAXES, url: routes.app.pricingTaxes }]
