import React from 'react'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import PropTypes from 'prop-types'
import StyledTextField from './StyledTextField'
import WithInputLabel from './WithInputLabel'
import StyledAutocomplete from './StyledAutocomplete'

const HHAutocompleteField = ({ label, debug = false, deprecatedLabel = true, ...rest }) => {
  return (
    <WithInputLabel label={deprecatedLabel && label}>
      <StyledAutocomplete
        debug={debug}
        popupIcon={<KeyboardArrowDownRounded />}
        renderInput={params => {
          return <StyledTextField label={deprecatedLabel ? null : label} size="small" {...params} />
        }}
        {...rest}
      />
    </WithInputLabel>
  )
}

HHAutocompleteField.propTypes = {
  label: PropTypes.string,
  deprecatedLabel: PropTypes.bool,
  debug: PropTypes.bool,
}

export default HHAutocompleteField
