import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    updateInvoiceFee: build.mutation({
      query: body => ({ url: '/api/invoice/fee/update', method: 'POST', body }),
    }),
  }),
})

export const { useUpdateInvoiceFeeMutation } = extendedApi
