import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { grey, red, green } from '@mui/material/colors'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'

import { get } from 'utils/lodash'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { getInvoicesByStatus } from 'data/invoice/invoiceSelectors'

import T from 'T'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS

const COLOR_MAP = {
  [PARTIAL]: grey[200],
  [UNPAID]: red[100],
  [PAID]: green[100],
}

const InvoiceStatusStatistic = ({ invoices = [], showFooter = true }) => {
  const invoicesByStatus = getInvoicesByStatus({ invoices })
  const data = [
    {
      id: 'Draft',
      label: 'Draft',
      value: get(invoicesByStatus, PARTIAL, []).length,
      color: COLOR_MAP[PARTIAL],
    },
    {
      id: UNPAID,
      label: UNPAID,
      value: get(invoicesByStatus, UNPAID, []).length,
      color: COLOR_MAP[UNPAID],
    },
    {
      id: PAID,
      label: PAID,
      value: get(invoicesByStatus, PAID, []).length,
      color: COLOR_MAP[PAID],
    },
  ]

  return (
    <>
      <Box height={250} position="relative">
        <GroupPieChart data={data} colors={Object.values(COLOR_MAP)} />
        <GroupChartOverlay overlayValue={invoices.length} />
      </Box>
      {showFooter && <GroupChartName Icon={CurrencyDollar} label={T.INVOICE_STATUS} />}
    </>
  )
}

InvoiceStatusStatistic.propTypes = {
  invoices: PropTypes.array,
  showFooter: PropTypes.bool,
}

export default InvoiceStatusStatistic
