import { getServiceType } from 'components/pricing/pricingMethods'
import { get } from 'utils/lodash'
import { createSelector } from 'reselect'
import { getPricingPeriod } from 'utils/service'

const selectPeriodPer = service => get(service, 'pricingPeriodPeriodPer', '')
const selectPeriodInterval = service => get(service, 'pricingPeriodPeriodInterval', '')
const selectMaterialType = service => get(service, 'materialType', '')
const selectBusinessLine = service => get(service, 'serviceBusinessLine', '')
const selectServiceType = service => getServiceType(service)
const selectVolume = service => get(service, 'measureVolume', '')
const selectActionType = service => get(service, 'serviceActionType', '')
const selectServiceName = service => get(service, 'serviceName', '')
const selectPrice = service => get(service, 'serviceValue', 0)
const selectMeasureUnit = service => get(service, 'measureUnit', '')
const selectMethodTypeIcon = service => get(service, 'methodType', '').replaceAll(' ', '')
export const getParsedMatchedService = createSelector(
  [
    selectPeriodPer,
    selectPeriodInterval,
    selectMaterialType,
    selectBusinessLine,
    selectServiceType,
    selectVolume,
    selectActionType,
    selectServiceName,
    selectPrice,
    selectMeasureUnit,
    selectMethodTypeIcon,
  ],
  (
    periodPer,
    periodInterval,
    materialType,
    businessLine,
    serviceType,
    volume,
    actionType,
    serviceName,
    price,
    measureUnit,
    methodTypeIcon
  ) => {
    return {
      periodPer,
      periodInterval,
      materialType,
      businessLine,
      serviceType,
      volume,
      actionType,
      serviceName,
      price,
      measureUnit,
      methodTypeIcon,
      pricingPeriod: getPricingPeriod(periodPer, periodInterval),
    }
  }
)
