import T from 'T'
import { get } from './lodash'

export const getLocationsWithCFCount = (locations = [], cfCount = []) =>
  locations.map(location => {
    const matchedLocation = cfCount.find(service => service.locationId === location.id)
    return { ...location, serviceCount: get(matchedLocation, 'serviceCount', 0) }
  })

export const getActiveDeactivatedLocations = (customerDetails, attachCFCount = false) => {
  const activeLocations = get(customerDetails, 'locations', []).filter(
    location => !get(location, 'deactivated', false) && get(location, 'address', '')
  )

  const deactivatedLocations = get(customerDetails, 'locations', []).filter(
    location => get(location, 'deactivated', false) && get(location, 'address', '')
  )

  const cfCount = get(customerDetails, 'cfCount', [])
  return { activeLocations: attachCFCount ? getLocationsWithCFCount(activeLocations, cfCount) : activeLocations, deactivatedLocations }
}

export const getLocationsWithNoActiveServices = locations => {
  if (!Array.isArray(locations)) {
    return []
  }

  return locations.filter(location => get(location, 'serviceCount', 0) < 1)
}

export const getLocationStatusChangeAndMessage = (isAccountActive, hasActiveServices, isDeactivated) => {
  if (hasActiveServices && !isDeactivated) {
    return { disabled: true, message: T.CANT_DEACTIVATE_LOCATION }
  }

  if (!isAccountActive && isDeactivated) {
    return { disabled: true, message: T.REACTIVATE_ACCOUNT }
  }

  return { disabled: false, message: '' }
}
