import { get } from 'utils/lodash'
import { toast } from 'react-toastify'

import { store } from 'providers/store'
import { putIsLoading } from 'middleware/actions/response'
import { STATUS_CODE } from 'settings/status'
import { ERROR_EXCEPTIONS } from 'settings/error'

import Constants from 'Constants'
import APIInstance, { APIInstancePublic } from 'providers/api-instance'
import { handleFirebaseLogout } from 'providers/firebaseLogout'
import { getToken } from 'providers/firebaseAuth'
import { getSelectedBusinessId } from 'data/select-account/selectAccount'

const { UNAUTHORIZED } = STATUS_CODE
const { INVALID_USER_EXCEPTION } = ERROR_EXCEPTIONS
const { BUSINESS_IDENTIFIER } = Constants

const handleCatch = errorInfo => {
  console.log('*** There was a server error!')
  console.log(errorInfo)
  console.log('***')

  const exceptionType = get(errorInfo, 'response.data.exceptionType')
  const errorMsg = get(errorInfo, 'response.data.message') || get(errorInfo, 'response.data.error')
  const statusCode = errorInfo?.response?.status

  if (errorMsg) {
    // Show toast
    toast.error(errorMsg)
  }

  if (statusCode === UNAUTHORIZED) {
    handleFirebaseLogout()
    return
  }

  // Initiate logout if API returns Invalid User
  if (exceptionType === INVALID_USER_EXCEPTION) {
    handleFirebaseLogout()
  }
}

const handleFinally = (showLoader, isLoaderAutoOff) => {
  if (showLoader && isLoaderAutoOff) {
    // hide loader
    store.dispatch(putIsLoading(false))
  }
}

// Common method for all post calls
export const doPost = async ({
  url,
  body = {},
  showLoader = false,
  isLoaderAutoOff = true,
  arraybuffer = '',
  isPublic = false,
  contentType = '',
}) => {
  let headers = {}
  const token = await getToken()
  const state = store.getState()
  const businessId = getSelectedBusinessId(state)

  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        [BUSINESS_IDENTIFIER]: businessId,
        ...(contentType && { 'Content-Type': contentType }),
      },
    }
  }

  if (arraybuffer) {
    headers.responseType = arraybuffer
  }

  if (showLoader) {
    store.dispatch(putIsLoading(true))
  }

  if (isPublic) {
    return APIInstancePublic.post(url, body, headers)
      .catch(errorInfo => handleCatch(errorInfo))
      .finally(() => handleFinally(showLoader, isLoaderAutoOff))
  }

  return APIInstance.post(url, body, headers)
    .catch(errorInfo => handleCatch(errorInfo))
    .finally(() => handleFinally(showLoader, isLoaderAutoOff))
}

// Common method for all get calls
export const doGet = async ({ url, showLoader = false, isLoaderAutoOff = true, arraybuffer = '', isPublic = false }) => {
  let headers = {}
  const token = await getToken()
  const state = store.getState()
  const businessId = getSelectedBusinessId(state)

  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        [BUSINESS_IDENTIFIER]: businessId,
      },
    }
  }

  if (arraybuffer) {
    headers.responseType = arraybuffer
  }

  if (showLoader) {
    store.dispatch(putIsLoading(true))
  }

  if (isPublic) {
    return APIInstancePublic.get(url, headers)
      .catch(errorInfo => handleCatch(errorInfo))
      .finally(() => handleFinally(showLoader, isLoaderAutoOff))
  }

  return APIInstance.get(url, headers)
    .catch(errorInfo => handleCatch(errorInfo))
    .finally(() => handleFinally(showLoader, isLoaderAutoOff))
}
