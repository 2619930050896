import React from 'react'
import PropTypes from 'prop-types'

import { Alert, Grid } from '@mui/material'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'

import T from 'T'

const DragPinMessage = ({ latitude = '', longitude = '', sx = {} }) => {
  const isValidLatLong = isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)

  return (
    isValidLatLong && (
      <Grid item xs={12}>
        <Alert sx={{ display: 'flex', alignItems: 'center', ...sx }} severity="info">
          {T.DRAG_PIN_MESSAGE}
        </Alert>
      </Grid>
    )
  )
}

DragPinMessage.propTypes = {
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  sx: PropTypes.object,
}

export default DragPinMessage
