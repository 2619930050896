import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateWorkOrdersDisposalTicket: builder.mutation({
      query: ({ workOrderId, body, disposalTicketId }) => ({
        url: `/api/v1/core/work-orders/${workOrderId}/disposal-tickets/${disposalTicketId}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useUpdateWorkOrdersDisposalTicketMutation } = extendedApi
