import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    updateReportConfigurator: build.mutation({
      query: ({ body, id }) => ({
        url: `/api/v1/core/report-configs/${id}`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
})

export const { useUpdateReportConfiguratorMutation } = extendedApi
