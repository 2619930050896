import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'
import { ListItem, ListItemText, Collapse, List, IconButton, Link, Box, Typography, Grid } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { CONTACT_METHODS } from 'settings/constants/contacts'
import { canUpdateCustomerManager } from 'data/permissions/permissionsSelectors'
import { SERVICE_NOTIFICATIONS_METHODS, BILLING_NOTIFICATIONS_METHODS } from 'settings/constants/notifications'

import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import BillingServiceNotificationAvatar from 'components/common/avatar/BillingServiceNotificationAvatar'
import ContactStatusAvatar from 'components/common/avatar/ContactStatusAvatar'
import ContactMethodIcon from './ContactMethodIcon'

const { EMAIL } = CONTACT_METHODS

const ContactView = ({ contact = {}, showActionMenu = false, onActionClick }) => {
  const [open, setOpen] = useState(false)
  const updateCustomerManager = useSelector(canUpdateCustomerManager, shallowEqual)
  const contactName = `${get(contact, 'firstName', '')} ${get(contact, 'lastName', '')}`.trim() || '- - '
  const contactMethods = get(contact, 'contactMethods', [])
  const isDeactivated = get(contact, 'deactivated')
  const hasContactMethods = contactMethods.length > 0
  const firstMethodType = get(contactMethods, '[0].methodType')
  const firstMethodValue = get(contactMethods, '[0].methodValue')

  const handleClick = () => setOpen(!open)

  return (
    <>
      <ListItem>
        <IconButton size="small" disabled={!hasContactMethods} onClick={handleClick}>
          {open ? <ExpandMore /> : <ChevronRight />}
        </IconButton>

        <ListItemText
          sx={{ display: 'grid' }}
          primary={contactName}
          secondary={
            hasContactMethods && !open ? (
              <Typography color="textSecondary" noWrap>
                {firstMethodType === EMAIL ? firstMethodValue : <HHPhoneField displayType="text" value={firstMethodValue} />}
              </Typography>
            ) : null
          }
        />

        {isDeactivated && <ContactStatusAvatar isActive={!isDeactivated} />}
        {showActionMenu && updateCustomerManager && (
          <IconButton size="small" onClick={onActionClick}>
            <MoreVertIcon />
          </IconButton>
        )}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {Children.toArray(
            contactMethods.map(method => {
              const methodType = get(method, 'methodType')
              const methodValue = get(method, 'methodValue')
              const serviceNotifications = get(method, 'serviceNotifications', false)
              const billingNotifications = get(method, 'billingNotifications', false)

              return (
                <ListItem
                  disablePadding
                  sx={{ pt: 0, pl: 6, pr: 2.5, pb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Grid container wrap="nowrap" display="flex" alignItems="center" spacing={0.5}>
                    <Grid item display="flex">
                      <ContactMethodIcon type={methodType} />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap display="grid">
                        {methodType === EMAIL ? (
                          <Link
                            overflow="hidden"
                            textOverflow="ellipsis"
                            href={`mailto:${methodValue}`}
                            color="text.primary"
                            underline="none"
                          >
                            {methodValue}
                          </Link>
                        ) : (
                          <Link href={`tel:${methodValue}`} color="text.primary" underline="none">
                            <HHPhoneField displayType="text" value={methodValue} />
                          </Link>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        {BILLING_NOTIFICATIONS_METHODS.includes(methodType) && (
                          <BillingServiceNotificationAvatar type="Billing" isActive={billingNotifications} styles={{ mr: 0.5 }} />
                        )}
                        {SERVICE_NOTIFICATIONS_METHODS.includes(methodType) && (
                          <BillingServiceNotificationAvatar type="Service" isActive={serviceNotifications} />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })
          )}
        </List>
      </Collapse>
    </>
  )
}

ContactView.propTypes = {
  contact: PropTypes.object,
  showActionMenu: PropTypes.bool,
  onActionClick: PropTypes.func.isRequired,
}

export default ContactView
