import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addUpdateLocation: build.mutation({
      query: body => ({ url: '/api/location', method: 'POST', body }),
    }),
  }),
})

export const { useAddUpdateLocationMutation } = extendedApi
