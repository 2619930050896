import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
// eslint-disable-next-line import/no-cycle
import { getInvoicesPDFStatus, isDraftInvoice, isUnPaidInvoice } from 'utils/billing'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { usePostInvoiceListMutation } from 'api/billing/postInvoiceList'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import { useInvoiceBulkMarkPaidMutation } from 'api/billing/invoiceBulkMarkPaid'
import { useInvoiceBulkMarkSentMutation } from 'api/billing/invoiceBulkMarkSent'
import { useUnpayInvoiceMutation } from 'api/billing/unpayInvoice'
import {
  isPostInvoiceBtnEnabled,
  isRegenerateBtnEnabled,
  isMarkAsPaidBtnEnabled,
  isMarkAsSentBtnEnabled,
  isEditBtnEnabled,
  isDeleteBtnEnabled,
  isMakePaymentEnabled,
  isRevertToDraftEnabled,
  isDeleteUnpaidEnabled,
  isRevertToUnpaidEnabled,
} from 'data/billing/InvoiceActionSelectors'

import T from 'T'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
// eslint-disable-next-line import/no-cycle
import {
  setInvoicesSendPostData,
  setInvoicesSideView,
  setInvoicesDeleteConfirmation,
  setInvoicesRegenerateConfirmation,
  setInvoicesPaymentsState,
  setInvoicesRevertToDraftConfirmation,
  setInvoicesDeleteUnpaidConfirmation,
  setAddToGroupDialogState,
} from '../../../slices/billing/invoicesSlice'
import api from '../../../api'
import { CACHE_TAG_INVOICE_LIST } from '../../../api/cacheTagTypes'

const TIMEOUT = 4000

const RenderGetInvoiceActionsDataCell = params => {
  const { row } = params
  const dispatch = useDispatch()
  const isEditInvoiceWOListingOpen = useSelector(state => state.invoices.sideView.isEditInvoiceWOListingOpen)
  const [sendInvoiceList] = useSendInvoiceListMutation()
  const [postInvoiceList] = usePostInvoiceListMutation()
  const [regenerateInvoiceList] = useRegenerateInvoiceListMutation()
  const [invoiceBulkMarkPaid] = useInvoiceBulkMarkPaidMutation()
  const [invoiceBulkMarkSent] = useInvoiceBulkMarkSentMutation()
  const [unpayInvoice] = useUnpayInvoiceMutation()

  const showPostAction = isPostInvoiceBtnEnabled(row)
  const showRegenerateAction = isRegenerateBtnEnabled(row)
  const showMarkAsPaidAction = isMarkAsPaidBtnEnabled(row)
  const showMarkAsSentAction = isMarkAsSentBtnEnabled(row)
  const showEditAction = isEditBtnEnabled(row)
  const showDeleteAction = isDeleteBtnEnabled(row)
  const showDeleteUnpaidAction = isDeleteUnpaidEnabled(row)
  const showMakePaymentAction = isMakePaymentEnabled(row)
  const showRevertToDraft = isRevertToDraftEnabled(row)
  const showRevertToUnpaidAction = isRevertToUnpaidEnabled(row)

  const handleRefetch = (timeout = 0) => setTimeout(() => dispatch(api.util.invalidateTags([CACHE_TAG_INVOICE_LIST])), timeout)

  const handleAPISuccess = (message, refetch = false, timer = false) => {
    if (timer) {
      toast.success(message, {
        hideProgressBar: false,
        autoClose: TIMEOUT,
        onClose: () => {
          if (refetch) handleRefetch()
        },
      })
      return
    }

    toast.success(message)
    if (refetch) handleRefetch()
  }

  const handleSendInvoice = async () => {
    try {
      dispatch(putIsLoading(true))
      const { invoiceNumber, status } = row
      let withPDF = [invoiceNumber]
      let withoutPDF = []

      if (isDraftInvoice(status)) {
        const result = await getInvoicesPDFStatus(withPDF)
        withPDF = result.withPDF
        withoutPDF = result.withoutPDF
      }

      const hasInvoicesWithoutPDF = withoutPDF.length > 0

      if (withPDF.length) {
        await sendInvoiceList({ invoiceNumbers: withPDF })
          .unwrap()
          .then(() => {
            if (hasInvoicesWithoutPDF) {
              dispatch(setInvoicesSendPostData({ isOpenInvoiceSendFailureConfirmation: true, draftInvoicesWithoutPDF: withoutPDF }))
              handleRefetch(TIMEOUT)
              return
            }
            handleAPISuccess(T.SEND_INVOICE_SUCCESS, true, true)
          })
          .catch(handleError)
      } else if (hasInvoicesWithoutPDF) {
        dispatch(
          setInvoicesSendPostData({
            isOpenInvoiceSendFailureConfirmation: true,
            draftInvoicesWithoutPDF: withoutPDF,
          })
        )
      }
    } finally {
      dispatch(putIsLoading(false))
    }
  }

  const handlePostInvoice = () => {
    dispatch(putIsLoading(true))
    postInvoiceList({ invoiceNumbers: [row.invoiceNumber] })
      .unwrap()
      .then(() => handleAPISuccess(T.POST_INVOICE_SUCCESS))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleRegenerateInvoice = () => {
    dispatch(putIsLoading(true))
    regenerateInvoiceList({ invoiceIds: [row.invoiceId] })
      .unwrap()
      .then(() => handleAPISuccess(T.REGENERATE_INVOICES_SUCCESS_MSG))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleValidateRegenerateInvoice = () => {
    const { invoiceNumber, status, invoiceId } = row
    if (isUnPaidInvoice(status)) {
      dispatch(
        setInvoicesRegenerateConfirmation({
          isOpen: true,
          invoiceIds: [invoiceId],
          invoiceNumbers: [invoiceNumber],
          invoices: [row],
          isMultiSelect: false,
        })
      )
      return
    }

    handleRegenerateInvoice()
  }

  const handleInvoiceMarkPaid = () => {
    dispatch(putIsLoading(true))
    invoiceBulkMarkPaid({ invoiceIds: [row.invoiceId] })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccess(T.MARK_AS_PAID_SUCCESS, true, true)
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleInvoiceMarkSent = () => {
    dispatch(putIsLoading(true))
    invoiceBulkMarkSent({ invoiceIds: [row.invoiceId] })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          handleAPISuccess(T.MARK_AS_SENT_SUCCESS, true, true)
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleEditAction = () => {
    dispatch(
      setInvoicesSideView({
        isOpen: false,
        isEditInvoiceWOListingOpen: !isEditInvoiceWOListingOpen,
        currentInvoice: { ...row, id: row?.invoiceId, accountName: row?.customerName },
      })
    )
  }

  const handleDeleteInvoice = () => {
    const { invoiceNumber, invoiceId } = row
    dispatch(setInvoicesDeleteConfirmation({ isOpen: true, invoiceIds: [invoiceId], invoiceNumbers: [invoiceNumber] }))
  }

  const handleMakePayment = () => dispatch(setInvoicesPaymentsState({ selectedRow: row, isOpenTakePaymentsDialog: true }))

  const handleRevertToDraft = () => {
    dispatch(setInvoicesRevertToDraftConfirmation({ isOpen: true, invoiceId: row.invoiceId }))
  }

  const handleDeleteUnpaidInvoice = () => {
    dispatch(setInvoicesDeleteUnpaidConfirmation({ isOpen: true, invoiceId: row.invoiceId }))
  }

  const handleRevertToUnpaid = () => {
    dispatch(putIsLoading(true))
    unpayInvoice({ invoiceId: [row.invoiceId] })
      .unwrap()
      .then(() => handleAPISuccess(T.REVERT_INVOICE_TO_UNPAID_SUCCESS, true, true))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleAddToBatch = () => dispatch(setAddToGroupDialogState({ isOpen: true, invoiceIds: [row.invoiceId] }))

  return [
    showMakePaymentAction ? <GridActionsCellItem label={T.APPLY_PAYMENT} showInMenu onClick={handleMakePayment} /> : null,
    showEditAction ? <GridActionsCellItem label={T.EDIT} showInMenu onClick={handleEditAction} /> : null,
    showMarkAsSentAction ? <GridActionsCellItem label={T.MARK_AS_SENT} showInMenu onClick={handleInvoiceMarkSent} /> : null,
    showMarkAsPaidAction ? <GridActionsCellItem showInMenu label={T.MARK_AS_PAID} onClick={handleInvoiceMarkPaid} /> : null,
    showRegenerateAction ? <GridActionsCellItem showInMenu label={T.REGENERATE} onClick={handleValidateRegenerateInvoice} /> : null,
    <GridActionsCellItem showInMenu label={T.SEND} onClick={handleSendInvoice} />,
    showPostAction ? <GridActionsCellItem showInMenu label={T.POST} onClick={handlePostInvoice} /> : null,
    showRevertToUnpaidAction ? <GridActionsCellItem showInMenu label={T.REVERT_TO_UNPAID} onClick={handleRevertToUnpaid} /> : null,
    <GridActionsCellItem label={T.ADD_TO_INVOICE_GROUP} showInMenu onClick={handleAddToBatch} />,
    showRevertToDraft ? (
      <GridActionsCellItem sx={{ color: 'warning.main' }} showInMenu label={T.CHANGE_BACK_TO_DRAFT} onClick={handleRevertToDraft} />
    ) : null,
    showDeleteUnpaidAction ? (
      <GridActionsCellItem sx={{ color: 'error.main' }} showInMenu label={T.DELETE} onClick={handleDeleteUnpaidInvoice} />
    ) : null,
    showDeleteAction ? (
      <GridActionsCellItem sx={{ color: 'error.main' }} showInMenu label={T.DELETE} onClick={handleDeleteInvoice} />
    ) : null,
  ].filter(Boolean)
}

RenderGetInvoiceActionsDataCell.propTypes = {
  selectedInvoice: PropTypes.object,
}

export default RenderGetInvoiceActionsDataCell
