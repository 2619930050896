import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox } from '@mui/material'
import { WORK_ORDER_STATUS } from 'settings/constants/service'
import { get } from 'utils/lodash'
import { useSelector, useDispatch } from 'react-redux'
import noop from 'lodash/noop'
import RouteCard, { formatRouteData } from 'containers/new-route-manager/RouteCard/RouteCard'
import { setStopDetailsDialogState } from 'slices/route/routeSlice'
import { canMoveStopToAnotherRoute } from '../helper'

const { SERVICED, SKIPPED } = WORK_ORDER_STATUS

const RouteItem = ({
  editRouteStops = false,
  selectedWorkOrderID = undefined,
  currentRouteId = undefined,
  handleSelectClick = noop,
  onTagsEditSave = noop,
  isCheck = [],
  showRS = false,
  scheduledStops = undefined,
  routeIndex = 0,
}) => {
  const {
    accountId,
    accountName,
    action,
    address,
    locationId,
    measureUnit,
    measureLabel,
    pricedServiceType,
    tags,
    workOrderId,
    serviceLocationName,
    routeId,
  } = formatRouteData(scheduledStops)
  const dispatch = useDispatch()
  const workOrderStatus = get(scheduledStops, 'workOrderStatus', '')
  const hasValidStatus = canMoveStopToAnotherRoute(workOrderStatus)
  let stopClass
  const isWorkOrderComplete = [SERVICED, SKIPPED].includes(workOrderStatus)
  const isSelected = selectedWorkOrderID === workOrderId
  const routeSequence = showRS ? scheduledStops.sequence : routeIndex + 1
  const servicedAt = get(scheduledStops, 'servicedAt')
  const lastTargetRouteId = useSelector(s => s.Route.recentStopsMoved.routeId)
  const lastStopsMoved = useSelector(s => s.Route.recentStopsMoved.idMap)
  const wasStopRecentlyMoved = lastTargetRouteId === routeId && workOrderId in lastStopsMoved
  if (isWorkOrderComplete) {
    stopClass = 'unscheduled-stop stop-grade-out'
  } else {
    stopClass = 'unscheduled-stop'
  }
  const handleDoubleClick = () => !editRouteStops && dispatch(setStopDetailsDialogState({ id: workOrderId }))

  return (
    <Box
      className={stopClass}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onDoubleClick={handleDoubleClick}
      data-id={routeId}
    >
      {editRouteStops && currentRouteId === routeId && hasValidStatus && (
        <Checkbox
          color="primary"
          key={workOrderId}
          type="checkbox"
          name={accountName}
          value={accountName}
          id={workOrderId}
          onChange={handleSelectClick}
          checked={isCheck.includes(workOrderId)}
        />
      )}
      <RouteCard
        sx={{ bgcolor: wasStopRecentlyMoved && 'background.header' }}
        pricedServiceType={pricedServiceType}
        isSelected={isSelected}
        routeSequence={routeSequence}
        accountName={accountName}
        accountId={accountId}
        locationId={locationId}
        serviceLocationName={serviceLocationName}
        measureLabel={measureLabel}
        measureUnit={measureUnit}
        action={action}
        address={address}
        tags={tags}
        onTagsSave={onTagsEditSave}
        servicedAt={servicedAt}
        workOrderStatus={workOrderStatus}
        isWorkOrderComplete={isWorkOrderComplete}
        workOrderId={workOrderId}
      />
    </Box>
  )
}

RouteItem.propTypes = {
  editRouteStops: PropTypes.bool,
  selectedWorkOrderID: PropTypes.number,
  currentRouteId: PropTypes.number,
  handleSelectClick: PropTypes.func,
  onTagsEditSave: PropTypes.func,
  isCheck: PropTypes.bool,
  showRS: PropTypes.bool,
  scheduledStops: PropTypes.object,
  routeIndex: PropTypes.number,
}

export default memo(RouteItem)
