import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Dialog from '@mui/material/Dialog'

const HHBaseDialog = ({ children, onClose = noop, ...rest }) => (
  <Dialog onClose={onClose} PaperProps={{ sx: { maxHeight: 'calc(100% - 128px)' } }} {...rest}>
    {children}
  </Dialog>
)

HHBaseDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
}

export default HHBaseDialog
