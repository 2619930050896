import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

import T from 'T'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'

const TakePaymentButton = ({ accountId, from = 'customerDetails', size = 'small', variant = 'outlined', onClick, ...rest }) => {
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)

  const handleToggleTakePaymentsDialog = () => setIsOpenTakePaymentsDialog(!isOpenTakePaymentsDialog)

  return (
    <>
      <Button size={size} variant={variant} {...rest} onClick={handleToggleTakePaymentsDialog}>
        {T.TAKE_PAYMENT}
      </Button>

      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog
          isOpen={isOpenTakePaymentsDialog}
          initialAccount={{ id: accountId, from }}
          onClose={handleToggleTakePaymentsDialog}
        />
      )}
    </>
  )
}

TakePaymentButton.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  from: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default TakePaymentButton
