import React, { useReducer, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isValidLongitude, isValidLatitude } from 'utils/validations'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { createMap, createMarker, flyToMap, dragEndMarkerResult } from 'utils/map'

import { CommonTextLabel } from 'components/common'
import { HHTextField } from 'components/form-fields/v5'

import T from 'T'
import Constants from 'Constants'

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import mapboxgl from '!mapbox-gl'

import './style.scss'
import { Stack, Typography } from '@mui/material'

const { ZOOM, LIGHT_VIEW, SATELLITE_VIEW } = MAP_DEFAULT_OPTIONS
const { MEDIA_SERVER } = Constants

const MAP_TAB_ID = 3

const MapTabContent = ({ activeTab, selectedLocation, onChange }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const marker = useRef(null)

  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isMapVisible: false,
    mapZoom: ZOOM,
    isStreetView: false,
  })

  const { isMapVisible, mapZoom, isStreetView } = localState

  const { latitude, longitude } = selectedLocation

  const validLatitude = isValidLatitude(latitude) ? latitude : 0
  const validLongitude = isValidLongitude(longitude) ? longitude : 0

  const initiatemarker = () => {
    if (marker.current) {
      // Remove any existing marker
      marker.current.remove()
    }

    setTimeout(() => {
      marker.current = createMarker(mapboxgl, map, validLongitude, validLatitude)
      marker.current.on('dragend', () => {
        const { mapLng, mapLat } = dragEndMarkerResult(map, marker)
        // set new location here
        onChange(mapLng, mapLat)
      })
      flyToMap(map, validLongitude, validLatitude, false)
    }, 100)
  }

  useEffect(() => {
    if (!isMapVisible) {
      return
    }

    map.current = createMap(mapboxgl, mapContainer, validLongitude, validLatitude, mapZoom, isStreetView ? LIGHT_VIEW : SATELLITE_VIEW)
    if (map.current) {
      initiatemarker()
    }
  }, [isMapVisible, isStreetView])

  useEffect(() => {
    mapboxgl.accessToken = get(userInfo, 'mapBoxToken')
    setLocalState({ isMapVisible: true })
  }, [])

  useEffect(() => {
    setLocalState({ isMapVisible: activeTab === MAP_TAB_ID })
  }, [activeTab])

  return (
    <div className="map-tab-content">
      <Stack sx={{ position: 'absolute' }} alignItems="center" flexDirection="row" className="address di absolute flex items-center">
        {get(selectedLocation, 'locationName', '')}
      </Stack>

      {isMapVisible && (
        <div className={`map-view ${isStreetView ? 'street' : 'satellite'}`}>
          <Stack justifyContent="space-between" alignItems="center" flexDirection="row" className="lat-long-actions">
            <Stack alignItems="center" flexDirection="row" className="lat-long">
              <div className="map-common map-lat">
                <CommonTextLabel title={T.LATITUTE} className="ttc" />
                <HHTextField size="small" sx={{ display: 'block', width: 114 }} value={validLatitude} readOnly />
              </div>

              <div className="map-common map-long">
                <CommonTextLabel title={T.LONGITUDE} className="ttc" />
                <HHTextField size="small" sx={{ display: 'block', width: 114 }} value={validLongitude} readOnly />
              </div>
            </Stack>

            <Stack alignItems="center" className="switch-view" flexDirection="row">
              <Stack sx={{ position: 'relative', cursor: 'pointer', top: 10 }} onClick={() => setLocalState({ isStreetView: false })}>
                <img src={`${MEDIA_SERVER}Map/ZoneView.png`} alt="" />
                <Stack
                  sx={{ width: '100%', height: '100%', position: 'absolute' }}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Typography variant="body2" sx={{ pb: 1, color: 'common.white' }}>
                    {T.ZONES}
                  </Typography>
                </Stack>
              </Stack>

              <Stack sx={{ position: 'relative', cursor: 'pointer', top: 10 }} onClick={() => setLocalState({ isStreetView: true })}>
                <img src={`${MEDIA_SERVER}Map/StreetView.png`} alt="" />
                <Stack
                  sx={{ width: '100%', height: '100%', position: 'absolute' }}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Typography variant="body2" sx={{ pb: 1, color: 'common.white' }}>
                    {T.STREET}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <div ref={mapContainer} className="map-container" />
        </div>
      )}
    </div>
  )
}

MapTabContent.defaultProps = {
  activeTab: 0,
  selectedLocation: {},
}

MapTabContent.propTypes = {
  activeTab: PropTypes.number,
  selectedLocation: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(MapTabContent)
