import React from 'react'
import PropTypes from 'prop-types'
import TagFilterMenuField from 'components/locations/filters/TagFilter/TagFilterMenuField'
import BillingProfileFilterMenuField from 'components/locations/filters/BillingProfileFilter/BillingProfileFilterMenuField'
import Menu from '@mui/material/Menu'
import { noop } from 'lodash'
import { useFormContext } from 'react-hook-form'

const FiltersMenu = ({ open = false, anchorEl, onClose = noop, anchorOrigin, transformOrigin }) => {
  const { control, watch, setValue } = useFormContext()

  const searchTagsInput = watch('searchTagsInput')
  const searchBillingInput = watch('searchBillingInput')

  const onTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const onBillingSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchBillingInput', value)
    }
  }
  return (
    <Menu
      id="locations-filters-menu"
      slotProps={{
        paper: {
          sx: {
            overflow: 'hidden',
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      keepMounted
    >
      <TagFilterMenuField name="selectedTags" control={control} inputValue={searchTagsInput} onInputChange={onTagsSearchInputChange} />
      <BillingProfileFilterMenuField
        name="selectedBillingProfiles"
        control={control}
        inputValue={searchBillingInput}
        onInputChange={onBillingSearchInputChange}
      />
    </Menu>
  )
}

FiltersMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  transformOrigin: PropTypes.object,
  anchorOrigin: PropTypes.object,
}

export default FiltersMenu
