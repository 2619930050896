import { useCallback } from 'react'
import { get } from 'lodash'

const useSplitValidInvalidKeysFromFormValues = toBeInsertedRowsIds => {
  return useCallback(
    formValues => {
      const toBeInsertedRowsIdSet = new Set(toBeInsertedRowsIds)
      const validKeys = []
      const invalidKeys = []
      for (const toBeInsertedStopId of toBeInsertedRowsIdSet) {
        if (get(formValues, toBeInsertedStopId, false)) {
          validKeys.push(toBeInsertedStopId)
        } else {
          invalidKeys.push(toBeInsertedStopId)
        }
      }
      return { validKeys, invalidKeys }
    },
    [toBeInsertedRowsIds]
  )
}

export default useSplitValidInvalidKeysFromFormValues
