import { get } from 'lodash'
import { combineAddressInfo } from 'utils/helper'
import { CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'
import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getLocationList: builder.query({
      query: ({ search, page: requestedPage, size: requestedPageSize, includeDeactivatedLocations }) => ({
        url: '/api/configured-service/locations',
        method: 'POST',
        body: {
          search,
          requestedPage,
          requestedPageSize,
          includeDeactivatedLocations,
        },
      }),
      transformResponse: data => {
        const { services } = data
        const page = get(data, 'currentPage', 0)
        const size = get(data, 'pageSize', 0)
        const totalPages = get(data, 'totalPages', 0) || 0
        const totalItems = get(data, 'totalItems', 0) || services.length
        return {
          page,
          size,
          locations: services.map(row => {
            const address = get(row, 'address', {})
            const combinedAddressInfo = combineAddressInfo(address)
            const addressStr = typeof combinedAddressInfo === 'string' ? combinedAddressInfo.trim() : ''
            const tagIds = get(row, 'tagDetails.forLocation', []).map(({ id }) => id)
            return { ...row, addressStr, tagIds }
          }),
          totalPages,
          totalItems,
        }
      },
      providesTags: [CACHE_TAG_ACCOUNT_LOCATION_LIST],
    }),
  }),
})

export const { useLazyGetLocationListQuery } = extendedApi
