import { takeEvery, all, call, put } from 'redux-saga/effects'

import { get } from 'utils/lodash'
import { doPost, doGet } from 'providers/api'
import { PRICING_TABS_ID } from 'settings/constants/pricing'
import { getPricingSummaryURL, getPricingURL, getPricingRevenueURL } from 'utils/pricing'

import {
  actions,
  putPricingServices,
  putTaxServices,
  putconfiguratorServicesList,
  putCreatePricingServices,
  putPricingFiltersMeta,
} from '../actions/pricing'

import endpoint from './endpoint'

const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID

export const getPricingTableContent = ({ body, url }) => doPost({ url, body, showLoader: false })
export const getServiceGroupsTableContent = ({ url }) => doGet({ url, showLoader: false })
export const getPricingResult = ({ body }) => doPost({ url: endpoint.pricing.service.index, body, showLoader: true })
export const getValidatePricingServicesResults = ({ body }) => doPost({ url: endpoint.pricing.service.validate, body })
export const getReValidatePricingServicesResults = ({ body }) => doPost({ url: endpoint.pricing.service.reValidate, body })
export const getCreatePricingServicesResults = ({ body }) => doPost({ url: endpoint.pricing.service.add, body })
export const getFetchServiceRevenueByIdResults = ({ body }) => doPost({ url: endpoint.pricing.service.revenue, body })
export const getUpdateServiceByIdResults = ({ body }) => doPost({ url: endpoint.pricing.service.update, body })
export const getServiceLinkedListResults = ({ body }) => doPost({ url: endpoint.pricing.service.linked.list, body })
export const getLinkServiceUpdateResults = ({ body }) => doPost({ url: endpoint.pricing.service.link.service.update, body })
export const getPricingStatusChangeResults = ({ body }) => doPost({ url: endpoint.pricing.service.status.change, body, showLoader: true })
export const getPricingTableSummaryContent = ({ body, url }) => doPost({ url, body, showLoader: false })
export const getPricingTableRevenueContent = ({ body, url }) => doPost({ url, body, showLoader: false })
export const getTaxResult = ({ body }) => doPost({ url: endpoint.pricing.tax.index, body, showLoader: true })
export const getValidateTaxServicesResults = ({ body }) => doPost({ url: endpoint.pricing.tax.validate, body })
export const getReValidateTaxServicesResults = ({ body }) => doPost({ url: endpoint.pricing.tax.reValidate, body })
export const getCreateTaxServicesResults = ({ body }) => doPost({ url: endpoint.pricing.tax.add, body })
export const getUpdateTaxByIdResults = ({ body }) => doPost({ url: endpoint.pricing.tax.update, body })
export const getTaxLinkedListResults = ({ body }) => doPost({ url: endpoint.pricing.tax.linked.list, body })
export const getLinkTaxUpdateResults = ({ body }) => doPost({ url: endpoint.pricing.service.link.tax.update, body })

export const getGeneralFeeResult = ({ body }) => doPost({ url: endpoint.pricing.fee.general.index, body, showLoader: true })
export const getDisposalFeeResult = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.index, body, showLoader: true })
export const getValidateGeneralFeeServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.validate, body })
export const getReValidateGeneralFeeServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.reValidate, body })
export const getValidateDisposalFeeServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.validate, body })
export const getReValidateDisposalFeeServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.reValidate, body })
export const getCreateGeneralFeeServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.add, body })
export const getGeneralFeeMatchedServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.matched, body })
export const getTaxesMatchedServicesResults = ({ body }) => doPost({ url: endpoint.pricing.tax.matched, body })
export const getDisposalFeeMatchedServicesResults = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.add, body })
export const getFetchGeneralFeeRevenueByIdResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.revenue, body })
export const getFetchDisposalFeeRevenueByIdResults = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.revenue, body })
export const getUpdateGeneralFeeByIdResults = ({ body }) => doPost({ url: endpoint.pricing.fee.general.update, body })
export const getUpdateDisposalFeeByIdResults = ({ body }) => doPost({ url: endpoint.pricing.fee.disposal.update, body })

export const getGeneralFeeLinkedListResults = ({ body }) => doPost({ url: endpoint.pricing.general.linked.list, body })
export const getLinkGeneralFeeUpdateResults = ({ body }) => doPost({ url: endpoint.pricing.service.link.general.update, body })

export const getDisposalFeeLinkedListResults = ({ body }) => doPost({ url: endpoint.pricing.disposal.linked.list, body })
export const getLinkDisposalFeeUpdateResults = ({ body }) => doPost({ url: endpoint.pricing.service.link.disposal.update, body })

export const getconfiguratorServicesResults = ({ body }) => doPost({ url: endpoint.pricing.additional.column, body })
export const getPricingFiltersMeta = ({ body }) => doGet({ url: endpoint.pricing.meta, body, showLoader: false })

export function* getPricingResultSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putPricingServices(null))
    const { data: res } = yield call(getPricingResult, { body })
    const { services, totalItems, totalPages } = res

    if (typeof onSuccess === 'function') {
      onSuccess(services, totalItems, totalPages)
    }

    yield put(putPricingServices(res))
  } catch (err) {
    yield put(putPricingServices(null))
  }
}

export function* getTaxResultSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    yield put(putTaxServices(null))
    const { data: res } = yield call(getTaxResult, { body })
    const { taxes, totalItems, totalPages } = res

    if (typeof onSuccess === 'function') {
      onSuccess(taxes, totalItems, totalPages)
    }

    yield put(putTaxServices(res))
  } catch (err) {
    yield put(putTaxServices(null))
  }
}

export function* getGeneralFeeResultSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getGeneralFeeResult, { body })

    const { feeAndCharges, totalItems, totalPages } = res

    if (typeof onSuccess === 'function') {
      onSuccess(feeAndCharges, totalItems, totalPages)
    }
  } catch (err) {}
}

export function* getDisposalFeeResultSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getDisposalFeeResult, { body })

    const { feeAndCharges, totalItems, totalPages } = res

    if (typeof onSuccess === 'function') {
      onSuccess(feeAndCharges, totalItems, totalPages)
    }
  } catch (err) {}
}

export function* getconfiguratorServicesSaga({ payload }) {
  const { body } = payload
  try {
    const { data: res } = yield call(getconfiguratorServicesResults, { body })
    yield put(putconfiguratorServicesList(res))
  } catch (err) {
    yield put(putconfiguratorServicesList(null))
  }
}

export function* getValidatePricingServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getValidatePricingServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getReValidatePricingServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getReValidatePricingServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getCreatePricingServicesSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putCreatePricingServices(null))
    const res = yield call(getCreatePricingServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }

    yield put(putCreatePricingServices(res))
  } catch (err) {
    yield put(putCreatePricingServices(null))
  }
}

export function* getValidateTaxServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getValidateTaxServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getReValidateTaxServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getReValidateTaxServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getCreateTaxServicesSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getCreateTaxServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getPricingSummarySaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getPricingSummaryURL('list', get(body, 'tab'))
    if (!url) {
      return
    }

    const { data: res } = yield call(getPricingTableSummaryContent, { body, url })
    const { min, max, average, locationCount, revenue } = res
    if (typeof onSuccess === 'function') {
      onSuccess(min, max, average, locationCount, revenue)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getPricingRevenueSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getPricingRevenueURL('list', get(body, 'tab'))
    if (!url) {
      return
    }

    const { data: res } = yield call(getPricingTableRevenueContent, { body, url })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getValidateGeneralFeeServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getValidateGeneralFeeServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getReValidateGeneralFeeServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getReValidateGeneralFeeServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getValidateDisposalFeeServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getValidateDisposalFeeServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getReValidateDisposalFeeServicesSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getReValidateDisposalFeeServicesResults, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getCreateGeneralFeeSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getCreateGeneralFeeServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getGeneralFeeMatchedSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getGeneralFeeMatchedServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getTaxesMatchedServicesSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getTaxesMatchedServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getCreateDisposalFeeSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getDisposalFeeMatchedServicesResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getFetchServiceRevenueByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getFetchServiceRevenueByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getFetchGeneralFeeRevenueByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getFetchGeneralFeeRevenueByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getFetchDisposalFeeRevenueByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getFetchDisposalFeeRevenueByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getUpdateServiceByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getUpdateServiceByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getUpdateTaxByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getUpdateTaxByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getUpdateGeneralFeeByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getUpdateGeneralFeeByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getUpdateDisposalFeeByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getUpdateDisposalFeeByIdResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getServiceLinkedListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getServiceLinkedListResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getLinkServiceUpdateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getLinkServiceUpdateResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getTaxLinkedListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getTaxLinkedListResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getLinkTaxUpdateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getLinkTaxUpdateResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getGeneralFeeLinkedListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getGeneralFeeLinkedListResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getLinkGeneralFeeUpdateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getLinkGeneralFeeUpdateResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getDisposalFeeLinkedListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getDisposalFeeLinkedListResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getLinkDisposalFeeUpdateSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getLinkDisposalFeeUpdateResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getPricingStatusChangeSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const res = yield call(getPricingStatusChangeResults, { body })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {}
}

export function* getPricingFiltersMetaSaga({ payload }) {
  const { body } = payload
  try {
    const { data: res } = yield call(getPricingFiltersMeta, { body })
    yield put(putPricingFiltersMeta(res))
  } catch (err) {
    yield put(putPricingFiltersMeta(null))
  }
}

export function* getPricingTableContentSaga({ payload }) {
  const { body, onSuccess } = payload
  const tab = get(body, 'tab')
  try {
    const url = getPricingURL('list', tab)
    if (!url) {
      return
    }

    // Only for service groups
    if (tab === SERVICE_GROUPS_TAB_ID) {
      const { data: res } = yield call(getServiceGroupsTableContent, {
        url: `${url}?sort=updatedAt,desc&page=${get(body, 'requestedPage')}&size=${get(body, 'requestedPageSize')}`,
      })
      const { content, totalElements, totalPages } = res
      onSuccess(content, totalElements, totalPages)
      return
    }

    const { data: res } = yield call(getPricingTableContent, { body, url })
    const { totalItems, totalPages } = res
    const tableRows = get(res, 'services') || get(res, 'taxes') || get(res, 'feeAndCharges') || []
    if (typeof onSuccess === 'function') {
      onSuccess(tableRows, totalItems, totalPages)
    }
  } catch (err) {
    onSuccess([])
  }
}

export default function* Pricing() {
  yield all([
    takeEvery(actions.GET.PRICING_TABLE_CONTENT, getPricingTableContentSaga),
    takeEvery(actions.GET.PRICING_RESULT, getPricingResultSaga),
    takeEvery(actions.GET.TAX_RESULT, getTaxResultSaga),
    takeEvery(actions.GET.GENERAL_FEE_RESULT, getGeneralFeeResultSaga),
    takeEvery(actions.GET.DISPOSAL_FEE_RESULT, getDisposalFeeResultSaga),
    takeEvery(actions.GET.CONFIGURATOR_SERVICES, getconfiguratorServicesSaga),
    takeEvery(actions.GET.VALIDATE_PRICING_SERVICES, getValidatePricingServicesSaga),
    takeEvery(actions.GET.RE_VALIDATE_PRICING_SERVICES, getReValidatePricingServicesSaga),
    takeEvery(actions.GET.CREATE_PRICING_SERVICES, getCreatePricingServicesSaga),
    takeEvery(actions.GET.VALIDATE_TAX_SERVICES, getValidateTaxServicesSaga),
    takeEvery(actions.GET.RE_VALIDATE_TAX_SERVICES, getReValidateTaxServicesSaga),
    takeEvery(actions.GET.VALIDATE_GENERAL_FEE_SERVICES, getValidateGeneralFeeServicesSaga),
    takeEvery(actions.GET.RE_VALIDATE_GENERAL_FEE_SERVICES, getReValidateGeneralFeeServicesSaga),
    takeEvery(actions.GET.VALIDATE_DISPOSAL_FEE_SERVICES, getValidateDisposalFeeServicesSaga),
    takeEvery(actions.GET.RE_VALIDATE_DISPOSAL_FEE_SERVICES, getReValidateDisposalFeeServicesSaga),
    takeEvery(actions.GET.CREATE_TAX_SERVICES, getCreateTaxServicesSaga),
    takeEvery(actions.GET.CREATE_GENERAL_FEE_SERVICES, getCreateGeneralFeeSaga),
    takeEvery(actions.GET.GENERAL_FEE_MATCHED_SERVICES, getGeneralFeeMatchedSaga),
    takeEvery(actions.GET.TAXES_MATCHED_SERVICES, getTaxesMatchedServicesSaga),
    takeEvery(actions.GET.CREATE_DISPOSAL_FEE_SERVICES, getCreateDisposalFeeSaga),
    takeEvery(actions.GET.FETCH_SERVICE_REVENUE_BY_ID, getFetchServiceRevenueByIdSaga),
    takeEvery(actions.GET.FETCH_GENERAL_FEE_REVENUE_BY_ID, getFetchGeneralFeeRevenueByIdSaga),
    takeEvery(actions.GET.FETCH_DISPOSAL_FEE_REVENUE_BY_ID, getFetchDisposalFeeRevenueByIdSaga),
    takeEvery(actions.GET.UPDATE_SERVICE_BY_ID, getUpdateServiceByIdSaga),
    takeEvery(actions.GET.UPDATE_TAX_BY_ID, getUpdateTaxByIdSaga),
    takeEvery(actions.GET.UPDATE_GENERAL_FEE_BY_ID, getUpdateGeneralFeeByIdSaga),
    takeEvery(actions.GET.UPDATE_DISPOSAL_FEE_BY_ID, getUpdateDisposalFeeByIdSaga),
    takeEvery(actions.GET.SERVICE_LINKED_LIST, getServiceLinkedListSaga),
    takeEvery(actions.GET.LINKED_SERVICE_UPDATE, getLinkServiceUpdateSaga),
    takeEvery(actions.GET.TAX_LINKED_LIST, getTaxLinkedListSaga),
    takeEvery(actions.GET.LINKED_TAX_UPDATE, getLinkTaxUpdateSaga),
    takeEvery(actions.GET.GENERAL_FEE_LINKED_LIST, getGeneralFeeLinkedListSaga),
    takeEvery(actions.GET.LINKED_GENERAL_FEE_UPDATE, getLinkGeneralFeeUpdateSaga),
    takeEvery(actions.GET.DISPOSAL_FEE_LINKED_LIST, getDisposalFeeLinkedListSaga),
    takeEvery(actions.GET.LINKED_DISPOSAL_FEE_UPDATE, getLinkDisposalFeeUpdateSaga),
    takeEvery(actions.GET.PRICING_STATUS_CHANGE, getPricingStatusChangeSaga),
    takeEvery(actions.GET.PRICING_SUMMARY, getPricingSummarySaga),
    takeEvery(actions.GET.PRICING_REVENUE, getPricingRevenueSaga),
    takeEvery(actions.GET.PRICING_FILTERS_META, getPricingFiltersMetaSaga),
  ])
}
