import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import MuiFormControl from '@mui/material/FormControl'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiButton from '@mui/material/Button'
import { noop } from 'lodash'
import { blueGrey, grey } from '@mui/material/colors'
import MuiGrid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import T from 'T'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Constants from 'Constants'

const { CANCEL, DONE, SELECT_ALL } = T
const BlueCheckbox = styled(props => <Checkbox color="primary" {...props} />)({
  root: {
    color: blueGrey[50],
  },
})
const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  maxHeight: 'calc(100vh - 318px)',
  width: '296px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: 0,
    background: 'transparent',
  },
}))
const BottonContainer = styled('div')(() => ({
  display: 'flex',
  padding: '10px',
  borderTop: `1px solid ${grey[200]}`,
  boxShadow: ' 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  height: '50px',
  width: '100%',
  justifyContent: 'space-between',
}))
const FormControl = styled(props => <MuiFormControl {...props} />)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}))
const CancelButton = styled(props => <MuiButton {...props} />)(() => ({
  color: grey[500],
}))
const { MEDIA_SERVER } = Constants

const Configurator = ({
  configData = [],
  handleOnDragEnd = noop,
  handleCancel = noop,
  handleConfChange = noop,
  saveConfigSettings = noop,
}) => {
  const [selected, setSelected] = useState(false)
  const checkSelected = () => configData.filter(item => item.checked).length === configData.length

  const handleChangeAll = () => {
    const isAllChecked = checkSelected()
    setSelected(!isAllChecked)
    handleConfChange(0, 'all', configData)
  }
  useEffect(() => {
    setSelected(checkSelected())
  }, [])

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="upper-section">
          {provided => (
            <MuiGrid container spacing={0} nowrap {...provided.droppableProps} ref={provided.innerRef}>
              <Container>
                <MuiGrid item xs={12}>
                  <FormControl component="fieldset">
                    <MuiFormControlLabel control={<BlueCheckbox checked={selected} onChange={handleChangeAll} />} label={SELECT_ALL} />
                  </FormControl>
                </MuiGrid>
                {Children.toArray(
                  configData &&
                    configData.map((data, index) => {
                      return (
                        <Draggable key={index} draggableId={`s${index}`} index={index}>
                          {provide => (
                            <MuiGrid
                              container
                              nowrap
                              spacing={1}
                              alignItems="center"
                              ref={provide.innerRef}
                              {...provide.draggableProps}
                              {...provide.dragHandleProps}
                              data-id={data.position}
                            >
                              <MuiGrid item xs={1}>
                                <img src={`${MEDIA_SERVER}Expander.svg`} alt="price-expander" />
                              </MuiGrid>
                              <MuiGrid item xs={11}>
                                <MuiFormControl component="fieldset">
                                  <MuiFormControlLabel
                                    control={
                                      <BlueCheckbox
                                        checked={configData[index].checked}
                                        onChange={() => {
                                          handleConfChange(index, 'checked', configData)
                                          setSelected(false)
                                        }}
                                        name={`checkedCol${index}`}
                                      />
                                    }
                                    label={data.label}
                                  />
                                </MuiFormControl>
                              </MuiGrid>
                            </MuiGrid>
                          )}
                        </Draggable>
                      )
                    })
                )}
                {provided.placeholder}
              </Container>
            </MuiGrid>
          )}
        </Droppable>
      </DragDropContext>
      <MuiGrid container spacing={0}>
        <BottonContainer>
          <CancelButton variant="text" size="small" onClick={handleCancel}>
            {CANCEL}
          </CancelButton>
          <MuiButton variant="contained" color="primary" size="small" onClick={() => saveConfigSettings(configData)}>
            {DONE}
          </MuiButton>
        </BottonContainer>
      </MuiGrid>
    </>
  )
}

Configurator.propTypes = {
  configData: PropTypes.array,
  handleOnDragEnd: PropTypes.func,
  handleCancel: PropTypes.func,
  handleConfChange: PropTypes.func,
  saveConfigSettings: PropTypes.func,
}

export default Configurator
