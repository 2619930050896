import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const AccountDetails = ({ accountName = '', accountNumber = '' }) => {
  return (
    <Box my={1.5}>
      <Typography variant="h5" fontWeight={400}>
        {accountName}
      </Typography>
      <Typography variant="h6" fontWeight={400} color="textSecondary">
        {accountNumber}
      </Typography>
    </Box>
  )
}

AccountDetails.propTypes = {
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
}

export default AccountDetails
