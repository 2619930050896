import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { removeParentFromSelection } from 'utils/groups'
import { get } from 'utils/lodash'

const initialState = {
  bulkPrintDialog: { groupId: '', isOpen: false },
  invoiceSelectionModel: [],
  selectedRowsById: {},
  selectedInvoiceIdForPreview: '',
  expansionState: [],
  goToPage: '',
  batchDialog: {
    isOpen: false,
    currentBatchId: '',
    batchPrintCompleteIds: [],
  },
}

const invoicePrintableViewSlice = createSlice({
  name: 'InvoicePrintableView',
  initialState,
  reducers: {
    setBulkPrintDialogState(state, action) {
      state.bulkPrintDialog = action.payload
    },
    setInvoiceSelectionModel(state, action) {
      const rows = get(action, 'payload.rows', [])
      const selectionModel = removeParentFromSelection(get(action, 'payload.selectionModel', []))
      state.invoiceSelectionModel = selectionModel
      const allInvoices = {}

      if (selectionModel.length > 0) {
        rows.forEach(invoice => {
          if (invoice?.id) {
            allInvoices[invoice.id] = { ...invoice }
          }
        })
      }
      state.selectedRowsById = allInvoices
    },
    resetSelectionModels(state) {
      state.invoiceSelectionModel = []
      state.selectedRowsById = {}
    },
    setSelectedInvoiceIdForPreview(state, action) {
      state.selectedInvoiceIdForPreview = action.payload
    },
    setExpansionState(state, action) {
      const id = get(action, 'payload.id', '')
      if (action.payload.type === 'add') {
        state.expansionState = uniq([...state.expansionState, id])
      } else {
        const filtered = state.expansionState.filter(expansionId => expansionId !== id)
        state.expansionState = [...filtered]
      }
    },
    setGoToPage(state, action) {
      state.goToPage = action.payload
    },
    resetGoToPage(state) {
      state.goToPage = ''
    },
    setBatchDialogState(state, action) {
      state.batchDialog = { ...state.batchDialog, ...action.payload }
    },
    resetBatchDialogState(state) {
      state.batchDialog = { ...initialState.batchDialog }
    },
    resetState(state) {
      state.invoiceSelectionModel = []
      state.selectedRowsById = {}
      state.selectedInvoiceIdForPreview = ''
      state.expansionState = []
      state.goToPage = ''
      state.isOpenBatchPrintDialog = false
      state.batchDialog = { ...initialState.batchDialog }
    },
  },
})

export const {
  setBulkPrintDialogState,
  setInvoiceSelectionModel,
  resetSelectionModels,
  setSelectedInvoiceIdForPreview,
  setExpansionState,
  setGoToPage,
  resetGoToPage,
  setBatchDialogState,
  resetBatchDialogState,
  resetState,
} = invoicePrintableViewSlice.actions
export default invoicePrintableViewSlice.reducer
