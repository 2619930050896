import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'

import { shallowEqual, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom-v5-compat'
import { Box } from '@mui/material'

import { get } from 'utils/lodash'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'

// Change model
import { FORM_MODEL } from 'containers/customers/groups/account/details/settings'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'

import PricingDrawer from 'components/pricing/common/drawer/PricingDrawer'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import RentalFeeDetailsHeader from 'components/pricing/RentalFees/details/RentalFeeDetailsHeader'
import RentalFeeDetailsList from 'components/pricing/RentalFees/details/RentalFeeDetailsList'

const RentalFeeDetails = () => {
  const { id } = useParams()
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)

  // Accounts data
  const data = []

  const [getAccountMeta, { isSuccess: isAccountMetaSuccess, data: accountMetaData }] = useLazyGetAccountMetaQuery()
  const rentalFeeDetailsForm = useForm({ defaultValues: { ...FORM_MODEL } })
  const { reset } = rentalFeeDetailsForm
  const customerMeta = useSelector(getAccountMetaData, shallowEqual)

  useEffect(() => {
    if (id && data && isAccountMetaSuccess) {
      const accounts = get(data, 'accounts', [])
      const dataBillingProfileIds = uniq(accounts.map(({ billingProfileId }) => billingProfileId))
      const dataTagIds = uniq(accounts.map(({ tagIds }) => tagIds).flat())
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      const selectedTags = allTags.filter(({ id }) => dataTagIds.includes(id))
      const selectedBillingProfiles = billingProfiles.filter(({ id }) => dataBillingProfileIds.includes(id))
      reset({
        ...FORM_MODEL,
        tagList: selectedTags,
        selectedTags,
        billingProfileList: selectedBillingProfiles,
        selectedBillingProfiles,
      })
    }
  }, [accountMetaData, id, data, isAccountMetaSuccess])

  useEffect(() => {
    getAccountMeta()
  }, [])

  useEffect(() => {
    if (id) {
      // call API to get linked accounts
    }
  }, [id])

  return (
    <Box height="100%" overflowY="hidden" minWidth="750px">
      <PricingDrawer />
      <CustomerLayout overflow="hidden" open={isOpenPricingDrawer}>
        <FormProvider {...rentalFeeDetailsForm}>
          <RentalFeeDetailsHeader />
          <RentalFeeDetailsList />
        </FormProvider>
      </CustomerLayout>
    </Box>
  )
}

export default RentalFeeDetails
