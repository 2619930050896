import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { noop, difference, uniq, uniqBy } from 'lodash'

import { useFormContext } from 'react-hook-form'
import Menu from '@mui/material/Menu'

import { get } from 'utils/lodash'
import { getTagsByRoutes } from 'data/route-manager/routeSelectors'

import TagFilterMenuField from './tag/TagFilterMenuField'

const RouteFiltersMenu = ({ open = false, anchorEl, routeId, onClose = noop }) => {
  const { control, watch, setValue } = useFormContext()

  const allTags = watch('allTags')
  const selectedTags = watch('selectedTags') || []
  const searchTagsInput = watch('searchTagsInput')
  const selectedRoutesByTagId = watch('selectedRoutesByTagId') || {}
  const selectedTagsByRouteId = getTagsByRoutes(selectedRoutesByTagId)
  const filteredTags = get(selectedTagsByRouteId, routeId, [])
  const fieldName = `selectedTagsByRouteId-${routeId}`

  const handleTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const handleTagFilterChange = newTags => {
    const newTagIds = newTags.map(({ id }) => id)
    let newSelectedTags = [...selectedTags]
    const newSelectedRoutesByTagId = {}

    allTags.forEach(({ id, stopCount }) => {
      if (stopCount === 0) return

      const routes = get(selectedRoutesByTagId, id, [])
      let newRoutes = []
      if (newTagIds.includes(id)) {
        newRoutes = uniq([...routes, routeId])
        newSelectedRoutesByTagId[id] = newRoutes
        // Select parent tag
        newSelectedTags.push(allTags.find(tag => tag.id === id))
        return
      }

      newRoutes = difference(routes, [routeId])
      newSelectedRoutesByTagId[id] = newRoutes
      if (newRoutes.length === 0) {
        // Deselect parent tag
        newSelectedTags = newSelectedTags.filter(tag => tag.id !== id)
      }
    })

    setValue('selectedTags', uniqBy([...newSelectedTags], 'id'))
    setValue('selectedRoutesByTagId', newSelectedRoutesByTagId)
  }

  useEffect(() => {
    if (open && routeId) {
      setValue(
        fieldName,
        filteredTags.map(tagId => ({ id: tagId }))
      )
    }
  }, [open, routeId])

  return (
    <Menu
      slotProps={{
        paper: {
          sx: { overflow: 'hidden' },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      keepMounted
    >
      <TagFilterMenuField
        name={fieldName}
        control={control}
        inputValue={searchTagsInput}
        onInputChange={handleTagsSearchInputChange}
        onChange={handleTagFilterChange}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        dropdownHeight="calc(100vh - 400px)"
        routeId={routeId}
      />
    </Menu>
  )
}

RouteFiltersMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  routeId: PropTypes.string,
  onClose: PropTypes.func,
}

export default RouteFiltersMenu
