import React from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

import { Box, Typography } from '@mui/material'

const BillingField = ({
  label = '',
  value = '',
  isNumberFormat = false,
  prefix = '',
  suffix = '',
  variant = 'body1',
  mb = 1,
  LabelTypographyProps,
  ValueTypographyProps,
}) => (
  <Box display="flex" justifyContent="space-between" mb={mb} alignItems="center">
    <Typography variant="body1" color="text.secondary" {...LabelTypographyProps}>
      {label}
    </Typography>
    <Typography variant={variant} {...ValueTypographyProps}>
      {isNumberFormat ? (
        <NumericFormat
          value={value}
          fixedDecimalScale
          displayType="text"
          thousandSeparator
          decimalSeparator="."
          decimalScale={2}
          prefix={prefix}
          suffix={suffix}
        />
      ) : (
        value
      )}
    </Typography>
  </Box>
)

BillingField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNumberFormat: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  variant: PropTypes.string,
  mb: PropTypes.number,
  LabelTypographyProps: PropTypes.object,
  ValueTypographyProps: PropTypes.object,
}

export default BillingField
