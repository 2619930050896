import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText } from '@mui/material'

const BillingDetailsListItem = ({ primary, secondary, primaryTypographyProps, secondaryTypographyProps, ...rest }) => {
  return (
    <ListItem disablePadding {...rest}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{
          variant: 'h5',
          wordBreak: 'break-word',
          fontWeight: 500,
          ...primaryTypographyProps,
        }}
        secondaryTypographyProps={{
          color: 'text.secondary',
          ...secondaryTypographyProps,
        }}
      />
    </ListItem>
  )
}

BillingDetailsListItem.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
}

export default BillingDetailsListItem
