import T from 'T'
import { USER_STATUS } from 'settings/constants/user'

const { PENDING } = USER_STATUS

export const USER_INITIAL_VALUES = {
  users: [],
  roles: [],
  assets: [],
  payRolls: [T.HOURLY, T.SALARY],
  totalUsersCount: 0,
  totalPageCount: 0,
  footerFlag: false,
  accountRepresentative: false,
  changeIndividualPermissions: false,
  originalChangeIndividualPermissions: false,
  commercialDriverLicense: false,
  active: true,
  status: PENDING,
  userTab: T.PERSONAL_INFORMATION,
  editID: '',
  firstName: '',
  lastName: '',
  userName: '',
  role: 'No Role',
  profileId: '',
  originalProfileId: '',
  userID: '',
  email: '',
  asset: '',
  phoneNumber: '',
  assetId: '',
  skills: [],
  selectedSkills: [],
  payType: '',
  payRate: '',
  burdenRate: '',
  timeZone: '',
  providerIds: [],
}
