import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useFormContext } from 'react-hook-form'
import Menu from '@mui/material/Menu'

import TagFilterMenuField from 'components/locations/filters/TagFilter/TagFilterMenuField'
import BillingProfileFilterMenuField from 'components/locations/filters/BillingProfileFilter/BillingProfileFilterMenuField'
import SuspensionTypeFilterMenuField from 'components/locations/filters/SuspensionTypeFilterMenuField'
import AccountStatusFilterMenuField from './account-status-filter/AccountStatusFilterMenuField'

const FiltersMenu = ({ open = false, anchorEl, onClose = noop, showSuspensionTypeFilter = false }) => {
  const { control, watch, setValue } = useFormContext()

  const searchTagsInput = watch('searchTagsInput')
  const searchBillingInput = watch('searchBillingInput')
  const searchSuspensionTypeInput = watch('searchSuspensionTypeInput') || ''
  const tagList = watch('tagList')
  const billingProfileList = watch('billingProfileList')

  const onTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const onBillingSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchBillingInput', value)
    }
  }

  const onSuspensionSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchSuspensionTypeInput', value)
    }
  }

  return (
    <Menu
      id="account-details-filters-menu"
      slotProps={{
        paper: {
          sx: {
            overflow: 'hidden',
          },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      keepMounted
    >
      <TagFilterMenuField
        name="selectedTags"
        control={control}
        tagList={tagList}
        autoHeight
        inputValue={searchTagsInput}
        onInputChange={onTagsSearchInputChange}
      />
      <BillingProfileFilterMenuField
        name="selectedBillingProfiles"
        control={control}
        billingProfileList={billingProfileList}
        autoHeight
        inputValue={searchBillingInput}
        onInputChange={onBillingSearchInputChange}
      />
      <AccountStatusFilterMenuField
        name="selectedAccountStatuses"
        control={control}
        inputValue={searchBillingInput}
        onInputChange={onBillingSearchInputChange}
      />
      {showSuspensionTypeFilter && (
        <SuspensionTypeFilterMenuField
          name="selectedSuspensionTypes"
          control={control}
          inputValue={searchSuspensionTypeInput}
          onChange={onSuspensionSearchInputChange}
        />
      )}
    </Menu>
  )
}

FiltersMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  showSuspensionTypeFilter: PropTypes.bool,
}

export default FiltersMenu
