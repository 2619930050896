import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Divider, useTheme, useMediaQuery, ListItem, SvgIcon, IconButton, ListItemText } from '@mui/material'

import { get } from 'utils/lodash'
import { formatDollarsToCents } from 'utils/price'

import T from 'T'
import { PencilAlt } from '@styled-icons/heroicons-solid/PencilAlt'
import UnpaidStats from 'components/billing/groups/payment/take-payments/UnpaidStats'
import AccountInfo from 'components/billing/groups/payment/take-payments/AccountInfo'
import PaymentType from 'components/billing/groups/payment/take-payments/PaymentType'
import InvoicesDataGrid from 'components/billing/groups/payment/take-payments/InvoicesDataGrid'
import { FIRST_STEP_LAYOUT_HEIGHT, FIRST_STEP_WITHOUT_STATS_LAYOUT_HEIGHT } from 'components/billing/groups/payment/take-payments/settings'
import AccountsDataGrid from 'components/customers/groups/account/common/bulk-take-payments/AccountsDataGrid'

const MAX_HEIGHT = 200

const ListLayout = ({
  isLoading = false,
  accountId = '',
  accountData,
  savedPaymentMethods = [],
  existingAccounts = [],
  sortedUnpaidInvoices = [],
  localState = {},
  apiRef,
  initialCreditBalanceCents = 0,
  updatedCreditBalanceCents = 0,
  selectionModel = [],
  watchAmount = 0,
  hasSurcharge = false,
  billingAddressStr = '',
  onChange,
  onRowCheckboxClick,
  onSelectAllClick,
  onAmountChange,
  onSubmit,
  onClearClick,
  onOpenEditBillingLocationDialog,
  onAccountRowSelectionModelChange,
  ...rest
}) => {
  const theme = useTheme()
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const { selectedOption, invoicesToPay } = localState

  return (
    <Grid mt={1} container>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          pl: 3,
          maxHeight: isTabletorMobile ? MAX_HEIGHT : FIRST_STEP_LAYOUT_HEIGHT,
          borderRight: isTabletorMobile ? 0 : 1,
          borderColor: 'divider',
        }}
        {...(isTabletorMobile && { pr: 3, width: 'calc(100vw - 90px)' })}
      >
        <AccountsDataGrid
          rows={existingAccounts}
          rowSelectionModel={[accountId]}
          onRowSelectionModelChange={onAccountRowSelectionModelChange}
        />
      </Grid>
      <Grid item sx={12} lg={9} {...(isTabletorMobile && { mt: 3 })}>
        <UnpaidStats invoices={selectedOption ? sortedUnpaidInvoices : []} />
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container>
          <Grid
            item
            xs={12}
            lg={7}
            sx={{
              overflow: 'auto',
              px: 3,
              borderRight: isTabletorMobile ? 0 : 1,
              borderColor: 'divider',
              maxHeight: isTabletorMobile ? MAX_HEIGHT + 40 : FIRST_STEP_WITHOUT_STATS_LAYOUT_HEIGHT,
            }}
            {...(isTabletorMobile && { minHeight: 56, width: 'calc(100vw - 90px)' })}
          >
            <InvoicesDataGrid
              apiRef={apiRef}
              loading={isLoading}
              existingAccounts={existingAccounts}
              selectedOption={selectedOption}
              amount={initialCreditBalanceCents + formatDollarsToCents(watchAmount)}
              rows={sortedUnpaidInvoices}
              billingProfileDetails={get(accountData, 'accountBilling.billingProfile', {})}
              selectionModel={selectionModel}
              onSelectionModelChange={onRowCheckboxClick}
              onSelectAll={onSelectAllClick}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{ height: isTabletorMobile ? '100%' : FIRST_STEP_WITHOUT_STATS_LAYOUT_HEIGHT, overflow: 'auto', px: 3 }}
            {...(isTabletorMobile && { mt: 3 })}
          >
            <AccountInfo
              selectedAccount={selectedOption ? accountData : null}
              initialCreditBalanceCents={initialCreditBalanceCents}
              updatedCreditBalanceCents={updatedCreditBalanceCents}
              showUpdatedCreditBalanceCents={watchAmount > 0 || invoicesToPay.length > 0}
            />
            <Divider sx={{ mt: 1, mb: 2 }} />
            <PaymentType
              isLoading={isLoading}
              hasSurcharge={hasSurcharge}
              savedPaymentMethods={savedPaymentMethods}
              params={localState}
              initialCreditBalanceCents={initialCreditBalanceCents}
              isSinglePayment={false}
              onChange={onChange}
              onAmountChange={onAmountChange}
              onNextBtnClick={onSubmit}
              onClearClick={onClearClick}
            />
            <Divider sx={{ my: 2 }} />
            <ListItem
              disableGutters
              secondaryAction={
                accountId && (
                  <IconButton onClick={onOpenEditBillingLocationDialog}>
                    <SvgIcon fontSize="small" sx={{ color: theme.palette.text.secondary }}>
                      <PencilAlt />
                    </SvgIcon>
                  </IconButton>
                )
              }
            >
              <ListItemText
                primary={T.BILLING_ADDRESS}
                primaryTypographyProps={{ variant: 'h5', fontWeight: 500 }}
                secondary={billingAddressStr}
                secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'textPrimary' }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ListLayout.propTypes = {
  isLoading: PropTypes.bool,
  accountId: PropTypes.string,
  accountData: PropTypes.object,
  savedPaymentMethods: PropTypes.array,
  existingAccounts: PropTypes.array,
  sortedUnpaidInvoices: PropTypes.array,
  localState: PropTypes.object,
  apiRef: PropTypes.object,
  initialCreditBalanceCents: PropTypes.number,
  updatedCreditBalanceCents: PropTypes.number,
  selectionModel: PropTypes.array,
  watchAmount: PropTypes.number,
  hasSurcharge: PropTypes.bool,
  billingAddressStr: PropTypes.string,
  onChange: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onAmountChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClearClick: PropTypes.func,
  onOpenEditBillingLocationDialog: PropTypes.func,
  onAccountRowSelectionModelChange: PropTypes.func,
}

export default ListLayout
