import api from 'api/index'
import {
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { transformSearchResponse } from 'utils/accountSearch'
import { get } from 'utils/lodash'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getSuspendedAccountsSummary: builder.query({
      query: () => ({
        url: `/api/v1/core/accounts/groups/suspended/summary`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY],
    }),
    getSuspendedAccounts: builder.query({
      query: params => ({
        url: `/api/v1/core/accounts/groups/suspended`,
        method: 'GET',
        params,
      }),
      transformResponse: (data, meta, params) => {
        const { accounts } = transformSearchResponse({ results: get(data, 'accounts', []) })
        return { ...data, accounts, groupId: 'suspended' }
      },
      providesTags: (result, error) => [{ type: CACHE_TAG_SUSPENSION_GROUP_DETAILS, id: 'suspended' }],
    }),
    bulkAddAccountNoteToSuspensionGroup: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/core/accounts/groups/suspension-group:add-account-note`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_SUSPENSION_GROUP_DETAILS, CACHE_TAG_AGING_GROUP_DETAILS],
    }),
    bulkAddBillingNoteToSuspensionGroup: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/core/accounts/groups/suspension-group:add-billing-note`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_SUSPENSION_GROUP_DETAILS, CACHE_TAG_AGING_GROUP_DETAILS],
    }),
    bulkAddTagsToSuspensionGroup: builder.mutation({
      query: ({ tags }) => ({
        url: `/api/v1/core/accounts/groups/suspension-group:add-tags`,
        body: { tags },
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_GROUP_DETAILS, CACHE_TAG_SUSPENSION_GROUP_DETAILS, CACHE_TAG_AGING_GROUP_DETAILS],
    }),
  }),
})

export const {
  useLazyGetSuspendedAccountsSummaryQuery,
  useLazyGetSuspendedAccountsQuery,
  useBulkAddBillingNoteToSuspensionGroupMutation,
  useBulkAddAccountNoteToSuspensionGroupMutation,
  useBulkAddTagsToSuspensionGroupMutation,
} = extendedApi
