import { getBounds, removeLayerIfExist, removeSourceIfExist } from 'utils/map'
import { addRouteIdSuffix, ROUTE_COLOR_STOPS_DOT_PAINT, ROUTE_COLOR_STOPS_LAYOUT } from 'components/route-manager/RouteManagerMap/settings'
import { ROUTE_STOPS_LINE_STRING } from 'slices/route/routeSlice'
import { common, orange } from '@mui/material/colors'
import { PRIMARY, WARNING } from 'theme/colors'
import { get, partition, sortBy } from 'lodash'
import { isValidHex } from 'utils/validations'
import { getDateObjectFromDateString } from 'utils/date'
import { getPricingPeriod } from 'utils/service'
import { PREVIEW_TYPE } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/ConfirmPermanentMoveDialog/settings'
import { formatStopFeatures } from 'utils/route'

export const BULK_MOVE_MAP_LAYERS = {
  FROM_ROUTE_LAYER: 'FROM_ROUTE_LAYER',
  TO_ROUTE_LAYER: 'TO_ROUTE_LAYER',
  BOTH_ROUTES_LAYER: 'BOTH_ROUTES_LAYER',
}

const { FROM_ROUTE_LAYER, TO_ROUTE_LAYER, BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

export const STATIC_COLOR_STOPS_LAYER = 'static-color-stops-layer'
export const DYNAMIC_COLOR_STOPS_LAYER = 'dynamic-color-stops-layer'
export const STATIC_COLOR_DOT_LAYER = 'static-color-stops-dot-layer'
export const DYNAMIC_COLOR_DOT_LAYER = 'dynamic-color-stops-dot-layer'
export const STATIC_COLOR_STOPS_SOURCE = 'static-color-stops-source'
export const DYNAMIC_COLOR_STOPS_SOURCE = 'dynamic-color-stops-source'

const DOT_ZOOM_UPPER_LIMIT = 18
export const HEADER_HEIGHT = 42

export const ROW_HEIGHT = 104

export const ZOOM_DOT_LAYER_PROPS = {
  minzoom: 0,
  maxzoom: DOT_ZOOM_UPPER_LIMIT,
}

export const ZOOM_MARKER_LAYER_PROPS = {
  minzoom: DOT_ZOOM_UPPER_LIMIT + 0.01,
}

export const getBulkMoveStopColumnSx = theme => {
  return {
    flex: '0 0 368px',
    maxWidth: '368px',
    width: '368px',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 368px',
      maxWidth: '368px',
      width: '368px',
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 368px',
      maxWidth: '368px',
      width: '368px',
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 368px',
      maxWidth: '368px',
      width: '368px',
    },
    [theme.breakpoints.up(1700)]: { flex: '0 0 460px', maxWidth: '460px', width: '460px' },
    [theme.breakpoints.up(2200)]: { flex: '0 0 552px', maxWidth: '552px', width: '552px' },
  }
}

export const UNASSIGNED_MARKER_PROPS = {
  icon: 'to-be-assigned-marker',
  color: common.white,
  'route-text-color': common.black,
  'border-color': WARNING.main,
}

export const HIGHLIGHTED_MARKER_PROPS = {
  icon: 'highlighted-marker',
  color: PRIMARY.main,
  'route-text-color': common.black,
  'border-color': common.white,
}

export const removeLayersAndSources = (mapCurrentRef, routeId) => {
  removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId))
  removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, routeId))
  removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, routeId))
  removeSourceIfExist(mapCurrentRef, addRouteIdSuffix(DYNAMIC_COLOR_STOPS_SOURCE, routeId))
  removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, routeId))
  removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, routeId))
  removeSourceIfExist(mapCurrentRef, addRouteIdSuffix(STATIC_COLOR_STOPS_SOURCE, routeId))
  removeSourceIfExist(mapCurrentRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId))
}

export const addMarkerLayers = (
  mapCurrentRef,
  { sourcePrefix, dotLayerPrefix, markerLayerPrefix, routeId, beforeIdLayer, hasDotLayer = true }
) => {
  if (hasDotLayer) {
    mapCurrentRef.addLayer(
      {
        id: addRouteIdSuffix(dotLayerPrefix, routeId),
        type: 'circle',
        source: addRouteIdSuffix(sourcePrefix, routeId),
        paint: { ...ROUTE_COLOR_STOPS_DOT_PAINT, 'circle-stroke-color': ['get', 'border-color'] },
        ...ZOOM_DOT_LAYER_PROPS,
      },
      beforeIdLayer
    )
  }

  mapCurrentRef.addLayer(
    {
      id: addRouteIdSuffix(markerLayerPrefix, routeId),
      type: 'symbol',
      source: addRouteIdSuffix(sourcePrefix, routeId),
      layout: {
        ...ROUTE_COLOR_STOPS_LAYOUT,
        'icon-image': ['get', 'icon'],
      },
      paint: {
        'icon-color': ['get', 'color'],
        'text-color': ['get', 'route-text-color'],
      },
      ...(hasDotLayer && { ...ZOOM_MARKER_LAYER_PROPS }),
    },
    beforeIdLayer
  )
  mapCurrentRef.on('mouseenter', addRouteIdSuffix(dotLayerPrefix, routeId), () => {
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'pointer'
  })
  mapCurrentRef.on('mouseleave', addRouteIdSuffix(dotLayerPrefix, routeId), () => {
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'grab'
  })
  mapCurrentRef.on('mouseenter', addRouteIdSuffix(markerLayerPrefix, routeId), () => {
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'pointer'
  })
  mapCurrentRef.on('mouseleave', addRouteIdSuffix(markerLayerPrefix, routeId), () => {
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'grab'
  })
}

export const setLayerVisibility = (mapRef, layerId, visibility) => {
  if (mapRef.getLayer(layerId)) {
    mapRef.setLayoutProperty(layerId, 'visibility', visibility)
  } else {
    console.warn(`Layer with ID ${layerId} does not exist.`)
  }
}
export const hideLayers = (mapRef, routeId) => {
  setLayerVisibility(mapRef, addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, routeId), 'none')
  setLayerVisibility(mapRef, addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, routeId), 'none')
  setLayerVisibility(mapRef, addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, routeId), 'none')
  setLayerVisibility(mapRef, addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, routeId), 'none')
  setLayerVisibility(mapRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId), 'none')
}

export const showLayers = (mapRef, routeId) => {
  setLayerVisibility(mapRef, addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, routeId), 'visible')
  setLayerVisibility(mapRef, addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, routeId), 'visible')
  setLayerVisibility(mapRef, addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, routeId), 'visible')
  setLayerVisibility(mapRef, addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, routeId), 'visible')
  setLayerVisibility(mapRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId), 'visible')
}

export const toggleMoveBetweenRoutesLayers = (mapRef, currentLayer, moveFromRouteId, moveToRouteId) => {
  switch (currentLayer) {
    case FROM_ROUTE_LAYER:
      showLayers(mapRef, moveFromRouteId)
      hideLayers(mapRef, moveToRouteId)
      break

    case TO_ROUTE_LAYER:
      hideLayers(mapRef, moveFromRouteId)
      showLayers(mapRef, moveToRouteId)
      break

    case BOTH_ROUTES_LAYER:
    default:
      showLayers(mapRef, moveFromRouteId)
      showLayers(mapRef, moveToRouteId)
      break
  }
}

export const fitBoundsMoveBetweenRoutesLayers = (mapRef, currentLayer, fromSequenceLine, toSequenceLine) => {
  const fromCoordinates = get(fromSequenceLine, 'geometry.coordinates', [])
  const toCoordinates = get(toSequenceLine, 'geometry.coordinates', [])

  let targetCoordinates

  switch (currentLayer) {
    case FROM_ROUTE_LAYER:
      targetCoordinates = fromCoordinates
      break

    case TO_ROUTE_LAYER:
      targetCoordinates = toCoordinates
      break

    case BOTH_ROUTES_LAYER:
    default:
      targetCoordinates = fromCoordinates.concat(toCoordinates)
      break
  }

  if (Array.isArray(targetCoordinates) && targetCoordinates.length > 0) {
    const bounds = getBounds(targetCoordinates, 25)
    mapRef.fitBounds(bounds, { maxZoom: 16, linear: true })
  }
}

export const updateFromMarkers = (selectedFromRows, rowsById, fromMarkers) => {
  return Array.isArray(fromMarkers)
    ? fromMarkers?.map(feature => {
        const properties = get(feature, 'properties', {})
        if (selectedFromRows.includes(feature.properties.id)) {
          return {
            ...feature,
            properties: {
              ...properties,
              color: PRIMARY.main,
              icon: 'highlighted-marker',
              'route-text-color': common.black,
              'border-color': common.white,
            },
          }
        }
        const route = get(rowsById, feature.properties.routeId)
        const color = get(route, 'color')
        const contrastColor = get(route, 'contrastColor')
        return {
          ...feature,
          properties: {
            ...properties,
            color,
            icon: 'monochrome-marker',
            'route-text-color': contrastColor,
          },
        }
      })
    : []
}

export const filterAndRepositionUnselectedRows = (rows, selectedRowIds) => {
  return rows.filter(row => !selectedRowIds.has(row.id)).map((row, index) => ({ ...row, position: index }))
}

export const combineAndResetPositionRows = (originalRows, additionalRows) => {
  return [...originalRows, ...additionalRows]
    .sort((a, b) => a.originalPosition - b.originalPosition)
    .map((row, index) => ({ ...row, position: index }))
}

export const combineAndRecolorMarkers = (originalMarkers, additionalMarkers, routesById, routeId, theme) => {
  return [...originalMarkers, ...additionalMarkers]
    .sort((a, b) => a.properties.originalPosition - b.properties.originalPosition)
    .map((feature, index) => {
      const properties = get(feature, 'properties', {})
      if (routeId === 'UNASSIGNED') {
        return {
          ...feature,
          properties: {
            ...properties,
            color: common.white,
            'border-color': orange[300],
            icon: 'to-be-assigned-marker',
            'route-text-color': common.black,
            position: index + 1,
          },
        }
      }
      const color = get(routesById, [routeId, 'color'], common.black)
      const formattedColor = isValidHex(color) ? color : common.black
      const routeTextColor = theme.palette.getContrastText(formattedColor)
      return {
        ...feature,
        properties: {
          ...properties,
          color,
          icon: 'monochrome-marker',
          'route-text-color': routeTextColor,
          'border-color': routeTextColor,
          position: index + 1,
        },
      }
    })
}

export const insertAndResequenceRows = (
  reorderedMoveToRows,
  reorderedMoveToRouteStopMarkers,
  formValues,
  selectedRowIdsMap,
  setValue,
  getIsMoveToRow
) => {
  let currentPosition = 0
  const updatedMoveToRows = []
  const updatedMoveToMarkers = []
  const toBeInsertedRowsIds = []
  reorderedMoveToRows.forEach((oldRow, i) => {
    const rowItem = { ...oldRow }
    const markerItem = {
      ...reorderedMoveToRouteStopMarkers[i],
      properties: { ...reorderedMoveToRouteStopMarkers[i].properties },
    }
    const rowId = get(rowItem, 'id')

    if (getIsMoveToRow(rowItem)) {
      rowItem.position = currentPosition
      markerItem.properties.position = currentPosition + 1
      currentPosition += 1
    } else {
      toBeInsertedRowsIds.push(rowId)
      const rowTypedPosition = get(formValues, rowId, '')

      if (selectedRowIdsMap.has(rowId) || (rowTypedPosition !== '' && rowTypedPosition !== currentPosition + 1)) {
        setValue(rowId, currentPosition + 1)
      }

      rowItem.position = selectedRowIdsMap.has(rowId) || rowTypedPosition ? currentPosition : ''
      markerItem.properties.position = selectedRowIdsMap.has(rowId) || rowTypedPosition ? currentPosition + 1 : ''

      if (selectedRowIdsMap.has(rowId) || rowTypedPosition !== '') {
        currentPosition += 1
        markerItem.properties = {
          ...markerItem.properties,
          ...HIGHLIGHTED_MARKER_PROPS,
        }
      } else {
        markerItem.properties = {
          ...markerItem.properties,
          ...UNASSIGNED_MARKER_PROPS,
        }
      }
    }
    updatedMoveToRows.push(rowItem)
    updatedMoveToMarkers.push(markerItem)
  })

  return {
    updatedMoveToRows,
    updatedMoveToMarkers,
    toBeInsertedRowsIds,
  }
}

export const getBulkStopData = row => {
  const accountName = get(row, 'accountName')
  const addressStr = get(row, 'addressStr')
  const id = get(row, 'id')
  const parsedDaysOfWeek = get(row, 'parsedDaysOfWeek', [])
  const position = get(row, 'position', 0) + 1
  const recurrence = get(row, 'recurrence')
  const routeId = get(row, 'routeId')
  const serviceName = get(row, 'serviceName')
  const showWeekSummary = get(row, 'showWeekSummary', false)
  const workOrderStatus = get(row, 'workOrderStatus')
  return {
    accountName,
    addressStr,
    id,
    parsedDaysOfWeek,
    position,
    recurrence,
    routeId,
    serviceName,
    showWeekSummary,
    workOrderStatus,
  }
}

export const getBulkConfirmPermanentMoveData = row => {
  const workOrderId = get(row, 'workOrderId')
  const workOrder = get(row, 'workOrderNumber')
  const workOrderStatus = get(row, 'workOrderStatus')
  const serviceDateStr = get(row, 'serviceDate')
  const serviceDate = getDateObjectFromDateString(serviceDateStr)
  const configuredServiceEventId = get(row, 'configuredServiceEventId')
  const id = [configuredServiceEventId, workOrder].join('|')
  const recurrenceFrequency = get(row, 'recurrenceFrequency', 1)
  const recurrencePer = get(row, 'recurrencePer', '')
  const recurrenceInterval = get(row, 'recurrenceInterval', '')
  const pricingPeriod = getPricingPeriod(recurrencePer, recurrenceInterval)
  const recurrence = pricingPeriod ? `${recurrenceFrequency}x ${pricingPeriod}` : 'On Request'
  return {
    id,
    serviceDate,
    type: PREVIEW_TYPE,
    hierarchy: [configuredServiceEventId, workOrder],
    workOrderId,
    configuredServiceEventId,
    workOrder,
    workOrderStatus,
    recurrence,
  }
}

export const groupFeaturesByIcon = features => {
  const monochromeFeatures = []
  const otherFeatures = []
  if (!Array.isArray(features)) return [monochromeFeatures, otherFeatures]
  features.forEach(feature => {
    if (feature.properties.icon === 'monochrome-marker') {
      monochromeFeatures.push(feature)
    } else {
      otherFeatures.push(feature)
    }
  })
  return [monochromeFeatures, otherFeatures]
}

export const getSortedSelectedMoveFromRows = selectedRowIdsMap => {
  return sortBy(Array.from(selectedRowIdsMap.values()), ['originalPosition'])
}

export const getUnselectedMoveFromRows = (selectedRowIdsMap, moveFromRows) => {
  return moveFromRows.filter(row => !selectedRowIdsMap.has(row.id)).map((r, i) => ({ ...r, position: i }))
}

export const partitionMoveFromMarkers = (selectedRowIdsMap, moveFromRouteStopMarkers) => {
  return partition(moveFromRouteStopMarkers, marker => selectedRowIdsMap.has(marker.properties.id))
}

export const getCoordsFromMarkers = markers => {
  return markers.map(marker => marker.geometry.coordinates)
}

export const updateMoveFromData = (unselectedMoveFromRows, unselectedMoveFromMarkers, unselectedMoveFromCoords) => {
  const updatedMoveFromRows = unselectedMoveFromRows
  const updatedMoveFromRouteStopMarkers = unselectedMoveFromMarkers.map((r, i) => ({
    ...r,
    properties: { ...r.properties, position: i + 1 },
  }))
  const updatedMoveFromRouteSequenceLine = {
    type: 'Feature',
    geometry: { type: 'LineString', coordinates: unselectedMoveFromCoords },
  }
  return { updatedMoveFromRows, updatedMoveFromRouteStopMarkers, updatedMoveFromRouteSequenceLine }
}

export const reorderMoveToData = (
  moveToRows,
  moveToRouteStopMarkers,
  moveToCoords,
  selectedMoveFromRows,
  selectedMoveFromMarkers,
  targetIndex,
  insertArrayAtPosition,
  selectedMoveFromCoords
) => {
  const reorderedMoveToRows = insertArrayAtPosition(moveToRows, selectedMoveFromRows, targetIndex)
  const reorderedMoveToRouteStopMarkers = insertArrayAtPosition(moveToRouteStopMarkers, selectedMoveFromMarkers, targetIndex)
  const reorderedMoveToRouteSequenceLine = {
    type: 'Feature',
    geometry: { type: 'LineString', coordinates: insertArrayAtPosition(moveToCoords, selectedMoveFromCoords, targetIndex) },
  }
  return { reorderedMoveToRows, reorderedMoveToRouteStopMarkers, reorderedMoveToRouteSequenceLine }
}

export const preProcessMoveDataOnSequenceChange = ({
  moveFromRouteStopMarkers,
  moveFromRows,
  moveToRouteSequenceLine,
  selectedRowIdsMap,
}) => {
  const selectedMoveFromRows = getSortedSelectedMoveFromRows(selectedRowIdsMap)
  const unselectedMoveFromRows = getUnselectedMoveFromRows(selectedRowIdsMap, moveFromRows)
  const [selectedMoveFromMarkers, unselectedMoveFromMarkers] = partitionMoveFromMarkers(selectedRowIdsMap, moveFromRouteStopMarkers)
  const unselectedMoveFromCoords = getCoordsFromMarkers(unselectedMoveFromMarkers)
  const selectedMoveFromCoords = getCoordsFromMarkers(selectedMoveFromMarkers)
  const moveToCoords = get(moveToRouteSequenceLine, 'geometry.coordinates', [])
  const { updatedMoveFromRows, updatedMoveFromRouteStopMarkers, updatedMoveFromRouteSequenceLine } = updateMoveFromData(
    unselectedMoveFromRows,
    unselectedMoveFromMarkers,
    unselectedMoveFromCoords
  )
  return {
    moveToCoords,
    selectedMoveFromRows,
    selectedMoveFromMarkers,
    selectedMoveFromCoords,
    updatedMoveFromRows,
    updatedMoveFromRouteStopMarkers,
    updatedMoveFromRouteSequenceLine,
  }
}

export const formatGeojsonDataBetweenDays = (data, date, routeId, theme) => {
  const routeGeojsonData = get(data, 'routeGeojsonData', {})
  const moveFromRouteFeatures = get(routeGeojsonData, `${routeId}.stops.features`, [])
  const stopMarkers = formatStopFeatures(moveFromRouteFeatures, theme).map(marker => ({
    ...marker,
    properties: {
      ...marker?.properties,
      icon: 'monochrome-marker',
      date,
    },
  }))
  const sequenceLine = get(routeGeojsonData, `${routeId}.sequenceLine`, {})
  return {
    stopMarkers,
    sequenceLine,
  }
}
