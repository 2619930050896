import React from 'react'
import T from 'T'
import PropTypes from 'prop-types'
import { noop, get } from 'lodash'
import SettingsTable from '../../../SettingsTable'

const columns = [
  { name: T.NAME, key: 'name' },
  { name: T.ADDRESS, key: 'address' },
  { name: `${T.MATERIAL}s`, key: 'materials', align: 'right' },
  { name: T.STATUS, key: 'status', align: 'right' },
]

const ListDisposalSite = ({ disposalSites = [], isLoading = false, handleEdit = noop }) => {
  const data = disposalSites.map(({ id, name, address, disposalSiteMaterial, active }) => {
    const line1 = get(address, 'line1', '')
    const state = get(address, 'state', '')
    const city = get(address, 'city', '')
    const country = get(address, 'country', '')
    const addressStr = `${line1} ${state} ${city}, ${country}`
    const materials = get(disposalSiteMaterial, 'length', 0)
    return {
      id,
      name,
      address: addressStr,
      materials,
      status: active ? 'Active' : 'Deactivated',
    }
  })
  return <SettingsTable columns={columns} data={data} isLoading={isLoading} onEditRecord={({ id }) => handleEdit(id)} />
}

ListDisposalSite.propTypes = {
  disposalSites: PropTypes.array,
  isLoading: PropTypes.bool,
  handleEdit: PropTypes.func,
}

export default ListDisposalSite
