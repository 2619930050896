import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Button, Badge, Menu, MenuItem, ListItemText, styled } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { shallowEqual, useSelector } from 'react-redux'
import T from 'T'
import PropTypes from 'prop-types'
import { RECURRENCE, SERVICE_ACTION_TYPE } from '../../../settings/constants/service'
import ServiceActionTypeChip from '../../services/ServiceActionTypeChip'

const { RECURRING } = RECURRENCE

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

const ViewActionsApplied = ({ disabled = false }) => {
  const theme = useTheme()
  const serviceActionTypeKeyList = Object.keys(SERVICE_ACTION_TYPE)
  const filters = useSelector(state => state.workOrdersReview.filters, shallowEqual)
  const serviceTypeList = useSelector(state => state.workOrdersReview.serviceTypeList, shallowEqual)
  const isRecurringFilterActive = serviceTypeList.includes(RECURRING)
  const activeFiltersCount = isRecurringFilterActive ? filters.length + 1 : filters.length
  const [anchorEl, setAnchorEl] = useState(null)
  const allFiltersSelected = activeFiltersCount === serviceActionTypeKeyList.length + 1
  const handleClick = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{ minWidth: '32px' }}
        disabled={disabled}
        startIcon={
          <StyledBadge sx={{ mr: 1.5, mb: 0.5 }} badgeContent={activeFiltersCount} color="primary">
            <FilterListIcon />
          </StyledBadge>
        }
      >
        <>
          {allFiltersSelected && 'All actions'}
          {filters.length === 0 && 'No actions'}
          {filters.length > 0 && !allFiltersSelected && 'actions'}
        </>
      </Button>
      <Menu
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ zIndex: theme.zIndex.tooltip + 10 }}
      >
        {filters.map(actionTypeKey => {
          return (
            <MenuItem>
              <ListItemText primary={<ServiceActionTypeChip action={actionTypeKey} />} />
            </MenuItem>
          )
        })}
        {isRecurringFilterActive && (
          <MenuItem>
            <ListItemText primary={<ServiceActionTypeChip action="Collection" label={T.RECURRING_COLLECTION} />} />
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

ViewActionsApplied.propTypes = {
  disabled: PropTypes.bool,
}

export default ViewActionsApplied
