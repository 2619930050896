import React from 'react'
import { Grid, Typography, Box, ListItem, ListItemText, Divider } from '@mui/material'
import ReorderIcon from 'components/common/ReorderIcon'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { get, capitalize } from 'lodash'
import T from 'T'
import LoadingButton from '@mui/lab/LoadingButton'
import { setShowBillingSideNav } from '../../../slices/billing/BillingSideNavSlice'
import { setWOReviewFiltersDialogIsOpen } from '../../../slices/billing/workOrdersReviewSlice'
import { deserializeDate, formatDateToFEFormatDateFns } from '../../../utils/date'
import ViewActionsApplied from './ViewActionsApplied'
import ViewActiveRoutes from './ViewActiveRoutes'
import { useWOReviewContext } from './WOReviewDataGrid/WOReviewProvider'

const WOReviewDialogAppbar = () => {
  const dispatch = useDispatch()
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)
  const serializedServiceDateRange = useSelector(state => state.workOrdersReview.serializedServiceDateRange, shallowEqual)
  const serviceDateRange = serializedServiceDateRange.map(deserializeDate)
  const { isLoading } = useWOReviewContext()
  const handleDrawerToggle = () => dispatch(setShowBillingSideNav(!showBillingSideNav))

  const openFiltersDialog = () => {
    dispatch(setWOReviewFiltersDialogIsOpen(true))
  }

  return (
    <Grid
      direction="row"
      container
      pl={showBillingSideNav ? 3 : 1.5}
      pr={3}
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      borderBottom={1}
      borderColor="divider"
      minHeight={72}
      columnGap={2}
    >
      <Grid item xs="auto" display="flex" alignItems="center">
        {!showBillingSideNav && <ReorderIcon onClick={handleDrawerToggle} />}
        <Typography component="span" variant="h4" fontWeight={600}>
          {T.WORK_ORDERS_REVIEW}
        </Typography>
      </Grid>

      <Grid item container xs justifyContent="flex-end" alignItems="center" columnGap={2.5} rowGap={2}>
        {serviceDateRange.map((date, index) => {
          const parsedDate = formatDateToFEFormatDateFns(date)
          return (
            <>
              <Grid key={`wo-date-${parsedDate}`} item xs="auto">
                <ListItem disableGutters disablePadding>
                  <ListItemText
                    primary={index === 0 ? capitalize(T.START_DATE) : capitalize(T.END_DATE)}
                    primaryTypographyProps={{ align: 'right', variant: 'body1', color: 'textSecondary' }}
                    secondary={parsedDate}
                    secondaryTypographyProps={{ align: 'right', variant: 'h5', fontWeight: 600, color: 'textPrimary' }}
                  />
                </ListItem>
              </Grid>
              {index === 0 && <Divider orientation="vertical" flexItem variant="middle" />}
            </>
          )
        })}
        <Grid item>
          <ViewActionsApplied />
          <ViewActiveRoutes />
        </Grid>
      </Grid>
      <Grid sm="auto">
        <Box ml={1}>
          <LoadingButton loading={isLoading} loadingIndicator="Loading…" variant="contained" onClick={openFiltersDialog}>
            {T.EDIT_FILTERS}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default WOReviewDialogAppbar
