import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Box, ListItem, Divider, IconButton, ListItemText, Typography, SvgIcon, Stack } from '@mui/material'
import MoreVert from '@mui/icons-material/MoreVert'
import { ReactComponent as ThumbtackOutlinedIcon } from 'assets/ThumbtackOutlined.svg'
import { ReactComponent as RectangleHistoryIcon } from 'assets/RectangleHistoryIcon.svg'
import { ReactComponent as ServiceNoteIcon } from 'assets/note/ServiceNote.svg'
import { ReactComponent as WorkOrderNoteIcon } from 'assets/note/WorkOrderNote.svg'
import { ReactComponent as TruckHiddenOutlinedIcon } from 'assets/TruckHiddenOutlined.svg'
import { blue } from '@mui/material/colors'

import { getNoteUserInfo } from 'data/notes/notesSelectors'
import { NOTE_TYPES } from 'settings/constants/note'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import NoteUserInfo from 'components/notes/common/NoteUserInfo'
import StyledBadge from 'components/notes/common/StyledBadge'

const { CONFIGURED_SERVICE, WORK_ORDER } = NOTE_TYPES

const NoteListItem = ({
  type = '',
  note = {},
  showNoteVisibilityOnMobileIcon = true,
  showDivider = true,
  showFullVersion = true,
  showSecondaryActions = true,
  onViewClick = noop,
  onActionMenuClick = noop,
  sx,
}) => {
  const { note: noteText, pinned, version, parentNoteId, visibleOnMobile, inherit } = note
  const { firstName, lastName, username, dateTime } = getNoteUserInfo({ note })
  const fullName = `${firstName} ${lastName}`.trim()
  const displayName = fullName || username || '--'
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime })
  const showHistoryIcon = showFullVersion && version > 1

  return (
    <>
      <ListItem
        onClick={onViewClick}
        sx={{
          pr: showSecondaryActions && showHistoryIcon ? 12 : 6,
          cursor: 'pointer',
          ...(pinned && { backgroundColor: `${blue[700]}0a` }),
          ...sx,
        }}
        secondaryAction={
          showSecondaryActions && (
            <Stack direction="row" alignItems="center">
              {showHistoryIcon && (
                <IconButton onClick={onViewClick}>
                  <StyledBadge badgeContent={version}>
                    <RectangleHistoryIcon />
                  </StyledBadge>
                </IconButton>
              )}
              <IconButton onClick={onActionMenuClick}>
                <MoreVert />
              </IconButton>
            </Stack>
          )
        }
      >
        {showFullVersion && <NoteUserInfo firstName={firstName} lastName={lastName} />}
        <ListItemText
          primaryTypographyProps={{ variant: 'h6', fontWeight: 400, whiteSpace: 'pre-line' }}
          primary={noteText}
          secondary={
            <Box display="flex" alignItems="center" gap={0.25}>
              <Box display="flex" alignItems="center" gap={0.25} sx={{ '& svg': { width: 20, height: 20 } }}>
                {pinned && (
                  <SvgIcon sx={{ ml: -0.5 }}>
                    <ThumbtackOutlinedIcon />
                  </SvgIcon>
                )}
                {showNoteVisibilityOnMobileIcon && !visibleOnMobile && <TruckHiddenOutlinedIcon />}
                {type === CONFIGURED_SERVICE && inherit && <WorkOrderNoteIcon />}
                {type === WORK_ORDER && parentNoteId && <ServiceNoteIcon />}
              </Box>
              <Typography noWrap variant="caption">{`Updated by ${displayName} | ${onlyDate} @ ${onlyTime}`}</Typography>
            </Box>
          }
        />
      </ListItem>
      {showDivider && <Divider />}
    </>
  )
}

NoteListItem.propTypes = {
  type: PropTypes.string,
  note: PropTypes.object,
  showNoteVisibilityOnMobileIcon: PropTypes.bool,
  showDivider: PropTypes.bool,
  showFullVersion: PropTypes.bool,
  showSecondaryActions: PropTypes.bool,
  onViewClick: PropTypes.func,
  onActionMenuClick: PropTypes.func,
  sx: PropTypes.object,
}

export default NoteListItem
