import React from 'react'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import T from 'T'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { closeConfirmAccountReactivatedDialog, selectIsOpenConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'

const ConfirmAccountReactivatedDialog = () => {
  const isOpenConfirmAccountReactivatedDialog = useSelector(selectIsOpenConfirmAccountReactivatedDialog, shallowEqual)
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeConfirmAccountReactivatedDialog())
  }

  console.log(isOpenConfirmAccountReactivatedDialog)

  return (
    <HHConfirmDialog
      isOpen={isOpenConfirmAccountReactivatedDialog}
      onConfirm={onClose}
      onClose={onClose}
      showCancelButton={false}
      ConfirmButtonProps={{
        color: 'success',
      }}
      confirmButtonTitle={T.OK}
      confirmTitle={T.ACCOUNT_ACTIVATED}
      confirmDescription={T.ACCOUNT_ACTIVATED_DESCRIPTION}
    />
  )
}

export default ConfirmAccountReactivatedDialog
