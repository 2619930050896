import { isRejectedWithValue } from '@reduxjs/toolkit'
import { handleFirebaseLogout } from 'providers/firebaseLogout'
import { STATUS_CODE } from 'settings/status'
import { ERROR_EXCEPTIONS } from 'settings/error'
import { get } from 'utils/lodash'

const { UNAUTHORIZED } = STATUS_CODE
const { INVALID_USER_EXCEPTION } = ERROR_EXCEPTIONS

export const rtkQueryErrorLogger = () => next => action => {
  if (isRejectedWithValue(action)) {
    const statusCode = action?.payload?.status
    const exceptionType = get(action, 'payload.data.exceptionType')

    if (statusCode === UNAUTHORIZED) {
      handleFirebaseLogout()
    }

    if (exceptionType === INVALID_USER_EXCEPTION) {
      handleFirebaseLogout()
    }
  }

  return next(action)
}
