import React, { useCallback, useEffect, useMemo } from 'react'
import SecondaryNavbar from 'components/pricing/LateFees/SecondaryNavbar'
import { Box, darken, lighten, LinearProgress, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import T from 'T'
import {
  getLateFeeRowClassName,
  groupRowsByBusinessLine,
  LATE_FEES_BUSINESS_LINES_TABS,
  mapActiveBusinessLinesToCategories,
} from 'components/pricing/LateFees/CreateEditLateFeesDialog/settings'
import {
  closeBulkLinkLateFeeDialog,
  closeBulkUnlinkLateFeeDialog,
  selectArchivedRestoredLateFee,
  selectHideArchivedLateFees,
  selectIsBulkLinkLateFeeDialogOpen,
  selectIsBulkUnlinkLateFeeDialogOpen,
  selectSelectedLateFeeBusinessLine,
  setArchivedRestoredLateFee,
  setSelectedLateFeeBusinessLine,
} from 'slices/pricing/lateFeesSlice'
import { useDispatch, useSelector } from 'react-redux'
import RenderLateFeeCell from 'components/pricing/LateFees/LateFeesListing/RenderLateFeeCell'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useLazyFetchLateFeesQuery } from 'api/pricing/lateFeesCrud'
import { get } from 'lodash'
import CustomLateFeeRow from 'components/pricing/LateFees/LateFeesListing/CustomLateFeeRow'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useLazyFetchMetaFiltersQuery } from 'api/meta/getMetaFilters'
import BulkLinkLateFeesDialog from 'components/pricing/LateFees/LinkUnlinkLateFees/BulkLinkLateFeesDialog'
import BulkUnlinkLateFeesDialog from 'components/pricing/LateFees/LinkUnlinkLateFees/BulkUnlinkLateFeesDialog'
import { BACKGROUND } from 'theme/colors'

const { ALL_BUSINESS_LINES, RESIDENTIAL, ROLL_OFF, COMMERCIAL } = LATE_FEES_BUSINESS_LINES_TABS

const LateFees = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const selectedLateFeeBusinessLine = useSelector(selectSelectedLateFeeBusinessLine)
  const hideArchived = useSelector(selectHideArchivedLateFees)
  const [fetchMetaFilters, { isLoading: isMetaFiltersLoading, isFetching: isMetaFiltersFetching, data: metaFiltersData }] =
    useLazyFetchMetaFiltersQuery()
  const [fetchLateFees, { data, isLoading, isFetching }] = useLazyFetchLateFeesQuery()
  const isBulkLinkLateFeeDialogOpen = useSelector(selectIsBulkLinkLateFeeDialogOpen)
  const isBulkUnlinkLateFeeDialogOpen = useSelector(selectIsBulkUnlinkLateFeeDialogOpen)
  const activeBusinessLinesToCategories = useMemo(() => {
    const { businessLines = [] } = metaFiltersData || {}
    return mapActiveBusinessLinesToCategories(businessLines)
  }, [metaFiltersData])
  const archivedRestoredLateFee = useSelector(selectArchivedRestoredLateFee)
  const archivedRestoredLateFeeId = useMemo(() => get(archivedRestoredLateFee, 'id'), [archivedRestoredLateFee])
  const filteredSelectedGroupRows = useMemo(() => {
    const rows = get(data, 'lateFees', [])
    const filteredRows = hideArchived ? rows.filter(({ archived }) => !archived) : rows
    const groupedRowsByCategories = groupRowsByBusinessLine(filteredRows, activeBusinessLinesToCategories)
    return get(groupedRowsByCategories, selectedLateFeeBusinessLine, [])
  }, [data, activeBusinessLinesToCategories, selectedLateFeeBusinessLine, hideArchived])

  const idToIndexMap = useMemo(
    () =>
      filteredSelectedGroupRows.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {}),
    [filteredSelectedGroupRows]
  )

  const rowLateFeeHeight = useMemo(() => {
    if (isXs) return 312
    if (isSm) return 208
    if (isMd) return 156
    return 104
  }, [isXs, isSm, isMd])

  const getRowHeight = useCallback(() => {
    return 'auto'
  }, [])
  const handleChangeSelectedLateFeeBusinessLine = (event, newSelectedBusinessLine) => {
    dispatch(setSelectedLateFeeBusinessLine(newSelectedBusinessLine))
  }

  const getRowSpacing = useCallback(
    params => ({
      top: params.model.id === get(filteredSelectedGroupRows, '[0].id') ? 24 : 0,
    }),
    [filteredSelectedGroupRows]
  )

  const getRowClassName = useCallback(getLateFeeRowClassName, [])

  const getRowId = useCallback(({ id }) => id, [])

  const handleCloseBulkLinkLateFeesDialog = () => {
    dispatch(closeBulkLinkLateFeeDialog())
  }

  const handleCloseBulkUnlinkLateFeesDialog = () => {
    dispatch(closeBulkUnlinkLateFeeDialog())
  }

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  useEffect(() => {
    fetchMetaFilters({
      body: {},
    })
    fetchLateFees()
  }, [])

  useEffect(() => {
    if (archivedRestoredLateFeeId) {
      setTimeout(() => {
        apiRef?.current?.scrollToIndexes({ rowIndex: idToIndexMap[archivedRestoredLateFeeId] })
        dispatch(setArchivedRestoredLateFee(undefined))
      }, 500)
    }
  }, [rowLateFeeHeight, archivedRestoredLateFeeId, idToIndexMap])

  return (
    <Stack
      direction="column"
      sx={{
        height: '100%',
        width: '100%',
        flex: '1 1 0',
      }}
    >
      <SecondaryNavbar />
      <Box px={2} borderBottom={1} borderColor="divider">
        <Tabs
          value={selectedLateFeeBusinessLine}
          onChange={handleChangeSelectedLateFeeBusinessLine}
          sx={{ button: { textTransform: 'none' } }}
        >
          <Tab value={ALL_BUSINESS_LINES} label={T.ALL_BUSINESS_LINES} />
          <Tab value={RESIDENTIAL} label={T.RESIDENTIAL} />
          <Tab value={COMMERCIAL} label={T.COMMERCIAL} />
          <Tab value={ROLL_OFF} label={T.ROLLOFF} />
        </Tabs>
      </Box>
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            apiRef={apiRef}
            sx={{
              borderTop: 0,
              borderRadius: 0,
              '& .MuiDataGrid-cell , & .MuiDataGrid-row': {
                border: 0,
              },
              '& .MuiDataGrid-cell.late-fee-row-cell': {
                pr: 0.8,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
                background: 'transparent',
                '&.archived': {
                  background: BACKGROUND.deactivated,
                  '&:hover, .deactivated-bg-indicator': {
                    backgroundColor: getHoverBackgroundColor(BACKGROUND.deactivated, theme.palette.mode),
                  },
                },
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden',
              },
            }}
            hideFooter
            slots={{
              detailPanelExpandIcon: ChevronRightIcon,
              detailPanelCollapseIcon: ExpandMoreIcon,
              loadingOverlay: LinearProgress,
              row: CustomLateFeeRow,
            }}
            columns={[
              {
                field: 'id',
                flex: 1,
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: params => <RenderLateFeeCell hideFooter {...params} />,
                cellClassName: 'late-fee-row-cell',
              },
            ]}
            getRowClassName={getRowClassName}
            getRowHeight={getRowHeight}
            columnHeaderHeight={0}
            disableRowSelectionOnClick
            getRowId={getRowId}
            getRowSpacing={getRowSpacing}
            loading={isLoading || isFetching || isMetaFiltersLoading || isMetaFiltersFetching}
            rows={filteredSelectedGroupRows}
          />
          {isBulkLinkLateFeeDialogOpen && (
            <BulkLinkLateFeesDialog isOpen={isBulkLinkLateFeeDialogOpen} onClose={handleCloseBulkLinkLateFeesDialog} />
          )}
          {isBulkUnlinkLateFeeDialogOpen && (
            <BulkUnlinkLateFeesDialog isOpen={isBulkUnlinkLateFeeDialogOpen} onClose={handleCloseBulkUnlinkLateFeesDialog} />
          )}
        </Box>
      </Box>
    </Stack>
  )
}

LateFees.propTypes = {}

export default LateFees
