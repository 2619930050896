import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Popover } from '@mui/material'

import { memo } from 'utils/react'
import ViewContacts from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContacts'
import { useLazyGetLocationSummaryQuery } from 'api/location/getLocations'
import { get } from 'lodash'

const ViewLocationContactsPopover = ({ locationId, anchorEl, onClose }) => {
  const open = Boolean(anchorEl)
  const [getLocationSummary, { isLoading, isFetching, data }] = useLazyGetLocationSummaryQuery()
  const activeContacts = useMemo(() => {
    return get(data, 'contacts', []).filter(({ deactivated }) => !deactivated)
  }, [data])

  useEffect(() => {
    if (locationId && open) {
      getLocationSummary({
        locationId,
      })
    }
  }, [locationId, open])

  return (
    <Popover
      sx={{ pointerEvents: 'none', minWidth: 'fit-content', maxWidth: '100%', mr: 2 }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box>
        <ViewContacts loading={isLoading || isFetching} contacts={activeContacts} column={3} />
      </Box>
    </Popover>
  )
}

ViewLocationContactsPopover.propTypes = {
  locationId: PropTypes.string,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
}

export default memo(ViewLocationContactsPopover)
