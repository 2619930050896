import React, { useReducer, useEffect, Children, useState } from 'react'
import PropTypes from 'prop-types'
import flattenDeep from 'lodash/flattenDeep'
import noop from 'lodash/noop'
import AddIcon from '@mui/icons-material/Add'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { shallowEqual, useSelector } from 'react-redux'
import { Button, Box, Typography } from '@mui/material'
import { useSaveBusinessLineConfigurationMutation } from 'api/settings/saveBusinessLineConfiguration'

import { cloneDeep, get } from 'lodash'
import T from 'T'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { handleError } from 'utils/error'
import Loader from 'components/common/loader'

import ListCategories from './create-configure/ListCategories'
import AddCategory from './create-configure/AddCategory'

const CreateConfigureSettings = ({
  service = {},
  measure = {},
  material = [],
  action = [],
  method = [],
  configurations = [],
  businessLines = [],
  getBusinessLineConfigurations = noop,
  getBusinessLines = noop,
}) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [addNew, setAddNew] = useState(false)

  const [saveBusinessLineConfiguration, { isLoading: isAddLoading, isFetching: isAddFetching }] = useSaveBusinessLineConfigurationMutation()

  const [categoryState, setCategoryState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    active: {},
    isExpanded: {},
    businessLineName: '',
    selectedIcon: `${T.RESIDENTIAL}.svg`,
    newActive: true,
    editId: '',
    payload: {},
    clonedConfiguration: [],
    footerFlag: false,
    configList: {},
  })

  const { active, newActive, selectedIcon, businessLineName, editId, payload, clonedConfiguration, configList } = categoryState

  useEffect(() => {
    let clonedData = cloneDeep(configurations)
    setCategoryState({ clonedConfiguration: clonedData })
  }, [configurations])

  const handleEdit = id => {
    setAddNew(true)

    let record = configurations.find(data => data.id === id)

    payload['businessLineId'] = id

    setCategoryState({
      businessLineName: record.businessLine,
      newActive: record.active,
      selectedIcon: record.businessLineIcon,
      editId: id,
      payload: payload,
      footerFlag: false,
    })
  }

  const handleCheckedValues = (type, id, data, event) => {
    if (!updateSettings) return
    payload['businessLineId'] = id

    if (type === 'materials') {
      type = 'material'
    } else if (type === 'methods') {
      type = 'method'
    }

    if (configList[id] === undefined) {
      configList[id] = {}
    }

    if (configList[id][type] === undefined) {
      configList[id][type] = []
    }

    configList[id][type].push({
      id: data.id,
      alreadySelected: data.alreadySelected,
      select: event.target.checked,
    })

    if (payload['values'] === undefined) {
      payload['values'] = []
    }

    let newRecord = {}

    Children.toArray(
      Object.entries(configList[id]).map(([key, value]) => {
        let record = {
          type: key,
          configList: value,
        }

        newRecord[key] = record
      })
    )

    payload['values'] = Object.values(newRecord)

    if (clonedConfiguration.length > 0 || flattenDeep(Object.values(businessLines)).length > 0) {
      if (type === 'measure' && id === undefined) {
        let payloadVal = payload['values'].find(val => val.type === type)['configList']
        let businessLineVal = businessLines.measure[data.unit].find(rec => rec.id === data.id)

        payloadVal['alreadySelected'] = event.target.checked
        businessLineVal['alreadySelected'] = event.target.checked
      } else if (type === 'period' || type === 'recurrence' || id === undefined) {
        data['alreadySelected'] = event.target.checked
      } else {
        let selectedBusinessLine = clonedConfiguration.find(val => val.id === id)

        let selectedType = selectedBusinessLine[type].find(rec => rec.id === data.id)
        selectedType['alreadySelected'] = event.target.checked
      }

      setCategoryState({ clonedConfiguration: clonedConfiguration, payload: payload, footerFlag: true })
    }
  }

  const handleSave = () => {
    setAddNew(false)

    if (payload['businessLineId'] === undefined || payload['businessLineId'] === null) {
      payload['businessLineId'] = null
      payload['active'] = newActive
      payload['businessLineName'] = businessLineName
      payload['businessLineIcon'] = `${selectedIcon}`
    } else {
      let currentIndex = configurations.map(data => data.id).indexOf(payload['businessLineId'])
      let currentRecord = configurations[currentIndex]

      payload['active'] = active[payload['businessLineId']]
      payload['businessLineName'] = businessLineName || get(currentRecord, 'businessLine', '')
      payload['businessLineIcon'] = `${selectedIcon}`
    }

    if (payload['values'] === undefined) {
      payload['values'] = []
    }

    saveBusinessLineConfiguration(payload)
      .unwrap()
      .then(() => {
        setCategoryState({
          businessLineName: '',
          selectedIcon: `${T.RESIDENTIAL}.svg`,
          editId: '',
          payload: {},
          footerFlag: false,
        })
        setCategoryState({ configList: {} })
        getBusinessLineConfigurations()
      })
      .catch(handleError)
  }

  const handleCancel = () => {
    setAddNew(false)
    setCategoryState({
      active: {},
      isExpanded: {},
      businessLineName: '',
      selectedIcon: `${T.RESIDENTIAL}.svg`,
      newActive: false,
      editId: '',
      payload: {},
      footerFlag: false,
    })
  }

  const onHandleNameChange = event => {
    let { value } = event.target

    const { name } = event.target

    if (/^\s/.test(value)) {
      value = ''
    }

    categoryState[name] = value
    setCategoryState({ [name]: value, footerFlag: true })
  }

  const onHandleIconChange = event => {
    setCategoryState({ selectedIcon: event.target.value, footerFlag: businessLineName !== '' })
  }

  const handleAdd = () => {
    setAddNew(true)
    setCategoryState({ editId: '' })
    setCategoryState({ footerFlag: false })
    getBusinessLines()
  }

  return (
    <Box sx={{ overflowY: 'scroll' }} maxHeight="calc(100vh - 100px)">
      {(isAddLoading || isAddFetching) && <Loader />}

      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNew ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setAddNew(false)}
            >
              {T.CREATE_N_CONFIGURE}
            </Typography>

            {addNew && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${editId === '' ? T.NEW : T.EDIT} ${T.BUSINESS_LINE}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNew && addSettings && (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>

        {addNew && updateSettings && (
          <AddCategory
            handleSave={handleSave}
            handleCancel={handleCancel}
            configurations={clonedConfiguration}
            businessLines={businessLines}
            categoryState={categoryState}
            setCategoryState={setCategoryState}
            handleCheckedValues={handleCheckedValues}
            onHandleNameChange={onHandleNameChange}
            onHandleIconChange={onHandleIconChange}
            editId={editId}
            addNew={addNew}
          />
        )}
      </Box>

      {!addNew && (
        <ListCategories
          service={service}
          measure={measure}
          material={material}
          action={action}
          method={method}
          handleSave={handleSave}
          configurations={clonedConfiguration}
          handleCheckedValues={handleCheckedValues}
          categoryState={categoryState}
          setCategoryState={setCategoryState}
          payload={payload}
          handleEdit={handleEdit}
          getBusinessLineConfigurations={getBusinessLineConfigurations}
        />
      )}
    </Box>
  )
}

CreateConfigureSettings.propTypes = {
  service: PropTypes.object,
  measure: PropTypes.object,
  material: PropTypes.array,
  action: PropTypes.array,
  method: PropTypes.array,
  configurations: PropTypes.array,
  businessLines: PropTypes.object,
  getBusinessLineConfigurations: PropTypes.func,
  getBusinessLines: PropTypes.func,
}

export default CreateConfigureSettings
