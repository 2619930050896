import React from 'react'
import PropTypes from 'prop-types'

import { ResponsivePie } from '@nivo/pie'
import { useTheme } from '@mui/material'

const GroupPieChart = ({ data = [], colors = [], legendProps = {}, ...rest }) => {
  const theme = useTheme()

  return (
    <ResponsivePie
      theme={{ fontSize: 14, legends: { text: { fontSize: 13 } } }}
      colors={colors}
      data={data}
      margin={{ top: 16, right: 16, bottom: 48, left: 16 }}
      innerRadius={0.5}
      padAngle={1}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1.5}
      borderColor={{ from: 'color', modifiers: [['darker', 1.5]] }}
      enableArcLinkLabels={false}
      arcLabelsSkipAngle={5}
      arcLabels
      arcLabelsTextColor={theme.palette.text.primary}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 40,
          itemsSpacing: 12,
          itemWidth: 80,
          itemHeight: 10,
          itemTextColor: theme.palette.text.primary,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 15,
          symbolShape: 'square',
          ...legendProps,
        },
      ]}
      {...rest}
    />
  )
}

GroupPieChart.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array,
  legendProps: PropTypes.object,
}

export default GroupPieChart
