import { useMemo } from 'react'

import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { useBulkUpdateAccountSettingsMutation } from 'api/groups/account/bulkUpdateAccountSettings'
import { useBulkUpdateAccountSettingsByAgingBucketMutation } from 'api/groups/account/accountsByAgingBucketCrud'

export const useGetBulkUpdateAccountSettings = accountGroupId => {
  const isDefaultBucket = useMemo(() => Object.values(BUCKET_NAME_TO_GROUP_ID_PARAM).includes(accountGroupId), [accountGroupId])
  if (isDefaultBucket) return useBulkUpdateAccountSettingsByAgingBucketMutation
  return useBulkUpdateAccountSettingsMutation
}
