import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectBulkResequenceFitBounds,
  selectBulkResequenceOriginalRouteSequenceLine,
  selectBulkResequenceRouteSequenceLine,
  selectBulkResequenceRouteStopMarkers,
  selectBulkResequenceMapLayer,
  selectBulkResequenceRoutesById,
  selectIsOpenBulkResequenceStopsDialog,
  setBulkResequenceStopsState,
} from 'slices/route/bulkResequenceStopsSlice'
import { useFormContext } from 'react-hook-form'
import { TOP_LAYER } from 'utils/map'
import Proptypes from 'prop-types'
import ToggleRouteLayers from 'components/route-manager/BulkActions/BulkUnassignStopsDialog/ToggleRouteLayers'
import { MapContext } from 'providers/MapProvider'
import {
  fitBoundsMoveBetweenRoutesLayers,
  removeLayersAndSources,
  toggleMoveBetweenRoutesLayers,
  DYNAMIC_COLOR_DOT_LAYER,
  DYNAMIC_COLOR_STOPS_LAYER,
  STATIC_COLOR_DOT_LAYER,
  STATIC_COLOR_STOPS_LAYER,
} from 'components/route-manager/BulkActions/settings'
import { addRouteIdSuffix } from 'components/route-manager/RouteManagerMap/settings'
import { get } from 'utils/lodash'

import CommonSelectStopsToMoveMap from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/CommonSelectStopsToMoveMap'
import useRecreateRouteSourcesAndLayers from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useRecreateRouteSourcesAndLayers'
import useMapRefInitDispose from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useMapInitDispose'
import useUpdateRouteLayer from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useUpdateRouteLayer'

const MapView = ({ routes }) => {
  const dispatch = useDispatch()
  const map = useContext(MapContext)
  const routesById = useSelector(selectBulkResequenceRoutesById)
  const [layersCreated, setLayersCreated] = useState(false)
  const mapContainer = useRef(null)
  const { watch } = useFormContext()
  const open = useSelector(selectIsOpenBulkResequenceStopsDialog)
  const originalRouteSequenceLine = useSelector(selectBulkResequenceOriginalRouteSequenceLine)
  const routeSequenceLine = useSelector(selectBulkResequenceRouteSequenceLine)
  const routeStopMarkers = useSelector(selectBulkResequenceRouteStopMarkers)
  const mapLayer = useSelector(selectBulkResequenceMapLayer)
  const routeId = watch('routeId')
  const fitBounds = useSelector(selectBulkResequenceFitBounds)
  const recreateRouteSourcesAndLayers = useRecreateRouteSourcesAndLayers(map, routesById)
  const { isMapLoaded } = useMapRefInitDispose({ map, mapContainer, open })
  const updateRouteLayer = useUpdateRouteLayer(map)

  const onMarkerClickHandler = useCallback(e => {
    const properties = get(e, 'features.0.properties', {})
    const id = get(properties, 'id')
    dispatch(setBulkResequenceStopsState({ clickedMarkerId: id }))
  }, [])

  const recreateSourcesAndLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !routes.length) {
      return null
    }
    setLayersCreated(false)
    routes.forEach(route => {
      removeLayersAndSources(map.current, route.id)
    })

    if (routeId) {
      recreateRouteSourcesAndLayers(routeId, { sequenceData: routeSequenceLine, features: routeStopMarkers, hasDotLayer: false }, TOP_LAYER)
      map.current.off('click', addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, routeId), onMarkerClickHandler)
      map.current.off('click', addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, routeId), onMarkerClickHandler)
      map.current.off('click', addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, routeId), onMarkerClickHandler)
      map.current.off('click', addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, routeId), onMarkerClickHandler)

      map.current.on('click', addRouteIdSuffix(DYNAMIC_COLOR_DOT_LAYER, routeId), onMarkerClickHandler)
      map.current.on('click', addRouteIdSuffix(DYNAMIC_COLOR_STOPS_LAYER, routeId), onMarkerClickHandler)
      map.current.on('click', addRouteIdSuffix(STATIC_COLOR_DOT_LAYER, routeId), onMarkerClickHandler)
      map.current.on('click', addRouteIdSuffix(STATIC_COLOR_STOPS_LAYER, routeId), onMarkerClickHandler)
    }
    setLayersCreated(true)
  }, [map, isMapLoaded, routeId, routes, routeStopMarkers, routeSequenceLine])

  const updateLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    if (routeId) updateRouteLayer(routeId, { sequenceData: routeSequenceLine, features: routeStopMarkers })
    toggleMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, routeId)
  }, [map, isMapLoaded, layersCreated, routeId, routeStopMarkers, routeSequenceLine, updateRouteLayer])

  const fitBoundsToLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    fitBoundsMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, originalRouteSequenceLine)
  }, [map, isMapLoaded, layersCreated, originalRouteSequenceLine])

  useEffect(() => {
    recreateSourcesAndLayers()
  }, [isMapLoaded, routeId, routes])

  useEffect(() => {
    updateLayers()
  }, [routeId, layersCreated, routeStopMarkers, routeSequenceLine])

  useEffect(() => {
    if (fitBounds) {
      fitBoundsToLayers()
    }
  }, [fitBounds, layersCreated, originalRouteSequenceLine])

  return <CommonSelectStopsToMoveMap mapContainer={mapContainer} ToggleComponent={<ToggleRouteLayers map={map} />} />
}

MapView.propTypes = {
  routes: Proptypes.array.isRequired,
}

export default memo(MapView)
