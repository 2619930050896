import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Plus } from '@styled-icons/heroicons-solid/Plus'
import { XCircle } from '@styled-icons/heroicons-solid/XCircle'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isOnRequestService } from 'utils/service'
import { createDateFromString, formatDateToBEFormatDateFns, isNotInvalidDate } from 'utils/date'
import { CommonTextLabel, CommonIconSelectExtended } from 'components/common'

import T from 'T'
import Constants from 'Constants'
import HHDatePicker from 'components/common/HHDatePicker'
import HHNumberField from 'components/form-fields/v5/HHNumberField'

const { MEDIA_SERVER } = Constants

// Later combine on request service & service group UI
const ScheduleServiceGroup = ({ serviceType = '', pricedServiceDetails = {}, schedule = {}, serviceEvents = [], onChange }) => {
  const [actionList, setActionList] = useState([])
  const allServices = get(pricedServiceDetails, 'allServices', [])
  const groupId = get(pricedServiceDetails, 'id')

  const handleServiceActionChange = (index, action) => {
    const cloneServiceEvents = cloneDeep(serviceEvents)
    const actionExists = cloneServiceEvents.find(event => event.actionType === action.name)
    set(cloneServiceEvents, `[${index}].actionType`, action.name)

    if (actionExists) {
      // Get price from service event
      set(cloneServiceEvents, `[${index}].price`, actionExists.price)
    } else {
      // Get price from existing service event
      set(cloneServiceEvents, `[${index}].price`, action.price)
    }
    onChange('serviceEvents', cloneServiceEvents, 'schedule')
  }

  const handleServiceEventPriceChange = (actionType, value) => {
    if (!actionType) {
      return
    }

    // Change all similar services price
    onChange(
      'serviceEvents',
      serviceEvents.map(event => (event.actionType === actionType ? { ...event, price: value } : { ...event })),
      'schedule'
    )
  }

  useEffect(() => {
    if (isOnRequestService(serviceType)) {
      setActionList(allServices)
    }
  }, [groupId])

  return (
    <Box className="on-request">
      <Box className="mt3-5 upcoming-events">
        <CommonTextLabel title="Upcoming Service Events" className="label" />
        {Children.toArray(
          serviceEvents.map((serviceEvent, index) => {
            const actionDate = get(serviceEvent, 'actionDate', '')
            const actionType = get(serviceEvent, 'actionType', '')
            const price = get(serviceEvent, 'price', '')
            const formatDate = createDateFromString(actionDate)

            return (
              <Grid container columnSpacing={2} mt={1}>
                <Grid item xs={4}>
                  <HHDatePicker
                    handleChange={date => {
                      const isValidDate = isNotInvalidDate(date)
                      const cloneServiceEvents = cloneDeep(serviceEvents)
                      set(cloneServiceEvents, `[${index}].actionDate`, isValidDate ? formatDateToBEFormatDateFns(date) : '')
                      set(cloneServiceEvents, `[${index}].isValid`, isValidDate)
                      onChange('serviceEvents', cloneServiceEvents, 'schedule')
                    }}
                    value={formatDate}
                    TextFieldProps={{ error: !get(serviceEvents, `[${index}].isValid`, true) }}
                    disablePast
                  />
                </Grid>

                <Grid item xs={5}>
                  <CommonIconSelectExtended
                    records={actionList}
                    selectedRecord={{ name: actionType }}
                    placeholder={`Select ${T.ACTION}s`}
                    type="action"
                    onChange={(isChanged, value) => {
                      if (isChanged) {
                        handleServiceActionChange(index, value)
                        return
                      }
                      setActionList(allServices.filter(action => action.name.toLowerCase().indexOf(value) > -1))
                    }}
                    onBlur={() => setActionList(cloneDeep(allServices))}
                  />
                </Grid>

                <Grid item xs={3} display="flex" alignItems="center" justifyContent="space-between">
                  <HHNumberField
                    fullWidth
                    placeholder="$0.00"
                    value={price}
                    autoComplete="off"
                    allowNegative={false}
                    decimalSeparator="."
                    decimalScale={2}
                    thousandSeparator
                    prefix="$"
                    onValueChange={values => {
                      const { value } = values
                      handleServiceEventPriceChange(actionType, value)
                    }}
                  />

                  {index > 0 && (
                    <Box ml={1} sx={{ color: 'text.secondary' }}>
                      <XCircle
                        width={16}
                        height={16}
                        className="cursor-pointer"
                        onClick={() => {
                          onChange('schedule', {
                            ...schedule,
                            serviceEvents: serviceEvents.filter((list, eventIndex) => eventIndex !== index),
                          })
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            )
          })
        )}
      </Box>

      <Box
        className="mt2 add-event inline-flex items-center cursor-pointer"
        onClick={() => {
          const lastAction = get(serviceEvents, `[${serviceEvents.length - 1}].actionType`, '')
          const lastPrice = get(serviceEvents, `[${serviceEvents.length - 1}].price`, '')
          onChange('serviceEvents', [...serviceEvents, { actionDate: '', actionType: lastAction, price: lastPrice }], 'schedule')
        }}
      >
        <Plus />
        <Box component="span" className="text">
          {T.EVENT}
        </Box>
        <img className="ml2" src={`${MEDIA_SERVER}CalendarCheckPrimary.svg`} alt="" />
      </Box>
    </Box>
  )
}

ScheduleServiceGroup.propTypes = {
  serviceType: PropTypes.string,
  pricedServiceDetails: PropTypes.object,
  serviceEvents: PropTypes.array,
  schedule: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(ScheduleServiceGroup)
