import React, { Children } from 'react'

import { TableRow, TableHead as THead, TableCell } from '@mui/material'

import { memo } from 'utils/react'

import { WORK_ORDER_COLUMNS } from './settings'

const WorkOrderTableHeader = () => (
  <THead className="table-header">
    <TableRow>
      {Children.toArray(
        WORK_ORDER_COLUMNS.map(column => (
          <TableCell className={column.className} align={column.align} size={column.size}>
            {column.label && (
              <div className="inline-flex items-center">
                <span>{column.label}</span>
              </div>
            )}
          </TableCell>
        ))
      )}
    </TableRow>
  </THead>
)

export default memo(WorkOrderTableHeader)
