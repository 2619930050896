import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getEmailStatus: build.query({
      query: ({ email }) => ({
        url: '/api/v1/core/settings/users/email:exists',
        params: { 'user-email': email },
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetEmailStatusQuery } = extendedApi
