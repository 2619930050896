import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Grid, ListItem, ListItemAvatar, ListItemText, Typography, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { formatDateToFEFormat } from 'utils/date'
import { INVOICE_TYPE } from 'settings/constants/billing'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'

const { MANUAL } = INVOICE_TYPE
const getBillingCycle = ({ startDate, endDate, invoiceDate, billingProfileDetails }) => {
  try {
    return getBillingPeriods({
      billingProfile: billingProfileDetails,
      billingPeriods: [{ startDate, endDate, invoiceDate }],
    })
  } catch (error) {
    return []
  }
}

const RenderInvoiceCell = props => {
  const { row } = props
  const {
    totalCents,
    amountPaidCents,
    status,
    dueDate: rawDueDate,
    invoiceNumber,
    type,
    startDate,
    endDate,
    invoiceDate,
    billingCycleInterval,
    billingCycleIntervalType,
    billingProfileTiming,
    billingProfileTimingDay,
  } = row

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const dueDate = transformDate(rawDueDate)
  const pendingAmount = totalCents - amountPaidCents
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, rawDueDate, amountPaidCents, totalCents)
  const billingProfileDetails = {
    billingCycle: {
      interval: billingCycleInterval,
      intervalType: capitalize(billingCycleIntervalType),
    },
    timing: capitalize(billingProfileTiming),
    timingDay: billingProfileTimingDay,
  }
  const parsedInvoiceDate = transformDate(invoiceDate)
  const billingCycle = getBillingCycle({ startDate, endDate, invoiceDate, billingProfileDetails })
  const isManual = type === MANUAL
  const title = isManual ? parsedInvoiceDate : get(billingCycle, '[0].labelLeft', '-')

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <ListItem sx={{ '&.MuiListItem-root': { p: 0 } }}>
          <Box minWidth={120}>
            <ListItemAvatar sx={{ mr: 2 }}>
              <InvoiceStatusChip
                status={status}
                isPartial={isPartial}
                agingBucketType={agingBucketType}
                showAmount
                amountCents={pendingAmount}
              />
            </ListItemAvatar>
          </Box>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ variant: 'subtitle2', fontWeight: 500, color: 'textPrimary' }}
            secondary={
              <>
                <Typography display="block" variant="caption">{`Invoice #: ${invoiceNumber}`}</Typography>
                <Typography display="block" variant="caption">{`Invoice date: ${parsedInvoiceDate}`}</Typography>
              </>
            }
          />
        </ListItem>
      </Grid>
      <Grid item>
        <ListItem disablePadding>
          <ListItemText
            primary={<HHDisplayMoney value={totalCents} formatToDollars />}
            primaryTypographyProps={{ variant: 'h6', fontWeight: 500, textAlign: 'right', color: 'textPrimary' }}
            secondary={rawDueDate ? `Due: ${dueDate}` : null}
            secondaryTypographyProps={{ fontWeight: 400, mt: 1 }}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}

RenderInvoiceCell.propTypes = {
  row: PropTypes.object,
}

export default RenderInvoiceCell
