import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useFormContext } from 'react-hook-form'
import CommonModalHeader from 'components/common/CommonModalHeader'
import CommonModalFooter from 'components/common/CommonModalFooter'
import T from 'T'
import Constants from 'Constants'
import { useCreateDisposalFeeMutation } from 'api/pricing/createDisposalFees'
import ConfirmationTable from './ConfirmationTable/ConfirmationTable'

const { MEDIA_SERVER } = Constants

const formResultToPayload = (disposalFees, disposalFeeFields) =>
  disposalFees.map((disposalFee, index) => {
    const { feeName, costPlus, tiers, measureType, minFee, overageThreshold, overageFee } = disposalFeeFields[index]
    return {
      ...disposalFee,
      feeName: feeName.trim(),
      costPlus,
      tiers: tiers.filter(({ price, maxVal, minVal }, tierIndex) => {
        if (tierIndex !== 0 && price === '' && maxVal === '' && minVal === '') {
          return false
        }
        return !(tierIndex !== 0 && price === '' && maxVal === '')
      }),
      measureType,
      minFee,
      overageThreshold,
      overageFee,
      renderTierFields: false,
    }
  })

const ConfirmationScreen = ({ services, uniqueRecords, duplicateRecords, setIsOpen, onBack, onCreate }) => {
  const { handleSubmit, watch, formState } = useFormContext()

  const [syncUniqueRecord, setUniqueRecords] = useState(uniqueRecords)
  const [syncDuplicatedRecords, setSyncDuplicatedRecords] = useState(duplicateRecords)
  const [createDisposalFees] = useCreateDisposalFeeMutation()

  const onSubmit = data => {
    const pricedDisposalFee = formResultToPayload(syncUniqueRecord, data.disposalFees)
    createDisposalFees({ pricedDisposalFee, services })
    onCreate()
  }

  const isAnyNameEmpty = array =>
    array.find(({ feeName }) => {
      return feeName.replaceAll(' ', '') === ''
    })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CommonModalHeader
        title={`${T.NEW_DISPOSAL_FEE}`}
        leadingIcon={<Box component="img" mr={1} src={`${MEDIA_SERVER}ClipboardList.svg`} alt="fee-icon" />}
        onClose={() => {
          setIsOpen(false)
        }}
      />
      <Box
        sx={{
          height: 'calc(100vh - 102px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingBottom: 64,
        }}
      >
        <Box
          sx={{
            padding: '24px 16px',
          }}
        >
          <ConfirmationTable
            disposalFees={uniqueRecords}
            duplicatedDisposalFees={duplicateRecords}
            syncDisposalFees={syncUniqueRecord}
            setSyncDisposalFees={setUniqueRecords}
            syncDuplicatedDisposalFees={syncDuplicatedRecords}
            setSyncDuplicatedDisposalFees={setSyncDuplicatedRecords}
          />
        </Box>
      </Box>
      <CommonModalFooter
        sx={{
          position: 'relative',
          bottom: 64,
        }}
        saveButtonLabel={T.CREATE_DISPOSAL_FEE}
        cancelButtonLabel={T.CLOSE}
        backButtonLabel={T.BACK}
        saveType="submit"
        onSave={() => {}}
        onCancel={() => {
          setIsOpen(false)
        }}
        onBack={onBack}
        showBackButton
        saveButtonDisabled={
          watch('disposalFees').length === 0 || formState.isValid === false || isAnyNameEmpty(watch('disposalFees')) !== undefined
        }
      />
    </form>
  )
}

ConfirmationScreen.propTypes = {
  uniqueRecords: PropTypes.array.isRequired,
  duplicateRecords: PropTypes.array.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
}

export default ConfirmationScreen
