import React from 'react'
import PropTypes from 'prop-types'

import TableContainer from '@mui/material/TableContainer'

const TableLayout = ({ children, height = '100%' }) => {
  return (
    <TableContainer
      sx={{
        height,
        overflowY: 'scroll',
        'tr th:last-child, tr td:last-child': {
          paddingRight: 7,
        },
      }}
    >
      {children}
    </TableContainer>
  )
}

TableLayout.propTypes = {
  height: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default TableLayout
