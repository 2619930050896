import React from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { Redirect } from 'react-router-dom'
import routes, { APP_PREFIX } from 'router/routes'

const changeToSingleCustomerPath = path => path.replace('/customers', '/customer')

const singularCustomerRoutes = {
  customers: changeToSingleCustomerPath(routes.app.customers),
  accounts: changeToSingleCustomerPath(routes.app.accounts),
  customerDetails: changeToSingleCustomerPath(routes.app.customerDetails),
  customerDetailsVariant: `${APP_PREFIX}/customer/details`,
}

const RedirectToValidCustomerDetailsUrlHelper = () => {
  const location = useLocation()
  let path
  if (location.pathname === `${APP_PREFIX}/customer/details` && location.hash && location.hash.includes('account_id')) {
    const accountId = location.hash.split('=')[1]
    if (accountId) {
      path = `${APP_PREFIX}/customers/${accountId}/details`
    } else {
      path = `${APP_PREFIX}/customers`
    }
  } else {
    path = location.pathname.replace('/customer', '/customers')
  }
  return <Redirect to={path} />
}

export { singularCustomerRoutes }

export default RedirectToValidCustomerDetailsUrlHelper
