import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Loader from 'components/common/loader'
import { Box, Grid } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { get } from 'utils/lodash'
import { getBillingCycleChanges } from 'data/billing-profile/billingCycleSelectors'

import T from 'T'

import LeftSide from './LeftSide'

const AddProfiles = ({
  disableFormFields = false,
  isLoading = false,
  profileState = {},
  customerMeta = {},
  setProfileState = noop,
  onHandleTextChange = noop,
}) => {
  const onHandleValChange = (e, type) => {
    let value
    if (type === 'deliveryMethod') {
      value = e
    } else {
      value = e.target.value
    }

    switch (type) {
      case 'timing':
        setProfileState({ timingDay: '', timingWeek: '' })
        break
      case 'cycle':
        setProfileState({
          ...getBillingCycleChanges({
            billingCycles: get(customerMeta, 'billingCycles', []),
            cycleId: e.target.value,
            paymentType: get(profileState, 'paymentType', ''),
            timing: get(profileState, 'timing', ''),
            timingDay: get(profileState, 'timingDay', ''),
            timingWeek: get(profileState, 'timingWeek', ''),
          }),
        })
        break
      default:
    }

    setProfileState({ [type]: value, footerFlag: true })
  }

  const onValueTypeChange = val => {
    setProfileState({ feeType: val, footerFlag: true })
  }

  return (
    <Box p="20px 24px" maxHeight="calc(100vh - 180px)" sx={{ overflowY: 'auto' }}>
      {isLoading && <Loader />}
      <HHTextField
        label={`${T.CUSTOMER_BILLING_PROFILE} ${T.NAME}`}
        fullwidth
        variant="outlined"
        autoComplete="off"
        placeholder={`${T.CUSTOMER_BILLING_PROFILE} ${T.NAME}`}
        value={get(profileState, 'customerBillingProfileName', '')}
        name="customerBillingProfileName"
        sx={{ mb: 2, minWidth: '100%' }}
        InputProps={{
          onChange: e => onHandleTextChange(e),
        }}
      />
      <Grid container spacing="24">
        <Grid item xs="12">
          <LeftSide
            disableFormFields={disableFormFields}
            profileState={profileState}
            customerMeta={customerMeta}
            onValueTypeChange={onValueTypeChange}
            onHandleTextChange={onHandleTextChange}
            onHandleValChange={onHandleValChange}
            setProfileState={setProfileState}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

AddProfiles.propTypes = {
  disableFormFields: PropTypes.bool,
  isLoading: PropTypes.bool,
  profileState: PropTypes.object,
  customerMeta: PropTypes.object,
  setProfileState: PropTypes.func,
  onHandleTextChange: PropTypes.func,
}

export default AddProfiles
