import React, { Children, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { memo } from 'utils/react'
import Card from '@mui/material/Card'
import T from 'T'
import { canSeePermissionSettings, canSeeUserSettings } from 'data/permissions/permissionsSelectors'
import { get } from 'utils/lodash'
import HHAccordion from './HHAccordion'

const tabs = [
  { title: `${T.GENERAL_SETTING}s`, type: 'select' },
  { title: `${T.BUSINESS_LINE}s`, type: 'select' },
  { title: `${T.TAG}s`, type: 'normal' },
  { title: `${T.USER}s`, type: 'select' },
  { title: `${T.TRUCK}s`, type: 'normal' },
  { title: `${T.PERMIT}s`, type: 'normal' },
  { title: `${T.ZONE}s`, type: 'select' },
  { title: `${T.BILLING}`, type: 'select' },
  { title: `${T.CUSTOMER_PORTAL}`, type: 'normal' },
]
const ZONES = [`${T.PRICING_ZONE}s`, `${T.TAX_ZONE}s`]
const BILLING = [T.GENERAL, `${T.CUSTOMER_BILLING_PROFILE}s`, T.GENERAL_LEDGER]

const BUSINESS_LINES = [T.ATTRIBUTES, T.CREATE_N_CONFIGURE]
const GENERAL_SETTINGS = [T.BUSINESS_INFO, `${T.YARD}s`, `${T.DISPOSAL_SITE}s`]

const SideBar = ({ activeOption = '', handleActiveClass = null, handleActiveOptions = null }) => {
  const [user, setUser] = useState([])
  const seeUserSettings = useSelector(canSeeUserSettings, shallowEqual)
  const seePermissionSettings = useSelector(canSeePermissionSettings, shallowEqual)
  const getOptions = val => {
    switch (val) {
      case 'Users':
        return user
      case 'Zones':
        return ZONES
      case 'Billing':
        return BILLING
      case 'General Settings':
        return GENERAL_SETTINGS
      default:
        return BUSINESS_LINES
    }
  }

  const handleSetUserItems = useCallback(() => {
    const updatedUser = [`${T.SKILL}s`]
    if (seeUserSettings) {
      updatedUser.push(T.PEOPLE)
    }
    if (seePermissionSettings) {
      updatedUser.push(`${T.ROLE}s`)
    }
    setUser(updatedUser)
  }, [])

  useEffect(() => {
    handleSetUserItems()
  }, [])

  return (
    <Card
      sx={{
        height: 'calc(100vh - 56px)',
        overflowY: 'auto',
        minWidth: 240,
        p: 2,
        '&::-webkit-scrollbar': {
          width: 0,
          background: 'transparent',
        },
      }}
    >
      {Children.toArray(
        tabs.map((tab, index) => {
          const title = get(tab, 'title', '')
          if (tab.type === 'normal') {
            return <HHAccordion title={title} handleActiveOptions={handleActiveOptions} handleActiveClass={handleActiveClass} />
          }

          if (tab.type === 'select') {
            return (
              <HHAccordion
                title={title}
                isExpanded
                options={getOptions(title)}
                handleSelectTab={handleActiveOptions}
                handleActiveOptions={handleActiveOptions}
                handleActiveClass={handleActiveClass}
                indexOptions={index}
                activeOption={activeOption}
              />
            )
          }
        })
      )}
    </Card>
  )
}

SideBar.propTypes = {
  activeOption: PropTypes.string,
  handleActiveClass: PropTypes.func,
  handleActiveOptions: PropTypes.func,
}

export default memo(SideBar)
