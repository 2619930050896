import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

const EventBlocker = ({ children, preventDefault = true }) => {
  const handleClick = event => {
    if (preventDefault) {
      event.preventDefault()
    }
    event.stopPropagation()
  }

  return (
    <Box component="span" onClick={handleClick}>
      {children}
    </Box>
  )
}

EventBlocker.propTypes = {
  children: PropTypes.any,
  preventDefault: PropTypes.bool,
}

export default EventBlocker
