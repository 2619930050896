import React, { useCallback, useEffect, useMemo } from 'react'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { DialogContent, Button, Box, Stack, useTheme } from '@mui/material'
import {
  selectAgingBucketAccountId,
  selectAgingBucketType,
  selectIsTakePaymentDialogOpen,
  selectOpenAgingBucket,
  setTakePaymentState,
  setOpenAgingBucket,
  selectTakePaymentInvoiceNumber,
} from 'slices/billing/agingBucketSlice'
import { useDispatch, useSelector } from 'react-redux'
import CancelButton from 'components/buttons/CancelButton'
import BorderlessDataGridPro from 'components/common/BorderlessDataGridPro'
import { get } from 'lodash'
import { AGING_BUCKET_TYPE_TO_QUERY_PARAM } from 'components/common/aging-bucket/settings'
import { useLazyGetAgingInvoiceListByAccountQuery } from 'api/billing/getBillingInvoiceListByAccount'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'
import InvoiceSendFailureConfirmation from 'containers/billing/InvoiceSendFailureConfirmation'
import {
  selectDraftInvoicesWithoutPDF,
  selectIsOpenInvoiceSendFailureConfirmation,
  setBulkSentInvoicesConfirmation,
  setInvoicesSendPostData,
} from 'slices/billing/invoicesSlice'
import { gridClasses } from '@mui/x-data-grid-pro'

import { getInvoicesPDFStatus, isDraftInvoice } from 'utils/billing'
import { handleError } from 'utils/error'
import BulkSentInvoicesConfirmationDialog from 'components/billing/invoices/BulkSentInvoicesConfirmationDialog'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { CACHE_TAG_ACCOUNT_INVOICE_LIST, CACHE_TAG_INVOICE_LIST } from 'api/cacheTagTypes'
import api from 'api'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import RenderAgingBucketCell from 'components/common/aging-bucket/InteractiveAgingBucket/RenderAgingBucketCell'
import CustomGridTreeDataGroupingCell from 'components/common/aging-bucket/InteractiveAgingBucket/CustomGridTreeDataGroupingCell'
import HHBaseDialog from 'components/common/HHBaseDialog'

const TIMEOUT = 4000

const AgingBucketDialog = () => {
  const theme = useTheme()
  const open = useSelector(selectOpenAgingBucket)
  const accountId = useSelector(selectAgingBucketAccountId)
  const agingBucketType = useSelector(selectAgingBucketType)
  const isTakePaymentDialogOpen = useSelector(selectIsTakePaymentDialogOpen)
  const isOpenInvoiceSendFailureConfirmation = useSelector(selectIsOpenInvoiceSendFailureConfirmation)
  const invoiceNumber = useSelector(selectTakePaymentInvoiceNumber)
  const draftInvoicesWithoutPDF = useSelector(selectDraftInvoicesWithoutPDF)
  const dispatch = useDispatch()
  const [sendInvoiceList, { isLoading: isSendInvoiceLoading }] = useSendInvoiceListMutation()
  const [getAgingInvoiceList, { isLoading, isFetching, data }] = useLazyGetAgingInvoiceListByAccountQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const [getAccountDetails, { data: accountData }] = useLazyGetAccountDetailsQuery()
  const formatData = useCallback(
    (data, accountId) => {
      const invoices = get(data, 'accountInvoices', [])

      const formattedInvoices = invoices.map(invoice => ({
        ...invoice,
        id: invoice.id,
        path: [accountId, invoice.id],
        isInvoiceRow: true,
        isAccountRow: false,
      }))

      const accountRow = {
        id: accountId,
        path: [accountId],
        isInvoiceRow: false,
        isAccountRow: true,
      }

      const combinedRows = [accountRow, ...formattedInvoices]
      const rowCount = combinedRows.length
      const hasRowData = rowCount !== 0

      return {
        rows: combinedRows,
        rowsCount: rowCount,
        hasRows: hasRowData,
      }
    },
    [data, accountId]
  )

  // Use useMemo to memoize the result of processData
  const { rows, hasRows } = useMemo(() => formatData(data, accountId), [formatData, data, accountId])

  const rowsExceedsLimit = rows.length > 20

  const columns = [
    {
      field: 'id',
      renderCell: p => <RenderAgingBucketCell {...p} accountData={accountData} />,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const onClose = () => {
    dispatch(setOpenAgingBucket(false))
  }

  const handleCloseTakePaymentDialog = () => {
    dispatch(
      setTakePaymentState({
        open: false,
        invoiceNumber: null,
      })
    )
  }

  const handleCloseInvoiceSendFailureConfirmation = () => {
    dispatch(setInvoicesSendPostData({ isOpenInvoiceSendFailureConfirmation: false, draftInvoicesWithoutPDF: [] }))
  }

  const getDraftAndNonDraftInvoicesBySelectedRows = useCallback(
    (key = 'id') => {
      const draftInvoices = []
      const nonDraftInvoices = []
      rows.forEach(invoice => {
        if (isDraftInvoice(invoice.status)) draftInvoices.push(get(invoice, key))
        else nonDraftInvoices.push(key ? get(invoice, key) : invoice)
      })

      return { draftInvoices, nonDraftInvoices }
    },
    [rows]
  )

  const handleSendInvoicesWithoutPDF = notExists => {
    dispatch(setInvoicesSendPostData({ draftInvoicesWithoutPDF: notExists, isOpenInvoiceSendFailureConfirmation: true }))
  }

  const handleRefetch = (timeout = 0) =>
    setTimeout(() => dispatch(api.util.invalidateTags([CACHE_TAG_INVOICE_LIST, CACHE_TAG_ACCOUNT_INVOICE_LIST])), timeout)

  const onSendInvoices = async () => {
    const { draftInvoices, nonDraftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows('invoiceNumber')
    const { withPDF, withoutPDF } = await getInvoicesPDFStatus(draftInvoices)
    const invoicesWithPDF = nonDraftInvoices.concat(withPDF)
    const hasInvoicesWithoutPDF = withoutPDF.length > 0

    if (invoicesWithPDF.length) {
      sendInvoiceList({ invoiceNumbers: invoicesWithPDF })
        .unwrap()
        .then(() => {
          if (hasInvoicesWithoutPDF) {
            handleSendInvoicesWithoutPDF(withoutPDF)
            handleRefetch(TIMEOUT)
            return
          }
          handleRefetch(TIMEOUT)
          dispatch(setBulkSentInvoicesConfirmation({ isOpen: true }))
        })
        .catch(handleError)
    } else if (hasInvoicesWithoutPDF) {
      handleSendInvoicesWithoutPDF(withoutPDF)
    }
  }

  const getTreeDataPath = row => row.path

  const getRowHeight = params => {
    const isAccountRow = get(params, 'model.isAccountRow', false)
    return isAccountRow ? 45 : 72.55
  }

  const groupingColDef = {
    headerName: 'Hierarchy',
    renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
    width: 1,
    minWidth: 1,
    maxWidth: 1,
    flex: 0,
    resizable: false,
  }

  const getRowClassName = params => {
    const { row } = params
    const isAccountRow = get(row, 'isAccountRow')
    return isAccountRow ? 'account-row' : 'invoice-row'
  }

  useEffect(() => {
    if (accountId) {
      getAccountDetails({
        accountId,
      })
    }
  }, [accountId])

  useEffect(() => {
    const aging = get(AGING_BUCKET_TYPE_TO_QUERY_PARAM, agingBucketType)
    if (open && aging && accountId) {
      getAgingInvoiceList({
        accountId,
        aging,
      })
    }
  }, [open, agingBucketType, accountId])

  return (
    <HHBaseDialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title="Invoice 60-90 days late" onClose={onClose} />
      <DialogContent>
        <Box height={rowsExceedsLimit ? 800 : 'auto'} mt={3} overflow="auto">
          <BorderlessDataGridPro
            loading={loading}
            columns={columns}
            rows={rows}
            treeData
            getRowClassName={getRowClassName}
            getTreeDataPath={getTreeDataPath}
            getRowHeight={getRowHeight}
            columnHeaderHeight={0}
            hideFooter
            defaultGroupingExpansionDepth={-1}
            autoHeight={!rowsExceedsLimit}
            groupingColDef={groupingColDef}
            sx={{
              [`& .${gridClasses.row}.account-row`]: {
                backgroundColor: theme.palette.background.header,
              },
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
                borderBottom: '1px solid',
                borderBottomColor: 'divider',
              },
            }}
          />
        </Box>
      </DialogContent>
      <HHDialogActions sx={{ width: '100%', flexBasis: '100%' }}>
        <Stack
          sx={{
            width: '100%',
          }}
          direction="row"
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Box flex="1 1 0">
            <CancelButton onClick={onClose} />
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              disabled={isLoading || isSendInvoiceLoading || !hasRows}
              onClick={onSendInvoices}
              sx={{ textWrap: 'nowrap' }}
              size="small"
            >
              Email invoices
            </Button>
          </Stack>
        </Stack>
      </HHDialogActions>
      {isTakePaymentDialogOpen && (
        <TakePaymentsDialog
          isOpen={isTakePaymentDialogOpen}
          initialAccount={{ id: accountId, invoiceNumber }}
          onClose={handleCloseTakePaymentDialog}
        />
      )}
      <BulkSentInvoicesConfirmationDialog confirmDescription="Invoices are being sent." />

      <InvoiceSendFailureConfirmation
        isOpen={isOpenInvoiceSendFailureConfirmation}
        invoices={draftInvoicesWithoutPDF}
        onClose={handleCloseInvoiceSendFailureConfirmation}
      />
    </HHBaseDialog>
  )
}

AgingBucketDialog.propTypes = {}

export default AgingBucketDialog
