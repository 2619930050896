import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    // deprecated mutation
    getMetaFilters: builder.mutation({
      query: body => ({ url: '/api/meta/filters', method: 'POST', body }),
    }),
    fetchMetaFilters: builder.query({
      query: ({ body = undefined, params = undefined }) => ({
        url: '/api/meta/filters',
        method: 'POST',
        body: {
          ...body,
        },
        params,
      }),
    }),
  }),
})

export const { useGetMetaFiltersMutation, useLazyFetchMetaFiltersQuery } = extendedApi
