import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { get, keyBy } from 'lodash'
import { ArrowBack } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBulkUnassignFromRouteStopMarkers,
  selectBulkUnassignFromRows,
  selectBulkUnassignToRouteStopMarkers,
  selectBulkUnassignToRows,
  setBulkUnassignStopsState,
  setSelectedMoveToRows,
} from 'slices/route/bulkUnassignStopsSlice'
import PropTypes from 'prop-types'
import BulkMoveColumnHeader from 'components/route-manager/BulkActions/common/BulkMoveColumnHeader'
import useBulkMoveSelectedRows from 'components/route-manager/BulkActions/common/useBulkMoveSelectedRows'
import T from 'T'

const RenderMoveToHeader = ({ routes }) => {
  const routesById = keyBy(routes, 'id')
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const selectedRowsCount = get(selectedRowIdsMap, 'size', 0)
  const moveToRows = useSelector(selectBulkUnassignToRows)
  const moveFromRows = useSelector(selectBulkUnassignFromRows)
  const moveFromRouteStopMarkers = useSelector(selectBulkUnassignFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkUnassignToRouteStopMarkers)

  const dispatch = useDispatch()

  const moveSelectedRows = useBulkMoveSelectedRows({
    moveToRows,
    moveFromRows,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    routesById,
    moveToRouteSequenceLine: {},
    bulkReduxAction: setBulkUnassignStopsState,
  })

  const handleAssignClick = () => {
    moveSelectedRows()
    dispatch(setSelectedMoveToRows([]))
  }

  return <BulkMoveColumnHeader label={T.ASSIGN} onClick={handleAssignClick} selectedRowsCount={selectedRowsCount} endIcon={<ArrowBack />} />
}

RenderMoveToHeader.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default RenderMoveToHeader
