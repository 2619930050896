export const handleRolesPayload = (payload, roleState) => {
  const {
    allowRoadLogin,
    allowOfficeLogin,
    settingsCrud,
    permissionsCrud,
    userCrud,
    resetPasswordCrud,
    pricingManagerCrud,
    billingCrud,
    customerBillingProfileCrud,
    invoicesCrud,
    postInvoicesPaymentCrud,
    paymentsCrud,
    applyPaymentsCrud,
    lockDateCrud,
    serviceCrud,
    customerManagerCrud,
    routeManagerCrud,
    followUpCrud,
    anyFollowUpCrud,
    dashboardCrud,
    reportsCrud,
    globalSearchCrud,

    mrcShowMiles,
    mrcPurchaseOrder,
    mrcMaterial,
    mrcMeasure,
    mrcService,
    mrcSchedule,
    mrcSequence,
    mrcDate,
    mrcQuantity,

    dcSafety,
    dcDelay,
    dcNoAccess,
    dcDamage,
    dcOverloaded,
    dcExtraItem,
    dcSpecialItem,
    dcContamination,
    dcServiceReview,
    dcWeight,
    dcNoPayment,
    dcNotOut,
    dcCamera,
    dcSignature,
    dcNote,
    dcSerialNumber,

    customerInformationCrud,
    truckCrud,
    routeCrud,
    autocompleteCrud,
    timerCrud,
  } = roleState

  if (allowOfficeLogin) {
    payload.settingsCrud = settingsCrud
    payload.permissionsCrud = permissionsCrud
    payload.userCrud = userCrud
    payload.resetPasswordCrud = resetPasswordCrud
    payload.pricingManagerCrud = pricingManagerCrud
    payload.billingCrud = billingCrud
    payload.customerBillingProfileCrud = customerBillingProfileCrud
    payload.invoicesCrud = invoicesCrud
    payload.postInvoicesPaymentCrud = postInvoicesPaymentCrud
    payload.paymentsCrud = paymentsCrud
    payload.applyPaymentsCrud = applyPaymentsCrud
    payload.lockDateCrud = lockDateCrud
    payload.serviceCrud = serviceCrud
    payload.customerManagerCrud = customerManagerCrud
    payload.routeManagerCrud = routeManagerCrud
    payload.followUpCrud = followUpCrud
    payload.anyFollowUpCrud = anyFollowUpCrud
    payload.dashboardCrud = dashboardCrud
    payload.reportsCrud = reportsCrud
    payload.globalSearchCrud = globalSearchCrud
  }

  if (allowRoadLogin) {
    payload.mrcShowMiles = mrcShowMiles
    payload.mrcPurchaseOrder = mrcPurchaseOrder
    payload.mrcMaterial = mrcMaterial
    payload.mrcMeasure = mrcMeasure
    payload.mrcService = mrcService
    payload.mrcSchedule = mrcSchedule
    payload.mrcSequence = mrcSequence
    payload.mrcDate = mrcDate
    payload.mrcQuantity = mrcQuantity

    payload.dcSafety = dcSafety
    payload.dcDelay = dcDelay
    payload.dcNoAccess = dcNoAccess
    payload.dcDamage = dcDamage
    payload.dcOverloaded = dcOverloaded
    payload.dcExtraItem = dcExtraItem
    payload.dcSpecialItem = dcSpecialItem
    payload.dcContamination = dcContamination
    payload.dcServiceReview = dcServiceReview
    payload.dcWeight = dcWeight
    payload.dcNoPayment = dcNoPayment
    payload.dcNotOut = dcNotOut
    payload.dcCamera = dcCamera
    payload.dcSignature = dcSignature
    payload.dcNote = dcNote
    payload.dcSerialNumber = dcSerialNumber

    payload.customerInformationCrud = customerInformationCrud
    payload.truckCrud = truckCrud
    payload.routeCrud = routeCrud
    payload.autocompleteCrud = autocompleteCrud
    payload.timerCrud = timerCrud
  }
  return payload
}

export const setUserProfile = (res, setRoleState) => {
  setRoleState({
    active: res.active,
    allowOfficeLogin: res.allowOfficeLogin,
    allowRoadLogin: res.allowRoadLogin,
    profileName: res.profileName,

    settingsCrud: res.settingsCrud,
    permissionsCrud: res.permissionsCrud,
    userCrud: res.userCrud,
    resetPasswordCrud: res.resetPasswordCrud,
    pricingManagerCrud: res.pricingManagerCrud,
    billingCrud: res.billingCrud,
    customerBillingProfileCrud: res.customerBillingProfileCrud,
    invoicesCrud: res.invoicesCrud,
    postInvoicesPaymentCrud: res.postInvoicesPaymentCrud,
    paymentsCrud: res.paymentsCrud,
    applyPaymentsCrud: res.applyPaymentsCrud,
    lockDateCrud: res.lockDateCrud,
    serviceCrud: res.serviceCrud,
    customerManagerCrud: res.customerManagerCrud,
    routeManagerCrud: res.routeManagerCrud,
    followUpCrud: res.followUpCrud,
    anyFollowUpCrud: res.anyFollowUpCrud,
    dashboardCrud: res.dashboardCrud,
    reportsCrud: res.reportsCrud,
    globalSearchCrud: res.globalSearchCrud,

    mrcShowMiles: res.mrcShowMiles,
    mrcPurchaseOrder: res.mrcPurchaseOrder,
    mrcMaterial: res.mrcMaterial,
    mrcMeasure: res.mrcMeasure,
    mrcService: res.mrcService,
    mrcSchedule: res.mrcSchedule,
    mrcSequence: res.mrcSequence,
    mrcDate: res.mrcDate,
    mrcQuantity: res.mrcQuantity,

    dcSafety: res.dcSafety,
    dcDelay: res.dcDelay,
    dcNoAccess: res.dcNoAccess,
    dcDamage: res.dcDamage,
    dcOverloaded: res.dcOverloaded,
    dcExtraItem: res.dcExtraItem,
    dcSpecialItem: res.dcSpecialItem,
    dcContamination: res.dcContamination,
    dcServiceReview: res.dcServiceReview,
    dcWeight: res.dcWeight,
    dcNoPayment: res.dcNoPayment,
    dcNotOut: res.dcNotOut,
    dcCamera: res.dcCamera,
    dcSignature: res.dcSignature,
    dcNote: res.dcNote,
    dcSerialNumber: res.dcSerialNumber,

    customerInformationCrud: res.customerInformationCrud,
    truckCrud: res.truckCrud,
    routeCrud: res.routeCrud,
    autocompleteCrud: res.autocompleteCrud,
    timerCrud: res.timerCrud,
  })
}
