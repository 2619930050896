import React, { Children } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import useTheme from '@mui/material/styles/useTheme'

const LIST_STYLES = { listStyleType: 'disc', pl: 3, mt: 1, color: 'text.primary' }
const LIST_ITEM_STYLES = { display: 'list-item' }

const BulkMoveFailure = ({ isOpen = false, stops = [], onClose }) => {
  const theme = useTheme()
  return (
    <HHBaseDialog sx={{ zIndex: theme.zIndex.snackbar + 4 }} open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.BULK_MOVE_EXCEPTIONS} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            The following stops could not be moved because they already exist on the destination day.
          </Typography>

          <List sx={LIST_STYLES}>
            {Children.toArray(
              stops.map(stop => (
                <ListItem disablePadding sx={LIST_ITEM_STYLES}>
                  <Typography variant="h5" fontWeight={400}>
                    {stop}
                  </Typography>
                </ListItem>
              ))
            )}
          </List>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

BulkMoveFailure.propTypes = {
  isOpen: PropTypes.bool,
  stops: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default BulkMoveFailure
