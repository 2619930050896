import React from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { Typography, TextField, Grid } from '@mui/material'
import { CommonSwitch, CommonTextLabel } from 'components/common'

import T from 'T'
import Constants from 'Constants'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { HHNumberField } from 'components/form-fields/v5'
import { canUpdateSettings } from 'data/permissions/permissionsSelectors'

const { MEDIA_SERVER } = Constants

const PostCardPreview = ({ postState, setPostState }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  return (
    <Grid container ml={3}>
      <Grid item xs={12}>
        <Typography variant="body1">{T.POSTCARD_PREVIEW}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" mt={1}>
          <Grid item>
            <CommonSwitch
              titlePosition="right"
              title="Postal permit"
              isChecked={postState.postalPermit}
              onChange={() => (updateSettings ? setPostState({ postalPermit: !postState.postalPermit, footerFlag: true }) : '#')}
            />

            {postState.postalPermit && (
              <Grid container justifyContent="space-between" mt={1.5}>
                <Grid item>
                  <HHNumberField
                    label={T.ZIP_CODE}
                    value={get(postState, 'zipCode', '')}
                    placeholder="Zip Code"
                    onValueChange={values => {
                      const { value } = values
                      if (updateSettings) {
                        setPostState({ zipCode: value, footerFlag: true })
                      }
                    }}
                    sx={{ maxWidth: 120 }}
                  />
                </Grid>
                <Grid item ml={1.5}>
                  <HHNumberField
                    label={`${T.PERMIT} #`}
                    value={get(postState, 'permitNumber', '')}
                    placeholder="Permit"
                    onValueChange={values => {
                      const { value } = values
                      if (updateSettings) {
                        setPostState({ permitNumber: value, footerFlag: true })
                      }
                    }}
                    sx={{ maxWidth: 120 }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <CommonTextLabel title={T.NOTE} />
            <TextField
              placeholder={`${T.NOTE}...`}
              variant="outlined"
              rows={3}
              fullWidth
              multiline
              defaultValue={get(postState, 'note', '')}
              onChange={event => (updateSettings ? setPostState({ note: event.target.value, footerFlag: true }) : '#')}
              sx={{ minWidth: 380 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2.5}>
        {postState.postalPermit && <img src={`${MEDIA_SERVER}PostCardSample.svg`} alt="" width="100%" />}
        {!postState.postalPermit && <img src={`${MEDIA_SERVER}NoStampPostCard.svg`} alt="" width="100%" />}
      </Grid>
    </Grid>
  )
}

PostCardPreview.defaultProps = {
  postState: {},
  setPostState: null,
}

PostCardPreview.propTypes = {
  postState: PropTypes.object,
  setPostState: PropTypes.func,
}
export default memo(PostCardPreview)
