export const INITIAL_STATE = {
  address: {
    addressName: '',
    line1: '',
    unitNumber: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  },
  contacts: [],
  tags: [],
  signatureRequired: false,
  locationPurchaseOrder: false,
  po: '',
  maxPoAmount: '',
}
