import api from 'api'
import { CACHE_TAG_ROUTES_METADATA } from 'api/cacheTagTypes'

// NOTE implement https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#transforming-responses
export const extendApiSlice = api.injectEndpoints({
  endpoints: build => ({
    getRouteMetadata: build.query({
      query: () => ({
        url: '/api/meta/route',
        method: 'POST',
      }),
      providesTags: [CACHE_TAG_ROUTES_METADATA],
    }),
  }),
})

export const { useGetRouteMetadataQuery } = extendApiSlice

// export memoized Selector for this mutation id on the api [apiReducerPath].mutations[mutationId] path
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-users-data
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
export const getRouteMetadataSlice = extendApiSlice.endpoints.getRouteMetadata.select()
