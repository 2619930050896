import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const CommonTextLabel = ({ children, title, className, isRequired, onClick, sx, ...rest }) => (
  <Box sx={sx} component="label" className={`common label ${className}`} htmlFor={title} onClick={onClick} {...rest}>
    {title || children}
    {isRequired && (
      <Box ml={0.5} component="span" className="red">
        *
      </Box>
    )}
  </Box>
)

CommonTextLabel.defaultProps = {
  title: null,
  className: '',
  children: null,
  isRequired: false,
  onClick: null,
}

CommonTextLabel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
}

export default CommonTextLabel
