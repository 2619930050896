import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_LIST, CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    createInvoiceGroupBatch: build.mutation({
      query: ({ invoiceGroupId }) => ({ url: `/api/v1/billing/invoice/groups/${invoiceGroupId}/batch`, method: 'POST' }),
      invalidatesTags: (result, error, arg) => [
        CACHE_TAG_BILLING_INVOICE_GROUP_LIST,
        { type: CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS, id: arg.invoiceGroupId },
      ],
    }),
  }),
})

export const { useCreateInvoiceGroupBatchMutation } = extendedApi
