import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { green, lightBlue } from '@mui/material/colors'
import { get } from 'utils/lodash'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const TotalInfo = ({ invoice = {} }) => {
  const hasAutopay = get(invoice, 'account.autoPay', false)
  const accountNumber = get(invoice, 'account.accountNumber', '')
  const invoiceNumber = get(invoice, 'invoiceNumber', '')
  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        px={1}
        borderTop="2px solid"
        borderBottom="2px solid"
        borderColor="divider"
        className="text-xs break-inside-avoid"
      >
        <p>Invoice total&nbsp;|&nbsp;</p>
        <div className="font-medium text-lg">
          <HHDisplayMoney value={get(invoice, 'total', 0)} formatToDollars={false} />
        </div>
      </Box>
      <Box width="100%" my={1} className="text-xs break-inside-avoid">
        {hasAutopay && (
          <Box component="p" mb={1} textAlign="right">
            Your automatic payment will process on the invoice due date
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <p className="font-medium">
            Contact your hauler to create an online account to make payments.
            <br />
            Additional charge may be incurred if payment is not received by due date.
            <br />
            Please include your account number
            <span className="font-bold">{` ${accountNumber} `}</span>
            <span>& invoice number</span>
            <span className="font-bold">{` ${invoiceNumber} `}</span>
            <span>when sending a check payment.</span>
          </p>
          <Box display="flex" justifyContent="flex-end">
            <Box
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
              py={1}
              px={2}
              borderRadius={2}
              border="2px solid"
              borderColor="background.black"
            >
              {hasAutopay ? (
                <Box display="flex" alignItems="center">
                  <Box mr={0.5} position="relative" bgcolor={green[500]} className="circle" width={14} height={14} borderRadius="50%">
                    <Box
                      position="absolute"
                      left="20%"
                      top="50%"
                      height={9}
                      width={5}
                      borderBottom="2px solid"
                      borderRight="2px solid"
                      borderColor="background.paper"
                      sx={{
                        transform: 'rotate(45deg) translate(-50%, -50%)',
                      }}
                    />
                  </Box>
                  <Box color={green[500]} className="font-bold">
                    Autopay
                  </Box>
                </Box>
              ) : (
                <Box textTransform="uppercase" color="text.secondary">
                  Pay now
                </Box>
              )}
              <div className="text-xl font-medium">
                <HHDisplayMoney value={get(invoice, 'arSummary.payNowCents', 0)} />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

TotalInfo.propTypes = {
  invoice: PropTypes.object,
}

export default TotalInfo
