import React from 'react'
import Box from '@mui/material/Box'
import { ReactComponent as TableSortIconUp } from 'assets/TableSortIconUp.svg'
import { ReactComponent as TableSortIconDown } from 'assets/TableSortIconDown.svg'
import PropTypes from 'prop-types'
import { PRIMARY, TEXT_SECONDARY } from 'theme/colors'

const FILLED = PRIMARY.main

const HHSortKeys = ({ isAscendingOrderActive = false, isDescendingOrderActive = false, columnName = '', handleChange }) => {
  return (
    <Box sx={{ display: 'inline-flex', ml: 1, flexDirection: 'column' }}>
      <TableSortIconUp
        style={{
          cursor: 'pointer',
          color: isAscendingOrderActive ? FILLED : TEXT_SECONDARY.main,
        }}
        onClick={() => handleChange(columnName, 'ASC')}
      />
      <TableSortIconDown
        style={{
          cursor: 'pointer',
          marginTop: '4px',
          color: isDescendingOrderActive ? FILLED : TEXT_SECONDARY.main,
        }}
        onClick={() => handleChange(columnName, 'DESC')}
      />
    </Box>
  )
}
HHSortKeys.propTypes = {
  isAscendingOrderActive: PropTypes.bool,
  isDescendingOrderActive: PropTypes.bool,
  columnName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}
export default HHSortKeys
