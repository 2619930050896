import React, { useReducer, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import FooterButton from 'components/buttons/FooterButton'
import Loader from 'components/common/loader'

import { useLazyGetPostCardDetailsQuery } from 'api/settings/getPostCardDetails'
import { useAddPostCardDetailsMutation } from 'api/settings/addPostCardDetails'

import T from 'T'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import PostCardLayout from './postcard-setup/PostCardLayout'
import PostCardPreview from './postcard-setup/PostCardPreview'

const PostcardSetupSettings = () => {
  const [getPostCardDetails, { isLoading, isFetching }] = useLazyGetPostCardDetailsQuery()
  const [addPostCardDetails, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddPostCardDetailsMutation()

  const [postState, setPostState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    postalPermit: false,
    note: '',
    postcardLayout: 2,
    footerFlag: false,
    zipCode: '',
    permitNumber: '',
    id: '',
  })

  const { postalPermit, note, postcardLayout, footerFlag, zipCode, permitNumber, id } = postState

  const fetchPostalCardInfo = () => {
    getPostCardDetails({})
      .unwrap()
      .then(res => {
        setPostState({
          note: get(res, 'note', ''),
          postcardLayout: get(res, 'postcardLayout', 2),
          postalPermit: get(res, 'postalPermit', false),
          zipCode: get(res, 'zipCode', ''),
          permitNumber: get(res, 'permitNumber', ''),
          id: get(res, 'id', ''),
          footerFlag: false,
        })
      })
      .catch(handleError)
  }

  useEffect(() => {
    fetchPostalCardInfo()
  }, [])

  const handleCancel = () => {
    setPostState({
      postalPermit: false,
      note: '',
      postcardLayout: 2,
      footerFlag: false,
      zipCode: '',
      permitNumber: '',
    })

    fetchPostalCardInfo()
  }

  const handleAdd = () => {
    const payload = {
      postalPermit,
      note,
      postcardLayout,
      footerFlag,
      zipCode,
      permitNumber,
      id,
    }

    addPostCardDetails(payload)
      .unwrap()
      .then(() => {
        fetchPostalCardInfo()
      })
      .catch(handleError)
  }

  return (
    <Box>
      <Box m="0 24px">
        {(isLoading || isFetching || isAddLoading || isAddFetching) && <Loader />}
        <Typography variant="h3">{T.POSTCARD_SETUP}</Typography>

        <Box
          display="flex"
          maxHeight="calc(100vh - 184px)"
          mt={3}
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: 0,
              background: 'transparent',
            },
          }}
        >
          <PostCardLayout postState={postState} setPostState={setPostState} />
          <PostCardPreview postState={postState} setPostState={setPostState} />
        </Box>
      </Box>

      {footerFlag && (
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'border.light',
            boxShadow: ' 0 1px 3px rgba(35,38,59,.15)',
            display: 'flex',
            justifyContent: 'space-between',
            paddingX: 3,
            paddingY: 1,
          }}
        >
          <FooterButton leftButtonTitle={T.CANCEL} onClose={() => handleCancel()} rightButtonTitle={T.SAVE} onProceed={handleAdd} />
        </Box>
      )}
    </Box>
  )
}

export default PostcardSetupSettings
