import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as NotStarted } from 'assets/route_manager/route_list_status/NotStarted.svg'
import { ReactComponent as InProgress } from 'assets/route_manager/route_list_status/InProgress.svg'
import { ReactComponent as Complete } from 'assets/route_manager/route_list_status/Complete.svg'
import { ReactComponent as Incomplete } from 'assets/route_manager/route_list_status/Incomplete.svg'
import { Chip } from '@mui/material'
import { blue, grey, lightGreen, amber } from '@mui/material/colors'

const statusToColor = {
  'Not Started': grey,
  'In Progress': blue,
  Complete: lightGreen,
  Incomplete: amber,
}

const RouteStatus = ({ status = 'Not Started', sx }) => {
  const color = status in statusToColor ? statusToColor[status] : grey
  return (
    <Chip
      sx={{
        ...sx,
        bgcolor: color[50],
        borderWidth: 3,
        padding: 0.5,
        paddingRight: 0,
        borderRadius: 1,
        borderColor: color[500],
        '.MuiChip-label': {
          px: 1,
        },
      }}
      variant="outlined"
      avatar={
        <>
          {status === 'Not Started' && <NotStarted />}
          {status === 'In Progress' && <InProgress />}
          {status === 'Complete' && <Complete />}
          {status === 'Incomplete' && <Incomplete />}
        </>
      }
      label={status}
    />
  )
}

RouteStatus.propTypes = {
  sx: PropTypes.object,
  status: PropTypes.oneOf(['Not Started', 'In Progress', 'Complete', 'Incomplete']),
}

export default RouteStatus
