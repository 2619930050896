import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { get } from 'lodash'
import { WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { grey } from '@mui/material/colors'
import { isValidHex } from 'utils/validations'
import useTheme from '@mui/material/styles/useTheme'
import { INPUT_WIDTH } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/settings'

const StopPositionLabel = ({ position, workOrderStatus }) => {
  const theme = useTheme()
  const bgcolor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`, grey[100])
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const color = theme.palette.getContrastText(formattedBgcolor)

  return (
    <Box
      sx={{
        width: INPUT_WIDTH,
        mr: theme.spacing(1),
        textAlign: 'center',
        height: 32,
        border: '1.5px solid',
        borderColor: 'divider',
        borderRadius: 1,
        backgroundColor: formattedBgcolor,
        color,
        padding: 0.25,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography sx={{ color: 'inherit' }}>{position}</Typography>
    </Box>
  )
}

StopPositionLabel.propTypes = {
  position: PropTypes.number,
  workOrderStatus: PropTypes.string,
}

export default memo(StopPositionLabel)
