import React, { useEffect, useMemo } from 'react'
import { get } from 'lodash'
import VacationHoldChip from 'components/common/chip/VacationHoldChip'
import { useSelector } from 'react-redux'
import { useLazyGetVacationHoldListQuery } from 'api/accounts/vacationHoldsCrud'
import { handleError } from 'utils/error'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'
import { isAfter, isBefore, startOfDay } from 'date-fns'
import { Skeleton } from '@mui/material'

const isVacationHoldActiveWithinRange = (date, startDate, endDate) => {
  const startDateObj = createDateFromString(startDate)
  const endDateObj = endDate ? createDateFromString(endDate) : null
  if (endDateObj) {
    return isAfter(date, startDateObj) && isBefore(date, endDateObj)
  }
  return isAfter(date, startDateObj)
}

const VacationHoldChipAccountHeader = () => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const accountId = get(customerDetails, 'id')
  const [getVacationHoldList, { data, isLoading, isFetching, isSuccess }] = useLazyGetVacationHoldListQuery()
  const loading = useMemo(() => isLoading || isFetching, [isFetching, isLoading])
  const vacationHolds = useMemo(() => get(data, 'vacationHolds', []), [data])
  const activeVacationHolds = useMemo(() => {
    return vacationHolds
      .filter(({ active }) => active)
      .filter(({ startDate, endDate }) => {
        return isVacationHoldActiveWithinRange(startOfDay(new Date()), startDate, endDate)
      })
      .map(({ startDate, endDate, ...rest }) => {
        const formattedStartDate = startDate ? formatDateToFEFormatDateFns(createDateFromString(startDate)) : null
        const formattedEndDate = endDate ? formatDateToFEFormatDateFns(createDateFromString(endDate)) : null
        const dateRange = [formattedStartDate, formattedEndDate].join('-')
        return {
          ...rest,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          dateRange,
        }
      })
  }, [vacationHolds])
  const activeVacationHoldsCount = useMemo(() => get(activeVacationHolds, 'length'), [activeVacationHolds])
  const hasActiveVacationHolds = useMemo(() => activeVacationHoldsCount > 0, [activeVacationHoldsCount])
  const hasManyActiveVacationHolds = useMemo(() => activeVacationHoldsCount > 1, [activeVacationHoldsCount])
  const activeVacationHoldDateRange = useMemo(() => get(activeVacationHolds, '0.dateRange'), [activeVacationHolds])

  useEffect(() => {
    if (!accountId) return
    getVacationHoldList({ accountId, deleted: false }).unwrap().catch(handleError)
  }, [accountId])

  return (
    <>
      {loading && (
        <Skeleton>
          <VacationHoldChip label="" />
        </Skeleton>
      )}
      {isSuccess && hasActiveVacationHolds && (
        <VacationHoldChip label={hasManyActiveVacationHolds ? `${activeVacationHoldsCount} vacation holds` : activeVacationHoldDateRange} />
      )}
    </>
  )
}

VacationHoldChipAccountHeader.propTypes = {}

export default VacationHoldChipAccountHeader
