import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import { get, noop } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Photo } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import useTheme from '@mui/material/styles/useTheme'
import { useUpdateExceptionNoteMutation } from 'api/work-order/updateExceptionNote'
import api from 'api'
import { CACHE_TAG_WORK_ORDER_DETAILS } from 'api/cacheTagTypes'
import CancelButton from 'components/buttons/CancelButton'
import ExceptionImage from 'components/work-order/details/exceptions/ExceptionImage'
import ExceptionCardMediaSwiperContainer from 'components/work-order/details/exceptions/ExceptionCardMediaSwiperContainer'
import { HHFormTextField } from 'components/form-fields/v5'

const EditExceptionModal = ({
  open = false,
  onClose = noop,
  accountId,
  workOrderId,
  exception,
  date,
  time,
  signatures,
  images,
  note,
  description,
}) => {
  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      note,
    },
  })
  const theme = useTheme()
  const dispatch = useDispatch()
  const id = get(exception, 'id')
  const [updateNote, { isLoading }] = useUpdateExceptionNoteMutation()

  const afterSubmit = () => {
    onClose()
    reset({
      note: '',
    })
    toast.success('Exception note updated successfully')
    dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS]))
  }

  const onSubmit = async data => {
    const { note } = data
    if (!isDirty) return afterSubmit()
    onClose()
    return updateNote({
      id,
      note,
    })
      .unwrap()
      .then(() => afterSubmit())
  }

  useEffect(() => {
    reset({ note })
  }, [note, open])

  return (
    <Dialog sx={{ zIndex: theme.zIndex.tooltip }} maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle sx={{ ...theme.typography.h5 }}>Edit note</DialogTitle>
      <DialogContent>
        <ExceptionCardMediaSwiperContainer>
          {Array.isArray(images) && images.length === 0 && (
            <Box
              sx={{
                height: '194px',
                [theme.breakpoints.down('md')]: {
                  image: '194px',
                },
                [theme.breakpoints.up('md')]: {
                  height: '280px',
                },
                [theme.breakpoints.up('lg')]: {
                  height: '480px',
                },
              }}
              bgcolor={grey[200]}
              height="199px"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Photo sx={{ fontSize: 40, color: grey[600] }} />
            </Box>
          )}
          {Array.isArray(images) &&
            images.length > 0 &&
            images.map(image => {
              return (
                <ExceptionImage
                  imageSx={{
                    height: '194px',
                    [theme.breakpoints.down('md')]: {
                      image: '194px',
                    },
                    [theme.breakpoints.up('md')]: {
                      height: '280px',
                    },
                    [theme.breakpoints.up('lg')]: {
                      height: '480px',
                    },
                  }}
                  key={image?.name}
                  exception={exception}
                  photo={image}
                  signature={get(signatures, `index`)}
                  accountId={accountId}
                  workOrderId={workOrderId}
                  withSwiperSlide
                  size="medium"
                />
              )
            })}
        </ExceptionCardMediaSwiperContainer>
        <Grid container rowSpacing={1}>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {date}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {time}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Description
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" noWrap style={{ cursor: 'pointer', textOverflow: 'ellipsis' }}>
                {description || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <HHFormTextField minRows={4} multiline label="Note" fullWidth deprecatedLabel={false} control={control} name="note" />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <CancelButton onClick={onClose} />
        <LoadingButton onClick={handleSubmit(onSubmit)} loading={isLoading} size="small" variant="contained">
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

EditExceptionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  exception: PropTypes.object.isRequired,
  signatures: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  workOrderId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
}

export default EditExceptionModal
