export const actions = {
  GET: {
    CALL_BY_ID: 'CUSTOMERS_GET_CALL_BY_ID',
  },
}

export const getCustomerCallById = (body, onSuccess) => ({
  type: actions.GET.CALL_BY_ID,
  payload: { body, onSuccess },
})
