import React, { Children } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'

import { AccordionDetails, Box, Divider, Grid, Typography, Tab, Tabs } from '@mui/material'
import { grey } from '@mui/material/colors'

import { get } from 'utils/lodash'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { PAYMENT_STATUS } from 'settings/constants/payment'

import T from 'T'
import InvoiceActivity from 'components/customer-details/content/common/InvoiceActivity'
import HHSectionPlaceholder from '../../common/HHSectionPlaceholder'
import TabPanel from '../../common/TabPanel'
import EmailActivity from '../../customer-details/content/common/EmailActivity'
import { PAYMENT_ACTIVITY_TABS_ID } from '../../customer-details/content/payments/settings'
import ActivityHeader from '../../customer-details/content/common/ActivityHeader'

const { APPROVED } = PAYMENT_STATUS
const { PAID } = INVOICE_STATUS
const { INVOICE_TAB_ID, EMAIL_TAB_ID } = PAYMENT_ACTIVITY_TABS_ID

const PaymentActivityFeed = ({
  invoices = [],
  accountCredit = {},
  transactionStatus = '',
  billingProfileDetails = {},
  emailEvents = [],
  selectedTab,
  onSelectedTabChange,
  Component = AccordionDetails,
}) => {
  const isApproved = transactionStatus === APPROVED

  const allInvoices = isEmpty(accountCredit)
    ? invoices
    : [
        ...invoices,
        {
          isAccountCredit: true,
          status: isApproved ? PAID : 'Debit',
          totalCents: get(accountCredit, 'amountCents', 0),
          paymentAppliedAmountCents: get(accountCredit, 'amountCents', 0),
          title: capitalize(isApproved ? T.ACCOUNT_CREDIT : T.ACCOUNT_DEBIT),
        },
      ]

  return (
    <Component
      sx={{
        px: 2,
        pt: 1,
        pb: 0,
        backgroundColor: grey[50],
        borderBottom: '1px solid',
        borderTop: '1px solid',
        borderColor: 'border.light',
      }}
    >
      <Box width="100%" pl={4.5}>
        <Typography variant="h6" fontWeight={500} my={1.5}>
          {T.ACTIVITY}
        </Typography>

        <Tabs value={selectedTab} onChange={onSelectedTabChange} sx={{ mb: 2, button: { textTransform: 'capitalize' } }}>
          <Tab value={INVOICE_TAB_ID} label={T.INVOICE} />
          <Tab value={EMAIL_TAB_ID} label={T.EMAIL} />
        </Tabs>

        <TabPanel index={INVOICE_TAB_ID} value={selectedTab}>
          {Array.isArray(allInvoices) && allInvoices.length > 0 ? (
            <>
              <ActivityHeader />
              <Divider sx={{ width: '100%' }} />
              <Grid container>
                {Children.toArray(
                  allInvoices.map((invoice, index) => (
                    <>
                      <InvoiceActivity
                        billingProfileDetails={billingProfileDetails}
                        invoice={invoice}
                        showAppliedColumn
                        align="inherit"
                        marginY={2}
                      />
                      {allInvoices.length - 1 !== index && <Divider sx={{ width: '100%' }} />}
                    </>
                  ))
                )}
              </Grid>
            </>
          ) : (
            <Box width="100%">
              <HHSectionPlaceholder title={T.NO_INVOICE_ACTIVITY_FOUND} />
            </Box>
          )}
        </TabPanel>
        <TabPanel index={EMAIL_TAB_ID} value={selectedTab}>
          <Grid rowGap={2} container mb={2}>
            {Array.isArray(emailEvents) && emailEvents.length > 0 ? (
              Children.toArray(
                emailEvents.map((paymentEmailEvent, index) => (
                  <>
                    <EmailActivity email={paymentEmailEvent} />
                    {emailEvents.length - 1 !== index && <Divider sx={{ width: '100%' }} />}
                  </>
                ))
              )
            ) : (
              <Box width="100%">
                <HHSectionPlaceholder title={T.NO_EMAIL_ACTIVITY_FOUND} />
              </Box>
            )}
          </Grid>
        </TabPanel>
      </Box>
    </Component>
  )
}

PaymentActivityFeed.propTypes = {
  invoices: PropTypes.array,
  accountCredit: PropTypes.object,
  transactionStatus: PropTypes.string,
  billingProfileDetails: PropTypes.object,
  emailEvents: PropTypes.array,
  selectedTab: PropTypes.string.isRequired,
  onSelectedTabChange: PropTypes.func.isRequired,
  Component: PropTypes.element,
}

export default PaymentActivityFeed
