import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemText, Collapse, List, IconButton, Typography, useMediaQuery } from '@mui/material'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { isCustomerDetailsPage } from 'router/routes'
import { combineAddressInfo } from 'utils/helper'
import { CACHE_TAG_ACCOUNT_DETAILS_BY_ID } from 'api/cacheTagTypes'

import api from 'api'
import LocationAvatar from 'components/common/avatar/LocationAvatar'
import { useDispatch } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import LocationExpandView from 'components/customer-details/content/location/LocationExpandView'
import { noop } from 'lodash'

const LocationView = ({
  selectedTab,
  location = {},
  showServiceCount = false,
  accountId,
  action,
  onRowClick = noop,
  showLocationAvatar = true,
  sx,
  primaryTypographyProps = {},
  showContacts = true,
}) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const locationName = combineAddressInfo(get(location, 'address', {}))
  const serviceCount = get(location, 'serviceCount', 0)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xl'))
  const orientation = isMobile ? 'vertical' : 'horizontal'
  const isTabletOrMobile = useMediaQuery(theme => theme.breakpoints.down('lg'))
  const dynamicWidth = isTabletOrMobile && orientation === 'horizontal'
  const handleClick = () => setOpen(!open)

  useEffect(() => {
    setOpen(false)
  }, [location])

  const onSaveTags = () => {
    if (isCustomerDetailsPage()) {
      dispatch(getCustomerDetails({ accountId }))
    }
    dispatch(api.util.invalidateTags([{ type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId }]))
  }

  return (
    <>
      <ListItem sx={sx} onClick={onRowClick}>
        <IconButton
          size="small"
          onClick={event => {
            event.stopPropagation()
            handleClick()
          }}
        >
          {open ? <ExpandMore /> : <ChevronRight />}
        </IconButton>

        <ListItemText
          sx={{ display: 'grid' }}
          primary={locationName}
          primaryTypographyProps={primaryTypographyProps}
          secondary={
            showServiceCount && (
              <Typography color="textSecondary" noWrap>
                {`${serviceCount} active service${serviceCount > 1 ? 's' : ''}`}
              </Typography>
            )
          }
        />

        {showLocationAvatar && <LocationAvatar isActive={false} />}
        {action}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem disablePadding sx={{ pt: 0, pl: 6, pr: 2.5, pb: 1 }}>
            <LocationExpandView
              caller="list"
              accountId={accountId}
              selectedLocation={location}
              orientation={orientation}
              dynamicWidth={dynamicWidth}
              onSaveTags={onSaveTags}
              showContacts={showContacts}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}

LocationView.propTypes = {
  selectedTab: PropTypes.string,
  location: PropTypes.object,
  showServiceCount: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  action: PropTypes.node,
  onRowClick: PropTypes.func,
  showLocationAvatar: PropTypes.bool,
  sx: PropTypes.object,
  primaryTypographyProps: PropTypes.object,
  showContacts: PropTypes.bool,
}

export default LocationView
