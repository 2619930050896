import React from 'react'
import { formLabelClasses } from '@mui/material'
import { GridCellCheckboxRenderer, selectedIdsLookupSelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { INVOICE_STATUS } from '../../../../settings/constants/invoice'

const { NOT_READY_FOR_INVOICE } = INVOICE_STATUS

const getCheckboxDataForRouteRow = (selectionLookup, childrenNotReadyIds) => {
  let indeterminate = false
  let checked = false
  let foundUndefined = false
  let foundDefined = false
  for (const child of childrenNotReadyIds) {
    if (selectionLookup[child] === undefined) {
      foundUndefined = true
    } else {
      foundDefined = true
    }
    if (foundUndefined && foundDefined) {
      indeterminate = true
      checked = false
      break
    }
  }
  if (foundDefined && !foundUndefined) {
    checked = true
  }
  return { indeterminate, checked }
}

const RenderCheckbox = params => {
  const { row, api } = params
  const { isWO } = row
  const selectionLookup = selectedIdsLookupSelector(api.state, api.instanceId)
  const apiRef = useGridApiContext()
  let indeterminate
  let checked = formLabelClasses
  const extraData = {}
  const isParentRow = row?.isRoute
  const hiddenSx = isWO && row.invoiceStatus !== NOT_READY_FOR_INVOICE
  if (isParentRow) {
    const hasSelectableChildren = row?.childrenNotReadyIds && row.childrenNotReadyIds.length !== 0
    if (hasSelectableChildren) {
      const { indeterminate: parentIndeterminate, checked: parentChecked } = getCheckboxDataForRouteRow(
        selectionLookup,
        row.childrenNotReadyIds
      )
      indeterminate = parentIndeterminate
      checked = parentChecked
      if (indeterminate) {
        extraData.indeterminate = indeterminate
      }
      if (checked) {
        extraData.checked = checked
      }
    }

    extraData.onClick = e => {
      e.preventDefault()
      if (hasSelectableChildren) {
        if (indeterminate || checked) {
          api.selectRows([...row.childrenNotReadyIds, row.id], false)
        } else {
          api.selectRows([...row.childrenNotReadyIds, row.id], indeterminate || !checked)
        }
      }
    }
  } else {
    extraData.onClick = e => {
      const isChecked = selectionLookup[row.id]
      if (isChecked) {
        e.preventDefault()
        apiRef.current.selectRows([row.id, row.routeId], false)
      } else {
        const parentRow = apiRef.current.getRow(row.routeId)
        const { checked: parentChecked } = getCheckboxDataForRouteRow(selectionLookup, parentRow.childrenNotReadyIds)
        apiRef.current.selectRows([row.routeId], parentChecked)
      }
    }
  }

  const isPlaceholder = row.id.includes('placeholder')
  if (isPlaceholder) return <div />
  return (
    <GridCellCheckboxRenderer
      {...params}
      {...extraData}
      sx={{
        ...(hiddenSx && { visibility: 'hidden' }),
        '& .MuiButtonBase-root': {
          padding: 0.5,
        },
      }}
    />
  )
}

RenderCheckbox.propTypes = {}

export default RenderCheckbox
