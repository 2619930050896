export const actions = {
  PUT: {
    IS_LOADING: 'RESPONSE_PUT_IS_LOADING',
  },
}

export const putIsLoading = bool => ({
  type: actions.PUT.IS_LOADING,
  payload: bool,
})
