import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'

const TagsWrapper = styled(Box)(({ theme }) => ({
  '& fieldset': {
    border: `1px solid ${theme.palette.background.tags}`,
    boxShadow: '0 1px 1px rgb(0 0 0 / 8%)',
  },
}))

export default TagsWrapper
