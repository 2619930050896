import React, { Children } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

import { Box, Typography, useTheme } from '@mui/material'

const DAYS = [
  { key: 'Sunday', value: 'Su' },
  { key: 'Monday', value: 'Mo' },
  { key: 'Tuesday', value: 'Tu' },
  { key: 'Wednesday', value: 'We' },
  { key: 'Thursday', value: 'Th' },
  { key: 'Friday', value: 'Fr' },
  { key: 'Saturday', value: 'Sa' },
]

const Schedule = ({ recurrenceInterval = '', serviceEvents = [], serviceDate }) => {
  const theme = useTheme()
  const isDailyService = recurrenceInterval === 'Day'
  const isWeeklyService = recurrenceInterval === 'Week'

  if (!isDailyService && !isWeeklyService) {
    return null
  }

  const serviceDayName = serviceDate ? format(serviceDate, 'EEEE') : ''
  const selectedDays = isDailyService ? DAYS.map(({ key }) => key) : serviceEvents.map(event => event?.dayOfTheWeek).filter(Boolean)

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {Children.toArray(
        DAYS.map(({ key, value }) => {
          const isSelected = selectedDays.includes(key)
          const isServiceDay = serviceDayName === key

          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
              borderRadius="50%"
              border={isSelected ? '2px solid' : '1px solid'}
              borderColor={isSelected ? theme.palette.action.active : 'divider'}
              {...(isServiceDay && { bgcolor: 'primary.main' })}
            >
              <Typography
                variant="subtitle2"
                {...(isServiceDay && { color: theme.palette.common.white })}
                {...(isSelected && { fontWeight: 600, color: isServiceDay ? theme.palette.common.white : 'textPrimary' })}
              >
                {value}
              </Typography>
            </Box>
          )
        })
      )}
    </Box>
  )
}

Schedule.propTypes = {
  recurrenceInterval: PropTypes.string,
  serviceEvents: PropTypes.array,
  serviceDate: PropTypes.object,
}

export default Schedule
