import React, { useEffect, useState, useRef, useCallback } from 'react'

import * as firebaseui from 'firebaseui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Alert, Box, Button, Card, Divider, Typography, useTheme } from '@mui/material'
import { auth, handleLogoutAuthApp } from 'providers/firebaseAuth'
import { useSearchParams, useNavigate } from 'react-router-dom-v5-compat'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { decodeToken } from 'utils/token'
import { setAllBusinesses, setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import { useLazyGetBusinessInfoByBusinessNameQuery } from 'api/business/getBusinessInfoByBusinessName'
import { useLazyGetAllBusinessesByEmailQuery } from 'api/business/getAllBusinessesByEmail'
import { useActivateUserMutation } from 'api/auth/activateUser'
import { useUpdateUserMutation } from 'api/auth/updateUser'
import { getFirstNameAndLastName } from 'utils/parseName'
import { selectHomePage } from 'slices/home/homeSlice'
import { getAllBusinesses, getFirstAccountDetails } from 'data/select-account/selectAccount'
import { isSSOProvider } from 'settings/constants/auth'

import T from 'T'
import Constants from 'Constants'
import routes from 'router/routes'
import Loader from 'components/common/loader'
import { signInOptions } from './settings'
import { useMutationObserver } from './useMutationObserver'
import CreatePassword from './CreatePassword'
import RecoverPassword from './RecoverPassword'
import 'firebaseui/dist/firebaseui.css'
import './style.scss'
import ContactDetails from './contact-details'

const { AUTH_TENANT_ID } = Constants
const { PUBLIC_ASSETS_BUCKET_NAME, STORAGE_PATH, SUPPORT_EMAIL, SUPPORT_WEBSITE, CAN_UPDATE_USER } = Constants

const BASE_PATH = `${STORAGE_PATH}${PUBLIC_ASSETS_BUCKET_NAME}/`
const DEFAULT_LOGO_PATH = 'haulerhero/business_logo.png'
const ACTIVATION_LINK_ERROR = 'activation_link_verification_error'

const Login = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [getBusinessInfoByBusinessName, { isFetching: isLoadingGetBusinessInfo, data: businessInfo, isSuccess: isGetBusinessInfoSuccess }] =
    useLazyGetBusinessInfoByBusinessNameQuery()
  const [getAllBusinessesByEmail, { isFetching: isLoadingGetAllBusinessesByEmail }] = useLazyGetAllBusinessesByEmailQuery()
  const [activateUser, { isLoading: isLoadingActivateUser }] = useActivateUserMutation()
  const [updateUser, { isLoading: isLoadingUpdateUser }] = useUpdateUserMutation()
  const [showRecoverPasswordBtn, setShowRecoverPasswordBtn] = useState(false)
  const [showCreatePasswordScreen, setShowCreatePasswordScreen] = useState(false)
  const [recoverPasswordScreen, setRecoverPasswordScreen] = useState({ isOpen: false, email: '' })
  const [hasAccountError, setHasAccountError] = useState(false)
  const [isAlreadyActivated, setIsAlreadyActivated] = useState(searchParams.get('error') === ACTIVATION_LINK_ERROR)
  const urlBusinessName = searchParams.get('business_name') || searchParams.get('bid')
  const businessId = get(businessInfo, 'id', '')
  const userId = searchParams.get('user_id')
  const uiRef = useRef(null)
  const mutationRef = useRef()
  const canRegister = urlBusinessName && userId

  const homePage = useSelector(selectHomePage, shallowEqual)

  const resetUI = () => {
    setShowRecoverPasswordBtn(false)
    setShowCreatePasswordScreen(false)
    setRecoverPasswordScreen({ isOpen: false, email: '' })
    uiRef.current.reset()
    initializeUi()
  }

  const goToHomePage = async email => {
    const result = await getAllBusinessesByEmail(email).unwrap().catch(handleError)
    const allBusinesses = getAllBusinesses(get(result, 'businesses', []))
    dispatch(setAllBusinesses(allBusinesses))
    const OnlyOneBusiness = allBusinesses.length === 1
    if (OnlyOneBusiness) {
      const { businessName: firstBusinessName, businessId: firstBusinessId } = getFirstAccountDetails({ allBusinesses })
      dispatch(setSelectedLoginDetails({ businessName: firstBusinessName, businessId: firstBusinessId, show: false }))
      navigate(homePage)
      return
    }

    if (canRegister) {
      dispatch(setSelectedLoginDetails({ businessName: urlBusinessName, businessId, show: false }))
      navigate(homePage)
      return
    }
    navigate(routes.app.selectAccount)
  }

  const handleAccountError = (logout = false) => {
    setHasAccountError(true)
    if (logout) handleLogoutAuthApp()
    resetUI()
  }

  const handleRegisterError = error => {
    handleError(error)
    handleLogoutAuthApp()
    resetUI()
  }

  const handleRegisterUser = authResult => {
    dispatch(setSelectedLoginDetails({ businessName: '', businessId: '', show: true }))
    const user = get(authResult, 'user', {})
    const displayName = get(user, 'displayName', '')
    const additionalUserInfo = get(authResult, 'additionalUserInfo', {})
    const providerId = get(additionalUserInfo, 'providerId', '')
    const { firstName, lastName } = getFirstNameAndLastName(displayName, providerId, get(additionalUserInfo, 'profile', {}))
    const userIdpId = get(user, 'uid', '')
    const email = get(user, 'email', '')

    if (canRegister) {
      // New user flow
      const payload = { providerId, userIdpId, businessId, userId, email, firstName, lastName }
      activateUser(payload)
        .unwrap()
        .then(() => {
          // After successful registration, refetch token to get new scope
          auth.currentUser
            .getIdToken(true)
            .then(() => goToHomePage(email))
            .catch(handleError)
        })
        .catch(error => {
          const errorMessage = get(error, 'data.message', '').toLowerCase()
          // Already registered in same tenant
          if (errorMessage.includes(`No pending user with email: ${email} found for businessId: ${businessId}`.toLowerCase())) {
            goToHomePage(email)
            return
          }

          handleAccountError(true)
        })
      return
    }

    // Existing user flow
    const accessToken = get(authResult, 'user.accessToken', '')
    const tokenInfo = decodeToken(accessToken)
    const tenants = get(tokenInfo, 'tenants', [])
    if (tenants.length === 0) {
      handleAccountError(true)
      return
    }

    if (isSSOProvider(providerId)) {
      updateUser({ email, businessId: get(tenants, '[0]'), payload: { idpId: providerId } })
        .unwrap()
        .then(() => goToHomePage(email))
        .catch(handleRegisterError)
      return
    }

    goToHomePage(email)
  }

  const initializeUi = () => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)
    auth.tenantId = AUTH_TENANT_ID
    ui.disableAutoSignIn()
    ui.start('#auth-container', {
      signInFlow: 'popup',
      signInSuccessUrl: routes.app.home,
      callbacks: {
        signInSuccessWithAuthResult: authResult => {
          handleRegisterUser(authResult)
          return false
        },
        signInFailure: error => console.log('Failure error --', error),
      },
      signInOptions,
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    })
    uiRef.current = ui
  }

  useEffect(() => {
    initializeUi()
  }, [])

  useEffect(() => {
    if (urlBusinessName) {
      getBusinessInfoByBusinessName(urlBusinessName).unwrap().catch(handleError)
    }
  }, [urlBusinessName])

  useEffect(() => {
    if (canRegister && isGetBusinessInfoSuccess) {
      resetUI()
    }
  }, [canRegister, isGetBusinessInfoSuccess])

  const handleCreatePassword = () => {
    setShowCreatePasswordScreen(true)
  }

  const handleRecoverPassword = () => {
    const emailEl = document.getElementById('ui-sign-in-email-input')
    setShowRecoverPasswordBtn(false)
    setRecoverPasswordScreen({ isOpen: true, email: get(emailEl, 'value', '') })
  }

  const mutationCallback = useCallback(() => {
    const emailEl = document.getElementById('ui-sign-in-email-input')
    if (emailEl) {
      setHasAccountError(false)
      setIsAlreadyActivated(false)

      if (canRegister) {
        handleCreatePassword()
      }
    }

    const firstAndLastNameEl = document.getElementById('ui-sign-in-name-input')
    if (firstAndLastNameEl) {
      handleAccountError()
      return
    }

    const trobleSignInEl = get(document.getElementsByClassName('firebaseui-form-links'), [0])
    if (trobleSignInEl) {
      setShowRecoverPasswordBtn(true)
      return
    }

    setShowRecoverPasswordBtn(false)
  }, [])

  useMutationObserver(mutationRef, mutationCallback)

  return (
    <>
      {(isLoadingGetBusinessInfo || isLoadingActivateUser || isLoadingUpdateUser || isLoadingGetAllBusinessesByEmail) && <Loader />}
      <Box bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
        <Card sx={{ m: 'auto', width: '352px', boxShadow: `0px 1px 8px 0px ${theme.palette.divider}` }}>
          {isAlreadyActivated && (
            <Alert severity="error" sx={{ mx: 3, my: 2, alignItems: 'center' }}>
              <Typography variant="h5" fontWeight={500}>
                Account already activated
              </Typography>
              <Typography variant="h6" fontWeight={400}>
                Please log in to proceed
              </Typography>
            </Alert>
          )}
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" px={3}>
            <Box my={2}>
              <img height={75} src={`${BASE_PATH}${DEFAULT_LOGO_PATH}`} alt="" />
            </Box>
            <Divider flexItem />
          </Box>
          <Box ref={mutationRef}>
            <Box id="auth-container" />

            {showCreatePasswordScreen && (
              <CreatePassword
                isOpen={showCreatePasswordScreen}
                auth={auth}
                businessId={businessId}
                userId={userId}
                onAccountError={handleAccountError}
                onRegisterUser={handleRegisterUser}
                onClose={resetUI}
              />
            )}

            {showRecoverPasswordBtn && (
              <Box position="relative">
                <Button
                  disabled={!CAN_UPDATE_USER}
                  onClick={handleRecoverPassword}
                  sx={{ position: 'absolute', ml: 2, zIndex: theme.zIndex.tooltip, bottom: 24 }}
                  color="primary"
                  variant="text"
                >
                  Trouble signing in?
                </Button>
              </Box>
            )}

            {recoverPasswordScreen.isOpen && (
              <RecoverPassword
                isOpen={recoverPasswordScreen.isOpen}
                businessId={businessId}
                existingEmail={recoverPasswordScreen.email}
                onClose={resetUI}
              />
            )}
          </Box>
          {hasAccountError && (
            <Alert severity="error" sx={{ mx: 3, mb: 2, alignItems: 'center' }}>
              <Typography variant="h6" fontWeight={400}>
                {T.ACCOUNT_DOESNT_EXIST}
              </Typography>
            </Alert>
          )}
        </Card>
        <ContactDetails email={SUPPORT_EMAIL} website={SUPPORT_WEBSITE} />
      </Box>
    </>
  )
}

export default Login
