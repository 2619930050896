import React from 'react'
import PricingDrawer from 'components/pricing/common/drawer/PricingDrawer'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import NavbarWrapper from 'components/common/NavbarWrapper'
import { useSelector } from 'react-redux'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import Pricing from 'containers/pricing'
import { PRICING_TABS_ID } from 'settings/constants/pricing'

const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID

const ServiceGroupsPage = () => {
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  return (
    <NavbarWrapper
      sx={{ backgroundColor: 'background.paper' }}
      innerSx={{
        overflow: 'hidden',
      }}
    >
      <PricingDrawer />
      <CustomerLayout overflow="hidden" open={isOpenPricingDrawer}>
        <Pricing defaultTabValue={SERVICE_GROUPS_TAB_ID} />
      </CustomerLayout>
    </NavbarWrapper>
  )
}

ServiceGroupsPage.propTypes = {}

export default ServiceGroupsPage
