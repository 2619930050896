import api from 'api'
import { CACHE_TAG_TAGS_METADATA } from 'api/cacheTagTypes'

export const extendApiSlice = api.injectEndpoints({
  endpoints: build => ({
    getTagsMetadata: build.query({
      query: (body = {}) => ({
        url: '/api/meta/filters?filterTypes=Tag',
        method: 'POST',
        body,
      }),

      // disable caching for filters endpoint in order to get the latest version of available tags without caching
      keepUnusedDataFor: 30,
      transformResponse: (response, meta, arg) => {
        const { tags } = response

        return {
          totalCount: tags?.length || 0,
          tags: tags || [],
        }
      },
      providesTags: [CACHE_TAG_TAGS_METADATA],
    }),
  }),
})

export const { useGetTagsMetadataQuery } = extendApiSlice

// export memoized Selector for this mutation id on the api [apiReducerPath].mutations[mutationId] path
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-users-data
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
export const getTagsMetadataSlice = extendApiSlice.endpoints.getTagsMetadata.select()
