import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector } from 'react-redux'
import { Box, ListItem, ListItemText, IconButton, Link, Button, Tooltip, useTheme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import { ReactComponent as Swap } from 'assets/services/actions/Swap.svg'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Adjustments } from '@styled-icons/heroicons-outline/Adjustments'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { ReactComponent as CommercialIcon } from 'assets/business-line/Commercial.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import { ReactComponent as RecycleIcon } from 'assets/RecycleIcon.svg'
import CloseIcon from '@mui/icons-material/Close'

import { get } from 'utils/lodash'
import { isString } from 'utils/string'
import { deserializeDate } from 'utils/date'
import { getMeasureUnit } from 'utils/get-measure-unit'
import { getWorkOrderDetailsPageUrl } from 'router/routes'
import { RECURRENCE, WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS } from 'api/cacheTagTypes'
import { selectRouteSerializedServiceDate, setStopDetailsDialogState } from 'slices/route/routeSlice'
import { setRoutingServiceDate } from 'slices/customer/customerDetailsSlice'
import { setStopToBeAssigned, setIsAssignStopDialogOpen } from 'slices/route/routingActionsSlice'

import T from 'T'
import api from 'api'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import ServiceTypeChip from 'components/services/ServiceTypeChip'
import BusinessLineChip from 'components/business-line/BusinessLineChip'
import WorkOrderStatusChip from 'components/work-order/WorkOrderStatusChip'
import ServiceActionTypeChip from 'components/services/ServiceActionTypeChip'
import ServiceMaterialTypeChip from 'components/services/ServiceMaterialTypeChip'
import StopListItem from './StopListItem'
import Schedule from './Schedule'
import MeasureChip from '../RouteCard/MeasureChip'
import { canMoveStopToAnotherRoute } from '../helper'
import StopDetailsActions from './StopDetailsActions'

const { RECURRING } = RECURRENCE

const StopDetails = ({ stop = {}, service = {}, showSchedule = false, showFullInfo = false, showCloseBtn = false, onClose = noop }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const {
    workOrderId = '',
    accountName = '',
    addressName = '',
    routeId = '',
    routeName = '',
    stopIndex = 1,
    totalStops = 1,
    workOrderStatus = '',
    workOrderNumber = '',
    tags = {},
    pricedServiceType = '',
    serviceName = '',
    line1 = '',
    unitNumber = '',
    city = '',
    state = '',
    zipCode = '',
    address: addressStr = '',
    latitude = '',
    longitude = '',
  } = stop || {}
  const stateAndZipCode = `${state} ${zipCode}`.trim()
  const allTags = [get(tags, 'forAccount', []), get(tags, 'forLocation', []), get(tags, 'forService', [])].flat()
  const serviceDetails = get(service, 'priceServiceDetail', {})
  const businessLineName = get(serviceDetails, 'businessLine')
  const businessLineIcon = get(serviceDetails, 'businessLineIcon', '')
  const businessLineType = isString(businessLineIcon) ? get(businessLineIcon.split('.'), [0]) : ''
  const action = get(serviceDetails, 'action.actionType')
  const measureUnit = get(serviceDetails, 'measure.unit')
  const measureVolume = get(serviceDetails, 'measure.volume', 0)
  const measureLabel = `${measureVolume} ${getMeasureUnit(measureUnit)}`
  const materialType = get(serviceDetails, 'material.materialType')
  const materialColor = get(serviceDetails, 'material.color')
  const recurrenceInterval = get(serviceDetails, 'recurrenceInterval')
  const canMoveStop = canMoveStopToAnotherRoute(workOrderStatus)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()

  const onTagsEditSave = (workOrder, onTagsSaveCallback = () => {}) => {
    onTagsSaveCallback()
    const isUnassignedWorkOrder = isEmpty(workOrder?.routeId)
    if (isUnassignedWorkOrder) {
      dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS]))
    } else {
      dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES]))
    }
    dispatch(setStopDetailsDialogState({ refetchStop: true }))
  }

  const handleAddUpdateStopRoute = () => {
    dispatch(setRoutingServiceDate(serviceDate))
    dispatch(
      setStopToBeAssigned({
        routeId,
        isUnassigned: !!routeId,
        addressStr,
        id: workOrderId,
        workOrderId,
        latitude,
        longitude,
        accountName,
        toBeInserted: true,
        sequence: routeId ? stopIndex : '',
      })
    )
    setTimeout(() => dispatch(setIsAssignStopDialogOpen(true)), 100)
    setTimeout(onClose, 200)
  }

  return (
    <>
      <Box borderBottom="1px solid" borderColor="divider">
        <ListItem
          sx={{ pl: 3, py: 1.5 }}
          secondaryAction={
            showCloseBtn ? (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null
          }
        >
          <ListItemText
            primaryTypographyProps={{ variant: 'h4', fontWeight: 600, sx: { wordWrap: 'break-word' } }}
            primary={addressName || accountName}
          />
        </ListItem>
      </Box>

      {showFullInfo && (
        <Box mt={1} pl={3} pr={2} display="flex" alignItems="center" justifyContent="end">
          {routeId ? (
            <Tooltip title={T.DISABLE_CHANGE_ROUTE_SEQUENCE_MSG} disableHoverListener={canMoveStop}>
              <span>
                <Button disabled={!canMoveStop} size="small" variant="outlined" onClick={handleAddUpdateStopRoute}>
                  {T.CHANGE_ROUTE_AND_SEQUENCE}
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button size="small" variant="outlined" onClick={handleAddUpdateStopRoute}>
              {T.ASSIGN}
            </Button>
          )}
          <StopDetailsActions stop={stop} />
        </Box>
      )}

      <Box my={1}>
        <StopListItem icon={<LocationOnIcon />} primary="Street" secondary={line1} />
        {unitNumber && <StopListItem icon={<LocationOnIcon />} primary="Street line 2" secondary={unitNumber} />}
        <StopListItem icon={<LocationOnIcon />} primary="City, State Zip" secondary={[city, stateAndZipCode].filter(Boolean).join(', ')} />
        <StopListItem
          icon={<Swap />}
          primary="Route"
          secondary={<Typography color={routeId ? 'textPrimary' : 'textSecondary'}>{routeName}</Typography>}
        />
        <StopListItem
          icon={<FormatListNumberedIcon />}
          primary="Sequence"
          secondary={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={0.5}
              px={routeId ? 1 : 1.5}
              border="1.5px solid"
              borderColor={routeId ? 'divider' : 'warning.main'}
              borderRadius={0.5}
              bgcolor={routeId ? get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`, grey[50]) : theme.palette.background.paper}
            >
              <Typography color={routeId ? 'textPrimary' : 'textSecondary'}>{routeId ? `${stopIndex}/${totalStops}` : 'U'}</Typography>
            </Box>
          }
        />

        <StopListItem icon={<SettingsOutlinedIcon />} primary="Service name" secondary={serviceName} />
        <StopListItem icon={<Adjustments />} primary="Service type" secondary={<ServiceTypeChip serviceType={pricedServiceType} />} />
        {showSchedule && ['Day', 'Week'].includes(recurrenceInterval) && (
          <StopListItem
            icon={<Calendar />}
            primary="Schedule"
            secondary={
              <Schedule
                recurrenceInterval={recurrenceInterval}
                serviceEvents={get(service, 'schedule.serviceEvents', [])}
                serviceDate={serviceDate}
              />
            }
          />
        )}

        <StopListItem
          icon={<LocalOfferOutlinedIcon />}
          primary="Tags"
          secondary={
            showFullInfo || allTags.length > 0 ? (
              <InteractiveTagsList data={{ ...stop, tagDetails: { ...tags } }} onSave={onTagsEditSave} />
            ) : null
          }
        />
        <StopListItem
          icon={<AssignmentOutlinedIcon />}
          primary="Work order"
          secondary={
            showFullInfo ? (
              <Link
                component={RouterLink}
                to={getWorkOrderDetailsPageUrl(workOrderId)}
                rel="noopener noreferrer"
                target="_blank"
                underline="hover"
              >
                {workOrderNumber}
              </Link>
            ) : (
              workOrderNumber
            )
          }
        />

        {showFullInfo && (
          <>
            <StopListItem
              icon={<CommercialIcon />}
              primary="Business line"
              secondary={<BusinessLineChip businessLineType={businessLineType} businessLineName={businessLineName} />}
            />
            <StopListItem
              icon={<CheckCircleIcon />}
              primary="Service status"
              secondary={<WorkOrderStatusChip status={workOrderStatus} />}
            />
            <StopListItem
              icon={<Swap />}
              primary="Action"
              secondary={
                <>
                  {action && <ServiceActionTypeChip size="small" action={action} />}
                  {!action && pricedServiceType === RECURRING && (
                    <ServiceActionTypeChip size="small" action="Collection" label={T.RECURRING_COLLECTION} />
                  )}
                </>
              }
            />
            <StopListItem
              icon={<SquareFootIcon />}
              primary="Measure"
              secondary={measureUnit ? <MeasureChip label={measureLabel} measureUnit={measureUnit} /> : null}
            />

            <StopListItem
              icon={<RecycleIcon />}
              primary="Material"
              secondary={<ServiceMaterialTypeChip materialColor={materialColor} label={materialType} />}
            />
          </>
        )}
      </Box>
    </>
  )
}

StopDetails.propTypes = {
  stop: PropTypes.object,
  service: PropTypes.object,
  showSchedule: PropTypes.bool,
  showFullInfo: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  onClose: PropTypes.func,
}

export default StopDetails
