import React, { useState } from 'react'

import { useParams } from 'react-router-dom-v5-compat'
import { Box, Grid, IconButton, ListItem, ListItemText } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import T from 'T'
import AccountStatusStatistic from 'components/customers/groups/account/AccountStatusStatistic'
import BillingProfilesStatistic from 'components/customers/groups/account/BillingProfilesStatistic'
import GraphCard from 'components/billing/groups/common/GraphCard'
import GraphCardEmptyState from 'components/billing/groups/common/GraphCardEmptyState'
import { useGetAccountGroupStatisticsData } from 'components/customers/groups/account/details/useGetAccountGroupStatisticsData'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'

const AccountGroupStatistics = () => {
  const { id } = useParams()
  const [isGraphSectionOpen, setIsGraphSectionOpen] = useState(true)
  const { data } = useGetAccountGroupDetailsData(id, true)
  const { data: statisticsData, isSuccess: isStatisticsSuccess } = useGetAccountGroupStatisticsData(true)

  const { billingProfilesGraphData, accountStatusGraphData, cutOffBillingProfiles } = statisticsData || {}
  const accounts = get(data, 'accounts', [])
  const accountsCount = get(accounts, 'length', 0)
  const hasAccounts = accountsCount > 0
  const groupName = get(data, 'groupName', '')
  const createdBy = get(data, 'createdBy')
  const createdAt = get(data, 'createdAt')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })

  const toggleGraphSection = () => setIsGraphSectionOpen(!isGraphSectionOpen)

  return (
    <>
      <Grid item xs={12}>
        <ListItem>
          <IconButton onClick={toggleGraphSection}>{isGraphSectionOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
          <ListItemText
            primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }}
            primary={`${groupName} statistics`}
          />
        </ListItem>
      </Grid>
      {isGraphSectionOpen && (
        <Grid container item spacing={2}>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard title="Billing profiles" createdBy={createdBy} time={onlyTime} date={onlyDate} createdAt={createdAt}>
              <Box height={300}>
                {!hasAccounts && <GraphCardEmptyState title={T.EMPTY_ACCOUNT_GROUP} />}
                {hasAccounts && isStatisticsSuccess && billingProfilesGraphData && (
                  <BillingProfilesStatistic
                    data={billingProfilesGraphData}
                    moreCount={cutOffBillingProfiles}
                    graphMargin={{ top: 16, right: 16, bottom: 8, left: 32 }}
                  />
                )}
              </Box>
            </GraphCard>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard title="Account status" createdBy={createdBy} time={onlyTime} date={onlyDate} createdAt={createdAt}>
              <Box height={300}>
                {!hasAccounts && <GraphCardEmptyState title={T.EMPTY_ACCOUNT_GROUP} />}
                {hasAccounts && isStatisticsSuccess && accountStatusGraphData && <AccountStatusStatistic data={accountStatusGraphData} />}
              </Box>
            </GraphCard>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AccountGroupStatistics
