import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import RenderMoveFromHeader from 'components/route-manager/BulkActions/BulkAssignStopsDialog/RenderMoveFromHeader'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import {
  selectBulkAssignFetchId,
  selectBulkAssignFromRows,
  selectBulkAssignStopsDate,
  selectBulkSelectedMoveFromRows,
  setBulkAssignStopsState,
  setSelectedMoveFromRows,
} from 'slices/route/bulkAssignStopsSlice'
import { useLazyGetRouteStopsUnassignedQuery } from 'api/route/getRouteStopsUnassigned'
import UnassignedPicker from 'components/route-manager/BulkActions/common/UnassignedPicker'
import RenderFromStopListItem from 'components/route-manager/BulkActions/common/StopListItem/RenderFromStopListItem'
import { mapStopToListItemFormat } from 'utils/route'

const MoveFromRouteColumn = ({ apiRef }) => {
  const { watch } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const dispatch = useDispatch()
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsUnassignedQuery()
  const serializedServiceDate = useSelector(selectBulkAssignStopsDate)
  const moveFromRows = useSelector(selectBulkAssignFromRows)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const fetchId = useSelector(selectBulkAssignFetchId)

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
    },
    {
      field: 'id',
      renderCell: RenderFromStopListItem,
      renderHeader: RenderMoveFromHeader,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const handleSelectionModelChange = selectionModel => {
    dispatch(setSelectedMoveFromRows(selectionModel))
  }

  const isRowSelectable = params => {
    if (!moveFromRouteId || !moveToRouteId) return false
    return params.row.routeId === moveFromRouteId
  }

  useEffect(() => {
    getRouteStops({
      serviceDate: formatDateToBEFormatDateFns(serviceDate),
      id: fetchId,
    })
      .unwrap()
      .then(data => {
        const stops = get(data, `stops`, [])
        const formattedStops = stops.map((stop, index) => {
          return {
            ...mapStopToListItemFormat(stop),
            position: index,
            originalPosition: index,
            sequence: index,
            routeId: moveFromRouteId,
          }
        })
        dispatch(
          setBulkAssignStopsState({
            moveFromRows: formattedStops,
          })
        )
      })
  }, [serializedServiceDate, fetchId])
  return (
    <>
      <Box mx={2} mb={2}>
        <UnassignedPicker />
      </Box>
      <Box flex={1} height="100%" width="100%" overflow="hidden">
        <BulkMoveStopsDataGridPro
          apiRef={apiRef}
          isRowSelectable={isRowSelectable}
          rowSelectionModel={selectedMoveFromRows}
          onRowSelectionModelChange={handleSelectionModelChange}
          loading={isLoading || isFetching}
          columns={columns}
          rows={moveFromRows}
        />
      </Box>
    </>
  )
}

MoveFromRouteColumn.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default MoveFromRouteColumn
