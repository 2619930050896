import React from 'react'
import { Card, Box, Link, Typography, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { getCustomerDetailsPageUrl } from 'router/routes'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import { PRIMARY } from 'theme/colors'
import T from 'T'
import { setStopDetailsDialogState } from 'slices/route/routeSlice'
import { useDispatch } from 'react-redux'
import ServiceActionTypeChip from 'components/services/ServiceActionTypeChip'
import { RECURRENCE, WORK_ORDER_STATUS, WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import MeasureChip from 'containers/new-route-manager/RouteCard/MeasureChip'
import { get } from 'utils/lodash'
import { getMeasureUnit } from 'utils/get-measure-unit'
import { combineAddressInfo } from 'utils/helper'
import WorkOrderStatusAvatar from './WorkOrderStatusAvatar'
import RouteCircle from './RouteCircle'

const { RECURRING } = RECURRENCE
const { SCHEDULED } = WORK_ORDER_STATUS

export const formatRouteData = data => {
  const accountId = get(data, 'accountId', '')
  const accountName = get(data, 'accountName', '')
  const serviceActionType = get(data, 'serviceActionType', '')
  const eventActionType = get(data, 'eventActionType', '')
  const pricedServiceType = get(data, 'pricedServiceType', '')
  const action = eventActionType || serviceActionType
  const address = combineAddressInfo(data)
  const locationId = get(data, 'locationId', '')
  const measureUnit = get(data, 'measureUnit')
  const measureVolume = get(data, 'measureVolume', 0)
  const measureLabel = `${measureVolume} ${getMeasureUnit(measureUnit)}`
  const serviceLocationName = get(data, 'addressName', '')
  const tags = { ...data, tagDetails: get(data, 'tags', []) }
  const workOrderId = get(data, 'workOrderId')
  const workOrderStatus = get(data, 'workOrderStatus', '')
  const routeId = get(data, 'routeId')
  return {
    accountId,
    accountName,
    action,
    address,
    eventActionType,
    locationId,
    measureUnit,
    measureVolume,
    measureLabel,
    pricedServiceType,
    serviceActionType,
    tags,
    workOrderId,
    workOrderStatus,
    serviceLocationName,
    routeId,
  }
}

const RouteCard = ({
  pricedServiceType = '',
  sx = {},
  routeSequence = '',
  accountName = '',
  accountId = '',
  locationId = '',
  measureUnit = '',
  measureLabel = '',
  action = '',
  address = '',
  tags = {},
  serviceLocationName = '',
  onTagsSave,
  isWorkOrderComplete = false,
  workOrderStatus = '',
  hasRouteCircle = true,
  isSelected = false,
  workOrderId = '',
  servicedAt,
}) => {
  const dispatch = useDispatch()
  const selectedSx = { border: `2pt solid ${PRIMARY.main}`, position: 'relative' }
  const defaultSx = {
    width: '100%',
    p: 1,
    borderWidth: workOrderStatus === SCHEDULED || !workOrderStatus ? 1.5 : 2,
    borderStyle: 'solid',
    borderColor: 'divider',
    ...(workOrderStatus !== SCHEDULED && {
      borderColor: get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`, 'divider'),
    }),
    ...sx,
  }
  const cardSx = isSelected ? { ...defaultSx, ...sx, ...selectedSx } : { ...defaultSx, ...sx }
  const handleServiceActionChipClick = () => dispatch(setStopDetailsDialogState({ id: workOrderId }))
  return (
    <Card variant="outlined" sx={cardSx}>
      <ListItem
        sx={{
          '& .MuiListItemSecondaryAction-root': {
            top: '18px',
            right: 0,
          },
          '& .MuiListItemAvatar-root': {
            minWidth: 'unset',
            paddingLeft: 0.5,
            paddingBottom: 0.5,
            paddingRight: 1,
          },
        }}
        disablePadding
        secondaryAction={workOrderStatus && <WorkOrderStatusAvatar status={workOrderStatus} />}
      >
        {hasRouteCircle && (
          <ListItemAvatar>
            <RouteCircle
              isWorkOrderComplete={isWorkOrderComplete}
              timestamp={servicedAt}
              routeSequence={routeSequence}
              workOrderStatus={workOrderStatus}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={serviceLocationName || accountName}
          primaryTypographyProps={{
            noWrap: true,
            component: Link,
            href: `${getCustomerDetailsPageUrl(accountId)}?location=${locationId}`,
            underline: 'hover',
            fontWeight: '500',
            color: 'inherit',
            sx: isWorkOrderComplete && { mr: 2.5 },
          }}
          secondary={
            <Typography variant="subtitle2" noWrap>
              {address}
            </Typography>
          }
        />
      </ListItem>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {action && <ServiceActionTypeChip clickable size="small" action={action} onClick={handleServiceActionChipClick} />}
        {!action && pricedServiceType === RECURRING && (
          <ServiceActionTypeChip
            clickable
            size="small"
            action="Collection"
            label={T.RECURRING_COLLECTION}
            onClick={handleServiceActionChipClick}
          />
        )}
        <Box display="inline-flex">
          <MeasureChip workOrderId={workOrderId} label={measureLabel.trim() ? measureLabel : 'No measurement'} measureUnit={measureUnit} />
        </Box>
      </Stack>
      <Box sx={{ mt: 2, width: '100%', height: '56px' }}>
        <InteractiveTagsList
          GridContainerProps={{
            direction: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            spacing: 0.5,
            sx: { height: '56px' },
          }}
          data={tags}
          onSave={onTagsSave}
          limit={6}
          tagMaxWidth="90px"
        />
      </Box>
    </Card>
  )
}

RouteCard.propTypes = {
  sx: PropTypes.object,
  routeSequence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountName: PropTypes.string,
  accountId: PropTypes.string,
  locationId: PropTypes.string,
  measureUnit: PropTypes.string,
  measureLabel: PropTypes.string,
  action: PropTypes.string,
  address: PropTypes.string,
  tags: PropTypes.object,
  serviceLocationName: PropTypes.string,
  onTagsSave: PropTypes.func,
  isWorkOrderComplete: PropTypes.bool,
  servicedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
  workOrderStatus: PropTypes.string,
  hasRouteCircle: PropTypes.bool,
  isSelected: PropTypes.bool,
  workOrderId: PropTypes.string,
  pricedServiceType: PropTypes.string,
}

export default RouteCard
