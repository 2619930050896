import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    updateBusinessInfo: build.mutation({
      query: body => ({
        url: '/api/v1/core/business',
        method: 'PUT',
        body,
      }),
    }),
  }),
})
export const { useUpdateBusinessInfoMutation } = extendedApi
