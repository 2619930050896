import React, { useReducer, useEffect, useState } from 'react'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import { addDays } from 'date-fns'

import { useHistory } from 'react-router-dom'

import routes from 'router/routes'

import { Menu, Typography, Box } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { get } from 'utils/lodash'
import { formatDateToBEFormat } from 'utils/date'
import { PAGINATION } from 'settings/constants/pagination'
import { handleConfigurationChange, handleConfiguratorDragEnd } from 'data/utils/configuratorSelector'
import {
  handleCommonTableSortingChange,
  handleCommonTableFilterChange,
  handleFilterInputChange,
  handleCommonTableformatFilters,
  handleCommonTableformatSortBy,
  handlePrefillResponseModification,
} from 'utils/table'
import { ReportTopSection, ReportTable } from 'components/reports/index'
import { CommonTableFilters } from 'components/common'
import SaveEditInvoiceViewModal from 'components/modal/SaveEditInvoiceViewModal'

import Configurator from 'components/common/Configurator'
import Loader from 'components/common/loader'

import { REPORT_MODEL } from 'containers/reports/settings/reportModel'
import { PDF_LIMIT_MSG, DEFAULT_REPORTS } from 'settings/constants/reports'

import SaveNewReportDrawer from 'components/drawer/reports/saveReport'
import { useGetReportFullListQuery, useLazyGetReportFullListQuery } from 'api/report/getReportFullList'
import { useSaveReportConfiguratorMutation } from 'api/report/saveReportConfigurator'
import { useUpdateReportConfiguratorMutation } from 'api/report/updateReportConfigurator'
import { useLazyGetReportTableContentQuery } from 'api/report/getReportTableContent'
import { useLazyGetReportSearchFilterViewListQuery } from 'api/report/getReportSearchFilterViewList'
import { useDeleteFilterViewMutation } from 'api/report/deleteFilterView'
import { useAddEditFilterViewMutation } from 'api/report/addEditFilterView'

import {
  REVENUE_CONFIGURATOR,
  SERVICE_CONFIGURATOR,
  SENT_INVOICES_CONFIGURATOR,
  PAYMENTS_CONFIGURATOR,
  INVOICES_CONFIGURATOR,
  WORK_ORDER_CONFIGURATOR,
  ACCOUNT_RECEIVABLE_CONFIGURATOR,
} from 'containers/reports/settings'
import 'containers/billing/style.scss'

import T from 'T'

import { ConfirmationModal } from 'components/modal'
import { handleError } from 'utils/error'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

const FIRST_PAGE_INDEX = 1

// hard coded filterVisibilityType
const VISIBILITY_TYPE = 'User'

const CALENDAR_START_DAY = -365 // starting from exact one year ago

const INITIAL_MINUTE = '00:01'
const LAST_MINUTE = '23:59' // last minute of day

const ReportTables = () => {
  const history = useHistory()

  const [rangeSelection, setRangeSelection] = useState({
    startDate: addDays(new Date(), CALENDAR_START_DAY),
    endDate: new Date(),
    key: 'selection',
  })
  const { data } = useGetReportFullListQuery()
  const [getReportFullList] = useLazyGetReportFullListQuery()
  const [saveReportConfigurator] = useSaveReportConfiguratorMutation()
  const [updateReportConfigurator] = useUpdateReportConfiguratorMutation()
  const [getReportTableContent, { isLoading, isFetching }] = useLazyGetReportTableContentQuery()
  const [getReportSearchFilterViewList] = useLazyGetReportSearchFilterViewListQuery()
  const [deleteFilterView] = useDeleteFilterViewMutation()
  const [addEditFilterView] = useAddEditFilterViewMutation()

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isPDFMaxColumnsModal: false,
    ...cloneDeep(REPORT_MODEL),
  })

  const {
    isOpenConfigurator,
    isOpenTableFilters,
    isOpenSaveEditViewModal,
    isOpenNewReportDrawer,
    allFilters,
    activeFilter,
    allSortBy,
    configuratorColumns,
    pastConfiguratorColumns,
    allTableRows,
    selectedRows,
    saveEditViewInput,
    paginationCache,
    page,
    rowsPerPage,
    totalTableRowsCount,
    totalPageCount,
    existingFilterList,
    selectedFilter,
    selectedFilterId,
    isPDFMaxColumnsModal,
    reportName,
    reportDesc,
  } = localState

  const formatFilters = (newFilters = allFilters) => handleCommonTableformatFilters(newFilters)

  const formatSortBy = (newSortBy = allSortBy) => handleCommonTableformatSortBy(newSortBy)
  const { reportId } = history.location.state
  const reportCustonName = history.location.state.reportName
  const tabConfigurator = data.find(item => item.id === reportId)
  const getDefaultTabConfigurator = get(tabConfigurator, 'isDefault', '')
  const getReportType = () => {
    return history.location.state.reportType
  }

  const getActiveTabConfigurator = () => {
    const updatedConfigurator = get(tabConfigurator, 'columnsConfig', []).map(item => (item.align === null ? { ...item, align: '' } : item))
    const selection = getReportType()
    if (getDefaultTabConfigurator) {
      switch (selection) {
        case T.AR_REPORT:
          return ACCOUNT_RECEIVABLE_CONFIGURATOR
        case T.REVENUE_REPORT:
          return REVENUE_CONFIGURATOR
        case T.SERVICE_REPORT:
          return SERVICE_CONFIGURATOR
        case T.PAYMENT_REPORT:
          return PAYMENTS_CONFIGURATOR
        case T.INVOICE_REPORT:
          return INVOICES_CONFIGURATOR
        case T.SI_REPORT:
          return SENT_INVOICES_CONFIGURATOR
        case T.WO_REPORT:
          return WORK_ORDER_CONFIGURATOR
        default:
          return []
      }
    } else {
      return updatedConfigurator
    }
  }

  const handleReportFilterPrefill = searchFilterId => {
    const newReportFilters = {}
    const newReportSortBy = {}
    if (!searchFilterId) {
      return { allFilters: newReportFilters, allSortBy: newReportSortBy }
    }

    const existingSearchFilters = existingFilterList.find(filter => filter.searchFilterId === searchFilterId)

    get(existingSearchFilters, 'sortRules', []).forEach(sort => {
      newReportSortBy[sort.columnName] = { columnName: sort.columnName, operation: sort.operation }
    })

    get(existingSearchFilters, 'searchFilters', []).forEach(filter => {
      const initialFilter = getActiveTabConfigurator().find(conf => conf.columnName === filter.columnName)
      newReportFilters[filter.columnName] = handlePrefillResponseModification(initialFilter, filter)
    })

    return { allFilters: newReportFilters, allSortBy: newReportSortBy }
  }

  const handleReportSave = () => {
    const type = DEFAULT_REPORTS.filter(item => item.reportParentType === getReportType())
    if (getDefaultTabConfigurator) {
      const body = {
        name: reportName,
        description: reportDesc,
        columnsConfig: configuratorColumns,
        type: get(type, '[0].reportTypeFilter', ''),
        sortRules: formatSortBy(allSortBy),
        filterRules: formatFilters(allFilters),
      }
      saveReportConfigurator(body)
      getReportFullList()
      history.push({
        pathname: `${routes.app.reports}/${reportName.replaceAll(/\s/g, '')}`,
        state: { reportType: getReportType(), reportName, reportId },
      })
      setLocalState({ isOpenNewReportDrawer: false })
    } else {
      const body = {
        id: reportId,
        body: {
          name: reportName,
          description: reportDesc,
          sortRules: formatSortBy(allSortBy),
          columnsConfig: configuratorColumns,
          filterRules: formatFilters(allFilters),
        },
      }
      updateReportConfigurator(body)
      getReportFullList()
      history.push({
        pathname: `${routes.app.reports}/${reportName.replaceAll(/\s/g, '')}`,
        state: { reportType: getReportType(), reportName, reportId },
      })
      setLocalState({ isOpenNewReportDrawer: false })
    }
  }

  const getReportTableData = (
    requestedPage,
    requestedPageSize,
    cache = true,
    searchFilters = [],
    sortRules = [],
    filterId = '',
    startDate = get(rangeSelection, 'startDate'),
    endDate = get(rangeSelection, 'endDate')
  ) => {
    // Check if cache present
    if (cache && get(paginationCache, requestedPage, '')) {
      setLocalState({ allTableRows: get(paginationCache, requestedPage, []) })
      return
    }

    const payload = {
      requestedPage,
      requestedPageSize,
      reportType: getReportType(),
    }

    if (getReportType() !== 'AR_REPORT') {
      set(payload, 'startDate', `${formatDateToBEFormat(startDate)} ${INITIAL_MINUTE}`)
      set(payload, 'endDate', `${formatDateToBEFormat(endDate)} ${LAST_MINUTE}`)
    }

    if (searchFilters.length) {
      set(payload, 'searchFilters', searchFilters)
    }

    set(payload, 'sortRules', sortRules.length ? sortRules : get(tabConfigurator, 'sortRules', []))

    const existingPaginationCache = cache ? { ...paginationCache } : {}

    getReportTableContent(payload)
      .unwrap()
      .then(res => {
        const tableRows = get(res, 'dataList', [])
        setLocalState({
          paginationCache: {
            ...existingPaginationCache,
            ...{
              [requestedPage]: tableRows,
            },
          },
          isOpenTableFilters: null,
          allTableRows: tableRows,
          totalTableRowsCount: get(res, 'totalItems', tableRows.length),
          // If no pages then set default to first page
          totalPageCount: get(res, 'totalPages', FIRST_PAGE_INDEX),
        })
      })
      .catch(handleError)
  }

  const handleMultipleChange = payload => setLocalState({ ...payload })

  const handleSingleChange = (key, value) => {
    if (key === 'selectedFilterId') {
      const { allFilters: newFilters, allSortBy: newSortBy } = handleReportFilterPrefill(value)
      setLocalState({ [key]: value, allFilters: newFilters, allSortBy: newSortBy })
      // Add previous filter state
      // Depricated
      // getReportTableData(INITIAL_PAGE, rowsPerPage, false, [], [], value);
      getReportTableData(INITIAL_PAGE, rowsPerPage, false, formatFilters(newFilters), formatSortBy(newSortBy), value)
      return
    }

    if (key === 'calendar') {
      setRangeSelection(value)
      getReportTableData(INITIAL_PAGE, rowsPerPage, false, formatFilters(), formatSortBy(), '', value.startDate, value.endDate)

      return
    }

    setLocalState({ [key]: value })
  }

  const handleFilterChange = (key, value, columnName) => setLocalState(handleFilterInputChange(key, value, columnName, allFilters))

  const handleSortByChange = (columnName, type) => {
    const newSort = handleCommonTableSortingChange(allSortBy, columnName, type)
    setLocalState({ allSortBy: newSort, page: INITIAL_PAGE })
    getReportTableData(INITIAL_PAGE, rowsPerPage, false, formatFilters(), formatSortBy(newSort), '')
  }

  // Page change handler
  const handlePageChange = newPage => {
    setLocalState({ page: newPage, selectedRows: [] })
    getReportTableData(newPage, rowsPerPage, true, formatFilters(), formatSortBy(), selectedFilterId)
  }

  // Rows per page change handler
  const handleRowsPerPageChange = event => {
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: event.target.value, paginationCache: '' })
    getReportTableData(INITIAL_PAGE, event.target.value, false, formatFilters(), formatSortBy(), selectedFilterId)
  }

  const handleTableFilterChange = (openEvent, filterType, columnName, subType, label, isSingleSelect, isBoolean) =>
    setLocalState(
      handleCommonTableFilterChange({ openEvent, filterType, columnName, subType, label, isSingleSelect, isBoolean, allFilters })
    )

  // Don't pass selectedFilterId
  const handleFilterApply = () => {
    setLocalState({ page: INITIAL_PAGE })
    getReportTableData(INITIAL_PAGE, rowsPerPage, false, formatFilters(), formatSortBy())
  }

  const handleReportFilterClose = () => {
    if (selectedFilterId === '') {
      delete allFilters[activeFilter]
    } else {
      const currentReportFilter = existingFilterList.find(dataItem => selectedFilterId === dataItem.searchFilterId)
      const filteredReportRecord = get(currentReportFilter, 'searchFilters', []).find(
        item => item.columnName === allFilters[activeFilter].columnName
      )

      if (!filteredReportRecord) {
        delete allFilters[activeFilter]
      } else {
        const initialReportFilter = getActiveTabConfigurator().find(conf => conf.columnName === allFilters[activeFilter].columnName)
        allFilters[activeFilter] = handlePrefillResponseModification(initialReportFilter, filteredReportRecord)
      }
    }

    setLocalState({ isOpenTableFilters: null, allFilters })
    handleFilterApply()
  }

  const closeReportFilterDropdown = element => {
    setTimeout(() => {
      if (document.querySelector(element)) {
        document.querySelector(element).click()
      }
    }, 300)
  }

  const getExistingFilterList = (closeDropdown = false, element = '') => {
    getReportSearchFilterViewList({ filterVisibilityType: VISIBILITY_TYPE, reportType: getReportType() })
      .unwrap()
      .then(res => {
        setLocalState({ existingFilterList: get(res, 'searchFilters', []) })
        if (closeDropdown && element) {
          closeReportFilterDropdown(element)
        }
      })
      .catch(handleError)
  }

  const getTabData = () => {
    const activeConf = getActiveTabConfigurator()
    setLocalState({ configuratorColumns: cloneDeep(activeConf), pastConfiguratorColumns: cloneDeep(activeConf) })
    getReportTableData(INITIAL_PAGE, ROWS_PER_PAGE, false, [], get(tabConfigurator, 'sortRules', []))

    // will uncomment later
    // getExistingFilterList();
  }

  useEffect(() => {
    getTabData()
    setLocalState({ reportName: reportCustonName, reportDesc: '' })
  }, [])

  const renderTabContent = () => ({
    allFilters,
    allSortBy,
    columns: pastConfiguratorColumns.filter(column => column.checked),
    lockedColumns: pastConfiguratorColumns.filter(column => column.locked && column.checked),
    allTableRows,
    selectedRows,
    page,
    rowsPerPage,
    totalTableRowsCount,
    totalPageCount,
    onTableFilterChange: handleTableFilterChange,
    onSortByChange: handleSortByChange,
    onPageChange: handlePageChange,
    onRowsPerPageChange: handleRowsPerPageChange,
    getTabData,
  })

  const isTopSectionVisibile = () => {
    return true
  }

  const handleAfterDelete = deleteId => {
    const isCurrentDeleted = deleteId === selectedFilterId

    // if deleted view was selected view then switch to default view
    setLocalState({
      isOpenSaveEditViewModal: false,
      saveEditViewInput: '',
      selectedFilter: {},
      selectedFilterId: isCurrentDeleted ? '' : selectedFilterId,
      allFilters: isCurrentDeleted ? {} : allFilters,
      allSortBy: isCurrentDeleted ? {} : allSortBy,
      existingFilterList: existingFilterList.filter(filter => filter.searchFilterId !== deleteId),
    })

    if (isCurrentDeleted) {
      getReportTableData(page, rowsPerPage, false)
    }

    // If current deleted then go to default view
    // If other deleted stay on same view, selected or default
    closeReportFilterDropdown(isCurrentDeleted ? '.filter-default' : `.filter-${selectedFilterId || 'default'}`)
  }

  const handleFilterViewDelete = () => {
    const deleteId = get(selectedFilter, 'searchFilterId', '')
    if (!deleteId) {
      return
    }

    const payload = {
      id: deleteId,
      reportType: getReportType(),
    }

    deleteFilterView(payload)
      .unwrap()
      .then(() => {
        handleAfterDelete(deleteId)
      })
      .catch(handleError)
  }

  const handleFilterViewSubmit = () => {
    const payload = {
      searchFilterName: saveEditViewInput,
      visibility: VISIBILITY_TYPE,
      reportType: getReportType(),
    }
    const existingId = get(selectedFilter, 'searchFilterId', '')
    const isAddMode = !existingId

    if (isAddMode) {
      set(payload, 'searchFilters', formatFilters())
      set(payload, 'sortRules', formatSortBy())
    } else {
      // Edit mode, searchFilters & sortRules updation is not allowed
      set(payload, 'id', existingId)
    }

    addEditFilterView(payload)
      .unwrap()
      .then(res => {
        const newId = get(res, 'id', '')
        let filterClass = selectedFilterId ? `.filter-${selectedFilterId}` : '.filter-default'
        const newState = {
          isOpenSaveEditViewModal: false,
          saveEditViewInput: '',
          selectedFilter: {},
        }

        if (isAddMode) {
          // Only set for newly added views, we can also edit other views by not opening it
          set(newState, 'selectedFilterId', newId)
          filterClass = `.filter-${newId}`
        }

        setLocalState(newState)
        getExistingFilterList(true, filterClass)
      })
      .catch(handleError)
  }

  return (
    <Box className="report-tab-content">
      {(isLoading || isFetching) && <Loader />}

      <Box display="flex" alignItems="center" p="24px 20px">
        <Typography variant="h3" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => history.push(routes.app.reports)}>
          {T.REPORTS}
        </Typography>

        <Box display="flex" alignItems="center">
          <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
          <Typography variant="h3">{history.location.state.newReport ? T.NEW_REPORT : history.location.state.reportName}</Typography>
        </Box>
      </Box>

      {isTopSectionVisibile() && (
        <ReportTopSection
          activeTab={3}
          allTableRows={allTableRows}
          selectedRows={selectedRows}
          selectedFilterId={selectedFilterId}
          existingFilterList={existingFilterList}
          newReport={getDefaultTabConfigurator}
          rangeSelection={rangeSelection}
          // Hide configurator for sent invoices tab
          showConfigurator={getReportType() !== 'SI_REPORT'}
          showDateRange={getReportType() !== 'AR_REPORT'}
          onChange={handleSingleChange}
          onMultipleChange={handleMultipleChange}
        />
      )}

      <ReportTable {...renderTabContent()} />

      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        label={get(allFilters, `${activeFilter}.label`)}
        isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
        isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        filterComponent="report"
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={() => handleReportFilterClose()}
      />

      <SaveEditInvoiceViewModal
        isOpen={isOpenSaveEditViewModal}
        input={saveEditViewInput}
        isAddMode={!get(selectedFilter, 'searchFilterName')}
        onDelete={handleFilterViewDelete}
        onSubmit={handleFilterViewSubmit}
        onClose={() => setLocalState({ isOpenSaveEditViewModal: false, saveEditViewInput: '', selectedFilter: {} })}
        onChange={handleSingleChange}
      />
      <Menu onClose={() => setLocalState({ isOpenConfigurator: null })} open={Boolean(isOpenConfigurator)} anchorEl={isOpenConfigurator}>
        <Configurator
          configData={configuratorColumns}
          handleOnDragEnd={result =>
            setLocalState({ configuratorColumns: handleConfiguratorDragEnd({ result, columns: configuratorColumns }) })
          }
          handleCancel={() => setLocalState({ isOpenConfigurator: null, configuratorColumns: pastConfiguratorColumns })}
          handleConfChange={(index, type, confData) =>
            setLocalState({ configuratorColumns: handleConfigurationChange({ index, type, confData }) })
          }
          saveConfigSettings={() => setLocalState({ isOpenConfigurator: null, pastConfiguratorColumns: configuratorColumns })}
        />
      </Menu>

      <ConfirmationModal
        isOpen={isPDFMaxColumnsModal}
        title={
          <Box>
            <Typography variant="h4" textAlign="center" color="error.main">{`${T.ERROR}!`}</Typography>
            <Typography variant="h6" mt={1}>
              {PDF_LIMIT_MSG}
            </Typography>
          </Box>
        }
        cancelButtonTitle={T.CLOSE}
        hasCancelBtn={false}
        proceedButtonTitle={T.CLOSE}
        onProceed={() => setLocalState({ isPDFMaxColumnsModal: false })}
      />
      {isOpenNewReportDrawer && (
        <SaveNewReportDrawer
          isOpenDrawer={isOpenNewReportDrawer}
          reportName={reportName}
          reportDesc={reportDesc}
          onHandleChange={handleSingleChange}
          onHandleSave={handleReportSave}
          onClose={isOpen => {
            setLocalState({ isOpenNewReportDrawer: isOpen, reportName: '', reportDesc: '' })
          }}
        />
      )}
    </Box>
  )
}

export default ReportTables
