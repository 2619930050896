import React, { useRef, useEffect, useState } from 'react'

import { keyBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { common } from '@mui/material/colors'
import { DialogContent, Box, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { isValidHex } from 'utils/validations'
import { formatStopFeatures } from 'utils/route'
import { MapProvider } from 'providers/MapProvider'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'
import { getBulkMoveStopColumnSx, removeLayersAndSources, ROW_HEIGHT } from 'components/route-manager/BulkActions/settings'
import {
  selectIsSelectDateDialogOpen,
  selectIsOpenBulkResequenceStopsDialog,
  selectIsOpenBulkResequenceConfirmCloseDialog,
  setBulkResequenceStopsState,
  BULK_MOVE_MAP_LAYERS,
  selectBulkResequenceFocusName,
  selectBulkResequenceFocusRowIndex,
  selectBulkResequenceRows,
  selectBulkResequenceClickedMarkerId,
  selectIsConfirmPermanentMoveDialogOpen,
  setConfirmPermanentMoveDialogState,
} from 'slices/route/bulkResequenceStopsSlice'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { useLazyGetRouteStopsGeojsonQuery } from 'api/route/getRouteStops'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import BulkDialogAppbar from 'components/route-manager/BulkActions/common/BulkDialogAppbar'
import ConfirmPermanentMoveDialog from 'components/route-manager/BulkActions/BulkResequenceStopsDialog/ConfirmPermanentMoveDialog/ConfirmPermanentMoveDialog'
import UnsavedChangesConfirmDialog from 'components/route-manager/BulkActions/common/UnsavedChangesConfirmDialog'
import useGetBEBatchesResequenceVariant from 'components/route-manager/BulkActions/common/useGetBEBatchesResequenceVariant'
import SelectRouteAndDateDialog from '../common/SelectRouteAndDateDialog'
import StopsDataGrid from './StopsDataGrid'
import MapView from './MapView'
import BulkResequenceStopsDialogActions from './BulkResequenceStopsDialogActions'
import { defaultValues, ROUTE_SELECTION_FIELD_KEY } from './settings'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const BulkResequenceStopsDialog = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const dataGridApiRef = useGridApiRef()
  const confirmPermanentGridApiRef = useGridApiRef()
  const map = useRef()
  const methods = useForm({ defaultValues })
  const { reset, watch, setFocus } = methods
  const [routes, setRoutes] = useState([])
  const [isPermanentMove, setIsPermanentMove] = useState(false)
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const [getRouteStopsGeojson] = useLazyGetRouteStopsGeojsonQuery()

  const open = useSelector(selectIsOpenBulkResequenceStopsDialog)
  const isConfirmCloseDialogOpen = useSelector(selectIsOpenBulkResequenceConfirmCloseDialog)
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const focusName = useSelector(selectBulkResequenceFocusName)
  const focusRowIndex = useSelector(selectBulkResequenceFocusRowIndex)
  const rows = useSelector(selectBulkResequenceRows)
  const clickedMarkerId = useSelector(selectBulkResequenceClickedMarkerId)
  const isConfirmPermanentMoveDialogOpen = useSelector(selectIsConfirmPermanentMoveDialogOpen)
  const columnSx = getBulkMoveStopColumnSx(theme)

  const serviceDate = watch('serviceDate')
  const routeId = watch('routeId')
  const getBEBatches = useGetBEBatchesResequenceVariant({ serviceDate, routeId, rows })

  const handleReset = (isRouteAndDateSelectionDialogOpen = true) => {
    const mapCurrentRef = map.current
    if (!mapCurrentRef) return
    if (routeId) removeLayersAndSources(mapCurrentRef, routeId)

    dispatch(
      setBulkResequenceStopsState({
        focus: { name: '', rowIndex: '' },
        isSelectDateDialogOpen: isRouteAndDateSelectionDialogOpen,
        isConfirmCloseDialogOpen: false,
        rows: [],
        originalRouteSequenceLine: {},
        routeSequenceLine: {},
        routeStopMarkers: {},
        mapLayer: FROM_ROUTE_LAYER,
        clickedMarkerId: '',
        confirmPermanentMoveDialog: { isOpen: false },
      })
    )
    reset(defaultValues)
  }

  const handleClose = () => {
    const changedStops = rows.find(row => row.isChanged)
    if (changedStops) {
      dispatch(setBulkResequenceStopsState({ isConfirmCloseDialogOpen: true }))
      return
    }

    handleReset(false)
    dispatch(setBulkResequenceStopsState({ isOpen: false }))
  }

  const onCancelCloseDialog = () => {
    dispatch(setBulkResequenceStopsState({ isConfirmCloseDialogOpen: false }))
  }

  const onConfirmCloseDialog = () => {
    handleReset(false)
    dispatch(setBulkResequenceStopsState({ isOpen: false }))
  }

  const onConfirmSelectDateDialog = () => {
    dispatch(setBulkResequenceStopsState({ isSelectDateDialogOpen: false, isOpen: true }))
    getRoutesMetadata({ serviceDate: formatDateToBEFormatDateFns(serviceDate) }, true)
  }

  const onCloseSelectDateDialog = () => {
    handleReset(false)
    dispatch(setBulkResequenceStopsState({ isSelectDateDialogOpen: false }))
  }

  const onCloseConfirmPermanentMoveDialog = () => {
    dispatch(setConfirmPermanentMoveDialogState({ isOpen: false }))
  }

  const onConfirmPermanentMove = () => {
    dispatch(setConfirmPermanentMoveDialogState({ isOpen: false }))
    setIsPermanentMove(true)
    const beBatches = getBEBatches(true)
    bulkMoveStops({ moves: beBatches })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        handleReset(false)
        dispatch(setBulkResequenceStopsState({ isOpen: false }))
      })
      .catch(handleError)
      .finally(() => setIsPermanentMove(false))
  }

  const formatGeojsonData = data => {
    const routeGeojsonData = get(data, 'routeGeojsonData', {})
    const routeFeatures = get(routeGeojsonData, `${routeId}.stops.features`, [])
    const routeStopMarkers = formatStopFeatures(routeFeatures, theme).map(marker => {
      const properties = get(marker, 'properties', {})
      return {
        ...marker,
        properties: {
          ...properties,
          icon: 'monochrome-marker',
        },
      }
    })
    const routeSequenceLine = get(routeGeojsonData, `${routeId}.sequenceLine`, {})
    dispatch(setBulkResequenceStopsState({ routeStopMarkers, routeSequenceLine, originalRouteSequenceLine: routeSequenceLine }))
  }

  useEffect(() => {
    dispatch(
      setBulkResequenceStopsState({
        routeId,
        fitBounds: true,
      })
    )
  }, [routeId])

  useEffect(() => {
    if (!isSelectDateDialogOpen && serviceDate && routeId) {
      getRouteStopsGeojson({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        routes: [routeId],
      })
        .unwrap()
        .then(formatGeojsonData)
    }
  }, [isSelectDateDialogOpen, serviceDate, routeId])

  useEffect(() => {
    return () => {
      map.current?.remove()
      map.current = null
    }
  }, [])

  useEffect(() => {
    if (routeMetaData) {
      const updatedRoutes = get(routeMetaData, 'routes', [])
      setRoutes(updatedRoutes)
    }
  }, [routeMetaData])

  useEffect(() => {
    const formattedRoutes = routes.map(route => {
      const color = get(route, 'color', common.black)
      const formattedColor = isValidHex(color) ? color : common.black
      const contrastColor = theme.palette.getContrastText(formattedColor)
      return {
        ...route,
        color: formattedColor,
        contrastColor,
      }
    })
    dispatch(setBulkResequenceStopsState({ routesById: keyBy(formattedRoutes, 'id') }))
  }, [routes])

  useEffect(() => {
    let timeoutId
    if (focusName) {
      const fixedFocusIndex = focusRowIndex || 0
      setTimeout(() => {
        scrollToSelectedRowInDataGrid(dataGridApiRef, fixedFocusIndex, ROW_HEIGHT)
        setTimeout(() => {
          if (focusName) {
            setFocus(focusName)
          }
          dispatch(setBulkResequenceStopsState({ focus: { name: '', rowIndex: 0 } }))
        }, 100)
      }, 100)
    }

    return () => clearTimeout(timeoutId)
  }, [focusName])

  useEffect(() => {
    if (clickedMarkerId) {
      const currentPosition = rows.findIndex(row => row.id === clickedMarkerId)
      if (currentPosition !== -1) {
        dispatch(setBulkResequenceStopsState({ clickedMarkerId: '', focus: { name: clickedMarkerId, rowIndex: currentPosition } }))
        // Highlight the input field text, we can also use  onFocus={e => e.target.select()} in the textfield if we want to highlight the text on focus globally
        setTimeout(() => document.getElementById(`${clickedMarkerId}-resequence-field`)?.select(), 200)
      }
    }
  }, [clickedMarkerId])

  useEffect(() => {
    return () => {
      handleReset(false)
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <MapProvider map={map}>
          <HHFullScreenBaseDialog open={open} onClose={handleClose}>
            <BulkDialogAppbar title={T.CHANGE_ROUTE_SEQUENCE} onClose={handleClose} serviceDate={serviceDate} showDate />
            <DialogContent dividers sx={{ p: 0 }}>
              <Box display="flex" direction="row" flexWrap="nowrap" height="100%" overflow="hidden">
                <Box mt={5} display="flex" flexDirection="column" sx={columnSx}>
                  <StopsDataGrid apiRef={dataGridApiRef} routes={routes} />
                </Box>
                <Box flex={1}>{open && <MapView routes={routes} />}</Box>
              </Box>
            </DialogContent>
            <BulkResequenceStopsDialogActions
              isPermanentLoading={isLoading}
              isPermanentMove={isPermanentMove}
              getBEBatches={getBEBatches}
              onReset={handleReset}
              onClose={handleClose}
            />
          </HHFullScreenBaseDialog>
        </MapProvider>
        <ConfirmPermanentMoveDialog
          isPermanentLoading={isLoading}
          confirmPermanentGridApiRef={confirmPermanentGridApiRef}
          onClose={onCloseConfirmPermanentMoveDialog}
          onConfirm={onConfirmPermanentMove}
          isOpen={isConfirmPermanentMoveDialogOpen}
        />
        <UnsavedChangesConfirmDialog onConfirm={onConfirmCloseDialog} onClose={onCancelCloseDialog} isOpen={isConfirmCloseDialogOpen} />
        <SelectRouteAndDateDialog
          isOpen={isSelectDateDialogOpen}
          routeSelectionFieldKey={ROUTE_SELECTION_FIELD_KEY}
          dialogTitle={T.CHANGE_ROUTE_SEQUENCE}
          onConfirm={onConfirmSelectDateDialog}
          onClose={onCloseSelectDateDialog}
          DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
        />
      </FormProvider>
    </>
  )
}

export default BulkResequenceStopsDialog
