import api from 'api'
import { CACHE_TAG_LOCATIONS_NOTES_COUNT } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getLocationsNotesCount: build.query({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/notes/count`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_LOCATIONS_NOTES_COUNT],
    }),
  }),
})

export const { useLazyGetLocationsNotesCountQuery } = extendedApi
