import React, { useReducer, useEffect, Children, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import set from 'lodash/set'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import Popover from '@mui/material/Popover'

import ServicePanel from 'components/pricing/Services/ServicesPanel'
import FeesPanel from 'components/pricing/Fees/FeesPanel'
import TaxesPanel from 'components/pricing/Taxes/TaxesPanel'
import CommonTableFilters from 'components/common/CommonTableFilters'
import Configurator from 'components/common/Configurator'

import { get } from 'utils/lodash'
import { canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { PRICING_TABS_ID } from 'settings/constants/pricing'
import { handleConfigurationChange, handleConfiguratorDragEnd } from 'data/utils/configuratorSelector'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import {
  handleCommonTableSortingChange,
  handleCommonTableFilterChange,
  handleFilterInputChange,
  handleCommonTableformatFilters,
  handleCommonTableformatSortBy,
} from 'utils/table'
import { handleScrollToTop } from 'utils/dom'
import { PRICING_PAGINATION } from 'settings/constants/pagination'
import { getPricingTableContent, getPricingSummary, getPricingRevenue, getPricingStatusChange } from 'middleware/actions/pricing'
import { initiateStatusDownload } from 'middleware/actions/download'
import { handleClass, handleCheckBoxClass } from 'components/pricing/pricingMethods'
import { SERVICES, TAXES, GENERAL_FEES, DISPOSAL_FEES } from 'components/pricing/settings'
import Loader from 'components/common/loader'

import ServiceGroups from 'containers/pricing/ServiceGroups'
import './style.scss'
import { truncateDecimals } from 'utils/validations'
import { useLazyGetPricingServiceListQuery } from 'api/pricing/pricingServiceCrud'
import { useLazyGetPricingMetaFiltersQuery } from 'api/meta/getPricingMetaFilters'
import { pricingMeta } from 'slices/meta/pricingFiltersMetaSlice'
import { handleError } from 'utils/error'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION
const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID
const FIRST_PAGE_INDEX = 1
const checkedVal = {}
const changePrice = {}

const Pricing = ({ defaultTabValue = 'services' }) => {
  const dispatch = useDispatch()
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)

  const [configData, setConfigdata] = useState([])
  const [tabValue, setTabValue] = useState(defaultTabValue)

  const ACTIVE_STATUS_FILTER = {
    active: {
      filterType: 'checkbox',
      operation: 'EQUAL_TO',
      columnValue: ['true'],
      columnName: 'active',
      subType: 'pricingStatus',
      label: 'Status',
      isSingleSelect: true,
      isBoolean: true,
    },
  }

  const [state, setState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    pricingServiceList: [],
    taxServiceList: [],
    feeServiceList: [],
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    totalServiceCount: 0,
    totalPageCount: 0,
    totalTaxCount: 0,
    totalTaxPageCount: 0,
    totalFeeCount: 0,
    totalFeePageCount: 0,
    isOpenConfigurator: false,
    saveConfSettings: false,
    configuratorColumns: [],
    pastConfiguratorColumns: [],
    columns: [],
    lockedColumns: [],
    summary: {},
    contentLoader: false,
    // Later change to redux store
    fileName: '',
    activeOnly: true,
    allCheckedVal: {},
    isOpenTableFilters: false,
    allFilters: { ...ACTIVE_STATUS_FILTER },
    activeFilter: '',
    allSortBy: {},
    allTableRows: [],
    totalTableRowsCount: 0,
    isDirty: false,
  })

  const {
    page,
    rowsPerPage,
    totalPageCount,
    configuratorColumns,
    saveConfSettings,
    pastConfiguratorColumns,
    summary,
    isOpenConfigurator,
    allCheckedVal,
    isOpenTableFilters,
    allFilters,
    activeFilter,
    allSortBy,
    allTableRows,
    totalTableRowsCount,
    contentLoader,
    isDirty,
  } = state

  const [anchorEl, setAnchorEl] = useState(null)
  const [feeTab, setFeeTab] = useState('general')
  const [selectedEditValues, setSelectedEditValues] = useState([])
  const [monthlyRevenue, setMonthlyRevenue] = useState('')
  const [getServiceList] = useLazyGetPricingServiceListQuery()
  const [getPricingMetaFilters] = useLazyGetPricingMetaFiltersQuery()

  const formatFilters = filters => handleCommonTableformatFilters(filters)

  const formatSortBy = (newSortBy = allSortBy) => handleCommonTableformatSortBy(newSortBy)

  const getPricingTableData = (requestedPage, requestedPageSize, tab, searchFilters = [], sortRules = []) => {
    setState({ contentLoader: true })
    const payload = {
      requestedPage,
      requestedPageSize,
    }
    if (searchFilters.length) {
      set(payload, 'searchFilters', searchFilters)
    }

    set(payload, 'sortRules', sortRules)

    checkedVal[tabValue] = {}
    changePrice[tabValue] = false
    ;['services', 'taxes', 'general', 'disposal', SERVICE_GROUPS_TAB_ID].forEach(data => {
      delete checkedVal[data]
      delete changePrice[data]
      delete summary[data]
    })
    if (tab === 'services') {
      getServiceList(payload)
        .unwrap()
        .then(data => {
          const { totalItems, totalPages, services } = data
          setState({
            allTableRows: services,
            totalTableRowsCount: totalItems,
            // If no pages then set default to first page
            totalPageCount: totalPages || FIRST_PAGE_INDEX,
            isOpenTableFilters: null,
            contentLoader: false,
          })
        })
    } else {
      set(payload, 'tab', tab === 'fees' ? feeTab : tab)
      dispatch(
        getPricingTableContent(payload, (tableRows, totalItems, totalPages) =>
          setState({
            allTableRows: tableRows,
            totalTableRowsCount: totalItems || tableRows.length,
            // If no pages then set default to first page
            totalPageCount: totalPages || FIRST_PAGE_INDEX,
            isOpenTableFilters: null,
            contentLoader: false,
          })
        )
      )
    }
  }

  const getServicesColumns = () => {
    return isRentalFeeEnabled ? SERVICES : SERVICES.filter(service => service.columnName !== 'rentalFeeCount')
  }

  useEffect(() => {
    const servicesConfig = getServicesColumns()
    setConfigdata(servicesConfig)

    getPricingTableData(page, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())

    setState({
      configuratorColumns: servicesConfig,
      pastConfiguratorColumns: servicesConfig,
    })

    // Get pricing filter meta
    getPricingMetaFilters()
      .unwrap()
      .then(d => {
        dispatch(pricingMeta({ data: d }))
      })
      .catch(handleError)
  }, [])

  const resetAllChecked = () => {
    Object.keys(allCheckedVal).forEach(key => {
      allCheckedVal[key] = false
    })
    setState({ allCheckedVal })
  }

  // Page change handler
  const handlePageChange = newPage => {
    resetAllChecked()
    setState({ page: newPage })

    getPricingTableData(newPage, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())

    changePrice[tabValue] = false

    document.getElementsByClassName('pricing-table')[0].scrollTop = 0
  }

  // Rows per page change handler
  const handleRowsPerPageChange = event => {
    resetAllChecked()
    setState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
    getPricingTableData(INITIAL_PAGE, event.target.value, tabValue, formatFilters(allFilters), formatSortBy())
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFeeTab = type => {
    resetAllChecked()
    setFeeTab(type)

    setState({
      rowsPerPage: ROWS_PER_PAGE,
      page: INITIAL_PAGE,
      allFilters: { ...ACTIVE_STATUS_FILTER },
      activeFilter: '',
      allSortBy: {},
      configuratorColumns: cloneDeep(GENERAL_FEES),
      pastConfiguratorColumns: cloneDeep(GENERAL_FEES),
    })

    getPricingTableData(INITIAL_PAGE, ROWS_PER_PAGE, type, formatFilters({ ...ACTIVE_STATUS_FILTER }), formatSortBy())

    if (type === 'general') {
      setConfigdata(GENERAL_FEES)
    } else {
      setConfigdata(DISPOSAL_FEES)

      setState({
        configuratorColumns: cloneDeep(DISPOSAL_FEES),
        pastConfiguratorColumns: cloneDeep(DISPOSAL_FEES),
      })
    }
  }

  const getActiveTabConfigurator = tab => {
    if (tab === 'services') {
      return getServicesColumns()
    }

    if (tab === 'taxes') {
      return TAXES
    }

    if (tab === 'fees' && feeTab === 'general') {
      return GENERAL_FEES
    }

    if (tab === 'fees' && feeTab === 'disposal') {
      return DISPOSAL_FEES
    }

    return []
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    resetAllChecked()
    const activeConf = getActiveTabConfigurator(newValue)
    setState({
      page: INITIAL_PAGE,
      rowsPerPage: ROWS_PER_PAGE,
      allTableRows: [],
      allFilters: { ...ACTIVE_STATUS_FILTER },
      activeFilter: '',
      allSortBy: {},
      configuratorColumns: cloneDeep(activeConf),
      pastConfiguratorColumns: cloneDeep(activeConf),
    })

    if (newValue !== 'fees') {
      getPricingTableData(INITIAL_PAGE, ROWS_PER_PAGE, newValue, formatFilters({ ...ACTIVE_STATUS_FILTER }), formatSortBy())
    }

    switch (newValue) {
      case 'services':
        setConfigdata(getServicesColumns())
        return

      case 'taxes':
        setConfigdata(TAXES)
        return

      case SERVICE_GROUPS_TAB_ID:
        // column configurator currently not supported
        setConfigdata([])
        return

      case 'fees':
        handleFeeTab(feeTab)
        break
      default:
    }
  }

  const setSummary = (tab, min, max, average, locationCount, revenue) => {
    let summaryData = {}

    if (summary[tab] === undefined) {
      summary[tab] = {}
    }

    summaryData = {
      min,
      max,
      avg: average,
      locations: locationCount,
      revenue,
    }

    summary[tab] = summaryData
    setState({ ...state, summary })
  }

  const handleSummaryVal = payload => {
    const tab = tabValue === 'fees' ? feeTab : tabValue
    payload.tab = tab

    dispatch(
      getPricingSummary(payload, (min, max, average, locationCount, revenue) => {
        setSummary(tab, min, max, average, locationCount, revenue)
      })
    )
  }

  const handleEditValues = tab => {
    const ids = Object.entries(checkedVal[tab]).map(([key, value]) => (value ? key : null))
    const uniqueIds = compact(ids)

    const payload = {
      id: uniqueIds,
      tab: tab === 'fees' ? feeTab : tab,
    }

    setSelectedEditValues(allTableRows.filter(data => uniqueIds.includes(data.id)))

    if (tab !== 'taxes') {
      dispatch(
        getPricingRevenue(payload, res => {
          setMonthlyRevenue(get(res, 'data.monthlyRevenue', ''))
        })
      )
    }
  }

  const handleCheckedIds = values => {
    const ids = []
    const allIds = Object.entries(values).map(([key, value]) => (value ? key : null))

    const uniqueIds = compact(allIds)

    Children.toArray(
      uniqueIds.map(id => {
        ids.push({ id })
      })
    )
    return ids
  }

  const handleAllChecked = (serviceList, event, tab) => {
    if (checkedVal[tab] === undefined) {
      checkedVal[tab] = {}
    }

    serviceList &&
      Children.toArray(
        serviceList.map((service, index) => {
          checkedVal[tab][service.id] = event.target.checked
        })
      )

    const ids = handleCheckedIds(checkedVal[tab])

    if (allCheckedVal[tab] === undefined || ids.length === serviceList.length) {
      allCheckedVal[tab] = true
    } else {
      allCheckedVal[tab] = !allCheckedVal[tab]
    }

    const payload = {}

    if (event.target.checked) {
      payload.all = true
    } else {
      payload.ids = []
    }

    handleSummaryVal(payload)

    changePrice[tab] = allCheckedVal[tab]

    setState({ ...state, checkedVal })
    setState({ allCheckedVal })

    handleEditValues(tab)
  }

  const handleSingleChecked = (serviceList, event, tab, serviceId) => {
    if (checkedVal[tab] === undefined) {
      checkedVal[tab] = {}
    }

    if (allCheckedVal[tab] && !event.target.checked) {
      allCheckedVal[tab] = false
      setState({ allCheckedVal })
    }

    checkedVal[tab][serviceId] = event.target.checked

    const ids = handleCheckedIds(checkedVal[tab])

    const payload = {
      ids,
      all: false,
    }

    handleSummaryVal(payload)

    changePrice[tab] = Object.values(checkedVal[tab]).includes(true)

    handleEditValues(tab)
  }

  const handleChangePriceDelete = service => {
    const data = selectedEditValues.filter(val => val !== service)
    setSelectedEditValues(data)
    setState({ isDirty: true })
  }

  const onHandleEditFieldsChange = (e, service, type) => {
    const editValues = cloneDeep(selectedEditValues)

    let valueType = 'taxValueType'
    if (feeTab === 'general' && tabValue === 'fees') {
      valueType = 'valueType'
    }
    const editValue = editValues.find(data => data.id === service.id)
    if (['%', '$'].includes(e)) {
      editValue[valueType] = e
      if (valueType === 'taxValueType') {
        editValue.taxValue =
          editValue?.taxValueType === '$' ? truncateDecimals(Number.parseFloat(editValue.taxValue), 2) : editValue.taxValue
      }
    } else if (e === 'subItem') {
      const { subItem } = editValue
      editValue.subItem = !subItem
    } else if (e === 'costPlus') {
      const { costPlus } = editValue
      editValue.costPlus = !costPlus
    } else {
      let { name, value } = e.target
      setState({ isDirty: true })

      if (['value', 'taxValue'].includes(name)) {
        value = value.replaceAll(',', '')
      }
      editValue[name] = value
    }

    if (e.target !== undefined && e.target.value === ' ') return

    setSelectedEditValues(editValues)
  }

  const handleTablesData = () => {
    getPricingTableData(page, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())
  }

  const handleActivation = (status, service) => {
    if (!updatePricingManager) return

    let tab = tabValue
    if (tabValue === 'fees') {
      tab = feeTab
    } else if (tabValue === 'taxes') {
      tab = 'tax'
    }

    const payload = {
      id: service.id,
      status,
      type: tab,
    }

    dispatch(
      getPricingStatusChange(payload, res => {
        if (res.status === 200) {
          handleTablesData()
        }
      })
    )
  }

  const handleFilterChange = (key, value, columnName) => setState(handleFilterInputChange(key, value, columnName, allFilters))

  const handleFilterApply = () => {
    // Scroll to the top of the table
    handleScrollToTop('pricing-table')
    setState({ page: INITIAL_PAGE })

    getPricingTableData(page, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())
  }

  const handleFilterClose = () => {
    delete allFilters[activeFilter]
    setState({ isOpenTableFilters: null, allFilters })
    handleFilterApply()
  }

  const handleTableFilterChange = (openEvent, filterType, columnName, subType, label, isSingleSelect, isBoolean) =>
    setState(handleCommonTableFilterChange({ openEvent, allFilters, filterType, columnName, subType, label, isSingleSelect, isBoolean }))

  const handleSortByChange = (columnName, type) => {
    const newSort = handleCommonTableSortingChange(allSortBy, columnName, type)
    setState({ allSortBy: newSort, page: INITIAL_PAGE })
    getPricingTableData(page, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy(newSort))
  }

  const handleSingleChange = (key, value) => {
    setState({ [key]: value })
  }

  const handleExport = ({ sendColumns = false }) => {
    const payload = {
      type: 'excel',
      requestedPage: page,
      requestedPageSize: rowsPerPage,
      searchFilters: formatFilters(allFilters),
      sortRules: formatSortBy(),
      fileName: 'pricing',
      isDownload: true,
      buffer: true,
      urlToMapWith: `pricingExport${tabValue === 'fees' ? feeTab : tabValue}`,
    }

    if (sendColumns) {
      set(
        payload,
        'columnList',
        configuratorColumns.filter(rec => rec.checked).map(data => data.label)
      )
    }

    dispatch(initiateStatusDownload(payload))
  }

  useEffect(() => {
    handleTabChange(null, defaultTabValue)
  }, [defaultTabValue])

  return (
    <TabContext value={tabValue}>
      <Box width="100%" overflow="hidden" className="pricing-container">
        {contentLoader && <Loader />}

        <TabPanel value="services" className="services-panel">
          <ServicePanel
            allCheckedVal={allCheckedVal}
            allFilters={allFilters}
            allSortBy={allSortBy}
            anchorEl={anchorEl}
            changePrice={changePrice}
            checkedVal={checkedVal}
            columns={pastConfiguratorColumns.filter(column => column.checked)}
            confServices={configuratorColumns}
            configData={configData}
            feeTab={feeTab}
            handleActivation={handleActivation}
            handleAllChecked={handleAllChecked}
            handleChangePriceDelete={handleChangePriceDelete}
            handleClose={handleClose}
            handleExport={handleExport}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleSingleChange={handleSingleChange}
            handleSingleChecked={handleSingleChecked}
            isDirty={isDirty}
            lockedColumns={pastConfiguratorColumns.filter(column => column.locked && column.checked)}
            monthlyRevenue={monthlyRevenue}
            onHandleEditFieldsChange={onHandleEditFieldsChange}
            onSortByChange={handleSortByChange}
            onTableFilterChange={handleTableFilterChange}
            onUpdatePricingService={handleTablesData}
            page={page}
            pricingServiceList={allTableRows}
            rowsPerPage={rowsPerPage}
            saveConfSettings={saveConfSettings}
            selectedEditValues={selectedEditValues}
            setAnchorEl={setAnchorEl}
            setSelectedEditValues={setSelectedEditValues}
            setState={setState}
            summary={summary}
            tabValue={tabValue}
            totalPageCount={totalPageCount}
            totalServiceCount={totalTableRowsCount}
            getPricingServicesResults={(currentPage, currentRowsPerPage) =>
              getPricingTableData(currentPage, currentRowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())
            }
          />
        </TabPanel>

        <TabPanel value="fees" className="fees-panel">
          <FeesPanel
            allCheckedVal={allCheckedVal}
            allFilters={allFilters}
            allSortBy={allSortBy}
            anchorEl={anchorEl}
            changePrice={changePrice}
            checkedVal={checkedVal}
            columns={pastConfiguratorColumns.filter(column => column.checked)}
            confServices={configuratorColumns}
            configData={configData}
            feeServiceList={allTableRows}
            feeTab={feeTab}
            handleActivation={handleActivation}
            handleAllChecked={handleAllChecked}
            handleChangePriceDelete={handleChangePriceDelete}
            handleClose={handleClose}
            handleExport={handleExport}
            handleFeeTab={handleFeeTab}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleSingleChange={handleSingleChange}
            handleSingleChecked={handleSingleChecked}
            handleTabChange={handleTabChange}
            isDirty={isDirty}
            lockedColumns={pastConfiguratorColumns.filter(column => column.locked && column.checked)}
            monthlyRevenue={monthlyRevenue}
            onHandleEditFieldsChange={onHandleEditFieldsChange}
            onSortByChange={handleSortByChange}
            onTableFilterChange={handleTableFilterChange}
            page={page}
            rowsPerPage={rowsPerPage}
            saveConfSettings={saveConfSettings}
            selectedEditValues={selectedEditValues}
            setAnchorEl={setAnchorEl}
            setSelectedEditValues={setSelectedEditValues}
            setState={setState}
            summary={summary}
            tabValue={tabValue}
            totalFeeCount={totalTableRowsCount}
            totalFeePageCount={totalPageCount}
            // We can also use page, rowsPerPage params but child component is sending it, so using the same
            getFeesServicesResults={(currentPage, currentRowsPerPage, currentFeeTab) =>
              getPricingTableData(currentPage, currentRowsPerPage, currentFeeTab, formatFilters(allFilters), formatSortBy())
            }
          />
        </TabPanel>

        <TabPanel value="taxes" className="taxes-panel">
          <TaxesPanel
            allCheckedVal={allCheckedVal}
            allFilters={allFilters}
            allSortBy={allSortBy}
            anchorEl={anchorEl}
            changePrice={changePrice}
            checkedVal={checkedVal}
            columns={pastConfiguratorColumns.filter(column => column.checked)}
            confServices={configuratorColumns}
            configData={configData}
            feeTab={feeTab}
            handleActivation={handleActivation}
            handleAllChecked={handleAllChecked}
            handleChangePriceDelete={handleChangePriceDelete}
            handleCheckBoxClass={handleCheckBoxClass}
            handleClass={handleClass}
            handleClose={handleClose}
            handleExport={handleExport}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleSingleChange={handleSingleChange}
            handleSingleChecked={handleSingleChecked}
            handleTabChange={handleTabChange}
            isDirty={isDirty}
            lockedColumns={pastConfiguratorColumns.filter(column => column.locked && column.checked)}
            onHandleEditFieldsChange={onHandleEditFieldsChange}
            onSortByChange={handleSortByChange}
            onTableFilterChange={handleTableFilterChange}
            page={page}
            rowsPerPage={rowsPerPage}
            saveConfSettings={saveConfSettings}
            selectedEditValues={selectedEditValues}
            setAnchorEl={setAnchorEl}
            setSelectedEditValues={setSelectedEditValues}
            setState={setState}
            summary={summary}
            tabValue={tabValue}
            taxServiceList={allTableRows}
            totalTaxCount={totalTableRowsCount}
            totalTaxPageCount={totalPageCount}
            getTaxServicesResults={(currentPage, currentRowsPerPage) =>
              getPricingTableData(currentPage, currentRowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())
            }
          />
        </TabPanel>

        <TabPanel value={SERVICE_GROUPS_TAB_ID} className="templates-panel">
          <ServiceGroups
            allTableRows={allTableRows}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPageCount={totalPageCount}
            totalTableRowsCount={totalTableRowsCount}
            onReloadMainTable={() => getPricingTableData(page, rowsPerPage, tabValue, formatFilters(allFilters), formatSortBy())}
          />
        </TabPanel>

        <CommonTableFilters
          isOpen={isOpenTableFilters}
          filterType={get(allFilters, `${activeFilter}.filterType`)}
          subType={get(allFilters, `${activeFilter}.subType`)}
          operation={get(allFilters, `${activeFilter}.operation`)}
          columnName={get(allFilters, `${activeFilter}.columnName`)}
          columnValue={get(allFilters, `${activeFilter}.columnValue`)}
          label={get(allFilters, `${activeFilter}.label`)}
          isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
          isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
          filterComponent="pricing"
          // key we can also take from activeFilter.columnName
          onFilterChange={handleFilterChange}
          onApply={handleFilterApply}
          // reset filter to past state on cancel
          onClose={() => handleFilterClose()}
        />

        <Popover
          open={Boolean(isOpenConfigurator)}
          anchorEl={isOpenConfigurator}
          onClose={() => setState({ isOpenConfigurator: null })}
          className="configurator-container"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Configurator
            configData={configuratorColumns}
            handleOnDragEnd={result =>
              setState({ configuratorColumns: handleConfiguratorDragEnd({ result, columns: configuratorColumns }) })
            }
            handleCancel={() => setState({ isOpenConfigurator: null, configuratorColumns: pastConfiguratorColumns })}
            handleConfChange={(index, type, confData) =>
              setState({ configuratorColumns: handleConfigurationChange({ index, type, confData }) })
            }
            saveConfigSettings={() => {
              setState({ isOpenConfigurator: null, pastConfiguratorColumns: configuratorColumns })
            }}
          />
        </Popover>
      </Box>
    </TabContext>
  )
}

Pricing.propTypes = {
  defaultTabValue: PropTypes.string,
}

export default Pricing
