import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, DialogContent, useTheme } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { HHAlert } from 'components/common/HHAlert'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'
import { useLazyGetPaymentGroupListQuery } from 'api/groups/payment/getPaymentGroupList'
import { useAddPaymentBatchesToGroupMutation } from 'api/groups/payment/addPaymentBatchesToGroup'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import AddEditPaymentGroupDialog from './AddEditPaymentGroupDialog'

const AddToGroupDialog = ({ isOpen = false, batchIds = [], onClose }) => {
  const theme = useTheme()
  const [isOpenAddEditPaymentGroupDialog, setIsOpenAddEditPaymentGroupDialog] = useState(false)
  const [fetchPaymentGroupList, { data: paymentGroupList = [] }] = useLazyGetPaymentGroupListQuery()
  const [addPaymentBatchesToGroup, { isLoading: isAddToGroupLoading }] = useAddPaymentBatchesToGroupMutation()
  const addToGroupForm = useForm({ defaultValues: { paymentGroupId: '' } })

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = addToGroupForm

  const paymentGroupIdHelperText = get(errors, 'paymentGroupId.message')
  const sortedGroups = sortByDateSelector({ data: paymentGroupList, key: 'createdAt' })

  const handleCreateNewGroup = () => setIsOpenAddEditPaymentGroupDialog(true)

  const handleCreateNewGroupSuccess = id => setValue('paymentGroupId', id)

  const handleCloseCreateNewGroup = () => setIsOpenAddEditPaymentGroupDialog(false)

  const onSubmitHandler = data => {
    const payload = { id: data?.paymentGroupId, batchIds }
    addPaymentBatchesToGroup(payload)
      .unwrap()
      .then(() => {
        toast.success(T.ADD_PAYMENTS_TO_GROUP_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      fetchPaymentGroupList()
    }
  }, [isOpen])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <HHDialogTitle title={T.ADD_TO_GROUP} onClose={onClose} />
        <DialogContent>
          <FormProvider {...addToGroupForm}>
            <Box mt={2}>
              <HHFormTextField
                error={paymentGroupIdHelperText}
                helperText={paymentGroupIdHelperText}
                control={control}
                required
                rules={{ required: T.CANNOT_BE_EMPTY }}
                name="paymentGroupId"
                label={T.PAYMENT_GROUPS}
                placeholder={T.SELECT_GROUP}
                fullWidth
                deprecatedLabel={false}
                select
              >
                {sortedGroups.map(({ id, groupName }) => (
                  <SelectItem value={id}>{groupName}</SelectItem>
                ))}
              </HHFormTextField>

              {!isOpenAddEditPaymentGroupDialog && watch('paymentGroupId') && (
                <HHAlert sx={{ mt: 2 }} borderColor={theme.palette.info.light} severity="info">
                  Any payments selected that are a part of a batch of payments will also be added to the group
                </HHAlert>
              )}
            </Box>
          </FormProvider>
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <Box sx={{ flex: '1 1 auto' }} />
          <Button size="small" variant="outlined" onClick={handleCreateNewGroup}>
            {T.CREATE_NEW_GROUP}
          </Button>
          <LoadingButton loading={isAddToGroupLoading} size="small" variant="contained" onClick={handleSubmit(onSubmitHandler)}>
            {T.ADD}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
      <AddEditPaymentGroupDialog
        isOpen={isOpenAddEditPaymentGroupDialog}
        onSuccess={handleCreateNewGroupSuccess}
        onClose={handleCloseCreateNewGroup}
      />
    </>
  )
}

AddToGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  batchIds: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default AddToGroupDialog
