import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    inviteUserToCustomerPortal: build.mutation({
      query: ({ accountId, payload }) => ({
        method: 'POST',
        url: `/api/v1/core/accounts/${accountId}/portal-users/invitation-email`,
        body: payload,
      }),
    }),
    bulkInviteUserToCustomerPortalByAccountIdAndEmail: build.mutation({
      query: body => ({
        method: 'POST',
        url: `/api/v1/core/accounts/bulk/portal-users/invitation-email`,
        body,
      }),
    }),

    bulkInviteUserToCustomerPortalByAccountId: build.mutation({
      query: body => ({
        method: 'POST',
        url: `/api/v1/core/accounts/contacts/async/bulk:portal-invitation`,
        body,
      }),
    }),
  }),
})

export const {
  useInviteUserToCustomerPortalMutation,
  useBulkInviteUserToCustomerPortalByAccountIdAndEmailMutation,
  useBulkInviteUserToCustomerPortalByAccountIdMutation,
} = extendedApi
