import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { useTheme } from '@mui/material'
import { HHAlert } from 'components/common/HHAlert'

const DataGrid = ({
  isSelectable = false,
  rows = [],
  selectionModel = [],
  onSelectionModelChange = noop,
  RenderCell,
  noRowsText = '',
  slots,
  sx = {},
  ...rest
}) => {
  const theme = useTheme()

  const getRowId = useCallback(({ id }) => id, [])

  const getRowHeight = useCallback(() => 'auto', [])

  const getIsRowSelectable = useCallback(() => isSelectable, [isSelectable])

  return (
    <DataGridPro
      columnHeaderHeight={0}
      hideFooter
      rows={rows}
      getRowId={getRowId}
      columns={[
        {
          field: 'id',
          flex: 1,
          resizable: false,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: params => <RenderCell {...params} isSelectable={isSelectable} />,
        },
      ]}
      getRowHeight={getRowHeight}
      isRowSelectable={getIsRowSelectable}
      checkboxSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      slots={{
        noRowsOverlay: () =>
          noRowsText ? (
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              {noRowsText}
            </HHAlert>
          ) : null,
        ...slots,
      }}
      sx={{
        border: 0,
        borderRadius: 0,
        '& .MuiDataGrid-columnHeaders': {
          border: 0,
          padding: 0,
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
          border: 0,
        },
        '& .MuiDataGrid-virtualScroller': {
          overflowX: 'hidden',
        },
        ...(!isSelectable && { '& .MuiDataGrid-row': { background: theme.palette.action.hover } }),
        ...sx,
      }}
      {...rest}
    />
  )
}

DataGrid.propTypes = {
  isSelectable: PropTypes.bool,
  rows: PropTypes.array,
  selectionModel: PropTypes.array,
  onSelectionModelChange: PropTypes.func,
  RenderCell: PropTypes.node,
  noRowsText: PropTypes.string,
  slots: PropTypes.object,
  sx: PropTypes.object,
}

export default DataGrid
