import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

import AutoComplete from 'components/common/AutoComplete'
import PricingSwitch from 'components/pricing/PricingSwitch'
import MeasureMaterialAutoComplete from 'components/pricing/MeasureMaterialAutoComplete'
import ActionExceptionAutoComplete from 'components/pricing/ActionExceptionAutoComplete'
import GeneralLedgerAutoComplete from 'components/pricing/GeneralLedgerAutoComplete'
import { getPricingLabel } from 'components/pricing/pricingMethods'

import T from 'T'

import { get } from 'utils/lodash'
import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'

import Constants from 'Constants'
import { DOLLAR_DECIMAL_DIGITS, PERCENTAGE_DECIMAL_DIGITS } from 'settings/constants/pricing'
import { Button, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { HHTextField } from 'components/form-fields/v5'
import LinkedServicesCard from '../LinkedServicesCard'
import { generateTaxName } from '../../../utils/pricing'

const { MEDIA_SERVER } = Constants

const TaxFormScreen = ({
  matchedServices,
  globalFilterOptions,
  handleCheckboxValue,
  checkedValues,
  handleSwitchVal,
  businessLine,
  handlePriceLocked,
  onHandleChange,
  inputTaxText,
  backFlag,
  onValueTypeChange,
  onClickLinkedServicesCard,
}) => {
  const getTaxName = () => {
    return inputTaxText.taxName
  }

  const getPriceValue = () => {
    return inputTaxText.value
  }

  const {
    checkedMaterials,
    checkedMeasure,
    checkedPricingPeriod,
    checkedTaxZones,
    checkedServiceAction,
    checkedExceptions,
    checkedGeneralLedger,
  } = (() => {
    return {
      checkedBusinessLines: get(checkedValues, 'businessLine', []),
      checkedMethods: get(checkedValues, 'methods', []),
      checkedMaterials: get(checkedValues, 'materials', []),
      checkedMeasure: get(checkedValues, 'measures', []),
      checkedPricingPeriod: get(checkedValues, 'pricingPeriod', []),
      checkedPricingZones: get(checkedValues, 'pricingZones', []),
      checkedServiceAction: get(checkedValues, 'actions', []),
      checkedExceptions: get(checkedValues, 'exceptions', []),
      checkedTaxZones: get(checkedValues, 'taxZones', []),
      checkedGeneralLedger: get(checkedValues, 'generalLedgerAccount', []),
    }
  })()

  useEffect(() => {
    if (inputTaxText.isNameDirty) return
    const name = generateTaxName({
      businessLine: get(businessLine, '[0]', ''),
      taxZone: get(checkedTaxZones, '[0].label', ''),
      measure: get(checkedMeasure, '[0].label', ''),
      material: get(checkedMaterials, '[0].label', ''),
    })
    onHandleChange({ target: { name: 'taxName', value: name } })
  }, [checkedTaxZones?.length, checkedMeasure?.length, checkedMaterials?.length, businessLine])

  return (
    <Stack flexDirection="row" className="grid-container new-tax-form">
      <div className="left-section">
        <ActionExceptionAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedServiceAction={checkedServiceAction}
          checkedExceptions={checkedExceptions}
          backFlag={backFlag}
        />

        <div className="value left-elements-width mt15">
          <div className="service-label">Value</div>

          <div className="price-fields">
            <Button
              variant="outlined"
              className={`dollar-btn ${inputTaxText.valueType === '$' ? 'value-type-active' : ''}`}
              onClick={() => onValueTypeChange('$')}
            >
              $
            </Button>

            <Button
              variant="outlined"
              className={`percent-btn ml10 ${inputTaxText.valueType === '%' ? 'value-type-active' : ''}`}
              onClick={() => onValueTypeChange('%')}
            >
              %
            </Button>

            <NumericFormat
              id="outlined-size-small"
              className="price-field ml10"
              placeholder="0.00"
              value={getPriceValue()}
              variant="outlined"
              autoComplete="off"
              name="value"
              size="medium"
              defaultSize
              onChange={e => onHandleChange(e)}
              customInput={HHTextField}
              thousandSeparator
              decimalSeparator="."
              decimalScale={inputTaxText.valueType === '%' ? PERCENTAGE_DECIMAL_DIGITS : DOLLAR_DECIMAL_DIGITS}
            />
          </div>
        </div>

        <div className="pricing-period left-elements-width mt15">
          <div className="service-label">{T.PRICING_PERIOD}</div>

          <AutoComplete
            multiple={false}
            isHandleCheckbox
            maxHeight={150}
            metaData={get(globalFilterOptions, 'pricingPeriod', [])}
            label={T.PRICING_PERIOD}
            getByLabelText={option => getPricingLabel(option)}
            handleCheckboxValue={handleCheckboxValue}
            checkedRecords={checkedPricingPeriod}
            getPlaceholder={checkedPricingPeriod.length ? '' : `Select ${T.PRICING_PERIOD}`}
          />
        </div>

        <div className="unlocked-price left-elements-width" onClick={() => handlePriceLocked()}>
          <div className="lock-img">
            <img src={!inputTaxText.priceLocked ? `${MEDIA_SERVER}Unlock.svg` : `${MEDIA_SERVER}Locked.svg`} alt="lock" height="20" />
          </div>

          <div className="unlock-label">{T.UNLOCKED_PRICE}</div>
        </div>
      </div>

      <div className="vertical-line" />

      <Box ml={2} className="right-section">
        <div className="service">
          <div className="service-label">
            {T.TAX}
            <span className="red ml1">*</span>
          </div>
          <HHTextField
            placeholder={T.TAX}
            value={getTaxName()}
            name="taxName"
            variant="outlined"
            autoFocus
            defaultSize
            autoComplete="off"
            fullWidth
            InputProps={{
              onChange: e => {
                onHandleChange(e)
              },
            }}
          />
        </div>

        <div className="prizing_zones mt15">
          <div className="service-label">
            {`${T.TAX_ZONE}s`}
            <span className="red ml1">*</span>
          </div>

          <AutoComplete
            backFlag={backFlag}
            isHandleCheckbox
            fullWidth
            metaData={get(globalFilterOptions, 'taxZones', [])}
            label={`${T.TAX_ZONE}s`}
            getByLabelText={option => option.zoneName}
            handleCheckboxValue={handleCheckboxValue}
            checkedRecords={checkedTaxZones}
            getPlaceholder={checkedTaxZones.length ? '' : `Select ${T.TAX_ZONE}`}
          />
        </div>

        <MeasureMaterialAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMeasure={checkedMeasure}
          checkedMaterials={checkedMaterials}
          backFlag={backFlag}
        />

        <GeneralLedgerAutoComplete checkedValues={checkedGeneralLedger} handleCheckboxValue={handleCheckboxValue} backFlag={backFlag} />

        <div className="switch-section">
          <div className="auto-link">
            <div className="switch">
              <PricingSwitch
                checked={inputTaxText.taxScopeAutoLink}
                handleSwitchVal={() => handleSwitchVal('AutoLink', !inputTaxText.taxScopeAutoLink)}
              />
            </div>
            <div className="switch-label">Auto-Link to Matched Services & Fees </div>
          </div>
        </div>
        <LinkedServicesCard
          fullWidth
          services={inputTaxText.taxScopeAutoLink ? matchedServices.length : 0}
          onClick={inputTaxText.taxScopeAutoLink ? onClickLinkedServicesCard : () => {}}
        />
      </Box>
    </Stack>
  )
}

TaxFormScreen.defaultProps = {
  taxInput: '',
  globalFilterOptions: {},
  inputTaxText: {},
  checkedValues: {},
  recurCounter: 1,
  backFlag: false,
  handleCheckboxValue: null,
  handleSwitchVal: null,
  handlePriceLocked: null,
  onHandleChange: null,
  onValueTypeChange: null,
}

TaxFormScreen.propTypes = {
  matchedServices: PropTypes.arrayOf(PropTypes.object),
  taxInput: PropTypes.string,
  globalFilterOptions: PropTypes.object,
  inputTaxText: PropTypes.object,
  checkedValues: PropTypes.object,
  backFlag: PropTypes.bool,
  handleCheckboxValue: PropTypes.func,
  handleSwitchVal: PropTypes.func,
  handlePriceLocked: PropTypes.func,
  onHandleChange: PropTypes.func,
  onValueTypeChange: PropTypes.func,
  onClickLinkedServicesCard: PropTypes.func,
  businessLine: PropTypes.array,
}

export default TaxFormScreen
