import React from 'react'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

const RenderSuspensionTypeFilterOption = (props, option, { selected }) => {
  const { label } = option
  return (
    <ListItemButton dense disableGutters {...props}>
      <ListItemIcon
        sx={{
          minWidth: 0,
        }}
      >
        <Checkbox checked={selected} />
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }} />
    </ListItemButton>
  )
}

RenderSuspensionTypeFilterOption.propTypes = {}

export default RenderSuspensionTypeFilterOption
