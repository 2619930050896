import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  selectBulkMoveFetchId,
  selectBulkMoveFromRows,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveRouteId,
  selectBulkMoveStopsToBeAssignedCount,
  selectBulkMoveToSerializedDate,
  selectBulkSelectedMoveFromRows,
  selectBulkSelectedMoveToRows,
  setBulkMoveBetweenDaysState,
  setSelectedMoveFromRows,
} from 'slices/route/bulkMoveBetweenDaysSlice'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import RenderMoveFromHeader from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/RenderMoveFromHeader'
import CommonMoveFromRouteColumnBetweenDays from 'components/route-manager/BulkActions/common/BetweenDays/CommonMoveFromColumnBetweenDays'

const MoveFromRouteColumn = ({ apiRef, fetchAndFormatGeojsonData }) => {
  const moveFromRows = useSelector(selectBulkMoveFromRows)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const selectedMoveToRows = useSelector(selectBulkSelectedMoveToRows)
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const routeId = useSelector(selectBulkMoveRouteId)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const fetchId = useSelector(selectBulkMoveFetchId)

  const isRowSelectable = params => {
    if (!fromFEDate || !toFEDate) return false
    return params.row.date === fromFEDate
  }

  return (
    <CommonMoveFromRouteColumnBetweenDays
      apiRef={apiRef}
      moveFromRows={moveFromRows}
      fetchAndFormatGeojsonData={fetchAndFormatGeojsonData}
      fromSerializedDate={fromSerializedDate}
      setSelectedMoveFromRows={setSelectedMoveFromRows}
      toSerializedDate={toSerializedDate}
      fetchId={fetchId}
      routeId={routeId}
      selectedMoveFromRows={selectedMoveFromRows}
      selectedMoveToRows={selectedMoveToRows}
      stopsToBeAssignedCount={stopsToBeAssignedCount}
      isRowSelectable={isRowSelectable}
      bulkReduxAction={setBulkMoveBetweenDaysState}
      RenderMoveFromHeader={RenderMoveFromHeader}
    />
  )
}

MoveFromRouteColumn.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  fetchAndFormatGeojsonData: PropTypes.func.isRequired,
}

export default MoveFromRouteColumn
