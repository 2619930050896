import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    deleteInvoices: build.mutation({
      query: body => ({ url: '/api/v1/billing/invoice/delete', method: 'POST', body }),
    }),
    deleteUnpaidInvoice: build.mutation({
      query: ({ id }) => ({ url: `/api/v1/billing/invoice/${id}`, method: 'DELETE' }),
    }),
  }),
})

export const { useDeleteInvoicesMutation, useDeleteUnpaidInvoiceMutation } = extendedApi
