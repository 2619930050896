import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web/build/player/lottie_light'
import { Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { Z_INDEX } from 'theme/zIndex'
import hhloader from './hh_loader.json'

const { LOADER } = Z_INDEX

const Loader = ({ left = 0, sx }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    const loaderInstance = lottie.loadAnimation({
      container: document.getElementById('hh_loader_container'),
      animationData: hhloader,
    })
    return () => {
      loaderInstance.destroy()
      document.body.style.overflow = 'visible'
    }
  }, [])

  const theme = useTheme()
  return (
    <Box
      backgroundColor={`${theme.palette.background.default}bd`}
      width="100vw"
      height="100vh"
      minWidth="100vw"
      minHeight="100vh"
      position="fixed"
      zIndex={LOADER}
      display="flex"
      alignItems="center"
      justifyContent="center"
      top={10}
      left={left}
      id="hh_loader_container"
      sx={{ '& svg': { width: '194px!important', height: '194px!important', position: 'absolute' }, ...sx }}
    />
  )
}

Loader.propTypes = {
  left: PropTypes.number,
  sx: PropTypes.object,
}

export default Loader
