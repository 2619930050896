/* eslint-disable no-param-reassign */
import { get } from 'lodash'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { createDataGridSlice } from '../generic/createDataGridSlice'
import { INVOICE_PAGINATION } from '../../settings/constants/pagination'

const { INITIAL_PAGE, ROWS_PER_PAGE } = INVOICE_PAGINATION

const getInitialState = () => ({
  pagination: {
    page: INITIAL_PAGE,
    pageSize: ROWS_PER_PAGE,
  },
  sorting: {
    sortModel: [],
  },
  columns: {
    columnVisibilityModel: {},
  },
  selectionModel: [],
  createdInvoicesData: {
    customerBillingProfile: null,
    invoiceDate: null,
    billingPeriod: {
      startDate: '',
      endDate: '',
      invoiceDate: '',
    },
  },
  selectedBillingProfileDetails: {},
  selectedBillingProfile: '',
  selectedBillingPeriod: null,
  selectedBillingPeriodDetails: {},
  preview: {
    previewPath: undefined,
    previewDocumentId: undefined,
    remoteRowCount: 0,
    prefilledDocIdList: [],
  },
  previewChargeDrawer: {
    docIdToChargeMap: {},
    docIdList: [],
  },
  filters: {
    allFilters: [],
    isOpenTableFilters: false,
    searchFilters: {},
    sortRules: [],
    refetchFlag: false,
    queryParams: {},
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
})

export const createInvoiceSlice = createDataGridSlice({
  name: 'createInvoice',
  initialState: getInitialState(),
  reducers: {
    changeCreatedInvoicesData: (state, action) => {
      state.createdInvoicesData = {
        ...state.createdInvoicesData,
        ...action.payload,
      }
    },
    setCreateInvoicesPreviewData: (state, action) => {
      state.preview = {
        ...state.preview,
        ...action.payload,
      }
    },
    resetCreateInvoicesPreviewData: state => {
      state.preview = getInitialState().preview
    },
    updateCreateInvoicesDocIdToChargeMapItem: (state, action) => {
      const docId = get(action, 'payload.docId')
      const value = get(action, 'payload.value')
      state.previewChargeDrawer.docIdToChargeMap[docId] = {
        ...state.previewChargeDrawer.docIdToChargeMap[docId],
        ...value,
      }
      state.previewChargeDrawer.docIdList = Object.keys(state.previewChargeDrawer.docIdToChargeMap)
    },
    resetCreateInvoicesPreviewChargeDrawerState: state => {
      state.previewChargeDrawer = getInitialState().previewChargeDrawer
    },
    setCreateInvoicesSelectedBillingProfile: (state, action) => {
      const billingProfile = get(action, 'payload.billingProfile')
      const billingProfileDetails = get(action, 'payload.billingProfileDetails')
      state.selectedBillingProfile = billingProfile
      state.selectedBillingProfileDetails = billingProfileDetails
      state.selectedBillingPeriod = ''
      state.selectedBillingPeriodDetails = {}
      state.filters = getInitialState().filters
    },
    setCreateInvoicesSelectedBillingPeriod: (state, action) => {
      const billingPeriod = get(action, 'payload.billingPeriod')
      const billingPeriodDetails = get(action, 'payload.billingPeriodDetails')
      state.selectedBillingPeriod = billingPeriod
      state.selectedBillingPeriodDetails = billingPeriodDetails
    },
    setCreateInvoicesRefetchFlag: (state, action) => {
      state.filters.refetchFlag = action.payload
    },
    resetCreateInvoicesState: () => {
      return getInitialState()
    },
  },
})

export const {
  setSortModel: setCreateInvoicesSortModel,
  setPage: setCreateInvoicesPage,
  setPageSize: setCreateInvoicesPageSize,
  setColumnVisibilityModel: setCreateInvoicesColumnVisibilityModel,
  setFilters: setCreateInvoicesFilters,
  setIsOpenTableFilters: setCreateInvoicesIsOpenTableFilters,
  setPinnedColumns: setCreateInvoicesPinnedColumns,
  setSelectionModel: setCreateInvoicesSelectionModel,
  resetCreateInvoicesPreviewChargeDrawerState,
  resetCreateInvoicesPreviewData,
  changeCreatedInvoicesData,
  setCreateInvoicesPreviewData,
  updateCreateInvoicesDocIdToChargeMapItem,
  setCreateInvoicesSelectedBillingProfile,
  setCreateInvoicesSelectedBillingPeriod,
  setCreateInvoicesRefetchFlag,
  resetCreateInvoicesState,
} = createInvoiceSlice.actions

export default createInvoiceSlice.reducer
