import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@mui/material'

const HHFormCheckboxField = ({ name, rules, control, onChange = noop, sx }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const { onBlur, onChange: onChangeHookForm, ...rest } = field
        return (
          <Checkbox
            checked={field?.value || false}
            onChange={value => {
              onChangeHookForm(value)
              onChange(value)
              onBlur()
            }}
            sx={sx}
            {...rest}
          />
        )
      }}
    />
  )
}

HHFormCheckboxField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  rules: PropTypes.object,
  sx: PropTypes.object,
  onChange: PropTypes.func,
}

export default HHFormCheckboxField
