import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceFeeList: build.query({
      query: body => ({
        url: '/api/invoice/fee/list',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useLazyGetInvoiceFeeListQuery } = extendedApi
