import React from 'react'
import PropTypes from 'prop-types'
import { Box, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as ReactiveAccountIcon } from 'assets/customers/ReactiveAccount.svg'
import Avatar from '@mui/material/Avatar'
import { getAccountRowBgcolor } from 'components/customers/accounts/settings'
import { getLocationRowBgcolor } from 'components/locations/settings'
import { get } from 'utils/lodash'

const WithDeactivatedIndicator = ({
  showIndicator = false,
  locationsVariant = false,
  children,
  Icon = <ReactiveAccountIcon />,
  ...rest
}) => {
  const theme = useTheme()
  const bgcolor = locationsVariant ? getLocationRowBgcolor(rest) : getAccountRowBgcolor(rest)
  const maxHeight = get(rest, 'rowHeight', '100%')
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderLeft: 0,
        borderRight: 0,
        width: '100%',
        maxHeight,
      }}
      {...rest}
    >
      {showIndicator && (
        <Box
          className="deactivated-bg-indicator"
          sx={{
            width: theme.spacing(1),
            bgcolor: showIndicator ? theme.palette.background.deactivated : bgcolor,
          }}
        />
      )}
      <Box
        sx={{
          borderLeftStyle: showIndicator && 'solid',
          borderLeftWidth: 2,
          borderLeftColor: showIndicator ? theme.palette.border.deactivated : 'inherit',
          position: 'relative',
          flex: 1,
          ...(!showIndicator && {
            '& .MuiDataGrid-row, & .expand-toggle-account-group-details': {
              pl: 1.2,
            },
          }),
        }}
      >
        {children}
        {showIndicator && (
          <Avatar
            sx={{
              position: 'absolute',
              width: 18,
              height: 18,
              bgcolor: theme.palette.border.deactivated,
              top: 4,
              left: -6,
            }}
          >
            <SvgIcon
              sx={{
                fontSize: 12,
              }}
            >
              {Icon}
            </SvgIcon>
          </Avatar>
        )}
      </Box>
    </Box>
  )
}

WithDeactivatedIndicator.propTypes = {
  showIndicator: PropTypes.bool,
  children: PropTypes.node,
  locationsVariant: PropTypes.bool,
  sx: PropTypes.object,
  Icon: PropTypes.node,
}

export default WithDeactivatedIndicator
