import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { serializeDate } from 'utils/date'
import { selectBulkResequenceRows } from 'slices/route/bulkResequenceStopsSlice'
import BasePermanentMoveDialog from 'components/route-manager/BulkActions/common/BasePermanentMoveDialog'
import PermanentMoveAlert from 'components/route-manager/BulkActions/BulkResequenceStopsDialog/ConfirmPermanentMoveDialog/PermanentMoveAlert'

const ConfirmPermanentMoveDialog = ({
  confirmPermanentGridApiRef,
  isOpen,
  onClose = noop,
  onConfirm = noop,
  DialogProps = {},
  isPermanentLoading,
}) => {
  const { watch, setValue } = useFormContext()
  const routeId = watch('routeId')
  const serviceDate = watch('serviceDate')
  const serializedServiceDate = serializeDate(serviceDate)
  const rows = useSelector(selectBulkResequenceRows)
  const changedRows = rows.filter(row => row.isChanged)

  const getIsFromRow = useCallback(() => true, [])

  useEffect(() => {
    if (isOpen) {
      // Fix BasePermanentMoveDialog to take this value from the param
      setValue('moveFromRouteId', routeId)
    }
  }, [isOpen])

  return (
    <BasePermanentMoveDialog
      AlertComponent={PermanentMoveAlert}
      DialogProps={DialogProps}
      confirmPermanentGridApiRef={confirmPermanentGridApiRef}
      isPermanentLoading={isPermanentLoading}
      moveToRows={changedRows}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      serializedServiceDate={serializedServiceDate}
      getIsFromRow={getIsFromRow}
      showOriginalPosition
    />
  )
}

ConfirmPermanentMoveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  DialogProps: PropTypes.object,
  confirmPermanentGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isPermanentLoading: PropTypes.bool,
}

export default ConfirmPermanentMoveDialog
