import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    activateAccount: build.mutation({
      query: ({ accountId }) => {
        return {
          url: `/api/v1/core/accounts/activate/${accountId}`,
          method: 'PUT',
        }
      },
    }),
  }),
})

export const { useActivateAccountMutation } = extendedApi
