import React, { memo } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Dialog, DialogContent } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import T from 'T'
import { noop } from 'lodash'
import { FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'
import useTheme from '@mui/material/styles/useTheme'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

const SelectDateDialog = ({ isOpen, onClose = noop, onConfirm = noop, onChange = noop, value, DialogProps = {} }) => {
  const theme = useTheme()
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose} {...DialogProps}>
      <HHDialogTitle title="Select a date" showCloseIcon onClose={onClose} />
      <DialogContent sx={{ pt: 0.5 }}>
        <HHDesktopDatePicker
          format={FRONTEND_DATE_FORMAT_DATE_FNS}
          onChange={onChange}
          value={value}
          PopperProps={{ sx: { zIndex: theme.zIndex.tooltip + 200 } }}
        />
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="small" onClick={onClose} />
        <LoadingButton onClick={onConfirm} variant="contained" size="small">
          {T.NEXT}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

SelectDateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  DialogProps: PropTypes.object,
}

export default memo(SelectDateDialog)
