import React from 'react'
import PropTypes from 'prop-types'

import { Box, Popover } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { ReactComponent as CalendarCirclePlusIcon } from 'assets/CalendarCirclePlus.svg'

import { memo } from 'utils/react'
import ListItemRow from './ListItemRow'

const LocationsServicesCountPopover = ({
  anchorEl,
  activeConfiguredServiceCount = 0,
  deactivatedConfiguredServiceCount = 0,
  activeLocationCount = 0,
  deactivatedLocationCount = 0,
  onClose,
}) => {
  const open = Boolean(anchorEl)

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box p={1.5}>
        <ListItemRow
          icon={<CalendarCirclePlusIcon width={20} height={20} />}
          label="Services"
          activeCount={activeConfiguredServiceCount}
          deactivatedCount={deactivatedConfiguredServiceCount}
        />
        <ListItemRow
          icon={<LocationOnIcon color="action" fontSize="small" />}
          label="Locations"
          activeCount={activeLocationCount}
          deactivatedCount={deactivatedLocationCount}
        />
      </Box>
    </Popover>
  )
}

LocationsServicesCountPopover.propTypes = {
  anchorEl: PropTypes.object,
  activeConfiguredServiceCount: PropTypes.number,
  deactivatedConfiguredServiceCount: PropTypes.number,
  activeLocationCount: PropTypes.number,
  deactivatedLocationCount: PropTypes.number,
  onClose: PropTypes.func,
}

export default memo(LocationsServicesCountPopover)
