import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'utils/lodash'
import { getPaymentFormParams, PG_CONNECTOR_TYPE } from 'settings/constants/paymentGateway'
import { useGetPGUserInfoQuery } from 'api/payment-gateway/paymentGateway'
import { getIsCreditCard } from 'utils/takePayments'
import { HHAlert } from 'components/common/HHAlert'

import IframeSkelton from './IframeSkelton'

const RenderPaymentIframe = ({ type, callback, sx = {} }) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)
  const { isLoading, isFetching, data: userInfoData } = useGetPGUserInfoQuery()
  const tokenizeUrl = useMemo(() => get(userInfoData, 'userInfo.tokenizeUrl'), [userInfoData])
  const pgConnectorType = useMemo(() => get(userInfoData, 'userInfo.pgConnectorType'), [userInfoData])
  const isCDCN = useMemo(() => pgConnectorType === PG_CONNECTOR_TYPE, [pgConnectorType])
  const isCreditCard = useMemo(() => getIsCreditCard(type), [type])

  const handleIframeLoad = () => {
    // added timeout to avoid CSS flickering
    setTimeout(() => setIsIframeLoaded(true), 300)
  }

  const handlePGResponse = event => {
    try {
      const data = JSON.parse(event.data)
      if (data?.message) {
        callback({ accountToken: data.message, expiry: data?.expiry })
        return
      }

      callback({ accountToken: null, expiry: null })
    } catch (error) {}
  }

  useEffect(() => {
    window.addEventListener('message', handlePGResponse, false)

    return () => {
      window.removeEventListener('message', handlePGResponse, false)
    }
  }, [])

  const isIframeLoading = isLoading || isFetching

  return (
    <>
      {!isCDCN && !isIframeLoading && (
        <HHAlert sx={{ mt: 2 }} severity="info">
          Invalid connector
        </HHAlert>
      )}
      {!tokenizeUrl && !isIframeLoading && (
        <HHAlert sx={{ mt: 2 }} severity="info">
          Missing tokenize URL
        </HHAlert>
      )}
      {!isIframeLoaded && <IframeSkelton isCreditCard={isCreditCard} />}

      {isCDCN && tokenizeUrl && !isIframeLoading && (
        <iframe
          title={`add-${type}-payment-dialog`}
          src={`${tokenizeUrl}${getPaymentFormParams(type)}`}
          frameBorder="0"
          scrolling="no"
          style={{
            marginLeft: '-8px',
            width: 'calc(100% + 16px)',
            ...(isIframeLoaded ? { height: isCreditCard ? 150 : 50 } : { height: 0 }),
            ...sx,
          }}
          onLoad={handleIframeLoad}
        />
      )}
    </>
  )
}

RenderPaymentIframe.propTypes = {
  type: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  sx: PropTypes.object,
}

export default RenderPaymentIframe
