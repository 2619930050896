import React, { Children, useState } from 'react'
import { styled } from '@mui/material/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiToggleButton from '@mui/material/ToggleButton'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiTypography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 0,
  width: theme.spacing(26),
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: theme.spacing(5),
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}))

const AccordionSummary = styled(props => <MuiAccordionSummary direction="row-reverse" {...props} />)(({ theme }) => ({
  padding: 0,
  borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
  height: theme.spacing(5),
  alignItems: 'center',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    justifyContent: 'space-between',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
}))

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  borderLeft: `1px solid ${theme.palette.border.secondary}`,
  marginLeft: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))
const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const Typography = styled(MuiTypography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '24px',
}))

const HHAccordion = ({
  title = 'Title',
  handleActiveClass = noop,
  isExpanded = false,
  options = [],
  activeOption = '',
  type,
  handleActiveOptions = noop,
}) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const [alignment, setAlignment] = useState('web')

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment)
    }
  }
  const handleExpand = e => {
    if (isExpanded) {
      handleActiveOptions(e, options[0])
      setExpanded(true)
    } else {
      handleActiveOptions(e, title)
    }
  }
  return (
    <Accordion expanded={options.includes(activeOption) && expanded} onChange={e => handleExpand(e)}>
      <AccordionSummary
        sx={handleActiveClass(title) && { backgroundColor: '#0073e614' }}
        expandIcon={isExpanded && <ExpandMore sx={{ fontSize: '1.5rem', color: '#afb4bd' }} />}
      >
        <Typography variant="body2" color={handleActiveClass(title) ? 'primary.main' : 'text.secondary'}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <ToggleButtonGroup orientation="vertical" color="primary" value={alignment} exclusive onChange={e => handleChange(e)}>
          {isExpanded &&
            Children.toArray(
              options.map(option => {
                return (
                  <ToggleButton
                    selected={activeOption === option}
                    value={option}
                    onClick={e => handleActiveOptions(e, option, type)}
                    sx={{ justifyContent: 'flex-start' }}
                  >
                    <Typography variant="overline">{option}</Typography>
                  </ToggleButton>
                )
              })
            )}
        </ToggleButtonGroup>
      </AccordionDetails>
    </Accordion>
  )
}
HHAccordion.propTypes = {
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
  handleActiveClass: PropTypes.func,
  handleActiveOptions: PropTypes.func,
  activeOption: PropTypes.string,
  options: PropTypes.array,
  type: PropTypes.string,
}
export default HHAccordion
