import React from 'react'
import PropTypes from 'prop-types'

import { Box, Table, TableHead, TableBody, TableRow } from '@mui/material'
import { blue } from '@mui/material/colors'

import { get } from 'utils/lodash'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { getInvoiceLinesByLocation, getSubtotalByLocation, transformInvoiceLineByLocation, getVacationHoldData } from '../helpers/invoice'
import { getWorkOrderPO, filterValidPO } from '../helpers/address'

const InvoiceLine = ({ invoice = {} }) => {
  let invoiceLinesByLocation = getInvoiceLinesByLocation(invoice)
  const subtotalByLocation = getSubtotalByLocation(invoiceLinesByLocation)
  invoiceLinesByLocation = transformInvoiceLineByLocation(invoiceLinesByLocation)
  const vacationHoldsById = getVacationHoldData(get(invoice, 'vacationHolds', []))

  const locationIdArray = Array.from(invoiceLinesByLocation.keys())
  const invoiceLinesByLocationObj = Object.fromEntries(invoiceLinesByLocation)
  const invoiceFeeTaxCharges = get(invoice, 'invoiceFeeTaxCharges', [])

  return (
    <Box width="100%" py={2}>
      <Table
        className="text-xs break-inside-auto"
        sx={{
          '& th, & td': { p: 1 },
        }}
      >
        <TableHead sx={{ textTransform: 'uppercase' }}>
          <TableRow sx={{ borderTop: '2px solid', borderColor: 'divider' }}>
            <th align="left" className="font-normal w-5/12">
              Service
            </th>
            <th align="left" className="font-normal w-3/12">
              Date
            </th>
            <th align="right" className="font-normal w-1/12">
              Price
            </th>
            <th align="right" className="font-normal w-1/12">
              QTY
            </th>
            <th align="right" className="font-normal w-2/12">
              AMOUNT
            </th>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(invoiceLinesByLocationObj).map(locationKey => {
            const invoiceLineParam = get(invoiceLinesByLocationObj, locationKey, [])
            const locationId = invoiceLineParam[0].location.id
            return (
              <>
                <TableRow
                  sx={{
                    backgroundColor: blue[50],
                    borderTop: '2px solid',
                    borderBottom: '2px solid',
                    borderColor: 'divider',
                  }}
                  className="break-inside-avoid break-after-auto"
                >
                  <td colSpan="4">
                    {(locationIdArray.length > 1 ||
                      (locationIdArray.length === 1 &&
                        invoiceLineParam[0].location.locationName &&
                        invoice.account.accountName.replace(/\s/g, '').toLocaleLowerCase() !==
                          invoiceLineParam[0].location.locationName.replace(/\s/g, '').toLocaleLowerCase())) && (
                      <span>{`${invoiceLineParam[0].location.locationName} | `}</span>
                    )}
                    <span>
                      {[
                        invoiceLineParam[0].location.address.line1,
                        invoiceLineParam[0].location.address.city,
                        invoiceLineParam[0].location.address.state,
                        invoiceLineParam[0].location.address.zipCode,
                      ].join(', ')}
                    </span>
                    {invoiceLineParam[0].location?.po && <span>{` | Location PO: ${invoiceLineParam[0].location.po}`}</span>}
                  </td>
                  <td align="right" className="font-bold">
                    {subtotalByLocation.has(locationId) ? (
                      <HHDisplayMoney value={subtotalByLocation.get(locationId)} formatToDollars={false} />
                    ) : (
                      ''
                    )}
                  </td>
                </TableRow>
                {vacationHoldsById.has(locationKey) &&
                  vacationHoldsById.get(locationId).map(vacationHold => (
                    <TableRow sx={{ borderBottom: '1px solid', borderColor: 'divider' }} className="break-inside-avoid break-after-auto">
                      <td className="font-bold" colSpan="3">
                        {`${vacationHold.type} - ${vacationHold.title}`}
                      </td>
                      <td align="right" className="font-bold" colSpan="2">
                        {`${vacationHold.startDate} - ${vacationHold.endDate}`}
                      </td>
                    </TableRow>
                  ))}
                {invoiceLineParam.map(invoiceLine => (
                  <>
                    <TableRow className="break-inside-avoid break-after-auto">
                      <td className="font-bold">{invoiceLine.summary}</td>
                      <td align="left">
                        <span>
                          {invoiceLine.serviceEndDate && invoiceLine.serviceStartDate
                            ? `${invoiceLine.serviceStartDate} - ${invoiceLine.serviceEndDate}`
                            : invoiceLine.serviceStartDate}
                        </span>
                      </td>
                      <td align="right">
                        <HHDisplayMoney value={invoiceLine.serviceCharge} formatToDollars={false} />
                      </td>
                      <td align="right">{invoiceLine.quantity}</td>
                      <td align="right">
                        <HHDisplayMoney value={invoiceLine.serviceCharge} formatToDollars={false} />
                      </td>
                    </TableRow>
                    {get(invoiceLine, 'invoiceFeeTaxCharges', []).map(charge => (
                      <TableRow className="break-inside-avoid break-after-auto">
                        <td>{charge.name}</td>
                        <td align="left">{charge.createdAt}</td>
                        <td align="right" />
                        <td align="right">{charge.quantity <= 0 ? '' : charge.quantity}</td>
                        <td align="right">
                          <HHDisplayMoney value={charge.feeCharge} formatToDollars={false} />
                        </td>
                      </TableRow>
                    ))}
                    {filterValidPO(get(invoiceLine, 'workOrders', [])).map(workOrder => (
                      <TableRow className="break-inside-avoid break-after-auto">
                        <td colSpan="5">{getWorkOrderPO(workOrder.workOrderNumber, workOrder.purchaseOrderNumber)}</td>
                      </TableRow>
                    ))}
                  </>
                ))}
              </>
            )
          })}
          {invoiceFeeTaxCharges.map(charge => (
            <TableRow className="break-inside-avoid break-after-auto">
              <td colSpan="4">
                <span>
                  {charge.name}
                  {charge?.showNote && <span>{` - ${charge.note}`}</span>}
                </span>
              </td>
              <td align="right">
                <HHDisplayMoney value={charge.feeCharge} formatToDollars={false} />
              </td>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

InvoiceLine.propTypes = {
  invoice: PropTypes.object,
}

export default InvoiceLine
