import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addProfile: build.mutation({
      query: body => ({
        url: 'api/settings/profile/add',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAddProfileMutation } = extendedApi
