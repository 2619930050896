export const FEATURE_FLAGS = {
  NOTIFICATION_CENTER: 'notificationCenter',
  NEW_ROUTE_MANAGER: 'newRouteManager',
  BILLING_WORK_ORDER_INVOICE_STATUS: 'billingWorkOrderInvoiceStatus',
  INVOICE_BATCHING: 'invoiceBatching',
  PAYMENT_GROUPS: 'paymentGroups',
  GCP_IP: 'gcpIp',
  NOTES_V_2: 'notesV2',
  ACCOUNT_GROUPS: 'accountsGroups',
  SUSPENSIONS: 'suspensions',
  LATE_FEE: 'lateFee',
  RENTAL_FEE: 'rentalFee',
}
