import React, { useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Button } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import PropTypes from 'prop-types'
import FiltersMenu from 'components/locations/filters/FiltersMenu'

const FiltersMenuButton = ({ sx }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        sx={sx}
        startIcon={<FilterListIcon color="primary" />}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        variant="text"
        onClick={handleClick}
      >
        Filters
      </Button>
      <FiltersMenu open={open} anchorEl={anchorEl} onClose={handleClose} />
    </>
  )
}

FiltersMenuButton.propTypes = {
  sx: PropTypes.object,
}

export default FiltersMenuButton
