import React, { useEffect } from 'react'
import Payments from 'containers/billing/payments/Payments'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import { useDispatch } from 'react-redux'

const PaymentsPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setShowAppBar(false))
    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  return (
    <ProtectedRouteHoC>
      <Payments />
    </ProtectedRouteHoC>
  )
}

PaymentsPage.propTypes = {}

export default PaymentsPage
