export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'
export const FRONTEND_DATE_FORMAT = 'MM/DD/YYYY'

export const FRONTEND_DATE_TIME_FORMAT = 'MM/DD/YYYY - HH:mm:ss Z'

// Later remove moment and rename these variables
export const BACKEND_DATE_FORMAT_DATE_FNS = 'yyyy-MM-dd'
export const FRONTEND_DATE_FORMAT_DATE_FNS = 'MM/dd/yyyy'
export const SHORT_FRONTEND_DATE_FORMAT_DATE_FNS = 'MM/dd/yy'

export const MOVE_BETWEEN_ROUTES_DATE_FORMAT_DATE_FNS = 'EEEE -  MM/dd/yyyy'

export const URL_DATE_FORMAT_DATE_FNS = 'MM-dd-yyyy'

export const FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS = 'EEEE - MM/dd/yyyy'

export const FRONTEND_TIME_FORMAT_DATE_FNS = 'hh:mm a'
export const FRONTEND_ZONE_FORMAT_DATE_FNS = 'z'

export const FRONTEND_NOTIFICATION_DATE_FORMAT = 'MMM d, yyyy'

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
