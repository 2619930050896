import React, { useReducer, useEffect, useState } from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import { Add, ChevronRight } from '@mui/icons-material'
import { Button, Box, Typography } from '@mui/material'
import { useLazyGetUsersListQuery } from 'api/settings/getUsersList'
import { useAddUserMutation, useEditUserMutation } from 'api/settings/addUser'
import { useLazyGetUserByIdQuery } from 'api/settings/getUserById'
import { useLazyGetProfileListQuery } from 'api/settings/getProfileList'
import { useLazyGetSkillListQuery } from 'api/settings/getSkillList'
import { useLazyGetTruckListQuery } from 'api/settings/getTrucksList'

import { USER_INITIAL_VALUES } from 'settings/constants/people'
import { INITIAL_ROLE_VALUES } from 'settings/constants/roles'

import { SETTINGS_PAGINATION } from 'settings/constants/pagination'

import Constants from 'Constants'
import T from 'T'
import { handleError } from 'utils/error'
import { get } from 'utils/lodash'
import { canAddSettings, canAddUsers, canUpdateSettings, canUpdateUsers } from 'data/permissions/permissionsSelectors'
import { getIsActivatedOrPending } from 'settings/constants/user'

import { handleRolesPayload, setUserProfile } from './roleMethods'

import ListUsers from './people/ListUsers'
import AddUsersScreen from './people/AddUsers'

const { INITIAL_PAGE, ROWS_PER_PAGE } = SETTINGS_PAGINATION
const MAX_USER_COUNT = 1000
const { CAN_UPDATE_USER } = Constants

const PeopleSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const addUsers = useSelector(canAddUsers, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)

  const [addNewUser, setAddNewUser] = useState(false)

  const [userState, setUserState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  })

  const [roleState, setRoleState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    roadTab: T.DISPLAY,
    allowOfficeLogin: true,
    allowRoadLogin: true,
    footerFlag: false,
  })

  const {
    firstName,
    profileId,
    originalProfileId,
    userID,
    lastName,
    email,
    phoneNumber,
    active,
    changeIndividualPermissions,
    originalChangeIndividualPermissions,
    accountRepresentative,
    commercialDriverLicense,
    payType,
    payRate,
    burdenRate,
    asset,
    users,
    selectedSkills,
    page,
    rowsPerPage,
    timeZone,
  } = userState
  const isEditForm = Boolean(userID)
  const [getUsersList, { isLoading, isFetching }] = useLazyGetUsersListQuery()
  const [addUser, { isLoading: isAddUserLoading, isFetching: isAddUserFetching }] = useAddUserMutation()
  const [editUser, { isLoading: isEditUserLoading, isFetching: isEditUserFetching }] = useEditUserMutation()
  const [getUserById, { data, isLoading: isSingleUserLoading, isFetching: isSingleUserFetching }] = useLazyGetUserByIdQuery()
  const [getTruckList] = useLazyGetTruckListQuery()
  const [getRoles] = useLazyGetProfileListQuery()
  const [getSkillList] = useLazyGetSkillListQuery()
  const isListLoading = isLoading || isFetching || isAddUserLoading || isAddUserFetching || isEditUserLoading || isEditUserFetching
  const setInitialValues = () => {
    setUserState(USER_INITIAL_VALUES)
    setRoleState(INITIAL_ROLE_VALUES)
  }
  const originalEmail = isEditForm ? get(data, 'user.email', '') : ''

  const fetchUsers = () => {
    const payload = {
      page: INITIAL_PAGE,
      size: MAX_USER_COUNT,
    }

    getUsersList(payload)
      .unwrap()
      .then(response => {
        const { totalItems, totalPages } = response
        setUserState({
          users: get(response, 'users', []),
          totalUsersCount: totalItems,
          totalPageCount: totalPages,
        })
      })
      .catch(handleError)
  }

  // Page change handler
  const handlePageChange = newPage => {
    setUserState({ page: newPage })
  }

  // Rows per page change handler
  const handleRowsPerPageChange = event => {
    setUserState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
  }

  const handleSave = () => {
    const payload = {
      firstName,
      profileId,
      lastName,
      userName: email,
      email,
      phoneNumber,
      active,
      changeIndividualPermissions,
      accountRepresentative,
      commercialDriverLicense,
      payType,
      payRate,
      burdenRate,
      timeZone,
      skills: selectedSkills,
    }

    if (asset !== T.NO_RECORD) {
      payload.asset = asset
    }

    if (userID !== '') {
      payload.id = userID
    }

    const profilePayload = {
      active: true,
      allowOfficeLogin: roleState.allowOfficeLogin,
      allowRoadLogin: roleState.allowRoadLogin,
    }

    if (changeIndividualPermissions === originalChangeIndividualPermissions && profileId === originalProfileId) {
      profilePayload.id = profileId
    }

    handleRolesPayload(profilePayload, roleState)

    payload.editedProfile = profilePayload
    const skills = get(payload, 'skills', [])
    payload.skills = skills.map(skill => skill.id).filter(id => id)
    if (isEditForm) {
      editUser({ body: payload })
        .unwrap()
        .then(() => {
          setAddNewUser(false)
          fetchUsers()
          setInitialValues()
          setUserState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
          window.location.reload()
        })
        .catch(handleError)
    } else {
      addUser(payload)
        .unwrap()
        .then(() => {
          setAddNewUser(false)
          fetchUsers()
          setInitialValues()
          setUserState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
          window.location.reload()
        })
        .catch(handleError)
    }
  }

  const getAssetsList = () => {
    getTruckList({})
      .unwrap()
      .then(response => {
        const activeAssets = get(response, 'assets', []).filter(data => data.active)

        const assetSelect = activeAssets.map(val => ({
          key: val.id,
          value: val.assetName ? val.assetName : 'NA',
        }))

        setUserState({
          assets: assetSelect,
        })
      })
      .catch(handleError)
  }

  const getSkillsList = () => {
    getSkillList()
      .unwrap()
      .then(res => {
        const activeSkills = get(res, 'skill', []).filter(data => data.active)
        setUserState({
          skills: activeSkills,
        })
      })
      .catch(() => {
        setUserState({
          skills: [],
        })
      })
  }

  const getRolesDropdownData = roles => roles.map(val => ({ key: val.id, value: val.profileName ? val.profileName : 'NA' }))

  const getUserRoles = () => {
    const payload = { requestedPage: page, requestedPageSize: rowsPerPage, user: false }

    getRoles(payload)
      .unwrap()
      .then(({ profile }) => {
        const rolesSelect = profile.filter(role => role.active)
        setUserState({ roles: getRolesDropdownData(rolesSelect) })
      })
      .catch(() => {
        setUserState({ roles: [] })
      })
  }

  const handleDropdownData = () => {
    getAssetsList()
    getSkillsList()
  }

  const handleEdit = async id => {
    setAddNewUser(true)
    handleDropdownData()
    const result = await getRoles({ requestedPage: page, requestedPageSize: rowsPerPage, user: false }).unwrap()
    const profiles = get(result, 'profile', [])

    getUserById({ id })
      .unwrap()
      .then(res => {
        const userInf = get(res, 'user', {})
        const currentProfile = get(userInf, 'profile', {})
        const currentAsset = get(userInf, 'asset', {})

        const currentProfileId = get(currentProfile, 'id', '')
        const currentProfileName = get(currentProfile, 'profileName', '')
        const canChangeIndividualPermissions = get(res, 'changeIndividualPermissions')
        const includeCustomProfile = canChangeIndividualPermissions
          ? [{ id: currentProfileId, profileName: currentProfileName }, ...profiles]
          : [...profiles]
        const allProfiles = getRolesDropdownData(includeCustomProfile)
        const status = get(userInf, 'status')

        setUserState({
          firstName: get(userInf, 'firstName', ''),
          lastName: get(userInf, 'lastName', ''),
          active: getIsActivatedOrPending(status),
          status,
          profileId: currentProfileId,
          originalProfileId: currentProfileId,
          asset: get(currentAsset, 'id', ''),
          userID: get(userInf, 'id', ''),
          email: get(userInf, 'email', ''),
          phoneNumber: get(userInf, 'phoneNumber', ''),
          payType: get(userInf, 'payType', ''),
          payRate: get(userInf, 'payRate', ''),
          burdenRate: get(userInf, 'burdenRate', ''),
          accountRepresentative: get(res, 'accountRepresentative'),
          commercialDriverLicense: get(userInf, 'commercialDriverLicense', ''),
          changeIndividualPermissions: canChangeIndividualPermissions,
          originalChangeIndividualPermissions: canChangeIndividualPermissions,
          roles: allProfiles,
          timeZone: get(userInf, 'timeZone', ''),
          role: currentProfileName,
          selectedSkills: get(userInf, 'skill', []),
          providerIds: get(userInf, 'providerIds', []),
        })
        setUserProfile(currentProfile, setRoleState)
        setTimeout(() => {
          setUserState({ footerFlag: false })
          setRoleState({ footerFlag: false })
        })
      })
      .catch(handleError)
  }

  const handleCancel = () => {
    setAddNewUser(false)
    setInitialValues()
    fetchUsers()
  }

  const onHandleNameChange = event => {
    if (updateSettings && updateUsers) {
      const { name } = event.target

      let { value } = event.target

      if (/^\s/.test(value)) {
        value = ''
      }

      setUserState({ [name]: value, footerFlag: true })
    }
  }

  useEffect(() => {
    fetchUsers()
    setInitialValues()
  }, [])

  const handleAdd = () => {
    setAddNewUser(true)
    getUserRoles()
    handleDropdownData()
  }

  const handleSwitchVal = (title, value) => {
    if (updateSettings) {
      const switchTitle = title === T.ACTIVE ? 'active' : 'accountRepresentative'
      setUserState({ [switchTitle]: value, footerFlag: true })
    }
  }

  return (
    <Box>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewUser ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCancel()}
            >
              {T.PEOPLE}
            </Typography>

            {addNewUser && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${userID ? T.EDIT : T.NEW} ${T.USER}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNewUser && addSettings && addUsers && (
            <Button disabled={!CAN_UPDATE_USER} variant="outlined" startIcon={<Add />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>

        {addNewUser && (
          <AddUsersScreen
            isEditForm={isEditForm}
            userState={userState}
            setUserState={setUserState}
            roleState={roleState}
            setRoleState={setRoleState}
            isLoading={isSingleUserLoading || isSingleUserFetching}
            isSubmitting={isAddUserLoading || isEditUserLoading}
            handleSave={handleSave}
            handleCancel={handleCancel}
            handleSwitchVal={handleSwitchVal}
            onHandleNameChange={onHandleNameChange}
            originalEmail={originalEmail}
          />
        )}
      </Box>

      {!addNewUser && (
        <ListUsers
          userState={userState}
          users={users}
          isLoading={isListLoading}
          handlePageChange={handlePageChange}
          handleEdit={handleEdit}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Box>
  )
}

export default PeopleSettings
