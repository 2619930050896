/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { get } from 'utils/lodash'

export const BULK_MOVE_MAP_LAYERS = {
  FROM_ROUTE_LAYER: 'FROM_ROUTE_LAYER',
}

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const getInitialState = () => ({
  focus: {
    name: '',
    rowIndex: '',
  },
  isOpen: false,
  isConfirmCloseDialogOpen: false,
  isSelectDateDialogOpen: false,
  mapLayer: FROM_ROUTE_LAYER,
  routesById: {},
  fitBounds: true,
  rows: [],
  originalRouteSequenceLine: {},
  routeSequenceLine: {},
  routeStopMarkers: {},
  clickedMarkerId: '',
  confirmPermanentMoveDialog: {
    isOpen: false,
  },
})

const bulkResequenceStopsSlice = createSlice({
  name: 'bulkResequenceStops',
  initialState: getInitialState(),
  reducers: {
    setBulkResequenceStopsState: (state, action) => {
      return { ...state, ...action.payload }
    },
    setConfirmPermanentMoveDialogState: (state, action) => {
      state.confirmPermanentMoveDialog = {
        ...state.confirmPermanentMoveDialog,
        ...action.payload,
      }
    },
  },
})
export const { setBulkResequenceStopsState, setConfirmPermanentMoveDialogState } = bulkResequenceStopsSlice.actions

export const selectBulkResequenceFocusName = state => get(state, 'bulkResequenceStops.focus.name')
export const selectBulkResequenceFocusRowIndex = state => get(state, 'bulkResequenceStops.focus.rowIndex', 0)
export const selectIsOpenBulkResequenceStopsDialog = state => get(state, 'bulkResequenceStops.isOpen', false)
export const selectIsSelectDateDialogOpen = state => get(state, 'bulkResequenceStops.isSelectDateDialogOpen', false)
export const selectIsOpenBulkResequenceConfirmCloseDialog = state => get(state, 'bulkResequenceStops.isConfirmCloseDialogOpen', false)
export const selectBulkResequenceRoutesById = state => get(state, 'bulkResequenceStops.routesById', {})
export const selectBulkResequenceFitBounds = state => get(state, 'bulkResequenceStops.fitBounds', false)

export const selectBulkResequenceRows = state => get(state, 'bulkResequenceStops.rows', [])
export const selectBulkResequenceOriginalRouteSequenceLine = state => get(state, 'bulkResequenceStops.originalRouteSequenceLine', {})
export const selectBulkResequenceRouteSequenceLine = state => get(state, 'bulkResequenceStops.routeSequenceLine', {})
export const selectBulkResequenceRouteStopMarkers = state => get(state, 'bulkResequenceStops.routeStopMarkers', {})
export const selectBulkResequenceMapLayer = state => get(state, 'bulkResequenceStops.mapLayer', FROM_ROUTE_LAYER)
export const selectBulkResequenceClickedMarkerId = state => get(state, 'bulkResequenceStops.clickedMarkerId')
export const selectIsConfirmPermanentMoveDialogOpen = state => get(state, 'bulkResequenceStops.confirmPermanentMoveDialog.isOpen', false)

export default bulkResequenceStopsSlice.reducer
