import omit from 'lodash/omit'
import { get } from 'utils/lodash'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { SEARCH_TYPES } from './model'

export const getInvoices = (searchType, selectedOption, invoiceData) => {
  const isCustomerSearch = searchType === SEARCH_TYPES.CUSTOMER
  const isInvoiceSearch = searchType === SEARCH_TYPES.INVOICE

  if (isCustomerSearch && get(selectedOption, 'unpaidInvoiceCount', 0) > 0) {
    return get(invoiceData, 'invoices', [])
  }

  if (isInvoiceSearch) {
    return selectedOption ? [selectedOption] : []
  }

  return []
}

export const preparePayload = (payments, hasInitialAccount, batchDate) => {
  const payload = {
    batchDate: formatDateToBEFormatDateFns(batchDate),
    requestedPayments: payments.map(payment => ({
      ...omit(payment, ['searchType', 'selectedOption', 'accountNumber', 'refId', 'initialCreditBalanceCents']),
      paymentDate: formatDateToBEFormatDateFns(payment.paymentDate),
    })),
  }

  if (hasInitialAccount) {
    // batch date field is not available for single payment, use payment date
    payload.batchDate = formatDateToBEFormatDateFns(get(payments, '[0].paymentDate'))
  }

  return payload
}
