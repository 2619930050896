import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Stack, Typography } from '@mui/material'

import PricingSwitch from 'components/pricing/PricingSwitch'
import CommonToggleButtonGroup from 'components/common/CommonToggleButtonGroup'
import CommonToggleButton from 'components/common/CommonToggleButton'

import './LinkedService.scss'
import ModalSplittedTitle from '../ModalSplittedTitle'

const LinkServiceSecondaryBar = ({
  tab = '',
  serviceName = '',
  serviceList = {},
  autoLink = false,
  handleLinkedServiceTabChange,
  handleAutoLink,
  tabValue = '',
}) => {
  const allTabs = {
    service: [
      { label: 'General Fee', value: 'general-fee' },
      { label: 'Taxes', value: 'taxes' },
    ],
    tax: [
      { label: 'General Fee', value: 'general-fee' },
      { label: 'Services', value: 'services' },
    ],
    general: [
      { label: 'Services', value: 'services' },
      { label: 'Taxes', value: 'taxes' },
    ],
    disposal: [
      { label: 'Services', value: 'services' },
      { label: 'Taxes', value: 'taxes' },
    ],
  }

  if (serviceList && Array.isArray(serviceList?.disposalFee) && serviceList.disposalFee.length > 0) {
    allTabs.service.splice(1, 0, { label: 'Disposal Fee', value: 'disposal-fee', className: 'disposal-fees' })
    allTabs.tax.splice(1, 0, { label: 'Disposal Fee', value: 'disposal-fee', className: 'disposal-fees' })
  }

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <ModalSplittedTitle title={serviceName} actionsGap={tab !== 'service'} />

        {tab !== 'service' && (
          <Stack flexDirection="row" alignItems="center" justifyContent="center" sx={{ mr: 3, ml: 2 }}>
            <PricingSwitch checked={autoLink} handleSwitchVal={() => handleAutoLink(!autoLink)} />
            <Typography sx={{ ml: 1 }}>Auto-Link </Typography>
          </Stack>
        )}
      </Stack>

      <Box mt={1}>
        {tab === 'service' && (
          <CommonToggleButtonGroup exclusive value={tabValue} onChange={handleLinkedServiceTabChange}>
            {Children.toArray(allTabs.service.map(data => <CommonToggleButton value={data.value}>{data.label}</CommonToggleButton>))}
          </CommonToggleButtonGroup>
        )}

        {tab === 'tax' && (
          <CommonToggleButtonGroup exclusive value={tabValue} onChange={handleLinkedServiceTabChange}>
            {Children.toArray(allTabs.tax.map(data => <CommonToggleButton value={data.value}>{data.label}</CommonToggleButton>))}
          </CommonToggleButtonGroup>
        )}

        {(tab === 'general' || tab === 'disposal') && (
          <CommonToggleButtonGroup exclusive value={tabValue} onChange={handleLinkedServiceTabChange}>
            <CommonToggleButton value="services" className="services">
              Services
            </CommonToggleButton>
            <CommonToggleButton value="taxes" className="taxes">
              Taxes
            </CommonToggleButton>
          </CommonToggleButtonGroup>
        )}
      </Box>
    </>
  )
}

LinkServiceSecondaryBar.propTypes = {
  tabValue: PropTypes.string,
  tab: PropTypes.string,
  serviceName: PropTypes.string,
  serviceList: PropTypes.object,
  handleAutoLink: PropTypes.func.isRequired,
  autoLink: PropTypes.bool,
  handleLinkedServiceTabChange: PropTypes.func.isRequired,
}

export default LinkServiceSecondaryBar
