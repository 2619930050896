import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
  Collapse,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'
import { ReactComponent as RectangleHistoryIcon } from 'assets/RectangleHistoryIcon.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { grey } from '@mui/material/colors'
import LinkifyText from 'components/notes/common/LinkifyText'
import ViewNoteHistory from './ViewNoteHistory'
import StyledBadge from './StyledBadge'

const ViewNoteDialog = ({ id, title = T.NOTE, isOpen = false, selectedNote = {}, useLazyGetNoteHistoryQuery, onClose }) => {
  const [getNoteHistory, { data: historyData, isFetching: isLoading }] = useLazyGetNoteHistoryQuery()
  const [isExpanded, setIsExpanded] = useState(false)

  const noteId = get(selectedNote, 'id')
  const version = get(selectedNote, 'version')
  const historyNotes = get(historyData, 'notes', [])
  const note = get(selectedNote, 'note', '')
  const handleExpandCollapseHistory = () => {
    getNoteHistory({ id, noteId })
      .unwrap()
      .then(() => setIsExpanded(true))
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      if (version > 1) {
        handleExpandCollapseHistory()
        return
      }
      setIsExpanded(false)
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent sx={{ px: 0, mt: 3 }}>
        <DialogContentText sx={{ px: 3 }}>
          <Typography variant="h5" color="textPrimary" fontWeight={400} whiteSpace="pre-line">
            <LinkifyText>{note}</LinkifyText>
          </Typography>
        </DialogContentText>

        {version > 1 && (
          <ListItem
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{
              mt: 3,
              cursor: 'pointer',
              backgroundColor: grey[50],
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderColor: 'divider',
              '& .MuiListItemSecondaryAction-root': { right: 32 },
            }}
            secondaryAction={
              <StyledBadge badgeContent={version}>
                <RectangleHistoryIcon />
              </StyledBadge>
            }
          >
            <IconButton size="small">{isExpanded ? <ExpandMore /> : <ChevronRight />}</IconButton>
            <ListItemText primaryTypographyProps={{ variant: 'h5', color: 'textPrimary', fontWeight: 400 }} primary={T.HISTORY} />
          </ListItem>
        )}

        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          {isLoading && (
            <Box mt={3} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!isLoading && <ViewNoteHistory notes={historyNotes} />}
        </Collapse>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

ViewNoteDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  selectedNote: PropTypes.object,
  useLazyGetNoteHistoryQuery: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ViewNoteDialog
