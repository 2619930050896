import React, { useState } from 'react'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { canAddPricingManager } from 'data/permissions/permissionsSelectors'
import { openPricingDrawer, selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import T from 'T'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { selectIsAddLateFeesDialogOpen, setIsAddLateFeesDialogOpen } from 'slices/pricing/lateFeesSlice'
import LateFeesHeaderActionMenu from 'components/pricing/LateFees/LateFeesListing/LateFeesHeaderActionMenu'
import CreateEditLateFeesDialog from 'components/pricing/LateFees/CreateEditLateFeesDialog/CreateEditLateFeesDialog'

const SecondaryNavbar = () => {
  const addPricingManager = useSelector(canAddPricingManager, shallowEqual)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const isAddLateFeesDialogOpen = useSelector(selectIsAddLateFeesDialogOpen)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDrawer = () => {
    dispatch(openPricingDrawer())
  }

  const handleAddClick = () => {
    dispatch(setIsAddLateFeesDialogOpen(true))
  }

  const handleCloseAddLateFeeDialog = () => {
    dispatch(setIsAddLateFeesDialogOpen(false))
  }

  return (
    <>
      <Box
        alignItems="center"
        bgcolor="background.default"
        display="flex"
        flexDirection="row"
        height={56}
        justifyContent={isTabletOrMobile ? 'normal' : 'space-between'}
        overflow="hidden"
        px={isTabletOrMobile ? 2 : 4}
        width="100%"
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.divider,
        }}
      >
        <Stack flexDirection="row" alignItems="center" columnGap={1} sx={{ flex: 1, flexGrow: 1 }}>
          {!isOpenPricingDrawer && <ReorderIcon sx={{ mr: 1 }} onClick={handleOpenDrawer} />}

          <Typography variant="h4" fontWeight="600">
            {T.LATE_FEES}
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end" columnGap={1}>
          {addPricingManager && (
            <IconButton onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          )}
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <LateFeesHeaderActionMenu anchorEl={anchorEl} onClose={handleClose} />
        </Stack>
      </Box>
      <CreateEditLateFeesDialog open={isAddLateFeesDialogOpen} onClose={handleCloseAddLateFeeDialog} />
    </>
  )
}

SecondaryNavbar.propTypes = {}

export default SecondaryNavbar
