import React from 'react'
import PropTypes from 'prop-types'
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker'
import { Controller } from 'react-hook-form'
import { Box, FormHelperText, InputAdornment, styled } from '@mui/material'
import { Calendar } from '@styled-icons/heroicons-outline'
import noop from 'lodash/noop'
import StyledTextField from './StyledTextField'

const StyledCalendar = styled(Calendar)({
  width: '20px',
  height: '20px',
})

const HHDateRangePicker = ({
  control,
  name,
  rules,
  error = false,
  helperText = '',
  readOnly = false,
  onChange = noop,
  fullWidth = true,
  StartTextFieldProps,
  EndTextFieldProps,
  restSlots = {},
  restSlotProps = {},
  ...rest
}) => {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field }) => {
        const handleChange = value => {
          field.onChange(value)
          onChange(value)
        }
        return (
          <Box>
            <DesktopDateRangePicker
              readOnly={readOnly}
              {...rest}
              {...field}
              onChange={handleChange}
              slots={{ textField: StyledTextField, ...restSlots }}
              slotProps={{
                textField: ({ position }) => ({
                  error,
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledCalendar />
                      </InputAdornment>
                    ),
                  },
                  fullWidth,
                  ...(position === 'start' ? { ...StartTextFieldProps } : { ...EndTextFieldProps }),
                }),
                fieldSeparator: { display: 'none' },
                ...restSlotProps,
              }}
            />
            <FormHelperText sx={{ marginX: '14px' }} error={error}>
              {helperText}
            </FormHelperText>
          </Box>
        )
      }}
    />
  )
}

HHDateRangePicker.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  rules: PropTypes.object,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  StartTextFieldProps: PropTypes.object,
  EndTextFieldProps: PropTypes.object,
  restSlots: PropTypes.object,
  restSlotProps: PropTypes.object,
}

export default HHDateRangePicker
