import isEmpty from 'lodash/isEmpty'
import { get } from 'lodash'

export const extractHash = hash => {
  if (!hash || isEmpty(hash)) {
    return {}
  }
  const trimHash = hash.substring(1)
  const urlParams = {}
  trimHash.split('&').map(urlHash => {
    const urlHashSplit = urlHash.split('=')
    urlParams[urlHashSplit[0]] = urlHashSplit[1]
  })

  return urlParams
}

export const encodeUrlParamsUsingURLSearchParams = config => {
  const params = new URLSearchParams()
  Object.keys(config).forEach(key => {
    const value = get(config, key)
    if (value) {
      params.append(key, value)
    }
  })

  return params.toString()
}

export const encodeUrlParams = config => {
  const params = Object.keys(config)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(config[key])}`)
    .join('&')

  return params
}

export const isV2URL = () => {
  return true
}
