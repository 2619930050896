import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import useTheme from '@mui/material/styles/useTheme'
import { get, noop, orderBy } from 'lodash'
import { toast } from 'react-toastify'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { LoadingButton } from '@mui/lab'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CancelButton from '../../buttons/CancelButton'
import T from '../../../T'
import { isValidHex } from '../../../utils/validations'
import { extractNameInitials } from '../../../utils/string'
import { useLazyGetRouteListQuery } from '../../../api/route/getRouteList'
import { convertDateTimeToPreferedTimezone } from '../../../data/dateTime/convertDateTimeToPreferedTimezone'
import { handleError } from '../../../utils/error'
import { useUndeleteRouteMutation } from '../../../api/route/undeleteRoute'
import { selectRouteIds } from '../../../slices/route/routeSlice'
import { CACHE_TAG_ROUTES_METADATA, CACHE_TAG_ROUTES_METADATA_BY_DATE } from '../../../api/cacheTagTypes'
import api from '../../../api'
import { getSelectedRouteIds } from '../../../data/route/selectedRoutesSelector'

const RouteRecoveryDialog = ({ open = false, onClose = noop, onConfirm = noop, onCancel = noop }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const [undeleteRouteMutation, { isLoading }] = useUndeleteRouteMutation()
  const [getRouteList, { data }] = useLazyGetRouteListQuery({ deleted: true })
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')
  const routes = get(data, 'routes', [])
  const sortedRoutesByName = useMemo(() => orderBy(routes, 'name', 'asc'), [routes])
  const getOptionLabel = option => get(option, 'name')
  const onChange = (_, newValue) => setValue(newValue)
  const onInputChange = (_, newValue) => setInputValue(newValue)
  const filterOptions = options => options.filter(({ name }) => name.toLowerCase().includes(inputValue.toLowerCase()))

  const handleConfirm = async () => {
    const recoveredIds = value.filter(({ id }) => id).map(({ id }) => id)

    const recoveryPromises = value.map(async route => {
      const id = get(route, 'id')
      try {
        await undeleteRouteMutation({ id }).unwrap()
      } catch (error) {
        handleError(error)
      }
    })

    return Promise.all(recoveryPromises).then(() => {
      setTimeout(() => {
        dispatch(selectRouteIds([...selectedRouteIds, ...recoveredIds]))
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES_METADATA, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        toast.success(`${value.length === 1 ? 'Route' : 'Routes'} successfully recovered`)
        onConfirm()
      }, 300)
    })
  }

  useEffect(() => {
    if (open) {
      getRouteList({ deleted: true })
    }
  }, [open])

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title={T.ROUTE_RECOVERY} onClose={onClose} />
      <DialogContent>
        <DialogContentText sx={{ ...theme.typography.body1, color: theme.palette.text.primary }}>
          <Autocomplete
            autoComplete
            sx={{
              mt: 0.5,
              boxSizing: 'content-box',
              '& input': {
                boxSizing: 'content-box',
                [theme.breakpoints.up('sm')]: {
                  flexBasis: '200px',
                },
              },
            }}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            ListboxProps={{
              sx: {
                height: '40vh',
              },
            }}
            multiple
            options={sortedRoutesByName}
            getOptionLabel={getOptionLabel}
            freeSolo
            filterSelectedOptions
            value={value}
            filterOptions={filterOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const bgcolor = get(option, 'color', grey[100])
                const name = get(option, 'name')
                const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
                const color = theme.palette.getContrastText(formattedBgcolor)
                const initials = extractNameInitials(name)
                return (
                  <Chip
                    variant="outlined"
                    label={option?.name}
                    {...getTagProps({ index })}
                    avatar={
                      <Avatar
                        sx={{
                          width: '24px',
                          height: '24px',
                          bgcolor: formattedBgcolor,
                          color,
                        }}
                      >
                        <Typography sx={{ color }} fontWeight={400}>
                          {initials}
                        </Typography>
                      </Avatar>
                    }
                  />
                )
              })
            }
            renderInput={params => <TextField {...params} variant="outlined" placeholder="Select a route" />}
            renderOption={(props, option, { selected }) => {
              const id = get(option, 'id', '')
              const name = get(option, 'name', '')
              const bgcolor = get(option, 'color', grey[100])
              const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
              const color = theme.palette.getContrastText(formattedBgcolor)
              const initials = extractNameInitials(name)
              const lastUpdated = get(option, 'lastUpdated')
              const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: lastUpdated })
              return (
                <li {...props} key={id}>
                  <Checkbox checked={selected} />
                  <ListItem disabbleGutters disablePadding>
                    <ListItemAvatar sx={{ minWidth: '40px' }}>
                      <Avatar
                        sx={{
                          width: '32px',
                          height: '32px',
                          bgcolor: formattedBgcolor,
                          color,
                        }}
                      >
                        <Typography variant="h5" fontWeight={400}>
                          {initials}
                        </Typography>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} secondary={`Updated on ${onlyDate} at ${onlyTime}`} />
                  </ListItem>
                </li>
              )
            }}
          />
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onCancel} />
        <LoadingButton loading={isLoading} disabled={value.length === 0} onClick={handleConfirm} variant="contained" autoFocus>
          Confirm
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

RouteRecoveryDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default RouteRecoveryDialog
