import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import {
  useLazyGetAllWorkOrderNotesQuery,
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
} from 'api/notes/workOrderNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import WONotes from './WONotes'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import NoteSkeleton from '../common/NoteSkeleton'

const WONotesDialogVariant = ({ isOpen = false, workOrderId = '', getCachedData = false, onClose }) => {
  const [getAllWorkOrderNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllWorkOrderNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddWONote = () => {
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleCloseAddWONote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (isOpen && workOrderId) {
      getAllWorkOrderNotes({ id: workOrderId }, getCachedData).unwrap().catch(handleError)
    }
  }, [isOpen, workOrderId])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={`${T.WORK_ORDER_NOTE}s`} onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          {isGetAllNotesLoading && <NoteSkeleton rows={1} />}
          {!isGetAllNotesLoading && (
            <WONotes
              itemSx={{
                [`.MuiListItemSecondaryAction-root`]: {
                  right: 12,
                },
              }}
              notes={notes}
              workOrderId={workOrderId}
              noNotesText={T.NO_WORK_ORDER_NOTES}
            />
          )}
        </DialogContent>
        <HHDialogActions>
          <Button variant="outlined" onClick={handleOpenAddWONote}>
            {T.CREATE_NOTE}
          </Button>
          <Button variant="contained" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <AddUpdateNoteDialog
        id={workOrderId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_WORK_ORDER_NOTE}
        useCreateNoteMutation={useCreateWorkOrderNoteMutation}
        useUpdateNoteMutation={useUpdateWorkOrderNoteNewMutation}
        onClose={handleCloseAddWONote}
      />
    </>
  )
}

WONotesDialogVariant.propTypes = {
  isOpen: PropTypes.bool,
  workOrderId: PropTypes.string,
  getCachedData: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default WONotesDialogVariant
