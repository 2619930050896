import api from 'api'
import { CACHE_TAG_ACCOUNT_SETTINGS } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    bulkUpdateAccountSettings: build.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/groups/${id}/settings`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: [CACHE_TAG_ACCOUNT_SETTINGS],
    }),
  }),
})

export const { useBulkUpdateAccountSettingsMutation } = extendedApi
