import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getYardList: build.query({
      query: () => ({
        url: 'api/settings/yard/list',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useLazyGetYardListQuery } = extendedApi
