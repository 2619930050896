import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { get, keyBy } from 'lodash'
import { ArrowBack } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import {
  selectBulkAssignFromRouteStopMarkers,
  selectBulkAssignFromRows,
  selectBulkAssignToRouteSequenceLine,
  selectBulkAssignToRouteStopMarkers,
  selectBulkAssignToRows,
  setBulkAssignStopsState,
} from 'slices/route/bulkAssignStopsSlice'
import PropTypes from 'prop-types'
import BulkMoveColumnHeader from 'components/route-manager/BulkActions/common/BulkMoveColumnHeader'
import useBulkMoveSelectedRows from 'components/route-manager/BulkActions/common/useBulkMoveSelectedRows'

const RenderMoveToHeader = ({ routes }) => {
  const routesById = keyBy(routes, 'id')
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const selectedRowsCount = get(selectedRowIdsMap, 'size', 0)
  const moveToRows = useSelector(selectBulkAssignToRows)
  const moveFromRows = useSelector(selectBulkAssignFromRows)
  const moveFromRouteStopMarkers = useSelector(selectBulkAssignFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkAssignToRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkAssignToRouteSequenceLine)
  const moveSelectedRows = useBulkMoveSelectedRows({
    moveToRows,
    moveFromRows,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    routesById,
    moveToRouteSequenceLine,
    bulkReduxAction: setBulkAssignStopsState,
  })

  return <BulkMoveColumnHeader onClick={moveSelectedRows} selectedRowsCount={selectedRowsCount} endIcon={<ArrowBack />} />
}

RenderMoveToHeader.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default RenderMoveToHeader
