import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { set, omit, capitalize, find, trim } from 'lodash'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useForm, FormProvider } from 'react-hook-form'

import { Grid, Button, DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { usePreviousValue } from 'utils/react'
import { addCustomer } from 'middleware/actions/customers'
import { getCustomerDetailsPageUrl, isCustomerDetailsPage } from 'router/routes'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import DragPinMessage from 'components/common/address/DragPinMessage'
import MapView from '../../customer-details/content/location/MapView'
import FormFields from './FormFields'
import { INITIAL_EMAIL_CONTACT_METHOD, INITIAL_PHONE_CONTACT_METHOD, NEW_ACCOUNT_INITIAL_STATE } from './model'

const { ACCOUNT_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const NewCustomer = ({ isOpen, phone = '', email = '', onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: accountMeta } = useGetAccountMetaQuery()
  const billingProfiles = get(accountMeta, 'billingProfiles', [])
  const defaultProfile = useMemo(() => find(billingProfiles, ['default', true]) || get(billingProfiles, '[0]', null), [billingProfiles])

  const { latitude: initialLatitude, longitude: initialLongitude } = NEW_ACCOUNT_INITIAL_STATE.address
  const [latLngForMap, setLatLngForMap] = useState({
    latitudeForMap: initialLatitude,
    longitudeForMap: initialLongitude,
  })
  const [latLngForMapHistory, setLatLngForMapHistory] = useState({
    latitudeForMapHistory: initialLatitude,
    longitudeForMapHistory: initialLongitude,
  })

  const { latitudeForMap, longitudeForMap } = latLngForMap
  const { latitudeForMapHistory, longitudeForMapHistory } = latLngForMapHistory

  const newAccountForm = useForm({ defaultValues: { ...NEW_ACCOUNT_INITIAL_STATE } })
  const { handleSubmit, setValue, reset, watch, setFocus } = newAccountForm
  const watchAccountName = watch('accountName')
  const watchLocationName = watch('address.addressName')
  const accountNamePastState = usePreviousValue(watchAccountName)

  useEffect(() => {
    if (!watchLocationName || accountNamePastState === watchLocationName) {
      setValue('address.addressName', watchAccountName)
    }
  }, [watchAccountName])

  const onSubmitHandler = data => {
    const { accountName, address, useAsBillingAddress, contact, contactPhone, contactEmail, billingProfileId } = data
    const billingAddress = useAsBillingAddress ? address : NEW_ACCOUNT_INITIAL_STATE.address

    const payload = {
      billedForOtherCustomer: false,
      accountName: trim(accountName),
      billingAddress: omit(billingAddress, ['addressName']),
      location: { address, tags: [] },
      tags: [],
      accountBilling: { billingProfileId },
    }

    if (contact?.firstName || contact?.lastName || contactPhone || contactEmail) {
      const contactMethods = []
      if (contactPhone) {
        contactMethods.push({ ...INITIAL_PHONE_CONTACT_METHOD, methodValue: contactPhone })
      }
      if (contactEmail) {
        contactMethods.push({ ...INITIAL_EMAIL_CONTACT_METHOD, methodValue: contactEmail })
      }
      set(payload, 'contacts', [{ ...contact, associatedWithAccount: true, associatedWithLocation: true, contactMethods }])
    }

    dispatch(
      addCustomer(payload, async (status, res) => {
        const accountId = get(res, 'id', '')
        if (status && accountId) {
          onClose()
          // redirect to customer details page
          const newLink = `${getCustomerDetailsPageUrl(accountId)}?tab=${ACCOUNT_TAB_ID}`
          if (isCustomerDetailsPage()) {
            window.location.href = newLink
            return
          }
          navigate(newLink)
        }
      })
    )
  }

  const handleLatLongFromMapView = (long, lat) => {
    setValue('address.longitude', long)
    setValue('address.latitude', lat)
    setLatLngForMap({ longitudeForMap: long, latitudeForMap: lat })
  }

  useEffect(() => {
    if (isOpen) {
      reset({
        ...NEW_ACCOUNT_INITIAL_STATE,
        ...(phone && { contactPhone: phone }),
        ...(email && { contactEmail: email }),
      })
      setLatLngForMapHistory({ longitudeForMapHistory: initialLongitude, latitudeForMapHistory: initialLatitude })
      handleLatLongFromMapView(initialLongitude, initialLatitude)
      setTimeout(() => setFocus('address.line1'), 100)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && defaultProfile?.id) {
      setValue('billingProfileId', defaultProfile.id)
    }
  }, [isOpen, defaultProfile])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth disableScrollLock>
      <HHDialogTitle title={capitalize(T.NEW_CUSTOMER)} onClose={onClose} />
      <DialogContent>
        <Grid container mt={2} columnGap={3}>
          <Grid item xs={12} md spacing={2}>
            <Grid item xs={12} height={470}>
              <MapView
                longitude={longitudeForMap}
                latitude={latitudeForMap}
                longitudeForMapHistory={longitudeForMapHistory}
                latitudeForMapHistory={latitudeForMapHistory}
                onChange={({ mapLng, mapLat }) => handleLatLongFromMapView(mapLng, mapLat)}
                onReset={() => handleLatLongFromMapView(longitudeForMapHistory, latitudeForMapHistory)}
              />
            </Grid>
            <DragPinMessage latitude={latitudeForMap} longitude={longitudeForMap} sx={{ mt: 2 }} />
          </Grid>
          <FormProvider {...newAccountForm}>
            <FormFields
              onEnter={handleSubmit(onSubmitHandler)}
              setLatLngForMap={setLatLngForMap}
              setLatLngForMapHistory={setLatLngForMapHistory}
            />
          </FormProvider>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <Button size="small" variant="contained" onClick={handleSubmit(onSubmitHandler)}>
          {T.CREATE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

NewCustomer.propTypes = {
  isOpen: PropTypes.bool,
  phone: PropTypes.string,
  email: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default NewCustomer
