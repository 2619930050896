import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Popover } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { useLazyGetAllAccountNotesQuery } from 'api/notes/accountNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import AccountNotes from 'components/notes/account/AccountNotes'
import NoteSkeleton from 'components/notes/common/NoteSkeleton'

const ViewAccountNotesPopover = ({ accountId, anchorEl, onClose }) => {
  const isOpen = Boolean(anchorEl)
  const [getAllAccountNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllAccountNotesQuery()
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  useEffect(() => {
    if (isOpen && accountId) {
      getAllAccountNotes({ accountId }).unwrap().catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={onClose}
        disableRestoreFocus
        slotProps={{ paper: { sx: { width: 'max-content' } } }}
      >
        {isGetAllNotesLoading && (
          <Box minWidth={300}>
            <NoteSkeleton rows={1} showActions={false} />
          </Box>
        )}
        <Box>{!isGetAllNotesLoading && <AccountNotes notes={notes} accountId={accountId} showSecondaryActions={false} />}</Box>
      </Popover>
    </>
  )
}

ViewAccountNotesPopover.propTypes = {
  accountId: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
}

export default memo(ViewAccountNotesPopover)
