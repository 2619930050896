import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getBulkCompleteStops: builder.mutation({
      query: body => ({ url: '/api/v1/core/work-orders/bulk-complete', method: 'PUT', body }),
    }),
  }),
})

export const { useGetBulkCompleteStopsMutation } = extendedApi
