import React from 'react'
import { Alert, Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { useSelector } from 'react-redux'
import FilterListIcon from '@mui/icons-material/FilterList'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { getViewMode } from '../../data/route/viewModeSelector'
import { VIEW_MODE_MAP } from '../../slices/route/routeSlice'
import { getIsUnassignedStopsOpen } from '../../data/route/unassignedStopsSelector'

const NoRoutesEmptyState = () => {
  const theme = useTheme()
  const viewMode = useSelector(getViewMode)
  const isMapView = viewMode === VIEW_MODE_MAP
  const isUnassignedStopsOpen = useSelector(getIsUnassignedStopsOpen)
  const boardWidth = isUnassignedStopsOpen ? 'calc(100vw - 378px)' : '100vw'
  const marginRight = isUnassignedStopsOpen && !isMapView ? '378px' : '0px'

  return (
    <Box
      overflow="hidden"
      bgcolor={isMapView ? theme.palette.primary.light : 'inherit'}
      height={isMapView ? 'calc(100vh - 76px)' : 'calc(100vh - 245px)'}
      width={isMapView ? '100%' : boardWidth}
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Alert
        icon={isMapView ? <FilterListIcon /> : <InfoOutlinedIcon />}
        severity="info"
        sx={{
          marginRight,
          ...theme.typography.body1,
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        Select a route from the filter above to
        <br /> see the route on the {isMapView ? ' map' : ' board'}
      </Alert>
    </Box>
  )
}

export default NoRoutesEmptyState
