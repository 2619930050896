import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'
import round from 'lodash/round'

const getOnlyNumber = value => `${value}`.replace(/[^\d.-]/g, '')

export const fixAmount = amount => {
  if (typeof amount === 'string') {
    return parseFloat(amount).toFixed(2)
  }

  if (typeof amount === 'number') {
    return amount.toFixed(2)
  }

  return amount
}

export const roundAndFix = amount => fixAmount(round(amount, 2))

export const formatDollarsToCents = value => {
  const onlyNumber = getOnlyNumber(value)
  return onlyNumber ? Math.round(parseFloat(onlyNumber) * 100) : 0
}

export const formatCentsToDollars = (value, forDisplay) => {
  const onlyNumber = parseFloat(getOnlyNumber(value))
  const result = onlyNumber ? onlyNumber / 100 : 0
  return forDisplay ? fixAmount(result) : result
}

export const calculateSum = (arr, key) => {
  if (!Array.isArray(arr) || !arr.length) {
    return 0
  }

  return key ? sumBy(arr, key) : sum(arr)
}

export const shortenMoneyValue = (value, minimumFractionDigits = 2, maximumFractionDigits = 2, prefix = '') => {
  const suffixes = ['', 'K', 'M', 'B', 'T', 'P', 'E']
  const isNegative = value < 0
  const absoluteValue = Math.abs(value)

  const tier = Math.floor(Math.log10(absoluteValue) / 3)

  if (tier === 0) {
    return `${isNegative ? '-' : ''}${prefix}${value.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits })}`
  }

  const suffix = suffixes[tier]
  const scale = 10 ** (tier * 3)
  const scaledValue = absoluteValue / scale
  return `${isNegative ? '-' : ''}${prefix}${scaledValue.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })}${suffix}`
}
