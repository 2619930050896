import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getExistingTaxes: build.query({
      query: body => ({ url: '/api/v1/pricing/tax/exists', method: 'POST', body }),
    }),
  }),
})
export const { useLazyGetExistingTaxesQuery } = extendedApi
