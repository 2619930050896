import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemText, ListItemIcon, styled } from '@mui/material'

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  '& .MuiListItemText-primary': { ...theme.typography.body1, fontWeight: 500, color: theme.palette.text.secondary, minWidth: 150 },
  '& .MuiListItemText-secondary': { ...theme.typography.h6, fontWeight: 400, color: theme.palette.text.primary },
}))

const StledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 24,
  '& svg': { width: 16, height: 16 },
}))

const StopListItem = ({ icon, primary = '', secondary = '' }) => {
  return (
    <ListItem sx={{ px: 3, py: 0.5 }}>
      <StledListItemIcon>{icon}</StledListItemIcon>
      <StyledListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )
}

StopListItem.propTypes = {
  icon: PropTypes.node,
  primary: PropTypes.string,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
}

export default StopListItem
