import React from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, useTheme } from '@mui/material'
import T from 'T'
import CancelButton from 'components/buttons/CancelButton'
import Tooltip from '@mui/material/Tooltip'
import SaveButton from 'components/buttons/SaveButton'
import DialogActions from '@mui/material/DialogActions'
import { useFormContext } from 'react-hook-form'
import { handleError } from 'utils/error'
import { get } from 'lodash'
import api from 'api'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import { setBulkMoveFailure } from 'slices/route/routeSlice'
import {
  selectBulkMoveFromSerializedDate,
  selectBulkMoveInvalidStopsToBeAssignedCount,
  selectBulkMoveRouteId,
  selectBulkMoveToSerializedDate,
  selectBulkMoveValidStopIdsToBeAssigned,
  setBulkMoveBetweenDaysState,
} from 'slices/route/bulkMoveBetweenDaysSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import { deserializeDate } from 'utils/date'

const BulkMoveStopsBetweenDaysDialogActions = ({ onReset, getBEBatches, onClose, onConfirmSelectDateDialog }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { handleSubmit } = useFormContext()
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const routeId = useSelector(selectBulkMoveRouteId)
  const invalidStopsToBeAssignedCount = useSelector(selectBulkMoveInvalidStopsToBeAssignedCount)
  const validStopsToBeAssigned = useSelector(selectBulkMoveValidStopIdsToBeAssigned)
  const validStopsToBeAssignedCount = get(validStopsToBeAssigned, 'length', 0)
  const isSaveButtonDisabled = invalidStopsToBeAssignedCount > 0 || validStopsToBeAssignedCount === 0
  const submitValidTitle =
    validStopsToBeAssignedCount > 0
      ? 'This does not affect future stops of these services'
      : 'This does not affect future stops of this service'
  const submitTitle = invalidStopsToBeAssignedCount > 0 ? `${invalidStopsToBeAssignedCount} stop left to assign` : submitValidTitle
  const onValidSubmit = async () => {
    const beBatches = getBEBatches()
    bulkMoveStops({ moves: beBatches })
      .unwrap()
      .then(data => {
        const failedStops = get(data, 'stops', []).map(s => get(s, 'stop.accountName'))
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        if (failedStops.length !== 0) {
          const savedData = structuredClone({ fromDate: new Date(fromDate), toDate: new Date(toDate), routeId })
          dispatch(setBulkMoveFailure({ isOpen: true, stops: failedStops, fetchId: crypto.randomUUID() }))
          onConfirmSelectDateDialog(savedData)
        } else {
          onReset(false)
          dispatch(
            setBulkMoveBetweenDaysState({
              isOpen: false,
              isUnsavedChangesModalOpen: false,
            })
          )
        }
      })
      .catch(handleError)
  }

  return (
    <DialogActions sx={{ flex: '0 0 48px' }}>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flex={1}>
        <Button size="small" variant="outlined" color="warning" onClick={onReset}>
          {T.RESET}
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
        <CancelButton size="small" onClick={onClose} />
        <Tooltip PopperProps={{ sx: { zIndex: theme.zIndex.intercom + 1 } }} arrow title={submitTitle}>
          <span>
            <SaveButton
              size="small"
              loading={isLoading}
              disabled={isSaveButtonDisabled}
              label={T.SAVE_TARGET_DAY}
              onClick={handleSubmit(onValidSubmit)}
            />
          </span>
        </Tooltip>
      </Stack>
    </DialogActions>
  )
}

BulkMoveStopsBetweenDaysDialogActions.propTypes = {
  onReset: PropTypes.func.isRequired,
  getBEBatches: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmSelectDateDialog: PropTypes.func.isRequired,
}

export default BulkMoveStopsBetweenDaysDialogActions
