import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addTrucks: build.mutation({
      query: body => ({
        url: '/api/settings/asset/add',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useAddTrucksMutation } = extendedApi