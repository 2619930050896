import React, { memo } from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { createDateFromString, formatDateToFEFormatDateFns, isValidDate } from 'utils/date'
import WOReviewDetailsCardLeftSection from './WOReviewDetailsCardLeftSection'
import WOReviewDetailsCardRightSection from './WOReviewDetailsCardRightSection'

const RenderWOReviewDetailsCardCell = ({ row }) => {
  const name = get(row, 'accountName')
  const id = get(row, 'id')
  const accountId = get(row, 'accountId')
  const line1 = get(row, 'billingAddressLine1')
  const unitNumber = get(row, 'billingAddressUnitNumber')
  const city = get(row, 'billingAddressCity')
  const state = get(row, 'billingAddressState')
  const zipCode = get(row, 'billingAddressZipCode')
  const action = get(row, 'eventActionType')
  const woStatus = get(row, 'workOrderStatus')
  const invoiceStatus = get(row, 'invoiceStatus')
  const serviceType = get(row, 'serviceType')
  const workOrderSequence = get(row, 'workOrderNumber')
  const accountNumber = get(row, 'accountNumber')
  const configuredServiceId = get(row, 'configuredServiceId')
  const disposalTicketAmountCents = get(row, 'disposalTicketAmountCents', null)
  const workOrderFees = get(row, 'workOrderFees', [])
  const date = get(row, 'serviceDate')
  const serviceDate = isValidDate(date) && formatDateToFEFormatDateFns(createDateFromString(date))
  const routeId = get(row, 'routeId')
  const rentalFeeCount = get(row, 'rentalFeeCount', 0)

  const secondLineLeftStr = [city, state].filter(Boolean).join(', ')
  const secondLine = [secondLineLeftStr, zipCode].filter(Boolean).join(' ')

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'calc(100% - 24px)',
        height: '160px',
        borderRadius: '8px',
        mt: 0.5,
        mp: 0.5,
      }}
    >
      <CardContent>
        <Grid container columnSpacing={2}>
          <Grid item xs={4} sx={{ borderRight: '1px solid', pr: 2, borderRightColor: 'border.light' }}>
            <WOReviewDetailsCardLeftSection
              id={id}
              accountId={accountId}
              name={name}
              line1={line1}
              unitNumber={unitNumber}
              secondLine={secondLine}
              workOrderSequence={workOrderSequence}
              accountNumber={accountNumber}
              serviceDate={serviceDate}
            />
          </Grid>
          <Grid container item xs>
            <WOReviewDetailsCardRightSection
              workOrderFees={workOrderFees}
              id={id}
              action={action}
              serviceType={serviceType}
              woStatus={woStatus}
              disposalTicketAmountCents={disposalTicketAmountCents}
              invoiceStatus={invoiceStatus}
              routeId={routeId}
              configuredServiceId={configuredServiceId}
              rentalFeeCount={rentalFeeCount}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

RenderWOReviewDetailsCardCell.propTypes = {
  row: PropTypes.object.isRequired,
}

export default memo(RenderWOReviewDetailsCardCell)
