import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { getAssociations } from '../../../customers/edit/utils'

export const getContactState = (customerDetails, contact) => {
  const contactState = cloneDeep(contact)
  const { associatedLocations } = getAssociations(customerDetails, contact.id)
  set(contactState, 'associatedLocations', associatedLocations)
  set(contactState, 'associatedWithLocation', associatedLocations.length > 0)

  return contactState
}
