import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { get } from 'utils/lodash'
import { Z_INDEX } from 'theme/zIndex'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Menu, Box, Link, MenuItem, SvgIcon, useTheme } from '@mui/material'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import AccountSelectionDropdown from 'containers/select-account/AccountSelectionDropdown'

const { MENU } = Z_INDEX

const UserMenu = ({ anchorEl, menuitems = [], onClose, onClick }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedBusinessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const allBusinesses = useSelector(state => get(state, 'LoginPersist.allBusinesses', []), shallowEqual)
  const OnlyOneBusiness = allBusinesses.length === 1

  const renderMenuItem = menuItem => (
    <>
      {menuItem?.icon && (
        <SvgIcon sx={{ width: 57, color: theme.palette.action.active }} fontSize="small">
          {menuItem.icon}
        </SvgIcon>
      )}
      <Box ml={1} width="100%">
        {menuItem.title}
      </Box>
    </>
  )

  const handleBusinessChange = event => {
    const newBusinessId = event.target.value
    const currentBusiness = allBusinesses.find(business => business.businessId === newBusinessId)
    dispatch(
      setSelectedLoginDetails({
        show: false,
        businessName: get(currentBusiness, 'businessName'),
        businessId: get(currentBusiness, 'businessId'),
      })
    )
    setTimeout(() => window.location.reload(), 100)
  }

  return (
    <Menu
      disableEnforceFocus
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ zIndex: MENU, mt: 4.5 }}
      id="menu-appbar"
      slotProps={{ paper: { sx: { '& ul': { p: 0 } } } }}
    >
      <AccountSelectionDropdown
        readOnly={OnlyOneBusiness}
        value={selectedBusinessId}
        onChange={handleBusinessChange}
        sx={{ pointerEvents: OnlyOneBusiness ? 'none' : 'inherit', '& fieldset': { border: 'none' } }}
        {...(OnlyOneBusiness && { inputProps: { IconComponent: () => null } })}
      />
      {Children.toArray(
        menuitems.map(menuItem =>
          menuItem?.type === 'link' ? (
            <MenuItem sx={{ p: 0, height: 40, display: 'flex', borderTop: '1px solid', borderColor: 'divider' }}>
              <Link
                sx={{ width: '100%', textDecoration: 'none', color: 'inherit', display: 'flex', py: 0.75, px: 2 }}
                href={menuItem.url}
                target="_blank"
                rel="noopener"
                onClick={onClose}
              >
                {renderMenuItem(menuItem)}
              </Link>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={event => onClick(event, get(menuItem, 'route', ''))}
              sx={{ height: 40, display: 'flex', borderTop: '1px solid', borderColor: 'divider' }}
            >
              {renderMenuItem(menuItem)}
            </MenuItem>
          )
        )
      )}
    </Menu>
  )
}

UserMenu.propTypes = {
  anchorEl: PropTypes.object,
  menuitems: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default UserMenu
