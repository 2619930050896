import React from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import capitalize from 'lodash/capitalize'

import T from 'T'
import { get } from 'utils/lodash'
import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import SettingsTable from '../../../SettingsTable'

const columns = [
  { key: 'name', name: T.NAME },
  { key: 'role', name: T.ROLE },
  { key: 'phone', name: `${T.PHONE} ${T.NUMBER}` },
  { key: 'status', name: T.STATUS, align: 'right' },
]

const ListUsers = ({ userState, isLoading = false, users = [], handlePageChange, handleRowsPerPageChange, handleEdit }) => {
  const data = orderBy(
    users?.map(({ id, firstName, lastName, profileName, phoneNumber, userStatus }) => {
      return {
        id,
        name: `${firstName} ${lastName}`,
        role: profileName,
        phone: <HHPhoneField displayType="text" value={phoneNumber} />,
        status: capitalize(userStatus),
      }
    }),
    ['status', user => user.name.toLowerCase()]
  )

  const pageStart = userState.page * userState.rowsPerPage
  const pageEnd = userState.rowsPerPage * (userState.page + 1)
  const numberOfPages = Math.ceil(userState.totalUsersCount / userState.rowsPerPage)

  return (
    <SettingsTable
      columns={columns}
      data={data.slice(pageStart, pageEnd)}
      isLoading={isLoading}
      paginated
      onEditRecord={({ id }) => handleEdit(id)}
      page={userState.page}
      onPageChange={handlePageChange}
      size={userState.rowsPerPage}
      onRowsPerPageChange={handleRowsPerPageChange}
      count={userState.totalUsersCount}
      pageCount={numberOfPages}
    />
  )
}

ListUsers.propTypes = {
  userState: PropTypes.object,
  users: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRowsPerPageChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleEdit: PropTypes.func,
}

export default ListUsers
