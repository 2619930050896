import { styled } from '@mui/system'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { grey, lightBlue } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Box } from '@mui/material'
import React from 'react'
import { HEADER_HEIGHT, ROW_HEIGHT } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/settings'
import { handleMUIPreventCellKeyDown } from 'utils/datagrid'
import { listItemTextClasses } from '@mui/material/ListItemText'

const WrappedDataGridPro = ({
  checkboxSelection = true,
  disableChildrenFiltering = true,
  disableSelectionOnClick = true,
  disableColumnReorder = true,
  disableColumnMenu = true,
  hideFooter = true,
  getRowId = row => get(row, 'id'),
  headerHeight = HEADER_HEIGHT,
  rowHeight = ROW_HEIGHT,
  onCellKeyDown = handleMUIPreventCellKeyDown,
  ...rest
}) => {
  const slots = get(rest, 'slots', {})

  const NoRowsOverlay = () => <Box />

  return (
    <DataGridPro
      slots={{
        noRowsOverlay: NoRowsOverlay,
        ...slots,
      }}
      getRowId={getRowId}
      checkboxSelection={checkboxSelection}
      disableChildrenFiltering={disableChildrenFiltering}
      disableRowSelectionOnClick={disableSelectionOnClick}
      disableColumnReorder={disableColumnReorder}
      disableColumnMenu={disableColumnMenu}
      columnHeaderHeight={headerHeight}
      rowHeight={rowHeight}
      hideFooter={hideFooter}
      onCellKeyDown={onCellKeyDown}
      {...rest}
    />
  )
}

WrappedDataGridPro.propTypes = {
  checkboxSelection: PropTypes.bool,
  disableChildrenFiltering: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  disableColumnReorder: PropTypes.bool,
  disableColumnMenu: PropTypes.bool,
  hideFooter: PropTypes.bool,
  getRowId: PropTypes.func,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  onCellKeyDown: PropTypes.func,
}

const BulkMoveStopsDataGridPro = styled(WrappedDataGridPro)(({ theme }) => ({
  border: 0,
  backgroundColor: theme.palette.background.paper,
  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
    border: 0,
    padding: 0,
    borderTop: `${theme.palette.divider} solid 1px`,
    borderBottom: `${theme.palette.divider} solid 1px`,
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiDataGrid-cellCheckbox': {
    paddingTop: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: 0,
  },
  [`& .${gridClasses.cellCheckbox}`]: {
    alignItems: 'center',
  },
  [`& .${gridClasses.row}`]: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: `${lightBlue[700]}14`,
    },
  },
  [`& .${gridClasses.row}.from-stop-to-be-assigned`]: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: `${theme.palette.warning.light}1F`,
    },
    backgroundColor: `${theme.palette.warning.light}1F`,
  },
  [`& .${gridClasses.row}.from-stop-assigned`]: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: `${lightBlue[700]}14`,
    },
    backgroundColor: `${lightBlue[700]}14`,
  },
  [`& .${gridClasses.row}.disabled`]: {
    [`& .${listItemTextClasses.primary}`]: {
      color: theme.palette.text.secondary,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[100],
    },
    backgroundColor: grey[100],
  },
}))

export default BulkMoveStopsDataGridPro
