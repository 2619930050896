import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { MenuItem, Box, Grid } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import { UNIT_OF_MEASURE } from 'settings/constants/generalSettings'

import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'

import T from 'T'

import { get } from 'utils/lodash'
import { isValidPrice } from 'utils/validations'

const DisposalSiteMaterials = ({ disposalState = {}, setDisposalState = noop, index = 0, handleRemoval = noop }) => {
  const dispatch = useDispatch()

  const { disposalSiteMaterials } = disposalState

  useEffect(() => {
    dispatch(getGlobalFilterOptions())
  }, [])

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const onHandleChange = (e, type) => {
    if (type === 'tippingFee' && e.target.value !== '' && !isValidPrice(e.target.value)) return

    if (disposalSiteMaterials[index] === undefined) {
      disposalSiteMaterials[index] = {}
    }
    disposalSiteMaterials[index][type] = type === 'materials' ? { id: e.target.value } : e.target.value
    setDisposalState({ disposalSiteMaterials, footerFlag: disposalState.name !== '' })
  }

  return (
    <Box mt={0.6}>
      {(disposalSiteMaterials[index] === undefined ||
        (disposalSiteMaterials[index] !== undefined && disposalSiteMaterials[index].deleted === undefined)) && (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <HHTextField
              fullWidth
              defaultSize
              label={index === 0 && `${T.MATERIAL}s`}
              select
              value={[get(disposalSiteMaterials[index], 'materials.id', '')]}
              onChange={e => onHandleChange(e, 'materials')}
            >
              <MenuItem value="">Select</MenuItem>
              {get(globalFilterOptions, 'materials', [])
                .filter(rec => rec.active)
                .map(data => (
                  <MenuItem value={data.id}>{data.materialType}</MenuItem>
                ))}
            </HHTextField>
          </Grid>
          <Grid item>
            <HHTextField
              defaultSize
              label={index === 0 && T.TIPPING_FEE}
              placeholder={T.TIPPING_FEE}
              value={get(disposalSiteMaterials[index], 'tippingFee', '')}
              variant="outlined"
              autoComplete="off"
              size="medium"
              defaultSize
              name="tippingFee"
              onChange={event => onHandleChange(event, 'tippingFee')}
            />
          </Grid>
          <Grid item xs={3}>
            <HHTextField
              fullWidth
              defaultSize
              label={index === 0 && T.UNIT_OF_MEASURE}
              select
              value={[get(disposalSiteMaterials[index], 'unitOfMeasure', '')]}
              onChange={e => onHandleChange(e, 'unitOfMeasure')}
            >
              <MenuItem value="">Select</MenuItem>
              {UNIT_OF_MEASURE.map(data => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </HHTextField>
          </Grid>
          <Grid item display="flex" alignItems="center">
            {index > 0 && <HighlightOffIcon onClick={() => handleRemoval(index, 'disposal-site-materials')} fontSize="small" />}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

DisposalSiteMaterials.propTypes = {
  disposalState: PropTypes.object,
  index: PropTypes.number,
  setDisposalState: PropTypes.func,
  handleRemoval: PropTypes.func,
}

export default DisposalSiteMaterials
