import uniq from 'lodash/uniq'
import { NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'
import { get } from 'utils/lodash'

export const getTagIds = (tags = {}, includeNoTags = true) => {
  const forAccount = get(tags, 'forAccount', []).map(({ id }) => id)
  const forLocation = get(tags, 'forLocation', []).map(({ id }) => id)
  const forService = get(tags, 'forService', []).map(({ id }) => id)
  const tagIds = [...forAccount, ...forLocation, ...forService]

  if (tagIds.length > 0) {
    return uniq(tagIds)
  }

  return includeNoTags ? [NO_TAGS_OPTION_ID] : []
}
