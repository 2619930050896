import api from 'api'
import { CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS } from 'api/cacheTagTypes'
import { get } from 'utils/lodash'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountContacts: builder.query({
      query: body => ({ url: '/api/v1/core/accounts/contacts/get', method: 'POST', body }),
      providesTags: (result, error, { accountIds }) => [
        CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS,
        { type: CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS, id: get(accountIds, '[0]') },
      ],
    }),
  }),
})

export const { useLazyGetAccountContactsQuery } = extendedApi
