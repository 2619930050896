import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'

import FeeAdjustmentTableBody from './FeeAdjustmentTableBody'
import FeeAdjustmentTableHeader from './FeeAdjustmentTableHeader'

import './style.scss'

const FeesAdjustmentTable = ({
  feesList = [],
  adjustedTotal = 0,
  instanceFeeList = [],
  filteredinstanceFeeList = [],
  onStateChange,
  onAddRemoveFee,
  onRowInlineClick,
  onRowDataChange,
}) => (
  <>
    <TableContainer sx={{ mt: 1 }} className="invoice-edit-container fee-adjustment-table">
      <TableView stickyHeader aria-label="sticky table">
        <FeeAdjustmentTableHeader />
        <FeeAdjustmentTableBody
          feesList={feesList}
          adjustedTotal={adjustedTotal}
          instanceFeeList={instanceFeeList}
          filteredinstanceFeeList={filteredinstanceFeeList}
          onStateChange={onStateChange}
          onAddRemoveFee={onAddRemoveFee}
          onRowInlineClick={onRowInlineClick}
          onRowDataChange={onRowDataChange}
        />
      </TableView>
    </TableContainer>
  </>
)

FeesAdjustmentTable.propTypes = {
  feesList: PropTypes.array,
  adjustedTotal: PropTypes.number,
  instanceFeeList: PropTypes.array,
  filteredinstanceFeeList: PropTypes.array,
  onStateChange: PropTypes.func.isRequired,
  onAddRemoveFee: PropTypes.func.isRequired,
  onRowInlineClick: PropTypes.func.isRequired,
  onRowDataChange: PropTypes.func.isRequired,
}

export default FeesAdjustmentTable
