import React, { useEffect } from 'react'
import uniq from 'lodash/uniq'

import { useParams } from 'react-router-dom-v5-compat'
import { shallowEqual, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import Box from '@mui/material/Box'

import { get } from 'utils/lodash'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'

import CustomerSideNav from 'components/customers/common/side-nav/CustomerSideNav'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import AccountGroupDetailsHeader from 'components/customers/groups/account/details/AccountGroupDetailsHeader'
import AccountGroupList from 'components/customers/groups/account/details/AccountGroupList'

import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import { FORM_MODEL } from './settings'

const AccountGroupDetails = () => {
  const { id } = useParams()
  const { data } = useGetAccountGroupDetailsData(id, false)
  const [getAccountMeta, { isSuccess: isAccountMetaSuccess }] = useLazyGetAccountMetaQuery()
  const accoutGroupDetailsForm = useForm({ defaultValues: { ...FORM_MODEL } })
  const { reset } = accoutGroupDetailsForm

  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const customerMeta = useSelector(getAccountMetaData, shallowEqual)

  useEffect(() => {
    if (id && data && isAccountMetaSuccess) {
      const accounts = get(data, 'accounts', [])
      const dataBillingProfileIds = uniq(accounts.map(({ billingProfileId }) => billingProfileId))
      const dataTagIds = uniq(accounts.map(({ tagIds }) => tagIds).flat())
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      const selectedTags = allTags.filter(({ id }) => dataTagIds.includes(id))
      const selectedBillingProfiles = billingProfiles.filter(({ id }) => dataBillingProfileIds.includes(id))

      reset({
        ...FORM_MODEL,
        tagList: selectedTags,
        selectedTags,
        billingProfileList: selectedBillingProfiles,
        selectedBillingProfiles,
      })
    }
  }, [id, data, isAccountMetaSuccess])

  useEffect(() => {
    getAccountMeta()
  }, [])

  return (
    <Box height="100%" overflowY="hidden" minWidth="750px">
      <CustomerSideNav />
      <CustomerLayout open={showCustomerSideNav}>
        <FormProvider {...accoutGroupDetailsForm}>
          <AccountGroupDetailsHeader />
          <AccountGroupList />
        </FormProvider>
      </CustomerLayout>
    </Box>
  )
}

export default AccountGroupDetails
