import React, { useState, useEffect } from 'react'
import { Box, Button, Card, Divider, Typography, useTheme } from '@mui/material'
import { common } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { get } from 'utils/lodash'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import { getFirstAccountDetails } from 'data/select-account/selectAccount'
import { selectHomePage } from 'slices/home/homeSlice'
import api from 'api'
import AccountSelectionDropdown from './AccountSelectionDropdown'

const SelectAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [selectedBusinessId, setSelectedBusinessId] = useState('')
  const existingBusinessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const allBusinesses = useSelector(state => get(state, 'LoginPersist.allBusinesses', []), shallowEqual)
  const homePage = useSelector(selectHomePage, shallowEqual)

  const handleChange = event => setSelectedBusinessId(event.target.value)

  const handleClick = () => {
    const currentBusiness = allBusinesses.find(business => business.businessId === selectedBusinessId)
    dispatch(
      setSelectedLoginDetails({
        show: false,
        businessName: get(currentBusiness, 'businessName'),
        businessId: get(currentBusiness, 'businessId'),
      })
    )

    dispatch(api.util.resetApiState())
    navigate(homePage)
  }

  useEffect(() => {
    if (existingBusinessId) {
      // Select newly registered account
      setSelectedBusinessId(existingBusinessId)
    } else {
      // Select first account
      const { businessId } = getFirstAccountDetails({ allBusinesses })
      setSelectedBusinessId(businessId)
    }
  }, [])

  return (
    <Box bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
      <Card sx={{ mx: 'auto', p: 4, width: '375px', boxShadow: `0px 1px 8px 0px ${theme.palette.divider}` }}>
        <Typography variant="h4" fontWeight={600}>
          Select a company to sign in
        </Typography>
        <Box mt={3}>
          <AccountSelectionDropdown value={selectedBusinessId} onChange={handleChange} />
        </Box>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            mt: 3,
            width: '100%',
            '&:hover': {
              color: common.white,
            },
          }}
        >
          Continue
        </Button>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Typography textAlign="center" color="textSecondary" variant="h6" fontWeight={500}>
          Powered by Hauler Hero ©
        </Typography>
      </Card>
    </Box>
  )
}

export default SelectAccount
