import { all } from 'redux-saga/effects'

import Login from './login'
import GlobalSearch from './globalsearch'
import Customers from './customers'
import Pricing from './pricing'
import Settings from './settings'
import Map from './map'
import FollowUp from './followUp'
import Calls from './calls'
import WorkOrders from './workOrders'
import ConfiguredServices from './configuredServices'
import RouteManager from './routeManager'
import Billing from './billing'
import Download from './download'
import Report from './report'

export default function* rootSaga() {
  yield all([
    Login(),
    GlobalSearch(),
    Customers(),
    Pricing(),
    Settings(),
    Map(),
    FollowUp(),
    Calls(),
    WorkOrders(),
    ConfiguredServices(),
    RouteManager(),
    Billing(),
    Download(),
    Report(),
  ])
}
