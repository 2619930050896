import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LogRocket from 'logrocket'

import { Helmet } from 'react-helmet-async'
import { useLocation, useHistory } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import { get } from 'utils/lodash'
import { useAuthValue } from 'auth/AuthContext'
import { getUnixTimestamp } from 'utils/date'
import { handleError } from 'utils/error'
import { getPageTitle } from 'utils/seo'
import { initializeBeamer } from 'utils/beamer'
import { getUserInfo } from 'middleware/actions/login'
import { TOAST_DISMISS_TIMEOUT } from 'settings/constants/toast'
import { useLazyGetUserByIdQuery } from 'api/settings/getUserById'
import { useLazyGetBusinessInfoQuery } from 'api/settings/getBusinessInfo'
import { BEAMER_SELECTOR_ID } from 'components/header/settings'
import { initializeIntercom, updateIntercom } from 'utils/intercom'

import Constants from 'Constants'
import Header from 'components/header'
import routes, { isPublicURL } from 'router/routes'

import 'react-toastify/dist/ReactToastify.css'
import setupLogRocketReact from 'logrocket-react'
import Loader from './loader'
import PreLoadImages from './PreLoadImages'
import GlobalDialogs from './GlobalDialogs'

const { BEAMER_APP_ID, LOGROCKET_ID, DEV_MODE } = Constants

const MainContainer = ({ children }) => {
  const ldClient = useLDClient()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [isValidUser, setIsValidUser] = useState(false)
  const { currentUser, isInitialized } = useAuthValue() || {}
  const showAppBar = useSelector(state => get(state, 'Appbar.showAppBar'), shallowEqual)
  const isLoading = useSelector(state => get(state, 'ResponseReducer.isLoading', false), shallowEqual)
  const userInfo = useSelector(state => get(state, 'AuthReducer.userInfo', null), shallowEqual)

  const { pathname } = location
  const isPublicPath = isPublicURL(pathname)

  const [getUserDetailsById] = useLazyGetUserByIdQuery()
  const [getBusinessInfo] = useLazyGetBusinessInfoQuery()

  const initiateIntercomBeamerAndLogRocket = async () => {
    const userId = get(userInfo, 'userId')
    const userDetails = await getUserDetailsById({ id: userId }).unwrap().catch(handleError)
    const businessInfo = await getBusinessInfo().unwrap().catch(handleError)

    const firstName = get(userInfo, 'firstName', '')
    const lastName = get(userInfo, 'lastName', '')
    const name = `${firstName} ${lastName}`.trim()
    const email = get(userDetails, 'user.email')
    const phone = get(userDetails, 'user.phoneNumber', null)
    const createdAt = getUnixTimestamp(get(userDetails, 'user.createdAt'))
    const company = {
      company_id: get(userInfo, 'businessInfo.businessId'),
      name: get(businessInfo, 'businessTitle'),
      website: get(businessInfo, 'website'),
      customer_portal_website: `${get(userInfo, 'businessInfo.businessName')}.haulerhero.com`,
    }

    const intercomParams = {
      user_id: userId,
      name,
      email,
      phone,
      created_at: createdAt,
      company,
    }
    updateIntercom(intercomParams)
    initializeBeamer(BEAMER_APP_ID, {
      selector: `#${BEAMER_SELECTOR_ID}`,
      user_id: userId,
      user_firstname: firstName,
      user_lastname: lastName,
      user_email: email,
    })
    if (LOGROCKET_ID && !DEV_MODE) {
      LogRocket.init(LOGROCKET_ID)
      setupLogRocketReact(LogRocket)
      LogRocket.identify(userId, {
        name,
        email,
        phone,
        company,
      })
    }
  }

  useEffect(() => {
    if (!currentUser || isPublicPath) {
      setIsValidUser(false)
      return
    }

    dispatch(
      getUserInfo(response => {
        setIsValidUser(true)
        if (!response) {
          history.push(routes.app.error)
        }
      })
    )
  }, [currentUser, isPublicPath])

  useEffect(() => {
    if (!isValidUser) {
      return
    }

    // Individual targeting using username, we can also use the withLDConsumer
    ldClient?.identify({ key: get(userInfo, 'username') })

    initiateIntercomBeamerAndLogRocket()
  }, [isValidUser])

  useEffect(() => {
    initializeIntercom()
  }, [])

  if (!isInitialized) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle(pathname)}</title>
      </Helmet>

      {isLoading && <Loader />}

      {(!currentUser || isPublicPath) && children}

      {currentUser && isValidUser && !isPublicPath && (
        <>
          {showAppBar && <Header />}
          <div className="main">{children}</div>
        </>
      )}

      <ToastContainer
        position="top-center"
        autoClose={TOAST_DISMISS_TIMEOUT}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        transition={Zoom}
      />
      <PreLoadImages />
      <GlobalDialogs />
    </>
  )
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContainer
