import React, { useEffect, useState, Children } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Typography, Box, Tab, Tabs, Pagination, Grid, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { CUSTOMER_DETAILS_DEFAULT_PAGINATION } from 'settings/constants/pagination'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import { useLazyGetEmailEventsQuery } from 'api/accounts/getEmailEvents'
import {
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
} from 'api/cacheTagTypes'
import { getInvoicesByStatus, getInvoicesFromEmailEvents } from 'data/invoice/invoiceSelectors'
import { useLazyGetARCustomerSummaryQuery } from 'api/billing/getARCustomerSummary'
import { PAYMENT_METHODS } from 'settings/constants/payment'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

import T from 'T'
import api from 'api'
import HHTablePagination from 'components/common/HHTablePagination'
import InvoiceActionMenu from 'components/billing/common/InvoiceActionMenu'
import InvoiceDrawer from 'components/billing/invoices/InvoiceDrawer'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AgingBucket from 'components/common/aging-bucket/AgingBucket'

import noop from 'lodash/noop'
import InteractiveAgingBucket from 'components/common/aging-bucket/InteractiveAgingBucket/InteractiveAgingBucket'
import AgingBucketDialog from 'components/common/aging-bucket/InteractiveAgingBucket/AgingBucketDialog'
import { Accordion, AccordionSummary, AccordionDetails } from './StyledAccordian'
import InvoiceAccordion from '../content/invoices/InvoiceAccordion'
import InvoiceEdit from '../../billing/invoices/invoice-edit'
import { CommonDrawer } from '../../common'
import CreateInvoice from '../content/CreateInvoice'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS
const { ACCOUNT_CREDIT } = PAYMENT_METHODS
const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CUSTOMER_DETAILS_DEFAULT_PAGINATION
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const Invoices = ({
  accountId,
  customerDetails = null,
  initialTab = PARTIAL,
  showCreateInvoiceButton = false,
  showARData = false,
  isViewOnly = false,
}) => {
  const dispatch = useDispatch()
  const [getARCustomerSummary, { data: summaryData }] = useLazyGetARCustomerSummaryQuery()
  const {
    currentCents = 0,
    dueOneThirtyCents = 0,
    dueThirtyOneSixtyCents = 0,
    dueSixtyOneNinetyCents = 0,
    dueOverNinetyCents = 0,
  } = summaryData || {}
  const customerCreditBalanceCents = get(customerDetails, 'customerCreditBalanceCents', 0)
  const outstandingAmount = currentCents + dueOneThirtyCents + dueThirtyOneSixtyCents + dueSixtyOneNinetyCents + dueOverNinetyCents

  const [isOpenEditInvoiceWOListing, setIsOpenEditInvoiceWOListing] = useState()
  const [isOpenCreateInvoice, setIsOpenCreateInvoice] = useState(false)
  const [getAccountInvoices, { data: invoiceList, isFetching: isLoading }] = useLazyGetAccountInvoicesQuery()
  const [getEmailEvents, { data: emailEventList }] = useLazyGetEmailEventsQuery()
  const [isInvoicesExpanded, setIsInvoicesExpanded] = useState(true)
  const [selectedTab, setSelectedTab] = useState(initialTab)
  const [pagination, setPagination] = useState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpenInvoicePDF, setIsOpenInvoicePDF] = useState(false)
  const { page, rowsPerPage } = pagination
  const shimmersArray = new Array(1).fill(0)

  const invalidateAccountTransaction = () => {
    setTimeout(() => {
      dispatch(
        api.util.invalidateTags([
          CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
          CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
          CACHE_TAG_ACCOUNT_INVOICE_LIST,
          CACHE_TAG_INVOICE_LIST,
          CACHE_TAG_WORK_ORDER_LIST,
          CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
        ])
      )
    }, 4000)
  }

  const handleInvoicesAccChange = () => setIsInvoicesExpanded(!isInvoicesExpanded)

  const handlePageChange = (newPage = INITIAL_PAGE) => setPagination(p => ({ ...p, page: newPage }))

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    handlePageChange()
  }

  const handleOpenActionMenu = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseActionMenu = () => {
    setAnchorEl(null)
  }

  const handleInvoicePDF = invoice => {
    setSelectedInvoice(invoice)
    setIsOpenInvoicePDF(true)
  }

  const handleEditInvoice = () => {
    setIsOpenInvoicePDF(false)
    setIsOpenEditInvoiceWOListing(!isOpenEditInvoiceWOListing)
  }

  const handleCloseInvoiceDrawer = () => {
    setIsOpenInvoicePDF(false)
  }

  const invoicesByStatus = getInvoicesByStatus({ invoices: get(invoiceList, 'invoices', []) })
  const invoicesFromEmailEvents = getInvoicesFromEmailEvents({ emailEvents: get(emailEventList, 'emailEvents', []) })

  const currentTabInvoices = get(invoicesByStatus, selectedTab, [])
  const currentInvoices = isLoading ? shimmersArray : currentTabInvoices.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  useEffect(() => {
    if (accountId) {
      getAccountInvoices({ accountId }).unwrap().catch(handleError)
      getEmailEvents({ accountId }).unwrap().catch(handleError)
      if (showARData) {
        getARCustomerSummary({ accountId })
      }
    }
  }, [accountId])

  useEffect(() => {
    if (currentTabInvoices?.length && !currentInvoices?.length) {
      handlePageChange()
    }
  }, [currentInvoices])

  return (
    <>
      <Accordion expanded={isInvoicesExpanded}>
        <AccordionSummary
          withNewColors
          sx={{
            '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
              mb: 0,
            },
          }}
          disablePointer
          expandIcon={undefined}
          iconGap={false}
        >
          <Grid container alignItems="center">
            <Grid item display="flex">
              {isInvoicesExpanded ? (
                <ArrowDropDown cursor="pointer" onClick={handleInvoicesAccChange} color="disabled" />
              ) : (
                <ArrowRight cursor="pointer" onClick={handleInvoicesAccChange} color="disabled" />
              )}
            </Grid>

            <Grid item xs>
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="h6"
                fontWeight={500}
                margin="auto 20px auto 8px"
                onClick={handleInvoicesAccChange}
              >
                {T.INVOICES}
              </Typography>
            </Grid>
            {showCreateInvoiceButton && (
              <Grid item xs display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" onClick={() => setIsOpenCreateInvoice(true)}>
                  {T.CREATE_INVOICE}
                </Button>
              </Grid>
            )}
            {showARData && (
              <Box my={2} display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={1}>
                <InteractiveAgingBucket accountId={accountId} type={CURRENT} label="Current" value={currentCents} />
                <InteractiveAgingBucket accountId={accountId} type={DUE_ONE_THIRTY} label="1-30 days" value={dueOneThirtyCents} />
                <InteractiveAgingBucket
                  accountId={accountId}
                  type={DUE_THIRTY_ONE_SIXTY}
                  label="31-60 days"
                  value={dueThirtyOneSixtyCents}
                />
                <InteractiveAgingBucket
                  accountId={accountId}
                  type={DUE_SIXTY_ONE_NINETY}
                  label="61-90 days"
                  value={dueSixtyOneNinetyCents}
                />
                <InteractiveAgingBucket accountId={accountId} type={DUE_OVER_NINETY} label="90+ days" value={dueOverNinetyCents} />
                <AgingBucket type={ACCOUNT_CREDIT} label="Account credit" value={customerCreditBalanceCents} />
                <AgingBucket label="Outstanding" value={outstandingAmount} />
                <AgingBucketDialog />
              </Box>
            )}

            <Grid item xs={12}>
              <Tabs value={selectedTab} onChange={handleTabChange} sx={{ button: { textTransform: 'capitalize' } }}>
                <Tab value={PARTIAL} label={`${T.DRAFT}s`} />
                <Tab value={UNPAID} label={T.UNPAID} />
                <Tab value={PAID} label={T.PAID} />
              </Tabs>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Box width="100%">
            <Box borderTop="1px solid" borderBottom="1px solid" borderColor="border.light">
              {Children.toArray(
                currentInvoices.map(invoice => (
                  <InvoiceAccordion
                    isViewOnly={isViewOnly}
                    isLoading={isLoading}
                    selectedTab={selectedTab}
                    billingProfileDetails={get(customerDetails, 'accountBilling.billingProfile', {})}
                    invoice={invoice}
                    emailEvents={invoicesFromEmailEvents.filter(event => event?.invoiceNumber === invoice?.invoiceNumber)}
                    onClick={() => handleInvoicePDF(invoice)}
                    onActionClick={event => {
                      handleOpenActionMenu(event)
                      setSelectedInvoice(invoice)
                    }}
                  />
                ))
              )}
            </Box>

            {currentTabInvoices.length > 0 && (
              <Box backgroundColor="background.header" px={3} display="flex" alignItems="center" justifyContent="space-between">
                <HHTablePagination
                  page={page}
                  component="div"
                  count={currentTabInvoices.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(event, newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onRowsPerPageChange={event => setPagination({ page: INITIAL_PAGE, rowsPerPage: event.target.value })}
                  ActionsComponent={params => <Box display="none" {...params} />}
                />
                <Pagination
                  color="primary"
                  count={Math.ceil(currentTabInvoices.length / rowsPerPage)}
                  page={page + 1}
                  onChange={(event, newPage) => handlePageChange(newPage - 1)}
                />
              </Box>
            )}

            {!isLoading && currentTabInvoices.length === 0 && <HHSectionPlaceholder title={T.NO_INVOICES} />}
          </Box>
        </AccordionDetails>
      </Accordion>

      <InvoiceActionMenu
        anchorEl={anchorEl}
        selectedInvoice={{
          ...selectedInvoice,
          amountCents: selectedInvoice?.totalCents,
        }}
        onClose={handleCloseActionMenu}
      />
      {isOpenCreateInvoice && (
        <CreateInvoice
          isOpen={isOpenCreateInvoice}
          accountId={accountId}
          billingProfileDetails={get(customerDetails, 'accountBilling.billingProfile', {})}
          callback={invalidateAccountTransaction}
          onClose={() => setIsOpenCreateInvoice(false)}
        />
      )}
      <InvoiceDrawer
        open={isOpenInvoicePDF}
        accountName={customerDetails?.accountName}
        onClose={handleCloseInvoiceDrawer}
        invoiceNumber={selectedInvoice?.invoiceNumber}
        invoiceId={selectedInvoice?.id}
        invoiceStatus={selectedInvoice?.status}
        onEditInvoice={handleEditInvoice}
      />
      <CommonDrawer isOpen={isOpenEditInvoiceWOListing} className="common-drawer-container invoice-work-order" onChange={handleEditInvoice}>
        <InvoiceEdit
          isOpen={isOpenEditInvoiceWOListing}
          invoiceNumber={selectedInvoice?.invoiceNumber}
          accountId={accountId}
          accountName={customerDetails?.accountName}
          invoiceDate={selectedInvoice?.invoiceDate}
          invoiceId={selectedInvoice?.id}
          unPostedAmountCents={selectedInvoice?.totalCents}
          invoiceStatus={selectedInvoice?.status}
          onInvoiceSideView={noop}
          onClose={handleEditInvoice}
          onRefreshTable={invalidateAccountTransaction}
        />
      </CommonDrawer>
    </>
  )
}

Invoices.propTypes = {
  accountId: PropTypes.string.isRequired,
  customerDetails: PropTypes.object,
  initialTab: PropTypes.string,
  showCreateInvoiceButton: PropTypes.bool,
  showARData: PropTypes.bool,
  isViewOnly: PropTypes.bool,
}

export default Invoices
