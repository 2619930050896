import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { AccordionDetails, Box, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import T from '../../../T'
import TabPanel from '../../common/TabPanel'
import PaymentActivity from '../../customer-details/content/common/PaymentActivity'
import HHSectionPlaceholder from '../../common/HHSectionPlaceholder'
import EmailActivity from '../../customer-details/content/common/EmailActivity'
import { INVOICE_ACTIVITY_TABS_ID } from '../../customer-details/content/invoices/settings'
import { PAYMENT_METHODS, PAYMENT_STATUS } from '../../../settings/constants/payment'
import ActivityHeader from '../../customer-details/content/common/ActivityHeader'

const { PAYMENT_TAB_ID, EMAIL_TAB_ID } = INVOICE_ACTIVITY_TABS_ID
const { ACCOUNT_CREDIT } = PAYMENT_METHODS
const { APPROVED } = PAYMENT_STATUS
const InvoiceActivityFeed = ({
  payments = [],
  accountCredits = [],
  emailEvents = [],
  onSelectedTabChange,
  selectedTab,
  Component = AccordionDetails,
}) => {
  const allPayments = [
    ...payments,
    ...accountCredits.map(accountCredit => ({
      debitCredit: accountCredit?.debitCredit,
      isAccountCredit: true,
      paymentMethod: ACCOUNT_CREDIT,
      transactionAmountCents: accountCredit?.amountCents,
      paymentAppliedAmountCents: accountCredit?.amountCents,
      transactionPostedDate: accountCredit?.createdAt,
      transactionStatus: APPROVED,
    })),
  ]

  return (
    <Component
      sx={{
        px: 2,
        pt: 1,
        pb: 0,
        backgroundColor: grey[50],
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'border.light',
      }}
    >
      <Box width="100%" pl={4.5}>
        <Typography variant="h6" fontWeight={500} my={1.5}>
          {T.ACTIVITY}
        </Typography>

        <Tabs value={selectedTab} onChange={onSelectedTabChange} sx={{ mb: 2, button: { textTransform: 'capitalize' } }}>
          <Tab value={PAYMENT_TAB_ID} label={T.PAYMENT} />
          <Tab value={EMAIL_TAB_ID} label={T.EMAIL} />
        </Tabs>

        <TabPanel value={selectedTab} index={PAYMENT_TAB_ID}>
          {Array.isArray(allPayments) && allPayments.length > 0 ? (
            <>
              <ActivityHeader statusLabel="Payment status" totalLabel="Payment total" />
              <Divider sx={{ width: '100%' }} />
              <Grid container>
                {Children.toArray(
                  allPayments.map((payment, index) => (
                    <>
                      <PaymentActivity payment={payment} showAppliedColumn align="inherit" marginY={2} />
                      {allPayments.length - 1 !== index && <Divider sx={{ width: '100%' }} />}
                    </>
                  ))
                )}
              </Grid>
            </>
          ) : (
            <Box width="100%">
              <HHSectionPlaceholder title={T.NO_PAYMENT_ACTIVITY_FOUND} />
            </Box>
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={EMAIL_TAB_ID}>
          <Grid container rowGap={2} mb={2}>
            {Array.isArray(emailEvents) && emailEvents.length > 0 ? (
              Children.toArray(
                emailEvents.map((email, index) => (
                  <>
                    <EmailActivity email={email} />
                    {emailEvents.length - 1 !== index && <Divider sx={{ width: '100%' }} />}
                  </>
                ))
              )
            ) : (
              <Box width="100%">
                <HHSectionPlaceholder title={T.NO_EMAIL_ACTIVITY_FOUND} />
              </Box>
            )}
          </Grid>
        </TabPanel>
      </Box>
    </Component>
  )
}

InvoiceActivityFeed.propTypes = {
  payments: PropTypes.array,
  accountCredits: PropTypes.array,
  emailEvents: PropTypes.array,
  Component: PropTypes.element,
  selectedTab: PropTypes.string.isRequired,
  onSelectedTabChange: PropTypes.func.isRequired,
}

export default InvoiceActivityFeed
