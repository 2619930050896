import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  addSelectedMoveFromRows,
  addSelectedMoveToRows,
  selectBulkMoveFetchId,
  selectBulkMoveFitBounds,
  selectBulkMoveFromRouteSequenceLine,
  selectBulkMoveFromRouteStopMarkers,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveMapLayer,
  selectBulkMoveRouteId,
  selectBulkMoveRoutes,
  selectBulkMoveRoutesById,
  selectBulkMoveToRouteSequenceLine,
  selectBulkMoveToRouteStopMarkers,
  selectBulkMoveToSerializedDate,
  selectIsOpenBulkMoveBetweenDaysStopsDialog,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import ToggleRouteLayers from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/ToggleRouteLayers'
import { MapContext } from 'providers/MapProvider'
import CommonSelectStopsToMoveBetweenDaysMap from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/CommonSelectStopsToMoveBetweenDaysMap'

const SelectStopsToMoveMap = () => {
  const fetchId = useSelector(selectBulkMoveFetchId)
  const fitBounds = useSelector(selectBulkMoveFitBounds)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const map = useContext(MapContext)
  const mapLayer = useSelector(selectBulkMoveMapLayer)
  const moveFromRouteSequenceLine = useSelector(selectBulkMoveFromRouteSequenceLine)
  const moveFromRouteStopMarkers = useSelector(selectBulkMoveFromRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkMoveToRouteSequenceLine)
  const moveToRouteStopMarkers = useSelector(selectBulkMoveToRouteStopMarkers)
  const open = useSelector(selectIsOpenBulkMoveBetweenDaysStopsDialog)
  const routeId = useSelector(selectBulkMoveRouteId)
  const routes = useSelector(selectBulkMoveRoutes)
  const routesById = useSelector(selectBulkMoveRoutesById)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)

  return (
    <CommonSelectStopsToMoveBetweenDaysMap
      ToggleRouteLayers={<ToggleRouteLayers map={map} />}
      addSelectedMoveFromRows={addSelectedMoveFromRows}
      addSelectedMoveToRows={addSelectedMoveToRows}
      fetchId={fetchId}
      fitBounds={fitBounds}
      fromSerializedDate={fromSerializedDate}
      mapLayer={mapLayer}
      moveFromRouteSequenceLine={moveFromRouteSequenceLine}
      moveFromRouteStopMarkers={moveFromRouteStopMarkers}
      moveToRouteSequenceLine={moveToRouteSequenceLine}
      moveToRouteStopMarkers={moveToRouteStopMarkers}
      open={open}
      routeId={routeId}
      routes={routes}
      routesById={routesById}
      toSerializedDate={toSerializedDate}
    />
  )
}

SelectStopsToMoveMap.propTypes = {}

export default SelectStopsToMoveMap
