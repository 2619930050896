import React from 'react'
import { ListItem, ListItemText, useTheme } from '@mui/material'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import { Controller } from 'react-hook-form'

const DisposalMaterialPicker = ({ control, name, onChange = noop, readOnly = false, materials = [], menuSx, rules = {}, ...rest }) => {
  const theme = useTheme()
  return (
    <Controller
      name={name}
      control={control}
      required
      rules={rules}
      render={({ field }) => {
        const { ref, onChange: onChangeHookForm, onBlur: onBlurHookForm, ..._rest } = field
        const handleChange = e => {
          onChangeHookForm(e)
          onChange(e.target.value)
        }
        const renderValue = val => {
          if (!val || !val?.length) {
            return 'Select a route'
          }
          if (Array.isArray(val)) {
            return val.join(', ')
          }
          return val
        }
        return (
          <HHTextField
            {...field}
            sx={{
              '& .MuiOutlinedInput-root .MuiListItemAvatar-root': {
                minWidth: '30px',
                position: 'relative',
                left: '-8px',
              },
            }}
            onBlur={onBlurHookForm}
            readOnly={readOnly}
            onChange={handleChange}
            fullWidth
            select
            deprecatedLabel={false}
            inputRef={ref}
            variant="outlined"
            renderValue={renderValue}
            label="Material"
            placeholder="Select a route"
            SelectProps={{
              MenuProps: {
                sx: { zIndex: theme.zIndex.tooltip + 10, ...menuSx },
                PaperProps: {
                  sx: { maxHeight: 'calc(100vh - 80px)' },
                },
                alignment: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                },
              },
            }}
            {..._rest}
            {...rest}
          >
            {materials.map(material => {
              const id = get(material, 'id')
              const materialType = get(material, 'material.materialType')
              const tippingFee = get(material, 'tippingFee')
              const measureUnit = get(material, 'measureUnit')
              return (
                <SelectItem disableGutters value={id}>
                  <ListItem disabbleGutters disablePadding>
                    <ListItemText primary={materialType} secondary={`Disposal fee: $${tippingFee}/${measureUnit}`} />
                  </ListItem>
                </SelectItem>
              )
            })}
          </HHTextField>
        )
      }}
    />
  )
}

DisposalMaterialPicker.propTypes = {
  materials: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  menuSx: PropTypes.object,
  rules: PropTypes.object,
}

export default DisposalMaterialPicker
