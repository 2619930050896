import inRange from 'lodash/inRange'

export const REGEX = {
  IS_EMAIL: /\S+@\S+\.\S{2,}/,
  IS_VALID_MOBILE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  // Infinite
  IS_NUMBER_ONLY: /^[0-9\b]+$/,

  // only 10 digits
  IS_TEN_DIGITS_ONLY: /^\d{0,10}?$/,
  IS_PRICE: /^\d{0,10}(\.\d{0,2})?$/,
  IS_PERCENTAGE: /^\d{0,10}(\.\d{0,5})?$/,

  // Space on staring is not allowed, In between allowed
  IS_ALPHABET_AND_SPACE: /^[a-zA-Z][a-zA-Z\s]*$/,
  IS_ALPHABET_NUMERIC_AND_SPACE: /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
  IS_VALID_LONGITUDE: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
  IS_VALID_LATITUDE: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
  IS_ALPHA_NUMERIC: /^[\w\-\s]*$/,
  IS_ZERO: /^0+$/,
  IS_CVV: /^[0-9]{3,4}$/,

  // valid uuid
  IS_UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,

  // hex
  IS_VALID_HEX: /^#(?:[0-9a-fA-F]{6})$/,
  IS_INVOICE_GROUPS_DETAILS_URL: /invoice_groups\/\w+/,
  IS_PAYMENT_GROUPS_DETAILS_URL: /payment_groups\/\w+/,
  IS_ACCOUNT_GROUPS_DETAILS_URL: /account_groups\/\w+/,
  IS_SUSPENSION_GROUPS_DETAILS_URL: /suspension_groups\/\w+/,
  IS_LATE_FEES_DETAILS_URL: /late_fees\/\w+/,
  IS_RENTAL_FEES_DETAILS_URL: /rental_fees\/\w+/,
  HAS_EXCEPTION_IMAGE_SIZE_SUFFIX: /_\d+x\d+$/,

  IS_URL: /\b((https?:\/\/|www\.|localhost)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?::\d+)?(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?)\b/g,
}

export const checkHasValue = (allFields, requiredFields) =>
  [allFields].every(item => requiredFields.every(key => item[key] && item[key].trim() !== ''))

export const isEmail = email => REGEX.IS_EMAIL.test(email)

export const isValidMobile = phone => REGEX.IS_VALID_MOBILE.test(phone)

export const isValidPrice = price => REGEX.IS_PRICE.test(price)

export const isValidPercentage = percentage => REGEX.IS_PERCENTAGE.test(percentage)

export const isTenDigitsOnly = number => REGEX.IS_TEN_DIGITS_ONLY.test(number)
export const isNumberOnly = number => REGEX.IS_NUMBER_ONLY.test(number)

export const isAlphabetAndSpaceOnly = name => REGEX.IS_ALPHABET_AND_SPACE.test(name)

export const isAlphNumericAndSpaceOnly = name => REGEX.IS_ALPHABET_NUMERIC_AND_SPACE.test(name)

export const isValidLongitude = coord => REGEX.IS_VALID_LONGITUDE.test(coord)

export const isValidLatitude = coord => REGEX.IS_VALID_LATITUDE.test(coord)

export const isValidLatitudeRange = num => (num ? inRange(num, -90, 90) : false)

export const isValidLongitudeRange = num => (num ? inRange(num, -180, 180) : false)

export const isAlphaNumeric = word => REGEX.IS_ALPHA_NUMERIC.test(word)

export const isZero = number => REGEX.IS_ZERO.test(number)

export const isCVV = number => REGEX.IS_CVV.test(number)

export const isValidUUID = number => REGEX.IS_UUID.test(number)
export const isValidHex = str => REGEX.IS_VALID_HEX.test(str)

export const truncateDecimals = (num, digits) => {
  const numStr = num.toString()
  const [integerPart, fractionalPart] = numStr.split('.')
  if (fractionalPart === undefined) return num
  const truncatedFractionalPart = fractionalPart.substring(0, digits)
  return parseFloat(`${integerPart}.${truncatedFractionalPart}`)
}

export const isInvoiceGroupDetailsUrl = url => REGEX.IS_INVOICE_GROUPS_DETAILS_URL.test(url)

export const isPaymentGroupDetailsUrl = url => REGEX.IS_PAYMENT_GROUPS_DETAILS_URL.test(url)

export const isAccountGroupDetailsUrl = url => REGEX.IS_ACCOUNT_GROUPS_DETAILS_URL.test(url)

export const isSuspensionGroupDetailsUrl = url => REGEX.IS_SUSPENSION_GROUPS_DETAILS_URL.test(url)

export const isLateFeeDetailsUrl = url => REGEX.IS_LATE_FEES_DETAILS_URL.test(url)

export const isRentalFeeDetailsUrl = url => REGEX.IS_RENTAL_FEES_DETAILS_URL.test(url)

export const hasExceptionImageSuffix = path => REGEX.HAS_EXCEPTION_IMAGE_SIZE_SUFFIX.test(path)

export const { IS_ALPHABET_AND_SPACE, IS_ALPHABET_NUMERIC_AND_SPACE } = REGEX
