import React, { useReducer, useEffect, useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { difference, cloneDeep, isEmpty, noop } from 'lodash'

import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Box, DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { calculateSum, formatCentsToDollars, formatDollarsToCents } from 'utils/price'
import { handleInvoiceSelectDeselect, removeInvoiceAmountFromPayload } from 'utils/payment'
import { useLazyGetIsSurchargeAppliedQuery, useLazyGetAllPaymentMethodsQuery } from 'api/payment-gateway/paymentGateway'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import {
  getPaymentMethodByStatus,
  getAllSortedUnpaidInvoices,
  getSinglePaymentPayload,
  addUpdateRequestedPayments,
  getEditPaymentModel,
} from 'utils/takePayments'
import { PAYMENT_METHODS } from 'settings/constants/payment'
import { usePostPaymentsMutation } from 'api/billing/postPayments'
import { useAddPaymentBatchesToGroupMutation } from 'api/groups/payment/addPaymentBatchesToGroup'
import { putIsLoading } from 'middleware/actions/response'
import { getCustomerDetails, putCustomerDetails } from 'middleware/actions/customers'
import {
  CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS,
  CACHE_TAG_BILLING_PAYMENT_LIST,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST,
} from 'api/cacheTagTypes'
import { setBatchConfirmationDialogState } from 'slices/groups/PaymentGroupsSlice'
import { getPGUrl } from 'data/payment-gateway/paymentGatewaySelectors'

import T from 'T'
import api from 'api'
import { isCustomerDetailsPage } from 'router/routes'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import Summary from 'components/billing/groups/payment/take-payments/Summary'
import DialogActions from 'components/billing/groups/payment/take-payments/DialogActions'
import { PAYMENT_MODEL, SEARCH_TYPES, PAYMENT_FORM_MODEL } from 'components/billing/groups/payment/take-payments/model'
import { PAYMENT_BATCH_CONFIRMATION_TIMEOUT } from 'components/billing/groups/payment/take-payments/settings'
import { getInvoices, preparePayload } from 'components/billing/groups/payment/take-payments/helper'
import EditBillingLocationModal from 'components/customer-details/content/billing-location-details/EditBillingLocationModal'
import { combineAddressInfo, isAddressObjectEmpty } from 'utils/helper'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import SearchLayout from './layout/SearchLayout'
import ListLayout from './layout/ListLayout'

const { ACCOUNT_CREDIT, CHECK } = PAYMENT_METHODS
const FIRST_STEP_INDEX = 0

const TakePaymentsDialog = ({
  isOpen = false,
  initialAccount = {},
  initialGroupId = '',
  existingAccounts = [],
  onClose,
  callback = noop,
}) => {
  const dispatch = useDispatch()
  const pgUrl = useSelector(getPGUrl, shallowEqual)
  const canTakePayment = useSelector(state => get(state, 'responseSlice.canTakePayment', true), shallowEqual)
  const [getIsSurchargeApplied, { data: hasSurcharge }] = useLazyGetIsSurchargeAppliedQuery()
  const [getAllPaymentMethods, { data: paymentMethodsData, isFetching: isLoadingGetAllPaymentMethods }] = useLazyGetAllPaymentMethodsQuery()
  const [getAccountDetails, { data: accountData, isFetching: isLoadingGetAccountDetails }] = useLazyGetAccountDetailsQuery()
  const [getAccountInvoices, { data: invoiceData, isFetching: isLoadingGetAccountInvoices }] = useLazyGetAccountInvoicesQuery()
  const [postPayments, { isLoading: isPostPaymentLoading }] = usePostPaymentsMutation()
  const [addPaymentBatchesToGroup] = useAddPaymentBatchesToGroupMutation()
  const isLoadingResponse = useSelector(s => get(s, 'ResponseReducer.isLoading', false))
  const [isOpenEditBillingLocationDialog, setIsOpenEditBillingLocationDialog] = useState(false)

  const hasExistingAccounts = existingAccounts.length > 0
  const hasInitialAccount = !isEmpty(initialAccount)
  const paymentForm = useForm({ defaultValues: { ...PAYMENT_FORM_MODEL } })
  const { formState, setValue, reset, watch, setFocus, clearErrors, handleSubmit } = paymentForm
  const watchPaymentMethod = watch('paymentMethod')
  const watchAmount = watch('amount')
  const watchIsNewCard = watch('isNewCard')
  const [isOpenConfirmCloseDialog, setIsOpenConfirmCloseDialog] = useState(false)
  const [activeStep, setActiveStep] = useState(FIRST_STEP_INDEX)
  const searchRef = useRef(null)
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    batchDate: new Date(),
    groupId: initialGroupId,
    editPaymentRefId: '',
    requestedPayments: [],
    ...PAYMENT_MODEL,
  })
  const { batchDate, groupId, editPaymentRefId, requestedPayments, searchType, selectedOption, invoicesToPay } = localState
  const isCalledFromCustomerDetailsPage = isCustomerDetailsPage()
  const accountId = selectedOption?.accountId || selectedOption?.id
  const isFirstStep = activeStep === FIRST_STEP_INDEX
  const isCustomerSearch = searchType === SEARCH_TYPES.CUSTOMER
  const isInvoiceSearch = searchType === SEARCH_TYPES.INVOICE
  const { isCreditCard, isCreditCardOrACH, isAccountCredit } = getPaymentMethodByStatus(watchPaymentMethod)
  const apiRef = useGridApiRef()

  const savedPaymentMethods = get(paymentMethodsData, 'paymentMethods', [])
  const invoiceList = getInvoices(searchType, selectedOption, invoiceData)
  const sortedUnpaidInvoices = getAllSortedUnpaidInvoices(invoiceList)
  const selectionModel = invoicesToPay.map(({ invoiceNumber }) => invoiceNumber)

  const initialCreditBalanceCents = selectedOption ? get(accountData, 'customerCreditBalanceCents', 0) : 0
  const updatedCreditBalanceCents =
    initialCreditBalanceCents +
    formatDollarsToCents(watchAmount) -
    calculateSum(invoicesToPay.map(invoice => invoice.usedPartialAmount || invoice.invoiceAmount))
  const isLoading = isLoadingGetAccountDetails || isLoadingGetAccountInvoices || isLoadingGetAllPaymentMethods || !canTakePayment
  const isPostLoading = isLoading || isLoadingResponse || isPostPaymentLoading

  const handleClose = (showConfirmDialog = true) => {
    if (requestedPayments.length !== 0 && showConfirmDialog) {
      setIsOpenConfirmCloseDialog(true)
    } else {
      onClose()
    }
  }

  const handleChange = (key, value) => setLocalState({ [key]: value })

  const handleInvoiceSelection = (allInvoices, creditBalanceCents, addedAmount, checkConditions) =>
    handleInvoiceSelectDeselect(allInvoices, creditBalanceCents, formatDollarsToCents(addedAmount), checkConditions)

  const handleResetPaymentTypeFields = () => {
    setValue('paymentDate', new Date())
    setValue('checkNum', '')
    setValue('amount', 0)
    setValue('selectedPaymentTokenDetails', {})
    setValue('isNewCard', false)
    setValue('cardHolderName', '')
    setValue('cvv', '')
    setValue('isSaveNewMethod', false)
    setValue('cardNickName', '')
    setValue('autoPayTime', null)
    setValue('note', '')
    clearErrors()
  }

  const fetchAllPaymentMethods = id => {
    getAllPaymentMethods({ pgUrl, accountId: id }).unwrap().catch(handleError)
  }

  const prefillInvoices = useCallback(
    (response, invoices) => {
      const unpaidInvoices = getAllSortedUnpaidInvoices(invoices)
      const customerCreditBalanceCents = get(response, 'customerCreditBalanceCents', 0)
      const invoiceNumber = get(initialAccount, 'invoiceNumber')
      if (hasInitialAccount && invoiceNumber) {
        const index = unpaidInvoices.findIndex(invoice => invoice.invoiceNumber === invoiceNumber)
        const selectedInvoice = unpaidInvoices[index]
        const { invoiceAmount } = selectedInvoice
        const neededAmountCents = Math.max(0, invoiceAmount - customerCreditBalanceCents)
        apiRef?.current?.scrollToIndexes({ rowIndex: index })
        const { selectedInvoices } = handleInvoiceSelection(
          [{ ...selectedInvoice }],
          customerCreditBalanceCents,
          formatCentsToDollars(neededAmountCents),
          false
        )
        setLocalState({ invoicesToPay: selectedInvoices })
        setValue('paymentMethod', neededAmountCents > 0 ? CHECK : ACCOUNT_CREDIT)
        setValue('amount', formatCentsToDollars(neededAmountCents))
        if (neededAmountCents > 0) {
          setTimeout(() => setFocus('checkNum'), 100)
        }
        return
      }

      if (customerCreditBalanceCents > 0 && unpaidInvoices.length > 0) {
        const { selectedInvoices } = handleInvoiceSelection(unpaidInvoices, customerCreditBalanceCents, 0, false)
        setLocalState({ invoicesToPay: selectedInvoices })
        setValue('paymentMethod', ACCOUNT_CREDIT)
        setValue('amount', 0)
      } else {
        setValue('paymentMethod', CHECK)
        setTimeout(() => setFocus('checkNum'), 100)
      }
    },
    [hasInitialAccount, initialAccount, apiRef, sortedUnpaidInvoices]
  )

  const handleSearchChange = async selection => {
    setLocalState({ selectedOption: selection, invoicesToPay: [] })
    setValue('paymentMethod', '')
    handleResetPaymentTypeFields()
    const newAccountId = selection?.accountId || selection?.id
    if (!newAccountId) {
      return
    }
    let invoices = []
    if (isCustomerSearch && get(selection, 'unpaidInvoiceCount', 0) > 0) {
      const result = await getAccountInvoices({ accountId: newAccountId }).unwrap().catch(handleError)
      invoices = get(result, 'invoices', [])
    } else if (isInvoiceSearch) {
      invoices = [selection]
    }

    getAccountDetails({ accountId: newAccountId })
      .unwrap()
      .then(response => prefillInvoices(response, invoices))

    fetchAllPaymentMethods(newAccountId)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const { selectedInvoices } = handleInvoiceSelection(sortedUnpaidInvoices, initialCreditBalanceCents, watchAmount, false)
      setLocalState({ invoicesToPay: selectedInvoices })
      return
    }

    setLocalState({ invoicesToPay: [] })
  }

  const handleRowCheckboxClick = newSelectionModel => {
    if (isLoading) {
      return
    }

    if (newSelectionModel.length === 0) {
      setLocalState({ invoicesToPay: [] })
      return
    }

    const selectedIds = difference(newSelectionModel, selectionModel)
    const unselectedIds = difference(selectionModel, newSelectionModel)

    let newSelected = []
    let checkValidations = false

    if (selectedIds.length) {
      const selectedInvoice = sortedUnpaidInvoices.find(invoice => invoice.invoiceNumber === selectedIds[0])
      const { invoiceNumber, invoiceAmount } = selectedInvoice
      newSelected = [...invoicesToPay, { invoiceNumber, invoiceAmount }]
      checkValidations = invoiceAmount > 0
    } else {
      newSelected = invoicesToPay.filter(row => row.invoiceNumber !== unselectedIds[0])
    }

    const { isChanged, selectedInvoices } = handleInvoiceSelection(newSelected, initialCreditBalanceCents, watchAmount, checkValidations)
    setLocalState({ invoicesToPay: isChanged ? selectedInvoices : invoicesToPay })
  }

  const handleAmountChange = value => {
    const { selectedInvoices } = handleInvoiceSelection(sortedUnpaidInvoices, initialCreditBalanceCents, value, false)
    setLocalState({ invoicesToPay: selectedInvoices })
  }

  const handleClearClick = () => {
    handleResetPaymentTypeFields()
    handleAmountChange(0)
    if (hasInitialAccount) {
      setLocalState({ groupId: '' })
    }
  }

  const handleRefetch = () => {
    const invalidateTags = hasExistingAccounts
      ? [CACHE_TAG_ACCOUNT_INVOICE_LIST, CACHE_TAG_ACCOUNT_PAYMENT_LIST]
      : [
          { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
          { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
        ]

    dispatch(
      api.util.invalidateTags([
        CACHE_TAG_BILLING_PAYMENT_LIST,
        CACHE_TAG_INVOICE_LIST,
        CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
        ...invalidateTags,
        CACHE_TAG_ACCOUNT_LIST,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ])
    )

    if (isCalledFromCustomerDetailsPage) {
      dispatch(getCustomerDetails({ accountId }))

      if (isCreditCardOrACH && watchIsNewCard) {
        dispatch(api.util.invalidateTags([{ type: CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST, id: accountId }]))
      }
    }
  }

  const handleAddBatchToGroup = batchId => {
    addPaymentBatchesToGroup({ id: groupId, batchIds: [batchId], skipInvalidate: true })
      .unwrap()
      .catch(handleError)
  }

  const handlePostPayments = payments => {
    if (isPostLoading) return
    dispatch(putIsLoading(true))
    const payload = preparePayload(payments, hasInitialAccount, batchDate)

    postPayments(removeInvoiceAmountFromPayload(payload))
      .unwrap()
      .then(async result => {
        const batchId = result?.batchId
        const hasBatchIdAndGroupId = batchId && groupId
        if (hasBatchIdAndGroupId) {
          handleAddBatchToGroup(batchId)
        }

        toast.info(hasInitialAccount ? T.PAYMENT_PROCESSING : T.PAYMENT_BATCH_PROCESSING, {
          hideProgressBar: false,
          autoClose: hasInitialAccount ? 5000 : PAYMENT_BATCH_CONFIRMATION_TIMEOUT,
          onClose: () => {
            if (hasBatchIdAndGroupId) {
              dispatch(api.util.invalidateTags([{ type: CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS, id: groupId }]))
            }
            handleRefetch()
            callback()
          },
        })

        if (batchId && !hasInitialAccount) {
          // Only open for batch payments
          dispatch(setBatchConfirmationDialogState({ isOpen: true, batchId, groupId, batchDate }))
        }
        handleClose(false)
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleNewPaymentClick = () => {
    setLocalState({ ...cloneDeep(PAYMENT_MODEL), editPaymentRefId: '' })
    reset(cloneDeep(PAYMENT_FORM_MODEL))
    setActiveStep(FIRST_STEP_INDEX)
  }

  const handleEditClick = (refId, editAccountId) => {
    const existingPaymentDetails = requestedPayments.find(payment => payment.refId === refId)
    const { stateData, formData } = getEditPaymentModel(existingPaymentDetails)
    setLocalState({ editPaymentRefId: refId, ...stateData })
    reset(formData)
    if (stateData.searchType === SEARCH_TYPES.CUSTOMER) {
      getAccountInvoices({ accountId: editAccountId })
    }
    getAccountDetails({ accountId: editAccountId })
    fetchAllPaymentMethods(editAccountId)
    setActiveStep(FIRST_STEP_INDEX)
  }

  const handleRemoveClick = (refId, invoiceNumber, totalInvoices) => {
    if (totalInvoices <= 1) {
      // If we have just one invoice per customer directly remove complete record
      // Or no selected invoice
      setLocalState({ requestedPayments: requestedPayments.filter(payment => payment.refId !== refId) })
      return
    }

    setLocalState({
      requestedPayments: requestedPayments.map(payment => {
        if (payment.refId === refId) {
          // b/c of partial payment recalculate once we delete any row
          const { selectedInvoices } = handleInvoiceSelectDeselect(
            payment.invoicesToPay.filter(invoice => invoice.invoiceNumber !== invoiceNumber),
            payment.initialCreditBalanceCents,
            payment.amountCents
          )
          return { ...payment, invoicesToPay: selectedInvoices }
        }
        return { ...payment }
      }),
    })
  }

  const handleAccountRowSelectionModelChange = (selection, doReset = true) => {
    const newId = get(selection, '0')
    if (!newId || newId === accountId) {
      return
    }
    const paymentInfo = requestedPayments.find(payment => payment.accountId === newId)
    if (paymentInfo) {
      handleEditClick(paymentInfo.refId, newId)
      return
    }
    if (doReset) {
      handleNewPaymentClick()
    }

    const accountInfo = existingAccounts.find(account => account.accountId === newId)
    handleSearchChange({ id: newId, unpaidInvoiceCount: get(accountInfo, 'unpaidInvoiceCount', 0) })
  }

  const increaseStep = () => setActiveStep(prevActiveStep => prevActiveStep + 1)

  const handleNextBtnClick = formData => {
    if (isPostLoading) return
    if (isCreditCardOrACH && !formData?.selectedPaymentTokenDetails?.accountToken) {
      toast.error(`Please select or add a ${isCreditCard ? 'card' : 'ACH'}`)
      return
    }

    if (isAccountCredit && invoicesToPay.length === 0) {
      toast.error('Please select an invoice')
      return
    }

    const payload = getSinglePaymentPayload({
      ...localState,
      ...formData,
      accountId: get(accountData, 'id'),
      customerName: get(accountData, 'accountName'),
      accountNumber: get(accountData, 'accountNumber'),
      initialCreditBalanceCents,
    })

    if (hasInitialAccount) {
      // Single customer post call
      handlePostPayments([payload])
      return
    }

    setLocalState({
      ...cloneDeep(PAYMENT_MODEL),
      editPaymentRefId: '',
      requestedPayments: addUpdateRequestedPayments(editPaymentRefId, payload, requestedPayments),
    })
    reset(cloneDeep(PAYMENT_FORM_MODEL))

    if (editPaymentRefId) {
      increaseStep()
      return
    }

    searchRef?.current?.focus()

    if (!hasExistingAccounts) {
      return
    }

    const lastAccountId = payload.accountId
    const lastAccountIdIndex = existingAccounts.findIndex(account => account.accountId === lastAccountId)
    const nextAccountId = get(existingAccounts, `${lastAccountIdIndex + 1}.accountId`)

    if (nextAccountId) {
      handleAccountRowSelectionModelChange([nextAccountId], false)
      return
    }
    increaseStep()
  }

  const submitFromSummaryButton = formData => {
    if (isPostLoading) return
    if (isCreditCardOrACH && !formData?.selectedPaymentTokenDetails?.accountToken) {
      toast.error(`Please select or add a ${isCreditCard ? 'card' : 'ACH'}`)
      return
    }

    if (isAccountCredit && invoicesToPay.length === 0) {
      toast.error('Please select an invoice')
      return
    }

    const payload = getSinglePaymentPayload({
      ...localState,
      ...formData,
      accountId: get(accountData, 'id'),
      customerName: get(accountData, 'accountName'),
      accountNumber: get(accountData, 'accountNumber'),
      initialCreditBalanceCents,
    })

    setLocalState({
      ...cloneDeep(PAYMENT_MODEL),
      editPaymentRefId: '',
      requestedPayments: addUpdateRequestedPayments(editPaymentRefId, payload, requestedPayments),
    })
    reset(cloneDeep(PAYMENT_FORM_MODEL))

    if (editPaymentRefId) {
      increaseStep()
      return
    }

    searchRef?.current?.focus()

    if (!hasExistingAccounts) {
      increaseStep()
      return
    }
    increaseStep()
  }

  const handleSummaryBtnClick = () => {
    const { dirtyFields, touchedFields } = formState
    const { checkNum, ...restTouchedFields } = touchedFields

    if (
      accountId &&
      watchPaymentMethod &&
      (!isEmpty(dirtyFields) ||
        !isEmpty(restTouchedFields) ||
        requestedPayments.length === 0 ||
        (watchPaymentMethod === ACCOUNT_CREDIT && watchAmount !== 0))
    ) {
      const submit = handleSubmit(submitFromSummaryButton)
      submit()
    } else if (requestedPayments.length > 0) {
      increaseStep()
    }
  }

  const onCloseEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(false)
  }

  const onSaveEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(false)
    if (accountId) {
      getAccountDetails({ accountId })
        .unwrap()
        .then(response => {
          if (isCalledFromCustomerDetailsPage) {
            dispatch(putCustomerDetails(response))
          }
        })
    }
  }

  const onOpenEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(true)
  }

  const handleConfirmClose = () => {
    onClose()
    setIsOpenConfirmCloseDialog(false)
  }

  const handleCancelClose = () => {
    setIsOpenConfirmCloseDialog(false)
  }

  useEffect(() => {
    if (pgUrl) {
      getIsSurchargeApplied({ pgUrl })
    }
  }, [pgUrl])

  useEffect(() => {
    if (hasInitialAccount && canTakePayment) {
      handleSearchChange({ ...initialAccount, unpaidInvoiceCount: 1 })
    }
  }, [hasInitialAccount, canTakePayment])

  useEffect(() => {
    if (isOpen) {
      setLocalState({ groupId: initialGroupId })
    }
  }, [isOpen])

  const getBillingAddressStr = useCallback(() => {
    const billingAddress = get(accountData, 'billingAddress')
    const isAddressEmpty = isAddressObjectEmpty(billingAddress)
    if (isLoadingGetAccountDetails) {
      return '-'
    }
    if (accountId && !isAddressEmpty) {
      return combineAddressInfo(billingAddress)
    }
    if (accountId && isAddressEmpty) {
      return 'No address saved'
    }
    return '-'
  }, [accountData, accountId, isLoadingGetAccountDetails])

  const billingAddressStr = getBillingAddressStr()

  useEffect(() => {
    if (isOpen) {
      handleAccountRowSelectionModelChange([get(existingAccounts, '0.accountId')])
    }
  }, [isOpen])

  const Layout = useMemo(() => (hasExistingAccounts ? ListLayout : SearchLayout), [hasExistingAccounts])

  return (
    <HHBaseDialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={isFirstStep ? T.NEW_PAYMENT : T.SUMMARY} onClose={handleClose} />
      <DialogContent sx={{ px: 0 }}>
        <FormProvider {...paymentForm}>
          {isFirstStep && (
            <Layout
              isLoading={isLoading}
              accountId={accountId}
              accountData={accountData}
              savedPaymentMethods={savedPaymentMethods}
              existingAccounts={existingAccounts}
              isCustomerSearch={isCustomerSearch}
              sortedUnpaidInvoices={sortedUnpaidInvoices}
              hasInitialAccount={hasInitialAccount}
              searchRef={searchRef}
              localState={localState}
              apiRef={apiRef}
              initialCreditBalanceCents={initialCreditBalanceCents}
              updatedCreditBalanceCents={updatedCreditBalanceCents}
              selectionModel={selectionModel}
              watchAmount={watchAmount}
              hasSurcharge={hasSurcharge}
              billingAddressStr={billingAddressStr}
              onChange={handleChange}
              onSearchChange={handleSearchChange}
              onRowCheckboxClick={handleRowCheckboxClick}
              onSelectAllClick={handleSelectAllClick}
              onAmountChange={handleAmountChange}
              onSubmit={handleSubmit(handleNextBtnClick)}
              onClearClick={handleClearClick}
              onOpenEditBillingLocationDialog={onOpenEditBillingLocationDialog}
              onAccountRowSelectionModelChange={handleAccountRowSelectionModelChange}
            />
          )}
        </FormProvider>

        {!isFirstStep && (
          <Summary
            requestedPayments={requestedPayments}
            batchDate={batchDate}
            groupId={groupId}
            showAddToGroupField={!initialGroupId}
            onChange={handleChange}
            onEditClick={handleEditClick}
            onRemoveClick={handleRemoveClick}
          />
        )}

        <Box id="takePaymentGroup" className="takePaymentGroup" display="none" />
      </DialogContent>
      <DialogActions
        accountId={accountId}
        isPostLoading={isPostLoading}
        isFirstStep={isFirstStep}
        isSingleStep={hasInitialAccount}
        paymentMethod={watchPaymentMethod}
        requestedPayments={requestedPayments}
        onClose={handleClose}
        onNextBtnClick={handleSubmit(handleNextBtnClick)}
        onPostBtnClick={() => handlePostPayments(requestedPayments)}
        onSummaryBtnClick={handleSummaryBtnClick}
        onNewPaymentClick={handleNewPaymentClick}
        editPaymentRefId={editPaymentRefId}
      />
      <EditBillingLocationModal
        customerDetails={accountData}
        open={isOpenEditBillingLocationDialog}
        onClose={onCloseEditBillingLocationDialog}
        onSave={onSaveEditBillingLocationDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenConfirmCloseDialog}
        DialogProps={{ maxWidth: 'xs' }}
        ConfirmButtonProps={{ size: 'small' }}
        confirmButtonTitle="Confirm"
        confirmTitle="Unposted payments"
        confirmDescription={`You currently have ${requestedPayments.length} ${
          requestedPayments.length > 1 ? ' payments ' : 'payment'
        } added in this batch that has not been posted.  If you proceed, all work will be lost.  Do you want to continue?`}
        onConfirm={handleConfirmClose}
        onClose={handleCancelClose}
      />
    </HHBaseDialog>
  )
}

TakePaymentsDialog.propTypes = {
  isOpen: PropTypes.bool,
  initialGroupId: PropTypes.string,
  initialAccount: PropTypes.object,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func,
}

export default TakePaymentsDialog
