import set from 'lodash/set'

import { get } from 'utils/lodash'
import { formatCentsToDollars, formatDollarsToCents } from 'utils/price'
import { INITIAL_MINUTE, LAST_MINUTE } from 'settings/constants/time'
import {
  TEXT_FILTERS,
  NUMBER_FILTERS,
  DATE_FILTERS,
  CHECKBOX_FILTERS,
  META_FILTERS,
  FILTER_TYPES,
  FILTER_SUB_TYPES,
} from 'settings/constants/filters'

const { NUMBER, STRING, CHECKBOX, META, DATE } = FILTER_TYPES
const { MONEY } = FILTER_SUB_TYPES

const getInitialFilterOperation = filterType => {
  switch (filterType) {
    case STRING:
      return TEXT_FILTERS

    case CHECKBOX:
      return CHECKBOX_FILTERS

    case META:
      return META_FILTERS

    case DATE:
      // for date we only have GREATER_THEN_EQUAL/LESS_THEN_EQUAL
      return DATE_FILTERS

    default:
      return NUMBER_FILTERS
  }
}

const getDateOperation = (isBefore, operation, isBetween) => {
  if (isBefore) {
    return 'LESS_THEN_EQUAL'
  }

  if (['IS_NOT_NULL', 'IS_NULL'].includes(operation)) {
    return operation
  }

  if (isBetween) {
    return 'IS_BETWEEN'
  }

  return 'GREATER_THEN_EQUAL'
}

const handleTimeWithDate = (allFilters, key, isBetween) => {
  const columnValue = get(allFilters, `${key}.columnValue`)
  const subType = get(allFilters, `${key}.subType`)
  const operation = get(allFilters, `${key}.operation`)

  // check Cast
  if (isBetween && operation === 'DATE_IS_BETWEEN' && columnValue && !columnValue.includes('#')) {
    const onlyDate = get(columnValue.split(' '), '[0]')
    return subType === 'dateTime' ? `${onlyDate} ${INITIAL_MINUTE}#${onlyDate} ${LAST_MINUTE}` : `${onlyDate}#${onlyDate}`
  }

  return columnValue
}

const handleDateFormatFilter = (allFilters, key) => {
  const operation = get(allFilters, `${key}.operation`)
  const isBefore = operation.includes('BEFORE')
  const isAfter = operation.includes('AFTER')
  const isBetween = operation.includes('IS_BETWEEN')

  return {
    columnName: get(allFilters, `${key}.columnName`),
    columnValue:
      isBefore || isAfter || isBetween ? handleTimeWithDate(allFilters, key, isBetween) : operation.replace(/DATE_|DATE_RANGE_/g, ''),
    operation: getDateOperation(isBefore, operation, isBetween),
  }
}

const handleCheckboxFormatFilter = (allFilters, key) => {
  const isSingleSelect = get(allFilters, `${key}.isSingleSelect`, false)
  const isBoolean = get(allFilters, `${key}.isBoolean`, false)
  let columnValue = get(allFilters, `${key}.columnValue`)

  if (isBoolean) {
    // convert all to bool
    columnValue = columnValue.map(value => value === 'true')
  }

  return {
    columnName: get(allFilters, `${key}.columnName`),
    columnValue: isSingleSelect ? columnValue[0] : columnValue,
    operation: get(allFilters, `${key}.operation`),
  }
}

const isMoneyFilter = (filterType, subType) => filterType === NUMBER && subType === MONEY

const handleMoneyFormatFilter = (allFilters, key) => {
  const columnValue = get(allFilters, `${key}.columnValue`, 0)

  return {
    columnName: get(allFilters, `${key}.columnName`),
    columnValue: formatDollarsToCents(columnValue),
    operation: get(allFilters, `${key}.operation`),
  }
}

// On click of filter icon
export const handleCommonTableFilterChange = ({
  openEvent,
  allFilters,
  filterType,
  columnName,
  subType,
  label,
  isSingleSelect = false,
  isBoolean = false,
}) => {
  const isFilterExist = get(allFilters, `${columnName}.filterType`)
  if (isFilterExist) {
    return { isOpenTableFilters: openEvent, activeFilter: columnName }
  }

  return {
    isOpenTableFilters: openEvent,
    activeFilter: columnName,
    allFilters: {
      ...allFilters,
      [columnName]: {
        filterType,
        operation: getInitialFilterOperation(filterType)[0].key,
        columnValue: '',
        columnName,
        subType,
        label,
        isSingleSelect,
        isBoolean,
      },
    },
  }
}

// On click of sort icon
export const handleCommonTableSortingChange = (allSortBy, columnName, type) => {
  const existingSortBy = get(allSortBy, columnName, {})
  const existingOperation = get(existingSortBy, 'operation', '')
  let newOperation = ''

  // Empty or different, for same it will remove
  if (existingOperation === '' || existingOperation !== type) {
    newOperation = type
  }

  return {
    ...allSortBy,
    [columnName]: {
      ...get(allSortBy, columnName, {}),
      columnName,
      operation: newOperation,
    },
  }
}

// Filter UI change handler
export const handleFilterInputChange = (key, value, columnName, allFilters) => {
  const newState = {
    allFilters: {
      ...allFilters,
      [columnName]: {
        ...get(allFilters, columnName, {}),
        [key]: value,
      },
    },
  }

  // reset value for date calendar operation
  if (key === 'operation' && ['DATE_BEFORE', 'DATE_AFTER', 'DATE_IS_BETWEEN', 'DATE_RANGE_IS_BETWEEN'].includes(value)) {
    set(newState, `allFilters.${columnName}.columnValue`, '')
  }

  return newState
}

export const isValidFilter = (allFilters, key) => {
  const value = get(allFilters, `${key}.columnValue`, '')

  // If no operation then skip
  if (!get(allFilters, `${key}.operation`, '')) {
    return false
  }

  if (Array.isArray(value) && !value.length) {
    return false
  }

  if (get(allFilters, `${key}.filterType`) === 'date') {
    return true
  }

  if (value) {
    return true
  }

  return false
}

export const handleCommonTableformatFilters = allFilters => {
  const searchFilters = []

  // get list of filters
  Object.keys(allFilters).forEach(key => {
    if (isValidFilter(allFilters, key)) {
      const filterType = get(allFilters, `${key}.filterType`)
      let filterValue = get(allFilters, key)

      if (filterType === DATE) {
        filterValue = handleDateFormatFilter(allFilters, key)
      } else if (filterType === CHECKBOX) {
        filterValue = handleCheckboxFormatFilter(allFilters, key)
      } else if (isMoneyFilter(filterType, get(allFilters, `${key}.subType`))) {
        filterValue = handleMoneyFormatFilter(allFilters, key)
      }

      searchFilters.push(filterValue)
    }
  })

  return searchFilters
}

export const handleCommonTableformatSortBy = newSortBy => {
  const sortRules = []

  Object.keys(newSortBy).forEach(key => {
    if (get(newSortBy, `${key}.operation`)) {
      // If asc or desc then only use sortyBy
      sortRules.push(get(newSortBy, key))
    }
  })
  return sortRules
}

const getIsBetweenOperator = columnValue => {
  const splitDate = columnValue.split('#')
  const rangeTo = splitDate[0].split(' ')[0]
  const rangeFrom = splitDate[1].split(' ')[0]

  return rangeTo === rangeFrom ? 'DATE_IS_BETWEEN' : 'DATE_RANGE_IS_BETWEEN'
}

export const handlePrefillResponseModification = (initialFilter, filter) => {
  const initialFilterType = get(initialFilter, 'filterType', '')
  const subType = get(initialFilter, 'subType', '')
  let filterOperation = filter.operation

  if (initialFilterType === 'date') {
    const isBeforeAfter = ![
      'TODAY',
      'YESTERDAY',
      'THIS_WEEK',
      'LAST_WEEK',
      'PAST_TWO_WEEK',
      'THIS_MONTH',
      'LAST_MONTH',
      'IS_NOT_NULL',
      'IS_NULL',
    ].includes(filter.columnValue)

    if (filter.operation === 'IS_BETWEEN') {
      filterOperation = getIsBetweenOperator(filter.columnValue)
    } else if (isBeforeAfter) {
      filterOperation = filterOperation === 'LESS_THEN_EQUAL' ? 'DATE_BEFORE' : 'DATE_AFTER'
    } else {
      filterOperation = ['IS_NOT_NULL', 'IS_NULL'].includes(filter.operation) ? filter.operation : `DATE_${filter.columnValue}`
    }
  }

  const isSingleSelect = get(initialFilter, 'isSingleSelect', false)
  const isBoolean = get(initialFilter, 'isBoolean', false)
  let columnValue = get(filter, 'columnValue')

  if (isSingleSelect) {
    columnValue = [columnValue]
  }

  if (isBoolean) {
    columnValue = columnValue.map(value => value.toString())
  }

  if (isMoneyFilter(initialFilterType, subType)) {
    columnValue = formatCentsToDollars(columnValue)
  }

  return {
    columnName: filter.columnName,
    columnValue,
    operation: filterOperation,
    filterType: initialFilterType,
    subType,
    label: get(initialFilter, 'label', ''),
    isSingleSelect,
    isBoolean,
  }
}
