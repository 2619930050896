import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { NumericFormat } from 'react-number-format'

import LastNextPriceUpdateColumn from 'components/pricing/LastNextPriceUpdateColumn'

import { TableCell } from '@mui/material'

import { Check } from '@styled-icons/heroicons-solid/Check'
import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import { get } from 'utils/lodash'

const FeeBody = ({ service, column, getFeeServiceName, ind, expand, handleExpand, lockedColumns }) => {
  const isLocked = get(column, 'locked', false)
  const handleService = () => {
    const serviceData = getFeeServiceName(service, column.label)

    if (serviceData === undefined) return

    return serviceData
  }

  const feeServiceName = handleService()

  const tiers = get(service, 'tiers', [])

  let tiers_count = 0

  tiers_count = tiers.length

  return (
    <>
      {['Overage Fee'].includes(column.label) && (
        <>
          <TableCell className={`next-last-field ${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
            <div>{get(service, 'overageThreshold', [])}</div>
          </TableCell>

          <TableCell
            className={`next-last-value ${column.label !== 'Last Value Update' && 'last-value'} ${handlePricingTableClass(
              ind,
              isLocked,
              lockedColumns
            )}`}
          >
            <div>{feeServiceName}</div>
          </TableCell>
        </>
      )}

      <LastNextPriceUpdateColumn service={service} column={column} ind={ind} lockedColumns={lockedColumns} />

      {!['Last Value Update', 'Next Value Update', 'Overage Fee'].includes(column.label) && (
        <TableCell className={`${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
          <div className={`price-cell ${column.label === 'Tiers' && 'tiers-icon'}`}>
            {column.label === 'Tiers' && (
              <>
                {tiers_count > 1 && (
                  <>
                    {!expand[service.id] && (
                      <>
                        <div onClick={() => handleExpand(service.id, true)}>
                          <ChevronRight />
                          <span>{tiers_count}</span>
                        </div>
                      </>
                    )}

                    {expand[service.id] && (
                      <>
                        <div onClick={() => handleExpand(service.id, false)} className="tiers-details">
                          <ChevronDown />
                          <div className="tiers-range">
                            {Children.toArray(
                              tiers
                                .sort((a, b) => a.minVal > b.minVal)
                                .map((tier, indL) => {
                                  return (
                                    <div className={`range ${indL > 0 ? 'mt4' : ''}`}>
                                      <div className="min-val">{`${tier.minVal}`} </div>

                                      {tiers.length !== indL + 1 && <div className="dash">{` - `}</div>}

                                      <div className="max-val">
                                        {tiers.length !== indL + 1 && ` ${tier.maxVal} `}

                                        {tiers.length === indL + 1 && ` + `}
                                      </div>
                                    </div>
                                  )
                                })
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {tiers_count === 1 && (
                  <>
                    <div className="empty-dash">-</div>
                  </>
                )}
              </>
            )}

            {column.label === 'Price' && tiers.length > 0 && (
              <div className="price">
                {expand[service.id] &&
                  Children.toArray(
                    tiers.map((tier, indx) => {
                      return (
                        <div className={`price-val ${indx > 0 ? 'mt4' : ''}`}>
                          <NumericFormat
                            value={tier.price}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                            decimalSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </div>
                      )
                    })
                  )}

                {!expand[service.id] && (
                  <div className="price-val">
                    <NumericFormat
                      value={tiers[0].price}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                )}
              </div>
            )}

            {column.label === 'Cost Plus' && feeServiceName && <Check className="cost-plus" />}

            {!['Tiers', 'Price', 'Cost Plus'].includes(column.label) && feeServiceName}
          </div>
        </TableCell>
      )}
    </>
  )
}

FeeBody.defaultProps = {
  ind: null,
  service: {},
  expand: {},
  column: {},
  lockedColumns: [],
  getFeeServiceName: null,
  handleExpand: null,
}

FeeBody.propTypes = {
  ind: PropTypes.number,
  service: PropTypes.object,
  expand: PropTypes.object,
  column: PropTypes.object,
  lockedColumns: PropTypes.array,
  getFeeServiceName: PropTypes.func,
  handleExpand: PropTypes.func,
}

export default FeeBody
