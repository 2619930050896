import React from 'react'
import PropTypes from 'prop-types'
import { Box, useMediaQuery, Grid, useTheme, MenuItem } from '@mui/material'
import SearchCustomerTextItem from './SearchCustomerTextItem'

const SearchCustomerOption = ({
  accountNumber = '',
  accountName = '',
  address = '',
  contactName = '',
  phone = '',
  billingProfile = '',
  ...rest
}) => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <MenuItem component="li" {...rest} divider={!isLg}>
      {isLg && (
        <Box display="flex" width="100%" gap={1}>
          <SearchCustomerTextItem text={accountNumber !== '' ? `#${accountNumber}` : ''} color="textSecondary" flex="0 0 10%" />
          <SearchCustomerTextItem text={accountName} flex="0 0 15%" />
          <SearchCustomerTextItem text={address} flex="0 0 30%" />
          <SearchCustomerTextItem text={contactName} flex="0 0 15%" />
          <SearchCustomerTextItem text={phone} flex="0 0 15%" />
          <SearchCustomerTextItem text={billingProfile} flex="0 0 15%" />
        </Box>
      )}
      {!isLg && (
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <SearchCustomerTextItem align="left" text={accountName} flex="0 0 20%" />
            <SearchCustomerTextItem
              align="left"
              text={accountNumber !== '' ? `#${accountNumber}` : ''}
              color="textSecondary"
              flex="0 0 20%"
            />
            <SearchCustomerTextItem align="center" text={phone} flex="0 0 30%" />
            <SearchCustomerTextItem align="right" text={contactName} flex="0 0 30%" />
          </Grid>
          <Grid container item xs={12}>
            <SearchCustomerTextItem align="left" text={address} flex="0 0 60%" />
            <SearchCustomerTextItem align="right" text={billingProfile} flex="0 0 40%" />
          </Grid>
        </Grid>
      )}
    </MenuItem>
  )
}

SearchCustomerOption.propTypes = {
  accountNumber: PropTypes.string,
  accountName: PropTypes.string,
  address: PropTypes.string,
  contactName: PropTypes.string,
  phone: PropTypes.string,
  billingProfile: PropTypes.string,
  searchText: PropTypes.string,
}

export default SearchCustomerOption
