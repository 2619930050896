/* eslint-disable */
import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import StyledTextField from './StyledTextField'
import WithInputLabel from './WithInputLabel'
import HHAddressAutocompleteField from './HHAddressAutocompleteField'

const HHFormAddressAutocompleteField = ({
  name,
  control,
  disabled = false,
  label,
  placeholder = '',
  rules,
  required = false,
  debug = false,
  deprecatedLabel = true,
  error = false,
  onChange = noop,
  onInputChange = noop,
  helperText = '',
  ...rest
}) => {
  const [open, setOpen] = React.useState(false)
  return (
    <WithInputLabel label={deprecatedLabel && label} required={required || rules?.required === true}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={props => {
          const {
            field: { onChange: hookFormOnChange, onBlur, value, ref },
          } = props
          const handleBlur = () => {
            setOpen(false)
            onBlur()
          }

          const handleChange = (event, item, reason) => {
            hookFormOnChange(item)
            onChange(event, item, reason)
          }

          const openOptionList = () => {
            if (!disabled) {
              setOpen(true)
            }
          }

          return (
            <HHAddressAutocompleteField
              open={debug || open}
              onBlur={handleBlur}
              ref={ref}
              value={value}
              disabled={disabled}
              onChange={handleChange}
              onInputChange={onInputChange}
              renderInput={params => {
                return (
                  <StyledTextField
                    label={deprecatedLabel ? null : label}
                    onClick={openOptionList}
                    onFocus={openOptionList}
                    ref={ref}
                    placeholder={Array.isArray(value) ? value.length === 0 && placeholder : ''}
                    error={error}
                    onBlur={onBlur}
                    {...params}
                    helperText={helperText}
                  />
                )
              }}
              {...rest}
            />
          )
        }}
      />
    </WithInputLabel>
  )
}

HHFormAddressAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  required: PropTypes.bool,
  debug: PropTypes.bool,
  deprecatedLabel: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  helperText: PropTypes.string,
}

export default HHFormAddressAutocompleteField
