import React from 'react'

import { ReactComponent as NoneRentalFeeIcon } from 'assets/NoneRentalFee.svg'

import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

const RenderNoneOption = () => {
  return (
    <ListItem sx={{ px: 0 }}>
      <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
        <NoneRentalFeeIcon width={28} height={28} />
      </ListItemIcon>
      <ListItemText
        primary="None"
        primaryTypographyProps={{ variant: 'h5', fontWeight: 500, noWrap: true, color: 'textPrimary' }}
        secondary="No rental fee connected"
        secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
      />
    </ListItem>
  )
}

export default RenderNoneOption
