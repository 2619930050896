import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'

import Grid from '@mui/material/Grid'

import { get } from 'utils/lodash'
import { getAddressParams } from 'utils/helper'
import { HHFormTextField, HHFormAutocompleteField } from 'components/form-fields/v5'
import { useLazyGetAddressSuggestListQuery } from 'api/map/getAddressSuggestList'

import T from 'T'
import { DEFAULT_ADDRESS_FIELD_KEY, DEFAULT_STATE } from './model'

const { line1Placeholder, line2Placeholder } = DEFAULT_STATE

const AddressRowOne = ({
  fieldPrefix = DEFAULT_ADDRESS_FIELD_KEY,
  line1 = '',
  control,
  onLine1Change,
  onBillingAddressChange,
  onKeyDown = noop,
}) => {
  const [getSuggestList, { data }] = useLazyGetAddressSuggestListQuery()
  const addressList = get(data, 'suggestions', [])

  const handleAddressLineChange = useMemo(() => debounce(value => getSuggestList({ search: value }), 300), [])

  useEffect(() => {
    return () => {
      handleAddressLineChange.cancel()
    }
  }, [])

  return (
    <>
      <Grid item xs={12}>
        <HHFormAutocompleteField
          clearOnBlur={false}
          blurOnSelect
          selectOnFocus={false}
          openOnFocus
          noOptionsText={T.SEARCH_ADDRESS_PLACEHOLDER}
          control={control}
          name={`${fieldPrefix}.line1`}
          label={T.STREET}
          placeholder={line1Placeholder}
          deprecatedLabel={false}
          getOptionLabel={option => {
            if (option?.text) {
              const { line1: addressLine1, city, state, zipCode } = getAddressParams(option)
              return (
                <>
                  {addressLine1}
                  <br />
                  {city ? `${city}, ` : ''}
                  {`${state} ${zipCode}`.trim()}
                </>
              )
            }
            return line1
          }}
          filterOptions={x => x}
          options={addressList}
          includeInputInList
          filterSelectedOptions
          onInputChange={(event, newInputValue) => {
            onLine1Change(newInputValue)
            if (event?.type !== 'change') {
              return
            }

            if (newInputValue) {
              handleAddressLineChange(newInputValue)
            }
          }}
          onChange={(event, address) => {
            if (!address) {
              return
            }

            onBillingAddressChange(address)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <HHFormTextField
          control={control}
          name={`${fieldPrefix}.unitNumber`}
          label={T.STREET_LINE_2}
          placeholder={line2Placeholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={onKeyDown}
        />
      </Grid>
    </>
  )
}

AddressRowOne.propTypes = {
  fieldPrefix: PropTypes.string,
  line1: PropTypes.string,
  control: PropTypes.object.isRequired,
  onLine1Change: PropTypes.func.isRequired,
  onBillingAddressChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
}

export default AddressRowOne
