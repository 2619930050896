import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import { toast } from 'react-toastify'

import { shallowEqual, useSelector } from 'react-redux'
import { DialogContent, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { get } from 'utils/lodash'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import {
  useBulkChangeBillingProfileForAccountsMutation,
  useBulkChangeBillingProfileForAccountGroupMutation,
} from 'api/groups/account/bulkChangeBillingProfile'
import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import BillingProfileDropdown from 'components/customer-details/content/common/billing-dropdown/index'

const BulkChangeBillingProfileDialog = ({ isOpen = false, groupId, allAccounts = [], onClose }) => {
  const [bulkChangeBillingProfileForAccounts, { isLoading: isLoadingForAccounts }] = useBulkChangeBillingProfileForAccountsMutation()
  const [bulkChangeBillingProfileForAccountGroup, { isLoading: isLoadingForAccountGroup }] =
    useBulkChangeBillingProfileForAccountGroupMutation()

  const [billingProfileId, setBillingProfileId] = useState('')
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const billingProfiles = get(customerMeta, 'billingProfiles', [])
  const defaultProfile = useMemo(() => find(billingProfiles, 'default'), [billingProfiles])

  const handleSuccess = () => {
    toast.success('Billing profile successfully changed')
    onClose()
  }

  const handleChangeBillingProfile = () => {
    const isDefaultBucket = Object.values(BUCKET_NAME_TO_GROUP_ID_PARAM).includes(groupId)
    const isSuspendedGroup = getIsSuspendedBucket(groupId)

    if (isDefaultBucket || isSuspendedGroup) {
      bulkChangeBillingProfileForAccounts({ billingProfileId, accountIds: allAccounts.map(account => account.accountId) })
        .unwrap()
        .then(handleSuccess)
      return
    }

    bulkChangeBillingProfileForAccountGroup({ groupId, billingProfileId }).unwrap().then(handleSuccess)
  }

  useEffect(() => {
    if (isOpen && defaultProfile?.id) {
      setBillingProfileId(defaultProfile.id)
    }
  }, [isOpen, defaultProfile])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} fullWidth PaperProps={{ sx: { maxWidth: '625px' } }}>
      <HHDialogTitle title={capitalize(T.BILLING_PROFILE)} onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Box mt={2}>
          <BillingProfileDropdown
            label={`${T.BILLING_PROFILE} *`}
            billingProfileId={billingProfileId}
            onChange={event => setBillingProfileId(event.target.value)}
          />
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton
          loading={isLoadingForAccounts || isLoadingForAccountGroup}
          size="small"
          variant="contained"
          onClick={handleChangeBillingProfile}
        >
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

BulkChangeBillingProfileDialog.propTypes = {
  isOpen: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  allAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default BulkChangeBillingProfileDialog
