import React, { useCallback, useMemo } from 'react'
import capitalize from 'lodash/capitalize'

import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, useGridApiRef } from '@mui/x-data-grid-pro'

import { get } from 'utils/lodash'
import { getPaymentsFromBatch } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import RenderPaymentGroupWrapper from './RenderPaymentGroupWrapper'
import PaymentActivityFeedWrapper from './PaymentActivityFeedWrapper'

const PaymentGroupList = () => {
  const { id: groupId } = useParams()
  const { data, isLoading } = useGetPaymentGroupDetailsQuery({ id: groupId })
  const payments = getPaymentsFromBatch({ paymentBatches: get(data, 'paymentBatches', []) })
  const apiRef = useGridApiRef()

  const getDetaiilPanelContent = useCallback(({ row }) => {
    const {
      invoices,
      accountId,
      accountCredit,
      paymentNumber,
      transactionStatus,
      billingCycleInterval,
      billingCycleIntervalType,
      billingProfileTiming,
      billingProfileTimingDay,
    } = row
    const billingProfileDetails = {
      billingCycle: {
        interval: billingCycleInterval,
        intervalType: capitalize(billingCycleIntervalType),
      },
      timing: capitalize(billingProfileTiming),
      timingDay: billingProfileTimingDay,
    }
    return (
      <PaymentActivityFeedWrapper
        accountId={accountId}
        invoices={invoices}
        paymentNumber={paymentNumber}
        accountCredit={accountCredit}
        transactionStatus={transactionStatus}
        billingProfileDetails={billingProfileDetails}
      />
    )
  }, [])

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ id }) => id, [])

  const getRowHeight = useCallback(({ id }) => {
    if (id === 'header') return 'auto'
    return null
  }, [])

  const getRowSpacing = useCallback(
    params => ({
      bottom: params.isLastVisible ? 128 : 0,
    }),
    []
  )

  const rows = useMemo(() => (isLoading ? [] : [{ id: 'header' }, ...payments]), [isLoading, payments])

  return (
    <Box display="flex" height="100%" overflow="auto">
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            slots={{ noRowsOverlay: () => null }}
            apiRef={apiRef}
            hideFooter
            rows={rows}
            getRowSpacing={getRowSpacing}
            rowHeight={119}
            getRowId={getRowId}
            getDetailPanelContent={getDetaiilPanelContent}
            getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
            getRowHeight={getRowHeight}
            columnVisibilityModel={{
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            }}
            columns={[
              {
                field: 'id',
                flex: 1,
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: RenderPaymentGroupWrapper,
              },
              {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                renderCell: () => <div />,
              },
            ]}
            columnHeaderHeight={0}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
                border: 0,
                padding: 0,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },

              '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
                backgroundColor: 'background.paper',
              },
              '& .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
              },
              '& .MuiDataGrid-menuIcon': {
                visibility: 'visible',
                width: 'unset',
              },
            }}
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentGroupList
