import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import GeneralBillingSettings from 'components/settings/pages/billing/GeneralBillingSettings'
import CustomerBillingProfileSettings from 'components/settings/pages/billing/CustomerBillingProfileSettings'
import PostcardSetupSettings from 'components/settings/pages/billing/PostcardSetupSettings'
import GeneralLedgerSettings from 'components/settings/pages/billing/GeneralLedgerSettings'

import T from 'T'

const BillingSettings = ({ billingOption = '' }) => {
  return (
    <Box>
      {billingOption === T.GENERAL && <GeneralBillingSettings />}
      {billingOption === `${T.CUSTOMER_BILLING_PROFILE}s` && <CustomerBillingProfileSettings />}
      {billingOption === T.POSTCARD_SETUP && <PostcardSetupSettings />}
      {billingOption === T.GENERAL_LEDGER && <GeneralLedgerSettings />}
    </Box>
  )
}

BillingSettings.propTypes = {
  billingOption: PropTypes.string,
}

export default BillingSettings
