import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { useSelector } from 'react-redux'
import {
  selectBulkMoveFromSerializedDate,
  selectBulkMoveToRows,
  selectBulkMoveToSerializedDate,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import BasePermanentMoveDialog from 'components/route-manager/BulkActions/common/BasePermanentMoveDialog'
import PermanentMoveAlert from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/ConfirmPermanentMoveDialog/PermanentMoveAlert'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'

const ConfirmPermanentMoveDialog = ({
  confirmPermanentGridApiRef,
  isOpen,
  onClose = noop,
  onConfirm = noop,
  DialogProps = {},
  isPermanentLoading,
}) => {
  const moveToRows = useSelector(selectBulkMoveToRows)
  const moveToSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const getIsFromRow = useCallback(row => row.date === fromFEDate, [fromFEDate])

  return (
    <BasePermanentMoveDialog
      AlertComponent={PermanentMoveAlert}
      DialogProps={DialogProps}
      confirmPermanentGridApiRef={confirmPermanentGridApiRef}
      isPermanentLoading={isPermanentLoading}
      moveToRows={moveToRows}
      onClose={onClose}
      onConfirm={onConfirm}
      open={isOpen}
      serializedServiceDate={moveToSerializedDate}
      getIsFromRow={getIsFromRow}
    />
  )
}

ConfirmPermanentMoveDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  DialogProps: PropTypes.object,
  confirmPermanentGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isPermanentLoading: PropTypes.bool,
}

export default ConfirmPermanentMoveDialog
