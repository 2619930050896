import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'

import T from 'T'
import LoadingButton from '@mui/lab/LoadingButton'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { setAddContactState } from 'slices/customer/contactsSlice'

const AddCustomerPortalUserSuccessDialog = ({
  isOpen = false,
  accountId,
  firstName = '',
  lastName = '',
  email,
  isNewUser = false,
  parent = '',
  onClose,
}) => {
  const dispatch = useDispatch()
  const handleAddToContact = () => {
    onClose()
    dispatch(
      setAddContactState({
        isOpen: true,
        accountId,
        initialMethodValue: email,
        initialFirstName: firstName,
        initialLastName: lastName,
        parent,
      })
    )
  }

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.SUCCESS} showCloseIcon onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" fontWeight={400} color="textPrimary">
            {`An invite email was sent to ${firstName} at ${email}. Please inform your customer to check their email for a private link to sign up.`}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        {isNewUser && (
          <LoadingButton variant="outlined" onClick={handleAddToContact}>
            {T.ADD_TO_CONTACTS}
          </LoadingButton>
        )}
        <LoadingButton variant="contained" onClick={onClose}>
          {T.OK}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

AddCustomerPortalUserSuccessDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  isNewUser: PropTypes.bool,
  parent: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default AddCustomerPortalUserSuccessDialog
