import api from 'api'
import { CACHE_TAG_BILLING_AR_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingARList: build.query({
      query: body => ({
        url: '/api/v1/billing/account/receivables/list',
        method: 'POST',
        body,
      }),
      providesTags: [CACHE_TAG_BILLING_AR_LIST],
      transformResponse: data => {
        const { accountReceivables, totalItems, totalPages } = data
        return {
          tableRows: accountReceivables,
          totalItems,
          totalPages,
        }
      },
    }),
  }),
})

export const { useGetBillingARListQuery, useLazyGetBillingARListQuery } = extendedApi
