import { takeEvery, all, call, put, delay } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { get } from 'utils/lodash'
import { getDownloadURL } from 'utils/download'
import { downloadFile } from 'utils/file'
import { doPost } from 'providers/api'
import { putIsLoading } from 'middleware/actions/response'

import T from 'T'
import { actions } from '../actions/download'

// Call status API maximum 10 times
const MAX_API_CALL_TIMEOUT = 10
const API_CALL_DELAY = 3000
const API_FAILED_STATUS = 'FAILED'

export const globalInitiateDownload = ({ body, url }) => doPost({ url, body, showLoader: false })
export const globalDownloadStatus = ({ body, url }) => doPost({ url, body, showLoader: false })
export const globalDownloadFile = ({ body, url, buffer }) =>
  doPost({ url, body, showLoader: false, arraybuffer: buffer ? 'arraybuffer' : '' })

function* changeLoader(isLoading) {
  yield put(putIsLoading(isLoading))
}

function* checkDownloadStatusSaga({ requestId, fileAvailable, url, apiCallCount }) {
  if (fileAvailable) {
    return true
  }

  if (apiCallCount > MAX_API_CALL_TIMEOUT) {
    toast.error(T.DOWNLOAD_TIMEOUT)
    return false
  }

  yield delay(API_CALL_DELAY)

  const { data: statusResponse } = yield call(globalDownloadStatus, {
    body: { id: requestId },
    url,
  })

  const { available } = statusResponse

  if (get(statusResponse, 'status') === API_FAILED_STATUS) {
    toast.error(get(statusResponse, 'description'))
    return false
  }

  if (!available) {
    return yield call(checkDownloadStatusSaga, { requestId, fileAvailable: available, url, apiCallCount: apiCallCount + 1 })
  }

  return true
}

export function* globalInitiateStatusDownloadSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    yield* changeLoader(true)

    const { type = '', urlToMapWith = '', isDownload = false, buffer = false } = body
    const { initiateUrl, statusUrl, downLoadUrl } = getDownloadURL(urlToMapWith)

    const { data: initiateResponse } = yield call(globalInitiateDownload, { body, url: initiateUrl })
    const requestId = get(initiateResponse, 'requestId', get(initiateResponse, 'id'))

    if (!requestId) {
      if (typeof onSuccess === 'function') {
        onSuccess(false)
        yield* changeLoader(false)
      }
      return
    }

    // call status API
    const isAvailable = yield* checkDownloadStatusSaga({ requestId, fileAvailable: false, url: statusUrl, apiCallCount: 1 })

    if (!isAvailable) {
      onSuccess(false)
      yield* changeLoader(false)
      return
    }

    // call download API
    const res = yield call(globalDownloadFile, { body: { id: requestId }, url: downLoadUrl, buffer })

    // for download, we are getting response as res.data
    // for base64, we are getting res.data.data
    const fileData = isDownload ? get(res, 'data', res) : get(res, 'data.data', res)

    if (!isDownload) {
      // Pass result to component
      onSuccess(true, fileData)
      yield* changeLoader(false)
      return
    }

    let { fileName } = body

    if (get(res, 'headers')) {
      fileName = decodeURI(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
    }

    downloadFile(fileData, fileName, type)
    onSuccess(true)
    yield* changeLoader(false)
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
    yield* changeLoader(false)
  }
}

export default function* Download() {
  yield all([takeEvery(actions.INITIATE_STATUS_DOWNLOAD, globalInitiateStatusDownloadSaga)])
}
