import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { Checkbox, FormControlLabel, Typography, Box, Grid, Card } from '@mui/material'
import HHMultiSelectAutocompleteField from 'components/form-fields/v5/HHMultiSelectAutocompleteField'
import { CommonSwitch } from 'components/common'
import FooterButton from 'components/buttons/FooterButton'
import T from 'T'
import Constants from 'Constants'
import Loader from 'components/common/loader'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const AddAssets = ({
  assetState = {},
  setAssetState = noop,
  isLoading = false,
  handleSave = noop,
  handleCancel = noop,
  onHandleNameChange = noop,
}) => {
  const handleSwitchVal = value => {
    setAssetState({ active: value, footerFlag: true })
  }

  const onHandleValChange = (e, type) => {
    let { value } = e.target
    if (type === 'commercialDriverLicense') {
      value = e.target.checked
    }
    setAssetState({ [type]: value, footerFlag: true })
  }

  return (
    <Card>
      {isLoading && <Loader />}
      <Grid container justifyContent="space-between" height="calc(100vh - 180px)" p="20px 24px">
        <Grid item xs={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">{T.GENERAL}</Typography>
            </Grid>
            <Grid item xs={12} mt={1.5}>
              <HHMultiSelectAutocompleteField
                options={get(assetState, 'methods', [])}
                label={`${T.COLLECTION} ${T.METHOD}`}
                getOptionLabel={option => get(option, 'methodName', '')}
                value={get(assetState, 'selectedMethods', [])}
                onChange={(event, selectedMethods) => {
                  setAssetState({ selectedMethods, footerFlag: true })
                }}
                placeholder={get(assetState, 'selectedMethods', []).length ? '' : `${T.COLLECTION} ${T.METHOD}`}
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12} mt={1.5}>
              <HHMultiSelectAutocompleteField
                options={get(assetState, 'businessLines', [])}
                label={`${T.BUSINESS_LINE}s`}
                getOptionLabel={option => get(option, 'businessLineLabel', '')}
                value={get(assetState, 'selectedBusinessLines', [])}
                onChange={(event, selectedBusinessLines) => {
                  setAssetState({ selectedBusinessLines, footerFlag: true })
                }}
                placeholder={get(assetState, 'selectedBusinessLines', []).length ? '' : `${T.BUSINESS_LINE}s`}
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">{T.POWER_UNIT}</Typography>
            </Grid>
            <Grid item xs={12} mt={1.5}>
              <HHTextField
                label={`${T.TRUCK} ${T.NAME}`}
                placeholder={`${T.TRUCK} ${T.NAME}`}
                value={assetState.assetName}
                variant="outlined"
                autoComplete="off"
                name="assetName"
                InputProps={{
                  onChange: e => onHandleNameChange(e),
                }}
                sx={{
                  width: 278,
                }}
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} width="16px" height="16px" alt="" />}
                    checkedIcon={<img src={`${MEDIA_SERVER}ConfiguratorCheckbox.svg`} width="16px" height="16px" alt="" />}
                    color="primary"
                    name="commercialDriverLicense"
                    checked={assetState.commercialDriverLicense}
                    onChange={e => onHandleValChange(e, 'commercialDriverLicense')}
                    sx={{ p: 0, m: '0 12px' }}
                  />
                }
                label={`${T.REQUIRES} ${T.COMMERCIAL_DRIVER_LICENSE}`}
                sx={{ whiteSpace: 'noWrap', mr: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CommonSwitch title={T.ACTIVE} isChecked={get(assetState, 'active', false)} onChange={checked => handleSwitchVal(checked)} />
        </Grid>
      </Grid>

      {assetState.footerFlag && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={() => handleCancel()}
            rightButtonTitle={T.SAVE}
            onProceed={handleSave}
            disabledProceed={
              assetState.assetName === '' ||
              get(assetState, 'selectedMethods', []).length === 0 ||
              get(assetState, 'selectedBusinessLines', []).length === 0
            }
          />
        </Box>
      )}
    </Card>
  )
}

AddAssets.propTypes = {
  assetState: PropTypes.object,
  isLoading: PropTypes.bool,
  setAssetState: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  onHandleNameChange: PropTypes.func,
}

export default AddAssets
