import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { get } from 'lodash'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useDispatch } from 'react-redux'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import {
  deserializeDate,
  formatDateToBEFormatDateFns,
  formatDateToBulkMoveStr,
  formatDateToFEFormatDateFns,
  serializeDate,
} from 'utils/date'
import { mapStopToListItemFormat } from 'utils/route'
import { isSameDay } from 'date-fns'
import T from 'T'
import { HHAlert } from 'components/common/HHAlert'
import { MapContext } from 'providers/MapProvider'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import { removeLayersAndSources } from 'components/route-manager/BulkActions/settings'
import DateColumnTitle from 'components/route-manager/BulkActions/common/DateColumnTitle'
import useParseDaysOfWeekBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/useParseDaysOfWeekBetweenDaysVariant'

const CommonMoveToColumnBetweenDays = ({
  apiRef,
  bulkReduxAction,
  fetchAndFormatGeojsonData,
  fetchId,
  fromSerializedDate,
  moveToRows,
  routeId,
  renderHeader,
  renderCell,
  selectedMoveFromRows,
  selectedMoveToRows,
  setSelectedMoveToRows,
  stopsToBeAssignedCount,
  toSerializedDate,
  validStopIdsToBeAssigned,
}) => {
  const map = useContext(MapContext)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const isMoveToDateButtonDisabled = stopsToBeAssignedCount !== 0 || selectedMoveFromRows.length !== 0 || selectedMoveToRows.length !== 0
  const fromDate = useMemo(() => {
    if (fromSerializedDate) {
      return deserializeDate(fromSerializedDate)
    }
    return new Date()
  }, [fromSerializedDate])
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const toDateTitleFormat = toDate ? formatDateToBulkMoveStr(toDate) : T.DESTINATION_DATE
  const parseDaysOfWeek = useParseDaysOfWeekBetweenDaysVariant(toDate, fromSerializedDate, toSerializedDate)

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
    },
    {
      field: 'id',
      renderCell,
      renderHeader,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]
  const handleSelectionModelChange = selectionModel => {
    dispatch(setSelectedMoveToRows(selectionModel))
  }

  const getRowClassName = useCallback(
    params => {
      if (params.row.date === fromFEDate) {
        return validStopIdsToBeAssigned.includes(params.row.id) ? 'from-stop-assigned' : 'from-stop-to-be-assigned'
      }
      return ''
    },
    [fromSerializedDate, validStopIdsToBeAssigned]
  )

  const isRowSelectable = params => {
    if (!fromFEDate || !toFEDate) return false
    return params.row.date === fromFEDate
  }

  const handleToDateChange = date => {
    if (date && toDate && !isSameDay(date, toDate)) {
      removeLayersAndSources(map.current, formatDateToFEFormatDateFns(toDate))
    }
    dispatch(bulkReduxAction({ toSerializedDate: serializeDate(date) }))
    fetchAndFormatGeojsonData({
      routeId,
      date,
      markersStateKey: 'moveToRouteStopMarkers',
      sequenceLineStateKey: 'moveToRouteSequenceLine',
    })
  }

  const shouldDisableToDate = useCallback(
    date => {
      if (fromDate) {
        return isSameDay(date, fromDate)
      }
      return false
    },

    [fromDate]
  )

  useEffect(() => {
    if (routeId && toSerializedDate) {
      getRouteStops(
        {
          routes: [routeId],
          serviceDate: formatDateToBEFormatDateFns(toDate),
          id: fetchId,
        },
        false
      )
        .unwrap()
        .then(data => {
          const stops = get(data, `routes.0.stops`, [])
          const formattedStops = stops.map((stop, index) => {
            const daysOfWeek = get(stop, 'daysOfWeek', '')
            const parsedStopData = mapStopToListItemFormat(stop)
            const pricingPeriod = get(parsedStopData, 'pricingPeriod')
            const parsedDaysOfWeek = parseDaysOfWeek(daysOfWeek, pricingPeriod)
            return {
              ...parsedStopData,
              position: index,
              originalPosition: index,
              date: toFEDate,
              parsedDaysOfWeek,
              daysOfWeek,
            }
          })
          dispatch(
            bulkReduxAction({
              moveToRows: formattedStops,
            })
          )
        })
    }
  }, [toSerializedDate, fromSerializedDate, routeId, fetchId])

  return (
    <>
      <Box mx={2} mb={2}>
        <DateColumnTitle
          disabled={isMoveToDateButtonDisabled}
          onChange={handleToDateChange}
          title={toDateTitleFormat}
          value={toDate}
          shouldDisableDate={shouldDisableToDate}
        />
        {!toDate && (
          <HHAlert severity="info" borderColor={theme.palette.info.dark} sx={{ mt: 1 }}>
            Select a destination date to proceed
          </HHAlert>
        )}
      </Box>
      <Box flex={1} height="100%" width="100%">
        {toDate && (
          <BulkMoveStopsDataGridPro
            apiRef={apiRef}
            isRowSelectable={isRowSelectable}
            getRowClassName={getRowClassName}
            loading={isLoading || isFetching}
            rowSelectionModel={selectedMoveToRows}
            onRowSelectionModelChange={handleSelectionModelChange}
            columns={columns}
            rows={moveToRows}
          />
        )}
      </Box>
    </>
  )
}

CommonMoveToColumnBetweenDays.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  bulkReduxAction: PropTypes.func.isRequired,
  fetchAndFormatGeojsonData: PropTypes.func.isRequired,
  fetchId: PropTypes.string,
  fromSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  moveToRows: PropTypes.array.isRequired,
  routeId: PropTypes.string,
  selectedMoveFromRows: PropTypes.array.isRequired,
  selectedMoveToRows: PropTypes.array.isRequired,
  setSelectedMoveToRows: PropTypes.func.isRequired,
  stopsToBeAssignedCount: PropTypes.number,
  toSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  validStopIdsToBeAssigned: PropTypes.array.isRequired,
  renderHeader: PropTypes.node.isRequired,
  renderCell: PropTypes.node.isRequired,
}

export default CommonMoveToColumnBetweenDays
