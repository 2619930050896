import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

const RenderAccountHeader = ({ rows = [], selectedRows = [], onSelectAll, onDeselectAll }) => {
  const totalRows = rows.length
  const totalSelectedRows = selectedRows.length

  const handleSelectDeselect = () => {
    if (totalRows === totalSelectedRows) {
      onDeselectAll()
      return
    }

    onSelectAll()
  }

  return (
    <Box p={2} display="flex" justifyContent="space-between">
      <Typography color="textPrimary">{`${totalRows} account${totalRows > 1 ? 's' : ''}`}</Typography>
      <Typography sx={{ cursor: 'pointer' }} color="textPrimary" onClick={handleSelectDeselect}>
        {totalRows === totalSelectedRows ? 'Deselect all' : 'Select all'}
      </Typography>
    </Box>
  )
}

RenderAccountHeader.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.string.isRequired,
}

export default RenderAccountHeader
