import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getSkillList: build.query({
      query: () => ({
        url: 'api/settings/skill/list',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useLazyGetSkillListQuery } = extendedApi
