import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { noop } from 'lodash'

const HHDialogTitle = ({ title, icon, onClose = noop, showCloseIcon = true, sx, closeIconSx, TitleTypographyProps }) => {
  return (
    <DialogTitle sx={{ px: 3, py: 2, ...sx }}>
      <Stack sx={{ display: 'inline-flex' }} direction="row" alignItems="center" spacing={1}>
        {icon}
        <Typography variant="h4" fontWeight={600} {...TitleTypographyProps}>
          {title}
        </Typography>
      </Stack>
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
            ...closeIconSx,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  )
}

HHDialogTitle.propTypes = {
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  title: PropTypes.node,
  sx: PropTypes.object,
  closeIconSx: PropTypes.object,
  TitleTypographyProps: PropTypes.object,
}

export default HHDialogTitle
