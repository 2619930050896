import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useFormContext } from 'react-hook-form'
import { ListItem, ListItemText, Typography, Grid, CircularProgress } from '@mui/material'

import { get } from 'utils/lodash'
import { REGEX } from 'utils/validations'
import { isEnterOrNumpadEnter } from 'utils/keyboard'
import { HHFormTextField, HHTextField } from 'components/form-fields/v5'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'

const FormFields = ({ isLoading, emailContacts = [], onSubmit }) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const watchContactId = watch('contactId')
  const { firstName: firstNameError, email: emailError, confirmEmail: confirmEmailError } = errors
  const firstNameHelperText = get(firstNameError, 'message')
  const emaillHelperText = get(emailError, 'message')
  const confirmEmaillHelperText = get(confirmEmailError, 'message')
  const hasEmailContacts = emailContacts.length > 0

  const handleContactChange = value => {
    const newContact = emailContacts.find(contact => contact.id === value)
    const { firstName, lastName, methodValue: newEmail } = newContact

    setValue('contactId', value)
    setValue('firstName', firstName)
    setValue('lastName', lastName)
    setValue('email', newEmail)
    setValue('confirmEmail', newEmail)
    clearErrors()
  }

  const handleKeyDown = event => {
    if (isEnterOrNumpadEnter(event)) {
      handleSubmit(onSubmit)()
    }
  }

  const contactDropdownValue = useMemo(() => {
    if (hasEmailContacts) {
      return watchContactId ? [watchContactId] : ''
    }

    return [watchContactId]
  }, [hasEmailContacts, watchContactId])

  return (
    <>
      {isLoading && <CircularProgress size={36} />}
      {!isLoading && (
        <HHTextField
          sx={{
            '& .MuiOutlinedInput-root': {
              height: watchContactId && hasEmailContacts ? '50px!important' : 'auto',
            },
            '& .MuiSelect-select': {
              px: 0,
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  '& .Mui-selected .MuiTypography-root': {
                    color: 'inherit',
                    '&:hover': { color: 'inherit' },
                  },
                },
              },
            },
          }}
          select
          label="Contact email"
          value={contactDropdownValue}
          onChange={event => handleContactChange(event.target.value)}
          fullWidth
          deprecatedLabel={false}
          disabled={!hasEmailContacts}
        >
          {!hasEmailContacts && (
            <SelectItem value="">
              <ListItem dense>
                <ListItemText primary={<Typography>{T.NO_INFO_ON_FILE}</Typography>} />
              </ListItem>
            </SelectItem>
          )}
          {emailContacts.map(contact => {
            const { id, methodValue } = contact
            return (
              <SelectItem value={id} sx={{ p: 0 }}>
                <ListItem dense className="contact-email-item">
                  <ListItemText
                    className="contact-email-item"
                    primary={<Typography>{methodValue}</Typography>}
                    secondary={`${get(contact, 'firstName', '')} ${get(contact, 'lastName', '')}`.trim()}
                  />
                </ListItem>
              </SelectItem>
            )
          })}
        </HHTextField>
      )}

      <Grid container mt={1} spacing={2}>
        <Grid item xs={12} md={6}>
          <HHFormTextField
            control={control}
            error={firstNameError}
            required
            rules={{ required: T.CANNOT_BE_EMPTY }}
            helperText={firstNameHelperText}
            name="firstName"
            label={capitalize(T.FIRST_NAME)}
            fullWidth
            deprecatedLabel={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HHFormTextField control={control} name="lastName" label={capitalize(T.LAST_NAME)} fullWidth deprecatedLabel={false} />
        </Grid>
      </Grid>

      <Typography mt={3} variant="h6" fontWeight={500} color="textSecondary">
        {T.NEW_USERNAME}
      </Typography>

      <HHFormTextField
        sx={{ mt: 2 }}
        control={control}
        error={emailError}
        rules={{ required: T.CANNOT_BE_EMPTY, pattern: { value: REGEX.IS_EMAIL, message: T.MUST_BE_A_VALID_EMAIL_ADDRESS } }}
        helperText={emaillHelperText}
        required
        name="email"
        label="Email"
        fullWidth
        deprecatedLabel={false}
        onKeyDown={handleKeyDown}
      />

      <HHFormTextField
        sx={{ mt: 2 }}
        control={control}
        error={confirmEmailError}
        rules={{
          required: T.CANNOT_BE_EMPTY,
          validate: value => watch('email') === value || T.EMAILS_DID_NOT_MATCH,
        }}
        helperText={confirmEmaillHelperText}
        required
        name="confirmEmail"
        label="Confirm email"
        fullWidth
        deprecatedLabel={false}
        onKeyDown={handleKeyDown}
      />
    </>
  )
}

FormFields.propTypes = {
  isLoading: PropTypes.bool,
  emailContacts: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
}

export default FormFields
