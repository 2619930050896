import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemIcon, ListItemText, Skeleton, Box } from '@mui/material'

const RentalFeeListItemSkeleton = ({ showCheckBox = false, rows = 1 }) =>
  Children.toArray(
    new Array(rows).fill('').map(() => (
      <Box display="flex" alignItems="center">
        {showCheckBox && <Skeleton variant="rectangular" width="1rem" height="1rem" sx={{ mr: 2 }} />}
        <ListItem sx={{ px: 0 }}>
          <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
            <Skeleton variant="rectangular" width="1rem" height="1rem" />
          </ListItemIcon>
          <ListItemText primary={<Skeleton variant="text" width="5rem" />} secondary={<Skeleton variant="text" width="10rem" />} />
          <ListItemIcon>
            <Skeleton variant="rectangular" width="4rem" height="1rem" />
          </ListItemIcon>
        </ListItem>
      </Box>
    ))
  )

RentalFeeListItemSkeleton.propTypes = {
  rows: PropTypes.number,
}

export default RentalFeeListItemSkeleton
