import React from 'react'
import { GridHeaderCheckbox, useGridApiContext } from '@mui/x-data-grid-pro'

const RenderPermanentChangeCheckboxHeaderCell = props => {
  const api = useGridApiContext()
  console.log('header here')
  return <GridHeaderCheckbox api={api} {...props} />
}

RenderPermanentChangeCheckboxHeaderCell.propTypes = {}

export default RenderPermanentChangeCheckboxHeaderCell
