import React from 'react'
import PropTypes from 'prop-types'
import { Chip, SvgIcon } from '@mui/material'
import { red, grey } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar'
import { ReactComponent as TicketIcon } from 'assets/work-orders/TicketIcon.svg'
import HHDisplayMoney from '../../common/HHDisplayMoney'

const DisposalTicketChip = ({ hasDisposalTicket = false, amountCents, onClick, disabled = false }) => {
  return (
    <Chip
      size="small"
      variant={hasDisposalTicket ? 'filled' : 'outlined'}
      avatar={
        <Avatar sx={{ backgroundColor: 'background.black', opacity: 0.38 }}>
          <SvgIcon inheritViewBox viewBox="0 0 15 11" sx={{ height: '11px' }}>
            <TicketIcon />
          </SvgIcon>
        </Avatar>
      }
      disabled={disabled}
      label={hasDisposalTicket ? <HHDisplayMoney value={amountCents} formatToDollars /> : '+ disposal ticket'}
      sx={{
        backgroundColor: hasDisposalTicket ? red[100] : grey[100],
        '&:hover': {
          backgroundColor: hasDisposalTicket ? red[200] : grey[200],
        },
        borderStyle: hasDisposalTicket ? 'none' : 'dashed',
      }}
      onClick={onClick}
    />
  )
}

DisposalTicketChip.propTypes = {
  hasDisposalTicket: PropTypes.bool.isRequired,
  amountCents: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default DisposalTicketChip
