import React from 'react'
import PropTypes from 'prop-types'
import { Typography, TextField, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FooterButton from 'components/buttons/FooterButton'
import { CommonDrawer, CommonTextLabel } from 'components/common'
import T from 'T'
import { memo } from 'utils/react'

const SaveNewReportDrawer = ({
  isOpenDrawer = false,
  reportName = '',
  reportDesc = '',
  onClose,
  onHandleChange = () => {},
  onHandleSave = () => {},
}) => {
  return (
    <CommonDrawer isOpen={isOpenDrawer} onChange={(event, isOpen) => onClose(isOpen)}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottomColor="background.tags"
        px={3}
        py={1}
        sx={{
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
        }}
      >
        <Typography variant="h6" noWrap>
          {T.SAVE_REPORT}
        </Typography>

        <CloseIcon fontSize="small" sx={{ color: 'text.lightBrown', cursor: 'pointer' }} onClick={() => onClose(false)} />
      </Box>
      <Box px={3} py={1} mt={1} minHeight="calc(100vh - 155px)">
        <CommonTextLabel title={T.NAME} isRequired />
        <TextField
          id="outlined-size-normal"
          placeholder={T.NAME}
          variant="outlined"
          sx={{ mb: 2 }}
          fullWidth
          required
          defaultValue={reportName}
          onChange={event => onHandleChange('reportName', event.target.value)}
        />

        <CommonTextLabel title={T.DESCRIPTION} />
        <TextField
          id="outlined-size-normal"
          placeholder={T.DESCRIPTION}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          defaultValue={reportDesc}
          onChange={event => onHandleChange('reportDesc', event.target.value)}
        />
      </Box>

      <Box
        px={3}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderTop: 1, borderColor: 'border.light' }}
      >
        <FooterButton
          leftButtonTitle={T.CANCEL}
          rightButtonTitle={(() => {
            return T.SAVE
          })()}
          disabledProceed={reportName === ''}
          onClose={() => onClose(false)}
          onProceed={() => onHandleSave()}
        />
      </Box>
    </CommonDrawer>
  )
}

SaveNewReportDrawer.propTypes = {
  reportName: PropTypes.string,
  reportDesc: PropTypes.string,
  isOpenDrawer: PropTypes.bool,
  onHandleChange: PropTypes.func,
  onHandleSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default memo(SaveNewReportDrawer)
