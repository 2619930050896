import React from 'react'
import PropTypes from 'prop-types'

import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const UnpaidStatListItem = ({ label = '', value = 0, ...rest }) => (
  <ListItem {...rest}>
    <ListItemText
      primary={label}
      secondary={<HHDisplayMoney value={value} />}
      primaryTypographyProps={{ variant: 'h5', fontWeight: 400 }}
      secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
    />
  </ListItem>
)

UnpaidStatListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
}

export default UnpaidStatListItem
