import { createSelector } from 'reselect'
import { getAccountStatusIdsValues } from 'components/customers/groups/account/details/filters/account-status-filter/settings'
import { filter } from 'lodash'
import { get } from 'utils/lodash'
import { hasIntersection } from 'components/locations/settings'

export const getFilteredRowsForLateFeeDetails = createSelector(
  [
    state => state?.rows,
    state => state?.selectedTagsIds,
    state => state?.selectedBillingProfilesIds,
    state => state?.selectedAccountStatusIds,
    state => state?.selectedSuspensionTypesValues,
  ],
  (rows = [], selectedTagsIds = [], selectedBillingProfilesIds = [], selectedAccountStatusIds = []) => {
    const selectedTagIdsSet = new Set(selectedTagsIds)
    const selectedBillingProfilesIdsSet = new Set(selectedBillingProfilesIds)
    const selectedAccountStatusIdsValues = getAccountStatusIdsValues(selectedAccountStatusIds)
    return filter(rows, row => {
      const active = get(row, 'active')
      const billingProfileId = get(row, 'billingProfileId')
      const tagIds = get(row, 'tagIds', [])
      return (
        selectedAccountStatusIdsValues.includes(active) &&
        hasIntersection(selectedTagIdsSet, tagIds) &&
        (selectedBillingProfilesIdsSet.has(billingProfileId) || !billingProfileId)
      )
    })
  }
)
