import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import rootReducer from 'middleware/reducers'
import api from 'api'
import { rtkQueryErrorLogger } from 'api/errorLogger'
import LogRocket from 'logrocket'
import Constants from 'Constants'
import migrations from './migrations'
import { ignoreLargeCalls, ignoreFrustratingCalls, ignoreLargeState } from './settings'

const { LOGROCKET_ID } = Constants

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'AuthReducer',
    'BillingReducer',
    'Route',
    'RouteManagerReducer',
    'CustomerDetails',
    'locations',
    'persistentWorkOrdersReview',
    'accounts',
    'home',
    'LoginPersist',
    'pricingDrawer',
  ],
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV !== 'production' }),
  version: 3,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, rtkQueryErrorLogger, api.middleware]

if (LOGROCKET_ID) {
  middlewares.push(
    LogRocket.reduxMiddleware({
      actionSanitizer: action => {
        if ([...ignoreLargeCalls, ...ignoreFrustratingCalls].includes(action?.meta?.arg?.endpointName)) {
          return null
        }
        return action
      },
      stateSanitizer: state => {
        return {
          ...state,
          ...ignoreLargeState,
        }
      },
    })
  )
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: defaultMiddleware => [
    ...defaultMiddleware({
      serializableCheck: false,
    }),
    ...middlewares,
  ],
  devTools: process.env.NODE_ENV !== 'production',
})
const persistor = persistStore(store)

export { store, persistor, sagaMiddleware }
