/* eslint-disable no-param-reassign */
import { get, uniq } from 'lodash'
import { updateUnassignedMarkers } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/settings'
import { createBulkActionSlice } from 'slices/generic/createBulkActionSlice'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const bulkAssignStopsSlice = createBulkActionSlice({
  name: 'bulkAssignStops',
  reducers: {
    addSelectedMoveFromRows: (state, action) => {
      state.fitBounds = false
      state.selectedMoveFromRows = uniq([...state.selectedMoveFromRows, ...action.payload])
      state.moveFromRouteStopMarkers = updateUnassignedMarkers(state.selectedMoveFromRows, state.routesById, state.moveFromRouteStopMarkers)
    },
    setSelectedMoveFromRows: (state, action) => {
      state.selectedMoveFromRows = action.payload
      state.moveFromRouteStopMarkers = updateUnassignedMarkers(state.selectedMoveFromRows, state.routesById, state.moveFromRouteStopMarkers)
    },
  },
})
export const {
  setBulkState: setBulkAssignStopsState,
  setBulkDate: setBulkAssignStopsDate,
  addSelectedMoveToRows,
  addSelectedMoveFromRows,
  setSelectedMoveFromRows,
  setSelectedMoveToRows,
  setConfirmPermanentMoveDialogState,
} = bulkAssignStopsSlice.actions

export const selectBulkAssignFocusName = state => get(state, 'bulkAssignStops.focus.name')
export const selectBulkAssignFocusRowIndex = state => get(state, 'bulkAssignStops.focus.rowIndex', 0)
export const selectBulkAssignFromRouteStopMarkers = state => get(state, 'bulkAssignStops.moveFromRouteStopMarkers', {})
export const selectBulkAssignFromRows = state => get(state, 'bulkAssignStops.moveFromRows', [])
export const selectBulkAssignInvalidStopsToBeAssignedCount = state => get(state, 'bulkAssignStops.invalidStopsToBeAssignedCount', 0)
export const selectBulkAssignValidStopIdsToBeAssigned = state => get(state, 'bulkAssignStops.validStopIdsToBeAssigned', [])
export const selectBulkAssignMapLayer = state => get(state, 'bulkAssignStops.mapLayer', BOTH_ROUTES_LAYER)
export const selectBulkAssignStopsDate = state => get(state, 'bulkAssignStops.serializedServiceDate')
export const selectBulkAssignStopsToBeAssignedCount = state => get(state, 'bulkAssignStops.stopsToBeAssignedCount', 0)
export const selectBulkAssignToRouteSequenceLine = state => get(state, 'bulkAssignStops.moveToRouteSequenceLine', {})
export const selectBulkAssignToRouteStopMarkers = state => get(state, 'bulkAssignStops.moveToRouteStopMarkers', {})
export const selectBulkAssignToRows = state => get(state, 'bulkAssignStops.moveToRows', [])
export const selectBulkSelectedMoveFromRows = state => get(state, 'bulkAssignStops.selectedMoveFromRows', [])
export const selectBulkSelectedMoveToRows = state => get(state, 'bulkAssignStops.selectedMoveToRows', [])
export const selectIsOpenBulkAssignConfirmCloseDialog = state => get(state, 'bulkAssignStops.isConfirmCloseDialogOpen', false)
export const selectIsOpenBulkAssignStopsDialog = state => get(state, 'bulkAssignStops.isOpen', false)
export const selectBulkAssignRoutesById = state => get(state, 'bulkAssignStops.routesById', {})

export const selectIsSelectDateDialogOpen = state => get(state, 'bulkAssignStops.isSelectDateDialogOpen', false)
export const selectIsSequenceDialogOpen = state => get(state, 'bulkAssignStops.isSequenceDialogOpen', false)
export const selectBulkAssignFitBounds = state => get(state, 'bulkAssignStops.fitBounds', false)
export const selectToBeInsertedRowsIds = state => get(state, 'bulkAssignStops.toBeInsertedRowsIds', [])

export const selectBulkAssignFetchId = state => get(state, 'bulkAssignStops.fetchId')

export const selectIsConfirmPermanentMoveDialogOpen = state => get(state, 'bulkAssignStops.confirmPermanentMoveDialog.isOpen', false)

export default bulkAssignStopsSlice.reducer
