import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@mui/material'
import Highlighter from 'react-highlight-words'
import { get } from 'lodash'
import HighlightPrimaryTag from './HighlightPrimaryTag'

const SearchAddressItem = ({ registerChild, style, dataSet }) => {
  const [props, data, inputValue] = dataSet
  const streetName = get(data, 'text', '').trim()
  const cityName = get(data, 'city', '')
  const stateName = get(data, 'state', '')
  console.log(stateName)
  const areaName = cityName && stateName ? `${cityName}, ${stateName}` : cityName || stateName
  return (
    <MenuItem {...props} divider ref={registerChild} style={style}>
      <Highlighter
        highlightClassName="searched-text"
        searchWords={[inputValue]}
        autoEscape
        textToHighlight={streetName}
        highlightTag={HighlightPrimaryTag}
      />
      <Highlighter
        highlightClassName="searched-text"
        searchWords={[inputValue]}
        autoEscape
        textToHighlight={areaName}
        highlightTag={HighlightPrimaryTag}
      />
    </MenuItem>
  )
}

SearchAddressItem.propTypes = {
  registerChild: PropTypes.func,
  style: PropTypes.object,
  dataSet: PropTypes.object,
}

export default SearchAddressItem
