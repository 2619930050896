import React, { createContext, useState } from 'react'

export const LocationRowContext = createContext()

export const LocationRowProvider = ({ children }) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null)
  const [isOpenContactsDialog, setIsOpenContactsDialog] = useState(false)
  const [isOpenTagsDialog, setIsOpenTagsDialog] = useState(false)
  const [isOpenLocationNotesDialog, setIsOpenLocationNotesDialog] = useState(false)

  const openActions = Boolean(actionsAnchorEl)

  const handleActionsClick = event => {
    setActionsAnchorEl(event.currentTarget)
  }

  const handleActionsClose = () => {
    setActionsAnchorEl(null)
  }

  const openContactsDialog = () => {
    setIsOpenContactsDialog(true)
  }

  const closeContactsDialog = () => {
    setIsOpenContactsDialog(false)
  }

  const openTagsDialog = () => {
    setIsOpenTagsDialog(true)
  }

  const closeTagsDialog = () => {
    setIsOpenTagsDialog(false)
  }

  const openLocationNotesDialog = () => {
    setIsOpenLocationNotesDialog(true)
  }

  const closeLocationNotesDialog = () => {
    setIsOpenLocationNotesDialog(false)
  }

  return (
    <LocationRowContext.Provider
      value={{
        actionsAnchorEl,
        openActions,
        handleActionsClick,
        handleActionsClose,
        isOpenContactsDialog,
        closeContactsDialog,
        openContactsDialog,
        isOpenTagsDialog,
        openTagsDialog,
        closeTagsDialog,
        isOpenLocationNotesDialog,
        openLocationNotesDialog,
        closeLocationNotesDialog,
      }}
    >
      {children}
    </LocationRowContext.Provider>
  )
}
