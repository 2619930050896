import React from 'react'
import PropTypes from 'prop-types'

import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle'

import './style.scss'

const CommonNoOptionsMsg = ({ text }) => (
  <div className="no-option-block">
    <div className="no-option-image">
      <ExclamationCircle className="error-msg" />
    </div>
    <div className="no-option-text">
      {text && `There are no results for ${text}`}
      {!text && `There are no results`}
    </div>
  </div>
)

CommonNoOptionsMsg.defaultProps = {
  text: '',
}

CommonNoOptionsMsg.propTypes = {
  text: PropTypes.string,
}

export default CommonNoOptionsMsg
