import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'

import { toast } from 'react-toastify'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import { Box, Button, DialogContent, Grid, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import SearchIcon from '@mui/icons-material/Search'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { dataGridHeightFixes } from 'utils/datagrid'
import { putIsLoading } from 'middleware/actions/response'
import { getAccountsPaymentsForDataGrid } from 'data/groups/paymentGroupsSelectors'
import { useDeletePaymentBatchesFromGroupMutation } from 'api/groups/payment/deletePaymentBatchesFromGroup'
import { setAccountSelectionModel, setPaymentSelectionModel, resetSelectionModels } from 'slices/groups/RemovePaymentDialogSlice'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHTextField from 'components/form-fields/v5/HHTextField'
import AccountsDataGrid from './AccountsDataGrid'
import PaymentsDataGrid from './PaymentsDataGrid'

const RemovePaymentBatchesFromGroupDialog = ({ isOpen = false, paymentBatches = [], groupId, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const accountApiRef = useGridApiRef()
  const paymentApiRef = useGridApiRef()
  const [search, setSearch] = useState('')
  const accountSelectionModel = useSelector(state => get(state, 'RemovePaymentDialog.accountSelectionModel', []), shallowEqual)
  const paymentSelectionModel = useSelector(state => get(state, 'RemovePaymentDialog.paymentSelectionModel', []), shallowEqual)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [deletePaymentBatchesFromGroup] = useDeletePaymentBatchesFromGroupMutation()
  const { accountRows, paymentRows, totalAmountCents, hierarchyIds } = getAccountsPaymentsForDataGrid({ paymentBatches, search })

  const handleRemoveBatchesFromGroup = () => {
    dispatch(putIsLoading(true))
    deletePaymentBatchesFromGroup({ id: groupId, batchIds: paymentSelectionModel })
      .unwrap()
      .then(() => toast.success(T.REMOVE_PAYMENTS_FROM_GROUP_SUCCESS_MSG))
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  const handleSelectAll = () => {
    dispatch(setAccountSelectionModel(accountApiRef.current.getAllRowIds()))
    dispatch(setPaymentSelectionModel(hierarchyIds))
  }

  const handleDeselectAll = () => {
    dispatch(resetSelectionModels())
  }

  const handleSearchChange = event => {
    const { value } = event.target
    setSearch(value)
    handleDeselectAll()
  }

  const handleAccountSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, accountSelectionModel)
    const unselectedIds = difference(accountSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setAccountSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let batchIds = []
    currentIds.forEach(id => {
      const rowData = accountApiRef.current.getRow(id)
      batchIds = [...batchIds, ...rowData.batchIds]
    })

    const newPayments = isAdded ? [...new Set([...paymentSelectionModel, ...batchIds])] : difference(paymentSelectionModel, batchIds)
    dispatch(setPaymentSelectionModel(newPayments))
  }

  const handlePaymentSelectionChange = newSelectionModel => {
    const selectedIds = difference(newSelectionModel, paymentSelectionModel)
    const unselectedIds = difference(paymentSelectionModel, newSelectionModel)
    const isAdded = selectedIds.length > 0
    const currentIds = isAdded ? selectedIds : unselectedIds
    dispatch(setPaymentSelectionModel(newSelectionModel))

    if (!currentIds.length) {
      return
    }

    let accountIds = []
    currentIds.forEach(id => {
      const rowData = paymentApiRef.current.getRow(id)
      accountIds = [...accountIds, ...rowData.accountIds]
    })

    const newAccounts = isAdded ? [...new Set([...accountSelectionModel, ...accountIds])] : difference(accountSelectionModel, accountIds)
    dispatch(setAccountSelectionModel(newAccounts))
  }

  useEffect(() => {
    if (isOpen) {
      setSearch('')
      handleDeselectAll()
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={T.REMOVE_PAYMENT} onClose={onClose} />
      <DialogContent>
        <Grid mt={1} container spacing={1}>
          <Grid item xs={12} md={4} borderRight={isTabletOrMobile ? 0 : 1} borderColor="divider">
            <HHTextField
              sx={{ pr: 1, '& .MuiInputBase-root': { pr: 0 } }}
              fullWidth
              label="Search"
              placeholder="Search"
              deprecatedLabel={false}
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon sx={{ color: 'text.secondary' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />

            <Box height={isTabletOrMobile ? 250 : 'calc(100vh - 325px)'}>
              <AccountsDataGrid
                ref={accountApiRef}
                rows={accountRows}
                onSelectionModelChange={handleAccountSelectionChange}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box height={isTabletOrMobile ? 500 : '100%'} {...dataGridHeightFixes}>
              <PaymentsDataGrid
                ref={paymentApiRef}
                rows={paymentRows}
                hierarchyIds={hierarchyIds}
                totalAmountCents={totalAmountCents}
                onSelectionModelChange={handlePaymentSelectionChange}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectAll}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <Button
          disabled={!paymentSelectionModel.length}
          color="error"
          autoFocus
          size="small"
          variant="contained"
          onClick={handleRemoveBatchesFromGroup}
        >
          {T.REMOVE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RemovePaymentBatchesFromGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  paymentBatches: PropTypes.array,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RemovePaymentBatchesFromGroupDialog
