import T from 'T'

export const DISPOSAL_TOOLTIP_TITLE = 'Toggle Off when driver cannot collect tickets because disposal site does not provide them'

export const OPEN_CLOSE_TIME = [
  '12:00 AM',
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
]

export const DAYS = [
  { id: T.ALL, value: T.ALL },
  { id: T.SUNDAY, value: T.SUNDAY },
  { id: T.MONDAY, value: T.MONDAY },
  { id: T.TUESDAY, value: T.TUESDAY },
  { id: T.WEDNESDAY, value: T.WEDNESDAY },
  { id: T.THURSDAY, value: T.THURSDAY },
  { id: T.FRIDAY, value: T.FRIDAY },
  { id: T.SATURDAY, value: T.SATURDAY },
]

export const UNIT_OF_MEASURE = ['Item', 'Ton', 'Yard', 'Lbs']

export const END_MODIFY_DAY_COUNT = 7
export const START_MODIFY_DAY_COUNT = 0
