import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'

import { useGridApiRef } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import { DialogContent, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useRemoveAccountsFromGroupMutation } from 'api/groups/account/removeAccountsFromGroup'
import { getFilteredRowsForAddRemoveDialog } from 'data/groups/accountGroupsSelectors'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SearchField from './common/SearchField'
import Filters from './common/Filters'
import DataGrid from './common/DataGrid'
import { ADD_REMOVE_FORM_MODEL } from './common/settings'

const RemoveAccountsFromGroupDialog = ({ isOpen = false, groupId, existingAccounts = [], onClose }) => {
  const apiRef = useGridApiRef()
  const [getAccountMeta, { isSuccess: isAccountMetaSuccess }] = useLazyGetAccountMetaQuery()
  const [removeAccountsFromGroup, { isLoading: isRemoveFromGroupLoading }] = useRemoveAccountsFromGroupMutation()
  const removeFromGroupForm = useForm({ defaultValues: { ...ADD_REMOVE_FORM_MODEL } })
  const { watch, reset, handleSubmit } = removeFromGroupForm

  const customerMeta = useSelector(getAccountMetaData, shallowEqual)
  const selectionModel = watch('selectionModel')
  const search = watch('search')
  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])

  const filteredRows = useMemo(
    () => getFilteredRowsForAddRemoveDialog({ rows: existingAccounts, search, selectedTagsIds, selectedBillingProfilesIds }),
    [existingAccounts, search, selectedTagsIds, selectedBillingProfilesIds]
  )

  const handleRemoveAccountsFromGroup = data => {
    const accounts = data.selectionModel
    const payload = { accountGroupId: groupId, accounts }
    removeAccountsFromGroup(payload)
      .unwrap()
      .then(() => {
        toast.success(accounts.length > 1 ? T.REMOVE_ACCOUNTS_FROM_GROUP_SUCCESS_MSG : T.REMOVE_ACCOUNT_FROM_GROUP_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen && isAccountMetaSuccess) {
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      reset({ ...ADD_REMOVE_FORM_MODEL, selectedTags: allTags, selectedBillingProfiles: billingProfiles })
    }
  }, [isOpen, isAccountMetaSuccess])

  useEffect(() => {
    if (isOpen) {
      getAccountMeta()
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={T.REMOVE_ACCOUNTS_FROM_GROUP} onClose={onClose} />
      <DialogContent>
        <FormProvider {...removeFromGroupForm}>
          <Grid mt={1} container spacing={2} alignItems="center">
            <Grid item xs>
              <SearchField />
            </Grid>
            <Grid item xs="auto">
              <Filters />
            </Grid>
          </Grid>
          <DataGrid apiRef={apiRef} rows={existingAccounts} filteredRows={filteredRows} />
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton
          loading={isRemoveFromGroupLoading}
          disabled={selectionModel.length === 0}
          color="error"
          size="small"
          variant="contained"
          onClick={handleSubmit(handleRemoveAccountsFromGroup)}
        >
          {T.REMOVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RemoveAccountsFromGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default RemoveAccountsFromGroupDialog
