import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  open: false,
  agingBucketType: null,
  accountId: '',
  takePayment: {
    open: false,
    invoiceNumber: null,
  },
}

const agingBucketSlice = createSlice({
  name: 'agingBucket',
  initialState,
  reducers: {
    setOpenAgingBucket: (state, action) => {
      state.open = action.payload
    },
    setAgingBucketType: (state, action) => {
      state.agingBucketType = action.payload
    },
    setAgingBucketState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setTakePaymentState: (state, action) => {
      return {
        ...state,
        takePayment: {
          ...state.takePayment,
          ...action.payload,
        },
      }
    },
  },
})

export const { setOpenAgingBucket, setAgingBucketType, setTakePaymentState, setAgingBucketState } = agingBucketSlice.actions
export default agingBucketSlice.reducer

export const selectOpenAgingBucket = s => get(s, 'agingBucket.open', false)
export const selectAgingBucketType = s => get(s, 'agingBucket.agingBucketType')
export const selectAgingBucketAccountId = s => get(s, 'agingBucket.accountId')
export const selectIsTakePaymentDialogOpen = s => get(s, 'agingBucket.takePayment.open', false)
export const selectTakePaymentInvoiceNumber = s => get(s, 'agingBucket.takePayment.invoiceNumber')
