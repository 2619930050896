import React from 'react'
import {
  selectBulkMoveMapLayer,
  setPermanentRecurrenceChangeState,
  selectBulkMoveToSerializedDate,
  selectBulkMoveFromSerializedDate,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { deserializeDate, formatDateToBulkMoveStr, formatDateToFEFormatDateFns } from 'utils/date'
import CommonToggleRouteLayer from 'components/route-manager/BulkActions/common/CommonToggleRouteLayers'

const ToggleRouteLayers = () => {
  const mapLayer = useSelector(selectBulkMoveMapLayer)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const toDateTitleFormat = toDate ? formatDateToBulkMoveStr(toDate) : ''
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const fromDateTitleFormat = fromDate ? formatDateToBulkMoveStr(fromDate) : ''
  const showToggle = toDate && fromDate

  return (
    <>
      <CommonToggleRouteLayer
        mapLayer={mapLayer}
        toLayerLabel={toDateTitleFormat}
        fromLayerLabel={fromDateTitleFormat}
        showToggle={showToggle}
        fromLayer={fromFEDate}
        toLayer={toFEDate}
        bulkReduxAction={setPermanentRecurrenceChangeState}
        showAllButton={false}
      />
    </>
  )
}

ToggleRouteLayers.propTypes = {
  map: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default ToggleRouteLayers
