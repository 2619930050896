import { useBulkAddBillingNoteToAccountsMutation } from 'api/groups/account/bulkAddBillingNoteToAccounts'
import { useBulkAddAccountNoteToAccountsMutation } from 'api/groups/account/bulkAddAccountNoteToAccounts'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import { useMemo } from 'react'
import {
  useBulkAddAccountNoteToSuspensionGroupMutation,
  useBulkAddBillingNoteToSuspensionGroupMutation,
} from 'api/groups/account/suspendedAccountsCrud'
import {
  useBulkAddAccountNoteToAgingGroupMutation,
  useBulkAddBillingNoteToAgingGroupMutation,
} from 'api/groups/account/accountsByAgingBucketCrud'
import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'

export const useGetAccountBulkActionFromGroupId = accountGroupId => {
  const isDefaultBucket = useMemo(() => Object.values(BUCKET_NAME_TO_GROUP_ID_PARAM).includes(accountGroupId), [accountGroupId])

  const [useBulkAddBillingNote, useBulkAddAccountNote] = useMemo(() => {
    const isSuspendedGroup = getIsSuspendedBucket(accountGroupId)
    if (isSuspendedGroup) return [useBulkAddBillingNoteToSuspensionGroupMutation, useBulkAddAccountNoteToSuspensionGroupMutation]
    if (isDefaultBucket) return [useBulkAddBillingNoteToAgingGroupMutation, useBulkAddAccountNoteToAgingGroupMutation]
    return [useBulkAddBillingNoteToAccountsMutation, useBulkAddAccountNoteToAccountsMutation]
  }, [accountGroupId])
  return {
    useBulkAddBillingNote,
    useBulkAddAccountNote,
  }
}
