import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import capitalize from 'lodash/capitalize'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { Menu, MenuItem, ListItemText, Tooltip } from '@mui/material'

import { handleError } from 'utils/error'
import { setArchivedRestoredRentalFee } from 'slices/pricing/rentalFeesSlice'
import { useArchiveRentalFeeMutation, useRestoreRentalFeeMutation, useDeleteRentalFeeMutation } from 'api/pricing/rentalFeesCrud'

import T from 'T'
import CreateEditRentalFeeDialog from 'components/pricing/RentalFees/CreateEditRentalFeeDialog'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import AddPricedServicesDialog from '../PricedServices/AddRemovePricedServices/AddPricedServicesDialog'
import RemovePricedServicesDialog from '../PricedServices/AddRemovePricedServices/RemovePricedServicesDialog'

const RentalFeesActionMenu = ({ anchorEl = null, onClose = noop, rentalFee = {} }) => {
  const dispatch = useDispatch()
  const open = Boolean(anchorEl)
  const [isUpdateRentalFeeOpen, setIsUpdateRentalFeeOpen] = useState(false)
  const [isOpenAddPricedServiceDialog, setIsOpenAddPricedServiceDialog] = useState(false)
  const [isOpenRemovePricedServiceDialog, setIsOpenRemovePricedServiceDialog] = useState(false)
  const [isOpenConfirmArchiveRentalFee, setIsOpenConfirmArchiveRentalFee] = useState(false)
  const [isOpenConfirmDeleteRentalFee, setIsOpenConfirmDeleteRentalFee] = useState(false)
  const [archiveRentalFee, { isLoading: isArchiveRentalFeeLoading }] = useArchiveRentalFeeMutation()
  const [restoreRentalFee] = useRestoreRentalFeeMutation()
  const [deleteRentalFee, { isLoading: isDeleteRentalFeeLoading }] = useDeleteRentalFeeMutation()

  const { archived, id: rentalFeeId } = rentalFee

  const handleEdit = () => {
    setIsUpdateRentalFeeOpen(true)
    onClose()
  }

  const handleCloseUpdateRentalFeeDialog = () => {
    setIsUpdateRentalFeeOpen(false)
  }

  const handleOpenAddPricedServiceDialog = () => {
    setIsOpenAddPricedServiceDialog(true)
    onClose()
  }

  const handleCloseAddPricedServiceDialog = () => {
    setIsOpenAddPricedServiceDialog(false)
  }

  const handleOpenRemovePricedServiceDialog = () => {
    setIsOpenRemovePricedServiceDialog(true)
    onClose()
  }

  const handleCloseRemovePricedServiceDialog = () => {
    setIsOpenRemovePricedServiceDialog(false)
  }

  const handleCloseConfirmArchiveRentalFeeDialog = () => {
    setIsOpenConfirmArchiveRentalFee(false)
  }

  const handleArchiveRestorePosition = () => {
    setTimeout(() => dispatch(setArchivedRestoredRentalFee(rentalFee)), 300)
  }

  const handleArchiveRestoreRentalFee = () => {
    if (archived) {
      restoreRentalFee({ id: rentalFeeId })
        .unwrap()
        .then(() => {
          toast.success(T.RENTAL_FEE_RESTORED_SUCCESSFULLY)
          handleArchiveRestorePosition()
        })
        .catch(handleError)
    } else {
      setIsOpenConfirmArchiveRentalFee(true)
    }
    onClose()
  }

  const handleConfirmArchiveRentalFee = () => {
    archiveRentalFee({ id: rentalFeeId })
      .unwrap()
      .then(() => {
        toast.success(T.RENTAL_FEE_ARCHIVED_SUCCESSFULLY)
        handleCloseConfirmArchiveRentalFeeDialog()
        handleArchiveRestorePosition()
      })
      .catch(handleError)
  }

  const handleCloseConfirmDeleteRentalFeeDialog = () => {
    setIsOpenConfirmDeleteRentalFee(false)
  }

  const handleOpenConfirmDeleteRentalFeeDialog = () => {
    setIsOpenConfirmDeleteRentalFee(true)
    onClose()
  }

  const handleConfirmDeleteRentalFee = () => {
    deleteRentalFee({ id: rentalFeeId })
      .unwrap()
      .then(() => {
        toast.success(T.RENTAL_FEE_DELETED_SUCCESSFULLY)
        handleCloseConfirmDeleteRentalFeeDialog()
      })
      .catch(handleError)
  }

  return (
    <>
      <Menu
        slotProps={{
          paper: {
            sx: { border: '1px solid', borderColor: 'divider' },
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenAddPricedServiceDialog}>
          <ListItemText>{capitalize(T.ADD_SERVICES)}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenRemovePricedServiceDialog}>
          <ListItemText>{capitalize(T.REMOVE_SERVICES)}</ListItemText>
        </MenuItem>
        <Tooltip
          placement="left"
          arrow
          title={T.RENTAL_FEE_ARCHIVE_ASSOCIATION_MSG}
          // Add disabled condition
          disableHoverListener
          disableInteractive
        >
          <span>
            {/* Add disabled condition */}
            <MenuItem disabled={false} onClick={handleArchiveRestoreRentalFee}>
              <ListItemText sx={{ color: archived ? 'success.main' : 'warning.main' }}>{archived ? 'Restore' : T.ARCHIVE}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          placement="left"
          arrow
          title={T.RENTAL_FEE_DELETE_ASSOCIATION_MSG}
          // Add disabled condition
          disableHoverListener
          disableInteractive
        >
          <span>
            {/* Add disabled condition */}
            <MenuItem disabled={false} onClick={handleOpenConfirmDeleteRentalFeeDialog}>
              <ListItemText sx={{ color: 'error.main' }}>{T.DELETE}</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      {isUpdateRentalFeeOpen && (
        <CreateEditRentalFeeDialog isOpen={isUpdateRentalFeeOpen} rentalFee={rentalFee} onClose={handleCloseUpdateRentalFeeDialog} />
      )}

      <AddPricedServicesDialog
        isOpen={isOpenAddPricedServiceDialog}
        rentalFeeId={rentalFeeId}
        onClose={handleCloseAddPricedServiceDialog}
      />
      <RemovePricedServicesDialog
        isOpen={isOpenRemovePricedServiceDialog}
        rentalFeeId={rentalFeeId}
        onClose={handleCloseRemovePricedServiceDialog}
      />

      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        isOpen={isOpenConfirmArchiveRentalFee}
        onClose={handleCloseConfirmArchiveRentalFeeDialog}
        confirmTitle={T.ARCHIVE_RENTAL_FEE}
        confirmDescription={T.ARCHIVE_RENTAL_FEE_CONFIRM_MSG}
        onConfirm={handleConfirmArchiveRentalFee}
        confirmButtonTitle={T.ARCHIVE}
        ConfirmButtonProps={{
          color: 'warning',
          loading: isArchiveRentalFeeLoading,
          disabled: isArchiveRentalFeeLoading,
          loadingPosition: 'center',
        }}
      />

      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        isOpen={isOpenConfirmDeleteRentalFee}
        onClose={handleCloseConfirmDeleteRentalFeeDialog}
        confirmTitle={T.DELETE_RENTAL_FEE}
        confirmDescription={T.DELETE_RENTAL_FEE_CONFIRM_MSG}
        onConfirm={handleConfirmDeleteRentalFee}
        confirmButtonTitle={T.DELETE}
        ConfirmButtonProps={{
          loading: isDeleteRentalFeeLoading,
          disabled: isDeleteRentalFeeLoading,
          loadingPosition: 'center',
        }}
      />
    </>
  )
}

RentalFeesActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Element)]),
  onClose: PropTypes.func,
  rentalFee: PropTypes.object,
}

export default RentalFeesActionMenu
