import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box, LinearProgress, darken, lighten, useTheme } from '@mui/material'
import { orange, purple, red } from '@mui/material/colors'
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { HHAlert } from 'components/common/HHAlert'
import { getAccountRowClassName, ACCOUNTS_DESKTOP_ROW_HEIGHT } from 'components/customers/accounts/settings'

import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import CustomAccountRow from 'components/customers/accounts/CustomAccountRow'
import ConfirmAccountReactivatedDialog from 'components/customers/accounts/ConfirmAccountReactivatedDialog'
import { closeConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'
import { useDispatch } from 'react-redux'
import RenderHeader from './RenderHeader'
import RenderAccountGroupCell from './RenderAccountGroupCell'
import ExpansionContent from './ExpansionContent'

const DataGrid = ({ apiRef, isLoading = false, rows = [], filteredRows = [], slots, ...rest }) => {
  const theme = useTheme()
  const { watch, setValue } = useFormContext()
  const expandedRowIds = watch('expandedRowIds')
  const selectionModel = watch('selectionModel')
  const dispatch = useDispatch()
  const getRowId = useCallback(({ accountId }) => accountId, [])

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getDetaiilPanelContent = useCallback(({ row }) => <ExpansionContent row={row} />, [])

  const getRowHeight = useCallback(() => ACCOUNTS_DESKTOP_ROW_HEIGHT, [])

  const getRowClassName = useCallback(getAccountRowClassName, [])

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  const handleSelectionModelChange = useCallback(newSelectionModel => {
    setValue('selectionModel', newSelectionModel)
  }, [])

  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => {
    setValue('expandedRowIds', newIds)
  }, [])

  useEffect(() => {
    dispatch(closeConfirmAccountReactivatedDialog())
  }, [])

  return (
    <Box mt={2} height="calc(100vh - 375px)">
      <DataGridPro
        loading={isLoading}
        apiRef={apiRef}
        hideFooter
        checkboxSelection
        rows={filteredRows}
        getRowId={getRowId}
        rowHeight={ACCOUNTS_DESKTOP_ROW_HEIGHT}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelectionModelChange}
        keepNonExistentRowsSelected
        getRowHeight={getRowHeight}
        getDetailPanelContent={getDetaiilPanelContent}
        getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
        detailPanelExpandedRowIds={expandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        columnVisibilityModel={{
          [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        }}
        columns={[
          {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            resizable: false,
            sortable: false,
            filterable: false,
          },
          {
            field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
            renderCell: () => <div />,
          },
          {
            field: 'id',
            flex: 1,
            resizable: false,
            sortable: false,
            filterable: false,
            headerClassName: 'data-column-header',
            renderHeader: () => <RenderHeader rows={rows} selectedRows={selectionModel} />,
            renderCell: RenderAccountGroupCell,
          },
        ]}
        getRowClassName={getRowClassName}
        slots={{
          baseCheckbox: params => <DataGridBaseCheckbox {...params} rows={filteredRows} />,
          noRowsOverlay: () => (
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              No result found
            </HHAlert>
          ),
          detailPanelExpandIcon: ChevronRightIcon,
          detailPanelCollapseIcon: ExpandMoreIcon,
          loadingOverlay: LinearProgress,
          row: CustomAccountRow,
          ...slots,
        }}
        disableColumnMenu
        sx={{
          border: 'none',
          '& .MuiDataGrid-columnHeaderCheckbox': {
            ml: 1,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .data-column-header .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
            background: 'transparent',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '& .MuiDataGrid-row, & .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover, & .MuiDataGrid-row:focus-within ': {
            '&.hold': {
              background: purple[50],
              '&:hover': {
                backgroundColor: getHoverBackgroundColor(purple[50], theme.palette.mode),
              },
            },
            '&.suspended': {
              background: red[50],
              '&:hover': {
                backgroundColor: getHoverBackgroundColor(red[50], theme.palette.mode),
              },
            },
            '&.deactivated': {
              background: orange[50],
              '&:hover': {
                backgroundColor: getHoverBackgroundColor(orange[50], theme.palette.mode),
              },
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'hidden',
          },
        }}
        {...rest}
      />
      <ConfirmAccountReactivatedDialog />
    </Box>
  )
}

DataGrid.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  filteredRows: PropTypes.array,
  slots: PropTypes.object,
}

export default DataGrid
