import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemText,
  styled,
  useTheme,
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { get } from 'lodash'
import { combineAddressInfo } from 'utils/helper'
import ServiceItem from './ServiceItem/ServiceItem'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
}))
const LocationItem = ({ location, serviceList, showLastDivider = false }) => {
  const theme = useTheme()
  const address = get(location, 'address')
  const addressStr = combineAddressInfo(address)
  const serviceCount = get(serviceList, `length`, 0)
  const hasServices = serviceCount !== 0
  const [expanded, setExpanded] = React.useState(hasServices)

  const handleChange = (event, isExpanded) => {
    if (serviceCount === 0) return
    setExpanded(isExpanded)
  }

  useEffect(() => {
    setExpanded(hasServices)
  }, [hasServices])

  return (
    <Accordion
      sx={{
        '&:last-of-type': {
          ...(showLastDivider === false && { borderBottom: 0 }),
        },
      }}
      expanded={expanded}
      defaultExpanded={hasServices}
      onChange={handleChange}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        direction="row-reverse"
        expandIcon={
          <ChevronRightIcon sx={{ fontSize: '1.5rem', color: theme.palette.action, visibility: hasServices ? 'visible' : 'hidden' }} />
        }
      >
        <ListItem disableGutters sx={{ ml: 1 }}>
          <ListItemText primary={addressStr} secondary={`${serviceCount} services`} />
        </ListItem>
      </AccordionSummary>
      <Divider flexItem />
      <AccordionDetails sx={{ py: 2 }}>
        <List disablePadding>
          {serviceList.map(service => {
            return <ServiceItem service={service} />
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

LocationItem.propTypes = {
  location: PropTypes.object.isRequired,
  serviceList: PropTypes.array.isRequired,
  showLastDivider: PropTypes.bool,
}

export default LocationItem
