export const actions = {
  REPORT_ADD_EDIT_FILTER_VIEW: 'REPORT_ADD_EDIT_SEARCH_FILTER',
  REPORT_DELETE_FILTER_VIEW: 'REPORT_DELETE_SEARCH_FILTER_VIEW',
  SAVE_REPORT: 'SAVE_NEW_REPORT',
  GET: {
    REPORT_TABLE_CONTENT: 'REPORT_GET_TABLE_CONTENT',
    REPORT_SEARCH_FILTER_VIEW_LIST: 'REPORT_GET_SEARCH_FILTER_VIEW_LIST',
    NEW_REPORT_LIST: 'NEW_REPORT_GET_LIST',
  },
}

export const getReportTableContent = (body, onSuccess) => ({
  type: actions.GET.REPORT_TABLE_CONTENT,
  payload: { body, onSuccess },
})

export const getReportSearchFilterViewList = (body, onSuccess) => ({
  type: actions.GET.REPORT_SEARCH_FILTER_VIEW_LIST,
  payload: { body, onSuccess },
})

export const reportAddEditFilterView = (body, onSuccess) => ({
  type: actions.REPORT_ADD_EDIT_FILTER_VIEW,
  payload: { body, onSuccess },
})

export const reportDeleteFilterView = (body, onSuccess) => ({
  type: actions.REPORT_DELETE_FILTER_VIEW,
  payload: { body, onSuccess },
})

export const saveReport = (body, onSuccess) => ({
  type: actions.SAVE_REPORT,
  payload: { body, onSuccess },
})

export const getNewReportList = (body, onSuccess) => ({
  type: actions.GET.NEW_REPORT_LIST,
  payload: { body, onSuccess },
})
