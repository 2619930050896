import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { DialogContent, Grid, Box, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetLocationsQuery } from 'api/location/getLocations'
import { useLazyGetRentalFeesByConfiguredServiceQuery } from 'api/configured-service/configuredServiceRentalFeesCrud'
import { useResetGracePeriodMutation } from 'api/pricing/resetGracePeriod'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import AccountsDataGrid from './AccountsDataGrid'
import ServicesDataGrid from '../LocationWise/ServicesDataGrid'
import RentalFeeDataGrid from '../LocationWise/RentalFeeDataGrid'
import { DATA_GRID_DESKTOP_HEIGHT, DATA_GRID_PHONE_HEIGHT } from '../common/settings'
import { getActiveServices } from '../common/utils'

const AccountsResetGracePeriodDialog = ({ isOpen = false, existingAccounts, onClose }) => {
  const theme = useTheme()
  const [accountSelectionModel, setAccountSelectionModel] = useState([])
  const [serviceSelectionModel, setServiceSelectionModel] = useState([])
  const [rentalFeeSelectionModel, setRentalFeeSelectionModel] = useState([])

  const [getLocations, { isFetching: isGetLocationsLoading, data: allLocations }] = useLazyGetLocationsQuery()
  const [getRentalFeesByConfiguredService, { isFetching: isGetRentalFeesByConfiguredServiceLoading, data: configuredRentalFeesData }] =
    useLazyGetRentalFeesByConfiguredServiceQuery()
  const [resetGracePeriod, { isLoading: isResetGracePeriodLoading }] = useResetGracePeriodMutation()

  const allServices = useMemo(() => getActiveServices(allLocations), [allLocations])
  const configuredRentalFees = useMemo(() => get(configuredRentalFeesData, 'configuredRentalFees', []), [configuredRentalFeesData])
  const selectedAccountId = useMemo(() => get(accountSelectionModel, '0', ''), [accountSelectionModel])
  const selectedServiceId = useMemo(() => get(serviceSelectionModel, '0', ''), [serviceSelectionModel])

  const handleResetState = () => {
    setAccountSelectionModel([])
    setServiceSelectionModel([])
    setRentalFeeSelectionModel([])
  }

  const handleClose = () => {
    handleResetState()
    onClose()
  }

  const handleAccountSelectionModelChange = newSelection => {
    setAccountSelectionModel(newSelection)
  }

  const handleServiceSelectionModelChange = newSelection => {
    setServiceSelectionModel(newSelection)
  }

  const handleRentalFeeSelectionModelChange = newSelection => {
    setRentalFeeSelectionModel(newSelection)
  }

  const handleReset = () => {
    resetGracePeriod({ rentalFeeToConfiguredServiceIds: rentalFeeSelectionModel })
      .unwrap()
      .then(() => {
        toast.success(T.RESET_GRACE_PERIOD_SUCCESS_MSG)
        handleRentalFeeSelectionModelChange([])
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleResetState()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && selectedAccountId) {
      getLocations({ searchText: selectedAccountId, searchType: 'ACCOUNT_ID', includeDeactivated: false, includeServices: true })
        .unwrap()
        .then(response => {
          const services = getActiveServices(response)
          const firstServiceId = get(services, '0.id', '')
          if (firstServiceId) {
            handleServiceSelectionModelChange([firstServiceId])
          }
        })
        .catch(handleError)
    }
  }, [isOpen, selectedAccountId])

  useEffect(() => {
    if (isOpen && selectedServiceId) {
      getRentalFeesByConfiguredService({ id: selectedServiceId }).unwrap().catch(handleError)
    }
  }, [isOpen, selectedServiceId])

  return (
    <HHBaseDialog fullWidth maxWidth="xl" open={isOpen} onClose={handleClose}>
      <HHDialogTitle title={T.RESET_GRACE_PERIOD} onClose={handleClose} />
      <DialogContent>
        <Grid mt={1} container>
          <Grid item xs={12} lg={4} sx={{ borderRight: { lg: `1px solid ${theme.palette.divider}` } }}>
            <Box sx={{ height: { xs: DATA_GRID_PHONE_HEIGHT, lg: DATA_GRID_DESKTOP_HEIGHT } }}>
              <AccountsDataGrid
                rows={existingAccounts}
                selectionModel={accountSelectionModel}
                onSelectionModelChange={handleAccountSelectionModelChange}
              />
            </Box>
          </Grid>

          {!selectedAccountId && (
            <Grid item xs sx={{ mt: { xs: 2, lg: 0 }, ml: { xs: 0, lg: 1 } }}>
              <HHAlert borderColor={theme.palette.info.light} severity="info">
                Select an account to start
              </HHAlert>
            </Grid>
          )}

          {selectedAccountId && (
            <>
              <Grid item xs={12} lg={4} sx={{ mt: { xs: 2, lg: 0 }, borderRight: { lg: `1px solid ${theme.palette.divider}` } }}>
                <Box sx={{ height: { xs: DATA_GRID_PHONE_HEIGHT, lg: DATA_GRID_DESKTOP_HEIGHT } }}>
                  <ServicesDataGrid
                    isLoading={isGetLocationsLoading}
                    rows={allServices}
                    selectionModel={serviceSelectionModel}
                    onSelectionModelChange={handleServiceSelectionModelChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={4} sx={{ mt: { xs: 2, lg: 0 } }}>
                <Box sx={{ height: { xs: DATA_GRID_PHONE_HEIGHT, lg: DATA_GRID_DESKTOP_HEIGHT } }}>
                  <RentalFeeDataGrid
                    isLoading={isGetLocationsLoading || isGetRentalFeesByConfiguredServiceLoading}
                    selectedServiceId={selectedServiceId}
                    rows={configuredRentalFees}
                    selectionModel={rentalFeeSelectionModel}
                    onSelectionModelChange={handleRentalFeeSelectionModelChange}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={handleClose} />
        <SaveButton
          loadingPosition="center"
          loading={isResetGracePeriodLoading}
          disabled={rentalFeeSelectionModel.length === 0 || isResetGracePeriodLoading}
          label={T.RESET}
          onClick={handleReset}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AccountsResetGracePeriodDialog.propTypes = {
  isOpen: PropTypes.bool,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func,
}

export default AccountsResetGracePeriodDialog
