import React, { useCallback, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { Box, useTheme } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { get } from 'utils/lodash'
import { HHAlert } from 'components/common/HHAlert'
import RenderAccountHeader from '../../common/data-grid/RenderAccountHeader'
import RenderAccountCell from '../../common/data-grid/RenderAccountCell'

const AccountsDataGrid = forwardRef((props, ref) => {
  const { rows = [], onSelectionModelChange, onSelectAll, onDeselectAll, ...rest } = props
  const theme = useTheme()
  const selectionModel = useSelector(state => get(state, 'RemovePaymentDialog.accountSelectionModel', []), shallowEqual)
  const totalRows = rows.length

  const getRowHeight = useCallback(() => 'auto', [])

  return (
    <DataGridPro
      apiRef={ref}
      columnVisibilityModel={{ __check__: false }}
      rows={rows}
      checkboxSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      columnHeaderHeight={totalRows > 0 ? 48 : 0}
      hideFooter
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: RenderAccountCell,
          renderHeader: () => (
            <RenderAccountHeader rows={rows} selectedRows={selectionModel} onSelectAll={onSelectAll} onDeselectAll={onDeselectAll} />
          ),
          sortable: false,
          resizable: false,
        },
      ]}
      slots={{
        noRowsOverlay: () => (
          <Box sx={{ mt: 2, pr: 1 }}>
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              No accounts
            </HHAlert>
          </Box>
        ),
      }}
      disableColumnSelector
      getRowId={({ accountId }) => accountId}
      getRowHeight={getRowHeight}
      disableColumnMenu
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnSeparator': {
          border: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
          display: 'block',
        },
        '& .MuiDataGrid-columnHeader': {
          padding: '0',
        },
      }}
      {...rest}
    />
  )
})

AccountsDataGrid.propTypes = {
  rows: PropTypes.array,
  onSelectionModelChange: PropTypes.string.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.string.isRequired,
}

export default AccountsDataGrid
