import React from 'react'
import PropTypes from 'prop-types'
import { ListItemAvatar, ListItem, Checkbox, useTheme } from '@mui/material'

const WithRouteCardCheckbox = ({ enable = false, children, checked, onChange }) => {
  const theme = useTheme()

  if (!enable) return children
  return (
    <ListItem disableGutters>
      <ListItemAvatar sx={{ minWidth: 32 }}>
        <Checkbox color="primary" sx={{ color: theme.palette.primary.main }} checked={checked} onChange={onChange} />
      </ListItemAvatar>
      {children}
    </ListItem>
  )
}

WithRouteCardCheckbox.propTypes = {
  enable: PropTypes.bool,
  children: PropTypes.node,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default WithRouteCardCheckbox
