import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { get } from 'lodash'
import VirtualizedAutocomplete from './VirtualizedAutocomplete'
import SearchItem from './SearchItem'
import ListboxComponent from './ListboxComponent'

function renderRow(props) {
  const { data, index, style } = props
  const dataSet = data[index]

  return <SearchItem dataSet={dataSet} index={index} style={style} />
}

const ListboxComponentWrapper = forwardRef((props, ref) => {
  return <ListboxComponent maxTotalHeight={900} rowHeight={52} {...props} ref={ref} renderRow={renderRow} />
})

const Search = ({ sx, onInputChange, onReset, onChange, defaultOptions, filteredOptions }) => {
  const [value, setValue] = React.useState()
  const [inputValue, setInputValue] = useState('')
  const options = inputValue ? filteredOptions : defaultOptions
  const hasNoOptions = Array.isArray(options) && options.length === 0
  const onInputChangeDebounced = useMemo(() => debounce(onInputChange, 300), [])

  const handleInputChange = (e, inputValue, reason) => {
    setInputValue(inputValue)
    onInputChangeDebounced(inputValue, defaultOptions)
    if (reason === 'clear') {
      onReset(true)
    }
  }

  const handleChange = (event, newValue) => {
    if (!newValue) return
    onChange(newValue)
    setValue(newValue)
  }

  useEffect(() => {
    return () => {
      onInputChangeDebounced.cancel()
    }
  }, [])

  return (
    <VirtualizedAutocomplete
      freeSolo={!hasNoOptions}
      sx={sx}
      value={value}
      ListboxComponent={ListboxComponentWrapper}
      onChange={handleChange}
      options={options}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={({ accountName, routeName }) => [accountName, routeName].join(' | ')}
    />
  )
}

Search.propTypes = {
  sx: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOptions: PropTypes.array,
  filteredOptions: PropTypes.array,
}

export default Search
