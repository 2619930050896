import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Divider } from '@mui/material'
import { memo } from 'utils/react'
import { get } from 'lodash'

import { HHAlert } from 'components/common/HHAlert'
import AlertTitle from '@mui/material/AlertTitle'
import { getMaxContactMethods } from 'components/locations/LocationsDataGrid/ViewLocationContacts/settings'
import ContactMethodSkeleton from 'components/locations/LocationsDataGrid/ViewLocationContacts/ContactMethodSkeleton'
import ContactMethodItem from 'components/locations/LocationsDataGrid/ViewLocationContacts/ContactMethodItem'

const ViewLocationContacts = ({ contacts = [], showAction = false, fullWidth = false, loading = false, sx, contactMethodSx }) => {
  const maxContactMethodsCount = useMemo(() => getMaxContactMethods(contacts), [contacts])

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        ...sx,
      }}
    >
      {loading ? (
        <Box display="grid" gridTemplateColumns={fullWidth ? '1fr' : `repeat(${1}, 1fr)`} gap={1}>
          <ContactMethodSkeleton showAction={showAction} />
        </Box>
      ) : (
        contacts.map((contact, index) => {
          const id = get(contact, 'id')
          const firstName = get(contact, 'firstName')
          const lastName = get(contact, 'lastName')
          const fullName = [firstName, lastName].filter(Boolean).join(' ')
          const contactMethods = get(contact, 'contactMethods', [])
          const contactMethodsCount = get(contactMethods, 'length', 0)

          const hasContactMethods = contactMethodsCount > 0

          return (
            <Fragment key={`contact-block-${id}`}>
              <Typography whiteSpace="nowrap" variant="h5" fontWeight={500}>
                {fullName}
              </Typography>
              <Box
                display={fullWidth ? 'block' : 'grid'}
                gridTemplateColumns={fullWidth ? '1fr' : `repeat(${contactMethodsCount}, 1fr)`}
                gap={1}
              >
                {hasContactMethods &&
                  contactMethods.map((method, subIndex) => {
                    const id = get(method, 'id')
                    const methodType = get(method, 'methodType')
                    const isPlaceholder = methodType === 'placeholder'

                    return (
                      <Box
                        key={`method-${id}`}
                        sx={{
                          borderRight: isPlaceholder || fullWidth || (subIndex + 1) % contactMethodsCount === 0 ? 0 : 1,
                          borderColor: 'divider',
                          width: '100%',
                        }}
                      >
                        {!isPlaceholder ? (
                          <ContactMethodItem sx={contactMethodSx} contact={contact} contactMethod={method} showAction={showAction} />
                        ) : (
                          <Box width="100%" />
                        )}
                      </Box>
                    )
                  })}
                {!hasContactMethods && (
                  <HHAlert sx={{ gridColumn: fullWidth ? '1 / -1' : `span ${maxContactMethodsCount}`, mt: 1 }} severity="info">
                    <AlertTitle>
                      <Typography variant="inherit" noWrap component="p" fontWeight={400}>
                        No contact methods
                      </Typography>
                    </AlertTitle>
                  </HHAlert>
                )}
              </Box>
              {index < contacts.length - 1 && <Divider sx={{ mt: 1, mb: 2 }} />}
            </Fragment>
          )
        })
      )}
    </Box>
  )
}

ViewLocationContacts.propTypes = {
  contacts: PropTypes.array,
  showAction: PropTypes.bool,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  contactMethodSx: PropTypes.object,
  sx: PropTypes.object,
}

export default memo(ViewLocationContacts)
