import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'

import Table from 'components/pricing/Services/Table'
import NewServiceModal from 'components/pricing/Services/NewServiceModal'
import SecondaryNavBar from 'components/pricing/SecondaryNavBar'
import LinkServiceModal from 'components/pricing/LinkedService/LinkServiceModal'
import Loader from 'components/common/loader'

import { PRICING_PAGINATION } from 'settings/constants/pagination'

import { useLazyGetServiceLinkListQuery } from 'api/pricing/getServiceLinkList'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'

import { canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual } from 'react-redux'

import 'containers/pricing/style.scss'
import { Box } from '@mui/material'
import PricingWrapper from 'components/pricing/common/drawer/PricingWrapper'
import { getPricingServiceName } from './pricingServiceName'
import EditServiceModal from './edit-service/EditServiceModal'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION
const link = {}

const ServicesPanel = ({
  allCheckedVal,
  allFilters,
  allSortBy,
  anchorEl,
  changePrice,
  checkedVal,
  columns,
  confServices,
  configData,
  feeTab,
  getPricingServicesResults,
  handleActivation,
  handleAllChecked,
  handleChangePriceDelete,
  handleClose,
  handleConfChange,
  handleExport = () => {},
  handleOnDragEnd,
  handlePageChange,
  handleRowsPerPageChange,
  handleSingleChange,
  handleSingleChecked,
  handleTabChange,
  isDirty,
  lockedColumns,
  monthlyRevenue,
  onHandleEditFieldsChange,
  onSortByChange,
  onTableFilterChange,
  onUpdatePricingService,
  page,
  pricingServiceList,
  rowsPerPage,
  saveConfSettings,
  saveConfigSettings,
  selectedEditValues,
  setAnchorEl,
  setSelectedEditValues,
  setState,
  summary,
  tabValue,
  totalPageCount,
  totalServiceCount,
}) => {
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)
  const [openServiceDrawer, setOpenServiceDrawer] = useState(false)
  const [openLinkServiceDrawer, setOpenLinkServiceDrawer] = useState(false)
  const [servicesScreen, setServicesScreen] = useState('initial')
  const [selectedService, setSelectedService] = useState(null)
  const [serviceName, setServiceName] = useState('')
  const [getServiceLinkList, { isFetching }] = useLazyGetServiceLinkListQuery()
  const [isOpenEditServiceModal, setIsOpenEditServiceModal] = useState(false)
  const [serviceState, setServiceState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    id: '',
    services: [],
    tax: [],
    generalFee: [],
    disposalFee: [],
    linkAll: {},
    linkVal: {},
  })

  const toggleServiceDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setServicesScreen('initial')
    setOpenServiceDrawer(open)
  }

  const handleChangePrice = () => {
    const checkedIds = Object.entries(get(checkedVal, 'services', [])).map(([key, value]) => (value ? key : null))

    setSelectedEditValues(compact(checkedIds).map(data => (data = pricingServiceList.find(service => service.id === data))))
    setServicesScreen('edit-services')
    setOpenServiceDrawer(true)
  }

  const toggleLinkServiceDrawer = (open, service) => {
    let servName = get(service, 'serviceName', '')
    const businessLine = get(service, 'businessLine', '')
    const zoneName = get(service, 'pricingZone.zoneName', '')

    setOpenLinkServiceDrawer(open)

    if (servName === '' && businessLine !== '') {
      servName += `${businessLine}`
    }

    if (servName !== '' && businessLine !== '') {
      servName += ` - ${businessLine}`
    }

    if (businessLine === '' && zoneName !== '') {
      servName += `${zoneName}`
    }

    if (businessLine !== '' && zoneName !== '') {
      servName += ` - ${zoneName}`
    }

    setServiceName(servName)

    const payload = {
      id: service.id,
    }

    getServiceLinkList(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        const taxes = get(res, 'tax', [])
        const generalFeeAndCharges = get(res, 'generalFeeAndCharges', [])
        const disposalFeeAndCharges = get(res, 'disposalFeeAndCharges', [])

        taxes.map(data => {
          data.linkedVal = data.linked
        })

        generalFeeAndCharges.map(data => {
          data.linkedVal = data.linked
        })

        disposalFeeAndCharges.map(data => {
          data.linkedVal = data.linked
        })

        setServiceState({
          id: service.id,
          services: get(res, 'services', []),
          tax: taxes,
          generalFee: generalFeeAndCharges,
          disposalFee: disposalFeeAndCharges,
        })
      })
      .catch(handleError)
    setAnchorEl(null)
  }

  const handleSwitchVal = (val, id, type) => {
    if (!updatePricingManager) return

    if (id === 'all' && serviceState[type].length > 0) {
      serviceState[type].map(data => {
        data.linkedVal = val
      })

      setServiceState(serviceState)
      if (serviceState[type].filter(data => data.linkedVal).length === serviceState[type].length) {
        link[type] = true
        setServiceState({ linkAll: link })
      } else {
        link[type] = false
        setServiceState({ linkAll: link })
      }
      setState({ isDirty: true })
      return
    }

    const serviceRecord = serviceState[type].find(data => data.id === id)

    if (serviceRecord !== undefined) {
      serviceRecord.linkedVal = val
    }

    setServiceState({ serviceState })
    if (serviceState[type].filter(data => data.linkedVal).length !== serviceState[type].length) {
      link[type] = false
    }
    setState({ isDirty: true })
  }

  const handleCloseEditServiceModal = () => {
    setIsOpenEditServiceModal(false)
  }

  const handleEditClick = (e, service) => {
    setIsOpenEditServiceModal(true)
    setSelectedService(service)
  }

  return (
    <>
      {isFetching && <Loader />}
      <PricingWrapper className="panel-description service-panel-container">
        <SecondaryNavBar
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          summary={summary}
          feeTab={feeTab}
          handleConfChange={handleConfChange}
          saveConfigSettings={saveConfigSettings}
          handleOnDragEnd={handleOnDragEnd}
          configData={configData}
          handleExport={handleExport}
          handleSingleChange={handleSingleChange}
          changePrice={changePrice}
          toggleDrawer={toggleServiceDrawer}
          tab="services"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleClose={handleClose}
          handleChangePrice={handleChangePrice}
        />

        <Box className="panel-table">
          <Table
            serviceList={pricingServiceList}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            totalServiceCount={totalServiceCount}
            totalPageCount={totalPageCount}
            confServices={confServices}
            allCheckedVal={allCheckedVal}
            columns={columns}
            lockedColumns={lockedColumns}
            saveConfSettings={saveConfSettings}
            getPricingServiceName={getPricingServiceName}
            handleAllChecked={handleAllChecked}
            handleSingleChecked={handleSingleChecked}
            checkedVal={checkedVal}
            type="services"
            toggleLinkServiceDrawer={toggleLinkServiceDrawer}
            handleActivation={handleActivation}
            allFilters={allFilters}
            allSortBy={allSortBy}
            onTableFilterChange={onTableFilterChange}
            onSortByChange={onSortByChange}
            onEditClick={handleEditClick}
            onUpdatePricingService={onUpdatePricingService}
          />
        </Box>
      </PricingWrapper>

      <NewServiceModal
        serviceList={pricingServiceList}
        checkedVal={checkedVal}
        page={page}
        rowsPerPage={rowsPerPage}
        isDirty={isDirty}
        monthlyRevenue={monthlyRevenue}
        handleChangePriceDelete={handleChangePriceDelete}
        onHandleEditFieldsChange={onHandleEditFieldsChange}
        selectedEditValues={selectedEditValues}
        setSelectedEditValues={setSelectedEditValues}
        openDrawer={openServiceDrawer}
        setOpenDrawer={setOpenServiceDrawer}
        servicesScreen={servicesScreen}
        setServicesScreen={setServicesScreen}
        getPricingServicesResults={getPricingServicesResults}
        confServices={columns}
        getPricingServiceName={getPricingServiceName}
        setState={setState}
      />
      <EditServiceModal
        service={selectedService}
        open={isOpenEditServiceModal}
        onClose={handleCloseEditServiceModal}
        onUpdate={onUpdatePricingService}
      />
      <LinkServiceModal
        tab="service"
        page={page}
        isDirty={isDirty}
        rowsPerPage={rowsPerPage}
        serviceName={serviceName}
        setState={setState}
        serviceList={serviceState}
        getResults={getPricingServicesResults}
        openLinkServiceDrawer={openLinkServiceDrawer}
        setOpenLinkServiceDrawer={setOpenLinkServiceDrawer}
        toggleLinkServiceDrawer={toggleLinkServiceDrawer}
        handleSwitchVal={handleSwitchVal}
      />
    </>
  )
}

ServicesPanel.defaultProps = {
  allCheckedVal: {},
  allFilters: {},
  allSortBy: {},
  anchorEl: '',
  changePrice: {},
  checkedVal: {},
  columns: [],
  confServices: [],
  configData: [],
  getPricingServicesResults: null,
  handleActivation: null,
  handleAllChecked: null,
  handleChangePriceDelete: null,
  handleClose: null,
  handleConfChange: null,
  handleOnDragEnd: null,
  handlePageChange: null,
  handleRowsPerPageChange: null,
  handleSingleChange: null,
  handleSingleChecked: null,
  isDirty: false,
  lockedColumns: [],
  monthlyRevenue: null,
  onHandleEditFieldsChange: null,
  page: INITIAL_PAGE,
  pricingServiceList: [],
  rowsPerPage: ROWS_PER_PAGE,
  saveConfSettings: false,
  saveConfigSettings: null,
  selectedEditValues: [],
  setAnchorEl: null,
  setSelectedEditValues: null,
  setState: null,
  totalPageCount: INITIAL_PAGE,
  totalServiceCount: INITIAL_PAGE,
}

ServicesPanel.propTypes = {
  allCheckedVal: PropTypes.object,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  anchorEl: PropTypes.any,
  changePrice: PropTypes.object,
  checkedVal: PropTypes.object,
  columns: PropTypes.array,
  confServices: PropTypes.array,
  configData: PropTypes.array,
  getPricingServicesResults: PropTypes.func,
  handleActivation: PropTypes.func,
  handleAllChecked: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  handleClose: PropTypes.func,
  handleConfChange: PropTypes.func,
  handleExport: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  handleSingleChange: PropTypes.func,
  handleSingleChecked: PropTypes.func,
  isDirty: PropTypes.bool,
  lockedColumns: PropTypes.array,
  monthlyRevenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onHandleEditFieldsChange: PropTypes.func,
  onSortByChange: PropTypes.func.isRequired,
  onTableFilterChange: PropTypes.func.isRequired,
  onUpdatePricingService: PropTypes.func.isRequired,
  page: PropTypes.number,
  pricingServiceList: PropTypes.array,
  rowsPerPage: PropTypes.number,
  saveConfSettings: PropTypes.bool,
  saveConfigSettings: PropTypes.func,
  selectedEditValues: PropTypes.array,
  setAnchorEl: PropTypes.func,
  setSelectedEditValues: PropTypes.func,
  setState: PropTypes.func,
  totalPageCount: PropTypes.number,
  totalServiceCount: PropTypes.number,
}

export default memo(ServicesPanel)
