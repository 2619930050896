import React, { Children } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'

const InvoiceSendFailureConfirmation = ({ isOpen = false, invoices = [], onClose }) => {
  return (
    <HHBaseDialog open={isOpen} onClose={onClose}>
      <HHDialogTitle title="Failed to send" onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          {invoices.length === 1 && (
            <Typography variant="h5" color="textPrimary" fontWeight={400}>
              {`Invoice `}
              <strong>{invoices[0]}</strong>
              {` failed to send because the PDF file could not be found. Please regenerate the invoice and try again.`}
            </Typography>
          )}

          {invoices.length > 1 && (
            <>
              <Typography variant="h5" color="textPrimary" fontWeight={400} mb={1}>
                The following invoices failed to send because the PDF file could not be found. Please regenerate these invoices and try
                again.
              </Typography>
              {Children.toArray(
                invoices.map(invoice => (
                  <Typography variant="h5" color="textPrimary">
                    {invoice}
                  </Typography>
                ))
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" color="primary" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

InvoiceSendFailureConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  invoices: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default InvoiceSendFailureConfirmation
