import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import HHSwitchIconField from './HHSwitchIconField'

const HHFormSwitchIconField = ({ name, rules, control, type, label, labelPlacement, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const { onBlur, onChange, ..._rest } = field
        return (
          <HHSwitchIconField
            type={type}
            label={label}
            labelPlacement={labelPlacement}
            isChecked={field?.value || false}
            onChange={value => {
              onChange(value)
              onBlur()
            }}
            {..._rest}
            {...rest}
          />
        )
      }}
    />
  )
}

HHFormSwitchIconField.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.object,
}

export default HHFormSwitchIconField
