import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, Grid } from '@mui/material'
import { NumericFormat } from 'react-number-format'

const BillingSummaryItem = ({ label, value, hasRevenueProp = false }) => {
  const billingProps = {
    prefix: hasRevenueProp ? '$' : '',
    decimalSeparator: hasRevenueProp ? '.' : '',
    decimalScale: hasRevenueProp ? 2 : 0,
    fixedDecimalScale: hasRevenueProp,
  }

  return (
    <Grid container item xs="auto" sx={{ px: 2, py: 1 }} columnSpacing={2}>
      <Grid item xs="auto">
        <Typography variant="body1" color="text.secondary" borderLeft="none" noWrap>
          {label}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <Tooltip title={<NumericFormat value={value} thousandSeparator type="text" displayType="text" {...billingProps} />}>
          <Typography noWrap variant="h5" display="block">
            <NumericFormat pl={0} value={value} thousandSeparator type="text" displayType="text" {...billingProps} />
            {value !== 0 && !value && '-'}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

BillingSummaryItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  hasRevenueProp: PropTypes.bool,
}

export default BillingSummaryItem
