import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton as MuiIconButton, Card, CardHeader, CardContent, Grid, Typography, MenuItem, Menu } from '@mui/material'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { toast } from 'react-toastify'
import { Photo } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { useDispatch } from 'react-redux'
import ExceptionChip from 'components/exceptions/ExceptionChip'
import ExceptionImage from 'components/work-order/details/exceptions/ExceptionImage'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { useDeleteExceptionMutation } from 'api/work-order/deleteException'
import { CACHE_TAG_WORK_ORDER_DETAILS } from 'api/cacheTagTypes'
import api from 'api'
import ReadMoreTypography from 'components/work-order/details/exceptions/ReadMoreTypography'
import ExceptionAvatarIcon from 'components/work-order/details/exceptions/ExceptionAvatarIcon'
import ExceptionCardMediaSwiperContainer from 'components/work-order/details/exceptions/ExceptionCardMediaSwiperContainer'
import EditExceptionModal from 'components/work-order/details/exceptions/EditExceptionModal'

const ExceptionCard = ({
  accountId,
  workOrderId,
  exception,
  exceptionType,
  date,
  time,
  signatures,
  images,
  note,
  description,
  onClick = noop,
  PageProps,
  ImageSx,
}) => {
  const dispatch = useDispatch()
  const id = get(exception, 'id')
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [deleteException, { isLoading: isDeleteLoading }] = useDeleteExceptionMutation()

  const handleOpenActionsMenu = event => {
    event.stopPropagation()
    setIsActionsMenuOpen(event.target)
  }

  const handleCloseActionsMenu = event => {
    event.stopPropagation()
    setIsActionsMenuOpen(false)
  }

  const handleOpenDeleteModal = event => {
    event.stopPropagation()
    setIsActionsMenuOpen(false)
    setIsDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = event => {
    event.stopPropagation()
    setIsActionsMenuOpen(false)
    setIsDeleteModalOpen(false)
  }

  const handleEditModalOpen = e => {
    e.stopPropagation()
    setIsActionsMenuOpen(false)
    setIsEditModalOpen(true)
  }

  const handleEditModalClose = e => {
    e?.stopPropagation()
    setIsEditModalOpen(false)
  }

  const handleConfirmDeleteModal = event => {
    event.stopPropagation()
    deleteException({ id })
      .unwrap()
      .then(() => {
        toast.success('Exception deleted successfully')
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS]))

        setIsDeleteModalOpen(false)
      })
  }

  const handleCardClick = event => {
    event.stopPropagation()
    if (images.length === 0) return
    onClick(exception)
  }
  return (
    <>
      <Card onClick={handleCardClick} sx={{ m: 1, height: '482px' }}>
        <CardHeader
          avatar={<ExceptionAvatarIcon exceptionType={exceptionType} />}
          action={
            <MuiIconButton onClick={handleOpenActionsMenu}>
              <MoreVertIcon />
            </MuiIconButton>
          }
          titleTypographyProps={{
            variant: 'h5',
            fontWeight: 'normal',
          }}
          title={exceptionType}
        />
        <ExceptionCardMediaSwiperContainer>
          {Array.isArray(images) && images.length === 0 && (
            <Box bgcolor={grey[200]} height="199px" width="100%" display="flex" alignItems="center" justifyContent="center">
              <Photo sx={{ fontSize: 40, color: grey[600] }} />
            </Box>
          )}
          {Array.isArray(images) &&
            images.length > 0 &&
            images.map(image => {
              return (
                <ExceptionImage
                  key={image?.name}
                  exception={exception}
                  photo={image}
                  signature={get(signatures, `index`)}
                  accountId={accountId}
                  workOrderId={workOrderId}
                  withSwiperSlide
                  size="small"
                  PageProps={PageProps}
                  ImageSx={ImageSx}
                />
              )
            })}
        </ExceptionCardMediaSwiperContainer>
        <CardContent sx={{ pt: 0.5 }}>
          <Grid container rowSpacing={1}>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {date}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  {time}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <ExceptionChip size="small" exception={exceptionType} />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Description
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReadMoreTypography variant="h6" fontWeight="normal" text={description || '-'} title="Description" />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Note
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReadMoreTypography title="Note" variant="h6" fontWeight="normal" text={note || '-'} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Menu
          getContentAnchorEl={null}
          anchorEl={isActionsMenuOpen}
          open={Boolean(isActionsMenuOpen)}
          onClose={handleCloseActionsMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={handleEditModalOpen}>Edit note</MenuItem>
          <MenuItem onClick={handleOpenDeleteModal}>
            <Typography variant="h6" color="error" fontWeight="normal">
              Delete exception
            </Typography>
          </MenuItem>
        </Menu>
      </Card>
      <HHConfirmDialog
        isOpen={isDeleteModalOpen}
        confirmTitle="Confirm exception deletion"
        confirmDescription={
          <Typography component="p">
            Deleting the exception will permanently delete all related photos and data. Are you sure you want to continue?
          </Typography>
        }
        loading={isDeleteLoading}
        onConfirm={handleConfirmDeleteModal}
        onClose={handleCloseDeleteModal}
      />
      <EditExceptionModal
        onClose={handleEditModalClose}
        open={isEditModalOpen}
        accountId={accountId}
        workOrderId={workOrderId}
        exception={exception}
        date={date}
        time={time}
        signatures={signatures}
        images={images}
        note={note}
        description={description}
      />
    </>
  )
}

ExceptionCard.propTypes = {
  onClick: PropTypes.func,
  exception: PropTypes.object.isRequired,
  exceptionType: PropTypes.string.isRequired,
  signatures: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  workOrderId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  PageProps: PropTypes.object,
  ImageSx: PropTypes.object,
}

export default ExceptionCard
