import React, { Children } from 'react'

import moment from 'moment'

import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { memo } from 'utils/react'

import { Grid } from '@mui/material'
import routes from 'router/routes'

import { DEFAULT_REPORTS } from 'settings/constants/reports'
import CommonCard from './CommonCard'

const CardSection = ({ reports = [] }) => {
  const history = useHistory()

  const handleClick = (reportParentType, reportName, reportId) => {
    history.push({
      pathname: `${routes.app.reports}/${reportName.replaceAll(/\s/g, '')}`,
      state: { reportType: reportParentType, reportName, reportId },
    })
  }
  const handleType = reportType => {
    const type = DEFAULT_REPORTS.filter(item => item.reportTypeFilter === reportType)
    return type[0].reportParentType
  }

  return (
    <Grid container spacing={2}>
      {Array.isArray(reports) &&
        Children.toArray(
          reports.map(report => {
            return (
              <Grid item sm={3} xs={6}>
                <CommonCard
                  title={report.name}
                  date={moment(new Date()).format('MM/DD/YYYY')}
                  description={report.description}
                  onHandleClick={() => handleClick(handleType(report.type), report.name, report.id)}
                />
              </Grid>
            )
          })
        )}
    </Grid>
  )
}

CardSection.propTypes = {
  reports: PropTypes.array,
}
export default memo(CardSection)
