import React, { useEffect, useMemo } from 'react'
import { Box, Card, CardHeader, Grid, Tab, Tabs } from '@mui/material'
import { useGridApiContext, useGridSelector, gridRowCountSelector } from '@mui/x-data-grid-pro'
import T from 'T'
import FiltersMenuButton from 'components/locations/filters/FiltersMenuButton'
import { LATE_FEE_ALL_TAB_ID, LATE_FEE_LATE_TAB_ID } from 'components/pricing/LateFees/LateFeesListing/settings'
import { selectLateFeeTab, setLateFeeTab } from 'slices/pricing/lateFeesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom-v5-compat'
import { useLazyFetchLateFeeDetailsQuery } from 'api/pricing/lateFeesCrud'
import Summary from './Summary'

const TopSection = () => {
  const { id } = useParams()
  const apiRef = useGridApiContext()
  const [fetchLateFEeDetailsQuery, { isLoading, isFetching, data }] = useLazyFetchLateFeeDetailsQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const { accountCount } = data || {}
  const rowCount = useGridSelector(apiRef, gridRowCountSelector) - 1
  const currentLateFeeTab = useSelector(selectLateFeeTab)
  const dispatch = useDispatch()
  const handleChangeSelectedLateFeeTab = (event, newLateFeeTab) => {
    dispatch(setLateFeeTab(newLateFeeTab))
  }

  useEffect(() => {
    if (id) {
      fetchLateFEeDetailsQuery({
        id,
      })
    }
  }, [id])

  return (
    <Box px={3} pt={3} width="100%" pb={0}>
      <Grid container>
        <Grid item xs={12}>
          <Summary accountCount={accountCount} loading={loading} />
        </Grid>
        <Grid item>
          <Box
            sx={{
              mt: 4,
              mb: 2,
            }}
          >
            <Tabs
              value={currentLateFeeTab}
              onChange={handleChangeSelectedLateFeeTab}
              sx={{
                button: { textTransform: 'none' },
                '.MuiTab-root': {
                  minWidth: 160,
                },
              }}
            >
              <Tab value={LATE_FEE_ALL_TAB_ID} label={T.ALL} />
              <Tab value={LATE_FEE_LATE_TAB_ID} label={T.LATE} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <CardHeader
              title={`${T.ACCOUNTS} (${rowCount})`}
              sx={{ bgcolor: 'background.header' }}
              action={<FiltersMenuButton showSuspensionTypeFilter={false} />}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
export default TopSection
