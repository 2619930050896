import React, { Children } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import uniq from 'lodash/uniq'

import { useSelector, shallowEqual } from 'react-redux'

import { Typography, Popover, MenuItem, Checkbox, FormControlLabel, InputAdornment, Stack } from '@mui/material'

import { Check } from '@styled-icons/heroicons-outline/Check'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'
import { Calendar as CalendarPicker, DateRangePicker } from 'react-date-range'

import { getMetadataInvoice } from 'data/billing/billingTableSelectors'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { INITIAL_MINUTE, LAST_MINUTE } from 'settings/constants/time'
import { SELECT_DOWNWARD_DIRECTION, SELECT_UPWARD_DIRECTION } from 'settings/constants/select'
import { createDateFromString, formatDateToFEFormatDateFns, formatDateToBEFormatDateFns } from 'utils/date'
import { isValidPrice, isNumberOnly } from 'utils/validations'
import {
  TEXT_FILTERS,
  NUMBER_FILTERS,
  DATE_FILTERS,
  META_FILTERS,
  NULL_FILTERS,
  CHECKBOX_OPTIONS,
  FILTER_TYPES,
  FILTER_SUB_TYPES,
} from 'settings/constants/filters'

import T from 'T'
import Constants from 'Constants'
import FooterButton from 'components/buttons/FooterButton'

import Box from '@mui/material/Box'
import CommonTextfield from './CommonTextfield'
import CommonSelect from './CommonSelect'
import { formatRecurrenceString } from '../../utils/service'

const { MEDIA_SERVER } = Constants
const { NUMBER } = FILTER_TYPES
const { PRICE, MONEY } = FILTER_SUB_TYPES

const DROPDOWN_PROPS = {
  PaperProps: {
    style: {
      maxHeight: 280,
      width: 320,
      paddingRight: 12,
    },
  },
}
const SELECT_FILTER = 'Select Filter'

const CommonTableFilters = ({
  isOpen = false,
  filterType = 'number',
  subType = '',
  operation = '',
  columnName = '',
  columnValue = '',
  label = '',
  isSingleSelect = false,
  onFilterChange,
  onApply,
  onClose,
}) => {
  const { pricingFiltersMeta } = useSelector(
    state => ({
      pricingFiltersMeta: get(state, 'PricingFiltersMetaSlice.records', null),
    }),
    shallowEqual
  )

  const invoiceMeta = useSelector(getMetadataInvoice)

  const isSingleDate = ['DATE_BEFORE', 'DATE_AFTER', 'DATE_IS_BETWEEN'].includes(operation)
  const isDateRange = operation === 'DATE_RANGE_IS_BETWEEN'
  const isCalendarOperation = isSingleDate || isDateRange
  const dropdownDirection = isSingleSelect ? SELECT_DOWNWARD_DIRECTION : SELECT_UPWARD_DIRECTION
  const isRecurrenceFilter = subType === 'feeRecurrence' || columnName === 'recurrenceText'
  const dropdownOptions = (() => {
    if (filterType === 'string') {
      // This is temporary until we fix tags from the backend
      return columnName === 'tags' ? TEXT_FILTERS.slice(0, 2) : TEXT_FILTERS
    }

    if (filterType === 'date') {
      if (['sentDate', 'postDate'].includes(columnName)) {
        return DATE_FILTERS.concat(NULL_FILTERS(label))
      }
      return DATE_FILTERS
    }

    if (filterType === 'meta') {
      return META_FILTERS
    }

    return NUMBER_FILTERS
  })()

  const getMetaData = (() => {
    if (columnName === 'billingCycleText') {
      return get(invoiceMeta, 'billingCycles', []).map(cycle => cycle.name)
    }

    if (columnName === 'paymentTermsText') {
      return get(invoiceMeta, 'paymentTerms', []).map(term => term.name)
    }

    if (['customerProfileName', 'customerBillingProfile'].includes(columnName)) {
      return get(invoiceMeta, 'billingProfiles', [])
        .map(profile => profile.name)
        .filter(Boolean)
    }

    if (subType === 'feeRecurrence') {
      return get(pricingFiltersMeta, 'feeRecurrence', []).map(term => term.name)
    }

    if (['recurrenceText', 'measureText', 'periodText'].includes(columnName)) {
      return get(pricingFiltersMeta, columnName.replace('Text', ''), []).map(term => term.name)
    }

    return []
  })()

  const getCheckboxOptions = CHECKBOX_OPTIONS(columnName, label, subType)

  const getDateValue = (value = columnValue) => {
    const onlyDate = get(value.split(' '), '[0]')
    return Date.parse(onlyDate) ? createDateFromString(onlyDate) : ''
  }

  const getDateTimeValue = (defaultValue = false) => {
    const splitDate = columnValue.split('#')
    // If start & end date both present
    if (splitDate.length === 2) {
      return { startDate: getDateValue(splitDate[0]), endDate: getDateValue(splitDate[1]) }
    }

    return { startDate: defaultValue ? new Date() : '', endDate: defaultValue ? new Date() : '' }
  }

  const getDatePlaceholderValue = () => {
    if (isDateRange) {
      const { startDate, endDate } = getDateTimeValue()
      return startDate && endDate && `${formatDateToFEFormatDateFns(startDate)} - ${formatDateToFEFormatDateFns(endDate)}`
    }
    return formatDateToFEFormatDateFns(getDateValue())
  }

  const renderDropdownValue = selectedValue => {
    try {
      if (isSingleSelect) {
        if (isRecurrenceFilter) return formatRecurrenceString(selectedValue || SELECT_FILTER)
        return selectedValue || SELECT_FILTER
      }

      const value = selectedValue && selectedValue.join(', ')
      return value || SELECT_FILTER
    } catch {
      return isSingleSelect ? '' : []
    }
  }

  const onMetaChangeValue = event => {
    const { value } = event.target
    const formattedValue = Array.isArray(value) ? value : [value]
    onFilterChange('columnValue', formattedValue, columnName)
  }

  const getMetaValue = () => {
    if (isSingleSelect) {
      return Array.isArray(columnValue) ? get(columnValue, '0') : columnValue
    }
    return columnValue || []
  }

  const metaValue = getMetaValue()

  return (
    <Popover
      open={Boolean(isOpen)}
      anchorEl={isOpen}
      onClose={onClose}
      className={`common table-filters-popover ${filterType} ${isCalendarOperation ? 'calendar' : ''}`}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {['string', 'number', 'meta', 'date'].includes(filterType) && (
        <CommonSelect
          className={`${filterType} filter-select`}
          value={operation}
          onChange={event => onFilterChange('operation', event.target.value, columnName)}
        >
          <MenuItem value="">
            <span>Select Filter</span>
            <Box display="none" className="tick-icon">
              <Check className="icon-w-16" />
            </Box>
          </MenuItem>
          {Children.toArray(
            dropdownOptions.map(filter => (
              <MenuItem value={filter.key}>
                <span>{filter.value}</span>
                <Box display="none" className="tick-icon">
                  <Check className="icon-w-16" />
                </Box>
              </MenuItem>
            ))
          )}
        </CommonSelect>
      )}

      {['string', 'number'].includes(filterType) && (
        <Box mt={0.5}>
          <CommonTextfield
            placeholder="Enter Value"
            value={columnValue}
            // Add only string or number validation
            onChange={event => {
              const { value } = event.target
              if (value === '') {
                onFilterChange('columnValue', event.target.value, columnName)
              }

              if (filterType === NUMBER && [PRICE, MONEY].includes(subType) && isValidPrice(value)) {
                onFilterChange('columnValue', event.target.value, columnName)
                return
              }

              if (filterType === 'number' && subType !== 'price' && isNumberOnly(value)) {
                onFilterChange('columnValue', event.target.value, columnName)
                return
              }

              if (filterType === 'string') {
                onFilterChange('columnValue', event.target.value, columnName)
              }
            }}
          />
        </Box>
      )}

      {['meta'].includes(filterType) && (
        <CommonSelect
          sx={{ mt: 0.5 }}
          className="filter-select"
          multiple={!isSingleSelect}
          value={metaValue}
          renderValue={renderDropdownValue}
          onChange={onMetaChangeValue}
          menuProps={{
            ...DROPDOWN_PROPS,
            getContentAnchorEl: null,
            ...dropdownDirection,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          {isSingleSelect && (
            <MenuItem value="">
              <Typography>{SELECT_FILTER}</Typography>
              <Box display="none" className="tick-icon">
                <Check className="icon-w-16" />
              </Box>
            </MenuItem>
          )}
          {Children.toArray(
            // Earlier we were using {key: 'id', value: 'name'} but now we need to only send name, so get unique values because names can be duplicate
            uniq(getMetaData).map(filter => {
              let formattedFilter = filter
              if (isRecurrenceFilter) {
                formattedFilter = formatRecurrenceString(filter)
              }
              return (
                <MenuItem value={filter}>
                  <span>{formattedFilter}</span>
                  <Box display="none" className="tick-icon">
                    <Check className="icon-w-16" />
                  </Box>
                </MenuItem>
              )
            })
          )}
        </CommonSelect>
      )}

      {filterType === 'date' && (isSingleDate || isDateRange) && (
        <div>
          <Box my={1} cursor="pointer">
            <CommonTextfield
              disabled
              placeholder="Please Select"
              value={getDatePlaceholderValue()}
              startAdornment={
                <InputAdornment position="start">
                  <Calendar className="cal-icon cursor-pointer icon-w-16" />
                </InputAdornment>
              }
            />
          </Box>

          {isSingleDate && (
            <Stack className="payment-filter">
              <CalendarPicker
                date={getDateValue() || new Date()}
                showDateDisplay={false}
                onChange={inDate => {
                  onFilterChange('columnValue', moment(inDate).format(subType === 'date' ? 'yyyy-MM-DD' : 'yyyy-MM-DD HH:mm'), columnName)
                }}
              />
            </Stack>
          )}

          {isDateRange && (
            <Stack className="payment-filter">
              <DateRangePicker
                moveRangeOnFirstSelection={false}
                months={1}
                showDateDisplay={false}
                showMonthAndYearPickers
                ranges={[{ ...getDateTimeValue(true), key: 'range' }]}
                direction="vertical"
                staticRanges={[]}
                inputRanges={[]}
                onChange={event => {
                  const { startDate, endDate } = event.range
                  const startDateFormat = formatDateToBEFormatDateFns(startDate) + (subType === 'date' ? '' : ` ${INITIAL_MINUTE}`)
                  const endDateFormat = formatDateToBEFormatDateFns(endDate) + (subType === 'date' ? '' : ` ${LAST_MINUTE}`)
                  onFilterChange('columnValue', `${startDateFormat}#${endDateFormat}`, columnName)
                }}
              />
            </Stack>
          )}
        </div>
      )}

      {/* Change this to handle dynamic columns, currently its just status */}
      {filterType === 'checkbox' && (
        <Stack flexDirection="column" className="common checkbox">
          {Children.toArray(
            getCheckboxOptions.map(option => {
              return (
                <Stack flexDirection="row" sx={{ ml: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} />}
                        checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedFull.svg`} />}
                        color="primary"
                        name={get(option, 'value')}
                        checked={columnValue.includes(get(option, 'value'))}
                        onChange={e => {
                          // We can also split columnValue to make it array and push/pop element
                          // multi select
                          let newMethods = getCheckboxOptions
                            .map(data => data.value)
                            .filter(pStatus => {
                              if (pStatus !== e.target.name) {
                                return columnValue.includes(pStatus) ? pStatus : ''
                              }
                              return e.target.checked ? pStatus : ''
                            })

                          // single select
                          if (isSingleSelect) {
                            newMethods = newMethods.filter(data => data === e.target.name)
                          }

                          onFilterChange('columnValue', newMethods, columnName)
                        }}
                      />
                    }
                    label={get(option, 'label')}
                  />
                </Stack>
              )
            })
          )}
        </Stack>
      )}
      {/* Footer buttons */}
      <Stack sx={{ mt: 3 }} flexDirection="row" alignItems="center" justifyContent="space-between">
        <FooterButton
          leftButtonTitle={T.CANCEL}
          onClose={onClose}
          rightButtonTitle={T.APPLY}
          onProceed={() => onApply()}
          disabledProceed={!columnValue && (isSingleDate || isDateRange)}
        />
      </Stack>
    </Popover>
  )
}

CommonTableFilters.propTypes = {
  isOpen: PropTypes.bool,
  filterType: PropTypes.string,
  subType: PropTypes.string,
  operation: PropTypes.string,
  columnName: PropTypes.string,
  columnValue: PropTypes.any,
  label: PropTypes.string,
  isSingleSelect: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CommonTableFilters)
