import React, { Children } from 'react'
import PropTypes from 'prop-types'
import lowerCase from 'lodash/lowerCase'

import { CommonTextLabel } from 'components/common'

import { memo } from 'utils/react'

import T from 'T'

const BusinessLineTabTools = ({ tabValue, onFrontScreenChange }) => {
  return (
    <div className="service-type">
      <CommonTextLabel title={T.RECURRENCE} className="ttc mt20" />
      <div className="business-service-container">
        {Children.toArray(
          ['Recurring', 'On Request'].map(service => (
            <div
              className={`business-services ${tabValue === lowerCase(service) ? 'active-Line' : ''}`}
              onClick={() => onFrontScreenChange('serviceType', lowerCase(service))}
            >
              {service}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

BusinessLineTabTools.defaultProps = {
  tabValue: '',
  onFrontScreenChange: null,
}

BusinessLineTabTools.propTypes = {
  tabValue: PropTypes.string,
  onFrontScreenChange: PropTypes.func,
}

export default memo(BusinessLineTabTools)
