import { WORK_ORDER_STATUS } from 'settings/constants/service'

import endpoint from 'middleware/sagas/endpoint'
import api from '../index'

const { SCHEDULED } = WORK_ORDER_STATUS

const OPEN_INVOICE_STATUS = 'Open'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getServiceEvents: builder.mutation({
      query: body => ({ url: endpoint.configuredService.events, method: 'POST', body }),
      transformResponse: (response, meta, arg) => {
        if (arg?.transformResponse) {
          const scheduledEvents = response.filter(
            // Scheduled and Open (not invoiced)
            event => event?.workOrderStatus === SCHEDULED && event?.invoiceStatus === OPEN_INVOICE_STATUS
          )
          return scheduledEvents.map(event => ({ ...event, actionDate: event.date }))
        }

        return response
      },
    }),
  }),
})

export const { useGetServiceEventsMutation } = extendedApi
