import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@mui/material'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { HHAlert } from 'components/common/HHAlert'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBulkUnassignFromRows,
  selectBulkSelectedMoveFromRows,
  setBulkUnassignStopsState,
  setSelectedMoveFromRows,
  selectIsSelectDateDialogOpen,
  selectBulkUnassignToRows,
} from 'slices/route/bulkUnassignStopsSlice'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { mapStopToListItemFormat } from 'utils/route'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import RenderMoveFromHeader from 'components/route-manager/BulkActions/BulkUnassignStopsDialog/RenderMoveFromHeader'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import RenderFromStopListItem from 'components/route-manager/BulkActions/common/StopListItem/RenderFromStopListItem'

const MoveFromRouteColumn = ({ apiRef, routes }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { control, watch } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const serviceDate = watch('serviceDate')
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const moveFromRows = useSelector(selectBulkUnassignFromRows)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const moveToRows = useSelector(selectBulkUnassignToRows)

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
    },
    {
      field: 'id',
      renderCell: RenderFromStopListItem,
      renderHeader: RenderMoveFromHeader,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const handleSelectionModelChange = selectionModel => dispatch(setSelectedMoveFromRows(selectionModel))

  useEffect(() => {
    if (!isSelectDateDialogOpen && serviceDate && moveFromRouteId) {
      getRouteStops({
        routes: [moveFromRouteId],
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
        .unwrap()
        .then(data => {
          const stops = get(data, `routes.0.stops`, [])
          const formattedStops = stops.map((stop, index) => {
            const routeId = get(stop, 'routeId')
            return {
              ...mapStopToListItemFormat(stop),
              position: index,
              originalPosition: index,
              routeId,
            }
          })
          dispatch(setBulkUnassignStopsState({ moveFromRows: formattedStops }))
        })
    }
  }, [isSelectDateDialogOpen, serviceDate, moveFromRouteId])

  return (
    <>
      <Box mx={2} mb={2}>
        <DesktopRoutePicker readOnly={moveToRows.length > 0} name="moveFromRouteId" control={control} routes={routes} />
        {!moveFromRouteId && (
          <HHAlert severity="info" borderColor={theme.palette.info.dark} sx={{ mt: 1 }}>
            Select a route
          </HHAlert>
        )}
      </Box>
      <Box flex={1} height="100%" width="100%" overflow="hidden">
        {!isSelectDateDialogOpen && moveFromRouteId && (
          <BulkMoveStopsDataGridPro
            apiRef={apiRef}
            loading={isLoading || isFetching}
            rowSelectionModel={selectedMoveFromRows}
            onRowSelectionModelChange={handleSelectionModelChange}
            columns={columns}
            rows={moveFromRows}
          />
        )}
      </Box>
    </>
  )
}

MoveFromRouteColumn.propTypes = {
  routes: PropTypes.array.isRequired,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default MoveFromRouteColumn
