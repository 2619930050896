import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { NumericFormat } from 'react-number-format'

import AutoComplete from 'components/common/AutoComplete'
import InputAdornment from '@mui/material/InputAdornment'

import RecurrenceNumberTool from 'components/pricing/RecurrenceNumberTool'
import MinimumBillingNumberTool from 'components/pricing/MinimumBillingNumberTool'

import PricingSwitch from 'components/pricing/PricingSwitch'
import ActionAutoComplete from 'components/pricing/ActionAutoComplete'
import MeasureMaterialAutoComplete from 'components/pricing/MeasureMaterialAutoComplete'
import MethodDaysAutoComplete from 'components/pricing/MethodDaysAutoComplete'
import PrizingZoneAutoComplete from 'components/pricing/PrizingZoneAutoComplete'
import PricingPeriodAutoComplete from 'components/pricing/PricingPeriodAutoComplete'
import GeneralLedgerAutoComplete from 'components/pricing/GeneralLedgerAutoComplete'

import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'
import { getRecurrenceLabel } from 'components/pricing/pricingMethods'
import { PRICING_NEW_VIEW_TYPE } from 'components/pricing/settings'
import { RECURRENCE } from 'settings/constants/service'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import { get } from 'utils/lodash'

import T from 'T'

import { Box, Stack } from '@mui/material'
import { HHTextField } from 'components/form-fields/v5'
import { generateServiceName } from '../../../utils/pricing'
import RentalFeeField from '../RentalFees/common/field/RentalFeeField'

const NewServicesForm = ({
  tabValue,
  globalFilterOptions,
  handleCheckboxValue,
  checkedValues,
  counter,
  recurCounter,
  switchVal,
  setSwitchVal,
  setCount,
  setRecCount,
  onHandleChange,
  inputText,
  backFlag,
  businessLine,
  rentalFeeId = '',
  onChangeRentalFee,
}) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const isRecurringService = tabValue === RECURRENCE.RECURRING.toLowerCase()
  const getServiceName = () => {
    return inputText.serviceName
  }

  const getPriceValue = () => {
    return inputText.value
  }

  const {
    checkedMethods,
    checkedMaterials,
    checkedMeasure,
    checkedPricingPeriod,
    checkedPricingZones,
    checkedRecurrence,
    checkedServiceAction,
    checkedDays,
    checkedGeneralLedger,
  } = (() => {
    return {
      checkedMethods: get(checkedValues, 'methods', []),
      checkedMaterials: get(checkedValues, 'materials', []),
      checkedMeasure: get(checkedValues, 'measures', []),
      checkedPricingPeriod: get(checkedValues, 'pricingPeriod', []),
      checkedPricingZones: get(checkedValues, 'pricingZones', []),
      checkedRecurrence: get(checkedValues, 'recurrence', []),
      checkedServiceAction: get(checkedValues, 'actions', []),
      checkedDays: get(checkedValues, 'specificDays', []),
      checkedGeneralLedger: get(checkedValues, 'generalLedgerAccount', []),
    }
  })()

  useEffect(() => {
    if (inputText.isNameDirty) return
    const name = generateServiceName({
      businessLine,
      recurrence: get(checkedRecurrence, '[0].label'),
    })
    onHandleChange({ target: { name: 'serviceName', value: name } })
  }, [])

  return (
    <Stack sx={{}} className="grid-container new-service-form" flexDirection="row">
      <div className="left-section">
        <ActionAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedServiceAction={checkedServiceAction}
          backFlag={backFlag}
        />

        <MethodDaysAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMethods={checkedMethods}
          checkedDays={checkedDays}
          backFlag={backFlag}
        />

        {isRecurringService && (
          <Box mt={3.2} className="min-billing-period">
            <div className="switch-section">
              <div className="switch">
                <PricingSwitch checked={switchVal} handleSwitchVal={() => setSwitchVal(!switchVal)} />
              </div>
              <div className="switch-label">Min. Billing Periods</div>
            </div>

            <MinimumBillingNumberTool counter={counter} onHandleChange={onHandleChange} setCount={setCount} />
          </Box>
        )}

        <div className="value left-elements-width mt15">
          <div className="service-label">Value</div>

          <NumericFormat
            id="outlined-size-small"
            className="normal-textfield service-text-field price-field"
            placeholder="0.00"
            value={getPriceValue()}
            variant="outlined"
            autoComplete="off"
            name="value"
            onChange={e => onHandleChange(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            defaultSize
            customInput={HHTextField}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
          />
        </div>

        <PricingPeriodAutoComplete
          viewType={PRICING_NEW_VIEW_TYPE.NEW_SERVICE}
          isRecurringService={isRecurringService}
          globalFilterOptions={globalFilterOptions}
          handleCheckboxValue={handleCheckboxValue}
          checkedPricingPeriod={checkedPricingPeriod}
          backFlag={backFlag}
        />
      </div>

      <div className="vertical-line" />

      <Box ml={2} className="right-section">
        <div className="service left-elements-width">
          <div className="service-label">
            {T.SERVICE}
            <span className="red ml1">*</span>
          </div>
          <HHTextField
            defaultSize
            id="outlined-size-small"
            placeholder={T.SERVICE}
            value={getServiceName()}
            name="serviceName"
            autoFocus
            variant="outlined"
            autoComplete="off"
            fullWidth
            InputProps={{
              onChange: e => {
                onHandleChange(e, true)
              },
            }}
          />
        </div>

        <PrizingZoneAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedPricingZones={checkedPricingZones}
          backFlag={backFlag}
        />

        {isRecurringService && (
          <div className="recurrence left-elements-width mt15">
            <div className="service-label">
              {T.RECURRENCE}
              <span className="red ml1">*</span>
            </div>

            <div className="recur-block">
              <RecurrenceNumberTool recurCounter={recurCounter} onHandleChange={onHandleChange} setRecCount={setRecCount} />

              <div className="auto-complete">
                <AutoComplete
                  backFlag={backFlag}
                  maxHeight={180}
                  isHandleCheckbox
                  metaData={get(globalFilterOptions, 'recurrence', [])}
                  label={T.RECURRENCE}
                  getByLabelText={option => getRecurrenceLabel(option)}
                  handleCheckboxValue={handleCheckboxValue}
                  checkedRecords={checkedRecurrence}
                  getPlaceholder={checkedRecurrence.length ? '' : T.RECURRENCE}
                />
              </div>
            </div>
          </div>
        )}

        <MeasureMaterialAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMeasure={checkedMeasure}
          checkedMaterials={checkedMaterials}
          backFlag={backFlag}
        />

        <GeneralLedgerAutoComplete checkedValues={checkedGeneralLedger} handleCheckboxValue={handleCheckboxValue} backFlag={backFlag} />

        {isRentalFeeEnabled && (
          <Box my={2} maxWidth={296}>
            <RentalFeeField
              value={rentalFeeId}
              hideArchived
              onChange={onChangeRentalFee}
              fieldSX={{
                '& .MuiOutlinedInput-root': {
                  height: '50px!important',
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Stack>
  )
}

NewServicesForm.defaultProps = {
  tabValue: 'recurring',
  globalFilterOptions: {},
  serviceInput: null,
  inputText: {},
  checkedValues: {},
  counter: 1,
  recurCounter: 1,
  switchVal: false,
  backFlag: false,
  handleCheckboxValue: null,
  setSwitchVal: null,
  setCount: null,
  setRecCount: null,
  handlePriceLocked: null,
  onHandleChange: null,
}

NewServicesForm.propTypes = {
  tabValue: PropTypes.string,
  globalFilterOptions: PropTypes.object,
  serviceInput: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputText: PropTypes.object,
  checkedValues: PropTypes.object,
  counter: PropTypes.number,
  recurCounter: PropTypes.number,
  switchVal: PropTypes.bool,
  backFlag: PropTypes.bool,
  handleCheckboxValue: PropTypes.func,
  setSwitchVal: PropTypes.func,
  setCount: PropTypes.func,
  setRecCount: PropTypes.func,
  onHandleChange: PropTypes.func,
  businessLine: PropTypes.string,
  rentalFeeId: PropTypes.string,
  onChangeRentalFee: PropTypes.func,
}

export default NewServicesForm
