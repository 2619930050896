import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRouteList: build.query({
      query: ({ deleted = false }) => ({
        url: `/api/v1/core/routes?deleted=${deleted}`,
      }),
    }),
  }),
})

export const { useLazyGetRouteListQuery } = extendedApi
