import { takeEvery, all, call, put } from 'redux-saga/effects'

import { get } from 'utils/lodash'
import { doPost, doGet } from 'providers/api'
import { getBillingURL } from 'utils/billing'

import { actions, putBillingInvoiceMeta } from '../actions/billing'

import endpoint from './endpoint'

export const getBillingTableContent = ({ body, url }) => doPost({ url, body, showLoader: false })
export const getBillingInvoiceMeta = ({ body }) => doGet({ url: endpoint.billing.invoice.meta, body, showLoader: false })
export const getBillingSearchFilterViewList = ({ body, url }) => doPost({ url, body, showLoader: false })
export const billingAddEditFilterView = ({ body, url }) => doPost({ url, body, showLoader: true })
export const billingDeleteFilterView = ({ body, url }) => doPost({ url, body, showLoader: true })
export const billingInvoicesSendInvoice = ({ body }) => doPost({ url: endpoint.billing.invoice.send, body, showLoader: true })
export const billingInvoicesPostInvoice = ({ body }) => doPost({ url: endpoint.billing.invoice.post, body, showLoader: true })
export const getBillingInvoiceWorkOrder = ({ body }) => doPost({ url: endpoint.workOrder.get, body, showLoader: false })
export const getBillingSentInvoiceDownload = ({ body }) =>
  doPost({ url: endpoint.billing.sentInvoice.download, body, showLoader: true, arraybuffer: 'arraybuffer' })

export function* getBillingInvoiceWorkOrderSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getBillingInvoiceWorkOrder, { body })
    const { workOrders } = res
    if (typeof onSuccess === 'function') {
      onSuccess(workOrders)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getBillingTableContentSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getBillingURL('list', get(body, 'activeBillingTab'))
    if (!url) {
      return
    }

    const { data: res } = yield call(getBillingTableContent, { body, url })
    const { totalItems, totalPages } = res
    const tableRows = get(res, 'account') || get(res, 'invoices') || get(res, 'payments') || []
    if (typeof onSuccess === 'function') {
      onSuccess(tableRows, totalItems, totalPages)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getBillingInvoiceMetaSaga({ payload }) {
  const { body } = payload
  try {
    const { data: res } = yield call(getBillingInvoiceMeta, { body })
    yield put(putBillingInvoiceMeta(res))
  } catch (err) {
    yield put(putBillingInvoiceMeta(null))
  }
}

export function* getBillingSearchFilterViewListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getBillingURL('filterView', get(body, 'activeBillingTab'))
    if (!url) {
      return
    }

    const { data: res } = yield call(getBillingSearchFilterViewList, { body, url })
    const { searchFilters } = res
    if (typeof onSuccess === 'function') {
      onSuccess(searchFilters)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* billingAddEditFilterViewSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getBillingURL('filterAddEdit', get(body, 'activeBillingTab'))
    if (!url) {
      return
    }

    const { data: res } = yield call(billingAddEditFilterView, { body, url })
    const { id } = res
    if (typeof onSuccess === 'function') {
      onSuccess(true, id)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* billingDeleteFilterViewSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const url = getBillingURL('filterDelete', get(body, 'activeBillingTab'))
    if (!url) {
      return
    }

    yield call(billingDeleteFilterView, { body, url })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* billingInvoicesSendInvoiceSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield call(billingInvoicesSendInvoice, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* billingInvoicesPostInvoiceSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield call(billingInvoicesPostInvoice, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* getBillingSentInvoiceDownloadSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getBillingSentInvoiceDownload, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export default function* Billing() {
  yield all([
    takeEvery(actions.GET.BILLING_TABLE_CONTENT, getBillingTableContentSaga),
    takeEvery(actions.GET.BILLING_INVOICE_META, getBillingInvoiceMetaSaga),
    takeEvery(actions.GET.BILLING_GET_SEARCH_FILTER_VIEW_LIST, getBillingSearchFilterViewListSaga),
    takeEvery(actions.BILLING_ADD_EDIT_FILTER_VIEW, billingAddEditFilterViewSaga),
    takeEvery(actions.BILLING_DELETE_FILTER_VIEW, billingDeleteFilterViewSaga),
    takeEvery(actions.BILLING_INVOICES_SEND_INVOICE, billingInvoicesSendInvoiceSaga),
    takeEvery(actions.BILLING_INVOICES_POST_INVOICE, billingInvoicesPostInvoiceSaga),
    takeEvery(actions.GET.BILLING_SENT_INVOICE_DOWNLOAD, getBillingSentInvoiceDownloadSaga),
    takeEvery(actions.GET.BILLING_INVOICE_WORK_ORDER, getBillingInvoiceWorkOrderSaga),
  ])
}
