import React, { useCallback, useContext, useState } from 'react'
import { User } from '@styled-icons/heroicons-outline/User'
import { Grid, IconButton, ListItem, ListItemText, Stack, SvgIcon, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { get } from 'lodash'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import { ReactComponent as LocationNoteIcon } from 'assets/note/LocationNote.svg'
import LocationNotesDialogVariant from 'components/notes/location/LocationNotesDialogVariant'
import api from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { CACHE_TAG_ACCOUNT_LOCATION_DETAILS, CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'
import EventBlocker from 'components/common/EventBlocker'
import StyledBadge from 'components/notes/common/StyledBadge'
import ViewLocationContactsPopover from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContactsPopover'
import ViewLocationContactsDialog from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContactsDialog'
import LocationActionsMenu from 'components/locations/LocationsDataGrid/LocationActionsMenu'
import { MoreVert } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { LocationRowContext } from 'components/locations/LocationsDataGrid/LocationRowContext'
import { useLazyGetAllLocationNotesQuery } from 'api/notes/locationNotesCrud'
import ViewNotesPopover from 'components/notes/common/ViewNotesPopover'

const RenderLocationRightContentCell = props => {
  const { row } = props
  const name = get(row, 'addressStr')
  const accountName = get(row, 'accountName')
  const locationNoteCount = get(row, 'locationNoteCount', 0)
  const contactCount = get(row, 'contactCount', 0)
  const hasContacts = contactCount > 0
  const hasLocationNotes = locationNoteCount > 0
  const tags = get(row, 'tags', [])
  const dispatch = useDispatch()
  const [viewContactsPopoverAnchorEl, setViewContactsPopoverAnchorEl] = useState(null)
  const [locationNotesPopoverAnchorEl, setLocationNotesPopoverAnchorEl] = useState(null)
  const locationId = get(row, 'locationId')
  const {
    handleActionsClick,
    isOpenContactsDialog,
    closeContactsDialog,
    openContactsDialog,
    isOpenLocationNotesDialog,
    openLocationNotesDialog,
    closeLocationNotesDialog,
  } = useContext(LocationRowContext)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'))

  const onSaveTags = (data, closeTagsDialog) => {
    dispatch(api.util.invalidateTags([CACHE_TAG_ACCOUNT_LOCATION_LIST, CACHE_TAG_ACCOUNT_LOCATION_DETAILS]))
    closeTagsDialog()
  }

  const handleViewContactsPopoverOpen = useCallback(
    event => {
      if (!hasContacts) return
      event.preventDefault()
      event.stopPropagation()
      setViewContactsPopoverAnchorEl(event.currentTarget)
    },
    [hasContacts]
  )

  const handleViewContactsPopoverClose = () => setViewContactsPopoverAnchorEl(null)

  const handleLocationNotesPopoverOpen = useCallback(
    event => {
      if (!hasLocationNotes) return
      event.preventDefault()
      event.stopPropagation()
      setLocationNotesPopoverAnchorEl(event.currentTarget)
    },
    [hasLocationNotes]
  )

  const handleLocationNotesPopoverClose = () => setLocationNotesPopoverAnchorEl(null)

  const openActionMenu = event => {
    event.preventDefault()
    event.stopPropagation()
    handleActionsClick(event)
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <ListItem dense disablePadding>
        <ListItemText
          disableTypography
          primary={
            <Grid item container xs={12} columnGap={1} justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
              {!isTabletOrMobile && (
                <Grid item>
                  <EventBlocker preventDefault>
                    <InteractiveTagsList
                      tagsFlexWrap="nowrap"
                      limit={isLarge && !showCustomerSideNav ? 3 : 2}
                      data={{
                        tagDetails: {
                          forLocation: tags,
                        },
                        locationId,
                        name,
                      }}
                      disableAccount
                      disableService
                      onSave={onSaveTags}
                    />
                  </EventBlocker>
                </Grid>
              )}
              {!isTabletOrMobile && (
                <Grid item>
                  <EventBlocker preventDefault>
                    <IconButton
                      onClick={openLocationNotesDialog}
                      onMouseEnter={handleLocationNotesPopoverOpen}
                      onMouseLeave={handleLocationNotesPopoverClose}
                    >
                      <StyledBadge badgeContent={locationNoteCount} color="primary">
                        <LocationNoteIcon />
                      </StyledBadge>
                    </IconButton>
                  </EventBlocker>
                </Grid>
              )}
              {!isTabletOrMobile && (
                <Grid item>
                  <EventBlocker>
                    <Tooltip placement="top" arrow title={!hasContacts && 'This location has no contacts'}>
                      <span>
                        <IconButton
                          disabled={!hasContacts}
                          onClick={openContactsDialog}
                          onMouseEnter={handleViewContactsPopoverOpen}
                          onMouseLeave={handleViewContactsPopoverClose}
                        >
                          <StyledBadge badgeContent={contactCount}>
                            <SvgIcon>
                              <User />
                            </SvgIcon>
                          </StyledBadge>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </EventBlocker>
                </Grid>
              )}
              <Grid item>
                <EventBlocker>
                  <IconButton onClick={openActionMenu}>
                    <MoreVert />
                  </IconButton>
                </EventBlocker>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <EventBlocker preventDefault={false}>
        <LocationActionsMenu row={row} />
        <ViewLocationContactsPopover
          anchorEl={viewContactsPopoverAnchorEl}
          locationId={locationId}
          accountName={accountName}
          onClose={handleViewContactsPopoverClose}
        />
        <ViewNotesPopover
          anchorEl={locationNotesPopoverAnchorEl}
          id={locationId}
          useLazyGetAllNotesQuery={useLazyGetAllLocationNotesQuery}
          onClose={handleLocationNotesPopoverClose}
        />
        <ViewLocationContactsDialog
          isOpen={isOpenContactsDialog}
          locationId={locationId}
          accountName={accountName}
          onClose={closeContactsDialog}
        />
        <LocationNotesDialogVariant isOpen={isOpenLocationNotesDialog} locationId={locationId} onClose={closeLocationNotesDialog} />
      </EventBlocker>
    </Stack>
  )
}

RenderLocationRightContentCell.propTypes = {
  row: PropTypes.object,
}

export default RenderLocationRightContentCell
