import api from 'api'
import { CACHE_TAG_BILLING_PAYMENT_GROUP_LIST, CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    updatePaymentGroup: build.mutation({
      query: ({ id, body }) => ({ url: `/api/v1/billing/payment/groups/${id}`, method: 'PUT', body }),
      invalidatesTags: (result, error, arg) => [
        CACHE_TAG_BILLING_PAYMENT_GROUP_LIST,
        { type: CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS, id: arg.id },
      ],
    }),
  }),
})

export const { useUpdatePaymentGroupMutation } = extendedApi
