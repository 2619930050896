import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableHead, TableCell } from '@mui/material'
import { styled } from '@mui/material/styles'
import PricingSwitch from 'components/pricing/PricingSwitch'

import Constants from 'Constants'

import { memo } from 'utils/react'

const { MEDIA_SERVER } = Constants
const FilterImg = styled('img')(() => ({
  marginLeft: '25px',
  marginRight: '0.25rem',
  display: 'none',
}))
const SortImg = styled('img')(() => ({
  marginLeft: '0.25rem',
  marginRight: '0.5rem',
  display: 'none',
}))

const LinkedServiceHeader = ({ values, handleSwitchVal, tab, serviceList }) => {
  const condition = serviceList.length > 0 && serviceList.length === serviceList.filter(data => data.linkedVal).length
  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          values &&
            values.map(val => (
              <TableCell
                sx={{
                  backgroundColor: '#ffffff',
                  fontSize: 12,
                  color: '#6e788a',
                  whiteSpace: 'nowrap',
                }}
              >
                <FilterImg src={`${MEDIA_SERVER}Table-Filter-Icon.svg`} alt="" />
                {val}
                <SortImg src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />
              </TableCell>
            ))
        )}

        <TableCell
          sx={{
            backgroundColor: '#ffffff',
            fontSize: 12,
            color: '#6e788a',
            whiteSpace: 'nowrap',
          }}
        >
          <PricingSwitch checked={condition} handleSwitchVal={() => handleSwitchVal(!condition, 'all', tab)} />
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

LinkedServiceHeader.defaultProps = {
  values: [],
  serviceList: [],
  tab: '',
  handleSwitchVal: null,
}

LinkedServiceHeader.propTypes = {
  values: PropTypes.array,
  serviceList: PropTypes.array,
  tab: PropTypes.string,
  handleSwitchVal: PropTypes.func,
}

export default memo(LinkedServiceHeader)
