import readXlsxFile from 'read-excel-file'
import indexOf from 'lodash/indexOf'
import { get } from 'utils/lodash'
import { isValidUUID } from 'utils/validations'

import T from 'T'

export const isImportSequenceFileValid = async (file, resequenceColumn) => {
  const isOldFormat = resequenceColumn === T.ROUTE_PLAN_ID
  return new Promise((resolve, reject) => {
    readXlsxFile(file)
      .then(rows => {
        // Header + data row length
        if (rows.length < 2) {
          return reject(T.EMPTY_FILE)
        }

        // check if resequence column exists
        const columnIndex = indexOf(get(rows, '[0]'), resequenceColumn)
        if (columnIndex === -1) {
          return reject(isOldFormat ? T.ROUTE_PLAN_ID_WRONG_COLUMN_ERROR : T.WORK_ORDER_ID_WRONG_COLUMN_ERROR)
        }

        const allStops = rows.slice(1)
        for (let i = 0; i < allStops.length; i++) {
          const resequenceColumnValue = get(allStops, `[${i}][${columnIndex}]`)
          // If resequenceColumn value exists then check for valid UUID, BE will skip empty values
          if (resequenceColumnValue && !isValidUUID(resequenceColumnValue.replace(/['"]/g, ""))) {
            return reject(isOldFormat ? T.ROUTE_PLAN_ID_WRONG_UUID_FORMAT : T.WORK_ORDER_ID_WRONG_UUID_FORMAT)
          }
        }
        return resolve(true)
      })
      .catch(() => reject(T.ERROR_READING_SEQUENCE_FILE))
  })
}
