import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { grey, green } from '@mui/material/colors'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'

import { formatCentsToDollars, shortenMoneyValue } from 'utils/price'
import { getInvoicesRevenue } from 'data/groups/invoiceGroupsSelectors'

import T from 'T'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'

const UNCOLLECTED_REVENYE = 'Uncollected'
const COLLECTED_REVENYE = 'Collected'

const COLOR_MAP = {
  [UNCOLLECTED_REVENYE]: grey[200],
  [COLLECTED_REVENYE]: green[100],
}

const InvoiceRevenueStatistic = ({ invoices = [], showFooter = true }) => {
  const { totalAmountCents, collectedRevenueCents, uncollectedRevenueCents } = getInvoicesRevenue({ invoices })

  const total = formatCentsToDollars(totalAmountCents)
  const collectedRevenue = formatCentsToDollars(collectedRevenueCents)
  const uncollectedRevenue = formatCentsToDollars(uncollectedRevenueCents)

  const shortenTotal = `$${shortenMoneyValue(total)}`
  const formattedTotal = `$${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`

  const data = [
    {
      id: UNCOLLECTED_REVENYE,
      label: UNCOLLECTED_REVENYE,
      value: uncollectedRevenue,
      color: COLOR_MAP[UNCOLLECTED_REVENYE],
    },
    {
      id: COLLECTED_REVENYE,
      label: COLLECTED_REVENYE,
      value: collectedRevenue,
      color: COLOR_MAP[COLLECTED_REVENYE],
    },
  ]

  const getArcLabel = ({ value }) => `$${shortenMoneyValue(value)}`

  const getValueFormat = value => `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`

  return (
    <>
      <Box height={250} position="relative">
        <GroupPieChart
          data={data}
          colors={Object.values(COLOR_MAP)}
          valueFormat={getValueFormat}
          arcLabel={getArcLabel}
          legendProps={{ itemWidth: 100 }}
        />
        <GroupChartOverlay overlayValue={shortenTotal} tooltipTitle={formattedTotal} />
      </Box>
      {showFooter && <GroupChartName Icon={CurrencyDollar} label={T.REVENUE} />}
    </>
  )
}

InvoiceRevenueStatistic.propTypes = {
  invoices: PropTypes.array,
  showFooter: PropTypes.bool,
}

export default InvoiceRevenueStatistic
