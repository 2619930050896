import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { TableRow, TableCell, TableBody as TBody, Paper, Typography, Popover } from '@mui/material'
import HHTextField from 'components/form-fields/v5/HHTextField'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { SketchPicker } from 'react-color'
import CommonSwitch from 'components/common/CommonSwitch'
import { get } from 'utils/lodash'
import { memo } from 'utils/react'

const MaterialTableBody = ({ values = [], handleSwitchVal = noop, onHandleChange = noop, handleRemoval = noop }) => {
  const [picker, setPicker] = useState(false)
  const [currentColor, setCurrentColor] = useState('')
  const [currentIndex, setCurrentIndex] = useState('')

  const handlePickerClick = event => {
    setPicker(event.currentTarget)
    setCurrentIndex(event.target.dataset.index)
  }

  const onHandleChangeComplete = e => {
    setCurrentColor(e.hex)
    onHandleChange(e, currentIndex, 'color')
  }

  const onHandleRemove = e => {
    handleRemoval(e.target.dataset.index)
  }

  return (
    <>
      <TBody>
        {Children.toArray(
          values &&
            values.map((val, index) => {
              const color = get(val, 'color', '#fff')
              return (
                <TableRow>
                  <TableCell>
                    <Paper
                      variant="outlined"
                      sx={{ backgroundColor: color, width: 24, height: 24, pointerEvents: val.id ? 'none' : '' }}
                      data-index={index}
                      onClick={handlePickerClick}
                    />
                  </TableCell>

                  <TableCell>
                    {val.id !== '' && <Typography variant="body1">{val.materialType}</Typography>}

                    {val.id === '' && (
                      <HHTextField
                        name="material"
                        placeholder="Enter Name"
                        autoComplete="off"
                        autoFocus
                        size="small"
                        variant="outlined"
                        value={val.materialType}
                        InputProps={{
                          onChange: e => onHandleChange(e, index, 'text'),
                        }}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    <CommonSwitch isChecked={get(val, 'active', false)} onChange={checked => handleSwitchVal('active', checked, index)} />
                  </TableCell>

                  <TableCell>
                    <CommonSwitch
                      isChecked={get(val, 'photoRequired', false)}
                      onChange={checked => handleSwitchVal('photoRequired', checked, index)}
                    />
                  </TableCell>

                  <TableCell>
                    {val.id === '' && (
                      <CancelOutlinedIcon
                        fontSize="small"
                        data-index={index}
                        onClick={onHandleRemove}
                        sx={{ color: 'text.secondary', m: 'auto 14px', cursor: 'pointer' }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })
        )}
      </TBody>

      {picker && (
        <Popover
          open={Boolean(picker)}
          anchorEl={picker}
          onClose={() => setPicker(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SketchPicker color={currentColor} disableAlpha onChangeComplete={onHandleChangeComplete} />
        </Popover>
      )}
    </>
  )
}

MaterialTableBody.propTypes = {
  values: PropTypes.array,
  handleSwitchVal: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleRemoval: PropTypes.func,
}

export default memo(MaterialTableBody)
