import { ACCOUNT_STATUS_FILTER_MENU_OPTIONS } from 'components/customers/groups/account/details/filters/account-status-filter/settings'
import { SUSPENSION_REASON_TYPES_OPTIONS } from 'components/customer-details/content/suspensions/settings'

export const FORM_MODEL = {
  tagList: [],
  billingProfileList: [],
  searchTagsInput: '',
  searchBillingInput: '',
  searchSuspensionTypeInput: '',
  selectedTags: [],
  selectedBillingProfiles: [],
  selectedAccountStatuses: ACCOUNT_STATUS_FILTER_MENU_OPTIONS,
  selectedSuspensionTypes: SUSPENSION_REASON_TYPES_OPTIONS,
}
