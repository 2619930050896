import React from 'react'
import PropTypes from 'prop-types'
import { Chip, MenuItem, useTheme } from '@mui/material'
import T from '../../../../T'
import { get } from '../../../../utils/lodash'
import { isValidHex } from '../../../../utils/validations'
import { HHFormAutocompleteField } from '../../../form-fields/v5'

const MaterialsAutocompleteFormField = ({ sx, options, control, name }) => {
  const theme = useTheme()
  const renderTags = (tags, getTagProps) =>
    tags.map((item, index) => {
      const materialType = get(item, 'materialType', '')
      const bgcolor = get(item, 'color', '')
      const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
      const color = theme.palette.getContrastText(formattedBgcolor)
      return <Chip label={materialType} size="small" {...getTagProps({ index })} sx={{ bgcolor: formattedBgcolor, color }} />
    })

  const renderOption = (props, option) => {
    const materialType = get(option, 'materialType', '')
    const bgcolor = get(option, 'color', '')
    const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
    const color = theme.palette.getContrastText(formattedBgcolor)
    return (
      <MenuItem {...props}>
        <Chip label={materialType} size="small" sx={{ bgcolor: formattedBgcolor, color }} />
      </MenuItem>
    )
  }

  const getOptionLabel = option => get(option, 'materialType', '')

  return (
    <HHFormAutocompleteField
      getOptionLabel={getOptionLabel}
      sx={sx}
      fullWidth
      options={options}
      name={name}
      control={control}
      label={T.MATERIAL}
      deprecatedLabel={false}
      renderTags={renderTags}
      renderOption={renderOption}
    />
  )
}

MaterialsAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  options: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default MaterialsAutocompleteFormField
