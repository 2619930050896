import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Divider } from '@mui/material'

import ActivityHeader from '../../common/ActivityHeader'
import PaymentActivity from '../../common/PaymentActivity'
import InvoiceActivity from '../../common/InvoiceActivity'

const PaymentInfoBlock = ({ payment = {}, paidInvoices = [], sourceAccountDetails = {} }) => {
  return (
    <>
      <Box mx={1}>
        <ActivityHeader />
      </Box>
      <Divider />
      <Box mx={1}>
        <PaymentActivity payment={payment} showAppliedColumn align="inherit" marginY={1} />
      </Box>
      <Divider />
      <Box>
        {Children.toArray(
          paidInvoices.map(invoice => (
            <>
              <Box mx={1}>
                <InvoiceActivity
                  chipGridWidth={120}
                  billingProfileDetails={sourceAccountDetails.billingProfileDetails}
                  invoice={invoice}
                  showAppliedColumn
                  align="inherit"
                  marginY={1}
                />
              </Box>
              <Divider />
            </>
          ))
        )}
      </Box>
    </>
  )
}

PaymentInfoBlock.propTypes = {
  payment: PropTypes.object,
  paidInvoices: PropTypes.array,
  sourceAccountDetails: PropTypes.object,
}

export default PaymentInfoBlock
