/* eslint-disable no-use-before-define */
import 'components/route-manager/BulkActions/common/boxdraw.css'
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
/* code taken from https://docs.mapbox.com/mapbox-gl-js/example/using-box-queryrenderedfeatures */
export const addCustomSelectBoxMapbox = ({ map, onSelectFeatures, layers }) => {
  map.boxZoom.disable()
  const canvas = map.getCanvasContainer()
  unsubscribe()

  let start

  let current

  let box

  canvas.addEventListener('mousedown', mouseDown, true)

  function mousePos(e) {
    const rect = canvas.getBoundingClientRect()
    return new mapboxgl.Point(e.clientX - rect.left - canvas.clientLeft, e.clientY - rect.top - canvas.clientTop)
  }

  function mouseDown(e) {
    if (!(e.shiftKey && e.button === 0)) return

    map.dragPan.disable()

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('keydown', onKeyDown)

    // Capture the first xy coordinates
    start = mousePos(e)
  }

  function onMouseMove(e) {
    // Capture the ongoing xy coordinates
    current = mousePos(e)

    // Append the box element if it doesnt exist
    if (!box) {
      box = document.createElement('div')
      box.classList.add('boxdraw')
      canvas.appendChild(box)
    }

    const minX = Math.min(start.x, current.x)
    const maxX = Math.max(start.x, current.x)
    const minY = Math.min(start.y, current.y)
    const maxY = Math.max(start.y, current.y)

    // Adjust width and xy position of the box element ongoing
    const pos = `translate(${minX}px, ${minY}px)`
    box.style.transform = pos
    box.style.width = `${maxX - minX}px`
    box.style.height = `${maxY - minY}px`
  }

  function onMouseUp(e) {
    finish([start, mousePos(e)])
  }

  function onKeyDown(e) {
    if (e.keyCode === 27) finish()
  }

  function finish(bbox) {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('keydown', onKeyDown)
    document.removeEventListener('mouseup', onMouseUp)

    if (box) {
      box.parentNode.removeChild(box)
      box = null
    }

    // If bbox exists. use this value as the argument for `queryRenderedFeatures`
    if (bbox) {
      const features = map.queryRenderedFeatures(bbox, {
        layers,
      })
      onSelectFeatures(features)
    }
    map.dragPan.enable()
  }
  function onMapMouseMove(e) {
    const features = map.queryRenderedFeatures(e.point)

    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = features.length ? 'pointer' : ''
  }
  map.off('mouvemove', onMapMouseMove)
  map.on('mousemove', onMapMouseMove)

  function unsubscribe() {
    if (canvas) {
      canvas.removeEventListener('mousedown', mouseDown)
    }
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('keydown', onKeyDown)
    document.removeEventListener('mouseup', onMouseUp)
    if (map) {
      map.off('mousemove', onMapMouseMove)
    }
  }

  return { unsubscribe }
}
