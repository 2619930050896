import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop, difference, differenceBy, uniq, isEqual, sortBy } from 'lodash'

import { useFormContext } from 'react-hook-form'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'

import { get } from 'utils/lodash'
import { getRoutesByMethod, getRoutesByBusinessLine } from 'data/route/routesByGroup'
import { selectRouteIds, setSelectedSearchResult, VIEW_MODE_SEQUENCE } from 'slices/route/routeSlice'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { getMetadataBusinessLines, getMetadataMethods } from 'data/route/routeMetadataSelector'
import { sortByStringSelector } from 'data/utils/sortByStringSelector'
import { getViewMode } from 'data/route/viewModeSelector'

import RoutePickerFilterButton from '../RouteManagerAppBar/RoutePickerFilterButton'
import BusinessLineFilterMenuField from './business-line-filter/BusinessLineFilterMenuField'
import MethodFilterMenuField from './method-filter/MethodFilterMenuField'
import TagFilterMenuField from './tag/TagFilterMenuField'

const FiltersMenu = ({ open = false, anchorEl, onClose = noop }) => {
  const dispatch = useDispatch()
  const { control, watch, setValue, reset } = useFormContext()

  const viewMode = useSelector(getViewMode, shallowEqual)
  const allRoutes = useSelector(state => get(state, 'Route.allRoutes', []), shallowEqual)
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const selectedRoutes = useMemo(() => allRoutes.filter(({ id }) => selectedRouteIds.includes(id)), [allRoutes, selectedRouteIds])
  const metaBusinessLines = useSelector(getMetadataBusinessLines, shallowEqual)
  const metaMethods = useSelector(getMetadataMethods, shallowEqual)

  const routesByMethod = getRoutesByMethod({ routes: allRoutes })
  const routesByBusinessLine = getRoutesByBusinessLine({ routes: allRoutes })

  const searchTagsInput = watch('searchTagsInput')
  const selectedTags = watch('selectedTags')
  const selectedRoutesByTagId = watch('selectedRoutesByTagId')
  const searchBusinessLineInput = watch('searchBusinessLineInput')
  const searchMethodInput = watch('searchMethodInput')
  const selectedBusinessLines = watch('selectedBusinessLines')
  const selectedMethods = watch('selectedMethods')
  const selectedBusinessLineIds = useMemo(() => selectedBusinessLines.map(({ id }) => id), [selectedBusinessLines])
  const selectedMethodTypes = useMemo(() => selectedMethods.map(({ methodType }) => methodType), [selectedMethods])

  const onRouteFilterChange = (routeIds = []) => {
    if (Array.isArray(routeIds)) {
      dispatch(selectRouteIds(routeIds))
      dispatch(setSelectedSearchResult(null))
    }
  }

  const handleBusinessLineSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchBusinessLineInput', value)
    }
  }

  const handleBusinessLineFilterChange = newBusinessLines => {
    const addedBusinessLines = differenceBy(newBusinessLines, selectedBusinessLines, 'id')
    const removedBusinessLines = differenceBy(selectedBusinessLines, newBusinessLines, 'id')
    const routeIdsToBeAdded = addedBusinessLines
      .map(({ id: businessLineId }) => {
        const routes = get(routesByBusinessLine, businessLineId, [])
        return routes.map(({ id }) => id)
      })
      .flat()
    const routeIdsTobeRemoved = removedBusinessLines
      .map(({ id: businessLineId }) => {
        const routes = get(routesByBusinessLine, businessLineId, [])
        return routes.map(({ id }) => id)
      })
      .flat()
    const newRouteIds = difference([...selectedRouteIds, ...routeIdsToBeAdded], routeIdsTobeRemoved)
    onRouteFilterChange(uniq(newRouteIds))
  }

  const handleMethodSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchMethodInput', value)
    }
  }

  const handleMethodFilterChange = newMethods => {
    const addedMethods = differenceBy(newMethods, selectedMethods, 'id')
    const removedMethods = differenceBy(selectedMethods, newMethods, 'id')
    const routeIdsToBeAdded = addedMethods
      .map(({ methodType }) => {
        const routes = get(routesByMethod, methodType, [])
        return routes.map(({ id }) => id)
      })
      .flat()
    const routeIdsTobeRemoved = removedMethods
      .map(({ methodType }) => {
        const routes = get(routesByMethod, methodType, [])
        return routes.map(({ id }) => id)
      })
      .flat()
    const newRouteIds = difference([...selectedRouteIds, ...routeIdsToBeAdded], routeIdsTobeRemoved)
    onRouteFilterChange(uniq(newRouteIds))
  }

  const handleTagsSearchInputChange = (_, value, reason) => {
    if (reason !== 'reset') {
      setValue('searchTagsInput', value)
    }
  }

  const handleTagFilterChange = newTags => {
    const addedTags = differenceBy(newTags, selectedTags, 'id')
    const removedTags = differenceBy(selectedTags, newTags, 'id')
    const newSelectedRoutesByTagId = { ...selectedRoutesByTagId }
    removedTags.forEach(({ id: tagId }) => {
      newSelectedRoutesByTagId[tagId] = []
    })
    addedTags.forEach(({ id: tagId, allRoutes: linkedRoutes }) => {
      newSelectedRoutesByTagId[tagId] = linkedRoutes.map(({ id }) => id)
    })
    setValue('selectedRoutesByTagId', newSelectedRoutesByTagId)
  }

  useEffect(() => {
    // if same business line and method type is selected, do not update
    if (metaBusinessLines.length === 0 && metaMethods.length === 0) {
      return
    }

    let allBusinessLineIds = []
    let allMethodTypes = []

    selectedRoutes.forEach(route => {
      const { businessLine = '', methodType = '' } = route
      if (businessLine) {
        allBusinessLineIds = uniq([...allBusinessLineIds, businessLine])
      }

      if (methodType) {
        allMethodTypes = uniq([...allMethodTypes, methodType])
      }
    })

    if (!isEqual(sortBy(allBusinessLineIds), sortBy(selectedBusinessLineIds))) {
      const newSelectedBusinessLines = metaBusinessLines.filter(({ id }) => {
        // Add check to preserve zero routes selected business line
        if (selectedBusinessLineIds.includes(id) && get(routesByBusinessLine, id, []).length === 0) {
          return true
        }

        return allBusinessLineIds.includes(id)
      })
      // remove sortByStringSelector
      setValue('selectedBusinessLines', sortByStringSelector({ data: newSelectedBusinessLines, key: 'businessLineName' }))
    }

    if (!isEqual(sortBy(allMethodTypes), sortBy(selectedMethodTypes))) {
      const newSelectedMethods = metaMethods.filter(({ methodType }) => {
        // Add check to preserve zero routes selected method
        if (selectedMethodTypes.includes(methodType) && get(routesByMethod, methodType, []).length === 0) {
          return true
        }

        return allMethodTypes.includes(methodType)
      })

      setValue('selectedMethods', newSelectedMethods)
    }
  }, [metaBusinessLines, metaMethods, selectedRoutes])

  const handleReset = () => {
    reset()
    if (selectedRouteIds.length === allRoutes.length) {
      onRouteFilterChange([])
      setTimeout(() => onRouteFilterChange(allRoutes.map(({ id }) => id)))
      return
    }
    onRouteFilterChange(allRoutes.map(({ id }) => id))
  }

  return (
    <Menu
      slotProps={{
        paper: {
          sx: { overflow: 'hidden' },
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      keepMounted
    >
      <MenuItem variant="text" onClick={handleReset}>
        <ListItemIcon>
          <ReplayIcon color="primary" />
        </ListItemIcon>
        <ListItemText sx={{ ml: 2 }}>Reset filters</ListItemText>
      </MenuItem>

      <BusinessLineFilterMenuField
        name="selectedBusinessLines"
        control={control}
        inputValue={searchBusinessLineInput}
        onInputChange={handleBusinessLineSearchInputChange}
        onChange={handleBusinessLineFilterChange}
      />

      <MethodFilterMenuField
        name="selectedMethods"
        control={control}
        inputValue={searchMethodInput}
        onInputChange={handleMethodSearchInputChange}
        onChange={handleMethodFilterChange}
      />

      {viewMode === VIEW_MODE_SEQUENCE && (
        <TagFilterMenuField
          name="selectedTags"
          control={control}
          inputValue={searchTagsInput}
          onInputChange={handleTagsSearchInputChange}
          onChange={handleTagFilterChange}
          isParentFilter
        />
      )}

      <RoutePickerFilterButton />
    </Menu>
  )
}

FiltersMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
}

export default FiltersMenu
