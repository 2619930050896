import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import { get } from 'utils/lodash'
import { selectLatitude, selectLongitude } from 'data/address/addressSelectors'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import LocationMapImage from 'components/customer-details/content/location/LocationMapImage'
import LocationContent from 'components/customer-details/content/location/LocationContent'

const RenderLocationDetails = ({ caller, location, onSaveTags, accountId, dynamicWidth = false }) => {
  const locationId = get(location, 'locationId')
  const tags = get(location, 'tags', [])
  const longitude = selectLongitude(location)
  const latitude = selectLatitude(location)
  const locationName = get(location, 'locationName')
  return (
    <Grid container flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'nowrap', lg: 'nowrap' }} columnGap={2} rowGap={2}>
      <Grid item xs={12} md lg xl>
        <LocationMapImage
          showInvalidAlert={false}
          height={200}
          width="100%"
          latitude={latitude}
          longitude={longitude}
          dynamicWidth={dynamicWidth}
          containerId={`location-${locationId}-map-preview-${caller}`}
          showPlaceholder
        />
      </Grid>

      <Grid item xs={12} md lg xl>
        <Box px={2}>
          <LocationContent showAddressName address={location} tags={tags} interactiveTags locationId={locationId} accountId={accountId} />
        </Box>
        <Box mt={1.5} px={2}>
          <InteractiveTagsList
            data={{
              locationId,
              name: locationName,
              tagDetails: { forLocation: tags },
            }}
            disableAccount
            disableService
            onSave={(data, closeCallback) => {
              closeCallback()
              onSaveTags()
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

RenderLocationDetails.propTypes = {
  caller: PropTypes.string,
  location: PropTypes.object,
  onSaveTags: PropTypes.func.isRequired,
  dynamicWidth: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
}

export default RenderLocationDetails
