import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import { Box, Dialog, DialogContent, styled } from '@mui/material'
import PermanentMoveToolbar from 'components/route-manager/BulkActions/common/PermanentMoveToolbar'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import { get } from 'lodash'
import { ChevronRight as UnstyledChevronRight } from '@styled-icons/heroicons-solid/ChevronRight'
import { ChevronDown as UnstyledChevronDown } from '@styled-icons/heroicons-solid/ChevronDown'
import useFetchConfirmPermanentRowsOnExpandedGroup from 'components/route-manager/BulkActions/common/useFetchConfirmPermanentRowsOnExpandedGroup'
import ConfirmPermanentGroupingCellWithLazyLoading from 'components/route-manager/BulkActions/BulkAssignStopsDialog/ConfirmPermanentMoveDialog/ConfirmPermanentGroupingCellWithLazyLoading'
import PermanentMoveStopListCell from 'components/route-manager/BulkActions/common/PermanentMoveStopListCell/PermanentMoveStopListCell'
import useTheme from '@mui/material/styles/useTheme'
import { TO_BE_MOVED_TYPE } from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/ConfirmPermanentMoveDialog/settings'
import { useFormContext } from 'react-hook-form'

const ChevronRight = styled(UnstyledChevronRight)({
  height: 20,
})

const ChevronDown = styled(UnstyledChevronDown)({
  height: 20,
})
const BasePermanentMoveDialog = ({
  AlertComponent,
  DialogProps,
  confirmPermanentGridApiRef,
  isPermanentLoading,
  onClose,
  onConfirm,
  open,
  serializedServiceDate,
  moveToRows,
  getIsFromRow,
  showOriginalPosition = false,
}) => {
  const theme = useTheme()

  const [toBeMovedRows, setToBeMovedRows] = useState([])
  const [rows, setRows] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const { watch } = useFormContext()
  const toBeMovedRowsCount = useMemo(() => get(toBeMovedRows, 'length', 0), [toBeMovedRows])
  const moveFromRouteId = watch('moveFromRouteId')
  const [expanded, setExpanded] = useState(false)

  const getTreeDataPath = useCallback(row => get(row, 'hierarchy'), [])
  const rowsExceedsLimit = useMemo(() => rows.length > 7, [rows])
  const toggleExpand = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded, confirmPermanentGridApiRef, rows])

  const { fetchConfirmPermanentMoveChildren } = useFetchConfirmPermanentRowsOnExpandedGroup({
    apiRef: confirmPermanentGridApiRef,
    isInitialized,
    rows,
    serializedServiceDate,
  })

  const columns = [
    {
      field: 'id',
      renderCell: params => <PermanentMoveStopListCell {...params} showOriginalPosition={showOriginalPosition} />,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const CUSTOM_GROUPING_COL_DEF = {
    renderHeader: () => <div />,
    renderCell: params => (
      <ConfirmPermanentGroupingCellWithLazyLoading
        {...params}
        isInitialized={isInitialized}
        fetchConfirmPermanentMoveChildren={fetchConfirmPermanentMoveChildren}
      />
    ),
    resizable: false,
    width: 46,
    minWidth: 46,
    maxWidth: 46,
  }

  useEffect(() => {
    if (open) {
      setToBeMovedRows(moveToRows.filter(getIsFromRow))
    }
  }, [moveToRows, open, moveFromRouteId])

  const getIsGroupExpandedByDefault = useCallback(() => expanded, [expanded])

  useEffect(() => {
    if (open && Array.isArray(toBeMovedRows) && toBeMovedRows.length > 0 && !isInitialized) {
      const stopsToBeAssigned = toBeMovedRows.map(row => {
        const configuredServiceEventId = get(row, 'configuredServiceEventId')
        const workOrderId = get(row, 'workOrderId')
        return {
          id: workOrderId,
          ...row,
          hierarchy: [configuredServiceEventId],
          isStop: true,
          type: TO_BE_MOVED_TYPE,
          childrenFetched: false,
          isLoading: false,
          isParent: true,
        }
      })
      setRows([...stopsToBeAssigned])
      setTimeout(() => {
        setIsInitialized(true)
      }, 200)
    }
  }, [open, toBeMovedRows, isInitialized])

  useEffect(() => {
    if (!open) {
      setIsInitialized(false)
      setToBeMovedRows([])
      setExpanded(false)
    }
  }, [open])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      {...DialogProps}
      sx={{ zIndex: theme.zIndex.snackbar + 4, ...DialogProps?.sx }}
    >
      <HHDialogTitle title={T.PERMANENT_MOVE_CONFIRMATION} showCloseIcon onClose={onClose} />
      <DialogContent sx={{ pt: 0.5 }}>
        <AlertComponent toBeMovedRowsCount={toBeMovedRowsCount} />
        <PermanentMoveToolbar toggleExpand={toggleExpand} expanded={expanded} />
        <Box height={rowsExceedsLimit ? 300 : 'auto'} overflow="auto">
          <BulkMoveStopsDataGridPro
            slots={{
              treeDataExpandIcon: ChevronRight,
              treeDataCollapseIcon: ChevronDown,
            }}
            treeData
            sortModel={[
              {
                field: 'position',
                sort: 'asc',
              },
              {
                field: 'serviceDate',
                sort: 'desc',
              },
            ]}
            loading={!isInitialized && toBeMovedRows.length !== 0}
            headerHeight={0}
            columns={columns}
            rows={rows}
            rowHeight={44}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={CUSTOM_GROUPING_COL_DEF}
            apiRef={confirmPermanentGridApiRef}
            isGroupExpandedByDefault={getIsGroupExpandedByDefault}
            checkboxSelection={false}
            autoHeight={!rowsExceedsLimit}
          />
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="small" onClick={onClose} />
        <LoadingButton onClick={onConfirm} variant="contained" size="small" loading={isPermanentLoading}>
          {T.CONFIRM}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

BasePermanentMoveDialog.propTypes = {
  AlertComponent: PropTypes.node.isRequired,
  DialogProps: PropTypes.object,
  confirmPermanentGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
  isPermanentLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  serializedServiceDate: PropTypes.string.isRequired,
  moveToRows: PropTypes.array.isRequired,
  getIsFromRow: PropTypes.func.isRequired,
  showOriginalPosition: PropTypes.bool,
}

export default BasePermanentMoveDialog
