import React from 'react'
import PropTypes from 'prop-types'

import { Chip, Avatar, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import HHDisplayMoney from '../HHDisplayMoney'

const AccountBalanceChip = ({ balance = 0, color = grey }) => {
  const theme = useTheme()
  return (
    <Chip
      size="small"
      avatar={
        <Avatar sx={{ backgroundColor: color[500] }}>
          <AttachMoneyIcon sx={{ fontSize: 16, color: theme.palette.common.white }} />
        </Avatar>
      }
      variant="outlined"
      label={<HHDisplayMoney value={balance} />}
      sx={{
        fontWeight: 600,
        border: '1px solid',
        borderColor: color[500],
        backgroundColor: color[50],
        color: color[700],
      }}
    />
  )
}

AccountBalanceChip.propTypes = {
  balance: PropTypes.number,
  color: PropTypes.object,
}

export default AccountBalanceChip
