import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import { User } from '@styled-icons/heroicons-outline/User'

import { shortenMoneyValue } from 'utils/price'

import T from 'T'
import GroupPieChart from 'components/billing/groups/common/GroupPieChart'
import GroupChartOverlay from 'components/billing/groups/common/GroupChartOverlay'
import GroupChartName from 'components/billing/groups/common/GroupChartName'
import ChartLegend from 'components/billing/groups/common/ChartLegend'
import { ACCOUNT_STATUS_COLOR_MAP } from 'components/customers/groups/account/common/settings'

const AccountStatusStatistic = ({ showFooter = true, data = [] }) => {
  const getArcLabel = ({ value }) => `${value}`

  const getValueFormat = value => `${value}`
  const total = useMemo(() => `${data.reduce((acc, cur) => acc + cur.value, 0)}`, [data])
  const shortenTotal = useMemo(() => shortenMoneyValue(total, 0, 0), [total])
  return (
    <>
      <Box height={230} top={8} position="relative">
        <GroupPieChart
          layers={['arcs', 'arcLinkLabels', 'arcLabels']}
          data={data}
          colors={Object.values(ACCOUNT_STATUS_COLOR_MAP)}
          valueFormat={getValueFormat}
          arcLabel={getArcLabel}
        />
        <GroupChartOverlay overlayValue={shortenTotal} tooltipTitle={total} />
      </Box>
      <ChartLegend showZeroValueLegend data={data} />
      {showFooter && <GroupChartName Icon={User} label={T.ACCOUNT_STATUS} />}
    </>
  )
}

AccountStatusStatistic.propTypes = {
  showFooter: PropTypes.bool,
  data: PropTypes.array,
}

export default AccountStatusStatistic
