import api from 'api'
import { CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getPGUserInfo: builder.query({
      query: ({ pgUrl }) => ({
        url: `${pgUrl}/api/crm/user/info`,
        method: 'POST',
        body: {},
      }),
    }),
    getIsSurchargeApplied: builder.query({
      query: ({ pgUrl }) => ({
        url: `${pgUrl}/api/crm/payment/surcharge`,
        method: 'GET',
      }),
    }),
    getAllPaymentMethods: builder.query({
      query: ({ pgUrl, accountId }) => ({
        url: `${pgUrl}/api/crm/payment-method/list`,
        method: 'POST',
        body: { accountId },
      }),
      providesTags: (result, error, { accountId }) => [
        CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST,
        { type: CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST, id: accountId },
      ],
    }),
    addPaymentMethod: builder.mutation({
      query: ({ pgUrl, payload }) => ({
        url: `${pgUrl}/api/crm/payment-method/add`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { payload }) => [{ type: CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST, id: payload.accountId }],
    }),
    removePaymentMethod: builder.mutation({
      query: ({ pgUrl, payload }) => ({
        url: `${pgUrl}/api/crm/payment-method/remove`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { payload }) => [{ type: CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST, id: payload.accountId }],
    }),
    toggleAutoPay: builder.mutation({
      query: ({ pgUrl, payload }) => ({
        url: `${pgUrl}/api/crm/toggle-autopay`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { payload }) => [{ type: CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST, id: payload.accountId }],
    }),
  }),
})

export const {
  useGetPGUserInfoQuery,
  useLazyGetPGUserInfoQuery,
  useLazyGetIsSurchargeAppliedQuery,
  useLazyGetAllPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useToggleAutoPayMutation,
} = extendedApi

export const getPGUserInfoSlice = extendedApi.endpoints.getPGUserInfo.select()
