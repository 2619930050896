import React from 'react'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import Typography from '@mui/material/Typography'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

const BillingPeriodDropdown = ({
  disabled = false,
  label = capitalize(T.BILLING_PERIOD),
  selectedBillingPeriod = '',
  allBillingPeriods = [],
  onBillingPeriodChange,
  ...rest
}) => {
  return (
    <HHTextField
      select
      disabled={disabled}
      label={label}
      fullWidth
      value={[selectedBillingPeriod]}
      onChange={event => onBillingPeriodChange(event.target.value)}
      sx={{
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }}
      {...rest}
    >
      <SelectItem value="">{T.PLEASE_SELECT}</SelectItem>
      {allBillingPeriods.map(val => {
        return (
          <SelectItem value={val.invoiceDate}>
            <Typography fontWeight={600} noWrap>
              {val.labelLeft}
            </Typography>
            <Typography noWrap>{val.labelRight}</Typography>
          </SelectItem>
        )
      })}
    </HHTextField>
  )
}

BillingPeriodDropdown.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  selectedBillingPeriod: PropTypes.string,
  allBillingPeriods: PropTypes.array,
  onBillingPeriodChange: PropTypes.func.isRequired,
}

export default BillingPeriodDropdown
