import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Skeleton, Box } from '@mui/material'

const IframeSkelton = ({ isCreditCard }) => {
  return (
    <Stack spacing={1}>
      <Skeleton width="100%" height={50} />
      {isCreditCard && (
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Skeleton width="50%" height={50} />
          <Skeleton width="50%" height={50} />
        </Box>
      )}
    </Stack>
  )
}
IframeSkelton.propTypes = {
  isCreditCard: PropTypes.bool.isRequired,
}

export default IframeSkelton
