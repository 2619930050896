import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { SERVICE_GROUP } from 'settings/constants/serviceGroups'

export const isServiceGroup = createSelector(
  state => state.type,
  type => type === SERVICE_GROUP
)

export const getServiceFee = (type, fees) => {
  if (!Array.isArray(fees)) {
    return []
  }

  const priceKey = type === 'General Fee' ? 'value' : 'tiers[0].price'
  return fees.map(fee => ({
    serviceId: fee.serviceId,
    serviceName: fee.serviceName,
    feeTaxId: fee.id,
    feeName: fee.feeName,
    linked: true,
    price: parseFloat(get(fee, priceKey, 0)).toFixed(2),
    parentType: 'Fee',
    type,
    valueType: get(fee, 'valueType', '$'),
  }))
}

// get service tax and get service fee tax
export const getServiceFeeTax = (taxes, inActiveFeeList, appliedOn = 'Service', feeId = null, feeName = '', serviceId, serviceName) => {
  return taxes.map(tax => ({
    serviceId: serviceId || tax.serviceId,
    serviceName: serviceName || tax.serviceName,
    feeTaxId: tax.id,
    feeName: tax.taxName,
    linked: feeId ? !inActiveFeeList.includes(feeId) : true,
    taxZoneId: get(tax, 'taxZoneId', ''),
    price: get(tax, 'taxValue', 0),
    parentType: 'Tax',
    type: 'Tax',
    taxValueType: tax.taxValueType,
    appliedOn,
    feeId,
    appliedOnName: feeName || appliedOn,
    // we can have multiple similiar feeId or taxId which will break select, so adding a unique conbination
    appliedOnId: feeId ? `${feeId}-${tax.id}` : tax.id,
    visible: feeId ? !inActiveFeeList.includes(feeId) : true,
  }))
}

// Fee tab linking change
export const getTaxOnFeeLinking = createSelector(
  [state => state.feeTaxList, state => state.feeId, state => state.serviceId, state => state.value],
  (feeTaxList, feeId, serviceId, value) =>
    feeTaxList.map(feeTax =>
      feeTax.parentType === 'Tax' && feeTax.serviceId === serviceId && feeTax.feeId === feeId
        ? { ...feeTax, visible: value, linked: value }
        : feeTax
    )
)

// Tax tab linking change
export const getTaxLinking = createSelector(
  [state => state.taxZoneId, state => state.feeTaxList, state => state.taxId, state => state.appliedOnId, state => state.serviceId],
  (taxZoneId, feeTaxList, taxId, appliedOnId, serviceId) =>
    feeTaxList.map(feeTax => {
      if (feeTax.serviceId === serviceId && feeTax.feeTaxId === taxId && feeTax.taxZoneId === taxZoneId) {
        return { ...feeTax, linked: appliedOnId.includes(feeTax.appliedOnId) }
      }
      return feeTax
    })
)
