import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBulkAssignFromRouteStopMarkers,
  selectBulkAssignFromRows,
  selectBulkAssignToRouteSequenceLine,
  selectBulkAssignToRouteStopMarkers,
  selectBulkAssignToRows,
  selectIsSequenceDialogOpen,
  setBulkAssignStopsState,
} from 'slices/route/bulkAssignStopsSlice'
import PropTypes from 'prop-types'

import CommonSequenceDialog from 'components/route-manager/BulkActions/common/CommonSequenceDialog'
import useBulkResequenceFunctions from 'components/route-manager/BulkActions/common/useBulkResequenceFunctions'

const SequenceDialog = ({ moveFromGridApiRef, moveToGridApiRef }) => {
  const dispatch = useDispatch()
  const methods = useForm({
    defaultValues: {
      startingSequence: '',
    },
  })
  const { reset } = methods
  const isSequenceDialogOpen = useSelector(selectIsSequenceDialogOpen)
  const moveToRows = useSelector(selectBulkAssignToRows)
  const moveFromRows = useSelector(selectBulkAssignFromRows)
  const moveFromRouteStopMarkers = useSelector(selectBulkAssignFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkAssignToRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkAssignToRouteSequenceLine)

  const onClose = () => {
    dispatch(
      setBulkAssignStopsState({
        isSequenceDialogOpen: false,
      })
    )
  }

  const { onResetSequence, onPreserveSequence } = useBulkResequenceFunctions({
    moveToRows,
    moveFromRows,
    moveFromRouteStopMarkers,
    moveToRouteSequenceLine,
    moveFromGridApiRef,
    moveToGridApiRef,
    moveToRouteStopMarkers,
    reset,
    bulkReduxAction: setBulkAssignStopsState,
  })

  return (
    <FormProvider {...methods}>
      <CommonSequenceDialog
        onResetSequence={onResetSequence}
        onClose={onClose}
        isOpen={isSequenceDialogOpen}
        onPreserveSequence={onPreserveSequence}
      />
    </FormProvider>
  )
}

SequenceDialog.propTypes = {
  moveFromGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  moveToGridApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default SequenceDialog
