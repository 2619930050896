import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'

import { useParams } from 'react-router-dom'
import { Box, Grid, IconButton, ListItem, ListItemText } from '@mui/material'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getPaymentsFromBatch } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import T from 'T'
import GraphCard from '../../common/GraphCard'
import PaymentTypeStatistic from '../PaymentTypeStatistic'
import PaymentTransactionStatistic from '../PaymentTransactionStatistic'
import GraphCardEmptyState from '../../common/GraphCardEmptyState'

const PaymentGroupStatistics = () => {
  const { id } = useParams()
  const [isGraphSectionOpen, setIsGraphSectionOpen] = useState(true)
  const { data } = useGetPaymentGroupDetailsQuery({ id })
  const payments = getPaymentsFromBatch({ paymentBatches: get(data, 'paymentBatches', []) })

  const groupName = get(data, 'groupName', '')
  const createdBy = get(data, 'createdBy')
  const createdAt = get(data, 'createdAt')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const showGraph = payments.length > 0

  const toggleGraphSection = () => setIsGraphSectionOpen(!isGraphSectionOpen)

  return (
    <>
      <Grid item xs={12}>
        <ListItem>
          <IconButton onClick={toggleGraphSection}>{isGraphSectionOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
          <ListItemText
            primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }}
            primary={`${groupName} statistics`}
          />
        </ListItem>
      </Grid>
      {isGraphSectionOpen && (
        <Grid container item spacing={2}>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard
              Icon={CurrencyDollar}
              title={capitalize(T.PAYMENT_TYPE)}
              createdBy={createdBy}
              time={onlyTime}
              date={onlyDate}
              createdAt={createdAt}
            >
              <Box height={250}>
                {showGraph && <PaymentTypeStatistic id={id} showFooter={false} groupedTooltip={false} />}
                {!showGraph && <GraphCardEmptyState title={T.EMPTY_PAYMENT_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard
              Icon={CurrencyDollar}
              title={T.TRANSACTION_STATUS}
              createdBy={createdBy}
              time={onlyTime}
              date={onlyDate}
              createdAt={createdAt}
            >
              <Box height={250}>
                {showGraph && <PaymentTransactionStatistic id={id} showFooter={false} groupedTooltip={false} />}
                {!showGraph && <GraphCardEmptyState title={T.EMPTY_PAYMENT_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PaymentGroupStatistics
