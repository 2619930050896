import React, { useCallback, Children, useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Reply } from '@styled-icons/heroicons-outline/Reply'

import {
  getGeneralValidateFeesServices,
  getReGeneralValidateFeesServices,
  getDisposalValidateFeesServices,
  getReDisposalValidateFeesServices,
  getGeneralFeesCreateServices,
  getGeneralMatchedFeesServices,
  getDisposalFeesCreateServices,
  updateGeneralFeeById,
  updateDisposalFeeById,
} from 'middleware/actions/pricing'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'

import CommonDrawer from 'components/common/CommonDrawer'
import FeesInitialScreen from 'components/pricing/Fees/FeesInitialScreen'
import GeneralFeesFormScreen from 'components/pricing/Fees/GeneralFeesFormScreen'
import DisposalFeesFormScreen from 'components/pricing/Fees/DisposalFeesFormScreen'
import FeesTableScreen from 'components/pricing/Fees/FeesTableScreen'
import ChangePrice from 'components/pricing/ChangePrice'
import ChangeDisposalPrice from 'components/pricing/Fees/ChangeDisposalPrice'
import { ALL_GENERAL_FEES, ALL_DISPOSAL_FEES, GENERAL_FEES, DISPOSAL_FEES } from 'components/pricing/settings'
import { handleSpecificDays } from 'components/pricing/pricingMethods'
import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import { PRICING_CONFIRMATION_MSG } from 'settings/constants/pricing'

import { get } from 'utils/lodash'
import { isTenDigitsOnly } from 'utils/validations'

import Constants from 'Constants'

import T from 'T'

import './style.scss'
import { generateFeeName } from 'utils/pricing'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Divider } from '@mui/material'
import LinkedServicesList from '../LinkedServicesList/LinkedServicesList'

const { MEDIA_SERVER } = Constants

const NewFeeModal = ({
  feeTab,
  openFeeDrawer,
  page,
  rowsPerPage,
  monthlyRevenue,
  setState,
  setOpenFeeDrawer,
  getFeesServicesResults,
  getFeeServiceName,
  feesScreen,
  setFeesScreen,
  selectedEditValues,
  handleChangePriceDelete,
  onHandleEditFieldsChange,
  setSelectedEditValues,
  isDirty,
  confServices,
}) => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState('')
  const [businessLines, setBusinessLines] = useState([])

  const [backFlag, setBackFlag] = useState(false)
  const [response, setResponse] = useState(false)

  const [tierCount, setTierCount] = useState({})

  const [feecounter, setFeeCounter] = useState(1)
  const [recurFeeCounter, setRecurFeeCounter] = useState(1)

  const [feeInput, setFeeInput] = useState({})

  const [checkedRecurValues, setCheckedRecurValues] = useState({})
  const [checkedRequestValues, setCheckedRequestValues] = useState({})

  const [matchedServices, setMatchedServices] = useState([])

  const [inputFeeTextRecurrence, setInputFeeTextRecurrence] = useState({
    feeName: '',
    value: '',
    recurrenceTimes: 1,
    billingPeriods: 1,
    priceLocked: false,
    isBusinessLine: false,
    autoLink: true,
    subItem: true,
    minBillingSwitchVal: false,
    valueType: '%',
    isNameDirty: false,
  })

  const [inputFeeTextOnRequest, setInputFeeTextOnRequest] = useState({
    feeName: '',
    value: '',
    priceLocked: false,
    isBusinessLine: false,
    subItem: true,
    autoLink: true,
    valueType: '%',
    isNameDirty: false,
  })

  const [inputDisposalText, setInputDisposalText] = useState({
    disposalFeeName: '',
    disposalValue: '',
    priceLocked: false,
    isBusinessLine: false,
    autoLink: false,
    costPlus: false,
    disposalMinFee: '',
    disposalOverage: '',
    disposalOverageFee: '',
  })

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const [configData, setConfigdata] = useState([])

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    validatedFees: [],
    duplicateFeeRecords: [],
    combinationFeeRequestCount: 0,
    combinationFeeRecurCount: 0,
    combinationFeeDisposalCount: 0,
    isOpenFeeConfirmationModal: false,
    isMultipleRecordsAltered: false,
  })

  const {
    validatedFees,
    duplicateFeeRecords,
    combinationFeeRequestCount,
    combinationFeeRecurCount,
    combinationFeeDisposalCount,
    isOpenFeeConfirmationModal,
    isMultipleRecordsAltered,
  } = localState

  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    const countValInd = {}
    countValInd['0'] = 0
    setTierCount(countValInd)

    dispatch(getGlobalFilterOptions())
    if (feeInput[tabValue] === undefined) {
      feeInput[tabValue] = ''
    }

    if (feeTab === 'disposal') {
      setConfigdata(ALL_DISPOSAL_FEES)
    } else {
      setConfigdata(ALL_GENERAL_FEES)
    }
  }, [feeTab])

  useEffect(() => {
    setConfigdata(confServices)
  }, [confServices])

  const handleClearConf = () => {
    if (feeTab == 'disposal') {
      setConfigdata(ALL_DISPOSAL_FEES)
    } else {
      setConfigdata(ALL_GENERAL_FEES)
    }
  }

  const handleDelete = (service, type) => {
    const validateFeeRecords = validatedFees.filter(data => data !== service)

    if (type === 'duplicate') {
      setLocalState({
        validatedFees: validateFeeRecords,
        duplicateFeeRecords: duplicateFeeRecords.filter(data => data !== service),
        combinationFeeRecurCount: validateFeeRecords.filter(data => data.feeType === 'Recurring').length,
        combinationFeeRequestCount: validateFeeRecords.filter(data => data.feeType === 'On Request').length,
        combinationFeeDisposalCount: validateFeeRecords.length,
      })
    } else {
      setLocalState({
        validatedFees: validateFeeRecords,
        combinationFeeRecurCount: validateFeeRecords.filter(data => data.serviceType === 'Recurring').length,
        combinationFeeRequestCount: validateFeeRecords.filter(data => data.serviceType === 'On Request').length,
        combinationFeeDisposalCount: validateFeeRecords.length,
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePriceLocked = () => {
    if (tabValue === 'recurring') {
      setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, priceLocked: !inputFeeTextRecurrence.priceLocked })
    } else {
      setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, priceLocked: !inputFeeTextOnRequest.priceLocked })
    }

    setState({ isDirty: true })
  }

  const onFrontScreenChange = (type, value) => {
    if (type === 'businessLine') {
      if (businessLines.includes(value)) {
        let businessLineSelected
        businessLineSelected = businessLines.filter(data => data !== value)
        setBusinessLines(businessLineSelected)
      } else {
        businessLines.push(value)
        setBusinessLines(businessLines)
      }

      setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, priceLocked: inputFeeTextOnRequest.priceLocked })
    }

    setState({ isDirty: true })

    if (type === 'serviceType') setTabValue(value)
  }

  const onHandleChange = useCallback((event, isNameDirty = false) => {
    const { value } = event.target
    const { name } = event.target
    setState({ isDirty: true })

    if (name.includes('disposal')) {
      setInputDisposalText({ ...inputDisposalText, [name]: value })
      return
    }

    tabValue === 'recurring'
      ? setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, [name]: value, isNameDirty })
      : setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, [name]: value, isNameDirty })
  })

  const setFeeCount = type => {
    let value = 0
    if (type === 'sum') {
      if (feecounter < 10) {
        value = feecounter + 1
        setFeeCounter(value)
        if (tabValue === 'recurring') {
          setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, billingPeriods: value })
        } else {
          setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, billingPeriods: value })
        }
      }
    } else if (feecounter > 1) {
      value = feecounter - 1
      setFeeCounter(value)
      if (tabValue === 'recurring') {
        setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, billingPeriods: value })
      } else {
        setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, billingPeriods: value })
      }
    }
  }

  const setInputFeeTextRecur = value => {
    if (tabValue === 'recurring') {
      inputFeeTextRecurrence.recurrenceTimes = value
      setInputFeeTextRecurrence(inputFeeTextRecurrence)
    } else {
      inputFeeTextOnRequest.recurrenceTimes = value
      setInputFeeTextOnRequest(inputFeeTextOnRequest)
    }
  }

  const setFeeRecCount = type => {
    if (type === 'sum') {
      if (recurFeeCounter < 7) {
        setInputFeeTextRecur(recurFeeCounter + 1)
        setRecurFeeCounter(recurFeeCounter + 1)
      }
    } else if (recurFeeCounter > 1) {
      setInputFeeTextRecur(recurFeeCounter - 1)
      setRecurFeeCounter(recurFeeCounter - 1)
    }
  }

  const handleValidFeeTiersMinVal = (validateFees, service, name, value, indx) => {
    if (validateFees.find(data => data === service) === undefined) return
    const validFees = validateFees.find(data => data === service).tiers

    validFees[indx][name] = value

    if (name === 'maxVal' && validFees.length > indx && validFees[indx + 1] !== undefined) {
      validFees[indx + 1].minVal = parseInt(value) // set tiers range
    }

    setLocalState({
      validatedFees: validateFees,
    })
  }

  const handleDupFeeTiersMinVal = (duplicatedFeeRecords, service, name, value, indx) => {
    const dupFees = duplicatedFeeRecords.find(data => data === service).tiers
    dupFees[indx][name] = value

    if (name === 'maxVal' && dupFees.length > indx && dupFees[indx + 1] !== undefined) {
      dupFees[indx + 1].minVal = parseInt(value)
    }

    setLocalState({
      duplicateFeeRecords: duplicatedFeeRecords,
    })
  }

  const onHandleTiersChange = (e, service, indx, type) => {
    const { name, value } = e.target

    if (['minVal', 'maxVal'].includes(name) && !isTenDigitsOnly(value)) return

    if (validatedFees.length > 0) {
      handleValidFeeTiersMinVal(validatedFees, service, name, value, indx)
    }

    if (duplicateFeeRecords.length > 0) {
      handleDupFeeTiersMinVal(duplicateFeeRecords, service, name, value, indx)
    }

    if (name === 'price') {
      setInputDisposalText({ ...inputDisposalText, [getDisposalColumnName(name)]: value })
    }

    if (type === 'edit' && selectedEditValues.length > 0) {
      const editValues = cloneDeep(selectedEditValues)

      const validFees = editValues.find(data => data.id === service.id).tiers

      validFees[indx][name] = value

      if (name === 'maxVal' && validFees.length > indx && validFees[indx + 1] !== undefined) {
        validFees[indx + 1].minVal = parseInt(value) // set tiers range
      }

      setSelectedEditValues(editValues)
    }

    handleDisposalFeeReValidate(name, value, service)
  }

  const handleAddTier = (matchedFee, lastTier, lastVal) => {
    if (
      (matchedFee.tiers.length > 1 && lastTier.price !== '' && matchedFee.tiers[matchedFee.tiers.length - 2].maxVal !== '') ||
      (matchedFee.tiers.length === 1 && lastTier.maxVal !== 0)
    ) {
      matchedFee.tiers.push(lastVal)
    }
    return matchedFee
  }

  const handleRemoveTier = (matchedFee, indx) => {
    matchedFee.tiers.splice(indx, 1)

    if (matchedFee.tiers.length > 0) {
      matchedFee.tiers[matchedFee.tiers.length - 1].maxVal = ''
    }

    if (matchedFee.tiers.length > 1) {
      matchedFee.tiers.forEach((data, index) => {
        if (index > 0) {
          data.minVal = matchedFee.tiers[index - 1].maxVal
        }
      })
    }

    return matchedFee
  }

  const onHandleMeasureChange = (e, fee, type) => {
    if (type === 'edit') {
      const editValues = cloneDeep(selectedEditValues)

      editValues.find(data => data.id === fee.id).measureType = e.target.value

      setSelectedEditValues(editValues)
    }

    if (validatedFees.length > 0) {
      validatedFees.find(data => data === fee).measureType = e.target.value

      setLocalState({
        validatedFees,
      })
    }

    if (duplicateFeeRecords.length > 0) {
      duplicateFeeRecords.find(data => data === fee).measureType = e.target.value

      setLocalState({
        duplicateFeeRecords,
      })
    }

    handleDisposalFeeReValidate('measureType', e.target.value, fee)
  }

  const getMinVal = (lastTier, matchedFee) => {
    let tierVal = ''
    if (lastTier.maxVal !== '') {
      tierVal = parseInt(lastTier.maxVal)
    }

    if (matchedFee.tiers.length > 0 && lastTier.maxVal === 0) {
      tierVal = ''
    }

    return tierVal
  }

  const handleTiers = (type, fee, indx, ind, editType) => {
    let matchedFee

    if (validatedFees.length > 0) {
      matchedFee = validatedFees.find(data => data === fee)
    }

    if (duplicateFeeRecords.length > 0) {
      matchedFee = duplicateFeeRecords.find(data => data === fee)
    }

    if (editType === 'edit' && selectedEditValues.length > 0) {
      matchedFee = compact(selectedEditValues).find(data => data.id === fee.id)
    }

    if (tierCount[ind] === undefined) {
      tierCount[ind] = 0
    }

    tierCount[ind] = tierCount[ind] + 1
    setTierCount(tierCount)

    if (tierCount[ind] > 0) {
      const lastTier = matchedFee.tiers[matchedFee.tiers.length - 1]
      const lastVal = Object.create(lastTier)
      const allKeys = Object.keys(lastTier)

      Children.toArray(allKeys.map(key => (lastVal[key] = lastTier[key])))

      lastVal.maxVal = ''
      lastVal.price = ''
      lastVal.minVal = ''

      if (editType === 'edit' && matchedFee.tiers.filter(data => data.id === lastVal.id).length > 0) {
        lastVal.id = null
      }

      lastVal.minVal = getMinVal(lastTier, matchedFee)

      if (type === 'add') {
        handleAddTier(matchedFee, lastTier, lastVal)
      }

      if (type === 'remove') {
        handleRemoveTier(matchedFee, indx)
      }
    }
    setResponse(!response)
  }

  const handleGeneralFeeReValidate = (name, value, service) => {
    const payload = {
      active: get(service, 'active', false),
      autoLink: get(service, 'autoLink', false),
      businessLine: get(service, 'businessLine', ''),
      days: get(service, 'days', []),
      exist: get(service, 'exist', false),
      feeException: get(service, 'feeException', ''),
      feeName: name === 'feeName' ? value : get(service, 'feeName', ''),
      feeType: get(service, 'feeType', 'On Request'),
      linked: get(service, 'linked', false),
      locked: get(service, 'locked', false),
      material: { id: get(service, 'material', { id: null }).id },
      measure: { id: get(service, 'measure', { id: null }).id },
      minBillingPeriod: get(service, 'minBillingPeriod', 0),
      minBillingPeriodBoolean: get(service, 'minBillingPeriodBoolean', false),
      pricingPeriod: { id: get(service, 'pricingPeriod', { id: null }).id },
      pricingZone: { id: get(service, 'pricingZone', { id: null }).id },
      recurrence: { id: get(service, 'recurrence', { id: null }).id },
      recurrenceFrequency: get(service, 'recurrenceFrequency', 1),
      serviceAction: { id: get(service, 'serviceAction', { id: null }).id },
      serviceMethod: { id: get(service, 'serviceMethod', { id: null }).id },
      services: get(service, 'services', [{ id: null }]).map(data => ({ id: data.id })),
      specificDays: get(service, 'specificDays', ''),
      subItem: get(service, 'subItem', true),
      value: name === 'value' ? value.toString().replaceAll(',', '') : get(service, 'value', '').toString().replaceAll(',', ''),
      valueType: get(service, 'valueType', '%'),
      generalLedgerAccountId: get(service, 'generalLedgerAccountId', null),
    }

    dispatch(
      getReGeneralValidateFeesServices(payload, res => {
        service.exist = res.exist
        const allGeneralFeeRecords = validatedFees.concat(duplicateFeeRecords)
        const validFeesData = allGeneralFeeRecords.filter(data => !data.exist)

        setLocalState({
          validatedFees: validFeesData,
          duplicateFeeRecords: allGeneralFeeRecords.filter(data => data.exist),
        })

        if (tabValue === 'recurring') {
          setLocalState({
            combinationFeeRecurCount: validFeesData.filter(data => data.serviceType === 'Recurring').length,
          })
        } else {
          setLocalState({
            combinationFeeRequestCount: validFeesData.filter(data => data.serviceType === 'On Request').length,
          })
        }
      })
    )
  }

  const handleDisposalFeeReValidate = (name, value, service) => {
    const payload = {
      active: get(service, 'active', false),
      autoLink: get(service, 'autoLink', false),
      businessLine: get(service, 'businessLine', ''),
      costPlus: name === 'costPlus' ? value : get(service, 'costPlus', false),
      days: get(service, 'days', ''),
      exist: get(service, 'exist', false),
      feeName: name === 'feeName' ? value : get(service, 'feeName', ''),
      linked: get(service, 'linked', false),
      measureType: get(service, 'measureType', ''),
      material: { id: get(service, 'material', { id: null }).id },
      minFee: name === 'minFee' ? value.toString().replaceAll(',', '') : get(service, 'minFee', '').toString().replaceAll(',', ''),
      overage: get(service, 'overage', false),
      overageFee:
        name === 'overageFee' ? value.toString().replaceAll(',', '') : get(service, 'overageFee', '').toString().replaceAll(',', ''),
      overageThreshold:
        name === 'overageThreshold'
          ? value.toString().replaceAll(',', '')
          : get(service, 'overageThreshold', '').toString().replaceAll(',', ''),
      serviceAction: { id: get(service, 'serviceAction', { id: null }).id },
      serviceMethod: { id: get(service, 'serviceMethod', { id: null }).id },
      pricingZone: { id: get(service, 'pricingZone', { id: null }).id },
      recurrence: { id: get(service, 'recurrence', { id: null }).id },
      services: get(service, 'services', [{ id: null }]).map(data => ({ id: data.id })),
      specificDays: get(service, 'specificDays', []),
      tiers: get(service, 'tiers', [])
        .filter(tier => tier.minVal !== '')
        .filter(tierVal => (tierVal.price = tierVal.price.toString().replaceAll(',', ''))),
      generalLedgerAccountId: get(service, 'generalLedgerAccountId', null),
    }

    dispatch(
      getReDisposalValidateFeesServices(payload, res => {
        service.exist = res.exist
        const combinedRecords = validatedFees.concat(duplicateFeeRecords)
        const measureValues = uniq(combinedRecords.map(data => data.measureType))

        const measures = measureValues.map(data => ({ id: data, label: data }))

        const checkedValues = getCheckedValues()
        checkedValues.measures = measures

        setCheckedRequestValues({ ...checkedValues })

        const validFeesData = combinedRecords.filter(data => !data.exist)

        setLocalState({
          validatedFees: validFeesData,
          duplicateFeeRecords: combinedRecords.filter(data => data.exist),
          combinationFeeDisposalCount: validFeesData.length,
        })
      })
    )
  }

  const getDisposalColumnName = type => {
    switch (type) {
      case 'feeName':
        return 'disposalFeeName'
      case 'value':
      case 'price':
        return 'disposalValue'
      case 'minFee':
        return 'disposalMinFee'
      case 'overageThreshold':
        return 'disposalOverage'
      case 'overageFee':
        return 'disposalOverageFee'
      default:
    }
  }

  const handleRecordsAlteration = (e, name, value) => {
    if (validatedFees.concat(duplicateFeeRecords).length === 1) {
      feeTab === 'general' ? onHandleChange(e) : setInputDisposalText({ ...inputDisposalText, [getDisposalColumnName(name)]: value })
    }
  }

  const onHandleValidatedFeeFieldsChange = (e, service) => {
    let { name, value } = e.target

    if (name === 'overage') {
      name = 'overageThreshold'
    }

    if (name === 'disposalFeeName') {
      name = 'feeName'
    }

    if (!isMultipleRecordsAltered) {
      setLocalState({
        isMultipleRecordsAltered: true,
      })
    }

    handleRecordsAlteration(e, name, value)

    if (feeTab === 'general') {
      handleGeneralFeeReValidate(name, value, service)
    } else {
      handleDisposalFeeReValidate(name, value, service)
    }

    if (validatedFees.length > 0) {
      const currentRecord = validatedFees.find(data => data === service)

      if (currentRecord) currentRecord[name] = value

      setLocalState({
        validatedFees,
      })
    }

    if (duplicateFeeRecords.length > 0) {
      const currentRecord = duplicateFeeRecords.find(data => data === service)

      if (currentRecord) currentRecord[name] = value

      setLocalState({
        duplicateFeeRecords,
      })
    }
  }

  const getFeeBtnText = () => {
    return `${feeTab === 'general' ? T.CREATE_FEE : T.CREATE_DISPOSAL_FEE}`
  }

  const handleButtonText = () => {
    let count = 0
    let duplicate = 0
    let combinationCount = 0

    if (tabValue === 'recurring') {
      combinationCount = combinationFeeRecurCount
      duplicate = duplicateFeeRecords.filter(data => data.serviceType === 'Recurring')
    } else if (tabValue === 'on request' || tabValue === '') {
      combinationCount = combinationFeeRequestCount
      duplicate = duplicateFeeRecords.filter(data => data.serviceType === 'On Request')
    }

    if (feeTab === 'disposal') {
      combinationCount = combinationFeeDisposalCount
      duplicate = duplicateFeeRecords
    }

    if (feesScreen === 'fees-form') {
      selectedEditValues = 0
      count = duplicate.length + combinationCount
    } else if (feesScreen === 'fees-table' && combinationCount > 0) {
      return `${getFeeBtnText()} (${combinationCount})`
    }
    if (feesScreen === 'initial') {
      return `${T.CONTINUE}`
    }
    if (count > 0) {
      return `${getFeeBtnText()} (${count})`
    }
    if (feesScreen === 'edit-fees') {
      return `${T.SAVE_CHANGES} (${selectedEditValues.filter(v => v !== undefined).length})`
    }
    if (feesScreen === 'edit-fees') {
      return `${T.SAVE_CHANGES}`
    }

    if (feesScreen === 'linked-fee-drawer') {
      return `${T.SAVE}`
    }

    return feeTab === 'general' ? T.CREATE_FEE : T.CREATE_DISPOSAL_FEE
  }

  const getInputdata = () => {
    if (tabValue === 'recurring') return inputFeeTextRecurrence

    return inputFeeTextOnRequest
  }

  const getFeeName = () => getInputdata().feeName

  const handleFeeDisabled = () => {
    let checkedValues
    if (tabValue === 'recurring') checkedValues = checkedRecurValues
    else checkedValues = checkedRequestValues

    if (feesScreen === 'fees-form' && feeTab === 'general') {
      return !(getFeeName() !== '' && Object.keys(checkedValues).includes('pricingPeriod') && checkedValues.pricingPeriod.length > 0)
    }
    if (feesScreen === 'fees-table') {
      const hasInvalidName = validatedFees.find(({ feeName }) => feeName?.trim() === '') !== undefined
      return validatedFees.length === 0 || hasInvalidName
    }
    if (feeTab === 'disposal' && feesScreen === 'fees-form') {
      return (feeInput[''] === '' && inputDisposalText.disposalFeeName === '') || get(checkedValues, 'measures', []).length === 0
    }
    if (feesScreen === 'edit-fees') {
      return !!selectedEditValues.filter(Boolean).find(({ feeName }) => feeName.trim() === '')
    }

    return false
  }

  const handleFeesCancel = () => {
    setCheckedRecurValues({})
    setCheckedRequestValues({})
    setInputFeeTextRecurrence({
      feeName: '',
      value: '',
      recurrenceTimes: 1,
      billingPeriods: 1,
      priceLocked: false,
      isBusinessLine: false,
      autoLink: true,
      subItem: true,
      minBillingSwitchVal: false,
      valueType: '%',
    })

    setInputFeeTextOnRequest({
      feeName: '',
      value: '',
      priceLocked: false,
      isBusinessLine: false,
      subItem: true,
      autoLink: true,
      valueType: '%',
    })

    setInputDisposalText({
      disposalFeeName: '',
      disposalValue: '',
      priceLocked: false,
      isBusinessLine: false,
      autoLink: false,
      costPlus: false,
      disposalMinFee: '',
      disposalOverage: '',
      disposalOverageFee: '',
    })

    setFeeInput({})
    setTabValue('')
    setBusinessLines([])

    setLocalState({
      validatedFees: [],
      duplicateFeeRecords: [],
      combinationFeeRequestCount: 0,
      combinationFeeRecurCount: 0,
      combinationFeeDisposalCount: 0,
      isOpenFeeConfirmationModal: false,
      isMultipleRecordsAltered: false,
    })

    setOpenFeeDrawer(false)

    if (feeTab === 'general') {
      setState({
        configuratorColumns: cloneDeep(GENERAL_FEES),
        pastConfiguratorColumns: cloneDeep(GENERAL_FEES),
      })
    } else {
      setState({
        configuratorColumns: cloneDeep(DISPOSAL_FEES),
        pastConfiguratorColumns: cloneDeep(DISPOSAL_FEES),
      })
    }

    setState({ isDirty: false })
  }

  const getBusinessIds = () => {
    const businessLineMeta = get(globalFilterOptions, 'businessLines', [])

    let businessLineRecord = []

    if (businessLineMeta.length > 0) businessLineRecord = businessLineMeta.filter(data => businessLines.includes(data.businessLineLabel))

    const businessIds = []

    Children.toArray(
      businessLineRecord.length > 0 &&
        businessLineRecord.map((data, index) => {
          businessIds.push({ id: data.id })
        })
    )
    return businessIds
  }

  const handleLinkedServices = () => {
    const inputData = getInputdata()

    let checkedValues = checkedRequestValues

    if (tabValue === 'recurring') {
      checkedValues = checkedRecurValues
    }

    const dayData = get(checkedValues, 'noSpecificDay', [])

    const payload = {
      search: '',
    }

    if (inputData.minBillingSwitchVal) {
      payload.minBillingPeriod = inputData.billingPeriods
    }

    const actions = get(checkedValues, 'actions', [])
    const measure = get(checkedValues, 'measures', [])
    const method = get(checkedValues, 'methods', [])
    const material = get(checkedValues, 'materials', [])
    const pricingPeriod = get(checkedValues, 'pricingPeriod', [])
    const zone = get(checkedValues, 'pricingZones', [])
    const recurrence = get(checkedValues, 'recurrence', [])
    const days = dayData.length > 0 ? dayData.map(data => data.label) : []

    if (actions.length > 0) {
      payload.action = actions.map(data => data.id)
    }
    if (measure.length > 0) {
      payload.measure = measure.map(data => data.id)
    }
    if (method.length > 0) {
      payload.method = method.map(data => data.id)
    }
    if (material.length > 0) {
      payload.material = material.map(data => data.id)
    }
    if (pricingPeriod.length > 0) {
      payload.pricingPeriod = pricingPeriod.map(data => data.id)
    }
    if (zone.length > 0) {
      payload.zone = zone.map(data => data.id)
    }
    if (recurrence.length > 0) {
      payload.recurrence = recurrence.map(data => data.id)
    }
    if (days.length > 0) {
      payload.days = days.join(',')
    }
    if (businessLines.length > 0) {
      payload.businessLine = businessLines
    }

    if (feeTab === 'general') {
      dispatch(
        getGeneralMatchedFeesServices(payload, res => {
          setMatchedServices(Array.isArray(res.data) ? res.data : [])
        })
      )
    }
  }

  const setFeeConfiguratorColumns = type => {
    if (type === 'submit') {
      setState({
        configuratorColumns: cloneDeep(ALL_GENERAL_FEES),
        pastConfiguratorColumns: cloneDeep(ALL_GENERAL_FEES),
      })
    }
  }

  const getMatchedServices = inputData => {
    if (get(inputData, 'autoLink')) {
      return matchedServices
    }
    return []
  }

  const handleGeneralFeeValidate = (values, type) => {
    const dayData = get(values, 'specificDays', [])

    const inputData = getInputdata()

    let title = inputData.feeName

    if (title === '' || title === undefined) title = feeInput[tabValue]

    const payload = {
      feeName: title.trim(),
      feeType: getFeeType(),
      value: get(inputData, 'value', '0').toString().replaceAll(',', ''),
      valueType: inputData.valueType,
      minBillingPeriod: inputData.minBillingSwitchVal ? inputData.billingPeriods : 0,
      minBillingPeriodBoolean: inputData.minBillingSwitchVal,
      autoLink: inputData.autoLink,
      subItem: inputData.subItem,
      action: get(values, 'actions', []),
      businessLine: getBusinessIds(),
      measure: get(values, 'measures', []),
      method: get(values, 'methods', []),
      material: get(values, 'materials', []),
      pricingPeriod: get(values, 'pricingPeriod', []),
      zone: get(values, 'pricingZones', []),
      recurrence: get(values, 'recurrence', []),
      exceptionType: get(values, 'exceptions', []).map(data => ({ value: data.label })),
      services: getMatchedServices(inputData),
      recurrenceFrequency: inputData.recurrenceTimes,
      days: dayData.length > 0 ? dayData.map(data => data.label) : [],
      isLocked: inputData.priceLocked,
      generalLedgerAccountId: get(values, 'generalLedgerAccount[0]', {}).id,
    }

    setFeeConfiguratorColumns(type)

    if (Object.keys(values).includes('businessLine')) {
      if (tabValue === 'recurring') setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, isBusinessLine: true })
      else setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, isBusinessLine: true })
    }

    dispatch(
      getGeneralValidateFeesServices(payload, res => {
        const validData = res.generalFees.filter(data => !data.exist)

        setLocalState({
          validatedFees: validData,
          duplicateFeeRecords: res.generalFees.filter(data => data.exist),
        })

        if (type === 'submit') setFeesScreen('fees-table')

        if (tabValue === 'recurring') {
          setLocalState({
            combinationFeeRecurCount: validData.filter(data => data.feeType === 'Recurring').length,
          })
        } else {
          setLocalState({
            combinationFeeRequestCount: validData.filter(data => data.feeType === 'On Request').length,
          })
        }

        if (type !== 'submit') {
          handleLinkedServices()
        }
      })
    )
  }

  const handleDisposalFeeValidate = (values, type) => {
    const dayData = get(values, 'specificDays', [])

    let title = inputDisposalText.disposalFeeName

    if (title === '') title = feeInput[tabValue]

    const payload = {
      feeName: title.trim(),
      price: inputDisposalText.disposalValue.toString().replaceAll(',', ''),
      autoLink: inputDisposalText.autoLink,
      costPlus: inputDisposalText.costPlus,
      minFee: inputDisposalText.disposalMinFee.toString().replaceAll(',', ''),
      overage: inputDisposalText.disposalOverage.toString().replaceAll(',', ''),
      overageFee: inputDisposalText.disposalOverageFee.toString().replaceAll(',', ''),
      zone: get(values, 'pricingZones', []),
      action: get(values, 'actions', []),
      businessLine: getBusinessIds(),
      measure: get(values, 'measures', []).map(data => ({ value: data.label })),
      method: get(values, 'methods', []),
      material: get(values, 'materials', []),
      recurrence: get(values, 'recurrence', []),
      days: dayData.length > 0 ? dayData.map(data => data.label) : [],
      services: get(values, 'linkedServices', []),
      generalLedgerAccountId: get(values, 'generalLedgerAccount[0]', {}).id,
    }

    if (type === 'submit') {
      setState({
        configuratorColumns: cloneDeep(ALL_DISPOSAL_FEES),
        pastConfiguratorColumns: cloneDeep(ALL_DISPOSAL_FEES),
      })
    }

    if (Object.keys(values).includes('businessLine')) setInputDisposalText({ ...inputDisposalText, isBusinessLine: true })

    dispatch(
      getDisposalValidateFeesServices(payload, res => {
        const validData = res.disposalFees.filter(data => !data.exist)

        setLocalState({
          validatedFees: validData,
          duplicateFeeRecords: res.disposalFees.filter(data => data.exist),
        })

        if (type === 'submit') setFeesScreen('fees-table')

        setLocalState({
          combinationFeeDisposalCount: validData.length,
        })
      })
    )
  }

  const handleFeesValidate = (values, type) => {
    if (feeTab === 'general') handleGeneralFeeValidate(values, type)
    else handleDisposalFeeValidate(values, type)
  }

  const handleGeneralFeeCreate = () => {
    validatedFees.forEach(val => {
      val.value = val.value.toString().replaceAll(',', '')
      val.services = matchedServices
    })

    const payload = {
      pricedGeneralFee: validatedFees,
    }

    setState({
      configuratorColumns: cloneDeep(ALL_GENERAL_FEES),
      pastConfiguratorColumns: cloneDeep(ALL_GENERAL_FEES),
    })

    dispatch(
      getGeneralFeesCreateServices(payload, res => {
        if (res.status === 200) {
          getFeesServicesResults(page, rowsPerPage, feeTab)
          setOpenFeeDrawer(false)

          setCheckedRecurValues({})
          setCheckedRequestValues({})
          setTabValue('')
          setBusinessLines([])
          setInputFeeTextRecurrence({
            feeName: '',
            value: '',
            recurrenceTimes: 1,
            billingPeriods: 1,
            priceLocked: false,
            isBusinessLine: false,
            autoLink: true,
            subItem: true,
            minBillingSwitchVal: false,
            valueType: '%',
            isNameDirty: false,
          })
          setInputFeeTextOnRequest({
            feeName: '',
            value: '',
            priceLocked: false,
            isBusinessLine: false,
            autoLink: true,
            subItem: true,
            valueType: '%',
            isNameDirty: false,
          })

          setBusinessLines([])
          setFeesScreen('initial')

          setState({
            configuratorColumns: cloneDeep(GENERAL_FEES),
            pastConfiguratorColumns: cloneDeep(GENERAL_FEES),
          })
        }
      })
    )
  }

  const handleDisposalFeeCreate = () => {
    validatedFees.forEach(val => {
      val.tiers = get(val, 'tiers', [])
        .filter(tier => tier.minVal !== '')
        .filter(tierVal => (tierVal.price = tierVal.price.toString().replaceAll(',', '')))
      val.overageFee = get(val, 'overageFee', '').toString().replaceAll(',', '')
      val.overageThreshold = get(val, 'overageThreshold', '').toString().replaceAll(',', '')
      val.minFee = get(val, 'minFee', '').toString().replaceAll(',', '')
    })

    const payload = {
      pricedDisposalFee: validatedFees,
    }

    dispatch(
      getDisposalFeesCreateServices(payload, res => {
        if (res.status === 200) {
          getFeesServicesResults(page, rowsPerPage, feeTab)
          setOpenFeeDrawer(false)

          setTabValue('')

          setInputDisposalText({
            disposalFeeName: '',
            disposalValue: '',
            priceLocked: false,
            isBusinessLine: false,
            autoLink: false,
            costPlus: false,
            disposalMinFee: '',
            disposalOverage: '',
            disposalOverageFee: '',
          })

          setCheckedRequestValues({})
          setBusinessLines([])
          setFeesScreen('initial')
          setBusinessLines([])

          setState({
            configuratorColumns: cloneDeep(DISPOSAL_FEES),
            pastConfiguratorColumns: cloneDeep(DISPOSAL_FEES),
          })
        }
      })
    )
  }

  const handleCreateFees = () => {
    if (feeTab === 'general') {
      handleGeneralFeeCreate()
    } else {
      handleDisposalFeeCreate()
    }
  }

  const handleProceed = () => {
    let checkedValues = checkedRequestValues

    if (tabValue === 'recurring') {
      checkedValues = checkedRecurValues
    }

    switch (feesScreen) {
      case 'initial':
        if (businessLines.length > 0) {
          const payload = {
            businessLine: businessLines,
          }

          dispatch(getGlobalFilterOptions(payload))
        } else {
          dispatch(getGlobalFilterOptions())
        }

        if (businessLines.length === 1) setLocalState({ feesInput: businessLines[0] })

        getDefaultFeeTitle()

        setFeesScreen('fees-form')

        handleLinkedServices()

        document.getElementsByClassName('tabpanel')[0].scrollTop = 0

        return

      case 'fees-form':
        handleFeesValidate(checkedValues, 'submit')
        return
      case 'fees-table':
        handleCreateFees()
        return
      case 'edit-fees':
        handleUpdateFees()
        return
      case 'linked-fee-drawer':
        setFeesScreen('fees-form')

      default:
    }
  }

  const handleUpdateAfterScreen = res => {
    if (res && res.status === 200) {
      getFeesServicesResults(page, rowsPerPage, feeTab)
      setOpenFeeDrawer(false)
      setFeesScreen('initial')
    }
  }

  const handleUpdateFees = type => {
    if (feeTab === 'disposal') {
      selectedEditValues.forEach(val => {
        val.tiers = get(val, 'tiers', []).filter(tier => tier.minVal !== '')
      })
    }
    const payload = {
      fee: selectedEditValues,
    }

    if (feeTab === 'general') {
      dispatch(
        updateGeneralFeeById(payload, res => {
          handleUpdateAfterScreen(res)
        })
      )
    } else {
      dispatch(
        updateDisposalFeeById(payload, res => {
          handleUpdateAfterScreen(res)
        })
      )
    }
  }

  const handleFeesBackBtn = () => {
    let checkedValues = checkedRequestValues

    if (tabValue === 'recurring') {
      checkedValues = checkedRecurValues
    }

    if (feesScreen === 'fees-table') {
      if (isMultipleRecordsAltered) {
        setLocalState({ isOpenFeeConfirmationModal: true })
        return
      }
      setFeesScreen('fees-form')

      handleFeesValidate(checkedValues, 'auto')
    } else if (feesScreen === 'fees-form') {
      setFeesScreen('initial')
    } else {
      setOpenFeeDrawer(false)
    }
    handleClearConf()
    setBackFlag(true)
  }

  const getDefaultFeeTitle = () => {
    let checkedValues = checkedRequestValues

    if (tabValue === 'recurring') {
      checkedValues = checkedRecurValues
    }

    const businessLine = businessLines && businessLines.length === 1 && businessLines
    const prizingZone = checkedValues.pricingZones && checkedValues.pricingZones.length === 1 && checkedValues.pricingZones
    const exceptions = checkedValues.exceptions && checkedValues.exceptions.length === 1 && checkedValues.exceptions
    const measures = checkedValues.measures && checkedValues.measures.length === 1 && checkedValues.measures
    const material = checkedValues.materials && checkedValues.materials.length === 1 && checkedValues.materials

    let businessLineLabel = get(businessLine, '[0]', '')
    const prizingZoneLabel = get(prizingZone, '[0]label', '')
    const exceptionsLabel = get(exceptions, '[0]value', '')
    const measuresLabel = get(measures, '[0]label', '')
    const materialLabel = get(material, '[0]label', '')

    if (feeTab === 'disposal') {
      businessLineLabel = ''
    }

    let feeVal = ''

    if (businessLineLabel != '') {
      feeVal += ` ${businessLineLabel}`
    }

    if (prizingZoneLabel != '') {
      feeVal += ` ${prizingZoneLabel}`
    }

    if (exceptionsLabel != '') {
      feeVal += ` ${exceptionsLabel}`
    }

    if (measuresLabel != '') {
      feeVal += ` ${measuresLabel}`
    }

    if (materialLabel != '') {
      feeVal += ` ${materialLabel}`
    }

    feeInput[tabValue] = feeVal.trim()

    setFeeInput(feeInput)
  }

  const handleCheckboxValue = (label, records, event) => {
    const checkedValues = getCheckedValues()

    const category = camelCase(label.replace(' ', ''))
    checkedValues[category] = []

    if (label === 'Business Line') {
      checkedValues[category] = [
        {
          id: records.id,
          label: records.businessLineLabel,
        },
      ]
    } else if (label === 'Specific Days') {
      checkedValues[category] = handleSpecificDays(records, checkedValues[category], label, event)
    } else if ((label === 'Pricing Period' || label === `${T.GENERAL_LEDGER} ${T.ACCOUNT}`) && records !== null) {
      checkedValues[category] = [
        {
          id: records.id,
          label: `${records.periodPer} ${records.periodInterval}`,
        },
      ]
    } else {
      records &&
        Children.toArray(
          records.forEach(data => {
            checkedValues[category].push({ id: data.id, label: getByLabelText(data, label) })
          })
        )
    }
    if (tabValue === 'recurring') {
      setCheckedRecurValues({ ...checkedValues })
    } else {
      setCheckedRequestValues({ ...checkedValues })
    }

    getDefaultFeeTitle()

    setState({ isDirty: true })

    handleFeesValidate(checkedValues, 'onChange')
  }

  const handleSwitchVal = (scope, value) => {
    let setData = setInputFeeTextOnRequest
    let data = inputFeeTextOnRequest

    if (tabValue === 'recurring') {
      setData = setInputFeeTextRecurrence
      data = inputFeeTextRecurrence
    }

    setState({ isDirty: true })

    if (scope === 'subItem') {
      setData({ ...data, subItem: value, autoLink: value })
    } else if (scope === 'billingPeriod') {
      setData({ ...data, minBillingSwitchVal: value })
    } else if (scope === 'autoLink') {
      setData({ ...data, autoLink: value })
    }
  }

  const handleDisposalSwitchVal = (scope, value) => {
    setState({ isDirty: true })
    if (scope === 'costPlus') setInputDisposalText({ ...inputDisposalText, costPlus: value })
    else if (scope === 'autoLink') setInputDisposalText({ ...inputDisposalText, autoLink: value })
  }

  const onValueTypeChange = value => {
    setState({ isDirty: true })
    if (tabValue === 'recurring') {
      setInputFeeTextRecurrence({
        ...inputFeeTextRecurrence,
        valueType: value,
        autoLink: inputFeeTextRecurrence.autoLink,
        subItem: inputFeeTextRecurrence.subItem,
      })
    } else {
      setInputFeeTextOnRequest({
        ...inputFeeTextOnRequest,
        valueType: value,
        autoLink: inputFeeTextOnRequest.autoLink,
        subItem: inputFeeTextOnRequest.subItem,
      })
    }
  }

  const onTableFeeScopeChange = (value, fee, type) => {
    setState({ isDirty: true })

    setInputDisposalText({ ...inputDisposalText, [type]: value })
    if (validatedFees.length > 0) {
      const validatedFeeService = validatedFees.find(data => data === fee)

      if (validatedFeeService !== undefined) {
        validatedFeeService[type] = value
      }
    }

    if (duplicateFeeRecords.length > 0) {
      const duplicateFeeService = duplicateFeeRecords.find(data => data === fee)

      if (duplicateFeeService !== undefined) {
        duplicateFeeService[type] = value
      }
    }

    setLocalState({
      validatedFees,
      duplicateFeeRecords,
    })

    if (feeTab === 'general') {
      handleGeneralFeeReValidate(type, value, fee)
      onValueTypeChange(value)
    } else {
      handleDisposalFeeReValidate(type, value, fee)
    }
  }

  const getCheckedValues = () => {
    if (tabValue === 'recurring') {
      return checkedRecurValues
    }
    return checkedRequestValues
  }

  const getFeeType = () => {
    if (tabValue === 'recurring') return 'Recurring'

    return 'On Request'
  }

  const generalFeeTitle = () => {
    if (feesScreen !== 'linked-fee-drawer') {
      return feesScreen === 'edit-fees' ? T.CHANGE_GENERAL_FEE : T.NEW_FEE
    }
    return `${T.AUTO_LINK}ed ${T.SERVICE}`
  }

  const disposalFeeTitle = () => {
    return feesScreen === 'edit-fees' ? T.CHANGE_DISPOSAL_FEE : T.DISPOSAL_FEE
  }

  const getTitle = () => {
    if (feeTab === 'general') {
      return generalFeeTitle()
    }

    if (feeTab === 'disposal') {
      return disposalFeeTitle()
    }
  }

  const getTitleExt = () => {
    if (['fees-form', 'fees-table'].includes(feesScreen) && businessLines.length > 0) {
      return feeTab === 'general' && tabValue ? `: ${businessLines.join(', ')} ${startCase(tabValue)}` : `: ${businessLines.join(', ')}`
    }
  }

  useEffect(() => {
    if (tabValue === 'recurring' && inputFeeTextRecurrence.isNameDirty) return
    if (tabValue !== 'recurring' && inputFeeTextOnRequest.isNameDirty) return
    const feeName = generateFeeName({
      businessLine: get(businessLines, '[0]', ''),
    })
    if (tabValue === 'recurring') {
      setInputFeeTextRecurrence({ ...inputFeeTextRecurrence, feeName })
    } else {
      setInputFeeTextOnRequest({ ...inputFeeTextOnRequest, feeName })
    }
  }, [businessLines, feesScreen])

  return (
    <div>
      <CommonDrawer
        isOpen={openFeeDrawer}
        isDirty={isDirty}
        className={`add-fee-drawer ${feesScreen} ${feeTab}`}
        onChange={(event, isOpen) => handleFeesCancel()}
      >
        <section>
          <HHDialogTitle
            icon={
              feesScreen !== 'linked-fee-drawer' && (
                <img
                  className="header-icon"
                  src={feesScreen === 'edit-fees' ? `${MEDIA_SERVER}SwitchVertical.svg` : `${MEDIA_SERVER}ClipboardList.svg`}
                  alt="fee-icon"
                />
              )
            }
            TitleTypographyProps={{ variant: 'h6' }}
            title={
              <>
                {getTitle()}
                {getTitleExt()}
              </>
            }
            onClose={() => handleFeesCancel()}
          />
          <Divider />
          <div className="content">
            <div className="tabpanel transparent-scroll">
              {feesScreen === 'initial' && (
                <FeesInitialScreen
                  feeTab={feeTab}
                  businessLines={businessLines}
                  tabValue={tabValue}
                  onFrontScreenChange={onFrontScreenChange}
                />
              )}

              {feesScreen === 'fees-form' && feeTab === 'general' && (
                <GeneralFeesFormScreen
                  tabValue={tabValue}
                  matchedServices={matchedServices}
                  globalFilterOptions={globalFilterOptions}
                  handleCheckboxValue={handleCheckboxValue}
                  onHandleChange={onHandleChange}
                  inputFeeText={getInputdata()}
                  feeInput={feeInput}
                  counter={feecounter}
                  recurCounter={recurFeeCounter}
                  setCount={setFeeCount}
                  setRecCount={setFeeRecCount}
                  handlePriceLocked={handlePriceLocked}
                  handleSwitchVal={handleSwitchVal}
                  onValueTypeChange={onValueTypeChange}
                  backFlag={backFlag}
                  checkedValues={getCheckedValues()}
                  handleLinkedServices={handleLinkedServices}
                  handleAutoLinkedServices={() => {
                    setFeesScreen('linked-fee-drawer')
                    document.getElementsByClassName('tabpanel')[0].scrollTop = 0
                  }}
                />
              )}

              {feesScreen === 'fees-form' && feeTab === 'disposal' && (
                <DisposalFeesFormScreen
                  tabValue={tabValue}
                  globalFilterOptions={globalFilterOptions}
                  handleCheckboxValue={handleCheckboxValue}
                  onHandleChange={onHandleChange}
                  inputDisposalText={inputDisposalText}
                  feeInput={feeInput}
                  handleDisposalSwitchVal={handleDisposalSwitchVal}
                  backFlag={backFlag}
                  checkedValues={getCheckedValues()}
                />
              )}

              {feesScreen === 'fees-table' && (
                <FeesTableScreen
                  feeTab={feeTab}
                  tierCount={tierCount}
                  getFeeServiceName={getFeeServiceName}
                  validatedFees={validatedFees}
                  duplicateFeeRecords={duplicateFeeRecords}
                  configData={configData}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  setState={setState}
                  onHandleValidatedFieldsChange={onHandleValidatedFeeFieldsChange}
                  onHandleTiersChange={onHandleTiersChange}
                  handleTiers={handleTiers}
                  onTableFeeScopeChange={onTableFeeScopeChange}
                  onHandleMeasureChange={onHandleMeasureChange}
                />
              )}

              {feesScreen === 'edit-fees' && feeTab === 'general' && (
                <ChangePrice
                  selectedValues={selectedEditValues}
                  type={feeTab}
                  handleChangePriceDelete={handleChangePriceDelete}
                  onHandleEditFieldsChange={onHandleEditFieldsChange}
                />
              )}
              {feesScreen === 'edit-fees' && feeTab === 'disposal' && (
                <ChangeDisposalPrice
                  selectedValues={selectedEditValues}
                  type={feeTab}
                  tierCount={tierCount}
                  setTierCount={setTierCount}
                  handleChangePriceDelete={handleChangePriceDelete}
                  onHandleEditFieldsChange={onHandleEditFieldsChange}
                  onHandleTiersChange={onHandleTiersChange}
                  onHandleMeasureChange={onHandleMeasureChange}
                  handleTiers={handleTiers}
                />
              )}

              {feesScreen === 'linked-fee-drawer' && <LinkedServicesList matchedServices={matchedServices} />}
            </div>
          </div>

          <div className="fees-footer">
            <input type="button" className="cancel" value={T.CANCEL} onClick={() => handleFeesCancel()} />

            <div className="right-aligned">
              {feesScreen !== 'initial' && feesScreen !== 'edit-fees' && feesScreen !== 'linked-fee-drawer' && (
                <div className="back" onClick={() => handleFeesBackBtn()}>
                  <Reply className="back-btn" />
                  <div className="back-txt">{T.BACK}</div>
                </div>
              )}

              <input
                disabled={handleFeeDisabled()}
                type="button"
                className="save"
                value={handleButtonText()}
                onClick={() => handleProceed()}
              />
            </div>
          </div>
        </section>
      </CommonDrawer>
      {isOpenFeeConfirmationModal && (
        <ConfirmationModal
          className="global-drawer-confirm"
          isOpen={isOpenFeeConfirmationModal}
          title={PRICING_CONFIRMATION_MSG}
          cancelButtonTitle={T.CANCEL}
          proceedButtonTitle={T.OK}
          onCancel={() => {
            setLocalState({ isOpenFeeConfirmationModal: false })
          }}
          onProceed={() => {
            setFeesScreen('fees-form')
            setLocalState({ isMultipleRecordsAltered: false })
            setLocalState({ isOpenFeeConfirmationModal: false })
          }}
        />
      )}
    </div>
  )
}

NewFeeModal.defaultProps = {
  feeTab: 'general',
  feesScreen: '',
  monthlyRevenue: '',
  isDirty: false,
  selectedEditValues: [],
  confServices: [],
  setFeesScreen: null,
  openFeeDrawer: false,
  setOpenFeeDrawer: null,
  setState: null,
  getFeesServicesResults: null,
  getFeeServiceName: null,
  handleChangePriceDelete: null,
  onHandleEditFieldsChange: null,
  setSelectedEditValues: null,
}

NewFeeModal.propTypes = {
  feeTab: PropTypes.string,
  feesScreen: PropTypes.string,
  monthlyRevenue: PropTypes.string,
  isDirty: PropTypes.bool,
  selectedEditValues: PropTypes.array,
  confServices: PropTypes.array,
  setFeesScreen: PropTypes.func,
  openFeeDrawer: PropTypes.bool,
  setOpenFeeDrawer: PropTypes.func,
  setState: PropTypes.func,
  getFeesServicesResults: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
  setSelectedEditValues: PropTypes.func,
}

export default NewFeeModal
