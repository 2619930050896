import React from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Divider } from '@mui/material'

const CommonModalHeader = ({ leadingIcon = undefined, title, onClose }) => {
  return (
    <>
      <HHDialogTitle TitleTypographyProps={{ variant: 'h6' }} title={title} icon={leadingIcon} onClose={onClose} />
      <Divider />
    </>
  )
}

CommonModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  leadingIcon: PropTypes.node,
}

export default CommonModalHeader
