import React from 'react'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'
import LateFeeDetails from 'containers/pricing/LateFeeDetails'

const LateFeeDetailsPage = () => {
  return (
    <ProtectedRouteHoC>
      <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
        <LateFeeDetails />
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

export default LateFeeDetailsPage
