import React, { useEffect, useState } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CheckIcon from '@mui/icons-material/Check'
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Badge,
  Box,
  Typography,
  SvgIcon,
} from '@mui/material'
import { useLazyGetNotificationListQuery } from 'api/notifications/getNotificationList'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useTheme from '@mui/material/styles/useTheme'
import { grey } from '@mui/material/colors'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { Link } from 'react-router-dom-v5-compat'
import { Bell } from '@styled-icons/heroicons-outline'
import { Z_INDEX } from 'theme/zIndex'
import routes, { APP_PREFIX } from '../../../router/routes'
import MenuToolTip from '../../common/Tooltip'
import NotificationsMenuItem from './NotificationsMenuItem'
import T from '../../../T'

const { MENU } = Z_INDEX

const NotificationsMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const isMenuOpen = Boolean(menuAnchorEl)
  const isSubmenuOpen = Boolean(submenuAnchorEl)
  const [getNotificationList, { isLoading, data }] = useLazyGetNotificationListQuery()
  const notificationList = get(data, 'notificationList', []).slice(0, 4)
  const theme = useTheme()
  const location = useLocation()
  const { pathname } = location
  const showNotificationList = !isLoading && Array.isArray(notificationList) && notificationList.length > 0
  const showEmptyState = !isLoading && Array.isArray(notificationList) && notificationList.length <= 0
  const isActive = routes.app.notifications === pathname || isMenuOpen

  const handleOpen = e => {
    setMenuAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const handleOpenSubmenu = e => {
    setSubmenuAnchorEl(e.currentTarget)
  }

  const handleCloseSubmenu = () => {
    setSubmenuAnchorEl(null)
  }

  useEffect(() => {
    getNotificationList()
  }, [])

  const handleOpenNotificationsPage = () => {
    handleCloseSubmenu()
    handleClose()
  }

  return (
    <>
      <MenuToolTip title="Notifications">
        <IconButton size="large" onClick={handleOpen}>
          <Badge max={99} color="primary" badgeContent={0}>
            <SvgIcon sx={{ color: isActive ? theme.palette.background.paper : grey[500] }}>
              <Bell />
            </SvgIcon>
          </Badge>
        </IconButton>
      </MenuToolTip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          zIndex: MENU,
          '.MuiMenuItem-root': { minWidth: '27rem', maxWidth: '27rem', whiteSpace: 'normal' },
          '.MuiMenu-list': { whiteSpace: 'normal' },
        }}
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          divider
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
            cursor: 'inherit',
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              sx: { ...theme.typography.h3, color: theme.palette.text.secondary, fontWeight: 600, pb: theme.spacing(1) },
            }}
          >
            Notifications
          </ListItemText>
          <ListItemSecondaryAction sx={{ pb: theme.spacing(1) }}>
            <IconButton onClick={handleOpenSubmenu}>
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </MenuItem>
        {showNotificationList &&
          notificationList.map(({ title, description, date, unread }) => (
            <NotificationsMenuItem title={title} description={description} date={date} unread={unread} />
          ))}
        {showEmptyState && (
          <Box display="flex" justifyContent="center" alignItems="center">
            {/* TODO: add empty state img */}
            <Typography>{T.NOTIFICATIONS_EMPTY_STATE_MSG}</Typography>
          </Box>
        )}
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ zIndex: MENU + 1 }}
          open={isSubmenuOpen}
          anchorEl={submenuAnchorEl}
          onClose={handleCloseSubmenu}
        >
          <MenuItem component={Link} onClick={handleOpenNotificationsPage} to={`${APP_PREFIX}/notifications`}>
            <ListItemIcon>
              <OpenInNewIcon />
            </ListItemIcon>
            <ListItemText>{T.OPEN_FULL_NOTIFICATIONS_PAGE}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCloseSubmenu}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText>{T.MARK_ALL_AS_READ}</ListItemText>
          </MenuItem>
        </Menu>
      </Menu>
    </>
  )
}

NotificationsMenu.propTypes = {}

export default NotificationsMenu
