import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Controller } from 'react-hook-form'

import MethodFilterMenu from './MethodFilterMenu'

const MethodFilterMenuField = ({ control, name, onChange = noop, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeHookForm, value } }) => {
        const handleChange = value => {
          onChange(value)
          onChangeHookForm(value)
        }

        return <MethodFilterMenu onChange={handleChange} value={value} {...rest} />
      }}
    />
  )
}

MethodFilterMenuField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default MethodFilterMenuField
