import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'utils/lodash'
import { getActiveDeactivatedLocations } from 'utils/location'

import { useDispatch, useSelector } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import LocationAccordion from './LocationAccordion'

const Location = ({ locationId = '', customerDetails = {}, onLocationChange }) => {
  const accountId = get(customerDetails, 'id')
  const dispatch = useDispatch()
  const { activeLocations: locations } = getActiveDeactivatedLocations(customerDetails, true)
  const selectedLocation = locations.find(location => location.id === locationId)
  const dropdownLocationId = useSelector(state => get(state, 'CustomerDetails.selectedLocationId', ''))

  const refetchCustomerDetails = () => {
    dispatch(getCustomerDetails({ accountId }))
  }

  const onLocationStatusChange = () => {
    // If current location is equal to the selected location in the services tab then change url to account
    if (locationId === dropdownLocationId) {
      onLocationChange()
    }
    refetchCustomerDetails()
  }

  return (
    <LocationAccordion
      showActionsMenu
      accountId={accountId}
      onLocationChange={onLocationChange}
      selectedLocation={selectedLocation}
      onLocationStatusChange={onLocationStatusChange}
      onSaveTags={refetchCustomerDetails}
    />
  )
}

Location.propTypes = {
  locationId: PropTypes.string,
  customerDetails: PropTypes.object,
  onLocationChange: PropTypes.func.isRequired,
}

export default Location
