import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as DashedTag } from 'assets/tags/DashedTag.svg'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import Avatar from '@mui/material/Avatar'
import { Tag } from '@styled-icons/heroicons-outline/Tag'
import { NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'
import { UNASSIGNED_ROUTE } from 'containers/new-route-manager/settings'

const RenderTagFilterOption = ({ props, option, selected, onOpenFilterMenu, isParentFilter }) => {
  const theme = useTheme()
  const id = get(option, 'id')
  const isActive = get(option, 'active', false)
  const tagName = get(option, 'tagName')
  const tagColor = get(option, 'tagColor')
  const contrastText = theme.palette.getContrastText(tagColor)
  const isNoTagsOption = id === NO_TAGS_OPTION_ID

  const routes = get(option, 'allRoutes', [])
  const unassignedRoute = useMemo(
    () => (isParentFilter ? routes.find(route => route.id === UNASSIGNED_ROUTE.id) : null),
    [isParentFilter, routes]
  )
  const routesCount = useMemo(() => routes.length, [routes])
  const stopsCount = useMemo(() => get(option, 'stopCount', 0), [option])

  const adjustedRoutesCount = useMemo(() => (unassignedRoute ? routesCount - 1 : routesCount), [unassignedRoute, routesCount])
  const adjustedStopsCount = useMemo(
    () => (unassignedRoute ? stopsCount - get(unassignedRoute, 'stopCount', 0) : stopsCount),
    [unassignedRoute, stopsCount]
  )

  const routeCountText = isParentFilter ? `${adjustedRoutesCount} route${adjustedRoutesCount > 1 ? 's' : ''} | ` : ''
  const secondaryText = `${routeCountText}${adjustedStopsCount} stop${adjustedStopsCount > 1 ? 's' : ''}`

  return (
    <ListItemButton dense disableGutters {...props} {...(isParentFilter && { onMouseOver: event => onOpenFilterMenu(event, routes, id) })}>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 1,
        }}
      >
        <Stack alignItems="center" direction="row">
          <Checkbox checked={selected} />
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: tagColor,
            }}
          >
            <SvgIcon
              sx={{
                color: contrastText,
              }}
            >
              {isNoTagsOption ? <DashedTag /> : <Tag />}
            </SvgIcon>
          </Avatar>
        </Stack>
      </ListItemIcon>
      <ListItemText
        primary={tagName}
        primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }}
        secondary={secondaryText}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
      {!isActive && (
        <ListItemIcon sx={{ minWidth: 0, ml: 1 }}>
          <VisibilityOffOutlinedIcon color="action" />
        </ListItemIcon>
      )}
    </ListItemButton>
  )
}

RenderTagFilterOption.propTypes = {
  option: PropTypes.object,
  props: PropTypes.object,
  selected: PropTypes.bool,
  onOpenFilterMenu: PropTypes.func,
  isParentFilter: PropTypes.bool,
}

export default RenderTagFilterOption
