import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import without from 'lodash/without'

import { Box, Typography, Divider, styled } from '@mui/material'

import HHAccordion from 'components/common/HHAccordion'

import T from 'T'

import { getCustomerBillingDetails } from 'data/customer/billingDetailsSelector'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'

import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import BillingField from './BillingField'
import EditBillingProfileDialog from './edit/EditBillingProfileDialog'

const DEFAULT_PRICE_TYPE = '$'

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const BillingDetails = ({ customerDetails = {} }) => {
  const [isOpenEditBillingDialog, setIsOpenEditBillingDialog] = useState(false)
  const billingProfile = get(customerDetails, 'accountBilling.billingProfile', {})
  const isBillingProfileEmpty = isEmpty(billingProfile)

  const {
    profileName,
    billingCycle,
    paymentType,
    timing,
    firstMonths,
    balance,
    isLateFeeTypeDollar,
    lateFeeAmount,
    lateFeeThreshold,
    lateFeeGracePeriod,
    paymentTerms,
    deliveryMethod,
    taxExempt,
  } = getCustomerBillingDetails(customerDetails)

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        isExpanded
        withNewColors
        summary={T.BILLING}
        onActionClick={() => setIsOpenEditBillingDialog(true)}
      >
        {isBillingProfileEmpty && <HHSectionPlaceholder title={T.NO_BILLING_DETAILS} />}
        {!isBillingProfileEmpty && (
          <Box width="100%" p={2}>
            <BillingField label={T.BALANCE} value={balance} isNumberFormat prefix={DEFAULT_PRICE_TYPE} variant="h6" />
            <Divider sx={{ mb: 1 }} />

            <Box mb={1}>
              <Typography color="text.secondary">{T.BILLING_PROFILE}</Typography>
              <Typography className="word-wrap-break-word">{profileName}</Typography>
            </Box>
            <StyledBox mb={1} alignItems="center">
              <StyledBox flexDirection="column">
                <Typography color="text.secondary">{T.TYPE}</Typography>
                <Typography>{paymentType}</Typography>
              </StyledBox>
              <StyledBox flexDirection="column" textAlign="right">
                <Typography color="text.secondary">{T.TIMING}</Typography>
                <Typography>{timing}</Typography>
              </StyledBox>
            </StyledBox>
            <BillingField label={T.CYCLE} value={billingCycle} />
            {firstMonths && <BillingField label={T.FIRST_MONTHS} value={firstMonths} />}
            <BillingField label={T.PAYMENT_TERMS} value={paymentTerms} />
            <Divider sx={{ mb: 1 }} />

            <BillingField
              label={T.LATE_FEES_FINANCING}
              value={lateFeeAmount}
              isNumberFormat
              prefix={isLateFeeTypeDollar ? DEFAULT_PRICE_TYPE : ''}
              suffix={!isLateFeeTypeDollar ? '%' : ''}
            />
            <BillingField label={T.THRESHOLD} value={lateFeeThreshold} isNumberFormat prefix={DEFAULT_PRICE_TYPE} />
            <BillingField label={T.GRACE_PERIOD} value={lateFeeGracePeriod} />
            <BillingField label={T.TAX_STATUS} value={taxExempt} />
            <BillingField
              label={T.DELIVERY_METHOD}
              value={without(deliveryMethod.replace('Pdf', 'PDF').split(', '), T.POSTCARD).join(', ')}
              mb={0}
            />
          </Box>
        )}
      </HHAccordion>

      <EditBillingProfileDialog
        isOpen={isOpenEditBillingDialog}
        customerDetails={customerDetails}
        onClose={() => setIsOpenEditBillingDialog(false)}
      />
    </>
  )
}

BillingDetails.propTypes = {
  customerDetails: PropTypes.object,
}

export default memo(BillingDetails)
