import React from 'react'
import ReportTables from 'containers/reports/ReportTables'

const ReportsDetailPage = () => (
  <section className="reports-page">
    <ReportTables />
  </section>
)

export default ReportsDetailPage
