import React from 'react'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import Constants from 'Constants'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { replaceAllSpacesAndAmpersand } from 'utils/string'

const CustomCardContent = styled(CardContent)(() => ({
  padding: '8px 12px',
  '&:last-child': {
    paddingBottom: 8,
  },
}))

const { MEDIA_SERVER } = Constants

const ServiceCard = ({
  title = '',
  price = '',
  businessLine = '',
  recurrence = '',
  volume = '',
  measureUnit = '',
  pricingPeriod = '',
  materialType = '',
  businessLineIcon = '',
  actionType = '',
  onClick,
  isSelected,
}) => {
  return (
    <Card
      variant="outlined"
      onClick={onClick}
      sx={{
        cursor: typeof onClick === 'function' ? 'pointer' : 'auto',
        borderColor: isSelected ? 'primary.main' : 'grey.200',
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      <CustomCardContent>
        <Grid container item justifyContent="space-between" wrap="nowrap" xs={12}>
          <Typography variant="h6" fontWeight={500} gutterBottom noWrap>
            {title}
          </Typography>
          <Typography variant="h6" fontWeight={500} gutterBottom>
            <NumericFormat prefix="$" thousandSeparator displayType="text" value={price} fixedDecimalScale decimalScale={2} />
          </Typography>
        </Grid>
        <Grid container item justifyContent="space-between" xs={12}>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {[businessLine, recurrence, `${volume} ${measureUnit}`.trim()].filter(Boolean).join(' - ')}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {pricingPeriod}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" xs={12}>
          <Typography variant="body1" color="text.secondary">
            {materialType}
          </Typography>
          {materialType && businessLineIcon && (
            <Typography variant="body1" color="text.secondary">
              &nbsp;-&nbsp;
            </Typography>
          )}
          {businessLineIcon && <img src={`${MEDIA_SERVER}${businessLineIcon}`} alt="icons" />}
          {(materialType || businessLineIcon) && actionType && (
            <Typography variant="body1" color="text.secondary">
              &nbsp;-&nbsp;
            </Typography>
          )}
          {actionType && <img src={`${MEDIA_SERVER}Action/${replaceAllSpacesAndAmpersand(actionType)}.svg`} alt="icons" />}
        </Grid>
      </CustomCardContent>
    </Card>
  )
}

ServiceCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  businessLine: PropTypes.string,
  recurrence: PropTypes.string,
  volume: PropTypes.string,
  measureUnit: PropTypes.string,
  pricingPeriod: PropTypes.string,
  materialType: PropTypes.string,
  businessLineIcon: PropTypes.string,
  actionType: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
}

export default ServiceCard
