export const OUTER_CONTENT = 300

export const getRemoveDialogContentHeight = (count, rowHeight) => {
  if (count === 0) return rowHeight
  if (count < 25) return 'auto'
  return `calc(100vh - ${OUTER_CONTENT}px)`
}

export const getAddDialogContentHeight = (existList, newList, maxContentHeight, rowHeight) => {
  const halfHeight = maxContentHeight / 2
  const existHeight = rowHeight * existList.length
  const newHeight = rowHeight * (newList.length || 1)

  if (existHeight + newHeight <= maxContentHeight) {
    return { existListContainerHeight: 'auto', newListContainerHeight: newList.length ? 'auto' : rowHeight }
  }

  if (existHeight > halfHeight && newHeight > halfHeight) {
    return { existListContainerHeight: halfHeight, newListContainerHeight: halfHeight }
  }

  if (existHeight > halfHeight) {
    const usedHeight = newHeight < halfHeight ? newHeight : halfHeight
    return { existListContainerHeight: maxContentHeight - usedHeight, newListContainerHeight: usedHeight }
  }

  if (newHeight > halfHeight) {
    const usedHeight = existHeight < halfHeight ? existHeight : halfHeight
    return { existListContainerHeight: usedHeight, newListContainerHeight: maxContentHeight - usedHeight }
  }

  return { existListContainerHeight: halfHeight, newListContainerHeight: halfHeight }
}
