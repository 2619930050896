import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Checkbox, useTheme, Typography, Stack } from '@mui/material'
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { calculateSum } from 'utils/price'
import { HHAlert } from 'components/common/HHAlert'

import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import InvoiceActivity from 'components/customer-details/content/common/InvoiceActivity'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const InvoicesDataGrid = ({
  existingAccounts = [],
  selectedOption = null,
  rows = [],
  amount = 0,
  billingProfileDetails = {},
  selectionModel = [],
  onSelectionModelChange,
  onSelectAll,
  ...rest
}) => {
  const theme = useTheme()
  const totalRows = rows.length
  const totalSelectedRows = selectionModel.length
  const totalAmountCents = calculateSum(rows, 'invoiceAmount')
  const isSelectionEnabled = amount > 0

  const getRowHeight = useCallback(() => 'auto', [])

  const HeaderSelectAll = () => (
    <Checkbox
      disabled={!isSelectionEnabled}
      indeterminate={totalSelectedRows && totalSelectedRows < totalRows}
      checked={totalSelectedRows > 0}
      onChange={onSelectAll}
      icon={<RadioButtonUncheckedSharpIcon />}
      checkedIcon={<CheckCircleSharpIcon />}
      indeterminateIcon={<RemoveCircleIcon />}
    />
  )

  const renderInvoiceColumnHeader = () => (
    <>
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        {`${totalRows} invoice${totalRows > 1 ? 's' : ''}`}
        {totalSelectedRows > 0 ? ` / ${totalSelectedRows} selected` : ''}
      </Typography>
      <Typography variant="h5" fontWeight={500} color="textPrimary">
        <HHDisplayMoney value={totalAmountCents} />
      </Typography>
    </>
  )

  const hasExistingAccounts = existingAccounts.length > 0

  const noRowsText = useMemo(() => {
    if (selectedOption) {
      return 'No invoices'
    }
    if (hasExistingAccounts) {
      return 'Select an account'
    }
    return 'Add invoices by searching customer name, or invoice #'
  }, [selectedOption, hasExistingAccounts])

  return (
    <DataGridPro
      rows={rows}
      columnHeaderHeight={totalRows > 0 ? 56 : 0}
      hideFooter
      checkboxSelection
      isRowSelectable={() => isSelectionEnabled}
      disableRowSelectionOnClick
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      columns={[
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          renderHeader: () => <HeaderSelectAll />,
        },
        {
          flex: 1,
          field: 'id',
          renderCell: ({ row }) => <InvoiceActivity invoice={row} billingProfileDetails={billingProfileDetails} />,
          renderHeader: renderInvoiceColumnHeader,
          sortable: false,
          headerClassName: 'data-column-header',
          cellClassName: 'data-column',
        },
      ]}
      slots={{
        baseCheckbox: params => <DataGridBaseCheckbox {...params} rows={rows} />,
        noRowsOverlay: () => (
          <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <HHAlert sx={{ mt: selectedOption || hasExistingAccounts ? 0 : 7 }} borderColor={theme.palette.info.light} severity="info">
              {noRowsText}
            </HHAlert>
          </Stack>
        ),
      }}
      getRowId={({ invoiceNumber }) => invoiceNumber}
      getRowHeight={getRowHeight}
      disableColumnMenu
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .data-column-header .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        },
        '& .data-column': {
          py: 1,
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
      }}
      {...rest}
    />
  )
}

InvoicesDataGrid.propTypes = {
  existingAccounts: PropTypes.array,
  selectedOption: PropTypes.object,
  rows: PropTypes.array,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  billingProfileDetails: PropTypes.object,
  selectionModel: PropTypes.array,
  onSelectionModelChange: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
}

export default InvoicesDataGrid
