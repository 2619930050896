import api from 'api'
import Constants from 'Constants'

const { BUSINESS_IDENTIFIER } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateUser: builder.mutation({
      query: ({ email, businessId, payload }) => ({
        method: 'PATCH',
        url: `/api/v1/core/settings/users/${email}`,
        body: { ...payload },
        ...(businessId && { headers: { [BUSINESS_IDENTIFIER]: businessId } }),
      }),
    }),
  }),
})

export const { useUpdateUserMutation } = extendedApi
