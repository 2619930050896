import React from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem } from '@mui/material'
import { PAYMENT_METHODS } from 'settings/constants/payment'

const { CREDIT_CARD, ACH } = PAYMENT_METHODS

const NewPaymentMethod = ({ anchorEl, onClick, onClose }) => (
  <Menu
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{ variant: 'outlined' }}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
    disableScrollLock
  >
    <MenuItem onClick={() => onClick(CREDIT_CARD)}>Credit card</MenuItem>
    <MenuItem onClick={() => onClick(ACH)}>ACH</MenuItem>
  </Menu>
)

NewPaymentMethod.propTypes = {
  anchorEl: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NewPaymentMethod
