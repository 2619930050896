import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { blue, green, grey, orange } from '@mui/material/colors'

import BillingActive from 'assets/switch/active/Billing.svg'
import BillingDeactivated from 'assets/switch/deactivated/Billing.svg'

import ServiceActive from 'assets/switch/active/Service.svg'
import ServiceDeactivated from 'assets/switch/deactivated/Service.svg'

import AccountStatusActive from 'assets/switch/active/AccountStatus.svg'
import AccountStatusDeactivated from 'assets/switch/deactivated/AccountStatus.svg'

import UnscheduledActive from 'assets/switch/active/Unscheduled.svg'
import UnscheduledDeactivated from 'assets/switch/deactivated/Unscheduled.svg'

import TemporaryModeActive from 'assets/switch/active/TemporaryMode.svg'
import TemporaryModeDeactivated from 'assets/switch/deactivated/TemporaryMode.svg'

import DriverPathActive from 'assets/switch/active/DriverPath.svg'
import DriverPathDeactivated from 'assets/switch/deactivated/DriverPath.svg'

import SequenceLineActive from 'assets/switch/active/SequenceLine.svg'
import SequenceLineDeactivated from 'assets/switch/deactivated/SequenceLine.svg'

import { noop } from 'lodash'

const StyledSwitch = styled(Switch)(({ activeIcon, deactivatedIcon, activeColor }) => ({
  width: 62,
  height: 34,
  padding: 7,
  margin: '0!important',
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: activeColor[50],
        border: '1px solid',
        borderColor: activeColor[500],
        '&:before': {
          backgroundImage: `url(${activeIcon})`,
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    backgroundColor: grey[50],
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.54)',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${deactivatedIcon})`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: grey[500],
    borderRadius: 20 / 2,
  },
}))

const getSwitchIcons = type => {
  if (type === 'Billing') {
    return { activeIcon: BillingActive, deactivatedIcon: BillingDeactivated }
  }

  if (type === 'Service') {
    return { activeIcon: ServiceActive, deactivatedIcon: ServiceDeactivated }
  }

  if (type === 'AccountStatus') {
    return { activeIcon: AccountStatusActive, deactivatedIcon: AccountStatusDeactivated }
  }

  if (type === 'UnscheduledRoute') return { activeIcon: UnscheduledActive, deactivatedIcon: UnscheduledDeactivated }

  if (type === 'TemporaryMode') return { activeIcon: TemporaryModeActive, deactivatedIcon: TemporaryModeDeactivated }

  if (type === 'DriverPath') return { activeIcon: DriverPathActive, deactivatedIcon: DriverPathDeactivated }

  if (type === 'SequenceLine') return { activeIcon: SequenceLineActive, deactivatedIcon: SequenceLineDeactivated }
  return { activeIcon: '', deactivatedIcon: '' }
}

const getActiveColor = type => {
  if (type === 'UnscheduledRoute') return blue
  if (type === 'TemporaryMode') return orange
  return green
}

const HHSwitchIconField = ({ type, label, labelPlacement = 'start', isChecked, onChange = noop }) => {
  const { activeIcon, deactivatedIcon } = getSwitchIcons(type)
  const activeColor = getActiveColor(type)
  return (
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <StyledSwitch
          activeColor={activeColor}
          activeIcon={activeIcon}
          deactivatedIcon={deactivatedIcon}
          sx={{ m: 1 }}
          checked={isChecked}
          onChange={event => onChange(event.target.checked)}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

HHSwitchIconField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default HHSwitchIconField
