import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CancelButton from 'components/buttons/CancelButton'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import T from 'T'

import { formatDateToFEFormat } from 'utils/date'

const BulkStopConfirmation = ({ isOpen = false, totalStops, currentDate, newDate, currentRoute, newRoute, onClose, onConfirm }) => {
  return (
    <Dialog open={isOpen}>
      <HHDialogTitle title={T.BULK_MOVE_CONFIRMATION} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            {`You’re about to move ${totalStops} stop${totalStops > 1 ? 's' : ''} from `}
            <strong>{`${currentRoute}`}</strong>
            {` on ${formatDateToFEFormat(currentDate)} to `}
            <strong>{`${newRoute}`}</strong>
            {` on ${formatDateToFEFormat(newDate)}.`}
          </Typography>
          <Typography variant="h5" color="textPrimary" fontWeight={400} mt={3}>
            Do you want to continue?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <Button size="small" variant="contained" onClick={onConfirm}>
          {T.CONFIRM}
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

BulkStopConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  totalStops: PropTypes.number,
  currentDate: PropTypes.string,
  newDate: PropTypes.string,
  currentRoute: PropTypes.string,
  newRoute: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default BulkStopConfirmation
