import React from 'react'

import InvoiceGroups from 'containers/billing/groups/invoice'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'

const InvoiceGroupsPage = () => (
  <ProtectedRouteHoC>
    <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
      <InvoiceGroups />
    </NavbarWrapper>
  </ProtectedRouteHoC>
)

export default InvoiceGroupsPage
