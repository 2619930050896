import React, { useEffect, useState } from 'react'
import set from 'lodash/set'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import { useGetGeneralLedgerMutation } from 'api/settings/getGeneralLedger'
import { useAddGeneralLedgerMutation } from 'api/settings/addGeneralLedger'
import { useUpdateGeneralLedgerMutation } from 'api/settings/updateGeneralLedger'
import { getLedgerData } from 'data/settings/generalLedgerSelectors'
import T from 'T'
import { get } from 'utils/lodash'
import { canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { LEDGER_VAR } from 'settings/constants/generalLedgerSettings'
import { isAlphaNumeric } from 'utils/validations'
import GeneralLedgerTable from './general-ledger/GeneralLedgerTable'

const GeneralLedgerSettings = () => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [records, setRecords] = useState([])
  const [footerFlag, setfooterFlag] = useState(false)
  const [editID, setEditId] = useState('')
  const [onPlaceLedgerEdit, setOnPlaceLedgerEdit] = useState([])

  const [getGeneralLedger, { data: ledgerData, isLoading: isLedgerFetchLoading, isFetching }] = useGetGeneralLedgerMutation()
  const [addGeneralLedger, { data: savedResponse, isLoading: isLedgerAddLoading, isFetching: isAddFetching }] =
    useAddGeneralLedgerMutation()
  const [updateGeneralLedger, { data: updatedResponse, isLoading: isLedgerUpdateLoading, isFetching: isUpdateFetching }] =
    useUpdateGeneralLedgerMutation()

  const fetchGeneralLedger = () => {
    const payload = {}
    getGeneralLedger(payload)
    setEditId('')
  }

  useEffect(() => {
    fetchGeneralLedger()
  }, [])

  const setFooter = flag => {
    setfooterFlag(flag)
  }

  useEffect(() => {
    if (ledgerData) {
      setRecords(ledgerData)
    }
  }, [ledgerData])

  useEffect(() => {
    if (savedResponse || updatedResponse) {
      fetchGeneralLedger()
      setFooter(false)
    }
  }, [savedResponse || updatedResponse])

  const handleOnPlaceLedgerEdit = (index, type) => {
    const inPlaceEdit = getLedgerData(onPlaceLedgerEdit)

    if (updateSettings) {
      inPlaceEdit[index] = {}

      inPlaceEdit[index].canEditAccountName = false
      inPlaceEdit[index].canEditAccountNumber = false

      inPlaceEdit[index][LEDGER_VAR[type]] = !inPlaceEdit[index][LEDGER_VAR[type]]

      setOnPlaceLedgerEdit(inPlaceEdit)

      setEditId(get(records, index, {}).id)
    }
  }

  const handleLedgerSaveOrUpdate = () => {
    const payload = records.filter(data => data.accountName !== '')

    const finalPayload = payload.find(data => data.id === editID)

    setOnPlaceLedgerEdit([])

    if (finalPayload.id !== '') {
      updateGeneralLedger(finalPayload)
    } else {
      addGeneralLedger(finalPayload)
    }

    setEditId('')
  }

  const onHandleLedgerChange = (e, index) => {
    let { name, value } = e.target

    if (/^\s/.test(value)) {
      value = ''
    }

    if (!isAlphaNumeric(value)) return

    const ledgerValues = getLedgerData(records)

    set(ledgerValues, `[${index}].${name}`, value)

    setRecords(ledgerValues)

    get(ledgerValues, `[${index}].accountName`, '')

    setFooter(get(ledgerValues, `[${index}].accountName`, '') !== '' && get(ledgerValues, `[${index}].accountNumber`, '') !== '')
  }

  const handleLedgerCancel = index => {
    const values = getLedgerData(records)
    values.splice(index, 1)
    setRecords(values)
    setFooter(false)
  }

  const handleCancel = () => {
    setRecords(ledgerData)
    setOnPlaceLedgerEdit([])
    setFooter(false)
  }

  const setNewFields = rec => {
    onPlaceLedgerEdit[rec.length - 1] = {}
    onPlaceLedgerEdit[rec.length - 1].canEditAccountName = true
    onPlaceLedgerEdit[rec.length - 1].canEditAccountNumber = true
    onPlaceLedgerEdit[rec.length - 1].canEditIncome = true

    setRecords(rec)
    setOnPlaceLedgerEdit(onPlaceLedgerEdit)
  }

  return (
    <Box>
      <Typography px={4} variant="h3">
        {T.GENERAL_LEDGER}
      </Typography>
      <GeneralLedgerTable
        isLoading={isLedgerFetchLoading || isLedgerAddLoading || isLedgerUpdateLoading || isFetching || isAddFetching || isUpdateFetching}
        records={records}
        footerFlag={footerFlag}
        onPlaceLedgerEdit={onPlaceLedgerEdit}
        onHandleChange={onHandleLedgerChange}
        setNewFields={setNewFields}
        handleSaveOrUpdate={handleLedgerSaveOrUpdate}
        handleLedgerCancel={handleLedgerCancel}
        handleOnPlaceEdit={handleOnPlaceLedgerEdit}
        handleCancel={handleCancel}
      />
    </Box>
  )
}
export default GeneralLedgerSettings
