import React from 'react'
import PropTypes from 'prop-types'
import { Grid, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material'
import { get } from 'lodash'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'

const PartialPaidInvoiceRenderCell = ({ row }) => {
  const invoiceNumber = get(row, 'invoiceNumber')
  const accountNumber = get(row, 'accountNumber')
  const customerName = get(row, 'customerName') || get(row, 'accountName')
  const balanceCents = get(row, 'balanceCents')
  const dueDateStr = get(row, 'dueDate')
  const status = get(row, 'status')
  const dueDateObj = createDateFromString(dueDateStr)
  const dueDate = formatDateToFEFormatDateFns(dueDateObj)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return (
      <ListItem
        secondaryAction={
          <InvoiceStatusChip size="small" status={status} isPartial={balanceCents !== 0} showAmount amountCents={balanceCents} />
        }
      >
        <ListItemText
          primary={`#${invoiceNumber}`}
          primaryTypographyProps={{
            variant: 'h6',
            color: 'textPrimary',
            gutterBottom: true,
          }}
          secondary={customerName}
          secondaryTypographyProps={{
            variant: 'body1',
          }}
        />
      </ListItem>
    )
  }

  return (
    <Grid container>
      <Grid item xs>
        <ListItem>
          <ListItemText
            primary={`#${invoiceNumber}`}
            primaryTypographyProps={{
              variant: 'h6',
              color: 'textPrimary',
              gutterBottom: true,
            }}
            secondary={`${accountNumber} | ${customerName}`}
            secondaryTypographyProps={{
              variant: 'body1',
            }}
          />
        </ListItem>
      </Grid>
      <Grid item xs>
        <ListItem>
          <ListItemText
            primary={
              <InvoiceStatusChip size="small" status={status} isPartial={balanceCents !== 0} showAmount amountCents={balanceCents} />
            }
            primaryTypographyProps={{
              textAlign: 'right',
              variant: 'h6',
            }}
            secondary={`DUE: ${dueDate}`}
            secondaryTypographyProps={{
              textAlign: 'right',
              sx: {
                pr: 0.5,
              },
              color: 'textPrimary',
              variant: 'body1',
              fontWeight: 500,
            }}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}

PartialPaidInvoiceRenderCell.propTypes = {
  row: PropTypes.object,
}

export default PartialPaidInvoiceRenderCell
