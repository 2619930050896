import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import { shallowEqual, useSelector } from 'react-redux'

import { FormControlLabel, Typography, Checkbox, Grid, MenuItem } from '@mui/material'
import SelectCheck from '@mui/icons-material/Check'
import SelectItem from 'components/form-fields/v5/SelectItem'
import { useLazyGetProfileByIdQuery } from 'api/settings/getProfileById'
import { handleError } from 'utils/error'
import { get } from 'utils/lodash'
import { canUpdateSettings, canUpdateUsers } from 'data/permissions/permissionsSelectors'
import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import HHTextField from 'components/form-fields/v5/HHTextField'
import CommonSwitch from 'components/common/CommonSwitch'
import T from 'T'
import Constants from 'Constants'

import { setUserProfile } from '../roleMethods'
import { getTimeZoneOptions } from './helper'

const { MEDIA_SERVER } = Constants

const PersonalInfoScreen = ({
  userState = {},
  setUserState = noop,
  roleState = {},
  setRoleState = noop,
  onHandleNameChange = noop,
  handleSwitchVal = noop,
  isValidEmail = false,
  isEmailAlreadyExist = false,
  onEmailChange,
}) => {
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const isEditMode = Boolean(userState.userID)

  const [getRoleById] = useLazyGetProfileByIdQuery()

  const selectedSkillSet = userState.selectedSkills.filter(data => data.id !== '').map(skill => skill.id)

  const onHandleValChange = (e, type) => {
    if (updateSettings) {
      let { value } = e.target
      const { name } = e.target
      if (type === 'commercialDriverLicense') {
        value = e.target.checked
      }

      if (name === 'profileId') {
        setUserState({ role: userState.roles.find(data => data.key === value).value, changeIndividualPermissions: false })
        const payload = {
          id: value,
          user: true,
        }
        getRoleById(payload)
          .unwrap()
          .then(res => {
            setUserProfile(res, setRoleState)
          })
          .catch(handleError)
      }

      setUserState({ [name]: value, footerFlag: true })
    }
  }

  const handleEmailChangeDebounce = debounce(event => {
    onEmailChange(event)
  }, 300)

  const handleEmailChange = event => {
    onHandleNameChange(event)
    handleEmailChangeDebounce(event)
  }

  const timeZone = get(userState, 'timeZone', '')
  // This is temporary until we change the data in the DB
  // later we will directly use TIME_ZONES from settings
  const timeZoneOptions = getTimeZoneOptions(timeZone)

  const onHandleSkillChange = event => {
    if (updateSettings) {
      setUserState({
        selectedSkills: get(userState, 'skills', []).filter(data => event.target.value.includes(data.id)),
        footerFlag: true,
      })
    }
  }

  const emailHelperText = useMemo(() => {
    if (!userState.email) {
      return ''
    }

    if (!isValidEmail) {
      return 'Invalid email'
    }

    if (isEmailAlreadyExist) {
      return 'Email already exists'
    }

    return ''
  }, [userState.email, isValidEmail, isEmailAlreadyExist])

  return (
    <Grid container spacing={3} sx={{ pointerEvents: updateUsers ? '' : 'none', justifyContent: 'space-between' }}>
      <Grid item xs="6">
        <Typography variant="h5" mb={1.2}>
          {T.BASIC_INFO}
        </Typography>

        <Grid container columnSpacing={2} rowSpacing={1.5} justifyContent="space-between">
          <Grid item xs="5">
            <HHTextField
              required
              label={T.FIRST_NAME}
              fullWidth
              disabled={!userState.active}
              placeholder={T.FIRST_NAME}
              value={userState.firstName}
              variant="outlined"
              autoComplete="off"
              autoFocus
              name="firstName"
              onChange={onHandleNameChange}
            />
          </Grid>

          <Grid item xs="7">
            <HHTextField
              required
              label={T.LAST_NAME}
              fullWidth
              disabled={!userState.active}
              placeholder={T.LAST_NAME}
              value={userState.lastName}
              variant="outlined"
              autoComplete="off"
              name="lastName"
              onChange={onHandleNameChange}
            />
          </Grid>

          <Grid item xs="12">
            <HHTextField
              required
              error={emailHelperText}
              helperText={emailHelperText}
              disabled={isEditMode}
              label={T.EMAIL}
              fullWidth
              value={userState.email}
              variant="outlined"
              autoComplete="off"
              onChange={handleEmailChange}
              {...(!isEditMode && { placeholder: T.EMAIL, name: 'email' })}
            />
          </Grid>

          <Grid item xs sm={2}>
            <HHTextField label={T.AREA_CODE} fullWidth disabled placeholder={T.AREA_CODE} value="+1" variant="outlined" />
          </Grid>
          <Grid item xs>
            <HHPhoneField
              label={`${T.PHONE} ${T.NUMBER}`}
              disabled={!userState.active}
              fullWidth
              value={get(userState, 'phoneNumber')}
              name="phoneNumber"
              onValueChange={value => onHandleNameChange({ target: { name: 'phoneNumber', value } })}
            />
          </Grid>

          <Grid item xs="12">
            <HHTextField
              label={T.TIME_ZONE}
              disabled={!userState.active}
              fullWidth
              placeholder={T.TIME_ZONE}
              select
              value={[timeZone]}
              name="timeZone"
              onChange={onHandleValChange}
            >
              <MenuItem value="">{T.SELECT}</MenuItem>
              {timeZoneOptions.map(data => (
                <MenuItem value={data.key}>{data.value}</MenuItem>
              ))}
            </HHTextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs="6">
        <Typography variant="h5" mb={1.2}>
          {T.WORK_INFO}
        </Typography>

        <Grid container columnSpacing={2} rowSpacing={1.5} justifyContent="space-between">
          <Grid item xs="12">
            <HHTextField
              required
              label={T.ROLE}
              disabled={!userState.active}
              fullWidth
              placeholder={T.ROLE}
              select
              value={[userState.profileId]}
              name="profileId"
              onChange={onHandleValChange}
            >
              <MenuItem value="">{T.SELECT}</MenuItem>
              {userState.roles.map(data => {
                return <MenuItem value={data.key}>{data.value}</MenuItem>
              })}
            </HHTextField>
          </Grid>
          {userState.profileId !== '' && roleState.allowOfficeLogin && (
            <Grid item xs="12" mt={1}>
              <CommonSwitch
                disabled={!userState.active}
                titlePosition="right"
                title={T.ACCOUNT_REPRESENTATIVE}
                isChecked={get(userState, 'accountRepresentative', false)}
                onChange={value => {
                  handleSwitchVal('accountRepresentative', value)
                }}
              />
            </Grid>
          )}
          {userState.profileId !== '' && roleState.allowRoadLogin && (
            <>
              <Grid item xs="12">
                <HHTextField
                  label={T.DEFAULT_TRUCK}
                  disabled={!userState.active}
                  fullWidth
                  placeholder={T.DEFAULT_TRUCK}
                  select
                  value={[userState.asset]}
                  name="asset"
                  onChange={onHandleValChange}
                >
                  <MenuItem value="">{T.SELECT}</MenuItem>
                  {userState.assets.map(data => (
                    <MenuItem value={data.key}>{data.value}</MenuItem>
                  ))}
                </HHTextField>
              </Grid>

              <Grid item xs="12">
                <FormControlLabel
                  sx={{ pr: 1, m: '8px 8px 0px 0px' }}
                  disabled={!userState.active}
                  control={
                    <Checkbox
                      disabled={!userState.active}
                      icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} width="16px" height="16px" alt="" />}
                      checkedIcon={<img src={`${MEDIA_SERVER}ConfiguratorCheckbox.svg`} width="16px" height="16px" alt="" />}
                      color="primary"
                      name="commercialDriverLicense"
                      checked={userState.commercialDriverLicense}
                      onChange={e => onHandleValChange(e, 'commercialDriverLicense')}
                      sx={{ p: 0, mr: 1 }}
                    />
                  }
                  label={T.COMMERCIAL_DRIVER_LICENSE}
                />
              </Grid>

              <Grid item xs="12">
                <HHTextField
                  sx={{
                    svg: {
                      '&: first-child': {
                        display: 'none',
                      },
                    },
                    '& .MuiSelect-select': {
                      display: 'flex',
                      svg: {
                        display: 'none',
                      },
                    },
                  }}
                  label={T.SKILL}
                  disabled={!userState.active}
                  fullWidth
                  SelectProps={{
                    multiple: true,
                  }}
                  placeholder={selectedSkillSet.length ? '' : `${T.SKILL}s`}
                  select
                  value={selectedSkillSet.length ? selectedSkillSet : new Array('')}
                  name="skills"
                  onChange={onHandleSkillChange}
                >
                  <SelectItem value="">{T.PLEASE_SELECT}</SelectItem>
                  {get(userState, 'skills', []).map(skill => {
                    return (
                      <SelectItem value={skill.id} justifyContent="space-between">
                        <Typography noWrap>{skill.skillName}</Typography>
                        {selectedSkillSet.includes(skill.id) && <SelectCheck sx={{ width: '20px', height: '20px' }} />}
                      </SelectItem>
                    )
                  })}
                </HHTextField>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

PersonalInfoScreen.propTypes = {
  userState: PropTypes.object,
  roleState: PropTypes.object,
  setUserState: PropTypes.func,
  setRoleState: PropTypes.func,
  onHandleNameChange: PropTypes.func,
  handleSwitchVal: PropTypes.func,
  isValidEmail: PropTypes.bool,
  isEmailAlreadyExist: PropTypes.bool,
  onEmailChange: PropTypes.func.isRequired,
}

export default PersonalInfoScreen
