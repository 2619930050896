import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { useForm, FormProvider } from 'react-hook-form'
import { TabContext, TabPanel } from '@material-ui/lab'

import { get } from 'utils/lodash'
import { NO_ACCESS_PERMISSION } from 'settings/constants/roles'
import SequenceContainer from 'containers/new-route-manager/SequenceContainer'

import routerList from 'router/routes'
import { getRouteSubscribeToken, putMarkerLocation } from 'middleware/actions/routeManager'
import {
  VIEW_MODE_SEQUENCE,
  VIEW_MODE_MAP,
  selectRouteIds,
  setStopsCountByRouteId,
  setAllRouteIds,
  setAllRoutes,
  setResetSearch,
  selectRouteSerializedServiceDate,
} from 'slices/route/routeSlice'
import { selectIsOpenBulkMoveStopsDialog } from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { getViewMode } from 'data/route/viewModeSelector'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'

import './style.scss'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useGetRouteMetadataQuery } from 'api/route/getRouteMetadata'
import RouteManagerAppBar from 'components/route-manager/RouteManagerAppBar/RouteManagerAppBar'
import RouteManagerMap from 'components/route-manager/RouteManagerMap/RouteManagerMap'
import { selectIsOpenBulkMoveBetweenDaysStopsDialog } from 'slices/route/bulkMoveBetweenDaysSlice'
import { selectIsOpenBulkAssignStopsDialog } from 'slices/route/bulkAssignStopsSlice'
import { selectIsOpenBulkUnassignStopsDialog } from 'slices/route/bulkUnassignStopsSlice'
import { selectIsOpenBulkResequenceStopsDialog } from 'slices/route/bulkResequenceStopsSlice'
import { FORM_MODEL } from 'containers/new-route-manager/settings'
import { MapProvider } from 'providers/MapProvider'

const RoutesManager = () => {
  const filtersForm = useForm({ defaultValues: { ...FORM_MODEL } })
  const dispatch = useDispatch()
  const history = useHistory()
  useGetRouteMetadataQuery()
  const map = useRef()
  const viewMode = useSelector(getViewMode)
  const isMapView = viewMode === VIEW_MODE_MAP
  const allRouteIds = useSelector(s => get(s, 'Route.allRouteIds', []), shallowEqual)
  const resetSearch = useSelector(s => get(s, 'Route.resetSearch', false), shallowEqual)
  const selectedRouteIds = useSelector(s => s.Route.filters.selectedRouteIds, shallowEqual)
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const [getRoutesMetadata, { data: routeMetaData, isSuccess }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const isOpenBulkMoveBetweenRoutesDialog = useSelector(selectIsOpenBulkMoveStopsDialog)
  const isOpenBulkMoveBetweenDaysDialog = useSelector(selectIsOpenBulkMoveBetweenDaysStopsDialog)
  const isOpenBulkAssignDialog = useSelector(selectIsOpenBulkAssignStopsDialog)
  const isOpenBulkUnassignDialog = useSelector(selectIsOpenBulkUnassignStopsDialog)
  const isOpenBulkResequenceStopsDialog = useSelector(selectIsOpenBulkResequenceStopsDialog)
  const showContent = useMemo(
    () =>
      !isOpenBulkMoveBetweenRoutesDialog &&
      !isOpenBulkMoveBetweenDaysDialog &&
      !isOpenBulkAssignDialog &&
      !isOpenBulkUnassignDialog &&
      !isOpenBulkResequenceStopsDialog,
    [
      isOpenBulkMoveBetweenRoutesDialog,
      isOpenBulkMoveBetweenDaysDialog,
      isOpenBulkAssignDialog,
      isOpenBulkUnassignDialog,
      isOpenBulkResequenceStopsDialog,
    ]
  )

  const handleAddressSelected = value => {
    dispatch(putMarkerLocation(value))
  }

  const handleResetSearch = (resetSearch = true) => {
    dispatch(setResetSearch(resetSearch))
  }

  useEffect(() => {
    const routePemission = get(userInfo, 'profile.routeManagerCrud', NO_ACCESS_PERMISSION)

    if (routePemission == NO_ACCESS_PERMISSION) {
      history.push(routerList.app.home)
    }
  }, [userInfo])

  const className = isMapView ? 'route-container map flex flex-column overflow-hidden' : 'route-container sequence flex flex-column'

  useEffect(() => {
    dispatch(getRouteSubscribeToken())
  }, [])

  useEffect(() => {
    if (serializedServiceDate) {
      getRoutesMetadata({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
    }
  }, [serializedServiceDate])

  useEffect(() => {
    if (!isSuccess) return
    const routes = get(routeMetaData, 'routes', [])
    const stopsByRouteId = routes.reduce((acc, route) => {
      const routeId = get(route, 'id')
      const stopCount = get(route, 'stopCount', 0)
      acc[routeId] = stopCount
      return acc
    }, {})
    const allRouteIds = Object.keys(stopsByRouteId)
    dispatch(setStopsCountByRouteId(stopsByRouteId))
    dispatch(setAllRouteIds(allRouteIds))
    dispatch(setAllRoutes(routes))
    if (Array.isArray(selectedRouteIds)) {
      const filteredSelectedRouteIds = selectedRouteIds.filter(selectedId => allRouteIds.includes(selectedId))
      dispatch(selectRouteIds(filteredSelectedRouteIds))
    }
  }, [routeMetaData, isSuccess])

  useEffect(() => {
    handleAddressSelected(null)
    handleResetSearch(false)
  }, [resetSearch])

  useEffect(() => {
    if (selectedRouteIds === null && Array.isArray(allRouteIds) && allRouteIds.length !== 0) {
      dispatch(selectRouteIds(allRouteIds))
    }
  }, [selectedRouteIds, allRouteIds])

  useEffect(() => {
    if (viewMode === VIEW_MODE_SEQUENCE || !showContent) {
      map.current = undefined
    }
  }, [viewMode, showContent])

  return (
    <Box width="100vw" className={className}>
      <TabContext value={viewMode}>
        <FormProvider {...filtersForm}>
          <MapProvider map={map}>
            <RouteManagerAppBar />
            {showContent && (
              <Box>
                <TabPanel value={VIEW_MODE_SEQUENCE}>
                  <SequenceContainer />
                </TabPanel>
                <TabPanel value={VIEW_MODE_MAP}>
                  <RouteManagerMap />
                </TabPanel>
              </Box>
            )}
          </MapProvider>
        </FormProvider>
      </TabContext>
    </Box>
  )
}

export default RoutesManager
