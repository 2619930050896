import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useCreateAccountGroupMutation } from 'api/groups/account/createAccountGroup'
import { useUpdateAccountGroupMutation } from 'api/groups/account/updateAccountGroup'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'

const AddEditAccountGroupDialog = ({ isOpen = false, existingGroup = null, onSuccess = noop, onClose }) => {
  const [createAccountGroup, { isLoading: isCreateLoading }] = useCreateAccountGroupMutation()
  const [updateAccountGroup, { isLoading: isUpdateLoading }] = useUpdateAccountGroupMutation()
  const accountGroupForm = useForm({ defaultValues: { groupName: '' } })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = accountGroupForm

  const groupNameHelperText = get(errors, 'groupName.message')

  const handleSuccess = (response, message) => {
    onSuccess(response.groupId)
    toast.success(message)
    onClose()
  }

  const onSubmitHandler = data => {
    const existingGroupId = get(existingGroup, 'groupId')
    const payload = { groupName: data?.groupName }
    if (existingGroupId) {
      // Edit group
      updateAccountGroup({ accountGroupId: existingGroupId, body: payload })
        .unwrap()
        .then(response => handleSuccess(response, T.ACCOUNT_GROUP_UPDATED_SUCCESS_MSG))
        .catch(handleError)
      return
    }

    // Add group
    createAccountGroup(payload)
      .unwrap()
      .then(response => handleSuccess(response, T.ACCOUNT_GROUP_CREATED_SUCCESS_MSG))
      .catch(handleError)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      handleSubmit(onSubmitHandler)()
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({ groupName: get(existingGroup, 'groupName', '') })
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={existingGroup ? T.EDIT_ACCOUNT_GROUP : T.NEW_ACCOUNT_GROUP} onClose={onClose} />
      <DialogContent>
        <FormProvider {...accountGroupForm}>
          <Box mt={2}>
            <HHFormTextField
              autoFocus
              error={groupNameHelperText}
              helperText={groupNameHelperText}
              control={control}
              rules={{ required: T.CANNOT_BE_EMPTY }}
              name="groupName"
              label={T.ACCOUNT_GROUP_NAME}
              placeholder={T.ACCOUNT_GROUP_NAME}
              fullWidth
              deprecatedLabel={false}
              onKeyDown={handleKeyDown}
            />
          </Box>
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton
          loading={isCreateLoading || isUpdateLoading}
          size="small"
          variant="contained"
          onClick={handleSubmit(onSubmitHandler)}
        >
          {existingGroup ? T.SAVE : T.CREATE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddEditAccountGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  existingGroup: PropTypes.string,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

export default AddEditAccountGroupDialog
