import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Tag from 'components/tags/Tag'
import CommonNoOptionsMsg from 'components/common/CommonNoOptionsMsg'
import T from 'T'
import { TextField } from '@mui/material'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const LIST_HEIGHT = 200
const alphabeticTagSort = tags => tags.sort((a, b) => a.tagName.localeCompare(b.tagName))

const TagsSelect = ({ tags, label, deprecatedLabel = true, selectedTags, limit = -1, onChange = noop }) => {
  const renderTags = (renderedTags = [], getTagProps = noop) =>
    renderedTags.map((tag, index) => <Tag {...getTagProps({ index })} key={index} tag={tag} />)

  const renderOptionTag = (optionProps, tagOption, { selected }) => (
    <MenuItem
      {...{
        ...optionProps,

        // remove default css class, otherwise it is not possible to change the padding according to the design requirements
        className: '',
      }}
      sx={{
        paddingLeft: theme => theme.spacing(0.5),
        paddingRight: theme => theme.spacing(0.5),
      }}
    >
      <Checkbox size="small" icon={icon} checkedIcon={checkedIcon} checked={selected} />
      <Tag tag={tagOption} size="small" />
    </MenuItem>
  )

  return (
    <>
      <Autocomplete
        sx={{
          boxSizing: 'content-box',
          '& input': {
            boxSizing: 'content-box',
            flexBasis: '200px',
          },
        }}
        limitTags={limit}
        options={alphabeticTagSort(tags)}
        onChange={onChange}
        size="small"
        getOptionLabel={option => option?.tagName}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        defaultValue={selectedTags}
        popupIcon={<KeyboardArrowDownIcon size="small" />}
        noOptionsText={<CommonNoOptionsMsg text="the typed tag" />}
        ListboxProps={{
          style: {
            maxHeight: LIST_HEIGHT,
            overflow: 'auto',
          },
        }}
        renderInput={params => <TextField {...params} placeholder={T.FILTER_TAGS_PLACEHOLDER} label={label} />}
        renderTags={renderTags}
        renderOption={renderOptionTag}
        openOnFocus
        fullWidth
        disableCloseOnSelect
        multiple
      />
    </>
  )
}

TagsSelect.propTypes = {
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array,
  label: PropTypes.string,
  deprecatedLabel: PropTypes.bool,
  limit: PropTypes.number,
  onChange: PropTypes.func,
}

export default TagsSelect
