import React from 'react'
import PropTypes from 'prop-types'

import { NumericFormat } from 'react-number-format'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import AutoComplete from 'components/common/AutoComplete'
import PricingSwitch from 'components/pricing/PricingSwitch'
import ActionAutoComplete from 'components/pricing/ActionAutoComplete'
import MeasureMaterialAutoComplete from 'components/pricing/MeasureMaterialAutoComplete'
import GeneralLedgerAutoComplete from 'components/pricing/GeneralLedgerAutoComplete'
import MethodDaysAutoComplete from 'components/pricing/MethodDaysAutoComplete'
import PrizingZoneAutoComplete from 'components/pricing/PrizingZoneAutoComplete'
import { getRecurrenceLabel } from 'components/pricing/pricingMethods'

import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'

import { get } from 'utils/lodash'

import T from 'T'

const DisposalFeesFormScreen = ({
  tabValue,
  globalFilterOptions,
  handleCheckboxValue,
  checkedValues,
  feeInput,
  handleDisposalSwitchVal,
  onHandleChange,
  inputDisposalText,
  backFlag,
}) => {
  const getFeeName = () => {
    return inputDisposalText.disposalFeeName || feeInput[tabValue]
  }

  const {
    checkedMethods,
    checkedMaterials,
    checkedMeasure,
    checkedRecurrence,
    checkedServiceAction,
    checkedDays,
    checkedPricingZones,
    checkedGeneralLedger,
  } = (() => {
    return {
      checkedMethods: get(checkedValues, 'methods', []),
      checkedMaterials: get(checkedValues, 'materials', []),
      checkedMeasure: get(checkedValues, 'measures', []),
      checkedRecurrence: get(checkedValues, 'recurrence', []),
      checkedServiceAction: get(checkedValues, 'actions', []),
      checkedDays: get(checkedValues, 'specificDays', []),
      checkedPricingZones: get(checkedValues, 'pricingZones', []),
      checkedGeneralLedger: get(checkedValues, 'generalLedgerAccount', []),
    }
  })()

  return (
    <div className="grid-container flex new-fee-form">
      <div className="left-section">
        <div className="value left-elements-width">
          <div className="service-label">Minimum Fee</div>

          <NumericFormat
            id="outlined-size-small"
            className="normal-textfield service-text-field price-field"
            placeholder="0.00"
            value={inputDisposalText.disposalMinFee}
            variant="outlined"
            size="small"
            autoComplete="off"
            name="disposalMinFee"
            onChange={e => onHandleChange(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            customInput={TextField}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
          />
        </div>

        <div className="overage-field left-elements-width mt15">
          <div className="overage">
            <div className="service-label">Overage</div>

            <NumericFormat
              id="outlined-size-small"
              className="normal-textfield service-text-field price-field"
              placeholder="0"
              value={inputDisposalText.disposalOverage}
              variant="outlined"
              size="small"
              autoComplete="off"
              name="disposalOverage"
              isNumeric
              onChange={e => onHandleChange(e)}
              customInput={TextField}
              thousandSeparator
              decimalSeparator={false}
            />
          </div>

          <div className="overage-fee">
            <div className="service-label">Fee</div>

            <NumericFormat
              id="outlined-size-small"
              className="normal-textfield service-text-field price-field"
              placeholder="0.00"
              value={inputDisposalText.disposalOverageFee}
              variant="outlined"
              size="small"
              autoComplete="off"
              name="disposalOverageFee"
              onChange={e => onHandleChange(e)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              customInput={TextField}
              thousandSeparator
              decimalSeparator="."
              decimalScale={2}
            />
          </div>
        </div>

        <div className="value left-elements-width mt15">
          <div className="service-label">Price</div>

          <NumericFormat
            id="outlined-size-small"
            className="normal-textfield service-text-field price-field"
            placeholder="0.00"
            value={inputDisposalText.disposalValue}
            variant="outlined"
            size="small"
            autoComplete="off"
            name="disposalValue"
            onChange={e => onHandleChange(e)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            customInput={TextField}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
          />
        </div>

        <div className="cost-plus">
          <div className="switch-section">
            <div className="switch">
              <PricingSwitch
                checked={inputDisposalText.costPlus}
                handleSwitchVal={() => handleDisposalSwitchVal('costPlus', !inputDisposalText.costPlus)}
              />
            </div>
            <div className="switch-label">Cost Plus</div>
          </div>
        </div>

        <div className="fee-auto-link">
          <div className="switch-section">
            <div className="switch">
              <PricingSwitch
                checked={inputDisposalText.autoLink}
                handleSwitchVal={() => handleDisposalSwitchVal('autoLink', !inputDisposalText.autoLink)}
              />
            </div>
            <div className="switch-label">Auto-Link </div>
          </div>
        </div>
      </div>

      <div className="vertical-line" />

      <div className="right-section">
        <div className="service left-elements-width">
          <div className="service-label">
            {T.DISPOSAL_FEE}
            <span className="red ml1">*</span>
          </div>
          <TextField
            id="outlined-size-small"
            placeholder={T.DISPOSAL_FEE}
            value={getFeeName()}
            name="disposalFeeName"
            autoFocus
            variant="outlined"
            size="small"
            autoComplete="off"
            className="normal-textfield service-text-field"
            InputProps={{
              onChange: e => onHandleChange(e),
            }}
          />
        </div>

        <MeasureMaterialAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMeasure={checkedMeasure}
          checkedMaterials={checkedMaterials}
          backFlag={backFlag}
          type="disposal"
        />

        <GeneralLedgerAutoComplete checkedValues={checkedGeneralLedger} handleCheckboxValue={handleCheckboxValue} backFlag={backFlag} />

        <div className="recurrence left-elements-width mt15">
          <div className="service-label">{T.RECURRENCE}</div>

          <div className="recur-block">
            <div className="auto-complete">
              <AutoComplete
                backFlag={backFlag}
                maxHeight={180}
                isHandleCheckbox
                metaData={get(globalFilterOptions, 'recurrence', [])}
                label={T.RECURRENCE}
                getByLabelText={option => getRecurrenceLabel(option)}
                handleCheckboxValue={handleCheckboxValue}
                checkedRecords={checkedRecurrence}
                getPlaceholder={checkedRecurrence.length ? '' : `Select ${T.RECURRENCE}`}
              />
            </div>
          </div>
        </div>

        <div className="mt15">
          <ActionAutoComplete
            globalFilterOptions={globalFilterOptions}
            getByLabelText={getByLabelText}
            handleCheckboxValue={handleCheckboxValue}
            checkedServiceAction={checkedServiceAction}
            backFlag={backFlag}
          />
        </div>

        <MethodDaysAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedMethods={checkedMethods}
          checkedDays={checkedDays}
          backFlag={backFlag}
        />

        <PrizingZoneAutoComplete
          globalFilterOptions={globalFilterOptions}
          getByLabelText={getByLabelText}
          handleCheckboxValue={handleCheckboxValue}
          checkedPricingZones={checkedPricingZones}
          backFlag={backFlag}
        />
      </div>
    </div>
  )
}

DisposalFeesFormScreen.defaultProps = {
  tabValue: 'recurring',
  globalFilterOptions: {},
  feeInput: {},
  inputDisposalText: {},
  checkedValues: {},
  backFlag: false,
  handleCheckboxValue: null,
  handleDisposalSwitchVal: null,
  onHandleChange: null,
}

DisposalFeesFormScreen.propTypes = {
  tabValue: PropTypes.string,
  globalFilterOptions: PropTypes.object,
  feeInput: PropTypes.object,
  inputDisposalText: PropTypes.object,
  checkedValues: PropTypes.object,
  backFlag: PropTypes.bool,
  handleCheckboxValue: PropTypes.func,
  handleDisposalSwitchVal: PropTypes.func,
  onHandleChange: PropTypes.func,
}

export default DisposalFeesFormScreen
