import React, { useEffect, useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import { Typography, Popper, styled, Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { get } from 'utils/lodash'
import { isUnPaidInvoice } from 'utils/billing'

import { useGetSuggestedAccountsMutation } from 'api/accounts/getSuggestedAccounts'
import { useLazyGetInvoiceBySearchQuery } from 'api/billing/getInvoiceBySearch'

import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import StyledTextField from 'components/form-fields/v5/StyledTextField'
import HHAutocompleteField from 'components/form-fields/v5/HHAutocompleteField'
import SearchCustomerOptionRow from './SearchCustomerOptionRow'
import SearchInvoiceOptionRow from './SearchInvoiceOptionRow'
import { SEARCH_TYPES, SEARCH_OPTIONS } from './model'

const StyledPopper = styled(Popper)({
  '& ul': { maxHeight: '60vh!important' },
})

const SearchDropdown = forwardRef((props, ref) => {
  const {
    searchType = SEARCH_TYPES.CUSTOMER,
    editPaymentRefId = '',
    searchValue = '',
    selectedOption = null,
    onChange,
    onSearchChange,
  } = props
  const [getSuggestedAccounts, { data: accounts, isLoading: isAccountSearchLoading }] = useGetSuggestedAccountsMutation()
  const [getInvoiceBySearch, { data: invoices, isFetching: isInvoiceSearchFetching, isLoading: isInvoiceSearchLoading }] =
    useLazyGetInvoiceBySearchQuery()

  const isLoading = isAccountSearchLoading || isInvoiceSearchFetching || isInvoiceSearchLoading
  const isCustomerSearchSelected = searchType === SEARCH_TYPES.CUSTOMER
  const isInvoiceSearchSelected = searchType === SEARCH_TYPES.INVOICE
  const options = isCustomerSearchSelected
    ? get(accounts, 'accounts', [])
    : get(invoices, 'invoices', []).filter(({ status }) => isUnPaidInvoice(status))

  const handleCustomerSearch = useMemo(() => debounce(value => getSuggestedAccounts({ search: value }), 300), [])
  const handleInvoiceSearch = useMemo(
    () => debounce(value => getInvoiceBySearch({ pageSize: 20, pageNumber: 0, invoiceNumber: value }), 600),
    []
  )

  const handleSetFocus = () => setTimeout(() => ref?.current?.focus(), 100)
  const getNoOptionsTextMessage = () => {
    if (!searchValue || (isCustomerSearchSelected && !accounts) || (isInvoiceSearchSelected && !invoices)) {
      return 'Start typing to search'
    }

    return 'No results'
  }

  const noOptionsText = getNoOptionsTextMessage()

  const handleInputChange = (event, value, reason) => {
    if (reason === 'reset' && !value) return
    onChange('searchValue', value)

    if (event?.type !== 'change' || !value) return

    if (isCustomerSearchSelected) {
      handleCustomerSearch(value)
      return
    }
    handleInvoiceSearch(value)
  }

  useEffect(() => {
    if (!editPaymentRefId) {
      handleSetFocus()
    }

    return () => {
      handleCustomerSearch.cancel()
      handleInvoiceSearch.cancel()
    }
  }, [])

  const renderOption = (params, option) => (
    <Box {...params} display="flex">
      {isCustomerSearchSelected ? <SearchCustomerOptionRow row={option} /> : <SearchInvoiceOptionRow row={option} />}
    </Box>
  )

  return (
    <Box my={1} display="flex">
      <HHAutocompleteField
        loading={isLoading}
        inputValue={searchValue}
        sx={{ width: '80%', '& .MuiInputBase-root ': { 'border-top-right-radius': 0, 'border-bottom-right-radius': 0 } }}
        blurOnSelect
        openOnFocus
        deprecatedLabel={false}
        filterOptions={x => x}
        options={searchValue && !isLoading ? options : []}
        getOptionLabel={option => (isCustomerSearchSelected ? get(option, 'accountName', '') : get(option, 'invoiceNumber', ''))}
        renderOption={renderOption}
        renderInput={params => (
          <StyledTextField
            {...params}
            inputRef={ref}
            autoFocus={!editPaymentRefId}
            placeholder="Search ..."
            InputProps={{ ...params.InputProps, startAdornment: <SearchIcon sx={{ color: 'text.secondary' }} /> }}
          />
        )}
        PopperComponent={StyledPopper}
        onChange={(event, value) => onSearchChange(value)}
        onInputChange={handleInputChange}
        value={selectedOption}
        noOptionsText={noOptionsText}
      />
      <HHTextField
        select
        sx={{ width: '20%', '& .MuiInputBase-root ': { 'border-top-left-radius': 0, 'border-bottom-left-radius': 0 } }}
        value={[searchType]}
        onChange={event => {
          onChange('searchType', event.target.value)
          onChange('searchValue', '')
          onSearchChange(null)
          handleSetFocus()
        }}
      >
        {SEARCH_OPTIONS.map(search => (
          <SelectItem value={search}>
            <Typography noWrap>{search}</Typography>
          </SelectItem>
        ))}
      </HHTextField>
    </Box>
  )
})

SearchDropdown.propTypes = {
  searchType: PropTypes.string,
  editPaymentRefId: PropTypes.string,
  searchValue: PropTypes.string,
  selectedOption: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
}

export default SearchDropdown
