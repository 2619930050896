import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Table as TableView, TableContainer } from '@mui/material'

import OfficeTableHeader from 'components/settings/pages/users/roles/OfficeTableHeader'
import OfficeTableBody from 'components/settings/pages/users/roles/OfficeTableBody'

import { ACCESS_VALUES } from 'settings/constants/roles'

const Office = ({ roleState = {}, setRoleState = noop, readonly = '', calledBy = '' }) => {
  const setMaxHeight = { xs: 'calc(100vh - 440px)', md: 'calc(100vh - 395px)' }
  return (
    <TableContainer sx={{ height: calledBy ? setMaxHeight : 'calc(100vh - 295px)' }}>
      {roleState.allowOfficeLogin && (
        <TableView stickyHeader aria-label="sticky table" sx={{ pointerEvents: readonly }}>
          <OfficeTableHeader values={ACCESS_VALUES} />
          <OfficeTableBody roleState={roleState} setRoleState={setRoleState} />
        </TableView>
      )}
    </TableContainer>
  )
}

Office.propTypes = {
  calledBy: PropTypes.string,
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
  readonly: PropTypes.string,
}

export default Office
