import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography, Button, Box, MenuItem } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Add from '@mui/icons-material/Add'
import HHTextField from 'components/form-fields/v5/HHTextField'

import T from 'T'

import { PAYMENT_TERMS } from 'settings/constants/billing'
import { get } from 'utils/lodash'
import { isNumberOnly, isZero } from 'utils/validations'
import { canAddSettings } from 'data/permissions/permissionsSelectors'

const PaymentTerms = ({ generalState = {}, handleRemoval = noop, addMore = noop, setGeneralState = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const { paymentTerms } = generalState

  const onHandleChange = (e, index) => {
    if (paymentTerms[index] === undefined) {
      paymentTerms[index] = {}
    }

    paymentTerms[index].term = e.target.value === 'Net [X] Days' ? 'Net' : e.target.value

    setGeneralState({ paymentTerms })
  }

  const onHandleNetValChange = (e, type, index) => {
    if (type === 'interval' && e.target.value !== '' && !isNumberOnly(e.target.value)) return

    if (paymentTerms[index] === undefined) {
      paymentTerms[index] = {}
    }

    paymentTerms[index][type] = e.target.value

    setGeneralState({ paymentTerms })
  }
  return (
    <Box>
      <Typography variant="subtitle1">{T.PAYMENT_TERMS}</Typography>

      {paymentTerms.map((paymentTerm, index) => {
        let mainTitle = get(paymentTerm, 'term', '')
        mainTitle = mainTitle === 'Net' ? 'Net [X] Days' : mainTitle

        return (
          <Box display="flex" mt={1} maxWidth={500} sx={{ pointerEvents: paymentTerm.id === '' ? '' : 'none' }}>
            <HHTextField select value={[mainTitle]} onChange={e => onHandleChange(e, index)} sx={{ minWidth: 385 }}>
              <MenuItem value="">{T.SELECT}</MenuItem>
              {PAYMENT_TERMS.map(data => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </HHTextField>

            {mainTitle === 'X% / Y, Net Z Days' && (
              <Box display="flex" alignItems="center" ml={2}>
                <HHTextField
                  placeholder="Z%"
                  value={get(paymentTerms[index], 'z-val', '')}
                  variant="outlined"
                  autoComplete="off"
                  name="z-val"
                  size="small"
                  sx={{
                    m: '0 5px',
                    minWidth: 50,
                  }}
                  InputProps={{
                    onChange: e => onHandleNetValChange(e, 'z-val', index),
                  }}
                />
                /
                <HHTextField
                  placeholder="Y"
                  value={get(paymentTerms[index], 'y-val', '')}
                  variant="outlined"
                  autoComplete="off"
                  name="y-val"
                  size="small"
                  sx={{
                    m: '0 5px',
                  }}
                  InputProps={{
                    onChange: e => onHandleNetValChange(e, 'y-val', index),
                  }}
                />
                ,
              </Box>
            )}

            {mainTitle === 'Net [X] Days' && (
              <Box display="flex" alignItems="center" ml={2} maxWidth={105}>
                <Typography variant="subtitle1">{T.NET}</Typography>

                <HHTextField
                  placeholder="X"
                  value={get(paymentTerm, 'interval', '')}
                  variant="outlined"
                  autoComplete="off"
                  name="interval"
                  size="small"
                  sx={{
                    m: '0 5px',
                    minWidth: 45,
                  }}
                  InputProps={{
                    onChange: e => !isZero(e.target.value) && onHandleNetValChange(e, 'interval', index),
                  }}
                />
                <Typography variant="subtitle1">{`${T.DAY}s`}</Typography>
              </Box>
            )}

            {paymentTerm.id === '' && (
              <CancelOutlinedIcon
                fontSize="small"
                onClick={() => handleRemoval(index, 'paymentTerms')}
                sx={{ color: 'text.secondary', m: 'auto 24px', cursor: 'pointer' }}
              />
            )}
          </Box>
        )
      })}

      {addSettings && (
        <Button variant="text" color="primary" startIcon={<Add />} onClick={() => addMore('paymentTerms')} sx={{ mt: 0.5 }}>
          {T.ADD}
        </Button>
      )}
    </Box>
  )
}

PaymentTerms.propTypes = {
  generalState: PropTypes.object,
  handleRemoval: PropTypes.func,
  addMore: PropTypes.func,
  setGeneralState: null,
}

export default PaymentTerms
