import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { grey } from '@mui/material/colors'
import { Typography, Box, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getPaymentMethodImage, getPaymentMethodName } from 'utils/payment'
import { getSavedMethodsByPaymentType } from 'utils/takePayments'

const CreditCardAchListing = ({ type, selectedPaymentTokenDetails = {}, paymentMethods = [], onPaymentMethodSelect }) => {
  const paymentMethodByType = getSavedMethodsByPaymentType(paymentMethods, type)

  const selectedPaymentToken = get(selectedPaymentTokenDetails, 'accountToken')
  return (
    <Box>
      {Children.toArray(
        paymentMethodByType.map(method => {
          const accountToken = get(method, 'accountToken', '')
          const accountType = get(method, 'accountType', '')
          const creditCardType = get(method, 'creditCardType')
          const methodName = getPaymentMethodName(creditCardType, accountType)
          const cardImage = getPaymentMethodImage(creditCardType || methodName)
          const expiry = get(method, 'expiry', '')
          const accountNumber = get(method, 'accountNumber', '')
          const cardNickName = get(method, 'cardNickName', '')

          return (
            <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
              <FormControl sx={{ '& label': { mr: 0.5 } }}>
                <RadioGroup
                  value={selectedPaymentToken === accountToken ? selectedPaymentToken : ''}
                  // For credit card account type is null, if ACH possible values Saving or Checking
                  onChange={() => onPaymentMethodSelect(method)}
                >
                  <FormControlLabel value={accountToken} control={<Radio />} />
                </RadioGroup>
              </FormControl>
              <Box
                border="1px solid"
                borderColor={grey[200]}
                borderRadius={1}
                height={40}
                padding={1.5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box display="flex" alignItems="center" width="80%">
                  <img src={cardImage} alt="" />
                  <Typography ml={1}>{cardNickName || `${creditCardType || accountType} •••• ${accountNumber}`}</Typography>
                </Box>

                {/* 4 means MMYY */}
                {expiry && expiry.length === 4 && (
                  <Typography variant="subtitle2" fontWeight={400}>{`Expires ${expiry.substr(0, 2)}/${expiry.substr(2)}`}</Typography>
                )}
              </Box>
            </Box>
          )
        })
      )}
    </Box>
  )
}

CreditCardAchListing.propTypes = {
  type: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array,
  selectedPaymentTokenDetails: PropTypes.object,
  onPaymentMethodSelect: PropTypes.func.isRequired,
}

export default memo(CreditCardAchListing)
