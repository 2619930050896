import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Card } from '@mui/material'

import { get } from 'utils/lodash'
import { useLazyGetEmailEventsQuery } from 'api/accounts/getEmailEvents'
import { getPaymentReceiptEmailEvents } from 'data/email/emailEventsSelectors'
import { handleError } from 'utils/error'

import PaymentActivityFeed from '../../../common/PaymentActivityFeed'
import { PAYMENT_ACTIVITY_TABS_ID } from '../../../../customer-details/content/payments/settings'

const { INVOICE_TAB_ID } = PAYMENT_ACTIVITY_TABS_ID

const PaymentActivityFeedWrapper = ({
  accountId,
  invoices = [],
  paymentNumber,
  transactionStatus = '',
  accountCredit = {},
  billingProfileDetails = {},
}) => {
  const [selectedSubTab, setSelectedSubTab] = useState(INVOICE_TAB_ID)
  const [getEmailEvents, { data: emailEventList }] = useLazyGetEmailEventsQuery()
  const emailEvents = getPaymentReceiptEmailEvents({ emailEvents: get(emailEventList, 'emailEvents', []) })
  const filteredEmailEvents = emailEvents.filter(event => event?.paymentNumber === paymentNumber)

  const handleTabChange = (event, newValue) => setSelectedSubTab(newValue)

  useEffect(() => {
    if (!accountId) return
    getEmailEvents({ accountId }).unwrap().catch(handleError)
  }, [accountId])

  return (
    <Box px={3}>
      <Card variant="outlined">
        <PaymentActivityFeed
          invoices={invoices}
          accountCredit={accountCredit}
          transactionStatus={transactionStatus}
          billingProfileDetails={billingProfileDetails}
          emailEvents={filteredEmailEvents}
          selectedTab={selectedSubTab}
          onSelectedTabChange={handleTabChange}
        />
      </Card>
    </Box>
  )
}

PaymentActivityFeedWrapper.propTypes = {
  accountId: PropTypes.string.isRequired,
  invoices: PropTypes.array,
  paymentNumber: PropTypes.number,
  transactionStatus: PropTypes.string,
  accountCredit: PropTypes.object,
  billingProfileDetails: PropTypes.object,
}

export default PaymentActivityFeedWrapper
