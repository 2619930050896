import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateWorkOrderInvoice: builder.mutation({
      query: body => ({ url: `/api/workorder/invoice/update`, method: 'POST', body }),
    }),
  }),
})

export const { useUpdateWorkOrderInvoiceMutation } = extendedApi
