import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Card, CardContent } from '@mui/material'

const CommonCard = ({ title = '-', description = '-', sx = undefined, onHandleClick }) => {
  const defaultStyle = { cursor: 'pointer', minHeight: 160 }
  return (
    <Card sx={{ ...defaultStyle, ...sx }} onClick={() => onHandleClick()}>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
    </Card>
  )
}

CommonCard.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  onHandleClick: PropTypes.func.isRequired,
}

export default CommonCard
