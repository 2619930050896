import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { get } from 'utils/lodash'
import ToBeInsertedMarker from 'assets/map/ToBeInsertedMarker.png'
import CanceledMarker from 'assets/map/CanceledMarker.png'
import HoldMarker from 'assets/map/HoldMarker.png'
import InProgressMarker from 'assets/map/InProgressMarker.png'
import ScheduledMarker from 'assets/map/ScheduledMarker.png'
import ServicedMarker from 'assets/map/ServicedMarker.png'
import SkippedMarker from 'assets/map/SkippedMarker.png'
import { addAssetsImageToMap, addPositionLayers, addSourceAsync, BOTTOM_LAYER, getBounds, MIDDLE_LAYER, TOP_LAYER } from 'utils/map'
import Loader from 'components/common/loader'
import { getMetadataRoutes } from 'data/route/routeMetadataSelector'
import { common } from '@mui/material/colors'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import { UNASSIGNED_ROUTE_STOPS_LAYOUT, WO_COLOR_STOPS_LAYOUT } from 'components/route-manager/RouteManagerMap/settings'
import mapboxgl from '!mapbox-gl'

const MAX_ZOOM = 16

const MapContainer = styled('div')({
  top: 0,
  right: 0,
  width: '100%',
  height: 'calc(100vh - 121.75px)',
  overflow: 'hidden',
  zIndex: 1,
})

const { MAP_VIEW_URL, LIGHT_VIEW, PROJECTION } = MAP_DEFAULT_OPTIONS

const SequenceMap = ({ routeGeojsonData, toBeAssignedGeojsonData, selectedRoute }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const userInfo = useSelector(s => s.AuthReducer.userInfo)
  const allRoutes = useSelector(getMetadataRoutes)
  const stopToBeAssigned = useSelector(s => s.routingActions.assignStopDialog.stopToBeAssigned, shallowEqual)
  const { latitude, longitude } = stopToBeAssigned
  const toBeassignedCoord = [longitude, latitude]
  const {
    watch,
    formState: { isDirty },
  } = useFormContext()
  const sequence = watch('sequence')

  const routeColorsById = allRoutes.reduce((colorMap, route) => {
    // eslint-disable-next-line no-param-reassign
    colorMap[route.id] = route.color
    return colorMap
  }, {})
  const routeColor = get(routeColorsById, selectedRoute, '#89B0D0')
  const map = useRef(null)
  const mapContainer = useRef(null)

  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `${MAP_VIEW_URL}${LIGHT_VIEW}`,
        center: [-98.5795, 39.82835],
        zoom: 3.6,
        projection: PROJECTION,
      })
      map.current.addControl(new mapboxgl.FullscreenControl())

      map.current.once('load', () => {
        addAssetsImageToMap(map.current, 'canceled-marker', CanceledMarker)
        addAssetsImageToMap(map.current, 'hold-marker', HoldMarker)
        addAssetsImageToMap(map.current, 'in-progress-marker', InProgressMarker)
        addAssetsImageToMap(map.current, 'scheduled-marker', ScheduledMarker)
        addAssetsImageToMap(map.current, 'serviced-marker', ServicedMarker)
        addAssetsImageToMap(map.current, 'skipped-marker', SkippedMarker)
        addAssetsImageToMap(map.current, 'to-be-assigned-marker', ToBeInsertedMarker)
        setIsMapLoaded(true)
        addPositionLayers(map.current)
      })
    }
  }, [])

  useEffect(() => {
    const mapCurrentRef = map.current
    if (!isMapLoaded || !routeGeojsonData || routeGeojsonData.length === 0) return
    const coordinates = routeGeojsonData.map(feature => feature.geometry.coordinates)
    addSourceAsync(
      mapCurrentRef,
      'lines',
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
      },
      {
        type: 'geojson',
      }
    ).then(() => {
      mapCurrentRef.addLayer(
        {
          id: 'lines-layer',
          type: 'line',
          source: 'lines',
          paint: {
            'line-color': routeColor,
            'line-width': 2,
          },
        },
        BOTTOM_LAYER
      )
    })

    addSourceAsync(
      mapCurrentRef,
      'stops',
      { type: 'FeatureCollection', features: routeGeojsonData },
      {
        type: 'geojson',
      }
    ).then(() => {
      mapCurrentRef.addLayer(
        {
          id: 'stops-layer-customers',
          type: 'symbol',
          source: 'stops',
          layout: WO_COLOR_STOPS_LAYOUT,
          paint: {
            'text-color': common.black, // Adjust the color if needed
          },
        },
        MIDDLE_LAYER
      )
      const boundingCoordinates = [...coordinates, toBeassignedCoord]
      if (Array.isArray(boundingCoordinates) && boundingCoordinates.length !== 0) {
        const bounds = getBounds(boundingCoordinates)
        mapCurrentRef.fitBounds(bounds, {
          padding: { top: 100, bottom: 100, left: 100, right: 100 },
          linear: true,
        })
      }
    })
    addSourceAsync(
      mapCurrentRef,
      'to-be-assigned-marker',
      { type: 'FeatureCollection', features: toBeAssignedGeojsonData },
      {
        type: 'geojson',
      }
    ).then(() => {
      mapCurrentRef.addLayer({
        id: 'to-be-assigned-marker-layer',
        type: 'symbol',
        source: 'to-be-assigned-marker',
        layout: UNASSIGNED_ROUTE_STOPS_LAYOUT,
      })
    }, TOP_LAYER)
  }, [isMapLoaded])

  useEffect(() => {
    const mapCurrentRef = map.current
    if (!isMapLoaded || !routeGeojsonData || routeGeojsonData.length === 0) return
    const lines = mapCurrentRef.getSource('lines')
    const stops = mapCurrentRef.getSource('stops')
    if (!lines || !stops) return
    if (mapCurrentRef) {
      stops.setData({
        type: 'FeatureCollection',
        features: routeGeojsonData,
      })
      const routeCoordinates = routeGeojsonData.map(feature => feature.geometry.coordinates)
      const toBeAssignCoordinates = toBeAssignedGeojsonData.map(feature => feature.geometry.coordinates)
      const allCoordinates = [...routeCoordinates, ...toBeAssignCoordinates]
      lines.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: routeCoordinates,
        },
      })

      const toBeInserted = routeGeojsonData.find(feature => feature.properties.toBeInserted)
      if (toBeInserted && sequence > 0 && isDirty) {
        const toBeInsertedCoordinates = get(toBeInserted, 'geometry.coordinates', [])
        const bounds = getBounds([toBeInsertedCoordinates, toBeInsertedCoordinates])
        mapCurrentRef.fitBounds(bounds, { linear: true, maxZoom: MAX_ZOOM })
        return
      }

      if (Array.isArray(allCoordinates) && allCoordinates.length !== 0) {
        const bounds = getBounds(allCoordinates)
        mapCurrentRef.fitBounds(bounds, {
          padding: { top: 25, bottom: 25, left: 25, right: 25 },
          linear: true,
        })
      }
    }
  }, [routeGeojsonData, selectedRoute, isMapLoaded])

  useEffect(() => {
    return () => {
      if (map.current) {
        map.current.remove()
        map.current = null
      }
    }
  }, [])

  useEffect(() => {
    const mapCurrentRef = map.current
    const linesLayer = mapCurrentRef.getLayer('lines-layer')
    if (map.current && isMapLoaded && linesLayer) {
      map.current.setPaintProperty('lines-layer', 'line-color', routeColor)
    }
  }, [routeColor, isMapLoaded])

  useEffect(() => {
    const mapCurrentRef = map.current
    const toBeAssignedSource = mapCurrentRef.getSource('to-be-assigned-marker')
    if (!isMapLoaded || !toBeAssignedSource) return
    toBeAssignedSource.setData({
      type: 'FeatureCollection',
      features: toBeAssignedGeojsonData,
    })
  }, [toBeAssignedGeojsonData])

  return (
    <Box position="relative" height="100%">
      {!isMapLoaded && <Loader sx={{ top: 0, width: '100%', minWidth: '100%', maxWidth: '100%' }} />}
      <MapContainer ref={mapContainer} />
    </Box>
  )
}

SequenceMap.propTypes = {
  routeGeojsonData: PropTypes.array,
  toBeAssignedGeojsonData: PropTypes.array,
  selectedRoute: PropTypes.string,
}

export default SequenceMap
