import sum from 'lodash/sum'
import { get } from 'utils/lodash'
import { RECURRENCE } from 'settings/constants/service'
import { calculateDaysDiffFromStringDates, formatDateToBEFormatDateFns } from 'utils/date'

import T from 'T'
import { getServiceFeeTax } from 'data/service/serviceSelectors'

const { ON_REQUEST, RECURRING } = RECURRENCE

const PRICE_TYPE = new Map([
  ['%', '%'],
  ['$', '$'],
  ['percentage', '%'],
  ['dollar', '$'],
  // later change this to   ['', ''], -- so that we can also handle empty case from here
  ['', '$'],
  ['default', '$'],
])

export const getPriceType = (type = 'default') => PRICE_TYPE.get((type || '').toLowerCase())

export const isPriceTypeDollar = type => getPriceType(type) === '$'

export const isPriceTypePercentage = type => getPriceType(type) === '%'

export const getPriceWithSymbol = (type, price) => (isPriceTypeDollar(type) ? `$${parseFloat(price).toFixed(2)}` : `${price}%`)

export const getPricingPeriod = (periodPer, periodInterval) => {
  switch (periodInterval) {
    case 'Hour':
      return periodPer > 1 ? `${periodPer} Hours` : 'Hourly'
    case 'Day':
      return periodPer > 1 ? `${periodPer} Days` : 'Daily'
    case 'Week':
      return periodPer > 1 ? `${periodPer} Weeks` : 'Weekly'
    case 'Month':
      return periodPer > 1 ? `${periodPer} Months` : 'Monthly'
    case 'Quarter':
      return periodPer > 1 ? `${periodPer} Quarters` : 'Quarterly'
    case 'Year':
      return periodPer > 1 ? `${periodPer} Years` : 'Yearly'
    case 'Annual':
      return periodPer > 1 ? `${periodPer} Annual` : 'Annually'
    default:
      return periodInterval
  }
}

export const getRecurrenceText = (per, interval, frequency, isFee) => {
  if (!interval) {
    // Currently we show blank for fee recurrence, need to validate this
    return isFee ? '' : ON_REQUEST
  }

  const period = getPricingPeriod(per, interval)

  if (!frequency) {
    return period
  }

  return `${frequency}x ${period}`
}

export const getServiceRecurrenceText = service => {
  const serviceType = get(service, 'serviceType', '').toUpperCase()
  const isRecurring = serviceType === RECURRING.toUpperCase()
  const recurrenceFrequency = get(service, 'recurrenceFrequency', 1)
  const recurrencePer = get(service, 'recurrencePer', '')
  const recurrenceInterval = get(service, 'recurrenceInterval', '')
  const pricingPeriod = getPricingPeriod(recurrencePer, recurrenceInterval)
  return isRecurring ? `${recurrenceFrequency}x ${pricingPeriod}` : 'On Request'
}

export const isRecurringService = type => type === RECURRING

export const isOnRequestService = type => type === T.ON_REQUEST

export const isDailyRecurrence = type => type === T.DAY

export const isWeeklyRecurrence = type => type === T.WEEK

export const isNonWeeklyRecurrence = type => [T.MONTH, T.QUARTER, T.ANNUAL, T.YEAR].includes(type)

export const getServiceStartEndDate = (key, value, schedule, isPastDateDisabled) => {
  const currentDate = formatDateToBEFormatDateFns(new Date())

  // If start date is greater than end date then reset end date
  if (key === 'startDate' && get(schedule, 'endDate') && calculateDaysDiffFromStringDates(value, get(schedule, 'endDate')) > 0) {
    return { [key]: value, endDate: '' }
  }

  // if end date is less than start date
  if (key === 'endDate' && get(schedule, 'startDate', '') && calculateDaysDiffFromStringDates(value, get(schedule, 'startDate')) < 0) {
    return { [key]: get(schedule, 'startDate') }
  }

  // Past start date not allowed
  if (['startDate', 'endDate'].includes(key) && isPastDateDisabled && calculateDaysDiffFromStringDates(value, currentDate) < 0) {
    return { [key]: currentDate }
  }

  return { [key]: value }
}

export const getDeactivationStatusMessage = (isRecurring, endDate, serviceEvents = []) => {
  const currentDate = formatDateToBEFormatDateFns(new Date())

  const hasFutureEvent = serviceEvents.find(
    event => event?.actionDate && calculateDaysDiffFromStringDates(event.actionDate, currentDate) >= 0
  )

  // On Request/ On Call
  if (!isRecurring) {
    return { showConfirmation: !!hasFutureEvent, message: hasFutureEvent ? T.ON_REQUEST_DEACTIVATE : '' }
  }

  // Recurring no endDate
  if (!endDate) {
    return { showConfirmation: true, message: T.ON_RECCURING_DEACTIVATE_NO_END_DATE_MSG }
  }

  const daysDiff = calculateDaysDiffFromStringDates(endDate, currentDate)

  // If end date is current date
  if (daysDiff === 0) {
    return { showConfirmation: !!hasFutureEvent, message: hasFutureEvent ? T.ON_REQUEST_DEACTIVATE : '' }
  }

  // If end date is future date
  if (daysDiff > 0) {
    return { showConfirmation: true, message: T.ON_RECCURING_DEACTIVATE_FUTURE_DATE_MSG }
  }

  return { showConfirmation: false, message: '' }
}

export const getServiceFilterAutoCompleteLabel = (type, option) => {
  switch (type) {
    case T.MEASURE:
      return `${get(option, 'volume', '')} ${get(option, 'unit', '')}`
    case T.MATERIAL:
      return get(option, 'materialType', '')
    case T.METHOD:
      return get(option, 'methodName', '')
    case T.ACTION:
      return get(option, 'actionName', '')
    default:
      return ''
  }
}

const getServiceDetails = service => ({
  type: get(service, 'serviceActionType', ''),
  name: get(service, 'serviceActionName', ''),
  price: get(service, 'value', ''),
  serviceId: get(service, 'id', ''),
  serviceName: get(service, 'serviceName', ''),
})

const transformSingleFeeTaxRow = (type, feeTaxes, service) =>
  feeTaxes.map(feeTax => ({
    ...feeTax,
    serviceId: service.id,
    serviceName: get(service, 'serviceName', ''),
  }))

const transformGeneralDisposalFeeAndTax = (type, pricedServices, isGroup) => {
  // Attach service Id and flat
  if (isGroup) {
    // we have nested array, so flat to top level
    return pricedServices.map(service => transformSingleFeeTaxRow(type, get(service, type, []), service)).flat()
  }

  // Attach service Id
  return transformSingleFeeTaxRow(type, get(pricedServices, type, []), pricedServices)
}

export const transformMultiplePricedServicesToSingle = (pricedServices, serviceGroupId) => {
  const isGroup = Array.isArray(pricedServices)
  const generalFeeList = transformGeneralDisposalFeeAndTax('generalFeeList', pricedServices, isGroup)
  const disposableFeeList = transformGeneralDisposalFeeAndTax('disposableFeeList', pricedServices, isGroup)
  const taxList = transformGeneralDisposalFeeAndTax('taxList', pricedServices, isGroup)

  const transformResult = {
    isGroup,
    generalFeeList,
    disposableFeeList,
    taxList,
    allServices: [{ ...getServiceDetails(pricedServices) }],
  }

  if (!isGroup) {
    return { ...pricedServices, ...transformResult }
  }

  return {
    ...transformResult,
    id: serviceGroupId,
    serviceType: 'On Request',
    allServices: pricedServices.map(service => getServiceDetails(service)),
  }
}

export const getServiceGroupPrice = allServices => {
  const allValidServices = allServices.map(service => parseFloat(get(service, 'price', 0)))
  return sum(allValidServices).toFixed(2)
}

export const formatRecurrenceString = inputString => {
  if (inputString === 'On Request') {
    return inputString
  }
  const parts = inputString.split(' ')
  if (parts.length === 4 && !Number.isNaN(parts[0]) && !Number.isNaN(parts[2])) {
    const frequency = parseInt(parts[0], 10)
    const per = parseInt(parts[2], 10)
    const period = parts[3]

    if (per === 1) {
      if (period === 'Day') {
        return `${frequency}x Daily`
      }
      if (period === 'Week') {
        return `${frequency}x Weekly`
      }
      if (period === 'Month') {
        return `${frequency}x Monthly`
      }
      if (period === 'Quarter') {
        return `${frequency}x Quarterly`
      }
      return `${frequency}x ${per} ${period}`
    }
    return `${frequency}x ${per} ${period}`
  }
  return inputString
}

export const getTaxList = (feeTaxList, taxes, generalFeeList, disposableFeeList) => {
  const inActiveFeeList = feeTaxList.filter(feeTax => feeTax.type !== T.TAX && !feeTax.linked).map(feeTax => feeTax.feeTaxId)
  const onlyTaxes = getServiceFeeTax(taxes, inActiveFeeList)

  // General fee & disposal fee we can remove, directly get from feeTax list
  const generalFeeTaxes = generalFeeList
    .map(fee =>
      getServiceFeeTax(
        get(fee, 'taxList', []),
        inActiveFeeList,
        'General Fee',
        fee.id,
        get(fee, 'feeName', 'Fee'),
        get(fee, 'serviceId'),
        get(fee, 'serviceName')
      )
    )
    .flat()

  const disposalFeeTaxes = disposableFeeList
    .map(fee =>
      getServiceFeeTax(
        get(fee, 'taxList', []),
        inActiveFeeList,
        'Disposal Fee',
        fee.id,
        get(fee, 'feeName', 'Fee'),
        get(fee, 'serviceId'),
        get(fee, 'serviceName')
      )
    )
    .flat()

  return [...onlyTaxes, ...generalFeeTaxes, ...disposalFeeTaxes]
}
