import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { useDispatch } from 'react-redux'
import { Divider } from '@mui/material'
import { get } from 'lodash'
import { useLazyGetRawRouteQuery } from '../api/route/getExportedRawRoute'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import CustomPrintContainer from '../components/work-order/export/CustomPrintContainer'
import PrintSettingsWrapper from '../components/work-order/export/PrintSettingsWrapper'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import WorkOrderCard from '../components/work-order/export/WorkOrderCard'
import { selectAddress } from '../data/address/addressSelectors'
import { getNotesForMobile } from '../data/notes/notesSelectors'

const ExportWorkOrderPage = () => {
  const dispatch = useDispatch()
  const { workOrderId, routeId } = useParams()
  const [getRawRouteQuery, { data, isSuccess }] = useLazyGetRawRouteQuery()

  useEffect(() => {
    dispatch(setShowAppBar(false))
    getRawRouteQuery({ routeId, workOrderId, expandNotes: true })
    return () => dispatch(setShowAppBar(true))
  }, [])

  const accountName = get(data, 'accountName')
  const accountNumber = get(data, 'accountNumber')
  const contactName = get(data, 'contactName')
  const contactPhone = get(data, 'contactPhone')
  const containers = get(data, 'containers', [])
  const locationName = get(data, 'locationName')
  const measure = get(data, 'measure')
  const serviceAction = get(data, 'serviceAction')
  const serviceName = get(data, 'serviceName')

  const locationNotesList = getNotesForMobile({ notes: get(data, 'locationNotesList', []) })
  const configuredServiceNotesList = getNotesForMobile({ notes: get(data, 'configuredServiceNotesList', []) })
  const workOrderNotesList = getNotesForMobile({ notes: get(data, 'workOrderNotesList', []) })

  const workOrderNotes = get(data, 'workOrderNotes')
  const workOrderSequence = get(data, 'workOrderSequence')
  const purchaseOrderNumber = get(data, 'purchaseOrderNumber')
  const { line1, state, city, unitNumber, zipCode } = selectAddress(data)

  return (
    <ProtectedRouteHoC>
      <PrintSettingsWrapper>
        <CustomPrintContainer>
          {isSuccess && data && (
            <>
              <WorkOrderCard
                accountName={accountName}
                accountNumber={accountNumber}
                city={city}
                contactName={contactName}
                contactPhone={contactPhone}
                containers={containers}
                line1={line1}
                line2={unitNumber}
                locationName={locationName}
                measure={measure}
                serviceAction={serviceAction}
                serviceName={serviceName}
                state={state}
                locationNotesList={locationNotesList}
                configuredServiceNotesList={configuredServiceNotesList}
                workOrderNotesList={workOrderNotesList}
                workOrderNotes={workOrderNotes}
                workOrderSequence={workOrderSequence}
                zipCode={zipCode}
                purchaseOrderNumber={purchaseOrderNumber}
              />
              <Divider sx={{ borderStyle: 'dashed', borderColor: 'border.lightGrey', borderWidth: 2 }} />
              <WorkOrderCard
                accountName={accountName}
                accountNumber={accountNumber}
                city={city}
                contactName={contactName}
                contactPhone={contactPhone}
                containers={containers}
                isCustomerCopy={false}
                line1={line1}
                line2={unitNumber}
                locationName={locationName}
                measure={measure}
                serviceAction={serviceAction}
                serviceName={serviceName}
                state={state}
                locationNotesList={locationNotesList}
                configuredServiceNotesList={configuredServiceNotesList}
                workOrderNotesList={workOrderNotesList}
                workOrderNotes={workOrderNotes}
                workOrderSequence={workOrderSequence}
                zipCode={zipCode}
                purchaseOrderNumber={purchaseOrderNumber}
              />
            </>
          )}
        </CustomPrintContainer>
      </PrintSettingsWrapper>
    </ProtectedRouteHoC>
  )
}

export default ExportWorkOrderPage
