import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHTextField from 'components/form-fields/v5/HHTextField'
import InvoiceActivity from '../../common/InvoiceActivity'

const SelectInvoiceDropdown = ({
  disabled = false,
  selectedInvoice = null,
  unPaidInvoices = [],
  destinationAccountDetails = {},
  onInvoiceChange = noop,
}) => {
  return (
    <HHTextField
      sx={{ mt: 1, '& .MuiOutlinedInput-root': { height: selectedInvoice ? 'auto!important' : 40 } }}
      deprecatedLabel={false}
      label={`${T.INVOICE} *`}
      fullWidth
      select
      value={selectedInvoice}
      onChange={event => onInvoiceChange(event.target.value)}
      disabled={disabled}
    >
      {unPaidInvoices.length === 0 && (
        <SelectItem disabled value="">
          {T.NO_BILLING_INVOICES_FOUND}
        </SelectItem>
      )}
      {unPaidInvoices.map(invoice => (
        <SelectItem value={invoice}>
          <InvoiceActivity billingProfileDetails={destinationAccountDetails.billingProfileDetails} invoice={invoice} />
        </SelectItem>
      ))}
    </HHTextField>
  )
}

SelectInvoiceDropdown.propTypes = {
  disabled: PropTypes.bool,
  selectedInvoice: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  unPaidInvoices: PropTypes.array,
  destinationAccountDetails: PropTypes.object,
  onInvoiceChange: PropTypes.func,
}

export default SelectInvoiceDropdown
