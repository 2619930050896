import { ListItem, styled } from '@mui/material'

const CenteredListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiListItemText-root': { flex: '0 0 auto' },
  '.MuiListItemSecondaryAction-root': {
    position: 'static',
    transform: 'none',
    height: 24,
    marginLeft: theme.spacing(1),
  },
}))

export default CenteredListItem
