import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    saveUpdateEventCSService: build.mutation({
      query: body => ({ url: '/api/configured-service/createEvent', method: 'POST', body }),
    }),
  }),
})

export const { useSaveUpdateEventCSServiceMutation } = extendedApi
