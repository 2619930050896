import React, { useState } from 'react'
import { Typography, ListItem, ListItemText, Stack, ListItemIcon } from '@mui/material'
import { get } from 'lodash'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EventBlocker from 'components/common/EventBlocker'
import ServicesCountPopover from 'components/locations/LocationsDataGrid/ServicesCountPopover'
import VacationHoldChip from 'components/common/chip/VacationHoldChip'
import SuspensionChip from 'components/common/chip/SuspensionChip'
import { createDateFromString, isNotInvalidDate } from 'utils/date'
import format from 'date-fns/format'
import { SHORT_FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'
import PropTypes from 'prop-types'

const formatDateRange = dates =>
  dates
    .map(date => date && createDateFromString(date))
    .filter(date => date && isNotInvalidDate(date))
    .map(date => format(date, SHORT_FRONTEND_DATE_FORMAT_DATE_FNS))
    .join(' - ')

const RenderLocationLeftContentCell = props => {
  const { row } = props
  const address = get(row, 'addressStr')
  const accountName = get(row, 'accountName')
  const accountNumber = get(row, 'accountNumber')
  const billingProfileName = get(row, 'billingProfileName')
  const activeConfiguredServiceCount = get(row, 'activeConfiguredServiceCount')
  const deactivatedConfiguredServiceCount = get(row, 'deactivatedConfiguredServiceCount')
  const suspensionHold = get(row, 'suspensionHold')
  const vacationHoldCount = get(row, 'vacationHoldCount', 0)
  const hasVacationHolds = vacationHoldCount > 0
  const vacationHoldStartDate = get(row, 'vacationHoldStartDate')
  const vacationHoldEndDate = get(row, 'vacationHoldEndDate')
  const suspensionHoldStartDate = get(row, 'suspensionHoldStartDate')
  const addressName = get(row, 'addressName')
  const formattedVacationRange = formatDateRange([vacationHoldStartDate, vacationHoldEndDate])
  const formattedSuspensionRange = formatDateRange([suspensionHoldStartDate])
  const secondaryText = [accountName, accountNumber, billingProfileName].filter(Boolean).join(' | ')
  const [locationsServicesCountPopoverAnchorEl, setLocationsServicesCountPopoverAnchorEl] = useState(null)
  const handleLocationsServicesCountPopoverOpen = event => {
    event.stopPropagation()
    event.preventDefault()
    setLocationsServicesCountPopoverAnchorEl(event.currentTarget)
  }

  const handleLocationsServicesCountPopoverClose = event => {
    event.stopPropagation()
    setLocationsServicesCountPopoverAnchorEl(null)
  }

  return (
    <ListItem dense disablePadding>
      <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
        <InfoOutlinedIcon
          onMouseEnter={handleLocationsServicesCountPopoverOpen}
          onMouseLeave={handleLocationsServicesCountPopoverClose}
          color="action"
          sx={{ fontSize: 24 }}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography noWrap variant="h5" color="textPrimary">
            {address}
          </Typography>
        }
        secondary={
          <Stack flexDirection="column" alignItems="flex-start">
            <Typography variant="h6" fontWeight={500} color="textPrimary">
              {addressName}
            </Typography>
            <Stack sx={{ mt: 0.5 }} flexDirection="row" alignItems="center" columnGap={1}>
              {(suspensionHold || hasVacationHolds) && (
                <EventBlocker preventDefault>
                  {suspensionHold && <SuspensionChip size="small" clickable={false} label={formattedSuspensionRange} />}
                  {hasVacationHolds && <VacationHoldChip size="small" clickable={false} label={formattedVacationRange} />}
                </EventBlocker>
              )}
              <Typography noWrap variant="body1" color="textSecondary" fontWeight={700}>
                {secondaryText}
              </Typography>
            </Stack>
          </Stack>
        }
        disableTypography
      />
      <EventBlocker preventDefault>
        <ServicesCountPopover
          anchorEl={locationsServicesCountPopoverAnchorEl}
          onClose={handleLocationsServicesCountPopoverClose}
          activeCount={activeConfiguredServiceCount}
          deactivatedCount={deactivatedConfiguredServiceCount}
        />
      </EventBlocker>
    </ListItem>
  )
}

RenderLocationLeftContentCell.propTypes = {
  row: PropTypes.object,
}

export default RenderLocationLeftContentCell
