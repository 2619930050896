import React, { useState, useEffect, useCallback } from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { get } from 'utils/lodash'
import { getGroupsForDataGrid, getExpandedRows } from 'data/groups/groupsSelectors'
import { useLazyGetPaymentGroupListQuery } from 'api/groups/payment/getPaymentGroupList'

import T from 'T'
import Loader from 'components/common/loader'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import PaymentGroupHeader from 'components/billing/groups/payment/PaymentGroupHeader'
import PaymentGroupDataGridRow from 'components/billing/groups/payment/PaymentGroupDataGridRow'
import ChartSummaryDialog from 'components/billing/groups/payment/common/ChartSummaryDialog'
import { PAYMENT_GROUP_DATA_GRID_ROW_HEIGHT } from './settings'

const PaymentGroups = () => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const [getPaymentGroupList, { isFetching: isPaymentGroupListLoading, data: paymentGroupList, isSuccess }] =
    useLazyGetPaymentGroupListQuery()
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)
  const [collapsedGroups, setCollapsedGroups] = useState([])

  const getColumnSize = () => {
    if (isXl) return 3
    if (isLg) return 2
    return 1
  }

  const noOfColumns = getColumnSize()
  const { originalRows = [], modifiedRows = [], groupNames = [] } = getGroupsForDataGrid({ groupList: paymentGroupList })
  const expandedRows = getExpandedRows({ rows: modifiedRows, collapsedGroups, noOfColumns })

  const handlePaymentGroupCollapse = (isOpen, groupRef) => {
    if (isOpen) {
      setCollapsedGroups(collapsedGroups.filter(group => group !== groupRef))
      return
    }

    setCollapsedGroups([...collapsedGroups, groupRef])
  }

  const getRowSpacing = useCallback(
    params => ({
      top: params.isFirstVisible ? 16 : 8,
      bottom: params.isLastVisible ? 16 : 8,
    }),
    []
  )

  const getRowHeight = useCallback(() => 'auto', [])

  useEffect(() => {
    getPaymentGroupList()
  }, [])

  useEffect(() => {
    if (isSuccess && collapsedGroups.length === 0) {
      setCollapsedGroups(groupNames.length > 1 ? groupNames.slice(1) : [])
    }
  }, [isSuccess])

  return (
    <>
      {isPaymentGroupListLoading && <Loader />}
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <PaymentGroupHeader />
        <Box flex="1" position="relative">
          <Box sx={{ position: 'absolute', inset: 0, overflowX: 'hidden' }}>
            <DataGridPro
              loading={isPaymentGroupListLoading}
              slots={{ noRowsOverlay: () => <HHSectionPlaceholder title={T.NO_PAYMENT_GROUPS} /> }}
              rows={expandedRows}
              hideFooter
              getRowId={({ id }) => id}
              checkboxSelection={false}
              columnHeaderHeight={0}
              columns={[
                {
                  flex: 1,
                  field: 'id',
                  renderCell: ({ row }) => (
                    <PaymentGroupDataGridRow
                      originalRows={originalRows}
                      row={row}
                      collapsedGroups={collapsedGroups}
                      noOfColumns={noOfColumns}
                      onGroupCollapse={handlePaymentGroupCollapse}
                    />
                  ),
                },
              ]}
              disableColumnSelector
              getRowSpacing={getRowSpacing}
              getRowHeight={getRowHeight}
              getEstimatedRowHeight={() => PAYMENT_GROUP_DATA_GRID_ROW_HEIGHT}
              isRowSelectable={false}
              sx={{
                border: 'none',
                '& .MuiDataGrid-columnHeaders': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  border: 'none',
                  padding: 0,
                  background: theme.palette.background.paper,
                  outline: '0!important',
                },
                '& .MuiDataGrid-virtualScroller': {
                  overflowX: 'hidden',
                },
              }}
            />
          </Box>
        </Box>
      </BillingLayout>
      <ChartSummaryDialog />
    </>
  )
}

export default PaymentGroups
