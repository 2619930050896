export const actions = {
  // Useful only for initiate, status & download file
  INITIATE_STATUS_DOWNLOAD: 'GLOBAL_INITIATE_STATUS_DOWNLOAD_ACTION',
  // Usefull for single download -- InProgress
  SINGLE_DOWNLOAD: 'GLOBAL_SINGLE_DOWNLOAD_ACTION',
}

// On success callback is required
export const initiateStatusDownload = (body, onSuccess = () => {}) => ({
  type: actions.INITIATE_STATUS_DOWNLOAD,
  payload: { body, onSuccess },
})
