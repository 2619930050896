import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import { NOTE_TYPES } from 'settings/constants/note'
import { useLazyGetCSNoteHistoryQuery } from 'api/notes/configuredServiceNotesCrud'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AddUpdateCSNoteDialog from './AddUpdateCSNoteDialog'
import NoteListItem from '../common/NoteListItem'
import NoteActionMenu from './NoteActionMenu'
import ViewNoteDialog from '../common/ViewNoteDialog'

const { CONFIGURED_SERVICE } = NOTE_TYPES

const CSNotes = ({ notes = [], configuredServiceId = '', sx, itemSx }) => {
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewNoteDialog, setIsOpenViewNoteDialog] = useState(null)
  const [isOpenNoteActionMenu, setIsOpenNoteActionMenu] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const hasNotes = notes.length > 0

  const handleOpenUpdateCSNote = () => setIsOpenAddUpdateNoteDialog(true)

  const handleOpenViewCSNoteDialog = note => {
    setSelectedNote(note)
    setIsOpenViewNoteDialog(true)
  }

  const handleCloseViewCSNoteDialog = () => {
    setSelectedNote(null)
    setIsOpenViewNoteDialog(null)
  }

  const handleOpenCSNoteActionMenu = (event, note) => {
    event.stopPropagation()
    setSelectedNote(note)
    setIsOpenNoteActionMenu(event.currentTarget)
  }

  const handleCloseCSNoteActionMenu = () => setIsOpenNoteActionMenu(null)

  const handleCloseAddUpdateCSNote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  return (
    <>
      {!hasNotes && <HHSectionPlaceholder title={T.NO_CS_NOTES} />}
      {hasNotes && (
        <List sx={{ py: 0, ...sx }}>
          {Children.toArray(
            notes.map((note, index) => (
              <NoteListItem
                sx={itemSx}
                type={CONFIGURED_SERVICE}
                note={note}
                showDivider={notes.length - 1 !== index}
                onViewClick={() => handleOpenViewCSNoteDialog(note)}
                onActionMenuClick={event => handleOpenCSNoteActionMenu(event, note)}
              />
            ))
          )}
        </List>
      )}
      <AddUpdateCSNoteDialog
        id={configuredServiceId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.CONFIGURED_SERVICE_NOTE : T.NEW_CONFIGURED_SERVICE_NOTE}
        existingNote={selectedNote}
        onClose={handleCloseAddUpdateCSNote}
      />
      <ViewNoteDialog
        id={configuredServiceId}
        isOpen={isOpenViewNoteDialog}
        title={T.CONFIGURED_SERVICE_NOTE}
        selectedNote={selectedNote}
        useLazyGetNoteHistoryQuery={useLazyGetCSNoteHistoryQuery}
        onClose={handleCloseViewCSNoteDialog}
      />
      <NoteActionMenu
        id={configuredServiceId}
        anchorEl={isOpenNoteActionMenu}
        selectedNote={selectedNote}
        onEditClick={handleOpenUpdateCSNote}
        onClose={handleCloseCSNoteActionMenu}
      />
    </>
  )
}

CSNotes.propTypes = {
  notes: PropTypes.array,
  configuredServiceId: PropTypes.string,
  sx: PropTypes.object,
  itemSx: PropTypes.object,
}

export default CSNotes
