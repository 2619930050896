import { useCallback } from 'react'
import { formatDateToBEFormatDateFns } from 'utils/date'

const useGetBeBatches = ({ serviceDate, serializedServiceDate, moveToRouteId, moveToRows }) => {
  return useCallback(
    (updateMasterSequence = false) => {
      let currentBatch = null
      let lastStop = null

      const batches = moveToRows.reduce((accum, currentRow) => {
        if (currentRow.routeId !== moveToRouteId) {
          if (!currentBatch) {
            currentBatch = {
              destinationRouteId: moveToRouteId,
              stops: [currentRow.id],
              serviceDate: formatDateToBEFormatDateFns(serviceDate),
              targetStopId: lastStop,
              updateMasterSequence,
            }
          } else {
            currentBatch.stops.push(currentRow.id)
          }
        } else {
          if (currentBatch) {
            accum.push(currentBatch)
            currentBatch = null
          }
          lastStop = currentRow.id
        }
        return accum
      }, [])
      if (currentBatch) {
        batches.push(currentBatch)
      }
      return batches
    },
    [moveToRouteId, moveToRows, serializedServiceDate]
  )
}

useGetBeBatches.propTypes = {}

export default useGetBeBatches
