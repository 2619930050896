import React from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { CardHeader, Grid, Link, Typography } from '@mui/material'
import { getCustomerDetailsPageUrl } from 'router/routes'

const AccountSummary = ({
  accountId,
  accountNumber,
  accountName,
  billingProfileName = '',
  autopay = false,
  billingCycle = '',
  batchNumber = '',
}) => {
  const onLinkClick = e => e.stopPropagation()

  return (
    <CardHeader
      disableTypography
      sx={{ py: 1, px: 2, bgcolor: 'background.header' }}
      title={
        <Grid container alignItems="center" columnSpacing={1} wrap="nowrap">
          <Grid item>
            <Link onClick={onLinkClick} component={RouterLink} to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`} variant="body1">
              {accountNumber}
            </Link>
          </Grid>
          <Grid container item xs>
            <Typography fontWeight={500}>{accountName}</Typography>
          </Grid>
          <Grid container item xs="auto" columnSpacing={2}>
            {billingProfileName && (
              <Grid item>
                <Typography fontWeight={500}>{billingProfileName}</Typography>
              </Grid>
            )}
            {batchNumber && (
              <Grid item>
                <Typography fontWeight={500}>{batchNumber}</Typography>
              </Grid>
            )}
            {autopay && (
              <Grid item>
                <Typography fontWeight={600} color="success.main">
                  Autopay
                </Typography>
              </Grid>
            )}
            {billingCycle && (
              <Grid item>
                <Typography fontWeight={500}>{billingCycle}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      }
    />
  )
}

AccountSummary.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  billingProfileName: PropTypes.string,
  autopay: PropTypes.bool,
  billingCycle: PropTypes.string,
  batchNumber: PropTypes.string,
}

export default AccountSummary
