import { get, invert } from 'lodash'
import { parseLHSbracketStr } from './string'
import { DATE_FILTERS } from '../settings/constants/filters'

const COLUMN_NAME_TO_PARAM_NAME = {
  customerBillingProfile: 'billing_profile',
  invoiceDate: 'invoice_date',
}

const PARAM_NAME_TO_COLUMN_NAME = invert(COLUMN_NAME_TO_PARAM_NAME)

const supportedFilters = Object.keys(COLUMN_NAME_TO_PARAM_NAME)

const DATE_OPERATIONS_WITH_VALUE = ['DATE_BEFORE', 'DATE_AFTER', 'DATE_IS_BETWEEN', 'DATE_RANGE_IS_BETWEEN']

const VALID_DATE_OPERATIONS = DATE_FILTERS.map(({ key }) => key)

export const queryParamsToFiltersMap = queryParams => {
  const paramKeys = queryParams ? Object.keys(queryParams) : {}
  if (paramKeys.length === 0) return
  const filtersMap = {}
  for (const paramKey of paramKeys) {
    const { key, operation } = parseLHSbracketStr(paramKey)
    if (key === 'invoice_date' && (VALID_DATE_OPERATIONS.includes(operation) || VALID_DATE_OPERATIONS.includes(queryParams[key]))) {
      const filterKey = PARAM_NAME_TO_COLUMN_NAME[key]
      filtersMap[filterKey] = {
        filterType: 'date',
        operation: operation || queryParams[key],
        columnValue: queryParams[paramKey],
        columnName: filterKey,
        subType: 'date',
        label: 'Inv date',
        isSingleSelect: false,
        isBoolean: false,
      }
    } else if (key === 'billing_profile') {
      const filterKey = PARAM_NAME_TO_COLUMN_NAME[key]
      filtersMap[filterKey] = {
        filterType: 'meta',
        operation: 'EQUAL_TO',
        columnValue: queryParams[paramKey].split(','),
        columnName: filterKey,
        subType: '',
        label: 'Customer Billing Profile',
        isSingleSelect: false,
        isBoolean: false,
      }
    }
  }
  return filtersMap
}

export const filterMapToQueryParams = filtersMap => {
  const filterKeys = filtersMap ? Object.keys(filtersMap) : {}
  if (filterKeys.length === 0) return
  const queryParams = {}
  for (const filterKey of filterKeys) {
    const filterData = get(filtersMap, `${filterKey}`)
    const columnName = get(filterData, 'columnName')
    const operation = get(filterData, 'operation')
    const columnValue = get(filterData, 'columnValue')
    const filterType = get(filterData, 'filterType')
    if (!supportedFilters.includes(columnName)) continue

    if (filterType === 'meta') {
      const paramKey = COLUMN_NAME_TO_PARAM_NAME[columnName]
      queryParams[paramKey] = Array.isArray(columnValue) ? columnValue.join(',') : columnValue
    } else if (filterType === 'date') {
      const paramKey = COLUMN_NAME_TO_PARAM_NAME[columnName]
      if (DATE_OPERATIONS_WITH_VALUE.includes(operation) && columnValue) {
        queryParams[`${paramKey}[${operation}]`] = columnValue
      } else if (operation) {
        queryParams[paramKey] = operation
      }
    }
  }
  return queryParams
}
