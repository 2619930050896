import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SvgIcon, MenuItem, ListItemIcon } from '@mui/material'
import { Tag as TagIcon } from '@styled-icons/heroicons-outline/Tag'

import EditTagsPopup from 'components/tags/EditTagsPopup'
import noop from 'lodash/noop'
import toPairs from 'lodash/toPairs'
import pick from 'lodash/pick'
import { get } from 'utils/lodash'
import { TAG_TYPE_ACCOUNT, TAG_TYPE_LOCATION, TAG_TYPE_SERVICE } from 'slices/tags/tagsSlice'
import { getEnabledTypes, getTagsGroupInfo, addParentIdToTagGroupTypes } from 'data/tags/tagsDataMappingSelector'
import Badge from '@mui/material/Badge'

const InteractiveTagItemMenu = ({
  data,
  disableAccount = false,
  disableLocation = false,
  disableService = false,
  onOpen = noop,
  onSave = noop,
  onClick = noop,
}) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const enabledTagTypes = getEnabledTypes({ disableAccount, disableLocation, disableService })

  // display only active tags
  const selectedTagsByType = get(data, 'tagDetails', [])
  const enabledSavedTags = pick(selectedTagsByType, enabledTagTypes)
  const filteredSavedTags = addParentIdToTagGroupTypes(data, enabledSavedTags)

  const allSelectedTags = toPairs(filteredSavedTags)
    .map(([type, { tags }]) =>
      tags.map(tag => ({
        ...tag,
        enabledGroups: type,
      }))
    )
    .flat()
  const allSelectedTagsWithTagsGroupInfo = getTagsGroupInfo(allSelectedTags)

  const tagsCount = allSelectedTagsWithTagsGroupInfo.length

  const onSaveTagsCallback = () => setIsEditPopupOpen(false)

  const onEditTagsOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    // the parent entity id [RouteId, Service, Location]
    const parentId = get(data, 'id')

    setIsEditPopupOpen(true)
    onOpen(parentId)
  }

  const onEditTagsClose = e => {
    setIsEditPopupOpen(false)
  }

  const onSaveTags = () => {
    onSave(data, onSaveTagsCallback)
  }

  const handleClick = e => {
    onClick()
    onEditTagsOpen(e)
  }

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <Badge badgeContent={tagsCount} color="primary">
            <SvgIcon>
              <TagIcon />
            </SvgIcon>
          </Badge>
        </ListItemIcon>
        Tags
      </MenuItem>
      {isEditPopupOpen && (
        <EditTagsPopup
          open={isEditPopupOpen}
          onClose={onEditTagsClose}
          onSave={onSaveTags}
          onOpen={onEditTagsOpen}
          name={get(data, 'name') || get(data, 'accountName', '')}
          defaultSelectedTags={filteredSavedTags}
          disableAccount={disableAccount}
          disableLocation={disableLocation}
          disableService={disableService}
        />
      )}
    </>
  )
}

InteractiveTagItemMenu.propTypes = {
  data: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    accountName: PropTypes.string,
    configuredServiceId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    locationId: PropTypes.string.isRequired,
    name: PropTypes.string,
    tagDetails: PropTypes.shape({
      [TAG_TYPE_ACCOUNT]: PropTypes.array,
      [TAG_TYPE_LOCATION]: PropTypes.array,
      [TAG_TYPE_SERVICE]: PropTypes.array,
    }).isRequired,
  }).isRequired,
  disableAccount: PropTypes.bool,
  disableLocation: PropTypes.bool,
  disableService: PropTypes.bool,
  onOpen: PropTypes.func,
  onSave: PropTypes.func,
  onClick: PropTypes.func,
}

export default InteractiveTagItemMenu
