import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@mui/material'
import CreditLimitEditDialog from 'components/customer-details/content/billing/CreditLimitEditDialog'
import T from 'T'
import EditBillingProfileDialog from './edit/EditBillingProfileDialog'
import CreditLimitHistoryDialog from './CreditLimitHistoryDialog'

const BillingDetailsActionMenu = ({ anchorEl, handleCloseMenu, customerDetails }) => {
  const [isOpenEditBillingDialog, setIsOpenEditBillingDialog] = useState(false)
  const [isOpenCreditLimitDialog, setIsOpenCreditLimitDialog] = useState(false)
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false)

  const handleOpenBillingProfile = () => {
    setIsOpenEditBillingDialog(true)
    handleCloseMenu()
  }

  const handleOpenCreditLimit = () => {
    setIsOpenCreditLimitDialog(true)
    handleCloseMenu()
  }

  const handleOpenHistory = () => {
    setIsOpenHistoryDialog(true)
    handleCloseMenu()
  }

  const handleCloseBillingProfile = () => {
    setIsOpenEditBillingDialog(false)
  }

  const handleCloseCreditLimit = () => {
    setIsOpenCreditLimitDialog(false)
  }

  const handleCloseHistoryDialog = () => {
    setIsOpenHistoryDialog(false)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenBillingProfile}>{T.BILLING_PROFILE}</MenuItem>
        <MenuItem onClick={handleOpenCreditLimit}>{T.CREDIT_LIMIT}</MenuItem>
        <MenuItem onClick={handleOpenHistory}>{T.CREDIT_LIMIT_HISTORY}</MenuItem>
      </Menu>
      <CreditLimitEditDialog open={isOpenCreditLimitDialog} customerDetails={customerDetails} onClose={handleCloseCreditLimit} />
      <EditBillingProfileDialog isOpen={isOpenEditBillingDialog} customerDetails={customerDetails} onClose={handleCloseBillingProfile} />
      <CreditLimitHistoryDialog customerDetails={customerDetails} open={isOpenHistoryDialog} onClose={handleCloseHistoryDialog} />
    </>
  )
}

BillingDetailsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleCloseMenu: PropTypes.func.isRequired,
  customerDetails: PropTypes.object.isRequired,
}

export default BillingDetailsActionMenu
