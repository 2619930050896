import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions, putCustomerWorkOrderDetails } from '../actions/workOrders'

import endpoint from './endpoint'

export const getCustomerWorkOrders = ({ body }) => doPost({ url: endpoint.workOrder.get, body, showLoader: false })
export const getCustomerWorkOrderDetails = ({ body }) => doPost({ url: endpoint.workOrder.details, body, showLoader: true })
export const updateWorkOrderExceptionNote = ({ body }) => doPost({ url: endpoint.workOrder.updateExceptionNote, body, showLoader: true })
export const updateWorkOrderTag = ({ body }) => doPost({ url: endpoint.workOrder.updateTag, body, showLoader: true })

export function* getCustomerWorkOrdersSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCustomerWorkOrders, { body })
    const { workOrders, totalItems, totalPages } = res
    if (typeof onSuccess === 'function') {
      onSuccess(workOrders, totalItems, totalPages)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getCustomerWorkOrderDetailsSaga({ payload }) {
  yield put(putCustomerWorkOrderDetails(null))
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCustomerWorkOrderDetails, { body })
    const { workOrders } = res
    yield put(putCustomerWorkOrderDetails(res))
    if (typeof onSuccess === 'function') {
      onSuccess(workOrders)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* updateWorkOrderTagSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield call(updateWorkOrderTag, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {}
}

export function* updateWorkOrderExceptionNoteSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield call(updateWorkOrderExceptionNote, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true)
    }
  } catch (err) {}
}

export default function* WorkOrders() {
  yield all([
    takeEvery(actions.GET.WORK_ORDERS, getCustomerWorkOrdersSaga),
    takeEvery(actions.GET.WORK_ORDER_DETAILS, getCustomerWorkOrderDetailsSaga),
    takeEvery(actions.UPDATE_EXCEPTION_NOTE, updateWorkOrderExceptionNoteSaga),
    takeEvery(actions.UPDATE_TAG, updateWorkOrderTagSaga),
  ])
}
