import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useSelector, shallowEqual } from 'react-redux'
import { Box, Typography } from '@mui/material'
import CommonSwitch from 'components/common/CommonSwitch'
import { DISPLAY_OPTIONS, DISPLAY_MAPPINGS } from 'settings/constants/roles'
import T from 'T'
import { memo } from 'utils/react'
import { canUpdateSettings, canUpdateUsers, canUpdatePermissions } from 'data/permissions/permissionsSelectors'

const DisplayScreen = ({ roleState = {}, setRoleState = noop, readonly = '', calledBy = '' }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)

  const handleSwitchVal = (title, value) => {
    if (updateSettings && updatePermissions && updateUsers) {
      setRoleState({ [`${DISPLAY_MAPPINGS[title]}`]: value, footerFlag: true })
    }
  }

  const setMaxHeight = { xs: 'calc(100vh - 490px)', md: 'calc(100vh - 450px)' }

  return (
    <Box maxHeight={calledBy ? setMaxHeight : 'calc(100vh - 350px)'} sx={{ overflowY: 'scroll' }}>
      {Children.toArray(
        DISPLAY_OPTIONS.map((val, index) => {
          return (
            <Box mb={1.2} mt={1.5} sx={{ pointerEvents: readonly }}>
              {index === 1 && (
                <Box>
                  <Typography variant="h6" mt={1.8}>
                    {T.WORK_ORDER_AND_ROUTE_INFO}
                  </Typography>
                  <Typography variant="body1" mt={1} mb={1.5}>
                    {T.ITEM_APPEAR_INFO}
                  </Typography>
                </Box>
              )}

              <CommonSwitch
                titlePosition="right"
                title={val}
                isChecked={roleState[DISPLAY_MAPPINGS[val]]}
                onChange={checked => handleSwitchVal(val, checked)}
              />
            </Box>
          )
        })
      )}
    </Box>
  )
}

DisplayScreen.propTypes = {
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
  readonly: PropTypes.string,
  calledBy: PropTypes.string,
}

export default memo(DisplayScreen)
