import React, { useReducer, Children, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import sum from 'lodash/sum'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'

import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { Box, MenuItem, SvgIcon, IconButton, Grid, Stack, Typography, Button, Divider, useTheme } from '@mui/material'
import { Cancel, Add } from '@mui/icons-material'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'

import { formatMeasure } from 'utils/format'
import { isPriceTypeDollar, getRecurrenceText } from 'utils/service'
import { isTenDigitsOnly } from 'utils/validations'
import { getInvoiceLineData } from 'data/work-order/workOrderSelectors'
import { useGetGeneralFeeListMutation } from 'api/pricing/getGeneralFeeList'
import { CommonTextLabel } from 'components/common'

import T from 'T'
import FooterButton from 'components/buttons/FooterButton'
import HHTextField from 'components/form-fields/v5/HHTextField'
import HHNumberField from 'components/form-fields/v5/HHNumberField'

import './style.scss'
import { useUpdateWorkOrderInvoiceMutation } from 'api/work-order/updateWorkOrderInvoice'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'
import { Accordion, AccordionDetails, AccordionSummary } from 'components/customer-details/tables/StyledAccordian'
import { sortBy } from 'lodash'
import lowerCase from 'lodash/lowerCase'
import { generalFeeDefaultFilter } from './filters'

const RIGHT_ALIGN = { input: { textAlign: 'right' } }

const NEW_FEE_TAX_MODEL = {
  feeTaxId: '',
  feeCharge: '',
  chargeType: '',
  subChargeType: '',
  measure: '',
  quantity: 1,
  isDirty: false,
  invoiceLineItemId: '',
  workOrderId: '',
}

const WorkOrderDetails = ({
  workOrderId = '',
  service = {},
  draftFees = [],
  invoiceLine = {},
  onUpdateInvoice,
  onAddUpdateDraftFee,
  onUpdateServiceDetails,
  onError,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [getGeneralFeeList] = useGetGeneralFeeListMutation()
  const [updateWorkOrderInvoice] = useUpdateWorkOrderInvoiceMutation()

  const [invoiceState, setInvoiceState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isInvoiceDetailsExpanded: true,
    isInvoiceEditable: false,
    generalFeeList: [],
    feeTax: [],
    toBeDeletedFeeTax: [],
    isOpenInvoiceDrawer: false,
    serviceAction: '',
  })

  const { isInvoiceDetailsExpanded, isInvoiceEditable, generalFeeList, feeTax, toBeDeletedFeeTax, isOpenInvoiceDrawer, serviceAction } =
    invoiceState
  const sortedGeneralFeeList = useMemo(() => sortBy(generalFeeList, fee => lowerCase(get(fee, 'feeName', ''))), [generalFeeList])

  const { invoiceLineId, invoiceLineItemId, invoiceStatus } = getInvoiceLineData({ invoiceLine })
  const { name, recurrence, pricingPeriod, price, materialType, measure, action, method, recurrenceFrequency } = service

  const handleChange = (key, value, index = '', parentKey = '', isEditMode = false) => {
    if (index === '') {
      setInvoiceState({ [key]: value })
      return
    }

    const parentKeyValue = cloneDeep(get(invoiceState, parentKey, {}))
    set(parentKeyValue, `[${index}].${key}`, value)
    set(parentKeyValue, `[${index}].isDirty`, true)

    if (isEditMode) {
      set(parentKeyValue, `[${index}].edit`, true)
    }

    if (key === 'feeTaxId') {
      // populate initial feeCharge
      const initialFeeCharge = generalFeeList.find(fee => fee.id === value)

      set(parentKeyValue, `[${index}].feeCharge`, get(initialFeeCharge, 'value', 0))
      set(parentKeyValue, `[${index}].subChargeType`, get(initialFeeCharge, 'subChargeType', 'General'))
      set(parentKeyValue, `[${index}].measure`, '')
      set(parentKeyValue, `[${index}].feeName`, get(initialFeeCharge, 'feeName', ''))
      set(parentKeyValue, `[${index}].workOrderId`, workOrderId)
    }

    setInvoiceState({ [parentKey]: parentKeyValue })
  }

  const setFeeCharges = () => {
    if (invoiceLineId) {
      const newFeeTax = get(invoiceLine, 'invoiceLineItem[0].invoiceFeeTaxCharges', [])
      setInvoiceState({ feeTax: newFeeTax.map(fee => ({ ...fee, invoiceLineItemId })) })
      return
    }

    setInvoiceState({ feeTax: [...draftFees] })
  }

  const parseAndFixedDecimals = number => parseFloat(number).toFixed(2)

  const addNewFeeTax = () => {
    const cloneFeeModel = cloneDeep(NEW_FEE_TAX_MODEL)
    set(cloneFeeModel, 'chargeType', 'Fee')

    set(cloneFeeModel, 'invoiceLineItemId', invoiceLineItemId)
    setInvoiceState({ feeTax: [...feeTax, cloneFeeModel] })
  }

  const removeFeeTax = (index, id = '') => {
    // For new added fee there will be no id and we don't need to call the update API
    // For delete fee we need to call the update API

    const newToBeDeletedFeeTax = id
      ? [
          ...toBeDeletedFeeTax,
          {
            invoiceLineItemId,
            id,
            delete: true,
          },
        ]
      : toBeDeletedFeeTax

    setInvoiceState({ feeTax: feeTax.filter((fee, feeTaxIndex) => feeTaxIndex !== index), toBeDeletedFeeTax: newToBeDeletedFeeTax })
  }

  const handleWorkOrderSuccess = () => setInvoiceState({ isInvoiceEditable: false, toBeDeletedFeeTax: [] })

  const handleDraftFee = () => {
    const getValidFees = feeTax.filter(fee => fee.isDirty && fee.feeTaxId)
    if (!getValidFees.length && !toBeDeletedFeeTax.length) {
      handleWorkOrderSuccess()
      return
    }

    const payload = {
      draftFees: getValidFees,
      removeDraftFees: toBeDeletedFeeTax,
    }

    onAddUpdateDraftFee(payload)
      .unwrap()
      .then(() => {
        onUpdateInvoice()
        setInvoiceState({ isInvoiceEditable: false, toBeDeletedFeeTax: [] })
      })
  }

  const handleUpdateInvoiceDetails = () => {
    const getValidFees = feeTax.filter(fee => fee.isDirty && fee.feeTaxId)
    const getAllChangedInvoices = getValidFees.concat(toBeDeletedFeeTax)

    if (!getAllChangedInvoices.length) {
      handleWorkOrderSuccess()
      return
    }

    const payload = {
      invoiceId: invoiceLineId,
      fees: getAllChangedInvoices,
    }
    dispatch(putIsLoading(true))
    updateWorkOrderInvoice(payload)
      .unwrap()
      .then(() => {
        onUpdateInvoice()
        dispatch(putIsLoading(false))
        setInvoiceState({ isInvoiceEditable: false, toBeDeletedFeeTax: [] })
      })
      .catch(error => {
        dispatch(putIsLoading(false))
        handleError(error)
      })
  }

  const handleWorkOrderUpdate = () => {
    if (serviceAction !== action) {
      // Call service action update API if different action
      onUpdateServiceDetails({ id: workOrderId, serviceAction })
        .unwrap()
        .catch(error => {
          setInvoiceState({ isInvoiceEditable: true, serviceAction: action })
          onError(error)
        })
    }

    if (invoiceLineId) {
      handleUpdateInvoiceDetails()
      return
    }

    handleDraftFee()
  }

  const handleEditClick = () => setInvoiceState({ isInvoiceEditable: true })

  useEffect(() => {
    setFeeCharges()
    setInvoiceState({ serviceAction: action })
  }, [invoiceLineId, draftFees])

  useEffect(() => {
    if (workOrderId) {
      getGeneralFeeList(generalFeeDefaultFilter)
        .unwrap()
        .then(payload => {
          // Currently only fixed $ fees supported (percentage not supported)
          const feeAndCharges = get(payload, 'feeAndCharges', []).filter(fee => fee?.feeName && isPriceTypeDollar(fee?.valueType))
          setInvoiceState({ generalFeeList: feeAndCharges })
        })
        .catch(() => setInvoiceState({ generalFeeList: [] }))
    }
  }, [workOrderId])

  const renderAmountField = (value = 0, readOnly = false) => (
    <HHNumberField
      fullWidth
      readOnly={readOnly}
      placeholder="$0.00"
      value={parseAndFixedDecimals(value)}
      decimalScale={2}
      allowNegative
      prefix="$"
      thousandSeparator
      sx={{ ...RIGHT_ALIGN }}
    />
  )

  const getTotalPrice = () => {
    // count -ve symbol value as zero
    const generalFeeSum = feeTax.map(fee => (fee.feeCharge === '-' ? 0 : fee.feeCharge * fee.quantity))
    return sum(generalFeeSum)
  }

  return (
    <div className="wo-invoice-details common-accordion">
      <Accordion
        className="accordion-wrapper"
        sx={{
          '&.Mui-expanded': {
            margin: 0,
          },
        }}
        expanded={isInvoiceDetailsExpanded === true}
      >
        <AccordionSummary
          expandIcon={
            <div onClick={() => handleChange('isInvoiceDetailsExpanded', !isInvoiceDetailsExpanded)} className="flex cursor-pointer">
              <ArrowRightIcon className="downIcon" />
            </div>
          }
          withNewColors
          className="accordion-summary"
        >
          <Stack alignItems="center" flexDirection="row" className="header-content">
            <Stack alignItems="center" justifyContent="space-between" flexDirection="row" sx={{ width: '100%' }}>
              <Stack alignItems="center" flexDirection="row">
                <Typography
                  sx={{ ml: 1 }}
                  variant="h6"
                  className="cursor-pointer text"
                  onClick={() => handleChange('isInvoiceDetailsExpanded', !isInvoiceDetailsExpanded)}
                >
                  {T.WORK_ORDER_DETAILS}
                </Typography>
              </Stack>

              {!isInvoiceEditable && (!invoiceStatus || invoiceStatus !== T.PAID) && (
                <IconButton sx={{ p: 0 }} size="small" onClick={handleEditClick}>
                  <SvgIcon fontSize="inherit">
                    <PencilAlt />
                  </SvgIcon>
                </IconButton>
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails className="accordion-content">
          <>
            <Grid
              container
              spacing={2}
              ml={0}
              py={2}
              pr={2}
              sx={{
                background: theme.palette.background.header,
                borderBottom: `1px solid ${theme.palette.border.secondary}`,
                maxWidth: '100%',
              }}
            >
              <Grid item xs={12} sm={5}>
                <HHTextField fullWidth readOnly label={T.SERVICE} value={name} />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <HHTextField
                      fullWidth
                      readOnly
                      label={T.RECURRENCE}
                      value={getRecurrenceText(
                        get(recurrence, 'recurrencePer', ''),
                        get(recurrence, 'recurrenceInterval', ''),
                        recurrenceFrequency
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <HHTextField fullWidth readOnly label="Billing Period" value={pricingPeriod} />
                  </Grid>
                  <Grid item xs={12} sm={3} className="align-right">
                    <CommonTextLabel title={T.PRICE} className="ttc db" />
                    {renderAmountField(price, true)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="content-wrapper">
              <Grid container spacing={2} my={1}>
                <Grid item xs={12} sm={3} pr={2} className="left-service">
                  <div>
                    <HHTextField fullWidth readOnly label={`${T.MATERIAL}s`} value={materialType} />
                  </div>

                  <div className="mt2-5">
                    <HHTextField fullWidth readOnly label={T.MEASURE} value={formatMeasure(measure)} />
                  </div>

                  <div className="mt2-5 service-details">
                    <HHTextField fullWidth label={T.ACTION} value={serviceAction} readOnly />
                  </div>

                  <Box my={1.75}>
                    <HHTextField fullWidth readOnly label={T.METHOD} value={method} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={9} className="right-service">
                  <Stack alignItems="center" justifyContent="space-between" flexDirection="row" className="mt2-5">
                    <Box width="50%">
                      <CommonTextLabel title="General Fees" className="ttc" />
                    </Box>

                    <Box width="30%">
                      <Stack alignItems="center" justifyContent="space-around" flexDirection="row" className="relative">
                        <Box textAlign="right" width="30%">
                          <CommonTextLabel title={T.QUANTITY} className="ttc" />
                        </Box>
                        <Box textAlign="right" width="50%">
                          <CommonTextLabel title={T.COST} className="ttc" />
                        </Box>
                      </Stack>
                    </Box>
                    <Box width="15%" textAlign="right">
                      <CommonTextLabel title={T.PRICE} className="ttc" />
                    </Box>
                  </Stack>

                  {feeTax &&
                    Children.toArray(
                      feeTax.map((tax, index) => {
                        const rowId = get(tax, 'id', '')
                        const isEditTax = !!rowId
                        const feeTaxId = get(tax, 'feeTaxId', '')
                        const quantity = get(tax, 'quantity', '')
                        const feeCharge = get(tax, 'feeCharge', '')
                        const feeName = get(tax, 'feeName', '')

                        const feeSum = feeCharge === '-' ? 0 : feeCharge * quantity
                        // handle deactivation case
                        const isFeeExists = generalFeeList.find(fee => fee.id === feeTaxId)

                        return (
                          <>
                            <Stack alignItems="center" justifyContent="space-between" flexDirection="row" sx={{ mt: index > 0 ? 1 : 0 }}>
                              <Box width="50%">
                                <Stack alignItems="center" flexDirection="row">
                                  <HHTextField
                                    select
                                    fullWidth
                                    readOnly={!isInvoiceEditable}
                                    placeholder="Select Fee"
                                    value={feeTaxId}
                                    onChange={event => handleChange('feeTaxId', event.target.value, index, 'feeTax', isEditTax)}
                                  >
                                    {/* Populate if fee doesn't exist */}
                                    {!isFeeExists && (
                                      <MenuItem disabled value={feeTaxId}>
                                        {feeName}
                                      </MenuItem>
                                    )}

                                    {sortedGeneralFeeList.map(fee => (
                                      <MenuItem key={fee.id} value={fee.id}>
                                        {fee.feeName}
                                      </MenuItem>
                                    ))}
                                  </HHTextField>

                                  <IconButton
                                    sx={{ ml: 1, visibility: isInvoiceEditable ? 'visible' : 'hidden' }}
                                    onClick={() => removeFeeTax(index, rowId)}
                                  >
                                    <Cancel fontSize="small" />
                                  </IconButton>
                                </Stack>
                              </Box>

                              <Box width="30%">
                                <Stack flexDirection="row" justifyContent="space-around" alignItems="center" sx={{ position: 'relative' }}>
                                  <Box textAlign="right" width="30%">
                                    <HHTextField
                                      fullWidth
                                      readOnly={!isInvoiceEditable}
                                      value={quantity}
                                      onChange={event => {
                                        if (isTenDigitsOnly(event.target.value)) {
                                          handleChange('quantity', event.target.value, index, 'feeTax', isEditTax)
                                        }
                                      }}
                                      sx={{ ...RIGHT_ALIGN }}
                                    />
                                  </Box>

                                  <Box textAlign="right" width="50%">
                                    <HHNumberField
                                      fullWidth
                                      readOnly={!isInvoiceEditable}
                                      placeholder="$0.00"
                                      value={parseAndFixedDecimals(feeCharge)}
                                      onValueChange={event => {
                                        const { value: originalValue } = event
                                        handleChange('feeCharge', originalValue, index, 'feeTax', isEditTax)
                                      }}
                                      decimalSeparator="."
                                      decimalScale={2}
                                      prefix="$"
                                      thousandSeparator
                                      sx={{ ...RIGHT_ALIGN }}
                                    />
                                  </Box>
                                </Stack>
                              </Box>

                              <Box textAlign="right" width="15%">
                                {renderAmountField(feeSum, true)}
                              </Box>
                            </Stack>
                          </>
                        )
                      })
                    )}

                  {isInvoiceEditable && (
                    <Box mt={2}>
                      <Button size="small" onClick={addNewFeeTax} startIcon={<Add />}>
                        {T.ADD}
                      </Button>
                    </Box>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} />
                    </Grid>
                  </Grid>

                  <Stack alignItems="center" justifyContent="space-between" sx={{ textAlign: 'right' }} flexDirection="row">
                    <CommonTextLabel title={T.FEE_TOTAL} className="total" />
                    <Box width="15%" className="price">
                      {renderAmountField(getTotalPrice(), true)}
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </div>

            {isInvoiceEditable && (
              <Stack
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row"
                sx={{ px: 2, height: 48, borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: 'divider' }}
              >
                <FooterButton
                  leftButtonTitle={T.CANCEL}
                  onClose={() => {
                    setInvoiceState({ isInvoiceEditable: false, toBeDeletedFeeTax: [], serviceAction: action })
                    setFeeCharges()
                  }}
                  rightButtonTitle={T.SAVE}
                  onProceed={handleWorkOrderUpdate}
                />
              </Stack>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

WorkOrderDetails.propTypes = {
  workOrderId: PropTypes.string,
  service: PropTypes.object,
  draftFees: PropTypes.array,
  invoiceLine: PropTypes.object,
  onUpdateInvoice: PropTypes.func.isRequired,
  onAddUpdateDraftFee: PropTypes.func.isRequired,
  onUpdateServiceDetails: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default memo(WorkOrderDetails)
