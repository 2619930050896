import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import VacationHoldChip from 'components/common/chip/VacationHoldChip'
import { handleError } from 'utils/error'
import { useLazyGetVacationHoldListQuery } from 'api/accounts/vacationHoldsCrud'
import { get, keyBy } from 'lodash'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import { combineAddressInfo } from 'utils/helper'
import { formatDateRangeToShortFeformat } from 'utils/date'
import { MoreVert } from '@mui/icons-material'
import noop from 'lodash/noop'
import { HHAlert } from 'components/common/HHAlert'
import T from 'T'

const filterAndFormatVacations = (vacationHoldList, locations) => {
  const locationMap = keyBy(locations, 'id')

  const activeVacations = vacationHoldList.filter(vacation => vacation.type === 'Vacation' && vacation.active && !vacation.deleted)

  return activeVacations.map(vacation => {
    const startDate = get(vacation, 'startDate')
    const endDate = get(vacation, 'endDate')
    const formattedVacationRange = formatDateRangeToShortFeformat([startDate, endDate])
    const formattedLocations = vacation.locations
      .map(locationId => {
        const location = locationMap[locationId]
        return location ? combineAddressInfo(location.address) : null
      })
      .filter(Boolean)

    return {
      ...vacation,
      formattedVacationRange,
      formattedLocations,
    }
  })
}

const VacationHoldList = ({ row, open = false, onClickVacationHoldActionMenu = noop, showActionMenu = false, itemSx, sx }) => {
  const { accountId } = row
  const theme = useTheme()
  const [getVacationHoldList, { data, isLoading, isFetching }] = useLazyGetVacationHoldListQuery()
  const [getAccountDetails, { data: accountData, isFetching: isGetAccountDetailsFetching, isLoading: isGetAccountDetailsLoading }] =
    useLazyGetAccountDetailsQuery()
  const loading = isLoading || isFetching || isGetAccountDetailsFetching || isGetAccountDetailsLoading
  const vacationHoldList = get(data, 'vacationHolds', [])

  const locations = get(accountData, 'locations', [])
  const formattedVacations = useMemo(() => {
    return filterAndFormatVacations(vacationHoldList, locations)
  }, [vacationHoldList, locations])
  const vacationHoldsCount = get(formattedVacations, 'length', 0)
  const hasVacationHolds = vacationHoldsCount > 0
  useEffect(() => {
    if (accountId && open) {
      getAccountDetails({ accountId }).unwrap().catch(handleError)
      getVacationHoldList({ accountId, deleted: false }).unwrap().catch(handleError)
    }
  }, [accountId, open])

  return (
    <>
      <List sx={{ ...sx }}>
        {loading && (
          <ListItem>
            <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
              <Skeleton>
                <VacationHoldChip label="" />
              </Skeleton>
            </ListItemIcon>
            <ListItemText primary={<Skeleton variant="text" width="6rem" />} secondary={<Skeleton variant="text" width="3rem" />} />
          </ListItem>
        )}
        {!loading && !hasVacationHolds && (
          <HHAlert borderColor={theme.palette.info.light} severity="info">
            {T.NO_ACTIVE_VACATION_HOLDS}
          </HHAlert>
        )}
        {!loading &&
          hasVacationHolds &&
          formattedVacations.map((vacationHold, index) => {
            const { title, formattedVacationRange, formattedLocations } = vacationHold
            const handleOpenActionMenu = event => {
              onClickVacationHoldActionMenu(event, vacationHold)
            }
            const locationsCount = get(formattedLocations, 'length', 0)
            const isNotLastIndex = formattedVacations.length - 1 !== index
            return (
              <ListItem sx={{ alignItems: 'flex-start', paddingBottom: 0, ...itemSx }} divider={isNotLastIndex}>
                <ListItemText
                  primary={<Typography variant="h6">{title}</Typography>}
                  disableTypography
                  secondary={
                    <Stack>
                      <Typography color="textSecondary">
                        {`${locationsCount} ${locationsCount === 1 ? 'location' : 'locations'}`}
                      </Typography>
                      {formattedLocations.map(location => (
                        <ListItem sx={{ mr: 4, ml: 2 }} disablePadding disableGutters>
                          <ListItemText primary={location} />
                        </ListItem>
                      ))}
                    </Stack>
                  }
                />
                <Stack sx={{ ml: 1 }} gap={1} direction="row" alignItems="center" flexWrap="nowrap">
                  <VacationHoldChip label={formattedVacationRange} />
                  {showActionMenu && (
                    <IconButton onClick={handleOpenActionMenu}>
                      <MoreVert />
                    </IconButton>
                  )}
                </Stack>
              </ListItem>
            )
          })}
      </List>
    </>
  )
}

VacationHoldList.propTypes = {
  row: PropTypes.object,
  open: PropTypes.bool,
  onClickVacationHoldActionMenu: PropTypes.func,
  showActionMenu: PropTypes.bool,
  itemSx: PropTypes.object,
  sx: PropTypes.object,
}

export default VacationHoldList
