import { createSlice } from '@reduxjs/toolkit'
import routes from 'router/routes'
import { get } from 'lodash'

const homeSlice = createSlice({
  name: 'home',
  initialState: { homePage: routes.app.recentlyViewed },
  reducers: {
    setHomePage: (state, action) => {
      state.homePage = action.payload
    },
  },
})
export const { setHomePage } = homeSlice.actions

export const selectHomePage = s => get(s, 'home.homePage', routes.app.recentlyViewed)

export default homeSlice.reducer
