import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import {
  Box,
  CardActionArea,
  cardActionAreaClasses,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'

import { getFormattedGracePeriod } from 'components/pricing/RentalFees/common/utils'
import { getRentalFeeDetailsPageUrl } from 'router/routes'

import T from 'T'
import Link from 'components/common/Link'
import EventBlocker from 'components/common/EventBlocker'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import RentalFeeStatItem from 'components/pricing/RentalFees/RentalFeesListing/RentalFeeStatItem'
import RentalFeesActionMenu from 'components/pricing/RentalFees/common/RentalFeesActionMenu'

const DESKTOP_MIN_WIDTH = { lg: '100px', xl: '150px' }

const RenderRentalFeeCell = ({ row }) => {
  const { id, feeName, pricingPeriod, amountCents, businessLine, accountCount = 0, serviceCount = 0, gracePeriodDays } = row
  const capitalizedPricingPeriod = useMemo(() => capitalize(pricingPeriod), [pricingPeriod])
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpenActionMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseActionMenu = () => {
    setAnchorEl(null)
  }

  const formattedGracePeriod = useMemo(() => getFormattedGracePeriod(gracePeriodDays), [gracePeriodDays])
  const formattedBusinessLine = businessLine || 'None'

  return (
    <CardActionArea
      disableRipple
      disableTouchRipple
      sx={{
        px: 0,
        height: '100%',
        [`& .${cardActionAreaClasses.focusVisible}, & .${cardActionAreaClasses.focusHighlight}`]: {
          display: 'none',
        },
      }}
      to={getRentalFeeDetailsPageUrl(id)}
      component={Link}
    >
      <Box height="100%" width="100%">
        <Grid container alignItems="center" justifyContent="center" flexWrap="nowrap">
          <Grid item xs sm md lg zeroMinWidth>
            <ListItem sx={{ px: 4, py: 1 }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                    {feeName}
                  </Typography>
                }
                secondary={
                  <Grid container item xs={12}>
                    <RentalFeeStatItem
                      xs={12}
                      sm={6}
                      md={4}
                      lg="auto"
                      primary={formattedGracePeriod}
                      secondary={capitalize(T.GRACE_PERIOD)}
                      divider
                      sx={{ minWidth: DESKTOP_MIN_WIDTH }}
                    />
                    <RentalFeeStatItem
                      xs={12}
                      sm={6}
                      md={4}
                      lg="auto"
                      primary={capitalizedPricingPeriod}
                      secondary={capitalize(T.PRICING_PERIOD)}
                      divider={isXs || isTablet || isDesktop}
                      sx={{ minWidth: DESKTOP_MIN_WIDTH }}
                    />
                    <RentalFeeStatItem
                      xs={12}
                      sm={6}
                      md={4}
                      lg="auto"
                      primary={accountCount}
                      secondary={T.ACCOUNTS}
                      divider={isXs || isMobile || isDesktop}
                      sx={{ minWidth: DESKTOP_MIN_WIDTH }}
                    />
                    <RentalFeeStatItem
                      xs={12}
                      sm={6}
                      md={4}
                      lg="auto"
                      primary={serviceCount}
                      secondary={T.SERVICES}
                      divider={isXs || isTablet || isDesktop}
                      sx={{ minWidth: DESKTOP_MIN_WIDTH }}
                    />
                    <RentalFeeStatItem
                      xs={12}
                      sm={6}
                      md={4}
                      lg
                      primary={formattedBusinessLine}
                      secondary={capitalize(T.BUSINESS_LINE)}
                      zeroMinWidth
                    />
                  </Grid>
                }
              />
            </ListItem>
          </Grid>
          <Grid
            item
            sx={{
              minWidth: { xs: '12rem', sm: '12rem', md: '12rem', lg: '13rem', xl: '24rem' },
              pr: 4,
            }}
          >
            <Grid container flexWrap="nowrap" alignItems="center" justifyContent="flex-end" columnGap={2}>
              <Grid item>
                <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                  <HHDisplayMoney value={amountCents} formatToDollars />
                </Typography>
              </Grid>
              <Grid item>
                <EventBlocker preventDefault>
                  <IconButton onClick={handleOpenActionMenu}>
                    <MoreVert />
                  </IconButton>
                </EventBlocker>
              </Grid>
              <EventBlocker preventDefault>
                <RentalFeesActionMenu rentalFee={{ ...row }} anchorEl={anchorEl} onClose={handleCloseActionMenu} />
              </EventBlocker>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardActionArea>
  )
}

RenderRentalFeeCell.propTypes = {
  row: PropTypes.object,
}

export default RenderRentalFeeCell
