import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as RentalFeeIcon } from 'assets/RentalFee.svg'

import StyledBadge from 'components/notes/common/StyledBadge'

const RentalFeeBadge = ({ rentalFeeCount = 0, ...rest }) => {
  return (
    <StyledBadge badgeContent={rentalFeeCount} {...rest}>
      <RentalFeeIcon />
    </StyledBadge>
  )
}

RentalFeeBadge.propTypes = {
  rentalFeeCount: PropTypes.number,
}

export default RentalFeeBadge
