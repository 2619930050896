/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { handleCommonTableformatFilters } from '../../utils/table'
import { filterMapToQueryParams } from '../../utils/LHSbracket'
// eslint-disable-next-line import/no-cycle
import { fixDateFilters } from './fixDateFilters'

export const mapSortModelToSortRules = sortModel =>
  sortModel.map(({ field, sort }) => ({ columnName: field, operation: sort.toUpperCase() }))
export const mapSortRulesToSortModel = sortRules =>
  sortRules.map(({ columnName, operation }) => ({ field: columnName, sort: operation.toLowerCase() }))

export const createDataGridSlice = ({ name, initialState, reducers }) =>
  createSlice({
    name,
    initialState,
    reducers: {
      setSelectionModel: (state, action) => {
        const selectionModel = get(action, 'payload', [])
        state.selectionModel = selectionModel
      },
      setSortModel: (state, action) => {
        const sortModel = get(action, 'payload', [])
        state.sorting.sortModel = sortModel
        state.filters.sortRules = mapSortModelToSortRules(sortModel)
      },
      setPage: (state, action) => {
        state.pagination.page = action.payload
      },
      setPageSize: (state, action) => {
        state.pagination.pageSize = action.payload
      },
      setColumnVisibilityModel: (state, action) => {
        state.columns.columnVisibilityModel = action.payload
      },
      setIsOpenTableFilters: (state, action) => {
        state.filters.isOpenTableFilters = action.payload
      },
      setFilters: (state, action) => {
        const allFilters = get(action, 'payload.allFilters', {})
        state.filters = {
          ...state.filters,
          ...action.payload,
        }
        const parsedFilters = handleCommonTableformatFilters(allFilters)
        state.filters.searchFilters = fixDateFilters(parsedFilters)
        state.filters.queryParams = filterMapToQueryParams(allFilters)
      },
      setPinnedColumns: (state, action) => {
        state.pinnedColumns = action.payload
      },
      ...reducers,
    },
  })
