import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { List, ListItem, ListItemText, Divider } from '@mui/material'

import { getNoteUserInfo, getSortedHistoryNotes } from 'data/notes/notesSelectors'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import LinkifyText from 'components/notes/common/LinkifyText'

const ViewNoteHistory = ({ notes = [] }) => {
  const sortedHistoryNotes = getSortedHistoryNotes({ notes })
  return (
    <List>
      {Children.toArray(
        sortedHistoryNotes.map((note, index) => {
          const { note: noteText, version } = note
          const isFirstVersion = version === 1
          const { firstName, lastName, dateTime } = getNoteUserInfo({ note, isUpdatedByPreference: !isFirstVersion })
          const fullName = `${firstName} ${lastName}`.trim()
          const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime })

          return (
            <>
              <ListItem>
                <ListItemText
                  sx={{ pl: 4.5 }}
                  primaryTypographyProps={{ variant: 'h6', fontWeight: 400, whiteSpace: 'pre-line' }}
                  primary={<LinkifyText>{noteText}</LinkifyText>}
                  secondaryTypographyProps={{ variant: 'subtitle2', fontWeight: 400 }}
                  secondary={`${isFirstVersion ? 'Created' : 'Updated'} by ${fullName} | ${onlyDate} @ ${onlyTime}`}
                />
              </ListItem>
              {sortedHistoryNotes.length - 1 !== index && <Divider />}
            </>
          )
        })
      )}
    </List>
  )
}

ViewNoteHistory.propTypes = {
  notes: PropTypes.array,
}

export default ViewNoteHistory
