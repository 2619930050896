import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'

const PaymentNoteDialog = ({ isOpen = false, note = '', onClose }) => {
  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={T.PAYMENT_NOTE} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            {note}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" onClick={onClose}>
          OK
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

PaymentNoteDialog.propTypes = {
  isOpen: PropTypes.bool,
  note: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default PaymentNoteDialog
