import lowerCase from 'lodash/lowerCase'

import T from 'T'
import { get } from 'utils/lodash'

import { isRecurringService, isOnRequestService, isWeeklyRecurrence } from 'utils/service'

export const isServiceFormInvalid = (serviceType, taxZoneId, pricedServiceDetails, schedule, billingSchedule, isDeactivated) => {
  // We may uncomment this later
  // if (!taxZoneId) {
  //   return true;
  // }
  // Start date is required for recurrence service

  if (
    isRecurringService(serviceType) &&
    (!get(schedule, 'startDate') || get(schedule, 'isStartDateInvalid') || get(schedule, 'isEndDateInvalid'))
  ) {
    return true
  }

  // Start date is required for billing schedule

  if (
    isRecurringService(serviceType) &&
    get(billingSchedule, 'isDifferentBillingSchedule') &&
    (!get(billingSchedule, 'startDate') || get(billingSchedule, 'isStartDateInvalid') || get(billingSchedule, 'isEndDateInvalid'))
  ) {
    return true
  }

  // add weekly check
  if (isRecurringService(serviceType) && isWeeklyRecurrence(get(pricedServiceDetails, 'recurrenceInterval', ''))) {
    // check if that matches with frequency X
    const recurrenceFrequency = get(pricedServiceDetails, 'recurrenceFrequency') || 1
    let serviceEvents = get(schedule, 'serviceEvents', [])

    // exclude off one events
    // serviceEvents = serviceEvents.filter(se => T.ONE_OFF.toLowerCase(se.eventType ? se.eventType.toLowerCase() : ''));
    serviceEvents = serviceEvents.filter(se => lowerCase(se.eventType) !== lowerCase(T.ONE_OFF))

    if (serviceEvents.length !== recurrenceFrequency) {
      // Invalid
      return true
    }

    return false
  }

  // may be required in future
  // if (isOnRequestService(serviceType) && !get(schedule, 'defaultActionType')) {
  //   return true;
  // }

  // Check all on request events
  if (isOnRequestService(serviceType)) {
    // actionDate & actionType are required
    const isEmptyEvent = get(schedule, 'serviceEvents', []).find(se => !se.actionDate || !se.actionType)
    if (isEmptyEvent) {
      return true
    }
  }

  return false
}
