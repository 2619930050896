import { useCallback, useRef } from 'react'
import { get } from 'lodash'
import { HIGHLIGHTED_MARKER_PROPS, UNASSIGNED_MARKER_PROPS } from 'components/route-manager/BulkActions/settings'
import debounce from 'lodash/debounce'

const useHandleSequenceChange = ({
  getIsMoveToRow,
  getTargetIndex,
  getValues,
  moveToRouteSequenceLine,
  moveToRouteStopMarkers,
  moveToRows,
  setValue,
  stopId,
  onSuccess,
}) => {
  const repositionArrayItem = (array, fromIndex, targetIndex) => {
    const arrayWithoutItem = [...array.slice(0, fromIndex), ...array.slice(fromIndex + 1)]
    return [...arrayWithoutItem.slice(0, targetIndex), array[fromIndex], ...arrayWithoutItem.slice(targetIndex)]
  }

  const handleSequenceChange = useCallback(
    value => {
      const originalIndex = moveToRows.findIndex(item => item.id === stopId)
      if (originalIndex !== -1) {
        const formValues = getValues()
        const targetIndex = getTargetIndex(originalIndex, value)
        const reorderedRows = repositionArrayItem(moveToRows, originalIndex, targetIndex)
        const reorderedStopMarkers = repositionArrayItem(moveToRouteStopMarkers, originalIndex, targetIndex)
        const reorderedCoordinates = repositionArrayItem(moveToRouteSequenceLine.geometry.coordinates, originalIndex, targetIndex)
        let currentPosition = 0
        const updatedMoveToRows = []
        const updatedMoveToMarkers = []
        for (let i = 0; i < reorderedRows.length; i++) {
          const rowItem = { ...reorderedRows[i] }
          const markerItem = { ...reorderedStopMarkers[i], properties: { ...reorderedStopMarkers[i].properties } }

          const rowId = get(rowItem, 'id')

          if (getIsMoveToRow(rowItem)) {
            rowItem.position = currentPosition
            markerItem.properties.position = currentPosition + 1
            currentPosition += 1
          } else {
            const rowTypedPosition = get(formValues, rowId, '')

            if (stopId !== rowId && rowTypedPosition && rowTypedPosition !== currentPosition + 1) {
              setValue(rowId, currentPosition + 1)
            }

            rowItem.position = rowTypedPosition ? currentPosition : ''
            markerItem.properties.position = rowTypedPosition ? currentPosition + 1 : ''

            if (rowTypedPosition !== '') {
              currentPosition += 1
              markerItem.properties = {
                ...markerItem.properties,
                ...HIGHLIGHTED_MARKER_PROPS,
              }
            } else {
              markerItem.properties = {
                ...markerItem.properties,
                ...UNASSIGNED_MARKER_PROPS,
              }
            }
          }
          updatedMoveToRows.push(rowItem)
          updatedMoveToMarkers.push(markerItem)
        }

        const updatedMoveToRouteSequenceLine = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: reorderedCoordinates,
          },
        }
        onSuccess({
          moveToRows: updatedMoveToRows,
          moveToRouteStopMarkers: updatedMoveToMarkers,
          moveToRouteSequenceLine: updatedMoveToRouteSequenceLine,
          focus: {
            name: stopId,
            rowIndex: targetIndex,
          },
        })
      }
    },
    [moveToRows, stopId, getValues, getTargetIndex, moveToRouteStopMarkers, moveToRouteSequenceLine, getIsMoveToRow, setValue]
  )

  const debouncedHandleSequenceChange = useRef(debounce(handleSequenceChange, 750))

  return useCallback(
    value => {
      debouncedHandleSequenceChange.current.cancel()
      debouncedHandleSequenceChange.current = debounce(handleSequenceChange, 750)
      debouncedHandleSequenceChange.current(value)
    },
    [handleSequenceChange]
  )
}

export default useHandleSequenceChange
