import React from 'react'
import { Box, Grid, Skeleton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

const DisposalTicketFormSkeleton = () => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Grid container rowSpacing={1}>
      <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} columnSpacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Skeleton width="100%">
            <TextField fullWidth />
          </Skeleton>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Skeleton width="100%">
            <TextField fullWidth />
          </Skeleton>
        </Grid>
      </Grid>
      <Grid item container xs={12} columnSpacing={2} justifyContent="space-between" alingItems="center">
        <Grid container item xs={12} justifyContent="space-between" alingItems="center">
          <Grid item xs="auto">
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Skeleton height={48} width={160}>
                <Typography variant="h5" fontWeight={500} />
              </Skeleton>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
          <Skeleton>
            <TextField fullWidth />
          </Skeleton>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
          <Skeleton>
            <TextField fullWidth />
          </Skeleton>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
          <Skeleton>
            <TextField fullWidth />
          </Skeleton>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
          <Skeleton>
            <TextField fullWidth />
          </Skeleton>
        </Grid>
        <Grid
          item
          container
          lg
          sm={8}
          md={8}
          columnSpacing={2}
          alignItems="flex-end"
          justifyContent={isTabletOrMobile ? 'flex-end' : 'center'}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Skeleton>
              <TextField fullWidth />
            </Skeleton>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Skeleton>
              <TextField fullWidth />
            </Skeleton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DisposalTicketFormSkeleton
