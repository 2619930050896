import { useCallback } from 'react'

const useParseDaysOfWeekBetweenDaysVariant = (date, fromSerializedDate, toSerializedDate) => {
  return useCallback(
    (daysOfWeek, pricingPeriod) => {
      const dayAbbreviations = ['S', 'M', 'T', 'W', 'R', 'F', 'U']
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const isDaily = pricingPeriod === 'Daily'

      return dayAbbreviations.map((abbr, index) => {
        let variant = 'default'
        if (isDaily || daysOfWeek.includes(abbr)) {
          variant = 'active'
        }
        if (date.getDay() === index) {
          variant = 'primary'
        }
        return {
          day: dayNames[index],
          variant,
        }
      })
    },
    [fromSerializedDate, toSerializedDate]
  )
}

export default useParseDaysOfWeekBetweenDaysVariant
