import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { HHFormNumberField, HHFormPriceField, HHFormSelectField } from 'components/form-fields/v5'
import { IconButton, Box, Typography, styled, TableCell as MuiTableCell } from '@mui/material'
import { PlusCircle } from '@styled-icons/heroicons-solid/PlusCircle'
import { XCircle } from '@styled-icons/heroicons-solid/XCircle'
import { MEASURE_OPTIONS } from 'components/pricing/settings'

const TableCell = styled(MuiTableCell)({
  padding: '4px 8px 4px 0px',
  '&:first-child': {
    paddingLeft: 8,
  },
})

const ConfirmationTableTiersFields = ({ index, name, updateFormElement }) => {
  const { watch, control, getValues, setValue } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${name}.${index}.tiers`,
  })
  const showTierFields = watch(`${name}.${index}.showTierFields`)

  React.useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name?.includes('maxVal') && type === 'change') {
        let [fieldName, index, subfieldName, subIndex] = name.split('.')
        index = Number.parseInt(index, 10)
        subIndex = Number.parseInt(subIndex, 10)
        const tiers = get(values, `${fieldName}.${index}.tiers`, [])
        if (tiers.length > 1 && tiers.length >= subIndex + 1) {
          setValue(`${fieldName}.${index}.tiers.${subIndex + 1}.minVal`, get(tiers, `${subIndex}.maxVal`))
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <TableCell>
        {fields.map((item, tierIndex) => (
          <Box
            sx={{
              minWidth: 202,
              height: 44,
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'row',
              justifyItems: 'center',
              alignItems: 'center',
            }}
            key={`${name}.${index}.tiers.${tierIndex}`}
          >
            {showTierFields && (
              <HHFormNumberField
                size="small"
                sx={{ width: 44, backgroundColor: '#F6F7F9' }}
                control={control}
                disabled
                name={`${name}.${index}.tiers.${tierIndex}.minVal`}
              />
            )}
            {showTierFields && (
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '32px',
                  width: '1em',
                  display: 'inline',
                  textAlign: 'center',
                }}
              >
                -
              </Typography>
            )}
            {showTierFields && (
              <HHFormNumberField
                size="small"
                disabled={tierIndex !== 0 && tierIndex === fields.length - 1}
                sx={{
                  mr: 1,
                  width: 44,
                  ...(tierIndex !== 0 && tierIndex === fields.length - 1 && { backgroundColor: '#F6F7F8' }),
                }}
                control={control}
                name={`${name}.${index}.tiers.${tierIndex}.maxVal`}
              />
            )}
            <HHFormPriceField
              size="small"
              sx={{ width: 96, float: 'right' }}
              control={control}
              name={`${name}.${index}.tiers.${tierIndex}.price`}
            />
          </Box>
        ))}
      </TableCell>
      <TableCell>
        {fields.map((item, tierIndex) => (
          <Box
            sx={{
              minWidth: 148,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            {tierIndex === 0 && (
              <HHFormSelectField
                size="small"
                sx={{
                  width: 88,
                  mr: 1,
                }}
                control={control}
                name={`${name}.${index}.measureType`}
                options={MEASURE_OPTIONS}
                getOptionLabel={({ key }) => key}
                getOptionValue={({ value }) => value}
              />
            )}
            {tierIndex !== 0 && (
              <Box
                sx={{
                  width: 88,
                  mr: 1,
                }}
              />
            )}
            <IconButton
              onClick={() => {
                const showTierFields = getValues(`${name}.${index}.showTierFields`)
                if (showTierFields === false) {
                  const rowValues = watch(`${name}.${index}`)
                  updateFormElement(index, { ...rowValues, showTierFields: true })
                } else if (tierIndex === 0) {
                  const lastMaxVal = getValues(`${name}.${index}.tiers.${fields.length - 1}.maxVal`)
                  append({
                    minVal: lastMaxVal,
                    maxVal: '',
                    price: '',
                  })
                } else {
                  if (tierIndex !== 0 && fields.length - 1 >= tierIndex) {
                    setValue(`${name}.${index}.tiers.${tierIndex - 1}.maxVal`, '')
                  }
                  remove(tierIndex)
                }
              }}
              disabled={
                (showTierFields && tierIndex === 0 && watch(`${name}.${index}.tiers.0.maxVal`) === '' && fields.length === 1) ||
                (fields.length > 1 &&
                  (watch(`${name}.${index}.tiers.${fields.length - 2}.maxVal`) === '' ||
                    watch(`${name}.${index}.tiers.${fields.length - 1}.price`) === '') &&
                  tierIndex === 0)
              }
            >
              {tierIndex === 0 && (
                <PlusCircle
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                />
              )}
              {tierIndex !== 0 && (
                <XCircle
                  sx={{
                    width: 20,
                    height: 20,
                    color: '#AA2B2B',
                  }}
                />
              )}
            </IconButton>
          </Box>
        ))}
      </TableCell>
    </>
  )
}

ConfirmationTableTiersFields.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  updateFormElement: PropTypes.func.isRequired,
}

export default ConfirmationTableTiersFields
