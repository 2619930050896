import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { get, groupBy } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import {
  addAssetsImageToMap,
  addPositionLayers,
  addSourceAsync,
  BOTTOM_LAYER,
  getBounds,
  getFeaturePointFromCoordsObj,
  MIDDLE_LAYER,
  removeLayerIfExist,
  removeSourceIfExist,
  TOP_LAYER,
} from 'utils/map'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import ToBeInsertedMarker from 'assets/map/ToBeInsertedMarker.png'
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import Loader from '../../common/loader'

const { MAP_VIEW_URL, LIGHT_VIEW, PROJECTION } = MAP_DEFAULT_OPTIONS

const MapContainer = styled('div')({
  top: 0,
  right: 0,
  width: '100%',
  height: 'calc(100vh - 121.75px)',
  overflow: 'hidden',
  zIndex: 1,
})

const ClusterMapView = ({ geojsonData }) => {
  const theme = useTheme()
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const userInfo = useSelector(s => s.AuthReducer.userInfo)
  const stopToBeAssigned = useSelector(s => s.routingActions.assignStopDialog.stopToBeAssigned, shallowEqual)

  const map = useRef(null)
  const mapContainer = useRef(null)

  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  useEffect(() => {
    if (map.current) return () => {}
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: `${MAP_VIEW_URL}${LIGHT_VIEW}`,
      center: [-98.5795, 39.82835],
      zoom: 3.6,
      projection: PROJECTION,
    })
    map.current.addControl(new mapboxgl.FullscreenControl())

    map.current.once('load', () => {
      addAssetsImageToMap(map.current, 'to-be-assigned-marker', ToBeInsertedMarker)
      addPositionLayers(map.current)
      setIsMapLoaded(true)
    })
  })

  useEffect(() => {
    const mapCurrentRef = map.current
    if (!isMapLoaded || (Array.isArray(geojsonData) && geojsonData.length === 0)) return

    const routeFeaturesGrouped = groupBy(geojsonData, feature => feature.properties.routeId)

    Object.keys(routeFeaturesGrouped).forEach(routeId => {
      const routeFeatures = routeFeaturesGrouped[routeId]

      addSourceAsync(
        mapCurrentRef,
        `stops-${routeId}`,
        { type: 'FeatureCollection', features: routeFeatures },
        {
          type: 'geojson',
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        }
      )
        .then(() => {
          mapCurrentRef.addLayer(
            {
              id: `clusters-${routeId}`,
              type: 'circle',
              source: `stops-${routeId}`,
              filter: ['has', 'point_count'],
              paint: {
                'circle-color': routeFeatures[0].properties.color || '#89B0D0',
                'circle-radius': ['step', ['get', 'point_count'], 20, 10, 30, 100, 40],
                'circle-stroke-width': 2,
                'circle-stroke-color': '#ffffff',
              },
            },
            BOTTOM_LAYER
          )

          mapCurrentRef.addLayer(
            {
              id: `cluster-count-${routeId}`,
              type: 'symbol',
              source: `stops-${routeId}`,
              filter: ['has', 'point_count'],
              layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-size': 12,
              },
              paint: {
                'text-color': theme.palette.getContrastText(routeFeatures[0].properties.color || '#89B0D0'),
              },
            },
            MIDDLE_LAYER
          )
          mapCurrentRef.addLayer(
            {
              id: `unclustered-point-${routeId}`,
              type: 'circle',
              source: `stops-${routeId}`,
              filter: ['!', ['has', 'point_count']],
              paint: {
                'circle-color': routeFeatures[0].properties.color || '#89B0D0',
                'circle-radius': 10,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#ffffff',
              },
            },
            BOTTOM_LAYER
          )
          const { latitude, longitude } = stopToBeAssigned
          const toBeassignedCoord = [longitude, latitude]
          const bounds = getBounds([toBeassignedCoord])
          map.current.fitBounds(bounds, {
            padding: { top: 100, bottom: 100, left: 100, right: 100 },
            linear: false,
            maxZoom: 12,
          })
        })
        .catch(error => {
          console.error(`Error adding source for route ${routeId}:`, error)
        })
    })
  }, [isMapLoaded, geojsonData])

  useEffect(() => {
    const mapCurrentRef = map.current
    if (!stopToBeAssigned || !isMapLoaded) return () => {}
    removeLayerIfExist(mapCurrentRef, 'to-be-assigned-marker-layer')
    removeSourceIfExist(mapCurrentRef, 'to-be-assigned-marker')
    const { latitude, longitude, ...rest } = stopToBeAssigned
    const toBeAssignedFeature = getFeaturePointFromCoordsObj({
      latitude,
      longitude,
      properties: {
        latitude,
        longitude,
        ...rest,
        icon: 'to-be-assigned-marker',
      },
    })
    addSourceAsync(mapCurrentRef, 'to-be-assigned-marker', toBeAssignedFeature, {
      type: 'geojson',
    }).then(() => {
      mapCurrentRef.addLayer(
        {
          id: 'to-be-assigned-marker-layer',
          type: 'symbol',
          source: 'to-be-assigned-marker',
          layout: {
            'icon-image': ['get', 'icon'],
            'text-anchor': 'top',
            'icon-offset': [0, -50],
            'text-offset': [0, -3.45],
            'icon-size': 0.55,
            'text-size': 12,
          },
        },
        TOP_LAYER
      )
    })
  }, [stopToBeAssigned, isMapLoaded])

  useEffect(() => {
    const mapCurrentRef = map.current
    if (!isMapLoaded) return () => {}

    const stopsSource = mapCurrentRef.getSource('stops')
    if (mapCurrentRef && stopsSource && Array.isArray(geojsonData) && geojsonData.length !== 0) {
      stopsSource.setData({
        type: 'FeatureCollection',
        features: geojsonData,
      })
    }
  }, [map, geojsonData, isMapLoaded])

  useEffect(() => {
    return () => {
      map.current.remove()
      map.current = null
    }
  }, [])

  return (
    <Box position="relative" height="100%">
      {!isMapLoaded && <Loader sx={{ top: 0, width: '100%', minWidth: '100%', maxWidth: '100%' }} />}
      <MapContainer ref={mapContainer} />
    </Box>
  )
}

ClusterMapView.propTypes = {
  geojsonData: PropTypes.array,
}

export default ClusterMapView
