/* eslint-disable no-param-reassign */
import { createDataGridSlice } from '../generic/createDataGridSlice'

const getInitialState = () => ({
  createdInvoicesData: {
    selectedInvoicesCount: 0,
    selectedBillingProfileName: null,
    isInvoiceCreationSuccessModalOpen: false,
    skipZeroAmountInvoices: false,
  },
})

export const invoicesCreationBridgeSlice = createDataGridSlice({
  name: 'invoicesCreationBridge',
  initialState: getInitialState(),
  reducers: {
    setCreatedInvoicesState: (state, action) => {
      state.createdInvoicesData = {
        ...state.createdInvoicesData,
        ...action.payload,
      }
    },
    resetInvoicesCreationBridgeState: () => {
      return getInitialState()
    },
  },
})

export const { resetInvoicesCreationBridgeState, setCreatedInvoicesState } = invoicesCreationBridgeSlice.actions

export default invoicesCreationBridgeSlice.reducer
