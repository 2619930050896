import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import Backdrop from '@mui/material/Backdrop'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import { get } from 'utils/lodash'

import T from 'T'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import './style.scss'
import { noop } from 'lodash'

const SWIPE_AREA_WIDTH = 0
const CONFIRMATION_MSG = T.COMMON_CONFIRM_TITLE

const CommonDrawer = ({
  isOpen = false,
  disableClassNamePrefix = false,
  className = '',
  isShowConfirmation = false,
  isDirty = false,
  onChange = noop,
  children,
  drawerDirection = 'right',
  callback = noop,
  sx,
}) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  return (
    <Fragment key={drawerDirection}>
      <SwipeableDrawer
        sx={sx}
        open={isOpen}
        className={disableClassNamePrefix ? className : `drawer-container ${className}`}
        anchor={drawerDirection}
        swipeAreaWidth={SWIPE_AREA_WIDTH}
        onOpen={event => onChange(event, true)}
        onClose={() => {
          const isFormTouched = get(document.getElementsByClassName('drawer-form-touched'), [0])
          if (isShowConfirmation || isDirty || isFormTouched) {
            // If any callback, call that
            if (callback) {
              callback()
            }
            return setIsOpenConfirmModal(true)
          }
          return onChange(null, false)
        }}
        BackdropComponent={Backdrop}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disableEnforceFocus
      >
        {children}
      </SwipeableDrawer>

      {isOpenConfirmModal && (
        <ConfirmationModal
          className="global-drawer-confirm"
          isOpen={isOpenConfirmModal}
          title={CONFIRMATION_MSG}
          cancelButtonTitle={T.CANCEL}
          proceedButtonTitle={T.CLOSE}
          onCancel={() => {
            setIsOpenConfirmModal(false)
          }}
          onProceed={() => {
            onChange(null, false)
            setIsOpenConfirmModal(false)
          }}
        />
      )}
    </Fragment>
  )
}

CommonDrawer.propTypes = {
  disableClassNamePrefix: PropTypes.bool,
  isOpen: PropTypes.bool,
  isShowConfirmation: PropTypes.bool,
  isDirty: PropTypes.bool,
  className: PropTypes.string,
  drawerDirection: PropTypes.string,
  callback: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
}

export default CommonDrawer
