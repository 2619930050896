import React from 'react'
import { ListItemText } from '@mui/material'
import PropTypes from 'prop-types'

const LabeledItem = ({ sx = { my: 0 }, primary, primaryTypographyProps, secondary, secondaryTypographyProps, ...rest }) => {
  return (
    <ListItemText
      sx={sx}
      primary={primary}
      primaryTypographyProps={{ variant: 'body1', color: 'textSecondary', noWrap: true, ...primaryTypographyProps }}
      secondary={secondary}
      secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'textPrimary', ...secondaryTypographyProps }}
      {...rest}
    />
  )
}

LabeledItem.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  sx: PropTypes.object,
}

export default LabeledItem
