import { Card, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const LinkedServicesCard = ({ fullWidth = false, services = 0, onClick = () => {} }) => {
  return (
    <Card
      sx={{
        background: '#ffffff',
        border: '1px solid #e5e7eb',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%)',
        borderRadius: '4px',
        width: fullWidth ? '100%' : '358px',
        height: '56px',
        padding: '5px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Typography textAlign="center" fontWeight={500}>{`${services} Services Linked Automatically`}</Typography>
      <Typography textAlign="center" variant="subtitle2">
        Click here to View
      </Typography>
    </Card>
  )
}

LinkedServicesCard.propTypes = {
  services: PropTypes.number,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default LinkedServicesCard
