import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useSelector, shallowEqual } from 'react-redux'
import { TableRow, TableCell, TableBody as TBody, Radio, Tooltip, Typography } from '@mui/material'
import ExclamationCircle from '@mui/icons-material/ErrorOutline'

import {
  OFFICE_MAPPING,
  OFFICE_ALLOWED_PERMISSIONS,
  OFFICE_TOOLTIP_TEXT,
  OFFICE_RECORDS,
  NO_ACCESS_PERMISSION,
  READ_PERMISSION,
  UPDATE_PERMISSION,
  FULL_PERMISSION,
} from 'settings/constants/roles'

import { canUpdateSettings, canUpdateUsers, canUpdatePermissions } from 'data/permissions/permissionsSelectors'

const OfficeTableBody = ({ roleState = {}, setRoleState = noop }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const updatePermissions = useSelector(canUpdatePermissions, shallowEqual)
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)

  const handleChange = (event, type) => {
    if (updateSettings && updatePermissions && updateUsers) {
      const roleVal = roleState

      roleVal[OFFICE_MAPPING[type]] = Number(event.target.value)

      setRoleState(roleState)
      setRoleState({ footerFlag: true })
    }
  }

  return (
    <TBody
      sx={{
        '& .MuiTableCell-body': {
          borderBottom: 'unset',
          padding: '6px 0',
          '& .MuiRadio-root': {
            padding: 0,
          },
        },
      }}
    >
      {Children.toArray(
        Object.entries(OFFICE_RECORDS).map(([key, value]) => {
          return (
            <>
              <TableRow>
                <Typography variant="h5" mt={2} mb={0.5}>
                  {key}
                </Typography>
              </TableRow>

              {Children.toArray(
                value.map(val => {
                  return (
                    <TableRow hover>
                      <TableCell sx={{ display: 'flex' }}>
                        <Typography variant="body1">{val}</Typography>

                        <Tooltip title={OFFICE_TOOLTIP_TEXT[val]}>
                          <ExclamationCircle fontSize="small" sx={{ ml: 1, pl: 0.5, cursor: 'pointer' }} />
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        {OFFICE_ALLOWED_PERMISSIONS[val].includes(NO_ACCESS_PERMISSION) && (
                          <Radio
                            checked={[NO_ACCESS_PERMISSION, 0].includes(roleState[OFFICE_MAPPING[val]])}
                            onChange={event => handleChange(event, val)}
                            value={NO_ACCESS_PERMISSION}
                            name="radio-buttons"
                            size="small"
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {OFFICE_ALLOWED_PERMISSIONS[val].includes(READ_PERMISSION) && (
                          <Radio
                            checked={roleState[OFFICE_MAPPING[val]] === READ_PERMISSION}
                            onChange={event => handleChange(event, val)}
                            value={READ_PERMISSION}
                            name="radio-buttons"
                            size="small"
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {OFFICE_ALLOWED_PERMISSIONS[val].includes(UPDATE_PERMISSION) && (
                          <Radio
                            checked={roleState[OFFICE_MAPPING[val]] === UPDATE_PERMISSION}
                            onChange={event => handleChange(event, val)}
                            value={UPDATE_PERMISSION}
                            name="radio-buttons"
                            size="small"
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {OFFICE_ALLOWED_PERMISSIONS[val].includes(FULL_PERMISSION) && (
                          <Radio
                            checked={roleState[OFFICE_MAPPING[val]] === FULL_PERMISSION}
                            onChange={event => handleChange(event, val)}
                            value={FULL_PERMISSION}
                            name="radio-buttons"
                            size="small"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </>
          )
        })
      )}
    </TBody>
  )
}

OfficeTableBody.propTypes = {
  roleState: PropTypes.object,
  setRoleState: PropTypes.func,
}

export default OfficeTableBody
