import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import noop from 'lodash/noop'
import TagFilterMenu from './TagFilterMenu'

const TagFilterMenuField = ({ control, name, onChange = noop, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onChangeHookForm, value } }) => {
        const handleChange = value => {
          onChange(value)
          onChangeHookForm(value)
        }
        return <TagFilterMenu onChange={handleChange} value={value} {...rest} />
      }}
    />
  )
}

TagFilterMenuField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default TagFilterMenuField
