import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

const AgingInfoItem = ({ label = '', value = '', ...rest }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-end" {...rest}>
    <Box whiteSpace="pre" color="text.secondary">
      {label}
    </Box>
    <div className="text-base font-medium">{value}</div>
  </Box>
)

AgingInfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
}

export default AgingInfoItem
