import React, { Children, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { DialogContent, Box, Checkbox, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetRentalFeesByConfiguredServiceQuery } from 'api/configured-service/configuredServiceRentalFeesCrud'
import { useResetGracePeriodMutation } from 'api/pricing/resetGracePeriod'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import RentalFeeListItem from '../../common/field/RentalFeeListItem'
import RentalFeeListItemSkeleton from '../../common/field/RentalFeeListItemSkeleton'

const ConfiguredServiceResetGracePeriodDialog = ({ isOpen = false, configuredServiceId, onClose }) => {
  const theme = useTheme()
  const [selectedRentalFee, setSelectedRentalFee] = useState([])
  const [getRentalFeesByConfiguredService, { isFetching: isGetRentalFeesByConfiguredServiceLoading, data: configuredRentalFeesData }] =
    useLazyGetRentalFeesByConfiguredServiceQuery()
  const [resetGracePeriod, { isLoading: isResetGracePeriodLoading }] = useResetGracePeriodMutation()
  const [resetGracePeriodAll, { isLoading: isResetGracePeriodAllLoading }] = useResetGracePeriodMutation()

  const configuredRentalFees = useMemo(() => get(configuredRentalFeesData, 'configuredRentalFees', []), [configuredRentalFeesData])
  const hasSingleRentalFee = useMemo(() => configuredRentalFees.length === 1, [configuredRentalFees])
  const hasMultipleRentalFees = useMemo(() => configuredRentalFees.length > 1, [configuredRentalFees])

  const handleResetState = () => {
    setSelectedRentalFee([])
  }

  const handleClose = () => {
    handleResetState()
    onClose()
  }

  const handleCheckboxChange = (event, rentalFeeToConfiguredServiceId) => {
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedRentalFee(prev => [...prev, rentalFeeToConfiguredServiceId])
      return
    }
    setSelectedRentalFee(prev => prev.filter(item => item !== rentalFeeToConfiguredServiceId))
  }

  const handleSuccess = () => {
    toast.success(T.RESET_GRACE_PERIOD_SUCCESS_MSG)
    handleClose()
  }

  const handleResetAll = () => {
    resetGracePeriodAll({ rentalFeeToConfiguredServiceIds: configuredRentalFees.map(({ id }) => id) })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleReset = () => {
    resetGracePeriod({ rentalFeeToConfiguredServiceIds: selectedRentalFee }).unwrap().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleResetState()
      getRentalFeesByConfiguredService({ id: configuredServiceId })
        .unwrap()
        .then(response => {
          const rentalFees = get(response, 'configuredRentalFees', '')
          if (rentalFees.length === 1) {
            setSelectedRentalFee([get(rentalFees, '0.id', '')])
          }
        })
        .catch(handleError)
    }
  }, [isOpen])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
      <HHDialogTitle title={T.RESET_GRACE_PERIOD} onClose={handleClose} />
      <DialogContent>
        {isGetRentalFeesByConfiguredServiceLoading && <RentalFeeListItemSkeleton rows={1} />}

        {!isGetRentalFeesByConfiguredServiceLoading && !hasSingleRentalFee && !hasMultipleRentalFees && (
          <HHAlert severity="info" borderColor={theme.palette.info.light}>
            {T.NO_RENTAL_FEES}
          </HHAlert>
        )}

        {!isGetRentalFeesByConfiguredServiceLoading &&
          hasMultipleRentalFees &&
          Children.toArray(
            configuredRentalFees.map(rentalFee => {
              const { id } = rentalFee
              const isChecked = selectedRentalFee.includes(id)

              return (
                <Box display="flex" alignItems="center">
                  <Checkbox checked={isChecked} onChange={event => handleCheckboxChange(event, id)} />
                  <RentalFeeListItem rentalFee={rentalFee} />
                </Box>
              )
            })
          )}
        {!isGetRentalFeesByConfiguredServiceLoading && hasSingleRentalFee && (
          <RentalFeeListItem rentalFee={get(configuredRentalFees, '0', {})} />
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={handleClose} />
        <Box flexGrow={1} />
        {!isGetRentalFeesByConfiguredServiceLoading && hasMultipleRentalFees && (
          <SaveButton
            loadingPosition="center"
            loading={isResetGracePeriodAllLoading}
            disabled={isResetGracePeriodAllLoading}
            label={T.RESET_ALL}
            variant="outlined"
            onClick={handleResetAll}
          />
        )}
        <SaveButton
          loadingPosition="center"
          loading={isResetGracePeriodLoading}
          disabled={selectedRentalFee.length === 0 || isResetGracePeriodLoading}
          label={T.RESET}
          onClick={handleReset}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

ConfiguredServiceResetGracePeriodDialog.propTypes = {
  isOpen: PropTypes.bool,
  configuredServiceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default ConfiguredServiceResetGracePeriodDialog
