import { useCallback } from 'react'
import {
  addMarkerLayers,
  DYNAMIC_COLOR_DOT_LAYER,
  DYNAMIC_COLOR_STOPS_LAYER,
  DYNAMIC_COLOR_STOPS_SOURCE,
  groupFeaturesByIcon,
  removeLayersAndSources,
  STATIC_COLOR_DOT_LAYER,
  STATIC_COLOR_STOPS_LAYER,
  STATIC_COLOR_STOPS_SOURCE,
} from 'components/route-manager/BulkActions/settings'
import { addSourceAsync, BOTTOM_LAYER } from 'utils/map'
import { addRouteIdSuffix } from 'components/route-manager/RouteManagerMap/settings'
import { ROUTE_STOPS_LINE_STRING } from 'slices/route/routeSlice'
import { get } from 'lodash'
import { common } from '@mui/material/colors'
import { isValidHex } from 'utils/validations'

const useRecreateRouteSourcesAndLayers = (map, routesById) => {
  return useCallback(
    (routeId, { sequenceData, features, hasLineString = true, hasDotLayer = true }, stopsBeforeIdLayer) => {
      const mapCurrentRef = map.current
      removeLayersAndSources(mapCurrentRef, routeId)
      if (hasLineString) {
        addSourceAsync(mapCurrentRef, addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId), sequenceData, {
          type: 'geojson',
        }).then(() => {
          const color = get(routesById, [routeId, 'color'], common.black)
          const formattedColor = isValidHex(color) ? color : common.black
          mapCurrentRef.addLayer(
            {
              id: addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId),
              type: 'line',
              source: addRouteIdSuffix(ROUTE_STOPS_LINE_STRING, routeId),
              paint: {
                'line-color': formattedColor,
                'line-width': 2,
              },
            },
            BOTTOM_LAYER
          )
        })
      }
      const [monochromeFeatures, otherFeatures] = groupFeaturesByIcon(features)

      addSourceAsync(
        mapCurrentRef,
        addRouteIdSuffix(DYNAMIC_COLOR_STOPS_SOURCE, routeId),
        { type: 'FeatureCollection', features: monochromeFeatures },
        {
          type: 'geojson',
        }
      ).then(() => {
        addMarkerLayers(mapCurrentRef, {
          routeId,
          beforeIdLayer: stopsBeforeIdLayer,
          dotLayerPrefix: DYNAMIC_COLOR_DOT_LAYER,
          markerLayerPrefix: DYNAMIC_COLOR_STOPS_LAYER,
          sourcePrefix: DYNAMIC_COLOR_STOPS_SOURCE,
          hasDotLayer,
        })
      })
      addSourceAsync(
        mapCurrentRef,
        addRouteIdSuffix(STATIC_COLOR_STOPS_SOURCE, routeId),
        { type: 'FeatureCollection', features: otherFeatures },
        {
          type: 'geojson',
        }
      ).then(() => {
        addMarkerLayers(mapCurrentRef, {
          routeId,
          beforeIdLayer: stopsBeforeIdLayer,
          dotLayerPrefix: STATIC_COLOR_DOT_LAYER,
          markerLayerPrefix: STATIC_COLOR_STOPS_LAYER,
          sourcePrefix: STATIC_COLOR_STOPS_SOURCE,
          hasDotLayer,
        })
      })
    },
    [map, routesById]
  )
}
export default useRecreateRouteSourcesAndLayers
