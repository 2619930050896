import React from 'react'
import PropTypes from 'prop-types'
import CommonMoveToColumnBetweenDays from 'components/route-manager/BulkActions/common/BetweenDays/CommonMoveToColumnBetweenDays'
import { useSelector } from 'react-redux'
import {
  selectBulkMoveFetchId,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveRouteId,
  selectBulkMoveStopsToBeAssignedCount,
  selectBulkMoveToRows,
  selectBulkMoveToSerializedDate,
  selectBulkMoveValidStopIdsToBeAssigned,
  selectBulkSelectedMoveFromRows,
  selectBulkSelectedMoveToRows,
  setBulkMoveBetweenDaysState,
  setSelectedMoveToRows,
} from 'slices/route/bulkMoveBetweenDaysSlice'
import RenderMoveToHeader from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/RenderMoveToHeader'
import RenderToStopListItem from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/RenderToStopListItem'

const MoveToRouteColumn = ({ routes, apiRef, fetchAndFormatGeojsonData }) => {
  const moveToRows = useSelector(selectBulkMoveToRows)
  const validStopIdsToBeAssigned = useSelector(selectBulkMoveValidStopIdsToBeAssigned)
  const selectedMoveToRows = useSelector(selectBulkSelectedMoveToRows)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const routeId = useSelector(selectBulkMoveRouteId)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const fetchId = useSelector(selectBulkMoveFetchId)

  return (
    <CommonMoveToColumnBetweenDays
      renderHeader={p => <RenderMoveToHeader routes={routes} {...p} />}
      apiRef={apiRef}
      bulkReduxAction={setBulkMoveBetweenDaysState}
      fetchAndFormatGeojsonData={fetchAndFormatGeojsonData}
      fetchId={fetchId}
      fromSerializedDate={fromSerializedDate}
      moveToRows={moveToRows}
      routeId={routeId}
      selectedMoveFromRows={selectedMoveFromRows}
      selectedMoveToRows={selectedMoveToRows}
      setSelectedMoveToRows={setSelectedMoveToRows}
      stopsToBeAssignedCount={stopsToBeAssignedCount}
      toSerializedDate={toSerializedDate}
      validStopIdsToBeAssigned={validStopIdsToBeAssigned}
      renderCell={RenderToStopListItem}
    />
  )
}

MoveToRouteColumn.propTypes = {
  routes: PropTypes.array.isRequired,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  fetchAndFormatGeojsonData: PropTypes.func.isRequired,
}

export default MoveToRouteColumn
