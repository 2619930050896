import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Typography, Button } from '@mui/material'
import Add from '@mui/icons-material/Add'
import { toast } from 'react-toastify'

import { getSingularIntervalName } from 'components/pricing/pricingMethods'

import T from 'T'

import { get } from 'utils/lodash'
import { canAddSettings } from 'data/permissions/permissionsSelectors'

import ServiceTool from '../ServiceTool'

const Service = ({ service = {}, periodCombinations = [], recurrenceCombinations = [], setServiceState = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const clonedService = cloneDeep(service)
  const pricinPeriod = get(clonedService, 'period', [])
  const recurrence = get(clonedService, 'recurrence', [])

  useEffect(() => {
    setServiceState({ periodCombinations: pricinPeriod, recurrenceCombinations: recurrence })
  }, [service])

  const addPeriodRows = () => {
    periodCombinations.push({
      id: '',
      periodInterval: '',
      periodPer: '1',
    })
    setServiceState({ periodCombinations, showFooter: true })
  }

  const addRecurrenceRows = () => {
    recurrenceCombinations.push({
      id: '',
      recurrenceInterval: '',
      recurrencePer: '1',
    })
    setServiceState({ recurrenceCombinations, showFooter: true })
  }

  const handleFooterVal = () => {
    const combinations = merge(
      periodCombinations.map(data => data.id),
      recurrenceCombinations.map(data => data.id)
    )
    if (!combinations.includes('')) {
      setServiceState({ showFooter: false })
    }
  }

  const handleRemoval = (index, type) => {
    if (type === 'pricingPeriod') {
      periodCombinations.splice(index, 1)
      setServiceState({ periodCombinations: periodCombinations })
    } else {
      recurrenceCombinations.splice(index, 1)
      setServiceState({ recurrenceCombinations: recurrenceCombinations })
    }

    handleFooterVal()
  }

  const onHandleChange = (value, index, type, category) => {
    if (type === 'pricingPeriod') {
      handlePricingPeriodChange(value, index, category)
    } else {
      handleRecurrenceChange(value, index, category)
    }
  }

  const checkDuplicates = (record, value, category, type) => {
    let attrChanged, otherAttr, combinations

    if (category === 'interval') {
      value = getSingularIntervalName(value)
    }

    if (type === 'period') {
      attrChanged = category === 'interval' ? 'periodInterval' : 'periodPer'
      otherAttr = attrChanged === 'periodInterval' ? 'periodPer' : 'periodInterval'
      combinations = periodCombinations
    } else {
      attrChanged = category === 'interval' ? 'recurrenceInterval' : 'recurrencePer'
      otherAttr = attrChanged === 'recurrenceInterval' ? 'recurrencePer' : 'recurrenceInterval'
      combinations = recurrenceCombinations
    }

    let checkRecord = combinations.filter(data => data[attrChanged] === value && data[otherAttr] === record[otherAttr])

    if (checkRecord.length > 0) {
      return 'duplicate'
    }
  }

  const handlePricingPeriodChange = (value, index, category) => {
    let record = periodCombinations[index]

    let checkVal = checkDuplicates(record, value, category, 'period')

    if (checkVal === 'duplicate') {
      toast.info('Duplicate Record')
      return
    }

    if (category === 'interval') {
      periodCombinations[index]['periodInterval'] = getSingularIntervalName(value)
    } else {
      periodCombinations[index]['periodPer'] = getSingularIntervalName(value)
    }
    setServiceState({ periodCombinations, showFooter: true })
  }

  const handleRecurrenceChange = (value, index, category) => {
    let record = recurrenceCombinations[index]

    let checkVal = checkDuplicates(record, value, category, 'recurrence')

    if (checkVal === 'duplicate') {
      toast.info('Duplicate Record')
      return
    }

    if (category === 'interval') {
      recurrenceCombinations[index]['recurrenceInterval'] = getSingularIntervalName(value)
    } else {
      recurrenceCombinations[index]['recurrencePer'] = getSingularIntervalName(value)
    }
    setServiceState({ recurrenceCombinations, showFooter: true })
  }

  return (
    <Box display="flex">
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          p="10px 0"
          sx={{ borderBottom: theme => `1px solid ${theme.palette.background.tags}` }}
        >
          <Typography variant="subtitle2">{T.PRICING_PERIOD}</Typography>
          <Typography variant="subtitle2">{T.FEES_PER_PERIOD}</Typography>
        </Box>

        {Children.toArray(
          periodCombinations.map((data, index) => {
            return (
              <ServiceTool
                id={data.id}
                index={index}
                type="pricingPeriod"
                selectedNum={data.periodPer}
                selectedVal={data.periodInterval}
                handleRemoval={handleRemoval}
                onHandleChange={onHandleChange}
              />
            )
          })
        )}

        {addSettings && (
          <Button onClick={addPeriodRows} variant="text" color="primary" startIcon={<Add />} sx={{ mt: 1.5 }}>
            {T.ADD_PRICING_PERIOD}
          </Button>
        )}
      </Box>

      <Box ml={10}>
        <Box
          display="flex"
          justifyContent="space-between"
          p="10px 0"
          sx={{ borderBottom: theme => `1px solid ${theme.palette.background.tags}` }}
        >
          <Typography variant="subtitle2">{T.SERVICE_INTERVAL}</Typography>
          <Typography variant="subtitle2">{T.SERVICES_PER_PERIOD}</Typography>
        </Box>

        {Children.toArray(
          recurrenceCombinations.map((data, index) => {
            return (
              <ServiceTool
                id={data.id}
                index={index}
                type="recurrence"
                selectedNum={data.recurrencePer}
                selectedVal={data.recurrenceInterval}
                handleRemoval={handleRemoval}
                onHandleChange={onHandleChange}
              />
            )
          })
        )}

        {addSettings && (
          <Button onClick={addRecurrenceRows} variant="text" color="primary" startIcon={<Add />} sx={{ mt: 1.5 }}>
            {T.ADD_RECURRENCE}
          </Button>
        )}
      </Box>
    </Box>
  )
}

Service.propTypes = {
  service: PropTypes.object,
  periodCombinations: PropTypes.array,
  recurrenceCombinations: PropTypes.array,
  setServiceState: PropTypes.func,
}

export default Service
