import { BUSINESS_LINE_TYPE_UPPERCASE } from 'settings/constants/businessLine'
import T from 'T'

export const RENTAL_FEES_BUSINESS_LINES_TABS = {
  ALL_BUSINESS_LINES: 'ALL',
  ...BUSINESS_LINE_TYPE_UPPERCASE,
}

export const NONE_BUSINESS_LINE_OPTION = {
  label: T.NONE,
  value: '',
}

export const RENTAL_FEE_GRACE_PERIOD = {
  NONE: 0,
  ONE_DAY: 1,
  TWO_DAYS: 2,
  THREE_DAYS: 3,
  FOUR_DAYS: 4,
  FIVE_DAYS: 5,
  CUSTOM: 'CUSTOM',
}

const { NONE, ONE_DAY, TWO_DAYS, THREE_DAYS, FOUR_DAYS, FIVE_DAYS, CUSTOM } = RENTAL_FEE_GRACE_PERIOD

export const RENTAL_FEE_GRACE_PERIOD_OPTIONS = [
  { value: NONE, label: 'None' },
  { value: ONE_DAY, label: '1 day' },
  { value: TWO_DAYS, label: '2 days' },
  { value: THREE_DAYS, label: '3 days' },
  { value: FOUR_DAYS, label: '4 days' },
  { value: FIVE_DAYS, label: '5 days' },
  { value: CUSTOM, label: 'Custom' },
]

export const RENTAL_FEE_PRICING_PERIOD = {
  ONCE: 'ONCE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  ANNUALLY: 'ANNUALLY',
}

const { ONCE, DAILY, WEEKLY, MONTHLY, QUARTERLY, ANNUALLY } = RENTAL_FEE_PRICING_PERIOD

export const RENTAL_FEE_PRICING_PERIOD_OPTIONS = [
  { value: ONCE, label: T.ONCE },
  { value: DAILY, label: T.DAILY },
  { value: WEEKLY, label: T.WEEKLY },
  { value: MONTHLY, label: T.MONTHLY },
  { value: QUARTERLY, label: T.QUARTERLY },
  { value: ANNUALLY, label: T.ANNUALLY },
]
