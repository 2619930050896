import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import {
  useCreateLocationNoteMutation,
  useUpdateLocationNoteMutation,
  usePatchLocationNoteMutation,
  useDeleteLocationNoteMutation,
  useLazyGetLocationNoteHistoryQuery,
} from 'api/notes/locationNotesCrud'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import NoteListItem from 'components/notes/common/NoteListItem'
import NoteActionMenu from 'components/notes/common/NoteActionMenu'
import ViewNoteDialog from 'components/notes/common/ViewNoteDialog'

const LocationNotes = ({
  notes = [],
  locationId = '',
  showFullVersion = true,
  showSecondaryActions = true,
  noNotesText = T.NO_NOTES_V2,
  itemSx,
}) => {
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewNoteDialog, setIsOpenViewNoteDialog] = useState(null)
  const [isOpenNoteActionMenu, setIsOpenNoteActionMenu] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const hasNotes = notes.length > 0

  const handleOpenUpdateLocationNote = () => setIsOpenAddUpdateNoteDialog(true)

  const handleOpenViewLocationNoteDialog = note => {
    setSelectedNote(note)
    setIsOpenViewNoteDialog(true)
  }

  const handleCloseViewLocationNoteDialog = () => {
    setSelectedNote(null)
    setIsOpenViewNoteDialog(null)
  }

  const handleOpenLocationNoteActionMenu = (event, note) => {
    event.stopPropagation()
    setSelectedNote(note)
    setIsOpenNoteActionMenu(event.currentTarget)
  }

  const handleCloseLocationNoteActionMenu = () => setIsOpenNoteActionMenu(null)

  const handleCloseAddUpdateLocationNote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  return (
    <>
      {!hasNotes && <HHSectionPlaceholder title={noNotesText} />}
      {hasNotes && (
        <List sx={{ py: 0 }}>
          {Children.toArray(
            notes.map((note, index) => (
              <NoteListItem
                sx={itemSx}
                note={note}
                showDivider={notes.length - 1 !== index}
                showFullVersion={showFullVersion}
                onViewClick={() => handleOpenViewLocationNoteDialog(note)}
                onActionMenuClick={event => handleOpenLocationNoteActionMenu(event, note)}
                showSecondaryActions={showSecondaryActions}
              />
            ))
          )}
        </List>
      )}
      <AddUpdateNoteDialog
        id={locationId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.LOCATION_NOTE : T.NEW_LOCATION_NOTE}
        existingNote={selectedNote}
        useCreateNoteMutation={useCreateLocationNoteMutation}
        useUpdateNoteMutation={useUpdateLocationNoteMutation}
        onClose={handleCloseAddUpdateLocationNote}
      />
      <ViewNoteDialog
        id={locationId}
        isOpen={isOpenViewNoteDialog}
        title={T.LOCATION_NOTE}
        selectedNote={selectedNote}
        useLazyGetNoteHistoryQuery={useLazyGetLocationNoteHistoryQuery}
        onClose={handleCloseViewLocationNoteDialog}
      />
      <NoteActionMenu
        id={locationId}
        anchorEl={isOpenNoteActionMenu}
        selectedNote={selectedNote}
        onEditClick={handleOpenUpdateLocationNote}
        usePatchNoteMutation={usePatchLocationNoteMutation}
        useDeleteNoteMutation={useDeleteLocationNoteMutation}
        onClose={handleCloseLocationNoteActionMenu}
      />
    </>
  )
}

LocationNotes.propTypes = {
  noNotesText: PropTypes.string,
  notes: PropTypes.array,
  showFullVersion: PropTypes.bool,
  locationId: PropTypes.string,
  showSecondaryActions: PropTypes.bool,
  itemSx: PropTypes.object,
}

export default LocationNotes
