import React, { useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import ReorderIcon from 'components/common/ReorderIcon'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { setShowBillingSideNav } from 'slices/billing/BillingSideNavSlice'

import T from 'T'
import AddEditInvoiceGroupDialog from './AddEditInvoiceGroupDialog'

const InvoiceGroupHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)
  const [isOpenAddEditInvoiceGroupDialog, setIsOpenAddEditInvoiceGroupDialog] = useState(false)

  const handleDrawerToggle = () => dispatch(setShowBillingSideNav(!showBillingSideNav))

  return (
    <>
      <Grid
        container
        pl={showBillingSideNav ? 3 : 1.5}
        pr={3}
        minHeight={56}
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        bgcolor={theme.background.default}
      >
        <Grid item xs display="flex" alignItems="center">
          {!showBillingSideNav && <ReorderIcon onClick={handleDrawerToggle} />}
          <Typography variant="h4" fontWeight={600}>
            {T.INVOICE_GROUPS}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton onClick={() => setIsOpenAddEditInvoiceGroupDialog(true)}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <AddEditInvoiceGroupDialog isOpen={isOpenAddEditInvoiceGroupDialog} onClose={() => setIsOpenAddEditInvoiceGroupDialog(false)} />
    </>
  )
}

export default InvoiceGroupHeader
