import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, ListItem, ListItemText, Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { get } from 'lodash'

const LateFeeStatItem = ({
  primary,
  secondary,
  divider,
  primaryTypographyProps,
  secondaryTypographyProps,
  ListItemProps,
  ListItemTextProps,
  DividerProps,
  loading = false,
  ...rest
}) => {
  const theme = useTheme()
  const isSmOrLower = useMediaQuery(theme.breakpoints.down('sm'))
  const sx = get(rest, 'sx')
  const ListItemSx = get(ListItemProps, 'sx')
  return (
    <>
      <Grid item sx={sx} {...rest}>
        <ListItem
          sx={{
            py: 0,
            ...ListItemSx,
          }}
          dense
          {...ListItemProps}
          divider={divider && isSmOrLower}
        >
          <ListItemText
            primary={loading ? <Skeleton width="5rem" /> : primary}
            primaryTypographyProps={{
              variant: 'h5',
              color: 'textPrimary',
              noWrap: true,
              ...primaryTypographyProps,
            }}
            secondary={loading ? <Skeleton width="4rem" /> : secondary}
            secondaryTypographyProps={{
              variant: 'body1',
              color: 'textSecondary',
              noWrap: true,
              ...secondaryTypographyProps,
            }}
            {...ListItemTextProps}
          />
        </ListItem>
      </Grid>
      {divider && !isSmOrLower && <Divider variant="middle" orientation="vertical" flexItem {...DividerProps} />}
    </>
  )
}

LateFeeStatItem.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  divider: PropTypes.bool,
  primaryTypographyProps: PropTypes.object,
  secondaryTypographyProps: PropTypes.object,
  ListItemProps: PropTypes.object,
  ListItemTextProps: PropTypes.object,
  DividerProps: PropTypes.object,
  loading: PropTypes.bool,
}

export default memo(LateFeeStatItem)
