import React from 'react'
import { useTheme } from '@mui/material/styles'
import { MenuItem, ListItemIcon, ListItemText, Checkbox, ListItem, ListItemAvatar, Avatar, Typography, SvgIcon } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash'
import { grey } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { isValidHex } from 'utils/validations'
import { extractNameInitials } from 'utils/string'
import { ReactComponent as CalendarPlus } from 'assets/route_manager/actions_menu/CalendarPlus.svg'
import { UNASSIGNED_ROUTE } from 'containers/new-route-manager/settings'

const RouteFilterMenuOptions = ({ allRoutes = [], selectedRouteIds = [], onSelectRouteChange, onSelectAllFilters }) => {
  const theme = useTheme()
  const viewOnMapRouteId = useSelector(s => get(s, 'Route.viewOnMap.routeId', null), shallowEqual)
  const selectedRouteIdList = viewOnMapRouteId ? [viewOnMapRouteId] : selectedRouteIds
  const selectedRouteIdListCount = selectedRouteIdList.length
  const allRoutesSelected = selectedRouteIdListCount === allRoutes.length && allRoutes.length > 0

  return (
    <>
      <MenuItem sx={{ padding: 0 }} divider onClick={onSelectAllFilters}>
        <ListItemIcon>
          <Checkbox checked={allRoutesSelected} />
        </ListItemIcon>
        <ListItemText primary={allRoutesSelected ? 'Deselect all' : 'Select all'} />
      </MenuItem>
      {allRoutes.map(route => {
        const name = get(route, 'name', '')
        const routeId = get(route, 'id', '')
        const initials = extractNameInitials(name)
        const bgcolor = get(route, 'color', grey[100])
        const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
        const color = theme.palette.getContrastText(formattedBgcolor)
        const stopCount = get(route, 'stopCount', 0)
        const isRouteSelected = selectedRouteIdList.includes(routeId)

        return (
          <MenuItem
            sx={{ py: 0, pl: 0, pr: 1.5, maxWidth: '30rem' }}
            onClick={event => onSelectRouteChange(event, routeId)}
            value={routeId}
          >
            <ListItemIcon>
              <Checkbox checked={isRouteSelected} />
            </ListItemIcon>

            <ListItemText>
              <ListItem disableGutters disablePadding>
                <ListItemAvatar sx={{ minWidth: '40px' }}>
                  <Avatar
                    sx={{
                      width: '32px',
                      height: '32px',
                      bgcolor: formattedBgcolor,
                      color,
                    }}
                  >
                    {UNASSIGNED_ROUTE.id === routeId ? (
                      <CalendarPlus width={20} height={20} />
                    ) : (
                      <Typography variant="h5" fontWeight={400}>
                        {initials}
                      </Typography>
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={name}
                  secondary={`${stopCount} stop${stopCount > 1 ? 's' : ''}`}
                  primaryTypographyProps={{
                    noWrap: true,
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            </ListItemText>
          </MenuItem>
        )
      })}
    </>
  )
}

RouteFilterMenuOptions.propTypes = {
  allRoutes: PropTypes.array,
  selectedRouteIds: PropTypes.array,
  onSelectRouteChange: PropTypes.func,
  onSelectAllFilters: PropTypes.func,
}

export default RouteFilterMenuOptions
