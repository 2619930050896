import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Button, Typography, Divider, List, DialogContent, DialogContentText, Box, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAccountContactsQuery } from 'api/accounts/getAccountContacts'
import { useEmailPaymentReceiptsMutation } from 'api/billing/emailPaymentReceipts'
import { HHAlert } from 'components/common/HHAlert'
import { CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST } from 'api/cacheTagTypes'
import { putIsLoading } from 'middleware/actions/response'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import Loader from 'components/common/loader'
import ExpandView from './ExpandView'
import { combinePayments } from './helper'

const PaymentReceiptEmailConfirmation = ({ isOpen = false, payments = [], isSingleAccount = false, onClose }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [getAccountContacts, { data, isFetching: isGetAccountContactsLoading }] = useLazyGetAccountContactsQuery()
  const [emailPaymentReceipts] = useEmailPaymentReceiptsMutation()

  const contactsByAccount = get(data, 'contactsByAccount', {})
  const { groupPayments = [], paymentsWithoutEmail = 0 } = combinePayments(payments, contactsByAccount)
  const hasEmails = groupPayments.find(payment => get(payment, 'emails', []).length)

  const handleSend = () => {
    dispatch(putIsLoading(true))
    const accountTransactionHistoryIds = payments.map(({ accountTransactionHistoryId, id }) => accountTransactionHistoryId || id)
    emailPaymentReceipts({ accountTransactionHistoryIds })
      .unwrap()
      .then(() => {
        toast.success(groupPayments.length === 1 ? T.SEND_PAYMENT_RECEIPT_SUCCESS : T.SEND_PAYMENT_RECEIPTS_SUCCESS)
        setTimeout(() => dispatch(api.util.invalidateTags([CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST])), 4000)
      })
      .catch(handleError)
      .finally(() => {
        onClose()
        dispatch(putIsLoading(false))
      })
  }

  useEffect(() => {
    if (isOpen) {
      const accountIds = uniq(payments.map(({ accountId }) => accountId))
      getAccountContacts({ accountIds })
    }
  }, [isOpen])

  return (
    <>
      {isGetAccountContactsLoading && <Loader />}
      <HHBaseDialog open={isOpen} maxWidth={isSingleAccount ? 'xs' : 'sm'} fullWidth onClose={onClose}>
        <HHDialogTitle title={T.PAYMENT_RECEIPT_CONFIRMATION} onClose={onClose} />
        <DialogContent>
          <DialogContentText>
            <Typography mb={2} variant="h5" color="textPrimary" fontWeight={400}>
              {`Payment receipt${groupPayments.length > 1 ? 's' : ''} will be sent to the following email addresses:`}
            </Typography>

            {isSingleAccount && !hasEmails && (
              <HHAlert borderColor={theme.palette.error.light} severity="error">
                No billing email
              </HHAlert>
            )}

            {isSingleAccount &&
              hasEmails &&
              Children.toArray(
                get(groupPayments, '[0].emails', []).map(email => (
                  <Typography variant="h5" color="textPrimary" fontWeight={400}>
                    {email}
                  </Typography>
                ))
              )}

            {!isSingleAccount && (
              <List disablePadding>
                {Children.toArray(
                  groupPayments.map((payment, index) => (
                    <>
                      <ExpandView defaultOpen={index === 0} payment={payment} />
                      {groupPayments.length - 1 !== index && <Divider />}
                    </>
                  ))
                )}
              </List>
            )}
          </DialogContentText>
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <Box sx={{ flex: '1 1 auto' }} />
          {!isSingleAccount && paymentsWithoutEmail > 0 && (
            <Typography variant="h6" fontWeight={400} color="textSecondary" mr={2}>
              {`${paymentsWithoutEmail} receipt${paymentsWithoutEmail > 1 ? 's' : ''} will not be sent`}
            </Typography>
          )}
          <Button size="small" variant="contained" color="primary" disabled={!hasEmails} onClick={handleSend}>
            {T.SEND}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

PaymentReceiptEmailConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  payments: PropTypes.array,
  isSingleAccount: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default PaymentReceiptEmailConfirmation
