import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { ListItem, ListItemText, Link } from '@mui/material'
import { getCustomerDetailsPageUrl } from 'router/routes'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const SummaryRow = ({
  customerName = '',
  accountNumber = '',
  accountId = '',
  transactionAmountCents = 0,
  isIndividualPayment = false,
  batchId = '',
  checkValue = '',
}) => {
  return (
    <ListItem dense disablePadding sx={{ px: 1 }}>
      <ListItemText
        sx={{ width: '50%', borderRight: '1px solid', borderColor: 'divider' }}
        primaryTypographyProps={{ variant: 'caption', fontWeight: 700, color: 'textPrimary', sx: { wordWrap: 'break-word' } }}
        primary={customerName}
        secondary={
          accountId ? (
            <Link
              component={RouterLink}
              to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              {accountNumber}
            </Link>
          ) : (
            accountNumber
          )
        }
      />
      <ListItemText
        sx={{ width: '50%' }}
        primaryTypographyProps={{ variant: 'caption', fontWeight: 700, color: 'textPrimary', textAlign: 'right' }}
        secondaryTypographyProps={{ textAlign: 'right', sx: { wordWrap: 'break-word' } }}
        primary={<HHDisplayMoney value={transactionAmountCents} />}
        secondary={isIndividualPayment ? batchId : checkValue}
      />
    </ListItem>
  )
}

SummaryRow.propTypes = {
  customerName: PropTypes.string,
  accountNumber: PropTypes.string,
  accountId: PropTypes.string,
  transactionAmountCents: PropTypes.number,
  batchId: PropTypes.string,
  isIndividualPayment: PropTypes.bool,
  checkValue: PropTypes.string,
}

export default SummaryRow
