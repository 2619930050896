import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useFormContext } from 'react-hook-form'
import { FormControl, RadioGroup, FormControlLabel, Radio, Grid, Divider, Box, Button, Typography } from '@mui/material'

import { get } from 'utils/lodash'
import { PAYMENT_METHODS, DEFAULT_AUTOPAY_TIMING } from 'settings/constants/payment'
import {
  getPaymentMethodByStatus,
  getIsCreditCardOrACH,
  getIsCash,
  getIsCreditCard,
  getSavedMethodsByPaymentType,
} from 'utils/takePayments'
import { HHFormTextField, HHFormCheckboxField } from 'components/form-fields/v5'

import T from 'T'
import CommonSwitch from 'components/common/CommonSwitch'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'
import CreditCardAchListing from 'components/billing/groups/payment/take-payments/CreditCardAchListing'
import TransactionSurchargeAlert from 'components/billing/payments/TransactionSurchargeAlert'
import AutopayDisclaimer from 'components/common/payments/AutopayDisclaimer'
import RenderCreditCard from './RenderCreditCard'
import RenderACH from './RenderACH'
import AmountField from './AmountField'
import AddToGroupField from './AddToGroupField'

const { CASH, CHECK, CREDIT_CARD, ACH, OTHER, ACCOUNT_CREDIT } = PAYMENT_METHODS

const PaymentType = ({
  isLoading = false,
  hasSurcharge = false,
  savedPaymentMethods = [],
  params = {},
  initialCreditBalanceCents = 0,
  isSinglePayment = false,
  onChange,
  onAmountChange,
  onNextBtnClick,
  onClearClick,
}) => {
  const {
    control,
    watch,
    setValue,
    setFocus,
    formState: { errors },
    clearErrors,
  } = useFormContext()

  const { selectedOption, groupId } = params
  const watchPaymentMethod = watch('paymentMethod')
  const watchSelectedPaymentTokenDetails = watch('selectedPaymentTokenDetails')
  const watchIsNewCard = watch('isNewCard')
  const watchIsSaveNewMethod = watch('isSaveNewMethod')
  const watchAutoPayTime = watch('autoPayTime')

  const { checkNum: checkNumError } = errors
  const checkNumHelperText = get(checkNumError, 'message')

  const { isCheck, isOther, isCreditCard, isACH, isCheckCashOther, isCreditCardOrACH } = getPaymentMethodByStatus(watchPaymentMethod)

  const handleResetCard = (paymentMethod = false) => {
    setValue('isNewCard', paymentMethod)
    setValue('selectedPaymentTokenDetails', {})
    setValue('cardHolderName', '')
    setValue('cvv', '')
    setValue('isSaveNewMethod', false)
    setValue('cardNickName', '')
    setValue('autoPayTime', null)
  }

  const handleCardChange = (method, paymentMethod = watchPaymentMethod) => {
    const accountToken = get(method, 'accountToken', '')
    const cardHolderName = get(method, 'cardHolderName', '')
    const accountType = get(method, 'accountType')
    const expiry = get(method, 'expiry')
    const cardNickName = get(method, 'cardNickName', '')

    setValue('selectedPaymentTokenDetails', { accountToken, accountType: getIsCreditCard(paymentMethod) ? null : accountType, expiry })
    setValue('cardHolderName', cardHolderName)
    setValue('cvv', '')
    setValue('isNewCard', false)
    setValue('isSaveNewMethod', false)
    setValue('cardNickName', cardNickName)
    setValue('autoPayTime', null)
  }

  const handlePaymentMethodChange = newPaymentMethod => {
    if (newPaymentMethod === ACCOUNT_CREDIT) {
      setValue('amount', 0)
      onAmountChange(0)
    }

    setValue('paymentMethod', newPaymentMethod)
    setValue('checkNum', '')

    let focusKey = ''

    if (getIsCreditCardOrACH(newPaymentMethod)) {
      const savedMethods = getSavedMethodsByPaymentType(savedPaymentMethods, newPaymentMethod)
      if (savedMethods.length > 0) {
        handleCardChange(savedMethods[0], newPaymentMethod)
        focusKey = 'amount'
      } else {
        handleResetCard(newPaymentMethod)
        focusKey = getIsCreditCard(newPaymentMethod) ? 'cardHolderName' : ''
      }
    } else {
      handleResetCard()
    }

    if ([CHECK, OTHER].includes(newPaymentMethod)) {
      focusKey = 'checkNum'
    }

    if (getIsCash(newPaymentMethod)) {
      focusKey = 'amount'
    }

    if (focusKey) {
      setTimeout(() => setFocus(focusKey), 300)
    }

    clearErrors()
  }

  const handleEnterPress = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onNextBtnClick()
    }
  }

  const handleNewCardTokenChange = response => {
    setValue('selectedPaymentTokenDetails', { ...response })
  }

  return (
    <>
      <Typography variant="h5" fontWeight={500}>
        {capitalize(T.PAYMENT_TYPE)}
      </Typography>
      <Grid my={2} container columnSpacing={2}>
        <Grid item xs={6}>
          <HHFormDesktopDatePicker
            label={T.PAYMENT_DATE}
            disabled={!watchPaymentMethod}
            name="paymentDate"
            control={control}
            TextFieldProps={{ deprecatedLabel: false }}
            disableFuture
          />
        </Grid>
      </Grid>

      <FormControl component="fieldset" disabled={!selectedOption || isLoading}>
        <RadioGroup row value={watchPaymentMethod} onChange={e => handlePaymentMethodChange(e.target.value)}>
          <Box width="100%">
            <FormControlLabel value={CHECK} control={<Radio />} label={T.CHECK} />
            <FormControlLabel value={CASH} control={<Radio />} label={T.CASH} />
            <FormControlLabel value={OTHER} control={<Radio />} label={T.OTHER} />
          </Box>
          <Box width="100%">
            <FormControlLabel value={CREDIT_CARD} control={<Radio />} label={capitalize(T.CREDIT_CARD)} />
            <FormControlLabel value={ACH} control={<Radio />} label={T.ACH} />
            <FormControlLabel
              value={ACCOUNT_CREDIT}
              control={<Radio disabled={!initialCreditBalanceCents} />}
              label={capitalize(T.ACCOUNT_CREDIT)}
            />
          </Box>
        </RadioGroup>
      </FormControl>

      {isCheckCashOther && (
        <Grid mt={2} container columnSpacing={2}>
          {(isCheck || isOther) && (
            <Grid item xs>
              <HHFormTextField
                control={control}
                name="checkNum"
                fullWidth
                required={isCheck}
                rules={{ required: isCheck ? T.CANNOT_BE_EMPTY : false }}
                error={checkNumError}
                helperText={checkNumHelperText}
                deprecatedLabel={false}
                label={isCheck ? `${T.CHECK} #` : T.EXTERNAL_PAYMENT_NUMBER}
                placeholder={isCheck ? `${T.CHECK} #` : T.EXTERNAL_PAYMENT_NUMBER}
              />
            </Grid>
          )}
          <Grid item xs>
            <AmountField onChange={onAmountChange} onKeyDown={handleEnterPress} />
          </Grid>
        </Grid>
      )}
      {isCreditCardOrACH && (
        <Box mt={1}>
          <CreditCardAchListing
            type={watchPaymentMethod}
            selectedPaymentTokenDetails={watchSelectedPaymentTokenDetails}
            paymentMethods={savedPaymentMethods}
            onPaymentMethodSelect={handleCardChange}
          />
          <FormControl component="fieldset">
            <RadioGroup value={watchIsNewCard} onChange={event => handleResetCard(event.target.value)}>
              <FormControlLabel
                value={watchPaymentMethod}
                control={<Radio />}
                label={watchPaymentMethod === ACH ? T.NEW_ACH : T.NEW_CARD}
              />
            </RadioGroup>
          </FormControl>
          {!watchIsNewCard && isCreditCard && hasSurcharge && <TransactionSurchargeAlert />}
          {watchIsNewCard && document.getElementById('takePaymentGroup') && (
            <Box>
              {isCreditCard && (
                <>
                  <RenderCreditCard onNewCardTokenChange={handleNewCardTokenChange} />
                  {hasSurcharge && (
                    <Box mt={2}>
                      <TransactionSurchargeAlert />
                    </Box>
                  )}
                </>
              )}
              {isACH && <RenderACH onNewCardTokenChange={handleNewCardTokenChange} />}
              <FormControlLabel
                sx={{ mt: 2 }}
                control={<HHFormCheckboxField control={control} name="isSaveNewMethod" onChange={() => setValue('cardNickName', '')} />}
                label={T.SAVE_METHOD}
              />
              {watchIsSaveNewMethod && (
                <>
                  <HHFormTextField
                    sx={{ mt: 1 }}
                    control={control}
                    name="cardNickName"
                    fullWidth
                    deprecatedLabel={false}
                    label={T.METHOD_NICKNAME}
                    placeholder={T.METHOD_NICKNAME}
                  />
                  <Box mt={2} pb={1}>
                    <CommonSwitch
                      title={T.AUTOPAY}
                      isChecked={Boolean(watchAutoPayTime)}
                      onChange={checked => setValue('autoPayTime', checked ? DEFAULT_AUTOPAY_TIMING : null)}
                    />
                    {watchAutoPayTime && <AutopayDisclaimer />}
                  </Box>
                </>
              )}
            </Box>
          )}

          <Box mt={2}>
            <AmountField onChange={onAmountChange} onKeyDown={handleEnterPress} />
          </Box>
        </Box>
      )}
      {watchPaymentMethod && (
        <>
          <Divider sx={{ my: 2 }} />
          <HHFormTextField control={control} name="note" fullWidth deprecatedLabel={false} label={T.NOTE} placeholder="Leave a note" />
        </>
      )}

      {isSinglePayment && watchPaymentMethod && (
        <Box mt={2}>
          <Typography variant="h5" fontWeight={500}>
            Payment group
          </Typography>

          <Box mt={2}>
            <AddToGroupField groupId={groupId} onChange={event => onChange('groupId', event.target.value)} />
          </Box>
        </Box>
      )}

      {watchPaymentMethod && (
        <>
          <Box mt={3} display="flex" justifyContent="space-between">
            <Button fullWidth={!isSinglePayment} variant="outlined" color="warning" onClick={onClearClick}>
              Clear fields
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

PaymentType.propTypes = {
  isLoading: PropTypes.bool,
  savedPaymentMethods: PropTypes.array,
  params: PropTypes.object,
  hasSurcharge: PropTypes.bool,
  initialCreditBalanceCents: PropTypes.number,
  isSinglePayment: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  onNextBtnClick: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
}

export default PaymentType
