import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserAuthToken: builder.query({
      query: () => {
        return { url: 'api/v1/core/users/auth/token', method: 'POST' }
      },
    }),
  }),
})

export const { useLazyGetUserAuthTokenQuery } = extendedApi
