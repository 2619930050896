import React from 'react'
import { Card, Box, Typography, Table, TableHead, TableContainer, TableRow, TableCell, TableBody, Button } from '@mui/material'
import T from 'T'
import { useGetPermitListQuery } from 'api/settings/getPermitList'
import AddIcon from '@mui/icons-material/Add'
import TableSkelton from 'components/common/TableSkelton'
import NewPermitTableRow from './NewPermitTableRow'
import PermitTableRow from './PermitTableRow'
import EditPermitTableRow from './EditPermitTableRow'

const columnsHeaders = [`${T.PERMIT} ${T.NAME}`, T.TYPE, T.STATUS]

const PermitsSettings = () => {
  const { data, isSuccess, refetch, isLoading, isFetching } = useGetPermitListQuery()
  const [newPermits, setNewPermits] = React.useState([])
  const [editIndex, setEditIndex] = React.useState(undefined)
  return (
    <Box>
      <Typography px={4} variant="h3">{`${T.PERMIT}s`}</Typography>
      <Card sx={{ mt: 2 }}>
        <TableContainer sx={{ height: 'calc(100vh - 135px)', p: '0px 15px' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columnsHeaders.map(title => (
                  <TableCell sx={{ p: '25px 16px 10px 16px', backgroundColor: 'background.paper' }}>
                    <Typography variant="subtitle2">{title}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isFetching || isLoading) && <TableSkelton rows={10} columns={3} />}
              {isSuccess &&
                data.permitList.map((row, index) => {
                  if (index === editIndex) {
                    return (
                      <EditPermitTableRow
                        onCancel={() => {
                          setEditIndex(undefined)
                        }}
                        onEditSuccess={() => {
                          refetch()
                          setEditIndex(undefined)
                        }}
                        id={row.id}
                        permitName={row.permitName}
                        permitType={row.permitType}
                        active={row.active}
                      />
                    )
                  }

                  return (
                    <PermitTableRow
                      onClick={() => {
                        setEditIndex(index)
                      }}
                      onToggleSuccess={() => {
                        refetch()
                      }}
                      data={row}
                    />
                  )
                })}

              {newPermits.length > 0 &&
                newPermits.map((e, index) => {
                  return (
                    <NewPermitTableRow
                      onCancel={() => {
                        setNewPermits(newPermits.filter((e, i) => i !== index))
                      }}
                      onCreationSuccess={() => {
                        refetch()
                        setNewPermits(newPermits.filter((e, i) => i !== index))
                      }}
                    />
                  )
                })}

              <TableRow>
                <TableCell sx={{ p: '10px 16px 10px 8px' }}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setNewPermits([...newPermits, true])
                    }}
                  >
                    {`${T.ADD_PERMIT}`}
                  </Button>
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  )
}

export default PermitsSettings
