import React from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

const CommonNumberFormat = ({
  id = 'outlined-size-small',
  value = null,
  displayType = null,
  decimalScale = 2,
  thousandSeparator = true,
  fixedDecimalScale = true,
  className = null,
  onChange = () => {},
  name = null,
  decimalSeparator = '.',
  customInput = 'TextField',
  InputProps = null,
  placeholder = null,
  variant = null,
  size = null,
  autoComplete = 'off',
  format = null,
  prefix = '',
  suffix = '',
  type = null,
  removeFormatting = null,
  allowNegative = null,
  allowEmptyFormatting = null,
  allowLeadingZeros = null,
  valueIsNumericString = null,
  allowedDecimalSeparators = null,
  onValChange = () => {},
}) => (
  <NumericFormat
    id={id}
    value={value}
    displayType={displayType}
    thousandSeparator={thousandSeparator}
    decimalSeparator={decimalSeparator}
    decimalScale={decimalScale}
    fixedDecimalScale={fixedDecimalScale}
    prefix={prefix}
    className={className}
    name={name}
    onChange={onChange}
    customInput={customInput}
    InputProps={InputProps}
    placeholder={placeholder}
    variant={variant}
    size={size}
    autoComplete={autoComplete}
    format={format}
    suffix={suffix}
    allowNegative={allowNegative}
    allowEmptyFormatting={allowEmptyFormatting}
    allowLeadingZeros={allowLeadingZeros}
    valueIsNumericString={valueIsNumericString}
    type={type}
    removeFormatting={removeFormatting}
    allowedDecimalSeparators={allowedDecimalSeparators}
    onValueChange={values => {
      const { value: originalValue } = values
      onValChange(originalValue)
    }}
  />
)

CommonNumberFormat.propTypes = {
  displayType: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  thousandSeparator: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  customInput: PropTypes.object,
  InputProps: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  autoComplete: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.string,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  allowNegative: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
  allowLeadingZeros: PropTypes.bool,
  valueIsNumericString: PropTypes.bool,
  type: PropTypes.string,
  removeFormatting: PropTypes.string,
  allowedDecimalSeparators: PropTypes.array,
  onValChange: PropTypes.func,
}

export default CommonNumberFormat
