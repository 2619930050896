import { get } from 'utils/lodash'
import {
  GENERAL_SEARCH_TYPE,
  ADDRESS_SEARCH_TYPE,
  PHONE_SEARCH_TYPE,
  CONTACT_NAME_SEARCH_TYPE,
  CONTAINER_ID_SEARCH_TYPE,
  RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
  ACCOUNT_NUMBER_SEARCH_TYPE,
  ACCOUNT_NAME_SEARCH_TYPE,
} from 'components/locations/filters/Search/settings'
import { isAccountsPage } from '../settings'

export const SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL = {
  [GENERAL_SEARCH_TYPE]: 'Choose a customer',
  [ADDRESS_SEARCH_TYPE]: 'Choose a billing or location address',
  [PHONE_SEARCH_TYPE]: 'Choose a contact mobile or office phone number',
  [CONTACT_NAME_SEARCH_TYPE]: 'Choose an individual contact name',
  [CONTAINER_ID_SEARCH_TYPE]: 'Choose a container identification number',
  [RECENT_ACCOUNT_VIEWED_SEARCH_TYPE]: '',
  [RECENT_ACCOUNT_ADDED_SEARCH_TYPE]: '',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'Choose an account number',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'Choose an account name',
}

export const SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER = {
  [GENERAL_SEARCH_TYPE]: 'Search...',
  [ADDRESS_SEARCH_TYPE]: 'Search by billing or location address',
  [PHONE_SEARCH_TYPE]: 'Search by',
  [CONTACT_NAME_SEARCH_TYPE]: 'Search by contact',
  [CONTAINER_ID_SEARCH_TYPE]: 'Search by the container ID',
  [RECENT_ACCOUNT_VIEWED_SEARCH_TYPE]: 'Recently viewed',
  [RECENT_ACCOUNT_ADDED_SEARCH_TYPE]: 'Recently added',
  [ACCOUNT_NUMBER_SEARCH_TYPE]: 'Search by account number',
  [ACCOUNT_NAME_SEARCH_TYPE]: 'Search by account name',
}

export const checkIsManualSearch = searchType => [ACCOUNT_NUMBER_SEARCH_TYPE, ACCOUNT_NAME_SEARCH_TYPE].includes(searchType)

export const getManualSearchParamName = searchType => (searchType === ACCOUNT_NUMBER_SEARCH_TYPE ? 'accountNumber' : 'accountName')

export const getSearchSuggestions = (row, type) => {
  if (isAccountsPage(type)) {
    return row
  }

  return {
    accountId: row?.accountId,
    locationId: row?.locationId,
    accountNumber: get(row, 'accountNumber', ''),
    accountName: get(row, 'accountName', ''),
    address: {
      unitNumber: get(row, 'unitNumber', ''),
      line1: get(row, 'line1', ''),
      city: get(row, 'city', ''),
      state: get(row, 'state', ''),
      zipCode: get(row, 'zipCode', ''),
    },
    billingProfile: get(row, 'billingProfileName', ''),
  }
}
