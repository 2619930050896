import Visa from 'assets/payment-type/Visa.svg'
import Mastercard from 'assets/payment-type/Mastercard.svg'
import Amex from 'assets/payment-type/Amex.svg'
import Discover from 'assets/payment-type/Discover.svg'
import JCB from 'assets/payment-type/JCB.svg'
import Diners from 'assets/payment-type/Diners.svg'
import Unknown from 'assets/payment-type/Unknown.svg'
import ACH_ICON from 'assets/payment-type/ACH.svg'
import CHECK_ICON from 'assets/payment-method/Check.svg'
import OTHER_ICON from 'assets/payment-method/Other.svg'
import CASH_ICON from 'assets/payment-method/Cash.svg'
import ACCOUNT_CREDIT_ICON from 'assets/payment-method/AccountCredit.svg'
import T from 'T'

export const PAYMENT_METHODS = {
  CASH: 'Cash',
  CHECK: 'Check',
  CREDIT_CARD: 'CreditCard',
  ACH: 'ACH',
  OTHER: 'Other',
  ACCOUNT_CREDIT: 'AccountCredit',
}

export const CREDIT = 'CREDIT'
export const DEBIT = 'DEBIT'

const { CHECK, OTHER, CREDIT_CARD, ACH, CASH, ACCOUNT_CREDIT } = PAYMENT_METHODS

export const PAYMENT_METHODS_LABELS = {
  [CASH]: 'Cash',
  [CHECK]: 'Check',
  [CREDIT_CARD]: 'Credit card',
  [ACH]: 'ACH',
  [OTHER]: 'Other',
  [ACCOUNT_CREDIT]: 'Account credit',
}

export const PAYMENT_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  REVERSED: 'Reversed',
  TRANSFERRED: 'Transferred',
  FAILED: 'Failed',
  PENDING: 'Pending',
}

const { APPROVED, REVERSED, TRANSFERRED, FAILED, DECLINED, PENDING } = PAYMENT_STATUS

export const PAYMENT_STATUS_LABELS = {
  [APPROVED]: 'Complete',
  [DECLINED]: 'Declined',
  [REVERSED]: 'Reversed',
  [TRANSFERRED]: 'Transfer',
  [FAILED]: 'Failed',
  [PENDING]: 'Pending',
}

export const ACH_ACCOUNT_TYPE = ['Checking', 'Saving']

export const AUTOPAY_TIMING = {
  IMMEDIATE: T.IMMEDIATE,
  DUE_DATE: T.DUE_DATE,
}

export const ENABLE_AUTOPAY_TIMING = false

export const DEFAULT_AUTOPAY_TIMING = AUTOPAY_TIMING.DUE_DATE

export const CARD_IMAGES = {
  Visa,
  Mastercard,
  Amex,
  Discover,
  Diners,
  JCB,
  Unknown,
}

export const ACH_IMAGE = ACH_ICON

export const DEFAULT_PAYMENT_METHOD_DELETE_ERROR = {
  status: 'Not Found',
  responseCode: '34',
}

export const PAYMENT_METHOD_IMAGES = {
  [CHECK]: CHECK_ICON,
  [OTHER]: OTHER_ICON,
  [CREDIT_CARD]: Unknown,
  [ACH]: ACH_ICON,
  [CASH]: CASH_ICON,
  [ACCOUNT_CREDIT]: ACCOUNT_CREDIT_ICON,
}
