import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import { ArrowForward } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setBulkMoveBetweenDaysState } from 'slices/route/bulkMoveBetweenDaysSlice'
import BulkMoveColumnHeader from 'components/route-manager/BulkActions/common/BulkMoveColumnHeader'

const RenderMoveFromHeader = () => {
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const selectedRowsCount = get(selectedRowIdsMap, 'size', 0)
  const dispatch = useDispatch()

  const onMoveFromClick = () => {
    dispatch(
      setBulkMoveBetweenDaysState({
        isSequenceDialogOpen: true,
      })
    )
  }
  return <BulkMoveColumnHeader onClick={onMoveFromClick} selectedRowsCount={selectedRowsCount} endIcon={<ArrowForward />} />
}

RenderMoveFromHeader.propTypes = {}

export default RenderMoveFromHeader
