import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import T from 'T'

const DeleteUnpaidInvoiceConfirmation = ({ isOpen = false, onConfirm, onClose }) => {
  return (
    <Dialog open={isOpen}>
      <HHDialogTitle title={T.DELETE_UNPAID_INVOICE} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            This invoice has already been sent to the customer. This action cannot be undone and the current invoice cannot be recovered. Do
            you want to proceed?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="small" onClick={onClose} />
        <LoadingButton onClick={onConfirm} variant="contained" color="error" size="small">
          Confirm
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

DeleteUnpaidInvoiceConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DeleteUnpaidInvoiceConfirmation
