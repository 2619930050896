import { actions } from '../actions/workOrders'

const { WORK_ORDER_DETAILS } = actions.PUT

const initialState = {
  workOrderDetails: null,
}

const WOReducer = (state = initialState, action = {}) => {
  if (action.type === WORK_ORDER_DETAILS) {
    return { ...state, workOrderDetails: action.payload }
  }
  return state
}

export default WOReducer
