export const WORK_ORDER_DOWNLOAD_PDF_KEY = 'workOrderDownloadPDF'

export const WORK_ORDER_PDF_FILTER = {
  type: 'pdf',
  searchFilters: [{ operation: 'EXIST_IN', columnValue: '', columnName: 'id' }],
  fileName: 'Work-Order',
  isDownload: true,
  buffer: true,
  urlToMapWith: WORK_ORDER_DOWNLOAD_PDF_KEY,
}
