import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

export const getFilterContent = createSelector(
  [state => state.globalFilterOptions, state => state.filter],
  (globalFilterOptions, filter) => ({
    businessLinesMeta: get(globalFilterOptions, 'businessLines', []),
    pricingZonesMeta: get(globalFilterOptions, 'pricingZones', []),
    businessLine: get(filter, 'businessLine', ''),
    pricingZoneId: get(filter, 'pricingZoneId', ''),
    search: get(filter, 'search', ''),
    measureIds: get(filter, 'measureIds', []),
    materialIds: get(filter, 'materialIds', []),
    serviceMethodIds: get(filter, 'serviceMethodIds', []),
    serviceActionIds: get(filter, 'serviceActionIds', []),
  })
)

export const getSelectedBusinessLineId = createSelector(
  [state => state.globalFilterOptions, state => state.businessLine],
  (globalFilterOptions, businessLine) => {
    const selectedBusinessLine = get(globalFilterOptions, 'businessLines', []).find(line => line.businessLineName === businessLine)
    return get(selectedBusinessLine, 'id', '')
  }
)

// Filter meta based on selected businessId
const getMetaByBusinessLine = (selectedBusinessLineId, metaFilter) => {
  if (!selectedBusinessLineId || !Array.isArray(metaFilter)) {
    return []
  }

  return metaFilter.filter(meta =>
    get(meta, 'businessLine', [])
      .map(line => line.id)
      .includes(selectedBusinessLineId)
  )
}

export const getFilterMeta = createSelector(
  [state => state.globalFilterOptions, state => state.businessLineId],
  (globalFilterOptions, businessLineId) => ({
    measureMeta: getMetaByBusinessLine(businessLineId, get(globalFilterOptions, 'measure', [])),
    materialsMeta: getMetaByBusinessLine(businessLineId, get(globalFilterOptions, 'materials', [])),
    methodsMeta: getMetaByBusinessLine(get(businessLineId, globalFilterOptions, 'methods', [])),
    serviceActionMeta: getMetaByBusinessLine(businessLineId, get(globalFilterOptions, 'serviceAction', [])),
  })
)
