import React, { useEffect, useMemo } from 'react'
import { Box, Grid, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Loader from 'components/common/loader'
import { useActivateAccountMutation } from 'api/accounts/activateAccount'
import { onChangeTab } from 'slices/customer/customerDetailsSlice'
import T from 'T'
import { get } from 'utils/lodash'
import { CUSTOMER_DETAILS_TABS_ID, CUSTOMER_DETAILS_TABS_ID_LIST } from 'containers/customer-details/settings'
import AccountActionsToolbar from 'components/customer-details/content/account-details/header/AccountActionsToolbar'
import lowerCase from 'lodash/lowerCase'
import { useLocation, useSearchParams } from 'react-router-dom-v5-compat'
import WithDeactivatedIndicator from 'components/customers/WithDeactivatedIndicator'
import { isLateFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { useFlags } from 'launchdarkly-react-client-sdk'
import CustomerInfo from './CustomerInfo'

const { ACCOUNT_TAB_ID, SERVICES_TAB_ID, BILLING_TAB_ID, FEES_TAB_ID } = CUSTOMER_DETAILS_TABS_ID
const DEFAULT_BACKGROUND_COLOR = '#f7f9fC'

const Header = ({ accountId, isPageReady }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const urlTab = searchParams.get('tab')
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const [_, { isLoading: isActivateAccountLoading }] = useActivateAccountMutation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isCustomerActive = get(customerDetails, 'active', false)
  const isCustomerSuspended = get(customerDetails, 'isSuspended', false)
  const flags = useFlags()
  const isLateFeeEnabled = isLateFeeFeatureAvailable({ flags })

  const bgcolor = useMemo(() => {
    if (isCustomerSuspended) {
      return theme.palette.background.suspension
    }
    if (!isCustomerActive) {
      return theme.palette.background.deactivated
    }
    return DEFAULT_BACKGROUND_COLOR
  }, [customerDetails, isCustomerActive, isCustomerSuspended])

  const handleTabChange = (event, newValue) => {
    searchParams.set('tab', newValue)
    dispatch(onChangeTab(newValue))
    setSearchParams(searchParams, { replace: false, state: get(location, 'state') })
  }

  useEffect(() => {
    const urlTabId = lowerCase(searchParams.get('tab') || '')
    if (CUSTOMER_DETAILS_TABS_ID_LIST.includes(urlTabId)) {
      dispatch(onChangeTab(urlTabId))
    }
  }, [searchParams])

  useEffect(() => {
    if (urlTab === FEES_TAB_ID && isPageReady && !isLateFeeEnabled) {
      setTimeout(() => {
        handleTabChange(null, ACCOUNT_TAB_ID)
      }, 300)
    }
  }, [urlTab, isPageReady, isLateFeeEnabled, handleTabChange])

  return (
    <WithDeactivatedIndicator showIndicator={!isCustomerActive}>
      <Box backgroundColor={bgcolor} width="100%" position="relative">
        {(isActivateAccountLoading || !isPageReady) && <Loader />}
        <Grid container direction="column" overflow="hidden">
          <Grid container item xs={12} direction={{ xs: 'column-reverse', lg: 'row' }}>
            <Grid item>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                  <CustomerInfo />
                </Grid>
                {!isDesktop && (
                  <Grid item>
                    <Box mr={3}>
                      <AccountActionsToolbar accountId={accountId} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} lg>
              <Box pt={1} px={3} width="100%" />
            </Grid>
            {isDesktop && (
              <Grid item>
                <Box pt={1} pr="36px">
                  <AccountActionsToolbar accountId={accountId} />
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box px={2} borderBottom={1} borderColor="divider">
              <Tabs value={urlTab} onChange={handleTabChange} sx={{ button: { textTransform: 'capitalize' } }}>
                <Tab value={ACCOUNT_TAB_ID} label={T.ACCOUNT} />
                <Tab value={SERVICES_TAB_ID} label={T.SERVICES} />
                <Tab value={BILLING_TAB_ID} label={T.BILLING} />
                {isLateFeeEnabled && <Tab value={FEES_TAB_ID} label={T.FEES} />}
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WithDeactivatedIndicator>
  )
}

Header.propTypes = {
  accountId: PropTypes.string.isRequired,
  isPageReady: PropTypes.bool.isRequired,
}

export default Header
