import React from 'react'
import PropTypes from 'prop-types'
import { Link, Tooltip } from '@mui/material'
import { noop } from 'lodash/util'

const ServiceNameWrapper = ({ disabled = false, serviceName, onClick = noop }) => {
  return (
    <Tooltip title={serviceName}>
      <Link
        component="button"
        onClick={onClick}
        variant="h6"
        color={disabled ? 'text.disabled' : 'textPrimary'}
        noWrap
        textAlign="left"
        sx={{ cursor: 'pointer', minWidth: '110px', maxWidth: { xs: '10rem', sm: '10rem', md: '14rem', lg: '16rem', xl: '20rem' } }}
      >
        {serviceName}
      </Link>
    </Tooltip>
  )
}

ServiceNameWrapper.propTypes = {
  disabled: PropTypes.bool,
  serviceName: PropTypes.string,
  onClick: PropTypes.func,
}

export default ServiceNameWrapper
