import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import { Button, DialogContent, List, useTheme } from '@mui/material'

import HHDialogActions from 'components/common/HHDialogActions'
import { useLazyGetSuspensionHistoryQuery } from 'api/accounts/suspensionsCrud'
import { handleError } from 'utils/error'
import SuspensionHistoryItem from 'components/customers/groups/suspension/suspension-history/SuspensionHistoryItem'
import SuspensionHistorySkeletonItem from 'components/customers/groups/suspension/suspension-history/SuspensionHistorySkeletonItem'
import { HHAlert } from 'components/common/HHAlert'

const SuspensionHistoryDialog = ({ open = false, accountId, onClose = noop }) => {
  const [getSuspensionHistory, { isLoading, isFetching, data = [] }] = useLazyGetSuspensionHistoryQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const shimmersArray = new Array(4).fill(null)
  const suspensionCount = Array.isArray(data) ? data.length : 0
  const hasSuspensions = suspensionCount > 0
  const theme = useTheme()

  useEffect(() => {
    if (open) {
      getSuspensionHistory({ accountId }).unwrap().catch(handleError)
    }
  }, [open, accountId])

  return (
    <HHBaseDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle title={T.SUSPENSION_HISTORY} onClose={onClose} />
      <DialogContent sx={{ overflowY: 'auto' }}>
        <List>
          {!loading &&
            hasSuspensions &&
            data.map(({ id, suspensionType, updatedBy, startEndDate, description, createdBy, createdAt }) => {
              return (
                <SuspensionHistoryItem
                  key={id}
                  createdAt={createdAt}
                  suspensionType={suspensionType}
                  updatedBy={updatedBy}
                  startEndDate={startEndDate}
                  description={description}
                  createdBy={createdBy}
                />
              )
            })}
          {loading && shimmersArray.map(() => <SuspensionHistorySkeletonItem />)}
          {!loading && !hasSuspensions && (
            <HHAlert severity="info" borderColor={theme.palette.info.main}>
              {T.NO_SUSPENSION_HISTORY}
            </HHAlert>
          )}
        </List>
      </DialogContent>
      <HHDialogActions>
        <Button onClick={onClose} variant="contained" color="primary" size="small">
          Close
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

SuspensionHistoryDialog.propTypes = {
  open: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default SuspensionHistoryDialog
