import Constants from 'Constants'
import { BACKGROUND } from 'theme/colors'
import { firebaseConfig } from 'settings/constants/firebase'
import { common, indigo } from '@mui/material/colors'
import { PROVIDERS } from 'settings/constants/auth'

const { APPLE, MICROSOFT, GOOGLE, FACEBOOK, TWITTER, EMAIL } = PROVIDERS
const { MEDIA_SERVER } = Constants
const WHITE_COLOR = BACKGROUND.paper

const commonProps = { buttonColor: WHITE_COLOR }
const { clientId } = firebaseConfig

export const signInOptions = [
  {
    provider: APPLE,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Apple.svg`,
    ...commonProps,
  },
  {
    provider: MICROSOFT,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Microsoft.svg`,
    ...commonProps,
  },
  {
    clientId,
    provider: GOOGLE,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Google.svg`,
    ...commonProps,
  },
  {
    provider: FACEBOOK,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Facebook.svg`,
    ...commonProps,
  },
  {
    provider: TWITTER,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Twitter.svg`,
    ...commonProps,
  },
  {
    provider: EMAIL,
    fullLabel: 'Sign in with email',
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Email.svg`,
    ...commonProps,
  },
].filter(({ provider }) => [MICROSOFT, GOOGLE, EMAIL].includes(provider))

export const PRIMARY_BUTTON_SX = {
  fontWeight: 500,
  backgroundColor: indigo[500],
  color: common.white,
  '&:hover': {
    backgroundColor: indigo[500],
    color: common.white,
  },
}
