import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import differenceBy from 'lodash/differenceBy'

import { toast } from 'react-toastify'
import { Box, DialogContent, Divider } from '@mui/material'

import { handleError } from 'utils/error'
import { useLazyGetAllPricedServicesQuery } from 'api/pricing/pricingServiceCrud'
import { useLazyGetPricedServicesByRentalFeeQuery, useLinkPricedServicesToRentalFeeMutation } from 'api/pricing/rentalFeePricedServicesCrud'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import RenderCell from './common/RenderCell'
import DataGrid from '../AddRemoveRentalFees/common/DataGrid'
import RentalFeeListItemSkeleton from '../../common/field/RentalFeeListItemSkeleton'
import { OUTER_CONTENT, getAddDialogContentHeight } from '../AddRemoveRentalFees/common/utils'
import { ROW_HEIGHT } from './common/settings'

const AddPricedServicesDialog = ({ isOpen = false, rentalFeeId, onClose }) => {
  const [selectionModel, setSelectionModel] = useState([])
  const [getAllPricedServices, { isFetching: isGetPricedServicesLoading, data: pricedServicesData }] = useLazyGetAllPricedServicesQuery()
  const [getPricedServicesByRentalFee, { isFetching: isGetPricedServicesByRentalFeeLoading, data: pricedServicesByRentalFeeData }] =
    useLazyGetPricedServicesByRentalFeeQuery()
  const [linkPricedServicesToRentalFee, { isLoading: isLinkLoading }] = useLinkPricedServicesToRentalFeeMutation()

  const isListLoading = useMemo(
    () => isGetPricedServicesLoading || isGetPricedServicesByRentalFeeLoading,
    [isGetPricedServicesLoading, isGetPricedServicesByRentalFeeLoading]
  )
  const activePricedServices = useMemo(() => pricedServicesData?.filter(({ active }) => active), [pricedServicesData])
  const newPricedServices = useMemo(
    () => differenceBy(activePricedServices, pricedServicesByRentalFeeData, 'id'),
    [activePricedServices, pricedServicesByRentalFeeData]
  )
  const sortedPricedServices = useMemo(() => sortBy(newPricedServices, ['serviceName']), [newPricedServices])
  const sortedAlreadyExistPricedServices = useMemo(
    () => sortBy(pricedServicesByRentalFeeData, ['serviceName']),
    [pricedServicesByRentalFeeData]
  )
  const hasPricedServices = useMemo(() => sortedPricedServices.length > 0, [sortedPricedServices])
  const hasAlreadyExistPricedServices = useMemo(() => sortedAlreadyExistPricedServices.length > 0, [sortedAlreadyExistPricedServices])
  const maxContentHeight = window.innerHeight - OUTER_CONTENT

  const handleSelectionModelChange = newSelectionModel => {
    setSelectionModel(newSelectionModel)
  }

  const handleAddPricedService = () => {
    linkPricedServicesToRentalFee({ id: rentalFeeId, pricedServiceIds: selectionModel })
      .unwrap()
      .then(() => {
        toast.success(`Priced service${selectionModel.length === 1 ? '' : 's'} added successfully`)
        onClose()
        handleSelectionModelChange([])
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleSelectionModelChange([])
      getAllPricedServices({})
      getPricedServicesByRentalFee({ id: rentalFeeId })
    }
  }, [isOpen])

  const { existListContainerHeight, newListContainerHeight } = useMemo(
    () => getAddDialogContentHeight(sortedAlreadyExistPricedServices, sortedPricedServices, maxContentHeight, ROW_HEIGHT),
    [maxContentHeight, sortedAlreadyExistPricedServices, sortedPricedServices]
  )

  const noRowText = useMemo(() => {
    if (!hasPricedServices && !hasAlreadyExistPricedServices) {
      return 'No priced services available'
    }
    return 'You have already added all priced services'
  }, [hasPricedServices, hasAlreadyExistPricedServices])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.ADD_SERVICES_TO_RENTAL_FEE} onClose={onClose} />
      <DialogContent>
        {isListLoading && <RentalFeeListItemSkeleton rows={2} />}
        {!isListLoading && (
          <>
            {hasAlreadyExistPricedServices && (
              <>
                <Box height={existListContainerHeight}>
                  <DataGrid rows={sortedAlreadyExistPricedServices} RenderCell={RenderCell} />
                </Box>
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <Box height={newListContainerHeight}>
              <DataGrid
                isSelectable
                rows={sortedPricedServices}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                RenderCell={RenderCell}
                noRowsText={noRowText}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isLinkLoading}
          disabled={isLinkLoading || selectionModel.length === 0}
          label={T.ADD}
          onClick={handleAddPricedService}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddPricedServicesDialog.propTypes = {
  isOpen: PropTypes.bool,
  rentalFeeId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default AddPricedServicesDialog
