import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import { HHFormTextField, HHFormPriceField, HHFormSwitchField, HHFormNumberField } from 'components/form-fields/v5'
import { TableBody, TableCell as MuiTableCell, TableRow, IconButton, Typography, Box, styled } from '@mui/material'
import Constants from 'Constants'
import ConfirmationTableTiersFields from './ConfirmationTableTiersFields'
import { getFeeRecord } from '../getFeeRecord'

const { MEDIA_SERVER } = Constants

const TableCell = styled(MuiTableCell)({
  padding: '4px 8px 4px 0px',
  '&:first-child': {
    paddingLeft: 8,
  },
})

const ConfirmationTableBody = ({ columns, rows, name, fields, remove, update, duplicated = false, setDisposalFeeRecords }) => {
  const { control } = useFormContext()
  const records = rows.map(row => getFeeRecord(row, 'create', undefined, true))
  return (
    <TableBody backgroundColor={duplicated && '#fbf4f4'}>
      {fields.map((item, index) => (
        <TableRow>
          {columns
            .filter(({ checked }) => checked)
            .map(({ label }) => (
              <>
                {label === T.DISPOSAL_FEE && (
                  <TableCell sx={{ minWidth: 240 }}>
                    <HHFormTextField size="small" control={control} name={`${name}.${index}.feeName`} required />
                  </TableCell>
                )}
                {label === T.MINIMUM_FEE && (
                  <TableCell sx={{ minWidth: 104 }}>
                    <HHFormPriceField size="small" control={control} placeholder="0.0" name={`${name}.${index}.minFee`} />
                  </TableCell>
                )}
                {label === T.COST_PLUS && (
                  <TableCell
                    sx={{
                      minWidth: 80,
                    }}
                  >
                    <Box
                      sx={{
                        margin: 'auto',
                        display: 'block',
                        height: 16,
                        width: 32,
                      }}
                    >
                      <HHFormSwitchField control={control} name={`${name}.${index}.costPlus`} />
                    </Box>
                  </TableCell>
                )}
                {label === T.OVERAGE_FEE && (
                  <>
                    <TableCell
                      sx={{
                        minWidth: 88,
                      }}
                    >
                      <HHFormNumberField size="small" control={control} name={`${name}.${index}.overageThreshold`} placeholder="0" />
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: 88,
                      }}
                    >
                      <HHFormPriceField size="small" control={control} name={`${name}.${index}.overageFee`} placeholder="0" />
                    </TableCell>
                  </>
                )}
                {label === T.PRICE && <ConfirmationTableTiersFields index={index} name={name} updateFormElement={update} />}
                {[T.PRICE, T.DISPOSAL_FEE, T.MINIMUM_FEE, T.COST_PLUS, T.OVERAGE_FEE, T.MEASURE].includes(label) === false && (
                  <TableCell
                    sx={{
                      p: 1,
                      minWidth: 88,
                      ...((label === T.BUSINESS_LINE || label === T.PRICING_ZONE) && { minWidth: 150 }),
                      ...(label === T.SPECIFIC_DAYS && { minWidth: 450 }),
                    }}
                  >
                    <Typography variant="body1" align="left">
                      {records[index][label]}
                    </Typography>
                  </TableCell>
                )}
              </>
            ))}
          <TableCell>
            <IconButton
              onClick={() => {
                remove(index)
                setDisposalFeeRecords(disposalFees => disposalFees.filter((e, disposalIndex) => index !== disposalIndex))
              }}
            >
              <Box component="img" minWidth={15} src={`${MEDIA_SERVER}Trash-Red.svg`} />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

ConfirmationTableBody.propTypes = {
  fields: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  duplicated: PropTypes.bool,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  setDisposalFeeRecords: PropTypes.func.isRequired,
}

export default ConfirmationTableBody
