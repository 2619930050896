import { BILLING_TABS_ID } from 'settings/constants/billing'

const { SENT_INVOICES_TAB_ID, PAYMENTS_TAB_ID } = BILLING_TABS_ID

const SORT_BY_DESC = 'DESC'

export const GET_DEFAULT_SORT_BY = tabId => {
  if (tabId === SENT_INVOICES_TAB_ID) {
    return { sentTime: { columnName: 'sentTime', operation: SORT_BY_DESC } }
  }

  if (tabId === PAYMENTS_TAB_ID) {
    return { transactionPostedDate: { columnName: 'transactionPostedDate', operation: SORT_BY_DESC } }
  }

  return {}
}

export const getRequiredCreateInvoicesFilter = (billingProfile, billingPeriodDetails) => [
  {
    operation: 'EQUAL',
    columnValue: billingProfile,
    columnName: 'customerBillingProfile',
  },
  {
    operation: 'EQUAL',
    columnValue: billingPeriodDetails.startDate,
    columnName: 'startDate',
  },
  {
    operation: 'EQUAL',
    columnValue: billingPeriodDetails.endDate,
    columnName: 'endDate',
  },
]
