/* eslint-disable no-param-reassign */
import { get } from 'lodash'
import { createBulkActionSlice } from 'slices/generic/createBulkActionSlice'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const bulkMoveBetweenRoutesSlice = createBulkActionSlice({
  name: 'bulkMoveBetweenRoutes',
})
export const {
  setBulkState: setBulkMoveStopsState,
  setBulkDate: setBulkMoveStopsDate,
  addSelectedMoveToRows,
  addSelectedMoveFromRows,
  setSelectedMoveFromRows,
  setSelectedMoveToRows,
  setConfirmPermanentMoveDialogState,
} = bulkMoveBetweenRoutesSlice.actions

export const selectBulkMoveFocusName = state => get(state, 'bulkMoveBetweenRoutes.focus.name')
export const selectBulkMoveFocusRowIndex = state => get(state, 'bulkMoveBetweenRoutes.focus.rowIndex', 0)
export const selectBulkMoveFromRouteSequenceLine = state => get(state, 'bulkMoveBetweenRoutes.moveFromRouteSequenceLine', {})
export const selectBulkMoveFromRouteStopMarkers = state => get(state, 'bulkMoveBetweenRoutes.moveFromRouteStopMarkers', {})
export const selectBulkMoveFromRows = state => get(state, 'bulkMoveBetweenRoutes.moveFromRows', [])
export const selectBulkMoveInvalidStopsToBeAssignedCount = state => get(state, 'bulkMoveBetweenRoutes.invalidStopsToBeAssignedCount', 0)
export const selectBulkMoveValidStopIdsToBeAssigned = state => get(state, 'bulkMoveBetweenRoutes.validStopIdsToBeAssigned', [])
export const selectBulkMoveMapLayer = state => get(state, 'bulkMoveBetweenRoutes.mapLayer', BOTH_ROUTES_LAYER)
export const selectBulkMoveStopsDate = state => get(state, 'bulkMoveBetweenRoutes.serializedServiceDate')
export const selectBulkMoveStopsToBeAssignedCount = state => get(state, 'bulkMoveBetweenRoutes.stopsToBeAssignedCount', 0)
export const selectBulkMoveToRouteSequenceLine = state => get(state, 'bulkMoveBetweenRoutes.moveToRouteSequenceLine', {})
export const selectBulkMoveToRouteStopMarkers = state => get(state, 'bulkMoveBetweenRoutes.moveToRouteStopMarkers', {})
export const selectBulkMoveToRows = state => get(state, 'bulkMoveBetweenRoutes.moveToRows', [])
export const selectBulkSelectedMoveFromRows = state => get(state, 'bulkMoveBetweenRoutes.selectedMoveFromRows', [])
export const selectBulkSelectedMoveToRows = state => get(state, 'bulkMoveBetweenRoutes.selectedMoveToRows', [])
export const selectIsOpenBulkMoveConfirmCloseDialog = state => get(state, 'bulkMoveBetweenRoutes.isConfirmCloseDialogOpen', false)
export const selectIsOpenBulkMoveStopsDialog = state => get(state, 'bulkMoveBetweenRoutes.isOpen', false)
export const selectBulkMoveRoutesById = state => get(state, 'bulkMoveBetweenRoutes.routesById', {})

export const selectIsSelectDateDialogOpen = state => get(state, 'bulkMoveBetweenRoutes.isSelectDateDialogOpen', false)
export const selectIsSequenceDialogOpen = state => get(state, 'bulkMoveBetweenRoutes.isSequenceDialogOpen', false)
export const selectBulkMoveFitBounds = state => get(state, 'bulkMoveBetweenRoutes.fitBounds', false)
export const selectToBeInsertedRowsIds = state => get(state, 'bulkMoveBetweenRoutes.toBeInsertedRowsIds', [])

export const selectIsConfirmPermanentMoveDialogOpen = state => get(state, 'bulkMoveBetweenRoutes.confirmPermanentMoveDialog.isOpen', false)

export default bulkMoveBetweenRoutesSlice.reducer
