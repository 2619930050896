import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import T from 'T'
import api from 'api'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'
import { CACHE_TAG_WORK_ORDER_DETAILS } from 'api/cacheTagTypes'
import CancelButton from 'components/buttons/CancelButton'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE } = INVOICE_STATUS
const ALLOWED_STATUS = [NOT_READY_FOR_INVOICE, READY_FOR_INVOICE]

const EditInvoiceDialog = ({ isOpen = false, currentInvoiceStatus, workOrderId, onClose }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder] = useUpdateWorkOrderMutation()
  const [newInvoiceStatus, setNewInvoiceStatus] = useState()

  const handleSave = () => {
    if (newInvoiceStatus === currentInvoiceStatus) {
      // If same status
      onClose()
      return
    }

    dispatch(putIsLoading(true))
    updateWorkOrder({ id: workOrderId, invoiceStatus: newInvoiceStatus })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS]))
        onClose()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useEffect(() => {
    if (isOpen) {
      setNewInvoiceStatus(currentInvoiceStatus)
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <HHDialogTitle title={T.EDIT_INVOICE_DETAILS} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <HHTextField
            sx={{ mt: 3 }}
            fullWidth
            deprecatedLabel={false}
            label={T.STATUS}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={[newInvoiceStatus]}
            onChange={e => setNewInvoiceStatus(e.target.value)}
          >
            {ALLOWED_STATUS.map(status => (
              <SelectItem value={status}>{INVOICE_STATUS_DISPLAY[status].text}</SelectItem>
            ))}
          </HHTextField>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <Button size="small" variant="contained" onClick={handleSave}>
          {T.SAVE}
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

EditInvoiceDialog.propTypes = {
  isOpen: PropTypes.bool,
  currentInvoiceStatus: PropTypes.string,
  workOrderId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default EditInvoiceDialog
