import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  selectBulkMoveFetchId,
  selectBulkMoveFromRows,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveRouteId,
  selectBulkMoveStopsToBeAssignedCount,
  selectBulkMoveToSerializedDate,
  selectBulkSelectedMoveFromRows,
  selectBulkSelectedMoveToRows,
  setPermanentRecurrenceChangeState,
  setSelectedMoveFromRows,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import RenderMoveFromHeader from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/RenderMoveFromHeader'
import CommonMoveFromRouteColumnBetweenDays from 'components/route-manager/BulkActions/common/BetweenDays/CommonMoveFromColumnBetweenDays'
import RenderPermanentChangeCheckboxHeaderCell from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/RenderPermanentChangeCheckboxHeaderCell'
import RenderPermanentChangeCheckboxCell from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/RenderPermanentChangeCheckboxCell'
import { get } from 'lodash'

const MoveFromRouteColumn = ({ apiRef, fetchAndFormatGeojsonData }) => {
  const moveFromRows = useSelector(selectBulkMoveFromRows)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const selectedMoveToRows = useSelector(selectBulkSelectedMoveToRows)
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const routeId = useSelector(selectBulkMoveRouteId)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const fetchId = useSelector(selectBulkMoveFetchId)

  const isRowSelectable = params => {
    if (!fromFEDate || !toFEDate) return false
    return params.row.date === fromFEDate && params.row.pricingPeriod === 'Weekly' && params.row.recurrenceFrequency !== '7'
  }

  const getRowClassName = params => {
    const { row } = params
    const pricingPeriod = get(row, 'pricingPeriod')
    const recurrenceFrequency = get(row, 'recurrenceFrequency')
    const isWeeklyPricingPeriod = pricingPeriod === 'Weekly' && recurrenceFrequency !== '7'
    return isWeeklyPricingPeriod ? '' : 'disabled'
  }

  return (
    <CommonMoveFromRouteColumnBetweenDays
      apiRef={apiRef}
      moveFromRows={moveFromRows}
      fetchAndFormatGeojsonData={fetchAndFormatGeojsonData}
      fromSerializedDate={fromSerializedDate}
      setSelectedMoveFromRows={setSelectedMoveFromRows}
      toSerializedDate={toSerializedDate}
      fetchId={fetchId}
      routeId={routeId}
      RenderCheckboxHeaderCell={RenderPermanentChangeCheckboxHeaderCell}
      RenderCheckboxCell={RenderPermanentChangeCheckboxCell}
      selectedMoveFromRows={selectedMoveFromRows}
      selectedMoveToRows={selectedMoveToRows}
      stopsToBeAssignedCount={stopsToBeAssignedCount}
      isRowSelectable={isRowSelectable}
      bulkReduxAction={setPermanentRecurrenceChangeState}
      RenderMoveFromHeader={RenderMoveFromHeader}
      getRowClassName={getRowClassName}
    />
  )
}

MoveFromRouteColumn.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  fetchAndFormatGeojsonData: PropTypes.func.isRequired,
}

export default MoveFromRouteColumn
