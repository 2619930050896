import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { lightBlue } from '@mui/material/colors'

import RenderAccountCell from './RenderAccountCell'

const AccountsDataGrid = ({ rows = [], rowSelectionModel = [], onRowSelectionModelChange }) => {
  const getRowHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ accountId }) => accountId, [])

  return (
    <DataGridPro
      rows={rows}
      columnHeaderHeight={0}
      hideFooter
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: RenderAccountCell,
          sortable: false,
          resizable: false,
        },
      ]}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      disableMultipleRowSelection
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      slots={{ noRowsOverlay: () => null }}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row': {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: `${lightBlue[700]}14`,
          },
        },
      }}
    />
  )
}

AccountsDataGrid.propTypes = {
  rows: PropTypes.array,
  rowSelectionModel: PropTypes.array,
  onRowSelectionModelChange: PropTypes.func.isRequired,
}

export default AccountsDataGrid
