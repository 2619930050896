import React from 'react'
import { GridCellCheckboxRenderer, useGridApiContext } from '@mui/x-data-grid-pro'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { get } from 'lodash'
import Typography from '@mui/material/Typography'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

const RenderPermanentChangeCheckboxCell = props => {
  const { row } = props
  const pricingPeriod = get(row, 'pricingPeriod')
  const recurrenceFrequency = get(row, 'recurrenceFrequency')
  const isWeeklyPricingPeriod = pricingPeriod === 'Weekly' && recurrenceFrequency !== '7'
  const api = useGridApiContext()
  return (
    <>
      {isWeeklyPricingPeriod && <GridCellCheckboxRenderer api={api} {...props} />}
      {!isWeeklyPricingPeriod && (
        <NoMaxWidthTooltip
          title={
            <>
              <Typography variant="subtitle2" color="common.white">
                Cannot bulk permanently change sequence.
              </Typography>
              <Typography variant="subtitle2" color="common.white">
                Only 1-6x weekly services are supported at this time.
              </Typography>
            </>
          }
          arrow
          placement="top-start"
        >
          <InfoOutlinedIcon />
        </NoMaxWidthTooltip>
      )}
    </>
  )
}

RenderPermanentChangeCheckboxCell.propTypes = {
  row: PropTypes.object.isRequired,
}

export default RenderPermanentChangeCheckboxCell
