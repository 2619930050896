import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { get } from 'lodash'
import { handleMUIPreventCellKeyDown } from 'utils/datagrid'
import BorderlessDataGrid from 'components/common/BorderlessDataGridPro'
import RenderStopListItem from './RenderStopListItem'
import { ROW_HEIGHT } from './setings'

const StopsDataGrid = ({ apiRef, rows = [], loading = false }) => {
  const columns = [
    {
      field: 'id',
      renderCell: RenderStopListItem,
      flex: 1,
    },
  ]

  const getRowId = row => get(row, 'id')
  const NoRowsOverlay = () => <Box />
  return (
    <Box height="calc(100vh - 234px)">
      <BorderlessDataGrid
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        loading={loading}
        apiRef={apiRef}
        getRowId={getRowId}
        disableChildrenFiltering
        disableRowSelectionOnClick
        disableColumnReorder
        columnHeaderHeight={0}
        rowHeight={ROW_HEIGHT}
        disableColumnMenu
        hideFooter
        columns={columns}
        rows={rows}
        onCellKeyDown={handleMUIPreventCellKeyDown}
      />
    </Box>
  )
}

StopsDataGrid.propTypes = {
  rows: PropTypes.array,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  loading: PropTypes.bool,
}

export default StopsDataGrid
