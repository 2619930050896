import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRawRoutes: build.query({
      query: ({ routeId, serviceDate, expandNotes }) => ({
        url: `/api/v1/core/routes/${routeId}/work-orders`,
        params: { service_date: serviceDate, expandNotes },
      }),
    }),
  }),
})

export const { useLazyGetRawRoutesQuery } = extendedApi
