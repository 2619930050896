import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { blue, grey, lightBlue } from '@mui/material/colors'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import AccountInfoListItem from './AccountInfoListItem'

const AccountInfo = ({
  selectedAccount = null,
  initialCreditBalanceCents = 0,
  updatedCreditBalanceCents = 0,
  showUpdatedCreditBalanceCents = false,
}) => {
  return (
    <Box sx={{ border: 1, borderColor: 'divider', bgcolor: selectedAccount ? blue[50] : grey[100] }}>
      <List dense disablePadding sx={{ display: 'flex' }}>
        {selectedAccount && <AccountInfoListItem label={selectedAccount?.accountNumber} value={selectedAccount?.accountName} />}
        <AccountInfoListItem
          label={capitalize(T.ACCOUNT_CREDIT)}
          value={<HHDisplayMoney value={initialCreditBalanceCents} />}
          sx={{ textAlign: 'right' }}
        />
      </List>

      {showUpdatedCreditBalanceCents && initialCreditBalanceCents !== updatedCreditBalanceCents && (
        <Typography variant="subtitle1" fontWeight={700} color={lightBlue[400]} textAlign="right" px={2} mt={-1} mb={1}>
          Your new account credit will be <HHDisplayMoney value={updatedCreditBalanceCents} />
        </Typography>
      )}
    </Box>
  )
}

AccountInfo.propTypes = {
  selectedAccount: PropTypes.object,
  initialCreditBalanceCents: PropTypes.number,
  updatedCreditBalanceCents: PropTypes.number,
  showUpdatedCreditBalanceCents: PropTypes.bool,
}

export default AccountInfo
