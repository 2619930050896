import React from 'react'
import PropTypes from 'prop-types'
import { Stack, styled, ToggleButtonGroup } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import Constants from 'Constants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { ROUTE_COLOR_STOPS_LAYER, setCurrentMapLayer, setIsStreetView, WO_COLOR_STOPS_LAYER } from 'slices/route/routeSlice'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import MapToggleButton from './MapToggleButton'
import { toggleRouteLayers } from './settings'

const MapStyleSwitch = styled('img')({
  height: '70px',
  width: '70px',
  position: 'absolute',
  top: 8,
  right: 40,
  cursor: 'pointer',
})

const { MAP_VIEW_URL, LIGHT_VIEW, SATELLITE_STREET_VIEW } = MAP_DEFAULT_OPTIONS

const { MEDIA_SERVER } = Constants
const RouteManagerMapToolbar = ({ map }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isStreetView = useSelector(s => get(s, 'Route.map.isStreetView', true), shallowEqual)
  const currentLayer = useSelector(s => get(s, 'Route.map.currentLayer', WO_COLOR_STOPS_LAYER), shallowEqual)
  const viewOnMapRouteId = useSelector(s => get(s, 'Route.viewOnMap.routeId', null), shallowEqual)
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const selectedRouteIdList = viewOnMapRouteId ? [viewOnMapRouteId] : selectedRouteIds
  const changeLayer = (event, newCurrentLayer) => {
    const mapCurrentRef = map.current
    if (mapCurrentRef && newCurrentLayer !== null) {
      dispatch(setCurrentMapLayer(newCurrentLayer))
      selectedRouteIdList.forEach(routeId => {
        toggleRouteLayers(mapCurrentRef, newCurrentLayer, routeId)
      })
    }
  }

  const toggleMapStyle = () => {
    const mapCurrentRef = map.current
    const updatedIsStreetView = !isStreetView
    dispatch(setIsStreetView(updatedIsStreetView))
    const styleSuffix = updatedIsStreetView ? LIGHT_VIEW : SATELLITE_STREET_VIEW
    mapCurrentRef.setStyle(`${MAP_VIEW_URL}${styleSuffix}`)
  }

  return (
    <>
      <MapStyleSwitch
        zIndex={theme.zIndex.appbar}
        src={`${MEDIA_SERVER}${isStreetView ? 'SatelliteView.png' : 'StreetView.png'}`}
        alt={isStreetView ? 'Switch to satellite view' : 'Switch to street view'}
        onClick={toggleMapStyle}
      />
      <Stack sx={{ position: 'absolute', top: 10, right: 110, zIndex: theme.zIndex.appbar }}>
        <ToggleButtonGroup color="primary" value={currentLayer} exclusive onChange={changeLayer} aria-label="Platform">
          <MapToggleButton value={ROUTE_COLOR_STOPS_LAYER}>Route</MapToggleButton>
          <MapToggleButton value={WO_COLOR_STOPS_LAYER}>Status</MapToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  )
}

RouteManagerMapToolbar.propTypes = {
  map: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]).isRequired,
}

export default RouteManagerMapToolbar
