import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MoreHoriz } from '@mui/icons-material'
import { IconButton, ListItemText, Menu, MenuItem, ListItemIcon, SvgIcon } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Recover } from 'assets/route_manager/actions_menu/Recover.svg'
import { ReactComponent as CalendarPlus } from 'assets/route_manager/actions_menu/CalendarPlus.svg'
import { ReactComponent as TemporaryMode } from 'assets/route_manager/actions_menu/TemporaryMode.svg'
import { ReactComponent as DriverPath } from 'assets/route_manager/actions_menu/DriverPath.svg'
import { ReactComponent as SequenceLine } from 'assets/route_manager/actions_menu/SequenceLine.svg'
import { capitalize } from 'lodash'
import T from 'T'
import HHSwitchIconField from 'components/form-fields/v5/HHSwitchIconField'
import { getIsUnassignedStopsOpen } from 'data/route/unassignedStopsSelector'
import { getViewMode } from 'data/route/viewModeSelector'
import {
  changeIsRouteChangesOnTemporaryMode,
  setIsDriverPathActive,
  setIsSequenceLineActive,
  VIEW_MODE_MAP,
  VIEW_MODE_SEQUENCE,
} from 'slices/route/routeSlice'
import TemporaryModeDialog from './TemporaryModeDialog'
import RouteRecoveryDialog from './RouteRecoveryDialog'

const RouteManagerActionsMenu = ({ sx, onUnscheduledRouteChange, updateRoute }) => {
  const dispatch = useDispatch()
  const [isTemporaryDialogOpen, setIsTemporaryDialogOpen] = useState(false)
  const [isRouteRecoveryDialogOpen, setIsRouteRecoveryDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isOpen = Boolean(anchorEl)
  const isUnassignedStopsActive = useSelector(getIsUnassignedStopsOpen)
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)
  const isDriverPathActive = useSelector(state => state.Route.isDriverPathActive)
  const isSequenceLineActive = useSelector(state => state.Route.isSequenceLineActive)
  const viewMode = useSelector(getViewMode)
  const showTemporaryModeOption = viewMode === VIEW_MODE_SEQUENCE
  const isMapView = viewMode === VIEW_MODE_MAP
  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleUnscheduledRouteChange = () => {
    onUnscheduledRouteChange()
    closeMenu()
  }

  const handleCloseTemporaryDialog = () => {
    setIsTemporaryDialogOpen(false)
  }

  const handleCancelTemporaryDialog = () => {
    setIsTemporaryDialogOpen(false)
  }

  const handleCloseRouteRecoveryDialog = () => {
    setIsRouteRecoveryDialogOpen(false)
  }

  const handleOpenRouteRecoveryDialog = () => {
    setIsRouteRecoveryDialogOpen(true)
    closeMenu()
  }

  const handleCancelRouteRecoveryDialog = () => {
    setIsRouteRecoveryDialogOpen(false)
  }

  const handleConfirmRouteRecoveryDialog = () => {
    setIsRouteRecoveryDialogOpen(false)
    updateRoute()
  }

  const toggleIsDriverPathActive = () => {
    dispatch(setIsDriverPathActive(!isDriverPathActive))
    closeMenu()
  }

  const toggleIsSequenceLineActive = () => {
    dispatch(setIsSequenceLineActive(!isSequenceLineActive))
    closeMenu()
  }

  const handleConfirmTemporaryDialog = () => {
    dispatch(changeIsRouteChangesOnTemporaryMode(true))
    setIsTemporaryDialogOpen(false)
  }

  const handleTemporaryModeChange = () => {
    const newTemporaryModeValue = !isTemporaryModeActive
    if (newTemporaryModeValue) {
      setIsTemporaryDialogOpen(true)
    } else {
      dispatch(changeIsRouteChangesOnTemporaryMode(newTemporaryModeValue))
    }
    closeMenu()
  }

  return (
    <>
      <IconButton sx={sx} onClick={openMenu}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleUnscheduledRouteChange}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <CalendarPlus />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{capitalize(T.UNASSIGNED_STOPS)}</ListItemText>
          <HHSwitchIconField isChecked={isUnassignedStopsActive} type="UnscheduledRoute" />
        </MenuItem>
        {showTemporaryModeOption && (
          <MenuItem onClick={handleTemporaryModeChange}>
            <ListItemIcon>
              <TemporaryMode />
            </ListItemIcon>
            <ListItemText>{T.TEMPORARY_MODE}</ListItemText>
            <HHSwitchIconField isChecked={isTemporaryModeActive} type="TemporaryMode" />
          </MenuItem>
        )}
        {isMapView && (
          <MenuItem onClick={toggleIsDriverPathActive}>
            <ListItemIcon>
              <DriverPath />
            </ListItemIcon>
            <ListItemText>{T.DRIVER_PATH}</ListItemText>
            <HHSwitchIconField isChecked={isDriverPathActive} type="DriverPath" />
          </MenuItem>
        )}
        {isMapView && (
          <MenuItem onClick={toggleIsSequenceLineActive}>
            <ListItemIcon>
              <SequenceLine />
            </ListItemIcon>
            <ListItemText>{T.SEQUENCE_LINE}</ListItemText>
            <HHSwitchIconField isChecked={isSequenceLineActive} type="SequenceLine" />
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenRouteRecoveryDialog}>
          <ListItemIcon>
            <Recover />
          </ListItemIcon>
          <ListItemText>{T.ROUTE_RECOVERY}</ListItemText>
        </MenuItem>
      </Menu>
      <TemporaryModeDialog
        open={isTemporaryDialogOpen}
        onClose={handleCloseTemporaryDialog}
        onConfirm={handleConfirmTemporaryDialog}
        onCancel={handleCancelTemporaryDialog}
      />
      {isRouteRecoveryDialogOpen && (
        <RouteRecoveryDialog
          open={isRouteRecoveryDialogOpen}
          onClose={handleCloseRouteRecoveryDialog}
          onCancel={handleCancelRouteRecoveryDialog}
          onConfirm={handleConfirmRouteRecoveryDialog}
        />
      )}
    </>
  )
}

RouteManagerActionsMenu.propTypes = {
  sx: PropTypes.object,
  onUnscheduledRouteChange: PropTypes.func.isRequired,
  updateRoute: PropTypes.func.isRequired,
}

export default RouteManagerActionsMenu
