import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CenteredListItem from 'components/common/CenteredListItem'
import IconButton from '@mui/material/IconButton'
import { ListItemText, Popover, SvgIcon, useTheme } from '@mui/material'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'
import { DateCalendar } from '@mui/x-date-pickers'

const DateColumnTitle = ({ title, value, onChange, shouldDisableDate, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = date => {
    onChange(date)
    handleClose()
  }

  return (
    <>
      <CenteredListItem
        secondaryAction={
          <>
            <IconButton disabled={disabled} onClick={handleClick}>
              <SvgIcon>
                <Calendar />
              </SvgIcon>
            </IconButton>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              anchorEl={anchorEl}
              sx={{
                zIndex: theme.zIndex.snackbar + 5,
                border: `1px solid ${theme.palette.divider}`,
              }}
              onClose={handleClose}
            >
              <DateCalendar value={value} onChange={handleChange} shouldDisableDate={shouldDisableDate} />
            </Popover>
          </>
        }
        sx={{
          '.MuiListItemSecondaryAction-root': {
            height: 40,
          },
        }}
      >
        <ListItemText primaryTypographyProps={{ variant: 'h4', fontWeight: 600 }} primary={title} />
      </CenteredListItem>
    </>
  )
}

DateColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  shouldDisableDate: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DateColumnTitle
