import { createSelector } from 'reselect'
import { groupBy, orderBy } from 'lodash'
import { EMAIL_TYPE } from 'settings/constants/email'

const { ACCOUNT_INVOICE_AVAILABLE } = EMAIL_TYPE

export const getInvoicesByStatus = createSelector([state => state?.invoices], (invoices = []) => {
  const sortedInvoices = orderBy(invoices, ['invoiceDate'], ['desc'])
  return groupBy(sortedInvoices, 'status')
})

export const getInvoicesFromEmailEvents = createSelector([state => state?.emailEvents], (emailEvents = []) => {
  const invoiceEvents = emailEvents.filter(event => event?.invoiceNumber && event?.emailType === ACCOUNT_INVOICE_AVAILABLE)
  return orderBy(invoiceEvents, ['timestamp'], ['desc'])
})
