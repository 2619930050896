import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addCustomerBillingProfile: build.mutation({
      query: body => ({
        url: '/api/settings/customer/billing/profile/add',
        method: 'POST',
        body,
      }),
    }),
    getCustomerBillingProfileById: build.query({
      query: ({ id }) => ({
        url: '/api/settings/customer/billing/profile/info',
        method: 'POST',
        body: { id },
      }),
    }),
    getCustomerBillingProfileList: build.query({
      query: body => ({
        url: '/api/settings/customer/billing/profile/list',
        method: 'POST',
        body,
      }),
    }),
    getServicesCountBillingProfile: build.query({
      query: ({ id }) => {
        return { url: `api/v1/core/accounts/billing/profiles/${id}/count` }
      },
    }),
    deleteCustomerBillingProfile: build.mutation({
      query: ({ id }) => {
        return { url: `api/settings/customer/billing/profile/${id}`, method: 'DELETE' }
      },
    }),
  }),
})

export const {
  useAddCustomerBillingProfileMutation,
  useLazyGetCustomerBillingProfileListQuery,
  useLazyGetCustomerBillingProfileByIdQuery,
  useLazyGetServicesCountBillingProfileQuery,
  useDeleteCustomerBillingProfileMutation,
} = extendedApi
