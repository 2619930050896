import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'

import { IconButton, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import T from 'T'
import { REGEX } from 'utils/validations'
import { HHFormTextField } from 'components/form-fields/v5'

const EmailSearchField = ({ onEnter, onSearch, onChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const hasError = Boolean(errors?.email)
  const helperText = hasError && 'Incomplete email'

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onEnter()
    }
  }

  return (
    <HHFormTextField
      onKeyDown={onKeyDown}
      autoFocus
      onChange={onChange}
      sx={{ mt: 1, '& .MuiInputBase-root': { pr: 0 } }}
      deprecatedLabel={false}
      control={control}
      error={hasError}
      helperText={helperText}
      name="email"
      rules={{ pattern: REGEX.IS_EMAIL }}
      label={T.EMAIL}
      placeholder={T.SEARCH_EMAIL}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

EmailSearchField.propTypes = {
  onEnter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EmailSearchField
