import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from '@mui/material'

import RentalFeeListItem from 'components/pricing/RentalFees/common/field/RentalFeeListItem'

const RenderCell = ({ row, isSelectable = false }) => {
  return (
    <Tooltip arrow title="Rental fee is already connected to this service" placement="top" disableHoverListener={isSelectable}>
      <RentalFeeListItem rentalFee={row} />
    </Tooltip>
  )
}

RenderCell.propTypes = {
  row: PropTypes.object,
  isSelectable: PropTypes.bool,
}

export default RenderCell
