import api from 'api'
import { CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'

export const extendApiSlice = api.injectEndpoints({
  endpoints: build => ({
    getRoutesMetadataByServiceDate: build.query({
      query: ({ serviceDate }) => ({
        url: `/api/meta/route/${serviceDate}`,
        method: 'POST',
      }),
      providesTags: (result, error, { serviceDate }) => [
        CACHE_TAG_ROUTES_METADATA_BY_DATE,
        { type: CACHE_TAG_ROUTES_METADATA_BY_DATE, id: serviceDate },
      ],
    }),
  }),
})
export const { useLazyGetRoutesMetadataByServiceDateQuery } = extendApiSlice
