import React from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { grey } from '@mui/material/colors'
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, InputAdornment, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import CloseIcon from '@mui/icons-material/Close'

import { get } from 'utils/lodash'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

const AccountRepDropdown = ({ csrId = '', onChange }) => {
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const allCsrs = get(customerMeta, 'csrs', [])

  return (
    <HHTextField
      deprecatedLabel={false}
      label={T.ACCOUNT_REP}
      fullWidth
      select
      value={[csrId]}
      onChange={event => onChange(event.target.value)}
      InputProps={{
        endAdornment: csrId ? (
          <InputAdornment position="start">
            <IconButton onClick={() => onChange('')}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    >
      <SelectItem value="">Please select</SelectItem>
      {allCsrs.map(csr => (
        <SelectItem value={csr.id}>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: grey[100],
                  }}
                >
                  <PersonIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${get(csr, 'firstName', '')} ${get(csr, 'lastName', '')}`.trim()} />
            </ListItem>
          </List>
        </SelectItem>
      ))}
    </HHTextField>
  )
}

AccountRepDropdown.propTypes = {
  csrId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default AccountRepDropdown
