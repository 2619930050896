import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import SortByFilterMenu from 'components/customers/accounts/filters/SortByFilter/SortByFilterMenu'
import noop from 'lodash/noop'

const SortByFilterMenuField = ({ sx, control, name, onChange = noop, isMenuItemVariant = false, anchorOrigin, transformOrigin }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onChangeHookForm, value } }) => {
        const handleChange = value => {
          onChange(value)
          onChangeHookForm(value)
        }
        return (
          <SortByFilterMenu
            sx={sx}
            onChange={handleChange}
            defaultSortBy={value}
            isMenuItemVariant={isMenuItemVariant}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          />
        )
      }}
    />
  )
}

SortByFilterMenuField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isMenuItemVariant: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  onChange: PropTypes.func,
}

export default SortByFilterMenuField
