import React from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Dialog, DialogContent } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import T from 'T'
import CancelButton from 'components/buttons/CancelButton'
import Button from '@mui/material/Button'
import UniqueUrlDialogContent from 'components/customer-details/content/customer-portal/UniqueUrlDialogContent'

const UniqueUrlDialog = ({ open = false, url, title = '', label = '', description = '', onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <UniqueUrlDialogContent url={url} label={label} description={description} />
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <Button variant="contained" color="warning" size="small" onClick={onClose}>
          Close
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

UniqueUrlDialog.propTypes = {
  open: PropTypes.bool,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
}

export default UniqueUrlDialog
