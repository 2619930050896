// Currently we are hiding many parameters, so created a separate file for the table.
// Later adjust with main pricing component and refactor it
// import TableBody from 'components/pricing/TableBody';
// import TableHeader from 'components/pricing/TableHeader';

import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

import { Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, useMediaQuery, useTheme } from '@mui/material'

import { DotsVertical } from '@styled-icons/heroicons-solid/DotsVertical'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getPricingPeriod } from 'utils/service'
import { PRICING_PAGINATION } from 'settings/constants/pagination'
import { useUpdateServiceGroupMutation } from 'api/priced-service-group/updateServiceGroup'

import T from 'T'
import Menu from 'components/common/Menu'
import PricingPagination from 'components/pricing/PricingPagination'

import { SERVICE_GROUPS_CONFIGURATOR } from './settings/serviceGroups'
import { SERVICE_GROUP_DETAILS_CONFIGURATOR } from './settings/serviceGroupDetails'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION
const MENU_ITEMS = [{ title: T.DEACTIVATE, icon: 'Deactivate.svg' }]

const ServiceGroupsTable = ({
  isDetailsView = false,
  serviceGroupsList = [],
  page = INITIAL_PAGE,
  rowsPerPage = ROWS_PER_PAGE,
  totalTableRowsCount = 0,
  totalPageCount = 0,
  currentSelectedGroup,
  onPageChange,
  onRowsPerPageChange,
  onRowClick,
  onReloadDetailsTable,
}) => {
  const [updateServiceGroup, { isSuccess: isDeactivateSuccess }] = useUpdateServiceGroupMutation()

  const [isOpenDeactivationMenu, setIsOpenDeactivationMenu] = useState(false)
  const [deactivateId, setDeactivateId] = useState('')

  const allColumns = isDetailsView ? SERVICE_GROUP_DETAILS_CONFIGURATOR : SERVICE_GROUPS_CONFIGURATOR
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const getTableColumnContent = (currentRow, subType) => {
    switch (subType) {
      case 'price':
        return (
          <NumericFormat
            value={get(currentRow, 'value', 0)}
            displayType="text"
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
          />
        )
      case 'measure':
        return get(currentRow, 'measure.unit') ? `${get(currentRow, 'measure.volume')} ${get(currentRow, 'measure.unit')}` : ''
      case 'period':
        return getPricingPeriod(get(currentRow, 'periodPer'), get(currentRow, 'periodInterval'))
      default:
        return ''
    }
  }

  const handleDeactivateService = () => {
    setIsOpenDeactivationMenu(false)
    const payload = { id: get(currentSelectedGroup, 'id'), deletedServices: [{ id: deactivateId }] }
    updateServiceGroup(payload)
  }

  useEffect(() => {
    if (isDeactivateSuccess) {
      onReloadDetailsTable()
    }
  }, [isDeactivateSuccess])

  const renderTableHeader = columns =>
    Children.toArray(
      columns.map(column => (
        <TableCell align={column.align}>
          <div className="inline-flex items-center wrapper wrapper-filter-sort">
            <span>{column.tableLabel}</span>
          </div>
        </TableCell>
      ))
    )

  return (
    <>
      <TableContainer
        sx={{
          height: isTabletOrMobile ? 'calc(100vh - 450px)' : 'calc(100vh - 250px)',
        }}
        className="pricing-table"
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            '& tbody tr': {
              paddingLeft: 16,
              paddingRight: 16,
              height: 48,
            },
          }}
        >
          <TableHead className="table-header">
            <TableRow className="service-groups-table-row">
              {renderTableHeader(allColumns)}
              {isDetailsView && <TableCell padding="checkbox" className="three-dots" />}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {Children.toArray(
              serviceGroupsList.map(service => {
                return (
                  <TableRow
                    className="service-groups-table-row cursor-pointer"
                    onClick={() => {
                      if (!isDetailsView) {
                        // For main row listing click, open details view
                        onRowClick(service)
                      }
                    }}
                  >
                    {Children.toArray(
                      allColumns.map(column => {
                        const className = get(column, 'className', '')
                        const columnName = get(column, 'columnName', '')
                        const subType = get(column, 'subType', '')
                        return (
                          <TableCell className={className}>
                            <div className="price-cell">
                              {subType ? getTableColumnContent(service, subType) : get(service, columnName, '')}
                            </div>
                          </TableCell>
                        )
                      })
                    )}

                    {isDetailsView && (
                      <TableCell className="expand">
                        <span className="expandable-menu">
                          <Box
                            className="dot-menu"
                            onClick={event => {
                              setDeactivateId(service.id)
                              setIsOpenDeactivationMenu(event.currentTarget)
                            }}
                          >
                            <DotsVertical className="dots-vertical" />
                          </Box>
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })
            )}

            {(!serviceGroupsList || serviceGroupsList.length === 0) && (
              <TableRow>
                <TableCell colspan="100%" className="no-border">
                  <div className="no-results">No Record Found</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isDetailsView && (
        <PricingPagination
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}

      <Menu
        id="price-menu-deactivate"
        anchorEl={isOpenDeactivationMenu}
        open={Boolean(isOpenDeactivationMenu)}
        menuitems={MENU_ITEMS}
        onClose={() => setIsOpenDeactivationMenu(false)}
        onClick={handleDeactivateService}
      />
    </>
  )
}

ServiceGroupsTable.propTypes = {
  isDetailsView: PropTypes.bool,
  serviceGroupsList: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  currentSelectedGroup: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onReloadDetailsTable: PropTypes.func.isRequired,
}

export default memo(ServiceGroupsTable)
