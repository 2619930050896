import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import capitalize from 'lodash/capitalize'

import { Grid } from '@mui/material'

import { HHFormTextField } from 'components/form-fields/v5'

import T from 'T'
import { DEFAULT_ADDRESS_FIELD_KEY, DEFAULT_STATE } from './model'

const { cityPlaceholder, statePlaceholder, zipCodePlaceholder } = DEFAULT_STATE

const AddressRowTwo = ({ fieldPrefix = DEFAULT_ADDRESS_FIELD_KEY, control, onKeyDown = noop }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HHFormTextField
          control={control}
          name={`${fieldPrefix}.city`}
          label={T.CITY}
          placeholder={cityPlaceholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={onKeyDown}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HHFormTextField
          control={control}
          name={`${fieldPrefix}.state`}
          label={T.STATE}
          placeholder={statePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={onKeyDown}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <HHFormTextField
          control={control}
          name={`${fieldPrefix}.zipCode`}
          label={capitalize(T.ZIP_CODE)}
          placeholder={zipCodePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={onKeyDown}
        />
      </Grid>
    </Grid>
  )
}

AddressRowTwo.propTypes = {
  fieldPrefix: PropTypes.string,
  control: PropTypes.object.isRequired,
  onKeyDown: PropTypes.func,
}

export default AddressRowTwo
