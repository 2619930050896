const ZONES = {
  // New zones
  NEW_YORK: 'America/New_York',
  CHICAGO: 'America/Chicago',
  DENVER: 'America/Denver',
  LOS_ANGELES: 'America/Los_Angeles',
  ANCHORAGE: 'America/Anchorage',
  HONOLULU: 'Pacific/Honolulu',
  PHOENIX: 'America/Phoenix',
  // Old zones
  MENOMINEE: 'America/Menominee',
  COSTA_RICA: 'America/Costa_Rica',
  DAWSON: 'America/Dawson',
  JUNEAU: 'America/Juneau',
  ATKA: 'America/Atka',
  HAWAII: 'US/Hawaii',
}

const { NEW_YORK, CHICAGO, DENVER, LOS_ANGELES, ANCHORAGE, HONOLULU, PHOENIX, MENOMINEE, COSTA_RICA, DAWSON, JUNEAU, ATKA, HAWAII } = ZONES

export const TIME_ZONES = [
  { key: NEW_YORK, value: 'Eastern Time (ET)' }, // Handles EST/EDT
  { key: CHICAGO, value: 'Central Time (CT)' }, // Handles CST/CDT
  { key: DENVER, value: 'Mountain Time (MT)' }, // Handles MST/MDT
  { key: PHOENIX, value: 'Mountain Standard Time (MST, no DST)' }, // Arizona (no DST)
  { key: LOS_ANGELES, value: 'Pacific Time (PT)' }, // Handles PST/PDT
  { key: ANCHORAGE, value: 'Alaska Time (AKT)' }, // Handles AKST/AKDT

  { key: HONOLULU, value: 'Hawaii-Aleutian Standard Time (HST)' }, // No DST
  { key: COSTA_RICA, value: 'Central Standard Time (CST, no DST)' }, // No DST
]

export const OLD_NEW_TIMEZONE_LINKING = new Map([
  [MENOMINEE, NEW_YORK],
  [COSTA_RICA, CHICAGO],
  [DAWSON, DENVER],
  [JUNEAU, LOS_ANGELES],
  [ATKA, ANCHORAGE],
  [HAWAII, HONOLULU],
])

export const TIME_ZONES_SHORT_FORM = {
  // New
  [NEW_YORK]: 'EST',
  [CHICAGO]: 'CST',
  [DENVER]: 'MST',
  [LOS_ANGELES]: 'PST',
  [ANCHORAGE]: 'AKST',
  [HONOLULU]: 'HST',

  // Old, some abbr maybe wrong b/c earlier we were binding using the offset +9, +10 etc.
  [MENOMINEE]: 'EST',
  [COSTA_RICA]: 'CST',
  [DAWSON]: 'MST',
  [JUNEAU]: 'PST',
  [ATKA]: 'AKST',
  [HAWAII]: 'HST',
}
