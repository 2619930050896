import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import { formatDateToBEFormatDateFns } from 'utils/date'
import { useSuspendAccountMutation } from 'api/accounts/suspensionsCrud'
import T from 'T'
import { handleError } from 'utils/error'
import { toast } from 'react-toastify'
import GenericSuspendAccountDialog from 'components/customer-details/content/suspensions/SuspendAccountDialog/GenericSuspendAccountDialog'
import { SUSPENSION_FORM_MODEL } from 'components/customer-details/content/suspensions/SuspendAccountDialog/settings'

const SuspendAccountDialog = ({ accountId, open = false, onClose = noop, onSave = noop }) => {
  const dispatch = useDispatch()
  const [suspend, { isLoading: isSuspendLoading }] = useSuspendAccountMutation()

  const formMethods = useForm({ defaultValues: SUSPENSION_FORM_MODEL })
  const { handleSubmit, reset } = formMethods
  const handleClose = () => {
    onClose()
    reset(SUSPENSION_FORM_MODEL)
  }

  const sendMutation = async ({ suspensionType, description, startDate: rawStartDate }) =>
    suspend({ suspensionType, description, accountId, startDate: formatDateToBEFormatDateFns(rawStartDate) }).unwrap()

  const onSubmit = async data =>
    sendMutation(data)
      .then(() => {
        onSave()
        dispatch(getCustomerDetails({ accountId }))
        reset(SUSPENSION_FORM_MODEL)
        toast.success(T.ACCOUNT_SUSPENDED_SUCCESSFULLY)
        onClose()
      })
      .catch(handleError)

  return (
    <FormProvider {...formMethods}>
      <GenericSuspendAccountDialog open={open} onClose={handleClose} onSave={handleSubmit(onSubmit)} isSaveLoading={isSuspendLoading} />
    </FormProvider>
  )
}

SuspendAccountDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default SuspendAccountDialog
