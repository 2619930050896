import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import RouteCard, { formatRouteData } from 'containers/new-route-manager/RouteCard/RouteCard'
import WithUnassigedRouteCheckbox from 'components/route-manager/UnassigedList/WithUnassigedRouteCheckbox'
import { selectRowsForBulkAssign, setStopDetailsDialogState } from 'slices/route/routeSlice'

const UnassignedListItem = ({ workOrder, onTagsSave = noop }) => {
  const {
    accountId,
    accountName,
    action,
    address,
    locationId,
    measureUnit,
    measureLabel,
    pricedServiceType,
    tags,
    workOrderId,
    serviceLocationName,
  } = formatRouteData(workOrder)
  const isOpenBulkAssign = useSelector(s => s.Route.bulkAssign.isOpen)
  const dispatch = useDispatch()
  const selectedRoutes = useSelector(s => s.Route.bulkAssign.selectedRows, shallowEqual)
  const value = selectedRoutes.map(({ workOrderId }) => workOrderId).includes(workOrderId)

  const handleChange = event => {
    const { checked } = event.target
    if (checked) {
      dispatch(selectRowsForBulkAssign([...selectedRoutes, workOrder]))
    } else {
      const newSelectedRows = selectedRoutes.filter(({ workOrderId: woId }) => woId !== workOrderId)
      dispatch(selectRowsForBulkAssign(newSelectedRows))
    }
  }

  const handleServiceActionChipClick = () => dispatch(setStopDetailsDialogState({ id: workOrderId }))

  return (
    <WithUnassigedRouteCheckbox workOrder={workOrder} enable={isOpenBulkAssign} checked={value} onChange={handleChange}>
      <Box onDoubleClick={handleServiceActionChipClick}>
        <RouteCard
          pricedServiceType={pricedServiceType}
          sx={{ mx: 0 }}
          accountId={accountId}
          locationId={locationId}
          accountName={accountName}
          address={address}
          measureLabel={measureLabel}
          measureUnit={measureUnit}
          tags={tags}
          serviceLocationName={serviceLocationName}
          hasRouteCircle={false}
          onTagsSave={onTagsSave}
          workOrderId={workOrderId}
          action={action}
        />
      </Box>
    </WithUnassigedRouteCheckbox>
  )
}

UnassignedListItem.propTypes = {
  workOrder: PropTypes.object,
  onTagsSave: PropTypes.func,
}

export default UnassignedListItem
