import { useCallback } from 'react'
import {
  combineAndRecolorMarkers,
  combineAndResetPositionRows,
  filterAndRepositionUnselectedRows,
} from 'components/route-manager/BulkActions/settings'
import { get, partition } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useDispatch } from 'react-redux'
import useTheme from '@mui/material/styles/useTheme'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import useParseDaysOfWeekBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/useParseDaysOfWeekBetweenDaysVariant'

const useBulkMoveSelectedRowsBetweenDaysVariant = ({
  bulkReduxAction,
  fromSerializedDate,
  moveFromRouteStopMarkers,
  moveFromRows,
  moveToRouteSequenceLine,
  moveToRouteStopMarkers,
  moveToRows,
  routeId,
  routesById,
  toSerializedDate,
}) => {
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const parseDaysOfWeek = useParseDaysOfWeekBetweenDaysVariant(fromDate, fromSerializedDate, toSerializedDate)
  const dispatch = useDispatch()
  const { getValues, setValue } = useFormContext()
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const theme = useTheme()
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  return useCallback(() => {
    const values = getValues()
    const selectedRows = Array.from(selectedRowIdsMap.values()).map(r => {
      const daysOfWeek = get(r, 'daysOfWeek', '')
      const pricingPeriod = get(r, 'pricingPeriod')
      return { ...r, parsedDaysOfWeek: parseDaysOfWeek(daysOfWeek, pricingPeriod) }
    })
    const unselectedMoveToRows = filterAndRepositionUnselectedRows(moveToRows, selectedRowIdsMap)
    const updatedMoveFromRows = combineAndResetPositionRows(moveFromRows, selectedRows)
    const [selectedMoveToMarkers, unselectedMoveToMarkers] = partition(moveToRouteStopMarkers, feature =>
      selectedRowIdsMap.has(feature.properties.id)
    )
    const updatedMoveFromMarkers = combineAndRecolorMarkers(moveFromRouteStopMarkers, selectedMoveToMarkers, routesById, routeId, theme)
    const unselectedMoveToSequences = moveToRouteSequenceLine.geometry.coordinates.filter(
      (coord, index) => !selectedRowIdsMap.has(moveToRows[index].id)
    )
    const updatedMoveFromSequences = updatedMoveFromRows.map(({ latitude, longitude }) => {
      return [longitude, latitude]
    })
    const updatedMoveFromRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: updatedMoveFromSequences },
    }
    const unselectedMoveToRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: unselectedMoveToSequences },
    }
    const updatedMoveToRows = []
    const updatedMoveToMarkers = []
    const toBeInsertedRowsIds = []
    let currentPosition = 0
    for (let i = 0; i < unselectedMoveToRows.length; i++) {
      const rowItem = { ...unselectedMoveToRows[i] }
      const markerItem = { ...unselectedMoveToMarkers[i], properties: { ...unselectedMoveToMarkers[i].properties } }
      const rowId = get(rowItem, 'id')
      const date = get(rowItem, 'date')
      if (date === toFEDate) {
        rowItem.position = currentPosition
        markerItem.properties.position = currentPosition + 1
        currentPosition += 1
      } else {
        const rowTypedPosition = get(values, rowId, '')
        toBeInsertedRowsIds.push(rowId)
        if (rowTypedPosition && rowTypedPosition !== currentPosition + 1) {
          setValue(rowId, currentPosition + 1)
        }
        rowItem.position = rowTypedPosition ? currentPosition : ''
        markerItem.properties.position = rowTypedPosition ? currentPosition + 1 : ''
        if (rowTypedPosition !== '') {
          currentPosition += 1
        }
      }
      updatedMoveToRows.push(rowItem)
      updatedMoveToMarkers.push(markerItem)
    }
    dispatch(
      bulkReduxAction({
        moveToRows: updatedMoveToRows,
        moveFromRows: updatedMoveFromRows,
        moveFromRouteStopMarkers: updatedMoveFromMarkers,
        moveToRouteStopMarkers: updatedMoveToMarkers,
        moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
        moveToRouteSequenceLine: unselectedMoveToRouteSequenceLine,
        toBeInsertedRowsIds,
      })
    )
    apiRef.current.selectRows([])
  }, [
    fromSerializedDate,
    moveFromRouteStopMarkers,
    moveFromRows,
    moveToRouteSequenceLine,
    moveToRouteStopMarkers,
    moveToRows,
    routeId,
    routesById,
    toSerializedDate,
  ])
}

useBulkMoveSelectedRowsBetweenDaysVariant.propTypes = {}

export default useBulkMoveSelectedRowsBetweenDaysVariant
