import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'

import { memo } from 'utils/react'
import { getFilterContent } from 'data/service-groups/filterSelectors'
import { getServiceFilterAutoCompleteLabel } from 'utils/service'
import { CommonTextLabel, CommonTextfield, CommonSelect } from 'components/common'

import T from 'T'
import Constants from 'Constants'
import AutoComplete from 'components/common/AutoComplete'
import { useTheme } from '@mui/material'

const { MEDIA_SERVER } = Constants

const ServiceGroupFilterContent = ({
  isEditMode = false,
  name = '',
  filter = {},
  measureMeta = [],
  materialsMeta = [],
  methodsMeta = [],
  serviceActionMeta = [],
  globalFilterOptions = {},
  onChange,
}) => {
  const theme = useTheme()
  const {
    businessLinesMeta,
    pricingZonesMeta,
    businessLine,
    pricingZoneId,
    search,
    measureIds,
    materialIds,
    serviceMethodIds,
    serviceActionIds,
  } = getFilterContent({ globalFilterOptions, filter })

  const renderAutoCompleteField = (type, label, key, meta, selectedIds) => {
    return (
      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={label} />
        <AutoComplete
          variant="contained"
          isHandleCheckbox
          maxHeight={150}
          metaData={meta}
          label={label}
          getByLabelText={option => getServiceFilterAutoCompleteLabel(type, option)}
          handleCheckboxValue={(_, result) => onChange(key, result, 'filterModel')}
          checkedRecords={selectedIds}
          getPlaceholder={selectedIds.length ? '' : `Select ${label}`}
        />
      </Box>
    )
  }

  return (
    <>
      {!isEditMode && (
        <Box mt={2} className="common autocomplete">
          <CommonTextLabel title={T.SERVICE_GROUP} isRequired />
          <CommonTextfield
            defaultSize
            placeholder={T.SERVICE_GROUP}
            value={name}
            onChange={event => onChange('name', event.target.value)}
          />
        </Box>
      )}

      <Box
        sx={{
          '& .common.select': {
            background: theme.palette.background.paper,
          },
        }}
        mt={2}
        className="common autocomplete"
      >
        <CommonTextLabel title={T.BUSINESS_LINE} isRequired />
        <CommonSelect
          disabled={isEditMode}
          className="common-service-select"
          sx={{
            ...(isEditMode && {
              // Only required in edit mode
              '& .MuiSelect-selectMenu, .MuiSelect-select': {
                cursor: 'not-allowed',
              },
            }),
          }}
          showEmpty={businessLinesMeta.length === 0}
          emptyText="No Business Line Available"
          placeholder={`Select ${T.BUSINESS_LINE}`}
          value={businessLine}
          options={businessLinesMeta.map(line => line.businessLineName)}
          // options={businessLinesMeta.map(line => ({ key: line.id, value: line.businessLineName }))}
          onChange={e => onChange('businessLine', e.target.value, 'filterModel', 'filters')}
          menuProps={{ getContentAnchorEl: null }}
        />
      </Box>

      <Box
        mt={2}
        sx={{
          '& .common.select': {
            background: theme.palette.background.paper,
          },
        }}
        className="common autocomplete"
      >
        <CommonTextLabel title={T.PRICING_ZONE} />
        <CommonSelect
          disabled={isEditMode}
          sx={{
            ...(isEditMode && {
              '& .MuiSelect-selectMenu, .MuiSelect-select': {
                cursor: 'not-allowed',
              },
            }),
          }}
          className="common-service-select"
          showEmpty={pricingZonesMeta.length === 0}
          emptyText="No Pricing Zone Available"
          placeholder={`Select ${T.PRICING_ZONE}`}
          value={pricingZoneId}
          options={pricingZonesMeta.map(pricingZone => ({ key: pricingZone.id, value: pricingZone.zoneName }))}
          onChange={e => onChange('pricingZoneId', e.target.value, 'filterModel')}
          menuProps={{ getContentAnchorEl: null }}
        />
      </Box>

      <Divider sx={{ mt: 2 }} />

      <Box mt={2}>
        <CommonTextfield
          startAdornment={
            <InputAdornment position="start">
              <img src={`${MEDIA_SERVER}Customer-Search.svg`} alt="search-icon" />
            </InputAdornment>
          }
          placeholder={T.SEARCH_SERVICES}
          value={search}
          onChange={e => onChange('search', e.target.value, 'filterModel')}
        />
      </Box>

      {/* We can also create a separate <renderAutoCompleteField /> component */}
      {renderAutoCompleteField(T.MEASURE, T.MEASURE, 'measureIds', measureMeta, measureIds)}
      {renderAutoCompleteField(T.MATERIAL, `${T.MATERIAL}s`, 'materialIds', materialsMeta, materialIds)}
      {renderAutoCompleteField(T.METHOD, T.METHOD, 'serviceMethodIds', methodsMeta, serviceMethodIds)}
      {renderAutoCompleteField(T.ACTION, `${T.ACTION}s`, 'serviceActionIds', serviceActionMeta, serviceActionIds)}
    </>
  )
}

ServiceGroupFilterContent.propTypes = {
  isEditMode: PropTypes.bool,
  name: PropTypes.string,
  filter: PropTypes.object,
  measureMeta: PropTypes.array,
  materialsMeta: PropTypes.array,
  methodsMeta: PropTypes.array,
  serviceActionMeta: PropTypes.array,
  globalFilterOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(ServiceGroupFilterContent)
