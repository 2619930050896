import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import Constants from 'Constants'

import { canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { memo } from 'utils/react'
import { Typography, Card, CardMedia, CardContent, Box, styled } from '@mui/material'
import T from 'T'

const { MEDIA_SERVER } = Constants

const StyledCardContent = styled(CardContent)(() => ({
  padding: '12px 20px',

  '&:last-child': {
    paddingBottom: 12,
  },
}))

const PostCardLayout = ({ postState = {}, setPostState = noop }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const handleLayoutSelection = val => {
    if (updateSettings) {
      setPostState({ postcardLayout: val, footerFlag: true })
    }
  }

  const StyledCard = (media, title, isSelected, type) => {
    return (
      <Card
        sx={{
          mt: 1.5,
          minWidth: 275,
          cursor: 'pointer',
          border: theme => (isSelected ? `2px solid ${theme.palette.primary.main}` : ''),
          borderRadius: 1,
          boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)',
        }}
        onClick={() => handleLayoutSelection(type)}
      >
        <CardMedia component="img" height="140" src={`${MEDIA_SERVER}${media}`} alt="" />
        <StyledCardContent>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </StyledCardContent>
      </Card>
    )
  }

  return (
    <Box>
      <Typography variant="body1">{T.POSTCARD_LAYOUT}</Typography>

      {StyledCard('ThreeFoldPostCard.svg', `3 ${T.POSTCARDS_ON_PAGE}`, postState.postcardLayout === 3, 3)}
      {StyledCard('FourFoldPostCard.svg', `4 ${T.POSTCARDS_ON_PAGE}`, postState.postcardLayout === 4, 4)}
    </Box>
  )
}

PostCardLayout.propTypes = {
  postState: PropTypes.object,
  setPostState: PropTypes.func,
}

export default memo(PostCardLayout)
