import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Chip, SvgIcon, useTheme } from '@mui/material'
import { blue, yellow } from '@mui/material/colors'
import { Phone } from '@styled-icons/heroicons-outline/Phone'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'

import { get } from 'utils/lodash'
import { RECURRENCE, RECURRENCE_LABELS } from 'settings/constants/service'
import { ALPHA_38_PERCENT } from 'theme/colors'

const { RECURRING, ON_REQUEST } = RECURRENCE

const SERVICE_TYPE_TO_SVG = {
  [RECURRING]: Calendar,
  [ON_REQUEST]: Phone,
}

const SERVICE_TYPE_TO_COLOR_MAP = {
  [RECURRING]: { backgroundColor: blue[100], borderColor: blue[500] },
  [ON_REQUEST]: { backgroundColor: yellow[100], borderColor: yellow[500] },
}

const ServiceTypeChip = ({ serviceType = '' }) => {
  const theme = useTheme()
  const Icon = get(SERVICE_TYPE_TO_SVG, serviceType)
  const { backgroundColor, borderColor } = get(SERVICE_TYPE_TO_COLOR_MAP, serviceType, {})

  return serviceType ? (
    <Chip
      size="small"
      variant="outlined"
      avatar={
        Icon ? (
          <Avatar
            sx={{
              backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}`,
            }}
          >
            <SvgIcon inheritViewBox sx={{ fontSize: 12, color: theme.palette.background.paper }}>
              <Icon />
            </SvgIcon>
          </Avatar>
        ) : null
      }
      label={get(RECURRENCE_LABELS, serviceType, '')}
      sx={{ ...(backgroundColor && { backgroundColor }), ...(borderColor && { border: '1px solid', borderColor }) }}
    />
  ) : (
    ''
  )
}

ServiceTypeChip.propTypes = {
  serviceType: PropTypes.string,
}

export default ServiceTypeChip
