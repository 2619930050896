import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { getInvoiceMetaSlice } from 'api/billing/getBillingInvoiceMeta'

export const getCurrentTableParams = createSelector([state => state?.tableData], (tableData = {}) => {
  const { tableRows = [], totalItems, totalPages } = tableData
  return { allTableRows: tableRows, totalTableRowsCount: totalItems, totalPageCount: totalPages }
})

const allInvoiceMetaSlice = getInvoiceMetaSlice
export const getMetadataInvoice = createSelector(allInvoiceMetaSlice, (invoiceMetadata = {}) => get(invoiceMetadata, 'data', []))
