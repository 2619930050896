import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBusinessLineConfiguration: build.query({
      query: () => ({
        url: '/api/settings/business-line/config',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useLazyGetBusinessLineConfigurationQuery } = extendedApi
