import endpoint from 'middleware/sagas/endpoint'

const GLOBAL_SEARCH_FILTER_URL_RESULT_PAIR = new Map([
  ['account', { url: endpoint.account.filter, resultKey: 'accountResults' }],
  ['contact', { url: endpoint.contact.filter, resultKey: 'contactResults' }],
  ['pricedService', { url: endpoint.pricing.service.filter, resultKey: 'pricedServiceResults' }],
  ['configuredService', { url: endpoint.configuredService.filter, resultKey: 'configuredServiceResults' }],
  ['notes', { url: endpoint.notes.filter, resultKey: 'notesResults' }],
  ['followUp', { url: endpoint.followUp.filter, resultKey: 'followUpResults' }],
  ['workOrder', { url: endpoint.workOrder.filter, resultKey: 'workOrderResults' }],
])

export const getGlobalSearchFilterRequestPair = type => GLOBAL_SEARCH_FILTER_URL_RESULT_PAIR.get(type)
