import { memo as reactMemo, useRef, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

// Memo function
// - to reduce component rerender time
// Compare previous state & new state
export const memo = Component => reactMemo(Component, isEqual)

export const usePreviousValue = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
