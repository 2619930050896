import { useFormContext } from 'react-hook-form'
import { useLazyGetLocationListQuery } from 'api/accounts/getRecentAndSearchAndSuggestedServices'
import { useLazyFetchFocusedServicesQuery } from 'api/accounts/getFocusedSearchServices'
import { useCallback, useMemo } from 'react'
import {
  ADDRESS_SEARCH_TYPE,
  CONTACT_NAME_SEARCH_TYPE,
  CONTAINER_ID_SEARCH_TYPE,
  PHONE_SEARCH_TYPE,
  SEARCH_TYPE_VALUE_TO_SEARCH_KEY,
} from 'components/locations/filters/Search/settings'

export const useLazyDebouncedSearchLocationOptionsQuery = () => {
  const { watch } = useFormContext()
  const searchType = watch('searchType')

  const lazyGetLocationListQuery = useLazyGetLocationListQuery()
  const lazyFetchFocusedServicesQuery = useLazyFetchFocusedServicesQuery()
  const isFocusedSearch = useMemo(
    () => [ADDRESS_SEARCH_TYPE, PHONE_SEARCH_TYPE, CONTACT_NAME_SEARCH_TYPE, CONTAINER_ID_SEARCH_TYPE].includes(searchType),
    [searchType]
  )
  const [fetchLocationOptions, fetchLocationOptionsQueryState] = useMemo(
    () => (isFocusedSearch ? lazyFetchFocusedServicesQuery : lazyGetLocationListQuery),
    [isFocusedSearch, lazyFetchFocusedServicesQuery, lazyGetLocationListQuery]
  )

  const searchLocations = useCallback(
    ({ searchInputValue, includeDeactivatedLocations }) => {
      if (!searchInputValue) return Promise.resolve()
      const payload = {
        page: 0,
        size: 10,
        includeDeactivatedLocations,
        ...(isFocusedSearch
          ? {
              lookups: [{ text: searchInputValue, type: SEARCH_TYPE_VALUE_TO_SEARCH_KEY[searchType] }],
            }
          : { search: searchInputValue }),
      }
      return fetchLocationOptions(payload)
    },
    [searchType, isFocusedSearch, fetchLocationOptions]
  )

  return [searchLocations, fetchLocationOptionsQueryState]
}
