import React, { memo } from 'react'
import { HHAlert } from 'components/common/HHAlert'
import { orange } from '@mui/material/colors'
import { Box, Typography, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectBulkResequenceRoutesById } from 'slices/route/bulkResequenceStopsSlice'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { formatDateToFEFormatDateFns } from 'utils/date'
import PropTypes from 'prop-types'

const PermanentMoveAlert = ({ toBeMovedRowsCount = 0 }) => {
  const { watch } = useFormContext()
  const theme = useTheme()
  const serviceDate = watch('serviceDate')
  const routeId = watch('routeId')
  const routesById = useSelector(selectBulkResequenceRoutesById)
  const routeName = get(routesById, [routeId, 'name'])
  const serviceDateFEFormat = formatDateToFEFormatDateFns(serviceDate)
  return (
    <HHAlert
      severity="warning"
      sx={{
        ...theme.typography.body1,
        borderColor: orange[500],
        borderWidth: 1,
        borderStyle: 'solid',
        pr: theme.spacing(3),
      }}
    >
      <Typography>
        You’re about to permanently change the route sequence for
        <Box component="span" fontWeight={600}>
          {` ${toBeMovedRowsCount} `}
        </Box>
        {toBeMovedRowsCount > 1 ? 'services' : 'service'} on
        <Box component="span" fontWeight={600}>{` ${routeName} `}</Box>
        on
        <Box component="span" fontWeight={600}>{` ${serviceDateFEFormat} `}</Box>
      </Typography>
    </HHAlert>
  )
}

PermanentMoveAlert.propTypes = {
  toBeMovedRowsCount: PropTypes.number,
}

export default memo(PermanentMoveAlert)
