import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { orange } from '@mui/material/colors'
import { getViewMode } from '../../../data/route/viewModeSelector'
import { VIEW_MODE_MAP, VIEW_MODE_SEQUENCE } from '../../../slices/route/routeSlice'

export const AntTabs = styled(Tabs)(({ theme, isTemporaryModeActive }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    backgroundColor: isTemporaryModeActive ? orange[500] : theme.palette.primary,
  },
}))

export const AntTab = styled(Tab)(({ theme, isTemporaryModeActive }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  '&.MuiTab-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.38)',
  '&:hover': {
    color: isTemporaryModeActive ? theme.palette.warning.light : theme.palette.primary.light,
    opacity: 1,
  },
  '&.Mui-selected': {
    color: isTemporaryModeActive ? orange[500] : theme.palette.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.common.white,
  },
}))

const RouteManagerTabs = ({ onChange }) => {
  const viewMode = useSelector(getViewMode)
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)

  return (
    <AntTabs isTemporaryModeActive={isTemporaryModeActive} sx={{ pl: 2 }} value={viewMode} onChange={onChange}>
      <AntTab isTemporaryModeActive={isTemporaryModeActive} label="Board" value={VIEW_MODE_SEQUENCE} />
      <AntTab isTemporaryModeActive={isTemporaryModeActive} label="Map" value={VIEW_MODE_MAP} />
    </AntTabs>
  )
}

RouteManagerTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default RouteManagerTabs
