import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import StopPositionLabel from 'components/route-manager/BulkActions/common/StopListItem/StopPositionLabel'

const ToBeMovedStopPreviewCell = ({ accountName, addressStr, position, showOriginalPosition = false, originalPosition }) => {
  const positionLabel = useMemo(
    () => (showOriginalPosition ? [originalPosition, position].join(' to ') : position),
    [showOriginalPosition, originalPosition, position]
  )

  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemAvatar sx={{ minWidth: 'unset' }}>
        <StopPositionLabel position={positionLabel} />
      </ListItemAvatar>
      <ListItemText
        primary={accountName}
        secondary={addressStr}
        primaryTypographyProps={{ variant: 'h6', fontWeight: 500, color: 'textPrimary' }}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
}

ToBeMovedStopPreviewCell.propTypes = {
  accountName: PropTypes.string,
  addressStr: PropTypes.string,
  position: PropTypes.string,
  showOriginalPosition: PropTypes.bool,
  originalPosition: PropTypes.string,
}

export default memo(ToBeMovedStopPreviewCell)
