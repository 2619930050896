import React, { useEffect, useMemo, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Button, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'
import Typography from '@mui/material/Typography'
import { SORT_BY_DEFAULT_VALUE, SORT_BY_OPTIONS } from 'components/customers/accounts/settings'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

const SortByFilterMenu = ({
  onChange = noop,
  defaultSortBy = SORT_BY_DEFAULT_VALUE,
  isMenuItemVariant = false,
  anchorOrigin,
  transformOrigin,
  sx,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const open = Boolean(anchorEl)
  const selectedOption = useMemo(() => SORT_BY_OPTIONS.find(option => option.value === sortBy), [sortBy])
  const selectedOptionLabel = get(selectedOption, 'label')
  const SelectedOptionIcon = get(selectedOption, 'icon')
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, value) => {
    onChange(value)
    setSortBy(value)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (sortBy !== defaultSortBy) {
      onChange(defaultSortBy)
      setSortBy(defaultSortBy)
    }
  }, [defaultSortBy])

  return (
    <>
      {isMenuItemVariant && (
        <MenuItem sx={sx} onClick={handleClickListItem}>
          <ListItemIcon>
            <SvgIcon>
              <SelectedOptionIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>{selectedOptionLabel}</ListItemText>
        </MenuItem>
      )}
      {!isMenuItemVariant && (
        <Button
          startIcon={
            <SvgIcon>
              <SelectedOptionIcon />
            </SvgIcon>
          }
          variant="text"
          onClick={handleClickListItem}
        >
          {selectedOptionLabel}
        </Button>
      )}
      <Menu
        keepMounted
        disablePortal
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {SORT_BY_OPTIONS.map(option => {
          const label = get(option, 'label')
          const value = get(option, 'value')
          const Icon = get(option, 'icon')
          return (
            <MenuItem option={label} selected={value === sortBy} onClick={event => handleMenuItemClick(event, value)}>
              <ListItemIcon>
                <SvgIcon>
                  <Icon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
              <Typography variant="h6" fontWeight={500} />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

SortByFilterMenu.propTypes = {
  onChange: PropTypes.func,
  defaultSortBy: PropTypes.string,
  isMenuItemVariant: PropTypes.bool,
  transformOrigin: PropTypes.object,
  anchorOrigin: PropTypes.object,
  sx: PropTypes.object,
}

export default SortByFilterMenu
