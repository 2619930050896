import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import HHTextField from './HHTextField'

const HHDesktopDatePicker = ({
  label = '',
  value = '',
  disabled = false,
  TextFieldProps = {},
  PopperProps = {},
  onChange = noop,
  restSlots = {},
  restSlotProps = {},
  ...rest
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const onOpen = () => setIsCalendarOpen(true)
  const onClose = () => setIsCalendarOpen(false)
  return (
    <DesktopDatePicker
      label={label}
      open={isCalendarOpen}
      onOpen={onOpen}
      onClose={onClose}
      value={value}
      disabled={disabled}
      onChange={onChange}
      slots={{ textField: HHTextField, ...restSlots }}
      slotProps={{
        textField: { onClick: onOpen, fullWidth: true, ...TextFieldProps },
        popper: { ...PopperProps },
        ...restSlotProps,
      }}
      {...rest}
    />
  )
}

HHDesktopDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  PopperProps: PropTypes.object,
  restSlots: PropTypes.object,
  restSlotProps: PropTypes.object,
  onChange: PropTypes.func,
}

export default HHDesktopDatePicker
