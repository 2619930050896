import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import cloneDeep from 'lodash/cloneDeep'

import { NumericFormat } from 'react-number-format'

import Table from 'components/pricing/Taxes/Table'
import SecondaryNavBar from 'components/pricing/SecondaryNavBar'
import NewTaxModal from 'components/pricing/Taxes/NewTaxModal'
import LinkServiceModal from 'components/pricing/LinkedService/LinkServiceModal'

import { getIntervalName } from 'components/pricing/pricingMethods'
import { useLazyGetTaxLinkListQuery } from 'api/pricing/getTaxLinkList'

import Loader from 'components/common/loader'

import { PRICING_PAGINATION } from 'settings/constants/pagination'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { isPriceTypeDollar } from 'utils/service'
import { canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual } from 'react-redux'
import { handleError } from 'utils/error'

import 'containers/pricing/style.scss'
import { DOLLAR_DECIMAL_DIGITS, PERCENTAGE_DECIMAL_DIGITS } from 'settings/constants/pricing'
import PricingWrapper from 'components/pricing/common/drawer/PricingWrapper'
import { Box } from '@mui/material'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const link = {}

const TaxesPanel = ({
  allCheckedVal,
  allFilters,
  allSortBy,
  anchorEl,
  changePrice,
  checkedVal,
  columns,
  confServices,
  configData,
  feeTab,
  getTaxServicesResults,
  handleActivation,
  handleAllChecked,
  handleChangePriceDelete,
  handleClose,
  handleConfChange,
  handleExport,
  handleOnDragEnd,
  handlePageChange,
  handleRowsPerPageChange,
  handleSingleChange,
  handleSingleChecked,
  handleTabChange,
  isDirty,
  lockedColumns,
  onHandleEditFieldsChange,
  onSortByChange,
  onTableFilterChange,
  page,
  rowsPerPage,
  saveConfSettings,
  saveConfigSettings,
  selectedEditValues,
  setAnchorEl,
  setSelectedEditValues,
  setState,
  summary,
  tabValue,
  taxServiceList,
  totalTaxCount,
  totalTaxPageCount,
}) => {
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [taxScreen, setTaxScreen] = useState('initial')
  const [openLinkTaxDrawer, setOpenLinkTaxDrawer] = useState(false)
  const [taxName, setTaxName] = useState('')
  const [autoLink, setAutoLink] = useState(false)

  const [getTaxLinkList, { isFetching }] = useLazyGetTaxLinkListQuery()

  const [taxState, setTaxState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    id: '',
    linkAll: {},
    linkVal: {},
    tax: [],
    generalFee: [],
    disposalFee: [],
    services: [],
  })

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpenDrawer(open)
    setTaxScreen('initial')
  }

  const getMeasure = (measure_vol, measure_unit) => {
    if (measure_vol > 0) {
      return `${measure_vol} ${measure_unit}`
    }
    return measure_unit
  }

  const getValue = (taxValType, value) => {
    if (value === '') return ''
    if (isPriceTypeDollar(taxValType)) {
      return (
        <NumericFormat
          value={value}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={DOLLAR_DECIMAL_DIGITS}
          fixedDecimalScale
        />
      )
    }
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        suffix="%"
        decimalSeparator="."
        decimalScale={PERCENTAGE_DECIMAL_DIGITS}
      />
    )
  }

  const getTaxServiceName = (service, column, type) => {
    const serviceName = get(service, 'taxName', '')
    const material = get(service, 'material.materialType', '')
    const businessLine = get(service, 'businessLine', '')
    const taxZone = get(service, 'taxZone.zoneName', '')
    const measure_vol = get(service, 'measure.volume', '')
    const measure_unit = get(service, 'measure.unit', '')
    const value = get(service, 'taxValue', '')
    const pricingPeriodPer = get(service, 'period.periodPer', '')
    const pricingPeriodInterval = get(service, 'period.periodInterval', '')

    const scope = get(service, 'taxScope', '')
    const taxValueType = get(service, 'taxValueType', '')
    const active = get(service, 'active', '')
    const nextPriceUpdate = get(service, 'nextPriceUpdate', '')
    const lastPriceUpdate = get(service, 'lastPriceUpdate', '')
    const action = get(service, 'serviceAction.actionName', '')
    const exception = get(service, 'exceptionType', '')
    const priority = get(service, 'priority', '')
    const activeLocations = get(service, 'activeLocationCount', '')

    if (column === undefined) return ''

    const records = {}
    records.Tax = serviceName
    records.Scope = scope
    records.Material = material
    records['Business Line'] = businessLine
    records['Tax Zone'] = taxZone
    records.Action = action
    records.Exception = exception
    records.Priority = priority

    records.Measure = getMeasure(measure_vol, measure_unit)

    let valName = 'Value'

    if (type === 'create') valName = 'taxValueType'

    records[valName] = value !== '' && value !== 0 ? `${value}` : ''

    if (type === 'create') {
      records[valName] = value
    } else {
      records[valName] = getValue(taxValueType, records[valName])
    }

    records.Status = active ? 'Active' : 'Deactivated'

    records['Next Value Update'] = ''
    records['Last Value Update'] = ''

    if (nextPriceUpdate !== '')
      records['Next Value Update'] = (
        <NumericFormat
          value={nextPriceUpdate}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={DOLLAR_DECIMAL_DIGITS}
          fixedDecimalScale
        />
      )

    if (lastPriceUpdate !== '')
      records['Last Value Update'] = (
        <NumericFormat
          value={lastPriceUpdate}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={DOLLAR_DECIMAL_DIGITS}
          fixedDecimalScale
        />
      )

    records['Active Locations'] = activeLocations

    if (pricingPeriodPer > 1) records.Period = `${pricingPeriodPer} ${pricingPeriodInterval}s`
    else records.Period = getIntervalName(pricingPeriodInterval)
    return records[column]
  }

  const handleChangePrice = () => {
    const checkedIds = Object.entries(get(checkedVal, 'taxes', [])).map(([key, value]) => (value ? key : null))
    setSelectedEditValues(checkedIds.map(data => (data = taxServiceList.find(tax => tax.id === data))))
    setTaxScreen('edit-taxes')
    setOpenDrawer(true)
  }

  const toggleLinkTaxDrawer = (open, service) => {
    const rawTaxName = get(service, 'taxName', '')
    const businessLine = get(service, 'businessLine', '')
    const zoneName = get(service, 'taxZone.zoneName', '')

    setOpenLinkTaxDrawer(open)

    setTaxName([rawTaxName, businessLine, zoneName].filter(Boolean).join(' - '))

    const payload = {
      id: service.id,
    }

    getTaxLinkList(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        const services = get(res, 'services', [])
        const generalFeeAndCharges = get(res, 'generalFeeAndCharges', [])
        const disposalFeeAndCharges = get(res, 'disposalFeeAndCharges', [])

        services.map(data => {
          data.linkedVal = data.linked
        })

        generalFeeAndCharges.map(data => {
          data.linkedVal = data.linked
        })

        disposalFeeAndCharges.map(data => {
          data.linkedVal = data.linked
        })

        setTaxState({
          id: service.id,
          tax: get(res, 'tax', []),
          generalFee: generalFeeAndCharges,
          disposalFee: disposalFeeAndCharges,
          services,
        })
        const autoLinkVal = service.autoLink

        setAutoLink(autoLinkVal)
      })
      .catch(handleError)

    setAnchorEl(null)
  }

  const handleTaxSwitchVal = (val, id, type) => {
    if (!updatePricingManager) return

    setState({ isDirty: true })

    if (type === 'service') {
      type = 'services'
    }

    if (id === 'all' && taxState[type].length > 0) {
      taxState[type].map(data => {
        data.linkedVal = val
      })

      setTaxState(taxState)
      if (taxState[type].filter(data => data.linkedVal).length === taxState[type].length) {
        link[type] = true
        setTaxState({ linkAll: link })
      } else {
        link[type] = false
        setTaxState({ linkAll: link })
      }
      return
    }

    const taxRecord = taxState[type].find(data => data.id === id)

    if (taxRecord !== undefined) {
      taxRecord.linkedVal = val
    }

    setTaxState({ taxState })
    if (taxState[type].filter(data => data.linkedVal).length !== taxState[type].length) {
      link[type] = false
    }
  }

  const handleAutoLink = val => {
    if (!updatePricingManager) return

    setState({ isDirty: true })
    setAutoLink(val)
  }

  return (
    <>
      {isFetching && <Loader />}
      <PricingWrapper className="panel-description tax-panel-container">
        <SecondaryNavBar
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          summary={summary}
          feeTab={feeTab}
          handleConfChange={handleConfChange}
          saveConfigSettings={saveConfigSettings}
          handleOnDragEnd={handleOnDragEnd}
          handleClose={handleClose}
          configData={configData}
          handleExport={handleExport}
          changePrice={changePrice}
          toggleDrawer={toggleDrawer}
          handleSingleChange={handleSingleChange}
          tab="taxes"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleChangePrice={handleChangePrice}
        />

        <Box className="panel-table" width="100%" height="100%" overflow="auto">
          <Table
            serviceList={taxServiceList}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            totalTaxCount={totalTaxCount}
            totalTaxPageCount={totalTaxPageCount}
            saveConfSettings={saveConfSettings}
            confServices={confServices}
            handleAllChecked={handleAllChecked}
            handleSingleChecked={handleSingleChecked}
            checkedVal={checkedVal}
            allCheckedVal={allCheckedVal}
            columns={columns}
            lockedColumns={lockedColumns}
            getTaxServiceName={getTaxServiceName}
            type="taxes"
            toggleLinkTaxDrawer={toggleLinkTaxDrawer}
            handleActivation={handleActivation}
            allFilters={allFilters}
            allSortBy={allSortBy}
            onTableFilterChange={onTableFilterChange}
            onSortByChange={onSortByChange}
          />
        </Box>
      </PricingWrapper>
      <NewTaxModal
        page={page}
        rowsPerPage={rowsPerPage}
        taxScreen={taxScreen}
        setTaxScreen={setTaxScreen}
        handleChangePriceDelete={handleChangePriceDelete}
        onHandleEditFieldsChange={onHandleEditFieldsChange}
        selectedEditValues={selectedEditValues}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        setOpenDrawer={setOpenDrawer}
        getTaxServicesResults={getTaxServicesResults}
        getTaxServiceName={getTaxServiceName}
        isDirty={isDirty}
        setState={setState}
        confServices={columns}
      />

      <LinkServiceModal
        tab="tax"
        page={page}
        isDirty={isDirty}
        rowsPerPage={rowsPerPage}
        serviceName={taxName}
        setState={setState}
        serviceList={taxState}
        getResults={getTaxServicesResults}
        openLinkServiceDrawer={openLinkTaxDrawer}
        setOpenLinkServiceDrawer={setOpenLinkTaxDrawer}
        toggleLinkServiceDrawer={toggleLinkTaxDrawer}
        handleSwitchVal={handleTaxSwitchVal}
        autoLink={autoLink}
        handleAutoLink={handleAutoLink}
      />
    </>
  )
}

TaxesPanel.defaultProps = {
  allCheckedVal: {},
  allFilters: {},
  allSortBy: {},
  anchorEl: '',
  changePrice: {},
  checkedVal: {},
  columns: [],
  confServices: [],
  configData: [],
  getTaxServicesResults: null,
  handleActivation: null,
  handleAllChecked: null,
  handleChangePriceDelete: null,
  handleClose: null,
  handleConfChange: null,
  handleOnDragEnd: null,
  handlePageChange: null,
  handleRowsPerPageChange: null,
  handleSingleChange: null,
  handleSingleChecked: null,
  isDirty: false,
  lockedColumns: [],
  onHandleEditFieldsChange: null,
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  saveConfSettings: false,
  saveConfigSettings: null,
  selectedEditValues: [],
  setAnchorEl: null,
  setSelectedEditValues: null,
  setState: null,
  taxServiceList: [],
  totalTaxCount: INITIAL_PAGE,
  totalTaxPageCount: INITIAL_PAGE,
}

TaxesPanel.propTypes = {
  allCheckedVal: PropTypes.object,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  anchorEl: PropTypes.string,
  changePrice: PropTypes.object,
  checkedVal: PropTypes.object,
  columns: PropTypes.array,
  confServices: PropTypes.array,
  configData: PropTypes.array,
  getTaxServicesResults: PropTypes.func,
  handleActivation: PropTypes.func,
  handleAllChecked: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  handleClose: PropTypes.func,
  handleConfChange: PropTypes.func,
  handleExport: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  handleSingleChange: PropTypes.func,
  handleSingleChecked: PropTypes.func,
  isDirty: PropTypes.bool,
  lockedColumns: PropTypes.array,
  onHandleEditFieldsChange: PropTypes.func,
  onSortByChange: PropTypes.func.isRequired,
  onTableFilterChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  saveConfSettings: PropTypes.bool,
  saveConfigSettings: PropTypes.func,
  selectedEditValues: PropTypes.array,
  setAnchorEl: PropTypes.func,
  setSelectedEditValues: PropTypes.func,
  setState: PropTypes.func,
  taxServiceList: PropTypes.array,
  totalTaxCount: PropTypes.number,
  totalTaxPageCount: PropTypes.number,
}

export default memo(TaxesPanel)
