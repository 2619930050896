import React, { useReducer, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Button, Box, Typography } from '@mui/material'
import T from 'T'
import { get } from 'utils/lodash'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { useLazyGetYardListQuery } from 'api/settings/getYardList'
import { useLazyGetYardByIdQuery } from 'api/settings/getYardById'
import { useAddYardMutation } from 'api/settings/addYard'
import ListYards from './yards/ListYards'
import AddYards from './yards/AddYards'
import { handleError } from '../../../../utils/error'

const YardsSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [addNewYard, setAddNewYard] = useState(false)

  const YARDS_INITIAL_VALUES = {
    yardName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    footerFlag: false,
    active: true,
    yardId: '',
    addressId: '',
    longitude: '',
    latitude: '',
  }

  const [yardState, setYardState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    yards: [],
    ...YARDS_INITIAL_VALUES,
  })

  const [getYardList, { isLoading, isFetching }] = useLazyGetYardListQuery()
  const [getYardById] = useLazyGetYardByIdQuery()
  const [addYard] = useAddYardMutation()
  const { yards, yardId, yardName, streetAddress, city, state, country, zipCode, active, addressId, longitude, latitude } = yardState

  const fetchYards = () => {
    getYardList()
      .then(data => {
        setYardState({
          yards: get(data, 'data.yard', []),
          ...YARDS_INITIAL_VALUES,
        })
      })
      .catch(() => {
        setYardState({
          yards: [],
        })
      })
  }

  useEffect(() => {
    fetchYards()
  }, [])

  const handleAdd = () => {
    setAddNewYard(true)
  }

  const handleSave = () => {
    const payload = {
      name: yardName,
      address: {
        line1: streetAddress,
        city,
        state,
        country,
        zipCode,
        longitude,
        latitude,
      },
      active,
    }

    if (yardId !== '') {
      payload.address.id = addressId
      payload.id = yardId
    }
    setYardState({ footerFlag: false })
    addYard(payload)
      .unwrap()
      .then(res => {
        if (get(res, 'id', '') !== '') {
          setAddNewYard(false)
          fetchYards()
        }
      })
      .catch(handleError)
  }

  const handleEdit = id => {
    if (updateSettings) {
      setAddNewYard(true)
      setYardState({
        yardId: id,
      })

      const payload = {
        id,
      }

      getYardById(payload)
        .unwrap()
        .then(data => {
          setYardState({
            yardName: get(data, 'name', ''),
            streetAddress: get(data, 'address.line1', ''),
            city: get(data, 'address.city', ''),
            state: get(data, 'address.state', ''),
            zipCode: get(data, 'address.zipCode', ''),
            country: get(data, 'address.country', ''),
            footerFlag: false,
            active: get(data, 'active', true),
            yardId: get(data, 'id', ''),
            addressId: get(data, 'address.id', ''),
          })
        })
        .catch(handleError)
    }
  }

  const handleCancel = () => {
    setAddNewYard(false)
    fetchYards()
  }

  const onHandleNameChange = event => {
    const { name } = event.target

    let { value } = event.target

    if (/^\s/.test(value)) {
      value = ''
    }

    yardState[name] = value
    setYardState({ [name]: value, footerFlag: true })
  }

  return (
    <Box minWidth={770}>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewYard ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCancel()}
            >
              {`${T.YARD}s`}
            </Typography>

            {addNewYard && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${yardId === '' ? T.NEW : T.EDIT} ${T.YARD}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNewYard && addSettings && (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>

        {addNewYard && updateSettings && (
          <AddYards
            yardState={yardState}
            setYardState={setYardState}
            handleSave={handleSave}
            handleCancel={handleCancel}
            onHandleNameChange={onHandleNameChange}
          />
        )}
      </Box>

      {!addNewYard && <ListYards isLoading={isLoading || isFetching} yards={Array.isArray(yards) ? yards : []} handleEdit={handleEdit} />}
    </Box>
  )
}

export default YardsSettings
