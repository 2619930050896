export const EDIT_ACCOUNT_INITIAL_STATE = {
  accountName: '',
  billingAddress: {
    attention: '',
    line1: '',
    unitNumber: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  },
  tags: [],
  accountBilling: {
    customerPurchaseOrder: false,
    po: '',
    maxPoAmount: '',
    taxExempt: '',
    taxExemptEnable: false,
  },
}
