import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getSuggestedAccounts: builder.mutation({
      query: body => ({
        url: '/api/account/suggest',
        method: 'POST',
        body,
      }),
      transformResponse: data => (Array.isArray(data?.accounts) ? data : []),
    }),
  }),
})

export const { useGetSuggestedAccountsMutation } = extendedApi
