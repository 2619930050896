import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { HHFormAutocompleteField } from '../../../form-fields/v5'
import T from '../../../../T'

const GeneralLedgerAutocompleteFormField = ({ sx, control, name, options, loading = false }) => {
  const getOptionLabel = option => get(option, 'accountName', '')

  return (
    <HHFormAutocompleteField
      sx={sx}
      fullWidth
      options={options}
      loading={loading}
      name={name}
      control={control}
      label={T.GENERAL_LEDGER_ACCOUNT}
      deprecatedLabel={false}
      getOptionLabel={getOptionLabel}
    />
  )
}

GeneralLedgerAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export default GeneralLedgerAutocompleteFormField
