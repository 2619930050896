import { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { deserializeDate, formatDateToBEFormat } from '../../../../utils/date'

export const useGetPayload = () => {
  const serializedServiceDateRange = useSelector(state => state.workOrdersReview.serializedServiceDateRange, shallowEqual)
  const serviceDateRange = serializedServiceDateRange.map(deserializeDate)
  const filters = useSelector(state => state.workOrdersReview.filters, shallowEqual)
  const isServiceDateRangeValid = useSelector(state => state.workOrdersReview.isServiceDateRangeValid, shallowEqual)
  const serviceTypeList = useSelector(state => state.workOrdersReview.serviceTypeList, shallowEqual)

  return useCallback(
    ({ id }) => ({
      routeId: id,
      serviceDateStart: formatDateToBEFormat(serviceDateRange[0]),
      serviceDateEnd: formatDateToBEFormat(serviceDateRange[1]),
      actionTypes: filters,
      serviceTypes: serviceTypeList,
    }),
    [serializedServiceDateRange, filters, serviceTypeList, isServiceDateRangeValid]
  )
}
