import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, MenuList, Popper, SvgIcon, MenuItem, Grow, Paper, ClickAwayListener } from '@mui/material'
import { ReactComponent as ListTree } from 'assets/ListTree.svg'
import T from 'T'
import useTheme from '@mui/material/styles/useTheme'
import { useDispatch } from 'react-redux'
import { setBulkMoveStopsState } from 'slices/route/bulkMoveBetweenRoutesSlice'
import BulkMoveStopsBetweenRoutesDialog from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/BulkMoveStopsBetweenRoutesDialog'
import { setBulkAssignStopsState } from 'slices/route/bulkAssignStopsSlice'
import BulkAssignStopsDialog from 'components/route-manager/BulkActions/BulkAssignStopsDialog/BulkAssignStopsDialog'
import { setBulkMoveBetweenDaysState } from 'slices/route/bulkMoveBetweenDaysSlice'
import BulkMoveStopsBetweenDaysDialog from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/BulkMoveStopsBetweenDaysDialog'
import { setBulkUnassignStopsState } from 'slices/route/bulkUnassignStopsSlice'
import BulkUnassignStopsDialog from 'components/route-manager/BulkActions/BulkUnassignStopsDialog/BulkUnassignStopsDialog'
import { setBulkResequenceStopsState } from 'slices/route/bulkResequenceStopsSlice'
import BulkResequenceStopsDialog from 'components/route-manager/BulkActions/BulkResequenceStopsDialog/BulkResequenceStopsDialog'
import { setPermanentRecurrenceChangeState } from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import BulkPermanentRecurrenceChangeDialog from 'components/route-manager/BulkActions/BulkPermanentRecurrenceChangeDialog/BulkPermanentRecurrenceChangeDialog'

const BulkActionMenu = ({ sx }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const dispatch = useDispatch()

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const onMoveBetweenRoutesClick = () => {
    dispatch(
      setBulkMoveStopsState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  const onAddUnassignedClick = () => {
    dispatch(
      setBulkAssignStopsState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  const onMoveBetweenDaysClick = () => {
    dispatch(
      setBulkMoveBetweenDaysState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  const onBulkUnassignClick = () => {
    dispatch(
      setBulkUnassignStopsState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  const onChangeRouteSequenceClick = () => {
    dispatch(
      setBulkResequenceStopsState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  const onPermanentChangeRecurrence = () => {
    dispatch(
      setPermanentRecurrenceChangeState({
        isOpen: false,
        isSelectDateDialogOpen: true,
      })
    )
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup sx={{ ...sx }} ref={anchorRef} variant="contained">
        <Button onClick={handleToggle}>{T.BULK}</Button>
        <Button onClick={handleToggle}>
          <SvgIcon>
            <ListTree />
          </SvgIcon>
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: theme.zIndex.tooltip,
        }}
        open={open}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={onAddUnassignedClick}>{T.ASSIGN_STOPS}</MenuItem>
                  <MenuItem onClick={onBulkUnassignClick}>{T.UNASSIGN_STOPS}</MenuItem>
                  <MenuItem onClick={onMoveBetweenRoutesClick}>{T.MOVE_BETWEEN_ROUTES}</MenuItem>
                  <MenuItem onClick={onMoveBetweenDaysClick}>{T.MOVE_BETWEEN_DAYS}</MenuItem>
                  <MenuItem onClick={onChangeRouteSequenceClick}>{T.CHANGE_ROUTE_SEQUENCE}</MenuItem>
                  <MenuItem onClick={onPermanentChangeRecurrence}>{T.PERMANENT_CHANGE_RECURRENCE_DAY}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <BulkMoveStopsBetweenRoutesDialog />
      <BulkAssignStopsDialog />
      <BulkMoveStopsBetweenDaysDialog />
      <BulkUnassignStopsDialog />
      <BulkResequenceStopsDialog />
      <BulkPermanentRecurrenceChangeDialog />
    </>
  )
}

BulkActionMenu.propTypes = {
  sx: PropTypes.object,
}

export default BulkActionMenu
