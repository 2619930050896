import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import T from 'T'
import { initiateStatusDownload } from '../../../middleware/actions/download'
import PdfViewer from '../../drawer/pdf-viewer/PdfViewer'

const StatementDrawer = ({ open, accountId, onClose }) => {
  const dispatch = useDispatch()
  const [pdfData, setPdfdata] = useState(null)

  useEffect(() => {
    if (!accountId) return
    dispatch(
      initiateStatusDownload({ accountId: [accountId], urlToMapWith: 'accountReceivableTransactionPDF' }, (status, pdfData) => {
        if (status && pdfData) {
          setPdfdata(pdfData)
        } else if (status && !pdfData) {
          toast.error('Invoice details not found')
        }
      })
    )
  }, [accountId])

  return (
    <PdfViewer
      open={open}
      type="statement"
      title={T.STATEMENT}
      fileName="AccountReceivable.pdf"
      pdfData={pdfData}
      onClose={() => {
        setPdfdata(undefined)
        onClose()
      }}
    />
  )
}

StatementDrawer.propTypes = {
  open: PropTypes.bool,
  accountId: PropTypes.string,
  onClose: PropTypes.func,
}

export default StatementDrawer
