export const scrollToSelectedRowInDataGrid = (apiRef, targetIndex = 0, rowHeight) => {
  if (targetIndex && targetIndex > 1) {
    apiRef?.current?.scroll({ top: targetIndex * rowHeight - rowHeight })
  } else {
    apiRef?.current?.scrollToIndexes({ rowIndex: 0 })
  }
}

export const handleMUIPreventCellKeyDown = (param, event, api, key = 'id') => {
  if (param?.field === key && event?.target?.tagName === 'INPUT') {
    event.defaultMuiPrevented = true
  }
}

// This is temporary, upgrade the overflow to have dataGrid height 100%
export const dataGridHeightFixes = {
  sx: { '& .MuiDataGrid-main': { height: '1px' } },
}
