import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MentionsInput, Mention } from 'react-mentions'
import { PaperClip } from '@styled-icons/heroicons-outline/PaperClip'
import { AtSymbol } from '@styled-icons/heroicons-outline/AtSymbol'
import { Trash } from '@styled-icons/heroicons-outline/Trash'
import { toast } from 'react-toastify'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isImage } from 'utils/file'
import { getUserSearchResult } from 'middleware/actions/customers'
import { USER_SEARCH_PAGINATION } from 'settings/constants/pagination'

import User from 'components/user'
import endpoint from 'middleware/sagas/endpoint'

import './mention-style.scss'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'

const { INITIAL_PAGE } = USER_SEARCH_PAGINATION

const AdditionalNote = ({ note, index, onChange, addAdditionalFollowUps }) => {
  const additionalNoteRef = useRef()
  const dispatch = useDispatch()

  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const [isEmpty, setIsEmpty] = useState(true)
  const [allMentions, setAllMentions] = useState([])

  const maxFileUploadSize = get(userInfo, 'maxFileUploadSize', '2MB')
  const noteImage = get(note, 'image', '')
  const actualNote = get(note, 'note', '')

  // Get assignee list
  const getMentionsList = (search, callback) => {
    dispatch(
      getUserSearchResult(
        {
          search,
          requestedPage: INITIAL_PAGE,
          requestedPageSize: 2,
        },
        (result = []) => {
          const transformResult = result.map(user => ({
            id: get(user, 'email', -1),
            display: `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`.trim(),
            user,
          }))
          setAllMentions([...allMentions, result].flat())
          setIsEmpty(transformResult.length === 0)
          callback(transformResult)
        }
      )
    )
  }

  const resetImage = event => {
    if (event) {
      event.target.value = null
    }
    additionalNoteRef.current.focus()
    setTimeout(() => {
      document.querySelector(`.div-editable.index-${index}`).classList.add('focus')
    }, 200)
  }

  return (
    <Box my={1} className="call-note">
      <div className="full-note text-area-add-container">
        <Stack justifyContent="space-between" className={`${noteImage ? 'has-image' : 'no-image'} div-editable index-${index}`}>
          <MentionsInput
            inputRef={additionalNoteRef}
            onFocus={() => document.querySelector(`.div-editable.index-${index}`).classList.add('focus')}
            onBlur={() => {
              document.querySelector(`.div-editable.index-${index}`).classList.remove('focus')
              addAdditionalFollowUps()
            }}
            name={`mention-${index}`}
            autoComplete="off"
            value={actualNote}
            onChange={(event, value) => {
              onChange('note', value, index)
            }}
            placeholder={`${index === 0 ? 'Note' : 'Add a note...'}`}
            className={`mentions element-${index} ${isEmpty ? 'empty-list' : 'filled-list'}`}
            // style={{ suggestions: { top: 'auto', left: 'auto' } }}
          >
            <Mention
              type="user"
              trigger="@"
              markup="[user/__id__]"
              data={(search, callback) => {
                if (search === '') {
                  setIsEmpty(true)

                  return [{ id: -1, display: 'Mention a teammate', disabled: true }]
                }

                return getMentionsList(search, callback)
              }}
              className="mentions__mention"
              renderSuggestion={(suggestion, search, highlightedDisplay) => {
                const userImage = get(suggestion, 'user.profilePicUri', '')
                const fName = get(suggestion, 'user.firstName', '')
                const lName = `${get(suggestion, 'user.lastName', '')}`
                const suggestionId = get(suggestion, 'id')

                return (
                  <>
                    {suggestionId === -1 && (
                      <Stack flexDirection="row" alignItems="center">
                        {highlightedDisplay}
                      </Stack>
                    )}
                    {suggestionId !== -1 && (
                      <Stack flexDirection="row" alignItems="center" className="user-list">
                        <User firstName={fName} lastName={lName} profilePicture={userImage} enableClick={false} uri={userImage} />
                        <div className="ml1">{highlightedDisplay}</div>
                      </Stack>
                    )}
                  </>
                )
              }}
              displayTransform={(id, display) => {
                const existingUserInfo = get(note, 'mentions', [])
                const findUser = existingUserInfo.find(user => user?.email === id) || allMentions.find(user => user?.email === id)
                const fName = `${get(findUser, 'firstName', '')} ${get(findUser, 'lastName', '')}`.trim()
                return `@${fName}`
              }}
            />
          </MentionsInput>

          {noteImage && (
            <Stack flexDirection="row" sx={{ mt: 1 }}>
              <img
                className="full-image"
                src={typeof noteImage === 'string' ? endpoint.followUp.noteImage + noteImage : URL.createObjectURL(noteImage)}
                alt="placeholder"
              />
            </Stack>
          )}

          <Box display="none" className="bottom-toolbar">
            {noteImage && (
              <Stack flexDirection="row" alignItems="center" className="mini-image">
                <img
                  className="db preview-image"
                  src={typeof noteImage === 'string' ? endpoint.followUp.noteImage + noteImage : URL.createObjectURL(noteImage)}
                  alt="placeholder"
                />
                <Trash className="trash-icon" onClick={() => onChange('image', '', index)} />
              </Stack>
            )}

            <Box mt={1} className="image-upload user-mentions">
              <label htmlFor={`file-input-${index}`}>
                <PaperClip />
              </label>

              <Box
                component="input"
                display="none"
                id={`file-input-${index}`}
                type="file"
                accept="image/*"
                onChange={event => {
                  const fileInput = event.target.files[0]

                  // Return if not an image
                  if (!isImage(fileInput.name)) {
                    toast.error('Error! Only JPG, JPEG & PNG files are allowed')
                    resetImage(event)
                    return
                  }

                  // convert to mb
                  if (fileInput.size / (1024 * 1024) > parseInt(maxFileUploadSize.replace(/[^0-9]/g, ''), 10)) {
                    toast.error(`Error! Please select image size less than ${maxFileUploadSize}`)
                    resetImage(event)
                    return
                  }

                  onChange('image', event.target.files[0], index)
                  resetImage(event)
                }}
              />

              <Box
                component="span"
                ml={1}
                onClick={() => {
                  let newNote = actualNote
                  if (newNote && newNote.slice(-1) !== ' ') {
                    newNote = `${actualNote} `
                  }
                  onChange('note', `${newNote}@`, index)
                  additionalNoteRef.current.focus()
                }}
              >
                <AtSymbol />
              </Box>
            </Box>
          </Box>
        </Stack>
      </div>
    </Box>
  )
}

AdditionalNote.defaultProps = {
  note: null,
  index: 0,
  addAdditionalFollowUps: null,
}

AdditionalNote.propTypes = {
  note: PropTypes.object,
  index: PropTypes.number,
  addAdditionalFollowUps: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default memo(AdditionalNote)
