import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import PeopleSettings from 'components/settings/pages/users/PeopleSettings'
import RolesSettings from 'components/settings/pages/users/RolesSettings'
import SkillsSettings from 'components/settings/pages/users/SkillsSettings'
import TeamsSettings from 'components/settings/pages/users/TeamsSettings'

import T from 'T'

import { canSeeUserSettings } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual } from 'react-redux'

const UserSettings = ({ userOption = '' }) => {
  const seeUserSettings = useSelector(canSeeUserSettings, shallowEqual)

  return (
    <Box>
      {seeUserSettings && userOption === T.PEOPLE && <PeopleSettings />}
      {userOption === `${T.ROLE}s` && <RolesSettings />}
      {userOption === `${T.TEAM}s` && <TeamsSettings />}
      {userOption === `${T.SKILL}s` && <SkillsSettings />}
    </Box>
  )
}

UserSettings.propTypes = {
  userOption: PropTypes.string,
}

export default UserSettings
