import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/lodash'

import { Menu as MuiMenu, MenuItem } from '@mui/material'
import T from '../../T'

const Menu = ({ id, anchorEl, open, menuitems, onClose, onClick }) => {
  return (
    <MuiMenu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
    >
      {Children.toArray(
        menuitems.map(menuItem => {
          const isActivate = menuItem.title === T.ACTIVATE
          const isDeactivate = menuItem.title === T.DEACTIVATE
          const sx = {
            ...(isActivate && { color: 'success.main' }),
            ...(isDeactivate && { color: 'error.main' }),
          }
          return (
            <MenuItem sx={sx} onClick={event => onClick(event, get(menuItem, 'route', ''))}>
              {menuItem.title}
            </MenuItem>
          )
        })
      )}
    </MuiMenu>
  )
}

Menu.defaultProps = {
  id: '',
  anchorEl: {},
  open: false,
  menuitems: [],
}

Menu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  menuitems: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Menu
