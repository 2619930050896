import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { NOTES_VISIBILITY } from 'settings/constants/customer'
import Box from '@mui/material/Box'
import T from 'T'
import Constants from 'Constants'
import BasicModal from 'components/modal/BasicModal'
import FooterButton from 'components/buttons/FooterButton'
import CommonSwitch from 'components/common/CommonSwitch'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Stack } from '@mui/material'

const { MEDIA_SERVER } = Constants

const SaveNotesModal = ({ isOpen, notesModalInput, pinned, visibilityType, onStateChange, onVisibilityChange, onAddEditNote, onClose }) => (
  <BasicModal isOpen={isOpen} onClose={onClose}>
    <div className="common-modal-style add-edit-notes">
      <div className="area">
        <HHDialogTitle title={T.NOTE} icon={<img src={`${MEDIA_SERVER}Clipboard.svg`} alt="" />} onClose={onClose} />
      </div>
      <div className="modal-body notes-body">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" className="visibility-type">
          <Stack flexDirection="row" className="switch">
            {/* Render all notes visibility */}
            {Children.toArray(
              NOTES_VISIBILITY.map(visibility => (
                <Box mr={2}>
                  <CommonSwitch
                    title={visibility}
                    isChecked={NOTES_VISIBILITY.includes(get(visibilityType, visibility))}
                    onChange={checked => onVisibilityChange(visibility, checked ? visibility : '')}
                  />
                </Box>
              ))
            )}
          </Stack>
          <Stack flexDirection="row" className="pin">
            <img src={`${MEDIA_SERVER}${pinned ? 'Pin' : 'Unpin'}.svg`} alt="" onClick={() => onStateChange('pinned', !pinned)} />
          </Stack>
        </Stack>
        <textarea
          value={notesModalInput}
          placeholder={T.NOTE}
          onClick={event => event.stopPropagation()}
          onChange={event => {
            onStateChange('notesModalInput', event.target.value)
          }}
        />
      </div>
      <div className="modal-footer notes-footer">
        <div>
          <FooterButton
            leftButtonTitle={T.CANCEL}
            rightButtonTitle={T.SAVE}
            onClose={onClose}
            onProceed={onAddEditNote}
            disabledProceed={!notesModalInput.trim()}
          />
        </div>
      </div>
    </div>
  </BasicModal>
)

SaveNotesModal.defaultProps = {
  isOpen: false,
  notesModalInput: '',
  pinned: false,
  visibilityType: null,
}

SaveNotesModal.propTypes = {
  isOpen: PropTypes.bool,
  notesModalInput: PropTypes.string,
  pinned: PropTypes.bool,
  visibilityType: PropTypes.object,
  onStateChange: PropTypes.func.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  onAddEditNote: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(SaveNotesModal)
