import React, { useReducer, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import FooterButton from 'components/buttons/FooterButton'
import { useLazyGetGeneralBillingQuery } from 'api/settings/getGeneralBilling'
import { useAddGeneralBillingMutation } from 'api/settings/addGeneralBilling'
import { handleError } from 'utils/error'
import T from 'T'
import Loader from 'components/common/loader'
import { get } from 'utils/lodash'

import PaymentTerms from './general/PaymentTerms'
import BillingCycle from './general/BillingCycle'

const GeneralBillingSettings = () => {
  const [getGeneralBilling, { isLoading, isFetching }] = useLazyGetGeneralBillingQuery()
  const [addGeneralBilling, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddGeneralBillingMutation()
  const [generalState, setGeneralState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    paymentTerms: [],
    billingCycles: [],
    footerFlag: false,
  })

  const [isValid, setValid] = useState(false)
  const { paymentTerms, billingCycles, footerFlag } = generalState

  const fetchGeneralBilling = () => {
    getGeneralBilling({})
      .unwrap()
      .then(res => setGeneralState({ paymentTerms: get(res, 'paymentTerm', []), billingCycles: get(res, 'billingCycle', []) }))
      .catch(handleError)
  }

  useEffect(() => {
    fetchGeneralBilling()
  }, [])

  useEffect(() => {
    let valid = true
    paymentTerms.forEach(pt => {
      if (pt.term === 'Net' && pt.interval === '') {
        valid = false
      }
    })
    setValid(valid)
  }, [generalState])

  const handleRemoval = (index, type) => {
    if (type === 'paymentTerms') {
      if (Object.keys(paymentTerms).length > 0) {
        paymentTerms.splice(index, 1)
      }

      setGeneralState({ paymentTerms })
    } else if (type === 'billingCycles') {
      if (Object.keys(billingCycles).length > 0) {
        billingCycles.splice(index, 1)
      }
      setGeneralState({ billingCycles })
    }
  }

  const addMore = type => {
    if (type === 'paymentTerms') {
      const updatedPaymentTerms = [...paymentTerms]
      updatedPaymentTerms.push({
        id: '',
        interval: '',
        term: '',
      })

      setGeneralState({ paymentTerms: updatedPaymentTerms, footerFlag: true })
    } else if (type === 'billingCycles') {
      const updatedBillingCycles = [...billingCycles]
      updatedBillingCycles.push({
        id: '',
        interval: 1,
        intervalType: '',
      })

      setGeneralState({ billingCycles: updatedBillingCycles, footerFlag: true })
    }
  }

  const handleCancel = () => {
    setGeneralState({
      footerFlag: false,
    })

    fetchGeneralBilling()
  }

  const handleAdd = () => {
    if (!isValid) {
      return
    }

    const payload = {
      billingCycle: billingCycles
        .filter(data => !data.id && data.intervalType !== '')
        .map(data => ({ interval: data.interval, intervalType: data.intervalType })),
      paymentTerm: paymentTerms
        .filter(data => !data.id && data.term !== '')
        .map(data => ({ interval: data.term === 'Due on Receipt' ? 0 : data.interval, term: data.term })),
    }

    addGeneralBilling(payload)
      .unwrap()
      .then(() => {
        fetchGeneralBilling()
      })
      .catch(handleError)
  }

  return (
    <Box>
      {(isLoading || isAddLoading || isFetching || isAddFetching) && <Loader />}

      <Box pl={4}>
        <Typography variant="h3">{T.GENERAL}</Typography>

        <Box mt={3} maxHeight="calc(100vh - 184px)" sx={{ overflowY: 'scroll' }}>
          <PaymentTerms generalState={generalState} setGeneralState={setGeneralState} handleRemoval={handleRemoval} addMore={addMore} />
          <BillingCycle generalState={generalState} setGeneralState={setGeneralState} handleRemoval={handleRemoval} addMore={addMore} />
        </Box>
      </Box>

      {footerFlag && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            disabledProceed={!isValid}
            leftButtonTitle={T.CANCEL}
            onClose={() => handleCancel()}
            rightButtonTitle={T.SAVE}
            onProceed={handleAdd}
          />
        </Box>
      )}
    </Box>
  )
}

export default GeneralBillingSettings
