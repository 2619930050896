import React, { useCallback } from 'react'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  selectBulkMoveFromSerializedDate,
  selectBulkMoveToRouteSequenceLine,
  selectBulkMoveToRouteStopMarkers,
  selectBulkMoveToRows,
  selectBulkMoveToSerializedDate,
  setPermanentRecurrenceChangeState,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { deserializeDate, formatDateToFEFormatDateFns } from 'utils/date'
import useHandleSequenceChange from 'components/route-manager/BulkActions/common/useHandleSequenceChange'
import { getBulkStopData } from 'components/route-manager/BulkActions/settings'
import StopListItem from 'components/route-manager/BulkActions/common/StopListItem/StopListItem'
import ToStopAvatarContent from 'components/route-manager/BulkActions/common/StopListItem/ToStopAvatarContent'

const RenderToStopListItem = props => {
  const dispatch = useDispatch()
  const moveToRouteSequenceLine = useSelector(selectBulkMoveToRouteSequenceLine)
  const moveToRouteStopMarkers = useSelector(selectBulkMoveToRouteStopMarkers)
  const moveToRows = useSelector(selectBulkMoveToRows)
  const { getValues, setValue } = useFormContext()
  const { row } = props
  const { id: stopId, position, workOrderStatus } = getBulkStopData(row)
  const date = get(row, 'date')
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toBeResequence = fromFEDate === date

  const getTargetIndex = useCallback(
    (fromIndex, toPosition) => {
      if (!toPosition) return 0
      const values = getValues()
      const toInsertCount = moveToRows
        .filter(item => item.date === fromFEDate)
        .filter(({ id }) => !get(values, id, '') && id !== stopId).length
      let targetPosition = Math.max(1, toPosition)
      targetPosition = Math.min(targetPosition, moveToRows.length + 1)
      const targetIndex = targetPosition - 1
      return targetIndex + toInsertCount
    },
    [moveToRows, fromSerializedDate, row?.id]
  )

  const getIsMoveToRow = useCallback(r => get(r, 'date') === toFEDate, [toSerializedDate])

  const onSuccess = data => dispatch(setPermanentRecurrenceChangeState(data))

  const handleSequenceChange = useHandleSequenceChange({
    getIsMoveToRow,
    getTargetIndex,
    getValues,
    moveToRouteSequenceLine,
    moveToRouteStopMarkers,
    moveToRows,
    onSuccess,
    setValue,
    stopId,
  })

  return (
    <StopListItem
      row={row}
      AvatarContent={
        <ToStopAvatarContent
          stopId={stopId}
          position={position}
          workOrderStatus={workOrderStatus}
          toBeResequence={toBeResequence}
          onChange={handleSequenceChange}
        />
      }
    />
  )
}

RenderToStopListItem.propTypes = {
  row: PropTypes.object,
}

export default RenderToStopListItem
