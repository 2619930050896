import React from 'react'
import PropTypes from 'prop-types'

import { GridRow } from '@mui/x-data-grid-pro'
import { LockClosed } from '@styled-icons/heroicons-solid'
import WithDeactivatedIndicator from 'components/customers/WithDeactivatedIndicator'

const CustomFeeRow = props => {
  const { row } = props
  const { archived } = row

  return (
    <WithDeactivatedIndicator Icon={<LockClosed />} showIndicator={archived} {...props}>
      <GridRow {...props} />
    </WithDeactivatedIndicator>
  )
}

CustomFeeRow.propTypes = {
  row: PropTypes.shape({
    active: PropTypes.bool,
    archived: PropTypes.bool,
  }),
}

export default CustomFeeRow
