import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Invoices from 'containers/billing/invoices/Invoices'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import { resetInvoicesState, setResetInvoicesStateOnUnmount } from '../slices/billing/invoicesSlice'

const InvoicesPage = () => {
  const dispatch = useDispatch()
  const resetOnUnmount = useSelector(state => state.invoices.resetOnUnmount)

  useEffect(() => {
    dispatch(setResetInvoicesStateOnUnmount(true))
    dispatch(setShowAppBar(false))
    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        dispatch(resetInvoicesState())
      }
    }
  }, [resetOnUnmount])

  return (
    <ProtectedRouteHoC>
      <Invoices />
    </ProtectedRouteHoC>
  )
}

InvoicesPage.propTypes = {}

export default InvoicesPage
