import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { Button, Divider, DialogContent, DialogContentText, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import { useLazyGetEmailEventsQuery } from 'api/accounts/getEmailEvents'
import { getCustomerPortalSignUpEmailEvents } from 'data/email/emailEventsSelectors'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import EmailActivity from '../common/EmailActivity'

const CustomerPortalEmailActivityDialog = ({ isOpen = false, emailId, accountId, onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [getEmailEvents, { data: emailEventList }] = useLazyGetEmailEventsQuery()
  const emailEvents = getCustomerPortalSignUpEmailEvents({ emailEvents: get(emailEventList, 'emailEvents', []) })
  const filteredEvents = emailEvents.filter(event => event?.email === emailId)

  useEffect(() => {
    if (isOpen) {
      dispatch(putIsLoading(true))
      getEmailEvents({ accountId })
        .unwrap()
        .catch(handleError)
        .finally(() => dispatch(putIsLoading(false)))
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} maxWidth="sm" onClose={onClose}>
      <HHDialogTitle title={T.EMAIL_ACTIVITY} onClose={onClose} />
      <DialogContent>
        <DialogContentText sx={{ mt: 2 }}>
          {Array.isArray(filteredEvents) && filteredEvents.length > 0 ? (
            Children.toArray(
              filteredEvents.map((paymentEmailEvent, index) => (
                <>
                  <EmailActivity email={paymentEmailEvent} />
                  {filteredEvents.length - 1 !== index && <Divider sx={{ my: 2, width: '100%' }} />}
                </>
              ))
            )
          ) : (
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              {T.NO_EMAIL_ACTIVITY_FOUND}
            </HHAlert>
          )}
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="contained" color="primary" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

CustomerPortalEmailActivityDialog.propTypes = {
  isOpen: PropTypes.bool,
  emailId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CustomerPortalEmailActivityDialog
