import React from 'react'
import { Box, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { HHAlert } from '../../../common/HHAlert'

const GraphCardEmptyState = ({ title }) => {
  const theme = useTheme()
  return (
    <Box height={250} display="flex" justifyContent="center" alignItems="center">
      <HHAlert borderColor={theme.palette.info.light} severity="info">
        {title}
      </HHAlert>
    </Box>
  )
}

GraphCardEmptyState.propTypes = {
  title: PropTypes.string.isRequired,
}

export default GraphCardEmptyState
