import React, { useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import cloneDeep from 'lodash/cloneDeep'

import NewDisposalFeeModal from 'components/pricing/DisposalFee/NewDisposalFeeModal'
import Table from 'components/pricing/Fees/Table'
import SecondaryNavBar from 'components/pricing/SecondaryNavBar'
import NewFeeModal from 'components/pricing/Fees/NewFeeModal'
import LinkServiceModal from 'components/pricing/LinkedService/LinkServiceModal'
import Loader from 'components/common/loader'

import { useLazyGetGeneralFeeLinkListQuery } from 'api/pricing/getGeneralFeeLinkList'
import { useLazyGetDisposalFeeLinkListQuery } from 'api/pricing/getDisposalFeeLinkList'

import { PRICING_PAGINATION } from 'settings/constants/pagination'
import { getFeeRecord } from 'components/pricing/DisposalFee/getFeeRecord'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual } from 'react-redux'

import './style.scss'
import 'components/pricing/services.scss'
import PricingWrapper from 'components/pricing/common/drawer/PricingWrapper'
import { Box } from '@mui/material'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const link = {}

const FeesPanel = ({
  allCheckedVal,
  allFilters,
  allSortBy,
  anchorEl,
  changePrice,
  checkedVal,
  columns,
  confServices,
  configData,
  feeServiceList,
  feeTab,
  getFeesServicesResults,
  handleActivation,
  handleAllChecked,
  handleChangePriceDelete,
  handleClose,
  handleConfChange,
  handleExport,
  handleFeeTab,
  handleOnDragEnd,
  handlePageChange,
  handleRowsPerPageChange,
  handleSingleChange,
  handleSingleChecked,
  handleTabChange,
  isDirty,
  lockedColumns,
  monthlyRevenue,
  onHandleEditFieldsChange,
  onSortByChange,
  onTableFilterChange,
  page,
  rowsPerPage,
  saveConfSettings,
  saveConfigSettings,
  selectedEditValues,
  setAnchorEl,
  setSelectedEditValues,
  setState,
  summary,
  tabValue,
  totalFeeCount,
  totalFeePageCount,
}) => {
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)
  const [openFeeDrawer, setOpenFeeDrawer] = useState(false)
  const [feesScreen, setFeesScreen] = useState('initial')
  const [openLinkFeeDrawer, setOpenLinkFeeDrawer] = useState(false)
  const [feeName, setFeeName] = useState('')
  const [autoLink, setAutoLink] = useState(false)
  const [openNewDisposalModal, setOpenNewDisposalModal] = useState(false)

  const [getGeneralFeeLinkList, { isFetching }] = useLazyGetGeneralFeeLinkListQuery()
  const [getDisposalFeeLinkList, { isFetching: isDisposalFetching }] = useLazyGetDisposalFeeLinkListQuery()

  const [feeState, setFeeState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    id: '',
    linkAll: {},
    linkVal: {},
    tax: [],
    generalFee: [],
    disposalFee: [],
    services: [],
  })

  const toggleFeeDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setFeesScreen('initial')
    setOpenFeeDrawer(open)
  }

  const setLinkListVal = (service, res) => {
    const services = get(res, 'services', [])
    const taxes = get(res, 'tax', [])

    services.map(data => {
      data.linkedVal = data.linked
    })

    taxes.map(data => {
      data.linkedVal = data.linked
    })

    setFeeState({
      id: service.id,
      services,
      tax: taxes,
      generalFee: get(res, 'generalFeeAndCharges'),
      disposalFee: get(res, 'disposalFeeAndCharges'),
    })

    const autoLinkVal = service.autoLink

    setAutoLink(autoLinkVal)
  }

  const toggleLinkFeeDrawer = (open, service) => {
    let feesName = get(service, 'feeName', '')
    const businessLine = get(service, 'businessLine', '')
    const zoneName = get(service, 'pricingZone.zoneName', '')

    setOpenLinkFeeDrawer(open)

    if (feesName === '' && businessLine !== '') {
      feesName += `${businessLine}`
    }

    if (feesName !== '' && businessLine !== '') {
      feesName += ` - ${businessLine}`
    }

    if (businessLine === '' && zoneName !== '') {
      feesName += `${zoneName}`
    }

    if (businessLine !== '' && zoneName !== '') {
      feesName += ` - ${zoneName}`
    }

    setFeeName(feesName)

    const payload = {
      id: service.id,
    }

    if (feeTab === 'general') {
      getGeneralFeeLinkList(payload)
        .unwrap()
        .then(response => {
          const res = cloneDeep(response)
          setLinkListVal(service, res)
        })
        .catch(handleError)
    } else {
      getDisposalFeeLinkList(payload)
        .unwrap()
        .then(response => {
          const res = cloneDeep(response)
          setLinkListVal(service, res)
        })
        .catch(handleError)
    }
    setAnchorEl(null)
  }

  const handleChangePrice = () => {
    const checkedIds = Object.entries(get(checkedVal, feeTab, [])).map(([key, value]) => (value ? key : null))
    setSelectedEditValues(checkedIds.map(data => (data = feeServiceList.find(fee => fee.id === data))))
    setFeesScreen('edit-fees')
    setOpenFeeDrawer(true)
  }

  const handleFeeSwitchVal = (val, id, type) => {
    if (!updatePricingManager) return

    setState({ isDirty: true })

    if (type === 'service') {
      type = 'services'
    }

    if (id === 'all' && feeState[type].length > 0) {
      feeState[type].map(data => {
        data.linkedVal = val
      })

      setFeeState(feeState)
      if (feeState[type].filter(data => data.linkedVal).length === feeState[type].length) {
        link[type] = true
        setFeeState({ linkAll: link })
      } else {
        link[type] = false
        setFeeState({ linkAll: link })
      }

      return
    }

    const feeRecord = feeState[type].find(data => data.id === id)

    if (feeRecord !== undefined) {
      feeRecord.linkedVal = val
    }

    setFeeState({ feeState })
    if (feeState[type].filter(data => data.linkedVal).length !== feeState[type].length) {
      link[type] = false
    }
  }

  const handleAutoLink = val => {
    if (!updatePricingManager) return

    setState({ isDirty: true })
    setAutoLink(val)
  }

  return (
    <>
      {(isFetching || isDisposalFetching) && <Loader />}
      <PricingWrapper className={`main-fees-panel ${feeTab}`}>
        <SecondaryNavBar
          handleConfChange={handleConfChange}
          saveConfigSettings={saveConfigSettings}
          handleOnDragEnd={handleOnDragEnd}
          configData={configData}
          handleExport={handleExport}
          changePrice={changePrice}
          toggleDrawer={toggleFeeDrawer}
          handleSingleChange={handleSingleChange}
          tab={feeTab}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleClose={handleClose}
          handleChangePrice={handleChangePrice}
          onClickNewDisposal={() => {
            setOpenNewDisposalModal(true)
          }}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          summary={summary}
          feeTab={feeTab}
        />
        <Box className="panel-table" width="100%" height="100%" overflow="auto">
          <Table
            serviceList={feeServiceList}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            totalCount={totalFeeCount}
            totalPageCount={totalFeePageCount}
            confServices={confServices}
            saveConfSettings={saveConfSettings}
            handleAllChecked={handleAllChecked}
            handleSingleChecked={handleSingleChecked}
            checkedVal={checkedVal}
            allCheckedVal={allCheckedVal}
            columns={columns}
            lockedColumns={lockedColumns}
            getFeeServiceName={getFeeRecord}
            type={feeTab}
            toggleLinkFeeDrawer={toggleLinkFeeDrawer}
            handleActivation={handleActivation}
            allFilters={allFilters}
            allSortBy={allSortBy}
            onTableFilterChange={onTableFilterChange}
            onSortByChange={onSortByChange}
          />
        </Box>
      </PricingWrapper>
      <NewDisposalFeeModal
        isOpen={openNewDisposalModal}
        setIsOpen={setOpenNewDisposalModal}
        getFeeServiceName={getFeeRecord}
        setSelectedEditValues={setSelectedEditValues}
        onSubmitCallback={() => {
          getFeesServicesResults(page, rowsPerPage, feeTab)
        }}
      />
      <NewFeeModal
        feeTab={feeTab}
        feesScreen={feesScreen}
        monthlyRevenue={monthlyRevenue}
        page={page}
        rowsPerPage={rowsPerPage}
        isDirty={isDirty}
        setFeesScreen={setFeesScreen}
        openFeeDrawer={openFeeDrawer}
        setOpenFeeDrawer={setOpenFeeDrawer}
        toggleFeeDrawer={toggleFeeDrawer}
        setState={setState}
        getFeesServicesResults={getFeesServicesResults}
        getFeeServiceName={getFeeRecord}
        selectedEditValues={selectedEditValues}
        setSelectedEditValues={setSelectedEditValues}
        handleChangePriceDelete={handleChangePriceDelete}
        onHandleEditFieldsChange={onHandleEditFieldsChange}
        confServices={columns}
      />

      <LinkServiceModal
        tab={feeTab}
        page={page}
        isDirty={isDirty}
        rowsPerPage={rowsPerPage}
        serviceName={feeName}
        serviceList={feeState}
        setState={setState}
        getResults={getFeesServicesResults}
        openLinkServiceDrawer={openLinkFeeDrawer}
        setOpenLinkServiceDrawer={setOpenLinkFeeDrawer}
        toggleLinkServiceDrawer={toggleLinkFeeDrawer}
        handleSwitchVal={handleFeeSwitchVal}
        autoLink={autoLink}
        handleAutoLink={handleAutoLink}
      />
    </>
  )
}

FeesPanel.defaultProps = {
  allCheckedVal: {},
  allFilters: {},
  allSortBy: {},
  anchorEl: null,
  changePrice: {},
  checkedVal: {},
  columns: [],
  confServices: [],
  configData: [],
  feeServiceList: [],
  feeTab: 'general',
  getFeesServicesResults: null,
  handleActivation: null,
  handleAllChecked: null,
  handleChangePriceDelete: null,
  handleClose: null,
  handleConfChange: null,
  handleFeeTab: null,
  handleOnDragEnd: null,
  handlePageChange: null,
  handleRowsPerPageChange: null,
  handleSingleChange: null,
  handleSingleChecked: null,
  isDirty: false,
  lockedColumns: [],
  monthlyRevenue: '',
  onHandleEditFieldsChange: null,
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  saveConfSettings: false,
  saveConfigSettings: null,
  selectedEditValues: [],
  setAnchorEl: null,
  setSelectedEditValues: null,
  setState: {},
  totalFeeCount: INITIAL_PAGE,
  totalFeePageCount: INITIAL_PAGE,
}

FeesPanel.propTypes = {
  allCheckedVal: PropTypes.object,
  allFilters: PropTypes.object,
  allSortBy: PropTypes.object,
  anchorEl: PropTypes.string,
  changePrice: PropTypes.object,
  checkedVal: PropTypes.object,
  columns: PropTypes.array,
  confServices: PropTypes.array,
  configData: PropTypes.array,
  feeServiceList: PropTypes.array,
  feeTab: PropTypes.string,
  getFeesServicesResults: PropTypes.func,
  handleActivation: PropTypes.func,
  handleAllChecked: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  handleClose: PropTypes.func,
  handleConfChange: PropTypes.func,
  handleExport: PropTypes.func,
  handleFeeTab: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  handleSingleChange: PropTypes.func,
  handleSingleChecked: PropTypes.func,
  isDirty: PropTypes.bool,
  lockedColumns: PropTypes.array,
  monthlyRevenue: PropTypes.string,
  onHandleEditFieldsChange: PropTypes.func,
  onSortByChange: PropTypes.func.isRequired,
  onTableFilterChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  saveConfSettings: PropTypes.bool,
  saveConfigSettings: PropTypes.func,
  selectedEditValues: PropTypes.array,
  setAnchorEl: PropTypes.func,
  setSelectedEditValues: PropTypes.func,
  setState: PropTypes.object,
  totalFeeCount: PropTypes.number,
  totalFeePageCount: PropTypes.number,
}

export default memo(FeesPanel)
