import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'

import { List, Divider, ListItem, Collapse, IconButton, ListItemText, Box } from '@mui/material'
import { get } from 'utils/lodash'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import { groupBy } from 'lodash'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { shallowEqual, useSelector } from 'react-redux'
import ContactView from './ContactView'
import ContactsActionMenu from './ContactsActionMenu'
import AddEditContactDialog from './AddEditContactDialog'
import { getContactState } from './utils'
import ContactStatusAvatar from '../../../common/avatar/ContactStatusAvatar'

const ContactsListing = ({ contacts = [], showActionMenu = false, noContactMessage = T.NO_CONTACTS }) => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails'), shallowEqual)
  const [isOpenAddEditDialog, setIsOpenAddEditDialog] = useState(false)
  const [contactsAnchorEl, setContactsAnchorEl] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)
  const [isOpenDeactivatedList, setIsOpenDeactivatedList] = useState(false)

  // Sort contact
  const allContacts = orderBy(contacts, ['deactivated', 'firstName', 'lastName'])
  const groupedContacts = groupBy(allContacts, ({ deactivated }) => deactivated)
  const activeContacts = get(groupedContacts, 'false', [])
  const deactivatedContacts = get(groupedContacts, 'true', [])
  const accountId = get(customerDetails, 'id')

  const handleOpenActionMenu = event => {
    event.stopPropagation()
    setContactsAnchorEl(event.currentTarget)
  }

  const handleCloseActionMenu = () => {
    setContactsAnchorEl(null)
    setSelectedContact(null)
  }

  const toggleDeactivatedListOpen = () => {
    setIsOpenDeactivatedList(s => !s)
  }

  return (
    <>
      {activeContacts.length === 0 && deactivatedContacts.length === 0 && <HHSectionPlaceholder title={noContactMessage} />}
      {activeContacts.length > 0 && (
        <List disablePadding>
          {Children.toArray(
            activeContacts.map((contact, index) => (
              <>
                <ContactView
                  contact={contact}
                  showActionMenu={showActionMenu}
                  onActionClick={event => {
                    setSelectedContact(getContactState(customerDetails, contact))
                    handleOpenActionMenu(event)
                  }}
                />
                {(activeContacts.length - 1 !== index || (activeContacts.length - 1 === index && deactivatedContacts.length !== 0)) && (
                  <Divider />
                )}
              </>
            ))
          )}
        </List>
      )}
      {deactivatedContacts.length !== 0 && (
        <>
          <ListItem
            divider
            secondaryAction={
              <Box mr={0.5}>
                <ContactStatusAvatar isActive={false} />
              </Box>
            }
          >
            <IconButton size="small" onClick={toggleDeactivatedListOpen}>
              {isOpenDeactivatedList ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            <ListItemText primaryTypographyProps={{ color: 'textSecondary' }} primary="Disabled contacts" />
          </ListItem>
          <Collapse in={isOpenDeactivatedList}>
            <List disablePadding>
              {Children.toArray(
                deactivatedContacts.map((contact, index) => (
                  <>
                    <ContactView
                      contact={contact}
                      showActionMenu={showActionMenu}
                      onActionClick={event => {
                        setSelectedContact(getContactState(customerDetails, contact))
                        handleOpenActionMenu(event)
                      }}
                    />
                    {deactivatedContacts.length - 1 !== index && <Divider />}
                  </>
                ))
              )}
            </List>
          </Collapse>
        </>
      )}

      <ContactsActionMenu
        anchorEl={contactsAnchorEl}
        accountId={accountId}
        selectedContact={selectedContact}
        onEdit={() => {
          setContactsAnchorEl(null)
          setIsOpenAddEditDialog(true)
        }}
        onClose={handleCloseActionMenu}
      />

      {isOpenAddEditDialog && (
        <AddEditContactDialog
          isOpen={isOpenAddEditDialog}
          accountId={accountId}
          contact={selectedContact}
          onClose={() => {
            setSelectedContact(null)
            setIsOpenAddEditDialog(false)
          }}
        />
      )}
    </>
  )
}

ContactsListing.propTypes = {
  contacts: PropTypes.array,
  showActionMenu: PropTypes.bool,
  noContactMessage: PropTypes.string,
}

export default ContactsListing
