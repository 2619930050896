import api from 'api'
import { CACHE_TAG_CALLS_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerCalls: build.query({
      providesTags: [CACHE_TAG_CALLS_LIST],
      query: body => ({ url: '/api/account/call', method: 'POST', body }),
    }),
  }),
})

export const { useLazyGetCustomerCallsQuery } = extendedApi
