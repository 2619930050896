import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AddToGroupDialog from 'components/customers/groups/account/AddToGroupDialog'
import AddAccountsToGroupDialog from 'components/customers/groups/account/add-remove/AddAccountsToGroupDialog'

const AddAgingAccountsToAccountGroup = ({ isOpen, existingAccounts = [], onClose }) => {
  const [groupId, setGroupId] = useState('')
  const [isOpenAddAccountsToGroupDialog, setIsOpenAddAccountsToGroupDialog] = useState(false)

  const handleAddToGroup = newGroupId => {
    setGroupId(newGroupId)
    setIsOpenAddAccountsToGroupDialog(true)
  }

  const handleCloseAddAccountsToGroupDialog = () => {
    setIsOpenAddAccountsToGroupDialog(false)
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setGroupId('')
    }
  }, [isOpen])

  return (
    <>
      <AddToGroupDialog isOpen={isOpen} onAddToGroup={handleAddToGroup} onClose={onClose} />
      {isOpenAddAccountsToGroupDialog && (
        <AddAccountsToGroupDialog
          isOpen={isOpenAddAccountsToGroupDialog}
          groupId={groupId}
          existingAccounts={existingAccounts}
          onClose={handleCloseAddAccountsToGroupDialog}
        />
      )}
    </>
  )
}

AddAgingAccountsToAccountGroup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  existingAccounts: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default AddAgingAccountsToAccountGroup
