import api from '../index'
import { CACHE_TAG_ROUTES } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountRouteStops: builder.query({
      query: ({ accountId, serviceDate }) => ({
        url: `/api/v1/core/accounts/${accountId}/route/stops?serviceDate=${serviceDate}`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_ROUTES],
    }),
    getStopsHeatMap: builder.query({
      query: ({ accountId, startDate, endDate }) => ({
        url: `/api/v1/core/accounts/${accountId}/route/stops/heatmap?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      providesTags: [CACHE_TAG_ROUTES],
    }),
  }),
})

export const { useLazyGetAccountRouteStopsQuery, useLazyGetStopsHeatMapQuery } = extendedApi
