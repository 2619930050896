/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const workOrdersReviewSlice = createSlice({
  name: 'persistentWorkOrdersReview',
  initialState: {
    routeIdList: [],
  },
  reducers: {
    setWOReviewRouteIdList: (state, action) => {
      state.routeIdList = action.payload
    },
  },
})

export const { setWOReviewRouteIdList } = workOrdersReviewSlice.actions

export default workOrdersReviewSlice.reducer
