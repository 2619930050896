import React from 'react'
import PropTypes from 'prop-types'
import RoomIcon from '@mui/icons-material/Room'
import { Grid, styled, Chip, Avatar, useTheme, Stack, Divider, SvgIcon } from '@mui/material'
import { Cog, Home } from '@styled-icons/heroicons-outline'
// eslint-disable-next-line import/no-extraneous-dependencies
import { StyledIconBase } from '@styled-icons/styled-icon'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'
import { CheckCircle } from '@styled-icons/heroicons-solid/CheckCircle'
import { ReactComponent as Swap } from 'assets/services/actions/Swap.svg'
import { ReactComponent as Frame } from 'assets/route_manager/Frame.svg'
import { ReactComponent as Material } from 'assets/route_manager/Material.svg'
import { ReactComponent as BusinessLine } from 'assets/route_manager/BusinessLine.svg'

import { cyan } from '@mui/material/colors'
import T from 'T'
import MeasureChip from 'containers/new-route-manager/RouteCard/MeasureChip'
import { RECURRENCE, SERVICE_ACTION_TYPE } from 'settings/constants/service'
import { isValidHex } from 'utils/validations'
import RouteDetailsItem from './RouteDetailsItem'
import WorkOrderStatusChip from '../../../work-order/WorkOrderStatusChip'
import WeekDayIcon from './WeekDayIcon'
import ServiceActionTypeChip from '../../../services/ServiceActionTypeChip'

const Icon = styled(StyledIconBase)(({ theme, color = theme.palette.text.secondary, width = '12px', height = '12px' }) => ({
  width,
  height,
  color,
}))

const parseDaysOfWeek = daysOfWeek => {
  const dayAbbreviations = ['S', 'M', 'T', 'W', 'R', 'F', 'U']
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const result = dayAbbreviations.map((abbr, index) => ({
    day: dayNames[index],
    active: false,
  }))

  for (const abbreviation of daysOfWeek) {
    const index = dayAbbreviations.indexOf(abbreviation)
    if (index !== -1) {
      result[index].active = true
    }
  }

  return result
}

const { COLLECTION } = SERVICE_ACTION_TYPE
const { RECURRING } = RECURRENCE

const RouteDetails = ({
  workOrderStatus,
  addressStr,
  daysOfWeek,
  businessLine,
  serviceType,
  measure,
  materialType,
  materialColor,
  action,
  measureUnit,
}) => {
  const theme = useTheme()
  const parsedDaysOfWeek = parseDaysOfWeek(daysOfWeek)
  const backgroundColor = isValidHex(materialColor) ? materialColor : '#000000'
  const color = theme.palette.getContrastText(backgroundColor)
  return (
    <Grid container rowSpacing={1}>
      <RouteDetailsItem icon={<RoomIcon sx={{ width: 14, height: 14, color: 'text.secondary' }} />} label="Location" value={addressStr} />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <Cog />
            </Icon>
          </SvgIcon>
        }
        label="Service type"
        value={serviceType}
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit" sx={{ color: 'white' }} color="white">
            <Icon>
              <Calendar />
            </Icon>
          </SvgIcon>
        }
        label="Schedule"
        value={
          <Stack direction="row" spacing={0.5}>
            {parsedDaysOfWeek.map(({ day, active }) => {
              return <WeekDayIcon day={day} variant={active ? 'active' : 'outlined'} />
            })}
          </Stack>
        }
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <BusinessLine />
            </Icon>
          </SvgIcon>
        }
        label="Business line"
        value={
          <Chip
            size="small"
            avatar={
              <Avatar sx={{ backgroundColor: `${theme.palette.background.black}61` }}>
                <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
                  <Icon width="12px" height="12px" color={theme.palette.common.white}>
                    <Home />
                  </Icon>
                </SvgIcon>
              </Avatar>
            }
            sx={{ backgroundColor: cyan[100] }}
            label={businessLine}
          />
        }
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <CheckCircle />
            </Icon>
          </SvgIcon>
        }
        label="Service status"
        value={<WorkOrderStatusChip status={workOrderStatus} />}
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <Swap />
            </Icon>
          </SvgIcon>
        }
        label="Action"
        value={
          <>
            {action && <ServiceActionTypeChip size="small" action={action} />}
            {!action && serviceType === RECURRING && (
              <ServiceActionTypeChip size="small" action={COLLECTION} label={T.RECURRING_COLLECTION} />
            )}
          </>
        }
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <Frame />
            </Icon>
          </SvgIcon>
        }
        label="Measure"
        value={<MeasureChip measureUnit={measureUnit} label={measure} />}
      />
      <RouteDetailsItem
        icon={
          <SvgIcon inheritViewBox viewBox="0 0 12 12" fontSize="inherit">
            <Icon>
              <Material />
            </Icon>
          </SvgIcon>
        }
        label="Material"
        value={materialType ? <Chip label={materialType} size="small" sx={{ borderRadius: 1, backgroundColor, color }} /> : '-'}
      />
      <Grid item xs={12}>
        <Divider variant="fullWidth" flexItem />
      </Grid>
    </Grid>
  )
}

RouteDetails.propTypes = {
  workOrderStatus: PropTypes.string,
  addressStr: PropTypes.string,
  daysOfWeek: PropTypes.string,
  businessLine: PropTypes.string,
  serviceType: PropTypes.string,
  measure: PropTypes.string,
  materialType: PropTypes.string,
  materialColor: PropTypes.string,
  measureUnit: PropTypes.string,
  action: PropTypes.string,
}

export default RouteDetails
