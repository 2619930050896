import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { getToken } from 'providers/firebaseAuth'
import { getSelectedBusinessId } from 'data/select-account/selectAccount'
import Constants from 'Constants'
import { CACHE_TAG_TYPES } from './cacheTagTypes'

const { SERVER_URL, BUSINESS_IDENTIFIER } = Constants

// define how much time in seconds the cached data will be stored before refreshed on next request
// https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#reducing-subscription-time-with-keepunuseddatafor
export const DEFAULT_CACHE_SUBSCRIPTION_DURATION = 60

const api = createApi({
  reducerPath: 'apiReducer',
  tagTypes: CACHE_TAG_TYPES,
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,

    // how many seconds the data will be cached between requests
    keepUnusedDataFor: DEFAULT_CACHE_SUBSCRIPTION_DURATION,
    prepareHeaders: async (headers, { getState }) => {
      const token = await getToken()
      const state = getState()
      const businessId = getSelectedBusinessId(state)

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
        if (businessId) {
          headers.set(BUSINESS_IDENTIFIER, businessId)
        }
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})

export default api
