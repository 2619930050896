import React, { createContext } from 'react'
import PropTypes from 'prop-types'

export const MapContext = createContext()

export const MapProvider = ({ children, map }) => {
  return <MapContext.Provider value={map}>{children}</MapContext.Provider>
}

MapProvider.propTypes = {
  children: PropTypes.node.isRequired,
  map: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.instanceOf(Element) }), PropTypes.func]),
}
