import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { toast } from 'react-toastify'
import { LoadingButton } from '@mui/lab'
import { DialogContent, Box } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useGetTagsMetadataQuery } from 'api/tags/getTagsMetadata'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import TagsSelect from 'components/tags/TagsSelect'
import { useBulkAddTagsMutation } from 'components/customers/groups/account/common/bulk-add-tags/useBulkAddTagsMutation'

const BulkAddTagsToAccounts = ({ isOpen = false, groupId, onClose }) => {
  const [bulkAddTagsToAccounts, { isLoading: isBulkAddTagsToGroupLoading }] = useBulkAddTagsMutation(groupId)
  const { data } = useGetTagsMetadataQuery()
  const allAccountTags = get(data, 'tags', []).filter(tag => tag.active && tag.forAccount)
  const [selectedTags, setSelectedTags] = useState([])

  const handleTagsChange = (event, selectedOptions) => setSelectedTags(selectedOptions)

  const handleSave = () => {
    const tags = selectedTags.map(tag => tag.id)
    bulkAddTagsToAccounts({ accountGroupId: groupId, tags })
      .then(() => {
        toast.success(T.BULK_ADD_TAGS_TO_ACCOUNTS_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedTags([])
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} fullWidth>
      <HHDialogTitle title={T.ADD_ACCOUNT_TAGS} onClose={onClose} />
      <DialogContent>
        <Box mt={2}>
          <TagsSelect
            deprecatedLabel={false}
            label={capitalize(T.ACCOUNT_TAGS)}
            tags={allAccountTags}
            selectedTags={selectedTags}
            onChange={handleTagsChange}
          />
        </Box>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton
          loading={isBulkAddTagsToGroupLoading}
          disabled={selectedTags.length === 0}
          size="small"
          variant="contained"
          onClick={handleSave}
        >
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

BulkAddTagsToAccounts.propTypes = {
  isOpen: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default BulkAddTagsToAccounts
