import React from 'react'
import { get } from 'lodash'
import HHDisplayMoney from '../../common/HHDisplayMoney'
import { FILTER_SUB_TYPES } from '../../../settings/constants/filters'

const { PRICE, MONEY } = FILTER_SUB_TYPES

const RenderPriceCell = ({ value, colDef }) => {
  const subType = get(colDef, 'subType')
  const field = get(colDef, 'field')
  return <HHDisplayMoney value={value} formatToDollars={(subType === PRICE || subType === MONEY) && field !== 'totalRevenue'} />
}

export default RenderPriceCell
