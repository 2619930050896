import { get } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { LATE_FEES_BUSINESS_LINES_TABS } from 'components/pricing/LateFees/CreateEditLateFeesDialog/settings'
import { LATE_FEE_ALL_TAB_ID } from 'components/pricing/LateFees/LateFeesListing/settings'

const lateFeesSlice = createSlice({
  name: 'lateFees',
  initialState: {
    isAddDialogOpen: false,
    filters: {
      businessLine: LATE_FEES_BUSINESS_LINES_TABS.ALL_BUSINESS_LINES,
      hideArchived: false,
    },
    bulkLinkDialog: {
      isOpen: false,
    },
    bulkUnlinkDialog: {
      isOpen: false,
    },
    selectedLateFee: undefined,
    archivedRestoredLateFee: undefined,
    lateFeeTab: LATE_FEE_ALL_TAB_ID,
  },
  reducers: {
    setIsAddLateFeesDialogOpen: (state, action) => {
      state.isAddDialogOpen = action.payload
    },
    setSelectedLateFeeBusinessLine: (state, action) => {
      state.filters.businessLine = action.payload
    },
    setHideArchivedLateFees: (state, action) => {
      state.filters.hideArchived = action.payload
    },
    setIsBulkLinkLateFeeDialogOpen: (state, action) => {
      state.bulkLinkDialog.isOpen = action.payload
    },
    openBulkLinkLateFeeDialog: (state, action) => {
      state.bulkLinkDialog.isOpen = true
    },
    closeBulkLinkLateFeeDialog: (state, action) => {
      state.bulkLinkDialog.isOpen = false
    },
    setIsBulkUnlinkLateFeeDialogOpen: (state, action) => {
      state.bulkUnlinkDialog.isOpen = action.payload
    },
    openBulkUnlinkLateFeeDialog: (state, action) => {
      state.bulkUnlinkDialog.isOpen = true
    },
    closeBulkUnlinkLateFeeDialog: (state, action) => {
      state.bulkUnlinkDialog.isOpen = false
    },
    setSelectedLateFee: (state, action) => {
      state.selectedLateFee = action.payload
    },
    setArchivedRestoredLateFee: (state, action) => {
      state.archivedRestoredLateFee = action.payload
    },
    setLateFeeTab: (state, action) => {
      state.lateFeeTab = action.payload
    },
  },
})

export const {
  setIsAddLateFeesDialogOpen,
  setSelectedLateFeeBusinessLine,
  setHideArchivedLateFees,
  openBulkLinkLateFeeDialog,
  setIsBulkLinkLateFeeDialogOpen,
  closeBulkLinkLateFeeDialog,
  openBulkUnlinkLateFeeDialog,
  setIsBulkUnlinkLateFeeDialogOpen,
  closeBulkUnlinkLateFeeDialog,
  setSelectedLateFee,
  setArchivedRestoredLateFee,
  setLateFeeTab,
} = lateFeesSlice.actions

export const selectIsAddLateFeesDialogOpen = s => get(s, 'lateFees.isAddDialogOpen', false)
export const selectSelectedLateFeeBusinessLine = s => get(s, 'lateFees.filters.businessLine')
export const selectHideArchivedLateFees = s => get(s, 'lateFees.filters.hideArchived')
export const selectIsBulkLinkLateFeeDialogOpen = s => get(s, 'lateFees.bulkLinkDialog.isOpen', false)
export const selectIsBulkUnlinkLateFeeDialogOpen = s => get(s, 'lateFees.bulkUnlinkDialog.isOpen', false)
export const selectSelectedLateFee = s => get(s, 'lateFees.selectedLateFee')
export const selectArchivedRestoredLateFee = s => get(s, 'lateFees.archivedRestoredLateFee')
export const selectLateFeeTab = s => get(s, 'lateFees.lateFeeTab')

export default lateFeesSlice.reducer
