import React, { useReducer, useEffect, useState } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import ChevronRight from '@mui/icons-material/ChevronRight'

import { Button, Box, Typography, Card, Grid, Divider, Tooltip } from '@mui/material'

import {
  useLazyGetCustomerBillingProfileListQuery,
  useAddCustomerBillingProfileMutation,
  useLazyGetCustomerBillingProfileByIdQuery,
  useLazyGetServicesCountBillingProfileQuery,
  useDeleteCustomerBillingProfileMutation,
} from 'api/settings/customerBillingProfileCrud'
import { useSetDefaultBillingProfileMutation } from 'api/settings/setDefaultBillingProfile'

import { getCustomerMeta } from 'middleware/actions/customers'

import T from 'T'

import { get } from 'utils/lodash'
import { isNumberOnly, isValidPrice } from 'utils/validations'
import { DAY_BASED_PRORATE, DELIVERY_METHODS } from 'settings/constants/billing'
import { hasFirstMonths } from 'utils/billingSchedule'

import { canAddSettings, canAddCustomerBillingProfiles, canUpdateSettings } from 'data/permissions/permissionsSelectors'

import { selectIsProceedDisabled } from 'data/settings/settingsSelectors'
import { handleError } from 'utils/error'

import SaveButton from 'components/buttons/SaveButton'
import HHConfirmDeleteButton from 'components/common/HHConfirmDeleteButton'
import CancelButton from 'components/buttons/CancelButton'
import { toast } from 'react-toastify'
import AddProfiles from './customer-billing-profiles/AddProfiles'
import ListProfiles from './customer-billing-profiles/ListProfiles'

const CustomerBillingProfileSettings = () => {
  const dispatch = useDispatch()
  const addCustomerBillingProfiles = useSelector(canAddCustomerBillingProfiles, shallowEqual)
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const { customerMeta } = useSelector(
    siteState => ({
      customerMeta: siteState.CustomersReducer.customerMeta,
    }),
    shallowEqual
  )

  const [addNewProfile, setAddNewProfile] = useState(false)

  const [profileState, setProfileState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    profiles: [],
    default: false,
  })

  const INITIAL_BILLING_VALUES = {
    profileId: '',
    cycle: '',
    creditLimit: '',
    paymentTerms: '',
    feeAmount: '',
    threshold: '',
    gracePeriod: '',
    cyclePeriod: '',
    suspensionThreshold: '',
    letter: [],
    lateFeeDays: [1],
    feeType: 'Dollar',
    customerBillingProfileName: '',
    timingDay: '',
    timing: 'Anniversary',
    timingQuarter: '',
    billingMonths: '',
    timingWeek: '',
    timingDayBefore: '',
    deliveryMethod: DELIVERY_METHODS,
    paymentType: 'Advance',
    rollUpAccount: false,
    customerPurchaseOrder: false,
    po: '',
    maxPOAmount: '',
    taxExemptEnable: false,
    taxExempt: '',
    prorationPreference: DAY_BASED_PRORATE,
  }

  const {
    customerBillingProfileName,
    profiles,
    profileId,
    cycle,
    deliveryMethod,
    paymentType,
    timing,
    timingDay,
    timingQuarter,
    billingMonths,
    timingWeek,
    feeType,
    gracePeriod,
    threshold,
    cyclePeriod,
    creditLimit,
    paymentTerms,
    feeAmount,
    prorationPreference,
  } = profileState
  const [getServiceCount, { data: count, isError: isCountError, isLoading: isCountLoading, isFetching: isCountFetching }] =
    useLazyGetServicesCountBillingProfileQuery()
  const [getCustomerBillingProfileList, { isLoading, isFetching }] = useLazyGetCustomerBillingProfileListQuery()
  const [addCustomerBillingProfile, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddCustomerBillingProfileMutation()
  const [getCustomerBillingProfileById, { isLoading: isEditLoading, isFetching: isEditFetching }] =
    useLazyGetCustomerBillingProfileByIdQuery()
  const [deleteCustomerBillingProfile, { isLoading: isDeleteLoading }] = useDeleteCustomerBillingProfileMutation()
  const [setDefaultBillingProfile, { isLoading: isDefaultLoading, isFetching: isDefaultFetching }] = useSetDefaultBillingProfileMutation()

  const setBillingInitialValues = () => {
    setProfileState(INITIAL_BILLING_VALUES)
  }

  const fetchCustomerBillingProfiles = () => {
    getCustomerBillingProfileList({})
      .unwrap()
      .then(res => {
        setProfileState({
          profiles: get(res, 'profile', []),
        })

        setBillingInitialValues()
      })
      .catch(handleError)
  }

  const disableFormFields =
    (profileState?.profileId && (count > 0 || isCountError)) || isCountFetching || isCountLoading || isEditLoading || isEditFetching
  const isEditForm = profileState?.profileId
  const handleAdd = () => {
    setAddNewProfile(true)
  }

  const isProceedDisabled = selectIsProceedDisabled(profileState)

  const handleSave = () => {
    setAddNewProfile(false)

    const payTerms = get(customerMeta, 'paymentTerms', [])
    const billCycle = get(customerMeta, 'billingCycles', [])

    const payload = {
      profile: customerBillingProfileName,
      deliveryMethod: deliveryMethod.join(','),
      paymentType,
      timing,
      timingDay,
      timingQuarter,
      timingWeek,
      lateFeeType: feeType,
      lateFeeGracePeriod: gracePeriod,
      lateFeeThreshold: threshold,
      creditLimit,
      lateFeeAmount: feeAmount,
      prorationPreference,
    }

    if (payTerms.length > 0) {
      payload.paymentTerm = payTerms.find(data => data.id === paymentTerms)
    }

    if (billCycle.length > 0) {
      payload.billingCycle = billCycle.find(data => data.id === cycle)
      payload.lateFeeCycle = billCycle.find(data => data.id === cyclePeriod)
    }

    if (hasFirstMonths(payload?.billingCycle) && billingMonths) {
      payload.billingMonths = billingMonths
    }

    if (profileState.profileId !== '') {
      payload.id = profileId
      payload.default = get(profileState, 'default', false)
    }

    addCustomerBillingProfile(payload)
      .unwrap()
      .then(() => {
        fetchCustomerBillingProfiles()
      })
      .catch(handleError)
  }

  const handleEdit = id => {
    if (updateSettings) {
      setAddNewProfile(true)
      setProfileState({
        profileId: id,
      })
      const payload = {
        id,
      }

      getCustomerBillingProfileById(payload)
        .unwrap()
        .then(res => {
          setProfileState({
            customerBillingProfileName: get(res, 'profile', ''),
            cycle: get(res, 'billingCycle.id', ''),
            deliveryMethod: get(res, 'deliveryMethod', '').split(','),
            paymentType: get(res, 'paymentType', ''),
            timing: get(res, 'timing', ''),
            timingDay: get(res, 'timingDay', ''),
            timingQuarter: get(res, 'timingQuarter', ''),
            billingMonths: get(res, 'billingMonths', ''),
            timingWeek: get(res, 'timingWeek', ''),
            feeType: get(res, 'lateFeeType', 'Dollar'),
            gracePeriod: get(res, 'lateFeeGracePeriod', ''),
            threshold: get(res, 'lateFeeThreshold', ''),
            cyclePeriod: get(res, 'lateFeeCycle.id', ''),
            creditLimit: get(res, 'creditLimit', ''),
            paymentTerms: get(res, 'paymentTerm.id', ''),
            feeAmount: get(res, 'lateFeeAmount', ''),
            default: get(res, 'default', false),
            prorationPreference: get(res, 'prorationPreference', ''),
          })
        })
        .catch(handleError)
    }
  }

  const handleCancel = () => {
    setAddNewProfile(false)
    fetchCustomerBillingProfiles()
  }

  const onHandleTextChange = event => {
    const { name } = event.target

    let { value } = event.target

    if (['threshold', 'feeAmount'].includes(name) && value !== '' && !isValidPrice(value)) return
    if (name === 'gracePeriod' && value !== '' && !isNumberOnly(value)) return

    if (/^\s/.test(value)) {
      value = ''
    }

    profileState[name] = value
    setProfileState({ [name]: value })
  }

  const handleDefaultChange = (event, val) => {
    const payload = {
      id: val,
      default: event.target.checked,
    }

    setDefaultBillingProfile(payload)
      .unwrap()
      .then(res => {
        setProfileState({ default: get(res, 'default', false) })
        fetchCustomerBillingProfiles()
      })
      .catch(handleError)
  }

  const onDeleteBillingProfileConfirmed = () => {
    deleteCustomerBillingProfile({ id: profileState.profileId })
      .unwrap()
      .then(() => {
        toast.success(T.CUSTOMER_BILLING_PROFILE_DELETED_SUCCESS)
        setAddNewProfile(false)
        fetchCustomerBillingProfiles()
      })
  }

  useEffect(() => {
    fetchCustomerBillingProfiles()
    setBillingInitialValues()
    dispatch(getCustomerMeta())
  }, [])

  useEffect(() => {
    if (profileState?.profileId) {
      getServiceCount({ id: profileState.profileId })
    }
  }, [profileState])

  return (
    <Box>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewProfile ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={handleCancel}
            >
              {`${T.CUSTOMER_BILLING_PROFILE}s`}
            </Typography>

            {addNewProfile && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${profileId === '' ? T.NEW : T.EDIT} ${T.CUSTOMER_BILLING_PROFILE}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNewProfile && addSettings && addCustomerBillingProfiles && (
            <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>

        {addNewProfile && addSettings && (
          <Card>
            <AddProfiles
              disableFormFields={disableFormFields}
              profileState={profileState}
              customerMeta={customerMeta}
              isLoading={isEditLoading || isEditFetching}
              setProfileState={setProfileState}
              onHandleTextChange={onHandleTextChange}
            />
            <Divider />
            <Box width="100%" px={2} py={1}>
              <Grid container>
                <Grid container item xs={12} flexWrap="nowrap" justifyContent="space-between">
                  <Grid item xs>
                    {isEditForm && (
                      <Tooltip
                        arrow
                        title={
                          disableFormFields &&
                          'Cannot delete this billing profile.  There are accounts associated with this billing profile.'
                        }
                      >
                        <span>
                          <HHConfirmDeleteButton
                            disabled={disableFormFields}
                            confirmTitle="Delete billing profile"
                            confirmDescription="Are you sure you want to delete this billing profile?"
                            onDeleteConfirmed={onDeleteBillingProfileConfirmed}
                            loading={isDeleteLoading}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item container xs="auto" columnGap={2}>
                    <CancelButton onClick={handleCancel} />
                    <SaveButton onClick={handleSave} disabled={isProceedDisabled} loading={isAddLoading} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
        )}
      </Box>

      {!addNewProfile && (
        <ListProfiles
          profiles={profiles}
          handleEdit={handleEdit}
          handleDefaultChange={handleDefaultChange}
          isLoading={isLoading || isFetching || isDefaultLoading || isDefaultFetching || isAddLoading || isAddFetching}
        />
      )}
    </Box>
  )
}

export default CustomerBillingProfileSettings
