import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import { Trash } from '@styled-icons/heroicons-outline/Trash'
import { Archive } from '@styled-icons/heroicons-outline/Archive'
import { ReactComponent as TruckHiddenOutlinedIcon } from 'assets/TruckHiddenOutlined.svg'
import { ReactComponent as ThumbtackOutlinedIcon } from 'assets/ThumbtackOutlined.svg'
import { ReactComponent as TruckOutlinedIcon } from 'assets/TruckOutlined.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'

import T from 'T'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const NoteActionMenu = ({
  id,
  anchorEl,
  selectedNote = {},
  showNoteVisibilityOnMobileOption = true,
  showDeleteOption = false,
  onEditClick,
  usePatchNoteMutation,
  useDeleteNoteMutation,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [patchNote, { isLoading: isPatchNoteLoading }] = usePatchNoteMutation()
  const [deleteNote, { isLoading: isDeleteNoteLoading }] = useDeleteNoteMutation()
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  const [isOpenConfirmArchiveDialog, setIsOpenConfirmArchiveDialog] = useState(false)
  const noteId = get(selectedNote, 'id')
  const isNotePinned = get(selectedNote, 'pinned', false)
  const isVisibleOnMobile = get(selectedNote, 'visibleOnMobile', false)

  const handleEditClick = () => {
    onClose()
    onEditClick()
  }

  const handleOpenArchiveDialog = () => {
    onClose()
    setIsOpenConfirmArchiveDialog(true)
  }

  const handlePinUnpin = () => {
    dispatch(putIsLoading(true))
    onClose()
    patchNote({ id, noteId, data: { pinned: !isNotePinned } })
      .unwrap()
      .then(() => toast.success(`Note ${isNotePinned ? 'unpinned' : 'pinned'} successfully`))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleCloseArchiveDialog = () => setIsOpenConfirmArchiveDialog(false)

  const handleConfirmArchive = () =>
    patchNote({ id, noteId, data: { archived: true } })
      .unwrap()
      .then(() => {
        toast.success('Note archived successfully')
        handleCloseArchiveDialog()
      })
      .catch(handleError)

  const handleOpenDeleteDialog = () => {
    onClose()
    setIsOpenConfirmDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setIsOpenConfirmDeleteDialog(false)

  const handleConfirmDelete = () =>
    deleteNote({ id, noteId })
      .unwrap()
      .then(() => {
        toast.success('Note deleted successfully')
        handleCloseDeleteDialog()
      })
      .catch(handleError)

  const handleNoteVisibility = () => {
    dispatch(putIsLoading(true))
    onClose()
    patchNote({ id, noteId, data: { visibleOnMobile: !isVisibleOnMobile } })
      .unwrap()
      .then(() => toast.success('Note visibility changed successfully'))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined' } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <PencilAlt width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.EDIT}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePinUnpin}>
          <ListItemIcon sx={{ mr: 1 }}>
            <ThumbtackOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{isNotePinned ? T.UNPIN : T.PIN}</ListItemText>
        </MenuItem>
        {showNoteVisibilityOnMobileOption && (
          <MenuItem onClick={handleNoteVisibility}>
            <ListItemIcon sx={{ mr: 1 }}>{isVisibleOnMobile ? <TruckHiddenOutlinedIcon /> : <TruckOutlinedIcon />}</ListItemIcon>
            <ListItemText>{isVisibleOnMobile ? T.HIDE_ON_MOBILE : T.SHOW_ON_MOBILE}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenArchiveDialog} sx={{ color: 'warning.main' }}>
          <ListItemIcon sx={{ mr: 1, color: 'warning.main' }}>
            <Archive width={24} height={24} />
          </ListItemIcon>
          <ListItemText>{T.ARCHIVE}</ListItemText>
        </MenuItem>
        {showDeleteOption && (
          <MenuItem onClick={handleOpenDeleteDialog} sx={{ color: 'error.main' }}>
            <ListItemIcon sx={{ mr: 1, color: 'error.main' }}>
              <Trash width={24} height={24} />
            </ListItemIcon>
            <ListItemText>{T.DELETE}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <HHConfirmDialog
        isOpen={isOpenConfirmArchiveDialog}
        DialogProps={{ maxWidth: 'xs', fullWidth: true, PaperProps: { sx: { maxWidth: '375px' } } }}
        confirmTitle={T.ARCHIVE_NOTE}
        confirmButtonTitle={T.ARCHIVE}
        ConfirmButtonProps={{ loading: isPatchNoteLoading, color: 'warning' }}
        confirmDescription={T.ARCHIVE_NOTE_CONFIRMATION_DESCRIPTION}
        onConfirm={handleConfirmArchive}
        onClose={handleCloseArchiveDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenConfirmDeleteDialog}
        DialogProps={{ maxWidth: 'xs', fullWidth: true, PaperProps: { sx: { maxWidth: '375px' } } }}
        confirmTitle={T.DELETE_NOTE}
        ConfirmButtonProps={{ loading: isDeleteNoteLoading }}
        confirmDescription={T.DELETE_NOTE_CONFIRMATION_DESCRIPTION}
        onConfirm={handleConfirmDelete}
        onClose={handleCloseDeleteDialog}
      />
    </>
  )
}

NoteActionMenu.propTypes = {
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  selectedNote: PropTypes.object,
  showNoteVisibilityOnMobileOption: PropTypes.bool,
  showDeleteOption: PropTypes.bool,
  onEditClick: PropTypes.func,
  usePatchNoteMutation: PropTypes.func.isRequired,
  useDeleteNoteMutation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NoteActionMenu
