import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Box from '@mui/material/Box'

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import HHTextField from 'components/form-fields/v5/HHTextField'

const MeasureTool = ({ id = '', index = 0, type = '', selectedVal = noop, onHandleChange = noop, handleRemoval = noop }) => {
  const handleMeasureRemoval = () => {
    handleRemoval(index, type)
  }

  return (
    <Box display="flex" sx={{ pointerEvents: id === '' ? '' : 'none', mt: index === 0 ? 2 : 1 }}>
      <Box display="flex" alignItems="center">
        <HHTextField
          value={selectedVal}
          variant="outlined"
          autoComplete="off"
          name={type}
          InputProps={{
            onChange: e => onHandleChange(e, type, index),
          }}
          sx={{
            width: 124,
          }}
        />
      </Box>

      {id === '' && (
        <CancelOutlinedIcon
          fontSize="small"
          onClick={handleMeasureRemoval}
          sx={{ color: 'text.secondary', m: 'auto 14px', cursor: 'pointer' }}
        />
      )}
    </Box>
  )
}

MeasureTool.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  type: PropTypes.string,
  selectedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleRemoval: PropTypes.func,
  onHandleChange: PropTypes.func,
}

export default MeasureTool
