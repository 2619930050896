import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  Box,
  Divider,
  styled,
  Grid,
  useTheme,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useDispatch, useSelector } from 'react-redux'
import { combineAddressInfo } from 'utils/helper'
import { RECURRENCE, WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { get } from 'utils/lodash'
import { getMeasureUnit } from 'utils/get-measure-unit'
import { selectLatitude, selectLongitude } from 'data/address/addressSelectors'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import T from 'T'
import RouteDetails from 'components/customer-details/content/routing/RouteDetails'
import ServiceItemSummary from 'components/customer-details/content/routing/ServiceItem/ServiceItemSummary'
import RoutingActionsMenu from 'components/customer-details/content/routing/RoutingActionsMenu'
import { setStopToBeAssigned } from 'slices/route/routingActionsSlice'
import RoutingMap from 'components/customer-details/content/routing/RoutingMap'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'
import MapPlaceholder from 'components/common/address/MapPlaceholder'
import { getFeaturePointFromCoordsObj } from 'utils/map'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { useMoveStopsMutation } from 'api/route/moveStops'
import api from 'api'
import { CACHE_TAG_ROUTES } from 'api/cacheTagTypes'
import { setRoutingTooltipCacheByAccountId } from 'slices/customer/customerDetailsSlice'

const { RECURRING } = RECURRENCE

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} {...props} />)(({ theme, borderColor }) => ({
  border: `2px solid ${borderColor}`,
  borderTop: `4px solid ${borderColor}`,
  borderRadius: theme.spacing(1),
  '&:first-of-type, &:last-of-type': {
    borderRadius: theme.spacing(1),
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(() => ({
  flexDirection: 'row-reverse',
  paddingRight: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginTop: 0,
    marginBottom: 0,
  },
}))
const ServiceItem = ({ service }) => {
  const theme = useTheme()
  const [features, setFeatures] = useState([])
  const [isUnassignConfirmDialogOpen, setIsUnassignConfirmDialogOpen] = useState(false)
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const accountId = get(customerDetails, 'id')
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const [moveStops, { isLoading: isMoveStopsLoading }] = useMoveStopsMutation()
  const accountName = get(customerDetails, 'accountName', '')
  const workOrderStatus = get(service, 'workOrderStatus')
  const sequence = get(service, 'sequence')
  const position = get(service, 'sequence', 'U')
  const stopCount = get(service, 'stopCount')
  const serviceName = get(service, 'serviceName')
  const routeId = get(service, 'routeId')
  const routeName = get(service, 'routeName', T.UNASSIGNED)
  const daysOfWeek = get(service, 'daysOfWeek', '')
  const serviceType = get(service, 'serviceType')
  const businessLine = get(service, 'businessLine')
  const measureUnit = get(service, 'measureUnit')
  const measureVolume = get(service, 'measureVolume', 0)
  const materialType = get(service, 'materialType')
  const materialColor = get(service, 'materialColor')
  const workOrderId = get(service, 'workOrderId')
  const containerLatitude = get(service, 'containerLatitude')
  const containerLongitude = get(service, 'containerLongitude')
  const serviceActionType = get(service, 'serviceActionType', '')
  const eventActionType = get(service, 'eventActionType', '')
  const currentStop = get(service, 'currentStop', 0)
  const action = serviceType === RECURRING ? serviceActionType : eventActionType
  const isUnassigned = routeName === T.UNASSIGNED
  const latitude = selectLatitude(service)
  const longitude = selectLongitude(service)
  const measure = `${measureVolume} ${getMeasureUnit(measureUnit)}`
  const addressStr = combineAddressInfo(service)
  const woStatusColor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}`, grey[400])
  const woStatusBorderColor = get(woStatusColor, 'borderColor')
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const beFormatServiceDate = formatDateToBEFormatDateFns(serviceDate)
  const dayOfWeek = serviceDate.getDay()
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const day = days[dayOfWeek]
  const hasValidLatLong = isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)

  const hasValidContainerLatLong = isValidLatitudeRange(containerLatitude) && isValidLongitudeRange(containerLongitude)
  const tooltipStopCacheByAccountId = useSelector(state => get(state, 'CustomerDetails.routing.tooltipStopCacheByAccountId', {}))
  const accountTooltipStopCacheByDate = get(tooltipStopCacheByAccountId, accountId, {})
  const handleClick = event => {
    event.stopPropagation()
    dispatch(
      setStopToBeAssigned({
        routeId,
        isUnassigned,
        addressStr,
        id: workOrderId,
        workOrderId,
        latitude,
        longitude,
        accountName,
        toBeInserted: true,
        sequence,
      })
    )
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUnassignStopClick = () => {
    setIsUnassignConfirmDialogOpen(true)
  }

  const closeUnassignConfirmDialog = () => {
    setIsUnassignConfirmDialogOpen(false)
  }

  const onUnassignConfirm = () => {
    moveStops({
      serviceDate: beFormatServiceDate,
      stops: [workOrderId],
      updateMasterSequence: true,
    })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES]))
        dispatch(
          setRoutingTooltipCacheByAccountId({
            [accountId]: {
              ...accountTooltipStopCacheByDate,
              [beFormatServiceDate]: undefined,
            },
          })
        )
      })
  }

  useEffect(() => {
    const marker = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.marker`)
    const markerTextColor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.markerTextColor`)
    const currentMarkerFeature = getFeaturePointFromCoordsObj({
      latitude,
      longitude,
      properties: { position, icon: position === 'U' ? 'to-be-assigned-marker' : marker, 'text-color': markerTextColor },
    })
    const newFeatures = [currentMarkerFeature]
    if (hasValidContainerLatLong) {
      const containerMarkerFeature = getFeaturePointFromCoordsObj({
        latitude: containerLatitude,
        longitude: containerLongitude,
        properties: { icon: 'container-marker' },
      })
      newFeatures.push(containerMarkerFeature)
    }
    setFeatures(newFeatures)
  }, [])

  return (
    <>
      <Accordion borderColor={woStatusBorderColor} disableGutters elevation={0} TransitionProps={{ mountOnEnter: true }}>
        <AccordionSummary
          sx={{ '& .MuiAccordionSummary-content': { overflow: 'auto' } }}
          direction="row-reverse"
          expandIcon={<ChevronRightIcon sx={{ fontSize: '1.5rem', color: theme.palette.action }} />}
        >
          <ServiceItemSummary
            addressStr={addressStr}
            currentStop={currentStop}
            stopCount={stopCount}
            serviceName={serviceName}
            workOrderStatus={workOrderStatus}
            routeName={routeName}
            sequence={sequence}
            day={day}
            isUnassigned={isUnassigned}
            workOrderId={workOrderId}
            onClick={handleClick}
          />
          <RoutingActionsMenu anchorEl={anchorEl} onClose={handleClose} onUnassignStopClick={handleUnassignStopClick} />
        </AccordionSummary>
        <Divider
          sx={{
            '.MuiDivider-root': {
              width: 1,
            },
          }}
        />
        <AccordionDetails sx={{ my: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Box height={250}>
                {hasValidLatLong && (
                  <RoutingMap
                    latitude={latitude}
                    longitude={longitude}
                    position={sequence}
                    workOrderStatus={workOrderStatus}
                    features={features}
                  />
                )}
                {!hasValidLatLong && <MapPlaceholder />}
              </Box>
            </Grid>
            <Grid item xs>
              <RouteDetails
                workOrderStatus={workOrderStatus}
                addressStr={addressStr}
                daysOfWeek={daysOfWeek}
                businessLine={businessLine}
                serviceType={serviceType}
                measure={measure}
                materialType={materialType}
                materialColor={materialColor}
                action={action}
                measureUnit={measureUnit}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <HHConfirmDialog
        isOpen={isUnassignConfirmDialogOpen}
        onClose={closeUnassignConfirmDialog}
        onConfirm={onUnassignConfirm}
        confirmTitle={T.UNASSIGN_STOP}
        confirmDescription={T.UNASSIGN_STOP_CONFIRM_MSG}
        confirmButtonTitle={T.UNASSIGN}
        ConfirmButtonProps={{ loading: isMoveStopsLoading }}
      />
    </>
  )
}

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired,
}

export default ServiceItem
