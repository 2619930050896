import T from 'T'

const { SERVICE, BUSINESS_LINE, RECURRENCE, MATERIAL, MEASURE, METHOD, ACTION, PRICING_ZONE, PRICE, PERIOD } = T

export const SERVICE_GROUP_DETAILS_CONFIGURATOR = [
  {
    position: 0,
    tableLabel: SERVICE,
    label: SERVICE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'serviceName',
  },
  {
    position: 1,
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine',
  },
  {
    position: 2,
    tableLabel: RECURRENCE,
    label: RECURRENCE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'serviceType',
  },
  {
    position: 3,
    tableLabel: MATERIAL,
    label: MATERIAL,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'material.materialType',
  },
  {
    position: 4,
    tableLabel: MEASURE,
    label: MEASURE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'measure',
    subType: 'measure',
  },
  {
    position: 5,
    tableLabel: METHOD,
    label: METHOD,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'method.methodType',
  },
  {
    position: 6,
    tableLabel: ACTION,
    label: ACTION,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'action.actionType',
  },
  {
    position: 7,
    tableLabel: PRICING_ZONE,
    label: PRICING_ZONE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'pricingZone.zoneName',
  },
  {
    position: 8,
    tableLabel: PRICE,
    label: PRICE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'value',
    subType: 'price',
  },
  {
    position: 9,
    tableLabel: PERIOD,
    label: PERIOD,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'period',
    subType: 'period',
  },
]
