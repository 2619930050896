import api from '../index'
import { CACHE_TAG_WORK_ORDER_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    fetchWorkOrderList: build.query({
      providesTags: [CACHE_TAG_WORK_ORDER_LIST],
      query: ({
        page,
        size,
        accountId,
        locationId,
        sortRules,
        statusList = ['Scheduled', 'In Process', 'Skipped', 'Canceled', 'Serviced'],
        serviceName,
      }) => ({
        url: '/api/v1/core/work-orders/list',
        method: 'POST',
        body: {
          requestedPage: page,
          requestedPageSize: size,
          accountId,
          locationId,
          sortRules,
          statuses: statusList,
          serviceName,
        },
      }),
    }),
  }),
})
export const { useLazyFetchWorkOrderListQuery } = extendedApi
