export const actions = {
  ADD_CUSTOMER: 'CUSTOMERS_ADD',
  GET: {
    SEARCH_RESULT: 'CUSTOMERS_GET_SEARCH_RESULT',
    RECENT_CUSTOMER_LIST: 'RECENT_CUSTOMERS_GET_LIST',
    SUGGEST_CUSTOMER_LIST: 'SUGGEST_CUSTOMERS_GET_LIST',
    CUSTOMER_LOCATION_LIST: 'CUSTOMER_LOCATION_GET_LIST',
    CUSTOMER_DETAILS: 'CUSTOMER_GET_DETAILS',
    CUSTOMER_META: 'USTOMER_GET_META',
    USER_SEARCH_RESULT: 'USER_GET_SEARCH_RESULT',
  },
  PUT: {
    SEARCH_RESULT: 'CUSTOMERS_PUT_SEARCH_RESULT',
    RECENT_CUSTOMER_LIST: 'RECENT_CUSTOMERS_PUT_LIST',
    SUGGEST_CUSTOMER_LIST: 'SUGGEST_CUSTOMERS_PUT_LIST',
    CUSTOMER_LOCATION_LIST: 'CUSTOMER_LOCATION_PUT_LIST',
    SEARCH_RESULT_PAGINATION: 'CUSTOMERS_PUT_SEARCH_RESULT_PAGINATION',
    CUSTOMER_DETAILS: 'CUSTOMER_PUT_DETAILS',
    CUSTOMER_META: 'CUSTOMER_PUT_META',
    USER_SEARCH_RESULT: 'USER_PUT_SEARCH_RESULT',
    CUSTOMER_HISTORY: 'CUSTOMER_PUT_HISTORY',
  },
}

export const putCustomerSearchResult = res => ({
  type: actions.PUT.SEARCH_RESULT,
  payload: res,
})

export const putRecentCustomerResult = res => ({
  type: actions.PUT.RECENT_CUSTOMER_LIST,
  payload: res,
})

export const putCustomerSuggestionResult = res => ({
  type: actions.PUT.SUGGEST_CUSTOMER_LIST,
  payload: res,
})

export const putCustomerSearchResultPagination = res => ({
  type: actions.PUT.SEARCH_RESULT_PAGINATION,
  payload: res,
})

export const putCustomerDetails = res => ({
  type: actions.PUT.CUSTOMER_DETAILS,
  payload: res,
})

export const putCustomerMeta = res => ({
  type: actions.PUT.CUSTOMER_META,
  payload: res,
})

export const putUserSearchResult = res => ({
  type: actions.PUT.USER_SEARCH_RESULT,
  payload: res,
})

export const putCustomerHistory = res => ({
  type: actions.PUT.CUSTOMER_HISTORY,
  payload: res,
})

export const getCustomerSearchResult = (body, onSuccess) => ({
  type: actions.GET.SEARCH_RESULT,
  payload: { body, onSuccess },
})

export const getRecentCustomerList = (body, onSuccess) => ({
  type: actions.GET.RECENT_CUSTOMER_LIST,
  payload: { body, onSuccess },
})

export const getCustomerSuggestionList = (body, onSuccess) => ({
  type: actions.GET.SUGGEST_CUSTOMER_LIST,
  payload: { body, onSuccess },
})

export const getCustomerLocationList = (body, onSuccess) => ({
  type: actions.GET.CUSTOMER_LOCATION_LIST,
  payload: { body, onSuccess },
})

export const getCustomerDetails = (body, onSuccess) => ({
  type: actions.GET.CUSTOMER_DETAILS,
  payload: { body, onSuccess },
})

export const addCustomer = (body, onSuccess) => ({
  type: actions.ADD_CUSTOMER,
  payload: { body, onSuccess },
})

export const getCustomerMeta = body => ({
  type: actions.GET.CUSTOMER_META,
  payload: { body },
})

export const getUserSearchResult = (body, onSuccess) => ({
  type: actions.GET.USER_SEARCH_RESULT,
  payload: { body, onSuccess },
})
