import api from 'api'
import Constants from 'Constants'

const { BUSINESS_IDENTIFIER } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllowedLogins: builder.query({
      query: ({ businessId, email }) => ({
        method: 'GET',
        url: `/api/v1/core/settings/users/${email}:allowed-logins`,
        ...(businessId && { headers: { [BUSINESS_IDENTIFIER]: businessId } }),
      }),
    }),
  }),
})

export const { useLazyGetAllowedLoginsQuery } = extendedApi
