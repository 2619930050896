import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { NumericFormat } from 'react-number-format'

const SummaryItem = ({ label, value, tooltip, hasPriceValue = false }) => {
  const priceProps = {
    prefix: hasPriceValue ? '$' : '',
    decimalSeparator: '.',
    decimalScale: hasPriceValue ? 2 : 0,
    fixedDecimalScale: true,
    thousandSeparator: true,
    displayType: 'text',
  }
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const fixedValue = typeof value === 'number' ? value : 0
  return (
    <Box
      px={2}
      py={1.5}
      display="flex"
      width={isTabletOrMobile ? '100%' : 'unset'}
      justifyContent={isTabletOrMobile ? 'space-between' : 'normal'}
    >
      <Tooltip title={tooltip}>
        <Typography variant="body1">{label}</Typography>
      </Tooltip>
      <Box pl={2} minWidth={fixedValue ? 36 : 16} maxWidth={isTabletOrMobile ? '100%' : '8rem'}>
        <Tooltip title={<NumericFormat thousandSeparator value={fixedValue} {...priceProps} />}>
          <Typography noWrap display="block" variant="h5">
            <NumericFormat value={fixedValue} thousandSeparator {...priceProps} />
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}

SummaryItem.propTypes = {
  tooltip: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasPriceValue: PropTypes.bool,
}

export default SummaryItem
