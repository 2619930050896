import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { lightBlue } from '@mui/material/colors'

import RenderAccountCell from './RenderAccountCell'

const AccountsDataGrid = ({ rows = [], onResend }) => {
  const { watch, setValue } = useFormContext()
  const selectionModel = watch('accountsSelectionModel')

  const getRowHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ accountId }) => accountId, [])

  const handleSelectionModelChange = useCallback(newSelectionModel => {
    setValue('accountsSelectionModel', newSelectionModel)
  }, [])

  return (
    <DataGridPro
      rows={rows}
      hideFooter
      columnHeaderHeight={0}
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: params => <RenderAccountCell row={params.row} onResend={onResend} />,
          sortable: false,
          resizable: false,
        },
      ]}
      disableMultipleRowSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row': {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: `${lightBlue[700]}14`,
          },
        },
      }}
      slots={{ noRowsOverlay: () => null }}
    />
  )
}

AccountsDataGrid.propTypes = {
  rows: PropTypes.array,
  onResend: PropTypes.func.isRequired,
}

export default AccountsDataGrid
