import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import { CommonTextLabel } from 'components/common'
import BusinessLinesTabTools from 'components/pricing/BusinessLinesTabTools'

import T from 'T'

import { get } from 'utils/lodash'

const ServicesInitialScreen = ({ businessLine, tabValue, onFrontScreenChange }) => {
  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  return (
    <div className="service-tabpanel-content">
      <div className="business-service-select">
        <CommonTextLabel title={T.BUSINESS_LINE} className="ttc" />

        <div className="business-type">
          <div className="business-service-container">
            {Children.toArray(
              globalFilterOptions &&
                globalFilterOptions.businessLines.map(business => (
                  <div
                    className={`business-services ${businessLine === business.businessLineLabel ? 'active-Line' : ''}`}
                    onClick={() => onFrontScreenChange('businessLine', business.businessLineLabel)}
                  >
                    {business.businessLineLabel}
                  </div>
                ))
            )}
          </div>
        </div>

        <BusinessLinesTabTools onFrontScreenChange={onFrontScreenChange} tabValue={tabValue} />
      </div>
    </div>
  )
}

ServicesInitialScreen.defaultProps = {
  businessLine: '',
  tabValue: '',
  onFrontScreenChange: null,
}

ServicesInitialScreen.propTypes = {
  businessLine: PropTypes.string,
  tabValue: PropTypes.string,
  onFrontScreenChange: PropTypes.func,
}

export default ServicesInitialScreen
