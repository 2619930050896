import { useCallback, useMemo } from 'react'
import { get } from 'utils/lodash'

import { checkIsManualSearch, getManualSearchParamName, getSearchSuggestions } from 'components/customers/accounts/search/settings'
import { showDeactivatedRow } from 'components/customers/accounts/settings'

const MAX_ROWS = 10

const useManualSearchListUtilities = ({ data, watch, type }) => {
  const searchType = watch('searchType')
  const withDeactivated = watch('withDeactivated')
  const searchInputValue = watch('searchInputValue')
  const isManualSearch = useMemo(() => checkIsManualSearch(searchType), [searchType])

  const rows = useMemo(() => (Array.isArray(data) ? data : []), [data])

  const getSearchedResultsManually = useCallback(() => {
    let index = 0
    const filteredRows = []

    if (!searchInputValue || !isManualSearch) return filteredRows

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const paramName = getManualSearchParamName(searchType)
      const value = get(row, paramName, '').toLowerCase()

      if (showDeactivatedRow(withDeactivated, row, type) && value.includes(searchInputValue.toLowerCase())) {
        filteredRows.push(getSearchSuggestions(row, type))
        index += 1
        if (index >= MAX_ROWS) break
      }
    }

    return filteredRows
  }, [rows, searchInputValue, withDeactivated, searchType, isManualSearch, type])

  return { getSearchedResultsManually }
}

export default useManualSearchListUtilities
