import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Dialog, DialogContent, Grid, Box } from '@mui/material'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import LoadingButton from '@mui/lab/LoadingButton'
import { capitalize, get, noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { FormProvider, useForm } from 'react-hook-form'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { formatDateToBEFormatDateFns } from 'utils/date'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'
import { isSameDay } from 'date-fns'
import { HHTextField } from 'components/form-fields/v5'
import MenuItem from '@mui/material/MenuItem'
import { HHAlert } from 'components/common/HHAlert'
import T from 'T'

const defaultValues = {
  fromDate: new Date(),
  toDate: null,
  routeId: '',
}

const SelectDateDialogBetweenDaysVariant = ({ title, isOpen, onClose = noop, onConfirm = noop, DialogProps = {}, isPermanent = false }) => {
  const theme = useTheme()
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const [routes, setRoutes] = useState([])

  const methods = useForm({
    defaultValues,
  })

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = methods

  const toDate = watch('toDate')
  const fromDate = watch('fromDate')

  const handleClose = () => {
    onClose()
    reset(defaultValues)
  }

  const routeIdErrorMessage = get(errors, 'routeId.message', false)
  const routeIdError = Boolean(routeIdErrorMessage)
  const fromDateErrorMessage = get(errors, 'fromDate.message', false)
  const fromDateError = Boolean(fromDateErrorMessage)
  const toDateErrorMessage = get(errors, 'toDate.message', false)
  const toDateError = Boolean(toDateErrorMessage)
  const onSubmit = data => {
    onConfirm(data)
  }

  const shouldDisableFromDate = useCallback(
    date => {
      if (toDate) {
        return isSameDay(date, toDate)
      }
      return false
    },
    [toDate]
  )

  const shouldDisableToDate = useCallback(
    date => {
      if (fromDate) {
        return isSameDay(date, fromDate)
      }
      return false
    },

    [fromDate]
  )

  const validateFromDate = useCallback(
    date => {
      if (date && toDate && isSameDay(date, toDate)) {
        return 'From date and to date cannot be same day'
      }
      return true
    },
    [toDate]
  )

  const validateToDate = useCallback(
    date => {
      if (date && fromDate && isSameDay(date, fromDate)) {
        return 'From date and to date cannot be same day'
      }
      return true
    },
    [fromDate]
  )

  useEffect(() => {
    getRoutesMetadata({
      serviceDate: formatDateToBEFormatDateFns(fromDate),
    })
  }, [fromDate])

  useEffect(() => {
    if (routeMetaData) {
      const updatedRoutes = get(routeMetaData, 'routes', [])
      setRoutes(updatedRoutes)
    }
  }, [routeMetaData])

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  return (
    <FormProvider {...methods}>
      <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose} {...DialogProps}>
        <HHDialogTitle title={title} showCloseIcon onClose={handleClose} />
        <DialogContent sx={{ pt: 0.5, pb: 0 }}>
          <Grid contaianer>
            <Grid item xs={12}>
              <Box mb={1}>
                <DesktopRoutePicker
                  rules={{ required: T.REQUIRED }}
                  name="routeId"
                  control={control}
                  routes={routes}
                  menuSx={{ zIndex: theme.zIndex.tooltip + 200 }}
                  helperText={routeIdErrorMessage}
                  error={routeIdError}
                />
              </Box>
            </Grid>
            {isPermanent && (
              <>
                <Grid item xs={12}>
                  <HHTextField sx={{ mb: 2 }} label={T.RECURRENCE} readOnly select fullWidth deprecatedLabel={false} defaultValue="Weekly">
                    <MenuItem value="Weekly">Weekly</MenuItem>
                  </HHTextField>
                </Grid>
                <Grid item xs={12}>
                  <HHAlert borderColor={theme.palette.info.dark} severity="info" sx={{ mb: 2 }}>
                    Currently, permanent changes to recurring services are only available for weekly services. More coming soon!
                  </HHAlert>
                </Grid>
              </>
            )}
            <Grid item container xs={12} columnGap={2}>
              <Grid item xs>
                <HHFormDesktopDatePicker
                  label={T.FROM_DATE}
                  PopperProps={{ sx: { zIndex: theme.zIndex.tooltip + 200 } }}
                  control={control}
                  name="fromDate"
                  rules={{ required: T.REQUIRED, validate: validateFromDate }}
                  TextFieldProps={{ deprecatedLabel: false, error: fromDateError, helperText: fromDateErrorMessage }}
                  shouldDisableDate={shouldDisableFromDate}
                />
              </Grid>
              <Grid item xs>
                <HHFormDesktopDatePicker
                  label={T.TO_DATE}
                  PopperProps={{ sx: { zIndex: theme.zIndex.tooltip + 200 } }}
                  control={control}
                  rules={{ validate: validateToDate }}
                  name="toDate"
                  TextFieldProps={{
                    deprecatedLabel: false,
                    helperText: toDateError ? toDateErrorMessage : T.OPTIONAL,
                    error: toDateError,
                  }}
                  shouldDisableDate={shouldDisableToDate}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="small" onClick={handleClose} />
          <LoadingButton onClick={handleSubmit(onSubmit)} variant="contained" size="small">
            {capitalize(T.NEXT)}
          </LoadingButton>
        </HHDialogActions>
      </Dialog>
    </FormProvider>
  )
}

SelectDateDialogBetweenDaysVariant.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  DialogProps: PropTypes.object,
  isPermanent: PropTypes.bool,
}

export default SelectDateDialogBetweenDaysVariant
