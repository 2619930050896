import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import { useForm, useFormState } from 'react-hook-form'
import { HHFormTextField, HHFormSelectField, HHFormSwitchField } from 'components/form-fields/v5'
import { useCreatePermitMutation } from 'api/settings/createPermit'
import CancelIcon from '@mui/icons-material/Cancel'

const PERMIT_TYPES = ['CLASS A', 'CLASS B', 'TYPE A', 'TYPE B', 'TYPE C', 'TYPE D', 'TYPE R']

const NewPermitTableRow = ({ onCancel, onCreationSuccess }) => {
  const { control, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      permitName: '',
      permitType: PERMIT_TYPES[0],
      active: false,
    },
  })
  const { isValid } = useFormState({ control })
  const [createPermit, { isSuccess, isLoading }] = useCreatePermitMutation()

  React.useEffect(() => {
    if (isSuccess) onCreationSuccess()
  }, [isSuccess])

  return (
    <TableRow>
      <TableCell p={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <CancelIcon fontSize="small" onClick={onCancel} sx={{ mr: 1, cursor: 'pointer' }} />
        <HHFormTextField
          size="small"
          control={control}
          name="permitName"
          onKeyPress={e => {
            if (e.key === 'Enter' && isValid && !isLoading) {
              e.preventDefault()
              const { permitName, permitType, active } = getValues()
              if (permitName.replaceAll(' ', '') === '') return
              createPermit({
                permitName: permitName.trim(),
                permitType,
                active,
              })
            }
          }}
          required
        />
      </TableCell>
      <TableCell p={1}>
        <HHFormSelectField
          size="small"
          control={control}
          options={PERMIT_TYPES}
          getOptionLabel={option => option}
          getOptionValue={option => option}
          name="permitType"
          required
        />
      </TableCell>
      <TableCell>
        <HHFormSwitchField control={control} name="active" />
      </TableCell>
    </TableRow>
  )
}

NewPermitTableRow.propTypes = {
  onCreationSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default NewPermitTableRow
