import api from '../index'
import { CACHE_TAG_ACCOUNT_INVOICE_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountInvoices: builder.query({
      providesTags: (result, error, { accountId }) => [
        CACHE_TAG_ACCOUNT_INVOICE_LIST,
        { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
      ],
      query: ({ accountId }) => ({ url: `/api/v1/core/accounts/${accountId}/invoices` }),
    }),
  }),
})

export const { useLazyGetAccountInvoicesQuery } = extendedApi
