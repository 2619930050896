import { get } from 'utils/lodash'
import { combineAddressInfo } from 'utils/helper'

const flatAccountLocationContacts = customerDetails => {
  const accountContacts = get(customerDetails, 'contacts', []).map(contact => ({
    ...contact,
    isAccount: true,
    parentId: customerDetails.id,
  }))
  const locationContacts = get(customerDetails, 'locations', []).map(location =>
    get(location, 'contacts', []).map(contact => ({
      ...contact,
      isLocation: true,
      parentId: location.id,
      label: combineAddressInfo(get(location, 'address', {})),
    }))
  )

  return accountContacts.concat(locationContacts.flat())
}

export const getAssociations = (customerDetails, contactId) => {
  // Remove this function once association is coming correctly from backend
  const allContacts = flatAccountLocationContacts(customerDetails)
  const associatedLocations = allContacts.filter(contact => contact.id === contactId && get(contact, 'isLocation'))
  return {
    associatedLocations: associatedLocations.map(contact => ({ id: contact.parentId, label: contact.label })),
  }
}
