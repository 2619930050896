import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import PricingDrawer from 'components/pricing/common/drawer/PricingDrawer'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import NavbarWrapper from 'components/common/NavbarWrapper'
import LateFees from 'containers/pricing/LateFees'

const LateFeesPage = () => {
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  return (
    <NavbarWrapper
      sx={{ backgroundColor: 'background.paper' }}
      innerSx={{
        overflow: 'hidden',
      }}
    >
      <PricingDrawer />
      <CustomerLayout overflow="hidden" open={isOpenPricingDrawer}>
        <LateFees />
      </CustomerLayout>
    </NavbarWrapper>
  )
}

export default LateFeesPage
