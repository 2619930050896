import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import { Typography, Grid, MenuItem } from '@mui/material'

import { get } from 'utils/lodash'
import { canUpdateSettings, canUpdateUsers } from 'data/permissions/permissionsSelectors'
import { HHTextField, HHNumberField } from 'components/form-fields/v5'
import T from 'T'

const PayScreen = ({ userState = {}, setUserState = noop }) => {
  const updateUsers = useSelector(canUpdateUsers, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const onHandleValChange = e => {
    if (updateSettings) {
      let { value } = e.target
      const { name } = e.target
      if (name === 'commercialDriverLicense') {
        value = e.target.checked
      }

      if (name === 'profileId') {
        setUserState({ role: userState.roles.find(data => data.key === value).value })
      }

      setUserState({ [name]: value, footerFlag: true })
    }
  }

  console.log(userState)

  return (
    <Grid container spacing={3} sx={{ pointerEvents: updateUsers ? '' : 'none', justifyContent: 'space-between' }}>
      <Grid item xs="6">
        <Typography variant="h5" mb={1.2}>
          {T.PAYROLL}
        </Typography>

        <Grid container columnSpacing={2} rowSpacing={1.5} justifyContent="space-between">
          <Grid item xs="12">
            <HHTextField
              label={T.TYPE}
              fullWidth
              select
              value={[get(userState, 'payType', '')]}
              name="payType"
              onChange={onHandleValChange}
            >
              <MenuItem value="">{T.SELECT}</MenuItem>
              {get(userState, 'payRolls', []).map(data => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </HHTextField>
          </Grid>

          <Grid item xs="6">
            <HHNumberField
              fullWidth
              label={T.PAY_RATE}
              placeholder={T.PAY_RATE}
              name="payRate"
              value={get(userState, 'payRate')}
              autoComplete="off"
              allowNegative={false}
              thousandSeparator
              decimalScale={2}
              dollarPrefix
              fixedDecimalScale={T.HOURLY === userState.payType}
              onValueChange={values => {
                const { value } = values
                setUserState({ payRate: value, footerFlag: true })
              }}
            />
          </Grid>

          <Grid item xs="6">
            <HHNumberField
              fullWidth
              label={T.BURDEN_RATE}
              placeholder={T.BURDEN_RATE}
              name="burdenRate"
              value={get(userState, 'burdenRate')}
              autoComplete="off"
              allowNegative={false}
              thousandSeparator
              decimalScale={2}
              dollarPrefix
              fixedDecimalScale={T.HOURLY === userState.payType}
              onValueChange={values => {
                const { value } = values
                setUserState({ burdenRate: value, footerFlag: true })
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs="6" />
    </Grid>
  )
}

PayScreen.propTypes = {
  userState: PropTypes.object,
  setUserState: PropTypes.func,
}

export default PayScreen
