import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import { Box, Grid, IconButton, ListItem, ListItemText } from '@mui/material'
import T from 'T'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import GraphCard from '../../common/GraphCard'
import InvoiceRevenueStatistic from '../InvoiceRevenueStatistic'
import InvoiceStatusStatistic from '../InvoiceStatusStatistic'
import InvoiceAgingBucketStatistic from '../InvoiceAgingBucketStatistic'
import InvoiceEmailStatistic from '../InvoiceEmailStatistic'
import { useGetInvoiceGroupDetailsQuery } from '../../../../../api/groups/invoice/getInvoiceGroupDetails'
import { convertDateTimeToPreferedTimezone } from '../../../../../data/dateTime/convertDateTimeToPreferedTimezone'
import GraphCardEmptyState from '../../common/GraphCardEmptyState'

const InvoiceGroupStatistics = () => {
  const { id } = useParams()
  const { data, loading, isSuccess } = useGetInvoiceGroupDetailsQuery({ id })
  const groupName = get(data, 'groupName', '')
  const invoices = get(data, 'invoices', [])
  const createdBy = get(data, 'createdBy')
  const createdAt = get(data, 'createdAt')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const [isGraphSectionOpen, setIsGraphSectionOpen] = useState(true)
  const hasInvoices = invoices.length !== 0
  const showEmptyState = !hasInvoices && !loading && isSuccess
  const showGraph = hasInvoices && !loading && isSuccess
  const toggleGraphSection = () => {
    setIsGraphSectionOpen(!isGraphSectionOpen)
  }
  return (
    <>
      <Grid item xs={12}>
        <ListItem>
          <IconButton onClick={toggleGraphSection}>{isGraphSectionOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
          <ListItemText
            primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }}
            primary={`${groupName} statistics`}
          />
        </ListItem>
      </Grid>
      {isGraphSectionOpen && (
        <Grid container item spacing={2}>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard Icon={CurrencyDollar} title={T.REVENUE} createdBy={createdBy} time={onlyTime} date={onlyDate} createdAt={createdAt}>
              <Box height={250}>
                {showGraph && <InvoiceRevenueStatistic invoices={invoices} showFooter={false} />}
                {showEmptyState && <GraphCardEmptyState title={T.EMPTY_INVOICE_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard
              Icon={CurrencyDollar}
              title={T.INVOICE_STATUS}
              createdBy={createdBy}
              time={onlyTime}
              date={onlyDate}
              createdAt={createdAt}
            >
              <Box height={250}>
                {showGraph && <InvoiceStatusStatistic invoices={invoices} showFooter={false} />}
                {showEmptyState && <GraphCardEmptyState title={T.EMPTY_INVOICE_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard
              Icon={CurrencyDollar}
              title={T.AGING_BUCKET}
              createdBy={createdBy}
              time={onlyTime}
              date={onlyDate}
              createdAt={createdAt}
            >
              <Box height={250}>
                {showGraph && <InvoiceAgingBucketStatistic id={id} showFooter={false} />}
                {showEmptyState && <GraphCardEmptyState title={T.EMPTY_INVOICE_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <GraphCard
              Icon={AlternateEmailOutlinedIcon}
              title={T.EMAIL}
              createdBy={createdBy}
              time={onlyTime}
              date={onlyDate}
              createdAt={createdAt}
            >
              <Box height={250}>
                {showGraph && <InvoiceEmailStatistic id={id} showFooter={false} />}
                {showEmptyState && <GraphCardEmptyState title={T.EMPTY_INVOICE_GROUP} />}
              </Box>
            </GraphCard>
          </Grid>
        </Grid>
      )}
    </>
  )
}

InvoiceGroupStatistics.propTypes = {}

export default InvoiceGroupStatistics
