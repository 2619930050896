export const CONTACT_METHODS = {
  MOBILE: 'Mobile',
  EMAIL: 'Email',
  PHONE: 'Phone',
  FAX: 'Fax',
}

export const CONTACT_METHOD_TYPES = {
  PERSONAL: 'Personal',
  WORK: 'Work',
}

const { PERSONAL, WORK } = CONTACT_METHOD_TYPES

export const METHOD_TYPES = {
  Email: [PERSONAL, WORK],
  Phone: [PERSONAL, WORK],
  Mobile: [PERSONAL, WORK],
  Fax: [WORK],
}
