import React from 'react'
import PropTypes from 'prop-types'

import { ListItemText } from '@mui/material'

const AccountDetailsListItem = ({ primaryText = '', secondaryText = '' }) => (
  <ListItemText
    primaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
    primary={primaryText}
    secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'textPrimary' }}
    secondary={secondaryText}
  />
)

AccountDetailsListItem.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
}

export default AccountDetailsListItem
