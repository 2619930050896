import React, { useState } from 'react'

import capitalize from 'lodash/capitalize'

import { blue } from '@mui/material/colors'
import { IconButton, Box, Menu, ListItemIcon, ListItemText } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

import T from 'T'
import MenuToolTip from 'components/common/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import useTheme from '@mui/material/styles/useTheme'
import { useDispatch } from 'react-redux'
import WithNewCustomerDialog from 'components/global-actions/new-customer/WithNewCustomerDialog'
import {
  setIsCreateCustomerDialogOpen,
  setIsSearchByEmailCustomerDialogOpen,
  setIsSearchByPhoneCustomerDialogOpen,
} from 'slices/customer/createCustomerSlice'

const GlobalAddMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const theme = useTheme()
  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onCreateCustomerClick = () => {
    dispatch(setIsCreateCustomerDialogOpen(true))
    closeMenu()
  }

  const onSearchByPhoneClick = () => {
    dispatch(setIsSearchByPhoneCustomerDialogOpen(true))
    closeMenu()
  }

  const onSearchByEmailClick = () => {
    dispatch(setIsSearchByEmailCustomerDialogOpen(true))
    closeMenu()
  }

  return (
    <WithNewCustomerDialog>
      <MenuToolTip title={capitalize(T.NEW_CUSTOMER)}>
        <IconButton size="large" onClick={openMenu}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={28}
            height={28}
            borderRadius="50%"
            bgcolor="primary.main"
            sx={{
              '&:hover': {
                bgcolor: blue[800],
              },
            }}
          >
            <PersonAddAltIcon sx={{ width: 20, height: 20, color: 'background.paper' }} />
          </Box>
        </IconButton>
      </MenuToolTip>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 4,
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        sx={{ zIndex: theme.zIndex.tooltip }}
      >
        <MenuItem onClick={onCreateCustomerClick}>
          <ListItemIcon>
            <PersonAddAltIcon />
          </ListItemIcon>
          <ListItemText>Create a new customer</ListItemText>
        </MenuItem>
        <MenuItem onClick={onSearchByEmailClick}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>Search by email</ListItemText>
        </MenuItem>
        <MenuItem onClick={onSearchByPhoneClick}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>Search by phone</ListItemText>
        </MenuItem>
      </Menu>
    </WithNewCustomerDialog>
  )
}

export default GlobalAddMenu
