import React, { memo } from 'react'
import { selectBulkUnassignMapLayer, setBulkUnassignStopsState, selectBulkUnassignRoutesById } from 'slices/route/bulkUnassignStopsSlice'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import T from 'T'
import { get } from 'lodash'
import CommonToggleRouteLayers from 'components/route-manager/BulkActions/common/CommonToggleRouteLayers'

const ToggleRouteLayers = () => {
  const routesById = useSelector(selectBulkUnassignRoutesById)
  const { watch } = useFormContext()
  const mapLayer = useSelector(selectBulkUnassignMapLayer)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const showToggle = moveFromRouteId && moveToRouteId

  const fromRouteName = get(routesById, [moveFromRouteId, 'name'])

  return (
    <CommonToggleRouteLayers
      showToggle={showToggle}
      showAllButton
      fromLayer={moveFromRouteId}
      toLayer={moveToRouteId}
      mapLayer={mapLayer}
      fromLayerLabel={fromRouteName}
      toLayerLabel={T.UNASSIGNED}
      bulkReduxAction={setBulkUnassignStopsState}
    />
  )
}

ToggleRouteLayers.propTypes = {}

export default memo(ToggleRouteLayers)
