import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Menu, MenuItem, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { DEFAULT_AUTOPAY_TIMING } from 'settings/constants/payment'
import { useToggleAutoPayMutation, useRemovePaymentMethodMutation } from 'api/payment-gateway/paymentGateway'

import T from 'T'
import Loader from 'components/common/loader'
import CommonSwitch from 'components/common/CommonSwitch'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const PaymentDetailsActionMenu = ({ anchorEl, accountId, selectedPaymentMethod = {}, onClose }) => {
  const theme = useTheme()
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  const [toggleAutoPay, { isLoading: isToggleAutoPayLoading }] = useToggleAutoPayMutation()
  const [removePaymentMethod, { isLoading: isRemovePaymentMethodLoading }] = useRemovePaymentMethodMutation()

  const handleAutoPayChange = isChecked => {
    toggleAutoPay({
      payload: {
        accountId,
        paymentMethodId: get(selectedPaymentMethod, 'id'),
        autoPay: isChecked,
        autoPayTime: DEFAULT_AUTOPAY_TIMING,
      },
    })
      .unwrap()
      .then(onClose)
      .catch(handleError)
  }

  const handleDeletePaymentMethod = () => {
    removePaymentMethod({ payload: { ...selectedPaymentMethod, accountId } })
      .unwrap()
      .then(response => {
        const status = get(response, 'status')
        const responseText = get(response, 'responseText')

        if (status === 'Approved') {
          toast.success(T.PAYMENT_METHOD_DELETE_SUCCESS_MESSAGE)
        } else {
          toast.error(`${status} - ${responseText}`)
        }
        setIsOpenConfirmDeleteDialog(false)
        onClose()
      })
      .catch(handleError)
  }

  const handleOpenDeleteConfirmationDialog = () => {
    setIsOpenConfirmDeleteDialog(true)
  }

  const handleCloseDeleteConfirmationDialog = () => {
    setIsOpenConfirmDeleteDialog(false)
    onClose()
  }

  return (
    <>
      {isToggleAutoPayLoading && <Loader />}
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem sx={{ '.MuiFormLabel-root': { color: 'text.primary' } }}>
          <CommonSwitch
            title={T.AUTOPAY}
            titlePosition="left"
            isChecked={get(selectedPaymentMethod, 'autoPay', false)}
            onChange={handleAutoPayChange}
          />
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDeleteConfirmationDialog}>
          {T.DELETE}
        </MenuItem>
      </Menu>
      <HHConfirmDialog
        isOpen={isOpenConfirmDeleteDialog}
        DialogProps={{ sx: { zIndex: theme.zIndex.tooltip + 20 } }}
        confirmTitle="Delete payment method"
        confirmDescription="Are you sure you want to delete this payment method?"
        ConfirmButtonProps={{ loading: isRemovePaymentMethodLoading }}
        onConfirm={handleDeletePaymentMethod}
        onClose={handleCloseDeleteConfirmationDialog}
      />
    </>
  )
}

PaymentDetailsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  selectedPaymentMethod: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default PaymentDetailsActionMenu
