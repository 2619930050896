import React, { useState, useReducer, Children } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import cloneDeep from 'lodash/cloneDeep'
import flattenDeep from 'lodash/flattenDeep'
import noop from 'lodash/noop'
import { Box, Tab, styled, Typography, Card } from '@mui/material'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { getSingularIntervalName } from 'components/pricing/pricingMethods'

import { useSaveBusinessLineSettingsMutation } from 'api/settings/saveBusinessLineSettings'

import Service from 'components/settings/pages/businesslines/attributes/Service'
import Measure from 'components/settings/pages/businesslines/attributes/Measure'
import Methods from 'components/settings/pages/businesslines/attributes/Methods'
import Materials from 'components/settings/pages/businesslines/attributes/Materials'
import Actions from 'components/settings/pages/businesslines/attributes/Actions'
import FooterButton from 'components/buttons/FooterButton'
import Loader from 'components/common/loader'

import { get } from 'lodash'
import { handleError } from 'utils/error'

import T from 'T'

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
  padding: 0,
  minWidth: 'auto',
  marginRight: 16,
}))

const AttributesSettings = ({ service = {}, measure = {}, material = [], action = [], method = [], getBusinessLines = noop }) => {
  const [activeTab, setActiveTab] = useState(T.SERVICE)
  const [addMore, setAddMore] = useState(false)
  const [showAddMore, setShowAddMore] = useState(false)

  const [saveBusinessLineSettings, { isLoading: isAddLoading, isFetching: isAddFetching }] = useSaveBusinessLineSettingsMutation()

  const [serviceState, setServiceState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    periodCombinations: [],
    recurrenceCombinations: [],
    measureCombinations: {},
    materialCombinations: [],
    actionCombinations: [],
    methodCombinations: [],
    showFooter: false,
  })

  const {
    periodCombinations,
    recurrenceCombinations,
    measureCombinations,
    materialCombinations,
    actionCombinations,
    methodCombinations,
    showFooter,
  } = serviceState

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
    setServiceState({ showFooter: false })
  }

  const handleSave = () => {
    switch (activeTab) {
      case T.SERVICE:
        return handleServiceSaveUpdate()
      case T.MEASURE:
        return handleMeasureSaveUpdate()
      case materials:
        return handleMaterialSaveUpdate()
      case actions:
        return handleActionSaveUpdate()
      case methods:
        return handleMethodSaveUpdate()
      default:
        return
    }
  }

  const handleServiceSaveUpdate = () => {
    let payload = {}

    payload['attributes'] = []

    Children.toArray(
      periodCombinations.map(data => {
        let record = {
          type: 'Period',
          value: getSingularIntervalName(data.periodInterval),
          per: data.periodPer,
        }
        if (data.id !== '') {
          record['id'] = data.id
        }
        payload['attributes'].push(record)
      })
    )

    Children.toArray(
      recurrenceCombinations.map(data => {
        let record = {
          type: 'Recurrence',
          value: getSingularIntervalName(data.recurrenceInterval),
          per: data.recurrencePer,
        }
        if (data.id !== '') {
          record['id'] = data.id
        }

        payload['attributes'].push(record)
      })
    )

    saveBusinessLineSaveCall(payload)
  }

  const saveBusinessLineSaveCall = payload => {
    saveBusinessLineSettings(payload)
      .unwrap()
      .then(() => {
        getBusinessLines()
        setServiceState({ showFooter: false })
      })
      .catch(handleError)
  }

  const handleMaterialSaveUpdate = () => {
    const payload = {}

    payload.attributes = []

    Children.toArray(
      materialCombinations
        .filter(data => data.materialType !== '')
        .map(val => {
          const record = {
            type: 'Material',
            value: val.materialType,
            color: val.color,
            active: val.active,
            photoRequired: val.photoRequired,
          }
          if (val.id !== '') {
            record.id = val.id
          }
          payload['attributes'].push(record)
        })
    )
    saveBusinessLineSaveCall(payload)
  }

  const handleMeasureSaveUpdate = () => {
    const payload = {}

    payload['attributes'] = []

    Children.toArray(
      Object.entries(measureCombinations).map(([key, value]) => {
        Children.toArray(
          value
            .filter(data => data.volume !== '')
            .map(val => {
              let record = {
                type: 'Measure',
                value: startCase(key),
                volume: parseFloat(val.volume),
              }
              if (val.id !== '') {
                record['id'] = val.id
              }
              payload['attributes'].push(record)
            })
        )
      })
    )
    saveBusinessLineSaveCall(payload)
  }

  const handleActionSaveUpdate = () => {
    let payload = {}

    payload['attributes'] = []

    Children.toArray(
      actionCombinations.map(val => {
        let record = {
          type: 'Action',
          value: val.actionType,
          label: val.actionName,
        }
        if (val.id !== '') {
          record['id'] = val.id
        }
        payload['attributes'].push(record)
      })
    )

    saveBusinessLineSaveCall(payload)
  }

  const handleMethodSaveUpdate = () => {
    let payload = {}

    payload['attributes'] = []

    Children.toArray(
      methodCombinations.map(val => {
        let record = {
          type: 'Method',
          value: val.methodType,
          label: val.methodName,
        }
        if (val.id !== '') {
          record['id'] = val.id
        }
        payload['attributes'].push(record)
      })
    )

    saveBusinessLineSaveCall(payload)
  }

  const handleCancel = () => {
    let clonedService = cloneDeep(service)
    let pricinPeriod = get(clonedService, 'period', [])
    let recurrence = get(clonedService, 'recurrence', [])

    let measuresCloned = cloneDeep(measure)
    let combinations = {}

    combinations['gallon'] = get(measuresCloned, 'gallon', [])
    combinations['ton'] = get(measuresCloned, 'ton', [])
    combinations['yard'] = get(measuresCloned, 'yard', [])
    combinations['pound'] = get(measuresCloned, 'pound', [])
    combinations['item'] = get(measuresCloned, 'item', [])

    setAddMore(false)
    setShowAddMore(false)

    setServiceState({
      periodCombinations: pricinPeriod,
      recurrenceCombinations: recurrence,
      methodCombinations: cloneDeep(method),
      actionCombinations: cloneDeep(action),
      measureCombinations: combinations,
      materialCombinations: cloneDeep(material),
      showFooter: false,
    })
  }

  const disabledProceed = (() => {
    switch (activeTab) {
      case T.SERVICE:
        return (
          periodCombinations.filter(data => data.periodInterval === '').length > 0 ||
          recurrenceCombinations.filter(data => data.recurrenceInterval === '').length > 0
        )
      case T.MEASURE:
        const allMeasures = flattenDeep(Object.values(measureCombinations))
        return allMeasures.filter(data => data.volume === '').length > 0
      case `${T.MATERIAL}s`:
        return materialCombinations.filter(data => data.materialType === '' && data.id === '').length > 0
      default:
        return false
    }
  })()

  let materials = `${T.MATERIAL}s`
  let actions = `${T.ACTION}s`
  let methods = `${T.METHOD}s`

  return (
    <Box pl={4}>
      {(isAddLoading || isAddFetching) && <Loader />}
      <Typography variant="h3">{T.ATTRIBUTES}</Typography>

      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 1 }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <StyledTab label={T.SERVICE} value={T.SERVICE} />
            <StyledTab label={T.MEASURE} value={T.MEASURE} />
            <StyledTab label={materials} value={materials} />
            <StyledTab label={actions} value={actions} />
            <StyledTab label={methods} value={methods} />
          </TabList>
        </Box>

        <Card sx={{ mt: 3 }}>
          <Box height="calc(100vh - 240px)" sx={{ overflowY: 'scroll' }}>
            <TabPanel value={T.SERVICE}>
              <Service
                service={service}
                periodCombinations={periodCombinations}
                recurrenceCombinations={recurrenceCombinations}
                setServiceState={setServiceState}
              />
            </TabPanel>

            <TabPanel value={T.MEASURE}>
              <Measure measure={measure} measureCombinations={measureCombinations} setServiceState={setServiceState} />
            </TabPanel>

            <TabPanel value={materials}>
              <Materials
                material={material}
                materialCombinations={materialCombinations}
                setServiceState={setServiceState}
                handleMaterialSaveUpdate={handleMaterialSaveUpdate}
              />
            </TabPanel>

            <TabPanel value={actions}>
              <Actions
                action={action}
                actionCombinations={actionCombinations}
                setServiceState={setServiceState}
                showAddMore={showAddMore}
                setShowAddMore={setShowAddMore}
              />
            </TabPanel>

            <TabPanel value={methods}>
              <Methods
                method={method}
                methodCombinations={methodCombinations}
                setServiceState={setServiceState}
                addMore={addMore}
                setAddMore={setAddMore}
              />
            </TabPanel>
          </Box>

          {showFooter && (
            <Box
              px={2}
              py={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ borderTop: 1, borderColor: 'border.light' }}
            >
              <FooterButton
                leftButtonTitle={T.CANCEL}
                onClose={handleCancel}
                rightButtonTitle={T.SAVE}
                onProceed={handleSave}
                disabledProceed={disabledProceed}
              />
            </Box>
          )}
        </Card>
      </TabContext>
    </Box>
  )
}

AttributesSettings.propTypes = {
  service: PropTypes.object,
  measure: PropTypes.object,
  material: PropTypes.array,
  action: PropTypes.array,
  method: PropTypes.array,
  getBusinessLines: PropTypes.func,
}

export default AttributesSettings
