import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SelectAccount from 'containers/select-account'
import { setShowAppBar } from 'slices/appbar/appbarSlice'

const SelectAccountPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setShowAppBar(false))

    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  return <SelectAccount />
}

export default SelectAccountPage
