import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Menu, styled } from '@mui/material'
import { SketchPicker } from 'react-color'
import { blue, green, indigo, lightBlue, orange, pink, red, teal, yellow } from '@mui/material/colors'
import ColorSquare from './ColorSquare'

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: 0,
  },
})

const FormColorPickerField = ({ control, name, rules, sx }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  return (
    <Controller
      defaultValue="#000000"
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const { onBlur, onChange, ...rest } = field
        return (
          <>
            <ColorSquare
              sx={sx}
              color={field?.value || false}
              onClick={event => {
                setAnchorEl(event.currentTarget)
              }}
            />
            <StyledMenu
              open={open}
              onClose={() => {
                setAnchorEl(null)
              }}
              anchorEl={anchorEl}
            >
              <SketchPicker
                color={field?.value}
                disableAlpha
                onChangeComplete={({ hex }) => {
                  onChange(hex)
                  onBlur()
                }}
                presetColors={[
                  red[500],
                  orange[500],
                  yellow[500],
                  green[400],
                  teal[400],
                  blue[500],
                  indigo[500],
                  pink[500],
                  '#F43F5E',
                  '#D946EF',
                  '#8B5CF6',
                  lightBlue[500],
                  '#10B981',
                  '#84CC16',
                ]}
                {...rest}
              />
            </StyledMenu>
          </>
        )
      }}
    />
  )
}

FormColorPickerField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  sx: PropTypes.object,
}

export default FormColorPickerField
