import React from 'react'
import PropTypes from 'prop-types'
import CommonModalFooter from 'components/common/CommonModalFooter'
import CommonModalHeader from 'components/common/CommonModalHeader'
import T from 'T'
import Box from '@mui/material/Box'
import LinkedServicesList from '../LinkedServicesList/LinkedServicesList'

const LinkedServicesScreen = ({ matchedServices, onCancel, onClose, onSave }) => {
  return (
    <>
      <CommonModalHeader title={T.AUTO_LINKED_SERVICES} onClose={onClose} />
      <Box>
        <LinkedServicesList matchedServices={matchedServices} />
      </Box>
      <CommonModalFooter
        onCancel={onCancel}
        onSave={onSave}
        saveButtonLabel={T.SAVE}
        cancelButtonLabel={T.CANCEL}
        sx={{
          position: 'relative',
          bottom: 56,
        }}
      />
    </>
  )
}

LinkedServicesScreen.propTypes = {
  matchedServices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default LinkedServicesScreen
