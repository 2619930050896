import React from 'react'
import T from 'T'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { setBulkSentInvoicesConfirmation } from 'slices/billing/invoicesSlice'
import api from 'api'
import { CACHE_TAG_INVOICE_LIST } from 'api/cacheTagTypes'

const BulkSentInvoicesConfirmationDialog = ({ confirmDescription = 'Invoices are being sent. Please check back later.' }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => get(state, 'invoices.bulkSendInvoicesConfirmation.isOpen', false), shallowEqual)

  const handleClose = () => {
    dispatch(api.util.invalidateTags([CACHE_TAG_INVOICE_LIST]))
    dispatch(setBulkSentInvoicesConfirmation({ isOpen: false }))
  }

  return (
    <HHConfirmDialog
      DialogProps={{
        fullWidth: true,
        maxWidth: 'xs',
      }}
      showCloseIconButton={false}
      showCancelButton={false}
      isOpen={isOpen}
      confirmTitle="Sending"
      confirmDescription={confirmDescription}
      confirmButtonTitle={T.OK}
      onClose={handleClose}
      onConfirm={handleClose}
      ConfirmButtonProps={{
        color: 'primary',
      }}
    />
  )
}

BulkSentInvoicesConfirmationDialog.propTypes = {
  confirmDescription: PropTypes.string,
}

export default BulkSentInvoicesConfirmationDialog
