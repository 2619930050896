import React, { useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useParams } from 'react-router-dom'
import { Box, Card, Grid, Divider, ListItem, Stack, ListItemText, Typography, IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import MoreVert from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { formatDateToFEFormat } from 'utils/date'
import { getPaymentMethod, getPaymentMethodImage } from 'utils/payment'
import { PAYMENT_STATUS_COLORS } from 'components/common/chip/settings'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { PAYMENT_METHODS, PAYMENT_METHOD_IMAGES, PAYMENT_STATUS } from 'settings/constants/payment'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PaymentStatusChip from 'components/common/chip/PaymentStatusChip'
import PaymentsActionMenu from 'components/billing/common/PaymentsActionMenu'
import AccountSummary from '../../common/AccountSummary'

const { CHECK, OTHER, ACCOUNT_CREDIT, ACH, CREDIT_CARD } = PAYMENT_METHODS
const { DECLINED } = PAYMENT_STATUS

const RenderPaymentGroupCell = ({ row = {} }) => {
  const { id: groupId } = useParams()
  const {
    id,
    accountId,
    customerName,
    accountNumber,
    batchId,
    transactionAmountCents,
    transactionPostedDate,
    transactionStatus,
    paymentDate,
    paymentMethod,
    checkNum,
    creditCardType,
    accountLastFour,
  } = row
  const [anchorEl, setAnchorEl] = useState(null)
  const isCheckOther = [CHECK, OTHER].includes(paymentMethod)
  const isAccountCredit = paymentMethod === ACCOUNT_CREDIT
  const isDeclinedPayment = transactionStatus === DECLINED

  const handleCloseActionMenu = () => setAnchorEl(null)

  const handleOpenActionMenu = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const checkValue = isCheckOther ? checkNum : accountNumber
  const paymentMethodName = paymentMethod === ACH ? paymentMethod : capitalize(getPaymentMethod(paymentMethod))
  const paymentMethodImage = paymentMethod === CREDIT_CARD ? getPaymentMethodImage(creditCardType) : PAYMENT_METHOD_IMAGES[paymentMethod]
  const { onlyDate: transactionPostedDateFormatted } = convertDateTimeToPreferedTimezone({ dateTime: transactionPostedDate })

  const apiRef = useGridApiContext()
  const expandedDetailsPanel = apiRef.current.getExpandedDetailPanels()
  const isExpanded = expandedDetailsPanel.includes(id)
  const handleToggleDetailPanel = e => {
    e.stopPropagation()
    apiRef.current.toggleDetailPanel(id)
  }

  return (
    <Box width="100%" px={3}>
      <Card variant="outlined" sx={{ width: '100%', borderRadius: 0 }}>
        <AccountSummary accountId={accountId} accountNumber={accountNumber} accountName={customerName} batchNumber={batchId} />
        <Divider />
        <Grid container px={2} columnSpacing={3}>
          <Grid item display="flex" alignItems="center">
            <Stack spacing={1}>
              <PaymentStatusChip status={transactionStatus} />
              {transactionStatus && (
                <Grid item sx>
                  <Typography color={PAYMENT_STATUS_COLORS[transactionStatus][600]} variant="caption" fontWeight={700}>
                    {T.POSTED}: {transformDate(paymentDate)}
                  </Typography>
                </Grid>
              )}
            </Stack>
          </Grid>
          <Grid item xs display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <img className="mr2" src={paymentMethodImage} alt="" />
              <Stack>
                <Typography display="inline" variant="h5" fontWeight={400}>
                  {creditCardType && accountLastFour ? `${creditCardType} •••• ${accountLastFour}` : paymentMethodName}
                </Typography>

                {(isCheckOther || isAccountCredit) && checkValue && <Typography variant="caption">{checkValue}</Typography>}
              </Stack>
            </Box>
          </Grid>
          <Grid item>
            <ListItem
              secondaryAction={
                <Stack>
                  <IconButton size="small" onClick={handleOpenActionMenu}>
                    <MoreVert />
                  </IconButton>
                  <IconButton size="small" onClick={handleToggleDetailPanel} disabled={isDeclinedPayment}>
                    {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </Stack>
              }
            >
              <ListItemText
                primary={<HHDisplayMoney value={transactionAmountCents} />}
                primaryTypographyProps={{ variant: 'h6', textAlign: 'right' }}
                secondary={`${capitalize(T.TRANSACTION_DATE)}: ${transactionPostedDateFormatted}`}
                secondaryTypographyProps={{ variant: 'body1', color: 'info.light', mt: 2 }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Card>
      <PaymentsActionMenu
        anchorEl={anchorEl}
        selectedPayment={{ ...row, groupId }}
        isOriginalPaymentReversedOrTransferred={false}
        onClose={handleCloseActionMenu}
        showRevertAction={false}
        showTransferAction={false}
        showApplyAction={false}
        showACHFailedAction={false}
        showAddToGroupAction={false}
        showMoveToAccountCreditAction={false}
      />
    </Box>
  )
}

RenderPaymentGroupCell.propTypes = {
  row: PropTypes.object,
}

export default RenderPaymentGroupCell
