/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import T from 'T'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import HHDisplayMoney from '../../../common/HHDisplayMoney'
import ChargesTableRow from './ChargesTableRow'
import { updateCreateInvoicesDocIdToChargeMapItem } from '../../../../slices/billing/createInvoicesSlice'
import WorkOrderPOList from './WorkOrderPOList'

const ChargesAccordionListItem = ({
  docId,
  expanded,
  chargesCount,
  locationsCount,
  poCount,
  index,
  listRef,
  title,
  total,
  accountNumber,
  locationListOfChargeList,
  isLoading,
  isScrolling,
  sx,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = () => {
    if (locationsCount === 0) return
    const newExpanded = !expanded
    const newValue = {
      expanded: newExpanded,
      height: newExpanded ? (chargesCount + poCount) * 28 + locationsCount * 37 + 208 : 98,
    }

    dispatch(
      updateCreateInvoicesDocIdToChargeMapItem({
        docId,
        value: newValue,
      })
    )
  }

  useEffect(() => {
    listRef.current.recomputeRowHeights(index)
    listRef.current.forceUpdateGrid()
  }, [expanded])

  return (
    <Accordion
      sx={{
        ...sx,
        mx: isTabletOrMobile ? 0.5 : 'auto',
        left: '50%',
        transform: 'translate(-50%, 0)',
        '&:first-child': {
          borderTop: `1px solid ${theme.palette.border.light}`,
        },
        '&:last-child': {
          borderBottom: `1px solid ${theme.palette.border.light}`,
        },
        '&.Mui-expanded': {
          ...sx,
          mx: isTabletOrMobile ? 0.5 : 'auto',
          left: '50%',
          transform: 'translate(-50%, 0)',
          mt: 0,
        },
        maxWidth: isTabletOrMobile ? 'unset' : 900,
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ visibility: !isLoading && locationsCount !== 0 ? 'visible' : 'hidden' }} />}
        sx={{
          position: 'relative',
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h4" fontWeight="700" whiteSpace="nowrap" textOverflow="ellipsis">
                  {isScrolling ? <Skeleton width="15rem" /> : title}
                </Typography>
              }
              secondary={
                <Grid container>
                  <Grid item container columnSpacing={1} wrap="nowrap" flexWrap="nowrap">
                    <Grid item xs="auto">
                      <Typography fontWeight="bold" color="textSecondary">
                        {isScrolling ? <Skeleton width="5rem" /> : 'Account:'}
                      </Typography>
                    </Grid>
                    <Grid container item xs columnSpacing={0.5}>
                      <Typography noWrap color="textPrimary">
                        {isScrolling ? <Skeleton width="5rem" /> : accountNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <ListItemIcon sx={{ alignItems: 'center', justifyItems: 'center' }}>
              <Typography fontWeight="bold" variant="h3" color="textPrimary">
                {isScrolling && <Skeleton width="5rem" />}
                {!isLoading && !isScrolling && <HHDisplayMoney value={total} formatToDollars={false} />}
              </Typography>
            </ListItemIcon>
          </ListItem>

          {isLoading && <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        {!isScrolling && <Divider />}
        {isScrolling && <Box width="100%" height={(chargesCount + poCount) * 28 + locationsCount * 37 + 103} />}
        {!isScrolling && (
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                px: 2,
                py: 0.5,
                ...theme.typography.body1,
              },
              [`& .${tableCellClasses.head} `]: {
                py: 1,
                overflow: 'hidden',
                whiteSpace: 'noWrap',
                textOverflow: 'ellipsis',
              },
              [`& .${tableCellClasses.body} `]: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'noWrap',
              },
            }}
          >
            <TableHead
              sx={{
                textTransform: 'uppercase',
                borderBottom: `1px solid ${theme.palette.border.grey}`,
                borderTop: `1px solid ${theme.palette.border.grey}`,
              }}
            >
              <TableCell>{T.SERVICE}</TableCell>
              <TableCell>{T.DATE}</TableCell>
              <TableCell>{T.PRICE}</TableCell>
              <TableCell>{T.QTY}</TableCell>
              <TableCell align="right">{T.EXT_PRICE}</TableCell>
            </TableHead>
            <TableBody>
              {locationListOfChargeList.map(chargeList => {
                const address = get(chargeList, `0.address`, '')
                return (
                  <>
                    <TableRow
                      sx={{
                        height: 37,
                        backgroundColor: `${theme.palette.info.dark}0A`,
                        borderBottom: `1px solid ${theme.palette.border.grey}`,
                        borderTop: `1px solid ${theme.palette.border.grey}`,
                      }}
                    >
                      <TableCell colSpan={5}>{address}</TableCell>
                    </TableRow>
                    {chargeList.map((chargeItem, rowIndex) => {
                      const service = get(chargeItem, 'service')
                      const date = get(chargeItem, 'date')
                      const charge = get(chargeItem, 'charge')
                      const quantity = get(chargeItem, 'quantity')
                      const chargeTotal = get(chargeItem, 'total', 0)
                      const subChargeList = get(chargeItem, 'subChargeList', [])
                      const workOrders = get(chargeItem, 'workOrders', [])
                      return (
                        <>
                          <ChargesTableRow
                            key={`ChargeTableRow-${index}-${rowIndex}`}
                            service={service}
                            date={date}
                            charge={charge}
                            quantity={quantity}
                            total={chargeTotal}
                          />
                          {subChargeList.map((subChargeItem, subRowIndex) => {
                            const subService = get(subChargeItem, 'service')
                            const subDate = get(subChargeItem, 'startDate')
                            const subCharge = get(subChargeItem, 'charge')
                            const subQuantity = get(subChargeItem, 'quantity')
                            return (
                              <ChargesTableRow
                                key={`ChargeTableRow-${index}-${rowIndex}-${subRowIndex}`}
                                service={subService}
                                date={subDate}
                                charge={subCharge}
                                quantity={subQuantity}
                                total={subCharge}
                                isSubCharge
                              />
                            )
                          })}
                          <WorkOrderPOList workOrders={workOrders} />
                        </>
                      )
                    })}
                  </>
                )
              })}
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" fontWeight="700">
                    Total
                  </Typography>
                </TableCell>
                <TableCell colSpan={3} />
                <TableCell>
                  <Typography align="right" color="textPrimary" fontWeight="700">
                    <HHDisplayMoney value={total} formatToDollars={false} />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

ChargesAccordionListItem.propTypes = {
  docId: PropTypes.string,
  index: PropTypes.number,
  accountNumber: PropTypes.string,
  expanded: PropTypes.bool,
  chargesCount: PropTypes.number,
  locationsCount: PropTypes.number,
  poCount: PropTypes.number,
  title: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationListOfChargeList: PropTypes.array,
  isLoading: PropTypes.bool,
  listRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isScrolling: PropTypes.bool,
  sx: PropTypes.object,
}

export default ChargesAccordionListItem
