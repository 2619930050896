import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { useLazyGetUserAuthTokenQuery } from 'api/auth/getUserAuthToken'
import { useAuthValue } from 'auth/AuthContext'
import { handleError } from 'utils/error'
import { get } from 'utils/lodash'

const {
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTHDOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_APIKEY,
  authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const handleLogout = () => {
  const auth = getAuth()
  signOut(auth)
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)

const WithFirebase = ({ children }) => {
  const [getUserAuthToken] = useLazyGetUserAuthTokenQuery()
  const { currentUser } = useAuthValue() || {}
  const selectedbusinessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)

  const handleSignInWithCustomToken = async customToken => {
    const auth = getAuth()
    try {
      await signInWithCustomToken(auth, customToken)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (currentUser && selectedbusinessId) {
      getUserAuthToken()
        .unwrap()
        .then(response => handleSignInWithCustomToken(response?.token))
        .catch(handleError)
    }
  }, [currentUser, selectedbusinessId])

  return <>{children}</>
}

WithFirebase.propTypes = {
  children: PropTypes.node,
}

export default WithFirebase
