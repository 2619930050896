import api from 'api'
import {
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
} from '../../cacheTagTypes'

const invalidatesTags = [
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
]

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    bulkChangeBillingProfileForAccounts: build.mutation({
      query: ({ billingProfileId, accountIds }) => ({
        url: '/api/v1/core/accounts/bulk/billing-profile',
        body: { billingProfileId, accountIds },
        method: 'PATCH',
      }),
      invalidatesTags,
    }),
    bulkChangeBillingProfileForAccountGroup: build.mutation({
      query: ({ groupId, billingProfileId }) => ({
        url: `/api/v1/core/accounts/groups/${groupId}:update-billing-profile`,
        method: 'POST',
        body: { billingProfileId },
      }),
      invalidatesTags,
    }),
  }),
})

export const { useBulkChangeBillingProfileForAccountsMutation, useBulkChangeBillingProfileForAccountGroupMutation } = extendedApi
