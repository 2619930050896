import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Box, Dialog, DialogContent, Grid, Typography, useMediaQuery } from '@mui/material'
import { get, noop } from 'lodash'
import { useUpdateBillingAddressMutation } from 'api/accounts/updateBillingAddress'
import HHDialogTitle from '../../../common/HHDialogTitle'
import T from '../../../../T'
import HHDialogActions from '../../../common/HHDialogActions'
import AddressFormFields from './AddressFormFields'
import { selectAddress } from '../../../../data/address/addressSelectors'
import { combineAddressInfo, splitAddressLine1 } from '../../../../utils/helper'
import MapPreview from './MapPreview'
import AddressDetails from '../common/AddressDetails'
import CancelButton from '../../../buttons/CancelButton'
import SaveButton from '../../../buttons/SaveButton'

const initialLatitude = '32.77321'
const initialLongitude = '-117.15920'

const defaultValues = {
  addressName: '',
  line1: '',
  unitNumber: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
}

const addressObjToOptionFormat = addressObj => {
  const { line1, city, state, zipCode, country, latitude, longitude } = addressObj
  const splittedLine1 = splitAddressLine1(line1)
  const streetNumber = splittedLine1.number
  const { streetName } = splittedLine1
  return {
    ...addressObj,
    streetNumber,
    streetName,
    city,
    stateShortCode: state,
    zipCode,
    country,
    center: {
      longitude,
      latitude,
    },
    text: combineAddressInfo(addressObj),
  }
}

const EditBillingLocationModal = ({ open = false, customerDetails, onClose = noop, onSave = noop }) => {
  const methods = useForm({ defaultValues })
  const { reset, handleSubmit, watch } = methods
  const watchAllFields = watch()
  const billingAddress = get(customerDetails, 'billingAddress', {})
  const attention = get(billingAddress, 'attention')
  const { city, state, latitude, longitude, line1, zipCode, unitNumber } = selectAddress(billingAddress)
  const isTabletOrMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isTabletOrDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const [defaultCoords, setDefaultCoords] = useState({
    defaultLatitude: initialLatitude,
    defaultLongitude: initialLongitude,
  })
  const [currentCoords, setCurrentCoords] = useState({
    currentLatitude: initialLatitude,
    currentLongitude: initialLongitude,
  })
  const [updateBillingAddress, { isLoading }] = useUpdateBillingAddressMutation()

  const { currentLatitude, currentLongitude } = currentCoords
  const { defaultLatitude, defaultLongitude } = defaultCoords

  const handleAddressChange = (e, addressObj) => {
    const { latitude: newLatitude, longitude: newLongitude } = addressObj
    setDefaultCoords({
      defaultLatitude: newLatitude,
      defaultLongitude: newLongitude,
    })
    setCurrentCoords({
      currentLatitude: newLatitude,
      currentLongitude: newLongitude,
    })
  }

  const handleSave = data => {
    // eslint-disable-next-line no-shadow
    const { line1, unitNumber, city, state, country, zipCode, addressName } = data
    const accountId = get(customerDetails, 'id')

    const body = {
      attention: addressName,
      line1,
      unitNumber,
      city,
      state,
      country,
      zipCode,
      latitude: currentLatitude,
      longitude: currentLongitude,
    }
    updateBillingAddress({ accountId, body })
      .unwrap()
      .then(() => {
        onSave()
      })
  }

  useEffect(() => {
    if (open) {
      reset({
        addressName: attention,
        city,
        state,
        latitude,
        longitude,
        line1,
        zipCode,
        unitNumber,
        address: addressObjToOptionFormat(billingAddress),
      })
      setDefaultCoords({
        defaultLatitude: latitude,
        defaultLongitude: longitude,
      })
      setCurrentCoords({
        currentLatitude: latitude,
        currentLongitude: longitude,
      })
    } else {
      reset(defaultValues)
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <HHDialogTitle sx={{ px: 3, pt: 3 }} closeIconSx={{ top: 16 }} onClose={onClose} title={T.EDIT_BILLING_LOCATION} />
      <DialogContent sx={{ px: 3 }}>
        <FormProvider {...methods}>
          <Grid container rowSpacing={2} direction={isTabletOrMobile ? 'column' : 'row'}>
            <Grid container item xs direction="column">
              <Grid container item direction={isMobile ? 'column' : 'row'} wrap="nowrap" xs={12}>
                <Grid item xs minWidth={isTabletOrMobile ? undefined : 260}>
                  <MapPreview
                    defaultLongitude={defaultLongitude}
                    defaultLatitude={defaultLatitude}
                    currentLatitude={currentLatitude}
                    currentLongitude={currentLongitude}
                    setCurrentCoords={setCurrentCoords}
                  />
                </Grid>
                <Grid item xs minWidth={isTabletOrMobile ? undefined : 260}>
                  {isTabletOrDesktop && (
                    <AddressDetails
                      spacing={2}
                      sx={{ px: 2, pt: isTabletOrMobile ? 4 : 3, pb: 2 }}
                      address={watchAllFields}
                      showAddressName
                      addressNameLabel={T.ATTENTION}
                      line1Label={T.BILLING_ADDRESS}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {isTabletOrMobile && (
              <Grid item xs={12}>
                <Alert
                  sx={{ display: 'flex', alignItems: 'center', borderColor: 'info.light', borderWidth: 1.5, borderStyle: 'solid' }}
                  severity="info"
                >
                  <Typography variant="h6" fontWeight="normal">
                    Drag the pin to reposition as needed
                  </Typography>
                </Alert>
              </Grid>
            )}
            <Grid item xs>
              <Box sx={{ mt: 2 }}>
                <AddressFormFields onAddressChange={handleAddressChange} addressNameLabel={T.ATTENTION} showAddressNameField />
              </Box>
            </Grid>
            {!isTabletOrMobile && (
              <Grid item container>
                <Alert
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: isTabletOrMobile ? undefined : 536,
                    borderColor: 'info.light',
                    borderWidth: 1.5,
                    borderStyle: 'solid',
                  }}
                  severity="info"
                >
                  <Typography variant="h6" fontWeight="normal">
                    Drag the pin to reposition as needed
                  </Typography>
                </Alert>
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton loading={isLoading} onClick={handleSubmit(handleSave)} />
      </HHDialogActions>
    </Dialog>
  )
}

EditBillingLocationModal.propTypes = {
  open: PropTypes.bool,
  customerDetails: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default EditBillingLocationModal
