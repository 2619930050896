import React, { useCallback, useEffect, useRef } from 'react'
import {
  setBulkMoveBetweenDaysState,
  selectBulkMoveFocusRowIndex,
  selectBulkMoveFocusName,
  selectBulkMoveStopsDate,
  selectIsOpenBulkMoveConfirmCloseDialog,
  selectBulkMoveToRows,
  selectIsSelectDateDialogOpen,
  selectBulkMoveFromSerializedDate,
  selectBulkMoveToSerializedDate,
  selectBulkMoveRoutes,
  selectIsOpenBulkMoveBetweenDaysStopsDialog,
  selectBulkMoveRouteId,
  selectBulkMoveFetchId,
  selectToBeInsertedRowsIds,
  selectBulkMoveStopsToBeAssignedCount,
} from 'slices/route/bulkMoveBetweenDaysSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DialogContent, Box } from '@mui/material'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import { FormProvider, useForm } from 'react-hook-form'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { deserializeDate, formatDateToBEFormatDateFns, formatDateToFEFormatDateFns, serializeDate } from 'utils/date'
import SelectStopsToMoveMap from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/SelectStopsToMoveMap'
import MoveFromRouteColumn from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/MoveFromRouteColumn'
import MoveToRouteColumn from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/MoveToRouteColumn'
import { get, keyBy } from 'lodash'
import { MapProvider } from 'providers/MapProvider'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { useLazyGetRouteStopsGeojsonQuery } from 'api/route/getRouteStops'
import useTheme from '@mui/material/styles/useTheme'
import { isValidHex } from 'utils/validations'
import { common } from '@mui/material/colors'
import SequenceDialog from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/SequenceDialog'
import debounce from 'lodash/debounce'
import BulkMoveFailure from 'containers/new-route-manager/BulkMoveFailure'
import { selectBulkMoveFailureStops, selectIsBulkMoveFailureOpen, setBulkMoveFailure } from 'slices/route/routeSlice'
import {
  BULK_MOVE_MAP_LAYERS,
  formatGeojsonDataBetweenDays,
  getBulkMoveStopColumnSx,
  removeLayersAndSources,
  ROW_HEIGHT,
} from 'components/route-manager/BulkActions/settings'
import UnsavedChangesConfirmDialog from 'components/route-manager/BulkActions/common/UnsavedChangesConfirmDialog'
import BulkMoveStopsBetweenDaysDialogActions from 'components/route-manager/BulkActions/BulkMoveStopsBetweenDaysDialog/BulkMoveStopsBetweenDaysDialogActions'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'
import BulkDialogAppbar from 'components/route-manager/BulkActions/common/BulkDialogAppbar'
import SelectDateDialogBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/SelectDateDialogBetweenDaysVariant'
import useGetBEBatchesBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/useGetBEBatchesBetweenDaysVariant'
import useSplitValidInvalidKeysFromFormValues from 'components/route-manager/BulkActions/common/useSplitValidInvalidKeysFromFormValues'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const defaultValues = {}

const BulkMoveStopsBetweenDaysDialog = () => {
  const routeId = useSelector(selectBulkMoveRouteId)
  const moveToGridApiRef = useGridApiRef()
  const moveFromGridApiRef = useGridApiRef()
  const map = useRef()
  const open = useSelector(selectIsOpenBulkMoveBetweenDaysStopsDialog)
  const isConfirmCloseDialogOpen = useSelector(selectIsOpenBulkMoveConfirmCloseDialog)
  const dispatch = useDispatch()
  const methods = useForm({
    defaultValues,
  })
  const theme = useTheme()
  const [getRouteStopsGeojson] = useLazyGetRouteStopsGeojsonQuery()

  const serializedServiceDate = useSelector(selectBulkMoveStopsDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const { reset, watch, getValues, setFocus } = methods
  const moveToRows = useSelector(selectBulkMoveToRows)
  const focusName = useSelector(selectBulkMoveFocusName)
  const focusRowIndex = useSelector(selectBulkMoveFocusRowIndex)
  const [getRoutesMetadata] = useLazyGetRoutesMetadataByServiceDateQuery()
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const toFEDate = toDate ? formatDateToFEFormatDateFns(toDate) : null
  const routes = useSelector(selectBulkMoveRoutes)
  const isOpenBulkMoveFailure = useSelector(selectIsBulkMoveFailureOpen)
  const bulkMoveFailureStops = useSelector(selectBulkMoveFailureStops)
  const fetchId = useSelector(selectBulkMoveFetchId)
  const columnSx = getBulkMoveStopColumnSx(theme)
  const toBeInsertedRowsIds = useSelector(selectToBeInsertedRowsIds)
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const splitValidInvalidKeysFromFormValues = useSplitValidInvalidKeysFromFormValues(toBeInsertedRowsIds)

  const handleReset = (isSelectDateDialogOpen = true) => {
    const mapCurrentRef = map.current
    if (!mapCurrentRef) return
    if (fromFEDate) removeLayersAndSources(mapCurrentRef, fromFEDate)
    if (toFEDate) removeLayersAndSources(mapCurrentRef, toFEDate)
    dispatch(
      setBulkMoveBetweenDaysState({
        isSelectDateDialogOpen,
        isUnsavedChangesModalOpen: false,
        moveFromRouteId: '',
        moveToRouteId: '',
        moveFromRows: [],
        moveToRows: [],
        selectedMoveFromRows: [],
        selectedMoveToRows: [],
        moveFromRouteStopMarkers: {},
        moveToRouteStopMarkers: {},
        moveFromRouteSequenceLine: {},
        moveToRouteSequenceLine: {},
        focus: {
          name: '',
          rowIndex: '',
        },
        mapLayer: FROM_ROUTE_LAYER,
      })
    )
    reset()
  }

  const handleClose = useCallback(() => {
    if (stopsToBeAssignedCount === 0) {
      handleReset(false)
      dispatch(
        setBulkMoveBetweenDaysState({
          isOpen: false,
          serializedServiceDate: serializeDate(new Date()),
        })
      )
    } else {
      dispatch(
        setBulkMoveBetweenDaysState({
          isConfirmCloseDialogOpen: true,
        })
      )
    }
  }, [stopsToBeAssignedCount])

  const getBEBatches = useGetBEBatchesBetweenDaysVariant({
    moveToRows,
    routeId,
    toSerializedDate,
  })

  const onCancelCloseDialog = () => {
    dispatch(
      setBulkMoveBetweenDaysState({
        isConfirmCloseDialogOpen: false,
      })
    )
  }

  const onConfirmCloseDialog = () => {
    handleReset(false)
    dispatch(
      setBulkMoveBetweenDaysState({
        isConfirmCloseDialogOpen: false,
        isOpen: false,
      })
    )
  }

  const onCloseSelectDateDialog = () => {
    dispatch(
      setBulkMoveBetweenDaysState({
        isSelectDateDialogOpen: false,
        serializedServiceDate: serializeDate(new Date()),
      })
    )
  }

  const fetchAndFormatGeojsonData = useCallback(
    ({ routeId, date, markersStateKey, sequenceLineStateKey }) => {
      getRouteStopsGeojson(
        {
          serviceDate: formatDateToBEFormatDateFns(date),
          routes: [routeId],
          id: fetchId,
        },
        false
      )
        .unwrap()
        .then(data => {
          const { stopMarkers, sequenceLine } = formatGeojsonDataBetweenDays(data, formatDateToFEFormatDateFns(date), routeId, theme)
          dispatch(
            setBulkMoveBetweenDaysState({
              [markersStateKey]: stopMarkers,
              [sequenceLineStateKey]: sequenceLine,
            })
          )
        })
    },
    [fetchId]
  )

  const onConfirmSelectDateDialog = ({ fromDate: newFromDate, toDate: newToDate, routeId: newRouteId }) => {
    dispatch(
      setBulkMoveBetweenDaysState({
        isSelectDateDialogOpen: false,
        isOpen: true,
        fromSerializedDate: serializeDate(newFromDate),
        toSerializedDate: serializeDate(newToDate),
        routeId: newRouteId,
        fetchId: crypto.randomUUID(),
        toBeInsertedRowsIds: [],
      })
    )
    getRoutesMetadata({
      serviceDate: formatDateToBEFormatDateFns(newFromDate),
    })
      .unwrap()
      .then(routesMetaData => {
        const routes = get(routesMetaData, 'routes', [])
        const formattedRoutes = routes.map(route => {
          const color = get(route, 'color', common.black)
          const formattedColor = isValidHex(color) ? color : common.black
          const contrastColor = theme.palette.getContrastText(formattedColor)
          return {
            ...route,
            color: formattedColor,
            contrastColor,
          }
        })
        dispatch(
          setBulkMoveBetweenDaysState({
            routesById: keyBy(formattedRoutes, 'id'),
            routes,
          })
        )
        if (newFromDate) {
          fetchAndFormatGeojsonData({
            routeId: newRouteId,
            date: newFromDate,
            markersStateKey: 'moveFromRouteStopMarkers',
            sequenceLineStateKey: 'moveFromRouteSequenceLine',
          })
        }
        if (newToDate) {
          fetchAndFormatGeojsonData({
            routeId: newRouteId,
            date: newToDate,
            markersStateKey: 'moveToRouteStopMarkers',
            sequenceLineStateKey: 'moveToRouteSequenceLine',
          })
        }
      })
  }
  const onCloseBulkMoveFailure = () => {
    dispatch(setBulkMoveFailure({ isOpen: false, stops: [] }))
  }

  useEffect(() => {
    const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(getValues())
    dispatch(
      setBulkMoveBetweenDaysState({
        invalidStopsToBeAssignedCount: invalidKeys.length,
        stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
        validStopIdsToBeAssigned: validKeys,
      })
    )
  }, [toBeInsertedRowsIds])

  useEffect(() => {
    let timeoutId
    if (focusName) {
      const fixedFocusIndex = focusRowIndex || 0
      setTimeout(() => {
        scrollToSelectedRowInDataGrid(moveToGridApiRef, fixedFocusIndex, ROW_HEIGHT)
        setTimeout(() => {
          if (focusName) {
            setFocus(focusName)
          }
          dispatch(
            setBulkMoveBetweenDaysState({
              focus: {
                name: '',
                rowIndex: 0,
              },
            })
          )
        }, 100)
      }, 100)
    }

    return () => clearTimeout(timeoutId)
  }, [focusName])

  useEffect(() => {
    const debouncedUpdate = debounce(values => {
      const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(values)
      dispatch(
        setBulkMoveBetweenDaysState({
          invalidStopsToBeAssignedCount: invalidKeys.length,
          stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
          validStopIdsToBeAssigned: validKeys,
        })
      )
    }, 1)

    const subscription = watch(values => {
      debouncedUpdate(values)
    })

    return () => {
      debouncedUpdate.cancel()
      subscription.unsubscribe()
    }
  }, [watch, toBeInsertedRowsIds])

  useEffect(() => {
    return () => {
      handleReset(false)
    }
  }, [])

  useEffect(() => {
    dispatch(
      setBulkMoveBetweenDaysState({
        toBeInsertedRowsIds: [],
      })
    )
  }, [routeId, fromSerializedDate, toSerializedDate])

  return (
    <>
      <FormProvider {...methods}>
        <MapProvider map={map}>
          <HHFullScreenBaseDialog open={open} onClose={handleClose}>
            <BulkDialogAppbar title="Move between days" onClose={handleClose} showDate={false} />
            <DialogContent dividers sx={{ p: 0 }}>
              <Box display="flex" direction="row" flexWrap="nowrap" height="100%" overflow="hidden">
                <Box mt={5} display="flex" flexDirection="column" sx={columnSx}>
                  <MoveFromRouteColumn apiRef={moveFromGridApiRef} fetchAndFormatGeojsonData={fetchAndFormatGeojsonData} />
                </Box>
                <Box mt={5} display="flex" flexDirection="column" sx={{ order: 3, ...columnSx }}>
                  <MoveToRouteColumn apiRef={moveToGridApiRef} routes={routes} fetchAndFormatGeojsonData={fetchAndFormatGeojsonData} />
                </Box>
                <Box flex={1}>{open && <SelectStopsToMoveMap />}</Box>
              </Box>
            </DialogContent>
            <BulkMoveStopsBetweenDaysDialogActions
              getBEBatches={getBEBatches}
              onClose={handleClose}
              onReset={handleReset}
              onConfirmSelectDateDialog={onConfirmSelectDateDialog}
            />
          </HHFullScreenBaseDialog>
          <SequenceDialog moveFromGridApiRef={moveFromGridApiRef} moveToGridApiRef={moveToGridApiRef} />
        </MapProvider>
      </FormProvider>
      <UnsavedChangesConfirmDialog isOpen={isConfirmCloseDialogOpen} onClose={onCancelCloseDialog} onConfirm={onConfirmCloseDialog} />
      <SelectDateDialogBetweenDaysVariant
        title="Bulk move between days"
        isPermanent={false}
        isOpen={isSelectDateDialogOpen}
        onConfirm={onConfirmSelectDateDialog}
        onClose={onCloseSelectDateDialog}
        value={serviceDate}
        DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
      />
      <BulkMoveFailure isOpen={isOpenBulkMoveFailure} stops={bulkMoveFailureStops} onClose={onCloseBulkMoveFailure} />
    </>
  )
}

BulkMoveStopsBetweenDaysDialog.propTypes = {}

export default BulkMoveStopsBetweenDaysDialog
