import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Link } from 'react-router-dom'

import { TableBody, TableRow, Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { getCustomerDetailsPageUrl } from 'router/routes'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import { BillingTableCell, StyledNoResultTypography } from 'components/billing/BillingCommonMethods'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getRecurrenceText } from 'utils/service'
import { getBillingColumnData, handleTableClass } from 'utils/billing'

import T from 'T'

const ReportTableBody = ({ isLoading = false, columns = [], lockedColumns = [], allTableRows = [], refreshTable = noop }) => {
  const getStatusColumnName = (reportSubType, columnValue) => {
    if (reportSubType === 'invoiceStatus' && columnValue === T.PARTIAL) {
      return 'Draft'
    }
    return columnValue
  }

  const getStatusClassName = (reportSubType, columnValue) => {
    const status = columnValue.toLowerCase().split(' ').join('-')

    switch (true) {
      case ['active', 'approved', 'complete', 'paid', 'serviced'].includes(status):
        return 'success.main'
      case ['partial', 'scheduled'].includes(status):
        return 'primary.main'
      case ['unpaid', 'in-process'].includes(status):
        return 'warning.main'
      case ['deactivated', 'declined', 'not-started'].includes(status):
        return 'error.main'
      case ['draft', 'canceled'].includes(status):
        return 'grey[300]'
      default:
        return ''
    }
  }

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map(row => {
          return (
            <TableRow>
              {Children.toArray(
                columns.map((reportColumn, index) => {
                  const reportClassName = get(reportColumn, 'className', '')
                  const reportSubType = get(reportColumn, 'subType', '')
                  const reportIsLocked = get(reportColumn, 'locked', false)

                  const { columnValue } = getBillingColumnData(row, reportColumn.columnName, reportSubType)

                  return (
                    <>
                      <BillingTableCell
                        align={reportColumn.align}
                        width={reportColumn.width}
                        className={` ${reportClassName} ${reportSubType} ${handleTableClass(index, reportIsLocked, lockedColumns)}`}
                      >
                        {[`${T.LAST_WORK_ORDER} Status`, T.STATUS].includes(reportColumn.tableLabel) && columnValue && (
                          <Box>
                            <CircleIcon sx={{ width: 10, height: 10, mr: 0.5, color: getStatusClassName(reportSubType, columnValue) }} />
                            {/* Refactor reports to follow the same as billing */}
                            {getStatusColumnName(reportSubType, columnValue)}
                          </Box>
                        )}

                        {reportColumn.label === T.CUSTOMER && columnValue && row.accountId && (
                          <Link
                            to={`${getCustomerDetailsPageUrl(row.accountId)}`}
                            style={{ textOverflow: 'ellipsis', display: 'block', overflow: 'hidden' }}
                          >
                            {columnValue}
                          </Link>
                        )}

                        {reportColumn.label === T.TAGS && (
                          <InteractiveTagsList
                            data={{ ...row, name: get(row, 'customerName', '') }}
                            disableService
                            disableLocation
                            limit={1}
                            onSave={(data, closeCallback) => {
                              refreshTable()
                              closeCallback()
                            }}
                            tagsFlexWrap="nowrap"
                          />
                        )}

                        {reportColumn.label === T.RECURRENCE &&
                          getRecurrenceText(row.recurrence?.recurrencePer, row.recurrence?.recurrenceInterval, row.recurrenceFrequency)}

                        {![T.STATUS, `${T.LAST_WORK_ORDER} Status`, T.CUSTOMER, T.RECURRENCE, T.TAGS].includes(reportColumn.tableLabel) &&
                          columnValue}
                      </BillingTableCell>
                    </>
                  )
                })
              )}
            </TableRow>
          )
        })
      )}

      {(!allTableRows || allTableRows.length === 0) && !isLoading && (
        <TableRow>
          <BillingTableCell colspan="100%" sx={{ border: 'none' }}>
            <StyledNoResultTypography variant="h4">{T.NO_REPORTS_FOUND}</StyledNoResultTypography>
          </BillingTableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

ReportTableBody.propTypes = {
  lockedColumns: PropTypes.array,
  allTableRows: PropTypes.array,
  isLoading: PropTypes.bool,
  columns: PropTypes.array,
  refreshTable: PropTypes.func,
}

export default memo(ReportTableBody)
