import T from 'T'

const { DATE, WO, ROUTE, PHOTO, NOTE, MEASURE, EXCEPTION, FEE_TOTAL } = T

export const WORK_ORDER_COLUMNS = [
  { label: DATE },
  { label: WO },
  { label: ROUTE },
  { label: MEASURE },
  { label: EXCEPTION },
  { label: NOTE },
  { label: PHOTO, className: 'text-center' },
  { label: FEE_TOTAL },
]
