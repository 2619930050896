import { parseAddress } from 'components/billing/groups/invoice/printable-view/helpers/address'
import { createSelector } from 'reselect'

export const getInvoicesExistinBucket = createSelector(
  [state => state?.invoices, state => state?.rawInvoicesData],
  (invoices = [], rawInvoicesData) => {
    if (!rawInvoicesData) {
      return []
    }

    return invoices.filter(invoice => invoice?.id in rawInvoicesData)
  }
)

export const parseAddressSelector = createSelector([state => state?.addressStr], (addressStr = '') => parseAddress(addressStr))
