import React from 'react'
import { Box } from '@mui/material'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { useSearchParams } from 'react-router-dom-v5-compat'
import Header from '../../../components/header'
import CreateInvoicesTopSection from '../../../components/billing/create-invoices/CreateInvoicesTopSection'
import CreateInvoicesDataGrid from '../../../components/billing/create-invoices/CreateInvoicesDataGrid'
import { CommonTableFilters } from '../../../components/common'
import { filterMapToQueryParams } from '../../../utils/LHSbracket'
import { handleFilterInputChange } from '../../../utils/table'
import { setCreateInvoicesFilters, setCreateInvoicesPage, setCreateInvoicesRefetchFlag } from '../../../slices/billing/createInvoicesSlice'
import BillingSecondaryNavBar from '../../../components/billing/common/BillingSecondaryNavBar'
import BillingSideNav from '../../../components/billing/common/side-nav/BillingSideNav'
import BillingLayout from '../../../components/billing/common/BillingLayout'
import T from '../../../T'

const CreateInvoices = () => {
  const [_, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const allFilters = useSelector(state => state.createInvoicesSlice.filters.allFilters, shallowEqual)
  const isOpenTableFilters = useSelector(state => state.createInvoicesSlice.filters.isOpenTableFilters)
  const activeFilter = useSelector(state => state.createInvoicesSlice.filters.activeFilter)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  const handleFilterApply = () => {
    const queryParams = filterMapToQueryParams(allFilters)
    setSearchParams({ ...queryParams })
    dispatch(setCreateInvoicesPage(0))
    dispatch(setCreateInvoicesRefetchFlag(true))
  }

  const handleFilterChange = (key, value, columnName) => {
    const newFiltersState = handleFilterInputChange(key, value, columnName, allFilters)
    dispatch(
      setCreateInvoicesFilters({
        ...newFiltersState,
        refetchFlag: false,
      })
    )
  }

  const handleFilterClose = () => {
    let newAllFilters = allFilters
    newAllFilters = omit(allFilters, activeFilter)
    const queryParams = filterMapToQueryParams(newAllFilters)
    setSearchParams({ ...queryParams })
    dispatch(setCreateInvoicesPage(0))
    dispatch(setCreateInvoicesFilters({ allFilters: newAllFilters, isOpenTableFilters: false, refetchFlag: true }))
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100vw">
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <Header />
        <BillingSecondaryNavBar heading={T.CREATE_DRAFT_INVOICES} />
        <Box overflow="auto" flex={1} display="flex" flexDirection="column" backgroundColor="background.paper">
          <CreateInvoicesTopSection />
          <CreateInvoicesDataGrid />
        </Box>
      </BillingLayout>
      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        label={get(allFilters, `${activeFilter}.label`)}
        isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
        isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
        filterComponent="billing"
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={handleFilterClose}
      />
    </Box>
  )
}

export default CreateInvoices
