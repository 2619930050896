import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { DialogContent, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useInviteUserToCustomerPortalMutation } from 'api/customer-portal/inviteUserToCustomerPortal'
import { CACHE_TAG_PORTAL_USER_LIST } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import AddCustomerPortalUserSuccessDialog from './AddCustomerPortalUserSuccessDialog'
import FormFields from './FormFields'
import { getAccountEmailContacts } from './utils'

const AddCustomerPortalUserDialog = ({
  isOpen = false,
  accountId,
  contacts = [],
  portalUserList = [],
  addToContact = true,
  parent = '',
  onClose,
}) => {
  const dispatch = useDispatch()
  const [inviteUserToCustomerPortal, { isLoading: isInviteUserToPortalLoading }] = useInviteUserToCustomerPortalMutation()
  const addUserForm = useForm({ defaultValues: { firstName: '', lastName: '', email: '', confirmEmail: '', contactId: '' } })
  const [isOpenAddPortalUserDialog, setIsOpenAddPortalUserDialog] = useState(false)
  const { watch, reset, handleSubmit } = addUserForm

  const watchEmail = watch('email')
  const watchFirstName = watch('firstName')
  const watchLastName = watch('lastName')
  const emailContacts = getAccountEmailContacts(contacts, portalUserList)

  const handleSuccess = () => {
    dispatch(api.util.invalidateTags([CACHE_TAG_PORTAL_USER_LIST]))
    onClose()
    setIsOpenAddPortalUserDialog(true)
  }

  const onSubmitHandler = async data => {
    inviteUserToCustomerPortal({
      accountId,
      payload: {
        email: data.email,
        firstName: get(data, 'firstName', ''),
        lastName: get(data, 'lastName', ''),
      },
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
        <HHDialogTitle title={T.INVITE_CUSTOMER_PORTAL_USER} onClose={onClose} />
        <DialogContent>
          <FormProvider {...addUserForm}>
            <Box mt={2}>
              <FormFields emailContacts={emailContacts} onSubmit={onSubmitHandler} />
            </Box>
          </FormProvider>
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <LoadingButton
            loading={isInviteUserToPortalLoading}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmitHandler)}
          >
            {T.INVITE}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
      <AddCustomerPortalUserSuccessDialog
        isOpen={isOpenAddPortalUserDialog}
        firstName={watchFirstName}
        lastName={watchLastName}
        email={watchEmail}
        accountId={accountId}
        isNewUser={addToContact ? !emailContacts.find(contact => contact.methodValue === watchEmail) : false}
        parent={parent}
        onClose={() => setIsOpenAddPortalUserDialog(false)}
      />
    </>
  )
}

AddCustomerPortalUserDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  contacts: PropTypes.array,
  portalUserList: PropTypes.object,
  addToContact: PropTypes.bool,
  parent: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default AddCustomerPortalUserDialog
