import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Box, Typography, Grid, Stack, Divider } from '@mui/material'

import { getPaymentMethod, getPaymentMethodImage } from 'utils/payment'

import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import { PAYMENT_METHODS, PAYMENT_METHOD_IMAGES, DEBIT, CREDIT } from 'settings/constants/payment'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PaymentStatusChip from 'components/common/chip/PaymentStatusChip'
import { green, red } from '@mui/material/colors'
import { TOTAL_COLUMN_WIDTH, AMOUNT_APPLIED_COLUMN_WIDTH } from './settings'

const { ACH, CREDIT_CARD } = PAYMENT_METHODS

const PaymentActivity = ({ chipGridWidth = 120, payment, showAppliedColumn = false, align = 'center', marginY = 0 }) => {
  const {
    transactionStatus,
    paymentMethod,
    transactionAmountCents,
    transactionPostedDate,
    creditCardType,
    accountLastFour,
    paymentAppliedAmountCents,
    batchId,
    debitCredit,
  } = payment || {}
  const paymentMethodName = paymentMethod === ACH ? paymentMethod : capitalize(getPaymentMethod(paymentMethod))
  const { fullDateTime } = convertDateTimeToPreferedTimezone({ dateTime: transactionPostedDate })
  const paymentMethodImage = paymentMethod === CREDIT_CARD ? getPaymentMethodImage(creditCardType) : PAYMENT_METHOD_IMAGES[paymentMethod]
  const isDebit = debitCredit === DEBIT
  const isCredit = debitCredit === CREDIT
  const debitCreditSx = {
    ...(isDebit && { color: green[500] }),
    ...(isCredit && { color: red[500] }),
  }
  return (
    <>
      <Grid container item xs={12} spacing={2.5} wrap="nowrap" alignItems={align}>
        <Grid item xs="auto" minWidth={chipGridWidth} my={marginY}>
          <PaymentStatusChip status={transactionStatus} />
        </Grid>
        <Grid item xs my={marginY}>
          <Box display="flex" alignItems="center">
            <img src={paymentMethodImage} alt="" />
            <Stack ml={1}>
              <Typography display="inline" variant="h6" fontWeight={500}>
                {creditCardType && accountLastFour ? `${creditCardType} •••• ${accountLastFour}` : paymentMethodName}
              </Typography>
              <Typography variant="caption">{batchId}</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item width={TOTAL_COLUMN_WIDTH} my={marginY}>
          <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h6" fontWeight={500}>
            <HHDisplayMoney value={transactionAmountCents} />
          </Typography>
          <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
            {fullDateTime}
          </Typography>
        </Grid>
        {showAppliedColumn && (
          <>
            <Grid item xs="auto" display="flex">
              <Divider flexItem orientation="vertical" />
            </Grid>
            <Grid item width={AMOUNT_APPLIED_COLUMN_WIDTH} my={marginY}>
              <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h6" fontWeight={500} sx={debitCreditSx}>
                {isDebit && '+'}
                {isCredit && '-'}
                <HHDisplayMoney value={paymentAppliedAmountCents} />
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

PaymentActivity.propTypes = {
  chipGridWidth: PropTypes.number,
  payment: PropTypes.object.isRequired,
  showAppliedColumn: PropTypes.string,
  align: PropTypes.string,
  marginY: PropTypes.string,
}

export default PaymentActivity
