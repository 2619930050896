import React from 'react'
import PropTypes from 'prop-types'

import { FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'
import T from 'T'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Popover } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import useTheme from '@mui/material/styles/useTheme'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

const ChangeRouteDate = ({
  currentDate = new Date(),
  routeDateMenu = {},
  handleClose,
  handleDateChange,
  handleCurrentRouteDate = () => {},
}) => {
  const theme = useTheme()
  const openRouteDate = Boolean(routeDateMenu)

  const handleCalendarChange = inDate => {
    handleCurrentRouteDate(inDate)
  }

  const onSave = () => {
    handleDateChange(currentDate)
  }
  return (
    <Popover
      PaperProps={{
        sx: {
          border: `1.5px solid ${theme.palette.divider}`,
        },
      }}
      anchorEl={routeDateMenu}
      open={openRouteDate}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <HHDialogTitle onClose={handleClose} title={T.MOVE_ROUTE_TO} />
      <DialogContent>
        <HHDesktopDatePicker format={FRONTEND_DATE_FORMAT_DATE_FNS} onChange={handleCalendarChange} value={currentDate} />
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={handleClose} />
        <SaveButton onClick={onSave} />
      </HHDialogActions>
    </Popover>
  )
}

ChangeRouteDate.propTypes = {
  currentDate: PropTypes.string,
  routeDateMenu: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleCurrentRouteDate: PropTypes.func,
}

export default ChangeRouteDate
