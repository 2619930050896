import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { get } from 'lodash'
import BorderlessDataGridPro from 'components/common/BorderlessDataGridPro'
import Box from '@mui/material/Box'
import PartialPaidInvoiceRenderCell from 'components/billing/invoices/RegenerateInvoiceConfirmation/PartialPaidInvoiceRenderCell'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { useTheme } from '@mui/material'

const { PARTIAL } = INVOICE_STATUS

const RegenerateInvoiceConfirmation = ({ isOpen = false, invoices = [], onRegenerate, onClose }) => {
  const theme = useTheme()
  const [partialPaidInvoices, setPartialPaidInvoices] = useState([])
  const rowsExceedsLimit = partialPaidInvoices.length > 20
  const hasPartialPaidInvoices = Array.isArray(partialPaidInvoices) && partialPaidInvoices.length !== 0
  const getRowId = row => get(row, 'invoiceNumber')
  const columns = [
    {
      flex: 1,
      field: 'id',
      sortable: true,
      filterable: false,
      resizable: false,
      renderHeader: () => {},
      renderCell: PartialPaidInvoiceRenderCell,
    },
  ]

  useEffect(() => {
    if (Array.isArray(invoices) && invoices.length && isOpen) {
      const filteredInvoices = invoices.filter(invoice => {
        const status = get(invoice, 'status')
        const amountPaidCents = get(invoice, 'amountPaidCents')
        return status === PARTIAL || amountPaidCents !== 0
      })
      setPartialPaidInvoices(filteredInvoices)
    } else {
      setPartialPaidInvoices([])
    }
  }, [invoices, isOpen])

  return (
    <Dialog
      sx={{
        zIndex: theme.zIndex.snackbar,
      }}
      maxWidth="md"
      open={isOpen}
    >
      <HHDialogTitle title="Regenerate invoice" onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentText>
          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            {`Regenerating ${invoices.length === 1 ? 'this invoice' : 'these invoices'} will also pull in all current work orders that are
              marked as ready for invoice.`}
            <br />
            <br />
            The following invoice(s) will not be regenerated because there are payments associated with it
          </Typography>
        </DialogContentText>
        {hasPartialPaidInvoices && (
          <>
            <Box mt={2} mb={2} height={rowsExceedsLimit ? 832 : 'auto'} overflow="auto">
              <BorderlessDataGridPro
                sx={{
                  '& .MuiDataGrid-cell': {
                    borderTopWidth: 1,
                    borderTopStyle: 'solid',
                    borderTopColor: 'divider',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'divider',
                  },
                }}
                columns={columns}
                rows={partialPaidInvoices}
                getRowId={getRowId}
                columnHeaderHeight={0}
                hideFooter
              />
            </Box>
            <Typography variant="h5" fontWeight={400}>
              Do you want to proceed?
            </Typography>
          </>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <Button size="small" variant="contained" color="primary" onClick={onRegenerate}>
          Yes
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

RegenerateInvoiceConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  invoices: PropTypes.array,
  onRegenerate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RegenerateInvoiceConfirmation
