import api from 'api'
import { CACHE_TAG_ACCOUNT_GROUP_DETAILS } from 'api/cacheTagTypes'
import { transformSearchResponse } from 'utils/accountSearch'
import { get } from 'utils/lodash'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getAccountGroupDetails: build.query({
      query: ({ id, includeTags = true, includeContacts = false, includeDeactivated = true }) => ({
        url: `/api/v1/core/accounts/groups/${id}`,
        method: 'GET',
        params: { includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: data => {
        const { accounts } = transformSearchResponse({ results: get(data, 'accounts', []) })
        return { ...data, accounts }
      },
      providesTags: (result, error, { id }) => [CACHE_TAG_ACCOUNT_GROUP_DETAILS, { type: CACHE_TAG_ACCOUNT_GROUP_DETAILS, id }],
    }),
  }),
})

export const { useGetAccountGroupDetailsQuery, useLazyGetAccountGroupDetailsQuery } = extendedApi
