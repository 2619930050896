import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Typography, Card, CardHeader, CardActions, Tooltip, CardContent, Box, CircularProgress, useTheme } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { getPaymentsFromBatch } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { ReactComponent as LayerIcon } from 'assets/Layer.svg'
import { Link } from 'react-router-dom-v5-compat'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import routes from 'router/routes'
import PaymentGroupActionMenu from './PaymentGroupActionMenu'
import PaymentTypeStatistic from './PaymentTypeStatistic'
import PaymentTransactionStatistic from './PaymentTransactionStatistic'
import CarouselWrapper from '../common/CarouselWrapper'
import TakePaymentsDialog from './take-payments'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const PaymentGroupCard = ({ group }) => {
  const theme = useTheme()
  const [paymentGroupAnchorEl, setPaymentGroupAnchorEl] = useState(null)
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)
  const groupId = get(group, 'id')
  const createdBy = get(group, 'createdBy')
  const createdAt = get(group, 'createdAt')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })

  const { data, isFetching: isLoading } = useGetPaymentGroupDetailsQuery({ id: groupId })
  const allPayments = getPaymentsFromBatch({ paymentBatches: get(data, 'paymentBatches', []) })

  const groupDetailsLink = `${routes.app.paymentGroups}/${groupId}`

  const handleToggleTakePaymentsDialog = () => setIsOpenTakePaymentsDialog(!isOpenTakePaymentsDialog)

  return (
    <>
      <Card variant="outlined" sx={{ boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)' }}>
        <CardHeader
          sx={{
            py: 1,
            pl: 1.5,
            pr: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            '& .MuiCardHeader-avatar': { mr: 1 },
            '& .MuiCardHeader-content': { width: '60%' },
          }}
          avatar={<LayerIcon />}
          title={
            <Typography noWrap variant="h5" fontWeight={500}>
              {get(group, 'groupName', '')}
            </Typography>
          }
          action={
            <>
              <Tooltip title={T.VIEW_PAYMENT_GROUP_DETAIL}>
                <IconButton component={Link} to={groupDetailsLink}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={`${T.TAKE_PAYMENT}s`}>
                <IconButton onClick={handleToggleTakePaymentsDialog}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={event => setPaymentGroupAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Box height={310}>
            {isLoading && (
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {!isLoading && allPayments.length === 0 && (
              <Box height="100%" display="flex" alignItems="center">
                <HHAlert borderColor={theme.palette.info.light} severity="info">
                  This payment group is empty. Add payments from the + button above or in the payments page
                </HHAlert>
              </Box>
            )}

            {!isLoading && allPayments.length > 0 && (
              <CarouselWrapper>
                <PaymentTypeStatistic id={groupId} />
                <PaymentTransactionStatistic id={groupId} />
              </CarouselWrapper>
            )}
          </Box>
        </CardContent>
        <CardActions sx={{ px: 2, py: 1.5, justifyContent: 'space-between', borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="caption">{`Created by ${createdBy}`}</Typography>
          {createdAt && <Typography variant="caption">{`${onlyTime} | ${onlyDate}`}</Typography>}
        </CardActions>
      </Card>
      {paymentGroupAnchorEl && (
        <PaymentGroupActionMenu anchorEl={paymentGroupAnchorEl} selectedGroup={data} onClose={() => setPaymentGroupAnchorEl(false)} />
      )}
      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog isOpen={isOpenTakePaymentsDialog} initialGroupId={groupId} onClose={handleToggleTakePaymentsDialog} />
      )}
    </>
  )
}

PaymentGroupCard.propTypes = {
  group: PropTypes.object,
}

export default PaymentGroupCard
