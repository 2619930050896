import React from 'react'
import PropTypes from 'prop-types'

import SearchOptionRow from './SearchOptionRow'

const SearchCustomerOptionRow = ({ row = {} }) => {
  const { accountName, accountNumber, billingProfile = '', unpaidInvoiceCount = 0, unpaidTotalCents = 0, unpaidAmountPaidCents = 0 } = row

  return (
    <SearchOptionRow
      leftPrimaryText={accountName}
      leftSecondaryText={`${accountNumber} | ${billingProfile}`}
      rightPrimaryText={unpaidTotalCents - unpaidAmountPaidCents}
      rightSecondaryText={`${unpaidInvoiceCount} invoice${unpaidInvoiceCount > 1 ? 's' : ''}`}
    />
  )
}

SearchCustomerOptionRow.propTypes = {
  row: PropTypes.object,
}

export default SearchCustomerOptionRow
