import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    createWorkOrdersDisposalTicket: builder.mutation({
      query: ({ workOrderId, body }) => ({ url: `/api/v1/core/work-orders/${workOrderId}/disposal-tickets`, method: 'POST', body }),
    }),
  }),
})

export const { useCreateWorkOrdersDisposalTicketMutation } = extendedApi
