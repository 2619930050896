import { styled } from '@mui/material'

import { BILLING_SIDE_NAV_DRAWER_WIDTH } from './side-nav/settings'

const BillingLayout = styled('div', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${BILLING_SIDE_NAV_DRAWER_WIDTH}px`,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
    },
  }),
}))

export default BillingLayout
