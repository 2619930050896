import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import HHTextField from './HHTextField'

const HHFormDesktopDatePicker = ({
  label = '',
  name,
  control,
  rules = undefined,
  required = false,
  TextFieldProps = {},
  PopperProps = {},
  restSlots = {},
  restSlotProps = {},
  ...rest
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const onOpen = () => setIsCalendarOpen(true)
  const onClose = () => setIsCalendarOpen(false)
  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? { ...rules, required: required || rules?.required === true } : { required: required || rules?.required === true }}
      render={({ field }) => {
        const { ref, onChange, onBlur, value, ..._rest } = field
        return (
          <DesktopDatePicker
            label={label}
            open={isCalendarOpen}
            onOpen={onOpen}
            onClose={onClose}
            disableScrollLock
            value={value}
            slots={{ textField: HHTextField, ...restSlots }}
            slotProps={{
              textField: { onClick: onOpen, fullWidth: true, ...TextFieldProps },
              popper: { ...PopperProps },
              ...restSlotProps,
            }}
            onChange={onChange}
            {..._rest}
            {...rest}
          />
        )
      }}
    />
  )
}

HHFormDesktopDatePicker.propTypes = {
  label: PropTypes.string,
  TextFieldProps: PropTypes.object,
  PopperProps: PropTypes.object,
  restSlots: PropTypes.object,
  restSlotProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  required: PropTypes.bool,
}

export default HHFormDesktopDatePicker
