/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const createCustomerSlice = createSlice({
  name: 'createCustomer',
  initialState: {
    isCreateDialogOpen: false,
    isSearchByPhoneDialogOpen: false,
    isSearchByEmailDialogOpen: false,
  },
  reducers: {
    setIsCreateCustomerDialogOpen: (state, action) => {
      state.isCreateDialogOpen = action.payload
    },
    setIsSearchByPhoneCustomerDialogOpen: (state, action) => {
      state.isSearchByPhoneDialogOpen = action.payload
    },
    setIsSearchByEmailCustomerDialogOpen: (state, action) => {
      state.isSearchByEmailDialogOpen = action.payload
    },
  },
})

export const { setIsCreateCustomerDialogOpen, setIsSearchByPhoneCustomerDialogOpen, setIsSearchByEmailCustomerDialogOpen } =
  createCustomerSlice.actions
export default createCustomerSlice.reducer
