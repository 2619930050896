import T from 'T'

export const getOrdinal = day => {
  if (day > 3 && day < 21) return 'th'

  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const containsAllWeekendDays = days => [T.SATURDAY, T.SUNDAY].every(day => days.includes(day))
export const containsAllWorkingDays = days => [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY].every(day => days.includes(day))
