import React, { useEffect } from 'react'

import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { HelmetProvider } from 'react-helmet-async'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider as ThemeProviderV5, styled } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import Paper from '@mui/material/Paper'

import { store, sagaMiddleware, persistor } from 'providers/store'
import { register } from 'swiper/element/bundle'

import rootSaga from 'middleware/sagas'
import history from 'providers/history'

import MainContainer from 'components/common/MainContainer'
import haulerHeroThemeV4 from 'theme/themeV4'
import haulerHeroThemeV5 from 'theme/themeV5'
import MainRoute from 'router'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'styles/common.scss'
import './App.css'
import 'overrides.scss'
import WithFirebase from 'providers/WithFirebase'
import AuthProvider from 'auth/AuthContext'
import AppWrapper from './AppWrapper'

sagaMiddleware.run(rootSaga)

const MainContent = styled(Paper)`
  flex: 1;
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: #f7f9fc;
  border-radius: 0px;
  box-shadow: none;
`

const App = () => {
  useEffect(() => {
    register()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <WithFirebase>
            <StylesProvider injectFirst>
              <AppWrapper>
                <HelmetProvider>
                  <ThemeProviderV4 theme={haulerHeroThemeV4}>
                    <ThemeProviderV5 theme={haulerHeroThemeV5}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Router history={history}>
                          <CompatRouter>
                            <MainContainer>
                              <MainContent className="MainContentWrapper">
                                <MainRoute />
                              </MainContent>
                            </MainContainer>
                          </CompatRouter>
                        </Router>
                      </LocalizationProvider>
                    </ThemeProviderV5>
                  </ThemeProviderV4>
                </HelmetProvider>
              </AppWrapper>
            </StylesProvider>
          </WithFirebase>
        </AuthProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
