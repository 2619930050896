import React, { useReducer, useEffect, useState } from 'react'

import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { Add, ChevronRight } from '@mui/icons-material'

import { Button, Box, Typography } from '@mui/material'
import { getUserInfo } from 'middleware/actions/login'

import { INITIAL_ROLE_VALUES } from 'settings/constants/roles'

import { SETTINGS_PAGINATION } from 'settings/constants/pagination'

import T from 'T'

import { canAddSettings, canAddPermissions, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { useLazyGetProfileListQuery } from 'api/settings/getProfileList'
import { useAddProfileMutation } from 'api/settings/addProfile'
import { useLazyGetProfileByIdQuery } from 'api/settings/getProfileById'
import { handleError } from 'utils/error'

import { handleRolesPayload, setUserProfile } from './roleMethods'
import ListRoles from './roles/ListRoles'
import AddRoles from './roles/AddRoles'

const { INITIAL_PAGE, ROWS_PER_PAGE } = SETTINGS_PAGINATION

const RolesSettings = () => {
  const dispatch = useDispatch()
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const addPermissions = useSelector(canAddPermissions, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const [addNewRole, setAddNewRole] = useState(false)

  const [roleState, setRoleState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    roles: [],
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    totalRolesCount: 0,
    totalPageCount: 0,
    roadTab: T.DISPLAY,
    footerFlag: false,
    editID: '',
    currentRole: [],
  })

  const [getRoles, { isLoading, isFetching }] = useLazyGetProfileListQuery()
  const [addRoles] = useAddProfileMutation()
  const [getRoleById] = useLazyGetProfileByIdQuery()

  const setInitialValues = () => {
    setRoleState(INITIAL_ROLE_VALUES)
  }

  const { roles, page, rowsPerPage, profileName, active, allowRoadLogin, allowOfficeLogin, editID } = roleState

  const fetchRoles = (requestedPage, requestedPageSize) => {
    const payload = {
      requestedPage,
      requestedPageSize,
    }

    getRoles(payload)
      .unwrap()
      .then(({ profile, totalItems, totalPages }) => {
        setRoleState({
          roles: profile,
          totalRolesCount: totalItems,
          totalPageCount: totalPages,
          roadTab: T.DISPLAY,
          footerFlag: false,
          editID: '',
        })
      })
      .catch(() => {
        setRoleState({
          roles: [],
          totalRolesCount: 0,
          totalPageCount: 0,
          roadTab: T.DISPLAY,
          footerFlag: false,
          editID: '',
        })
      })
  }

  useEffect(() => {
    fetchRoles(page, rowsPerPage)
    setInitialValues()
  }, [])

  const handleAdd = () => {
    setAddNewRole(true)
  }

  const handleSave = () => {
    setAddNewRole(false)

    const payload = {
      profileName,
      active,
      allowOfficeLogin,
      allowRoadLogin,
    }

    handleRolesPayload(payload, roleState)

    if (roleState.editID !== '') {
      payload.id = editID
    }

    addRoles(payload)
      .unwrap()
      .then(() => {
        fetchRoles(page, rowsPerPage)
        setInitialValues()
        dispatch(getUserInfo(status => console.log(status)))
        window.location.reload()
      })
      .catch(handleError)
  }

  const handleEdit = id => {
    setAddNewRole(true)

    setRoleState({
      editID: id,
    })

    const payload = {
      id,
    }

    getRoleById(payload)
      .unwrap()
      .then(res => {
        setUserProfile(res, setRoleState)
      })
      .catch(handleError)
  }

  const handleCancel = () => {
    setAddNewRole(false)
    setInitialValues()
    fetchRoles(page, rowsPerPage)
  }

  const onHandleNameChange = event => {
    if (updateSettings) {
      const { name } = event.target

      let { value } = event.target

      if (/^\s/.test(value)) {
        value = ''
      }

      roleState[name] = value
      setRoleState({ [name]: value, footerFlag: true })
    }
  }

  // Page change handler
  const handlePageChange = newPage => {
    setRoleState({ page: newPage })
    fetchRoles(newPage, rowsPerPage)

    document.getElementsByClassName('MuiTable-root')[0].scrollTop = 0
  }

  // Rows per page change handler
  const handleRowsPerPageChange = event => {
    setRoleState({ page: INITIAL_PAGE, rowsPerPage: event.target.value })
    fetchRoles(INITIAL_PAGE, event.target.value)
  }

  return (
    <Box>
      <Box px={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p="2px 0">
          <Box display="flex" alignItems="center">
            <Typography
              variant="h3"
              color={addNewRole ? 'text.secondary' : 'text.primary'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCancel()}
            >
              {`${T.ROLE}s`}
            </Typography>

            {addNewRole && (
              <Box display="flex" alignItems="center">
                <ChevronRight fontSize="medium" sx={{ m: '0 4px' }} />
                <Typography variant="h3">{`${editID ? T.EDIT : T.NEW} ${T.ROLE}`}</Typography>
              </Box>
            )}
          </Box>

          {!addNewRole && addSettings && addPermissions && (
            <Button variant="outlined" startIcon={<Add />} onClick={() => handleAdd()}>
              {T.ADD}
            </Button>
          )}
        </Box>

        {addNewRole && (
          <AddRoles
            roleState={roleState}
            setRoleState={setRoleState}
            handleSave={handleSave}
            handleCancel={handleCancel}
            onHandleNameChange={onHandleNameChange}
          />
        )}
      </Box>

      {!addNewRole && (
        <ListRoles
          isLoading={isLoading || isFetching}
          roleState={roleState}
          roles={roles}
          handlePageChange={handlePageChange}
          handleEdit={handleEdit}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Box>
  )
}

export default RolesSettings
