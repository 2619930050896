import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Table as TableView, TableContainer, TableCell, Typography } from '@mui/material'

import MaterialTableBody from './MaterialTableBody'
import ActionMethodTableBody from './ActionMethodTableBody'

const Table = ({ headerValues = [], records = [], handleSwitchVal = noop, onHandleChange = noop, handleRemoval = noop, type = '' }) => {
  return (
    <TableContainer>
      <TableView stickyHeader aria-label="sticky table">
        {Children.toArray(
          headerValues &&
            headerValues.map(val => (
              <TableCell sx={{ p: '8px 12px 8px' }}>
                <Typography variant="subtitle2">{val.name}</Typography>
              </TableCell>
            ))
        )}
        {type === 'material' && (
          <MaterialTableBody
            values={records}
            handleSwitchVal={handleSwitchVal}
            onHandleChange={onHandleChange}
            handleRemoval={handleRemoval}
          />
        )}

        {type !== 'material' && (
          <ActionMethodTableBody values={records} onHandleChange={onHandleChange} handleRemoval={handleRemoval} type={type} />
        )}
      </TableView>
    </TableContainer>
  )
}

Table.propTypes = {
  type: PropTypes.string,
  headerValues: PropTypes.array,
  records: PropTypes.array,
  handleSwitchVal: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleRemoval: PropTypes.func,
}

export default Table
