import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'

import ValidatedTaxTableBody from './ValidatedTaxTableBody'
import ValidatedListingTableHeader from 'components/pricing/ValidatedListingTableHeader'

const ValidatedTaxTable = ({
  validatedTaxes,
  confServices,
  handleSingleChange,
  handleDelete,
  onHandleValidatedFieldsChange,
  type,
  getTaxServiceName,
  onTableScopeChange,
}) => {
  return (
    <>
      <TableContainer className="taxes-table">
        <TableView>
          <ValidatedListingTableHeader confServices={confServices} handleSingleChange={handleSingleChange} />

          <ValidatedTaxTableBody
            validatedTaxes={validatedTaxes}
            confServices={confServices}
            handleDelete={handleDelete}
            onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
            type={type}
            getTaxServiceName={getTaxServiceName}
            onTableScopeChange={onTableScopeChange}
          />
        </TableView>
      </TableContainer>
    </>
  )
}

ValidatedTaxTable.defaultProps = {
  validatedTaxes: [],
  confServices: [],
  type: 'unique',
  handleSingleChange: null,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getTaxServiceName: null,
  onTableScopeChange: null,
}

ValidatedTaxTable.propTypes = {
  validatedTaxes: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  handleSingleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  onTableScopeChange: PropTypes.func,
}

export default ValidatedTaxTable
