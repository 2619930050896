import { useMemo } from 'react'
import { getDurationMeasureUnit, getMeasureUnitLabel } from 'components/pricing/LateFees/CreateEditLateFeesDialog/settings'
import T from 'T'

const useFormatLateFee = lateFee => {
  const { id, feeName, duration, pricingPeriod, startOffset, amountCents, businessLine, accountCount } = lateFee
  const durationMeasureUnit = useMemo(() => getDurationMeasureUnit(pricingPeriod), [pricingPeriod])
  const formattedDuration = useMemo(
    () => (duration === 0 ? T.INDEFINITE : `${duration} ${getMeasureUnitLabel(duration, durationMeasureUnit)}`),
    [durationMeasureUnit, duration]
  )
  const formattedStartDate = useMemo(
    () => (startOffset === 0 ? T.IMMEDIATE : `${startOffset} ${getMeasureUnitLabel(startOffset, durationMeasureUnit)}`),
    [duration, durationMeasureUnit]
  )
  return { id, feeName, formattedDuration, formattedStartDate, pricingPeriod, amountCents, businessLine, accountCount }
}

export default useFormatLateFee
