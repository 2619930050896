import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { shallowEqual, useSelector } from 'react-redux'

import { TableBody as TBody, TableCell, TableRow, Checkbox, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'

import ServiceBody from 'components/pricing/Services/ServiceBody'
import TaxBody from 'components/pricing/Taxes/TaxBody'
import FeeBody from 'components/pricing/Fees/FeeBody'

import { handleCheckBoxClass } from 'components/pricing/pricingMethods'
import { canSeePricingTab } from 'data/permissions/permissionsSelectors'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import T from 'T'
import { noop } from 'lodash/util'
import { MoreVert } from '@mui/icons-material'
import AddRentalFeesDialog from './RentalFees/PricedServices/AddRemoveRentalFees/AddRentalFeesDialog'
import RemoveRentalFeesDialog from './RentalFees/PricedServices/AddRemoveRentalFees/RemoveRentalFeesDialog'

const TableBody = ({
  serviceList,
  handleChange,
  confServices,
  getPricingServiceName,
  getTaxServiceName,
  getFeeServiceName,
  checkedVal,
  allCheckedVal,
  columns,
  lockedColumns,
  type,
  saveConfSettings,
  toggleLinkServiceDrawer,
  handleActivation,
  onEditClick = noop,
  onUpdatePricingService,
}) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpenAddRentalFeeDialog, setIsOpenAddRentalFeeDialog] = useState(false)
  const [isOpenRemoveRentalFeeDialog, setIsOpenRemoveRentalFeeDialog] = useState(false)
  const [activeService, setActiveService] = useState({})
  const isActiveRow = get(activeService, 'active', false)
  const hasActiveLocations = get(activeService, 'activeLocationCount', 0) > 0
  const hasRentalFees = get(activeService, 'rentalFeeCount', 0) > 0
  const isServicesTab = type === 'services'
  const { isLoading } = useSelector(
    pricingState => ({
      isLoading: get(pricingState, 'ResponseReducer.isLoading', false),
    }),
    shallowEqual
  )

  // close menu function
  const handleClose = () => setAnchorEl(null)

  const handleMenuClicked = (event, serv) => {
    setAnchorEl(event.currentTarget)
    setActiveService(serv)
  }

  // handle click of user menus
  const handleClick = e => {
    const selection = e.target.textContent

    if (type === 'services' && activeService.configured && !['Link', 'Edit'].includes(selection)) return

    if (selection === 'Link') {
      toggleLinkServiceDrawer(true, activeService)
    } else if (selection === 'Deactivate') {
      handleActivation(false, activeService)
    } else if (selection === 'Activate') {
      handleActivation(true, activeService)
    } else if (selection === 'Edit') {
      onEditClick(e, activeService)
    }
    handleClose()
  }

  const [state, setState] = useState({
    expand: {},
  })

  const handleExpand = (id, status) => {
    expand[id] = status
    setState({
      expand,
    })
  }

  const { expand } = state

  const getNoRecordMsg = () => {
    if (type === 'services') {
      return T.NO_PRICING_SERVICES_FOUND
    }

    if (type === 'taxes') {
      return T.NO_PRICING_TAXES_FOUND
    }

    return T.NO_PRICING_FEES_FOUND
  }

  const handleOpenAddRentalFeeDialog = () => {
    setIsOpenAddRentalFeeDialog(true)
    handleClose()
  }

  const handleCloseAddRentalFeeDialog = () => {
    setIsOpenAddRentalFeeDialog(false)
  }

  const handleOpenRemoveRentalFeeDialog = () => {
    setIsOpenRemoveRentalFeeDialog(true)
    handleClose()
  }

  const handleCloseRemoveRentalFeeDialog = () => {
    setIsOpenRemoveRentalFeeDialog(false)
  }

  const handleAddRemoveRentalFeesSuccess = () => {
    // Temporary, Until we fix the old implementation
    onUpdatePricingService()
  }

  return (
    <>
      <TBody className="table-body">
        {(!serviceList || serviceList.length === 0) && !isLoading && (
          <TableRow>
            <TableCell colSpan="100%" className="no-border">
              <div className="no-results">{getNoRecordMsg()}</div>
            </TableCell>
          </TableRow>
        )}

        {serviceList &&
          columns &&
          Children.toArray(
            serviceList.map((service, index) => {
              let checkboxVal = false

              if (checkedVal[type] !== undefined) {
                checkboxVal = checkedVal[type][service.id]
              }

              if (expand[service.id] === undefined) {
                expand[service.id] = false
                setState({
                  expand,
                })
              }

              return (
                <TableRow>
                  <TableCell padding="checkbox" className={handleCheckBoxClass(saveConfSettings)}>
                    <Checkbox
                      onChange={event => handleChange(serviceList, event, type, service.id)}
                      checked={allCheckedVal[type] || (checkboxVal === undefined ? false : checkboxVal)}
                      name={`checkedRow${index}`}
                    />
                  </TableCell>

                  {Children.toArray(
                    columns.map((column, ind) => {
                      return (
                        <>
                          {type === 'services' && (
                            <ServiceBody
                              service={service}
                              column={column}
                              getPricingServiceName={getPricingServiceName}
                              saveConfSettings={saveConfSettings}
                              lockedColumns={lockedColumns}
                              ind={ind}
                            />
                          )}

                          {type === 'taxes' && (
                            <TaxBody
                              service={service}
                              column={column}
                              getTaxServiceName={getTaxServiceName}
                              saveConfSettings={saveConfSettings}
                              lockedColumns={lockedColumns}
                              ind={ind}
                            />
                          )}

                          {['general', 'disposal'].includes(type) && (
                            <FeeBody
                              service={service}
                              column={column}
                              getFeeServiceName={getFeeServiceName}
                              saveConfSettings={saveConfSettings}
                              lockedColumns={lockedColumns}
                              ind={ind}
                              handleExpand={handleExpand}
                              expand={expand}
                            />
                          )}
                        </>
                      )
                    })
                  )}

                  <TableCell className="expand">
                    {canSeePricingTab() && (
                      <IconButton onClick={e => handleMenuClicked(e, service)}>
                        <MoreVert />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })
          )}
      </TBody>

      <Menu
        id={`price-menu-${get(activeService, 'active', false) ? 'deactivate' : 'reactivate'}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose()
          setActiveService({})
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
      >
        {isServicesTab && <MenuItem onClick={handleClick}>{T.EDIT}</MenuItem>}
        <MenuItem onClick={handleClick}>{T.LINK}</MenuItem>
        {isServicesTab && isRentalFeeEnabled && (
          <>
            <MenuItem onClick={handleOpenAddRentalFeeDialog}>{T.ADD_RENTAL_FEE}</MenuItem>
            <Tooltip
              arrow
              placement="left"
              title={T.NO_RENTAL_FEES_LINKED_TO_PRICED_SERVICE}
              disableHoverListener={hasRentalFees}
              disableInteractive
            >
              <span>
                <MenuItem disabled={!hasRentalFees} onClick={handleOpenRemoveRentalFeeDialog}>
                  {T.REMOVE_RENTAL_FEE}
                </MenuItem>
              </span>
            </Tooltip>
          </>
        )}
        {!hasActiveLocations && (
          <MenuItem onClick={handleClick} sx={{ color: isActiveRow ? 'error.main' : 'success.main' }}>
            {isActiveRow ? T.DEACTIVATE : T.ACTIVATE}
          </MenuItem>
        )}
      </Menu>

      <AddRentalFeesDialog
        isOpen={isOpenAddRentalFeeDialog}
        pricedServiceId={activeService.id}
        callback={handleAddRemoveRentalFeesSuccess}
        onClose={handleCloseAddRentalFeeDialog}
      />
      <RemoveRentalFeesDialog
        isOpen={isOpenRemoveRentalFeeDialog}
        pricedServiceId={activeService.id}
        callback={handleAddRemoveRentalFeesSuccess}
        onClose={handleCloseRemoveRentalFeeDialog}
      />
    </>
  )
}

TableBody.defaultProps = {
  serviceList: [],
  confServices: [],
  lockedColumns: [],
  columns: [],
  saveConfSettings: false,
  type: '',
  checkedVal: {},
  allCheckedVal: {},
  handleChange: null,
  getPricingServiceName: null,
  getTaxServiceName: null,
  getFeeServiceName: null,
  toggleLinkServiceDrawer: null,
  handleActivation: null,
}

TableBody.propTypes = {
  serviceList: PropTypes.array,
  confServices: PropTypes.array,
  lockedColumns: PropTypes.array,
  columns: PropTypes.array,
  saveConfSettings: PropTypes.bool,
  type: PropTypes.string,
  checkedVal: PropTypes.object,
  allCheckedVal: PropTypes.object,
  handleChange: PropTypes.func,
  getPricingServiceName: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  toggleLinkServiceDrawer: PropTypes.func,
  handleActivation: PropTypes.func,
  onEditClick: PropTypes.func,
  onUpdatePricingService: PropTypes.func.isRequired,
}

export default memo(TableBody)
