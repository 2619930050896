import T from 'T'

const {
  SERVICE,
  MATERIAL,
  RECURRENCE,
  BUSINESS_LINE,
  ACTION,
  METHOD,
  MEASURE,
  PRICING_ZONE,
  PERIOD,
  PRICE,
  MINIMUM_BILLING_PERIOD,
  SPECIFIC_DAYS,
  TAX,
  TAX_ZONE,
  VALUE,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
  WEEKEND,
  WORKING_DAYS,
  LINK,
  DEACTIVATE,
  SUB_ITEM,
  EXCEPTION,
  AUTO_LINK,
  DISPOSAL_FEE,
  COST_PLUS,
  TIERS,
  MINIMUM_FEE,
  ACTIVE_LOCATIONS,
  RENTAL_FEES,
  STATUS,
  DELAY,
  SAFETY,
  CONTAMINATION,
  SERVICE_REVIEW,
  DAMAGE,
  SPECIAL,
  NO_ACCESS,
  EXTRA_ITEM,
  WEIGHT,
  OVERLOADED,
  PRIORITY,
  CUSTOMER_COUNT,
  OVERAGE_FEE,
  FEE,
} = T

const COMMON_CONFIG = {
  RECURRENCE_COLUMN: {
    label: RECURRENCE,
    checked: true,
    locked: false,
    filterType: 'meta',
    columnName: 'recurrenceText',
    isSingleSelect: true,
  },
  MEASURE_COLUMN: { label: MEASURE, checked: true, locked: false, filterType: 'meta', columnName: 'measureText', isSingleSelect: true },
  PERIOD_COLUMN: { label: PERIOD, checked: true, locked: false, filterType: 'meta', columnName: 'periodText', isSingleSelect: true },
  // Add more common column config
}

const { RECURRENCE_COLUMN, MEASURE_COLUMN, PERIOD_COLUMN } = COMMON_CONFIG

export const SERVICES = [
  { position: 0, original_position: 0, label: SERVICE, checked: true, locked: false, filterType: 'string', columnName: 'serviceName' },
  { position: 1, original_position: 1, label: MATERIAL, checked: true, locked: false, filterType: 'string', columnName: 'materialType' },
  { position: 2, original_position: 2, ...RECURRENCE_COLUMN },
  {
    position: 3,
    original_position: 3,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine',
  },
  { position: 4, original_position: 4, label: ACTION, checked: true, locked: false, filterType: 'string', columnName: 'actionName' },
  { position: 5, original_position: 5, label: METHOD, checked: true, locked: false, filterType: 'string', columnName: 'methodName' },
  { position: 6, original_position: 6, ...MEASURE_COLUMN },
  { position: 7, original_position: 7, label: PRICING_ZONE, checked: true, locked: false, filterType: 'string', columnName: 'zoneName' },
  { position: 8, original_position: 8, ...PERIOD_COLUMN },
  {
    position: 9,
    original_position: 9,
    label: STATUS,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'active',
    subType: 'pricingStatus',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 10,
    original_position: 10,
    label: RENTAL_FEES,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'rentalFeeCount',
  },
  {
    position: 11,
    original_position: 11,
    label: VALUE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'value',
    subType: 'price',
  },
  {
    position: 12,
    original_position: 12,
    label: MINIMUM_BILLING_PERIOD,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'minBillingPeriod',
  },
  {
    position: 12,
    original_position: 12,
    label: SPECIFIC_DAYS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'days',
  },
  {
    position: 13,
    original_position: 13,
    label: ACTIVE_LOCATIONS,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'activeLocationCount',
  },
  // {position: 14, original_position: 14, label: LAST_VALUE_UPDATE, checked: false, locked: false},
  // {position: 15, original_position: 15, label: NEXT_VALUE_UPDATE, checked: false, locked: false},
]

export const TAXES = [
  { position: 0, original_position: 0, label: TAX, checked: true, locked: false, filterType: 'string', columnName: 'taxName' },
  // {position: 1, original_position: 1, label: SCOPE, checked: true, locked: false},
  { position: 1, original_position: 1, label: MATERIAL, checked: true, locked: false, filterType: 'string', columnName: 'materialType' },
  {
    position: 2,
    original_position: 2,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine',
  },
  { position: 3, original_position: 3, label: TAX_ZONE, checked: true, locked: false, filterType: 'string', columnName: 'zoneName' },
  { position: 4, original_position: 4, ...MEASURE_COLUMN },
  {
    position: 5,
    original_position: 5,
    label: VALUE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'taxValue',
    subType: 'price',
  },
  { position: 6, original_position: 6, label: ACTION, checked: false, locked: false, filterType: 'string', columnName: 'actionName' },
  { position: 7, original_position: 7, label: EXCEPTION, checked: false, locked: false, filterType: 'string', columnName: 'exceptionType' },
  { position: 8, original_position: 8, ...PERIOD_COLUMN, checked: false },
  {
    position: 9,
    original_position: 9,
    label: ACTIVE_LOCATIONS,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'activeLocationCount',
  },
  {
    position: 10,
    original_position: 10,
    label: STATUS,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'active',
    subType: 'pricingStatus',
    isSingleSelect: true,
    isBoolean: true,
  },
]

export const ALL_TAXES = [
  { position: 0, original_position: 0, label: TAX, checked: true, locked: false },
  // {position: 1, original_position: 1, label: SCOPE, checked: true, locked: false},
  { position: 1, original_position: 1, label: TAX_ZONE, checked: true, locked: false },
  { position: 2, original_position: 2, label: MATERIAL, checked: true, locked: false },
  { position: 3, original_position: 3, label: VALUE, checked: true, locked: false },
  { position: 4, original_position: 4, label: BUSINESS_LINE, checked: false, locked: false },
  { position: 5, original_position: 5, label: ACTION, checked: false, locked: false },
  { position: 6, original_position: 6, label: EXCEPTION, checked: false, locked: false },
  { position: 7, original_position: 7, label: MEASURE, checked: false, locked: false },
  { position: 8, original_position: 8, label: PERIOD, checked: false, locked: false },
  { position: 9, original_position: 9, label: PRIORITY, checked: false, locked: false },
]

export const GENERAL_FEES = [
  { position: 0, original_position: 0, label: FEE, checked: true, locked: false, filterType: 'string', columnName: 'feeName' },
  {
    position: 1,
    original_position: 1,
    label: SUB_ITEM,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'isSubItem',
    isSingleSelect: true,
    isBoolean: true,
  },
  { position: 2, original_position: 2, label: MATERIAL, checked: true, locked: false, filterType: 'string', columnName: 'materialType' },
  { position: 3, original_position: 3, ...RECURRENCE_COLUMN, subType: 'feeRecurrence' },
  {
    position: 4,
    original_position: 4,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine',
  },
  { position: 6, original_position: 6, ...MEASURE_COLUMN },
  { position: 7, original_position: 7, label: PRICING_ZONE, checked: true, locked: false, filterType: 'string', columnName: 'zoneName' },
  { position: 8, original_position: 8, ...PERIOD_COLUMN },
  {
    position: 9,
    original_position: 9,
    label: VALUE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'value',
    subType: 'price',
  },
  { position: 10, original_position: 10, label: METHOD, checked: false, locked: false, filterType: 'string', columnName: 'methodName' },
  { position: 11, original_position: 11, label: ACTION, checked: false, locked: false, filterType: 'string', columnName: 'actionName' },
  {
    position: 12,
    original_position: 12,
    label: CUSTOMER_COUNT,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'customerCount',
  },
  {
    position: 13,
    original_position: 13,
    label: MINIMUM_BILLING_PERIOD,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'minBillingPeriod',
  },
  {
    position: 14,
    original_position: 14,
    label: AUTO_LINK,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'isAutoLink',
    isSingleSelect: true,
    isBoolean: true,
  },
  // {position: 15, original_position: 15, label: LAST_VALUE_UPDATE, checked: false, locked: false},
  // {position: 16, original_position: 16, label: NEXT_VALUE_UPDATE, checked: false, locked: false},
  {
    position: 15,
    original_position: 15,
    label: STATUS,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'active',
    subType: 'pricingStatus',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 16,
    original_position: 16,
    label: ACTIVE_LOCATIONS,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'activeLocationCount',
  },
]

export const DISPOSAL_FEES = [
  { position: 0, original_position: 0, label: DISPOSAL_FEE, checked: true, locked: false, filterType: 'string', columnName: 'feeName' },
  { position: 1, original_position: 1, label: MATERIAL, checked: true, locked: false, filterType: 'string', columnName: 'materialType' },
  {
    position: 2,
    original_position: 2,
    label: COST_PLUS,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'costPlus',
    isSingleSelect: true,
    isBoolean: true,
  },
  { position: 3, original_position: 3, label: PRICING_ZONE, checked: true, locked: false, filterType: 'string', columnName: 'zoneName' },
  { position: 4, original_position: 4, label: TIERS, checked: true, locked: false, filterType: 'number', columnName: 'tiers' },
  {
    position: 5,
    original_position: 5,
    label: PRICE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'price',
    subType: 'price',
  },
  { position: 6, original_position: 6, ...MEASURE_COLUMN },
  {
    position: 7,
    original_position: 7,
    label: MINIMUM_FEE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'minFee',
    subType: 'price',
  },
  {
    position: 8,
    original_position: 8,
    label: OVERAGE_FEE,
    checked: true,
    locked: false,
    filterType: 'number',
    columnName: 'overageFee',
    subType: 'price',
  },
  {
    position: 9,
    original_position: 9,
    ...RECURRENCE_COLUMN,
    checked: false,
    subType: 'feeRecurrence',
  },
  {
    position: 10,
    original_position: 10,
    label: BUSINESS_LINE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine',
  },
  { position: 11, original_position: 11, label: ACTION, checked: false, locked: false, filterType: 'string', columnName: 'actionName' },
  { position: 12, original_position: 12, label: METHOD, checked: false, locked: false, filterType: 'string', columnName: 'methodName' },
  { position: 13, original_position: 13, ...PERIOD_COLUMN, checked: false },
  { position: 14, original_position: 14, label: SPECIFIC_DAYS, checked: false, locked: false, filterType: 'string', columnName: 'days' },
  {
    position: 15,
    original_position: 15,
    label: AUTO_LINK,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'isAutoLink',
    isSingleSelect: true,
    isBoolean: true,
  },
  // {position: 16, original_position: 16, label: LAST_VALUE_UPDATE, checked: false, locked: false},
  // {position: 17, original_position: 17, label: NEXT_VALUE_UPDATE, checked: false, locked: false},
  {
    position: 16,
    original_position: 16,
    label: ACTIVE_LOCATIONS,
    checked: false,
    locked: false,
    filterType: 'number',
    columnName: 'activeLocationCount',
  },
  {
    position: 17,
    original_position: 17,
    label: STATUS,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'active',
    subType: 'pricingStatus',
    isSingleSelect: true,
    isBoolean: true,
  },
]

export const ALL_SERVICES = [
  { position: 0, original_position: 0, label: SERVICE, checked: true, locked: false },
  { position: 1, original_position: 1, label: MATERIAL, checked: false, locked: false },
  { position: 2, original_position: 2, label: RECURRENCE, checked: false, locked: false },
  { position: 3, original_position: 3, label: BUSINESS_LINE, checked: false, locked: false },
  { position: 4, original_position: 4, label: ACTION, checked: false, locked: false },
  { position: 5, original_position: 5, label: METHOD, checked: false, locked: false },
  { position: 6, original_position: 6, label: PRICING_ZONE, checked: true, locked: false },
  { position: 7, original_position: 7, label: MEASURE, checked: true, locked: false },
  { position: 8, original_position: 8, label: PERIOD, checked: true, locked: false },
  { position: 9, original_position: 9, label: VALUE, checked: true, locked: false },
  { position: 10, original_position: 10, label: MINIMUM_BILLING_PERIOD, checked: false, locked: false },
  { position: 11, original_position: 11, label: SPECIFIC_DAYS, checked: false, locked: false },
]

export const ALL_GENERAL_FEES = [
  { position: 0, original_position: 0, label: FEE, checked: true, locked: false },
  { position: 1, original_position: 1, label: SUB_ITEM, checked: true, locked: false },
  { position: 2, original_position: 2, label: PRICING_ZONE, checked: true, locked: false },
  { position: 3, original_position: 3, label: EXCEPTION, checked: true, locked: false },
  { position: 4, original_position: 4, label: METHOD, checked: false, locked: false },
  { position: 5, original_position: 5, label: VALUE, checked: true, locked: false },
  { position: 6, original_position: 6, label: MATERIAL, checked: false, locked: false },
  { position: 7, original_position: 7, label: BUSINESS_LINE, checked: false, locked: false },
  { position: 8, original_position: 8, label: RECURRENCE, checked: false, locked: false },
  { position: 9, original_position: 9, label: MEASURE, checked: false, locked: false },
  { position: 10, original_position: 10, label: PERIOD, checked: true, locked: false },
  { position: 11, original_position: 11, label: ACTION, checked: false, locked: false },
  { position: 12, original_position: 12, label: MINIMUM_BILLING_PERIOD, checked: false, locked: false },
  { position: 13, original_position: 13, label: AUTO_LINK, checked: false, locked: false },
  { position: 14, original_position: 14, label: SPECIFIC_DAYS, checked: false, locked: false },
]

export const ALL_DISPOSAL_FEES = [
  { position: 0, original_position: 0, label: DISPOSAL_FEE, checked: true, locked: false },
  { position: 1, original_position: 1, label: COST_PLUS, checked: true, locked: false },
  { position: 2, original_position: 2, label: PRICE, checked: true, locked: false },
  { position: 3, original_position: 3, label: MEASURE, checked: true, locked: false },
  { position: 4, original_position: 4, label: MINIMUM_FEE, checked: true, locked: false },
  { position: 5, original_position: 5, label: OVERAGE_FEE, checked: true, locked: false },
  { position: 6, original_position: 6, label: RECURRENCE, checked: false, locked: false },
  { position: 7, original_position: 7, label: MATERIAL, checked: false, locked: false },
  { position: 8, original_position: 8, label: BUSINESS_LINE, checked: false, locked: false },
  { position: 9, original_position: 9, label: PRICING_ZONE, checked: false, locked: false },
  { position: 10, original_position: 10, label: METHOD, checked: false, locked: false },
  { position: 11, original_position: 11, label: ACTION, checked: false, locked: false },
  { position: 12, original_position: 12, label: SPECIFIC_DAYS, checked: false, locked: false },
  { position: 13, original_position: 13, label: AUTO_LINK, checked: false, locked: false },
]

export const DAYS = [
  { id: MONDAY, value: MONDAY },
  { id: TUESDAY, value: TUESDAY },
  { id: WEDNESDAY, value: WEDNESDAY },
  { id: THURSDAY, value: THURSDAY },
  { id: FRIDAY, value: FRIDAY },
  { id: SATURDAY, value: SATURDAY },
  { id: SUNDAY, value: SUNDAY },
  { id: WEEKEND, value: WEEKEND },
  { id: WORKING_DAYS, value: WORKING_DAYS },
]

export const EXCEPTIONS = [
  { id: DELAY, value: DELAY },
  { id: SAFETY, value: SAFETY },
  { id: CONTAMINATION, value: CONTAMINATION },
  { id: DAMAGE, value: DAMAGE },
  { id: SERVICE_REVIEW, value: SERVICE_REVIEW },
  { id: NO_ACCESS, value: NO_ACCESS },
  { id: SPECIAL, value: SPECIAL },
  { id: EXTRA_ITEM, value: EXTRA_ITEM },
  { id: WEIGHT, value: WEIGHT },
  { id: OVERLOADED, value: OVERLOADED },
]

export const menuitems = [
  {
    title: LINK,
    icon: 'Link.svg',
  },
  // {
  //     title: CUSTOMERS,
  //     icon: 'PricingCustomer.svg',
  // },
  // {
  //     title: HISTORY,
  //     icon: 'History.svg',
  // },
  // {
  //     title: LOCK,
  //     icon: 'Lock.svg',
  // },
  // {
  //     title: DUPLICATE,
  //     icon: 'Duplicate.svg',
  // },
  {
    title: DEACTIVATE,
    icon: 'Deactivate.svg',
  },
]

export const MEASURE_OPTIONS = [
  { key: 'Gallon', value: 'Gallon' },
  { key: 'Yard', value: 'Yard' },
  { key: 'Pound', value: 'Pound' },
  { key: 'Ton', value: 'Ton' },
  { key: 'Item', value: 'Item' },
]

export const FORM_MEASURE_OPTIONS = [
  { id: 'Gallon', value: 'Gallon' },
  { id: 'Yard', value: 'Yard' },
  { id: 'Pound', value: 'Pound' },
  { id: 'Ton', value: 'Ton' },
  { id: 'Item', value: 'Item' },
]

export const PRICING_NEW_VIEW_TYPE = {
  NEW_SERVICE: 'NEW_SERVICE',
  NEW_FEE: 'NEW_FEE',
  NEW_TAX: 'NEW_TAX',
}
