import React from 'react'
import PropTypes from 'prop-types'

import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle'

import ValidatedFeeTable from 'components/pricing/Fees/ValidatedFeeTable'

import T from 'T'

import 'components/pricing/services.scss'

const FeesTableScreen = ({
  feeTab,
  tierCount,
  getFeeServiceName,
  validatedFees,
  duplicateFeeRecords,
  configData,
  onHandleValidatedFieldsChange,
  onHandleTiersChange,
  handleTiers,
  handleDelete,
  setState,
  onTableFeeScopeChange,
  onHandleMeasureChange,
}) => {
  const handleClick = (key, value) => {
    setState({ [key]: value })
  }

  return (
    <div className="tax-tabpanel-content">
      <div className="all-taxes-screen">
        {validatedFees.length > 0 && (
          <ValidatedFeeTable
            feeTab={feeTab}
            tierCount={tierCount}
            validatedFees={validatedFees}
            confServices={configData}
            handleSingleChange={handleClick}
            handleDelete={handleDelete}
            onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
            onHandleTiersChange={onHandleTiersChange}
            handleTiers={handleTiers}
            type="unique"
            getFeeServiceName={getFeeServiceName}
            onTableFeeScopeChange={onTableFeeScopeChange}
            onHandleMeasureChange={onHandleMeasureChange}
          />
        )}

        {duplicateFeeRecords.length > 0 && (
          <div className="duplicate-records">
            <div className={`error-block-record ${validatedFees.length === 0 ? 'no-validated-record' : ''}`}>
              <div className="error-block">
                <ExclamationCircle className="error-msg" />
                <div className="label">{T.FEE_DUPLICATED_MSG}</div>
              </div>

              <ValidatedFeeTable
                feeTab={feeTab}
                tierCount={tierCount}
                validatedFees={duplicateFeeRecords}
                confServices={configData}
                handleClick={handleClick}
                handleDelete={handleDelete}
                onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
                onHandleTiersChange={onHandleTiersChange}
                handleTiers={handleTiers}
                type="duplicate"
                getFeeServiceName={getFeeServiceName}
                onTableFeeScopeChange={onTableFeeScopeChange}
                onHandleMeasureChange={onHandleMeasureChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

FeesTableScreen.defaultProps = {
  feeTab: 'general',
  validatedFees: [],
  duplicateFeeRecords: [],
  configData: [],
  tierCount: {},
  setState: {},
  getFeeServiceName: null,
  onHandleValidatedFieldsChange: null,
  handleDelete: null,
  onTableFeeScopeChange: null,
  handleTiers: null,
  onHandleTiersChange: null,
  onHandleMeasureChange: null,
}

FeesTableScreen.propTypes = {
  feeTab: PropTypes.string,
  validatedFees: PropTypes.array,
  duplicateFeeRecords: PropTypes.array,
  configData: PropTypes.array,
  tierCount: PropTypes.object,
  setState: PropTypes.object,
  getFeeServiceName: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onTableFeeScopeChange: PropTypes.func,
  handleTiers: PropTypes.func,
  onHandleTiersChange: PropTypes.func,
  onHandleMeasureChange: PropTypes.func,
}

export default FeesTableScreen
