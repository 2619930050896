import React from 'react'
import PropTypes from 'prop-types'
import { Box, ListItem, ListItemText, Typography } from '@mui/material'
import { get } from 'utils/lodash'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const SummaryHeader = ({ color = '', label = '', value = 0, total = 0, isBatch = false, rows = [] }) => {
  return (
    <ListItem dense disablePadding sx={{ px: 1 }}>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Box width={16} height={16} bgcolor={color} />
            <Typography variant="caption" fontWeight={700} color="textPrimary" ml={0.5}>
              {label}
            </Typography>
          </Box>
        }
        secondaryTypographyProps={{ sx: { ml: 2.5 } }}
        secondary={isBatch && !get(rows, '[0].isIndividualPayment') ? get(rows, '[0].batchId') : ''}
      />
      <ListItemText
        primaryTypographyProps={{ variant: 'caption', fontWeight: 700, color: 'textPrimary', textAlign: 'right' }}
        secondaryTypographyProps={{ textAlign: 'right' }}
        primary={<HHDisplayMoney value={total} />}
        secondary={`${value} payment${value > 1 ? 's' : ''}`}
      />
    </ListItem>
  )
}

SummaryHeader.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  total: PropTypes.number,
  isBatch: PropTypes.bool,
  rows: PropTypes.array,
}

export default SummaryHeader
