import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doGet, doPost } from 'providers/api'

import routes from 'router/routes'

import { get } from 'utils/lodash'
import {
  actions,
  putCustomerSearchResult,
  putRecentCustomerResult,
  putCustomerSuggestionResult,
  putCustomerDetails,
  putCustomerMeta,
  putUserSearchResult,
} from '../actions/customers'

import endpoint, { API_PREFIX } from './endpoint'

export const getCustomerSearchResult = ({ body }) => doPost({ url: endpoint.account.search, body, showLoader: true })
export const getRecentCustomerList = ({ body }) => doPost({ url: endpoint.account.recent, body, showLoader: true })
export const getCustomerSuggestionList = ({ body }) => doPost({ url: endpoint.account.suggest, body })
export const getCustomerLocationList = ({ body }) => doPost({ url: endpoint.location.find, body, showLoader: true })
export const getCustomerDetails = (accountId, addRecent = false) =>
  doGet({ url: `${endpoint.account.view}/${accountId}?add-recent=${addRecent}`, showLoader: true, redirectURL: routes.app.accounts })

export const addCustomer = ({ body }) => doPost({ url: endpoint.account.add, body, showLoader: true })
export const getCustomerMeta = ({ body }) => doPost({ url: endpoint.meta.account, body, showLoader: false })
export const getUserSearchResult = ({ body }) => {
  const { requestedPage, requestedPageSize, search } = body
  return doGet({
    url: `${API_PREFIX}/v1/core/settings/users/search?page=${requestedPage}&size=${requestedPageSize}&search=${search}`,
    showLoader: false,
  })
}

export function* getCustomerSearchResultSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putCustomerSearchResult(null))
    const { data: res } = yield call(getCustomerSearchResult, { body })
    const { accounts, totalItems, totalPages } = res
    if (typeof onSuccess === 'function') {
      onSuccess(accounts, totalItems, totalPages)
    }
    yield put(putCustomerSearchResult(res))
  } catch (err) {
    yield put(putCustomerSearchResult(null))
  }
}

export function* getRecentCustomerListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getRecentCustomerList, { body })
    const { accounts, totalItems, totalPages } = res
    if (typeof onSuccess === 'function') {
      onSuccess(accounts, totalItems, totalPages)
    }
    yield put(putRecentCustomerResult(accounts))
  } catch (err) {
    yield put(putRecentCustomerResult(null))
  }
}

export function* getCustomerSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCustomerSuggestionList, { body })
    const { accounts } = res
    yield put(putCustomerSuggestionResult(accounts))
    if (typeof onSuccess === 'function') {
      onSuccess(accounts)
    }
  } catch (err) {
    yield put(putCustomerSuggestionResult(null))
  }
}

export function* getCustomerLocationListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCustomerLocationList, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getCustomerDetailsSaga({ payload }) {
  const { body, onSuccess } = payload
  if (body?.isInitialCall) {
    yield put(putCustomerDetails(null))
  }

  try {
    const { data: res } = yield call(getCustomerDetails, body.accountId, get(body, 'addRecent', false))
    const accountData = {
      ...get(res, 'account', {}),
      balanceDetails: get(res, 'accountForBalance', {}),
      cfCount: get(res, 'cfCountForLocations', []),
    }
    yield put(putCustomerDetails(accountData))
    if (typeof onSuccess === 'function') {
      onSuccess(accountData)
    }
  } catch (err) {
    yield put(putCustomerDetails(null))
  }
}

export function* addCustomerSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(addCustomer, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess(false)
    }
  }
}

export function* getCustomerMetaSaga({ payload }) {
  const { body } = payload
  try {
    const { data: res } = yield call(getCustomerMeta, { body })
    yield put(putCustomerMeta(res))
  } catch (err) {
    yield put(putCustomerMeta(null))
  }
}

export function* getUserSearchResultSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getUserSearchResult, { body })
    const { users } = res
    yield put(putUserSearchResult(users))
    if (typeof onSuccess === 'function') {
      onSuccess(users)
    }
  } catch (err) {
    yield put(putUserSearchResult(null))
  }
}

export default function* Customers() {
  yield all([
    takeEvery(actions.GET.SEARCH_RESULT, getCustomerSearchResultSaga),
    takeEvery(actions.GET.RECENT_CUSTOMER_LIST, getRecentCustomerListSaga),
    takeEvery(actions.GET.SUGGEST_CUSTOMER_LIST, getCustomerSuggestionListSaga),
    takeEvery(actions.GET.CUSTOMER_LOCATION_LIST, getCustomerLocationListSaga),
    takeEvery(actions.GET.CUSTOMER_DETAILS, getCustomerDetailsSaga),
    takeEvery(actions.ADD_CUSTOMER, addCustomerSaga),
    takeEvery(actions.GET.CUSTOMER_META, getCustomerMetaSaga),
    takeEvery(actions.GET.USER_SEARCH_RESULT, getUserSearchResultSaga),
  ])
}
