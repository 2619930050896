import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'

import { get } from 'utils/lodash'
import { PAYMENT_METHODS } from 'settings/constants/payment'

import T from 'T'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import RenderPaymentIframe from 'components/customer-details/content/payment-details/common/RenderPaymentIframe'

const { CREDIT_CARD } = PAYMENT_METHODS

const RenderCreditCard = ({ onNewCardTokenChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { cardHolderName: cardHolderNameError, cvv: cvvError } = errors
  const cardHolderNameHelperText = get(cardHolderNameError, 'message')
  const cvvHelperText = get(cvvError, 'message')

  return (
    <Box>
      <HHFormTextField
        control={control}
        name="cardHolderName"
        fullWidth
        required
        rules={{ required: T.CANNOT_BE_EMPTY }}
        error={cardHolderNameError}
        helperText={cardHolderNameHelperText}
        label={T.CARDHOLDER_NAME}
        placeholder={T.CARDHOLDER_NAME}
      />

      <RenderPaymentIframe type={CREDIT_CARD} callback={onNewCardTokenChange} />

      <Box mt={2}>
        <HHFormTextField
          control={control}
          name="cvv"
          fullWidth
          // CVV is optional, so can be less than 3-4 digits
          rules={{ maxLength: { value: 4, message: 'Invalid CVV' } }}
          error={cvvError}
          helperText={cvvHelperText}
          label="CVV*"
          placeholder="123"
        />
      </Box>
    </Box>
  )
}

RenderCreditCard.propTypes = {
  onNewCardTokenChange: PropTypes.func.isRequired,
}

export default RenderCreditCard
