export const SERVICE_GROUP_MODEL = {
  name: '',
  filterModel: {
    businessLine: '',
    pricingZoneId: '',
    search: '',
    measureIds: [],
    materialIds: [],
    serviceMethodIds: [],
    serviceActionIds: [],
  },
  currentSelectedServices: [],
}
