import React, { useMemo, useEffect, Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Popover, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { HHAlert } from 'components/common/HHAlert'
import { useLazyGetRentalFeesByConfiguredServiceQuery } from 'api/configured-service/configuredServiceRentalFeesCrud'
import { useLazyGetRentalFeesByPricedServiceQuery } from 'api/pricing/pricedServiceRentalFeesCrud'

import T from 'T'
import RentalFeeListItemSkeleton from '../field/RentalFeeListItemSkeleton'
import RentalFeeListItem from '../field/RentalFeeListItem'

const RentalFeePopover = ({ anchorEl, configuredServiceId = '', pricedServiceId = '', onClose }) => {
  const theme = useTheme()
  const [getRentalFeesByConfiguredService, { isFetching: isGetRentalFeesByConfiguredServiceLoading, data: configuredRentalFeesData }] =
    useLazyGetRentalFeesByConfiguredServiceQuery()
  const [getRentalFeesByPricedService, { isFetching: isGetRentalFeesByPricedServiceLoading, data: rentalFeesByPricedServiceData }] =
    useLazyGetRentalFeesByPricedServiceQuery()

  const isOpen = Boolean(anchorEl)
  const rentalFeesByConfiguredService = useMemo(() => get(configuredRentalFeesData, 'configuredRentalFees', []), [configuredRentalFeesData])
  const rentalFeesByPricedService = useMemo(() => get(rentalFeesByPricedServiceData, 'rentalFees', []), [rentalFeesByPricedServiceData])

  const isLoading = useMemo(
    () => isGetRentalFeesByConfiguredServiceLoading || isGetRentalFeesByPricedServiceLoading,
    [isGetRentalFeesByConfiguredServiceLoading, isGetRentalFeesByPricedServiceLoading]
  )

  const rows = useMemo(() => {
    if (configuredServiceId) {
      return rentalFeesByConfiguredService
    }
    if (pricedServiceId) {
      return rentalFeesByPricedService
    }
    return []
  }, [configuredServiceId, pricedServiceId, rentalFeesByConfiguredService, rentalFeesByPricedService])

  const hasRows = useMemo(() => rows.length > 0, [rows])

  useEffect(() => {
    if (isOpen && pricedServiceId) {
      getRentalFeesByPricedService({ id: pricedServiceId })
    }
  }, [isOpen, pricedServiceId])

  useEffect(() => {
    if (isOpen && configuredServiceId) {
      getRentalFeesByConfiguredService({ id: configuredServiceId }).unwrap().catch(handleError)
    }
  }, [isOpen, configuredServiceId])

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      slotProps={{ paper: { sx: { minWidth: 300 } } }}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box px={2}>
        {isLoading && <RentalFeeListItemSkeleton rows={1} />}
        {!isLoading && (
          <>
            {!hasRows && (
              <HHAlert severity="info" borderColor={theme.palette.info.light}>
                {T.NO_RENTAL_FEES}
              </HHAlert>
            )}
            {hasRows && Children.toArray(rows.map(rentalFee => <RentalFeeListItem rentalFee={rentalFee} />))}
          </>
        )}
      </Box>
    </Popover>
  )
}

RentalFeePopover.propTypes = {
  anchorEl: PropTypes.object,
  configuredServiceId: PropTypes.string,
  pricedServiceId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default RentalFeePopover
