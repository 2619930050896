import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemText, Divider } from '@mui/material'

const WOInfoListItem = ({ primaryText, secondaryText, primaryTypographyProps = {}, hasDivider = true, hasLargeSize = false }) => (
  <ListItem disablePadding>
    <ListItemText
      sx={{ display: 'flex', flexDirection: 'column-reverse', '& a': { textDecorationColor: 'inherit', color: 'inherit' } }}
      primaryTypographyProps={{ variant: hasLargeSize ? 'h5' : 'subtitle1', fontWeight: 500, ...primaryTypographyProps }}
      secondaryTypographyProps={{ variant: hasLargeSize ? 'h6' : 'body2', fontWeight: 400, noWrap: true }}
      primary={primaryText}
      secondary={secondaryText}
    />
    {hasDivider && <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />}
  </ListItem>
)

WOInfoListItem.propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  primaryTypographyProps: PropTypes.object,
  hasDivider: PropTypes.bool,
  hasLargeSize: PropTypes.bool,
}

export default WOInfoListItem
