import { isUnPaidInvoice } from 'utils/billing'

import { calculateDaysDiffFromStringDates, formatDateToBEFormatDateFns } from 'utils/date'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const getAgingBucket = dueDate => {
  if (!dueDate) {
    return ''
  }

  const diff = calculateDaysDiffFromStringDates(formatDateToBEFormatDateFns(new Date()), dueDate)
  if (diff <= 0) {
    return CURRENT
  }

  if (diff < 31) {
    return DUE_ONE_THIRTY
  }

  if (diff < 61) {
    return DUE_THIRTY_ONE_SIXTY
  }

  if (diff < 91) {
    return DUE_SIXTY_ONE_NINETY
  }

  return DUE_OVER_NINETY
}

export const getInvoiceIsPartialStatusAndBucket = (status, dueDate, amountPaidCents = 0, totalCents = 0) => {
  const isUnPaid = isUnPaidInvoice(status)
  const isPartial = isUnPaid && amountPaidCents > 0 && amountPaidCents < totalCents
  const agingBucketType = isUnPaid ? getAgingBucket(dueDate) : ''

  return { isPartial, agingBucketType }
}
