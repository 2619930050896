import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TakePaymentsDialog from 'components/billing/groups/payment/take-payments'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import T from 'T'

const TakePaymentIconButton = ({ accountId, from = 'customerDetails', size = 'medium', onClick, ...rest }) => {
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)

  const handleToggleTakePaymentsDialog = () => setIsOpenTakePaymentsDialog(!isOpenTakePaymentsDialog)

  return (
    <>
      <Tooltip arrow title={T.TAKE_PAYMENT}>
        <IconButton size={size} {...rest} onClick={handleToggleTakePaymentsDialog}>
          <AttachMoneyIcon />
        </IconButton>
      </Tooltip>
      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog
          isOpen={isOpenTakePaymentsDialog}
          initialAccount={{ id: accountId, from }}
          onClose={handleToggleTakePaymentsDialog}
        />
      )}
    </>
  )
}

TakePaymentIconButton.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  from: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default TakePaymentIconButton
