import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { Z_INDEX } from 'theme/zIndex'

import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import SelectItemContent from './SelectItemContent'

const { MENU } = Z_INDEX

const AccountSelectionDropdown = ({ value, readOnly = false, onChange, sx = {}, ...rest }) => {
  const allBusinesses = useSelector(state => get(state, 'LoginPersist.allBusinesses', []), shallowEqual)

  return (
    <HHTextField
      readOnly={readOnly}
      fullWidth
      select
      value={[value]}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root': { height: '52px!important' },
        '& .MuiSelect-select': {
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
        },
        ...sx,
      }}
      SelectProps={{
        MenuProps: {
          style: { zIndex: MENU },
          PaperProps: {
            sx: { '& .Mui-selected': { '& .MuiTypography-root': { color: 'primary.main' } } },
          },
        },
      }}
      {...rest}
    >
      {Children.toArray(
        allBusinesses.map(({ businessId, businessTitle }) => (
          <SelectItem value={businessId}>
            <SelectItemContent businessId={businessId} businessTitle={businessTitle} />
          </SelectItem>
        ))
      )}
    </HHTextField>
  )
}

AccountSelectionDropdown.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default AccountSelectionDropdown
