import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import CreateInvoices from '../containers/billing/create-invoices/CreateInvoices'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import { resetCreateInvoicesState } from '../slices/billing/createInvoicesSlice'

const CreateInvoicesPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setShowAppBar(false))
    return () => {
      dispatch(resetCreateInvoicesState())
      dispatch(setShowAppBar(true))
    }
  }, [])

  return (
    <ProtectedRouteHoC>
      <CreateInvoices />
    </ProtectedRouteHoC>
  )
}

export default CreateInvoicesPage
