import { styled } from '@mui/material'
import { HHFormNumberField } from 'components/form-fields/v5'
import { INPUT_WIDTH } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/settings'

const StopPositionField = styled(HHFormNumberField)(({ theme }) => ({
  width: INPUT_WIDTH,
  marginRight: theme.spacing(1),
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  input: { textAlign: 'center' },
}))

export default StopPositionField
