import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom-v5-compat'
import PropTypes from 'prop-types'
import { isV2URL } from 'utils/url'
import routes from './routes'

const ProtectedRouteHoC = ({ children, redirectPath = routes.app.accounts }) => {
  const { isUserAuthenticated } = useSelector(
    state => ({
      isUserAuthenticated: state.AuthReducer.isUserAuthenticated,
    }),
    shallowEqual
  )

  if (!isUserAuthenticated && !isV2URL()) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

ProtectedRouteHoC.propTypes = {
  redirectPath: PropTypes.string,
  children: PropTypes.node,
}

export default ProtectedRouteHoC
