import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { capitalize } from 'lodash'
import { ReactComponent as SideLoad } from 'assets/methods/SideLoad.svg'
import { ReactComponent as RearLoad } from 'assets/methods/RearLoad.svg'
import { ReactComponent as Grapple } from 'assets/methods/Grapple.svg'
import { ReactComponent as Hook } from 'assets/methods/Hook.svg'
import { ReactComponent as Hoist } from 'assets/methods/Hoist.svg'
import { ReactComponent as FrontLoad } from 'assets/methods/FrontLoad.svg'
import { ReactComponent as DumpTruck } from 'assets/methods/DumpTruck.svg'
import { grey, indigo, lightGreen, lime, orange, purple, teal } from '@mui/material/colors'
import { METHOD_TYPES } from '../../../settings/constants/methods'

const { SIDE_LOAD, FRONT_LOAD, REAR_LOAD, DUMP_TRUCK, GRAPPLE, HOIST, HOOK } = METHOD_TYPES

export const METHOD_TO_ICON_MAP = {
  [SIDE_LOAD]: SideLoad,
  [FRONT_LOAD]: FrontLoad,
  [REAR_LOAD]: RearLoad,
  [DUMP_TRUCK]: DumpTruck,
  [GRAPPLE]: Grapple,
  [HOIST]: Hoist,
  [HOOK]: Hook,
}

const METHOD_TO_BG_COLOR_MAP = {
  [SIDE_LOAD]: teal[100],
  [FRONT_LOAD]: orange[100],
  [REAR_LOAD]: purple[100],
  [DUMP_TRUCK]: grey[500],
  [HOOK]: indigo[100],
  [HOIST]: lime[100],
  [GRAPPLE]: lightGreen[100],
}
const MethodChip = ({ methodType, label, ...rest }) => {
  const theme = useTheme()
  const methodTypeUpperCase = methodType?.toUpperCase()
  const Icon = methodTypeUpperCase in METHOD_TO_ICON_MAP ? METHOD_TO_ICON_MAP[methodTypeUpperCase] : SideLoad
  const bgcolor = methodTypeUpperCase in METHOD_TO_BG_COLOR_MAP ? METHOD_TO_BG_COLOR_MAP[methodTypeUpperCase] : grey[500]
  const capitalizedLabel = capitalize(label || methodType)
  return (
    <Chip
      sx={{
        bgcolor,
        '&:hover': {
          bgcolor,
        },
      }}
      {...rest}
      size="small"
      variant="filled"
      avatar={
        <Avatar
          sx={{
            backgroundColor: `${theme.palette.background.black}61`,
            '& svg': {
              width: '14px',
            },
          }}
        >
          <Icon />
        </Avatar>
      }
      label={capitalizedLabel}
      {...rest}
    />
  )
}

MethodChip.propTypes = {
  label: PropTypes.string,
  methodType: PropTypes.string.isRequired,
}

export default MethodChip
