import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme } from '@mui/material'
import { ALPHA_38_PERCENT } from 'theme/colors'
import ExceptionIcon from 'components/exceptions/ExceptionIcon'
import { EXCEPTION_TO_COLOR_MAP } from 'components/exceptions/ExceptionChip'

const ExceptionAvatarIcon = ({ exceptionType }) => {
  const theme = useTheme()
  const backgroundColor = exceptionType in EXCEPTION_TO_COLOR_MAP ? EXCEPTION_TO_COLOR_MAP[exceptionType] : undefined

  return (
    <Box
      sx={{
        borderRadius: '8rem',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor,
      }}
    >
      <Box
        sx={{
          borderRadius: '8rem',
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}`,
        }}
      >
        <ExceptionIcon sx={{ color: theme.palette.common.white, fontSize: 20 }} exception={exceptionType} />
      </Box>
    </Box>
  )
}

ExceptionAvatarIcon.propTypes = {
  exceptionType: PropTypes.string.isRequired,
}

export default ExceptionAvatarIcon
