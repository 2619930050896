import { store } from 'providers/store'
import { get } from 'utils/lodash'

const { createSelector } = require('reselect')

export const useGetUserInfo = createSelector(() => {
  const state = store.getState()
  return get(state, 'AuthReducer.userInfo', {})
})

export const getUserInfo = () => {
  const state = store.getState()
  return get(state, 'AuthReducer.userInfo', {})
}
