import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getExistingServices: build.query({
      query: body => ({ url: '/api/v1/pricing/service/exists', method: 'POST', body }),
    }),
  }),
})
export const { useLazyGetExistingServicesQuery } = extendedApi
