import { createSelector } from 'reselect'

// Sort Array order by newest date first
export const getArraySortedAscendingOrder = createSelector(
  [state => state.arrayToSort, state => state.keyToSort],
  (arrayToSort, keyToSort) => {
    if (!Array.isArray(arrayToSort)) {
      return []
    }
    return arrayToSort.sort((data, index) => {
      return new Date(data[keyToSort]) - new Date(index[keyToSort])
    })
  }
)
