import React from 'react'
import PropTypes from 'prop-types'
import StopPositionLabel from 'components/route-manager/BulkActions/common/StopListItem/StopPositionLabel'
import { get } from 'lodash'
import StopListItem from 'components/route-manager/BulkActions/common/StopListItem/StopListItem'

const RenderFromStopListItem = ({ row, showAvatarContent = true }) => {
  const position = get(row, 'position', 0) + 1
  const workOrderStatus = get(row, 'workOrderStatus')
  return (
    <StopListItem
      row={row}
      showAvatarContent={showAvatarContent}
      AvatarContent={<StopPositionLabel position={position} workOrderStatus={workOrderStatus} />}
    />
  )
}

RenderFromStopListItem.propTypes = {
  row: PropTypes.object,
  showAvatarContent: PropTypes.bool,
}

export default RenderFromStopListItem
