import { get } from 'utils/lodash'
import api from 'api'
import endpoint from 'middleware/sagas/endpoint'
import { CACHE_TAG_ACCOUNT_DETAILS, CACHE_TAG_ACCOUNT_DETAILS_BY_ID } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountDetails: builder.query({
      query: ({ accountId, addRecent = false }) => ({
        url: `${endpoint.account.view}/${accountId}`,
        method: 'GET',
        params: {
          'add-recent': addRecent,
        },
      }),
      providesTags: (result, error, { accountId }) => [CACHE_TAG_ACCOUNT_DETAILS, { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId }],
      transformResponse: res => ({
        ...get(res, 'account', {}),
        balanceDetails: get(res, 'accountForBalance', {}),
        cfCount: get(res, 'cfCountForLocations', []),
      }),
    }),
  }),
})

export const { useLazyGetAccountDetailsQuery } = extendedApi
