import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { sortByDateSelector } from 'data/utils/sortByDateSelector'
import { useGetPaymentGroupListQuery } from 'api/groups/payment/getPaymentGroupList'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHTextField from 'components/form-fields/v5/HHTextField'

import T from 'T'
import AddEditPaymentGroupDialog from 'components/billing/groups/payment/AddEditPaymentGroupDialog'

const AddToGroupField = ({ groupId, onChange, ...rest }) => {
  const [isOpenAddEditPaymentGroupDialog, setIsOpenAddEditPaymentGroupDialog] = useState(false)
  const { data: paymentGroupList = [] } = useGetPaymentGroupListQuery()
  const sortedGroups = sortByDateSelector({ data: paymentGroupList, key: 'createdAt' })

  const handleCreateNewGroup = () => setIsOpenAddEditPaymentGroupDialog(true)

  const handleCreateNewGroupSuccess = id => onChange({ target: { value: id } })

  const handleCloseCreateNewGroup = () => setIsOpenAddEditPaymentGroupDialog(false)

  return (
    <>
      <Box display="flex" alignItems="center" gap={0.5}>
        <HHTextField select deprecatedLabel={false} label={T.PAYMENT_GROUPS} fullWidth value={[groupId]} onChange={onChange} {...rest}>
          <SelectItem value="">{T.PLEASE_SELECT}</SelectItem>
          {sortedGroups.map(({ id, groupName }) => (
            <SelectItem value={id}>{groupName}</SelectItem>
          ))}
        </HHTextField>
        <Tooltip title="Create new payment group">
          <IconButton onClick={handleCreateNewGroup}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <AddEditPaymentGroupDialog
        isOpen={isOpenAddEditPaymentGroupDialog}
        onSuccess={handleCreateNewGroupSuccess}
        onClose={handleCloseCreateNewGroup}
      />
    </>
  )
}

AddToGroupField.propTypes = {
  groupId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default AddToGroupField
