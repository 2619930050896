import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    addRoute: builder.mutation({
      query: body => ({ url: '/api/route/add', method: 'POST', body }),
    }),
    getRouteById: builder.query({
      query: body => ({ url: '/api/route', method: 'POST', body }),
    }),
    deleteRouteById: builder.mutation({
      query: ({ id }) => ({ url: `/api/v1/core/routes/${id}`, method: 'DELETE' }),
    }),
  }),
})

export const { useAddRouteMutation, useDeleteRouteByIdMutation, useLazyGetRouteByIdQuery } = extendedApi
