import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getDisposalSites: builder.mutation({
      query: () => ({ url: '/api/settings/disposal/site/list', method: 'POST', body: {} }),
    }),
  }),
})

export const { useGetDisposalSitesMutation } = extendedApi
