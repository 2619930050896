import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Card, Grid, Divider, ListItem, Stack, ListItemAvatar, ListItemText, Typography, IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { formatDateToFEFormat } from 'utils/date'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import MoreVert from '@mui/icons-material/MoreVert'
import { get, capitalize } from 'lodash'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import InvoiceActionMenu from 'components/billing/common/InvoiceActionMenu'
import { INVOICE_TYPE } from 'settings/constants/billing'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { setInvoiceDetailsDrawerState } from '../../../../../slices/billing/invoiceDetailsDrawerSlice'
import AccountSummary from '../../common/AccountSummary'

const { MANUAL } = INVOICE_TYPE
const getBillingCycle = ({ startDate, endDate, invoiceDate, billingProfileDetails }) => {
  try {
    return getBillingPeriods({
      billingProfile: billingProfileDetails,
      billingPeriods: [{ startDate, endDate, invoiceDate }],
    })
  } catch (error) {
    return []
  }
}

const RenderInvoiceGroupCell = ({ row = {} }) => {
  const {
    id,
    accountId,
    totalCents,
    amountPaidCents,
    status,
    dueDate: rawDueDate,
    billingProfileName,
    autopay,
    accountName,
    accountNumber,
    invoiceNumber,
    type,
    startDate,
    endDate,
    invoiceDate,
    billingCycleInterval,
    billingCycleIntervalType,
    billingProfileTiming,
    billingProfileTimingDay,
  } = row
  const [anchorEl, setAnchorEl] = useState(null)

  const handleCloseActionMenu = () => setAnchorEl(null)

  const handleOpenActionMenu = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const dueDate = transformDate(rawDueDate)
  const dispatch = useDispatch()
  const { id: groupId } = useParams()
  const pendingAmount = totalCents - amountPaidCents
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, rawDueDate, amountPaidCents, totalCents)
  const billingProfileDetails = {
    billingCycle: {
      interval: billingCycleInterval,
      intervalType: capitalize(billingCycleIntervalType),
    },
    timing: capitalize(billingProfileTiming),
    timingDay: billingProfileTimingDay,
  }
  const parsedInvoiceDate = transformDate(invoiceDate)
  const billingCycle = getBillingCycle({ startDate, endDate, invoiceDate, billingProfileDetails })
  const isManual = type === MANUAL
  const title = isManual ? parsedInvoiceDate : get(billingCycle, '[0].labelLeft', '-')
  const labelRight = get(billingCycle, '[0].labelRight')
  const apiRef = useGridApiContext()
  const expandedDetailsPanel = apiRef.current.getExpandedDetailPanels()
  const isExpanded = expandedDetailsPanel.includes(id)
  const handleToggleDetailPanel = e => {
    e.stopPropagation()
    apiRef.current.toggleDetailPanel(id)
  }

  const handleClick = () => {
    dispatch(
      setInvoiceDetailsDrawerState({
        isOpenPDFDrawer: true,
        invoice: {
          invoiceNumber,
          accountId,
          invoiceDate,
          id,
          totalCents,
          status,
          accountName,
        },
      })
    )
  }

  return (
    <Box width="100%" px={3}>
      <Card onClick={handleClick} variant="outlined" sx={{ width: '100%', borderRadius: 0, cursor: 'pointer' }}>
        <AccountSummary
          accountId={accountId}
          accountNumber={accountNumber}
          accountName={accountName}
          billingProfileName={billingProfileName}
          autopay={autopay}
          billingCycle={labelRight}
        />
        <Divider />
        <Grid container>
          <Grid item xs>
            <ListItem
              sx={{
                '&.MuiListItem-root': {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              <ListItemAvatar sx={{ mr: 2 }}>
                <Stack spacing={1}>
                  <InvoiceStatusChip status={status} isPartial={isPartial} agingBucketType={agingBucketType} />
                  {isPartial && agingBucketType && (
                    <Typography color={AGING_BUCKET_COLORS[agingBucketType][700]} variant="caption" fontWeight={700}>
                      <HHDisplayMoney value={pendingAmount} /> left
                    </Typography>
                  )}
                </Stack>
              </ListItemAvatar>
              <ListItemText
                primary={title}
                primaryTypographyProps={{ variant: 'body1', fontWeight: 500 }}
                secondary={
                  <Stack>
                    <Typography>{`Invoice #: ${invoiceNumber}`}</Typography>
                    <Typography>{`Invoice date: ${parsedInvoiceDate}`}</Typography>
                  </Stack>
                }
                secondaryTypographyProps={{ fontWeight: 400 }}
              />
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem
              secondaryAction={
                <Stack>
                  <IconButton size="small" onClick={handleOpenActionMenu}>
                    <MoreVert />
                  </IconButton>
                  <IconButton size="small" onClick={handleToggleDetailPanel}>
                    {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </Stack>
              }
            >
              <ListItemText
                primary={<HHDisplayMoney value={totalCents} formatToDollars />}
                primaryTypographyProps={{ variant: 'h6', textAlign: 'right' }}
                secondary={`Due: ${dueDate}`}
                secondaryTypographyProps={{ variant: 'body1', color: 'info.light', mt: 2 }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Card>
      <InvoiceActionMenu
        anchorEl={anchorEl}
        selectedInvoice={{
          ...row,
          groupId,
          amountCents: totalCents,
        }}
        showAddToGroupAction={false}
        onClose={handleCloseActionMenu}
      />
    </Box>
  )
}

RenderInvoiceGroupCell.propTypes = {
  row: PropTypes.object,
}

export default RenderInvoiceGroupCell
