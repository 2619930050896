import React from 'react'
import PropTypes from 'prop-types'
import RenderExceptionContent from 'components/work-order/details/exceptions/RenderExceptionContent'

const RenderException = ({ isSignExceptionType, ImageSx, height, base64Data, PageProps }) => {
  if (isSignExceptionType) {
    return <RenderExceptionContent sx={ImageSx} height={height} src={base64Data} title="PDF Document" isPdf PageProps={PageProps} />
  }
  return <RenderExceptionContent sx={ImageSx} height={height} src={base64Data} loading="lazy" />
}

RenderException.propTypes = {
  ImageSx: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSignExceptionType: PropTypes.bool,
  base64Data: PropTypes.string,
  PageProps: PropTypes.object,
}

export default RenderException
