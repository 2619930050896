import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import { EMAIL_TYPE } from 'settings/constants/email'

const { ACCOUNT_INVOICE_AVAILABLE, ACCOUNT_PAYMENT_RECEIPT, PORTAL_ACCOUNT_SIGN_UP, PORTAL_USER_SIGN_UP } = EMAIL_TYPE

export const getInvoiceEmailEvents = createSelector([state => state?.emailEvents], (emailEvents = []) => {
  const invoiceEvents = emailEvents.filter(event => event?.invoiceNumber && event?.emailType === ACCOUNT_INVOICE_AVAILABLE)
  return orderBy(invoiceEvents, ['timestamp'], ['desc'])
})

export const getPaymentReceiptEmailEvents = createSelector([state => state?.emailEvents], (emailEvents = []) => {
  const paymentReceiptEvents = emailEvents.filter(event => event?.paymentNumber && event?.emailType === ACCOUNT_PAYMENT_RECEIPT)
  return orderBy(paymentReceiptEvents, ['timestamp'], ['desc'])
})

export const getCustomerPortalSignUpEmailEvents = createSelector([state => state?.emailEvents], (emailEvents = []) => {
  const signUpEvents = emailEvents.filter(event => [PORTAL_ACCOUNT_SIGN_UP, PORTAL_USER_SIGN_UP].includes(event?.emailType))
  return orderBy(signUpEvents, ['timestamp'], ['desc'])
})
