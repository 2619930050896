import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { addYears, endOfYear, startOfYear, subYears } from 'date-fns'
import { ResponsiveCalendar } from '@nivo/calendar'
import { Box, IconButton as MuiIconButton, Stack, styled, useMediaQuery } from '@mui/material'
import { grey } from '@mui/material/colors'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { useLazyGetStopsHeatMapQuery } from 'api/accounts/accountRouting'
import { setRoutingServiceDate } from 'slices/customer/customerDetailsSlice'
import useTheme from '@mui/material/styles/useTheme'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import RenderTooltip from '../RenderTooltip/RenderTooltip'
import { colorScaleFn, formatHeatmapData } from '../utils'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 24,
  height: 24,
}))

const MARGIN = { top: 20, right: 36, bottom: 20, left: 56 }

const YearlyHeapMap = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const [getStopsHeatMap, { data }] = useLazyGetStopsHeatMapQuery()
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const accountId = get(customerDetails, 'id')
  const [startDate, setStartDate] = useState(startOfYear(serviceDate))
  const [endDate, setEndDate] = useState(endOfYear(serviceDate))
  const formattedStartDate = useMemo(() => formatDateToBEFormatDateFns(startDate), [startDate])
  const formattedEndDate = useMemo(() => formatDateToBEFormatDateFns(endDate), [endDate])
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))

  const fontSize = useMemo(() => {
    if (isXl) return 14
    return 11
  }, [isXl])

  const heatmap = get(data, 'heatmap', {})
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    getStopsHeatMap({
      accountId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
  }, [formattedStartDate, formattedEndDate])

  useEffect(() => {
    const formattedData = formatHeatmapData(heatmap, formatDateToBEFormatDateFns(serviceDate))
    setGraphData(formattedData)
  }, [data, serializedServiceDate])

  const heatmapSx = {
    height: '155px',
    [theme.breakpoints.up('xl')]: {
      height: '200px',
    },
    [theme.breakpoints.up(1800)]: {
      height: '250px',
    },
    [theme.breakpoints.up(2150)]: {
      height: '300px',
    },
    [theme.breakpoints.up(2400)]: {
      height: '350px',
    },
  }

  const onClick = day => {
    const newServiceDate = get(day, 'date')
    dispatch(setRoutingServiceDate(newServiceDate))
  }

  const handleNextDateClick = () => {
    const newStartDate = addYears(endDate, 1)
    setStartDate(startOfYear(newStartDate))
    setEndDate(endOfYear(newStartDate))
  }

  const handlePreviousDateClick = () => {
    const newStartDate = subYears(endDate, 1)
    setStartDate(startOfYear(newStartDate))
    setEndDate(endOfYear(newStartDate))
  }

  return (
    <Box position="relative" width="100%" sx={heatmapSx}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        height="100%"
        position="absolute"
        px={0.5}
      >
        <IconButton sx={{ position: 'relative', zIndex: 1 }} onClick={handlePreviousDateClick}>
          <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
        </IconButton>
        <IconButton sx={{ position: 'relative', zIndex: 1 }} onClick={handleNextDateClick}>
          <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Stack>
      <Box
        position="absolute"
        top={0}
        width="100%"
        sx={{
          ...heatmapSx,
          '& svg text': {
            fontWeight: 700,
          },
        }}
      >
        <ResponsiveCalendar
          theme={{ fontSize, legends: { text: { fontSize } } }}
          data={graphData}
          from={startDate}
          to={endDate}
          minValue={0}
          tooltip={RenderTooltip}
          colorScale={colorScaleFn}
          maxValue={99}
          emptyColor={grey[100]}
          monthLegendOffset={4}
          margin={MARGIN}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'row',
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: 'right-to-left',
            },
          ]}
          onClick={onClick}
        />
      </Box>
    </Box>
  )
}

export default YearlyHeapMap
