import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom-v5-compat'

import { Box, useTheme, LinearProgress } from '@mui/material'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, useGridApiRef } from '@mui/x-data-grid-pro'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getFilteredRowsForLateFeeDetails } from 'data/groups/pricingSelectors'
import { ACCOUNTS_DESKTOP_ROW_HEIGHT, getAccountRowClassName } from 'components/customers/accounts/settings'
import { closeConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'

import RenderRentalFeeDetailsWrapper from 'components/pricing/RentalFees/details/RenderRentalFeeDetailsWrapper'
import ConfirmAccountReactivatedDialog from 'components/customers/accounts/ConfirmAccountReactivatedDialog'

const RentalFeeDetailsList = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { watch } = useFormContext()
  const { id: rentalFeeId } = useParams()
  const apiRef = useGridApiRef()

  const data = {}
  const isLoading = false
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([])

  const getDetailPanelContent = useCallback(({ row }) => <Box>Expanded content</Box>, [])

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ id, accountId }) => id || accountId, [])

  const getRowHeight = useCallback(() => 'auto', [])

  const getRowClassName = useCallback(getAccountRowClassName, [])

  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])

  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedAccountStatuses = watch('selectedAccountStatuses')
  const selectedSuspensionTypes = watch('selectedSuspensionTypes')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedAccountStatusIds = useMemo(() => selectedAccountStatuses.map(({ id }) => id), [selectedAccountStatuses])
  const selectedSuspensionTypesValues = useMemo(() => selectedSuspensionTypes.map(({ value }) => value), [selectedSuspensionTypes])
  const rows = useMemo(() => {
    const accounts = get(data, 'accounts', [])
    // Change function
    const filteredRows = getFilteredRowsForLateFeeDetails({
      rows: accounts,
      selectedTagsIds,
      selectedBillingProfilesIds,
      selectedAccountStatusIds,
      selectedSuspensionTypesValues,
    })
    return isLoading ? [] : [{ id: 'header', active: true }, ...filteredRows]
  }, [data, selectedTagsIds, selectedBillingProfilesIds, selectedSuspensionTypesValues, selectedAccountStatusIds, isLoading])

  const getRowSpacing = params => ({
    bottom: params.isLastVisible ? 256 : 0,
  })

  useEffect(() => {
    if (rentalFeeId) {
      // call API to get linked accounts
    }
  }, [rentalFeeId])

  useEffect(() => {
    dispatch(closeConfirmAccountReactivatedDialog())
  }, [])

  return (
    <Box display="flex" height="100%" overflow="auto">
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            apiRef={apiRef}
            loading={isLoading}
            slots={{
              noRowsOverlay: () => null,
              detailPanelCollapseIcon: ExpandMoreIcon,
              detailPanelExpandIcon: ChevronRightIcon,
              loadingOverlay: LinearProgress,
            }}
            hideFooter
            rows={rows}
            rowHeight={ACCOUNTS_DESKTOP_ROW_HEIGHT}
            getRowId={getRowId}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            getRowHeight={getRowHeight}
            columnVisibilityModel={{
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            }}
            disableRowSelectionOnClick
            columns={[
              {
                flex: 1,
                field: 'id',
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: params => <RenderRentalFeeDetailsWrapper {...params} detailPanelExpandedRowIds={detailPanelExpandedRowIds} />,
              },
              {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                renderCell: () => <div />,
              },
            ]}
            columnHeaderHeight={0}
            getRowSpacing={getRowSpacing}
            getRowClassName={getRowClassName}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
                border: 0,
                padding: 0,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
                background: theme.palette.background.paper,
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden',
              },
            }}
          />
          <ConfirmAccountReactivatedDialog />
        </Box>
      </Box>
    </Box>
  )
}

export default RentalFeeDetailsList
