import { uniqBy } from 'lodash'
import orderBy from 'lodash/orderBy'
import { CONTACT_METHODS } from 'settings/constants/contacts'

const { EMAIL } = CONTACT_METHODS

export const getAccountEmailContacts = (contacts = [], portalUserList = [], getUniqEmails = false) => {
  const uniqContacts = uniqBy(contacts, 'id')
  const emailContacts = []
  const existingEmails = portalUserList.map(({ email }) => email)

  uniqContacts.forEach(({ deactivated, contactMethods = [], firstName, lastName }) => {
    if (deactivated) return

    contactMethods.forEach(method => {
      const { methodValue, methodType } = method
      if (methodType === EMAIL && methodValue) {
        emailContacts.push({ ...method, firstName, lastName, isAlreadyRegistered: existingEmails.includes(methodValue) })
      }
    })
  })

  const uniqueEmails = getUniqEmails ? uniqBy(emailContacts, 'methodValue') : emailContacts
  return orderBy(uniqueEmails, ['isAlreadyRegistered'])
}
