import React from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'utils/lodash'
import T from 'T'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
// eslint-disable-next-line import/no-cycle
import { toast } from 'react-toastify'
// eslint-disable-next-line import/no-cycle
import { canUpdateInvoices } from '../../../data/permissions/permissionsSelectors'
import { downloadInvoiceBlobListInChunks } from '../../../utils/file'
import { useSendInvoiceListMutation } from '../../../api/billing/sendInvoiceList'
import { putIsLoading } from '../../../middleware/actions/response'
import { handleError } from '../../../utils/error'

const RenderGetSentInvoiceActionsDataCell = params => {
  const dispatch = useDispatch()
  const updateInvoices = useSelector(canUpdateInvoices, shallowEqual)
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const { row } = params
  const [sendInvoiceList] = useSendInvoiceListMutation()
  const status = get(row, 'status')
  const totalOnPaper = get(row, 'totalOnPaper', 0)
  const invoiceNumbersList = get(row, 'invoiceNumbers', [])

  const onSendPaymentBatch = async () => {
    dispatch(putIsLoading(true))

    if (invoiceNumbersList.length) {
      sendInvoiceList({ invoiceNumbers: invoiceNumbersList })
        .unwrap()
        .then(() => {
          toast.success(T.SEND_INVOICE_SUCCESS)
        })
        .catch(handleError)
        .finally(() => dispatch(putIsLoading(false)))
    }
    dispatch(putIsLoading(false))
  }

  const downloadInvoices = async () => {
    if (status !== 'Complete' || totalOnPaper < 1) return
    downloadInvoiceBlobListInChunks(invoiceNumbersList, businessId)
  }
  const showDownloadOption = updateInvoices && totalOnPaper > 0 && status === 'Complete'
  return [
    showDownloadOption ? <GridActionsCellItem showInMenu label={T.DOWNLOAD} onClick={downloadInvoices} /> : null,
    invoiceNumbersList.length > 0 ? <GridActionsCellItem showInMenu label={T.SEND} onClick={onSendPaymentBatch} /> : null,
  ].filter(Boolean)
}

RenderGetSentInvoiceActionsDataCell.propTypes = {
  selectedInvoice: PropTypes.object,
}

export default RenderGetSentInvoiceActionsDataCell
