import set from 'lodash/set'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import startCase from 'lodash/startCase'

import { get } from './lodash'
import { isEmail, isValidMobile, isValidLongitude, isValidLatitude } from './validations'

export const isContactMethodValid = (contactMethod = {}) => {
  const methodType = get(contactMethod, 'methodType', '').toLowerCase()
  const methodValue = get(contactMethod, 'methodValue', '').trim()
  if (!methodValue) {
    return false
  }

  if (methodType === 'email') {
    return isEmail(methodValue)
  }

  return isValidMobile(methodValue)
}

export const validateContactMethods = (contactMethods = []) => {
  if (!Array.isArray(contactMethods)) {
    return []
  }

  const oldContacts = contactMethods.filter(contact => contact.id)
  const newContacts = contactMethods.filter(contact => !contact.id)

  const validOldContacts = oldContacts.map(contact => (isContactMethodValid(contact) ? { ...contact } : { ...contact, delete: true }))
  const validNewContacts = newContacts.filter(contact => isContactMethodValid(contact))
  return [...validOldContacts, ...validNewContacts]
}

export const validateContact = contacts => {
  const contactCopy = cloneDeep(contacts)
  return contactCopy.filter(contact => {
    if (!contact.firstName.trim()) {
      return false
    }

    // Don't mutate directly return new contact methods
    set(contact, 'contactMethods', validateContactMethods(contact.contactMethods))
    return contact
  })
}

export const addBillingPOAndTax = billingProfile => {
  const result = {}
  const hasCustomerPurchaseOrder = get(billingProfile, 'customerPurchaseOrder', false)

  set(result, 'po', hasCustomerPurchaseOrder ? get(billingProfile, 'po', null) : null)
  set(result, 'maxPoAmount', hasCustomerPurchaseOrder ? get(billingProfile, 'maxPoAmount', 0) : 0)

  const isTaxExemptEnable = get(billingProfile, 'taxExemptEnable', false)
  set(result, 'taxExempt', isTaxExemptEnable ? get(billingProfile, 'taxExempt', '') : null)

  return result
}

// Check for valid lat/lng
const isValidLatLng = address => {
  const latitude = get(address, 'latitude', '')
  const longitude = get(address, 'longitude', '')
  if (isValidLatitude(latitude) && isValidLongitude(longitude)) {
    return true
  }

  return false
}

// Add customer payload
export const getAddCustomerFormBody = account => {
  const accountContacts = get(account, 'contacts', [])
  const locationContacts = get(account, 'locationContacts', []).filter(contact => contact.firstName !== '' && contact.lastname !== '')

  const billedForOtherCustomer = get(account, 'billedForOtherCustomer', false)

  const billingProfile = get(account, 'billingProfile', {})

  const billingAddress = get(account, 'billingAddress', {})
  if (!isValidLatLng(billingAddress)) {
    set(billingAddress, 'latitude', '')
    set(billingAddress, 'longitude', '')
  }

  const payload = {
    accountName: get(account, 'accountName', '').trim(),
    billingAddress: { ...billingAddress },
    contacts: validateContact(accountContacts),
    csr: get(account, 'csr.id', null),
    tags: get(account, 'tags', []).map(tag => tag.id),
    billedForOtherCustomer,
    location: {
      signatureRequired: get(account, 'location.signatureRequired', false),
      contacts: validateContact(locationContacts),
      tags: get(account, 'location.tags', []).map(tag => tag.id),
    },
  }

  const locationAddress = get(account, 'locationAddress', {})
  if (!isValidLatLng(locationAddress)) {
    set(locationAddress, 'latitude', '')
    set(locationAddress, 'longitude', '')
  }

  const addressName = get(locationAddress, 'addressName', '').trim()
  set(payload, 'location.address', { ...locationAddress, addressName })

  const locationPurchaseOrder = get(account, 'location.locationPurchaseOrder', false)
  const PO = get(account, 'location.po', '')
  const maxPoAmount = get(account, 'location.maxPoAmount', '')

  if (locationPurchaseOrder && (PO || maxPoAmount)) {
    set(payload, 'location.po', PO)
    set(payload, 'location.maxPoAmount', maxPoAmount)
  }

  const billingProfileId = get(billingProfile, 'id')
  if (billingProfileId) {
    set(payload, 'accountBilling.billingProfileId', billingProfileId)
  }

  set(payload, 'accountBilling', { ...payload.accountBilling, ...addBillingPOAndTax(billingProfile) })

  return payload
}

// Add call/followUp payload
export const getAddCallFollowUpPayload = (state, accountId, locationId, selectedId = '') => {
  const { contactName, contactMethod, direction, followUp, assignee, tags, status, contact = {} } = state
  const contactId = get(contact, 'id')
  const payload = {
    call: {
      direction,
      followUp,
      subject: get(state, 'subject', '').trim(),
      note: get(state, 'note', '').trim(),
      account: {
        id: accountId,
      },
      tags,
    },
  }

  // contact name is used for add mode and contact id is used for edit mode
  if (contactName || contactId) {
    set(payload, 'call.contact.id', contactName || contactId)
  }

  // Edit call followUp mode
  if (selectedId) {
    set(payload, 'call.id', selectedId)
  }

  if (locationId) {
    set(payload, 'call.location.id', locationId)
  }

  if (contactMethod) {
    // Id for edit mode
    set(payload, 'call.contactMethod.id', get(contactMethod, 'id') || contactMethod)
  }

  if (followUp) {
    set(payload, 'call.assigneeEmail', get(assignee, 'email', ''))
    set(payload, 'call.status', startCase(status || 'Open'))
  }

  return payload
}

export const addEditAdditionalNote = (followUpId, state) => {
  const { note, image, id: followUpNoteId = '' } = state
  const payload = new FormData()

  payload.append('followUpId', followUpId)
  payload.append('note', note.trim())

  if (followUpNoteId) {
    payload.append('followUpNoteId', followUpNoteId)
  }

  if (image && typeof image === 'object') {
    payload.append('image', image)
  }

  if (!image) {
    payload.append('deleteImage', true)
  }

  return payload
}

// New API accepts the delete flag but for Old API doesn't send the object
export const removeDeletedContactMethods = contacts => {
  if (!Array.isArray(contacts)) {
    return []
  }

  return contacts.map(contact => ({ ...contact, contactMethods: get(contact, 'contactMethods', []).filter(method => !method.delete) }))
}

export const fixContactLocationAssociation = contacts => {
  if (!Array.isArray(contacts)) {
    return []
  }

  return contacts.map(contact => {
    const associatedLocations = get(contact, 'associatedLocations', []).map(location => location.id)
    const associatedWithLocation = get(contact, 'associatedWithLocation', false)

    return {
      ...omit(contact, ['associatedWithLocation']),
      associatedLocations: associatedWithLocation ? associatedLocations : [],
    }
  })
}

export const getAPIIdParam = (locationId, accountId) => ({ [locationId ? 'locationId' : 'accountId']: locationId || accountId })
