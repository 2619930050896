import React from 'react'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material'
import HHDisplayMoney from 'components/common/HHDisplayMoney'

const AddLateFeeItemSkeleton = () => {
  return (
    <ListItemButton>
      <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
        <Skeleton>
          <Checkbox />
        </Skeleton>
      </ListItemIcon>
      <ListItemText primary={<Skeleton width="4rem" />} secondary={<Skeleton width="8rem" />} />
      <Skeleton width="2rem">
        <Typography variant="h5">
          <HHDisplayMoney formatToDollars />
        </Typography>
      </Skeleton>
    </ListItemButton>
  )
}

export default AddLateFeeItemSkeleton
