import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Grid } from '@mui/material'
import { getPOAndTaxInfo } from 'utils/customerPayload'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PreviewField from '../common/PreviewField'

const AccountMISCContent = ({ customerDetails = {} }) => {
  const { customerPurchaseOrder, po, maxPoAmount, taxExemptEnable } = getPOAndTaxInfo(customerDetails)
  return (
    // we can also use direction column/row
    <Grid container rowGap={1.5}>
      <Grid item xs={12} md xl={12}>
        <PreviewField fieldName={T.CUSTOMER_PURCHASE_ORDER} fieldValue={customerPurchaseOrder ? po : ''} />
      </Grid>

      <Grid item xs={12} md xl={12}>
        <PreviewField
          fieldName={T.MAX_PO_AMOUNT}
          fieldValue={customerPurchaseOrder ? <HHDisplayMoney formatToDollars={false} value={maxPoAmount} /> : ''}
        />
      </Grid>

      <Grid item xs={12} md xl={12}>
        <PreviewField fieldName={capitalize(T.TAX_STATUS)} fieldValue={taxExemptEnable ? 'Exempt' : 'Not Exempt'} />
      </Grid>
    </Grid>
  )
}

AccountMISCContent.propTypes = {
  customerDetails: PropTypes.object,
}

export default AccountMISCContent
