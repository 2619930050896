import React from 'react'
import PropTypes from 'prop-types'

import { Box, Stack, Typography } from '@mui/material'
import { PAYMENT_METHOD_IMAGES, PAYMENT_METHODS, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'
import { get } from 'utils/lodash'

const { CHECK, OTHER } = PAYMENT_METHODS

const PaymentMethod = ({ row = {} }) => {
  const { paymentMethod, checkNum } = row
  const isCheckOrOther = [CHECK, OTHER].includes(paymentMethod)
  const paymentMethodIcon = PAYMENT_METHOD_IMAGES[paymentMethod]

  return (
    <Box display="flex" alignItems="center">
      {paymentMethodIcon && <img src={paymentMethodIcon} alt="" />}
      <Stack sx={{ ml: 2 }}>
        <Typography display="inline" variant="h5" fontWeight={400}>
          {get(PAYMENT_METHODS_LABELS, paymentMethod, '')}
        </Typography>
        {isCheckOrOther && checkNum && <Typography variant="caption">{checkNum}</Typography>}
      </Stack>
    </Box>
  )
}

PaymentMethod.propTypes = {
  row: PropTypes.object,
}

export default PaymentMethod
