export const collapseLocationsInCustomerTable = () => {
  // Only close if collapsebar is open
  document.querySelectorAll('.table-expand-icon .downIcon').forEach(el => {
    if (el && el.parentElement && el.parentElement.parentElement) {
      el.parentElement.parentElement.click()
    }
  })
}

export const handleCustomerTableExpandClass = () => {
  setTimeout(() => {
    if (document.getElementsByClassName('table-header')[0]) {
      document.getElementsByClassName('table-header')[0].classList.remove('collapse')
    }

    if (document.getElementsByClassName('table-body')[0]) {
      document.getElementsByClassName('table-body')[0].classList.remove('collapse')
    }

    document.querySelectorAll('.normal-row').forEach(row => row.classList.remove('o-50'))
    if (document.querySelectorAll('.location-expanded').length) {
      document.querySelectorAll('.normal-row').forEach(row => row.classList.add('o-50'))
      document.getElementsByClassName('table-header')[0].classList.add('collapse')
      document.getElementsByClassName('table-body')[0].classList.add('collapse')
    }
  })
}

export const handleCustomerTableExpandWidth = () => {
  const parentRowWidth = document.querySelectorAll('.expanded-parent-row td:not(.collapse-hidden)')
  setTimeout(() => {
    document.querySelectorAll('.expanded-rows:not(.location-expanded) td').forEach((el, index) => {
      // eslint-disable-next-line no-param-reassign
      el.width = `${parentRowWidth[index % parentRowWidth.length].getBoundingClientRect().width}px`
    })
  })
}

export const handleScrollToTop = className => {
  if (!className) {
    return
  }

  const elRef = document.getElementsByClassName(className)[0]
  if (elRef) {
    elRef.scrollTop = 0
  }
}

export const addFormDirtyClass = isDirty => (isDirty ? 'drawer-form-touched' : 'drawer-form-untouched')
