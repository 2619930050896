import React from 'react'
import { MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useTheme from '@mui/material/styles/useTheme'
import Select from '@mui/material/Select'
import { useSelector } from 'react-redux'

export const LOOKUP_MODE_SEARCH = 'search'
export const LOOKUP_MODE_ADDRESS = 'findAddress'
const LookupToggle = ({ value, onChange }) => {
  const theme = useTheme()
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)
  const color = isTemporaryModeActive ? theme.palette.warning.light : theme.palette.primary.main
  return (
    <Select
      IconComponent={ExpandMoreIcon}
      sx={{
        minWidth: '7rem',
        width: '7rem',
        maxWidth: '7rem',
        backgroundColor: theme.palette.background.paper,
        height: '36.6833px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
      }}
      select
      size="small"
      value={value}
      onChange={onChange}
    >
      <MenuItem value={LOOKUP_MODE_SEARCH}>Route</MenuItem>
      <MenuItem value={LOOKUP_MODE_ADDRESS}>Address</MenuItem>
    </Select>
  )
}

LookupToggle.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LookupToggle
