import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ReactComponent as ServiceNoteIcon } from 'assets/note/ServiceNote.svg'

import { useLazyGetAllCSNotesQuery } from 'api/notes/configuredServiceNotesCrud'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

import T from 'T'
import ViewArchiveNotesDialog from 'components/notes/configured-service/ViewArchiveNotesDialog'
import AddUpdateCSNoteDialog from 'components/notes/configured-service/AddUpdateCSNoteDialog'
import ViewArchivedNotesMenuOption from 'components/notes/common/actions/ViewArchivedNotesMenuOption'
import RentalFeesDialog from 'components/pricing/RentalFees/ConfiguredServices/RentalFeesDialog'
import RentalFeeBadge from 'components/pricing/RentalFees/common/badge/RentalFeeBadge'

const ConFiguredServiceActionMenu = ({ anchorEl, selectedService, onClose }) => {
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const [getAllCSNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllCSNotesQuery()
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(false)
  const [isOpenRentalFeesDialog, setIsOpenRentalFeesDialog] = useState(false)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const configuredServiceId = get(selectedService, 'id')
  const archivedNotesCount = get(selectedService, 'archivedNotesCount', 0)
  const rentalFeeCount = get(selectedService, 'rentalFeeCount', 0)

  const handleViewArchiveClick = () => {
    onClose()
    setIsOpenViewArchiveNoteDialog(true)
    getAllCSNotes({ id: configuredServiceId })
      .unwrap()
      .catch(error => {
        handleError(error)
        setIsOpenViewArchiveNoteDialog(false)
      })
  }

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  const handleOpenAddNoteDialog = () => {
    onClose()
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleCloseAddNote = () => setIsOpenAddUpdateNoteDialog(false)

  const handleOpenRentalFeesDialog = () => {
    setIsOpenRentalFeesDialog(true)
    onClose()
  }

  const handleCloseRentalFeesDialog = () => {
    setIsOpenRentalFeesDialog(false)
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined' } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleOpenAddNoteDialog}>
          <ListItemIcon sx={{ mr: 1 }}>
            <ServiceNoteIcon />
          </ListItemIcon>
          <ListItemText>{T.ADD_NOTE}</ListItemText>
        </MenuItem>
        {isRentalFeeEnabled && (
          <MenuItem onClick={handleOpenRentalFeesDialog}>
            <ListItemIcon sx={{ mr: 1 }}>
              <RentalFeeBadge rentalFeeCount={rentalFeeCount} />
            </ListItemIcon>
            <ListItemText>{T.RENTAL_FEES}</ListItemText>
          </MenuItem>
        )}
        <ViewArchivedNotesMenuOption isDisabled={archivedNotesCount === 0} count={archivedNotesCount} onClick={handleViewArchiveClick} />
      </Menu>
      <AddUpdateCSNoteDialog
        id={configuredServiceId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_CONFIGURED_SERVICE_NOTE}
        onClose={handleCloseAddNote}
      />
      <ViewArchiveNotesDialog
        id={configuredServiceId}
        isOpen={isOpenViewArchiveNoteDialog}
        isLoading={isGetAllNotesLoading}
        notes={archivedNotes}
        onClose={handleCloseViewArchiveDialog}
      />
      <RentalFeesDialog isOpen={isOpenRentalFeesDialog} configuredServiceId={configuredServiceId} onClose={handleCloseRentalFeesDialog} />
    </>
  )
}

ConFiguredServiceActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  selectedService: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default ConFiguredServiceActionMenu
