import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import YardsSettings from 'components/settings/pages/general/YardsSettings'
import DisposalSitesSettings from 'components/settings/pages/general/DisposalSitesSettings'

import T from 'T'

import 'components/settings/pages/users/style.scss'
import BusinessInfoSettings from './general/BusinessInfoSettings'

const GeneralSettings = ({ generalSettingsOption = '' }) => {
  return (
    <Box>
      {generalSettingsOption === T.BUSINESS_INFO && <BusinessInfoSettings />}
      {generalSettingsOption === `${T.YARD}s` && <YardsSettings />}
      {generalSettingsOption === `${T.DISPOSAL_SITE}s` && <DisposalSitesSettings />}
    </Box>
  )
}

GeneralSettings.propTypes = {
  generalSettingsOption: PropTypes.string,
}

export default GeneralSettings
