import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    undeleteRoute: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/routes/${id}/undelete`,
        method: 'PUT',
      }),
    }),
  }),
})

export const { useUndeleteRouteMutation } = extendedApi
