import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormContext } from 'react-hook-form'
import { Box, darken, lighten, useTheme, LinearProgress } from '@mui/material'
import { orange, purple, red } from '@mui/material/colors'
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_FIELD, useGridApiRef } from '@mui/x-data-grid-pro'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getFilteredRowsForAccountGroupDetails } from 'data/groups/accountGroupsSelectors'
import { ACCOUNTS_DESKTOP_ROW_HEIGHT, getAccountRowClassName } from 'components/customers/accounts/settings'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import { useParams } from 'react-router-dom-v5-compat'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import ConfirmAccountReactivatedDialog from 'components/customers/accounts/ConfirmAccountReactivatedDialog'
import { closeConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'
import { useDispatch } from 'react-redux'
import RenderAccountGroupWrapper from './RenderAccountGroupWrapper'
import AccountGroupExpansionContent from './AccountGroupExpansionContent'

const AccountGroupList = () => {
  const { watch } = useFormContext()
  const theme = useTheme()
  const { id } = useParams()
  const isSuspendedBucket = getIsSuspendedBucket(id)
  const dispatch = useDispatch()
  const accountGroupDetailsData = useGetAccountGroupDetailsData(id, true)
  const { data, isLoading, isFetching } = accountGroupDetailsData
  const apiRef = useGridApiRef()
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([])

  const getDetaiilPanelContent = useCallback(({ row }) => <AccountGroupExpansionContent row={row} />, [])

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ id, accountId }) => id || accountId, [])

  const getRowHeight = useCallback(() => 'auto', [])

  const getRowClassName = useCallback(getAccountRowClassName, [])

  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])

  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedAccountStatuses = watch('selectedAccountStatuses')
  const selectedSuspensionTypes = watch('selectedSuspensionTypes')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])
  const selectedAccountStatusIds = useMemo(() => selectedAccountStatuses.map(({ id }) => id), [selectedAccountStatuses])
  const selectedSuspensionTypesValues = useMemo(() => selectedSuspensionTypes.map(({ value }) => value), [selectedSuspensionTypes])
  const rows = useMemo(() => {
    const accounts = get(data, 'accounts', [])
    const filteredRows = getFilteredRowsForAccountGroupDetails({
      rows: accounts,
      selectedTagsIds,
      selectedBillingProfilesIds,
      selectedAccountStatusIds,
      selectedSuspensionTypesValues,
      isSuspendedBucket,
    })
    return isLoading || isFetching ? [] : [{ id: 'header', active: true }, ...filteredRows]
  }, [data, selectedTagsIds, selectedBillingProfilesIds, selectedSuspensionTypesValues, selectedAccountStatusIds, isLoading, isFetching])

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  const getRowSpacing = params => ({
    bottom: params.isLastVisible ? 256 : 0,
  })

  useEffect(() => {
    // workaround to prevent space between header and rows
    if (apiRef?.current) {
      setTimeout(() => {
        apiRef.current.updateRows(rows)
      }, 50)
    }
  }, [apiRef, rows])

  useEffect(() => {
    dispatch(closeConfirmAccountReactivatedDialog())
  }, [])

  return (
    <Box display="flex" height="100%" overflow="auto">
      <Box flex="1" position="relative">
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            loading={isFetching || isLoading}
            slots={{
              noRowsOverlay: () => null,
              detailPanelExpandIcon: ChevronRightIcon,
              detailPanelCollapseIcon: ExpandMoreIcon,
              loadingOverlay: LinearProgress,
            }}
            apiRef={apiRef}
            hideFooter
            rows={rows}
            rowHeight={ACCOUNTS_DESKTOP_ROW_HEIGHT}
            getRowId={getRowId}
            getDetailPanelContent={getDetaiilPanelContent}
            getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            getRowHeight={getRowHeight}
            columnVisibilityModel={{
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            }}
            columns={[
              {
                field: 'id',
                flex: 1,
                resizable: false,
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: params => <RenderAccountGroupWrapper {...params} detailPanelExpandedRowIds={detailPanelExpandedRowIds} />,
              },
              {
                field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
                renderCell: () => <div />,
              },
            ]}
            columnHeaderHeight={0}
            getRowSpacing={getRowSpacing}
            getRowClassName={getRowClassName}
            sx={{
              border: 0,
              '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
                border: 0,
                padding: 0,
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
                background: theme.palette.background.paper,
              },
              '& .MuiDataGrid-row, & .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover, & .MuiDataGrid-row:focus-within ': {
                '&.hold': {
                  '& .MuiDataGrid-cell .MuiBox-root:not(.deactivated-bg-indicator)': {
                    background: purple[50],
                    '&:hover': {
                      backgroundColor: getHoverBackgroundColor(purple[50], theme.palette.mode),
                    },
                  },
                },
                '&.suspended': {
                  '& .MuiDataGrid-cell .MuiBox-root:not(.deactivated-bg-indicator)': {
                    background: red[50],
                    '&:hover': {
                      backgroundColor: getHoverBackgroundColor(red[50], theme.palette.mode),
                    },
                  },
                },
                '&.deactivated': {
                  '& .MuiDataGrid-cell .MuiBox-root:not(.deactivated-bg-indicator)': {
                    background: orange[50],
                    '&:hover': {
                      backgroundColor: getHoverBackgroundColor(orange[50], theme.palette.mode),
                    },
                  },
                },
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'hidden',
              },
            }}
            disableRowSelectionOnClick
          />
          <ConfirmAccountReactivatedDialog />
        </Box>
      </Box>
    </Box>
  )
}

export default AccountGroupList
