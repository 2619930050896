import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Card, Grid, Typography } from '@mui/material'
import ReorderIcon from 'components/common/ReorderIcon'
import BillingSummaryCard from '../BillingSummaryCard'
import { get } from '../../../utils/lodash'
import { formatCentsToDollars } from '../../../utils/price'
import { setShowBillingSideNav } from '../../../slices/billing/BillingSideNavSlice'
import T from '../../../T'

const BillingSecondaryNavBar = ({ heading = T.CREATE_DRAFT_INVOICES, customers, revenue, showRevenueCard = false }) => {
  const dispatch = useDispatch()
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  const handleDrawerToggle = () => dispatch(setShowBillingSideNav(!showBillingSideNav))

  return (
    <Grid
      container
      direction="row"
      pl={showBillingSideNav ? 3 : 1.5}
      pr={3}
      minHeight={56}
      alignItems="center"
      borderBottom={1}
      borderColor="divider"
    >
      <Grid item xs="auto" display="flex" alignItems="center">
        {!showBillingSideNav && <ReorderIcon onClick={handleDrawerToggle} />}
        <Typography component="span" variant="h4" fontWeight={600}>
          {heading}
        </Typography>
      </Grid>
      <Grid item container xs justifyContent="flex-end" alignItems="center" columnGap={2.5}>
        {showRevenueCard && (
          <Grid item xs="auto">
            <Card variant="outlined">
              <BillingSummaryCard customers={customers} revenue={formatCentsToDollars(revenue)} />
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

BillingSecondaryNavBar.propTypes = {
  heading: PropTypes.string,
  customers: PropTypes.number,
  revenue: PropTypes.number,
  showRevenueCard: PropTypes.bool,
}

export default BillingSecondaryNavBar
