import api from 'api'
import { CACHE_TAG_BILLING_PAYMENT_GROUP_LIST } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getPaymentGroupList: build.query({
      query: () => ({ url: '/api/v1/billing/payment/groups', method: 'GET' }),
      providesTags: [CACHE_TAG_BILLING_PAYMENT_GROUP_LIST],
    }),
  }),
})

export const { useGetPaymentGroupListQuery, useLazyGetPaymentGroupListQuery } = extendedApi
