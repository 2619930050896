import React, { useEffect } from 'react'
import { Grid, Box, useTheme } from '@mui/material'
import { get } from 'lodash'
import RenderLocationDetails from 'components/locations/LocationsDataGrid/RenderLocationDetailPanelContent/RenderLocationDetails'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'
import api from 'api'
import { useLazyGetLocationSummaryQuery } from 'api/location/getLocations'
import RenderConfiguredServices from './RenderConfiguredServices'

const RenderLocationDetailPanelContent = ({ row }) => {
  const dispatch = useDispatch()
  const accountId = get(row, 'accountId')
  const locationId = get(row, 'locationId')
  const [getLocationSummary, { isLoading, isFetching, data }] = useLazyGetLocationSummaryQuery()
  const services = get(data, 'services', [])
  const onSaveTags = (data, closeTagsDialog) => {
    dispatch(api.util.invalidateTags([CACHE_TAG_ACCOUNT_LOCATION_LIST]))
    closeTagsDialog()
  }
  const theme = useTheme()

  useEffect(() => {
    if (locationId) {
      getLocationSummary({
        locationId,
      })
    }
  }, [])

  return (
    <Box
      pl={7.5}
      pr={2}
      pb={3}
      backgroundColor="background.paper"
      width="100%"
      height="100%"
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Box mt={3}>
            <RenderLocationDetails caller="locations-list" dynamicWidth location={row} accountId={accountId} onSaveTags={onSaveTags} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box mt={2}>
            <RenderConfiguredServices accountId={accountId} locationId={locationId} services={services} loading={isLoading || isFetching} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

RenderLocationDetailPanelContent.propTypes = {
  row: PropTypes.object,
}

export default RenderLocationDetailPanelContent
