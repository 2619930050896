import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  setBulkMoveStopsState,
  selectBulkMoveFocusRowIndex,
  selectBulkMoveFocusName,
  selectBulkMoveStopsDate,
  selectIsOpenBulkMoveStopsDialog,
  selectIsOpenBulkMoveConfirmCloseDialog,
  selectIsSelectDateDialogOpen,
  setBulkMoveStopsDate,
  setConfirmPermanentMoveDialogState,
  selectIsConfirmPermanentMoveDialogOpen,
  selectToBeInsertedRowsIds,
  selectBulkMoveToRows,
  selectBulkMoveStopsToBeAssignedCount,
} from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DialogContent, Box } from '@mui/material'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import { FormProvider, useForm } from 'react-hook-form'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { deserializeDate, formatDateToBEFormatDateFns, serializeDate } from 'utils/date'
import SelectStopsToMoveMap from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/SelectStopsToMoveMap'
import MoveFromRouteColumn from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/MoveFromRouteColumn'
import MoveToRouteColumn from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/MoveToRouteColumn'
import { get, keyBy } from 'lodash'
import { MapProvider } from 'providers/MapProvider'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { defaultValues } from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/settings'
import { formatStopFeatures } from 'utils/route'
import { useLazyGetRouteStopsGeojsonQuery } from 'api/route/getRouteStops'
import useTheme from '@mui/material/styles/useTheme'
import { useBulkMoveStopsMutation } from 'api/route/moveStops'
import { handleError } from 'utils/error'
import api from 'api'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import { isValidHex } from 'utils/validations'
import { common } from '@mui/material/colors'
import SelectDateDialog from 'components/route-manager/BulkActions/common/SelectDateDialog'
import SequenceDialog from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/SequenceDialog'
import debounce from 'lodash/debounce'
import ConfirmPermanentMoveDialog from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/ConfirmPermanentMoveDialog/ConfirmPermanentMoveDialog'
import UnsavedChangesConfirmDialog from 'components/route-manager/BulkActions/common/UnsavedChangesConfirmDialog'
import BulkMoveStopsDialogActions from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/BulkMoveStopsDialogActions'
import {
  BULK_MOVE_MAP_LAYERS,
  getBulkMoveStopColumnSx,
  removeLayersAndSources,
  ROW_HEIGHT,
} from 'components/route-manager/BulkActions/settings'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'
import BulkDialogAppbar from 'components/route-manager/BulkActions/common/BulkDialogAppbar'
import useGetBEBatches from 'components/route-manager/BulkActions/common/useGetBEBatches'
import useSplitValidInvalidKeysFromFormValues from 'components/route-manager/BulkActions/common/useSplitValidInvalidKeysFromFormValues'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const BulkMoveStopsBetweenRoutesDialog = () => {
  const moveToGridApiRef = useGridApiRef()
  const moveFromGridApiRef = useGridApiRef()
  const confirmPermanentGridApiRef = useGridApiRef()
  const map = useRef()
  const open = useSelector(selectIsOpenBulkMoveStopsDialog)
  const isConfirmCloseDialogOpen = useSelector(selectIsOpenBulkMoveConfirmCloseDialog)
  const dispatch = useDispatch()
  const methods = useForm({
    defaultValues,
  })
  const toBeInsertedRowsIds = useSelector(selectToBeInsertedRowsIds)
  const [bulkMoveStops, { isLoading }] = useBulkMoveStopsMutation()
  const theme = useTheme()
  const [getRouteStopsGeojson, { data: geojsonData }] = useLazyGetRouteStopsGeojsonQuery()
  const [isPermanentMove, setIsPermanentMove] = useState(false)
  const serializedServiceDate = useSelector(selectBulkMoveStopsDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const { reset, watch, getValues, setFocus } = methods
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const moveToRows = useSelector(selectBulkMoveToRows)
  const focusName = useSelector(selectBulkMoveFocusName)
  const focusRowIndex = useSelector(selectBulkMoveFocusRowIndex)
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const isSelectDateDialogOpen = useSelector(selectIsSelectDateDialogOpen)
  const [routes, setRoutes] = useState([])
  const isConfirmPermanentMoveDialogOpen = useSelector(selectIsConfirmPermanentMoveDialogOpen)
  const getBEBatches = useGetBEBatches({ serviceDate, serializedServiceDate, moveToRouteId, moveToRows })
  const stopsToBeAssignedCount = useSelector(selectBulkMoveStopsToBeAssignedCount)
  const splitValidInvalidKeysFromFormValues = useSplitValidInvalidKeysFromFormValues(toBeInsertedRowsIds)
  const columnSx = getBulkMoveStopColumnSx(theme)

  const handleReset = (isSelectDateDialogOpen = true) => {
    const mapCurrentRef = map.current
    if (moveFromRouteId) removeLayersAndSources(mapCurrentRef, moveFromRouteId)
    if (moveToRouteId) removeLayersAndSources(mapCurrentRef, moveToRouteId)
    dispatch(
      setBulkMoveStopsState({
        isSelectDateDialogOpen,
        isUnsavedChangesModalOpen: false,
        moveFromRouteId: '',
        moveToRouteId: '',
        moveFromRows: [],
        moveToRows: [],
        selectedMoveFromRows: [],
        selectedMoveToRows: [],
        moveFromRouteStopMarkers: {},
        moveToRouteStopMarkers: {},
        moveFromRouteSequenceLine: {},
        moveToRouteSequenceLine: {},
        focus: {
          name: '',
          rowIndex: '',
        },
        mapLayer: BOTH_ROUTES_LAYER,
      })
    )
    reset(defaultValues)
  }

  const handleClose = useCallback(() => {
    if (stopsToBeAssignedCount === 0) {
      handleReset(false)
      dispatch(
        setBulkMoveStopsState({
          isOpen: false,
          serializedServiceDate: serializeDate(new Date()),
        })
      )
    } else {
      dispatch(
        setBulkMoveStopsState({
          isConfirmCloseDialogOpen: true,
        })
      )
    }
  }, [stopsToBeAssignedCount])

  const onCancelCloseDialog = () => {
    dispatch(
      setBulkMoveStopsState({
        isConfirmCloseDialogOpen: false,
      })
    )
  }

  const onConfirmCloseDialog = () => {
    handleReset(false)
    dispatch(
      setBulkMoveStopsState({
        isConfirmCloseDialogOpen: false,
        isOpen: false,
      })
    )
  }

  const formatGeojsonData = data => {
    const routeGeojsonData = get(data, 'routeGeojsonData', {})
    const moveFromRouteFeatures = get(routeGeojsonData, `${moveFromRouteId}.stops.features`, [])
    const moveToRouteFeatures = get(routeGeojsonData, `${moveToRouteId}.stops.features`, [])
    const newMoveFromRouteStopMarkers = formatStopFeatures(moveFromRouteFeatures, theme).map(marker => ({
      ...marker,
      properties: {
        ...marker?.properties,
        icon: 'monochrome-marker',
      },
    }))
    const newMoveToRouteStopMarkers = formatStopFeatures(moveToRouteFeatures, theme).map(marker => ({
      ...marker,
      properties: {
        ...marker?.properties,
        icon: 'monochrome-marker',
      },
    }))
    const newMoveFromRouteSequenceLine = get(routeGeojsonData, `${moveFromRouteId}.sequenceLine`, {})
    const newMoveToRouteSequenceLine = get(routeGeojsonData, `${moveToRouteId}.sequenceLine`, {})
    dispatch(
      setBulkMoveStopsState({
        moveFromRouteStopMarkers: newMoveFromRouteStopMarkers,
        moveFromRouteSequenceLine: newMoveFromRouteSequenceLine,
        moveToRouteStopMarkers: newMoveToRouteStopMarkers,
        moveToRouteSequenceLine: newMoveToRouteSequenceLine,
      })
    )
  }

  const onCloseSelectDateDialog = () => {
    dispatch(
      setBulkMoveStopsState({
        isSelectDateDialogOpen: false,
        serializedServiceDate: serializeDate(new Date()),
      })
    )
  }

  const onConfirmSelectDateDialog = () => {
    dispatch(
      setBulkMoveStopsState({
        isSelectDateDialogOpen: false,
        isOpen: true,
      })
    )
  }
  const onChangeDate = newServiceDate => {
    dispatch(setBulkMoveStopsDate(newServiceDate))
  }

  const onCloseConfirmPermanentMoveDialog = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: false,
      })
    )
  }

  const onConfirmPermanentMove = () => {
    dispatch(
      setConfirmPermanentMoveDialogState({
        isOpen: false,
      })
    )
    setIsPermanentMove(true)
    const beBatches = getBEBatches(true)
    bulkMoveStops({ moves: beBatches })
      .unwrap()
      .then(() => {
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        handleReset(false)
        dispatch(
          setBulkMoveStopsState({
            isOpen: false,
            isUnsavedChangesModalOpen: false,
          })
        )
      })
      .catch(handleError)
      .finally(() => {
        setIsPermanentMove(false)
      })
  }

  useEffect(() => {
    if (serializedServiceDate) {
      getRoutesMetadata({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
    }
  }, [serializedServiceDate])

  useEffect(() => {
    dispatch(
      setBulkMoveStopsState({
        moveFromRouteId,
        moveToRouteId,
        fitBounds: true,
        toBeInsertedRowsIds: [],
      })
    )
  }, [moveToRouteId, moveFromRouteId])

  useEffect(() => {
    const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(getValues())
    dispatch(
      setBulkMoveStopsState({
        invalidStopsToBeAssignedCount: invalidKeys.length,
        stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
        validStopIdsToBeAssigned: validKeys,
      })
    )
  }, [toBeInsertedRowsIds])

  useEffect(() => {
    let timeoutId
    if (focusName) {
      const fixedFocusIndex = focusRowIndex || 0
      setTimeout(() => {
        scrollToSelectedRowInDataGrid(moveToGridApiRef, fixedFocusIndex, ROW_HEIGHT)
        setTimeout(() => {
          if (focusName) {
            setFocus(focusName)
          }
          dispatch(
            setBulkMoveStopsState({
              focus: {
                name: '',
                rowIndex: 0,
              },
            })
          )
        }, 100)
      }, 100)
    }

    return () => clearTimeout(timeoutId)
  }, [focusName])

  useEffect(() => {
    if (moveFromRouteId || moveToRouteId) {
      getRouteStopsGeojson({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        routes: [moveFromRouteId, moveToRouteId].filter(Boolean),
      })
    }
  }, [serializedServiceDate, moveFromRouteId, moveToRouteId])

  useEffect(() => {
    formatGeojsonData(geojsonData)
  }, [geojsonData])

  useEffect(() => {
    if (routeMetaData) {
      const updatedRoutes = get(routeMetaData, 'routes', [])
      setRoutes(updatedRoutes)
    }
  }, [routeMetaData])

  useEffect(() => {
    const debouncedUpdate = debounce(values => {
      const { validKeys, invalidKeys } = splitValidInvalidKeysFromFormValues(values)
      dispatch(
        setBulkMoveStopsState({
          invalidStopsToBeAssignedCount: invalidKeys.length,
          stopsToBeAssignedCount: invalidKeys.length + validKeys.length,
          validStopIdsToBeAssigned: validKeys,
        })
      )
    }, 1)

    const subscription = watch(values => {
      debouncedUpdate(values)
    })

    return () => {
      debouncedUpdate.cancel()
      subscription.unsubscribe()
    }
  }, [watch, toBeInsertedRowsIds])

  useEffect(() => {
    const formattedRoutes = routes.map(route => {
      const color = get(route, 'color', common.black)
      const formattedColor = isValidHex(color) ? color : common.black
      const contrastColor = theme.palette.getContrastText(formattedColor)
      return {
        ...route,
        color: formattedColor,
        contrastColor,
      }
    })
    dispatch(
      setBulkMoveStopsState({
        routesById: keyBy(formattedRoutes, 'id'),
      })
    )
  }, [routes])

  useEffect(() => {
    return () => {
      handleReset(false)
    }
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <MapProvider map={map}>
          <HHFullScreenBaseDialog open={open} onClose={handleClose}>
            <BulkDialogAppbar onClose={handleClose} title="Move stops between routes" serviceDate={serviceDate} showDate />
            <DialogContent dividers sx={{ p: 0 }}>
              <Box display="flex" direction="row" flexWrap="nowrap" height="100%" overflow="hidden">
                <Box mt={5} display="flex" flexDirection="column" sx={columnSx}>
                  <MoveFromRouteColumn apiRef={moveFromGridApiRef} routes={routes} />
                </Box>
                <Box mt={5} display="flex" flexDirection="column" sx={{ order: 3, ...columnSx }}>
                  <MoveToRouteColumn apiRef={moveToGridApiRef} routes={routes} />
                </Box>
                <Box flex={1}>{open && <SelectStopsToMoveMap routes={routes} />}</Box>
              </Box>
            </DialogContent>
            <BulkMoveStopsDialogActions
              isPermanentLoading={isLoading}
              isPermanentMove={isPermanentMove}
              getBEBatches={getBEBatches}
              onReset={handleReset}
              onClose={handleClose}
            />
          </HHFullScreenBaseDialog>
        </MapProvider>
        <SequenceDialog
          moveFromGridApiRef={moveFromGridApiRef}
          moveToGridApiRef={moveToGridApiRef}
          DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
        />
        <ConfirmPermanentMoveDialog
          isPermanentLoading={isLoading}
          confirmPermanentGridApiRef={confirmPermanentGridApiRef}
          onClose={onCloseConfirmPermanentMoveDialog}
          onConfirm={onConfirmPermanentMove}
          isOpen={isConfirmPermanentMoveDialogOpen}
          DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
        />
      </FormProvider>
      <UnsavedChangesConfirmDialog onConfirm={onConfirmCloseDialog} onClose={onCancelCloseDialog} isOpen={isConfirmCloseDialogOpen} />
      <SelectDateDialog
        isOpen={isSelectDateDialogOpen}
        onConfirm={onConfirmSelectDateDialog}
        onClose={onCloseSelectDateDialog}
        onChange={onChangeDate}
        value={serviceDate}
        DialogProps={{ sx: { zIndex: theme.zIndex.snackbar + 4 } }}
      />
    </>
  )
}

BulkMoveStopsBetweenRoutesDialog.propTypes = {}

export default BulkMoveStopsBetweenRoutesDialog
