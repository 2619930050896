import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { isEnterOrNumpadEnterWithCtrlOrMeta } from 'utils/keyboard'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { HHFormTextField } from 'components/form-fields/v5'

const AddUpdateNoteDialog = ({
  id,
  isOpen = false,
  title = T.NEW_NOTE,
  existingNote = {},
  useCreateNoteMutation,
  useUpdateNoteMutation,
  onClose,
}) => {
  const [createNote, { isLoading: isCreateNoteLoading }] = useCreateNoteMutation()
  const [updateNote, { isLoading: isUpdateNoteLoading }] = useUpdateNoteMutation()
  const addUpdateNoteForm = useForm({ defaultValues: { note: '' } })
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = addUpdateNoteForm

  const noteHelperText = get(errors, 'note.message')

  const handleSave = data => {
    if (!isDirty) {
      onClose()
      return
    }

    const noteId = get(existingNote, 'id')
    if (noteId) {
      updateNote({
        id,
        noteId,
        data,
      })
        .unwrap()
        .then(() => {
          toast.success('Note updated successfully')
          onClose()
        })
        .catch(handleError)
      return
    }

    createNote({
      id,
      data,
    })
      .unwrap()
      .then(() => {
        toast.success('Note added successfully')
        onClose()
      })
      .catch(handleError)
  }

  const handleKeyDown = event => {
    if (isEnterOrNumpadEnterWithCtrlOrMeta(event)) {
      handleSubmit(handleSave)()
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset({ note: get(existingNote, 'note', '') })
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <FormProvider {...addUpdateNoteForm}>
          <HHFormTextField
            autoFocus
            control={control}
            error={noteHelperText}
            helperText={noteHelperText}
            rules={{ required: T.CANNOT_BE_EMPTY }}
            deprecatedLabel={false}
            name="note"
            label="Note"
            placeholder="Leave a note"
            sx={{ mt: 3, '& .MuiOutlinedInput-root': { height: 'inherit' } }}
            rows={4}
            multiline
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton
          loading={isCreateNoteLoading || isUpdateNoteLoading}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmit(handleSave)}
        >
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddUpdateNoteDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  existingNote: PropTypes.object,
  useCreateNoteMutation: PropTypes.func.isRequired,
  useUpdateNoteMutation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  additionalData: PropTypes.object,
}

export default AddUpdateNoteDialog
