import React, { useReducer, useEffect, useState } from 'react'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import routes from 'router/routes'
import { useHistory } from 'react-router-dom'

import { Grid, Box, Button, Typography } from '@mui/material'
import Loader from 'components/common/loader'
import CardSection from 'components/reports/CardSection'
import ReportDrawer from 'components/drawer/reports'
import { useLazyGetReportFullListQuery } from 'api/report/getReportFullList'
import { useLazyGetDefaultReportListQuery } from 'api/report/getDefaultReportList'
import { useLazyGetPricingMetaFiltersQuery } from 'api/meta/getPricingMetaFilters'
import { useSaveReportConfiguratorMutation } from 'api/report/saveReportConfigurator'
import { canAddReports } from 'data/permissions/permissionsSelectors'
import { pricingMeta } from 'slices/meta/pricingFiltersMetaSlice'
import {
  REVENUE_CONFIGURATOR,
  SERVICE_CONFIGURATOR,
  SENT_INVOICES_CONFIGURATOR,
  PAYMENTS_CONFIGURATOR,
  INVOICES_CONFIGURATOR,
  WORK_ORDER_CONFIGURATOR,
  ACCOUNT_RECEIVABLE_CONFIGURATOR,
} from 'containers/reports/settings'
import { DEFAULT_REPORTS } from 'settings/constants/reports'

import T from 'T'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'

const Reports = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const addReports = useSelector(canAddReports, shallowEqual)
  const [getReportFullList, { data, isSuccess, isFetching }] = useLazyGetReportFullListQuery()
  const [getDefaultReportList, { data: defaultReportList }] = useLazyGetDefaultReportListQuery()
  const [getPricingMetaFilters] = useLazyGetPricingMetaFiltersQuery()
  const [saveReportConfigurator, { isSuccess: saveSuccess }] = useSaveReportConfiguratorMutation()
  const [tempReportName, setTempReportName] = useState('')
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenReportDrawer: false,
  })
  const { isOpenReportDrawer } = localState
  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )
  const metabaseLink = get(userInfo, 'businessInfo.urlMetabase', '')

  const getTitle = selection => {
    switch (selection) {
      case T.AR_REPORT:
        return 'Accounts Receivable'
      case T.REVENUE_REPORT:
        return 'Revenue'
      case T.SERVICE_REPORT:
        return 'Services'
      case T.PAYMENT_REPORT:
        return 'Payments'
      case T.INVOICE_REPORT:
        return 'Invoices'
      case T.SI_REPORT:
        return 'Sent Invoices'
      case T.WO_REPORT:
        return 'Work Order'
      default:
        return ''
    }
  }

  const handleChange = (key, value) => {
    setLocalState({ [key]: value })
  }

  const getActiveTabConfigurator = selection => {
    switch (selection) {
      case T.AR_REPORT:
        return ACCOUNT_RECEIVABLE_CONFIGURATOR
      case T.REVENUE_REPORT:
        return REVENUE_CONFIGURATOR
      case T.SERVICE_REPORT:
        return SERVICE_CONFIGURATOR
      case T.PAYMENT_REPORT:
        return PAYMENTS_CONFIGURATOR
      case T.INVOICE_REPORT:
        return INVOICES_CONFIGURATOR
      case T.SI_REPORT:
        return SENT_INVOICES_CONFIGURATOR
      case T.WO_REPORT:
        return WORK_ORDER_CONFIGURATOR
      default:
        return []
    }
  }
  const handleType = reportType => {
    const type = DEFAULT_REPORTS.filter(item => item.reportParentType === reportType)
    return type[0].reportTypeFilter
  }
  const handleCreateReport = (reportName, reportType) => {
    setTempReportName(`${reportName} ${data.length - 1}`)
    const body = {
      name: `${reportName} ${data.length - 1}`,
      description: '',
      columnsConfig: getActiveTabConfigurator(reportType),
      type: handleType(reportType),
      sortRules: [],
      filterRules: [],
    }
    saveReportConfigurator(body)
      .unwrap()
      .then(() => {
        getReportFullList()
      })
  }
  const handleNewReport = () => {
    const newReport = data.filter(item => item.name === tempReportName)
    const type = DEFAULT_REPORTS.filter(item => item.reportTypeFilter === newReport[0].type)
    history.push({
      pathname: `${routes.app.reports}/${newReport[0].name.replaceAll(/\s/g, '')}`,
      state: { reportType: type[0].reportParentType, reportName: newReport[0].name, reportId: newReport[0].id },
    })
  }

  useEffect(() => {
    if (saveSuccess) {
      setLocalState({ isOpenReportDrawer: false })
    }
  }, [saveSuccess])

  useEffect(() => {
    getReportFullList()
    getDefaultReportList()
    // Get pricing filter meta
    getPricingMetaFilters()
      .then(response => {
        dispatch(pricingMeta(response))
      })
      .catch(handleError)
  }, [])

  useEffect(() => {
    if (saveSuccess) {
      handleNewReport()
    }
  }, [isSuccess, data])

  return (
    <Box>
      {isFetching && <Loader />}
      <Grid px={4} pt={3} container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h3">{T.REPORTS}</Typography>
        </Grid>

        <Grid item>
          {metabaseLink && (
            <Button
              variant="contained"
              onClick={() => {
                window.location.href = metabaseLink
              }}
              sx={{ mr: 2 }}
            >
              {T.MY_DASHBOARD}
            </Button>
          )}

          {addReports && (
            <Button variant="outlined" onClick={() => handleChange('isOpenReportDrawer', true)}>
              {T.CREATE_REPORT}
            </Button>
          )}
        </Grid>
      </Grid>

      <Box pl={4} pr={6.5} display="box" pb={1} maxHeight="calc(100vh - 150px)" sx={{ overflowY: 'auto' }}>
        {Array.isArray(data) && <CardSection reports={data} />}
      </Box>

      {isOpenReportDrawer && (
        <ReportDrawer
          isOpenDrawer={isOpenReportDrawer}
          title={T.CREATE_REPORT}
          defaultReports={get(defaultReportList, 'dataList', [])}
          getTitle={getTitle}
          onClick={handleCreateReport}
          onClose={isOpen => {
            setLocalState({ isOpenReportDrawer: isOpen })
          }}
        />
      )}
    </Box>
  )
}

export default Reports
