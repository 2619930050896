import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getDisposalFeeMatchedServices: build.mutation({
      query: body => ({
        url: `/api/pricing/fee/disposal/matched`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetDisposalFeeMatchedServicesMutation } = extendedApi
