import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { LinearProgress } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { getInvoicesByStatus } from 'data/invoice/invoiceSelectors'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'

import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import RenderHeader from './RenderHeader'
import RenderInvoiceCell from './RenderInvoiceCell'

const { UNPAID } = INVOICE_STATUS

const InvoicesDataGrid = () => {
  const [invoicesByAccounts, setInvoicesByAccounts] = useState({})
  const [getAccountInvoices, { data: invoiceList, isFetching: isLoading }] = useLazyGetAccountInvoicesQuery()
  const { watch, setValue } = useFormContext()
  const invoicesSelectionModel = watch('invoicesSelectionModel')
  const accountsSelectionModel = watch('accountsSelectionModel')
  const accountId = get(accountsSelectionModel, '[0]')

  const getRowHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ invoiceNumber }) => invoiceNumber, [])

  const handleSelectionModelChange = useCallback(newSelectionModel => {
    setValue('invoicesSelectionModel', newSelectionModel)
  }, [])

  useEffect(() => {
    if (accountId) {
      getAccountInvoices({ accountId }, true)
        .unwrap()
        .then(data => {
          const invoicesByStatus = getInvoicesByStatus({ invoices: get(data, 'invoices', []) })
          const allUnpaidInvoices = get(invoicesByStatus, UNPAID, [])
          setInvoicesByAccounts(prev => ({
            ...prev,
            [accountId]: allUnpaidInvoices,
          }))
        })
        .catch(handleError)
    }
  }, [accountId])

  const invoicesByStatus = getInvoicesByStatus({ invoices: get(invoiceList, 'invoices', []) })
  const rows = useMemo(() => get(invoicesByStatus, UNPAID, []), [invoicesByStatus])
  const allRows = useMemo(() => Object.values(invoicesByAccounts).flat(), [invoicesByAccounts])

  return (
    <DataGridPro
      loading={isLoading}
      rows={rows}
      hideFooter
      columns={[
        {
          field: 'id',
          flex: 1,
          resizable: false,
          sortable: false,
          filterable: false,
          renderHeader: () => <RenderHeader rows={allRows} selectedRows={invoicesSelectionModel} />,
          renderCell: RenderInvoiceCell,
        },
      ]}
      checkboxSelection
      disableMultipleRowSelection
      keepNonExistentRowsSelected
      rowSelectionModel={invoicesSelectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      disableColumnMenu
      slots={{
        noRowsOverlay: () => null,
        loadingOverlay: LinearProgress,
        baseCheckbox: params => <DataGridBaseCheckbox {...params} rows={rows} />,
      }}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
          },
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
          background: 'transparent',
        },
        '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
          backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
          padding: '0 4px',
        },
      }}
    />
  )
}

InvoicesDataGrid.propTypes = {
  rows: PropTypes.array,
}

export default InvoicesDataGrid
