export const DOLLAR_DECIMAL_DIGITS = 2
export const PERCENTAGE_DECIMAL_DIGITS = 5
export const PRICING_CONFIRMATION_MSG = 'Are you sure you want to go back? Any changes will be lost.'

// Ideally this should be like BILLING_TABS_ID
export const PRICING_TABS_ID = {
  SERVICES_TAB_ID: 'services',
  GENERAL_TAB_ID: 'general',
  DISPOSAL_TAB_ID: 'disposal',
  TAXES_TAB_ID: 'taxes',
  SERVICE_GROUPS_TAB_ID: 'serviceGroups',
}

const { SERVICES_TAB_ID, TAXES_TAB_ID, SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID

export const PRICING_PARAM_TO_TAB_ID = {
  services: SERVICES_TAB_ID,
  general_fees: 'fees',
  taxes: TAXES_TAB_ID,
  service_groups: SERVICE_GROUPS_TAB_ID,
}
