import { get } from 'utils/lodash'
import { getPricingServiceName } from 'components/pricing/Services/pricingServiceName'

const WEEKENDS = ['Saturday', 'Sunday', 'Weekend']
const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Working Days']

export const handleClass = (column, ind, allowedServices, saveConfSettings) => {
  let clasName = ''

  if (column.locked && saveConfSettings) {
    clasName += 'sticky-col'
    if (allowedServices.filter(data => data.checked).filter(data => data.locked).length === ind + 1) {
      clasName += ' add-shadow'
    }

    if (column.label === 'Service') {
      clasName += ' service-field'
    }

    if (column.label === 'Price') {
      clasName += ' price-field'
    }
  }

  if (column.label === 'Overage') {
    clasName += ' overage-field'
  }
  return clasName
}

export const handlePricingTableClass = (index, isRowLocked, lockedColumns) => {
  if (lockedColumns.length === index + 1) {
    return 'sticky-col add-shadow'
  }

  if (isRowLocked) {
    return 'sticky-col'
  }

  return ''
}

export const handleCheckBoxClass = saveConfSettings => {
  let clasName = ''

  if (saveConfSettings) {
    clasName += 'sticky-col'
  }
  return clasName
}

const setNormaldays = (records, checkedValues, label) => {
  let recordsValues = records.filter(data => data.id !== 'Weekend' && data.id !== 'Working Days')
  recordsValues &&
    recordsValues.forEach(data => {
      let rec = { id: data.id, label: data.value }
      checkedValues.push(rec)
    })
}

const setWeekValues = (weekData, checkedValues) => {
  weekData.forEach(option => {
    let val = { id: option, label: option }

    if (checkedValues.filter(check => check.id == val.id).length === 0) {
      checkedValues.push(val)
    }
  })
}

const handleDeselection = (weekValues, records, checkedValues) => {
  let recordsValues = records.filter(data => !weekValues.includes(data.id))
  recordsValues &&
    recordsValues.forEach(data => {
      let rec = { id: data.id, label: data.value }
      checkedValues.push(rec)
    })
}

const getWeeklyCondition = selectedVal => {
  return selectedVal !== 'Weekend' && selectedVal !== 'Working Days'
}

const getUncheckedWeelyCondition = selectedVal => {
  return !WEEKDAYS.includes(selectedVal) && !WEEKENDS.includes(selectedVal)
}

export const handleSpecificDays = (records, checkedValues, label, event) => {
  let selectedVal = get(event, 'target.innerText', '')
  let selectedOption = records.map(data => data.id).includes(selectedVal)

  let weeklyCond = getWeeklyCondition(selectedVal)
  let uncheckedWeeklyCond = getUncheckedWeelyCondition(selectedVal)

  let weekendCond =
    selectedVal === 'Weekend' ||
    (['Saturday', 'Sunday'].every(i => records.map(data => data.id).includes(i)) && records.map(data => data.id).length === 2)

  let workingDayCond =
    selectedVal === 'Working Days' ||
    (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].every(i => records.map(data => data.id).includes(i)) &&
      records.map(data => data.id).length === 5)

  if (selectedOption && weeklyCond && uncheckedWeeklyCond) {
    setNormaldays(records, checkedValues, label) //handles selections other than weekly or weekends with condition on selected value
  } else if (!selectedOption && weekendCond && selectedVal === 'Weekend') {
    handleDeselection(WEEKENDS, records, checkedValues) //handles Weekends deselection
  } else if (!selectedOption && workingDayCond && selectedVal === 'Working Days') {
    handleDeselection(WEEKDAYS, records, checkedValues) //handles Weekdays deselection
  } else if (weekendCond) {
    setWeekValues(WEEKENDS, checkedValues) //sets Weekend data
  } else if (workingDayCond) {
    setWeekValues(WEEKDAYS, checkedValues) //sets Weekdays data
  } else {
    setNormaldays(records, checkedValues, label) //handles selections other than weekly or weekends
  }

  if (records.length === 0) {
    checkedValues = []
  }

  return checkedValues
}

export const getIntervalName = interval => {
  switch (interval) {
    case 'Quarter':
      return 'Quarterly'
    case 'Week':
      return 'Weekly'
    case 'Year':
      return 'Yearly'
    case 'Day':
      return 'Daily'
    case 'Month':
      return 'Monthly'
    case 'Annual':
      return 'Annually'
    case 'Hour':
      return 'Hourly'
    default:
      return interval
  }
}

export const getServiceType = data => {
  if (get(data, 'serviceType') === 'Recurring') {
    const recurrenceFrequency = get(data, 'serviceRecurrenceFrequency', 0)
    const recurrencePer = get(data, 'recurrencePer')
    const recurrenceInterval = get(data, 'recurrenceInterval')

    let recurrence = recurrencePer

    if (recurrenceInterval > 1) {
      recurrence = `per ${recurrenceInterval} ${recurrencePer}s`
    }

    if (recurrenceFrequency > 0) {
      return `${recurrenceFrequency}x ${recurrence}`
    }
  }
  return get(data, 'serviceType')
}

export const getReverseIntervalName = interval => {
  switch (interval) {
    case 'Quarterly':
      return 'Quarter'
    case 'Weekly':
      return 'Week'
    case 'Yearly':
      return 'Year'
    case 'Daily':
      return 'Day'
    case 'Monthly':
      return 'Month'
    case 'Annually':
      return 'Annual'
    case 'Hourly':
      return 'Hour'
    default:
      return interval
  }
}

export const getSingularIntervalName = interval => {
  switch (interval) {
    case 'Weeks':
      return 'Week'
    case 'Years':
      return 'Year'
    case 'Days':
      return 'Day'
    case 'Months':
      return 'Month'
    case 'Quarters':
      return 'Quarter'
    case 'Hours':
      return 'Hour'
    case 'Instances':
      return 'Instance'
    case 'Quarterly':
      return 'Quarter'
    case 'Weekly':
      return 'Week'
    case 'Yearly':
      return 'Year'
    case 'Daily':
      return 'Day'
    case 'Monthly':
      return 'Month'
    case 'Annually':
      return 'Annual'
    case 'Hourly':
      return 'Hour'
    default:
      return interval
  }
}

export const getServiceHoverTitle = service => {
  let title = []
  let businessLine = getPricingServiceName(service, 'Business Line')
  let recurrence = getPricingServiceName(service, 'Recurrence')
  let measure = getPricingServiceName(service, 'Measure')
  let method = getPricingServiceName(service, 'Method')
  let material = getPricingServiceName(service, 'Material')

  if (get(service, 'serviceType') === 'On Request') {
    recurrence = ''
  }

  let pricingZone = get(service, 'pricingZone', '')
  if (pricingZone !== '') {
    pricingZone = get(pricingZone, 'zoneName', '')
  }

  ;[businessLine, recurrence, measure, method, material, pricingZone].forEach(
    data => ![undefined, null, ''].includes(data) && title.push(data.trim())
  )
  return title.join('.')
}

export const getPricingLabel = option => {
  const periodPer = get(option, 'periodPer', '')
  const periodInterval = get(option, 'periodInterval', '')
  if (periodPer > 1) {
    return `${periodPer} ${periodInterval}s`
  }
  return getIntervalName(periodInterval)
}

export const getPricingPeriod = records => {
  const periodValues = []
  records.map(option => {
    periodValues.push({ id: option.id, value: getPricingLabel(option) })
  })

  return periodValues
}

export const getRecurrenceLabel = option => {
  const recurrencePer = get(option, 'recurrencePer', '')
  const recurrenceInterval = get(option, 'recurrenceInterval', '')

  if (recurrencePer > 1) {
    return `${recurrencePer} ${recurrenceInterval}s`
  }
  return getIntervalName(recurrenceInterval)
}

export const getRecurrence = records => {
  const recurValues = []
  records.map(option => {
    recurValues.push({ id: option.id, value: getRecurrenceLabel(option) })
  })
  return recurValues
}

export const getMeasureLabel = option => {
  if (option.volume !== undefined) {
    return `${get(option, 'volume', '')} ${get(option, 'unit', '')}`
  }

  if (option.value !== undefined) {
    return get(option, 'value', '')
  }
}

export const getMeasureData = records => {
  const measureData = []
  records.map(option => {
    measureData.push({ id: option.id, value: getMeasureLabel(option) })
  })
  return measureData
}
