import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    unpayInvoice: build.mutation({
      query: ({ invoiceId }) => ({ url: `/api/v1/billing/invoice/${invoiceId}/unpay`, method: 'PUT' }),
    }),
  }),
})

export const { useUnpayInvoiceMutation } = extendedApi
