export const ROUTE_COLUMN_WIDTH = 375
export const ROUTE_COLUMN_HEIGHT = 'calc(100vh - 245px)'
export const UNASSIGNED_COLUMN_WIDTH = 375
export const UNASSIGNED_COLUMN_HEIGHT = 'calc(100vh - 245px)'
export const LEFT_DRAWER_WIDTH = 40 // 320px

export const getStyle = ({ draggableStyle, virtualStyle, isDragging }) => {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  }

  const grid = 8

  const result = {
    ...combined,
    marginTop: grid,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging ? draggableStyle.width : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid,
  }

  return result
}
