import { Tooltip, Typography, Alert, styled } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React from 'react'
import PropTypes from 'prop-types'
import T from 'T'

const SurchargeAlertTooltip = styled(Tooltip)(() => ({
  '& .MuiAlert-icon': {
    marginRight: 6,
  },
}))

const TransactionSurchargeAlert = ({ className }) => {
  return (
    <SurchargeAlertTooltip title={T.TRANSACTION_SURCHARGE_DESCRIPTION}>
      <Alert
        className={className}
        sx={{ p: 0, cursor: 'pointer' }}
        icon={
          <InfoOutlinedIcon
            sx={{
              width: 18,
              height: 18,
              pl: 0.25,
              ml: 1.25,
              mt: 0.15,
            }}
          />
        }
        severity="info"
      >
        <Typography variant="h4" fontSize="11px" lineHeight="18px">{`${T.NOTE}: ${T.TRANSACTION_SURCHARGE_NOTE}`}</Typography>
      </Alert>
    </SurchargeAlertTooltip>
  )
}

TransactionSurchargeAlert.propTypes = {
  className: PropTypes.string,
}

export default TransactionSurchargeAlert
