export const ADD_REMOVE_FORM_MODEL = {
  search: '',
  searchTagsInput: '',
  searchBillingInput: '',
  selectedTags: [],
  selectedBillingProfiles: [],
  selectionModel: [],
  expandedRowIds: [],
  tagList: [],
  billingProfileList: [],
}
