import React from 'react'
import PropTypes from 'prop-types'
import { Box, DialogActions } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormContext } from 'react-hook-form'
import { noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import T from 'T'
import HhConfirmDeleteButton from '../../../../common/HHConfirmDeleteButton'
import CancelButton from '../../../../buttons/CancelButton'

const DisposalTicketFormFooter = ({
  variant,
  onSubmit,
  onCancel = noop,
  isConfirmDeleteButtonLoading = false,
  isSubmitButtonLoading,
  onDeleteConfirmed = noop,
}) => {
  const theme = useTheme()
  const { watch } = useFormContext()
  const isModalVariant = variant === 'modal'
  const FooterWrapperComponent = isModalVariant ? DialogActions : Box
  return (
    <FooterWrapperComponent
      sx={
        isModalVariant
          ? { justifyContent: 'space-between', px: 3, paddingTop: 0, paddingBottom: 2 }
          : {
              px: 2,
              py: 1,
              borderTop: 1,
              display: 'flex',
              borderColor: 'border.light',
              alignItems: 'center',
              justifyContent: 'space-between',
            }
      }
    >
      <Box>
        {watch('disposalTicketId') && (
          <HhConfirmDeleteButton
            loading={isConfirmDeleteButtonLoading}
            confirmTitle="Delete disposal ticket"
            confirmDescription="Are you sure you want to delete this disposal ticket?"
            DialogProps={{ sx: { zIndex: theme.zIndex.tooltip + 20 } }}
            onDeleteConfirmed={onDeleteConfirmed}
          />
        )}
      </Box>
      <Box
        sx={{
          '& > :not(:first-of-type)': {
            marginLeft: 1,
          },
        }}
      >
        <CancelButton size="medium" onClick={onCancel} />
        <LoadingButton loading={isSubmitButtonLoading} onClick={onSubmit} variant="contained">
          {T.SAVE}
        </LoadingButton>
      </Box>
    </FooterWrapperComponent>
  )
}

DisposalTicketFormFooter.propTypes = {
  variant: PropTypes.oneOf(['accordion', 'modal']),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDeleteConfirmed: PropTypes.func,
  isConfirmDeleteButtonLoading: PropTypes.bool,
  isSubmitButtonLoading: PropTypes.bool,
}

export default DisposalTicketFormFooter
