import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

// inputData - Can be object or array
// key - Param to extract
// resultParam - Return value in that parameter if exist
// defaultValue - any default value

const selectInputData = state => get(state, 'inputData')
const selectKey = state => get(state, 'key')
const selectResultParam = state => get(state, 'resultParam')
const selectDefaultValue = state => state?.defaultValue

export const selectValueFromState = createSelector(
  [selectInputData, selectKey, selectResultParam, selectDefaultValue],
  (inputData, key, resultParam, defaultValue) => {
    if (!resultParam) {
      return get(inputData, key, defaultValue)
    }

    return { [resultParam]: get(inputData, key, defaultValue) }
  }
)
