/* eslint-disable no-param-reassign */
import { get } from 'lodash'
import { createBulkActionSlice, getInitialBulkActionState } from 'slices/generic/createBulkActionSlice'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

const { FROM_ROUTE_LAYER } = BULK_MOVE_MAP_LAYERS

const bulkPermanentRecurrenceChangeSlice = createBulkActionSlice({
  initialState: getInitialBulkActionState(FROM_ROUTE_LAYER),
  name: 'bulkPermanentRecurrenceChange',
  reducers: {
    resetBulkState: (state, action) => {
      return { ...getInitialBulkActionState(FROM_ROUTE_LAYER), ...action.payload }
    },
  },
})
export const {
  setBulkState: setPermanentRecurrenceChangeState,
  addSelectedMoveToRows,
  addSelectedMoveFromRows,
  setSelectedMoveFromRows,
  setSelectedMoveToRows,
  setConfirmPermanentMoveDialogState,
  resetBulkState,
} = bulkPermanentRecurrenceChangeSlice.actions

export const selectBulkMoveFocusName = state => get(state, 'bulkPermanentRecurrenceChange.focus.name')
export const selectBulkMoveFocusRowIndex = state => get(state, 'bulkPermanentRecurrenceChange.focus.rowIndex', 0)
export const selectBulkMoveFromRouteSequenceLine = state => get(state, 'bulkPermanentRecurrenceChange.moveFromRouteSequenceLine', {})
export const selectBulkMoveFromRouteStopMarkers = state => get(state, 'bulkPermanentRecurrenceChange.moveFromRouteStopMarkers', {})
export const selectBulkMoveFromRows = state => get(state, 'bulkPermanentRecurrenceChange.moveFromRows', [])
export const selectBulkMoveInvalidStopsToBeAssignedCount = state =>
  get(state, 'bulkPermanentRecurrenceChange.invalidStopsToBeAssignedCount', 0)
export const selectBulkMoveValidStopIdsToBeAssigned = state => get(state, 'bulkPermanentRecurrenceChange.validStopIdsToBeAssigned', [])
export const selectBulkMoveMapLayer = state => get(state, 'bulkPermanentRecurrenceChange.mapLayer', FROM_ROUTE_LAYER)
export const selectBulkMoveStopsDate = state => get(state, 'bulkPermanentRecurrenceChange.serializedServiceDate')
export const selectBulkMoveStopsToBeAssignedCount = state => get(state, 'bulkPermanentRecurrenceChange.stopsToBeAssignedCount', 0)
export const selectBulkMoveToRouteSequenceLine = state => get(state, 'bulkPermanentRecurrenceChange.moveToRouteSequenceLine', {})
export const selectBulkMoveToRouteStopMarkers = state => get(state, 'bulkPermanentRecurrenceChange.moveToRouteStopMarkers', {})
export const selectBulkMoveToRows = state => get(state, 'bulkPermanentRecurrenceChange.moveToRows', [])
export const selectBulkSelectedMoveFromRows = state => get(state, 'bulkPermanentRecurrenceChange.selectedMoveFromRows', [])
export const selectBulkSelectedMoveToRows = state => get(state, 'bulkPermanentRecurrenceChange.selectedMoveToRows', [])
export const selectIsOpenBulkMoveConfirmCloseDialog = state => get(state, 'bulkPermanentRecurrenceChange.isConfirmCloseDialogOpen', false)
export const selectIsOpenBulkMoveBetweenDaysStopsDialog = state => get(state, 'bulkPermanentRecurrenceChange.isOpen', false)
export const selectBulkMoveRoutesById = state => get(state, 'bulkPermanentRecurrenceChange.routesById', {})

export const selectIsSelectDateDialogOpen = state => get(state, 'bulkPermanentRecurrenceChange.isSelectDateDialogOpen', false)
export const selectIsSequenceDialogOpen = state => get(state, 'bulkPermanentRecurrenceChange.isSequenceDialogOpen', false)
export const selectBulkMoveFitBounds = state => get(state, 'bulkPermanentRecurrenceChange.fitBounds', false)
export const selectBulkMoveToSerializedDate = state => get(state, 'bulkPermanentRecurrenceChange.toSerializedDate', false)
export const selectBulkMoveFromSerializedDate = state => get(state, 'bulkPermanentRecurrenceChange.fromSerializedDate', false)
export const selectBulkMoveRouteId = state => get(state, 'bulkPermanentRecurrenceChange.routeId')
export const selectBulkMoveRoutes = state => get(state, 'bulkPermanentRecurrenceChange.routes', [])
export const selectBulkMoveFetchId = state => get(state, 'bulkPermanentRecurrenceChange.fetchId')
export const selectToBeInsertedRowsIds = state => get(state, 'bulkPermanentRecurrenceChange.toBeInsertedRowsIds', [])

export const selectIsConfirmPermanentMoveDialogOpen = state =>
  get(state, 'bulkPermanentRecurrenceChange.confirmPermanentMoveDialog.isOpen', false)

export default bulkPermanentRecurrenceChangeSlice.reducer
