import api from 'api'
import { CACHE_TAG_RENTAL_FEES } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    createRentalFee: build.mutation({
      query: body => ({
        url: '/api/v1/core/fee/rental',
        method: 'POST',
        body,
      }),
      invalidatesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    updateRentalFee: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/fee/rental/${id}`,
        method: 'PUT',
        body: { ...rest },
      }),
      invalidatesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    getRentalFees: build.query({
      query: body => ({
        url: '/api/v1/core/fee/rental',
        method: 'GET',
        body,
      }),
      providesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    getRentalFeeDetails: build.query({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/rental/${id}`,
      }),
      providesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    archiveRentalFee: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/rental/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    restoreRentalFee: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/rental/${id}/restore`,
        method: 'PUT',
      }),
      invalidatesTags: [CACHE_TAG_RENTAL_FEES],
    }),
    deleteRentalFee: build.mutation({
      query: ({ id }) => ({
        url: `/api/v1/core/fee/rental/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CACHE_TAG_RENTAL_FEES],
    }),
  }),
})

export const {
  useCreateRentalFeeMutation,
  useUpdateRentalFeeMutation,
  useGetRentalFeesQuery,
  useLazyGetRentalFeesQuery,
  useLazyGetRentalFeeDetailsQuery,
  useArchiveRentalFeeMutation,
  useRestoreRentalFeeMutation,
  useDeleteRentalFeeMutation,
} = extendedApi
