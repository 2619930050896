import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Typography, Card, CardHeader, CardContent, CardActions, Box, CircularProgress, useTheme, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { useGetAccountGroupDetailsQuery } from 'api/groups/account/getAccountGroupDetails'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { ReactComponent as LayerIcon } from 'assets/Layer.svg'
import { Link } from 'react-router-dom-v5-compat'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import routes from 'router/routes'
import BillingProfilesStatistic from 'components/customers/groups/account/BillingProfilesStatistic'
import AccountGroupActionMenu from 'components/customers/groups/account/AccountGroupActionMenu'
import AddAccountsToGroupDialog from 'components/customers/groups/account/add-remove/AddAccountsToGroupDialog'
import CarouselWrapper from 'components/billing/groups/common/CarouselWrapper'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import AccountStatusStatistic from 'components/customers/groups/account/AccountStatusStatistic'
import { useGetAccountGroupStatisticsQuery } from 'api/groups/account/getAccountGroupStatistics'

const AccountGroupCard = ({ group }) => {
  const theme = useTheme()
  const [accountGroupAnchorEl, setAccountGroupAnchorEl] = useState(null)
  const [isOpenAddAccountsToGroupDialog, setIsOpenAddAccountsToGroupDialog] = useState(false)
  const groupId = get(group, 'groupId')
  const { data = {}, isFetching: isDetailsFetching, isLoading: isDetailsLoading } = useGetAccountGroupDetailsQuery({ id: groupId })

  const createdBy = get(group, 'createdBy', '')
  const createdAt = get(group, 'createdAt', '')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const groupDetailsLink = `${routes.app.accountGroups}/${groupId}`

  const {
    data: statisticsData,
    isSuccess: isStatisticsSuccess,
    isLoading: isStatisticsLoading,
    isFetching: isStatisticsFetching,
  } = useGetAccountGroupStatisticsQuery({ id: groupId })
  const loadingDetails = useMemo(() => isDetailsLoading || isDetailsFetching, [isDetailsLoading, isDetailsFetching])
  const loadingStatistics = useMemo(() => isStatisticsLoading || isStatisticsFetching, [isStatisticsLoading, isStatisticsFetching])
  const loading = useMemo(() => loadingDetails || loadingStatistics, [loadingDetails, loadingStatistics])
  const { accountStatus, billingProfilesGraphData, accountStatusGraphData, cutOffBillingProfiles = 0 } = statisticsData || {}
  const hasAccounts = get(accountStatus, 'activeCount', 0) + get(accountStatus, 'deactivatedCount', 0) > 0

  const handleOpenAccountGroupActionMenu = event => setAccountGroupAnchorEl(event.currentTarget)

  const handleCloseAccountGroupActionMenu = () => setAccountGroupAnchorEl(false)

  const handleOpenAddAccountsToGroupDialog = () => setIsOpenAddAccountsToGroupDialog(true)

  const handleCloseAddAccountsToGroupDialog = () => setIsOpenAddAccountsToGroupDialog(false)

  return (
    <>
      <Card variant="outlined" sx={{ boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)' }}>
        <CardHeader
          sx={{
            pl: 1.5,
            pr: 1,
            py: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            '& .MuiCardHeader-content': { width: '60%' },
          }}
          avatar={<LayerIcon />}
          title={
            <Typography noWrap variant="h5" fontWeight={500} ml={2}>
              {get(group, 'groupName', '')}
            </Typography>
          }
          action={
            <>
              <Tooltip title={T.VIEW_ACCOUNT_GROUP_DETAIL}>
                <IconButton component={Link} to={groupDetailsLink}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={T.ADD_ACCOUNTS}>
                <IconButton onClick={handleOpenAddAccountsToGroupDialog}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleOpenAccountGroupActionMenu}>
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Box height={310}>
            {loadingStatistics && (
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {!loadingStatistics && !hasAccounts && (
              <Box height="100%" display="flex" alignItems="center">
                <HHAlert borderColor={theme.palette.info.light} severity="info">
                  This account group is empty
                </HHAlert>
              </Box>
            )}

            {!loadingStatistics && isStatisticsSuccess && hasAccounts && (
              <CarouselWrapper>
                {billingProfilesGraphData && (
                  <BillingProfilesStatistic
                    data={billingProfilesGraphData}
                    moreCount={cutOffBillingProfiles}
                    sx={{ mr: 3 }}
                    graphMargin={{ top: 16, right: 16, bottom: 8, left: 72 }}
                  />
                )}
                {accountStatusGraphData && <AccountStatusStatistic data={accountStatusGraphData} />}
              </CarouselWrapper>
            )}
          </Box>
        </CardContent>
        <CardActions sx={{ px: 2, py: 1.5, justifyContent: 'space-between', borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="caption">{`Created by ${createdBy}`}</Typography>
          {createdAt && <Typography variant="caption">{`${onlyTime} | ${onlyDate}`}</Typography>}
        </CardActions>
      </Card>
      {accountGroupAnchorEl && (
        <AccountGroupActionMenu
          anchorEl={accountGroupAnchorEl}
          selectedGroup={data}
          showEditGroupOption
          showRemoveAccountsOption
          showBulkUnsuspendOption
          showBulkSuspendOption
          loading={loading}
          onClose={handleCloseAccountGroupActionMenu}
        />
      )}
      {isOpenAddAccountsToGroupDialog && (
        <AddAccountsToGroupDialog isOpen={isOpenAddAccountsToGroupDialog} groupId={groupId} onClose={handleCloseAddAccountsToGroupDialog} />
      )}
    </>
  )
}

AccountGroupCard.propTypes = {
  group: PropTypes.object,
}

export default AccountGroupCard
