import api from '../index'
import { CACHE_TAG_WORK_ORDER_REVIEW_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getWorkOrdersReviewData: builder.query({
      providesTags: [CACHE_TAG_WORK_ORDER_REVIEW_LIST],
      query: body => {
        return {
          url: `/api/v1/core/work-orders/review`,
          body,
          method: 'POST',
        }
      },
    }),
    getWorkOrdersCount: builder.query({
      providesTags: [CACHE_TAG_WORK_ORDER_REVIEW_LIST],
      query: body => {
        return {
          url: `/api/v1/core/work-orders/count`,
          body,
          method: 'POST',
        }
      },
    }),
  }),
})

export const { useLazyGetWorkOrdersReviewDataQuery, useLazyGetWorkOrdersCountQuery } = extendedApi
