import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Button, ListItemText, ListItem } from '@mui/material'
import { get, noop } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import CloseIcon from '@mui/icons-material/Close'
import { Download } from '@mui/icons-material'
import { format, parse } from 'date-fns'

import { blobToBase64, getExceptionImageBlob } from 'utils/file'
import T from 'T'
import { handleError } from 'utils/error'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import ExceptionImage from './ExceptionImage'
import SwiperContainer from './SwiperContainer'

const swiperSx = {
  'swiper-slide img': {
    width: '100%',
  },
}

const thumbsSwiperSx = {
  'swiper-slide': {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  '.thumbs-swiper swiper-slide': {
    width: '25%',
    height: '100%',
  },
  '.thumbs-swiper swiper-slide:not(.swiper-slide-active)': {
    opacity: 0.4,
  },
  '.thumbs-swiper swiper-slide .swiper-slide-active': {
    opacity: 1,
  },
  'swiper-slide img': {
    width: '100%',
    height: '100%',
  },
  '.thumbs-swiper swiper-slide img': {
    objectFit: 'cover',
    objectPosition: 'center',
  },
}

const ExceptionGallery = ({ open, onClose = noop, exception, accountId, workOrderId }) => {
  const swiperRef = useRef()
  const thumbsSwiperRef = useRef()
  const theme = useTheme()
  const [currentIndex, setCurrentIndex] = useState(0)

  const images = get(exception, 'images', [])
  const imagesCount = images.length
  const hasManyImages = imagesCount > 1
  const signatures = get(exception, 'signatures', [])
  const swiper = swiperRef?.current?.swiper
  const currentPhoto = get(images, `${currentIndex}`)
  const photoName = get(currentPhoto, `name`)
  const createdAt = get(exception, 'createdAt')
  const fileLocation = get(exception, 'images[0].fileLocation')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const date = onlyDate && parse(onlyDate, 'MM/dd/yyyy', new Date())
  const formattedDate = date && format(date, 'MMM dd, yyyy')

  const handleDownloadImage = () => {
    getExceptionImageBlob(fileLocation)
      .then(blobToBase64)
      .then(base64 => {
        if (base64) {
          const link = document.createElement('a')
          link.href = base64
          link.download = `${photoName}.png`
          link.click()
        }
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', swiper => {
        setCurrentIndex(swiper.realIndex)
      })
    }
  }, [swiper])

  return (
    <Dialog
      PaperProps={{ sx: { bgcolor: theme.palette.common.black } }}
      sx={{ zIndex: theme.zIndex.snackbar, color: theme.palette.common.white }}
      fullWidth
      fullScreen
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: 'relative', bgcolor: theme.palette.common.black, minHeight: '56px' }}>
        <Toolbar sx={{ minHeight: '56px' }}>
          <IconButton onClick={onClose} edge="start" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
          <ListItem>
            <ListItemText
              primary={`${photoName}.png`}
              primaryTypographyProps={{ variant: 'h6', color: 'common.white' }}
              secondaryTypographyProps={{ color: 'common.white' }}
              secondary={`${formattedDate} at ${onlyTime}`}
            />
          </ListItem>
          <Button variant="text" startIcon={<Download />} onClick={handleDownloadImage} edge="start" color="inherit" aria-label="close">
            {T.DOWNLOAD}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent className="exceptions-gallery" sx={{ height: '100%', overflowY: 'hidden' }}>
        <SwiperContainer
          sx={{
            ...swiperSx,
            '.main-swiper': {
              height: hasManyImages ? 'calc(85vh - 56px)' : 'calc(100vh - 56px)',
              width: '100%',
            },
            '.main-swiper swiper-slide img': {
              objectFit: 'contain',
              backgroundColor: '#000',
              objectPosition: 'center',
            },
            swiper: {
              height: '100px',
              width: '100%',
            },
            'swiper-container': {
              height: '100%',
              width: '100%',
            },
          }}
          swiperRef={swiperRef}
          className="main-swiper"
          thumbs-swiper=".thumbs-swiper"
          loop
          spaceBetween={10}
          navigation
        >
          {Array.isArray(images) &&
            images.map(image => {
              return (
                <ExceptionImage
                  key={image?.name}
                  height={hasManyImages ? 'calc(85vh - 56px)' : 'calc(100vh - 56px)'}
                  exception={exception}
                  photo={image}
                  signature={get(signatures, currentIndex)}
                  accountId={accountId}
                  workOrderId={workOrderId}
                  withSwiperSlide
                />
              )
            })}
        </SwiperContainer>

        <SwiperContainer
          sx={{
            ...thumbsSwiperSx,
            '.thumbs-swiper': {
              height: hasManyImages ? '15vh' : '0px',
              boxSizing: 'border-box',
              padding: '10px 0',
            },
          }}
          swiperRef={thumbsSwiperRef}
          className="thumbs-swiper"
          spaceBetween={10}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          centeredSlides
        >
          {Array.isArray(images) &&
            hasManyImages &&
            images.map(image => {
              return (
                <ExceptionImage
                  key={image?.name}
                  height={hasManyImages ? '15vh' : '0px'}
                  exception={exception}
                  photo={image}
                  signature={get(signatures, `index`)}
                  accountId={accountId}
                  workOrderId={workOrderId}
                  withSwiperSlide
                  size="small"
                />
              )
            })}
        </SwiperContainer>
      </DialogContent>
    </Dialog>
  )
}

ExceptionGallery.propTypes = {
  exception: PropTypes.object,
  accountId: PropTypes.string,
  workOrderId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ExceptionGallery
