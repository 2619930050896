import React, { useState } from 'react'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { canAddPricingManager } from 'data/permissions/permissionsSelectors'
import { openPricingDrawer, selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { selectIsCreateEditRentalFeeDialogOpen, setIsCreateEditRentalFeeDialogOpen } from 'slices/pricing/rentalFeesSlice'

import T from 'T'
import CreateEditRentalFeeDialog from 'components/pricing/RentalFees/CreateEditRentalFeeDialog'
import RentalFeesHeaderActionMenu from 'components/pricing/RentalFees/common/RentalFeesHeaderActionMenu'

const SecondaryNavbar = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const addPricingManager = useSelector(canAddPricingManager, shallowEqual)
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const isCreateEditRentalFeeDialogOpen = useSelector(selectIsCreateEditRentalFeeDialogOpen)

  const handleOpenActionMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseActionMenu = () => {
    setAnchorEl(null)
  }

  const handleOpenPricingDrawer = () => {
    dispatch(openPricingDrawer())
  }

  const handleOpenCreateRentalFeeDialog = () => {
    dispatch(setIsCreateEditRentalFeeDialogOpen(true))
  }

  const handleCloseCreateRentalFeeDialog = () => {
    dispatch(setIsCreateEditRentalFeeDialogOpen(false))
  }

  return (
    <>
      <Box
        width="100%"
        overflow="hidden"
        px={isTabletOrMobile ? 2 : 4}
        height={56}
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent={isTabletOrMobile ? 'normal' : 'space-between'}
        bgcolor="background.default"
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.divider,
        }}
      >
        <Stack flexDirection="row" alignItems="center" columnGap={1} sx={{ flex: 1, flexGrow: 1 }}>
          {!isOpenPricingDrawer && <ReorderIcon sx={{ mr: 1 }} onClick={handleOpenPricingDrawer} />}

          <Typography variant="h4" fontWeight="600">
            {T.RENTAL_FEES}
          </Typography>
        </Stack>
        <Stack flexDirection="row" justifyContent="flex-end" columnGap={1}>
          {addPricingManager && (
            <IconButton onClick={handleOpenCreateRentalFeeDialog}>
              <AddIcon />
            </IconButton>
          )}
          <IconButton onClick={handleOpenActionMenu}>
            <MoreVertIcon />
          </IconButton>

          <RentalFeesHeaderActionMenu anchorEl={anchorEl} onClose={handleCloseActionMenu} />
        </Stack>
      </Box>
      <CreateEditRentalFeeDialog isOpen={isCreateEditRentalFeeDialogOpen} onClose={handleCloseCreateRentalFeeDialog} />
    </>
  )
}

SecondaryNavbar.propTypes = {}

export default SecondaryNavbar
