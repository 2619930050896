import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { useLazyGetCustomerPortalSettingsQuery } from 'api/settings/getCustomerPortalSettings'
import { useSaveCustomerPortalSettingsMutation } from 'api/settings/saveCustomerPortalSettings'
import Loader from 'components/common/loader'
import FooterButton from 'components/buttons/FooterButton'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import T from 'T'

import ColorScheme from './CustomerPortal/ColorScheme'

const CustomerPortalSettings = ({ selectedTab = '' }) => {
  const [getCustomerPortalSettings, { isLoading, isFetching }] = useLazyGetCustomerPortalSettingsQuery()
  const [saveCustomerPortalSettings, { isLoading: isSaveLoading, isFetching: isSaveFetching }] = useSaveCustomerPortalSettingsMutation()

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    mainColor: '#0f0f13',
    secondaryColor: '#bd10e0',
    navigationFontColor: '#949599',
    actionButtonColor: '#7ed321',
    actionButtonFontColor: '#fff',
    picker: false,
    paperDelievery: false,
    pdfDelievery: false,
    portalDelievery: false,
    postCardDelievery: false,
    customerPortalId: '',
    showFooter: false,
  })

  const {
    mainColor,
    secondaryColor,
    navigationFontColor,
    actionButtonColor,
    actionButtonFontColor,
    paperDelievery,
    pdfDelievery,
    portalDelievery,
    postCardDelievery,
    customerPortalId,
    showFooter,
  } = localState

  const getPortalSettings = () => {
    getCustomerPortalSettings()
      .unwrap()
      .then(res => {
        setLocalState({
          mainColor: get(res, 'mainColor', mainColor),
          secondaryColor: get(res, 'secondaryColor', secondaryColor),
          navigationFontColor: get(res, 'navigationFontColor', navigationFontColor),
          actionButtonColor: get(res, 'actionButtonColor', actionButtonColor),
          actionButtonFontColor: get(res, 'actionButtonFontColor', actionButtonFontColor),
          paperDelievery: res.paperDelievery,
          pdfDelievery: res.pdfDelievery,
          portalDelievery: res.portalDelievery,
          postCardDelievery: res.postCardDelievery,
          customerPortalId: res.id,
        })
      })
      .catch(handleError)
  }

  const tabCheck = selectedTab === 'customer-portal'

  useEffect(() => {
    getPortalSettings()
  }, [tabCheck])

  const onHandleChange = (event, type) => {
    const { name, value, checked } = event.target

    let record = checked

    if (type === 'color') {
      record = value
    }

    localState[name] = record
    setLocalState({ [name]: record, showFooter: true })
  }

  const handleColorPickerChangeComplete = (color, type) => {
    localState[type] = color.hex
    setLocalState({ [type]: color.hex, showFooter: true })
  }

  const saveCustomerPortalChanges = () => {
    const payload = new FormData()
    payload.append('mainColor', mainColor)
    payload.append('secondaryColor', secondaryColor)
    payload.append('navigationFontColor', navigationFontColor)
    payload.append('actionButtonColor', actionButtonColor)
    payload.append('actionButtonFontColor', actionButtonFontColor)
    payload.append('paperDelievery', paperDelievery)
    payload.append('pdfDelievery', pdfDelievery)
    payload.append('portalDelievery', portalDelievery)
    payload.append('postCardDelievery', postCardDelievery)

    if (customerPortalId !== null) {
      payload.append('id', customerPortalId)
    }

    saveCustomerPortalSettings(payload)
      .unwrap()
      .then(() => {
        setLocalState({ showFooter: false })
      })
      .catch(handleError)
  }

  const handleCancel = () => {
    getPortalSettings()
  }

  return (
    <Box>
      {(isLoading || isFetching || isSaveLoading || isSaveFetching) && <Loader />}
      <Box p="4px 30px" minHeight="calc(100vh - 130px)" width="60%">
        <ColorScheme
          mainColor={mainColor}
          secondaryColor={secondaryColor}
          navigationFontColor={navigationFontColor}
          actionButtonColor={actionButtonColor}
          actionButtonFontColor={actionButtonFontColor}
          onHandleChange={onHandleChange}
          handleColorPickerChangeComplete={handleColorPickerChangeComplete}
        />
      </Box>

      {showFooter && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={() => handleCancel()}
            rightButtonTitle={T.SAVE}
            onProceed={() => saveCustomerPortalChanges()}
          />
        </Box>
      )}
    </Box>
  )
}

CustomerPortalSettings.propTypes = {
  selectedTab: PropTypes.string,
}

export default CustomerPortalSettings
