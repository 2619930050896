import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, MenuItem, IconButton, Link, Tooltip } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'

import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS, CACHE_TAG_ROUTES_METADATA_BY_DATE } from 'api/cacheTagTypes'
import { useMoveStopsMutation } from 'api/route/moveStops'
import { handleError } from 'utils/error'
import { formatDateToBEFormatDateFns, deserializeDate } from 'utils/date'
import { setStopDetailsDialogState, setResequenceHistory, VIEW_MODE_MAP, selectRouteSerializedServiceDate } from 'slices/route/routeSlice'
import { getCustomerDetailsPageUrl } from 'router/routes'
import { getViewMode } from 'data/route/viewModeSelector'

import T from 'T'
import api from 'api'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import EditServiceDialog from 'components/work-order/details/EditServiceDialog'
import { canMoveStopToAnotherRoute } from '../helper'

const StopDetailsActions = ({ stop }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isUnassignConfirmDialogOpen, setIsUnassignConfirmDialogOpen] = useState(false)
  const [isOpenEditWOStatusDialog, setIsOpenEditWOStatusDialog] = useState(false)

  const [moveStops, { isLoading: isMoveStopsLoading }] = useMoveStopsMutation()

  const viewMode = useSelector(getViewMode)
  const isTemporaryModeActive = useSelector(state => state.Route.isRouteChangesOnTemporaryMode)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const { workOrderId, workOrderStatus, routeId, accountId } = stop
  const canMoveStop = canMoveStopToAnotherRoute(workOrderStatus)

  const handleCloseStopDetailsDialog = () => dispatch(setStopDetailsDialogState({ isOpen: false, id: '', stop: {}, refetchStop: false }))

  const handleRefetchStopDetailsDialogData = () => dispatch(setStopDetailsDialogState({ refetchStop: true }))

  const handleClickActionMenu = event => setAnchorEl(event.currentTarget)

  const handleCloseActionMenu = () => setAnchorEl(null)

  const handleUnassignStopClick = () => {
    handleCloseActionMenu()
    setIsUnassignConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = () => setIsUnassignConfirmDialogOpen(false)

  const handleChangeWOStatusClick = () => {
    handleCloseActionMenu()
    setIsOpenEditWOStatusDialog(true)
  }

  const handleCloseWOStatusDialog = () => {
    setIsOpenEditWOStatusDialog(false)
    handleRefetchStopDetailsDialogData()
  }

  const handleChangeWOStatusSuccess = () => {
    if (viewMode === VIEW_MODE_MAP) {
      dispatch(setResequenceHistory({ track: true }))
    }
  }

  const handleMoveStops = () => {
    moveStops({
      serviceDate: formatDateToBEFormatDateFns(serviceDate),
      stops: [workOrderId],
      updateMasterSequence: !isTemporaryModeActive,
    })
      .unwrap()
      .then(() => {
        toast.success(T.UNASSIGN_STOP_SUCCESS_MSG)
        dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS, CACHE_TAG_ROUTES_METADATA_BY_DATE]))
        handleCloseStopDetailsDialog()
      })
      .catch(handleError)
  }

  return (
    <>
      <IconButton onClick={handleClickActionMenu}>
        <MoreHoriz sx={{ fontSize: 28 }} />
      </IconButton>

      <Menu
        disableScrollLock
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
      >
        <MenuItem sx={{ p: 0 }}>
          <Link
            sx={{ width: '100%', px: 2, py: 0.75 }}
            color="textPrimary"
            component={RouterLink}
            to={`${getCustomerDetailsPageUrl(accountId)}?tab=services`}
            rel="noopener noreferrer"
            target="_blank"
            underline="none"
          >
            View account
          </Link>
        </MenuItem>
        <MenuItem onClick={handleChangeWOStatusClick}>{T.CHANGE_WORK_ORDER_STATUS}</MenuItem>
        {routeId && (
          <Tooltip title={T.DISABLE_UNASSIGN_STOP_MSG} disableHoverListener={canMoveStop}>
            <span>
              <MenuItem disabled={!canMoveStop} sx={{ color: 'warning.main' }} onClick={handleUnassignStopClick}>
                {T.UNASSIGN_STOP}
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>

      <HHConfirmDialog
        isOpen={isUnassignConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleMoveStops}
        confirmTitle={T.UNASSIGN_STOP}
        confirmDescription={T.UNASSIGN_STOP_CONFIRM_MSG}
        confirmButtonTitle={T.UNASSIGN}
        ConfirmButtonProps={{ loading: isMoveStopsLoading }}
      />

      <EditServiceDialog
        title={T.CHANGE_WORK_ORDER_STATUS}
        isOpen={isOpenEditWOStatusDialog}
        currentWOStatus={workOrderStatus}
        workOrderId={workOrderId}
        onClose={handleCloseWOStatusDialog}
        callback={handleChangeWOStatusSuccess}
      />
    </>
  )
}

StopDetailsActions.propTypes = {
  stop: PropTypes.object,
}

export default StopDetailsActions
