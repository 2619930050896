import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableHead, TableRow, TableContainer, SvgIcon, Stack, styled, TableBody, Typography } from '@mui/material'
import UnstyledTableCell, { tableCellClasses } from '@mui/material/TableCell'

import { capitalize, get } from 'lodash'

import T from 'T'
import { ReactComponent as MultiplicationSign } from 'assets/MultiplicationSign.svg'
import { ReactComponent as EqualSign } from 'assets/EqualSign.svg'
import { shallowEqual, useSelector } from 'react-redux'
import HHDisplayMoney from '../../../../common/HHDisplayMoney'

const TableCell = styled(UnstyledTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.sizeSmall}`]: {
    padding: 0,
    paddingBottom: theme.spacing(0.25),
    paddingTop: theme.spacing(0.25),
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
  },
  [`&.${tableCellClasses.head}`]: {
    ...theme.typography.h5,
  },
  [`&.${tableCellClasses.body}`]: {
    ...theme.typography.h5,
    fontWeight: 400,
  },
}))
const GeneralFeesDetails = ({ workOrderFees = [] }) => {
  const generalFeesById = useSelector(state => state.workOrdersReview.generalFeesById, shallowEqual)
  const fees = workOrderFees.map(fee => {
    const { feeTaxId } = fee
    const selectedFee = generalFeesById[feeTaxId]
    const feeCharge = get(fee, 'feeCharge', 0)
    const quantity = get(fee, 'quantity', 0)
    const subChargeType = get(selectedFee, 'subChargeType', 'General')
    const feeName = get(selectedFee, 'feeName', '')
    return {
      ...fee,
      feeName,
      feeCharge,
      subChargeType,
      total: feeCharge * quantity,
      isEdit: 'true',
    }
  })
  const totalCharge = workOrderFees.reduce((acc, val) => {
    const feeCharge = get(val, 'feeCharge', 0)
    const quantity = get(val, 'quantity', 0)
    const total = feeCharge * quantity
    return acc + total
  }, 0)

  return (
    <TableContainer sx={{ minWidth: '250px', overflow: 'hidden', p: 2 }}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
        dense
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>{capitalize(T.FEE)}</TableCell>
            <TableCell align="right">{T.QTY}</TableCell>
            <TableCell>
              <Stack height="100%" justifyContent="center" alignItems="center">
                <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
                  <MultiplicationSign />
                </SvgIcon>
              </Stack>
            </TableCell>
            <TableCell align="right">{capitalize(T.COST)}</TableCell>
            <TableCell>
              <Stack height="100%" justifyContent="center" alignItems="center">
                <SvgIcon inheritViewBox viewBox=" 0 0 20 20" sx={{ width: '20px', height: '20px' }}>
                  <EqualSign />
                </SvgIcon>
              </Stack>
            </TableCell>
            <TableCell align="right">{capitalize(T.PRICE)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fees.map(fee => {
            const name = get(fee, 'feeName')
            const charge = get(fee, 'feeCharge')
            const qty = get(fee, 'quantity')
            const total = get(fee, 'total')
            return (
              <TableRow>
                <TableCell>{name}</TableCell>
                <TableCell align="right">{qty}</TableCell>
                <TableCell />
                <TableCell align="right">
                  <HHDisplayMoney value={charge} formatToDollars={false} />
                </TableCell>
                <TableCell />
                <TableCell align="right">
                  <HHDisplayMoney value={total} formatToDollars={false} />
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell colSpan={5} />
            <TableCell align="right">
              <Typography variant="h5" fontWeight={600}>
                <HHDisplayMoney value={totalCharge} formatToDollars={false} />
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

GeneralFeesDetails.propTypes = {
  workOrderFees: PropTypes.array,
}

export default GeneralFeesDetails
