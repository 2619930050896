import { PRICING_TABS_ID } from 'settings/constants/pricing'

import endpoint from 'middleware/sagas/endpoint'

const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID

const GET_PRICING_SUMMARY_URLS = new Map([
  ['services', endpoint.pricing.service.summary],
  ['taxes', endpoint.pricing.tax.summary],
  ['general', endpoint.pricing.fee.general.summary],
  ['disposal', endpoint.pricing.fee.disposal.summary],
  ['', ''],
])

const GET_PRICING_LIST_URLS = new Map([
  ['services', endpoint.pricing.service.index],
  ['taxes', endpoint.pricing.tax.index],
  ['general', endpoint.pricing.fee.general.index],
  ['disposal', endpoint.pricing.fee.disposal.index],
  [SERVICE_GROUPS_TAB_ID, endpoint.serviceGroups.list],
  ['', ''],
])

const GET_PRICING_REVENUE_URLS = new Map([
  ['services', endpoint.pricing.service.revenue],
  ['general', endpoint.pricing.fee.general.revenue],
  ['disposal', endpoint.pricing.fee.disposal.revenue],
  ['', ''],
])

export const getPricingSummaryURL = (type, activeTab) => {
  if (type === 'list') {
    return GET_PRICING_SUMMARY_URLS.get(activeTab)
  }

  return ''
}

export const getPricingURL = (type, activeTab) => {
  if (type === 'list') {
    return GET_PRICING_LIST_URLS.get(activeTab)
  }

  return ''
}

export const getPricingRevenueURL = (type, activeTab) => {
  if (type === 'list') {
    return GET_PRICING_REVENUE_URLS.get(activeTab)
  }

  return ''
}

export const generateFeeName = ({ businessLine = '', pricingZone = '', measure = '', material = '' }) =>
  [businessLine, pricingZone, measure, material].filter(Boolean).join(' ')

export const generateServiceName = ({ businessLine = '', recurrence = '', measure = '', method = '', material = '' }) =>
  [businessLine, recurrence, measure, method, material].filter(Boolean).join(' ')

export const generateTaxName = ({ businessLine = '', taxZone = '', measure = '', material = '' }) =>
  [businessLine, taxZone, measure, material].filter(Boolean).join(' ')
