import React, { useEffect, useMemo } from 'react'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import useTheme from '@mui/material/styles/useTheme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { get } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { fixTimezoneForDateFilters } from 'slices/generic/fixDateFilters'
import { INVOICE_PAGINATION } from 'settings/constants/pagination'
import { queryParamsToFiltersMap } from 'utils/LHSbracket'
import { handleCommonTableformatFilters } from 'utils/table'
import { useLazyGetBillingInvoiceMetaQuery } from 'api/billing/getBillingInvoiceMeta'
import {
  setPaymentsColumnVisibilityModel,
  setPaymentsFilters,
  setPaymentsIsOpenTableFilters,
  setPaymentsPage,
  setPaymentsPageSize,
  setPaymentsPinnedColumns,
  setPaymentsRefetchFlag,
  setPaymentsSelectionModel,
  setPaymentsSortModel,
  setPaymentReceiptDialogState,
} from 'slices/billing/paymentsSlice'
import { PAYMENT_CONFIGURATOR } from 'containers/billing/settings'
import { useLazyGetBillingPaymentListQuery } from 'api/billing/getBillingPaymentList'
import { DataGridPagination } from '../../data_grid/DataGridPagination'
import CustomLoadingOverlay from '../common/CustomLoadingOverlay'

const { ROWS_PER_PAGE_OPTIONS } = INVOICE_PAGINATION

const PaymentsDataGrid = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsMap = Object.fromEntries(searchParams)
  const filtersFromSearchParams = queryParamsToFiltersMap(searchParamsMap)
  const allFiltersFromSearchParams = handleCommonTableformatFilters(filtersFromSearchParams || {})
  const page = useSelector(state => state.payments.pagination.page)
  const pageSize = useSelector(state => state.payments.pagination.pageSize)
  const [getBillingPaymentList, { isFetching, isLoading, data }] = useLazyGetBillingPaymentListQuery()
  const [getBillingInvoiceMeta] = useLazyGetBillingInvoiceMetaQuery()
  const refetchFlag = useSelector(state => state.payments.filters.refetchFlag)
  const rows = get(data, 'tableRows', [])
  const rowCount = get(data, 'totalItems')
  const sortModel = useSelector(state => state.payments.sorting.sortModel, shallowEqual)
  const selectionModel = useSelector(state => state.payments.selectionModel, shallowEqual)
  const queryParams = useSelector(state => state.payments.filters.queryParams, shallowEqual)
  const columnVisibilityModel = useSelector(state => state.payments.columns.columnVisibilityModel)
  const rawSearchFilters = useSelector(state => state.payments.filters.searchFilters, shallowEqual)
  const rawSortRules = useSelector(state => state.payments.filters.sortRules, shallowEqual)
  const pinnedColumns = useSelector(state => state.payments.pinnedColumns, shallowEqual)
  const searchFilters = isEmpty(rawSearchFilters) ? undefined : rawSearchFilters
  const timeZone = useSelector(state => get(state, 'AuthReducer.userInfo.timeZone'), shallowEqual)
  const searchFiltersTimezoned = useMemo(() => fixTimezoneForDateFilters(searchFilters, timeZone), [searchFilters, timeZone])
  const sortRules = isEmpty(rawSortRules) ? undefined : rawSortRules

  const isRowSelectable = ({ row }) => {
    const paymentNumber = get(row, 'paymentNumber')
    return Boolean(paymentNumber)
  }

  const getRowId = ({ accountTransactionHistoryId }) => accountTransactionHistoryId

  const handlePageSizeChange = newSize => {
    dispatch(setPaymentsPageSize(newSize))
    dispatch(setPaymentsPage(0))
    getBillingPaymentList({ sortRules, searchFilters, activeBillingTab: 'payments', requestedPage: 0, requestedPageSize: newSize })
  }

  const handlePageChange = newPage => {
    if (isLoading || isFetching) return
    dispatch(setPaymentsPage(newPage))
    getBillingPaymentList({ sortRules, searchFilters, activeBillingTab: 'payments', requestedPage: newPage, requestedPageSize: pageSize })
  }

  const handlePaymentsPageAndSizeChange = ({ page: newPage, pageSize: newPageSize }) => {
    if (page !== newPage) {
      handlePageChange(newPage)
      return
    }

    if (pageSize !== newPageSize) {
      handlePageSizeChange(newPageSize)
    }
  }

  const handleSortModelChange = newSortModel => {
    dispatch(setPaymentsSortModel(newSortModel))
    dispatch(setPaymentsRefetchFlag(true))
  }

  const handleSelectionModelChange = newSelectionModel => {
    dispatch(setPaymentsSelectionModel({ selectionModel: newSelectionModel, rows }))
  }

  const handleColumnVisibilityModelChange = newColumnVisibilityModel => {
    dispatch(setPaymentsColumnVisibilityModel(newColumnVisibilityModel))
  }

  const handlePinnedColumnModelChange = updatedPinnedColumns => {
    dispatch(setPaymentsPinnedColumns(updatedPinnedColumns))
  }

  const handleRowClick = params => {
    const { row } = params
    const { accountId, customerName, paymentNumber, accountTransactionHistoryId } = row
    if (!paymentNumber || !accountTransactionHistoryId) return
    dispatch(
      setPaymentReceiptDialogState({
        isOpen: true,
        payment: { accountId, accountName: customerName, paymentNumber, accountTransactionHistoryId },
      })
    )
  }

  useEffect(() => {
    getBillingInvoiceMeta()
    dispatch(
      setPaymentsFilters({
        allFilters: filtersFromSearchParams,
      })
    )
    getBillingPaymentList({
      sortRules,
      searchFilters: fixTimezoneForDateFilters(
        [
          ...(searchFilters && Array.isArray(searchFilters) ? searchFilters : []),
          ...(allFiltersFromSearchParams && Array.isArray(allFiltersFromSearchParams) ? allFiltersFromSearchParams : []),
        ],
        timeZone
      ),
      activeBillingTab: 'payments',
      requestedPage: page,
      requestedPageSize: pageSize,
    })
  }, [])

  useEffect(() => {
    if (refetchFlag) {
      getBillingPaymentList({
        sortRules,
        searchFilters: searchFiltersTimezoned,
        activeBillingTab: 'payments',
        requestedPage: page,
        requestedPageSize: pageSize,
      })
        .unwrap()
        .then(() => {
          dispatch(setPaymentsRefetchFlag(false))
          dispatch(setPaymentsIsOpenTableFilters(false))
        })
    }
  }, [refetchFlag])

  useEffect(() => {
    if (queryParams) {
      setSearchParams({ ...queryParams })
    }
  }, [queryParams])

  return (
    <DataGridPro
      initialState={{ pagination: { paginationModel: { page, pageSize } } }}
      checkboxSelection
      disableRowSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      disableColumnFilter
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={{ page, pageSize }}
      rows={rows}
      pagination
      pinnedColumns={pinnedColumns}
      rowCount={rowCount}
      sortModel={sortModel}
      onPinnedColumnsChange={handlePinnedColumnModelChange}
      rowSelectionModel={selectionModel}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleSelectionModelChange}
      onPaginationModelChange={handlePaymentsPageAndSizeChange}
      onRowClick={handleRowClick}
      isRowSelectable={isRowSelectable}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      getRowId={getRowId}
      columnVisibilityModel={columnVisibilityModel}
      columns={PAYMENT_CONFIGURATOR}
      loading={isLoading || isFetching}
      sx={{
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        '.MuiDataGrid-columnHeader': {
          fontWeight: 'bold',
        },
        '.MuiDataGrid-toolbarContainer': {
          paddingLeft: 1.5,
        },
      }}
      slots={{
        toolbar: GridToolbar,
        pagination: DataGridPagination,
        loadingOverlay: CustomLoadingOverlay,
      }}
    />
  )
}

export default PaymentsDataGrid
