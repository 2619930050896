import { orderBy, lowerCase, uniq } from 'lodash'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { PAYMENT_METHODS, PAYMENT_STATUS } from 'settings/constants/payment'
import { calculateSum } from 'utils/price'

const { CHECK, CASH, OTHER, CREDIT_CARD, ACH, ACCOUNT_CREDIT } = PAYMENT_METHODS
const { APPROVED, DECLINED, REVERSED, TRANSFERRED, FAILED } = PAYMENT_STATUS

export const getPaymentsFromBatch = createSelector([state => state?.paymentBatches], (paymentBatches = []) =>
  paymentBatches.map(batch => get(batch, 'payments', [])).flat()
)

export const getPaymentsByBatch = createSelector([state => state?.paymentBatches], (paymentBatches = []) => {
  const batches = {}
  let individualBatch = []
  paymentBatches.forEach(({ paymentBatchId, payments = [] }) => {
    if (payments.length > 1) {
      batches[paymentBatchId] = payments
    } else {
      individualBatch = [...individualBatch, ...payments.map(payment => ({ ...payment, isIndividualPayment: true }))]
    }
  })

  return individualBatch.length ? { ...batches, 'Individual payments': [...individualBatch] } : { ...batches }
})

export const getPaymentTypeStats = createSelector([state => state?.payments], (payments = []) => {
  const allTypes = {
    [CHECK]: { count: 0, total: 0, rows: [] },
    [CASH]: { count: 0, total: 0, rows: [] },
    [OTHER]: { count: 0, total: 0, rows: [] },
    [CREDIT_CARD]: { count: 0, total: 0, rows: [] },
    [ACH]: { count: 0, total: 0, rows: [] },
    [ACCOUNT_CREDIT]: { count: 0, total: 0, rows: [] },
  }

  payments.forEach(payment => {
    const { paymentMethod, transactionAmountCents } = payment
    if (!paymentMethod) return
    allTypes[paymentMethod].count += 1
    allTypes[paymentMethod].total += transactionAmountCents
    allTypes[paymentMethod].rows.push(payment)
  })

  return allTypes
})

export const getPaymentTransactionStats = createSelector([state => state?.payments], (payments = []) => {
  const allStatus = {
    [APPROVED]: { count: 0, total: 0, rows: [] },
    [DECLINED]: { count: 0, total: 0, rows: [] },
    [REVERSED]: { count: 0, total: 0, rows: [] },
    [TRANSFERRED]: { count: 0, total: 0, rows: [] },
    [FAILED]: { count: 0, total: 0, rows: [] },
  }

  payments.forEach(payment => {
    const { transactionStatus, transactionAmountCents } = payment
    if (!transactionStatus) return
    allStatus[transactionStatus].count += 1
    allStatus[transactionStatus].total += transactionAmountCents
    allStatus[transactionStatus].rows.push(payment)
  })

  return {
    ...allStatus,
    [FAILED]: {
      // Merge declined with failed
      count: allStatus[DECLINED].count + allStatus[FAILED].count,
      total: allStatus[DECLINED].total + allStatus[FAILED].total,
      rows: [...allStatus[DECLINED].rows, ...allStatus[FAILED].rows],
    },
  }
})

export const getAccountsPaymentsForDataGrid = createSelector(
  [state => state?.paymentBatches, state => state?.search],
  (paymentBatches = [], search = '') => {
    const payments = getPaymentsFromBatch({ paymentBatches })
    const lowerCaseSearch = lowerCase(search)
    const filteredPayments = search ? payments.filter(({ customerName }) => lowerCase(customerName).includes(lowerCaseSearch)) : payments
    const sortedPayments = orderBy(filteredPayments, [({ customerName }) => lowerCase(customerName)], ['asc'])

    const accountRows = {}
    const paymentsByBatch = {}
    let hierarchyIds = []

    sortedPayments.forEach(payment => {
      const {
        id,
        batchId,
        accountId,
        customerName,
        accountNumber,
        customerAddress: line1,
        customerUnitNumber: unitNumber,
        customerCity: city,
        customerState: state,
        customerZipCode: zipCode,
      } = payment
      const existingAccountObj = accountRows[accountId]
      const existingPaymentObj = paymentsByBatch[batchId]

      if (existingAccountObj) {
        accountRows[accountId].batchIds = uniq([...existingAccountObj.batchIds, batchId])
      } else {
        accountRows[accountId] = {
          accountId,
          accountName: customerName,
          accountAddress: { line1, unitNumber, city, state, zipCode },
          accountNumber,
          batchIds: [batchId],
        }
      }

      if (existingPaymentObj) {
        paymentsByBatch[batchId].push({ ...payment, hierarchy: [batchId, id] })
      } else {
        paymentsByBatch[batchId] = [{ ...payment, hierarchy: [batchId, id] }]
      }
    })

    let paymentRows = []
    let totalAmountCents = 0

    Object.keys(paymentsByBatch).forEach(batch => {
      const currentPayments = paymentsByBatch[batch]
      const currentGroupTotalCents = calculateSum(currentPayments, 'transactionAmountCents')
      if (currentPayments.length) {
        paymentRows = [
          ...paymentRows,
          {
            id: batch,
            isParent: true,
            groupName: batch,
            hierarchy: [batch],
            totalCents: currentGroupTotalCents,
            accountIds: uniq(currentPayments.map(({ accountId }) => accountId)),
          },
          ...currentPayments,
        ]
        totalAmountCents += currentGroupTotalCents
        hierarchyIds = [...hierarchyIds, batch]
      }
    })

    return { accountRows: Object.values(accountRows), paymentRows, totalAmountCents, hierarchyIds }
  }
)
