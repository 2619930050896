import { createRoot } from 'react-dom/client'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import App from 'containers/app/App'
import Constants from 'Constants'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro'

import reportWebVitals from './reportWebVitals'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)

const { LAUNCH_DARKLY_CLIENT_SIDE_ID, MUI_X_LICENSE_KEY } = Constants

LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY)
;(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID })

  root.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
