import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'utils/lodash'
import AccountsV2 from 'containers/customers/AccountsV2'
import CustomerSideNav from 'components/customers/common/side-nav/CustomerSideNav'
import CustomerLayout from 'components/customers/common/CustomerLayout'
import OnRampProvider from 'providers/OnRampProvider'
import PropTypes from 'prop-types'
import { GENERAL_VIEW_MODE } from 'components/customers/accounts/settings'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import NavbarWrapper from '../components/common/NavbarWrapper'

const AccountsPage = ({ viewMode = GENERAL_VIEW_MODE }) => {
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)

  return (
    <OnRampProvider>
      <ProtectedRouteHoC>
        <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
          <CustomerSideNav />
          <CustomerLayout open={showCustomerSideNav}>
            <AccountsV2 viewMode={viewMode} />
          </CustomerLayout>
        </NavbarWrapper>
      </ProtectedRouteHoC>
    </OnRampProvider>
  )
}

AccountsPage.propTypes = {
  viewMode: PropTypes.string,
}

export default AccountsPage
