import React from 'react'
import PropTypes from 'prop-types'

import { Carousel } from 'react-responsive-carousel'
import { IconButton, Box, useTheme } from '@mui/material'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

const CarouselWrapper = ({ children }) => {
  const theme = useTheme()

  const handleRenderArrowPrev = clickHandler => (
    <Box position="absolute" display="flex" alignItems="center" height="100%" zIndex={1} onClick={clickHandler}>
      <IconButton size="small" sx={{ border: '1px solid', borderColor: 'divider' }}>
        <ChevronLeftOutlinedIcon />
      </IconButton>
    </Box>
  )

  const handleRenderArrowNext = clickHandler => (
    <Box position="absolute" display="flex" alignItems="center" height="100%" zIndex={1} top={0} right={0} onClick={clickHandler}>
      <IconButton size="small" sx={{ border: '1px solid', borderColor: 'divider' }}>
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Box>
  )

  return (
    <Box
      height="100%"
      sx={{
        '& .carousel-root, & .carousel-slider': { height: '100%' },
        '& .control-dots': {
          margin: 0,
          '& .dot': {
            backgroundColor: `${theme.palette.action.active} !important`,
            boxShadow: 'none !important',
          },
        },
      }}
    >
      <Carousel
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={handleRenderArrowPrev}
        renderArrowNext={handleRenderArrowNext}
        infiniteLoop
      >
        {children}
      </Carousel>
    </Box>
  )
}

CarouselWrapper.propTypes = {
  children: PropTypes.node,
}

export default CarouselWrapper
