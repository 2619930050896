import { CONTACT_METHODS, CONTACT_METHOD_TYPES } from 'settings/constants/contacts'

const { EMAIL } = CONTACT_METHODS
const { PERSONAL } = CONTACT_METHOD_TYPES

export const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  position: '',
  active: true,
  deactivated: false,
  associatedWithLocation: false,
  associatedLocations: [],
  contactMethods: [
    {
      contactMethodType: PERSONAL,
      methodType: EMAIL,
      methodValue: '',
      serviceNotifications: true,
      billingNotifications: true,
    },
  ],
}

export const getAddModel = (initialFirstName, initialLastName, initialMethodValue) => {
  return {
    ...INITIAL_STATE,
    firstName: initialFirstName,
    lastName: initialLastName,
    contactMethods: [{ ...INITIAL_STATE.contactMethods[0], methodValue: initialMethodValue }],
  }
}

export const ADD_EDIT_CONTACT_PARENT_TYPES = {
  ACCOUNTS: 'accounts',
  ACCOUNT_GROUP: 'accountGroup',
  CUSTOMER_DETAILS: 'customerDetails',
}
