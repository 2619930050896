import T from 'T'
import capitalize from 'lodash/capitalize'

const {
  CUSTOMER,
  AMOUNT,
  BALANCE,
  STATUS,
  POSTED_DATE,
  POSTED,
  UNPOSTED,
  LATE_FEES,
  PAYMENT,
  CYCLE,
  TYPE,
  PAYMENT_TERMS,
  PAPER,
  PDF,
  EMAIL,
  PORTAL,
  CUSTOMER_ADDRESS,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP,
  ACCOUNT,
  CUSTOMER_BILLING_PROFILE,
  TAGS,
  ADJUSTMENTS,
  ADJUSTMENTS_TOTAL,
} = T

export const INVOICE_CONFIGURATOR = [
  {
    width: 360,
    position: 0,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    checked: true,
    locked: false,
    filterType: 'meta',
    columnName: 'customerBillingProfile',
  },
  {
    width: 160,
    position: 1,
    tableLabel: `${ACCOUNT} #`,
    label: `${ACCOUNT} #`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'accountNumber',
    className: 'font-500',
  },
  {
    width: 270,
    position: 2,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'customerName',
  },
  {
    width: 180,
    position: 3,
    tableLabel: 'Invoice #',
    label: 'Invoice #',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'invoiceNumber',
  },
  {
    width: 140,
    position: 4,
    tableLabel: 'Inv date',
    label: 'Invoice Date',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'invoiceDate',
    subType: 'date',
  },
  {
    width: 230,
    position: 5,
    tableLabel: 'Sent Date',
    label: 'Sent Date',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'sentDate',
    subType: 'dateTime',
  },
  {
    width: 135,
    position: 6,
    tableLabel: AMOUNT,
    label: AMOUNT,
    checked: true,
    locked: false,
    align: 'right',
    className: 'font-500 tr',
    filterType: 'number',
    columnName: 'amountCents',
    subType: 'money',
  },
  {
    width: 135,
    position: 7,
    tableLabel: BALANCE,
    label: BALANCE,
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'balanceCents',
    subType: 'money',
  },
  {
    width: 135,
    position: 8,
    tableLabel: STATUS,
    label: STATUS,
    checked: true,
    locked: false,
    filterType: 'checkbox',
    columnName: 'status',
    subType: 'invoiceStatus',
  },
  {
    position: 9,
    tableLabel: 'WO',
    label: 'Work Orders',
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'wo',
  },
  {
    position: 10,
    tableLabel: '# Serv',
    label: 'Services',
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'service',
  },
  {
    position: 11,
    tableLabel: POSTED_DATE,
    label: POSTED_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'postDate',
    subType: 'dateTime',
  },
  {
    position: 12,
    tableLabel: POSTED,
    label: POSTED,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'postedAmountCents',
    subType: 'money',
  },
  {
    position: 13,
    tableLabel: UNPOSTED,
    label: UNPOSTED,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'unPostedAmountCents',
    subType: 'money',
  },
  {
    position: 14,
    tableLabel: LATE_FEES,
    label: LATE_FEES,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'lateFees',
    subType: 'price',
  },
  {
    position: 15,
    tableLabel: PAYMENT,
    label: PAYMENT,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'paymentNumber',
  },
  {
    position: 16,
    tableLabel: CYCLE,
    label: CYCLE,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'billingCycleText',
    isSingleSelect: true,
  },
  {
    position: 17,
    tableLabel: 'Billing Timing',
    label: 'Billing Timing',
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'billingTiming',
  },
  {
    position: 18,
    tableLabel: TYPE,
    label: TYPE,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'type',
    isSingleSelect: true,
  },
  {
    position: 19,
    tableLabel: PAYMENT_TERMS,
    label: PAYMENT_TERMS,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'paymentTermsText',
    isSingleSelect: true,
  },
  {
    position: 20,
    tableLabel: PDF,
    label: PAPER,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'paper',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 21,
    tableLabel: EMAIL,
    label: PDF,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'pdf',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 22,
    tableLabel: PORTAL,
    label: PORTAL,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'portal',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    width: 300,
    position: 23,
    tableLabel: CUSTOMER_ADDRESS,
    label: CUSTOMER_ADDRESS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'customerAddress',
  },
  {
    position: 23,
    tableLabel: CUSTOMER_CITY,
    label: CUSTOMER_CITY,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'customerCity',
  },
  {
    position: 24,
    tableLabel: CUSTOMER_STATE,
    label: CUSTOMER_STATE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'customerState',
  },
  {
    position: 25,
    tableLabel: CUSTOMER_ZIP,
    label: CUSTOMER_ZIP,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'customerZip',
  },
  {
    width: 300,
    position: 26,
    tableLabel: TAGS,
    label: TAGS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'tags',
    className: 'tags',
    subType: 'tags',
    hideSort: true,
  },
  {
    position: 27,
    tableLabel: ADJUSTMENTS,
    label: ADJUSTMENTS,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'adjustments',
  },
  {
    position: 28,
    tableLabel: ADJUSTMENTS_TOTAL,
    label: ADJUSTMENTS_TOTAL,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'adjustmentsTotal',
    subType: 'price',
  },
]

export const REGENERATION_BULK_ACTION = 'REGENERATION'
export const MARK_AS_SENT_BULK_ACTION = 'MARK_AS_SENT'
export const SEND_INVOICES_BULK_ACTION = 'SEND_INVOICES'
export const SEND_UNPAID_INVOICES_BULK_ACTION = 'SEND_UNPAID_INVOICES'

export const INVOICE_BULK_ACTION_TO_DIALOG_CONTENT_MAP = {
  [REGENERATION_BULK_ACTION]: {
    singleTitle: T.CONFIRM_REGENERATION_INDIVIDUAL_ACTION_TITLE,
    singleDescription: T.CONFIRM_REGENERATION_INDIVIDUAL_ACTION_DESCRIPTION,
    bulkTitle: T.CONFIRM_REGENERATION_BULK_ACTION_TITLE,
    bulkDescription: T.CONFIRM_REGENERATION_BULK_ACTION_DESCRIPTION,
  },
  [MARK_AS_SENT_BULK_ACTION]: {
    singleTitle: T.CONFIRM_MARK_AS_SENT_INDIVIDUAL_ACTION_TITLE,
    singleDescription: T.CONFIRM_MARK_AS_SENT_INDIVIDUAL_ACTION_DESCRIPTION,
    bulkTitle: T.CONFIRM_MARK_AS_SENT_BULK_ACTION_TITLE,
    bulkDescription: T.CONFIRM_MARK_AS_SENT_BULK_ACTION_DESCRIPTION,
  },
  [SEND_INVOICES_BULK_ACTION]: {
    singleTitle: T.CONFIRM_SEND_INVOICES_INDIVIDUAL_ACTION_TITLE,
    singleDescription: T.CONFIRM_SEND_INVOICES_INDIVIDUAL_ACTION_DESCRIPTION,
    bulkTitle: T.CONFIRM_SEND_INVOICES_BULK_ACTION_TITLE,
    bulkDescription: T.CONFIRM_SEND_INVOICES_BULK_ACTION_DESCRIPTION,
  },
  [SEND_UNPAID_INVOICES_BULK_ACTION]: {
    singleTitle: T.CONFIRM_SEND_UNPAID_INVOICES_INDIVIDUAL_ACTION_TITLE,
    singleDescription: T.CONFIRM_SEND_UNPAID_INVOICES_INDIVIDUAL_ACTION_DESCRIPTION,
    bulkTitle: T.CONFIRM_SEND_UNPAID_INVOICES_BULK_ACTION_TITLE,
    bulkDescription: T.CONFIRM_SEND_UNPAID_INVOICES_BULK_ACTION_DESCRIPTION,
  },
}

export const getInvoiceBulkActionConfirmDialogContent = ({ action = REGENERATION_BULK_ACTION, invoiceCount = '' }) => {
  const isSingleInvoice = invoiceCount === 1
  const { singleTitle, singleDescription, bulkTitle, bulkDescription } = INVOICE_BULK_ACTION_TO_DIALOG_CONTENT_MAP[action] || {}

  let title
  let description

  if (isSingleInvoice) {
    title = singleTitle
    description = singleDescription
  } else {
    title = bulkTitle
    description = invoiceCount ? `${invoiceCount} ${bulkDescription}` : capitalize(bulkDescription)
  }

  return { title, description }
}
