import React from 'react'
import PropTypes from 'prop-types'

import { Avatar } from '@mui/material'
import { blue, amber } from '@mui/material/colors'
import { ReactComponent as CalendarDayIcon } from 'assets/CalendarDay.svg'
import { ReactComponent as CalendarRecurringIcon } from 'assets/CalendarRecurring.svg'

import { RECURRENCE } from 'settings/constants/service'

const { RECURRING } = RECURRENCE

const ServiceTypeAvatar = ({ serviceType = '' }) => {
  const isRecurringService = serviceType.toUpperCase() === RECURRING.toUpperCase()

  return (
    <Avatar sx={{ width: 24, height: 24, bgcolor: isRecurringService ? blue[500] : amber[500] }}>
      {isRecurringService ? <CalendarRecurringIcon width={20} height={20} /> : <CalendarDayIcon width={20} height={20} />}
    </Avatar>
  )
}

ServiceTypeAvatar.propTypes = {
  serviceType: PropTypes.string,
}

export default ServiceTypeAvatar
