import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Accordion, AccordionSummary, Box, LinearProgress, Skeleton, Typography, IconButton, Grid, Stack } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { getPaymentMethod, getPaymentMethodImage } from 'utils/payment'
import { formatDateToFEFormat } from 'utils/date'
import { PAYMENT_STATUS_COLORS } from 'components/common/chip/settings'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { PAYMENT_METHODS, PAYMENT_METHOD_IMAGES } from 'settings/constants/payment'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PaymentStatusChip from 'components/common/chip/PaymentStatusChip'
import PaymentActivityFeed from 'components/billing/common/PaymentActivityFeed'
import { PAYMENT_ACTIVITY_TABS_ID } from './settings'

const { CHECK, OTHER, ACCOUNT_CREDIT, ACH, CREDIT_CARD } = PAYMENT_METHODS
const { INVOICE_TAB_ID } = PAYMENT_ACTIVITY_TABS_ID

const PaymentAccordion = ({
  isViewOnly = false,
  isLoading = false,
  billingProfileDetails = {},
  accountNumber = '',
  payment = {},
  emailEvents = [],
  hasActivity = false,
  onPaymentNoteClick,
  onActionClick,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedSubTab, setSelectedSubTab] = useState(INVOICE_TAB_ID)
  const {
    paymentNumber,
    paymentMethod,
    checkNum = '',
    transactionAmountCents,
    transactionPostedDate,
    paymentDate,
    paymentNote,
    transactionStatus,
    creditCardType,
    accountLastFour,
    accountCredit,
    invoices,
    batchId = '',
  } = payment
  const isCheckOther = [CHECK, OTHER].includes(paymentMethod)
  const isAccountCredit = paymentMethod === ACCOUNT_CREDIT
  const capitalizeTransactionStatus = capitalize(transactionStatus)
  const paymentMethodName = paymentMethod === ACH ? paymentMethod : capitalize(getPaymentMethod(paymentMethod))
  const paymentMethodImage = paymentMethod === CREDIT_CARD ? getPaymentMethodImage(creditCardType) : PAYMENT_METHOD_IMAGES[paymentMethod]

  const paymentMethodSecondaryText = useMemo(() => {
    let text = ''
    if (isCheckOther) {
      text = checkNum
    }
    if (isAccountCredit) {
      text = accountNumber
    }
    if (!batchId) {
      return text
    }

    return text ? `${batchId} | ${text}` : batchId
  }, [isCheckOther, isAccountCredit, checkNum, accountNumber, batchId])

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const { onlyDate: transactionPostedDateFormatted } = convertDateTimeToPreferedTimezone({ dateTime: transactionPostedDate })

  const handleTabChange = (event, newValue) => setSelectedSubTab(newValue)

  useEffect(() => {
    if (isLoading) {
      setIsExpanded(false)
      setSelectedSubTab(INVOICE_TAB_ID)
    }
  }, [isLoading])

  return (
    <Accordion
      disableGutters
      sx={{ boxShadow: 'none', '&.Mui-expanded:before': { opacity: '1' }, '&.Mui-expanded': { margin: 'auto' } }}
      expanded={isExpanded}
    >
      <AccordionSummary onClick={onClick} sx={{ '&.MuiButtonBase-root:hover': { cursor: paymentNumber ? 'pointer' : 'default' } }}>
        <Box width="100%">
          {isLoading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />}
          <Grid container alignItems="center">
            <Grid item xs="auto" minWidth={130}>
              {isLoading ? <Skeleton width="5rem" /> : <PaymentStatusChip status={capitalizeTransactionStatus} />}
            </Grid>
            <Grid item xs="auto">
              <Box display="flex" alignItems="center">
                {!isLoading && <Box component="img" mr={1} src={paymentMethodImage} alt="" />}
                <Stack>
                  <Typography display="inline" variant="h5" fontWeight={400}>
                    {isLoading && <Skeleton width="15rem" />}
                    {!isLoading && creditCardType && accountLastFour ? `${creditCardType} •••• ${accountLastFour}` : paymentMethodName}
                  </Typography>

                  {paymentMethodSecondaryText && (
                    <Typography variant="caption">{isLoading ? <Skeleton width="5rem" /> : paymentMethodSecondaryText}</Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs>
              <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h3" fontWeight={700}>
                {isLoading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={transactionAmountCents} />}
                {isViewOnly ? (
                  <Box width={34} height={34} />
                ) : (
                  <IconButton size="small" onClick={onActionClick}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            {transactionStatus && (
              <Grid item sx>
                <Typography color={PAYMENT_STATUS_COLORS[capitalizeTransactionStatus][600]} variant="caption" fontWeight={700}>
                  {`${T.POSTED}: `}
                  {isLoading ? <Skeleton width="5rem" /> : transformDate(paymentDate)}
                </Typography>
              </Grid>
            )}

            <Grid item sx>
              <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
                {`${capitalize(T.TRANSACTION_DATE)}: `}
                {isLoading ? <Skeleton width="5rem" /> : transactionPostedDateFormatted}
                <span onClick={event => event.stopPropagation()}>
                  <IconButton
                    size="small"
                    disabled={!hasActivity && !emailEvents.length}
                    onClick={event => {
                      event.stopPropagation()
                      setIsExpanded(expanded => !expanded)
                    }}
                  >
                    {isExpanded ? <ExpandMore /> : <ChevronRight />}
                  </IconButton>
                </span>
              </Typography>
            </Grid>
          </Grid>

          {paymentNote && (
            <Grid container justifyContent="flex-end" pt={1} sx={{ borderTop: '1px dashed rgba(0, 0, 0, 0.12)' }}>
              <Grid item xs={12} display="grid">
                <Typography
                  mr="15px"
                  sx={{ cursor: 'pointer' }}
                  onClick={event => {
                    event.stopPropagation()
                    onPaymentNoteClick(paymentNote)
                  }}
                  textAlign="right"
                  variant="h6"
                  fontWeight={400}
                  noWrap
                >
                  {paymentNote}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </AccordionSummary>

      <PaymentActivityFeed
        invoices={invoices}
        accountCredit={accountCredit}
        transactionStatus={capitalizeTransactionStatus}
        billingProfileDetails={billingProfileDetails}
        emailEvents={emailEvents}
        selectedTab={selectedSubTab}
        onSelectedTabChange={handleTabChange}
      />
    </Accordion>
  )
}

PaymentAccordion.propTypes = {
  isViewOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  billingProfileDetails: PropTypes.object,
  accountNumber: PropTypes.string,
  payment: PropTypes.object,
  emailEvents: PropTypes.array,
  hasActivity: PropTypes.bool,
  onPaymentNoteClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PaymentAccordion
