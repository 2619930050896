import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Controller } from 'react-hook-form'
import Switch from 'react-switch'
import WithSwitchLabel from './WithSwitchLabel'
import { SUCCESS, TEXT } from '../../../theme/colors'

const HHFormSwitchField = ({ name, rules, label, control, onChange = noop, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const { onBlur, onChange: onChangeHookForm, ..._rest } = field
        return (
          <WithSwitchLabel label={label}>
            <Switch
              offColor={TEXT.secondary}
              onColor={SUCCESS.main}
              width={32}
              height={16}
              handleDiameter={10}
              checked={field?.value || false}
              onChange={value => {
                onChangeHookForm(value)
                onChange(value)
                onBlur()
              }}
              {..._rest}
              {...rest}
            />
          </WithSwitchLabel>
        )
      }}
    />
  )
}

HHFormSwitchField.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.object,
  onChange: PropTypes.func,
}

export default HHFormSwitchField
