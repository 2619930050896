import allRoutes from 'router/routes'

import T from 'T'
import Constants from 'Constants'

import {
  canSeeCustomersTab,
  canSeeRouteTab,
  canSeeBillingTab,
  canSeeReportTab,
  canSeePricingTab,
} from 'data/permissions/permissionsSelectors'

const { CAN_SEE_REPORTS } = Constants
const { ROUTE, BILLING, REPORTS, PRICING, CUSTOMERS } = T

const { app } = allRoutes
const {
  routes,
  billing,
  reports,
  pricing,
  customers,
  accounts,
  locations,
  accountGroups,
  suspensionGroups,
  customerDetails,
  recentlyAdded,
  recentlyViewed,
  workOrderDetails,
  createInvoices,
  payments,
  invoices,
  accountsReceivable,
  invoiceGroups,
  paymentGroups,
  pricingTaxes,
  pricingFees,
  pricingServices,
  pricingServiceGroups,
  pricingLateFees,
  pricingLateFeeDetails,
} = app

// title -> actual label
// route -> component path
// icon -> display icon

export const getAllMenuItems = flags => {
  return [
    {
      title: CUSTOMERS,
      route: customers,
      icon: 'Customers.svg',
      childList: [accounts, locations, accountGroups, customerDetails, workOrderDetails, suspensionGroups, recentlyAdded, recentlyViewed],
      permission: canSeeCustomersTab(),
      isFeatureAvailable: true,
    },
    {
      title: `${ROUTE}s`,
      route: routes,
      icon: 'Route.svg',
      childList: [routes],
      permission: canSeeRouteTab(),
      isFeatureAvailable: true,
    },
    {
      title: BILLING,
      route: createInvoices,
      icon: 'Billing.svg',
      childList: [billing, payments, createInvoices, invoices, accountsReceivable, invoiceGroups, paymentGroups],
      permission: canSeeBillingTab(),
      isFeatureAvailable: true,
    },
    {
      title: REPORTS,
      route: reports,
      icon: 'Reports.svg',
      childList: [reports],
      permission: canSeeReportTab(),
      isFeatureAvailable: CAN_SEE_REPORTS,
    },
    {
      title: PRICING,
      route: pricing,
      icon: 'Pricing.svg',
      childList: [pricing, pricingFees, pricingServices, pricingTaxes, pricingServiceGroups, pricingLateFees, pricingLateFeeDetails],
      permission: canSeePricingTab(),
      isFeatureAvailable: true,
    },
  ]
}
