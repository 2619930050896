import React from 'react'
import PropTypes from 'prop-types'
import { VariableSizeList } from 'react-window'

export const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}
const ListboxComponent = ({ ref, renderRow, rowHeight, maxTotalHeight, itemSize, ...rest }) => {
  const { children, ...other } = rest
  const itemData = []
  children.forEach(item => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const defaultItemSize = () => rowHeight

  const itemCount = itemData.length
  const totalHeight = itemCount * rowHeight

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={totalHeight < maxTotalHeight ? totalHeight : maxTotalHeight}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize || defaultItemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
}

ListboxComponent.propTypes = {
  renderRow: PropTypes.func.isRequired,
  itemSize: PropTypes.func,
  rowHeight: PropTypes.number.isRequired,
  maxTotalHeight: PropTypes.number.isRequired,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default ListboxComponent
