import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Popover,
  Popper,
  TextField,
  ListItemButton,
  MenuItem,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop, orderBy, lowerCase } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { shallowEqual, useSelector } from 'react-redux'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { getMetadataBusinessLines } from 'data/route/routeMetadataSelector'
import { getRoutesByBusinessLine } from 'data/route/routesByGroup'
import { calculateSum } from 'utils/price'
import zIndex from '@mui/material/styles/zIndex'
import RenderBusinessLineFilterOption from './RenderBusinessLineFilterOption'
import RouteFilter from '../route-filter/RouteFilter'
import { generateFilterLabel } from '../settings'

const WIDTH = 300

const CustomPopper = props => <Popper {...props} placement="bottom-start" sx={{ '&.base-Popper-root': { zIndex: zIndex.modal + 2 } }} />

const BusinessLineFilterMenu = ({ value, onChange = noop, RenderOption = RenderBusinessLineFilterOption, ...rest }) => {
  const inputRef = useRef(null)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const open = Boolean(popoverAnchorEl)
  const [filterMenu, setFiltermenu] = useState({ anchorEl: null, position: null, filteredRoutes: [] })
  const { anchorEl, position, filteredRoutes } = filterMenu
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const allRoutes = useSelector(state => get(state, 'Route.allRoutes', []), shallowEqual)
  const routesByBusinessLine = getRoutesByBusinessLine({ routes: allRoutes })
  const metaBusinessLines = useSelector(getMetadataBusinessLines, shallowEqual)
  const metaBusinessLinesWithRoutes = useMemo(
    () =>
      metaBusinessLines.map(businessLine => {
        const linkedRoutes = get(routesByBusinessLine, businessLine.id, [])
        return { ...businessLine, allRoutes: linkedRoutes, stopCount: calculateSum(linkedRoutes, 'stopCount') }
      }),
    [metaBusinessLines, routesByBusinessLine]
  )
  const businessLines = useMemo(
    () => orderBy(metaBusinessLinesWithRoutes, [row => row?.stopCount > 0, row => lowerCase(row?.businessLineName)], ['desc', 'asc']),
    [metaBusinessLinesWithRoutes]
  )
  const businessLineIdList = useMemo(() => businessLines.filter(({ id }) => id).map(({ id }) => id), [businessLines])
  const isControlled = value !== undefined
  const [internalSelectedBusinessLines, setInternalSelectedBusinessLines] = useState([])
  const selectedBusinessLines = isControlled ? value : internalSelectedBusinessLines

  const allBusinessLinesSelected = useMemo(
    () => selectedBusinessLines.length === businessLineIdList.length,
    [selectedBusinessLines, businessLineIdList]
  )

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleOpen = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(null)
  }

  const getOptionLabel = option => get(option, 'businessLineName')

  const toggleAllBusinessLineSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelectedBusinessLines = allBusinessLinesSelected ? [] : [...businessLines]
      if (isControlled) {
        onChange(newSelectedBusinessLines)
      } else {
        setInternalSelectedBusinessLines(newSelectedBusinessLines)
        onChange(newSelectedBusinessLines)
      }
    },
    [isControlled, allBusinessLinesSelected, businessLines, selectedRouteIds]
  )

  const handleChange = (event, newSelectedBusinessLines) => {
    event.stopPropagation()
    if (isControlled) {
      onChange(newSelectedBusinessLines)
    } else {
      setInternalSelectedBusinessLines(newSelectedBusinessLines)
      onChange(newSelectedBusinessLines)
    }
  }

  const handleClosFilterMenu = useCallback(() => {
    setFiltermenu({ anchorEl: null, position: null, filteredRoutes: [] })
  }, [])

  const handleMouseLeave = event => {
    event.stopPropagation()
    const relatedTarget = event?.relatedTarget
    if (relatedTarget?.classList?.[0] === 'MuiBackdrop-root') {
      handleClosFilterMenu()
    }
  }

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton
            disableGutters
            divider
            selected={allBusinessLinesSelected}
            onMouseDown={toggleAllBusinessLineSelection}
            onMouseOver={handleClosFilterMenu}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allBusinessLinesSelected} />
            </ListItemIcon>
            <ListItemText primary={allBusinessLinesSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allBusinessLinesSelected, toggleAllBusinessLineSelection, handleClosFilterMenu]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  const handleOpenFilterMenu = (event, routeList) => {
    const target = event.currentTarget
    if (anchorEl !== target) {
      const popoverTop = target?.getBoundingClientRect().top || 0
      const popoverLeft = target?.getBoundingClientRect().left || 0
      setFiltermenu({ anchorEl: target, position: { top: popoverTop, left: popoverLeft }, filteredRoutes: routeList })
    }
  }

  const dropdownHeight = get(rest, 'dropdownHeight', 'calc(100vh - 272px)')
  const maxHeight = get(rest, 'autoHeight') ? null : { maxHeight: dropdownHeight }

  const buttonLabel = useMemo(
    () => generateFilterLabel(businessLineIdList, selectedBusinessLines, 'business line'),
    [businessLineIdList, selectedBusinessLines]
  )

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedBusinessLines.length} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 120 }}>{buttonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="business-line-filter-menu-popover"
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        transitionDuration={{ appear: 0, exit: 0 }}
        TransitionProps={{ onEntered: focusAutocomplete }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
        onMouseLeave={handleMouseLeave}
      >
        <ClickAwayListener onClickAway={handleClosFilterMenu}>
          <Autocomplete
            multiple
            options={businessLines}
            sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
            fullWidth
            componentsProps={{
              paper: { allBusinessLinesSelected, toggleAllBusinessLineSelection },
              popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
            }}
            ListboxProps={{
              sx: { ...maxHeight, py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
            }}
            open={open}
            disableClearable
            onChange={handleChange}
            PopperComponent={CustomPopper}
            PaperComponent={SelectAllPaperComponent}
            renderTags={() => <></>}
            value={selectedBusinessLines}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(props, option, { selected }) => (
              <RenderOption props={props} option={option} selected={selected} onOpenFilterMenu={handleOpenFilterMenu} />
            )}
            renderInput={params => (
              <TextField {...params} placeholder="Type to filter business lines" inputRef={inputRef} variant="standard" />
            )}
            {...rest}
          />
        </ClickAwayListener>
      </Popover>

      {position && filteredRoutes.length > 0 && (
        <RouteFilter type="method" anchorEl={position} filteredRoutes={filteredRoutes} onMouseLeave={handleMouseLeave} />
      )}
    </>
  )
}

BusinessLineFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  RenderOption: PropTypes.func,
}

export default BusinessLineFilterMenu
