import React, { Children } from 'react'

import { TableRow, TableHead as THead, TableCell } from '@mui/material'

import { memo } from 'utils/react'

import { FEES_ADJUSTMENT_COLUMNS } from './settings'

const FeeAdjustmentTableHeader = () => (
  <THead className="table-header">
    <TableRow>
      {Children.toArray(
        FEES_ADJUSTMENT_COLUMNS.map(column => (
          <TableCell className={column.className} align={column.align} size={column.size}>
            <div className="inline-flex items-center">
              <span>{column.label}</span>
            </div>
          </TableCell>
        ))
      )}
    </TableRow>
  </THead>
)

export default memo(FeeAdjustmentTableHeader)
