import T from 'T'

const {
  CUSTOMER,
  BALANCE,
  LAST_PAYMENT_DATE,
  PRICING_ZONE,
  LATE_FEES,
  PAYMENT_TYPE,
  PAYMENT_TERMS,
  CUSTOMER_ADDRESS,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP,
  LAST_PAYMENT_AMOUNT,
  BILLING_TYPE,
  BILLING_CYCLE,
  BILLING_TIMING,
  BUSINESS_LINE,
  INVOICES_NUMBER,
  ACCOUNT,
  CUSTOMER_BILLING_PROFILE,
  TAGS,
  CONTACT_NAME,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  AUTOPAY,
  AUTOPAY_PAYMENT_CARD,
  AUTOPAY_PAYMENT_METHOD,
  AUTOPAY_EXPIRY,
} = T

export const ACCOUNTS_RECEIVABLE_CONFIGURATOR = [
  {
    position: 0,
    tableLabel: `${ACCOUNT} #`,
    label: `${ACCOUNT} #`,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'accountNumber',
    className: 'font-500',
  },
  {
    position: 1,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    checked: true,
    locked: false,
    className: 'font-500',
    filterType: 'string',
    columnName: 'customerName',
  },
  {
    position: 2,
    tableLabel: INVOICES_NUMBER,
    label: INVOICES_NUMBER,
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'invoiceCount',
  },
  {
    position: 5,
    tableLabel: BALANCE,
    label: BALANCE,
    checked: true,
    locked: false,
    align: 'right',
    className: 'font-500 tr',
    filterType: 'number',
    columnName: 'balanceCents',
    subType: 'money',
  },
  {
    position: 6,
    tableLabel: 'Unbilled',
    label: 'Unbilled',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'unBilledCents',
    subType: 'money',
  },
  {
    position: 7,
    tableLabel: 'Current',
    label: 'Current',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'currentCents',
    subType: 'money',
  },
  {
    position: 8,
    tableLabel: '1-30',
    label: '1-30',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'dueOneThirtyCents',
    subType: 'money',
  },
  {
    position: 9,
    tableLabel: '31-60',
    label: '31-60',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'dueThirtyOneSixtyCents',
    subType: 'money',
  },
  {
    position: 10,
    tableLabel: '61-90',
    label: '61-90',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'dueSixtyOneNinetyCents',
    subType: 'money',
  },
  {
    position: 11,
    tableLabel: 'Over 90',
    label: 'Over 90',
    checked: true,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'dueOverNinetyCents',
    subType: 'money',
  },
  {
    position: 12,
    label: CUSTOMER_ADDRESS,
    tableLabel: CUSTOMER_ADDRESS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'line1',
  },
  {
    position: 13,
    label: CUSTOMER_CITY,
    tableLabel: CUSTOMER_CITY,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'city',
  },
  {
    position: 14,
    label: CUSTOMER_STATE,
    tableLabel: CUSTOMER_STATE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'state',
  },
  {
    position: 15,
    label: CUSTOMER_ZIP,
    tableLabel: CUSTOMER_ZIP,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'zipCode',
  },
  {
    position: 16,
    label: LAST_PAYMENT_DATE,
    tableLabel: LAST_PAYMENT_DATE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'date',
    columnName: 'transactionDate',
    subType: 'date',
  },
  {
    position: 17,
    label: LAST_PAYMENT_AMOUNT,
    tableLabel: LAST_PAYMENT_AMOUNT,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'transactionAmount',
    subType: 'price',
    hideFilter: true,
    hideSort: true,
  },
  {
    position: 18,
    tableLabel: BILLING_TYPE,
    label: BILLING_TYPE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'paymentType',
  },
  {
    position: 19,
    tableLabel: BILLING_CYCLE,
    label: BILLING_CYCLE,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'billingCycleText',
    isSingleSelect: true,
  },
  {
    position: 20,
    tableLabel: BILLING_TIMING,
    label: BILLING_TIMING,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'timing',
  },
  {
    position: 21,
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'businessLinesCount',
  },
  {
    position: 22,
    tableLabel: PRICING_ZONE,
    label: PRICING_ZONE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'pricingZones',
    subType: 'pricingZones',
  },
  {
    position: 23,
    tableLabel: PAYMENT_TYPE,
    label: PAYMENT_TYPE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'paymentMethod',
    subType: 'paymentMethod',
  },
  {
    position: 24,
    tableLabel: PAYMENT_TERMS,
    label: PAYMENT_TERMS,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'paymentTermsText',
    isSingleSelect: true,
  },
  {
    position: 25,
    tableLabel: LATE_FEES,
    label: LATE_FEES,
    checked: false,
    locked: false,
    align: 'right',
    className: 'tr',
    filterType: 'number',
    columnName: 'lateFeeAmount',
    subType: 'price',
  },
  {
    position: 26,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    checked: false,
    locked: false,
    filterType: 'meta',
    columnName: 'customerProfileName',
  },
  {
    position: 27,
    tableLabel: TAGS,
    label: TAGS,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'tags',
    className: 'tags',
    subType: 'tags',
    hideSort: true,
  },
  {
    position: 28,
    tableLabel: CONTACT_NAME,
    label: CONTACT_NAME,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'contactName',
  },
  {
    position: 29,
    tableLabel: CONTACT_PHONE,
    label: CONTACT_PHONE,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'contactNumber',
  },
  {
    position: 30,
    tableLabel: CONTACT_EMAIL,
    label: CONTACT_EMAIL,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'email',
  },
  {
    position: 31,
    tableLabel: AUTOPAY,
    label: AUTOPAY,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'autoPay',
    subType: 'autoPay',
    isSingleSelect: true,
    isBoolean: true,
  },
  {
    position: 32,
    tableLabel: AUTOPAY_PAYMENT_METHOD,
    label: AUTOPAY_PAYMENT_METHOD,
    checked: false,
    locked: false,
    filterType: 'checkbox',
    columnName: 'autoPaymentType',
    subType: 'autoPaymentType',
  },
  {
    position: 33,
    tableLabel: AUTOPAY_PAYMENT_CARD,
    label: AUTOPAY_PAYMENT_CARD,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'autoPaymentMethod',
  },
  {
    position: 34,
    tableLabel: AUTOPAY_EXPIRY,
    label: AUTOPAY_EXPIRY,
    checked: false,
    locked: false,
    filterType: 'string',
    columnName: 'autoPaymentExpiry',
  },
]
