import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getPostCardDetails: build.query({
      query: body => ({
        url: '/api/settings/billing/postcard/details',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useLazyGetPostCardDetailsQuery } = extendedApi