import { Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const HighlightPrimaryTag = ({ children }) => (
  <Typography component="span" color="primary">
    {children}
  </Typography>
)

HighlightPrimaryTag.propTypes = {
  children: PropTypes.node,
}
export default HighlightPrimaryTag
