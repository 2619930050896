import { transformSuggestResponse, transformSearchResponse } from 'utils/accountSearch'
import api from '../index'
import { CACHE_TAG_ACCOUNT_LIST } from '../cacheTagTypes'

const DEFAULT_CACHE_TIME = 3600

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAccountsBySuggest: builder.query({
      query: ({ search = '', includeTags = false, includeContacts = true, includeDeactivated = true }) => ({
        url: '/api/v1/core/accounts/search/suggest',
        method: 'GET',
        params: { search, includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: transformSuggestResponse,
    }),
    getAccountsBySearch: builder.query({
      query: ({ search = '', includeTags = true, includeContacts = false, includeDeactivated = true }) => ({
        url: '/api/v1/core/accounts/search',
        method: 'GET',
        params: { search, includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: transformSearchResponse,
      providesTags: [CACHE_TAG_ACCOUNT_LIST],
      keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),

    getAccountsByFocusedSuggest: builder.query({
      query: ({ search = '', type, includeTags = false, includeContacts = true, includeDeactivated = true }) => ({
        url: '/api/v1/core/accounts/search/focused/suggest',
        method: 'GET',
        params: { search, type, includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: transformSuggestResponse,
    }),
    getAccountsByFocusedSearch: builder.query({
      query: ({ search = '', type, includeTags = true, includeContacts = false, includeDeactivated = true }) => ({
        url: '/api/v1/core/accounts/search/focused',
        method: 'GET',
        params: { search, type, includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: transformSearchResponse,
      providesTags: [CACHE_TAG_ACCOUNT_LIST],
      keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
    getAccountsByRecentSearch: builder.query({
      query: ({ type, includeTags = true, includeContacts = false, includeDeactivated = true }) => ({
        url: '/api/v1/core/accounts/search/recent',
        method: 'GET',
        params: { type, includeTags, includeContacts, includeDeactivated },
      }),
      transformResponse: transformSearchResponse,
      providesTags: [CACHE_TAG_ACCOUNT_LIST],
      keepUnusedDataFor: DEFAULT_CACHE_TIME,
    }),
  }),
})

export const {
  useLazyGetAccountsBySuggestQuery,
  useLazyGetAccountsBySearchQuery,
  useLazyGetAccountsByFocusedSuggestQuery,
  useLazyGetAccountsByFocusedSearchQuery,
  useLazyGetAccountsByRecentSearchQuery,
} = extendedApi
