import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box, Typography, SvgIcon, IconButton, CircularProgress, Tooltip } from '@mui/material'
import { ReactComponent as PaperPlane } from 'assets/PaperPlane.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { getInvoicesByStatus } from 'data/invoice/invoiceSelectors'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import RenderAccountNameAndBalance from '../content/RenderAccountNameAndBalance'
import RenderSecondaryText from '../content/RenderSecondaryText'

const { UNPAID } = INVOICE_STATUS

const RenderAccountCell = ({ row = {}, onResend }) => {
  const { watch, setValue } = useFormContext()
  const [getAccountInvoices, { isFetching: isGetAccountInvoicesLoading }] = useLazyGetAccountInvoicesQuery()
  const { accountId, accountName, accountNumber, unpaidInvoiceCount = 0 } = row

  const clickedAccountId = watch('clickedAccountId')

  const handleResend = async event => {
    event.stopPropagation()
    setValue('clickedAccountId', accountId)
    const result = await getAccountInvoices({ accountId }, true).unwrap().catch(handleError)
    const invoicesByStatus = getInvoicesByStatus({ invoices: get(result, 'invoices', []) })
    const unpaidInvoices = get(invoicesByStatus, UNPAID, []).map(({ invoiceNumber }) => invoiceNumber)
    onResend(unpaidInvoices)
  }

  return (
    <Box py={0.5} width="100%">
      <RenderAccountNameAndBalance row={row} />
      <RenderSecondaryText
        row={row}
        rightSecondaryText={
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle2">{`${unpaidInvoiceCount} invoice${unpaidInvoiceCount > 1 ? 's' : ''}`}</Typography>
            {isGetAccountInvoicesLoading || clickedAccountId === accountId ? (
              <CircularProgress size={30} />
            ) : (
              <Tooltip placement="right" title={`Resend all unpaid invoices for ${accountName} - ${accountNumber}`}>
                <IconButton size="small" onClick={handleResend}>
                  <SvgIcon fontSize="small">
                    <PaperPlane />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
          </Box>
        }
      />
    </Box>
  )
}

RenderAccountCell.propTypes = {
  row: PropTypes.object,
  onResend: PropTypes.func.isRequired,
}

export default RenderAccountCell
