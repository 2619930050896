import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getPermitList: build.query({
      query: () => '/api/v1/core/settings/permit/',
    }),
  }),
})

export const { useGetPermitListQuery, useLazyGetPermitListQuery } = extendedApi
