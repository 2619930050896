export const getMobileVisibilityDescriptionAndTitle = (visibleOnMobile, updateInherited) => {
  if (updateInherited) {
    return {
      title: `Confirm ${visibleOnMobile ? 'show' : 'hide'} for all work orders`,
      description: `Are you sure you want to ${
        visibleOnMobile ? 'show' : 'hide'
      } this service note for all work orders and future generated work orders on mobile?`,
    }
  }

  return {
    title: `Confirm ${visibleOnMobile ? 'show' : 'hide'} service note`,
    description: `Are you sure you want to ${visibleOnMobile ? 'show' : 'hide'} service note on mobile? `,
  }
}

export const getArchiveDescriptionAndTitle = (updateServiceNote, updateInherited) => {
  if (updateServiceNote && updateInherited) {
    return {
      title: 'Archive service & work order notes',
      description: 'Are you sure you want to archive this service note and its related work order notes?',
    }
  }
  if (updateServiceNote) {
    return {
      title: 'Archive service note',
      description: 'Are you sure you want to archive this service note?',
    }
  }
  return {
    title: 'Archive work order notes',
    description: 'Are you sure you want to archive the related work order notes?',
  }
}

export const getUnarchiveDescriptionAndTitle = (updateServiceNote, updateInherited) => {
  if (updateServiceNote && updateInherited) {
    return {
      title: 'Unarchive service & work order notes',
      description: 'Are you sure you want to unarchive this service note and its related work order notes?',
    }
  }
  if (updateServiceNote) {
    return {
      title: 'Unarchive service note',
      description: 'Are you sure you want to unarchive this service note?',
    }
  }
  return {
    title: 'Unarchive work order notes',
    description: 'Are you sure you want to unarchive the related work order notes?',
  }
}

export const getDeleteDescriptionAndTitle = (deleteServiceNote, deleteInherited) => {
  if (deleteServiceNote && deleteInherited) {
    return {
      title: 'Delete service & work order notes',
      description: 'Are you sure you want to delete this service note and its related work order notes?',
    }
  }
  if (deleteServiceNote) {
    return {
      title: 'Delete service note',
      description: 'Are you sure you want to delete this service note?',
    }
  }
  return {
    title: 'Delete work order notes',
    description: 'Are you sure you want to delete the related work order notes?',
  }
}
