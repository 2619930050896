import api from 'api'
import {
  CACHE_TAG_LOCATION_NOTES_LIST,
  CACHE_TAG_LOCATIONS_NOTES_COUNT,
  CACHE_TAG_ACCOUNT_LOCATION_LIST,
  CACHE_TAG_ACCOUNT_LOCATION_DETAILS,
} from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllLocationNotes: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/core/location/${id}/notes`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [CACHE_TAG_LOCATION_NOTES_LIST, { type: CACHE_TAG_LOCATION_NOTES_LIST, id }],
    }),
    getLocationNoteDetails: builder.query({
      query: ({ locationId, noteId }) => ({
        url: `/api/v1/core/location/${locationId}/notes/${noteId}`,
        method: 'GET',
      }),
    }),
    getLocationNoteHistory: builder.query({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/location/${id}/notes/${noteId}/history`,
        method: 'GET',
      }),
    }),
    createLocationNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/location/${id}/notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_LOCATION_NOTES_LIST, id },
        { type: CACHE_TAG_ACCOUNT_LOCATION_DETAILS, id },
        CACHE_TAG_LOCATIONS_NOTES_COUNT,
        CACHE_TAG_ACCOUNT_LOCATION_LIST,
      ],
    }),
    updateLocationNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/location/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_LOCATION_NOTES_LIST, id },
        { type: CACHE_TAG_ACCOUNT_LOCATION_DETAILS, id },
        CACHE_TAG_LOCATIONS_NOTES_COUNT,
        CACHE_TAG_ACCOUNT_LOCATION_LIST,
      ],
    }),
    deleteLocationNote: builder.mutation({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/location/${id}/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_LOCATION_NOTES_LIST, id },
        { type: CACHE_TAG_ACCOUNT_LOCATION_DETAILS, id },
        CACHE_TAG_LOCATIONS_NOTES_COUNT,
        CACHE_TAG_ACCOUNT_LOCATION_LIST,
      ],
    }),
    patchLocationNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/location/${id}/notes/${noteId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_LOCATION_NOTES_LIST, id },
        { type: CACHE_TAG_ACCOUNT_LOCATION_DETAILS, id },
        CACHE_TAG_LOCATIONS_NOTES_COUNT,
        CACHE_TAG_ACCOUNT_LOCATION_LIST,
      ],
    }),
  }),
})

export const {
  useLazyGetAllLocationNotesQuery,
  useLazyGetLocationNoteDetailsQuery,
  useLazyGetLocationNoteHistoryQuery,
  useCreateLocationNoteMutation,
  useUpdateLocationNoteMutation,
  useDeleteLocationNoteMutation,
  usePatchLocationNoteMutation,
} = extendedApi
