import orderBy from 'lodash/orderBy'
import { get } from 'lodash'
import { createSelector } from 'reselect'

export const sortByDateSelector = createSelector(
  [state => state?.data, state => state?.key, state => state?.order],
  (data = [], key = '', order = 'desc') => {
    if (!Array.isArray(data) || !key) {
      return []
    }

    return orderBy(data, [row => new Date(get(row, key))], [order])
  }
)
