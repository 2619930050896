import { useCallback, useEffect, useMemo, useState } from 'react'
import { get, keyBy, noop, upperCase } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { common } from '@mui/material/colors'
import { useTheme } from '@mui/material'
import DriverMarker from 'assets/map/DriverMarker.png'
import CanceledMarker from 'assets/map/CanceledMarker.png'
import HoldMarker from 'assets/map/HoldMarker.png'
import InProgressMarker from 'assets/map/InProgressMarker.png'
import ScheduledMarker from 'assets/map/ScheduledMarker.png'
import ServicedMarker from 'assets/map/ServicedMarker.png'
import SkippedMarker from 'assets/map/SkippedMarker.png'
import ToBeInsertedMarker from 'assets/map/ToBeInsertedMarker.png'
import MonochromeMarker from 'assets/map/MonochromeMarker.png'
import SideLoadMarker from 'assets/map/SideLoadMarker.png'
import FrontMarker from 'assets/map/FrontMarker.png'
import RearMarker from 'assets/map/RearMarker.png'
import DumpTruckMarker from 'assets/map/DumpTruckMarker.png'
import HookMarker from 'assets/map/HookMarker.png'
import HoistMarker from 'assets/map/HoistMarker.png'
import GrappleMarker from 'assets/map/GrappleMarker.png'
import ArrowTip from 'assets/map/ArrowTip.png'
import {
  addAssetsImageToMap,
  addPositionLayers,
  addSourceAsync,
  BOTTOM_LAYER,
  flyToMap,
  getBounds,
  MIDDLE_LAYER,
  removeLayerIfExist,
  removeSourceIfExist,
  TOP_LAYER,
} from 'utils/map'
import {
  ROUTE_COLOR_STOPS_DOT_LAYER,
  ROUTE_COLOR_STOPS_LAYER,
  ROUTE_STOPS_UNASSIGNED,
  ROUTE_STOPS_UNASSIGNED_DOT_LAYER,
  ROUTE_STOPS_UNASSIGNED_LAYER,
  setIsBreadcrumbInitialized,
  setStopDetailsDialogState,
  setStopDetailsPopoverState,
  WO_COLOR_STOPS_DOT_LAYER,
  WO_COLOR_STOPS_LAYER,
  setResequenceHistory,
} from 'slices/route/routeSlice'
import { isValidHex } from 'utils/validations'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { METHOD_TO_MAP_ICON } from 'settings/constants/methods'
import { formatStopFeatures } from 'utils/route'
import { getViewMode } from 'data/route/viewModeSelector'
import { getIsUnassignedStopsOpen } from 'data/route/unassignedStopsSelector'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import {
  addRouteIdSuffix,
  BREADCRUMB_ARROW_TIP_LAYOUT,
  ROUTE_COLOR_STOPS_DOT_PAINT,
  ROUTE_COLOR_STOPS_LAYOUT,
  toggleRouteLayers,
  UNASSIGNED_ROUTE_STOPS_LAYOUT,
  UNASSIGNED_ROUTE_STOPS_PAINT,
  WO_COLOR_STOPS_DOT_PAINT,
  WO_COLOR_STOPS_LAYOUT,
  ZOOM_DOT_LAYER_PROPS,
  ZOOM_MARKER_LAYER_PROPS,
} from './settings'
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'

const { MAP_VIEW_URL, LIGHT_VIEW, PROJECTION } = MAP_DEFAULT_OPTIONS

export const useRouteManagerMap = ({ mapData, map, mapContainer }) => {
  const isStreetView = useSelector(s => get(s, 'Route.map.isStreetView', true), shallowEqual)
  const [geojsonDataByRouteId, setGeojsonDataByRouteId] = useState()
  const isUnassignedStopsActive = useSelector(getIsUnassignedStopsOpen)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const currentLayer = useSelector(s => get(s, 'Route.map.currentLayer', WO_COLOR_STOPS_LAYER), shallowEqual)
  const isBreadcrumbInitialized = useSelector(s => get(s, 'Route.isBreadcrumbInitialized'), shallowEqual)
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const selectedSearchResult = useSelector(s => s.Route.selectedSearchResult, shallowEqual)
  const viewOnMapRouteId = useSelector(s => get(s, 'Route.viewOnMap.routeId', null), shallowEqual)
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const selectedRouteIdList = useMemo(
    () => (viewOnMapRouteId ? [viewOnMapRouteId] : selectedRouteIds),
    [viewOnMapRouteId, selectedRouteIds]
  )
  const isSequenceLineActive = useSelector(state => state.Route.isSequenceLineActive)
  const allRouteIds = useSelector(s => get(s, 'Route.allRouteIds', []), shallowEqual)
  const breadcrumbsByRouteId = useSelector(s => get(s, 'Route.breadcrumbsByRouteId', {}), shallowEqual)
  const serializedServiceDate = useSelector(s => s.Route.filters.serializedServiceDate, shallowEqual)
  const serviceDate = useMemo(() => (serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()), [serializedServiceDate])
  const routes = useMemo(() => get(routeMetaData, 'routes', []), [routeMetaData])
  const dispatch = useDispatch()
  const theme = useTheme()
  const userInfo = useSelector(s => s.AuthReducer.userInfo)
  const routesById = useMemo(() => keyBy(routes, 'id'), [routes])
  const viewMode = useSelector(getViewMode)
  const resequenceHistory = useSelector(s => s.Route.resequenceHistory, shallowEqual)
  const { resequenceHistoryTrack, resequenceHistoryCoords } = useMemo(
    () => ({
      resequenceHistoryTrack: get(resequenceHistory, 'track', false),
      resequenceHistoryCoords: get(resequenceHistory, 'coord'),
    }),
    [resequenceHistory]
  )

  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  const handlePopoverOpen = useCallback(event => {
    const properties = get(event, 'features.0.properties', {})
    const id = get(properties, 'id')

    dispatch(setStopDetailsPopoverState({ position: event.originalEvent, id }))
  }, [])

  const handlePopoverClose = useCallback(() => {
    dispatch(setStopDetailsPopoverState({ isOpen: false, id: '', position: null, stop: {} }))
  }, [])

  const updateRouteLinesVisibility = useCallback(() => {
    const mapCurrentRef = map.current
    selectedRouteIdList.forEach(routeId => {
      if (mapCurrentRef.getLayer(addRouteIdSuffix(`route-stops-line-string`, routeId))) {
        mapCurrentRef.setLayoutProperty(
          addRouteIdSuffix(`route-stops-line-string`, routeId),
          'visibility',
          isSequenceLineActive ? 'visible' : 'none'
        )
      }
    })
  }, [map, selectedRouteIdList, isSequenceLineActive])

  const updateSingleRouteLineVisibility = useCallback(
    routeId => {
      const mapCurrentRef = map.current
      if (mapCurrentRef.getLayer(addRouteIdSuffix(`route-stops-line-string`, routeId))) {
        mapCurrentRef.setLayoutProperty(
          addRouteIdSuffix(`route-stops-line-string`, routeId),
          'visibility',
          isSequenceLineActive ? 'visible' : 'none'
        )
      }
    },
    [map, selectedRouteIdList, isSequenceLineActive]
  )

  const loadMapAssets = useCallback(() => {
    addAssetsImageToMap(map.current, 'canceled-marker', CanceledMarker)
    addAssetsImageToMap(map.current, 'driver-marker', DriverMarker)
    addAssetsImageToMap(map.current, 'hold-marker', HoldMarker)
    addAssetsImageToMap(map.current, 'in-progress-marker', InProgressMarker)
    addAssetsImageToMap(map.current, 'scheduled-marker', ScheduledMarker)
    addAssetsImageToMap(map.current, 'serviced-marker', ServicedMarker)
    addAssetsImageToMap(map.current, 'skipped-marker', SkippedMarker)
    addAssetsImageToMap(map.current, 'to-be-assigned-marker', ToBeInsertedMarker)
    addAssetsImageToMap(map.current, 'monochrome-marker', MonochromeMarker, true)
    addAssetsImageToMap(map.current, 'side-load-marker', SideLoadMarker)
    addAssetsImageToMap(map.current, 'front-load-marker', FrontMarker)
    addAssetsImageToMap(map.current, 'rear-load-marker', RearMarker)
    addAssetsImageToMap(map.current, 'dump-truck-marker', DumpTruckMarker)
    addAssetsImageToMap(map.current, 'hook-marker', HookMarker)
    addAssetsImageToMap(map.current, 'hoist-marker', HoistMarker)
    addAssetsImageToMap(map.current, 'grapple-marker', GrappleMarker)
    addAssetsImageToMap(map.current, 'arrow-tip', ArrowTip, true)
  }, [map])

  const unclusteredClickHandler = useCallback(
    e => {
      if (!map || !map.current) return null
      const { properties, geometry } = e.features[0]
      const id = get(properties, 'id')
      const [longitude, latitude] = get(geometry, 'coordinates', [])
      dispatch(setStopDetailsDialogState({ id }))
      dispatch(setResequenceHistory({ zoom: map.current.getZoom(), coord: [longitude, latitude] }))
      // center map
      flyToMap(map, longitude, latitude)
    },
    [map]
  )

  const changeCursorOnEnterMarker = useCallback(() => {
    const mapCurrentRef = map.current
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'pointer'
  }, [map])

  const changeCursorOnLeaveMarker = useCallback(() => {
    const mapCurrentRef = map.current
    // eslint-disable-next-line no-param-reassign
    mapCurrentRef.getCanvas().style.cursor = 'grab'
  }, [map])

  const onMissingStyleImage = useCallback(() => {
    setTimeout(loadMapAssets, 200)
  }, [loadMapAssets])

  const updateStopMarkers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded) return null
    loadMapAssets()
    const mapCurrentRef = map.current
    allRouteIds.forEach(routeId => {
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix('route-stops-line-string', routeId))
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix('route-stops', routeId))
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId))
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId))
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId))
      removeLayerIfExist(mapCurrentRef, addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId))
      removeSourceIfExist(mapCurrentRef, addRouteIdSuffix('route-stops', routeId))
      removeSourceIfExist(mapCurrentRef, addRouteIdSuffix('route-stops-line-string', routeId))
    })
    if (geojsonDataByRouteId && selectedRouteIdList.length > 0) {
      for (const routeId in geojsonDataByRouteId) {
        const route = get(geojsonDataByRouteId, routeId, {})
        const sequenceLine = get(route, 'sequenceLine')
        const stops = get(route, 'stops')
        addSourceAsync(mapCurrentRef, addRouteIdSuffix(`route-stops-line-string`, routeId), sequenceLine, {
          type: 'geojson',
        }).then(() => {
          const color = get(routesById, [routeId, 'color'], '#000000')
          const formattedColor = isValidHex(color) ? color : '#000000'
          mapCurrentRef.addLayer(
            {
              id: addRouteIdSuffix(`route-stops-line-string`, routeId),
              type: 'line',
              source: addRouteIdSuffix(`route-stops-line-string`, routeId),
              paint: {
                'line-color': formattedColor,
                'line-width': 1.5,
              },
            },
            BOTTOM_LAYER
          )
          updateSingleRouteLineVisibility(routeId)
        })
        addSourceAsync(
          mapCurrentRef,
          `route-stops-${routeId}`,
          { type: 'FeatureCollection', features: stops },
          {
            type: 'geojson',
          }
        )
          .then(() => {
            mapCurrentRef.addLayer(
              {
                id: addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId),
                type: 'circle',
                source: addRouteIdSuffix('route-stops', routeId),
                paint: WO_COLOR_STOPS_DOT_PAINT,
                ...ZOOM_DOT_LAYER_PROPS,
              },
              MIDDLE_LAYER
            )
            mapCurrentRef.addLayer(
              {
                id: addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId),
                type: 'symbol',
                source: addRouteIdSuffix('route-stops', routeId),
                layout: WO_COLOR_STOPS_LAYOUT,
                paint: {
                  'text-color': common.black,
                },
                ...ZOOM_MARKER_LAYER_PROPS,
              },
              MIDDLE_LAYER
            )
            mapCurrentRef.addLayer(
              {
                id: addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId),
                type: 'circle',
                source: addRouteIdSuffix('route-stops', routeId),
                paint: ROUTE_COLOR_STOPS_DOT_PAINT,
                ...ZOOM_DOT_LAYER_PROPS,
              },
              MIDDLE_LAYER
            )
            mapCurrentRef.addLayer(
              {
                id: addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId),
                type: 'symbol',
                source: addRouteIdSuffix('route-stops', routeId),
                layout: ROUTE_COLOR_STOPS_LAYOUT,
                paint: {
                  'icon-color': ['get', 'color'],
                  'text-color': ['get', 'route-text-color'],
                },
                ...ZOOM_MARKER_LAYER_PROPS,
              },
              MIDDLE_LAYER
            )

            toggleRouteLayers(mapCurrentRef, currentLayer, routeId)
            mapCurrentRef.off('click', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.off('click', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.off('click', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.off('click', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.on('click', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.on('click', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.on('click', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), unclusteredClickHandler)
            mapCurrentRef.on('click', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), unclusteredClickHandler)

            mapCurrentRef.off('styleimagemissing', onMissingStyleImage)
            mapCurrentRef.on('styleimagemissing', onMissingStyleImage)

            mapCurrentRef.off('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverClose)

            mapCurrentRef.off('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.off('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.off('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnLeaveMarker)

            mapCurrentRef.on('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnLeaveMarker)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnEnterMarker)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), changeCursorOnLeaveMarker)

            mapCurrentRef.on('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverClose)
            mapCurrentRef.on('mouseenter', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverOpen)
            mapCurrentRef.on('mouseleave', addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), handlePopoverClose)
          })
          .catch(error => {
            console.error('Error adding source for route stops:', error)
          })
      }
    }
  }, [map, geojsonDataByRouteId, isMapLoaded, currentLayer, loadMapAssets, selectedRouteIds])

  const getRouteCoordinates = routeId => {
    const mapCurrentRef = map.current
    const routeSource = mapCurrentRef.getSource(`route-${routeId}`)
    if (routeSource) {
      return get(routeSource, '_data.geometry.coordinates', [])
    }
    return []
  }

  const onDriverMarkerClick = useCallback(
    e => {
      const mapCurrentRef = map.current
      if (!map || !mapCurrentRef) return null
      const features = mapCurrentRef.queryRenderedFeatures(e.point, {
        layers: ['last-driver-positions'],
      })
      if (features.length > 0) {
        const clickedRouteId = features[0].properties['route-id']

        const routeCoordinates = getRouteCoordinates(clickedRouteId)
        if (routeCoordinates.length > 0) {
          const routeBounds = getBounds(routeCoordinates, 25)

          mapCurrentRef.fitBounds(routeBounds, {
            maxZoom: 20,
            linear: true,
          })
        }
      }
    },
    [map]
  )

  const updateBreadcrumAndDriverPositions = useCallback(() => {
    const mapCurrentRef = map.current
    Object.keys(breadcrumbsByRouteId).forEach(routeId => {
      const coordinates = breadcrumbsByRouteId[routeId]
      const color = get(routesById, [routeId, 'color'], '#000000')
      const formattedColor = isValidHex(color) ? color : '#000000'
      const data = {
        type: 'Feature',
        properties: {
          color: formattedColor,
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      }
      mapCurrentRef.getSource(`route-${routeId}`).setData(data)
    })

    const lastPositionsByRouteId = {}
    Object.keys(breadcrumbsByRouteId).forEach(routeId => {
      const coordinates = breadcrumbsByRouteId[routeId]
      const mostRecentCoordinate = coordinates[coordinates.length - 1]

      lastPositionsByRouteId[routeId] = mostRecentCoordinate
    })
    const lastDriverFeatures = Object.keys(lastPositionsByRouteId).map(routeId => {
      const position = get(lastPositionsByRouteId, routeId)
      const methodName = get(routesById, [routeId, 'methodName'])
      const icon = get(METHOD_TO_MAP_ICON, upperCase(methodName), 'side-load-marker')
      return {
        type: 'Feature',
        properties: {
          'route-id': routeId,
          'method-name': methodName,
          icon,
        },
        geometry: {
          type: 'Point',
          coordinates: [position[0], position[1]],
        },
      }
    })

    const lastDriverGeojson = {
      type: 'FeatureCollection',
      features: lastDriverFeatures,
    }
    if (mapCurrentRef.getSource('last-driver-positions')) {
      mapCurrentRef.getSource('last-driver-positions').setData(lastDriverGeojson)
    }
  }, [map, breadcrumbsByRouteId])

  const formatRouteGeojsonData = routeGeojsonDataByRouteId => {
    const formattedGeojsonDataByRouteId = {}
    for (const routeId in routeGeojsonDataByRouteId) {
      const routeData = routeGeojsonDataByRouteId[routeId]
      const sequenceLine = get(routeData, 'sequenceLine')
      const features = get(routeData, 'stops.features', [])
      const formattedFeatures = formatStopFeatures(features, theme)
      formattedGeojsonDataByRouteId[routeId] = {
        ...routeData,
        stops: formattedFeatures,
        sequenceLine,
      }
    }
    return formattedGeojsonDataByRouteId
  }

  const resetResequenceHistory = () => dispatch(setResequenceHistory({ track: false, zoom: '', coord: null }))

  const zoomToSearchResult = () => {
    const mapCurrentRef = map.current
    const coordinates = selectedSearchResult.cord.map(parseFloat)
    const bounds = getBounds([coordinates, coordinates])
    mapCurrentRef.fitBounds(bounds, {
      maxZoom: 15,
      linear: true,
    })
    resetResequenceHistory()
  }

  const zoomToResequenceHistory = () => {
    const [longitude, latitude] = resequenceHistoryCoords || []
    const zoom = get(resequenceHistory, 'zoom', 15)
    flyToMap(map, longitude, latitude, true, zoom)
    resetResequenceHistory()
  }

  const zoomToSelectedRoutes = () => {
    const mapCurrentRef = map.current
    let allCoordinates = Object.values(geojsonDataByRouteId).reduce(
      (coordinates, routeData) => coordinates.concat(routeData.stops.map(feature => feature.geometry.coordinates)),
      []
    )
    const unassignedStops = get(mapData, 'unassignedStops.features')
    if (unassignedStops) {
      const unassignedStopsCoordinates = unassignedStops.map(feature => feature.geometry.coordinates)
      allCoordinates = [...allCoordinates, ...unassignedStopsCoordinates]
    }
    if (Array.isArray(allCoordinates) && allCoordinates.length > 0) {
      const bounds = getBounds(allCoordinates, 25)
      mapCurrentRef.fitBounds(bounds, {
        linear: true,
      })
    }
    resetResequenceHistory()
  }

  const onMapLoad = useCallback(() => {
    loadMapAssets()
    addPositionLayers(map.current)
    setIsMapLoaded(true)
  }, [map])

  useEffect(() => {
    if (!map || !map.current) return noop
    if (!isMapLoaded) return noop
    const mapCurrentRef = map.current
    removeLayerIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED_DOT_LAYER)
    removeLayerIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED_LAYER)
    removeSourceIfExist(mapCurrentRef, ROUTE_STOPS_UNASSIGNED)
    if (isUnassignedStopsActive) {
      const unassignedStopsData = get(mapData, 'unassignedStops', {})
      addSourceAsync(mapCurrentRef, ROUTE_STOPS_UNASSIGNED, unassignedStopsData, {
        type: 'geojson',
      })
        .then(() => {
          mapCurrentRef.addLayer(
            {
              id: ROUTE_STOPS_UNASSIGNED_DOT_LAYER,
              type: 'circle',
              source: ROUTE_STOPS_UNASSIGNED,
              paint: UNASSIGNED_ROUTE_STOPS_PAINT,
              ...ZOOM_DOT_LAYER_PROPS,
            },
            MIDDLE_LAYER
          )
          mapCurrentRef.addLayer(
            {
              id: ROUTE_STOPS_UNASSIGNED_LAYER,
              type: 'symbol',
              source: ROUTE_STOPS_UNASSIGNED,
              layout: UNASSIGNED_ROUTE_STOPS_LAYOUT,
              paint: {
                'text-color': common.black,
              },
              ...ZOOM_MARKER_LAYER_PROPS,
            },
            MIDDLE_LAYER
          )
          mapCurrentRef.off('click', ROUTE_STOPS_UNASSIGNED_LAYER, unclusteredClickHandler)
          mapCurrentRef.off('click', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, unclusteredClickHandler)
          mapCurrentRef.on('click', ROUTE_STOPS_UNASSIGNED_LAYER, unclusteredClickHandler)
          mapCurrentRef.on('click', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, unclusteredClickHandler)

          mapCurrentRef.off('mouseenter', ROUTE_STOPS_UNASSIGNED_LAYER, handlePopoverOpen)
          mapCurrentRef.off('mouseleave', ROUTE_STOPS_UNASSIGNED_LAYER, handlePopoverClose)
          mapCurrentRef.off('mouseenter', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, handlePopoverOpen)
          mapCurrentRef.off('mouseleave', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, handlePopoverClose)
          mapCurrentRef.on('mouseenter', ROUTE_STOPS_UNASSIGNED_LAYER, handlePopoverOpen)
          mapCurrentRef.on('mouseleave', ROUTE_STOPS_UNASSIGNED_LAYER, handlePopoverClose)
          mapCurrentRef.on('mouseenter', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, handlePopoverOpen)
          mapCurrentRef.on('mouseleave', ROUTE_STOPS_UNASSIGNED_DOT_LAYER, handlePopoverClose)
        })
        .catch(error => {
          console.error('Error adding source for route stops:', error)
        })
    }
  }, [mapData, map, serializedServiceDate, isUnassignedStopsActive, isMapLoaded, isStreetView])

  useEffect(() => {
    if (mapData) {
      const routeGeojsonData = get(mapData, 'routeGeojsonData', {})
      const geojsonData = formatRouteGeojsonData(routeGeojsonData)
      setGeojsonDataByRouteId(geojsonData)
    }
  }, [mapData, selectedRouteIds, isUnassignedStopsActive, serializedServiceDate, viewOnMapRouteId])

  useEffect(() => {
    if (!map || !map.current) return noop
    updateRouteLinesVisibility()
  }, [isMapLoaded, map, selectedRouteIdList, geojsonDataByRouteId, isSequenceLineActive])

  useEffect(() => {
    if (mapContainer.current && !map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `${MAP_VIEW_URL}${LIGHT_VIEW}`,
        center: [-98.5795, 39.82835],
        zoom: 3.6,
        projection: PROJECTION,
      })

      map.current.addControl(new mapboxgl.FullscreenControl())

      map.current.on('load', () => {
        onMapLoad()
      })

      map.current.on('style.load', () => {
        setIsMapLoaded(false)
        onMapLoad()
        dispatch(setIsBreadcrumbInitialized(false))
      })
    }
  }, [onMapLoad, mapContainer])

  useEffect(() => {
    setIsMapLoaded(false)
    dispatch(setIsBreadcrumbInitialized(true))
  }, [viewMode])

  useEffect(() => {
    if (serializedServiceDate) {
      getRoutesMetadata({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
    }
  }, [serializedServiceDate])

  useEffect(() => {
    if (!isMapLoaded) return
    setTimeout(updateBreadcrumAndDriverPositions, 300)
  }, [isMapLoaded, breadcrumbsByRouteId])

  useEffect(() => {
    if (!isMapLoaded || isBreadcrumbInitialized) return
    const mapCurrentRef = map.current
    removeLayerIfExist(mapCurrentRef, 'last-driver-positions') // Remove previous driver positions layer
    removeSourceIfExist(mapCurrentRef, 'last-driver-positions')
    addSourceAsync(mapCurrentRef, 'last-driver-positions', {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [],
      },
    }).then(() => {
      mapCurrentRef.addLayer(
        {
          id: 'last-driver-positions',
          type: 'symbol',
          source: 'last-driver-positions',
          layout: {
            'icon-image': ['get', 'icon'],
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.5, 15, 0.75],
            'icon-allow-overlap': true,
          },
        },
        TOP_LAYER
      )
    })
    mapCurrentRef.off('click', 'last-driver-positions', onDriverMarkerClick)
    mapCurrentRef.on('click', 'last-driver-positions', onDriverMarkerClick)

    selectedRouteIds.forEach(routeId => {
      addSourceAsync(mapCurrentRef, `route-${routeId}`, {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      }).then(() => {
        mapCurrentRef.addLayer(
          {
            id: `route-${routeId}`,
            type: 'line',
            source: `route-${routeId}`,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': ['get', 'color'],
              'line-width': 1.5,
            },
          },
          MIDDLE_LAYER
        )
        mapCurrentRef.addLayer(
          {
            id: `route-${routeId}-arrows`,
            type: 'symbol',
            source: `route-${routeId}`,
            layout: BREADCRUMB_ARROW_TIP_LAYOUT,
            paint: {
              'icon-color': ['get', 'color'],
            },
          },
          MIDDLE_LAYER
        )
      })
    })

    dispatch(setIsBreadcrumbInitialized(true))
  }, [isMapLoaded, isBreadcrumbInitialized, selectedRouteIds, serializedServiceDate])

  useEffect(() => {
    if (!isMapLoaded) return
    setTimeout(updateStopMarkers, 300)
  }, [isMapLoaded, selectedRouteIds, viewOnMapRouteId, geojsonDataByRouteId, isStreetView])

  useEffect(() => {
    if (!isMapLoaded) return
    if (selectedSearchResult && selectedSearchResult.cord) {
      zoomToSearchResult()
    } else if (geojsonDataByRouteId) {
      if (resequenceHistoryTrack && resequenceHistoryCoords) {
        zoomToResequenceHistory()
        return
      }
      zoomToSelectedRoutes()
    }
  }, [isMapLoaded, selectedSearchResult, geojsonDataByRouteId])

  return { isMapLoaded }
}
