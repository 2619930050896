import api from 'api'
import { CACHE_TAG_ACCOUNT_LOCATION_DETAILS, CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'
import { combineAddressInfo, splitAddressLine1 } from 'utils/helper'
import { get } from 'lodash'
import { NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getLocations: build.query({
      query: params => ({
        url: '/api/v1/core/locations',
        params,
      }),
      providesTags: [CACHE_TAG_ACCOUNT_LOCATION_LIST],
      transformResponse: data => {
        if (Array.isArray(data)) {
          return data.map(location => {
            const combinedAddressInfo = combineAddressInfo(location)
            const addressStr = typeof combinedAddressInfo === 'string' ? combinedAddressInfo.trim() : ''
            const line1 = get(location, 'line1')
            const locationName = get(location, 'locationName')
            const splittedLine1 = splitAddressLine1(line1)
            const { streetName } = splittedLine1
            const tags = get(location, 'tags', [])
            const tagIds = tags.length > 0 ? tags.map(({ id }) => id) : [NO_TAGS_OPTION_ID]
            return { ...location, addressStr, tags, tagIds, streetName, addressName: locationName }
          })
        }
        return data
      },
      keepUnusedDataFor: 3600,
    }),
    getLocationSummary: build.query({
      query: ({ locationId }) => ({
        url: `/api/v1/core/locations/${locationId}`,
      }),
      providesTags: (result, error, { locationId }) => [
        { type: CACHE_TAG_ACCOUNT_LOCATION_DETAILS, id: locationId },
        CACHE_TAG_ACCOUNT_LOCATION_DETAILS,
      ],
      transformResponse: location => {
        const combinedAddressInfo = combineAddressInfo(location)
        const addressStr = typeof combinedAddressInfo === 'string' ? combinedAddressInfo.trim() : ''
        const tags = get(location, 'tags', [])
        const tagIds = tags.map(({ id }) => id)
        return { ...location, addressStr, tags, tagIds }
      },
    }),
  }),
})

export const { useLazyGetLocationsQuery, useLazyGetLocationSummaryQuery } = extendedApi
