import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import AutoComplete from 'components/common/AutoComplete'

import { useGetGeneralLedgerMutation } from 'api/settings/getGeneralLedger'

import T from 'T'
import { get } from 'utils/lodash'

const GeneralLedgerAutoComplete = ({ checkedValues = [], handleCheckboxValue = () => {}, backFlag = false }) => {
  const [getGeneralLedger, { data: ledgerData }] = useGetGeneralLedgerMutation()

  useEffect(() => {
    getGeneralLedger({})
  }, [])

  return (
    <div className="actions left-elements-width mt15">
      <div className="service-label">{`${T.GENERAL_LEDGER} ${T.ACCOUNT}`}</div>

      {ledgerData && (
        <AutoComplete
          multiple={false}
          backFlag={backFlag}
          isHandleCheckbox
          metaData={ledgerData}
          label={`${T.GENERAL_LEDGER} ${T.ACCOUNT}`}
          getByLabelText={option => get(option, 'accountName', '')}
          handleCheckboxValue={handleCheckboxValue}
          checkedRecords={checkedValues}
          getPlaceholder={checkedValues.length ? '' : `Select ${T.GENERAL_LEDGER} ${T.ACCOUNT}`}
        />
      )}
    </div>
  )
}

GeneralLedgerAutoComplete.propTypes = {
  handleCheckboxValue: PropTypes.func,
  checkedValues: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default GeneralLedgerAutoComplete
