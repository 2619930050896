import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, ListItemText, ListItemIcon, SvgIcon, useTheme, useMediaQuery } from '@mui/material'
import { handleError } from 'utils/error'
import { useActivateDeactivateLocationsMutation } from 'api/location/activateDeactivateLocations'
import T from 'T'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import { LocationRowContext } from 'components/locations/LocationsDataGrid/LocationRowContext'
import Badge from '@mui/material/Badge'
import { ReactComponent as LocationNoteIcon } from 'assets/note/LocationNote.svg'
import InteractiveTagItemMenu from 'components/tags/InteractiveTagItemMenu'
import api from 'api'
import { CACHE_TAG_ACCOUNT_LOCATION_DETAILS, CACHE_TAG_ACCOUNT_LOCATION_LIST } from 'api/cacheTagTypes'
import { useDispatch } from 'react-redux'
import ToggleLocationStatusMenuItem from 'components/common/locations/ToggleLocationStatusMenuItem'

const LocationActionsMenu = ({ row }) => {
  const activeConfiguredServiceCount = get(row, 'activeConfiguredServiceCount', 0)
  const [activateDeactivateLocations] = useActivateDeactivateLocationsMutation()
  const hasActiveServices = activeConfiguredServiceCount > 0
  const locationId = get(row, 'locationId')
  const isDeactivated = get(row, 'deactivated')
  const name = get(row, 'addressStr')
  const contactCount = get(row, 'contactCount', 0)
  const locationNoteCount = get(row, 'locationNoteCount', 0)
  const isAccountActive = get(row, 'accountActive', false)
  const tags = get(row, 'tags', [])
  const dispatch = useDispatch()
  const { actionsAnchorEl, openActions, handleActionsClose, openContactsDialog, openLocationNotesDialog } = useContext(LocationRowContext)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const handleLocationActivateDeactivate = () => {
    const payload = {
      deactivate: !isDeactivated,
      locations: [{ id: locationId }],
    }

    activateDeactivateLocations(payload)
      .unwrap()
      .then(() => {
        handleActionsClose()
        toast.success(payload.deactivate ? T.LOCATION_DEACTIVATED_MSG : T.LOCATION_ACTIVATED_MSG)
      })
      .catch(handleError)
  }

  const handleLocationNoteClick = () => {
    openLocationNotesDialog()
    handleActionsClose()
  }

  const handleContactsClick = () => {
    openContactsDialog()
    handleActionsClose()
  }

  const onSaveTags = (data, closeTagsDialog) => {
    dispatch(api.util.invalidateTags([CACHE_TAG_ACCOUNT_LOCATION_LIST, CACHE_TAG_ACCOUNT_LOCATION_DETAILS]))
    closeTagsDialog()
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined', sx: { minWidth: 200 } } }}
        anchorEl={actionsAnchorEl}
        open={openActions}
        onClose={handleActionsClose}
        disableScrollLock
        keepMounted
      >
        {isTabletOrMobile && (
          <MenuItem onClick={handleContactsClick}>
            <ListItemIcon>
              <Badge badgeContent={contactCount} color="primary">
                <SvgIcon fontSize="small">
                  <PersonOutlinedIcon />
                </SvgIcon>
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </MenuItem>
        )}
        {isTabletOrMobile && (
          <MenuItem onClick={handleLocationNoteClick}>
            <ListItemIcon>
              <Badge badgeContent={locationNoteCount} color="primary">
                <SvgIcon fontSize="small">
                  <LocationNoteIcon />
                </SvgIcon>
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Location notes" />
          </MenuItem>
        )}
        {isTabletOrMobile && (
          <InteractiveTagItemMenu
            data={{
              tagDetails: {
                forLocation: tags,
              },
              locationId,
              name,
            }}
            onSave={onSaveTags}
            disableAccount
            disableService
            onClick={handleActionsClose}
          />
        )}
        <ToggleLocationStatusMenuItem
          onClick={handleLocationActivateDeactivate}
          hasActiveServices={hasActiveServices}
          isAccountActive={isAccountActive}
          isLocationDeactivated={isDeactivated}
        />
      </Menu>
    </>
  )
}

LocationActionsMenu.propTypes = {
  row: PropTypes.object,
}

export default LocationActionsMenu
