import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getTaxZoneList: build.query({
      query: () => ({
        url: 'api/settings/tax/zone/list',
        method: 'POST',
        body: {},
      }),
    }),
  }),
})

export const { useLazyGetTaxZoneListQuery } = extendedApi
