/* eslint-disable no-param-reassign */
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { get } from 'lodash'
import { createDataGridSlice, mapSortRulesToSortModel } from '../generic/createDataGridSlice'
import { INVOICE_PAGINATION } from '../../settings/constants/pagination'
import { handleCommonTableformatFilters, handleCommonTableformatSortBy, handlePrefillResponseModification } from '../../utils/table'
import { filterMapToQueryParams } from '../../utils/LHSbracket'
// eslint-disable-next-line import/no-cycle
import { SENT_INVOICES_CONFIGURATOR } from '../../containers/billing/settings'
import { fixDateFilters } from '../generic/fixDateFilters'

const { INITIAL_PAGE, ROWS_PER_PAGE } = INVOICE_PAGINATION

const getInitialState = () => ({
  pagination: {
    page: INITIAL_PAGE,
    pageSize: ROWS_PER_PAGE,
  },
  sorting: {
    sortModel: [{ field: 'transactionPostedDate', sort: 'desc' }],
  },
  columns: {
    columnVisibilityModel: {
      accountNumber: true,
      customerName: true,
      paymentNumber: true,
      checkNum: true,
      batchId: true,
      transactionPostedDate: true,
      transactionAmountCents: true,
      paymentTarget: true,
      transactionStatus: true,
      paymentDate: true,
      paymentNote: false,
      customerAddress: false,
      customerCity: false,
      customerState: false,
      customerZipCode: false,
      customerProfileName: false,
      tags: false,
    },
  },
  selectionModel: [],
  selectedRows: [],
  savedView: {
    existingFilterList: [],
    activeFilter: '',
    input: '',
    selectedFilterId: '',
    selectedFilter: {},
    isModalOpen: false,
  },
  resetOnUnmount: true,
  filters: {
    allFilters: [],
    isOpenTableFilters: false,
    searchFilters: {},
    sortRules: [{ columnName: 'transactionPostedDate', operation: 'DESC' }],
    refetchFlag: false,
    queryParams: {},
  },
  isOpenPaymentActionMenu: false,
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
  isOpenInvoiceActionMenu: null,
  addToGroup: {
    isOpen: false,
    batchIds: [],
  },
  paymentReceiptDialog: {
    isOpen: false,
    payment: {
      accountId: '',
      accountName: '',
      paymentNumber: '',
      accountTransactionHistoryId: '',
    },
  },
  paymentReceiptEmailConfirmation: {
    isOpen: false,
    payments: [],
  },
  addUpdatePaymentNote: {
    isOpen: false,
    accountId: '',
    paymentNumber: '',
    paymentNote: '',
  },
})

export const payments = createDataGridSlice({
  name: 'payments',
  initialState: getInitialState(),
  reducers: {
    setPaymentsSelectionModel: (state, action) => {
      const selectionModel = get(action, 'payload.selectionModel', [])
      const rows = get(action, 'payload.rows', [])
      state.selectionModel = selectionModel
      if (selectionModel.length > 0) {
        const selectedPaymentIdsSet = new Set(selectionModel)
        state.selectedRows = rows.filter(({ accountTransactionHistoryId }) => selectedPaymentIdsSet.has(accountTransactionHistoryId))
      } else {
        state.selectedRows = []
      }
    },
    setPaymentsSavedViewSelectedFilterId: (state, action) => {
      state.savedView.selectedFilterId = action.payload
    },
    setPaymentsExistingFilterList: (state, action) => {
      state.savedView.existingFilterList = action.payload
    },
    setPaymentsSavedViewInput: (state, action) => {
      state.savedView.input = action.payload
    },
    setPaymentsRefetchFlag: (state, action) => {
      state.filters.refetchFlag = action.payload
    },
    setPaymentsFiltersAndSortRulesFromSavedView: (state, action) => {
      const newAllFilters = {}
      const newSortBy = {}
      const searchFilters = get(action, 'payload.searchFilters', [])
      const sortRules = get(action, 'payload.sortRules', [])
      if (Array.isArray(searchFilters) && searchFilters.length > 0) {
        searchFilters.forEach(filter => {
          const initialFilter = SENT_INVOICES_CONFIGURATOR.find(({ columnName }) => columnName === filter.columnName)
          newAllFilters[filter.columnName] = handlePrefillResponseModification(initialFilter, filter)
        })
        state.filters.allFilters = newAllFilters
        state.filters.queryParams = filterMapToQueryParams(newAllFilters)
        const parsedFilters = handleCommonTableformatFilters(newAllFilters)
        state.filters.searchFilters = fixDateFilters(parsedFilters)
      } else {
        state.filters.queryParams = {}
        state.filters.allFilters = {}
        state.filters.searchFilters = {}
      }
      if (Array.isArray(sortRules) && sortRules.length > 0) {
        sortRules.forEach(sort => {
          newSortBy[sort.columnName] = { columnName: sort.columnName, operation: sort.operation }
        })
        const newSortRules = handleCommonTableformatSortBy(newSortBy)
        state.filters.sortRules = newSortRules
        state.sorting.sortModel = mapSortRulesToSortModel(newSortRules)
      } else {
        state.filters.sortRules = {}
        state.sorting.sortModel = []
      }
      state.filters.refetchFlag = true
    },
    setPaymentsSavedViewState: (state, action) => {
      state.savedView = {
        ...state.savedView,
        ...action.payload,
      }
    },
    setPaymentsIsOpenActionMenu: (state, action) => {
      state.isOpenPaymentActionMenu = action.payload
    },
    setResetPaymentsStateOnUnmount: (state, action) => {
      state.resetOnUnmount = action.payload
    },
    resetPaymentsState: state => {
      if (state.resetOnUnmount) return getInitialState()
      return state
    },
    setAddToGroupDialogState: (state, action) => {
      state.addToGroup = action.payload
    },
    setPaymentReceiptDialogState: (state, action) => {
      state.paymentReceiptDialog = action.payload
    },
    setPaymentReceiptEmailConfirmationState: (state, action) => {
      state.paymentReceiptEmailConfirmation = action.payload
    },
    setAddUpdatePaymentNoteState: (state, action) => {
      state.addUpdatePaymentNote = action.payload
    },
  },
})

export const {
  setSortModel: setPaymentsSortModel,
  setPage: setPaymentsPage,
  setPageSize: setPaymentsPageSize,
  setColumnVisibilityModel: setPaymentsColumnVisibilityModel,
  setFilters: setPaymentsFilters,
  setIsOpenTableFilters: setPaymentsIsOpenTableFilters,
  setPinnedColumns: setPaymentsPinnedColumns,
  setPaymentsSelectionModel,
  setPaymentsSavedViewSelectedFilterId,
  setPaymentsExistingFilterList,
  setPaymentsSavedViewInput,
  setResetPaymentsStateOnUnmount,
  resetPaymentsState,
  setPaymentsRefetchFlag,
  setPaymentsSavedViewState,
  setPaymentsFiltersAndSortRulesFromSavedView,
  setPaymentsIsOpenActionMenu,
  setAddToGroupDialogState,
  setPaymentReceiptDialogState,
  setPaymentReceiptEmailConfirmationState,
  setAddUpdatePaymentNoteState,
} = payments.actions

export default payments.reducer
