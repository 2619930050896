import omit from 'lodash/omit'
import api from 'api'
import {
  CACHE_TAG_ACCOUNT_LOCATION_DETAILS,
  CACHE_TAG_ACCOUNT_LOCATION_LIST,
  CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST,
  CACHE_TAG_CONFIGURED_SERVICES_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
} from '../cacheTagTypes'

const invalidatesTags = [
  CACHE_TAG_CONFIGURED_SERVICES_LIST,
  CACHE_TAG_WORK_ORDER_LIST,
  CACHE_TAG_ACCOUNT_LOCATION_LIST,
  CACHE_TAG_ACCOUNT_LOCATION_DETAILS,
]

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllCSNotes: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/core/configured-services/${id}/notes`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [
        CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST,
        { type: CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST, id },
      ],
    }),
    getCSNoteDetails: builder.query({
      query: ({ configuredServiceId, noteId }) => ({
        url: `/api/v1/core/configured-services/${configuredServiceId}/notes/${noteId}`,
        method: 'GET',
      }),
    }),
    getCSNoteHistory: builder.query({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/configured-services/${id}/notes/${noteId}/history`,
        method: 'GET',
      }),
    }),
    createCSNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/configured-services/${id}/notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST, id }, ...invalidatesTags],
    }),
    updateCSNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/configured-services/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST, id }, ...invalidatesTags],
    }),
    patchCSNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/configured-services/${id}/notes/${noteId}`,
        method: 'PATCH',
        body: omit(data, 'skipInvalidate'),
      }),
      invalidatesTags: (result, error, arg) =>
        arg?.data?.skipInvalidate ? [] : [{ type: CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST, id: arg.id }, ...invalidatesTags],
    }),
    deleteCSNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/configured-services/${id}/notes/${noteId}/delete`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST, id }, ...invalidatesTags],
    }),
  }),
})

export const {
  useLazyGetAllCSNotesQuery,
  useLazyGetCSNoteDetailsQuery,
  useLazyGetCSNoteHistoryQuery,
  useCreateCSNoteMutation,
  useUpdateCSNoteMutation,
  usePatchCSNoteMutation,
  useDeleteCSNoteMutation,
} = extendedApi
