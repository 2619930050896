import api from 'api'
import { CACHE_TAG_PRICED_SERVICES_LIST } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    // w/o pagination
    getAllPricedServices: build.query({
      query: () => ({ url: '/api/v1/pricing/service', method: 'GET' }),
    }),
    // with pagination
    getPricingServiceList: build.query({
      query: body => ({ url: '/api/v1/pricing/service/list', method: 'POST', body }),
      providesTags: [CACHE_TAG_PRICED_SERVICES_LIST],
    }),
    updatePricingService: build.mutation({
      query: body => ({ url: '/api/v1/pricing/service', method: 'PUT', body }),
    }),
  }),
})
export const { useLazyGetAllPricedServicesQuery, useLazyGetPricingServiceListQuery, useUpdatePricingServiceMutation } = extendedApi
