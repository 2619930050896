import React, { useEffect, useMemo } from 'react'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import useTheme from '@mui/material/styles/useTheme'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { get } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { fixTimezoneForDateFilters } from 'slices/generic/fixDateFilters'
import { INVOICE_PAGINATION } from 'settings/constants/pagination'
import { queryParamsToFiltersMap } from 'utils/LHSbracket'
import { handleCommonTableformatFilters } from 'utils/table'
import { useLazyGetBillingARListQuery } from 'api/billing/getBillingARList'
import { useLazyGetBillingInvoiceMetaQuery } from 'api/billing/getBillingInvoiceMeta'
import {
  setARColumnVisibilityModel,
  setARFilters,
  setARIsOpenTableFilters,
  setARPage,
  setARPageSize,
  setARPinnedColumns,
  setARRefetchFlag,
  setARSelectionModel,
  setARSortModel,
} from '../../../slices/billing/accountsReceivableSlice'
import { ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID } from '../../../containers/billing/settings'
import { DataGridPagination } from '../../data_grid/DataGridPagination'
import CustomLoadingOverlay from '../common/CustomLoadingOverlay'

const { ROWS_PER_PAGE_OPTIONS } = INVOICE_PAGINATION
const AccountsReceivableDataGrid = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsMap = Object.fromEntries(searchParams)
  const filtersFromSearchParams = queryParamsToFiltersMap(searchParamsMap)
  const allFiltersFromSearchParams = handleCommonTableformatFilters(filtersFromSearchParams || {})
  const page = useSelector(state => state.accountsReceivable.pagination.page)
  const pageSize = useSelector(state => state.accountsReceivable.pagination.pageSize)
  const [getBillingARList, { isFetching, isLoading, data }] = useLazyGetBillingARListQuery()
  const [getBillingInvoiceMeta] = useLazyGetBillingInvoiceMetaQuery()
  const refetchFlag = useSelector(state => state.accountsReceivable.filters.refetchFlag)
  const rows = get(data, 'tableRows', [])
  const rowCount = get(data, 'totalItems')
  const sortModel = useSelector(state => state.accountsReceivable.sorting.sortModel, shallowEqual)
  const selectionModel = useSelector(state => state.accountsReceivable.selectionModel, shallowEqual)
  const queryParams = useSelector(state => state.accountsReceivable.filters.queryParams, shallowEqual)
  const columnVisibilityModel = useSelector(state => state.accountsReceivable.columns.columnVisibilityModel)
  const rawSearchFilters = useSelector(state => state.accountsReceivable.filters.searchFilters, shallowEqual)
  const rawSortRules = useSelector(state => state.accountsReceivable.filters.sortRules, shallowEqual)
  const pinnedColumns = useSelector(state => state.accountsReceivable.pinnedColumns, shallowEqual)
  const searchFilters = isEmpty(rawSearchFilters) ? undefined : rawSearchFilters
  const timeZone = useSelector(state => get(state, 'AuthReducer.userInfo.timeZone'), shallowEqual)
  const searchFiltersTimezoned = useMemo(() => fixTimezoneForDateFilters(searchFilters, timeZone), [searchFilters, timeZone])
  const sortRules = isEmpty(rawSortRules) ? undefined : rawSortRules
  const getRowId = ({ accountId }) => accountId

  const handlePageSizeChange = newSize => {
    dispatch(setARPageSize(newSize))
    dispatch(setARPage(0))
    getBillingARList({ sortRules, searchFilters, requestedPage: 0, requestedPageSize: newSize })
  }

  const handlePageChange = newPage => {
    if (isLoading || isFetching) return
    dispatch(setARPage(newPage))
    getBillingARList({ sortRules, searchFilters, requestedPage: newPage, requestedPageSize: pageSize })
  }

  const handleARPageAndSizeChange = ({ page: newPage, pageSize: newPageSize }) => {
    if (page !== newPage) {
      handlePageChange(newPage)
      return
    }

    if (pageSize !== newPageSize) {
      handlePageSizeChange(newPageSize)
    }
  }

  const handleSortModelChange = newSortModel => {
    dispatch(setARSortModel(newSortModel))
    dispatch(setARRefetchFlag(true))
  }

  const handleSelectionModelChange = newSelectionModel => {
    dispatch(setARSelectionModel({ selectionModel: newSelectionModel, rows }))
  }

  const handleColumnVisibilityModelChange = newColumnVisibilityModel => {
    dispatch(setARColumnVisibilityModel(newColumnVisibilityModel))
  }

  const handlePinnedColumnModelChange = updatedPinnedColumns => {
    dispatch(setARPinnedColumns(updatedPinnedColumns))
  }

  useEffect(() => {
    getBillingInvoiceMeta()
    dispatch(setARFilters({ allFilters: filtersFromSearchParams }))
    getBillingARList({
      sortRules,
      searchFilters: fixTimezoneForDateFilters(
        [
          ...(searchFilters && Array.isArray(searchFilters) ? searchFilters : []),
          ...(allFiltersFromSearchParams && Array.isArray(allFiltersFromSearchParams) ? allFiltersFromSearchParams : []),
        ],
        timeZone
      ),
      requestedPage: page,
      requestedPageSize: pageSize,
    })
  }, [])

  useEffect(() => {
    if (refetchFlag) {
      getBillingARList({ sortRules, searchFilters: searchFiltersTimezoned, requestedPage: page, requestedPageSize: pageSize })
        .unwrap()
        .then(() => {
          dispatch(setARRefetchFlag(false))
          dispatch(setARIsOpenTableFilters(false))
        })
    }
  }, [refetchFlag])

  useEffect(() => {
    if (queryParams) {
      setSearchParams({ ...queryParams })
    }
  }, [queryParams])

  return (
    <DataGridPro
      checkboxSelection
      disableRowSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      disableColumnFilter
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={{ page, pageSize }}
      rows={rows}
      pagination
      pinnedColumns={pinnedColumns}
      rowCount={rowCount}
      sortModel={sortModel}
      onPinnedColumnsChange={handlePinnedColumnModelChange}
      rowSelectionModel={selectionModel}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleSelectionModelChange}
      onPaginationModelChange={handleARPageAndSizeChange}
      onRowClick={noop}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      getRowId={getRowId}
      columnVisibilityModel={columnVisibilityModel}
      columns={ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID}
      loading={isLoading || isFetching}
      sx={{
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        '.MuiDataGrid-columnHeader': {
          fontWeight: 'bold',
        },
        '.MuiDataGrid-toolbarContainer': {
          paddingLeft: 1.5,
        },
      }}
      slots={{
        toolbar: GridToolbar,
        pagination: DataGridPagination,
        loadingOverlay: CustomLoadingOverlay,
      }}
    />
  )
}

export default AccountsReceivableDataGrid
