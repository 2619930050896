import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { useMemo } from 'react'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import { useBulkAddTagsToAccountsMutation } from 'api/groups/account/bulkAddTagsToAccounts'
import { useBulkAddTagsToAgingGroupMutation } from 'api/groups/account/accountsByAgingBucketCrud'
import { useBulkAddTagsToSuspensionGroupMutation } from 'api/groups/account/suspendedAccountsCrud'

export const useBulkAddTagsMutation = accountGroupId => {
  const isDefaultBucket = useMemo(() => Object.values(BUCKET_NAME_TO_GROUP_ID_PARAM).includes(accountGroupId), [accountGroupId])
  const bulkAddTagsToAccounts = useBulkAddTagsToAccountsMutation()
  const bulkAddTagsToAgingGroup = useBulkAddTagsToAgingGroupMutation()
  const bulkAddTagsToSuspensionGroup = useBulkAddTagsToSuspensionGroupMutation()
  return useMemo(() => {
    const isSuspendedGroup = getIsSuspendedBucket(accountGroupId)
    if (isSuspendedGroup) return bulkAddTagsToSuspensionGroup
    if (isDefaultBucket) return bulkAddTagsToAgingGroup
    return bulkAddTagsToAccounts
  }, [accountGroupId, isDefaultBucket])
}
