import api from 'api'
import { CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getEmailEvents: builder.query({
      providesTags: (result, error, { accountId }) => [
        CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
        { type: CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST, id: accountId },
      ],
      query: ({ accountId }) => ({ url: `/api/v1/core/accounts/${accountId}/email-events` }),
    }),
  }),
})

export const { useLazyGetEmailEventsQuery } = extendedApi
