import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

const ChartLegend = ({ data = [], showZeroValueLegend = true, ...rest }) => (
  <Box display="flex" alignItems="center" justifyContent="center" columnGap={2} flexWrap="wrap" {...rest}>
    {Children.toArray(
      data.map(
        ({ color, label, value }) =>
          (showZeroValueLegend || value > 0) && (
            <Box display="flex" alignItems="center">
              <Box width={15} height={15} bgcolor={color} />
              <Typography ml={0.5}>{label}</Typography>
            </Box>
          )
      )
    )}
  </Box>
)

ChartLegend.propTypes = {
  data: PropTypes.array,
  showZeroValueLegend: PropTypes.bool,
}

export default ChartLegend
