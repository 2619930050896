import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import HHTextField from './HHTextField'

const HHFormPhoneField = ({
  sx = undefined,
  name,
  control,
  disabled = false,
  label = undefined,
  placeholder = '(201) 555-0123',
  size = 'medium',
  autocomplete = 'off',
  variant = 'outlined',
  rules = undefined,
  required = false,
  fullWidth = false,
  valueIsNumericString = false,
  InputProps = undefined,
  error = '',
  showErrorMessage = false,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? { ...rules, required: required || rules?.required === true } : { required: required || rules?.required === true }}
      render={({ field }) => {
        const { ref, onChange, onBlur, ..._rest } = field
        return (
          <PatternFormat
            label={required ? `${label} *` : label}
            required={required || rules?.required === true}
            error={error}
            format="(###) ###-####"
            customInput={HHTextField}
            sx={sx}
            disabled={disabled}
            placeholder={placeholder}
            size={size}
            inputRef={ref}
            autoComplete={autocomplete}
            InputProps={{ ...InputProps, onBlur }}
            variant={variant}
            valueIsNumericString={valueIsNumericString}
            {..._rest}
            {...rest}
            onValueChange={values => {
              const { value } = values
              onChange(value)
            }}
            fullWidth={fullWidth}
            showErrorMessage={showErrorMessage}
          />
        )
      }}
    />
  )
}

HHFormPhoneField.propTypes = {
  sx: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  autocomplete: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  rules: PropTypes.object,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  valueIsNumericString: PropTypes.bool,
  InputProps: PropTypes.object,
  error: PropTypes.string,
  showErrorMessage: PropTypes.bool,
}

export default HHFormPhoneField
