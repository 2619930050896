import React, { useState } from 'react'
import { Box, Grid, IconButton, ListItem, ListItemText, Menu, MenuItem, styled, ListItemIcon } from '@mui/material'
import { toast } from 'react-toastify'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactComponent as RentalFeeGracePeriodIcon } from 'assets/RentalFeeGracePeriod.svg'
import { CheckCircle } from '@styled-icons/heroicons-outline/CheckCircle'
import useTheme from '@mui/material/styles/useTheme'
import { useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import PropTypes from 'prop-types'
import WorkOrderStatusChip from 'components/work-order/WorkOrderStatusChip'
import CommonSwitch from 'components/common/CommonSwitch'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'
import WorkOrderInvoiceStatusChip from 'components/work-order/WorkOrderInvoiceStatusChip'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { canChangeWOInvoiceStatusSelector } from 'data/work-order/workOrderSelectors'
import ServiceActionTypeChip from 'components/services/ServiceActionTypeChip'
import T from 'T'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { setWOReviewDisposalTicketsDialog, setWOReviewGeneralFeesDialog } from 'slices/billing/workOrdersReviewSlice'
import RentalFeeBadgeButton from 'components/pricing/RentalFees/common/badge/RentalFeeBadgeButton'
import ConfiguredServiceResetGracePeriodDialog from 'components/pricing/RentalFees/ResetGracePeriod/ServiceWise/ConfiguredServiceResetGracePeriodDialog'
import DisposalTicketChip from '../../DisposalTicketChip'
import GeneralFeesChip from '../../GeneralFeesChip'
import { useWOReviewContext } from '../WOReviewProvider'
import WithGeneralFeesDetailsTooltip from './WithGeneralFeesDetailsTooltip'

const { READY_FOR_INVOICE, NOT_READY_FOR_INVOICE } = INVOICE_STATUS

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '.MuiListItemText-primary': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  '.MuiListItemText-secondary': {
    marginTop: theme.spacing(0.75),
  },
}))

const WOReviewDetailsCardRightSection = ({
  id,
  serviceType,
  woStatus,
  invoiceStatus,
  disposalTicketAmountCents,
  action,
  workOrderFees,
  routeId,
  configuredServiceId,
  rentalFeeCount = 0,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const flags = useFlags()
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const [updateWorkOrder] = useUpdateWorkOrderMutation()
  const apiRef = useGridApiContext()
  const allowedToChangeWOInvoiceStatus = canChangeWOInvoiceStatusSelector({ serviceType, invoiceStatus })
  const hasDisposalTicket = disposalTicketAmountCents !== null
  const feesCount = workOrderFees.length
  const hasFees = feesCount !== 0
  const totalFeeChargeCents = workOrderFees.reduce((sum, item) => sum + item.feeChargeCents * item.quantity, 0)
  const { fetchAllChildrenRows } = useWOReviewContext()
  const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false)
  const [isOpenResetGracePeriodDialog, setIsOpenResetGracePeriodDialog] = useState(false)

  const handleCloseActionsMenu = () => setIsActionsMenuOpen(false)
  const handleOpenDisposalModal = () => {
    dispatch(
      setWOReviewDisposalTicketsDialog({
        isOpen: true,
        woId: id,
        routeId,
        configuredServiceId,
      })
    )
  }

  const handleOpenGeneralFeesModal = () => {
    dispatch(
      setWOReviewGeneralFeesDialog({
        isOpen: true,
        woId: id,
        fees: workOrderFees,
        routeId,
      })
    )
  }
  const handleOpenActionsMenu = e => setIsActionsMenuOpen(e.currentTarget)

  const handleInvoiceStatusChange = () => {
    const isChecked = invoiceStatus === NOT_READY_FOR_INVOICE
    if (isChecked === true) {
      apiRef.current.selectRows([id, routeId], false)
    }
    dispatch(putIsLoading(true))
    handleCloseActionsMenu()
    updateWorkOrder({ id, invoiceStatus: isChecked ? READY_FOR_INVOICE : NOT_READY_FOR_INVOICE })
      .unwrap()
      .then(async () => {
        toast.success('Invoice status updated successfully')
        await fetchAllChildrenRows()
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleOpenResetGracePeriodDialog = () => {
    handleCloseActionsMenu()
    setIsOpenResetGracePeriodDialog(true)
  }

  const handleCloseResetGracePeriodDialog = () => {
    setIsOpenResetGracePeriodDialog(false)
  }

  const isActionMenuDisabled = !allowedToChangeWOInvoiceStatus && !isRentalFeeEnabled

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs>
          <ListItem disablePadding disableGutters>
            <StyledListItemText
              primary="SERVICE EVENT"
              secondary={
                action ? (
                  <ServiceActionTypeChip size="small" action={action} />
                ) : (
                  <ServiceActionTypeChip size="small" action="Collection" label={T.RECURRING_COLLECTION} />
                )
              }
            />
          </ListItem>
        </Grid>
        <Grid item xs="auto">
          <ListItem disablePadding disableGutters>
            <StyledListItemText primary="WORK ORDER STATUS" secondary={<WorkOrderStatusChip status={woStatus} />} />
          </ListItem>
        </Grid>
        <Grid item xs="auto">
          <IconButton onClick={handleOpenActionsMenu} disabled={isActionMenuDisabled}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={isActionsMenuOpen}
            anchorEl={isActionsMenuOpen}
            onClose={handleCloseActionsMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ zIndex: theme.zIndex.tooltip + 4 }}
            {...(allowedToChangeWOInvoiceStatus && { slotProps: { paper: { sx: { minWidth: 250 } } } })}
          >
            {isRentalFeeEnabled && (
              <MenuItem sx={{ '.MuiFormLabel-root': { color: 'text.primary' } }} onClick={handleOpenResetGracePeriodDialog}>
                <ListItemIcon>
                  <RentalFeeGracePeriodIcon />
                </ListItemIcon>
                <ListItemText>{T.RESET_GRACE_PERIOD}</ListItemText>
              </MenuItem>
            )}
            {allowedToChangeWOInvoiceStatus && (
              <MenuItem sx={{ '.MuiFormLabel-root': { color: 'text.primary' } }} onClick={handleInvoiceStatusChange}>
                <ListItemIcon>
                  <CheckCircle width={24} height={24} />
                </ListItemIcon>
                <ListItemText>{INVOICE_STATUS_DISPLAY.READY_FOR_INVOICE.text}</ListItemText>
                <CommonSwitch isChecked={invoiceStatus === READY_FOR_INVOICE} />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item>
          <ListItem disablePadding disableGutters sx={{ mr: 2 }}>
            <StyledListItemText
              primary="DISPOSAL TICKET"
              secondary={
                <DisposalTicketChip
                  amountCents={disposalTicketAmountCents}
                  onClick={handleOpenDisposalModal}
                  hasDisposalTicket={hasDisposalTicket}
                />
              }
            />
          </ListItem>
        </Grid>
        <Grid item {...(!isRentalFeeEnabled && { xs: true })}>
          <ListItem disablePadding disableGutters sx={{ mr: 2 }}>
            <StyledListItemText
              primary="GENERAL FEES"
              secondary={
                <WithGeneralFeesDetailsTooltip workOrderFees={workOrderFees}>
                  <GeneralFeesChip
                    feesCount={feesCount}
                    totalFeeChargeCents={totalFeeChargeCents}
                    onClick={handleOpenGeneralFeesModal}
                    hasFees={hasFees}
                  />
                </WithGeneralFeesDetailsTooltip>
              }
            />
          </ListItem>
        </Grid>
        {isRentalFeeEnabled && (
          <Grid item xs>
            <ListItem disablePadding disableGutters>
              <StyledListItemText
                primary={T.RENTAL_FEES.toUpperCase()}
                secondary={<RentalFeeBadgeButton rentalFeeCount={rentalFeeCount} configuredServiceId={configuredServiceId} disableRipple />}
                sx={{ '.MuiListItemText-secondary': { mt: 0.25 } }}
              />
            </ListItem>
          </Grid>
        )}
        <Grid item xs="auto">
          <ListItem sx={{ mr: '2.2rem' }} disablePadding disableGutters>
            <StyledListItemText primary="INVOICE STATUS" secondary={<WorkOrderInvoiceStatusChip status={invoiceStatus} />} />
          </ListItem>
        </Grid>
        <Grid item xs="auto">
          <Box height="100%" display="flex" alignItems="end" visibility="hidden">
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <ConfiguredServiceResetGracePeriodDialog
        isOpen={isOpenResetGracePeriodDialog}
        configuredServiceId={configuredServiceId}
        onClose={handleCloseResetGracePeriodDialog}
      />
    </>
  )
}

WOReviewDetailsCardRightSection.propTypes = {
  id: PropTypes.string,
  action: PropTypes.string,
  serviceType: PropTypes.string,
  invoiceStatus: PropTypes.string,
  routeId: PropTypes.string,
  woStatus: PropTypes.string,
  disposalTicketAmountCents: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  workOrderFees: PropTypes.array,
  configuredServiceId: PropTypes.string,
  rentalFeeCount: PropTypes.number,
}

export default WOReviewDetailsCardRightSection
