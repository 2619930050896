import { get } from 'utils/lodash'
import { getPriceWithSymbol } from 'utils/service'

const getTaxValues = (column, service) => {
  switch (column) {
    case 'Tax':
      return get(service, 'taxName', '')
    case 'Measure':
      return getMeasure(service)
    case 'Value':
      return getTaxValueType(service)
    default:
      return T.CHANGE_FEE
  }
}

const getGeneralFeeValues = (column, service) => {
  switch (column) {
    case 'Fee':
      return get(service, 'feeName', '')
    case 'Measure':
      return getMeasure(service)
    case 'Period':
      return getPeriod(service)
    case 'Value':
      return getValue(service)
    default:
      return
  }
}

const getDisposalFeeValues = (column, service) => {
  switch (column) {
    case 'Fee':
      return get(service, 'feeName', '')
    case 'Measure':
      return get(service, 'measureType', '')
    case 'Period':
      return getPeriod(service)
    case 'Method':
      return get(service, 'methodName', '')
    default:
      return
  }
}

const getServiceValues = (column, service) => {
  switch (column) {
    case 'Service':
      return get(service, 'serviceName', '')
    case 'Measure':
      return getMeasure(service)
    case 'Material':
      return get(service, 'material.materialType', '')
    case 'Recurrence':
      return getRecurrence(service)
    case 'Period':
      return getPeriod(service)
    case 'Value':
      return getValue(service)
    default:
      return
  }
}

const getRecurrence = service => {
  const recurrencePer = get(service, 'recurrence.recurrencePer', '')
  const recurrenceInterval = get(service, 'recurrence.recurrenceInterval', '')
  let recurence = ''

  if (recurrencePer > 1) {
    recurence = recurrencePer
  }

  return `${recurence} ${recurrenceInterval}`
}

const getMeasure = service => {
  const measure_vol = get(service, 'measure.volume', '')
  const measure_unit = get(service, 'measure.unit', '')
  if (measure_vol > 0) {
    return `${measure_vol} ${measure_unit}`
  }
  return measure_unit
}

const getPeriod = service => {
  const pricingPeriodPer = get(service, 'pricingPeriod.periodPer', '')
  const pricingPeriodInterval = get(service, 'pricingPeriod.periodInterval', '')

  if (pricingPeriodPer > 1) {
    return `${pricingPeriodPer} ${pricingPeriodInterval}`
  }
  return pricingPeriodInterval
}

const getTaxValueType = service => {
  const taxValue = get(service, 'taxValue', '')
  const taxValueType = get(service, 'taxValueType', '')
  return getPriceWithSymbol(taxValueType, taxValue)
}

const getValue = service => {
  const value = get(service, 'value', '')
  if (value === '') {
    return ``
  }

  const valueType = get(service, 'valueType', '')
  return getPriceWithSymbol(valueType, value)
}

export const getServiceName = (service, column, tab) => {
  switch (tab) {
    case 'tax':
      return getTaxValues(column, service)
    case 'generalFee':
      return getGeneralFeeValues(column, service)
    case 'disposalFee':
      return getDisposalFeeValues(column, service)
    case 'service':
      return getServiceValues(column, service)
    default:
      return
  }
}
