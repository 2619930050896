import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'
import Constants from 'Constants'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { addAssetsImageToMap, addSourceAsync, getBounds } from 'utils/map'
import PropTypes from 'prop-types'
import ToBeInsertedMarker from 'assets/map/ToBeInsertedMarker.png'
import CanceledMarker from 'assets/map/CanceledMarker.png'
import HoldMarker from 'assets/map/HoldMarker.png'
import InProgressMarker from 'assets/map/InProgressMarker.png'
import ScheduledMarker from 'assets/map/ScheduledMarker.png'
import ServicedMarker from 'assets/map/ServicedMarker.png'
import SkippedMarker from 'assets/map/SkippedMarker.png'
import ContainerMarker from 'assets/map/ContainerMarker.png'
import { common } from '@mui/material/colors'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import mapboxgl from '!mapbox-gl'

const { MEDIA_SERVER } = Constants
const { LIGHT_VIEW, MAP_VIEW_URL, SATELLITE_STREET_VIEW } = MAP_DEFAULT_OPTIONS

const StyledImageButton = styled('img')({
  height: 70,
  cursor: 'pointer',
})

const RoutingMap = ({ latitude, longitude, features = [] }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [isStreetView, setIsStreetView] = useState(true)
  const userInfo = useSelector(s => s.AuthReducer.userInfo)
  mapboxgl.accessToken = get(userInfo, 'mapBoxToken')

  const loadMapContent = () => {
    const markerCoordinates = latitude && longitude && [longitude, latitude]
    addAssetsImageToMap(map.current, 'canceled-marker', CanceledMarker)
    addAssetsImageToMap(map.current, 'hold-marker', HoldMarker)
    addAssetsImageToMap(map.current, 'in-progress-marker', InProgressMarker)
    addAssetsImageToMap(map.current, 'scheduled-marker', ScheduledMarker)
    addAssetsImageToMap(map.current, 'serviced-marker', ServicedMarker)
    addAssetsImageToMap(map.current, 'skipped-marker', SkippedMarker)
    addAssetsImageToMap(map.current, 'to-be-assigned-marker', ToBeInsertedMarker)
    addAssetsImageToMap(map.current, 'container-marker', ContainerMarker)
    addSourceAsync(map.current, 'marker-source', { type: 'FeatureCollection', features }, { type: 'geojson' }).then(() => {
      map.current.addLayer({
        id: 'marker-layer',
        source: 'marker-source',
        type: 'symbol',
        layout: {
          'icon-image': ['get', 'icon'],
        },
      })

      map.current.addLayer({
        id: 'position-layer',
        type: 'symbol',
        source: 'marker-source',
        layout: {
          'icon-image': ['get', 'icon'],
          'text-field': ['get', 'position'],
          'text-anchor': 'top',
          'icon-offset': [0, -50],
          'text-offset': [0, -3.45],
          'icon-size': 0.55,
          'text-size': 12,
        },
        paint: {
          'text-color': common.black,
        },
      })
    })
    const coordinates = features.map(feature => feature.geometry.coordinates)

    if (Array.isArray(coordinates) && coordinates.length > 1) {
      const bounds = getBounds(coordinates)
      map.current.fitBounds(bounds, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
        linear: true,
      })
    } else if (Array.isArray(coordinates) && coordinates.length === 1) {
      map.current.flyTo({
        center: markerCoordinates,
        zoom: 18,
        speed: 2,
        essential: false,
      })
    }
  }

  useEffect(() => {
    if (!map.current) {
      const initialCoords = [-98.5795, 39.82835]
      const initialZoom = 3.6

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `${MAP_VIEW_URL}${isStreetView ? LIGHT_VIEW : SATELLITE_STREET_VIEW}`,
        center: initialCoords,
        zoom: initialZoom,
      })

      map.current.on('load', () => {
        loadMapContent()
      })

      map.current.on('style.load', () => {
        loadMapContent()
      })

      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right')
    }
  }, [])

  useEffect(() => {
    if (map.current) {
      map.current.setStyle(`${MAP_VIEW_URL}${isStreetView ? LIGHT_VIEW : SATELLITE_STREET_VIEW}`)
    }
  }, [isStreetView])

  const toggleMapStyle = () => {
    setIsStreetView(!isStreetView)
  }

  useEffect(() => {
    if (map.current) {
      map.current.on('idle', () => {
        map.current.resize()
      })
    }
  }, [map.current])

  return (
    <Box height="250px" width="100%">
      <Box position="relative" onClick={e => e.stopPropagation()}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" position="absolute" top={6} right={6} zIndex={1}>
          <StyledImageButton src={`${MEDIA_SERVER}${isStreetView ? 'SatelliteView.png' : 'StreetView.png'}`} onClick={toggleMapStyle} />
        </Box>
      </Box>
      <Box ref={mapContainer} height="250px" width="100%" />
    </Box>
  )
}

RoutingMap.propTypes = {
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  features: PropTypes.array,
}

export default RoutingMap
