import React, { useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'

import T from 'T'
import AddEditAccountGroupDialog from './AddEditAccountGroupDialog'

const AccountGroupHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const [isOpenAddEditAccountGroupDialog, setIsOpenAddEditAccountGroupDialog] = useState(false)

  const handleDrawerToggle = () => dispatch(setShowCustomerSideNav(!showCustomerSideNav))

  return (
    <>
      <Grid
        container
        pl={showCustomerSideNav ? 3 : 1.5}
        pr={3}
        minHeight={56}
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        bgcolor={theme.background.default}
      >
        <Grid item xs display="flex" alignItems="center">
          {!showCustomerSideNav && (
            <IconButton onClick={handleDrawerToggle}>
              <ReorderIcon />
            </IconButton>
          )}
          <Typography variant="h4" fontWeight={600}>
            {T.ACCOUNT_GROUPS}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <IconButton onClick={() => setIsOpenAddEditAccountGroupDialog(true)}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <AddEditAccountGroupDialog isOpen={isOpenAddEditAccountGroupDialog} onClose={() => setIsOpenAddEditAccountGroupDialog(false)} />
    </>
  )
}

export default AccountGroupHeader
