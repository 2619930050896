import { parse, format } from 'date-fns'
// eslint-disable-next-line import/no-cycle
import {
  convertToUtc,
  getDateRangeForLastMonth,
  getDateRangeForLastWeek,
  getDateRangeForPastTwoWeeks,
  getDateRangeForThisMonth,
  getDateRangeForThisWeek,
  getDateRangeForYesterday,
  isNotInvalidDate,
} from 'utils/date'

const parseToDateObj = (rawDate, isDateTime = true) => {
  try {
    if (isNotInvalidDate(rawDate)) {
      return rawDate
    }
    return parse(rawDate, isDateTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd', new Date())
  } catch (e) {
    return null // Return null if parsing fails
  }
}

const dateToPayloadFormatWithParse = (date, isDateTime = true) => {
  try {
    const parsedDate = parse(date, isDateTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd', new Date())
    return format(parsedDate, isDateTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
  } catch {
    return format(date, isDateTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
  }
}

const dateToPayloadFormatWithoutParse = (date, isDateTime = true) => {
  return format(date, isDateTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
}

const getDateRange = keyword => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0) // Set time to midnight

  switch (keyword) {
    case 'TODAY':
      // eslint-disable-next-line no-case-declarations
      const nextDate = new Date()
      nextDate.setHours(23, 59, 59, 59)
      return [currentDate, nextDate]
    case 'YESTERDAY':
      return getDateRangeForYesterday(currentDate)
    case 'THIS_WEEK':
      return getDateRangeForThisWeek(currentDate)
    case 'LAST_WEEK':
      return getDateRangeForLastWeek(currentDate)
    case 'PAST_TWO_WEEK':
      return getDateRangeForPastTwoWeeks(currentDate)
    case 'THIS_MONTH':
      return getDateRangeForThisMonth(currentDate)
    case 'LAST_MONTH':
      return getDateRangeForLastMonth(currentDate)
    default:
      return [currentDate, currentDate]
  }
}

const checkIsDateTimeFilter = columnName => {
  const dateTimeColumns = ['transactionPostedDate', 'sentDate', 'postDate', 'sentTime']
  return dateTimeColumns.includes(columnName)
}

const checkIsDateKeyword = columnValue => {
  const dateKeywords = ['TODAY', 'YESTERDAY', 'THIS_WEEK', 'LAST_WEEK', 'PAST_TWO_WEEK', 'THIS_MONTH', 'LAST_MONTH']
  return dateKeywords.includes(columnValue)
}

const checkIsDateRangeKeyword = columnValue => {
  const dateRangeKeywords = ['THIS_WEEK', 'LAST_WEEK', 'PAST_TWO_WEEK', 'THIS_MONTH', 'LAST_MONTH']
  return dateRangeKeywords.includes(columnValue)
}

const checkIsOnlyDateFilter = columnName => {
  const onlyDateColumns = ['invoiceDate', 'transactionDate', 'paymentDate']
  return onlyDateColumns.includes(columnName)
}

export function fixDateFilters(filters) {
  if (!filters) return filters
  return filters.map(filter => {
    if (!filter.columnValue) {
      return filter
    }
    const isDateKeyword = checkIsDateKeyword(filter.columnValue)
    const isDateRangeKeyword = checkIsDateRangeKeyword(filter.columnValue)
    const isDateTimeFilter = checkIsDateTimeFilter(filter.columnName)
    const isOnlyDateFilter = checkIsOnlyDateFilter(filter.columnName)

    if (isDateKeyword && isDateTimeFilter) {
      const [startDate, endDate] = getDateRange(filter.columnValue)
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 59, 59)
      return {
        ...filter,
        operation: 'IS_BETWEEN',
        columnValue: `${dateToPayloadFormatWithParse(startDate, true)}#${dateToPayloadFormatWithParse(endDate, true)}`,
      }
    }
    if (isDateRangeKeyword && isOnlyDateFilter) {
      const [startDate, endDate] = getDateRange(filter.columnValue)
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)
      return {
        ...filter,
        operation: 'IS_BETWEEN',
        columnValue: `${dateToPayloadFormatWithParse(startDate, false)}#${dateToPayloadFormatWithParse(endDate, false)}`,
      }
    }

    if (isDateTimeFilter && typeof filter.columnValue === 'string') {
      const rawDateRange = filter.columnValue.split('#')
      const dateRange = rawDateRange.map(date => parseToDateObj(date, isDateTimeFilter))
      if (dateRange.length === 2) {
        const [startDateTime, endDateTime] = dateRange
        return {
          ...filter,
          columnValue: `${dateToPayloadFormatWithParse(startDateTime, isDateTimeFilter)}#${dateToPayloadFormatWithParse(
            endDateTime,
            isDateTimeFilter
          )}`,
        }
      }
      if (dateRange.length === 1) {
        return {
          ...filter,
          columnValue: dateToPayloadFormatWithParse(dateRange[0], true),
        }
      }
    }
    return filter
  })
}

export function fixTimezoneForDateFilters(filters, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
  if (!filters) return filters
  return filters.map(filter => {
    if (!filter.columnValue) {
      return filter
    }
    const isDateTimeFilter = ['transactionPostedDate', 'sentDate', 'postDate', 'sentTime'].includes(filter.columnName)
    const isDateKeyword = ['TODAY', 'YESTERDAY', 'THIS_WEEK', 'LAST_WEEK', 'PAST_TWO_WEEK', 'THIS_MONTH', 'LAST_MONTH'].includes(
      filter.columnValue
    )
    const isDateRangeKeyword = ['THIS_WEEK', 'LAST_WEEK', 'PAST_TWO_WEEK', 'THIS_MONTH', 'LAST_MONTH'].includes(filter.columnValue)
    const isOnlyDateFilter = ['invoiceDate', 'transactionDate', 'paymentDate'].includes(filter.columnName)

    if (isDateKeyword && isDateTimeFilter) {
      return filter
    }
    if (isDateRangeKeyword && isOnlyDateFilter) {
      return filter
    }

    if (isDateTimeFilter && typeof filter.columnValue === 'string') {
      const rawDateRange = filter.columnValue.split('#')
      const dateRange = rawDateRange.map(date => convertToUtc(date, timeZone))
      if (dateRange.length === 2) {
        const [startDateTime, endDateTime] = dateRange
        return {
          ...filter,
          columnValue: `${dateToPayloadFormatWithParse(startDateTime, isDateTimeFilter)}#${dateToPayloadFormatWithParse(
            endDateTime,
            isDateTimeFilter
          )}`,
        }
      }
      if (dateRange.length === 1) {
        return {
          ...filter,
          columnValue: dateToPayloadFormatWithParse(dateRange[0], true),
        }
      }
    }
    return filter
  })
}
