import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import orderBy from 'lodash/orderBy'

import { useSelector, shallowEqual } from 'react-redux'
import { Typography, Grid, Link, Divider, List, ListItem, Chip, IconButton, SvgIcon, useTheme, Stack } from '@mui/material'
import { ReactComponent as PaperPlane } from 'assets/PaperPlane.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { CONTACT_METHODS } from 'settings/constants/contacts'
import { useLazyGetPortalUsersQuery } from 'api/customer-portal/getPortalUsers'

import HHAccordion from 'components/common/HHAccordion'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'

import T from 'T'
import { MoreVert } from '@mui/icons-material'
import CustomerPortalActionMenu from 'components/customer-details/content/customer-portal/CustomerPortalActionMenu'
import EventBlocker from 'components/common/EventBlocker'
import ContactMethodIcon from '../contacts/ContactMethodIcon'
import CustomerPortalContactsActionMenu from './CustomerPortalContactsActionMenu'
import AddCustomerPortalUserDialog from './add-portal-user/AddCustomerPortalUserDialog'

const { EMAIL } = CONTACT_METHODS

const CustomerPortal = ({ accountId }) => {
  const theme = useTheme()
  const [customerPortalActionMenuAnchorEl, setCustomerPortalActionMenuAnchorEl] = useState(null)
  const [getPortalUsers, { data }] = useLazyGetPortalUsersQuery()
  const [selectedPortalContact, setSelectedPortalContact] = useState()
  const [anchorEl, setAnchorEl] = useState()
  const [isOpenAddPortalUserDialog, setIsOpenAddPortalUserDialog] = useState(false)
  const allContacts = orderBy(get(data, 'portalUsers', []), ['confirmed'], ['desc'])
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)

  const handleOpenCustomerPortalActionMenu = e => setCustomerPortalActionMenuAnchorEl(e.currentTarget)
  const handleCloseCustomerPortalActionMenu = () => setCustomerPortalActionMenuAnchorEl(null)

  const handleOpenActionMenu = e => setAnchorEl(e.currentTarget)

  const handleCloseActionMenu = () => setAnchorEl(null)

  const handleOpenAddPortalUser = () => {
    setIsOpenAddPortalUserDialog(true)
  }

  const handleCloseAddPortalUser = () => setIsOpenAddPortalUserDialog(false)

  const handleActionsMenuButtonClick = (event, contact) => {
    handleOpenActionMenu(event)
    setSelectedPortalContact(contact)
  }

  useEffect(() => {
    if (accountId) {
      getPortalUsers({ accountId }).unwrap().catch(handleError)
    }
  }, [accountId])

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={capitalize(T.CUSTOMER_PORTAL)}
        showActionIcon={false}
        showCustomActions
        customActionContent={
          <EventBlocker>
            <Stack direction="row" gap={0.5}>
              <IconButton onClick={handleOpenAddPortalUser}>
                <SvgIcon>
                  <PaperPlane />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={handleOpenCustomerPortalActionMenu}>
                <MoreVert />
              </IconButton>
            </Stack>
            <CustomerPortalActionMenu
              accountId={accountId}
              anchorEl={customerPortalActionMenuAnchorEl}
              onClose={handleCloseCustomerPortalActionMenu}
            />
          </EventBlocker>
        }
      >
        {allContacts.length === 0 && (
          <HHSectionPlaceholder
            sx={{
              borderColor: theme.palette.primary.light,
              borderWidth: 1.5,
              borderStyle: 'solid',
            }}
            title={
              <Typography color="textPrimary" variant="h6" fontWeight={400} display="flex" alignItems="center">
                Click
                <SvgIcon color="textPrimary" fontSize="small">
                  <PaperPlane />
                </SvgIcon>
                above to invite a new user
              </Typography>
            }
          />
        )}

        {allContacts.length > 0 && (
          <List disablePadding sx={{ mt: 1 }}>
            {Children.toArray(
              allContacts.map((contact, index) => {
                return (
                  <>
                    <ListItem
                      sx={{ p: 2 }}
                      secondaryAction={
                        <IconButton onClick={event => handleActionsMenuButtonClick(event, contact)}>
                          <MoreVert />
                        </IconButton>
                      }
                    >
                      <Grid container rowGap={0.5}>
                        {!contact?.confirmed && (
                          <Grid item xs={12}>
                            <Chip size="small" variant="outlined" color="error" label="Pending" />
                          </Grid>
                        )}
                        <Grid container wrap="nowrap" display="flex" alignItems="center" spacing={0.5}>
                          <Grid item display="flex">
                            <ContactMethodIcon type={EMAIL} />
                          </Grid>
                          <Grid item xs>
                            <Typography>
                              <Link href={`mailto:${contact.email}`} color="text.primary" underline="none">
                                {contact.email}
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>

                    {allContacts.length - 1 !== index && <Divider />}
                  </>
                )
              })
            )}
          </List>
        )}
        <CustomerPortalContactsActionMenu
          onClose={handleCloseActionMenu}
          selectedContact={selectedPortalContact}
          anchorEl={anchorEl}
          accountId={accountId}
        />
      </HHAccordion>
      <AddCustomerPortalUserDialog
        isOpen={isOpenAddPortalUserDialog}
        accountId={accountId}
        contacts={get(customerDetails, 'contacts', [])}
        portalUserList={allContacts}
        onClose={handleCloseAddPortalUser}
      />
    </>
  )
}

CustomerPortal.propTypes = {
  accountId: PropTypes.string,
}

export default CustomerPortal
