import React from 'react'
import Grid from '@mui/material/Grid'
import T from 'T'
import SummaryCard from 'components/billing/groups/common/SummaryCard'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PropTypes from 'prop-types'
import { Skeleton } from '@mui/material'

const Summary = ({ accountCount, lateInvoicesCount = 0, loading = false }) => {
  return (
    <Grid container columnSpacing={2.5} spacing={2.5}>
      <Grid item xs={12} sm={6} lg={6}>
        <SummaryCard
          label="Accounts"
          value={
            loading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={accountCount} formatToDollars={false} prefix="" decimalScale={0} />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <SummaryCard
          label={T.LATE_INVOICES}
          value={
            loading ? (
              <Skeleton width="3rem" />
            ) : (
              <HHDisplayMoney value={lateInvoicesCount} formatToDollars={false} prefix="" decimalScale={0} />
            )
          }
        />
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  accountCount: PropTypes.number,
  lateInvoicesCount: PropTypes.number,
  loading: PropTypes.bool,
}

export default Summary
