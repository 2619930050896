import React, { useReducer, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import { Button, Typography, Box } from '@mui/material'
import 'components/settings/pages/users/style.scss'
import { handleError } from 'utils/error'
import { get } from 'utils/lodash'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { isAlphaNumeric } from 'utils/validations'
import { useAddTaxZoneMutation } from 'api/settings/addTaxZone'
import { useLazyGetTaxZoneListQuery } from 'api/settings/getTaxZoneList'
import T from 'T'
import ZonesTable from './ZonesTable'

const TaxZoneSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  const [taxZoneState, setTaxZoneState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    taxZones: [],
    onPlaceTaxZoneEdit: {},
    default: false,
    defaultId: '',
  })

  const [getTaxZoneList, { isLoading, isFetching }] = useLazyGetTaxZoneListQuery()
  const [addTaxZones, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddTaxZoneMutation()

  const { taxZones, onPlaceTaxZoneEdit, defaultId } = taxZoneState

  const fetchTaxZones = () => {
    getTaxZoneList()
      .unwrap()
      .then(res => {
        const zones = get(res, 'zone', [])
        const selectedValue = zones.find(data => data.default)
        setTaxZoneState({
          taxZones: zones.map(data => ({
            active: data.active,
            customerCount: data.customerCount,
            zoneId: data.id,
            zoneName: data.zoneName,
            default: data.default,
          })),
          defaultId: selectedValue ? selectedValue.id : '',
        })
      })
      .catch(() => {
        setTaxZoneState({
          taxZones: [],
          defaultId: '',
        })
      })
  }

  useEffect(() => {
    fetchTaxZones()
  }, [])

  const handleOnPlaceEdit = index => {
    if (updateSettings) {
      if (Object.keys(onPlaceTaxZoneEdit).length === 0) {
        onPlaceTaxZoneEdit[index] = false
      }

      onPlaceTaxZoneEdit[index] = !onPlaceTaxZoneEdit[index]
      setTaxZoneState({ onPlaceTaxZoneEdit })
    }
  }

  const handleSave = () => {
    const payload = {
      zones: taxZones.filter(data => data.zoneName !== ''),
    }

    setTaxZoneState({ onPlaceTaxZoneEdit: {} })
    addTaxZones(payload)
      .unwrap()
      .then(() => fetchTaxZones())
      .catch(handleError)
  }

  const handleZoneSwitchVal = (value, index) => {
    if (updateSettings) {
      taxZones[index].active = value

      setTaxZoneState({ taxZones })
      handleSave()
    }
  }

  const onHandleChange = (e, index) => {
    let { value } = e.target

    if (/^\s/.test(value)) {
      value = ''
    }

    if (!isAlphaNumeric(value)) return

    taxZones[index].zoneName = value

    setTaxZoneState({ taxZones })
  }

  const handleZoneRemoval = index => {
    taxZones.splice(index, 1)

    const selectedEntry = taxZones.find(taxZone => taxZone.zoneId === defaultId)

    if (selectedEntry) selectedEntry.default = true

    setTaxZoneState({ taxZones })
  }

  const addRows = () => {
    taxZones.push({
      active: true,
      default: false,
      zoneId: '',
      zoneName: '',
    })

    onPlaceTaxZoneEdit[taxZones.length - 1] = true
    setTaxZoneState({ taxZones, onPlaceTaxZoneEdit })
  }

  const handleDefaultChange = (event, index) => {
    const alreadySelected = taxZones.find(zone => zone.default)

    if (alreadySelected) alreadySelected.default = false

    taxZones[index].default = event.target.checked
    setTaxZoneState({ taxZones })

    if (taxZones[index].zoneName) handleSave()
  }

  return (
    <Box>
      <Box px={4} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{`${T.TAX_ZONE}s`}</Typography>
        {addSettings && (
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => addRows()}>
            {T.ADD}
          </Button>
        )}
      </Box>

      <ZonesTable
        type="tax"
        zones={taxZones}
        onPlaceEdit={onPlaceTaxZoneEdit}
        isLoading={isLoading || isFetching || isAddLoading || isAddFetching}
        onHandleChange={onHandleChange}
        handleSave={handleSave}
        handleZoneSwitchVal={handleZoneSwitchVal}
        handleZoneRemoval={handleZoneRemoval}
        handleOnPlaceEdit={handleOnPlaceEdit}
        handleDefaultChange={handleDefaultChange}
      />
    </Box>
  )
}
export default TaxZoneSettings
