import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem, ListItemText, Tooltip } from '@mui/material'
import { noop } from 'lodash'
import { useArchiveLateFeeMutation, useRestoreLateFeeMutation } from 'api/pricing/lateFeesCrud'
import CreateEditLateFeesDialog from 'components/pricing/LateFees/CreateEditLateFeesDialog/CreateEditLateFeesDialog'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import T from 'T'
import { toast } from 'react-toastify'
import {
  openBulkLinkLateFeeDialog,
  openBulkUnlinkLateFeeDialog,
  setArchivedRestoredLateFee,
  setSelectedLateFee,
} from 'slices/pricing/lateFeesSlice'
import { useDispatch } from 'react-redux'

const LateFeesActionMenu = ({ anchorEl = null, onClose = noop, lateFee }) => {
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { archived, id, accountCount = 0 } = lateFee || {}
  const isEmptyLateFee = accountCount === 0
  const [archiveLateFee, { isLoading: isLoadingArchivedLateFee }] = useArchiveLateFeeMutation()
  const [restoreLateFee] = useRestoreLateFeeMutation()
  const [isUpdateLateFeeOpen, setIsUpdateLateFeeOpen] = useState(false)
  const [isOpenConfirmArchiveLateFee, setIsOpenConfirmArchiveLateFee] = useState(false)

  const handleCloseConfirmArchiveLateFeeDialog = () => {
    setIsOpenConfirmArchiveLateFee(false)
  }

  const handleConfirmArchiveLateFee = async () => {
    archiveLateFee({
      id,
    })
      .unwrap()
      .then(() => {
        toast.success(T.LATE_FEE_ARCHIVED_SUCCESSFULLY)
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setArchivedRestoredLateFee(lateFee))
          handleCloseConfirmArchiveLateFeeDialog()
        }, 300)
      })
  }

  const handleCloseUpdateLateFeeDialog = () => {
    setIsUpdateLateFeeOpen(false)
  }

  const handleEdit = () => {
    setIsUpdateLateFeeOpen(true)
    onClose()
  }

  const handleAddAccounts = () => {
    dispatch(setSelectedLateFee(lateFee))
    dispatch(openBulkLinkLateFeeDialog())
    onClose()
  }

  const handleRemoveAccounts = () => {
    dispatch(setSelectedLateFee(lateFee))
    dispatch(openBulkUnlinkLateFeeDialog())
    onClose()
  }

  const handleArchiveRestore = async () => {
    if (archived) {
      await restoreLateFee({
        id,
      })
        .unwrap()
        .then(() => {
          toast.success(T.LATE_FEE_RESTORED_SUCCESSFULLY)
          setTimeout(() => {
            dispatch(setArchivedRestoredLateFee(lateFee))
            handleCloseConfirmArchiveLateFeeDialog()
          }, 300)
        })
    } else {
      setIsOpenConfirmArchiveLateFee(true)
    }
    onClose()
  }

  return (
    <>
      <Menu
        id="late-fees-action-menu"
        slotProps={{
          paper: {
            sx: {
              border: '1px solid',
              borderColor: 'divider',
            },
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddAccounts}>
          <ListItemText>Add accounts</ListItemText>
        </MenuItem>
        <Tooltip arrow title={T.EMPTY_LATE_FEE} disableHoverListener={!isEmptyLateFee} disableInteractive>
          <span>
            <MenuItem disabled={isEmptyLateFee} onClick={handleRemoveAccounts}>
              <ListItemText>Remove accounts</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          arrow
          title={T.REMOVE_CUSTOMERS_BEFORE_ARCHIVE_LATE_FEE}
          disableHoverListener={!(!isEmptyLateFee && !archived)}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!isEmptyLateFee && !archived} onClick={handleArchiveRestore}>
              <ListItemText
                sx={{ color: archived ? 'success.main' : 'warning.main', ...(!isEmptyLateFee && !archived && { color: undefined }) }}
              >
                {archived ? 'Restore' : 'Archive'}
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs', fullWidth: true, PaperProps: { sx: { maxWidth: '375px' } } }}
        isOpen={isOpenConfirmArchiveLateFee}
        onClose={handleCloseConfirmArchiveLateFeeDialog}
        confirmTitle={T.ARCHIVE_LATE_FEE}
        confirmDescription={T.ARCHIVE_LATE_FEE_CONFIRM_MSG}
        onConfirm={handleConfirmArchiveLateFee}
        confirmButtonTitle={T.ARCHIVE}
        ConfirmButtonProps={{
          color: 'warning',
          loading: isLoadingArchivedLateFee,
          disabled: isLoadingArchivedLateFee,
          loadingPosition: 'center',
        }}
      />
      {isUpdateLateFeeOpen && (
        <CreateEditLateFeesDialog open={isUpdateLateFeeOpen} onClose={handleCloseUpdateLateFeeDialog} id={id} archived={archived} />
      )}
    </>
  )
}

LateFeesActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Element)]),
  onClose: PropTypes.func,
  lateFee: PropTypes.object,
}

export default LateFeesActionMenu
