import { cloneDeep } from 'lodash'
import { splitAddress } from '../parse-address-string'

export const addHashIfNeeded = inputString => {
  if (/^[0-9-\s]+$/.test(inputString) && !inputString.startsWith('#')) {
    return `#${inputString}`
  }
  return inputString
}

export const parseAddress = addressStr => {
  try {
    const { street_address1: line1, city, state, postal_code: zipCode } = splitAddress(cloneDeep(addressStr))

    return { line1, city, state, zipCode }
  } catch (error) {
    return { line1: null, city: null, state: null, zipCode: null }
  }
}

export const filterValidPO = poList => poList.filter(po => po.purchaseOrderNumber)

export const getWorkOrderPO = (workOrderNumber, purchaseOrderNumber) => `${workOrderNumber} | PO: ${purchaseOrderNumber}`
