import React from 'react'
import { ListItem, ListItemIcon, ListItemText, Paper, useTheme } from '@mui/material'
import Box from '@mui/material/Box'

const RenderTooltip = params => {
  const theme = useTheme()
  const { value, color, data } = params
  const { label } = data

  return (
    <Paper sx={{ zIndex: theme.zIndex.drawer * 6 }}>
      <ListItem dense sx={{ p: 1 }} disablePadding disableGutters>
        <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
          <Box backgroundColor={color} height={12} width={12} />
        </ListItemIcon>
        <ListItemText primary={`${label}: ${value}`} />
      </ListItem>
    </Paper>
  )
}

RenderTooltip.propTypes = {}

export default RenderTooltip
