import React from 'react'
import PropTypes from 'prop-types'

import { Box, Popover, Typography } from '@mui/material'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import { ReactComponent as InvoiceIcon } from 'assets/Invoice.svg'

import { memo } from 'utils/react'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import AgingBucketContent from '../common/AgingBucketContent'

const AgingPopover = ({ anchorEl, row = {}, onClose }) => {
  const open = Boolean(anchorEl)

  const { unpaidInvoiceCount = 0, balanceCents = 0, autopay } = row

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      slotProps={{ paper: { sx: { minWidth: 600 } } }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="caption" display="flex" alignItems="center" gap={0.5}>
            <InvoiceIcon width={14} height={14} color="action" />
            {`Invoices | ${unpaidInvoiceCount} unpaid | `}
            <HHDisplayMoney value={balanceCents} />
          </Typography>
          {autopay && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <CheckCircleOutlinedIcon color="action" sx={{ width: 14, height: 14 }} />
              <Typography variant="caption">Autopay</Typography>
            </Box>
          )}
        </Box>

        <AgingBucketContent mt={1} isInteractive={false} {...row} />
      </Box>
    </Popover>
  )
}

AgingPopover.propTypes = {
  anchorEl: PropTypes.object,
  row: PropTypes.object,
  onClose: PropTypes.func,
}

export default memo(AgingPopover)
