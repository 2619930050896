import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material'
import Highlighter from 'react-highlight-words'
import { get } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import { useSelector } from 'react-redux'
import { grey } from '@mui/material/colors'
import { extractNameInitials } from '../../../utils/string'
import { isValidHex } from '../../../utils/validations'
import { getMetadataRoutes } from '../../../data/route/routeMetadataSelector'
import HighlightPrimaryTag from './HighlightPrimaryTag'

const SearchItem = ({ registerChild, style, dataSet }) => {
  const theme = useTheme()
  const allRoutes = useSelector(getMetadataRoutes)
  const routeNameToColorMap = allRoutes.reduce((acc, obj) => {
    const routeName = get(obj, 'name')
    const color = get(obj, 'color')
    acc[routeName] = color
    return acc
  }, {})
  const [props, data, inputValue] = dataSet
  const accountName = get(data, 'accountName')
  const routeName = get(data, 'routeName')
  const address = get(data, 'address')
  const key = [accountName, routeName, address].join()
  const initials = extractNameInitials(routeName)
  const bgcolor = get(routeNameToColorMap, routeName, grey[100])
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const color = theme.palette.getContrastText(formattedBgcolor)
  return (
    <MenuItem key={key} {...props} divider ref={registerChild} style={style}>
      <ListItemAvatar sx={{ minWidth: '40px' }}>
        <Avatar
          sx={{
            width: '32px',
            height: '32px',
            bgcolor,
            color,
          }}
        >
          <Typography variant="h5" fontWeight={400}>
            {initials}
          </Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            <Typography component="span" fontWeight={500}>
              <Highlighter
                highlightClassName="searched-text"
                searchWords={[inputValue]}
                autoEscape
                textToHighlight={accountName}
                highlightTag={({ children }) => (
                  <Typography component="span" color="primary">
                    {children}
                  </Typography>
                )}
              />
            </Typography>
            <Typography component="span">&nbsp;|&nbsp;</Typography>
            <Highlighter
              highlightClassName="searched-text"
              searchWords={[inputValue]}
              autoEscape
              textToHighlight={routeName}
              highlightTag={HighlightPrimaryTag}
            />
          </Typography>
        }
        secondary={
          <Typography color="textSecondary">
            <Highlighter
              highlightClassName="searched-text"
              searchWords={[inputValue]}
              autoEscape
              textToHighlight={address}
              highlightTag={HighlightPrimaryTag}
            />
          </Typography>
        }
      />
    </MenuItem>
  )
}

SearchItem.propTypes = {
  registerChild: PropTypes.func,
  style: PropTypes.object,
  dataSet: PropTypes.object,
}

export default SearchItem
