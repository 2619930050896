import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { get } from 'lodash'
import RenderFromStopListItem from 'components/route-manager/BulkActions/common/StopListItem/RenderFromStopListItem'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useDispatch } from 'react-redux'
import {
  deserializeDate,
  formatDateToBEFormatDateFns,
  formatDateToBulkMoveStr,
  formatDateToFEFormatDateFns,
  serializeDate,
} from 'utils/date'
import { mapStopToListItemFormat } from 'utils/route'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import DateColumnTitle from 'components/route-manager/BulkActions/common/DateColumnTitle'
import { isSameDay } from 'date-fns'
import { MapContext } from 'providers/MapProvider'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'
import { removeLayersAndSources } from 'components/route-manager/BulkActions/settings'
import useParseDaysOfWeekBetweenDaysVariant from 'components/route-manager/BulkActions/common/BetweenDays/useParseDaysOfWeekBetweenDaysVariant'

const CommonMoveFromColumnBetweenDays = ({
  apiRef,
  bulkReduxAction,
  fetchAndFormatGeojsonData,
  fetchId,
  fromSerializedDate,
  moveFromRows,
  routeId,
  selectedMoveFromRows,
  selectedMoveToRows,
  setSelectedMoveFromRows,
  stopsToBeAssignedCount,
  toSerializedDate,
  isRowSelectable,
  RenderMoveFromHeader,
  RenderCheckboxCell,
  RenderCheckboxHeaderCell,
  getRowClassName,
}) => {
  const dispatch = useDispatch()
  const map = useContext(MapContext)
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const isMoveFromDateButtonDisabled = stopsToBeAssignedCount !== 0 || selectedMoveFromRows.length !== 0 || selectedMoveToRows.length !== 0
  const toDate = toSerializedDate ? deserializeDate(toSerializedDate) : null
  const fromDate = fromSerializedDate ? deserializeDate(fromSerializedDate) : new Date()
  const fromFEDate = fromDate ? formatDateToFEFormatDateFns(fromDate) : null
  const fromDateTitleFormat = fromDate ? formatDateToBulkMoveStr(fromDate) : ''
  const parseDaysOfWeek = useParseDaysOfWeekBetweenDaysVariant(fromDate, fromSerializedDate, toSerializedDate)
  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
      ...(RenderCheckboxCell && { renderCell: RenderCheckboxCell }),
      ...(RenderCheckboxHeaderCell && { renderHeaderCell: RenderCheckboxHeaderCell }),
    },
    {
      field: 'id',
      renderCell: RenderFromStopListItem,
      renderHeader: RenderMoveFromHeader,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]

  const handleSelectionModelChange = selectionModel => {
    dispatch(setSelectedMoveFromRows(selectionModel))
  }

  const handleFromDateChange = date => {
    if (date && fromDate && !isSameDay(date, fromDate)) {
      removeLayersAndSources(map.current, formatDateToFEFormatDateFns(date))
    }
    dispatch(bulkReduxAction({ fromSerializedDate: serializeDate(date) }))
    fetchAndFormatGeojsonData({
      routeId,
      date,
      markersStateKey: 'moveFromRouteStopMarkers',
      sequenceLineStateKey: 'moveFromRouteSequenceLine',
    })
  }

  const shouldDisableFromDate = useCallback(
    date => {
      if (toDate) {
        return isSameDay(date, toDate)
      }
      return false
    },
    [toDate]
  )

  useEffect(() => {
    if (routeId) {
      getRouteStops(
        {
          routes: [routeId],
          serviceDate: formatDateToBEFormatDateFns(fromDate),
          id: fetchId,
        },
        false
      )
        .unwrap()
        .then(data => {
          const stops = get(data, `routes.0.stops`, [])
          const formattedStops = stops.map((stop, index) => {
            const daysOfWeek = get(stop, 'daysOfWeek', '')
            const parsedStopData = mapStopToListItemFormat(stop)
            const pricingPeriod = get(parsedStopData, 'pricingPeriod')
            const parsedDaysOfWeek = parseDaysOfWeek(daysOfWeek, pricingPeriod)
            return {
              ...parsedStopData,
              position: index,
              originalPosition: index,
              date: fromFEDate,
              parsedDaysOfWeek,
              daysOfWeek,
            }
          })
          dispatch(
            bulkReduxAction({
              moveFromRows: formattedStops,
            })
          )
        })
    }
  }, [routeId, fromSerializedDate, toSerializedDate, fetchId])

  return (
    <>
      <Box mx={2} mb={2}>
        <DateColumnTitle
          onChange={handleFromDateChange}
          title={fromDateTitleFormat}
          value={fromDate}
          shouldDisableDate={shouldDisableFromDate}
          disabled={isMoveFromDateButtonDisabled}
        />
      </Box>
      <Box flex={1} height="100%" width="100%" overflow="hidden">
        <BulkMoveStopsDataGridPro
          getRowClassName={getRowClassName}
          apiRef={apiRef}
          isRowSelectable={isRowSelectable}
          rowSelectionModel={selectedMoveFromRows}
          onRowSelectionModelChange={handleSelectionModelChange}
          loading={isLoading || isFetching}
          columns={columns}
          rows={moveFromRows}
        />
      </Box>
    </>
  )
}

CommonMoveFromColumnBetweenDays.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  bulkReduxAction: PropTypes.func.isRequired,
  fetchAndFormatGeojsonData: PropTypes.func.isRequired,
  fetchId: PropTypes.string.isRequired,
  fromSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]).isRequired,
  moveFromRows: PropTypes.array.isRequired,
  routeId: PropTypes.string.isRequired,
  selectedMoveFromRows: PropTypes.array.isRequired,
  selectedMoveToRows: PropTypes.array.isRequired,
  setSelectedMoveFromRows: PropTypes.func.isRequired,
  stopsToBeAssignedCount: PropTypes.number.isRequired,
  toSerializedDate: PropTypes.oneOf([PropTypes.string, PropTypes.bool]).isRequired,
  isRowSelectable: PropTypes.func.isRequired,
  RenderMoveFromHeader: PropTypes.node.isRequired,
  RenderCheckboxCell: PropTypes.node,
  RenderCheckboxHeaderCell: PropTypes.node,
  getRowClassName: PropTypes.func,
}

export default CommonMoveFromColumnBetweenDays
