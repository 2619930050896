import { format, utcToZonedTime } from 'date-fns-tz'
import { FRONTEND_DATE_FORMAT_DATE_FNS as DATE_FORMAT, FRONTEND_TIME_FORMAT_DATE_FNS as TIME_FORMAT } from 'settings/constants/date'
import { TIME_ZONES_SHORT_FORM as TIME_ZONES } from 'settings/constants/timeZones'

const DATE_TIME_FORMAT = `${DATE_FORMAT} @ ${TIME_FORMAT}`

const UTC = 'UTC'

export const getTimeZoneDetails = timeZone => {
  if (timeZone && timeZone in TIME_ZONES) {
    return { zoneName: TIME_ZONES[timeZone], timeZone }
  }

  return { zoneName: UTC, timeZone: UTC }
}

const isUTC = timeZone => !timeZone || timeZone.toUpperCase() === UTC

const castToDateTime = (dateTime, timeZone) => {
  try {
    if (isUTC(timeZone)) {
      // Replace ZULU identifier if exist to avoid local conversion
      return new Date(dateTime.replace('Z', ''))
    }

    // Adding ZULU (UTC) identifier if no UTC identifier exists on the timestamp
    return new Date(dateTime.includes('Z') ? dateTime : `${dateTime}Z`)
  } catch (error) {
    throw new Error()
  }
}

export const formatDateTimeToPreferedTimezone = (value, timeZone) => {
  try {
    if (!value) return { fullDateTime: '', onlyDate: '', onlyTime: '' }

    const newDate = castToDateTime(value, timeZone)

    if (isUTC(timeZone)) {
      return {
        fullDateTime: format(newDate, DATE_TIME_FORMAT),
        onlyDate: format(newDate, DATE_FORMAT),
        onlyTime: format(newDate, TIME_FORMAT),
      }
    }

    const zonedTime = utcToZonedTime(newDate, timeZone)
    return {
      fullDateTime: format(zonedTime, DATE_TIME_FORMAT, { timeZone }),
      onlyDate: format(zonedTime, DATE_FORMAT, { timeZone }),
      onlyTime: format(zonedTime, TIME_FORMAT, { timeZone }),
    }
  } catch (error) {
    return { fullDateTime: '', onlyDate: '', onlyTime: '' }
  }
}
