import React from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import { ReactComponent as Assign } from 'assets/route_manager/Assign.svg'
import { useDispatch } from 'react-redux'
import { setBulkAssignStopsState } from 'slices/route/bulkAssignStopsSlice'
import BulkMoveColumnHeader from 'components/route-manager/BulkActions/common/BulkMoveColumnHeader'
import { SvgIcon } from '@mui/material'
import T from 'T'

const RenderMoveFromHeader = () => {
  const apiRef = useGridApiContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const selectedRowsCount = get(selectedRowIdsMap, 'size', 0)
  const dispatch = useDispatch()
  const onMoveFromClick = () => {
    dispatch(
      setBulkAssignStopsState({
        isSequenceDialogOpen: true,
      })
    )
  }

  return (
    <BulkMoveColumnHeader
      onClick={onMoveFromClick}
      selectedRowsCount={selectedRowsCount}
      label={T.ASSIGN}
      endIcon={
        <SvgIcon inheritViewBox fontSize="medium">
          <Assign />
        </SvgIcon>
      }
    />
  )
}

RenderMoveFromHeader.propTypes = {}

export default RenderMoveFromHeader
