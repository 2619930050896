import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { blue, yellow, orange, red, lime } from '@mui/material/colors'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'

import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

import { useGetInvoiceGroupAgingBucketDataQuery } from 'api/groups/invoice/getInvoiceGroupAgingBucketData'
import { get } from 'lodash'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'
import { formatCentsToDollars, shortenMoneyValue } from '../../../../utils/price'
import GraphCardEmptyState from '../common/GraphCardEmptyState'
import T from '../../../../T'
import ChartLegend from '../common/ChartLegend'

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const COLOR_MAP = {
  [CURRENT]: blue[100],
  [DUE_ONE_THIRTY]: lime[100],
  [DUE_THIRTY_ONE_SIXTY]: yellow[100],
  [DUE_SIXTY_ONE_NINETY]: orange[100],
  [DUE_OVER_NINETY]: red[100],
}

const InvoiceAgingBucketStatistic = ({ id, showFooter = true }) => {
  const { data: agingBucketData, isSuccess } = useGetInvoiceGroupAgingBucketDataQuery({ id })
  const currentCents = get(agingBucketData, 'currentCents', 0)
  const dueOneThirtyCents = get(agingBucketData, 'dueOneThirtyCents', 0)
  const dueThirtyOneSixtyCents = get(agingBucketData, 'dueThirtyOneSixtyCents', 0)
  const dueSixtyOneNinetyCents = get(agingBucketData, 'dueSixtyOneNinetyCents', 0)
  const dueOverNinetyCents = get(agingBucketData, 'dueOverNinetyCents', 0)

  const current = formatCentsToDollars(currentCents)
  const dueOneThirty = formatCentsToDollars(dueOneThirtyCents)
  const dueThirtyOneSixty = formatCentsToDollars(dueThirtyOneSixtyCents)
  const dueSixtyOneNinety = formatCentsToDollars(dueSixtyOneNinetyCents)
  const dueOverNinety = formatCentsToDollars(dueOverNinetyCents)
  const totalCents = currentCents + dueOneThirtyCents + dueThirtyOneSixtyCents + dueSixtyOneNinetyCents + dueOverNinetyCents
  const total = formatCentsToDollars(totalCents)
  const formattedTotal = `$${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  const isChartEmpty = isSuccess && total <= 0
  const shortenTotal = `$${shortenMoneyValue(total)}`
  const data = [
    {
      id: 'Current',
      label: 'Current',
      value: current,
      color: COLOR_MAP[CURRENT],
    },
    {
      id: '1-30',
      label: '1-30',
      value: dueOneThirty,
      color: COLOR_MAP[DUE_ONE_THIRTY],
    },
    {
      id: '31-60',
      label: '31-60',
      value: dueThirtyOneSixty,
      color: COLOR_MAP[DUE_THIRTY_ONE_SIXTY],
    },
    {
      id: '61-90',
      label: '61-90',
      value: dueSixtyOneNinety,
      color: COLOR_MAP[DUE_SIXTY_ONE_NINETY],
    },
    {
      id: '90+',
      label: '90+',
      value: dueOverNinety,
      color: COLOR_MAP[DUE_OVER_NINETY],
    },
  ]

  const getArcLabel = ({ value }) => {
    return `$${shortenMoneyValue(value)}`
  }

  const getValueFormat = value =>
    `$${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`

  return (
    <>
      {isChartEmpty && <GraphCardEmptyState title={T.NO_INVOICE_DATA} />}
      {!isChartEmpty && (
        <>
          <Box height={250} position="relative">
            <GroupPieChart data={data} colors={Object.values(COLOR_MAP)} valueFormat={getValueFormat} arcLabel={getArcLabel} legends={[]} />
            <GroupChartOverlay overlayValue={shortenTotal} tooltipTitle={formattedTotal} />
          </Box>
          <ChartLegend data={data} mt={-3} />
        </>
      )}
      {showFooter && <GroupChartName Icon={CurrencyDollar} label="Aging bucket" />}
    </>
  )
}

InvoiceAgingBucketStatistic.propTypes = {
  id: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
}

export default InvoiceAgingBucketStatistic
