import React from 'react'
import PropTypes from 'prop-types'

import AutoComplete from 'components/common/AutoComplete'

import T from 'T'

import { get } from 'utils/lodash'

const PrizingZoneAutoComplete = ({ globalFilterOptions, checkedPricingZones, handleCheckboxValue, backFlag }) => {
  return (
    <div className="prizing_zones left-elements-width mt15">
      <div className="service-label">{`${T.PRICING_ZONE}s`}</div>

      <AutoComplete
        backFlag={backFlag}
        isHandleCheckbox
        metaData={get(globalFilterOptions, 'pricingZones', [])}
        label={`${T.PRICING_ZONE}s`}
        getByLabelText={option => option.zoneName}
        handleCheckboxValue={handleCheckboxValue}
        checkedRecords={checkedPricingZones}
        getPlaceholder={checkedPricingZones.length ? '' : `Select ${T.PRICING_ZONE}s`}
      />
    </div>
  )
}

PrizingZoneAutoComplete.defaultProps = {
  globalFilterOptions: {},
  handleCheckboxValue: null,
  checkedPricingZones: [],
  backFlag: false,
}

PrizingZoneAutoComplete.propTypes = {
  globalFilterOptions: PropTypes.object,
  handleCheckboxValue: PropTypes.func,
  checkedPricingZones: PropTypes.array,
  backFlag: PropTypes.bool,
}

export default PrizingZoneAutoComplete
