import T from 'T'
import { orange, blue, green, red, grey, yellow, lightGreen, pink, purple, deepPurple, deepOrange, indigo } from '@mui/material/colors'

export const SIGNATURE_REQUIRED_OPTIONS = ['Signature Only', 'Damage Waiver', 'Certificate of Destruction']
export const SKILLS_REQUIRED_OPTIONS = ['Military Clearance', 'Security Clearance']

export const WORK_ORDER_STATUS = {
  SERVICED: 'Serviced',
  SKIPPED: 'Skipped',
  IN_PROGRESS: 'In Process',
  IN_PROCESS: 'In Process',
  SCHEDULED: 'Scheduled',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled',
  HOLD: 'Hold',
}

export const WO_STATUS_BE_TO_FE_FORMAT = {
  SERVICED: 'Serviced',
  SKIPPED: 'Skipped',
  INPROGRESS: 'In Process',
  INPROCESS: 'In Process',
  SCHEDULED: 'Scheduled',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled',
  HOLD: 'Hold',
}
const { SERVICED, SKIPPED, IN_PROCESS, SCHEDULED, CANCELED, HOLD } = WORK_ORDER_STATUS

export const WORK_ORDER_STATUS_DISPLAY = {
  [SERVICED]: { type: 'success', bgColor: green[100], borderColor: green[200], marker: 'serviced-marker', markerTextColor: '#ffffff' },
  [SKIPPED]: { type: 'warning', bgColor: orange[100], borderColor: orange[200], marker: 'skipped-marker', markerTextColor: '#000000' },
  [IN_PROCESS]: { type: 'info', bgColor: blue[100], borderColor: blue[200], marker: 'in-progress-marker', markerTextColor: '#ffffff' },
  [SCHEDULED]: { type: '', bgColor: grey[100], borderColor: grey[400], marker: 'scheduled-marker', markerTextColor: '#ffffff' },
  [CANCELED]: { type: 'error', bgColor: red[100], borderColor: red[200], marker: 'canceled-marker', markerTextColor: '#ffffff' },
  [HOLD]: { type: '', bgColor: deepPurple[100], borderColor: deepPurple[200], marker: 'hold-marker', markerTextColor: '#ffffff' },
}

export const PLAN_TYPE = {
  INSTANCE: 'Instance',
  RECURRING: 'Recurring',
}

export const SERVICE_ACTION_TYPE = {
  SWAP: 'Swap',
  DELIVERY: 'Delivery',
  'DUMP & RETURN': 'Dump & Return',
  DROP: 'Drop',
  'LIVE LOAD': 'Live load',
  REPOSITION: 'Reposition',
  FINAL: 'Final',
  COLLECTION: 'Collection',
  RENTAL: 'Rental',
  CONCIERGE: 'Concierge',
}

export const SERVICE_ACTION_TYPE_TO_COLOR_MAP = {
  SWAP: yellow,
  DELIVERY: deepOrange,
  'DUMP & RETURN': indigo,
  DROP: orange,
  'LIVE LOAD': lightGreen,
  REPOSITION: red,
  FINAL: pink,
  COLLECTION: purple,
  RENTAL: green,
  CONCIERGE: blue,
}

export const SERVICE_ACTION_TYPE_TO_BE_STR = {
  SWAP: 'swap',
  DELIVERY: 'delivery',
  'DUMP & RETURN': 'dump & return',
  DROP: 'drop',
  'LIVE LOAD': 'live load',
  REPOSITION: 'reposition',
  FINAL: 'final',
  COLLECTION: 'collection',
  RENTAL: 'rental',
  CONCIERGE: 'concierge',
}

export const SERVICE_VIEW = {
  WEEK: 'Week',
  UPCOMING: 'Upcoming',
}

export const SERVICE_TYPE = {
  SERVICE: 'Service',
  SERVICE_GROUP: 'Service Group',
}

export const RECURRENCE = {
  RECURRING: 'Recurring',
  ON_REQUEST: 'On Request',
}

const { RECURRING, ON_REQUEST } = RECURRENCE

export const RECURRENCE_LABELS = {
  [RECURRING]: 'Recurring',
  [ON_REQUEST]: 'On-request',
}

export const HOLD_TYPES = {
  VACATION: 'Vacation',
  SUSPENSION: 'Suspension',
}

export const WORK_ORDER_COLUMNS = [T.PREVIOUS_WO, T.PREVIOUS_DATE, T.NEW_DATE, T.ROUTE]
