import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const ColorSquare = ({ color, sx, ...rest }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 115, 230, 0.2)',
        width: '26px',
        height: '26px',
        border: '1px solid',
        borderRadius: '5px',
        borderColor: 'border.light',
        backgroundColor: color,
        cursor: 'pointer',
        ...sx,
      }}
      {...rest}
    />
  )
}

ColorSquare.propTypes = {
  color: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

export default ColorSquare
