import { actions } from '../actions/billing'

const { BILLING_INVOICE_META, INVOICE_TAB_STATE } = actions.PUT

const initialState = {
  invoiceMeta: false,
  invoiceTabState: {
    tableState: undefined,
    hasReturnedFromWorkOrderRoute: false,
  },
}

const BillingReducer = (state = initialState, action = {}) => {
  if (action.type === BILLING_INVOICE_META) {
    return { ...state, invoiceMeta: action.payload }
  }
  if (action.type === INVOICE_TAB_STATE) {
    return { ...state, invoiceTabState: action.payload }
  }
  return state
}

export default BillingReducer
