export const ignoreLargeCalls = [
  // New accounts/locations UI
  'getAccountsBySearch',
  'getAccountsByFocusedSearch',
  'getAccountsByRecentSearch',
  'getLocations',
  // Route manager
  'getRouteStopsUnassigned',
  'getRouteStops',
  'getRouteStopsGeojson',
  // Billing
  'getBillingCreateInvoiceList',
  'getBillingInvoiceList',
  'getBillingSentInvoiceList',
  'getBillingPaymentList',
  'getBillingARList',
  // Groups
  'getInvoiceGroupDetails',
  'getPaymentGroupDetails',
  'getAccountGroupDetails',
]

export const ignoreFrustratingCalls = ['getAllAccountNotes', 'getLocationsNotesCount', 'getPortalUsers']

export const ignoreLargeState = {
  accounts: undefined,
  locations: undefined,
  apiReducer: undefined,
}
