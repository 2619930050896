import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getServiceDetails: build.query({
      query: id => ({
        url: '/api/configured-service/detail',
        method: 'POST',
        body: {
          configuredServiceId: id,
        },
      }),
    }),
  }),
})

export const { useLazyGetServiceDetailsQuery } = extendedApi
