import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, CardActionArea } from '@mui/material'
import { grey } from '@mui/material/colors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { getCustomerDetailsPageUrl } from 'router/routes'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

import Link from 'components/common/Link'
import AutopayChip from 'components/common/chip/AutopayChip'
import AccountBalanceChip from 'components/common/chip/AccountBalanceChip'
import { formatDateRangeToShortFeformat } from 'utils/date'
import SuspensionChip from 'components/common/chip/SuspensionChip'
import VacationHoldChip from 'components/common/chip/VacationHoldChip'
import VacationHoldPopover from 'components/customers/accounts/vacation-holds/VacationHoldPopover'
import VacationHoldDialog from 'components/customers/accounts/vacation-holds/VacationHoldDialog'
import EventBlocker from 'components/common/EventBlocker'
import AgingPopover from 'components/customers/accounts/popover/AgingPopover'
import LocationsServicesCountPopover from 'components/customers/accounts/locations-services/LocationsServicesCountPopover'

const { BILLING_TAB_ID } = CUSTOMER_DETAILS_TABS_ID
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const RenderAccountLeftContentCell = ({ row = {} }) => {
  const {
    accountId,
    accountNumber,
    accountName,
    billingAddress,
    billingProfile = '',
    activeConfiguredServiceCount,
    deactivatedConfiguredServiceCount,
    activeLocationCount,
    deactivatedLocationCount,
    balanceCents = 0,
    currentCents = 0,
    dueOneThirtyCents = 0,
    dueThirtyOneSixtyCents = 0,
    dueSixtyOneNinetyCents = 0,
    dueOverNinetyCents = 0,
    autopay,
    vacationHold = false,
    vacationHoldCount = 0,
    suspensionHold = false,
    vacationHoldStartDate,
    vacationHoldEndDate,
    suspensionHoldStartDate,
  } = row
  const formattedVacationRange = useMemo(
    () =>
      vacationHoldCount > 1
        ? `${vacationHoldCount} vacation holds`
        : formatDateRangeToShortFeformat([vacationHoldStartDate, vacationHoldEndDate]),
    [vacationHoldCount, vacationHoldStartDate, vacationHoldEndDate]
  )
  const formattedSuspensionRange = formatDateRangeToShortFeformat([suspensionHoldStartDate])
  const hasBalanceCents = balanceCents !== 0
  const [locationsServicesCountPopoverAnchorEl, setLocationsServicesCountPopoverAnchorEl] = useState(null)
  const [agingPopoverAnchorEl, setAgingPopoverAnchorEl] = useState(null)
  const [vacationHoldPopoverAnchorEl, setVacationHoldPopoverAnchorEl] = useState(null)
  const [isVacationHoldDialogOpen, setIsVacationHoldDialogOpen] = useState(false)

  const handleVacationHoldDialogOpen = () => {
    setIsVacationHoldDialogOpen(true)
  }

  const handleVacationHoldDialogClose = () => setIsVacationHoldDialogOpen(false)

  const handleVacationHoldPopoverOpen = event => setVacationHoldPopoverAnchorEl(event.currentTarget)

  const handleVacationHoldPopoverClose = () => setVacationHoldPopoverAnchorEl(null)

  const handleLocationsServicesCountPopoverOpen = event => setLocationsServicesCountPopoverAnchorEl(event.currentTarget)

  const handleLocationsServicesCountPopoverClose = () => setLocationsServicesCountPopoverAnchorEl(null)

  const handleAgingPopoverOpen = event => {
    if (!hasBalanceCents) return
    setAgingPopoverAnchorEl(event.currentTarget)
  }

  const handleAgingPopoverClose = () => setAgingPopoverAnchorEl(null)

  const balanceChipColor = useMemo(() => {
    if (dueOverNinetyCents > 0) {
      return AGING_BUCKET_COLORS[DUE_OVER_NINETY]
    }
    if (dueSixtyOneNinetyCents > 0) {
      return AGING_BUCKET_COLORS[DUE_SIXTY_ONE_NINETY]
    }
    if (dueThirtyOneSixtyCents > 0) {
      return AGING_BUCKET_COLORS[DUE_THIRTY_ONE_SIXTY]
    }
    if (dueOneThirtyCents > 0) {
      return AGING_BUCKET_COLORS[DUE_ONE_THIRTY]
    }
    if (currentCents > 0) {
      return AGING_BUCKET_COLORS[CURRENT]
    }

    return grey
  }, [currentCents, dueOverNinetyCents, dueSixtyOneNinetyCents, dueThirtyOneSixtyCents, dueOneThirtyCents])

  return (
    <>
      <Box display="flex" gap={0.5} alignItems="center">
        <Typography variant="h5" fontWeight={700} color="textPrimary">
          {accountName}
        </Typography>
        <Typography variant="subtitle2" fontWeight={700} noWrap>
          {`| ${accountNumber} | ${billingProfile}`}
        </Typography>
      </Box>
      <Box display="flex" gap={0.75} alignItems="center">
        {autopay && <AutopayChip />}
        <CardActionArea
          sx={{
            width: 'auto',
            cursor: hasBalanceCents ? 'pointer' : 'default',
          }}
          to={`${getCustomerDetailsPageUrl(accountId)}?tab=${BILLING_TAB_ID}`}
          component={Link}
          disableRipple
          disableTouchRipple
          onMouseEnter={handleAgingPopoverOpen}
          onMouseLeave={handleAgingPopoverClose}
        >
          <AccountBalanceChip balance={balanceCents} color={balanceChipColor} />
        </CardActionArea>
        {suspensionHold && <SuspensionChip size="small" clickable={false} label={formattedSuspensionRange} />}
        <EventBlocker preventDefault>
          {vacationHold && (
            <VacationHoldChip
              size="small"
              clickable
              label={formattedVacationRange}
              onClick={handleVacationHoldDialogOpen}
              onMouseEnter={handleVacationHoldPopoverOpen}
              onMouseLeave={handleVacationHoldPopoverClose}
            />
          )}
        </EventBlocker>
        <InfoOutlinedIcon
          color="action"
          sx={{ width: 20, height: 20 }}
          onMouseEnter={handleLocationsServicesCountPopoverOpen}
          onMouseLeave={handleLocationsServicesCountPopoverClose}
        />
        <Typography variant="caption" color="textPrimary" noWrap>
          {billingAddress}
        </Typography>
      </Box>
      <LocationsServicesCountPopover
        anchorEl={locationsServicesCountPopoverAnchorEl}
        activeConfiguredServiceCount={activeConfiguredServiceCount}
        deactivatedConfiguredServiceCount={deactivatedConfiguredServiceCount}
        activeLocationCount={activeLocationCount}
        deactivatedLocationCount={deactivatedLocationCount}
        onClose={handleLocationsServicesCountPopoverClose}
      />
      <AgingPopover anchorEl={agingPopoverAnchorEl} row={row} onClose={handleAgingPopoverClose} />
      <VacationHoldPopover anchorEl={vacationHoldPopoverAnchorEl} row={row} onClose={handleVacationHoldPopoverClose} />
      <EventBlocker>
        <VacationHoldDialog open={isVacationHoldDialogOpen} row={row} onClose={handleVacationHoldDialogClose} />
      </EventBlocker>
    </>
  )
}

RenderAccountLeftContentCell.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountLeftContentCell
