import { useCallback } from 'react'
import { get } from 'lodash'
import {
  insertAndResequenceRows,
  preProcessMoveDataOnSequenceChange,
  reorderMoveToData,
  ROW_HEIGHT,
  UNASSIGNED_MARKER_PROPS,
} from 'components/route-manager/BulkActions/settings'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { scrollToSelectedRowInDataGrid } from 'utils/datagrid'

const useBulkResequenceFunctions = ({
  moveToRows,
  moveFromRows,
  moveFromRouteStopMarkers,
  moveToRouteSequenceLine,
  moveFromGridApiRef,
  moveToGridApiRef,
  moveToRouteStopMarkers,
  reset,
  bulkReduxAction,
}) => {
  const dispatch = useDispatch()
  const { watch, getValues, setValue } = useFormContext()
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const insertArrayAtPosition = (array, insertArray, targetIndex) => {
    return [...array.slice(0, targetIndex), ...insertArray, ...array.slice(targetIndex)]
  }

  const getTargetIndex = useCallback(
    toPosition => {
      if (!toPosition) return 0
      const values = getValues()
      const toInsertCount = moveToRows.filter(item => item.routeId === moveFromRouteId).filter(({ id }) => !get(values, id, '')).length
      let targetPosition = Math.max(1, toPosition)
      targetPosition = Math.min(targetPosition, moveToRows.length + 1)
      const targetIndex = targetPosition - 1
      return targetIndex + toInsertCount
    },
    [moveToRows, moveFromRouteId]
  )

  const onPreserveSequence = useCallback(
    data => {
      const startingSequence = get(data, 'startingSequence', '')
      const selectedRowIdsMap = moveFromGridApiRef.current.getSelectedRows()
      const {
        moveToCoords,
        selectedMoveFromRows,
        selectedMoveFromMarkers,
        selectedMoveFromCoords,
        updatedMoveFromRows,
        updatedMoveFromRouteStopMarkers,
        updatedMoveFromRouteSequenceLine,
      } = preProcessMoveDataOnSequenceChange({
        moveFromRouteStopMarkers,
        moveFromRows,
        moveToRouteSequenceLine,
        selectedRowIdsMap,
      })
      const values = getValues()
      const targetIndex = getTargetIndex(startingSequence)
      const { reorderedMoveToRows, reorderedMoveToRouteStopMarkers, reorderedMoveToRouteSequenceLine } = reorderMoveToData(
        moveToRows,
        moveToRouteStopMarkers,
        moveToCoords,
        selectedMoveFromRows,
        selectedMoveFromMarkers,
        targetIndex,
        insertArrayAtPosition,
        selectedMoveFromCoords
      )
      const getIsMoveToRow = row => row.routeId === moveToRouteId
      const { updatedMoveToRows, updatedMoveToMarkers, toBeInsertedRowsIds } = insertAndResequenceRows(
        reorderedMoveToRows,
        reorderedMoveToRouteStopMarkers,
        values,
        selectedRowIdsMap,
        setValue,
        getIsMoveToRow
      )
      dispatch(
        bulkReduxAction({
          moveFromRows: updatedMoveFromRows,
          moveFromRouteStopMarkers: updatedMoveFromRouteStopMarkers,
          moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
          moveToRows: updatedMoveToRows,
          moveToRouteStopMarkers: updatedMoveToMarkers,
          moveToRouteSequenceLine: reorderedMoveToRouteSequenceLine,
          isSequenceDialogOpen: false,
          toBeInsertedRowsIds,
        })
      )
      reset({ startingSequence: '' })
      scrollToSelectedRowInDataGrid(moveToGridApiRef, targetIndex, ROW_HEIGHT)
      moveFromGridApiRef.current.selectRows([])
    },
    [moveFromRows, moveFromRouteStopMarkers, moveToRouteSequenceLine, moveToRows, moveToRouteStopMarkers]
  )

  const onResetSequence = useCallback(() => {
    const selectedRowIdsMap = moveFromGridApiRef.current.getSelectedRows()
    const {
      moveToCoords,
      selectedMoveFromRows,
      selectedMoveFromMarkers,
      selectedMoveFromCoords,
      updatedMoveFromRows,
      updatedMoveFromRouteStopMarkers,
      updatedMoveFromRouteSequenceLine,
    } = preProcessMoveDataOnSequenceChange({
      moveFromRouteStopMarkers,
      moveFromRows,
      moveToRouteSequenceLine,
      selectedRowIdsMap,
    })
    const updatedMoveToRows = [...selectedMoveFromRows, ...moveToRows]
    const toBeInsertedRowsIds = updatedMoveToRows.filter(({ routeId }) => moveFromRouteId === routeId).map(({ id }) => id)
    toBeInsertedRowsIds.forEach(id => {
      if (selectedRowIdsMap.has(id)) {
        setValue(id, '')
      }
    })
    const updatedMoveToRouteStopMarkers = [
      ...selectedMoveFromMarkers.map(m => {
        const props = get(m, 'properties', {})
        return {
          ...m,
          properties: {
            ...props,
            ...UNASSIGNED_MARKER_PROPS,
            position: '',
          },
        }
      }),
      ...moveToRouteStopMarkers,
    ]
    const updatedMoveToRouteSequenceLine = {
      type: 'Feature',
      geometry: { type: 'LineString', coordinates: [...selectedMoveFromCoords, ...moveToCoords] },
    }
    dispatch(
      bulkReduxAction({
        moveFromRows: updatedMoveFromRows,
        moveFromRouteStopMarkers: updatedMoveFromRouteStopMarkers,
        moveFromRouteSequenceLine: updatedMoveFromRouteSequenceLine,
        moveToRows: updatedMoveToRows,
        moveToRouteStopMarkers: updatedMoveToRouteStopMarkers,
        moveToRouteSequenceLine: updatedMoveToRouteSequenceLine,
        isSequenceDialogOpen: false,
        toBeInsertedRowsIds,
      })
    )
    scrollToSelectedRowInDataGrid(moveToGridApiRef, 0, ROW_HEIGHT)
    reset({ startingSequence: '' })
    moveFromGridApiRef.current.selectRows([])
  }, [moveFromRouteId, moveFromRows, moveFromRouteStopMarkers, moveToRouteSequenceLine, moveToRows, moveToRouteStopMarkers])

  return {
    onPreserveSequence,
    onResetSequence,
  }
}
export default useBulkResequenceFunctions
