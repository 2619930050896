import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useUpdateLinkedServicesMutation } from 'api/pricing/updateLinkedServices'
import { useUpdateLinkedTaxesMutation } from 'api/pricing/updateLinkedTaxes'
import { useUpdateLinkedGeneralFeeMutation } from 'api/pricing/updateLinkedGeneralFee'
import { useUpdateLinkedDisposalFeeMutation } from 'api/pricing/updateLinkedDisposalFee'

import CommonDrawer from 'components/common/CommonDrawer'
import LinkServiceSecondaryBar from 'components/pricing/LinkedService/LinkServiceSecondaryBar'
import Loader from 'components/common/loader'
import { PRICING_PAGINATION } from 'settings/constants/pagination'

import { canUpdatePricingManager } from 'data/permissions/permissionsSelectors'
import { useSelector, shallowEqual } from 'react-redux'
import { handleError } from 'utils/error'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'

import T from 'T'
import Constants from 'Constants'

import LinkedServiceTable from './LinkedServiceTable'
import './LinkedService.scss'
import CommonModalHeader from '../../common/CommonModalHeader'
import CommonModalFooter from '../../common/CommonModalFooter'

const { MEDIA_SERVER } = Constants

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION
const HEADING_LIMIT = 47

const generalFeeList = [T.FEE, T.MEASURE, T.PERIOD, T.VALUE]
const disposalFeeList = [T.FEE, T.MEASURE, T.PERIOD, T.METHOD]
const taxList = [T.TAX, T.MEASURE, T.VALUE]
const servicesList = [T.SERVICE, T.MATERIAL, T.RECURRENCE, T.MEASURE, T.PERIOD, T.VALUE]

const LinkServiceModal = ({
  tab,
  serviceName,
  serviceList,
  page,
  rowsPerPage,
  openLinkServiceDrawer,
  setOpenLinkServiceDrawer,
  isDirty,
  handleSwitchVal,
  getResults,
  autoLink,
  setState,
  handleAutoLink,
}) => {
  const updatePricingManager = useSelector(canUpdatePricingManager, shallowEqual)
  const [updateLinkedServices, { isLoading }] = useUpdateLinkedServicesMutation()
  const [updateLinkedTaxes, { isLoading: isTaxLoading }] = useUpdateLinkedTaxesMutation()
  const [updateLinkedGeneralFee, { isLoading: isGeneralFeeLoading }] = useUpdateLinkedGeneralFeeMutation()
  const [updateLinkedDisposalFee, { isLoading: isDisposalFeeLoading }] = useUpdateLinkedDisposalFeeMutation()
  const theme = useTheme()
  const [tabValue, setTabValue] = useState(tab !== 'tax' && tab !== 'service' ? 'services' : 'general-fee')

  const handleCancel = () => {
    setOpenLinkServiceDrawer(false)
    setState({ isDirty: false })
    setTabValue(tab !== 'tax' && tab !== 'service' ? 'services' : 'general-fee')
  }

  const loadResult = tabVal => {
    if (['general', 'disposal'].includes(tabVal)) {
      getResults(page, rowsPerPage, tabVal)
    } else {
      getResults(page, rowsPerPage)
    }
  }

  const handleApply = () => {
    if (!updatePricingManager) return

    const taxdata = []
    const generalFeeData = []
    const disposalFeeData = []
    const servicesData = []

    serviceList?.tax?.forEach(data => {
      taxdata.push({ linkedId: data.id, linked: data.linked, link: data.linkedVal })
    })

    serviceList?.services?.forEach(data => {
      servicesData.push({ linkedId: data.id, linked: data.linked, link: data.linkedVal })
    })

    serviceList?.generalFee?.forEach(data => {
      generalFeeData.push({ linkedId: data.id, linked: data.linked, link: data.linkedVal })
    })

    serviceList?.disposalFee?.forEach(data => {
      disposalFeeData.push({ linkedId: data.id, linked: data.linked, link: data.linkedVal })
    })

    const payload = {
      id: serviceList.id,
      autoLink,
      disposalFee: disposalFeeData,
      services: servicesData,
      tax: taxdata,
      generalFee: generalFeeData,
    }

    if (tab === 'service') {
      updateLinkedServices(payload)
        .unwrap()
        .then(() => {
          setOpenLinkServiceDrawer(false)
          setTabValue('general-fee')
          loadResult('service')
        })
        .catch(handleError)
    }

    if (tab === 'tax') {
      updateLinkedTaxes(payload)
        .unwrap()
        .then(() => {
          setOpenLinkServiceDrawer(false)
          setTabValue('general-fee')
          loadResult('tax')
        })
        .catch(handleError)
    }

    if (tab === 'general') {
      updateLinkedGeneralFee(payload)
        .unwrap()
        .then(() => {
          setOpenLinkServiceDrawer(false)
          setTabValue('services')
          loadResult('general')
        })
        .catch(handleError)
    }

    if (tab === 'disposal') {
      updateLinkedDisposalFee(payload)
        .unwrap()
        .then(() => {
          setOpenLinkServiceDrawer(false)
          setTabValue('services')
          loadResult('disposal')
        })
        .catch(handleError)
    }
  }

  const handleLinkedServiceTabChange = (event, newValue) => {
    if (newValue) {
      setTabValue(newValue)
    }
  }

  const handleHeaderText = () => {
    switch (tab) {
      case 'service':
        return T.LINKED_SERVICE
      case 'tax':
        return T.LINKED_TAX
      case 'general':
        return T.LINKED_GENERAL_FEE
      case 'disposal':
        return T.LINKED_DISPOSAL_FEE
      default:
        return ''
    }
  }

  return (
    <Box>
      {(isLoading || isTaxLoading || isGeneralFeeLoading || isDisposalFeeLoading) && <Loader />}

      <CommonDrawer
        isOpen={openLinkServiceDrawer}
        isDirty={isDirty}
        sx={{
          height: 'calc(100vh - 56px)',
          width: '696px',
          overflow: 'visible',
          boxSizing: 'content-box',
          '& .MuiDrawer-paper': {
            overflow: 'visible',
          },
        }}
        className="add-drawer linked-services"
        onChange={() => handleCancel()}
      >
        <Box
          sx={{
            height: 'calc(100vh - 56px)',
            pb: 1,
          }}
        >
          <CommonModalHeader
            title={handleHeaderText()}
            onClose={() => handleCancel()}
            leadingIcon={
              <Box
                component="img"
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: 6,
                  '& svg': {
                    fill: theme.palette.text.primary,
                  },
                }}
                src={`${MEDIA_SERVER}Link.svg`}
                alt="fee-icon"
              />
            }
          />
          <Box
            sx={{
              padding: '0px 24px',
              height: `calc(100vh - 180px)`,
            }}
          >
            <LinkServiceSecondaryBar
              tab={tab}
              tabValue={tabValue}
              serviceList={serviceList}
              serviceName={serviceName}
              autoLink={autoLink}
              handleAutoLink={handleAutoLink}
              handleLinkedServiceTabChange={handleLinkedServiceTabChange}
            />
            {!(tab === 'general-fee' && tab === 'disposal-fee') && (
              <>
                {tabValue === 'general-fee' && (
                  <LinkedServiceTable
                    multilineHeading={serviceName.length > HEADING_LIMIT}
                    serviceList={serviceList.generalFee}
                    linkAll={serviceList.linkAll.generalFee}
                    linkVal={serviceList.linkVal.generalFee}
                    headerList={generalFeeList}
                    tab="generalFee"
                    handleSwitchVal={handleSwitchVal}
                  />
                )}
                {tabValue === 'disposal-fee' && (
                  <LinkedServiceTable
                    multilineHeading={serviceName.length > HEADING_LIMIT}
                    serviceList={serviceList.disposalFee}
                    linkAll={serviceList.linkAll.disposalFee}
                    linkVal={serviceList.linkVal.disposalFee}
                    headerList={disposalFeeList}
                    tab="disposalFee"
                    handleSwitchVal={handleSwitchVal}
                  />
                )}
              </>
            )}

            {tab !== 'tax' && tabValue === 'taxes' && (
              <LinkedServiceTable
                multilineHeading={serviceName.length > HEADING_LIMIT}
                serviceList={serviceList.tax}
                linkAll={serviceList.linkAll.tax}
                linkVal={serviceList.linkVal.tax}
                headerList={taxList}
                tab="tax"
                handleSwitchVal={handleSwitchVal}
              />
            )}

            {tab !== 'service' && tabValue === 'services' && (
              <LinkedServiceTable
                multilineHeading={serviceName.length > HEADING_LIMIT}
                serviceList={serviceList.services}
                linkAll={serviceList.linkAll.service}
                linkVal={serviceList.linkVal.service}
                headerList={servicesList}
                tab="service"
                handleSwitchVal={handleSwitchVal}
              />
            )}
          </Box>
          <CommonModalFooter onSave={handleApply} onCancel={handleCancel} cancelButtonLabel={T.CANCEL} saveButtonLabel="Apply Changes" />
        </Box>
      </CommonDrawer>
    </Box>
  )
}

LinkServiceModal.defaultProps = {
  tab: '',
  serviceName: '',
  serviceList: {},
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  openLinkServiceDrawer: false,
  autoLink: false,
  setOpenLinkServiceDrawer: null,
  getResults: null,
  isDirty: false,
  handleSwitchVal: null,
  handleAutoLink: null,
  setState: null,
}

LinkServiceModal.propTypes = {
  tab: PropTypes.string,
  serviceName: PropTypes.string,
  serviceList: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  openLinkServiceDrawer: PropTypes.bool,
  autoLink: PropTypes.bool,
  setOpenLinkServiceDrawer: PropTypes.func,
  getResults: PropTypes.func,
  isDirty: PropTypes.bool,
  setState: PropTypes.func,
  handleSwitchVal: PropTypes.func,
  handleAutoLink: PropTypes.func,
}

export default LinkServiceModal
