import React, { Children, useState, useEffect } from 'react'
import chunk from 'lodash/chunk'
import uniq from 'lodash/uniq'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { Button, Divider, List, DialogContent, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { setBatchDialogState, resetBatchDialogState } from 'slices/groups/InvoicePrintableViewSlice'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import ExpandView from './ExpandView'
import { BATCH_SIZE } from '../settings'

const BatchPrintDialog = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [expandedBatch, setExpandedBatch] = useState('')
  const isOpen = useSelector(state => get(state, 'InvoicePrintableView.batchDialog.isOpen', false), shallowEqual)
  const selectionModel = useSelector(state => get(state, 'InvoicePrintableView.invoiceSelectionModel', []), shallowEqual)
  const batchPrintCompleteIds = useSelector(state => get(state, 'InvoicePrintableView.batchDialog.batchPrintCompleteIds', []), shallowEqual)
  const batches = chunk(selectionModel, BATCH_SIZE)

  const handleClose = () => dispatch(resetBatchDialogState())

  const handlePrint = index => {
    dispatch(setBatchDialogState({ currentBatchId: index, batchPrintCompleteIds: uniq([...batchPrintCompleteIds, index]) }))
    setTimeout(() => window.print())
  }

  const handleAfterPrint = () => dispatch(setBatchDialogState({ currentBatchId: '' }))

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setExpandedBatch('')
    }
  }, [isOpen])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="sm" fullWidth className="no-print" sx={{ zIndex: theme.zIndex.snackbar }}>
        <HHDialogTitle title="Batch print" onClose={handleClose} />
        <DialogContent>
          <List disablePadding>
            {Children.toArray(
              batches.map((batch, index) => (
                <>
                  <ExpandView
                    index={index}
                    batch={batch}
                    isExpanded={expandedBatch === index}
                    onExpandChange={open => setExpandedBatch(open ? index : '')}
                    onPrint={() => handlePrint(index)}
                  />
                  {batches.length - 1 !== index && <Divider />}
                </>
              ))
            )}
          </List>
        </DialogContent>
        <HHDialogActions>
          <Button size="small" variant="contained" color="primary" onClick={handleClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

export default BatchPrintDialog
