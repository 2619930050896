import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { toast } from 'react-toastify'
import Header from 'components/header'
import SaveViews from 'components/billing/account-receivable/SaveViews'
import AccountsReceivableDataGrid from 'components/billing/account-receivable/AccountsReceivableDataGrid'
import { CommonTableFilters } from 'components/common'
import { handleFilterInputChange, handlePrefillResponseModification } from 'utils/table'
import {
  setARFilters,
  setARPage,
  setARRefetchFlag,
  setARSelectionModel,
  setStatementDrawerState,
} from 'slices/billing/accountsReceivableSlice'
import { filterMapToQueryParams } from 'utils/LHSbracket'
import T from 'T'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'
import { usePostBillingAccountApplyCreditMutation } from 'api/billing/postBillingAccountApplyCredit'
import { initiateStatusDownload } from 'middleware/actions/download'
import BillingSecondaryNavBar from 'components/billing/common/BillingSecondaryNavBar'
import BillingSideNav from 'components/billing/common/side-nav/BillingSideNav'
import BillingLayout from 'components/billing/common/BillingLayout'
import StatementDrawer from 'components/billing/account-receivable/StatementDrawer'
import { ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID } from '../settings'

const AccountsReceivable = () => {
  const [_, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const [postBillingAccountApplyCredit] = usePostBillingAccountApplyCreditMutation()
  const allFilters = useSelector(state => state.accountsReceivable.filters.allFilters, shallowEqual)
  const activeFilter = useSelector(state => state.accountsReceivable.filters.activeFilter)
  const isOpenTableFilters = useSelector(state => state.accountsReceivable.filters.isOpenTableFilters)
  const selectedFilterId = useSelector(state => state.accountsReceivable.filters.selectedFilterId)
  const existingFilterList = useSelector(state => state.accountsReceivable.savedView.existingFilterList)
  const selectedRows = useSelector(state => state.accountsReceivable.selectionModel, shallowEqual)
  const statementDrawer = useSelector(state => state.accountsReceivable.statementDrawer, shallowEqual)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  const handleFilterApply = () => {
    const queryParams = filterMapToQueryParams(allFilters)
    setSearchParams({ ...queryParams })
    dispatch(setARPage(0))
    dispatch(setARRefetchFlag(true))
  }

  const handleFilterChange = (key, value, columnName) => {
    const newFiltersState = handleFilterInputChange(key, value, columnName, allFilters)
    dispatch(
      setARFilters({
        ...newFiltersState,
        refetchFlag: false,
      })
    )
  }

  const handleFilterClose = () => {
    let newAllFilters = allFilters
    if (selectedFilterId === '') {
      newAllFilters = omit(allFilters, activeFilter)
    } else {
      const currentFilter = existingFilterList.find(filterListEl => selectedFilterId === filterListEl.searchFilterId)
      const filteredRecord = get(currentFilter, 'searchFilters', []).find(
        filterListEl => filterListEl.columnName === allFilters[activeFilter].columnName
      )

      if (!filteredRecord) {
        newAllFilters = omit(allFilters, activeFilter)
      } else {
        const initialFilter = ACCOUNTS_RECEIVABLE_CONFIGURATOR_DATA_GRID.find(
          conf => conf.columnName === allFilters[activeFilter].columnName
        )
        newAllFilters[activeFilter] = handlePrefillResponseModification(initialFilter, filteredRecord)
      }
    }
    const queryParams = filterMapToQueryParams(newAllFilters)
    setSearchParams({ ...queryParams })
    dispatch(setARPage(0))
    dispatch(setARFilters({ allFilters: newAllFilters, isOpenTableFilters: false, refetchFlag: true }))
  }

  const handleBulkDownload = () => {
    const body = {
      type: 'pdf',
      fileName: 'account-receivable',
      isDownload: true,
      buffer: true,
      urlToMapWith: 'accountReceivableDownloadPDF',
      accountId: selectedRows,
      searchFilters: [{ operation: 'EXIST_IN', columnValue: selectedRows, columnName: 'accountId' }],
    }

    dispatch(
      initiateStatusDownload(body, status => {
        if (status) {
          dispatch(setARSelectionModel({ selectionModel: [] }))
        }
      })
    )
  }

  const handleBulkApplyCreditClick = () => {
    dispatch(putIsLoading(true))
    postBillingAccountApplyCredit({ accountIds: selectedRows })
      .unwrap()
      .then(() => {
        toast.success(T.APPLY_CREDIT_SUCCESS_MSG)
        dispatch(setARSelectionModel({ selectionModel: [] }))
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleCloseStatementDrawer = () => {
    dispatch(
      setStatementDrawerState({
        isOpen: false,
        accountId: '',
      })
    )
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100vw">
      <BillingSideNav />
      <BillingLayout open={showBillingSideNav}>
        <Header />
        <BillingSecondaryNavBar heading={T.ACCOUNTS_RECEIVABLE} />
        <Box overflow="auto" flex={1} display="flex" flexDirection="column" backgroundColor="background.paper">
          <Grid container p={3} justifyContent="space-between" alignItems="flex-end" sx={{ width: '100%' }}>
            <Grid item>
              <SaveViews />
            </Grid>
            {selectedRows.length > 0 && (
              <Grid item>
                <Button variant="outlined" color="primary" size="small" sx={{ ml: 1.5 }} onClick={handleBulkApplyCreditClick}>
                  {T.APPLY_CREDIT}
                </Button>
              </Grid>
            )}
          </Grid>
          <AccountsReceivableDataGrid />
        </Box>
      </BillingLayout>
      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        label={get(allFilters, `${activeFilter}.label`)}
        isSingleSelect={get(allFilters, `${activeFilter}.isSingleSelect`)}
        isBoolean={get(allFilters, `${activeFilter}.isBoolean`)}
        filterComponent="billing"
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={handleFilterClose}
      />
      <StatementDrawer accountId={statementDrawer.accountId} open={statementDrawer.isOpen} onClose={handleCloseStatementDrawer} />
    </Box>
  )
}

export default AccountsReceivable
