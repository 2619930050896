import toNumber from 'lodash/toNumber'
import { INTERVALS } from 'settings/constants/billing'

export const getBillingCycleIntervalTypeDropdownOptions = (interval, id) => {
  const intervalNumber = toNumber(interval)
  // old records
  if (id) {
    return intervalNumber > 1 ? ['Weeks', 'Months', 'Quarters'] : ['Week', 'Month', 'Quarter', 'Year']
  }

  // new records
  if (intervalNumber === 1) {
    return ['Month', 'Quarter', 'Year']
  }

  if (intervalNumber === 2) {
    return ['Months', 'Quarters']
  }

  return ['Months']
}

export const getPricingPeriodIntervalTypeDropdownOptions = (interval, id) => {
  const intervalNumber = toNumber(interval)
  // old records
  if (id) {
    return intervalNumber > 1
      ? ['Instance', 'Hours', 'Days', 'Weeks', 'Months', 'Quarters', 'Years']
      : ['Instance', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']
  }

  // new records
  if (intervalNumber === 1) {
    return ['Instance', 'Month', 'Quarter', 'Year']
  }

  if (intervalNumber === 2) {
    return ['Months', 'Quarters']
  }

  return ['Months']
}

export const getRecurrenceIntervalTypeDropdownOptions = (interval, id) => {
  const intervalNumber = toNumber(interval)
  // old records
  if (id) {
    return intervalNumber > 1
      ? ['Instance', 'Hours', 'Days', 'Weeks', 'Months', 'Quarters', 'Years']
      : ['Instance', 'Hour', 'Day', 'Week', 'Month', 'Quarter', 'Year']
  }

  // new records
  return intervalNumber > 1 ? ['Days', 'Weeks', 'Months', 'Quarters', 'Years'] : ['Day', 'Week', 'Month', 'Quarter', 'Year']
}

export const getIntervalDropdownOptions = (intervalType, id) => {
  if (id) {
    return INTERVALS
  }

  if (intervalType === 'Year') {
    return ['1']
  }

  if (intervalType === 'Quarter') {
    return ['1', '2']
  }

  return INTERVALS
}
