import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { handleError } from 'utils/error'
import { useAddUpdatePaymentNoteMutation } from 'api/billing/addUpdatePaymentNote'
import { CACHE_TAG_ACCOUNT_PAYMENT_LIST, CACHE_TAG_BILLING_PAYMENT_LIST, CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { HHFormTextField } from 'components/form-fields/v5'

const AddUpdatePaymentNoteDialog = ({ isOpen = false, accountId, paymentNumber = '', existingNote = '', onClose }) => {
  const dispatch = useDispatch()
  const [addUpdatePaymentNote, { isLoading }] = useAddUpdatePaymentNoteMutation()

  const paymentNoteForm = useForm({ defaultValues: { note: '' } })
  const {
    control,
    reset,
    setFocus,
    handleSubmit,
    formState: { isDirty },
  } = paymentNoteForm

  const onSubmit = data => {
    if (!isDirty) {
      onClose()
      return
    }

    addUpdatePaymentNote({ paymentNumber, note: data.note })
      .unwrap()
      .then(() => {
        toast.success(`Note ${existingNote ? 'updated' : 'added'} successfully`)
        onClose()
        dispatch(
          api.util.invalidateTags([
            { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
            CACHE_TAG_BILLING_PAYMENT_LIST,
            CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS,
          ])
        )
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      reset({ note: existingNote })
      setTimeout(() => setFocus('note'), 500)
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <HHDialogTitle title={T.PAYMENT_NOTE} onClose={onClose} />
      <DialogContent>
        <FormProvider {...paymentNoteForm}>
          <HHFormTextField
            control={control}
            name="note"
            deprecatedLabel={false}
            label="Note"
            placeholder="Leave a note"
            sx={{ mt: 3, '& .MuiOutlinedInput-root': { height: 'inherit' } }}
            rows={4}
            multiline
            fullWidth
          />
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton loading={isLoading} size="small" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

AddUpdatePaymentNoteDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  paymentNumber: PropTypes.string,
  existingNote: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default AddUpdatePaymentNoteDialog
