export const MAP_DEFAULT_OPTIONS = {
  MAP_VIEW_URL: 'mapbox://styles/mapbox/',
  STREET_VIEW: 'streets-v11',
  LIGHT_VIEW: 'light-v11',
  SATELLITE_VIEW: 'satellite-v9',
  SATELLITE_STREET_VIEW: 'satellite-streets-v12',
  ZOOM: 16,
  LNG_LAT_FIX: 5,
  ZOOM_FIX: 2,
  DIRECTION_API: 'https://api.mapbox.com/directions/v5/mapbox',
  GEOCODING_API: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  PROJECTION: 'mercator',
}

export const PARALLEL_LENGTH_PERCISION = [
  [0.0, 12.65625, 40075016, 2.41],
  [12.66, 17.40234375, 39107539, 2.18],
  [17.4, 22.1484375, 38252117, 2.91],
  [22.15, 25.7080078125, 37135495, 2.7],
  [25.71, 28.377685546875, 36130924, 2.33],
  [28.38, 31.04736328125, 35285940, 2.61],
  [31.05, 33.717041015625, 34364413, 2.89],
  [33.72, 35.71929931640625, 33368262, 2.38],
  [35.72, 37.7215576171875, 32573423, 2.56],
  [37.72, 39.72381591796875, 31738714, 2.75],
  [39.72, 41.72607421875, 30865121, 2.95],
  [41.73, 43.227767944335938, 29953681, 2.36],
  [43.23, 44.729461669921875, 29245913, 2.48],
  [44.73, 46.231155395507812, 28517939, 2.62],
  [46.23, 47.73284912109375, 27770248, 2.76],
  [47.73, 49.234542846679688, 27003344, 2.9],
  [49.23, 50.360813140869141, 26217745, 2.29],
  [50.36, 51.487083435058594, 25616595, 2.38],
  [51.49, 52.613353729248047, 25005457, 2.48],
  [52.61, 53.7396240234375, 24384564, 2.58],
  [53.74, 54.865894317626953, 23754152, 2.69],
  [54.87, 55.992164611816406, 23114464, 2.8],
  [55.99, 57.118434906005859, 22465745, 2.92],
  [57.12, 57.963137626647949, 21808245, 2.28],
  [57.96, 58.807840347290039, 21309508, 2.36],
  [58.81, 59.652543067932129, 20806081, 2.44],
  [59.65, 60.497245788574219, 20298074, 2.52],
  [60.5, 61.341948509216309, 19785597, 2.61],
  [61.34, 62.186651229858398, 19268762, 2.7],
  [62.19, 63.031353950500488, 18747680, 2.8],
  [63.03, 63.876056671142578, 18222465, 2.9],
  [63.88, 64.509583711624146, 17693232, 2.25],
  [64.51, 65.143110752105713, 17293739, 2.32],
  [65.14, 65.77663779258728, 16892100, 2.39],
  [65.78, 66.410164833068848, 16488364, 2.46],
  [66.41, 67.043691873550415, 16082582, 2.53],
  [67.04, 67.677218914031982, 15674801, 2.61],
  [67.68, 68.31074595451355, 15265074, 2.69],
  [68.31, 68.944272994995117, 14853450, 2.78],
  [68.94, 69.577800035476685, 14439980, 2.87],
  [69.58, 70.211327075958252, 14024715, 2.97],
  [70.21, 70.686472356319427, 13607707, 2.3],
  [70.69, 71.161617636680603, 13293838, 2.36],
  [71.16, 71.636762917041779, 12979039, 2.43],
  [71.64, 72.111908197402954, 12663331, 2.5],
  [72.11, 72.58705347776413, 12346738, 2.57],
  [72.59, 73.062198758125305, 12029281, 2.64],
  [73.06, 73.537344038486481, 11710981, 2.72],
  [73.54, 74.012489318847656, 11391862, 2.8],
  [74.01, 74.487634599208832, 11071946, 2.89],
  [74.49, 74.962779879570007, 10751254, 2.98],
  [74.96, 75.319138839840889, 10429810, 2.31],
  [75.32, 75.675497800111771, 10188246, 2.37],
  [75.68, 76.031856760382652, 9946280, 2.43],
  [76.03, 76.388215720653534, 9703923, 2.5],
  [76.39, 76.744574680924416, 9461183, 2.56],
  [76.74, 77.100933641195297, 9218071, 2.64],
  [77.1, 77.457292601466179, 8974595, 2.71],
  [77.46, 77.813651561737061, 8730766, 2.79],
  [77.81, 78.170010522007942, 8486593, 2.88],
  [78.17, 78.526369482278824, 8242085, 2.97],
  [78.53, 78.793638702481985, 7997252, 2.29],
  [78.79, 79.060907922685146, 7813420, 2.35],
  [79.06, 79.328177142888308, 7629414, 2.41],
  [79.33, 79.595446363091469, 7445240, 2.47],
  [79.6, 79.86271558329463, 7260900, 2.54],
  [79.86, 80.129984803497791, 7076399, 2.6],
  [80.13, 80.397254023700953, 6891742, 2.68],
  [80.4, 80.664523243904114, 6706931, 2.75],
  [80.66, 80.931792464107275, 6521972, 2.83],
  [80.93, 81.199061684310436, 6336868, 2.92],
  [81.2, 81.399513599462807, 6151624, 2.25],
  [81.4, 81.599965514615178, 6012600, 2.31],
  [81.6, 81.800417429767549, 5873502, 2.36],
  [81.8, 82.00086934491992, 5734331, 2.42],
  [82.0, 82.201321260072291, 5595088, 2.48],
  [82.2, 82.401773175224662, 5455775, 2.55],
  [82.4, 82.602225090377033, 5316394, 2.62],
  [82.6, 82.802677005529404, 5176947, 2.69],
  [82.8, 83.003128920681775, 5037435, 2.77],
  [83.0, 83.203580835834146, 4897860, 2.85],
  [83.2, 83.404032750986516, 4758224, 2.93],
  [83.4, 83.554371687350795, 4618528, 2.26],
  [83.55, 83.704710623715073, 4513719, 2.32],
  [83.7, 83.855049560079351, 4408878, 2.37],
  [83.86, 84.005388496443629, 4304006, 2.43],
  [84.01, 84.155727432807907, 4199104, 2.49],
  [84.16, 84.306066369172186, 4094172, 2.56],
  [84.31, 84.456405305536464, 3989211, 2.63],
  [84.46, 84.606744241900742, 3884223, 2.7],
  [84.61, 84.75708317826502, 3779207, 2.77],
  [84.76, 84.907422114629298, 3674165, 2.85],
  [84.91, 85.057761050993577, 3569096, 2.94],
  [85.06, 85.170515253266785, 3464003, 2.27],
  [85.17, 85.283269455539994, 3385167, 2.32],
  [85.28, 85.396023657813203, 3306318, 2.38],
  [85.4, 85.508777860086411, 3227456, 2.44],
  [85.51, 85.62153206235962, 3148581, 2.5],
  [85.62, 85.734286264632829, 3069693, 2.57],
  [85.73, 85.847040466906037, 2990793, 2.63],
  [85.85, 85.959794669179246, 2911882, 2.71],
  [85.96, 86.072548871452454, 2832959, 2.78],
  [86.07, 86.185303073725663, 2754025, 2.86],
  [86.19, 86.298057275998872, 2675080, 2.95],
  [86.3, 86.382622927703778, 2596124, 2.28],
  [86.38, 86.467188579408685, 2536901, 2.33],
  [86.47, 86.551754231113591, 2477672, 2.39],
  [86.55, 86.636319882818498, 2418437, 2.44],
  [86.64, 86.720885534523404, 2359197, 2.51],
  [86.72, 86.805451186228311, 2299952, 2.57],
  [86.81, 86.890016837933217, 2240701, 2.64],
  [86.89, 86.974582489638124, 2181446, 2.71],
  [86.97, 87.05914814134303, 2122186, 2.79],
  [87.06, 87.143713793047937, 2062921, 2.87],
  [87.14, 87.228279444752843, 2003652, 2.95],
  [87.23, 87.291703683531523, 1944378, 2.28],
  [87.29, 87.355127922310203, 1899919, 2.34],
  [87.36, 87.418552161088883, 1855459, 2.39],
  [87.42, 87.481976399867563, 1810996, 2.45],
  [87.48, 87.545400638646242, 1766531, 2.51],
  [87.55, 87.608824877424922, 1722063, 2.58],
  [87.61, 87.672249116203602, 1677594, 2.65],
  [87.67, 87.735673354982282, 1633122, 2.72],
  [87.74, 87.799097593760962, 1588648, 2.79],
  [87.8, 87.862521832539642, 1544172, 2.88],
  [87.86, 87.925946071318322, 1499695, 2.96],
  [87.93, 87.973514250402332, 1455215, 2.29],
  [87.97, 88.021082429486341, 1421854, 2.34],
  [88.02, 88.068650608570351, 1388493, 2.4],
  [88.07, 88.116218787654361, 1355130, 2.46],
  [88.12, 88.163786966738371, 1321766, 2.52],
  [88.16, 88.211355145822381, 1288401, 2.58],
  [88.21, 88.258923324906391, 1255036, 2.65],
  [88.26, 88.306491503990401, 1221669, 2.73],
  [88.31, 88.354059683074411, 1188302, 2.8],
  [88.35, 88.401627862158421, 1154934, 2.88],
  [88.4, 88.449196041242431, 1121565, 2.97],
  [88.45, 88.484872175555438, 1088195, 2.29],
  [88.48, 88.520548309868445, 1063167, 2.35],
  [88.52, 88.556224444181453, 1038139, 2.41],
  [88.56, 88.59190057849446, 1013110, 2.47],
  [88.59, 88.627576712807468, 988081, 2.53],
  [88.63, 88.663252847120475, 963052, 2.59],
  [88.66, 88.698928981433482, 938022, 2.66],
  [88.7, 88.73460511574649, 912992, 2.74],
  [88.73, 88.770281250059497, 887961, 2.81],
  [88.77, 88.805957384372505, 862930, 2.9],
  [88.81, 88.841633518685512, 837899, 2.98],
  [88.84, 88.868390619420268, 812867, 2.3],
  [88.87, 88.895147720155023, 794093, 2.36],
  [88.9, 88.921904820889779, 775319, 2.42],
  [88.92, 88.948661921624534, 756545, 2.48],
  [88.95, 88.97541902235929, 737771, 2.54],
  [88.98, 89.002176123094046, 718996, 2.61],
  [89.0, 89.028933223828801, 700221, 2.68],
  [89.03, 89.055690324563557, 681446, 2.75],
  [89.06, 89.082447425298312, 662671, 2.83],
  [89.08, 89.109204526033068, 643896, 2.91],
  [89.11, 89.129272351584135, 625121, 2.25],
  [89.13, 89.149340177135201, 611039, 2.3],
  [89.15, 89.169408002686268, 596957, 2.35],
  [89.17, 89.189475828237335, 582876, 2.41],
  [89.19, 89.209543653788401, 568794, 2.47],
  [89.21, 89.229611479339468, 554712, 2.53],
  [89.23, 89.249679304890535, 540630, 2.6],
  [89.25, 89.269747130441601, 526548, 2.67],
  [89.27, 89.289814955992668, 512466, 2.74],
  [89.29, 89.309882781543735, 498384, 2.82],
  [89.31, 89.329950607094801, 484302, 2.9],
  [89.33, 89.350018432645868, 470219, 2.99],
  [89.35, 89.365069301809172, 456137, 2.31],
  [89.37, 89.380120170972475, 445575, 2.37],
  [89.38, 89.395171040135779, 435013, 2.42],
  [89.4, 89.410221909299082, 424451, 2.48],
  [89.41, 89.425272778462386, 413889, 2.55],
  [89.43, 89.440323647625689, 403328, 2.61],
  [89.44, 89.455374516788993, 392766, 2.68],
  [89.46, 89.470425385952296, 382204, 2.76],
  [89.47, 89.4854762551156, 371642, 2.84],
  [89.49, 89.500527124278904, 361080, 2.92],
  [89.5, 89.511815276151381, 350518, 2.26],
  [89.51, 89.523103428023859, 342596, 2.31],
  [89.52, 89.534391579896337, 334674, 2.36],
  [89.53, 89.545679731768814, 326753, 2.42],
  [89.55, 89.556967883641292, 318831, 2.48],
  [89.56, 89.56825603551377, 310910, 2.54],
  [89.57, 89.579544187386247, 302988, 2.61],
  [89.58, 89.590832339258725, 295066, 2.68],
  [89.59, 89.602120491131203, 287145, 2.75],
  [89.6, 89.61340864300368, 279223, 2.83],
  [89.61, 89.624696794876158, 271301, 2.91],
  [89.62, 89.63316290878052, 263380, 2.25],
  [89.63, 89.641629022684882, 257438, 2.3],
  [89.64, 89.650095136589243, 251497, 2.36],
  [89.65, 89.658561250493605, 245556, 2.41],
  [89.66, 89.667027364397967, 239615, 2.47],
  [89.67, 89.675493478302329, 233673, 2.54],
  [89.68, 89.683959592206691, 227732, 2.6],
  [89.68, 89.692425706111052, 221791, 2.67],
  [89.69, 89.700891820015414, 215849, 2.75],
  [89.7, 89.709357933919776, 209908, 2.83],
  [89.71, 89.717824047824138, 203967, 2.91],
  [89.72, 89.724173633252406, 198026, 2.25],
  [89.72, 89.730523218680673, 193570, 2.3],
  [89.73, 89.736872804108941, 189114, 2.35],
  [89.74, 89.743222389537209, 184658, 2.41],
  [89.74, 89.749571974965477, 180202, 2.47],
  [89.75, 89.755921560393745, 175746, 2.53],
  [89.76, 89.762271145822012, 171290, 2.6],
  [89.76, 89.76862073125028, 166834, 2.67],
  [89.77, 89.774970316678548, 162378, 2.74],
  [89.77, 89.781319902106816, 157922, 2.82],
  [89.78, 89.787669487535084, 153466, 2.9],
  [89.79, 89.794019072963351, 149010, 2.99],
  [89.79, 89.798781262034552, 144554, 2.31],
  [89.8, 89.803543451105753, 141212, 2.36],
  [89.8, 89.808305640176954, 137869, 2.42],
  [89.81, 89.813067829248155, 134527, 2.48],
  [89.81, 89.817830018319356, 131185, 2.54],
  [89.82, 89.822592207390556, 127843, 2.61],
  [89.82, 89.827354396461757, 124501, 2.68],
  [89.83, 89.832116585532958, 121159, 2.75],
  [89.83, 89.836878774604159, 117817, 2.83],
  [89.84, 89.84164096367536, 114475, 2.91],
  [89.84, 89.845212605478764, 111133, 2.25],
  [89.85, 89.848784247282168, 108627, 2.3],
  [89.85, 89.852355889085572, 106120, 2.36],
  [89.85, 89.855927530888977, 103614, 2.41],
  [89.86, 89.859499172692381, 101107, 2.47],
  [89.86, 89.863070814495785, 98601, 2.54],
  [89.86, 89.866642456299189, 96094, 2.6],
  [89.87, 89.870214098102593, 93588, 2.67],
  [89.87, 89.873785739905998, 91081, 2.75],
  [89.87, 89.877357381709402, 88575, 2.83],
  [89.88, 89.880929023512806, 86068, 2.91],
  [89.88, 89.883607754865352, 83562, 2.24],
  [89.88, 89.886286486217898, 81682, 2.3],
  [89.89, 89.888965217570444, 79802, 2.35],
  [89.89, 89.89164394892299, 77922, 2.41],
  [89.89, 89.894322680275536, 76042, 2.47],
  [89.89, 89.897001411628082, 74162, 2.53],
  [89.9, 89.899680142980628, 72282, 2.6],
  [89.9, 89.902358874333174, 70402, 2.66],
  [89.9, 89.90503760568572, 68523, 2.74],
  [89.91, 89.907716337038266, 66643, 2.82],
  [89.91, 89.910395068390812, 64763, 2.9],
  [89.91, 89.913073799743358, 62883, 2.98],
  [89.91, 89.915082848257768, 61003, 2.31],
  [89.92, 89.917091896772178, 59593, 2.36],
  [89.92, 89.919100945286587, 58183, 2.42],
  [89.92, 89.921109993800997, 56773, 2.48],
  [89.92, 89.923119042315406, 55363, 2.54],
  [89.92, 89.925128090829816, 53953, 2.61],
  [89.93, 89.927137139344225, 52543, 2.68],
  [89.93, 89.929146187858635, 51134, 2.75],
  [89.93, 89.931155236373044, 49724, 2.83],
  [89.93, 89.933164284887454, 48314, 2.91],
  [89.93, 89.934671071273257, 46904, 2.25],
  [89.93, 89.936177857659061, 45846, 2.3],
  [89.94, 89.937684644044865, 44789, 2.36],
  [89.94, 89.939191430430668, 43731, 2.41],
  [89.94, 89.940698216816472, 42674, 2.47],
  [89.94, 89.942205003202275, 41617, 2.54],
  [89.94, 89.943711789588079, 40559, 2.6],
  [89.94, 89.945218575973882, 39502, 2.67],
  [89.95, 89.946725362359686, 38444, 2.74],
  [89.95, 89.94823214874549, 37387, 2.82],
  [89.95, 89.949738935131293, 36329, 2.9],
]
