import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { memo } from 'utils/react'
import { CommonTextLabel } from 'components/common'
import { getInvoiceLineData, canChangeWorkOrderStatusSelector } from 'data/work-order/workOrderSelectors'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import WorkOrderStatusAlert from 'components/work-order/WorkOrderStatusAlert'

import EditServiceDialog from './EditServiceDialog'

const ServiceDetails = ({ service = {}, workOrderId = '', workOrderStatus = '', invoiceLine = {}, onRouteClick }) => {
  const [isOpenEditWODialog, setIsOpenEditWODialog] = useState(false)
  const { routeName, driverName } = service
  const { invoiceStatus } = getInvoiceLineData({ invoiceLine })
  const canChangeWOStatus = canChangeWorkOrderStatusSelector({ workOrderStatus, invoiceStatus })

  return (
    <HHAccordion
      withNewColors
      isExpanded
      isExpandCollapseHeightSame
      summary={T.SERVICE_DETAILS}
      showActionIcon={canChangeWOStatus}
      onActionClick={() => setIsOpenEditWODialog(true)}
    >
      <Box px={2} py={1.5}>
        {!workOrderStatus && <Box className="no-results">{T.NO_WO_SERVICE_DETAILS}</Box>}

        <Box>
          <Box>
            <CommonTextLabel title={T.ROUTE} className="ttc" />
            <Typography variant="body1" fontWeight={500} className="word-wrap-break-word" onClick={onRouteClick}>
              {routeName || '-'}
            </Typography>
          </Box>

          <Box mt={2}>
            <CommonTextLabel title={T.DRIVER} className="ttc" />
            <Typography variant="body1" fontWeight={500} className="word-wrap-break-word">
              {driverName || '-'}
            </Typography>
          </Box>

          <Box mt={2}>
            <CommonTextLabel title={T.STATUS} className="ttc" />
            {workOrderStatus && <WorkOrderStatusAlert status={workOrderStatus} />}
          </Box>
        </Box>
      </Box>
      <EditServiceDialog
        isOpen={isOpenEditWODialog}
        currentWOStatus={workOrderStatus}
        workOrderId={workOrderId}
        onClose={() => setIsOpenEditWODialog(false)}
      />
    </HHAccordion>
  )
}

ServiceDetails.propTypes = {
  service: PropTypes.object,
  workOrderId: PropTypes.string,
  workOrderStatus: PropTypes.string,
  invoiceLine: PropTypes.object,
  onRouteClick: PropTypes.func.isRequired,
}

export default memo(ServiceDetails)
