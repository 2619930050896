import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import {
  actions,
  putGlobalSearchResult,
  putGlobalSearchFilter,
  putGlobalFilterOptions,
  putSearchSuggestionList,
  putContactSearchSuggestionList,
  putPricedServiceSearchSuggestionList,
  putConfiguredServiceSearchSuggestionList,
  putNoteSearchSuggestionList,
  putFollowUpSearchSuggestionList,
  putWorkOrderSearchSuggestionList,
} from '../actions/globalsearch'

import { getGlobalSearchFilterRequestPair } from 'components/globalfilter/filterUrlResultPair.js'

import endpoint from './endpoint'

export const getGlobalSearchResult = ({ body, loader }) => doPost({ url: endpoint.global.search, body, showLoader: loader })
export const getGlobalSearchFilterResult = ({ url, body }) => doPost({ url: url, body, showLoader: true })
export const getFilterOptionsResult = ({ body }) => doPost({ url: endpoint.meta.filters, body })
export const getContactSuggestionResults = ({ body }) => doPost({ url: endpoint.contact.suggest, body })
export const getPricedServiceSuggestionResults = ({ body }) => doPost({ url: endpoint.pricing.service.suggest, body })
export const getConfiguredServiceSuggestionResults = ({ body }) => doPost({ url: endpoint.configuredService.suggest, body })
export const getNoteSuggestionResults = ({ body }) => doPost({ url: endpoint.notes.suggest, body })
export const getWorkOrderSuggestionResults = ({ body }) => doPost({ url: endpoint.workOrder.suggest, body })
export const getFollowUpSuggestionResults = ({ body }) => doPost({ url: endpoint.followUp.suggest, body })

export function* getGlobalSearchResultSaga({ payload }) {
  const { body, onSuccess } = payload
  const loader = true

  try {
    const { data: res } = yield call(getGlobalSearchResult, { body, loader })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }

    yield put(putGlobalSearchResult(res))
  } catch (err) {
    yield put(putGlobalSearchResult(null))
  }
}

export function* getGlobalSearchFilterSaga({ payload }) {
  const { body, onSuccess } = payload

  const { url, resultKey: resultCategory } = getGlobalSearchFilterRequestPair(body.categoryName)
  delete body.categoryName
  try {
    const { data: res } = yield call(getGlobalSearchFilterResult, { url, body })

    const { searchInfo, totalItems, totalPages } = res[resultCategory]
    if (typeof onSuccess === 'function') {
      onSuccess(searchInfo, totalItems, totalPages)
    }

    yield put(putGlobalSearchFilter(res[resultCategory]))
  } catch (err) {
    yield put(putGlobalSearchFilter(null))
  }
}

export function* getFilterOptionsSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getFilterOptionsResult, { body })
    yield put(putGlobalFilterOptions(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    console.log(err)
    yield put(putGlobalFilterOptions(null))
  }
}

export function* getSearchSuggestionSaga({ payload }) {
  const { body, onSuccess } = payload
  const loader = false
  try {
    const { data: res } = yield call(getGlobalSearchResult, { body, loader })

    yield put(putSearchSuggestionList(res))
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    yield put(putSearchSuggestionList(null))
  }
}

export function* getContactSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getContactSuggestionResults, { body })
    const { contact } = res

    yield put(putContactSearchSuggestionList(contact))
    if (typeof onSuccess === 'function') {
      onSuccess(contact)
    }
  } catch (err) {
    yield put(putContactSearchSuggestionList(null))
  }
}

export function* getPricedServiceSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getPricedServiceSuggestionResults, { body })

    const { pricedService } = res

    yield put(putPricedServiceSearchSuggestionList(pricedService))
    if (typeof onSuccess === 'function') {
      onSuccess(pricedService)
    }
  } catch (err) {
    yield put(putPricedServiceSearchSuggestionList(null))
  }
}

export function* getConfiguredServiceSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getConfiguredServiceSuggestionResults, { body })
    const { configuredService } = res

    yield put(putConfiguredServiceSearchSuggestionList(configuredService))
    if (typeof onSuccess === 'function') {
      onSuccess(configuredService)
    }
  } catch (err) {
    yield put(putConfiguredServiceSearchSuggestionList(null))
  }
}

export function* getNoteSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getNoteSuggestionResults, { body })
    const { notes } = res

    yield put(putNoteSearchSuggestionList(notes))
    if (typeof onSuccess === 'function') {
      onSuccess(notes)
    }
  } catch (err) {
    yield put(putNoteSearchSuggestionList(null))
  }
}

export function* getWorkOrderSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getWorkOrderSuggestionResults, { body })

    const { workOrder } = res

    yield put(putWorkOrderSearchSuggestionList(workOrder))
    if (typeof onSuccess === 'function') {
      onSuccess(workOrder)
    }
  } catch (err) {
    yield put(putWorkOrderSearchSuggestionList(null))
  }
}

export function* getFollowUpSuggestionListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getFollowUpSuggestionResults, { body })
    const { followUp } = res

    yield put(putFollowUpSearchSuggestionList(followUp))
    if (typeof onSuccess === 'function') {
      onSuccess(followUp)
    }
  } catch (err) {
    yield put(putFollowUpSearchSuggestionList(null))
  }
}

export default function* GlobalSearch() {
  yield all([
    takeEvery(actions.GET.GLOBAL_SEARCH_RESULT, getGlobalSearchResultSaga),
    takeEvery(actions.GET.GLOBAL_SEARCH_FILTER, getGlobalSearchFilterSaga),
    takeEvery(actions.GET.GLOBAL_FILTER_OPTIONS, getFilterOptionsSaga),
    takeEvery(actions.GET.SEARCH_SUGGEST_RESULT, getSearchSuggestionSaga),
    takeEvery(actions.GET.SUGGEST_CONTACT_LIST, getContactSuggestionListSaga),
    takeEvery(actions.GET.SUGGEST_PRICED_SERVICE_LIST, getPricedServiceSuggestionListSaga),
    takeEvery(actions.GET.SUGGEST_CONFIGURED_SERVICE_LIST, getConfiguredServiceSuggestionListSaga),
    takeEvery(actions.GET.SUGGEST_NOTE_LIST, getNoteSuggestionListSaga),
    takeEvery(actions.GET.SUGGEST_WORK_ORDER_LIST, getWorkOrderSuggestionListSaga),
    takeEvery(actions.GET.SUGGEST_FOLLOW_UP_LIST, getFollowUpSuggestionListSaga),
  ])
}
