import React from 'react'
import PropTypes from 'prop-types'
import invert from 'lodash/invert'

import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import { lightBlue } from '@mui/material/colors'

import { get } from 'utils/lodash'
import { PAYMENT_METHODS, PAYMENT_METHODS_LABELS } from 'settings/constants/payment'
import { getPaymentTypeStats } from 'data/groups/paymentGroupsSelectors'
import { setChartSummaryDialogState } from 'slices/groups/PaymentGroupsSlice'
import T from 'T'
import GroupPieChart from '../../common/GroupPieChart'
import GroupChartOverlay from '../../common/GroupChartOverlay'
import GraphCardEmptyState from '../../common/GraphCardEmptyState'
import ChartLegend from '../../common/ChartLegend'
import PaymentStatsTooltip from '../common/PaymentStatsTooltip'

const { CHECK, CASH, OTHER, CREDIT_CARD, ACH, ACCOUNT_CREDIT } = PAYMENT_METHODS

const COLOR_MAP = {
  [CASH]: lightBlue[50],
  [CHECK]: lightBlue[100],
  [ACCOUNT_CREDIT]: lightBlue[200],
  [ACH]: lightBlue[300],
  [OTHER]: lightBlue[400],
  [CREDIT_CARD]: lightBlue[500],
}

const PaymentBatchStatistic = ({ payments = [] }) => {
  const dispatch = useDispatch()
  const paymentTypeData = getPaymentTypeStats({ payments })
  const check = get(paymentTypeData, `${CHECK}.count`, 0)
  const cash = get(paymentTypeData, `${CASH}.count`, 0)
  const other = get(paymentTypeData, `${OTHER}.count`, 0)
  const creditCard = get(paymentTypeData, `${CREDIT_CARD}.count`, 0)
  const ach = get(paymentTypeData, `${ACH}.count`, 0)
  const accountCredit = get(paymentTypeData, `${ACCOUNT_CREDIT}.count`, 0)
  const totalCount = check + cash + other + creditCard + ach + accountCredit
  const isChartEmpty = totalCount === 0
  const invertedLabels = invert(PAYMENT_METHODS_LABELS)

  const data = [
    {
      label: PAYMENT_METHODS_LABELS[CASH],
      id: PAYMENT_METHODS_LABELS[CASH],
      value: cash,
      color: COLOR_MAP[CASH],
    },
    {
      label: PAYMENT_METHODS_LABELS[CHECK],
      id: PAYMENT_METHODS_LABELS[CHECK],
      value: check,
      color: COLOR_MAP[CHECK],
    },
    {
      label: PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT],
      id: PAYMENT_METHODS_LABELS[ACCOUNT_CREDIT],
      value: accountCredit,
      color: COLOR_MAP[ACCOUNT_CREDIT],
    },
    {
      label: PAYMENT_METHODS_LABELS[ACH],
      id: PAYMENT_METHODS_LABELS[ACH],
      value: ach,
      color: COLOR_MAP[ACH],
    },
    {
      label: PAYMENT_METHODS_LABELS[OTHER],
      id: PAYMENT_METHODS_LABELS[OTHER],
      value: other,
      color: COLOR_MAP[OTHER],
    },
    {
      label: PAYMENT_METHODS_LABELS[CREDIT_CARD],
      id: PAYMENT_METHODS_LABELS[CREDIT_CARD],
      value: creditCard,
      color: COLOR_MAP[CREDIT_CARD],
    },
  ]

  const renderPaymentBatchTooltip = params => {
    const { label, value, color } = params?.datum || {}
    const { rows, total } = get(paymentTypeData, invertedLabels[label])
    return <PaymentStatsTooltip color={color} label={label} value={value} total={total} isBatch rows={rows} />
  }

  const handleClick = ({ label, value, color }) => {
    if (value > 0) {
      const { rows, total } = get(paymentTypeData, invertedLabels[label])
      dispatch(setChartSummaryDialogState({ isOpen: true, color, label, value, total, rows, isBatch: true }))
    }
  }

  return (
    <>
      {isChartEmpty && <GraphCardEmptyState title={T.NO_PAYMENT_DATA} />}
      {!isChartEmpty && (
        <>
          <Box height={180} position="relative">
            <GroupPieChart
              data={data}
              colors={Object.values(COLOR_MAP)}
              legends={[]}
              tooltip={renderPaymentBatchTooltip}
              onClick={handleClick}
            />
            <GroupChartOverlay overlayValue={totalCount} />
          </Box>
          <ChartLegend data={data} mt={-3.5} showZeroValueLegend={false} />
        </>
      )}
    </>
  )
}

PaymentBatchStatistic.propTypes = {
  payments: PropTypes.array,
}

export default PaymentBatchStatistic
