import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import { INVOICE_STATUS } from 'settings/constants/billing'
import EventBlocker from 'components/common/EventBlocker'
import LateFeesPopover from 'components/pricing/LateFees/LateFeesListing/LateFeesPopover'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const LateInvoiceItem = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = () => setIsExpanded(!isExpanded)
  return (
    <Accordion
      elevation={0}
      expanded={isExpanded}
      sx={{
        '&.Mui-expanded': {
          m: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
          mb: 0,
          mt: 0,
        },
        '&:before': {
          display: 'none',
        },
      }}
      disablePointer
      expandIcon={undefined}
      iconGap={false}
    >
      <AccordionSummary
        sx={{
          p: 0,
        }}
      >
        <ListItem
          sx={{
            pr: 6,
          }}
          divider
        >
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
            <Stack direction="row" alignItems="center" columnGap={2}>
              <IconButton onClick={toggleIsExpanded}>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
              <InvoiceStatusChip status={UNPAID} agingBucketType={DUE_SIXTY_ONE_NINETY} />
            </Stack>
          </ListItemIcon>
          <ListItemText
            primary="January 2024 | $200"
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold',
              color: 'textPrimary',
            }}
            secondary="221203001008 | 01/01/2023"
            secondaryTypographyProps={{
              variant: 'body1',
              color: 'textSecondary',
            }}
          />
          <EventBlocker>
            <LateFeesPopover />
          </EventBlocker>
        </ListItem>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
        }}
      />
    </Accordion>
  )
}

LateInvoiceItem.propTypes = {}

export default LateInvoiceItem
