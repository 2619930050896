import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Typography, Divider, IconButton, Box, Tabs, Tab, List, Pagination } from '@mui/material'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { ReactComponent as LocationNoteIcon } from 'assets/note/LocationNote.svg'

import { getActiveDeactivatedLocations } from 'utils/location'
import { Plus } from '@styled-icons/heroicons-outline/Plus'

import { get } from 'utils/lodash'
import { getCustomerDetailsPageUrl, isCustomerDetailsPage } from 'router/routes'
import { onChangeTab } from 'slices/customer/customerDetailsSlice'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'
import { useLazyGetLocationsNotesCountQuery } from 'api/notes/getLocationsNotesCount'
import { CACHE_TAG_ACCOUNT_DETAILS_BY_ID } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import LocationNotesDialogVariant from 'components/notes/location/LocationNotesDialogVariant'
import StyledBadge from 'components/notes/common/StyledBadge'

import { getCustomerDetails } from 'middleware/actions/customers'
import { CUSTOMER_DETAILS_DEFAULT_PAGINATION } from 'settings/constants/pagination'
import HHTablePagination from 'components/common/HHTablePagination'
import LocationView from 'components/customer-details/content/location/list/LocationView'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LocationActionMenu from '../LocationActionMenu'
import AddEditLocationDialog from '../add-edit-dialog'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../tables/StyledAccordian'

const LOCATION_TABS = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
}

const { ACTIVE, DEACTIVATED } = LOCATION_TABS
const { SERVICES_TAB_ID } = CUSTOMER_DETAILS_TABS_ID
const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CUSTOMER_DETAILS_DEFAULT_PAGINATION

const AllLocations = ({
  customerDetails = {},
  accountId,
  showAddLocationIcon = true,
  isViewOnly = false,
  showContacts = true,
  paginationSiblingCount = 1,
  onLocationChange = noop,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const locationHook = useLocation()
  const [getLocationsNotesCount, { data }] = useLazyGetLocationsNotesCountQuery()
  const allLocationsNotesCount = get(data, 'counts', [])

  const [isLocationsExpanded, setIsLocationsExpanded] = useState(true)
  const [isOpenAddLocationDialog, setIsOpenAddLocationDialog] = useState(false)
  const [selectedTab, setSelectedTab] = useState(ACTIVE)
  const [locationAnchorEl, setLocationAnchorEl] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [isOpenLocationNotesDialog, setIsOpenLocationNotesDialog] = useState(false)
  const [selectedNoteLocationId, setSelectedNoteLocationId] = useState('')
  const [pagination, setPagination] = useState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
  const { page, rowsPerPage } = pagination

  const isActiveTab = selectedTab === ACTIVE
  const { activeLocations, deactivatedLocations } = getActiveDeactivatedLocations(customerDetails, true)
  const currentTabLocations = isActiveTab ? activeLocations : deactivatedLocations
  const locationId = get(selectedLocation, 'id')
  const dropdownLocationId = useSelector(state => get(state, 'CustomerDetails.selectedLocationId', ''))
  const handleLocationsAccChange = () => setIsLocationsExpanded(!isLocationsExpanded)

  const currentTabLocationsCount = currentTabLocations.length
  const currentPageLocations = currentTabLocations.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  const currentPageLocationsLength = currentPageLocations.length

  const handlePageChange = (newPage = INITIAL_PAGE) => setPagination(p => ({ ...p, page: newPage }))

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const onLocationStatusChange = () => {
    // If current location is equal to the selected location in the services tab then change url to account
    if (locationId === dropdownLocationId) {
      onLocationChange()
    }
    if (isCustomerDetailsPage()) {
      dispatch(getCustomerDetails({ accountId }))
    }
    dispatch(api.util.invalidateTags([{ type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId }]))
  }

  const handleOpenLocationNotesDialog = (event, id) => {
    event.stopPropagation()
    setSelectedNoteLocationId(id)
    setIsOpenLocationNotesDialog(true)
  }

  const handleCloseLocationNotesDialog = () => {
    setIsOpenLocationNotesDialog(false)
    setSelectedNoteLocationId('')
  }

  const handleActionMenuIconClick = (event, location) => {
    event.stopPropagation()
    setSelectedLocation(location)
    setLocationAnchorEl(event.currentTarget)
  }

  const handleLocationRowClick = id => {
    if (isActiveTab) {
      history.push({
        pathname: getCustomerDetailsPageUrl(accountId),
        search: `?tab=${SERVICES_TAB_ID}&location=${id}`,
        state: get(locationHook, 'state', null),
        tab: get(locationHook, 'tab', null),
      })
      dispatch(onChangeTab(SERVICES_TAB_ID))
    }
  }

  useEffect(() => {
    getLocationsNotesCount({ accountId })
  }, [])

  useEffect(() => {
    if (currentPageLocationsLength === 0 && currentTabLocationsCount > 0) {
      handlePageChange()
    }
  }, [currentPageLocationsLength, currentTabLocationsCount])

  return (
    <>
      <Accordion expanded={isLocationsExpanded}>
        <AccordionSummary
          withNewColors
          sx={{
            '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
              mb: 0,
            },
          }}
          disablePointer
          expandIcon={undefined}
          iconGap={false}
        >
          <Grid container alignItems="center">
            <Grid item mt="6px">
              {isLocationsExpanded ? (
                <ArrowDropDown cursor="pointer" onClick={handleLocationsAccChange} color="disabled" />
              ) : (
                <ArrowRight cursor="pointer" onClick={handleLocationsAccChange} color="disabled" />
              )}
            </Grid>
            <Grid item xs>
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="h6"
                fontWeight={500}
                margin="auto 20px auto 8px"
                onClick={handleLocationsAccChange}
              >
                {T.LOCATIONS}
              </Typography>
            </Grid>
            {showAddLocationIcon && (
              <Grid item xs display="flex" justifyContent="flex-end">
                <IconButton sx={{ color: 'text.secondary' }} onClick={() => setIsOpenAddLocationDialog(true)}>
                  <Plus width={20} height={20} />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <Tabs value={selectedTab} onChange={handleTabChange} sx={{ button: { textTransform: 'capitalize' } }}>
                <Tab value={ACTIVE} label={T.ACTIVE} />
                <Tab value={DEACTIVATED} label={T.DEACTIVATED} />
              </Tabs>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Box width="100%">
            <Box borderTop="1px solid" borderBottom="1px solid" borderColor="border.light">
              {currentTabLocationsCount > 0 && (
                <List disablePadding>
                  {Children.toArray(
                    currentPageLocations.map((location, index) => {
                      const { id } = location
                      const currentLocationNotesCount = allLocationsNotesCount.find(loc => loc.locationId === id)
                      return (
                        <>
                          <LocationView
                            sx={{ cursor: isActiveTab ? 'pointer' : 'default' }}
                            accountId={accountId}
                            selectedTab={selectedTab}
                            location={location}
                            showLocationAvatar={!isActiveTab}
                            showServiceCount={isActiveTab}
                            showContacts={showContacts}
                            action={
                              !isViewOnly && (
                                <>
                                  <IconButton size="small" onClick={event => handleOpenLocationNotesDialog(event, id)}>
                                    <StyledBadge badgeContent={get(currentLocationNotesCount, 'notesCount', 0)}>
                                      <LocationNoteIcon />
                                    </StyledBadge>
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={event =>
                                      handleActionMenuIconClick(event, {
                                        ...location,
                                        archivedNotesCount: get(currentLocationNotesCount, 'archivedNotesCount', 0),
                                      })
                                    }
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </>
                              )
                            }
                            onRowClick={() => handleLocationRowClick(id)}
                          />
                          {currentTabLocations.length - 1 !== index && <Divider />}
                        </>
                      )
                    })
                  )}
                </List>
              )}
            </Box>
            {currentTabLocationsCount > 0 && (
              <Box backgroundColor="background.header" pl={3.5} pr={1.5} display="flex" alignItems="center" justifyContent="space-between">
                <HHTablePagination
                  page={page}
                  component="div"
                  count={currentTabLocationsCount}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(event, newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onRowsPerPageChange={event => setPagination({ page: INITIAL_PAGE, rowsPerPage: event.target.value })}
                  ActionsComponent={params => <Box display="none" {...params} />}
                />
                <Pagination
                  color="primary"
                  count={Math.ceil(currentTabLocationsCount / rowsPerPage)}
                  page={page + 1}
                  onChange={(event, newPage) => handlePageChange(newPage - 1)}
                  siblingCount={paginationSiblingCount}
                />
              </Box>
            )}
          </Box>
          {currentTabLocationsCount === 0 && <HHSectionPlaceholder title={isActiveTab ? T.NO_LOCATIONS : T.NO_DEACTIVATED_LOCATIONS} />}
        </AccordionDetails>
      </Accordion>
      <AddEditLocationDialog isOpen={isOpenAddLocationDialog} accountId={accountId} onClose={() => setIsOpenAddLocationDialog(false)} />
      <LocationActionMenu
        isAccountActive={get(customerDetails, 'active')}
        onLocationStatusChange={onLocationStatusChange}
        anchorEl={locationAnchorEl}
        showArchivedNotesOption
        selectedLocation={selectedLocation}
        accountId={accountId}
        onClose={() => setLocationAnchorEl(null)}
      />
      <LocationNotesDialogVariant
        isOpen={isOpenLocationNotesDialog}
        locationId={selectedNoteLocationId}
        onClose={handleCloseLocationNotesDialog}
      />
    </>
  )
}

AllLocations.propTypes = {
  customerDetails: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  showAddLocationIcon: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  showContacts: PropTypes.bool,
  paginationSiblingCount: PropTypes.number,
  onLocationChange: PropTypes.func,
}

export default AllLocations
