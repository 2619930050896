import React, { Children, useReducer, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  Table as TableView,
  TableRow,
  TableCell,
  TableBody as TBody,
  TableHead as THead,
  Card,
  Typography,
  Box,
  Button,
} from '@mui/material'

import { Add, Cancel } from '@mui/icons-material'
import PricingSwitch from 'components/pricing/PricingSwitch'
import TableSkelton from 'components/common/TableSkelton'
import HHTextField from 'components/form-fields/v5/HHTextField'
import T from 'T'
import { SETTINGS_SKELTON_ROWS } from 'settings/constants/pagination'

import { get } from 'utils/lodash'
import { canAddSettings, canUpdateSettings } from 'data/permissions/permissionsSelectors'
import { useLazyGetSkillListQuery } from 'api/settings/getSkillList'
import { useAddSkillMutation } from 'api/settings/addSkill'
import { handleError } from 'utils/error'
import TableLayout from '../../layout/TableLayout'

const COLUMNS = [{ title: `${T.SKILL}s` }, { title: T.ACTIVE, align: 'right' }]

const SkillsSettings = () => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const [skillState, setSkillState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    skills: [],
    onPlaceEdit: {},
  })

  const [getSkillList, { isLoading, isFetching }] = useLazyGetSkillListQuery()
  const [addSkills, { isLoading: isAddLoading, isFetching: isAddFetching }] = useAddSkillMutation()
  const { skills, onPlaceEdit } = skillState

  const fetchSkills = () => {
    getSkillList()
      .unwrap()
      .then(res => {
        setSkillState({
          skills: get(res, 'skill', []).map(data => ({
            active: data.active,
            skillId: data.id,
            skillName: data.skillName,
          })),
          onPlaceEdit: {},
        })
      })
      .catch(() => {
        setSkillState({
          skills: [],
          onPlaceEdit: {},
        })
      })
  }

  useEffect(() => {
    fetchSkills()
  }, [])

  const handleOnPlaceEdit = index => {
    if (updateSettings) {
      if (Object.keys(onPlaceEdit).length === 0) {
        onPlaceEdit[index] = false
      }

      onPlaceEdit[index] = !onPlaceEdit[index]
      setSkillState({ onPlaceEdit })
    }
  }

  const handleSave = index => {
    const payload = {
      skills: skills.filter(data => data.skillName !== ''),
    }

    setSkillState({ onPlaceEdit: {} })

    addSkills(payload)
      .unwrap()
      .then(res => {
        if (res.skill) {
          fetchSkills()
        }
      })
      .catch(handleError)
  }

  const handleSkillSwitchVal = (value, index) => {
    if (updateSettings) {
      skills[index].active = value

      setSkillState({ skills })
      handleSave(index)
    }
  }

  const onHandleChange = (e, index) => {
    let { value } = e.target

    if (/^\s/.test(value)) {
      value = ''
    }
    skills[index].skillName = value
    setSkillState({ skills })
  }

  const handleSkillRemoval = index => {
    skills.splice(index, 1)
    setSkillState({ skills })
  }

  const addRows = () => {
    skills.push({
      active: true,
      skillId: '',
      skillName: '',
    })

    onPlaceEdit[skills.length - 1] = true
    setSkillState({ skills, onPlaceEdit })
  }

  return (
    <Box>
      <Typography px={4} variant="h3">{`${T.SKILL}s`}</Typography>
      <Card sx={{ mt: 2 }}>
        <TableLayout height="calc(100vh - 135px)">
          <TableView stickyHeader aria-label="sticky table">
            <THead>
              <TableRow>
                {COLUMNS.map(record => (
                  <TableCell sx={{ p: '25px 16px 10px 16px', backgroundColor: 'background.paper' }} align={record.align}>
                    <Typography variant="subtitle2">{record.title}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </THead>

            <TBody>
              {(isLoading || isFetching || isAddLoading || isAddFetching) && <TableSkelton rows={SETTINGS_SKELTON_ROWS} columns={2} />}
              {Children.toArray(
                skills &&
                  skills.map((val, index) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {!onPlaceEdit[index] && (
                            <Typography variant="body1" onClick={() => handleOnPlaceEdit(index)}>
                              {val.skillName}
                            </Typography>
                          )}

                          {onPlaceEdit[index] && Object.keys(onPlaceEdit).length > 0 && (
                            <HHTextField
                              placeholder={T.TYPE_SKILL_HERE}
                              value={val.skillName}
                              variant="outlined"
                              autoComplete="off"
                              name="skillName"
                              InputProps={{
                                onChange: e => onHandleChange(e, index),
                                onKeyDown: event => (event.code === 'Enter' || event.code === 'NumpadEnter') && handleSave(index),
                              }}
                              sx={{
                                width: 278,
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <PricingSwitch checked={val.active} handleSwitchVal={() => handleSkillSwitchVal(!val.active, index)} />

                          {val.skillId === '' && (
                            <Cancel
                              onClick={() => handleSkillRemoval(index)}
                              fontSize="small"
                              sx={{ ml: 2, pt: 0.2, alignItems: 'center', color: 'text.secondary', cursor: 'pointer' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
              )}

              {addSettings && (
                <TableRow>
                  <TableCell colSpan="2">
                    <Button onClick={() => addRows()} variant="text" color="primary" startIcon={<Add />}>
                      {T.ADD_SKILL}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TBody>
          </TableView>
        </TableLayout>
      </Card>
    </Box>
  )
}
export default SkillsSettings
