/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const customerCellSlice = createSlice({
  name: 'customerCell',
  initialState: {
    isOpenConfirmAccountReactivatedDialog: false,
  },
  reducers: {
    openConfirmAccountReactivatedDialog: state => {
      state.isOpenConfirmAccountReactivatedDialog = true
    },
    closeConfirmAccountReactivatedDialog: state => {
      state.isOpenConfirmAccountReactivatedDialog = false
    },
    setIsOpenConfirmAccountReactivatedDialog: (state, action) => {
      state.isOpenConfirmAccountReactivatedDialog = action.payload
    },
  },
})

export const { openConfirmAccountReactivatedDialog, closeConfirmAccountReactivatedDialog, setIsOpenConfirmAccountReactivatedDialog } =
  customerCellSlice.actions

export const selectIsOpenConfirmAccountReactivatedDialog = s => get(s, 'customerCell.isOpenConfirmAccountReactivatedDialog', false)

export default customerCellSlice.reducer
