import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    bulkAddBillingNoteToAccounts: build.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/groups/${id}:add-billing-note`,
        body: data,
        method: 'POST',
      }),
    }),
  }),
})

export const { useBulkAddBillingNoteToAccountsMutation } = extendedApi
