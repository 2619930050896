import React from 'react'
import TabMainLayout from 'components/customer-details/layout/TabMainLayout'
import { Box } from '@mui/material'
import LateFeesAccordion from 'components/customer-details/content/fees/late_fees/LateFeesAccordion'

const FeesTab = () => {
  return (
    <TabMainLayout>
      <Box mx={4} flex={1} minHeight="100vh">
        <Box mt={3}>
          <LateFeesAccordion />
        </Box>
      </Box>
    </TabMainLayout>
  )
}

export default FeesTab
