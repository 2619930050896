import T from 'T'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
// eslint-disable-next-line import/no-cycle
import RenderHeaderCell from '../../../components/data_grid/render_cell/RenderHeaderCell'
import { dateTimeValueFormatter } from '../../../components/data_grid/formatters'
import { RenderPriceCell, RenderStatusCell } from '../../../components/data_grid/render_cell'
// eslint-disable-next-line import/no-cycle
import RenderGetSentInvoiceActionsDataCell from '../../../components/data_grid/render_cell/RenderGetSentInvoicesActionsDataCell'

const { DATE_AND_TIME, CUSTOMERS, TOTAL_INVOICES, TOTAL_REVENUE, PDF, PORTAL, PAPER, STATUS } = T

export const SENT_INVOICES_CONFIGURATOR = [
  { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 50, resizable: false },
  {
    flex: 2,
    label: DATE_AND_TIME,
    headerName: DATE_AND_TIME,
    tableLabel: DATE_AND_TIME,
    columnName: 'sentTime',
    field: 'sentTime',
    filterType: 'date',
    subType: 'dateTime',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateTimeValueFormatter,
  },
  {
    flex: 1,
    tableLabel: CUSTOMERS,
    headerName: CUSTOMERS,
    label: CUSTOMERS,
    columnName: 'totalAccounts',
    field: 'totalAccounts',
    filterType: 'number',
    type: 'number',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    tableLabel: TOTAL_INVOICES,
    headerName: TOTAL_INVOICES,
    label: TOTAL_INVOICES,
    columnName: 'totalInvoices',
    field: 'totalInvoices',
    filterType: 'number',
    type: 'number',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    tableLabel: TOTAL_REVENUE,
    headerName: TOTAL_REVENUE,
    label: TOTAL_REVENUE,
    columnName: 'totalRevenue',
    field: 'totalRevenue',
    filterType: 'number',
    type: 'number',
    subType: 'price',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
  },
  {
    flex: 1,
    tableLabel: PDF,
    headerName: PDF,
    label: PDF,
    columnName: 'totalPdfEmails',
    field: 'totalPdfEmails',
    filterType: 'number',
    type: 'number',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    tableLabel: PORTAL,
    headerName: PORTAL,
    label: PORTAL,
    columnName: 'totalPortalEmails',
    field: 'totalPortalEmails',
    filterType: 'number',
    type: 'number',
    renderHeader: RenderHeaderCell,
  },
  {
    tableLabel: PAPER,
    headerName: PAPER,
    label: PAPER,
    columnName: 'totalOnPaper',
    field: 'totalOnPaper',
    filterType: 'number',
    type: 'number',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    tableLabel: STATUS,
    headerName: STATUS,
    label: STATUS,
    columnName: 'status',
    field: 'status',
    filterType: 'checkbox',
    subType: 'sentInvoicesStatus',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
    renderCell: RenderStatusCell,
  },
  { field: 'actions', type: 'actions', align: 'left', width: 90, resizable: false, getActions: RenderGetSentInvoiceActionsDataCell },
]
