import React from 'react'
import { Table, TableContainer, styled, TableCell, Typography, Badge } from '@mui/material'

export const BillingTableContainer = styled(TableContainer)(props => ({
  height: 'calc(100vh - 288px)',

  [props.theme.breakpoints.up('xs')]: {
    maxWidth: 'calc(100vw + 285px)',
    minWidth: '100%',
  },

  [props.theme.breakpoints.up('sm')]: {
    maxWidth: 'calc(100vw + 50px)',
  },

  [props.theme.breakpoints.up('md')]: {
    maxWidth: 'calc(100vw - 64px)',
  },

  '& .MuiTableCell-paddingCheckbox': {
    '& .MuiButtonBase-root': {
      width: '32px',
    },
  },

  '& .font-500': {
    fontWeight: 500,
  },
}))

export const BillingTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: theme.spacing(3.75),
  whiteSpace: 'nowrap',
  borderBottomColor: theme.palette.border.light,
}))

export const StyledNoResultTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  minHeight: theme.spacing(3),
  fontSize: '13px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export const BillingSentInvoiceTable = styled(Table)(({ theme }) => ({
  'th, td': {
    '&:nth-of-type(2)': {
      maxWidth: theme.spacing(12.5),
    },

    '&:last-of-type': {
      paddingLeft: '50px',
      paddingRight: '16px',
      maxWidth: theme.spacing(13.75),
    },
  },

  '& .empty-th': {
    paddingBottom: theme.spacing(0.25),
  },

  '& .download-icon': {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    lineHeight: theme.spacing(4),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',

    svg: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      color: theme.palette.text.primary,
    },
  },
}))

export const getStatusBadge = (color = 'primary') => {
  return <Badge sx={{ m: '0px 12px 3px 4px' }} badgeContent="." variant="dot" color={color} />
}
