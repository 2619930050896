import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { CssBaseline } from '@material-ui/core'

const Root = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
`

const AppContent = styled('div')(({ sx }) => ({
  flex: '1',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  ...sx,
}))
const AppWrapper = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <AppContent>{children}</AppContent>
    </Root>
  )
}

AppWrapper.propTypes = {
  children: PropTypes.object,
}

export default AppWrapper
