import React from 'react'
import PropTypes from 'prop-types'

import { Redirect, useLocation } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'
import { memo } from 'utils/react'
import { useAuthValue } from 'auth/AuthContext'

import routes from 'router/routes'

const ProtectedRoute = ({ component, exact, path }) => {
  const { currentUser } = useAuthValue()
  const location = useLocation()

  if (currentUser) {
    return <CompatRoute exact={exact} component={component} path={path} />
  }

  return <Redirect to={{ pathname: routes.app.login, search: location?.search }} />
}

ProtectedRoute.defaultProps = {
  exact: null,
  path: null,
}

ProtectedRoute.propTypes = {
  exact: PropTypes.bool,
  component: PropTypes.func.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default memo(ProtectedRoute)
