import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

import { calculateSum } from 'utils/price'

import HHDisplayMoney from 'components/common/HHDisplayMoney'

const RenderHeader = ({ rows = [], selectedRows = [] }) => {
  const totalSelectedRows = selectedRows.length
  const filteredRows = useMemo(() => rows.filter(row => selectedRows.includes(row.accountId)), [rows, selectedRows])
  const totalSelectedDue = useMemo(() => calculateSum(filteredRows, 'balanceCents'), [filteredRows])

  return (
    <Box pl={7} width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        {`${totalSelectedRows} selected`}
      </Typography>
      {totalSelectedRows > 0 && (
        <Typography variant="h5" fontWeight={500} color="textPrimary">
          <HHDisplayMoney value={totalSelectedDue} />
        </Typography>
      )}
    </Box>
  )
}

RenderHeader.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
}

export default RenderHeader
