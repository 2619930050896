import orderBy from 'lodash/orderBy'

import { ACCOUNTS_RECEIVABLE_CONFIGURATOR } from 'containers/billing/settings/accountsReceivable'

export const ACCOUNT_RECEIVABLE_CONFIGURATOR = orderBy(
  ACCOUNTS_RECEIVABLE_CONFIGURATOR.filter(
    data => !['autoPay', 'autoPaymentMethod', 'autoPaymentExpiry', 'zipCode', 'state', 'city', 'line1'].includes(data.columnName)
  ),
  ['position'],
  ['asc']
)
