import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import difference from 'lodash/difference'
import lowerCase from 'lodash/lowerCase'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Button, MenuItem, Typography } from '@mui/material'
import Add from '@mui/icons-material/Add'
import Table from 'components/settings/pages/businesslines/Table'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { replaceAllSpacesAndAmpersand } from 'utils/string'
import T from 'T'
import Constants from 'Constants'
import { canAddSettings } from 'data/permissions/permissionsSelectors'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const headerValues = [
  { filter: false, name: T.ACTION_NAME, sort: false },
  { filter: false, name: '', sort: false },
]

const Actions = ({ action = [], showAddMore = false, actionCombinations = [], setServiceState = noop, setShowAddMore = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)
  const ACTIONS_VALUES = [T.COLLECTION, T.DUMP_N_RETURN, T.SWAP, T.FINAL, T.REPOSITION, T.LIVE_LOAD, T.CONCIERGE, T.DELIVERY, T.RENTAL]

  const uniqueActionRecords = difference(
    ACTIONS_VALUES,
    actionCombinations.map(data => data.actionName)
  )

  const [actions, setActions] = useState([])
  const [currentAction, setCurrentAction] = useState([])

  useEffect(() => {
    const actionCloned = cloneDeep(action)
    setServiceState({
      actionCombinations: actionCloned,
    })
  }, [action])

  useEffect(() => {
    setActions(uniqueActionRecords)
  }, [actionCombinations])

  const onHandleChange = (e, index) => {
    actionCombinations[index]['actionName'] = e.target.value
    setServiceState({ actionCombinations: actionCombinations })
  }

  const updateActionRecords = () => {
    const newValues = difference(
      ACTIONS_VALUES,
      actionCombinations.map(data => data.actionName)
    )
    setActions(newValues)

    setShowAddMore(false)
  }

  const handleRemoval = index => {
    actionCombinations.splice(index, 1)

    setServiceState({ actionCombinations: actionCombinations })

    if (!actionCombinations.map(data => data.id).includes('')) {
      setServiceState({ showFooter: false })
    }
    updateActionRecords()
  }

  const getActionSearchList = value => {
    const values = uniqueActionRecords.filter(data => lowerCase(data).includes(lowerCase(value)))
    setCurrentAction([])
    setActions(values)
  }

  const handleAddMore = () => setShowAddMore(!showAddMore)

  return (
    <Box>
      <Table
        headerValues={headerValues}
        records={actionCombinations}
        onHandleChange={onHandleChange}
        handleRemoval={handleRemoval}
        type="action"
      />

      {showAddMore && (
        <HHTextField
          select
          sx={{
            minWidth: 300,
            '& .MuiSelect-select': {
              display: 'flex',
            },
            mt: 2,
          }}
          value={[currentAction]}
          onChange={e => {
            const { value } = e.target

            const actionVal = get(value, '[0]', '')

            if (!actionVal) return
            getActionSearchList(actionVal)

            let newRecords = actionCombinations.find(data => data.actionName === actionVal)

            if (newRecords === undefined) {
              newRecords = {
                actionName: actionVal,
                actionType: actionVal,
                alreadySelected: false,
                id: '',
              }
            }
            actionCombinations.push(newRecords)
            setServiceState({
              actionCombinations,
              showFooter: true,
            })

            updateActionRecords()
          }}
        >
          <MenuItem value="">{`Select ${T.ACTION}s`}</MenuItem>
          {actions.map(data => (
            <MenuItem value={[data]} sx={{ display: 'flex' }}>
              {data && <img src={`${MEDIA_SERVER}Action/${replaceAllSpacesAndAmpersand(data)}.svg`} alt="icons" />}
              <Typography variant="body1" ml={1}>
                {data}
              </Typography>
            </MenuItem>
          ))}
        </HHTextField>
      )}

      {addSettings && (
        <Button onClick={handleAddMore} variant="text" color="primary" startIcon={<Add />} sx={{ mt: 1, display: 'flex' }}>
          {T.ADD_ACTION}
        </Button>
      )}
    </Box>
  )
}

Actions.propTypes = {
  action: PropTypes.array,
  actionCombinations: PropTypes.array,
  showAddMore: PropTypes.bool,
  setServiceState: PropTypes.func,
  setShowAddMore: PropTypes.func,
}

export default Actions
