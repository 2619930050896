import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import { createDateFromString, formatDateToFEFormat } from 'utils/date'
import { INVOICE_TYPE } from 'settings/constants/billing'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SendInvoiceIconButton from 'components/billing/common/SendInvoiceIconButton'
import IconButton from '@mui/material/IconButton'
import { setTakePaymentState } from 'slices/billing/agingBucketSlice'
import { useDispatch } from 'react-redux'

const { MANUAL } = INVOICE_TYPE

const RenderAgingBucketInvoiceCell = ({ row, accountData }) => {
  const dispatch = useDispatch()
  const status = get(row, 'status')
  const dueDate = get(row, 'dueDate')
  const amountPaidCents = get(row, 'amountPaidCents')
  const totalCents = get(row, 'totalCents')
  const type = get(row, 'type')
  const startDate = get(row, 'startDate')
  const endDate = get(row, 'endDate')
  const invoiceDate = get(row, 'invoiceDate')
  const invoiceNumber = get(row, 'invoiceNumber')
  const billingProfileDetails = get(accountData, 'accountBilling.billingProfile', {})
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, dueDate, amountPaidCents, totalCents)
  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')

  const getBillingPeriodTitle = (invoiceType, invoiceStartDate, invoiceEndDate, invoiceDate) => {
    let getBillingCycle = []

    try {
      getBillingCycle = getBillingPeriods({
        billingProfile: billingProfileDetails,
        billingPeriods: [{ startDate: invoiceStartDate, endDate: invoiceEndDate, invoiceDate }],
      })
    } catch (error) {}

    const parsedInvoiceDate = transformDate(invoiceDate)
    return invoiceType === MANUAL ? parsedInvoiceDate : get(getBillingCycle, '[0].labelLeft', '-')
  }
  const title = getBillingPeriodTitle(type, startDate, endDate, invoiceDate)

  const openTakePaymentDialog = () => {
    dispatch(
      setTakePaymentState({
        open: true,
        invoiceNumber,
      })
    )
  }

  return (
    <Box width="100%">
      <Grid container alignItems="center">
        <Grid item container xs={12} alignItems="center" sx={{ px: 2 }} flexWrap="nowrap">
          <Grid item xs>
            <ListItem disableGutters disablePadding>
              <ListItemAvatar sx={{ minWidth: 0, mr: 2 }}>
                <InvoiceStatusChip status={status} isPartial={isPartial} agingBucketType={agingBucketType} />
              </ListItemAvatar>
              <ListItemText
                primary={title}
                primaryTypographyProps={{
                  color: 'textPrimary',
                  fontWeight: 600,
                }}
                secondary={
                  <Stack flexWrap="nowrap">
                    <Typography>{`Invoice #: ${invoiceNumber}`}</Typography>
                    <Typography>{`Invoice date: ${formatDateToFEFormat(createDateFromString(invoiceDate))}`}</Typography>
                  </Stack>
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                    <IconButton onClick={openTakePaymentDialog}>
                      <AttachMoneyIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                    <SendInvoiceIconButton invoice={row} />
                  </Stack>
                }
                primaryTypographyProps={{ textAlign: 'right', gutterBottom: true }}
                secondary={`Due: ${formatDateToFEFormat(createDateFromString(dueDate))}`}
                secondaryTypographyProps={{ textAlign: 'right', variant: 'body1' }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

RenderAgingBucketInvoiceCell.propTypes = {
  row: PropTypes.object,
  accountData: PropTypes.object,
}

export default RenderAgingBucketInvoiceCell
