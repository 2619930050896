import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { PAYMENT_SUSPENSION_REASON, UNSUSPEND_SUSPENSION_REASON } from 'components/customer-details/content/suspensions/settings'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

const SuspensionHistoryItem = ({ suspensionType, description, createdBy, createdAt }) => {
  const isSuspension = suspensionType !== UNSUSPEND_SUSPENSION_REASON
  const isPaymentSuspensionReason = suspensionType === PAYMENT_SUSPENSION_REASON
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  return (
    <ListItem divider disablePadding>
      <ListItemText
        disableTypography
        primary={
          <Stack>
            <Typography gutterBottom variant="h6" fontWeight={500} color={isSuspension ? 'error' : 'success.main'}>
              {isSuspension ? 'Suspended' : 'Unsuspended'}
            </Typography>
            {isSuspension && (
              <Typography variant="h6" fontWeight={400}>
                {isPaymentSuspensionReason ? 'Past due' : `Other: ${description}`}
              </Typography>
            )}
          </Stack>
        }
        secondary={<Typography color="textSecondary">{`Updated by ${createdBy} | ${onlyDate} @${onlyTime}`}</Typography>}
      />
    </ListItem>
  )
}

SuspensionHistoryItem.propTypes = {
  suspensionType: PropTypes.string,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  createdBy: PropTypes.string,
}

export default SuspensionHistoryItem
