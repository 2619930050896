import { blue, green, red, amber, orange, lime, common } from '@mui/material/colors'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const AGING_BUCKET_COLORS = {
  [CURRENT]: blue,
  [DUE_ONE_THIRTY]: lime,
  [DUE_THIRTY_ONE_SIXTY]: amber,
  [DUE_SIXTY_ONE_NINETY]: orange,
  [DUE_OVER_NINETY]: red,
}

export const AGING_BUCKET_TYPE_TO_QUERY_PARAM = {
  [CURRENT]: 'CURRENT',
  [DUE_ONE_THIRTY]: 'ONE_THIRTY',
  [DUE_THIRTY_ONE_SIXTY]: 'THIRTY_SIXTY',
  [DUE_SIXTY_ONE_NINETY]: 'SIXTY_NINETY',
  [DUE_OVER_NINETY]: 'OVER_NINETY',
}

export const ACCOUNT_CREDIT_COLOR = green

export const getBucketColorScheme = agingBucketName => {
  return agingBucketName in AGING_BUCKET_COLORS ? AGING_BUCKET_COLORS[agingBucketName] : ACCOUNT_CREDIT_COLOR
}

export const getBucketStyles = agingBucketName => {
  if (!agingBucketName) {
    return { borderColor: common.black }
  }

  const bucketColorScheme = getBucketColorScheme(agingBucketName)
  return { backgroundColor: bucketColorScheme[50], borderColor: bucketColorScheme[500] }
}
