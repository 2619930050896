import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material'
import { isValidHex } from 'utils/validations'
import { grey } from '@mui/material/colors'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import RouteAvatar from '../../../../route-manager/RouteDetailsModal/RouteAvatar/RouteAvatar'
import { INVOICE_STATUS } from '../../../../../settings/constants/invoice'
import WorkOrderInvoiceStatusChip from '../../../WorkOrderInvoiceStatusChip'

const { NOT_READY_FOR_INVOICE } = INVOICE_STATUS

const RenderRouteCell = props => {
  const { row } = props
  const { name } = row
  const bgcolor = get(row, 'color', grey[100])
  const countByStatus = get(row, 'countByStatus', {})
  const woNotReadyCount = get(countByStatus, NOT_READY_FOR_INVOICE, 0)
  const notReadyLabel = `${woNotReadyCount} Not ready`
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  return (
    <Stack width="calc(100% - 24px)" direction="row" justifyContent="space-between" alignItems="center">
      <ListItem sx={{ ml: 1 }} disableGutters>
        <ListItemAvatar sx={{ minWidth: '32px' }}>
          <RouteAvatar
            sx={{ width: '24px', height: '24px' }}
            TypographyProps={{ variant: 'body1', fontWeight: 600 }}
            name={name}
            bgcolor={formattedBgcolor}
          />
        </ListItemAvatar>
        <ListItemText primary={name} primaryTypographyProps={{ color: 'textPrimary', variant: 'h5', fontWeight: 400 }} />
      </ListItem>
      <WorkOrderInvoiceStatusChip status={NOT_READY_FOR_INVOICE} label={notReadyLabel} />
    </Stack>
  )
}

RenderRouteCell.propTypes = {
  row: PropTypes.object.isRequired,
}

export default RenderRouteCell
