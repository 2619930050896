import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    resendActivationEmail: build.mutation({
      query: body => ({
        method: 'POST',
        url: '/api/v1/core/settings/users/email:resendActivation',
        body,
      }),
    }),
  }),
})

export const { useResendActivationEmailMutation } = extendedApi
