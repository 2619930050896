import { get } from 'lodash'
import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getFocusedSearchAccounts: builder.mutation({
      query: search => ({
        url: '/api/account/focusedSearch',
        method: 'POST',
        body: {
          lookups: search?.lookups,
          requestedPage: search?.requestedPage ?? search?.page,
          requestedPageSize: search?.requestedPageSize ?? search?.size,
          includeDeactivatedAccounts: search?.includeDeactivatedAccounts,
        },
      }),
      transformResponse: data => {
        const { accounts, totalPages, totalItems } = data
        const page = get(data, 'currentPage', 0)
        const size = get(data, 'pageSize', 0)
        return {
          page,
          size,
          accounts,
          totalPages,
          totalItems,
        }
      },
    }),
  }),
})

export const { useGetFocusedSearchAccountsMutation } = extendedApi
