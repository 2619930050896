import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box, Grid, IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RenderAccountCell from 'components/customers/accounts/RenderAccountCell'

const RenderAccountGroupCell = ({ row = {} }) => {
  const apiRef = useGridApiContext()
  const { watch } = useFormContext()
  const expandedRowIds = watch('expandedRowIds')

  const { accountId } = row
  const isExpanded = expandedRowIds.includes(accountId)

  const handleToggleDetailPanel = e => {
    e.stopPropagation()
    apiRef.current.toggleDetailPanel(accountId)
  }

  return (
    <Box width="100%">
      <Grid container alignItems="center" justifyContent="space-between" columnGap={2}>
        <Grid sx="auto">
          <IconButton onClick={handleToggleDetailPanel}>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
        </Grid>
        <Grid item xs>
          <RenderAccountCell row={row} isViewOnly />
        </Grid>
      </Grid>
    </Box>
  )
}

RenderAccountGroupCell.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountGroupCell
