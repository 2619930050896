import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, Grid } from '@mui/material'
import T from 'T'
import { FormProvider, useForm } from 'react-hook-form'
import { get, noop, sortBy, capitalize } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import HHDialogTitle from '../../../common/HHDialogTitle'
import HHDialogActions from '../../../common/HHDialogActions'
import CancelButton from '../../../buttons/CancelButton'
import SaveButton from '../../../buttons/SaveButton'
import { HHFormAutocompleteField, HHFormPriceField, HHFormTextField } from '../../../form-fields/v5'
import ActionsAutocompleteFormField from './ActionsAutocompleteFormField'
import MeasuresAutocompleteFormField from './MeasuresAutocompleteFormField'
import { getGlobalFilterOptions } from '../../../../middleware/actions/globalsearch'
import MethodsAutocompleteFormField from './MethodsAutocompleteFormField'
import MaterialsAutocompleteFormField from './MaterialsAutocompleteFormField'
import DaysAutocompleteFormField from './DaysAutocompleteFormField'
import GeneralLedgerAutocompleteFormField from './GeneralLedgerAutocompleteFormField'
import { RECURRENCE } from '../../../../settings/constants/service'
import { getPricingLabel } from '../../pricingMethods'
import { useGetGeneralLedgerMutation } from '../../../../api/settings/getGeneralLedger'
import { useUpdatePricingServiceMutation } from '../../../../api/pricing/pricingServiceCrud'
import { containsAllWeekendDays, containsAllWorkingDays } from '../../../../utils/day'
import PricingZoneAutocompleteFormField from './PricingZoneAutocompleteFormField'

const { RECURRING } = RECURRENCE

const defaultValues = {
  serviceName: '',
  action: '',
  measure: '',
  method: '',
  material: '',
  generalLedger: '',
  days: [],
  price: '0',
  workingDaysSelected: false,
  weekendSelected: false,
  pricingZone: '',
}
const EditServiceModal = ({ service, open = false, onClose = noop, onUpdate }) => {
  const [getGeneralLedger, { data, isLoading: isGeneralLedgerListLoading }] = useGetGeneralLedgerMutation()
  const [updatePricingService, { isLoading }] = useUpdatePricingServiceMutation()
  const globalFilterOptions = useSelector(state => get(state, 'GlobalSearchReducer.globalFilterOptions', null))
  const methods = useForm({ defaultValues })
  const dispatch = useDispatch()
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods
  const actionList = get(globalFilterOptions, 'serviceAction', [])
  const measureList = get(globalFilterOptions, 'measure', [])
  const methodList = get(globalFilterOptions, 'methods', [])
  const materialList = get(globalFilterOptions, 'materials', [])
  const serviceType = get(service, 'serviceType', '')
  const pricingPeriod = get(globalFilterOptions, 'pricingPeriod', [])
  const pricingZoneList = get(globalFilterOptions, 'pricingZones', [])
  const recurrenceList = get(globalFilterOptions, 'recurrence', [])
  const isRecurring = serviceType === RECURRING
  const pricingPeriodList = isRecurring ? pricingPeriod.filter(period => period?.periodInterval !== 'Instance') : pricingPeriod

  const serviceNameError = get(errors, 'serviceName.message')
  const pricingPeriodError = get(errors, 'pricingPeriod.message')

  useEffect(() => {
    getGeneralLedger({})
  }, [])

  const generalLedgerOptionList = data || []
  const sortedGeneralLedgerOptionList = sortBy(generalLedgerOptionList, 'accountName')

  const autocompleteSx = {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'wrap',
    },
    '& .MuiTextField-root .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
      height: 'unset',
      py: 0.5,
    },
  }

  const onSubmit = data => {
    const id = get(service, 'id')
    const serviceName = get(data, 'serviceName')
    const materialId = get(data, 'material.id')

    const recurrencePer = get(service, 'recurrencePer')
    const recurrenceInterval = get(service, 'recurrenceInterval')
    const recurrence =
      recurrencePer && recurrenceInterval
        ? recurrenceList.find(item => item.recurrencePer === recurrencePer && item.recurrenceInterval === recurrenceInterval)
        : {}
    const recurrenceId = get(recurrence, 'id')

    const serviceActionId = get(data, 'action.id')
    const serviceMethodId = get(data, 'method.id')

    const pricingZone = get(data, 'pricingZone')
    const pricingZoneId = get(pricingZone, 'id')

    const measureId = get(data, 'measure.id')
    const pricingPeriodId = get(data, 'pricingPeriod.id')
    const generalLedgerAccountId = get(data, 'generalLedger.id')
    const days = get(data, 'days', [])
    const value = get(data, 'price', '0')
    const recurrenceFrequency = get(service, 'recurrenceFrequency')
    const minBillingPeriod = get(service, 'minBillingPeriod')
    const businessLine = get(service, 'businessLine')
    const updatedService = {
      id,
      serviceName,
      serviceType,
      materialId,
      recurrenceId,
      serviceActionId,
      serviceMethodId,
      pricingZoneId,
      measureId,
      pricingPeriodId,
      generalLedgerAccountId,
      days,
      value: value || '0',
      recurrenceFrequency,
      minBillingPeriod,
      businessLine,
    }
    updatePricingService({
      services: [updatedService],
    })
      .unwrap()
      .then(() => {
        onClose()
        onUpdate()
      })
  }

  useEffect(() => {
    if (!service || !open) return
    const serviceName = get(service, 'serviceName', '')
    const businessLine = get(service, 'businessLine')
    const actionType = get(service, 'actionType')
    const methodType = get(service, 'methodType', '')
    const unit = get(service, 'unit')
    const volume = get(service, 'volume')
    const days = get(service, 'days', [])
    const materialType = get(service, 'materialType')
    const periodPer = get(service, 'periodPer')
    const periodInterval = get(service, 'periodInterval')
    const price = get(service, 'value')
    const generalLedgerAccountNumber = get(service, 'generalLedgerAccountNumber')
    const generalLedger = sortedGeneralLedgerOptionList.find(item => item.accountNumber === generalLedgerAccountNumber)
    const action = actionList.find(item => item.actionType === actionType)
    const method = methodList.find(item => item.methodType === methodType)
    const material = materialList.find(item => item.materialType === materialType)
    const measure = measureList.find(item => item.unit === unit && item.volume === volume)
    const pricingPeriod = pricingPeriodList.find(item => item.periodPer === periodPer && item.periodInterval === periodInterval)
    const zoneName = get(service, 'zoneName')
    const pricingZone = zoneName ? pricingZoneList.find(item => item.zoneName === zoneName) : {}

    reset({
      ...defaultValues,
      serviceName,
      ...(action && { action }),
      ...(method && { method }),
      ...(unit && volume && { measure }),
      ...{ days, workingDaysSelected: containsAllWorkingDays(days), weekendSelected: containsAllWeekendDays(days) },
      ...(materialType && { material }),
      ...(price && { price }),
      ...(periodInterval && periodPer && { pricingPeriod }),
      ...(generalLedger && {
        generalLedger,
      }),
      ...(pricingZone && { pricingZone }),
    })
    dispatch(getGlobalFilterOptions({ businessLine: [businessLine] }))
  }, [service, open])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <HHDialogTitle title={T.EDIT_SERVICE} onClose={onClose} />
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container rowSpacing={2}>
            <Grid container item columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <HHFormTextField
                  sx={{ my: 1 }}
                  label={T.SERVICE_NAME}
                  control={control}
                  name="serviceName"
                  error={Boolean(serviceNameError)}
                  helperText={serviceNameError}
                  rules={{ required: T.REQUIRED }}
                  required
                  fullWidth
                  deprecatedLabel={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HHFormPriceField sx={{ my: 1 }} fullWidth name="price" control={control} label={T.PRICE} deprecatedLabel={false} />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1.2}>
              <Grid item xs={12} md={6}>
                <ActionsAutocompleteFormField options={actionList} name="action" control={control} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MaterialsAutocompleteFormField options={materialList} name="material" control={control} />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1.2}>
              <Grid item xs={12} md={6}>
                <MethodsAutocompleteFormField options={methodList} name="method" control={control} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HHFormAutocompleteField
                  name="pricingPeriod"
                  required
                  rules={{ required: T.REQUIRED }}
                  error={Boolean(pricingPeriodError)}
                  helperText={pricingPeriodError}
                  control={control}
                  options={pricingPeriodList}
                  deprecatedLabel={false}
                  label={`${capitalize(T.PRICING_PERIOD)} *`}
                  getOptionLabel={getPricingLabel}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1.2}>
              <Grid item xs={12} md={6}>
                <MeasuresAutocompleteFormField options={measureList} name="measure" control={control} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PricingZoneAutocompleteFormField name="pricingZone" options={pricingZoneList} control={control} />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={2} rowSpacing={1.2}>
              <Grid item xs={12} md={6}>
                <DaysAutocompleteFormField sx={autocompleteSx} name="days" control={control} />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralLedgerAutocompleteFormField
                  loading={isGeneralLedgerListLoading}
                  options={sortedGeneralLedgerOptionList}
                  sx={autocompleteSx}
                  name="generalLedger"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton onClick={handleSubmit(onSubmit)} loading={isLoading} />
      </HHDialogActions>
    </Dialog>
  )
}

EditServiceModal.propTypes = {
  service: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default EditServiceModal
