import React from 'react'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { useTheme, Chip, Typography } from '@mui/material'
import { isValidHex } from '../../utils/validations'

const DEFAULT_MAX_WIDTH = 200
const SIZE_CHIP_SMALL = 'small'
const SIZE_CHIP_MEDIUM = 'medium'

// returns style object containing Defined tag style props Only
const getStyle = ({ tagColor }) => {
  return {
    ...(tagColor && { backgroundColor: tagColor }),
  }
}

const Tag = ({ tag, size = SIZE_CHIP_SMALL, onClick = noop, maxWidth = DEFAULT_MAX_WIDTH, clickable = true, ...rest }) => {
  const tagStyle = getStyle(tag)
  const theme = useTheme()
  const bgcolor = get(tag, 'tagColor')
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const color = theme.palette.getContrastText(formattedBgcolor)
  return (
    <Chip
      {...rest}
      clickable={clickable}
      label={<Typography noWrap>{get(tag, 'tagName')}</Typography>}
      size={size}
      sx={{
        ...tagStyle,
        color,
        '&:hover': {
          ...tagStyle,
          color,
        },
        ...(maxWidth && { maxWidth }),
      }}
      onClick={e => onClick(e, tag)}
    />
  )
}

Tag.propTypes = {
  tag: PropTypes.shape({
    tagName: PropTypes.string.isRequired,
    tagColor: PropTypes.string.isRequired,
    hasBorder: PropTypes.bool,
    tagTextColor: PropTypes.string.isRequired,
    tagIcon: PropTypes.string,
    forService: PropTypes.bool,
    forAccount: PropTypes.bool,
    forLocation: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  clickable: PropTypes.bool,
  size: PropTypes.oneOf([SIZE_CHIP_SMALL, SIZE_CHIP_MEDIUM]),
  onClick: PropTypes.func,
  maxWidth: PropTypes.number,
}

export default Tag
