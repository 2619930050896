import React, { useReducer, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import { memo } from 'utils/react'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import { useTheme } from '@mui/material'
import { get } from 'lodash'
import useGetExceptionProps from 'components/work-order/details/exceptions/useGetExceptionProps'
import ExceptionCard from './ExceptionCard'
import { convertDateTimeToPreferedTimezone } from '../../../../data/dateTime/convertDateTimeToPreferedTimezone'
import ExceptionGallery from './ExceptionGallery'
import ExceptionSwiperContainer from './ExceptionSwiperContainer'
import HHSectionPlaceholder from '../../../common/HHSectionPlaceholder'

const Exceptions = ({ accountId, workOrderId, exceptions = [] }) => {
  const theme = useTheme()
  const [exceptionsState, setExceptionsState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isExceptionsLoading: false,
    allExceptions: exceptions,
    currentException: {},
    isGalleryOpen: false,
  })
  const { PageProps, ImageSx } = useGetExceptionProps()
  const { isExceptionsLoading, allExceptions, isGalleryOpen, currentException } = exceptionsState

  const handleCardClick = exception => {
    setExceptionsState({ isGalleryOpen: true, currentException: exception })
  }

  const handleGalleryClose = () => {
    setExceptionsState({ isGalleryOpen: false, currentException: null })
  }

  useEffect(() => {
    setExceptionsState({ allExceptions: exceptions })
  }, [exceptions])

  return (
    <HHAccordion
      withNewColors
      sx={{
        mb: 3,
        '&.Mui-expanded': {
          margin: `${theme.spacing(3)} 0`,
        },
      }}
      isExpanded
      isExpandCollapseHeightSame
      summary={T.EXCEPTIONS}
      showActionIcon={false}
    >
      {(!Array.isArray(allExceptions) || allExceptions.length === 0) && !isExceptionsLoading && (
        <HHSectionPlaceholder title={T.NO_WO_EXCEPTIONS} />
      )}
      <ExceptionSwiperContainer exceptions={exceptions}>
        {!isExceptionsLoading &&
          Array.isArray(allExceptions) &&
          Children.toArray(
            allExceptions.map(exception => {
              const exceptionType = get(exception, 'exceptionType')
              const createdAt = get(exception, 'createdAt')
              const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
              const note = get(exception, 'noteText', '')
              const description = get(exception, 'description', '')
              const images = get(exception, 'images', [])
              const signatures = get(exception, 'signatures', [])
              const id = get(exception, 'id', [])
              return (
                <swiper-slide key={`exception-card-${id}`}>
                  <ExceptionCard
                    onClick={handleCardClick}
                    exception={exception}
                    note={note}
                    description={description}
                    exceptionType={exceptionType}
                    date={onlyDate}
                    time={onlyTime}
                    images={images}
                    signatures={signatures}
                    createdAt={createdAt}
                    accountId={accountId}
                    workOrderId={workOrderId}
                    PageProps={PageProps}
                    ImageSx={ImageSx}
                  />
                </swiper-slide>
              )
            })
          )}
      </ExceptionSwiperContainer>
      <ExceptionGallery
        onClose={handleGalleryClose}
        open={isGalleryOpen}
        exception={currentException}
        accountId={accountId}
        workOrderId={workOrderId}
      />
    </HHAccordion>
  )
}

Exceptions.propTypes = {
  accountId: PropTypes.string.isRequired,
  workOrderId: PropTypes.string.isRequired,
  exceptions: PropTypes.array.isRequired,
}

export default memo(Exceptions)
