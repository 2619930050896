import React from 'react'
import PropTypes from 'prop-types'

import { Link, Grid } from '@mui/material'

import { getCustomerDetailsPageUrl } from 'router/routes'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'

import T from 'T'

import WOInfoListItem from './WOInfoListItem'

const { SERVICES_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const WOInfo = ({
  accountId = '',
  locationId = '',
  accountName = '',
  accountNumber = '',
  workOrderSequence = '',
  containerCount = 0,
  serviceDate = '',
  purchaseOrderNumber = '',
  hasLargeSize = false,
}) => (
  <>
    <Grid item>
      <WOInfoListItem primaryText={accountName} secondaryText={T.ACCOUNT_NAME} hasLargeSize={hasLargeSize} />
    </Grid>
    <Grid item>
      <WOInfoListItem
        primaryText={accountNumber}
        secondaryText={T.ACCOUNT}
        primaryTypographyProps={
          accountId && locationId
            ? {
                href: `${getCustomerDetailsPageUrl(accountId)}?tab=${SERVICES_TAB_ID}&location=${locationId}`,
                component: Link,
              }
            : {}
        }
        hasLargeSize={hasLargeSize}
      />
    </Grid>
    <Grid item>
      <WOInfoListItem primaryText={workOrderSequence} secondaryText={T.WORK_ORDER} hasLargeSize={hasLargeSize} />
    </Grid>
    <Grid item>
      <WOInfoListItem
        primaryText={containerCount}
        secondaryText={T.CONTAINERS}
        hasDivider={serviceDate || purchaseOrderNumber}
        hasLargeSize={hasLargeSize}
      />
    </Grid>
    {purchaseOrderNumber && (
      <Grid item>
        <WOInfoListItem
          primaryText={purchaseOrderNumber}
          secondaryText="Purchase order"
          hasDivider={serviceDate}
          hasLargeSize={hasLargeSize}
        />
      </Grid>
    )}
    {serviceDate && (
      <Grid item>
        <WOInfoListItem primaryText={serviceDate} secondaryText={T.DATE} hasDivider={false} hasLargeSize={hasLargeSize} />
      </Grid>
    )}
  </>
)

WOInfo.propTypes = {
  accountId: PropTypes.string,
  locationId: PropTypes.string,
  accountName: PropTypes.string,
  accountNumber: PropTypes.string,
  workOrderSequence: PropTypes.string,
  containerCount: PropTypes.number,
  serviceDate: PropTypes.string,
  purchaseOrderNumber: PropTypes.string,
  hasLargeSize: PropTypes.bool,
}

export default WOInfo
