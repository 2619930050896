import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@mui/material'
import { capitalize, get } from 'lodash'
import { useSelector, shallowEqual } from 'react-redux'
import { toast } from 'react-toastify'
import T from '../../../../T'
import { useSendResetPasswordEmailGcpMutation } from '../../../../api/customer-portal/sendResetPasswordEmail'
import { useUnlinkUserMutation } from '../../../../api/customer-portal/unlinkUser'
import { handleError } from '../../../../utils/error'
import HHConfirmDialog from '../../../common/HHConfirmDialog'
import CustomerPortalEmailActivityDialog from './CustomerPortalEmailActivityDialog'

const CustomerPortalContactsActionMenu = ({ anchorEl, onClose, accountId, selectedContact }) => {
  const [sendResetPasswordEmailGcp, { isLoading: isSendResetPasswordEmailGcpLoading }] = useSendResetPasswordEmailGcpMutation()
  const [unlinkUser, { isLoading: isUnlinkUserLoading }] = useUnlinkUserMutation()
  const [isEmailActivityDialogOpen, setIsEmailActivityDialogOpen] = useState(false)
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false)
  const [isDisconnectUserConfirmDialogOpen, setIsDisconnectUserConfirmDialogOpen] = useState(false)
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const email = get(selectedContact, 'email')

  const handleOpenEmailActivityDialog = () => {
    onClose()
    setIsEmailActivityDialogOpen(true)
  }

  const handleCloseEmailActivityDialog = () => setIsEmailActivityDialogOpen(false)

  const handleOpenResendPasswordDialog = () => {
    onClose()
    setIsResetPasswordDialogOpen(true)
  }

  const handleCloseResendPasswordDialog = () => setIsResetPasswordDialogOpen(false)

  const handleSendResetPasswordEmail = () => {
    sendResetPasswordEmailGcp({ email, businessId })
      .unwrap()
      .then(() => {
        handleCloseResendPasswordDialog()
        toast.success('Reset password email sent successfully')
      })
      .catch(handleError)
  }

  const handleOpenDisconnectUserConfirmation = () => {
    onClose()
    setIsDisconnectUserConfirmDialogOpen(true)
  }

  const handleCloseDisconnectUserConfirmation = () => setIsDisconnectUserConfirmDialogOpen(false)

  const handleDisconnectUser = () =>
    unlinkUser({ accountId, payload: { emails: [email] } })
      .unwrap()
      .then(() => {
        handleCloseDisconnectUserConfirmation()
        toast.success('User disconnected successfully')
      })
      .catch(handleError)

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleOpenEmailActivityDialog}>{T.EMAIL_ACTIVITY}</MenuItem>
        <MenuItem onClick={handleOpenResendPasswordDialog}>{capitalize(T.RESET_PASSWORD)}</MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDisconnectUserConfirmation}>
          {T.DISCONNECT_USER}
        </MenuItem>
      </Menu>
      <CustomerPortalEmailActivityDialog
        isOpen={isEmailActivityDialogOpen}
        emailId={email}
        accountId={accountId}
        onClose={handleCloseEmailActivityDialog}
      />
      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs' }}
        isOpen={isResetPasswordDialogOpen}
        confirmTitle={capitalize(T.RESET_PASSWORD)}
        ConfirmButtonProps={{ loading: isSendResetPasswordEmailGcpLoading, color: 'primary' }}
        confirmDescription={T.SEND_RESET_PASSWORD_EMAIL}
        confirmButtonTitle={T.OK}
        onConfirm={handleSendResetPasswordEmail}
        onClose={handleCloseResendPasswordDialog}
      />
      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs' }}
        isOpen={isDisconnectUserConfirmDialogOpen}
        confirmTitle="Confirm disconnect user"
        ConfirmButtonProps={{ loading: isUnlinkUserLoading, color: 'error' }}
        confirmDescription="Are you sure you want to disconnect this customer portal user from this account?"
        confirmButtonTitle="Disconnect"
        onConfirm={handleDisconnectUser}
        onClose={handleCloseDisconnectUserConfirmation}
      />
    </>
  )
}

CustomerPortalContactsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string,
  selectedContact: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default CustomerPortalContactsActionMenu
