import React from 'react'
import TopSection from 'components/pricing/RentalFees/details/TopSection'
import RenderRentalFeeAccountCell from 'components/pricing/RentalFees/details/RenderRentalFeeAccountCell'

const RenderRentalFeeDetailsWrapper = params => {
  const { id: rowId } = params

  if (rowId === 'header') {
    return <TopSection />
  }

  return <RenderRentalFeeAccountCell {...params} />
}

export default RenderRentalFeeDetailsWrapper
