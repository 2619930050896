import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Box, Divider, Skeleton } from '@mui/material'

const NoteSkeleton = ({ rows = 1, showActions = true }) => {
  const shimmersArray = new Array(rows).fill()

  return (
    <Box pt={2} pb={1}>
      {Children.toArray(
        shimmersArray.map((_, index) => (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" ml={2} mr={3}>
              <Box display="flex" alignItems="center" width="100%" gap={2}>
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="rectangular" width={showActions ? '30%' : '80%'} />
              </Box>
              {showActions && <Skeleton variant="rectangular" width={24} height={24} />}
            </Box>
            {rows - 1 !== index && <Divider sx={{ my: 1 }} />}
          </>
        ))
      )}
    </Box>
  )
}
NoteSkeleton.propTypes = {
  rows: PropTypes.number,
  showActions: PropTypes.bool,
}

export default NoteSkeleton
