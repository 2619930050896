import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost, doGet } from 'providers/api'
import { get } from 'utils/lodash'
import { transformServiceGroupsToServices } from 'utils/serviceGroup'
import { isServiceGroup } from 'data/service/serviceSelectors'

import { actions, putPricingServiceSearch } from '../actions/configuredServices'

import endpoint from './endpoint'

export const getCSSearchResults = ({ body }) => doPost({ url: endpoint.configuredService.search, body, showLoader: false })
export const getPricingServiceSearch = ({ body }) =>
  doPost({ url: endpoint.configuredService.pricingServiceSearch, body, showLoader: false })
export const getPricingServiceGroupSearch = ({ body }) => doPost({ url: endpoint.serviceGroups.search, body, showLoader: false })
export const getPricingServiceDetail = ({ body }) =>
  doPost({ url: endpoint.configuredService.pricingServiceDetail, body, showLoader: true })
export const getPricingServiceGroupDetail = ({ url }) => doGet({ url, showLoader: true })
export const getConfigureServiceDetail = ({ body }) => doPost({ url: endpoint.configuredService.detail, body, showLoader: true })
export const saveUpdateEventCSService = ({ body }) => doPost({ url: endpoint.configuredService.saveUpdateEvent, body, showLoader: true })
export const deleteEventCSService = ({ body }) => doPost({ url: endpoint.configuredService.deleteEvent, body, showLoader: true })
export const updateCSNote = ({ body }) => doPost({ url: endpoint.configuredService.updateRouteNote, body, showLoader: true })
export const getCSEvents = ({ body }) => doPost({ url: endpoint.configuredService.events, body, showLoader: true })

export function* getCSSearchResultsSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCSSearchResults, { body })
    const { services, totalItems, totalPages } = res
    if (typeof onSuccess === 'function') {
      onSuccess(services, totalItems, totalPages)
    }
  } catch (err) {
    onSuccess([])
  }
}

export function* getPricingServiceSearchSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    yield put(putPricingServiceSearch(null))
    let pricedServices = []
    if (isServiceGroup({ type: get(body, 'type') })) {
      const { data: res } = yield call(getPricingServiceGroupSearch, { body })
      pricedServices = transformServiceGroupsToServices(get(res, 'pricedGroupService', []))
    } else {
      const { data: res } = yield call(getPricingServiceSearch, { body })
      pricedServices = get(res, 'pricedServices', [])
    }

    yield put(putPricingServiceSearch(pricedServices))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (err) {
    yield put(putPricingServiceSearch(null))
    onSuccess()
  }
}

export function* getPricingServiceDetailSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    let serviceDetails = []
    if (isServiceGroup({ type: get(body, 'type') })) {
      let url = endpoint.serviceGroups.details
      if (get(body, 'serviceGroupId')) {
        url += `/${body.serviceGroupId}`
      }

      if (get(body, 'accountId')) {
        url += `/account/${body.accountId}`
      }
      const { data: res } = yield call(getPricingServiceGroupDetail, { url })
      serviceDetails = get(res, 'pricedServices', [])
    } else {
      const { data: res } = yield call(getPricingServiceDetail, { body })
      serviceDetails = res
    }

    if (typeof onSuccess === 'function') {
      onSuccess(true, serviceDetails)
    }
  } catch (err) {
    onSuccess(false, {})
  }
}

export function* getConfigureServiceDetailSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getConfigureServiceDetail, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    onSuccess(false, {})
  }
}

export function* saveUpdateEventCSServiceSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(saveUpdateEventCSService, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* deleteEventCSServiceSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(deleteEventCSService, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(true, res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export function* updateCSNoteSaga({ payload }) {
  const { body } = payload
  try {
    yield call(updateCSNote, { body })
  } catch (err) {}
}

export function* getCSEventsSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getCSEvents, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export default function* ConfiguredServices() {
  yield all([
    takeEvery(actions.GET.SEARCH_CONFIGURED_SERVICES, getCSSearchResultsSaga),
    takeEvery(actions.GET.SEARCH_PRICING_SERVICE, getPricingServiceSearchSaga),
    takeEvery(actions.GET.PRICING_SERVICE_DETAIL, getPricingServiceDetailSaga),
    takeEvery(actions.GET.CONFIGURED_SERVICE_DETAIL, getConfigureServiceDetailSaga),
    takeEvery(actions.SAVE_UPDATE_EVENT_CS_SERVICE, saveUpdateEventCSServiceSaga),
    takeEvery(actions.DELETE_EVENT_CS_SERVICE, deleteEventCSServiceSaga),
    takeEvery(actions.PUT.UPDATE_CS_NOTE, updateCSNoteSaga),
    takeEvery(actions.GET.CONFIGURED_SERVICES_EVENTS, getCSEventsSaga),
  ])
}
