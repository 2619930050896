import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Tooltip, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import T from 'T'
import Constants from 'Constants'
import { DAYS, FORM_MEASURE_OPTIONS } from 'components/pricing/settings'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getRecurrenceLabel } from 'components/pricing/pricingMethods'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CommonModalHeader from '../../common/CommonModalHeader'
import CommonModalFooter from '../../common/CommonModalFooter'
import {
  HHFormTextField,
  HHFormPriceField,
  HHFormNumberField,
  HHFormMultiSelectAutocompleteField,
  HHFormSwitchField,
} from '../../form-fields/v5'
import { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails } from './StyledAccordion'
import ConfirmationModal from '../../modal/ConfirmationModal'
import LinkedServicesCard from '../LinkedServicesCard'

const { MEDIA_SERVER } = Constants

const alphabeticSort = (a, b) => a.localeCompare(b)
const workingDaysOption = DAYS.find(({ id }) => id === T.WORKING_DAYS)
const weekendDaysOption = DAYS.find(({ id }) => id === T.WEEKEND)
const allWorkingDaysOptions = DAYS.filter(({ id }) => [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY, T.WORKING_DAYS].includes(id))
const allWorkingDaysOptionsStr = allWorkingDaysOptions
  .map(({ id }) => id)
  .sort(alphabeticSort)
  .join(',')
const allWeekendDaysOptions = DAYS.filter(({ id }) => [T.SATURDAY, T.SUNDAY, T.WEEKEND].includes(id))
const allWeekendDaysOptionsStr = allWeekendDaysOptions.map(({ id }) => id).join(',')
const workingDaysOptionsStr = DAYS.filter(({ id }) => [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY].includes(id))
  .map(({ id }) => id)
  .sort(alphabeticSort)
  .join(',')
const weekendDaysOptionsStr = DAYS.filter(({ id }) => [T.SATURDAY, T.SUNDAY].includes(id))
  .map(({ id }) => id)
  .sort(alphabeticSort)
  .join(',')

const NewDisposalFeeFormModal = ({ setIsOpen, onSubmit, matchedServicesCount = 0, onClickLinkedServicesCard }) => {
  const { control, handleSubmit, watch, formState, setValue, getValues } = useFormContext()
  const [days, setDays] = useState([])
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [selectedBusinessLine, setSelectedBusinessLine] = useState([])
  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  React.useEffect(() => {
    dispatch(getGlobalFilterOptions())
  }, [])

  React.useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name === 'businessLines' && values.businessLines.length > 0) {
        const businessLine = values.businessLines.map(({ label }) => label)
        dispatch(getGlobalFilterOptions({ businessLine }))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  React.useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      const listenList = ['material', 'zone', 'measure']
      if (listenList.includes(name) && formState.dirtyFields?.feeName !== true) {
        const { measure, material, zone } = values
        setValue(
          'feeName',
          [`${get(zone, '[0].zoneName', '')}`, `${get(measure, '[0].id', '')}`, get(material, '[0].materialType', '')].join(' ').trim()
        )
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Box
      component="form"
      sx={{
        height: 'calc(100vh - 56px)',
        overflow: 'hidden',
      }}
      onSubmit={handleSubmit(data => onSubmit(data))}
    >
      <CommonModalHeader
        title={`${T.NEW_DISPOSAL_FEE}`}
        leadingIcon={<Box component="img" mr={1} src={`${MEDIA_SERVER}ClipboardList.svg`} alt="fee-icon" />}
        onClose={() => {
          setIsOpen(false)
        }}
      />
      <Box
        sx={{
          height: 'calc(100vh - 102px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingBottom: 8,
        }}
      >
        <Grid
          sx={{
            padding: 3,
          }}
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <HHFormTextField
              control={control}
              name="feeName"
              label={T.DISPOSAL_FEE_NAME}
              placeholder={T.DISPOSAL_FEE_NAME}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <HHFormMultiSelectAutocompleteField
              control={control}
              name="businessLines"
              onChangeMiddleware={(onChange, nextValue) => {
                const linkFormValues = getValues(['days', 'measure', 'material', 'action', 'recurrence', 'method', 'zone']).flat()
                if (linkFormValues?.length > 0) {
                  setSelectedBusinessLine(nextValue)
                  setOpenModal(true)
                } else {
                  onChange(nextValue)
                }
              }}
              label={T.BUSINESS_LINE}
              placeholder={watch('businessLines').length === 0 ? T.BUSINESS_LINE : undefined}
              options={get(globalFilterOptions, 'businessLines', []).map(({ businessLineLabel, id }) => ({ id, label: businessLineLabel }))}
              getOptionLabel={({ label }) => label}
              getOptionSelected={(option, value) => option.id === value.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <HHFormPriceField
              endLabelIcon={
                <Tooltip placement="right" title="Price per Measure of the disposal">
                  <InfoOutlinedIcon
                    sx={{
                      marginBottom: -2,
                      marginLeft: 3,
                      height: 14,
                      width: 14,
                    }}
                  />
                </Tooltip>
              }
              control={control}
              name="price"
              label={T.PRICE}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <HHFormMultiSelectAutocompleteField
              control={control}
              name="measure"
              required
              label={T.DISPOSAL_UNIT_OF_MEASURE}
              placeholder={watch('measure').length === 0 ? `${T.SELECT} ${T.MEASURE}` : undefined}
              options={FORM_MEASURE_OPTIONS}
              getOptionLabel={({ id }) => id}
              getOptionSelected={(option, value) => option.id === value.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <HHFormNumberField
              endLabelIcon={
                <Tooltip placement="right" title="Max amount that can be disposed before incurring Overage Fees">
                  <InfoOutlinedIcon
                    sx={{
                      marginBottom: -2,
                      marginLeft: 3,
                      height: 14,
                      width: 14,
                    }}
                  />
                </Tooltip>
              }
              control={control}
              name="overage"
              label={T.OVERAGE_THRESHOLD}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <HHFormPriceField control={control} name="overageFee" label={T.OVERAGE_FEE} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <HHFormPriceField
              endLabelIcon={
                <Tooltip placement="right" title="Minimum amount charged if the total price of the disposal is less than minimum fee">
                  <InfoOutlinedIcon
                    sx={{
                      marginBottom: -2,
                      marginLeft: 3,
                      height: 14,
                      width: 14,
                    }}
                  />
                </Tooltip>
              }
              control={control}
              name="minFee"
              label={T.MINIMUM_FEE_COMPLETE}
              fullWidth
            />
          </Grid>
          <Grid
            sx={{
              marginTop: 24,
              minHeight: '53px',
            }}
            container
            item
            xs={6}
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <HHFormSwitchField control={control} name="costPlus" label={T.COST_PLUS} />
            </Grid>
            <Grid item>
              <HHFormSwitchField control={control} name="autoLink" label={T.AUTO_LINK} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LinkedServicesCard services={matchedServicesCount} fullWidth onClick={onClickLinkedServicesCard} />
          </Grid>
          <Grid item xs={12}>
            <StyledAccordion square>
              <StyledAccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ChevronDown sx={{ width: 15 }} />}>
                <Typography variant="h6">Advanced</Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="material"
                      label={`${T.MATERIAL}s`}
                      placeholder={watch('material').length === 0 ? `${T.MATERIAL}s` : ''}
                      options={get(globalFilterOptions, 'materials', [])}
                      getOptionLabel={({ materialType }) => materialType}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="days"
                      label={T.SPECIFIC_DAYS}
                      placeholder={watch('days').length === 0 ? T.SPECIFIC_DAYS : ''}
                      options={DAYS}
                      getOptionLabel={({ id }) => id}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                      onChangeMiddleware={(onChange, nextDays) => {
                        const daysStr = days
                          .map(({ id }) => id)
                          .sort(alphabeticSort)
                          .join(',')
                        const nextDaysStr = nextDays
                          .map(({ id }) => id)
                          .sort(alphabeticSort)
                          .join(',')
                        const nextDaysHasWorkingDaysOption = nextDays.find(({ id }) => id === workingDaysOption.id) !== undefined
                        const nextDaysHasWeekendDaysOption = nextDays.find(({ id }) => id === weekendDaysOption.id) !== undefined
                        const daysHasWorkingDaysOption = days.find(({ id }) => id === workingDaysOption.id) !== undefined
                        const daysHasWeekendDaysOption = days.find(({ id }) => id === weekendDaysOption.id) !== undefined
                        if (nextDaysHasWorkingDaysOption && !daysHasWorkingDaysOption) {
                          onChange(allWorkingDaysOptions)
                          setDays(allWorkingDaysOptions)
                        } else if (nextDaysHasWeekendDaysOption && !daysHasWeekendDaysOption) {
                          onChange(allWeekendDaysOptions)
                          setDays(allWeekendDaysOptions)
                        } else if (nextDaysHasWeekendDaysOption && daysStr === allWeekendDaysOptionsStr) {
                          onChange(nextDays.filter(({ id }) => id !== weekendDaysOption.id))
                          setDays(nextDays.filter(({ id }) => id !== weekendDaysOption.id))
                        } else if (nextDaysHasWorkingDaysOption && daysStr === allWorkingDaysOptionsStr) {
                          onChange(nextDays.filter(({ id }) => id !== workingDaysOption.id))
                          setDays(nextDays.filter(({ id }) => id !== workingDaysOption.id))
                        } else if (!nextDaysHasWorkingDaysOption && daysHasWorkingDaysOption && daysStr === allWorkingDaysOptionsStr) {
                          onChange([])
                          setDays([])
                        } else if (!nextDaysHasWeekendDaysOption && daysHasWeekendDaysOption && daysStr === allWeekendDaysOptionsStr) {
                          onChange([])
                          setDays([])
                        } else if (nextDaysStr === workingDaysOptionsStr && !daysHasWorkingDaysOption) {
                          onChange(allWorkingDaysOptions)
                          setDays(allWorkingDaysOptions)
                        } else if (nextDaysStr === weekendDaysOptionsStr && !daysHasWeekendDaysOption) {
                          onChange(allWeekendDaysOptions)
                          setDays(allWeekendDaysOptions)
                        } else {
                          onChange(nextDays)
                          setDays(nextDays)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="method"
                      label={T.METHOD}
                      placeholder={watch('method').length === 0 ? T.METHOD : ''}
                      options={get(globalFilterOptions, 'methods', [])}
                      getOptionLabel={({ methodName }) => methodName}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="recurrence"
                      label={T.RECURRENCE}
                      placeholder={watch('recurrence').length === 0 ? T.RECURRENCE : ''}
                      options={get(globalFilterOptions, 'recurrence', []).map(option => ({
                        id: option.id,
                        label: getRecurrenceLabel(option),
                      }))}
                      getOptionLabel={({ label }) => label}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="action"
                      label={T.ACTION}
                      placeholder={watch('action').length === 0 ? T.ACTION : ''}
                      options={get(globalFilterOptions, 'serviceAction', [])}
                      getOptionLabel={({ actionName }) => actionName}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormMultiSelectAutocompleteField
                      control={control}
                      name="zone"
                      options={get(globalFilterOptions, 'pricingZones', [])}
                      label={`${T.PRICING_ZONE}s`}
                      getOptionLabel={({ zoneName }) => zoneName}
                      getOptionSelected={(option, value) => option.id === value.id}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      placeholder={watch('zone').length === 0 ? `Select ${T.PRICING_ZONE}s` : ''}
                    />
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>
          </Grid>
        </Grid>
      </Box>
      <CommonModalFooter
        sx={{
          position: 'relative',
          bottom: 64,
        }}
        saveButtonLabel={T.CREATE_DISPOSAL_FEE}
        cancelButtonLabel={T.CLOSE}
        saveType="submit"
        onSave={() => {}}
        onCancel={() => {
          setIsOpen(false)
        }}
        saveButtonDisabled={formState.isValid === false || watch('feeName').replaceAll(' ', '') === ''}
      />
      <ConfirmationModal
        className="global-drawer-confirm"
        isOpen={openModal}
        title={T.CHANGE_BUSINESS_LINES_ALERT}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={T.YES_PROCEED}
        onCancel={() => {
          setOpenModal(false)
        }}
        onProceed={() => {
          setValue('businessLines', selectedBusinessLine)
          setValue('days', [])
          setValue('measure', [])
          setValue('material', [])
          setValue('action', [])
          setValue('recurrence', [])
          setValue('method', [])
          setValue('zone', [])
          setOpenModal(false)
        }}
      />
    </Box>
  )
}

NewDisposalFeeFormModal.propTypes = {
  setIsOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  matchedServicesCount: PropTypes.number,
  onClickLinkedServicesCard: PropTypes.func.isRequired,
}

export default NewDisposalFeeFormModal
