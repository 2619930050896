import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import set from 'lodash/set'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Document } from '@styled-icons/heroicons-outline/Document'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { CommonDrawer } from 'components/common'
import { getSelectedBusinessLineId, getFilterMeta } from 'data/service-groups/filterSelectors'
import { getServicesAndActions } from 'data/service-groups/serviceGroupSelectors'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import { getPricingServiceSearchResults } from 'middleware/actions/configuredServices'
import { useCreateServiceGroupMutation } from 'api/priced-service-group/createServiceGroup'
import { useUpdateServiceGroupMutation } from 'api/priced-service-group/updateServiceGroup'

import T from 'T'
import Loader from 'components/common/loader'
import FooterButton from 'components/buttons/FooterButton'
import CommonModalHeader from 'components/common/CommonModalHeader'

import { Stack, useTheme } from '@mui/material'
import ServiceGroupFilterContent from './ServiceGroupFilterContent'
import ServiceListing from './ServiceListing'

import { getPricingServiceSearchPayload, getCreateServiceGroupPayload, getUpdateServiceGroupPayload } from './form-data'
import { SERVICE_GROUP_MODEL } from './serviceGroupModel'

// Later extract common styles from service & create styled components
import '../service/style.scss'

const DEBOUNCE_TIME = 300
const FILTER_CHANGE_KEY = ['businessLine', 'pricingZoneId', 'search', 'measureIds', 'materialIds', 'serviceMethodIds', 'serviceActionIds']
const MODAL_WIDTH = {
  // lg: 1052,
  lg: '80vw',
  md: '100vw',
}

const ServiceGroupDrawer = ({
  isOpenDrawer = false,
  currentSelectedGroup = {},
  initialSelectedServices = [],
  initialSelectedActions = [],
  hasFilterContent = true,
  onClose,
  onReloadMainTable,
  onReloadDetailsTable,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [createServiceGroup, { isSuccess: isCreateServiceGroupSuccess, isLoading: isCreateServiceGroupLoading }] =
    useCreateServiceGroupMutation()

  const [updateServiceGroup, { isSuccess: isUpdateServiceGroupSuccess, isLoading: isUpdateServiceGroupLoading }] =
    useUpdateServiceGroupMutation()

  const { globalFilterOptions, pricedServices } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
      pricedServices: get(state, 'ConfiguredServicesReducer.pricedServices', []).filter(service => service.action),
    }),
    shallowEqual
  )

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isDirty: false,
    isLoading: false,
    ...cloneDeep(SERVICE_GROUP_MODEL),
  })

  const { isDirty, isLoading, name, filterModel, currentSelectedServices } = localState
  const isEditMode = !!get(currentSelectedGroup, 'id')

  const selectedBusinessLineId = getSelectedBusinessLineId({ globalFilterOptions, businessLine: get(filterModel, 'businessLine') })

  const { measureMeta, materialsMeta, methodsMeta, serviceActionMeta } = getFilterMeta({
    globalFilterOptions,
    businessLineId: selectedBusinessLineId,
  })

  const { currentSelectedServicesIds, allSelectedServicesIds, allSelectedActions } = getServicesAndActions({
    currentSelectedServices,
    initialSelectedServices,
    initialSelectedActions,
  })

  const isProceedBtnDisabled = () => {
    const { businessLine } = filterModel
    if (isEditMode) {
      return !currentSelectedServices.length
    }

    return !name.trim() || !businessLine || !currentSelectedServices.length
  }

  const handlePricingServiceSearch = newChanges => {
    const payload = getPricingServiceSearchPayload(newChanges)
    dispatch(getPricingServiceSearchResults(payload, () => setLocalState({ isLoading: false })))
  }

  const handleDelayPricingServiceSearch = useCallback(
    debounce((key, value, newChanges) => {
      handlePricingServiceSearch(newChanges)
    }, DEBOUNCE_TIME),
    []
  )

  const handleChange = (key, value, parentKey = '', dependentField = '', dependentFieldValue = '') => {
    if (!parentKey) {
      setLocalState({ [key]: value, isDirty: true, [dependentField]: dependentFieldValue })
      return
    }

    let dependentFieldChange = {}
    if (dependentField === 'filters') {
      dependentFieldChange = {
        measureIds: [],
        materialIds: [],
        serviceMethodIds: [],
        serviceActionIds: [],
      }
    }

    const isNewSearch = FILTER_CHANGE_KEY.includes(key)

    const newChanges = {
      [parentKey]: { ...get(localState, parentKey), [key]: value, ...dependentFieldChange },
      isDirty: true,
      isLoading: isNewSearch,
    }

    // Remove selected services if businessLine/pricingZoneId is different
    if (value && ['businessLine', 'pricingZoneId'].includes(key)) {
      set(
        newChanges,
        'currentSelectedServices',
        currentSelectedServices.filter(service => get(service, key) === value)
      )
    }

    setLocalState({ ...newChanges })

    // Call pricing search API
    if (isNewSearch) {
      handleDelayPricingServiceSearch(key, value, newChanges.filterModel)
    }
  }

  const handleServiceSelection = (value, operation) => {
    if (
      isEditMode ||
      operation === 'delete' ||
      currentSelectedServices.length > 0 ||
      (filterModel.businessLine && filterModel.pricingZoneId)
    ) {
      handleChange('currentSelectedServices', value)
      return
    }

    const businessLine = get(value, '[0].businessLine', '')
    const pricingZoneId = get(value, '[0].pricingZoneId', '')
    const newChanges = {
      isDirty: true,
      isLoading: true,
      currentSelectedServices: value,
      filterModel: { ...filterModel, businessLine, pricingZoneId },
    }

    setLocalState({ ...newChanges })
    handlePricingServiceSearch(newChanges.filterModel)
  }

  const handleCreateServiceGroup = () => {
    const payload = getCreateServiceGroupPayload(name, selectedBusinessLineId, currentSelectedServicesIds, filterModel)
    createServiceGroup(payload)
  }

  const handleUpdateServiceGroup = () => {
    const payload = getUpdateServiceGroupPayload(currentSelectedGroup, currentSelectedServicesIds)
    updateServiceGroup(payload)
  }

  useEffect(() => {
    if (isCreateServiceGroupSuccess) {
      onClose(false)
      onReloadMainTable()
    }
  }, [isCreateServiceGroupSuccess])

  useEffect(() => {
    if (isUpdateServiceGroupSuccess) {
      onClose(false)
      onReloadDetailsTable()
    }
  }, [isUpdateServiceGroupSuccess])

  useEffect(() => {
    // Check for existing businessLine/pricingZone
    const newChanges = {
      isLoading: true,
      filterModel: {
        ...filterModel,
        businessLine: get(currentSelectedGroup, 'businessLine.businessLineName', ''),
        pricingZoneId: get(currentSelectedGroup, 'pricingZone.id', ''),
      },
    }

    setLocalState({ ...newChanges })
    handlePricingServiceSearch(newChanges.filterModel)

    if (!hasFilterContent) {
      dispatch(getGlobalFilterOptions())
    }
  }, [isEditMode])

  return (
    <CommonDrawer
      isDirty={isDirty}
      isOpen={isOpenDrawer}
      disableClassNamePrefix
      className="drawer-container common-drawer-container cs-service-common-classes styled-width"
      onChange={(event, isOpen) => onClose(isOpen)}
      sx={{
        '& .MuiDrawer-paperAnchorRight': {
          overflow: 'hidden',
          [theme.breakpoints.up('1100')]: {
            width: MODAL_WIDTH.lg,
          },
          [theme.breakpoints.down('1100')]: {
            width: MODAL_WIDTH.md,
          },
        },
      }}
    >
      {(isCreateServiceGroupLoading || isUpdateServiceGroupLoading) && <Loader />}
      <Box component="section">
        <CommonModalHeader
          title={isEditMode ? T.ADD_SERVICES : T.NEW_SERVICE_GROUP}
          leadingIcon={<Document className="icon-w-16 cursor-pointer mr1" />}
          onClose={() => onClose(false)}
        />

        <Stack flexDirection="row" sx={{ height: '100%' }} className="content">
          <Box
            sx={{
              width: '30%',
              // width: 320,
              background: theme.background.default,
              boxShadow: 'inset -1px 0px 0px #dfe1e6',
              height: '100vh',
              '& .left-content': {
                // Left content should scroll independently
                height: `calc(100vh - 120px)`,
                paddingBottom: 7,
              },
            }}
          >
            <Box className="left-content transparent-scroll common-padding-lr-3-5">
              <ServiceGroupFilterContent
                isEditMode={isEditMode}
                name={name}
                filter={filterModel}
                measureMeta={measureMeta}
                materialsMeta={materialsMeta}
                methodsMeta={methodsMeta}
                serviceActionMeta={serviceActionMeta}
                globalFilterOptions={globalFilterOptions}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: `calc(100% - 30%)`,
              '& .right-content': {
                paddingBottom: 7,
              },
            }}
          >
            <Box className="right-content">
              <Box className="common-padding-lr-3-5">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ServiceListing
                      isLoading={isLoading}
                      pricedServices={pricedServices.filter(service => !allSelectedServicesIds.includes(service.id))}
                      currentSelectedServices={currentSelectedServices}
                      allSelectedActions={allSelectedActions}
                      totalServices={pricedServices.filter(service => !initialSelectedServices.includes(service.id)).length}
                      onChange={handleServiceSelection}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <ServiceListing
                      isLoading={isLoading}
                      isSelectedMode
                      pricedServices={currentSelectedServices}
                      currentSelectedServices={currentSelectedServices}
                      allSelectedActions={allSelectedActions}
                      totalServices={currentSelectedServices.length}
                      onChange={handleServiceSelection}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box
              sx={{
                height: 56,
                paddingTop: 1.5,
                paddingBottom: 1.5,
                bottom: 30,
                borderTop: '1px solid #dfe1e6',
                '& .footer-cancel': {
                  padding: 0,
                },
              }}
              position="relative"
              className="bg-white common-padding-lr-3-5"
            >
              <Stack display="flex" justifyContent="space-between" flexDirection="row">
                <FooterButton
                  leftButtonTitle={T.CANCEL}
                  hasBackButton={false}
                  rightButtonTitle={isEditMode ? T.ADD_SERVICES : T.ADD_SERVICE_GROUP}
                  onClose={() => onClose(false)}
                  onProceed={() => (isEditMode ? handleUpdateServiceGroup() : handleCreateServiceGroup())}
                  disabledProceed={isProceedBtnDisabled()}
                />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
    </CommonDrawer>
  )
}

ServiceGroupDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
  currentSelectedGroup: PropTypes.object,
  initialSelectedServices: PropTypes.array,
  initialSelectedActions: PropTypes.array,
  hasFilterContent: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onReloadMainTable: PropTypes.func.isRequired,
  onReloadDetailsTable: PropTypes.func.isRequired,
}

export default memo(ServiceGroupDrawer)
