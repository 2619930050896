import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addUser: build.mutation({
      query: body => ({
        url: '/api/v1/core/settings/users',
        method: 'POST',
        body,
      }),
    }),
    editUser: build.mutation({
      query: ({ body }) => ({
        url: `/api/v1/core/settings/users/${body.email}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useAddUserMutation, useEditUserMutation } = extendedApi
