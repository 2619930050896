import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Box, Popover, Typography, Grid, ListItem, ListItemAvatar, Stack, ListItemText, Divider, CircularProgress } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { formatDateToFEFormat } from 'utils/date'
import { INVOICE_TYPE, INVOICE_STATUS } from 'settings/constants/billing'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import { getInvoicesByStatus } from 'data/invoice/invoiceSelectors'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import AccountBalanceChip from 'components/common/chip/AccountBalanceChip'

const { MANUAL } = INVOICE_TYPE
const { UNPAID } = INVOICE_STATUS

const MAX_INVOICES = 5

const getBillingCycle = ({ startDate, endDate, invoiceDate, billingProfileDetails }) => {
  try {
    return getBillingPeriods({
      billingProfile: billingProfileDetails,
      billingPeriods: [{ startDate, endDate, invoiceDate }],
    })
  } catch (error) {
    return []
  }
}

const InvoicesPopover = ({ anchorEl, accountId, onClose }) => {
  const isOpen = Boolean(anchorEl)
  const [getAccountInvoices, { data: invoiceList, isFetching: isLoading }] = useLazyGetAccountInvoicesQuery()

  const invoicesByStatus = getInvoicesByStatus({ invoices: get(invoiceList, 'invoices', []) })
  const unpaidInvoices = get(invoicesByStatus, UNPAID, [])

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')

  const invoices = unpaidInvoices.slice(0, MAX_INVOICES)
  const extraInvoices = unpaidInvoices.length - MAX_INVOICES

  useEffect(() => {
    if (isOpen && accountId) {
      getAccountInvoices({ accountId }).unwrap().catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      slotProps={{ paper: { sx: { minWidth: 500 } } }}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box>
        {isLoading && (
          <Box p={2} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            {Children.toArray(
              invoices.map((invoice, index) => {
                const {
                  invoiceNumber,
                  status,
                  invoiceDate,
                  dueDate,
                  amountPaidCents,
                  totalCents,
                  startDate,
                  endDate,
                  type,
                  billingCycleInterval,
                  billingCycleIntervalType,
                  billingProfileTiming,
                  billingProfileTimingDay,
                } = invoice
                const parsedInvoiceDate = transformDate(invoiceDate)
                const parsedDueDate = transformDate(dueDate)
                const pendingAmount = totalCents - amountPaidCents
                const { agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, dueDate, amountPaidCents, totalCents)
                const billingProfileDetails = {
                  billingCycle: {
                    interval: billingCycleInterval,
                    intervalType: capitalize(billingCycleIntervalType),
                  },
                  timing: capitalize(billingProfileTiming),
                  timingDay: billingProfileTimingDay,
                }

                const billingCycle = getBillingCycle({ startDate, endDate, invoiceDate, billingProfileDetails })
                const isManual = type === MANUAL
                const title = isManual ? parsedInvoiceDate : get(billingCycle, '[0].labelLeft', '-')

                return (
                  <>
                    <Grid container>
                      <Grid item xs>
                        <ListItem sx={{ '&.MuiListItem-root': { paddingTop: 0, paddingBottom: 0 } }}>
                          <Box minWidth={120}>
                            <ListItemAvatar sx={{ mr: 2 }}>
                              <AccountBalanceChip balance={pendingAmount} color={AGING_BUCKET_COLORS[agingBucketType]} />
                            </ListItemAvatar>
                          </Box>
                          <ListItemText
                            primary={title}
                            primaryTypographyProps={{ variant: 'body1', fontWeight: 500 }}
                            secondary={
                              <Stack>
                                <Typography>{`Invoice #: ${invoiceNumber}`}</Typography>
                                <Typography>{`Invoice date: ${parsedInvoiceDate}`}</Typography>
                              </Stack>
                            }
                            secondaryTypographyProps={{ fontWeight: 400 }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item>
                        <ListItem>
                          <ListItemText
                            primary={<HHDisplayMoney value={totalCents} formatToDollars />}
                            primaryTypographyProps={{ variant: 'h6', textAlign: 'right' }}
                            secondary={`Due: ${parsedDueDate}`}
                            secondaryTypographyProps={{ variant: 'body1', color: 'info.light', mt: 2 }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    {index < invoices.length - 1 && <Divider />}
                  </>
                )
              })
            )}
            {extraInvoices > 0 && (
              <>
                <Divider />
                <Typography m={2} variant="caption" display="flex" justifyContent="end">
                  {`+${extraInvoices} more`}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </Popover>
  )
}

InvoicesPopover.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default memo(InvoicesPopover)
