import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box } from '@mui/material'
import { lightBlue, grey } from '@mui/material/colors'
import LocationOn from '@mui/icons-material/LocationOn'
import LocationOff from '@mui/icons-material/LocationOff'

const deactivateColor = 'rgba(0, 0, 0, 0.54)'

const BOX_ACTIVE_SX = { borderColor: lightBlue['A700'], backgroundColor: lightBlue[50] }
const AVATAR_ACTIVE_SX = { backgroundColor: lightBlue['A700'] }

const BOX_DEACTIVATED_SX = { borderColor: deactivateColor, backgroundColor: grey[50] }
const AVATAR_DEACTIVATED_SX = { backgroundColor: deactivateColor }

// Combine this with HHAvatar component
const LocationAvatar = ({ isActive = false }) => {
  const boxStyles = isActive ? BOX_ACTIVE_SX : BOX_DEACTIVATED_SX
  const sx = isActive ? AVATAR_ACTIVE_SX : AVATAR_DEACTIVATED_SX
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={24}
      height={24}
      borderRadius="50%"
      sx={{ border: '1px solid', ...boxStyles }}
    >
      <Avatar sx={{ ...sx, width: 18, height: 18, '& svg': { height: 'inherit' } }}>{isActive ? <LocationOn /> : <LocationOff />}</Avatar>
    </Box>
  )
}

LocationAvatar.propTypes = {
  isActive: PropTypes.bool,
}

export default LocationAvatar
