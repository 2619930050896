import api from 'api'
import { formatCentsToDollars } from 'utils/price'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingInvoiceRevenue: build.query({
      query: body => ({ url: '/api/billing/invoice/revenue', method: 'POST', body }),
      // Move this conversion from all revenue RTK and add logic in billingTableSelectors.js
      transformResponse: data => ({ ...data, totalRevenue: formatCentsToDollars(data?.totalRevenueCents) }),
    }),
  }),
})

export const { useLazyGetBillingInvoiceRevenueQuery } = extendedApi
