import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, DialogContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { CommonSwitch } from 'components/common'
import { getIsACH, getIsCreditCard } from 'utils/takePayments'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import AutopayDisclaimer from 'components/common/payments/AutopayDisclaimer'
import RenderCreditCardFields from 'components/customer-details/content/payment-details/RenderCreditCardFields'
import RenderACHFields from 'components/customer-details/content/payment-details/RenderACHFields'
import HHDialogActions from '../../../common/HHDialogActions'
import CancelButton from '../../../buttons/CancelButton'

const RenderPaymentForm = ({ isLoading = false, type, formFields = {}, onChange, onChangeBulk, onSave, onClose }) => {
  const { accountToken, cardHolderName, cardNickName, cvv, accountType, autoPay } = formFields
  const cardHolderNameInputRef = useRef()
  const [cardHolderNameError, setCardHolderNameError] = useState()
  const [showInvalidFormAlert, setShowInvalidFormAlert] = useState(false)
  const isCreditCardForm = getIsCreditCard(type)
  const isACHForm = getIsACH(type)

  const validateCardHolderName = () => {
    if (!isCreditCardForm) return true
    const isValid = Boolean(cardHolderName)
    setCardHolderNameError(isValid ? null : T.CANNOT_BE_EMPTY)
    if (!isValid) cardHolderNameInputRef.current.focus()
    return isValid
  }

  const handleSave = () => {
    const isCardHolderValid = validateCardHolderName()
    if (!isCardHolderValid) return
    const isTokenValid = Boolean(accountToken)
    setShowInvalidFormAlert(!isTokenValid)
    if (!isTokenValid) return
    onSave()
  }

  return (
    <>
      <DialogContent>
        {isCreditCardForm && (
          <RenderCreditCardFields
            cardHolderName={cardHolderName}
            showInvalidFormAlert={showInvalidFormAlert}
            onChange={onChange}
            onNewCardTokenChange={onChangeBulk}
            cardHolderNameError={cardHolderNameError}
            cvv={cvv}
            cardHolderNameInputRef={cardHolderNameInputRef}
          />
        )}
        {isACHForm && (
          <RenderACHFields
            cardHolderName={cardHolderName}
            showInvalidFormAlert={showInvalidFormAlert}
            onChange={onChange}
            onNewCardTokenChange={onChangeBulk}
            accountType={accountType}
          />
        )}
        <Box mt={2}>
          <HHTextField
            label={T.METHOD_NICKNAME}
            placeholder={T.METHOD_NICKNAME}
            fullWidth
            value={cardNickName}
            onChange={event => onChange('cardNickName', event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <CommonSwitch title={T.AUTOPAY} isChecked={autoPay} onChange={checked => onChange('autoPay', checked)} />
        </Box>
        {autoPay && <AutopayDisclaimer show={autoPay} />}
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} />
        <LoadingButton loading={isLoading} onClick={handleSave} variant="contained">
          Save
        </LoadingButton>
      </HHDialogActions>
    </>
  )
}
RenderPaymentForm.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string.isRequired,
  formFields: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeBulk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default RenderPaymentForm
