import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, useTheme } from '@mui/material'

const WeekDayIcon = ({ variant, day }) => {
  const theme = useTheme()
  const dayAbbreviations = {
    sunday: 'Su',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
  }
  const twoLetterDay = dayAbbreviations[day.toLowerCase()]

  const outlinedSx = {
    borderColor: 'divider',
    borderWidth: 1,
    borderStyle: 'solid',
  }

  const outlinedTextSx = {
    color: 'textSecondary',
    fontWeight: 500,
  }

  const primarySx = {
    backgroundColor: theme.palette.primary.main,
  }

  const primaryTextSx = {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
  }

  const warningSx = {
    backgroundColor: theme.palette.warning.main,
  }

  const warningTextSx = {
    color: 'white',
    fontWeight: 600,
  }

  const activeSx = {
    borderColor: theme.palette.action.active,
    borderWidth: 2,
    borderStyle: 'solid',
  }

  const activeTextSx = {
    color: theme.palette.text.primary,
    fontWeight: 600,
  }

  const getStyleFromVariant = variant => {
    if (variant === 'outlined') return { containerSx: outlinedSx, textSx: outlinedTextSx }
    if (variant === 'primary') return { containerSx: primarySx, textSx: primaryTextSx }
    if (variant === 'warning') return { containerSx: warningSx, textSx: warningTextSx }
    if (variant === 'active') return { containerSx: activeSx, textSx: activeTextSx }
    return { containerSx: outlinedSx, textSx: outlinedTextSx }
  }

  const { containerSx, textSx } = getStyleFromVariant(variant)

  return (
    <Stack
      sx={{
        width: '22px',
        height: '22px',
        borderRadius: '50%',
        ...containerSx,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography sx={{ fontSize: '11px', ...textSx }} lineHeight="14px">
        {twoLetterDay}
      </Typography>
    </Stack>
  )
}

WeekDayIcon.propTypes = {
  variant: PropTypes.string,
  day: PropTypes.node.isRequired,
}

export default WeekDayIcon
