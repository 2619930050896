import React, { useEffect } from 'react'
import capitalize from 'lodash/capitalize'
import { Grid, Card, Typography, CardContent, CardActions, styled, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import T from '../../../../T'
import { HHFormTextField, HHFormSelectField } from '../../../form-fields/v5'
import HHFormPhoneField from '../../../form-fields/v5/HHFormPhoneField'
import { useLazyGetBusinessInfoQuery } from '../../../../api/settings/getBusinessInfo'
import { useUpdateBusinessInfoMutation } from '../../../../api/settings/updateBusinessInfo'
import { putIsLoading } from '../../../../middleware/actions/response'
import { handleError } from '../../../../utils/error'
import { REGEX } from '../../../../utils/validations'
import { TIME_ZONES } from '../../../../settings/constants/timeZones'

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  height: 'calc(100 % -81px)',
  display: 'block',
  [theme.breakpoints.down('md')]: {
    minWidth: '30rem',
  },
}))

const BusinessInfoSettings = () => {
  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      businessTitle: '',
      website: '',
      businessEmail: '',
      phoneNumber: '',
      businessHours: '',
      address: '',
      timeZone: '',
    },
  })
  const dispatch = useDispatch()
  const [updateBusinessInfo, { isLoading }] = useUpdateBusinessInfoMutation()
  const [getBusinessInfo] = useLazyGetBusinessInfoQuery()
  const { isDirty, errors } = formState

  useEffect(() => {
    dispatch(putIsLoading(true))
    getBusinessInfo()
      .unwrap()
      .then(data => {
        dispatch(putIsLoading(false))
        reset(data)
      })
  }, [])

  const onSubmit = async data => {
    try {
      const { data: updatedData } = await updateBusinessInfo(data)
      reset(updatedData)
      toast.success('Business information successfully updated')
    } catch (e) {
      handleError(e)
    }
  }

  const handleCancel = () => {
    reset()
  }

  return (
    <Form height="calc(100vh - 81px)" display="block" onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ paddingTop: '7px', paddingX: { xs: 2, sm: 2, md: 4 }, paddingBottom: 0 }}>
        <Grid item sm={12}>
          <Typography variant="h3" fontWeight={600}>
            {T.BUSINESS_INFO}
          </Typography>
        </Grid>
        <Grid item sm={12} sx={{ paddingTop: 3 }}>
          <Card variant="outlined" sx={{ height: 'calc(100vh - 152px)', minHeight: '500px', width: '100%', px: { sm: 0, md: 1 }, pt: 1 }}>
            <CardContent sx={{ height: 'calc(100% - 52.5px)' }}>
              <Grid container>
                <Grid item container xs={12} columnSpacing={2} rowSpacing={4}>
                  <Grid item xs={12} lg={4}>
                    <HHFormTextField deprecatedLabel={false} control={control} name="businessTitle" label="Company name" fullWidth />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <HHFormTextField
                      deprecatedLabel={false}
                      control={control}
                      name="website"
                      label="Company website"
                      fullWidth
                      helperText="This URL will display on invoices"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <HHFormTextField
                      error={errors?.businessEmail}
                      deprecatedLabel={false}
                      control={control}
                      name="businessEmail"
                      label="Company email"
                      fullWidth
                      rules={{ pattern: REGEX.IS_EMAIL }}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Typography color="textPrimary" variant="h5" sx={{ my: 3 }}>
                    Office
                  </Typography>
                </Grid>
                <Grid item container xs={12} columnSpacing={2} rowSpacing={4}>
                  <Grid item xs={12}>
                    <HHFormTextField deprecatedLabel={false} control={control} name="address" label="Address" fullWidth />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <HHFormPhoneField deprecatedLabel={false} fullWidth control={control} name="phoneNumber" label="Phone number" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <HHFormSelectField
                      control={control}
                      deprecatedLabel={false}
                      fullWidth
                      name="timeZone"
                      label={capitalize(T.TIME_ZONE)}
                      options={TIME_ZONES}
                      getOptionLabel={option => option.value}
                      getOptionValue={option => option.key}
                    />
                  </Grid>
                  <Grid item container xs={12} alignItems="center">
                    <HHFormTextField
                      deprecatedLabel={false}
                      label="Working hours"
                      sx={{ '& .MuiOutlinedInput-root': { height: 'inherit' } }}
                      rows={4}
                      multiline
                      fullWidth
                      control={control}
                      name="businessHours"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ border: '1px solid border.light', px: 2 }}>
              <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                  {isDirty && (
                    <Button variant="text" onClick={handleCancel}>
                      Cancel
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <LoadingButton
                    loadingPosition="start"
                    loading={isLoading}
                    disabled={!formState.isDirty}
                    variant="contained"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Form>
  )
}

export default BusinessInfoSettings
