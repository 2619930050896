import React, { useState, useEffect } from 'react'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { getApp } from 'firebase/app'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import CloseIcon from '@mui/icons-material/Close'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetInvoiceGroupDetailsQuery } from 'api/groups/invoice/getInvoiceGroupDetails'
import { resetState, setBatchDialogState, setBulkPrintDialogState } from 'slices/groups/InvoicePrintableViewSlice'
import { getInvoicesExistinBucket } from 'data/groups/invoicePrintSelectors'
import { getAccountsInvoicesForDataGrid } from 'data/groups/invoiceGroupsSelectors'

import Constants from 'Constants'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoicesDataGrid from './InvoicesDataGrid'
import InvoicePreview from './InvoicePreview'
import StyledFullScreenBaseDialog from './StyledFullScreenBaseDialog'
import BatchPrintDialog from './batch-print'

const { ASSETS_BUCKET_NAME } = Constants

const InvoiceGroupPrintableView = () => {
  const dispatch = useDispatch()
  const invoiceApiRef = useGridApiRef()
  const [loading, setLoading] = useState(false)
  const [rawInvoicesData, setRawInvoicesData] = useState(null)
  const [getInvoiceGroupDetails, { isFetching: isInvoiceGroupListLoading, data: invoiceGroupDetails }] =
    useLazyGetInvoiceGroupDetailsQuery()

  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const isOpen = useSelector(state => get(state, 'InvoicePrintableView.bulkPrintDialog.isOpen', false), shallowEqual)
  const groupId = useSelector(state => get(state, 'InvoicePrintableView.bulkPrintDialog.groupId'), shallowEqual)
  const selectedInvoices = useSelector(state => get(state, 'InvoicePrintableView.invoiceSelectionModel', []), shallowEqual)
  const invoices = get(invoiceGroupDetails, 'invoices', [])
  const validInvoices = getInvoicesExistinBucket({ invoices, rawInvoicesData })
  const { invoiceRows, totalAmountCents, hierarchyIds } = getAccountsInvoicesForDataGrid({ invoices: validInvoices })
  const totalRows = invoiceRows.length - hierarchyIds.length
  const isLoading = loading || isInvoiceGroupListLoading

  const handlePrintClasses = () => {
    const mainEl = document.getElementsByClassName('main')[0]
    const mainElClasses = mainEl.classList
    if (mainElClasses.contains('no-print')) {
      mainElClasses.remove('no-print')
    } else {
      mainElClasses.add('no-print')
    }
  }

  const handleReset = () => {
    dispatch(resetState())
    setRawInvoicesData(null)
  }

  const handleClose = () => {
    handlePrintClasses()
    handleReset()
    dispatch(setBulkPrintDialogState({ isOpen: false, groupId: '' }))
  }

  const downloadBulkPrintData = () => {
    const firebaseApp = getApp()
    const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)
    const invoiceBatchRef = ref(storage, `${businessId}/invoice_batch/${groupId}.json`)
    setLoading(true)
    getDownloadURL(invoiceBatchRef)
      .then(url => fetch(url))
      .then(response => response.json())
      .then(data => {
        const allInvoices = {}
        get(data, 'invoices', []).forEach(list => {
          const invoice = get(list, 'data', {})
          if (invoice.id) {
            allInvoices[invoice.id] = { ...invoice }
          }
        })
        setRawInvoicesData(allInvoices)
      })
      .catch(error => {
        handleError(error)
      })
      .finally(() => setLoading(false))
  }

  const handlePrintClick = event => {
    event.stopPropagation()
    dispatch(setBatchDialogState({ isOpen: true }))
  }

  useEffect(() => {
    if (isOpen) {
      handlePrintClasses()
      handleReset()
      getInvoiceGroupDetails({ id: groupId }, true)
      downloadBulkPrintData()
    }
  }, [isOpen])

  useEffect(() => {
    handleReset()

    return () => handleReset()
  }, [])

  return (
    <>
      <StyledFullScreenBaseDialog open={isOpen} sx={{ '& .MuiBackdrop-root': { backgroundColor: 'background.paper' } }}>
        <AppBar color="inherit" className="no-print">
          <Toolbar>
            <IconButton onClick={handleClose} edge="start" aria-label="close" color="inherit">
              <CloseIcon />
            </IconButton>
            <Typography flex={1} variant="h4" fontWeight={600} ml={2} color="textPrimary">
              {!isLoading && (
                <>
                  {`${totalRows} invoice${totalRows > 1 ? 's' : ''}`}
                  {` - `}
                  <HHDisplayMoney value={totalAmountCents} />
                </>
              )}
            </Typography>
            <IconButton onClick={handlePrintClick} disabled={selectedInvoices.length === 0 || !rawInvoicesData}>
              <Printer width={24} height={24} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box pt={8} sx={{ '@media print': { p: 0 } }} display="flex" height="100%" overflow="auto">
          <Box pt={3} borderRight="1px solid" borderColor="divider" flex="0 0 30%" position="sticky" top="0" className="no-print">
            <InvoicesDataGrid
              ref={invoiceApiRef}
              isLoading={isLoading}
              rows={invoiceRows}
              hierarchyIds={hierarchyIds}
              totalAmountCents={totalAmountCents}
            />
          </Box>
          <Box flex={1}>
            <InvoicePreview
              ref={invoiceApiRef}
              isLoading={isLoading}
              invoiceRows={invoiceRows}
              rawInvoicesData={rawInvoicesData}
              hierarchyIds={hierarchyIds}
            />
          </Box>
        </Box>
      </StyledFullScreenBaseDialog>
      <BatchPrintDialog />
    </>
  )
}

export default InvoiceGroupPrintableView
