import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Button, SvgIcon } from '@mui/material'
import { Reply } from '@styled-icons/heroicons-outline/Reply'
import CancelButton from 'components/buttons/CancelButton'

const CommonModalFooter = ({
  onSave,
  onCancel,
  onBack,
  saveButtonLabel,
  cancelButtonLabel,
  saveType = undefined,
  backButtonLabel,
  showBackButton = false,
  saveButtonDisabled = false,
  sx,
}) => {
  return (
    <Box px={3} height={56} display="flex" justifyContent="space-between" alignItems="center" sx={sx}>
      <Grid container xs={12} justifyContent="space-between" alignItems="center">
        <CancelButton size="medium" onClick={onCancel}>
          {cancelButtonLabel}
        </CancelButton>
        <Grid item xs="auto">
          {showBackButton && (
            <Button
              sx={{ mr: 2 }}
              startIcon={
                <SvgIcon>
                  <Reply />
                </SvgIcon>
              }
              variant="outlined"
              onClick={onBack}
            >
              {backButtonLabel}
            </Button>
          )}
          <Button type={saveType} disabled={saveButtonDisabled} variant="contained" onClick={onSave}>
            {saveButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

CommonModalFooter.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  saveButtonLabel: PropTypes.string.isRequired,
  backButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveType: PropTypes.string,
  showBackButton: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  sx: PropTypes.object,
}

export default CommonModalFooter
