import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { Alert, Box, Typography, Divider, List } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAllPaymentMethodsQuery } from 'api/payment-gateway/paymentGateway'
import { getPGUrl } from 'data/payment-gateway/paymentGatewaySelectors'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import NewPaymentMethodDialog from './NewPaymentMethodDialog'
import NewPaymentMethod from './NewPaymentMethod'
import PaymentDetailsActionMenu from './PaymentDetailsActionMenu'
import PaymentMethodView from './PaymentMethodView'
import PaymentMethodViewSkelton from './PaymentMethodViewSkelton'

const PaymentDetails = ({ accountId }) => {
  const [getAllPaymentMethods, { isFetching: isLoading, data }] = useLazyGetAllPaymentMethodsQuery()
  const [addNewMethodAnchorEl, setAddNewMethodAnchorEl] = useState(null)
  const [newPaymentMethodDialog, setNewPaymentMethodDialog] = useState({ isOpen: false, type: '' })
  const [actionMenu, setActionMenu] = useState({ anchorEl: null, selectedPaymentMethod: null })

  const pgUrl = useSelector(getPGUrl, shallowEqual)
  const paymentMethods = get(data, 'paymentMethods', [])

  const handleAddNewMethodActionMenu = event => {
    setAddNewMethodAnchorEl(event.currentTarget)
  }

  const handleCloseAddNewMethodActionMenu = () => {
    setAddNewMethodAnchorEl(null)
  }

  const handleOpenNewPaymentMethodDialog = type => {
    setNewPaymentMethodDialog({ isOpen: true, type })
    handleCloseAddNewMethodActionMenu()
  }

  const handleCloseNewPaymentMethodDialog = () => {
    setNewPaymentMethodDialog({ isOpen: false, type: '' })
  }

  const handleOpenActionMenu = (event, paymentMethod) => {
    setActionMenu({ anchorEl: event.currentTarget, selectedPaymentMethod: paymentMethod })
  }

  const handleCloseActionMenu = () => {
    setActionMenu({ anchorEl: null, selectedPaymentMethod: null })
  }

  useEffect(() => {
    if (accountId) {
      getAllPaymentMethods({ pgUrl, accountId }).unwrap().catch(handleError)
    }
  }, [accountId])

  return (
    <>
      <HHAccordion
        isExpandCollapseHeightSame
        withNewColors
        isExpanded
        summary={T.PAYMENT_DETAILS}
        actionType="add"
        onActionClick={handleAddNewMethodActionMenu}
      >
        {!isLoading && paymentMethods.length === 0 && (
          <Box p={2}>
            <Alert severity="info" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" fontWeight={400}>
                {T.ADD_NEW_METHOD}
              </Typography>
            </Alert>
          </Box>
        )}
        {isLoading && (
          <List disablePadding>
            <PaymentMethodViewSkelton />
          </List>
        )}

        {!isLoading && paymentMethods.length > 0 && (
          <List disablePadding>
            {Children.toArray(
              paymentMethods.map((method, index) => (
                <>
                  <PaymentMethodView paymentMethod={method} onActionClick={event => handleOpenActionMenu(event, method)} />
                  {paymentMethods.length - 1 !== index && <Divider />}
                </>
              ))
            )}
          </List>
        )}
      </HHAccordion>

      <NewPaymentMethod
        anchorEl={addNewMethodAnchorEl}
        onClick={handleOpenNewPaymentMethodDialog}
        onClose={handleCloseAddNewMethodActionMenu}
      />

      {newPaymentMethodDialog.isOpen && (
        <NewPaymentMethodDialog
          isOpen={newPaymentMethodDialog.isOpen}
          accountId={accountId}
          type={newPaymentMethodDialog.type}
          onClose={handleCloseNewPaymentMethodDialog}
        />
      )}

      <PaymentDetailsActionMenu
        anchorEl={actionMenu.anchorEl}
        accountId={accountId}
        selectedPaymentMethod={actionMenu.selectedPaymentMethod}
        onClose={handleCloseActionMenu}
      />
    </>
  )
}

PaymentDetails.propTypes = {
  accountId: PropTypes.string.isRequired,
}

export default PaymentDetails
