import { blue, green, grey, yellow, common } from '@mui/material/colors'
import { get } from 'lodash'

export const RECURRENCE_TO_HEATMAP_VALUE = {
  RECURRING: 1,
  ON_REQUEST: 2,
  SELECTED: 4,
  EMPTY: 0,
  MIXED: 3,
}

export const HEATMAP_VALUE_TO_RECURRENCE = {
  [RECURRENCE_TO_HEATMAP_VALUE.EMPTY]: {
    recurrence: 'Empty',
    color: grey[100],
  },
  [RECURRENCE_TO_HEATMAP_VALUE.RECURRING]: {
    recurrence: 'Recurring',
    color: blue[300],
  },
  [RECURRENCE_TO_HEATMAP_VALUE.ON_REQUEST]: {
    recurrence: 'On Request',
    color: yellow[400],
  },
  [RECURRENCE_TO_HEATMAP_VALUE.SELECTED]: {
    recurrence: 'Selected',
    color: common.black,
  },
  [RECURRENCE_TO_HEATMAP_VALUE.MIXED]: {
    recurrence: 'Mixed',
    color: green[300],
  },
}

const ticks = [0, 1, 2, 3, 4]
export const colorScaleFn = value => {
  const mappedValue = HEATMAP_VALUE_TO_RECURRENCE[value]
  return mappedValue ? mappedValue.color : grey[100] // Default to grey[100] if not found
}

colorScaleFn.ticks = () => ticks

export const formatHeatmapData = (heatmap, serviceDate) => {
  const graphData = []
  let serviceDateFound = false // Flag to check if serviceDate was found

  for (const date in heatmap) {
    const recurringCount = get(heatmap, `${date}.recurringCount`, 0)
    const requestCount = get(heatmap, `${date}.requestCount`, 0)
    let value

    if (recurringCount > 0 && requestCount === 0) {
      value = RECURRENCE_TO_HEATMAP_VALUE.RECURRING
    } else if (recurringCount === 0 && requestCount > 0) {
      value = RECURRENCE_TO_HEATMAP_VALUE.ON_REQUEST
    } else if (recurringCount > 0 && requestCount > 0) {
      value = RECURRENCE_TO_HEATMAP_VALUE.MIXED
    }

    if (date === serviceDate) {
      value = RECURRENCE_TO_HEATMAP_VALUE.SELECTED
      serviceDateFound = true
    }

    graphData.push({ day: date, value })
  }

  if (!serviceDateFound) {
    graphData.push({ day: serviceDate, value: RECURRENCE_TO_HEATMAP_VALUE.SELECTED })
  }
  return graphData
}
