import React, { useEffect, useMemo, useState } from 'react'
import capitalize from 'lodash/capitalize'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom-v5-compat'
import { Grid, IconButton, ListItem, ListItemIcon, ListItemText, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { MoreVert } from '@mui/icons-material'

import { useLazyGetRentalFeeDetailsQuery } from 'api/pricing/rentalFeesCrud'
import { getFormattedGracePeriod } from 'components/pricing/RentalFees/common/utils'
import { openPricingDrawer, selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'

import T from 'T'
import ReorderIcon from 'components/common/ReorderIcon'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import RentalFeeStatItem from 'components/pricing/RentalFees/RentalFeesListing/RentalFeeStatItem'
import RentalFeesActionMenu from 'components/pricing/RentalFees/common/RentalFeesActionMenu'

const RentalFeeDetailsHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [anchorEl, setAnchorEl] = useState(null)

  const [getRentalFeeDetails, { isFetching: isLoading, data }] = useLazyGetRentalFeeDetailsQuery()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)

  const { feeName, pricingPeriod, amountCents, gracePeriodDays } = data || {}
  const capitalizedPricingPeriod = useMemo(() => capitalize(pricingPeriod), [pricingPeriod])
  const formattedGracePeriod = useMemo(() => getFormattedGracePeriod(gracePeriodDays), [gracePeriodDays])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDrawer = () => {
    dispatch(openPricingDrawer())
  }

  useEffect(() => {
    if (id) {
      getRentalFeeDetails({ id })
    }
  }, [id])

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        flexWrap="nowrap"
        sx={{
          backgroundColor: theme.palette.background.default,
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
        }}
      >
        <Grid item xs md lg zeroMinWidth>
          <ListItem sx={{ px: 4, pt: 1, pb: 0 }}>
            {!isOpenPricingDrawer && (
              <ListItemIcon>
                <ReorderIcon onClick={handleOpenDrawer} />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{ my: 0 }}
              disableTypography
              primary={
                <Typography variant="h4" fontWeight={600} color="textPrimary" noWrap>
                  {isLoading ? <Skeleton width="15rem" /> : feeName}
                </Typography>
              }
              secondary={
                <Grid flexWrap={isTabletOrMobile ? 'wrap' : 'nowrap'} justifyContent="flex-start" alignItems="center" container>
                  <RentalFeeStatItem
                    isLoading={isLoading}
                    primary={formattedGracePeriod}
                    secondary={capitalize(T.GRACE_PERIOD)}
                    secondaryTypographyProps={{ variant: 'body2' }}
                    divider
                    DividerProps={{ variant: 'fullWidth' }}
                  />
                  <RentalFeeStatItem
                    isLoading={isLoading}
                    primary={capitalizedPricingPeriod}
                    secondary={capitalize(T.PRICING_PERIOD)}
                    secondaryTypographyProps={{ variant: 'body2' }}
                  />
                </Grid>
              }
            />
          </ListItem>
        </Grid>
        <Grid item sx={{ minWidth: '8rem', pr: 4 }}>
          <Grid container flexWrap="nowrap" alignItems="center" justifyContent="flex-end" columnGap={2}>
            <Grid item>
              <Typography variant="h2" color="textPrimary" fontWeight={600} noWrap>
                {isLoading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={amountCents} formatToDollars />}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </Grid>
            <RentalFeesActionMenu rentalFee={data} anchorEl={anchorEl} onClose={handleClose} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default RentalFeeDetailsHeader
