import React from 'react'

import AccountGroups from 'containers/customers/groups/account'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'

const AccountGroupsPage = () => (
  <ProtectedRouteHoC>
    <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
      <AccountGroups />
    </NavbarWrapper>
  </ProtectedRouteHoC>
)

export default AccountGroupsPage
