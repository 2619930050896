import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import api from 'api'
import { CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_REVIEW_LIST } from 'api/cacheTagTypes'
import DisposalTicketForm from './DisposalTicketForm'

const DisposalTicketAccordionWrapper = ({ workOrderId, configuredServiceId }) => {
  const [isFormEditable, setIsFormEditable] = useState(false)
  const isTaxable = Boolean(localStorage.getItem('isDisposalFeeTaxable'))
  const methods = useForm({
    defaultValues: {
      disposalTicketId: '',
      ticketNumber: '',
      disposalSiteId: '',
      materials: [],
      isTaxable,
    },
  })
  const dispatch = useDispatch()

  const onDisposalTicketDataFetched = () => {
    setIsFormEditable(false)
  }
  const toggleEditMode = () => {
    setIsFormEditable(!isFormEditable)
  }

  const onAddDisposalTicket = () => {
    setIsFormEditable(true)
  }

  const onDeleteConfirmationModalProceed = () => {
    dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_REVIEW_LIST]))
    setIsFormEditable(false)
  }

  const onSubmitWithoutChanges = () => {
    setIsFormEditable(false)
  }

  const onClose = () => {
    setIsFormEditable(false)
  }

  const onSubmitSuccess = () => {
    dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_LIST, CACHE_TAG_WORK_ORDER_REVIEW_LIST]))
    setIsFormEditable(false)
  }

  return (
    <FormProvider {...methods}>
      <DisposalTicketForm
        isFormEditable={isFormEditable}
        workOrderId={workOrderId}
        configuredServiceId={configuredServiceId}
        onAddDisposalTicket={onAddDisposalTicket}
        onDeleteConfirmationModalProceed={onDeleteConfirmationModalProceed}
        onDisposalTicketDataFetched={onDisposalTicketDataFetched}
        onSubmitWithoutChanges={onSubmitWithoutChanges}
        onClose={onClose}
        onSubmitSuccess={onSubmitSuccess}
        toggleEditMode={toggleEditMode}
      />
    </FormProvider>
  )
}

DisposalTicketAccordionWrapper.propTypes = {
  workOrderId: PropTypes.string,
  configuredServiceId: PropTypes.string.isRequired,
}

export default DisposalTicketAccordionWrapper
