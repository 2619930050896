import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

const CardInformationInvalidAlert = ({ isCreditCardForm = false }) => {
  const theme = useTheme()
  const label = isCreditCardForm ? 'credit card ' : ''

  return (
    <Alert
      severity="error"
      sx={{
        color: 'text.primary',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderColor: 'error.main',
        borderWidth: 1,
        mt: 2,
        mb: 1,
        borderStyle: 'solid',
        paddingRight: 2,
        paddingLeft: 2,
      }}
    >
      <Typography variant="h6" fontWeight="normal" sx={{ color: `${theme.palette.common.black}99` }}>
        {`The ${label}information provided is not valid. Please re-enter valid ${label}info and try again`}
      </Typography>
    </Alert>
  )
}

CardInformationInvalidAlert.propTypes = {
  isCreditCardForm: PropTypes.bool,
}

export default CardInformationInvalidAlert
