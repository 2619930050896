import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { Grid, useMediaQuery, useTheme, Divider, ListItem, ListItemText, ListItemAvatar, Avatar, Box } from '@mui/material'
import { grey } from '@mui/material/colors'

import { get } from 'utils/lodash'
import { extractNameInitials } from 'utils/string'
import { selectAddress } from 'data/address/addressSelectors'
import { getCustomerDetails } from 'middleware/actions/customers'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'

import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import InteractiveTagsList from 'components/tags/InteractiveTagsList'
import LocationMapImage from '../location/LocationMapImage'
import LocationContent from '../location/LocationContent'
import AccountContent from './AccountContent'
import AccountMISCContent from './AccountMISCContent'

const IMAGE_MAX_HEIGHT = 250

const AccountDetails = ({ customerDetails, accountId }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const billingAddress = get(customerDetails, 'billingAddress', {})
  const { latitude, longitude, attention } = selectAddress({ ...billingAddress })
  const hasValidCoords = isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)
  const csrInfo = get(customerDetails, 'csr')
  const csrName = `${get(csrInfo, 'firstName', '')} ${get(csrInfo, 'lastName', '')}`.trim()
  const imageWidth = 280
  const imageHeight = csrName ? IMAGE_MAX_HEIGHT + 30 : IMAGE_MAX_HEIGHT

  return (
    <>
      <HHAccordion showActionIcon={false} isExpandCollapseHeightSame withNewColors isExpanded summary="Account details">
        <Grid container>
          <Grid xs={12} xl={hasValidCoords ? 'auto' : 12}>
            <Box
              width={{
                [theme.breakpoints.up('xl')]: {
                  width: hasValidCoords ? 560 : '100%',
                },
                [theme.breakpoints.only('xl')]: {
                  width: hasValidCoords ? imageWidth : '100%',
                },
              }}
            >
              <LocationMapImage
                width={imageWidth}
                height={imageHeight}
                latitude={latitude}
                longitude={longitude}
                containerId={`${accountId}-account-details-map-container`}
                dynamicWidth
                showInvalidAlert={false}
              />
            </Box>
          </Grid>
          <Grid container item xs={12} xl p={3} columnGap={3}>
            <Grid item xs={12} md>
              <AccountContent
                accountNumber={get(customerDetails, 'accountNumber')}
                accountName={get(customerDetails, 'accountName', '')}
                attention={attention}
              />
            </Grid>
            {!isTabletOrMobile && (
              <Grid item xs="auto">
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md>
              <LocationContent address={billingAddress} />
              {csrName && (
                <ListItem disablePadding sx={{ mt: 1.5 }}>
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: grey[100], color: 'text.secondary' }}>{extractNameInitials(csrName)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primaryTypographyProps={{ variant: 'h6', fontWeight: 400 }} primary={csrName} secondary={T.ACCOUNT_REP} />
                </ListItem>
              )}
            </Grid>
            {!isTabletOrMobile && (
              <Grid my={isXl ? 0 : 1} item xs={12} xl="auto">
                <Divider orientation={isXl ? 'vertical' : 'horizontal'} />
              </Grid>
            )}
            <Grid item xs={12} xl={3}>
              <AccountMISCContent customerDetails={customerDetails} />
            </Grid>
            <Grid mt={1.5} container item xs={12} spacing={0.5} alignItems="center">
              <InteractiveTagsList
                data={{
                  accountId,
                  name: get(customerDetails, 'accountName'),
                  tagDetails: { forAccount: get(customerDetails, 'tags', []) },
                }}
                disableLocation
                disableService
                onSave={(data, closeCallback) => {
                  closeCallback()
                  dispatch(getCustomerDetails({ accountId }))
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </HHAccordion>
    </>
  )
}

AccountDetails.propTypes = {
  customerDetails: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
}

export default AccountDetails
