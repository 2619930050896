import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import SearchCustomerByPhone from './SearchCustomerByPhone/SearchCustomerByPhone'
import NewCustomer from './NewCustomer'
import { setIsCreateCustomerDialogOpen, setIsSearchByPhoneCustomerDialogOpen } from '../../../slices/customer/createCustomerSlice'
import SearchCustomerByEmail from './SearchCustomerByEmail/SearchCustomerByEmail'

const WithNewCustomerDialog = ({ children }) => {
  const isCreateDialogOpen = useSelector(s => s.createCustomer.isCreateDialogOpen)
  const isSearchByPhoneDialogOpen = useSelector(s => s.createCustomer.isSearchByPhoneDialogOpen)
  const dispatch = useDispatch()
  const searchByPhoneForm = useForm({ defaultValues: { phone: '' }, mode: 'onSubmit' })
  const searchByEmailForm = useForm({ defaultValues: { email: '' }, mode: 'onSubmit' })
  const { watch: watchPhoneForm } = searchByPhoneForm
  const { watch: watchEmailForm } = searchByEmailForm
  const phone = watchPhoneForm('phone')
  const email = watchEmailForm('email')
  const closeSearchDialog = () => {
    dispatch(setIsSearchByPhoneCustomerDialogOpen(false))
  }

  const closeCreateDialog = () => {
    dispatch(setIsCreateCustomerDialogOpen(false))
  }

  return (
    <>
      {children}
      <FormProvider {...searchByPhoneForm}>
        <SearchCustomerByPhone onClose={closeSearchDialog} isOpen={isSearchByPhoneDialogOpen} />
      </FormProvider>
      <FormProvider {...searchByEmailForm}>
        <SearchCustomerByEmail />
      </FormProvider>
      <NewCustomer onClose={closeCreateDialog} phone={phone} email={email} isOpen={isCreateDialogOpen} />
    </>
  )
}

WithNewCustomerDialog.propTypes = {
  children: PropTypes.node,
}

export default WithNewCustomerDialog
