import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom-v5-compat'
import { useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { List, ListItemIcon, ListItemText, ListItemButton, Collapse, Divider, Box, useMediaQuery, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { isFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'
import { noop } from 'lodash'

const MenuGroup = ({ groupName = '', menuItems = [], RenderSecondaryAction, onLinkClick = noop }) => {
  const flags = useFlags()
  const location = useLocation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const pathname = get(location, 'pathname')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const handleClick = () => setIsOpen(!isOpen)

  return (
    <>
      <List disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ minWidth: 32 }}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6', fontWeight: 500 }} primary={groupName} />
        </ListItemButton>
        <Divider />
      </List>

      <Box>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ mb: 1 }} disablePadding>
            {Children.toArray(
              menuItems.map(menuItem => {
                const { label, url, featureFlag } = menuItem

                const handleMenuClick = () => {
                  if (isTabletOrMobile) {
                    // Close menu on small devices
                    dispatch(setShowCustomerSideNav(false))
                  }
                  onLinkClick(menuItem)
                }

                return (
                  (!featureFlag || isFeatureAvailable(flags, featureFlag)) && (
                    <ListItemButton selected={pathname === url} to={url} sx={{ pl: 3 }} onClick={handleMenuClick} component={Link}>
                      <ListItemText primaryTypographyProps={{ variant: 'h6', fontWeight: 400 }} primary={label} />
                      {RenderSecondaryAction && <RenderSecondaryAction {...menuItem} />}
                    </ListItemButton>
                  )
                )
              })
            )}
          </List>
        </Collapse>
      </Box>
    </>
  )
}

MenuGroup.propTypes = {
  groupName: PropTypes.string,
  menuItems: PropTypes.array,
  RenderSecondaryAction: PropTypes.node,
  onLinkClick: PropTypes.func,
}

export default MenuGroup
