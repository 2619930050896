/* eslint-disable no-param-reassign */
import { serializeDate } from 'utils/date'
import { createSlice } from '@reduxjs/toolkit'
import { get, sortBy } from 'lodash'
import { BULK_MOVE_MAP_LAYERS } from 'components/route-manager/BulkActions/settings'

export const VIEW_MODE_SEQUENCE = 'board'
export const VIEW_MODE_MAP = 'map'
export const DEFAULT_VIEW_MODE = VIEW_MODE_SEQUENCE
export const WO_COLOR_STOPS_LAYER = 'workorder-stops-layer'
export const ROUTE_COLOR_STOPS_LAYER = 'route-color-stops-layer'
export const WO_COLOR_STOPS_DOT_LAYER = 'workorder-stops-dot-layer'

export const ROUTE_STOPS_UNASSIGNED_LAYER = 'route-stops-unassigned-layer'
export const ROUTE_STOPS_UNASSIGNED_DOT_LAYER = 'route-stops-unassigned-dot-layer'

export const ROUTE_STOPS_UNASSIGNED = `route-stops-unassigned`
export const ROUTE_STOPS = `route-stops`
export const ROUTE_STOPS_LINE_STRING = `route-stops-line-string`
export const ROUTE_COLOR_STOPS_DOT_LAYER = 'route-color-stops-dot-layer'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS
const getInitialState = () => ({
  isUnassignedStopsOpen: false,
  viewMode: DEFAULT_VIEW_MODE,
  allRouteIds: [],
  allRoutes: [],
  filters: {
    serializedServiceDate: serializeDate(new Date()),
    selectedRouteIds: null,
    businessId: null,
  },
  map: {
    currentLayer: ROUTE_COLOR_STOPS_LAYER,
    isStreetView: true,
  },
  viewOnMap: {
    routeId: '',
  },
  breadcrumbsByRouteId: {},
  unsubscribeByRouteId: {},
  isBreadcrumbInitialized: false,
  stopsCountByRouteId: {},
  currentTab: 'sequence',
  isRouteChangesOnTemporaryMode: false,
  isDriverPathActive: false,
  isSequenceLineActive: false,
  resetSearch: false,
  selectedSearchResult: {},
  bulkAssign: {
    isOpen: false,
    selectedRows: [],
  },
  recentStopsMoved: {
    idMap: {},
    routeId: null,
  },
  recentDraggedStop: { id: '' },
  newRouteDrawer: {
    isOpen: false,
  },
  markerDetailsDrawer: {
    isOpen: false,
    workOrderId: null,
    stopIndex: null,
    isSearchRow: false,
  },
  resequenceHistory: {
    track: false,
    zoom: '',
    coord: null,
  },
  stopDetailsDialog: {
    isOpen: false,
    id: '',
    stop: {},
    // Refetch flag used to get new stop data after tags updation
    refetchStop: false,
  },
  stopDetailsPopover: {
    id: '',
    position: null,
    stop: {},
  },
  bulkMoveFailure: {
    isOpen: false,
    stops: [],
  },
})

const routeSlice = createSlice({
  name: 'Route',
  initialState: getInitialState(),
  reducers: {
    setSelectedSearchResult: (state, action) => {
      state.selectedSearchResult = action.payload
    },
    setResetSearch: (state, action) => {
      state.resetSearch = action.payload
    },
    setViewOnMapRouteId: (state, action) => {
      state.viewOnMap = {
        ...state.viewOnMap,
        routeId: action.payload,
      }
    },
    setAllRouteIds: (state, action) => {
      state.allRouteIds = action.payload
    },
    setAllRoutes: (state, action) => {
      state.allRoutes = action.payload
    },
    setCurrentMapLayer: (state, action) => {
      state.map = {
        ...state?.map,
        currentLayer: action.payload,
      }
    },
    setIsStreetView: (state, action) => {
      state.map = {
        ...state?.map,
        isStreetView: action.payload,
      }
    },
    toggleUnassignedStops: (state, action) => {
      state.isUnassignedStopsOpen = [true, false].includes(action.payload) ? action.payload : !state.isUnassignedStopsOpen
    },
    changeViewMode: (state, action) => {
      state.viewMode = ![VIEW_MODE_SEQUENCE, VIEW_MODE_MAP].includes(action.payload) ? VIEW_MODE_SEQUENCE : action.payload
    },
    setSerializedServiceDate: (state, action) => {
      const newSerializedServiceDate = action.payload
      if (!newSerializedServiceDate) {
        state.filters.serializedServiceDate = serializeDate(new Date())
      } else if (newSerializedServiceDate instanceof Date) {
        state.filters.serializedServiceDate = serializeDate(newSerializedServiceDate)
      } else {
        state.filters.serializedServiceDate = newSerializedServiceDate
      }
    },
    setStopsCountByRouteId: (state, action) => {
      state.stopsCountByRouteId = action.payload
    },
    setRouteBusinessId: (state, action) => {
      if (state?.filters?.businessId !== action.payload) {
        state.filters.selectedRouteIds = null
        state.filters.businessId = action.payload
      }
    },
    selectRouteIds: (state, action) => {
      state.filters.selectedRouteIds = Array.isArray(action.payload) ? sortBy([...action.payload]) : action.payload
    },
    changeIsRouteChangesOnTemporaryMode: (state, action) => {
      state.isRouteChangesOnTemporaryMode = action.payload
    },
    setIsDriverPathActive: (state, action) => {
      state.isDriverPathActive = action.payload
    },
    setIsSequenceLineActive: (state, action) => {
      state.isSequenceLineActive = action.payload
    },
    resetRouteManagerState: state => {
      return {
        ...getInitialState(),
        isUnassignedStopsOpen: state?.isUnassignedStopsOpen,
        filters: { businessId: state?.filters?.businessId, selectedRouteIds: state?.filters?.selectedRouteIds },
        isDriverPathActive: state?.isDriverPathActive,
        isSequenceLineActive: state?.isSequenceLineActive,
        map: state?.map,
      }
    },
    selectRowsForBulkAssign: (state, action) => {
      state.bulkAssign.selectedRows = action.payload
    },
    setIsOpenBulkAssign: (state, action) => {
      state.bulkAssign.isOpen = action.payload
    },
    setRecentStopsMoved: (state, action) => {
      state.recentStopsMoved = action.payload
    },
    setIsOpenNewRouteDrawer: (state, action) => {
      state.newRouteDrawer.isOpen = action.payload
    },
    setRecentDraggedStop: (state, action) => {
      state.recentDraggedStop = action.payload
    },
    setResequenceHistory: (state, action) => {
      state.resequenceHistory = { ...state.resequenceHistory, ...action.payload }
    },
    setStopDetailsDialogState: (state, action) => {
      state.stopDetailsDialog = { ...state.stopDetailsDialog, ...action.payload }
    },
    setStopDetailsPopoverState: (state, action) => {
      state.stopDetailsPopover = { ...state.stopDetailsPopover, ...action.payload }
    },
    setIsBreadcrumbInitialized: (state, action) => {
      state.isBreadcrumbInitialized = action.payload
    },
    setBreadcrumbsByRouteId: (state, action) => {
      state.breadcrumbsByRouteId = { ...state.breadcrumbsByRouteId, ...action.payload }
    },
    setUnsubscribeByRouteId: (state, action) => {
      state.unsubscribeByRouteId = { ...state.unsubscribeByRouteId, ...action.payload }
    },
    resetBreadcrumbsByRouteId: state => {
      state.breadcrumbsByRouteId = {}
    },
    resetUnsubscribeByRouteId: state => {
      state.unsubscribeByRouteId = {}
    },
    setBulkMoveFailure: (state, action) => {
      state.bulkMoveFailure = {
        ...state.bulkMoveFailure,
        ...action.payload,
      }
    },
  },
})
export const {
  setStopsCountByRouteId,
  toggleUnassignedStops,
  changeViewMode,
  setSerializedServiceDate,
  selectRouteIds,
  changeIsRouteChangesOnTemporaryMode,
  resetRouteManagerState,
  selectRowsForBulkAssign,
  setIsOpenBulkAssign,
  setRecentStopsMoved,
  setIsOpenNewRouteDrawer,
  setCurrentMapLayer,
  setViewOnMapRouteId,
  setAllRouteIds,
  setAllRoutes,
  setResetSearch,
  setSelectedSearchResult,
  setIsStreetView,
  setRecentDraggedStop,
  setResequenceHistory,
  setStopDetailsDialogState,
  setStopDetailsPopoverState,
  setIsBreadcrumbInitialized,
  setIsDriverPathActive,
  setIsSequenceLineActive,
  setBreadcrumbsByRouteId,
  resetBreadcrumbsByRouteId,
  setUnsubscribeByRouteId,
  resetUnsubscribeByRouteId,
  setBulkMoveFailure,
  setRouteBusinessId,
} = routeSlice.actions

export const selectIsBulkMoveFailureOpen = s => get(s, 'Route.bulkMoveFailure.isOpen', false)
export const selectBulkMoveFailureStops = s => get(s, 'Route.bulkMoveFailure.stops', [])

export const selectRouteSerializedServiceDate = s => get(s, 'Route.filters.serializedServiceDate')
export const selectRouteBusinessId = s => get(s, 'Route.filters.businessId', null)

export default routeSlice.reducer
