import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Typography, Grid, Stack, Box, ListItem, ListItemText } from '@mui/material'

import { formatDateToFEFormat } from 'utils/date'
import { getPaymentMethod, getPaymentMethodImage } from 'utils/payment'
import { PAYMENT_STATUS_COLORS } from 'components/common/chip/settings'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { PAYMENT_METHODS, PAYMENT_METHOD_IMAGES } from 'settings/constants/payment'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import PaymentStatusChip from 'components/common/chip/PaymentStatusChip'
import AccountSummary from '../../common/data-grid/AccountSummary'

const { CHECK, OTHER, ACCOUNT_CREDIT, ACH, CREDIT_CARD } = PAYMENT_METHODS

const RenderPaymentCell = ({ row = {} }) => {
  const {
    accountName,
    accountNumber,
    accountId,
    batchId,
    transactionStatus,
    paymentDate,
    transactionAmountCents,
    transactionPostedDate,
    creditCardType,
    accountLastFour,
    paymentMethod,
    checkNum,
  } = row

  const isCheckOther = [CHECK, OTHER].includes(paymentMethod)
  const isAccountCredit = paymentMethod === ACCOUNT_CREDIT
  const checkValue = isCheckOther ? checkNum : accountNumber
  const paymentMethodName = paymentMethod === ACH ? paymentMethod : capitalize(getPaymentMethod(paymentMethod))
  const paymentMethodImage = paymentMethod === CREDIT_CARD ? getPaymentMethodImage(creditCardType) : PAYMENT_METHOD_IMAGES[paymentMethod]
  const { onlyDate: transactionPostedDateFormatted } = convertDateTimeToPreferedTimezone({ dateTime: transactionPostedDate })

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')

  return (
    <Grid container>
      <AccountSummary accountId={accountId} accountNumber={accountNumber} accountName={accountName} batchNumber={batchId} />
      <Grid container px={2} columnSpacing={3}>
        <Grid item display="flex" alignItems="center">
          <Stack spacing={1}>
            <PaymentStatusChip status={transactionStatus} />
            {transactionStatus && (
              <Grid item sx>
                <Typography color={PAYMENT_STATUS_COLORS[transactionStatus][600]} variant="body2">
                  {T.POSTED}: {transformDate(paymentDate)}
                </Typography>
              </Grid>
            )}
          </Stack>
        </Grid>
        <Grid item xs display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <img className="mr2" src={paymentMethodImage} alt="" />
            <Stack>
              <Typography display="inline" variant="h6" fontWeight={400}>
                {creditCardType && accountLastFour ? `${creditCardType} •••• ${accountLastFour}` : paymentMethodName}
              </Typography>

              {(isCheckOther || isAccountCredit) && checkValue && <Typography variant="caption">{checkValue}</Typography>}
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <ListItem>
            <ListItemText
              primary={<HHDisplayMoney value={transactionAmountCents} />}
              primaryTypographyProps={{ variant: 'h6', fontWeight: 500, color: 'textPrimary', textAlign: 'right' }}
              secondary={`${capitalize(T.TRANSACTION_DATE)}: ${transactionPostedDateFormatted}`}
              secondaryTypographyProps={{ color: 'info.light', fontWeight: 400, mt: 1 }}
            />
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  )
}

RenderPaymentCell.propTypes = {
  row: PropTypes.object,
}

export default RenderPaymentCell
