import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedMoveFromRows,
  addSelectedMoveToRows,
  selectBulkMoveFitBounds,
  selectBulkMoveFromRouteSequenceLine,
  selectBulkMoveFromRouteStopMarkers,
  selectBulkMoveMapLayer,
  selectBulkMoveRoutesById,
  selectBulkMoveToRouteSequenceLine,
  selectBulkMoveToRouteStopMarkers,
  selectIsOpenBulkMoveStopsDialog,
} from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useFormContext } from 'react-hook-form'
import { MIDDLE_LAYER, TOP_LAYER } from 'utils/map'
import Proptypes from 'prop-types'
import ToggleRouteLayers from 'components/route-manager/BulkActions/BulkMoveStopsBetweenRoutesDialog/ToggleRouteLayers'
import { MapContext } from 'providers/MapProvider'
import {
  fitBoundsMoveBetweenRoutesLayers,
  removeLayersAndSources,
  toggleMoveBetweenRoutesLayers,
} from 'components/route-manager/BulkActions/settings'
import CommonSelectStopsToMoveMap from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/CommonSelectStopsToMoveMap'
import useRecreateRouteSourcesAndLayers from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useRecreateRouteSourcesAndLayers'
import useMapRefInitDispose from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useMapInitDispose'
import useUpdateRouteLayer from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useUpdateRouteLayer'
import useCustomSelectBoxMapbox from 'components/route-manager/BulkActions/common/SelectStopsToMoveMap/useCustomSelectBoxMapbox'

const SelectStopsToMoveMap = ({ routes }) => {
  const map = useContext(MapContext)
  const routesById = useSelector(selectBulkMoveRoutesById)
  const [layersCreated, setLayersCreated] = useState(false)
  const mapContainer = useRef(null)
  const { watch } = useFormContext()
  const dispatch = useDispatch()
  const open = useSelector(selectIsOpenBulkMoveStopsDialog)
  const moveFromRouteStopMarkers = useSelector(selectBulkMoveFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkMoveToRouteStopMarkers)
  const moveFromRouteSequenceLine = useSelector(selectBulkMoveFromRouteSequenceLine)
  const moveToRouteSequenceLine = useSelector(selectBulkMoveToRouteSequenceLine)
  const mapLayer = useSelector(selectBulkMoveMapLayer)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const fitBounds = useSelector(selectBulkMoveFitBounds)
  const recreateRouteSourcesAndLayers = useRecreateRouteSourcesAndLayers(map, routesById)
  const { isMapLoaded } = useMapRefInitDispose({ map, mapContainer, open })
  const updateRouteLayer = useUpdateRouteLayer(map)

  const getRouteIdFromSource = useCallback(
    source => {
      if (source.includes(moveToRouteId)) {
        return moveToRouteId
      }
      if (source.includes(moveFromRouteId)) {
        return moveFromRouteId
      }
      return null
    },
    [moveFromRouteId, moveToRouteId]
  )

  const onSelectFeatures = useCallback(
    features => {
      const selectedFromMarkerIds = []
      const selectedToMarkersIds = []
      features.forEach(feature => {
        const routeId = get(feature, 'properties.routeId')
        const stopId = get(feature, 'properties.id')
        const source = get(feature, 'source')
        const sourceRouteId = getRouteIdFromSource(source)
        if (routeId === moveFromRouteId && sourceRouteId === moveFromRouteId) selectedFromMarkerIds.push(stopId)
        if (routeId === moveFromRouteId && sourceRouteId === moveToRouteId) selectedToMarkersIds.push(stopId)
      })
      dispatch(addSelectedMoveFromRows(selectedFromMarkerIds))
      dispatch(addSelectedMoveToRows(selectedToMarkersIds))
    },
    [moveFromRouteId, moveToRouteId]
  )

  useCustomSelectBoxMapbox({ isMapLoaded, onSelectFeatures, moveFromRouteId, moveToRouteId })

  const recreateSourcesAndLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !routes.length) {
      return null
    }
    setLayersCreated(false)
    routes.forEach(route => {
      removeLayersAndSources(map.current, route.id)
    })
    if (moveFromRouteId)
      recreateRouteSourcesAndLayers(
        moveFromRouteId,
        {
          sequenceData: moveFromRouteSequenceLine,
          features: moveFromRouteStopMarkers,
        },
        TOP_LAYER
      )
    if (moveToRouteId)
      recreateRouteSourcesAndLayers(
        moveToRouteId,
        { sequenceData: moveToRouteSequenceLine, features: moveToRouteStopMarkers },
        MIDDLE_LAYER
      )
    setLayersCreated(true)
  }, [
    map,
    isMapLoaded,
    moveFromRouteId,
    moveToRouteId,
    routes,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
  ])

  const updateLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    if (moveFromRouteId) updateRouteLayer(moveFromRouteId, { sequenceData: moveFromRouteSequenceLine, features: moveFromRouteStopMarkers })
    if (moveToRouteId) updateRouteLayer(moveToRouteId, { sequenceData: moveToRouteSequenceLine, features: moveToRouteStopMarkers })
    toggleMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, moveFromRouteId, moveToRouteId)
  }, [
    map,
    isMapLoaded,
    layersCreated,
    moveFromRouteId,
    moveToRouteId,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
    updateRouteLayer,
  ])

  const fitBoundsToLayers = useCallback(() => {
    if (!map || !map.current) return null
    if (!isMapLoaded || !layersCreated) {
      return null
    }
    const mapCurrentRef = map.current
    fitBoundsMoveBetweenRoutesLayers(mapCurrentRef, mapLayer, moveFromRouteSequenceLine, moveToRouteSequenceLine)
  }, [map, isMapLoaded, layersCreated, moveFromRouteSequenceLine, moveToRouteSequenceLine])

  useEffect(() => {
    recreateSourcesAndLayers()
  }, [isMapLoaded, moveFromRouteId, moveToRouteId, routes])

  useEffect(() => {
    updateLayers()
  }, [
    moveToRouteId,
    moveFromRouteId,
    layersCreated,
    moveFromRouteStopMarkers,
    moveToRouteStopMarkers,
    moveFromRouteSequenceLine,
    moveToRouteSequenceLine,
  ])

  useEffect(() => {
    if (fitBounds) {
      fitBoundsToLayers()
    }
  }, [fitBounds, moveFromRouteSequenceLine, moveToRouteSequenceLine])

  return <CommonSelectStopsToMoveMap mapContainer={mapContainer} ToggleComponent={<ToggleRouteLayers map={map} />} />
}

SelectStopsToMoveMap.propTypes = {
  routes: Proptypes.array.isRequired,
}

export default memo(SelectStopsToMoveMap)
