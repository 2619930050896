import React from 'react'
import { AppBar, IconButton, ListItem, ListItemAvatar, ListItemText, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import WeekDayIcon from 'components/customer-details/content/routing/WeekDayIcon'
import { deserializeDate } from 'utils/date'

const ScheduleStopDialogAppBar = ({ onClose }) => {
  const stopToBeAssigned = useSelector(s => s.routingActions.assignStopDialog.stopToBeAssigned, shallowEqual)
  const addressStr = get(stopToBeAssigned, 'addressStr')
  const serviceName = get(stopToBeAssigned, 'accountName')
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const dayOfWeek = serviceDate.getDay()
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const day = days[dayOfWeek]

  return (
    <AppBar elevation={0} sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton edge="start" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <ListItem disableGutters>
          <ListItemAvatar sx={{ minWidth: 0, mr: 2 }}>
            <WeekDayIcon day={day} variant="active" />
          </ListItemAvatar>
          <ListItemText
            primary={serviceName}
            primaryTypographyProps={{ color: 'textPrimary', variant: 'h4', fontWeight: 600 }}
            secondary={addressStr}
            secondaryTypographyProps={{ color: 'textSecondary' }}
          />
        </ListItem>
      </Toolbar>
    </AppBar>
  )
}

ScheduleStopDialogAppBar.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default ScheduleStopDialogAppBar
