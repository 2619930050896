import React from 'react'
import { IconButton, ListItemSecondaryAction, MenuItem, Stack, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import PropTypes from 'prop-types'
import { formatDateToNotificationStr } from '../../../utils/date'
import NotificationItemText from '../NotificationItemText'

const NotificationsMenuItem = ({ title, description, date, unread = true }) => {
  const handleUnreadIconClick = () => {}
  const formattedDate = formatDateToNotificationStr(date)
  return (
    <MenuItem disableRipple disableTouchRipple sx={{ pt: 2 }}>
      <NotificationItemText
        primary={title}
        secondary={
          <Stack>
            <Typography>{description}</Typography>
            <Typography variant="body2">{formattedDate}</Typography>
          </Stack>
        }
      />
      <ListItemSecondaryAction sx={{ top: '32%', mr: 1 }}>
        {unread && (
          <IconButton onClick={handleUnreadIconClick}>
            <CircleIcon sx={{ fontSize: 10 }} color="primary" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </MenuItem>
  )
}

NotificationsMenuItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  unread: PropTypes.bool,
}

export default NotificationsMenuItem
