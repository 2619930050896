import { TIME_ZONES, OLD_NEW_TIMEZONE_LINKING } from 'settings/constants/timeZones'

export const getTimeZoneOptions = timeZone => {
  const isNewTimeZone = !timeZone || TIME_ZONES.find(zone => zone.key === timeZone)

  if (isNewTimeZone) {
    // New zones
    return TIME_ZONES
  }

  const linkedZone = OLD_NEW_TIMEZONE_LINKING.get(timeZone)
  // Replace with old zone, we can also directly use this logic
  return TIME_ZONES.map(zone => (zone.key === linkedZone ? { ...zone, key: timeZone } : zone))
}
