import { createSlice } from '@reduxjs/toolkit'

const initialState = { showBillingSideNav: true }

const billingSideNavSlice = createSlice({
  name: 'BillingSideNav',
  initialState,
  reducers: {
    setShowBillingSideNav(state, action) {
      state.showBillingSideNav = action.payload
    },
  },
})

export const { setShowBillingSideNav } = billingSideNavSlice.actions
export default billingSideNavSlice.reducer
