import React, { useState, useEffect } from 'react'

const ErrorPage = () => {
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    // added timeout, so that Lauch darkly flags are available
    setTimeout(() => setErrMsg('Error Page'), 1500)
  }, [])

  return <div className="common-padding">{errMsg}</div>
}

export default ErrorPage
