import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Checkbox,
  useTheme,
  Stack,
  Button,
  darken,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { clamp, get, isEmpty } from 'lodash'
import T from 'T'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'
import { createDateFromString, formatDateToBEFormatDateFns } from 'utils/date'
import { HHFormNumberField, HHFormTextField } from 'components/form-fields/v5'
import { getMetadataRoutes } from 'data/route/routeMetadataSelector'
import { selectRowsForBulkAssign, setIsOpenBulkAssign } from '../../../slices/route/routeSlice'

const BulkAssignForm = ({ serviceDate, unassignedWorkOrders, handleBulkMoveStopsFromUnassigned, routeList }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const selectedRoutes = useSelector(s => s.Route.bulkAssign.selectedRows, shallowEqual)
  const selectedRoutesCount = Array.isArray(selectedRoutes) ? selectedRoutes.length : 0
  const serviceDateCast = createDateFromString(serviceDate)
  const allRoutes = useSelector(getMetadataRoutes)
  const routeId = get(allRoutes, '[0].id')
  const indeterminateSelectAll = selectedRoutesCount !== 0 && unassignedWorkOrders.length !== selectedRoutesCount
  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      date: serviceDateCast,
      destinationRouteId: '',
      sequence: 1,
    },
  })

  const handleCancel = () => {
    dispatch(selectRowsForBulkAssign([]))
    dispatch(setIsOpenBulkAssign(false))
  }

  const handleSelectAll = event => {
    const { checked } = event.target
    dispatch(selectRowsForBulkAssign(checked ? unassignedWorkOrders : []))
  }
  const onSubmit = async data => {
    const { destinationRouteId, sequence, date } = data
    const stops = selectedRoutes.map(({ workOrderId }) => workOrderId)
    let targetStopId
    if (sequence > 1) {
      const targetRoute = routeList.find(({ id }) => id === destinationRouteId)
      const numberToClamp = sequence - 2
      const targetStops = get(targetRoute, 'stops', [])
      const index = clamp(numberToClamp, 0, targetStops.length - 1)
      if (!isEmpty(targetStops)) {
        targetStopId = get(targetStops, `${index}.workOrderId`)
      }
    }
    await handleBulkMoveStopsFromUnassigned({ destinationRouteId, serviceDate: formatDateToBEFormatDateFns(date), stops, targetStopId })
    handleCancel()
  }

  useEffect(() => {
    if (routeId) {
      reset({
        date: serviceDateCast,
        destinationRouteId: routeId,
        sequence: 1,
      })
    }
  }, [routeId])

  return (
    <Card elevation={0} sx={{ backgroundColor: theme.palette.background.header, flex: '0 0 auto' }}>
      <CardHeader
        title="Bulk assign"
        sx={{ color: theme.palette.primary.main }}
        titleTypographyProps={{ variant: 'h4', fontWeight: 600 }}
      />
      <CardContent sx={{ py: 0 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <HHFormDesktopDatePicker name="date" control={control} />
          </Grid>
          <Grid item xs={12}>
            <HHFormTextField
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                  PaperProps: {
                    sx: { maxHeight: 'calc(100vh - 500px)', width: '344px' },
                  },
                },
              }}
              control={control}
              name="destinationRouteId"
              select
              fullWidth
            >
              {allRoutes.map(option => {
                const routeName = get(option, 'name')
                const routeId = get(option, 'id')
                return <MenuItem value={routeId}>{routeName}</MenuItem>
              })}
            </HHFormTextField>
          </Grid>
          <Grid item xs={12}>
            <HHFormNumberField deprecatedLabel={false} label={T.SEQUENCE_POSITION} control={control} name="sequence" fullWidth />
          </Grid>
          <Grid container item>
            <ListItem
              disableGutters
              disablePadding
              dense
              secondaryAction={
                <Stack direction="row" spacing={1}>
                  <Button
                    sx={{
                      color: theme.palette.common.black,
                      bgcolor: theme.palette.common.white,
                    }}
                    size="small"
                    variant="outlined"
                    color="textSecondary"
                    onClick={handleCancel}
                  >
                    {T.CANCEL}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="complete"
                    disabled={selectedRoutesCount === 0}
                    sx={{
                      color: `${theme.palette.complete.contrastText}`,
                      background: `${theme.palette.complete.main}`,
                      border: `1px solid ${theme.palette.complete.main}`,
                      '&:hover': {
                        background: `${darken(theme.palette.complete.main, 0.25)}`,
                      },
                      '&:disabled': {
                        color: `${theme.palette.complete.contrastText}`,
                        background: `${theme.palette.complete.dark}`,
                      },
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {T.ASSIGN}
                  </Button>
                </Stack>
              }
            >
              <ListItemAvatar sx={{ minWidth: 32 }}>
                <Checkbox
                  indeterminate={indeterminateSelectAll}
                  color="primary"
                  sx={{ ml: -1, color: theme.palette.primary.main }}
                  onChange={handleSelectAll}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${selectedRoutesCount} Selected`}
                primaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'primary' }}
              />
            </ListItem>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

BulkAssignForm.propTypes = {
  serviceDate: PropTypes.string,
  unassignedWorkOrders: PropTypes.array.isRequired,
  handleBulkMoveStopsFromUnassigned: PropTypes.func.isRequired,
  routeList: PropTypes.array.isRequired,
}

export default BulkAssignForm
