import React, { useEffect } from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import T from '../T'
import { useLazyGetNotificationListQuery } from '../api/notifications/getNotificationList'
import { groupNotificationsByDate } from '../utils/notifications'
import NotificationListItem from '../components/notifications/NotificationListItem'

const NotificationsPage = () => {
  const [getNotificationList, { isLoading, data }] = useLazyGetNotificationListQuery()
  const notificationList = get(data, 'notificationList', [])
  const notificationGroups = groupNotificationsByDate(notificationList, 'day')
  const notificationGroupKeys = Array.from(notificationGroups.keys())
  const showNotificationList = !isLoading && Array.isArray(notificationGroupKeys) && notificationGroupKeys.length > 0

  useEffect(() => {
    getNotificationList()
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 56px)', width: '100%' }}>
      <Typography variant="h3" fontWeight="bold" pt={5} pl={5} pb={7}>
        {T.NOTIFICATIONS}
      </Typography>
      <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <Container sx={{ flex: '1 1 auto', pb: 4 }}>
          <Stack>
            {showNotificationList &&
              notificationGroupKeys.map((key, index) => {
                const notificationSubList = notificationGroups.get(key)
                return (
                  <>
                    <Typography sx={{ ml: 3, pt: index !== 0 ? 2 : 0 }} gutterBottom>
                      {key}
                    </Typography>
                    <Stack spacing={0.5}>
                      {notificationSubList.map(({ title, description, date }) => (
                        <NotificationListItem title={title} description={description} date={date} />
                      ))}
                    </Stack>
                  </>
                )
              })}
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export default NotificationsPage
