import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateExceptionNote: builder.mutation({
      query: ({ id, note }) => ({
        url: `api/workorder/exception/note/update`,
        method: 'POST',
        body: {
          exceptionId: id,
          noteText: note,
        },
      }),
    }),
  }),
})

export const { useUpdateExceptionNoteMutation } = extendedApi
