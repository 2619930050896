import { UPDATE_PERMISSION, FULL_PERMISSION, READ_PERMISSION } from 'settings/constants/roles'

// Later add to Constants.js file
const IS_ROLE_CHECK = true

export const hasReadPermissions = code => {
  if (IS_ROLE_CHECK) {
    return code >= READ_PERMISSION
  }

  return true
}

export const hasAddPermissions = code => {
  if (IS_ROLE_CHECK) {
    return code === FULL_PERMISSION
  }

  return true
}

export const hasUpdatePermissions = code => {
  if (IS_ROLE_CHECK) {
    return code >= UPDATE_PERMISSION
  }

  return true
}
