import { actions } from '../actions/configuredServices'

const { SEARCH_PRICING_SERVICE } = actions.PUT

const initialState = {
  pricedServices: null,
  pricedServiceDetails: null,
}

const ConfiguredServicesReducer = (state = initialState, action = {}) => {
  if (action.type === SEARCH_PRICING_SERVICE) {
    return { ...state, pricedServices: action.payload }
  }
  return state
}

export default ConfiguredServicesReducer
