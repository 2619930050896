import React, { createContext, useState } from 'react'

export const LocationsActionsContext = createContext()

export const LocationsActionsProvider = ({ children }) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null)
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null)
  const [sortingAnchorEl, setSortingAnchorEl] = useState(null)

  const openActions = Boolean(actionsAnchorEl)
  const openFilters = Boolean(filtersAnchorEl)
  const openSorting = Boolean(sortingAnchorEl)

  const handleActionsClick = event => {
    setActionsAnchorEl(event.currentTarget)
  }

  const handleFiltersClick = event => {
    setFiltersAnchorEl(event.currentTarget)
  }

  const handleSortingClick = event => {
    setSortingAnchorEl(event.currentTarget)
  }

  const handleActionsClose = () => {
    setActionsAnchorEl(null)
  }

  const handleFiltersClose = () => {
    setFiltersAnchorEl(null)
  }

  const handleSortingClose = () => {
    setSortingAnchorEl(null)
  }

  return (
    <LocationsActionsContext.Provider
      value={{
        actionsAnchorEl,
        openActions,
        handleActionsClick,
        handleActionsClose,
        filtersAnchorEl,
        openFilters,
        handleFiltersClick,
        handleFiltersClose,
        sortingAnchorEl,
        openSorting,
        handleSortingClick,
        handleSortingClose,
      }}
    >
      {children}
    </LocationsActionsContext.Provider>
  )
}
