import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Grid, IconButton, Typography, Tooltip } from '@mui/material'
import { Add, MoreVert } from '@mui/icons-material'
import ReorderIcon from 'components/common/ReorderIcon'

import { get } from 'utils/lodash'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'
import { setShowBillingSideNav } from 'slices/billing/BillingSideNavSlice'
import T from 'T'
import Loader from 'components/common/loader'
import PaymentGroupActionMenu from '../PaymentGroupActionMenu'
import TakePaymentsDialog from '../take-payments'

const PaymentGroupDetailsHeader = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { data, isLoading, isFetching } = useGetPaymentGroupDetailsQuery({ id })
  const [paymentGroupAnchorEl, setPaymentGroupAnchorEl] = useState(null)
  const [isOpenTakePaymentsDialog, setIsOpenTakePaymentsDialog] = useState(false)
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)

  const handleDrawerToggle = () => dispatch(setShowBillingSideNav(!showBillingSideNav))
  const handleOpenPaymentGroupActionMenu = event => setPaymentGroupAnchorEl(event.currentTarget)
  const handleClosePaymentGroupActionMenu = () => setPaymentGroupAnchorEl(false)
  const handleToggleTakePaymentsDialog = () => setIsOpenTakePaymentsDialog(!isOpenTakePaymentsDialog)

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <Grid
        container
        pr={3}
        pl={showBillingSideNav ? 3 : 1.5}
        minHeight={56}
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        bgcolor="#f7f9fc"
      >
        <Grid item xs display="flex" alignItems="center">
          {!showBillingSideNav && <ReorderIcon onClick={handleDrawerToggle} />}
          <Typography variant="h4" fontWeight={600}>
            {get(data, 'groupName')}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title={`${T.TAKE_PAYMENT}s`}>
            <IconButton onClick={handleToggleTakePaymentsDialog}>
              <Add />
            </IconButton>
          </Tooltip>

          <IconButton onClick={handleOpenPaymentGroupActionMenu}>
            <MoreVert />
          </IconButton>
        </Grid>
      </Grid>
      <PaymentGroupActionMenu anchorEl={paymentGroupAnchorEl} selectedGroup={data} onClose={handleClosePaymentGroupActionMenu} />
      {isOpenTakePaymentsDialog && (
        <TakePaymentsDialog isOpen={isOpenTakePaymentsDialog} initialGroupId={id} onClose={handleToggleTakePaymentsDialog} />
      )}
    </>
  )
}

export default PaymentGroupDetailsHeader
