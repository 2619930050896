import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

import { Grid, InputAdornment, Box, MenuItem, Stack, Button } from '@mui/material'

import { XCircle } from '@styled-icons/heroicons-outline/XCircle'
import { ChevronUp } from '@styled-icons/heroicons-outline/ChevronUp'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { createDateFromString } from 'utils/date'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isValidPrice } from 'utils/validations'
import { isRecurringService } from 'utils/service'
import { SIGNATURE_REQUIRED_OPTIONS } from 'settings/constants/service'
import { CommonTextLabel, CommonTextfield, CommonSwitch, CommonSelect } from 'components/common'
import { useLazyGetPermitListQuery } from 'api/settings/getPermitList'
import T from 'T'
import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'settings/constants/date'
import { addDays } from 'date-fns'
import StyledTextField from 'components/form-fields/v5/StyledTextField'
import EventIcon from '@mui/icons-material/Event'
import StyledAutocomplete from 'components/form-fields/v5/StyledAutocomplete'

const AdvancedTabContent = ({ serviceType, advanced, skillsMeta, onContainerChange, onChange }) => {
  const [getPermitList, { data, isUninitialized }] = useLazyGetPermitListQuery()
  const purchaseOrder = get(advanced, 'purchaseOrder', false)
  const containerList = get(advanced, 'containerList', [])
  const minimumBillingPeriods = get(advanced, 'minimumBillingPeriods', false)
  const minimumBillingPeriodsNumber = get(advanced, 'minimumBillingPeriodsNumber', 0)
  const signatureRequired = get(advanced, 'signatureRequired', false)
  const skillsRequired = get(advanced, 'skillsRequired', false)
  const permit = get(advanced, 'permit', false)
  const permitId = get(advanced, 'permitId', '')
  const permitObjArray = get(advanced, 'permitObjArray', undefined)
  const permitStartDate = get(advanced, 'permitStartDate', '')
  const permitEndDate = get(advanced, 'permitEndDate', '')

  useEffect(() => {
    if (isUninitialized || permit) {
      getPermitList()
    }
  }, [isUninitialized, permit])

  useEffect(() => {
    if (!!data && !!permitId && permitObjArray === undefined) {
      const selectedPermitObj = data.permitList.find(({ id }) => advanced.permitId === id)
      if (selectedPermitObj) {
        onChange('permitObjArray', [selectedPermitObj], 'advanced')
      }
    }
  }, [data, permitId, permitObjArray])

  useEffect(() => {
    if (Array.isArray(permitObjArray) && permitObjArray.length > 0 && permitObjArray[0]?.permitObjArray) {
      onChange('permitType', permitObjArray, 'advanced')
    }
  }, [permitObjArray])

  return (
    <div className="advanced-tab-content common-padding-lr-3-5">
      <Stack sx={{ mt: 2 }} className="switch">
        <CommonSwitch
          title={T.PURCHASE_ORDER}
          isChecked={purchaseOrder}
          onChange={checked => onChange('purchaseOrder', checked, 'advanced')}
        />
      </Stack>

      {purchaseOrder && (
        <Grid container className="purchase-order" mt={1} spacing={2}>
          <Grid item xs={12} sm={6}>
            <CommonTextLabel title={`${T.PO} #`} className="ttc" />
            <CommonTextfield
              value={get(advanced, 'purchaseOrderNumber', '')}
              placeholder={`${T.PO} #`}
              onChange={event => onChange('purchaseOrderNumber', event.target.value, 'advanced')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextLabel title={T.MAX_PO_AMOUNT} className="ttc" />
            <CommonTextfield
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              value={get(advanced, 'maximumPurchaseOrderAmount', '')}
              placeholder={T.MAX_PO_AMOUNT}
              onChange={event => {
                if (isValidPrice(event.target.value)) {
                  onChange('maximumPurchaseOrderAmount', event.target.value, 'advanced')
                }
              }}
            />
          </Grid>
        </Grid>
      )}

      <Stack sx={{ mt: 2 }} className="switch">
        <CommonSwitch
          title={T.CONTAINER_DETAIL}
          isChecked={get(advanced, 'container', false)}
          // Min one container required, so always true
          onChange={() => onChange('container', true, 'advanced')}
        />
      </Stack>

      {containerList.length > 0 &&
        Children.toArray(
          containerList.map((container, index) => {
            return (
              <Stack alignItems="center" flexDirection="row" className="container-list">
                <CommonTextLabel title={get(container, 'containerName', '')} className="ttc" />
                <CommonTextfield
                  value={get(container, 'containerDescription', '')}
                  onChange={e => {
                    const containerCopy = cloneDeep(containerList)
                    set(containerCopy, `[${index}].containerDescription`, e.target.value)
                    onContainerChange(containerCopy)
                  }}
                />

                {index > 0 && (
                  <XCircle
                    className="icon-w-16 remove cursor-pointer"
                    onClick={() => {
                      const containerId = get(container, 'id')
                      let deletedContainerIds = get(advanced, 'deletedContainerIds', [])
                      if (containerId) {
                        deletedContainerIds = [...deletedContainerIds, containerId]
                      }
                      onContainerChange(
                        containerList
                          .filter((list, cIndex) => cIndex !== index)
                          .map((list, cIndex) => ({ ...list, containerName: `Container #${cIndex + 1}` })),
                        deletedContainerIds
                      )
                    }}
                  />
                )}
              </Stack>
            )
          })
        )}

      <div className="add-container">
        <Button
          onClick={() =>
            onContainerChange([
              ...containerList,
              {
                containerName: `Container #${containerList.length + 1}`,
                containerDescription: '',
              },
            ])
          }
          size="small"
        >
          {T.ADD_CONTAINER}
        </Button>
      </div>

      {isRecurringService(serviceType) && (
        <Stack sx={{ mt: 2 }} className="switch flex">
          <CommonSwitch
            title={`${T.MINIMUM_BILLING_PERIOD}s`}
            isChecked={minimumBillingPeriods}
            // onChange={checked => onChange('minimumBillingPeriods', checked, 'advanced')}
            onChange={checked =>
              onChange('advanced', { ...advanced, minimumBillingPeriods: checked, minimumBillingPeriodsNumber: checked ? 1 : 0 })
            }
          />
        </Stack>
      )}

      {minimumBillingPeriods && (
        <Box sx={{ mt: 1 }}>
          <Stack flexDirection="row" className="number-arrows-field">
            <CommonTextfield value={minimumBillingPeriodsNumber} />

            <Stack className="tool-section">
              <Stack alignItems="center" justifyContent="center" className="upper">
                <ChevronUp
                  className="icon-w-16 cursor-pointer"
                  onClick={e => {
                    e.stopPropagation()
                    if (minimumBillingPeriodsNumber < 10) {
                      onChange('minimumBillingPeriodsNumber', minimumBillingPeriodsNumber + 1, 'advanced')
                    }
                  }}
                />
              </Stack>
              <Stack alignItems="center" justifyContent="center" className="lower">
                <ChevronDown
                  className="icon-w-16 cursor-pointer"
                  onClick={e => {
                    e.stopPropagation()
                    if (minimumBillingPeriodsNumber > 1) {
                      onChange('minimumBillingPeriodsNumber', minimumBillingPeriodsNumber - 1, 'advanced')
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}

      <Stack sx={{ mt: 2 }} className="switch">
        <CommonSwitch
          title={T.SIGNATURE_REQUIRED}
          isChecked={signatureRequired}
          onChange={checked => onChange('signatureRequired', checked, 'advanced')}
        />
      </Stack>

      {signatureRequired && (
        <Box mt={1}>
          <CommonSelect
            className="common-service-select"
            placeholder="None"
            value={get(advanced, 'signatureType', '')}
            options={SIGNATURE_REQUIRED_OPTIONS}
            onChange={e => onChange('signatureType', e.target.value, 'advanced')}
          />
        </Box>
      )}

      <Stack sx={{ mt: 2 }} className="switch">
        <CommonSwitch
          title={T.SKILLS_REQUIRED}
          isChecked={skillsRequired}
          onChange={checked => onChange('skillsRequired', checked, 'advanced')}
        />
      </Stack>

      {skillsRequired && (
        <Box mt={1}>
          <CommonSelect
            className="common-service-select"
            placeholder="None"
            value={get(advanced, 'skillId', '')}
            options={skillsMeta}
            onChange={e => onChange('skillId', e.target.value, 'advanced')}
          />
        </Box>
      )}
      <Stack sx={{ mt: 2 }} className="switch">
        <CommonSwitch title={T.PERMIT} isChecked={permit} onChange={checked => onChange('permit', checked, 'advanced')} />
      </Stack>
      {permit && (
        <Grid container mt={1} spacing={1}>
          <Grid item xs={12}>
            <CommonTextLabel title={`${T.PERMIT} ${T.NAME}`} className="ttc" />
            <StyledAutocomplete
              options={get(data, 'permitList', []).filter(({ active }) => active)}
              getOptionLabel={option => get(option, 'permitName', '')}
              onChange={(e, newValue) => {
                onChange('permitObjArray', [newValue], 'advanced')
              }}
              value={get(advanced, 'permitObjArray[0]', []) ?? []}
              getOptionSelected={(option, value) => option?.id === value?.id}
              renderInput={params => <StyledTextField {...params} label="" variant="outlined" />}
              renderOption={(props, option) => <MenuItem {...props}>{get(option, 'permitName', '')}</MenuItem>}
            />
          </Grid>
          <Grid item xs={12}>
            <CommonTextLabel title={T.TYPE} className="ttc" />
            <CommonTextfield disabled value={get(advanced, 'permitObjArray[0].permitType', '')} onChange={() => {}} />
          </Grid>
          <Grid item xs={6}>
            <CommonTextLabel title={T.LOCATION} className="ttc" />
            <CommonSelect
              className="common-service-select"
              value={get(advanced, 'permitLocation', '')}
              options={['Alley', 'Curb', 'Permanent']}
              onChange={e => onChange('permitLocation', e.target.value, 'advanced')}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonTextLabel title={T.NUMBER_OF_CONTAINERS} className="ttc" />
            <CommonTextfield
              value={get(advanced, 'permitContainerCount', '')}
              onChange={e => {
                onChange('permitContainerCount', e.target.value, 'advanced')
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              '& .react-datepicker-wrapper': {
                paddingLeft: 3,
              },
            }}
          >
            <CommonTextLabel title={T.START_DATE} className="ttc" />
            <div className="date-picker relative common textfield">
              <EventIcon color="action" className="cal-icon" />
              <DatePicker
                className="MuiInputBase-input"
                placeholderText={FRONTEND_DATE_FORMAT}
                selected={createDateFromString(permitStartDate)}
                onChange={date => {
                  const parsedDate = date ? moment(date).format(BACKEND_DATE_FORMAT) : undefined
                  onChange('permitStartDate', parsedDate, 'advanced')
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              '& .react-datepicker-wrapper': {
                paddingLeft: 3,
              },
            }}
          >
            <CommonTextLabel title={T.END_DATE} className="ttc" />
            <div className="date-picker relative common textfield">
              <EventIcon color="action" className="cal-icon" />
              <DatePicker
                className="MuiInputBase-input"
                placeholderText={FRONTEND_DATE_FORMAT}
                selected={createDateFromString(permitEndDate)}
                minDate={permitStartDate ? addDays(createDateFromString(permitStartDate), 1) : undefined}
                onChange={date => {
                  const parsedDate = date ? moment(date).format(BACKEND_DATE_FORMAT) : undefined
                  onChange('permitEndDate', parsedDate, 'advanced')
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <CommonTextLabel title={`${T.PERMIT} #`} className="ttc" />
            <CommonTextfield
              value={get(advanced, 'permitNote', '')}
              onChange={e => {
                onChange('permitNote', e.target.value.toString().replaceAll(' ', ''), 'advanced')
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

AdvancedTabContent.defaultProps = {
  serviceType: '',
  advanced: {},
  skillsMeta: [],
}

AdvancedTabContent.propTypes = {
  serviceType: PropTypes.string,
  advanced: PropTypes.object,
  skillsMeta: PropTypes.array,
  onContainerChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(AdvancedTabContent)
