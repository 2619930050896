import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getReportTableContent: build.query({
      query: body => ({
        url: `/api/report/data/list`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useLazyGetReportTableContentQuery } = extendedApi
