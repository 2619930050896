import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Popover } from '@mui/material'
import { memo } from 'utils/react'
import { get } from 'lodash'
import { useLazyGetAllLocationNotesQuery } from 'api/notes/locationNotesCrud'
import { handleError } from 'utils/error'
import { getArchivedAndUnarchivedNotes, getSortedNotes } from 'data/notes/notesSelectors'
import LocationNotes from 'components/notes/location/LocationNotes'
import T from 'T'
import NoteSkeleton from 'components/notes/common/NoteSkeleton'

const LocationNotesPopover = ({ locationId, anchorEl, onClose }) => {
  const open = Boolean(anchorEl)
  const [getAllLocationNotes, { isFetching, isLoading, data: notesData }] = useLazyGetAllLocationNotesQuery()
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const isGetAllNotesLoading = isFetching || isLoading

  useEffect(() => {
    if (locationId && open) {
      getAllLocationNotes({ locationId }).unwrap().catch(handleError)
    }
  }, [locationId, open])

  return (
    <Popover
      sx={{ pointerEvents: 'none', minWidth: 'fit-content' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
      slotProps={{ paper: { sx: { width: 'max-content' } } }}
    >
      <Box>
        {isGetAllNotesLoading && (
          <Box minWidth={300}>
            <NoteSkeleton rows={1} showActions={false} />
          </Box>
        )}
        {!isGetAllNotesLoading && (
          <LocationNotes
            showSecondaryActions={false}
            showFullVersion
            notes={notes}
            locationId={locationId}
            noNotesText={T.NO_LOCATION_NOTES}
          />
        )}
      </Box>
    </Popover>
  )
}

LocationNotesPopover.propTypes = {
  locationId: PropTypes.string,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
}

export default memo(LocationNotesPopover)
