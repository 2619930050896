import React, { useRef, useEffect, Children } from 'react'
import PropTypes from 'prop-types'

import autosize from 'autosize'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { combineAddressInfo, formatPhone } from 'utils/helper'
import { getUserSearchResult } from 'middleware/actions/customers'
import { USER_SEARCH_PAGINATION } from 'settings/constants/pagination'
import { CommonTextLabel, CommonPhotoSelect } from 'components/common'

import T from 'T'
import AvatarSquare from 'components/user/AvatarSquare'

import AdditionalNote from './AdditionalNote'

import './mention-style.scss'

const { INITIAL_PAGE, ROWS_PER_PAGE } = USER_SEARCH_PAGINATION

const CommonBody = ({ isOpenDrawer = false, details, isFollowUp, addAdditionalFollowUps, onChange }) => {
  const subjectRef = useRef()
  const noteRef = useRef()
  const dispatch = useDispatch()

  const { userSearchResult } = useSelector(
    state => ({
      userSearchResult: get(state, 'CustomersReducer.userSearchResult', []),
    }),
    shallowEqual
  )

  const loggedByUserImage = get(details, 'userInfo.profilePicUri', '')
  const loggedByFName = get(details, 'createdByFirstName', '')
  const loggedByLName = `${get(details, 'createdByLastName', '')}`
  const loggedByCompleteName = `${loggedByFName} ${loggedByLName}`.trim()

  const followUpNotes = get(details, 'followUpNotes', [])

  const address = combineAddressInfo(
    get(details, 'location', '') ? get(details, 'location.address', {}) : get(details, 'account.billingAddress', {})
  )

  // Get assignee list
  const getAssigneeList = (search = '') => {
    dispatch(
      getUserSearchResult({
        search,
        requestedPage: INITIAL_PAGE,
        requestedPageSize: ROWS_PER_PAGE,
      })
    )
  }

  useEffect(() => {
    autosize(subjectRef.current)
    autosize(noteRef.current)
  }, [])

  useEffect(() => {
    if (isOpenDrawer && isFollowUp) {
      // Call user assignee list only in case of followUp
      getAssigneeList()
    }
  }, [isOpenDrawer, isFollowUp])

  return (
    <div className="calls-content transparent-scroll">
      <div className="text-area-container subject">
        <textarea
          ref={subjectRef}
          className="common textarea subject-textarea"
          placeholder={T.SUBJECT}
          value={get(details, 'subject', '')}
          onChange={event => onChange('subject', event.target.value)}
        />
      </div>

      <div className="call-details common-margin">
        <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
          <CommonTextLabel title={isFollowUp ? T.LOGGED_BY : T.ADDED_BY} className="ttc common-label label-left" />
          <Stack flexDirection="row" alignItems="center" className="ttc common-label label-right flex items-center">
            <AvatarSquare firstName={loggedByFName} lastName={loggedByLName} src={loggedByUserImage} />
            <Typography ml={1}>{loggedByCompleteName}</Typography>
          </Stack>
        </Stack>

        {isFollowUp && (
          <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
            <CommonTextLabel title={T.ASSIGNEE} className="ttc common-label label-left" />
            <Stack flexDirection="row" alignItems="center" className="ttc common-label label-right">
              <CommonPhotoSelect
                users={userSearchResult}
                selectedUser={get(details, 'assignee')}
                className="drawer-assignee-dropdown"
                placeholder={T.ASSIGNEE}
                onChange={(isChanged, value) => {
                  if (isChanged) {
                    onChange('assignee', value)
                  }
                  // TODO: For future don't call API for empty value, load from state directly just like Account rep in add customer
                  getAssigneeList(isChanged ? '' : value)
                }}
              />
            </Stack>
          </Stack>
        )}

        <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
          <CommonTextLabel title={T.SERVICE_LOCATION} className="ttc common-label label-left whitespace-nowrap" />
          <CommonTextLabel title={address} className="ttc common-label label-right" />
        </Stack>
        <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
          <CommonTextLabel title={T.CONTACT} className="ttc common-label label-left" />
          <CommonTextLabel
            title={`${get(details, 'contact.firstName', '')} ${get(details, 'contact.lastName', '')}`.trim()}
            className="ttc common-label label-right"
          />
        </Stack>
        <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
          <CommonTextLabel title={T.CONTACT_METHOD} className="ttc common-label label-left" />
          <CommonTextLabel title={formatPhone(get(details, 'contactMethod.methodValue', ''))} className="ttc common-label label-right" />
        </Stack>
        <Stack flexDirection="row" alignItems="center" sx={{ mb: 1 }}>
          <CommonTextLabel title={T.DIRECTION} className="ttc common-label label-left" />
          <CommonTextLabel title={get(details, 'direction', '')} className="ttc common-label label-right" />
        </Stack>
      </div>

      {!isFollowUp && (
        <>
          <CommonTextLabel title={T.CALL_NOTE} className="ttc common-label label-left common-margin" />
          <div className="call-note">
            <div className="full-note text-area-container">
              <textarea
                name="note"
                autoComplete="off"
                ref={noteRef}
                className="common textarea notes-textarea"
                placeholder={T.NOTE}
                value={get(details, 'note', '')}
                onChange={event => onChange('note', event.target.value)}
              />
            </div>
          </div>
        </>
      )}

      {isFollowUp &&
        followUpNotes.length > 0 &&
        Children.toArray(
          followUpNotes.map((note, index) => {
            return (
              <>
                {index === 0 && <CommonTextLabel title={T.NOTE} className="ttc common-label label-left common-margin" />}
                <AdditionalNote note={note} index={index} addAdditionalFollowUps={addAdditionalFollowUps} onChange={onChange} />
              </>
            )
          })
        )}
    </div>
  )
}

CommonBody.defaultProps = {
  details: null,
  isFollowUp: false,
  addAdditionalFollowUps: null,
}

CommonBody.propTypes = {
  isOpenDrawer: PropTypes.bool,
  details: PropTypes.object,
  isFollowUp: PropTypes.bool,
  addAdditionalFollowUps: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default memo(CommonBody)
