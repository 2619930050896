import React from 'react'

import { useParams } from 'react-router-dom'
import { useGridApiContext, useGridSelector, gridRowCountSelector } from '@mui/x-data-grid-pro'
import { Box, Card, CardHeader, Divider, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { calculateSum } from 'utils/price'

import T from 'T'
import AgingBucketContent from 'components/customers/accounts/common/AgingBucketContent'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import FiltersMenuButton from 'components/customers/groups/account/details/filters/FiltersMenuButton'
import Summary from './Summary'
import AccountGroupStatistics from './AccountGroupStatistics'
import { getIsDefaultBucket, getIsSuspendedBucket } from '../common/settings'

const TopSection = () => {
  const apiRef = useGridApiContext()
  const { id } = useParams()
  const { data } = useGetAccountGroupDetailsData(id, true)
  const isDefaultBucket = getIsDefaultBucket(id)
  const isSuspendedBucket = getIsSuspendedBucket(id)
  const isSuspendedOrDefaultBucket = isDefaultBucket || isSuspendedBucket
  const accounts = get(data, 'accounts', [])
  const currentCents = calculateSum(accounts, 'currentCents')
  const dueOneThirtyCents = calculateSum(accounts, 'dueOneThirtyCents')
  const dueThirtyOneSixtyCents = calculateSum(accounts, 'dueThirtyOneSixtyCents')
  const dueSixtyOneNinetyCents = calculateSum(accounts, 'dueSixtyOneNinetyCents')
  const dueOverNinetyCents = calculateSum(accounts, 'dueOverNinetyCents')
  const rowCount = useGridSelector(apiRef, gridRowCountSelector) - 1

  return (
    <Box px={3} pt={3} width="100%" pb={0}>
      <Grid container>
        <Grid item xs={12}>
          <Summary />
        </Grid>
        {!isSuspendedOrDefaultBucket && (
          <AgingBucketContent
            item
            mt={3}
            mb={2}
            currentCents={currentCents}
            dueOneThirtyCents={dueOneThirtyCents}
            dueThirtyOneSixtyCents={dueThirtyOneSixtyCents}
            dueSixtyOneNinetyCents={dueSixtyOneNinetyCents}
            dueOverNinetyCents={dueOverNinetyCents}
            isInteractive={false}
            showCustomerCreditBalance={false}
          />
        )}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 3 }} />
        </Grid>
        <AccountGroupStatistics />
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <CardHeader
              title={`${T.ACCOUNTS} (${rowCount})`}
              sx={{ bgcolor: 'background.header' }}
              action={<FiltersMenuButton showSuspensionTypeFilter={isSuspendedBucket} />}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
export default TopSection
