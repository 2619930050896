/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import api from 'api'
import Route from 'slices/route/routeSlice'
import Tags from 'slices/tags/tagsSlice'
import Appbar from 'slices/appbar/appbarSlice'
import bulkAssignStops from 'slices/route/bulkAssignStopsSlice'
import home from 'slices/home/homeSlice'
import lateFees from 'slices/pricing/lateFeesSlice'
import rentalFees from 'slices/pricing/rentalFeesSlice'
import customerCell from 'slices/customer/customerCellSlice'
import AuthReducer from './AuthReducer'
import GlobalSearchReducer from './GlobalSearchReducer'
import ResponseReducer from './ResponseReducer'
import CustomersReducer from './CustomersReducer'
import PricingReducer from './PricingReducer'
import WOReducer from './WOReducer'
import ConfiguredServicesReducer from './ConfiguredServicesReducer'
import RouteManagerReducer from './RouteManagerReducer'
import BillingReducer from './BillingReducer'
import PricingFiltersMetaSlice from '../../slices/meta/pricingFiltersMetaSlice'
import createInvoicesSlice from '../../slices/billing/createInvoicesSlice'
import invoices from '../../slices/billing/invoicesSlice'
import payments from '../../slices/billing/paymentsSlice'
import accountsReceivable from '../../slices/billing/accountsReceivableSlice'
import createCustomer from '../../slices/customer/createCustomerSlice'
import workOrdersReview from '../../slices/billing/workOrdersReviewSlice'
import persistentWorkOrdersReview from '../../slices/billing/persistentWorkOrdersReviewSlice'
import invoicesCreationBridge from '../../slices/billing/invoicesCreationBridgeSlice'
import CustomerDetails from '../../slices/customer/customerDetailsSlice'
import Contacts from '../../slices/customer/contactsSlice'
import CustomerSideNav from '../../slices/customer/CustomerSideNavSlice'
import BillingSideNav from '../../slices/billing/BillingSideNavSlice'
import invoiceDetailsDrawer from '../../slices/billing/invoiceDetailsDrawerSlice'
import AddRemoveInvoiceDialog from '../../slices/groups/AddRemoveInvoiceDialogSlice'
import RemovePaymentDialog from '../../slices/groups/RemovePaymentDialogSlice'
import Download from '../../slices/download/DownloadSlice'
import PaymentGroups from '../../slices/groups/PaymentGroupsSlice'
import routingActions from '../../slices/route/routingActionsSlice'
import bulkMoveBetweenRoutes from '../../slices/route/bulkMoveBetweenRoutesSlice'
import InvoicePrintableView from '../../slices/groups/InvoicePrintableViewSlice'
import bulkMoveBetweenDays from '../../slices/route/bulkMoveBetweenDaysSlice'
import bulkUnassignStops from '../../slices/route/bulkUnassignStopsSlice'
import bulkResequenceStops from '../../slices/route/bulkResequenceStopsSlice'
import bulkPermanentRecurrenceChange from '../../slices/route/bulkPermanentRecurrenceChangeSlice'
import agingBucket from '../../slices/billing/agingBucketSlice'
import suspensionGroups from '../../slices/customer/suspensionGroupsSlice'
import locations from '../../slices/locations/locationsSlice'
import accounts from '../../slices/customer/accountsSlice'
import responseSlice from '../../slices/response/responseSlice'
import pricingDrawer from '../../slices/pricing/pricingDrawerSlice'
import LoginPersist from '../../slices/login/LoginPersistSlice'

const appReducer = combineReducers({
  AddRemoveInvoiceDialog,
  Appbar,
  AuthReducer,
  BillingReducer,
  BillingSideNav,
  ConfiguredServicesReducer,
  CustomerDetails,
  Contacts,
  CustomerSideNav,
  CustomersReducer,
  Download,
  GlobalSearchReducer,
  PaymentGroups,
  PricingFiltersMetaSlice,
  PricingReducer,
  RemovePaymentDialog,
  ResponseReducer,
  Route,
  RouteManagerReducer,
  Tags,
  WOReducer,
  accountsReceivable,
  bulkAssignStops,
  createCustomer,
  createInvoicesSlice,
  invoiceDetailsDrawer,
  invoices,
  invoicesCreationBridge,
  payments,
  persistentWorkOrdersReview,
  routingActions,
  workOrdersReview,
  bulkMoveBetweenRoutes,
  bulkMoveBetweenDays,
  bulkUnassignStops,
  bulkResequenceStops,
  bulkPermanentRecurrenceChange,
  InvoicePrintableView,
  agingBucket,
  suspensionGroups,
  locations,
  accounts,
  home,
  responseSlice,
  pricingDrawer,
  LoginPersist,
  lateFees,
  rentalFees,
  customerCell,
  [api.reducerPath]: api.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
