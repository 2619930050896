import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'

const ContactMethodSkeleton = ({ showAction = false }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <ListItem sx={{ width: '100%' }}>
        <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
          <Skeleton variant="circular" width={24} height={24} />
        </ListItemIcon>
        <ListItemText
          sx={{ ml: 1, mr: 2 }}
          primary={<Skeleton variant="text" width="8rem" />}
          secondary={<Skeleton variant="text" width="8rem" />}
        />
        {showAction && (
          <IconButton edge="end" size="small">
            <Skeleton variant="rectangular" width={24} height={24} />
          </IconButton>
        )}
      </ListItem>
    </Box>
  )
}

ContactMethodSkeleton.propTypes = {
  showAction: PropTypes.bool,
}

export default ContactMethodSkeleton
