import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import AccountStatusToggleIconButton from 'components/customer-details/content/common/ReactivateAccountIconButton'
import TakePaymentIconButton from 'components/common/payments/TakePaymentIconButton'
import IconButton from '@mui/material/IconButton'
import MoreVert from '@mui/icons-material/MoreVert'
import { get } from 'utils/lodash'
import { shallowEqual, useSelector } from 'react-redux'
import AccountHeaderActionMenu from 'components/customer-details/content/account-details/header/AccountHeaderActionMenu'
import { canUpdateCustomerManager } from 'data/permissions/permissionsSelectors'

const AccountActionsToolbar = ({ accountId }) => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const [accountActionMenuAnchorEl, setAccountActionMenuAnchorEl] = useState(null)
  const isActiveAccount = get(customerDetails, 'active')
  const showAccountActionMenu = useSelector(canUpdateCustomerManager, shallowEqual)
  const handleCloseActionsMenu = () => setAccountActionMenuAnchorEl(null)
  const handleOpenActionsMenu = event => setAccountActionMenuAnchorEl(event.currentTarget)
  return (
    <>
      <Grid container alignItems="center" flexWrap="nowrap">
        {!isActiveAccount && (
          <Grid item>
            <AccountStatusToggleIconButton accountId={accountId} customerDetails={customerDetails} />
          </Grid>
        )}
        <Grid item>
          <TakePaymentIconButton accountId={accountId} />
        </Grid>
        <Grid item>
          {showAccountActionMenu && (
            <IconButton onClick={handleOpenActionsMenu}>
              <MoreVert />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <AccountHeaderActionMenu accountId={accountId} onClose={handleCloseActionsMenu} anchorEl={accountActionMenuAnchorEl} />
    </>
  )
}

AccountActionsToolbar.propTypes = {
  accountId: PropTypes.string.isRequired,
}

export default AccountActionsToolbar
