import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getDraftInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/DRAFT',
        method: 'GET',
        params,
      }),
    }),
    getCurrentInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/CURRENT',
        method: 'GET',
        params,
      }),
    }),
    getOneThirtyInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/ONE_THIRTY',
        method: 'GET',
        params,
      }),
    }),
    getThirtySixtyInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/THIRTY_SIXTY',
        method: 'GET',
        params,
      }),
    }),
    getSixtyNinetyInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/SIXTY_NINETY',
        method: 'GET',
        params,
      }),
    }),
    getOverNinetyInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/OVER_NINETY',
        method: 'GET',
        params,
      }),
    }),
    getPaidInvoiceList: builder.query({
      query: params => ({
        url: '/api/v1/billing/invoice/aging/PAID',
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useLazyGetDraftInvoiceListQuery,
  useLazyGetCurrentInvoiceListQuery,
  useLazyGetOneThirtyInvoiceListQuery,
  useLazyGetThirtySixtyInvoiceListQuery,
  useLazyGetSixtyNinetyInvoiceListQuery,
  useLazyGetOverNinetyInvoiceListQuery,
  useLazyGetPaidInvoiceListQuery,
} = extendedApi
