/* eslint-disable no-param-reassign */
import { serializeDate } from 'utils/date'
import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { BULK_MOVE_MAP_LAYERS, updateFromMarkers } from 'components/route-manager/BulkActions/settings'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS
export const getInitialBulkActionState = (mapLayer = BOTH_ROUTES_LAYER) => ({
  focus: {
    name: '',
    rowIndex: '',
  },
  invalidStopsToBeAssignedCount: 0,
  isConfirmCloseDialogOpen: false,
  isSelectDateDialogOpen: false,
  isOpen: false,
  mapLayer,
  serializedServiceDate: serializeDate(new Date()),
  moveFromRouteId: '',
  moveToRouteId: '',
  moveFromRouteStopMarkers: {},
  moveFromRows: [],
  moveToRouteSequenceLine: {},
  moveToRouteStopMarkers: {},
  moveToRows: [],
  routesById: {},
  selectedMoveFromRows: [],
  selectedMoveToRows: [],
  toBeInsertedRowsIds: [],
  validStopIdsToBeAssigned: [],
  isSequenceDialogOpen: false,
  fitBounds: true,
  confirmPermanentMoveDialog: {
    isOpen: false,
  },
  fetchId: '',
  moveFromRouteSequenceLine: {},
  serializedFromDate: serializeDate(new Date()),
  serializedToDate: serializeDate(new Date()),
  toSerializedDate: null,
  fromSerializedDate: null,
  routeId: null,
})

export const createBulkActionSlice = ({ name, initialState = getInitialBulkActionState(), reducers }) =>
  createSlice({
    name,
    initialState,
    reducers: {
      setBulkState: (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      },
      setBulkDate: (state, action) => {
        const newSerializedServiceDate = action.payload
        if (!newSerializedServiceDate) {
          state.serializedServiceDate = serializeDate(new Date())
        } else if (newSerializedServiceDate instanceof Date) {
          state.serializedServiceDate = serializeDate(newSerializedServiceDate)
        } else {
          state.serializedServiceDate = newSerializedServiceDate
        }
      },
      addSelectedMoveFromRows: (state, action) => {
        state.fitBounds = false
        state.selectedMoveFromRows = uniq([...state.selectedMoveFromRows, ...action.payload])
        state.moveFromRouteStopMarkers = updateFromMarkers(state.selectedMoveFromRows, state.routesById, state.moveFromRouteStopMarkers)
      },
      addSelectedMoveToRows: (state, action) => {
        state.fitBounds = false
        state.selectedMoveToRows = uniq([...state.selectedMoveToRows, ...action.payload])
      },
      setSelectedMoveFromRows: (state, action) => {
        state.selectedMoveFromRows = action.payload
        state.moveFromRouteStopMarkers = updateFromMarkers(state.selectedMoveFromRows, state.routesById, state.moveFromRouteStopMarkers)
      },
      setSelectedMoveToRows: (state, action) => {
        state.selectedMoveToRows = action.payload
      },
      setConfirmPermanentMoveDialogState: (state, action) => {
        state.confirmPermanentMoveDialog = {
          ...state.confirmPermanentMoveDialog,
          ...action.payload,
        }
      },
      resetBulkState: (state, action) => {
        return { ...getInitialBulkActionState(), ...action.payload }
      },
      ...reducers,
    },
  })
