import { actions } from '../actions/pricing'

const {
  PRICING_RESULT,
  TAX_RESULT,
  CONFIGURATOR_SERVICES,
  CONFIGURATOR_TAXES,
  SERVICE_CONFIG_RESULTS,
  TAX_CONFIG_RESULTS,
  ALL_CONFIG_RESULTS,
  SAVED_CONFIGURATOR_SERVICES,
  VALIDATE_PRICING_SERVICES,
  CREATE_PRICING_SERVICES,
  PRICING_FILTERS_META,
} = actions.PUT

const initialState = {
  pricingServicesResult: [],
  taxServicesResult: [],
  confServices: null,
  confRecords: null,
  validatePricingServices: null,
  savedPricingServices: null,
  pricingFiltersMeta: false,
}

const PricingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRICING_FILTERS_META:
      return { ...state, pricingFiltersMeta: action.payload }
    case PRICING_RESULT:
      return { ...state, pricingServicesResult: action.payload }
    case TAX_RESULT:
      return { ...state, taxServicesResult: action.payload }
    case VALIDATE_PRICING_SERVICES:
      return { ...state, validatePricingServices: action.payload }
    case CREATE_PRICING_SERVICES:
      return { ...state, savedPricingServices: action.payload }
    case CONFIGURATOR_SERVICES:
      return {
        ...state,
        confServices: action.payload,
      }

    case SERVICE_CONFIG_RESULTS:
      let services = []

      Object.values(action.payload).map((data, index) => {
        services.push({
          position: index,
          original_position: data.position,
          label: data.label,
          checked: data.checked,
          locked: data.locked,
          filterType: data.filterType,
          columnName: data.columnName,
          subType: data.subType,
          isSingleSelect: data.isSingleSelect,
          isBoolean: data.isBoolean,
        })
      })

      return {
        ...state,
        confServices: services,
      }

    case SAVED_CONFIGURATOR_SERVICES:
      return {
        ...state,
        confRecords: action.payload,
      }

    case ALL_CONFIG_RESULTS:
      let records = []

      Object.values(action.payload).map((data, index) => {
        records.push({
          position: index,
          original_position: data.position,
          label: data.label,
          checked: data.checked,
          locked: data.locked,
          filterType: data.filterType,
          columnName: data.columnName,
          subType: data.subType,
          isSingleSelect: data.isSingleSelect,
          isBoolean: data.isBoolean,
        })
      })

      return {
        ...state,
        confRecords: records,
      }
    default:
      return state
  }
}

export default PricingReducer
