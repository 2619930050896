import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { HHAlert } from 'components/common/HHAlert'
import { common, blueGrey } from '@mui/material/colors'
import { AlertTitle, SvgIcon, useTheme, Snackbar, Button } from '@mui/material'
import { ReactComponent as FrameIcon } from 'assets/Frame.svg'
import T from 'T'

const MapSelectionSnackbar = ({ isOpen, onClose }) => {
  const theme = useTheme()
  return (
    <Snackbar
      ClickAwayListenerProps={{ onClickAway: () => null }}
      onClose={onClose}
      sx={{ bottom: { xs: 80, sm: 80 } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
    >
      <HHAlert
        action={
          <Button variant="contained" onClick={onClose}>
            {T.DISMISS}
          </Button>
        }
        icon={
          <SvgIcon>
            <FrameIcon />
          </SvgIcon>
        }
        sx={{ bgcolor: blueGrey[700], color: common.white }}
        severity="info"
        borderColor={theme.palette.info.main}
      >
        <AlertTitle variant="h5">Map selection</AlertTitle>
        Hold shift & drag the mouse to
        <br /> multi-select in the map
      </HHAlert>
    </Snackbar>
  )
}

MapSelectionSnackbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(MapSelectionSnackbar)
