import React, { useState } from 'react'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Tab, Tabs } from '@mui/material'
import T from 'T'
import { LATE_FEE_PAID_TAB_DETAILS_ID, LATE_FEE_UNPAID_TAB_DETAILS_ID } from 'components/pricing/LateFees/LateFeesListing/settings'
import PropTypes from 'prop-types'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import LateFeesPopover from 'components/pricing/LateFees/LateFeesListing/LateFeesPopover'
import EventBlocker from 'components/common/EventBlocker'

const { PARTIAL, UNPAID, PAID } = INVOICE_STATUS
const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const LateFeeDetailPanel = ({ row }) => {
  const [selectedLateFeeDetailsTab, setSelectedLateFeeDetailsTab] = useState(LATE_FEE_UNPAID_TAB_DETAILS_ID)

  const handleChangeSelectedLateFeeDetailsTab = (event, newLateFeeDetailsTab) => {
    setSelectedLateFeeDetailsTab(newLateFeeDetailsTab)
  }

  return (
    <Stack
      sx={{
        mx: 3,
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderColor: 'divider',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        pl: '60px',
      }}
      direction="column"
    >
      <Stack direction="column">
        <Tabs value={selectedLateFeeDetailsTab} onChange={handleChangeSelectedLateFeeDetailsTab} sx={{ button: { textTransform: 'none' } }}>
          <Tab value={LATE_FEE_UNPAID_TAB_DETAILS_ID} label={T.UNPAID} />
          <Tab value={LATE_FEE_PAID_TAB_DETAILS_ID} label={T.PAID} />
        </Tabs>
        <Divider />
      </Stack>
      <List
        sx={{
          py: 0,
        }}
      >
        <ListItem divider>
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
            <InvoiceStatusChip status={UNPAID} agingBucketType={DUE_SIXTY_ONE_NINETY} />
          </ListItemIcon>
          <ListItemText
            primary="January 2024 | $200"
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold',
              color: 'textPrimary',
            }}
            secondary="221203001008 | 01/01/2023"
            secondaryTypographyProps={{
              variant: 'body1',
              color: 'textSecondary',
            }}
          />
          <EventBlocker>
            <LateFeesPopover />
          </EventBlocker>
        </ListItem>
        <ListItem divider>
          <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
            <InvoiceStatusChip status={UNPAID} agingBucketType={DUE_SIXTY_ONE_NINETY} />
          </ListItemIcon>
          <ListItemText
            primary="January 2024 | $200"
            primaryTypographyProps={{
              variant: 'body1',
              fontWeight: 'bold',
              color: 'textPrimary',
            }}
            secondary="221203001008 | 01/01/2023"
            secondaryTypographyProps={{
              variant: 'body1',
              color: 'textSecondary',
            }}
          />
          <EventBlocker>
            <LateFeesPopover />
          </EventBlocker>
        </ListItem>
      </List>
    </Stack>
  )
}

LateFeeDetailPanel.propTypes = {
  row: PropTypes.object,
}

export default LateFeeDetailPanel
