import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton'
import { styled } from '@mui/material'

const CommonToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&': {
    padding: '4px 18px',
    textTransform: 'none',
  },
  '&:hover': {
    background: 'linear-gradient(180deg, #cbdeeb 0%, #d5e3f1 100%)',
    boxShadow: '0px 1px 2px rgb(4 32 64 / 60%)',
  },
  [`&.${toggleButtonClasses.selected}`]: {
    background: 'linear-gradient(180deg, #0073E6 0%, #0068CF 100%)',
    boxShadow: '0px 1px 2px rgba(4, 32, 64, 0.6)',
    borderRadius: 4,
    color: `${theme.palette.common.white}!important`,
    fontWeight: 600,
    '&:hover': {
      background: 'linear-gradient(180deg, #0073E6 0%, #0068CF 100%)',
      boxShadow: '0px 1px 2px rgba(4, 32, 64, 0.6)',
    },
  },
}))

export default CommonToggleButton
