import React, { useMemo } from 'react'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import ToBeMovedStopPreviewCell from 'components/route-manager/BulkActions/common/PermanentMoveStopListCell/ToBeMovedStopPreviewCell'
import WorkOrderPreviewCell from 'components/route-manager/BulkActions/common/PermanentMoveStopListCell/WorkOrderPreviewCell'
import PropTypes from 'prop-types'
import { TO_BE_MOVED_TYPE } from 'components/route-manager/BulkActions/BulkAssignStopsDialog/ConfirmPermanentMoveDialog/settings'

const PermanentMoveStopListCell = props => {
  const { row, showOriginalPosition = false } = props
  const { watch } = useFormContext()
  const addressStr = useMemo(() => get(row, 'addressStr'), [row])
  const accountName = useMemo(() => get(row, 'accountName'), [row])
  const id = useMemo(() => get(row, 'id'), [row])
  const position = watch(id)
  const type = useMemo(() => get(row, 'type'), [row])
  const workOrder = useMemo(() => get(row, 'workOrder'), [row])
  const serviceDate = useMemo(() => get(row, 'serviceDate'), [row])
  const originalPosition = useMemo(() => get(row, 'originalPosition', 0) + 1, [row])
  if (type === TO_BE_MOVED_TYPE) {
    return (
      <ToBeMovedStopPreviewCell
        position={position}
        showOriginalPosition={showOriginalPosition}
        originalPosition={originalPosition}
        accountName={accountName}
        addressStr={addressStr}
      />
    )
  }
  return <WorkOrderPreviewCell workOrder={workOrder} serviceDate={serviceDate} />
}

PermanentMoveStopListCell.propTypes = {
  row: PropTypes.object,
  showOriginalPosition: PropTypes.bool,
}

export default PermanentMoveStopListCell
