import { common, orange } from '@mui/material/colors'
import {
  ROUTE_COLOR_STOPS_DOT_LAYER,
  ROUTE_COLOR_STOPS_LAYER,
  WO_COLOR_STOPS_DOT_LAYER,
  WO_COLOR_STOPS_LAYER,
} from 'slices/route/routeSlice'

export const WO_COLOR_STOPS_DOT_PAINT = {
  'circle-radius': {
    base: 6,
    stops: [
      [12, 6],
      [22, 180],
    ],
  },
  'circle-color': ['get', 'work-order-color'],
  'circle-stroke-width': 2,
  'circle-stroke-color': ['get', 'text-color'],
}

export const WO_COLOR_STOPS_LAYOUT = {
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-image': ['get', 'icon'],
  'text-field': ['get', 'position'],
  'text-anchor': 'top',
  'icon-offset': [0, -50],
  'text-offset': [0, -3.45],
  'icon-size': 0.55,
  'text-size': 12,
}

export const UNASSIGNED_ROUTE_STOPS_LAYOUT = {
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-image': 'to-be-assigned-marker',
  'text-anchor': 'top',
  'icon-offset': [0, -50],
  'text-offset': [0, -3.45],
  'icon-size': 0.55,
  'text-size': 12,
}

export const UNASSIGNED_ROUTE_STOPS_PAINT = {
  'circle-radius': {
    base: 6,
    stops: [
      [12, 6],
      [22, 180],
    ],
  },
  'circle-color': common.white,
  'circle-stroke-width': 2,
  'circle-stroke-color': orange[300],
}

export const BREADCRUMB_ARROW_TIP_LAYOUT = {
  'symbol-placement': `line`,
  'symbol-spacing': 2.5,
  'icon-allow-overlap': true,
  'icon-rotate': 90,
  'icon-image': 'arrow-tip',
  'icon-size': 0.35,
  visibility: 'visible',
}

export const ROUTE_COLOR_STOPS_LAYOUT = {
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-image': 'monochrome-marker',
  'text-field': ['get', 'position'],
  'text-anchor': 'top',
  'icon-offset': [0, -50],
  'text-offset': [0, -3.45],
  'icon-size': 0.55,
  'text-size': 12,
}

export const ROUTE_COLOR_STOPS_DOT_PAINT = {
  'circle-radius': {
    base: 6,
    stops: [
      [12, 6],
      [22, 180],
    ],
  },
  'circle-color': ['get', 'color'],
  'circle-stroke-width': 2,
  'circle-stroke-color': ['get', 'route-text-color'],
}

const DOT_ZOOM_UPPER_LIMIT = 14

export const ZOOM_DOT_LAYER_PROPS = {
  minzoom: 0,
  maxzoom: DOT_ZOOM_UPPER_LIMIT,
}

export const ZOOM_MARKER_LAYER_PROPS = {
  minzoom: DOT_ZOOM_UPPER_LIMIT + 0.01,
}

export const addRouteIdSuffix = (str, routeId) => `${str}-${routeId}`

export const toggleRouteLayers = (mapCurrentRef, currentLayer, routeId) => {
  if (currentLayer === ROUTE_COLOR_STOPS_LAYER) {
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), 'visibility', 'none')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), 'visibility', 'none')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), 'visibility', 'visible')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), 'visibility', 'visible')
  } else if (currentLayer === WO_COLOR_STOPS_LAYER) {
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(WO_COLOR_STOPS_LAYER, routeId), 'visibility', 'visible')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(WO_COLOR_STOPS_DOT_LAYER, routeId), 'visibility', 'visible')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(ROUTE_COLOR_STOPS_LAYER, routeId), 'visibility', 'none')
    mapCurrentRef.setLayoutProperty(addRouteIdSuffix(ROUTE_COLOR_STOPS_DOT_LAYER, routeId), 'visibility', 'none')
  }
}
