export const FORM_MODEL = {
  searchBusinessLineInput: '',
  searchMethodInput: '',
  searchTagsInput: '',
  selectedBusinessLines: [],
  selectedMethods: [],
  selectedTags: [],
  selectedRoutesByTagId: null,
  allTags: [],
  isFiltersLoading: false,
}

export const UNASSIGNED_ROUTE = {
  id: 'unassigned',
  name: 'Unassigned',
}
