import React from 'react'
import PropTypes from 'prop-types'
import { Popover, List, Stack, Grid, Typography, CircularProgress } from '@mui/material'
import { getBucketColorScheme } from 'components/common/aging-bucket/settings'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import PreviewListItem from 'components/common/aging-bucket/InteractiveAgingBucket/PreviewListItem'
import { get } from 'lodash'
import { HHAlert } from 'components/common/HHAlert'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

const AGING_BUCKET_LABELS = {
  [CURRENT]: 'Current',
  [DUE_ONE_THIRTY]: '1-30',
  [DUE_THIRTY_ONE_SIXTY]: '31-60',
  [DUE_SIXTY_ONE_NINETY]: '61-90',
  [DUE_OVER_NINETY]: '90+',
}

const AgingBucketPreviewPopover = ({ hasInvoices, loading, invoices, invoiceCount, anchorEl, onClose, agingBucketType }) => {
  const colorSchema = getBucketColorScheme(agingBucketType)
  const label = get(AGING_BUCKET_LABELS, agingBucketType, '')

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -8,
        horizontal: 'left',
      }}
      onClose={onClose}
      disableRestoreFocus
      slotProps={{
        paper: {
          variant: 'outlined',
          sx: {
            minWidth: 300,
            maxWidth: 450,
          },
        },
      }}
    >
      {loading && (
        <Stack sx={{ p: 1 }} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {hasInvoices && !loading && (
        <>
          <List disablePadding>
            <PreviewListItem color={colorSchema[100]} primaryLeft={label} primaryRight={invoiceCount} />
            {invoices.map(invoice => {
              const invoiceNumber = get(invoice, 'invoiceNumber')
              const accountName = get(invoice, 'accountName')
              const accountNumber = get(invoice, 'accountNumber')
              const totalCents = get(invoice, 'totalCents', 0)
              const dueDateStr = get(invoice, 'dueDate')
              const invoiceDateStr = get(invoice, 'invoiceDate')
              const dueDate = formatDateToFEFormatDateFns(createDateFromString(dueDateStr))
              const invoiceDate = formatDateToFEFormatDateFns(createDateFromString(invoiceDateStr))

              return (
                <PreviewListItem
                  primaryLeft={invoiceNumber}
                  primaryRight={<HHDisplayMoney value={totalCents} formatToDollars />}
                  withVerticalDivider
                  secondaryLeft={
                    <Stack>
                      <Typography noWrap>{accountName}</Typography>
                      <Typography noWrap>{accountNumber}</Typography>
                    </Stack>
                  }
                  secondaryRight={
                    <Grid container justifyContent="flex-end">
                      <Grid container item columnSpacing={0.5} justifyContent="flex-end">
                        <Grid item>
                          <Typography textAlign="left">Date:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography textAlign="right">{invoiceDate}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item columnSpacing={0.5} justifyContent="flex-end">
                        <Grid item>
                          <Typography textAlign="left">Due:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography textAlign="right">{dueDate}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
              )
            })}
            {invoiceCount > 5 && <PreviewListItem secondaryRight={`${invoiceCount - 5}+ more`} />}
          </List>
        </>
      )}
      {!hasInvoices && !loading && <HHAlert severity="info">No invoices for this customer</HHAlert>}
    </Popover>
  )
}

AgingBucketPreviewPopover.propTypes = {
  anchorEl: PropTypes.element,
  onClose: PropTypes.func,
  agingBucketType: PropTypes.string,
  hasInvoices: PropTypes.bool,
  loading: PropTypes.bool,
  invoices: PropTypes.array,
  invoiceCount: PropTypes.number,
}

export default AgingBucketPreviewPopover
