/* eslint-disable */
export default class EventBus {
  static BACKEND_CALL_STARTED = 'BACKEND_CALL_STARTED'
  static BACKEND_CALL_ENDED = 'BACKEND_CALL_ENDED'
  static SHOW_MESSAGE = 'SHOW_MESSAGE'

  static LISTENERS = {}

  static addListener(event, callback) {
    var collection = this.LISTENERS[event]

    if (!collection) {
      collection = []
      this.LISTENERS[event] = collection
    }

    collection.push({ callback })
  }

  static notifyListeners(event, data) {
    var collection = this.LISTENERS[event]

    if (!collection) return

    for (var i = 0; i < collection.length; i++) {
      collection[0].callback(data)
    }
  }
}
