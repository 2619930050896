import React, { memo } from 'react'
import { ReactComponent as ExpandAll } from 'assets/ExpandAll.svg'
import { Stack, SvgIcon } from '@mui/material'
import T from 'T'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'

const PermanentMoveToolbar = ({ toggleExpand, expanded, isLoadingExpanded }) => {
  return (
    <Stack sx={{ my: 2 }} direction="row" alignItems="center" justifyContent="flex-end">
      <LoadingButton
        loading={isLoadingExpanded}
        onClick={toggleExpand}
        startIcon={
          !isLoadingExpanded && (
            <SvgIcon>
              <ExpandAll />
            </SvgIcon>
          )
        }
      >
        {expanded ? T.COLLAPSE_ALL : T.EXPAND_ALL}
      </LoadingButton>
    </Stack>
  )
}

PermanentMoveToolbar.propTypes = {
  toggleExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  isLoadingExpanded: PropTypes.bool.isRequired,
}

export default memo(PermanentMoveToolbar)
