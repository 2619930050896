import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getPricingMetaFilters: builder.query({
      query: () => ({ url: '/api/pricing/meta' }),
    }),
  }),
})

export const { useLazyGetPricingMetaFiltersQuery } = extendedApi
