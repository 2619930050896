import React from 'react'
import { ReactComponent as CalendarPlus } from 'assets/route_manager/actions_menu/CalendarPlus.svg'
import SelectItem from 'components/form-fields/v5/SelectItem'
import { Avatar, ListItem, ListItemAvatar, ListItemText, SvgIcon, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { selectBulkAssignFromRows } from 'slices/route/bulkAssignStopsSlice'
import T from 'T'

const UnassignedPicker = () => {
  const theme = useTheme()
  const moveFromRows = useSelector(selectBulkAssignFromRows)
  const stopsCount = get(moveFromRows, 'length', 0)
  return (
    <HHTextField
      sx={{
        '& .MuiOutlinedInput-root .MuiListItemAvatar-root': {
          minWidth: '30px',
          position: 'relative',
          left: '-8px',
        },
      }}
      value="unassigned"
      readOnly
      fullWidth
      select
      variant="outlined"
      displayEmpty
      placeholder="Select a route"
      SelectProps={{
        MenuProps: {
          sx: { zIndex: theme.zIndex.snackbar },
          PaperProps: {
            sx: { maxHeight: 'calc(100vh - 80px)' },
          },
          alignment: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          },
        },
      }}
    >
      <SelectItem disableGutters value="unassigned">
        <ListItem disabbleGutters disablePadding>
          <ListItemAvatar sx={{ minWidth: '40px' }}>
            <Avatar
              sx={{
                width: '32px',
                height: '32px',
                bgcolor: grey[200],
                border: `1px solid ${theme.palette.divider}`,
              }}
            >
              <SvgIcon fontSize="small" inheritViewBox viewBox="0 0 32 32">
                <CalendarPlus />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={T.UNASSIGNED} secondary={`${stopsCount} stops`} />
        </ListItem>
      </SelectItem>
    </HHTextField>
  )
}

UnassignedPicker.propTypes = {}

export default UnassignedPicker
