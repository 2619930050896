export const actions = {
  PUT: {
    TOKEN: 'AUTH_PUT_TOKEN',
    USER_DETAILS: 'AUTH_PUT_USER_DETAILS',
    IS_USER_AUTHENTICATED: 'AUTH_PUT_IS_USER_AUTHENTICATED',
  },
}

export const putToken = token => ({
  type: actions.PUT.TOKEN,
  payload: token,
})

export const putUserDetails = res => ({
  type: actions.PUT.USER_DETAILS,
  payload: res,
})

export const putIsUserAuthenticated = status => ({
  type: actions.PUT.IS_USER_AUTHENTICATED,
  payload: status,
})
