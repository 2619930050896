import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useTheme from '@mui/material/styles/useTheme'
import { FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

const BulkDialogAppbar = ({ onClose, serviceDate, showDate, title }) => {
  const theme = useTheme()
  return (
    <AppBar color="inherit" sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Grid container>
          <Grid container item alignItems="center" justifyContent="space-between" flexWrap="nowrap" xs={12}>
            <Grid item xs>
              <Typography variant="h4" fontWeight={600} sx={{ ml: theme.spacing(2) }}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs container justifyContent="flex-end" />
          </Grid>
          <Grid item xs={12}>
            {showDate && (
              <Box position="relative" top={0}>
                <Box position="absolute" left="50%" sx={{ transform: 'translate(-50%, 0)', marginLeft: '-66px' }}>
                  <HHDesktopDatePicker
                    format={FRONTEND_DATE_FORMAT_DATE_FNS}
                    value={serviceDate}
                    readOnly
                    restSlots={{ openPickerIcon: () => <Box /> }}
                    TextFieldProps={{
                      sx: {
                        '.MuiInputBase-root': {
                          width: '8rem',
                          borderRadius: '50px',
                          input: { textAlign: 'center' },
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

BulkDialogAppbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  serviceDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  showDate: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(BulkDialogAppbar)
