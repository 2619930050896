import Constants from 'Constants'

const { SERVER_URL } = Constants

export const API_PREFIX = '/api'
const CDN_PREFIX = `${SERVER_URL}/cdn`
const PUBLIC_API = '/public'
const VERSION_1 = 'v1'

const endpoint = {
  user: {
    info: `${API_PREFIX}/user/info`,
    profilePic: `${CDN_PREFIX}/user/pp`,
    profilePicUri: `${CDN_PREFIX}/user/pp?uri=`,
    search: `${API_PREFIX}/user/search`,
    passwordInitiate: `${API_PREFIX}/user/password/initiate`,
    passwordChange: `${API_PREFIX}/user/password/change`,
  },
  account: {
    search: `${API_PREFIX}/account/search`,
    recent: `${API_PREFIX}/account/recent`,
    suggest: `${API_PREFIX}/account/suggest`,
    filter: `${API_PREFIX}/account/filter`,
    view: `${API_PREFIX}/v1/core/accounts`,
    add: `${API_PREFIX}/v1/core/accounts`,
    update: `${API_PREFIX}/account/update`,
    contact: `${API_PREFIX}/account/contact`,
    deactivateContact: `${API_PREFIX}/account/contact/activate`,
    call: `${API_PREFIX}/account/call`,
    followUp: `${API_PREFIX}/account/follow-up`,
    callById: `${API_PREFIX}/account/call/view`,
    addCall: `${API_PREFIX}/account/call/add`,
    followUpById: `${API_PREFIX}/account/follow-up/view`,
    addFollowUpNote: `${API_PREFIX}/account/follow-up/note/add`,
  },
  invoice: {
    list: `${API_PREFIX}/invoice/fee/list`,
    update: `${API_PREFIX}/invoice/fee/update`,
  },
  billing: {
    accountReceivable: {
      list: `${API_PREFIX}/billing/account/receivable/list`,
      revenue: `${API_PREFIX}/v1/billing/account/receivables/revenue`,
      filterList: `${API_PREFIX}/billing/account/receivable/filter/list`,
      filterSave: `${API_PREFIX}/billing/account/receivable/filter/save`,
      filterDelete: `${API_PREFIX}/billing/account/receivable/filter/delete`,
      initiateExport: `${API_PREFIX}/billing/account/receivable/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/account/receivable/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/account/receivable/export/download`,
      initiateDownload: `${API_PREFIX}/billing/account/receivable/initiate/download`,
      downloadStatus: `${API_PREFIX}/billing/account/receivable/download/status`,
      downloadAccountReceivable: `${API_PREFIX}/billing/account/receivable/download`,
      customerStandards: `${API_PREFIX}/billing/accounts/receivables/summary/items`,
      customerLegacy: `${API_PREFIX}/legacy/invoices`,
    },
    invoice: {
      list: `${API_PREFIX}/billing/invoice/list`,
      revenue: `${API_PREFIX}/billing/invoice/revenue`,
      meta: `${API_PREFIX}/billing/invoice/meta`,
      send: `${API_PREFIX}/billing/invoice/send`,
      post: `${API_PREFIX}/billing/invoice/post`,
      filterList: `${API_PREFIX}/billing/invoice/filter/list`,
      filterSave: `${API_PREFIX}/billing/invoice/filter/save`,
      filterDelete: `${API_PREFIX}/billing/invoice/filter/delete`,
      initiateDownload: `${API_PREFIX}/billing/invoice/initiate/download`,
      downloadStatus: `${API_PREFIX}/billing/invoice/download/status`,
      downloadInvoice: `${API_PREFIX}/billing/invoice/download`,
      initiateExport: `${API_PREFIX}/billing/invoice/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/invoice/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/invoice/export/download`,
    },
    sentInvoice: {
      list: `${API_PREFIX}/billing/invoice/sent/list`,
      download: `${API_PREFIX}/billing/invoice/sent/download`,
      filterList: `${API_PREFIX}/billing/sent/invoices/filter/list`,
      filterSave: `${API_PREFIX}/billing/sent/invoices/filter/save`,
      filterDelete: `${API_PREFIX}/billing/sent/invoices/filter/delete`,
      initiateExport: `${API_PREFIX}/billing/sent/invoices/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/sent/invoices/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/sent/invoices/export/download`,
      initiateBatchExport: `${API_PREFIX}/billing/sent/invoices/batch/export/initiate/download`,
      batchExportStatus: `${API_PREFIX}/billing/sent/invoices/batch/export/check/status`,
      batchExportDownload: `${API_PREFIX}/billing/sent/invoices/batch/export/download`,
    },
    payment: {
      list: `${API_PREFIX}/billing/payment/list`,
      revenue: `${API_PREFIX}/v1/billing/payment/revenue`,
      filterList: `${API_PREFIX}/billing/payment/filter/list`,
      filterSave: `${API_PREFIX}/billing/payment/filter/save`,
      filterDelete: `${API_PREFIX}/billing/payment/filter/delete`,
      initiateExport: `${API_PREFIX}/billing/payment/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/payment/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/payment/export/download`,
      apply: `${API_PREFIX}/billing/payment/apply`,
      post: `${API_PREFIX}/billing/payment/post`,
      initiateReceiptDownload: `${API_PREFIX}/billing/payment/receipt/initiate/download`,
      receiptDownloadStatus: `${API_PREFIX}/billing/payment/receipt/download/status`,
      receiptDownload: `${API_PREFIX}/billing/payment/receipt/download`,
      sendReceipt: `${API_PREFIX}/billing/payment/receipt/send/email`,
    },
    createInvoice: {
      list: '/api/billing/invoice/customer/list',
      filterList: '/api/billing/invoice/customer/filter/list',
      filterSave: '/api/billing/invoice/customer/filter/save',
      filterDelete: '/api/billing/invoice/customer/filter/delete',
    },
  },
  contact: {
    filter: `${API_PREFIX}/contact/filter`,
    suggest: `${API_PREFIX}/contact/suggest`,
  },
  followUp: {
    noteImage: `${CDN_PREFIX}/follow-up/note/image?uri=`,
    filter: `${API_PREFIX}/follow-up/filter`,
    suggest: `${API_PREFIX}/follow-up/suggest`,
  },
  workOrder: {
    get: `${API_PREFIX}/workorder`,
    details: `${API_PREFIX}/workorder/details`,
    filter: `${API_PREFIX}/workorder/filter`,
    suggest: `${API_PREFIX}/workorder/suggest`,
    image: `${CDN_PREFIX}/workorder/image?uri=`,
    signature: `${CDN_PREFIX}/workorder/signature?uri=`,
    updateInvoice: `${API_PREFIX}/workorder/invoice/update `,
    updateNote: `${API_PREFIX}/workorder/note/update `,
    updateExceptionNote: `${API_PREFIX}/workorder/exception/note/update `,
    updateTag: `${API_PREFIX}/route/workorder/tag/update `,
    initiateDownload: `${API_PREFIX}/v1/core/work-orders/pdf/export`,
    downloadStatus: `${API_PREFIX}/v1/core/work-order/pdf/export/check/status`,
    downloadWorkOrder: `${API_PREFIX}/v1/core/work-order/pdf/download`,
  },
  configuredService: {
    filter: `${API_PREFIX}/configured-service/filter`,
    suggest: `${API_PREFIX}/configured-service/suggest`,
    search: `${API_PREFIX}/configured-service/search`,
    detail: `${API_PREFIX}/configured-service/detail`,
    saveUpdate: `${API_PREFIX}/configured-service/saveUpdate`,
    saveUpdateBulk: `${API_PREFIX}/configured-service/saveUpdateBulk`,
    pricingServiceSearch: `${API_PREFIX}/configured-service/pricing-service/search`,
    pricingServiceDetail: `${API_PREFIX}/configured-service/pricing-service/detail`,
    saveUpdateEvent: `${API_PREFIX}/configured-service/createEvent`,
    deleteEvent: `${API_PREFIX}/configured-service/deleteEvent`,
    updateRouteNote: `${API_PREFIX}/configured-service/updateRouteNote`,
    events: `${API_PREFIX}/configured-service/events`,
  },
  location: {
    find: `${API_PREFIX}/location/view`,
    activateDeactivate: `${API_PREFIX}/location/activate`,
    insertUpdate: `${API_PREFIX}/location`,
  },
  global: {
    search: `${API_PREFIX}/global/search`,
  },
  meta: {
    filters: `${API_PREFIX}/meta/filters`,
    account: `${API_PREFIX}/meta/account`,
    route: `${API_PREFIX}/meta/route`,
  },
  notes: {
    get: `${API_PREFIX}/account/note`,
    addEdit: `${API_PREFIX}/note/save`,
    pin: `${API_PREFIX}/note/pin`,
    archive: `${API_PREFIX}/note/archive`,
    filter: `${API_PREFIX}/note/filter`,
    suggest: `${API_PREFIX}/note/suggest`,
  },
  pricing: {
    meta: `${API_PREFIX}/pricing/meta`,
    fee: {
      general: {
        index: `${API_PREFIX}/pricing/fee/general`,
        summary: `${API_PREFIX}/pricing/fee/general/summary`,
        validate: `${API_PREFIX}/pricing/fee/general/validate`,
        reValidate: `${API_PREFIX}/v1/pricing/general-fee/exists`,
        add: `${API_PREFIX}/pricing/fee/general/add`,
        matched: `${API_PREFIX}/pricing/fee/general/matched`,
        update: `${API_PREFIX}/pricing/fee/general/update`,
        revenue: `${API_PREFIX}/pricing/fee/general/revenue`,
        initiateExport: `${API_PREFIX}/billing/pricing/general/fee/export/initiate/download`,
        exportStatus: `${API_PREFIX}/billing/pricing/general/fee/export/check/status`,
        exportDownload: `${API_PREFIX}/billing/pricing/general/fee/export/download`,
      },
      disposal: {
        index: `${API_PREFIX}/pricing/fee/disposal`,
        summary: `${API_PREFIX}/pricing/fee/disposal/summary`,
        validate: `${API_PREFIX}/pricing/fee/disposal/validate`,
        reValidate: `${API_PREFIX}/v1/pricing/disposal-fee/exists`,
        add: `${API_PREFIX}/pricing/fee/disposal/add`,
        update: `${API_PREFIX}/pricing/fee/disposal/update`,
        revenue: `${API_PREFIX}/pricing/fee/disposal/revenue`,
        initiateExport: `${API_PREFIX}/billing/pricing/disposal/fee/export/initiate/download`,
        exportStatus: `${API_PREFIX}/billing/pricing/disposal/fee/export/check/status`,
        exportDownload: `${API_PREFIX}/billing/pricing/disposal/fee/export/download`,
      },
    },
    general: {
      linked: {
        list: `${API_PREFIX}/pricing/general/fee/linked/list`,
      },
    },
    disposal: {
      linked: {
        list: `${API_PREFIX}/pricing/disposal/fee/linked/list`,
      },
    },
    additional: {
      column: `${API_PREFIX}/pricing/additional/column`,
    },
    service: {
      index: `${API_PREFIX}/pricing/service`,
      validate: `${API_PREFIX}/pricing/service/validate`,
      reValidate: `${API_PREFIX}/v1/pricing/service/exists`,
      add: `${API_PREFIX}/pricing/service/add`,
      summary: `${API_PREFIX}/pricing/service/summary`,
      filter: `${API_PREFIX}/priced-service/filter`,
      suggest: `${API_PREFIX}/priced-service/suggest`,
      update: `${API_PREFIX}/pricing/service/update`,
      revenue: `${API_PREFIX}/pricing/service/revenue`,
      status: {
        change: `${API_PREFIX}/pricing/service/status/change`,
      },
      linked: {
        list: `${API_PREFIX}/pricing/service/linked/list`,
      },
      link: {
        service: {
          update: `${API_PREFIX}/pricing/service/link/service/update`,
        },
        tax: {
          update: `${API_PREFIX}/pricing/service/link/tax/update`,
        },
        general: {
          update: `${API_PREFIX}/pricing/service/link/general/update`,
        },
        disposal: {
          update: `${API_PREFIX}/pricing/service/link/disposal/update`,
        },
      },
      initiateExport: `${API_PREFIX}/billing/pricing/service/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/pricing/service/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/pricing/service/export/download`,
    },
    tax: {
      index: `${API_PREFIX}/pricing/tax`,
      validate: `${API_PREFIX}/pricing/tax/validate`,
      reValidate: `${API_PREFIX}/v1/pricing/tax/exists`,
      add: `${API_PREFIX}/pricing/tax/add`,
      summary: `${API_PREFIX}/pricing/tax/summary`,
      update: `${API_PREFIX}/pricing/tax/update`,
      linked: {
        list: `${API_PREFIX}/pricing/tax/linked/list`,
      },
      matched: `${API_PREFIX}/pricing/tax/matched`,
      initiateExport: `${API_PREFIX}/billing/pricing/tax/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/pricing/tax/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/pricing/tax/export/download`,
    },
  },
  export: {
    initiate: {
      download: `${API_PREFIX}/export/initiate/download`,
    },
    check: {
      status: `${API_PREFIX}/export/check/status`,
    },
    download: `${API_PREFIX}/export/download`,
  },
  map: {
    suggest: `${API_PREFIX}/map/suggest`,
    image: `${API_PREFIX}/map/image`,
  },
  settings: {
    profile: {
      list: `${API_PREFIX}/settings/profile/list`,
      add: `${API_PREFIX}/settings/profile/add`,
      info: `${API_PREFIX}/settings/profile/info`,
    },
    skill: {
      list: `${API_PREFIX}/settings/skill/list`,
      add: `${API_PREFIX}/settings/skill/add`,
    },
    tax: {
      zone: {
        list: `${API_PREFIX}/settings/tax/zone/list`,
        add: `${API_PREFIX}/settings/tax/zone/add`,
      },
    },
    tag: {
      list: `${API_PREFIX}/settings/tag/list`,
      add: `${API_PREFIX}/settings/tag/add`,
    },
    yard: {
      list: `${API_PREFIX}/settings/yard/list`,
      add: `${API_PREFIX}/settings/yard/add`,
      info: `${API_PREFIX}/settings/yard/info`,
    },
    customerBillingProfile: {
      list: `${API_PREFIX}/settings/customer/billing/profile/list`,
      add: `${API_PREFIX}/settings/customer/billing/profile/add`,
      info: `${API_PREFIX}/settings/customer/billing/profile/info`,
      default: `${API_PREFIX}/settings/customer/billing/profile/default`,
    },
  },
  routeManager: {
    routeDetail: `${API_PREFIX}/route`,
    stopsList: `${API_PREFIX}/route/workorders`,
    addRoute: `${API_PREFIX}/route/add`,
    updateSequence: `${API_PREFIX}/route/workorder/update`,
    moveStopInterRoute: `${API_PREFIX}/route/workorder/move`,
    unassignedWorkOrder: `${API_PREFIX}/route/unassigned/workorders`,
    assignWorkOrder: `${API_PREFIX}/route/workorder/assign`,
    getRouteDeleated: `${API_PREFIX}/route/delete`,
    moveRoute: `${API_PREFIX}/route/move`,
    getSyncToken: `${API_PREFIX}/notification/initiate/subscribe`,
    getRouteUpdates: `${PUBLIC_API}/notification/subscribe`,
    unassignWorkOrders: `${API_PREFIX}/route/workorder/unassign`,
  },
  public: {
    forgotPassword: `${API_PREFIX}/crm/reset-password`,
    initiateCompleteResetPwd: `${API_PREFIX}/initiate-complete-reset-password`,
    passwordReset: `${API_PREFIX}/complete-reset-password`,
    passwordResetSendEmail: `${API_PREFIX}/reset-password-send-email`,
  },
  report: {
    list: `${API_PREFIX}/report/data/list`,
    filterList: `${API_PREFIX}/report/data/filter/list`,
    filterSave: `${API_PREFIX}/report/data/filter/save`,
    filterDelete: `${API_PREFIX}/report/data/filter/delete`,
    save: `${API_PREFIX}/report/new/save`,
    newReportList: `${API_PREFIX}/v1/report/reports`,
    accountReceivables: {
      initiateExport: `${API_PREFIX}/billing/account/receivable/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/account/receivable/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/account/receivable/export/download`,
    },
    invoices: {
      initiateExport: `${API_PREFIX}/billing/invoice/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/invoice/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/invoice/export/download`,
    },
    sentInvoices: {
      initiateExport: `${API_PREFIX}/billing/sent/invoices/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/sent/invoices/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/sent/invoices/export/download`,
    },
    payments: {
      initiateExport: `${API_PREFIX}/billing/payment/export/initiate/download`,
      exportStatus: `${API_PREFIX}/billing/payment/export/check/status`,
      exportDownload: `${API_PREFIX}/billing/payment/export/download`,
    },
    revenue: {
      initiateExport: `${API_PREFIX}/v1/core/revenue/report/export`,
      exportStatus: `${API_PREFIX}/v1/core/revenue/report/export/check/status`,
      exportDownload: `${API_PREFIX}/v1/core/revenue/report/download`,
    },
    service: {
      initiateExport: `${API_PREFIX}/v1/core/configured-service/report/export`,
      exportStatus: `${API_PREFIX}/v1/core/configured-service/report/export/check/status`,
      exportDownload: `${API_PREFIX}/v1/core/configured-service/download`,
    },
    workOrder: {
      initiateExport: `${API_PREFIX}/v1/core/work-order/report/export`,
      exportStatus: `${API_PREFIX}/v1/core/work-order/report/export/check/status`,
      exportDownload: `${API_PREFIX}/v1/core/work-order/report/download`,
    },
  },
  serviceGroups: {
    list: `${API_PREFIX}/${VERSION_1}/core/priced-service-groups/list/aggregate`,
    details: `${API_PREFIX}/${VERSION_1}/core/priced-service-groups`,
    search: `${API_PREFIX}/${VERSION_1}/core/priced-service-groups/search`,
  },
}

export default endpoint
