import React from 'react'
import Reports from 'containers/reports'

const ReportsPage = () => (
  <section className="reports-page">
    <Reports />
  </section>
)

export default ReportsPage
