import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import DialogContent from '@mui/material/DialogContent'
import { Button, Divider, List, ListItem, ListItemText } from '@mui/material'
import { capitalize } from 'lodash'
import { get } from 'utils/lodash'
import { getCustomerDetails } from 'middleware/actions/customers'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import LocationView from 'components/customer-details/content/location/list/LocationView'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import HHBaseDialog from 'components/common/HHBaseDialog'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveDeactivatedLocations } from 'utils/location'
import { useActivateDeactivateLocationsMutation } from 'api/location/activateDeactivateLocations'
import noop from 'lodash/noop'

const ReactivateLocationsDialog = ({ accountId, open = false, onClose = noop }) => {
  const dispatch = useDispatch()
  const [activateDeactivateLocations, { isLoading }] = useActivateDeactivateLocationsMutation()
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const { deactivatedLocations } = getActiveDeactivatedLocations(customerDetails, true)
  const deactivatedLocationCount = get(deactivatedLocations, 'length', 0)
  const hasDeactivatedLocations = deactivatedLocationCount !== 0

  const handleReactivateAllLocations = useCallback(async () => {
    const allPromises = deactivatedLocations.map(location => {
      const locationId = get(location, 'id')

      const payload = {
        deactivate: false,
        locations: [{ id: locationId }],
      }

      return activateDeactivateLocations(payload).unwrap()
    })
    try {
      await Promise.all(allPromises)
      dispatch(getCustomerDetails({ accountId }))
      toast.success(T.MULTIPLE_LOCATION_ACTIVATED_MSG)
    } catch (error) {
      handleError(error)
    }
  }, [deactivatedLocations])

  return (
    <HHBaseDialog open={open} onClose={onClose} maxWidth="md" fullWidth disableScrollLock>
      <HHDialogTitle title={T.REACTIVATE_LOCATIONS} onClose={onClose} />
      <DialogContent sx={{ ...(hasDeactivatedLocations && { px: 0 }) }}>
        <ListItem
          sx={{ ...(hasDeactivatedLocations && { pl: '50px' }) }}
          secondaryAction={
            hasDeactivatedLocations && (
              <Button size="small" variant="contained" color="success" onClick={handleReactivateAllLocations}>
                {capitalize(`${T.REACTIVATE} all`)}
              </Button>
            )
          }
        >
          <ListItemText primary={`${deactivatedLocationCount} locations`} primaryTypographyProps={{ variant: 'h5' }} />
        </ListItem>
        <Divider />
        <List disablePadding>
          {deactivatedLocations.map(location => {
            const locationId = get(location, 'id')
            const activateLocation = () => {
              const payload = {
                deactivate: false,
                locations: [{ id: locationId }],
              }

              activateDeactivateLocations(payload)
                .unwrap()
                .then(() => {
                  dispatch(getCustomerDetails({ accountId }))
                  toast.success(payload.deactivate ? T.LOCATION_DEACTIVATED_MSG : T.LOCATION_ACTIVATED_MSG)
                })
                .catch(handleError)
            }

            return (
              <>
                <LocationView
                  showLocationAvatar={false}
                  accountId={accountId}
                  location={location}
                  isActive
                  action={
                    <Button disabled={isLoading} size="small" variant="outlined" color="success" onClick={activateLocation}>
                      {T.REACTIVATE}
                    </Button>
                  }
                />
                <Divider />
              </>
            )
          })}
        </List>
        {!hasDeactivatedLocations && <HHSectionPlaceholder title={T.NO_DEACTIVATED_LOCATIONS} />}
      </DialogContent>
    </HHBaseDialog>
  )
}

ReactivateLocationsDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ReactivateLocationsDialog
