import React, { useReducer } from 'react'
import startCase from 'lodash/startCase'
import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import ContentPage from 'components/settings/ContentPage'
import SideBar from 'components/settings/SideBar'
import routes from 'router/routes'
import { canSeeUserSettings, canSeePermissionSettings } from 'data/permissions/permissionsSelectors'
import T from 'T'
import { useSelector, shallowEqual } from 'react-redux'

const Settings = () => {
  const seeUserSettings = useSelector(canSeeUserSettings, shallowEqual)
  const seePermissionSettings = useSelector(canSeePermissionSettings, shallowEqual)

  const getUsersPerPermission = () => {
    if (seeUserSettings) {
      return 'people'
    }

    if (seePermissionSettings) {
      return 'roles'
    }
    return 'skills'
  }

  const settingsName = {
    'General Settings': 'business-info',
    'Business Lines': 'attributes',
    Tags: 'tags',
    Users: getUsersPerPermission(),
    Trucks: 'trucks',
    Zones: 'pricing-zones',
    Billings: 'general',
    'Customer Portal': 'customer-portal',
    People: 'people',
    Roles: 'roles',
    Teams: 'teams',
    Skills: 'skills',
    [`${T.PERMIT}s`]: 'permits',
    'Pricing Zones': 'pricing-zones',
    'Tax Zones': 'tax-zones',
    'Customer Zones': 'customer-zones',
    General: 'general',
    'Customer Billing Profiles': 'customer-billing-profiles',
    'Postcard Setup': 'postcard-setup',
    'General Ledger': 'general-ledger',
    Attributes: 'attributes',
    'Create & Configure': 'create-configure',
    Yards: 'yards',
    'Disposal Sites': 'disposal-sites',
    'Business Information': 'business-information',
  }
  // used in url setting

  const history = useHistory()
  const selectedTab = history.location.pathname.split('/').pop()

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    activeOption: selectedTab === 'create-configure' ? T.CREATE_N_CONFIGURE : startCase(selectedTab),
  })

  const { activeOption } = localState

  // For sidebar tab selection
  const handleActiveOptions = (e, option, type) => {
    setLocalState({ activeOption: option })
    history.push({
      pathname: routes.app.settings + `/${settingsName[option]}`,
    })
  }

  // For setting selected class
  const handleActiveClass = (option, type) => {
    let selectedOption = option.replace(/\s+/g, '-').toLowerCase()
    if (selectedOption === 'create-&-configure') {
      selectedOption = 'create-configure'
    }
    return selectedTab === selectedOption
  }

  return (
    <Box display="flex">
      <SideBar
        selectedTab={selectedTab}
        activeOption={activeOption}
        handleActiveClass={handleActiveClass}
        handleActiveOptions={handleActiveOptions}
      />

      <ContentPage selectedTab={selectedTab} />
    </Box>
  )
}

export default Settings
