/* eslint-disable no-param-reassign */
import { get } from 'lodash'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { handleCommonTableformatFilters, handleCommonTableformatSortBy, handlePrefillResponseModification } from 'utils/table'
// eslint-disable-next-line import/no-cycle
import { INVOICE_CONFIGURATOR_DATA_GRID } from 'containers/billing/settings'
// eslint-disable-next-line import/no-cycle
import { isUnPaidInvoice } from 'utils/billing'
import { INVOICE_PAGINATION } from 'settings/constants/pagination'
import { filterMapToQueryParams } from 'utils/LHSbracket'
import { createDataGridSlice, mapSortRulesToSortModel } from '../generic/createDataGridSlice'
import { fixDateFilters } from '../generic/fixDateFilters'

const { INITIAL_PAGE, ROWS_PER_PAGE } = INVOICE_PAGINATION

const getInitialState = () => ({
  pagination: {
    page: INITIAL_PAGE,
    pageSize: ROWS_PER_PAGE,
  },
  sorting: {
    sortModel: [{ field: 'invoiceDate', sort: 'desc' }],
  },
  columns: {
    columnVisibilityModel: {
      customerBillingProfile: true,
      accountNumber: true,
      customerName: true,
      invoiceNumber: true,
      invoiceDate: true,
      sentDate: true,
      amount: true,
      balance: true,
      status: true,
      wo: false,
      service: false,
      postDate: false,
      unPostedAmountCents: false,
      postedAmountCents: false,
      lateFees: false,
      paymentNumber: false,
      billingCycleText: false,
      billingTiming: false,
      type: false,
      paymentTermsText: false,
      paper: false,
      pdf: false,
      portal: false,
      deliveryMethod: false,
      customerAddress: false,
      customerCity: false,
      customerState: false,
      customerZip: false,
      tags: false,
      adjustments: false,
      adjustmentsTotal: false,
    },
  },
  confirmInvoiceBulkActionDialog: {
    open: false,
    action: '',
    invoiceCount: 0,
  },
  selectionModel: [],
  selectedRows: [],
  selectedInvoiceIdList: [],
  selectedRowsStatusCount: { paid: 0, unpaid: 0, partial: 0, unpaidWithZeroAmount: 0 },
  isOpenInvoiceActionMenu: null,
  isListLoading: false,
  savedView: {
    existingFilterList: [],
    activeFilter: '',
    input: '',
    selectedFilterId: '',
    selectedFilter: {},
    isModalOpen: false,
  },
  filters: {
    allFilters: [],
    isOpenTableFilters: false,
    searchFilters: {},
    sortRules: [{ columnName: 'invoiceDate', operation: 'DESC' }],
    refetchFlag: false,
    queryParams: {},
  },
  sendPostData: {
    draftInvoicesWithoutPDF: [],
    isOpenInvoiceSendFailureConfirmation: false,
  },
  deleteInvoiceConfirmation: {
    isOpen: false,
    invoiceIds: [],
    invoiceNumbers: [],
  },
  deleteUnpaidInvoiceConfirmation: {
    isOpen: false,
    invoiceId: '',
  },
  regenerateConfirmation: {
    isOpen: false,
    invoiceIds: [],
    invoiceNumbers: [],
    invoices: [],
    isMultiSelect: false,
  },
  revertToDraftConfirmation: {
    isOpen: false,
    invoiceId: '',
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
  sideView: {
    isOpen: false,
    isEditInvoiceWOListingOpen: false,
    currentInvoice: {
      accountId: '',
      invoiceNumber: '',
      invoiceId: '',
      unPostedAmountCents: '',
      accountName: '',
      invoiceDate: '',
      invoiceStatus: '',
      isCurrentUnPosted: false,
    },
  },
  markAsPaidAction: {
    show: false,
    disabled: true,
  },
  markAsSentAction: {
    show: false,
    disabled: true,
  },
  regenerateAction: {
    show: false,
    disabled: true,
  },
  deleteAction: {
    show: false,
    disabled: true,
  },
  resetOnUnmount: true,
  payments: {
    selectedRow: undefined,
    isOpenTakePaymentsDialog: false,
  },
  bulkSendInvoicesConfirmation: {
    isOpen: false,
  },
  addToGroup: {
    isOpen: false,
    invoiceIds: [],
  },
})
export const invoicesSlice = createDataGridSlice({
  name: 'invoices',
  initialState: getInitialState(),
  reducers: {
    setInvoicesSelectionModel: (state, action) => {
      const selectionModel = get(action, 'payload.selectionModel', [])
      const rows = get(action, 'payload.rows', [])
      state.selectionModel = selectionModel
      if (selectionModel.length > 0) {
        const selectedInvoiceNumbersSet = new Set(selectionModel)
        state.selectedRows = rows.filter(({ invoiceNumber }) => selectedInvoiceNumbersSet.has(invoiceNumber))
        state.selectedInvoiceIdList = state.selectedRows.map(({ invoiceId }) => invoiceId)
        const newSelectedRowsStatusCount = { ...getInitialState().selectedRowsStatusCount }
        state.selectedRows.forEach(({ status, amountCents }) => {
          newSelectedRowsStatusCount[status.toLowerCase()] += 1
          if (isUnPaidInvoice(status) && amountCents === 0) newSelectedRowsStatusCount.unpaidWithZeroAmount += 1
        })
        const { paid, unpaid, partial, unpaidWithZeroAmount } = newSelectedRowsStatusCount
        state.selectedRowsStatusCount = newSelectedRowsStatusCount
        state.regenerateAction = { show: partial > 0 || unpaid > 0, disabled: paid > 0 }
        state.markAsPaidAction = { show: unpaidWithZeroAmount > 0, disabled: partial > 0 || paid > 0 || unpaid - unpaidWithZeroAmount > 0 }
        state.markAsSentAction = { show: partial > 0, disabled: false }
        state.deleteAction = { show: partial > 0, disabled: paid > 0 || unpaid > 0 }
      } else {
        const initialState = getInitialState()
        state.selectedRows = []
        state.selectedRowsStatusCount = initialState.selectedRowsStatusCount
        state.regenerateAction = initialState.regenerateAction
        state.markAsPaidAction = initialState.markAsPaidAction
        state.markAsSentAction = initialState.markAsSentAction
        state.deleteAction = initialState.deleteAction
      }
    },
    filterInvoicesSelectionModel: (state, action) => {
      const selectionModel = get(action, 'payload.selectionModel', [])
      state.selectionModel = selectionModel
      if (selectionModel.length > 0) {
        const selectedInvoiceNumbersSet = new Set(selectionModel)
        state.selectedRows = state.selectedRows.filter(({ invoiceNumber }) => selectedInvoiceNumbersSet.has(invoiceNumber))
        state.selectedInvoiceIdList = state.selectedRows.map(({ invoiceId }) => invoiceId)
        const newSelectedRowsStatusCount = { ...getInitialState().selectedRowsStatusCount }
        state.selectedRows.forEach(({ status, amountCents }) => {
          newSelectedRowsStatusCount[status.toLowerCase()] += 1
          if (isUnPaidInvoice(status) && amountCents === 0) newSelectedRowsStatusCount.unpaidWithZeroAmount += 1
        })
        const { paid, unpaid, partial, unpaidWithZeroAmount } = newSelectedRowsStatusCount
        state.selectedRowsStatusCount = newSelectedRowsStatusCount
        state.regenerateAction = { show: partial > 0 || unpaid > 0, disabled: paid > 0 }
        state.markAsPaidAction = { show: unpaidWithZeroAmount > 0, disabled: partial > 0 || paid > 0 || unpaid - unpaidWithZeroAmount > 0 }
        state.markAsSentAction = { show: partial > 0, disabled: false }
        state.deleteAction = { show: partial > 0, disabled: paid > 0 || unpaid > 0 }
      } else {
        const initialState = getInitialState()
        state.selectedRows = []
        state.selectedRowsStatusCount = initialState.selectedRowsStatusCount
        state.regenerateAction = initialState.regenerateAction
        state.markAsPaidAction = initialState.markAsPaidAction
        state.markAsSentAction = initialState.markAsSentAction
        state.deleteAction = initialState.deleteAction
      }
    },
    setInvoicesSavedViewSelectedFilterId: (state, action) => {
      state.savedView.selectedFilterId = action.payload
    },
    setInvoicesExistingFilterList: (state, action) => {
      state.savedView.existingFilterList = action.payload
    },
    setInvoicesSavedViewInput: (state, action) => {
      state.savedView.input = action.payload
    },
    setInvoicesSavedViewState: (state, action) => {
      state.savedView = {
        ...state.savedView,
        ...action.payload,
      }
    },
    setInvoicesRefetchFlag: (state, action) => {
      state.filters.refetchFlag = action.payload
    },
    setInvoicesFiltersAndSortRulesFromSavedView: (state, action) => {
      const newAllFilters = {}
      const newSortBy = {}
      const searchFilters = get(action, 'payload.searchFilters', [])
      const sortRules = get(action, 'payload.sortRules', [])
      if (Array.isArray(searchFilters) && searchFilters.length > 0) {
        searchFilters.forEach(filter => {
          const initialFilter = INVOICE_CONFIGURATOR_DATA_GRID.find(({ columnName }) => columnName === filter.columnName)
          newAllFilters[filter.columnName] = handlePrefillResponseModification(initialFilter, filter)
        })
        state.filters.allFilters = newAllFilters
        state.filters.queryParams = filterMapToQueryParams(newAllFilters)
        const parsedFilters = handleCommonTableformatFilters(newAllFilters)
        state.filters.searchFilters = fixDateFilters(parsedFilters)
      } else {
        state.filters.queryParams = {}
        state.filters.allFilters = {}
        state.filters.searchFilters = {}
      }
      if (Array.isArray(sortRules) && sortRules.length > 0) {
        sortRules.forEach(sort => {
          newSortBy[sort.columnName] = { columnName: sort.columnName, operation: sort.operation }
        })
        const newSortRules = handleCommonTableformatSortBy(newSortBy)
        state.filters.sortRules = newSortRules
        state.sorting.sortModel = mapSortRulesToSortModel(newSortRules)
      } else {
        state.filters.sortRules = {}
        state.sorting.sortModel = []
      }
      state.filters.refetchFlag = true
    },
    setInvoicesSideView: (state, action) => {
      state.sideView = {
        ...state.sideView,
        ...action.payload,
      }
    },
    setInvoicesSelectedInvoice: (state, action) => {
      state.sideView.currentInvoice = {
        ...state.sideView.currentInvoice,
        ...action.payload,
      }
    },
    setInvoicesSendPostData: (state, action) => {
      state.sendPostData = {
        ...state.sendPostData,
        ...action.payload,
      }
    },
    setInvoicesDeleteConfirmation: (state, action) => {
      state.deleteInvoiceConfirmation = {
        ...state.deleteInvoiceConfirmation,
        ...action.payload,
      }
    },
    setInvoicesRegenerateConfirmation: (state, action) => {
      state.regenerateConfirmation = {
        ...state.regenerateConfirmation,
        ...action.payload,
      }
    },
    setInvoicesRevertToDraftConfirmation: (state, action) => {
      state.revertToDraftConfirmation = {
        ...state.revertToDraftConfirmation,
        ...action.payload,
      }
    },
    setInvoicesDeleteUnpaidConfirmation: (state, action) => {
      state.deleteUnpaidInvoiceConfirmation = {
        ...state.deleteUnpaidInvoiceConfirmation,
        ...action.payload,
      }
    },
    setInvoicesIsOpenInvoiceActionMenu: (state, action) => {
      state.isOpenInvoiceActionMenu = action.payload
    },
    resetInvoicesState: state => {
      if (state.resetOnUnmount) return getInitialState()
      return state
    },
    setResetInvoicesStateOnUnmount: (state, action) => {
      state.resetOnUnmount = action.payload
    },
    setInvoicesPaymentsState: (state, action) => {
      state.payments = action.payload
    },
    setBulkSentInvoicesConfirmation: (state, action) => {
      state.bulkSendInvoicesConfirmation = {
        ...state?.bulkSendInvoicesConfirmation,
        ...action.payload,
      }
    },
    setIsInvoiceListLoading: (state, action) => {
      state.isListLoading = action.payload
    },
    setAddToGroupDialogState: (state, action) => {
      state.addToGroup = action.payload
    },
    setConfirmInvoiceBulkActionDialog: (state, action) => {
      state.confirmInvoiceBulkActionDialog = {
        ...state.confirmInvoiceBulkActionDialog,
        ...action.payload,
      }
    },
  },
})

export const {
  setInvoicesExistingFilterList,
  setInvoicesSelectionModel,
  filterInvoicesSelectionModel,
  setSortModel: setInvoicesSortModel,
  setPage: setInvoicesPage,
  setPageSize: setInvoicesPageSize,
  setColumnVisibilityModel: setInvoicesColumnVisibilityModel,
  setFilters: setInvoicesFilters,
  setIsOpenTableFilters: setInvoicesIsOpenTableFilters,
  setPinnedColumns: setInvoicesPinnedColumns,
  setInvoicesSavedViewInput,
  setInvoicesSavedViewState,
  setInvoicesFiltersAndSortRulesFromSavedView,
  setInvoicesRefetchFlag,
  setInvoicesSavedViewSelectedFilterId,
  setInvoicesSideView,
  setInvoicesSelectedInvoice,
  setInvoicesSendPostData,
  setInvoicesDeleteConfirmation,
  setInvoicesIsOpenInvoiceActionMenu,
  setInvoicesRegenerateConfirmation,
  setResetInvoicesStateOnUnmount,
  resetInvoicesState,
  setInvoicesPaymentsState,
  setInvoicesRevertToDraftConfirmation,
  setInvoicesDeleteUnpaidConfirmation,
  setBulkSentInvoicesConfirmation,
  setIsInvoiceListLoading,
  setAddToGroupDialogState,
  setConfirmInvoiceBulkActionDialog,
} = invoicesSlice.actions

export const selectIsOpenInvoiceSendFailureConfirmation = s => get(s, 'invoices.sendPostData.isOpenInvoiceSendFailureConfirmation', false)
export const selectDraftInvoicesWithoutPDF = s => get(s, 'invoices.sendPostData.draftInvoicesWithoutPDF', [])
export const selectConfirmInvoiceBulkActionDialog = s => get(s, 'invoices.confirmInvoiceBulkActionDialog', {})

export default invoicesSlice.reducer
