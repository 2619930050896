import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Grid } from '@mui/material'

import { selectAddress } from 'data/address/addressSelectors'

import T from 'T'
import PreviewField from '../common/PreviewField'

import { DEFAULT_STATE } from '../../../common/address/model'

const { addressNamePlaceholder, line1Placeholder, cityPlaceholder, statePlaceholder, zipCodePlaceholder } = DEFAULT_STATE

const LocationContent = ({
  showAddressName = false,
  addressNameLabel = capitalize(T.SERVICE_LOCATION_NAME),
  address = {},
  showPlaceholder = false,
}) => {
  const { addressName, line1, unitNumber, city, state, zipCode } = selectAddress({ ...address })

  return (
    <Grid container rowGap={1.5}>
      {showAddressName && (
        <Grid item xs={12}>
          <PreviewField
            fieldName={addressNameLabel}
            fieldValue={addressName}
            showPlaceholder={showPlaceholder}
            placeholderValue={addressNamePlaceholder}
            fontWeight={500}
          />
        </Grid>
      )}

      <Grid item>
        <PreviewField
          fieldName={capitalize(T.STREET_ADDRESS)}
          fieldValue={`${[line1, unitNumber].filter(Boolean).join(', ')}`}
          showPlaceholder={showPlaceholder}
          placeholderValue={line1Placeholder}
        />
      </Grid>

      <Grid container item spacing={3}>
        <Grid item>
          <PreviewField fieldName={T.CITY} fieldValue={city} showPlaceholder={showPlaceholder} placeholderValue={cityPlaceholder} />
        </Grid>
        <Grid item>
          <PreviewField fieldName={T.STATE} fieldValue={state} showPlaceholder={showPlaceholder} placeholderValue={statePlaceholder} />
        </Grid>
        <Grid item>
          <PreviewField
            fieldName={capitalize(T.ZIP_CODE)}
            fieldValue={zipCode}
            showPlaceholder={showPlaceholder}
            placeholderValue={zipCodePlaceholder}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

LocationContent.propTypes = {
  showAddressName: PropTypes.bool,
  addressNameLabel: PropTypes.string,
  address: PropTypes.object,
  showPlaceholder: PropTypes.bool,
}

export default LocationContent
