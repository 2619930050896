const { createSelector } = require('reselect')

const selectCustomerBillingProfileName = state => state.customerBillingProfileName
const selectCycle = state => state.cycle
const selectPaymentTerms = state => state.paymentTerms

export const selectIsProceedDisabled = createSelector(
  [selectCustomerBillingProfileName, selectCycle, selectPaymentTerms],
  (customerBillingProfileName, cycle, paymentTerms) => customerBillingProfileName === '' || !cycle || !paymentTerms
)
