import { createSlice } from '@reduxjs/toolkit'

const getInitialState = () => ({
  isOpenPDFDrawer: false,
  isOpenEditDrawer: false,
  invoice: null,
})

const invoiceDetailsDrawerSlice = createSlice({
  name: 'invoiceDetailsDrawer',
  initialState: getInitialState(),
  reducers: {
    setInvoiceDetailsDrawerState: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setInvoiceDetailsDrawerState } = invoiceDetailsDrawerSlice.actions
export default invoiceDetailsDrawerSlice.reducer
