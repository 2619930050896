import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { Box, Typography, Alert } from '@mui/material'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { ALL_DISPOSAL_FEES } from 'components/pricing/settings'
import { ExclamationCircle } from '@styled-icons/heroicons-solid/ExclamationCircle'
import { useGetDisposalFeeRevalidateMutation } from 'api/pricing/getDisposalFeeRevalidate'
import ConfirmationTableBody from './ConfirmationTableBody'
import ConfirmationTableHeader from './ConfirmationTableHeader'

const toRevalidatePayload = (disposalFeeField, disposalFeeObject) => {
  const { costPlus, feeName, measureType, tiers } = disposalFeeField
  const {
    active,
    autoLink,
    businessLine,
    exist,
    linked,
    material,
    overage,
    pricingZone,
    recurrence,
    serviceAction,
    serviceMethod,
    specificDays,
  } = disposalFeeObject
  return {
    active,
    autoLink,
    businessLine,
    costPlus,
    exist,
    feeName,
    linked,
    material: material?.id ? { id: material?.id } : undefined,
    measureType,
    overage,
    pricingZone: pricingZone?.id ? { id: pricingZone?.id } : undefined,
    recurrence: recurrence?.id ? { id: recurrence?.id } : undefined,
    serviceAction: serviceAction?.id ? { id: serviceAction?.id } : undefined,
    serviceMethod: serviceMethod?.id ? { id: serviceMethod?.id } : undefined,
    services: [],
    specificDays,
    tiers: tiers.map(({ minVal, price, maxVal }) => ({ minVal, price, maxVal })),
  }
}

const disposalArrayToFormFields = disposalArray =>
  disposalArray.map(record => {
    const { feeName, costPlus, tiers, measureType, minFee, overageThreshold, overageFee, specificDays } = record
    tiers[0].maxVal = ''
    return {
      feeName,
      costPlus,
      measureType,
      minFee,
      overageThreshold,
      overageFee,
      tiers,
      showTierFields: false,
      specificDays,
    }
  })

const ConfirmationTable = ({
  disposalFees,
  duplicatedDisposalFees,
  syncDisposalFees,
  setSyncDisposalFees,
  syncDuplicatedDisposalFees,
  setSyncDuplicatedDisposalFees,
}) => {
  const [columns, setColumns] = useState(ALL_DISPOSAL_FEES)
  const { control, watch, getValues, setFocus } = useFormContext()
  const {
    fields: disposalFeeFields,
    remove: removeDisposalFeeFields,
    update: updateDisposalFeeFields,
    replace: replaceDisposalFeeFields,
    append: appendDisposalFeeFields,
  } = useFieldArray({ name: 'disposalFees', control })
  const {
    fields: duplicatedDisposalFeeFields,
    remove: removeDuplicatedDisposalFeeFields,
    update: updateDuplicatedDisposalFeeFields,
    replace: replaceDuplicatedDisposalFeeFields,
    append: appendDuplicatedDisposalFeeFields,
  } = useFieldArray({ name: 'duplicatedDisposalFees', control })
  const [revalidate, { isLoading }] = useGetDisposalFeeRevalidateMutation()

  useEffect(() => {
    if (disposalFees.length > 0) {
      const formFieldArray = disposalArrayToFormFields(disposalFees)
      replaceDisposalFeeFields(formFieldArray)
    }
  }, [disposalFees])

  useEffect(() => {
    if (duplicatedDisposalFees.length > 0) {
      const formFieldArray = disposalArrayToFormFields(duplicatedDisposalFees)
      replaceDuplicatedDisposalFeeFields(formFieldArray)
    }
  }, [duplicatedDisposalFees])

  // when change is detected swap duplicatedFields to
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && !isLoading && (name.includes('duplicatedDisposalFees') || name.includes('disposalFees'))) {
        const path = name?.split('.')
        if (Array.isArray(path) && path.length >= 3) {
          const [fieldName, index] = path
          const disposalFeesObj = getValues(`${fieldName}.${index}`)
          const disposalFeeResponse = name.includes('duplicatedDisposalFees') ? syncDuplicatedDisposalFees[index] : syncDisposalFees[index]
          const payload = toRevalidatePayload(disposalFeesObj, disposalFeeResponse)
          revalidate(payload)
            .then(({ data }) => {
              const { exist } = data
              if (name.includes('duplicatedDisposalFees') && exist === false) {
                removeDuplicatedDisposalFeeFields(index)
                setSyncDuplicatedDisposalFees(r => r?.filter((_, i) => i === index))
                setSyncDisposalFees(d => [...d, disposalFeeResponse])
                appendDisposalFeeFields(disposalFeesObj)
              } else if (name.includes('disposalFees') && exist === true) {
                removeDisposalFeeFields(index)
                setSyncDisposalFees(d => d?.filter((_, i) => i === index))
                setSyncDuplicatedDisposalFees(d => [...d, disposalFeeResponse])
                appendDuplicatedDisposalFeeFields(disposalFeesObj)
              }
              setFocus(name)
            })
            .catch(_ => {})
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, syncDisposalFees, syncDuplicatedDisposalFees, isLoading])

  return (
    <Box sx={{ overflowX: 'auto', width: '100%' }}>
      {disposalFeeFields.length > 0 && (
        <>
          <ConfirmationTableHeader
            sx={{
              width: '100%',
            }}
            setColumns={setColumns}
            columns={columns}
          />
          <ConfirmationTableBody
            columns={columns}
            fields={disposalFeeFields}
            rows={syncDisposalFees}
            name="disposalFees"
            setDisposalFeeRecords={setSyncDisposalFees}
            update={updateDisposalFeeFields}
            remove={removeDisposalFeeFields}
            replace={replaceDisposalFeeFields}
          />
        </>
      )}
      <Box mt={3}>
        {duplicatedDisposalFeeFields.length > 0 && (
          <>
            <Alert
              sx={{
                padding: 0,
                backgroundColor: '#ffffff',
              }}
              severity="error"
              icon={<ExclamationCircle sx={{ width: 20, color: '#AA2B2B' }} />}
            >
              <Typography fontWeight={500} variant="body1">
                {T.FEE_DUPLICATED_MSG}
              </Typography>
            </Alert>
            <ConfirmationTableHeader
              sx={{
                width: '100%',
              }}
              setColumns={setColumns}
              columns={columns}
            />
            <ConfirmationTableBody
              duplicated
              fields={duplicatedDisposalFeeFields}
              columns={columns}
              rows={syncDuplicatedDisposalFees}
              name="duplicatedDisposalFees"
              setDisposalFeeRecords={setSyncDuplicatedDisposalFees}
              update={updateDuplicatedDisposalFeeFields}
              remove={removeDuplicatedDisposalFeeFields}
              replace={replaceDuplicatedDisposalFeeFields}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

ConfirmationTable.propTypes = {
  disposalFees: PropTypes.array,
  duplicatedDisposalFees: PropTypes.array,
  syncDisposalFees: PropTypes.array,
  setSyncDisposalFees: PropTypes.func,
  syncDuplicatedDisposalFees: PropTypes.array,
  setSyncDuplicatedDisposalFees: PropTypes.func,
}

export default ConfirmationTable
