import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'

import { Grid, Box, Button, Typography, IconButton } from '@mui/material'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getContainerCount } from 'utils/container'
import { selectAddressName } from 'data/address/addressSelectors'
import { combineAddressInfo } from 'utils/helper'
import { CACHE_TAG_WORK_ORDER_DETAILS } from 'api/cacheTagTypes'
import { createDateFromString, formatDateToBEFormatDateFns } from 'utils/date'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { APP_PREFIX } from 'router/routes'

import T from 'T'
import api from 'api'
import Loader from 'components/common/loader'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import HHDesktopDatePicker from 'components/form-fields/v5/HHDesktopDatePicker'

import ExternalLinkBehavior from '../../common/ExternalLinkBehavior'
import WOInfo from '../common/WOInfo'
import WorkOrderDetailsActionMenu from './action-menu'

const TopSection = ({ workOrder = {} }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder, { isLoading: isUpdateWOLoading }] = useUpdateWorkOrderMutation()
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [confirmationType, setConfirmationType] = useState('')
  const [newServiceDate, setNewServiceDate] = useState()
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null)

  const {
    account,
    id: workOrderId,
    workOrderSequence,
    routeId,
    serviceDate,
    location,
    containers,
    purchaseOrderNumber = '',
  } = workOrder || {}
  const locationAddress = get(location, 'address', {})
  const addressName = selectAddressName(locationAddress)
  const serviceAddress = combineAddressInfo(get(location, 'address', {}))
  const containerCount = getContainerCount(containers)

  const isDownloadButtonDisabled = !workOrderId || !routeId
  const serviceDateCast = createDateFromString(serviceDate)

  const isConfirmationType = confirmationType === T.CONFIRMATION

  const handleProceed = () => {
    updateWorkOrder({ id: workOrderId, serviceDate: formatDateToBEFormatDateFns(newServiceDate) })
      .unwrap()
      .then(() => {
        setConfirmationType(T.COMPLETE)
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_DETAILS]))
      })
      .catch(error => {
        setIsOpenConfirmModal(false)
        handleError(error)
      })
  }

  const handleOpenActionMenu = event => setActionMenuAnchorEl(event.currentTarget)

  const handleCloseActionMenu = () => setActionMenuAnchorEl(null)

  return (
    <>
      {isUpdateWOLoading && <Loader />}
      <Box px={4} py={2.5} position="sticky" bgcolor="background.header" top={56} zIndex={100}>
        <Grid container>
          <Grid item sm={12} md={8}>
            {addressName && (
              <Typography variant="h6" fontWeight={500} color="textSecondary" mb={0.5}>
                {addressName}
              </Typography>
            )}
            <Typography variant="h4" fontWeight={600}>
              {serviceAddress}
            </Typography>
            <Grid container>
              <WOInfo
                accountId={get(account, 'id')}
                locationId={get(location, 'id')}
                accountName={get(account, 'accountName')}
                accountNumber={get(account, 'accountNumber')}
                workOrderSequence={workOrderSequence}
                containerCount={containerCount}
                purchaseOrderNumber={purchaseOrderNumber}
                hasLargeSize
              />
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} textAlign="right">
            <Button
              sx={{ minWidth: 40, height: 40, px: 2, '& .MuiButton-startIcon ': { mr: 0 } }}
              startIcon={<Printer width={24} height={24} />}
              disabled={isDownloadButtonDisabled}
              component={ExternalLinkBehavior}
              to={`${APP_PREFIX}/routes/${routeId}/work_orders/${workOrderId}/export?date=${serviceDate}`}
              variant="outlined"
            />

            <HHDesktopDatePicker
              disableScrollLock
              value={serviceDateCast}
              TextFieldProps={{ sx: { ml: 2, width: 200 }, size: 'medium' }}
              onChange={date => {
                setIsOpenConfirmModal(true)
                setConfirmationType(T.CONFIRMATION)
                setNewServiceDate(date)
              }}
            />

            <IconButton sx={{ ml: 1 }} onClick={handleOpenActionMenu}>
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <ConfirmationModal
        className="global-drawer-confirm"
        isOpen={isOpenConfirmModal}
        title={isConfirmationType ? T.WO_DATE_CHANGE_MSG : T.WO_DATE_CHANGE_SUCCESS}
        hasCancelBtn={isConfirmationType}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={isConfirmationType ? T.CONTINUE : T.CLOSE}
        onCancel={() => setIsOpenConfirmModal(false)}
        onProceed={() => (isConfirmationType ? handleProceed() : setIsOpenConfirmModal(false))}
      />
      <WorkOrderDetailsActionMenu
        anchorEl={actionMenuAnchorEl}
        workOrderId={workOrderId}
        existingPurchaseOrder={purchaseOrderNumber}
        onClose={handleCloseActionMenu}
      />
    </>
  )
}

TopSection.propTypes = {
  workOrder: PropTypes.object,
}

export default TopSection
