import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom-v5-compat'

import { Box, Typography, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { get } from 'utils/lodash'
import { getOnlyDigits } from 'utils/string'
import routes, { getCustomerDetailsPageUrl } from 'router/routes'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'
import { useLazyEffect } from 'components/locations/filters/Search/useLazyEffect'
import { useLazyDebouncedSearchAccountOptionsQuery } from 'components/customers/accounts/search/useLazyDebouncedSearchAccountOptionsQuery'
import {
  PHONE_SEARCH_TYPE,
  RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
} from 'components/locations/filters/Search/settings'
import { CUSTOMER_SIDE_NAV_DRAWER_WIDTH } from 'components/customers/common/side-nav/settings'
import {
  SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL,
  SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER,
  checkIsManualSearch,
} from 'components/customers/accounts/search/settings'

import T from 'T'
import FiltersMenuButton from 'components/locations/filters/FiltersMenuButton'
import SearchAutocomplete from 'components/locations/filters/Search/SearchAutocomplete'
import SortByFilterMenuField from 'components/customers/accounts/filters/SortByFilter/SortByFilterMenuField'
import AccountsActionMenu from 'components/customers/accounts/common/AccountsActionMenu'
import useManualSearchListUtilities from 'components/customers/accounts/search/useManualSearchListUtilities'
import { PAGE_TYPE, RECENTLY_ADDED_VIEW_MODE, RECENTLY_VIEWED_VIEW_MODE } from 'components/customers/accounts/settings'

const AccountsHeader = ({
  loadingRows = false,
  accountsCount,
  showSortByControl = true,
  showToggleNumericControl = true,
  isRecentlySelected,
  allAccounts = [],
  isManualSearchLoading = false,
  viewMode,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const { watch, control, setValue } = useFormContext()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const searchType = watch('searchType')
  const searchInputValue = watch('searchInputValue')
  const search = watch('search')
  const searchValue = watch('searchValue')
  const includeDeactivated = watch('withDeactivated')

  const isManualSearch = useMemo(() => checkIsManualSearch(searchType), [searchType])
  const { getSearchedResultsManually } = useManualSearchListUtilities({ data: allAccounts, watch, type: PAGE_TYPE })
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null)
  const [searchAccountOptions, { isLoading, isFetching, data }] = useLazyDebouncedSearchAccountOptionsQuery()

  const accounts = useMemo(() => {
    if (isManualSearch) {
      return getSearchedResultsManually()
    }
    return get(data, 'accounts', [])
  }, [data, allAccounts, watch, searchInputValue, isManualSearch, searchType, includeDeactivated])

  const label = SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_LABEL[searchType]
  const placeholder = SEARCH_TYPE_VALUE_TO_AUTOCOMPLETE_PLACEHOLDER[searchType]

  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'), shallowEqual)
  const title = useMemo(() => {
    if (viewMode === RECENTLY_VIEWED_VIEW_MODE) return T.RECENTLY_VIEWED
    if (viewMode === RECENTLY_ADDED_VIEW_MODE) return T.RECENTLY_ADDED
    return T.ACCOUNT_VIEW
  }, [viewMode])
  const handleDrawerToggle = () => dispatch(setShowCustomerSideNav(!showCustomerSideNav))

  const handleSortByChange = noop

  const handleOpenActionMenu = event => setActionMenuAnchorEl(event.currentTarget)

  const handleCloseActionMenu = () => setActionMenuAnchorEl(null)

  const handleChange = (event, item, reason) => {
    const accountId = get(item, 'accountId')
    if (accountId) {
      const accountDetailsBaseURL = getCustomerDetailsPageUrl(accountId)
      navigate({ pathname: accountDetailsBaseURL })
    }
  }

  const handleInputChange = useCallback(
    (event, value, reason) => {
      if (value === '' && reason !== 'reset') {
        setValue('searchValue', '')
        setValue('search', '')
      }
    },
    [search, searchValue]
  )

  const handleChangeSearchType = newSearchType => {
    if (
      [PHONE_SEARCH_TYPE, RECENT_ACCOUNT_VIEWED_SEARCH_TYPE, RECENT_ACCOUNT_ADDED_SEARCH_TYPE].includes(searchType) ||
      [PHONE_SEARCH_TYPE, RECENT_ACCOUNT_VIEWED_SEARCH_TYPE, RECENT_ACCOUNT_ADDED_SEARCH_TYPE].includes(newSearchType)
    ) {
      setValue('searchInputValue', '')
      setValue('searchValue', '')
    }
  }

  const handleSearch = useCallback(
    (search, closeOptionList) => {
      setValue('search', search)
      setValue('searchValue', search)
      closeOptionList()
      if (isRecentlySelected) {
        navigate({
          pathname: routes.app.accounts,
        })
      }
    },
    [isRecentlySelected]
  )

  const popperSx = useMemo(
    () => ({
      minWidth: {
        lg: showCustomerSideNav ? `calc(100vw - ${CUSTOMER_SIDE_NAV_DRAWER_WIDTH + 64}px)` : `calc(100vw - ${64}px)`,
        md: `calc(100vw - 64px)`,
        xs: `calc(100vw - 32px)`,
      },
    }),
    [showCustomerSideNav]
  )

  const paperSx = useMemo(
    () => ({
      mr: {
        xs: 1,
        lg: 5,
      },
      ml: showCustomerSideNav ? 1 : 0,
    }),
    [showCustomerSideNav]
  )

  useLazyEffect(
    () => {
      if (searchInputValue && !isManualSearch) {
        const formattedValue = searchType === PHONE_SEARCH_TYPE ? getOnlyDigits(searchInputValue) : searchInputValue
        searchAccountOptions({ searchInputValue: formattedValue, includeDeactivated })
      }
    },
    [searchInputValue, searchType, includeDeactivated, isManualSearch],
    300
  )

  const loading = useMemo(
    () => (isManualSearch ? isManualSearchLoading : isLoading || isFetching),
    [isManualSearch, isLoading, isFetching, isManualSearchLoading]
  )

  return (
    <Box px={3} py={2} bgcolor="background.default" minHeight={56}>
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item container xs md="auto" alignItems="center" flexWrap="nowrap" columnSpacing={1} sx={{ mr: 5 }}>
          {!showCustomerSideNav && (
            <Grid item>
              <ReorderIcon onClick={handleDrawerToggle} />
            </Grid>
          )}
          <Grid item container xs="auto" alignItems="center" zeroMinWidth columnSpacing={0.5}>
            <Grid item zeroMinWidth>
              <Typography variant="h4" fontWeight="600">
                {title}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              {!loadingRows && (
                <Typography variant="h4" fontWeight="600">
                  <NumericFormat value={accountsCount} prefix="(" suffix=")" displayType="text" thousandSeparator />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md sx={{ order: { xs: 2, md: 0 }, mr: isTabletOrMobile ? 0 : 1 }}>
          <SearchAutocomplete
            popperSx={popperSx}
            paperSx={paperSx}
            loading={loading}
            options={accounts}
            onChange={handleChange}
            onChangeSearchType={handleChangeSearchType}
            label={label}
            onInputChange={handleInputChange}
            placeholder={placeholder}
            onSelectFreeEntry={handleSearch}
            onEnterPress={handleSearch}
            selectOnFocus
          />
        </Grid>
        <Grid item container xs="auto" columnSpacing={1} alignItems="center">
          <Grid item sx={{ ...(isTabletOrMobile && { display: 'none' }) }}>
            <FiltersMenuButton />
          </Grid>
          <Grid item sx={{ ...((isTabletOrMobile || !showSortByControl) && { display: 'none' }) }}>
            <SortByFilterMenuField name="sortBy" control={control} onChange={handleSortByChange} />
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpenActionMenu}>
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <AccountsActionMenu
        showToggleNumericControl={showToggleNumericControl}
        showSortByControl={showSortByControl}
        anchorEl={actionMenuAnchorEl}
        onSortByChange={handleSortByChange}
        onClose={handleCloseActionMenu}
      />
    </Box>
  )
}

AccountsHeader.propTypes = {
  showToggleNumericControl: PropTypes.bool,
  showSortByControl: PropTypes.bool,
  loadingRows: PropTypes.bool,
  accountsCount: PropTypes.number.isRequired,
  viewMode: PropTypes.string,
  isRecentlySelected: PropTypes.bool,
  allAccounts: PropTypes.array,
  isManualSearchLoading: PropTypes.bool,
}

export default AccountsHeader
