import { get } from 'lodash'
import { getLatitude, getLongitude } from 'data/address/addressSelectors'
import { combineAddressInfo } from 'utils/helper'
import { isValidHex } from 'utils/validations'
import { common, orange } from '@mui/material/colors'
import { WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { getPricingPeriod } from 'utils/service'

export const canModifyRouteSequence = () => {
  return true
}

export const parseDaysOfWeek = (daysOfWeek, pricingPeriod) => {
  const dayAbbreviations = ['S', 'M', 'T', 'W', 'R', 'F', 'U']
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const isDaily = pricingPeriod === 'Daily'
  return dayAbbreviations.map((abbr, index) => {
    let variant = 'default'
    if (isDaily || daysOfWeek.includes(abbr)) {
      variant = 'active'
    }
    return {
      day: dayNames[index],
      variant,
    }
  })
}

export const mapStopToListItemFormat = stop => {
  const addressStr = combineAddressInfo(stop)
  const accountName = get(stop, 'accountName')
  const latitude = getLatitude(stop)
  const longitude = getLongitude(stop)
  const sequence = get(stop, 'sequence')
  const position = get(stop, 'position')
  const id = get(stop, 'workOrderId')
  const workOrderStatus = get(stop, 'workOrderStatus')
  const serviceName = get(stop, 'serviceName')
  const configuredServiceEventId = get(stop, 'configuredServiceEventId')
  const recurrenceFrequency = get(stop, 'recurrenceFrequency', 1)
  const recurrencePer = get(stop, 'recurrencePer', '')
  const recurrenceInterval = get(stop, 'recurrenceInterval', '')
  const pricingPeriod = getPricingPeriod(recurrencePer, recurrenceInterval)
  const showWeekSummary = pricingPeriod === 'Weekly' || pricingPeriod === 'Daily'
  const recurrence = pricingPeriod ? `${recurrenceFrequency}x | ${pricingPeriod}` : 'On Request'
  const daysOfWeek = get(stop, 'daysOfWeek', '')
  const parsedDaysOfWeek = parseDaysOfWeek(daysOfWeek, pricingPeriod)

  return {
    id,
    workOrderId: id,
    addressStr,
    accountName,
    latitude,
    longitude,
    sequence,
    position,
    workOrderStatus,
    serviceName,
    configuredServiceEventId,
    recurrence,
    showWeekSummary,
    pricingPeriod,
    daysOfWeek,
    parsedDaysOfWeek,
    recurrenceFrequency,
  }
}

export const formatStopFeatures = (features, theme) => {
  return features.map((feature, index) => {
    const properties = get(feature, 'properties', {})
    const color = get(properties, 'color', common.black)
    const formattedColor = isValidHex(color) ? color : common.black
    const routeTextColor = theme.palette.getContrastText(formattedColor)
    const workOrderStatus = get(properties, 'workOrderStatus')
    const marker = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.marker`)
    const markerTextColor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.markerTextColor`)
    const workOrderColor = get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.borderColor`)
    return {
      ...feature,
      properties: {
        ...properties,
        'route-text-color': routeTextColor,
        'border-color': routeTextColor,
        icon: marker,
        'text-color': markerTextColor,
        'work-order-color': workOrderColor,
        position: index + 1,
        originalPosition: index + 1,
      },
    }
  })
}

export const formatUnassignedStopFeatures = features => {
  return features.map((feature, index) => {
    const properties = get(feature, 'properties', {})
    return {
      ...feature,
      properties: {
        ...properties,
        color: common.white,
        'route-text-color': common.black,
        'border-color': orange[300],
        icon: 'to-be-assigned-marker',
        'text-color': common.black,
        'work-order-color': common.white,
        routeId: 'UNASSIGNED',
        position: index + 1,
        originalPosition: index + 1,
      },
    }
  })
}
