import T from 'T'
import { PAYMENT_CONFIGURATOR } from 'containers/billing/settings/payments'

export const PAYMENTS_CONFIGURATOR = PAYMENT_CONFIGURATOR.map(rec =>
  rec.tableLabel === `${T.ACCOUNT} #`
    ? {
        position: 0,
        tableLabel: `${T.ACCOUNT} #`,
        label: `${T.ACCOUNT} #`,
        checked: true,
        locked: false,
        filterType: 'string',
        columnName: 'accountNumber',
        className: 'font-500',
      }
    : rec
)
