import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Divider } from '@mui/material'
import { useParams, useSearchParams } from 'react-router-dom-v5-compat'
import { get } from 'utils/lodash'
import WorkOrderCard from '../components/work-order/export/WorkOrderCard'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import { useLazyGetRawRoutesQuery } from '../api/route/getExportedRawRoutes'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import PrintSettingsWrapper from '../components/work-order/export/PrintSettingsWrapper'
import CustomPrintContainer from '../components/work-order/export/CustomPrintContainer'
import PageBreak from '../components/work-order/export/PageBreak'
import { getNotesForMobile } from '../data/notes/notesSelectors'

const ExportWorkOrderListPage = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { routeId } = useParams()
  const serviceDate = searchParams.get('date')
  const [getRawRoutesQuery, { data, isLoading }] = useLazyGetRawRoutesQuery()

  useEffect(() => {
    dispatch(setShowAppBar(false))
    getRawRoutesQuery({ routeId, serviceDate, expandNotes: true })
    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  return (
    <ProtectedRouteHoC>
      <PrintSettingsWrapper>
        <CustomPrintContainer>
          {Array.isArray(data) &&
            !isLoading &&
            data.map(row => {
              const {
                accountName,
                accountNumber,
                city,
                contactName,
                contactPhone,
                containers,
                line1,
                locationName,
                measure,
                serviceAction,
                serviceName,
                state,
                unitNumber,
                workOrderNotes,
                workOrderSequence,
                zipCode,
                purchaseOrderNumber,
              } = row

              const locationNotesList = getNotesForMobile({ notes: get(row, 'locationNotesList', []) })
              const configuredServiceNotesList = getNotesForMobile({ notes: get(row, 'configuredServiceNotesList', []) })
              const workOrderNotesList = getNotesForMobile({ notes: get(row, 'workOrderNotesList', []) })

              return (
                <>
                  <WorkOrderCard
                    accountName={accountName}
                    accountNumber={accountNumber}
                    city={city}
                    contactName={contactName}
                    contactPhone={contactPhone}
                    containers={containers}
                    line1={line1}
                    line2={unitNumber}
                    locationName={locationName}
                    measure={measure}
                    serviceAction={serviceAction}
                    serviceName={serviceName}
                    state={state}
                    locationNotesList={locationNotesList}
                    configuredServiceNotesList={configuredServiceNotesList}
                    workOrderNotesList={workOrderNotesList}
                    workOrderNotes={workOrderNotes}
                    workOrderSequence={workOrderSequence}
                    zipCode={zipCode}
                    purchaseOrderNumber={purchaseOrderNumber}
                  />
                  <Divider sx={{ borderStyle: 'dashed', borderColor: 'border.lightGrey', borderWidth: 2 }} />
                  <WorkOrderCard
                    accountName={accountName}
                    accountNumber={accountNumber}
                    city={city}
                    contactName={contactName}
                    contactPhone={contactPhone}
                    containers={containers}
                    isCustomerCopy={false}
                    line1={line1}
                    line2={unitNumber}
                    locationName={locationName}
                    measure={measure}
                    serviceAction={serviceAction}
                    serviceName={serviceName}
                    state={state}
                    locationNotesList={locationNotesList}
                    configuredServiceNotesList={configuredServiceNotesList}
                    workOrderNotesList={workOrderNotesList}
                    workOrderNotes={workOrderNotes}
                    workOrderSequence={workOrderSequence}
                    zipCode={zipCode}
                    purchaseOrderNumber={purchaseOrderNumber}
                  />
                  <PageBreak />
                </>
              )
            })}
        </CustomPrintContainer>
      </PrintSettingsWrapper>
    </ProtectedRouteHoC>
  )
}

export default ExportWorkOrderListPage
