import { getApp } from 'firebase/app'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { chunk } from 'lodash'
import { store } from 'providers/store'
import { putIsLoading } from 'middleware/actions/response'
import { setInvoiceDownloadFailureDialogState, setPaymentReceiptDownloadFailureDialogState } from 'slices/download/DownloadSlice'
import { hasExceptionImageSuffix } from 'utils/validations'
import { get } from './lodash'
import Constants from '../Constants'
import { cloudStorageErrorHandler } from './error'

const IMAGES_ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png']
const IMPORT_SEQUENCE_EXTENSTION = ['xlsx']

const DOWNLOAD_TYPE = {
  pdf: 'application/pdf',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  png: 'data:image/png',
  json: 'application/json',
}

const { ASSETS_BUCKET_NAME, IMAGE_UPLOADS_PRIVATE_BUCKET_NAME } = Constants

// Return file extension
export const getFileExtension = fileName => {
  if (typeof fileName !== 'string') {
    return fileName
  }

  const lastIndex = fileName.toLowerCase().lastIndexOf('.')

  return lastIndex > -1 ? fileName.substr(lastIndex + 1) : ''
}

// Validate if it is an image by its filename
export const isImage = fileName => IMAGES_ALLOWED_EXTENSIONS.includes(getFileExtension(fileName))
export const isXlsxFormat = fileName => IMPORT_SEQUENCE_EXTENSTION.includes(getFileExtension(fileName))

export const isValidFileSize = (size, maxSize) => size / (1024 * 1024) > parseInt(maxSize.replace(/D/g, ''), 10)

export const downloadFile = (data, fileName, type = 'pdf') => {
  const url = window.URL.createObjectURL(new Blob([data], { type: get(DOWNLOAD_TYPE, type) }))
  // Create A label
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  // Set the download file file name
  link.setAttribute('download', fileName) // or any other extension
  console.log(fileName)
  document.body.appendChild(link)
  link.click()
}

const convertBase64toBlob = (content, contentType = '') => {
  const sliceSize = 512
  // converts base64 to binary
  const byteCharacters = window.atob(content)

  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    byteArrays.push(new Uint8Array(byteNumbers))
  }

  // Create blob & return
  return new Blob(byteArrays, { type: contentType })
}

export const printPreviewBase64PDF = (data, type = 'application/pdf') => {
  let blob = null
  blob = convertBase64toBlob(data, type)
  const blobURL = URL.createObjectURL(blob)
  const windowRef = window.open(blobURL)
  const docRef = windowRef.document
  const scriptRef = document.createElement('script')

  function injectThis() {
    window.print()
  }

  scriptRef.innerHTML = `window.onload = ${injectThis.toString()};`
  docRef.body.appendChild(scriptRef)
}

export const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const requestBlobFromUrl = url => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'

    xhr.addEventListener('load', () => {
      const blob = xhr.response
      resolve(blob)
    })
    xhr.open('GET', url)
    xhr.send()
  })
}

export const getFileNameFromHeaders = (headers, name, date) => {
  try {
    let fileName = headers['content-disposition'].split(';')[1].split('filename=')[1]
    if (/^".+"$/.test(fileName)) {
      fileName = fileName.substr(1).slice(0, -1)
    }
    return fileName
  } catch (e) {
    return [name, date].join('_').replace(/ /g, '_')
  }
}

export const getFileBlob = file => {
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)
  const fileRef = ref(storage, file)
  return getBlob(fileRef)
}

export const getPaymentReceiptBlob = (businessId, accountId, accountTransactionHistoryId) =>
  getFileBlob(`${businessId}/accounts/${accountId}/payments/${accountTransactionHistoryId.toString()}.pdf`)

export const getInvoiceBlob = (invoiceNumber, businessId) => getFileBlob(`${businessId}/INVOICE/${invoiceNumber.toString()}.pdf`)

export const downloadInvoiceBlob = async (invoiceNumber, businessId) =>
  getInvoiceBlob(invoiceNumber, businessId)
    .then(blob => {
      downloadFile(blob, invoiceNumber)
      return Promise.resolve({ invoiceNumber, success: true })
    })
    .catch(() => Promise.resolve({ invoiceNumber, success: false }))

export const downloadInvoiceBlobListInChunks = async (invoiceNumberList, businessId) => {
  let invoiceResult = []
  const invoiceNumberDonwloadListOfChunks = chunk(invoiceNumberList, 2)
  for (const invoiceNumberChunkList of invoiceNumberDonwloadListOfChunks) {
    const dowloadBlobInvoiceList = invoiceNumberChunkList.map(invoiceNumber => downloadInvoiceBlob(invoiceNumber, businessId))
    const response = await Promise.all(dowloadBlobInvoiceList)
    if (Array.isArray(response)) {
      invoiceResult = [...invoiceResult, ...response]
    }
    await new Promise(r => setTimeout(r, 500))
  }

  const failedInvoices = invoiceResult.filter(({ success }) => !success)
  if (failedInvoices.length > 0) {
    store.dispatch(
      setInvoiceDownloadFailureDialogState({ isOpen: true, invoices: failedInvoices.map(({ invoiceNumber }) => invoiceNumber) })
    )
  }
}

const MEDIUM_PHOTO_SUFFIX = '_1024x1024'
const SMALL_PHOTO_SUFFIX = '_260x260'

const PHOTO_SIZE_TO_SUFFIX = {
  default: '',
  medium: MEDIUM_PHOTO_SUFFIX,
  small: SMALL_PHOTO_SUFFIX,
}

const fetchBlobFromBucket = async (storage, path) => {
  try {
    const imageRef = ref(storage, path)
    return getBlob(imageRef)
  } catch (error) {
    console.log(error)
  }
  return null
}

export const getExceptionImageBlob = async fileLocation => {
  const firebaseApp = getApp()
  const bucketStorage = getStorage(firebaseApp, `gs://${IMAGE_UPLOADS_PRIVATE_BUCKET_NAME}`)
  const bucketBlob = await fetchBlobFromBucket(bucketStorage, fileLocation)
  if (bucketBlob) {
    return bucketBlob
  }
  throw new Error('Image not found in bucket')
}

export const getExceptionImageBlobFromPath = async (path, size) => {
  const firebaseApp = getApp()
  try {
    const bucketStorage = getStorage(firebaseApp, `gs://${IMAGE_UPLOADS_PRIVATE_BUCKET_NAME}`)

    const hasSuffixPattern = path ? hasExceptionImageSuffix(path) : false

    let suffix = ''
    if (!hasSuffixPattern && size in PHOTO_SIZE_TO_SUFFIX) {
      suffix = PHOTO_SIZE_TO_SUFFIX[size]
    }

    const imageRef = ref(bucketStorage, `${path}${suffix}`)
    return getBlob(imageRef)
  } catch (error) {
    console.log(error)
  }
}

export const downloadInvoiceGroupBatch = async (businessId, groupId, groupName) => {
  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp, `gs://${ASSETS_BUCKET_NAME}`)
  const invoiceBatchRef = ref(storage, `${businessId}/invoice_batch/${groupId}.json`)

  getBlob(invoiceBatchRef)
    .then(blob => downloadFile(blob, groupName, 'json'))
    .catch(cloudStorageErrorHandler)
}

export const downloadPaymentReceiptBlob = async (businessId, accountId, paymentNumber, accountTransactionHistoryId) =>
  getPaymentReceiptBlob(businessId, accountId, accountTransactionHistoryId)
    .then(blob => {
      downloadFile(blob, paymentNumber)
      return Promise.resolve({ paymentNumber, accountTransactionHistoryId, success: true })
    })
    .catch(() => Promise.resolve({ paymentNumber, accountTransactionHistoryId, success: false }))

export const downloadPaymentReceiptBlobListInChunks = async (businessId, paymentReceiptList, showLoader = false) => {
  if (showLoader) store.dispatch(putIsLoading(true))

  let paymentReceiptResult = []
  const listOfChunks = chunk(paymentReceiptList, 2)
  for (const paymentReceiptChunk of listOfChunks) {
    const dowloadBlobReceiptList = paymentReceiptChunk.map(({ paymentNumber, accountTransactionHistoryId, accountId }) =>
      downloadPaymentReceiptBlob(businessId, accountId, paymentNumber, accountTransactionHistoryId)
    )
    const response = await Promise.all(dowloadBlobReceiptList)
    if (Array.isArray(response)) {
      paymentReceiptResult = [...paymentReceiptResult, ...response]
    }
    await new Promise(r => setTimeout(r, 500))
  }

  const failedPaymentReceipts = paymentReceiptResult.filter(({ success }) => !success)
  if (failedPaymentReceipts.length > 0) {
    store.dispatch(
      setPaymentReceiptDownloadFailureDialogState({
        isOpen: true,
        receipts: failedPaymentReceipts.map(({ paymentNumber }) => paymentNumber),
      })
    )
  }

  if (showLoader) store.dispatch(putIsLoading(false))
}
