import api from 'api'
import { CACHE_TAG_PORTAL_USER_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    unlinkUser: build.mutation({
      query: ({ accountId, payload }) => ({
        method: 'POST',
        url: `/api/v1/core/accounts/${accountId}/portal-users/unlink`,
        body: payload,
      }),
      invalidatesTags: [CACHE_TAG_PORTAL_USER_LIST],
    }),
  }),
})

export const { useUnlinkUserMutation } = extendedApi
