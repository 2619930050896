import React from 'react'
import PropTypes from 'prop-types'

import List from '@mui/material/List'
import { getUnpaidInvoiceStats } from 'utils/takePayments'
import UnpaidStatListItem from './UnpaidStatListItem'

const UnpaidStats = ({ invoices = [] }) => {
  const { totalUnpaidBalance, lastInvoice, oldestUnpaidInvoice } = getUnpaidInvoiceStats(invoices)
  return (
    <List dense disablePadding sx={{ display: 'flex' }}>
      <UnpaidStatListItem label="Unpaid balance" value={totalUnpaidBalance} />
      <UnpaidStatListItem label="Most recent invoice" value={lastInvoice} sx={{ borderLeft: 1, borderRight: 1, borderColor: 'divider' }} />
      <UnpaidStatListItem label="Oldest unpaid invoice" value={oldestUnpaidInvoice} />
    </List>
  )
}

UnpaidStats.propTypes = {
  invoices: PropTypes.array,
}

export default UnpaidStats
