import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getProfileById: build.query({
      query: ({ id }) => ({
        url: 'api/settings/profile/info',
        method: 'POST',
        body: { id },
      }),
    }),
  }),
})

export const { useLazyGetProfileByIdQuery } = extendedApi
