import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Helmet } from 'react-helmet-async'
import PropTypes from 'prop-types'

const OnRampProvider = ({ children }) => {
  const isUserAuthenticated = useSelector(state => state.AuthReducer.isUserAuthenticated)
  const userInfo = useSelector(state => state.AuthReducer.userInfo)
  const email = get(userInfo, 'email')
  return (
    <>
      {isUserAuthenticated && email && (
        <Helmet>
          <script>
            {`(function (w, d, s, o, f, js, fjs) {
            w[o] = w[o] || function () {
              (w[o].q = w[o].q || []).push(arguments)
            };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
          }(window, document, 'script', '_onramp_widget',
            'https://app.onramp.us/api/embed_widget/static/onramp_inapp_sdk_v1.js'));
            _onramp_widget({organization: "d02ad520-86c4-4138-a981-7d0d7bc2d09d",
            user: "${email}", scheme: 'plaintext'});`}
          </script>
          <style>
            {`iframe#or-iframe {
              border: 0!important
            }`}
          </style>
        </Helmet>
      )}

      {children}
    </>
  )
}

OnRampProvider.propTypes = {
  children: PropTypes.node,
}

export default OnRampProvider
