export const actions = {
  ADD_ASSETS: 'ASSETS_SETTINGS_ADD',
  ADD_PRICING_ZONES: 'PRICING_ZONES_SETTINGS_ADD',
  ADD_TAGS: 'TAGS_SETTINGS_ADD',
  GET: {
    LIST_ASSETS: 'LIST_GET_ASSETS',
    ASSET_BY_ID: 'ASSET_BY_GET_ID',
    LIST_PRICING_ZONES: 'LIST_GET_PRICING_ZONES',
    LIST_TAGS: 'LIST_GET_TAGS',
  },
}

export const getTags = (body, onSuccess) => ({
  type: actions.GET.LIST_TAGS,
  payload: { body, onSuccess },
})

export const addTags = (body, onSuccess) => ({
  type: actions.ADD_TAGS,
  payload: { body, onSuccess },
})
