import React, { Children } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { MenuItem, Grid, Box, Card } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import HHTextField from 'components/form-fields/v5/HHTextField'
import FooterButton from 'components/buttons/FooterButton'
import CommonSwitch from 'components/common/CommonSwitch'
import { get } from 'utils/lodash'
import T from 'T'
import Constants from 'Constants'

import CheckboxContainer from './CheckboxContainer'

const { MEDIA_SERVER } = Constants
const { RESIDENTIAL, COMMERCIAL, ROLLOFF } = T
const OPTIONS = [`${RESIDENTIAL}.svg`, `${COMMERCIAL}.svg`, `${ROLLOFF}.svg`]

const AddCategory = ({
  configurations = [],
  handleSave = noop,
  categoryState = {},
  setCategoryState = noop,
  handleCancel = noop,
  businessLines = {},
  handleCheckedValues = noop,
  onHandleNameChange = noop,
  onHandleIconChange = noop,
  editId = '',
  addNew = false,
}) => {
  if (editId !== '') {
    configurations = configurations.find(data => data.id === editId)
  }

  return (
    <Card sx={{ p: '20px 24px' }}>
      <Grid container mb={3}>
        <Grid item xs={1}>
          <HHTextField
            size="small"
            label={T.ICON}
            select
            value={[get(categoryState, 'selectedIcon', '')]}
            onChange={onHandleIconChange}
            sx={{
              maxWidth: 64,
              '& .MuiSelect-select svg': {
                display: 'none',
              },
              '& .MuiSelect-select img': {
                marginTop: 0.5,
              },
            }}
          >
            {Children.toArray(
              OPTIONS.map(option => (
                <MenuItem value={option}>
                  <img src={`${MEDIA_SERVER}Businessline/${option}`} alt="" height="18" />
                  {option === get(categoryState, 'selectedIcon', '') && <DoneIcon sx={{ ml: 1 }} fontSize="small" />}
                </MenuItem>
              ))
            )}
          </HHTextField>
        </Grid>
        <Grid item xs={4}>
          <HHTextField
            fullWidth
            label={`${T.BUSINESS_LINE} ${T.NAME}`}
            placeholder={`${T.BUSINESS_LINE} ${T.NAME}`}
            name="businessLineName"
            autoComplete="off"
            size="small"
            variant="outlined"
            value={get(categoryState, 'businessLineName', '')}
            InputProps={{
              onChange: e => onHandleNameChange(e),
            }}
          />
        </Grid>
        <Grid item xs={7} display="flex" sx={{ justifyContent: 'end' }}>
          {editId === '' && (
            <CommonSwitch
              titlePosition="right"
              title={T.ACTIVE}
              isChecked={get(categoryState, 'newActive', false)}
              onChange={checked => setCategoryState({ newActive: checked, footerFlag: true })}
            />
          )}
        </Grid>
      </Grid>

      {editId === '' && (
        <CheckboxContainer
          records={configurations}
          businessLines={businessLines}
          handleCheckedValues={handleCheckedValues}
          addNew={addNew}
        />
      )}

      {categoryState.footerFlag && (
        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderTop: 1, borderColor: 'border.light' }}
        >
          <FooterButton
            leftButtonTitle={T.CANCEL}
            onClose={handleCancel}
            rightButtonTitle={T.SAVE}
            onProceed={handleSave}
            disabledProceed={categoryState.businessLineName === ''}
          />
        </Box>
      )}
    </Card>
  )
}

AddCategory.propTypes = {
  configurations: PropTypes.array,
  businessLines: PropTypes.object,
  categoryState: PropTypes.object,
  editId: PropTypes.string,
  addNew: PropTypes.bool,
  setCategoryState: PropTypes.func,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
  handleCheckedValues: PropTypes.func,
  onHandleNameChange: PropTypes.func,
  onHandleIconChange: PropTypes.func,
}

export default AddCategory
