import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemText } from '@mui/material'

import HHDisplayMoney from 'components/common/HHDisplayMoney'

const SearchOptionRow = ({ leftPrimaryText = '', leftSecondaryText = '', rightPrimaryText = '', rightSecondaryText = '' }) => {
  return (
    <>
      <ListItem disablePadding sx={{ width: '75%' }}>
        <ListItemText
          primaryTypographyProps={{ noWrap: true, variant: 'h5', fontWeight: 400 }}
          secondaryTypographyProps={{ noWrap: true, variant: 'h6', fontWeight: 400 }}
          primary={leftPrimaryText}
          secondary={leftSecondaryText}
        />
      </ListItem>
      <ListItem disablePadding sx={{ textAlign: 'right', width: '25%' }}>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5', fontWeight: 400 }}
          secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
          primary={<HHDisplayMoney value={rightPrimaryText} />}
          secondary={rightSecondaryText}
        />
      </ListItem>
    </>
  )
}

SearchOptionRow.propTypes = {
  leftPrimaryText: PropTypes.string,
  leftSecondaryText: PropTypes.string,
  rightPrimaryText: PropTypes.string,
  rightSecondaryText: PropTypes.string,
}

export default SearchOptionRow
