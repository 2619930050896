import { styled, TableCell } from '@mui/material'

const StickyTableCell = styled(TableCell)(({ theme, zIndex = 0 }) => ({
  right: 0,
  position: 'sticky',
  borderLeft: `1px solid ${theme.palette.border.light}`,
  borderBottom: `1px solid ${theme.palette.border.light}`,
  background: theme.palette.background.paper,
  zIndex,
}))

export default StickyTableCell
