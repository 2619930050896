import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, ListItemIcon, ListItemText, Popover, Popper, TextField, ListItemButton, MenuItem, Paper } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { shallowEqual, useSelector } from 'react-redux'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import RenderBillingProfileFilterOption from 'components/locations/filters/BillingProfileFilter/RenderBillingProfileFilterOption'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import zIndex from '@mui/material/styles/zIndex'

const WIDTH = 400

const CustomPopper = props => <Popper {...props} placement="bottom-start" sx={{ '&.base-Popper-root': { zIndex: zIndex.modal + 2 } }} />

const BillingProfileFilterMenu = ({
  value,
  onChange = noop,
  defaultSelectedBillingProfiles = [],
  renderOption = RenderBillingProfileFilterOption,
  billingProfileList,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const open = Boolean(popoverAnchorEl)
  const { isSuccess } = useGetAccountMetaQuery()
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const billingProfiles = useMemo(() => billingProfileList || get(customerMeta, 'billingProfiles', []), [billingProfileList, customerMeta])
  const billingProfileIdList = useMemo(() => billingProfiles.filter(({ id }) => id).map(({ id }) => id), [billingProfiles])

  const isControlled = value !== undefined

  const [internalSelectedBillingProfiles, setInternalSelectedBillingProfiles] = useState(defaultSelectedBillingProfiles)
  const selectedBillingProfiles = isControlled ? value : internalSelectedBillingProfiles

  const allBillingProfilesSelected = useMemo(
    () => selectedBillingProfiles.length === billingProfileIdList.length,
    [selectedBillingProfiles, billingProfileIdList]
  )
  const billingProfilesButtonLabel = useMemo(() => {
    if (selectedBillingProfiles.length === 0) return 'No billing profiles'
    return 'Billing profiles'
  }, [selectedBillingProfiles])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleOpen = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(null)
  }

  const getOptionLabel = option => get(option, 'profile')

  const toggleAllBillingSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelectedBillingProfiles = allBillingProfilesSelected ? [] : [...billingProfiles]
      if (isControlled) {
        onChange(newSelectedBillingProfiles)
      } else {
        setInternalSelectedBillingProfiles(newSelectedBillingProfiles)
        onChange(newSelectedBillingProfiles)
      }
    },
    [isControlled, allBillingProfilesSelected, billingProfiles]
  )

  const handleChange = (event, newSelectedBillingProfiles) => {
    if (isControlled) {
      onChange(newSelectedBillingProfiles)
    } else {
      setInternalSelectedBillingProfiles(newSelectedBillingProfiles)
      onChange(newSelectedBillingProfiles)
    }
  }

  useEffect(() => {
    if (!isInitialized && isSuccess) {
      setInternalSelectedBillingProfiles(defaultSelectedBillingProfiles)
      setIsInitialized(true)
    }
  }, [isInitialized, isSuccess, billingProfiles, defaultSelectedBillingProfiles])

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton disableGutters divider selected={allBillingProfilesSelected} onMouseDown={toggleAllBillingSelection}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allBillingProfilesSelected} />
            </ListItemIcon>
            <ListItemText primary={allBillingProfilesSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allBillingProfilesSelected, toggleAllBillingSelection]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  const dropdownHeight = get(rest, 'dropdownHeight', 'calc(100vh - 272px)')
  const maxHeight = get(rest, 'autoHeight') ? null : { maxHeight: dropdownHeight }

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedBillingProfiles.length} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 110 }}>{billingProfilesButtonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="billing-profile-filter-menu-popover"
        open={open}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        transitionDuration={{ appear: 0, exit: 0 }}
        TransitionProps={{ onEntered: focusAutocomplete }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
      >
        <Autocomplete
          multiple
          options={billingProfiles}
          sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
          fullWidth
          componentsProps={{
            paper: { allBillingProfilesSelected, toggleAllBillingSelection },
            popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
          }}
          ListboxProps={{
            sx: { ...maxHeight, py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
          }}
          open={open}
          disableClearable
          onChange={handleChange}
          PopperComponent={CustomPopper}
          PaperComponent={SelectAllPaperComponent}
          renderTags={() => <></>}
          value={selectedBillingProfiles}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption}
          renderInput={params => (
            <TextField {...params} placeholder="Type to filter billing profiles" inputRef={inputRef} variant="standard" />
          )}
          {...rest}
        />
      </Popover>
    </>
  )
}

BillingProfileFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedBillingProfiles: PropTypes.array,
  renderOption: PropTypes.func,
  billingProfileList: PropTypes.array,
}

export default BillingProfileFilterMenu
