import React, { useEffect } from 'react'
import AccountsReceivable from 'containers/billing/accounts-receivable'
import { useDispatch, useSelector } from 'react-redux'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import { setShowAppBar } from '../slices/appbar/appbarSlice'
import { resetARState, setResetARStateOnUnmount } from '../slices/billing/accountsReceivableSlice'

const AccountsReceivablePage = () => {
  const dispatch = useDispatch()
  const resetOnUnmount = useSelector(state => state.accountsReceivable.resetOnUnmount)

  useEffect(() => {
    dispatch(setResetARStateOnUnmount(true))
    dispatch(setShowAppBar(false))
    return () => {
      dispatch(setShowAppBar(true))
    }
  }, [])

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        dispatch(resetARState())
      }
    }
  }, [resetOnUnmount])

  return (
    <ProtectedRouteHoC>
      <AccountsReceivable />
    </ProtectedRouteHoC>
  )
}

AccountsReceivablePage.propTypes = {}

export default AccountsReceivablePage
