import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Popover,
  Popper,
  TextField,
  ListItemButton,
  MenuItem,
  Paper,
  ClickAwayListener,
  CircularProgress,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import zIndex from '@mui/material/styles/zIndex'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop, orderBy, lowerCase, uniqBy, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { shallowEqual, useSelector } from 'react-redux'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { getTagsWithRoutesAndStopCount } from 'data/tags/tagsMetadataSelector'
import { useGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useLazyGetRouteStopsUnassignedQuery } from 'api/route/getRouteStopsUnassigned'
import { selectRouteSerializedServiceDate } from 'slices/route/routeSlice'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { UNASSIGNED_ROUTE } from 'containers/new-route-manager/settings'
import RenderTagFilterOption from './RenderTagFilterOption'
import RouteFilterByTags from '../route-filter/RouteFilterByTags'
import { generateFilterLabel } from '../settings'

const WIDTH = 350

const CustomPopper = props => <Popper {...props} placement="bottom-start" sx={{ '&.base-Popper-root': { zIndex: zIndex.modal + 2 } }} />

const TagFilterMenu = ({ isParentFilter = false, routeId = '', value, onChange = noop, RenderOption = RenderTagFilterOption, ...rest }) => {
  const [isRouteMetaAvailable, setIsRouteMetaAvailable] = useState(false)
  const { setValue, watch } = useFormContext()
  const watchSelectedTags = watch('selectedTags')
  const watchSelectedRoutesByTagId = watch('selectedRoutesByTagId')
  const inputRef = useRef(null)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const metaRoutes = useSelector(state => get(state, 'Route.allRoutes', []), shallowEqual)
  const metaRouteIds = useMemo(() => metaRoutes.map(({ id }) => id), [metaRoutes])
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [filterMenu, setFiltermenu] = useState({ anchorEl: null, position: null, filteredRoutes: [], tagId: '' })
  const { anchorEl, position, filteredRoutes } = filterMenu

  const { isSuccess: isAccountMetaSuccess } = useGetAccountMetaQuery()
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const [getRouteStops, { isSuccess: isGetRouteStopsSuccess, data, isFetching: isGetRouteStopsLoading }] = useLazyGetRouteStopsQuery()
  const [
    getRouteStopsUnassigned,
    { isSuccess: isGetRouteStopsUnassignedSuccess, data: unassignedData, isFetching: isGetRouteStopsUnassignedLoading },
  ] = useLazyGetRouteStopsUnassignedQuery()
  const routesData = useMemo(
    () => [{ ...UNASSIGNED_ROUTE, stops: get(unassignedData, 'stops', []) }, ...get(data, 'routes', [])],
    [data, unassignedData]
  )
  const allRoutes = useMemo(() => (routeId ? routesData.filter(({ id }) => id === routeId) : routesData), [routeId, routesData])
  const metaTags = useMemo(() => uniqBy(get(customerMeta, 'tags', []), 'id'), [customerMeta])
  const withNoTagsOption = useMemo(() => [NO_TAGS_OPTION, ...metaTags], [metaTags])
  const tagsWithRoutesAndStopCount = getTagsWithRoutesAndStopCount({ tags: withNoTagsOption, routes: allRoutes })
  const sortedTags = useMemo(
    () =>
      orderBy(
        tagsWithRoutesAndStopCount,
        ['order', 'active', row => row?.stopCount > 0, row => lowerCase(row?.tagName)],
        ['asc', 'desc', 'desc', 'asc']
      ),
    [tagsWithRoutesAndStopCount]
  )
  const skipTagsWithZeroStops = useMemo(() => sortedTags.filter(({ stopCount }) => stopCount > 0), [sortedTags])
  const tags = useMemo(() => (routeId ? skipTagsWithZeroStops : sortedTags), [routeId, skipTagsWithZeroStops, sortedTags])
  const tagIdList = useMemo(() => tags.map(({ id }) => id), [tags])
  const isControlled = useMemo(() => value, [value])
  const [internalSelectedTags, setInternalSelectedTags] = useState([])
  const selectedTags = useMemo(() => (isControlled ? value : internalSelectedTags), [isControlled, internalSelectedTags, value])
  const allTagsSelected = useMemo(() => selectedTags.length === tagIdList.length, [selectedTags, tagIdList])
  const selectedTagsCount = useMemo(() => selectedTags.length, [selectedTags])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [inputRef])

  const handleOpen = event => setPopoverAnchorEl(event.currentTarget)

  const getOptionLabel = option => get(option, 'tagName')

  const handleClose = () => setPopoverAnchorEl(null)

  const toggleAllTagSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelectedTags = allTagsSelected ? [] : [...tags]
      if (isControlled) {
        onChange(newSelectedTags)
      } else {
        setInternalSelectedTags(newSelectedTags)
      }
    },
    [isControlled, allTagsSelected, tags, watchSelectedRoutesByTagId]
  )

  const handleChange = (event, newSelectedTags) => {
    event.stopPropagation()
    if (isControlled) {
      onChange(newSelectedTags)
    } else {
      setInternalSelectedTags(newSelectedTags)
      onChange(newSelectedTags)
    }
  }

  const handleClosFilterMenu = useCallback(() => {
    setFiltermenu({ anchorEl: null, position: null, filteredRoutes: [] })
  }, [])

  const handleMouseLeave = event => {
    event.stopPropagation()
    const relatedTarget = event?.relatedTarget
    if (relatedTarget?.classList?.[0] === 'MuiBackdrop-root') {
      handleClosFilterMenu()
    }
  }

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton
            disableGutters
            divider
            selected={allTagsSelected}
            onMouseDown={toggleAllTagSelection}
            onMouseOver={handleClosFilterMenu}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allTagsSelected} />
            </ListItemIcon>
            <ListItemText primary={allTagsSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allTagsSelected, toggleAllTagSelection]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionId = get(option, 'id')
    const valueId = get(value, 'id')
    return optionId && optionId === valueId
  }

  const dropdownHeight = get(rest, 'dropdownHeight', 'calc(100vh - 340px)')
  const maxHeight = get(rest, 'autoHeight') ? null : { maxHeight: dropdownHeight }
  const anchorOrigin = get(rest, 'anchorOrigin', { vertical: 'top', horizontal: 'left' })
  const transformOrigin = get(rest, 'transformOrigin', { vertical: 'top', horizontal: 'right' })

  const handleOpenFilterMenu = (event, routeList, id) => {
    const target = event.currentTarget
    if (anchorEl !== target) {
      const popoverTop = target?.getBoundingClientRect().top || 0
      const popoverLeft = target?.getBoundingClientRect().left || 0
      setFiltermenu({ anchorEl: target, position: { top: popoverTop, left: popoverLeft }, tagId: id, filteredRoutes: routeList })
    }
  }

  useEffect(() => {
    if (serviceDate && isRouteMetaAvailable) {
      if (isParentFilter) {
        setValue('selectedRoutesByTagId', null)
      }
      setValue('isFiltersLoading', true)

      const stopServiceDate = formatDateToBEFormatDateFns(serviceDate)

      Promise.all([
        getRouteStopsUnassigned({ serviceDate: stopServiceDate }, !isGetRouteStopsUnassignedSuccess),
        getRouteStops({ routes: sortBy(metaRouteIds), serviceDate: stopServiceDate }, !isGetRouteStopsSuccess),
      ]).then(() => setValue('isFiltersLoading', false))
    }
  }, [serializedServiceDate, isRouteMetaAvailable])

  useEffect(() => {
    if (metaRouteIds?.length > 0) {
      setIsRouteMetaAvailable(true)
    }
  }, [metaRouteIds])

  useEffect(() => {
    if (
      isAccountMetaSuccess &&
      isGetRouteStopsSuccess &&
      isGetRouteStopsUnassignedSuccess &&
      !isGetRouteStopsLoading &&
      !isGetRouteStopsUnassignedLoading &&
      isParentFilter
    ) {
      setValue('allTags', sortedTags)
      const allSelectedTagIds = selectedTags.map(({ id }) => id)
      const newTags = watchSelectedTags?.length === 0 ? sortedTags : sortedTags.filter(({ id }) => allSelectedTagIds.includes(id))
      setValue('selectedTags', newTags)
      const selectedRoutesByTagId = {}
      newTags.forEach(({ id: tagId, allRoutes: linkedRoutes }) => {
        selectedRoutesByTagId[tagId] = linkedRoutes.map(({ id }) => id)
      })
      setValue('selectedRoutesByTagId', selectedRoutesByTagId)
    }
  }, [
    isAccountMetaSuccess,
    isGetRouteStopsSuccess,
    isGetRouteStopsUnassignedSuccess,
    isGetRouteStopsLoading,
    isGetRouteStopsUnassignedLoading,
  ])

  const buttonLabel = useMemo(() => generateFilterLabel(tags, selectedTags, 'tag'), [tags, selectedTags])

  const isLoading = isGetRouteStopsLoading || isGetRouteStopsUnassignedLoading

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen} disabled={isLoading}>
        <ListItemIcon>
          {isLoading ? (
            <CircularProgress color="action" size={20} thickness={4} />
          ) : (
            <StyledBadge overlap="circular" badgeContent={selectedTagsCount} color="primary">
              <FilterListIcon color="primary" />
            </StyledBadge>
          )}
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 50 }}>{buttonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="tag-filter-menu-popover"
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClose}
        TransitionProps={{ onEntered: focusAutocomplete }}
        transitionDuration={{ appear: 0, exit: 0 }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
        onMouseLeave={handleMouseLeave}
      >
        <ClickAwayListener onClickAway={handleClosFilterMenu}>
          <Autocomplete
            multiple
            options={tags}
            sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
            fullWidth
            componentsProps={{
              paper: { allTagsSelected, toggleAllTagSelection },
              popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
            }}
            ListboxProps={{
              sx: { ...maxHeight, py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
            }}
            open={Boolean(popoverAnchorEl)}
            disableClearable
            onChange={handleChange}
            PopperComponent={CustomPopper}
            PaperComponent={SelectAllPaperComponent}
            renderTags={() => <></>}
            value={selectedTags}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option, { selected }) => (
              <RenderOption
                props={props}
                option={option}
                selected={selected}
                onOpenFilterMenu={handleOpenFilterMenu}
                isParentFilter={isParentFilter}
              />
            )}
            renderInput={params => <TextField {...params} placeholder="Type to filter tags" inputRef={inputRef} variant="standard" />}
            {...rest}
          />
        </ClickAwayListener>
      </Popover>

      {isParentFilter && position && filteredRoutes.length > 0 && (
        <RouteFilterByTags
          allTags={tags}
          anchorEl={position}
          tagId={filterMenu.tagId}
          filteredRoutes={filteredRoutes}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  )
}

TagFilterMenu.propTypes = {
  isParentFilter: PropTypes.bool,
  routeId: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  RenderOption: PropTypes.func,
}

export default TagFilterMenu
