import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { lightBlue } from '@mui/material/colors'

import RenderAccountCell from './RenderAccountCell'

const AccountsDataGrid = ({ rows = [], rowSelectionModel = [], onRowSelectionModelChange, onInviteAll }) => {
  const getRowHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ accountId }) => accountId, [])

  return (
    <DataGridPro
      rows={rows}
      hideFooter
      columnHeaderHeight={0}
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: params => <RenderAccountCell row={params.row} onInviteAll={onInviteAll} />,
          sortable: false,
          resizable: false,
        },
      ]}
      disableMultipleRowSelection
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      sx={{
        border: 'none',
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row': {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: `${lightBlue[700]}14`,
          },
        },
      }}
      slots={{ noRowsOverlay: () => null }}
    />
  )
}

AccountsDataGrid.propTypes = {
  rows: PropTypes.array,
  rowSelectionModel: PropTypes.array,
  onRowSelectionModelChange: PropTypes.func.isRequired,
  onInviteAll: PropTypes.func.isRequired,
}

export default AccountsDataGrid
