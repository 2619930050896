import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getPricingPeriod } from 'utils/service'
import { replaceAllSpacesAndAmpersand } from 'utils/string'
import { getServiceType } from 'components/pricing/pricingMethods'

import T from 'T'
import Constants from 'Constants'
import TableSkelton from 'components/common/TableSkelton'
import CommonNumberFormat from 'components/common/CommonNumberFormat'

const { MEDIA_SERVER } = Constants

const ServiceListing = ({
  isLoading = false,
  isSelectedMode = false,
  pricedServices,
  currentSelectedServices = [],
  allSelectedActions = [],
  totalServices = 0,
  onChange,
}) => {
  const theme = useTheme()
  const handleServiceSelectDeselect = (actionType, pricedService) => {
    if (isSelectedMode) {
      onChange(
        currentSelectedServices.filter(service => service.id !== get(pricedService, 'id')),
        'remove'
      )
      return
    }

    // Check for unique action
    const isDuplicateAction = allSelectedActions.includes(actionType)
    if (isDuplicateAction) {
      toast.dismiss()
      toast.error(`Service with ${actionType} already selected`)
      return
    }

    onChange([...currentSelectedServices, pricedService], 'add')
  }

  return (
    <>
      <Box
        mt={4}
        sx={{
          border: `1px dashed ${theme.palette.border.secondary}`,
          borderRadius: 6,
          '&.default': {
            // Right content should scroll independently
            height: `calc(100vh - 225px)`,
          },
          '&.selected': {
            border: `1px solid ${theme.palette.primary.main}`,
            minHeight: `50%`,
            maxHeight: `calc(100vh - 225px)`,
          },
        }}
        className={`${isSelectedMode ? 'selected' : 'default'}`}
        padding={1}
      >
        <Box
          sx={{
            marginTop: -20,
            '& .totalServiceText': {
              background: theme.background.default,
              color: theme.palette.text.secondary,
              padding: 4,
              '&.selected': {
                color: theme.palette.primary.main,
              },
            },
          }}
          position="absolute"
        >
          <Typography fontWeight={500} className={`totalServiceText ${isSelectedMode ? 'selected' : ''}`} variant="h6">
            {`${isSelectedMode ? T.SELECTED_SERVICES : T.TOTAL_SERVICES} (${totalServices})`}
          </Typography>
        </Box>

        <Box mt={2} sx={{ maxHeight: 'calc(100vh - 265px)' }} className="transparent-scroll">
          {isLoading && !isSelectedMode && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                '& td': {
                  border: 'none',
                },
              }}
              component="span"
            >
              <Box component="table" width="100%">
                <TableSkelton rows={3} columns={1} />
              </Box>
            </Box>
          )}

          {!isLoading && !isSelectedMode && totalServices === 0 && (
            <Box
              p={2}
              textAlign="center"
              sx={{
                border: `1px solid ${theme.palette.border.secondary}`,
                borderRadius: 4,
              }}
            >
              <Box component="span" className="empty">
                {T.NO_CS_PRICING_SERVICE}
              </Box>
            </Box>
          )}

          {!isLoading &&
            pricedServices.length > 0 &&
            Children.toArray(
              pricedServices.map(pricedService => {
                const periodPer = get(pricedService, 'periodPer')
                const periodInterval = get(pricedService, 'periodInterval')
                const price = get(pricedService, 'value')

                const materialType = get(pricedService, 'material.materialType', '')
                const businessLine = get(pricedService, 'businessLine', '')
                const businessLineIcon = get(pricedService, 'businessLineIcon', '')
                const serviceType = getServiceType(pricedService)
                const volume = get(pricedService, 'measure.volume', '')
                const actionType = get(pricedService, 'action.actionType', '')

                return (
                  <Box
                    component="span"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      border: `1px solid ${theme.palette.border.secondary}`,
                      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08)',
                      borderRadius: 4,
                    }}
                    padding={1}
                    marginBottom={1}
                    onClick={() => handleServiceSelectDeselect(actionType, pricedService)}
                  >
                    <Box className="left-section">
                      <Typography fontWeight={500} variant="h6" className="lh-copy">
                        {get(pricedService, 'serviceName', '')}
                      </Typography>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{
                          '& img': {
                            verticalAlign: 'text-bottom',
                          },
                        }}
                      >
                        {businessLine}

                        {serviceType && (
                          <>
                            {' - '}
                            {serviceType}
                          </>
                        )}

                        {volume && (
                          <>
                            {serviceType && ' - '}
                            {`${volume} ${get(pricedService, 'measure.unit', '')}`}
                          </>
                        )}
                      </Typography>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{
                          '& img': {
                            verticalAlign: 'text-bottom',
                          },
                        }}
                      >
                        {materialType}
                        {businessLineIcon && (
                          <>
                            {materialType && ' - '}
                            <img className="icon-w-16" src={`${MEDIA_SERVER}Businessline/Secondary/${businessLineIcon}`} alt="" />
                          </>
                        )}
                        {actionType && (
                          <>
                            {(materialType || businessLineIcon) && ' - '}
                            <img
                              className="icon-w-16"
                              src={`${MEDIA_SERVER}Action/Secondary/${replaceAllSpacesAndAmpersand(actionType)}.svg`}
                              alt=""
                            />
                          </>
                        )}
                      </Typography>
                    </Box>

                    <Box className="right-section">
                      <Typography fontWeight={500} variant="h6" className="lh-copy tr">
                        <CommonNumberFormat value={price} displayType="text" prefix="$" />
                      </Typography>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        sx={{
                          '& img': {
                            verticalAlign: 'text-bottom',
                          },
                        }}
                      >
                        {getPricingPeriod(periodPer, periodInterval)}
                      </Typography>
                    </Box>
                  </Box>
                )
              })
            )}
        </Box>
      </Box>
    </>
  )
}

ServiceListing.propTypes = {
  isLoading: PropTypes.bool,
  isSelectedMode: PropTypes.bool,
  pricedServices: PropTypes.array,
  currentSelectedServices: PropTypes.array,
  allSelectedActions: PropTypes.array,
  totalServices: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default memo(ServiceListing)
