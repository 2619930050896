import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    createServiceGroup: builder.mutation({
      query: body => ({ url: '/api/v1/core/priced-service-groups', method: 'POST', body }),
    }),
  }),
})

export const { useCreateServiceGroupMutation } = extendedApi
