import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box, LinearProgress, useMediaQuery, useTheme, darken, lighten } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import AgingBucketDialog from 'components/common/aging-bucket/InteractiveAgingBucket/AgingBucketDialog'
import CustomAccountRow from 'components/customers/accounts/CustomAccountRow'
import { BACKGROUND } from 'theme/colors'
import ConfirmAccountReactivatedDialog from 'components/customers/accounts/ConfirmAccountReactivatedDialog'
import { useDispatch } from 'react-redux'
import { closeConfirmAccountReactivatedDialog } from 'slices/customer/customerCellSlice'
import RenderAccountCell from './RenderAccountCell'
import DetailPanelContent from './common/expansion-panel'
import NoOptionsFound from './NoOptionsFound'
import { ACCOUNTS_DESKTOP_ROW_HEIGHT, ACCOUNTS_MOBILE_ROW_HEIGHT, getAccountRowClassName } from './settings'

const AccountsDataGrid = ({ isLoading = false, rows = [], apiRef }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { watch, setValue } = useFormContext()
  const expandedRowIds = watch('expandedRowIds')

  const getDetailPanelHeight = useCallback(() => 'auto', [])

  const getDetaiilPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, [])

  const getRowId = useCallback(({ accountId }) => accountId, [])

  const getRowHeight = useCallback(() => {
    return isTabletOrMobile ? ACCOUNTS_MOBILE_ROW_HEIGHT : ACCOUNTS_DESKTOP_ROW_HEIGHT
  }, [isTabletOrMobile])

  const getRowSpacing = useCallback(
    params => ({
      bottom: params.isLastVisible ? 128 : 0,
    }),
    []
  )

  const getRowClassName = useCallback(getAccountRowClassName, [])

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.1))

  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => setValue('expandedRowIds', newIds), [])

  useEffect(() => {
    dispatch(closeConfirmAccountReactivatedDialog())
  }, [])

  return (
    <Box flex="1" position="relative">
      <Box sx={{ position: 'absolute', inset: 0 }}>
        <DataGridPro
          loading={isLoading}
          apiRef={apiRef}
          hideFooter
          rows={rows}
          getRowId={getRowId}
          rowHeight={70}
          getRowHeight={getRowHeight}
          getDetailPanelContent={getDetaiilPanelContent}
          getDetailPanelHeight={getDetailPanelHeight} // Height based on the content.
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
          columns={[
            {
              field: 'id',
              flex: 1,
              resizable: false,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              renderCell: params => <RenderAccountCell {...params} showAddToGroup />,
            },
          ]}
          columnHeaderHeight={0}
          disableRowSelectionOnClick
          slots={{
            noRowsOverlay: () => (
              <Box mt={4}>
                <NoOptionsFound />
              </Box>
            ),
            detailPanelExpandIcon: ChevronRightIcon,
            detailPanelCollapseIcon: ExpandMoreIcon,
            loadingOverlay: LinearProgress,
            row: CustomAccountRow,
          }}
          getRowSpacing={getRowSpacing}
          getRowClassName={getRowClassName}
          sx={{
            borderTop: 0,
            borderRadius: 0,
            '& .MuiDataGrid-cell , & .MuiDataGrid-row': {
              border: 0,
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell, & .MuiDataGrid-cell:hover, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell.Mui-hovered, ': {
              background: 'transparent',
            },
            '& .MuiDataGrid-row, & .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover, & .MuiDataGrid-row:focus-within ': {
              '&.hold': {
                background: BACKGROUND.hold,
                '&:hover, .deactivated-bg-indicator': {
                  backgroundColor: getHoverBackgroundColor(BACKGROUND.hold, theme.palette.mode),
                },
              },
              '&.suspended': {
                background: BACKGROUND.suspension,
                '&:hover, .deactivated-bg-indicator': {
                  backgroundColor: getHoverBackgroundColor(BACKGROUND.suspension, theme.palette.mode),
                },
              },
              '&.deactivated': {
                background: BACKGROUND.deactivated,
                '&:hover, .deactivated-bg-indicator': {
                  backgroundColor: getHoverBackgroundColor(BACKGROUND.deactivated, theme.palette.mode),
                },
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'hidden',
            },
          }}
        />
        <ConfirmAccountReactivatedDialog />
        <AgingBucketDialog />
      </Box>
    </Box>
  )
}

AccountsDataGrid.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
}

export default AccountsDataGrid
