import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { noop, capitalize } from 'lodash'

import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { DialogContent, Stack, Grid, InputAdornment, SvgIcon, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ReactComponent as ArrowSpin } from 'assets/ArrowSpin.svg'
import { ReactComponent as CalendarWeek } from 'assets/CalendarWeek.svg'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyFetchMetaFiltersQuery } from 'api/meta/getMetaFilters'
import { getBusinessLineDropdownOptions } from 'data/business-line/businessLineSelector'
import {
  RENTAL_FEE_GRACE_PERIOD,
  RENTAL_FEE_GRACE_PERIOD_OPTIONS,
  RENTAL_FEE_PRICING_PERIOD,
  RENTAL_FEE_PRICING_PERIOD_OPTIONS,
  NONE_BUSINESS_LINE_OPTION,
} from 'components/pricing/RentalFees/common/settings'
import {
  formatDataToPayloadCreateUpdateRentalFee,
  transformServerDataToCreateUpdateRentalFeeForm,
} from 'components/pricing/RentalFees/common/utils'
import { HHFormAutocompleteField, HHFormPriceField, HHFormSelectField, HHFormTextField, HHFormNumberField } from 'components/form-fields/v5'
import { useCreateRentalFeeMutation, useUpdateRentalFeeMutation } from 'api/pricing/rentalFeesCrud'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import HHDialogActions from 'components/common/HHDialogActions'

const defaultValues = {
  feeName: '',
  amount: '',
  businessLine: NONE_BUSINESS_LINE_OPTION,
  gracePeriodDays: RENTAL_FEE_GRACE_PERIOD.NONE,
  customGracePeriodDays: '',
  pricingPeriod: RENTAL_FEE_PRICING_PERIOD.ONCE,
}

const CreateEditRentalFeeDialog = ({ isOpen = false, rentalFee = null, onClose = noop }) => {
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues })

  const existingRentalFeeId = get(rentalFee, 'id')
  const watchGracePeriodDays = watch('gracePeriodDays')
  const feeNameHelperText = get(errors, 'feeName.message')
  const amountHelperText = get(errors, 'amount.message')
  const customGracePeriodDaysHelperText = get(errors, 'customGracePeriodDays.message')
  const isCustomGracePeriod = watchGracePeriodDays === RENTAL_FEE_GRACE_PERIOD.CUSTOM

  const [fetchMetaFilters, { isFetching: isMetaFiltersLoading, data: metaFiltersData }] = useLazyFetchMetaFiltersQuery()
  const [createRentalFee, { isLoading: isCreateRentalFeeLoading }] = useCreateRentalFeeMutation()
  const [updateRentalFee, { isLoading: isUpdateRentalFeeLoading }] = useUpdateRentalFeeMutation()

  const isLoading = useMemo(
    () => isCreateRentalFeeLoading || isUpdateRentalFeeLoading,
    [isCreateRentalFeeLoading, isUpdateRentalFeeLoading]
  )

  const getOptionLabel = ({ label }) => label

  const businessLineOptions = getBusinessLineDropdownOptions({
    businessLines: get(metaFiltersData, 'businessLines', []),
    includeNoneOption: true,
  })

  const handleGracePeriodChange = () => {
    setValue('customGracePeriod', '')
  }

  const handleClose = () => {
    onClose()
    reset(defaultValues)
  }

  const onSave = async data => {
    const payload = formatDataToPayloadCreateUpdateRentalFee(data)
    if (existingRentalFeeId) {
      updateRentalFee({ ...payload, id: existingRentalFeeId })
        .unwrap()
        .then(() => {
          toast.success(T.RENTAL_FEE_UPDATED_SUCCESSFULLY)
          handleClose()
        })
        .catch(handleError)
      return
    }
    createRentalFee(payload)
      .unwrap()
      .then(() => {
        toast.success(T.RENTAL_FEE_CREATED_SUCCESSFULLY)
        handleClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      const formModel = existingRentalFeeId ? transformServerDataToCreateUpdateRentalFeeForm(rentalFee) : defaultValues
      reset(formModel)
      fetchMetaFilters({ body: {} })
    }
  }, [isOpen, existingRentalFeeId])

  return (
    <HHBaseDialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={existingRentalFeeId ? T.EDIT_RENTAL_FEE_DIALOG : T.CREATE_RENTAL_FEE_DIALOG} onClose={onClose} />
      <DialogContent>
        <Stack direction="column" rowGap={3} sx={{ mt: 1 }}>
          <HHFormTextField
            required
            rules={{ required: T.THIS_FIELD_IS_REQUIRED }}
            deprecatedLabel={false}
            control={control}
            name="feeName"
            label={T.NAME}
            fullWidth
            helperText={feeNameHelperText}
            error={Boolean(feeNameHelperText)}
          />
          <HHFormAutocompleteField
            label={capitalize(T.BUSINESS_LINE)}
            deprecatedLabel={false}
            name="businessLine"
            control={control}
            options={businessLineOptions}
            loading={isMetaFiltersLoading}
            getOptionLabel={getOptionLabel}
            helperText="Optional*"
          />
          <HHFormPriceField
            required
            rules={{ required: T.THIS_FIELD_IS_REQUIRED }}
            name="amount"
            control={control}
            deprecatedLabel={false}
            label={`${T.AMOUNT}*`}
            helperText={amountHelperText}
            error={Boolean(amountHelperText)}
          />
          <Grid container columnGap={2}>
            <Grid item xs>
              <HHFormSelectField
                deprecatedLabel={false}
                required
                label={capitalize(T.GRACE_PERIOD)}
                fullWidth
                control={control}
                name="gracePeriodDays"
                onChange={handleGracePeriodChange}
                options={RENTAL_FEE_GRACE_PERIOD_OPTIONS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <CalendarWeek />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs>
              <HHFormSelectField
                deprecatedLabel={false}
                label={capitalize(T.PRICING_PERIOD)}
                fullWidth
                control={control}
                name="pricingPeriod"
                options={RENTAL_FEE_PRICING_PERIOD_OPTIONS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <ArrowSpin />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start" sx={{ mr: 2.5, cursor: 'pointer' }}>
                      <Tooltip title="The cadence of the fee" placement="top">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {isCustomGracePeriod && (
            <Grid container columnGap={2}>
              <Grid item xs>
                <HHFormNumberField
                  allowNegative={false}
                  name="customGracePeriodDays"
                  label={`${T.CUSTOM_GRACE_PERIOD}*`}
                  fullWidth
                  deprecatedLabel={false}
                  required
                  rules={{ required: T.THIS_FIELD_IS_REQUIRED }}
                  control={control}
                  placeholder="Enter custom days"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography color="textSecondary">days</Typography>
                      </InputAdornment>
                    ),
                  }}
                  helperText={customGracePeriodDaysHelperText}
                  error={Boolean(customGracePeriodDaysHelperText)}
                />
              </Grid>
              <Grid item xs />
            </Grid>
          )}
        </Stack>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isLoading}
          disabled={isLoading}
          label={existingRentalFeeId ? T.SAVE : T.CREATE}
          size="small"
          onClick={handleSubmit(onSave)}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

CreateEditRentalFeeDialog.propTypes = {
  isOpen: PropTypes.bool,
  rentalFee: PropTypes.object,
  onClose: PropTypes.func,
}

export default CreateEditRentalFeeDialog
