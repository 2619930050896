import { get, uniqBy } from 'lodash'
import { createSelector } from 'reselect'

export const getSelectedBusinessId = state => get(state, 'LoginPersist.selectedLoginDetails.businessId', '')

// get First business details
export const getFirstAccountDetails = createSelector([state => state?.allBusinesses], (allBusinesses = []) => {
  const business = get(allBusinesses, '[0]', {})
  return {
    businessTitle: get(business, 'businessTitle'),
    businessName: get(business, 'businessName'),
    businessId: get(business, 'businessId'),
  }
})

// get all businesses
export const getAllBusinesses = (businesses = []) => {
  return uniqBy(
    businesses.map(row => ({ businessName: row.businessName, businessId: row.id, businessTitle: row.businessTitle })),
    'businessId'
  )
}
