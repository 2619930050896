import React from 'react'
import ProtectedRouteHoC from 'router/ProtectedRouteHoC'
import NavbarWrapper from 'components/common/NavbarWrapper'
import SuspensionGroups from 'containers/customers/groups/suspension/SuspensionGroups'

const SuspensionGroupsPage = () => {
  return (
    <ProtectedRouteHoC>
      <NavbarWrapper sx={{ backgroundColor: 'background.paper' }} innerSx={{ overflow: 'auto' }}>
        <SuspensionGroups />
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

SuspensionGroupsPage.propTypes = {}

export default SuspensionGroupsPage
