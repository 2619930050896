import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { blue, red, green } from '@mui/material/colors'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import T from 'T'
import { useGetInvoiceGroupEmailSummaryQuery } from 'api/groups/invoice/getInvoiceGroupEmailSummary'
import { get } from 'lodash'
import GroupPieChart from '../common/GroupPieChart'
import GroupChartOverlay from '../common/GroupChartOverlay'
import GroupChartName from '../common/GroupChartName'
import GraphCardEmptyState from '../common/GraphCardEmptyState'

const COLOR_MAP = {
  Viewed: blue[100],
  Clicked: green[100],
  Bounced: red[100],
}

const InvoiceEmailStatistic = ({ id, showFooter = true }) => {
  const { data: emailSummaryData } = useGetInvoiceGroupEmailSummaryQuery({ id })
  const bounced = get(emailSummaryData, 'bounced', 0)
  const clicked = get(emailSummaryData, 'clicked', 0)
  const opened = get(emailSummaryData, 'opened', 0)
  const total = bounced + clicked + opened
  const isChartEmpty = total === 0
  const data = [
    {
      id: 'Viewed',
      label: 'Viewed',
      value: opened,
      color: COLOR_MAP.Viewed,
    },
    {
      id: 'Clicked',
      label: 'Clicked',
      value: clicked,
      color: COLOR_MAP.Clicked,
    },
    {
      id: 'Bounced',
      label: 'Bounced',
      value: bounced,
      color: COLOR_MAP.Bounced,
    },
  ]

  return (
    <>
      {isChartEmpty && <GraphCardEmptyState title={T.NO_EMAIL_EVENTS} />}
      {!isChartEmpty && (
        <Box height={250} position="relative">
          <GroupPieChart data={data} colors={Object.values(COLOR_MAP)} />
          <GroupChartOverlay overlayValue={total} />
        </Box>
      )}
      {showFooter && <GroupChartName Icon={AlternateEmailOutlinedIcon} label={T.EMAIL} />}
    </>
  )
}

InvoiceEmailStatistic.propTypes = {
  id: PropTypes.string.isRequired,
  showFooter: PropTypes.bool,
}

export default InvoiceEmailStatistic
