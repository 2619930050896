import React, { useMemo, useState } from 'react'
import {
  Box,
  CardActionArea,
  cardActionAreaClasses,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import LateFeeStatItem from 'components/pricing/LateFees/LateFeesListing/LateFeeStatItem'
import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { MoreVert } from '@mui/icons-material'
import LateFeesActionMenu from 'components/pricing/LateFees/LateFeesListing/LateFeesActionMenu'
import { capitalize } from 'lodash'
import PropTypes from 'prop-types'
import useFormatLateFee from 'components/customer-details/content/fees/late_fees/useFormatLateFee'
import { getLateFeeDetailsPageUrl } from 'router/routes'
import Link from 'components/common/Link'
import EventBlocker from 'components/common/EventBlocker'

const RenderLateFeeCell = ({ row }) => {
  const { id, feeName, formattedStartDate, pricingPeriod, formattedDuration, amountCents, businessLine, accountCount } =
    useFormatLateFee(row)
  const capitalizedPricingPeriod = useMemo(() => capitalize(pricingPeriod), [pricingPeriod])
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <CardActionArea
      disableRipple
      disableTouchRipple
      sx={{
        px: 0,
        height: '100%',
        [`& .${cardActionAreaClasses.focusVisible}, & .${cardActionAreaClasses.focusHighlight}`]: {
          display: 'none',
        },
      }}
      to={getLateFeeDetailsPageUrl(id)}
      component={Link}
    >
      <Box height="100%" width="100%">
        <Grid container alignItems="center" justifyContent="center" flexWrap="nowrap">
          <Grid item xs sm md lg zeroMinWidth>
            <ListItem sx={{ px: 4, py: 1 }}>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                    {feeName}
                  </Typography>
                }
                secondary={
                  <Grid flexWrap={isTabletOrMobile ? 'wrap' : 'nowrap'} justifyContent="flex-start" alignItems="center" container>
                    {isDesktop && (
                      <>
                        <LateFeeStatItem
                          primary={capitalizedPricingPeriod}
                          secondary={capitalize(T.PRICING_PERIOD)}
                          divider
                          lg={2}
                          xl={1}
                          sx={{
                            minWidth: {
                              lg: '100px',
                              xl: '150px',
                            },
                          }}
                        />
                        <LateFeeStatItem
                          primary={formattedStartDate}
                          secondary={capitalize(T.START_FROM)}
                          divider
                          lg={2}
                          xl={1}
                          sx={{
                            minWidth: {
                              lg: '100px',
                              xl: '150px',
                            },
                          }}
                        />
                        <LateFeeStatItem
                          primary={formattedDuration}
                          secondary={capitalize(T.DURATION)}
                          divider={isXs || isDesktop}
                          lg={2}
                          xl={1}
                          sx={{
                            minWidth: {
                              lg: '100px',
                              xl: '150px',
                            },
                          }}
                        />
                        <LateFeeStatItem
                          lg={2}
                          xl={1}
                          sx={{
                            minWidth: {
                              lg: '100px',
                              xl: '150px',
                            },
                          }}
                          primary={accountCount}
                          secondary="accounts"
                          divider
                        />
                        <LateFeeStatItem
                          lg={4}
                          primary={businessLine || 'None'}
                          secondary={capitalize(T.BUSINESS_LINE)}
                          xl={8}
                          zeroMinWidth
                          sx={{
                            maxWidth: {
                              lg: 'unset',
                              xl: 'unset',
                            },
                          }}
                        />
                      </>
                    )}
                    {isTablet && (
                      <>
                        <Grid container item xs={12}>
                          <LateFeeStatItem sm md primary={formattedStartDate} secondary={capitalize(T.START_DATE)} divider />
                          <LateFeeStatItem sm md primary={formattedDuration} secondary={capitalize(T.DURATION)} divider />
                          <LateFeeStatItem sm md primary={capitalizedPricingPeriod} secondary={capitalize(T.PRICING_PERIOD)} />
                        </Grid>
                        <Grid container item xs={12} flexWrap="nowrap">
                          <LateFeeStatItem sm={4} md={4} primary={accountCount} secondary={T.ACCOUNTS} divider />
                          <LateFeeStatItem
                            primary={businessLine || 'None'}
                            secondary={capitalize(T.BUSINESS_LINE)}
                            sm
                            md
                            zeroMinWidth
                            sx={{
                              maxWidth: {
                                md: 'unset',
                              },
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    {isMobile && (
                      <>
                        <Grid container item xs={12}>
                          <LateFeeStatItem xs={12} sm md primary={formattedStartDate} secondary={capitalize(T.START_DATE)} divider />
                          <LateFeeStatItem xs={12} sm md primary={formattedDuration} secondary={capitalize(T.DURATION)} divider={isXs} />
                        </Grid>
                        <Grid container item xs={12}>
                          <LateFeeStatItem
                            xs={12}
                            sm
                            md
                            primary={capitalizedPricingPeriod}
                            secondary={capitalize(T.PRICING_PERIOD)}
                            divider
                          />
                          <LateFeeStatItem xs={12} sm md primary={accountCount} secondary={T.ACCOUNTS} divider={isXs} />
                        </Grid>
                        <LateFeeStatItem
                          primary={businessLine || 'None'}
                          secondary={capitalize(T.BUSINESS_LINE)}
                          xs={12}
                          sx={{
                            maxWidth: {
                              sm: 'unset',
                            },
                          }}
                        />
                      </>
                    )}
                  </Grid>
                }
              />
            </ListItem>
          </Grid>
          <Grid
            item
            sx={{
              minWidth: {
                xs: '12rem',
                sm: '12rem',
                md: '12rem',
                lg: '13rem',
                xl: '24rem',
              },
              pr: 4,
            }}
          >
            <Grid container flexWrap="nowrap" alignItems="center" justifyContent="flex-end" columnGap={2}>
              <Grid item>
                <Typography variant="h4" color="textPrimary" fontWeight={600} noWrap>
                  <HHDisplayMoney value={amountCents} formatToDollars />
                </Typography>
              </Grid>
              <Grid item>
                <EventBlocker preventDefault>
                  <IconButton onClick={handleClick}>
                    <MoreVert />
                  </IconButton>
                </EventBlocker>
              </Grid>
              <EventBlocker preventDefault>
                <LateFeesActionMenu lateFee={row} anchorEl={anchorEl} onClose={handleClose} />
              </EventBlocker>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardActionArea>
  )
}

RenderLateFeeCell.propTypes = {
  row: PropTypes.object,
}

export default RenderLateFeeCell
