import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from '@mui/material'
import VacationHoldList from 'components/customers/accounts/vacation-holds/VacationHoldList'

const VacationHoldPopover = ({ anchorEl, row, onClose }) => {
  const open = Boolean(anchorEl)
  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      slotProps={{ paper: { sx: { minWidth: 'fit-content' } } }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <VacationHoldList row={row} open={open} />
    </Popover>
  )
}

VacationHoldPopover.propTypes = {
  anchorEl: PropTypes.object,
  row: PropTypes.object,
  onClose: PropTypes.func,
}

export default VacationHoldPopover
