import React, { useState, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import trim from 'lodash/trim'

import { toast } from 'react-toastify'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { DialogContent, Grid, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useUpdateAccountMutation } from 'api/accounts/updateAccount'
import { HHFormTextField, HHFormSwitchField, HHFormPriceField } from 'components/form-fields/v5'
import { addBillingPOAndTax } from 'utils/form-body'
import { getExistingCustomerInfo } from 'utils/customerPayload'
import {
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { isCustomerDetailsPage } from 'router/routes'

import T from 'T'
import api from 'api'
import Tag from 'components/tags/Tag'
import CancelButton from 'components/buttons/CancelButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import DragPinMessage from 'components/common/address/DragPinMessage'
import LocationContent from '../../location/LocationContent'
import MapView from '../../location/MapView'
import AccountForm from './AccountForm'
import AccountContent from '../AccountContent'
import { EDIT_ACCOUNT_INITIAL_STATE } from './model'

const EditAccountDialog = ({ isOpen = false, customerDetails = {}, account, onClose }) => {
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const dispatch = useDispatch()
  const [updateAccount, { isLoading: isUpdateAccountLoading }] = useUpdateAccountMutation()
  const metaTags = get(customerMeta, 'tags', [])

  const initialState = account || EDIT_ACCOUNT_INITIAL_STATE
  const { latitude: initialLatitude, longitude: initialLongitude } = initialState.billingAddress
  const [latLngForMap, setLatLngForMap] = useState({
    latitudeForMap: initialLatitude,
    longitudeForMap: initialLongitude,
  })
  const [latLngForMapHistory, setLatLngForMapHistory] = useState({
    latitudeForMapHistory: initialLatitude,
    longitudeForMapHistory: initialLongitude,
  })
  const { latitudeForMap, longitudeForMap } = latLngForMap
  const { latitudeForMapHistory, longitudeForMapHistory } = latLngForMapHistory

  const editAccountForm = useForm({ defaultValues: { ...EDIT_ACCOUNT_INITIAL_STATE } })
  const { handleSubmit, control, watch, setValue, resetField, reset, setFocus } = editAccountForm
  const watchAddress = watch('billingAddress')

  const onSubmitHandler = data => {
    const accountBilling = get(data, 'accountBilling', {})
    const payload = {
      ...data,
      accountName: trim(get(data, 'accountName', '')),
      // append new tags
      ...getExistingCustomerInfo({ ...customerDetails, tags: get(data, 'tags', []) }),
      accountBilling: {
        ...accountBilling,
        ...addBillingPOAndTax(accountBilling),
      },
    }

    const accountId = data.id

    updateAccount(payload)
      .unwrap()
      .then(() => {
        if (isCustomerDetailsPage()) {
          dispatch(getCustomerDetails({ accountId }))
        }
        dispatch(
          api.util.invalidateTags([
            CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
            // cross check if we need to invalidate the CACHE_TAG_ACCOUNT_INVOICE_LIST & CACHE_TAG_ACCOUNT_PAYMENT_LIST
            { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
            { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
            CACHE_TAG_ACCOUNT_LIST,
            { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
            CACHE_TAG_ACCOUNT_GROUP_DETAILS,
            CACHE_TAG_AGING_GROUP_DETAILS,
            CACHE_TAG_SUSPENSION_GROUP_DETAILS,
          ])
        )
        toast.success('Account details updated successfully')
        onClose()
      })
      .catch(handleError)
  }

  const handleLatLongFromMapView = (long, lat) => {
    setValue('billingAddress.longitude', long)
    setValue('billingAddress.latitude', lat)
    setLatLngForMap({ longitudeForMap: long, latitudeForMap: lat })
  }

  useEffect(() => {
    if (isOpen) {
      if (account) {
        reset(account, { keepDefaultValues: true })
      }
      setLatLngForMapHistory({ longitudeForMapHistory: initialLongitude, latitudeForMapHistory: initialLatitude })
      handleLatLongFromMapView(initialLongitude, initialLatitude)
    }
  }, [isOpen, account])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth disableScrollLock>
        <HHDialogTitle title="Edit account details" onClose={onClose} />
        <DialogContent>
          <Grid container mt={2} columnGap={3}>
            <Grid container item xs={12} md spacing={2}>
              <Grid item xs={12} md={6} minHeight={335}>
                <MapView
                  longitude={longitudeForMap}
                  latitude={latitudeForMap}
                  longitudeForMapHistory={longitudeForMapHistory}
                  latitudeForMapHistory={latitudeForMapHistory}
                  onChange={({ mapLng, mapLat }) => handleLatLongFromMapView(mapLng, mapLat)}
                  onReset={() => handleLatLongFromMapView(longitudeForMapHistory, latitudeForMapHistory)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <AccountContent
                  accountNumber={get(customerDetails, 'accountNumber')}
                  accountName={watch('accountName')}
                  attention={watch('billingAddress.attention')}
                  showPlaceholder
                />
                <Box mt={1.5}>
                  <LocationContent address={watchAddress} showPlaceholder />
                </Box>
                <Grid mt={1.5} container spacing={0.5} alignItems="center">
                  {Children.toArray(
                    watch('tags').map(tag => (
                      <Grid item>
                        <Tag clickable={false} tag={tag} />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
              <DragPinMessage latitude={latitudeForMap} longitude={longitudeForMap} />
              <Grid item xs={12}>
                <HHFormSwitchField
                  label={T.CUSTOMER_PURCHASE_ORDER}
                  control={control}
                  name="accountBilling.customerPurchaseOrder"
                  onChange={checked => {
                    if (!checked) {
                      resetField('accountBilling.po')
                      resetField('accountBilling.maxPoAmount')
                      return
                    }

                    setTimeout(() => setFocus('accountBilling.po'), 100)
                  }}
                />
              </Grid>
              {watch('accountBilling.customerPurchaseOrder') && (
                <>
                  <Grid item xs={6}>
                    <HHFormTextField
                      control={control}
                      name="accountBilling.po"
                      label="PO number"
                      placeholder="PO number"
                      fullWidth
                      deprecatedLabel={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormPriceField
                      dollarPrefix
                      control={control}
                      name="accountBilling.maxPoAmount"
                      label={T.MAX_PO_AMOUNT}
                      placeholder={T.MAX_PO_AMOUNT}
                      fullWidth
                      deprecatedLabel={false}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <HHFormSwitchField
                  label={capitalize(T.TAX_EXEMPT)}
                  control={control}
                  name="accountBilling.taxExemptEnable"
                  onChange={checked => {
                    if (!checked) {
                      resetField('accountBilling.taxExempt')
                      return
                    }

                    setTimeout(() => setFocus('accountBilling.taxExempt'), 100)
                  }}
                />
              </Grid>
              {watch('accountBilling.taxExemptEnable') && (
                <Grid item xs={12}>
                  <HHFormTextField
                    control={control}
                    name="accountBilling.taxExempt"
                    label={T.TAX_IDENTIFIER}
                    placeholder={T.TAX_IDENTIFIER}
                    fullWidth
                    deprecatedLabel={false}
                  />
                </Grid>
              )}
            </Grid>

            <FormProvider {...editAccountForm}>
              <AccountForm metaTags={metaTags} setLatLngForMap={setLatLngForMap} setLatLngForMapHistory={setLatLngForMapHistory} />
            </FormProvider>
          </Grid>
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <LoadingButton loading={isUpdateAccountLoading} size="small" variant="contained" onClick={handleSubmit(onSubmitHandler)}>
            {T.SAVE}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

EditAccountDialog.propTypes = {
  isOpen: PropTypes.bool,
  customerDetails: PropTypes.object,
  account: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default EditAccountDialog
