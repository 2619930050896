import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogContent } from '@mui/material'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import ViewLocationContacts from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContacts'
import { useLazyGetLocationSummaryQuery } from 'api/location/getLocations'
import { get } from 'lodash'

const ViewLocationContactsDialog = ({ locationId, accountName = '', isOpen = false, onClose }) => {
  const [getLocationSummary, { isLoading, isFetching, data }] = useLazyGetLocationSummaryQuery()
  const activeContacts = useMemo(() => {
    return get(data, 'contacts', []).filter(({ deactivated }) => !deactivated)
  }, [data])

  useEffect(() => {
    if (locationId && isOpen) {
      getLocationSummary({
        locationId,
      })
    }
  }, [locationId, isOpen])

  return (
    <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
      <HHDialogTitle title={accountName} onClose={onClose} />
      <DialogContent sx={{ p: 0 }}>
        <ViewLocationContacts
          loading={isLoading || isFetching}
          contacts={activeContacts}
          showAction
          fullWidth
          sx={{ pr: 0 }}
          contactMethodSx={{
            pr: 1.5,
          }}
        />
      </DialogContent>
      <HHDialogActions>
        <Button variant="contained" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

ViewLocationContactsDialog.propTypes = {
  accountName: PropTypes.string,
  locationId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ViewLocationContactsDialog
