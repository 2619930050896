import T from 'T'

import { getIntervalName } from 'components/pricing/pricingMethods'

import { get } from 'utils/lodash'

export const getByLabelText = (option, category) => {
  let measureData
  if (category === 'Measures' && option.volume !== undefined) {
    measureData = `${get(option, 'volume', '')} ${get(option, 'unit', '')}`
  }

  if (category === 'Measures' && option.value !== undefined) {
    measureData = get(option, 'value', '')
  }
  switch (category) {
    case null:
      return ''

    case T.BUSINESS_LINE:
      return get(option, 'businessLineLabel', '')

    case T.TAGS:
      return get(option, 'tagName', '')

    case T.TAG_NAME:
      return get(option, 'tagName', '')

    case `${T.METHOD}s`:
      return get(option, 'methodType', '')

    case `${T.MEASURE}s`:
      return measureData

    case `${T.MATERIAL}s`:
      return get(option, 'materialType', '')

    case `${T.PRICING_ZONE}s`:
      return get(option, 'zoneName', '')

    case `${T.TAX_ZONE}s`:
      return get(option, 'zoneName', '')

    case `${T.EXCEPTION}s`:
      return get(option, 'value', '')

    case `${T.ACTION}s`:
      return get(option, 'actionType', '')

    case `${T.LINKED_SERVICES}`:
      return get(option, 'serviceName', '')

    case `${T.DISCOUNT} / ${T.PREMIUM_REASON}`:
      return get(option, 'reasonName', '')

    case T.RECURRENCE:
      const recurrencePer = get(option, 'recurrencePer', '')
      const recurrenceInterval = get(option, 'recurrenceInterval', '')

      if (recurrencePer > 1) return `${recurrencePer} ${recurrenceInterval}s`
      return getIntervalName(recurrenceInterval)

    case T.PRICING_PERIOD:
      const periodPer = get(option, 'periodPer', '')
      const periodInterval = get(option, 'periodInterval', '')

      if (periodPer > 1) return `${periodPer} ${periodInterval}s`
      return getIntervalName(periodInterval)

    default:
      return get(option, 'label', '')
  }
}
