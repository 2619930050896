import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Button } from '@mui/material'
import { blobToBase64, getInvoiceBlob } from 'utils/file'
import { isDraftInvoice } from 'utils/billing'
import { FIREBASE_OBJECT_NOT_FOUND } from 'settings/error'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import { isRegenerateBtnEnabled } from 'data/billing/InvoiceActionSelectors'
import PdfViewer from '../../drawer/pdf-viewer/PdfViewer'

const InvoiceDrawer = ({
  open = false,
  accountName = '',
  invoiceNumber = '',
  invoiceId = '',
  invoiceStatus = '',
  onClose = noop,
  onEditInvoice = noop,
}) => {
  const [regenerateInvoiceList] = useRegenerateInvoiceListMutation()
  const [pdfData, setPdfdata] = useState(undefined)
  const invoiceName = ['Invoice', accountName, invoiceNumber].join(' - ')
  const [isLoading, setIsLoading] = useState(false)
  const [isRegenerated, setIsRegenerated] = useState(false)

  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const showEditButton = isDraftInvoice(invoiceStatus)

  const regenerateInvoice = () => {
    regenerateInvoiceList({ invoiceIds: [invoiceId] })
      .unwrap()
      .then(() => setIsRegenerated(true))
      .catch(() => setIsLoading(false))
  }

  const getInvoice = () => {
    setIsLoading(true)
    getInvoiceBlob(invoiceNumber, businessId)
      .then(blobToBase64)
      .then(base64 => {
        setPdfdata(base64.split(',')[1])
        setIsLoading(false)
      })
      .catch(error => {
        if (!isRegenerated && isRegenerateBtnEnabled({ status: invoiceStatus }) && error?.code === FIREBASE_OBJECT_NOT_FOUND) {
          regenerateInvoice()
        } else {
          setIsLoading(false)
        }
      })
  }

  useEffect(() => {
    if (!invoiceNumber || open === false || !businessId) return
    getInvoice()
  }, [invoiceNumber, businessId, open])

  useEffect(() => {
    if (isRegenerated) {
      setTimeout(getInvoice, 5000)
    }
  }, [isRegenerated])

  return (
    <PdfViewer
      open={open}
      isLoading={isLoading}
      type="invoice"
      fileName={invoiceName}
      title={invoiceName}
      pdfData={pdfData}
      onClose={() => {
        setPdfdata(undefined)
        setIsRegenerated(false)
        onClose()
      }}
      actions={
        showEditButton &&
        pdfData && (
          <Button autoFocus onClick={onEditInvoice}>
            Edit Invoice
          </Button>
        )
      }
    />
  )
}

InvoiceDrawer.propTypes = {
  open: PropTypes.bool,
  accountName: PropTypes.string,
  invoiceNumber: PropTypes.number,
  invoiceId: PropTypes.string,
  invoiceStatus: PropTypes.string,
  onClose: PropTypes.func,
  onEditInvoice: PropTypes.func,
}

export default InvoiceDrawer
