import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors'

export const getIsDefaultBucket = id => {
  return Object.values(AGING_BUCKET_NAME).includes(id)
}

export const getIsSuspendedBucket = id => {
  return id === 'suspended'
}

export const ACCOUNT_STATUS_COLOR_MAP = {
  Active: green[200],
  Deactivated: orange[100],
}

const BILLING_PROFILE_COLOR_MAP = [
  purple[100],
  deepPurple[100],
  pink[100],
  red[100],
  blue[100],
  green[100],
  orange[100],
  cyan[100],
  amber[200],
  indigo[100],
  lightGreen[100],
  lime[200],
  teal[100],
  deepOrange[100],
  yellow[200],
  lightBlue[100],
  purple[200],
  deepPurple[200],
  pink[200],
  red[200],
  blue[200],
  green[200],
  orange[200],
  cyan[200],
  amber[300],
  indigo[200],
  lightGreen[200],
  lime[300],
  teal[200],
  deepOrange[200],
  yellow[300],
  lightBlue[200],
]

const LIMIT = 9

export const getBillingProfileGraphDataFromStatistics = (billingProfiles = {}, limit = LIMIT) => {
  const sortedProfileIds = Object.keys(billingProfiles).sort((a, b) => billingProfiles[b] - billingProfiles[a])

  const topProfileIds = sortedProfileIds.slice(0, LIMIT)

  const topProfiles = topProfileIds.map((profileId, index) => {
    return {
      id: profileId,
      label: profileId,
      [profileId]: billingProfiles[profileId],
      [`${profileId}Color`]: BILLING_PROFILE_COLOR_MAP[index % BILLING_PROFILE_COLOR_MAP.length],
    }
  })
  const cutOffCount = sortedProfileIds.length - topProfiles.length

  return { topProfiles, cutOffCount }
}

export const getAccountStatusGraphDataFromStatistics = (accountStatus = {}) => {
  return [
    {
      id: 'Active',
      label: 'Active',
      value: accountStatus.activeCount || 0,
      color: ACCOUNT_STATUS_COLOR_MAP.Active,
    },
    {
      id: 'Deactivated',
      label: 'Deactivated',
      value: accountStatus.deactivatedCount || 0,
      color: ACCOUNT_STATUS_COLOR_MAP.Deactivated,
    },
  ]
}
