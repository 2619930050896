import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_LIST } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceGroupList: build.query({
      query: () => ({ url: '/api/v1/billing/invoice/groups', method: 'GET' }),
      providesTags: [CACHE_TAG_BILLING_INVOICE_GROUP_LIST],
    }),
  }),
})

export const { useGetInvoiceGroupListQuery, useLazyGetInvoiceGroupListQuery } = extendedApi
