import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getDefaultReportList: builder.query({
      query: () => ({ url: '/api/v1/report/reports' }),
    }),
  }),
})

export const { useLazyGetDefaultReportListQuery } = extendedApi
