import React, { Children } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import { NumericFormat } from 'react-number-format'

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, Typography, Stack } from '@mui/material'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { getPriceType } from 'utils/service'

import { CommonSwitch, CommonTextfield } from 'components/common'

import T from 'T'
import Constants from 'Constants'
import ToolTip from 'components/common/Tooltip'

import TaxAssignedTo from './TaxAssignedTo'
import './style.scss'

const { MEDIA_SERVER } = Constants

const MAX_FEE_NAME = 30
const MAX_TAX_NAME = 11
const DOTS_LENGTH = 3

const FeeTaxesTabContent = ({
  feeTaxesActiveTab = T.FEES,
  taxZoneId = '',
  feeTaxList = [],
  pricedServiceDetails = {},
  onFeeChange,
  onTaxChange,
  onChange,
}) => {
  const allServices = get(pricedServiceDetails, 'allServices', [])
  const onlyFees = feeTaxList.filter(fee => fee.type !== T.TAX)
  const groupByFees = groupBy(onlyFees, 'serviceId')

  const onlyTaxes = feeTaxList.filter(tax => tax.type === T.TAX)
  const taxesThatMatchTaxZone = onlyTaxes.filter(tax => tax.taxZoneId === taxZoneId && tax.visible)
  const groupByTaxesAndTaxOnFees = groupBy(taxesThatMatchTaxZone, 'serviceId')

  const getTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <Stack alignItems="center" flexDirection="row">
            {feeTaxesActiveTab === T.FEES ? T.FEE : T.TAX}
            <img className="arrow cursor-pointer" src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack alignItems="center" flexDirection="row">
            {T.LINKED}
            <img className="arrow cursor-pointer" src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />
          </Stack>
        </TableCell>
        {feeTaxesActiveTab !== T.FEES && (
          <TableCell align="right" className="whitespace-nowrap">
            <Stack alignItems="center" flexDirection="row">
              {T.ASSIGNED_TO}
              <img className="arrow cursor-pointer" src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />
            </Stack>
          </TableCell>
        )}
        <TableCell align="right">
          <Stack alignItems="center" justifyContent="flex-end" flexDirection="row">
            {feeTaxesActiveTab === T.FEES ? T.PRICE : T.RATE}
            <img className="arrow cursor-pointer" src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />
          </Stack>
        </TableCell>
      </TableRow>
    </TableHead>
  )

  const renderName = (feeName, limit) => {
    return (
      <>
        {feeName && feeName.length > limit ? (
          <ToolTip title={feeName}>
            <Box component="span">{`${feeName.substring(0, limit - DOTS_LENGTH)}...`}</Box>
          </ToolTip>
        ) : (
          feeName
        )}
      </>
    )
  }

  return (
    <div className="fee-tax-tab-content common-padding-lr-3-5">
      <div className="mt3-5">
        <Stack flexDirection="row" className="fee-tax-toggle">
          <input
            type="button"
            value={T.FEES}
            className={`btn fees-btn ${feeTaxesActiveTab === T.FEES ? 'selected' : 'not-selected'}`}
            onClick={() => onChange('feeTaxesActiveTab', T.FEES)}
          />

          <input
            type="button"
            value={T.TAXES}
            className={`btn tax-btn ${feeTaxesActiveTab === T.TAXES ? 'selected' : 'not-selected'}`}
            onClick={() => onChange('feeTaxesActiveTab', T.TAXES)}
          />
        </Stack>
      </div>

      {feeTaxesActiveTab === T.FEES &&
        Children.toArray(
          allServices.map(service => {
            const { serviceId, serviceName } = service
            const fees = get(groupByFees, serviceId, [])
            return (
              <Box mt={3} className="fee-table">
                <Typography variant="h6">{serviceName}</Typography>
                <TableContainer sx={{ mt: 3 }}>
                  <Table>
                    {getTableHeader()}
                    <TableBody>
                      {Children.toArray(
                        fees.map(fee => {
                          const feePriceType = getPriceType(get(fee, 'valueType'))
                          return (
                            <TableRow>
                              <TableCell width="70%">{renderName(fee.feeName, MAX_FEE_NAME)}</TableCell>
                              <TableCell width="10%" className="switch tc">
                                <CommonSwitch
                                  isChecked={fee.linked}
                                  onChange={checked => onFeeChange('linked', checked, fee.feeTaxId, serviceId)}
                                />
                              </TableCell>
                              <TableCell width="20%" className="price">
                                <NumericFormat
                                  style={{
                                    textAlign: 'right',
                                  }}
                                  className="common textfield no-outline"
                                  placeholder=""
                                  value={fee.price}
                                  autoComplete="off"
                                  onValueChange={values => {
                                    const { value } = values
                                    onFeeChange('price', value, fee.feeTaxId, serviceId)
                                  }}
                                  renderText={formattedValue => <Typography>{`${formattedValue}`}</Typography>}
                                  decimalSeparator="."
                                  decimalScale={2}
                                  prefix={feePriceType === '$' ? '$' : ''}
                                  suffix={feePriceType === '%' ? '%' : ''}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })
                      )}

                      {fees && fees.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={12} className="pa0">
                            <div className="no-results">{T.NO_CS_FEE}</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          })
        )}

      {feeTaxesActiveTab === T.TAXES &&
        Children.toArray(
          allServices.map(service => {
            const { serviceId, serviceName } = service
            const taxes = get(groupByTaxesAndTaxOnFees, serviceId, [])
            const groupByFeeTaxes = groupBy(taxes, 'feeTaxId')

            return (
              <Box mt={3} className="tax-table">
                <Typography variant="h6">{serviceName}</Typography>
                <TableContainer sx={{ mt: 3 }}>
                  <Table>
                    {getTableHeader()}
                    <TableBody>
                      {taxZoneId &&
                        Children.toArray(
                          Object.keys(groupByFeeTaxes).map(taxKey => {
                            const allTaxes = get(groupByFeeTaxes, taxKey, [])
                            // all values will be the same except for the feeId & applied to, so use the first one
                            const tax = get(allTaxes, `[0]`, {})
                            const priceType = getPriceType(get(tax, 'taxValueType'))
                            let taxPrice = get(tax, 'price', 0)

                            if (priceType === '$') {
                              taxPrice = priceType + taxPrice
                            } else {
                              taxPrice += priceType
                            }

                            // If any one is linked then show toggle on
                            const isLinked = allTaxes.find(taxRow => taxRow.linked)
                            const rowTaxId = get(allTaxes, '[0].feeTaxId')

                            return (
                              <TableRow>
                                <TableCell width="40%">{renderName(tax.feeName, MAX_TAX_NAME)}</TableCell>
                                <TableCell width="10%" className="switch" align="center">
                                  <CommonSwitch
                                    isChecked={isLinked}
                                    onChange={checked =>
                                      onTaxChange(rowTaxId, checked ? allTaxes.map(rowTax => rowTax.appliedOnId) : [], serviceId)
                                    }
                                  />
                                </TableCell>
                                <TableCell width="30%" align="right">
                                  <TaxAssignedTo
                                    mainTaxId={rowTaxId}
                                    taxList={allTaxes}
                                    onRenderName={renderName}
                                    onTaxChange={(mainTaxId, linkedId) => onTaxChange(mainTaxId, linkedId, serviceId)}
                                  />
                                </TableCell>
                                <TableCell width="20%" className="price">
                                  <CommonTextfield className="disabled" readonly value={taxPrice} />
                                </TableCell>
                              </TableRow>
                            )
                          })
                        )}

                      {taxes && taxes.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={12} padding="none">
                            <div className="no-results">{taxZoneId ? T.NO_TAX_ZONE : T.NO_TAX_ZONE_SELECTED}</div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          })
        )}
    </div>
  )
}

FeeTaxesTabContent.propTypes = {
  feeTaxesActiveTab: PropTypes.string,
  taxZoneId: PropTypes.string,
  feeTaxList: PropTypes.array,
  pricedServiceDetails: PropTypes.object,
  onFeeChange: PropTypes.func.isRequired,
  onTaxChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default memo(FeeTaxesTabContent)
