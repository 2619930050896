import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { DatePicker } from '@mui/x-date-pickers-pro'
import HHTextField from 'components/form-fields/v5/HHTextField'

const HHDatePicker = ({
  label = '',
  value = '',
  disabled = false,
  disablePast = false,
  disableFuture = false,
  shouldDisableDate = noop,
  handleChange = noop,
  readOnly = false,
  TextFieldProps = {},
  PopperProps = {},
  restSlots = {},
  restSlotProps = {},
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (readOnly) return
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  return (
    <DatePicker
      readOnly={readOnly}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disableFuture={disableFuture}
      disabled={disabled}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      label={label}
      value={value}
      slots={{ textField: HHTextField, ...restSlots }}
      slotProps={{
        textField: { onClick: handleOpen, fullWidth: true, ...TextFieldProps },
        popper: { ...PopperProps },
        ...restSlotProps,
      }}
      onChange={handleChange}
    />
  )
}

HHDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  readOnly: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  PopperProps: PropTypes.object,
  restSlots: PropTypes.object,
  restSlotProps: PropTypes.object,
}

export default HHDatePicker
