import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useFormContext } from 'react-hook-form'
import { Grid, Alert, Typography, Divider } from '@mui/material'

import { get } from 'utils/lodash'
import { REGEX } from 'utils/validations'
import { getAddressParams } from 'utils/helper'
import { HHFormTextField, HHFormSwitchField } from 'components/form-fields/v5'
import { DEFAULT_STATE } from 'components/common/address/model'

import T from 'T'
import HHFormPhoneField from 'components/form-fields/v5/HHFormPhoneField'
import AddressRowOne from 'components/common/address/AddressRowOne'
import AddressRowTwo from 'components/common/address/AddressRowTwo'
import AddressLatLong from 'components/common/address/AddressLatLong'
import BillingProfileDropdown from 'components/customer-details/content/common/billing-dropdown'

const { accountNamePlaceholder, addressNamePlaceholder } = DEFAULT_STATE

const FormFields = ({ onEnter, setLatLngForMap, setLatLngForMapHistory }) => {
  const {
    control,
    watch,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext()

  const { address: addressErrors, accountName: accountNameError, contactPhone: contactPhoneError, contactEmail: contactEmailError } = errors
  const accountNameHelperText = get(accountNameError, 'message')
  const watchLine1 = watch('address.line1')
  const watchCity = watch('address.city')
  const watchState = watch('address.state')
  const watchZipCode = watch('address.zipCode')
  const watchLong = watch('address.longitude')
  const watchLat = watch('address.latitude')
  const watchBillingProfileId = watch('billingProfileId')

  const handleBillingAddressChange = address => {
    const { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed } = getAddressParams(address)
    setValue('address.line1', line1)
    setValue('address.city', city)
    setValue('address.state', state)
    setValue('address.country', country)
    setValue('address.zipCode', zipCode)
    setValue('address.latitude', latitudeFixed)
    setValue('address.longitude', longitudeFixed)
    setLatLngForMap({ longitudeForMap: longitudeFixed, latitudeForMap: latitudeFixed })
    setLatLngForMapHistory({ longitudeForMapHistory: longitudeFixed, latitudeForMapHistory: latitudeFixed })
    setFocus('accountName')
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      onEnter()
    }
  }

  return (
    <Grid container item xs={12} md={5} rowGap={2} height="fit-content">
      <Grid item xs={12}>
        <Alert severity="info" sx={{ alignItems: 'center' }}>
          <Typography variant="h6" fontWeight={400}>
            Just the basics here. You can add more details quickly once the account is created.
          </Typography>
        </Alert>
      </Grid>

      <Grid item xs={12}>
        <HHFormTextField
          error={accountNameHelperText}
          helperText={accountNameHelperText}
          control={control}
          required
          rules={{ required: T.CANNOT_BE_EMPTY }}
          name="accountName"
          label={capitalize(T.CUSTOMER_NAME)}
          placeholder={accountNamePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={handleKeyDown}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HHFormTextField
            control={control}
            name="contact.firstName"
            label={T.CONTACT_FIRST_NAME}
            placeholder={T.CONTACT_FIRST_NAME}
            fullWidth
            deprecatedLabel={false}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HHFormTextField
            control={control}
            name="contact.lastName"
            label={T.CONTACT_LAST_NAME}
            placeholder={T.CONTACT_LAST_NAME}
            fullWidth
            deprecatedLabel={false}
            onKeyDown={handleKeyDown}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <HHFormPhoneField
          control={control}
          name="contactPhone"
          error={contactPhoneError}
          rules={{ pattern: REGEX.IS_VALID_MOBILE }}
          label={T.CONTACT_PHONE_NUMBER}
          placeholder={T.CONTACT_PHONE_NUMBER}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={handleKeyDown}
        />
      </Grid>

      <Grid item xs={12}>
        <HHFormTextField
          control={control}
          name="contactEmail"
          error={contactEmailError}
          rules={{ pattern: REGEX.IS_EMAIL }}
          label={T.BILLING_EMAIL}
          placeholder={T.BILLING_EMAIL}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={handleKeyDown}
        />
      </Grid>

      <Grid item xs={12}>
        <BillingProfileDropdown
          billingProfileId={watchBillingProfileId}
          onChange={event => setValue('billingProfileId', event.target.value)}
        />
        <Typography mt={0.5} ml={1.75} variant="subtitle2" color="warning.main" fontWeight={400}>
          Please confirm billing profile for this account.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <HHFormTextField
          control={control}
          name="address.addressName"
          label={capitalize(T.SERVICE_LOCATION_NAME)}
          placeholder={addressNamePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onKeyDown={handleKeyDown}
        />
      </Grid>

      <AddressRowOne
        control={control}
        line1={watchLine1}
        hasLine1Error={addressErrors?.line1 && !watchLine1}
        onLine1Change={newValue => setValue('address.line1', newValue)}
        onBillingAddressChange={handleBillingAddressChange}
        onKeyDown={handleKeyDown}
      />

      <AddressRowTwo
        control={control}
        hasCityError={addressErrors?.city && !watchCity}
        hasStateError={addressErrors?.state && !watchState}
        hasCountryError={addressErrors?.zipCode && !watchZipCode}
        onKeyDown={handleKeyDown}
      />

      <AddressLatLong
        control={control}
        lat={watchLat}
        long={watchLong}
        hasLatError={addressErrors?.latitude}
        hasLongError={addressErrors?.longitude}
        onBlurLat={value => {
          setLatLngForMap(prev => ({ ...prev, latitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, latitudeForMapHistory: value }))
        }}
        onBlurLong={value => {
          setLatLngForMap(prev => ({ ...prev, longitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, longitudeForMapHistory: value }))
        }}
      />

      <Grid item xs={12}>
        <HHFormSwitchField label={T.USE_AS_BILLING_ADDRESS} control={control} name="useAsBillingAddress" />
      </Grid>
    </Grid>
  )
}

FormFields.propTypes = {
  onEnter: PropTypes.func.isRequired,
  setLatLngForMap: PropTypes.func.isRequired,
  setLatLngForMapHistory: PropTypes.func.isRequired,
}

export default FormFields
