import React, { useEffect, useMemo, useState } from 'react'
import { Grid, IconButton, ListItem, ListItemIcon, ListItemText, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ReorderIcon from 'components/common/ReorderIcon'
import { MoreVert } from '@mui/icons-material'
import LateFeeStatItem from 'components/pricing/LateFees/LateFeesListing/LateFeeStatItem'
import T from 'T'
import { openPricingDrawer, selectIsOpenPricingDrawer } from 'slices/pricing/pricingDrawerSlice'
import { useParams } from 'react-router-dom-v5-compat'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import LateFeesActionMenu from 'components/pricing/LateFees/LateFeesListing/LateFeesActionMenu'
import { getDurationMeasureUnit, getMeasureUnitLabel } from 'components/pricing/LateFees/CreateEditLateFeesDialog/settings'
import capitalize from 'lodash/capitalize'
import { useLazyFetchLateFeeDetailsQuery } from 'api/pricing/lateFeesCrud'
import BulkLinkLateFeesDialog from 'components/pricing/LateFees/LinkUnlinkLateFees/BulkLinkLateFeesDialog'
import BulkUnlinkLateFeesDialog from 'components/pricing/LateFees/LinkUnlinkLateFees/BulkUnlinkLateFeesDialog'
import {
  closeBulkLinkLateFeeDialog,
  closeBulkUnlinkLateFeeDialog,
  selectIsBulkLinkLateFeeDialogOpen,
  selectIsBulkUnlinkLateFeeDialogOpen,
} from 'slices/pricing/lateFeesSlice'

const LateFeeDetailsHeader = () => {
  const { id } = useParams()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [fetchLateFEeDetailsQuery, { isLoading, isFetching, data }] = useLazyFetchLateFeeDetailsQuery()
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const isBulkLinkLateFeeDialogOpen = useSelector(selectIsBulkLinkLateFeeDialogOpen)
  const isBulkUnlinkLateFeeDialogOpen = useSelector(selectIsBulkUnlinkLateFeeDialogOpen)
  const { feeName, duration, pricingPeriod, startOffset, amountCents, businessLine, accountCount } = data || {}

  const capitalizedPricingPeriod = useMemo(() => capitalize(pricingPeriod), [pricingPeriod])
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const durationMeasureUnit = useMemo(() => getDurationMeasureUnit(pricingPeriod), [pricingPeriod])
  const formattedDuration = useMemo(
    () => (duration === 0 ? T.INDEFINITE : `${duration} ${getMeasureUnitLabel(duration, durationMeasureUnit)}`),
    [durationMeasureUnit, duration]
  )
  const formattedStartDate = useMemo(
    () => (startOffset === 0 ? T.IMMEDIATE : `${startOffset} ${getMeasureUnitLabel(startOffset, durationMeasureUnit)}`),
    [duration, durationMeasureUnit]
  )
  const handleOpenDrawer = () => {
    dispatch(openPricingDrawer())
  }

  const handleCloseBulkLinkLateFeesDialog = () => {
    dispatch(closeBulkLinkLateFeeDialog())
  }

  const handleCloseBulkUnlinkLateFeesDialog = () => {
    dispatch(closeBulkUnlinkLateFeeDialog())
  }

  useEffect(() => {
    if (id) {
      fetchLateFEeDetailsQuery({
        id,
      })
    }
  }, [id])

  return (
    <>
      <Grid
        sx={{
          backgroundColor: theme.palette.background.default,
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
        }}
        container
        alignItems="center"
        justifyContent="center"
        flexWrap="nowrap"
      >
        <Grid item xs md lg zeroMinWidth>
          <ListItem sx={{ px: 4, pt: 1, pb: 0 }}>
            {!isOpenPricingDrawer && (
              <ListItemIcon>
                <ReorderIcon onClick={handleOpenDrawer} />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{
                my: 0,
              }}
              disableTypography
              primary={
                <Typography variant="h4" fontWeight={600} color="textPrimary" noWrap>
                  Late fee
                </Typography>
              }
              secondary={
                <Grid flexWrap={isTabletOrMobile ? 'wrap' : 'nowrap'} justifyContent="flex-start" alignItems="center" container>
                  <LateFeeStatItem
                    loading={loading}
                    primary={capitalizedPricingPeriod}
                    secondary={capitalize(T.PRICING_PERIOD)}
                    secondaryTypographyProps={{
                      variant: 'body2',
                    }}
                    divider
                    DividerProps={{
                      variant: 'fullWidth',
                    }}
                  />
                  <LateFeeStatItem
                    loading={loading}
                    primary={formattedStartDate}
                    secondary={capitalize(T.START_FROM)}
                    secondaryTypographyProps={{
                      variant: 'body2',
                    }}
                    divider
                    DividerProps={{
                      variant: 'fullWidth',
                    }}
                  />
                  <LateFeeStatItem
                    primary={formattedDuration}
                    secondary={capitalize(T.DURATION)}
                    loading={loading}
                    secondaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </Grid>
              }
            />
          </ListItem>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: '8rem',
            pr: 4,
          }}
        >
          <Grid container flexWrap="nowrap" alignItems="center" justifyContent="flex-end" columnGap={2}>
            <Grid item>
              <Typography variant="h2" color="textPrimary" fontWeight={600} noWrap>
                {loading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={amountCents} formatToDollars />}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClick}>
                <MoreVert />
              </IconButton>
            </Grid>
            <LateFeesActionMenu lateFee={data} anchorEl={anchorEl} onClose={handleClose} />
          </Grid>
        </Grid>
      </Grid>
      {isBulkLinkLateFeeDialogOpen && (
        <BulkLinkLateFeesDialog isOpen={isBulkLinkLateFeeDialogOpen} onClose={handleCloseBulkLinkLateFeesDialog} />
      )}
      {isBulkUnlinkLateFeeDialogOpen && (
        <BulkUnlinkLateFeesDialog isOpen={isBulkUnlinkLateFeeDialogOpen} onClose={handleCloseBulkUnlinkLateFeesDialog} />
      )}
    </>
  )
}

export default LateFeeDetailsHeader
