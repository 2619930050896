import React from 'react'
import PropTypes from 'prop-types'

import { Box, ListItem, ListItemAvatar, ListItemText, Typography, ListItemIcon } from '@mui/material'

import { getMeasureUnit } from 'utils/get-measure-unit'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import ServiceTypeAvatar from 'components/common/avatar/ServiceTypeAvatar'
import RentalFeeBadge from 'components/pricing/RentalFees/common/badge/RentalFeeBadge'

const RenderServiceCell = ({ row }) => {
  const {
    serviceType,
    name,
    measureUnit = '',
    measureVolume = 0,
    price = 0,
    startDate: startDateStr,
    locationName,
    locationAddress,
    rentalFeeCount = 0,
  } = row
  const measureLabel = measureUnit ? `${measureVolume} ${getMeasureUnit(measureUnit)}` : ''
  const startDate = createDateFromString(startDateStr)
  const formattedStartDate = formatDateToFEFormatDateFns(startDate)

  return (
    <Box width="100%" py={0.5}>
      <ListItem sx={{ pl: 0, py: 0 }} secondaryAction={<RentalFeeBadge rentalFeeCount={rentalFeeCount} />}>
        <ListItemAvatar sx={{ minWidth: 'auto' }}>
          <ServiceTypeAvatar serviceType={serviceType} />
        </ListItemAvatar>
        <ListItemText
          sx={{ ml: 1 }}
          primary={name}
          primaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'text.primary', noWrap: true }}
          secondaryTypographyProps={{ variant: 'caption' }}
          secondary={
            <>
              {measureLabel}
              {measureLabel && ' | '}
              {formattedStartDate}
            </>
          }
        />
        <ListItemIcon>
          <Typography variant="h5" fontWeight={500} color="text.primary">
            <HHDisplayMoney value={price} formatToDollars={false} />
          </Typography>
        </ListItemIcon>
      </ListItem>

      <ListItem sx={{ pl: 4, py: 0 }}>
        <ListItemText
          primary={locationAddress}
          primaryTypographyProps={{ variant: 'h6', fontWeight: 500, color: 'text.primary', noWrap: true }}
          secondary={locationName}
          secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
        />
      </ListItem>
    </Box>
  )
}

RenderServiceCell.propTypes = {
  row: PropTypes.array,
}

export default RenderServiceCell
