import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, Grid, Box } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { get, sortBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'
import ClusterMapView from 'components/route-manager/ScheduleStop/ClusterMapView'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { getFeaturePointFromCoordsObj, getStops, getUniqueStops, mapStopsToFeaturePoints } from 'utils/map'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { HHAlert } from 'components/common/HHAlert'
import { setIsAssignStopDialogOpen } from 'slices/route/routingActionsSlice'
import ScheduleStopDialogActions from 'components/route-manager/ScheduleStop/ScheduleStopDialogActions'
import SequenceMap from 'components/route-manager/ScheduleStop/SequenceMap'
import ScheduleStopDialogAppBar from 'components/route-manager/ScheduleStop/ScheduleStopDialogAppBar'
import StopsDataGrid from 'components/route-manager/ScheduleStop/StopsDataGrid'
import { useMoveStopsMutation } from 'api/route/moveStops'
import api from 'api'
import {
  CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS,
  CACHE_TAG_ROUTES,
  CACHE_TAG_WORK_ORDER_LIST,
  CACHE_TAG_CONFIGURED_SERVICES_LIST,
  CACHE_TAG_ROUTES_METADATA_BY_DATE,
} from 'api/cacheTagTypes'
import { setRoutingTooltipCacheByAccountId } from 'slices/customer/customerDetailsSlice'
import { setResequenceHistory } from 'slices/route/routeSlice'
import { mapStopToListItemFormat } from 'utils/route'
import { useLazyGetRoutesMetadataByServiceDateQuery } from 'api/route/getRouteMetadataByServiceDate'
import { handleError } from 'utils/error'
import Loader from '../../common/loader'
import { ROW_HEIGHT } from './setings'

const defaultValues = {
  sequence: '',
  selectedRoute: '',
}

const getStopsByRouteId = routes => {
  const stopsByRouteId = {}
  routes.forEach(route => {
    const { id, stops } = route
    const sortedStops = sortBy(stops, ['sequence'])
    stopsByRouteId[id] = sortedStops.map((s, index) => ({ ...s, position: index }))
  })
  return stopsByRouteId
}

const formatStops = (stops, workOrderId) =>
  stops.map(stop => {
    const listItem = mapStopToListItemFormat(stop)
    return {
      ...listItem,
      toBeInserted: workOrderId === listItem.workOrderId,
      isUnassigned: false,
    }
  })

const insertElementAtPosition = (rows, toBeInserted, sequenceNumber = 0, isAssignedAndSameRoute = false) => {
  const updatedRows = [...rows]
  const fixedSequenceNumber = Math.max(sequenceNumber, 1)

  if (isAssignedAndSameRoute) {
    // Swap logic
    const currentPosition = rows.findIndex(row => row.workOrderId === toBeInserted.workOrderId)
    const element = updatedRows[currentPosition]
    updatedRows.splice(currentPosition, 1)
    updatedRows.splice(fixedSequenceNumber - 1, 0, element)
    return updatedRows
  }

  if (fixedSequenceNumber > updatedRows.length) {
    updatedRows.push(toBeInserted)
  } else {
    updatedRows.splice(fixedSequenceNumber - 1, 0, toBeInserted)
  }
  return updatedRows
}

const ScheduleStopDialog = ({ isTemporary = false }) => {
  const formMethods = useForm({
    defaultValues,
  })
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const accountId = get(customerDetails, 'id')
  const tooltipStopCacheByAccountId = useSelector(state => get(state, 'CustomerDetails.routing.tooltipStopCacheByAccountId', {}))
  const accountTooltipStopCacheByDate = get(tooltipStopCacheByAccountId, accountId, {})
  const apiRef = useGridApiRef()
  const { watch, setFocus, reset, control, handleSubmit, setValue } = formMethods
  const selectedSequence = watch('sequence')
  const selectedRoute = watch('selectedRoute')
  const isOpen = useSelector(s => s.routingActions.assignStopDialog.isOpen)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [loadingRows, setLoadingRows] = useState(false)
  const [getRouteStops, { isLoading, isFetching }] = useLazyGetRouteStopsQuery()
  const [rows, setRows] = useState([])
  const [toBeAssignedGeojsonData, setToBeAssignedGeojsonData] = useState([])
  const [getRoutesMetadata, { data: routeMetaData }] = useLazyGetRoutesMetadataByServiceDateQuery()
  const [moveStops, { isLoading: isMoveStopLoading }] = useMoveStopsMutation()
  const [clusterData, setClusterData] = useState([])
  const [routeGeojsonData, setRoutePointGeojsonData] = useState()
  const serializedServiceDate = useSelector(state => get(state, 'CustomerDetails.routing.serializedServiceDate'))
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const beFormatServiceDate = formatDateToBEFormatDateFns(serviceDate)
  const [stopsByRouteId, setStopsByRouteId] = useState({})
  const routes = get(routeMetaData, 'routes', [])
  const isRouteSelected = Boolean(selectedRoute)
  const stopToBeAssigned = useSelector(s => s.routingActions.assignStopDialog.stopToBeAssigned, shallowEqual)
  const routeId = get(stopToBeAssigned, 'routeId')
  const workOrderId = get(stopToBeAssigned, 'workOrderId')
  const sequence = get(stopToBeAssigned, 'sequence')
  const filteredStops = get(stopsByRouteId, selectedRoute, [])
  const loading = isLoading || isFetching || loadingRows

  const handleResetWithExistingRoute = () => {
    reset({ sequence, selectedRoute: routeId })
  }

  const handleReset = () => {
    reset(defaultValues)
    setRows([])
    setClusterData([])
    setRoutePointGeojsonData(undefined)
  }

  const handleClose = () => {
    dispatch(setIsAssignStopDialogOpen(false))
  }

  const handleCancel = () => {
    handleReset()
    handleClose()
  }

  const handleSave = () => {
    const toBeAssignedIndex = rows.findIndex(element => element.toBeInserted === true)
    const toBeAssignId = get(stopToBeAssigned, 'id')
    let targetStopId = null
    if (toBeAssignedIndex > 0) {
      targetStopId = get(rows, `${toBeAssignedIndex - 1}.id`)
    }
    moveStops({
      destinationRouteId: selectedRoute,
      serviceDate: formatDateToBEFormatDateFns(serviceDate),
      stops: [toBeAssignId],
      targetStopId,
      updateMasterSequence: !isTemporary,
    })
      .unwrap()
      .then(() => {
        dispatch(
          api.util.invalidateTags([
            CACHE_TAG_ROUTES,
            CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS,
            CACHE_TAG_ROUTES_METADATA_BY_DATE,
            CACHE_TAG_WORK_ORDER_LIST,
            CACHE_TAG_CONFIGURED_SERVICES_LIST,
          ])
        )
        dispatch(setResequenceHistory({ track: true }))
        dispatch(
          setRoutingTooltipCacheByAccountId({
            [accountId]: {
              ...accountTooltipStopCacheByDate,
              [beFormatServiceDate]: undefined,
            },
          })
        )
        handleReset()
        handleClose()
      })
      .catch(e => {
        handleError(e)
      })
  }

  const isAssignedAndSameRoute = stops => routeId === selectedRoute && stops.find(stop => stop.workOrderId === workOrderId)

  const handleRouteChange = () => {
    setTimeout(() => {
      setFocus('sequence')
    }, 300)
  }

  useEffect(() => {
    if (isOpen) {
      const currentDate = new Date()
      const timestamp = currentDate.getTime()
      getRouteStops({
        routes: [],
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        fetchId: timestamp,
      })
        .unwrap()
        .then(r => {
          const routes = get(r, 'routes', [])
          const updatedStops = getStops(routes)
          const updatedGeojsonData = mapStopsToFeaturePoints(updatedStops)
          const updatedStopsByRouteId = getStopsByRouteId(routes)
          setStopsByRouteId(updatedStopsByRouteId)
          setClusterData(updatedGeojsonData)

          if (routeId) {
            handleResetWithExistingRoute()
          }
        })
    }
  }, [serializedServiceDate, isOpen])

  useEffect(() => {
    if (!selectedRoute) return
    setValue('sequence', routeId === selectedRoute ? sequence : '')
    const formattedStops = formatStops(filteredStops, workOrderId)
    const updatedPointGeojsonData = mapStopsToFeaturePoints([...formattedStops])
    const { latitude, longitude, ...rest } = stopToBeAssigned
    const toBeAssignedFeature = getFeaturePointFromCoordsObj({
      latitude,
      longitude,
      properties: {
        latitude,
        longitude,
        ...rest,
        icon: 'to-be-assigned-marker',
      },
    })
    setToBeAssignedGeojsonData([toBeAssignedFeature])
    setRoutePointGeojsonData(updatedPointGeojsonData)
  }, [stopsByRouteId, selectedRoute])

  useEffect(() => {
    if (isEmpty(stopToBeAssigned)) return
    const formattedStops = formatStops(filteredStops, workOrderId)
    if (isAssignedAndSameRoute(formattedStops)) {
      setRows([...formattedStops])
      return
    }
    setRows([stopToBeAssigned, ...formattedStops])
  }, [stopsByRouteId, selectedRoute, stopToBeAssigned])

  const onSequenceChange = useCallback(
    debounce(
      () => {
        const formattedStops = formatStops(filteredStops, workOrderId)
        const sortedRows = insertElementAtPosition(
          formattedStops,
          stopToBeAssigned,
          selectedSequence,
          isAssignedAndSameRoute(formattedStops)
        )
        const toBeAssignedIndex = sortedRows.findIndex(element => element.toBeInserted === true)

        if (selectedSequence) {
          const updatedRows = sortedRows.map((row, i) => ({ ...row, position: i }))
          setRows(updatedRows)
          const updatedPointGeojsonData = mapStopsToFeaturePoints(sortedRows)
          setRoutePointGeojsonData(updatedPointGeojsonData)
          setToBeAssignedGeojsonData([])
        } else {
          let position = 0
          const updatedRows = sortedRows.map(row => {
            if (row.workOrderId === workOrderId) {
              return { ...row, position: null }
            }
            const newRow = { ...row, position }
            position += 1
            return newRow
          })
          setRows(updatedRows)
          const uniqueFormattedStops = getUniqueStops([...formattedStops])
          const updatedPointGeojsonData = mapStopsToFeaturePoints(uniqueFormattedStops)
          const { latitude, longitude, ...rest } = stopToBeAssigned
          const toBeAssignedFeature = getFeaturePointFromCoordsObj({
            latitude,
            longitude,
            properties: {
              latitude,
              longitude,
              ...rest,
              icon: 'to-be-assigned-marker',
            },
          })
          setToBeAssignedGeojsonData([toBeAssignedFeature])
          setRoutePointGeojsonData(updatedPointGeojsonData)
        }

        try {
          if (selectedSequence && selectedSequence > 1) {
            // Place edit element as second row
            apiRef.current?.scroll({ top: selectedSequence * ROW_HEIGHT - ROW_HEIGHT * 2 })
          } else {
            apiRef.current?.scrollToIndexes({ rowIndex: toBeAssignedIndex })
          }
          setTimeout(() => setFocus('sequence'), 200)
        } catch (error) {}
      },

      750
    ),
    [selectedSequence]
  )

  useEffect(() => {
    if (!isOpen) return
    if (!apiRef.current) return
    setLoadingRows(true)
    onSequenceChange()
    setTimeout(() => {
      setLoadingRows(false)
    }, 950)
  }, [selectedSequence])

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : 'visible'
    if (!isOpen) {
      handleReset()
    }
  }, [isOpen])

  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = 'visible'
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      getRoutesMetadata({
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
      })
    }
  }, [isOpen, serializedServiceDate])

  return (
    <FormProvider {...formMethods}>
      <HHFullScreenBaseDialog onClose={handleCancel} fullScreen open={isOpen}>
        <ScheduleStopDialogAppBar onClose={handleCancel} />
        {loading && <Loader />}
        <Box
          sx={{
            ...(isOpen && {
              html: {
                overflow: 'hidden',
              },
            }),
          }}
          height="100%"
          overflow="hidden"
        >
          <Grid container>
            <Grid item container xs={12}>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={3}>
                <Box px={2} pt={5} height="100%" display="flex" flexDirection="column">
                  <DesktopRoutePicker onChange={handleRouteChange} routes={routes} name="selectedRoute" control={control} />
                  {!isRouteSelected && (
                    <HHAlert severity="info" borderColor={theme.palette.info.main} sx={{ mt: 1 }}>
                      Select a route to assign any unscheduled stops
                    </HHAlert>
                  )}
                  <Box flex={1} height="100%" pt={4}>
                    <StopsDataGrid rows={rows} apiRef={apiRef} loading={loading} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={8} lg={8} xl={9}>
                {!isRouteSelected && !routeId && <ClusterMapView geojsonData={clusterData} />}
                {isRouteSelected && (
                  <SequenceMap
                    toBeAssignedGeojsonData={toBeAssignedGeojsonData}
                    routeGeojsonData={routeGeojsonData}
                    selectedRoute={selectedRoute}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ScheduleStopDialogActions
                onCancel={handleCancel}
                onReset={routeId ? handleResetWithExistingRoute : handleReset}
                onSave={handleSubmit(handleSave)}
                loading={isMoveStopLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </HHFullScreenBaseDialog>
    </FormProvider>
  )
}

ScheduleStopDialog.propTypes = {
  isTemporary: PropTypes.bool,
}

export default ScheduleStopDialog
