import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { Grid } from '@mui/material'

import T from 'T'
import PreviewField from '../common/PreviewField'

import { DEFAULT_STATE } from '../../../common/address/model'

const { accountNamePlaceholder, attentionPlaceholder } = DEFAULT_STATE

const AccountContent = ({ accountNumber, accountName = '', attention = '', showPlaceholder = false }) => {
  return (
    <Grid container rowGap={1.5}>
      <Grid item xs={12}>
        <PreviewField fieldName={capitalize(T.ACCOUNT_NUMBER)} fieldValue={`#${accountNumber}`} variant="h4" fontWeight={600} />
      </Grid>
      <Grid item xs={12}>
        <PreviewField
          fieldName={capitalize(T.CUSTOMER_NAME)}
          fieldValue={accountName}
          showPlaceholder={showPlaceholder}
          placeholderValue={accountNamePlaceholder}
        />
      </Grid>

      <Grid item xs={12}>
        <PreviewField
          fieldName={T.ATTENTION}
          fieldValue={attention}
          showPlaceholder={showPlaceholder}
          placeholderValue={attentionPlaceholder}
        />
      </Grid>
    </Grid>
  )
}

AccountContent.propTypes = {
  accountNumber: PropTypes.string,
  accountName: PropTypes.string,
  attention: PropTypes.string,
  showPlaceholder: PropTypes.bool,
}

export default AccountContent
