import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingPeriodsById: build.query({
      query: profileId => ({
        url: `/api/v1/billing/invoice/billing-profiles/${profileId}/billing-periods/current-cycle`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetBillingPeriodsByIdQuery } = extendedApi
