import { Container, styled } from '@mui/material'

const CustomPrintContainer = styled(Container)`
  @media print {
    background-color: #ffffff;
    & {
      max-width: unset;
      padding-left: unset;
      padding-right: unset;
      margin-left: unset;
      margin-right: unset;
    }
  }
`
export default CustomPrintContainer
