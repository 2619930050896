import T from 'T'

export const NEW_CUSTOMER_TABS_ID = {
  CUSTOMER_TAB_ID: 0, // Initial Selected tab Id
  LOCATION_TAB_ID: 1,
  BILLING_TAB_ID: 2,
  PAYMENT_TAB_ID: 3,
}

export const ADDRESS_REQUIRED_FIELDS = ['line1', 'city', 'state', 'zipCode']
export const BILLING_REQUIRED_FIELDS = ['cycleId', 'paymentTermId']

export const NOTES_VISIBILITY = [T.ROUTE, T.BILLING, T.PAYMENTS]
// We may use all in future
// export const ACTIONS_OPTIONS = ['Take Payment', 'Create Invoice', 'Suspend Service', 'Pause Dunning Sequence', 'Adjust Late Fees'];
export const ACTIONS_OPTIONS = ['Take Payment']
export const DIRECTION_OPTIONS = ['Inbound', 'Outbound']

export const CUSTOMERS_TABS_ID = {
  ACCOUNT_TAB_ID: 'account',
  SERVICE_TAB_ID: 'service',
}

export const CUSTOMER_SEARCH_LOOKUP = [
  { key: 'Address', value: 'address:', description: 'Billing or Location Address' },
  { key: 'Phone', value: 'phone:', description: 'Contact Mobile or Office Phone' },
  { key: 'ContactName', value: 'contact name:', description: 'Customer Individual Contact' },
  { key: 'ContainerId', value: 'container id:', description: 'Container Identification Number' },
]

export const INPUT_MASK_REGEX = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const CUSTOMERS_TAB_OPTIONS = [
  {
    key: T.ACCOUNT_VIEW,
    value: CUSTOMERS_TABS_ID.ACCOUNT_TAB_ID,
  },
  {
    key: T.SERVICE_VIEW,
    value: CUSTOMERS_TABS_ID.SERVICE_TAB_ID,
  },
]
