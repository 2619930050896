import React, { memo } from 'react'
import { Stack } from '@mui/material'
import WeekDayIcon from 'components/customer-details/content/routing/WeekDayIcon'
import PropTypes from 'prop-types'

const WeekSummary = ({ daysOfWeek = [] }) => {
  return (
    <Stack direction="row" spacing={0.5}>
      {daysOfWeek.map(({ day, variant }) => {
        return <WeekDayIcon day={day} variant={variant} />
      })}
    </Stack>
  )
}

WeekSummary.propTypes = {
  daysOfWeek: PropTypes.array,
}

export default memo(WeekSummary)
