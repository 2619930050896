import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'

import { useGridApiRef } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import { DialogContent, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { getFilteredRowsForAddRemoveDialog } from 'data/groups/accountGroupsSelectors'
import { getAccountMeta as getAccountMetaData } from 'data/meta/accountMetaSelectors'
import { getTagsByVisibility } from 'data/tags/tagsMetadataSelector'
import { useLazyGetAccountMetaQuery } from 'api/meta/getAccountMeta'
import { NO_TAGS_OPTION } from 'components/locations/filters/TagFilter/settings'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SearchField from 'components/customers/groups/account/add-remove/common/SearchField'
import DataGrid from 'components/customers/groups/account/add-remove/common/DataGrid'
import { ADD_REMOVE_FORM_MODEL } from 'components/customers/groups/account/add-remove/common/settings'
import { noop } from 'lodash'
import Filters from 'components/customers/groups/account/add-remove/common/Filters'
import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'

const HHBulkSelectAccountsDialog = ({
  CancelButtonProps,
  ConfirmButtonProps,
  DataGridProps,
  confirmButtonTitle = T.SAVE,
  isConfirmLoading = false,
  isLoading = false,
  isOpen = false,
  onClose = noop,
  onConfirm = noop,
  rows = [],
  useDisabledCheckedIcons = false,
  title,
}) => {
  const apiRef = useGridApiRef()
  const [getAccountMeta, { isSuccess: isAccountMetaSuccess }] = useLazyGetAccountMetaQuery()
  const addToGroupForm = useForm({ defaultValues: { ...ADD_REMOVE_FORM_MODEL } })
  const { watch, reset, handleSubmit } = addToGroupForm

  const customerMeta = useSelector(getAccountMetaData, shallowEqual)
  const selectionModel = watch('selectionModel')
  const search = watch('search')
  const selectedTags = watch('selectedTags')
  const selectedBillingProfiles = watch('selectedBillingProfiles')
  const selectedTagsIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags])
  const selectedBillingProfilesIds = useMemo(() => selectedBillingProfiles.map(({ id }) => id), [selectedBillingProfiles])

  const filteredRows = useMemo(
    () => getFilteredRowsForAddRemoveDialog({ rows, search, selectedTagsIds, selectedBillingProfilesIds }),
    [rows, search, selectedTagsIds, selectedBillingProfilesIds]
  )

  const handleAddAccountsToGroup = data => {
    const accounts = data.selectionModel
    onConfirm({ accounts, onClose })
  }

  useEffect(() => {
    if (isOpen && isAccountMetaSuccess) {
      const billingProfiles = get(customerMeta, 'billingProfiles', [])
      const tagsByVisibility = getTagsByVisibility({ tags: get(customerMeta, 'tags', []) })
      const allTags = [NO_TAGS_OPTION, ...tagsByVisibility]
      reset({ ...ADD_REMOVE_FORM_MODEL, selectedTags: allTags, selectedBillingProfiles: billingProfiles })
    }
  }, [isOpen, isAccountMetaSuccess])

  useEffect(() => {
    if (isOpen) {
      getAccountMeta()
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl" fullWidth>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <FormProvider {...addToGroupForm}>
          <Grid mt={1} container spacing={2} alignItems="center">
            <Grid item xs>
              <SearchField />
            </Grid>
            <Grid item xs="auto">
              <Filters />
            </Grid>
          </Grid>
          <DataGrid
            slots={{
              baseCheckbox: params => (
                <DataGridBaseCheckbox
                  {...params}
                  icon={params.disabled && useDisabledCheckedIcons ? <CheckCircleSharpIcon /> : <RadioButtonUncheckedSharpIcon />}
                  rows={filteredRows}
                />
              ),
            }}
            isLoading={isLoading}
            apiRef={apiRef}
            rows={rows}
            filteredRows={filteredRows}
            {...DataGridProps}
          />
        </FormProvider>
      </DialogContent>
      <HHDialogActions>
        <CancelButton size="medium" onClick={onClose} {...CancelButtonProps} />
        <LoadingButton
          loading={isConfirmLoading}
          disabled={selectionModel.length === 0 || isConfirmLoading}
          size="small"
          variant="contained"
          onClick={handleSubmit(handleAddAccountsToGroup)}
          {...ConfirmButtonProps}
        >
          {confirmButtonTitle}
        </LoadingButton>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

HHBulkSelectAccountsDialog.propTypes = {
  CancelButtonProps: PropTypes.object,
  ConfirmButtonProps: PropTypes.object,
  DataGridProps: PropTypes.object,
  confirmButtonTitle: PropTypes.string,
  isConfirmLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  rows: PropTypes.array,
  title: PropTypes.string,
  useDisabledCheckedIcons: PropTypes.bool,
}

export default HHBulkSelectAccountsDialog
