import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getReportFullList: builder.query({
      query: () => ({ url: '/api/v1/core/report-configs' }),
    }),
  }),
})

export const { useLazyGetReportFullListQuery, useGetReportFullListQuery } = extendedApi
