import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash'
import { HHFormAutocompleteField } from '../../../form-fields/v5'
import MultiSelectItem from '../../../form-fields/v5/MultiSelectItem'
import { getActiveDeactivatedLocations } from '../../../../utils/location'
import PopupPaper from '../../../form-fields/v5/PopupPaper'
import { selectAddressName } from '../../../../data/address/addressSelectors'
import { combineAddressInfo } from '../../../../utils/helper'
import T from '../../../../T'

const LocationsAutocompleteFormField = ({
  sx,
  allLocationsSelected,
  control,
  error = false,
  helperText,
  label,
  name,
  readOnly = false,
  required = false,
  rules,
  setValue,
}) => {
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null), shallowEqual)
  const { activeLocations } = getActiveDeactivatedLocations(customerDetails)

  const handleToggleSelectAll = () => {
    setValue('allLocationsSelected', !allLocationsSelected)
    if (!allLocationsSelected) {
      setValue(name, [...activeLocations])
    } else {
      setValue(name, [])
    }
  }

  const getLocationOptionLabel = option => {
    const address = get(option, 'address', {})
    const addressName = selectAddressName({ ...address })
    if (addressName) return addressName
    return combineAddressInfo(address)
  }

  const renderLocationOption = ({ className, ...rest }, option, { selected }) => {
    return (
      <MultiSelectItem key={`location-option-${getLocationOptionLabel(option)}`} {...rest} selected={selected}>
        {getLocationOptionLabel(option)}
      </MultiSelectItem>
    )
  }

  const renderLocationTags = (value, getTagProps) =>
    value.map((option, index) => {
      if (index === 0)
        return (
          <>
            {allLocationsSelected && <Chip size="small" label={T.ALL_LOCATIONS} onDelete={handleToggleSelectAll} />}
            <Chip size="small" label={getLocationOptionLabel(option)} {...getTagProps({ index })} />
          </>
        )
      return <Chip size="small" label={getLocationOptionLabel(option)} {...getTagProps({ index })} />
    })

  const onChange = (_, newValue, reason) => {
    const newValueCount = get(newValue, 'length', 0)
    if (reason === 'clear' || reason === 'removeOption') {
      setValue('allLocationsSelected', false)
      setValue(name, newValue)
    } else if (reason === 'selectOption' && newValueCount === activeLocations.length) {
      setValue('allLocationsSelected', true)
      setValue(name, activeLocations)
    } else {
      setValue(name, newValue)
    }
  }

  const SelectAllPaperComponent = paperProps => {
    const { children, ...restPaperProps } = paperProps
    return (
      <PopupPaper {...restPaperProps}>
        <MultiSelectItem
          selected={allLocationsSelected}
          onMouseDown={e => {
            e.preventDefault() // prevent blur
            handleToggleSelectAll()
          }}
        >
          Select all
        </MultiSelectItem>
        {children}
      </PopupPaper>
    )
  }

  const isOptionEqualToValue = (option, value) => value.id === option.id

  return (
    <HHFormAutocompleteField
      clearIcon={false}
      sx={sx}
      isOptionEqualToValue={isOptionEqualToValue}
      PaperComponent={SelectAllPaperComponent}
      renderOption={renderLocationOption}
      renderTags={renderLocationTags}
      readOnly={readOnly}
      helperText={helperText}
      rules={rules}
      error={error}
      control={control}
      name={name}
      required={required}
      deprecatedLabel={false}
      label={required && label ? `${label} *` : label}
      fullWidth
      options={activeLocations}
      multiple
      getOptionLabel={getLocationOptionLabel}
      onChange={onChange}
    />
  )
}

LocationsAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  allLocationsSelected: PropTypes.bool.isRequired,
}

export default LocationsAutocompleteFormField
