import { styled } from '@mui/material'
import HHFullScreenBaseDialog from 'components/common/HHFullScreenBaseDialog'

const StyledFullScreenBaseDialog = styled(HHFullScreenBaseDialog)`
  @page {
    size: auto;
    margin: 30px 20px 30px 20px;
  }

  height: 100%;
  width: 100%;
`
export default StyledFullScreenBaseDialog
