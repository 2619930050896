import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom-v5-compat'
import { useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { List, ListItemIcon, ListItemText, ListItemButton, Collapse, Divider, Box, useMediaQuery, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { isFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { setShowBillingSideNav } from 'slices/billing/BillingSideNavSlice'

const MenuGroup = ({ groupName = '', menuItems = [] }) => {
  const flags = useFlags()
  const location = useLocation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true)
  const pathname = get(location, 'pathname')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const handleMenuClick = () => {
    if (isTabletOrMobile) {
      // Close menu on small devices
      dispatch(setShowBillingSideNav(false))
    }
  }

  const handleClick = () => setIsOpen(!isOpen)

  useEffect(() => {
    // Right now all options are expanded, this will be useful once we only expand the selected option
    if (pathname && menuItems.find(({ url }) => url === pathname)) {
      setIsOpen(true)
    }
  }, [pathname])

  return (
    <>
      <List disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ minWidth: 32 }}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'h6', fontWeight: 500 }} primary={groupName} />
        </ListItemButton>
        <Divider />
      </List>

      <Box>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ mb: 1 }}>
            {Children.toArray(
              menuItems.map(
                ({ label, url, featureFlag }) =>
                  (!featureFlag || isFeatureAvailable(flags, featureFlag)) && (
                    <ListItemButton sx={{ pl: 3 }} component={Link} selected={pathname === url} to={url} onClick={handleMenuClick}>
                      <ListItemText primaryTypographyProps={{ variant: 'h6', fontWeight: 400 }} primary={label} />
                    </ListItemButton>
                  )
              )
            )}
          </List>
        </Collapse>
      </Box>
    </>
  )
}

MenuGroup.propTypes = {
  groupName: PropTypes.string,
  menuItems: PropTypes.array,
}

export default MenuGroup
