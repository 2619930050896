import React from 'react'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import ServiceNameWrapper from './ServiceNameWrapper'
import MeasureChip from '../../../../../containers/new-route-manager/RouteCard/MeasureChip'
import InteractiveTagsList from '../../../../tags/InteractiveTagsList'
import CommonNumberFormat from '../../../../common/CommonNumberFormat'

const TopRow = ({ service, onServiceNameClick = noop, onSaveTags = noop }) => {
  const serviceName = get(service, 'name', '')
  const isDraftRow = get(service, 'status', '').toLowerCase() === 'draft'
  const volume = get(service, 'measure.volume', '')
  const unit = get(service, 'measure.unit', '')
  const measureLabel = [volume, unit].join(' ').trim()
  const serviceId = get(service, 'id', '')
  const price = get(service, 'price', 0)

  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const isXl = useMediaQuery(theme => theme.breakpoints.up('xl'))
  const isXXl = useMediaQuery(theme => theme.breakpoints.up('xxl'))
  const getTagLimit = () => {
    if (isXXl) return 7
    if (isXl) return 6
    if (isDesktop) return 4
    if (isTablet) return 3
    if (isSm) return 1
    return 1
  }

  const tagLimit = getTagLimit()

  return (
    <Grid item container direction="row" spacing={1} alignItems="center" justifyContent="space-between" flexWrap="nowrap" xs>
      <Grid item xs>
        <ServiceNameWrapper serviceName={serviceName} onClick={onServiceNameClick} />
      </Grid>
      <Grid item xs>
        {measureLabel && <MeasureChip measureUnit={unit} label={measureLabel} />}
      </Grid>
      <Grid item xs={12}>
        <InteractiveTagsList
          data={{
            ...service,
            name: serviceName,
            configuredServiceId: serviceId,
          }}
          limit={tagLimit}
          onSave={onSaveTags}
          disableAccount
          disableLocation
          tagsFlexWrap="nowrap"
          tagMaxWidth="90px"
        />
      </Grid>
      <Grid item justifySelf="flex-end">
        <Typography fontWeight="500" color={service.deactivate || isDraftRow ? 'text.disabled' : 'textPrimary'}>
          <CommonNumberFormat className="font500" value={price} displayType="text" decimalScale={2} prefix="$" />
        </Typography>
      </Grid>
    </Grid>
  )
}

TopRow.propTypes = {
  service: PropTypes.object.isRequired,
  onServiceNameClick: PropTypes.func,
  onSaveTags: PropTypes.func,
}

export default TopRow
