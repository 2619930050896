import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { get, noop } from 'lodash'
import { useDispatch } from 'react-redux'
import T from 'T'
import VirtualizedAutocomplete from './VirtualizedAutocomplete'
import ListboxComponent from './ListboxComponent'
import SearchAddressItem from './SearchAddressItem'
import { useLazyGetAddressSuggestListQuery } from '../../../api/map/getAddressSuggestList'
import { putMarkerLocation } from '../../../middleware/actions/routeManager'

function renderRow(props) {
  const { data, index, style } = props
  const dataSet = data[index]
  return <SearchAddressItem dataSet={dataSet} index={index} style={style} />
}

const ListboxComponentWrapper = forwardRef((props, ref) => {
  return <ListboxComponent maxTotalHeight={900} rowHeight={52} {...props} ref={ref} renderRow={renderRow} />
})
const SearchAddress = ({ sx, onChange, onOpen = noop, onClose = noop, onReset }) => {
  const dispatch = useDispatch()
  const [value, setValue] = React.useState()
  const [inputValue, setInputValue] = useState('')
  const [getAddressSuggestionList, { isLoading, isFetching, data }] = useLazyGetAddressSuggestListQuery()
  const suggestions = get(data, 'suggestions', [])
  const getDebouncedSuggestions = useMemo(() => debounce(getAddressSuggestionList, 300), [])

  useEffect(() => {
    return () => {
      getDebouncedSuggestions.cancel()
    }
  }, [])

  const handleInputChange = (e, newInputValue, reason) => {
    if (reason === 'clear') {
      setInputValue(newInputValue)
      dispatch(putMarkerLocation(null))
      onReset(true)
    } else {
      setInputValue(newInputValue)
      if (!newInputValue) return
      getDebouncedSuggestions({ search: newInputValue })
    }
  }

  const handleChange = (event, newValue) => {
    if (!newValue) return
    const streetName = `${get(newValue, 'text', '')}`.trim()
    onChange(newValue, streetName)
    setValue(newValue)
  }

  return (
    <VirtualizedAutocomplete
      onOpen={onOpen}
      onClose={onClose}
      sx={sx}
      freeSolo
      value={value}
      onChange={handleChange}
      ListboxComponent={ListboxComponentWrapper}
      options={suggestions}
      inputValue={inputValue}
      loading={isLoading || isFetching}
      onInputChange={handleInputChange}
      placeholder={T.SEARCH_ADDRESS_PLACEHOLDER}
      getOptionLabel={option => {
        const streetName = `${get(option, 'text', '')}`.trim()
        const cityName = get(option, 'city', '')
        const stateName = get(option, 'state', '')
        const areaName = cityName && stateName ? `${cityName}, ${stateName}` : cityName || stateName
        const leftSideData = streetName
        const leftSideAddressData = areaName
        return [leftSideData, leftSideAddressData].join(' ')
      }}
    />
  )
}

SearchAddress.propTypes = {
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onReset: PropTypes.func.isRequired,
}

export default SearchAddress
