import React, { Children } from 'react'
import orderBy from 'lodash/orderBy'
import first from 'lodash/first'
import { addDays, getDay } from 'date-fns'
import PropTypes from 'prop-types'
import { getWorkOrderDetailsPageUrl } from 'router/routes'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  styled,
  Tooltip,
  Dialog,
  Alert,
  Button,
  Link,
} from '@mui/material'

import CancelButton from 'components/buttons/CancelButton'

import T from 'T'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { TableSkelton } from 'components/common'
import { WORK_ORDER_COLUMNS } from 'settings/constants/service'
import { formatDateToFEFormat, createDateFromString } from 'utils/date'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 6,
  backgroundColor: theme.palette.background.paper,
}))

const DAYS = [T.SUNDAY, T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY, T.SATURDAY]

const ConfirmServiceDays = ({
  isOpenDrawer = false,
  workOrders = [],
  serviceEvents = [],
  accountName = '',
  startDate = '',
  isLoading,
  onClose,
  handleProceed = () => {},
}) => {
  const updatedEvent = serviceEvents.filter(event => !event.id).map(rec => rec.dayOfTheWeek)

  return (
    <Dialog open={isOpenDrawer} onClose={onClose}>
      <Box
        display="flex"
        sx={{
          p: '18px 18px 10px',
        }}
      >
        <Stack sx={{ padding: '10px 74px 4px' }}>
          <Typography variant="h6" textAlign="center">
            {T.REVIEW_CONFIRM_DAYS}
          </Typography>

          <Typography variant="body1" textAlign="center" marginTop={1.5}>
            {T.RE_ROUTE_UPDATES}
          </Typography>
        </Stack>

        <CloseIcon fontSize="small" sx={{ cursor: 'pointer', color: 'text.routeIndexText' }} onClick={() => onClose(false)} />
      </Box>

      <Divider sx={{ margin: '15px 10px 0px' }} />

      <Box p="14px 20px 0">
        <Typography variant="h6">{T.AFFECTED_WORK_ORDERS}</Typography>

        <TableContainer
          sx={{
            maxHeight: 222,
            marginTop: 1,
            '&::-webkit-scrollbar': {
              width: 0,
              background: 'transparent',
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {WORK_ORDER_COLUMNS.map(label => {
                  return (
                    <StyledTableCell align="left" sx={{ paddingY: '12px' }}>
                      <Box display="flex" alignItems="center" justifyContent="start">
                        <Typography variant="subtitle2">{label}</Typography>
                      </Box>
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && <TableSkelton rows={4} columns={4} />}
              {!isLoading && (!Array.isArray(workOrders) || workOrders.length === 0) && (
                <TableRow>
                  <StyledTableCell colspan="100%" className="no-border">
                    <Box className="no-results">{T.NO_RECORDS}</Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {!isLoading &&
                Children.toArray(
                  orderBy(workOrders, ['date'], ['asc']).map(record => {
                    const previousDate = formatDateToFEFormat(record.date)
                    const previousDayNumber = getDay(createDateFromString(first(workOrders).date))
                    const newDayNumber = DAYS.indexOf(first(updatedEvent))
                    const dayDiff = newDayNumber - previousDayNumber
                    const newDate = formatDateToFEFormat(addDays(new Date(previousDate), dayDiff))
                    const formattedStartDate = formatDateToFEFormat(new Date(startDate))
                    const newValidDate = new Date(newDate) > new Date(formattedStartDate) ? newDate : '-'
                    return (
                      <TableRow>
                        <StyledTableCell>
                          <Typography
                            variant="body1"
                            sx={{
                              cursor: 'pointer',
                              textDecorationLine: 'underline',
                            }}
                          >
                            <Link href={getWorkOrderDetailsPageUrl(get(record, 'workOrderId'))} color="inherit">
                              {get(record, 'workOrderSequence', '')}
                            </Link>
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography variant="body1" color="text.secondary">
                            {previousDate}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography variant="body1">{newValidDate}</Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography variant="body1" color="error.main">
                            {T.UNASSIGNED}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box display="flex" justifyContent="center" mt={2.5} mb={2.5}>
        <Tooltip title={T.DAYS_CANCEL_SELECTION} placement="top">
          <Box sx={{ margin: 'auto 0' }}>
            <CancelButton onClick={() => onClose(true)} />
          </Box>
        </Tooltip>

        <Button size="small" variant="contained" color="primary" onClick={handleProceed} sx={{ ml: 1.5 }}>
          {T.CONFIRM}
        </Button>
      </Box>
    </Dialog>
  )
}

ConfirmServiceDays.propTypes = {
  isOpenDrawer: PropTypes.bool,
  isLoading: PropTypes.bool,
  workOrders: PropTypes.array,
  serviceEvents: PropTypes.array,
  accountName: PropTypes.string,
  startDate: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  handleProceed: PropTypes.func,
}

export default memo(ConfirmServiceDays)
