import { createSelector } from 'reselect'

import { get } from 'utils/lodash'
import { convertTimeToTimezone } from 'utils/timezoneDate'
import { getUserInfo } from 'data/userInfo/userInfoSelectors'
import { TIME_ZONES_SHORT_FORM } from 'settings/constants/timeZones'
import { FRONTEND_DATE_FORMAT_DATE_FNS, FRONTEND_TIME_FORMAT_DATE_FNS, FRONTEND_ZONE_FORMAT_DATE_FNS } from 'settings/constants/date'
import format from 'date-fns/format'
import { getLocalDate } from '../../utils/date'

const EMPTY_RESPONSE = { fullDateTime: '', onlyDate: '', onlyTime: '', DSTZone: '', zone: '' }

const selectDateTime = state => get(state, 'dateTime', '')
const selectDefaultZone = state => get(state, 'defaultZone', '')
const selectDefaultDateFormat = state => get(state, 'defaultDateFormat', FRONTEND_DATE_FORMAT_DATE_FNS)
const selectDefaultTimeFormat = state => get(state, 'defaultTimeFormat', FRONTEND_TIME_FORMAT_DATE_FNS)

export const convertDateTimeToPreferedTimezone = createSelector(
  [selectDateTime, selectDefaultZone, selectDefaultDateFormat, selectDefaultTimeFormat],
  (dateTime, defaultZone, defaultDateFormat, defaultTimeFormat) => {
    try {
      if (!dateTime) {
        return { ...EMPTY_RESPONSE }
      }

      const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`
      const userTimeZone = get(getUserInfo(), 'timeZone', defaultZone)

      // Don't include zone here, calculate zone separately
      const convertToUserTimeZone = userTimeZone
        ? convertTimeToTimezone(dateTime, defaultDateTimeFormat, userTimeZone)
        : getLocalDate(dateTime)
      const convertToDate = new Date(convertToUserTimeZone)

      return {
        fullDateTime: userTimeZone ? convertToUserTimeZone : format(convertToDate, defaultDateTimeFormat, { timezone: userTimeZone }),
        onlyDate: format(convertToDate, defaultDateFormat, { timezone: userTimeZone }),
        onlyTime: format(convertToDate, defaultTimeFormat, { timezone: userTimeZone }),
        DSTZone: userTimeZone ? convertTimeToTimezone(dateTime, FRONTEND_ZONE_FORMAT_DATE_FNS, userTimeZone) : '',
        zone: get(TIME_ZONES_SHORT_FORM, userTimeZone, ''),
      }
    } catch (err) {
      return { ...EMPTY_RESPONSE }
    }
  }
)
