import React, { useEffect, useMemo, Children } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, DialogContent, Divider, IconButton, ListItemText, Tooltip, Typography, Stack } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CancelIcon from '@mui/icons-material/Cancel'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { formatCentsToDollars } from 'utils/price'
import { RECURRENCE } from 'settings/constants/service'
import { createDateFromString, formatDateToBEFormatDateFns, isValidDate } from 'utils/date'
import { useLazyGetServiceDetailsQuery } from 'api/configured-service/getServiceDetails'
import {
  useLazyGetRentalFeesByConfiguredServiceQuery,
  useAddRentalFeesToConfiguredServiceMutation,
  useUpdateRentalFeesConfiguredServiceMutation,
  useDeleteRentalFeesFromConfiguredServiceMutation,
} from 'api/configured-service/configuredServiceRentalFeesCrud'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { HHFormPriceField } from 'components/form-fields/v5'
import HHFormDesktopDatePicker from 'components/form-fields/v5/HHFormDesktopDatePicker'
import SkeletonListItem from './ListSkeleton'
import RentalFeeFormField from '../common/field/RentalFeeFormField'

const { RECURRING } = RECURRENCE

const RentalFeesDialog = ({ isOpen = false, configuredServiceId, onClose }) => {
  const [getServiceDetails, { isFetching: isGetServiceDetailsLoading, data: csData, isSuccess: isSuccessGetServiceDetails }] =
    useLazyGetServiceDetailsQuery()
  const [
    getRentalFeesByConfiguredService,
    {
      isFetching: isGetRentalFeesByConfiguredServiceLoading,
      data: configuredRentalFeesData,
      isSuccess: isSuccessGetRentalFeesByConfiguredServiceLoading,
    },
  ] = useLazyGetRentalFeesByConfiguredServiceQuery()
  const [addRentalFeesToConfiguredService, { isLoading: isAddLoading }] = useAddRentalFeesToConfiguredServiceMutation()
  const [updateRentalFeesConfiguredService, { isLoading: isUpdateLoading }] = useUpdateRentalFeesConfiguredServiceMutation()
  const [deleteRentalFeesFromConfiguredService, { isLoading: isDeleteLoading }] = useDeleteRentalFeesFromConfiguredServiceMutation()

  const form = useForm({ defaultValues: { rentalFees: [] } })
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = form
  const rentalFees = watch('rentalFees')
  const selectedRentalFeeIds = rentalFees.filter(({ rentalFeeId }) => rentalFeeId).map(({ rentalFeeId }) => rentalFeeId)

  const configuredRentalFees = useMemo(() => get(configuredRentalFeesData, 'configuredRentalFees', []), [configuredRentalFeesData])
  const { serviceType, serviceName, price, containerCount, serviceStartDateBEFormat } = useMemo(
    () => ({
      pricedServiceId: get(csData, 'priceServiceDetail.id', ''),
      serviceType: get(csData, 'priceServiceDetail.serviceType', ''),
      serviceName: get(csData, 'priceServiceDetail.serviceName', ''),
      price: get(csData, 'priceServiceDetail.price', 0),
      containerCount: get(csData, 'advanced.containerList', []).length,
      serviceStartDateBEFormat: get(csData, 'schedule.startDate', ''),
    }),
    [csData]
  )
  const isRecurringService = useMemo(() => serviceType === RECURRING, [serviceType])
  const serviceStartDate = isRecurringService && serviceStartDateBEFormat ? createDateFromString(serviceStartDateBEFormat) : new Date()

  const isGetLoading = useMemo(
    () => isGetServiceDetailsLoading || isGetRentalFeesByConfiguredServiceLoading,
    [isGetServiceDetailsLoading, isGetRentalFeesByConfiguredServiceLoading]
  )

  const isSaveLoading = useMemo(() => isAddLoading || isUpdateLoading || isDeleteLoading, [isAddLoading, isUpdateLoading, isDeleteLoading])

  const handleRentalFeeChange = (index, rentalFee) => {
    setValue(`rentalFees.${index}.amount`, formatCentsToDollars(rentalFee.amountCents))
  }

  const validateDate = date => {
    return isValidDate(date) ? true : T.THIS_FIELD_IS_REQUIRED
  }

  const handleClear = index => {
    setValue(`rentalFees.${index}.rentalFeeId`, '', { shouldDirty: true })
    setValue(`rentalFees.${index}.startDate`, serviceStartDate)
    setValue(`rentalFees.${index}.amount`, 0)
  }

  const getInitialData = () => {
    getServiceDetails(configuredServiceId).unwrap().catch(handleError)
    getRentalFeesByConfiguredService({ id: configuredServiceId }).unwrap().catch(handleError)
  }

  const handleSave = async data => {
    if (!isDirty) {
      onClose()
      return
    }
    const toBeAddedRentalFees = []
    const toBeUpdatedRentalFees = []
    const toBeDeletedRentalFees = []
    data.rentalFees.forEach(({ rentalFeeToConfiguredServiceId, rentalFeeId, startDate }) => {
      if (!rentalFeeToConfiguredServiceId && rentalFeeId) {
        // Add rental fee
        toBeAddedRentalFees.push({
          rentalFeeId,
          startDate: formatDateToBEFormatDateFns(startDate),
        })
      }
      if (rentalFeeToConfiguredServiceId && rentalFeeId) {
        // Update rental fee
        toBeUpdatedRentalFees.push({
          rentalFeeToConfiguredServiceId,
          rentalFeeId,
          startDate: formatDateToBEFormatDateFns(startDate),
        })
      }
      if (rentalFeeToConfiguredServiceId && !rentalFeeId) {
        // Delete rental fee
        toBeDeletedRentalFees.push(rentalFeeToConfiguredServiceId)
      }
    })

    const allPromises = []
    if (toBeDeletedRentalFees.length) {
      allPromises.push(
        deleteRentalFeesFromConfiguredService({
          id: configuredServiceId,
          rentalFeeToConfiguredServiceIds: toBeDeletedRentalFees,
        })
          .unwrap()
          .then(() => Promise.resolve({ success: true }))
          .catch(error => {
            handleError(error)
            return Promise.resolve({ error: true })
          })
      )
    }

    if (toBeAddedRentalFees.length) {
      allPromises.push(
        addRentalFeesToConfiguredService({ id: configuredServiceId, configuredRentalFees: toBeAddedRentalFees })
          .unwrap()
          .then(() => Promise.resolve({ success: true }))
          .catch(error => {
            handleError(error)
            return Promise.resolve({ error: true })
          })
      )
    }

    if (toBeUpdatedRentalFees.length) {
      // Add check to only call PUT API if there is any change in rental fees
      allPromises.push(
        updateRentalFeesConfiguredService({ id: configuredServiceId, rentalFeesToConfiguredService: toBeUpdatedRentalFees })
          .unwrap()
          .then(() => Promise.resolve({ success: true }))
          .catch(error => {
            handleError(error)
            return Promise.resolve({ error: true })
          })
      )
    }

    const response = await Promise.all(allPromises)
    const hasError = response.find(({ error }) => error)
    if (hasError) {
      getInitialData()
      return
    }

    toast.success('Rental fees saved successfully')
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      reset({ rentalFees: [] })
      getInitialData()
    }
  }, [isOpen])

  useEffect(() => {
    if (!isGetLoading && isSuccessGetServiceDetails && isSuccessGetRentalFeesByConfiguredServiceLoading) {
      const sortedConfiguredRentalFees = sortByDateSelector({ data: configuredRentalFees, key: 'createdAt', order: 'asc' })
      let newRentalFees = sortedConfiguredRentalFees.map(({ id, rentalFeeId, startDate, amountCents }) => ({
        rentalFeeToConfiguredServiceId: id,
        rentalFeeId,
        startDate: createDateFromString(startDate),
        amount: formatCentsToDollars(amountCents),
      }))

      if (newRentalFees.length < containerCount) {
        newRentalFees = newRentalFees.concat(
          Array(containerCount - newRentalFees.length).fill({ rentalFeeId: '', startDate: serviceStartDate, amount: 0 })
        )
      }

      reset({ rentalFees: newRentalFees })
    }
  }, [isGetLoading, isSuccessGetServiceDetails, isSuccessGetRentalFeesByConfiguredServiceLoading])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.RENTAL_FEES} onClose={onClose} />
      <DialogContent sx={{ px: 0 }}>
        {isGetLoading && <SkeletonListItem rows={1} />}
        {!isGetLoading && (
          <FormProvider {...form}>
            <ListItemText
              sx={{ px: 3 }}
              primary={
                <Typography variant="h4" fontWeight={600} noWrap>
                  {`${serviceName} | `}
                  <HHDisplayMoney value={price} formatToDollars={false} />
                </Typography>
              }
              primaryTypographyProps={{ variant: 'h4', fontWeight: 600, noWrap: true }}
              secondary={
                <Typography variant="h5" fontWeight={500} color="textSecondary" display="flex" alignItems="center" gap={0.5}>
                  <Tooltip arrow placement="bottom" title="The number of rental fees cannot exceed the number of containers">
                    <InfoOutlinedIcon color="action" />
                  </Tooltip>
                  {`${containerCount} container${containerCount === 1 ? '' : 's'}`}
                </Typography>
              }
              secondaryTypographyProps={{
                variant: 'h5',
                fontWeight: 500,
                color: 'textSecondary',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            />
            <Divider sx={{ my: 2 }} />
            <Box sx={{ px: 3 }}>
              {Children.toArray(
                rentalFees.map((rentalFee, index) => {
                  const rentalFeeId = get(rentalFee, 'rentalFeeId', '')
                  const startDateErrorMessage = get(errors, `rentalFees.${index}.startDate.message`)
                  const startDateError = Boolean(startDateErrorMessage)

                  return (
                    <Stack direction="column" rowGap={2} mb={2}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4" fontWeight={600}>
                          {`Rental fee ${index + 1}`}
                        </Typography>
                        {rentalFeeId && (
                          <IconButton onClick={() => handleClear(index)}>
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Box>

                      <RentalFeeFormField
                        name={`rentalFees.${index}.rentalFeeId`}
                        value={rentalFeeId}
                        excludeList={selectedRentalFeeIds}
                        onChange={newRentalFee => handleRentalFeeChange(index, newRentalFee)}
                      />
                      {rentalFeeId && (
                        <>
                          <HHFormDesktopDatePicker
                            name={`rentalFees.${index}.startDate`}
                            control={control}
                            label={T.START_DATE}
                            rules={{ required: T.THIS_FIELD_IS_REQUIRED, validate: validateDate }}
                            TextFieldProps={{
                              deprecatedLabel: false,
                              error: startDateError,
                              helperText: startDateErrorMessage,
                            }}
                            // Verify this
                            {...(isRecurringService && { minDate: serviceStartDate })}
                          />
                          <HHFormPriceField
                            fullWidth
                            name={`rentalFees.${index}.amount`}
                            control={control}
                            deprecatedLabel={false}
                            label={T.AMOUNT}
                            disabled
                          />
                        </>
                      )}
                    </Stack>
                  )
                })
              )}
            </Box>
          </FormProvider>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isSaveLoading}
          disabled={isSaveLoading}
          label={T.SAVE}
          onClick={handleSubmit(handleSave)}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RentalFeesDialog.propTypes = {
  isOpen: PropTypes.bool,
  configuredServiceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

export default RentalFeesDialog
