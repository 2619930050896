import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import { useFormContext } from 'react-hook-form'
import Grid from '@mui/material/Grid'

import { getAddressParams } from 'utils/helper'
import { HHFormTextField } from 'components/form-fields/v5'
import { DEFAULT_STATE } from 'components/common/address/model'

import T from 'T'
import TagsSelect from 'components/tags/TagsSelect'
import TagsWrapper from 'components/customers/add/tab-content/TagsWrapper'
import AddressRowOne from 'components/common/address/AddressRowOne'
import AddressRowTwo from 'components/common/address/AddressRowTwo'
import AddressLatLong from 'components/common/address/AddressLatLong'

const { addressNamePlaceholder } = DEFAULT_STATE

const AddressForm = ({ metaTags = [], setLatLngForMap, setLatLngForMapHistory }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { address: addressErrors } = errors
  const watchLine1 = watch('address.line1')
  const watchCity = watch('address.city')
  const watchState = watch('address.state')
  const watchZipCode = watch('address.zipCode')
  const watchLat = watch('address.latitude')
  const watchLong = watch('address.longitude')

  const handleAddressChange = address => {
    const { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed } = getAddressParams(address)
    setValue('address.line1', line1)
    setValue('address.city', city)
    setValue('address.state', state)
    setValue('address.country', country)
    setValue('address.zipCode', zipCode)
    setValue('address.latitude', latitudeFixed)
    setValue('address.longitude', longitudeFixed)
    setLatLngForMap({ longitudeForMap: longitudeFixed, latitudeForMap: latitudeFixed })
    setLatLngForMapHistory({ longitudeForMapHistory: longitudeFixed, latitudeForMapHistory: latitudeFixed })
  }

  return (
    <Grid container item xs={12} md={5} rowGap={2} height="fit-content">
      <Grid item xs={12}>
        <HHFormTextField
          control={control}
          name="address.addressName"
          label={capitalize(T.SERVICE_LOCATION_NAME)}
          placeholder={addressNamePlaceholder}
          fullWidth
          deprecatedLabel={false}
        />
      </Grid>

      <AddressRowOne
        control={control}
        line1={watchLine1}
        hasLine1Error={addressErrors?.line1 && !watchLine1}
        onLine1Change={newValue => setValue('address.line1', newValue)}
        onBillingAddressChange={handleAddressChange}
      />

      <AddressRowTwo
        control={control}
        hasCityError={addressErrors?.city && !watchCity}
        hasStateError={addressErrors?.state && !watchState}
        hasCountryError={addressErrors?.zipCode && !watchZipCode}
      />

      <AddressLatLong
        control={control}
        lat={watchLat}
        long={watchLong}
        hasLatError={addressErrors?.latitude}
        hasLongError={addressErrors?.longitude}
        onBlurLat={value => {
          setLatLngForMap(prev => ({ ...prev, latitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, latitudeForMapHistory: value }))
        }}
        onBlurLong={value => {
          setLatLngForMap(prev => ({ ...prev, longitudeForMap: value }))
          setLatLngForMapHistory(prev => ({ ...prev, longitudeForMapHistory: value }))
        }}
      />

      <Grid item xs={12}>
        <TagsWrapper>
          <TagsSelect
            deprecatedLabel={false}
            label={capitalize(T.LOCATION_TAGS)}
            tags={metaTags.filter(data => data.active && data.forLocation)}
            selectedTags={watch('tags')}
            onChange={(e, selectedOptions) => {
              setValue('tags', selectedOptions)
              // Trigger resize to fix map height
              setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
            }}
            limit={2}
          />
        </TagsWrapper>
      </Grid>
    </Grid>
  )
}

AddressForm.propTypes = {
  metaTags: PropTypes.array,
  setLatLngForMap: PropTypes.func.isRequired,
  setLatLngForMapHistory: PropTypes.func.isRequired,
}

export default AddressForm
