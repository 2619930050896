import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'

import { Box, Stack } from '@mui/material'

import { memo } from 'utils/react'
import { getAPIIdParam } from 'utils/form-body'
import { CONFIGURED_SERVICES_PAGINATION } from 'settings/constants/pagination'
import { getCustomerDetails } from 'middleware/actions/customers'

import { formatDateToBEFormatDateFns } from 'utils/date'
import { putIsLoading } from 'middleware/actions/response'
import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import CSNotesDialogVariant from 'components/notes/configured-service/CSNotesDialogVariant'
import { noop, get, cloneDeep } from 'lodash'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLazySearchConfiguredServicesQuery } from 'api/configured-service/searchConfiguredServices'
import { useLazyGetServiceDetailsQuery } from 'api/configured-service/getServiceDetails'
import { useForm, FormProvider } from 'react-hook-form'
import { handleError } from 'utils/error'
import Loader from 'components/common/loader'
import { toast } from 'react-toastify'
import ConfiguredServiceResetGracePeriodDialog from 'components/pricing/RentalFees/ResetGracePeriod/ServiceWise/ConfiguredServiceResetGracePeriodDialog'
import { Accordion, AccordionSummary, AccordionDetails } from './StyledAccordian'
import './common.scss'
import Header from './ConfiguredServices/Header'
import ConfiguredServicesRow from './ConfiguredServices/ServiceCard/ServiceDetailsCard'
import ServiceDrawer from '../../drawer/service'
import { canUpdateService } from '../../../data/permissions/permissionsSelectors'
import EventsDrawer from '../drawer/events'
import { useGetServiceEventsMutation } from '../../../api/configured-service/getServiceEvents'
import api from '../../../api'
import {
  CACHE_TAG_WORK_ORDER_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ROUTES,
} from '../../../api/cacheTagTypes'
import CustomerDetailsPaginations from './CustomerDetailsPaginations'
import ConFiguredServiceActionMenu from './ConfiguredServices/ConFiguredServiceActionMenu'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CONFIGURED_SERVICES_PAGINATION

const ConfiguredServices = ({ accountId, locationId = '' }) => {
  const methods = useForm({
    defaultValues: {
      startDate: new Date(),
      upcoming: false,
      status: ['Active'],
      accountId,
      locationId,
      page: INITIAL_PAGE,
      size: ROWS_PER_PAGE,
    },
  })
  const dispatch = useDispatch()
  const updateService = useSelector(canUpdateService, shallowEqual)
  const [currentServiceId, setCurrentServiceId] = useState(null)
  const [isOpenEventsDrawer, setIsOpenEventsDrawer] = useState(false)
  const [isOpenServiceDrawer, setIsOpenServiceDrawer] = useState(false)
  const [isOpenActionMenu, setIsOpenActionMenu] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [isOpenCSNotesDialog, setIsOpenCSNotesDialog] = useState(false)
  const [isOpenResetGracePeriodDialog, setIsOpenResetGracePeriodDialog] = useState(false)
  const [expanded, setExpanded] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE)
  const [size, setSize] = useState(ROWS_PER_PAGE)
  const [existingDetails, setExistingDetails] = useState(null)
  const [searchConfiguredServices, { isFetching, isLoading, data }] = useLazySearchConfiguredServicesQuery()
  const [getServiceDetails, { isFetching: isConfiguredServiceDetailsFetching }] = useLazyGetServiceDetailsQuery()
  const [getServiceEvents, { data: eventsData }] = useGetServiceEventsMutation()
  const { getValues, watch } = methods
  const selectedServiceId = get(existingDetails, 'configuredServiceId') || currentServiceId

  const fetchConfiguredServices = () => {
    const { status, upcoming, startDate } = getValues()
    return searchConfiguredServices({
      ...getAPIIdParam(locationId, accountId),
      page,
      size,
      status: status.join(','),
      upcoming,
      startDate: formatDateToBEFormatDateFns(upcoming ? new Date() : startOfWeek(startDate)),
      endDate: upcoming ? null : formatDateToBEFormatDateFns(endOfWeek(startDate)),
    }).unwrap()
  }

  const handleServiceNameClick = serviceId =>
    getServiceDetails(serviceId)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        setExistingDetails(res)
        setIsOpenServiceDrawer(true)
      })
      .catch(handleError)

  const handleAddEventClick = service => {
    if (service.deactivate) return

    dispatch(putIsLoading(true))
    getServiceEvents({ configuredServiceId: service.id })
      .unwrap()
      .then(() => {
        setCurrentServiceId(service.id)
        setIsOpenEventsDrawer(true)
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleSaveTags = (_data, closeCallback) => {
    closeCallback()
    fetchConfiguredServices()
  }

  const handleOpenActionMenu = (event, service) => {
    setIsOpenActionMenu(event.currentTarget)
    setSelectedService(service)
  }

  const handleCloseActionMenu = () => setIsOpenActionMenu(false)

  const handleOpenCSNotesDialog = service => {
    setSelectedService(service)
    setIsOpenCSNotesDialog(true)
  }

  const handleCloseCSNotesDialog = () => {
    setIsOpenCSNotesDialog(false)
    setSelectedService(null)
  }

  const handleOpenResetGracePeriodDialog = service => {
    setSelectedService(service)
    setIsOpenResetGracePeriodDialog(true)
  }

  const handleCloseResetGracePeriodDialog = () => {
    setIsOpenResetGracePeriodDialog(false)
    setSelectedService(null)
  }

  useEffect(() => {
    const subscription = watch(() => {
      fetchConfiguredServices()
    })
    return () => subscription.unsubscribe()
  }, [watch, locationId, accountId, dispatch])

  useEffect(() => {
    if (locationId || accountId) {
      fetchConfiguredServices()
    }
  }, [accountId, locationId, page, size])

  const totalCount = get(data, 'totalItems', 0)
  const totalPages = get(data, 'totalPages', 0)
  return (
    <FormProvider {...methods}>
      {isConfiguredServiceDetailsFetching && <Loader />}
      <Accordion expanded={expanded} onChange={noop}>
        <AccordionSummary
          sx={{ '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': { mx: 0 } }}
          withNewColors
          disablePointer
          expandIcon={null}
        >
          <Header
            isExpanded={expanded}
            label={`${T.CONFIGURED_SERVICE}s`}
            onLabelClick={() => setExpanded(!expanded)}
            onNewServiceClick={() => {
              setIsOpenServiceDrawer(true)
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          {!isLoading && !isFetching && Array.isArray(data?.services) && data.services.length === 0 && (
            <HHSectionPlaceholder
              title={!watch('status')?.includes('Deactivated') ? T.NO_CONFIGURED_SERVICES : T.NO_DEACTIVATED_CONFIGURED_SERVICES}
            />
          )}

          {!isLoading && !isFetching && Array.isArray(data?.services) && data.services.length > 0 && (
            <Box py={1} px={2} width="100%">
              <Stack spacing={1} sx={{ minWidth: 0 }}>
                {data.services.map(service => (
                  <ConfiguredServicesRow
                    service={service}
                    selectedServiceId={selectedServiceId}
                    onServiceNameClick={() => handleServiceNameClick(service.id)}
                    onHandleResetGracePeriod={() => handleOpenResetGracePeriodDialog(service)}
                    onAddEventClick={() => handleAddEventClick(service)}
                    onActionClick={event => handleOpenActionMenu(event, service)}
                    onViewNotesClick={() => handleOpenCSNotesDialog(service)}
                    onSaveTags={handleSaveTags}
                  />
                ))}
              </Stack>
            </Box>
          )}

          {totalCount > ROWS_PER_PAGE && (
            <CustomerDetailsPaginations
              withNewColors
              page={page}
              totalCount={totalCount}
              rowsPerPage={size}
              handlePageChange={newPage => {
                setPage(newPage)
              }}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              handleRowsPerPageChange={event => {
                const { value } = event.target
                setSize(value)
              }}
              totalPageCount={totalPages}
            />
          )}
        </AccordionDetails>
        {updateService && (
          <EventsDrawer
            isOpenDrawer={isOpenEventsDrawer}
            configuredServiceId={currentServiceId}
            className="events-drawer"
            eventInstances={eventsData}
            onRefetch={fetchConfiguredServices}
            onClose={() => {
              setIsOpenEventsDrawer(false)
              setCurrentServiceId(null)
            }}
          />
        )}
        {updateService && isOpenServiceDrawer && (
          <ServiceDrawer
            isOpenDrawer={isOpenServiceDrawer}
            accountId={accountId}
            locationId={locationId}
            isAddMode={!existingDetails}
            existingDetails={existingDetails}
            onClose={() => {
              setIsOpenServiceDrawer(false)
              setExistingDetails(null)
            }}
            onRefresh={() => {
              fetchConfiguredServices().then(() => {
                toast.success(T.CALENDAR_SAVE_MESSAGE, {
                  hideProgressBar: false,
                  autoClose: 3000,
                  onClose: () => {
                    dispatch(
                      api.util.invalidateTags([
                        CACHE_TAG_WORK_ORDER_LIST,
                        CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
                        CACHE_TAG_ROUTES,
                        CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
                      ])
                    )
                    dispatch(getCustomerDetails({ accountId }))
                  },
                })
              })
            }}
          />
        )}
      </Accordion>
      <ConFiguredServiceActionMenu anchorEl={isOpenActionMenu} selectedService={selectedService} onClose={handleCloseActionMenu} />
      <CSNotesDialogVariant isOpen={isOpenCSNotesDialog} configuredServiceId={selectedService?.id} onClose={handleCloseCSNotesDialog} />
      <ConfiguredServiceResetGracePeriodDialog
        isOpen={isOpenResetGracePeriodDialog}
        configuredServiceId={selectedService?.id}
        onClose={handleCloseResetGracePeriodDialog}
      />
    </FormProvider>
  )
}

ConfiguredServices.propTypes = {
  locationId: PropTypes.string,
  accountId: PropTypes.string.isRequired,
}

export default memo(ConfiguredServices)
