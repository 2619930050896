import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { orange } from '@mui/material/colors'
import { Accordion, AccordionSummary, Box, LinearProgress, Skeleton, Typography, IconButton, Chip, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'
import { formatDateToFEFormat } from 'utils/date'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import { PAYMENT_METHODS, PAYMENT_STATUS } from 'settings/constants/payment'
import { INVOICE_TYPE } from 'settings/constants/billing'

import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isPaymentsV15FeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { INVOICE_ACTIVITY_TABS_ID } from './settings'
import InvoiceActivityFeed from '../../../billing/common/InvoiceActivityFeed'

const { MANUAL } = INVOICE_TYPE
const { APPROVED } = PAYMENT_STATUS
const { ACCOUNT_CREDIT } = PAYMENT_METHODS
const { PAYMENT_TAB_ID } = INVOICE_ACTIVITY_TABS_ID

const InvoiceAccordion = ({
  isViewOnly = false,
  isLoading = false,
  selectedTab,
  billingProfileDetails = {},
  invoice = {},
  emailEvents = [],
  onClick,
  onActionClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [selectedSubTab, setSelectedSubTab] = useState(PAYMENT_TAB_ID)

  const {
    invoiceNumber,
    invoiceDate,
    dueDate,
    amountPaidCents,
    totalCents,
    startDate,
    endDate,
    type,
    payments = [],
    paymentSummary = [],
    accountCredits = [],
    status,
  } = invoice
  let getBillingCycle = []

  try {
    getBillingCycle = getBillingPeriods({
      billingProfile: billingProfileDetails,
      billingPeriods: [{ startDate, endDate, invoiceDate }],
    })
  } catch (error) {}

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')

  const parsedInvoiceDate = transformDate(invoiceDate)
  const title = type === MANUAL ? parsedInvoiceDate : get(getBillingCycle, '[0].labelLeft', '-')
  const pendingAmount = totalCents - amountPaidCents
  const { isPartial, agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, dueDate, amountPaidCents, totalCents)
  const flags = useFlags()
  const isPaymentsV15Enabled = isPaymentsV15FeatureAvailable({ flags })

  const allPayments = useMemo(() => {
    if (isPaymentsV15Enabled) {
      return paymentSummary.map(paymentSummaryItem => {
        const { paymentMethod, appliedAmount } = paymentSummaryItem
        const isAccountCredit = paymentMethod === ACCOUNT_CREDIT
        if (isAccountCredit) {
          return {
            ...paymentSummaryItem,
            transactionStatus: APPROVED,
            transactionAmount: appliedAmount,
          }
        }
        return paymentSummaryItem
      })
    }
    return [
      ...payments,
      ...accountCredits.map(accountCredit => ({
        isAccountCredit: true,
        paymentMethod: ACCOUNT_CREDIT,
        transactionAmountCents: accountCredit?.amountCents,
        transactionPostedDate: accountCredit?.createdAt,
        transactionStatus: APPROVED,
      })),
    ]
  }, [payments, accountCredits, isPaymentsV15Enabled, paymentSummary])

  const handleTabChange = (event, newValue) => setSelectedSubTab(newValue)

  useEffect(() => {
    if (selectedTab) {
      setIsExpanded(false)
      setSelectedSubTab(PAYMENT_TAB_ID)
    }
  }, [selectedTab, isLoading])

  return (
    <Accordion
      disableGutters
      sx={{ boxShadow: 'none', '&.Mui-expanded:before': { opacity: '1' }, '&.Mui-expanded': { margin: 'auto' } }}
      expanded={isExpanded}
    >
      <AccordionSummary onClick={onClick}>
        <Box width="100%">
          {isLoading && <LinearProgress sx={{ position: 'absolute', top: -5, left: 0, width: '100%' }} />}
          <Grid container alignItems="center">
            <Grid item xs="auto" minWidth={120}>
              {isLoading ? (
                <Skeleton width="5rem" />
              ) : (
                <InvoiceStatusChip status={status} isPartial={isPartial} agingBucketType={agingBucketType} />
              )}
            </Grid>

            <Grid item xs="auto">
              <Typography variant="h4" fontWeight={600}>
                {isLoading ? <Skeleton width="15rem" /> : title}
                {!isLoading && type === MANUAL && (
                  <Chip label={T.MANUAL} size="small" sx={{ backgroundColor: orange[100], ml: 1, borderRadius: 1 }} />
                )}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography display="flex" alignItems="center" justifyContent="flex-end" variant="h3" fontWeight={700}>
                {isLoading ? <Skeleton width="5rem" /> : <HHDisplayMoney value={totalCents} />}
                {isViewOnly ? (
                  <Box width={34} height={34} />
                ) : (
                  <IconButton size="small" onClick={onActionClick}>
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs="auto" minWidth={120}>
              {isPartial && agingBucketType && (
                <Typography color={AGING_BUCKET_COLORS[agingBucketType][700]} variant="caption" fontWeight={700}>
                  <HHDisplayMoney value={pendingAmount} /> left
                </Typography>
              )}
            </Grid>
            <Grid item xs="auto">
              <Typography variant="subtitle2">
                {isLoading ? <Skeleton width="15rem" /> : `Invoice #: ${invoiceNumber} // Invoice date: ${transformDate(invoiceDate)}`}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography variant="subtitle2" display="flex" alignItems="center" justifyContent="flex-end">
                {isLoading ? <Skeleton width="5rem" /> : ` Due: ${transformDate(dueDate)}`}
                <span onClick={event => event.stopPropagation()}>
                  <IconButton
                    size="small"
                    disabled={!allPayments.length && !emailEvents.length}
                    onClick={event => {
                      event.stopPropagation()
                      setIsExpanded(expanded => !expanded)
                    }}
                  >
                    {isExpanded ? <ExpandMore /> : <ChevronRight />}
                  </IconButton>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <InvoiceActivityFeed
        selectedTab={selectedSubTab}
        onSelectedTabChange={handleTabChange}
        payments={isPaymentsV15Enabled ? paymentSummary : payments}
        accountCredits={accountCredits}
        emailEvents={emailEvents}
      />
    </Accordion>
  )
}

InvoiceAccordion.propTypes = {
  isViewOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedTab: PropTypes.string.isRequired,
  billingProfileDetails: PropTypes.object,
  invoice: PropTypes.object,
  emailEvents: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
}

export default InvoiceAccordion
