import T from 'T'

const { SERVICE_GROUP, BUSINESS_LINE, PRICING_ZONE, SERVICE } = T

export const SERVICE_GROUPS_CONFIGURATOR = [
  {
    position: 0,
    tableLabel: SERVICE_GROUP,
    label: SERVICE_GROUP,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'name',
  },
  {
    position: 1,
    tableLabel: BUSINESS_LINE,
    label: BUSINESS_LINE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'businessLine.businessLineName',
  },
  {
    position: 2,
    tableLabel: PRICING_ZONE,
    label: PRICING_ZONE,
    checked: true,
    locked: false,
    filterType: 'string',
    columnName: 'pricingZone.zoneName',
  },
  {
    position: 3,
    tableLabel: `${SERVICE}s`,
    label: `${SERVICE}s`,
    checked: true,
    locked: false,
    // className: 'tr',
    // align: 'right',
    filterType: 'number',
    columnName: 'pricedServicesCount',
  },
]
