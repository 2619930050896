import React from 'react'
import { useMediaQuery } from '@mui/material'
import WODetails from '../components/work-order/details'
import NavbarWrapper from '../components/common/NavbarWrapper'

const WoDetailsPage = () => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  return (
    <NavbarWrapper
      sx={{
        width: '100vw',
        maxWidth: '100%',
        overflowX: isDesktop ? 'hidden' : false,
      }}
    >
      <WODetails />
    </NavbarWrapper>
  )
}

export default WoDetailsPage
