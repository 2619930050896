import { get as getLocalStorage, set as setLocalStorage, remove as removeLocalStorage } from 'simple-webstorage/lib/local'

const accessTokenKey = '__HH__ACCESS_TOKEN__'

export default class LocalStorage {
  static set(key, value) {
    setLocalStorage(key, value)
  }

  static get(key) {
    return getLocalStorage(key)
  }

  static remove(key) {
    removeLocalStorage(key)
  }

  static getAccessToken() {
    return LocalStorage.get(accessTokenKey)
  }

  static setAccessToken(accessToken) {
    LocalStorage.set(accessTokenKey, accessToken)
  }

  static removeAccessToken() {
    LocalStorage.remove(accessTokenKey)
  }
}
