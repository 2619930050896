import { BILLING_CYCLES } from 'settings/constants/billing'
import {
  TWO_MONTH_SCHEDULE_OPTIONS,
  FOUR_MONTH_SCHEDULE_OPTIONS,
  ANNUAL_SCHEDULE_OPTIONS,
  QUARTERLY_SCHEDULE_OPTIONS,
  TWO_QUARTER_SCHEDULE_OPTIONS,
} from 'settings/constants/day'

const { MONTH, QUARTER, YEAR } = BILLING_CYCLES

export const isBiMonthlyBillingCycle = cycle => cycle?.intervalType === MONTH && cycle?.interval === 2

export const isFourMonthBillingCycle = cycle => cycle?.intervalType === MONTH && cycle?.interval === 4

export const isQuarterlyBillingCycle = cycle => cycle?.intervalType === QUARTER && cycle?.interval === 1

export const isTwoQuarterBillingCycle = cycle => cycle?.intervalType === QUARTER && cycle?.interval === 2

export const hasFirstMonths = cycle => isBiMonthlyBillingCycle(cycle) || isFourMonthBillingCycle(cycle) || cycle?.intervalType === YEAR

export const getFirstMonthsScheduleOptions = cycle => {
  if (isBiMonthlyBillingCycle(cycle)) {
    return TWO_MONTH_SCHEDULE_OPTIONS
  }

  if (isFourMonthBillingCycle(cycle)) {
    return FOUR_MONTH_SCHEDULE_OPTIONS
  }

  if (cycle?.intervalType === YEAR) {
    return ANNUAL_SCHEDULE_OPTIONS
  }

  return []
}

export const getQuarterlyScheduleOptions = cycle => {
  if (isQuarterlyBillingCycle(cycle)) {
    return QUARTERLY_SCHEDULE_OPTIONS
  }

  if (isTwoQuarterBillingCycle(cycle)) {
    return TWO_QUARTER_SCHEDULE_OPTIONS
  }

  return []
}
