import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  invoiceDownloadFailureDialog: {
    isOpen: false,
    invoices: [],
  },
  paymentReceiptDownloadFailureDialog: {
    isOpen: false,
    receipts: [],
  },
}

const downloadSlice = createSlice({
  name: 'Download',
  initialState,
  reducers: {
    setInvoiceDownloadFailureDialogState: (state, action) => {
      state.invoiceDownloadFailureDialog = action.payload
    },
    setPaymentReceiptDownloadFailureDialogState: (state, action) => {
      state.paymentReceiptDownloadFailureDialog = action.payload
    },
  },
})

export const { setInvoiceDownloadFailureDialogState, setPaymentReceiptDownloadFailureDialogState } = downloadSlice.actions
export default downloadSlice.reducer
