import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRawRoute: build.query({
      query: ({ routeId, workOrderId, expandNotes }) => ({
        url: `/api/v1/core/routes/${routeId}/work-orders/${workOrderId}`,
        params: { expandNotes },
      }),
    }),
  }),
})

export const { useLazyGetRawRouteQuery } = extendedApi
