import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  showCustomerSideNav: true,
  tabId: '',
}

const customerSideNavSlice = createSlice({
  name: 'CustomerSideNav',
  initialState,
  reducers: {
    setShowCustomerSideNav(state, action) {
      state.showCustomerSideNav = action.payload
    },
  },
})

export const { setShowCustomerSideNav } = customerSideNavSlice.actions

export const selectShowCustomerSideNav = s => get(s, 'CustomerSideNav.showCustomerSideNav', false)

export default customerSideNavSlice.reducer
