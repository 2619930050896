import React from 'react'
import PropTypes from 'prop-types'

import { Grid, ListItem, ListItemText } from '@mui/material'

import { formatDateToFEFormat } from 'utils/date'
import { getInvoiceIsPartialStatusAndBucket } from 'utils/agingBucket'

import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'

const InvoiceSummary = ({ row, ...rest }) => {
  const { totalCents, amountPaidCents, status, dueDate: rawDueDate, invoiceNumber, billingProfileName, accountName, accountNumber } = row

  const transformDate = date => (date ? formatDateToFEFormat(date) : '-')
  const dueDate = transformDate(rawDueDate)
  const pendingAmount = totalCents - amountPaidCents
  const { agingBucketType } = getInvoiceIsPartialStatusAndBucket(status, rawDueDate, amountPaidCents, totalCents)

  return (
    <Grid container alignItems="center" wrap="nowrap" columnGap={1} pl={1} pr={3} {...rest}>
      <Grid item xs zeroMinWidth>
        <ListItem disablePadding>
          <ListItemText
            primaryTypographyProps={{ color: 'textPrimary', noWrap: true }}
            primary={`${invoiceNumber} - ${billingProfileName}`}
            secondaryTypographyProps={{ noWrap: true }}
            secondary={`${accountName} | ${accountNumber}`}
          />
        </ListItem>
      </Grid>
      <Grid item>
        <ListItem disablePadding sx={{ textAlign: 'right' }}>
          <ListItemText
            primary={<InvoiceStatusChip status={status} agingBucketType={agingBucketType} showAmount amountCents={pendingAmount} />}
            secondaryTypographyProps={{ color: 'textPrimary' }}
            secondary={rawDueDate ? `Due: ${dueDate}` : null}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}

InvoiceSummary.propTypes = {
  row: PropTypes.object,
}

export default InvoiceSummary
