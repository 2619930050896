import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import { get } from 'utils/lodash'
import { BUSINESS_LINE_TYPE_TO_SVG } from 'components/business-line/BusinessLineChip'

const RenderBusinessLineFilterOption = ({ props, option, selected, onOpenFilterMenu }) => {
  const theme = useTheme()
  const businessLineName = get(option, 'businessLineName', '')
  const businessLineIcon = get(option, 'businessLineIcon', '').replace('.svg', '')
  const Icon = get(BUSINESS_LINE_TYPE_TO_SVG, businessLineIcon, null)
  const routes = get(option, 'allRoutes', [])
  const routesCount = routes.length
  const stopsCount = get(option, 'stopCount', 0)

  return (
    <ListItemButton dense disableGutters {...props} onMouseOver={event => onOpenFilterMenu(event, routes)}>
      <ListItemIcon sx={{ minWidth: 0 }}>
        <Checkbox checked={selected} />
      </ListItemIcon>
      {Icon && (
        <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
          <Icon width={24} color={theme.palette.text.secondary} />
        </ListItemIcon>
      )}
      <ListItemText
        primary={businessLineName}
        secondary={`${routesCount} route${routesCount > 1 ? 's' : ''} | ${stopsCount} stop${stopsCount > 1 ? 's' : ''}`}
        primaryTypographyProps={{ variant: 'body1', fontWeight: 400 }}
        secondaryTypographyProps={{ variant: 'body1', fontWeight: 500 }}
      />
    </ListItemButton>
  )
}

RenderBusinessLineFilterOption.propTypes = {
  option: PropTypes.object,
  props: PropTypes.object,
  selected: PropTypes.bool,
  onOpenFilterMenu: PropTypes.func,
}

export default RenderBusinessLineFilterOption
