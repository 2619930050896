import React from 'react'
import PropTypes from 'prop-types'
import { ListItemIcon, ListItemText, MenuItem, SvgIcon, Tooltip } from '@mui/material'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined'
import { getLocationStatusChangeAndMessage } from 'utils/location'
import noop from 'lodash/noop'

const ToggleLocationStatusMenuItem = ({
  isLocationDeactivated = true,
  onClick = noop,
  hasActiveServices = true,
  isAccountActive = true,
  tooltipPlacement = 'left',
}) => {
  const { disabled, message } = getLocationStatusChangeAndMessage(isAccountActive, hasActiveServices, isLocationDeactivated)

  return (
    <Tooltip arrow title={message} disableHoverListener={!disabled} placement={tooltipPlacement}>
      <span>
        <MenuItem disabled={disabled} onClick={onClick}>
          <ListItemIcon>
            <SvgIcon fontSize="small" color={isLocationDeactivated ? 'success' : 'warning'}>
              {isLocationDeactivated ? <FmdGoodOutlinedIcon /> : <LocationOffOutlinedIcon />}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={isLocationDeactivated ? 'Reactivate location' : 'Deactivate location'}
            primaryTypographyProps={{ color: isLocationDeactivated ? 'success.main' : 'warning.main' }}
          />
        </MenuItem>
      </span>
    </Tooltip>
  )
}

ToggleLocationStatusMenuItem.propTypes = {
  isLocationDeactivated: PropTypes.bool,
  hasActiveServices: PropTypes.bool.isRequired,
  isAccountActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipPlacement: PropTypes.string,
}

export default ToggleLocationStatusMenuItem
