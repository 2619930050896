import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CommonDrawer from 'components/common/CommonDrawer'
import { get, partition } from 'lodash'
import { useDispatch } from 'react-redux'
import { getDisposalValidateFeesServices } from 'middleware/actions/pricing'
import { FormProvider, useForm, useFormState } from 'react-hook-form'
import { useGetDisposalFeeMatchedServicesMutation } from 'api/pricing/getDisposalFeeMatchedServices'
import T from 'T'
import { FORM_MEASURE_OPTIONS } from 'components/pricing/settings'
import { useTheme } from '@mui/material'
import NewDisposalFeeFormModal from './NewDisposalFeeFormModal'
import ConfirmationScreen from './ConfirmationScreen'
import LinkedServicesScreen from './LinkedServicesScreen'
import ConfirmationModal from '../../modal/ConfirmationModal'

const DRAWER_CONTENT = {
  NEW_FORM: 'NEW_FORM',
  CONFIRMATION: 'CONFIRMATION',
  LINKED_SERVICES: 'LINKED_SERVICES',
}

const MODAL_WIDTH = {
  NEW_FORM: {
    lg: '40vw',
    md: '100vw',
  },
  CONFIRMATION: {
    lg: '1100px',
    md: '100vw',
  },
  LINKED_SERVICES: {
    lg: '40vw',
    md: '100vw',
  },
}

const DISPOSAL_FORM_INITIAL_VALUES = {
  feeName: '',
  businessLines: [],
  measure: [],
  overage: '',
  overageFee: '',
  minFee: '',
  costPlus: false,
  autoLink: true,
  material: [],
  days: [],
  action: [],
  recurrence: [],
  method: [],
  zone: [],
  price: '',
}

const CONFIRMATION_FORM_INITIAL_VALUES = {
  disposalFees: [],
  duplicatedDisposalFees: [],
}

const NewDisposalFeeModal = ({ isOpen, setIsOpen, getFeeServiceName, setSelectedEditValues, onSubmitCallback }) => {
  const [uniqueRecords, setUniqueRecords] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [duplicateRecords, setDuplicateRecords] = useState([])
  const [drawerContent, setDrawerContent] = useState(DRAWER_CONTENT.NEW_FORM)
  const theme = useTheme()
  const dispatch = useDispatch()
  const [getDisposalFeeMatchedServices, { data }] = useGetDisposalFeeMatchedServicesMutation()
  const disposalFormMethods = useForm({
    defaultValues: DISPOSAL_FORM_INITIAL_VALUES,
    mode: 'onChange',
  })
  const confirmationFormMethods = useForm({
    defaultValues: CONFIRMATION_FORM_INITIAL_VALUES,
    mode: 'onChange',
  })
  const {
    setValue: disposalFormSetValue,
    watch: disposalFormWatch,
    control: disposalFormControl,
    reset: disposalFormReset,
  } = disposalFormMethods
  const { getValues: confirmationFormGetValues, control: confirmationFormControl, reset: confirmationFormReset } = confirmationFormMethods
  const { isDirty: isDisposalFormDirty } = useFormState({
    control: disposalFormControl,
  })
  const { touchedFields: confirmationFormTouchedFields } = useFormState({
    control: confirmationFormControl,
  })
  const onSubmit = values => {
    const {
      action,
      autoLink,
      businessLines,
      costPlus,
      days,
      feeName,
      material,
      measure,
      method,
      minFee,
      overage,
      overageFee,
      price,
      recurrence,
      zone,
    } = values
    const payload = {
      action: action.map(({ actionType, id }) => ({ id, label: actionType })),
      autoLink,
      businessLine: businessLines.map(({ id }) => ({ id })),
      costPlus,
      days: days.map(({ id }) => id),
      feeName: feeName.trim(),
      material: material.map(({ materialType, id }) => ({ label: materialType, id })),
      measure: measure.map(({ id }) => ({ value: id })),
      method: method.map(({ methodType, id }) => ({ id, label: methodType })),
      minFee,
      overage,
      overageFee,
      price,
      services: [],
      zone: zone.map(({ zoneName, id }) => ({ label: zoneName, id })),
      recurrence,
    }
    dispatch(
      getDisposalValidateFeesServices(payload, res => {
        const parsedResult = res.disposalFees.map(disposalFee => ({ ...disposalFee }))
        const result = partition(parsedResult, ({ exist }) => exist)
        setUniqueRecords(result[1])
        setDuplicateRecords(result[0])
        setDrawerContent(DRAWER_CONTENT.CONFIRMATION)
      })
    )
  }

  React.useEffect(() => {
    const subscription = disposalFormWatch((values, { name, type }) => {
      const listenList = ['action', 'businessLines', 'days', 'material', 'method', 'zone', 'recurrence']
      if (name === 'businessLines' || (type === 'change' && listenList.includes(name))) {
        const { action, businessLines, days, material, method, recurrence, zone } = values
        getDisposalFeeMatchedServices({
          action: action.map(({ id }) => id),
          businessLine: businessLines.map(({ label }) => label),
          days: days.length > 0 ? days.map(({ id }) => id).join(',') : undefined,
          material: material.map(({ id }) => id),
          method: method.map(({ id }) => id),
          zone: zone.map(({ id }) => id),
          recurrence: recurrence.map(({ id }) => id),
          search: '',
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [disposalFormWatch])

  React.useEffect(() => {
    if (isOpen === false) {
      setDrawerContent(DRAWER_CONTENT.NEW_FORM)
      disposalFormReset(DISPOSAL_FORM_INITIAL_VALUES)
      confirmationFormReset(CONFIRMATION_FORM_INITIAL_VALUES)
    }
  }, [isOpen])

  return (
    <CommonDrawer
      isDirty={drawerContent === DRAWER_CONTENT.NEW_FORM && isDisposalFormDirty}
      disableClassNamePrefix
      isOpen={isOpen}
      onChange={() => setIsOpen(false)}
      sx={{
        '& .MuiDrawer-paper': {
          top: 56,
        },
        '& .MuiDrawer-paperAnchorRight': {
          [theme.breakpoints.up('1100')]: {
            width: MODAL_WIDTH[drawerContent].lg,
          },
          [theme.breakpoints.down('1100')]: {
            width: MODAL_WIDTH[drawerContent].md,
          },
        },
      }}
    >
      <FormProvider {...disposalFormMethods}>
        {drawerContent === DRAWER_CONTENT.NEW_FORM && (
          <NewDisposalFeeFormModal
            matchedServicesCount={get(data, 'length', 0)}
            setIsOpen={setIsOpen}
            onSubmit={onSubmit}
            show={drawerContent === DRAWER_CONTENT.NEW_FORM}
            onClickLinkedServicesCard={() => {
              setDrawerContent(DRAWER_CONTENT.LINKED_SERVICES)
            }}
          />
        )}
      </FormProvider>
      {drawerContent === DRAWER_CONTENT.CONFIRMATION && (
        <FormProvider {...confirmationFormMethods}>
          <ConfirmationScreen
            onBack={() => {
              const [disposalFees, duplicatedDisposalFees] = confirmationFormGetValues(['disposalFees', 'duplicatedDisposalFees'])
              const hasKeys = !!Object.keys(confirmationFormTouchedFields).length
              if (duplicatedDisposalFees.length === 0 && disposalFees.length === 1 && disposalFees[0].tiers.length === 1) {
                const { feeName, minFee, costPlus, overageThreshold, overageFee, tiers, measureType } = disposalFees[0]
                disposalFormSetValue('feeName', feeName)
                disposalFormSetValue('minFee', minFee)
                disposalFormSetValue('costPlus', costPlus)
                disposalFormSetValue('overage', overageThreshold)
                disposalFormSetValue('overageFee', overageFee)
                disposalFormSetValue('price', tiers[0].price)
                disposalFormSetValue('measure', measureType ? [FORM_MEASURE_OPTIONS.find(({ id }) => id === measureType)] : [])
                setDrawerContent(DRAWER_CONTENT.NEW_FORM)
                confirmationFormReset(CONFIRMATION_FORM_INITIAL_VALUES)
              } else if (hasKeys) {
                setOpenModal(hasKeys)
              } else {
                setDrawerContent(DRAWER_CONTENT.NEW_FORM)
                confirmationFormReset(CONFIRMATION_FORM_INITIAL_VALUES)
              }
            }}
            setIsOpen={setIsOpen}
            uniqueRecords={uniqueRecords}
            duplicateRecords={duplicateRecords}
            getFeeServiceName={getFeeServiceName}
            setSelectedEditValues={setSelectedEditValues}
            onCreate={() => {
              setIsOpen(false)
              onSubmitCallback()
            }}
            services={Array.isArray(data) ? data : []}
          />
        </FormProvider>
      )}
      {drawerContent === DRAWER_CONTENT.LINKED_SERVICES && (
        <LinkedServicesScreen
          matchedServices={Array.isArray(data) ? data : []}
          onClose={() => {
            setIsOpen(false)
          }}
          onCancel={() => {
            setDrawerContent(DRAWER_CONTENT.NEW_FORM)
          }}
          onSave={() => {
            setDrawerContent(DRAWER_CONTENT.NEW_FORM)
          }}
        />
      )}
      <ConfirmationModal
        className="global-drawer-confirm"
        isOpen={openModal}
        title={`${T.ALERT_GO_BACK}\n${T.ALERT_CHANGES}`}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={T.OK}
        onCancel={() => {
          setOpenModal(false)
        }}
        onProceed={() => {
          setDrawerContent(DRAWER_CONTENT.NEW_FORM)
          setOpenModal(false)
          confirmationFormReset(CONFIRMATION_FORM_INITIAL_VALUES)
        }}
      />
    </CommonDrawer>
  )
}

NewDisposalFeeModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  setSelectedEditValues: PropTypes.func,
  onSubmitCallback: PropTypes.func.isRequired,
}

export default NewDisposalFeeModal
