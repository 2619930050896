import { actions } from '../actions/routeManager'

const {
  ROUTE_STOPS_LIST,
  META_DETAILS,
  ADD_ROUTE,
  UPDATE_ROUTE_SEQUENCE,
  MOVE_ROUTE_STOP,
  UNASSIGNED_WORK_ORDERS,
  SUBSCRIBE_TOKEN,
  MARKER_LOCATION,
} = actions.PUT
const { GET_PUSH_STATE, SELECT_ROUTE } = actions

const initialState = {
  routeStopsList: [],
  metaDetails: {
    routes: [],
  },
  filters: {
    selectedRouteIds: [],
  },
  addRoute: null,
  updateRouteSequence: null,
  moveRouteStop: null,
  unassignedStops: [],
  markerLocation: null,
  syncToken: null,
  pushState: '',
}

const RouteManagerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ROUTE_STOPS_LIST:
      return { ...state, routeStopsList: [...action.payload] }

    case META_DETAILS:
      return { ...state, metaDetails: action.payload }

    case ADD_ROUTE:
      return { ...state, addRoute: action.payload }

    case UPDATE_ROUTE_SEQUENCE:
      return { ...state, updateRouteSequence: action.payload }

    case MOVE_ROUTE_STOP:
      return { ...state, moveRouteStop: action.payload }

    case UNASSIGNED_WORK_ORDERS:
      return { ...state, unassignedStops: action.payload }

    case MARKER_LOCATION:
      return { ...state, markerLocation: action.payload }

    case SUBSCRIBE_TOKEN:
      return { ...state, syncToken: action.payload }
    case GET_PUSH_STATE:
      return { ...state, pushState: action.payload }

    case SELECT_ROUTE:
      return {
        ...state,
        filters: {
          selectedRouteIds: [...action.payload],
        },
      }

    default:
      return state
  }
}

export default RouteManagerReducer
