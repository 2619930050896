import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Box } from '@mui/material'
import { green, grey } from '@mui/material/colors'

import { ReactComponent as Service } from 'assets/notifications/Service.svg'
import { ReactComponent as Billing } from 'assets/notifications/Billing.svg'

const BOX_ACTIVE_SX = { borderColor: green[500], backgroundColor: green[50] }
const AVATAR_ACTIVE_SX = { backgroundColor: green[400] }

const BOX_DEACTIVATED_SX = { borderColor: 'rgba(0, 0, 0, 0.54)', backgroundColor: grey[50] }
const AVATAR_DEACTIVATED_SX = { backgroundColor: 'rgba(0, 0, 0, 0.38)' }

const BillingServiceNotificationAvatar = ({ type = 'Service', isActive = false, styles = {} }) => {
  const icon = type === 'Service' ? <Service /> : <Billing />
  const boxStyles = isActive ? BOX_ACTIVE_SX : BOX_DEACTIVATED_SX
  const sx = isActive ? AVATAR_ACTIVE_SX : AVATAR_DEACTIVATED_SX
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={24}
      height={24}
      borderRadius="50%"
      sx={{ border: '1px solid', ...boxStyles, ...styles }}
    >
      <Avatar sx={{ ...sx, width: 18, height: 18 }}>{icon}</Avatar>
    </Box>
  )
}

BillingServiceNotificationAvatar.propTypes = {
  type: PropTypes.string,
  isActive: PropTypes.bool,
  styles: PropTypes.object,
}

export default BillingServiceNotificationAvatar
