import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

const getAttention = state => get(state, 'attention', '')
const getAddressName = state => get(state, 'addressName', '')
const getLine1 = state => get(state, 'line1', '')
const getUnitNumber = state => get(state, 'unitNumber', '')
const getCity = state => get(state, 'city', '')
const getState = state => get(state, 'state', '')
const getZipCode = state => get(state, 'zipCode', '')
export const getLatitude = state => get(state, 'latitude', '')
export const getLongitude = state => get(state, 'longitude', '')

export const selectAttention = createSelector([getAttention], attention => attention)
export const selectAddressName = createSelector([getAddressName], addressName => addressName)
export const selectLine1 = createSelector([getLine1], line1 => line1)
export const selectUnitNumber = createSelector([getUnitNumber], unitNumber => unitNumber)
export const selectCity = createSelector([getCity], city => city)
export const selectState = createSelector([getState], state => state)
export const selectZipCode = createSelector([getZipCode], zipCode => zipCode)
export const selectLatitude = createSelector([getLatitude], latitude => latitude)
export const selectLongitude = createSelector([getLongitude], longitude => longitude)

export const selectAddress = createSelector(
  [
    selectAttention,
    selectAddressName,
    selectLine1,
    selectUnitNumber,
    selectCity,
    selectState,
    selectZipCode,
    selectLatitude,
    selectLongitude,
  ],
  (attention, addressName, line1, unitNumber, city, state, zipCode, latitude, longitude) => {
    return {
      attention,
      addressName,
      line1,
      unitNumber,
      city,
      state,
      zipCode,
      latitude,
      longitude,
    }
  }
)
