import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

export const getServicesAndActions = createSelector(
  [state => state.currentSelectedServices, state => state.initialSelectedServices, state => state.initialSelectedActions],
  (currentSelectedServices, initialSelectedServices, initialSelectedActions) => {
    const currentSelectedServicesIds = currentSelectedServices.map(service => service.id)
    const currentSelectedServicesActions = currentSelectedServices.map(service => get(service, 'action.actionType'))

    return {
      currentSelectedServicesIds,
      // Initial + selected
      allSelectedServicesIds: initialSelectedServices.concat(currentSelectedServicesIds),
      allSelectedActions: initialSelectedActions.concat(currentSelectedServicesActions),
    }
  }
)
