import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Link, ListItem, ListItemIcon, ListItemText, SvgIcon, useTheme } from '@mui/material'
import ContactMethodIcon from 'components/customer-details/content/contacts/ContactMethodIcon'
import { HHPhoneField } from 'components/form-fields/v5'
import { PhoneOutgoing } from '@styled-icons/heroicons-outline/PhoneOutgoing'
import { get } from 'lodash'
import { CONTACT_METHODS } from 'settings/constants/contacts'
import { ReactComponent as PaperPlane } from 'assets/PaperPlane.svg'

const { EMAIL } = CONTACT_METHODS

const ContactMethodItem = ({ contact, contactMethod, showAction = true, sx }) => {
  const theme = useTheme()
  const methodType = get(contactMethod, 'methodType')
  const isEmailMethod = methodType === EMAIL
  const methodValue = get(contactMethod, 'methodValue')
  const billingNotifications = get(contact, 'billingNotifications', false)
  const serviceNotifications = get(contact, 'serviceNotifications', false)
  const secondary = [billingNotifications ? 'Billing' : false, serviceNotifications ? 'Service' : false].filter(Boolean).join(', ')

  return (
    <ListItem sx={sx}>
      <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
        <SvgIcon>
          <ContactMethodIcon type={methodType} sx={{ width: 24, height: 24 }} />
        </SvgIcon>
      </ListItemIcon>
      <ListItemText
        sx={{ ml: 1, mr: 2 }}
        primary={isEmailMethod ? methodValue : <HHPhoneField displayType="text" value={methodValue} />}
        primaryTypographyProps={{ noWrap: true }}
        secondary={secondary}
      />
      {showAction && (
        <IconButton component={Link} href={isEmailMethod ? `mailto:${methodValue}` : `tel:${methodValue}`}>
          <SvgIcon sx={{ color: theme.palette.text.secondary, fontSize: 24 }} inheritViewBox>
            {isEmailMethod ? <PaperPlane /> : <PhoneOutgoing />}
          </SvgIcon>
        </IconButton>
      )}
    </ListItem>
  )
}

ContactMethodItem.propTypes = {
  contact: PropTypes.object,
  contactMethod: PropTypes.object,
  showAction: PropTypes.bool,
  sx: PropTypes.object,
}

export default ContactMethodItem
