import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import T from 'T'
import BillingSummaryItem from './BillingSummaryItem'

const BillingSummaryCard = ({ customers = '-', revenue = '-' }) => {
  const border = {
    height: 20,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'border.lightGrey',
  }
  return (
    <Grid container item xs="auto" alignItems="center">
      <BillingSummaryItem label={T.CUSTOMERS} value={customers} />
      <Box sx={border} />
      <BillingSummaryItem label={T.TOTAL_REVENUE} value={revenue} hasRevenueProp />
    </Grid>
  )
}

BillingSummaryCard.propTypes = {
  customers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default BillingSummaryCard
