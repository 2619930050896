import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, styled } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { get } from 'utils/lodash'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'
import T from 'T'
import LocationMapSkeleton from 'components/customer-details/content/location/LocationMapSkeleton'

const { LIGHT_VIEW, ZOOM } = MAP_DEFAULT_OPTIONS

const MapImage = styled('img')(({ hideContent = false }) => ({
  width: '100%',
  display: hideContent ? 'none' : 'block',
}))

const LocationMapImage = ({
  showInvalidAlert = true,
  latitude,
  longitude,
  width,
  height,
  dynamicWidth = false,
  containerId,
  showPlaceholder = false,
}) => {
  const userInfo = useSelector(state => get(state, 'AuthReducer.userInfo'), shallowEqual)
  const [src, setSource] = useState(null)
  const hasValidCoords = isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)
  const mapBoxToken = get(userInfo, 'mapBoxToken')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const hideContent = loading || error
  const [containerWidth, setContainerWidth] = useState(width)

  useEffect(() => {
    if (!hasValidCoords) return
    let timeoutId

    const handleResize = () => {
      clearTimeout(timeoutId)

      timeoutId = setTimeout(() => {
        const containerHtmlEl = document.getElementById(containerId)
        const parentWidth = containerHtmlEl.clientWidth
        if (!parentWidth) return
        setContainerWidth(parentWidth)
        setSource(`https://api.mapbox.com/styles/v1/mapbox/${LIGHT_VIEW}/static/pin-s+da0808(${longitude},${latitude})
              /${longitude},${latitude},${ZOOM}/${parentWidth}x${height}?access_token=${mapBoxToken}&logo=false`)
      }, 600)
    }
    if (!dynamicWidth) {
      setContainerWidth(width)
      setSource(`https://api.mapbox.com/styles/v1/mapbox/${LIGHT_VIEW}/static/pin-s+da0808(${longitude},${latitude})
              /${longitude},${latitude},${ZOOM}/${width}x${height}?access_token=${mapBoxToken}&logo=false`)
    } else {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => {
        clearTimeout(timeoutId)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [dynamicWidth])

  useEffect(() => {
    if (!hasValidCoords) return
    if (!dynamicWidth) {
      setContainerWidth(width)
      setSource(`https://api.mapbox.com/styles/v1/mapbox/${LIGHT_VIEW}/static/pin-s+da0808(${longitude},${latitude})
              /${longitude},${latitude},${ZOOM}/${width}x${height}?access_token=${mapBoxToken}&logo=false`)
    } else if (containerId) {
      const containerHtmlEl = document.getElementById(containerId)
      const parentWidth = containerHtmlEl.clientWidth || containerWidth

      setSource(`https://api.mapbox.com/styles/v1/mapbox/${LIGHT_VIEW}/static/pin-s+da0808(${longitude},${latitude})
              /${longitude},${latitude},${ZOOM}/${parentWidth}x${height}?access_token=${mapBoxToken}&logo=false`)
    }
  }, [latitude, longitude])

  const onLoad = () => {
    setError(false)
    setLoading(false)
  }

  const onError = () => {
    setError(true)
    setLoading(false)
  }

  return (
    <>
      {showInvalidAlert && (!hasValidCoords || error) && (
        <Alert sx={{ display: 'flex', alignItems: 'center' }} severity="warning">
          {T.INVALID_LAT_LONG}
        </Alert>
      )}
      {(!hasValidCoords || error) && showPlaceholder && <LocationMapSkeleton width={width} height={height} />}

      {hasValidCoords && (
        <Box id={containerId} height={height} width="100%">
          {loading && <LocationMapSkeleton width={containerWidth} height={height} />}
          <MapImage height={height} onLoad={onLoad} onError={onError} hideContent={hideContent} src={src} />
        </Box>
      )}
    </>
  )
}

LocationMapImage.propTypes = {
  width: PropTypes.number.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  dynamicWidth: PropTypes.bool,
  containerId: PropTypes.string.isRequired,
  showInvalidAlert: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
}

export default LocationMapImage
