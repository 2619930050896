import { get } from 'utils/lodash'
import { NO_TAGS_OPTION_ID } from 'components/locations/filters/TagFilter/settings'
import trim from 'lodash/trim'

export const transformSuggestResponse = data => {
  const { results } = data
  return { accounts: results }
}

export const getFirstAndLastName = accountName => {
  const lastSpaceIndex = accountName.lastIndexOf(' ')
  const isMoreThanOneWord = lastSpaceIndex > -1
  const firstName = isMoreThanOneWord ? trim(accountName.substring(0, lastSpaceIndex)) : accountName
  const lastName = isMoreThanOneWord ? trim(accountName.substring(lastSpaceIndex + 1, accountName.length)) : accountName
  return { firstName, lastName }
}

export const transformSearchResponse = data => {
  const { results } = data

  return {
    accounts: results.map(row => {
      const accountTags = get(row, 'tags', [])
      const tagIds = accountTags.length > 0 ? accountTags.map(({ id }) => id) : [NO_TAGS_OPTION_ID]
      const accountName = trim(get(row, 'accountName', ''))
      const { firstName, lastName } = getFirstAndLastName(accountName)
      return { ...row, tagIds, firstName, lastName }
    }),
  }
}
