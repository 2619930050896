import { get } from 'lodash'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom-v5-compat'
import React from 'react'
import { getCustomerDetailsPageUrl } from 'router/routes'

const RenderAccountLinkCell = ({ value, row }) => {
  const accountId = get(row, 'accountId', '')
  const handleLinkClick = e => e.stopPropagation()
  return (
    <Link onClick={handleLinkClick} component={RouterLink} to={getCustomerDetailsPageUrl(accountId)}>
      {value}
    </Link>
  )
}

export default RenderAccountLinkCell
