import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableCell, TableBody as TBody } from '@mui/material'

import PricingSwitch from 'components/pricing/PricingSwitch'

import { getServiceName } from './linkedServiceName'

import Constants from 'Constants'

import { memo } from 'utils/react'

const { MEDIA_SERVER } = Constants

const LinkedServiceBody = ({ serviceList, headerList, tab, handleSwitchVal }) => {
  return (
    <TBody className="table-body">
      {Children.toArray(
        serviceList !== null &&
          serviceList &&
          serviceList.map(val => {
            return (
              <TableRow>
                {Children.toArray(
                  headerList &&
                    headerList.map(header => {
                      return <TableCell>{getServiceName(val, header, tab)}</TableCell>
                    })
                )}

                <TableCell>
                  <PricingSwitch checked={val.linkedVal} handleSwitchVal={() => handleSwitchVal(!val.linkedVal, val.id, tab)} />
                </TableCell>
              </TableRow>
            )
          })
      )}
    </TBody>
  )
}

LinkedServiceBody.defaultProps = {
  values: [],
  headerList: [],
  tab: '',
  handleSwitchVal: null,
  linkAll: false,
}

LinkedServiceBody.propTypes = {
  values: PropTypes.array,
  headerList: PropTypes.array,
  tab: PropTypes.string,
  handleSwitchVal: PropTypes.func,
}

export default memo(LinkedServiceBody)
