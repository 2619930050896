import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton } from '@mui/material'
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined'
import { Login } from '@styled-icons/heroicons-outline/Login'
import { Key } from '@styled-icons/heroicons-outline/Key'

import { get } from 'utils/lodash'
import { decodeToken } from 'utils/token'
import { isEmailProvider } from 'settings/constants/auth'

import T from 'T'
import Constants from 'Constants'

import Endpoint from 'middleware/sagas/endpoint'
import ChangePassword from 'components/change-password/ChangePassword'

import './User.scss'
import { handleFirebaseLogout } from 'providers/firebaseLogout'
import { useAuthValue } from 'auth/AuthContext'
import useTheme from '@mui/material/styles/useTheme'
import AvatarSquare from './AvatarSquare'
import UserMenu from './UserMenu'

const { profilePic, profilePicUri } = Endpoint.user
const { PRIVACY_POLICY_PAGE } = Constants

const User = ({ firstName, lastName, profilePicture, enableClick, uri }) => {
  const theme = useTheme()
  const [resetPwdModal, setResetPwdModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  // currentUser.providerData returns all strategies, so decoding the token to get the strategy used
  const token = get(useAuthValue(), 'currentUser.accessToken')
  const decodedToken = decodeToken(token)
  const isPasswordProvider = isEmailProvider(get(decodedToken, 'firebase.sign_in_provider'))

  // menuItems on click of user profile pic
  const menuitems = [
    { ...(isPasswordProvider && { title: T.CHANGE_PASSWORD, icon: <Key /> }) },
    { ...{ title: T.PRIVACY_POLICY, icon: <PolicyOutlinedIcon />, type: 'link', url: PRIVACY_POLICY_PAGE } },
    { title: T.LOGOUT, icon: <Login /> },
  ].filter(value => Object.keys(value).length !== 0)

  // close menu function
  const handleClose = () => setAnchorEl(null)

  // on profile pic click_event
  const handlePersonButtonClicked = event => setAnchorEl(event.currentTarget)
  // handle click of user menus
  const handleClick = e => {
    const selectedMenu = e.target.innerText

    if (selectedMenu === 'Log Out') {
      handleFirebaseLogout()
    } else if (selectedMenu === T.CHANGE_PASSWORD) {
      handleClose()
      setResetPwdModal(true)
    }
  }

  const handleResetPwdModalClose = () => {
    setResetPwdModal(false)
    handleClose()
  }

  const getProfileURI = uri ? profilePicUri + uri : profilePic

  return (
    <>
      {/* user component call */}
      <IconButton size="large" onClick={e => enableClick && handlePersonButtonClicked(e)}>
        <AvatarSquare
          firstName={firstName}
          lastName={lastName}
          src={profilePicture && getProfileURI}
          sx={{ ...theme.typography.h5, width: 32, height: 32 }}
        />
      </IconButton>

      <ChangePassword passwordModal={resetPwdModal} onClose={handleResetPwdModalClose} />
      <UserMenu anchorEl={anchorEl} menuitems={menuitems} onClick={handleClick} onClose={handleClose} />
    </>
  )
}

User.defaultProps = {
  firstName: '',
  lastName: '',
  profilePicture: '',
  uri: '',
  enableClick: true,
}

User.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  enableClick: PropTypes.bool,
  uri: PropTypes.string,
  profilePicture: PropTypes.string,
}

export default User
