import React, { memo, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { blobToBase64, getExceptionImageBlob } from 'utils/file'
import { CircularProgress, Stack } from '@mui/material'
import { EXCEPTION_TYPES } from 'settings/constants/exceptions'
import RenderException from 'components/work-order/details/exceptions/RenderException'

const ExceptionImage = ({ exception, photo, height = '194px', ImageSx, size = 'default', withSwiperSlide = false, PageProps }) => {
  const fileLocation = get(exception, 'images[0].fileLocation')
  const exceptionType = useMemo(() => get(exception, 'exceptionType'), [exception])
  const isSignExceptionType = useMemo(() => exceptionType === EXCEPTION_TYPES.Sign, [exceptionType])
  const [base64Data, setBase64Data] = useState('')
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)

  const showContent = base64Data && !isPhotoLoading
  const showSkeleton = !base64Data || isPhotoLoading

  const getCircularProgressSize = size => {
    if (size === 'medium') return 40
    if (size === 'small') return 40
    return 64
  }

  const circularProgressSize = getCircularProgressSize(size)

  const getExceptionPhoto = async () => {
    setIsPhotoLoading(true)
    getExceptionImageBlob(fileLocation, isSignExceptionType ? 'default' : size)
      .then(blobToBase64)
      .then(base64 => {
        if (base64) {
          setBase64Data(base64)
        }
      })
      .catch(() => {})
      .finally(() => setIsPhotoLoading(false))
  }

  useEffect(() => {
    if (isPhotoLoading || !fileLocation) return
    getExceptionPhoto()
  }, [fileLocation, exceptionType])

  if (withSwiperSlide) {
    return (
      <swiper-slide lazy="false">
        {showContent && (
          <RenderException
            base64Data={base64Data}
            ImageSx={ImageSx}
            height={height}
            isSignExceptionType={isSignExceptionType}
            PageProps={PageProps}
          />
        )}
        {showSkeleton && <Stack alignItems="center" justifyContent="center" sx={{ height, ...ImageSx }} />}
      </swiper-slide>
    )
  }

  return (
    <>
      {showContent && (
        <RenderException
          base64Data={base64Data}
          ImageSx={ImageSx}
          height={height}
          PageProps={PageProps}
          isSignExceptionType={isSignExceptionType}
        />
      )}
      {showSkeleton && (
        <Stack alignItems="center" justifyContent="center" sx={{ height, ...ImageSx }}>
          {isPhotoLoading && <CircularProgress size={circularProgressSize} />}
        </Stack>
      )}
    </>
  )
}

ExceptionImage.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exception: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  ImageSx: PropTypes.object,
  size: PropTypes.string,
  withSwiperSlide: PropTypes.bool,
  PageProps: PropTypes.object,
}

export default memo(ExceptionImage)
