import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { SwipeableDrawer, useTheme, useMediaQuery, ListItem, IconButton, ListItemText } from '@mui/material'
import {
  closePricingDrawer,
  openPricingDrawer,
  selectIsOpenPricingDrawer,
  setResetSelectServiceGroup,
} from 'slices/pricing/pricingDrawerSlice'
import { CUSTOMER_SIDE_NAV_DRAWER_WIDTH } from 'components/customers/common/side-nav/settings'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import T from 'T'
import { FEES_MENU, SERVICES_MENU, TAXES_MENU } from 'components/pricing/common/drawer/settings'
import PricingFavButton from 'components/pricing/common/drawer/PricingFavButton'
import MenuGroup from 'components/customers/common/side-nav/MenuGroup'
import routes from 'router/routes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isLateFeeFeatureAvailable, isRentalFeeFeatureAvailable } from 'data/launch-darkly/featureSelectors'

const PricingDrawer = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isOpenPricingDrawer = useSelector(selectIsOpenPricingDrawer)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const handleDrawerOpen = () => dispatch(openPricingDrawer())
  const handleDrawerClose = () => dispatch(closePricingDrawer())
  const flags = useFlags()
  const isLateFeeEnabled = isLateFeeFeatureAvailable({ flags })
  const isRentalFeeEnabled = isRentalFeeFeatureAvailable({ flags })
  const [feesMenuItems, setFeesMenuItems] = useState(FEES_MENU)
  const handleLinkClick = ({ url }) => {
    if (url === routes.app.pricingServiceGroups) {
      dispatch(setResetSelectServiceGroup(true))
    }
  }

  useEffect(() => {
    if (isTabletOrMobile) {
      handleDrawerClose()
    } else {
      handleDrawerOpen()
    }
  }, [])

  useEffect(() => {
    let menuItems = [...FEES_MENU]
    if (isLateFeeEnabled) {
      menuItems = [...menuItems, { label: T.LATE, url: routes.app.pricingLateFees }]
    }

    if (isRentalFeeEnabled) {
      menuItems = [...menuItems, { label: T.RENTAL, url: routes.app.pricingRentalFees }]
    }
    setFeesMenuItems(menuItems)
  }, [isLateFeeEnabled, isRentalFeeEnabled])

  return (
    <SwipeableDrawer
      sx={{
        width: CUSTOMER_SIDE_NAV_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: CUSTOMER_SIDE_NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
          top: 56,
        },
      }}
      variant={isTabletOrMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={isOpenPricingDrawer}
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
    >
      <ListItem
        sx={{ height: 56, borderBottom: 1, borderColor: 'divider' }}
        secondaryAction={
          <IconButton edge="end" onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        }
      >
        <ListItemText primary={T.PRICING} primaryTypographyProps={{ variant: 'h5', fontWeight: 500, color: 'textSecondary' }} />
      </ListItem>

      <MenuGroup groupName={T.SERVICES} menuItems={SERVICES_MENU} RenderSecondaryAction={PricingFavButton} onLinkClick={handleLinkClick} />
      <MenuGroup groupName={T.FEES} menuItems={feesMenuItems} RenderSecondaryAction={PricingFavButton} />
      <MenuGroup groupName={T.TAXES} menuItems={TAXES_MENU} RenderSecondaryAction={PricingFavButton} />
    </SwipeableDrawer>
  )
}

export default PricingDrawer
