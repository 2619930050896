import api from 'api'
import Constants from 'Constants'

const { SERVER_PUBLIC_URL } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    activateUser: build.mutation({
      query: body => ({
        method: 'POST',
        url: `${SERVER_PUBLIC_URL}/api/v1/crm-user/activate`,
        body,
      }),
    }),
  }),
})

export const { useActivateUserMutation } = extendedApi
