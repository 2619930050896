import React, { Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import cloneDeep from 'lodash/cloneDeep'
import flattenDeep from 'lodash/flattenDeep'
import { shallowEqual, useSelector } from 'react-redux'
import { Box, Typography, Button, Divider } from '@mui/material'
import T from 'T'
import Add from '@mui/icons-material/Add'
import { get } from 'utils/lodash'
import { canAddSettings } from 'data/permissions/permissionsSelectors'

import { isValidPrice } from 'utils/validations'

import MeasureTool from '../MeasureTool'

const Measure = ({ measure = {}, measureCombinations = {}, setServiceState = noop }) => {
  const addSettings = useSelector(canAddSettings, shallowEqual)

  useEffect(() => {
    let measuresCloned = cloneDeep(measure)
    let combinations = {}

    combinations['gallon'] = get(measuresCloned, 'gallon', [])
    combinations['ton'] = get(measuresCloned, 'ton', [])
    combinations['yard'] = get(measuresCloned, 'yard', [])
    combinations['pound'] = get(measuresCloned, 'pound', [])
    combinations['item'] = get(measuresCloned, 'item', [])

    // WOLV-1573 Sort descending
    combinations.yard.sort((a, b) => {
        if (a.volume > b.volume) return -1
        if (a.volume < b.volume) return 1
        return 0
    })

    setServiceState({
      measureCombinations: combinations,
    })
  }, [measure])

  const addRows = event => {
    measureCombinations[event.target.value].push({
      id: '',
      volume: '',
    })
    setServiceState({ measureCombinations, showFooter: true })
  }

  const handleFooter = () => {
    const values = flattenDeep(Object.values(measureCombinations)).map(data => data.id)

    if (values.filter(data => data == '').length === 0) {
      setServiceState({ showFooter: false })
    }
  }

  const handleRemoval = (index, type) => {
    measureCombinations[type].splice(index, 1)
    setServiceState({ measureCombinations: measureCombinations })

    handleFooter()
  }

  const onHandleChange = (e, type, index) => {
    if (isValidPrice(e.target.value)) {
      measureCombinations[type][index].volume = e.target.value
      setServiceState({ measureCombinations, showFooter: true })
    }
  }

  const addContainer = value =>
    addSettings && (
      <Button
        onClick={addRows}
        variant="text"
        color="primary"
        value={value}
        startIcon={<Add fontSize="small" sx={{ height: 18, mr: 0.2 }} />}
        sx={{ mt: 1, fontSize: 13, whiteSpace: 'nowrap' }}
      >
        {T.ADD_CONTAINER_SIZE}
      </Button>
    )

  return (
    <Box
      display="flex"
      sx={{
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      }}
    >
      <Box>
        <Typography variant="subtitle2" pb={1}>
          {T.GALLON}
        </Typography>
        <Divider width={134} />

        {measureCombinations['gallon'] &&
          Children.toArray(
            measureCombinations['gallon'].map((data, index) => {
              return (
                <MeasureTool
                  id={data.id}
                  index={index}
                  type="gallon"
                  selectedVal={data.volume}
                  handleRemoval={handleRemoval}
                  onHandleChange={onHandleChange}
                />
              )
            })
          )}

        {addContainer('gallon')}
      </Box>

      <Box ml={2}>
        <Typography variant="subtitle2" pb={1}>
          {T.YARD}
        </Typography>
        <Divider width={134} />
        {measureCombinations['yard'] &&
          Children.toArray(
            measureCombinations['yard'].map((data, index) => {
              return (
                <MeasureTool
                  id={data.id}
                  index={index}
                  type="yard"
                  selectedVal={data.volume}
                  handleRemoval={handleRemoval}
                  onHandleChange={onHandleChange}
                />
              )
            })
          )}

        {addContainer('yard')}
      </Box>

      <Box ml={2}>
        <Typography variant="subtitle2" pb={1}>
          {T.POUND}
        </Typography>
        <Divider width={134} />
        {measureCombinations['pound'] &&
          Children.toArray(
            measureCombinations['pound'].map((data, index) => {
              return (
                <MeasureTool
                  id={data.id}
                  index={index}
                  type="pound"
                  selectedVal={data.volume}
                  handleRemoval={handleRemoval}
                  onHandleChange={onHandleChange}
                />
              )
            })
          )}

        {addContainer('pound')}
      </Box>

      <Box ml={2}>
        <Typography variant="subtitle2" pb={1}>
          {T.TON}
        </Typography>
        <Divider width={134} />
        {measureCombinations['ton'] &&
          Children.toArray(
            measureCombinations['ton'].map((data, index) => {
              return (
                <MeasureTool
                  id={data.id}
                  index={index}
                  type="ton"
                  selectedVal={data.volume}
                  handleRemoval={handleRemoval}
                  onHandleChange={onHandleChange}
                />
              )
            })
          )}

        {addContainer('ton')}
      </Box>

      <Box ml={2}>
        <Typography variant="subtitle2" pb={1}>
          {T.ITEM}
        </Typography>
        <Divider width={134} />
        {measureCombinations['item'] &&
          Children.toArray(
            measureCombinations['item'].map((data, index) => {
              return (
                <MeasureTool
                  id={data.id}
                  index={index}
                  type="item"
                  selectedVal={data.volume}
                  handleRemoval={handleRemoval}
                  onHandleChange={onHandleChange}
                />
              )
            })
          )}

        {addContainer('item')}
      </Box>
    </Box>
  )
}

Measure.propTypes = {
  measure: PropTypes.object,
  measureCombinations: PropTypes.object,
  setServiceState: PropTypes.func,
}

export default Measure
