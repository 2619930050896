import React, { useState, Children, useEffect } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import capitalize from 'lodash/capitalize'
import uniqBy from 'lodash/uniqBy'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useFieldArray, useForm, FormProvider } from 'react-hook-form'

import {
  Alert,
  Button,
  Typography,
  DialogContent,
  Grid,
  Divider,
  FormControlLabel,
  List,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { combineAddressInfo } from 'utils/helper'
import { isCustomerDetailsPage } from 'router/routes'
import { getActiveDeactivatedLocations } from 'utils/location'
import { CONTACT_METHODS, CONTACT_METHOD_TYPES } from 'settings/constants/contacts'
import { fixContactLocationAssociation } from 'utils/form-body'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useAddUpdateContactMutation } from 'api/contact/addUpdateContact'
import { HHFormTextField, HHFormCheckboxField } from 'components/form-fields/v5'
import {
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS,
} from 'api/cacheTagTypes'
import { useLazyGetAccountDetailsQuery } from 'api/accounts/getAccountDetails'
import { putIsLoading } from 'middleware/actions/response'

import T from 'T'
import api from 'api'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import HHFormSwitchIconField from 'components/form-fields/v5/HHFormSwitchIconField'
import CancelButton from 'components/buttons/CancelButton'
import HHFormMultiSelectAutocompleteField from 'components/form-fields/v5/HHFormMultiSelectAutocompleteField'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import ContactMethod from './ContactMethod'
import { INITIAL_STATE } from './settings'

const { EMAIL, FAX } = CONTACT_METHODS
const { PERSONAL } = CONTACT_METHOD_TYPES

export const ContactMethodModel = (methodType = '') =>
  cloneDeep({
    contactMethodType: PERSONAL,
    methodType,
    methodValue: '',
    serviceNotifications: methodType !== FAX,
    billingNotifications: methodType === EMAIL,
  })

const AddEditContactDialog = ({ isOpen = false, accountId, contact, onClose }) => {
  const [getAccountDetails, { data: customerDetails }] = useLazyGetAccountDetailsQuery()
  const [areContactMethodsValid, setAreContactMethodsValid] = useState(true)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { activeLocations } = getActiveDeactivatedLocations(customerDetails)
  const serviceLocations = activeLocations.map(location => ({ id: location.id, label: combineAddressInfo(get(location, 'address', {})) }))

  const [addUpdateContact, { isLoading: isAddUpdateContactLoading }] = useAddUpdateContactMutation()

  const initialState = contact || INITIAL_STATE
  const [expandedContactMethods, setExpandedContactMethods] = useState([0])
  const contactForm = useForm({ defaultValues: { ...initialState, active: !initialState?.deactivated } })

  const accountContacts = get(customerDetails, 'contacts', [])
  const locationContacts = get(customerDetails, 'locations', []).map(location => get(location, 'contacts', []))
  const mergeContacts = [...accountContacts, ...locationContacts.flat()]
  // Remove duplicates
  const uniqueContacts = uniqBy(mergeContacts, c => c.id)
  const allExistingContactMethods = uniqueContacts
    // Exclude current contact
    .filter(({ id }) => id !== contact?.id)
    .map(({ contactMethods }) => contactMethods)
    .flat()
  const existingEmailContactMethods = allExistingContactMethods.filter(({ methodType }) => methodType === EMAIL)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setFocus,
    clearErrors,
    formState: { errors },
  } = contactForm

  const { append: appendContactMethod, remove: removeContactMethod } = useFieldArray({
    control,
    name: 'contactMethods',
  })

  const contactMethods = watch('contactMethods')

  const handleAddContactMethod = () => {
    appendContactMethod({ ...ContactMethodModel(EMAIL) })
    setExpandedContactMethods([contactMethods.length])
  }

  const onSubmitHandler = data => {
    // Don't save empty contact methods
    const removeEmptyContactMethods = get(data, 'contactMethods').filter(({ methodValue }) => methodValue)
    const fixContactsAssociation = fixContactLocationAssociation([{ ...data, contactMethods: removeEmptyContactMethods }])

    const payload = { accountId, contact: { ...get(fixContactsAssociation, '[0]', {}), deactivated: !data.active } }
    addUpdateContact(payload)
      .unwrap()
      .then(() => {
        onClose()
        if (isCustomerDetailsPage()) {
          dispatch(getCustomerDetails({ accountId }))
        }
        dispatch(
          api.util.invalidateTags([
            CACHE_TAG_ACCOUNT_LIST,
            CACHE_TAG_ACCOUNT_GROUP_DETAILS,
            CACHE_TAG_AGING_GROUP_DETAILS,
            CACHE_TAG_SUSPENSION_GROUP_DETAILS,
            { type: CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS, id: accountId },
          ])
        )
        toast.success(contact ? T.CONTACT_UPDATED_SUCCESS : T.CONTACT_ADDED_SUCCESS)
      })
      .catch(handleError)
  }

  const onSubmitErrorHandler = err => {
    toast.error(T.REQUIRED_FIELD_MSG)
    const errorMethods = Object.keys(get(err, 'contactMethods', []))
    if (errorMethods.length) {
      setExpandedContactMethods(errorMethods.map(errorIndex => parseInt(errorIndex)))
    }
  }

  const validateContactMethods = (newMethods, isActive) => {
    const newEmailMethods = newMethods.filter(({ methodType }) => methodType === EMAIL)
    if (newEmailMethods.length === 0 || !isActive) {
      setAreContactMethodsValid(true)
      return
    }

    const allEmailMethods = [...existingEmailContactMethods, ...newEmailMethods]
    const hasNotificationBillingEmail = allEmailMethods.some(method => method.billingNotifications)
    setAreContactMethodsValid(hasNotificationBillingEmail)
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const [field] = name.split('.')
      if (field === 'associatedWithLocation') {
        setValue('associatedLocations', [])
      }

      const { contactMethods: currentContactMethods, active: isActive } = value
      validateContactMethods(
        currentContactMethods.filter(({ methodValue }) => methodValue),
        isActive
      )
    })

    return () => subscription.unsubscribe()
  }, [customerDetails, watch])

  useEffect(() => {
    if (isOpen && accountId) {
      dispatch(putIsLoading(true))
      getAccountDetails({ accountId })
        .unwrap()
        .catch(handleError)
        .finally(() => dispatch(putIsLoading(false)))
      setTimeout(() => setFocus('firstName'), 100)
      clearErrors()
    }
  }, [isOpen, accountId])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <HHDialogTitle title={contact ? 'Edit contact' : 'Create contact'} onClose={onClose} />
        <DialogContent>
          <Grid container mt={2} columnGap={3}>
            <Grid container rowGap={1} spacing={1} xs={12} md={5} height="fit-content">
              <Grid item xs={12} md={6}>
                <HHFormTextField
                  control={control}
                  error={errors?.firstName}
                  required
                  rules={{ required: true }}
                  name="firstName"
                  label={capitalize(T.FIRST_NAME)}
                  fullWidth
                  deprecatedLabel={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <HHFormTextField control={control} name="lastName" label={capitalize(T.LAST_NAME)} fullWidth deprecatedLabel={false} />
              </Grid>
              <Grid item xs={12}>
                <HHFormTextField control={control} name="position" label={T.TITLE} fullWidth deprecatedLabel={false} />
              </Grid>
              <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="flex-end" sx={{ '& label': { color: 'text.primary' } }}>
                  <HHFormSwitchIconField control={control} type="AccountStatus" label={T.ACTIVE_CONTACT} name="active" />
                </Box>
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Typography color="textSecondary">Associated with:</Typography>
                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={<HHFormCheckboxField control={control} name="associatedWithLocation" />}
                  label={capitalize(T.SERVICE_LOCATION)}
                />
              </Grid>
              {watch('associatedWithLocation') && (
                <Grid item xs={12}>
                  <HHFormMultiSelectAutocompleteField
                    disableCloseOnSelect
                    control={control}
                    name="associatedLocations"
                    sx={{ '.MuiOutlinedInput-root': { pr: '100px!important' } }}
                    fullWidth
                    deprecatedLabel={false}
                    label={`${capitalize(T.SERVICE_LOCATION)}s`}
                    options={serviceLocations}
                    ListboxProps={{ style: { maxHeight: '14rem' } }}
                    getOptionLabel={option => get(option, 'label', '')}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Grid>
              )}
            </Grid>
            {!isTabletOrMobile && (
              <Grid item xs="auto">
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md sx={isTabletOrMobile ? {} : { overflowY: 'auto', maxHeight: 'calc(100vh - 270px)' }}>
              <Typography variant="subtitle2">{capitalize(T.CONTACT_METHODS)}</Typography>
              <Divider sx={{ mt: 0.5 }} />
              {contactMethods.length > 0 && (
                <FormProvider {...contactForm}>
                  <List disablePadding>
                    {Children.toArray(
                      contactMethods.map((contactMethod, index) => (
                        <>
                          <ContactMethod
                            index={index}
                            isExpanded={expandedContactMethods.includes(index)}
                            onExpandChange={open =>
                              setExpandedContactMethods(
                                open ? [index] : expandedContactMethods.filter(expandedMethod => expandedMethod !== index)
                              )
                            }
                            contactMethod={contactMethod}
                            onDelete={() => removeContactMethod(index)}
                          />

                          <Divider sx={{ mt: 1 }} />
                        </>
                      ))
                    )}
                  </List>
                </FormProvider>
              )}
              {!areContactMethodsValid && (
                <Alert
                  icon={<ReportProblemOutlinedIcon sx={{ color: 'warningTheme' }} />}
                  severity="warning"
                  sx={{
                    color: 'text.primary',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderColor: 'warningTheme.main',
                    borderWidth: 1,
                    my: 1,
                    borderStyle: 'solid',
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}
                >
                  <Typography variant="h6" fontWeight="normal" sx={{ color: `${theme.palette.common.black}99` }}>
                    {T.BILLING_CONTACT_EMALIL_WARNING_MSG}
                  </Typography>
                </Alert>
              )}
              <Button
                sx={{ mt: areContactMethodsValid && 3 }}
                fullWidth
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleAddContactMethod}
              >
                {capitalize(T.ADD_CONTACT_METHOD)}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <LoadingButton
            loading={isAddUpdateContactLoading}
            size="small"
            variant="contained"
            disabled={!areContactMethodsValid}
            onClick={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}
          >
            {T.SAVE}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

AddEditContactDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  contact: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default AddEditContactDialog
