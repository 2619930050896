import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Divider, useTheme, useMediaQuery, ListItem, SvgIcon, IconButton, ListItemText } from '@mui/material'

import { get } from 'utils/lodash'
import { formatDollarsToCents } from 'utils/price'

import T from 'T'
import { PencilAlt } from '@styled-icons/heroicons-solid/PencilAlt'
import UnpaidStats from 'components/billing/groups/payment/take-payments/UnpaidStats'
import AccountInfo from 'components/billing/groups/payment/take-payments/AccountInfo'
import SearchDropdown from 'components/billing/groups/payment/take-payments/SearchDropdown'
import PaymentType from 'components/billing/groups/payment/take-payments/PaymentType'
import InvoicesDataGrid from 'components/billing/groups/payment/take-payments/InvoicesDataGrid'
import { FIRST_STEP_LAYOUT_HEIGHT } from 'components/billing/groups/payment/take-payments/settings'

const SearchLayout = ({
  isLoading = false,
  accountId = '',
  accountData,
  savedPaymentMethods = [],
  isCustomerSearch = true,
  sortedUnpaidInvoices = [],
  hasInitialAccount = false,
  searchRef,
  localState = {},
  apiRef,
  initialCreditBalanceCents = 0,
  updatedCreditBalanceCents = 0,
  selectionModel = [],
  watchAmount = 0,
  hasSurcharge = false,
  billingAddressStr = '',
  onChange,
  onSearchChange,
  onRowCheckboxClick,
  onSelectAllClick,
  onAmountChange,
  onSubmit,
  onClearClick,
  onOpenEditBillingLocationDialog,
  ...rest
}) => {
  const theme = useTheme()
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { searchType, searchValue, selectedOption, editPaymentRefId, invoicesToPay } = localState
  const desktopGridHeight = `calc(100vh - (425px - ${hasInitialAccount ? '50' : 0}px))`
  const gridHeight = isTabletorMobile ? 250 : desktopGridHeight

  return (
    <Grid mt={1} container>
      <Grid
        item
        xs={12}
        md
        sx={{
          height: isTabletorMobile ? '100%' : FIRST_STEP_LAYOUT_HEIGHT,
          overflow: 'auto',
          px: 3,
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        <UnpaidStats invoices={selectedOption && isCustomerSearch ? sortedUnpaidInvoices : []} />
        <Divider sx={{ mt: 1, mb: 2 }} />
        {!hasInitialAccount && (
          <SearchDropdown
            ref={searchRef}
            searchType={searchType}
            editPaymentRefId={editPaymentRefId}
            searchValue={searchValue}
            selectedOption={selectedOption}
            onChange={onChange}
            onSearchChange={onSearchChange}
          />
        )}
        <Box height={gridHeight}>
          <InvoicesDataGrid
            apiRef={apiRef}
            loading={isLoading}
            selectedOption={selectedOption}
            amount={initialCreditBalanceCents + formatDollarsToCents(watchAmount)}
            rows={sortedUnpaidInvoices}
            billingProfileDetails={get(accountData, 'accountBilling.billingProfile', {})}
            selectionModel={selectionModel}
            onSelectionModelChange={onRowCheckboxClick}
            onSelectAll={onSelectAllClick}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{ height: isTabletorMobile ? '100%' : FIRST_STEP_LAYOUT_HEIGHT, overflow: 'auto', px: 3 }}
        {...(isTabletorMobile && { mt: 3 })}
      >
        <AccountInfo
          selectedAccount={selectedOption ? accountData : null}
          initialCreditBalanceCents={initialCreditBalanceCents}
          updatedCreditBalanceCents={updatedCreditBalanceCents}
          showUpdatedCreditBalanceCents={watchAmount > 0 || invoicesToPay.length > 0}
        />
        <Divider sx={{ mt: 1, mb: 2 }} />
        <PaymentType
          isLoading={isLoading}
          hasSurcharge={hasSurcharge}
          savedPaymentMethods={savedPaymentMethods}
          params={localState}
          initialCreditBalanceCents={initialCreditBalanceCents}
          isSinglePayment={hasInitialAccount}
          onChange={onChange}
          onAmountChange={onAmountChange}
          onNextBtnClick={onSubmit}
          onClearClick={onClearClick}
        />
        <Divider sx={{ my: 2 }} />
        <ListItem
          disableGutters
          secondaryAction={
            accountId && (
              <IconButton onClick={onOpenEditBillingLocationDialog}>
                <SvgIcon fontSize="small" sx={{ color: theme.palette.text.secondary }}>
                  <PencilAlt />
                </SvgIcon>
              </IconButton>
            )
          }
        >
          <ListItemText
            primary={T.BILLING_ADDRESS}
            primaryTypographyProps={{ variant: 'h5', fontWeight: 500 }}
            secondary={billingAddressStr}
            secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'textPrimary' }}
          />
        </ListItem>
      </Grid>
    </Grid>
  )
}

SearchLayout.propTypes = {
  isLoading: PropTypes.bool,
  accountId: PropTypes.string,
  accountData: PropTypes.object,
  savedPaymentMethods: PropTypes.array,
  isCustomerSearch: PropTypes.bool,
  sortedUnpaidInvoices: PropTypes.array,
  hasInitialAccount: PropTypes.bool,
  searchRef: PropTypes.object,
  localState: PropTypes.object,
  apiRef: PropTypes.object,
  initialCreditBalanceCents: PropTypes.number,
  updatedCreditBalanceCents: PropTypes.number,
  selectionModel: PropTypes.array,
  watchAmount: PropTypes.number,
  hasSurcharge: PropTypes.bool,
  billingAddressStr: PropTypes.string,
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onRowCheckboxClick: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  onAmountChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClearClick: PropTypes.func,
  onOpenEditBillingLocationDialog: PropTypes.func,
}

export default SearchLayout
