import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TableBody as TBody, TableCell, TableRow, TextField, Box, Tooltip } from '@mui/material'

import { NumericFormat } from 'react-number-format'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import Constants from 'Constants'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { classNames } from 'utils/styling'
import { DOLLAR_DECIMAL_DIGITS, PERCENTAGE_DECIMAL_DIGITS } from 'settings/constants/pricing'

const { MEDIA_SERVER } = Constants

const ValidatedTaxTableBody = ({
  validatedTaxes,
  confServices,
  handleDelete,
  onHandleValidatedFieldsChange,
  type,
  getTaxServiceName,
  saveConfSettings,
  onTableScopeChange,
}) => {
  const [allowedServices, setAllowedServices] = useState(confServices.filter(data => data.checked))
  useEffect(() => {
    setAllowedServices(confServices.filter(data => data.checked))
  }, [confServices])

  const lockedColumns = confServices.filter(column => column.locked && column.checked)

  return (
    <TBody className="table-body">
      {validatedTaxes &&
        allowedServices &&
        Children.toArray(
          validatedTaxes.map(service => {
            return (
              <TableRow>
                {Children.toArray(
                  allowedServices.map((column, ind) => {
                    let columnName = column.label
                    if (column.label === 'Value') {
                      columnName = 'taxValueType'
                    }

                    const record = getTaxServiceName(service, columnName, 'create')
                    const taxHoverTitle = get(service, 'taxName', '')
                    const isLocked = get(column, 'locked', false)

                    return (
                      <>
                        <TableCell className={handlePricingTableClass(ind, isLocked, lockedColumns)}>
                          {columnName === 'Tax' && (
                            <Tooltip title={taxHoverTitle}>
                              <div className="services-field">
                                <TextField
                                  id="outlined-size-small"
                                  value={record}
                                  variant="outlined"
                                  size="small"
                                  autoComplete="off"
                                  name="taxName"
                                  InputProps={{
                                    onChange: e => onHandleValidatedFieldsChange(e, service),
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}

                          {columnName === 'taxValueType' && (
                            <div className="prices-field">
                              <div
                                className={`dollar-btn ${service && service.taxValueType === '$' ? 'value-type-active' : ''}`}
                                onClick={() => onTableScopeChange('$', service, 'taxValueType')}
                              >
                                $
                              </div>

                              <Box
                                ml={1.25}
                                className={classNames(['percent-btn', service && service.taxValueType === '%' ? 'value-type-active' : ''])}
                                onClick={() => onTableScopeChange('%', service, 'taxValueType')}
                              >
                                %
                              </Box>
                              <NumericFormat
                                sx={{ ml: 1.25 }}
                                id="outlined-size-small"
                                placeholder="0.00"
                                value={record}
                                variant="outlined"
                                size="small"
                                autoComplete="off"
                                name="taxValue"
                                onChange={e => onHandleValidatedFieldsChange(e, service)}
                                customInput={TextField}
                                thousandSeparator
                                decimalSeparator="."
                                decimalScale={service?.taxValueType === '%' ? PERCENTAGE_DECIMAL_DIGITS : DOLLAR_DECIMAL_DIGITS}
                              />
                            </div>
                          )}
                          {columnName === 'Scope' && (
                            <div className="scope-section">
                              <div
                                className={`cumulative 
                                  ${
                                    service && service.taxScope === 'Cumulative' && service.taxValueType === '%' ? 'value-type-active' : ''
                                  } 
                                  
                                  ${service.taxValueType === '$' ? 'disable-switch' : ''}`}
                                onClick={() => onTableScopeChange('Cumulative', service, 'taxScope')}
                              >
                                <img src={`${MEDIA_SERVER}TaxCircuit.svg`} />
                              </div>

                              <div
                                className={`global ml10 
                                  ${service && service.taxScope === 'Global' && service.taxValueType === '%' ? 'value-type-active' : ''} 

                                  ${service.taxValueType === '$' ? 'disable-switch' : ''}`}
                                onClick={() => onTableScopeChange('Global', service, 'taxScope')}
                              >
                                <img src={`${MEDIA_SERVER}TaxSphere.svg`} />
                              </div>
                            </div>
                          )}

                          {!['taxValueType', 'Tax', 'Scope'].includes(columnName) && <div className="price-cell">{record}</div>}
                        </TableCell>
                      </>
                    )
                  })
                )}
                <TableCell>
                  <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleDelete(service, type)} />
                </TableCell>
              </TableRow>
            )
          })
        )}
    </TBody>
  )
}

ValidatedTaxTableBody.defaultProps = {
  validatedTaxes: [],
  confServices: [],
  type: 'unique',
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getTaxServiceName: null,
  onTableScopeChange: null,
  saveConfSettings: null,
}

ValidatedTaxTableBody.propTypes = {
  validatedTaxes: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  onTableScopeChange: PropTypes.func,
  saveConfSettings: PropTypes.func,
}

export default memo(ValidatedTaxTableBody)
