/* eslint-disable import/no-cycle */
import T from 'T'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { RenderAccountLinkCell, RenderCheckCell, RenderTagsCell, RenderPriceCell, RenderStatusCell } from 'components/data_grid/render_cell'
import { dateTimeValueFormatter, dateValueFormatter, moneyValueFormatter } from 'components/data_grid/formatters'
import RenderHeaderCell from 'components/data_grid/render_cell/RenderHeaderCell'
import RenderGetInvoiceActionsDataCell from 'components/data_grid/render_cell/RenderGetInvoiceActionsDataCell'

const {
  CUSTOMER,
  AMOUNT,
  BALANCE,
  STATUS,
  POSTED_DATE,
  POSTED,
  UNPOSTED,
  LATE_FEES,
  CYCLE,
  TYPE,
  PAYMENT_TERMS,
  PAPER,
  PDF,
  PORTAL,
  CUSTOMER_ADDRESS,
  CUSTOMER_CITY,
  CUSTOMER_STATE,
  CUSTOMER_ZIP,
  ACCOUNT,
  CUSTOMER_BILLING_PROFILE,
  TAGS,
  ADJUSTMENTS,
  ADJUSTMENTS_TOTAL,
} = T

export const INVOICE_CONFIGURATOR_DATA_GRID = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 50,
    resizable: false,
  },
  {
    flex: 2,
    minWidth: 200,
    headerName: CUSTOMER_BILLING_PROFILE,
    tableLabel: CUSTOMER_BILLING_PROFILE,
    label: CUSTOMER_BILLING_PROFILE,
    filterType: 'meta',
    field: 'customerBillingProfile',
    columnName: 'customerBillingProfile',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 120,
    headerName: `${ACCOUNT} #`,
    tableLabel: `${ACCOUNT} #`,
    label: `${ACCOUNT} #`,
    filterType: 'string',
    field: 'accountNumber',
    columnName: 'accountNumber',
    renderCell: RenderAccountLinkCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: CUSTOMER,
    tableLabel: CUSTOMER,
    label: CUSTOMER,
    filterType: 'string',
    field: 'customerName',
    columnName: 'customerName',
    renderCell: RenderAccountLinkCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 130,
    headerName: 'Invoice #',
    tableLabel: 'Invoice #',
    label: 'Invoice #',
    filterType: 'number',
    field: 'invoiceNumber',
    columnName: 'invoiceNumber',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 125,
    headerName: 'Invoice Date',
    tableLabel: 'Invoice Date',
    label: 'Invoice Date',
    filterType: 'date',
    field: 'invoiceDate',
    columnName: 'invoiceDate',
    subType: 'date',
    type: 'date',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateValueFormatter,
  },
  {
    flex: 2,
    minWidth: 170,
    headerName: 'Sent Date',
    tableLabel: 'Sent Date',
    label: 'Sent Date',
    filterType: 'date',
    field: 'sentDate',
    columnName: 'sentDate',
    subType: 'dateTime',
    type: 'dateTime',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateTimeValueFormatter,
  },
  {
    flex: 1,
    minWidth: 98,
    headerName: AMOUNT,
    tableLabel: AMOUNT,
    label: AMOUNT,
    filterType: 'number',
    field: 'amountCents',
    columnName: 'amountCents',
    subType: 'money',
    type: 'number',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 98,
    headerName: BALANCE,
    tableLabel: BALANCE,
    label: BALANCE,
    filterType: 'number',
    field: 'balanceCents',
    columnName: 'balanceCents',
    subType: 'money',
    type: 'number',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 120,
    headerName: STATUS,
    tableLabel: STATUS,
    label: STATUS,
    filterType: 'checkbox',
    field: 'status',
    columnName: 'status',
    subType: 'invoiceStatus',
    renderHeader: RenderHeaderCell,
    renderCell: RenderStatusCell,
  },
  { field: 'actions', type: 'actions', align: 'left', width: 90, resizable: false, getActions: RenderGetInvoiceActionsDataCell },
  {
    flex: 1,
    minWidth: 120,
    headerName: 'Work Orders',
    tableLabel: 'Work Orders',
    label: 'Work Orders',
    filterType: 'number',
    type: 'number',
    field: 'wo',
    columnName: 'wo',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 111,
    headerName: 'Services',
    tableLabel: 'Services',
    label: 'Services',
    filterType: 'number',
    type: 'number',
    field: 'service',
    columnName: 'service',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 3,
    minWidth: 170,
    headerName: POSTED_DATE,
    tableLabel: POSTED_DATE,
    label: POSTED_DATE,
    filterType: 'date',
    field: 'postDate',
    columnName: 'postDate',
    subType: 'dateTime',
    renderHeader: RenderHeaderCell,
    valueFormatter: dateTimeValueFormatter,
  },
  {
    flex: 1,
    minWidth: 100,
    headerName: POSTED,
    tableLabel: POSTED,
    label: POSTED,
    filterType: 'number',
    type: 'number',
    field: 'postedAmountCents',
    columnName: 'postedAmountCents',
    subType: 'money',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 111,
    headerName: UNPOSTED,
    tableLabel: UNPOSTED,
    label: UNPOSTED,
    filterType: 'number',
    type: 'number',
    field: 'unPostedAmountCents',
    columnName: 'unPostedAmountCents',
    subType: 'money',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
    valueFormatter: moneyValueFormatter,
  },
  {
    flex: 1,
    minWidth: 111,
    headerName: LATE_FEES,
    tableLabel: LATE_FEES,
    label: LATE_FEES,
    filterType: 'number',
    type: 'number',
    field: 'lateFees',
    columnName: 'lateFees',
    subType: 'price',
    renderHeader: RenderHeaderCell,
    renderCell: RenderPriceCell,
  },
  {
    flex: 2,
    minWidth: 89,
    headerName: CYCLE,
    tableLabel: CYCLE,
    label: CYCLE,
    filterType: 'meta',
    field: 'billingCycleText',
    columnName: 'billingCycleText',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 140,
    headerName: 'Billing Timing',
    tableLabel: 'Billing Timing',
    label: 'Billing Timing',
    filterType: 'string',
    field: 'billingTiming',
    columnName: 'billingTiming',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 96,
    headerName: TYPE,
    tableLabel: TYPE,
    label: TYPE,
    filterType: 'checkbox',
    field: 'type',
    columnName: 'type',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 2,
    minWidth: 150,
    headerName: PAYMENT_TERMS,
    tableLabel: PAYMENT_TERMS,
    label: PAYMENT_TERMS,
    filterType: 'meta',
    field: 'paymentTermsText',
    columnName: 'paymentTermsText',
    isSingleSelect: true,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 93,
    align: 'center',
    headerName: PAPER,
    tableLabel: PAPER,
    label: PAPER,
    filterType: 'checkbox',
    field: 'paper',
    columnName: 'paper',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
    renderHeader: RenderHeaderCell,
    renderCell: RenderCheckCell,
  },
  {
    flex: 1,
    minWidth: 83,
    align: 'center',
    headerName: PDF,
    tableLabel: PDF,
    label: PDF,
    filterType: 'checkbox',
    field: 'pdf',
    columnName: 'pdf',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
    renderHeader: RenderHeaderCell,
    renderCell: RenderCheckCell,
  },
  {
    flex: 1,
    minWidth: 89,
    align: 'center',
    headerName: PORTAL,
    tableLabel: PORTAL,
    label: PORTAL,
    columnName: PORTAL,
    filterType: 'checkbox',
    field: 'portal',
    subType: 'deliveryMethod',
    isSingleSelect: true,
    isBoolean: true,
    renderHeader: RenderHeaderCell,
    renderCell: RenderCheckCell,
  },
  {
    flex: 3,
    minWidth: 250,
    headerName: CUSTOMER_ADDRESS,
    tableLabel: CUSTOMER_ADDRESS,
    label: CUSTOMER_ADDRESS,
    filterType: 'string',
    field: 'customerAddress',
    columnName: 'customerAddress',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 144,
    headerName: CUSTOMER_CITY,
    tableLabel: CUSTOMER_CITY,
    label: CUSTOMER_CITY,
    filterType: 'string',
    field: 'customerCity',
    columnName: 'customerCity',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 160,
    headerName: CUSTOMER_STATE,
    tableLabel: CUSTOMER_STATE,
    label: CUSTOMER_STATE,
    filterType: 'string',
    field: 'customerState',
    columnName: 'customerState',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 140,
    headerName: CUSTOMER_ZIP,
    tableLabel: CUSTOMER_ZIP,
    label: CUSTOMER_ZIP,
    filterType: 'string',
    field: 'customerZip',
    columnName: 'customerZip',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 250,
    sortable: false,
    headerName: TAGS,
    tableLabel: TAGS,
    label: TAGS,
    filterType: 'string',
    field: 'tags',
    columnName: 'tags',
    subType: 'tags',
    renderCell: RenderTagsCell,
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 125,
    headerName: ADJUSTMENTS,
    tableLabel: ADJUSTMENTS,
    label: ADJUSTMENTS,
    filterType: 'number',
    type: 'number',
    field: 'adjustments',
    columnName: 'adjustments',
    renderHeader: RenderHeaderCell,
  },
  {
    flex: 1,
    minWidth: 200,
    headerName: ADJUSTMENTS_TOTAL,
    tableLabel: ADJUSTMENTS_TOTAL,
    label: ADJUSTMENTS_TOTAL,
    filterType: 'number',
    type: 'number',
    field: 'adjustmentsTotal',
    columnName: 'adjustmentsTotal',
    subType: 'price',
    renderCell: RenderPriceCell,
    renderHeader: RenderHeaderCell,
  },
]
