import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowRight, Check } from '@mui/icons-material'
import {
  Box,
  Grid,
  styled,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Popover,
  Card,
  CardActions,
  Menu,
  MenuItem,
  Link,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { ReactComponent as WorkOrderNoteIcon } from 'assets/note/WorkOrderNote.svg'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import { CheckCircle } from '@styled-icons/heroicons-outline/CheckCircle'
import {
  useCreateWorkOrderNoteMutation,
  useUpdateWorkOrderNoteNewMutation,
  usePatchWorkOrderNoteMutation,
  useLazyGetAllWorkOrderNotesQuery,
} from 'api/notes/workOrderNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'
import Loader from 'components/common/loader'
import T from 'T'
import api from 'api'
import { get } from 'lodash'
import { getAPIIdParam } from 'utils/form-body'
import { INVOICE_STATUS, INVOICE_STATUS_DISPLAY } from 'settings/constants/invoice'
import { putIsLoading } from 'middleware/actions/response'
import { CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { handleError } from 'utils/error'
import { canChangeWOInvoiceStatusSelector } from 'data/work-order/workOrderSelectors'
import moment from 'moment'
import routes, { APP_PREFIX, getWorkOrderDetailsPageUrl } from 'router/routes'
import { Photograph as UnstyledPhotograph } from '@styled-icons/heroicons-outline/Photograph'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import CardContent from '@mui/material/CardContent'
import HHSortKeys from 'components/common/HHSortKeys'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import WorkOrderStatusChip from 'components/work-order/WorkOrderStatusChip'
import WorkOrderInvoiceStatusChip from 'components/work-order/WorkOrderInvoiceStatusChip'
import WONotesDialogVariant from 'components/notes/work-order/WONotesDialogVariant'
import ViewArchiveNotesDialog from 'components/notes/common/ViewArchiveNotesDialog'
import ViewArchivedNotesMenuOption from 'components/notes/common/actions/ViewArchivedNotesMenuOption'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import StyledBadge from 'components/notes/common/StyledBadge'
import { createDateFromString, formatDateToURLFormatDateFns } from 'utils/date'
import { useLazySearchConfiguredServicesQuery } from 'api/configured-service/searchConfiguredServices'
import { changeWOPageSize } from 'slices/customer/customerDetailsSlice'
import { Accordion, AccordionSummary, AccordionDetails } from '../StyledAccordian'
import { HHAutocompleteField, HHTextField } from '../../../form-fields/v5'
import SelectItem from '../../../form-fields/v5/SelectItem'
import { useLazyFetchWorkOrderListQuery } from '../../../../api/work-order/fetchWorkOrderList'
import { WORK_ORDERS_PAGINATION } from '../../../../settings/constants/pagination'
import { WORK_ORDERS } from '../settings/workOrders'
import CustomerDetailsPaginations from '../CustomerDetailsPaginations'
import WorkOrdersPhotos from '../../../drawer/work-orders-photos'
import { TableSkelton } from '../../../common'
import ActionsColumn from '../../../common/ActionsColumn'
import CommonSwitch from '../../../common/CommonSwitch'
import StickyTableCell from '../../../common/StickyTableCell'
import ExternalLinkBehavior from '../../../common/ExternalLinkBehavior'
import ExceptionIcon from '../../../exceptions/ExceptionIcon'
import HHTableFilter from '../../../common/HHTableFilter'
import AddEditPurchaseOrderMenuOption from '../../../work-order/purchase-order/AddEditPurchaseOrderMenuOption'
import RemovePurchaseOrderMenuOption from '../../../work-order/purchase-order/RemovePurchaseOrderMenuOption'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE } = INVOICE_STATUS

const StyledTableCell = styled(TableCell)(() => ({
  padding: 8,
  height: '48px',
}))

const Photograph = styled(UnstyledPhotograph)`
  width: 16px;
  height: 16px;
`

const MAX_EXCEPTION_ALLOWED = 4

const { INITIAL_PAGE, ROWS_PER_PAGE_OPTIONS, ROWS_PER_PAGE } = WORK_ORDERS_PAGINATION

const STATUS_OPTIONS = ['Scheduled', 'In Process', 'Skipped', 'Canceled', 'Serviced', 'Hold']

const WorkOrders = ({ locationId = '', accountId }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [updateWorkOrder] = useUpdateWorkOrderMutation()
  const pageSize = useSelector(s => get(s, 'CustomerDetails.workOrders.pagination.size', ROWS_PER_PAGE))
  const [expanded, setExpanded] = useState(true)
  const [statusList, setStatusList] = useState(STATUS_OPTIONS)
  const [sortRules, setSortRules] = useState([
    {
      sortDirection: 'DESC',
      sortField: 'SERVICE_DATE',
    },
  ])
  const [photoState, setPhotoState] = useState({ open: false, currentWorkOrder: null })
  const [pagination, setPagination] = useState({
    page: INITIAL_PAGE,
  })
  const [getAllWorkOrderNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllWorkOrderNotesQuery()
  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { archivedNotes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })
  const [isOpenWONotesDialog, setIsOpenWONotesDialog] = useState(false)
  const [selectedNoteWorkOrderId, setSelectedNoteWorkOrderId] = useState('')
  const [isOpenViewArchiveNoteDialog, setIsOpenViewArchiveNoteDialog] = useState(false)
  const [isOpenAddNoteDialog, setIsOpenAddNoteDialog] = useState(false)
  const [woAction, setWoAction] = useState({
    isOpen: false,
    selectedWorkOrderId: '',
    selectedWorkOrderRouteId: '',
    selectedInvoiceStatus: '',
    selectedServiceType: '',
    selectedServiceDate: '',
    selectedPurchaseOrderNumber: '',
    selectedArchivedNotesCount: 0,
  })
  const {
    selectedWorkOrderId,
    selectedWorkOrderRouteId,
    selectedInvoiceStatus,
    selectedServiceType,
    selectedServiceDate,
    selectedPurchaseOrderNumber,
    selectedArchivedNotesCount,
  } = woAction
  const [filters, setFilters] = useState({
    anchorEl: null,
    serviceName: '',
    serviceNameInput: '',
    serviceNameOption: null,
  })
  const [fetchWorkOrderList, { isLoading, isFetching, data }] = useLazyFetchWorkOrderListQuery()
  const [searchConfiguredServices, { data: configuredServicesData, isUninitialized }] = useLazySearchConfiguredServicesQuery()
  const canChangeWOInvoiceStatus = canChangeWOInvoiceStatusSelector({
    serviceType: selectedServiceType,
    invoiceStatus: selectedInvoiceStatus,
  })
  const isLoadingOrFetching = isLoading || isFetching
  const hideWorkOrdersTable = !isLoadingOrFetching && (!Array.isArray(data?.workOrders) || data.workOrders.length === 0)
  const exceptions = get(photoState, 'currentWorkOrder.exceptions', [])
  const exceptionsWithPhotos = exceptions.filter(e => get(e, 'fileLocation', false))
  const handleWorkOrdersSortRulesChange = (sortField, sortDirection) => {
    const sortingRuleIdx = sortRules.findIndex(e => get(e, 'sortField', '') === sortField)
    if (sortingRuleIdx !== -1 && sortRules[sortingRuleIdx].sortDirection === sortDirection) {
      setSortRules(sortRules.filter((_, i) => sortingRuleIdx !== i))
    } else if (sortingRuleIdx !== -1 && sortRules[sortingRuleIdx].sortDirection !== sortDirection) {
      setSortRules(_sortRules =>
        _sortRules.map((item, idx) => {
          if (idx !== sortingRuleIdx) return item
          return {
            ...item,
            sortDirection,
          }
        })
      )
    } else {
      setSortRules([
        ...sortRules,
        {
          sortField,
          sortDirection,
        },
      ])
    }
    setPagination(p => ({ ...p, page: INITIAL_PAGE }))
  }

  const handleResetActionMenu = () =>
    setWoAction({
      isOpen: false,
      selectedWorkOrderId: '',
      selectedWorkOrderRouteId: '',
      selectedInvoiceStatus: '',
      selectedServiceType: '',
      selectedServiceDate: '',
      selectedPurchaseOrderNumber: '',
      selectedArchivedNotesCount: 0,
    })

  const handleCloseActionMenu = () => setWoAction(prev => ({ ...prev, isOpen: false }))

  const onServiceFilterClick = event => {
    setFilters(s => ({ ...s, anchorEl: event.currentTarget }))
  }

  const onCancelFilterClick = () => {
    setPagination(p => ({ ...p, page: INITIAL_PAGE }))
    setFilters({ serviceNameInput: '', serviceName: '', serviceNameOption: null, anchorEl: null })
  }

  const onServiceNameChange = (event, newValue) => {
    setFilters(s => ({ ...s, serviceNameInput: newValue }))
  }

  const onServiceNameOptionChange = (event, newOption) => {
    setFilters(s => ({ ...s, serviceNameOption: newOption }))
  }

  const onSaveFilterClick = () => {
    const { serviceNameInput, serviceNameOption } = filters
    setPagination(p => ({ ...p, page: INITIAL_PAGE }))
    const serviceNameValue = serviceNameOption?.name ? serviceNameOption.name : serviceNameInput
    setFilters(s => ({ ...s, anchorEl: null, serviceName: serviceNameValue }))
  }

  const getServiceOptionLabel = ({ name }) => name

  const handleInvoiceStatusChange = () => {
    dispatch(putIsLoading(true))
    updateWorkOrder({
      id: selectedWorkOrderId,
      invoiceStatus: selectedInvoiceStatus === READY_FOR_INVOICE ? NOT_READY_FOR_INVOICE : READY_FOR_INVOICE,
    })
      .unwrap()
      .then(() => {
        handleResetActionMenu()
        toast.success('Invoice status updated successfully')
        dispatch(api.util.invalidateTags([CACHE_TAG_WORK_ORDER_LIST]))
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useEffect(() => {
    if (accountId || locationId) {
      fetchWorkOrderList({
        ...getAPIIdParam(locationId, accountId),
        ...pagination,
        size: pageSize,
        sortRules,
        statusList,
        serviceName: filters?.serviceName,
      })
        .unwrap()
        .catch(handleError)
    }
  }, [accountId, locationId, pagination, sortRules, statusList, filters?.serviceName, pageSize])

  useEffect(() => {
    if ((accountId || locationId) && isUninitialized) {
      searchConfiguredServices({
        startDate: new Date(),
        ...getAPIIdParam(locationId, accountId),
        page: 0,
        size: 100,
        status: 'Active',
      })
    }
  }, [accountId, locationId, isUninitialized])

  const handleOpenWONotesDialog = (event, workOrderId) => {
    event.stopPropagation()
    setSelectedNoteWorkOrderId(workOrderId)
    setIsOpenWONotesDialog(true)
  }

  const handleCloseWONotesDialog = () => {
    setIsOpenWONotesDialog(false)
    setSelectedNoteWorkOrderId('')
  }

  const handleOpenAddNoteClick = () => {
    handleCloseActionMenu()
    setIsOpenAddNoteDialog(true)
  }

  const handleCloseAddNote = () => {
    setIsOpenAddNoteDialog(false)
    handleResetActionMenu()
  }

  const handleViewArchiveClick = () => {
    handleCloseActionMenu()
    getAllWorkOrderNotes({ workOrderId: selectedWorkOrderId })
      .unwrap()
      .then(() => setIsOpenViewArchiveNoteDialog(true))
      .catch(handleError)
  }

  const handleCloseViewArchiveDialog = () => setIsOpenViewArchiveNoteDialog(false)

  return (
    <>
      {isGetAllNotesLoading && <Loader />}
      <Accordion expanded={expanded}>
        <AccordionSummary
          withNewColors
          disablePointer
          expandIcon={<ArrowRight cursor="pointer" color="disabled" onClick={() => setExpanded(!expanded)} />}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
              <Box display="inline-flex" alignItems="center">
                <Typography sx={{ cursor: 'pointer' }} variant="h6" fontWeight={500} onClick={() => setExpanded(!expanded)}>
                  {T.WORK_ORDERS}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} container justifyContent="end">
              <Box width="24rem">
                <HHTextField
                  inputValue={filters.serviceNameInput}
                  fullWidth
                  select
                  size="small"
                  variant="outlined"
                  SelectProps={{
                    multiple: true,
                  }}
                  value={statusList}
                  onChange={event => {
                    const {
                      target: { value },
                    } = event
                    const statusList = typeof value === 'string' ? value.split(',') : value
                    if (Array.isArray(statusList) && statusList.length === 0) return
                    setStatusList(statusList)
                    setPagination(p => ({ ...p, page: INITIAL_PAGE }))
                  }}
                >
                  {STATUS_OPTIONS.map(status => (
                    <SelectItem value={status}>
                      {status}
                      {statusList.includes(status) && <Check sx={{ width: '24px', height: '24px', display: 'none' }} />}
                    </SelectItem>
                  ))}
                </HHTextField>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {!isLoadingOrFetching && (!Array.isArray(data?.workOrders) || data.workOrders.length === 0) && (
                <HHSectionPlaceholder title={T.NO_WORK_ORDERS} />
              )}

              {/* Pull skelton outside the table and then remove the Box */}
              {!hideWorkOrdersTable && (
                <Box>
                  <TableContainer sx={{ px: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {WORK_ORDERS.map(({ label, sortKey = undefined, headerSx = {} }) => {
                            const sortRule = sortRules.find(e => get(e, 'sortField', '') === sortKey)
                            const isDescendingOrderActive = get(sortRule, `sortDirection`) === 'DESC'
                            const isAscendingOrderActive = get(sortRule, `sortDirection`) === 'ASC'
                            return (
                              <StyledTableCell align="left" sx={{ paddingY: '12px', ...headerSx }}>
                                <Box display="flex" alignItems="center" justifyContent="start">
                                  {label === T.SERVICE && <HHTableFilter isActive={filters?.serviceName} onClick={onServiceFilterClick} />}
                                  <Typography variant="subtitle2">{label}</Typography>

                                  {sortKey && (
                                    <HHSortKeys
                                      isAscendingOrderActive={isAscendingOrderActive}
                                      isDescendingOrderActive={isDescendingOrderActive}
                                      columnName={sortKey}
                                      handleChange={handleWorkOrdersSortRulesChange}
                                    />
                                  )}
                                </Box>
                              </StyledTableCell>
                            )
                          })}
                          <StickyTableCell sx={{ background: 'transparent' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* WORK_ORDERS.length + 1 for action column */}
                        {isLoadingOrFetching && <TableSkelton rows={pageSize} columns={WORK_ORDERS.length + 1} />}
                        {!isLoadingOrFetching &&
                          Array.isArray(data?.workOrders) &&
                          data.workOrders.length > 0 &&
                          data.workOrders.map(workOrder => {
                            const workOrderId = get(workOrder, 'workOrderId', '')
                            const purchaseOrderNumber = get(workOrder, 'purchaseOrderNumber', '')
                            const workOrderNumber = get(workOrder, 'workOrderNumber', '')
                            const serviceDate = get(workOrder, 'serviceDate', '')
                            const date = serviceDate && moment(serviceDate).format('L')
                            const exceptions = get(workOrder, 'exceptions', [])
                            const hasExtraExceptions = exceptions.length > MAX_EXCEPTION_ALLOWED
                            const skipFromIndex = hasExtraExceptions ? MAX_EXCEPTION_ALLOWED - 1 : MAX_EXCEPTION_ALLOWED
                            const totalImages = exceptions.reduce(
                              (acc, exception) => (acc + exception.fileLocation && exception.fileContentType === 'image/jpeg' ? 1 : 0),
                              0
                            )
                            const feeTotalCents = get(workOrder, 'feeTotalCents', 0)
                            const status = get(workOrder, 'workOrderStatus', '')
                            const invoiceStatus = get(workOrder, 'invoiceStatus', '')
                            const routeId = get(workOrder, 'routeId', '')
                            const serviceType = get(workOrder, 'pricedServiceType')
                            const routeName = workOrder?.routeName
                            const routeUrl = `${routes.app.routes}?service_date=${formatDateToURLFormatDateFns(
                              createDateFromString(serviceDate)
                            )}`
                            const notesCount = get(workOrder, 'notesCount', 0)
                            const archivedNotesCount = get(workOrder, 'archivedNotesCount', 0)
                            return (
                              <TableRow
                                hover
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  history.push({
                                    pathname: getWorkOrderDetailsPageUrl(workOrderId),
                                    state: { prevPath: location?.pathname },
                                  })
                                }
                              >
                                <StyledTableCell>
                                  <Typography variant="body1">
                                    <Link href={getWorkOrderDetailsPageUrl(workOrderId)} underline="hover" color="inherit">
                                      {workOrderNumber}
                                    </Link>
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography color="text.secondary" variant="body1">
                                    {date}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography variant="body1">{workOrder?.serviceName}</Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {routeName && (
                                    <Link href={routeUrl} underline="hover" color="inherit">
                                      {routeName}
                                    </Link>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography variant="body1">{purchaseOrderNumber}</Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Stack direction="row" gap={0.5} display="flex" alignItems="center">
                                    {exceptions
                                      .slice(0, skipFromIndex)
                                      .filter(exception => Boolean(exception?.exceptionType))
                                      .map(exception => {
                                        const type = get(exception, 'exceptionType', '')
                                        return <ExceptionIcon fontSize="small" exception={type} />
                                      })}
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <IconButton size="small" onClick={event => handleOpenWONotesDialog(event, workOrderId)}>
                                    <StyledBadge badgeContent={notesCount}>
                                      <WorkOrderNoteIcon />
                                    </StyledBadge>
                                  </IconButton>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {totalImages > 0 && (
                                    <Button
                                      onClick={e => {
                                        e.stopPropagation()
                                        setPhotoState({
                                          open: true,
                                          currentWorkOrder: workOrder,
                                        })
                                      }}
                                      variant="outlined"
                                      color="secondary"
                                      sx={{ padding: '6px', minWidth: 0 }}
                                    >
                                      <Photograph />
                                    </Button>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography variant="body1" fontWeight={600}>
                                    <HHDisplayMoney value={feeTotalCents} />
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <WorkOrderStatusChip status={status} />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <WorkOrderInvoiceStatusChip status={invoiceStatus} />
                                </StyledTableCell>
                                <ActionsColumn
                                  sx={{ background: 'transparent' }}
                                  onClick={event => {
                                    event.stopPropagation()
                                    setWoAction({
                                      isOpen: event.currentTarget,
                                      selectedWorkOrderId: workOrderId,
                                      selectedWorkOrderRouteId: routeId,
                                      selectedInvoiceStatus: invoiceStatus,
                                      selectedServiceType: serviceType,
                                      selectedServiceDate: serviceDate,
                                      selectedPurchaseOrderNumber: purchaseOrderNumber,
                                      selectedArchivedNotesCount: archivedNotesCount,
                                    })
                                  }}
                                />
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Grid>
            {get(data, 'totalItems', 0) > 0 && (
              <Grid item xs={12}>
                <CustomerDetailsPaginations
                  withNewColors
                  page={pagination.page}
                  totalCount={data?.totalItems}
                  rowsPerPage={pageSize}
                  handlePageChange={newPage => {
                    setPagination(p => ({ ...p, page: newPage }))
                  }}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  handleRowsPerPageChange={event => {
                    const newPageSize = event.target.value
                    dispatch(changeWOPageSize(newPageSize))
                    setPagination(p => ({ ...p, page: INITIAL_PAGE }))
                  }}
                  totalPageCount={data?.totalPages}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <WorkOrdersPhotos
        title={T.WO_PHOTOS}
        isOpenDrawer={photoState.open}
        exceptions={exceptionsWithPhotos}
        showNavigation
        onClose={() => setPhotoState({ open: false, currentWorkOrder: null })}
      />
      <Menu
        open={Boolean(woAction.isOpen)}
        anchorEl={woAction.isOpen}
        onClose={handleCloseActionMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...(canChangeWOInvoiceStatus && { slotProps: { paper: { sx: { minWidth: 275 } } } })}
      >
        {selectedWorkOrderRouteId && selectedWorkOrderId && (
          <MenuItem>
            <Button
              component={ExternalLinkBehavior}
              to={`${APP_PREFIX}/routes/${selectedWorkOrderRouteId}/work_orders/${selectedWorkOrderId}/export?date=${selectedServiceDate}`}
              variant="text"
              sx={{ color: 'inherit', fontSize: '13px', fontWeight: '400', margin: '0', padding: '0' }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <Printer width={24} height={24} />
              </ListItemIcon>
              <ListItemText>{T.PRINTABLE_VIEW}</ListItemText>
            </Button>
          </MenuItem>
        )}
        {canChangeWOInvoiceStatus && (
          <MenuItem onClick={handleInvoiceStatusChange}>
            <ListItemIcon sx={{ mr: 1 }}>
              <CheckCircle width={24} height={24} />
            </ListItemIcon>
            <ListItemText>{INVOICE_STATUS_DISPLAY.READY_FOR_INVOICE.text}</ListItemText>
            <CommonSwitch isChecked={selectedInvoiceStatus === READY_FOR_INVOICE} />
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenAddNoteClick}>
          <ListItemIcon sx={{ mr: 1 }}>
            <WorkOrderNoteIcon />
          </ListItemIcon>
          <ListItemText>{T.ADD_NOTE}</ListItemText>
        </MenuItem>
        <ViewArchivedNotesMenuOption
          isDisabled={selectedArchivedNotesCount === 0}
          count={selectedArchivedNotesCount}
          onClick={handleViewArchiveClick}
        />
        <AddEditPurchaseOrderMenuOption
          workOrderId={selectedWorkOrderId}
          existingPurchaseOrder={selectedPurchaseOrderNumber}
          onClose={handleResetActionMenu}
        />
        <RemovePurchaseOrderMenuOption
          workOrderId={selectedWorkOrderId}
          existingPurchaseOrder={selectedPurchaseOrderNumber}
          onClose={handleResetActionMenu}
        />
      </Menu>
      <Popover
        sx={{ mt: 1 }}
        open={Boolean(filters.anchorEl)}
        anchorEl={filters.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onCancelFilterClick}
      >
        <Card variant="outlined">
          <CardContent sx={{ py: 1 }}>
            <HHAutocompleteField
              size="small"
              sx={{ width: '20rem' }}
              label={T.SERVICE}
              onChange={onServiceNameOptionChange}
              onInputChange={onServiceNameChange}
              options={Array.isArray(configuredServicesData?.services) ? configuredServicesData?.services : []}
              getOptionLabel={getServiceOptionLabel}
              fullWidth
              inputValue={filters?.serviceNameInput}
              value={filters?.serviceNameOption}
              placeholder={T.SEARCH_SERVICES}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between', px: 2 }}>
            <Button variant="outlined" size="small" onClick={onCancelFilterClick}>
              {T.CANCEL}
            </Button>
            <Button size="small" variant="contained" onClick={onSaveFilterClick}>
              {T.APPLY}
            </Button>
          </CardActions>
        </Card>
      </Popover>
      <WONotesDialogVariant isOpen={isOpenWONotesDialog} workOrderId={selectedNoteWorkOrderId} onClose={handleCloseWONotesDialog} />
      <AddUpdateNoteDialog
        id={selectedWorkOrderId}
        isOpen={isOpenAddNoteDialog}
        title={T.NEW_WORK_ORDER_NOTE}
        useCreateNoteMutation={useCreateWorkOrderNoteMutation}
        useUpdateNoteMutation={useUpdateWorkOrderNoteNewMutation}
        onClose={handleCloseAddNote}
      />
      <ViewArchiveNotesDialog
        id={selectedWorkOrderId}
        isOpen={isOpenViewArchiveNoteDialog}
        title={T.ARCHIVED_WORK_ORDER_NOTES}
        notes={archivedNotes}
        useUnarchiveNoteMutation={usePatchWorkOrderNoteMutation}
        onClose={handleCloseViewArchiveDialog}
      />
    </>
  )
}

WorkOrders.propTypes = {
  locationId: PropTypes.string,
  accountId: PropTypes.string.isRequired,
}

export default WorkOrders
