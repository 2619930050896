import React, { useState } from 'react'
import PropTypes from 'prop-types'
import without from 'lodash/without'

import { grey } from '@mui/material/colors'
import { ListItem, ListItemText, Collapse, List, IconButton, Grid, Divider } from '@mui/material'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { getCustomerBillingDetails } from 'data/customer/billingDetailsSelector'

import T from 'T'
import BillingField from '../../billing/BillingField'

const DEFAULT_PRICE_TYPE = '$'

const ListView = ({ billingProfile = {} }) => {
  const [open, setOpen] = useState(false)
  const handleClick = event => {
    event.stopPropagation()
    setOpen(!open)
  }

  const {
    billingCycle,
    paymentType,
    timing,
    firstMonths,
    isLateFeeTypeDollar,
    lateFeeAmount,
    lateFeeThreshold,
    lateFeeGracePeriod,
    paymentTerms,
    deliveryMethod,
  } = getCustomerBillingDetails({ accountBilling: { billingProfile } })

  return (
    <>
      <ListItem sx={{ px: 1 }} className="billing-detail-summary">
        <IconButton size="small" onClick={handleClick}>
          {open ? <ExpandMore /> : <ChevronRight />}
        </IconButton>
        <ListItemText sx={{ display: 'grid' }} primary={billingProfile?.name} secondary={`${paymentType} - ${billingCycle}`} />
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className="billing-detail" disablePadding sx={{ py: 1, pl: 5, pr: 1, background: grey[50] }}>
            <Grid container columnGap={1}>
              <Grid item xs>
                <BillingField label={T.TYPE} value={paymentType} />
                <BillingField label={T.TIMING} value={timing} />
                <BillingField label={T.CYCLE} value={billingCycle} />
                {firstMonths && <BillingField label={T.FIRST_MONTHS} value={firstMonths} />}
                <BillingField label={T.PAYMENT_TERMS} value={paymentTerms} />
              </Grid>
              <Grid item xs="auto">
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs>
                <BillingField
                  label={T.LATE_FEES_FINANCING}
                  value={lateFeeAmount}
                  isNumberFormat
                  prefix={isLateFeeTypeDollar ? DEFAULT_PRICE_TYPE : ''}
                  suffix={!isLateFeeTypeDollar ? '%' : ''}
                />
                <BillingField label={T.THRESHOLD} value={lateFeeThreshold} isNumberFormat prefix={DEFAULT_PRICE_TYPE} />
                <BillingField label={T.GRACE_PERIOD} value={lateFeeGracePeriod} />
                <BillingField
                  label={T.DELIVERY_METHOD}
                  value={without(deliveryMethod.replace('Pdf', 'PDF').split(', '), T.POSTCARD).join(', ')}
                  mb={0}
                />
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}

ListView.propTypes = {
  billingProfile: PropTypes.object,
}

export default ListView
