import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Button, DialogContent, DialogContentText, Stack } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getAddCallFollowUpPayload } from 'utils/form-body'
import { getContactFromAccountOrLocation, getContactNameList, getContactMethodList } from 'utils/contact'
import { getUserSearchResult } from 'middleware/actions/customers'
import { useAddUpdateCallFollowUpMutation } from 'api/calls/addUpdateCallFollowUp'
import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'
import { USER_SEARCH_PAGINATION } from 'settings/constants/pagination'
import { DIRECTION_OPTIONS } from 'settings/constants/customer'
import { CommonTextLabel, CommonSelect, CommonPhotoSelect } from 'components/common'

import T from 'T'
import CancelButton from 'components/buttons/CancelButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import TagsSelect from 'components/tags/TagsSelect'
import TagsWrapper from 'components/customers/add/tab-content/TagsWrapper'
import Loader from 'components/common/loader'

import { CACHE_TAG_CALLS_LIST, CACHE_TAG_FOLLOW_UP_LIST } from 'api/cacheTagTypes'
import api from 'api'
import Box from '@mui/material/Box'

const { INITIAL_PAGE, ROWS_PER_PAGE } = USER_SEARCH_PAGINATION

const AddCallsFollowupDialog = ({ isFollowUp = false, isOpen = false, locationId = '', accountId, onClose }) => {
  const dispatch = useDispatch()
  const [addUpdateCallFollowUp, { isLoading: isAddCallFollowUpLoading }] = useAddUpdateCallFollowUpMutation()

  const { userSearchResult, globalFilterOptions, customerDetails } = useSelector(
    state => ({
      userSearchResult: get(state, 'CustomersReducer.userSearchResult', []),
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
      customerDetails: get(state, 'CustomersReducer.customerDetails', null),
    }),
    shallowEqual
  )

  const globalTags = get(globalFilterOptions, 'tags', []).filter(data => data.active && data[locationId ? 'forLocation' : 'forAccount'])

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    contactName: '',
    contactMethod: '',
    direction: DIRECTION_OPTIONS[0],
    followUp: isFollowUp,
    assignee: null,
    tags: [],
    subject: '',
    note: '',
    isOpenPaymentDrawer: false,
    requestedPayments: [],
  })

  const { contactName, contactMethod, direction, followUp, assignee, subject, note, tags } = localState

  const disabledCreateCallsFollowUpBtn = (() => {
    return !(contactName.trim() && subject.trim() && (!followUp || assignee))
  })()

  const { allContacts } = getContactFromAccountOrLocation(customerDetails, locationId)

  // Get assignee list
  const getUserSearchList = (search = '') => {
    dispatch(
      getUserSearchResult({
        search,
        requestedPage: INITIAL_PAGE,
        requestedPageSize: ROWS_PER_PAGE,
      })
    )
  }

  const handleStateChange = (name, value, resetDependentField = '') => {
    if (resetDependentField) {
      setLocalState({ [name]: value, [resetDependentField]: '' })
      return
    }
    setLocalState({ [name]: value })
    if (name === 'contactMethod' && !contactName) {
      setLocalState({
        contactName: get(
          allContacts.find(contact => contact.contactMethods.some(method => method.id === value)),
          'id',
          ''
        ),
      })
    }
  }

  const saveCallFollowUp = () => {
    const payload = getAddCallFollowUpPayload(localState, accountId, locationId)

    addUpdateCallFollowUp(payload)
      .unwrap()
      .then(() => {
        if (followUp) {
          dispatch(api.util.invalidateTags([CACHE_TAG_FOLLOW_UP_LIST]))
        } else {
          dispatch(api.util.invalidateTags([CACHE_TAG_CALLS_LIST]))
        }
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      getUserSearchList()
      dispatch(getGlobalFilterOptions())
    }
  }, [isOpen])

  return (
    <>
      {isAddCallFollowUpLoading && <Loader />}
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <HHDialogTitle title={isFollowUp ? 'Add follow up' : 'Add call'} onClose={onClose} />
        <DialogContent>
          <DialogContentText>
            <div className="call-section-container">
              <div className="call-section">
                <Box
                  width="40%"
                  className="left-section common-section"
                  // Temporary fix, Replace all CommonSelect with HHTextField
                  sx={{ '& .common-calls-select-container': { border: '1px solid', borderColor: 'border.light', borderRadius: 1 } }}
                >
                  <Stack flexDirection="row" alignItems="center" justifyContent="space-around">
                    <CommonTextLabel title={T.CONTACT_NAME} className="ttc left-labels" isRequired />
                    <CommonSelect
                      className="common-calls-select"
                      placeholder="-"
                      placeholderHidden
                      showEmpty={getContactNameList(allContacts).length === 0}
                      emptyText="No Contact Available"
                      value={contactName}
                      options={getContactNameList(allContacts, contactName)}
                      onChange={e => handleStateChange('contactName', e.target.value, 'contactMethod')}
                    />
                  </Stack>
                  <Stack flexDirection="row" alignItems="center" justifyContent="space-around" sx={{ mt: 2 }}>
                    <CommonTextLabel title={T.CONTACT_METHOD} className="ttc left-labels" />
                    <CommonSelect
                      className="common-calls-select"
                      placeholder="-"
                      placeholderHidden
                      showEmpty={getContactMethodList(allContacts, contactName).length === 0}
                      emptyText="No Method Available"
                      value={contactMethod}
                      options={getContactMethodList(allContacts, contactName)}
                      onChange={e => handleStateChange('contactMethod', e.target.value)}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      mt: 2,
                    }}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <CommonTextLabel title={T.DIRECTION} className="ttc left-labels" />
                    <CommonSelect
                      className="common-calls-select"
                      value={direction}
                      options={DIRECTION_OPTIONS}
                      onChange={e => handleStateChange('direction', e.target.value)}
                    />
                  </Stack>
                  {followUp && (
                    <Stack
                      sx={{
                        mt: 2,
                      }}
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <CommonTextLabel title={T.ASSIGNEE} className="ttc left-labels" isRequired />
                      <CommonPhotoSelect
                        users={userSearchResult}
                        selectedUser={assignee}
                        className="assignee-dropdown"
                        placeholder={T.ASSIGNEE}
                        onChange={(isChanged, value) => {
                          if (isChanged) {
                            setLocalState({ assignee: value })
                          }
                          // TODO: For future don't call API for empty value, load from state directly just like Account rep in add customer
                          getUserSearchList(isChanged ? '' : value)
                        }}
                      />
                    </Stack>
                  )}
                </Box>
                <Box width="60%" className="right-section">
                  <div className="right-block">
                    <Stack flexDirection="row" alignItems="center" justifyContent="space-around">
                      <CommonTextLabel width="9.5%" title={T.SUBJECT} className="left-labels" isRequired />
                      <Box
                        component="textarea"
                        width="90%"
                        ml={2}
                        className="common textarea subject"
                        placeholder={T.SUBJECT}
                        value={subject}
                        onChange={event => handleStateChange('subject', event.target.value)}
                      />
                    </Stack>
                    <Stack
                      sx={{
                        mt: 2,
                      }}
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="space-around"
                    >
                      <CommonTextLabel title={T.NOTE} width="10%" mt={0.5} className="left-labels" />
                      <Box
                        component="textarea"
                        width="90%"
                        ml={2}
                        className={`common textarea note  ${followUp ? 'follow-up' : 'call'}`}
                        placeholder={T.NOTE}
                        value={note}
                        onChange={event => handleStateChange('note', event.target.value)}
                      />
                    </Stack>
                    <Stack sx={{ mt: 2 }} flexDirection="row" alignItems="center" justifyContent="space-around">
                      <CommonTextLabel width="10%" title={T.TAGS} className="left-labels" />
                      <TagsWrapper sx={{ backgroundColor: 'background.paper', ml: 2 }} width="90%">
                        <TagsSelect
                          tags={globalTags}
                          selectedTags={tags}
                          onChange={(e, selectedOptions) => handleStateChange('tags', selectedOptions)}
                          limit={1}
                        />
                      </TagsWrapper>
                    </Stack>
                  </div>
                </Box>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <Button disabled={disabledCreateCallsFollowUpBtn} size="small" variant="contained" onClick={saveCallFollowUp}>
            {T.SAVE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

AddCallsFollowupDialog.propTypes = {
  isOpen: PropTypes.bool,
  isFollowUp: PropTypes.bool,
  locationId: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddCallsFollowupDialog
