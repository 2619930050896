import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    revertInvoiceToDraft: build.mutation({
      query: ({ id }) => ({ url: `/api/v1/billing/invoice/${id}/revert-to-draft`, method: 'POST' }),
    }),
  }),
})

export const { useRevertInvoiceToDraftMutation } = extendedApi
