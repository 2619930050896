import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Button } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAllBillingNotesQuery, useCreateBillingNoteMutation, useUpdateBillingNoteMutation } from 'api/notes/billingNotesCrud'
import { getSortedNotes, getArchivedAndUnarchivedNotes } from 'data/notes/notesSelectors'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import BillingNotes from './BillingNotes'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import NoteSkeleton from '../common/NoteSkeleton'

const BillingNotesDialogVariant = ({ isOpen = false, accountId = '', getCachedData = false, onClose }) => {
  const [getAllBillingNotes, { isFetching: isGetAllNotesLoading, data: notesData }] = useLazyGetAllBillingNotesQuery()
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)

  const sortedNotes = getSortedNotes({ notes: get(notesData, 'notes', []) })
  const { unarchivedNotes: notes } = getArchivedAndUnarchivedNotes({ notes: sortedNotes })

  const handleOpenAddBillingNote = () => {
    setIsOpenAddUpdateNoteDialog(true)
  }

  const handleCloseAddBillingNote = () => {
    setIsOpenAddUpdateNoteDialog(false)
  }

  useEffect(() => {
    if (isOpen && accountId) {
      getAllBillingNotes({ id: accountId }, getCachedData).unwrap().catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={`${T.BILLING_NOTE}s`} onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          {isGetAllNotesLoading && <NoteSkeleton rows={1} />}
          {!isGetAllNotesLoading && (
            <BillingNotes
              itemSx={{
                [`.MuiListItemSecondaryAction-root`]: {
                  right: 12,
                },
              }}
              notes={notes}
              accountId={accountId}
              noNotesText={T.NO_BILLING_NOTES}
            />
          )}
        </DialogContent>
        <HHDialogActions>
          <Button variant="outlined" onClick={handleOpenAddBillingNote}>
            {T.CREATE_NOTE}
          </Button>
          <Button variant="contained" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={T.NEW_BILLING_NOTE}
        useCreateNoteMutation={useCreateBillingNoteMutation}
        useUpdateNoteMutation={useUpdateBillingNoteMutation}
        onClose={handleCloseAddBillingNote}
      />
    </>
  )
}

BillingNotesDialogVariant.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string,
  getCachedData: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default BillingNotesDialogVariant
