import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import IconButton from '@mui/material/IconButton'
import { ReactComponent as TableFilterIcon } from 'assets/TableFilterIcon.svg'
import { ReactComponent as TableFilterFilledIcon } from 'assets/TableFilterFilledIcon.svg'

const HHTableFilter = ({ isDisabled = false, isActive = false, onClick = noop, ...rest }) => (
  <IconButton disabled={isDisabled} onClick={onClick} {...rest}>
    {isActive ? <TableFilterFilledIcon /> : <TableFilterIcon />}
  </IconButton>
)

HHTableFilter.propTypes = {
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
export default HHTableFilter
