import React from 'react'
import { Box, CardActionArea, Grid } from '@mui/material'
import RenderLocationLeftContentCell from 'components/locations/LocationsDataGrid/RenderLocationLeftContentCell'
import RenderLocationRightContentCell from 'components/locations/LocationsDataGrid/RenderLocationRightContentCell'
import { get } from 'lodash'
import { getCustomerDetailsPageUrl } from 'router/routes'
import { createSearchParams } from 'react-router-dom-v5-compat'
import Link from 'components/common/Link'
import { LocationRowProvider } from 'components/locations/LocationsDataGrid/LocationRowContext'
import PropTypes from 'prop-types'

const RenderLocationCell = props => {
  const { row } = props
  const accountId = get(row, 'accountId')
  const locationId = get(row, 'locationId')
  const accountDetailsBaseURL = getCustomerDetailsPageUrl(accountId)

  return (
    <LocationRowProvider>
      <CardActionArea
        to={{
          pathname: accountDetailsBaseURL,
          search: createSearchParams({
            ...(locationId && { location: locationId }),
          }).toString(),
        }}
        component={Link}
        color="inherit"
        underline="none"
        focusRipple={false}
        disableRipple
        disableTouchRipple
        sx={{
          py: 1,
          pl: 0,
          pr: 0,
          height: '100%',
          backgroundColor: 'inherit',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <Box height="100%" width="100%" overflow="hidden">
          <Grid container alignItems="center" justifyContent="center" flexWrap="nowrap" sx={{ width: '100%', height: '100%' }}>
            <Grid item xs zeroMinWidth>
              <RenderLocationLeftContentCell {...props} />
            </Grid>
            <Grid item xs="auto" sm="auto" md lg xl sx={{ minWidth: '40px' }}>
              <RenderLocationRightContentCell {...props} />
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </LocationRowProvider>
  )
}

RenderLocationCell.propTypes = {
  row: PropTypes.object,
}

export default RenderLocationCell
