import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    deleteWorkOrdersDisposalTicket: builder.mutation({
      query: ({ workOrderId, disposalTicketId }) => ({
        url: `/api/v1/core/work-orders/${workOrderId}/disposal-tickets/${disposalTicketId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { useDeleteWorkOrdersDisposalTicketMutation } = extendedApi
