import T from 'T'

export const ALL_DAYS = [T.MONDAY, T.TUESDAY, T.WEDNESDAY, T.THURSDAY, T.FRIDAY, T.SATURDAY, T.SUNDAY]

export const WEEK_OF_MONTH = [T.FIRST, T.SECOND, T.THIRD, T.FOURTH]

export const WEEK_OF_MONTH_IN_NUMBERS = [
  { key: 1, value: T.FIRST },
  { key: 2, value: T.SECOND },
  { key: 3, value: T.THIRD },
  { key: 4, value: T.FOURTH },
  { key: -1, value: T.LAST },
]

export const DAY_OF_MONTH_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  'Last',
]

export const QUARTERLY_SCHEDULE_OPTIONS = [
  { key: '1', value: 'Jan • Apr • Jul • Oct' },
  { key: '2', value: 'Feb • May • Aug • Nov' },
  { key: '3', value: 'Mar • Jun • Sep • Dec' },
]

export const TWO_QUARTER_SCHEDULE_OPTIONS = [
  { key: '1', value: 'Jan • Jul' },
  { key: '2', value: 'Feb • Aug' },
  { key: '3', value: 'Mar • Sep' },
  { key: '4', value: 'Apr • Oct' },
  { key: '5', value: 'May • Nov' },
  { key: '6', value: 'Jun • Dec' },
]

export const DAY_SHORT_FORM = {
  Sunday: 'U',
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'H',
  Friday: 'F',
  Saturday: 'S',
}

export const TWO_MONTH_SCHEDULE_OPTIONS = [
  { key: 'BIMONTHLY_ODD', value: 'Odd months - Jan • Feb' },
  { key: 'BIMONTHLY_EVEN', value: 'Even months - Feb • Mar' },
]

export const FOUR_MONTH_SCHEDULE_OPTIONS = [
  { key: 'FOURMONTHS_JAN_MAY_SEP', value: 'Jan • May • Sep' },
  { key: 'FOURMONTHS_FEB_JUN_OCT', value: 'Feb • Jun • Oct' },
  { key: 'FOURMONTHS_MAR_JUL_NOV', value: 'Mar • Jul • Nov' },
  { key: 'FOURMONTHS_APR_AUG_DEC', value: 'Apr • Aug • Dec' },
]

export const ANNUAL_SCHEDULE_OPTIONS = [
  { key: 'ANNUAL_JAN', value: 'January' },
  { key: 'ANNUAL_FEB', value: 'February' },
  { key: 'ANNUAL_MAR', value: 'March' },
  { key: 'ANNUAL_APR', value: 'April' },
  { key: 'ANNUAL_MAY', value: 'May' },
  { key: 'ANNUAL_JUN', value: 'June' },
  { key: 'ANNUAL_JUL', value: 'July' },
  { key: 'ANNUAL_AUG', value: 'August' },
  { key: 'ANNUAL_SEP', value: 'September' },
  { key: 'ANNUAL_OCT', value: 'October' },
  { key: 'ANNUAL_NOV', value: 'November' },
  { key: 'ANNUAL_DEC', value: 'December' },
]
