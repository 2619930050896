import api from 'api'

export const extendApiSlice = api.injectEndpoints({
  endpoints: build => ({
    putUpdateTags: build.mutation({
      query: body => ({
        url: '/api/v1/core/tags',
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { usePutUpdateTagsMutation } = extendApiSlice
