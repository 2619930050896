import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Box, styled } from '@mui/material'
import { useLazyGetRouteStopsGeojsonQuery, useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { get } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import Loader from 'components/common/loader'
import {
  resetBreadcrumbsByRouteId,
  resetUnsubscribeByRouteId,
  selectRouteSerializedServiceDate,
  setBreadcrumbsByRouteId,
  setIsBreadcrumbInitialized,
  setSelectedSearchResult,
  setUnsubscribeByRouteId,
  setViewOnMapRouteId,
} from 'slices/route/routeSlice'
import { onSnapshot, doc } from 'firebase/firestore'
import { removeLayerIfExist, removeSourceIfExist } from 'utils/map'
import { db } from 'providers/WithFirebase'
import { getSelectedRouteIds } from 'data/route/selectedRoutesSelector'
import { getIsUnassignedStopsOpen } from 'data/route/unassignedStopsSelector'
import { MapContext } from 'providers/MapProvider'
import NoRoutesEmptyState from '../NoRoutesEmptyState'
import RouteManagerMapToolbar from './RouteManagerMapToolbar'
import { useRouteManagerMap } from './useRouteManagerMap'

const MapContainer = styled('div')(({ zIndex }) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'fixed',
  zIndex,
}))

const RouteManagerMap = () => {
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const [getRouteStopsGeojson, { data: mapData, isFetching: isFetchingMapData, isLoading: isLoadingMapData }] =
    useLazyGetRouteStopsGeojsonQuery()
  const isUnassignedStopsActive = useSelector(getIsUnassignedStopsOpen)
  const isMapLoading = useMemo(
    () => isFetching || isLoading || isFetchingMapData || isLoadingMapData,
    [isFetching, isLoading, isFetchingMapData, isLoadingMapData]
  )
  const map = useContext(MapContext)
  const mapContainer = useRef(null)
  const viewOnMapRouteId = useSelector(s => get(s, 'Route.viewOnMap.routeId', null), shallowEqual)
  const unsubscribeByRouteId = useSelector(s => get(s, 'Route.unsubscribeByRouteId', {}), shallowEqual)
  const allRouteIds = useSelector(s => get(s, 'Route.allRouteIds', []), shallowEqual)
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)
  const selectedRouteIdList = useMemo(
    () => (viewOnMapRouteId ? [viewOnMapRouteId] : selectedRouteIds),
    [viewOnMapRouteId, selectedRouteIds]
  )
  const hasRoutes = useMemo(() => Array.isArray(selectedRouteIds) && selectedRouteIds.length !== 0, [selectedRouteIds])
  const isDriverPathActive = useSelector(state => state.Route.isDriverPathActive)

  useRouteManagerMap({ map, mapContainer, mapData })
  const isMapVisible = useMemo(() => hasRoutes || isUnassignedStopsActive, [hasRoutes, isUnassignedStopsActive])

  const unsubscribeAllBreadcrumbListeners = useCallback(() => {
    const mapCurrentRef = map.current
    allRouteIds.forEach(routeId => {
      if (routeId in unsubscribeByRouteId) {
        const unsubscribe = unsubscribeByRouteId[routeId]
        unsubscribe()
      }
      removeLayerIfExist(mapCurrentRef, `route-${routeId}`)
      removeLayerIfExist(mapCurrentRef, `route-${routeId}-arrows`)
      removeSourceIfExist(mapCurrentRef, `route-${routeId}`)
      dispatch(resetBreadcrumbsByRouteId({}))
      dispatch(resetUnsubscribeByRouteId({}))
    })
  }, [map, allRouteIds, unsubscribeByRouteId])

  const updateBreadcrumbsFromGeojsonResponse = useCallback(
    data => {
      const mapCurrentRef = map.current
      removeLayerIfExist(mapCurrentRef, 'last-driver-positions')
      removeSourceIfExist(mapCurrentRef, 'last-driver-positions')
      unsubscribeAllBreadcrumbListeners()
      dispatch(setIsBreadcrumbInitialized(false))
      const routeGeojsonData = get(data, 'routeGeojsonData', {})
      if (isDriverPathActive) {
        Object.keys(routeGeojsonData).forEach(routeId => {
          const data = get(routeGeojsonData, routeId, {})
          const routeDailyGeoJsonId = get(data, 'routeDailyGeoJsonId', null)
          if (routeDailyGeoJsonId) {
            const unsub = onSnapshot(doc(db, `crm_web/${businessId}/route_daily_geo_jsons/${routeDailyGeoJsonId}`), doc => {
              const docData = doc.data()
              const routeId = get(docData, 'routeId')
              const driverPathGeoJson = get(docData, 'driverPathGeoJson')
              const driverPath = JSON.parse(driverPathGeoJson)
              const coordinates = get(driverPath, 'geometry.coordinates', [])
              dispatch(setBreadcrumbsByRouteId({ [routeId]: coordinates }))
            })
            dispatch(setUnsubscribeByRouteId({ [routeId]: unsub }))
          }
        })
      }
    },
    [map, allRouteIds, isDriverPathActive]
  )

  useEffect(() => {
    if (serviceDate && selectedRouteIdList && selectedRouteIdList.length !== 0) {
      getRouteStops({ routes: selectedRouteIdList, serviceDate: formatDateToBEFormatDateFns(serviceDate) })
    }
  }, [])

  useEffect(() => {
    if (serviceDate && ((selectedRouteIdList && selectedRouteIdList.length !== 0) || isUnassignedStopsActive)) {
      getRouteStopsGeojson({
        routes: selectedRouteIdList,
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        includeUnassigned: isUnassignedStopsActive,
      })
        .unwrap()
        .then(updateBreadcrumbsFromGeojsonResponse)
    }
  }, [])

  useEffect(() => {
    if (serviceDate && selectedRouteIdList && selectedRouteIdList.length !== 0) {
      getRouteStops({ routes: selectedRouteIdList, serviceDate: formatDateToBEFormatDateFns(serviceDate) })
    }
  }, [serializedServiceDate, selectedRouteIds, viewOnMapRouteId, isUnassignedStopsActive])

  useEffect(() => {
    if (serviceDate && ((selectedRouteIdList && selectedRouteIdList.length !== 0) || isUnassignedStopsActive)) {
      getRouteStopsGeojson({
        routes: selectedRouteIdList,
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        includeUnassigned: isUnassignedStopsActive,
      })
        .unwrap()
        .then(updateBreadcrumbsFromGeojsonResponse)
    }
  }, [serializedServiceDate, selectedRouteIds, viewOnMapRouteId, isUnassignedStopsActive, isDriverPathActive])

  useEffect(() => {
    return () => {
      dispatch(setViewOnMapRouteId(''))
      dispatch(setSelectedSearchResult(null))
      unsubscribeAllBreadcrumbListeners()
    }
  }, [])

  return (
    <>
      <Box position="relative" height="100%" width="100%">
        {!isMapVisible && <NoRoutesEmptyState />}
        {isMapLoading && <Loader />}
        <MapContainer ref={mapContainer} />
        {isMapVisible && <RouteManagerMapToolbar map={map} />}
      </Box>
    </>
  )
}

export default RouteManagerMap
