import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRentalFeesByPricedService: build.query({
      query: ({ id }) => ({
        url: `/api/v1/core/priced-service/${id}/fee/rental`,
        method: 'GET',
      }),
    }),
    linkRentalFeesToPricedService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/priced-service/${id}/fee/rental/link`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
    unlinkRentalFeesFromPricedService: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/api/v1/core/priced-service/${id}/fee/rental/unlink`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
  }),
})

export const {
  useLazyGetRentalFeesByPricedServiceQuery,
  useLinkRentalFeesToPricedServiceMutation,
  useUnlinkRentalFeesFromPricedServiceMutation,
} = extendedApi
