import { createDrawerRouterSlice, getInitialDrawerRouterState } from 'slices/generic/createDrawerRouterSlice'
import { get } from 'lodash'
import routes from 'router/routes'

const pricingDrawerSlice = createDrawerRouterSlice({
  name: 'pricingDrawer',
  initialState: {
    ...getInitialDrawerRouterState({ favoriteUrl: routes.app.pricingServices }),
    resetSelectServiceGroup: false,
  },
  reducers: {
    setResetSelectServiceGroup: (state, action) => {
      state.resetSelectServiceGroup = action.payload
    },
  },
})

export const {
  openDrawer: openPricingDrawer,
  closeDrawer: closePricingDrawer,
  setIsOpenDrawer: setIsOpenPricingDrawer,
  setFavoriteUrl: setFavoritePricingUrl,
  setResetSelectServiceGroup,
} = pricingDrawerSlice.actions

export const selectIsOpenPricingDrawer = s => get(s, 'pricingDrawer.isDrawerOpen', true)
export const selectPricingFavoriteUrl = s => get(s, 'pricingDrawer.favoriteUrl', routes.app.pricingServices)
export const selectResetSelectServiceGroup = s => get(s, 'pricingDrawer.resetSelectServiceGroup', false)

export default pricingDrawerSlice.reducer
