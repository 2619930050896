import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { Button, DialogContent, List, ListItem, ListItemText, IconButton, Divider, Menu, MenuItem } from '@mui/material'
import MoreVert from '@mui/icons-material/MoreVert'

import { handleError } from 'utils/error'
import { getNoteUserInfo } from 'data/notes/notesSelectors'
import { usePatchCSNoteMutation } from 'api/notes/configuredServiceNotesCrud'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import { getUnarchiveDescriptionAndTitle } from './utils'

const ViewArchiveNotesDialog = ({ id, isOpen = false, notes = [], onClose }) => {
  const [unarchiveCSNote, { isLoading: isUnarchiveCSNoteLoading }] = usePatchCSNoteMutation()
  const [unarchiveDialog, setUnarchiveDialog] = useState({ isOpen: false, props: {} })
  const [actionMenu, setActionMenu] = useState({ anchorEl: null, noteId: '' })

  const { description = '', title = '' } = getUnarchiveDescriptionAndTitle(
    unarchiveDialog.props.updateServiceNote,
    unarchiveDialog.props.updateInherited
  )

  const handleCloseActionMenu = () => setActionMenu({ anchorEl: null, noteId: '' })

  const handleOpenActionMenu = (event, note) => setActionMenu({ anchorEl: event.currentTarget, noteId: note.id, props: {} })

  const handleOpenUnarchiveDialog = props => {
    setActionMenu(prev => ({ ...prev, anchorEl: null }))
    onClose()
    setUnarchiveDialog({ isOpen: true, props })
  }

  const handleCloseUnarchiveDialog = () => setUnarchiveDialog({ isOpen: false, props: {} })

  const handleConfirmUnarchived = () => {
    unarchiveCSNote({ id, noteId: actionMenu.noteId, data: { archived: false, ...unarchiveDialog.props } })
      .unwrap()
      .then(() => {
        handleCloseActionMenu()
        handleCloseUnarchiveDialog()
        toast.success('Note unarchived successfully')
      })
      .catch(handleError)
  }

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={T.ARCHIVED_CONFIGURED_SERVICE_NOTES} onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ py: 0 }}>
            {Children.toArray(
              notes.map((note, index) => {
                const { note: noteText } = note
                const { firstName, lastName, dateTime, username } = getNoteUserInfo({ note })
                const fullName = `${firstName} ${lastName}`.trim()
                const displayName = fullName || username || '--'
                const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime })

                return (
                  <>
                    <ListItem
                      sx={{ pl: 3 }}
                      secondaryAction={
                        <IconButton onClick={event => handleOpenActionMenu(event, note)}>
                          <MoreVert />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primaryTypographyProps={{ variant: 'h6', fontWeight: 400, noWrap: true }}
                        primary={noteText}
                        secondaryTypographyProps={{ variant: 'subtitle2', fontWeight: 400 }}
                        secondary={`Created by ${displayName} | ${onlyDate} @ ${onlyTime}`}
                      />
                    </ListItem>
                    {notes.length - 1 !== index && <Divider />}
                  </>
                )
              })
            )}
          </List>
        </DialogContent>
        <HHDialogActions>
          <Button size="small" variant="contained" color="primary" onClick={onClose}>
            {T.CLOSE}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined' } }}
        anchorEl={actionMenu.anchorEl}
        open={Boolean(actionMenu.anchorEl)}
        onClose={handleCloseActionMenu}
        disableScrollLock
      >
        <MenuItem onClick={() => handleOpenUnarchiveDialog({ updateServiceNote: true, updateInherited: false })}>
          {T.UNARCHIVE_SERVICE_ONLY}
        </MenuItem>
        <MenuItem onClick={() => handleOpenUnarchiveDialog({ updateServiceNote: false, updateInherited: true })}>
          {T.UNARCHIVE_WORK_ORDERS_ONLY}
        </MenuItem>
        <MenuItem onClick={() => handleOpenUnarchiveDialog({ updateServiceNote: true, updateInherited: true })}>
          {T.UNARCHIVE_BOTH}
        </MenuItem>
      </Menu>
      <HHConfirmDialog
        isOpen={unarchiveDialog.isOpen}
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        confirmTitle={title}
        confirmDescription={description}
        confirmButtonTitle={T.UNARCHIVE}
        ConfirmButtonProps={{ loading: isUnarchiveCSNoteLoading, color: 'primary' }}
        onConfirm={handleConfirmUnarchived}
        onClose={handleCloseUnarchiveDialog}
      />
    </>
  )
}

ViewArchiveNotesDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  notes: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default ViewArchiveNotesDialog
