import { takeEvery, all, call } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions } from '../actions/followUp'

import endpoint from './endpoint'

export const getFollowUpById = ({ body }) => doPost({ url: endpoint.account.followUpById, body, showLoader: true })
export const addFollowUpAdditionalNotes = ({ body }) =>
  doPost({ url: endpoint.account.addFollowUpNote, body, showLoader: true, contentType: 'multipart/form-data' })

export function* getFollowUpByIdSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getFollowUpById, { body })
    const { followUp } = res
    if (typeof onSuccess === 'function') {
      onSuccess(followUp)
    }
  } catch (err) {
    onSuccess(null)
  }
}

export function* addFollowUpAdditionalNotesSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(addFollowUpAdditionalNotes, { body })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess(null)
  }
}

export default function* FollowUp() {
  yield all([
    takeEvery(actions.GET.FOLLOW_UP_BY_ID, getFollowUpByIdSaga),
    takeEvery(actions.ADD_CUSTOMER_FOLLOW_UP_NOTE, addFollowUpAdditionalNotesSaga),
  ])
}
