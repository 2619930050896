import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    addUpdateCallFollowUp: build.mutation({
      query: body => ({ url: '/api/account/call/add', method: 'POST', body }),
    }),
  }),
})

export const { useAddUpdateCallFollowUpMutation } = extendedApi
