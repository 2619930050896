import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import { TEXT, SUCCESS } from 'theme/colors'
import WithSwitchLabel from './WithSwitchLabel'

const HHSwitchField = ({ label, offColor = TEXT.secondary, onColor = SUCCESS.main, ...rest }) => {
  return (
    <WithSwitchLabel label={label}>
      <Switch offColor={offColor} onColor={onColor} width={32} height={16} handleDiameter={10} {...rest} />
    </WithSwitchLabel>
  )
}

HHSwitchField.propTypes = {
  label: PropTypes.string,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
}

export default HHSwitchField
