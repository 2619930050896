import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBusinessInfo: build.query({
      query: () => ({
        url: '/api/v1/core/business',
      }),
    }),
  }),
})
export const { useLazyGetBusinessInfoQuery } = extendedApi

export const getBusinessInfoSlice = extendedApi.endpoints.getBusinessInfo.select()
