import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

const ReadMoreTypography = ({ text, title, TypographyProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const handleClick = e => {
    e.stopPropagation()
    setIsOpen(true)
  }

  const handleClose = e => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return (
    <div>
      <Typography variant="body1" onClick={handleClick} {...TypographyProps} noWrap style={{ cursor: 'pointer', textOverflow: 'ellipsis' }}>
        {text}
      </Typography>
      <Dialog sx={{ zIndex: theme.zIndex.tooltip }} maxWidth="md" open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>{text}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

ReadMoreTypography.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  TypographyProps: PropTypes.object,
  characterLimit: PropTypes.number,
}

export default ReadMoreTypography
