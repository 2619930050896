import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

import { getBalanceChipColor } from 'components/customers/accounts/settings'
import AccountBalanceChip from 'components/common/chip/AccountBalanceChip'

const RenderAccountNameAndBalance = ({ row = {} }) => {
  const { accountName, balanceCents = 0 } = row

  const balanceChipColor = useMemo(() => getBalanceChipColor(row), [row])

  return (
    <ListItem disablePadding>
      <ListItemText primary={accountName} primaryTypographyProps={{ variant: 'h6', fontWeight: 500, color: 'textPrimary' }} />
      <ListItemIcon>
        <AccountBalanceChip balance={balanceCents} color={balanceChipColor} />
      </ListItemIcon>
    </ListItem>
  )
}

RenderAccountNameAndBalance.propTypes = {
  row: PropTypes.object,
}

export default RenderAccountNameAndBalance
