import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'

import { get } from 'utils/lodash'
import { HHFormTextField } from 'components/form-fields/v5'
import { useGetRentalFeesQuery } from 'api/pricing/rentalFeesCrud'
import { getRentalFees } from 'data/pricing/rentalFeeSelectors'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import RenderNoneOption from './RenderNoneOption'
import RentalFeeListItem from './RentalFeeListItem'

const RentalFeeFormField = ({ name, value, excludeList = [], hideArchived = false, onChange }) => {
  const { control } = useFormContext()
  const { data: rentalFeesData } = useGetRentalFeesQuery()
  const rentalFees = getRentalFees({ rentalFees: get(rentalFeesData, 'rentalFees', []), hideArchived })

  // Exclude previously selected rental fee
  const listOptions = useMemo(
    () =>
      rentalFees.filter(fee => {
        if (fee.id === value) {
          return true
        }

        return !excludeList.includes(fee.id)
      }),
    [rentalFees, value, excludeList]
  )

  const handleChange = newRentalFeeId => {
    if (newRentalFeeId) {
      const selectedRentalFee = rentalFees.find(fee => fee.id === newRentalFeeId)
      onChange(selectedRentalFee)
    }
  }

  return (
    <HHFormTextField
      fullWidth
      select
      control={control}
      name={name}
      value={[value]}
      label={T.RENTAL_FEES}
      placeholder={`Select ${T.RENTAL_FEES}`}
      deprecatedLabel={false}
      onChange={handleChange}
    >
      <SelectItem value="">
        <RenderNoneOption />
      </SelectItem>
      {listOptions.map(fee => (
        <SelectItem value={fee.id}>
          <RentalFeeListItem rentalFee={fee} />
        </SelectItem>
      ))}
    </HHFormTextField>
  )
}

RentalFeeFormField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  excludeList: PropTypes.array,
  hideArchived: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RentalFeeFormField
