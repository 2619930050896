import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Box, MenuItem } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import HHTextField from 'components/form-fields/v5/HHTextField'

import T from 'T'
import {
  getPricingPeriodIntervalTypeDropdownOptions,
  getRecurrenceIntervalTypeDropdownOptions,
  getIntervalDropdownOptions,
} from 'utils/interval'
import { RECURRENCE_INTERVALS } from 'settings/constants/billing'

const ServiceTool = ({
  id = '',
  index = 0,
  type = '',
  selectedNum = '',
  selectedVal = '',
  onHandleChange = noop,
  handleRemoval = noop,
}) => {
  let intervalType = selectedVal || ''
  const isPricingPeriodType = type === 'pricingPeriod'
  const allIntervalTypes = isPricingPeriodType
    ? getPricingPeriodIntervalTypeDropdownOptions(selectedNum, id)
    : getRecurrenceIntervalTypeDropdownOptions(selectedNum, id)
  const allIntervals = isPricingPeriodType ? getIntervalDropdownOptions(selectedVal, id) : RECURRENCE_INTERVALS

  if (selectedNum > 1 && selectedVal && selectedVal !== 'Instance') {
    intervalType = `${selectedVal}s`
  }

  const handleServiceRemoval = () => {
    handleRemoval(index, type)
  }

  const handleNumberChange = event => {
    onHandleChange(event.target.value, index, type, 'num')
  }

  const handleIntervalChange = event => {
    onHandleChange(event.target.value, index, type, 'interval')
  }

  return (
    <Box display="flex" sx={{ pointerEvents: id === '' ? '' : 'none', mt: 1 }}>
      <HHTextField select value={[selectedNum]} onChange={handleNumberChange} sx={{ minWidth: 64 }}>
        {allIntervals.map(data => (
          <MenuItem value={data}>{data}</MenuItem>
        ))}
      </HHTextField>

      <Box display="flex" alignItems="center" ml={1}>
        <HHTextField select value={[intervalType]} onChange={handleIntervalChange} sx={{ m: '0 5px', minWidth: 240 }}>
          <MenuItem value="">{isPricingPeriodType ? `${T.SELECT} ${T.INTERVAL}` : `${T.SELECT} ${T.INTERVAL} ${T.TYPE}`}</MenuItem>
          {allIntervalTypes.map(data => (
            <MenuItem value={data}>{data}</MenuItem>
          ))}
        </HHTextField>

        {id === '' && (
          <CancelOutlinedIcon
            fontSize="small"
            onClick={handleServiceRemoval}
            sx={{ color: 'text.secondary', m: 'auto 14px', cursor: 'pointer' }}
          />
        )}
      </Box>
    </Box>
  )
}

ServiceTool.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  selectedNum: PropTypes.string,
  selectedVal: PropTypes.string,
  id: PropTypes.string,
  handleRemoval: PropTypes.func,
  onHandleChange: PropTypes.func,
}

export default ServiceTool
