import { PAYMENT_METHODS } from 'settings/constants/payment'
import { encodeUrlParams } from 'utils/url'

const { ACH } = PAYMENT_METHODS

const css =
  '.error%20%7B%0A%20%20%20%20color%3A%20red%3B%0A%7D%0A%0Ainput%20%7B%0A%20%20%20%20font-family%3A%20%27Arial%27%2C%20sans-serif%3B%0A%20%20%20%20border%3A%201px%20solid%20%23DFE1E6%3B%0A%20%20%20%20box-sizing%3A%20border-box%3B%0A%20%20%20%20filter%3A%20drop-shadow(0px%201px%201px%20rgba(0%2C%200%2C%200%2C%200.08))%3B%0A%20%20%20%20border-radius%3A%204px%3B%0A%20%20%20%20padding%3A%200px%2012px%3B%0A%20%20%20%20margin%3A%202px%200px%2010px%200px%3B%0A%20%20%20%20color%3A%20%23042040%3B%0A%20%20%20%20height%3A%2040px%3B%0A%7D%0A%0A%23ccnumfield%20%7B%0A%20%20%20%20width%3A%20100%25%3B%0A%7D%0A%0A%23ccexpiryfieldmonth%2C%20%23ccexpiryfieldyear%2C%20%23cccvvfield%20%7B%0A%20%20%20%20width%3A%2080px%3B%0A%7D%0A%0Alabel%20%7B%0A%20%20%20%20font-family%3A%20%27Arial%27%2C%20sans-serif%3B%0A%20%20%20%20font-style%3A%20normal%3B%0A%20%20%20%20font-weight%3A%20500%3B%0A%20%20%20%20font-size%3A%2012px%3B%0A%20%20%20%20line-height%3A%2024px%3B%0A%20%20%20%20color%3A%20%236B778C%3B%0A%7D'

export const PG_CONNECTOR_TYPE = 'CDCN'

const CREDIT_CARD_CONFIG = {
  useexpiry: true,
  useexpiryfield: true,
  placeholdermonth: 'MM',
  placeholderyear: 'YYYY',
  usecvv: true,
  placeholdercvv: '123',
  usemonthnames: true,
  formatinput: true,
  placeholder: 'XXXX - XXXX - XXXX - XXXX',
  cardlabel: 'Card Number*',
  expirylabel: 'Expiration Date*',
  cvvlabel: 'CVV*',
  cardtitle: 'Card Number',
}

const ACH_CONFIG = {
  useexpiry: false,
  useexpiryfield: false,
  usecvv: false,
  usemonthnames: false,
  formatinput: true,
  cardlabel: 'Routing Number / Account Number*',
  placeholder: '123456789 / 1234123412341234',
  fullmobilekeyboard: true,
  cardtitle: 'ACH Payment Details',
}

// CVV not required for crm core
export const getPaymentFormParams = (paymentMethod, cvvRequired = false) => {
  const params = paymentMethod === ACH ? ACH_CONFIG : { ...CREDIT_CARD_CONFIG, usecvv: cvvRequired }
  return `?${encodeUrlParams(params)}&css=${css}`
}
