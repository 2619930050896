import { BUCKET_NAME_TO_GROUP_ID_PARAM } from 'settings/constants/accountReceivables'
import { useMemo } from 'react'
import { getIsSuspendedBucket } from 'components/customers/groups/account/common/settings'
import {
  useBulkResendUnpaidInvoicesForAccountGroupMutation,
  useBulkResendUnpaidInvoicesForAgingGroupMutation,
  useBulkResendUnpaidInvoicesForSuspensionGroupMutation,
} from 'api/groups/account/bulkResendUnpaidInvoices'

export const useBulkResendUnpaidInvoices = accountGroupId => {
  const isDefaultBucket = useMemo(() => Object.values(BUCKET_NAME_TO_GROUP_ID_PARAM).includes(accountGroupId), [accountGroupId])

  return useMemo(() => {
    const isSuspendedGroup = getIsSuspendedBucket(accountGroupId)
    if (isSuspendedGroup) return useBulkResendUnpaidInvoicesForSuspensionGroupMutation
    if (isDefaultBucket) return useBulkResendUnpaidInvoicesForAgingGroupMutation
    return useBulkResendUnpaidInvoicesForAccountGroupMutation
  }, [accountGroupId, isDefaultBucket])
}
