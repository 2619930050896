import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@mui/material'
import { DeviceMobile } from '@styled-icons/heroicons-outline/DeviceMobile'
import { Phone } from '@styled-icons/heroicons-outline/Phone'
import { Mail } from '@styled-icons/heroicons-outline/Mail'
import { Printer } from '@styled-icons/heroicons-outline/Printer'
import { CONTACT_METHODS } from 'settings/constants/contacts'

const { EMAIL, PHONE, FAX } = CONTACT_METHODS
const ICON_SIZE = { width: 16, height: 16 }

const ContactMethodIcon = ({ type, sx = {} }) => {
  const theme = useTheme()
  const iconColor = theme.palette.text.secondary
  const props = { ...ICON_SIZE, color: iconColor, ...sx }

  if (type === PHONE) return <Phone {...props} />
  if (type === EMAIL) return <Mail {...props} />
  if (type === FAX) return <Printer {...props} />

  // MOBILE
  return <DeviceMobile {...props} />
}

ContactMethodIcon.propTypes = {
  type: PropTypes.string,
  sx: PropTypes.object,
}

export default ContactMethodIcon
