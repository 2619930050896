import React from 'react'
import PropTypes from 'prop-types'

import { ChevronUp } from '@styled-icons/heroicons-outline/ChevronUp'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { memo } from 'utils/react'
import { HHTextField } from 'components/form-fields/v5'

const MinimumBillingNumberTool = ({ counter, onHandleChange, setCount }) => {
  return (
    <div className="number-tool">
      <HHTextField
        id="outlined-size-small"
        placeholder=""
        value={counter}
        variant="outlined"
        autoComplete="off"
        name="billingPeriods"
        defaultSize
        className="normal-textfield num"
        InputProps={{
          onChange: e => onHandleChange(e),
        }}
      />

      <div className="tool-section">
        <div className="upper">
          <ChevronUp className="chevron-up" onClick={() => setCount('sum')} />
        </div>
        <div className="lower">
          <ChevronDown className="chevron-down" onClick={() => setCount('sub')} />
        </div>
      </div>
    </div>
  )
}

MinimumBillingNumberTool.defaultProps = {
  counter: 1,
  setCount: null,
  onHandleChange: null,
}

MinimumBillingNumberTool.propTypes = {
  counter: PropTypes.number,
  setCount: PropTypes.func,
  onHandleChange: PropTypes.func,
}

export default memo(MinimumBillingNumberTool)
