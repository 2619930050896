import T from 'T'
import { get } from 'utils/lodash'
import { formatCentsToDollars, formatDollarsToCents } from 'utils/price'
import { RENTAL_FEE_GRACE_PERIOD, NONE_BUSINESS_LINE_OPTION } from 'components/pricing/RentalFees/common/settings'

const { CUSTOM: CUSTOM_GRACE_PERIOD, NONE: NONE_GRACE_PERIOD } = RENTAL_FEE_GRACE_PERIOD

export const getFormattedGracePeriod = (gracePeriodDays = NONE_GRACE_PERIOD) => {
  if (gracePeriodDays === NONE_GRACE_PERIOD) {
    return T.NONE
  }

  return `${gracePeriodDays} day${gracePeriodDays === 1 ? '' : 's'}`
}

export const formatDataToPayloadCreateUpdateRentalFee = data => {
  const { feeName, businessLine, amount, gracePeriodDays, customGracePeriodDays, pricingPeriod } = data
  return {
    feeName,
    businessLineId: get(businessLine, 'value', ''),
    amountCents: formatDollarsToCents(amount),
    gracePeriodDays: gracePeriodDays === CUSTOM_GRACE_PERIOD ? customGracePeriodDays : gracePeriodDays,
    pricingPeriod,
  }
}

export const transformServerDataToCreateUpdateRentalFeeForm = rentalFee => {
  const {
    feeName,
    businessLine: businessLineLabel = NONE_BUSINESS_LINE_OPTION.label,
    businessLineId = '',
    amountCents,
    gracePeriodDays,
    pricingPeriod,
  } = rentalFee
  const isCustomGracePeriod = !Object.values(RENTAL_FEE_GRACE_PERIOD).includes(gracePeriodDays)
  return {
    feeName,
    businessLine: { label: businessLineLabel, value: businessLineId },
    amount: formatCentsToDollars(amountCents),
    gracePeriodDays: isCustomGracePeriod ? CUSTOM_GRACE_PERIOD : gracePeriodDays,
    customGracePeriodDays: isCustomGracePeriod ? gracePeriodDays : '',
    pricingPeriod,
  }
}
