import React, { forwardRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, styled, Stack, LinearProgress } from '@mui/material'
import { GRID_TREE_DATA_GROUPING_FIELD, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { INVOICE_STATUS } from 'settings/constants/invoice'
import { canChangeWOInvoiceStatusSelector } from 'data/work-order/workOrderSelectors'
import { setWOReviewParentRows, setWOReviewRowSelectionModel } from 'slices/billing/workOrdersReviewSlice'
import { ChevronRight as UnstyledChevronRight } from '@styled-icons/heroicons-solid/ChevronRight'
import { ChevronDown as UnstyledChevronDown } from '@styled-icons/heroicons-solid/ChevronDown'
import RenderWORightSectionHeader from './RenderWORightSectionHeader'
import RouteGroupingCellWithLazyLoading from './RouteGroupingCellWithLazyLoading'
import { useLazyGetWorkOrdersReviewDataQuery } from '../../../../api/work-order/getWorkOrdersReviewData'
import { useFetchWORowsOnExpandGroup } from './useFetchWORowsOnExpandGroup'
import RenderWOReviewRow from './RenderWOReviewRow/RenderWOReviewRow'
import RenderCheckbox from './RenderCheckbox'
import { useGetPayload } from './useGetPayload'
import { useWOReviewContext } from './WOReviewProvider'
import StyledDataGridPro from './StyledDataGrid'
import RenderCheckboxHeader from './RenderCheckboxHeader'
import { useGetRouteMetadataQuery } from '../../../../api/route/getRouteMetadata'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE, INVOICED } = INVOICE_STATUS

const ChevronRight = styled(UnstyledChevronRight)({
  height: 20,
})

const ChevronDown = styled(UnstyledChevronDown)({
  height: 20,
})

const CheckCircle = forwardRef((props, ref) => {
  return (
    <Checkbox
      ref={ref}
      icon={<RadioButtonUncheckedSharpIcon />}
      checkedIcon={<CheckCircleSharpIcon />}
      indeterminateIcon={<RemoveCircleIcon />}
      {...props}
    />
  )
})

const WOReviewDataGrid = ({ apiRef, routesLoading }) => {
  const dispatch = useDispatch()
  const [getWorkOrdersReview, { isError }] = useLazyGetWorkOrdersReviewDataQuery()

  const { data: metaData } = useGetRouteMetadataQuery()
  const allRoutes = get(metaData, 'routes', [])
  const routeIdList = useSelector(state => state.persistentWorkOrdersReview.routeIdList, shallowEqual)
  const parentRows = useSelector(state => state.workOrdersReview.parentRows, shallowEqual)
  const selectionModel = useSelector(state => state.workOrdersReview.selectionModel, shallowEqual)
  const applyFilters = useSelector(state => state.workOrdersReview.filtersDialog.applyFilters, shallowEqual)

  const { updateDataGridForIncomingChanges, fetchAllChildrenRows, isLoading } = useWOReviewContext()
  const renderHeader = (...params) => {
    return isError ? <></> : <RenderWORightSectionHeader {...params} />
  }

  const renderCheckbox = p => <RenderCheckbox {...p} apiRef={apiRef} />
  const renderCell = p => <RenderWOReviewRow {...p} />
  const renderCheckboxHeader = p => <RenderCheckboxHeader {...p} apiRef={apiRef} />
  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 32,
      maxWidth: 32,
      minWidth: 32,
      renderHeader: renderCheckboxHeader,
      renderCell: renderCheckbox,
    },
    {
      flex: 1,
      renderHeader,
      renderCell,
      sortable: false,
      filterable: false,
    },
  ]

  const CUSTOM_GROUPING_COL_DEF = {
    renderHeader: () => <div />,
    width: 46,
    minWidth: 46,
    maxWidth: 46,
    resizable: false,
    renderCell: params => <RouteGroupingCellWithLazyLoading {...params} />,
  }

  const handleSelectionModelChange = useCallback(newSelectionModel => {
    dispatch(setWOReviewRowSelectionModel(newSelectionModel))
  }, [])

  const getRowId = useCallback(row => {
    if (typeof row?.id === 'string' && row?.id.startsWith('placeholder-children-')) {
      return row.id
    }
    return row.id
  }, [])

  const getTreeDataPath = useCallback(row => row.hierarchy, [])

  const getRowClassName = useCallback(params => {
    const { row } = params
    const { isWO, isRoute } = row
    if (isWO) return 'workorder-row'
    if (isRoute) return 'route-row'
    return ''
  }, [])

  const getPayload = useGetPayload()

  const BaseCheckbox = params => {
    return (
      <Stack justifyContent="center" alignItems="center">
        <CheckCircle sx={{ padding: 0.5 }} {...params} rows={parentRows} />
      </Stack>
    )
  }

  useFetchWORowsOnExpandGroup({
    fetchWorkOrders: getWorkOrdersReview,
    getPayload,
    apiRef,
  })

  const isRowSelectable = useCallback(
    params => {
      if (params.row)
        if (params.row?.id.startsWith('placeholder-children-')) {
          return false
        }
      const { row } = params
      const { isRoute, isWO } = row
      if (isRoute) {
        const { NOT_READY_FOR_INVOICE } = row.countByStatus
        const rowNode = apiRef.current.getRowNode(row.id)
        const { children } = rowNode
        return NOT_READY_FOR_INVOICE && children && NOT_READY_FOR_INVOICE !== 0
      }
      if (isWO) {
        const serviceType = get(params.row, 'serviceType')
        const invoiceStatus = get(params.row, 'invoiceStatus')
        return canChangeWOInvoiceStatusSelector({ serviceType, invoiceStatus }) && invoiceStatus !== READY_FOR_INVOICE
      }
    },
    [apiRef]
  )
  const getRowHeight = useCallback(({ model }) => {
    if (model.isWO) {
      return 172
    }
    if (model.id.startsWith('placeholder-children-')) {
      return 1
    }
    return 50
  }, [])

  const fetchRoutesData = async () => {
    if (parentRows.length !== 0) {
      updateDataGridForIncomingChanges()
    }
    const rows = allRoutes
      .filter(({ id }) => routeIdList.includes(id))
      .map(({ id, name, color }) => {
        return {
          hierarchy: [id],
          isRoute: true,
          isWO: false,
          id,
          name,
          color,
          childrenFetched: false,
          childrenExpanded: false,
          childrenNotReadyIds: [],
          descendantCount: 0,
          countByStatus: {
            [NOT_READY_FOR_INVOICE]: 0,
            [READY_FOR_INVOICE]: 0,
            [INVOICED]: 0,
          },
        }
      })
    dispatch(setWOReviewParentRows(rows))
  }

  useEffect(() => {
    fetchRoutesData()
  }, [metaData])

  useEffect(() => {
    fetchRoutesData()
  }, [applyFilters])

  useEffect(() => {
    fetchAllChildrenRows()
  }, [parentRows])

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      checkboxSelection
      columns={columns}
      slots={{
        baseCheckbox: BaseCheckbox,
        treeDataExpandIcon: ChevronRight,
        treeDataCollapseIcon: ChevronDown,
        loadingOverlay: LinearProgress,
      }}
      disableChildrenFiltering
      disableRowSelectionOnClick
      disableColumnReorder
      disableColumnMenu
      getRowClassName={getRowClassName}
      getRowHeight={getRowHeight}
      getRowId={getRowId}
      getTreeDataPath={getTreeDataPath}
      groupingColDef={CUSTOM_GROUPING_COL_DEF}
      hideFooter
      initialState={{
        pinnedColumns: {
          left: [GRID_TREE_DATA_GROUPING_FIELD],
        },
      }}
      isRowSelectable={isRowSelectable}
      loading={routesLoading || isLoading}
      rows={parentRows}
      treeData
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
    />
  )
}

WOReviewDataGrid.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  routesLoading: PropTypes.bool,
}

export default WOReviewDataGrid
