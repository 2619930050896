import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import DetailPanelContent from 'components/customers/accounts/common/expansion-panel'

const ExpansionContent = ({ row = {} }) => {
  return (
    <Box pl={7} py={1.5} borderBottom="1px solid" borderColor="divider">
      <DetailPanelContent row={row} isViewOnly />
    </Box>
  )
}

ExpansionContent.propTypes = {
  row: PropTypes.object,
}

export default ExpansionContent
