import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    moveStops: builder.mutation({
      query: body => ({ url: '/api/v1/core/route/stops', method: 'POST', body }),
    }),
    bulkMoveStops: builder.mutation({
      query: body => ({ url: '/api/v1/core/route/stops/bulk', method: 'POST', body }),
    }),
    bulkChangeDate: builder.mutation({
      query: body => ({ url: '/api/v1/core/route/stops/change-date/bulk', method: 'POST', body }),
    }),
  }),
})

export const { useMoveStopsMutation, useBulkMoveStopsMutation, useBulkChangeDateMutation } = extendedApi
