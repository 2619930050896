import React from 'react'
import PropTypes from 'prop-types'

import { LoadingButton } from '@mui/lab'
import { Tooltip, Button, Stack, SvgIcon } from '@mui/material'

import T from 'T'

import { Reply } from '@styled-icons/heroicons-outline/Reply'

import './style.scss'

const FooterButton = ({
  onProceed,
  onBack = () => {},
  onClose,
  leftButtonTitle = T.CANCEL,
  backButtonTitle = T.BACK,
  rightButtonTitle = T.SAVE,
  disabledProceed = false,
  hasBackButton = false,
  hasProceedButton = true,
  disabledCancel = false,
  disabledBack = false,
  isProceedButtonMUIType = false,
  proceedButtonType = 'button',
  tooltipTitle = '',
  showBackIcon = true,
  isProceedButtonLoading = false,
}) => {
  // hasProceedButton: an option to enable/hide the proceed button
  const buttonProceedClass = disabledProceed ? 'btn-inactive' : 'btn-active'
  // later combine line 31 & 36
  const buttonProceedCursorClass = disabledProceed ? 'cursor-not-allowed' : 'cursor-pointer'

  return (
    <>
      <Button color="textSecondary" variant="text" size="small" sx={{ mr: 2 }} disabled={disabledCancel} onClick={onClose}>
        {leftButtonTitle}
      </Button>

      {(hasBackButton || hasProceedButton) && (
        <Stack alignItems="center" flexDirection="row">
          {hasBackButton && (
            // Replace this with input & InputAdornment
            <Button
              sx={{ mr: 2 }}
              onClick={() => !disabledBack && onBack()}
              color="textSecondary"
              variant="outlined"
              size="small"
              startIcon={
                showBackIcon && (
                  <SvgIcon sx={{ fontSize: 8 }} inheritViewBox>
                    <Reply />
                  </SvgIcon>
                )
              }
            >
              {backButtonTitle}
            </Button>
          )}

          {hasProceedButton &&
            (isProceedButtonMUIType ? (
              <Tooltip placement="left" arrow title={tooltipTitle}>
                <span className={buttonProceedCursorClass}>
                  <LoadingButton
                    loading={isProceedButtonLoading}
                    variant="contained"
                    onClick={onProceed}
                    disabled={disabledProceed}
                    sx={{ height: 32 }}
                  >
                    {rightButtonTitle}
                  </LoadingButton>
                </span>
              </Tooltip>
            ) : (
              <input
                type={proceedButtonType}
                value={rightButtonTitle}
                onClick={onProceed}
                disabled={disabledProceed}
                className={`footer-btns footer-proceed ${buttonProceedClass}`}
              />
            ))}
        </Stack>
      )}
    </>
  )
}

FooterButton.propTypes = {
  disabledProceed: PropTypes.bool,
  disabledCancel: PropTypes.bool,
  disabledBack: PropTypes.bool,
  isProceedButtonMUIType: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  hasProceedButton: PropTypes.bool,
  leftButtonTitle: PropTypes.string,
  backButtonTitle: PropTypes.string,
  rightButtonTitle: PropTypes.string,
  tooltipTitle: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  proceedButtonType: PropTypes.string,
  showBackIcon: PropTypes.bool,
  isProceedButtonLoading: PropTypes.bool,
}

export default FooterButton
