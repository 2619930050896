import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'

const DeleteInvoiceConfirmation = ({ isOpen = false, invoices = [], onDelete, onClose }) => {
  return (
    <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xl">
      <HHDialogTitle title="Delete confirmation" onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          {invoices.length === 1 && (
            <Typography variant="h5" color="textPrimary" fontWeight={400}>
              {`Are you sure you want to delete invoice `}
              <Typography variant="h5" fontWeight={400} color="primary" display="inline">
                {invoices[0]}
              </Typography>
              {`?`}
            </Typography>
          )}

          {invoices.length > 1 && (
            <>
              <Typography variant="h5" color="textPrimary" fontWeight={400}>
                Are you sure you want to delete the following invoices?
              </Typography>

              <Typography variant="h5" color="primary" fontWeight={400}>
                {invoices.join(', ')}
              </Typography>
            </>
          )}

          <Typography variant="h5" color="textPrimary" fontWeight={400}>
            This action cannot be reversed.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <Button size="small" variant="text" color="primary" onClick={onClose}>
          {T.CANCEL}
        </Button>

        <Button size="small" variant="contained" color="error" onClick={onDelete}>
          Yes, delete
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

DeleteInvoiceConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  invoices: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DeleteInvoiceConfirmation
