import { Alert, styled } from '@mui/material'

export const HHAlert = styled(Alert)(({ theme, borderColor = null }) => ({
  ...theme.typography.h6,
  fontWeight: 400,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  ...(borderColor && {
    borderColor,
    borderWidth: 1.5,
    borderStyle: 'solid',
  }),
  whiteSpace: 'initial',
}))
