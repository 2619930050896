import find from 'lodash/find'
import { createSelector } from 'reselect'
import { get } from 'utils/lodash'
import { keyBy, mapValues } from 'lodash'
import { hasIntersection } from 'components/locations/settings'

const getRoutes = state => get(state, 'routes', [])
const getRouteIdFromRoute = state => get(state, 'id', null)
const getRouteName = state => get(state, 'name', '')

export const selectRoutes = createSelector([getRoutes], routes => routes)
export const selectRouteIdFromRoute = createSelector([getRouteIdFromRoute], routeId => routeId)
export const selectRouteName = createSelector([getRouteName], routeName => routeName)

// get route object by its id
export const selectRouteByRouteId = createSelector([selectRoutes, selectRouteIdFromRoute], (routes, routeId) =>
  find(routes, route => route?.id === routeId)
)

export const selectStopsByRouteId = createSelector([getRoutes], routes => mapValues(keyBy(routes, 'id'), 'stopCount'))

export const getTagsByRoutes = (selectedRoutesByTagId = {}) => {
  const routes = {}
  Object.keys(selectedRoutesByTagId).forEach(tagId => {
    selectedRoutesByTagId[tagId].forEach(routeId => {
      if (!routes[routeId]) {
        routes[routeId] = []
      }
      routes[routeId].push(tagId)
    })
  })
  return routes
}

export const getFilteredStopsByTags = ({ routes = [], selectedRoutesByTagId = {}, isUnassignedOnly = false }) => {
  const tagsByRoutes = getTagsByRoutes(selectedRoutesByTagId)
  const newRoutes = routes.map(route => {
    const stops = get(route, 'stops', []).filter(stop => {
      const tagIds = get(stop, 'tagIds', [])
      const selectedTagsIdsSet = new Set(get(tagsByRoutes, route.id, []))
      return hasIntersection(selectedTagsIdsSet, tagIds)
    })
    return { ...route, stops }
  })

  return isUnassignedOnly ? get(newRoutes, '[0].stops') : newRoutes
}
