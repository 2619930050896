import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { noop } from 'lodash'
import useFormatLateFee from 'components/customer-details/content/fees/late_fees/useFormatLateFee'

const AddLateFeeItem = ({ checked = false, indeterminate = false, disabled = false, onChange = noop, lateFee }) => {
  const theme = useTheme()
  const handleChange = () => {
    onChange(!checked)
  }
  const { feeName, pricingPeriod, formattedDuration, formattedStartDate, amountCents, businessLine, accountCount } =
    useFormatLateFee(lateFee)
  return (
    <ListItemButton
      sx={{
        '&.Mui-disabled': {
          opacity: 1,
          backgroundColor: theme.palette.background.hover,
        },
      }}
      disabled={disabled}
      onClick={handleChange}
    >
      <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
        <Checkbox
          sx={{
            ...(indeterminate && {
              '& svg': {
                color: theme.palette.action.active,
              },
            }),
          }}
          disabled={disabled}
          indeterminate={indeterminate}
          checked={checked}
          onChange={handleChange}
        />
      </ListItemIcon>
      <ListItemText primary={feeName} secondary={[pricingPeriod, formattedStartDate, formattedDuration].filter(Boolean).join(' | ')} />
      <Typography variant="h5">
        <HHDisplayMoney value={amountCents} formatToDollars />
      </Typography>
    </ListItemButton>
  )
}

AddLateFeeItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  lateFee: PropTypes.object,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default AddLateFeeItem
