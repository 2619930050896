import { NumericFormat } from 'react-number-format'
import React from 'react'
import { Check } from '@styled-icons/heroicons-outline/Check'
import { get } from 'utils/lodash'
import { getRecurrenceText, getPricingPeriod, isPriceTypeDollar } from 'utils/service'

// extracted from FeePanel to prevent prop drilling

const getValue = (feeType, value) => {
  if (value === '') return ''
  if (isPriceTypeDollar(feeType)) {
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    )
  }
  return (
    <NumericFormat value={value} displayType="text" thousandSeparator suffix="%" decimalSeparator="." decimalScale={2} fixedDecimalScale />
  )
}

const getFeePriceVal = value => {
  if (value !== '') {
    return (
      <NumericFormat
        value={value}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    )
  }
  return ''
}

const getFeeRecord = (service, column, type, complete = false) => {
  const serviceFeeName = get(service, 'feeName', '')
  const subItem = get(service, 'subItem', '')
  const materialType = get(service, 'material.materialType', '')
  const recurrencePer = get(service, 'recurrence.recurrencePer', '')
  const recurrenceInterval = get(service, 'recurrence.recurrenceInterval', '')
  const recurrenceFrequency = get(service, 'recurrenceFrequency', '')
  const businessLine = get(service, 'businessLine', '')
  const feeException = get(service, 'feeException', '')
  const measureVol = get(service, 'measure.volume', '')
  const measureUnit = get(service, 'measure.unit', '')
  const zoneName = get(service, 'pricingZone.zoneName', '')
  const pricingPeriodPer = get(service, 'pricingPeriod.periodPer', '')
  const pricingPeriodInterval = get(service, 'pricingPeriod.periodInterval', '')
  const value = get(service, 'value', '')
  const actionType = get(service, 'serviceAction.actionName', '')
  const methodType = get(service, 'serviceMethod.methodName', '')
  const autoLinking = get(service, 'autoLink', false)
  const lastPriceUpdate = get(service, 'lastPriceUpdate', '')
  const nextPriceUpdate = get(service, 'nextPriceUpdate', '')
  const active = get(service, 'active', false)
  const days = get(service, 'days', '')
  const specificDays = get(service, 'specificDays', [])
  const minFee = get(service, 'minFee', '')
  const overageThreshold = get(service, 'overageThreshold', '')
  const overageFee = get(service, 'overageFee', '')
  const overage = get(service, 'overage', '')
  const costPlus = get(service, 'costPlus', '')
  const activeLocations = get(service, 'activeLocationCount', '')
  const measureType = get(service, 'measureType', '')
  const feeType = get(service, 'valueType', '')
  const minBillingPeriod = get(service, 'minBillingPeriod', '')
  const customerCount = get(service, 'customerCount', '')

  if (column === undefined) {
    return ''
  }

  const records = {}
  records['Fee / Charge'] = serviceFeeName
  records.Fee = serviceFeeName
  records['Disposal Fee'] = serviceFeeName
  records['Sub-Item'] = subItem
  records.Material = materialType
  records.Recurrence = getRecurrenceText(recurrencePer, recurrenceInterval, recurrenceFrequency, true)
  records['Business Line'] = businessLine
  records.Exception = feeException
  records['Minimum Billing Period'] = minBillingPeriod
  records['Customer Count'] = customerCount

  if (measureUnit !== '') records.Measure = `${measureVol} ${measureUnit}`

  records['Pricing Zone'] = zoneName

  records.Period = getPricingPeriod(pricingPeriodPer, pricingPeriodInterval)

  records.Value = getValue(feeType, value)

  records.Action = actionType
  records.Method = methodType
  records['Auto-Link'] = autoLinking ? 'Yes' : 'No'
  records['Active Locations'] = activeLocations

  if (measureType !== '') {
    records.Measure = measureType
  }

  records.Status = active ? 'Active' : 'Deactivated'
  records['Specific Days'] = days || Array.isArray(specificDays) ? specificDays.join(', ') : ''

  records.Overage = overage
  records['Overage Threshold'] = overageThreshold

  if (type === 'create') {
    records.Price = value !== '' ? value : ''
    records['Overage Fee'] = overageFee
    records['Min. Fee'] = minFee
    records['Last Value Update'] = lastPriceUpdate
    records['Next Value Update'] = nextPriceUpdate
  } else {
    records['Min. Fee'] = minFee ? (
      <NumericFormat
        value={minFee}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    ) : (
      ''
    )

    records.Price = getFeePriceVal(value)

    records['Overage Fee'] = overageFee ? (
      <NumericFormat
        value={overageFee}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    ) : (
      ''
    )

    if (nextPriceUpdate !== '')
      records['Next Value Update'] = (
        <NumericFormat
          value={nextPriceUpdate}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
        />
      )

    if (lastPriceUpdate !== '')
      records['Last Value Update'] = (
        <NumericFormat
          value={lastPriceUpdate}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
        />
      )
  }
  records.valueType = records.Price
  records['Cost Plus'] = costPlus

  if (column === 'Sub-Item') return subItem && <Check className="sub-item-check" />

  if (complete === false) {
    return records[column]
  }
  return records
}

export { getFeeRecord }
