import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'

import HHTablePagination from 'components/common/HHTablePagination'
import { PRICING_PAGINATION } from 'settings/constants/pagination'

import { memo } from 'utils/react'
import { useMediaQuery, useTheme } from '@mui/material'

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PRICING_PAGINATION

const PricingPagination = ({ page, onPageChange, rowsPerPage, onRowsPerPageChange, totalCount, totalPageCount }) => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      {totalCount > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider,
          }}
        >
          <Grid item xs container justifyContent="flex-start" pl={2}>
            <HHTablePagination
              sx={{ borderBottom: 'none' }}
              ActionsComponent={params => <Box display="none" {...params} />}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              page={page}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              onPageChange={(_, newPage) => onPageChange(newPage)}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </Grid>
          {isLg && (
            <Grid item xs container justifyContent="flex-end">
              <Pagination color="primary" count={totalPageCount} page={page + 1} onChange={(event, newPage) => onPageChange(newPage - 1)} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

PricingPagination.defaultProps = {
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  totalCount: INITIAL_PAGE,
  totalPageCount: INITIAL_PAGE,
  onPageChange: null,
  onRowsPerPageChange: null,
}

PricingPagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default memo(PricingPagination)
