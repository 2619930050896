import React, { useCallback, useContext } from 'react'
import { Stack, useTheme } from '@mui/material'
import MapToggleButton from 'components/route-manager/RouteManagerMap/MapToggleButton'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import T from 'T'
import MapToggleButtonGroup from 'components/route-manager/RouteManagerMap/MapToggleButtonGroup'
import { BULK_MOVE_MAP_LAYERS, toggleMoveBetweenRoutesLayers } from 'components/route-manager/BulkActions/settings'
import { MapContext } from 'providers/MapProvider'

const { FROM_ROUTE_LAYER, TO_ROUTE_LAYER, BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const CommonToggleRouteLayer = ({
  showToggle,
  mapLayer,
  fromLayer,
  toLayer,
  fromLayerLabel,
  toLayerLabel,
  showAllButton = true,
  bulkReduxAction,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const map = useContext(MapContext)

  const changeLayer = useCallback(
    (event, newCurrentLayer) => {
      const mapCurrentRef = map.current
      if (mapCurrentRef && newCurrentLayer !== null) {
        dispatch(
          bulkReduxAction({
            mapLayer: newCurrentLayer,
          })
        )
        toggleMoveBetweenRoutesLayers(mapCurrentRef, newCurrentLayer, fromLayer, toLayer)
      }
    },
    [map, fromLayer, toLayer]
  )

  return (
    <>
      {showToggle && (
        <Stack sx={{ position: 'absolute', top: 16, right: 16, zIndex: theme.zIndex.appbar }}>
          <MapToggleButtonGroup color="primary" value={mapLayer} exclusive onChange={changeLayer} aria-label="Platform">
            <MapToggleButton value={FROM_ROUTE_LAYER}>{fromLayerLabel}</MapToggleButton>
            {showAllButton && <MapToggleButton value={BOTH_ROUTES_LAYER}>{T.ALL}</MapToggleButton>}
            <MapToggleButton value={TO_ROUTE_LAYER}>{toLayerLabel}</MapToggleButton>
          </MapToggleButtonGroup>
        </Stack>
      )}
    </>
  )
}

CommonToggleRouteLayer.propTypes = {
  showToggle: PropTypes.bool.isRequired,
  mapLayer: PropTypes.string.isRequired,
  fromLayer: PropTypes.string.isRequired,
  toLayer: PropTypes.string.isRequired,
  fromLayerLabel: PropTypes.string.isRequired,
  toLayerLabel: PropTypes.string.isRequired,
  bulkReduxAction: PropTypes.func.isRequired,
}

export default CommonToggleRouteLayer
