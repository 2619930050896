import { get } from 'utils/lodash'
import { createSelector } from 'reselect'
import { getTagsMetadataSlice } from 'api/tags/getTagsMetadata'
import { orderBy, lowerCase, uniqBy } from 'lodash'

const emptyCollection = []
const allTagsMetadataSlice = getTagsMetadataSlice

const getMetadataTags = createSelector(allTagsMetadataSlice, (tagsMetadata = {}) => get(tagsMetadata, 'data.tags', emptyCollection))

export const getActiveTags = createSelector(getMetadataTags, (tags = emptyCollection) => tags.filter(({ active }) => active))

export const getLocationTags = createSelector(getActiveTags, (tags = emptyCollection) => tags.filter(({ forLocation }) => forLocation))

export const getAccountTags = createSelector(getActiveTags, (tags = emptyCollection) => tags.filter(({ forAccount }) => forAccount))

export const getServiceTags = createSelector(getActiveTags, (tags = emptyCollection) => tags.filter(({ forService }) => forService))

export const getTagsByVisibility = createSelector([state => state?.tags], (tags = emptyCollection) => {
  const uniqueTags = uniqBy(tags, 'id')
  const sortedTags = orderBy(uniqueTags, ['active', ({ tagName }) => lowerCase(tagName)], ['desc', 'asc'])

  return sortedTags.map(tag => ({
    ...tag,
    secondaryText: [tag?.forAccount && 'Account', tag?.forLocation && 'Location', tag?.forService && 'Service', tag?.forMobile && 'Mobile']
      .filter(Boolean)
      .join(', '),
  }))
})

export const getTagsWithRoutesAndStopCount = createSelector(
  [state => state?.tags, state => state?.routes, state => state?.routesMeta],
  (tags = emptyCollection, routes = emptyCollection) => {
    const tagsObj = {}
    routes.forEach(route => {
      const { id, color, method = {}, name, stops = [] } = route
      const { methodName, methodType } = method
      stops.forEach(stop => {
        const { tagIds = [] } = stop
        tagIds.forEach(tagId => {
          if (tagsObj[tagId]) {
            tagsObj[tagId].stopCount += 1
          } else {
            tagsObj[tagId] = { allRoutes: {}, stopCount: 1 }
          }

          if (tagsObj[tagId].allRoutes[id]) {
            tagsObj[tagId].allRoutes[id].stopCount += 1
          } else {
            tagsObj[tagId].allRoutes[id] = { id, color, methodName, methodType, name, stopCount: 1 }
          }
        })
      })
    })

    return tags.map(tag => ({
      ...tag,
      allRoutes: Object.values(get(tagsObj, `${tag.id}.allRoutes`, {})),
      stopCount: get(tagsObj, `${tag.id}.stopCount`, 0),
    }))
  }
)
