import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getRouteImportSequence: builder.mutation({
      query({ routeId, ...body }) {
        const formData = new FormData()
        formData.append('serviceDate', body.serviceDate)
        formData.append('updateMasterSequence', body.resequenceRoutePlan)
        formData.append('exportXlsFile', body.exportXlsFile)
        return {
          url: `/api/v1/core/routes/${routeId}/resequence`,
          method: 'POST',
          body: formData,
        }
      },
      transformResponse: data => data,
    }),
  }),
})

export const { useGetRouteImportSequenceMutation } = extendedApi
