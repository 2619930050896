import moment from 'moment'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import { get } from 'lodash'
import {
  FRONTEND_DATE_FORMAT,
  BACKEND_DATE_FORMAT,
  FRONTEND_DATE_FORMAT_DATE_FNS,
  BACKEND_DATE_FORMAT_DATE_FNS,
  FRONTEND_NOTIFICATION_DATE_FORMAT,
  FRONTEND_TIME_FORMAT_DATE_FNS,
  FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS,
  URL_DATE_FORMAT_DATE_FNS,
  MOVE_BETWEEN_ROUTES_DATE_FORMAT_DATE_FNS,
  SHORT_FRONTEND_DATE_FORMAT_DATE_FNS,
} from 'settings/constants/date'
import subDays from 'date-fns/subDays'
import { addWeeks, parse, setHours, setMinutes } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const isNotInvalidDate = date => date instanceof Date && !Number.isNaN(date.getTime())

export const calculateDaysDiff = date => Math.max(0, moment().diff(moment(date), 'days'))

// Format should be YYYY-MM-DD, output date
export const createDateFromString = date => {
  if (!date) {
    return null
  }

  // This gives one day less in negative UTC country, example central america timezone
  // return  new Date(date);

  const dateSplit = date.split('-')
  // Month start from 0, so -1
  return new Date(get(dateSplit, '[0]', undefined), get(dateSplit, '[1]', 1) - 1, get(dateSplit, '[2]', undefined))
}

export const parseURLDateString = dateString => {
  try {
    return parse(dateString, URL_DATE_FORMAT_DATE_FNS, new Date())
  } catch (error) {
    console.error('Error parsing date:', error)
    return null
  }
}

export const calculateDaysDiffFromStringDates = (dateLeft, dateRight) => {
  if (!dateLeft || !dateRight) {
    return false
  }

  return differenceInDays(createDateFromString(dateLeft), createDateFromString(dateRight))
}

// Format date to frontend format
export const formatDateToFEFormatDateFns = date => (date ? format(date, FRONTEND_DATE_FORMAT_DATE_FNS) : date)
export const formatDateToBulkMoveStr = date => (date ? format(date, MOVE_BETWEEN_ROUTES_DATE_FORMAT_DATE_FNS) : date)
export const formatDateToURLFormatDateFns = date => (date ? format(date, URL_DATE_FORMAT_DATE_FNS) : date)
export const formatDateToFEwithWeekday = date => (date ? format(date, FRONTEND_DATE_WITH_WEEKDAY_FORMAT_DATE_FNS) : date)

export const formatDateToFETimeFormatDateFns = date => (date ? format(date, FRONTEND_TIME_FORMAT_DATE_FNS) : date)

// Format date to backend format
export const formatDateToBEFormatDateFns = date => (date ? format(date, BACKEND_DATE_FORMAT_DATE_FNS) : date)

export const formatDateToNotificationStr = date => (isNotInvalidDate(date) ? format(date, FRONTEND_NOTIFICATION_DATE_FORMAT) : date)

// Format date to frontend format
export const formatDateToFEFormat = date => (date ? moment(date).format(FRONTEND_DATE_FORMAT) : date)

// Format date to backend format
export const formatDateToBEFormat = date => (date ? moment(date).format(BACKEND_DATE_FORMAT) : date)

// Later switch to date-fns-tz
export const getLocalDate = date => {
  const dateFormat = 'MM/DD/YYYY hh:mm A'
  const lastUpdatedUTC = moment.utc(date)
  const localDate = lastUpdatedUTC.local()
  return localDate.format(dateFormat)
}

const getMomentDate = date => (moment.isMoment(date) ? date : moment(date))

export const isValidDate = date => {
  if (!date) {
    return false
  }

  return getMomentDate(date).isValid()
}

export const formatDate = (date = new Date(), dateFormat = DEFAULT_DATE_FORMAT) => getMomentDate(date).format(dateFormat)

export const getUnixTimestamp = (date = new Date()) => getMomentDate(date).unix()

export const getDateFromUnixTimestamp = timestamp => moment.unix(timestamp).toDate()

export const normalizeDateToApiDateFormat = date => getMomentDate(date).format(BACKEND_DATE_FORMAT)

export const getCurrentDate = () => moment(new Date()).toDate()

export const getDateObjectFromDateString = (dateString = getCurrentDate()) => getMomentDate(dateString).toDate()

// checks if the Date instance has a valid date
export const serializeDate = date => {
  return date?.toISOString()
}

export const deserializeDate = dateString => {
  return new Date(dateString)
}

const { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths } = require('date-fns')

export const getDateRangeForYesterday = date => {
  const yesterday = subDays(date, 1)
  return [startOfDay(yesterday), endOfDay(yesterday)]
}

export const getDateRangeForThisWeek = date => {
  const startDate = startOfWeek(date)
  const endDate = endOfWeek(date)
  return [startDate, endDate]
}

export const getDateRangeForLastWeek = date => {
  const startDate = startOfWeek(subWeeks(date, 1))
  const endDate = endOfWeek(subWeeks(date, 1))
  return [startDate, endDate]
}

export const getDateRangeForPastTwoWeeks = date => {
  const startDate = startOfWeek(subWeeks(date, 2))
  const endDate = endOfWeek(addWeeks(startDate, 1))
  return [startDate, endDate]
}

export const getDateRangeForThisMonth = date => {
  const startDate = startOfMonth(date)
  const endDate = endOfMonth(date)
  return [startDate, endDate]
}

export const getDateRangeForLastMonth = date => {
  const startDate = startOfMonth(subMonths(date, 1))
  const endDate = endOfMonth(startDate)
  return [startDate, endDate]
}

export const formatDateRangeToShortFeformat = dates =>
  dates
    .map(date => date && createDateFromString(date))
    .filter(date => date && isNotInvalidDate(date))
    .map(date => format(date, SHORT_FRONTEND_DATE_FORMAT_DATE_FNS))
    .join(' - ')

export const convertUtcToTimezone = (dateStr, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  const hasTimezoneOrZ = /([+-]\d{2}:\d{2}|Z)$/.test(dateStr)
  const dateWithTimezone = hasTimezoneOrZ ? dateStr : `${dateStr}Z`
  const utcDate = new Date(dateWithTimezone)
  const zonedDate = utcToZonedTime(utcDate, timeZone)
  return format(zonedDate, 'yyyy-MM-dd HH:mm')
}

export const convertToUtc = (dateStr, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  const zonedDate = new Date(dateStr)
  const utcDate = zonedTimeToUtc(zonedDate, timeZone)
  return format(utcDate, 'yyyy-MM-dd HH:mm')
}

export const convertToUtcKeepTime = (dateStr, timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  const zonedDate = new Date(dateStr)
  const utcDate = zonedTimeToUtc(zonedDate, timeZone)
  const updatedUtcDate = setMinutes(setHours(utcDate, zonedDate.getHours()), zonedDate.getMinutes())
  return format(updatedUtcDate, 'yyyy-MM-dd HH:mm')
}
