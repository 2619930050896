import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Typography } from '@mui/material'
import { debounce, get, noop } from 'lodash'
import { useLazyGetAddressSuggestListQuery } from '../../../api/map/getAddressSuggestList'
// eslint-disable-next-line import/no-cycle
import { HHAutocompleteField } from './index'
import T from '../../../T'
import { getAddressParams } from '../../../utils/helper'

const HHAddressAutocompleteField = ({
  noOptionsText = T.SEARCH_ADDRESS_PLACEHOLDER,
  label = T.STREET_ADDRESS,
  autoComplete = false,
  onChange = noop,
  onInputChange = noop,
  deprecatedLabel = false,
  filterOptions = x => x,
  ...rest
}) => {
  const [getSuggestList, { data }] = useLazyGetAddressSuggestListQuery()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const options = get(data, 'suggestions', [])

  const getOptionLabel = option => {
    if (option?.text) {
      const { line1 } = getAddressParams(option)
      return line1
    }
    return inputValue
  }

  const debouncedGetSuggestList = useMemo(() => {
    return debounce(args => {
      return getSuggestList(args)
        .unwrap()
        .then(() => {})
        .finally(() => {
          setLoading(false)
        })
    }, 300)
  }, [])

  const handleChange = (e, option, reason) => {
    const { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed } = getAddressParams(option)
    const longitude = get(option, 'center.longitude', '')
    const latitude = get(option, 'center.latitude', '')
    const selectedOption = { line1, city, state, zipCode, country, longitude, latitude, longitudeFixed, latitudeFixed }
    setValue(selectedOption)
    onChange(e, selectedOption, reason)
  }

  const handleInputChange = (e, newInputValue, reason) => {
    if (!e && inputValue && !value && reason === 'reset') return
    setLoading(true)
    setInputValue(newInputValue)
    onInputChange(e, newInputValue, reason)
    debouncedGetSuggestList({ search: newInputValue })
  }

  const renderOption = (props, option) => {
    const { line1, city, state, zipCode } = getAddressParams(option)
    const stateAndZipCode = [state, zipCode].filter(Boolean).join(' ')
    const rightAddressStr = [city, stateAndZipCode].filter(Boolean).join(', ')
    return (
      <MenuItem {...props}>
        <Typography fontWeight={600}>{rightAddressStr ? `${line1},` : line1}</Typography>
        &nbsp;
        <Typography color="textSecondary">{rightAddressStr}</Typography>
      </MenuItem>
    )
  }

  useEffect(() => {
    return () => {
      debouncedGetSuggestList.cancel()
    }
  }, [])

  return (
    <HHAutocompleteField
      autoComplete={autoComplete}
      blurOnSelect
      noOptionsText={noOptionsText}
      label={label}
      deprecatedLabel={deprecatedLabel}
      value={value}
      renderOption={renderOption}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      loading={loading}
      {...rest}
    />
  )
}

HHAddressAutocompleteField.propTypes = {
  autoComplete: PropTypes.bool,
  deprecatedLabel: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  filterOptions: PropTypes.func,
  noOptionsText: PropTypes.string,
  label: PropTypes.string,
}

export default HHAddressAutocompleteField
