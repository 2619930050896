import { useState } from 'react'
import { add } from 'date-fns'

const currentDate = new Date()

const mockedNotificationList = [
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { minutes: 3 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { minutes: 4 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { minutes: 5 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { minutes: 6 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { days: 1 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { days: 1, hours: 4 }),
    unread: true,
  },
  {
    title: 'Invoice batch sent ',
    description: 'Invoice #24731703 has been successfully processed',
    date: add(currentDate, { weeks: 2 }),
    unread: true,
  },
  {
    title: 'Autopay successful',
    description: 'Autopay for Closetmaid was successful',
    date: add(currentDate, { months: 1 }),
    unread: true,
  },
  {
    title: 'Autopay successful',
    description: 'Autopay for Closetmaid was successful',
    date: add(currentDate, { months: 1 }),
    unread: true,
  },
  {
    title: 'Autopay successful',
    description: 'Autopay for Closetmaid was successful',
    date: add(currentDate, { months: 1 }),
    unread: true,
  },
  {
    title: 'Autopay successful',
    description: 'Autopay for Closetmaid was successful',
    date: add(currentDate, { months: 1 }),
    unread: true,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { months: 2 }),
    unread: false,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { months: 2 }),
    unread: false,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { months: 2 }),
    unread: false,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { months: 2 }),
    unread: false,
  },
  {
    title: 'Download is ready',
    description: 'Report 24731703 has been successfully processed',
    date: add(currentDate, { months: 2 }),
    unread: false,
  },
].sort((a, b) => a.date - b.date)

export const useLazyGetNotificationListQuery = () => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const getNotificationList = async () => {
    setIsLoading(true)
    await new Promise(resolve => setTimeout(resolve, 500))
    setData({
      notificationList: mockedNotificationList,
    })
    setIsLoading(false)
    await new Promise(resolve => setTimeout(resolve, 500))
  }

  return [getNotificationList, { data, isLoading }]
}
