import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Payments } from 'components/customer-details/tables'
import { TABS_ID } from './settings'

const { PAYMENTS_TAB_ID } = TABS_ID

const PaymentsTab = ({ isViewOnly = false, selectedTab = '', accountId, accountData = null }) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (selectedTab === PAYMENTS_TAB_ID) {
      setIsReady(true)
    }
  }, [selectedTab])

  return isReady && <Payments accountId={accountId} customerDetails={accountData} isViewOnly={isViewOnly} />
}

PaymentsTab.propTypes = {
  isViewOnly: PropTypes.bool,
  selectedTab: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  accountData: PropTypes.object,
}

export default PaymentsTab
