import React, { useState, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom-v5-compat'

import { Button, DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { getCustomerDetailsPageUrl } from 'router/routes'
import { useGetFocusedSearchAccountsMutation } from 'api/accounts/getFocusedSearchAccounts'
import { CUSTOMER_DETAILS_TABS_ID } from 'containers/customer-details/settings'

import T from 'T'
import Loader from 'components/common/loader'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import { useDispatch, useSelector } from 'react-redux'
import { setIsCreateCustomerDialogOpen, setIsSearchByEmailCustomerDialogOpen } from 'slices/customer/createCustomerSlice'
import { HHAlert } from 'components/common/HHAlert'
import useTheme from '@mui/material/styles/useTheme'
import EmailSearchField from './EmailSearchField'
import EmailSelectField from './EmailSelectField'

const { ACCOUNT_TAB_ID } = CUSTOMER_DETAILS_TABS_ID

const ACTIONS = {
  SEARCH: 'SEARCH',
  SELECTION: 'SELECTION',
  NO_ACCOUNT_FOUND: 'NO_ACCOUNT_FOUND',
}

const { SEARCH, SELECTION, NO_ACCOUNT_FOUND } = ACTIONS

const SearchCustomerByEmail = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isSearchByEmailDialogOpen = useSelector(s => s.createCustomer.isSearchByEmailDialogOpen)
  const [getFocusedSearchAccounts, { isLoading, data }] = useGetFocusedSearchAccountsMutation()
  const [actionType, setActionType] = useState(SEARCH)
  const [selectedAccount, setSelectedAccount] = useState('')
  const searchForm = useFormContext()
  const { handleSubmit, reset, watch } = searchForm
  const onClose = () => {
    dispatch(setIsSearchByEmailCustomerDialogOpen(false))
  }
  const email = watch('email')
  const isSearchAction = actionType === SEARCH
  const isSelectAction = actionType === SELECTION
  const isNoAccountFound = actionType === NO_ACCOUNT_FOUND
  const existingAccounts = get(data, 'accounts', [])

  const handleCreateNewAccount = () => {
    dispatch(setIsCreateCustomerDialogOpen(true))
    onClose()
  }

  const handleEmailSearch = ({ email }) => {
    if (!email) {
      handleCreateNewAccount()
    }
    const payload = {
      lookups: [{ text: email, type: 'Email' }],
      requestedPage: 0,
      requestedPageSize: 50,
    }

    getFocusedSearchAccounts(payload)
      .unwrap()
      .then(response => {
        const allAccounts = get(response, 'accounts', [])
        const firstAccountId = get(allAccounts, '[0].id', '')

        setActionType(allAccounts.length === 0 ? NO_ACCOUNT_FOUND : SELECTION)
        setSelectedAccount(firstAccountId)
      })
      .catch(handleError)
  }

  const handleGoToAccount = () => {
    navigate(`${getCustomerDetailsPageUrl(selectedAccount)}?tab=${ACCOUNT_TAB_ID}`)
    onClose()
  }
  const getSubmitBtnNameAndAction = () => {
    if (actionType === SELECTION) return { title: T.GO_TO_ACCOUNT, action: handleGoToAccount }

    if (actionType === NO_ACCOUNT_FOUND) return { title: T.CREATE_NEW, action: handleCreateNewAccount }

    return { title: T.SEARCH, action: handleSubmit(handleEmailSearch) }
  }

  const { title, action } = getSubmitBtnNameAndAction()

  const onEmailSearchChange = () => setActionType(SEARCH)

  const onEmailSelectChange = event => setSelectedAccount(event.target.value)

  const onClearEmailSelect = () => {
    reset()
    setActionType(SEARCH)
  }

  useEffect(() => {
    reset()
    setActionType(SEARCH)
  }, [isSearchByEmailDialogOpen])

  return (
    <>
      {isLoading && <Loader />}
      <HHBaseDialog open={isSearchByEmailDialogOpen} onClose={onClose} maxWidth="xs" fullWidth disableScrollLock>
        <HHDialogTitle title={T.SEARCH_CUSTOMER} onClose={onClose} />
        <DialogContent>
          {/* Combine these sections using autocomplete */}
          {isSearchAction && (
            <>
              <EmailSearchField
                onEnter={handleSubmit(handleEmailSearch)}
                onSearch={handleSubmit(handleEmailSearch)}
                onChange={onEmailSearchChange}
              />
              <HHAlert severity="info" borderColor={theme.palette.info.main} sx={{ mt: 3 }}>
                Search for existing account by email
              </HHAlert>
            </>
          )}
          {isNoAccountFound && (
            <>
              <EmailSearchField
                onEnter={handleCreateNewAccount}
                onSearch={handleSubmit(handleEmailSearch)}
                onChange={onEmailSearchChange}
              />
              <HHAlert severity="warning" borderColor={theme.palette.warning.main} sx={{ mt: 3 }}>
                Cannot find customer with that email. Would you like to create a new customer?
              </HHAlert>
            </>
          )}
          {isSelectAction && (
            <EmailSelectField
              searchValue={email}
              selectedAccount={selectedAccount}
              existingAccounts={existingAccounts}
              onChange={onEmailSelectChange}
              onCancel={onClearEmailSelect}
            />
          )}
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <Button size="small" variant="contained" onClick={action}>
            {title}
          </Button>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

export default SearchCustomerByEmail
