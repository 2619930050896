import React from 'react'
import { DialogContent, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

import { useFetchChargeList } from './useFetchChargeList'
import ChargeList from './ChargeList'
import HHFullScreenBaseDialog from '../../../common/HHFullScreenBaseDialog'

const PreviewChargeCardListDrawer = ({ open, onClose }) => {
  const { onClose: onCloseFetchChargeList, loadMoreRows, isLoadingFirstBatch } = useFetchChargeList()

  const handleClose = () => {
    onCloseFetchChargeList()
    onClose()
  }
  return (
    <HHFullScreenBaseDialog open={open} onClose={onClose}>
      <AppBar elevation={0}>
        <Toolbar>
          <IconButton onClick={handleClose} edge="start" aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="textPrimary">
            Invoice preview
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ height: 'calc(100vh - 128px)', mt: 6, px: 0, pb: 0, display: 'block', width: '100%' }}>
        <ChargeList open={open} loadMoreRows={loadMoreRows} isLoadingFirstBatch={isLoadingFirstBatch} />
      </DialogContent>
    </HHFullScreenBaseDialog>
  )
}

PreviewChargeCardListDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PreviewChargeCardListDrawer
