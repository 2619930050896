import { createSelector } from 'reselect'
import { get } from 'utils/lodash'

import { selectTrimValue } from 'data/utils/trimSelector'

const CUSTOM_PROFILE_KEY = '_custom'

const selectBillingCycles = customerMeta => get(customerMeta, 'billingCycles', [])
const selectBillingCycleId = accountOrProfile => get(accountOrProfile, 'billingProfile.cycleId', null) || get(accountOrProfile, 'cycle', '')
const selectBillingTimingType = accountOrProfile =>
  get(accountOrProfile, 'timing', null) || get(accountOrProfile, 'billingProfile.timing', '')
const selectSelectedCycle = (customerMeta, accountOrProfile) => {
  const billingCycles = selectBillingCycles(customerMeta)
  const cycleId = selectBillingCycleId(accountOrProfile)
  return billingCycles.find(c => c.id === cycleId) || {}
}

export const selectAccountBillingCycleName = createSelector([selectSelectedCycle], selectedCycle => selectedCycle.intervalType || '')

export const selectAccountBillingCycleInterval = createSelector([selectSelectedCycle], selectedCycle => selectedCycle.interval || 1)

export const selectTiming = createSelector([selectBillingTimingType], timing => timing)

export const isWeekBillingCycle = createSelector([selectAccountBillingCycleName], cycleName =>
  cycleName && cycleName.match(/Week/) ? true : false
)

export const isMonthBillingCycle = createSelector([selectAccountBillingCycleName], cycleName =>
  cycleName && cycleName.match(/Month/) ? true : false
)
export const isQuarterBillingCycle = createSelector([selectAccountBillingCycleName], cycleName =>
  cycleName && cycleName.match(/Quarter/) ? true : false
)
export const isYearBillingCycle = createSelector([selectAccountBillingCycleName], cycleName =>
  cycleName && cycleName.match(/Year/) ? true : false
)

// Later extract parentBillingProfileId, profileId, accountName, parentBillingProfileId separately to handle undefined cases
export const getSelectedProfileName = createSelector(
  [state => state.parentBillingProfileId, state => state.profileId],
  (parentBillingProfileId, profileId) => {
    if (parentBillingProfileId) {
      return `${parentBillingProfileId}${CUSTOM_PROFILE_KEY}`
    }

    return profileId
  }
)

export const getCustomProfileRow = createSelector(
  [state => state.accountName, state => state.profileName, state => state.parentBillingProfileId],
  (accountName, profileName, parentBillingProfileId) => {
    if (!parentBillingProfileId) {
      return []
    }

    const trimAccountName = selectTrimValue({ valueToTrim: accountName })
    const customProfileName = trimAccountName ? `${profileName} (${trimAccountName})` : `${profileName} (Custom)`

    return [{ key: `${parentBillingProfileId}${CUSTOM_PROFILE_KEY}`, value: customProfileName }]
  }
)

export const getParentProfileName = createSelector(
  [state => state.profileId, state => state.billingProfiles],
  (profileId, billingProfiles) => {
    if (!Array.isArray(billingProfiles) || !profileId) {
      return ''
    }

    const matchedProfile = billingProfiles.find(profile => profile.id === profileId)
    return get(matchedProfile, 'profile', '')
  }
)
