import React, { useEffect } from 'react'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'
import useTheme from '@mui/material/styles/useTheme'
import isEmpty from 'lodash/isEmpty'
import { get } from 'lodash'
import { Box } from '@mui/material'
import { INVOICE_PAGINATION } from '../../../settings/constants/pagination'
import { useLazyGetBillingCreateInvoiceListQuery } from '../../../api/billing/getBillingCreateInvoiceList'
import { CREATE_INVOICE_CONFIGURATOR } from '../../../containers/billing/settings'
import { getRequiredCreateInvoicesFilter } from '../../../containers/billing/filter'
import {
  setCreateInvoicesColumnVisibilityModel,
  setCreateInvoicesIsOpenTableFilters,
  setCreateInvoicesPage,
  setCreateInvoicesPageSize,
  setCreateInvoicesPinnedColumns,
  setCreateInvoicesRefetchFlag,
  setCreateInvoicesSelectionModel,
  setCreateInvoicesSortModel,
} from '../../../slices/billing/createInvoicesSlice'
import { StyledNoResultTypography } from '../BillingCommonMethods'
import T from '../../../T'
import { DataGridPagination } from '../../data_grid/DataGridPagination'
import CustomLoadingOverlay from '../common/CustomLoadingOverlay'

const { ROWS_PER_PAGE_OPTIONS } = INVOICE_PAGINATION

const NoRowsOverlay = () => {
  const selectedBillingProfile = useSelector(state => state.createInvoicesSlice.selectedBillingProfile)
  const selectedBillingPeriod = useSelector(state => state.createInvoicesSlice.selectedBillingPeriod)
  const isBillingProfileAndPeriodSelected = selectedBillingProfile && selectedBillingPeriod
  return (
    <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center">
      <StyledNoResultTypography variant="h4">
        {isBillingProfileAndPeriodSelected ? T.NO_CUSTOMERS : T.CREATE_INVOICE_TABLE_REQUIRED_MSG}
      </StyledNoResultTypography>
    </Box>
  )
}
const CreateInvoicesDataGrid = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [_, setSearchParams] = useSearchParams()
  const [getBillingCreateInvoiceList, { isFetching, isLoading, data }] = useLazyGetBillingCreateInvoiceListQuery()
  const selectedBillingProfile = useSelector(state => state.createInvoicesSlice.selectedBillingProfile)
  const selectedBillingPeriod = useSelector(state => state.createInvoicesSlice.selectedBillingPeriod)
  const isBillingProfileAndPeriodSelected = selectedBillingProfile && selectedBillingPeriod
  const rows = isBillingProfileAndPeriodSelected ? get(data, 'tableRows', []) : []
  const rowCount = isBillingProfileAndPeriodSelected ? get(data, 'totalItems') : null
  const page = useSelector(state => state.createInvoicesSlice.pagination.page)
  const pageSize = useSelector(state => state.createInvoicesSlice.pagination.pageSize)
  const sortModel = useSelector(state => state.createInvoicesSlice.sorting.sortModel, shallowEqual)
  const selectionModel = useSelector(state => state.createInvoicesSlice.selectionModel, shallowEqual)
  const queryParams = useSelector(state => state.createInvoicesSlice.filters.queryParams, shallowEqual)
  const columnVisibilityModel = useSelector(state => state.createInvoicesSlice.columns.columnVisibilityModel)
  const rawSearchFilters = useSelector(state => state.createInvoicesSlice.filters.searchFilters, shallowEqual)
  const rawSortRules = useSelector(state => state.createInvoicesSlice.filters.sortRules, shallowEqual)
  const pinnedColumns = useSelector(state => state.createInvoicesSlice.pinnedColumns, shallowEqual)
  const selectedBillingProfileDetails = useSelector(state => state.createInvoicesSlice.selectedBillingProfileDetails)
  const selectedBillingPeriodDetails = useSelector(state => state.createInvoicesSlice.selectedBillingPeriodDetails)
  const refetchFlag = useSelector(state => state.createInvoicesSlice.filters.refetchFlag)
  const searchFilters = isEmpty(rawSearchFilters) ? [] : rawSearchFilters
  const sortRules = isEmpty(rawSortRules) ? undefined : rawSortRules
  const createInvoicesSearchFilters = searchFilters.concat(
    getRequiredCreateInvoicesFilter(get(selectedBillingProfileDetails, 'name'), selectedBillingPeriodDetails)
  )

  const getRowId = ({ accountId }) => accountId

  const handlePageSizeChange = newSize => {
    if (!isBillingProfileAndPeriodSelected) return
    dispatch(setCreateInvoicesPageSize(newSize))
    dispatch(setCreateInvoicesPage(0))
    getBillingCreateInvoiceList({
      sortRules,
      searchFilters: createInvoicesSearchFilters,
      activeBillingTab: 'create_invoices',
      requestedPage: 0,
      requestedPageSize: newSize,
    })
  }

  const handlePageChange = newPage => {
    if (isLoading || isFetching) return
    dispatch(setCreateInvoicesPage(newPage))
    getBillingCreateInvoiceList({
      sortRules,
      searchFilters: createInvoicesSearchFilters,
      activeBillingTab: 'create_invoices',
      requestedPage: newPage,
      requestedPageSize: pageSize,
    })
  }

  const handlePageAndSizeChange = ({ page: newPage, pageSize: newPageSize }) => {
    if (page !== newPage) {
      handlePageChange(newPage)
      return
    }

    if (pageSize !== newPageSize) {
      handlePageSizeChange(newPageSize)
    }
  }

  const handleSortModelChange = newSortModel => {
    if (!isBillingProfileAndPeriodSelected) return
    dispatch(setCreateInvoicesSortModel(newSortModel))
    dispatch(setCreateInvoicesRefetchFlag(true))
  }

  const handleSelectionModelChange = newSelectionModel => {
    dispatch(setCreateInvoicesSelectionModel(newSelectionModel))
  }

  const handleColumnVisibilityModelChange = newColumnVisibilityModel => {
    dispatch(setCreateInvoicesColumnVisibilityModel(newColumnVisibilityModel))
  }

  const handlePinnedColumnModelChange = updatedPinnedColumns => {
    dispatch(setCreateInvoicesPinnedColumns(updatedPinnedColumns))
  }

  const handleRowClick = () => {}

  useEffect(() => {
    if (isBillingProfileAndPeriodSelected) {
      getBillingCreateInvoiceList({
        activeBillingTab: 'create_invoices',
        sortRules,
        searchFilters: createInvoicesSearchFilters,
        requestedPage: page,
        requestedPageSize: pageSize,
      })
    }
  }, [isBillingProfileAndPeriodSelected])

  useEffect(() => {
    if (refetchFlag) {
      getBillingCreateInvoiceList({
        activeBillingTab: 'create_invoices',
        sortRules,
        searchFilters: createInvoicesSearchFilters,
        requestedPage: page,
        requestedPageSize: pageSize,
      })
        .unwrap()
        .then(() => {
          dispatch(setCreateInvoicesRefetchFlag(false))
          dispatch(setCreateInvoicesIsOpenTableFilters(false))
        })
    }
  }, [refetchFlag])

  useEffect(() => {
    if (queryParams) {
      setSearchParams({ ...queryParams })
    }
  }, [queryParams])

  return (
    <DataGridPro
      checkboxSelection
      disableRowSelectionOnClick
      paginationMode="server"
      sortingMode="server"
      disableColumnFilter
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={{ page, pageSize }}
      rows={rows}
      pagination
      pinnedColumns={pinnedColumns}
      rowCount={rowCount}
      sortModel={sortModel}
      rowSelectionModel={selectionModel}
      columnVisibilityModel={columnVisibilityModel}
      loading={isLoading || isFetching}
      sx={{
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        '.MuiDataGrid-columnHeader': {
          fontWeight: 'bold',
        },
        mt: 3,
        '.MuiDataGrid-toolbarContainer': {
          paddingLeft: 1.5,
        },
      }}
      columns={CREATE_INVOICE_CONFIGURATOR}
      slots={{
        toolbar: GridToolbar,
        noRowsOverlay: NoRowsOverlay,
        pagination: DataGridPagination,
        loadingOverlay: CustomLoadingOverlay,
      }}
      getRowId={getRowId}
      onPinnedColumnsChange={handlePinnedColumnModelChange}
      onSortModelChange={handleSortModelChange}
      onRowSelectionModelChange={handleSelectionModelChange}
      onPaginationModelChange={handlePageAndSizeChange}
      onRowClick={handleRowClick}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
    />
  )
}

CreateInvoicesDataGrid.propTypes = {}

export default CreateInvoicesDataGrid
