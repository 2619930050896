import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import MaterialTaxListItem from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/MaterialTaxFooter/MaterialTaxListItem'
import { useFormContext, useWatch } from 'react-hook-form'

const MaterialTaxFooter = ({ showSubtotal, subtotal, groupByFeeTaxes, index }) => {
  const { watch, setValue } = useFormContext()
  const isTaxable = watch(`isTaxable`)
  const taxes = useWatch({
    name: `materials.${index}.taxes`,
  })

  const materialTotal = watch(`materials.${index}.total`)

  useEffect(() => {
    let total = subtotal
    if (Array.isArray(taxes) && isTaxable) {
      for (const tax of taxes) {
        total += tax
      }
      setValue(`materials.${index}.total`, total)
    } else {
      setValue(`materials.${index}.total`, subtotal)
    }
  }, [taxes, subtotal, isTaxable])

  return (
    <>
      {isTaxable && (
        <Box mt={1}>
          {Object.keys(groupByFeeTaxes).map((taxKey, taxIndex) => {
            return (
              <MaterialTaxListItem
                taxKey={taxKey}
                groupByFeeTaxes={groupByFeeTaxes}
                materialIndex={index}
                subtotal={subtotal}
                taxIndex={taxIndex}
              />
            )
          })}
          {showSubtotal && (
            <Grid container item xs={12} justifyContent="flex-end">
              <Box my={1}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" fontWeight={500} textAlign="right">
                    <HHDisplayMoney value={materialTotal} formatToDollars={false} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary" textAlign="right">
                    Subtotal
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          )}
        </Box>
      )}
    </>
  )
}

MaterialTaxFooter.propTypes = {
  subtotal: PropTypes.number.isRequired,
  groupByFeeTaxes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  showSubtotal: PropTypes.bool,
}

export default MaterialTaxFooter
