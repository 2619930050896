import { alpha, Autocomplete as MuiAutocomplete, styled } from '@mui/material'
import { orange } from '@mui/material/colors'

const ExpandableAutocomplete = styled(MuiAutocomplete)(({ theme, isTemporaryModeActive = false }) => {
  const color = isTemporaryModeActive ? orange[500] : theme.palette.primary.main
  return {
    // '&:not(:focus-within)': {
    //   width: '10rem',
    //   transition: theme.transitions.create('width'),
    // },
    '.MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '& fieldset': {
        borderColor: color,
      },
      '&:hover fieldset': {
        borderColor: color,
      },
      '&:focus fieldset': {
        borderColor: color,
      },
      '&:focus-within fieldset': {
        borderColor: color,
      },
    },
    // '&:focus-within': {
    //   width: '100%',
    //   transition: theme.transitions.create('width'),
    // },
    '& svg': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '&:focus-within svg': {
      color: alpha(theme.palette.common.black, 0.54),
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.primary,
    },
  }
})

export default ExpandableAutocomplete
