import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RenderAccountCell from 'components/customers/accounts/RenderAccountCell'
import WithDeactivatedIndicator from 'components/customers/WithDeactivatedIndicator'

const RenderRentalFeeAccountCell = ({ row = {}, detailPanelExpandedRowIds = [] }) => {
  const apiRef = useGridApiContext()
  const { accountId, active } = row
  const isExpanded = detailPanelExpandedRowIds.includes(accountId)

  const handleToggleAccountDetailPanel = e => {
    e.stopPropagation()
    apiRef.current.toggleDetailPanel(accountId)
  }

  return (
    <Box width="100%" mx={3} borderLeft="1px solid" borderRight="1px solid" borderColor="divider" borderTopWidth={0} borderBottomWidth={0}>
      <WithDeactivatedIndicator showIndicator={!active} row={row}>
        <Grid container alignItems="center" justifyContent="space-between" columnGap={2}>
          <Grid className="expand-toggle-account-group-details" sx="auto">
            <IconButton onClick={handleToggleAccountDetailPanel}>{isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
          </Grid>
          <Grid item xs>
            <RenderAccountCell row={row} showRemoveFromGroup={false} />
          </Grid>
        </Grid>
      </WithDeactivatedIndicator>
    </Box>
  )
}

RenderRentalFeeAccountCell.propTypes = {
  row: PropTypes.object,
  detailPanelExpandedRowIds: PropTypes.array,
}

export default RenderRentalFeeAccountCell
