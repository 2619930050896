import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, ListItemIcon, ListItemText, Popover, Popper, TextField, ListItemButton, MenuItem, Paper } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import StyledBadge from 'components/locations/filters/StyledBadge'
import { SUSPENSION_REASON_TYPES_OPTIONS } from 'components/customer-details/content/suspensions/settings'
import RenderSuspensionTypeFilterOption from 'components/locations/RenderSuspensionTypeFilterOption'

const WIDTH = 200

const CustomPopper = props => <Popper {...props} placement="bottom-start" />

const SuspensionTypeFilterMenu = ({
  value,
  onChange = noop,
  defaultSelectedSuspensionTypes = [],
  renderOption = RenderSuspensionTypeFilterOption,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const open = Boolean(popoverAnchorEl)
  const isControlled = value !== undefined
  const [internalSelectedSuspensionTypes, setInternalSelectedSuspensionTypes] = useState(defaultSelectedSuspensionTypes)
  const selectedSuspensionTypes = isControlled ? value : internalSelectedSuspensionTypes
  const allSuspensionTypesSelected = useMemo(
    () => selectedSuspensionTypes.length === SUSPENSION_REASON_TYPES_OPTIONS.length,
    [selectedSuspensionTypes]
  )
  const suspensionTypeButtonLabel = useMemo(() => {
    if (selectedSuspensionTypes.length === 0) return 'No suspension type'
    return 'Suspension type'
  }, [selectedSuspensionTypes])

  const focusAutocomplete = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleOpen = event => {
    setPopoverAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchorEl(null)
  }

  const getOptionLabel = option => get(option, 'value')

  const toggleAllSuspensionTypeSelection = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      const newSelected = allSuspensionTypesSelected ? [] : [...SUSPENSION_REASON_TYPES_OPTIONS]
      if (isControlled) {
        onChange(newSelected)
      } else {
        setInternalSelectedSuspensionTypes(newSelected)
        onChange(newSelected)
      }
    },
    [isControlled, allSuspensionTypesSelected]
  )

  const handleChange = (event, newSelected) => {
    if (isControlled) {
      onChange(newSelected)
    } else {
      setInternalSelectedSuspensionTypes(newSelected)
      onChange(newSelected)
    }
  }

  useEffect(() => {
    if (!isInitialized) {
      setInternalSelectedSuspensionTypes(defaultSelectedSuspensionTypes)
      setIsInitialized(true)
    }
  }, [isInitialized, defaultSelectedSuspensionTypes])

  const SelectAllPaperComponent = useMemo(
    () => paperProps => {
      const { children, ...restPaperProps } = paperProps
      return (
        <Paper square sx={{ width: WIDTH }} {...restPaperProps}>
          <ListItemButton disableGutters divider selected={allSuspensionTypesSelected} onMouseDown={toggleAllSuspensionTypeSelection}>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox checked={allSuspensionTypesSelected} />
            </ListItemIcon>
            <ListItemText primary={allSuspensionTypesSelected ? 'Deselect all' : 'Select all'} />
          </ListItemButton>
          {children}
        </Paper>
      )
    },
    [allSuspensionTypesSelected, toggleAllSuspensionTypeSelection]
  )

  const isOptionEqualToValue = (option, value) => {
    const optionValue = get(option, 'value', '')
    const valueToCompare = get(value, 'value', '')
    return optionValue && optionValue === valueToCompare
  }

  return (
    <>
      <MenuItem variant="text" onClick={handleOpen}>
        <ListItemIcon>
          <StyledBadge overlap="circular" badgeContent={selectedSuspensionTypes.length} color="primary">
            <FilterListIcon color="primary" />
          </StyledBadge>
        </ListItemIcon>
        <ListItemText sx={{ ml: 2, minWidth: 120 }}>{suspensionTypeButtonLabel}</ListItemText>
      </MenuItem>
      <Popover
        id="suspension-type-filter-menu-popover"
        open={open}
        TransitionProps={{ onEntered: focusAutocomplete }}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        transitionDuration={{ appear: 0, exit: 0 }}
        slotProps={{
          paper: {
            square: true,
            sx: { width: WIDTH, overflow: 'hidden' },
          },
        }}
      >
        <Autocomplete
          multiple
          options={SUSPENSION_REASON_TYPES_OPTIONS}
          sx={{ px: 1.5, '& .MuiInputBase-root': { py: 0.75 } }}
          fullWidth
          componentsProps={{
            paper: { allSuspensionTypesSelected, toggleAllSuspensionTypeSelection },
            popper: { modifiers: [{ name: 'offset', options: { offset: [-12, 0] } }] },
          }}
          ListboxProps={{
            sx: { py: 0, [`& .${autocompleteClasses.option}`]: { pl: 0 } },
          }}
          PaperComponent={SelectAllPaperComponent}
          PopperComponent={CustomPopper}
          disableClearable
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={handleChange}
          open={open}
          renderOption={renderOption}
          renderTags={() => <></>}
          value={selectedSuspensionTypes}
          renderInput={({ inputProps, ...restTextFieldProps }) => (
            <TextField {...restTextFieldProps} inputRef={inputRef} variant="standard" inputProps={{ ...inputProps }} />
          )}
          {...rest}
        />
      </Popover>
    </>
  )
}

SuspensionTypeFilterMenu.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedSuspensionTypes: PropTypes.array,
  renderOption: PropTypes.func,
}

export default SuspensionTypeFilterMenu
