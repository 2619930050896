import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, ListItem, ListItemText } from '@mui/material'
import T from 'T'

const BulkMoveColumnHeader = ({ endIcon, selectedRowsCount, onClick, label = T.MOVE }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <ListItem disablePadding disableGutters>
        <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={`${selectedRowsCount} selected`} />
      </ListItem>
      <Button
        sx={{ position: 'absolute', right: 8 }}
        size="small"
        variant="outlined"
        disabled={selectedRowsCount === 0}
        endIcon={endIcon}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  )
}

BulkMoveColumnHeader.propTypes = {
  endIcon: PropTypes.element.isRequired,
  selectedRowsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default BulkMoveColumnHeader
