import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'

import Chip from '@mui/material/Chip'
import EmailIcon from '@mui/icons-material/Email'
import { MailOpen } from '@styled-icons/heroicons-solid/MailOpen'
import { Mail } from '@styled-icons/heroicons-solid/Mail'

import { green, red } from '@mui/material/colors'
import { EVENT_TYPE } from 'settings/constants/email'

const { PROCESSED, DELIVERED, CLICK, OPEN, SPAM } = EVENT_TYPE

const EmailEventChip = ({ eventType = '' }) => {
  const getParams = () => {
    const isSuccessEvent = [PROCESSED, DELIVERED, CLICK, OPEN].includes(eventType)
    const color = isSuccessEvent ? green : red
    let label = capitalize(eventType)

    if ([CLICK, OPEN].includes(eventType)) label += 'ed'
    if (eventType === SPAM) label = 'Spam'

    return { label, color, isSuccessEvent }
  }

  const { label, color, isSuccessEvent } = getParams()

  return (
    <Chip
      size="small"
      avatar={isSuccessEvent ? <MailOpen /> : <Mail />}
      label={label}
      sx={{
        fontWeight: 600,
        border: '1px solid',
        borderColor: color[500],
        backgroundColor: color[50],
        color: color[700],
        '& .MuiChip-avatar': { color: color[500] },
      }}
    />
  )
}

EmailEventChip.propTypes = {
  eventType: PropTypes.string,
}

export default EmailEventChip
