import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemAvatar, ListItemText, Stack, styled, SvgIcon, useMediaQuery } from '@mui/material'
import { Star } from '@mui/icons-material'
import { ReactComponent as FlagCheckered } from 'assets/route_manager/yards_preview/FlagCheckered.svg'
import { get, keyBy } from 'lodash'
import { grey } from '@mui/material/colors'
import { useSelector } from 'react-redux'
import RouteAvatar from './RouteAvatar/RouteAvatar'
import { isValidHex } from '../../../utils/validations'
import { getMetadataBusinessLines, getMetadataMethods, getMetadataYards } from '../../../data/route/routeMetadataSelector'
import { replaceAllSpacesAndAmpersand } from '../../../utils/string'
import Constants from '../../../Constants'

const { MEDIA_SERVER } = Constants

const Icon = styled('img')`
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  height: 24px;
  width: 24px;
`
const RouteDetailsViewModeContent = ({ route }) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const bgcolor = get(route, 'color', grey[100])
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const routeName = get(route, 'name')
  const driverProfilePic = get(route, 'driverInfo.profilePicUri', null)
  const allYards = useSelector(getMetadataYards)
  const idToYardMap = keyBy(allYards, 'id')
  const allBusinessLines = useSelector(getMetadataBusinessLines)
  const idToBusinessLineMap = keyBy(allBusinessLines, 'id')
  const allMethods = useSelector(getMetadataMethods)
  const idToMethodsMap = keyBy(allMethods, 'id')
  const startingYardId = get(route, 'startingYard.id')
  const endingYardId = get(route, 'endingYard.id')

  const businessLineId = get(route, 'businessLine.id')
  const selectedBusinessLineId = businessLineId in idToBusinessLineMap && idToBusinessLineMap[businessLineId]
  const businessLineLabel = get(selectedBusinessLineId, 'businessLineLabel', undefined)
  const businessLineIcon = get(selectedBusinessLineId, 'businessLineIcon', undefined)

  const methodId = get(route, 'method.id')
  const selectedMethod = methodId in idToMethodsMap && idToMethodsMap[methodId]
  const methodType = get(selectedMethod, 'methodType', undefined)
  const methodName = get(selectedMethod, 'methodName', undefined)
  const methodIcon = methodType && `${replaceAllSpacesAndAmpersand(methodType)}.svg`

  const startingYardName = startingYardId in idToYardMap ? get(idToYardMap[startingYardId], 'name', undefined) : undefined
  const endingYardName = endingYardId in idToYardMap ? get(idToYardMap[endingYardId], 'name', undefined) : undefined
  return (
    <Stack direction="column">
      <ListItem disableGutters disablePadding sx={{ minWidth: isDesktop ? '14rem' : 'unset' }}>
        <ListItemAvatar>
          <RouteAvatar bgcolor={formattedBgcolor} name={routeName} avatarSrc={driverProfilePic} />
        </ListItemAvatar>
        <ListItemText primary={routeName} />
      </ListItem>
      <ListItem sx={{ mt: 1.5 }} disableGutters disablePadding>
        <ListItemAvatar>{businessLineIcon && <Icon src={`${MEDIA_SERVER}${businessLineIcon}`} />}</ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'h6', color: 'textPrimary', fontWeight: 400 }}
          primary="Business line"
          secondary={businessLineLabel || '-'}
        />
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemAvatar>{methodIcon && <Icon src={`${MEDIA_SERVER}Method/${methodIcon}`} />}</ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'h6', color: 'textPrimary', fontWeight: 400 }}
          primary="Method"
          secondary={methodName || '-'}
        />
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemAvatar>
          <Star sx={{ ml: 0.5 }} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'h6', color: 'textPrimary', fontWeight: 400 }}
          primary="Starting yard"
          secondary={startingYardName || '-'}
        />
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemAvatar>
          <SvgIcon sx={{ ml: 0.7 }} fontSize="small">
            <FlagCheckered />
          </SvgIcon>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          secondaryTypographyProps={{ variant: 'h6', color: 'textPrimary', fontWeight: 400 }}
          primary="Ending yard"
          secondary={endingYardName || '-'}
        />
      </ListItem>
    </Stack>
  )
}

RouteDetailsViewModeContent.propTypes = {
  route: PropTypes.object,
}

export default RouteDetailsViewModeContent
