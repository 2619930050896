import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import {
  Table as TableView,
  TableRow,
  TableCell,
  TableBody as TBody,
  TableHead as THead,
  Typography,
  Card,
  Radio,
  FormControlLabel,
  styled,
  Box,
} from '@mui/material'

import Loader from 'components/common/loader'
import { canUpdateSettings } from 'data/permissions/permissionsSelectors'

import T from 'T'

import Constants from 'Constants'

import { getLocalDate } from 'utils/date'
import TableLayout from '../../../layout/TableLayout'

const { MEDIA_SERVER } = Constants

const headerVal = [
  { filter: false, name: T.NAME, sort: false },
  { filter: false, name: T.DEFAULT, sort: false },
  { filter: false, name: T.LAST_UPDATED, sort: false, align: 'right' },
]

const StyledTableCell = styled(TableCell)(() => ({
  padding: '5px 16px 5px 8px',
  cursor: 'pointer',
}))

const ListProfiles = ({ isLoading = false, profiles = [], handleEdit = noop, handleDefaultChange = noop }) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)

  return (
    <Card sx={{ mt: 2 }}>
      <TableLayout height="calc(100vh - 140px)">
        <TableView stickyHeader aria-label="sticky table">
          <THead>
            <TableRow>
              {Children.toArray(
                headerVal &&
                  headerVal.map(val => (
                    <TableCell sx={{ p: '25px 16px 10px 8px', backgroundColor: 'background.paper' }}>
                      <Box display="flex" justifyContent={val.align}>
                        {val.filter && <img src={`${MEDIA_SERVER}Table-Filter-Icon.svg`} alt="" />}

                        <Typography variant="subtitle2" p={val.filter ? '0 4px' : 0}>
                          {val.name}
                        </Typography>
                        {val.sort && <img src={`${MEDIA_SERVER}Table-Sort-Icon.svg`} alt="" />}
                      </Box>
                    </TableCell>
                  ))
              )}
            </TableRow>
          </THead>

          <TBody>
            {isLoading && <Loader />}

            {Children.toArray(
              profiles.length > 0 &&
                profiles.map(val => {
                  return (
                    <TableRow>
                      <StyledTableCell onClick={() => (updateSettings ? handleEdit(val.id) : '#')}>
                        <Typography variant="body1">{val.name}</Typography>
                      </StyledTableCell>

                      <StyledTableCell>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={val.default}
                              onChange={event => handleDefaultChange(event, val.id)}
                              name="radio-buttons"
                              size="small"
                            />
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (updateSettings ? handleEdit(val.id) : '#')} align="right">
                        <Typography variant="body1">{getLocalDate(val.updatedAt)}</Typography>
                      </StyledTableCell>
                    </TableRow>
                  )
                })
            )}
          </TBody>
        </TableView>
      </TableLayout>
    </Card>
  )
}

ListProfiles.propTypes = {
  isLoading: PropTypes.bool,
  profiles: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDefaultChange: PropTypes.func,
}

export default ListProfiles
