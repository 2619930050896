import React, { useState, Children } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useParams } from 'react-router-dom'
import { Box, Grid, IconButton, ListItem, ListItemText } from '@mui/material'
import { CurrencyDollar } from '@styled-icons/heroicons-outline/CurrencyDollar'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { getBatchCreationDetails } from 'utils/takePayments'
import { getPaymentsByBatch } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'

import GraphCard from '../../common/GraphCard'
import PaymentBatchStatistic from './PaymentBatchStatistic'

const PaymentBatches = () => {
  const { id } = useParams()
  const [isGraphSectionOpen, setIsGraphSectionOpen] = useState(true)
  const { data } = useGetPaymentGroupDetailsQuery({ id })
  const batches = getPaymentsByBatch({ paymentBatches: get(data, 'paymentBatches', []) })

  const toggleGraphSection = () => setIsGraphSectionOpen(!isGraphSectionOpen)

  return (
    !isEmpty(batches) && (
      <>
        <Grid item xs={12}>
          <ListItem>
            <IconButton onClick={toggleGraphSection}>{isGraphSectionOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
            <ListItemText primaryTypographyProps={{ variant: 'h5', fontWeight: 400, color: 'textPrimary' }} primary="Payment batches" />
          </ListItem>
        </Grid>
        {isGraphSectionOpen && (
          <Grid container item spacing={2} mb={2}>
            {Children.toArray(
              Object.keys(batches).map(batch => {
                const currentBatch = get(batches, batch, [])
                const isIndividualPayment = get(currentBatch, '[0].isIndividualPayment')
                const { createdBy, createdAt, onlyDate, onlyTime } = getBatchCreationDetails(isIndividualPayment, currentBatch)

                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <GraphCard
                      sx={{ height: '100%' }}
                      Icon={CurrencyDollar}
                      title={batch}
                      createdBy={createdBy}
                      time={onlyTime}
                      date={onlyDate}
                      createdAt={createdAt}
                    >
                      <Box height={180}>
                        <PaymentBatchStatistic payments={currentBatch} showZeroValueLegend={false} customTooltip />
                      </Box>
                    </GraphCard>
                  </Grid>
                )
              })
            )}
          </Grid>
        )}
      </>
    )
  )
}

export default PaymentBatches
