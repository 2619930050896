import React from 'react'
import PropTypes from 'prop-types'

import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { ReactComponent as HoldIcon } from 'assets/work-orders/HoldIcon.svg'

import { get } from 'utils/lodash'
import { WO_STATUS_BE_TO_FE_FORMAT, WORK_ORDER_STATUS } from 'settings/constants/service'
import { blue, deepPurple, green, grey, orange, red } from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import { Check } from '@mui/icons-material'
import { SvgIcon } from '@mui/material'

const { SERVICED, SKIPPED, IN_PROCESS, IN_PROGRESS, SCHEDULED, CANCELED, HOLD } = WORK_ORDER_STATUS

const WORK_ORDER_STATUS_DISPLAY = {
  [SERVICED]: { type: 'success', bgColor: green[50] },
  [SKIPPED]: { type: 'warning', bgColor: orange[50] },
  [IN_PROCESS]: { type: 'info', bgColor: blue[50] },
  [SCHEDULED]: { type: '', bgColor: grey[50] },
  [CANCELED]: { type: 'error', bgColor: red[50] },
  [HOLD]: { type: '', bgColor: deepPurple[50] },
}

const WorkOrderStatusAlert = ({ status }) => {
  const workOrderStatus = get(WO_STATUS_BE_TO_FE_FORMAT, status, status)

  const alertScheduledType =
    status === SCHEDULED ? { color: 'text.primary', backgroundColor: get(WORK_ORDER_STATUS_DISPLAY, `${workOrderStatus}.bgColor`) } : {}
  const getIcon = () => {
    if (workOrderStatus === CANCELED) return <CloseIcon />
    if (workOrderStatus === SERVICED) return <Check />
    if (workOrderStatus === SKIPPED) return <FastForwardOutlinedIcon />
    if (workOrderStatus === IN_PROCESS) return <PlayCircleOutlineOutlinedIcon />
    if (workOrderStatus === IN_PROGRESS) return <PlayCircleOutlineOutlinedIcon />
    if (workOrderStatus === HOLD)
      return (
        <SvgIcon inheritViewBox viewBox="0 0 12 10" sx={{ color: deepPurple[700], width: 24, height: 24 }}>
          <HoldIcon />
        </SvgIcon>
      )
    // SCHEDULED
    return <CalendarTodayOutlinedIcon />
  }

  const getSeverity = () => {
    if (workOrderStatus === CANCELED) return 'error'
    if (workOrderStatus === SERVICED) return 'success'
    if (workOrderStatus === SKIPPED) return 'warning'
    if (workOrderStatus === IN_PROCESS) return 'info'
    if (workOrderStatus === IN_PROGRESS) return 'info'
    return ''
  }

  const getTitle = () => {
    if (workOrderStatus === CANCELED) return 'This work order was cancelled'
    if (workOrderStatus === SERVICED) return 'This work order was serviced'
    if (workOrderStatus === SKIPPED) return 'This work order was skipped'
    if (workOrderStatus === IN_PROCESS) return 'This work order is in progress'
    if (workOrderStatus === IN_PROGRESS) return 'This work order is in progress'
    if (workOrderStatus === HOLD) return 'This work order is on hold'
    if (workOrderStatus === SCHEDULED) return 'This work order is scheduled'
    return ''
  }

  const severity = getSeverity()
  const icon = getIcon()
  const title = getTitle()
  return (
    <Alert
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...alertScheduledType,
        ...(workOrderStatus === HOLD && { bgcolor: deepPurple[100] }),
        ...(workOrderStatus === SCHEDULED && { bgcolor: grey[100] }),
      }}
      severity={severity}
      icon={icon}
    >
      <Typography variant="h5" fontWeight={400} color="textPrimary">
        {title}
      </Typography>
    </Alert>
  )
}

WorkOrderStatusAlert.propTypes = {
  status: PropTypes.string,
}

export default WorkOrderStatusAlert
