import React, { useRef, useState, Children } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import InputAdornment from '@mui/material/InputAdornment'

import { Search } from '@styled-icons/heroicons-outline/Search'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { get } from 'utils/lodash'
import { replaceAllSpacesAndAmpersand } from 'utils/string'

import Constants from 'Constants'
import CommonTextfield from 'components/common/CommonTextfield'

import './style.scss'
import { Box } from '@mui/material'

const { MEDIA_SERVER } = Constants

const CommonIconSelectExtended = ({ records, selectedRecord, className, placeholder, type, onBlur, onChange, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef()

  // We can also find name & icon from selectedRecord.id if passed
  const selectedRecordName = get(selectedRecord, 'name', '')
  const selectedRecordIcon = get(selectedRecord, 'type', selectedRecordName)

  const handleChange = (value, isOptionSelected = false) => {
    onChange(isOptionSelected, value)
    setInputValue(value)
  }

  return (
    <div className={`common icon-selection-field ${className}`}>
      <div
        onClick={event => {
          if (disabled) return
          setIsOpen(true)
          setTimeout(() => inputRef.current.focus(), 100)
        }}
        className={`photo-select-field transparent-scroll ${isOpen ? 'hide' : ''}`}
      >
        <div className="flex items-center">
          {selectedRecordName && (
            <>
              <img
                className="pic-icon"
                src={`${MEDIA_SERVER}${startCase(type)}/${replaceAllSpacesAndAmpersand(selectedRecordIcon)}.svg`}
                alt="icons"
              />
              <span className="text">{selectedRecordName}</span>
            </>
          )}
          {!selectedRecordName && <span className="text empty">{placeholder}</span>}
        </div>
        <ChevronDown className="assignee-down-icon" />
      </div>

      <Box position="relative" display={!isOpen ? 'none' : 'block'} className={!isOpen ? 'dn relative' : 'relative'}>
        <CommonTextfield
          disabled={disabled}
          refVar={inputRef}
          value={inputValue}
          onChange={event => handleChange(event.target.value)}
          placeholder={placeholder}
          onBlur={() => {
            setIsOpen(false)
            if (onBlur) {
              onBlur(inputValue)
            }
            setInputValue('')
          }}
          startAdornment={
            <InputAdornment position="start">
              <Search className="search-icon" />
            </InputAdornment>
          }
        />
        <ul className="photo-select-autocomplete">
          {Children.toArray(
            records.map(record => {
              const name = get(record, 'name', '')
              const icon = get(record, 'type', name)

              return (
                <li onMouseDown={() => handleChange(record, true)}>
                  {name && (
                    <img
                      className="pic-icon"
                      src={`${MEDIA_SERVER}${startCase(type)}/${replaceAllSpacesAndAmpersand(icon)}.svg`}
                      alt="icons"
                    />
                  )}
                  <span className="text">{name}</span>
                </li>
              )
            })
          )}

          {!records.length && <span className="text empty tl">No Options</span>}
        </ul>
      </Box>
    </div>
  )
}

CommonIconSelectExtended.defaultProps = {
  records: [],
  className: '',
  placeholder: '',
  type: '',
  selectedRecord: null,
  onBlur: null,
}

CommonIconSelectExtended.propTypes = {
  records: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  selectedRecord: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CommonIconSelectExtended
