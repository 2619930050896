import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'

import ValidatedListingTableHeader from 'components/pricing/ValidatedListingTableHeader'
import ServiceTableBody from './ServiceTableBody'

const ServiceTable = ({
  validatedServices,
  confServices,
  handleSingleChange,
  handleDelete,
  onHandleValidatedFieldsChange,
  type,
  getPricingServiceName,
}) => {
  return (
    <>
      <TableContainer className="services-table">
        <TableView>
          <ValidatedListingTableHeader confServices={confServices} handleSingleChange={handleSingleChange} />

          <ServiceTableBody
            validatedServices={validatedServices}
            confServices={confServices}
            handleDelete={handleDelete}
            onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
            type={type}
            getPricingServiceName={getPricingServiceName}
          />
        </TableView>
      </TableContainer>
    </>
  )
}

ServiceTable.defaultProps = {
  validatedServices: [],
  confServices: [],
  type: 'unique',
  handleSingleChange: null,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getPricingServiceName: null,
}

ServiceTable.propTypes = {
  validatedServices: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  handleSingleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getPricingServiceName: PropTypes.func,
}

export default ServiceTable
