/* eslint-disable no-restricted-syntax */
import { cloneDeep, get } from 'lodash'

function sort(invoiceLinesByLocation) {
  for (const [_, invoiceLines] of invoiceLinesByLocation) {
    invoiceLines.sort((a, b) => {
      // Protection in case there is no serviceStartDate
      let aDate
      let bDate
      try {
        aDate = new Date(a.serviceStartDate)
      } catch (err) {
        aDate = new Date()
      }
      try {
        bDate = new Date(b.serviceStartDate)
      } catch (err) {
        bDate = new Date()
      }

      // Sort by 1) service type, 2) service start date, 3) service name
      return (
        (a.pricedServiceType || '')?.localeCompare(b.pricedServiceType) ||
        aDate.getTime() - bDate.getTime() ||
        (a.summary || '')?.localeCompare(b.summary)
      )
    })
  }
}

export const getInvoiceLinesByLocation = invoice => {
  const invoiceLinesByLocation = new Map()
  for (const invoiceLine of get(invoice, 'invoiceLines', [])) {
    const { locationId } = invoiceLine

    const invoiceLines = invoiceLinesByLocation.get(locationId)
    if (!invoiceLinesByLocation.has(locationId)) {
      invoiceLinesByLocation.set(locationId, [invoiceLine])
    } else if (
      invoiceLine.pricedServiceType === 'On Request' ||
      !invoiceLines.find(line => line.configuredServiceId === invoiceLine.configuredServiceId)
    ) {
      invoiceLines.push(invoiceLine)
    }
  }
  sort(invoiceLinesByLocation)
  return invoiceLinesByLocation
}

export const getSubtotalByLocation = invoiceLinesByLocation => {
  const subtotalByLocation = new Map()
  for (const [locationId, invoiceLines] of invoiceLinesByLocation) {
    let subtotal = 0
    for (const invoiceLine of invoiceLines) {
      subtotal += invoiceLine.total
    }
    subtotalByLocation.set(locationId, subtotal)
  }

  return subtotalByLocation
}

export const transformInvoiceLineByLocation = invoiceLinesByLocation => {
  const invoiceLinesByLocationClone = cloneDeep(invoiceLinesByLocation)

  invoiceLinesByLocationClone.forEach(invoiceLines => {
    ;(invoiceLines || []).forEach(invoiceLine => {
      ;(invoiceLine.invoiceFeeTaxCharges || []).forEach(invoiceFeeTaxCharge => {
        if (invoiceLine.pricedServiceType === 'On Request') {
          invoiceFeeTaxCharge.createdAt = ''
        }

        // Taxes should not have date
        if (invoiceFeeTaxCharge.chargeType === 'Tax') {
          invoiceFeeTaxCharge.createdAt = ''
        }
      })

      // Order by createdAt field first and then empty createdAt field like Tax
      ;(invoiceLine.invoiceFeeTaxCharges || []).sort((first, second) => {
        if (!first?.createdAt) {
          return 1
        }

        if (!second?.createdAt) {
          return -1
        }

        return 0
      })
    })
  })

  return invoiceLinesByLocationClone
}

export const getVacationHoldData = vacationHolds => {
  const vacationHoldsById = new Map()

  for (const vacationHold of vacationHolds) {
    const { locationIds } = vacationHold
    for (const locationId of locationIds) {
      if (!vacationHoldsById.has(locationId)) {
        vacationHoldsById.set(locationId, [vacationHold])
      } else {
        const existingVacationHolds = vacationHoldsById.get(locationId)
        if (Array.isArray(existingVacationHolds)) {
          vacationHoldsById.set(locationId, [...existingVacationHolds, vacationHold])
        }
      }
    }
  }
  for (const [locationId] of vacationHoldsById) {
    const existingVacationHolds = vacationHoldsById.get(locationId)
    if (Array.isArray(existingVacationHolds)) {
      vacationHoldsById.set(
        locationId,
        existingVacationHolds.sort((a, b) => {
          const dateATime = new Date(a.startDate).getTime()
          const dateBTime = new Date(b.startDate).getTime()
          return dateATime - dateBTime
        })
      )
    }
  }
  return vacationHoldsById
}
