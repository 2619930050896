import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import flattenDeep from 'lodash/flattenDeep'
import HHTextField from 'components/form-fields/v5/HHTextField'
import AutoComplete from 'components/common/AutoComplete'
import { MenuItem, Box, Typography, Grid } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { OPEN_CLOSE_TIME, DAYS } from 'settings/constants/generalSettings'
import T from 'T'
import { get } from 'utils/lodash'

const DisposalSiteHours = ({ disposalState = {}, setDisposalState = noop, index = 0, handleRemoval = noop }) => {
  const { disposalSiteHours } = disposalState

  const onHandleChange = (e, type) => {
    if (disposalSiteHours[index] === undefined) {
      disposalSiteHours[index] = {}
    }
    disposalSiteHours[index][type] = e.target.value
    setDisposalState({ disposalSiteHours, footerFlag: disposalState.name !== '' })
  }

  const alreadySelectedDays = flattenDeep(
    disposalSiteHours.filter((data, ind) => ind !== index && !data.deleted).map(val => val.days.map(rec => rec.id))
  )

  const handleCheckboxValue = (label, records, event) => {
    let data
    if (get(event, 'target.innerText', '') === T.ALL) {
      if (records.filter(rec => rec.id !== 'All').length > 0) {
        data = []
      } else {
        data = DAYS.filter(day => !alreadySelectedDays.includes(day.id)).map(record => ({ id: record.id, value: record.id }))
      }
    } else {
      data = records.filter(rec => rec.id !== 'All').map(inf => ({ id: inf.id, value: inf.id }))
    }

    if (disposalSiteHours[index] === undefined) {
      disposalSiteHours[index] = {}
    }
    disposalSiteHours[index].days = data
    setDisposalState({ disposalSiteHours, footerFlag: disposalState.name !== '' })
  }

  return (
    <Box mt={0.6}>
      {(disposalSiteHours[index] === undefined ||
        (disposalSiteHours[index] !== undefined && disposalSiteHours[index].deleted === undefined)) && (
        <Grid container spacing={1}>
          <Grid item mt={index === 0 && 0.5}>
            {index === 0 && (
              <Typography variant="subtitle2" mb={0.5}>
                {T.DAYS}
              </Typography>
            )}

            <AutoComplete
              backFlag
              maxHeight={280}
              isHandleCheckbox
              size="medium"
              defaultSize
              metaData={DAYS.filter(data => !alreadySelectedDays.includes(data.id))}
              label={T.DAYS}
              getByLabelText={option => option.value}
              handleCheckboxValue={handleCheckboxValue}
              checkedRecords={get(disposalSiteHours[index], 'days', [])}
              getPlaceholder={get(disposalSiteHours[index], 'days', []).length ? '' : T.DAYS}
            />
          </Grid>

          <Grid item xs={4}>
            <HHTextField
              fullWidth
              defaultSize
              label={index === 0 && `${T.OPEN} ${T.TIME}`}
              select
              value={[get(disposalSiteHours[index], 'openTime', '')]}
              onChange={e => onHandleChange(e, 'openTime')}
            >
              <MenuItem value="">Select</MenuItem>
              {OPEN_CLOSE_TIME.map(status => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </HHTextField>
          </Grid>

          <Grid item xs={4}>
            <HHTextField
              fullWidth
              defaultSize
              label={index === 0 && `${T.CLOSE} ${T.TIME}`}
              select
              value={[get(disposalSiteHours[index], 'closeTime', '')]}
              onChange={e => onHandleChange(e, 'closeTime')}
            >
              <MenuItem value="">Select</MenuItem>
              {OPEN_CLOSE_TIME.map(status => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </HHTextField>
          </Grid>

          <Grid item display="flex" alignItems="center">
            {index > 0 && <HighlightOffIcon onClick={() => handleRemoval(index, 'disposal-site-hours')} fontSize="small" />}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

DisposalSiteHours.propTypes = {
  disposalState: PropTypes.object,
  index: PropTypes.number,
  setDisposalState: PropTypes.func,
  handleRemoval: PropTypes.func,
}

export default DisposalSiteHours
