import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Tooltip } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'

const DisposalTicketTaxInfoIcon = () => {
  const theme = useTheme()
  return (
    <Tooltip
      PopperProps={{
        sx: {
          zIndex: theme.zIndex.tooltip + 10,
        },
      }}
      arrow
      title="Marking the disposal ticket as taxable will apply the taxes associated with the service."
    >
      <Stack alignItems="center" justifyContent="center">
        <InfoOutlinedIcon color="action" fontSize="small" />
      </Stack>
    </Tooltip>
  )
}

export default DisposalTicketTaxInfoIcon
