import { takeEvery, all, call } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions } from '../actions/map'

import endpoint from './endpoint'

export const getAddressList = ({ body }) => doPost({ url: endpoint.map.suggest, body, showLoader: false })
export const getMapImage = ({ body }) => doPost({ url: endpoint.map.image, body, showLoader: false })

export function* getAddressListSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getAddressList, { body })
    const { suggestions } = res
    if (typeof onSuccess === 'function') {
      onSuccess(suggestions)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess([])
    }
  }
}

export function* getMapImageSaga({ payload }) {
  const { body, onSuccess } = payload
  try {
    const { data: res } = yield call(getMapImage, { body })
    const { mapUrl } = res
    if (typeof onSuccess === 'function') {
      onSuccess(mapUrl)
    }
  } catch (err) {
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  }
}

export default function* Map() {
  yield all([takeEvery(actions.GET.ADDRESS_SUGGEST_LIST, getAddressListSaga), takeEvery(actions.GET.MAP_IMAGE, getMapImageSaga)])
}
