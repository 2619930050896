import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    achFailed: build.mutation({
      query: ({ paymentNumber }) => ({
        url: `/api/v1/billing/payment/${paymentNumber}/reverse`,
        method: 'POST',
        body: { failed: true },
      }),
    }),
  }),
})

export const { useAchFailedMutation } = extendedApi
