import React, { useEffect } from 'react'

import capitalize from 'lodash/capitalize'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { SwipeableDrawer, IconButton, ListItem, ListItemText, useTheme, useMediaQuery } from '@mui/material'
import { get } from 'utils/lodash'
import { isBillingWOInvoiceStatusFeatureAvailable } from 'data/launch-darkly/featureSelectors'
import { setShowBillingSideNav } from 'slices/billing/BillingSideNavSlice'

import T from 'T'
import MenuGroup from './MenuGroup'
import { GROUPS_MENU, WORK_ORDERS_MENU, INVOICES_MENU, PAYMENTS_MENU } from './MenuItems'
import { BILLING_SIDE_NAV_DRAWER_WIDTH, MIN_SCREEN_SIZE } from './settings'

const BillingSideNav = () => {
  const flags = useFlags()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isBillingWOInvoiceStatusAvailable = isBillingWOInvoiceStatusFeatureAvailable({ flags })
  const showBillingSideNav = useSelector(state => get(state, 'BillingSideNav.showBillingSideNav'), shallowEqual)
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const handleDrawerOpen = () => dispatch(setShowBillingSideNav(true))
  const handleDrawerClose = () => dispatch(setShowBillingSideNav(false))

  useEffect(() => {
    if (window?.innerWidth < MIN_SCREEN_SIZE) {
      handleDrawerClose()
    }
  }, [])

  return (
    <SwipeableDrawer
      className="no-print"
      sx={{
        width: BILLING_SIDE_NAV_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: BILLING_SIDE_NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
          top: 56,
        },
      }}
      variant={isTabletOrMobile ? 'temporary' : 'persistent'}
      anchor="left"
      open={showBillingSideNav}
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
    >
      <ListItem
        sx={{ height: 56, borderBottom: 1, borderColor: 'divider' }}
        secondaryAction={
          <IconButton edge="end" onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        }
      >
        <ListItemText primary={T.BILLING} primaryTypographyProps={{ variant: 'h5', fontWeight: 500, color: 'textSecondary' }} />
      </ListItem>

      <MenuGroup groupName={T.GROUPS} menuItems={GROUPS_MENU} />
      {isBillingWOInvoiceStatusAvailable && <MenuGroup groupName={capitalize(T.WORK_ORDERS)} menuItems={WORK_ORDERS_MENU} />}
      <MenuGroup groupName={T.INVOICES} menuItems={INVOICES_MENU} />
      <MenuGroup groupName={T.PAYMENTS} menuItems={PAYMENTS_MENU} />
    </SwipeableDrawer>
  )
}

export default BillingSideNav
