import React from 'react'

import { Box, Typography } from '@mui/material'

import { memo } from 'utils/react'

const AutopayTermsConditions = () => {
  return (
    <Box
      sx={{
        '& .MuiTypography-root': {
          padding: '3px',
          fontSize: '15px',
          wordWrap: 'break-word',
          lineHeight: '24px',
          color: 'text.primary',
        },
      }}
    >
      <Typography>
        By enrolling in this recurring payment program, you authorize Hauler Hero Inc. (<b>"Hauler Hero"</b>) acting on behalf of the vendor
        listed on your invoice (the <b>"Vendor"</b>): (a) to initiate recurring automated clearing house (ACH) debit entries or debit card
        payments from the checking or savings account you specify, or (b) to initiate recurring charges from your specified credit card.
      </Typography>

      <Typography mt={2}>
        The amount debited from your checking or savings account or charged to your credit card every month will be the then current balance
        on your account. Your current balance is the amount on your most recent invoice, plus additional charges billed to your account
        after your invoice was issued, less credits or payments posted to your account after your invoice was issued. Once your enrollment
        is processed, all payments will be automatically withdrawn from your specified checking or savings account or charged to the
        designated credit or debit card on the invoice due date, unless you terminate your authorization in the manner described herein.
      </Typography>

      <Typography mt={2}>
        You agree to be bound by any rules your financial institution requires for pre-authorized electronic funds transfers and/or that
        your debit or credit card issuer requires for pre-authorized debit or credit card transactions. You are responsible for all fees
        charged by your financial institution associated with the pre-authorized payment option.
      </Typography>

      <Typography mt={2}>YOU HAVE THE RIGHT TO TERMINATE YOUR AUTHORIZATION AT ANY TIME BY TURNING OFF AUTOPAY.</Typography>

      <Typography mt={2}>
        You must update all changes to your checking/savings account or credit/debit card information by logging into your customer portal
        account. If you do not update your checking/savings account or credit/debit card information and Hauler Hero is unable to charge
        your credit card or withdraw funds from your debit card, checking account, or savings account for the amount due on your invoice,
        you may be subject to applicable late fees, returned item charges and any fees or charges assessed by your financial institution.
      </Typography>

      <Typography mt={2}>
        Your Vendor will make your invoice available to you by PDF or other means particular to your Vendor's business processes. You can
        access your invoice by logging into your customer portal account.
      </Typography>

      <Typography mt={2}>
        HAULER HERO AND VENDOR SHALL BEAR NO LIABILITY OR RESPONSIBILITY FOR ANY LOSSES OF ANY KIND THAT YOU MAY INCUR AS A RESULT OF A
        PAYMENT MADE ON ITEMS INCORRECTLY BILLED OR FOR ANY DELAY IN THE ACTUAL DATE ON WHICH YOUR ACCOUNT IS DEBITED OR YOUR CREDIT CARD IS
        CHARGED.
      </Typography>

      <Typography mt={2}>
        Hauler Hero and Vendor each reserve the right to change these terms or terminate this automatic payment program at any time. Notice
        may be given on or with your invoice or by other methods.
      </Typography>

      <Typography mt={2}>
        These terms do not in any way terminate, amend or modify other terms, agreements or policies that apply to any services you receive
        or other agreements you may have with Vendor.
      </Typography>
    </Box>
  )
}

export default memo(AutopayTermsConditions)
