import cloneDeep from 'lodash/cloneDeep'

import { get } from 'utils/lodash'
import { fixContactLocationAssociation } from 'utils/form-body'
import { isTaxExempt } from 'data/customer/billingDetailsSelector'
import { getAssociations } from 'components/customers/edit/utils'

const INITIAL_PO_STATE = { customerPurchaseOrder: false, po: '', maxPoAmount: '' }
const INITIAL_TAX_STATE = { taxExemptEnable: false, taxExempt: '' }

export const getExistingCustomerInfo = customerDetails => {
  const exitingContacts = cloneDeep(get(customerDetails, 'contacts', [])).map(contact => {
    const { associatedLocations } = getAssociations(customerDetails, contact.id)
    return { ...contact, associatedLocations, associatedWithLocation: associatedLocations.length > 0 }
  })

  return {
    contacts: fixContactLocationAssociation(exitingContacts),
    tags: get(customerDetails, 'tags', []).map(tag => tag.id),
    csr: get(customerDetails, 'csr.id', null),
  }
}

export const getPOAndTaxInfo = customerDetails => {
  const accountBilling = get(customerDetails, 'accountBilling', {})
  const po = get(accountBilling, 'po', '')
  const maxPoAmount = get(accountBilling, 'maxPoAmount', '')
  const taxExempt = accountBilling?.taxExempt
  let poInfo = INITIAL_PO_STATE
  let taxInfo = INITIAL_TAX_STATE

  if (po || maxPoAmount) {
    poInfo = { customerPurchaseOrder: true, po, maxPoAmount }
  }

  // Empty string is a valid case
  if (isTaxExempt(taxExempt)) {
    taxInfo = { taxExemptEnable: true, taxExempt }
  }

  return { ...poInfo, ...taxInfo }
}

export const getEditAccountModel = customerDetails => ({
  id: get(customerDetails, 'id'),
  accountName: get(customerDetails, 'accountName', ''),
  billingAddress: get(customerDetails, 'billingAddress', {}),
  tags: get(customerDetails, 'tags', []),
  accountBilling: {
    id: get(customerDetails, 'accountBilling.id'),
    billingProfileId: get(customerDetails, 'accountBilling.billingProfile.id'),
    ...getPOAndTaxInfo(customerDetails),
  },
})
