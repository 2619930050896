import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { Box, Grid } from '@mui/material'

import { CommonTextLabel } from 'components/common'
import { ACH_ACCOUNT_TYPE, PAYMENT_METHODS } from 'settings/constants/payment'
import { HHFormTextField, HHFormSelectField } from 'components/form-fields/v5'

import T from 'T'
import RenderPaymentIframe from 'components/customer-details/content/payment-details/common/RenderPaymentIframe'

const { ACH } = PAYMENT_METHODS

const RenderACH = ({ onNewCardTokenChange }) => {
  const { control } = useFormContext()

  return (
    <>
      <Box>
        <CommonTextLabel title={T.ROUTING_ACCOUNT_NUMBER} isRequired />
        <RenderPaymentIframe type={ACH} callback={onNewCardTokenChange} />

        <Grid mt={1} container spacing={2}>
          <Grid item xs={6}>
            <HHFormTextField control={control} name="cardHolderName" fullWidth label={T.NAME_ON_ACCOUNT} placeholder="John Doe" />
          </Grid>
          <Grid item xs={6}>
            <HHFormSelectField
              name="accountType"
              control={control}
              label={T.BANK_ACCOUNT_TYPE}
              options={ACH_ACCOUNT_TYPE}
              getOptionValue={option => option}
              getOptionLabel={option => option}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

RenderACH.propTypes = {
  onNewCardTokenChange: PropTypes.func.isRequired,
}

export default RenderACH
