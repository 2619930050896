import { get } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { RENTAL_FEES_BUSINESS_LINES_TABS } from 'components/pricing/RentalFees/common/settings'
import { RENTAL_FEE_ACCOUNTS_TAB_ID } from 'components/pricing/RentalFees/details/settings'

const rentalFeesSlice = createSlice({
  name: 'rentalFees',
  initialState: {
    isCreateEditRentalFeeDialogOpen: false,
    filters: {
      businessLine: RENTAL_FEES_BUSINESS_LINES_TABS.ALL_BUSINESS_LINES,
      hideArchived: false,
    },
    archivedRestoredRentalFee: undefined,
    rentalFeeTab: RENTAL_FEE_ACCOUNTS_TAB_ID,
  },
  reducers: {
    setIsCreateEditRentalFeeDialogOpen: (state, action) => {
      state.isCreateEditRentalFeeDialogOpen = action.payload
    },
    setSelectedRentalFeesBusinessLine: (state, action) => {
      state.filters.businessLine = action.payload
    },
    setHideArchivedRentalFees: (state, action) => {
      state.filters.hideArchived = action.payload
    },
    setArchivedRestoredRentalFee: (state, action) => {
      state.archivedRestoredRentalFee = action.payload
    },
    setRentalFeeTab: (state, action) => {
      state.rentalFeeTab = action.payload
    },
  },
})

export const {
  setIsCreateEditRentalFeeDialogOpen,
  setSelectedRentalFeesBusinessLine,
  setHideArchivedRentalFees,
  setArchivedRestoredRentalFee,
  setRentalFeeTab,
} = rentalFeesSlice.actions

export const selectIsCreateEditRentalFeeDialogOpen = s => get(s, 'rentalFees.isCreateEditRentalFeeDialogOpen', false)
export const selectSelectedRentalFeesBusinessLine = s => get(s, 'rentalFees.filters.businessLine')
export const selectHideArchivedRentalFees = s => get(s, 'rentalFees.filters.hideArchived')
export const selectArchivedRestoredRentalFee = s => get(s, 'rentalFees.archivedRestoredRentalFee')
export const selectRentalFeeTab = s => get(s, 'rentalFees.rentalFeeTab')

export default rentalFeesSlice.reducer
