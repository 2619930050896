import React from 'react'
import Box from '@mui/material/Box'
import CardInformationInvalidAlert from 'components/customer-details/content/payments/common/CardInformationInvalidAlert'
import { CommonTextLabel } from 'components/common'
import T from 'T'
import Grid from '@mui/material/Grid'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { ACH_ACCOUNT_TYPE, PAYMENT_METHODS } from 'settings/constants/payment'
import SelectItem from 'components/form-fields/v5/SelectItem'
import PropTypes from 'prop-types'
import RenderPaymentIframe from './common/RenderPaymentIframe'

const { ACH } = PAYMENT_METHODS

const RenderACHFields = ({ showInvalidFormAlert, cardHolderName, onChange, onNewCardTokenChange, accountType }) => {
  return (
    <Box>
      {showInvalidFormAlert && <CardInformationInvalidAlert isCreditCardForm={false} />}
      <CommonTextLabel title={T.ROUTING_ACCOUNT_NUMBER} isRequired />
      <RenderPaymentIframe type={ACH} callback={onNewCardTokenChange} />

      <Grid mt={1} container spacing={2}>
        <Grid item xs={6}>
          <HHTextField
            label={T.NAME_ON_ACCOUNT}
            required
            rules={{ required: true }}
            placeholder="John Doe"
            fullWidth
            value={cardHolderName}
            onChange={event => onChange('cardHolderName', event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <HHTextField
            label={T.BANK_ACCOUNT_TYPE}
            fullWidth
            select
            value={[accountType]}
            onChange={event => onChange('accountType', event.target.value)}
          >
            {ACH_ACCOUNT_TYPE.map(achType => (
              <SelectItem value={achType}>{achType}</SelectItem>
            ))}
          </HHTextField>
        </Grid>
      </Grid>
    </Box>
  )
}

RenderACHFields.propTypes = {
  showInvalidFormAlert: PropTypes.bool.isRequired,
  cardHolderName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewCardTokenChange: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
}

export default RenderACHFields
