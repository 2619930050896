import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'
import { DialogContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetAccountInvoicesQuery } from 'api/accounts/getAccountInvoices'
import { useAchFailedMutation } from 'api/billing/achFailed'
import { getCustomerDetails } from 'middleware/actions/customers'
import {
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from 'api/cacheTagTypes'
import { isCustomerDetailsPage } from 'router/routes'

import T from 'T'
import api from 'api'
import CancelButton from 'components/buttons/CancelButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import Loader from 'components/common/loader'

import ACHFailedDialogContent from './ACHFailedDialogContent'
import { getPaidInvoicesWIthAppliedAmount } from '../transfer-payments/helper'

const ACHFailedDialog = ({ isOpen = false, customerDetails = null, payment = {}, onClose = noop }) => {
  const dispatch = useDispatch()
  const [achFailed, { isLoading: isACHFailedLoading }] = useAchFailedMutation()
  const [getSourceAccountInvoices, { data: sourceInvoices, isFetching: isLoadingSourceInvoices }] = useLazyGetAccountInvoicesQuery()

  const sourceAccountDetails = {
    accountId: get(customerDetails, 'id'),
    accountName: get(customerDetails, 'accountName'),
    accountNumber: get(customerDetails, 'accountNumber'),
    customerCreditBalanceCents: get(customerDetails, 'customerCreditBalanceCents', 0),
    billingProfileDetails: get(customerDetails, 'accountBilling.billingProfile', {}),
  }

  const { transactionAmountCents, invoices = [] } = payment || {}
  const paidInvoices = getPaidInvoicesWIthAppliedAmount(get(sourceInvoices, 'invoices', []), invoices)

  const handleACHFailedSuccess = () => {
    const { accountId } = sourceAccountDetails
    if (isCustomerDetailsPage()) {
      dispatch(getCustomerDetails({ accountId }))
    }
    dispatch(
      api.util.invalidateTags([
        CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
        CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
        { type: CACHE_TAG_ACCOUNT_INVOICE_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_PAYMENT_LIST, id: accountId },
        { type: CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST, id: accountId },
        CACHE_TAG_ACCOUNT_LIST,
        { type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId },
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ])
    )
  }

  const handleClose = () => onClose()

  const handleConfirm = () => {
    achFailed({
      paymentNumber: payment.paymentNumber,
    })
      .unwrap()
      .then(() => {
        toast.info(T.PAYMENT_ACH_FAILED_MSG, {
          hideProgressBar: false,
          autoClose: 4000,
          onClose: () => handleACHFailedSuccess(),
        })

        handleClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      getSourceAccountInvoices({ accountId: sourceAccountDetails.accountId }).unwrap().catch(handleError)
    }
  }, [isOpen])

  return (
    <>
      {isLoadingSourceInvoices && <Loader />}
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose} disableScrollLock>
        <HHDialogTitle title={T.ACH_PAYMENT_FAILED} onClose={handleClose} />
        <DialogContent>
          <ACHFailedDialogContent
            transactionAmountCents={transactionAmountCents}
            payment={payment}
            paidInvoices={paidInvoices}
            sourceAccountDetails={sourceAccountDetails}
          />
        </DialogContent>
        <HHDialogActions sx={{ px: 2, py: 1, justifyContent: 'space-between', border: '1px solid', borderColor: 'border.light' }}>
          <CancelButton size="medium" onClick={handleClose} />
          <LoadingButton loading={isACHFailedLoading} size="small" variant="contained" onClick={handleConfirm}>
            {T.CONFIRM}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

ACHFailedDialog.propTypes = {
  isOpen: PropTypes.bool,
  customerDetails: PropTypes.object,
  payment: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default ACHFailedDialog
