import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { Box, useTheme } from '@mui/material'

import { HHAlert } from 'components/common/HHAlert'
import RenderAccountCell from './RenderAccountCell'

const AccountsDataGrid = ({ rows = [], selectionModel = [], onSelectionModelChange }) => {
  const theme = useTheme()

  const getRowHeight = useCallback(() => 'auto', [])

  const getRowId = useCallback(({ accountId }) => accountId, [])

  return (
    <DataGridPro
      rows={rows}
      hideFooter
      columnHeaderHeight={0}
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: RenderAccountCell,
          sortable: false,
          resizable: false,
        },
      ]}
      disableMultipleRowSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
          display: 'block',
        },
        '& .MuiDataGrid-columnHeader': {
          padding: '0',
        },
        '& .MuiDataGrid-overlayWrapperInner': {
          overflow: 'auto',
        },
      }}
      slots={{
        noRowsOverlay: () => (
          <Box sx={{ pr: 1 }}>
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              No accounts
            </HHAlert>
          </Box>
        ),
      }}
    />
  )
}

AccountsDataGrid.propTypes = {
  rows: PropTypes.array,
  selectionModel: PropTypes.array,
  onSelectionModelChange: PropTypes.func.isRequired,
}

export default AccountsDataGrid
