import React from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { calculateDaysDiff } from 'utils/date'

import T from 'T'
import CommonDrawer from 'components/common/CommonDrawer'
import CommonSelect from 'components/common/CommonSelect'
import FooterButton from 'components/buttons/FooterButton'

import CommonBody from './CommonBody'

import './style.scss'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Divider, Stack } from '@mui/material'
import Box from '@mui/material/Box'

const STATUS_OPTIONS = ['Open', 'In Progress', 'Closed']

const CallsFollowUpDrawer = ({
  isOpenDrawer,
  isFollowUp,
  className,
  details,
  disabledProceed,
  onChange,
  onProceed,
  onClose,
  addAdditionalFollowUps,
}) => {
  const days = calculateDaysDiff(get(details, 'createdAt', ''))
  const status = get(details, 'status', 'open')

  const isContentEditingDisabled = isFollowUp && status.toLowerCase() === 'closed'

  return (
    <CommonDrawer isOpen={isOpenDrawer} className={`customer-details-drawer ${className}`} onChange={(event, isOpen) => onClose(isOpen)}>
      <section>
        <HHDialogTitle
          title={
            <Stack flexDirection="row" alignItems="center">
              <CommonSelect
                className={`${status.toLowerCase()} followUp-status`}
                value={startCase(status)}
                options={STATUS_OPTIONS}
                onChange={e => onChange('status', e.target.value)}
              />
              <Box component="span" ml={2} className="day-text">{`${days} ${days > 1 ? 'Days' : 'Day'}`}</Box>
            </Stack>
          }
          TitleTypographyProps={{
            variant: 'h6',
          }}
          onClose={() => onClose(false)}
        />
        <Divider />
        <div className={`content ${isContentEditingDisabled ? 'pointer-events-none' : 'editable'}`}>
          <CommonBody
            isOpenDrawer={isOpenDrawer}
            details={details}
            isFollowUp={isFollowUp}
            onChange={onChange}
            addAdditionalFollowUps={addAdditionalFollowUps}
          />
        </div>

        <Box position="fixed" className="footer common-margin  bg-white">
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <FooterButton
              leftButtonTitle={T.CANCEL}
              rightButtonTitle={T.SAVE}
              onClose={() => onClose(false)}
              onProceed={onProceed}
              disabledProceed={disabledProceed}
            />
          </Stack>
        </Box>
      </section>
    </CommonDrawer>
  )
}

CallsFollowUpDrawer.defaultProps = {
  isOpenDrawer: false,
  className: '',
  details: null,
  isFollowUp: false,
  disabledProceed: false,
  addAdditionalFollowUps: null,
}

CallsFollowUpDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool,
  className: PropTypes.string,
  details: PropTypes.object,
  isFollowUp: PropTypes.bool,
  disabledProceed: PropTypes.bool,
  addAdditionalFollowUps: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CallsFollowUpDrawer)
