import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    createPermit: build.mutation({
      query: body => ({
        url: '/api/v1/core/settings/permit/',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useCreatePermitMutation } = extendedApi
