import React from 'react'
import PropTypes from 'prop-types'
import HHAccordion from 'components/common/HHAccordion'
import { Box, Grid, useTheme } from '@mui/material'
import T from 'T'
import { get } from 'lodash'
import { HHAlert } from 'components/common/HHAlert'
import ConfiguredServiceCard from 'components/locations/LocationsDataGrid/RenderLocationDetailPanelContent/ConfiguredServiceCard'
import SkeletonConfiguredService from 'components/locations/LocationsDataGrid/RenderLocationDetailPanelContent/SkeletonConfiguredService'

const RenderConfiguredServices = ({ services, loading = false }) => {
  const servicesCount = get(services, 'length', 0)
  const hasServices = servicesCount > 0
  const theme = useTheme()
  return (
    <HHAccordion isExpandCollapseHeightSame showActionIcon={false} summary={T.SERVICES} withNewColors isExpanded>
      <Box width="100%" p={2}>
        <Grid container spacing={2}>
          {!loading && !hasServices && (
            <Box pt={2} pl={2} width="100%">
              <HHAlert severity="info" borderColor={theme.palette.info.main}>
                There are no services for this location
              </HHAlert>
            </Box>
          )}
          {loading && <SkeletonConfiguredService />}
          {!loading &&
            hasServices &&
            services.map(service => {
              return <ConfiguredServiceCard service={service} />
            })}
        </Grid>
      </Box>
    </HHAccordion>
  )
}

RenderConfiguredServices.propTypes = {
  loading: PropTypes.bool,
  services: PropTypes.array,
}

export default RenderConfiguredServices
