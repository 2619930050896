import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { Menu, MenuItem, Tooltip } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { downloadInvoiceBlobListInChunks, downloadInvoiceGroupBatch } from 'utils/file'
import { handleError } from 'utils/error'
import { getInvoicesPDFStatus, getDraftAndNonDraftInvoicesBySelectedRows, isUnPaidInvoice, isDraftInvoice } from 'utils/billing'
import { putIsLoading } from 'middleware/actions/response'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import { useRegenerateInvoiceListMutation } from 'api/billing/regenerateInvoiceList'
import { useInvoiceBulkMarkSentMutation } from 'api/billing/invoiceBulkMarkSent'
import { useCreateInvoiceGroupBatchMutation } from 'api/groups/invoice/createInvoiceGroupBatch'
import { CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS, CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA } from 'api/cacheTagTypes'
import { setBulkPrintDialogState } from 'slices/groups/InvoicePrintableViewSlice'

import T from 'T'
import api from 'api'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import InvoiceSendFailureConfirmation from 'containers/billing/InvoiceSendFailureConfirmation'
import ConfirmInvoiceBulkActionDialog from 'containers/billing/ConfirmInvoiceBulkActionDialog'
import { setConfirmInvoiceBulkActionDialog } from 'slices/billing/invoicesSlice'
import {
  MARK_AS_SENT_BULK_ACTION,
  REGENERATION_BULK_ACTION,
  SEND_INVOICES_BULK_ACTION,
  SEND_UNPAID_INVOICES_BULK_ACTION,
} from 'containers/billing/settings/invoices'
import AddEditInvoiceGroupDialog from './AddEditInvoiceGroupDialog'
import RemoveInvoicesFromGroupDialog from './add-remove/RemoveInvoicesFromGroupDialog'

const SHOW_DELETE_GROUP_OPTION = false

const InvoiceGroupActionMenu = ({ anchorEl, selectedGroup = {}, onClose }) => {
  const dispatch = useDispatch()
  const [sendInvoiceList, { isLoading: isSendInvoiceListLoading }] = useSendInvoiceListMutation()
  const [regenerateInvoiceList, { isLoading: isRegenerateInvoiceListLoading }] = useRegenerateInvoiceListMutation()
  const [invoiceBulkMarkSent, { isLoading: isBulkMarkSentLoading }] = useInvoiceBulkMarkSentMutation()
  const [createInvoiceGroupBatch, { isLoading: isCreateInvoiceGroupBatchLoading }] = useCreateInvoiceGroupBatchMutation()

  const isLoading = isSendInvoiceListLoading || isRegenerateInvoiceListLoading || isBulkMarkSentLoading || isCreateInvoiceGroupBatchLoading
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  const [isOpenConfirmEmailDialog, setIsOpenConfirmEmailDialog] = useState(false)
  const [isOpenAddEditInvoiceGroupDialog, setIsOpenAddEditInvoiceGroupDialog] = useState(false)
  const [isOpenSendInvoiceConfirmation, setIsOpenSendInvoiceConfirmation] = useState({ invoiceNumbers: [], isOpen: false })
  const [isOpenRemoveInvoicesFromGroupDialog, setIsOpenRemoveInvoicesFromGroupDialog] = useState(false)
  const businessId = get(selectedGroup, 'businessId')
  const invoiceGroupId = get(selectedGroup, 'id')
  const batchFileLocation = get(selectedGroup, 'batchFileLocation')
  const invoices = get(selectedGroup, 'invoices', [])
  const isEmptyGroup = invoices.length === 0
  const listHasDraftInvoice = invoices.find(({ status }) => isDraftInvoice(status))
  const listHasUnpaidInvoice = invoices.find(({ status }) => isUnPaidInvoice(status))

  const handleClose = () => {
    dispatch(putIsLoading(false))
    onClose()
  }

  const handleDeleteGroupConfirm = () => {
    setIsOpenConfirmDeleteDialog(false)
    handleClose()
  }

  const refetchInvoiceGroupDetails = () =>
    setTimeout(() => {
      dispatch(
        api.util.invalidateTags([
          { type: CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS, id: invoiceGroupId },
          { type: CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA, id: invoiceGroupId },
        ])
      )
    }, 1000)

  const handleDownloadInvoiceListPDF = () => {
    if (isEmptyGroup) return
    const invoiceNumberList = invoices.map(({ invoiceNumber }) => invoiceNumber)
    downloadInvoiceBlobListInChunks(invoiceNumberList, businessId)
    handleClose()
  }

  const handleSendEmailConfirmation = () => setIsOpenConfirmEmailDialog(true)
  const handleCloseSendEmailConfirmation = () => {
    setIsOpenConfirmEmailDialog(false)
  }

  const handleSendEmail = async () => {
    const { draftInvoices, nonDraftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows(invoices, 'invoiceNumber')
    const { withPDF, withoutPDF } = await getInvoicesPDFStatus(draftInvoices)
    const invoicesWithPDF = nonDraftInvoices.concat(withPDF)
    const hasInvoicesWithoutPDF = withoutPDF.length > 0

    if (invoicesWithPDF.length) {
      sendInvoiceList({ invoiceNumbers: invoicesWithPDF })
        .unwrap()
        .then(() => {
          if (hasInvoicesWithoutPDF) setIsOpenSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
          else {
            dispatch(
              setConfirmInvoiceBulkActionDialog({
                open: true,
                action: SEND_INVOICES_BULK_ACTION,
                invoiceCount: invoicesWithPDF.length,
              })
            )
          }

          if (draftInvoices.length) refetchInvoiceGroupDetails()
        })
        .catch(handleError)
        .finally(handleCloseSendEmailConfirmation)
    } else if (hasInvoicesWithoutPDF) {
      setIsOpenSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
      handleCloseSendEmailConfirmation()
    }
  }

  const handleSendEmailUnpaidInvoices = () => {
    const unpaidInvoiceNumbers = invoices.filter(({ status }) => isUnPaidInvoice(status)).map(({ invoiceNumber }) => invoiceNumber)
    sendInvoiceList({ invoiceNumbers: unpaidInvoiceNumbers })
      .unwrap()
      .then(() => {
        dispatch(
          setConfirmInvoiceBulkActionDialog({
            open: true,
            action: SEND_UNPAID_INVOICES_BULK_ACTION,
            invoiceCount: unpaidInvoiceNumbers.length,
          })
        )
      })
      .catch(e => {
        handleError(e)
        handleClose()
      })
  }

  const handleRegenerateDraftInvoices = () => {
    const { draftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows(invoices)
    regenerateInvoiceList({ invoiceIds: draftInvoices })
      .unwrap()
      .then(() =>
        dispatch(
          setConfirmInvoiceBulkActionDialog({
            open: true,
            action: REGENERATION_BULK_ACTION,
            invoiceCount: draftInvoices.length,
          })
        )
      )
      .catch(e => {
        handleError(e)
        handleClose()
      })
  }

  const handleBulkMarkSentDraftInvoices = () => {
    const { draftInvoices } = getDraftAndNonDraftInvoicesBySelectedRows(invoices)
    invoiceBulkMarkSent({ invoiceIds: draftInvoices })
      .unwrap()
      .then(response => {
        if (get(response, 'updatedIds', []).length) {
          dispatch(
            setConfirmInvoiceBulkActionDialog({
              open: true,
              action: MARK_AS_SENT_BULK_ACTION,
              invoiceCount: draftInvoices.length,
            })
          )
          refetchInvoiceGroupDetails()
        }
        if (get(response, 'failedIds', []).length) {
          toast.error(T.FAILED)
        }
      })
      .catch(e => {
        handleError(e)
        handleClose()
      })
  }

  const handleSendToBMS = () => {
    createInvoiceGroupBatch({ invoiceGroupId })
      .unwrap()
      .then(() => toast.success(T.GENERATING_BULK_PRINT_DATA))
      .catch(handleError)
      .finally(handleClose)
  }

  const handleClickInvoicePrintableView = () => {
    dispatch(setBulkPrintDialogState({ isOpen: true, groupId: invoiceGroupId }))
    handleClose()
  }

  const handleDownloadGroupData = () => {
    downloadInvoiceGroupBatch(businessId, invoiceGroupId, get(selectedGroup, 'groupName'))
    handleClose()
  }

  const handleCloseConfirmInvoiceBulkActionDialog = () => {
    dispatch(
      setConfirmInvoiceBulkActionDialog({
        open: false,
        action: '',
        invoiceCount: '',
      })
    )
    handleClose()
    refetchInvoiceGroupDetails()
  }

  useEffect(() => {
    dispatch(putIsLoading(isLoading))
  }, [isLoading])

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem onClick={() => setIsOpenAddEditInvoiceGroupDialog(true)}>{T.EDIT_GROUP_NAME}</MenuItem>
        <Tooltip title={T.EMPTY_INVOICE_GROUP} disableHoverListener={!isEmptyGroup} disableInteractive>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleDownloadInvoiceListPDF}>
              {T.DOWNLOAD_ALL_INVOICES}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_INVOICE_GROUP} disableHoverListener={!isEmptyGroup} disableInteractive>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleSendEmailConfirmation}>
              {T.EMAIL_INVOICES}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={isEmptyGroup ? T.EMPTY_INVOICE_GROUP : T.NO_UNPAID_INVOICE_IN_INVOICE_GROUP}
          disableHoverListener={listHasUnpaidInvoice}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!listHasUnpaidInvoice} onClick={handleSendEmailUnpaidInvoices}>
              {T.EMAIL_UNPAID_INVOICES}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={isEmptyGroup ? T.EMPTY_INVOICE_GROUP : T.NO_DRAFT_INVOICE_IN_INVOICE_GROUP}
          disableHoverListener={listHasDraftInvoice}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!listHasDraftInvoice} onClick={handleRegenerateDraftInvoices}>
              {T.REGENERATE_DRAFT_INVOICES}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={isEmptyGroup ? T.EMPTY_INVOICE_GROUP : T.NO_DRAFT_INVOICE_IN_INVOICE_GROUP}
          disableHoverListener={listHasDraftInvoice}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!listHasDraftInvoice} onClick={handleBulkMarkSentDraftInvoices}>
              {T.MARK_DRAFT_AS_SENT}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_INVOICE_GROUP} disableHoverListener={!isEmptyGroup} disableInteractive>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={handleSendToBMS}>
              {T.GENERATE_BULK_PRINT_DATA}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={isEmptyGroup ? T.EMPTY_INVOICE_GROUP : T.NO__BULK_PRINT_DATA_AVAILABLE}
          disableHoverListener={batchFileLocation}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!batchFileLocation} onClick={handleClickInvoicePrintableView}>
              {T.PRINTABLE_VIEW}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={isEmptyGroup ? T.EMPTY_INVOICE_GROUP : T.NO__BULK_PRINT_DATA_AVAILABLE}
          disableHoverListener={batchFileLocation}
          disableInteractive
        >
          <span>
            <MenuItem disabled={!batchFileLocation} onClick={handleDownloadGroupData}>
              {T.DOWNLOAD_BULK_PRINT_DATA}
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip title={T.EMPTY_INVOICE_GROUP} disableHoverListener={!isEmptyGroup} disableInteractive>
          <span>
            <MenuItem disabled={isEmptyGroup} onClick={() => setIsOpenRemoveInvoicesFromGroupDialog(true)}>
              {T.REMOVE_INVOICES}
            </MenuItem>
          </span>
        </Tooltip>
        {SHOW_DELETE_GROUP_OPTION && (
          <MenuItem sx={{ color: 'error.main' }} onClick={() => setIsOpenConfirmDeleteDialog(true)}>
            {T.DELETE}
          </MenuItem>
        )}
      </Menu>
      <AddEditInvoiceGroupDialog
        isOpen={isOpenAddEditInvoiceGroupDialog}
        existingGroup={selectedGroup}
        onClose={() => {
          setIsOpenAddEditInvoiceGroupDialog(false)
          handleClose()
        }}
      />
      <HHConfirmDialog
        isOpen={isOpenConfirmDeleteDialog}
        confirmTitle="Delete group"
        confirmDescription="Are you sure you want to delete this group?"
        onConfirm={handleDeleteGroupConfirm}
        onClose={() => {
          setIsOpenConfirmDeleteDialog(false)
          handleClose()
        }}
      />
      <InvoiceSendFailureConfirmation
        isOpen={isOpenSendInvoiceConfirmation.isOpen}
        invoices={isOpenSendInvoiceConfirmation.invoiceNumbers}
        onClose={() => {
          setIsOpenSendInvoiceConfirmation({ isOpen: false, invoiceNumbers: [] })
          handleClose()
        }}
      />
      <ConfirmInvoiceBulkActionDialog
        onConfirm={handleCloseConfirmInvoiceBulkActionDialog}
        onClose={handleCloseConfirmInvoiceBulkActionDialog}
      />
      <HHConfirmDialog
        DialogProps={{ maxWidth: 'xs', fullWidth: true }}
        isOpen={isOpenConfirmEmailDialog}
        confirmTitle="E-mail all invoices"
        confirmDescription="This will e-mail invoices with any status, Paid or Unpaid, to all customers in this group. Do you want to proceed?"
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary' }}
        onConfirm={handleSendEmail}
        onClose={handleCloseSendEmailConfirmation}
      />
      <RemoveInvoicesFromGroupDialog
        isOpen={isOpenRemoveInvoicesFromGroupDialog}
        groupId={invoiceGroupId}
        invoices={invoices}
        onClose={() => {
          setIsOpenRemoveInvoicesFromGroupDialog(false)
          handleClose()
        }}
      />
    </>
  )
}

InvoiceGroupActionMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  selectedGroup: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default memo(InvoiceGroupActionMenu)
