import React from 'react'
import PropTypes from 'prop-types'
import { Chip, ListItem, ListItemAvatar, ListItemText, Typography, useTheme, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import T from 'T'
import { grey } from '@mui/material/colors'
import { getMetadataRoutes } from '../../../data/route/routeMetadataSelector'
import MultiSelectItem from '../../form-fields/v5/MultiSelectItem'
import PopupPaper from '../../form-fields/v5/PopupPaper'
import { HHFormAutocompleteField } from '../../form-fields/v5'
import RouteAvatar from '../../route-manager/RouteDetailsModal/RouteAvatar/RouteAvatar'
import { isValidHex } from '../../../utils/validations'

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => !['bgcolor', 'withMaxWidth', 'deleteColor'].includes(prop),
})(({ withMaxWidth = false, bgcolor, deleteColor }) => ({
  '&.MuiAutocomplete-tag': {
    ...(withMaxWidth && { maxWidth: '50%' }),
  },
  backgroundColor: bgcolor,
  '.MuiChip-deleteIcon': {
    fill: deleteColor,
    fillOpacity: 0.45,
  },
}))

const RoutesAutocompleteFormField = ({
  sx,
  allRoutesSelected,
  control,
  error = false,
  helperText,
  label,
  name,
  readOnly = false,
  required = false,
  rules,
  setValue,
  ...rest
}) => {
  const allRoutes = useSelector(getMetadataRoutes)
  const theme = useTheme()
  const handleToggleSelectAll = () => {
    setValue('allRoutesSelected', !allRoutesSelected)
    if (!allRoutesSelected) {
      setValue(name, [...allRoutes])
    } else {
      setValue(name, [])
    }
  }

  const getLocationOptionLabel = option => get(option, 'name')

  const renderLocationOption = ({ className, ...rest }, option, { selected }) => {
    const name = get(option, 'name', '')
    const bgcolor = get(option, 'color', grey[100])
    return (
      <MultiSelectItem key={`route-option-${getLocationOptionLabel(option)}-${option?.id}`} {...rest} selected={selected}>
        <ListItem disabbleGutters disablePadding>
          <ListItemAvatar sx={{ minWidth: '40px' }}>
            <RouteAvatar name={name} bgcolor={bgcolor} />
          </ListItemAvatar>
          <ListItemText primary={<Typography noWrap>{name}</Typography>} />
        </ListItem>
      </MultiSelectItem>
    )
  }

  const renderLocationTags = (value, getTagProps) => {
    return (
      <>
        {value.map((option, index) => {
          const bgcolor = get(option, 'color', grey[100])
          const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
          const color = theme.palette.getContrastText(formattedBgcolor)
          if (index === 0)
            return (
              <>
                {allRoutesSelected && <Chip size="small" label={T.ALL_ROUTES} onDelete={handleToggleSelectAll} />}
                <StyledChip
                  bgcolor={bgcolor}
                  deleteColor={color}
                  withMaxWidth
                  size="small"
                  label={getLocationOptionLabel(option)}
                  {...getTagProps({ index })}
                  sx={{ bgcolor: formattedBgcolor, color }}
                  key={`tag-route-option-${getLocationOptionLabel(option)}-${option?.id}`}
                />
              </>
            )
          return (
            <StyledChip
              withMaxWidth
              bgcolor={bgcolor}
              size="small"
              deleteColor={color}
              label={getLocationOptionLabel(option)}
              {...getTagProps({ index })}
              sx={{ bgcolor: formattedBgcolor, color }}
              key={`tag-route-option-${getLocationOptionLabel(option)}-${option?.id}`}
            />
          )
        })}
      </>
    )
  }

  const onChange = (_, newValue, reason) => {
    const newValueCount = get(newValue, 'length', 0)
    if (reason === 'clear' || reason === 'removeOption') {
      setValue('allRoutesSelected', false)
      setValue(name, newValue)
    } else if (reason === 'selectOption' && newValueCount === allRoutes.length) {
      setValue('allRoutesSelected', true)
      setValue(name, allRoutes)
    } else {
      setValue(name, newValue)
    }
  }

  const SelectAllPaperComponent = paperProps => {
    const { children, ...restPaperProps } = paperProps
    return (
      <PopupPaper {...restPaperProps}>
        <MultiSelectItem
          divider
          selected={allRoutesSelected}
          onMouseDown={e => {
            e.preventDefault() // prevent blur
            handleToggleSelectAll()
          }}
        >
          Select all
        </MultiSelectItem>
        {children}
      </PopupPaper>
    )
  }

  const isOptionEqualToValue = (option, value) => value.id === option.id

  const handleChange = () => {}

  return (
    <HHFormAutocompleteField
      clearIcon={false}
      sx={sx}
      isOptionEqualToValue={isOptionEqualToValue}
      PaperComponent={SelectAllPaperComponent}
      renderOption={renderLocationOption}
      renderTags={renderLocationTags}
      readOnly={readOnly}
      helperText={helperText}
      rules={rules}
      error={error}
      control={control}
      name={name}
      onChangeMiddleware={handleChange}
      required={required}
      deprecatedLabel={false}
      label={required && label ? `${label} *` : label}
      fullWidth
      options={allRoutes}
      multiple
      getOptionLabel={getLocationOptionLabel}
      onChange={onChange}
      ListboxProps={{
        style: {
          maxHeight: '30vh',
          overflow: 'auto',
        },
      }}
      {...rest}
    />
  )
}

RoutesAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  allRoutesSelected: PropTypes.bool.isRequired,
}

export default RoutesAutocompleteFormField
