import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Dialog, DialogContent, DialogContentText } from '@mui/material'
import { orange } from '@mui/material/colors'
import useTheme from '@mui/material/styles/useTheme'
import { noop } from 'lodash'
import { ReactComponent as TemporaryModeActive } from 'assets/switch/active/TemporaryMode.svg'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from '../../buttons/CancelButton'
import T from '../../../T'

const TemporaryModeDialog = ({ open = false, onClose = noop, onConfirm = noop, onCancel = noop }) => {
  const theme = useTheme()
  return (
    <Dialog open={open} onClose={onClose}>
      <HHDialogTitle title={T.TEMPORARY_MODE} onClose={onCancel} />
      <DialogContent>
        <DialogContentText sx={{ ...theme.typography.body1, color: theme.palette.text.primary }}>
          <Alert
            icon={<TemporaryModeActive />}
            severity="warning"
            sx={{
              ...theme.typography.body1,
              borderColor: orange[500],
              borderWidth: 1,
              borderStyle: 'solid',
              pr: theme.spacing(3),
            }}
          >
            Any changes made in temporary mode will not
            <br /> effect future recurring stops
          </Alert>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions sx={{ paddingTop: 0 }}>
        <CancelButton size="medium" onClick={onCancel} />
        <Button onClick={onConfirm} variant="contained" autoFocus>
          Okay
        </Button>
      </HHDialogActions>
    </Dialog>
  )
}

TemporaryModeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

export default TemporaryModeDialog
