import React from 'react'
import uniq from 'lodash/uniq'

import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'

import { get } from 'utils/lodash'
import { calculateSum } from 'utils/price'
import { PAYMENT_STATUS } from 'settings/constants/payment'
import { getPaymentsFromBatch } from 'data/groups/paymentGroupsSelectors'
import { useGetPaymentGroupDetailsQuery } from 'api/groups/payment/getPaymentGroupDetails'

import T from 'T'
import SummaryCard from '../../common/SummaryCard'
import HHDisplayMoney from '../../../../common/HHDisplayMoney'

const { DECLINED, FAILED } = PAYMENT_STATUS

const Summary = () => {
  const { id } = useParams()
  const { data } = useGetPaymentGroupDetailsQuery({ id })
  const payments = getPaymentsFromBatch({ paymentBatches: get(data, 'paymentBatches', []) })
  const paymentsCount = payments.length
  const capturedRevenueCents = calculateSum(payments, 'transactionAmountCents')
  const failedPayments = payments.filter(({ transactionStatus }) => [DECLINED, FAILED].includes(transactionStatus))
  const failedCents = calculateSum(failedPayments, 'transactionAmountCents')
  const accountCount = uniq(payments.map(({ accountNumber }) => accountNumber)).length

  return (
    <Grid container columnSpacing={2.5} spacing={2.5}>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.PAYMENTS} value={paymentsCount} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.COLLECTED_REVENUE} value={<HHDisplayMoney value={capturedRevenueCents} />} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={`${T.FAILED_PAYMENTS} (${failedPayments.length})`} value={<HHDisplayMoney value={failedCents} />} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.NUMBER_OF_ACCOUNTS} value={accountCount} />
      </Grid>
    </Grid>
  )
}

export default Summary
