import React, { useCallback } from 'react'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  selectBulkMoveToRouteSequenceLine,
  selectBulkMoveToRouteStopMarkers,
  selectBulkMoveToRows,
  setBulkMoveStopsState,
} from 'slices/route/bulkMoveBetweenRoutesSlice'
import { useDispatch, useSelector } from 'react-redux'
import useHandleSequenceChange from 'components/route-manager/BulkActions/common/useHandleSequenceChange'
import { getBulkStopData } from 'components/route-manager/BulkActions/settings'
import StopListItem from 'components/route-manager/BulkActions/common/StopListItem/StopListItem'
import ToStopAvatarContent from 'components/route-manager/BulkActions/common/StopListItem/ToStopAvatarContent'

const RenderToStopListItem = props => {
  const { getValues, setValue, watch } = useFormContext()
  const dispatch = useDispatch()
  const moveToRows = useSelector(selectBulkMoveToRows)
  const moveToRouteStopMarkers = useSelector(selectBulkMoveToRouteStopMarkers)
  const moveToRouteSequenceLine = useSelector(selectBulkMoveToRouteSequenceLine)
  const { row } = props
  const { id: stopId, routeId, position, workOrderStatus } = getBulkStopData(row)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const toBeResequence = moveFromRouteId === routeId

  const getTargetIndex = useCallback(
    (fromIndex, toPosition) => {
      if (!toPosition) return 0
      const values = getValues()
      const toInsertCount = moveToRows
        .filter(item => item.routeId === moveFromRouteId)
        .filter(({ id }) => !get(values, id, '') && id !== stopId).length
      let targetPosition = Math.max(1, toPosition)
      targetPosition = Math.min(targetPosition, moveToRows.length + 1)
      const targetIndex = targetPosition - 1
      return targetIndex + toInsertCount
    },
    [moveToRows, moveFromRouteId, row?.id]
  )

  const getIsMoveToRow = useCallback(r => get(r, 'routeId') === moveToRouteId, [moveToRouteId])

  const onSuccess = data => dispatch(setBulkMoveStopsState(data))

  const handleSequenceChange = useHandleSequenceChange({
    getIsMoveToRow,
    getTargetIndex,
    getValues,
    moveToRouteSequenceLine,
    moveToRouteStopMarkers,
    moveToRows,
    setValue,
    stopId,
    onSuccess,
  })

  return (
    <StopListItem
      row={row}
      AvatarContent={
        <ToStopAvatarContent
          stopId={stopId}
          position={position}
          workOrderStatus={workOrderStatus}
          toBeResequence={toBeResequence}
          onChange={handleSequenceChange}
        />
      }
    />
  )
}

RenderToStopListItem.propTypes = {
  row: PropTypes.object,
}

export default RenderToStopListItem
