import React from 'react'
import PropTypes from 'prop-types'

import { Box, Popover } from '@mui/material'
import { ReactComponent as CalendarCirclePlusIcon } from 'assets/CalendarCirclePlus.svg'

import { memo } from 'utils/react'
import ListItemRow from 'components/customers/accounts/locations-services/ListItemRow'

const ServicesCountPopover = ({ anchorEl, onClose, activeCount, deactivatedCount }) => {
  const open = Boolean(anchorEl)

  return (
    <Popover
      sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Box p={1.5}>
        <ListItemRow
          icon={<CalendarCirclePlusIcon width={20} height={20} />}
          label="Services"
          activeCount={activeCount}
          deactivatedCount={deactivatedCount}
        />
      </Box>
    </Popover>
  )
}

ServicesCountPopover.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  activeCount: PropTypes.number,
  deactivatedCount: PropTypes.number,
}

export default memo(ServicesCountPopover)
