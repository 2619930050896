import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateBillingAddress: builder.mutation({
      query: ({ accountId, body }) => ({
        url: `/api/v1/core/accounts/${accountId}/billing-address`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useUpdateBillingAddressMutation } = extendedApi
