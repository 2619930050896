import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@mui/icons-material/Close'
import { InputAdornment, IconButton, ListItem, ListItemText } from '@mui/material'

import { get } from 'utils/lodash'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHTextField from 'components/form-fields/v5/HHTextField'

const EmailSelectField = ({ searchValue, selectedAccount = '', existingAccounts = [], onChange, onCancel }) => {
  return (
    <HHTextField
      sx={{ mt: 1, '& .MuiOutlinedInput-root': { height: selectedAccount ? '60px!important' : 40 } }}
      deprecatedLabel={false}
      label={T.EMAIL}
      fullWidth
      select
      value={[selectedAccount]}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      SelectProps={{
        defaultOpen: true,
        autoFocus: true,
        MenuProps: {
          PaperProps: {
            sx: {
              '& .Mui-selected .MuiTypography-root': {
                color: 'inherit',
              },
            },
          },
        },
      }}
    >
      {existingAccounts.map(account => (
        <SelectItem value={account.id}>
          <ListItem disableGutters>
            <ListItemText
              primary={searchValue}
              secondary={`${get(account, 'customerName', '')} | ${get(account, 'accountNumber')}`}
              primaryTypographyProps={{ variant: 'h6', fontWeight: 400, gutterBottom: true }}
              secondaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
            />
          </ListItem>
        </SelectItem>
      ))}
    </HHTextField>
  )
}

EmailSelectField.propTypes = {
  searchValue: PropTypes.string,
  selectedAccount: PropTypes.string,
  existingAccounts: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default EmailSelectField
