import React from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { Box, Divider, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme, Link } from '@mui/material'
import { LocationMarker } from '@styled-icons/heroicons-outline/LocationMarker'
import { Mail } from '@styled-icons/heroicons-outline/Mail'
import { Phone } from '@styled-icons/heroicons-outline/Phone'
import { GlobeAlt } from '@styled-icons/heroicons-outline/GlobeAlt'
import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import ContactListItem from './ContactListItem'

const ContactDetails = ({ address = '', email = '', phone = '', website = '' }) => {
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box mb={isTabletOrMobile ? 6 : 0}>
      {address && (
        <>
          <ListItem sx={{ display: 'block', textAlign: isTabletOrMobile ? 'left' : 'center' }}>
            <ListItemIcon sx={{ display: 'inline-block', minWidth: 24 }}>
              <LocationMarker />
            </ListItemIcon>
            <ListItemText sx={{ ml: 1, display: 'inline-block' }} primary={address} />
          </ListItem>
        </>
      )}

      {(email || phone || website) && (
        <>
          <Divider flexItem />
          <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
            {email && (
              <ContactListItem
                title={
                  <Link href={`mailto:${email}`} color="text.primary" underline="none">
                    {email}
                  </Link>
                }
                Icon={Mail}
              />
            )}
            {phone && (
              <ContactListItem
                primaryProps={{ whiteSpace: 'nowrap' }}
                title={
                  <Link href={`tel:${phone}`} color="text.primary" underline="none">
                    <HHPhoneField displayType="text" value={phone} />
                  </Link>
                }
                Icon={Phone}
              />
            )}
            {website && (
              <ContactListItem
                primaryProps={{ whiteSpace: 'nowrap' }}
                title={
                  <Link component={RouterLink} to={website} color="text.primary" rel="noopener noreferrer" target="_blank" underline="none">
                    {website}
                  </Link>
                }
                Icon={GlobeAlt}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

ContactDetails.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
}

export default ContactDetails
