import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { find, get } from 'lodash'
import { createSearchParams, useNavigate } from 'react-router-dom-v5-compat'
import { getMetadataInvoice } from 'data/billing/billingTableSelectors'
import { useLazyGetBillingInvoiceMetaQuery } from 'api/billing/getBillingInvoiceMeta'
import { getBillingPeriods } from 'data/billing-period/billingPeriodSelectors'
import { useLazyGetCustomerBillingProfileByIdQuery } from 'api/settings/customerBillingProfileCrud'
import { useLazyGetBillingPeriodsByIdQuery } from 'api/billing/getBillingPeriodsById'
import {
  changeCreatedInvoicesData,
  setCreateInvoicesPreviewData,
  setCreateInvoicesSelectedBillingPeriod,
  setCreateInvoicesSelectedBillingProfile,
  setCreateInvoicesSelectionModel,
} from 'slices/billing/createInvoicesSlice'
import { usePostInvoiceBulkPreviewMutation } from 'api/billing/postBulkPreview'
import { INVOICE_TYPE } from 'settings/constants/billing'
import { setCreatedInvoicesState } from 'slices/billing/invoicesCreationBridgeSlice'
import { handleError } from 'utils/error'
import { putIsLoading } from 'middleware/actions/response'
import ConfirmInvoiceDate from 'components/modal/ConfirmInvoiceDate'
import PreviewChargeListDrawer from 'components/billing/create-invoices/PreviewChargeListDrawer/PreviewChargeCardListDrawer'
import T from 'T'
import BillingProfileDropdown from 'components/billing/common/BillingProfileDropdown'
import BillingPeriodDropdown from 'components/billing/common/BillingPeriodDropdown'
import routes from 'router/routes'

const { RECURRING } = INVOICE_TYPE

const CreateInvoicesTopSection = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const createdInvoicesData = useSelector(state => get(state, 'createInvoicesSlice.createdInvoicesData', {}), shallowEqual)
  const billingPeriodStartDate = useSelector(state => state.createInvoicesSlice.createdInvoicesData.billingPeriod.startDate)
  const invoiceDate = useSelector(state => state.createInvoicesSlice.createdInvoicesData.billingPeriod.invoiceDate)
  const [postInvoiceBulkPreviewMutation] = usePostInvoiceBulkPreviewMutation()
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [isOpenChargesDrawer, setIsOpenChargesDrawer] = useState(false)
  const billingInvoiceMetaData = useSelector(getMetadataInvoice)
  const billingProfileMeta = get(billingInvoiceMetaData, 'billingProfiles', [])
  const [getBillingInvoiceMeta] = useLazyGetBillingInvoiceMetaQuery()
  const [getBillingPeriodsById, { data: billingPeriodList, isError, isFetching: isBillingPeriodsByIdLoading }] =
    useLazyGetBillingPeriodsByIdQuery()
  const [
    getCustomerBillingProfileById,
    { data: billingProfileDetails, isError: isBillingProfileByIdError, isFetching: isBillingProfileByIdLoading },
  ] = useLazyGetCustomerBillingProfileByIdQuery()
  const selectedBillingProfile = useSelector(state => state.createInvoicesSlice.selectedBillingProfile)
  const selectedBillingPeriod = useSelector(state => state.createInvoicesSlice.selectedBillingPeriod)
  const allBillingPeriods = getBillingPeriods({
    billingProfile: isBillingProfileByIdError ? {} : billingProfileDetails,
    billingPeriods: isError ? [] : get(billingPeriodList, 'billingPeriods', []),
  })
  const selectionModel = useSelector(state => state.createInvoicesSlice.selectionModel, shallowEqual)
  const isLoading = isBillingProfileByIdLoading || isBillingPeriodsByIdLoading

  const handleOpenChargesDrawer = () => {
    postInvoiceBulkPreviewMutation({
      accountIds: selectionModel,
      billingPeriodStartDate,
      invoiceDate,
      invoiceType: RECURRING,
    })
      .unwrap()
      .then(data => {
        dispatch(setCreateInvoicesPreviewData({ ...data, remoteRowCount: selectionModel.length, prefilledDocIdList: selectionModel }))
      })
    setIsOpenChargesDrawer(true)
  }
  const handleCloseChargesDrawer = () => {
    setIsOpenChargesDrawer(false)
  }

  const getBillingPeriodDetails = invoiceDate =>
    get(billingPeriodList, 'billingPeriods', []).find(bp => bp.invoiceDate === invoiceDate) || {}
  const handleCustomerBillingProfileChange = value => {
    const billingProfile = billingProfileMeta.find(({ id }) => id === value)
    const customerBillingProfile = get(billingProfile, 'name')
    dispatch(changeCreatedInvoicesData({ customerBillingProfile }))
    dispatch(
      setCreateInvoicesSelectedBillingProfile({
        billingProfile: value,
        billingProfileDetails: billingProfile,
      })
    )
    if (value) {
      getBillingPeriodsById(value).unwrap().catch(handleError)
      getCustomerBillingProfileById({ id: value }).unwrap().catch(handleError)
    }
  }

  const handleBillingPeriodChange = value => {
    dispatch(changeCreatedInvoicesData({ billingPeriod: getBillingPeriodDetails(value) }))
    dispatch(
      setCreateInvoicesSelectedBillingPeriod({
        billingPeriod: value,
        billingPeriodDetails: getBillingPeriodDetails(value),
      })
    )
  }
  const handleConfirmInvoiceDateOpen = () => {
    setIsOpenDrawer(true)
  }

  const handleCloseInvoiceDateModal = isOpen => {
    dispatch(setCreateInvoicesSelectionModel([]))
    setIsOpenDrawer(isOpen)
  }

  const handleConfirmInvoiceDateModal = isOpen => {
    setIsOpenDrawer(isOpen)
    const { customerBillingProfile, invoiceDate } = createdInvoicesData
    const selectedBillingProfileObj = find(billingProfileMeta, { id: selectedBillingProfile })
    const selectedBillingProfileName = get(selectedBillingProfileObj, 'name')
    const params = {
      billing_profile: customerBillingProfile,
      'invoice_date[DATE_IS_BETWEEN]': invoiceDate,
    }
    dispatch(
      setCreatedInvoicesState({
        isInvoiceCreationSuccessModalOpen: true,
        selectedInvoicesCount: selectionModel.length,
        selectedBillingProfileName,
      })
    )
    dispatch(setCreateInvoicesSelectionModel([]))

    navigate({
      pathname: routes.app.invoices,
      search: `?${createSearchParams(params)}`,
    })
  }

  useEffect(() => {
    getBillingInvoiceMeta()
  }, [])

  useEffect(() => {
    dispatch(putIsLoading(isLoading))
  }, [isLoading])

  return (
    <Grid container sx={{ mt: 2 }} px={3} columnSpacing={2} alignItems="flex-end">
      <Grid item xs={3}>
        <BillingProfileDropdown
          size="small"
          selectedBillingProfile={selectedBillingProfile}
          billingProfileMeta={billingProfileMeta}
          onBillingProfileChange={handleCustomerBillingProfileChange}
        />
      </Grid>
      <Grid item xs={3}>
        <BillingPeriodDropdown
          size="small"
          disabled={!selectedBillingProfile}
          selectedBillingPeriod={selectedBillingPeriod}
          allBillingPeriods={allBillingPeriods}
          onBillingPeriodChange={handleBillingPeriodChange}
        />
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end" columnSpacing={2}>
        <Grid item>
          <Button onClick={handleOpenChargesDrawer} disabled={selectionModel.length === 0} variant="outlined" size="small">
            Preview charges
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleConfirmInvoiceDateOpen}
            disabled={selectionModel.length === 0}
            variant="contained"
            color="primary"
            size="small"
          >
            {T.CREATE_INVOICES}
          </Button>
        </Grid>
      </Grid>
      <ConfirmInvoiceDate
        currentBillingProfile={selectedBillingProfile}
        nextBillDate={selectedBillingPeriod}
        accountIds={selectionModel}
        isOpenDrawer={isOpenDrawer}
        onClose={handleCloseInvoiceDateModal}
        onConfirm={handleConfirmInvoiceDateModal}
      />

      <PreviewChargeListDrawer open={isOpenChargesDrawer} onClose={handleCloseChargesDrawer} />
    </Grid>
  )
}

export default CreateInvoicesTopSection
