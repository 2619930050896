import api from 'api'
import { CACHE_TAG_INVOICE_LIST } from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getBillingInvoiceList: build.query({
      query: body => ({
        url: '/api/billing/invoice/list',
        method: 'POST',
        body,
      }),
      providesTags: [CACHE_TAG_INVOICE_LIST],
      transformResponse: data => {
        const { invoices, totalItems, totalPages } = data
        return {
          tableRows: invoices,
          totalItems,
          totalPages,
        }
      },
    }),
  }),
})

export const { useLazyGetBillingInvoiceListQuery } = extendedApi
