import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import {
  useCreateAccountNoteMutation,
  useUpdateAccountNoteMutation,
  usePatchAccountNoteMutation,
  useDeleteAccountNoteMutation,
  useLazyGetAccountNoteHistoryQuery,
} from 'api/notes/accountNotesCrud'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AddUpdateNoteDialog from 'components/notes/common/AddUpdateNoteDialog'
import NoteListItem from 'components/notes/common/NoteListItem'
import NoteActionMenu from 'components/notes/common/NoteActionMenu'
import ViewNoteDialog from 'components/notes/common/ViewNoteDialog'

const AccountNotes = ({ notes = [], accountId = '', noNotesText = T.NO_NOTES_V2, showSecondaryActions = true, sx, itemSx }) => {
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewNoteDialog, setIsOpenViewNoteDialog] = useState(null)
  const [isOpenNoteActionMenu, setIsOpenNoteActionMenu] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const hasNotes = notes.length > 0

  const handleOpenUpdateAccountNote = () => setIsOpenAddUpdateNoteDialog(true)

  const handleOpenViewAccountNoteDialog = note => {
    setSelectedNote(note)
    setIsOpenViewNoteDialog(true)
  }

  const handleCloseViewAccountNoteDialog = () => {
    setSelectedNote(null)
    setIsOpenViewNoteDialog(null)
  }

  const handleOpenAccountNoteActionMenu = (event, note) => {
    event.stopPropagation()
    setSelectedNote(note)
    setIsOpenNoteActionMenu(event.currentTarget)
  }

  const handleCloseAccountNoteActionMenu = () => setIsOpenNoteActionMenu(null)

  const handleCloseAddUpdateAccountNote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  return (
    <>
      {!hasNotes && <HHSectionPlaceholder title={noNotesText} />}
      {hasNotes && (
        <List sx={{ py: 0, ...sx }}>
          {Children.toArray(
            notes.map((note, index) => (
              <NoteListItem
                sx={itemSx}
                note={note}
                showNoteVisibilityOnMobileIcon={false}
                showDivider={notes.length - 1 !== index}
                showSecondaryActions={showSecondaryActions}
                onViewClick={() => handleOpenViewAccountNoteDialog(note)}
                onActionMenuClick={event => handleOpenAccountNoteActionMenu(event, note)}
              />
            ))
          )}
        </List>
      )}
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.ACCOUNT_NOTE : T.NEW_ACCOUNT_NOTE}
        existingNote={selectedNote}
        useCreateNoteMutation={useCreateAccountNoteMutation}
        useUpdateNoteMutation={useUpdateAccountNoteMutation}
        onClose={handleCloseAddUpdateAccountNote}
      />
      <ViewNoteDialog
        id={accountId}
        isOpen={isOpenViewNoteDialog}
        title={T.ACCOUNT_NOTE}
        selectedNote={selectedNote}
        useLazyGetNoteHistoryQuery={useLazyGetAccountNoteHistoryQuery}
        onClose={handleCloseViewAccountNoteDialog}
      />
      <NoteActionMenu
        id={accountId}
        anchorEl={isOpenNoteActionMenu}
        selectedNote={selectedNote}
        showNoteVisibilityOnMobileOption={false}
        onEditClick={handleOpenUpdateAccountNote}
        usePatchNoteMutation={usePatchAccountNoteMutation}
        useDeleteNoteMutation={useDeleteAccountNoteMutation}
        onClose={handleCloseAccountNoteActionMenu}
      />
    </>
  )
}

AccountNotes.propTypes = {
  notes: PropTypes.array,
  accountId: PropTypes.string,
  noNotesText: PropTypes.string,
  showSecondaryActions: PropTypes.bool,
  sx: PropTypes.object,
  itemSx: PropTypes.object,
}

export default AccountNotes
