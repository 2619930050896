import React from 'react'
import PropTypes from 'prop-types'
import { grey } from '@mui/material/colors'
import { Card, Stack, SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as Map } from 'assets/map/OutlinedMap.svg'

const LocationMapSkeleton = ({ width = '100%', height = '100%' }) => {
  const theme = useTheme()
  return (
    <Card elevation={0} sx={{ backgroundColor: grey[100], width, height }}>
      <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
        <SvgIcon inheritViewBox sx={{ width: 72, height: 72, color: `${theme.palette.action.active}` }}>
          <Map width={72} height={72} />
        </SvgIcon>
      </Stack>
    </Card>
  )
}

LocationMapSkeleton.propTypes = {
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
}

export default LocationMapSkeleton
