import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { putIsLoading } from 'middleware/actions/response'
import { getInvoicesPDFStatus, isDraftInvoice } from 'utils/billing'
import T from 'T'
import { useSendInvoiceListMutation } from 'api/billing/sendInvoiceList'
import api from 'api'
import {
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
  CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
} from 'api/cacheTagTypes'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import InvoiceSendFailureConfirmation from 'containers/billing/InvoiceSendFailureConfirmation'
import { SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as PaperPlane } from 'assets/PaperPlane.svg'
import IconButton from '@mui/material/IconButton'
import { capitalize } from 'lodash'

const TIMEOUT = 4000

const SendInvoiceIconButton = ({ sx, iconSx, invoice, ...rest }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [sendInvoiceList] = useSendInvoiceListMutation()
  const [sendInvoiceConfirmation, setSendInvoiceConfirmation] = useState({ invoiceNumbers: [], isOpen: false })

  const handleRefetch = (timeout = 0) =>
    setTimeout(
      () =>
        dispatch(
          api.util.invalidateTags([
            CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
            CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
            CACHE_TAG_INVOICE_LIST,
            CACHE_TAG_ACCOUNT_INVOICE_LIST,
            CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
            CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
            CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
            CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA,
          ])
        ),
      timeout
    )

  const handleAPISuccess = (message, refetch = false, timer = false) => {
    if (timer) {
      toast.success(capitalize(message), {
        hideProgressBar: false,
        autoClose: TIMEOUT,
        onClose: () => {
          if (refetch) handleRefetch()
        },
      })
      return
    }

    toast.success(message)
    if (refetch) handleRefetch()
  }

  const handleSendInvoice = async () => {
    try {
      dispatch(putIsLoading(true))
      const { invoiceNumber, status } = invoice
      let withPDF = [invoiceNumber]
      let withoutPDF = []

      if (isDraftInvoice(status)) {
        const result = await getInvoicesPDFStatus(withPDF)
        withPDF = result.withPDF
        withoutPDF = result.withoutPDF
      }

      const hasInvoicesWithoutPDF = withoutPDF.length > 0

      if (withPDF.length) {
        await sendInvoiceList({ invoiceNumbers: withPDF })
          .unwrap()
          .then(() => {
            if (hasInvoicesWithoutPDF) {
              setSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
              handleRefetch(TIMEOUT)
              return
            }
            handleAPISuccess(T.SEND_INVOICE_SUCCESS, true, true)
          })
          .catch(handleError)
      } else if (hasInvoicesWithoutPDF) {
        setSendInvoiceConfirmation({ isOpen: true, invoiceNumbers: withoutPDF })
      }
    } finally {
      dispatch(putIsLoading(false))
    }
  }

  const handleCloseInvoiceSendFailure = () => setSendInvoiceConfirmation({ isOpen: false, invoiceNumbers: [] })

  return (
    <>
      <IconButton onClick={handleSendInvoice} size="small" {...rest} sx={sx}>
        <SvgIcon sx={{ color: `${theme.palette.text.primary}8A`, fontSize: '20', ...iconSx }}>
          <PaperPlane />
        </SvgIcon>
      </IconButton>
      <InvoiceSendFailureConfirmation
        isOpen={sendInvoiceConfirmation.isOpen}
        invoices={sendInvoiceConfirmation.invoiceNumbers}
        onClose={handleCloseInvoiceSendFailure}
      />
    </>
  )
}

SendInvoiceIconButton.propTypes = {
  sx: PropTypes.object,
  iconSx: PropTypes.object,
  invoice: PropTypes.object,
}

export default SendInvoiceIconButton
