import React, { useState } from 'react'
import PropTypes from 'prop-types'
import T from 'T'
import { styled } from '@mui/material/styles'
import MuiBox from '@mui/material/Box'
import MuiTypography from '@mui/material/Typography'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'

import Constants from 'Constants'

import { getParsedMatchedService } from 'data/pricing/billingSelector'

import LinkedServicesTile from './LinkedServicesTile'

const { MEDIA_SERVER } = Constants

const BoxRoot = styled(MuiBox)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
}))
const BoxHeader = styled(MuiBox)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
}))
const Typography = styled(MuiTypography)(({ theme }) => ({
  flex: 1,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 12,
  lineHeight: theme.spacing(4),
  color: theme.palette.text.secondary,
  margin: 0,
}))
const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  '& .MuiToggleButton-sizeSmall': {
    padding: '2px',
  },
}))
const Img = styled('img')(() => ({
  margin: 3,
  height: 20,
  width: 20,
  minWidth: 0,
}))

const LinkedServicesList = ({ matchedServices = [] }) => {
  const [expandList, setExpandList] = useState(false)
  return (
    <BoxRoot>
      <BoxHeader>
        <Typography>{`${T.PRICED_SERVICE}s`}</Typography>
        <ToggleButtonGroup
          size="small"
          value={expandList}
          exclusive
          onChange={(_, newValue) => {
            if (newValue === true || newValue === false) {
              setExpandList(newValue)
            }
          }}
        >
          <ToggleButton value={true}>
            <Img src={`${MEDIA_SERVER}CardView.svg`} alt="" />
          </ToggleButton>
          <ToggleButton value={false}>
            <Img src={`${MEDIA_SERVER}CardViewExpanded.svg`} alt="" />
          </ToggleButton>
        </ToggleButtonGroup>
      </BoxHeader>
      {matchedServices?.map(rawMatchedService => {
        const {
          serviceName,
          price,
          pricingPeriod,
          businessLine,
          materialType,
          methodTypeIcon,
          serviceType,
          actionType,
          volume,
          measureUnit,
        } = getParsedMatchedService(rawMatchedService)
        return (
          <LinkedServicesTile
            serviceName={serviceName}
            expanded={expandList === true}
            price={price}
            pricingPeriod={pricingPeriod}
            businessLine={businessLine}
            materialType={materialType}
            methodTypeIcon={methodTypeIcon}
            serviceType={serviceType}
            actionType={actionType}
            volume={volume}
            measureUnit={measureUnit}
          />
        )
      })}
    </BoxRoot>
  )
}

LinkedServicesList.propTypes = {
  matchedServices: PropTypes.arrayOf(PropTypes.object),
}

export default LinkedServicesList
