import React from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'
import { Card, TableHead, Table, TableBody, TableRow, TableCell, Grid, Typography, Box, Skeleton } from '@mui/material'
import { SETTINGS_PAGINATION, SETTINGS_SKELTON_ROWS } from 'settings/constants/pagination'
import { get, noop } from 'lodash'
import { canUpdateSettings } from '../../data/permissions/permissionsSelectors'
import HHTablePaginationV5 from '../common/HHTablePaginationV5'
import HHPaginationV5 from '../common/HHPaginationV5'
import TableLayout from './layout/TableLayout'

const { ROWS_PER_PAGE_OPTIONS, INITIAL_PAGE, ROWS_PER_PAGE } = SETTINGS_PAGINATION

const SettingsTable = ({
  columns = [],
  data = [],
  onEditRecord = noop,
  page = INITIAL_PAGE,
  pageCount = 0,
  count = 0,
  size = ROWS_PER_PAGE,
  paginated = false,
  isLoading = false,
  onRowsPerPageChange = noop,
  onPageChange = noop,
}) => {
  const updateSettings = useSelector(canUpdateSettings, shallowEqual)
  const skeletonArray = new Array(SETTINGS_SKELTON_ROWS).fill(0)
  return (
    <Card variant="outlined">
      <TableLayout height="calc(100vh - 200px)">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(({ name, align = 'inherit', colSpan = 1 }) => (
                <TableCell sx={{ bgcolor: 'background.paper' }} align={align} colSpan={colSpan}>
                  <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
                    {name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              !isLoading &&
              data.map(record => (
                <TableRow sx={{ cursor: 'pointer' }} hover onClick={() => (updateSettings ? onEditRecord(record) : noop)}>
                  {columns.map(({ key, align = 'inherit', colSpan = 1 }) => (
                    <TableCell align={align} colSpan={colSpan}>
                      <Typography>{get(record, key, '')}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {isLoading &&
              skeletonArray.map(() => (
                <TableRow>
                  {columns.map(({ key, align = 'inherit', colSpan = 1 }) => (
                    <TableCell key={key} align={align} colSpan={colSpan}>
                      <Skeleton variant="rect" />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableLayout>
      {paginated && (
        <Card variant="outlined">
          <Grid container justifyContent="flex-start" sx={{ bgcolor: 'background.default' }}>
            <Grid item xs={6} pl={2}>
              <HHTablePaginationV5
                sx={{ borderBottom: 'none' }}
                ActionsComponent={params => <Box display="none" {...params} />}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                page={page}
                count={count}
                rowsPerPage={size}
                onPageChange={(_, newPage) => onPageChange(newPage)}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <HHPaginationV5 count={pageCount} page={page + 1} onChange={(_, newPage) => onPageChange(newPage - 1)} />
            </Grid>
          </Grid>
        </Card>
      )}
    </Card>
  )
}

SettingsTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  paginated: PropTypes.bool,
  page: PropTypes.number,
  count: PropTypes.number,
  size: PropTypes.number,
  pageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onEditRecord: PropTypes.func,
}

export default SettingsTable
