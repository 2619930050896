import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'
import { Box, Checkbox, DialogContent } from '@mui/material'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import {
  useLazyGetRentalFeesByPricedServiceQuery,
  useUnlinkRentalFeesFromPricedServiceMutation,
} from 'api/pricing/pricedServiceRentalFeesCrud'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import RenderCell from './common/RenderCell'
import DataGrid from './common/DataGrid'
import RentalFeeListItemSkeleton from '../../common/field/RentalFeeListItemSkeleton'
import { getRemoveDialogContentHeight } from './common/utils'
import { ROW_HEIGHT } from './common/settings'

const RemoveRentalFeesDialog = ({ isOpen = false, pricedServiceId, callback = noop, onClose }) => {
  const [selectionModel, setSelectionModel] = useState([])
  const [getRentalFeesByPricedService, { isFetching: isGetRentalFeesByPricedServiceLoading, data: rentalFeesByPricedServiceData }] =
    useLazyGetRentalFeesByPricedServiceQuery()
  const [unlinkRentalFeesFromPricedService, { isLoading: isUnlinkLoading }] = useUnlinkRentalFeesFromPricedServiceMutation()

  const rentalFeesByPricedService = useMemo(() => get(rentalFeesByPricedServiceData, 'rentalFees', []), [rentalFeesByPricedServiceData])
  const sortedRentalFees = useMemo(() => sortBy(rentalFeesByPricedService, ['feeName']), [rentalFeesByPricedService])

  const handleSelectionModelChange = newSelectionModel => {
    setSelectionModel(newSelectionModel)
  }

  const handleRemoveRentalFee = () => {
    unlinkRentalFeesFromPricedService({ id: pricedServiceId, rentalFees: selectionModel })
      .unwrap()
      .then(() => {
        toast.success(`Rental fee${selectionModel.length === 1 ? '' : 's'} removed successfully`)
        callback()
        onClose()
        handleSelectionModelChange([])
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      handleSelectionModelChange([])
      getRentalFeesByPricedService({ id: pricedServiceId })
    }
  }, [isOpen])

  const containerHeight = useMemo(() => getRemoveDialogContentHeight(sortedRentalFees.length, ROW_HEIGHT), [sortedRentalFees])

  return (
    <HHBaseDialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <HHDialogTitle title={T.REMOVE_RENTAL_FEE} onClose={onClose} />
      <DialogContent>
        {isGetRentalFeesByPricedServiceLoading && <RentalFeeListItemSkeleton rows={2} />}
        {!isGetRentalFeesByPricedServiceLoading && (
          <Box height={containerHeight}>
            <DataGrid
              isSelectable
              rows={sortedRentalFees}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              RenderCell={RenderCell}
              noRowsText={T.NO_RENTAL_FEES_LINKED_TO_PRICED_SERVICE}
              slots={{
                baseCheckbox: params => <Checkbox {...params} color="warning" checkedIcon={<DisabledByDefaultRoundedIcon />} />,
              }}
            />
          </Box>
        )}
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <SaveButton
          loadingPosition="center"
          loading={isUnlinkLoading}
          disabled={isUnlinkLoading || selectionModel.length === 0}
          label={T.REMOVE}
          color="warning"
          onClick={handleRemoveRentalFee}
        />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

RemoveRentalFeesDialog.propTypes = {
  isOpen: PropTypes.bool,
  pricedServiceId: PropTypes.string.isRequired,
  callback: PropTypes.func,
  onClose: PropTypes.func,
}

export default RemoveRentalFeesDialog
