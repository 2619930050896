import { Box, Stack, Tooltip, Grid, Typography, darken } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import PropTypes from 'prop-types'
import { ResponsivePie } from '@nivo/pie'
import { get, lowerCase, noop } from 'lodash'
import { selectStopsByStatusCounts } from 'data/route-manager/stopSelector'
import { WORK_ORDER_STATUS_DISPLAY } from 'settings/constants/service'
import { styled } from '@mui/system'
import { tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme, mw }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid ${theme.palette.divider}`,
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    minWidth: mw,
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    padding: 0,
  },
}))

const getData = (stopsByStatusCounts, totalValue) => {
  const data = []
  let sumOfPercentages = 0
  let maxPercentage = 0
  let maxPercentageIndex = -1

  for (const status of Object.keys(WORK_ORDER_STATUS_DISPLAY)) {
    const value = get(stopsByStatusCounts, status, 0)

    if (value !== 0) {
      const color = get(WORK_ORDER_STATUS_DISPLAY, `${status}.bgColor`, grey[100])
      const percentage = Math.round((value / totalValue) * 100)

      if (percentage > maxPercentage) {
        maxPercentage = percentage
        maxPercentageIndex = data.length
      }

      const percentageLabel = percentage < 10 ? `0${percentage}% ${lowerCase(status)}` : `${percentage}% ${lowerCase(status)}`
      const borderColor = darken(color, 0.1)

      data.push({
        id: status,
        label: status,
        value,
        color,
        borderColor,
        percentage,
        percentageLabel,
      })

      sumOfPercentages += percentage
    }
  }

  if (sumOfPercentages > 100 && maxPercentageIndex !== -1) {
    const excess = sumOfPercentages - 100
    data[maxPercentageIndex].percentage -= excess
    data[maxPercentageIndex].percentageLabel = `${
      data[maxPercentageIndex].percentage < 10 ? `0${data[maxPercentageIndex].percentage}` : data[maxPercentageIndex].percentage.toString()
    }% ${lowerCase(data[maxPercentageIndex].label)}`
  }

  return data
}

const CustomCircularProgress = ({ children, stopsCount, stops, onClick = noop }) => {
  const stopsByStatusCounts = selectStopsByStatusCounts({ stops })
  const data = getData(stopsByStatusCounts, stopsCount)
  const graphSize = 170
  const getColor = ({ data }) => {
    return get(data, `color`, grey[100])
  }
  return (
    <LightTooltip
      arrow
      placement="bottom-start"
      mw={350}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
      title={
        <Box variant="outlined" minHeight="100px" minWidth={300} p={2}>
          <Grid container columnGap={2} flexWrap="nowrap">
            <Grid item>
              <Stack alignItems="center" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
                <Box position="relative" width={graphSize} height={graphSize}>
                  <Box position="absolute" zIndex={1} overflow="visible" width={graphSize} height={graphSize}>
                    <Stack
                      direction="column"
                      sx={{ position: 'absolute', width: graphSize, height: graphSize }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h4" fontWeight={600} color="textSecondary">
                        {stopsCount}
                      </Typography>
                      <Typography variant="h5" fontWeight={600} color="textSecondary">
                        stops
                      </Typography>
                    </Stack>
                    <ResponsivePie
                      isInteractive
                      data={data}
                      margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
                      innerRadius={0.65}
                      padAngle={2}
                      cornerRadius={0}
                      activeOuterRadiusOffset={0}
                      borderWidth={2}
                      colors={getColor}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                      }}
                      enableArcLabels
                      enableArcLinkLabels={false}
                      defs={[]}
                      fill={[]}
                      legends={[]}
                    />
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item container xs direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Stack>
                  {data.map(({ color, borderColor, percentageLabel }) => {
                    return (
                      <Stack
                        key={percentageLabel}
                        direction="row"
                        alignItems="center"
                        sx={{
                          mb: 1,
                          '&:last-child': {
                            mb: 0,
                          },
                        }}
                      >
                        <Box
                          sx={{ backgroundColor: color, width: 20, height: 20, borderColor, borderWidth: 2, borderStyle: 'solid', mr: 1 }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                          {percentageLabel}
                        </Typography>
                      </Stack>
                    )
                  })}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Stack
        onClick={onClick}
        justifyContent="center"
        alignItems="center"
        sx={{ position: 'relative', width: 50, height: 50, cursor: 'pointer' }}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ position: 'absolute', width: 50, height: 50 }}>
          <ResponsivePie
            isInteractive={false}
            data={data}
            margin={{ top: 1, right: 1, bottom: 1, left: 1 }}
            innerRadius={0.85}
            padAngle={8}
            cornerRadius={0}
            activeOuterRadiusOffset={0}
            borderWidth={2}
            colors={getColor}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.4]],
            }}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            defs={[]}
            fill={[]}
            legends={[]}
          />
        </Stack>
        <Stack justifyContent="center" alignItems="center" sx={{ position: 'absolute' }}>
          {children}
        </Stack>
      </Stack>
    </LightTooltip>
  )
}

CustomCircularProgress.propTypes = {
  children: PropTypes.node,
  stops: PropTypes.array,
  stopsCount: PropTypes.string,
  onClick: PropTypes.func,
}

export default CustomCircularProgress
