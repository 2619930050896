import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { compact, noop, capitalize } from 'lodash'
import { NumericFormat } from 'react-number-format'

import {
  TextField,
  Table as TableView,
  TableContainer,
  TableRow,
  TableHead as THead,
  TableCell,
  TableBody as TBody,
  MenuItem,
  IconButton,
  Box,
  InputAdornment,
} from '@mui/material'

import { get } from 'utils/lodash'
import { getServiceHoverTitle } from 'components/pricing/pricingMethods'
import { DOLLAR_DECIMAL_DIGITS, PERCENTAGE_DECIMAL_DIGITS } from 'settings/constants/pricing'

import T from 'T'
import Constants from 'Constants'
import ToolTip from 'components/common/Tooltip'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { getPricingPeriod, isRecurringService as getIsRecurringService } from '../../utils/service'

const { MEDIA_SERVER } = Constants

const ChangePrice = ({ selectedValues = [], type = '', handleChangePriceDelete = noop, onHandleEditFieldsChange = noop }) => {
  let name = ''
  let price = ''
  let title = ''
  let priceTitle = T.PRICE
  if (type === 'services') {
    name = 'serviceName'
    price = 'value'
    title = T.SERVICE
    priceTitle = T.RECURRENCE
  } else if (type === 'taxes') {
    name = 'taxName'
    price = 'taxValue'
    title = T.TAX
  } else if (type === 'general') {
    name = 'feeName'
    price = 'value'
    title = T.FEE
    priceTitle = T.VALUE
  }

  return (
    <TableContainer className="change-pricing-table">
      <TableView stickyHeader aria-label="sticky table">
        <THead className="table-header">
          <TableRow>
            <TableCell>{title}</TableCell>
            <TableCell colSpan={type === 'services' ? 2 : 1}>{priceTitle}</TableCell>
            {type === 'services' ? <TableCell colSpan={2}>{T.PRICE}</TableCell> : <TableCell />}
          </TableRow>
        </THead>

        <TBody className="table-body">
          {selectedValues &&
            type !== 'services' &&
            Children.toArray(
              compact(selectedValues).map(service => {
                let valueType = ''
                if (get(service, 'taxValueType') !== undefined) {
                  valueType = get(service, 'taxValueType', '%')
                }

                if (get(service, 'valueType') !== undefined) {
                  valueType = get(service, 'valueType', '%')
                }

                const hoverTitle = getServiceHoverTitle(service)

                return (
                  <TableRow>
                    <TableCell>
                      <div className="services-field">
                        <ToolTip title={hoverTitle}>
                          <HHTextField
                            fullWidth
                            className="change-price"
                            value={service[name]}
                            size="small"
                            autoComplete="off"
                            name={name}
                            onChange={e => onHandleEditFieldsChange(e, service)}
                          />
                        </ToolTip>
                      </div>
                    </TableCell>

                    <TableCell>
                      {type !== 'services' && (
                        <div className="prices-field">
                          <div
                            className={`dollar-btn ${['$', 'dollar'].includes(valueType) ? 'value-type-active' : ''}`}
                            onClick={() => onHandleEditFieldsChange('$', service)}
                          >
                            $
                          </div>

                          <div
                            className={`percent-btn ml10 ${['%', 'percentage'].includes(valueType) ? 'value-type-active' : ''}`}
                            onClick={() => onHandleEditFieldsChange('%', service)}
                          >
                            %
                          </div>

                          <NumericFormat
                            id="outlined-size-small"
                            placeholder="0.00"
                            value={service[price]}
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            className="ml10"
                            name={price}
                            onChange={e => onHandleEditFieldsChange(e, service, 'price')}
                            customInput={TextField}
                            thousandSeparator
                            decimalSeparator="."
                            decimalScale={service?.taxValueType === '%' ? PERCENTAGE_DECIMAL_DIGITS : DOLLAR_DECIMAL_DIGITS}
                          />
                        </div>
                      )}
                      {type === 'services' && (
                        <div className="prices-field">
                          <NumericFormat
                            id="outlined-size-small"
                            placeholder="0.00"
                            value={service[price]}
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            name={price}
                            onChange={e => onHandleEditFieldsChange(e, service, 'price')}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            customInput={TextField}
                            thousandSeparator
                            decimalSeparator="."
                            decimalScale={2}
                          />
                        </div>
                      )}
                    </TableCell>

                    <TableCell>
                      <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleChangePriceDelete(service, selectedValues)} />
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          {selectedValues &&
            type === 'services' &&
            Children.toArray(
              compact(selectedValues).map(service => {
                let valueType = ''
                if (get(service, 'taxValueType') !== undefined) {
                  valueType = get(service, 'taxValueType', '%')
                }

                if (get(service, 'valueType') !== undefined) {
                  valueType = get(service, 'valueType', '%')
                }

                const hoverTitle = getServiceHoverTitle(service)
                const recurrenceInterval = get(service, 'recurrenceInterval')
                const recurrenceFrequency = get(service, 'recurrenceFrequency')
                const pricingPeriod = getPricingPeriod('', recurrenceInterval)
                const isRecurringService = getIsRecurringService(capitalize(service.serviceType))

                return (
                  <>
                    <TableRow>
                      <TableCell padding="none" colSpan={4} sx={{ pt: 2 }}>
                        <ToolTip title={hoverTitle}>
                          <HHTextField
                            fullWidth
                            value={service[name]}
                            autoComplete="off"
                            name={name}
                            onChange={e => onHandleEditFieldsChange(e, service)}
                          />
                        </ToolTip>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      {isRecurringService && (
                        <TableCell padding="none" className="no-border" colSpan={2} sx={{ py: 2 }}>
                          <HHTextField
                            fullWidth
                            select
                            value={recurrenceFrequency}
                            autoComplete="off"
                            name="recurrenceFrequency"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">{pricingPeriod}</InputAdornment>,
                            }}
                            onChange={e => onHandleEditFieldsChange(e, service)}
                          >
                            <MenuItem value={1}>1x</MenuItem>
                            <MenuItem value={2}>2x</MenuItem>
                            <MenuItem value={3}>3x</MenuItem>
                            <MenuItem value={4}>4x</MenuItem>
                            <MenuItem value={5}>5x</MenuItem>
                            <MenuItem value={6}>6x</MenuItem>
                            <MenuItem value={7}>7x</MenuItem>
                          </HHTextField>
                        </TableCell>
                      )}
                      <TableCell
                        padding="none"
                        className="no-border"
                        colSpan={isRecurringService ? 2 : 4}
                        sx={{ py: 2, pl: isRecurringService ? 2 : 0 }}
                      >
                        <NumericFormat
                          placeholder="0.00"
                          value={service[price]}
                          variant="outlined"
                          autoComplete="off"
                          name={price}
                          fullWidth
                          onChange={e => onHandleEditFieldsChange(e, service, 'price')}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          customInput={HHTextField}
                          thousandSeparator
                          decimalSeparator="."
                          decimalScale={2}
                        />
                      </TableCell>
                      <TableCell padding="none" className="no-border" sx={{ width: 4, height: 4 }}>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ pl: 2 }}>
                          <IconButton onClick={() => handleChangePriceDelete(service, selectedValues)}>
                            <img src={`${MEDIA_SERVER}Trash-Red.svg`} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                )
              })
            )}
        </TBody>
      </TableView>
    </TableContainer>
  )
}

ChangePrice.propTypes = {
  selectedValues: PropTypes.array,
  type: PropTypes.string,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
}

export default ChangePrice
