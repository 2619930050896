import { createSlice } from '@reduxjs/toolkit'

const responseSlice = createSlice({
  name: 'responseSlice',
  initialState: {
    canTakePayment: true,
  },
  reducers: {
    changeCanTakePayment: (state, action) => {
      state.canTakePayment = action.payload
    },
  },
})
export const { changeCanTakePayment } = responseSlice.actions

export default responseSlice.reducer
