import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import { ChevronUp } from '@styled-icons/heroicons-outline/ChevronUp'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { SERVICE_GROUP } from 'settings/constants/serviceGroups'
import { getPricingPeriod, isRecurringService } from 'utils/service'
import { CommonTextLabel, CommonTextfield } from 'components/common'

import T from 'T'
import AutoComplete from 'components/common/AutoComplete'
import { Stack, SvgIcon } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { noop } from 'lodash'

const ServiceTabFilterContent = ({
  service = {},
  measureMeta = [],
  materialsMeta = [],
  methodsMeta = [],
  serviceActionMeta = [],
  globalFilterOptions = {},
  onChange,
}) => {
  const recurrenceFrequency = get(service, 'recurrenceFrequency')
  const recurrenceIds = get(service, 'recurrenceIds', [])
  const businessLines = get(service, 'businessLines', [])

  const measureIds = get(service, 'measureIds', [])
  const materialIds = get(service, 'materialIds', [])
  const serviceMethodIds = get(service, 'serviceMethodIds', [])
  const serviceActionIds = get(service, 'serviceActionIds', [])

  const getFilterLabel = (refArray, translation) => (refArray.length ? '' : translation)

  return (
    <>
      <Box className="common radio">
        <CommonTextLabel title={T.TYPE} />
        <FormControl component="fieldset">
          <RadioGroup row value={get(service, 'searchType', '')} onChange={e => onChange('searchType', e.target.value, 'service')}>
            <FormControlLabel sx={{ width: '40%' }} value={T.SERVICE} control={<Radio />} label={T.SERVICE} />
            <FormControlLabel sx={{ width: '50%' }} value={SERVICE_GROUP} control={<Radio />} label={T.SERVICE_GROUP} />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={1} className="common radio">
        <CommonTextLabel title={T.RECURRENCE} />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="recurrence"
            name="recurrence"
            row
            value={get(service, 'serviceType', '')}
            onChange={e => onChange('serviceType', e.target.value, 'service')}
          >
            <FormControlLabel sx={{ width: '40%' }} value={T.RECURRING} control={<Radio />} label={T.RECURRING} />
            <FormControlLabel sx={{ width: '50%' }} value={T.ON_REQUEST} control={<Radio />} label={T.ON_REQUEST} />
          </RadioGroup>
        </FormControl>
      </Box>

      {isRecurringService(get(service, 'serviceType')) && (
        <Box mt={1} display="flex" className="common autocomplete">
          <Box display="flex" mr={2} className="number-arrows-field">
            <CommonTextfield
              readOnly
              sx={{ width: 64 }}
              variant="outlined"
              defaultSize
              withClasses={false}
              value={`${recurrenceFrequency}x`}
              onChange={noop}
            />
            <Box display="flex" flexDirection="column" className="tool-section">
              <Stack sx={{ flex: 1 }} className="upper" alignItems="center" justifyContent="center">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={e => {
                    e.stopPropagation()
                    if (recurrenceFrequency < 7) {
                      onChange('recurrenceFrequency', recurrenceFrequency + 1, 'service')
                    }
                  }}
                >
                  <SvgIcon sx={{ fontSize: 16 }}>
                    <ChevronUp />
                  </SvgIcon>
                </IconButton>
              </Stack>
              <Stack sx={{ flex: 1 }} className="lower" alignItems="center" justifyContent="center">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={e => {
                    e.stopPropagation()
                    if (recurrenceFrequency > 1) {
                      onChange('recurrenceFrequency', recurrenceFrequency - 1, 'service')
                    }
                  }}
                >
                  <SvgIcon sx={{ fontSize: 16 }}>
                    <ChevronDown />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Box>
          </Box>
          <Box width="100%">
            <AutoComplete
              backFlag
              isHandleCheckbox
              maxHeight={150}
              metaData={get(globalFilterOptions, 'recurrence', [])}
              label={T.RECURRENCE}
              getByLabelText={option => getPricingPeriod(get(option, 'recurrencePer'), get(option, 'recurrenceInterval'))}
              handleCheckboxValue={(label, result) => onChange('recurrenceIds', result, 'service')}
              checkedRecords={recurrenceIds}
              getPlaceholder={getFilterLabel(recurrenceIds, `Select ${T.RECURRENCE}`)}
            />
          </Box>
        </Box>
      )}

      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={T.BUSINESS_LINE} />
        <AutoComplete
          multiple={false}
          isHandleCheckbox
          maxHeight={150}
          metaData={get(globalFilterOptions, 'businessLines', [])}
          label={T.BUSINESS_LINE}
          getByLabelText={option => get(option, 'businessLineName', '')}
          handleCheckboxValue={(label, result) => onChange('businessLines', result ? [result] : [], 'service', 'filters')}
          checkedRecords={businessLines}
          getPlaceholder={getFilterLabel(businessLines, `Select ${T.BUSINESS_LINE}`)}
        />
      </Box>

      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={T.MEASURE} />
        <AutoComplete
          isHandleCheckbox
          maxHeight={150}
          metaData={measureMeta}
          label={T.MEASURE}
          getByLabelText={option => `${get(option, 'volume', '')} ${get(option, 'unit', '')}`}
          handleCheckboxValue={(label, result) => onChange('measureIds', result, 'service')}
          checkedRecords={measureIds}
          getPlaceholder={getFilterLabel(measureIds, `Select ${T.MEASURE}`)}
        />
      </Box>

      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={`${T.MATERIAL}s`} />
        <AutoComplete
          isHandleCheckbox
          maxHeight={150}
          metaData={materialsMeta}
          label={`${T.MATERIAL}s`}
          getByLabelText={option => option.materialType}
          handleCheckboxValue={(label, result) => onChange('materialIds', result, 'service')}
          checkedRecords={materialIds}
          getPlaceholder={getFilterLabel(materialIds, `Select ${T.MATERIAL}s`)}
        />
      </Box>

      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={T.METHOD} />
        <AutoComplete
          isHandleCheckbox
          maxHeight={150}
          metaData={methodsMeta}
          label={T.METHOD}
          getByLabelText={option => option.methodName}
          handleCheckboxValue={(label, result) => onChange('serviceMethodIds', result, 'service')}
          checkedRecords={serviceMethodIds}
          getPlaceholder={getFilterLabel(serviceMethodIds, `Select ${T.METHOD}`)}
        />
      </Box>

      <Box mt={2} className="common autocomplete">
        <CommonTextLabel title={`${T.ACTION}s`} />
        <AutoComplete
          isHandleCheckbox
          maxHeight={150}
          metaData={serviceActionMeta}
          label={`${T.ACTION}s`}
          getByLabelText={option => option.actionName}
          handleCheckboxValue={(label, result) => onChange('serviceActionIds', result, 'service')}
          checkedRecords={serviceActionIds}
          getPlaceholder={getFilterLabel(serviceActionIds, `Select ${T.ACTION}s`)}
        />
      </Box>
    </>
  )
}

ServiceTabFilterContent.propTypes = {
  service: PropTypes.object,
  measureMeta: PropTypes.array,
  materialsMeta: PropTypes.array,
  methodsMeta: PropTypes.array,
  serviceActionMeta: PropTypes.array,
  globalFilterOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default memo(ServiceTabFilterContent)
