import T from 'T'
import { INVOICE_STATUS } from 'settings/constants/billing'
import { PAYMENT_METHODS, PAYMENT_STATUS } from 'settings/constants/payment'

const { PAID, PARTIAL, UNPAID } = INVOICE_STATUS
const { CASH, CHECK, CREDIT_CARD, ACH, OTHER, ACCOUNT_CREDIT } = PAYMENT_METHODS
const { APPROVED, DECLINED, REVERSED, TRANSFERRED, FAILED } = PAYMENT_STATUS

export const FILTER_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  DATE: 'date',
  CHECKBOX: 'checkbox',
  META: 'meta',
}

export const FILTER_SUB_TYPES = {
  DATE: 'date',
  DATE_TIME: 'dateTime',
  MONEY: 'money',
  PRICE: 'price',
  NOTE: 'note',
}

export const TEXT_FILTERS = [
  { key: 'MATCH_TO', value: 'Contains' },
  { key: 'NOT_MATCH_TO', value: 'Does Not Contain' },
  { key: 'MATCH_START', value: 'Starts With' },
  { key: 'EQUAL_TO', value: 'Equals' },
  { key: 'NOT_EQUAL', value: 'Does Not Equal' },
]

export const META_FILTERS = [{ key: 'EXIST_IN', value: 'Equals' }]

export const NUMBER_FILTERS = [
  { key: 'EQUAL_TO', value: 'Is Equal to' },
  { key: 'NOT_EQUAL', value: 'Is Not Equal to' },
  { key: 'GREATER_THEN', value: 'Is Greater Than' },
  { key: 'LESS_THEN', value: 'Is Less Than' },
]

// Duplicate keys not supported in select , so append value also
export const DATE_FILTERS = [
  { key: 'DATE_TODAY', value: 'Today' },
  { key: 'DATE_YESTERDAY', value: 'Yesterday' },
  { key: 'DATE_THIS_WEEK', value: 'This Week' },
  { key: 'DATE_LAST_WEEK', value: 'Last Week' },
  { key: 'DATE_PAST_TWO_WEEK', value: 'Past Two Weeks' },
  { key: 'DATE_THIS_MONTH', value: 'This Month' },
  { key: 'DATE_LAST_MONTH', value: 'Last Month' },
  { key: 'DATE_BEFORE', value: 'Before' },
  { key: 'DATE_AFTER', value: 'After' },
  { key: 'DATE_IS_BETWEEN', value: 'Specific Date' },
  { key: 'DATE_RANGE_IS_BETWEEN', value: 'Date Range' },
]

export const NULL_FILTERS = label => {
  const labelName = label.split(' ')[0]
  return [
    { key: 'IS_NOT_NULL', value: labelName },
    { key: 'IS_NULL', value: `Not ${labelName}` },
  ]
}

export const CHECKBOX_FILTERS = [{ key: 'EQUAL_TO', value: 'Equals' }]

export const CHECKBOX_OPTIONS = (columnName, label, subType) => {
  // later add component check also
  // subType is used where columnName is same
  const type = [
    'invoiceStatus',
    'scheduledStatus',
    'serviceStatus',
    'deliveryMethod',
    'sentInvoicesStatus',
    'workOrderStatus',
    'pricingStatus',
    'autoPay',
    'paymentMethod',
    'autoPaymentType',
    'accountActive',
  ].includes(subType)
    ? subType
    : columnName

  switch (type) {
    // Billing case
    case 'invoiceStatus':
      return [
        { label: T.PAID, value: PAID },
        { label: T.UNPAID, value: UNPAID },
        { label: T.DRAFT, value: PARTIAL },
      ]

    case 'deliveryMethod':
      return [
        { label: `${T.SENT_BY} ${label}`, value: 'true' },
        { label: `${T.NOT_SENT_BY} ${label}`, value: 'false' },
      ]

    case 'type':
      return [
        { label: T.ADVANCE, value: 'Advance' },
        { label: T.ARREARS, value: 'Arrears' },
      ]

    case 'sentInvoicesStatus':
      return [
        { label: T.NOT_STARTED, value: 'Not Started' },
        { label: T.COMPLETE, value: 'Complete' },
      ]

    case 'workOrderStatus':
      return [
        { label: T.SCHEDULED, value: 'Scheduled' },
        { label: T.SERVICED, value: 'Serviced' },
      ]

    case 'transactionStatus':
      return [
        { label: T.APPROVED, value: APPROVED },
        { label: T.REVERSED, value: REVERSED },
        { label: T.TRANSFER, value: TRANSFERRED },
        { label: T.DECLINED, value: DECLINED },
        { label: T.FAILED, value: FAILED },
      ]
    case 'pricingStatus':
    case 'accountActive':
      return [
        { label: T.ACTIVE, value: 'true' },
        { label: T.DEACTIVATED, value: 'false' },
      ]

    case 'isSubItem':
      return [
        { label: T.SUB_ITEM, value: 'true' },
        { label: T.NOT_SUB_ITEM, value: 'false' },
      ]

    case 'isAutoLink':
    case 'autoPay':
      return [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ]

    case 'costPlus':
      return [
        { label: T.COST_PLUS, value: 'true' },
        { label: T.NOT_COST_PLUS, value: 'false' },
      ]

    // Report filters
    case 'serviceStatus':
      return [
        { label: T.ACTIVE, value: 'Active' },
        { label: T.DRAFT, value: 'Draft' },
        { label: T.DEACTIVATED, value: 'Deactivated' },
      ]
    case 'scheduledStatus':
      return [
        { label: T.SERVICED, value: 'Serviced' },
        { label: T.IN_PROCESS, value: 'In Process' },
        { label: T.SCHEDULED, value: 'Scheduled' },
      ]
    case 'paymentMethod':
      return [
        { label: T.ACH, value: ACH },
        { label: T.CASH, value: CASH },
        { label: T.CHECK, value: CHECK },
        { label: T.CREDIT_CARD, value: CREDIT_CARD },
        { label: T.OTHER, value: OTHER },
        { label: T.ACCOUNT_CREDIT, value: ACCOUNT_CREDIT },
      ]
    case 'autoPaymentType':
      return [
        { label: T.ACH, value: T.ACH },
        { label: T.CREDIT_CARD, value: T.CREDIT_CARD },
      ]
    default:
      return []
  }
}
