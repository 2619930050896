import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { Radio, RadioGroup, FormControl, Box, Stack, Typography, useTheme, FormControlLabel, Checkbox, Button } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import AddIcon from '@mui/icons-material/Add'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import Tooltip from '@mui/material/Tooltip'
import { XCircle } from '@styled-icons/heroicons-solid/XCircle'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { createDateFromString, formatDateToBEFormatDateFns, isNotInvalidDate } from 'utils/date'
import { getPricingPeriod, isRecurringService, isOnRequestService, isWeeklyRecurrence, isNonWeeklyRecurrence } from 'utils/service'
import { ALL_DAYS, WEEK_OF_MONTH_IN_NUMBERS } from 'settings/constants/day'
import { BACKEND_DATE_FORMAT } from 'settings/constants/date'
import { CommonTextLabel, CommonTextfield, CommonSelect, CommonIconSelectExtended, CommonSwitch } from 'components/common'

import T from 'T'
import Constants from 'Constants'
import ConfirmationModal from 'components/modal/ConfirmationModal'

import HHDatePicker from 'components/common/HHDatePicker'
import { isAfter, isBefore, isSameDay } from 'date-fns'
import moment from 'moment'
import { getArraySortedAscendingOrder } from 'data/utils/ascendingSortSelector'
import ScheduleServiceGroup from './ScheduleServiceGroup'
import 'react-datepicker/dist/react-datepicker.css'

import './style.scss'
import { HHTextField } from '../../../form-fields/v5'

const { MEDIA_SERVER } = Constants

const ScheduleTabContent = ({
  isGroupSelected = false,
  isAddMode = true,
  serviceType = '',
  pricedServiceDetails = {},
  eventInstanceType = '',
  schedule = {},
  billingSchedule = {},
  serviceActionAllMeta = [],
  onChange,
}) => {
  const theme = useTheme()
  const [actionList, setActionList] = useState([])
  const [isOpenEndDateConfirmModal, setIsOpenEndDateConfirmModal] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [endDateConfirmValue, setEndDateConfirmValue] = useState(false)

  const recurrenceFrequency = get(pricedServiceDetails, 'recurrenceFrequency') || 1
  const recurrenceInterval = get(pricedServiceDetails, 'recurrenceInterval', '')
  const recurrencePer = get(pricedServiceDetails, 'recurrencePer', '')

  const serviceEvents = getArraySortedAscendingOrder({ arrayToSort: get(schedule, 'serviceEvents', []), keyToSort: 'actionDate' })
  // Check line 26 drawer/service/validation.js
  const onlyDays = serviceEvents.map(event => get(event, 'dayOfTheWeek', event.eventType))
  const defaultActionType = get(schedule, 'defaultActionType', '')
  const daysSelectionInfo = `${T.PLEASE_SELECT} ${recurrenceFrequency} ${T.DAY.toLowerCase()}(s)`
  const isDifferentBillingSchedule = get(billingSchedule, 'isDifferentBillingSchedule', false)
  const scheduleStartDate = createDateFromString(get(schedule, 'startDate', null))
  const scheduleEndDate = createDateFromString(get(schedule, 'endDate', null))
  const billingStartDate = createDateFromString(get(billingSchedule, 'startDate', null))
  const billingEndDate = createDateFromString(get(billingSchedule, 'endDate', null))
  useEffect(() => {
    if (isOnRequestService(serviceType)) {
      setActionList(cloneDeep(serviceActionAllMeta))
    }
  }, [serviceType])

  return (
    <div className="schedule-tab-content common-padding-lr-3-5">
      {isRecurringService(serviceType) && (
        <Box className="recurring" backgroundColor={theme.palette.background.paper}>
          <Stack alignItems="center" justifyContent="space-between" flexDirection="row" className="mt3-5 start-end-date">
            <Stack className="date-column">
              <CommonTextLabel title={T.START_DATE} isRequired />
              <HHDatePicker
                handleChange={date => {
                  if (isNotInvalidDate(date)) {
                    onChange('startDate', formatDateToBEFormatDateFns(date), 'schedule', 'isStartDateInvalid', false)
                  } else {
                    onChange('startDate', '', 'schedule', 'isStartDateInvalid', true)
                  }
                }}
                value={scheduleStartDate}
                TextFieldProps={{
                  error: get(schedule, 'isStartDateInvalid', false),
                  helperText: get(schedule, 'isStartDateInvalid', false) && T.INVALID_DATE,
                }}
                shouldDisableDate={date => {
                  const dateToCompare = createDateFromString(get(schedule, 'endDate', ''))
                  if (!dateToCompare) return false
                  return !isBefore(date, dateToCompare) && !isSameDay(date, dateToCompare)
                }}
              />
            </Stack>
            <Stack className="date-column">
              <Stack justifyContent="space-between" alignItems="center" flexDirection="row">
                <CommonTextLabel title={T.END_DATE} />
                <CommonTextLabel title="Optional" className="end-date" />
              </Stack>
              <HHDatePicker
                handleChange={date => {
                  if (isNotInvalidDate(date)) {
                    if (!isAddMode && date) {
                      setEndDateConfirmValue(formatDateToBEFormatDateFns(date))
                      setIsOpenEndDateConfirmModal(true)
                    }
                    onChange('endDate', formatDateToBEFormatDateFns(date), 'schedule', 'isEndDateInvalid', false)
                  } else {
                    onChange('endDate', '', 'schedule', 'isEndDateInvalid', false)
                  }
                }}
                value={scheduleEndDate}
                TextFieldProps={{
                  error: get(schedule, 'isEndDateInvalid', false),
                  helperText: get(schedule, 'isEndDateInvalid', false) && T.INVALID_DATE,
                }}
                shouldDisableDate={date => {
                  const dateToCompare = createDateFromString(get(schedule, 'startDate', null))
                  if (dateToCompare) return !isAfter(date, dateToCompare) && !isSameDay(date, dateToCompare)
                }}
              />
            </Stack>
          </Stack>

          <FormControlLabel
            sx={{
              pl: 1.5,
              pt: 1.5,
            }}
            control={
              <Checkbox
                disableRipple
                sx={{
                  p: 0,
                  pr: 1,
                }}
                icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} alt="" />}
                checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedFull.svg`} alt="" />}
                color="primary"
                checked={get(schedule, 'endDate', '') && get(schedule, 'deactivateOnEndDate', true)}
                onChange={event => {
                  if (!get(schedule, 'endDate', '')) setIsOpenConfirmModal(true)
                  onChange('deactivateOnEndDate', event.target.checked, 'schedule')
                }}
              />
            }
            label={T.DEACTIVATE_ON_END_DATE}
          />

          <Box mt={2}>
            <CommonSwitch
              title={T.DIFFERENT_BILLING_DATES}
              isChecked={get(billingSchedule, 'isDifferentBillingSchedule', false)}
              onChange={checked => {
                onChange('billingSchedule', {
                  isDifferentBillingSchedule: checked,
                  startDate: checked ? get(schedule, 'startDate', '') : '',
                  endDate: checked ? get(schedule, 'endDate', '') : '',
                })
              }}
            />
          </Box>
          <Stack sx={{ mt: 2 }} alignItems="center" justifyContent="space-between" className="start-end-date" flexDirection="row">
            <Stack className="date-column">
              <CommonTextLabel title={T.BILLING_START_DATE} isRequired={isDifferentBillingSchedule} />
              <HHDatePicker
                disabled={isDifferentBillingSchedule === false}
                handleChange={date => {
                  if (isNotInvalidDate(date)) {
                    onChange('startDate', formatDateToBEFormatDateFns(date), 'billingSchedule', 'isStartDateInvalid', false)
                  } else {
                    onChange('startDate', '', 'billingSchedule', 'isStartDateInvalid', true)
                  }
                }}
                value={billingStartDate}
                TextFieldProps={{
                  error: get(billingSchedule, 'isStartDateInvalid', false),
                  helperText: get(billingSchedule, 'isStartDateInvalid', false) && T.INVALID_DATE,
                }}
                shouldDisableDate={date => {
                  const dateToCompare = createDateFromString(get(billingSchedule, 'endDate', ''))
                  if (!dateToCompare) return false
                  return !isBefore(date, dateToCompare) && !isSameDay(date, dateToCompare)
                }}
              />
            </Stack>
            <Stack className="date-column">
              <Stack justifyContent="space-between" alignItems="center" flexDirection="row">
                <CommonTextLabel title={T.BILLING_END_DATE} />
                <CommonTextLabel title={isDifferentBillingSchedule && 'Optional'} className="end-date" />
              </Stack>
              <HHDatePicker
                disabled={isDifferentBillingSchedule === false}
                handleChange={date => {
                  if (isNotInvalidDate(date)) {
                    onChange('endDate', formatDateToBEFormatDateFns(date), 'billingSchedule', 'isEndDateInvalid', false)
                  } else {
                    onChange('endDate', '', 'billingSchedule', 'isEndDateInvalid', false)
                  }
                }}
                value={billingEndDate}
                TextFieldProps={{
                  error: get(billingSchedule, 'isEndDateInvalid', false),
                  helperText: get(billingSchedule, 'isEndDateInvalid', false) && T.INVALID_DATE,
                }}
                shouldDisableDate={date => {
                  const dateToCompare = createDateFromString(get(billingSchedule, 'startDate', null))
                  if (dateToCompare) return !isAfter(date, dateToCompare) && !isSameDay(date, dateToCompare)
                }}
              />
            </Stack>
          </Stack>
          <Box mt={2}>
            <CommonTextLabel title={T.RECURRENCE} />
            <Stack alignItems="center" flexDirection="row">
              <HHTextField sx={{ width: '3.5rem' }} readOnly value={`${recurrenceFrequency}x`} />
              <HHTextField sx={{ ml: 2 }} fullWidth readOnly value={getPricingPeriod(recurrencePer, recurrenceInterval)} />
            </Stack>
          </Box>

          {isWeeklyRecurrence(recurrenceInterval) && (
            <Stack sx={{ mt: 2.5 }}>
              <Box display="flex" p={1} sx={{ borderRadius: 1, backgroundColor: 'rgba(0, 115, 230, 0.1)' }}>
                <ErrorOutlineIcon sx={{ width: 16, height: 16, color: 'primary.main', mr: 0.5 }} />
                <Typography variant="subtitle2" color="primary.main" lineHeight="17px">
                  {daysSelectionInfo}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" sx={{ mt: 0.5 }}>
                {Children.toArray(
                  ALL_DAYS.map(weekDay => {
                    const getCurrentSelection = serviceEvents.find(event => event.dayOfTheWeek === weekDay)
                    const isChecked = get(getCurrentSelection, 'dayOfTheWeek') === weekDay
                    const isMaxSelected = serviceEvents.length >= recurrenceFrequency

                    return (
                      <Box display="flex" sx={{ ml: 0.5 }}>
                        <FormControlLabel
                          sx={{ width: 120 }}
                          control={
                            <Tooltip title={isMaxSelected ? `You can only select ${recurrenceFrequency} day(s)` : ''}>
                              <Checkbox
                                icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} alt="" width="16" height="16" />}
                                checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedFull.svg`} alt="" />}
                                color="primary"
                                name={weekDay}
                                checked={isChecked}
                                onChange={e => {
                                  if (e.target.checked && isMaxSelected) return
                                  const cloneServiceEvents = cloneDeep(serviceEvents)
                                  const eventIndex = onlyDays.findIndex(day => day === weekDay)
                                  if (eventIndex === -1) {
                                    // New Week event
                                    onChange(
                                      'serviceEvents',
                                      [...cloneServiceEvents, { dayOfTheWeek: e.target.name, customerRequired: false }],
                                      'schedule'
                                    )
                                    return
                                  }

                                  const existingId = get(cloneServiceEvents, `[${eventIndex}].id`)
                                  let deletedServiceEventIds = get(schedule, 'deletedServiceEventIds', [])
                                  if (existingId) {
                                    deletedServiceEventIds = [...deletedServiceEventIds, existingId]
                                  }

                                  // Remove existing day
                                  onChange('schedule', {
                                    ...schedule,
                                    serviceEvents: cloneServiceEvents.filter((ev, index) =>
                                      existingId ? ev.id !== existingId : index !== eventIndex
                                    ),
                                    deletedServiceEventIds,
                                  })
                                }}
                              />
                            </Tooltip>
                          }
                          label={weekDay}
                        />
                        {isChecked && (
                          <FormControlLabel
                            sx={{ ml: 2 }}
                            control={
                              <Checkbox
                                icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} width="16" height="16" alt="" />}
                                checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedFull.svg`} alt="" />}
                                color="primary"
                                name="Required"
                                checked={get(getCurrentSelection, 'customerRequired', false)}
                                onChange={e => {
                                  const cloneServiceEvents = cloneDeep(serviceEvents)
                                  const eventIndex = onlyDays.findIndex(day => day === weekDay)
                                  set(cloneServiceEvents, `[${eventIndex}].customerRequired`, e.target.checked)
                                  onChange('serviceEvents', cloneServiceEvents, 'schedule')
                                }}
                              />
                            }
                            label="Required"
                          />
                        )}
                      </Box>
                    )
                  })
                )}
              </Box>
            </Stack>
          )}
          {isNonWeeklyRecurrence(recurrenceInterval) && (
            <div className="rec-options other-rec">
              <div className="common radio">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="recurrence"
                    name="recurrence"
                    row
                    value={eventInstanceType}
                    onChange={e => {
                      const { value } = e.target
                      if (value === T.DAY_OF_WEEK) {
                        onChange('eventInstanceType', value, '', 'schedule', {
                          ...schedule,
                          serviceEvents: [{ dayOfTheWeek: T.MONDAY, eventOccurence: 1, id: get(schedule, 'serviceEvents[0].id', '') }],
                        })
                        return
                      }
                      onChange('eventInstanceType', value, '', 'schedule', {
                        ...schedule,
                        serviceEvents: [{ dayOfTheMonth: 1, id: get(schedule, 'serviceEvents[0].id', '') }],
                      })
                    }}
                  >
                    <FormControlLabel value={T.DAY_OF_WEEK} control={<Radio />} label={T.DAY_OF_WEEK} />
                    <FormControlLabel value={T.SPECIFIC_DATE} control={<Radio />} label={T.SPECIFIC_DATE} />
                  </RadioGroup>
                </FormControl>
              </div>

              {eventInstanceType === T.DAY_OF_WEEK && (
                <Stack alignItems="center" flexDirection="row">
                  <CommonSelect
                    className="common-service-select"
                    value={get(schedule, 'serviceEvents[0].eventOccurence', '')}
                    options={WEEK_OF_MONTH_IN_NUMBERS}
                    onChange={e => {
                      const newEvent = [
                        {
                          eventOccurence: e.target.value,
                          dayOfTheWeek: get(schedule, 'serviceEvents[0].dayOfTheWeek', ''),
                          id: get(schedule, 'serviceEvents[0].id', ''),
                        },
                      ]
                      onChange('serviceEvents', newEvent, 'schedule')
                    }}
                  />

                  <CommonSelect
                    className="day-select common-service-select"
                    value={get(schedule, 'serviceEvents[0].dayOfTheWeek', '')}
                    options={ALL_DAYS}
                    onChange={e => {
                      const newEvent = [
                        {
                          eventOccurence: get(schedule, 'serviceEvents[0].eventOccurence', ''),
                          dayOfTheWeek: e.target.value,
                          id: get(schedule, 'serviceEvents[0].id', ''),
                        },
                      ]
                      onChange('serviceEvents', newEvent, 'schedule')
                    }}
                  />
                </Stack>
              )}

              {eventInstanceType === T.SPECIFIC_DATE && (
                <Stack alignItems="center">
                  <CommonTextfield
                    value={get(schedule, 'serviceEvents[0].dayOfTheMonth', '')}
                    onChange={e => {
                      const newEvent = [{ dayOfTheMonth: e.target.value, id: get(schedule, 'serviceEvents[0].id', ''), eventOccurence: 0 }]
                      onChange('serviceEvents', newEvent, 'schedule')
                    }}
                  />
                </Stack>
              )}
            </div>
          )}
        </Box>
      )}

      {isOnRequestService && isGroupSelected && (
        <ScheduleServiceGroup
          serviceType={serviceType}
          pricedServiceDetails={pricedServiceDetails}
          schedule={schedule}
          serviceEvents={serviceEvents}
          onChange={onChange}
        />
      )}

      {/* Later combine this with ScheduleServiceGroup */}
      {isOnRequestService(serviceType) && !isGroupSelected && (
        <div className="on-request">
          <Box mt={3} className="upcoming-events">
            <CommonTextLabel title="Upcoming Service Events" className="label" />
            {Children.toArray(
              serviceEvents.map((serviceEvent, index) => {
                const actionDate = get(serviceEvent, 'actionDate', '')
                const actionType = get(serviceEvent, 'actionType', '')
                const formatDate = createDateFromString(actionDate)

                return (
                  <Stack sx={{ mt: 2 }} alignItems="center" flexDirection="row">
                    <HHDatePicker
                      handleChange={date => {
                        if (isNotInvalidDate(date)) {
                          const cloneServiceEvents = cloneDeep(serviceEvents)
                          set(cloneServiceEvents, `[${index}].actionDate`, date ? moment(date).format(BACKEND_DATE_FORMAT) : '')
                          set(cloneServiceEvents, `[${index}].isValid`, true)
                          onChange('serviceEvents', cloneServiceEvents, 'schedule')
                        } else {
                          const cloneServiceEvents = cloneDeep(serviceEvents)
                          set(cloneServiceEvents, `[${index}].actionDate`, '')
                          set(cloneServiceEvents, `[${index}].isValid`, false)
                          onChange('serviceEvents', cloneServiceEvents, 'schedule')
                        }
                        // for edit mode, if service event date is changed then activate the service
                        if (!isAddMode) {
                          onChange('deactivate', false, 'advanced')
                        }
                      }}
                      disabled={!isAddMode}
                      value={formatDate}
                      TextFieldProps={{
                        fullWidth: false,
                        error: !get(serviceEvents, `[${index}].isValid`, true),
                        helperText: get(billingSchedule, 'isEndDateInvalid', false) && T.INVALID_DATE,
                      }}
                    />
                    <Stack alignItems="center" flexDirection="row" sx={{ width: '100%', ml: 2 }}>
                      <CommonIconSelectExtended
                        sx={{
                          ...(!isAddMode && {
                            backgroundColor: theme.palette.background.disabled,
                            cursor: 'not-allowed',
                          }),
                        }}
                        records={actionList}
                        selectedRecord={{ name: actionType }}
                        placeholder={`Select ${T.ACTION}s`}
                        type="action"
                        disabled={!isAddMode}
                        onChange={(isChanged, value) => {
                          if (isChanged) {
                            const cloneServiceEvents = cloneDeep(serviceEvents)
                            set(cloneServiceEvents, `[${index}].actionType`, value.name)
                            onChange('serviceEvents', cloneServiceEvents, 'schedule')
                            return
                          }
                          setActionList(serviceActionAllMeta.filter(action => action.name.toLowerCase().indexOf(value) > -1))
                        }}
                        onBlur={() => setActionList(cloneDeep(serviceActionAllMeta))}
                      />

                      {/* For edit mode, we can remove all service events */}
                      {isAddMode && index > 0 && (
                        <XCircle
                          className="icon-w-16 remove-event cursor-pointer"
                          onClick={() => {
                            const existingId = get(serviceEvent, 'id')
                            let deletedServiceEventIds = get(schedule, 'deletedServiceEventIds', [])
                            if (existingId) {
                              deletedServiceEventIds = [...deletedServiceEventIds, existingId]
                            }
                            onChange('schedule', {
                              ...schedule,
                              serviceEvents: serviceEvents.filter((list, eventIndex) => eventIndex !== index),
                              deletedServiceEventIds,
                            })

                            // only service event change
                            // onChange(
                            //   'serviceEvents',
                            //   serviceEvents.filter((list, eventIndex) => eventIndex !== index),
                            //   'schedule'
                            // );
                          }}
                        />
                      )}
                    </Stack>
                  </Stack>
                )
              })
            )}
          </Box>

          {isAddMode && (
            <Button
              size="small"
              sx={{ mt: 1 }}
              startIcon={<AddIcon />}
              endIcon={<EventAvailableIcon />}
              onClick={() => {
                onChange(
                  'serviceEvents',
                  [
                    ...serviceEvents,
                    {
                      actionDate: '',
                      actionType: get(serviceEvents, `[${serviceEvents.length - 1}].actionType`, '') || defaultActionType,
                    },
                  ],
                  'schedule'
                )
              }}
            >
              {T.EVENT}
            </Button>
          )}

          <Box mt={2}>
            <CommonTextLabel title="Default Action" />
            <CommonIconSelectExtended
              records={actionList}
              selectedRecord={{ name: defaultActionType }}
              placeholder={`Select ${T.ACTION}s`}
              type="action"
              onChange={(isChanged, value) => {
                if (isChanged) {
                  onChange('defaultActionType', value.name, 'schedule')
                  return
                }
                setActionList(serviceActionAllMeta.filter(action => action.name.toLowerCase().indexOf(value) > -1))
              }}
              onBlur={() => setActionList(cloneDeep(serviceActionAllMeta))}
            />
          </Box>
        </div>
      )}

      <ConfirmationModal
        className="global-drawer-confirm service-drawer-confirm"
        isOpen={isOpenEndDateConfirmModal}
        title={T.ON_RECCURING_SET_END_DATE_MSG}
        cancelButtonTitle={T.CANCEL}
        proceedButtonTitle={T.CONTINUE}
        onCancel={() => {
          onChange('endDate', '', 'schedule', 'isEndDateInvalid', false)
          setIsOpenEndDateConfirmModal(false)
        }}
        onProceed={() => {
          onChange('endDate', endDateConfirmValue, 'schedule', 'isEndDateInvalid', false)
          setIsOpenEndDateConfirmModal(false)
        }}
      />

      <ConfirmationModal
        className="global-drawer-confirm service-drawer-confirm"
        isOpen={isOpenConfirmModal}
        title={T.ON_RECCURING_DEACTIVATE_NO_END_DATE_MSG}
        proceedButtonTitle={T.CLOSE}
        hasCancelBtn={false}
        onProceed={() => {
          setIsOpenConfirmModal(false)
        }}
      />
    </div>
  )
}

ScheduleTabContent.propTypes = {
  isGroupSelected: PropTypes.string,
  isAddMode: PropTypes.bool,
  serviceType: PropTypes.string,
  schedule: PropTypes.object,
  billingSchedule: PropTypes.object,
  eventInstanceType: PropTypes.string,
  pricedServiceDetails: PropTypes.object,
  serviceActionAllMeta: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default memo(ScheduleTabContent)
