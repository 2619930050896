import { get } from 'utils/lodash'
import { INVOICE_STATUS } from 'settings/constants/billing'

const { UNPAID, PAID } = INVOICE_STATUS

export const getPaidInvoicesWIthAppliedAmount = (allInvoices, paidInvoices) => {
  const invoices = []
  allInvoices.forEach(invoice => {
    const isExist = paidInvoices.find(paidInvoice => paidInvoice?.invoiceNumber === invoice?.invoiceNumber)
    if (isExist) {
      // Show new invoice state and current payment applied amount
      invoices.push({ ...invoice, paymentAppliedAmountCents: get(isExist, 'paymentAppliedAmountCents', 0) })
    }
  })

  return invoices
}

export const getTransferFromUpdatedInvoices = (paidInvoices, transactionAmountCents = 0) => {
  if (!Array.isArray(paidInvoices) || !paidInvoices.length) {
    return []
  }

  let transactionAmountCentsCopy = transactionAmountCents

  return paidInvoices.map(invoice => {
    const { amountPaidCents } = invoice
    const updatedPaidTotal = Math.max(0, amountPaidCents - transactionAmountCentsCopy)
    transactionAmountCentsCopy = updatedPaidTotal > 0 ? 0 : Math.max(0, transactionAmountCentsCopy - amountPaidCents)

    return { ...invoice, status: UNPAID, amountPaidCents: updatedPaidTotal, paymentAppliedAmountCents: 0 }
  })
}

export const getTransferToUpdatedInvoice = (selectedInvoice = {}, transactionAmountCents = 0) => {
  const { amountPaidCents, totalCents } = selectedInvoice
  const updatedPaidTotal = amountPaidCents + transactionAmountCents
  const updatedStatus = updatedPaidTotal < totalCents ? UNPAID : PAID
  return {
    ...selectedInvoice,
    status: updatedStatus,
    amountPaidCents: updatedStatus === PAID ? totalCents : updatedPaidTotal,
    paymentAppliedAmountCents: updatedPaidTotal < totalCents ? transactionAmountCents : totalCents - amountPaidCents,
  }
}
