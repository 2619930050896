import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import {
  ADDRESS_SEARCH_TYPE,
  CONTACT_NAME_SEARCH_TYPE,
  CONTAINER_ID_SEARCH_TYPE,
  GENERAL_SEARCH_TYPE,
  PHONE_SEARCH_TYPE,
  RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
  ACCOUNT_NUMBER_SEARCH_TYPE,
  ACCOUNT_NAME_SEARCH_TYPE,
} from 'components/locations/filters/Search/settings'
import { noop, capitalize } from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import T from 'T'
import { HHTextField } from 'components/form-fields/v5'

const SearchTypeField = ({ showRecentlyMenuOptions = false, control, name = 'searchType', onChange = noop, sx, ...rest }) => {
  const recentlyProps = { ...(!showRecentlyMenuOptions && { sx: { display: 'none' } }) }
  return (
    <Controller
      control={control}
      name={name}
      render={props => {
        const {
          field: { onChange: hookFormOnChange, value, ref, ..._rest },
        } = props
        const handleChange = e => {
          const { value } = e.target
          hookFormOnChange(value)
          onChange(value)
        }
        return (
          <HHTextField select ref={ref} value={value} onChange={handleChange} IconComponent={ExpandMoreIcon} {..._rest} {...rest} sx={sx}>
            <MenuItem {...recentlyProps} value={RECENT_ACCOUNT_VIEWED_SEARCH_TYPE}>
              {T.RECENTLY_VIEWED}
            </MenuItem>
            <MenuItem {...recentlyProps} value={RECENT_ACCOUNT_ADDED_SEARCH_TYPE}>
              {T.RECENTLY_ADDED}
            </MenuItem>
            <MenuItem value={GENERAL_SEARCH_TYPE}>{T.GENERAL}</MenuItem>
            <MenuItem value={ADDRESS_SEARCH_TYPE}>{T.ADDRESS}</MenuItem>
            <MenuItem value={PHONE_SEARCH_TYPE}>{T.PHONE}</MenuItem>
            <MenuItem value={CONTACT_NAME_SEARCH_TYPE}>{T.CONTACT_NAME}</MenuItem>
            <MenuItem value={CONTAINER_ID_SEARCH_TYPE}>{T.CONTAINER_ID}</MenuItem>
            <MenuItem value={ACCOUNT_NUMBER_SEARCH_TYPE}>{capitalize(T.ACCOUNT_NUMBER)}</MenuItem>
            <MenuItem value={ACCOUNT_NAME_SEARCH_TYPE}>{capitalize(T.ACCOUNT_NAME)}</MenuItem>
          </HHTextField>
        )
      }}
    />
  )
}

SearchTypeField.propTypes = {
  showRecentlyMenuOptions: PropTypes.bool,
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object,
}

export default SearchTypeField
