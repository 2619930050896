import React, { useEffect } from 'react'
import T from 'T'
import HHAccordion from 'components/common/HHAccordion'
import { Divider, Grid, useTheme, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { resetRoutingTooltipCacheByAccountId, setRoutingServiceDate } from 'slices/customer/customerDetailsSlice'
import ScheduleStopDialog from 'components/route-manager/ScheduleStop/ScheduleStopDialog'
import YearlyHeapMap from './Graphs/YearlyHeapMap'
import ServicesGroupedByLocationList from './ServicesGroupedByLocationList'
import RoutingDatePickerSection from './RoutingDatePickerSection'
import ThreeMonthsHeapMap from './Graphs/ThreeMonthsHeapMap'

const Routing = ({ accountId, activeLocations }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const isXXL = useMediaQuery(theme.breakpoints.up('xxl'))

  useEffect(() => {
    dispatch(setRoutingServiceDate(new Date()))
    dispatch(resetRoutingTooltipCacheByAccountId())
  }, [])

  return (
    <HHAccordion isExpandCollapseHeightSame withNewColors isExpanded summary={T.ROUTING} showActionIcon={false}>
      {isXXL && (
        <Grid container>
          <Grid item xs={12}>
            <RoutingDatePickerSection />
          </Grid>
          <Grid item>
            <ThreeMonthsHeapMap />
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs mt={3}>
            <ServicesGroupedByLocationList accountId={accountId} activeLocations={activeLocations} showLastDivider={isXXL} />
          </Grid>
        </Grid>
      )}
      {!isXXL && (
        <Grid container>
          <Grid item xs={12}>
            <RoutingDatePickerSection />
          </Grid>
          <Grid item xs={12} mt={3}>
            <YearlyHeapMap />
          </Grid>
          <Grid item xs={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <Grid item xs={12}>
            <ServicesGroupedByLocationList accountId={accountId} activeLocations={activeLocations} />
          </Grid>
        </Grid>
      )}
      <ScheduleStopDialog />
    </HHAccordion>
  )
}

Routing.propTypes = {
  accountId: PropTypes.string.isRequired,
  activeLocations: PropTypes.array,
}

export default Routing
