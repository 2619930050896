import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PencilAlt } from '@styled-icons/heroicons-outline/PencilAlt'
import isEmpty from 'lodash/isEmpty'
import { get, set } from 'lodash'
import capitalize from 'lodash/capitalize'
import { billingAddEditFilterView, billingDeleteFilterView, getBillingSearchFilterViewList } from '../../../middleware/actions/billing'
import { SaveEditInvoiceViewModal } from '../../modal'
import T from '../../../T'
import SelectItem from '../../form-fields/v5/SelectItem'
import { HHTextField } from '../../form-fields/v5'
import {
  setPaymentsExistingFilterList,
  setPaymentsFiltersAndSortRulesFromSavedView,
  setPaymentsSavedViewInput,
  setPaymentsSavedViewSelectedFilterId,
  setPaymentsSavedViewState,
} from '../../../slices/billing/paymentsSlice'

const VISIBILITY_TYPE = 'User'
const SaveViews = () => {
  const dispatch = useDispatch()
  const rawSearchFilters = useSelector(state => state.payments.filters.searchFilters, shallowEqual)
  const rawSortRules = useSelector(state => state.payments.filters.sortRules, shallowEqual)
  const existingFilterList = useSelector(state => state.payments.savedView.existingFilterList, shallowEqual)
  const isModalOpen = useSelector(state => state.payments.savedView.isModalOpen)
  const input = useSelector(state => state.payments.savedView.input)
  const selectedFilter = useSelector(state => state.payments.savedView.selectedFilter, shallowEqual)
  const selectedFilterId = useSelector(state => state.payments.savedView.selectedFilterId)
  const searchFilters = isEmpty(rawSearchFilters) ? undefined : rawSearchFilters
  const sortRules = isEmpty(rawSortRules) ? undefined : rawSortRules
  const isAddMode = !get(selectedFilter, 'searchFilterName')

  const fetchExistingFilterList = () => {
    dispatch(
      getBillingSearchFilterViewList({ filterVisibilityType: 'User', activeBillingTab: 'payments' }, filterList => {
        dispatch(setPaymentsExistingFilterList(filterList))
      })
    )
  }

  const handleAfterSavedViewDeleted = deleteId => {
    const isCurrentDeleted = deleteId === selectedFilterId
    dispatch(
      setPaymentsSavedViewState({
        input: '',
        isModalOpen: false,
        selectedFilter: {},
        selectedFilterId: isCurrentDeleted ? '' : selectedFilterId,
        existingFilterList: existingFilterList.filter(filter => filter.searchFilterId !== deleteId),
      })
    )
    fetchExistingFilterList()
  }

  const handleSavedViewOptionChange = event => {
    const searchFilterId = event.target.value
    const existingSearchFilters = existingFilterList.find(filter => filter.searchFilterId === searchFilterId)
    const savedViewSearchFilters = get(existingSearchFilters, 'searchFilters', [])
    const savedViewSortRules = get(existingSearchFilters, 'sortRules', [])
    dispatch(setPaymentsSavedViewSelectedFilterId(searchFilterId))
    dispatch(
      setPaymentsFiltersAndSortRulesFromSavedView({
        searchFilters: savedViewSearchFilters,
        sortRules: savedViewSortRules,
      })
    )
  }

  const handleInputChange = (_, value) => {
    dispatch(setPaymentsSavedViewInput(value))
  }
  const handleDelete = () => {
    const id = get(selectedFilter, 'searchFilterId', '')
    if (!id) return
    dispatch(
      billingDeleteFilterView({ id, activeBillingTab: 'payments' }, status => {
        if (!status) return
        handleAfterSavedViewDeleted(id)
      })
    )
  }
  const handleSubmit = () => {
    const payload = {
      searchFilterName: input,
      visibility: VISIBILITY_TYPE,
      activeBillingTab: 'payments',
    }
    const id = get(selectedFilter, 'searchFilterId', '')
    const isAddMode = !id

    if (isAddMode) {
      set(payload, 'searchFilters', searchFilters)
      set(payload, 'sortRules', sortRules)
    } else {
      set(payload, 'id', id)
    }

    dispatch(
      billingAddEditFilterView(payload, (status, newId) => {
        if (!status) return
        dispatch(
          setPaymentsSavedViewState({
            isModalOpen: false,
            input: '',
            selectedFilter: {},
            ...(isAddMode && { selectedFilterId: newId }),
          })
        )
        fetchExistingFilterList()
      })
    )
  }
  const handleClose = () => {
    dispatch(
      setPaymentsSavedViewState({
        selectedFilter: {},
        input: '',
        isModalOpen: false,
      })
    )
  }

  const handleSaveViewButtonClick = () => {
    dispatch(
      setPaymentsSavedViewState({
        isModalOpen: true,
        input: '',
        selectedFilter: {},
      })
    )
  }

  useEffect(() => {
    fetchExistingFilterList()
  }, [])

  return (
    <Box display="flex" alignItems="flex-end">
      <Box>
        <HHTextField
          onChange={handleSavedViewOptionChange}
          size="small"
          label={capitalize(T.MY_SAVED_VIEWS)}
          select
          sx={{
            width: 350,
            justifyContent: 'right',
            '& .MuiOutlinedInput-root': {
              lineHeight: '20px',
            },
            svg: {
              '&: first-child': {
                display: 'none',
              },
            },
          }}
          value={[selectedFilterId]}
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          <SelectItem value="">{T.DEFAULT}</SelectItem>
          {existingFilterList.map(option => {
            return (
              <SelectItem value={option.searchFilterId}>
                <Box noWrap>
                  {option.searchFilterName}
                  {option.searchFilterId && (
                    <PencilAlt
                      style={{ width: 16, marginLeft: 18 }}
                      onClick={event => {
                        event.stopPropagation()
                        dispatch(
                          setPaymentsSavedViewState({
                            selectedFilter: option,
                            input: option.searchFilterName,
                            isModalOpen: true,
                          })
                        )
                      }}
                    />
                  )}
                </Box>
              </SelectItem>
            )
          })}
        </HHTextField>
      </Box>
      <Button disabled={false} onClick={handleSaveViewButtonClick} sx={{ ml: 1, whiteSpace: 'nowrap' }} size="small" variant="outlined">
        {capitalize(T.SAVE_VIEW)}
      </Button>
      <SaveEditInvoiceViewModal
        isOpen={isModalOpen}
        input={input}
        isAddMode={isAddMode}
        onChange={handleInputChange}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </Box>
  )
}

SaveViews.propTypes = {}

export default SaveViews
