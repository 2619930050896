import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'

import ValidatedFeeTableBody from './ValidatedFeeTableBody'
import ValidatedDisposalFeeTableBody from './ValidatedDisposalFeeTableBody'
import ValidatedListingTableHeader from 'components/pricing/ValidatedListingTableHeader'

const ValidatedFeeTable = ({
  feeTab,
  tierCount,
  validatedFees,
  saveConfSettings,
  confServices,
  handleSingleChange,
  handleDelete,
  onHandleTiersChange,
  handleTiers,
  onHandleValidatedFieldsChange,
  type,
  getFeeServiceName,
  onTableFeeScopeChange,
  onHandleMeasureChange,
}) => {
  return (
    <>
      <TableContainer className="taxes-table">
        <TableView>
          <ValidatedListingTableHeader confServices={confServices} handleSingleChange={handleSingleChange} tierCount={tierCount} />

          {feeTab === 'general' && (
            <ValidatedFeeTableBody
              validatedFees={validatedFees}
              confServices={confServices}
              handleDelete={handleDelete}
              onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
              type={type}
              getFeeServiceName={getFeeServiceName}
              saveConfSettings={saveConfSettings}
              onTableFeeScopeChange={onTableFeeScopeChange}
            />
          )}

          {feeTab === 'disposal' && (
            <ValidatedDisposalFeeTableBody
              validatedFees={validatedFees}
              tierCount={tierCount}
              confServices={confServices}
              handleDelete={handleDelete}
              onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
              onHandleTiersChange={onHandleTiersChange}
              handleTiers={handleTiers}
              type={type}
              getFeeServiceName={getFeeServiceName}
              saveConfSettings={saveConfSettings}
              onTableFeeScopeChange={onTableFeeScopeChange}
              onHandleMeasureChange={onHandleMeasureChange}
            />
          )}
        </TableView>
      </TableContainer>
    </>
  )
}

ValidatedFeeTable.defaultProps = {
  feeTab: 'general',
  tierCount: {},
  validatedFees: [],
  confServices: [],
  type: 'unique',
  saveConfSettings: false,
  handleSingleChange: null,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getFeeServiceName: null,
  onHandleTiersChange: null,
  handleTiers: null,
  onTableFeeScopeChange: null,
  onHandleMeasureChange: null,
}

ValidatedFeeTable.propTypes = {
  feeTab: PropTypes.string,
  tierCount: PropTypes.object,
  validatedFees: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  saveConfSettings: PropTypes.bool,
  handleSingleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  onHandleTiersChange: PropTypes.func,
  handleTiers: PropTypes.func,
  onTableFeeScopeChange: PropTypes.func,
  onHandleMeasureChange: PropTypes.func,
}

export default ValidatedFeeTable
