import api from 'api'
import {
  CACHE_TAG_ACCOUNT_NOTES_LIST,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
} from '../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllAccountNotes: builder.query({
      query: ({ accountId }) => ({
        url: `/api/v1/core/accounts/${accountId}/notes`,
        method: 'GET',
      }),
      providesTags: (result, error, { accountId }) => [CACHE_TAG_ACCOUNT_NOTES_LIST, { type: CACHE_TAG_ACCOUNT_NOTES_LIST, id: accountId }],
    }),
    getAccountNoteDetails: builder.query({
      query: ({ accountId, noteId }) => ({
        url: `/api/v1/core/accounts/${accountId}/notes/${noteId}`,
        method: 'GET',
      }),
    }),
    getAccountNoteHistory: builder.query({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/accounts/${id}/notes/${noteId}/history`,
        method: 'GET',
      }),
    }),
    createAccountNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/core/accounts/${id}/notes`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_ACCOUNT_NOTES_LIST, id },
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ],
    }),
    updateAccountNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/accounts/${id}/notes/${noteId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_TAG_ACCOUNT_NOTES_LIST, id }],
    }),
    patchAccountNote: builder.mutation({
      query: ({ id, noteId, data }) => ({
        url: `/api/v1/core/accounts/${id}/notes/${noteId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_ACCOUNT_NOTES_LIST, id },
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ],
    }),
    deleteAccountNote: builder.mutation({
      query: ({ id, noteId }) => ({
        url: `/api/v1/core/accounts/${id}/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: CACHE_TAG_ACCOUNT_NOTES_LIST, id },
        CACHE_TAG_ACCOUNT_LIST,
        CACHE_TAG_ACCOUNT_GROUP_DETAILS,
        CACHE_TAG_AGING_GROUP_DETAILS,
        CACHE_TAG_SUSPENSION_GROUP_DETAILS,
      ],
    }),
  }),
})

export const {
  useLazyGetAllAccountNotesQuery,
  useLazyGetAccountNoteDetailsQuery,
  useLazyGetAccountNoteHistoryQuery,
  useCreateAccountNoteMutation,
  useUpdateAccountNoteMutation,
  usePatchAccountNoteMutation,
  useDeleteAccountNoteMutation,
} = extendedApi
