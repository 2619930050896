import React, { memo } from 'react'
import ZoomToStopButton from 'components/route-manager/BulkActions/common/StopListItem/ZoomToStopButton'
import { ListItem, ListItemAvatar } from '@mui/material'
import StopListItemText from 'components/route-manager/BulkActions/common/StopListItem/StopListItemText'
import { listItemSecondaryActionClasses } from '@mui/material/ListItemSecondaryAction'
import PropTypes from 'prop-types'
import { getBulkStopData } from 'components/route-manager/BulkActions/settings'

const StopListItem = ({ row, AvatarContent, showAvatarContent = true }) => {
  const { accountName, addressStr, parsedDaysOfWeek, recurrence, serviceName, showWeekSummary } = getBulkStopData(row)
  return (
    <ListItem
      secondaryAction={<ZoomToStopButton row={row} />}
      disableGutters
      disablePadding
      sx={{
        flex: 1,
        width: '100%',
        [`.${listItemSecondaryActionClasses.root}.${listItemSecondaryActionClasses.disableGutters}`]: { right: 8, top: 16 },
      }}
    >
      {showAvatarContent && <ListItemAvatar sx={{ minWidth: 'unset' }}>{AvatarContent}</ListItemAvatar>}
      <StopListItemText
        accountName={accountName}
        serviceName={serviceName}
        parsedDaysOfWeek={parsedDaysOfWeek}
        addressStr={addressStr}
        recurrence={recurrence}
        showWeekSummary={showWeekSummary}
      />
    </ListItem>
  )
}

StopListItem.propTypes = {
  row: PropTypes.object,
  AvatarContent: PropTypes.node,
  showAvatarContent: PropTypes.bool,
}

export default memo(StopListItem)
