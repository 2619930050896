import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    markWorkOrderListReadyForInvoice: builder.mutation({
      query: body => ({ url: `/api/v1/core/work-orders/mark-ready-for-invoice`, method: 'POST', body }),
    }),
  }),
})

export const { useMarkWorkOrderListReadyForInvoiceMutation } = extendedApi
