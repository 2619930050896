import { createSlice } from '@reduxjs/toolkit'

const emptyTagType = {
  parentId: null,
  selectedTagIds: [],
}
export const TAG_TYPE_ACCOUNT = 'forAccount'
export const TAG_TYPE_LOCATION = 'forLocation'
export const TAG_TYPE_SERVICE = 'forService'
const SELECTED_TAG_IDS_INITIAL_STATE = {
  [TAG_TYPE_ACCOUNT]: emptyTagType,
  [TAG_TYPE_LOCATION]: emptyTagType,
  [TAG_TYPE_SERVICE]: emptyTagType,
}

const tagsSlice = createSlice({
  name: 'Tags',
  initialState: {
    selectedTags: SELECTED_TAG_IDS_INITIAL_STATE,
  },
  reducers: {
    selectTagIds: (state, action) => {
      const { tagType, selectedTagIds, parentId } = action?.payload
      state.selectedTags[tagType] = {
        parentId,
        selectedTagIds,
      }
    },
    resetSelectedTagIds: state => {
      state.selectedTags = SELECTED_TAG_IDS_INITIAL_STATE
    },
  },
})

export const { selectTagIds, resetSelectedTagIds } = tagsSlice.actions
export default tagsSlice.reducer
