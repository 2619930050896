import { SortAscending, SortDescending } from '@styled-icons/heroicons-solid'
import { RECENT_ACCOUNT_ADDED_SEARCH_TYPE, RECENT_ACCOUNT_VIEWED_SEARCH_TYPE } from 'components/locations/filters/Search/settings'
import { get } from 'utils/lodash'
import { AGING_BUCKET_COLORS } from 'components/common/aging-bucket/settings'
import { AGING_BUCKET_NAME } from 'settings/constants/accountReceivables'
import { grey } from '@mui/material/colors'
import { BACKGROUND } from 'theme/colors'

export const PAGE_TYPE = 'accounts'
export const SORT_BY_FIRST_NAME_ASC = 'first_name_asc'
export const SORT_BY_FIRST_NAME_DESC = 'first_name_desc'
export const SORT_BY_LAST_NAME_ASC = 'last_name_asc'
export const SORT_BY_LAST_NAME_DESC = 'last_name_desc'

export const SORT_BY_DEFAULT_VALUE = SORT_BY_FIRST_NAME_ASC

export const SORT_BY_SEARCH_PARAM_KEY = 'sort_by'

export const SORT_BY_FIRST_NAME_CRITERIA = 'firstName'
export const SORT_BY_LAST_NAME_CRITERIA = 'lastName'

export const ALPHABET_LIST = 'abcdefghijklmnopqrstuvwxyz'.split('')
export const ALPHABET_WITH_DIGITS_LIST = '1234567890abcdefghijklmnopqrstuvwxyz'.split('')

export const ACCOUNTS_DESKTOP_ROW_HEIGHT = 70
export const ACCOUNTS_MOBILE_ROW_HEIGHT = 70

export const SORT_BY_OPTIONS = [
  {
    icon: SortAscending,
    label: 'First name ascending',
    value: SORT_BY_FIRST_NAME_ASC,
    ascending: true,
    sortCriteria: SORT_BY_FIRST_NAME_CRITERIA,
  },
  {
    icon: SortDescending,
    label: 'First name descending',
    value: SORT_BY_FIRST_NAME_DESC,
    ascending: false,
    sortCriteria: SORT_BY_FIRST_NAME_CRITERIA,
  },
  {
    icon: SortAscending,
    label: 'Last name ascending',
    value: SORT_BY_LAST_NAME_ASC,
    ascending: true,
    sortCriteria: SORT_BY_LAST_NAME_CRITERIA,
  },
  {
    icon: SortDescending,
    label: 'Last name descending',
    value: SORT_BY_LAST_NAME_DESC,
    ascending: false,
    sortCriteria: SORT_BY_LAST_NAME_CRITERIA,
  },
]

export const RECENTLY_ADDED_VIEW_MODE = 'recently_added'
export const RECENTLY_VIEWED_VIEW_MODE = 'recently_viewed'
export const GENERAL_VIEW_MODE = 'general'

export const VIEW_MODE_TO_SEARCH_TYPE = {
  [RECENTLY_VIEWED_VIEW_MODE]: RECENT_ACCOUNT_VIEWED_SEARCH_TYPE,
  [RECENTLY_ADDED_VIEW_MODE]: RECENT_ACCOUNT_ADDED_SEARCH_TYPE,
}

export const ACCOUNTS_VIEW_MODES = [RECENTLY_ADDED_VIEW_MODE, RECENTLY_VIEWED_VIEW_MODE, GENERAL_VIEW_MODE]

export const isAccountsPage = (type = PAGE_TYPE) => type === PAGE_TYPE

export const showDeactivatedRow = (withDeactivated, row, type) => {
  if (withDeactivated) {
    return true
  }

  return isAccountsPage(type) ? get(row, 'active') : !get(row, 'deactivated')
}

export const getAccountRowClassName = params => {
  const { row } = params
  if (row?.suspensionHold) return 'suspended'
  if (row?.vacationHold) return ' hold'
  if (!row?.active) return 'deactivated'
  return 'active'
}

export const getAccountRowBgcolor = params => {
  const { row } = params
  if (row?.suspensionHold) return BACKGROUND.suspension
  if (row?.vacationHold) return BACKGROUND.hold
  if (!row?.active) return BACKGROUND.deactivated
  return BACKGROUND.paper
}

const { CURRENT, DUE_ONE_THIRTY, DUE_THIRTY_ONE_SIXTY, DUE_SIXTY_ONE_NINETY, DUE_OVER_NINETY } = AGING_BUCKET_NAME

export const getBalanceChipColor = (row = {}) => {
  const { currentCents = 0, dueOneThirtyCents = 0, dueThirtyOneSixtyCents = 0, dueSixtyOneNinetyCents = 0, dueOverNinetyCents = 0 } = row
  if (dueOverNinetyCents > 0) {
    return AGING_BUCKET_COLORS[DUE_OVER_NINETY]
  }
  if (dueSixtyOneNinetyCents > 0) {
    return AGING_BUCKET_COLORS[DUE_SIXTY_ONE_NINETY]
  }
  if (dueThirtyOneSixtyCents > 0) {
    return AGING_BUCKET_COLORS[DUE_THIRTY_ONE_SIXTY]
  }
  if (dueOneThirtyCents > 0) {
    return AGING_BUCKET_COLORS[DUE_ONE_THIRTY]
  }
  if (currentCents > 0) {
    return AGING_BUCKET_COLORS[CURRENT]
  }

  return grey
}
