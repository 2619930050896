import { get } from './lodash'

export const formatMeasure = measure => {
  if (measure) {
    const measureValue = measure.split(' ')
    return `${parseFloat(get(measureValue, '[0]', 0))} ${get(measureValue, '[1]', '')}`
  }

  return measure
}
