import { createSlice } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { removeParentFromSelection } from 'utils/groups'
import { get } from 'utils/lodash'

const initialState = {
  accountSelectionModel: [],
  invoiceSelectionModel: [],
  expansionState: [],
}

const addRemoveInvoiceDialogSlice = createSlice({
  name: 'AddRemoveInvoiceDialog',
  initialState,
  reducers: {
    setAccountSelectionModel(state, action) {
      state.accountSelectionModel = action.payload
    },
    setInvoiceSelectionModel(state, action) {
      state.invoiceSelectionModel = removeParentFromSelection(action.payload)
    },
    setExpansionState(state, action) {
      const id = get(action, 'payload.id', '')
      if (action.payload.type === 'add') {
        state.expansionState = uniq([...state.expansionState, id])
      } else {
        const filtered = state.expansionState.filter(expansionId => expansionId !== id)
        state.expansionState = [...filtered]
      }
    },
    resetSelectionModels(state) {
      state.accountSelectionModel = []
      state.invoiceSelectionModel = []
      state.expansionState = []
    },
  },
})

export const { setAccountSelectionModel, setInvoiceSelectionModel, setExpansionState, resetSelectionModels } =
  addRemoveInvoiceDialogSlice.actions
export default addRemoveInvoiceDialogSlice.reducer
