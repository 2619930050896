import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useAddPaymentMethodMutation } from 'api/payment-gateway/paymentGateway'
import { DEFAULT_AUTOPAY_TIMING, ACH_ACCOUNT_TYPE, PAYMENT_METHODS } from 'settings/constants/payment'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'

import RenderPaymentForm from './RenderPaymentForm'

const { ACH } = PAYMENT_METHODS

const FORM_FIELDS = {
  accountToken: null,
  expiry: null,
  cardHolderName: '',
  cvv: '',
  accountType: ACH_ACCOUNT_TYPE[0],
  cardNickName: '',
  defaultAccount: false,
  autoPay: false,
  autoPayTime: DEFAULT_AUTOPAY_TIMING,
}

const NewPaymentMethodDialog = ({ isOpen = false, accountId, type, onClose }) => {
  const [addPaymentMethod, { isLoading }] = useAddPaymentMethodMutation()
  const [newMethodFields, setNewMethodFields] = useState(cloneDeep(FORM_FIELDS))
  const { accountToken, expiry, cardHolderName, cardNickName, cvv, accountType, autoPay } = newMethodFields

  const handleChange = (key, value) => setNewMethodFields(method => ({ ...method, [key]: value }))

  const handleChangeBulk = value => setNewMethodFields(method => ({ ...method, ...value }))

  const resetFormFields = () => setNewMethodFields(cloneDeep(FORM_FIELDS))

  const handleClose = () => {
    resetFormFields()
    onClose()
  }

  const handleSave = () => {
    const payload = {
      accountId,
      accountToken,
      expiry,
      cardHolderName,
      cardNickName,
      defaultAccount: false,
      // accounType if ACH possible values Saving or Checking , for Credit Card send this as null
      accountType: type === ACH ? accountType : null,
      autoPayTime: autoPay ? DEFAULT_AUTOPAY_TIMING : null,
      cvv,
    }
    addPaymentMethod({ payload })
      .unwrap()
      .then(response => {
        const status = get(response, 'status')
        if (status === 'Added') {
          toast.success(T.PAYMENT_METHOD_ADD_SUCCESS_MESSAGE)
        } else if (status === 'Exists') {
          toast.error(T.PAYMENT_METHOD_ALREADY_EXISTS)
        } else {
          toast.error(status)
        }
        handleClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      resetFormFields()
    }
  }, [isOpen])

  return (
    <HHBaseDialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth disableScrollLock>
      <HHDialogTitle title="Add payment method" onClose={handleClose} />
      <RenderPaymentForm
        isLoading={isLoading}
        type={type}
        onChange={handleChange}
        onChangeBulk={handleChangeBulk}
        formFields={newMethodFields}
        onClose={onClose}
        onSave={handleSave}
      />
    </HHBaseDialog>
  )
}

NewPaymentMethodDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NewPaymentMethodDialog
