export const EXCEPTION_TYPES = {
  Contamination: 'Contamination',
  Damage: 'Damage',
  Delay: 'Delay',
  Extra: 'Extra',
  'Extra Item': 'Extra Item',
  'No Access': 'No Access',
  'No Payment': 'No Payment',
  'Not Out': 'Not Out',
  Overloaded: 'Overloaded',
  Photo: 'Photo',
  Safety: 'Safety',
  'Service Review': 'Service Review',
  Special: 'Special',
  Weight: 'Weight',
  Sign: 'Sign',
}
