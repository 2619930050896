import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@mui/material'
import T from 'T'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { setIsAssignStopDialogOpen } from 'slices/route/routingActionsSlice'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

const RoutingActionsMenu = ({ anchorEl, onClose, onUnassignStopClick }) => {
  const stopToBeAssigned = useSelector(s => s.routingActions.assignStopDialog.stopToBeAssigned, shallowEqual)
  const isUnassigned = get(stopToBeAssigned, 'isUnassigned', false)
  const dispatch = useDispatch()

  const onClick = e => {
    e.stopPropagation()
  }

  const handleUnassignStopClick = event => {
    event.stopPropagation()
    onUnassignStopClick()
    onClose()
  }

  const handleAddUpdateStopRoute = event => {
    event.stopPropagation()
    dispatch(setIsAssignStopDialogOpen(true))
    onClose()
  }

  return (
    <>
      <Menu
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          variant: 'outlined',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        onClick={onClick}
      >
        {isUnassigned && <MenuItem onClick={handleAddUpdateStopRoute}>{T.ASSIGN_STOP}</MenuItem>}
        {!isUnassigned && <MenuItem onClick={handleUnassignStopClick}>{T.UNASSIGN_STOP}</MenuItem>}
        {!isUnassigned && <MenuItem onClick={handleAddUpdateStopRoute}>{T.CHANGE_ROUTE_AND_SEQUENCE}</MenuItem>}
      </Menu>
      <HHConfirmDialog />
    </>
  )
}

RoutingActionsMenu.propTypes = {
  anchorEl: PropTypes.node,
  onClose: PropTypes.func,
  onUnassignStopClick: PropTypes.func,
}

export default RoutingActionsMenu
