import { get } from 'utils/lodash'
import { formatPhone } from 'utils/helper'

export const getContactFromAccountOrLocation = (customerDetails, locationId) => {
  if (locationId) {
    // Get contacts from selected location
    const getContactsbyLocationId = get(customerDetails, 'locations', []).find(location => location.id === locationId)
    return {
      // Get all active contacts from location
      allContacts: get(getContactsbyLocationId, 'contacts', []).filter(contact => !get(contact, 'deactivated', false)),
      allTags: get(getContactsbyLocationId, 'tags', []),
    }
  }

  return {
    // Get all active contacts
    allContacts: get(customerDetails, 'contacts', []).filter(contact => !get(contact, 'deactivated', false)),
    allTags: get(customerDetails, 'tags', []),
  }
}

export const getContactNameList = allContacts =>
  allContacts.map(contact => {
    const fullName = `${get(contact, 'firstName', '')} ${get(contact, 'lastName', '')}`.trim()
    if (fullName) {
      return { key: get(contact, 'id', ''), value: fullName }
    }
  })

export const getContactMethodList = (allContacts, contactName) => {
  let filteredMethods = []
  if (contactName) {
    const findIndex = allContacts.findIndex(contact => contact.id === contactName)
    filteredMethods = get(allContacts, `[${findIndex}].contactMethods`, []).slice(0, 6)
  } else {
    filteredMethods = allContacts
      .map(contact => contact.contactMethods)
      .flat()
      .slice(0, 6)
  }

  return filteredMethods.map(
    contact =>
      get(contact, 'methodValue', '') !== '' && {
        key: contact.id,
        value: contact.methodType !== 'email' ? formatPhone(contact.methodValue) : contact.methodValue,
        type: contact.methodType,
      }
  )
}
