import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { IconButton, ListItem, ListItemAvatar, ListItemText, Box, CircularProgress } from '@mui/material'
import { MoreHoriz, FilterList } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { get } from 'lodash'
import noop from 'lodash/noop'
import RouteStatus from './RouteStatus'
import { isValidHex } from '../../../utils/validations'
import RouteAvatar from './RouteAvatar/RouteAvatar'
import RouteDetailsModal from './RouteDetailsModal'
import RouteStatusAvatarWrapper from './RouteAvatar/RouteStatusAvatarWrapper'
import CustomCircularProgress from './RouteAvatar/CustomCircularProgress'

const RouteDetailsHeader = ({
  onOpenRouteFilterMenu,
  driverProfilePic,
  isActionsMenuDisabled,
  onActionsMenuClick = noop,
  onEditRoute = noop,
  route,
  routeName,
  routeStatus,
  stopsCount,
  serviceDate,
}) => {
  const { watch } = useFormContext()
  const isFiltersLoading = watch('isFiltersLoading')
  const stops = get(route, 'stops', [])
  const isRouteInProgress = routeStatus === 'In Progress'
  const bgcolor = get(route, 'color', grey[100])
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClose = () => setIsModalOpen(false)
  const handleOpen = () => setIsModalOpen(true)
  const handleClick = e => onActionsMenuClick(e, route)

  const handleRouteFilterClick = event => onOpenRouteFilterMenu(event, route)

  const onRouteChangesSaved = () => {
    onEditRoute()
    handleClose()
  }

  return (
    <Box position="relative">
      <ListItem
        sx={{ pr: 13 }}
        divider
        secondaryAction={
          <>
            <IconButton disabled={isFiltersLoading || isActionsMenuDisabled} onClick={handleRouteFilterClick}>
              {isFiltersLoading ? <CircularProgress color="action" size={20} thickness={4} /> : <FilterList />}
            </IconButton>
            <IconButton disabled={isActionsMenuDisabled} onClick={handleClick}>
              <MoreHoriz sx={{ fontSize: 28 }} />
            </IconButton>
          </>
        }
      >
        <ListItemAvatar>
          <CustomCircularProgress onClick={handleOpen} stopsCount={stopsCount} variant="determinate" stops={stops}>
            <RouteStatusAvatarWrapper isRouteInProgress={isRouteInProgress}>
              <RouteAvatar bgcolor={formattedBgcolor} name={routeName} avatarSrc={driverProfilePic} />
            </RouteStatusAvatarWrapper>
          </CustomCircularProgress>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5', flexWrap: 'nowrap', noWrap: true }}
          secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, flexWrap: 'nowrap', noWrap: true }}
          primary={routeName}
          secondary={`${stopsCount} stops`}
        />
      </ListItem>
      {routeStatus && <RouteStatus status={routeStatus} sx={{ position: 'absolute', top: -18, right: 16 }} />}
      <RouteDetailsModal
        serviceDate={serviceDate}
        onSave={onRouteChangesSaved}
        onDelete={onRouteChangesSaved}
        defaultMode="read"
        open={isModalOpen}
        route={route}
        onClose={handleClose}
      />
    </Box>
  )
}

RouteDetailsHeader.propTypes = {
  onOpenRouteFilterMenu: PropTypes.func,
  driverProfilePic: PropTypes.string,
  isActionsMenuDisabled: PropTypes.bool,
  onActionsMenuClick: PropTypes.func,
  onEditRoute: PropTypes.func,
  route: PropTypes.object,
  routeName: PropTypes.string,
  routeStatus: PropTypes.string,
  stopsCount: PropTypes.number,
  serviceDate: PropTypes.string.isRequired,
}

export default RouteDetailsHeader
