import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Contamination } from 'assets/exceptions/Contamination.svg'
import { ReactComponent as Damage } from 'assets/exceptions/Damage.svg'
import { ReactComponent as Delay } from 'assets/exceptions/Delay.svg'
import { ReactComponent as Extra } from 'assets/exceptions/Extra.svg'
import { ReactComponent as NoAccess } from 'assets/exceptions/NoAccess.svg'
import { ReactComponent as NoPayment } from 'assets/exceptions/NoPayment.svg'
import { ReactComponent as NotOut } from 'assets/exceptions/NotOut.svg'
import { ReactComponent as Overloaded } from 'assets/exceptions/Overloaded.svg'
import { ReactComponent as Camera } from 'assets/exceptions/Camera.svg'
import { ReactComponent as Safety } from 'assets/exceptions/Safety.svg'
import { ReactComponent as ServiceReview } from 'assets/exceptions/ServiceReview.svg'
import { ReactComponent as Special } from 'assets/exceptions/Special.svg'
import { ReactComponent as Weight } from 'assets/exceptions/Weight.svg'
import { ReactComponent as Note } from 'assets/exceptions/Note.svg'
import { ReactComponent as Signature } from 'assets/exceptions/Signature.svg'
import { ReactComponent as SerialNumber } from 'assets/exceptions/SerialNumber.svg'
import { Tooltip, SvgIcon } from '@mui/material'

const exceptionNameToSvg = {
  Contamination,
  Damage,
  Delay,
  Extra,
  'Extra Item': Extra,
  'No Access': NoAccess,
  'No Payment': NoPayment,
  'Not Out': NotOut,
  Overloaded,
  Photo: Camera,
  Safety,
  'Service Review': ServiceReview,
  Special,
  Weight,
  Note,
  Sign: Signature,
  Camera,
  'Serial Number': SerialNumber,
}

const ExceptionIcon = ({ exception, sx, ...rest }) => {
  console.log(exception)
  const isExceptionValid = exception && exception in exceptionNameToSvg
  const IconSvg = exceptionNameToSvg[exception]
  return (
    <>
      {isExceptionValid && (
        <Tooltip arrow title={exception} placement="bottom">
          <SvgIcon sx={sx} {...rest}>
            <IconSvg />
          </SvgIcon>
        </Tooltip>
      )}
      {!isExceptionValid && (
        <Tooltip arrow title={exception} placement="bottom">
          <SvgIcon sx={sx} {...rest}>
            <NoAccess />
          </SvgIcon>
        </Tooltip>
      )}
    </>
  )
}

ExceptionIcon.propTypes = {
  exception: PropTypes.string,
  sx: PropTypes.object,
}

export default ExceptionIcon
