/**
 * This file contains cache tags for RTKQ
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#invalidating-cache-data
 *
 * Each endpoint in the cache has an associated TAG used when needed in order to refresh the cached endoint data after a POST, DELETE mutation
 * https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#invalidating-cache-data
 *
 * Usage:
 *    - define your the cache tags for type of data [Routes, Route, Customers, Customer] with format CACHE_TAG_[ENDPOINT_DATA_TYPE]
 *
 *    - Import new tag into `api/index` and add as new line with trailing comma on the `tagTypes` collection
 *    - Import new tag into your api slice `api/[apiSliceFolder]/[apiSliceFille]`
 *      add a new prop `providesTags` to the endpoint definition after `query` i.e. `providesTags: CACHE_TAG_[ENDPOINT_DATA_TYPE]`
 */
export const CACHE_TAG_ROUTES_METADATA = 'RoutesMetadata'
export const CACHE_TAG_ROUTES_METADATA_BY_DATE = 'RoutesMetadataByDate'
export const CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS = 'RoutesUnassignedWorkOrders'
export const CACHE_TAG_ROUTES = 'RoutesList'
export const CACHE_TAG_TAGS_METADATA = 'Tags'
export const CACHE_TAG_ACCOUNT_EXPANDABLE_VIEW = 'AccountExpandableView'
export const CACHE_TAG_SERVICE_EXPANDABLE_VIEW = 'ServiceExpandableView'
export const CACHE_TAG_WORK_ORDER_LIST = 'WorkOrderList'
export const CACHE_TAG_WORK_ORDER_REVIEW_LIST = 'WorkOrderReviewList'
export const CACHE_TAG_WORK_ORDER_COUNT = 'WorkOrderCount'
export const CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY = 'AccountReceivablesSummary'
export const CACHE_TAG_ACCOUNT_RECEIVABLES_LIST = 'AccountReceivablesList'
export const CACHE_TAG_CONFIGURED_SERVICES_LIST = 'ConfiguredServicesList'
export const CACHE_TAG_CALLS_LIST = 'CallsList'
export const CACHE_TAG_FOLLOW_UP_LIST = 'FollowUpList'
export const CACHE_TAG_ACCOUNT_INVOICE_LIST = 'AccountInvoiceList'
export const CACHE_TAG_ACCOUNT_PAYMENT_LIST = 'AccountPaymentList'
export const CACHE_TAG_INVOICE_LIST = 'InvoiceList'
export const CACHE_TAG_BILLING_PAYMENT_LIST = 'BillingPaymentList'
export const CACHE_TAG_BILLING_AR_LIST = 'BillingARList'
export const CACHE_TAG_WORK_ORDER_DETAILS = 'WorkOrderDetails'
export const CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST = 'EmailEventList'
export const CACHE_TAG_PORTAL_USER_LIST = 'PortalUserList'
export const CACHE_TAG_VACATION_HOLD_LIST = 'VacationHoldList'
export const CACHE_TAG_BILLING_INVOICE_GROUP_LIST = 'BillingInvoiceGroupList'
export const CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS = 'BillingInvoiceGroupDetails'
export const CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA = 'BillingInvoiceGroupAgingBucketData'
export const CACHE_TAG_BILLING_INVOICE_GROUP_EMAIL_SUMMARY_DATA = 'BillingInvoiceGroupEmailSummaryData'
export const CACHE_TAG_BILLING_PAYMENT_GROUP_LIST = 'BillingPaymentGroupList'
export const CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS = 'BillingPaymentGroupDetails'
export const CACHE_TAG_ACCOUNT_META = 'AccountMeta'
export const CACHE_TAG_ACCOUNT_NOTES_LIST = 'AccountNotesList'
export const CACHE_TAG_LOCATION_NOTES_LIST = 'LocationNotesList'
export const CACHE_TAG_LOCATIONS_NOTES_COUNT = 'LocationsNotesCount'
export const CACHE_TAG_WORK_ORDER_NOTES_LIST = 'WorkOrderNotesList'
export const CACHE_TAG_BILLING_NOTES_LIST = 'BillingNotesList'
export const CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST = 'ConfiguredServiceNotesList'
export const CACHE_TAG_ACCOUNT_DETAILS = 'AccountDetails'
export const CACHE_TAG_ACCOUNT_DETAILS_BY_ID = 'AccountDetailsById'
export const CACHE_TAG_ACCOUNT_LOCATION_LIST = 'LocationList'
export const CACHE_TAG_ACCOUNT_LOCATION_DETAILS = 'LocationList'
export const CACHE_TAG_ACCOUNT_LIST = 'AccountList'
export const CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS = 'ContactListByAccounts'
export const CACHE_TAG_ACCOUNT_GROUP_LIST = 'AccountGroupList'
export const CACHE_TAG_ACCOUNT_GROUP_DETAILS = 'AccountGroupDetails'
export const CACHE_TAG_SUSPENSION_GROUP_DETAILS = 'SuspensionGroupDetails'
export const CACHE_TAG_AGING_GROUP_DETAILS = 'AgingGroupDetails'
export const CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS = 'AccountGroupSummaryDetails'
export const CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY = 'AccountGroupsByAgingBucketSummary'
export const CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY = 'AccountGroupsBySuspendedSummary'
export const CACHE_TAG_SUSPENSION_HISTORY = 'SuspensionHistory'
export const CACHE_TAG_ACCOUNT_SETTINGS = 'AccountSettings'
export const CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST = 'AccountPaymentMethodList'
export const CACHE_TAG_LATE_FEES = 'LateFees'
export const CACHE_TAG_RENTAL_FEES = 'RentalFees'
export const CACHE_TAG_PRICED_SERVICES_LIST = 'PricedServicesList'

export const CACHE_TAG_TYPES = [
  CACHE_TAG_ROUTES_METADATA,
  CACHE_TAG_ROUTES_METADATA_BY_DATE,
  CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS,
  CACHE_TAG_ROUTES,
  CACHE_TAG_TAGS_METADATA,
  CACHE_TAG_WORK_ORDER_LIST,
  CACHE_TAG_WORK_ORDER_REVIEW_LIST,
  CACHE_TAG_ACCOUNT_RECEIVABLES_SUMMARY,
  CACHE_TAG_ACCOUNT_RECEIVABLES_LIST,
  CACHE_TAG_CONFIGURED_SERVICES_LIST,
  CACHE_TAG_CALLS_LIST,
  CACHE_TAG_FOLLOW_UP_LIST,
  CACHE_TAG_ACCOUNT_INVOICE_LIST,
  CACHE_TAG_ACCOUNT_PAYMENT_LIST,
  CACHE_TAG_INVOICE_LIST,
  CACHE_TAG_BILLING_AR_LIST,
  CACHE_TAG_BILLING_PAYMENT_LIST,
  CACHE_TAG_WORK_ORDER_DETAILS,
  CACHE_TAG_ACCOUNT_EMAIL_EVENT_LIST,
  CACHE_TAG_VACATION_HOLD_LIST,
  CACHE_TAG_PORTAL_USER_LIST,
  CACHE_TAG_BILLING_INVOICE_GROUP_LIST,
  CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
  CACHE_TAG_BILLING_INVOICE_GROUP_AGING_BUCKET_DATA,
  CACHE_TAG_WORK_ORDER_COUNT,
  CACHE_TAG_BILLING_PAYMENT_GROUP_LIST,
  CACHE_TAG_BILLING_PAYMENT_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_META,
  CACHE_TAG_ACCOUNT_NOTES_LIST,
  CACHE_TAG_LOCATION_NOTES_LIST,
  CACHE_TAG_LOCATIONS_NOTES_COUNT,
  CACHE_TAG_WORK_ORDER_NOTES_LIST,
  CACHE_TAG_BILLING_NOTES_LIST,
  CACHE_TAG_CONFIGURED_SERVICE_NOTES_LIST,
  CACHE_TAG_ACCOUNT_DETAILS,
  CACHE_TAG_ACCOUNT_DETAILS_BY_ID,
  CACHE_TAG_ACCOUNT_LOCATION_LIST,
  CACHE_TAG_ACCOUNT_LOCATION_DETAILS,
  CACHE_TAG_ACCOUNT_LIST,
  CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS,
  CACHE_TAG_ACCOUNT_GROUP_LIST,
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_HISTORY,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_AGING_BUCKET_SUMMARY,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_PAYMENT_METHOD_LIST,
  CACHE_TAG_RENTAL_FEES,
  CACHE_TAG_PRICED_SERVICES_LIST,
]
