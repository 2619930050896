import React from 'react'
import PropTypes from 'prop-types'
import { amber, blue, deepOrange, deepPurple, grey, indigo, lightBlue, orange, pink, purple, red, teal, yellow } from '@mui/material/colors'
import { Avatar, Chip } from '@mui/material'
import { capitalize } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import { ALPHA_38_PERCENT } from 'theme/colors'
import ExceptionIcon from 'components/exceptions/ExceptionIcon'
import { EXCEPTION_TYPES } from 'settings/constants/exceptions'

const {
  Contamination,
  Damage,
  Delay,
  Extra,
  'Extra Item': ExtraItem,
  'No Access': NoAccess,
  'No Payment': NoPayment,
  'Not Out': NotOut,
  Overloaded,
  Photo,
  Safety,
  'Service Review': ServiceReview,
  Special,
  Weight,
  Sign,
} = EXCEPTION_TYPES
export const EXCEPTION_TO_COLOR_MAP = {
  [Contamination]: indigo[100],
  [Damage]: orange[100],
  [Delay]: purple[100],
  [Extra]: yellow[100],
  [ExtraItem]: yellow[100],
  [NoAccess]: pink[100],
  [NoPayment]: deepOrange[100],
  [NotOut]: red[100],
  [Overloaded]: amber[100],
  [Photo]: teal[100],
  [Safety]: deepPurple[100],
  [ServiceReview]: blue[100],
  [Special]: grey[100],
  [Weight]: lightBlue[100],
  [Sign]: grey[100],
}

const ExceptionChip = ({ exception, label, ...rest }) => {
  const theme = useTheme()
  const backgroundColor = exception in EXCEPTION_TO_COLOR_MAP ? EXCEPTION_TO_COLOR_MAP[exception] : undefined
  return (
    <Chip
      avatar={
        <Avatar sx={{ backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}` }}>
          <ExceptionIcon sx={{ color: theme.palette.common.white, fontSize: 16 }} exception={exception} />
        </Avatar>
      }
      sx={{ backgroundColor, borderRadius: '4px' }}
      label={label || capitalize(exception)}
      {...rest}
    />
  )
}

ExceptionChip.propTypes = {
  exception: PropTypes.oneOf([
    Contamination,
    Damage,
    Delay,
    Extra,
    ExtraItem,
    NoAccess,
    NoPayment,
    NotOut,
    Overloaded,
    Photo,
    Safety,
    ServiceReview,
    Special,
    Weight,
    Sign,
  ]),
  label: PropTypes.string,
}

export default ExceptionChip
