import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    editPermit: build.mutation({
      query: ({ id, body }) => ({
        url: `/api/v1/core/settings/permit/${id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useEditPermitMutation } = extendedApi
