import React from 'react'
import PropTypes from 'prop-types'

import capitalize from 'lodash/capitalize'
import Typography from '@mui/material/Typography'
import SelectCheck from '@mui/icons-material/Check'

import T from 'T'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

const BillingProfileDropdown = ({
  label = capitalize(T.CUSTOMER_BILLING_PROFILE),
  selectedBillingProfile = '',
  billingProfileMeta = [],
  onBillingProfileChange,
  ...rest
}) => {
  const billingProfileOptionList = billingProfileMeta.filter(profile => profile.name)

  return (
    <HHTextField
      select
      label={label}
      fullWidth
      value={[selectedBillingProfile]}
      onChange={event => onBillingProfileChange(event.target.value)}
      {...rest}
    >
      <SelectItem value="">{T.PLEASE_SELECT}</SelectItem>
      {billingProfileOptionList.map(profile => {
        return (
          <SelectItem value={profile.id}>
            <Typography noWrap>{profile.name}</Typography>
            {selectedBillingProfile === profile.id && <SelectCheck sx={{ width: '20px', height: '20px', display: 'none' }} />}
          </SelectItem>
        )
      })}
    </HHTextField>
  )
}

BillingProfileDropdown.propTypes = {
  label: PropTypes.string,
  selectedBillingProfile: PropTypes.string,
  billingProfileMeta: PropTypes.array,
  onBillingProfileChange: PropTypes.func.isRequired,
}

export default BillingProfileDropdown
