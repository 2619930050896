import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, SvgIcon } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ReactComponent as SuspensionIcon } from 'assets/services/hold/SuspensionIcon.svg'
import { red } from '@mui/material/colors'

const SuspensionChip = ({ workOrderId, label, size = 'small', ...rest }) => {
  const theme = useTheme()
  const linkSx = { cursor: 'pointer', '&:hover .MuiChip-label': { textDecoration: 'underline' } }
  return (
    <Chip
      sx={{
        ...(workOrderId && linkSx),
        fontWeight: 600,
        border: '1px solid',
        borderColor: red[500],
        backgroundColor: red[50],
        color: red[700],
      }}
      size={size}
      variant="outlined"
      avatar={
        <Avatar
          sx={{
            backgroundColor: red[500],
            '& svg': {
              width: '14px',
            },
          }}
        >
          <SvgIcon
            sx={{
              color: theme.palette.common.white,
            }}
          >
            <SuspensionIcon />
          </SvgIcon>
        </Avatar>
      }
      label={label || 'Suspended'}
      {...rest}
    />
  )
}

SuspensionChip.propTypes = {
  workOrderId: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
}

export default SuspensionChip
