import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useLazyGetEmailEventsQuery } from 'api/accounts/getEmailEvents'
import { getInvoicesFromEmailEvents } from 'data/invoice/invoiceSelectors'
import { handleError } from 'utils/error'
import { Box, Card } from '@mui/material'
import InvoiceActivityFeed from '../../../common/InvoiceActivityFeed'
import { INVOICE_ACTIVITY_TABS_ID } from '../../../../customer-details/content/invoices/settings'

const { PAYMENT_TAB_ID } = INVOICE_ACTIVITY_TABS_ID

const InvoiceActivityFeedWrapper = ({ invoiceNumber, accountId, accountCredits = [], payments = [] }) => {
  const [selectedSubTab, setSelectedSubTab] = useState(PAYMENT_TAB_ID)
  const [getEmailEvents, { data: emailEventList }] = useLazyGetEmailEventsQuery()
  const invoicesFromEmailEvents = getInvoicesFromEmailEvents({ emailEvents: get(emailEventList, 'emailEvents', []) })
  const emailEvents = invoicesFromEmailEvents.filter(event => event?.invoiceNumber === invoiceNumber)

  const handleTabChange = (event, newValue) => setSelectedSubTab(newValue)

  useEffect(() => {
    if (!accountId) return
    getEmailEvents({ accountId }).unwrap().catch(handleError)
  }, [accountId])

  return (
    <Box px={3}>
      <Card variant="outlined">
        <InvoiceActivityFeed
          selectedTab={selectedSubTab}
          onSelectedTabChange={handleTabChange}
          emailEvents={emailEvents}
          accountCredits={accountCredits}
          payments={payments}
        />
      </Card>
    </Box>
  )
}

InvoiceActivityFeedWrapper.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountCredits: PropTypes.array,
  payments: PropTypes.array,
}

export default InvoiceActivityFeedWrapper
