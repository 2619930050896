import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableHead as THead, TableCell } from '@mui/material'

import Constants from 'Constants'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const ValidatedListingTableHeader = ({ confServices, handleSingleChange, tierCount }) => {
  const [allowedServices, setAllowedServices] = useState(confServices.filter(data => data.checked))
  useEffect(() => {
    setAllowedServices(confServices.filter(data => data.checked))
  }, [confServices])

  const lockedColumns = confServices.filter(column => column.locked && column.checked)

  const handleHeaderClass = (ind, isLocked) => {
    let clasName = ''
    if (confServices[ind] && confServices[ind].label === 'Service') {
      clasName += 'service-field'
    }

    if (lockedColumns.length === ind + 1) {
      return `sticky-col add-shadow ${clasName}`
    }

    if (isLocked) {
      return 'sticky-col'
    }

    return ''
  }

  return (
    <THead className="table-header">
      <TableRow>
        {Children.toArray(
          allowedServices &&
            allowedServices.map((column, index) => {
              const isLocked = get(column, 'locked', false)
              let disposalCondition = allowedServices.map(data => data.label).includes('Disposal Fee')
              return (
                <>
                  {column.label === 'Overage Fee' && (
                    <>
                      <TableCell className={`next-last-update ${handleHeaderClass(index, isLocked)}`}>
                        <div className="head-wrap">
                          <div>Overage</div>
                        </div>
                      </TableCell>

                      <TableCell className={`next-last-value-update ${handleHeaderClass(index, isLocked)}`}>
                        <div className="head-wrap">
                          <div>Fee</div>
                        </div>
                      </TableCell>
                    </>
                  )}

                  {disposalCondition && column.label === 'Price' && (
                    <>
                      <TableCell
                        className={`${handleHeaderClass(index, isLocked)} ${
                          tierCount !== undefined && tierCount[0] > 0 ? 'min-width-155' : ''
                        }`}
                      >
                        <div className="head-wrap">
                          <div>Price</div>
                        </div>
                      </TableCell>

                      <TableCell className={`${handleHeaderClass(index, isLocked)}`}>
                        <div className="head-wrap">
                          <div>Measure</div>
                        </div>
                      </TableCell>
                    </>
                  )}

                  {!['Overage Fee'].includes(column.label) && !(disposalCondition && ['Price', 'Measure'].includes(column.label)) && (
                    <TableCell className={handleHeaderClass(index, isLocked)}>
                      <div className="head-wrap">
                        <div>{column.label}</div>
                      </div>
                    </TableCell>
                  )}
                </>
              )
            })
        )}

        <TableCell>
          <div className="configurator-btn" onClick={event => handleSingleChange('isOpenConfigurator', event.currentTarget)}>
            {<img src={`${MEDIA_SERVER}Services.svg`} alt="" className="conf-icon" />}
          </div>
        </TableCell>
      </TableRow>
    </THead>
  )
}

ValidatedListingTableHeader.defaultProps = {
  confServices: [],
  handleSingleChange: null,
  tierCount: {},
}

ValidatedListingTableHeader.propTypes = {
  confServices: PropTypes.array,
  handleSingleChange: PropTypes.func,
  tierCount: PropTypes.object,
}

export default memo(ValidatedListingTableHeader)
