export const ALL_PERMISSION_KEYS = {
  DASHBOARD_MENU_PERMISSION_KEY: 'profile.dashboardCrud',
  CUSTOMER_MENU_PERMISSION_KEY: 'profile.customerManagerCrud',
  ROUTE_MENU_PERMISSION_KEY: 'profile.routeManagerCrud',
  BILLING_MENU_PERMISSION_KEY: 'profile.billingCrud',
  REPORT_MENU_PERMISSION_KEY: 'profile.reportsCrud',
  PRICING_MENU_PERMISSION_KEY: 'profile.pricingManagerCrud',
  FOLLOW_UP_PERMISSION_KEY: 'profile.followUpCrud',
  SETTING_MENU_PERMISSION_KEY: 'profile.settingsCrud',
  INVOICES_PERMISSION_KEY: 'profile.invoicesCrud',
  CUSTOMER_BILLING_PROFILE_PERMISSION_KEY: 'profile.customerBillingProfileCrud',
  SERVICE_PERMISSION_KEY: 'profile.serviceCrud',
  GLOBAL_SEARCH_PERMISSION_KEY: 'profile.globalSearchCrud',
  USER_SETTINGS_PERMISSION_KEY: 'profile.userCrud',
  PERMISSIONS_SETTINGS_PERMISSION_KEY: 'profile.permissionsCrud',
  PAYMENTS_PERMISSION_KEY: 'profile.paymentsCrud',
  APPLY_PAYMENTS_PERMISSION_KEY: 'profile.applyPaymentsCrud',
  POST_INVOICES_PAYMENT_PERMISSION_KEY: 'profile.postInvoicesPaymentCrud',
  RESET_PASSWORD_PERMISSION_KEY: 'profile.resetPasswordCrud',
}

export const TEST_RECORDS = [
  {
    title: 'canAddRecords',
    successTitle: 'that a user can add records',
    failureTitle: 'that a user cannot add records',
    type: 'Add',
  },
  {
    title: 'canReadRecords',
    successTitle: 'that a user can read records',
    failureTitle: 'that a user cannot read records',
    type: 'Read',
  },
  {
    title: 'canUpdateRecords',
    successTitle: 'that a user can update records',
    failureTitle: 'that a user cannot update records',
    type: 'Update',
  },
]
