/* eslint-disable import/no-cycle */
import React from 'react'
import { get } from 'lodash'
import { Typography } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { handleCommonTableFilterChange, isValidFilter } from 'utils/table'
import { setInvoicesFilters } from 'slices/billing/invoicesSlice'
import { setCreateInvoicesFilters } from 'slices/billing/createInvoicesSlice'
import { setPaymentsFilters } from 'slices/billing/paymentsSlice'
import { setARFilters } from 'slices/billing/accountsReceivableSlice'
import HHTableFilter from 'components/common/HHTableFilter'

const RenderHeaderCell = params => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isCreateInvoicesTab = location.pathname.includes('create_invoices')
  const isPaymentsTab = location.pathname.includes('payments')
  const isAccountsReceivableTab = location.pathname.includes('accounts_receivable')

  const allFilters = useSelector(state => {
    if (isCreateInvoicesTab) return state.createInvoicesSlice.filters.allFilters
    if (isPaymentsTab) return state.payments.filters.allFilters
    if (isAccountsReceivableTab) return state.accountsReceivable.filters.allFilters
    return state.invoices.filters.allFilters
  }, shallowEqual)
  const selectedBillingProfile = useSelector(state => state.createInvoicesSlice.selectedBillingProfile)
  const selectedBillingPeriod = useSelector(state => state.createInvoicesSlice.selectedBillingPeriod)
  const isBillingProfileAndPeriodSelected = selectedBillingProfile && selectedBillingPeriod
  const { colDef } = params
  const columnName = get(colDef, 'columnName')
  const isFilterValid = isValidFilter(allFilters, columnName)
  const isSingleSelect = get(colDef, 'isSingleSelect', false)
  const isBoolean = get(colDef, 'isBoolean', false)
  const filterType = get(colDef, 'filterType')
  const label = get(colDef, 'tableLabel')
  const subType = get(colDef, 'subType', '')

  const isFilterDisabled = isCreateInvoicesTab && !isBillingProfileAndPeriodSelected
  const handleTableFilterChange = event => {
    event.stopPropagation()
    const newInvoicesFiltersState = handleCommonTableFilterChange({
      openEvent: event.currentTarget,
      allFilters,
      filterType,
      columnName,
      subType,
      label,
      isSingleSelect,
      isBoolean,
    })
    const payload = { ...newInvoicesFiltersState, refetchFlag: false }
    if (isCreateInvoicesTab) {
      dispatch(setCreateInvoicesFilters(payload))
    } else if (isPaymentsTab) {
      dispatch(setPaymentsFilters(payload))
    } else if (isAccountsReceivableTab) {
      dispatch(setARFilters(payload))
    } else {
      dispatch(setInvoicesFilters(payload))
    }
  }
  return (
    <>
      <HHTableFilter disabled={isFilterDisabled} isActive={isFilterValid} onClick={handleTableFilterChange} />
      <Typography sx={{ ml: 0.5 }}>{label}</Typography>
    </>
  )
}

export default RenderHeaderCell
