import React, { useCallback, forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Checkbox, useTheme } from '@mui/material'
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_TREE_DATA_GROUPING_FIELD } from '@mui/x-data-grid-pro'
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { get } from 'utils/lodash'
import { HHAlert } from 'components/common/HHAlert'
import { setExpansionState } from 'slices/groups/RemovePaymentDialogSlice'
import DataGridBaseCheckbox from 'components/data_grid/DataGridBaseCheckbox'
import RenderPaymentHeader from './RenderPaymentHeader'
import RenderPaymentCell from './RenderPaymentCell'
import RenderhHerarchyRow from './RenderhHerarchyRow'

const PaymentsDataGrid = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { rows = [], hierarchyIds = [], totalAmountCents = 0, onSelectionModelChange, onSelectAll, onDeselectAll, ...rest } = props
  const selectionModel = useSelector(state => get(state, 'RemovePaymentDialog.paymentSelectionModel', []), shallowEqual)
  const expansionState = useSelector(state => get(state, 'RemovePaymentDialog.expansionState', []), shallowEqual)

  const totalRows = rows.length
  const totalHierarchyRows = hierarchyIds.length
  const totalSelectedRows = selectionModel.length

  const getRowHeight = useCallback(() => 'auto', [])

  const HeaderSelectAll = () => {
    return (
      <Checkbox
        indeterminate={totalSelectedRows && totalSelectedRows < totalHierarchyRows}
        checked={totalSelectedRows === totalHierarchyRows}
        onChange={event => {
          const isChecked = event.target.checked
          if (isChecked) {
            onSelectAll()
            return
          }

          onDeselectAll()
        }}
        icon={<RadioButtonUncheckedSharpIcon />}
        indeterminateIcon={<RemoveCircleIcon />}
        checkedIcon={<CheckCircleSharpIcon />}
      />
    )
  }

  useEffect(() => {
    const handleRowExpansionChange = params => {
      const { id, childrenExpanded } = params
      dispatch(setExpansionState({ id, type: childrenExpanded ? 'add' : 'remove' }))
    }

    return ref?.current?.subscribeEvent('rowExpansionChange', handleRowExpansionChange)
  }, [ref])

  const getIsGroupExpandedByDefault = useCallback(({ groupingKey }) => expansionState.includes(groupingKey), [expansionState])

  return (
    <DataGridPro
      treeData
      isGroupExpandedByDefault={getIsGroupExpandedByDefault}
      apiRef={ref}
      rows={rows}
      columnHeaderHeight={totalRows > 0 ? 56 : 0}
      hideFooter
      checkboxSelection
      disableRowSelectionOnClick
      keepNonExistentRowsSelected
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      getRowClassName={params => `${params.row.isParent ? 'parent' : 'child'}-row`}
      isRowSelectable={params => params.row.isParent}
      columnVisibilityModel={{
        groupingField: false,
      }}
      columns={[
        {
          ...GRID_TREE_DATA_GROUPING_FIELD,
          field: 'groupingField',
        },
        {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          cellClassName: 'checkbox-column',
          headerClassName: 'checkbox-column-header',
          renderHeader: () => <HeaderSelectAll />,
        },
        {
          flex: 1,
          field: 'id',
          renderCell: ({ row, rowNode }) =>
            row?.isParent ? <RenderhHerarchyRow row={row} rowNode={rowNode} /> : <RenderPaymentCell row={row} />,
          headerClassName: 'data-column-header',
          renderHeader: () => <RenderPaymentHeader hierarchyIds={hierarchyIds} totalAmountCents={totalAmountCents} />,
          sortable: false,
          cellClassName: 'data-column',
        },
      ]}
      slots={{
        baseCheckbox: params => <DataGridBaseCheckbox {...params} rows={rows} />,
        noRowsOverlay: () => (
          <HHAlert borderColor={theme.palette.info.light} severity="info">
            No payments
          </HHAlert>
        ),
      }}
      getTreeDataPath={row => row.hierarchy}
      groupingColDef={{
        hideDescendantCount: true,
        valueFormatter: () => '',
        width: 24,
        minWidth: 24,
        maxWidth: 24,
        headerName: '',
        cellClassName: 'grouping-column',
      }}
      getRowHeight={getRowHeight}
      disableColumnMenu
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .data-column-header .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          '& .data-column-header': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .checkbox-column-header': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
          backgroundColor: 'background.paper',
        },
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
          padding: '0 4px',
        },
        '& .MuiDataGrid-row': {
          '&.child-row': {
            '& .checkbox-column': {
              visibility: 'hidden',
            },
            '& .data-column': {
              backgroundColor: theme.palette.action.hover,
              px: 0,
              width: '100vw',
            },
          },
          '& .grouping-column': {
            border: 'none',
          },
        },
      }}
      {...rest}
    />
  )
})

PaymentsDataGrid.propTypes = {
  rows: PropTypes.array,
  hierarchyIds: PropTypes.array,
  totalAmountCents: PropTypes.number,
  onSelectionModelChange: PropTypes.string.isRequired,
  onSelectAll: PropTypes.string.isRequired,
  onDeselectAll: PropTypes.string.isRequired,
}

export default PaymentsDataGrid
