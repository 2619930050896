import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedLoginDetails: { show: false, businessName: '', businessId: '' },
  allBusinesses: [],
}

const loginPersistSlice = createSlice({
  name: 'LoginPersist',
  initialState,
  reducers: {
    setSelectedLoginDetails: (state, action) => {
      state.selectedLoginDetails = { ...state.selectedLoginDetails, ...action.payload }
    },
    setAllBusinesses: (state, action) => {
      state.allBusinesses = action.payload
    },
    resetLogin: state => {
      state.selectedLoginDetails = { ...initialState.selectedLoginDetails }
      state.allBusinesses = [...initialState.allBusinesses]
    },
  },
})

export const { setSelectedLoginDetails, setAllBusinesses, resetLogin } = loginPersistSlice.actions
export default loginPersistSlice.reducer
