import { last, set } from 'lodash'

import { get } from 'utils/lodash'
import { PAYMENT_METHODS } from 'settings/constants/payment'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { isUnPaidInvoice } from './billing'
import { calculateSum, formatDollarsToCents } from './price'
import { generateRandomId } from './string'
import { isValidAccountType } from './payment'

const { CHECK, OTHER, CASH, CREDIT_CARD, ACH, ACCOUNT_CREDIT } = PAYMENT_METHODS

export const getIsCheck = paymentMethod => paymentMethod === CHECK
export const getIsCash = paymentMethod => paymentMethod === CASH
export const getIsOther = paymentMethod => paymentMethod === OTHER
export const getIsCreditCard = paymentMethod => paymentMethod === CREDIT_CARD
export const getIsACH = paymentMethod => paymentMethod === ACH
export const getIsAccountCredit = paymentMethod => paymentMethod === ACCOUNT_CREDIT

export const getIsCheckCashOther = paymentMethod => [CHECK, OTHER, CASH].includes(paymentMethod)
export const getIsCreditCardOrACH = paymentMethod => [CREDIT_CARD, ACH].includes(paymentMethod)

export const getPaymentMethodByStatus = paymentMethod => ({
  isCheck: getIsCheck(paymentMethod),
  isCash: getIsCash(paymentMethod),
  isOther: getIsOther(paymentMethod),
  isCreditCard: getIsCreditCard(paymentMethod),
  isACH: getIsACH(paymentMethod),
  isAccountCredit: getIsAccountCredit(paymentMethod),
  isCheckCashOther: getIsCheckCashOther(paymentMethod),
  isCreditCardOrACH: getIsCreditCardOrACH(paymentMethod),
})

export const getAllSortedUnpaidInvoices = (invoices = []) => {
  const unpaidInvoices = invoices.filter(({ status }) => isUnPaidInvoice(status))
  const unpaidInvoicesWithAmountDue = unpaidInvoices.map(invoice => ({
    ...invoice,
    invoiceAmount: invoice.totalCents - invoice.amountPaidCents,
  }))
  return sortByDateSelector({ data: unpaidInvoicesWithAmountDue, key: 'invoiceDate', order: 'asc' })
}

export const getUnpaidInvoiceStats = (invoices, key = 'invoiceAmount') => {
  const totalUnpaidBalance = calculateSum(invoices, key)
  const lastInvoice = get(invoices, `[0].${key}`, 0)
  const oldestUnpaidInvoice = get(last(invoices), key, 0)
  return { totalUnpaidBalance, lastInvoice, oldestUnpaidInvoice }
}

export const getSinglePaymentPayload = ({
  searchType,
  selectedOption,
  initialCreditBalanceCents,
  accountId,
  customerName,
  accountNumber,
  paymentMethod,
  paymentDate,
  checkNum,
  amount,
  selectedPaymentTokenDetails,
  cardHolderName,
  cvv,
  accountType,
  isNewCard,
  isSaveNewMethod,
  cardNickName,
  autoPayTime,
  invoicesToPay,
  note,
}) => {
  const payload = {
    searchType,
    selectedOption,
    refId: generateRandomId(13),
    initialCreditBalanceCents,
    accountId,
    customerName,
    accountNumber,
    paymentMethod,
    paymentDate,
    checkNum,
    amount: parseFloat(amount),
    amountCents: formatDollarsToCents(amount),
    invoicesToPay,
    note,
  }

  if (amount > 0) {
    payload.checkNum = checkNum
  }

  if (getIsCreditCardOrACH(paymentMethod)) {
    const tokenDetails = {
      ...selectedPaymentTokenDetails,
      cardHolderName,
      cvv,
      cardNickName,
      savePaymentMethod: isSaveNewMethod,
      isNewCard,
      autoPayTime,
    }

    if (getIsACH(paymentMethod) && isNewCard) {
      set(tokenDetails, 'accountType', accountType)
    }

    set(payload, 'tokenDetails', tokenDetails)
  }

  return payload
}

export const addUpdateRequestedPayments = (editPaymentRefId, payload, requestedPayments) => {
  if (editPaymentRefId) {
    return requestedPayments.map(payment => (payment.refId === editPaymentRefId ? { ...payload } : { ...payment }))
  }

  return [...requestedPayments, { ...payload }]
}

export const getEditPaymentModel = existingPaymentDetails => {
  const existingTokenDetails = get(existingPaymentDetails, 'tokenDetails', null)

  return {
    stateData: {
      searchType: get(existingPaymentDetails, 'searchType'),
      selectedOption: get(existingPaymentDetails, 'selectedOption'),
      invoicesToPay: get(existingPaymentDetails, 'invoicesToPay', []),
    },
    formData: {
      paymentDate: get(existingPaymentDetails, 'paymentDate'),
      paymentMethod: get(existingPaymentDetails, 'paymentMethod'),
      checkNum: get(existingPaymentDetails, 'checkNum', ''),
      amount: get(existingPaymentDetails, 'amount', 0),
      note: get(existingPaymentDetails, 'note', ''),

      // Prefill card
      selectedPaymentTokenDetails: {
        accountToken: get(existingTokenDetails, 'accountToken', ''),
        accountType: get(existingTokenDetails, 'accountType', ''),
        expiry: get(existingTokenDetails, 'expiry', ''),
      },
      isNewCard: get(existingTokenDetails, 'isNewCard', false),
      cardHolderName: get(existingTokenDetails, 'cardHolderName', ''),
      cardNickName: get(existingTokenDetails, 'cardNickName', ''),
      cvv: get(existingTokenDetails, 'cvv', ''),
      isSaveNewMethod: get(existingTokenDetails, 'savePaymentMethod', false),
      autoPayTime: get(existingTokenDetails, 'autoPayTime', null),
    },
  }
}

export const getBatchCreationDetails = (isIndividual, batch) => {
  if (isIndividual) {
    const isSameUser = new Set(batch.map(payment => payment?.createdBy)).size === 1
    return { createdBy: isSameUser ? get(batch, '[0].createdBy') : 'multiple users', createdAt: '' }
  }

  const createdBy = get(batch, '[0].createdBy')
  const createdAt = get(batch, '[0].createdAt')

  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  return { createdBy, createdAt, onlyDate, onlyTime }
}

export const getSavedMethodsByPaymentType = (paymentMethods = [], type) => {
  if (!type || !Array.isArray(paymentMethods) || paymentMethods.length === 0) {
    return []
  }

  return getIsCreditCard(type)
    ? paymentMethods.filter(method => method.creditCardType)
    : paymentMethods.filter(method => isValidAccountType(method.accountType))
}
