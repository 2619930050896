import React from 'react'
import PropTypes from 'prop-types'
import { get, capitalize } from 'lodash'
import { HHFormAutocompleteField } from '../../../form-fields/v5'
import T from '../../../../T'

const PricingZoneAutocompleteFormField = ({ sx, control, name, options }) => {
  const getOptionLabel = option => get(option, 'zoneName', '')

  return (
    <HHFormAutocompleteField
      sx={sx}
      fullWidth
      options={options}
      name={name}
      control={control}
      label={capitalize(T.PRICING_ZONE)}
      deprecatedLabel={false}
      getOptionLabel={getOptionLabel}
    />
  )
}

PricingZoneAutocompleteFormField.propTypes = {
  sx: PropTypes.object,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}

export default PricingZoneAutocompleteFormField
