import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, useTheme, useMediaQuery } from '@mui/material'

import AllLocations from 'components/customer-details/content/location/list'
import AccountDetails from '../../AccountDetails'
import { TABS_ID } from './settings'

const { ACCOUNT_DETAILS_TAB_ID } = TABS_ID

const DetailsTab = ({ isViewOnly = false, selectedTab = '', accountId, accountData = {} }) => {
  const [isReady, setIsReady] = useState(false)
  const theme = useTheme()
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  useEffect(() => {
    if (selectedTab === ACCOUNT_DETAILS_TAB_ID) {
      setIsReady(true)
    }
  }, [selectedTab])

  return (
    isReady && (
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} xl={6}>
          <Box mt={2}>
            <AccountDetails isViewOnly={isViewOnly} accountData={accountData} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box mt={2}>
            <AllLocations
              customerDetails={accountData}
              accountId={accountId}
              showContacts={false}
              showAddLocationIcon={!isViewOnly}
              isViewOnly={isViewOnly}
              paginationSiblingCount={isXl ? -1 : 1}
            />
          </Box>
        </Grid>
      </Grid>
    )
  )
}

DetailsTab.propTypes = {
  isViewOnly: PropTypes.bool,
  selectedTab: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  accountData: PropTypes.object,
}

export default DetailsTab
