import { cyan, orange, amber } from '@mui/material/colors'

export const BUSINESS_LINE_TYPE = {
  RESIDENTIAL: 'Residential',
  COMMERCIAL: 'Commercial',
  ROLL_OFF: 'Rolloff',
}

const { RESIDENTIAL, COMMERCIAL, ROLL_OFF } = BUSINESS_LINE_TYPE

export const BUSINESS_LINE_TYPE_TO_COLOR_MAP = {
  [RESIDENTIAL]: cyan[100],
  [COMMERCIAL]: orange[100],
  [ROLL_OFF]: amber[100],
}

export const BUSINESS_LINE_TYPE_UPPERCASE = {
  RESIDENTIAL: RESIDENTIAL.toUpperCase(),
  COMMERCIAL: COMMERCIAL.toUpperCase(),
  ROLL_OFF: ROLL_OFF.toUpperCase(),
}
