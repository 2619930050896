import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom-v5-compat'
import { IconButton, Typography, Card, CardHeader, CardContent, Tooltip, Grid, Divider, useTheme, SvgIcon } from '@mui/material'
import { Eye } from '@styled-icons/heroicons-outline'
import { ReactComponent as SackDollarIcon } from 'assets/SackDollar.svg'
import { ReactComponent as UserGroupRemoveOutlinedIcon } from 'assets/UserGroupRemoveOutlined.svg'
import { ReactComponent as UserGroupRemoveSolidIcon } from 'assets/UserGroupRemoveSolid.svg'
import T from 'T'
import routes from 'router/routes'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import AccountGroupDefaultCardListItem from 'components/customers/groups/account/AccountGroupDefaultCardListItem'
import { red } from '@mui/material/colors'
import { useLazyGetSuspendedAccountsSummaryQuery } from 'api/groups/account/suspendedAccountsCrud'
import { formatCentsToDollars, shortenMoneyValue } from 'utils/price'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AccountGroupActionMenu from 'components/customers/groups/account/AccountGroupActionMenu'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'

const SuspendedAccountsStatsCard = ({ id }) => {
  const theme = useTheme()
  const [accountGroupAnchorEl, setAccountGroupAnchorEl] = useState(null)
  const [getSuspendedAccountsSummary, { data = {}, isFetching, isLoading }] = useLazyGetSuspendedAccountsSummaryQuery()
  const groupDetailsLink = `${routes.app.suspensionGroups}/suspended`
  const { totalCents = 0, accountCount = 0 } = data
  const { data: accountGroupDetailsData } = useGetAccountGroupDetailsData(id, true)

  const shouldShortenValue = value => {
    return Math.abs(value) >= 1e6
  }
  const fixedTotal = useMemo(() => {
    const total = formatCentsToDollars(totalCents)
    return shouldShortenValue(total)
      ? `${shortenMoneyValue(total, 2, 2, '$')}`
      : `$${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  }, [totalCents])
  const loading = useMemo(() => isFetching || isLoading, [isFetching, isLoading])

  const handleOpenAccountGroupActionMenu = event => setAccountGroupAnchorEl(event.currentTarget)
  const handleCloseAccountGroupActionMenu = () => setAccountGroupAnchorEl(false)

  useEffect(() => {
    getSuspendedAccountsSummary()
  }, [])

  return (
    <>
      <Card variant="outlined" sx={{ boxShadow: `0px 1px 18px 0px ${theme.palette.action.disabledBackground}`, border: 'none' }}>
        <CardHeader
          sx={{
            py: 1.5,
            bgcolor: red[50],
            borderBottom: '2px solid',
            borderColor: red[500],
          }}
          avatar={<UserGroupRemoveSolidIcon width={24} height={24} color={red[500]} />}
          title={
            <Typography noWrap variant="h5" fontWeight={700} ml={2}>
              Suspended
            </Typography>
          }
          action={
            <>
              <Tooltip title={T.VIEW_ACCOUNT_GROUP_DETAIL}>
                <IconButton component={Link} to={groupDetailsLink}>
                  <SvgIcon>
                    <Eye />
                  </SvgIcon>
                </IconButton>
                <IconButton onClick={handleOpenAccountGroupActionMenu}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />
        <CardContent sx={{ py: 3, '&:last-child': { pb: 3 } }}>
          <Grid container gap={1}>
            <Grid container columnGap={2} xs={12}>
              <Grid item xs>
                <AccountGroupDefaultCardListItem
                  label="Total due"
                  value={
                    <Tooltip title={<HHDisplayMoney value={totalCents} formatToDollars />} arrow>
                      {fixedTotal}
                    </Tooltip>
                  }
                  Icon={SackDollarIcon}
                  isLoading={loading}
                />
              </Grid>
              <Grid sx="auto">
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs>
                <AccountGroupDefaultCardListItem
                  label="Suspended accounts"
                  value={accountCount}
                  isLoading={loading}
                  Icon={UserGroupRemoveOutlinedIcon}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {accountGroupAnchorEl && (
        <AccountGroupActionMenu
          showBulkSuspendOption={false}
          showBulkUnsuspendOption={loading}
          anchorEl={accountGroupAnchorEl}
          selectedGroup={{ ...data, ...accountGroupDetailsData, groupId: 'suspended' }}
          onClose={handleCloseAccountGroupActionMenu}
        />
      )}
    </>
  )
}

SuspendedAccountsStatsCard.propTypes = {
  id: PropTypes.string,
}

export default SuspendedAccountsStatsCard
