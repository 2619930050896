import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { IconButton, Tooltip } from '@mui/material'
import { ReactComponent as RentalFeeIcon } from 'assets/RentalFee.svg'

import T from 'T'
import StyledBadge from 'components/notes/common/StyledBadge'
import RentalFeePopover from '../popover/RentalFeePopover'

const RentalFeeBadgeButton = ({
  size = 'small',
  rentalFeeCount = 0,
  configuredServiceId = '',
  pricedServiceId = '',
  onClick = noop,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)

  const hasRentalFees = useMemo(() => rentalFeeCount > 0, [rentalFeeCount])
  const showPopover = useMemo(
    () => hasRentalFees && (configuredServiceId || pricedServiceId),
    [hasRentalFees, configuredServiceId, pricedServiceId]
  )

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={hasRentalFees ? '' : T.NO_RENTAL_FEES}
        disableHoverListener={hasRentalFees}
        disableInteractive
      >
        <IconButton
          size={size}
          disabled={!hasRentalFees}
          onClick={onClick}
          {...(showPopover && { onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose })}
          {...rest}
        >
          <StyledBadge badgeContent={rentalFeeCount}>
            <RentalFeeIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      {anchorEl && (
        <RentalFeePopover
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          configuredServiceId={configuredServiceId}
          pricedServiceId={pricedServiceId}
        />
      )}
    </>
  )
}

RentalFeeBadgeButton.propTypes = {
  size: PropTypes.string,
  rentalFeeCount: PropTypes.number,
  configuredServiceId: PropTypes.string,
  pricedServiceId: PropTypes.string,
  onClick: PropTypes.func,
}

export default RentalFeeBadgeButton
