import React from 'react'
import PropTypes from 'prop-types'
import { Card, Box, useMediaQuery, useTheme } from '@mui/material'
import SummaryItem from './SummaryItem'
import T from '../../../T'

const MIN_TOOLTIP_TITLE = {
  services: 'Minimum amount for any selected service among accounts that have this service actively configured',
  fees: 'Minimum value for any selected fee among active accounts that have charged this fee',
  taxes: 'Minimum amount for any selected tax among active accounts that have charged this tax',
}

const MAX_TOOLTIP_TITLE = {
  services: 'Maximum amount for any selected service among accounts that have this service actively configured',
  fees: 'Maximum price for any selected fee among active accounts that have charged this fee',
  taxes: 'Maximum amount for any selected tax among active accounts that have charged this tax',
}

const AVG_TOOLTIP_TITLE = {
  services: 'Average amount for any selected service among accounts that have this service actively configured',
  fees: 'Average price for any selected fee among active accounts that have charged this fee',
  taxes: 'Average amount for any selected tax among active accounts that have charged this tax',
}

const LOCATION_TOOLTIP_TITLE = {
  services: 'Number of discrete locations that have this service actively configured',
  fees: 'Number of discrete locations that have the selected fees linked to an active configured service',
  taxes: 'Number of discrete locations that have the selected taxes linked to an active configured service',
}

const REVENUE_TOOLTIP_TITLE = {
  services: 'Amount of revenue generated across all selected services during the previous month',
  fees: 'Amount of revenue generated across all selected fees during the previous month',
  taxes: 'Amount of revenue generated across all selected taxes during the previous month',
}

const SummaryCard = ({ min = '-', max = '-', average = '-', locations = '-', revenue = '-', sx = undefined, tabValue }) => {
  const borderY = {
    height: 20,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: 'border.lightGrey',
  }
  const borderX = {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'border.lightGrey',
  }
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const border = isTabletOrMobile ? borderX : borderY
  return (
    <Card
      variant="outlined"
      sx={{
        ...sx,
        display: 'inline-block',
        width: isTabletOrMobile ? '100%' : 'unset',
        mt: isTabletOrMobile ? 2 : 0,
      }}
    >
      <Box display="flex" alignItems="center" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
        <SummaryItem label={`${T.MIN}.`} value={min} tooltip={MIN_TOOLTIP_TITLE[tabValue]} hasPriceValue />
        <Box sx={border} />
        <SummaryItem label={`${T.MAX}.`} value={max} tooltip={MAX_TOOLTIP_TITLE[tabValue]} hasPriceValue />
        <Box sx={border} />
        <SummaryItem label={`${T.AVG}.`} value={average} tooltip={AVG_TOOLTIP_TITLE[tabValue]} hasPriceValue />
        <Box sx={border} />
        <SummaryItem label={`${T.LOCATION}s`} value={locations} tooltip={LOCATION_TOOLTIP_TITLE[tabValue]} />
        <Box sx={border} />
        <SummaryItem label={T.REVENUE} value={revenue} tooltip={REVENUE_TOOLTIP_TITLE[tabValue]} hasPriceValue />
      </Box>
    </Card>
  )
}

SummaryCard.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  average: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  locations: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  revenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object,
  tabValue: PropTypes.string.isRequired,
}

export default SummaryCard
