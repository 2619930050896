import React, { memo } from 'react'
import { selectBulkMoveMapLayer, setBulkMoveStopsState, selectBulkMoveRoutesById } from 'slices/route/bulkMoveBetweenRoutesSlice'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import CommonToggleRouteLayer from 'components/route-manager/BulkActions/common/CommonToggleRouteLayers'

const ToggleRouteLayers = ({ map }) => {
  const routesById = useSelector(selectBulkMoveRoutesById)
  const { watch } = useFormContext()
  const mapLayer = useSelector(selectBulkMoveMapLayer)
  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const showToggle = moveFromRouteId && moveToRouteId

  const fromRouteName = get(routesById, [moveFromRouteId, 'name'])
  const toRouteName = get(routesById, [moveToRouteId, 'name'])

  return (
    <>
      <CommonToggleRouteLayer
        showToggle={showToggle}
        mapLayer={mapLayer}
        fromLayer={moveFromRouteId}
        toLayer={moveToRouteId}
        bulkReduxAction={setBulkMoveStopsState}
        fromLayerLabel={fromRouteName}
        toLayerLabel={toRouteName}
        showAllButton
      />
    </>
  )
}

ToggleRouteLayers.propTypes = {
  map: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default memo(ToggleRouteLayers)
