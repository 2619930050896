import React, { useEffect, useState } from 'react'
import RoutesManager from 'containers/new-route-manager'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useSearchParams } from 'react-router-dom-v5-compat'
import NavbarWrapper from 'components/common/NavbarWrapper'
import ProtectedRouteHoC from '../router/ProtectedRouteHoC'
import {
  changeViewMode,
  resetRouteManagerState,
  selectRouteSerializedServiceDate,
  setSerializedServiceDate,
  VIEW_MODE_MAP,
  VIEW_MODE_SEQUENCE,
} from '../slices/route/routeSlice'
import { parseURLDateString, formatDateToURLFormatDateFns, deserializeDate, serializeDate } from '../utils/date' // Importing the date utility functions
import Loader from '../components/common/loader'
import { getViewMode } from '../data/route/viewModeSelector'
import api from '../api'
import { CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS } from '../api/cacheTagTypes'

const NewRoutesPage = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [initialUrlFiltersApplied, setInitialUrlFiltersApplied] = useState(false)

  const viewMode = useSelector(getViewMode, shallowEqual)
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()

  const handleViewMode = () => {
    const viewFromUrl = searchParams.get('view')
    const newViewValue = [VIEW_MODE_SEQUENCE, VIEW_MODE_MAP].includes(viewFromUrl) ? viewFromUrl : VIEW_MODE_SEQUENCE
    setSearchParams(params => {
      params.set('view', newViewValue)
      return params
    })
    if (viewMode !== newViewValue) {
      dispatch(changeViewMode(newViewValue))
    }
  }

  const handleServiceDate = () => {
    const urlStringDate = searchParams.get('service_date')
    const urlDateObj = parseURLDateString(urlStringDate)

    if (urlDateObj && !Number.isNaN(urlDateObj.getTime())) {
      const serializedUrlDate = serializeDate(urlDateObj)
      if (serializedServiceDate !== serializedUrlDate) {
        dispatch(setSerializedServiceDate(urlDateObj))
      }
    } else {
      if (!serializedServiceDate) {
        dispatch(setSerializedServiceDate(serviceDate))
      }
      setSearchParams(params => {
        params.set('service_date', formatDateToURLFormatDateFns(serviceDate))
        return params
      })
    }
  }

  const handleURLFilters = () => {
    handleServiceDate()
    handleViewMode()
    setTimeout(setInitialUrlFiltersApplied(true), 200)
  }

  useEffect(() => {
    dispatch(resetRouteManagerState())

    return () => {
      dispatch(resetRouteManagerState())
      // Clear cache to avoid lag between navigation
      setTimeout(() => dispatch(api.util.invalidateTags([CACHE_TAG_ROUTES, CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS])))
    }
  }, [])

  useEffect(() => {
    if (!serializedServiceDate) {
      handleURLFilters()
    }
  }, [serializedServiceDate])

  return (
    <ProtectedRouteHoC>
      <NavbarWrapper>
        <div className="routes-manager-page">{initialUrlFiltersApplied ? <RoutesManager /> : <Loader />}</div>
      </NavbarWrapper>
    </ProtectedRouteHoC>
  )
}

export default NewRoutesPage
