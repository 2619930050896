import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'
import { ListItemText, Menu, MenuItem } from '@mui/material'

import T from 'T'
import UniqueUrlDialog from 'components/customer-details/content/customer-portal/UniqueUrlDialog'

const AddUserActionMenu = ({ userId, anchorEl, onClose }) => {
  const [isOpenInviteUrlDialog, setIsOpenInviteUrlDialog] = useState(false)

  const businessName = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessName, shallowEqual)
  const inviteURL = `${window.location.origin}/app/login?bid=${businessName}&user_id=${userId}`

  const handleOpenInviteUrlDialog = () => {
    onClose()
    setIsOpenInviteUrlDialog(true)
  }

  const handleCloseInviteUrlDialog = () => {
    setIsOpenInviteUrlDialog(false)
  }

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { variant: 'outlined' } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleOpenInviteUrlDialog}>
          <ListItemText sx={{ color: 'warning.main' }}>{T.COPY_UNIQUE_INVITE_URL}</ListItemText>
        </MenuItem>
      </Menu>
      <UniqueUrlDialog
        open={isOpenInviteUrlDialog}
        url={inviteURL}
        title={T.COPY_UNIQUE_INVITE_URL}
        label="Invite URL"
        description="DO NOT SHARE WITH OTHER USERS. This URL is unique to this user ONLY."
        onClose={handleCloseInviteUrlDialog}
      />
    </>
  )
}

AddUserActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  userId: PropTypes.string,
  onClose: PropTypes.func,
}

export default AddUserActionMenu
