export const FIREBASE_OBJECT_NOT_FOUND = 'storage/object-not-found'

export const ERROR_MESSAGES = {
  INVALID_USER_MESSAGE: 'Invalid User!',
}

export const ERROR_EXCEPTIONS = {
  INVALID_USER_EXCEPTION: 'InvalidUserException',
  INVALID_ACCOUNT_EXCEPTION: 'InvalidAccountException',
  DATA_INTEGRITY_EXCEPTION: 'DataIntegrityViolationException',
}
