import React from 'react'
import PropTypes from 'prop-types'

import { TableRow, Typography, TableCell } from '@mui/material'

import { getWorkOrderPO, filterValidPO } from 'components/billing/groups/invoice/printable-view/helpers/address'

const WorkOrderPOList = ({ workOrders = [] }) => {
  const validPO = filterValidPO(workOrders)
  return validPO.map(workOrder => (
    <TableRow>
      <TableCell colSpan="5">
        <Typography>{getWorkOrderPO(workOrder.workOrderNumber, workOrder.purchaseOrderNumber)}</Typography>
      </TableCell>
    </TableRow>
  ))
}

WorkOrderPOList.propTypes = {
  workOrders: PropTypes.array,
}

export default WorkOrderPOList
