import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import DesktopRoutePicker from 'components/customer-details/content/routing/DesktopRoutePicker'
import { HHAlert } from 'components/common/HHAlert'
import { useFormContext } from 'react-hook-form'
import useTheme from '@mui/material/styles/useTheme'
import { get } from 'lodash'
import RenderToStopListItem from 'components/route-manager/BulkActions/BulkAssignStopsDialog/RenderToStopListItem'
import { useLazyGetRouteStopsQuery } from 'api/route/getRouteStops'
import { useDispatch, useSelector } from 'react-redux'
import T from 'T'
import {
  selectBulkAssignFetchId,
  selectBulkAssignInvalidStopsToBeAssignedCount,
  selectBulkAssignStopsDate,
  selectBulkAssignStopsToBeAssignedCount,
  selectBulkAssignToRows,
  selectBulkAssignValidStopIdsToBeAssigned,
  selectBulkSelectedMoveFromRows,
  selectBulkSelectedMoveToRows,
  setBulkAssignStopsState,
  setSelectedMoveToRows,
} from 'slices/route/bulkAssignStopsSlice'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { mapStopToListItemFormat } from 'utils/route'
import RenderMoveToHeader from 'components/route-manager/BulkActions/BulkAssignStopsDialog/RenderMoveToHeader'
import BulkMoveStopsDataGridPro from 'components/route-manager/BulkActions/common/BulkMoveStopsDataGridPro'

const MoveToRouteColumn = ({ routes, apiRef }) => {
  const { control, watch } = useFormContext()
  const moveToRouteId = watch('moveToRouteId')
  const moveFromRouteId = watch('moveFromRouteId')
  const theme = useTheme()
  const dispatch = useDispatch()
  const [getRouteStops, { isFetching, isLoading }] = useLazyGetRouteStopsQuery()
  const moveToRows = useSelector(selectBulkAssignToRows)
  const validStopIdsToBeAssigned = useSelector(selectBulkAssignValidStopIdsToBeAssigned)
  const serializedServiceDate = useSelector(selectBulkAssignStopsDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()
  const [filteredRoutes, setFilteredRoutes] = useState(routes)
  const selectedMoveFromRows = useSelector(selectBulkSelectedMoveFromRows)
  const selectedMoveToRows = useSelector(selectBulkSelectedMoveToRows)
  const stopsToBeAssignedCount = useSelector(selectBulkAssignStopsToBeAssignedCount)
  const invalidStopsToBeAssignedCount = useSelector(selectBulkAssignInvalidStopsToBeAssignedCount)
  const validStopsToBeAssigned = useSelector(selectBulkAssignValidStopIdsToBeAssigned)
  const validStopsToBeAssignedCount = get(validStopsToBeAssigned, 'length', 0)
  const fetchId = useSelector(selectBulkAssignFetchId)
  const isMoveToRouteReadOnly =
    stopsToBeAssignedCount !== 0 ||
    selectedMoveFromRows.length !== 0 ||
    selectedMoveToRows.length !== 0 ||
    validStopsToBeAssignedCount !== 0 ||
    invalidStopsToBeAssignedCount !== 0

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      minWidth: 42,
      maxWidth: 42,
    },
    {
      field: 'id',
      renderCell: RenderToStopListItem,
      renderHeader: p => <RenderMoveToHeader routes={routes} {...p} />,
      flex: 1,
      sortable: false,
      filterable: false,
    },
  ]
  const handleSelectionModelChange = selectionModel => {
    dispatch(setSelectedMoveToRows(selectionModel))
  }

  const getRowClassName = useCallback(
    params => {
      if (params.row.routeId === moveFromRouteId) {
        return validStopIdsToBeAssigned.includes(params.row.id) ? 'from-stop-assigned' : 'from-stop-to-be-assigned'
      }
      return ''
    },
    [moveFromRouteId, validStopIdsToBeAssigned]
  )

  const isRowSelectable = params => {
    if (!moveFromRouteId || !moveToRouteId) return false
    return params.row.routeId === moveFromRouteId
  }

  useEffect(() => {
    if (moveToRouteId) {
      getRouteStops({
        routes: [moveToRouteId],
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        id: fetchId,
      })
        .unwrap()
        .then(data => {
          const stops = get(data, `routes.0.stops`, [])
          const formattedStops = stops.map((stop, index) => {
            const routeId = get(stop, 'routeId')

            return {
              ...mapStopToListItemFormat(stop),
              position: index,
              originalPosition: index,
              routeId,
            }
          })
          dispatch(
            setBulkAssignStopsState({
              moveToRows: formattedStops,
            })
          )
        })
    }
  }, [fetchId, moveToRouteId, serializedServiceDate])

  useEffect(() => {
    if (moveFromRouteId) {
      setFilteredRoutes(routes.filter(route => route.id !== moveFromRouteId))
    } else {
      setFilteredRoutes(routes)
    }
  }, [moveFromRouteId, routes])

  return (
    <>
      <Box mx={2} mb={2}>
        <DesktopRoutePicker
          readOnly={isMoveToRouteReadOnly}
          name="moveToRouteId"
          control={control}
          routes={filteredRoutes}
          deprecatedLabel={false}
          InputLabelProps={{
            shrink: true,
          }}
          label={T.ROUTE}
        />
        {!moveToRouteId && (
          <HHAlert severity="info" borderColor={theme.palette.info.dark} sx={{ mt: 1 }}>
            Select a route to move stops to
          </HHAlert>
        )}
      </Box>
      <Box width="100%" height="100%" overflow="hidden" flex={1}>
        {moveToRouteId && (
          <BulkMoveStopsDataGridPro
            apiRef={apiRef}
            isRowSelectable={isRowSelectable}
            getRowClassName={getRowClassName}
            loading={isLoading || isFetching}
            rowSelectionModel={selectedMoveToRows}
            onRowSelectionModelChange={handleSelectionModelChange}
            columns={columns}
            rows={moveToRows}
          />
        )}
      </Box>
    </>
  )
}

MoveToRouteColumn.propTypes = {
  routes: PropTypes.array.isRequired,
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default MoveToRouteColumn
