import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuItem } from '@mui/material'
import { get, noop } from 'lodash'
import { useDispatch } from 'react-redux'
import { getCustomerDetails } from 'middleware/actions/customers'
import T from 'T'
import { CommonSwitch } from 'components/common'
import { useUpdateVacationHoldMutation } from 'api/accounts/vacationHoldsCrud'

const HoldActionMenu = ({ selectedHold, anchorEl, onClose = noop, onEdit = noop, onDelete = noop, accountId }) => {
  const active = get(selectedHold, 'active', false)
  const [update] = useUpdateVacationHoldMutation()
  const dispatch = useDispatch()
  const handleEdit = () => {
    onEdit()
    onClose()
  }

  const handleDelete = () => {
    onDelete()
    onClose()
  }

  const toggleVacationHoldStatus = () => {
    onClose()
    const vacationHoldId = get(selectedHold, 'id')
    update({
      accountId,
      vacationHoldId,
      data: { ...selectedHold, active: !active },
    })
      .unwrap()
      .then(() => {
        dispatch(getCustomerDetails({ accountId }))
      })
  }

  return (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ variant: 'outlined' }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      disableScrollLock
    >
      <MenuItem onClick={handleEdit}>{T.EDIT}</MenuItem>
      <MenuItem sx={{ '.MuiFormLabel-root': { color: 'text.primary' } }}>
        <CommonSwitch title={T.ACTIVE} titlePosition="left" isChecked={active} onChange={toggleVacationHoldStatus} />
      </MenuItem>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
        {T.DELETE}
      </MenuItem>
    </Menu>
  )
}

HoldActionMenu.propTypes = {
  anchorEl: PropTypes.node,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  accountId: PropTypes.string.isRequired,
  selectedHold: PropTypes.object.isRequired,
}

export default HoldActionMenu
