import React from 'react'
import PropTypes from 'prop-types'

import { Menu } from '@mui/material'

import AddEditPurchaseOrderMenuOption from 'components/work-order/purchase-order/AddEditPurchaseOrderMenuOption'
import RemovePurchaseOrderMenuOption from 'components/work-order/purchase-order/RemovePurchaseOrderMenuOption'

const WorkOrderDetailsActionMenu = ({ anchorEl, workOrderId, existingPurchaseOrder = '', onClose }) => (
  <Menu
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{ variant: 'outlined' }}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    <AddEditPurchaseOrderMenuOption workOrderId={workOrderId} existingPurchaseOrder={existingPurchaseOrder} onClose={onClose} />
    <RemovePurchaseOrderMenuOption workOrderId={workOrderId} existingPurchaseOrder={existingPurchaseOrder} onClose={onClose} />
  </Menu>
)

WorkOrderDetailsActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  workOrderId: PropTypes.string.isRequired,
  existingPurchaseOrder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default WorkOrderDetailsActionMenu
