import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { get, noop } from 'lodash'
import { DialogContent, Divider, List, ListItem, ListItemText, Skeleton, useTheme } from '@mui/material'
import T from 'T'
import NoteUserInfo from 'components/notes/common/NoteUserInfo'
import HHDialogActions from 'components/common/HHDialogActions'
import SaveButton from 'components/buttons/SaveButton'
import { useLazyGetCreditLimitHistoryByAccountQuery } from 'api/accounts/getAccountDetails'
import { handleError } from 'utils/error'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { HHAlert } from 'components/common/HHAlert'

const CreditLimitHistoryDialog = ({ open = false, customerDetails, onClose = noop }) => {
  const mockedListItems = new Array(4).fill(0)
  const accountId = get(customerDetails, 'id')
  const [getCreditLimitHistory, { isLoading, isFetching, data, isSuccess }] = useLazyGetCreditLimitHistoryByAccountQuery()
  const history = useMemo(() => get(data, 'history', []), [data])
  const hasHistory = useMemo(() => history.length !== 0 && isSuccess, [history, isSuccess])
  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])
  const theme = useTheme()

  useEffect(() => {
    if (accountId && open) {
      getCreditLimitHistory({
        accountId,
      })
        .unwrap()
        .then()
        .catch(handleError)
    }
  }, [accountId, open])

  return (
    <HHBaseDialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <HHDialogTitle onClose={onClose} title={T.CREDIT_LIMIT_HISTORY} />
      <DialogContent>
        <List disablePadding>
          {hasHistory &&
            !loading &&
            history.map(({ createdAt, creditLimitCents, createdByUser }, index) => {
              const { firstName, lastName } = createdByUser
              const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
              const isLastRow = index === history.length - 1

              return (
                <>
                  <ListItem>
                    <NoteUserInfo firstName={firstName} lastName={lastName} />
                    <ListItemText
                      primary={
                        <span>
                          {'Changed to '}
                          <HHDisplayMoney formatToDollars value={creditLimitCents} />
                        </span>
                      }
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 400,
                      }}
                      secondary={`Updated by ${firstName} ${lastName} | ${onlyDate} @${onlyTime}`}
                    />
                  </ListItem>
                  {!isLastRow && <Divider />}
                </>
              )
            })}
          {isSuccess && !loading && !hasHistory && (
            <HHAlert severity="info" borderColor={theme.palette.info.light}>
              No credit limit history is currently available.
            </HHAlert>
          )}
          {loading &&
            mockedListItems.map((_, index) => {
              const isLastRow = index === history.length - 1
              return (
                <>
                  <ListItem>
                    <Skeleton variant="circular" width={32} height={32}>
                      <NoteUserInfo firstName="" lastName="" />
                    </Skeleton>
                    <ListItemText
                      primary={<Skeleton width="6rem" variant="text" />}
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 400,
                      }}
                      secondary={<Skeleton width="12rem" variant="text" />}
                    />
                  </ListItem>
                  {!isLastRow && <Divider />}
                </>
              )
            })}
        </List>
      </DialogContent>
      <HHDialogActions>
        <SaveButton onClick={onClose} label={T.CLOSE} color="primary" />
      </HHDialogActions>
    </HHBaseDialog>
  )
}

CreditLimitHistoryDialog.propTypes = {
  open: PropTypes.bool,
  customerDetails: PropTypes.object,
  onClose: PropTypes.func,
}

export default CreditLimitHistoryDialog
