import React, { useEffect, useState, useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ALL_SERVICES } from 'components/pricing/settings'
import NewServiceModalContent from 'components/pricing/Services/NewServiceModalContent'

import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'

import { PRICING_PAGINATION } from 'settings/constants/pagination'

import { get } from 'utils/lodash'

import '../services.scss'

const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const NewServiceModal = ({
  serviceList,
  monthlyRevenue,
  openDrawer,
  setOpenDrawer,
  getPricingServicesResults,
  getPricingServiceName,
  servicesScreen,
  isDirty,
  setServicesScreen,
  selectedEditValues,
  handleChangePriceDelete,
  onHandleEditFieldsChange,
  page,
  rowsPerPage,
  confServices,
  setState,
}) => {
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState('')
  const [businessLine, setBusinessLine] = useState('')

  const [switchVal, setSwitchVal] = useState(false)

  const [counter, setCounter] = useState(1)
  const [recurCounter, setRecurCounter] = useState(1)

  const [serviceInput, setServiceInput] = useState({})

  const [checkedRecurValues, setCheckedRecurValues] = useState({})
  const [checkedRequestValues, setCheckedRequestValues] = useState({})

  const [inputTextRecurrence, setInputTextRecurrence] = useState({
    serviceName: '',
    value: '',
    recurrenceTimes: 1,
    billingPeriods: 1,
    priceLocked: false,
    isBusinessLine: false,
    isNameDirty: false,
  })

  const [inputTextOnRequest, setInputTextOnRequest] = useState({
    serviceName: '',
    value: '',
    priceLocked: false,
    isBusinessLine: false,
    isNameDirty: false,
  })

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const [configData, setConfigdata] = useState([])

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    validatedServices: [],
    duplicateRecords: [],
    combinationRequestCount: 0,
    combinationRecurCount: 0,
  })

  const { validatedServices, duplicateRecords, combinationRequestCount, combinationRecurCount } = localState

  useEffect(() => {
    dispatch(getGlobalFilterOptions())
    setConfigdata(ALL_SERVICES)

    if (serviceInput[tabValue] === undefined) {
      serviceInput[tabValue] = ''
    }
  }, [])

  useEffect(() => {
    setConfigdata(confServices)
  }, [confServices])

  const handleClearConf = () => {
    setConfigdata(ALL_SERVICES)
  }

  const onFrontScreenChange = (type, value) => {
    if (type === 'businessLine') {
      setBusinessLine(value)
      setCheckedRecurValues({})
    }

    setState({ isDirty: true })

    if (type === 'serviceType') setTabValue(value)
  }

  const onHandleChange = useCallback((event, isNameDirty = false) => {
    let { value } = event.target
    const { name } = event.target

    if (/^\s/.test(value)) {
      value = ''
    }

    tabValue === 'recurring'
      ? setInputTextRecurrence({ ...inputTextRecurrence, [name]: value, isNameDirty })
      : setInputTextOnRequest({ ...inputTextOnRequest, [name]: value, isNameDirty })
  })

  const setCount = type => {
    if (type === 'sum') {
      if (counter < 10) {
        setCounter(counter + 1)
        if (tabValue === 'recurring') {
          setInputTextRecurrence({ ...inputTextRecurrence, billingPeriods: counter + 1 })
        } else {
          setInputTextOnRequest({ ...inputTextOnRequest, billingPeriods: counter + 1 })
        }
      }
    } else if (counter > 1) {
      setCounter(counter - 1)
      if (tabValue === 'recurring') {
        setInputTextRecurrence({ ...inputTextRecurrence, billingPeriods: counter - 1 })
      } else {
        setInputTextOnRequest({ ...inputTextOnRequest, billingPeriods: counter - 1 })
      }
    }
  }

  const setInputTextRecur = value => {
    setRecurCounter(value)
    if (tabValue === 'recurring') {
      setInputTextRecurrence({ ...inputTextRecurrence, recurrenceTimes: value })
    } else {
      setInputTextOnRequest({ ...inputTextOnRequest, recurrenceTimes: value })
    }
  }

  const setRecCount = type => {
    if (type === 'sum') {
      if (recurCounter < 7) {
        setInputTextRecur(recurCounter + 1)
      }
    } else if (recurCounter > 1) {
      setInputTextRecur(recurCounter - 1)
    }
  }

  const handleTabClick = type => {
    setTabValue(type)

    if (type === 'on-request') {
      if (inputTextOnRequest[type] === undefined) {
        inputTextOnRequest[type] = { serviceName: '', value: '', recurrenceTimes: 1, billingPeriods: 1 }
      }
      setInputTextOnRequest(inputTextOnRequest)
      setLocalState({
        combinationRequestCount: combinationRequestCount || validatedServices.filter(data => data.serviceType === 'On Request').length,
      })
    } else {
      setLocalState({
        combinationRecurCount: combinationRecurCount || validatedServices.filter(data => data.serviceType === 'Recurring').length,
      })
    }
  }

  return (
    <NewServiceModalContent
      serviceList={serviceList}
      tabValue={tabValue}
      businessLine={businessLine}
      monthlyRevenue={monthlyRevenue}
      selectedValues={selectedEditValues}
      setTabValue={setTabValue}
      setBusinessLine={setBusinessLine}
      onFrontScreenChange={onFrontScreenChange}
      switchVal={switchVal}
      setSwitchVal={setSwitchVal}
      counter={counter}
      isDirty={isDirty}
      recurCounter={recurCounter}
      setRecurCounter={setRecurCounter}
      serviceInput={serviceInput}
      setServiceInput={setServiceInput}
      servicesScreen={servicesScreen}
      setServicesScreen={setServicesScreen}
      inputTextRecurrence={inputTextRecurrence}
      inputTextOnRequest={inputTextOnRequest}
      checkedRecurValues={checkedRecurValues}
      checkedRequestValues={checkedRequestValues}
      setCheckedRecurValues={setCheckedRecurValues}
      setCheckedRequestValues={setCheckedRequestValues}
      setInputTextRecurrence={setInputTextRecurrence}
      setInputTextOnRequest={setInputTextOnRequest}
      combinationRecurCount={combinationRecurCount}
      combinationRequestCount={combinationRequestCount}
      validatedServices={validatedServices}
      duplicateRecords={duplicateRecords}
      globalFilterOptions={globalFilterOptions}
      handleTabClick={handleTabClick}
      setRecCount={setRecCount}
      setCount={setCount}
      handleChangePriceDelete={handleChangePriceDelete}
      onHandleEditFieldsChange={onHandleEditFieldsChange}
      onHandleChange={onHandleChange}
      setLocalState={setLocalState}
      setState={setState}
      page={page}
      rowsPerPage={rowsPerPage}
      getPricingServicesResults={getPricingServicesResults}
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      configData={configData}
      getPricingServiceName={getPricingServiceName}
      handleClearConf={handleClearConf}
    />
  )
}

NewServiceModal.defaultProps = {
  selectedEditValues: [],
  confServices: [],
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  setState: null,
  isDirty: false,
  monthlyRevenue: null,
  openDrawer: false,
  setOpenDrawer: null,
  servicesScreen: 'initial',
  setServicesScreen: null,
  getPricingServicesResults: null,
  getPricingServiceName: null,
  handleChangePriceDelete: null,
  onHandleEditFieldsChange: null,
}

NewServiceModal.propTypes = {
  selectedEditValues: PropTypes.array,
  serviceList: PropTypes.array,
  confServices: PropTypes.array,
  page: PropTypes.number,
  isDirty: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  setState: PropTypes.func,
  monthlyRevenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  servicesScreen: PropTypes.string,
  setServicesScreen: PropTypes.func,
  getPricingServicesResults: PropTypes.func,
  getPricingServiceName: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
}

export default NewServiceModal
