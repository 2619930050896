import React, { useRef, useState, Children } from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'

import { Search } from '@styled-icons/heroicons-outline/Search'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { get } from 'utils/lodash'

import T from 'T'
import AvatarSquare from 'components/user/AvatarSquare'
import CommonTextfield from 'components/common/CommonTextfield'

import './style.scss'
import { List, ListItemButton, Stack } from '@mui/material'
import Box from '@mui/material/Box'

const CommonPhotoSelect = ({ users, selectedUser, className, placeholder, onChange, onBlur }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef()

  const profilePicture = get(selectedUser, 'profilePicUri', '')
  const firstName = get(selectedUser, 'firstName', '')
  const lastName = `${get(selectedUser, 'lastName', '')}`
  const fullName = `${firstName} ${lastName}`.trim()

  const handleChange = (value, isOptionSelected = false) => {
    onChange(isOptionSelected, value)
    setInputValue(value)
  }

  return (
    <div className={`common photo-select ${className}`}>
      <div
        onClick={event => {
          setIsOpen(true)
          setTimeout(() => inputRef.current.focus(), 100)
        }}
        className={`photo-select-field ${isOpen ? 'hide' : ''}`}
      >
        <Stack flexDirection="row" alignItems="center">
          {fullName && (
            <>
              <AvatarSquare firstName={firstName} lastName={lastName} src={profilePicture} />
              <Typography ml={1}>{fullName}</Typography>
            </>
          )}
          {!fullName && <span className="text empty">{placeholder}</span>}
        </Stack>
        <ChevronDown className="assignee-down-icon" />
      </div>

      <Box position="relative" width="100%" display={!isOpen && 'none'}>
        <CommonTextfield
          refVar={inputRef}
          value={inputValue}
          onChange={event => handleChange(event.target.value)}
          placeholder={T.SEARCH_PLACEHOLDER}
          onBlur={() => {
            setIsOpen(false)
            if (onBlur) {
              onBlur(inputValue)
            }
            setInputValue('')
          }}
          startAdornment={
            <InputAdornment position="start">
              <Search className="search-icon" />
            </InputAdornment>
          }
        />
        <List
          sx={{
            borderColor: 'divider',
            borderWidth: 1,
            borderStyle: 'solid',
            position: 'absolute',
            width: '100%',
            overflowY: 'auto',
            bgcolor: 'common.white',
            padding: 0,
          }}
          disablePadding
          className="photo-select-autocomplete"
        >
          {Children.toArray(
            users.map(user => {
              const userImage = get(user, 'profilePicUri', '')
              const fName = get(user, 'firstName', '')
              const lName = `${get(user, 'lastName', '')}`
              const completeName = `${fName} ${lName}`.trim()
              return (
                <ListItemButton onMouseDown={() => handleChange(user, true)}>
                  <Stack flexDirection="row" sx={{ width: '100%' }} alignItems="center">
                    <AvatarSquare firstName={fName} lastName={lName} src={userImage} />
                    <Typography ml={1}>{completeName}</Typography>
                  </Stack>
                </ListItemButton>
              )
            })
          )}

          {!users.length && <span className="text empty tl">No Options</span>}
        </List>
      </Box>
    </div>
  )
}

CommonPhotoSelect.defaultProps = {
  users: [],
  selectedUser: null,
  className: '',
  placeholder: '',
  onBlur: null,
}

CommonPhotoSelect.propTypes = {
  users: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  selectedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default CommonPhotoSelect
