import React from 'react'
import PropTypes from 'prop-types'

import { Link as RouterLink } from 'react-router-dom-v5-compat'
import { Typography, Box, Link } from '@mui/material'

import { getCustomerDetailsPageUrl } from 'router/routes'

const AccountSummary = ({ accountId, accountNumber, accountName, billingProfileName = '', billingCycle = '', batchNumber = '' }) => {
  const onLinkClick = e => e.stopPropagation()

  return (
    <Box
      width="100%"
      p={2}
      height={42}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom={1}
      borderColor="divider"
      bgcolor="background.header"
    >
      <Box>
        <Link variant="caption" onClick={onLinkClick} component={RouterLink} to={`${getCustomerDetailsPageUrl(accountId)}?tab=billing`}>
          {accountNumber}
        </Link>
        <Typography display="inline-block" ml={1} variant="subtitle2" color="textPrimary">
          {accountName}
        </Typography>
      </Box>
      <Box>
        {billingProfileName && (
          <Typography variant="caption" fontWeight={400} color="textPrimary">
            {billingProfileName}
          </Typography>
        )}

        {batchNumber && (
          <Typography variant="caption" fontWeight={400} color="textPrimary">
            {batchNumber}
          </Typography>
        )}

        {billingCycle && (
          <Typography ml={2} variant="caption" fontWeight={400} color="textPrimary">
            {billingCycle}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

AccountSummary.propTypes = {
  accountId: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  billingProfileName: PropTypes.string,
  billingCycle: PropTypes.string,
  batchNumber: PropTypes.string,
}

export default AccountSummary
