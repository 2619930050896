import React, { useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { InputAdornment, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import HHFormTextField from 'components/form-fields/v5/HHFormTextField'

const SearchField = () => {
  const { control, watch, setValue } = useFormContext()
  const search = watch('search')
  const [shrink, setShrink] = useState(false)

  const handleClear = () => setValue('search', '')

  const handleFocus = () => setShrink(true)
  const handleBlur = () => setShrink(false)

  return (
    <HHFormTextField
      autoFocus
      control={control}
      name="search"
      sx={{
        '& .MuiInputBase-root': { px: 0 },
        '.MuiInputLabel-root:not(.Mui-focused)': {
          left: 24,
        },
      }}
      fullWidth
      label="Search by account name"
      deprecatedLabel={false}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: search && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <CloseIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputLabelProps={{ shrink }}
    />
  )
}

export default SearchField
