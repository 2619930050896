import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import capitalize from 'lodash/capitalize'

import { Button, DialogContent } from '@mui/material'

import { get } from 'utils/lodash'
import { memo } from 'utils/react'
import { handleError } from 'utils/error'
import { useLazyGetAccountContactsQuery } from 'api/accounts/getAccountContacts'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import ViewContacts from 'components/locations/LocationsDataGrid/ViewLocationContacts/ViewLocationContacts'

const ViewContactsDialog = ({ isOpen = false, accountId, accountName = '', getCachedData = false, onCreateContact, onClose }) => {
  const [getAccountContacts, { isFetching, data }] = useLazyGetAccountContactsQuery()
  const contacts = useMemo(() => {
    const contactsByAccount = get(data, 'contactsByAccount', {})
    return uniqBy(get(contactsByAccount, accountId, []), 'id').filter(({ deactivated }) => !deactivated)
  }, [data, accountId])

  const handleOpenCreateContactDialog = () => {
    onClose()
    onCreateContact()
  }

  useEffect(() => {
    if (isOpen && accountId) {
      getAccountContacts({ accountIds: [accountId] }, getCachedData)
        .unwrap()
        .catch(handleError)
    }
  }, [isOpen, accountId])

  return (
    <HHBaseDialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <HHDialogTitle title={accountName} onClose={onClose} />
      <DialogContent sx={{ p: 0 }}>
        <ViewContacts
          contacts={contacts}
          loading={isFetching}
          showAction
          fullWidth
          sx={{ pr: 0 }}
          contactMethodSx={{
            pr: 1.5,
          }}
        />
      </DialogContent>
      <HHDialogActions>
        <Button variant="outlined" onClick={handleOpenCreateContactDialog}>
          {capitalize(T.CREATE_CONTACT)}
        </Button>
        <Button variant="contained" onClick={onClose}>
          {T.CLOSE}
        </Button>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

ViewContactsDialog.propTypes = {
  isOpen: PropTypes.bool,
  accountId: PropTypes.string.isRequired,
  accountName: PropTypes.string,
  getCachedData: PropTypes.bool,
  onCreateContact: PropTypes.func,
  onClose: PropTypes.func,
}

export default memo(ViewContactsDialog)
