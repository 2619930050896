import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { get } from 'lodash'
import { GridHeaderCheckbox, selectedIdsLookupSelector } from '@mui/x-data-grid-pro'
import { INVOICE_STATUS } from '../../../../settings/constants/invoice'
import { useWOReviewContext } from './WOReviewProvider'

const { NOT_READY_FOR_INVOICE } = INVOICE_STATUS

const getCheckboxDataForRouteRow = (selectionLookup, childrenNotReadyIds) => {
  let indeterminate = false
  let checked = false
  let foundUndefined = false
  let foundDefined = false
  for (const child of childrenNotReadyIds) {
    if (selectionLookup[child] === undefined) {
      foundUndefined = true
    } else {
      foundDefined = true
    }
    if (foundUndefined && foundDefined) {
      indeterminate = true
      checked = false
      break
    }
  }
  if (foundDefined && !foundUndefined) {
    checked = true
  }
  return { indeterminate, checked }
}
const RenderCheckboxHeader = ({ apiRef, ...rest }) => {
  const totalStatusCount = useSelector(state => state.workOrdersReview.totalStatusCount, shallowEqual)
  const notReadyIds = useSelector(state => state.workOrdersReview.notReadyIds, shallowEqual)
  const selectionLookup = selectedIdsLookupSelector(apiRef.current.state, apiRef.current.instanceId)
  const { indeterminate, checked } = getCheckboxDataForRouteRow(selectionLookup, notReadyIds)
  const woNotReadyCount = get(totalStatusCount, NOT_READY_FOR_INVOICE, 0)
  const { isLoading } = useWOReviewContext()
  return (
    <GridHeaderCheckbox
      {...rest}
      indeterminate={indeterminate}
      checked={checked && !isLoading}
      disabled={woNotReadyCount === 0 || isLoading}
      onClick={e => {
        e.preventDefault()
        const { checked } = e.target
        const allRowsIds = apiRef.current?.getAllRowIds()
        if (indeterminate) {
          apiRef.current.selectRows(allRowsIds, false)
          return
        }
        if (checked) {
          apiRef.current.selectRows(allRowsIds, true)
        } else {
          apiRef.current.selectRows(allRowsIds, false)
        }
      }}
    />
  )
}

RenderCheckboxHeader.propTypes = {
  apiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default RenderCheckboxHeader
