import { createSlice } from '@reduxjs/toolkit'

const routingActionsSlice = createSlice({
  name: 'routingActions',
  initialState: {
    assignStopDialog: {
      isOpen: false,
      stopToBeAssigned: {},
    },
  },
  reducers: {
    setIsAssignStopDialogOpen: (state, action) => {
      state.assignStopDialog.isOpen = action.payload
    },
    setStopToBeAssigned: (state, action) => {
      state.assignStopDialog.stopToBeAssigned = action.payload
    },
  },
})

export const { setIsAssignStopDialogOpen, setStopToBeAssigned } = routingActionsSlice.actions

export default routingActionsSlice.reducer
