import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TableBody as TBody, TableCell, TableRow } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { NumericFormat } from 'react-number-format'

import MenuToolTip from 'components/common/Tooltip'
import styled from 'styled-components'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import Constants from 'Constants'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const Tooltip = styled(props => <MenuToolTip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    margin-top: -8px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
  }
`

const ServiceTableBody = ({
  validatedServices,
  confServices,
  handleDelete,
  onHandleValidatedFieldsChange,
  type,
  getPricingServiceName,
}) => {
  const [allowedServices, setAllowedServices] = useState(confServices.filter(data => data.checked))

  useEffect(() => {
    setAllowedServices(confServices.filter(data => data.checked))
  }, [confServices])

  const lockedColumns = confServices.filter(column => column.locked && column.checked)

  return (
    <TBody className="table-body">
      {validatedServices &&
        allowedServices &&
        Children.toArray(
          validatedServices.map(service => {
            return (
              <TableRow>
                {Children.toArray(
                  allowedServices.map((column, ind) => {
                    const record = getPricingServiceName(service, column.label, 'create')
                    const isLocked = get(column, 'locked', false)
                    const hoverTitle = get(service, 'serviceName', '')

                    return (
                      <>
                        <TableCell className={handlePricingTableClass(ind, isLocked, lockedColumns)}>
                          {column.label === 'Service' && (
                            <Tooltip title={hoverTitle}>
                              <TextField
                                id="outlined-size-small"
                                value={record}
                                variant="outlined"
                                autoComplete="off"
                                name="serviceName"
                                InputProps={{
                                  onChange: e => onHandleValidatedFieldsChange(e, service),
                                }}
                              />
                            </Tooltip>
                          )}

                          {column.label === 'Value' && (
                            <div className="prices-field">
                              <NumericFormat
                                id="outlined-size-small"
                                placeholder="0.00"
                                value={record}
                                variant="outlined"
                                size="small"
                                autoComplete="off"
                                name="value"
                                onChange={e => onHandleValidatedFieldsChange(e, service)}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                customInput={TextField}
                                thousandSeparator
                                decimalSeparator="."
                                decimalScale={2}
                              />
                            </div>
                          )}

                          {column.label !== 'Value' && column.label !== 'Service' && <div className="price-cell">{record}</div>}
                        </TableCell>
                      </>
                    )
                  })
                )}
                <TableCell>
                  <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleDelete(service, type)} />
                </TableCell>
              </TableRow>
            )
          })
        )}
    </TBody>
  )
}

ServiceTableBody.defaultProps = {
  validatedServices: [],
  confServices: [],
  type: 'unique',
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getPricingServiceName: null,
}

ServiceTableBody.propTypes = {
  validatedServices: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getPricingServiceName: PropTypes.func,
}

export default memo(ServiceTableBody)
