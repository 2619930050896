import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import T from 'T'
import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { usePostBillingAccountApplyCreditMutation } from '../../../api/billing/postBillingAccountApplyCredit'
import { putIsLoading } from '../../../middleware/actions/response'
import { initiateStatusDownload } from '../../../middleware/actions/download'
// eslint-disable-next-line import/no-cycle
import { setStatementDrawerState } from '../../../slices/billing/accountsReceivableSlice'

const RenderGetAccountsReceivableActionsDataCell = params => {
  const dispatch = useDispatch()
  const { row } = params
  const [postBillingAccountApplyCredit] = usePostBillingAccountApplyCreditMutation()
  const accountId = get(row, 'accountId', [])

  const handleDownload = () => {
    const body = {
      type: 'pdf',
      fileName: 'account-receivable',
      isDownload: true,
      buffer: true,
      urlToMapWith: 'accountReceivableDownloadPDF',
      accountId: [accountId],
      searchFilters: [{ operation: 'EXIST_IN', columnValue: [accountId], columnName: 'accountId' }],
    }

    dispatch(initiateStatusDownload(body))
  }

  const handleApplyCreditClick = () => {
    dispatch(putIsLoading(true))
    postBillingAccountApplyCredit({ accountIds: [accountId] })
      .unwrap()
      .then(() => {
        toast.success(T.APPLY_CREDIT_SUCCESS_MSG)
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleViewStatement = () => {
    dispatch(
      setStatementDrawerState({
        isOpen: true,
        accountId,
      })
    )
  }

  return [
    <GridActionsCellItem showInMenu label={T.VIEW_STATEMENT} onClick={handleViewStatement} />,
    <GridActionsCellItem showInMenu label={T.DOWNLOAD_STATEMENT} onClick={handleDownload} />,
    <GridActionsCellItem showInMenu label={T.APPLY_CREDIT} onClick={handleApplyCreditClick} />,
  ].filter(Boolean)
}

RenderGetAccountsReceivableActionsDataCell.propTypes = {
  selectedInvoice: PropTypes.object,
}

export default RenderGetAccountsReceivableActionsDataCell
