import React, { useContext } from 'react'
import noop from 'lodash/noop'
import { useFormContext } from 'react-hook-form'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import { ArrowForward } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import BulkMoveColumnHeader from 'components/route-manager/BulkActions/common/BulkMoveColumnHeader'
import T from 'T'
import {
  selectBulkUnassignFromRouteStopMarkers,
  selectBulkUnassignFromRows,
  selectBulkUnassignToRouteStopMarkers,
  selectBulkUnassignToRows,
  setBulkUnassignStopsState,
} from 'slices/route/bulkUnassignStopsSlice'
import { MapContext } from 'providers/MapProvider'
import { BULK_MOVE_MAP_LAYERS, toggleMoveBetweenRoutesLayers } from 'components/route-manager/BulkActions/settings'
import useBulkResequenceFunctions from 'components/route-manager/BulkActions/common/useBulkResequenceFunctions'

const { BOTH_ROUTES_LAYER } = BULK_MOVE_MAP_LAYERS

const RenderMoveFromHeader = () => {
  const apiRef = useGridApiContext()
  const map = useContext(MapContext)
  const dispatch = useDispatch()
  const { watch } = useFormContext()
  const selectedRowIdsMap = apiRef.current.getSelectedRows()
  const selectedRowsCount = get(selectedRowIdsMap, 'size', 0)

  const moveFromRouteId = watch('moveFromRouteId')
  const moveToRouteId = watch('moveToRouteId')
  const moveToRows = useSelector(selectBulkUnassignToRows)
  const moveFromRows = useSelector(selectBulkUnassignFromRows)
  const moveFromRouteStopMarkers = useSelector(selectBulkUnassignFromRouteStopMarkers)
  const moveToRouteStopMarkers = useSelector(selectBulkUnassignToRouteStopMarkers)

  const { onResetSequence } = useBulkResequenceFunctions({
    moveToRows,
    moveFromRows,
    moveFromRouteStopMarkers,
    moveToRouteSequenceLine: {},
    moveFromGridApiRef: apiRef,
    moveToRouteStopMarkers,
    reset: noop,
    bulkReduxAction: setBulkUnassignStopsState,
  })

  const handleUnassignClick = () => {
    onResetSequence()
    dispatch(setBulkUnassignStopsState({ mapLayer: BOTH_ROUTES_LAYER }))
    toggleMoveBetweenRoutesLayers(map.current, BOTH_ROUTES_LAYER, moveFromRouteId, moveToRouteId)
  }

  return (
    <BulkMoveColumnHeader
      label={T.UNASSIGN}
      onClick={handleUnassignClick}
      selectedRowsCount={selectedRowsCount}
      endIcon={<ArrowForward />}
    />
  )
}

RenderMoveFromHeader.propTypes = {}

export default RenderMoveFromHeader
