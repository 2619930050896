import React from 'react'
import { Grid } from '@mui/material'
import T from 'T'
import { useParams } from 'react-router-dom-v5-compat'
import { get, uniq } from 'lodash'
import { getInvoicesRevenue } from 'data/groups/invoiceGroupsSelectors'
import SummaryCard from '../../common/SummaryCard'
import { useGetInvoiceGroupDetailsQuery } from '../../../../../api/groups/invoice/getInvoiceGroupDetails'
import HHDisplayMoney from '../../../../common/HHDisplayMoney'

const Summary = () => {
  const { id } = useParams()
  const { data } = useGetInvoiceGroupDetailsQuery({ id })
  const invoices = get(data, 'invoices', [])
  const invoicesCount = invoices.length
  const { totalAmountCents, collectedRevenueCents, uncollectedRevenueCents } = getInvoicesRevenue({ invoices })
  const accountCount = uniq(invoices.map(({ accountNumber }) => accountNumber)).length

  return (
    <Grid container columnSpacing={2.5} spacing={2.5}>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard
          label={T.INVOICES}
          value={
            <>
              {invoicesCount} / <HHDisplayMoney value={totalAmountCents} />
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.COLLECTED_REVENUE} value={<HHDisplayMoney value={collectedRevenueCents} />} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.UNCOLLECTED_REVENUE} value={<HHDisplayMoney value={uncollectedRevenueCents} />} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label={T.NUMBER_OF_ACCOUNTS} value={accountCount} />
      </Grid>
    </Grid>
  )
}

export default Summary
