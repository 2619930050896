import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, useTheme } from '@mui/material'
import Link from 'components/common/Link'
import { get } from 'lodash'
import { getCustomerDetailsPageUrl } from 'router/routes'

const RenderAgingBucketAccountRow = ({ accountData }) => {
  const theme = useTheme()
  const accountNumber = get(accountData, 'accountNumber')
  const accountName = get(accountData, 'accountName')
  const accountId = get(accountData, 'accountId')
  const billingProfileName = get(accountData, 'accountBilling.billingProfile.profile')
  const accountDetailsUrl = getCustomerDetailsPageUrl(accountId)
  return (
    <Grid container alignItems="center">
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        spacing={1}
        sx={{
          px: 2,
          py: 1,
        }}
      >
        <Grid item>
          <Link to={accountDetailsUrl} variant="body1" fontWeight={500} sx={{ color: theme.palette.info.light }}>
            {accountNumber}
          </Link>
        </Grid>
        <Grid item xs>
          <Typography variant="h6" color="textPrimary">
            {accountName}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textPrimary">
            {billingProfileName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

RenderAgingBucketAccountRow.propTypes = {
  accountData: PropTypes.object,
}

export default RenderAgingBucketAccountRow
