import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, DialogContent, Divider, Grid, Stack, Typography } from '@mui/material'

import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { get, noop, sortBy } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import HHBaseDialog from 'components/common/HHBaseDialog'
import { useCreateUpdateDraftFeeMutation } from 'api/work-order/createUpdateDraftFee'
import lowerCase from 'lodash/lowerCase'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import HHConfirmDeleteButton from 'components/common/HHConfirmDeleteButton'
import CancelButton from 'components/buttons/CancelButton'
import SaveButton from 'components/buttons/SaveButton'
import { useGetGeneralFeeListMutation } from 'api/pricing/getGeneralFeeList'
import { generalFeeDefaultFilter } from 'components/work-order/details/invoice-details/filters'
import { isPriceTypeDollar } from 'utils/service'

import HHDialogActions from 'components/common/HHDialogActions'
import GeneralFeeFormRow from 'components/work-order/WorkOrderReviewDialog/GeneralFeesDialog/GeneralFeeFormRow'

const GeneralFeesDialog = ({ open, woId, fees, onClose = noop, onRefresh = noop }) => {
  const [generalFeeList, setGeneralFeeList] = useState([])
  const [generalFeesById, setGeneralFeesById] = useState({})
  const [toBeDeleted, setToBeDeleted] = useState([])
  const [createUpdateDraftFee, { isLoading: isCreateUpdateFeeLoading }] = useCreateUpdateDraftFeeMutation()

  const [total, setTotal] = useState(0)
  const methods = useForm({
    defaultValues: {
      fees: [
        {
          feeTaxId: '',
          feeCharge: '',
          chargeType: '',
          subChargeType: '',
          measure: '',
          quantity: 1,
          invoiceLineItemId: '',
          workOrderId: '',
        },
      ],
    },
  })
  const { control, watch, reset, setValue, getValues, handleSubmit } = methods
  const [getGeneralFeeList] = useGetGeneralFeeListMutation()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fees',
  })
  const formFees = watch('fees')

  const addFee = () => {
    append({
      feeTaxId: '',
      feeCharge: '',
      chargeType: '',
      subChargeType: '',
      measure: '',
      quantity: 1,
      invoiceLineItemId: '',
      workOrderId: woId,
    })
  }
  const updateGeneralFees = payload => {
    const feeAndCharges = get(payload, 'feeAndCharges', []).filter(fee => fee?.feeName && isPriceTypeDollar(fee?.valueType))
    const newFeesMapById = {}
    feeAndCharges.forEach(item => {
      newFeesMapById[item.id] = item
    })
    const sortedFeeAndCharges = sortBy(feeAndCharges, fee => lowerCase(get(fee, 'feeName', '')))
    setGeneralFeeList(sortedFeeAndCharges)
    setGeneralFeesById(newFeesMapById)
  }

  const initializeFormData = () => {
    if (fees.length === 0) {
      reset({
        fees: [
          {
            feeTaxId: '',
            feeCharge: '',
            chargeType: '',
            subChargeType: '',
            measure: '',
            quantity: 1,
            invoiceLineItemId: '',
            workOrderId: woId,
          },
        ],
      })
    } else {
      const initialFees = fees.map(fee => {
        const { feeTaxId } = fee
        const selectedFee = generalFeesById[feeTaxId]
        const feeCharge = get(fee, 'feeCharge', 0)
        const subChargeType = get(selectedFee, 'subChargeType', 'General')
        const feeName = get(selectedFee, 'feeName', '')
        return {
          ...fee,
          workOrderId: woId,
          feeName,
          feeTaxId,
          feeCharge,
          subChargeType,
          isEdit: 'true',
        }
      })
      reset({
        fees: initialFees,
      })
    }
  }

  const handleFeeChange = ({ feeId, index }) => {
    const selectedFee = generalFeesById[feeId]
    const value = get(selectedFee, 'value', 0)
    setValue(`fees.${index}.feeCharge`, value.toFixed(2))
  }

  const handleRemove = index => {
    if (formFees.length === 1) {
      reset({
        fees: [
          {
            feeTaxId: '',
            feeCharge: '',
            chargeType: '',
            subChargeType: '',
            measure: '',
            quantity: 1,
            invoiceLineItemId: '',
            workOrderId: woId,
          },
        ],
      })
    } else {
      const toBeDeletedItem = getValues(`fees.${index}`)
      if (toBeDeletedItem?.id) {
        setToBeDeleted([...toBeDeleted, { ...toBeDeletedItem, delete: true }])
      }
      remove(index)
    }
  }

  const onSubmit = async data => {
    const { fees } = data
    await createUpdateDraftFee({
      draftFees: fees,
      removeDraftFees: toBeDeleted,
    }).unwrap()
    onRefresh()
    onClose()
  }

  const handleDeleteConfirmed = async () => {
    const removeDraftFees = fees.filter(({ id }) => id).map(fee => ({ ...fee, delete: true }))
    await createUpdateDraftFee({
      draftFees: [],
      removeDraftFees,
    }).unwrap()
    onRefresh()
    onClose()
  }

  const onInvalid = errors => {}

  useEffect(() => {
    if (woId && open) {
      getGeneralFeeList(generalFeeDefaultFilter)
        .unwrap()
        .then(payload => {
          updateGeneralFees(payload)
          initializeFormData()
        })
        .catch(() => {
          setGeneralFeeList([])
          setGeneralFeesById({})
        })
    }
    if (!open) {
      reset({
        fees: [
          {
            feeTaxId: '',
            feeCharge: '',
            chargeType: '',
            subChargeType: '',
            measure: '',
            quantity: 1,
            invoiceLineItemId: '',
            workOrderId: woId,
          },
        ],
      })
    }
  }, [woId, open, fees])

  useEffect(() => {
    const subscription = watch(value => {
      const { fees: formFees } = value
      const totalCharge = formFees.reduce((accumulator, currentFee) => {
        const itemCharge = currentFee.quantity * currentFee.feeCharge
        return accumulator + itemCharge
      }, 0)
      setTotal(totalCharge)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <HHBaseDialog open={open} maxWidth="sm" onClose={onClose}>
      <HHDialogTitle title={T.FEES} sx={{ fontWeight: 600 }} onClose={onClose} />
      <DialogContent sx={{ mt: 2 }}>
        <Grid container rowSpacing={2}>
          <FormProvider {...methods}>
            {fields.map((field, index) => {
              return (
                <GeneralFeeFormRow
                  key={field.id}
                  onFeeChange={handleFeeChange}
                  index={index}
                  field={field}
                  generalFeeList={generalFeeList}
                  watch={watch}
                  onRemove={handleRemove}
                />
              )
            })}
          </FormProvider>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button size="small" variant="outlined" startIcon={<AddIcon />} onClick={addFee}>
              {T.FEE}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} wrap="nowrap" columnSpacing={1}>
            <Grid item xs>
              <Box display="flex" alignItems="center" height="100%">
                <Typography variant="h4" fontWeight={600}>
                  {T.TOTAL}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box width="20px" />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box width="20px" />
            </Grid>
            <Grid item xs>
              <Typography variant="h4" align="right" fontWeight={600}>
                <HHDisplayMoney value={total} formatToDollars={false} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <HHDialogActions sx={{ flex: '1 1 auto', justifyContent: 'space-between' }}>
        <HHConfirmDeleteButton
          size="small"
          confirmTitle="Delete fee"
          confirmDescription="Are you sure you want to delete these fees"
          onDeleteConfirmed={handleDeleteConfirmed}
        />
        <Stack direction="row" spacing={1}>
          <CancelButton onClick={onClose} />
          <SaveButton loading={isCreateUpdateFeeLoading} onClick={handleSubmit(onSubmit, onInvalid)} />
        </Stack>
      </HHDialogActions>
    </HHBaseDialog>
  )
}

GeneralFeesDialog.propTypes = {
  open: PropTypes.bool,
  woId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  fees: PropTypes.array,
}

export default GeneralFeesDialog
