import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'
import { useFormContext, Controller } from 'react-hook-form'

import { get } from 'utils/lodash'
import { HHNumberField } from 'components/form-fields/v5'

import T from 'T'

const AmountField = ({ onChange = noop, ...rest }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { amount: amountError } = errors
  const amountHelperText = get(amountError, 'message')

  return (
    <Controller
      control={control}
      name="amount"
      rules={{ required: { value: true, message: T.CANNOT_BE_EMPTY }, min: { value: 0.01, message: T.CANNOT_BE_EMPTY } }}
      render={({ field }) => {
        const { ref, value, onChange: onChangeHookForm, onBlur, ..._rest } = field

        return (
          <HHNumberField
            inputRef={ref}
            error={amountError}
            helperText={amountHelperText}
            fullWidth
            deprecatedLabel={false}
            isRequired
            label={`${T.AMOUNT} *`}
            placeholder="0.00"
            dollarPrefix
            decimalScale={2}
            thousandSeparator
            allowNegative={false}
            value={value || ''}
            onChange={event => onChange(Number(event.target.value.replace(/[^0-9\.]+/g, '')))}
            onValueChange={values => {
              const { value: originalValue } = values
              onChangeHookForm(originalValue)
            }}
            InputProps={{ onBlur }}
            {..._rest}
            {...rest}
          />
        )
      }}
    />
  )
}

AmountField.propTypes = {
  onChange: PropTypes.func,
}

export default AmountField
