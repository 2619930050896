import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { toast } from 'react-toastify'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, DialogContent } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'
import { useLazyGetAccountGroupListQuery } from 'api/groups/account/getAccountGroupList'
import { useAddAccountsToGroupMutation } from 'api/groups/account/addAccountsToGroup'

import T from 'T'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import AddEditAccountGroupDialog from './AddEditAccountGroupDialog'

const AddToGroupDialog = ({ isOpen = false, onAddToGroup = noop, accountIds = [], onClose }) => {
  const [isOpenAddEditAccountGroupDialog, setIsOpenAddEditAccountGroupDialog] = useState(false)
  const [getAccountGroupList, { data: accountGroupList = [] }] = useLazyGetAccountGroupListQuery()
  const [addAccountsToGroup, { isLoading: isAddToGroupLoading }] = useAddAccountsToGroupMutation()
  const addToGroupForm = useForm({ defaultValues: { accountGroupId: '' } })
  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = addToGroupForm

  const accountGroupIdHelperText = get(errors, 'accountGroupId.message')
  const accountGroups = get(accountGroupList, 'accountGroups', [])
  const sortedGroups = sortByDateSelector({ data: accountGroups, key: 'createdAt' })

  const handleCreateNewGroup = () => setIsOpenAddEditAccountGroupDialog(true)

  const handleCreateNewGroupSuccess = id => setValue('accountGroupId', id)

  const handleCloseCreateNewGroup = () => setIsOpenAddEditAccountGroupDialog(false)

  const onSubmitHandler = data => {
    const accountGroupId = data?.accountGroupId

    // Support for AddAgingAccountsToAccountGroup component
    onAddToGroup(accountGroupId)
    if (accountIds.length === 0) return

    const payload = { accountGroupId, accounts: accountIds }
    addAccountsToGroup(payload)
      .unwrap()
      .then(() => {
        toast.success(T.ADD_ACCOUNT_TO_GROUP_SUCCESS_MSG)
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      reset()
      getAccountGroupList()
    }
  }, [isOpen])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <HHDialogTitle title={T.ADD_TO_GROUP} onClose={onClose} />
        <DialogContent>
          <FormProvider {...addToGroupForm}>
            <Box mt={2}>
              <HHFormTextField
                error={accountGroupIdHelperText}
                helperText={accountGroupIdHelperText}
                control={control}
                required
                rules={{ required: T.CANNOT_BE_EMPTY }}
                name="accountGroupId"
                label={T.ACCOUNT_GROUPS}
                placeholder={T.SELECT_GROUP}
                fullWidth
                deprecatedLabel={false}
                select
              >
                {sortedGroups.map(({ groupId, groupName }) => (
                  <SelectItem value={groupId}>{groupName}</SelectItem>
                ))}
              </HHFormTextField>
            </Box>
          </FormProvider>
        </DialogContent>
        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <Box sx={{ flex: '1 1 auto' }} />
          <Button size="small" variant="outlined" onClick={handleCreateNewGroup}>
            {T.CREATE_NEW_GROUP}
          </Button>
          <LoadingButton loading={isAddToGroupLoading} size="small" variant="contained" onClick={handleSubmit(onSubmitHandler)}>
            {T.ADD}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
      <AddEditAccountGroupDialog
        isOpen={isOpenAddEditAccountGroupDialog}
        onSuccess={handleCreateNewGroupSuccess}
        onClose={handleCloseCreateNewGroup}
      />
    </>
  )
}

AddToGroupDialog.propTypes = {
  isOpen: PropTypes.bool,
  onAddToGroup: PropTypes.func,
  accountIds: PropTypes.array,
  onClose: PropTypes.func.isRequired,
}

export default AddToGroupDialog
