import React from 'react'
import PropTypes from 'prop-types'
import HHDialogTitle from 'components/common/HHDialogTitle'
import T from 'T'
import { Dialog, DialogContent, DialogContentText, useTheme } from '@mui/material'
import { HHFormNumberField } from 'components/form-fields/v5'
import HHDialogActions from 'components/common/HHDialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'

const CommonSequenceDialog = ({ isOpen, onClose, onPreserveSequence, onResetSequence }) => {
  const theme = useTheme()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()
  const startingSequenceError = get(errors, 'startingSequence', false)
  const startingSequenceErrorMessage = get(errors, 'startingSequence.message')
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose} sx={{ zIndex: theme.zIndex.snackbar + 4 }}>
      <HHDialogTitle title={T.SEQUENCE} showCloseIcon onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          Do you want to preserve the order of the current sequence selection? Type the starting position for the selection below
        </DialogContentText>
        <HHFormNumberField
          sx={{
            mt: 2,
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          deprecatedLabel={false}
          fullWidth
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={0}
          label="Starting position"
          name="startingSequence"
          control={control}
          error={startingSequenceError}
          helperText={startingSequenceError ? startingSequenceErrorMessage : ''}
          rules={{ required: T.REQUIRED }}
        />
      </DialogContent>
      <HHDialogActions
        sx={{
          '&>:not(:first-of-type)': {
            ml: 2,
          },
        }}
      >
        <LoadingButton onClick={onResetSequence} variant="outlined" size="small" color="warning">
          {T.RESET_SEQUENCE}
        </LoadingButton>
        <LoadingButton onClick={handleSubmit(onPreserveSequence)} variant="contained" size="small" color="primary">
          {T.PRESERVE_SEQUENCE}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

CommonSequenceDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPreserveSequence: PropTypes.func.isRequired,
  onResetSequence: PropTypes.func.isRequired,
}

export default CommonSequenceDialog
