import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web/build/player/lottie_light'
import hhloader from 'components/common/loader/hh_loader.json'
import useTheme from '@mui/material/styles/useTheme'
import { Box } from '@mui/material'

const CustomLoadingOverlay = ({ id = '' }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    const loaderInstance = lottie.loadAnimation({
      container: document.getElementById(`hh_loader_container_datagrid${id}`),
      animationData: hhloader,
    })
    return () => {
      loaderInstance.destroy()
      document.body.style.overflow = 'visible'
    }
  }, [])

  const theme = useTheme()
  return (
    <Box
      backgroundColor={`${theme.palette.background.default}bd`}
      width="100%"
      height="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      id={`hh_loader_container_datagrid${id}`}
      zIndex={2}
      sx={{ '& svg': { height: '150px!important', position: 'absolute', zIndex: 2 } }}
    />
  )
}

CustomLoadingOverlay.propTypes = {
  id: PropTypes.string,
}

export default CustomLoadingOverlay
