import React from 'react'
import PropTypes from 'prop-types'
import StopPositionLabel from 'components/route-manager/BulkActions/common/StopListItem/StopPositionLabel'
import StopPositionField from 'components/route-manager/BulkActions/common/StopListItem/StopPositionField'
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form'

const ToStopAvatarContent = ({ toBeResequence, stopId, onChange, position, workOrderStatus }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  if (toBeResequence) {
    const errorMessage = get(errors, `${stopId}.message`)
    return (
      <StopPositionField
        sx={{
          mr: 1,
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
        id={`${stopId}-resequence-field`}
        error={Boolean(errorMessage)}
        required
        rules={{ required: true }}
        control={control}
        name={stopId}
        size="small"
        onChange={onChange}
      />
    )
  }
  return <StopPositionLabel position={position} workOrderStatus={workOrderStatus} />
}

ToStopAvatarContent.propTypes = {
  toBeResequence: PropTypes.bool.isRequired,
  stopId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  workOrderStatus: PropTypes.string.isRequired,
}

export default ToStopAvatarContent
