import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'

const Tooltip = ({ ...rest }) => (
  <MuiTooltip
    componentsProps={{
      tooltip: {
        sx: {
          '&.MuiTooltip-tooltip': {
            backgroundColor: '#1a1f36',
          },
        },
      },
    }}
    {...rest}
  />
)

export default Tooltip
