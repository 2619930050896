import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectBulkMoveRoutesById,
  selectBulkMoveRouteId,
  selectBulkMoveToSerializedDate,
} from 'slices/route/bulkPermanentRecurrenceChangeSlice'
import PropTypes from 'prop-types'
import CommonPermanentMoveAlert from 'components/route-manager/BulkActions/common/PermanentMove/CommonPermanentMoveAlert'
import { selectBulkMoveFromSerializedDate } from 'slices/route/bulkMoveBetweenDaysSlice'

const PermanentMoveAlert = ({ toBeMovedRowsCount = 0 }) => {
  const routeId = useSelector(selectBulkMoveRouteId)
  const routesById = useSelector(selectBulkMoveRoutesById)
  const toSerializedDate = useSelector(selectBulkMoveToSerializedDate)
  const fromSerializedDate = useSelector(selectBulkMoveFromSerializedDate)
  return (
    <CommonPermanentMoveAlert
      toSerializedDate={toSerializedDate}
      fromSerializedDate={fromSerializedDate}
      routesById={routesById}
      moveFromRouteId={routeId}
      moveToRouteId={routeId}
      toBeMovedRowsCount={toBeMovedRowsCount}
    />
  )
}

PermanentMoveAlert.propTypes = {
  toBeMovedRowsCount: PropTypes.number,
}

export default memo(PermanentMoveAlert)
