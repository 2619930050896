import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material'
import SummaryRow from './SummaryRow'
import SummaryHeader from './SummaryHeader'

const MAX_ALLOWED = 10

const PaymentStatsTooltip = ({ color = '', label = '', value = 0, total = 0, isBatch = false, rows = [] }) => {
  const visibleRows = rows.slice(0, MAX_ALLOWED)
  const moreCount = rows.length - visibleRows.length

  return (
    <Box bgcolor="white" width={275} height="auto" boxShadow="0px 1px 4px 0px #00000040">
      <List dense disablePadding>
        <SummaryHeader color={color} label={label} value={value} total={total} isBatch={isBatch} rows={rows} />
        {Children.toArray(
          visibleRows.map(({ customerName, accountNumber, transactionAmountCents, checkNum, isIndividualPayment, batchId }, index) => {
            const checkValue = checkNum ? `Check# ${checkNum} ` : null
            return (
              <>
                <Divider />
                <SummaryRow
                  customerName={customerName}
                  accountNumber={accountNumber}
                  transactionAmountCents={transactionAmountCents}
                  isIndividualPayment={isIndividualPayment}
                  batchId={batchId}
                  checkValue={checkValue}
                />
                {index === visibleRows.length - 1 && moreCount > 0 && (
                  <ListItem dense disablePadding sx={{ px: 1 }}>
                    <ListItemText secondaryTypographyProps={{ textAlign: 'right' }} secondary={`+ ${moreCount} more`} />
                  </ListItem>
                )}
              </>
            )
          })
        )}
      </List>
    </Box>
  )
}

PaymentStatsTooltip.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  total: PropTypes.number,
  isBatch: PropTypes.bool,
  rows: PropTypes.array,
}

export default PaymentStatsTooltip
