import api from 'api'
import { CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS } from '../../cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getInvoiceGroupDetails: build.query({
      providesTags: (result, error, { id }) => [
        CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS,
        { type: CACHE_TAG_BILLING_INVOICE_GROUP_DETAILS, id },
      ],
      query: ({ id }) => ({ url: `/api/v1/billing/invoice/groups/${id}`, method: 'GET' }),
    }),
  }),
})

export const { useGetInvoiceGroupDetailsQuery, useLazyGetInvoiceGroupDetailsQuery } = extendedApi
