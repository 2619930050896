import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'utils/lodash'
import { HHTextField } from 'components/form-fields/v5'
import { useGetRentalFeesQuery } from 'api/pricing/rentalFeesCrud'
import { getRentalFees } from 'data/pricing/rentalFeeSelectors'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import RenderNoneOption from './RenderNoneOption'
import RentalFeeListItem from './RentalFeeListItem'

const RentalFeeField = ({ value, onChange, hideArchived = false, fieldSX = {} }) => {
  const { data: rentalFeesData } = useGetRentalFeesQuery()
  const rentalFees = getRentalFees({ rentalFees: get(rentalFeesData, 'rentalFees', []), hideArchived })

  return (
    <HHTextField
      fullWidth
      select
      label={T.RENTAL_FEES}
      placeholder={`Select ${T.RENTAL_FEES}`}
      value={[value]}
      onChange={event => onChange(event.target.value)}
      sx={{ ...fieldSX }}
    >
      <SelectItem value="">
        <RenderNoneOption />
      </SelectItem>
      {rentalFees.map(fee => (
        <SelectItem value={fee.id}>
          <RentalFeeListItem rentalFee={fee} />
        </SelectItem>
      ))}
    </HHTextField>
  )
}

RentalFeeField.propTypes = {
  value: PropTypes.string,
  fieldSX: PropTypes.object,
  hideArchived: PropTypes.bool,
  onChange: PropTypes.func,
}

export default RentalFeeField
