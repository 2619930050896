import React from 'react'
import { GridRow } from '@mui/x-data-grid-pro'
import WithDeactivatedIndicator from 'components/customers/WithDeactivatedIndicator'
import PropTypes from 'prop-types'

const CustomAccountRow = props => {
  const { row } = props
  const { active } = row
  return (
    <WithDeactivatedIndicator showIndicator={!active} withHoverStyles {...props}>
      <GridRow {...props} />
    </WithDeactivatedIndicator>
  )
}

CustomAccountRow.propTypes = {
  row: PropTypes.shape({
    active: PropTypes.bool,
  }),
}

export default CustomAccountRow
