import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Divider } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import T from 'T'
import ActivityHeader from '../../common/ActivityHeader'
import AccountDetails from '../common/AccountDetails'
import InvoiceActivity from '../../common/InvoiceActivity'
import { getTransferFromUpdatedInvoices, getTransferToUpdatedInvoice } from './helper'

const SummaryStep = ({
  isIntraAccountTransfer = false,
  paidInvoices = [],
  selectedInvoice = {},
  transactionAmountCents = 0,
  sourceAccountDetails = {},
  destinationAccountDetails = {},
}) => {
  const updatedPaidInvoices = getTransferFromUpdatedInvoices(paidInvoices, transactionAmountCents)
  const updatedSelectedInvoice = getTransferToUpdatedInvoice(selectedInvoice, transactionAmountCents)
  return (
    <>
      <Box mt={2} display="flex" alignItems="center">
        <RemoveCircleIcon fontSize="small" color="textSecondary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.TRANSFERRING_FROM}:`}
        </Typography>
      </Box>
      {isIntraAccountTransfer && (
        <AccountDetails accountName={sourceAccountDetails.accountName} accountNumber={sourceAccountDetails.accountNumber} />
      )}
      <Typography variant="subtitle2" my={1}>
        {T.PREVIOUS_INVOICE}
      </Typography>
      <Box mx={1}>
        <ActivityHeader />
      </Box>
      <Divider />
      {Children.toArray(
        paidInvoices.map(invoice => {
          return (
            <>
              <Box mx={1}>
                <InvoiceActivity
                  billingProfileDetails={sourceAccountDetails.billingProfileDetails}
                  invoice={invoice}
                  showAppliedColumn
                  align="inherit"
                  marginY={1}
                />
              </Box>
              <Divider />
            </>
          )
        })
      )}
      <Typography variant="subtitle1" my={1}>
        {T.UPDATED_INVOICE}
      </Typography>
      {Children.toArray(
        updatedPaidInvoices.map(invoice => (
          <>
            <Box mx={1}>
              <InvoiceActivity
                billingProfileDetails={sourceAccountDetails.billingProfileDetails}
                invoice={invoice}
                showAppliedColumn
                align="inherit"
                marginY={1}
              />
              <Divider />
            </Box>
          </>
        ))
      )}

      <Box mt={2} display="flex" alignItems="center">
        <AddCircleIcon fontSize="small" color="primary" />
        <Typography ml={0.5} variant="h6" fontWeight={500}>
          {`${T.TRANSFERRING_TO}:`}
        </Typography>
      </Box>
      {isIntraAccountTransfer && (
        <AccountDetails accountName={destinationAccountDetails.accountName} accountNumber={destinationAccountDetails.accountNumber} />
      )}
      <Typography variant="subtitle2" my={1}>
        {T.PREVIOUS_INVOICE}
      </Typography>
      <Box mx={1}>
        <ActivityHeader />
      </Box>
      <Divider />
      <Box mx={1}>
        <InvoiceActivity
          billingProfileDetails={destinationAccountDetails.billingProfileDetails}
          invoice={selectedInvoice}
          showAppliedColumn
          align="inherit"
          marginY={1}
        />
      </Box>
      <Divider />
      <Box mx={1}>
        <Typography variant="subtitle1" my={1}>
          {T.UPDATED_INVOICE}
        </Typography>
        <InvoiceActivity
          billingProfileDetails={destinationAccountDetails.billingProfileDetails}
          invoice={updatedSelectedInvoice}
          showAppliedColumn
          align="inherit"
          marginY={1}
        />
      </Box>
      <Divider />
    </>
  )
}

SummaryStep.propTypes = {
  isIntraAccountTransfer: PropTypes.bool,
  transactionAmountCents: PropTypes.number,
  paidInvoices: PropTypes.array,
  selectedInvoice: PropTypes.object,
  sourceAccountDetails: PropTypes.object,
  destinationAccountDetails: PropTypes.object,
}

export default SummaryStep
