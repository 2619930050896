import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton, Typography, Card, CardHeader, CardContent, CardActions, Box, CircularProgress, useTheme, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import AddIcon from '@mui/icons-material/Add'

import { get } from 'utils/lodash'
import { useGetInvoiceGroupDetailsQuery } from 'api/groups/invoice/getInvoiceGroupDetails'
import { convertDateTimeToPreferedTimezone } from 'data/dateTime/convertDateTimeToPreferedTimezone'
import { ReactComponent as LayerIcon } from 'assets/Layer.svg'
import { Link } from 'react-router-dom-v5-compat'
import { HHAlert } from 'components/common/HHAlert'

import T from 'T'
import routes from 'router/routes'
import InvoiceGroupActionMenu from './InvoiceGroupActionMenu'
import InvoiceRevenueStatistic from './InvoiceRevenueStatistic'
import InvoiceStatusStatistic from './InvoiceStatusStatistic'
import InvoiceAgingBucketStatistic from './InvoiceAgingBucketStatistic'
import InvoiceEmailStatistic from './InvoiceEmailStatistic'
import AddInvoicesToGroupDialog from './add-remove/AddInvoicesToGroupDialog'
import CarouselWrapper from '../common/CarouselWrapper'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const InvoiceGroupCard = ({ group }) => {
  const theme = useTheme()
  const [invoiceGroupAnchorEl, setInvoiceGroupAnchorEl] = useState(null)
  const [isOpenAddInvoicesToGroupDialog, setIsOpenAddInvoicesToGroupDialog] = useState(false)
  const groupId = get(group, 'id')
  const { data = {}, isFetching: isLoading } = useGetInvoiceGroupDetailsQuery({ id: groupId })

  const createdBy = get(group, 'createdBy')
  const createdAt = get(group, 'createdAt')
  const { onlyDate, onlyTime } = convertDateTimeToPreferedTimezone({ dateTime: createdAt })
  const allInvoices = get(data, 'invoices', [])

  const groupDetailsLink = `${routes.app.invoiceGroups}/${groupId}`

  const handleCloseInvoiceGroupActionMenu = () => {
    setInvoiceGroupAnchorEl(false)
  }

  return (
    <>
      <Card variant="outlined" sx={{ boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)' }}>
        <CardHeader
          sx={{
            pl: 1.5,
            pr: 1,
            py: 1,
            borderBottom: '1px solid',
            borderColor: 'divider',
            '& .MuiCardHeader-avatar': { mr: 1 },
            '& .MuiCardHeader-content': { width: '60%' },
          }}
          avatar={<LayerIcon />}
          title={
            <Typography noWrap variant="h5" fontWeight={500}>
              {get(group, 'groupName', '')}
            </Typography>
          }
          action={
            <>
              <Tooltip title={T.VIEW_INVOICE_GROUP_DETAIL}>
                <IconButton component={Link} to={groupDetailsLink}>
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={T.ADD_INVOICES}>
                <IconButton onClick={() => setIsOpenAddInvoicesToGroupDialog(true)}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={event => setInvoiceGroupAnchorEl(event.currentTarget)}>
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Box height={310}>
            {isLoading && (
              <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {!isLoading && allInvoices.length === 0 && (
              <Box height="100%" display="flex" alignItems="center">
                <HHAlert borderColor={theme.palette.info.light} severity="info">
                  This invoice group is empty. Add invoices from the + button above or in the invoices page
                </HHAlert>
              </Box>
            )}

            {!isLoading && allInvoices.length > 0 && (
              <CarouselWrapper>
                <InvoiceRevenueStatistic invoices={allInvoices} />
                <InvoiceStatusStatistic invoices={allInvoices} />
                <InvoiceAgingBucketStatistic id={groupId} />
                <InvoiceEmailStatistic id={groupId} />
              </CarouselWrapper>
            )}
          </Box>
        </CardContent>
        <CardActions sx={{ px: 2, py: 1.5, justifyContent: 'space-between', borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="caption">{`Created by ${createdBy}`}</Typography>
          {createdAt && <Typography variant="caption">{`${onlyTime} | ${onlyDate}`}</Typography>}
        </CardActions>
      </Card>
      {invoiceGroupAnchorEl && (
        <InvoiceGroupActionMenu anchorEl={invoiceGroupAnchorEl} selectedGroup={data} onClose={handleCloseInvoiceGroupActionMenu} />
      )}
      {isOpenAddInvoicesToGroupDialog && (
        <AddInvoicesToGroupDialog
          isOpen={isOpenAddInvoicesToGroupDialog}
          groupId={groupId}
          onClose={() => setIsOpenAddInvoicesToGroupDialog(false)}
        />
      )}
    </>
  )
}

InvoiceGroupCard.propTypes = {
  group: PropTypes.object,
}

export default InvoiceGroupCard
