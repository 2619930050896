import React, { useEffect, useState, Children } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash/orderBy'
import capitalize from 'lodash/capitalize'

import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { Typography, Box, Pagination, Grid } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { CUSTOMER_DETAILS_DEFAULT_PAGINATION } from 'settings/constants/pagination'
import { PAYMENT_STATUS } from 'settings/constants/payment'
import { useLazyGetAccountPaymentsQuery } from 'api/accounts/getAccountPayments'
import { useLazyGetEmailEventsQuery } from 'api/accounts/getEmailEvents'
import { getPaymentReceiptEmailEvents } from 'data/email/emailEventsSelectors'

import T from 'T'
import HHTablePagination from 'components/common/HHTablePagination'
import PaymentsActionMenu from 'components/billing/common/PaymentsActionMenu'
import TakePaymentButton from 'components/common/payments/TakePaymentButton'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import PaymentReceiptDialog from 'components/billing/payments/PaymentReceiptDialog'

import { Accordion, AccordionSummary, AccordionDetails } from './StyledAccordian'
import PaymentAccordion from '../content/payments/PaymentAccordion'
import PaymentNoteDialog from '../content/payments/PaymentNoteDialog'

const { DECLINED } = PAYMENT_STATUS
const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = CUSTOMER_DETAILS_DEFAULT_PAGINATION

const Payments = ({ accountId, customerDetails = null, showTakePaymentButton = false, isViewOnly = false }) => {
  const [getAccountPayments, { data: paymentList, isFetching: isLoading }] = useLazyGetAccountPaymentsQuery()
  const [getEmailEvents, { data: emailEventList }] = useLazyGetEmailEventsQuery()
  const [isPaymentsExpanded, setIsPaymentsExpanded] = useState(true)
  const [pagination, setPagination] = useState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE })
  const [selectedPayment, setSelectedPayment] = useState(null)
  const [paymentsAnchorEl, setPaymentsAnchorEl] = useState(null)
  const [paymentNoteDialog, setPaymentNoteDialog] = useState({ isOpen: false, note: '' })
  const [paymentReceiptDialog, setPaymentReceiptDialog] = useState({ isOpen: false, payment: null })

  const { page, rowsPerPage } = pagination
  const shimmersArray = new Array(1).fill(0)

  const handlePaymentsAccChange = () => setIsPaymentsExpanded(!isPaymentsExpanded)

  const handlePageChange = (newPage = INITIAL_PAGE) => setPagination(p => ({ ...p, page: newPage }))

  const handleOpenActionMenu = event => {
    event.stopPropagation()
    setPaymentsAnchorEl(event.currentTarget)
  }

  const handleCloseActionMenu = () => {
    setPaymentsAnchorEl(null)
    setSelectedPayment(null)
  }

  const handleOpenPaymentReceiptDrawer = payment => {
    if (!payment?.paymentNumber || !payment?.id) return
    setPaymentReceiptDialog({ isOpen: true, payment })
  }

  const handleClosePaymentReceiptDrawer = () => setPaymentReceiptDialog({ isOpen: false, payment: null })

  const allPayments = orderBy(get(paymentList, 'payments', []), ['transactionPostedDate'], ['desc'])
  const emailEvents = getPaymentReceiptEmailEvents({ emailEvents: get(emailEventList, 'emailEvents', []) })
  const currentPayments = isLoading ? shimmersArray : allPayments.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  const duplicatePayments = allPayments
    .map(payment => payment?.paymentNumber)
    .filter((element, index, original) => element && original.indexOf(element) !== index)

  useEffect(() => {
    if (accountId) {
      getAccountPayments({ accountId }).unwrap().catch(handleError)
      getEmailEvents({ accountId }).unwrap().catch(handleError)
    }
  }, [accountId])

  return (
    <>
      <Accordion expanded={isPaymentsExpanded}>
        <AccordionSummary
          withNewColors
          sx={{
            '& .MuiAccordionSummary-content.Mui-expanded, & .MuiAccordionSummary-content': {
              my: 0,
            },
          }}
          disablePointer
          expandIcon={undefined}
          iconGap={false}
        >
          <Grid container alignItems="center">
            <Grid item display="flex">
              {isPaymentsExpanded ? (
                <ArrowDropDown cursor="pointer" onClick={handlePaymentsAccChange} color="disabled" />
              ) : (
                <ArrowRight cursor="pointer" onClick={handlePaymentsAccChange} color="disabled" />
              )}
            </Grid>
            <Grid item xs>
              <Typography
                sx={{ cursor: 'pointer' }}
                variant="h6"
                fontWeight={500}
                margin="auto 20px auto 8px"
                onClick={handlePaymentsAccChange}
              >
                {T.PAYMENTS}
              </Typography>
            </Grid>
            {showTakePaymentButton && (
              <Grid item xs display="flex" justifyContent="flex-end">
                <TakePaymentButton accountId={accountId} />
              </Grid>
            )}
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Box width="100%">
            <Box borderBottom="1px solid" borderColor="border.light">
              {Children.toArray(
                currentPayments.map(payment => (
                  <PaymentAccordion
                    isViewOnly={isViewOnly}
                    isLoading={isLoading}
                    billingProfileDetails={get(customerDetails, 'accountBilling.billingProfile', {})}
                    accountNumber={get(customerDetails, 'accountNumber')}
                    payment={payment}
                    emailEvents={emailEvents.filter(event => event?.paymentNumber === payment?.paymentNumber)}
                    hasActivity={capitalize(payment?.transactionStatus) !== DECLINED}
                    onPaymentNoteClick={note => setPaymentNoteDialog({ isOpen: true, note })}
                    onActionClick={event => {
                      handleOpenActionMenu(event)
                      setSelectedPayment(payment)
                    }}
                    onClick={() => handleOpenPaymentReceiptDrawer(payment)}
                  />
                ))
              )}
            </Box>

            {allPayments.length > 0 && (
              <Box backgroundColor="background.header" px={3} display="flex" alignItems="center" justifyContent="space-between">
                <HHTablePagination
                  page={page}
                  component="div"
                  count={allPayments.length}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(event, newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  onRowsPerPageChange={event => setPagination({ page: INITIAL_PAGE, rowsPerPage: event.target.value })}
                  ActionsComponent={params => <Box display="none" {...params} />}
                />
                <Pagination
                  color="primary"
                  count={Math.ceil(allPayments.length / rowsPerPage)}
                  page={page + 1}
                  onChange={(event, newPage) => handlePageChange(newPage - 1)}
                />
              </Box>
            )}

            {!isLoading && allPayments.length === 0 && <HHSectionPlaceholder title={T.NO_PAYMENTS} />}
          </Box>
        </AccordionDetails>
      </Accordion>

      <PaymentsActionMenu
        anchorEl={paymentsAnchorEl}
        customerDetails={customerDetails}
        selectedPayment={selectedPayment}
        isOriginalPaymentReversedOrTransferred={duplicatePayments.includes(selectedPayment?.paymentNumber)}
        onClose={handleCloseActionMenu}
      />
      <PaymentNoteDialog
        isOpen={paymentNoteDialog.isOpen}
        note={paymentNoteDialog.note}
        onClose={() => setPaymentNoteDialog({ isOpen: false, note: '' })}
      />
      <PaymentReceiptDialog
        open={paymentReceiptDialog.isOpen}
        accountId={accountId}
        accountName={customerDetails?.accountName}
        paymentNumber={paymentReceiptDialog?.payment?.paymentNumber}
        accountTransactionHistoryId={paymentReceiptDialog?.payment?.id}
        onClose={handleClosePaymentReceiptDrawer}
      />
    </>
  )
}

Payments.propTypes = {
  accountId: PropTypes.string.isRequired,
  customerDetails: PropTypes.object,
  showTakePaymentButton: PropTypes.bool,
  isViewOnly: PropTypes.bool,
}

export default Payments
