import Grid from '@mui/material/Grid'
import Constants from 'Constants'
import React from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import { replaceAllSpacesAndAmpersand } from 'utils/string'
import { styled } from '@mui/material/styles'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiTypography from '@mui/material/Typography'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  '&.Mui-expanded': {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
  },
  '&:before': {
    backgroundColor: theme.palette.grey[200],
    height: 0,
    content: 'none',
  },
}))
const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: '8px 12px',
  minHeight: 0,
  '&.Mui-expanded': {
    minHeight: 0,
    padding: '8px 12px',
  },
  '&	.MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
}))
const TypographyTitle = styled(MuiTypography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: theme.spacing(3),
  color: theme.palette.text.primary,
}))
const TypographyDescription = styled(MuiTypography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '20px',
  color: theme.palette.text.secondary,
}))
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}))
const Img = styled('img')(({ theme }) => ({
  filter: `invert(59%) sepia(13%) saturate(580%) hue-rotate(179deg) brightness(87%) contrast(80%)`,
  height: '20px',
  verticalAlign: 'text-bottom',
}))

const { MEDIA_SERVER } = Constants

const LinkedServicesTile = ({
  serviceName = '',
  expanded = false,
  price = '',
  pricingPeriod = '',
  businessLine = '',
  materialType = '',
  actionType = '',
  serviceType = '',
  volume = '',
  measureUnit = '',
  methodTypeIcon = '',
}) => {
  return (
    <Accordion elevation={0} expanded={expanded} square={false}>
      <AccordionSummary>
        <Grid container>
          <Grid item xs={9}>
            <TypographyTitle align="left">{serviceName}</TypographyTitle>
          </Grid>
          <Grid item xs={3}>
            <TypographyTitle align="right">
              <NumericFormat value={price} displayType="text" thousandSeparator decimalSeparator="." decimalScale={2} prefix="$" />
            </TypographyTitle>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={9}>
            <TypographyDescription>
              {businessLine}
              {serviceType && ` - ${serviceType}`}
              {volume && serviceType && ` - ${volume} ${measureUnit}`}
            </TypographyDescription>
            {materialType && (
              <TypographyDescription>
                {materialType}
                {actionType && (
                  <>
                    {' - '}
                    <Img src={`${MEDIA_SERVER}Action/${replaceAllSpacesAndAmpersand(actionType)}.svg`} alt="icons" />
                  </>
                )}
                {methodTypeIcon && (
                  <>
                    {' - '}
                    <Img src={`${MEDIA_SERVER}Method/${replaceAllSpacesAndAmpersand(methodTypeIcon)}.svg`} alt="icons" />
                  </>
                )}
              </TypographyDescription>
            )}
          </Grid>
          <Grid item xs={3}>
            <TypographyDescription align="right">{pricingPeriod}</TypographyDescription>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

LinkedServicesTile.propTypes = {
  serviceName: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  businessLine: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  expanded: PropTypes.bool,
  price: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  pricingPeriod: PropTypes.string,
  materialType: PropTypes.string,
  methodTypeIcon: PropTypes.string,
  actionType: PropTypes.string,
  serviceType: PropTypes.string,
  volume: PropTypes.string,
  measureUnit: PropTypes.string,
}

export default LinkedServicesTile
