import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { setWOReviewGeneralFeesDialog } from 'slices/billing/workOrdersReviewSlice'
import GeneralFeesDialog from './GeneralFeesDialog/GeneralFeesDialog'
import { useWOReviewContext } from './WOReviewDataGrid/WOReviewProvider'

const WithGeneralFeesDialogForm = ({ children }) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.workOrdersReview.generalFeesDialog.isOpen)
  const woId = useSelector(state => state.workOrdersReview.generalFeesDialog.woId)
  const routeId = useSelector(state => state.workOrdersReview.generalFeesDialog.routeId)
  const fees = useSelector(state => state.workOrdersReview.generalFeesDialog.fees, shallowEqual)
  const { refreshInnerRows } = useWOReviewContext()
  const handleClose = () => {
    dispatch(
      setWOReviewGeneralFeesDialog({
        isOpen: false,
        woId: '',
      })
    )
  }

  const onRefresh = () => {
    if (routeId) {
      refreshInnerRows({ routeId })
    }
  }

  return (
    <>
      {children}
      <GeneralFeesDialog open={isOpen} woId={woId} onClose={handleClose} onRefresh={onRefresh} fees={fees} />
    </>
  )
}

WithGeneralFeesDialogForm.propTypes = {
  children: PropTypes.node,
}

export default WithGeneralFeesDialogForm
