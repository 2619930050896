/* eslint-disable import/no-cycle */
import React from 'react'
import PropTypes from 'prop-types'
import lowerCase from 'lodash/capitalize'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import { downloadPaymentReceiptBlobListInChunks } from '../../../utils/file'
import T from '../../../T'
import { handleError } from '../../../utils/error'
import { putIsLoading } from '../../../middleware/actions/response'
import { useRegeneratePaymentReceiptsMutation } from '../../../api/billing/regeneratePaymentReceipts'
import {
  setAddToGroupDialogState,
  setPaymentReceiptEmailConfirmationState,
  setAddUpdatePaymentNoteState,
} from '../../../slices/billing/paymentsSlice'

const RenderGetPaymentsActionsDataCell = params => {
  const { row } = params
  const dispatch = useDispatch()
  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)
  const [regeneratePaymentReceipts] = useRegeneratePaymentReceiptsMutation()
  const paymentNumber = get(row, 'paymentNumber')
  const accountTransactionHistoryId = get(row, 'accountTransactionHistoryId')
  const batchId = get(row, 'batchId')
  const accountId = get(row, 'accountId')
  const paymentNote = get(row, 'paymentNote', '')

  const handleDownloadPaymentReceipt = () =>
    downloadPaymentReceiptBlobListInChunks(businessId, [{ paymentNumber, accountTransactionHistoryId, accountId }], true)

  const handleRegeneratePaymentReceipt = () => {
    dispatch(putIsLoading(true))
    regeneratePaymentReceipts({ accountTransactionHistoryIds: [accountTransactionHistoryId] })
      .unwrap()
      .then(() => toast.success(T.RECEIPT_IS_REGENERATING, { hideProgressBar: false, autoClose: 4000 }))
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  const handleSendPaymentReceipt = () => dispatch(setPaymentReceiptEmailConfirmationState({ isOpen: true, payments: [row] }))

  const handleAddToBatch = () => dispatch(setAddToGroupDialogState({ isOpen: true, batchIds: [batchId] }))

  const handleAddUpdatePaymentNote = () => dispatch(setAddUpdatePaymentNoteState({ isOpen: true, accountId, paymentNumber, paymentNote }))

  return [
    paymentNumber ? (
      <GridActionsCellItem
        label={`${paymentNote ? 'Edit' : 'Add'} ${lowerCase(T.PAYMENT_NOTE)}`}
        showInMenu
        onClick={handleAddUpdatePaymentNote}
      />
    ) : null,
    paymentNumber ? <GridActionsCellItem label={T.DOWNLOAD} showInMenu onClick={handleDownloadPaymentReceipt} /> : null,
    paymentNumber ? <GridActionsCellItem label={T.REGENERATE} showInMenu onClick={handleRegeneratePaymentReceipt} /> : null,
    paymentNumber ? <GridActionsCellItem label={T.EMAIL} showInMenu onClick={handleSendPaymentReceipt} /> : null,
    batchId ? <GridActionsCellItem label={T.ADD_TO_PAYMENT_GROUP} showInMenu onClick={handleAddToBatch} /> : null,
  ].filter(Boolean)
}

RenderGetPaymentsActionsDataCell.propTypes = {
  selectedPaymentNumber: PropTypes.object,
}

export default RenderGetPaymentsActionsDataCell
