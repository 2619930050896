import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, Typography } from '@mui/material'

import T from 'T'
import { WORK_ORDER_STATUS } from 'settings/constants/service'
import noop from 'lodash/noop'
import { doPost } from 'providers/api'
import { downloadFile, getFileNameFromHeaders } from 'utils/file'
import { handleError } from 'utils/error'
import { Link } from 'react-router-dom-v5-compat'
import { get } from 'lodash'
import { APP_PREFIX } from 'router/routes'
import { toast } from 'react-toastify'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ChangeRouteDate from './ChangeRouteDate'
import ImportSequenceDialog from '../ImportSequenceDialog'
import RouteDetailsModal from '../../../components/route-manager/RouteDetailsModal/RouteDetailsModal'
import HHConfirmDialog from '../../../components/common/HHConfirmDialog'
import { useDeleteRouteByIdMutation } from '../../../api/route/routeListCrud'
import { selectRouteIds } from '../../../slices/route/routeSlice'
import { getSelectedRouteIds } from '../../../data/route/selectedRoutesSelector'

const { IN_PROGRESS, COMPLETE } = WORK_ORDER_STATUS

const RouteContextMenu = ({
  contextMenu,
  currentDate = '',
  currentRoute,
  currentRouteId,
  currentRouteName = '',
  handleBulkAssign,
  handleBulkComplete = noop,
  handleClose = noop,
  handleCurrentRouteDate = noop,
  handleDateChange = noop,
  handleEditRouteStops,
  handleRouteUpdateStatus = noop,
  handleViewOnMap,
  isDragDisabled = false,
  onEditRoute = noop,
  selectedRouteStatus,
  startDate = new Date(),
  workOrderCount = '',
}) => {
  const dispatch = useDispatch()
  const open = Boolean(contextMenu)
  const [routeDateMenu, setRouteDateMenu] = useState(null)
  const [sequenceDialog, setSequenceDialog] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteRoute, { isDeleteLoading }] = useDeleteRouteByIdMutation()
  const selectedRouteIds = useSelector(getSelectedRouteIds, shallowEqual)

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
  }

  const handleConfirmDeleteModal = () => {
    deleteRoute({
      id: currentRouteId,
    })
      .unwrap()
      .then(() => {
        const filteredSelectedRouteIds = selectedRouteIds.filter(routeId => routeId !== currentRouteId)
        dispatch(selectRouteIds([...filteredSelectedRouteIds]))
        onEditRoute()
        toast.success('Route deleted successfully')
        setIsDeleteModalOpen(false)
      })
  }
  const handleModalClose = () => setIsModalOpen(false)
  const handleOpenDeleteModal = () => {
    handleClose()
    setIsDeleteModalOpen(true)
  }

  const handleExportRoute = async type => {
    const body = {
      fileType: type,
      reportName: 'route',
      serviceDate: startDate,
      routeId: currentRouteId,
    }
    try {
      const { data, headers } = await doPost({
        url: '/api/v1/core/export/download',
        body,
        showLoader: true,
        arraybuffer: 'arraybuffer',
      })
      const fileName = getFileNameFromHeaders(headers, currentRouteName, currentDate)
      downloadFile(data, fileName, type)
    } catch (e) {
      handleError(e)
    } finally {
      handleClose()
    }
  }

  const handleRouteDate = () => {
    handleClose()
    setRouteDateMenu(contextMenu)
  }

  const handleEditRoute = () => {
    setIsModalOpen(true)
    handleClose()
  }

  const handleImportSequence = () => {
    setSequenceDialog(!sequenceDialog)
    handleRouteUpdateStatus(false)
  }

  const onRouteChangesSaved = () => {
    onEditRoute()
    handleClose()
    setIsModalOpen(false)
  }

  const handleImportClose = () => {
    setSequenceDialog(false)
    handleClose()
  }

  const handleChangeDateClose = () => {
    setRouteDateMenu(null)
    handleClose()
  }

  const handleRouteDateChange = () => {
    handleDateChange()
    setRouteDateMenu(null)
  }

  return (
    <>
      <Menu
        className="change-route-info"
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        getContentAnchorEl={null}
        anchorEl={contextMenu}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {!isDragDisabled && (
          <>
            <MenuItem className="mb-2" onClick={handleEditRoute}>
              {T.EDIT_ROUTE}
            </MenuItem>

            <MenuItem className="mb-2" onClick={handleRouteDate}>
              {T.CHANGE_DATE}
            </MenuItem>
          </>
        )}
        {workOrderCount > 0 && (
          <>
            <MenuItem onClick={() => handleExportRoute('excel')}>{`${T.EXPORT_ROUTE} (${T.XLS})`}</MenuItem>
            <MenuItem onClick={() => handleExportRoute('pdf')}>{`${T.EXPORT_ROUTE} (${T.PDF})`}</MenuItem>
            <MenuItem onClick={handleImportSequence}>{T.IMPORT_SEQUENCE}</MenuItem>
            <MenuItem onClick={handleViewOnMap}>{T.VIEW_ON_MAP}</MenuItem>
            <MenuItem
              component={Link}
              target="_blank"
              rel="noopener noreferrer"
              to={`${APP_PREFIX}/routes/${currentRouteId}/work_orders/export?date=${startDate}`}
            >
              View work orders
            </MenuItem>
            {!isDragDisabled && selectedRouteStatus !== IN_PROGRESS && selectedRouteStatus !== COMPLETE && (
              <>
                {/* Later create common handler */}
                <MenuItem onClick={handleEditRouteStops}>{T.BULK_UNASSIGN}</MenuItem>
                <MenuItem onClick={handleBulkComplete}>{T.BULK_COMPLETE}</MenuItem>
                <MenuItem onClick={handleBulkAssign}>{T.BULK_MOVE}</MenuItem>
              </>
            )}
          </>
        )}
        <MenuItem onClick={handleOpenDeleteModal}>
          <Typography variant="h6" color="error" fontWeight="normal">
            Delete route
          </Typography>
        </MenuItem>
      </Menu>
      <HHConfirmDialog
        isOpen={isDeleteModalOpen}
        confirmTitle="Confirm route deletion"
        loading={isDeleteLoading}
        confirmDescription={
          <Typography component="p">
            Deleting route
            <Typography component="span" fontWeight="bold">
              {` ${get(currentRoute, 'name')} `}
            </Typography>
            will automatically unassigned all stops from today. Are you sure you want to continue?
          </Typography>
        }
        onConfirm={handleConfirmDeleteModal}
        onClose={handleCloseDeleteModal}
      />
      <ChangeRouteDate
        currentDate={currentDate}
        startDate={startDate}
        routeDateMenu={routeDateMenu}
        handleClose={handleChangeDateClose}
        handleDateChange={handleRouteDateChange}
        handleCurrentRouteDate={handleCurrentRouteDate}
      />
      <ImportSequenceDialog
        openImportSequenceDialog={sequenceDialog}
        routeId={currentRouteId}
        serviceDate={startDate}
        handleSequenceDialog={handleImportSequence}
        handleContextMenu={handleImportClose}
        handleRouteUpdate={handleRouteUpdateStatus}
      />
      <RouteDetailsModal
        serviceDate={startDate}
        onSave={onRouteChangesSaved}
        onDelete={onRouteChangesSaved}
        defaultMode="update"
        open={isModalOpen}
        route={currentRoute}
        onClose={handleModalClose}
      />
    </>
  )
}

RouteContextMenu.propTypes = {
  contextMenu: PropTypes.object.isRequired,
  currentDate: PropTypes.string,
  currentRoute: PropTypes.object,
  currentRouteId: PropTypes.string.isRequired,
  currentRouteName: PropTypes.string,
  handleBulkAssign: PropTypes.func.isRequired,
  handleBulkComplete: PropTypes.func,
  handleClose: PropTypes.func,
  handleCurrentRouteDate: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleEditRouteStops: PropTypes.func.isRequired,
  handleRouteUpdateStatus: PropTypes.func.isRequired,
  handleViewOnMap: PropTypes.func.isRequired,
  isDragDisabled: PropTypes.bool,
  onEditRoute: PropTypes.func,
  selectedRouteStatus: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  workOrderCount: PropTypes.string,
}

export default RouteContextMenu
