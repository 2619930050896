import React, { Children } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { Checkbox, FormControlLabel, Box, Typography } from '@mui/material'

import Constants from 'Constants'

import { get } from 'lodash'

import T from 'T'

const { MEDIA_SERVER } = Constants

const CheckboxContainer = ({ records = noop, businessLines = {}, handleCheckedValues = noop, addNew = false }) => {
  const getIntervalRecord = (per, interval) => {
    if (per > 1) {
      return `${per} ${interval}s`
    }
    return interval
  }

  const getLabel = (type, data) => {
    switch (type) {
      case 'period':
        return getIntervalRecord(data.periodPer, data.periodInterval)
      case 'recurrence':
        return getIntervalRecord(data.recurrencePer, data.recurrenceInterval)
      case 'measure':
        return get(data, 'title', '')
      case 'materials':
        return get(data, 'materialType', '')
      case 'action':
        return get(data, 'actionName', '')
      case 'methods':
        return get(data, 'methodName', '')
      default:
        return []
    }
  }

  const measures = []
  const getMeasureRecords = (measur, type) => {
    if (type === 'add') {
      Children.toArray(
        Object.entries(measur).map(([key, value]) => {
          Children.toArray(
            value.map(val => {
              measures.push({ id: val.id, title: `${val.volume} ${key}`, alreadySelected: val.alreadySelected, unit: key })
            })
          )
        })
      )
    } else {
      Children.toArray(
        measur.map(val => {
          measures.push({ id: val.id, title: `${val.volume} ${val.unit}`, alreadySelected: val.alreadySelected, unit: val.unit })
        })
      )
    }

    return measures
  }

  let period = get(records, 'service.period', [])
  let recurrence = get(records, 'service.recurrence', [])
  let measure = getMeasureRecords(get(records, 'measure', []), 'all')
  let material = get(records, 'material', [])
  let action = get(records, 'action', [])
  let method = get(records, 'method', [])

  if (Object.keys(businessLines).length > 0 && addNew) {
    period = get(businessLines, 'service.period', [])
    recurrence = get(businessLines, 'service.recurrence', [])
    measure = getMeasureRecords(get(businessLines, 'measure', []), 'add')
    material = get(businessLines, 'material', [])
    action = get(businessLines, 'action', [])
    method = get(businessLines, 'method', [])
  }

  const allRecords = [
    { type: 'period', title: `${T.SERVICE}- ${T.PRICING_PERIOD}`, dataValues: period },
    { type: 'recurrence', title: `${T.SERVICE}- ${T.SERVICE_INTERVAL}`, dataValues: recurrence },
    { type: 'measure', title: T.MEASURE, dataValues: measure },
    { type: 'materials', title: `${T.MATERIAL}s`, dataValues: material.filter(data => data.active) },
    { type: 'action', title: `${T.ACTION}s`, dataValues: action },
    { type: 'methods', title: `${T.METHOD}s`, dataValues: method },
  ]

  return (
    <Box display="flex">
      {Children.toArray(
        allRecords.length > 0 &&
          allRecords.map((record, index) => {
            return (
              <Box ml={index > 0 ? 2.5 : 0}>
                <Typography
                  variant="subtitle2"
                  pb={1}
                  mb={1}
                  sx={{
                    borderBottom: theme => `1px solid ${theme.palette.background.tags}`,
                  }}
                  noWrap
                >
                  {record.title}
                </Typography>
                {Children.toArray(
                  record.dataValues.length > 0 &&
                    record.dataValues.map(data => {
                      return (
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} width="16px" height="16px" alt="" />}
                                checkedIcon={<img src={`${MEDIA_SERVER}ConfiguratorCheckbox.svg`} alt="" />}
                                onChange={event => handleCheckedValues(record.type, records.id, data, event)}
                                checked={get(data, 'alreadySelected', false)}
                                name={`${record.title}${index}`}
                              />
                            }
                            label={getLabel(record.type, data)}
                          />
                        </Box>
                      )
                    })
                )}
              </Box>
            )
          })
      )}
    </Box>
  )
}

CheckboxContainer.propTypes = {
  records: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  addNew: PropTypes.bool,
  businessLines: PropTypes.object,
  handleCheckedValues: PropTypes.func,
}

export default CheckboxContainer
