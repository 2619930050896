import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Chip, SvgIcon, Typography } from '@mui/material'
import { red, grey } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar'
import { ReactComponent as TicketIcon } from 'assets/work-orders/TicketIcon.svg'
import noop from 'lodash/noop'
import HHDisplayMoney from '../../common/HHDisplayMoney'

const GeneralFeesChip = forwardRef(
  ({ hasFees = false, onClick = noop, feesCount, totalFeeChargeCents, disabled = false, ...rest }, ref) => {
    return (
      <Chip
        ref={ref}
        size="small"
        variant={hasFees ? 'filled' : 'outlined'}
        avatar={
          <Avatar sx={{ backgroundColor: 'background.black', opacity: 0.38 }}>
            <SvgIcon inheritViewBox viewBox="0 0 15 11" sx={{ height: '11px' }}>
              <TicketIcon />
            </SvgIcon>
          </Avatar>
        }
        disabled={disabled}
        label={
          <>
            {hasFees && (
              <Typography fontWeight={500}>
                {`${feesCount}x`}
                <HHDisplayMoney value={totalFeeChargeCents} formatToDollars />
              </Typography>
            )}
            {!hasFees && '+ general fee'}
          </>
        }
        sx={{
          backgroundColor: hasFees ? red[100] : grey[100],
          '&:hover': {
            backgroundColor: hasFees ? red[200] : grey[200],
          },
          borderStyle: hasFees ? 'none' : 'dashed',
        }}
        onClick={onClick}
        {...rest}
      />
    )
  }
)

GeneralFeesChip.propTypes = {
  hasFees: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  feesCount: PropTypes.string,
  totalFeeChargeCents: PropTypes.number,
  disabled: PropTypes.bool,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
}

export default GeneralFeesChip
