import React from 'react'
import PropTypes from 'prop-types'
import useTheme from '@mui/material/styles/useTheme'
import { Avatar, Typography } from '@mui/material'
import noop from 'lodash/noop'
import endpoint from '../../../../middleware/sagas/endpoint'
import { extractNameInitials } from '../../../../utils/string'
import { isValidHex } from '../../../../utils/validations'

const RouteAvatar = ({ name, bgcolor, avatarSrc, onClick = noop, sx = {}, TypographyProps = {} }) => {
  const theme = useTheme()
  const initials = extractNameInitials(name || '')
  const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
  const color = theme.palette.getContrastText(formattedBgcolor)
  return (
    <Avatar
      onClick={onClick}
      sx={{
        width: '32px',
        height: '32px',
        bgcolor: formattedBgcolor,
        color,
        ...sx,
      }}
      src={avatarSrc ? `${endpoint.user.profilePicUri}${avatarSrc}` : undefined}
    >
      <Typography variant="h5" fontWeight={400} {...TypographyProps}>
        {initials}
      </Typography>
    </Avatar>
  )
}

RouteAvatar.propTypes = {
  name: PropTypes.string,
  bgcolor: PropTypes.string,
  avatarSrc: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  TypographyProps: PropTypes.object,
}

export default RouteAvatar
