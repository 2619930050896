/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

export const getInitialDrawerRouterState = ({ favoriteUrl = '' }) => ({
  favoriteUrl,
  isDrawerOpen: true,
})

export const createDrawerRouterSlice = ({ name, initialState = getInitialDrawerRouterState(), reducers }) =>
  createSlice({
    name,
    initialState,
    reducers: {
      openDrawer: state => {
        state.isDrawerOpen = true
      },
      closeDrawer: state => {
        state.isDrawerOpen = false
      },
      setIsDrawerOpen: (state, action) => {
        state.isDrawerOpen = action.payload
      },
      setFavoriteUrl: (state, action) => {
        state.favoriteUrl = action.payload
      },
      ...reducers,
    },
  })
