import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'

import {
  useCreateBillingNoteMutation,
  useUpdateBillingNoteMutation,
  usePatchBillingNoteMutation,
  useDeleteBillingNoteMutation,
  useLazyGetBillingNoteHistoryQuery,
} from 'api/notes/billingNotesCrud'

import T from 'T'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import AddUpdateNoteDialog from '../common/AddUpdateNoteDialog'
import NoteListItem from '../common/NoteListItem'
import NoteActionMenu from '../common/NoteActionMenu'
import ViewNoteDialog from '../common/ViewNoteDialog'

const BillingNotes = ({ notes = [], accountId = '', noNotesText = T.NO_NOTES_V2, showSecondaryActions = true, sx, itemSx }) => {
  const [isOpenAddUpdateNoteDialog, setIsOpenAddUpdateNoteDialog] = useState(null)
  const [isOpenViewNoteDialog, setIsOpenViewNoteDialog] = useState(null)
  const [isOpenNoteActionMenu, setIsOpenNoteActionMenu] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const hasNotes = notes.length > 0

  const handleOpenUpdateBillingNote = () => setIsOpenAddUpdateNoteDialog(true)

  const handleOpenViewBillingNoteDialog = note => {
    setSelectedNote(note)
    setIsOpenViewNoteDialog(true)
  }

  const handleCloseViewBillingNoteDialog = () => {
    setSelectedNote(null)
    setIsOpenViewNoteDialog(null)
  }

  const handleOpenBillingNoteActionMenu = (event, note) => {
    event.stopPropagation()
    setSelectedNote(note)
    setIsOpenNoteActionMenu(event.currentTarget)
  }

  const handleCloseBillingNoteActionMenu = () => setIsOpenNoteActionMenu(null)

  const handleCloseAddUpdateBillingNote = () => {
    setSelectedNote(null)
    setIsOpenAddUpdateNoteDialog(false)
  }

  return (
    <>
      {!hasNotes && <HHSectionPlaceholder title={noNotesText} />}
      {hasNotes && (
        <List sx={{ py: 0, ...sx }}>
          {Children.toArray(
            notes.map((note, index) => (
              <NoteListItem
                sx={itemSx}
                note={note}
                showNoteVisibilityOnMobileIcon={false}
                showDivider={notes.length - 1 !== index}
                showSecondaryActions={showSecondaryActions}
                onViewClick={() => handleOpenViewBillingNoteDialog(note)}
                onActionMenuClick={event => handleOpenBillingNoteActionMenu(event, note)}
              />
            ))
          )}
        </List>
      )}
      <AddUpdateNoteDialog
        id={accountId}
        isOpen={isOpenAddUpdateNoteDialog}
        title={selectedNote ? T.BILLING_NOTE : T.NEW_BILLING_NOTE}
        existingNote={selectedNote}
        useCreateNoteMutation={useCreateBillingNoteMutation}
        useUpdateNoteMutation={useUpdateBillingNoteMutation}
        onClose={handleCloseAddUpdateBillingNote}
      />
      <ViewNoteDialog
        id={accountId}
        isOpen={isOpenViewNoteDialog}
        title={T.BILLING_NOTE}
        selectedNote={selectedNote}
        useLazyGetNoteHistoryQuery={useLazyGetBillingNoteHistoryQuery}
        onClose={handleCloseViewBillingNoteDialog}
      />
      <NoteActionMenu
        id={accountId}
        anchorEl={isOpenNoteActionMenu}
        selectedNote={selectedNote}
        showNoteVisibilityOnMobileOption={false}
        onEditClick={handleOpenUpdateBillingNote}
        usePatchNoteMutation={usePatchBillingNoteMutation}
        useDeleteNoteMutation={useDeleteBillingNoteMutation}
        onClose={handleCloseBillingNoteActionMenu}
      />
    </>
  )
}

BillingNotes.propTypes = {
  notes: PropTypes.array,
  accountId: PropTypes.string,
  noNotesText: PropTypes.string,
  showSecondaryActions: PropTypes.bool,
  sx: PropTypes.object,
  itemSx: PropTypes.object,
}

export default BillingNotes
