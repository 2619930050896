import React from 'react'
import PropTypes from 'prop-types'

import { Box, useMediaQuery, useTheme } from '@mui/material'

const TabMainLayout = ({ children }) => {
  const theme = useTheme()
  const isTabletorMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box bgcolor="background.paper" display={isTabletorMobile ? 'block' : 'flex'}>
      {children}
    </Box>
  )
}

TabMainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabMainLayout
