import React, { useCallback, Children, useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Reply } from '@styled-icons/heroicons-outline/Reply'

import { useLazyGetValidatePricingTaxesQuery } from 'api/pricing/getValidatePricingTaxes'
import { useLazyGetExistingTaxesQuery } from 'api/pricing/getExistingTaxes'
import { useCreateTaxesMutation } from 'api/pricing/createTaxes'
import { useUpdateTaxesMutation } from 'api/pricing/updateTaxes'
import { useLazyGetTaxMatchedServicesQuery } from 'api/pricing/getTaxMatchedServices'

import { getGlobalFilterOptions } from 'middleware/actions/globalsearch'

import { PRICING_PAGINATION } from 'settings/constants/pagination'

import CommonDrawer from 'components/common/CommonDrawer'
import TaxInitialScreen from 'components/pricing/Taxes/TaxInitialScreen'
import TaxFormScreen from 'components/pricing/Taxes/TaxFormScreen'
import TaxTableScreen from 'components/pricing/Taxes/TaxTableScreen'
import ChangePrice from 'components/pricing/ChangePrice'
import { ALL_TAXES, TAXES } from 'components/pricing/settings'
import { PRICING_CONFIRMATION_MSG } from 'settings/constants/pricing'
import { getByLabelText } from 'components/globalfilter/autoCompleteLabelText'
import ConfirmationModal from 'components/modal/ConfirmationModal'
import Loader from 'components/common/loader'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'

import Constants from 'Constants'
import T from 'T'
import HHDialogTitle from 'components/common/HHDialogTitle'
import { Divider } from '@mui/material'

import LinkedServicesList from '../LinkedServicesList/LinkedServicesList'
import './taxes.scss'
import { truncateDecimals } from '../../../utils/validations'

const { MEDIA_SERVER } = Constants
const { INITIAL_PAGE, ROWS_PER_PAGE } = PRICING_PAGINATION

const TAX_VIEWS = {
  LINKED_SERVICES_VIEW: 'linked-tax-drawer',
  TAX_FORM_VIEW: 'tax-form',
  TAX_TABLE_VIEW: 'tax-table',
  EDIT_TAXES_VIEW: 'edit-taxes',
}

const { LINKED_SERVICES_VIEW, TAX_FORM_VIEW, TAX_TABLE_VIEW, EDIT_TAXES_VIEW } = TAX_VIEWS

const NewTaxModal = ({
  page,
  rowsPerPage,
  openDrawer,
  taxScreen,
  setTaxScreen,
  confServices,
  setOpenDrawer,
  getTaxServicesResults,
  getTaxServiceName,
  handleChangePriceDelete,
  onHandleEditFieldsChange,
  selectedEditValues,
  isDirty,
  setState,
}) => {
  const dispatch = useDispatch()

  const { globalFilterOptions } = useSelector(
    state => ({
      globalFilterOptions: get(state, 'GlobalSearchReducer.globalFilterOptions', null),
    }),
    shallowEqual
  )

  const [configData, setConfigdata] = useState([])
  const [checkedTaxValues, setCheckedTaxValues] = useState({})
  const [allTaxes, setAllTaxes] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [businessLines, setBusinessLines] = useState([])
  const [backFlag, setBackFlag] = useState(false)
  const [matchedServices, setMatchedServices] = useState([])
  const [createTaxes, { isLoading }] = useCreateTaxesMutation()
  const [getValidatePricingTaxes] = useLazyGetValidatePricingTaxesQuery()
  const [getExistingTaxes] = useLazyGetExistingTaxesQuery()
  const [updateTaxes, { isLoading: isUpdateLoading }] = useUpdateTaxesMutation()
  const [getTaxMatchedServices] = useLazyGetTaxMatchedServicesQuery()

  const [inputTaxText, setInputTaxText] = useState({
    taxName: '',
    value: '',
    priceLocked: false,
    taxScopeGlobal: false,
    taxScopeCumulative: false,
    taxScopeAutoLink: false,
    valueType: '%',
    isNameDirty: false,
  })

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    taxInput: '',
    validatedTaxes: [],
    duplicateTaxRecords: [],
    combinationCount: 0,
    isOpenTaxConfirmationModal: false,
    isMultipleRecordsAltered: false,
  })

  const { taxInput, validatedTaxes, duplicateTaxRecords, combinationCount, isOpenTaxConfirmationModal, isMultipleRecordsAltered } =
    localState

  useEffect(() => {
    setConfigdata(ALL_TAXES)
  }, [])

  useEffect(() => {
    setConfigdata(confServices)
  }, [confServices])

  const onBusinessLineChange = label => {
    if (businessLines.includes(label)) {
      let businessLineSelected
      businessLineSelected = businessLines.filter(data => data !== label)
      setBusinessLines(businessLineSelected)
    } else {
      businessLines.push(label)
      setBusinessLines(businessLines)
    }
    setState({ isDirty: true })
    setInputTaxText({ ...inputTaxText, priceLocked: inputTaxText.priceLocked })
  }

  const onValueTypeChange = value => {
    setState({ isDirty: true })
    setInputTaxText({
      ...inputTaxText,
      valueType: value,
      value: value !== '%' && inputTaxText.value ? truncateDecimals(Number.parseFloat(inputTaxText.value), 2) : inputTaxText.value,
    })
  }

  const onTableScopeChange = (value, service, type) => {
    if (validatedTaxes.length > 0) {
      const validatedTaxService = validatedTaxes.find(data => data === service)
      if (validatedTaxService !== undefined) {
        if (type === 'taxScope') {
          validatedTaxService.taxScope = value
        } else {
          validatedTaxService.taxValueType = value
          if (value !== 'percentage') {
            validatedTaxService.taxValue = truncateDecimals(validatedTaxService.taxValue, 2)
          }
        }
      }
    }

    if (duplicateTaxRecords.length > 0) {
      const duplicateTaxService = duplicateTaxRecords.find(data => data === service)

      if (duplicateTaxService !== undefined) {
        if (type === 'taxScope') {
          duplicateTaxService.taxScope = value
        } else {
          duplicateTaxService.taxValueType = value
          if (value !== 'percentage') {
            duplicateTaxService.taxValue = Number.parseFloat(duplicateTaxService.taxValue).toFixed(2)
          }
        }
      }
    }

    setState({ isDirty: true })

    setLocalState({
      validatedTaxes,
      duplicateTaxRecords,
    })

    handleTaxReValidate(type, value, service)
    setInputTaxText({ ...inputTaxText, valueType: value })
  }

  const handleSwitchVal = (scope, value) => {
    setState({ isDirty: true })
    if (scope === 'Global') setInputTaxText({ ...inputTaxText, taxScopeGlobal: value, taxScopeCumulative: value })
    else if (scope === 'Cumulative' && !inputTaxText.taxScopeGlobal) setInputTaxText({ ...inputTaxText, taxScopeCumulative: value })
    else if (scope === 'AutoLink') setInputTaxText({ ...inputTaxText, taxScopeAutoLink: value })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLinkedServices = () => {
    if (checkedTaxValues) {
      const payload = { search: '' }
      if (Array.isArray(checkedTaxValues?.actions) && checkedTaxValues.actions.length > 0) {
        payload.action = get(checkedTaxValues, 'actions', []).map(({ id }) => id)
      }
      if (Array.isArray(checkedTaxValues?.materials) && checkedTaxValues.materials.length > 0) {
        payload.material = get(checkedTaxValues, 'materials', []).map(({ id }) => id)
      }
      if (Array.isArray(checkedTaxValues?.measures) && checkedTaxValues.measures.length > 0) {
        payload.measure = get(checkedTaxValues, 'measures', []).map(({ id }) => id)
      }
      if (Array.isArray(businessLines) && businessLines.length > 0) {
        payload.businessLine = businessLines
      }
      if (Array.isArray(checkedTaxValues?.pricingPeriod) && checkedTaxValues.pricingPeriod.length > 0) {
        payload.pricingPeriod = get(checkedTaxValues, 'pricingPeriod', []).map(({ id }) => id)
      }

      getTaxMatchedServices(payload)
        .unwrap()
        .then(data => setMatchedServices(Array.isArray(data) ? data : []))
        .catch(handleError)
    }
  }

  const handleProceed = () => {
    switch (taxScreen) {
      case 'initial':
        if (businessLines.length > 0) {
          const payload = {
            businessLine: businessLines,
          }

          dispatch(getGlobalFilterOptions(payload))
        } else {
          dispatch(getGlobalFilterOptions())
        }

        if (businessLines.length === 1) {
          setLocalState({ taxInput: `${businessLines[0].trim()}` })
        } else {
          setLocalState({ taxInput: taxInput.replace(businessLines[0], '').trim() })
        }

        getDefaultTaxTitle()
        handleLinkedServices()
        setTaxScreen(TAX_FORM_VIEW)

        document.getElementsByClassName('tabpanel')[0].scrollTop = 0

        return

      case TAX_FORM_VIEW:
        handleTaxValidate(checkedTaxValues, 'submit')
        return
      case TAX_TABLE_VIEW:
        handleCreateTaxes()
        return
      case EDIT_TAXES_VIEW:
        handleUpdateTaxes()
        return
      case LINKED_SERVICES_VIEW:
        setTaxScreen(TAX_FORM_VIEW)
    }
  }

  const handleTaxValidate = (values, type) => {
    let scope
    if (inputTaxText.taxScopeGlobal) {
      scope = 'Global'
    } else if (inputTaxText.taxScopeCumulative) {
      scope = 'Cumulative'
    } else {
      scope = null
    }

    let title = inputTaxText.taxName

    if (title === '') title = taxInput

    const businessLineMeta = get(globalFilterOptions, 'businessLines', [])

    const businessLineRecord =
      businessLineMeta.length > 0 ? businessLineMeta.filter(data => businessLines.includes(data.businessLineLabel)) : []

    const businessIds = []

    Children.toArray(
      businessLineRecord.length > 0 &&
        businessLineRecord.map(data => {
          businessIds.push({ id: data.id })
        })
    )

    const payload = {
      taxName: title.trim(),
      taxScope: scope,
      autoLink: inputTaxText.taxScopeAutoLink,
      businessLine: businessIds,
      material: get(values, 'materials', []),
      action: get(values, 'actions', []),
      taxZone: get(values, 'taxZones', []),
      measure: get(values, 'measures', []),
      pricingPeriod: get(values, 'pricingPeriod', []),
      taxValueType: inputTaxText.valueType,
      taxValue: inputTaxText.value.toString().replaceAll(',', ''),
      exceptionType: get(values, 'exceptions', []).map(data => ({ value: data.label })),
      priceLocked: inputTaxText.priceLocked,
      services: get(values, 'linkedServices', []),
      generalLedgerAccountId: get(values, 'generalLedgerAccount[0]', {}).id,
    }
    allTaxes.push(payload)
    setAllTaxes(allTaxes)

    if (type === 'submit') {
      setState({
        configuratorColumns: cloneDeep(ALL_TAXES),
        pastConfiguratorColumns: cloneDeep(ALL_TAXES),
      })
    }

    getValidatePricingTaxes(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        const validData = res.taxList.filter(data => !data.exist)

        setLocalState({
          validatedTaxes: validData,
          duplicateTaxRecords: res.taxList.filter(data => data.exist),
          combinationCount: validData.length,
        })

        if (type === 'submit') {
          setTaxScreen(TAX_TABLE_VIEW)
        }
      })
      .catch(handleError)

    if (type !== 'submit') {
      handleLinkedServices()
    }
  }

  const handleTaxReValidate = (name, value, service) => {
    const payload = {
      active: get(service, 'active', false),
      autoLink: get(service, 'autoLink', false),
      businessLine: get(service, 'businessLine', ''),
      exceptionType: get(service, 'exceptionType', ''),
      exist: get(service, 'exist', false),
      linked: get(service, 'linked', false),
      material: { id: get(service, 'material', { id: null }).id },
      measure: { id: get(service, 'measure', { id: null }).id },
      period: { id: get(service, 'period', { id: null }).id },
      priceLocked: get(service, 'priceLocked', false),
      serviceAction: { id: get(service, 'serviceAction', { id: null }).id },
      services: get(service, 'services', [{ id: null }]).map(data => ({ id: data.id })),
      taxName: name === 'taxName' ? value : get(service, 'taxName', ''),
      taxValue: name === 'taxValue' ? value.toString().replaceAll(',', '') : get(service, 'taxValue', '').toString().replaceAll(',', ''),
      taxValueType: get(service, 'taxValueType', '%'),
      taxZone: { id: get(service, 'taxZone', { id: null }).id },
      generalLedgerAccountId: get(service, 'generalLedgerAccountId', null),
    }

    getExistingTaxes(payload)
      .unwrap()
      .then(response => {
        const res = cloneDeep(response)
        service.exist = res.exist

        const allTaxRecords = validatedTaxes.concat(duplicateTaxRecords)
        const validTaxData = allTaxRecords.filter(data => !data.exist)

        setLocalState({
          validatedTaxes: validTaxData,
          duplicateTaxRecords: allTaxRecords.filter(data => data.exist),
          combinationCount: validTaxData.length,
        })
      })
      .catch(handleError)
  }

  const handleCreateTaxes = () => {
    const payload = {
      pricedTax: validatedTaxes.filter(data => (data.taxValue = data.taxValue.toString().replaceAll(',', ''))),
    }

    createTaxes(payload)
      .unwrap()
      .then(() => {
        getTaxServicesResults(page, rowsPerPage)
        setTaxScreen('initial')
        handleTaxesCancel()
      })
      .catch(handleError)
  }

  const handleUpdateTaxes = type => {
    const payload = {
      tax: selectedEditValues,
    }

    updateTaxes(payload)
      .unwrap()
      .then(() => {
        getTaxServicesResults(page, rowsPerPage)
        setTaxScreen('initial')
        handleTaxesCancel()
      })

      .catch(handleError)
  }

  const handleCheckboxValue = (label, records) => {
    const taxCategory = camelCase(label.replace(' ', ''))
    checkedTaxValues[taxCategory] = []

    if ((label === 'Pricing Period' || label === `${T.GENERAL_LEDGER} ${T.ACCOUNT}`) && records !== null) {
      checkedTaxValues[taxCategory] = [
        {
          id: records.id,
          label: `${records.periodPer} ${records.periodInterval}`,
        },
      ]
    } else {
      records &&
        Children.toArray(
          records.forEach(data => {
            checkedTaxValues[taxCategory].push({ id: data.id, label: getByLabelText(data, label) })
          })
        )
    }

    setCheckedTaxValues(checkedTaxValues)

    setState({ isDirty: true })

    getDefaultTaxTitle()

    handleTaxValidate(checkedTaxValues, 'auto')
  }

  const getDefaultTaxTitle = () => {
    const businessLine = businessLines.length > 1 ? '' : businessLines.join(' ')
    const taxZone = checkedTaxValues.taxZones && checkedTaxValues.taxZones.length === 1 && checkedTaxValues.taxZones
    const measure = checkedTaxValues.measures && checkedTaxValues.measures.length === 1 && checkedTaxValues.measures
    const material = checkedTaxValues.materials && checkedTaxValues.materials.length === 1 && checkedTaxValues.materials

    const taxZoneLabel = get(taxZone, '[0]label', '')
    const measureLabel = get(measure, '[0]label', '')
    const materialLabel = get(material, '[0]label', '')

    let taxVal = businessLine

    if (taxZoneLabel != '') {
      taxVal += ` ${taxZoneLabel}`
    }

    if (measureLabel != '') {
      taxVal += ` ${measureLabel}`
    }

    if (materialLabel != '') {
      taxVal += ` ${materialLabel}`
    }

    setLocalState({ taxInput: taxVal })
  }

  const onHandleChange = useCallback(event => {
    const { value } = event.target

    const { name } = event.target

    setState({ isDirty: true })
    if (name === 'taxName') {
      inputTaxText.isNameDirty = true
    }
    inputTaxText[name] = value
    setInputTaxText({ ...inputTaxText })
  })

  const handlePriceLocked = () => {
    setState({ isDirty: true })
    setInputTaxText({ ...inputTaxText, priceLocked: !inputTaxText.priceLocked })
  }

  const handleTaxDisabled = (() => {
    let isDisabled = false
    if (taxScreen === TAX_FORM_VIEW) {
      const taxName = taxInput || inputTaxText.taxName

      isDisabled = !(Object.keys(checkedTaxValues).includes('taxZones') && checkedTaxValues.taxZones.length > 0 && taxName !== '')
    } else if (taxScreen === TAX_TABLE_VIEW) {
      isDisabled = validatedTaxes.length === 0 || validatedTaxes.find(({ taxName }) => taxName?.replaceAll(' ', '') === '') !== undefined
    } else if (taxScreen === EDIT_TAXES_VIEW) {
      isDisabled = !!selectedEditValues.filter(Boolean).find(({ taxName }) => taxName?.trim() === '')
    }
    return isDisabled
  })()

  const onHandleValidatedFieldsChange = (e, service) => {
    const { name, value } = e.target
    if (!isMultipleRecordsAltered) {
      setLocalState({
        isMultipleRecordsAltered: true,
      })
    }

    handleTaxReValidate(name, value, service)

    if (validatedTaxes.length > 0) {
      validatedTaxes.find(data => data === service)[name] = value

      setLocalState({
        validatedTaxes,
      })
    }

    if (duplicateTaxRecords.length > 0) {
      duplicateTaxRecords.find(data => data === service)[name] = value

      setLocalState({
        duplicateTaxRecords,
      })
    }

    if (validatedTaxes.concat(duplicateTaxRecords).length === 1) {
      setInputTaxText({ ...inputTaxText, [name === 'taxValue' ? 'value' : name]: value })
    }
  }

  const handleDelete = (service, type) => {
    const validateRecords = validatedTaxes.filter(data => data !== service)

    if (type === 'duplicate') {
      setLocalState({
        validatedTaxes: validateRecords,
        duplicateTaxRecords: duplicateTaxRecords.filter(data => data !== service),
        combinationCount: validateRecords.length,
      })
    } else {
      setLocalState({
        validatedTaxes: validateRecords,
        combinationCount: validateRecords.length,
      })
    }
  }

  const handleButtonText = () => {
    const totalRecords = duplicateTaxRecords.length + validatedTaxes.length
    let editCount = ''

    if (taxScreen === TAX_FORM_VIEW) {
      selectedEditValues = 0
    }

    if (selectedEditValues.length > 0) {
      editCount = selectedEditValues.filter(Boolean).length
    }

    if (taxScreen === 'initial') {
      return `${T.CONTINUE}`
    }
    if (taxScreen === TAX_FORM_VIEW && totalRecords > 0) {
      return `${T.CREATE_TAX} (${totalRecords})`
    }
    if (taxScreen === TAX_TABLE_VIEW && combinationCount > 0) {
      return `${T.CREATE_TAX} (${combinationCount})`
    }
    if (editCount > 0 && taxScreen === EDIT_TAXES_VIEW) {
      return `${T.CHANGE_TAX}es (${editCount})`
    }
    if (taxScreen === EDIT_TAXES_VIEW) {
      return `${T.CHANGE_TAX}es`
    }
    if (taxScreen === LINKED_SERVICES_VIEW) {
      return `${T.SAVE}`
    }
    return `${T.CREATE_TAX}`
  }

  const handleTaxesBackBtn = () => {
    if (taxScreen === TAX_TABLE_VIEW) {
      if (isMultipleRecordsAltered) {
        setLocalState({ isOpenTaxConfirmationModal: true })
        return
      }
      setTaxScreen(TAX_FORM_VIEW)

      handleTaxValidate(checkedTaxValues, 'auto')
    } else if (taxScreen === TAX_FORM_VIEW) {
      setTaxScreen('initial')
    } else {
      setOpenDrawer(false)
    }

    setBackFlag(true)
  }

  const handleTaxesCancel = () => {
    setCheckedTaxValues({})
    setInputTaxText({ taxName: '', value: '', priceLocked: false, taxScope: 'Cumulative', businessLine: '', valueType: '%' })

    setLocalState({ taxInput: '', isOpenTaxConfirmationModal: false, isMultipleRecordsAltered: false })
    setBusinessLines([])

    setOpenDrawer(false)

    setState({
      isDirty: false,
      configuratorColumns: cloneDeep(TAXES),
      pastConfiguratorColumns: cloneDeep(TAXES),
    })
  }

  return (
    <div>
      {(isLoading || isUpdateLoading) && <Loader />}
      <CommonDrawer
        isOpen={openDrawer}
        isDirty={isDirty}
        className={`add-tax-drawer ${taxScreen}`}
        onChange={(event, isOpen) => handleTaxesCancel()}
      >
        <section>
          <HHDialogTitle
            icon={
              <img
                className="header-icon"
                src={taxScreen === EDIT_TAXES_VIEW ? `${MEDIA_SERVER}SwitchVertical.svg` : `${MEDIA_SERVER}ClipboardPercent.svg`}
                alt="tax-icon"
              />
            }
            title={
              <>
                {taxScreen === EDIT_TAXES_VIEW ? 'Change Tax' : T.NEW_TAX}
                {[TAX_FORM_VIEW, TAX_TABLE_VIEW].includes(taxScreen) && businessLines.length > 0 && `: ${businessLines.join(', ')}`}
              </>
            }
            TitleTypographyProps={{
              variant: 'h6',
            }}
            onClose={() => handleTaxesCancel()}
          />
          <Divider />
          <div className="content">
            <div className="tabpanel transparent-scroll">
              {taxScreen === 'initial' && <TaxInitialScreen businessLines={businessLines} onBusinessLineChange={onBusinessLineChange} />}

              {taxScreen === TAX_FORM_VIEW && (
                <TaxFormScreen
                  globalFilterOptions={globalFilterOptions}
                  handleCheckboxValue={handleCheckboxValue}
                  onHandleChange={onHandleChange}
                  inputTaxText={inputTaxText}
                  taxInput={taxInput}
                  handlePriceLocked={handlePriceLocked}
                  handleSwitchVal={handleSwitchVal}
                  onValueTypeChange={onValueTypeChange}
                  backFlag={backFlag}
                  businessLine={businessLines}
                  checkedValues={checkedTaxValues}
                  onClickLinkedServicesCard={() => {
                    setTaxScreen(LINKED_SERVICES_VIEW)
                    document.getElementsByClassName('tabpanel')[0].scrollTop = 0
                  }}
                  matchedServices={matchedServices}
                />
              )}

              {taxScreen === TAX_TABLE_VIEW && (
                <TaxTableScreen
                  getTaxServiceName={getTaxServiceName}
                  validatedTaxes={validatedTaxes}
                  duplicateTaxRecords={duplicateTaxRecords}
                  configData={configData}
                  anchorEl={anchorEl}
                  setState={setState}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  onHandleValidatedFieldsChange={onHandleValidatedFieldsChange}
                  onTableScopeChange={onTableScopeChange}
                />
              )}
              {taxScreen === EDIT_TAXES_VIEW && (
                <ChangePrice
                  selectedValues={selectedEditValues}
                  type="taxes"
                  handleChangePriceDelete={handleChangePriceDelete}
                  onHandleEditFieldsChange={onHandleEditFieldsChange}
                />
              )}
              {taxScreen === LINKED_SERVICES_VIEW && <LinkedServicesList matchedServices={matchedServices} />}
            </div>
          </div>

          <div className="taxes-footer">
            <input type="button" className="cancel" value={T.CANCEL} onClick={() => handleTaxesCancel()} />

            <div className="right-aligned">
              {taxScreen !== 'initial' && taxScreen !== EDIT_TAXES_VIEW && taxScreen !== LINKED_SERVICES_VIEW && (
                <div className="back" onClick={() => handleTaxesBackBtn()}>
                  <Reply className="back-btn" />
                  <div className="back-txt">{T.BACK}</div>
                </div>
              )}

              <input
                disabled={handleTaxDisabled}
                type="button"
                className="save"
                value={handleButtonText()}
                onClick={() => handleProceed()}
              />
            </div>
          </div>
        </section>
      </CommonDrawer>

      {isOpenTaxConfirmationModal && (
        <ConfirmationModal
          className="global-drawer-confirm"
          isOpen={isOpenTaxConfirmationModal}
          title={PRICING_CONFIRMATION_MSG}
          cancelButtonTitle={T.CANCEL}
          proceedButtonTitle={T.OK}
          onCancel={() => {
            setLocalState({ isOpenTaxConfirmationModal: false })
          }}
          onProceed={() => {
            setTaxScreen(TAX_FORM_VIEW)
            setLocalState({ isMultipleRecordsAltered: false })
            setLocalState({ isOpenTaxConfirmationModal: false })
          }}
        />
      )}
    </div>
  )
}

NewTaxModal.defaultProps = {
  selectedEditValues: [],
  taxScreen: '',
  openDrawer: false,
  isDirty: false,
  confServices: {},
  setOpenDrawer: null,
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  getTaxServicesResults: null,
  setTaxScreen: null,
  getTaxServiceName: null,
  handleChangePriceDelete: null,
  onHandleEditFieldsChange: null,
  setState: null,
}

NewTaxModal.propTypes = {
  selectedEditValues: PropTypes.array,
  taxScreen: PropTypes.string,
  confServices: PropTypes.object,
  isDirty: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  getTaxServicesResults: PropTypes.func,
  setTaxScreen: PropTypes.func,
  getTaxServiceName: PropTypes.func,
  handleChangePriceDelete: PropTypes.func,
  onHandleEditFieldsChange: PropTypes.func,
  setState: PropTypes.func,
}

export default NewTaxModal
