import { styled } from '@mui/material'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  borderLeft: 0,
  borderRight: 0,
  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders': {
    borderLeft: 0,
    borderRight: 0,
    padding: 0,
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-row.Mui-selected:hover,  & .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row:hover, ': {
    backgroundColor: 'background.paper',
  },
  '& .MuiDataGrid-cellCheckbox': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  '& .MuiDataGrid-columnHeaders': {
    marginX: '16px',
    [theme.breakpoints.up('md')]: {
      marginX: '32px',
    },
    '& .MuiDataGrid-columnHeaderCheckbox': {
      width: 'inherit !important',
      minWidth: 'inherit !important',
      '& .MuiButtonBase-root.MuiCheckbox-root': {
        padding: 0.5,
        margin: 0.5,
      },
    },
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    marginY: '24px',
    paddingX: '16px',
    [theme.breakpoints.up('md')]: {
      paddingX: '32px',
    },
  },
  '& .WOReview-DataGridHeader': {
    width: 'calc(100% - 128px)',
    borderBottom: `1px solid ${theme.palette.border.light}`,
    borderTop: `1px solid ${theme.palette.border.light}`,
  },
  '& .MuiDataGrid-menuIcon': {
    visibility: 'visible',
    width: 'unset',
  },
  '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: 'inherit',
  },
  [`& .${gridClasses.row}.workorder-row .MuiDataGrid-cell`]: {
    backgroundColor: theme.palette.background.header,
    border: 0,
    '&:hover': {
      backgroundColor: theme.palette.background.header,
    },
  },
  [`& .${gridClasses.row}.route-row .MuiDataGrid-cell`]: {
    borderBottom: `1px solid ${theme.palette.border.light}`,
    borderTop: `1px solid ${theme.palette.border.light}`,
  },
  '& .MuiButtonBase-root.MuiCheckbox-root': {
    padding: 0.5,
    margin: 0.5,
  },
  '& .workorder-row': {
    backgroundColor: theme.palette.background.header,
    '&:hover': {
      backgroundColor: theme.palette.background.header,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.header,
      '&:hover': {
        backgroundColor: theme.palette.background.header,
      },
    },
  },
  '& .route-row': {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}))

export default StyledDataGridPro
