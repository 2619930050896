import api from 'api'

import { CACHE_TAG_ACCOUNT_EXPANDABLE_VIEW } from 'api/cacheTagTypes'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getCustomerLocationList: build.query({
      query: body => ({
        url: '/api/location/view',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 30,
      providesTags: [CACHE_TAG_ACCOUNT_EXPANDABLE_VIEW],
    }),
  }),
})

export const { useGetCustomerLocationListQuery, useLazyGetCustomerLocationListQuery } = extendedApi
