export const actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',

  // Move to user actions later
  USER_INFO: 'USER_INFO',
  PASSWORD_INITIATE: 'PASSWORD_INITIATE',
  PASSWORD_UPDATE: 'PASSWORD_UPDATE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_RESET_INITIATE: 'PASSWORD_RESET_INITIATE',
  PASSWORD_RESET_RESEND_EMAIL: 'PASSWORD_RESET_RESEND_EMAIL',
}

export const login = (token, success = true) => ({
  type: actions.LOGIN,
  payload: { success, token },
})

export const logout = (initiateLogoutFromUtil = false) => ({
  type: actions.LOGOUT,
  payload: { initiateLogoutFromUtil },
})

export const getUserInfo = onSuccess => ({
  type: actions.USER_INFO,
  payload: { onSuccess },
})

export const getPasswordInitiate = (body, onSuccess) => ({
  type: actions.PASSWORD_INITIATE,
  payload: { body, onSuccess },
})

export const updatePassword = (body, onSuccess) => ({
  type: actions.PASSWORD_UPDATE,
  payload: { body, onSuccess },
})

export const forgotPassword = (body, onSuccess) => ({
  type: actions.FORGOT_PASSWORD,
  payload: { body, onSuccess },
})

export const resetPassword = (body, onSuccess) => ({
  type: actions.PASSWORD_RESET,
  payload: { body, onSuccess },
})

export const getInitiateResetPassword = (body, onSuccess) => ({
  type: actions.PASSWORD_RESET_INITIATE,
  payload: { body, onSuccess },
})

export const resendForgotPwdEmail = (body, onSuccess) => ({
  type: actions.PASSWORD_RESET_RESEND_EMAIL,
  payload: { body, onSuccess },
})
