import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { toast } from 'react-toastify'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { Box, DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { useLazyGetPortalUsersQuery } from 'api/customer-portal/getPortalUsers'
import { useLazyGetAccountContactsQuery } from 'api/accounts/getAccountContacts'
import {
  useInviteUserToCustomerPortalMutation,
  useBulkInviteUserToCustomerPortalByAccountIdAndEmailMutation,
  useBulkInviteUserToCustomerPortalByAccountIdMutation,
} from 'api/customer-portal/inviteUserToCustomerPortal'
import { CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS } from 'api/cacheTagTypes'
import { HHAlert } from 'components/common/HHAlert'
import { getAccountEmailContacts } from 'components/customer-details/content/customer-portal/add-portal-user/utils'
import { getAddModel, ADD_EDIT_CONTACT_PARENT_TYPES } from 'components/customer-details/content/contacts/settings'
import { setAddContactState } from 'slices/customer/contactsSlice'

import T from 'T'
import api from 'api'
import SaveButton from 'components/buttons/SaveButton'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import CancelButton from 'components/buttons/CancelButton'
import FormFields from 'components/customer-details/content/customer-portal/add-portal-user/FormFields'
import AddCustomerPortalUserSuccessDialog from 'components/customer-details/content/customer-portal/add-portal-user/AddCustomerPortalUserSuccessDialog'
import AddEditContactDialog from 'components/customer-details/content/contacts/AddEditContactDialog'
import AccountsDataGrid from './AccountsDataGrid'
import { FORM_MODEL } from './settings'

const { ACCOUNT_GROUP } = ADD_EDIT_CONTACT_PARENT_TYPES

const BulkSendPortalInviteDialog = ({ isOpen = false, existingAccounts = [], showInviteAllButton = true, onClose }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [getPortalUsers, { data: portalUserData }] = useLazyGetPortalUsersQuery()
  const [getAccountContacts, { data: accountContactData, isFetching: isGetAccountContactsLoading }] = useLazyGetAccountContactsQuery()
  const [inviteUserToCustomerPortal, { isLoading: isInviteUserToPortalLoading }] = useInviteUserToCustomerPortalMutation()
  const [bulkInviteUserToCustomerPortalByAccountIdAndEmail] = useBulkInviteUserToCustomerPortalByAccountIdAndEmailMutation()
  const [bulkInviteUserToCustomerPortalByAccountId, { isLoading: isBulkInviteLoading }] =
    useBulkInviteUserToCustomerPortalByAccountIdMutation()
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const addUserForm = useForm({ defaultValues: { ...FORM_MODEL } })
  const [isOpenAddPortalUserDialog, setIsOpenAddPortalUserDialog] = useState(false)
  const [isOpenConfirmInviteAllDialog, setIsOpenConfirmInviteAllDialog] = useState(false)
  const { setValue, watch, reset, handleSubmit } = addUserForm

  const selectedAccountId = get(rowSelectionModel, '[0]', '')
  const contacts = get(accountContactData, `contactsByAccount.${selectedAccountId}`, [])
  const portalUserList = get(portalUserData, 'portalUsers', [])
  const watchEmail = watch('email')
  const watchFirstName = watch('firstName')
  const watchLastName = watch('lastName')
  const emailContacts = getAccountEmailContacts(contacts, portalUserList)
  const hasAccounts = useMemo(() => existingAccounts.length > 0, [existingAccounts])

  const isOpenAddContactDialog = useSelector(state => get(state, 'Contacts.addContact.isOpen', false), shallowEqual)
  const contactAccountId = useSelector(state => get(state, 'Contacts.addContact.accountId'), shallowEqual)
  const initialMethodValue = useSelector(state => get(state, 'Contacts.addContact.initialMethodValue', ''), shallowEqual)
  const initialFirstName = useSelector(state => get(state, 'Contacts.addContact.initialFirstName', ''), shallowEqual)
  const initialLastName = useSelector(state => get(state, 'Contacts.addContact.initialLastName', ''), shallowEqual)
  const parentType = useSelector(state => get(state, 'Contacts.addContact.parent', ''), shallowEqual)
  const contactModel = initialMethodValue ? getAddModel(initialFirstName, initialLastName, initialMethodValue) : null

  const handleRowSelectionModelChange = selection => setRowSelectionModel(selection)

  const handleInvite = (accountId, allEmailContacts) => {
    if (allEmailContacts.length === 0) {
      toast.info(T.NO_INFO_ON_FILE)
      setValue('clickedAccountId', '')
      return
    }
    bulkInviteUserToCustomerPortalByAccountIdAndEmail({
      accountToPortalUserInvitations: [{ accountId, portalUserInvitationRequests: allEmailContacts }],
    })
      .unwrap()
      .then(() => toast.success(T.INVITE_SENT_SUCCESS_MESSAGE))
      .catch(handleError)
      .finally(() => setValue('clickedAccountId', ''))
  }

  const handleOpenConfirmInviteAllDialog = () => setIsOpenConfirmInviteAllDialog(true)

  const handleCloseConfirmInviteAllDialog = () => setIsOpenConfirmInviteAllDialog(false)

  const handleInviteAll = () => {
    bulkInviteUserToCustomerPortalByAccountId({ accountIds: existingAccounts.map(({ accountId }) => accountId) })
      .unwrap()
      .then(() => {
        onClose()
        handleCloseConfirmInviteAllDialog()
        toast.success(T.INVITE_SENT_SUCCESS_MESSAGE)
      })
      .catch(handleError)
  }

  const onSubmitHandler = async data => {
    inviteUserToCustomerPortal({
      accountId: selectedAccountId,
      payload: { email: data.email, firstName: get(data, 'firstName', ''), lastName: get(data, 'lastName', '') },
    })
      .unwrap()
      .then(() => {
        setIsOpenAddPortalUserDialog(true)
      })
      .catch(handleError)
  }

  const handleCloseSuccessDialog = () => {
    setIsOpenAddPortalUserDialog(false)
    reset()
  }

  const handleCloseCreateContactDialog = () =>
    dispatch(
      setAddContactState({ isOpen: false, accountId: '', initialFirstName: '', initialLastName: '', initialMethodValue: '', parent: '' })
    )

  useEffect(() => {
    if (isOpen && hasAccounts) {
      dispatch(api.util.invalidateTags([CACHE_TAG_CONTACT_LIST_BY_ACCOUNTS]))
      reset()
      handleRowSelectionModelChange([get(existingAccounts, '[0].accountId')])
    }
  }, [isOpen, hasAccounts])

  useEffect(() => {
    if (selectedAccountId) {
      reset()
      getPortalUsers({ accountId: selectedAccountId }, true)
      getAccountContacts({ accountIds: [selectedAccountId] }, true)
    }
  }, [selectedAccountId])

  return (
    <>
      <HHBaseDialog open={isOpen} maxWidth="md" fullWidth onClose={onClose}>
        <HHDialogTitle title={`${T.INVITE_CUSTOMER_PORTAL_USER}s`} onClose={onClose} />
        <DialogContent>
          <FormProvider {...addUserForm}>
            {!hasAccounts && (
              <HHAlert borderColor={theme.palette.info.light} severity="info">
                No accounts
              </HHAlert>
            )}
            {hasAccounts && (
              <Grid mt={2} container spacing={2}>
                <Grid item sm={12} md={6} sx={{ height: isTabletOrMobile ? 250 : 'calc(100vh - 285px)' }}>
                  <AccountsDataGrid
                    rows={existingAccounts}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={handleRowSelectionModelChange}
                    onInviteAll={handleInvite}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormFields isLoading={isGetAccountContactsLoading} emailContacts={emailContacts} onSubmit={onSubmitHandler} />
                </Grid>
              </Grid>
            )}
          </FormProvider>
        </DialogContent>

        <HHDialogActions>
          <CancelButton size="medium" onClick={onClose} />
          <Box flexGrow={1} />
          {showInviteAllButton && (
            <SaveButton disabled={!hasAccounts} label="Invite all accounts" variant="outlined" onClick={handleOpenConfirmInviteAllDialog} />
          )}
          <SaveButton
            loading={isInviteUserToPortalLoading}
            disabled={!selectedAccountId}
            label={T.INVITE}
            onClick={handleSubmit(onSubmitHandler)}
          />
        </HHDialogActions>
      </HHBaseDialog>
      <HHConfirmDialog
        isOpen={isOpenConfirmInviteAllDialog}
        confirmButtonTitle={T.CONFIRM}
        ConfirmButtonProps={{ color: 'primary', loading: isBulkInviteLoading }}
        confirmTitle="Confirm invite all customer portal accounts"
        confirmDescription="You are about to send customer portal invites to all accounts. Any account without an email on file will not receive an invite. Are you sure you want to proceed?"
        onConfirm={handleInviteAll}
        onClose={handleCloseConfirmInviteAllDialog}
      />
      <AddCustomerPortalUserSuccessDialog
        isOpen={isOpenAddPortalUserDialog}
        firstName={watchFirstName}
        lastName={watchLastName}
        email={watchEmail}
        accountId={selectedAccountId}
        isNewUser={!emailContacts.find(contact => contact.methodValue === watchEmail)}
        parent={ACCOUNT_GROUP}
        onClose={handleCloseSuccessDialog}
      />
      {parentType === ACCOUNT_GROUP && contactAccountId === selectedAccountId && isOpenAddContactDialog && (
        <AddEditContactDialog
          isOpen={isOpenAddContactDialog}
          accountId={selectedAccountId}
          contact={contactModel}
          onClose={handleCloseCreateContactDialog}
        />
      )}
    </>
  )
}

BulkSendPortalInviteDialog.propTypes = {
  isOpen: PropTypes.bool,
  existingAccounts: PropTypes.array,
  showInviteAllButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default BulkSendPortalInviteDialog
