import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Box, useTheme } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'

import { HHAlert } from 'components/common/HHAlert'
import RenderServiceCell from './RenderServiceCell'

const ServicesDataGrid = ({ isLoading, rows = [], selectionModel = [], onSelectionModelChange, ...rest }) => {
  const theme = useTheme()

  const getRowId = useCallback(({ id }) => id, [])

  const getRowHeight = useCallback(() => 'auto', [])

  return (
    <DataGridPro
      loading={isLoading}
      rows={rows}
      columnHeaderHeight={0}
      hideFooter
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={onSelectionModelChange}
      disableMultipleRowSelection
      columns={[
        {
          flex: 1,
          field: 'id',
          renderCell: RenderServiceCell,
          sortable: false,
          resizable: false,
        },
      ]}
      disableColumnSelector
      getRowId={getRowId}
      getRowHeight={getRowHeight}
      disableColumnMenu
      slots={{
        noRowsOverlay: () => (
          <Box sx={{ px: 1 }}>
            <HHAlert borderColor={theme.palette.info.light} severity="info">
              No active services
            </HHAlert>
          </Box>
        ),
      }}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
          border: 'none',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
          display: 'block',
        },
        '& .MuiDataGrid-columnHeader': {
          padding: '0',
        },
        '& .MuiDataGrid-overlayWrapperInner': {
          overflow: 'auto',
        },
      }}
      {...rest}
    />
  )
}

ServicesDataGrid.propTypes = {
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  selectionModel: PropTypes.array,
  onSelectionModelChange: PropTypes.string.isRequired,
}

export default ServicesDataGrid
