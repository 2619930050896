import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { shallowEqual, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Typography, Button, Box, MenuItem } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Add from '@mui/icons-material/Add'
import HHTextField from 'components/form-fields/v5/HHTextField'
import { getSingularIntervalName } from 'components/pricing/pricingMethods'

import T from 'T'

import { get } from 'utils/lodash'
import { hasAddPermissions } from 'utils/permissions'
import { getBillingCycleIntervalTypeDropdownOptions, getIntervalDropdownOptions } from 'utils/interval'

const BillingCycle = ({ generalState = {}, setGeneralState = noop, handleRemoval = noop, addMore = noop }) => {
  const { billingCycles } = generalState

  const { userInfo } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const onHandleChange = (e, index, type) => {
    if (billingCycles[index] === undefined) {
      billingCycles[index] = {}
    }

    const { value } = e.target

    const attrChanged = type === 'intervalType' ? 'intervalType' : 'interval'
    const otherAttr = attrChanged === 'intervalType' ? 'interval' : 'intervalType'

    const singularLabel = getSingularIntervalName(value)

    const checkRecord = billingCycles.filter(
      data => data[attrChanged] === singularLabel && data[otherAttr] === parseInt(billingCycles[index][otherAttr])
    )
    const interval = get(billingCycles[index], 'interval', 1)
    const intervalType = get(billingCycles[index], 'intervalType')
    if (checkRecord.length > 0) {
      toast.info('Duplicate Record')
    } else if ((singularLabel === 'Quarter' && interval > 2) || (type === 'interval' && value > 2 && intervalType === 'Quarter')) {
      toast.info('The maximum number of Quarters is 2')
    } else {
      billingCycles[index][type] = singularLabel
    }

    setGeneralState({ billingCycles })
  }

  return (
    <Box mt={4}>
      <Typography variant="subtitle1">{T.BILLING_CYCLE}</Typography>
      {billingCycles.map((billingCycle, index) => {
        let intervalType = get(billingCycle, 'intervalType', '')
        if (['Manual'].includes(intervalType)) return false

        const interval = get(billingCycle, 'interval', 1)
        const allIntervalTypes = getBillingCycleIntervalTypeDropdownOptions(interval, billingCycle?.id)
        const allIntervals = getIntervalDropdownOptions(intervalType, billingCycle?.id)

        if (interval > 1 && intervalType) {
          intervalType = `${intervalType}s`
        }

        return (
          <Box display="flex" sx={{ pointerEvents: billingCycle.id === '' ? '' : 'none', mt: 1 }}>
            <HHTextField select value={[interval]} onChange={e => onHandleChange(e, index, 'interval')} sx={{ minWidth: 64 }}>
              {allIntervals.map(data => (
                <MenuItem value={data}>{data}</MenuItem>
              ))}
            </HHTextField>

            <Box display="flex" alignItems="center" ml={1}>
              <HHTextField
                select
                value={[intervalType]}
                onChange={e => onHandleChange(e, index, 'intervalType')}
                sx={{ m: '0 5px', minWidth: 424 }}
              >
                <MenuItem value="">{`${T.SELECT} ${T.INTERVAL}`}</MenuItem>
                {allIntervalTypes.map(data => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
              </HHTextField>

              {billingCycle.id === '' && (
                <CancelOutlinedIcon
                  fontSize="small"
                  onClick={() => handleRemoval(index, 'billingCycles')}
                  sx={{ color: 'text.secondary', m: 'auto 24px', cursor: 'pointer' }}
                />
              )}
            </Box>
          </Box>
        )
      })}

      {hasAddPermissions(get(userInfo, 'profile.settingsCrud', 0)) && (
        <Button variant="text" color="primary" startIcon={<Add />} onClick={() => addMore('billingCycles')} sx={{ mt: 0.5, mb: 1 }}>
          {T.ADD}
        </Button>
      )}
    </Box>
  )
}

BillingCycle.propTypes = {
  generalState: PropTypes.object,
  setGeneralState: PropTypes.func,
  handleRemoval: PropTypes.func,
  addMore: PropTypes.func,
}

export default BillingCycle
