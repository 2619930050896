import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Select, InputBase, FormControl, MenuItem } from '@mui/material'

import { get } from 'utils/lodash'

import Constants from 'Constants'
import Box from '@mui/material/Box'

const { MEDIA_SERVER } = Constants

const CommonSelect = ({
  children,
  value,
  placeholder,
  placeholderHidden,
  placeholderDisabled,
  showEmpty,
  emptyText,
  showTick,
  options,
  disabled,
  className,
  onChange,
  onOpen,
  menuProps = {},
  ...rest
}) => {
  return (
    <FormControl className={`common select ${!value ? 'placeholder-selected' : ''} ${className}-container`}>
      <Select
        displayEmpty
        disabled={disabled}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        input={<InputBase />}
        className="select-class"
        MenuProps={{ className: ` ${className}-dropdown common-select-dropdown`, ...menuProps }}
        {...rest}
      >
        {placeholder && (
          <MenuItem value="" className={`${className}-menu-item ${placeholderHidden ? 'hide' : ''}`} disabled={placeholderDisabled}>
            {placeholder}
          </MenuItem>
        )}
        {children ||
          Children.toArray(
            options.map(option => (
              <MenuItem value={get(option, 'key', option)} className={`${className}-menu-item`}>
                <span className="text">{get(option, 'value', option)}</span>
                <Box display={showTick ? 'inline' : 'none'} className={`tick-icon ${showTick ? 'showtick' : 'hide-tick'}`}>
                  <img src={`${MEDIA_SERVER}Tick.svg`} alt="" />
                </Box>
              </MenuItem>
            ))
          )}

        {showEmpty && (
          <MenuItem value="-1" className={`${className}-menu-item`} disabled>
            {emptyText}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

CommonSelect.defaultProps = {
  value: null,
  placeholder: null,
  placeholderHidden: false,
  placeholderDisabled: false,
  showEmpty: false,
  emptyText: '',
  showTick: false,
  options: [],
  disabled: false,
  className: '',
  children: null,
}

CommonSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholderHidden: PropTypes.bool,
  placeholderDisabled: PropTypes.bool,
  showEmpty: PropTypes.bool,
  emptyText: PropTypes.string,
  showTick: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  menuProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default CommonSelect
