export const EMAIL_TYPE = {
  ACCOUNT_INVOICE_AVAILABLE: 'account_invoice_available',
  PORTAL_ACCOUNT_SIGN_UP: 'portal_account_sign_up',
  PORTAL_USER_SIGN_UP: 'portal_user_signup_invitation',
  ACCOUNT_PAYMENT_RECEIPT: 'account_payment_receipt',
}

export const EVENT_TYPE = {
  PROCESSED: 'processed',
  DEFERRED: 'deferred',
  DELIVERED: 'delivered',
  CLICK: 'click',
  OPEN: 'open',
  DROPPED: 'dropped',
  BOUNCE: 'bounce',
  SPAM: 'spamreport',
  BLOCKED: 'blocked',
}
