import React from 'react'

import Settings from 'containers/settings'
import NavbarWrapper from 'components/common/NavbarWrapper'

const SettingsPage = () => (
  <NavbarWrapper>
    <Settings />
  </NavbarWrapper>
)

export default SettingsPage
