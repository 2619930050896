import React from 'react'
import PropTypes from 'prop-types'

import { Table as TableView, TableContainer } from '@mui/material'
import LinkedServiceHeader from './LinkedServiceHeader'
import LinkedServiceBody from './LinkedServiceBody'

const LinkedServiceTable = ({ headerList, serviceList, tab, handleSwitchVal, multilineHeadline }) => {
  return (
    <TableContainer
      sx={{
        height: `calc(100vh - ${multilineHeadline ? '248px' : '336px'})`,
        margin: '22px 0',
        overflowY: 'scroll',
        overflowX: 'auto',
      }}
    >
      <TableView
        stickyHeader
        aria-label="sticky table"
        sx={{
          '& .MuiTableCell-body': {
            fontFamily: 'Inter',
            fontSize: 13,
          },
        }}
      >
        <LinkedServiceHeader values={headerList} handleSwitchVal={handleSwitchVal} tab={tab} serviceList={serviceList} />
        <LinkedServiceBody serviceList={serviceList} headerList={headerList} tab={tab} handleSwitchVal={handleSwitchVal} />
      </TableView>
    </TableContainer>
  )
}

LinkedServiceTable.propTypes = {
  multilineHeadline: PropTypes.bool.isRequired,
  serviceList: PropTypes.array.isRequired,
  headerList: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  handleSwitchVal: PropTypes.func.isRequired,
}

export default LinkedServiceTable
