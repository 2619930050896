import { INVOICE_CONFIGURATOR } from 'containers/billing/settings/invoices'

import T from 'T'

export const INVOICES_CONFIGURATOR = INVOICE_CONFIGURATOR.filter(
  data => !['pdf', 'portal', 'postcard', 'paper', 'adjustments', 'adjustmentsTotal'].includes(data.columnName)
).map(rec =>
  rec.tableLabel === T.CUSTOMER
    ? {
        position: rec.position,
        tableLabel: T.CUSTOMER_NAME,
        label: T.CUSTOMER_NAME,
        checked: rec.checked,
        locked: rec.locked,
        className: rec.className,
        filterType: rec.filterType,
        columnName: rec.columnName,
      }
    : rec
)
