import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import TableCell from '@mui/material/TableCell'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'

import { get } from 'utils/lodash'

const LastNextPriceUpdateColumn = ({ service, column, ind, lockedColumns }) => {
  let lastPriceUpdate = get(service, 'lastPriceUpdate', '')
  let lastPriceUpdateDay = get(service, 'lastPriceUpdateDay', '')
  let lastPriceUpdateValueType = get(service, 'lastPriceUpdateValueType', '')

  let nextPriceUpdate = get(service, 'nextPriceUpdate', '')
  let nextPriceUpdateDay = get(service, 'nextPriceUpdateDay', '')
  let nextPriceUpdateValueType = get(service, 'nextPriceUpdateValueType', '')
  const isLocked = get(column, 'locked', false)

  if (lastPriceUpdateDay !== '') lastPriceUpdateDay = moment(lastPriceUpdateDay).format('MM/DD/YYYY')

  if (nextPriceUpdateDay !== '') nextPriceUpdateDay = moment(nextPriceUpdateDay).format('MM/DD/YYYY')

  return (
    <>
      {['Last Value Update', 'Next Value Update'].includes(column.label) && (
        <>
          <TableCell className={`next-last-field ${handlePricingTableClass(ind, isLocked, lockedColumns)}`}>
            <div>{column.label === 'Last Value Update' ? lastPriceUpdateDay : nextPriceUpdateDay}</div>
          </TableCell>

          <TableCell
            className={`next-last-value ${column.label === 'Next Value Update' && 'last-value'} ${handlePricingTableClass(
              ind,
              isLocked,
              lockedColumns
            )}`}
          >
            <div>
              {column.label === 'Last Value Update' ? (
                <div className="price-container">
                  <div className="price">{lastPriceUpdate}</div>
                  <div className="sign">
                    {/* I think we are not using this */}
                    {lastPriceUpdateValueType === '$' && '$'}
                    {lastPriceUpdateValueType === '%' && '%'}
                  </div>
                </div>
              ) : (
                <div className="price-container">
                  <div className="price">{nextPriceUpdate}</div>
                  <div className="sign">
                    {nextPriceUpdateValueType === '$' && '$'}
                    {nextPriceUpdateValueType === '%' && '%'}
                  </div>
                </div>
              )}
            </div>
          </TableCell>
        </>
      )}
    </>
  )
}

LastNextPriceUpdateColumn.defaultProps = {
  ind: null,
  service: {},
  column: {},
  lockedColumns: [],
}

LastNextPriceUpdateColumn.propTypes = {
  ind: PropTypes.number,
  service: PropTypes.object,
  column: PropTypes.object,
  lockedColumns: PropTypes.array,
}

export default LastNextPriceUpdateColumn
