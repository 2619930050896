import React from 'react'
import PropTypes from 'prop-types'

import { Box, Tooltip, Typography } from '@mui/material'

const GroupChartOverlay = ({ overlayValue = '', tooltipTitle = '' }) => {
  return (
    overlayValue && (
      <Box
        position="absolute"
        top={16}
        right={16}
        bottom={48}
        left={16}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ pointerEvents: 'none' }}
      >
        <Tooltip title={tooltipTitle}>
          <Typography sx={{ pointerEvents: tooltipTitle ? 'auto' : 'none' }} variant="h5" color="textSecondary">
            {overlayValue}
          </Typography>
        </Tooltip>
      </Box>
    )
  )
}

GroupChartOverlay.propTypes = {
  overlayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipTitle: PropTypes.string,
}

export default GroupChartOverlay
