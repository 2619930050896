import React, { useState, useEffect, Children } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'

import { toast } from 'react-toastify'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { DialogContent, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { isCustomerDetailsPage } from 'router/routes'
import { getCustomerDetails } from 'middleware/actions/customers'
import { useAddUpdateLocationMutation } from 'api/location/addUpdateLocation'
import { HHFormTextField, HHFormSwitchField, HHFormPriceField } from 'components/form-fields/v5'
import { getAccountMeta } from 'data/meta/accountMetaSelectors'
import { CACHE_TAG_ACCOUNT_DETAILS_BY_ID } from 'api/cacheTagTypes'

import T from 'T'
import api from 'api'
import Tag from 'components/tags/Tag'
import CancelButton from 'components/buttons/CancelButton'
import HHBaseDialog from 'components/common/HHBaseDialog'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import DragPinMessage from 'components/common/address/DragPinMessage'
import LocationContent from '../LocationContent'
import MapView from '../MapView'
import { INITIAL_STATE } from './model'
import AddressForm from './AddressForm'

const AddEditLocationDialog = ({ isOpen = false, location, accountId, onClose }) => {
  const customerMeta = useSelector(getAccountMeta, shallowEqual)
  const dispatch = useDispatch()
  const [addUpdateLocation, { isLoading: isAddUpdateLocationLoading }] = useAddUpdateLocationMutation()
  const metaTags = get(customerMeta, 'tags', [])

  const initialState = location || INITIAL_STATE
  const { latitude: initialLatitude, longitude: initialLongitude } = initialState.address
  const [latLngForMap, setLatLngForMap] = useState({
    latitudeForMap: initialLatitude,
    longitudeForMap: initialLongitude,
  })
  const [latLngForMapHistory, setLatLngForMapHistory] = useState({
    latitudeForMapHistory: initialLatitude,
    longitudeForMapHistory: initialLongitude,
  })
  const { latitudeForMap, longitudeForMap } = latLngForMap
  const { latitudeForMapHistory, longitudeForMapHistory } = latLngForMapHistory

  const locationForm = useForm({ defaultValues: INITIAL_STATE })
  const { handleSubmit, control, watch, setValue, resetField, reset, setFocus } = locationForm
  const watchAddress = watch('address')

  const onSubmitHandler = data => {
    const locationPayload = {
      accountId,
      location: {
        ...omit(data, ['maxPoAmount']),
        // New API accept maxPoAmount but old API accept maxPOAmount
        maxPOAmount: get(data, 'maxPoAmount'),
      },
    }

    addUpdateLocation(locationPayload)
      .unwrap()
      .then(() => {
        onClose()
        if (isCustomerDetailsPage()) {
          dispatch(getCustomerDetails({ accountId }))
        }
        dispatch(api.util.invalidateTags([{ type: CACHE_TAG_ACCOUNT_DETAILS_BY_ID, id: accountId }]))
        toast.success(location ? 'Location updated successfully' : 'Location added successfully')
      })
      .catch(handleError)
  }

  const onSubmitErrorHandler = () => {
    toast.error(T.REQUIRED_FIELD_MSG)
  }

  const handleLatLongFromMapView = (long, lat) => {
    setValue('address.longitude', long)
    setValue('address.latitude', lat)
    setLatLngForMap({ longitudeForMap: long, latitudeForMap: lat })
  }

  useEffect(() => {
    if (isOpen) {
      if (location) {
        reset(location, { keepDefaultValues: true })
      }
      setLatLngForMapHistory({ longitudeForMapHistory: initialLongitude, latitudeForMapHistory: initialLatitude })
      handleLatLongFromMapView(initialLongitude, initialLatitude)
    }
  }, [isOpen, location])

  return (
    <>
      <HHBaseDialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth disableScrollLock>
        <HHDialogTitle title={location ? 'Edit location' : 'Add location'} onClose={onClose} />
        <DialogContent>
          <Grid container mt={2} columnGap={3}>
            <Grid container item xs={12} md spacing={2}>
              <Grid item xs={12} md={6} minHeight={280}>
                <MapView
                  longitude={longitudeForMap}
                  latitude={latitudeForMap}
                  longitudeForMapHistory={longitudeForMapHistory}
                  latitudeForMapHistory={latitudeForMapHistory}
                  onChange={({ mapLng, mapLat }) => handleLatLongFromMapView(mapLng, mapLat)}
                  onReset={() => handleLatLongFromMapView(longitudeForMapHistory, latitudeForMapHistory)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <LocationContent showAddressName address={watchAddress} showPlaceholder />
                <Grid mt={1.5} container spacing={0.5} alignItems="center">
                  {Children.toArray(
                    watch('tags').map(tag => (
                      <Grid item>
                        <Tag clickable={false} tag={tag} />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
              <DragPinMessage latitude={latitudeForMap} longitude={longitudeForMap} />
              <Grid item xs={12}>
                <HHFormSwitchField label="Signature required" control={control} name="signatureRequired" />
              </Grid>
              <Grid item xs={12}>
                <HHFormSwitchField
                  label="Location purchase order"
                  control={control}
                  name="locationPurchaseOrder"
                  onChange={checked => {
                    if (!checked) {
                      resetField('po')
                      resetField('maxPoAmount')
                      return
                    }

                    setTimeout(() => setFocus('po'), 100)
                  }}
                />
              </Grid>
              {watch('locationPurchaseOrder') && (
                <>
                  <Grid item xs={6}>
                    <HHFormTextField
                      control={control}
                      name="po"
                      label="PO number"
                      placeholder="PO number"
                      fullWidth
                      deprecatedLabel={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <HHFormPriceField
                      dollarPrefix
                      control={control}
                      name="maxPoAmount"
                      label="Max PO amount"
                      placeholder="Max PO amount"
                      fullWidth
                      deprecatedLabel={false}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <FormProvider {...locationForm}>
              <AddressForm metaTags={metaTags} setLatLngForMap={setLatLngForMap} setLatLngForMapHistory={setLatLngForMapHistory} />
            </FormProvider>
          </Grid>
        </DialogContent>
        <HHDialogActions>
          <CancelButton onClick={onClose} />
          <LoadingButton
            loading={isAddUpdateLocationLoading}
            size="small"
            variant="contained"
            onClick={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}
          >
            {T.SAVE}
          </LoadingButton>
        </HHDialogActions>
      </HHBaseDialog>
    </>
  )
}

AddEditLocationDialog.propTypes = {
  isOpen: PropTypes.bool,
  location: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddEditLocationDialog
