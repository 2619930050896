import api from 'api'
import { CACHE_TAG_ROUTES } from 'api/cacheTagTypes'
import { get } from 'utils/lodash'
import { getTagIds } from 'utils/tags'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getRouteStops: build.query({
      query: ({ routes, serviceDate }) => ({
        url: '/api/v1/core/route/stops/get',
        method: 'POST',
        body: { routes, serviceDate },
      }),
      transformResponse: data => {
        const newRoutes = get(data, 'routes', []).map(route => ({
          ...route,
          stops: get(route, 'stops', []).map(stop => ({
            ...stop,
            tagIds: getTagIds(get(stop, 'tags', {})),
          })),
        }))
        return { ...data, routes: newRoutes }
      },
      providesTags: (result, error, body) => (body?.skipCache ? [] : [CACHE_TAG_ROUTES]),
    }),
    getRouteStopsGeojson: build.query({
      query: body => ({
        url: '/api/v1/core/route/stops/geojson',
        method: 'POST',
        body,
      }),
      providesTags: [CACHE_TAG_ROUTES],
    }),
  }),
})

export const { useLazyGetRouteStopsQuery, useLazyGetRouteStopsGeojsonQuery } = extendedApi
