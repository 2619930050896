import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { PAGINATION } from 'settings/constants/pagination'
import { PRICING_TABS_ID } from 'settings/constants/pricing'
import { SERVICE_GROUP } from 'settings/constants/serviceGroups'
import { getPricingServiceDetail } from 'middleware/actions/configuredServices'

import T from 'T'
import SecondaryNavBar from 'components/pricing/SecondaryNavBar'
import ServiceGroupDrawer from 'components/drawer/service-group'
import ServiceGroupsTable from 'components/pricing/service-groups'
import PricingWrapper from 'components/pricing/common/drawer/PricingWrapper'
import { selectResetSelectServiceGroup, setResetSelectServiceGroup } from 'slices/pricing/pricingDrawerSlice'

const { SERVICE_GROUPS_TAB_ID } = PRICING_TABS_ID
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

const ServiceGroups = ({
  allTableRows = [],
  page = INITIAL_PAGE,
  rowsPerPage = ROWS_PER_PAGE,
  totalTableRowsCount = 0,
  totalPageCount = 0,
  onPageChange,
  onRowsPerPageChange,
  onReloadMainTable,
}) => {
  const resetSelectServiceGroup = useSelector(selectResetSelectServiceGroup)
  const dispatch = useDispatch()
  const [serviceState, setServiceState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenServiceGroupDrawer: false,
    isDetailsPage: false,
    detailTableRows: [],
    currentSelectedGroup: {},
  })

  const { isOpenServiceGroupDrawer, isDetailsPage, detailTableRows, currentSelectedGroup } = serviceState
  const currentSelectedGroupName = get(currentSelectedGroup, 'name')

  const handleBackToGroupClick = () => {
    // Later create paginationCache just like customers/billing and don't call API, just update servicesCount
    onReloadMainTable()
    setServiceState({ currentSelectedGroup: '', detailTableRows: [], isDetailsPage: false })
  }

  const getServiceGroupDetails = group =>
    // Call service group details API
    dispatch(
      getPricingServiceDetail({ type: SERVICE_GROUP, serviceGroupId: get(group, 'id') }, (status, serviceDetails) => {
        setServiceState({ currentSelectedGroup: group, detailTableRows: serviceDetails, isDetailsPage: true })
      })
    )

  useEffect(() => {
    dispatch(setResetSelectServiceGroup(false))
  }, [])

  useEffect(() => {
    if (resetSelectServiceGroup) {
      handleBackToGroupClick()
      dispatch(setResetSelectServiceGroup(false))
    }
    return () => {
      dispatch(setResetSelectServiceGroup(false))
    }
  }, [resetSelectServiceGroup])

  return (
    <>
      {/* Inner content */}
      <PricingWrapper
        sx={{
          '& .services-secondary-nav': {
            '& .services-btn': {
              display: 'none',
            },
          },
        }}
        className="panel-description"
      >
        <SecondaryNavBar
          handleBackToGroupClick={handleBackToGroupClick}
          currentSelectedGroupName={currentSelectedGroupName}
          newButtonName={isDetailsPage ? `Add ${T.SERVICE}` : `New ${T.SERVICE_GROUP}`}
          toggleDrawer={open => () => setServiceState({ isOpenServiceGroupDrawer: open })}
          tab={SERVICE_GROUPS_TAB_ID}
          tabValue={SERVICE_GROUPS_TAB_ID}
        />

        {/* Main & detail table */}
        <Box className="panel-table" width="100%" height="100%" overflow="auto">
          <ServiceGroupsTable
            isDetailsView={isDetailsPage}
            serviceGroupsList={isDetailsPage ? detailTableRows : allTableRows}
            page={page}
            rowsPerPage={rowsPerPage}
            totalTableRowsCount={totalTableRowsCount}
            totalPageCount={totalPageCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            currentSelectedGroup={currentSelectedGroup}
            onRowClick={group => getServiceGroupDetails(group)}
            onReloadDetailsTable={() => getServiceGroupDetails(currentSelectedGroup)}
          />
        </Box>

        {/* New service group/Add service slideout */}
        {isOpenServiceGroupDrawer && (
          <ServiceGroupDrawer
            isOpenDrawer={isOpenServiceGroupDrawer}
            // edit mode
            currentSelectedGroup={currentSelectedGroup}
            initialSelectedServices={get(currentSelectedGroup, 'id') ? detailTableRows.map(row => row.id) : []}
            initialSelectedActions={currentSelectedGroup ? detailTableRows.map(row => get(row, 'action.actionType')) : []}
            onClose={isOpen => {
              setServiceState({ isOpenServiceGroupDrawer: isOpen })
            }}
            onReloadMainTable={handleBackToGroupClick}
            onReloadDetailsTable={() => getServiceGroupDetails(currentSelectedGroup)}
          />
        )}
      </PricingWrapper>
    </>
  )
}

ServiceGroups.propTypes = {
  allTableRows: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onReloadMainTable: PropTypes.func.isRequired,
}

export default memo(ServiceGroups)
