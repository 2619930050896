import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, Grid, Typography, IconButton, Menu, MenuItem, Stack, Box } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { get, keyBy, noop } from 'lodash'
import LoadingButton from '@mui/lab/LoadingButton'
import { shallowEqual, useSelector } from 'react-redux'
import { MoreHoriz } from '@mui/icons-material'
import { toast } from 'react-toastify'
import CancelButton from '../../buttons/CancelButton'
import StopsMapPreview from './StopsMapPreview'
import { getMetadataYards } from '../../../data/route/routeMetadataSelector'
import { combineAddressInfo } from '../../../utils/helper'
import { getCoordinates } from '../../../utils/map'
import { useAddRouteMutation, useDeleteRouteByIdMutation, useLazyGetRouteByIdQuery } from '../../../api/route/routeListCrud'
import { CustomDialogTitle } from './CustomDialogTitle'
import RouteDetailsViewModeContent from './RouteDetailsViewModeContent'
import RouteDetailsFormContent from './RouteDetailsFormContent'
import HHConfirmDeleteButton from '../../common/HHConfirmDeleteButton'
import { HHFormTextField } from '../../form-fields/v5'
import FormColorPickerField from '../../settings/pages/tags/FormColorPickerField'

const getDefaultRouteValues = route => {
  const defaultId = get(route, 'id')
  const defaultColor = get(route, 'color', '#000000')
  const defaultStartingYardId = get(route, 'startingYard.id')
  const defaultEndingYardId = get(route, 'endingYard.id')
  const defaultBusinessLineId = get(route, 'businessLine.id')
  const defaultMethodId = get(route, 'method.id')
  const defaultRouteName = get(route, 'name')
  return {
    color: defaultColor,
    id: defaultId,
    name: defaultRouteName,
    startingYardId: defaultStartingYardId,
    endingYardId: defaultEndingYardId,
    businessLineId: defaultBusinessLineId,
    methodId: defaultMethodId,
  }
}

const getCoordinatesFromSelectedYardId = async ({ newYardId, idToYardMap, mapboxToken }) => {
  if (!(newYardId in idToYardMap)) return null
  const selectedYard = idToYardMap[newYardId]
  const latitude = get(selectedYard, 'address.latitude', null)
  const longitude = get(selectedYard, 'address.longitude', null)
  if (latitude && longitude) return [longitude, latitude]
  const addressObj = get(selectedYard, 'address', null)
  const addressStr = combineAddressInfo(addressObj)
  const coordinates = await getCoordinates(addressStr, mapboxToken)
  return coordinates
}

const getDefaultRouteDetailsValues = () => ({
  id: null,
  name: '',
  color: '#000000',
  startingYardId: '',
  endingYardId: '',
  businessLineId: '',
  methodId: '',
})

const RouteDetailsModal = ({ open, onClose = noop, route, defaultMode, onSave = noop, onDelete = noop, serviceDate }) => {
  const [startingYardCoords, setStartingYardCoords] = useState(null)
  const [endingYardCoords, setEndingYardsCoords] = useState(null)
  const [mode, setMode] = useState(defaultMode)
  const stops = get(route, 'stops', [])
  const allYards = useSelector(getMetadataYards)
  const idToYardMap = keyBy(allYards, 'id')
  const [addRoute, { isLoading }] = useAddRouteMutation()
  const [getRoute, { data: routeByIdData }] = useLazyGetRouteByIdQuery()
  const [deleteRoute, { isDeleteLoading }] = useDeleteRouteByIdMutation()
  const userInfo = useSelector(s => s.AuthReducer.userInfo, shallowEqual)
  const mapboxToken = get(userInfo, 'mapBoxToken')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const routeData = get(routeByIdData, 'route', undefined)
  const isMenuOpen = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const methods = useForm({
    defaultValues: getDefaultRouteDetailsValues(),
  })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods

  const nameError = get(errors, 'name', false)

  const handleStartingYardChange = async newYardId => {
    const coords = await getCoordinatesFromSelectedYardId({ newYardId, mapboxToken, idToYardMap })
    setStartingYardCoords(coords)
  }

  const handleSubmitError = () => {
    const existingToast = toast.isActive('route-details-form-error')
    if (!existingToast) {
      toast.error('Please fill out all fields before submitting the form.', { toastId: 'route-details-form-error' })
    }
  }

  const handleEndingYardChange = async newYardId => {
    const coords = await getCoordinatesFromSelectedYardId({ newYardId, mapboxToken, idToYardMap })
    setEndingYardsCoords(coords)
  }

  const handleChangeToEditionMode = () => {
    setMode('update')
    handleMenuClose()
  }

  const handleReset = () => {
    reset(getDefaultRouteValues())
  }

  const handleClose = () => {
    onClose()
    handleReset()
  }

  const handleDeleteConfirmed = () => {
    const id = get(route, 'id')
    deleteRoute({
      id,
    })
      .unwrap()
      .then(() => {
        toast.success('Route deleted successfully')
        setMode('read')
        handleReset()
        onDelete()
      })
  }

  const handleSave = formData => {
    const id = get(formData, 'id', undefined)
    const color = get(formData, 'color', null)
    const endingYard = get(formData, 'endingYardId', null)
    const startingYard = get(formData, 'startingYardId', null)
    const businessLine = get(formData, 'businessLineId', null)
    const method = get(formData, 'methodId', null)
    const name = get(formData, 'name', null)

    const routeInfo = {
      name,
      color,
      startingYard,
      endingYard,
      businessLine,
      method,
      ...(id && { id }),
    }
    addRoute({
      routeInfo,
    })
      .unwrap()
      .then(() => {
        toast.success(`Route successfully ${mode === 'create' ? 'created' : 'updated'}`)
        onSave()
        handleReset()
        setMode('read')
        // forcing reload to get new route
        if (mode === 'create') {
          window.location.reload()
        }
      })
  }

  useEffect(() => {
    if (route && open) {
      const routeId = get(route, 'id')
      getRoute({ routeId })
        .unwrap()
        .then(({ route: routeDetails }) => {
          const { id, name, startingYardId, endingYardId, color, methodId, businessLineId } = getDefaultRouteValues(routeDetails)
          reset({
            id,
            color,
            name,
            startingYardId,
            endingYardId,
            businessLineId,
            methodId,
          })
          handleStartingYardChange(startingYardId)
          handleEndingYardChange(endingYardId)
        })
    }
  }, [route, open, mode])

  useEffect(() => {
    if (open) {
      setMode(defaultMode)
    }
  }, [open, defaultMode])

  return (
    <Dialog
      sx={{
        '& .MuiDialogActions-root': {
          px: theme => theme.spacing(3),
          pb: theme => theme.spacing(2),
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <CustomDialogTitle
        action={
          <>
            {mode === 'read' && (
              <>
                <IconButton
                  onClick={handleClick}
                  sx={{ position: 'absolute', right: 24, top: 8, color: theme => theme.palette.textSecondary }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem sx={{ minWidth: '7rem' }} onClick={handleChangeToEditionMode}>
                    Edit
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        }
      >
        <Typography variant="h4" color="textSecondary" fontWeight={600}>
          {mode === 'create' && 'New route'}
          {mode === 'read' && 'Route details'}
          {mode === 'update' && 'Edit route details'}
        </Typography>
      </CustomDialogTitle>
      <DialogContent sx={{ pb: mode === 'read' ? 4 : 0, px: 4 }}>
        <Grid container rowSpacing={2}>
          {mode !== 'read' && (
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <HHFormTextField
                  sx={{ mt: 1 }}
                  deprecatedLabel={false}
                  fullWidth
                  label={nameError ? 'Add a route name' : 'Route name'}
                  control={control}
                  name="name"
                  required
                  rules={{ required: true }}
                  error={nameError}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Stack justifyContent="flex-end" sx={{ ml: 2, height: '100%', width: '100%' }}>
                  <FormColorPickerField sx={{ height: '40px', width: '40px' }} name="color" control={control} />
                </Stack>
              </Grid>
            </Grid>
          )}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <FormProvider {...methods}>
              {mode !== 'read' ? (
                <RouteDetailsFormContent onStartingYardChange={handleStartingYardChange} onEndingYardChange={handleEndingYardChange} />
              ) : (
                <RouteDetailsViewModeContent route={routeData} />
              )}
            </FormProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <StopsMapPreview stops={stops} startingYardCoords={startingYardCoords} endingYardCoords={endingYardCoords} />
          </Grid>
        </Grid>
      </DialogContent>
      {mode !== 'read' && (
        <DialogActions>
          <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              {mode === 'update' && (
                <HHConfirmDeleteButton
                  confirmTitle="Confirm route deletion"
                  confirmDescription={
                    <Typography component="p">
                      Deleting route
                      <Typography component="span" fontWeight="bold">
                        {` ${get(route, 'name')} `}
                      </Typography>
                      will automatically unassigned all stops from today. Are you sure you want to continue?
                    </Typography>
                  }
                  CancelButtonProps={{ size: 'small' }}
                  loading={isDeleteLoading}
                  onDeleteConfirmed={handleDeleteConfirmed}
                />
              )}
            </Box>
            {mode !== 'read' && (
              <Box>
                <CancelButton onClick={handleClose} />
                <LoadingButton
                  sx={{ ml: 1 }}
                  size="small"
                  variant="contained"
                  loading={isLoading}
                  onClick={handleSubmit(handleSave, handleSubmitError)}
                >
                  Save
                </LoadingButton>
              </Box>
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  )
}

RouteDetailsModal.propTypes = {
  route: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultMode: PropTypes.oneOf(['create', 'read', 'update']).isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  serviceDate: PropTypes.string.isRequired,
}

export default RouteDetailsModal
