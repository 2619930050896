import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import ReorderIcon from '@mui/icons-material/Reorder'

import { get } from 'utils/lodash'
import { setShowCustomerSideNav } from 'slices/customer/CustomerSideNavSlice'

import T from 'T'

const SuspensionGroupsHeader = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const showCustomerSideNav = useSelector(state => get(state, 'CustomerSideNav.showCustomerSideNav'))

  const handleDrawerToggle = () => dispatch(setShowCustomerSideNav(!showCustomerSideNav))
  return (
    <Box px={3} py={2} bgcolor="background.default" minHeight={56} borderBottom={1} borderColor="divider">
      <Grid container alignItems="center" justifyContent="center" columnSpacing={1} bgcolor={theme.background.default}>
        {!showCustomerSideNav && (
          <Grid container alignItems="center" justifyContent="center" item xs="auto">
            <ReorderIcon onClick={handleDrawerToggle} />
          </Grid>
        )}
        <Grid item xs>
          <Typography variant="h4" fontWeight={600}>
            {T.SUSPENSION_GROUPS}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SuspensionGroupsHeader
