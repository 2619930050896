import { get } from 'utils/lodash'

export const getActiveServices = (locations = []) => {
  const activeLocations = locations.filter(location => !location.deactivated)
  const services = activeLocations
    .map(location => {
      const activeServices = get(location, 'services', []).filter(service => service.status === 'Active')
      return activeServices.map(service => ({
        ...service,
        locationId: location.locationId,
        locationName: location.locationName,
        locationAddress: location.addressStr,
      }))
    })
    .flat()

  return services
}
