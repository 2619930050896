import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { ListItemText, Skeleton } from '@mui/material'

const SkeletonListItem = ({ rows = 1 }) =>
  Children.toArray(
    new Array(rows)
      .fill('')
      .map(() => (
        <ListItemText
          sx={{ px: 3 }}
          primary={<Skeleton variant="text" width="20rem" height="2rem" />}
          secondary={<Skeleton variant="text" width="10rem" height="2rem" />}
        />
      ))
  )

SkeletonListItem.propTypes = {
  rows: PropTypes.number,
}

export default SkeletonListItem
