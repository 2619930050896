import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import T from 'T'
import SummaryCard from 'components/billing/groups/common/SummaryCard'

const Summary = ({ accountCount = 0, serviceCount = 0, loading = false }) => {
  return (
    <Grid container columnSpacing={2.5} spacing={2.5}>
      <Grid item xs={12} sm={6} lg={6}>
        <SummaryCard label={T.ACCOUNTS} value={loading ? <Skeleton sx={{ m: '0 auto' }} width="5rem" /> : accountCount} />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <SummaryCard label={T.SERVICES} value={loading ? <Skeleton sx={{ m: '0 auto' }} width="5rem" /> : serviceCount} />
      </Grid>
    </Grid>
  )
}

Summary.propTypes = {
  accountCount: PropTypes.number,
  serviceCount: PropTypes.number,
  loading: PropTypes.bool,
}

export default Summary
