import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { Menu, MenuItem, Tooltip } from '@mui/material'

import T from 'T'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'utils/lodash'
import ReactivateLocationsDialog from 'components/customer-details/content/account-details/header/ReactivateLocationsDialog/ReactivateLocationsDialog'
import {
  selectIsActiveDeactivateAccountDialogOpen,
  selectIsChangeAccountRepresentativeDialogOpen,
  selectIsReactivateLocationsDialogOpen,
  setIsActiveDeactivateAccountDialogOpen,
  setIsChangeAccountRepresentativeDialogOpen,
  setIsReactivateLocationsDialogOpen,
} from 'slices/customer/customerDetailsSlice'
import HHConfirmDialog from 'components/common/HHConfirmDialog'
import EditAccountRepDialog from 'components/customer-details/content/account-rep/EditAccountRepDialog'
import api from 'api'
import { CACHE_TAG_CONFIGURED_SERVICES_LIST, CACHE_TAG_WORK_ORDER_LIST } from 'api/cacheTagTypes'
import { toast } from 'react-toastify'
import { handleError } from 'utils/error'
import { useActivateAccountMutation } from 'api/accounts/activateAccount'
import { useDeactivateAccountMutation } from 'api/accounts/deactivateAccount'
import { getActiveDeactivatedLocations } from 'utils/location'
import SuspendAccountDialog from 'components/customer-details/content/suspensions/SuspendAccountDialog/SuspendAccountDialog'
import UnsuspendAccountDialog from 'components/customer-details/content/suspensions/UnsuspendAccountDialog/UnsuspendAccountDialog'
import { getEditAccountModel } from 'utils/customerPayload'
import EditAccountDialog from 'components/customer-details/content/account-details/edit'
import { getCustomerDetails } from 'middleware/actions/customers'
import AddToGroupDialog from 'components/customers/groups/account/AddToGroupDialog'
import SuspensionHistoryDialog from 'components/customers/groups/suspension/suspension-history/SuspensionHistoryDialog'
import SettingsDialog from 'components/customer-details/content/settings/SettingsDialog'

const AccountHeaderActionMenu = ({ anchorEl, accountId, onClose }) => {
  const dispatch = useDispatch()
  const [isOpenEditAccountDialog, setIsOpenEditAccountDialog] = useState(false)
  const [isOpenAddToGroupDialog, setIsOpenAddToGroupDialog] = useState(false)
  const [isOpenSuspendDialog, setIsOpenSuspendDialog] = useState(false)
  const [isOpenUnsuspendDialog, setIsOpenUnsuspendDialog] = useState(false)
  const [isOpenSuspensionHistoryDialog, setIsOpenSuspensionHistoryDialog] = useState(false)
  const [isOpenSettingsDialog, setIsOpenSettingsDialog] = useState(false)
  const [isOpenConfirmAccountReactivatedDialog, setIsOpenConfirmAccountReactivatedDialog] = useState(false)

  const handleEditAccount = () => {
    onClose()
    setIsOpenEditAccountDialog(true)
  }

  const onCloseEditAccountDialog = () => {
    setIsOpenEditAccountDialog(false)
  }

  const [activateAccount] = useActivateAccountMutation()
  const [deactivateAccount, { isLoading: isDeactivateAccountLoading }] = useDeactivateAccountMutation()
  const isOpenReactivateLocationsDialog = useSelector(selectIsReactivateLocationsDialogOpen)
  const isOpenChangeAccountRepresentativeDialog = useSelector(selectIsChangeAccountRepresentativeDialogOpen)
  const isOpenActiveDeactivateAccountDialog = useSelector(selectIsActiveDeactivateAccountDialogOpen)
  const customerDetails = useSelector(state => get(state, 'CustomersReducer.customerDetails', null))
  const isActiveAccount = get(customerDetails, 'active')
  const isAccountSuspended = get(customerDetails, 'isSuspended')
  const { activeLocations } = getActiveDeactivatedLocations(customerDetails, true)
  const accountEditModel = useMemo(() => getEditAccountModel(customerDetails), [customerDetails])

  const openConfirmAccountReactivatedDialog = () => {
    setIsOpenConfirmAccountReactivatedDialog(true)
  }

  const closeConfirmAccountReactivatedDialog = () => {
    setIsOpenConfirmAccountReactivatedDialog(false)
  }

  const handleReactivateLocationsAction = () => {
    onClose()
    dispatch(setIsReactivateLocationsDialogOpen(true))
  }

  const handleCloseReactivateLocationsDialog = () => {
    dispatch(setIsReactivateLocationsDialogOpen(false))
  }

  const handleChangeAccountRepAction = () => {
    onClose()
    dispatch(setIsChangeAccountRepresentativeDialogOpen(true))
  }

  const handleCloseChangeAccountRepresentativeDialog = () => {
    dispatch(setIsChangeAccountRepresentativeDialogOpen(false))
  }

  const handleActivateDeactivateSuccess = () => {
    onClose()
    dispatch(getCustomerDetails({ accountId }))
    // Trigger configured service & work order table updates
    dispatch(api.util.invalidateTags([CACHE_TAG_CONFIGURED_SERVICES_LIST, CACHE_TAG_WORK_ORDER_LIST]))
  }

  const handleAccountDeactivation = () =>
    deactivateAccount({ accountId, locations: activeLocations.map(location => location.id) })
      .unwrap()
      .then(() => {
        dispatch(setIsActiveDeactivateAccountDialogOpen(false))
        handleActivateDeactivateSuccess()
        toast.success(T.ACCOUNT_DEACTIVATED_SUCCESSFULLY)
      })
      .catch(handleError)

  const handleAccountActivateDeactivateAction = () => {
    onClose()
    if (isActiveAccount) {
      // Deactivate account
      dispatch(setIsActiveDeactivateAccountDialogOpen(true))
      return
    }
    // Activate account
    activateAccount({ accountId })
      .unwrap()
      .then(() => {
        handleActivateDeactivateSuccess()
        openConfirmAccountReactivatedDialog()
      })
      .catch(handleError)
  }

  const handleCloseAccountActivateDeactivateDialog = () => {
    dispatch(setIsActiveDeactivateAccountDialogOpen(false))
  }

  const handleSuspendAction = () => {
    if (isAccountSuspended) {
      onClose()
      setIsOpenUnsuspendDialog(true)
    } else {
      onClose()
      setIsOpenSuspendDialog(true)
    }
  }

  const handleCloseSuspendDialog = () => {
    setIsOpenSuspendDialog(false)
  }

  const handleCloseUnsuspendDialog = () => {
    setIsOpenUnsuspendDialog(false)
  }

  const handleOpenAddToGroupDialog = () => {
    onClose()
    setIsOpenAddToGroupDialog(true)
  }

  const handleCloseAddToGroupDialog = () => setIsOpenAddToGroupDialog(false)

  const handleOpenSuspensionHistoryDialog = () => {
    onClose()
    setIsOpenSuspensionHistoryDialog(true)
  }

  const handleCloseSuspensionHistoryDialog = () => setIsOpenSuspensionHistoryDialog(false)

  const handleOpenSettingsDialog = () => {
    onClose()
    setIsOpenSettingsDialog(true)
  }
  const handleCloseSettingsDialog = () => setIsOpenSettingsDialog(false)
  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ variant: 'outlined' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        disableScrollLock
      >
        <MenuItem onClick={handleEditAccount}>{T.EDIT}</MenuItem>

        <Tooltip arrow title={!isActiveAccount && T.REACTIVATE_ACCOUNT}>
          <span>
            <MenuItem disabled={!isActiveAccount} onClick={handleReactivateLocationsAction}>
              {T.REACTIVATE_MULTIPLE_LOCATION}
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={handleOpenAddToGroupDialog}>{T.ADD_TO_ACCOUNT_GROUP}</MenuItem>
        <MenuItem onClick={handleChangeAccountRepAction}>{T.CHANGE_ACCOUNT_REP}</MenuItem>
        <MenuItem onClick={handleOpenSettingsDialog}>{T.SETTINGS}</MenuItem>
        <MenuItem onClick={handleOpenSuspensionHistoryDialog}>{T.SUSPENSION_HISTORY}</MenuItem>
        <MenuItem onClick={handleAccountActivateDeactivateAction} sx={{ color: isActiveAccount ? 'warning.main' : 'success.main' }}>
          {isActiveAccount ? T.DEACTIVATE : T.REACTIVATE}
        </MenuItem>
        <MenuItem onClick={handleSuspendAction} sx={{ color: isAccountSuspended ? 'success.main' : 'error.main' }}>
          {isAccountSuspended ? T.UNSUSPEND_ACCOUNT : T.SUSPEND_ACCOUNT}
        </MenuItem>
      </Menu>
      <ReactivateLocationsDialog
        accountId={accountId}
        open={isOpenReactivateLocationsDialog}
        onClose={handleCloseReactivateLocationsDialog}
      />
      <EditAccountRepDialog
        isOpen={isOpenChangeAccountRepresentativeDialog}
        customerDetails={customerDetails}
        accountId={accountId}
        onClose={handleCloseChangeAccountRepresentativeDialog}
      />
      <HHConfirmDialog
        isOpen={isOpenActiveDeactivateAccountDialog}
        DialogProps={{ maxWidth: 'xs' }}
        confirmButtonTitle={T.DEACTIVATE}
        confirmTitle="Deactivate account"
        ConfirmButtonProps={{ loading: isDeactivateAccountLoading, disabled: isDeactivateAccountLoading }}
        confirmDescription="You're about to deactivate this account. All services and locations in this account will be deactivated. Do you want to continue?"
        onConfirm={handleAccountDeactivation}
        onClose={handleCloseAccountActivateDeactivateDialog}
      />
      <SuspendAccountDialog accountId={accountId} open={isOpenSuspendDialog} onClose={handleCloseSuspendDialog} />
      <UnsuspendAccountDialog accountId={accountId} open={isOpenUnsuspendDialog} onClose={handleCloseUnsuspendDialog} />
      <EditAccountDialog
        isOpen={isOpenEditAccountDialog}
        account={accountEditModel}
        customerDetails={customerDetails}
        onClose={onCloseEditAccountDialog}
      />
      <AddToGroupDialog isOpen={isOpenAddToGroupDialog} accountIds={[accountId]} onClose={handleCloseAddToGroupDialog} />
      <SuspensionHistoryDialog accountId={accountId} onClose={handleCloseSuspensionHistoryDialog} open={isOpenSuspensionHistoryDialog} />
      <SettingsDialog accountId={accountId} open={isOpenSettingsDialog} onClose={handleCloseSettingsDialog} />
      <HHConfirmDialog
        onConfirm={closeConfirmAccountReactivatedDialog}
        isOpen={isOpenConfirmAccountReactivatedDialog}
        onClose={closeConfirmAccountReactivatedDialog}
        showCancelButton={false}
        confirmButtonTitle={T.OK}
        confirmTitle={T.ACCOUNT_ACTIVATED}
        confirmDescription={T.ACCOUNT_ACTIVATED_DESCRIPTION}
        ConfirmButtonProps={{
          color: 'success',
        }}
      />
    </>
  )
}

AccountHeaderActionMenu.propTypes = {
  anchorEl: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AccountHeaderActionMenu
