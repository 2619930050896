import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@mui/material'

import { get } from 'utils/lodash'
import AccountGroupCard from './AccountGroupCard'

const AccountGroupDataGridRow = ({ originalRows = [], row = {}, noOfColumns = 1 }) => {
  const { index } = row

  return (
    <Grid container spacing={2} px={3}>
      {Children.toArray(
        new Array(noOfColumns).fill().map((_, subIndex) => {
          const nextRow = get(originalRows, `[${index + subIndex}]`)

          return (
            nextRow && (
              <Grid item xs={12} md={6} xl={4}>
                <AccountGroupCard group={nextRow} />
              </Grid>
            )
          )
        })
      )}
    </Grid>
  )
}

AccountGroupDataGridRow.propTypes = {
  originalRows: PropTypes.array,
  row: PropTypes.object,
  noOfColumns: PropTypes.number,
}

export default AccountGroupDataGridRow
