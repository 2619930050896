import { NumericFormat } from 'react-number-format'

import { get, capitalize } from 'lodash'
import { getRecurrenceText, getPricingPeriod } from 'utils/service'

export const getPricingServiceName = (service, column, type, useNewDataSource = false) => {
  const serviceName = get(service, 'serviceName', '')
  let material
  let recurrencePer
  let action
  let method
  const measureVol = get(service, 'measure.volume', '')
  const measureUnit = get(service, 'measure.unit', '')
  const measure = get(service, 'measureText', '')
  let pricingZone
  let pricingPeriodPer
  let pricingPeriodInterval
  let specificDays
  if (useNewDataSource) {
    material = get(service, 'materialType', '')
    recurrencePer = get(service, 'recurrencePer', '')
    action = get(service, 'actionName', '')
    method = capitalize(get(service, 'methodType', ''))
    pricingZone = get(service, 'zoneName', '')
    pricingPeriodPer = get(service, 'periodPer', '')
    pricingPeriodInterval = get(service, 'periodInterval', '')
    specificDays = get(service, 'days', [])
  } else {
    material = get(service, 'material.materialType', '')
    recurrencePer = get(service, 'recurrence.recurrencePer', '')
    action = get(service, 'serviceAction.actionName', '')
    method = get(service, 'serviceMethod.methodName', '')
    pricingZone = get(service, 'pricingZone.zoneName', '')
    pricingPeriodPer = get(service, 'pricingPeriod.periodPer', '')
    pricingPeriodInterval = get(service, 'pricingPeriod.periodInterval', '')
    specificDays = get(service, 'specificDays', '')
  }
  let recurrenceInterval

  if (useNewDataSource) {
    recurrenceInterval = get(service, 'recurrenceInterval', '')
  } else {
    recurrenceInterval = get(service, 'recurrence.recurrenceInterval', '')
  }
  const recurrenceFrequency = get(service, 'recurrenceFrequency', '')

  const businessLine = get(service, 'businessLine', '')
  const value = get(service, 'value', '')
  const minimumBillingPeriod = get(service, 'minBillingPeriod', '')
  const template = get(service, 'template', '')
  const agreement = get(service, 'agreement', '')
  const agreementEnd = get(service, 'agreementEnd', '')
  const agreementMax = get(service, 'agreementMax', '')
  const agreementReverts = get(service, 'agreementReverts', '')
  const active = get(service, 'active', false)
  const nextPriceUpdate = get(service, 'nextPriceUpdate', '')
  const lastPriceUpdate = get(service, 'lastPriceUpdate', '')

  const activeLocations = get(service, 'activeLocationCount', '')

  if (column === undefined) return ''

  const records = {}
  records.Service = serviceName
  records.Material = material
  records.Recurrence = getRecurrenceText(recurrencePer, recurrenceInterval, recurrenceFrequency)

  records['Business Line'] = businessLine
  records.Action = action
  if (useNewDataSource) {
    records.Measure = measure
  } else if (measureUnit !== '') records.Measure = `${measureVol} ${measureUnit}`
  records.Method = method

  records['Pricing Zone'] = pricingZone

  records.Period = getPricingPeriod(pricingPeriodPer, pricingPeriodInterval)

  if (type === 'create') records.Value = value
  else
    records.Value =
      value !== '' && value !== 0 ? (
        <NumericFormat
          value={value}
          displayType="text"
          thousandSeparator
          prefix="$"
          decimalSeparator="."
          decimalScale={2}
          fixedDecimalScale
        />
      ) : (
        ''
      )

  records['Minimum Billing Period'] = minimumBillingPeriod
  records.Template = template

  records.Status = 'Deactivated'

  if (active) records.Status = 'Active'

  if (nextPriceUpdate !== '')
    records['Next Value Update'] = (
      <NumericFormat
        value={nextPriceUpdate}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    )

  if (lastPriceUpdate !== '')
    records['Last Value Update'] = (
      <NumericFormat
        value={lastPriceUpdate}
        displayType="text"
        thousandSeparator
        prefix="$"
        decimalSeparator="."
        decimalScale={2}
        fixedDecimalScale
      />
    )

  records.Agreement = agreement
  records.AgreementEnd = agreementEnd
  records.AgreementMax = agreementMax
  records.AgreementReverts = agreementReverts
  if (useNewDataSource) {
    records['Specific Days'] = specificDays.join(', ')
  } else {
    records['Specific Days'] = specificDays
  }
  records['Active Locations'] = activeLocations

  return records[column]
}
