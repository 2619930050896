import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@mui/material'

import { get } from 'utils/lodash'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import InvoiceStatusChip from 'components/common/chip/InvoiceStatusChip'
import InvoiceSummary from './batch-print/InvoiceSummary'

const RenderInvoiceCell = props => {
  const { row, rowNode } = props
  const { isParent, groupName, totalCents, status } = row

  return (
    <>
      {isParent && (
        <Box pr={2} width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <InvoiceStatusChip status={status} agingBucketType={groupName} count={get(rowNode, 'children', []).length} />
          <Typography variant="h6" fontWeight={500} color="textPrimary">
            <HHDisplayMoney value={totalCents} />
          </Typography>
        </Box>
      )}
      {!isParent && <InvoiceSummary row={row} sx={{ cursor: 'pointer' }} />}
    </>
  )
}

RenderInvoiceCell.propTypes = {
  row: PropTypes.object,
  rowNode: PropTypes.object,
}

export default RenderInvoiceCell
