import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Chip, ListItem, ListItemText, Avatar, useTheme, Typography, Tooltip } from '@mui/material'
import { ReactComponent as ObjectsColumnIcon } from 'assets/ObjectsColumn.svg'

import { ALPHA_38_PERCENT } from 'theme/colors'
import { getMeasureUnit } from 'utils/get-measure-unit'
import { getServiceRecurrenceText } from 'components/locations/LocationsDataGrid/RenderLocationDetailPanelContent/ConfiguredServiceCard'

import HHDisplayMoney from 'components/common/HHDisplayMoney'
import MeasureChip from 'containers/new-route-manager/RouteCard/MeasureChip'
import ServiceTypeAvatar from 'components/common/avatar/ServiceTypeAvatar'

const RenderCell = ({ row, isSelectable = false }) => {
  const theme = useTheme()
  const { serviceName, value = 0, unit = '', volume = 0, serviceType, zoneName } = row
  const measureLabel = `${volume} ${getMeasureUnit(unit)}`
  const recurrenceLabel = useMemo(() => getServiceRecurrenceText(row), [row])

  return (
    <Tooltip arrow title="Service is already connected to this rental fee" placement="top" disableHoverListener={isSelectable}>
      <ListItem sx={{ pl: 0 }}>
        <ListItemText
          primary={
            <Box display="flex" gap={0.5}>
              <Typography variant="h5" fontWeight={500} noWrap>{`${serviceName} |`}</Typography>
              <HHDisplayMoney value={value} formatToDollars={false} />
            </Box>
          }
          primaryTypographyProps={{ variant: 'h5', fontWeight: 500, noWrap: true, color: 'textPrimary' }}
          secondary={
            <Box display="flex" alignItems="center" gap={1}>
              <ServiceTypeAvatar serviceType={serviceType} />

              {recurrenceLabel}
              {unit && <MeasureChip label={measureLabel} measureUnit={unit} />}
              {zoneName && (
                <Chip
                  size="small"
                  variant="outlined"
                  avatar={
                    <Avatar
                      sx={{
                        backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}`,
                      }}
                    >
                      <ObjectsColumnIcon />
                    </Avatar>
                  }
                  label={zoneName}
                />
              )}
            </Box>
          }
          secondaryTypographyProps={{ mt: 0.5, variant: 'caption', noWrap: true }}
        />
      </ListItem>
    </Tooltip>
  )
}

RenderCell.propTypes = {
  row: PropTypes.object,
  isSelectable: PropTypes.bool,
}

export default RenderCell
