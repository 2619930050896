import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Chip, SvgIcon } from '@mui/material'
import { capitalize, get } from 'lodash'
import { ReactComponent as CollectionIcon } from 'assets/service-action-types/CollectionIcon.svg'
import { ReactComponent as DropIcon } from 'assets/service-action-types/DropIcon.svg'
import { ReactComponent as DumpReturnIcon } from 'assets/service-action-types/DumpReturnIcon.svg'
import { ReactComponent as SwapIcon } from 'assets/service-action-types/SwapIcon.svg'
import { ReactComponent as FinalIcon } from 'assets/service-action-types/FinalIcon.svg'
import { ReactComponent as RepositionIcon } from 'assets/service-action-types/RepositionIcon.svg'
import { ReactComponent as LiveLoadIcon } from 'assets/service-action-types/LiveLoadIcon.svg'
import { ReactComponent as RentalIcon } from 'assets/service-action-types/RentalIcon.svg'
import { ReactComponent as DeliveryIcon } from 'assets/service-action-types/DeliveryIcon.svg'
import { ReactComponent as ConciergeIcon } from 'assets/service-action-types/ConciergeIcon.svg'
import useTheme from '@mui/material/styles/useTheme'
import { cyan } from '@mui/material/colors'
import T from 'T'
import { SERVICE_ACTION_TYPE, SERVICE_ACTION_TYPE_TO_COLOR_MAP } from 'settings/constants/service'
import { ALPHA_38_PERCENT } from 'theme/colors'

export const SERVICE_ACTION_TYPE_TO_SVG = {
  SWAP: SwapIcon,
  DELIVERY: DeliveryIcon,
  'DUMP & RETURN': DumpReturnIcon,
  DROP: DropIcon,
  'LIVE LOAD': LiveLoadIcon,
  REPOSITION: RepositionIcon,
  FINAL: FinalIcon,
  COLLECTION: CollectionIcon,
  RENTAL: RentalIcon,
  CONCIERGE: ConciergeIcon,
}

const ServiceActionTypeChip = ({ action, label, clickable = false, ...rest }) => {
  const isRecurringCollection = label === T.RECURRING_COLLECTION
  const theme = useTheme()
  const actionUpperCase = action.toUpperCase()
  const ActionTypeIcon = actionUpperCase in SERVICE_ACTION_TYPE_TO_SVG ? SERVICE_ACTION_TYPE_TO_SVG[actionUpperCase] : undefined
  const backgroundColor = actionUpperCase in SERVICE_ACTION_TYPE ? SERVICE_ACTION_TYPE_TO_COLOR_MAP[actionUpperCase] : undefined
  const size = get(rest, 'size', 'medium')
  const isMediumSize = size === 'medium'
  if (action)
    return (
      <Chip
        avatar={
          <Avatar sx={{ backgroundColor: `${theme.palette.background.black}${ALPHA_38_PERCENT}` }}>
            <SvgIcon sx={{ fontSize: isMediumSize ? '1.1rem' : 14, color: theme.palette.common.white }} inheritViewBox>
              <ActionTypeIcon />
            </SvgIcon>
          </Avatar>
        }
        sx={{
          backgroundColor: isRecurringCollection ? cyan[100] : backgroundColor[100],
          ...(clickable && {
            '&:hover': {
              backgroundColor: isRecurringCollection ? cyan[200] : backgroundColor[200],
            },
          }),
        }}
        clickable={clickable}
        label={label || capitalize(action)}
        {...rest}
      />
    )
}

ServiceActionTypeChip.propTypes = {
  action: PropTypes.string,
  label: PropTypes.string,
  clickable: PropTypes.bool,
}

export default ServiceActionTypeChip
