import React, { forwardRef } from 'react'
import { Autocomplete, styled } from '@mui/material'
import { get } from 'lodash'
import PopupPaper from './PopupPaper'

const CustomAutocomplete = forwardRef((props, ref) => {
  const PaperComponent = get(props, 'PaperComponent', false)
  return <Autocomplete {...props} ref={ref} PaperComponent={PaperComponent || PopupPaper} />
})

const StyledAutocomplete = styled(CustomAutocomplete)(() => ({
  '.MuiAutocomplete-inputRoot': {
    // when flexWrap is set as wrap it doesn't align the text properly
    flexWrap: 'initial',
  },
}))

export default StyledAutocomplete
