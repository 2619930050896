import { store } from 'providers/store'
import { getAuth, signOut } from 'firebase/auth'
import { app } from 'providers/firebaseAuth'
import { resetLogin } from 'slices/login/LoginPersistSlice'
import { BEAMER_SCRIPT_ID } from 'components/header/settings'
import { shutdownIntercom } from 'utils/intercom'
import { putUserDetails } from 'middleware/actions/auth'
import api from 'api'

export const resetAuthReducer = () => {
  store.dispatch(putUserDetails(null))
}

export const handleFirebaseLogout = () => {
  const otherApp = getAuth()
  const authApp = getAuth(app)

  signOut(otherApp)
  signOut(authApp)

  const beamerScript = document.getElementById(BEAMER_SCRIPT_ID)
  if (beamerScript) {
    window?.Beamer.destroy()
    beamerScript.remove()
  }
  shutdownIntercom()

  store.dispatch(resetLogin())
  setTimeout(() => {
    store.dispatch(api.util.resetApiState())
    resetAuthReducer()
  }, 1000)
}
