import React from 'react'
import PropTypes from 'prop-types'

import { ListItemAvatar, Avatar, Typography } from '@mui/material'

const NoteUserInfo = ({ firstName = '', lastName = '' }) => {
  const firstNameLetter = firstName?.charAt(0)
  const lastNameLetter = lastName?.charAt(0)
  const avatarText = `${firstNameLetter}${lastNameLetter}`.trim()

  return (
    <>
      <ListItemAvatar sx={{ minWidth: 48 }}>
        {avatarText && (
          <Avatar sx={{ width: 32, height: 32, backgroundColor: 'background.notesAvatar' }}>
            <Typography variant="h6" fontWeight={400} color="textSecondary">
              {`${firstNameLetter}${lastNameLetter}`.trim()}
            </Typography>
          </Avatar>
        )}
      </ListItemAvatar>
    </>
  )
}

NoteUserInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
}

export default NoteUserInfo
