import React, { Children, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { TableBody as TBody, TableCell, TableRow, TextField } from '@mui/material'

import { NumericFormat } from 'react-number-format'

import { handlePricingTableClass } from 'components/pricing/pricingMethods'
import PricingSwitch from 'components/pricing/PricingSwitch'

import Constants from 'Constants'

import { memo } from 'utils/react'

import { get } from 'utils/lodash'

const { MEDIA_SERVER } = Constants

const ValidatedFeeTableBody = ({
  validatedFees,
  confServices,
  handleDelete,
  onHandleValidatedFieldsChange,
  type,
  getFeeServiceName,
  saveConfSettings,
  onTableFeeScopeChange,
}) => {
  const [allowedServices, setAllowedServices] = useState(confServices.filter(data => data.checked))

  useEffect(() => {
    setAllowedServices(confServices.filter(data => data.checked))
  }, [confServices])

  const lockedColumns = confServices.filter(column => column.locked && column.checked)

  return (
    <TBody className="table-body">
      {validatedFees &&
        allowedServices &&
        Children.toArray(
          validatedFees.map(fee => {
            return (
              <TableRow>
                {Children.toArray(
                  allowedServices.map((column, ind) => {
                    let columnName = column.label
                    if (column.label === 'Value') {
                      columnName = 'valueType'
                    }

                    const record = getFeeServiceName(fee, columnName, 'create')
                    const isLocked = get(column, 'locked', false)

                    return (
                      <>
                        <TableCell className={handlePricingTableClass(ind, isLocked, lockedColumns)}>
                          {columnName === 'Fee' && (
                            <div className="services-field">
                              <TextField
                                id="outlined-size-small"
                                value={record}
                                variant="outlined"
                                size="small"
                                autoComplete="off"
                                name="feeName"
                                InputProps={{
                                  onChange: e => onHandleValidatedFieldsChange(e, fee),
                                }}
                              />
                            </div>
                          )}

                          {columnName === 'Sub-Item' && (
                            <div className="sub-item">
                              <div className="switch-section">
                                <div className="switch">
                                  <PricingSwitch
                                    checked={get(fee, 'subItem', false)}
                                    handleSwitchVal={checked => onTableFeeScopeChange(checked, fee, 'subItem')}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {columnName === 'valueType' && (
                            <div className="prices-field">
                              <div
                                className={`dollar-btn ${
                                  fee && (fee.valueType === 'dollar' || fee.valueType === '$') ? 'value-type-active' : ''
                                }`}
                                onClick={() => onTableFeeScopeChange('$', fee, 'valueType')}
                              >
                                $
                              </div>

                              <div
                                className={`percent-btn ml10 ${
                                  fee && (fee.valueType === 'percentage' || fee.valueType === '%') ? 'value-type-active' : ''
                                }`}
                                onClick={() => onTableFeeScopeChange('%', fee, 'valueType')}
                              >
                                %
                              </div>

                              <NumericFormat
                                id="outlined-size-small"
                                className="ml10"
                                placeholder="0.00"
                                value={record}
                                variant="outlined"
                                size="small"
                                autoComplete="off"
                                name="value"
                                onChange={e => onHandleValidatedFieldsChange(e, fee)}
                                customInput={TextField}
                                thousandSeparator
                                decimalSeparator="."
                                decimalScale={2}
                              />
                            </div>
                          )}

                          {!['valueType', 'Fee', 'Sub-Item'].includes(columnName) && <div className="price-cell">{record}</div>}
                        </TableCell>
                      </>
                    )
                  })
                )}
                <TableCell>
                  <img src={`${MEDIA_SERVER}Trash-Red.svg`} onClick={() => handleDelete(fee, type)} />
                </TableCell>
              </TableRow>
            )
          })
        )}
    </TBody>
  )
}

ValidatedFeeTableBody.defaultProps = {
  validatedFees: [],
  confServices: [],
  type: 'unique',
  saveConfSettings: false,
  handleDelete: null,
  onHandleValidatedFieldsChange: null,
  getFeeServiceName: null,
  onTableFeeScopeChange: null,
}

ValidatedFeeTableBody.propTypes = {
  validatedFees: PropTypes.array,
  confServices: PropTypes.array,
  type: PropTypes.string,
  saveConfSettings: PropTypes.bool,
  handleDelete: PropTypes.func,
  onHandleValidatedFieldsChange: PropTypes.func,
  getFeeServiceName: PropTypes.func,
  onTableFeeScopeChange: PropTypes.func,
}

export default memo(ValidatedFeeTableBody)
