import React from 'react'
import { Popover } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import useTheme from '@mui/material/styles/useTheme'
import StopDetails from 'containers/new-route-manager/stop-details/StopDetails'
import { deserializeDate } from 'utils/date'
import { selectRouteSerializedServiceDate, setStopDetailsPopoverState } from 'slices/route/routeSlice'

const StopDetailsPopover = () => {
  const isOpen = useSelector(s => get(s, 'Route.stopDetailsPopover.isOpen', false))
  const x = useSelector(s => get(s, 'Route.stopDetailsPopover.position.x'))
  const y = useSelector(s => get(s, 'Route.stopDetailsPopover.position.y'))
  const id = useSelector(s => get(s, 'Route.stopDetailsPopover.id'))
  const stop = useSelector(s => get(s, 'Route.stopDetailsPopover.stop'), shallowEqual)
  const theme = useTheme()
  const dispatch = useDispatch()
  const serializedServiceDate = useSelector(selectRouteSerializedServiceDate)
  const serviceDate = serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()

  const handlePopoverClose = () => dispatch(setStopDetailsPopoverState({ isOpen: false, position: null, stop: {}, id: '' }))

  if (!isOpen || !id || !stop) {
    // prevents showing an empty popover
    return null
  }

  return (
    <Popover
      anchorReference="anchorPosition"
      sx={{ pointerEvents: 'none', zIndex: theme.zIndex.tooltip }}
      PaperProps={{ sx: { maxWidth: 475 } }}
      open
      anchorPosition={{ top: y, left: x }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: -20 }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <StopDetails stop={stop} serviceDate={serviceDate} />
    </Popover>
  )
}

StopDetailsPopover.propTypes = {}

export default StopDetailsPopover
