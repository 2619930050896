import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { ListItem, ListItemText, Collapse, List, IconButton, Box, Button, Typography, Divider, Grid } from '@mui/material'

import ChevronRight from '@mui/icons-material/ChevronRight'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { get } from 'utils/lodash'
import { REGEX } from 'utils/validations'
import { CONTACT_METHODS, CONTACT_METHOD_TYPES, METHOD_TYPES } from 'settings/constants/contacts'
import { HHFormTextField, HHFormSelectField } from 'components/form-fields/v5'
import { SERVICE_NOTIFICATIONS_METHODS, BILLING_NOTIFICATIONS_METHODS } from 'settings/constants/notifications'

import T from 'T'
import SelectItem from 'components/form-fields/v5/SelectItem'
import HHPhoneField from 'components/form-fields/v5/HHPhoneField'
import BillingServiceNotificationAvatar from 'components/common/avatar/BillingServiceNotificationAvatar'
import HHFormSwitchIconField from 'components/form-fields/v5/HHFormSwitchIconField'
import HHFormPhoneField from 'components/form-fields/v5/HHFormPhoneField'
import ContactMethodIcon from './ContactMethodIcon'

const { EMAIL, FAX } = CONTACT_METHODS
const { PERSONAL, WORK } = CONTACT_METHOD_TYPES

const EMAIL_PLACEHOLDER = 'place.holder@email.com'
const MOBILE_PLACEHOLDER = '(858) 888-1324'

const ContactMethod = ({ isExpanded = false, index, contactMethod = {}, onExpandChange, onDelete }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const methodType = get(contactMethod, 'methodType')
  const contactMethodType = get(contactMethod, 'contactMethodType')
  const methodValue = get(contactMethod, 'methodValue', '')
  const billingNotifications = get(contactMethod, 'billingNotifications')
  const serviceNotifications = get(contactMethod, 'serviceNotifications')
  const hasBillingNotifications = BILLING_NOTIFICATIONS_METHODS.includes(methodType)
  const hasServiceNotifications = SERVICE_NOTIFICATIONS_METHODS.includes(methodType)
  const isValidContactMethod = get(errors, `contactMethods[${index}].methodValue`)

  const handleClick = () => {
    // we can also use independent state
    onExpandChange(!isExpanded)
  }

  const renderMethodText = () => {
    const isEmailMethod = methodType === EMAIL
    if (!methodValue) {
      return (
        <Typography ml={0.5} color="textSecondary">
          {isEmailMethod ? EMAIL_PLACEHOLDER : MOBILE_PLACEHOLDER}
        </Typography>
      )
    }

    return (
      <Typography ml={0.5} noWrap>
        {isEmailMethod ? methodValue : <HHPhoneField displayType="text" value={methodValue} />}
      </Typography>
    )
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const [field, subIndex, subfield] = name.split('.')
      if (subfield === 'methodType') {
        const selectedMethod = get(value, `${field}.${subIndex}.${subfield}`)
        setValue(`contactMethods[${subIndex}].contactMethodType`, selectedMethod === FAX ? WORK : PERSONAL)
        setValue(`contactMethods[${subIndex}].methodValue`, '')
        setValue(`contactMethods[${subIndex}].billingNotifications`, BILLING_NOTIFICATIONS_METHODS.includes(selectedMethod))
        setValue(`contactMethods[${subIndex}].serviceNotifications`, SERVICE_NOTIFICATIONS_METHODS.includes(selectedMethod))
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <>
      <ListItem>
        <IconButton size="small" onClick={handleClick}>
          {isExpanded ? <ExpandMore /> : <ChevronRight />}
        </IconButton>

        <ListItemText>
          <Typography variant="h6" fontWeight={400}>
            {methodType}
          </Typography>
        </ListItemText>

        {hasBillingNotifications && (
          <BillingServiceNotificationAvatar type="Billing" isActive={billingNotifications} styles={{ mr: 0.5 }} />
        )}

        {hasServiceNotifications && <BillingServiceNotificationAvatar type="Service" isActive={serviceNotifications} />}

        <Button sx={{ ml: 1 }} size="small" color="error" variant="outlined" onClick={onDelete}>
          {T.DELETE}
        </Button>
      </ListItem>

      <Box sx={{ pl: 6, pr: 2, py: 0 }} display="flex" alignItems="center" minHeight={20}>
        <ContactMethodIcon type={methodType} />
        {renderMethodText()}
      </Box>

      <Collapse in={isExpanded} timeout="1000">
        <List component="div" disablePadding>
          <ListItem disablePadding sx={{ pl: 5, pr: 1, pb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container mt={1.5} rowGap={1} spacing={1}>
              <Grid item xs={4}>
                <HHFormTextField control={control} fullWidth select name={`contactMethods.${index}.methodType`}>
                  {Object.values(CONTACT_METHODS).map(method => (
                    <SelectItem value={method} sx={{ '&.Mui-selected svg': { color: 'primary.main' } }}>
                      <ListItem disablePadding>
                        <ContactMethodIcon type={method} />
                        <Typography ml={1}>{method}</Typography>
                      </ListItem>
                    </SelectItem>
                  ))}
                </HHFormTextField>
              </Grid>
              <Grid item xs>
                <HHFormSelectField
                  value={contactMethodType}
                  control={control}
                  label="Contact type"
                  options={METHOD_TYPES[methodType]}
                  getOptionValue={option => option}
                  getOptionLabel={option => option}
                  fullWidth
                  deprecatedLabel={false}
                  name={`contactMethods.${index}.contactMethodType`}
                />
              </Grid>
              <Grid item xs={12}>
                {methodType === EMAIL ? (
                  <HHFormTextField
                    value={methodValue}
                    control={control}
                    error={isValidContactMethod}
                    rules={{ pattern: REGEX.IS_EMAIL }}
                    name={`contactMethods.${index}.methodValue`}
                    label={methodType}
                    fullWidth
                    deprecatedLabel={false}
                  />
                ) : (
                  <HHFormPhoneField
                    value={methodValue}
                    control={control}
                    error={isValidContactMethod}
                    rules={{ pattern: REGEX.IS_VALID_MOBILE }}
                    name={`contactMethods.${index}.methodValue`}
                    label={methodType}
                    fullWidth
                    deprecatedLabel={false}
                  />
                )}
              </Grid>
              {(hasBillingNotifications || hasServiceNotifications) && (
                <Grid container item mt={1}>
                  <Box width="100%">
                    <Typography color="textSecondary">{T.NOTIFICATIONS}</Typography>
                    <Divider sx={{ mt: 0.5 }} />
                  </Box>

                  <Grid container display="flex" justifyContent="space-between">
                    {hasBillingNotifications && (
                      <Grid item xs="auto" py={1.5} px={2}>
                        <HHFormSwitchIconField
                          type="Billing"
                          label="Billing"
                          isChecked={billingNotifications}
                          name={`contactMethods.${index}.billingNotifications`}
                        />
                      </Grid>
                    )}
                    {hasBillingNotifications && (
                      <Grid item xs="auto">
                        <Divider orientation="vertical" />
                      </Grid>
                    )}
                    {hasServiceNotifications && (
                      <Grid item xs="auto" py={1.5} px={2}>
                        <HHFormSwitchIconField
                          type="Service"
                          label="Service"
                          isChecked={serviceNotifications}
                          name={`contactMethods.${index}.serviceNotifications`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}

ContactMethod.propTypes = {
  isExpanded: PropTypes.bool,
  index: PropTypes.number,
  contactMethod: PropTypes.object,
  onExpandChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ContactMethod
