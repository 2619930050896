import React, { useRef, useState, Children } from 'react'
import PropTypes from 'prop-types'

import InputAdornment from '@mui/material/InputAdornment'

import { Search } from '@styled-icons/heroicons-outline/Search'
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown'

import { get } from 'utils/lodash'
import { isPriceTypeDollar } from 'utils/service'

import CommonTextfield from 'components/common/CommonTextfield'

import './style.scss'
import { List, ListItemButton, Stack } from '@mui/material'
import Box from '@mui/material/Box'

const FeeSearch = ({ records, selectedRecord, className, placeholder, onBlur, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef()

  const selectedRecordId = get(selectedRecord, 'feeName', '')

  const handleChange = (value, isOptionSelected = false) => {
    onChange(isOptionSelected, value)
    setInputValue(value)
  }

  return (
    <div className={`common icon-selection-field ${className}`}>
      <div
        onClick={event => {
          setIsOpen(true)
          setTimeout(() => inputRef.current.focus(), 100)
        }}
        className={`fee-selection-field photo-select-field transparent-scroll ${isOpen ? 'hide' : ''}`}
      >
        <Stack flexDirection="row" alignItems="center">
          {selectedRecordId && (
            <>
              <span className="text">{selectedRecordId}</span>
            </>
          )}
          {!selectedRecordId && <span className="text empty">{placeholder}</span>}
        </Stack>
        <ChevronDown className="assignee-down-icon" />
      </div>

      <Box display={!isOpen && 'none'} position="relative">
        <CommonTextfield
          value={inputValue}
          refVar={inputRef}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          onBlur={() => {
            setIsOpen(false)
            if (onBlur) {
              onBlur(inputValue)
            }
            setInputValue('')
          }}
          startAdornment={
            <InputAdornment position="start">
              <Search className="search-icon" />
            </InputAdornment>
          }
        />
        <List
          sx={{
            borderColor: 'divider',
            borderWidth: 1,
            borderStyle: 'solid',
            position: 'absolute',
            width: '100%',
            overflowY: 'auto',
            bgcolor: 'common.white',
            padding: 0,
          }}
          disablePadding
          className="search-select-autocomplete photo-select-autocomplete"
        >
          {Children.toArray(
            records.map(record => {
              const feeName = get(record, 'feeName', '')
              const valueType = get(record, 'valueType', '')

              const value = parseFloat(get(record, 'value', 0)).toFixed(2)

              return (
                // Handle percentage case later
                feeName &&
                isPriceTypeDollar(valueType) && (
                  <>
                    <ListItemButton onMouseDown={() => handleChange(record, true)}>
                      <Stack sx={{ width: '100%' }} flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box component="span" width="80%" className="fee-name text w-80 truncate">
                          {feeName}
                        </Box>
                        <Box component="span" className="price text w-15 truncate">{`$${value}`}</Box>
                      </Stack>
                    </ListItemButton>
                  </>
                )
              )
            })
          )}

          {!records.length && <span className="text empty tl">No Options</span>}
        </List>
      </Box>
    </div>
  )
}

FeeSearch.defaultProps = {
  records: [],
  className: '',
  placeholder: '',
  selectedRecord: null,
  onBlur: null,
}

FeeSearch.propTypes = {
  records: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  selectedRecord: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}

export default FeeSearch
