import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { noop } from 'lodash'
import VacationHoldChip from 'components/common/chip/VacationHoldChip'

const HoldListItem = ({ primary, hold, secondary, divider = false, onOpenActionMenu = noop }) => {
  const handleOpenActionMenu = e => {
    onOpenActionMenu(e, hold)
  }

  return (
    <ListItem
      divider={divider}
      secondaryAction={
        <IconButton onClick={handleOpenActionMenu}>
          <MoreVert />
        </IconButton>
      }
    >
      <ListItemIcon sx={{ mr: 1 }}>
        <VacationHoldChip label="Vacation" />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ variant: 'h6', fontWeight: 'normal', noWrap: true }}
        secondaryTypographyProps={{ variant: 'body1', noWrap: true }}
      />
    </ListItem>
  )
}

HoldListItem.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  divider: PropTypes.bool,
  onOpenActionMenu: PropTypes.func,
  hold: PropTypes.object.isRequired,
}

export default HoldListItem
