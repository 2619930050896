import Badge from '@mui/material/Badge'
import { styled } from '@mui/material'
import { blue } from '@mui/material/colors'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: blue[200],
    color: theme.palette.text.primary,
  },
}))

export default StyledBadge
