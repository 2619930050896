import React, { Fragment, useCallback } from 'react'
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro'
import { unstable_composeClasses as composeClasses, Box, IconButton, CircularProgress, styled } from '@mui/material'

const useUtilityClasses = ownerState => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }

  return composeClasses(slots, getDataGridUtilityClass, classes)
}

const LoadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const RouteGroupingCellWithLazyLoading = props => {
  const { id, field, rowNode, row } = props

  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useUtilityClasses({ classes: rootProps.classes })

  const { treeDataCollapseIcon, treeDataExpandIcon } = rootProps.slots

  const isLoading = !row.childrenFetched

  const getIcon = useCallback(() => {
    if (rowNode?.childrenExpanded && treeDataCollapseIcon) {
      return treeDataCollapseIcon
    }
    if (treeDataExpandIcon) {
      return treeDataExpandIcon
    }
    return Fragment
  }, [rootProps, rowNode])

  const Icon = getIcon()

  const isNavigationKey = key => key === 'Home' || key === 'End' || key.indexOf('Arrow') === 0 || key.indexOf('Page') === 0 || key === ' '

  const handleKeyDown = event => {
    if (event.key === ' ') {
      event.stopPropagation()
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent('cellNavigationKeyDown', props, event)
    }
  }

  const handleClick = event => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return (
    <Box className={classes.root} sx={{ pl: 2 }}>
      <Box className={classes.toggle} sx={{ minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {row.isRoute && (
          <>
            {isLoading && (
              <LoadingContainer>
                <CircularProgress size="1.4rem" color="inherit" />
              </LoadingContainer>
            )}
            {!isLoading && row.descendantCount !== 0 && (
              <IconButton
                size="small"
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                tabIndex={-1}
                aria-label={
                  rowNode.childrenExpanded
                    ? apiRef.current.getLocaleText('treeDataCollapse')
                    : apiRef.current.getLocaleText('treeDataExpand')
                }
              >
                <Icon fontSize="inherit" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default RouteGroupingCellWithLazyLoading
