import React, { useEffect } from 'react'
import { Box, Button, Skeleton, Stack } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { INVOICE_STATUS } from 'settings/constants/invoice'
import { toast } from 'react-toastify'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'
import { useMarkWorkOrderListReadyForInvoiceMutation } from 'api/work-order/markWorkOrderListReadyForInvoice'
import { setWOReviewNotReadyRowsSelectedCount, setWOReviewRowSelectionModel } from 'slices/billing/workOrdersReviewSlice'
import { useWOReviewContext } from './WOReviewProvider'
import WorkOrderInvoiceStatusChip from '../../WorkOrderInvoiceStatusChip'

const { NOT_READY_FOR_INVOICE, READY_FOR_INVOICE, INVOICED } = INVOICE_STATUS

const RenderWORightSectionHeader = () => {
  const dispatch = useDispatch()
  const apiRef = useGridApiContext()
  const totalStatusCount = useSelector(state => state.workOrdersReview.totalStatusCount, shallowEqual)
  const selectionModel = useSelector(state => state.workOrdersReview.selectionModel, shallowEqual)
  const notReadyRowsSelectedCount = useSelector(state => state.workOrdersReview.notReadyRowsSelectedCount, shallowEqual)
  const sortedRows = apiRef.current.getSortedRows()
  const woNotReadyCount = get(totalStatusCount, NOT_READY_FOR_INVOICE, 0)
  const woReadyCount = get(totalStatusCount, READY_FOR_INVOICE, 0)
  const woInvoicedCount = get(totalStatusCount, INVOICED, 0)
  const notReadyLabel = `${notReadyRowsSelectedCount}/${woNotReadyCount} Not ready`
  const readyLabel = `${woReadyCount} Ready`
  const invoicedLabel = `${woInvoicedCount} Invoiced`
  const [markWOListReadyForInvoice] = useMarkWorkOrderListReadyForInvoiceMutation()
  const { fetchAllChildrenRows, setIsLoading, isLoading } = useWOReviewContext()

  useEffect(() => {
    let _notReadyRowsSelectedCount = 0
    selectionModel.forEach(id => {
      const row = apiRef.current.getRow(id)
      if (row.isWO) _notReadyRowsSelectedCount++
    })
    dispatch(setWOReviewNotReadyRowsSelectedCount(_notReadyRowsSelectedCount))
  }, [selectionModel])

  const handleMarkWOListAsReadyForInvoice = () => {
    const selectedRowsById = apiRef.current.getSelectedRows()
    const selectedWOList = selectionModel.filter(id => {
      const selectedRow = selectedRowsById.get(id)
      return selectedRow?.isWO
    })
    if (selectedWOList.length === 0) return
    dispatch(putIsLoading(true))
    markWOListReadyForInvoice({ workOrderIds: selectedWOList })
      .unwrap()
      .then(() => {
        dispatch(setWOReviewRowSelectionModel([]))
        fetchAllChildrenRows()
        toast.success('Invoice status updated successfully')
      })
      .catch(handleError)
      .finally(() => dispatch(putIsLoading(false)))
  }

  useEffect(() => {
    const allChildrenFetched = sortedRows.filter(({ isRoute }) => isRoute).every(({ childrenFetched }) => childrenFetched)
    if (isLoading !== !allChildrenFetched) {
      setIsLoading(!allChildrenFetched)
    }
  }, [sortedRows, isLoading])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {!isLoading && (
        <Stack direction="row" spacing={2}>
          <WorkOrderInvoiceStatusChip status={NOT_READY_FOR_INVOICE} label={notReadyLabel} />
          <WorkOrderInvoiceStatusChip status={READY_FOR_INVOICE} label={readyLabel} />
          <WorkOrderInvoiceStatusChip status={INVOICED} label={invoicedLabel} />
        </Stack>
      )}
      {isLoading && (
        <Stack direction="row" spacing={2}>
          <Skeleton sx={{ width: '6rem', height: '2.5rem' }} />
          <Skeleton sx={{ width: '6rem', height: '2.5rem' }} />
          <Skeleton sx={{ width: '6rem', height: '2.5rem' }} />
        </Stack>
      )}
      <Button
        sx={{
          textTransform: 'none',
          position: 'absolute',
          right: '24px',
        }}
        disabled={selectionModel.length === 0}
        variant="outlined"
        onClick={handleMarkWOListAsReadyForInvoice}
      >
        Mark as ready
      </Button>
    </Box>
  )
}

export default RenderWORightSectionHeader
