import { useCallback, useEffect, useRef } from 'react'
import { debounce, noop } from 'lodash'

export const useLazyEffect = (effect = noop, deps = [], wait = 300) => {
  const cleanUp = useRef()
  const effectRef = useRef()
  effectRef.current = useCallback(effect, deps)
  const lazyEffect = useCallback(
    debounce(() => (cleanUp.current = effectRef.current?.()), wait),
    []
  )

  useEffect(lazyEffect, deps)

  useEffect(() => {
    return () => (cleanUp.current instanceof Function ? cleanUp.current() : undefined)
  }, [])
}
