import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    getServiceAffectedWO: builder.mutation({
      query: body => ({ url: `/api/configured-service/work-orders`, method: 'POST', body }),
    }),
  }),
})

export const { useGetServiceAffectedWOMutation } = extendedApi