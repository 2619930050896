import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'

import { get } from 'utils/lodash'
import { calculateSum } from 'utils/price'

import T from 'T'
import { useGetAccountGroupDetailsData } from 'components/customers/groups/account/details/useGetAccountGroupDetailsData'
import { useParams } from 'react-router-dom-v5-compat'
import SummaryCard from '../../../../billing/groups/common/SummaryCard'
import HHDisplayMoney from '../../../../common/HHDisplayMoney'

const Summary = () => {
  const { id } = useParams()
  const { data } = useGetAccountGroupDetailsData(id, true)
  const accounts = get(data, 'accounts', [])
  const unpaidInvoicesCount = useMemo(() => calculateSum(accounts, 'unpaidInvoiceCount'), [accounts])
  const totalDue = useMemo(() => calculateSum(accounts, 'balanceCents'), [accounts])
  const suspendedAccounts = useMemo(() => accounts.filter(({ suspensionHold }) => suspensionHold).length, [accounts])
  const totalAccounts = accounts.length

  return (
    <Grid container columnSpacing={2.5} spacing={2.5}>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard
          label="Unpaid invoices"
          value={<HHDisplayMoney value={unpaidInvoicesCount} formatToDollars={false} prefix="" decimalScale={0} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard label="Total due" value={<HHDisplayMoney value={totalDue} />} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard
          label="Suspensions"
          value={<HHDisplayMoney value={suspendedAccounts} formatToDollars={false} prefix="" decimalScale={0} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <SummaryCard
          label={T.NUMBER_OF_ACCOUNTS}
          value={<HHDisplayMoney value={totalAccounts} formatToDollars={false} prefix="" decimalScale={0} />}
        />
      </Grid>
    </Grid>
  )
}

export default Summary
