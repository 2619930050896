import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { get, groupBy, sortBy } from 'lodash'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useDispatch } from 'react-redux'
import T from 'T'
import { HHFormSelectField, HHFormSwitchField, HHFormTextField } from 'components/form-fields/v5'
import { useGetDisposalSitesMutation } from 'api/settings/getDisposalSites'
import { useGetWorkOrdersDisposalTicketMutation } from 'api/work-order/getWorkOrdersDisposalTicket'
import { useCreateWorkOrdersDisposalTicketMutation } from 'api/work-order/createWorkOrdersDisposalTicket'
import { useUpdateWorkOrdersDisposalTicketMutation } from 'api/work-order/updateWorkOrdersDisposalTicket'
import { useDeleteWorkOrdersDisposalTicketMutation } from 'api/work-order/deleteWorkOrdersDisposalTicket'
import { selectValueFromState } from 'data/lodash/getSelector'
import { putIsLoading } from 'middleware/actions/response'
import { handleError } from 'utils/error'
import { getTaxList, transformMultiplePricedServicesToSingle } from 'utils/service'
import { getServiceFee } from 'data/service/serviceSelectors'
import { useLazyGetServiceDetailsQuery } from 'api/configured-service/getServiceDetails'
import HHDisplayMoney from 'components/common/HHDisplayMoney'
import DisposalTicketTaxInfoIcon from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalTicketTaxInfoIcon'
import { formatDollarsToCents } from 'utils/price'
import lowerCase from 'lodash/lowerCase'
import HHSectionPlaceholder from 'components/common/HHSectionPlaceholder'
import { getTransformDisposalData } from 'components/work-order/details/disposal-ticket/settings'
import DisposalMaterialFormRow from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalMaterialFormRow'
import DisposalTicketFormFooter from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalTicketFormFooter'
import DisposalTicketVariantWrapper from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalTicketVariantWrapper'
import DisposalTicketFormSkeleton from 'components/work-order/details/disposal-ticket/DisposalTicketAccordion/DisposalTicketFormSkeleton'

const accordionContentSx = { py: 3, px: 3 }

const defaultModalFormValues = {
  disposalTicketId: '',
  ticketNumber: '',
  disposalSiteId: '',
  materials: [
    {
      id: undefined,
      disposalSiteMaterialId: '',
      quantity: '',
      measureUnit: '',
      tippingFee: '',
      unitPriceDollars: '',
      taxes: [],
    },
  ],
}

const defaultAccordionFormValues = {
  disposalTicketId: '',
  ticketNumber: '',
  disposalSiteId: '',
  materials: [],
}

const newMaterial = {
  id: undefined,
  disposalSiteMaterialId: '',
  quantity: '',
  measureUnit: '',
  tippingFee: '',
  unitPriceDollars: '',
  taxes: [],
}

const DisposalTicketForm = ({
  workOrderId,
  isFormEditable = false,
  onAddDisposalTicket = noop,
  onDeleteConfirmationModalProceed = noop,
  onDisposalTicketDataFetched = noop,
  onSubmitWithoutChanges = noop,
  onSubmitSuccess = noop,
  onClose = noop,
  isFormAlwaysVisible = false,
  toggleEditMode = noop,
  variant = 'accordion',
  configuredServiceId,
}) => {
  const [getDisposalSites, { data: disposalSites }] = useGetDisposalSitesMutation()
  const [getWorkOrdersDisposalTicket, { isLoading: isGetWorkOrdersDisposalTicketLoading }] = useGetWorkOrdersDisposalTicketMutation()
  const [createWorkOrdersDisposalTicket, { isLoading: isCreateWorkOrdersDisposalTicketLoading }] =
    useCreateWorkOrdersDisposalTicketMutation()
  const [updateWorkOrdersDisposalTicket, { isLoading: isUpdateWorkOrdersDisposalTicketLoading }] =
    useUpdateWorkOrdersDisposalTicketMutation()
  const [deleteWorkOrdersDisposalTicket, { isLoading: isDeleteWorkOrdersDisposalTicketLoading }] =
    useDeleteWorkOrdersDisposalTicketMutation()
  const [toBeDeletedMaterials, setToBeDeletedMaterials] = useState([])
  const {
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    getValues,
    formState: { isDirty },
    setValue,
  } = useFormContext()
  const {
    fields: materialFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'materials',
  })
  const materials = useWatch({
    name: 'materials',
  })
  const dispatch = useDispatch()
  const theme = useTheme()
  const isLoading =
    isCreateWorkOrdersDisposalTicketLoading || isUpdateWorkOrdersDisposalTicketLoading || isDeleteWorkOrdersDisposalTicketLoading
  const disposalTicketId = watch('disposalTicketId')
  const isTaxable = watch('isTaxable')
  const allSites = selectValueFromState({ inputData: disposalSites, key: 'sites', defaultValue: [] })
  const allSitesSorted = useMemo(() => sortBy(allSites, site => lowerCase(get(site, 'name', ''))), [allSites])
  const disposalSiteId = watch('disposalSiteId')
  const selectedDisposal = useMemo(() => allSites.find(site => site.id === disposalSiteId), [allSites, disposalSiteId])
  const validMaterials = useMemo(
    () => sortBy(get(selectedDisposal, 'disposalSiteMaterial', []), material => lowerCase(get(material, 'material.materialType', ''))),
    [selectedDisposal]
  )
  const isFormVisible = isFormAlwaysVisible || disposalTicketId || materialFields.length > 0
  const showEditButton = Boolean(!isFormEditable && disposalTicketId)
  const isModalVariant = variant === 'modal'
  const [getServiceDetails, { data }] = useLazyGetServiceDetailsQuery()
  const [feeTaxList, setFeeTaxList] = useState([])
  const priceServiceDetail = get(data, 'priceServiceDetail', {})
  const taxZoneId = get(data, 'taxZoneId', '')
  const total = watch('total')
  const serviceId = get(priceServiceDetail, 'id', '')
  const onlyTaxes = feeTaxList.filter(tax => tax.type === T.TAX)
  const taxesThatMatchTaxZone = onlyTaxes.filter(tax => tax.taxZoneId === taxZoneId && tax.visible)
  const groupByTaxesAndTaxOnFees = groupBy(taxesThatMatchTaxZone, 'serviceId')
  const taxes = get(groupByTaxesAndTaxOnFees, serviceId, [])
  const groupByFeeTaxes = groupBy(taxes, 'feeTaxId')
  const getOptionId = option => option?.id
  const getDisposalSiteOptionLabel = option => option?.name
  const onAddNewMaterial = () => {
    append({
      ...newMaterial,
    })
  }
  const onAddDisposalTicketClick = () => {
    onAddDisposalTicket()
    append({
      ...newMaterial,
    })
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  const fetchDisposalTicketData = async () =>
    getWorkOrdersDisposalTicket(workOrderId)
      .unwrap()
      .then(res => {
        onDisposalTicketDataFetched()
        if (Array.isArray(res) && res.length !== 0) {
          reset(getTransformDisposalData(res))
        } else {
          reset(isModalVariant ? { ...defaultModalFormValues } : { ...defaultAccordionFormValues })
        }
      })
      .catch(handleError)

  const onSubmitHandler = async data => {
    const { disposalTicketId, disposalSiteId, ticketNumber, materials } = data
    if (!isDirty) {
      onSubmitWithoutChanges()
    }

    const materialsPayload = materials.map(material => ({
      ...material,
      quantity: get(material, 'quantity', 0),
      tippingFee: get(material, 'tippingFee', 0),
      unitPriceCents: formatDollarsToCents(get(material, 'unitPriceDollars', 0)),
    }))

    const body = {
      ticketNumber,
      disposalSiteId,
      materials: materialsPayload.concat(toBeDeletedMaterials),
      isTaxable,
    }

    if (data.disposalTicketId) {
      await updateWorkOrdersDisposalTicket({
        workOrderId,
        disposalTicketId,
        body,
        isTaxable,
      })
        .unwrap()
        .catch(handleError)
    } else {
      await createWorkOrdersDisposalTicket({ workOrderId, body }).unwrap().catch(handleError)
    }
    fetchDisposalTicketData().then(onSubmitSuccess)
  }

  const onSubmitErrorHandler = () => {
    toast.error(T.REQUIRED_FIELD_MSG)
  }

  const getTaxesAndFees = data => {
    const pricedServiceId = get(data, 'id')
    const transformResponse = transformMultiplePricedServicesToSingle(data, pricedServiceId)
    const generalFee = getServiceFee('General Fee', get(transformResponse, 'generalFeeList', []))
    const disposalFee = getServiceFee('Disposal Fee', get(transformResponse, 'disposableFeeList', []))
    const allFees = generalFee.concat(disposalFee)

    const taxList = getTaxList(
      [],
      get(transformResponse, 'taxList', []),
      get(transformResponse, 'generalFeeList', []),
      get(transformResponse, 'disposableFeeList', [])
    )

    const allFeesAndTaxes = allFees.concat(taxList)

    return allFeesAndTaxes
  }

  const handleConfirmationModalProceed = async () => {
    await deleteWorkOrdersDisposalTicket({ workOrderId, disposalTicketId })
      .unwrap()
      .then(async () => {
        await fetchDisposalTicketData()
      })
      .catch(handleError)
    setTimeout(() => {
      onDeleteConfirmationModalProceed()
    }, 300)
  }
  const onMaterialDelete = index => {
    const id = getValues(`materials.${index}.id`)
    if (id) {
      setToBeDeletedMaterials([...toBeDeletedMaterials, { id, deleted: true }])
    }
    remove(index)
  }

  useEffect(() => {
    getDisposalSites()
    if (workOrderId) {
      fetchDisposalTicketData()
    }
  }, [workOrderId])

  useEffect(() => {
    dispatch(putIsLoading(isLoading))
  }, [isLoading])

  useEffect(() => {
    if (configuredServiceId) {
      getServiceDetails(configuredServiceId)
        .unwrap()
        .then(data => {
          const taxList = getTaxesAndFees(get(data, 'priceServiceDetail', {}))
          setFeeTaxList(taxList)
        })
    }
  }, [configuredServiceId])

  useEffect(() => {
    localStorage.setItem('isDisposalFeeTaxable', isTaxable)
  }, [isTaxable])

  const handleDisposalSiteIdChange = () => {
    materialFields.forEach((material, index) => {
      resetField(`materials.${index}.measureUnit`)
      resetField(`materials.${index}.disposalSiteMaterialId`)
      resetField(`materials.${index}.tippingFee`)
    })
  }

  useEffect(() => {
    let total = 0
    for (const material of materials) {
      const materialTotal = get(material, 'total', 0)
      total += materialTotal
    }
    setValue('total', total)
  }, [materials, isTaxable])

  return (
    <DisposalTicketVariantWrapper
      variant={variant}
      actions={
        <Grid container wrap="nowrap" columnGap={1}>
          {!isFormVisible && (
            <Grid item>
              <Button color="primary" size="small" variant="outlined" onClick={onAddDisposalTicketClick}>
                {T.ADD_DISPOSAL_TICKET}
              </Button>
            </Grid>
          )}

          {isFormVisible && (
            <Grid item container columnGap={1} alignItems="center" wrap="nowrap">
              <Grid item>
                <HHFormSwitchField disabled={!isFormEditable} control={control} name="isTaxable" label={T.TAXABLE} />
              </Grid>
              <Grid item>
                <DisposalTicketTaxInfoIcon />
              </Grid>
            </Grid>
          )}
          {showEditButton && (
            <Grid item>
              <Button color="primary" size="small" variant="outlined" onClick={toggleEditMode} sx={{ whiteSpace: 'nowrap' }}>
                {T.EDIT_DISPOSAL_TICKET}
              </Button>
            </Grid>
          )}
        </Grid>
      }
      footer={
        isFormEditable && (
          <DisposalTicketFormFooter
            variant={variant}
            isConfirmDeleteButtonLoading={isDeleteWorkOrdersDisposalTicketLoading}
            onSubmit={handleSubmit(onSubmitHandler, onSubmitErrorHandler)}
            onDeleteConfirmed={handleConfirmationModalProceed}
            onCancel={handleClose}
            isSubmitButtonLoading={isCreateWorkOrdersDisposalTicketLoading || isUpdateWorkOrdersDisposalTicketLoading}
          />
        )
      }
    >
      <Box sx={!isModalVariant && !isGetWorkOrdersDisposalTicketLoading && materialFields.length !== 0 && accordionContentSx}>
        {!isModalVariant && !isGetWorkOrdersDisposalTicketLoading && materialFields.length === 0 && (
          <HHSectionPlaceholder title={T.DISPOSAL_TICKETS_EMPTY_STATE_MSG} />
        )}
        {isGetWorkOrdersDisposalTicketLoading && <DisposalTicketFormSkeleton />}
        {isFormVisible && !isGetWorkOrdersDisposalTicketLoading && (
          <Grid container rowSpacing={1}>
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <HHFormTextField
                  readOnly={!isFormEditable}
                  required
                  rules={{ required: true }}
                  fullWidth
                  control={control}
                  name="ticketNumber"
                  label={`${T.TICKET} #`}
                  placeholder={`${T.TICKET} #`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <HHFormSelectField
                  readOnly={!isFormEditable}
                  fullWidth
                  control={control}
                  required
                  label={T.DISPOSAL_SITE}
                  placeholder={T.DISPOSAL_SITE}
                  options={allSitesSorted}
                  getOptionValue={getOptionId}
                  getOptionLabel={getDisposalSiteOptionLabel}
                  name="disposalSiteId"
                  onChange={handleDisposalSiteIdChange}
                  SelectProps={{ MenuProps: { sx: { zIndex: theme.zIndex.tooltip + 10 } } }}
                />
              </Grid>
            </Grid>
            <Box width="100%" mt={2}>
              {materialFields.map((materialField, index) => {
                const isLast = index === materialFields.length - 1
                return (
                  <DisposalMaterialFormRow
                    key={materialField.id}
                    materialFields={materialFields}
                    materialField={materialField}
                    index={index}
                    validMaterials={validMaterials}
                    isFormEditable={isFormEditable}
                    onMaterialDelete={onMaterialDelete}
                    allSites={allSites}
                    configuredServiceId={configuredServiceId}
                    groupByFeeTaxes={groupByFeeTaxes}
                    isLast={isLast}
                    showSubtotal={materialFields.length !== 1}
                  />
                )
              })}
            </Box>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography textAlign="right" variant="h4" fontWeight="bold">
                  <HHDisplayMoney value={total} formatToDollars={false} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary" textAlign="right">
                  Total
                </Typography>
              </Grid>
            </Grid>
            {isFormEditable && (
              <Grid container item xs={12}>
                <Grid item>
                  <Button variant="text" startIcon={<AddIcon />} onClick={onAddNewMaterial}>
                    {T.ADD_MATERIAL_AND_ITEM}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </DisposalTicketVariantWrapper>
  )
}

DisposalTicketForm.propTypes = {
  workOrderId: PropTypes.string,
  isFormEditable: PropTypes.bool,
  isFormAlwaysVisible: PropTypes.bool,
  onAddDisposalTicket: PropTypes.func,
  onDeleteConfirmationModalProceed: PropTypes.func,
  onDisposalTicketDataFetched: PropTypes.func,
  onSubmitWithoutChanges: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  toggleEditMode: PropTypes.func,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['modal', 'accordion']),
  configuredServiceId: PropTypes.string.isRequired,
}

export default DisposalTicketForm
