export const METHOD_TYPES = {
  SIDE_LOAD: 'SIDE LOAD',
  FRONT_LOAD: 'FRONT LOAD',
  REAR_LOAD: 'REAR LOAD',
  DUMP_TRUCK: 'DUMP TRUCK',
  HOOK: 'HOOK',
  HOIST: 'HOIST',
  GRAPPLE: 'GRAPPLE',
}

const { SIDE_LOAD, FRONT_LOAD, REAR_LOAD, DUMP_TRUCK, HOOK, HOIST, GRAPPLE } = METHOD_TYPES

export const METHOD_TO_MAP_ICON = {
  [SIDE_LOAD]: 'side-load-marker',
  [FRONT_LOAD]: 'front-load-marker',
  [REAR_LOAD]: 'rear-load-marker',
  [DUMP_TRUCK]: 'dump-truck-marker',
  [HOOK]: 'hook-marker',
  [HOIST]: 'hoist-marker',
  [GRAPPLE]: 'grapple-marker',
}
