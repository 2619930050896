import React from 'react'
import { FormControl, Avatar, InputLabel, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import useTheme from '@mui/material/styles/useTheme'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import { extractNameInitials } from 'utils/string'
import { isValidHex } from 'utils/validations'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import { Controller, useFormContext } from 'react-hook-form'

const DesktopRoutePicker = ({ control, name, onChange = noop, readOnly = false, routes = [], menuSx, rules = {}, ...rest }) => {
  const theme = useTheme()
  const { watch } = useFormContext()
  const value = watch(name)
  return (
    <FormControl fullWidth sx={{ position: 'relative' }}>
      {!value && <InputLabel sx={{ height: '40px', lineHeight: '.8rem' }}>Select a route</InputLabel>}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          const { ref, onChange: onChangeHookForm, onBlur: onBlurHookForm, ..._rest } = field
          const handleChange = e => {
            onChangeHookForm(e)
            onChange(e.target.value)
          }
          const renderValue = val => {
            if (!val || !val?.length) {
              return 'Select a route'
            }
            if (Array.isArray(val)) {
              return val.join(', ')
            }
            return val
          }
          return (
            <HHTextField
              {...field}
              sx={{
                '& .MuiOutlinedInput-root .MuiListItemAvatar-root': {
                  minWidth: '30px',
                  position: 'relative',
                  left: '-8px',
                },
              }}
              onBlur={onBlurHookForm}
              readOnly={readOnly}
              onChange={handleChange}
              fullWidth
              select
              inputRef={ref}
              variant="outlined"
              displayEmpty
              renderValue={renderValue}
              placeholder="Select a route"
              SelectProps={{
                MenuProps: {
                  sx: { zIndex: theme.zIndex.snackbar, ...menuSx },
                  PaperProps: {
                    sx: { maxHeight: 'calc(100vh - 80px)' },
                  },
                  alignment: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  },
                },
              }}
              {..._rest}
              {...rest}
            >
              {routes.map(val => {
                const routeName = get(val, 'name', '')
                const bgcolor = get(val, 'color', grey[100])
                const formattedBgcolor = isValidHex(bgcolor) ? bgcolor : '#000000'
                const color = theme.palette.getContrastText(formattedBgcolor)
                const id = get(val, 'id')
                const initials = extractNameInitials(routeName)
                const stopsCount = get(val, `stopCount`, 0)
                return (
                  <SelectItem disableGutters value={id}>
                    <ListItem disabbleGutters disablePadding>
                      <ListItemAvatar sx={{ minWidth: '40px' }}>
                        <Avatar
                          sx={{
                            width: '32px',
                            height: '32px',
                            bgcolor: formattedBgcolor,
                            color,
                          }}
                        >
                          <Typography variant="h5" fontWeight={400}>
                            {initials}
                          </Typography>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={routeName} secondary={`${stopsCount} stops`} />
                    </ListItem>
                  </SelectItem>
                )
              })}
            </HHTextField>
          )
        }}
      />
    </FormControl>
  )
}

DesktopRoutePicker.propTypes = {
  routes: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  menuSx: PropTypes.object,
  rules: PropTypes.object,
}

export default DesktopRoutePicker
