import { actions } from '../actions/response'

const { IS_LOADING } = actions.PUT

const initialState = {
  isLoading: false,
}

const ResponseReducer = (state = initialState, action = {}) => {
  if (action.type === IS_LOADING) {
    return { ...state, isLoading: action.payload }
  }
  return state
}

export default ResponseReducer
