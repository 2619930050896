import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useDispatch } from 'react-redux'

import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import T from 'T'
import api from 'api'
import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

import { handleError } from 'utils/error'
import { useUpdateWorkOrderMutation } from 'api/work-order/updateWorkOrder'
import { WO_STATUS_BE_TO_FE_FORMAT, WORK_ORDER_STATUS } from 'settings/constants/service'
import {
  CACHE_TAG_WORK_ORDER_DETAILS,
  CACHE_TAG_ROUTES,
  CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS,
  CACHE_TAG_ROUTES_METADATA_BY_DATE,
} from 'api/cacheTagTypes'
import CancelButton from 'components/buttons/CancelButton'
import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import { get } from '../../../utils/lodash'

const { SCHEDULED, IN_PROGRESS, SERVICED, SKIPPED, CANCELED, HOLD } = WORK_ORDER_STATUS
const ALLOWED_STATUS = [SCHEDULED, IN_PROGRESS, SERVICED, SKIPPED, CANCELED, HOLD]

const EditServiceDialog = ({ title = T.EDIT_SERVICE_DETAILS, isOpen = false, currentWOStatus, workOrderId, onClose, callback = noop }) => {
  const dispatch = useDispatch()
  const [updateWorkOrder, { isLoading }] = useUpdateWorkOrderMutation()
  const [newWOStatus, setNewWOStatus] = useState()
  const currentWorkOrderStatus = get(WO_STATUS_BE_TO_FE_FORMAT, currentWOStatus, currentWOStatus)
  const handleSave = () => {
    if (newWOStatus === currentWorkOrderStatus) {
      // If same status
      onClose()
      return
    }

    updateWorkOrder({ id: workOrderId, workOrderStatus: newWOStatus })
      .unwrap()
      .then(() => {
        dispatch(
          api.util.invalidateTags([
            CACHE_TAG_WORK_ORDER_DETAILS,
            CACHE_TAG_ROUTES,
            CACHE_TAG_ROUTES_UNASSIGNED_WORK_ORDERS,
            CACHE_TAG_ROUTES_METADATA_BY_DATE,
          ])
        )
        callback()
        onClose()
      })
      .catch(handleError)
  }

  useEffect(() => {
    if (isOpen) {
      setNewWOStatus(currentWorkOrderStatus)
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <HHDialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          <HHTextField
            sx={{ mt: 3 }}
            fullWidth
            deprecatedLabel={false}
            label={T.STATUS}
            select
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={[newWOStatus]}
            onChange={e => setNewWOStatus(e.target.value)}
          >
            {ALLOWED_STATUS.map(status => (
              <SelectItem value={status}>{status}</SelectItem>
            ))}
          </HHTextField>
        </DialogContentText>
      </DialogContent>
      <HHDialogActions>
        <CancelButton onClick={onClose} />
        <LoadingButton loading={isLoading} size="small" variant="contained" onClick={handleSave}>
          {T.SAVE}
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

EditServiceDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  currentWOStatus: PropTypes.string,
  workOrderId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func,
}

export default EditServiceDialog
