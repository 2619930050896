import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, CardMedia, IconButton as MuiIconButton, styled, useTheme } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import SwiperContainer from './SwiperContainer'

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  opacity: '0.7',
  background: theme.palette.background.paper,
  '&.Mui-disabled': {
    background: theme.palette.background.paper,
  },
  '&:hover': {
    background: theme.palette.background.paper,
  },
  borderWidth: 1,
  borderColor: theme.palette.border.lightGrey,
  borderStyle: 'solid',
  aspectRatio: 1,
  width: 20,
  height: 20,
}))
const ExceptionCardMediaSwiperContainer = ({ images, children }) => {
  const swiperRef = useRef()
  const theme = useTheme()
  const swiper = swiperRef?.current?.swiper

  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const handleSwiperPrev = e => {
    e.stopPropagation()
    swiper?.slidePrev(300)
    swiper?.update()
  }

  const handleSwiperNext = e => {
    swiper?.slideNext(300)
  }

  const handleReachEnd = swiper => {
    setIsEnd(swiper.isEnd)
    setIsBeginning(swiper.isBeginning)
  }

  const handleSlideChange = swiper => {
    setIsEnd(swiper.isEnd)
    setIsBeginning(swiper.isBeginning)
  }

  useEffect(() => {
    if (Array.isArray(images)) {
      setIsEnd(images.length === 1)
    }
  }, [images])

  return (
    <CardMedia sx={{ position: 'relative' }} component="div" height="194" alt="Paella dish">
      <SwiperContainer
        swiperRef={swiperRef}
        onSlideChange={handleSlideChange}
        onReachEnd={handleReachEnd}
        sx={{
          '.swiper-pagination-bullet': {
            opacity: 0.5,
            background: theme.palette.common.white,
          },
          '.swiper-pagination-bullet-active': {
            opacity: 1,
            background: theme.palette.common.white,
          },
        }}
      >
        {children}
      </SwiperContainer>
      {Array.isArray(images) && images.length > 1 && (
        <>
          <Box
            zIndex={1}
            position="absolute"
            left="4px"
            bottom="calc(50% - 12px)"
            transform="translate(-50%, -50%)"
            margin="0 auto"
            marginRight="-28px"
          >
            <IconButton disabled={isBeginning} onClick={handleSwiperPrev}>
              <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
          <Box
            zIndex={1}
            position="absolute"
            right="4px"
            bottom="calc(50% - 12px)"
            transform="translate(-50%, -50%)"
            margin="0 auto"
            marginLeft="-28px"
          >
            <IconButton disabled={isEnd} sx={{ ml: 1 }} onClick={handleSwiperNext}>
              <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </>
      )}
    </CardMedia>
  )
}

ExceptionCardMediaSwiperContainer.propTypes = {
  images: PropTypes.array,
  children: PropTypes.node,
}

export default ExceptionCardMediaSwiperContainer
