import { useCallback, useEffect, useMemo } from 'react'
import { deserializeDate, formatDateToBEFormatDateFns } from 'utils/date'
import { getBulkConfirmPermanentMoveData } from 'components/route-manager/BulkActions/settings'
import { useLazyGetRouteStopsPreviewQuery } from 'api/route/getRouteStopsPreview'

const useFetchConfirmPermanentRowsOnExpandedGroup = ({ apiRef, isInitialized, serializedServiceDate }) => {
  const [getRouteStopsPreview] = useLazyGetRouteStopsPreviewQuery()
  const serviceDate = useMemo(() => (serializedServiceDate ? deserializeDate(serializedServiceDate) : new Date()), [serializedServiceDate])

  const fetchConfirmPermanentMoveChildren = useCallback(
    (apiRef, row) => {
      if (!apiRef?.current || !row) {
        return
      }
      apiRef.current?.updateRows([
        { id: row.id, childrenFetched: false, isLoading: true },
        {
          id: `placeholder-children-${row.id}`,
          hierarchy: [...row?.hierarchy, ''],
        },
      ])
      getRouteStopsPreview({
        stops: [row.id],
        serviceDate: formatDateToBEFormatDateFns(serviceDate),
        updateMasterSequence: true,
      })
        .unwrap()
        .then(({ stops = [] }) => {
          const newRows = Array.isArray(stops) ? stops.map(previewRow => getBulkConfirmPermanentMoveData(previewRow)) : []
          apiRef?.current?.updateRows([
            ...newRows,
            { id: row.id, childrenFetched: true, descendantCount: newRows.length, count: newRows.length, isLoading: false },
            {
              id: `placeholder-children-${row.id}`,
              _action: 'delete',
            },
          ])
        })
        .catch(e => {
          throw e
        })
    },
    [getRouteStopsPreview, serviceDate]
  )

  useEffect(() => {
    if (!apiRef.current) {
      return
    }
    const handleRowExpansionChange = async node => {
      const row = apiRef.current.getRow(node.id)

      if (!node.childrenExpanded || !row || row.childrenFetched || row?.isLoading) {
        return
      }

      apiRef.current.updateRows([
        {
          id: `placeholder-children-${node.id}`,
          hierarchy: [...row.hierarchy, ''],
        },
      ])

      const childrenRows = await fetchConfirmPermanentMoveChildren(apiRef, row)
      apiRef.current.updateRows([...childrenRows, { id: node.id, childrenFetched: true }])

      if (childrenRows.length) {
        apiRef.current.setRowChildrenExpansion(node.id, true)
      }
    }
    if (isInitialized) {
      return apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange)
    }
  }, [apiRef, isInitialized, fetchConfirmPermanentMoveChildren])

  useEffect(() => {
    if (!apiRef.current) {
      return
    }
    /**
     * By default, the grid does not toggle the expansion of rows with 0 children
     * We need to override the `cellKeyDown` event listener to force the expansion if there are children on the server
     */
    const handleCellKeyDown = (params, event) => {
      const cellParams = apiRef.current.getCellParams(params.id, params.field)
      if (cellParams.colDef.type === 'treeDataGroup' && event.key === ' ') {
        event.stopPropagation()
        event.preventDefault()
        event.defaultMuiPrevented = true

        apiRef.current.setRowChildrenExpansion(params.id, !params.rowNode.childrenExpanded)
      }
    }

    if (isInitialized) {
      return apiRef.current.subscribeEvent('cellKeyDown', handleCellKeyDown, {
        isFirst: true,
      })
    }
  }, [apiRef, isInitialized])

  return { fetchConfirmPermanentMoveChildren }
}
export default useFetchConfirmPermanentRowsOnExpandedGroup
