import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { IconButton, ListItem, ListItemAvatar, ListItemText, Box, Menu, MenuItem, Avatar, SvgIcon, CircularProgress } from '@mui/material'
import { MoreHoriz, FilterList } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { useDispatch } from 'react-redux'
import { ReactComponent as CalendarPlus } from 'assets/route_manager/actions_menu/CalendarPlus.svg'
import { setIsOpenBulkAssign } from '../../slices/route/routeSlice'
import { UNASSIGNED_ROUTE } from './settings'

const UnassignedRouteHeader = ({ onOpenRouteFilterMenu, routeName, stopsCount, isActionsButtonDisabled = false }) => {
  const { watch } = useFormContext()
  const isFiltersLoading = watch('isFiltersLoading')
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleBulkAssignClick = () => {
    dispatch(setIsOpenBulkAssign(true))
    handleClose()
  }

  const handleRouteFilterClick = event => onOpenRouteFilterMenu(event, { id: UNASSIGNED_ROUTE.id })

  return (
    <Box position="relative">
      <ListItem
        divider
        secondaryAction={
          <>
            <IconButton disabled={isFiltersLoading || isActionsButtonDisabled} onClick={handleRouteFilterClick}>
              {isFiltersLoading ? <CircularProgress color="action" size={20} thickness={4} /> : <FilterList />}
            </IconButton>
            <IconButton disabled={isActionsButtonDisabled} onClick={handleClick}>
              <MoreHoriz sx={{ fontSize: 28 }} />
            </IconButton>
          </>
        }
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: grey[100] }}>
            <SvgIcon fontSize="medium">
              <CalendarPlus />
            </SvgIcon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5', flexWrap: 'nowrap', noWrap: true, fontWeight: 700 }}
          secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, flexWrap: 'nowrap', noWrap: true }}
          primary={routeName}
          secondary={`${stopsCount} stops`}
        />
      </ListItem>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          variant: 'outlined',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleBulkAssignClick}>Bulk assign</MenuItem>
      </Menu>
    </Box>
  )
}

UnassignedRouteHeader.propTypes = {
  onOpenRouteFilterMenu: PropTypes.func,
  routeName: PropTypes.string,
  stopsCount: PropTypes.number,
  isActionsButtonDisabled: PropTypes.bool,
}

export default UnassignedRouteHeader
