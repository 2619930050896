import api from 'api/index'
import {
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
} from 'api/cacheTagTypes'

const invalidatesTags = [
  CACHE_TAG_ACCOUNT_GROUP_DETAILS,
  CACHE_TAG_AGING_GROUP_DETAILS,
  CACHE_TAG_SUSPENSION_GROUP_DETAILS,
  CACHE_TAG_ACCOUNT_GROUP_SUMMARY_DETAILS,
  CACHE_TAG_ACCOUNT_GROUPS_BY_SUSPENDED_SUMMARY,
]

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    bulkResendUnpaidInvoicesForAccountGroup: builder.mutation({
      query: ({ accountGroupId }) => ({
        url: `/api/v1/core/accounts/groups/${accountGroupId}/resend-invoices`,
        method: 'POST',
      }),
      invalidatesTags,
    }),
    bulkResendUnpaidInvoicesForAgingGroup: builder.mutation({
      query: ({ accountGroupId }) => ({
        url: `/api/v1/core/aging/group/${accountGroupId}/resend-invoices`,
        method: 'POST',
        body: { suspendedOnly: false },
      }),
      invalidatesTags,
    }),
    bulkResendUnpaidInvoicesForSuspensionGroup: builder.mutation({
      query: () => ({
        url: '/api/v1/core/suspension/group/resend-invoices',
        method: 'POST',
      }),
      invalidatesTags,
    }),
  }),
})

export const {
  useBulkResendUnpaidInvoicesForAccountGroupMutation,
  useBulkResendUnpaidInvoicesForAgingGroupMutation,
  useBulkResendUnpaidInvoicesForSuspensionGroupMutation,
} = extendedApi
