import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    saveBusinessLineConfiguration: build.mutation({
      query: body => ({
        url: '/api/settings/business-line/config/update',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useSaveBusinessLineConfigurationMutation } = extendedApi