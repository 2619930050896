import { useCallback } from 'react'
import { addAssetsImageToMap } from 'utils/map'
import DriverMarker from 'assets/map/DriverMarker.png'
import CanceledMarker from 'assets/map/CanceledMarker.png'
import HoldMarker from 'assets/map/HoldMarker.png'
import InProgressMarker from 'assets/map/InProgressMarker.png'
import ScheduledMarker from 'assets/map/ScheduledMarker.png'
import ServicedMarker from 'assets/map/ServicedMarker.png'
import SkippedMarker from 'assets/map/SkippedMarker.png'
import ToBeInsertedMarker from 'assets/map/ToBeInsertedMarker.png'
import MonochromeMarker from 'assets/map/MonochromeMarker.png'
import HighlightedMarker from 'assets/map/HighlightedMarker.png'

const useLoadMapAssets = map => {
  return useCallback(() => {
    addAssetsImageToMap(map.current, 'canceled-marker', CanceledMarker)
    addAssetsImageToMap(map.current, 'driver-marker', DriverMarker)
    addAssetsImageToMap(map.current, 'hold-marker', HoldMarker)
    addAssetsImageToMap(map.current, 'in-progress-marker', InProgressMarker)
    addAssetsImageToMap(map.current, 'scheduled-marker', ScheduledMarker)
    addAssetsImageToMap(map.current, 'serviced-marker', ServicedMarker)
    addAssetsImageToMap(map.current, 'skipped-marker', SkippedMarker)
    addAssetsImageToMap(map.current, 'to-be-assigned-marker', ToBeInsertedMarker)
    addAssetsImageToMap(map.current, 'highlighted-marker', HighlightedMarker)
    addAssetsImageToMap(map.current, 'monochrome-marker', MonochromeMarker, true)
  }, [map])
}

useLoadMapAssets.propTypes = {}

export default useLoadMapAssets
