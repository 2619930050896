import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { toast } from 'react-toastify'

import { shallowEqual, useSelector } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { blobToBase64, getPaymentReceiptBlob } from 'utils/file'
import { FIREBASE_OBJECT_NOT_FOUND } from 'settings/error'
import { handleError } from 'utils/error'
import { useEmailPaymentReceiptsMutation } from 'api/billing/emailPaymentReceipts'
import { useRegeneratePaymentReceiptsMutation } from 'api/billing/regeneratePaymentReceipts'
import T from 'T'
import PdfViewer from '../../drawer/pdf-viewer/PdfViewer'

const PaymentReceiptDialog = ({
  open = false,
  accountId = '',
  accountName = '',
  paymentNumber = '',
  accountTransactionHistoryId = '',
  onClose = noop,
}) => {
  const [emailPaymentReceipts, { isLoading: isEmailPaymentReceiptsLoading }] = useEmailPaymentReceiptsMutation()
  const [regeneratePaymentReceipts] = useRegeneratePaymentReceiptsMutation()
  const [pdfData, setPdfdata] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isRegenerated, setIsRegenerated] = useState(false)
  const paymentReceiptName = ['Payment receipt', accountName, paymentNumber].join(' - ')

  const businessId = useSelector(state => state.AuthReducer.userInfo.businessInfo.businessId, shallowEqual)

  const handleSendPaymentReceipt = () => {
    emailPaymentReceipts({ accountTransactionHistoryIds: [accountTransactionHistoryId] })
      .unwrap()
      .then(() => toast.success(T.SEND_PAYMENT_RECEIPT_SUCCESS))
      .catch(handleError)
  }

  const regenerateReceipt = () => {
    regeneratePaymentReceipts({ accountTransactionHistoryIds: [accountTransactionHistoryId] })
      .unwrap()
      .then(() => setIsRegenerated(true))
      .catch(() => setIsLoading(false))
  }

  const getPaymentReceipt = () => {
    setIsLoading(true)
    getPaymentReceiptBlob(businessId, accountId, accountTransactionHistoryId)
      .then(blobToBase64)
      .then(base64 => {
        setPdfdata(base64.split(',')[1])
        setIsLoading(false)
      })
      .catch(error => {
        if (!isRegenerated && error?.code === FIREBASE_OBJECT_NOT_FOUND) {
          regenerateReceipt()
        } else {
          setIsLoading(false)
        }
      })
  }

  useEffect(() => {
    if (!paymentNumber || !accountTransactionHistoryId || !open || !businessId || !accountId) return
    getPaymentReceipt()
  }, [paymentNumber, businessId, accountId, open])

  useEffect(() => {
    if (isRegenerated) {
      setTimeout(getPaymentReceipt, 5000)
    }
  }, [isRegenerated])

  return (
    <PdfViewer
      open={open}
      isLoading={isLoading}
      type="payment receipt"
      fileName={paymentReceiptName}
      title={paymentReceiptName}
      pdfData={pdfData}
      onClose={() => {
        setPdfdata(undefined)
        setIsRegenerated(false)
        onClose()
      }}
      actions={
        pdfData && (
          <LoadingButton
            loading={isEmailPaymentReceiptsLoading}
            onClick={handleSendPaymentReceipt}
            sx={{ '& .MuiCircularProgress-root': { color: 'primary.main' } }}
          >
            Resend
          </LoadingButton>
        )
      }
    />
  )
}

PaymentReceiptDialog.propTypes = {
  open: PropTypes.bool,
  accountId: PropTypes.string,
  accountName: PropTypes.string,
  paymentNumber: PropTypes.number,
  accountTransactionHistoryId: PropTypes.number,
  onClose: PropTypes.func,
}

export default PaymentReceiptDialog
