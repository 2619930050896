import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Alert } from '@mui/material'

import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'
import { HHFormTextField } from 'components/form-fields/v5'

import T from 'T'
import { DEFAULT_ADDRESS_FIELD_KEY, DEFAULT_STATE } from './model'

const { latitudePlaceholder, longitudePlaceholder } = DEFAULT_STATE

const AddressLatLong = ({
  fieldPrefix = DEFAULT_ADDRESS_FIELD_KEY,
  isLatLngRequiredFields = false,
  lat = '',
  long = '',
  hasLatError = false,
  hasLongError = false,
  control,
  onBlurLat,
  onBlurLong,
  showInvalidAlert = true,
}) => {
  // lat long are optional for billing address but required for location address
  const checkLatValidations = value => {
    if (!isLatLngRequiredFields && !value) {
      return true
    }

    return isValidLatitudeRange(value)
  }

  const checkLongValidations = value => {
    if (!isLatLngRequiredFields && !value) {
      return true
    }

    return isValidLongitudeRange(value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HHFormTextField
          control={control}
          error={hasLatError}
          required={isLatLngRequiredFields}
          rules={{ validate: checkLatValidations }}
          name={`${fieldPrefix}.latitude`}
          label={T.LATITUTE}
          placeholder={latitudePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onBlur={event => onBlurLat(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <HHFormTextField
          control={control}
          error={hasLongError}
          required={isLatLngRequiredFields}
          rules={{ validate: checkLongValidations }}
          name={`${fieldPrefix}.longitude`}
          label={T.LONGITUDE}
          placeholder={longitudePlaceholder}
          fullWidth
          deprecatedLabel={false}
          onBlur={event => onBlurLong(event.target.value)}
        />
      </Grid>
      {showInvalidAlert && (!isValidLatitudeRange(lat) || !isValidLongitudeRange(long)) && (
        <Grid item xs={12}>
          <Alert sx={{ display: 'flex', alignItems: 'center' }} severity="warning">
            {T.INVALID_LAT_LONG}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

AddressLatLong.propTypes = {
  fieldPrefix: PropTypes.string,
  isLatLngRequiredFields: PropTypes.bool,
  lat: PropTypes.string,
  long: PropTypes.string,
  hasLatError: PropTypes.bool,
  hasLongError: PropTypes.bool,
  control: PropTypes.object.isRequired,
  onBlurLat: PropTypes.func.isRequired,
  onBlurLong: PropTypes.func.isRequired,
  showInvalidAlert: PropTypes.bool,
}

export default AddressLatLong
