import React from 'react'
import PropTypes from 'prop-types'

const TabPanel = ({ children, value, index, className, reRender }) => (
  <div role="tabpanel" hidden={value !== index} className={className}>
    {!reRender && children}
    {reRender && value === index && children}
  </div>
)

TabPanel.defaultProps = {
  className: '',
  reRender: false,
}

TabPanel.propTypes = {
  className: PropTypes.string,
  reRender: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
}

export default TabPanel
